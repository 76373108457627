import Dexie from "dexie"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { ColumnLayout, ControllerModeContainer, RowLayout } from "../Styles/Styles"

export const GlobalSettingsView: React.FC = props => {


    return <>
        <ContentTile>
            <ColumnLayout>
            <RowLayout>
                <TextStyle>Key</TextStyle>
                <input type="text" />
            </RowLayout>
            <StyledButton>Save</StyledButton>
            </ColumnLayout>
        </ContentTile>
    </>
}