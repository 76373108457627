import { AppConfig } from "../../Types/AppConfig";
import { DictionaryQueryResult, HttpFile, Messages, Query, SegmentSentenceRequest, SentenceSegmentationResult } from "../../api";

export enum ResponseType {
    Success,
    Error
}

export interface ApiResponse<ResultType, StatusType> {
    object : ResultType
    status: StatusType
    responseType: ResponseType.Success
}

export interface ApiError<ErrorType, StatusType> {
    error : ErrorType
    status: StatusType
    responseType: ResponseType.Error
}

export enum ApiErrorType {
    Http403,
    Http500,
    HttpOther
}

export enum ApiRequestStatus {
    Success,
    Forbidden,
    Error
}

export type ApiCall<ResultType> = Promise<ApiEither<ResultType>>
export type ApiEither<ResultType> = ApiResponse<ResultType, ApiRequestStatus> | ApiError<ApiErrorType, ApiRequestStatus>


export interface RootApiContext {
    Settings?: AppConfig

    applySettings : (settings? : AppConfig) => void 
    setApiKey : (key : string) => void

    CheckKey : (key : string) => ApiCall<boolean>

    AssistantChat : (messages : Messages) => ApiCall<string>

    Lookup : (query : Query) => ApiCall<DictionaryQueryResult>
    Translate : (query : Query) => ApiCall<string>
    Explain : (query : Query) => ApiCall<string>

    SegmentSentences : (request : SegmentSentenceRequest) => ApiCall<SentenceSegmentationResult>

    EvaluateImage : (file : HttpFile) => ApiCall<SentenceSegmentationResult>
    EvaluatePronunciation : (id: string, blob: Blob) => ApiCall<any>
}