/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SegmentedText } from '../models/SegmentedText';
import { TextPair } from '../models/TextPair';
import { HttpFile } from '../http/http';

export class SegmentationOutput {
    'input': TextPair;
    'output': Array<SegmentedText>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "input",
            "baseName": "Input",
            "type": "TextPair",
            "format": ""
        },
        {
            "name": "output",
            "baseName": "Output",
            "type": "Array<SegmentedText>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SegmentationOutput.attributeTypeMap;
    }

    public constructor() {
    }
}

