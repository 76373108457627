import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { AssistValidateRequest } from '../models/AssistValidateRequest';
import { DictionaryEntryType } from '../models/DictionaryEntryType';
import { DictionaryQueryResult } from '../models/DictionaryQueryResult';
import { EntryExtraData } from '../models/EntryExtraData';
import { KeyContainer } from '../models/KeyContainer';
import { LanguageTextPair } from '../models/LanguageTextPair';
import { LanguageUnit } from '../models/LanguageUnit';
import { LanguageUnitDefinition } from '../models/LanguageUnitDefinition';
import { LanguageUnitDefinitionExamples } from '../models/LanguageUnitDefinitionExamples';
import { LookupQuery } from '../models/LookupQuery';
import { Message } from '../models/Message';
import { Messages } from '../models/Messages';
import { Query } from '../models/Query';
import { Sample } from '../models/Sample';
import { SegmentSentenceRequest } from '../models/SegmentSentenceRequest';
import { SegmentTextRequest } from '../models/SegmentTextRequest';
import { SegmentationOutput } from '../models/SegmentationOutput';
import { SegmentationResult } from '../models/SegmentationResult';
import { SegmentedText } from '../models/SegmentedText';
import { SentenceSegmentationResult } from '../models/SentenceSegmentationResult';
import { TextPair } from '../models/TextPair';
import { TranslateResponse } from '../models/TranslateResponse';
import { TranslateResponseCost } from '../models/TranslateResponseCost';
import { TranslateResponseOutput } from '../models/TranslateResponseOutput';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class ObservableDefaultApi {
    private requestFactory: DefaultApiRequestFactory;
    private responseProcessor: DefaultApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new DefaultApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new DefaultApiResponseProcessor();
    }

    /**
     * @param query 
     */
    public assistExamplesWithHttpInfo(query: Query, _options?: Configuration): Observable<HttpInfo<TranslateResponse>> {
        const requestContextPromise = this.requestFactory.assistExamples(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistExamplesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param query 
     */
    public assistExamples(query: Query, _options?: Configuration): Observable<TranslateResponse> {
        return this.assistExamplesWithHttpInfo(query, _options).pipe(map((apiResponse: HttpInfo<TranslateResponse>) => apiResponse.data));
    }

    /**
     * @param query 
     */
    public assistExplainWithHttpInfo(query: Query, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistExplain(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistExplainWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param query 
     */
    public assistExplain(query: Query, _options?: Configuration): Observable<string> {
        return this.assistExplainWithHttpInfo(query, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param query 
     */
    public assistTranslateWithHttpInfo(query: Query, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistTranslate(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistTranslateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param query 
     */
    public assistTranslate(query: Query, _options?: Configuration): Observable<string> {
        return this.assistTranslateWithHttpInfo(query, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidateWithHttpInfo(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Observable<HttpInfo<TranslateResponse>> {
        const requestContextPromise = this.requestFactory.assistValidate(assistValidateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistValidateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidate(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Observable<TranslateResponse> {
        return this.assistValidateWithHttpInfo(assistValidateRequest, _options).pipe(map((apiResponse: HttpInfo<TranslateResponse>) => apiResponse.data));
    }

    /**
     * @param messages 
     */
    public assistantChatWithHttpInfo(messages: Messages, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistantChat(messages, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistantChatWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param messages 
     */
    public assistantChat(messages: Messages, _options?: Configuration): Observable<string> {
        return this.assistantChatWithHttpInfo(messages, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param keyContainer 
     */
    public checkKeyWithHttpInfo(keyContainer: KeyContainer, _options?: Configuration): Observable<HttpInfo<boolean>> {
        const requestContextPromise = this.requestFactory.checkKey(keyContainer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.checkKeyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param keyContainer 
     */
    public checkKey(keyContainer: KeyContainer, _options?: Configuration): Observable<boolean> {
        return this.checkKeyWithHttpInfo(keyContainer, _options).pipe(map((apiResponse: HttpInfo<boolean>) => apiResponse.data));
    }

    /**
     * @param image 
     */
    public evaluateImageWithHttpInfo(image: HttpFile, _options?: Configuration): Observable<HttpInfo<SentenceSegmentationResult>> {
        const requestContextPromise = this.requestFactory.evaluateImage(image, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.evaluateImageWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param image 
     */
    public evaluateImage(image: HttpFile, _options?: Configuration): Observable<SentenceSegmentationResult> {
        return this.evaluateImageWithHttpInfo(image, _options).pipe(map((apiResponse: HttpInfo<SentenceSegmentationResult>) => apiResponse.data));
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciationWithHttpInfo(reference: string, audio: HttpFile, _options?: Configuration): Observable<HttpInfo<any>> {
        const requestContextPromise = this.requestFactory.evaluatePronunciation(reference, audio, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.evaluatePronunciationWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciation(reference: string, audio: HttpFile, _options?: Configuration): Observable<any> {
        return this.evaluatePronunciationWithHttpInfo(reference, audio, _options).pipe(map((apiResponse: HttpInfo<any>) => apiResponse.data));
    }

    /**
     * @param lookupQuery 
     */
    public lookupQueryWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Observable<HttpInfo<DictionaryQueryResult>> {
        const requestContextPromise = this.requestFactory.lookupQuery(lookupQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.lookupQueryWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param lookupQuery 
     */
    public lookupQuery(lookupQuery: LookupQuery, _options?: Configuration): Observable<DictionaryQueryResult> {
        return this.lookupQueryWithHttpInfo(lookupQuery, _options).pipe(map((apiResponse: HttpInfo<DictionaryQueryResult>) => apiResponse.data));
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentencesWithHttpInfo(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Observable<HttpInfo<SentenceSegmentationResult>> {
        const requestContextPromise = this.requestFactory.segmentSentences(segmentSentenceRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.segmentSentencesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentences(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Observable<SentenceSegmentationResult> {
        return this.segmentSentencesWithHttpInfo(segmentSentenceRequest, _options).pipe(map((apiResponse: HttpInfo<SentenceSegmentationResult>) => apiResponse.data));
    }

    /**
     * @param segmentTextRequest 
     */
    public segmentTextWithHttpInfo(segmentTextRequest: SegmentTextRequest, _options?: Configuration): Observable<HttpInfo<SegmentationResult>> {
        const requestContextPromise = this.requestFactory.segmentText(segmentTextRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.segmentTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param segmentTextRequest 
     */
    public segmentText(segmentTextRequest: SegmentTextRequest, _options?: Configuration): Observable<SegmentationResult> {
        return this.segmentTextWithHttpInfo(segmentTextRequest, _options).pipe(map((apiResponse: HttpInfo<SegmentationResult>) => apiResponse.data));
    }

}
