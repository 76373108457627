import React from "react"
import { useState } from "react"
import { TextStyle } from "../App"
import { DisplayItem } from "../Components/DisplayItem"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { UnitDefinitionMap } from "../Config/UnitDefinitionMap"
import { ZhLanguageDefinition } from "../data/ZhLanguageDefinition"
import { db } from "../Database/db"
import { ItemHistoryElement } from "../Database/HistoryState"
import { ColumnLayout, ControllerModeContainer, RowLayout } from "../Styles/Styles"

export const TestDisplayTestDefinition: React.FC<{ Back: () => any }> = props => {

    const [checkItem, setCheck] = useState("")
    const [checkItemHistory, setItemHistory] = useState<ItemHistoryElement | undefined>(undefined)

    const [selectedUnitType, setSelectedUnitType] = useState(0)
    const [selectedTd, setSelectedTd] = useState(0)

    const [display, setDisplayOn] = useState(false)

    const CheckItemHistory = async () => {

        db.ItemHistory.where("UnitKey").equals(checkItem).first().then(h => {
            if (h != undefined) {
                setItemHistory(h)
                setSelectedUnitType(h!!.UnitTypes[0])
                setSelectedTd(h!!.TestDefinitionId)
                setDisplayOn(false)
            } else {
                setItemHistory(undefined)
                setDisplayOn(false)
            }
        })
    }

    const itemRef = React.createRef<HTMLDivElement>()


    return <>
        <ContentTile>
            <ControllerModeContainer>

                <RowLayout>
                    <div>
                        <ColumnLayout>
                            <TextStyle weight="bold">Check Item</TextStyle>
                            <input type='text' onChange={(e) => { setCheck(e.target.value) }} />
                            <StyledButton onClick={CheckItemHistory}>Check</StyledButton>

                        </ColumnLayout>
                    </div>
                    {checkItemHistory != undefined && <>

                        <div>
                            <ColumnLayout>
                                <TextStyle>UnitId {checkItemHistory.Id}</TextStyle>
                                <TextStyle>Unlocked: {checkItemHistory.Unlocked ? "true" : "false"}</TextStyle>
                                <TextStyle>Introduced: {checkItemHistory.Introduced ? "true" : "false"}</TextStyle>
                                <TextStyle>Types: {checkItemHistory.UnitTypes.join(", ")}</TextStyle>
                                <TextStyle>Key: {checkItemHistory.UnitKey}</TextStyle>
                                {/* {checkItemHistory.UnitTypes.includes(ZhLanguageUnitTypes.Word) && <>
                                    <TextStyle>Unlock Status: {UnitDefinitionMap[ZhLanguageUnitTypes.Word].UnlockItemOn(checkItemHistory, undefined) ? "true" : "false"}</TextStyle>
                                </>} */}
                            </ColumnLayout>
                        </div>

                        <div>
                            <ColumnLayout>
                                <TextStyle>LanguageDefinitionUnit</TextStyle>
                                <TextStyle>Keys:</TextStyle>
                                <TextStyle>Radical: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Radical"]}</TextStyle>
                                <TextStyle>Hanzi: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Hanzi"]}</TextStyle>
                                <TextStyle>Word: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Word"]}</TextStyle>
                            </ColumnLayout>
                        </div>
                        <div>
                            <ColumnLayout>
                                <TextStyle>TestDefinitions</TextStyle>
                                <select defaultValue={selectedUnitType} onChange={(e) => { setDisplayOn(false); setSelectedUnitType(parseInt(e.target.value)) }}>
                                    {checkItemHistory.UnitTypes.map(t => {
                                        return <option value={t} label={UnitDefinitionMap[t].DisplayLabel} />
                                    })}
                                </select>
                                <select defaultValue={selectedTd} onChange={(e) => { setDisplayOn(false); setSelectedTd(parseInt(e.target.value)) }}>
                                    {Object.entries(ZhLanguageConfiguration.BaseUnitTestDefinitions).map((e) => {
                                        return <option value={parseInt(e[0])} label={e[1].Label} />
                                    })}
                                </select>
                                <StyledButton onClick={() => { setDisplayOn(true) }}>Load</StyledButton>
                                <StyledButton onClick={() => { setDisplayOn(false) }}>Unload</StyledButton>
                            </ColumnLayout>
                        </div>
                    </>}
                </RowLayout>

                {
                    display && <>
                        <ContentTile>
                            <div ref={itemRef}>
                                <DisplayItem Data={{
                                    LanguageConfiguration: ZhLanguageConfiguration,

                                    RoundItem: {
                                        HistoryId: 0,
                                        UnitItemKey: checkItemHistory!!.UnitKey,
                                        TestDefinition: selectedTd,
                                        Complete: false,
                                        SuccessCount: 0,
                                        FailureCount: 0,
                                        Record: []


                                    },
                                    Introduce: false,
                                    TimeLimit: 0,
                                    OnIntroduced: () => { setDisplayOn(false) },
                                    OnAnswer: () => { setDisplayOn(false) }
                                }} />
                            </div>
                        </ContentTile>
                    </>
                }
                <StyledButton onClick={() => { props.Back() }}><TextStyle>Back</TextStyle></StyledButton>

            </ControllerModeContainer>
        </ContentTile>
    </>
}