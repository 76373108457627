import { useEffect, useState } from "react"
import { AppCallbacks, TextStyle } from "../App"
import { db } from "../Database/db"
import { ProcessedTextDisplay } from "../Displays/ProcessedTextDisplay"
import { ProcessedText } from "../Types/ProcessedText"
import { ProcessTextView } from "./ProcessTextView"

export const ManageTextsView: React.FC<AppCallbacks> = props => {

    enum ViewState {
        Main,
        Process
    }


    const [loaded, setLoaded] = useState(false)
    const [viewState, setViewState] = useState(ViewState.Main)

    const [texts, setTexts] = useState<ProcessedText[]>([])

    const TextMenuConfig = [
        {
            Label: "Process Text",
            OnClick: () => { setViewState(ViewState.Process) }
        },
        {
            Label: "Exit",
            OnClick: () => { props.OnExit() }
        }
    ]



    useEffect(() => {
        if (loaded) {
            props.SetMenu(TextMenuConfig)
        }

        if (!loaded) {
            db.Texts.toArray().then((texts) => { setTexts(texts); setLoaded(true) })
        }
    }, [loaded])

    return <>
        <div style={{ width: '100%', padding: 24 }}>
            {viewState == ViewState.Main && <>
                {!loaded && <><TextStyle>Loading...</TextStyle></>}
                {loaded && <>
                    {texts.map(t => {
                        return <ProcessedTextDisplay Text={t} />
                    })}
                </>}
            </>}
            {viewState == ViewState.Process && <ProcessTextView OnExit={() => { setViewState(ViewState.Main); props.SetMenu(TextMenuConfig) }} SetMenu={props.SetMenu} />}
        </div>
    </>
}