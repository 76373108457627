export const OptionsIcon: React.FC = props => {
    return <>

        <svg width="24" height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" >

            <path d="M0 0h48v48H0z" fill="none" />
            <g id="Shopicon">
                <circle cx="24" cy="24" r="5" />
                <circle cx="24" cy="11" r="5" />
                <circle cx="24" cy="37" r="5" />
            </g>
        </svg>
    </>
}