// @ts-no-check
import { LanguageDefinition } from '../Types/LanguageDefinition';
import { ZhLanguageUnitTypes } from '../Types/Zh/LanguageDefinition';

export const ZhLanguageDefinition : LanguageDefinition<ZhLanguageUnitTypes> = {
  "重组": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngzǔ",
      "PrimaryDefinition": "to reorganize, to recombine, recombination"
    },
    "Keys": {
      "Word": 4422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "菏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "river in Shandong province"
    },
    "Keys": {
      "Hanzi": 4762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4762,
      "Radical": "艸",
      "FrequencyRank": 4926,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "漓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "river in Guangxi province; water dripping"
    },
    "Keys": {
      "Hanzi": 3014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3014,
      "Radical": "水",
      "FrequencyRank": 3413,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "各式各样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèshì-gèyàng",
      "PrimaryDefinition": "various, kinds, sundry, all sorts, assorted"
    },
    "Keys": {
      "Word": 6739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衔接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiánjiē",
      "PrimaryDefinition": "to join together, to combine"
    },
    "Keys": {
      "Word": 9891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "proud, haughty, overbearing"
    },
    "Keys": {
      "Hanzi": 2690,
      "Word": 5452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.11",
      "Grade": 7,
      "GeneralStandard": 2690,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1960,
      "StrokeCount": 12
    }
  },
  "烛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "candle, taper; shine, illuminate"
    },
    "Keys": {
      "Hanzi": 2061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "GeneralStandard": 2061,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 2537,
      "StrokeCount": 10
    }
  },
  "皴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūn",
      "PrimaryDefinition": "chapped, cracked"
    },
    "Keys": {
      "Hanzi": 5325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5325,
      "Radical": "皮",
      "FrequencyRank": 5961,
      "RadicalIndex": "107.7",
      "StrokeCount": 12
    }
  },
  "生前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngqián",
      "PrimaryDefinition": "during lifetime (of deceased)"
    },
    "Keys": {
      "Word": 9057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "靡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "divide, disperse, scatter"
    },
    "Keys": {
      "Hanzi": 3466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3466,
      "Radical": "非",
      "FrequencyRank": 3074,
      "RadicalIndex": "175.11",
      "StrokeCount": 19
    }
  },
  "来历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láilì",
      "PrimaryDefinition": "history, antecedents, origin"
    },
    "Keys": {
      "Word": 7919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诚心诚意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxīn-chéngyì",
      "PrimaryDefinition": "earnestly and sincerely (idiom), with all sincerity"
    },
    "Keys": {
      "Word": 5877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "活期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huóqī",
      "PrimaryDefinition": "(banking) current (account), checking (account), demand (deposit etc)"
    },
    "Keys": {
      "Word": 7237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "世界级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjièjí",
      "PrimaryDefinition": "world-class"
    },
    "Keys": {
      "Word": 9131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成才": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng cái",
      "PrimaryDefinition": "to make sth of oneself, to become a person who is worthy of respect"
    },
    "Keys": {
      "Word": 5867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngshí",
      "PrimaryDefinition": "then, at that time, while, at once, right away"
    },
    "Keys": {
      "Word": 604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "沉着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénzhuó",
      "PrimaryDefinition": "steady, calm and collected, not nervous"
    },
    "Keys": {
      "Word": 5856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掌握": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎngwò",
      "PrimaryDefinition": "take ... into one's hands, know well, master, have in hand, grasp, command, possess, control"
    },
    "Keys": {
      "Word": 4194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "地位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìwèi",
      "PrimaryDefinition": "station, estate, importance, appointment, character, footing, capacity, chair, estate of the realm, level, situation, status, standing, position, spot, connection, space, sphere, quality, place, condition"
    },
    "Keys": {
      "Word": 2408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "玎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "jingling, tinkling"
    },
    "Keys": {
      "Hanzi": 3565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3565,
      "Radical": "玉",
      "FrequencyRank": 5921,
      "RadicalIndex": "96.2",
      "StrokeCount": 6
    }
  },
  "整合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěnghé",
      "PrimaryDefinition": "integrate"
    },
    "Keys": {
      "Word": 10730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邪恶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiéè",
      "PrimaryDefinition": "sinister, vicious, wicked, evil"
    },
    "Keys": {
      "Word": 9989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "cove, bay, inlet"
    },
    "Keys": {
      "Hanzi": 5012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5012,
      "Radical": "阜",
      "FrequencyRank": 6472,
      "RadicalIndex": "170.9",
      "StrokeCount": 11
    }
  },
  "鲽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "flatfish; flounder; sole"
    },
    "Keys": {
      "Hanzi": 6277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6277,
      "Radical": "魚",
      "FrequencyRank": 6555,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "揭发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēfā",
      "PrimaryDefinition": "to expose, to bring to light, to disclose, revelation"
    },
    "Keys": {
      "Word": 7513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "carp, carassicus auratus"
    },
    "Keys": {
      "Hanzi": 5530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5530,
      "Radical": "魚",
      "FrequencyRank": 5408,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "停留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngliú",
      "PrimaryDefinition": "stay for a time or at a place"
    },
    "Keys": {
      "Word": 3985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "虹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "rainbow"
    },
    "Keys": {
      "Hanzi": 1497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.3",
      "GeneralStandard": 1497,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2176,
      "StrokeCount": 9
    }
  },
  "驽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nú",
      "PrimaryDefinition": "tired, old horse; old, weak"
    },
    "Keys": {
      "Hanzi": 4070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4070,
      "Radical": "馬",
      "FrequencyRank": 5972,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "清洗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxǐ",
      "PrimaryDefinition": "to wash, to clean, to purge"
    },
    "Keys": {
      "Word": 4950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "愚蠢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúchǔn",
      "PrimaryDefinition": "silly, stupid"
    },
    "Keys": {
      "Word": 10499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "ramie; sack cloth"
    },
    "Keys": {
      "Hanzi": 6641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6641,
      "Radical": "糸",
      "FrequencyRank": 6378,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "锱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "8 oz; an ancient unit of weight"
    },
    "Keys": {
      "Hanzi": 5492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5492,
      "Radical": "金",
      "FrequencyRank": 5967,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "拙劣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuōliè",
      "PrimaryDefinition": "clumsy, botched"
    },
    "Keys": {
      "Word": 10925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "a bamboo flute with seven holes"
    },
    "Keys": {
      "Hanzi": 6127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6127,
      "Radical": "竹",
      "FrequencyRank": 5585,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "史无前例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐwúqiánlì",
      "PrimaryDefinition": "unprecedented in history"
    },
    "Keys": {
      "Word": 9123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口令": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒulìng",
      "PrimaryDefinition": "oral command, a word of command (used in drilling troops or gymnasts), password (used by sentry)"
    },
    "Keys": {
      "Word": 7840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "time, season; era, age, period"
    },
    "Keys": {
      "Hanzi": 717,
      "Word": 1895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.3",
      "Grade": 3,
      "GeneralStandard": 717,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 25,
      "StrokeCount": 7
    }
  },
  "视频": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìpín",
      "PrimaryDefinition": "video"
    },
    "Keys": {
      "Word": 3910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "惊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "frighten, surprise, startle"
    },
    "Keys": {
      "Hanzi": 2445,
      "Word": 7598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "Grade": 7,
      "GeneralStandard": 2445,
      "Radical": "心",
      "HSK": 4,
      "FrequencyRank": 659,
      "StrokeCount": 11
    }
  },
  "剩下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng xia",
      "PrimaryDefinition": "be left over, leave, be left (over), be left, remain"
    },
    "Keys": {
      "Word": 3894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "奭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "red; anger; ire; surname"
    },
    "Keys": {
      "Hanzi": 5858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5858,
      "Radical": "大",
      "RadicalIndex": "37.12",
      "StrokeCount": 15
    }
  },
  "报到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào dào",
      "PrimaryDefinition": "check in, register, registration, report for duty, appear, report, check_in"
    },
    "Keys": {
      "Word": 1283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "作废": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòfèi",
      "PrimaryDefinition": "to become invalid, to cancel, to delete, to nullify"
    },
    "Keys": {
      "Word": 5419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "生机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngjī",
      "PrimaryDefinition": "animation, verdure, vitality, vital_force, viability, life, verve, lease of life, new lease on life"
    },
    "Keys": {
      "Word": 9053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竞相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngxiāng",
      "PrimaryDefinition": "competitive, eagerly, to vie"
    },
    "Keys": {
      "Word": 7637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lào",
      "PrimaryDefinition": "inundate, flood; torrent"
    },
    "Keys": {
      "Hanzi": 2067,
      "Word": 7955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 7,
      "GeneralStandard": 2067,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 4179,
      "StrokeCount": 10
    }
  },
  "仵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "similar"
    },
    "Keys": {
      "Hanzi": 3606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3606,
      "Radical": "人",
      "FrequencyRank": 6393,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "糍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "food made of crushed and cooked rice"
    },
    "Keys": {
      "Hanzi": 5992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5992,
      "Radical": "米",
      "FrequencyRank": 6391,
      "RadicalIndex": "119.9",
      "StrokeCount": 15
    }
  },
  "逃生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táoshēng",
      "PrimaryDefinition": "to flee for one's life"
    },
    "Keys": {
      "Word": 9432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "身不由己": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnbùyóujǐ",
      "PrimaryDefinition": "without the freedom to act independently (idiom), involuntary, not of one's own volition, in spite of oneself"
    },
    "Keys": {
      "Word": 9020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "魄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pò",
      "PrimaryDefinition": "vigor; body; dark part of moon"
    },
    "Keys": {
      "Hanzi": 3129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "194.5",
      "GeneralStandard": 3129,
      "Radical": "鬼",
      "HSK": 9,
      "FrequencyRank": 2511,
      "StrokeCount": 14
    }
  },
  "权利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánlì",
      "PrimaryDefinition": "privilege, claim, merit, title, entitlements, escheatage, right, dib, authority, competency, obligation, pretension, prescription, dibs"
    },
    "Keys": {
      "Word": 2816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "检": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "check; examine"
    },
    "Keys": {
      "Hanzi": 2227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "GeneralStandard": 2227,
      "Radical": "木",
      "HSK": 2,
      "FrequencyRank": 731,
      "StrokeCount": 11
    }
  },
  "长度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángdù",
      "PrimaryDefinition": "long measure, extent, long_measure, measurement, length, yardage, longness, linear measure"
    },
    "Keys": {
      "Word": 3309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "监测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāncè",
      "PrimaryDefinition": "monitor, supervise"
    },
    "Keys": {
      "Word": 4709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "名胜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngshèng",
      "PrimaryDefinition": "a place famous for its scenery or historical relics, scenic spot, CL:處|处[chu4]"
    },
    "Keys": {
      "Word": 4868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "壮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàng",
      "PrimaryDefinition": "big, large; robust; name of tribe"
    },
    "Keys": {
      "Hanzi": 510,
      "Word": 10906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "33.3",
      "Grade": 7,
      "GeneralStandard": 510,
      "Radical": "士",
      "HSK": 6,
      "FrequencyRank": 1432,
      "StrokeCount": 6
    }
  },
  "煤矿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méikuàng",
      "PrimaryDefinition": "coal mine, coal seam"
    },
    "Keys": {
      "Word": 8227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "准则": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔnzé",
      "PrimaryDefinition": "guideline, formula, principle, practice, norm, rule of thumb, standard, verity, criterion, canon, touchstone, basis, guidepost, maxim"
    },
    "Keys": {
      "Word": 10929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "但是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànshì",
      "PrimaryDefinition": "conj.: but, however, yet, still"
    },
    "Keys": {
      "Word": 601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "珵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7101,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "尔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "you; that, those; final particle"
    },
    "Keys": {
      "Hanzi": 286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "42.2",
      "GeneralStandard": 286,
      "Radical": "小",
      "HSK": 5,
      "FrequencyRank": 220,
      "StrokeCount": 5
    }
  },
  "匣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "small box, case, coffer"
    },
    "Keys": {
      "Hanzi": 692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 692,
      "Radical": "匚",
      "FrequencyRank": 3061,
      "RadicalIndex": "22.5",
      "StrokeCount": 7
    }
  },
  "蔻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòu",
      "PrimaryDefinition": "肉豆蔻 nutmeg, 豆蔻 cardamon"
    },
    "Keys": {
      "Hanzi": 5655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5655,
      "Radical": "艸",
      "FrequencyRank": 5601,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "蕗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "leucacene"
    },
    "Keys": {
      "Hanzi": 7842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7842,
      "Radical": "艸",
      "FrequencyRank": 7357,
      "RadicalIndex": "140.12",
      "StrokeCount": 16
    }
  },
  "水温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐwēn",
      "PrimaryDefinition": "water temperature"
    },
    "Keys": {
      "Word": 9269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěn",
      "PrimaryDefinition": "gnaw, chew, bite"
    },
    "Keys": {
      "Hanzi": 2275,
      "Word": 7818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "Grade": 7,
      "GeneralStandard": 2275,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 3345,
      "StrokeCount": 11
    }
  },
  "打盹儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ dǔnr5",
      "PrimaryDefinition": "to doze off, erhua variant of 打盹[da3 dun3]"
    },
    "Keys": {
      "Word": 6100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎorén",
      "PrimaryDefinition": "a healthy person, good person, goodman, sb. who tries to get along with everyone, bawcock, good, agreeable individual, good egg, a person who tries to get along with everyone (oft"
    },
    "Keys": {
      "Word": 712
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "除去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúqù",
      "PrimaryDefinition": "eliminate"
    },
    "Keys": {
      "Word": 5984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "clover"
    },
    "Keys": {
      "Hanzi": 3859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3859,
      "Radical": "艸",
      "FrequencyRank": 5032,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "姓名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngmíng",
      "PrimaryDefinition": "surname and personal name, name, full name"
    },
    "Keys": {
      "Word": 1116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "税务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuìwù",
      "PrimaryDefinition": "taxation services, state revenue service"
    },
    "Keys": {
      "Word": 9275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "stone roller used to level fields"
    },
    "Keys": {
      "Hanzi": 5676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5676,
      "Radical": "石",
      "FrequencyRank": 5540,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "时尚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíshàng",
      "PrimaryDefinition": "fashion, fad, fashionable"
    },
    "Keys": {
      "Word": 9109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "加剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiājù",
      "PrimaryDefinition": "to intensify, to sharpen, to accelerate, to aggravate, to exacerbate, to embitter"
    },
    "Keys": {
      "Word": 7345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讹诈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ézhà",
      "PrimaryDefinition": "to extort under false pretenses, to blackmail, to bluff, to defraud"
    },
    "Keys": {
      "Word": 6408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "薯条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔtiáo",
      "PrimaryDefinition": "french fries, french fried potatoes, chips"
    },
    "Keys": {
      "Word": 5046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "滗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "drain"
    },
    "Keys": {
      "Hanzi": 5577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5577,
      "Radical": "水",
      "FrequencyRank": 7237,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "合约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héyuē",
      "PrimaryDefinition": "contract, booking, obligation, treaty"
    },
    "Keys": {
      "Word": 4658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "田径": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiánjìng",
      "PrimaryDefinition": "track and field, track-and-field, athlete, athletics, sport"
    },
    "Keys": {
      "Word": 5085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "披露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīlù",
      "PrimaryDefinition": "to reveal, to publish, to make public, to announce"
    },
    "Keys": {
      "Word": 8505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巨型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùxíng",
      "PrimaryDefinition": "giant, enormous"
    },
    "Keys": {
      "Word": 7700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng tái",
      "PrimaryDefinition": "enter"
    },
    "Keys": {
      "Word": 4999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "影响": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngxiǎng",
      "PrimaryDefinition": "influence"
    },
    "Keys": {
      "Word": 1168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "无条件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wútiáojiàn",
      "PrimaryDefinition": "unconditional"
    },
    "Keys": {
      "Word": 9785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōng",
      "PrimaryDefinition": "culprit; murder; bad, sad"
    },
    "Keys": {
      "Hanzi": 145,
      "Word": 5197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "17.2",
      "Grade": 6,
      "GeneralStandard": 145,
      "Radical": "凵",
      "HSK": 6,
      "FrequencyRank": 1378,
      "StrokeCount": 4
    }
  },
  "崚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léng",
      "PrimaryDefinition": "hilly, steep, rugged"
    },
    "Keys": {
      "Hanzi": 4856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4856,
      "Radical": "山",
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "疑惑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíhuò",
      "PrimaryDefinition": "be perplexed, be uncertain"
    },
    "Keys": {
      "Word": 10311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǔ",
      "PrimaryDefinition": "breast, nipples; milk, suckle"
    },
    "Keys": {
      "Hanzi": 1175
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "5.7",
      "GeneralStandard": 1175,
      "Radical": "乙",
      "HSK": 6,
      "FrequencyRank": 1831,
      "StrokeCount": 8
    }
  },
  "蔊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7645,
      "Radical": "艸",
      "FrequencyRank": 8790,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "波动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōdòng",
      "PrimaryDefinition": "undulate, fluctuate"
    },
    "Keys": {
      "Word": 4343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "望远镜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngyuǎnjìng",
      "PrimaryDefinition": "telescope"
    },
    "Keys": {
      "Word": 9684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5793,
      "Radical": "米",
      "FrequencyRank": 4286,
      "RadicalIndex": "119.8",
      "StrokeCount": 14
    }
  },
  "嬷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mā",
      "PrimaryDefinition": "mother"
    },
    "Keys": {
      "Hanzi": 6306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6306,
      "Radical": "女",
      "FrequencyRank": 4920,
      "RadicalIndex": "38.14",
      "StrokeCount": 17
    }
  },
  "鳎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎ",
      "PrimaryDefinition": "sole"
    },
    "Keys": {
      "Hanzi": 6353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6353,
      "Radical": "魚",
      "FrequencyRank": 4080,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "莼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chún",
      "PrimaryDefinition": "an edible water plant-brasenia"
    },
    "Keys": {
      "Hanzi": 4446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4446,
      "Radical": "艸",
      "FrequencyRank": 5653,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "影视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngshì",
      "PrimaryDefinition": "movies and television"
    },
    "Keys": {
      "Word": 2102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "螱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7975,
      "Radical": "虫",
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "珍珠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnzhū",
      "PrimaryDefinition": "pearl, CL:顆|颗[ke1]"
    },
    "Keys": {
      "Word": 4198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "超过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo guò",
      "PrimaryDefinition": "overpass, overreach, outwalk, outstrip, overstep, outmarch, distance, pass, overstride, outpace, overtop, surpass, crown, exceed, overtake, preponderate, overrun, top, outrun, go_over, transcend, outrange, transgress, go past, break, excess, overhaul, overshine"
    },
    "Keys": {
      "Word": 555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "卑鄙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēibǐ",
      "PrimaryDefinition": "base, mean, contemptible, despicable"
    },
    "Keys": {
      "Word": 5550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "afraid, scared, fearful"
    },
    "Keys": {
      "Hanzi": 5589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "GeneralStandard": 5589,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 2970,
      "StrokeCount": 13
    }
  },
  "爆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "crackle, pop, burst, explode"
    },
    "Keys": {
      "Hanzi": 3471,
      "Word": 4307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.15",
      "Grade": 6,
      "GeneralStandard": 3471,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 1243,
      "StrokeCount": 19
    }
  },
  "海鲜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎixiān",
      "PrimaryDefinition": "seafood"
    },
    "Keys": {
      "Word": 2527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "浣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "to wash, to rinse"
    },
    "Keys": {
      "Hanzi": 4657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4657,
      "Radical": "水",
      "FrequencyRank": 4343,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "鉴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "mirror, looking glass; reflect"
    },
    "Keys": {
      "Hanzi": 2894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "GeneralStandard": 2894,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 1655,
      "StrokeCount": 13
    }
  },
  "污染": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūrǎn",
      "PrimaryDefinition": "pollute, contaminate"
    },
    "Keys": {
      "Word": 4016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "萍水相逢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngshuǐ-xiāngféng",
      "PrimaryDefinition": "strangers coming together by chance (idiom)"
    },
    "Keys": {
      "Word": 8562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎn",
      "PrimaryDefinition": "go in hurry, hasten; urge"
    },
    "Keys": {
      "Hanzi": 6478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6478,
      "Radical": "走",
      "FrequencyRank": 6578,
      "RadicalIndex": "156.16",
      "StrokeCount": 23
    }
  },
  "呤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìng",
      "PrimaryDefinition": "purine"
    },
    "Keys": {
      "Hanzi": 3915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3915,
      "Radical": "口",
      "FrequencyRank": 4807,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "异议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyì",
      "PrimaryDefinition": "remonstrance, dissent, expostulation, exception, dissidence, protestation, demur, objection, demurrer"
    },
    "Keys": {
      "Word": 10364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "退回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì huí",
      "PrimaryDefinition": "to return (an item), to send back, to go back"
    },
    "Keys": {
      "Word": 9604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敬意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngyì",
      "PrimaryDefinition": "respect, tribute"
    },
    "Keys": {
      "Word": 7648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rēng",
      "PrimaryDefinition": "throw, hurl; throw away, cast"
    },
    "Keys": {
      "Hanzi": 203,
      "Word": 3846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.2",
      "Grade": 5,
      "GeneralStandard": 203,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1905,
      "StrokeCount": 5
    }
  },
  "留意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú yì",
      "PrimaryDefinition": "be careful, watch, see, insure, see to it, pay attention to, keep one's eyes open, take notice, listen, heed, mind, ensure, advert, assure, control, look out, check, ascertain, regard"
    },
    "Keys": {
      "Word": 8095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "banana; plantain"
    },
    "Keys": {
      "Hanzi": 3209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.12",
      "GeneralStandard": 3209,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 3104,
      "StrokeCount": 15
    }
  },
  "窭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "poor, impoverished"
    },
    "Keys": {
      "Hanzi": 7735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7735,
      "Radical": "穴",
      "FrequencyRank": 5870,
      "RadicalIndex": "116.9",
      "StrokeCount": 14
    }
  },
  "茧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "cocoon; callus, blister"
    },
    "Keys": {
      "Hanzi": 1409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1409,
      "Radical": "艸",
      "FrequencyRank": 3608,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "江": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "large river; yangzi; surname"
    },
    "Keys": {
      "Hanzi": 538,
      "Word": 2609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.3",
      "Grade": 4,
      "GeneralStandard": 538,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 577,
      "StrokeCount": 6
    }
  },
  "苷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "licorice"
    },
    "Keys": {
      "Hanzi": 3854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3854,
      "Radical": "艸",
      "FrequencyRank": 4476,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "震惊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènjīng",
      "PrimaryDefinition": "shock, amaze, astonish"
    },
    "Keys": {
      "Word": 4205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "惺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": "intelligent, clever, astute"
    },
    "Keys": {
      "Hanzi": 5295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5295,
      "Radical": "心",
      "FrequencyRank": 3357,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "book, letter, document; writings"
    },
    "Keys": {
      "Hanzi": 188,
      "Word": 338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "2.3",
      "Grade": 1,
      "GeneralStandard": 188,
      "Radical": "丨",
      "HSK": 1,
      "FrequencyRank": 282,
      "StrokeCount": 4
    }
  },
  "诅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ",
      "PrimaryDefinition": "curse; swear, pledge"
    },
    "Keys": {
      "Hanzi": 3796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3796,
      "Radical": "言",
      "FrequencyRank": 3171,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "另外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìngwài",
      "PrimaryDefinition": "conj.: in addition, besides, moreover"
    },
    "Keys": {
      "Word": 1718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "氰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "cyanogen; ethane dinitrile"
    },
    "Keys": {
      "Hanzi": 5193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5193,
      "Radical": "气",
      "FrequencyRank": 4463,
      "RadicalIndex": "84.8",
      "StrokeCount": 12
    }
  },
  "胸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōng",
      "PrimaryDefinition": "breast, bosom, chest; thorax"
    },
    "Keys": {
      "Hanzi": 1996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 1996,
      "Radical": "肉",
      "HSK": 4,
      "FrequencyRank": 1356,
      "StrokeCount": 10
    }
  },
  "乍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "first time, for the first time"
    },
    "Keys": {
      "Hanzi": 266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 266,
      "Radical": "丿",
      "FrequencyRank": 2914,
      "RadicalIndex": "4.4",
      "StrokeCount": 5
    }
  },
  "补习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔxí",
      "PrimaryDefinition": "take lessons after school/work"
    },
    "Keys": {
      "Word": 4350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夸耀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāyào",
      "PrimaryDefinition": "to brag about, to flaunt"
    },
    "Keys": {
      "Word": 7870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "indulge; excess; numeral four; particle meaning now, therefore; shop"
    },
    "Keys": {
      "Hanzi": 2831
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "129.7",
      "GeneralStandard": 2831,
      "Radical": "聿",
      "HSK": 8,
      "FrequencyRank": 2445,
      "StrokeCount": 13
    }
  },
  "胡闹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "húnào",
      "PrimaryDefinition": "to act willfully and make a scene, to make trouble"
    },
    "Keys": {
      "Word": 7114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4370,
      "Radical": "示",
      "FrequencyRank": 6020,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "迷惑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míhuo",
      "PrimaryDefinition": "puzzle, confuse, baffle"
    },
    "Keys": {
      "Word": 8255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngjī",
      "PrimaryDefinition": "camera"
    },
    "Keys": {
      "Word": 1088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "泰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "great, exalted, superior; big"
    },
    "Keys": {
      "Hanzi": 1771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1771,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 1318,
      "StrokeCount": 10
    }
  },
  "漴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7728,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng",
      "PrimaryDefinition": "prove, confirm, verify; proof"
    },
    "Keys": {
      "Hanzi": 885,
      "Word": 2151
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "Grade": 3,
      "GeneralStandard": 885,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 373,
      "StrokeCount": 7
    }
  },
  "避暑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì shǔ",
      "PrimaryDefinition": "to be away for the summer holidays, to spend a holiday at a summer resort, to prevent sunstroke"
    },
    "Keys": {
      "Word": 5594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "loud clamor; sound of wailing"
    },
    "Keys": {
      "Hanzi": 5434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5434,
      "Radical": "口",
      "FrequencyRank": 4104,
      "RadicalIndex": "30.11",
      "StrokeCount": 13
    }
  },
  "战士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànshì",
      "PrimaryDefinition": "fighter, soldier, warrior, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "刁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "tricky, sly, crafty, cunning"
    },
    "Keys": {
      "Hanzi": 16
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18",
      "GeneralStandard": 16,
      "Radical": "刀",
      "HSK": 9,
      "FrequencyRank": 3635,
      "StrokeCount": 2
    }
  },
  "原装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánzhuāng",
      "PrimaryDefinition": "genuine, intact in original packaging (not locally assembled and packaged)"
    },
    "Keys": {
      "Word": 10544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "Yi (nationality); tripod, wine vessel; rule"
    },
    "Keys": {
      "Hanzi": 6364
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6364,
      "Radical": "彐",
      "FrequencyRank": 3554,
      "RadicalIndex": "58.15",
      "StrokeCount": 18
    }
  },
  "奘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàng",
      "PrimaryDefinition": "large, powerful, stout, thick"
    },
    "Keys": {
      "Hanzi": 4683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4683,
      "Radical": "大",
      "FrequencyRank": 4545,
      "RadicalIndex": "37.7",
      "StrokeCount": 10
    }
  },
  "了结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎojié",
      "PrimaryDefinition": "to settle, to finish, to conclude, to wind up"
    },
    "Keys": {
      "Word": 8047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "简历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnlì",
      "PrimaryDefinition": "curriculum vitae (CV), résumé, biographical notes"
    },
    "Keys": {
      "Word": 2605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "打破": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎpo",
      "PrimaryDefinition": "shiver, better, explode, smash, crush, fall apart, discomfit, part, shatter, force, murder, wear, break, bust, drub, wear out"
    },
    "Keys": {
      "Word": 1382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "帖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiē",
      "PrimaryDefinition": "invitation card; notice"
    },
    "Keys": {
      "Hanzi": 1107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.5",
      "GeneralStandard": 1107,
      "Radical": "巾",
      "HSK": 9,
      "FrequencyRank": 2892,
      "StrokeCount": 8
    }
  },
  "带领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàilǐng",
      "PrimaryDefinition": "lead (army/party/etc.), guide"
    },
    "Keys": {
      "Word": 1394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "政": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng",
      "PrimaryDefinition": "government, political affairs"
    },
    "Keys": {
      "Hanzi": 1376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "66.4",
      "GeneralStandard": 1376,
      "Radical": "攴",
      "HSK": 4,
      "FrequencyRank": 150,
      "StrokeCount": 9
    }
  },
  "干预": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānyù",
      "PrimaryDefinition": "intervene, interfere"
    },
    "Keys": {
      "Word": 3480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "赋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "tax; give; endow; army; diffuse"
    },
    "Keys": {
      "Hanzi": 2650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.8",
      "GeneralStandard": 2650,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 1747,
      "StrokeCount": 12
    }
  },
  "煅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5562
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5562,
      "Radical": "火",
      "FrequencyRank": 5608,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "嵖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "name of a mountain; (Cant.) to obstruct"
    },
    "Keys": {
      "Hanzi": 5164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5164,
      "Radical": "山",
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "滑雪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá xuě",
      "PrimaryDefinition": "to ski, skiing"
    },
    "Keys": {
      "Word": 7138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宽泛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānfàn",
      "PrimaryDefinition": "wide-ranging"
    },
    "Keys": {
      "Word": 7878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "区分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūfēn",
      "PrimaryDefinition": "section, demarcate, compart, sectionalize, zone, screen, compartmentalize, individuate, differentiation, distinguish, mark off, secern, district, segment, separate, mark, sieve, differentiate, mark out, classify, divide, ramify, screen out, set apart, sort"
    },
    "Keys": {
      "Word": 4957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔ",
      "PrimaryDefinition": "long time (ago); time passage, grow late"
    },
    "Keys": {
      "Hanzi": 47,
      "Word": 1647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "4.2",
      "Grade": 3,
      "GeneralStandard": 47,
      "Radical": "丿",
      "HSK": 2,
      "FrequencyRank": 637,
      "StrokeCount": 3
    }
  },
  "反倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎndào",
      "PrimaryDefinition": "conj.: on the contrary, instead"
    },
    "Keys": {
      "Word": 6469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèn",
      "PrimaryDefinition": "extend across, through; from"
    },
    "Keys": {
      "Hanzi": 3585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3585,
      "Radical": "二",
      "FrequencyRank": 3874,
      "RadicalIndex": "7.4",
      "StrokeCount": 6
    }
  },
  "蚶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hān",
      "PrimaryDefinition": "kind of clam, arca inflata"
    },
    "Keys": {
      "Hanzi": 4831
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4831,
      "Radical": "虫",
      "FrequencyRank": 5725,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "狙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "an ape, monkey; to spy, watch for; to lie"
    },
    "Keys": {
      "Hanzi": 3985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3985,
      "Radical": "犬",
      "FrequencyRank": 3621,
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "垡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "plow soil; place name"
    },
    "Keys": {
      "Hanzi": 4261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4261,
      "Radical": "土",
      "FrequencyRank": 5671,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "祃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mà",
      "PrimaryDefinition": "a sacrifice at the beginning of a military campaign"
    },
    "Keys": {
      "Hanzi": 6624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6624,
      "Radical": "示",
      "FrequencyRank": 8319,
      "RadicalIndex": "113.3",
      "StrokeCount": 7
    }
  },
  "代理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàilǐ",
      "PrimaryDefinition": "act as agent/proxy"
    },
    "Keys": {
      "Word": 3393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "前年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánnián",
      "PrimaryDefinition": "the year before last"
    },
    "Keys": {
      "Word": 927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鐾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6461,
      "Radical": "金",
      "FrequencyRank": 5752,
      "RadicalIndex": "167.13",
      "StrokeCount": 21
    }
  },
  "厚度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòudù",
      "PrimaryDefinition": "thickness"
    },
    "Keys": {
      "Word": 7104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "适": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "go, reach; appropriate, suitable"
    },
    "Keys": {
      "Hanzi": 1546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "GeneralStandard": 1546,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 663,
      "StrokeCount": 9
    }
  },
  "郫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4580
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4580,
      "Radical": "邑",
      "FrequencyRank": 6636,
      "RadicalIndex": "163.8",
      "StrokeCount": 10
    }
  },
  "珺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "beautiful jade"
    },
    "Keys": {
      "Hanzi": 7105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7105,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "庶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "numerous, various; multitude"
    },
    "Keys": {
      "Hanzi": 2378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2378,
      "Radical": "广",
      "FrequencyRank": 3016,
      "RadicalIndex": "53.8",
      "StrokeCount": 11
    }
  },
  "优秀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuxiù",
      "PrimaryDefinition": "outstanding, excellent"
    },
    "Keys": {
      "Word": 3116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "弼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "aid, assist, help; correct"
    },
    "Keys": {
      "Hanzi": 5317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5317,
      "Radical": "弓",
      "FrequencyRank": 3647,
      "RadicalIndex": "57.9",
      "StrokeCount": 12
    }
  },
  "罹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "sorrow, grief; incur, meet with"
    },
    "Keys": {
      "Hanzi": 6109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6109,
      "Radical": "网",
      "FrequencyRank": 4168,
      "RadicalIndex": "122.11",
      "StrokeCount": 16
    }
  },
  "试用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyòng",
      "PrimaryDefinition": "try out"
    },
    "Keys": {
      "Word": 9148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùshǎo",
      "PrimaryDefinition": "det.: a lot of"
    },
    "Keys": {
      "Word": 536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "吵架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎo jià",
      "PrimaryDefinition": "quarrel, have a row/spat"
    },
    "Keys": {
      "Word": 1344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "孖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mā",
      "PrimaryDefinition": "twins"
    },
    "Keys": {
      "Hanzi": 6552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6552,
      "Radical": "子",
      "RadicalIndex": "39.3",
      "StrokeCount": 6
    }
  },
  "播放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōfàng",
      "PrimaryDefinition": "broadcast, transmit"
    },
    "Keys": {
      "Word": 1312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "可乘之机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěchéngzhījī",
      "PrimaryDefinition": "Can"
    },
    "Keys": {
      "Word": 7795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电视机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànshìjī",
      "PrimaryDefinition": "set, idiot_box, tv, goggle-box, idiot box, television receiver/set, teleset, television set, television receiver, TV set, goggle_box, tv set, goggle box, boob tube, receiver, telly, television"
    },
    "Keys": {
      "Word": 79
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "舍不得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěbude",
      "PrimaryDefinition": "be grudge doing sth., spare, begrudge, loathe to part with"
    },
    "Keys": {
      "Word": 3871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "小区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoqū",
      "PrimaryDefinition": "neighborhood, district"
    },
    "Keys": {
      "Word": 9971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "张贴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāngtiē",
      "PrimaryDefinition": "to post (a notice), to advertise"
    },
    "Keys": {
      "Word": 10650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "ramble, play about; leisurely; surname"
    },
    "Keys": {
      "Hanzi": 4411
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4411,
      "Radical": "攴",
      "FrequencyRank": 3573,
      "RadicalIndex": "66.7",
      "StrokeCount": 10
    }
  },
  "反省": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnxǐng",
      "PrimaryDefinition": "introspect, self-questioning"
    },
    "Keys": {
      "Word": 6477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "overflow, spill over, spread out"
    },
    "Keys": {
      "Hanzi": 1582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "144.3",
      "GeneralStandard": 1582,
      "Radical": "行",
      "HSK": 9,
      "FrequencyRank": 2437,
      "StrokeCount": 9
    }
  },
  "长足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángzú",
      "PrimaryDefinition": "remarkable (progress, improvement, expansion etc)"
    },
    "Keys": {
      "Word": 5813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5878,
      "Radical": "艸",
      "FrequencyRank": 6950,
      "RadicalIndex": "140.13",
      "StrokeCount": 15
    }
  },
  "产量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnliàng",
      "PrimaryDefinition": "turnout, turnoff, outturn, capacity, output, produce, product, fruitage, throughput, fruit, production, yield, crop"
    },
    "Keys": {
      "Word": 4385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "自助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìzhù",
      "PrimaryDefinition": "bootstrap, self-service, self-helf"
    },
    "Keys": {
      "Word": 10972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衬托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèntuō",
      "PrimaryDefinition": "to set off"
    },
    "Keys": {
      "Word": 5860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鬶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7983,
      "Radical": "鬲",
      "FrequencyRank": 8827,
      "RadicalIndex": "193.8",
      "StrokeCount": 18
    }
  },
  "要不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàobù",
      "PrimaryDefinition": "otherwise, or else, how about...?, either... (or...)"
    },
    "Keys": {
      "Word": 10244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "courtyard; spacious hall or yard"
    },
    "Keys": {
      "Hanzi": 1624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.7",
      "GeneralStandard": 1624,
      "Radical": "广",
      "HSK": 2,
      "FrequencyRank": 931,
      "StrokeCount": 9
    }
  },
  "前方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánfāng",
      "PrimaryDefinition": "ahead, the front"
    },
    "Keys": {
      "Word": 4929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèn",
      "PrimaryDefinition": "ask about, inquire after"
    },
    "Keys": {
      "Hanzi": 528,
      "Word": 376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.3",
      "Grade": 1,
      "GeneralStandard": 528,
      "Radical": "門",
      "HSK": 1,
      "FrequencyRank": 137,
      "StrokeCount": 6
    }
  },
  "邨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūn",
      "PrimaryDefinition": "village, hamlet; rustic"
    },
    "Keys": {
      "Hanzi": 6530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6530,
      "Radical": "邑",
      "FrequencyRank": 5514,
      "RadicalIndex": "163.4",
      "StrokeCount": 6
    }
  },
  "钆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gá",
      "PrimaryDefinition": "gadolinium"
    },
    "Keys": {
      "Hanzi": 6536
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6536,
      "Radical": "金",
      "FrequencyRank": 6410,
      "RadicalIndex": "167.1",
      "StrokeCount": 6
    }
  },
  "僰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "ancient aboriginal tribe"
    },
    "Keys": {
      "Hanzi": 5671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5671,
      "Radical": "人",
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "坊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 647,
      "Radical": "土",
      "FrequencyRank": 2542,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "河流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héliú",
      "PrimaryDefinition": "freshet, rivers, river"
    },
    "Keys": {
      "Word": 7043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "截": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "cut off, stop, obstruct, intersect"
    },
    "Keys": {
      "Hanzi": 3059,
      "Word": 7530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.1",
      "Grade": 7,
      "GeneralStandard": 3059,
      "Radical": "戈",
      "HSK": 6,
      "FrequencyRank": 1552,
      "StrokeCount": 14
    }
  },
  "蛋糕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngāo",
      "PrimaryDefinition": "cake, CL:塊|块[kuai4],個|个[ge4]"
    },
    "Keys": {
      "Word": 3400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "黑客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēikè",
      "PrimaryDefinition": "hacker (computing) (loanword)"
    },
    "Keys": {
      "Word": 7058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "anvil; flat stone; exe"
    },
    "Keys": {
      "Hanzi": 4476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4476,
      "Radical": "石",
      "FrequencyRank": 4371,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "新房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnfáng",
      "PrimaryDefinition": "brand new house, bridal chamber"
    },
    "Keys": {
      "Word": 10029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "知道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīdao",
      "PrimaryDefinition": "tell, have, ken, understand, infer, wis, learn, cognize, gather, know, be aware of, wise up, savvy, mind, realize"
    },
    "Keys": {
      "Word": 471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "辐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "spokes of wheel"
    },
    "Keys": {
      "Hanzi": 2888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.9",
      "GeneralStandard": 2888,
      "Radical": "車",
      "HSK": 7,
      "FrequencyRank": 2428,
      "StrokeCount": 13
    }
  },
  "接通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē tōng",
      "PrimaryDefinition": "to connect, to put through"
    },
    "Keys": {
      "Word": 7512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "a kind of monkey"
    },
    "Keys": {
      "Hanzi": 4602
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4602,
      "Radical": "犬",
      "FrequencyRank": 6244,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "种族": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒngzú",
      "PrimaryDefinition": "tribe, citizenry, race, nation, phyle, stem, stirp, phylon, ethnos, nationality, ethnic, folk, people, stirps, racial, ethnic group, family"
    },
    "Keys": {
      "Word": 10825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎo",
      "PrimaryDefinition": "to discuss; ask for, beg; demand; dun; marry"
    },
    "Keys": {
      "Hanzi": 315,
      "Word": 9445
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.3",
      "Grade": 7,
      "GeneralStandard": 315,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 833,
      "StrokeCount": 5
    }
  },
  "尤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "especially, particularly"
    },
    "Keys": {
      "Hanzi": 98
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "43.1",
      "GeneralStandard": 98,
      "Radical": "尢",
      "HSK": 5,
      "FrequencyRank": 1099,
      "StrokeCount": 4
    }
  },
  "闯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuǎng",
      "PrimaryDefinition": "rush in, burst in, charge in"
    },
    "Keys": {
      "Hanzi": 529,
      "Word": 3356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.3",
      "Grade": 5,
      "GeneralStandard": 529,
      "Radical": "門",
      "HSK": 5,
      "FrequencyRank": 2129,
      "StrokeCount": 6
    }
  },
  "毛衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máoyī",
      "PrimaryDefinition": "(wool) sweater, CL:件[jian4]"
    },
    "Keys": {
      "Word": 2725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "小孩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoháir",
      "PrimaryDefinition": "child, erhua variant of 小孩[xiao3 hai2]"
    },
    "Keys": {
      "Word": 399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "獴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "mongoose"
    },
    "Keys": {
      "Hanzi": 6147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6147,
      "Radical": "犬",
      "RadicalIndex": "94.14",
      "StrokeCount": 16
    }
  },
  "地图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìtú",
      "PrimaryDefinition": "map"
    },
    "Keys": {
      "Word": 72
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "甚至": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shènzhì",
      "PrimaryDefinition": "even (to the point of), so much so that"
    },
    "Keys": {
      "Word": 2855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "闳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "gate, barrier; wide, vast, expand"
    },
    "Keys": {
      "Hanzi": 3762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3762,
      "Radical": "門",
      "FrequencyRank": 5087,
      "RadicalIndex": "169.4",
      "StrokeCount": 7
    }
  },
  "按照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànzhào",
      "PrimaryDefinition": "according to, in the light of, on the basis of"
    },
    "Keys": {
      "Word": 1264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "奶茶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎichá",
      "PrimaryDefinition": "milk tea"
    },
    "Keys": {
      "Word": 1758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "促": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "urge, press, hurry; close"
    },
    "Keys": {
      "Hanzi": 1563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1563,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 1102,
      "StrokeCount": 9
    }
  },
  "冷静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngjìng",
      "PrimaryDefinition": "sober, calm"
    },
    "Keys": {
      "Word": 2691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "狭窄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiázhǎi",
      "PrimaryDefinition": "narrow, bottleneck, narrow and limited, cramped"
    },
    "Keys": {
      "Word": 9854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "秧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": "rice seedlings, young rice plants"
    },
    "Keys": {
      "Hanzi": 1946
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "GeneralStandard": 1946,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 3522,
      "StrokeCount": 10
    }
  },
  "雉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "pheasant; crenellated wall"
    },
    "Keys": {
      "Hanzi": 5493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5493,
      "Radical": "隹",
      "FrequencyRank": 4559,
      "RadicalIndex": "172.5",
      "StrokeCount": 13
    }
  },
  "比喻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐyù",
      "PrimaryDefinition": "metaphor, analogy"
    },
    "Keys": {
      "Word": 5585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "to unravel or unreel silk; to interpret"
    },
    "Keys": {
      "Hanzi": 1349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "GeneralStandard": 1349,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 3015,
      "StrokeCount": 8
    }
  },
  "出行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūxíng",
      "PrimaryDefinition": "to set out on a long journey, to travel afar"
    },
    "Keys": {
      "Word": 4435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "上调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngdiào",
      "PrimaryDefinition": "to raise (prices), to adjust upwards"
    },
    "Keys": {
      "Word": 8981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斩草除根": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎncǎo-chúgēn",
      "PrimaryDefinition": "to cut weeds and eliminate the roots (idiom), to destroy root and branch, to eliminate completely"
    },
    "Keys": {
      "Word": 10639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūn",
      "PrimaryDefinition": "gatekeeper; gate, door"
    },
    "Keys": {
      "Hanzi": 4950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4950,
      "Radical": "門",
      "FrequencyRank": 5275,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "椰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yē",
      "PrimaryDefinition": "palm tree, coconut palm"
    },
    "Keys": {
      "Hanzi": 2565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.9",
      "GeneralStandard": 2565,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 3932,
      "StrokeCount": 12
    }
  },
  "逑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "collect, unite; match, pair"
    },
    "Keys": {
      "Hanzi": 4464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4464,
      "Radical": "辵",
      "FrequencyRank": 5854,
      "RadicalIndex": "162.7",
      "StrokeCount": 10
    }
  },
  "体温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐwēn",
      "PrimaryDefinition": "(body) temperature"
    },
    "Keys": {
      "Word": 9468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sā",
      "PrimaryDefinition": "to feel or fondle with the fingers"
    },
    "Keys": {
      "Hanzi": 4971
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4971,
      "Radical": "手",
      "FrequencyRank": 5230,
      "RadicalIndex": "64.7",
      "StrokeCount": 11
    }
  },
  "胡子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "húzi",
      "PrimaryDefinition": "beard, mustache or whiskers, facial hair, CL:撮[zuo3],根[gen1], (coll.) bandit"
    },
    "Keys": {
      "Word": 3545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "eye"
    },
    "Keys": {
      "Radical": 109,
      "Hanzi": 236
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109",
      "GeneralStandard": 236,
      "Radical": "目",
      "HSK": 2,
      "FrequencyRank": 239,
      "StrokeCount": 5
    }
  },
  "概率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàilǜ",
      "PrimaryDefinition": "probability (math.)"
    },
    "Keys": {
      "Word": 6662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓷器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíqì",
      "PrimaryDefinition": "porcelain, chinaware"
    },
    "Keys": {
      "Word": 6036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轻松": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngsōng",
      "PrimaryDefinition": "light, relaxed"
    },
    "Keys": {
      "Word": 2807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "甄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "to examine, discern; to grade; a surname"
    },
    "Keys": {
      "Hanzi": 5411
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5411,
      "Radical": "瓦",
      "FrequencyRank": 3933,
      "RadicalIndex": "98.9",
      "StrokeCount": 13
    }
  },
  "残疾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánji",
      "PrimaryDefinition": "disabled, handicapped, deformity on a person or animal"
    },
    "Keys": {
      "Word": 4368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "凌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "pure; virtuous; insult; maltreat"
    },
    "Keys": {
      "Hanzi": 2014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.8",
      "GeneralStandard": 2014,
      "Radical": "冫",
      "HSK": 7,
      "FrequencyRank": 1731,
      "StrokeCount": 10
    }
  },
  "绑架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎngjià",
      "PrimaryDefinition": "to kidnap, to abduct, to hijack, a kidnapping, abduction, staking"
    },
    "Keys": {
      "Word": 5507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "官": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "official, public servant"
    },
    "Keys": {
      "Hanzi": 1281,
      "Word": 2515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "Grade": 4,
      "GeneralStandard": 1281,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 432,
      "StrokeCount": 8
    }
  },
  "犊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "calf; victim of sacrifice"
    },
    "Keys": {
      "Hanzi": 5196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5196,
      "Radical": "牛",
      "FrequencyRank": 3915,
      "RadicalIndex": "93.8",
      "StrokeCount": 12
    }
  },
  "堰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "dam; embankment, dike, bank"
    },
    "Keys": {
      "Hanzi": 2512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2512,
      "Radical": "土",
      "FrequencyRank": 3486,
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "即": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "promptly, quickly, immediately"
    },
    "Keys": {
      "Hanzi": 901,
      "Word": 7298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "26.5",
      "Grade": 7,
      "GeneralStandard": 901,
      "Radical": "卩",
      "HSK": 4,
      "FrequencyRank": 293,
      "StrokeCount": 7
    }
  },
  "家教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiājiào",
      "PrimaryDefinition": "tutor"
    },
    "Keys": {
      "Word": 7351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7598,
      "Radical": "心",
      "RadicalIndex": "61.1",
      "StrokeCount": 13
    }
  },
  "失落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīluò",
      "PrimaryDefinition": "lose"
    },
    "Keys": {
      "Word": 9088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róu",
      "PrimaryDefinition": "blend, mix; mixed"
    },
    "Keys": {
      "Hanzi": 5993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5993,
      "Radical": "米",
      "FrequencyRank": 5544,
      "RadicalIndex": "119.9",
      "StrokeCount": 15
    }
  },
  "冷漠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngmò",
      "PrimaryDefinition": "cold and detached, unconcerned, indifferent"
    },
    "Keys": {
      "Word": 7970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "明日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngrì",
      "PrimaryDefinition": "tomorrow"
    },
    "Keys": {
      "Word": 4874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "明星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngxīng",
      "PrimaryDefinition": "headliner, lucida, (movie/etc.) star, impersonator, (movie, famous performer, stardom, Venus, star, etc.) star, movie_star"
    },
    "Keys": {
      "Word": 882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "厌倦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànjuàn",
      "PrimaryDefinition": "to be weary of, to be fed up with, tedious"
    },
    "Keys": {
      "Word": 10208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "男生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánshēng",
      "PrimaryDefinition": "brother, sonny boy, man, male, laddie, lad, boy student, male student, boy, schoolboy, sonny, cub, man student"
    },
    "Keys": {
      "Word": 261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "概": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "generally, approximately"
    },
    "Keys": {
      "Hanzi": 2871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.9",
      "GeneralStandard": 2871,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 791,
      "StrokeCount": 13
    }
  },
  "黄瓜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánggua",
      "PrimaryDefinition": "cucumber, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 2556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "茅台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Máotái",
      "PrimaryDefinition": "Maotai town in Renhuai county, Guizhou, Maotai liquor 茅臺酒|茅台酒"
    },
    "Keys": {
      "Word": 8209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měi",
      "PrimaryDefinition": "beautiful, pretty; pleasing"
    },
    "Keys": {
      "Hanzi": 1642,
      "Word": 1736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "123.3",
      "Grade": 3,
      "GeneralStandard": 1642,
      "Radical": "羊",
      "HSK": 3,
      "FrequencyRank": 151,
      "StrokeCount": 9
    }
  },
  "操心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāo xīn",
      "PrimaryDefinition": "to worry about"
    },
    "Keys": {
      "Word": 5770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "既然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìrán",
      "PrimaryDefinition": "conj.: since, as, now that"
    },
    "Keys": {
      "Word": 2592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "拗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎo",
      "PrimaryDefinition": "to pull, drag, break off, to pluck (a flower); bent, warped; perverse, obstinate"
    },
    "Keys": {
      "Hanzi": 995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 995,
      "Radical": "手",
      "FrequencyRank": 3629,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "翮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "stem of feather; quill"
    },
    "Keys": {
      "Hanzi": 6067
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6067,
      "Radical": "羽",
      "FrequencyRank": 6716,
      "RadicalIndex": "124.1",
      "StrokeCount": 16
    }
  },
  "大使馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshǐguǎn",
      "PrimaryDefinition": "embassy, CL:座[zuo4],個|个[ge4]"
    },
    "Keys": {
      "Word": 1385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "笃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔ",
      "PrimaryDefinition": "deep, true, sincere, genuine"
    },
    "Keys": {
      "Hanzi": 4255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4255,
      "Radical": "竹",
      "FrequencyRank": 3207,
      "RadicalIndex": "118.3",
      "StrokeCount": 9
    }
  },
  "祓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "exorcise, remove evil; clean"
    },
    "Keys": {
      "Hanzi": 4366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4366,
      "Radical": "示",
      "FrequencyRank": 6423,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "冕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "crown; ceremonial cap"
    },
    "Keys": {
      "Hanzi": 4825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4825,
      "Radical": "冂",
      "FrequencyRank": 3456,
      "RadicalIndex": "13.9",
      "StrokeCount": 11
    }
  },
  "详细": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiángxì",
      "PrimaryDefinition": "detailed, minute"
    },
    "Keys": {
      "Word": 4042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "隐蔽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnbì",
      "PrimaryDefinition": "to conceal, to hide, covert, under cover"
    },
    "Keys": {
      "Word": 10397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "prepare, ready, perfect"
    },
    "Keys": {
      "Hanzi": 1200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "34.5",
      "GeneralStandard": 1200,
      "Radical": "夂",
      "HSK": 1,
      "FrequencyRank": 397,
      "StrokeCount": 8
    }
  },
  "睇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "look at, glance at; stare at"
    },
    "Keys": {
      "Hanzi": 5125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5125,
      "Radical": "目",
      "FrequencyRank": 5521,
      "RadicalIndex": "109.7",
      "StrokeCount": 12
    }
  },
  "言语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yányu",
      "PrimaryDefinition": "spoken language, speech"
    },
    "Keys": {
      "Word": 4090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "筌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "bamboo fish trap"
    },
    "Keys": {
      "Hanzi": 5207
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5207,
      "Radical": "竹",
      "FrequencyRank": 6087,
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "沱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "rivers, streams, waterways; flow"
    },
    "Keys": {
      "Hanzi": 4021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4021,
      "Radical": "水",
      "FrequencyRank": 4382,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "恋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "love; long for, yearn for; love"
    },
    "Keys": {
      "Hanzi": 2016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 2016,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1464,
      "StrokeCount": 10
    }
  },
  "大道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàdào",
      "PrimaryDefinition": "wide road, the way of virtue and justice"
    },
    "Keys": {
      "Word": 4472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胜负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng-fù",
      "PrimaryDefinition": "victory or defeat, the outcome of a battle"
    },
    "Keys": {
      "Word": 3892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "关头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāntóu",
      "PrimaryDefinition": "juncture, moment"
    },
    "Keys": {
      "Word": 6868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高超": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāochāo",
      "PrimaryDefinition": "excellent, superlative"
    },
    "Keys": {
      "Word": 6693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风尚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngshàng",
      "PrimaryDefinition": "current custom, current way of doing things"
    },
    "Keys": {
      "Word": 6576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "终究": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngjiū",
      "PrimaryDefinition": "eventually, in the end, after all"
    },
    "Keys": {
      "Word": 10822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舌头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shétou",
      "PrimaryDefinition": "lingua, clack, tongue, clapper, ability to talk, glossa, enemy soldier captured to extract inform, enemy soldier captured to extract information, an enemy soldier captured for the purpose of extra"
    },
    "Keys": {
      "Word": 5001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "脉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mài",
      "PrimaryDefinition": "blood vessels, veins, arteries"
    },
    "Keys": {
      "Hanzi": 1597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 1597,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 1594,
      "StrokeCount": 9
    }
  },
  "压缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāsuō",
      "PrimaryDefinition": "reduce, pack together, compact, encapsulate, cut down, compressed, constrict, reduction, shrink, condensation, compress, condense, strangulate, compression"
    },
    "Keys": {
      "Word": 10158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "幅度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúdù",
      "PrimaryDefinition": "amplitude, breadth, extent, spread, range, spectrum, scope"
    },
    "Keys": {
      "Word": 3470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "好心人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoxīnrén",
      "PrimaryDefinition": "Good people"
    },
    "Keys": {
      "Word": 7012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnfèn",
      "PrimaryDefinition": "(to play) one's part, one's role, one's duty, (to stay within) one's bounds, dutiful, keeping to one's role"
    },
    "Keys": {
      "Word": 5563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "飔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "cool breeze of autumn"
    },
    "Keys": {
      "Hanzi": 7566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7566,
      "Radical": "風",
      "FrequencyRank": 7790,
      "RadicalIndex": "182.9",
      "StrokeCount": 13
    }
  },
  "领导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngdǎo",
      "PrimaryDefinition": "lead, exercise leadership"
    },
    "Keys": {
      "Word": 1713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "灈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8087,
      "Radical": "水",
      "RadicalIndex": "85.18",
      "StrokeCount": 21
    }
  },
  "梅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "plums; prunes; surname"
    },
    "Keys": {
      "Hanzi": 2226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "GeneralStandard": 2226,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1159,
      "StrokeCount": 11
    }
  },
  "伤心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng xīn",
      "PrimaryDefinition": "injure, offend, broken-hearted, be sad, pierce, hurt, grieved, sad, spite, aggrieve, grieve, be grieved, sadden, smart, wound"
    },
    "Keys": {
      "Word": 1864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "宁可": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nìngkě",
      "PrimaryDefinition": "(would) rather, better"
    },
    "Keys": {
      "Word": 8426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嶷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "a range of mountains in Hunan province"
    },
    "Keys": {
      "Hanzi": 6240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6240,
      "Radical": "山",
      "FrequencyRank": 6333,
      "RadicalIndex": "46.14",
      "StrokeCount": 17
    }
  },
  "怠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "idle, remiss, negligent; neglect"
    },
    "Keys": {
      "Hanzi": 1747
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1747,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 3024,
      "StrokeCount": 9
    }
  },
  "开幕式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāimùshì",
      "PrimaryDefinition": "opening ceremony"
    },
    "Keys": {
      "Word": 3646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "持续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chíxù",
      "PrimaryDefinition": "perennate, hang on, carry_over, persist, steady, keep, carry over, keep on, proceed, stretch, carry_on, retain, sustain, persevere, continuity, endure, stay, continue, hang in, hold, maintain, run, span, go on, run for, last, go along, exist, hold on"
    },
    "Keys": {
      "Word": 1356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "迷人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mírén",
      "PrimaryDefinition": "beguile, enamor, charm, becharm, enchanting, charming, enwrap, attractive"
    },
    "Keys": {
      "Word": 3722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "告示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàoshi",
      "PrimaryDefinition": "announcement"
    },
    "Keys": {
      "Word": 6715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讥笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīxiào",
      "PrimaryDefinition": "to sneer"
    },
    "Keys": {
      "Word": 7261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无故": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúgù",
      "PrimaryDefinition": "without cause or reason"
    },
    "Keys": {
      "Word": 9767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "液体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yètǐ",
      "PrimaryDefinition": "liquid"
    },
    "Keys": {
      "Word": 10272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "低": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "low; to lower, hang, bend, bow"
    },
    "Keys": {
      "Hanzi": 781,
      "Word": 616
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 2,
      "GeneralStandard": 781,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 592,
      "StrokeCount": 7
    }
  },
  "败": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bài",
      "PrimaryDefinition": "be defeated, decline, fail"
    },
    "Keys": {
      "Hanzi": 1113,
      "Word": 2245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "Grade": 4,
      "GeneralStandard": 1113,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 862,
      "StrokeCount": 8
    }
  },
  "顶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐng",
      "PrimaryDefinition": "top, summit, peak; to carry on the head"
    },
    "Keys": {
      "Hanzi": 971,
      "Word": 2418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.2",
      "Grade": 4,
      "GeneralStandard": 971,
      "Radical": "頁",
      "HSK": 4,
      "FrequencyRank": 1000,
      "StrokeCount": 8
    }
  },
  "曷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "why? what? where?"
    },
    "Keys": {
      "Hanzi": 4198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4198,
      "Radical": "曰",
      "FrequencyRank": 5256,
      "RadicalIndex": "73.5",
      "StrokeCount": 9
    }
  },
  "惯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "habit, custom; habitually, usual"
    },
    "Keys": {
      "Hanzi": 2450,
      "Word": 6890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "Grade": 7,
      "GeneralStandard": 2450,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 1226,
      "StrokeCount": 11
    }
  },
  "教室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoshì",
      "PrimaryDefinition": "classroom, schoolroom"
    },
    "Keys": {
      "Word": 775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "准确": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔnquè",
      "PrimaryDefinition": "accurate, exact, precise"
    },
    "Keys": {
      "Word": 1238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "挤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "crowd, squeeze, push against"
    },
    "Keys": {
      "Hanzi": 1392,
      "Word": 3579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 5,
      "GeneralStandard": 1392,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1661,
      "StrokeCount": 9
    }
  },
  "生气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng qì",
      "PrimaryDefinition": "get angry, be angry"
    },
    "Keys": {
      "Word": 326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "评定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngdìng",
      "PrimaryDefinition": "to evaluate, to make one's judgment"
    },
    "Keys": {
      "Word": 8553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "ancient musical instrument"
    },
    "Keys": {
      "Hanzi": 5621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5621,
      "Radical": "玉",
      "RadicalIndex": "96.11",
      "StrokeCount": 14
    }
  },
  "跖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "sole (of the foot)"
    },
    "Keys": {
      "Hanzi": 5135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5135,
      "Radical": "足",
      "FrequencyRank": 6119,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "赙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "gift of money help pay funeral"
    },
    "Keys": {
      "Hanzi": 5728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5728,
      "Radical": "貝",
      "FrequencyRank": 6654,
      "RadicalIndex": "154.1",
      "StrokeCount": 14
    }
  },
  "诡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "deceive, cheat, defraud; sly"
    },
    "Keys": {
      "Hanzi": 1298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1298,
      "Radical": "言",
      "FrequencyRank": 2578,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "低头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī tóu",
      "PrimaryDefinition": "submission, surrender, submit, bow/hang one's head, bow, crouch, lower, yield, hang one's head, bow one's head, lower one's head"
    },
    "Keys": {
      "Word": 4498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "失望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīwàng",
      "PrimaryDefinition": "become disappointed, lose (hope/faith)"
    },
    "Keys": {
      "Word": 2860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "分红": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn hóng",
      "PrimaryDefinition": "dividend, to award a bonus"
    },
    "Keys": {
      "Word": 6536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "意图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìtú",
      "PrimaryDefinition": "intention, intent"
    },
    "Keys": {
      "Word": 10375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诺言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuòyán",
      "PrimaryDefinition": "promise"
    },
    "Keys": {
      "Word": 8447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "pig"
    },
    "Keys": {
      "Radical": 152,
      "Hanzi": 3688
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3688,
      "Radical": "豕",
      "FrequencyRank": 4953,
      "RadicalIndex": "152",
      "StrokeCount": 7
    }
  },
  "哺育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔyù",
      "PrimaryDefinition": "to feed, to nurture, to foster"
    },
    "Keys": {
      "Word": 5688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宽恕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānshù",
      "PrimaryDefinition": "to forgive, forgiveness"
    },
    "Keys": {
      "Word": 7883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欠缺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiànquē",
      "PrimaryDefinition": "lack, be deficient, be short of"
    },
    "Keys": {
      "Word": 8683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "look at, stare at, gaze at"
    },
    "Keys": {
      "Hanzi": 6085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6085,
      "Radical": "目",
      "FrequencyRank": 4018,
      "RadicalIndex": "109.11",
      "StrokeCount": 16
    }
  },
  "指责": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐzé",
      "PrimaryDefinition": "censure, criticize"
    },
    "Keys": {
      "Word": 4232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "礓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6325,
      "Radical": "石",
      "FrequencyRank": 6981,
      "RadicalIndex": "112.13",
      "StrokeCount": 18
    }
  },
  "墅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "villa, country house"
    },
    "Keys": {
      "Hanzi": 3102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.11",
      "GeneralStandard": 3102,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2901,
      "StrokeCount": 14
    }
  },
  "茺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6790,
      "Radical": "艸",
      "FrequencyRank": 7250,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "徒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "disciple, follower; go on foot"
    },
    "Keys": {
      "Hanzi": 1975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.7",
      "GeneralStandard": 1975,
      "Radical": "彳",
      "HSK": 6,
      "FrequencyRank": 1060,
      "StrokeCount": 10
    }
  },
  "念书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàn shū",
      "PrimaryDefinition": "read, study"
    },
    "Keys": {
      "Word": 8417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "fish guts"
    },
    "Keys": {
      "Hanzi": 6143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6143,
      "Radical": "魚",
      "FrequencyRank": 6565,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "考量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎoliang",
      "PrimaryDefinition": "consider, think"
    },
    "Keys": {
      "Word": 7783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "服务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúwù",
      "PrimaryDefinition": "be in the service of, serve"
    },
    "Keys": {
      "Word": 662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蟒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎng",
      "PrimaryDefinition": "python, boa constrictor"
    },
    "Keys": {
      "Hanzi": 6098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6098,
      "Radical": "虫",
      "FrequencyRank": 3696,
      "RadicalIndex": "142.13",
      "StrokeCount": 16
    }
  },
  "违规": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi guī",
      "PrimaryDefinition": "violate rule/regulation"
    },
    "Keys": {
      "Word": 4003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "徘徊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páihuái",
      "PrimaryDefinition": "perambulate, fluctuate, roll, hesitation, mosey, pace up and down, tarry, divagate, peripatetic, roam, stray, vacillate, linger, hover, rove, cast, pace back and forth, cruise, stravage, dally, maunder, wander, loiter, stroll, stooge, waver, drift, on the prowl, range, swan, hang_around, prowl, vagabond, fluctuation, ramble, hesitate, traik, skulk"
    },
    "Keys": {
      "Word": 8459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辣椒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làjiāo",
      "PrimaryDefinition": "hot pepper, chili"
    },
    "Keys": {
      "Word": 7914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "busy, pressed for time; hustling"
    },
    "Keys": {
      "Hanzi": 543,
      "Word": 227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.3",
      "Grade": 1,
      "GeneralStandard": 543,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 827,
      "StrokeCount": 6
    }
  },
  "放水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng shuǐ",
      "PrimaryDefinition": "to turn on the water, to let water out, to throw a game (sports)"
    },
    "Keys": {
      "Word": 6503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "receive, accept, get; bear, stand"
    },
    "Keys": {
      "Hanzi": 1174,
      "Word": 1931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.6",
      "Grade": 3,
      "GeneralStandard": 1174,
      "Radical": "又",
      "HSK": 2,
      "FrequencyRank": 238,
      "StrokeCount": 8
    }
  },
  "觃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "place name"
    },
    "Keys": {
      "Hanzi": 6599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6599,
      "Radical": "",
      "RadicalIndex": "",
      "StrokeCount": 7
    }
  },
  "纠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "investigate, inspect"
    },
    "Keys": {
      "Hanzi": 344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.2",
      "GeneralStandard": 344,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 1723,
      "StrokeCount": 5
    }
  },
  "废物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèiwu",
      "PrimaryDefinition": "crap, plug, wastage, dreg, crock, garbage, weed, riffraff, obsolete, ejecta, trashery, rubbish, trash, cur, chaff, offal, food waste, recrement, rejectamenta, waste matter, lumber, sullage, write-off, waste material, sordes, dud, good-for-nothing, black sheep, culch, ruck, wastefulness, slag, scapegrace, dissipation, cultch, raffle, flotsam, dreck, off-scum, no-account, waste, offscouring"
    },
    "Keys": {
      "Word": 6527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上帝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Shàngdì",
      "PrimaryDefinition": "Creator, god, Yahveh, Almighty, almighty, Godhead, Jehovah, Being, Supreme_Being, God Almighty, Maker, Allah, deity, omnipotent, king, abba, Omniscience, omniscient, God, creator, Providence, Omnipotence, Deus, providence, Divine, lording, Lord, lord, maker, Supreme Being, judge, Dominus, Heaven, All-father, Adonai, Father-God, divinity, Father"
    },
    "Keys": {
      "Word": 4998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "忱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "truth, sincerity; sincere"
    },
    "Keys": {
      "Hanzi": 875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 875,
      "Radical": "心",
      "FrequencyRank": 3328,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "忌口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì kǒu",
      "PrimaryDefinition": "abstain from certain food (as when ill), avoid certain foods, be on a diet"
    },
    "Keys": {
      "Word": 7331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "体育场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐyùchǎng",
      "PrimaryDefinition": "stadium, CL:個|个[ge4],座[zuo4]"
    },
    "Keys": {
      "Word": 1021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "友情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuqíng",
      "PrimaryDefinition": "friendly feelings, friendship"
    },
    "Keys": {
      "Word": 10469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "glazed tiles, bricks"
    },
    "Keys": {
      "Hanzi": 7976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7976,
      "Radical": "瓦",
      "FrequencyRank": 6096,
      "RadicalIndex": "98.13",
      "StrokeCount": 17
    }
  },
  "尼龙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nílóng",
      "PrimaryDefinition": "nylon (loanword)"
    },
    "Keys": {
      "Word": 8403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "和解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héjiě",
      "PrimaryDefinition": "become reconciled"
    },
    "Keys": {
      "Word": 7038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "方法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngfǎ",
      "PrimaryDefinition": "know-how, plan, attack, system, stepping-stone, manner, method, gateway, idea, measure, way, guise, key, wise, quomodo, plan of attack, tack, machinery, process, avenue, modus, how, means, channel, gate, approach, agency, gamesmanship, mode, medium, sort"
    },
    "Keys": {
      "Word": 653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "响亮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngliàng",
      "PrimaryDefinition": "loud and clear, resounding"
    },
    "Keys": {
      "Word": 9942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "传闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánwén",
      "PrimaryDefinition": "rumor"
    },
    "Keys": {
      "Word": 6011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěxiào",
      "PrimaryDefinition": "funny, ridiculous"
    },
    "Keys": {
      "Word": 7804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "财政": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáizhèng",
      "PrimaryDefinition": "finance"
    },
    "Keys": {
      "Word": 5738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "swelling; the Gulf of Hopei (Hebei)"
    },
    "Keys": {
      "Hanzi": 2754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2754,
      "Radical": "水",
      "FrequencyRank": 3762,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "affair, matter, business; to serve; accident, incident"
    },
    "Keys": {
      "Hanzi": 1038,
      "Word": 330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "6.7",
      "Grade": 1,
      "GeneralStandard": 1038,
      "Radical": "亅",
      "HSK": 1,
      "FrequencyRank": 58,
      "StrokeCount": 8
    }
  },
  "迁移": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānyí",
      "PrimaryDefinition": "move, remove, migrate"
    },
    "Keys": {
      "Word": 8651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi kǒu",
      "PrimaryDefinition": "uncork, open one's mouth, sharpen knife, vent, put the first edge on a knife, broach, speak_up, laugh out loudly, breach a dam, speak, start to talk"
    },
    "Keys": {
      "Word": 7750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "协商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiéshāng",
      "PrimaryDefinition": "consult, talk things over"
    },
    "Keys": {
      "Word": 5177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "作弊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò bì",
      "PrimaryDefinition": "indulge in corrupt practices, crib, cheat, fix, practice corruption, exam, misbehave, cheat on test, cheat on exam, corruption, practice fraud, practise fraud"
    },
    "Keys": {
      "Word": 11031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "贼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zéi",
      "PrimaryDefinition": "thief, traitor; kill"
    },
    "Keys": {
      "Hanzi": 1924,
      "Word": 10622
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "Grade": 7,
      "GeneralStandard": 1924,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 2001,
      "StrokeCount": 10
    }
  },
  "滫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǔ",
      "PrimaryDefinition": "water in which rice has been boiled"
    },
    "Keys": {
      "Hanzi": 5578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5578,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 13
    }
  },
  "主题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔtí",
      "PrimaryDefinition": "motif, lemma, musical theme, theme, thesis, idea, literary argument, argument, motive, leitmotiv, matter, subject_matter, keynote, issue, subject, topic, mythos, melodic theme"
    },
    "Keys": {
      "Word": 3191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "帮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāng",
      "PrimaryDefinition": "help, assist; defend; shoe upper"
    },
    "Keys": {
      "Hanzi": 1356,
      "Word": 13
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.7",
      "Grade": 1,
      "GeneralStandard": 1356,
      "Radical": "巾",
      "HSK": 1,
      "FrequencyRank": 769,
      "StrokeCount": 9
    }
  },
  "辨别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànbié",
      "PrimaryDefinition": "to differentiate, to distinguish, to discriminate"
    },
    "Keys": {
      "Word": 5615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "法律": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎlǜ",
      "PrimaryDefinition": "constitution, juridical, legal, jus, judiciary, statute law, law, codex, legislation, jurisprudence, lex, code, Themis, statute, judicial, practice of law, prescript, forensic, natural law"
    },
    "Keys": {
      "Word": 2449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "馍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "bread"
    },
    "Keys": {
      "Hanzi": 2984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2984,
      "Radical": "食",
      "FrequencyRank": 4070,
      "RadicalIndex": "184.1",
      "StrokeCount": 13
    }
  },
  "媭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5218,
      "Radical": "女",
      "RadicalIndex": "38.12",
      "StrokeCount": 12
    }
  },
  "骕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "name of a famous horse"
    },
    "Keys": {
      "Hanzi": 7280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7280,
      "Radical": "馬",
      "FrequencyRank": 7108,
      "RadicalIndex": "187.12",
      "StrokeCount": 11
    }
  },
  "大量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàliàng",
      "PrimaryDefinition": "det.: any numer of, a great many"
    },
    "Keys": {
      "Word": 590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "畤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "place for worshipping the haven"
    },
    "Keys": {
      "Hanzi": 7160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7160,
      "Radical": "田",
      "RadicalIndex": "102.6",
      "StrokeCount": 11
    }
  },
  "银": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "silver; cash, money, wealth"
    },
    "Keys": {
      "Hanzi": 2309,
      "Word": 2099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.6",
      "Grade": 3,
      "GeneralStandard": 2309,
      "Radical": "金",
      "HSK": 2,
      "FrequencyRank": 757,
      "StrokeCount": 11
    }
  },
  "荼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "bitter vegetable"
    },
    "Keys": {
      "Hanzi": 4436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4436,
      "Radical": "艸",
      "FrequencyRank": 3930,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "筑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "ancient lute; build"
    },
    "Keys": {
      "Hanzi": 2682,
      "Word": 10874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 7,
      "GeneralStandard": 2682,
      "Radical": "竹",
      "HSK": 5,
      "FrequencyRank": 1130,
      "StrokeCount": 12
    }
  },
  "聚精会神": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùjīng-huìshén",
      "PrimaryDefinition": "to concentrate one's attention (idiom)"
    },
    "Keys": {
      "Word": 7712
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "折扣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhékòu",
      "PrimaryDefinition": "allowance, rebate, price reduction, discount, agio, reduction, deduction, percentage"
    },
    "Keys": {
      "Word": 10688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聚集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùjí",
      "PrimaryDefinition": "gather, assemble, collect"
    },
    "Keys": {
      "Word": 7711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāng",
      "PrimaryDefinition": "syllable"
    },
    "Keys": {
      "Hanzi": 4187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4187,
      "Radical": "口",
      "FrequencyRank": 5013,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "埃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "fine dust, dirt"
    },
    "Keys": {
      "Hanzi": 1814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1814,
      "Radical": "土",
      "FrequencyRank": 1121,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "智力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìlì",
      "PrimaryDefinition": "intelligence, intellect"
    },
    "Keys": {
      "Word": 3179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "淘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "wash in sieve; weed out"
    },
    "Keys": {
      "Hanzi": 2426,
      "Word": 9438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 7,
      "GeneralStandard": 2426,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2499,
      "StrokeCount": 11
    }
  },
  "迢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": "far; distant"
    },
    "Keys": {
      "Hanzi": 1331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1331,
      "Radical": "辵",
      "FrequencyRank": 3898,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "大熊猫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxióngmāo",
      "PrimaryDefinition": "giant panda (Ailuropoda melanoleuca)"
    },
    "Keys": {
      "Word": 3387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "睥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "look askance at, glare at"
    },
    "Keys": {
      "Hanzi": 5439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5439,
      "Radical": "目",
      "FrequencyRank": 5974,
      "RadicalIndex": "109.8",
      "StrokeCount": 13
    }
  },
  "爷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yé",
      "PrimaryDefinition": "father, grandfather"
    },
    "Keys": {
      "Hanzi": 492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "88.2",
      "GeneralStandard": 492,
      "Radical": "父",
      "HSK": 1,
      "FrequencyRank": 1007,
      "StrokeCount": 6
    }
  },
  "访谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎngtán",
      "PrimaryDefinition": "interview"
    },
    "Keys": {
      "Word": 6500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鋈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "silver plating"
    },
    "Keys": {
      "Hanzi": 6002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6002,
      "Radical": "金",
      "FrequencyRank": 4499,
      "RadicalIndex": "167.7",
      "StrokeCount": 15
    }
  },
  "交集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojí",
      "PrimaryDefinition": "intersection (symbol ∩) (set theory)"
    },
    "Keys": {
      "Word": 7454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "in pain, sorrowful"
    },
    "Keys": {
      "Hanzi": 4352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4352,
      "Radical": "心",
      "FrequencyRank": 4095,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "一鼓作气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīgǔ-zuòqì",
      "PrimaryDefinition": "in a spurt of energy"
    },
    "Keys": {
      "Word": 10325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煤气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méiqì",
      "PrimaryDefinition": "coal gas, gas (fuel)"
    },
    "Keys": {
      "Word": 3718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "车票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēpiào",
      "PrimaryDefinition": "ticket (for train/bus)"
    },
    "Keys": {
      "Word": 43
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "年代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niándài",
      "PrimaryDefinition": "a decade of a century (e.g. the Sixties), age, era, period, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "独特": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dútè",
      "PrimaryDefinition": "special, distinctive, unique"
    },
    "Keys": {
      "Word": 2425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "抬头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái tóu",
      "PrimaryDefinition": "raise one's head, gain ground, improve, go up (in price)"
    },
    "Keys": {
      "Word": 3957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "英雄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngxióng",
      "PrimaryDefinition": "hero"
    },
    "Keys": {
      "Word": 5260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "营养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngyǎng",
      "PrimaryDefinition": "nutrition, nourishment"
    },
    "Keys": {
      "Word": 2101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "微观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēiguān",
      "PrimaryDefinition": "micro-, sub-atomic"
    },
    "Keys": {
      "Word": 9691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沐浴露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùyùlù",
      "PrimaryDefinition": "shower gel"
    },
    "Keys": {
      "Word": 8352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说实话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō shíhuà",
      "PrimaryDefinition": "to speak the truth, truth to tell, frankly"
    },
    "Keys": {
      "Word": 5049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "暨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "and; attain, reach; confines"
    },
    "Keys": {
      "Hanzi": 5827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5827,
      "Radical": "日",
      "FrequencyRank": 3572,
      "RadicalIndex": "72.1",
      "StrokeCount": 14
    }
  },
  "畅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng",
      "PrimaryDefinition": "smoothly, freely, unrestrained"
    },
    "Keys": {
      "Hanzi": 1086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1086,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 2122,
      "StrokeCount": 8
    }
  },
  "队形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìxíng",
      "PrimaryDefinition": "formation"
    },
    "Keys": {
      "Word": 6376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "type of creeping plant; turnip"
    },
    "Keys": {
      "Hanzi": 2204
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2204,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2745,
      "StrokeCount": 11
    }
  },
  "歌剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gējù",
      "PrimaryDefinition": "revue, melodrama, opus, operatic, opera"
    },
    "Keys": {
      "Word": 6725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "团员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuányuán",
      "PrimaryDefinition": "member (of delegation/organization/etc.)"
    },
    "Keys": {
      "Word": 9590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāng",
      "PrimaryDefinition": "bear, accept, undertake; just"
    },
    "Keys": {
      "Hanzi": 418,
      "Word": 4486
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "42.3",
      "Grade": 6,
      "GeneralStandard": 418,
      "Radical": "小",
      "HSK": 2,
      "FrequencyRank": 71,
      "StrokeCount": 6
    }
  },
  "仪式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíshì",
      "PrimaryDefinition": "religious rite, ceremonial occasion, deeds, practice, form, rituality, office, formality, ritual, circumstance, ceremony, formalness, exercise, powwow, observance, ceremonial, rite, works"
    },
    "Keys": {
      "Word": 5237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "两手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎngshǒu",
      "PrimaryDefinition": "Two hands"
    },
    "Keys": {
      "Word": 4827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "趣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "156.8",
      "GeneralStandard": 3194,
      "Radical": "走",
      "HSK": 4,
      "FrequencyRank": 1065,
      "StrokeCount": 15
    }
  },
  "俊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "talented, capable; handsome"
    },
    "Keys": {
      "Hanzi": 1578,
      "Word": 7733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "Grade": 7,
      "GeneralStandard": 1578,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 1847,
      "StrokeCount": 9
    }
  },
  "动荡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngdàng",
      "PrimaryDefinition": "unrest (social or political), turmoil, upheaval, commotion"
    },
    "Keys": {
      "Word": 6331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "综合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōnghé",
      "PrimaryDefinition": "synthetical, comprehensive, multiple, composite"
    },
    "Keys": {
      "Word": 3209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "长途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángtú",
      "PrimaryDefinition": "long-distance, long distance"
    },
    "Keys": {
      "Word": 2300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "随大溜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suídàliù",
      "PrimaryDefinition": "Slider"
    },
    "Keys": {
      "Word": 9362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "团圆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuányuán",
      "PrimaryDefinition": "to have a reunion"
    },
    "Keys": {
      "Word": 9592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "秭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "one thousand millions, sometimes used for one hundred millions"
    },
    "Keys": {
      "Hanzi": 4252
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4252,
      "Radical": "禾",
      "FrequencyRank": 6171,
      "RadicalIndex": "115.5",
      "StrokeCount": 9
    }
  },
  "争先恐后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngxiān-kǒnghòu",
      "PrimaryDefinition": "striving to be first and fearing to be last (idiom), outdoing one another"
    },
    "Keys": {
      "Word": 10720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拓展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuòzhǎn",
      "PrimaryDefinition": "expand"
    },
    "Keys": {
      "Word": 9628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng",
      "PrimaryDefinition": "abundant, flourishing; contain; fill"
    },
    "Keys": {
      "Hanzi": 2247,
      "Word": 5884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.7",
      "Grade": 7,
      "GeneralStandard": 2247,
      "Radical": "皿",
      "HSK": 6,
      "FrequencyRank": 1142,
      "StrokeCount": 11
    }
  },
  "搅拌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎobàn",
      "PrimaryDefinition": "to stir, to agitate"
    },
    "Keys": {
      "Word": 7482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倒下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoxià",
      "PrimaryDefinition": "to collapse, to topple over"
    },
    "Keys": {
      "Word": 6197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爽快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǎngkuai",
      "PrimaryDefinition": "refreshed, rejuvenated, frank and straightforward"
    },
    "Keys": {
      "Word": 9256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quān",
      "PrimaryDefinition": "to circle; a circle; corral"
    },
    "Keys": {
      "Hanzi": 2302,
      "Word": 7716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.8",
      "Grade": 7,
      "GeneralStandard": 2302,
      "Radical": "囗",
      "HSK": 4,
      "FrequencyRank": 1366,
      "StrokeCount": 11
    }
  },
  "拐弯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎiwān",
      "PrimaryDefinition": "to go round a curve, to turn a corner, fig. a new direction"
    },
    "Keys": {
      "Word": 6860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "絜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "a marking line; ascertain, assess; measure"
    },
    "Keys": {
      "Hanzi": 7282
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7282,
      "Radical": "糸",
      "FrequencyRank": 6587,
      "RadicalIndex": "120.6",
      "StrokeCount": 12
    }
  },
  "干涉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānshè",
      "PrimaryDefinition": "interfere, intervene, meddle"
    },
    "Keys": {
      "Word": 4580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "㤘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "(simplified form of 㥮) obstinate; stubborn; opinionated; obstinacy; stubbornness; intransigent, truculent; savage, ferocious; fierce"
    },
    "Keys": {
      "Hanzi": 4031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4031,
      "Radical": "心",
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "亲戚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnqi",
      "PrimaryDefinition": "belongings, relation, kinswoman, kinsman, kith, kindred, cousinry, sib, ally, relative, relatives, kin, connection, kinsfolk, cousin"
    },
    "Keys": {
      "Word": 8721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngshí",
      "PrimaryDefinition": "to get to know each other, acquaintance"
    },
    "Keys": {
      "Word": 9926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7584,
      "Radical": "火",
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "上火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng huǒ",
      "PrimaryDefinition": "to get angry, to suffer from excessive internal heat (TCM)"
    },
    "Keys": {
      "Word": 8973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "标题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāotí",
      "PrimaryDefinition": "head, headline, title, superscription, statute title, header, headword, caption, cutline, rubric, heading"
    },
    "Keys": {
      "Word": 1304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "兑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": "cash; exchange"
    },
    "Keys": {
      "Hanzi": 850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "10.5",
      "GeneralStandard": 850,
      "Radical": "儿",
      "HSK": 8,
      "FrequencyRank": 2424,
      "StrokeCount": 7
    }
  },
  "最佳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìjiā",
      "PrimaryDefinition": "first-rate, best, superlative, optimum"
    },
    "Keys": {
      "Word": 5418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "浑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hún",
      "PrimaryDefinition": "muddy, turbid; blend, merge, mix"
    },
    "Keys": {
      "Hanzi": 1681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1681,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 1991,
      "StrokeCount": 9
    }
  },
  "基本功": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīběngōng",
      "PrimaryDefinition": "basic skills, fundamentals"
    },
    "Keys": {
      "Word": 7273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxuǎn",
      "PrimaryDefinition": "general election"
    },
    "Keys": {
      "Word": 6137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔ",
      "PrimaryDefinition": "earth"
    },
    "Keys": {
      "Hanzi": 27,
      "Radical": 32,
      "Word": 5106
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32",
      "Grade": 6,
      "GeneralStandard": 27,
      "Radical": "土",
      "HSK": 3,
      "FrequencyRank": 515,
      "StrokeCount": 3
    }
  },
  "癶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "foot steps"
    },
    "Keys": {
      "Radical": 105
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fáng",
      "PrimaryDefinition": "house, building; room"
    },
    "Keys": {
      "Hanzi": 1290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "63.4",
      "GeneralStandard": 1290,
      "Radical": "戶",
      "HSK": 1,
      "FrequencyRank": 512,
      "StrokeCount": 8
    }
  },
  "逃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "escape, flee; abscond, dodge"
    },
    "Keys": {
      "Hanzi": 1588,
      "Word": 3963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 5,
      "GeneralStandard": 1588,
      "Radical": "辵",
      "HSK": 5,
      "FrequencyRank": 996,
      "StrokeCount": 9
    }
  },
  "褚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": "bag, valise; stuff, pad; surname"
    },
    "Keys": {
      "Hanzi": 5597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5597,
      "Radical": "衣",
      "FrequencyRank": 3826,
      "RadicalIndex": "145.9",
      "StrokeCount": 13
    }
  },
  "家务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāwù",
      "PrimaryDefinition": "household duties, housework"
    },
    "Keys": {
      "Word": 2597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "璠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "a piece of precious jade"
    },
    "Keys": {
      "Hanzi": 6032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6032,
      "Radical": "玉",
      "RadicalIndex": "96.12",
      "StrokeCount": 16
    }
  },
  "辣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "là",
      "PrimaryDefinition": "peppery, pungent, hot; cruel"
    },
    "Keys": {
      "Hanzi": 3148,
      "Word": 2679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "160.7",
      "Grade": 4,
      "GeneralStandard": 3148,
      "Radical": "辛",
      "HSK": 4,
      "FrequencyRank": 2420,
      "StrokeCount": 14
    }
  },
  "滆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7590,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "靰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "leg warmer"
    },
    "Keys": {
      "Hanzi": 5081
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5081,
      "Radical": "革",
      "FrequencyRank": 7000,
      "RadicalIndex": "177.3",
      "StrokeCount": 12
    }
  },
  "委屈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěiqu",
      "PrimaryDefinition": "feel wronged, be obstructed (in career), inconvenience (sb.)"
    },
    "Keys": {
      "Word": 9708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敦促": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūncù",
      "PrimaryDefinition": "to press, to urge, to hasten"
    },
    "Keys": {
      "Word": 6389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蟪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "a kind of cicada"
    },
    "Keys": {
      "Hanzi": 6336
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6336,
      "Radical": "虫",
      "FrequencyRank": 4911,
      "RadicalIndex": "142.12",
      "StrokeCount": 18
    }
  },
  "认出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn chū",
      "PrimaryDefinition": "recognition, to recognize"
    },
    "Keys": {
      "Word": 1852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "move, happen; movement, action"
    },
    "Keys": {
      "Hanzi": 353,
      "Word": 86
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.4",
      "Grade": 1,
      "GeneralStandard": 353,
      "Radical": "力",
      "HSK": 1,
      "FrequencyRank": 73,
      "StrokeCount": 6
    }
  },
  "彩电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎidiàn",
      "PrimaryDefinition": "color TV"
    },
    "Keys": {
      "Word": 5746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féi",
      "PrimaryDefinition": "calf; avoid; be ill; wither"
    },
    "Keys": {
      "Hanzi": 5229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5229,
      "Radical": "肉",
      "FrequencyRank": 3543,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "靺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "stocking; the Tungusic tribe"
    },
    "Keys": {
      "Hanzi": 5645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5645,
      "Radical": "革",
      "FrequencyRank": 7443,
      "RadicalIndex": "177.5",
      "StrokeCount": 14
    }
  },
  "决议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéyì",
      "PrimaryDefinition": "resolution"
    },
    "Keys": {
      "Word": 7717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quān",
      "PrimaryDefinition": "bowl"
    },
    "Keys": {
      "Hanzi": 7330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7330,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "黑马": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēimǎ",
      "PrimaryDefinition": "dark horse, fig. unexpected winner"
    },
    "Keys": {
      "Word": 7059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "a flathead (fish)"
    },
    "Keys": {
      "Hanzi": 7802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7802,
      "Radical": "魚",
      "FrequencyRank": 8737,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "漫画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànhuà",
      "PrimaryDefinition": "takeoff, cartoon, caricature"
    },
    "Keys": {
      "Word": 3712
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "氿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "spring"
    },
    "Keys": {
      "Hanzi": 6515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6515,
      "Radical": "水",
      "RadicalIndex": "85.2",
      "StrokeCount": 5
    }
  },
  "坻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "an islet, a rock in a river; an embankment; to stop"
    },
    "Keys": {
      "Hanzi": 3847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3847,
      "Radical": "土",
      "FrequencyRank": 6455,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "壳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ké",
      "PrimaryDefinition": "casing, shell, husk"
    },
    "Keys": {
      "Hanzi": 650,
      "Word": 7791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "33.4",
      "Grade": 7,
      "GeneralStandard": 650,
      "Radical": "士",
      "HSK": 8,
      "FrequencyRank": 1937,
      "StrokeCount": 7
    }
  },
  "蔌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "vegetables"
    },
    "Keys": {
      "Hanzi": 7641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7641,
      "Radical": "艸",
      "FrequencyRank": 6809,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "崇尚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngshàng",
      "PrimaryDefinition": "to hold up (as an model), to hold in esteem, to revere, to advocate"
    },
    "Keys": {
      "Word": 5927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "a fault, mistake, error, transgression"
    },
    "Keys": {
      "Hanzi": 5509
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5509,
      "Radical": "心",
      "FrequencyRank": 5208,
      "RadicalIndex": "61.9",
      "StrokeCount": 13
    }
  },
  "塑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "model in clay, sculpt; plastics"
    },
    "Keys": {
      "Hanzi": 3001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.1",
      "GeneralStandard": 3001,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 2038,
      "StrokeCount": 13
    }
  },
  "滕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "an ancient state in Shandong province; water bursting forth"
    },
    "Keys": {
      "Hanzi": 5967
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5967,
      "Radical": "水",
      "FrequencyRank": 3892,
      "RadicalIndex": "85.1",
      "StrokeCount": 15
    }
  },
  "沚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "islet in stream; small sandbar"
    },
    "Keys": {
      "Hanzi": 3772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3772,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "落地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò dì",
      "PrimaryDefinition": "be born (of babies), born, fail, fall to the ground, be born"
    },
    "Keys": {
      "Word": 8170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7066,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "媓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7451,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "包扎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāozā",
      "PrimaryDefinition": "to wrap up, to pack, to bind up (a wound)"
    },
    "Keys": {
      "Word": 5513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "从事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngshì",
      "PrimaryDefinition": "engross, have, join, treat, plow, handle, follow, embark, undertake, attend_to, attack, occupy, absorb, go_into, work, engage, prosecute, go_about, go in for, pursue, be, meddle, embark_on, perform, enter, cover, deal, address, be engaged in, deal with, place, wage"
    },
    "Keys": {
      "Word": 1376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "轮船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnchuán",
      "PrimaryDefinition": "steamship, steamer, ocean liner, ship, CL:艘[sou1]"
    },
    "Keys": {
      "Word": 2721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "爱好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àihào",
      "PrimaryDefinition": "love, like, be fond of, be keen on"
    },
    "Keys": {
      "Word": 2
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "䴖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "a kind of water bird"
    },
    "Keys": {
      "Hanzi": 7465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7465,
      "Radical": "",
      "FrequencyRank": 8771,
      "RadicalIndex": "",
      "StrokeCount": 19
    }
  },
  "茶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "tea"
    },
    "Keys": {
      "Hanzi": 1411,
      "Word": 37
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "Grade": 1,
      "GeneralStandard": 1411,
      "Radical": "艸",
      "HSK": 1,
      "FrequencyRank": 1272,
      "StrokeCount": 9
    }
  },
  "只": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "only, just, simply"
    },
    "Keys": {
      "Hanzi": 246,
      "Word": 2158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 3,
      "GeneralStandard": 246,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 97,
      "StrokeCount": 5
    }
  },
  "胶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "glue, gum, resin, rubber; sound; shin bone"
    },
    "Keys": {
      "Hanzi": 2000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 2000,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 2170,
      "StrokeCount": 10
    }
  },
  "摏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "pound; strike against; ram; (Cant.) to hit with the elbow"
    },
    "Keys": {
      "Hanzi": 7628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7628,
      "Radical": "手",
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "怀抱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáibào",
      "PrimaryDefinition": "ambition, embrace"
    },
    "Keys": {
      "Word": 7153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阅历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèlì",
      "PrimaryDefinition": "to experience, experience"
    },
    "Keys": {
      "Word": 10568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "警钟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngzhōng",
      "PrimaryDefinition": "alarm bell"
    },
    "Keys": {
      "Word": 7633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "medicinal herb"
    },
    "Keys": {
      "Hanzi": 3670
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3670,
      "Radical": "艸",
      "FrequencyRank": 4704,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "堽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "mound; used in place names"
    },
    "Keys": {
      "Hanzi": 7478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7478,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 13
    }
  },
  "乘人之危": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngrénzhīwēi",
      "PrimaryDefinition": "to take advantage of sb's precarious position"
    },
    "Keys": {
      "Word": 5883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扎根": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā gēn",
      "PrimaryDefinition": "to take root"
    },
    "Keys": {
      "Word": 10623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "照办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào bàn",
      "PrimaryDefinition": "to follow the rules, to do as instructed, to play by the book, to comply with a request"
    },
    "Keys": {
      "Word": 10675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "put out, extinguish, quash"
    },
    "Keys": {
      "Hanzi": 3157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.1",
      "GeneralStandard": 3157,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 2858,
      "StrokeCount": 14
    }
  },
  "痢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "dysentery"
    },
    "Keys": {
      "Hanzi": 2732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2732,
      "Radical": "疒",
      "FrequencyRank": 4107,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "汽水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìshuǐ",
      "PrimaryDefinition": "soda, pop"
    },
    "Keys": {
      "Word": 2796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "别的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biéde",
      "PrimaryDefinition": "else, other"
    },
    "Keys": {
      "Word": 25
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "确诊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèzhěn",
      "PrimaryDefinition": "to make a definite diagnosis"
    },
    "Keys": {
      "Word": 8819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "used in onomatopoetic expressions"
    },
    "Keys": {
      "Hanzi": 5158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5158,
      "Radical": "口",
      "FrequencyRank": 3220,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "疑虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yílǜ",
      "PrimaryDefinition": "misgivings, doubts"
    },
    "Keys": {
      "Word": 10312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "改革开放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎigé-kāifàng",
      "PrimaryDefinition": "to reform and open to the outside world, refers to Deng Xiaoping's policies from around 1980"
    },
    "Keys": {
      "Word": 6652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抗争": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàngzhēng",
      "PrimaryDefinition": "resistance, struggle, resist, contend, make a stand against, make stand against, oppose"
    },
    "Keys": {
      "Word": 7781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "像样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngyàng",
      "PrimaryDefinition": "presentable, decent, up to par"
    },
    "Keys": {
      "Word": 9952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "被动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèidòng",
      "PrimaryDefinition": "passive"
    },
    "Keys": {
      "Word": 3241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "部门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùmén",
      "PrimaryDefinition": "category, section, department, service, instrumentality, branch, realm, sector, ministry, province, divisional, classification, departmental, division"
    },
    "Keys": {
      "Word": 1325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "潼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "high, lofty; damp"
    },
    "Keys": {
      "Hanzi": 6004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6004,
      "Radical": "水",
      "FrequencyRank": 4021,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "厶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "private"
    },
    "Keys": {
      "Radical": 28
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "邸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "official residence, residence of"
    },
    "Keys": {
      "Hanzi": 3743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3743,
      "Radical": "邑",
      "FrequencyRank": 3186,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "剧烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùliè",
      "PrimaryDefinition": "violent, fierce"
    },
    "Keys": {
      "Word": 7701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "复发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùfā",
      "PrimaryDefinition": "to recur (of a disease), to reappear, to relapse (into a former bad state)"
    },
    "Keys": {
      "Word": 6633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nián",
      "PrimaryDefinition": "sheatfish, parasilurus asotus"
    },
    "Keys": {
      "Hanzi": 5527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5527,
      "Radical": "魚",
      "FrequencyRank": 4154,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "极其": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíqí",
      "PrimaryDefinition": "absolutely, extremely, very, parlous, heaps, utterly, entirely, miserably, goldarn, precious, exceedingly, passing, definitely, plaguily, passingly, totally, staving, goldurn, completely"
    },
    "Keys": {
      "Word": 2584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蹶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "stumble, fall down; trample"
    },
    "Keys": {
      "Hanzi": 6384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6384,
      "Radical": "足",
      "FrequencyRank": 4420,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "澜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "overflowing; waves, ripples"
    },
    "Keys": {
      "Hanzi": 3298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 3298,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2789,
      "StrokeCount": 15
    }
  },
  "兖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "establish; one of nine empire divisions"
    },
    "Keys": {
      "Hanzi": 4000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4000,
      "Radical": "儿",
      "FrequencyRank": 4893,
      "RadicalIndex": "10.6",
      "StrokeCount": 8
    }
  },
  "零钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língqián",
      "PrimaryDefinition": "change (of money), small change, pocket money"
    },
    "Keys": {
      "Word": 8074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "思维": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīwéi",
      "PrimaryDefinition": "thought, thinking"
    },
    "Keys": {
      "Word": 3949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "构": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "frame, building, structure"
    },
    "Keys": {
      "Hanzi": 1029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1029,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 511,
      "StrokeCount": 8
    }
  },
  "人品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénpǐn",
      "PrimaryDefinition": "moral standing, moral quality, character, personality, appearance, looks (colloquial), bearing"
    },
    "Keys": {
      "Word": 8842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiājìng",
      "PrimaryDefinition": "family financial situation, family circumstances"
    },
    "Keys": {
      "Word": 7352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "问题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèntí",
      "PrimaryDefinition": "problem, mishap, query, job, interface, question, inquiry, matter, enquiry, trouble, issue, worry, subject, topic, hang-up, business, quaere"
    },
    "Keys": {
      "Word": 1067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "区域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūyù",
      "PrimaryDefinition": "area, region, district"
    },
    "Keys": {
      "Word": 3821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "免疫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎnyì",
      "PrimaryDefinition": "immunize, immune"
    },
    "Keys": {
      "Word": 8278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "神情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénqíng",
      "PrimaryDefinition": "patina, way, expression, facial_expression, look, patine"
    },
    "Keys": {
      "Word": 3887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "凹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āo",
      "PrimaryDefinition": "concave, hollow, depressed; a pass, valley"
    },
    "Keys": {
      "Hanzi": 260,
      "Word": 5449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "17.3",
      "Grade": 7,
      "GeneralStandard": 260,
      "Radical": "凵",
      "HSK": 8,
      "FrequencyRank": 2862,
      "StrokeCount": 5
    }
  },
  "圈套": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quāntào",
      "PrimaryDefinition": "trap, snare, trick"
    },
    "Keys": {
      "Word": 8795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "wings; fins on fish; shelter"
    },
    "Keys": {
      "Hanzi": 3435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124.12",
      "GeneralStandard": 3435,
      "Radical": "羽",
      "HSK": 8,
      "FrequencyRank": 1294,
      "StrokeCount": 17
    }
  },
  "囡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3596,
      "Radical": "囗",
      "FrequencyRank": 4759,
      "RadicalIndex": "31.3",
      "StrokeCount": 6
    }
  },
  "互": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "mutually, reciprocally"
    },
    "Keys": {
      "Hanzi": 106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "7.2",
      "GeneralStandard": 106,
      "Radical": "二",
      "HSK": 3,
      "FrequencyRank": 819,
      "StrokeCount": 4
    }
  },
  "锋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "point of spear, sharp point"
    },
    "Keys": {
      "Hanzi": 2664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "GeneralStandard": 2664,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 1671,
      "StrokeCount": 12
    }
  },
  "三维": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sānwéi",
      "PrimaryDefinition": "three-dimensional, 3D"
    },
    "Keys": {
      "Word": 8919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风风雨雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngfēng-yǔyǔ",
      "PrimaryDefinition": "Wind and rain"
    },
    "Keys": {
      "Word": 6568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "compliance; obedience; female"
    },
    "Keys": {
      "Hanzi": 7226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7226,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "紧迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnpò",
      "PrimaryDefinition": "pressing, urgent"
    },
    "Keys": {
      "Word": 7568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "深奥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnào",
      "PrimaryDefinition": "profound, abstruse, recondite, profoundly"
    },
    "Keys": {
      "Word": 9029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "day-lily, hemerocallisflava"
    },
    "Keys": {
      "Hanzi": 5091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5091,
      "Radical": "艸",
      "FrequencyRank": 4243,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "慰劳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèiláo",
      "PrimaryDefinition": "to show appreciation (by kind words, small gifts etc), to comfort"
    },
    "Keys": {
      "Word": 9723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "这里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèlǐ",
      "PrimaryDefinition": "this place, there, here"
    },
    "Keys": {
      "Word": 463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "阴谋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnmóu",
      "PrimaryDefinition": "plot, conspiracy"
    },
    "Keys": {
      "Word": 5252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "筹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "chip, tally, token; raise money"
    },
    "Keys": {
      "Hanzi": 2952,
      "Word": 5939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.7",
      "Grade": 7,
      "GeneralStandard": 2952,
      "Radical": "竹",
      "HSK": 7,
      "FrequencyRank": 1677,
      "StrokeCount": 13
    }
  },
  "荆棘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngjí",
      "PrimaryDefinition": "thistles and thorns, brambles, thorny undergrowth"
    },
    "Keys": {
      "Word": 7597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāibàn",
      "PrimaryDefinition": "open, set up, start (business/etc.)"
    },
    "Keys": {
      "Word": 7740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bié",
      "PrimaryDefinition": "separate, other; do not"
    },
    "Keys": {
      "Hanzi": 751,
      "Word": 2272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.5",
      "Grade": 4,
      "GeneralStandard": 751,
      "Radical": "刀",
      "HSK": 1,
      "FrequencyRank": 222,
      "StrokeCount": 7
    }
  },
  "坜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "a hole, pit"
    },
    "Keys": {
      "Hanzi": 6561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6561,
      "Radical": "土",
      "FrequencyRank": 7159,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "旅行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚxíng",
      "PrimaryDefinition": "travel, journey, tour"
    },
    "Keys": {
      "Word": 860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蓿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xu",
      "PrimaryDefinition": "clover, lucerne"
    },
    "Keys": {
      "Hanzi": 5656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5656,
      "Radical": "艸",
      "FrequencyRank": 4151,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "闹事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nào shì",
      "PrimaryDefinition": "to cause trouble, to create a disturbance"
    },
    "Keys": {
      "Word": 8387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厂长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngzhǎng",
      "PrimaryDefinition": "factory director"
    },
    "Keys": {
      "Word": 3315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "羕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7229,
      "Radical": "羊",
      "FrequencyRank": 8963,
      "RadicalIndex": "123.5",
      "StrokeCount": 11
    }
  },
  "箱子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngzi",
      "PrimaryDefinition": "suitcase, chest, box, case, trunk, CL:隻|只[zhi1],個|个[ge4]"
    },
    "Keys": {
      "Word": 3020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "锨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "shovel"
    },
    "Keys": {
      "Hanzi": 5489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5489,
      "Radical": "金",
      "FrequencyRank": 4650,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "arrive at, reach; intelligent; smooth, slippery"
    },
    "Keys": {
      "Hanzi": 398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.3",
      "GeneralStandard": 398,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 289,
      "StrokeCount": 6
    }
  },
  "大宗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàzōng",
      "PrimaryDefinition": "large amount, staple, influential family of long standing"
    },
    "Keys": {
      "Word": 6141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老百姓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎobǎixìng",
      "PrimaryDefinition": "plebe, populace, mister, civilians, roturier, ordinary people, common people"
    },
    "Keys": {
      "Word": 1685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùtóng",
      "PrimaryDefinition": "different"
    },
    "Keys": {
      "Word": 537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "拖鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōxié",
      "PrimaryDefinition": "slippers, sandals, flip-flops, CL:雙|双[shuang1],隻|只[zhi1]"
    },
    "Keys": {
      "Word": 5113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "扳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "pull; drag; right itself"
    },
    "Keys": {
      "Hanzi": 634,
      "Word": 5482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 634,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3169,
      "StrokeCount": 7
    }
  },
  "论证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùnzhèng",
      "PrimaryDefinition": "to prove a point, to expound on, to demonstrate or prove (through argument), proof"
    },
    "Keys": {
      "Word": 8162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "expel, disperse, exorcise"
    },
    "Keys": {
      "Hanzi": 4364
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4364,
      "Radical": "示",
      "FrequencyRank": 4176,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "娆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráo",
      "PrimaryDefinition": "graceful, charming, fascinating"
    },
    "Keys": {
      "Hanzi": 4384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4384,
      "Radical": "女",
      "FrequencyRank": 5584,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "拯救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngjiù",
      "PrimaryDefinition": "spare, help, extricate, rescue, save, deliver"
    },
    "Keys": {
      "Word": 10728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "honey; sweet; nectar"
    },
    "Keys": {
      "Hanzi": 3175,
      "Word": 8271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.8",
      "Grade": 7,
      "GeneralStandard": 3175,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2014,
      "StrokeCount": 14
    }
  },
  "吹牛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuī niú",
      "PrimaryDefinition": "to talk big, to shoot off one's mouth, to chat (dialect)"
    },
    "Keys": {
      "Word": 6022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聋人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóngrén",
      "PrimaryDefinition": "Deaf"
    },
    "Keys": {
      "Word": 8114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "际": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "border, boundary, juncture"
    },
    "Keys": {
      "Hanzi": 911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.5",
      "GeneralStandard": 911,
      "Radical": "阜",
      "HSK": 2,
      "FrequencyRank": 423,
      "StrokeCount": 7
    }
  },
  "性情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngqíng",
      "PrimaryDefinition": "constitution, fibre, aptness, acerbity, fiber, affection, temper, disposition, individuality, blood, streak, temperament"
    },
    "Keys": {
      "Word": 10061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公务员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngwùyuán",
      "PrimaryDefinition": "jobholder, servant, public_servant, official, government official worker, functionary, government employee, public servant, officeholder, officialdom, officer, orderly, civil servant, government officials"
    },
    "Keys": {
      "Word": 1505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "仝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "together, same; surname"
    },
    "Keys": {
      "Hanzi": 6513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6513,
      "Radical": "人",
      "FrequencyRank": 6887,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "激情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīqíng",
      "PrimaryDefinition": "passion, fervor, enthusiasm, strong emotion"
    },
    "Keys": {
      "Word": 4695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "俘虏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúlǔ",
      "PrimaryDefinition": "captive"
    },
    "Keys": {
      "Word": 6604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūn",
      "PrimaryDefinition": "swallow; absorb, annex, engulf"
    },
    "Keys": {
      "Hanzi": 607,
      "Word": 5109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 6,
      "GeneralStandard": 607,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 1797,
      "StrokeCount": 7
    }
  },
  "黑暗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēiàn",
      "PrimaryDefinition": "dark"
    },
    "Keys": {
      "Word": 2543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "汩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "run swiftly"
    },
    "Keys": {
      "Hanzi": 3773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3773,
      "Radical": "水",
      "FrequencyRank": 4342,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "鱼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "fish"
    },
    "Keys": {
      "Hanzi": 1194,
      "Radical": 195,
      "Word": 1181
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "195",
      "Grade": 2,
      "GeneralStandard": 1194,
      "Radical": "魚",
      "HSK": 2,
      "FrequencyRank": 852,
      "StrokeCount": 8
    }
  },
  "满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎn",
      "PrimaryDefinition": "fill; full, satisfied"
    },
    "Keys": {
      "Hanzi": 3005,
      "Word": 874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "Grade": 2,
      "GeneralStandard": 3005,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 436,
      "StrokeCount": 13
    }
  },
  "不在乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù zàihu",
      "PrimaryDefinition": "not to care"
    },
    "Keys": {
      "Word": 2280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "拼搏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīnbó",
      "PrimaryDefinition": "to struggle, to wrestle"
    },
    "Keys": {
      "Word": 8532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "flower of leek family; turnip"
    },
    "Keys": {
      "Hanzi": 4746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4746,
      "Radical": "艸",
      "FrequencyRank": 3321,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "卑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "humble, low, inferior; despise"
    },
    "Keys": {
      "Hanzi": 1154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.6",
      "GeneralStandard": 1154,
      "Radical": "十",
      "HSK": 7,
      "FrequencyRank": 2059,
      "StrokeCount": 8
    }
  },
  "旒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "fringes of pearls on crowns"
    },
    "Keys": {
      "Hanzi": 5550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5550,
      "Radical": "方",
      "FrequencyRank": 6046,
      "RadicalIndex": "70.9",
      "StrokeCount": 13
    }
  },
  "精英": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngyīng",
      "PrimaryDefinition": "quintessence, flower, broth of a boy, elite, broth of a man, aristocracy, essence, cream, powerhouse"
    },
    "Keys": {
      "Word": 7625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "排行榜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páihángbǎng",
      "PrimaryDefinition": "seniority among siblings"
    },
    "Keys": {
      "Word": 4894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "暿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7859,
      "Radical": "日",
      "RadicalIndex": "72.12",
      "StrokeCount": 16
    }
  },
  "信封": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnfēng",
      "PrimaryDefinition": "envelope, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "镵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "spade, trowel"
    },
    "Keys": {
      "Hanzi": 6473
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6473,
      "Radical": "金",
      "FrequencyRank": 8066,
      "RadicalIndex": "167.17",
      "StrokeCount": 22
    }
  },
  "走路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒu lù",
      "PrimaryDefinition": "go away, stroam, go on foot, walk"
    },
    "Keys": {
      "Word": 489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "国情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóqíng",
      "PrimaryDefinition": "current state of a country, national conditions, (US) State of the Union"
    },
    "Keys": {
      "Word": 6933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7612,
      "Radical": "女",
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "遗留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíliú",
      "PrimaryDefinition": "to leave behind, to hand down"
    },
    "Keys": {
      "Word": 10302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "携手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié shǒu",
      "PrimaryDefinition": "hand in hand, to join hands, to collaborate"
    },
    "Keys": {
      "Word": 9992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "修长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūcháng",
      "PrimaryDefinition": "slender, lanky, tall and thin"
    },
    "Keys": {
      "Word": 10077
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "款式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎnshì",
      "PrimaryDefinition": "pattern, style, design, CL:種|种[zhong3], elegant, elegance, good taste"
    },
    "Keys": {
      "Word": 7882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínggǎn",
      "PrimaryDefinition": "affect, friendship, sensibility, bond, emotion, bosom, breast, sentiment, emotional, chasm, feeling, feelings"
    },
    "Keys": {
      "Word": 1820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "去向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùxiàng",
      "PrimaryDefinition": "the position of sth, whereabouts"
    },
    "Keys": {
      "Word": 8793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "简介": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnjiè",
      "PrimaryDefinition": "brief introduction, synopsis"
    },
    "Keys": {
      "Word": 4711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "齐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "even"
    },
    "Keys": {
      "Hanzi": 518,
      "Radical": 210,
      "Word": 1801
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "210",
      "Grade": 3,
      "GeneralStandard": 518,
      "Radical": "齊",
      "HSK": 3,
      "FrequencyRank": 1063,
      "StrokeCount": 6
    }
  },
  "徒弟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túdi",
      "PrimaryDefinition": "apprentice, disciple"
    },
    "Keys": {
      "Word": 5105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "暾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūn",
      "PrimaryDefinition": "morning sun, sunrise"
    },
    "Keys": {
      "Hanzi": 6090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6090,
      "Radical": "日",
      "FrequencyRank": 6112,
      "RadicalIndex": "72.12",
      "StrokeCount": 16
    }
  },
  "实用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíyòng",
      "PrimaryDefinition": "practical, pragmatic, functional"
    },
    "Keys": {
      "Word": 2861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "历程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìchéng",
      "PrimaryDefinition": "appendage, kinetics, process, journey, course, outgrowth"
    },
    "Keys": {
      "Word": 7996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "膺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "breast, chest; undertake, bear"
    },
    "Keys": {
      "Hanzi": 6289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6289,
      "Radical": "肉",
      "FrequencyRank": 3783,
      "RadicalIndex": "130.13",
      "StrokeCount": 17
    }
  },
  "中药": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngyào",
      "PrimaryDefinition": "trad. Ch. medicine"
    },
    "Keys": {
      "Word": 4241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "a sore, ulcer; pestilence"
    },
    "Keys": {
      "Hanzi": 3997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3997,
      "Radical": "疒",
      "FrequencyRank": 5812,
      "RadicalIndex": "104.3",
      "StrokeCount": 8
    }
  },
  "藠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6321,
      "Radical": "艸",
      "FrequencyRank": 7919,
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "涂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "surname; name of certain rivers"
    },
    "Keys": {
      "Hanzi": 2075,
      "Word": 9582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 7,
      "GeneralStandard": 2075,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 1735,
      "StrokeCount": 10
    }
  },
  "侬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóng",
      "PrimaryDefinition": "I; you; family name"
    },
    "Keys": {
      "Hanzi": 3966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3966,
      "Radical": "人",
      "FrequencyRank": 3236,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "流畅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúchàng",
      "PrimaryDefinition": "flowing (of speech, writing), fluent, smooth and easy"
    },
    "Keys": {
      "Word": 8096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "variegated, adorned; brilliant"
    },
    "Keys": {
      "Hanzi": 1759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1759,
      "Radical": "糸",
      "FrequencyRank": 3711,
      "RadicalIndex": "120.6",
      "StrokeCount": 9
    }
  },
  "椒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "pepper, spices"
    },
    "Keys": {
      "Hanzi": 2570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2570,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 3187,
      "StrokeCount": 12
    }
  },
  "管理费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnlǐfèi",
      "PrimaryDefinition": "management fee"
    },
    "Keys": {
      "Word": 6883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "示范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìfàn",
      "PrimaryDefinition": "demonstrate, exemplify, exhibit"
    },
    "Keys": {
      "Word": 3906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒu",
      "PrimaryDefinition": "mouth"
    },
    "Keys": {
      "Hanzi": 38,
      "Radical": 30,
      "Word": 199
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30",
      "Grade": 1,
      "GeneralStandard": 38,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 212,
      "StrokeCount": 3
    }
  },
  "外星人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàixīngrén",
      "PrimaryDefinition": "space alien, extraterrestrial"
    },
    "Keys": {
      "Word": 9642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "移": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "change place, shift; move about"
    },
    "Keys": {
      "Hanzi": 2316,
      "Word": 3091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.6",
      "Grade": 4,
      "GeneralStandard": 2316,
      "Radical": "禾",
      "HSK": 4,
      "FrequencyRank": 880,
      "StrokeCount": 11
    }
  },
  "精华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnghuá",
      "PrimaryDefinition": "elite, prime, cream, plum, wale, goodness, distillation, substance, pink, quintessence, distillate, flower, extract, essence, gem, pick, marrow, soul, elixir"
    },
    "Keys": {
      "Word": 7612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "停下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngxià",
      "PrimaryDefinition": "to stop"
    },
    "Keys": {
      "Word": 2937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "wren"
    },
    "Keys": {
      "Hanzi": 6263
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6263,
      "Radical": "鳥",
      "FrequencyRank": 5911,
      "RadicalIndex": "196.12",
      "StrokeCount": 17
    }
  },
  "节目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiémù",
      "PrimaryDefinition": "item, item (on program), programme, act, telecast, number, program"
    },
    "Keys": {
      "Word": 782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "匆匆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōngcōng",
      "PrimaryDefinition": "hurriedly"
    },
    "Keys": {
      "Word": 6054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孪生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luánshēng",
      "PrimaryDefinition": "twin"
    },
    "Keys": {
      "Word": 8148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掌管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎngguǎn",
      "PrimaryDefinition": "in charge of, to control"
    },
    "Keys": {
      "Word": 10653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàng",
      "PrimaryDefinition": "resist, oppose, defy, reject"
    },
    "Keys": {
      "Hanzi": 646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 646,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 766,
      "StrokeCount": 7
    }
  },
  "毕竟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìjìng",
      "PrimaryDefinition": "after all, when all is said and done"
    },
    "Keys": {
      "Word": 3249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "旎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǐ",
      "PrimaryDefinition": "fluttering of flag; romantic"
    },
    "Keys": {
      "Hanzi": 4943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4943,
      "Radical": "方",
      "FrequencyRank": 5041,
      "RadicalIndex": "70.7",
      "StrokeCount": 11
    }
  },
  "拥护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōnghù",
      "PrimaryDefinition": "to endorse, to support"
    },
    "Keys": {
      "Word": 10429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "candle wick, lamp wick; stick of incense"
    },
    "Keys": {
      "Hanzi": 4329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4329,
      "Radical": "火",
      "FrequencyRank": 5424,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "load, burden; carry, bear"
    },
    "Keys": {
      "Hanzi": 503,
      "Word": 4573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.2",
      "Grade": 6,
      "GeneralStandard": 503,
      "Radical": "貝",
      "HSK": 3,
      "FrequencyRank": 691,
      "StrokeCount": 6
    }
  },
  "电铃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànlíng",
      "PrimaryDefinition": "electric bell"
    },
    "Keys": {
      "Word": 6275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ma",
      "PrimaryDefinition": "final interrogative particle"
    },
    "Keys": {
      "Hanzi": 432,
      "Word": 225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 1,
      "GeneralStandard": 432,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 453,
      "StrokeCount": 6
    }
  },
  "杯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "cup, glass"
    },
    "Keys": {
      "Hanzi": 1020,
      "Word": 17
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 1,
      "GeneralStandard": 1020,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 1396,
      "StrokeCount": 8
    }
  },
  "有力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒulì",
      "PrimaryDefinition": "forceful, strong, potent, powerful"
    },
    "Keys": {
      "Word": 4143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "虫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóng",
      "PrimaryDefinition": "insect"
    },
    "Keys": {
      "Radical": 142,
      "Hanzi": 423
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142",
      "GeneralStandard": 423,
      "Radical": "虫",
      "HSK": 4,
      "FrequencyRank": 1287,
      "StrokeCount": 6
    }
  },
  "镳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "bit, bridle; ride"
    },
    "Keys": {
      "Hanzi": 6435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6435,
      "Radical": "金",
      "FrequencyRank": 4558,
      "RadicalIndex": "167.15",
      "StrokeCount": 20
    }
  },
  "长相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎngxiàng",
      "PrimaryDefinition": "appearance, looks, profile, countenance"
    },
    "Keys": {
      "Word": 10652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晚餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎncān",
      "PrimaryDefinition": "dinner, supper"
    },
    "Keys": {
      "Word": 1051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "骆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "a white horse with black mane; a camel"
    },
    "Keys": {
      "Hanzi": 1760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1760,
      "Radical": "馬",
      "FrequencyRank": 2856,
      "RadicalIndex": "187.6",
      "StrokeCount": 9
    }
  },
  "虽说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suīshuō",
      "PrimaryDefinition": "conj.: though, although"
    },
    "Keys": {
      "Word": 9364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "留学生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúxuéshēng",
      "PrimaryDefinition": "student studying abroad, returned student"
    },
    "Keys": {
      "Word": 855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "摩擦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mócā",
      "PrimaryDefinition": "friction, rubbing, chafing, fig. disharmony, conflict, also written 磨擦"
    },
    "Keys": {
      "Word": 3733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "或许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòxǔ",
      "PrimaryDefinition": "possibly, in all probability, peradventure, conceivably, mayhap, likely, perhaps, perchance, probably, maybe, haply, in all likelihood, belike"
    },
    "Keys": {
      "Word": 2566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "螃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páng",
      "PrimaryDefinition": "crab"
    },
    "Keys": {
      "Hanzi": 3347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3347,
      "Radical": "虫",
      "FrequencyRank": 3938,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "系统": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìtǒng",
      "PrimaryDefinition": "lineage, filiation, scheme, pedigree, system, formation, superstructure, programme, organization, succession, tract, system of rules, organisation, program"
    },
    "Keys": {
      "Word": 3000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "路口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùkǒu",
      "PrimaryDefinition": "crossing, intersection (of roads)"
    },
    "Keys": {
      "Word": 218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "苌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "averrhora carambola; surname"
    },
    "Keys": {
      "Hanzi": 3669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3669,
      "Radical": "艸",
      "FrequencyRank": 5130,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "损失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔnshī",
      "PrimaryDefinition": "loss"
    },
    "Keys": {
      "Word": 3955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "佐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǒ",
      "PrimaryDefinition": "assist, aid, second; subordinate"
    },
    "Keys": {
      "Hanzi": 772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 772,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2081,
      "StrokeCount": 7
    }
  },
  "位于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèiyú",
      "PrimaryDefinition": "be situated, situated, locate, precede, lie, come, nestle, subsist, underlie, sit, be located, skirt, rank"
    },
    "Keys": {
      "Word": 2977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "绯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "scarlet, dark red, crimson; purpl"
    },
    "Keys": {
      "Hanzi": 5030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 5030,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 3862,
      "StrokeCount": 11
    }
  },
  "苹果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngguǒ",
      "PrimaryDefinition": "mincemeat, pome, apple, Empire"
    },
    "Keys": {
      "Word": 1795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "隺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "a bird flying high ambition"
    },
    "Keys": {
      "Hanzi": 7081
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7081,
      "Radical": "隹",
      "FrequencyRank": 8800,
      "RadicalIndex": "172.2",
      "StrokeCount": 10
    }
  },
  "穿着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuānzhuó",
      "PrimaryDefinition": "dress, apparel"
    },
    "Keys": {
      "Word": 6004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "to mash rice"
    },
    "Keys": {
      "Hanzi": 5269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5269,
      "Radical": "米",
      "FrequencyRank": 6489,
      "RadicalIndex": "119.6",
      "StrokeCount": 12
    }
  },
  "齁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōu",
      "PrimaryDefinition": "snore loudly; very, extremely"
    },
    "Keys": {
      "Hanzi": 6400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6400,
      "Radical": "鼻",
      "FrequencyRank": 7153,
      "RadicalIndex": "209.5",
      "StrokeCount": 19
    }
  },
  "驾车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàchē",
      "PrimaryDefinition": "drive, drive a vehicle, drive a car"
    },
    "Keys": {
      "Word": 7366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "儿科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "érkē",
      "PrimaryDefinition": "pediatrics"
    },
    "Keys": {
      "Word": 4535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "减少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnshǎo",
      "PrimaryDefinition": "reduce, decrease"
    },
    "Keys": {
      "Word": 2602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "爆满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàomǎn",
      "PrimaryDefinition": "filled to capacity (of theater, stadium, gymnasium etc)"
    },
    "Keys": {
      "Word": 5547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "漩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "eddy, whirlpool"
    },
    "Keys": {
      "Hanzi": 5810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5810,
      "Radical": "水",
      "FrequencyRank": 3706,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "以内": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnèi",
      "PrimaryDefinition": "within, less than"
    },
    "Keys": {
      "Word": 3097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "自杀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìshā",
      "PrimaryDefinition": "commit suicide"
    },
    "Keys": {
      "Word": 4274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "双边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngbiān",
      "PrimaryDefinition": "bilateral"
    },
    "Keys": {
      "Word": 9251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róu",
      "PrimaryDefinition": "soft; gentle, pliant"
    },
    "Keys": {
      "Hanzi": 1750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1750,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 1573,
      "StrokeCount": 9
    }
  },
  "螣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tè",
      "PrimaryDefinition": "mythological wingless dragon of"
    },
    "Keys": {
      "Hanzi": 7883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7883,
      "Radical": "虫",
      "FrequencyRank": 7653,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "货币": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòbì",
      "PrimaryDefinition": "coinage, mintage, tin, coin, currency, specie, monetary, money, metal money, piece"
    },
    "Keys": {
      "Word": 7252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "台灯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táidēng",
      "PrimaryDefinition": "desk lamp, table lamp"
    },
    "Keys": {
      "Word": 5064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "旁观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pángguān",
      "PrimaryDefinition": "spectator, non-participant"
    },
    "Keys": {
      "Word": 8472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "excellent; joyful; auspicious"
    },
    "Keys": {
      "Hanzi": 3056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.11",
      "GeneralStandard": 3056,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 1845,
      "StrokeCount": 14
    }
  },
  "有些": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuxiē",
      "PrimaryDefinition": "kind_of, rather, somewhat"
    },
    "Keys": {
      "Word": 442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "稗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bài",
      "PrimaryDefinition": "darnels, weeds, tares small"
    },
    "Keys": {
      "Hanzi": 5498
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5498,
      "Radical": "禾",
      "FrequencyRank": 4912,
      "RadicalIndex": "115.8",
      "StrokeCount": 13
    }
  },
  "房屋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángwū",
      "PrimaryDefinition": "casa, premises, buildings, digging, houses, housing, tenement, house"
    },
    "Keys": {
      "Word": 1448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "隐身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnshēn",
      "PrimaryDefinition": "to hide oneself, invisible (person or online status)"
    },
    "Keys": {
      "Word": 10401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "accuse; sue; inform; narrate"
    },
    "Keys": {
      "Hanzi": 894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "GeneralStandard": 894,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 595,
      "StrokeCount": 7
    }
  },
  "粗暴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūbào",
      "PrimaryDefinition": "rough, cruel"
    },
    "Keys": {
      "Word": 6069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "to dismember, tear apart; downward stroke slanting right"
    },
    "Keys": {
      "Hanzi": 5895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5895,
      "Radical": "石",
      "FrequencyRank": 5597,
      "RadicalIndex": "112.1",
      "StrokeCount": 15
    }
  },
  "情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "feeling, sentiment, emotion"
    },
    "Keys": {
      "Hanzi": 2438,
      "Word": 8754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "Grade": 7,
      "GeneralStandard": 2438,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 120,
      "StrokeCount": 11
    }
  },
  "巩固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒnggù",
      "PrimaryDefinition": "to consolidate, consolidation, to strengthen"
    },
    "Keys": {
      "Word": 4614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "风景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngjǐng",
      "PrimaryDefinition": "view, aspect, vista, panorama, scenery, prospect, scene, sight, landscape"
    },
    "Keys": {
      "Word": 2470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "回答": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huídá",
      "PrimaryDefinition": "answer, reply"
    },
    "Keys": {
      "Word": 150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "强度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángdù",
      "PrimaryDefinition": "strength, intension, kick, degree, emphasis, intensity, depth, intensiveness"
    },
    "Keys": {
      "Word": 3807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "姆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1330,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 1061,
      "StrokeCount": 8
    }
  },
  "消化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāohuà",
      "PrimaryDefinition": "digest"
    },
    "Keys": {
      "Word": 3027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "输液": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū yè",
      "PrimaryDefinition": "intravenous infusion, to get put on an IV"
    },
    "Keys": {
      "Word": 9225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "便条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàntiáo",
      "PrimaryDefinition": "(informal) note, CL:張|张[zhang1],個|个[ge4]"
    },
    "Keys": {
      "Word": 3257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "离开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí kāi",
      "PrimaryDefinition": "leave, depart/deviate from"
    },
    "Keys": {
      "Word": 838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "嗐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài",
      "PrimaryDefinition": "alas!"
    },
    "Keys": {
      "Hanzi": 5474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5474,
      "Radical": "口",
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "侁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "crowd"
    },
    "Keys": {
      "Hanzi": 6695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6695,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "光顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānggù",
      "PrimaryDefinition": "to visit (as a customer)"
    },
    "Keys": {
      "Word": 6900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4045,
      "Radical": "邑",
      "FrequencyRank": 5342,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "箱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "case, box, chest, trunk"
    },
    "Keys": {
      "Hanzi": 3265,
      "Word": 3019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.9",
      "Grade": 4,
      "GeneralStandard": 3265,
      "Radical": "竹",
      "HSK": 3,
      "FrequencyRank": 1453,
      "StrokeCount": 15
    }
  },
  "大概": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàgài",
      "PrimaryDefinition": "chiefly, if not, perchance, approximately, believably, maybe, presumably, plausibly, almost, conceivably, most likely, assumedly, ablings, likely, perhaps, probably, supposedly, ablins, credibly, assumably, belike"
    },
    "Keys": {
      "Word": 1383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "千军万马": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānjūn-wànmǎ",
      "PrimaryDefinition": "magnificent army with thousands of men and horses (idiom), impressive display of manpower, all the King's horses and all the King's men"
    },
    "Keys": {
      "Word": 8648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帮手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāngshou",
      "PrimaryDefinition": "helper, assistant"
    },
    "Keys": {
      "Word": 5504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "列举": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièjǔ",
      "PrimaryDefinition": "chronicle, cite item by item, specify, list, marshal, number, enumeration, article, particularize, rehearse, count, recite, enumerate, itemize"
    },
    "Keys": {
      "Word": 8050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hē",
      "PrimaryDefinition": "scold loudly, curse, abuse"
    },
    "Keys": {
      "Hanzi": 3795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3795,
      "Radical": "言",
      "FrequencyRank": 3687,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "佸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huó",
      "PrimaryDefinition": "meet"
    },
    "Keys": {
      "Hanzi": 6697
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6697,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "糊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "paste, stick on with paste"
    },
    "Keys": {
      "Hanzi": 3287,
      "Word": 7118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.9",
      "Grade": 7,
      "GeneralStandard": 3287,
      "Radical": "米",
      "HSK": 5,
      "FrequencyRank": 1393,
      "StrokeCount": 15
    }
  },
  "柝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4152,
      "Radical": "木",
      "FrequencyRank": 6680,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "平衡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínghéng",
      "PrimaryDefinition": "balance"
    },
    "Keys": {
      "Word": 4908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "萄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "grapes"
    },
    "Keys": {
      "Hanzi": 2208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2208,
      "Radical": "艸",
      "HSK": 5,
      "FrequencyRank": 2210,
      "StrokeCount": 11
    }
  },
  "多余": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōyú",
      "PrimaryDefinition": "spare, redundant, unnecessary, superfluous, uncalled-for, surplus"
    },
    "Keys": {
      "Word": 6400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分裂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnliè",
      "PrimaryDefinition": "split, divide, break up"
    },
    "Keys": {
      "Word": 4564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "粗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cū",
      "PrimaryDefinition": "rough, thick, course; rude"
    },
    "Keys": {
      "Hanzi": 2403,
      "Word": 2354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.5",
      "Grade": 4,
      "GeneralStandard": 2403,
      "Radical": "米",
      "HSK": 4,
      "FrequencyRank": 1414,
      "StrokeCount": 11
    }
  },
  "供求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngqiú",
      "PrimaryDefinition": "supply and demand (economics)"
    },
    "Keys": {
      "Word": 6798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "终点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngdiǎn",
      "PrimaryDefinition": "terminal point, destination, finish line"
    },
    "Keys": {
      "Word": 4242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "同意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngyì",
      "PrimaryDefinition": "agree, consent, approve"
    },
    "Keys": {
      "Word": 1975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "扑克": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pūkè",
      "PrimaryDefinition": "poker (game) (loanword), deck of playing cards"
    },
    "Keys": {
      "Word": 8578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "pound; weigh"
    },
    "Keys": {
      "Hanzi": 3227,
      "Word": 5511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.1",
      "Grade": 7,
      "GeneralStandard": 3227,
      "Radical": "石",
      "HSK": 7,
      "FrequencyRank": 2941,
      "StrokeCount": 15
    }
  },
  "掉队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào duì",
      "PrimaryDefinition": "to fall behind, to drop out"
    },
    "Keys": {
      "Word": 6293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hén",
      "PrimaryDefinition": "scar; mark; trace"
    },
    "Keys": {
      "Hanzi": 2383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.6",
      "GeneralStandard": 2383,
      "Radical": "疒",
      "HSK": 7,
      "FrequencyRank": 1938,
      "StrokeCount": 11
    }
  },
  "绶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "silk ribbon attached as a seal"
    },
    "Keys": {
      "Hanzi": 5035
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5035,
      "Radical": "糸",
      "FrequencyRank": 4098,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "伟大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěidà",
      "PrimaryDefinition": "great, mighty"
    },
    "Keys": {
      "Word": 2006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "联网": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián wǎng",
      "PrimaryDefinition": "network, cyber-"
    },
    "Keys": {
      "Word": 8022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "算盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suànpan",
      "PrimaryDefinition": "abacus, CL:把[ba3], plan, scheme"
    },
    "Keys": {
      "Word": 9359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngnián",
      "PrimaryDefinition": "middle age"
    },
    "Keys": {
      "Word": 1223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "帼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guó",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4858,
      "Radical": "巾",
      "FrequencyRank": 4778,
      "RadicalIndex": "50.8",
      "StrokeCount": 11
    }
  },
  "粮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "food, grain, provisions"
    },
    "Keys": {
      "Hanzi": 2998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "119.7",
      "GeneralStandard": 2998,
      "Radical": "米",
      "HSK": 4,
      "FrequencyRank": 1303,
      "StrokeCount": 13
    }
  },
  "奖学金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngxuéjīn",
      "PrimaryDefinition": "burse, stipend, foundation, exhibition, studentship, scholarship"
    },
    "Keys": {
      "Word": 2613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "较量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoliàng",
      "PrimaryDefinition": "to have a contest with sb, to cross swords, to measure up against, to compete with, to haggle, to quibble"
    },
    "Keys": {
      "Word": 7491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "clothes"
    },
    "Keys": {
      "Radical": 145,
      "Hanzi": 520
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145",
      "GeneralStandard": 520,
      "Radical": "衣",
      "HSK": 1,
      "FrequencyRank": 725,
      "StrokeCount": 6
    }
  },
  "仔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "small thing, child; young animal"
    },
    "Keys": {
      "Hanzi": 276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "GeneralStandard": 276,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1572,
      "StrokeCount": 5
    }
  },
  "嘘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "exhale; blow out; deep sigh; hiss; praise"
    },
    "Keys": {
      "Hanzi": 5715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5715,
      "Radical": "口",
      "FrequencyRank": 2973,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "公益性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyì xìng",
      "PrimaryDefinition": "Public welfare"
    },
    "Keys": {
      "Word": 6777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "网民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngmín",
      "PrimaryDefinition": "web user, netizen"
    },
    "Keys": {
      "Word": 9670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隔开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gékāi",
      "PrimaryDefinition": "to separate"
    },
    "Keys": {
      "Word": 2495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "饼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "rice-cakes, biscuits"
    },
    "Keys": {
      "Hanzi": 1614,
      "Word": 3264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.6",
      "Grade": 5,
      "GeneralStandard": 1614,
      "Radical": "食",
      "HSK": 5,
      "FrequencyRank": 2359,
      "StrokeCount": 9
    }
  },
  "摄氏度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèshìdù",
      "PrimaryDefinition": "degrees centigrade"
    },
    "Keys": {
      "Word": 9011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàn",
      "PrimaryDefinition": "to manipulate; a pricker for a lamp-wick"
    },
    "Keys": {
      "Hanzi": 7106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7106,
      "Radical": "手",
      "FrequencyRank": 7987,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "妧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6635,
      "Radical": "女",
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "磁卡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíkǎ",
      "PrimaryDefinition": "magnetic card, IC Card (telephone)"
    },
    "Keys": {
      "Word": 6044
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杂交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zájiāo",
      "PrimaryDefinition": "to hybridize, to crossbreed, promiscuity"
    },
    "Keys": {
      "Word": 10584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "考": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎo",
      "PrimaryDefinition": "examine, test; investigate"
    },
    "Keys": {
      "Hanzi": 358,
      "Word": 192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "125",
      "Grade": 1,
      "GeneralStandard": 358,
      "Radical": "老",
      "HSK": 1,
      "FrequencyRank": 495,
      "StrokeCount": 6
    }
  },
  "庆幸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìngxìng",
      "PrimaryDefinition": "rejoice at a good outcome"
    },
    "Keys": {
      "Word": 8766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "优": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "superior, excellent; actor"
    },
    "Keys": {
      "Hanzi": 461,
      "Word": 10446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 7,
      "GeneralStandard": 461,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 774,
      "StrokeCount": 6
    }
  },
  "杀毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā dú",
      "PrimaryDefinition": "Antivirus"
    },
    "Keys": {
      "Word": 3856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "难为情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánwéiqíng",
      "PrimaryDefinition": "embarrassed"
    },
    "Keys": {
      "Word": 8380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "curium"
    },
    "Keys": {
      "Hanzi": 5189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5189,
      "Radical": "金",
      "FrequencyRank": 6157,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "穹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "high and vast; elevated; arched"
    },
    "Keys": {
      "Hanzi": 4036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4036,
      "Radical": "穴",
      "FrequencyRank": 3521,
      "RadicalIndex": "116.3",
      "StrokeCount": 8
    }
  },
  "屿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "island"
    },
    "Keys": {
      "Hanzi": 434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.3",
      "GeneralStandard": 434,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 2636,
      "StrokeCount": 6
    }
  },
  "袍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páo",
      "PrimaryDefinition": "long gown, robe, cloak"
    },
    "Keys": {
      "Hanzi": 2117
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.5",
      "GeneralStandard": 2117,
      "Radical": "衣",
      "HSK": 9,
      "FrequencyRank": 2324,
      "StrokeCount": 10
    }
  },
  "房东": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángdōng",
      "PrimaryDefinition": "landlord"
    },
    "Keys": {
      "Word": 1445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "品种": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐnzhǒng",
      "PrimaryDefinition": "breed, variety, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "浴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "bathe, wash; bath"
    },
    "Keys": {
      "Hanzi": 2076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2076,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2290,
      "StrokeCount": 10
    }
  },
  "籀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "recite, read; style of calligraphy"
    },
    "Keys": {
      "Hanzi": 6398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6398,
      "Radical": "竹",
      "FrequencyRank": 6220,
      "RadicalIndex": "118.13",
      "StrokeCount": 19
    }
  },
  "座右铭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòyòumíng",
      "PrimaryDefinition": "motto, maxim"
    },
    "Keys": {
      "Word": 11032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōng",
      "PrimaryDefinition": "radon"
    },
    "Keys": {
      "Hanzi": 4247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4247,
      "Radical": "气",
      "FrequencyRank": 5497,
      "RadicalIndex": "84.5",
      "StrokeCount": 9
    }
  },
  "缺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quē",
      "PrimaryDefinition": "be short of, lack; gap, deficit"
    },
    "Keys": {
      "Hanzi": 1935,
      "Word": 1835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "121.4",
      "Grade": 3,
      "GeneralStandard": 1935,
      "Radical": "缶",
      "HSK": 3,
      "FrequencyRank": 875,
      "StrokeCount": 10
    }
  },
  "人间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénjiān",
      "PrimaryDefinition": "the human world"
    },
    "Keys": {
      "Word": 3836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "原谅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánliàng",
      "PrimaryDefinition": "excuse, pardon"
    },
    "Keys": {
      "Word": 5290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "同伴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngbàn",
      "PrimaryDefinition": "associate, friend, associator, chum, gentry, cully, familiar, fellow traveller, accompanist, fellow traveler, stablemate, companion, mate, compeer, peer, pard, bunkie, consociate, fellow, buddy, campmate, comrade"
    },
    "Keys": {
      "Word": 9531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "更改": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnggǎi",
      "PrimaryDefinition": "change, alter"
    },
    "Keys": {
      "Word": 6748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扫除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎochú",
      "PrimaryDefinition": "to sweep, to clean with a brush, to sweep away (often fig.)"
    },
    "Keys": {
      "Word": 8927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngguǒ",
      "PrimaryDefinition": "product, achievement, outcome, gain, production, fruit, positive result, progeny, job"
    },
    "Keys": {
      "Word": 1348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "窦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòu",
      "PrimaryDefinition": "surname; hole, burrow; corrupt"
    },
    "Keys": {
      "Hanzi": 5593
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5593,
      "Radical": "穴",
      "FrequencyRank": 2895,
      "RadicalIndex": "116.8",
      "StrokeCount": 13
    }
  },
  "舒服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūfu",
      "PrimaryDefinition": "comfortable"
    },
    "Keys": {
      "Word": 988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "面貌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànmào",
      "PrimaryDefinition": "aspect, face, physiognomy, look, vestige, visage, appearance, features"
    },
    "Keys": {
      "Word": 3721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "尤其": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuqí",
      "PrimaryDefinition": "especially"
    },
    "Keys": {
      "Word": 4137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "富足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzú",
      "PrimaryDefinition": "rich, plentiful"
    },
    "Keys": {
      "Word": 6647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "探测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàncè",
      "PrimaryDefinition": "to probe, to take readings, to explore, exploration"
    },
    "Keys": {
      "Word": 9417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绯闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēiwén",
      "PrimaryDefinition": "sex scandal"
    },
    "Keys": {
      "Word": 6518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回避": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíbì",
      "PrimaryDefinition": "to shun, to avoid (sb), to skirt, to evade (an issue), to step back, to withdraw, to recuse (a judge etc)"
    },
    "Keys": {
      "Word": 3560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "宽阔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānkuò",
      "PrimaryDefinition": "expansive, wide, width, thickness"
    },
    "Keys": {
      "Word": 4804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "棱角": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léngjiǎo",
      "PrimaryDefinition": "edge and corner, protrusion, sharpness (of a protrusion), craggy, ridge corner"
    },
    "Keys": {
      "Word": 7963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耽搁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānge",
      "PrimaryDefinition": "to tarry, to delay, to stop over"
    },
    "Keys": {
      "Word": 6162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戒烟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè yān",
      "PrimaryDefinition": "to give up smoking"
    },
    "Keys": {
      "Word": 7547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宗教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōngjiào",
      "PrimaryDefinition": "theology, community, religious, cult, creed, faith, religion"
    },
    "Keys": {
      "Word": 5399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "照样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàoyàng",
      "PrimaryDefinition": "as before, (same) as usual, in the same manner, still, nevertheless"
    },
    "Keys": {
      "Word": 5332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "新人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnrén",
      "PrimaryDefinition": "newlywed"
    },
    "Keys": {
      "Word": 5186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "篾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miè",
      "PrimaryDefinition": "bamboo splints or slats"
    },
    "Keys": {
      "Hanzi": 6256
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6256,
      "Radical": "竹",
      "FrequencyRank": 4892,
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "蛞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuò",
      "PrimaryDefinition": "snail; slug, mole cricket"
    },
    "Keys": {
      "Hanzi": 5148
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5148,
      "Radical": "虫",
      "FrequencyRank": 4993,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "疑问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíwèn",
      "PrimaryDefinition": "interrogation, enquiry, question, interrogatory, demand, dubiousness, inquiry, interrogative, interrogative mood, doubtfulness, query, quaere, doubt"
    },
    "Keys": {
      "Word": 3095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "根基": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnjī",
      "PrimaryDefinition": "foundation"
    },
    "Keys": {
      "Word": 6742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "friendly, amiable, peaceful"
    },
    "Keys": {
      "Hanzi": 2897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.8",
      "GeneralStandard": 2897,
      "Radical": "目",
      "HSK": 8,
      "FrequencyRank": 3019,
      "StrokeCount": 13
    }
  },
  "自卫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìwèi",
      "PrimaryDefinition": "self-defense"
    },
    "Keys": {
      "Word": 10964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叮嘱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīngzhǔ",
      "PrimaryDefinition": "to warn repeatedly, to urge, to exhort again and again"
    },
    "Keys": {
      "Word": 6299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "西南": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnán",
      "PrimaryDefinition": "southwest"
    },
    "Keys": {
      "Word": 1071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "镢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "hoe"
    },
    "Keys": {
      "Hanzi": 6246
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6246,
      "Radical": "金",
      "FrequencyRank": 5288,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "右": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "right; west; right-wing"
    },
    "Keys": {
      "Hanzi": 217,
      "Word": 444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 1,
      "GeneralStandard": 217,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 783,
      "StrokeCount": 5
    }
  },
  "随后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíhòu",
      "PrimaryDefinition": "soon after"
    },
    "Keys": {
      "Word": 3947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蔼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎi",
      "PrimaryDefinition": "lush; affable, friendly"
    },
    "Keys": {
      "Hanzi": 3074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.11",
      "GeneralStandard": 3074,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 3460,
      "StrokeCount": 14
    }
  },
  "熻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "to heat; to roast; to burn"
    },
    "Keys": {
      "Hanzi": 7898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7898,
      "Radical": "火",
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "喔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ō",
      "PrimaryDefinition": "descriptive of crying or of crowing"
    },
    "Keys": {
      "Hanzi": 5161
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5161,
      "Radical": "口",
      "FrequencyRank": 2860,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "袢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "robe"
    },
    "Keys": {
      "Hanzi": 4672
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4672,
      "Radical": "衣",
      "FrequencyRank": 5217,
      "RadicalIndex": "145.5",
      "StrokeCount": 10
    }
  },
  "沅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "name of a river in western Hunan that flows into Dongting lake"
    },
    "Keys": {
      "Hanzi": 3767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3767,
      "Radical": "水",
      "FrequencyRank": 4212,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "自负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìfù",
      "PrimaryDefinition": "conceited, to take responsibility"
    },
    "Keys": {
      "Word": 10955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "forehead; title, headline; theme"
    },
    "Keys": {
      "Hanzi": 3233,
      "Word": 1020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.9",
      "Grade": 2,
      "GeneralStandard": 3233,
      "Radical": "頁",
      "HSK": 2,
      "FrequencyRank": 218,
      "StrokeCount": 15
    }
  },
  "鲁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": "foolish, stupid, rash; vulgar"
    },
    "Keys": {
      "Hanzi": 2717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "195.4",
      "GeneralStandard": 2717,
      "Radical": "魚",
      "HSK": 7,
      "FrequencyRank": 898,
      "StrokeCount": 12
    }
  },
  "雅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎ",
      "PrimaryDefinition": "elegant, graceful, refined"
    },
    "Keys": {
      "Hanzi": 2600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "172.4",
      "GeneralStandard": 2600,
      "Radical": "隹",
      "HSK": 7,
      "FrequencyRank": 1139,
      "StrokeCount": 12
    }
  },
  "由衷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuzhōng",
      "PrimaryDefinition": "heartfelt, sincere, unfeigned"
    },
    "Keys": {
      "Word": 10462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lī",
      "PrimaryDefinition": "mile"
    },
    "Keys": {
      "Hanzi": 1905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1905,
      "Radical": "口",
      "FrequencyRank": 1949,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "坡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō",
      "PrimaryDefinition": "slope, bank, hillside"
    },
    "Keys": {
      "Hanzi": 989,
      "Word": 4917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.5",
      "Grade": 6,
      "GeneralStandard": 989,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 1550,
      "StrokeCount": 8
    }
  },
  "暑假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔjià",
      "PrimaryDefinition": "summer vacation, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "唷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yō",
      "PrimaryDefinition": "final particle"
    },
    "Keys": {
      "Hanzi": 4847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4847,
      "Radical": "口",
      "FrequencyRank": 3964,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "嘁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "to be grieved; ashamed; used to imitate sounds"
    },
    "Keys": {
      "Hanzi": 5698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5698,
      "Radical": "口",
      "FrequencyRank": 4513,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "猥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "vulgar, low, cheap; wanton; obscene"
    },
    "Keys": {
      "Hanzi": 5241
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5241,
      "Radical": "犬",
      "FrequencyRank": 4270,
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "一如既往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīrú-jìwǎng",
      "PrimaryDefinition": "just as in the past (idiom), as before, continuing as always"
    },
    "Keys": {
      "Word": 10338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碰撞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèngzhuàng",
      "PrimaryDefinition": "to collide, collision"
    },
    "Keys": {
      "Word": 8501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "船桨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánjiǎng",
      "PrimaryDefinition": "Marker"
    },
    "Keys": {
      "Word": 6013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "crossbeam above or under gate"
    },
    "Keys": {
      "Hanzi": 5406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5406,
      "Radical": "木",
      "FrequencyRank": 4048,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "沨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3777,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "朴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "simple, unadorned; sincere; surname; a tree"
    },
    "Keys": {
      "Hanzi": 376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "GeneralStandard": 376,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2092,
      "StrokeCount": 6
    }
  },
  "蒴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "seed (capsule)"
    },
    "Keys": {
      "Hanzi": 5388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5388,
      "Radical": "艸",
      "FrequencyRank": 6043,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "队伍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìwu",
      "PrimaryDefinition": "cue, procession, contingent, ranks, troops, gang"
    },
    "Keys": {
      "Word": 4525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "漤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "marinate in salt"
    },
    "Keys": {
      "Hanzi": 7725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7725,
      "Radical": "水",
      "FrequencyRank": 8388,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "淖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nào",
      "PrimaryDefinition": "slush; mud"
    },
    "Keys": {
      "Hanzi": 4970
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4970,
      "Radical": "水",
      "FrequencyRank": 3966,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "幺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "thread"
    },
    "Keys": {
      "Radical": 52,
      "Hanzi": 3507
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3507,
      "Radical": "幺",
      "FrequencyRank": 4505,
      "RadicalIndex": "52",
      "StrokeCount": 3
    }
  },
  "提炼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíliàn",
      "PrimaryDefinition": "to extract (ore, minerals etc), to refine, to purify, to process"
    },
    "Keys": {
      "Word": 9458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蟑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "cockroach"
    },
    "Keys": {
      "Hanzi": 6235
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6235,
      "Radical": "虫",
      "FrequencyRank": 4269,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "渺小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎoxiǎo",
      "PrimaryDefinition": "minute, tiny, negligible, insignificant"
    },
    "Keys": {
      "Word": 8293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慰问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèiwèn",
      "PrimaryDefinition": "to express sympathy, greetings, consolation etc"
    },
    "Keys": {
      "Word": 4010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "铙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "cymbals; hand bell; disturb"
    },
    "Keys": {
      "Hanzi": 4868
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4868,
      "Radical": "金",
      "FrequencyRank": 6373,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "来回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láihuí",
      "PrimaryDefinition": "go to a place and come back, make a round trip, make a return journey, move to and fro, move back and forth, go to and fro"
    },
    "Keys": {
      "Word": 7918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎi",
      "PrimaryDefinition": "person of reprehensible morals; immoral; adulterer"
    },
    "Keys": {
      "Hanzi": 6568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6568,
      "Radical": "毋",
      "RadicalIndex": "80.3",
      "StrokeCount": 7
    }
  },
  "竭尽全力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéjìn-quánlì",
      "PrimaryDefinition": "to spare no effort (idiom), to do one's utmost"
    },
    "Keys": {
      "Word": 7532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "向着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngzhe",
      "PrimaryDefinition": "Toward"
    },
    "Keys": {
      "Word": 9951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忧郁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuyù",
      "PrimaryDefinition": "sullen, depressed, melancholy, dejected"
    },
    "Keys": {
      "Word": 10455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "where? what? how? why?; servant"
    },
    "Keys": {
      "Hanzi": 4587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4587,
      "Radical": "大",
      "FrequencyRank": 3395,
      "RadicalIndex": "37.7",
      "StrokeCount": 10
    }
  },
  "词典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cídiǎn",
      "PrimaryDefinition": "lexicon, dictionary"
    },
    "Keys": {
      "Word": 577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "春": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūn",
      "PrimaryDefinition": "spring; wanton"
    },
    "Keys": {
      "Hanzi": 1355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "GeneralStandard": 1355,
      "Radical": "日",
      "HSK": 2,
      "FrequencyRank": 921,
      "StrokeCount": 9
    }
  },
  "举办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔbàn",
      "PrimaryDefinition": "conduct, hold, run"
    },
    "Keys": {
      "Word": 1651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "贯通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàntōng",
      "PrimaryDefinition": "to link up, to thread together"
    },
    "Keys": {
      "Word": 6889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒushuǐ",
      "PrimaryDefinition": "spit, slobber, drivel, spittle, saliva, slaver, drool, dribble"
    },
    "Keys": {
      "Word": 7844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "stumble, fall down; wrestle"
    },
    "Keys": {
      "Hanzi": 2919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.6",
      "GeneralStandard": 2919,
      "Radical": "足",
      "HSK": 7,
      "FrequencyRank": 3715,
      "StrokeCount": 13
    }
  },
  "关照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānzhào",
      "PrimaryDefinition": "to take care, to keep an eye on, to look after, to tell, to remind"
    },
    "Keys": {
      "Word": 6869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "连绵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánmián",
      "PrimaryDefinition": "continuous, unbroken, uninterrupted, extending forever into the distance (of mountain range, river etc)"
    },
    "Keys": {
      "Word": 8010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外貌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàimào",
      "PrimaryDefinition": "profile, appearance"
    },
    "Keys": {
      "Word": 9640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "栗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "chestnut tree, chestnuts; surname"
    },
    "Keys": {
      "Hanzi": 1855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1855,
      "Radical": "木",
      "FrequencyRank": 2595,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "翡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "kingfisher; emerald, jade"
    },
    "Keys": {
      "Hanzi": 5689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5689,
      "Radical": "羽",
      "FrequencyRank": 4135,
      "RadicalIndex": "124.8",
      "StrokeCount": 14
    }
  },
  "露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "dew; bare, open, exposed"
    },
    "Keys": {
      "Hanzi": 3490,
      "Word": 4843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.12",
      "Grade": 6,
      "GeneralStandard": 3490,
      "Radical": "雨",
      "HSK": 6,
      "FrequencyRank": 841,
      "StrokeCount": 21
    }
  },
  "羚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "species of antelope"
    },
    "Keys": {
      "Hanzi": 2399
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2399,
      "Radical": "羊",
      "FrequencyRank": 3859,
      "RadicalIndex": "123.5",
      "StrokeCount": 11
    }
  },
  "士兵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìbīng",
      "PrimaryDefinition": "rank-and-file soldiers"
    },
    "Keys": {
      "Word": 2866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "忘记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngjì",
      "PrimaryDefinition": "misremember, block, blank out, neglect, overlook, draw a blank, leave_out, disremember, fail, forget"
    },
    "Keys": {
      "Word": 374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "拆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāi",
      "PrimaryDefinition": "to break up, split apart, rip open; to destroy"
    },
    "Keys": {
      "Hanzi": 972,
      "Word": 3308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 5,
      "GeneralStandard": 972,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 2076,
      "StrokeCount": 8
    }
  },
  "援助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánzhù",
      "PrimaryDefinition": "support, aid"
    },
    "Keys": {
      "Word": 5293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "赞同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàntóng",
      "PrimaryDefinition": "approve of, endorse"
    },
    "Keys": {
      "Word": 10600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "研": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "grind, rub; study, research"
    },
    "Keys": {
      "Hanzi": 1446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.6",
      "GeneralStandard": 1446,
      "Radical": "石",
      "HSK": 4,
      "FrequencyRank": 447,
      "StrokeCount": 9
    }
  },
  "谦逊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānxùn",
      "PrimaryDefinition": "humble, modest, unpretentious, modesty"
    },
    "Keys": {
      "Word": 8658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng jiā",
      "PrimaryDefinition": "to settle down and get married (of a man), to become a recognized expert"
    },
    "Keys": {
      "Word": 5868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.1",
      "GeneralStandard": 5686,
      "Radical": "車",
      "HSK": 6,
      "FrequencyRank": 3831,
      "StrokeCount": 14
    }
  },
  "佶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "strong, robust; exact, correct"
    },
    "Keys": {
      "Hanzi": 3950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3950,
      "Radical": "人",
      "FrequencyRank": 5486,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "新年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnnián",
      "PrimaryDefinition": "New Year, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "资本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīběn",
      "PrimaryDefinition": "capitalization, principal, principal sum, finance, sth. capitalized on, corpus, working capital, capital"
    },
    "Keys": {
      "Word": 4270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "解说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěshuō",
      "PrimaryDefinition": "explain orally, appease"
    },
    "Keys": {
      "Word": 4735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "骙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "(of a horse) lively; vigorous"
    },
    "Keys": {
      "Hanzi": 7460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7460,
      "Radical": "馬",
      "FrequencyRank": 6594,
      "RadicalIndex": "187.9",
      "StrokeCount": 12
    }
  },
  "咿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "descriptive of creaking; laugh"
    },
    "Keys": {
      "Hanzi": 4220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4220,
      "Radical": "口",
      "FrequencyRank": 4740,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "无能为力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúnéngwéilì",
      "PrimaryDefinition": "impotent (idiom), powerless, helpless"
    },
    "Keys": {
      "Word": 9778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kào",
      "PrimaryDefinition": "shackles, manacles"
    },
    "Keys": {
      "Hanzi": 2303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2303,
      "Radical": "金",
      "FrequencyRank": 3760,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "魇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "nightmare, bad dreams"
    },
    "Keys": {
      "Hanzi": 5893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5893,
      "Radical": "鬼",
      "FrequencyRank": 4294,
      "RadicalIndex": "194.6",
      "StrokeCount": 15
    }
  },
  "瞿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 6328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6328,
      "Radical": "目",
      "FrequencyRank": 3305,
      "RadicalIndex": "109.13",
      "StrokeCount": 18
    }
  },
  "囱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "chimney"
    },
    "Keys": {
      "Hanzi": 790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "31.4",
      "GeneralStandard": 790,
      "Radical": "囗",
      "HSK": 8,
      "FrequencyRank": 3555,
      "StrokeCount": 7
    }
  },
  "伺机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìjī",
      "PrimaryDefinition": "to wait for an opportunity, to watch for one's chance"
    },
    "Keys": {
      "Word": 9329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐ",
      "PrimaryDefinition": "strip, tear off, undress"
    },
    "Keys": {
      "Hanzi": 6013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6013,
      "Radical": "衣",
      "FrequencyRank": 5631,
      "RadicalIndex": "145.1",
      "StrokeCount": 15
    }
  },
  "木板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùbǎn",
      "PrimaryDefinition": "batten, deal, board, plank, planch, softwood"
    },
    "Keys": {
      "Word": 8342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "like, admire; willingly, gladly; to quicken"
    },
    "Keys": {
      "Hanzi": 5549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5549,
      "Radical": "欠",
      "FrequencyRank": 4418,
      "RadicalIndex": "76.9",
      "StrokeCount": 13
    }
  },
  "灞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "river in Shanxi province"
    },
    "Keys": {
      "Hanzi": 6491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6491,
      "Radical": "水",
      "FrequencyRank": 5624,
      "RadicalIndex": "85.21",
      "StrokeCount": 24
    }
  },
  "后年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòunián",
      "PrimaryDefinition": "the year after next"
    },
    "Keys": {
      "Word": 1552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "进来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn lai",
      "PrimaryDefinition": "come in, enter"
    },
    "Keys": {
      "Word": 180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "墩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": "heap, mound, block of stone"
    },
    "Keys": {
      "Hanzi": 3201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3201,
      "Radical": "土",
      "FrequencyRank": 3553,
      "RadicalIndex": "32.12",
      "StrokeCount": 15
    }
  },
  "辀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "shaft"
    },
    "Keys": {
      "Hanzi": 6977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6977,
      "Radical": "車",
      "FrequencyRank": 6996,
      "RadicalIndex": "159.6",
      "StrokeCount": 10
    }
  },
  "手枪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuqiāng",
      "PrimaryDefinition": "pistol"
    },
    "Keys": {
      "Word": 9177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "ear of grain; ripe grain"
    },
    "Keys": {
      "Hanzi": 7950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7950,
      "Radical": "禾",
      "FrequencyRank": 9863,
      "RadicalIndex": "115.13",
      "StrokeCount": 17
    }
  },
  "极": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "extreme, utmost, furthest, final"
    },
    "Keys": {
      "Hanzi": 687,
      "Word": 2579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "Grade": 4,
      "GeneralStandard": 687,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 363,
      "StrokeCount": 7
    }
  },
  "帘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "flag-sign of a tavern"
    },
    "Keys": {
      "Hanzi": 1283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.5",
      "GeneralStandard": 1283,
      "Radical": "巾",
      "HSK": 5,
      "FrequencyRank": 2458,
      "StrokeCount": 8
    }
  },
  "不能不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùnéngbù",
      "PrimaryDefinition": "have to, cannot but"
    },
    "Keys": {
      "Word": 3282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "漫游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànyóu",
      "PrimaryDefinition": "to travel around, to roam, (mobile telephony) roaming"
    },
    "Keys": {
      "Word": 8201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "元旦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yuándàn",
      "PrimaryDefinition": "New Year's Day"
    },
    "Keys": {
      "Word": 4150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "襦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "short coat, jacket; fine silk fab"
    },
    "Keys": {
      "Hanzi": 6415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6415,
      "Radical": "衣",
      "FrequencyRank": 6714,
      "RadicalIndex": "145.14",
      "StrokeCount": 19
    }
  },
  "絮叨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùdao",
      "PrimaryDefinition": "long-winded, garrulous, to talk endlessly without getting to the point"
    },
    "Keys": {
      "Word": 10107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒng",
      "PrimaryDefinition": "element mercury"
    },
    "Keys": {
      "Hanzi": 628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 628,
      "Radical": "水",
      "FrequencyRank": 3772,
      "RadicalIndex": "85.3",
      "StrokeCount": 7
    }
  },
  "做客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò kè",
      "PrimaryDefinition": "to be a guest or visitor"
    },
    "Keys": {
      "Word": 2225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "了不起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎobuqǐ",
      "PrimaryDefinition": "amazing, terrific, extraordinary"
    },
    "Keys": {
      "Word": 2702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "独一无二": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúyī-wúèr",
      "PrimaryDefinition": "unique and unmatched (idiom), unrivalled, nothing compares with it"
    },
    "Keys": {
      "Word": 6361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "框架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàngjià",
      "PrimaryDefinition": "frame, framework, fig. pattern, outline, organizing plan"
    },
    "Keys": {
      "Word": 7893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yītóu",
      "PrimaryDefinition": "one head, a head full of sth, one end (of a stick), one side, headlong, directly, rapidly, simultaneously"
    },
    "Keys": {
      "Word": 10345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "colt; fleet, swift; sun; surname"
    },
    "Keys": {
      "Hanzi": 1343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1343,
      "Radical": "馬",
      "FrequencyRank": 3691,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "委员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěiyuán",
      "PrimaryDefinition": "commissar, committee man, committee, committee member, commissioner, committeeman, delegate, board member, commissary"
    },
    "Keys": {
      "Word": 9709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "离谱儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí pǔr5",
      "PrimaryDefinition": "Scientific"
    },
    "Keys": {
      "Word": 7973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "thousandth part of tael"
    },
    "Keys": {
      "Hanzi": 1448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.7",
      "GeneralStandard": 1448,
      "Radical": "厂",
      "HSK": 4,
      "FrequencyRank": 2262,
      "StrokeCount": 9
    }
  },
  "身价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnjià",
      "PrimaryDefinition": "social status, price of a slave, price of a person (a sportsman etc), worth, value (of stocks, valuables etc)"
    },
    "Keys": {
      "Word": 9022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "ancient emperor; breath, vapor"
    },
    "Keys": {
      "Hanzi": 6166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6166,
      "Radical": "羊",
      "FrequencyRank": 3942,
      "RadicalIndex": "123.1",
      "StrokeCount": 16
    }
  },
  "并购": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìnggòu",
      "PrimaryDefinition": "merge into"
    },
    "Keys": {
      "Word": 5651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泄漏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xièlòu",
      "PrimaryDefinition": "(of a liquid or gas) to leak, to divulge (a secret), to leak"
    },
    "Keys": {
      "Word": 9998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūfā",
      "PrimaryDefinition": "depart, get_off, proceed, take_the_road, part, set out, start from, start out, sidetrack, start/proceed from, stray, take the road, sally out, sally_forth, proceed from, set_off, sally forth, take off, start, set forth, set off, digress, straggle, start off, move"
    },
    "Keys": {
      "Word": 562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "龂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "gums (of the teeth); to dispute"
    },
    "Keys": {
      "Hanzi": 7346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7346,
      "Radical": "齒",
      "FrequencyRank": 6692,
      "RadicalIndex": "211.4",
      "StrokeCount": 12
    }
  },
  "重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòng",
      "PrimaryDefinition": "heavy, weighty; double"
    },
    "Keys": {
      "Hanzi": 1552,
      "Word": 1362
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "166.2",
      "Grade": 3,
      "GeneralStandard": 1552,
      "Radical": "里",
      "HSK": 1,
      "FrequencyRank": 140,
      "StrokeCount": 9
    }
  },
  "军官": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnguān",
      "PrimaryDefinition": "officer (military)"
    },
    "Keys": {
      "Word": 7729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "漏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòu",
      "PrimaryDefinition": "leak, drip; funnel; hour glass"
    },
    "Keys": {
      "Hanzi": 3168,
      "Word": 3705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 5,
      "GeneralStandard": 3168,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 2052,
      "StrokeCount": 14
    }
  },
  "舯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7025
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7025,
      "Radical": "舟",
      "FrequencyRank": 6172,
      "RadicalIndex": "137.4",
      "StrokeCount": 10
    }
  },
  "合伙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héhuǒ",
      "PrimaryDefinition": "to act jointly, to form a partnership"
    },
    "Keys": {
      "Word": 7025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn kāi",
      "PrimaryDefinition": "rive, uncouple, unyoke, divaricate, detach, disjoint, cleave, shift, break_up, cut, segregate, isolate, divorce, severalize, unclasp, divide, dissever, disjoin, break, come off, demarcate, fork, sever, sequester, disconnect, part, come away, sunder, break up, keep apart, separate, diverge, branch, set_off, ramify, split, sequestrate, untwine, separation, set apart, parcel"
    },
    "Keys": {
      "Word": 658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "菩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "herb, aromatic plant"
    },
    "Keys": {
      "Hanzi": 2210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2210,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2209,
      "StrokeCount": 11
    }
  },
  "除了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúle",
      "PrimaryDefinition": "except, besides"
    },
    "Keys": {
      "Word": 1364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "余": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "I, my, me; surname; surplus"
    },
    "Keys": {
      "Hanzi": 795,
      "Word": 10495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 7,
      "GeneralStandard": 795,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 729,
      "StrokeCount": 7
    }
  },
  "想不到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngbudào",
      "PrimaryDefinition": "unable to anticipate"
    },
    "Keys": {
      "Word": 5166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "清洁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngjié",
      "PrimaryDefinition": "clean, sanitary"
    },
    "Keys": {
      "Word": 4944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "氚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuān",
      "PrimaryDefinition": "tritium"
    },
    "Keys": {
      "Hanzi": 3726
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3726,
      "Radical": "气",
      "FrequencyRank": 5050,
      "RadicalIndex": "84.3",
      "StrokeCount": 7
    }
  },
  "粑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "tsamba (food in Tibet)"
    },
    "Keys": {
      "Hanzi": 4634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4634,
      "Radical": "米",
      "FrequencyRank": 4587,
      "RadicalIndex": "119.4",
      "StrokeCount": 10
    }
  },
  "股": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "thighs, haunches, rump; share"
    },
    "Keys": {
      "Hanzi": 1186,
      "Word": 4620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 6,
      "GeneralStandard": 1186,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 644,
      "StrokeCount": 8
    }
  },
  "番": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān",
      "PrimaryDefinition": "to take turns; a turn, a time; to repeat"
    },
    "Keys": {
      "Hanzi": 2709,
      "Word": 4544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102.7",
      "Grade": 6,
      "GeneralStandard": 2709,
      "Radical": "田",
      "HSK": 6,
      "FrequencyRank": 1452,
      "StrokeCount": 12
    }
  },
  "心眼儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnyǎnr5",
      "PrimaryDefinition": "one's thoughts, mind, intention, willingness to accept new ideas, baseless suspicions"
    },
    "Keys": {
      "Word": 10017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "assemble, meet together, collect"
    },
    "Keys": {
      "Hanzi": 3065,
      "Word": 2650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "128.8",
      "Grade": 4,
      "GeneralStandard": 3065,
      "Radical": "耳",
      "HSK": 4,
      "FrequencyRank": 1306,
      "StrokeCount": 14
    }
  },
  "钔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mén",
      "PrimaryDefinition": "mendelevium (Md)"
    },
    "Keys": {
      "Hanzi": 6690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6690,
      "Radical": "金",
      "FrequencyRank": 5938,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "请假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng jià",
      "PrimaryDefinition": "vacate, ask for leave"
    },
    "Keys": {
      "Word": 294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "汇集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìjí",
      "PrimaryDefinition": "to collect, to compile, to converge"
    },
    "Keys": {
      "Word": 7216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1202,
      "Word": 510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.5",
      "Grade": 2,
      "GeneralStandard": 1202,
      "Radical": "食",
      "HSK": 2,
      "FrequencyRank": 1998,
      "StrokeCount": 8
    }
  },
  "结局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéjú",
      "PrimaryDefinition": "final result, outcome, ending"
    },
    "Keys": {
      "Word": 7527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "推选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīxuǎn",
      "PrimaryDefinition": "to elect, to choose, to nominate"
    },
    "Keys": {
      "Word": 9601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "jade"
    },
    "Keys": {
      "Hanzi": 6448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6448,
      "Radical": "玉",
      "RadicalIndex": "96.18",
      "StrokeCount": 21
    }
  },
  "李": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "plum; judge; surname"
    },
    "Keys": {
      "Hanzi": 688
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "GeneralStandard": 688,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 472,
      "StrokeCount": 7
    }
  },
  "陎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6738,
      "Radical": "阜",
      "RadicalIndex": "170.6",
      "StrokeCount": 8
    }
  },
  "肯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěn",
      "PrimaryDefinition": "willing; consent to, permit"
    },
    "Keys": {
      "Hanzi": 1067,
      "Word": 4797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 6,
      "GeneralStandard": 1067,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 860,
      "StrokeCount": 8
    }
  },
  "野生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěshēng",
      "PrimaryDefinition": "desert, wild, godforsaken, waste, feral, uncultivated"
    },
    "Keys": {
      "Word": 5227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "直至": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhízhì",
      "PrimaryDefinition": "lasting until, up till (the present)"
    },
    "Keys": {
      "Word": 10765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "设计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèjì",
      "PrimaryDefinition": "design, plan"
    },
    "Keys": {
      "Word": 1874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "手表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒubiǎo",
      "PrimaryDefinition": "wristwatch"
    },
    "Keys": {
      "Word": 986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "汇报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìbào",
      "PrimaryDefinition": "to report, to give an account of, report"
    },
    "Keys": {
      "Word": 2561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "诟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "abuse, scold, berate, insult"
    },
    "Keys": {
      "Hanzi": 4053
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4053,
      "Radical": "言",
      "FrequencyRank": 4844,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "涨价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎng jià",
      "PrimaryDefinition": "rise in price"
    },
    "Keys": {
      "Word": 4193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "涞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "river in Hebei province; creek"
    },
    "Keys": {
      "Hanzi": 4646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4646,
      "Radical": "水",
      "FrequencyRank": 4483,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "赛场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàichǎng",
      "PrimaryDefinition": "racetrack, field (for athletics competition)"
    },
    "Keys": {
      "Word": 4985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "扭头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔ tóu",
      "PrimaryDefinition": "to turn one's head, to turn around"
    },
    "Keys": {
      "Word": 8430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "模特儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mótèr5",
      "PrimaryDefinition": "poser, artist's model, model"
    },
    "Keys": {
      "Word": 2749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "繁重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánzhòng",
      "PrimaryDefinition": "heavy, burdensome, heavy-duty, arduous, onerous"
    },
    "Keys": {
      "Word": 6465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "背心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèixīn",
      "PrimaryDefinition": "sleeveless garment (vest, waistcoat, singlet, tank top etc), CL:件[jian4]"
    },
    "Keys": {
      "Word": 4314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "reason, logic; manage"
    },
    "Keys": {
      "Hanzi": 2157,
      "Word": 4818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96.7",
      "Grade": 6,
      "GeneralStandard": 2157,
      "Radical": "玉",
      "HSK": 2,
      "FrequencyRank": 89,
      "StrokeCount": 11
    }
  },
  "蓊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěng",
      "PrimaryDefinition": "luxuriant vegetation; lush"
    },
    "Keys": {
      "Hanzi": 5378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5378,
      "Radical": "艸",
      "FrequencyRank": 6287,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "整顿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngdùn",
      "PrimaryDefinition": "rectify, reorganize"
    },
    "Keys": {
      "Word": 5339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "惊心动魄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngxīn-dòngpò",
      "PrimaryDefinition": "shaking one to the core, extremely disturbing, hair-raising (idiom)"
    },
    "Keys": {
      "Word": 7606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péi",
      "PrimaryDefinition": "surname; look of a flowing gown"
    },
    "Keys": {
      "Hanzi": 5688
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5688,
      "Radical": "衣",
      "FrequencyRank": 3122,
      "RadicalIndex": "145.8",
      "StrokeCount": 14
    }
  },
  "道理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàoli",
      "PrimaryDefinition": "thread, principle, the right way, theory, reason, argument, sense, order, truth, rationality"
    },
    "Keys": {
      "Word": 609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "赶赴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnfù",
      "PrimaryDefinition": "to hurry, to rush"
    },
    "Keys": {
      "Word": 6670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岂有此理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐyǒu-cǐlǐ",
      "PrimaryDefinition": "how can this be so? (idiom), preposterous, ridiculous, absurd"
    },
    "Keys": {
      "Word": 8607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逐渐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhújiàn",
      "PrimaryDefinition": "step_by_step, by degrees, gradually"
    },
    "Keys": {
      "Word": 3186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鲜美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānměi",
      "PrimaryDefinition": "delicious, tasty"
    },
    "Keys": {
      "Word": 9888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pén",
      "PrimaryDefinition": "basin, tub, pot, bowl"
    },
    "Keys": {
      "Hanzi": 1590,
      "Word": 3766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.4",
      "Grade": 5,
      "GeneralStandard": 1590,
      "Radical": "皿",
      "HSK": 5,
      "FrequencyRank": 2035,
      "StrokeCount": 9
    }
  },
  "太太": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàitai",
      "PrimaryDefinition": "lady, memsahib, Dona, dona, Mrs, mum, Senora, senora, Mrs., mistress of a household, madam, Frau, goodwife, missus, senhora, married woman, wife, vrouw, Madame, madame, missis, wahine, Signora, signora"
    },
    "Keys": {
      "Word": 1007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "发烧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāshāo",
      "PrimaryDefinition": "fever, kindle, feverish, temperature, run a temperature, run a fever, have a fever, have a temperature"
    },
    "Keys": {
      "Word": 2446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "阊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "gate of heaven; main gate of a palace"
    },
    "Keys": {
      "Hanzi": 4948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4948,
      "Radical": "門",
      "FrequencyRank": 5926,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "真正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnzhèng",
      "PrimaryDefinition": "true, indeed, certainty"
    },
    "Keys": {
      "Word": 1211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "纪录": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìlù",
      "PrimaryDefinition": "minutes, notes, record, record"
    },
    "Keys": {
      "Word": 1589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "眦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zì",
      "PrimaryDefinition": "corner of the eyes, eyesockets"
    },
    "Keys": {
      "Hanzi": 4810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4810,
      "Radical": "目",
      "FrequencyRank": 5790,
      "RadicalIndex": "109.5",
      "StrokeCount": 11
    }
  },
  "超市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāoshì",
      "PrimaryDefinition": "supermarket, abbr. for 超級市場|超级市场, CL:家[jia1]"
    },
    "Keys": {
      "Word": 556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "施加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shījiā",
      "PrimaryDefinition": "to exert (effort or pressure)"
    },
    "Keys": {
      "Word": 9097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnài",
      "PrimaryDefinition": "love, treasure"
    },
    "Keys": {
      "Word": 10000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "紧密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnmì",
      "PrimaryDefinition": "settle, close together, rapid and intense, compact, inseparable, close"
    },
    "Keys": {
      "Word": 2635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "游玩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuwán",
      "PrimaryDefinition": "to amuse oneself, to have fun, to go sightseeing, to take a stroll"
    },
    "Keys": {
      "Word": 5271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "传播": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánbō",
      "PrimaryDefinition": "disseminate, propagate, spread"
    },
    "Keys": {
      "Word": 1367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "综": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "arrange threads for weaving"
    },
    "Keys": {
      "Hanzi": 2496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2496,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 1425,
      "StrokeCount": 11
    }
  },
  "巨人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùrén",
      "PrimaryDefinition": "colossus, whale, Titan, Goliath, titan, bouncer, darb, goliath, Brobdingnagian, heavyweight, monster, behemoth, giant, Heracles, hulk"
    },
    "Keys": {
      "Word": 7697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "试用期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyòngqī",
      "PrimaryDefinition": "trial period, probation"
    },
    "Keys": {
      "Word": 9147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外企": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiqǐ",
      "PrimaryDefinition": "foreign enterprise, company established in mainland China with direct investment from foreign entities or from investors in Taiwan, Macao or Hong Kong, abbr. for 外資企業|外资企业"
    },
    "Keys": {
      "Word": 9641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "栽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāi",
      "PrimaryDefinition": "to cultivate, plant; to care for plants"
    },
    "Keys": {
      "Hanzi": 1782,
      "Word": 10586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 7,
      "GeneralStandard": 1782,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2401,
      "StrokeCount": 10
    }
  },
  "谄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "flatter, truckle, toady"
    },
    "Keys": {
      "Hanzi": 4677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4677,
      "Radical": "言",
      "FrequencyRank": 4145,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "资金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zījīn",
      "PrimaryDefinition": "principal_sum, fund, bankroll, finance, coffer, ante, grubstake, munition, wherewithal, capital, working capital"
    },
    "Keys": {
      "Word": 2209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "认证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènzhèng",
      "PrimaryDefinition": "to authenticate, to approve"
    },
    "Keys": {
      "Word": 8862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "来电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láidiàn",
      "PrimaryDefinition": "incoming telegram or telephone call, your telegram, telephone call, or message, to send a telegram or make a telephone call here (i.e. to the speaker), to have instant attraction to sb, to have chemistry with sb, to come back (of electricity, after an outage)"
    },
    "Keys": {
      "Word": 7916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gà",
      "PrimaryDefinition": "limp, staggering gait; embarrass"
    },
    "Keys": {
      "Hanzi": 705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "43.4",
      "GeneralStandard": 705,
      "Radical": "尢",
      "HSK": 7,
      "FrequencyRank": 2729,
      "StrokeCount": 7
    }
  },
  "贴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiē",
      "PrimaryDefinition": "paste to, stick on; attached to"
    },
    "Keys": {
      "Hanzi": 1521,
      "Word": 2935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "Grade": 4,
      "GeneralStandard": 1521,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 1454,
      "StrokeCount": 9
    }
  },
  "栳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "a basket"
    },
    "Keys": {
      "Hanzi": 4448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4448,
      "Radical": "木",
      "FrequencyRank": 6788,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "扣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòu",
      "PrimaryDefinition": "knock, strike, rap, tap; button"
    },
    "Keys": {
      "Hanzi": 357,
      "Word": 4799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "Grade": 6,
      "GeneralStandard": 357,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1625,
      "StrokeCount": 6
    }
  },
  "来不及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láibují",
      "PrimaryDefinition": "be too late to do sth.,"
    },
    "Keys": {
      "Word": 2678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "非得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēiděi",
      "PrimaryDefinition": "(followed by a verb phrase, then – optionally – 不可, or 不行 etc) must"
    },
    "Keys": {
      "Word": 6513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "grow, multiply, increase; thrive"
    },
    "Keys": {
      "Hanzi": 2767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 2767,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 1931,
      "StrokeCount": 12
    }
  },
  "囵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lún",
      "PrimaryDefinition": "all, complete, entire"
    },
    "Keys": {
      "Hanzi": 3719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3719,
      "Radical": "囗",
      "FrequencyRank": 5249,
      "RadicalIndex": "31.4",
      "StrokeCount": 7
    }
  },
  "着眼于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóyǎn yú",
      "PrimaryDefinition": "Focus on"
    },
    "Keys": {
      "Word": 10937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "regretful, disappointed"
    },
    "Keys": {
      "Hanzi": 3783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3783,
      "Radical": "心",
      "FrequencyRank": 6296,
      "RadicalIndex": "61.5",
      "StrokeCount": 7
    }
  },
  "显眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnyǎn",
      "PrimaryDefinition": "conspicuous, eye-catching, glamorous"
    },
    "Keys": {
      "Word": 9899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoxué",
      "PrimaryDefinition": "primary school, elementary school"
    },
    "Keys": {
      "Word": 403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "洢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6890,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "硖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "(archaic) town in Hebei province"
    },
    "Keys": {
      "Hanzi": 4792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4792,
      "Radical": "石",
      "FrequencyRank": 5856,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "禁区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnqū",
      "PrimaryDefinition": "restricted area, forbidden region"
    },
    "Keys": {
      "Word": 7589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒng",
      "PrimaryDefinition": "urge on; rise up; stir, excite"
    },
    "Keys": {
      "Hanzi": 1984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.4",
      "GeneralStandard": 1984,
      "Radical": "耳",
      "HSK": 9,
      "FrequencyRank": 2163,
      "StrokeCount": 10
    }
  },
  "幢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuáng",
      "PrimaryDefinition": "carriage curtain; sun screen"
    },
    "Keys": {
      "Hanzi": 3254,
      "Word": 10913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.12",
      "Grade": 7,
      "GeneralStandard": 3254,
      "Radical": "巾",
      "HSK": 7,
      "FrequencyRank": 2566,
      "StrokeCount": 15
    }
  },
  "树梢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùshāo",
      "PrimaryDefinition": "the tip of a tree, treetop"
    },
    "Keys": {
      "Word": 9232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "混凝土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùnníngtǔ",
      "PrimaryDefinition": "concrete"
    },
    "Keys": {
      "Word": 7231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "eyelid"
    },
    "Keys": {
      "Hanzi": 5124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5124,
      "Radical": "目",
      "FrequencyRank": 3901,
      "RadicalIndex": "109.7",
      "StrokeCount": 12
    }
  },
  "家家户户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiājiā-hùhù",
      "PrimaryDefinition": "each and every family (idiom), every household"
    },
    "Keys": {
      "Word": 7350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "hazelnut; thicket, underbrush"
    },
    "Keys": {
      "Hanzi": 5660
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5660,
      "Radical": "木",
      "FrequencyRank": 4595,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "苞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "a variety of rush; firm, enduring; to burst forth"
    },
    "Keys": {
      "Hanzi": 1009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1009,
      "Radical": "艸",
      "FrequencyRank": 3798,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "羽绒服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔróngfú",
      "PrimaryDefinition": "down-filled garment"
    },
    "Keys": {
      "Word": 4148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "总裁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngcái",
      "PrimaryDefinition": "head examiner, director-general, president, governor"
    },
    "Keys": {
      "Word": 4277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "印刷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìnshuā",
      "PrimaryDefinition": "print"
    },
    "Keys": {
      "Word": 4122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "牡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔ",
      "PrimaryDefinition": "male of animals; bolt of door"
    },
    "Keys": {
      "Hanzi": 759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "93.3",
      "GeneralStandard": 759,
      "Radical": "牛",
      "HSK": 9,
      "FrequencyRank": 3001,
      "StrokeCount": 7
    }
  },
  "峱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "name of a mountain"
    },
    "Keys": {
      "Hanzi": 7036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7036,
      "Radical": "山",
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "霏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "falling of snow and rain"
    },
    "Keys": {
      "Hanzi": 6077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6077,
      "Radical": "雨",
      "FrequencyRank": 4768,
      "RadicalIndex": "173.8",
      "StrokeCount": 16
    }
  },
  "大赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàsài",
      "PrimaryDefinition": "race, game, tournament, contest"
    },
    "Keys": {
      "Word": 4477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "瘼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "sickness; distress"
    },
    "Keys": {
      "Hanzi": 5983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5983,
      "Radical": "疒",
      "FrequencyRank": 7421,
      "RadicalIndex": "104.11",
      "StrokeCount": 15
    }
  },
  "功臣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngchén",
      "PrimaryDefinition": "minister who has given outstanding service"
    },
    "Keys": {
      "Word": 6784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "启示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐshì",
      "PrimaryDefinition": "apocalypse, illumination, revelation"
    },
    "Keys": {
      "Word": 8611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāotōng",
      "PrimaryDefinition": "intercourse, commutation, transportation, communication, commuting, traffic, traffic_light, liaison man, communications, dealings, liaison"
    },
    "Keys": {
      "Word": 766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "仫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "tribe"
    },
    "Keys": {
      "Hanzi": 3545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3545,
      "Radical": "人",
      "FrequencyRank": 5893,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "孵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "sit on eggs, hatch"
    },
    "Keys": {
      "Hanzi": 3137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.11",
      "GeneralStandard": 3137,
      "Radical": "子",
      "HSK": 9,
      "FrequencyRank": 3256,
      "StrokeCount": 14
    }
  },
  "有利于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒulìyú",
      "PrimaryDefinition": "beneficial to"
    },
    "Keys": {
      "Word": 4144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "含义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hányì",
      "PrimaryDefinition": "meaning (implicit in a phrase), implied meaning, hidden meaning, hint, connotation"
    },
    "Keys": {
      "Word": 2532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "迷信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míxìn",
      "PrimaryDefinition": "have blind faith in, make fetish of"
    },
    "Keys": {
      "Word": 3720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "杰出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéchū",
      "PrimaryDefinition": "excel at, exceed, shine at, outstanding, stand_out"
    },
    "Keys": {
      "Word": 4731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "芤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6577,
      "Radical": "艸",
      "FrequencyRank": 5635,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "笕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "bamboo water pipe"
    },
    "Keys": {
      "Hanzi": 4557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4557,
      "Radical": "竹",
      "FrequencyRank": 4867,
      "RadicalIndex": "118.4",
      "StrokeCount": 10
    }
  },
  "加盟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāméng",
      "PrimaryDefinition": "align"
    },
    "Keys": {
      "Word": 4701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "彩色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎisè",
      "PrimaryDefinition": "colour, chromatic, multicolour, colorful"
    },
    "Keys": {
      "Word": 1330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "遇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "meet, come across, encounter"
    },
    "Keys": {
      "Hanzi": 2622,
      "Word": 3128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "Grade": 4,
      "GeneralStandard": 2622,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 899,
      "StrokeCount": 12
    }
  },
  "无穷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúqióng",
      "PrimaryDefinition": "infinite, endless, inexhaustible"
    },
    "Keys": {
      "Word": 9781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悠久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōujiǔ",
      "PrimaryDefinition": "long in time"
    },
    "Keys": {
      "Word": 10454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尝试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángshì",
      "PrimaryDefinition": "attempt, try"
    },
    "Keys": {
      "Word": 3314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "cooked rice; food, meal"
    },
    "Keys": {
      "Hanzi": 823,
      "Word": 98
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.4",
      "Grade": 1,
      "GeneralStandard": 823,
      "Radical": "食",
      "HSK": 1,
      "FrequencyRank": 935,
      "StrokeCount": 7
    }
  },
  "专题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāntí",
      "PrimaryDefinition": "special subject/topic"
    },
    "Keys": {
      "Word": 2200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "生怕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngpà",
      "PrimaryDefinition": "to fear, afraid, extremely nervous, for fear that, to avoid, so as not to"
    },
    "Keys": {
      "Word": 9056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老实说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoshíshuō",
      "PrimaryDefinition": "honestly"
    },
    "Keys": {
      "Word": 7950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "院士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànshì",
      "PrimaryDefinition": "academician"
    },
    "Keys": {
      "Word": 10558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "增值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngzhí",
      "PrimaryDefinition": "to appreciate (financially), to increase in value, value-added (accountancy)"
    },
    "Keys": {
      "Word": 5312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "酣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hān",
      "PrimaryDefinition": "enjoy intoxicants"
    },
    "Keys": {
      "Hanzi": 2585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.5",
      "GeneralStandard": 2585,
      "Radical": "酉",
      "HSK": 9,
      "FrequencyRank": 3582,
      "StrokeCount": 12
    }
  },
  "㕮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "to chew; to masticate, to dwell on, Chinese medicine term"
    },
    "Keys": {
      "Hanzi": 6592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6592,
      "Radical": "口",
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "快递": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàidì",
      "PrimaryDefinition": "express delivery"
    },
    "Keys": {
      "Word": 2668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "进程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnchéng",
      "PrimaryDefinition": "process, course"
    },
    "Keys": {
      "Word": 7577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一不小心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībùxiǎoxīn",
      "PrimaryDefinition": "Unclear"
    },
    "Keys": {
      "Word": 10281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "Buddhist monastery or nunnery"
    },
    "Keys": {
      "Hanzi": 2380
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2380,
      "Radical": "广",
      "FrequencyRank": 3022,
      "RadicalIndex": "53.8",
      "StrokeCount": 11
    }
  },
  "峂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "name of place"
    },
    "Keys": {
      "Hanzi": 6685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6685,
      "Radical": "山",
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "炎热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánrè",
      "PrimaryDefinition": "scorching/blazing hot"
    },
    "Keys": {
      "Word": 10182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不利于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù lìyú",
      "PrimaryDefinition": "is harmful to"
    },
    "Keys": {
      "Word": 5675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "jade look alike stone"
    },
    "Keys": {
      "Hanzi": 7623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7623,
      "Radical": "玉",
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "惇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": "be kind, cordial, sincere"
    },
    "Keys": {
      "Hanzi": 4991
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4991,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "珀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pò",
      "PrimaryDefinition": "amber"
    },
    "Keys": {
      "Hanzi": 4089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4089,
      "Radical": "玉",
      "FrequencyRank": 2981,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "从来不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngláibù",
      "PrimaryDefinition": "never"
    },
    "Keys": {
      "Word": 6058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "high and dry place"
    },
    "Keys": {
      "Hanzi": 6770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6770,
      "Radical": "土",
      "FrequencyRank": 9025,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "界线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièxiàn",
      "PrimaryDefinition": "limits, bounds, dividing line"
    },
    "Keys": {
      "Word": 7552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "open eyes; stare"
    },
    "Keys": {
      "Hanzi": 2261,
      "Word": 10726
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.6",
      "Grade": 7,
      "GeneralStandard": 2261,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 1963,
      "StrokeCount": 11
    }
  },
  "不曾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùcéng",
      "PrimaryDefinition": "hasn't yet, hasn't ever"
    },
    "Keys": {
      "Word": 3276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "茶叶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháyè",
      "PrimaryDefinition": "tea, tea leaf, tea leaves"
    },
    "Keys": {
      "Word": 2297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "孱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càn",
      "PrimaryDefinition": "weak, unfit, frail, feeble"
    },
    "Keys": {
      "Hanzi": 5316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5316,
      "Radical": "子",
      "FrequencyRank": 4985,
      "RadicalIndex": "39.9",
      "StrokeCount": 12
    }
  },
  "不行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù xíng",
      "PrimaryDefinition": "won't do, be out of the question, be no good, not work, not be capable"
    },
    "Keys": {
      "Word": 538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "已": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "already; finished; stop"
    },
    "Keys": {
      "Hanzi": 61,
      "Word": 2090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "49",
      "Grade": 3,
      "GeneralStandard": 61,
      "Radical": "己",
      "HSK": 2,
      "FrequencyRank": 117,
      "StrokeCount": 3
    }
  },
  "房价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángjià",
      "PrimaryDefinition": "house/apartment purchase price, house purchase price, apartment purchase price, house"
    },
    "Keys": {
      "Word": 4556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "展览会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnlǎnhuì",
      "PrimaryDefinition": "exhibition, show, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 10642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": "ditch; pool"
    },
    "Keys": {
      "Hanzi": 3595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3595,
      "Radical": "水",
      "FrequencyRank": 5490,
      "RadicalIndex": "85.2",
      "StrokeCount": 6
    }
  },
  "流利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúlì",
      "PrimaryDefinition": "fluent"
    },
    "Keys": {
      "Word": 856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "卧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "lie down; crouch"
    },
    "Keys": {
      "Hanzi": 1037,
      "Word": 9754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "131.2",
      "Grade": 7,
      "GeneralStandard": 1037,
      "Radical": "臣",
      "HSK": 5,
      "FrequencyRank": 1944,
      "StrokeCount": 8
    }
  },
  "之前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīqián",
      "PrimaryDefinition": "before, prior to, ago"
    },
    "Keys": {
      "Word": 3168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "神奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénqí",
      "PrimaryDefinition": "magical, mystical, miraculous"
    },
    "Keys": {
      "Word": 3891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "苣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "kind of lettuce"
    },
    "Keys": {
      "Hanzi": 3664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3664,
      "Radical": "艸",
      "FrequencyRank": 4756,
      "RadicalIndex": "140.5",
      "StrokeCount": 7
    }
  },
  "狂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuáng",
      "PrimaryDefinition": "insane, mad; violent; wild"
    },
    "Keys": {
      "Hanzi": 811,
      "Word": 3671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.4",
      "Grade": 5,
      "GeneralStandard": 811,
      "Radical": "犬",
      "HSK": 5,
      "FrequencyRank": 1147,
      "StrokeCount": 7
    }
  },
  "讣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "obituary; give notice of death"
    },
    "Keys": {
      "Hanzi": 3523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3523,
      "Radical": "言",
      "FrequencyRank": 4666,
      "RadicalIndex": "149.2",
      "StrokeCount": 4
    }
  },
  "低价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dījià",
      "PrimaryDefinition": "cheap, song, low, low price"
    },
    "Keys": {
      "Word": 6229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7387
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7387,
      "Radical": "竹",
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "冠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "cap, crown, headgear"
    },
    "Keys": {
      "Hanzi": 1705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "14.7",
      "GeneralStandard": 1705,
      "Radical": "冖",
      "HSK": 5,
      "FrequencyRank": 1713,
      "StrokeCount": 9
    }
  },
  "事务所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìwùsuǒ",
      "PrimaryDefinition": "office, business office, business premises"
    },
    "Keys": {
      "Word": 9140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "swear; oath, covenant, alliance"
    },
    "Keys": {
      "Hanzi": 2907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "108.8",
      "GeneralStandard": 2907,
      "Radical": "皿",
      "HSK": 6,
      "FrequencyRank": 1163,
      "StrokeCount": 13
    }
  },
  "博士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bóshì",
      "PrimaryDefinition": "Ph.D, master of any trade/craft, master of any craft, Dr., holder of doctoral degree, master of any trade, Erudite, doctoral, Doctor_of_Philosophy, Ph.D., court academician, craft, erudite, Doctor of Philosophy, doctor"
    },
    "Keys": {
      "Word": 3266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "缔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "tie, join, connect; connection"
    },
    "Keys": {
      "Hanzi": 2819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "GeneralStandard": 2819,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2688,
      "StrokeCount": 12
    }
  },
  "妻子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīzi",
      "PrimaryDefinition": "frow, feme, married woman, wifelike, wife, woman, femme, missis, squaw"
    },
    "Keys": {
      "Word": 2786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "相遇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngyù",
      "PrimaryDefinition": "to meet, to encounter, to come across"
    },
    "Keys": {
      "Word": 9933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迫切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòqiè",
      "PrimaryDefinition": "imminent, imperative, pressing, urgent"
    },
    "Keys": {
      "Word": 2784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "电脑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànnǎo",
      "PrimaryDefinition": "electronic computer, computery, data processor, information processing system, computer"
    },
    "Keys": {
      "Word": 78
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "骦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāng",
      "PrimaryDefinition": "horse"
    },
    "Keys": {
      "Hanzi": 8072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8072,
      "Radical": "馬",
      "RadicalIndex": "187.17",
      "StrokeCount": 20
    }
  },
  "猩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": "species of orangutan"
    },
    "Keys": {
      "Hanzi": 2718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.9",
      "GeneralStandard": 2718,
      "Radical": "犬",
      "HSK": 8,
      "FrequencyRank": 2016,
      "StrokeCount": 12
    }
  },
  "泣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "cry, sob, weep"
    },
    "Keys": {
      "Hanzi": 1253
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1253,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2449,
      "StrokeCount": 8
    }
  },
  "觱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "tartar horn; chilly wind"
    },
    "Keys": {
      "Hanzi": 7849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7849,
      "Radical": "角",
      "FrequencyRank": 7300,
      "RadicalIndex": "148.9",
      "StrokeCount": 16
    }
  },
  "没关系": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méiguānxi",
      "PrimaryDefinition": "it doesn't matter, never mind"
    },
    "Keys": {
      "Word": 230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "胎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāi",
      "PrimaryDefinition": "unborn child, embryo, fetus"
    },
    "Keys": {
      "Hanzi": 1598,
      "Word": 9386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "Grade": 7,
      "GeneralStandard": 1598,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 1984,
      "StrokeCount": 9
    }
  },
  "视线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìxiàn",
      "PrimaryDefinition": "line of sight"
    },
    "Keys": {
      "Word": 9152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒ",
      "PrimaryDefinition": "cakes, biscuits, pastry"
    },
    "Keys": {
      "Hanzi": 4932
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4932,
      "Radical": "食",
      "FrequencyRank": 5533,
      "RadicalIndex": "184.8",
      "StrokeCount": 11
    }
  },
  "瞬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùn",
      "PrimaryDefinition": "wink, blink; in a wink, a flash"
    },
    "Keys": {
      "Hanzi": 3403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.12",
      "GeneralStandard": 3403,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 2114,
      "StrokeCount": 17
    }
  },
  "嬥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7978,
      "Radical": "女",
      "RadicalIndex": "38.14",
      "StrokeCount": 17
    }
  },
  "铒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "erbium"
    },
    "Keys": {
      "Hanzi": 4866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4866,
      "Radical": "金",
      "FrequencyRank": 5951,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "缳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "noose; hang death; tie, bind"
    },
    "Keys": {
      "Hanzi": 6194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6194,
      "Radical": "糸",
      "FrequencyRank": 6810,
      "RadicalIndex": "120.13",
      "StrokeCount": 16
    }
  },
  "松弛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōngchí",
      "PrimaryDefinition": "to relax, relaxed, limp, lax"
    },
    "Keys": {
      "Word": 9334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7340,
      "Radical": "石",
      "FrequencyRank": 5369,
      "RadicalIndex": "112.7",
      "StrokeCount": 12
    }
  },
  "获悉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòxī",
      "PrimaryDefinition": "have, understand, get_onto, learn, learn (of an event)"
    },
    "Keys": {
      "Word": 7257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "继续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìxù",
      "PrimaryDefinition": "go, resume, keep_on, abide, come on, persist in, extend, run_on, run on, carry_on, stretch, sustain, pursue, keep going, continuance, get_along, get_on, remain, continue, keep_up, go ahead, keep up, come up, continuation, hold, carry, run, go_on, maintain, go on, bide, plow ahead, hold_on, piece, restart"
    },
    "Keys": {
      "Word": 1583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "耧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "drill for sowing grain"
    },
    "Keys": {
      "Hanzi": 5850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5850,
      "Radical": "耒",
      "FrequencyRank": 7069,
      "RadicalIndex": "127.9",
      "StrokeCount": 15
    }
  },
  "夔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "one-legged monster; walrus"
    },
    "Keys": {
      "Hanzi": 6457
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6457,
      "Radical": "夊",
      "FrequencyRank": 4330,
      "RadicalIndex": "35.19",
      "StrokeCount": 21
    }
  },
  "玿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sháo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6765,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "标本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāoběn",
      "PrimaryDefinition": "example, representative, pattern, specimen, root cause and symptoms of disease, sample, model"
    },
    "Keys": {
      "Word": 5624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēn",
      "PrimaryDefinition": "run fast, flee; rush about; run"
    },
    "Keys": {
      "Hanzi": 1049,
      "Word": 5569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.6",
      "Grade": 7,
      "GeneralStandard": 1049,
      "Radical": "大",
      "HSK": 6,
      "FrequencyRank": 1285,
      "StrokeCount": 8
    }
  },
  "格式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "géshi",
      "PrimaryDefinition": "pattern, form, a style, a format, etiquette, format, style, the form of characters or letters"
    },
    "Keys": {
      "Word": 6733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "name of a district in Shandong"
    },
    "Keys": {
      "Hanzi": 3863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3863,
      "Radical": "艸",
      "FrequencyRank": 6995,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "好玩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎowánr",
      "PrimaryDefinition": "interesting, delightful, amusing"
    },
    "Keys": {
      "Word": 140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "熹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "dim light, glimmer; warm, bright"
    },
    "Keys": {
      "Hanzi": 6040
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6040,
      "Radical": "火",
      "FrequencyRank": 3871,
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "高龄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāolíng",
      "PrimaryDefinition": "elderly"
    },
    "Keys": {
      "Word": 6701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "lesson; course; classwork"
    },
    "Keys": {
      "Hanzi": 2120,
      "Word": 196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "Grade": 1,
      "GeneralStandard": 2120,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 1208,
      "StrokeCount": 10
    }
  },
  "血压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuèyā",
      "PrimaryDefinition": "blood pressure"
    },
    "Keys": {
      "Word": 10142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "to connect"
    },
    "Keys": {
      "Hanzi": 6746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6746,
      "Radical": "又",
      "RadicalIndex": "29.6",
      "StrokeCount": 8
    }
  },
  "晚会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnhuì",
      "PrimaryDefinition": "evening entertainment, soiree, evening party, social evening, party, evening, evening entertainment/party"
    },
    "Keys": {
      "Word": 1052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "犟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "stubborn"
    },
    "Keys": {
      "Hanzi": 6188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6188,
      "Radical": "牛",
      "FrequencyRank": 4639,
      "RadicalIndex": "93.12",
      "StrokeCount": 16
    }
  },
  "手帕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒupà",
      "PrimaryDefinition": "handkerchief"
    },
    "Keys": {
      "Word": 9175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赔偿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péicháng",
      "PrimaryDefinition": "compensate, pay for"
    },
    "Keys": {
      "Word": 3761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "看到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn dào",
      "PrimaryDefinition": "view, receive, ken, observe, lamp, catch, see, take in, watch, chatter away, lay_eyes_on, boast, brag, shoot the breeze, spy, catch sight of, catch sight of"
    },
    "Keys": {
      "Word": 191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "脑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎo",
      "PrimaryDefinition": "brain"
    },
    "Keys": {
      "Hanzi": 2001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 2001,
      "Radical": "肉",
      "HSK": 1,
      "FrequencyRank": 646,
      "StrokeCount": 10
    }
  },
  "季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "quarter of year; season; surname"
    },
    "Keys": {
      "Hanzi": 1132,
      "Word": 2589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "39.5",
      "Grade": 4,
      "GeneralStandard": 1132,
      "Radical": "子",
      "HSK": 4,
      "FrequencyRank": 1279,
      "StrokeCount": 8
    }
  },
  "天地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāndì",
      "PrimaryDefinition": "heaven and earth, world, scope, field of activity"
    },
    "Keys": {
      "Word": 9475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "利益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìyì",
      "PrimaryDefinition": "privilege, profit, vantage, favor, interest, behoof, account, goodness, gain, increment, avail, boon, plus, commodity, lucre, advantage, stead, boot, benefit, welfare, good, asset, behalf, percentage, stake, favour"
    },
    "Keys": {
      "Word": 2696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "开会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi huì",
      "PrimaryDefinition": "hold/attend meeting"
    },
    "Keys": {
      "Word": 187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "逻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "patrol; inspect; watch; patrol"
    },
    "Keys": {
      "Hanzi": 2295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.8",
      "GeneralStandard": 2295,
      "Radical": "辵",
      "HSK": 5,
      "FrequencyRank": 1591,
      "StrokeCount": 11
    }
  },
  "悄悄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāoqiāo",
      "PrimaryDefinition": "silently, stealthily, quietly"
    },
    "Keys": {
      "Word": 3811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "兄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōng",
      "PrimaryDefinition": "elder brother"
    },
    "Keys": {
      "Hanzi": 250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "10.3",
      "GeneralStandard": 250,
      "Radical": "儿",
      "HSK": 4,
      "FrequencyRank": 1089,
      "StrokeCount": 5
    }
  },
  "泮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "Zhou dynasty school; disperse; fall apart"
    },
    "Keys": {
      "Hanzi": 4020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4020,
      "Radical": "水",
      "FrequencyRank": 5962,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "湖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "a lake; Hubei, Hunan; bluish-green"
    },
    "Keys": {
      "Hanzi": 2751,
      "Word": 727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 2,
      "GeneralStandard": 2751,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 918,
      "StrokeCount": 12
    }
  },
  "湣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "mixed, confused; pity"
    },
    "Keys": {
      "Hanzi": 7436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7436,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "箭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "arrow; type of bamboo"
    },
    "Keys": {
      "Hanzi": 3267,
      "Word": 4714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.9",
      "Grade": 6,
      "GeneralStandard": 3267,
      "Radical": "竹",
      "HSK": 6,
      "FrequencyRank": 1496,
      "StrokeCount": 15
    }
  },
  "汤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": "hot water; soup, gravy, broth"
    },
    "Keys": {
      "Hanzi": 542,
      "Word": 1952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.3",
      "Grade": 3,
      "GeneralStandard": 542,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 1618,
      "StrokeCount": 6
    }
  },
  "僮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "page, boy servant"
    },
    "Keys": {
      "Hanzi": 5760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5760,
      "Radical": "人",
      "FrequencyRank": 4523,
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "俘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "prisoner of war; take as prisoner"
    },
    "Keys": {
      "Hanzi": 1569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1569,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 2057,
      "StrokeCount": 9
    }
  },
  "底子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐzi",
      "PrimaryDefinition": "base, foundation, bottom"
    },
    "Keys": {
      "Word": 6246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "fill, extend"
    },
    "Keys": {
      "Hanzi": 6451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6451,
      "Radical": "石",
      "FrequencyRank": 4856,
      "RadicalIndex": "112.16",
      "StrokeCount": 21
    }
  },
  "诈骗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàpiàn",
      "PrimaryDefinition": "to defraud, to swindle, to blackmail"
    },
    "Keys": {
      "Word": 10629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "to ditch; a moat"
    },
    "Keys": {
      "Hanzi": 4341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4341,
      "Radical": "水",
      "FrequencyRank": 6901,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "约定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuēdìng",
      "PrimaryDefinition": "agree upon"
    },
    "Keys": {
      "Word": 5298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "敬而远之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngéryuǎnzhī",
      "PrimaryDefinition": "to show respect from a distance (idiom), to remain at a respectful distance"
    },
    "Keys": {
      "Word": 7642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "to walk"
    },
    "Keys": {
      "Hanzi": 6488
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6488,
      "Radical": "足",
      "FrequencyRank": 6415,
      "RadicalIndex": "157.17",
      "StrokeCount": 24
    }
  },
  "臆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "chest, breast, bosom; thought"
    },
    "Keys": {
      "Hanzi": 6274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6274,
      "Radical": "肉",
      "FrequencyRank": 3709,
      "RadicalIndex": "130.13",
      "StrokeCount": 17
    }
  },
  "计策": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìcè",
      "PrimaryDefinition": "stratagem"
    },
    "Keys": {
      "Word": 7327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "长处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángchu",
      "PrimaryDefinition": "good aspects, strong points"
    },
    "Keys": {
      "Word": 1334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "do, handle, govern, act; be"
    },
    "Keys": {
      "Hanzi": 165,
      "Word": 2005
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "3.3",
      "Grade": 3,
      "GeneralStandard": 165,
      "Radical": "丶",
      "HSK": 2,
      "FrequencyRank": 18,
      "StrokeCount": 4
    }
  },
  "氆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "thick, rough serge from Tibet"
    },
    "Keys": {
      "Hanzi": 6121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6121,
      "Radical": "毛",
      "FrequencyRank": 6397,
      "RadicalIndex": "82.12",
      "StrokeCount": 16
    }
  },
  "情绪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngxù",
      "PrimaryDefinition": "form, pulse, humor, depression, temper, feeling, spirits, monkey, emotion, sentiment, humour, soulfulness, mood, heartbeat, heartstrings, sulks, chord, vein, feelings, emotional state, spirit, frame_of_mind, sentiments, moodiness, emotions, steam, tune, soul"
    },
    "Keys": {
      "Word": 4948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鞡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "la",
      "PrimaryDefinition": "leg warmer"
    },
    "Keys": {
      "Hanzi": 6206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6206,
      "Radical": "革",
      "FrequencyRank": 7001,
      "RadicalIndex": "177.8",
      "StrokeCount": 17
    }
  },
  "对抗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìkàng",
      "PrimaryDefinition": "baulk, pit, combat, antagonise, encounter, counterwork, countervail, counteract, opposition, resistance, cope, breast, defend, fight, stem, play, battle, fight down, meet, fight back, antagonize, face, confront, front, oppose, contradict, controvert, balk, resist, jib, countercheck, take on, counterbalance, match"
    },
    "Keys": {
      "Word": 4527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "沽名钓誉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūmíng-diàoyù",
      "PrimaryDefinition": "to angle for fame (idiom), to fish for compliments"
    },
    "Keys": {
      "Word": 6818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "校": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": "school; military field officer"
    },
    "Keys": {
      "Hanzi": 1847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 1847,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 633,
      "StrokeCount": 10
    }
  },
  "㠓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "name of a mountain"
    },
    "Keys": {
      "Hanzi": 7868
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7868,
      "Radical": "山",
      "RadicalIndex": "46.13",
      "StrokeCount": 16
    }
  },
  "发起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāqǐ",
      "PrimaryDefinition": "initiate, commence, stage, instigate, sparkplug, sponsor, inauguraion, start, launch, promote, inaugurate, initiation"
    },
    "Keys": {
      "Word": 4539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "高新技术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoxīn jìshù",
      "PrimaryDefinition": "High-tech"
    },
    "Keys": {
      "Word": 6705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèlì",
      "PrimaryDefinition": "special case, isolated example"
    },
    "Keys": {
      "Word": 9447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尤为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuwéi",
      "PrimaryDefinition": "especially"
    },
    "Keys": {
      "Word": 10457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "秆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "straw; stalk of grain"
    },
    "Keys": {
      "Hanzi": 1130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1130,
      "Radical": "禾",
      "FrequencyRank": 4132,
      "RadicalIndex": "115.3",
      "StrokeCount": 8
    }
  },
  "上岗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng gǎng",
      "PrimaryDefinition": "to take up one's post, to be given a job"
    },
    "Keys": {
      "Word": 8972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燃料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ránliào",
      "PrimaryDefinition": "fuel"
    },
    "Keys": {
      "Word": 2819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "昌盛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāngshèng",
      "PrimaryDefinition": "prosperous"
    },
    "Keys": {
      "Word": 4386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "诞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "bear children, give birth; birth"
    },
    "Keys": {
      "Hanzi": 1297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "GeneralStandard": 1297,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 1997,
      "StrokeCount": 8
    }
  },
  "消耗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāohào",
      "PrimaryDefinition": "consume, use up, deplete"
    },
    "Keys": {
      "Word": 5167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "劳动力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láodònglì",
      "PrimaryDefinition": "labor force, labor, capacity for physical labor, able-bodied person"
    },
    "Keys": {
      "Word": 7938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猜想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāixiǎng",
      "PrimaryDefinition": "to guess, to conjecture, to suppose, to suspect"
    },
    "Keys": {
      "Word": 5733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "chipped edge of a container"
    },
    "Keys": {
      "Hanzi": 5677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5677,
      "Radical": "石",
      "FrequencyRank": 4321,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "导航": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoháng",
      "PrimaryDefinition": "navigation"
    },
    "Keys": {
      "Word": 6188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "犴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "a wild dog, a not very large lock-up"
    },
    "Keys": {
      "Hanzi": 6543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6543,
      "Radical": "犬",
      "FrequencyRank": 6407,
      "RadicalIndex": "94.3",
      "StrokeCount": 6
    }
  },
  "唯独": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéidú",
      "PrimaryDefinition": "only, just (i.e. it is only that...), all except, unique"
    },
    "Keys": {
      "Word": 9701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "larva"
    },
    "Keys": {
      "Hanzi": 4841
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4841,
      "Radical": "虫",
      "FrequencyRank": 4976,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "怍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "to be ashamed"
    },
    "Keys": {
      "Hanzi": 4030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4030,
      "Radical": "心",
      "FrequencyRank": 6390,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "丢弃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diūqì",
      "PrimaryDefinition": "to discard, to abandon"
    },
    "Keys": {
      "Word": 6320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "light of crescent moon"
    },
    "Keys": {
      "Hanzi": 6871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6871,
      "Radical": "月",
      "RadicalIndex": "74.5",
      "StrokeCount": 9
    }
  },
  "沤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōu",
      "PrimaryDefinition": "soak, steep; sodden or soaked"
    },
    "Keys": {
      "Hanzi": 3769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3769,
      "Radical": "水",
      "FrequencyRank": 4900,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "拙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "stupid, clumsy, crude; convention"
    },
    "Keys": {
      "Hanzi": 987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 987,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 2709,
      "StrokeCount": 8
    }
  },
  "集合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíhé",
      "PrimaryDefinition": "gather, assemble, muster"
    },
    "Keys": {
      "Word": 2586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "加快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiākuài",
      "PrimaryDefinition": "speed up, accelerate"
    },
    "Keys": {
      "Word": 1587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "点火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn huǒ",
      "PrimaryDefinition": "to ignite, to light a fire, to agitate, to start an engine, ignition, fig. to stir up trouble"
    },
    "Keys": {
      "Word": 6265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "栋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "main beams supporting house"
    },
    "Keys": {
      "Hanzi": 1429,
      "Word": 6342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 7,
      "GeneralStandard": 1429,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2710,
      "StrokeCount": 9
    }
  },
  "枉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "useless, in vain; bent, crooked"
    },
    "Keys": {
      "Hanzi": 1017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1017,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2918,
      "StrokeCount": 8
    }
  },
  "养活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnghuo",
      "PrimaryDefinition": "to provide for, to keep (animals, a family etc), to raise animals, to feed and clothe, support, the necessities of life, to give birth"
    },
    "Keys": {
      "Word": 10223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "功底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngdǐ",
      "PrimaryDefinition": "training in the basic skills, knowledge of the fundamentals"
    },
    "Keys": {
      "Word": 6785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "点燃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnrán",
      "PrimaryDefinition": "light_up, light up, kindle, enkindle, burn, tind, light, inflame, ignition, set fire to, ignite, emblaze"
    },
    "Keys": {
      "Word": 3420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "辜负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūfù",
      "PrimaryDefinition": "to fail to live up (to expectations), unworthy (of trust), to let down, to betray (hopes), to disappoint"
    },
    "Keys": {
      "Word": 6823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "shine, illumine, reflect"
    },
    "Keys": {
      "Hanzi": 2911,
      "Word": 2141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.9",
      "Grade": 3,
      "GeneralStandard": 2911,
      "Radical": "火",
      "HSK": 2,
      "FrequencyRank": 443,
      "StrokeCount": 13
    }
  },
  "大规模": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàguīmó",
      "PrimaryDefinition": "large scale, extensive, wide scale, broad scale"
    },
    "Keys": {
      "Word": 2371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "因而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnér",
      "PrimaryDefinition": "conj.: consequently"
    },
    "Keys": {
      "Word": 4120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "驳回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bóhuí",
      "PrimaryDefinition": "to reject, to turn down, to overrule"
    },
    "Keys": {
      "Word": 5668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "omit; remove; withdraw"
    },
    "Keys": {
      "Hanzi": 3203,
      "Word": 5847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 7,
      "GeneralStandard": 3203,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1270,
      "StrokeCount": 15
    }
  },
  "西班牙语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Xībānyáyǔ",
      "PrimaryDefinition": "Spanish language"
    },
    "Keys": {
      "Word": 5152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鼓励": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔlì",
      "PrimaryDefinition": "encourage, urge"
    },
    "Keys": {
      "Word": 3512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "陪伴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péibàn",
      "PrimaryDefinition": "to accompany"
    },
    "Keys": {
      "Word": 8481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "和蔼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héǎi",
      "PrimaryDefinition": "kindly, nice, amiable"
    },
    "Keys": {
      "Word": 7037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎi",
      "PrimaryDefinition": "then, thereupon, only then"
    },
    "Keys": {
      "Hanzi": 6807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6807,
      "Radical": "辵",
      "FrequencyRank": 7986,
      "RadicalIndex": "162.6",
      "StrokeCount": 9
    }
  },
  "垓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāi",
      "PrimaryDefinition": "border, boundary, frontier"
    },
    "Keys": {
      "Hanzi": 4105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4105,
      "Radical": "土",
      "FrequencyRank": 6093,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "dense, thick, close; intimate"
    },
    "Keys": {
      "Hanzi": 2458,
      "Word": 2738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.8",
      "Grade": 4,
      "GeneralStandard": 2458,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 591,
      "StrokeCount": 11
    }
  },
  "芍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sháo",
      "PrimaryDefinition": "peony; water chestnuts"
    },
    "Keys": {
      "Hanzi": 3579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3579,
      "Radical": "艸",
      "FrequencyRank": 4361,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "昂贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ángguì",
      "PrimaryDefinition": "cost, costly, expensive"
    },
    "Keys": {
      "Word": 5450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "餐厅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāntīng",
      "PrimaryDefinition": "dining hall, restaurant, eating place, eating house, hall, dining_room, rathskeller, brasserie, dining room/hall, refectory, dining room, lunchroom, diner, inn"
    },
    "Keys": {
      "Word": 3294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "百分比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎifēnbǐ",
      "PrimaryDefinition": "percent, per centum, pct, percentage, percent(age)"
    },
    "Keys": {
      "Word": 5472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "单纯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānchún",
      "PrimaryDefinition": "simple, pure, plain, artless"
    },
    "Keys": {
      "Word": 2387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "采购": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎigòu",
      "PrimaryDefinition": "choose and purchase"
    },
    "Keys": {
      "Word": 3290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "老婆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎopo",
      "PrimaryDefinition": "old lady, broomstick, missis, missus, married woman, squaw, old_lady, wife"
    },
    "Keys": {
      "Word": 2683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "唇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chún",
      "PrimaryDefinition": "lips"
    },
    "Keys": {
      "Hanzi": 1861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1861,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1880,
      "StrokeCount": 10
    }
  },
  "魈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "mischevious, one footed spirit that dwells in the mountains"
    },
    "Keys": {
      "Hanzi": 6133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6133,
      "Radical": "鬼",
      "FrequencyRank": 5684,
      "RadicalIndex": "194.7",
      "StrokeCount": 16
    }
  },
  "钉子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīngzi",
      "PrimaryDefinition": "nail, snag, saboteur"
    },
    "Keys": {
      "Word": 6300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主人公": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔréngōng",
      "PrimaryDefinition": "hero (of a novel or film), main protagonist"
    },
    "Keys": {
      "Word": 10854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "starch; paste. to starch"
    },
    "Keys": {
      "Hanzi": 6360
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6360,
      "Radical": "米",
      "FrequencyRank": 6314,
      "RadicalIndex": "119.11",
      "StrokeCount": 18
    }
  },
  "匈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 504,
      "Radical": "勹",
      "FrequencyRank": 2503,
      "RadicalIndex": "20.4",
      "StrokeCount": 6
    }
  },
  "日夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìyè",
      "PrimaryDefinition": "day and night, around the clock"
    },
    "Keys": {
      "Word": 4971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不客气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù kèqì",
      "PrimaryDefinition": "you're welcome, don't mention it, impolite, rude, blunt"
    },
    "Keys": {
      "Word": 31
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "炒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎo",
      "PrimaryDefinition": "fry, saute, roast, boil, cook"
    },
    "Keys": {
      "Hanzi": 1234,
      "Word": 4400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.4",
      "Grade": 6,
      "GeneralStandard": 1234,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 2590,
      "StrokeCount": 8
    }
  },
  "意见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìjian",
      "PrimaryDefinition": "latitude, point_of_view, estimation, sight, conceit, verdict, hypothesis, attitude, estimate, possibility, judgement, appraisal, ground, slant, differing opinion, ruling, complaint, thinking, suggestion, comment, advisement, mind, apprehension, say, vote, impression, deliverance, view, understanding, feeling, idea, notion, sentiment, judgment, advice, current, opinion, advert, theory, v"
    },
    "Keys": {
      "Word": 1155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "禅杖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chánzhàng",
      "PrimaryDefinition": "the staff of a Buddhist monk"
    },
    "Keys": {
      "Word": 5798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "半真半假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànzhēn-bànjiǎ",
      "PrimaryDefinition": "(idiom) half true and half false"
    },
    "Keys": {
      "Word": 5498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "mysterious gem; pearl"
    },
    "Keys": {
      "Hanzi": 3831
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3831,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "继母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìmǔ",
      "PrimaryDefinition": "stepmother"
    },
    "Keys": {
      "Word": 7337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèsè",
      "PrimaryDefinition": "motif, a character, a characteristic, manner, trait, differentiation, flavor, colour, singularity, distinction, characteristic, hue, stripe, physiognomy, a distinguishing feature, idiom, specialty, character, difference, particular, distinguishing feature, distinguishing feature/quality, salience, coloration, distinguishing quality, specific, feature, peculiarity, artistic st"
    },
    "Keys": {
      "Word": 1954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "涩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": "astringent; harsh; uneven, rough"
    },
    "Keys": {
      "Hanzi": 2088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2088,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 2836,
      "StrokeCount": 10
    }
  },
  "浦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "bank of river, shore; surname"
    },
    "Keys": {
      "Hanzi": 2068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2068,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2161,
      "StrokeCount": 10
    }
  },
  "股民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔmín",
      "PrimaryDefinition": "stock investor, share trader"
    },
    "Keys": {
      "Word": 6831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "sound of crying, sobbing"
    },
    "Keys": {
      "Hanzi": 745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 745,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2572,
      "StrokeCount": 7
    }
  },
  "着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhe",
      "PrimaryDefinition": "make move, take action"
    },
    "Keys": {
      "Hanzi": 2398,
      "Word": 3154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.6",
      "Grade": 4,
      "GeneralStandard": 2398,
      "Radical": "目",
      "HSK": 1,
      "FrequencyRank": 41,
      "StrokeCount": 11
    }
  },
  "斩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎn",
      "PrimaryDefinition": "cut, chop, sever; behead"
    },
    "Keys": {
      "Hanzi": 1060,
      "Word": 10638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.4",
      "Grade": 7,
      "GeneralStandard": 1060,
      "Radical": "車",
      "HSK": 8,
      "FrequencyRank": 2463,
      "StrokeCount": 8
    }
  },
  "唐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "Tang dynasty; Chinese"
    },
    "Keys": {
      "Hanzi": 2037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 2037,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 973,
      "StrokeCount": 10
    }
  },
  "欣慰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnwèi",
      "PrimaryDefinition": "delighted, be gratified"
    },
    "Keys": {
      "Word": 10022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葡萄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pútao",
      "PrimaryDefinition": "grape"
    },
    "Keys": {
      "Word": 3784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "怀里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáilǐ",
      "PrimaryDefinition": "embrace, bosom"
    },
    "Keys": {
      "Word": 7155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "paralysis, numbness of limbs"
    },
    "Keys": {
      "Hanzi": 2733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.7",
      "GeneralStandard": 2733,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 3128,
      "StrokeCount": 12
    }
  },
  "送礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng lǐ",
      "PrimaryDefinition": "to give a present"
    },
    "Keys": {
      "Word": 5053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "叛逆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pànnì",
      "PrimaryDefinition": "to rebel, to revolt, a rebel"
    },
    "Keys": {
      "Word": 8470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "改善": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎishàn",
      "PrimaryDefinition": "improve, perfect"
    },
    "Keys": {
      "Word": 2484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "吉祥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíxiáng",
      "PrimaryDefinition": "lucky, auspicious, propitious"
    },
    "Keys": {
      "Word": 4696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "较": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "compare; comparatively, more"
    },
    "Keys": {
      "Hanzi": 1876,
      "Word": 1614
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.6",
      "Grade": 3,
      "GeneralStandard": 1876,
      "Radical": "車",
      "HSK": 3,
      "FrequencyRank": 491,
      "StrokeCount": 10
    }
  },
  "正规": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngguī",
      "PrimaryDefinition": "regular, standard"
    },
    "Keys": {
      "Word": 4209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "大幅度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàfúdù",
      "PrimaryDefinition": "by a wide margin, substantial"
    },
    "Keys": {
      "Word": 6117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "a shuttlecock"
    },
    "Keys": {
      "Hanzi": 5195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5195,
      "Radical": "毛",
      "FrequencyRank": 6291,
      "RadicalIndex": "82.9",
      "StrokeCount": 12
    }
  },
  "酱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "any jam-like or paste-like food"
    },
    "Keys": {
      "Hanzi": 2986,
      "Word": 4717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.6",
      "Grade": 6,
      "GeneralStandard": 2986,
      "Radical": "酉",
      "HSK": 6,
      "FrequencyRank": 3100,
      "StrokeCount": 13
    }
  },
  "毕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "end, finish, conclude; completed"
    },
    "Keys": {
      "Hanzi": 409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "81.2",
      "GeneralStandard": 409,
      "Radical": "比",
      "HSK": 4,
      "FrequencyRank": 1093,
      "StrokeCount": 6
    }
  },
  "戢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "to put away; to cease; store up"
    },
    "Keys": {
      "Hanzi": 5127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5127,
      "Radical": "戈",
      "FrequencyRank": 6205,
      "RadicalIndex": "62.9",
      "StrokeCount": 12
    }
  },
  "认定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèndìng",
      "PrimaryDefinition": "firmly believe, set one's mind on sth."
    },
    "Keys": {
      "Word": 3844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "城区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngqū",
      "PrimaryDefinition": "city district, urban area"
    },
    "Keys": {
      "Word": 4413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "复习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùxí",
      "PrimaryDefinition": "to review, revision, variant of 復習|复习[fu4 xi2]"
    },
    "Keys": {
      "Word": 663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "春天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūntiān",
      "PrimaryDefinition": "spring (season), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "多少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōshao",
      "PrimaryDefinition": "det.: how many, how much, many"
    },
    "Keys": {
      "Word": 93
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "错别字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòbiézì",
      "PrimaryDefinition": "incorrectly written or mispronounced characters"
    },
    "Keys": {
      "Word": 6093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuí",
      "PrimaryDefinition": "ruined, decayed; disintegrate"
    },
    "Keys": {
      "Hanzi": 2950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.7",
      "GeneralStandard": 2950,
      "Radical": "頁",
      "HSK": 9,
      "FrequencyRank": 2996,
      "StrokeCount": 13
    }
  },
  "堡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": "fort, fortress; town, village"
    },
    "Keys": {
      "Hanzi": 2693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.9",
      "GeneralStandard": 2693,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 1521,
      "StrokeCount": 12
    }
  },
  "驰名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chímíng",
      "PrimaryDefinition": "famous"
    },
    "Keys": {
      "Word": 5900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "工人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngren",
      "PrimaryDefinition": "navvy, hired man, operative, working person, laborer, shopworker, esne, working man, hired hand, worker, workpeople, workman, hand"
    },
    "Keys": {
      "Word": 121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "隘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "narrow, confined; a strategic pass"
    },
    "Keys": {
      "Hanzi": 2807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.1",
      "GeneralStandard": 2807,
      "Radical": "阜",
      "HSK": 9,
      "FrequencyRank": 3152,
      "StrokeCount": 12
    }
  },
  "冷门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngmén",
      "PrimaryDefinition": "a neglected branch (of arts, science, sports etc), fig. a complete unknown who wins a competition"
    },
    "Keys": {
      "Word": 7969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "核电站": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hédiànzhàn",
      "PrimaryDefinition": "nuclear power plant"
    },
    "Keys": {
      "Word": 7047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "mountain mist, mountain haze"
    },
    "Keys": {
      "Hanzi": 3717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3717,
      "Radical": "山",
      "FrequencyRank": 2663,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "忠贞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngzhēn",
      "PrimaryDefinition": "loyal and dependable"
    },
    "Keys": {
      "Word": 10820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缴费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎofèi",
      "PrimaryDefinition": "to pay a fee"
    },
    "Keys": {
      "Word": 7485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "新型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxíng",
      "PrimaryDefinition": "pattern, novelty, of new type"
    },
    "Keys": {
      "Word": 3037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "袜子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàzi",
      "PrimaryDefinition": "socks, stockings, CL:隻|只[zhi1],對|对[dui4],雙|双[shuang1]"
    },
    "Keys": {
      "Word": 2957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "知识分子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīshifènzǐ",
      "PrimaryDefinition": "wig, intellectual, educated person, educated person (high school or college equivalent), intellect, intelligentsia, clerisy"
    },
    "Keys": {
      "Word": 10749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妥协": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǒxié",
      "PrimaryDefinition": "come to terms, compromise"
    },
    "Keys": {
      "Word": 9625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "判断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pànduàn",
      "PrimaryDefinition": "judge, determine"
    },
    "Keys": {
      "Word": 1783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "听讲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng jiǎng",
      "PrimaryDefinition": "to attend a lecture, to listen to a talk"
    },
    "Keys": {
      "Word": 1028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "夜以继日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèyǐjìrì",
      "PrimaryDefinition": "night and day (idiom), continuous strenuous effort"
    },
    "Keys": {
      "Word": 10268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mú",
      "PrimaryDefinition": "serge from Tibet"
    },
    "Keys": {
      "Hanzi": 4551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4551,
      "Radical": "毛",
      "RadicalIndex": "82.6",
      "StrokeCount": 10
    }
  },
  "掴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4721,
      "Radical": "手",
      "FrequencyRank": 5461,
      "RadicalIndex": "64.7",
      "StrokeCount": 11
    }
  },
  "大惊小怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjīng-xiǎoguài",
      "PrimaryDefinition": "to make a fuss about nothing (idiom)"
    },
    "Keys": {
      "Word": 6121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "frontier pass; close; relation"
    },
    "Keys": {
      "Hanzi": 532,
      "Word": 2512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.4",
      "Grade": 4,
      "GeneralStandard": 532,
      "Radical": "八",
      "HSK": 1,
      "FrequencyRank": 127,
      "StrokeCount": 6
    }
  },
  "不顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùgù",
      "PrimaryDefinition": "ignore"
    },
    "Keys": {
      "Word": 3272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "悔恨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǐhèn",
      "PrimaryDefinition": "remorse, repentance"
    },
    "Keys": {
      "Word": 7213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "品德": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐndé",
      "PrimaryDefinition": "moral character"
    },
    "Keys": {
      "Word": 8539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "六": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liù",
      "PrimaryDefinition": "number six"
    },
    "Keys": {
      "Hanzi": 160,
      "Word": 214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.2",
      "Grade": 1,
      "GeneralStandard": 160,
      "Radical": "八",
      "HSK": 1,
      "FrequencyRank": 478,
      "StrokeCount": 4
    }
  },
  "梦幻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mènghuàn",
      "PrimaryDefinition": "dream, illusion, reverie"
    },
    "Keys": {
      "Word": 8252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祝贺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùhè",
      "PrimaryDefinition": "to congratulate, congratulations, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 4260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "什么样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénmeyàng",
      "PrimaryDefinition": "what kind?, what sort?, what appearance?"
    },
    "Keys": {
      "Word": 968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "觌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "see; interview; be admitted to audience"
    },
    "Keys": {
      "Hanzi": 7337
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7337,
      "Radical": "見",
      "FrequencyRank": 7086,
      "RadicalIndex": "147.8",
      "StrokeCount": 12
    }
  },
  "方案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngàn",
      "PrimaryDefinition": "formula, plan, scheme, projection, programme, proposal, schema, strategy, project, design, program"
    },
    "Keys": {
      "Word": 2457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "人质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénzhì",
      "PrimaryDefinition": "hostage"
    },
    "Keys": {
      "Word": 8856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6855,
      "Radical": "臼",
      "FrequencyRank": 6672,
      "RadicalIndex": "134.4",
      "StrokeCount": 9
    }
  },
  "蒡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "burdock, herb"
    },
    "Keys": {
      "Hanzi": 5386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5386,
      "Radical": "艸",
      "FrequencyRank": 6034,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "宪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "constitution, statute, law"
    },
    "Keys": {
      "Hanzi": 1699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.6",
      "GeneralStandard": 1699,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 1484,
      "StrokeCount": 9
    }
  },
  "拈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niān",
      "PrimaryDefinition": "pick up with fingers; draw lots"
    },
    "Keys": {
      "Hanzi": 3840
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3840,
      "Radical": "手",
      "FrequencyRank": 3616,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "陈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "exhibit, display; plead; a surname"
    },
    "Keys": {
      "Hanzi": 914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.5",
      "GeneralStandard": 914,
      "Radical": "阜",
      "HSK": 7,
      "FrequencyRank": 525,
      "StrokeCount": 7
    }
  },
  "就餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù cān",
      "PrimaryDefinition": "to dine"
    },
    "Keys": {
      "Word": 7666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毫米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háomǐ",
      "PrimaryDefinition": "millimeter"
    },
    "Keys": {
      "Word": 2537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "虚幻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūhuàn",
      "PrimaryDefinition": "imaginary, illusory"
    },
    "Keys": {
      "Word": 10091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倒是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàoshì",
      "PrimaryDefinition": "actually, contrariwise"
    },
    "Keys": {
      "Word": 3408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "锵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "tinkle, clang, jingle"
    },
    "Keys": {
      "Hanzi": 5738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5738,
      "Radical": "金",
      "FrequencyRank": 3953,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "缟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "white raw silk"
    },
    "Keys": {
      "Hanzi": 5615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5615,
      "Radical": "糸",
      "FrequencyRank": 5286,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "钻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuān",
      "PrimaryDefinition": "drill, bore; pierce; diamond"
    },
    "Keys": {
      "Hanzi": 1930,
      "Word": 5415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "Grade": 6,
      "GeneralStandard": 1930,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 1724,
      "StrokeCount": 10
    }
  },
  "小麦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎomài",
      "PrimaryDefinition": "wheat, CL:粒[li4]"
    },
    "Keys": {
      "Word": 5170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "约会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuēhui",
      "PrimaryDefinition": "appointment, engagement, date, CL:次[ci4],個|个[ge4], to arrange to meet"
    },
    "Keys": {
      "Word": 3135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "无力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúlì",
      "PrimaryDefinition": "powerless, feel weak, lack strength, incapable"
    },
    "Keys": {
      "Word": 9776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoyǒu",
      "PrimaryDefinition": "peer, chum, crony, great friend, good friend, brick"
    },
    "Keys": {
      "Word": 2539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "搬家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān jiā",
      "PrimaryDefinition": "to move house, removal"
    },
    "Keys": {
      "Word": 1272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "凉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "cool, cold; disheartened"
    },
    "Keys": {
      "Hanzi": 2040,
      "Word": 847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.8",
      "Grade": 2,
      "GeneralStandard": 2040,
      "Radical": "冫",
      "HSK": 2,
      "FrequencyRank": 1581,
      "StrokeCount": 10
    }
  },
  "踉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "hop, jump; hurriedly, urgently"
    },
    "Keys": {
      "Hanzi": 5703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5703,
      "Radical": "足",
      "FrequencyRank": 3244,
      "RadicalIndex": "157.7",
      "StrokeCount": 14
    }
  },
  "风气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngqì",
      "PrimaryDefinition": "mores, ethos, order_of_the_day, climate, established practice, genius, genius loci, atmosphere, tone, general mood, common practice, fashion"
    },
    "Keys": {
      "Word": 6574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怨言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànyán",
      "PrimaryDefinition": "complaint"
    },
    "Keys": {
      "Word": 10556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāixiao",
      "PrimaryDefinition": "disbursal, expense, spending, outlay, disbursement, expense account"
    },
    "Keys": {
      "Word": 7758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "taro"
    },
    "Keys": {
      "Hanzi": 370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 370,
      "Radical": "艸",
      "FrequencyRank": 4246,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "代价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàijià",
      "PrimaryDefinition": "reward, cost, cost (of doing sth.), forfeit, expense, damage, wages, payoff, terms, toll, price, wage"
    },
    "Keys": {
      "Word": 3391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "胍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4284,
      "Radical": "肉",
      "FrequencyRank": 5090,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "纪念馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìniànguǎn",
      "PrimaryDefinition": "memorial museum, museum in memory of sb., memorial hall, monument, memorial hall/museum, museum, memorial"
    },
    "Keys": {
      "Word": 7324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缺少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quēshǎo",
      "PrimaryDefinition": "lack, be short of"
    },
    "Keys": {
      "Word": 1833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "虻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "horsefly, gadfly"
    },
    "Keys": {
      "Hanzi": 4212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4212,
      "Radical": "虫",
      "FrequencyRank": 4580,
      "RadicalIndex": "142.3",
      "StrokeCount": 9
    }
  },
  "从小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngxiǎo",
      "PrimaryDefinition": "from childhood, as a child"
    },
    "Keys": {
      "Word": 582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "搋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāi",
      "PrimaryDefinition": "to thump"
    },
    "Keys": {
      "Hanzi": 5357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5357,
      "Radical": "手",
      "FrequencyRank": 7334,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "捎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "to select; to take; to carry"
    },
    "Keys": {
      "Hanzi": 1791,
      "Word": 8990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1791,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3603,
      "StrokeCount": 10
    }
  },
  "价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jià",
      "PrimaryDefinition": "price, value"
    },
    "Keys": {
      "Hanzi": 469,
      "Word": 3590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 5,
      "GeneralStandard": 469,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 409,
      "StrokeCount": 6
    }
  },
  "内心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèixīn",
      "PrimaryDefinition": "interior, inner being, conscience, inside, breast, bosom, innermost being, incenter, heart"
    },
    "Keys": {
      "Word": 1761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "毫升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háoshēng",
      "PrimaryDefinition": "milliliter"
    },
    "Keys": {
      "Word": 2540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蝗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "kind of locust"
    },
    "Keys": {
      "Hanzi": 3250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3250,
      "Radical": "虫",
      "FrequencyRank": 3271,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "占据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànjù",
      "PrimaryDefinition": "inhabit, occupy, stand, assume, prevail, hold, invade, occupation, take, take up"
    },
    "Keys": {
      "Word": 5318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "完成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán chéng",
      "PrimaryDefinition": "fill in, finish out, fulfil, practice, complete, finish, encompass, clear_up, top_off, cleave, top off, accomplishment, make out, effect, perfective, fulfill, go through, exercise, discharge, fill out, completion, end, run, bring ... to fruition, round_out, achieve, execute, round out, make, cap off, accomplish, practise, follow through, come, finalize, fulfilment, dispatch, bring ..."
    },
    "Keys": {
      "Word": 1045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "玑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "pearl that is not quite round"
    },
    "Keys": {
      "Hanzi": 3566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3566,
      "Radical": "玉",
      "FrequencyRank": 2107,
      "RadicalIndex": "96.2",
      "StrokeCount": 6
    }
  },
  "与否": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔfǒu",
      "PrimaryDefinition": "particle: meaning \"whether or not\""
    },
    "Keys": {
      "Word": 10504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6994,
      "Radical": "虫",
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "墙壁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángbì",
      "PrimaryDefinition": "wall"
    },
    "Keys": {
      "Word": 3808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "不为人知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùwéirénzhī",
      "PrimaryDefinition": "not known to anyone, secret, unknown"
    },
    "Keys": {
      "Word": 5712
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "繁华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánhuá",
      "PrimaryDefinition": "flourishing, bustling, busy"
    },
    "Keys": {
      "Word": 6462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "swim, float, wade"
    },
    "Keys": {
      "Hanzi": 4014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4014,
      "Radical": "水",
      "FrequencyRank": 4604,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "急需": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíxū",
      "PrimaryDefinition": "to urgently need, urgent need"
    },
    "Keys": {
      "Word": 7307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "artemisia"
    },
    "Keys": {
      "Hanzi": 4431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4431,
      "Radical": "艸",
      "FrequencyRank": 5829,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "机动车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīdòngchē",
      "PrimaryDefinition": "motor vehicle"
    },
    "Keys": {
      "Word": 4690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "long"
    },
    "Keys": {
      "Hanzi": 127,
      "Radical": 168,
      "Word": 5326
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "168",
      "Grade": 6,
      "GeneralStandard": 127,
      "Radical": "長",
      "HSK": 2,
      "FrequencyRank": 109,
      "StrokeCount": 4
    }
  },
  "尼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "Buddhist nun; transliteration"
    },
    "Keys": {
      "Hanzi": 326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.2",
      "GeneralStandard": 326,
      "Radical": "尸",
      "HSK": 9,
      "FrequencyRank": 654,
      "StrokeCount": 5
    }
  },
  "龉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "uneven teeth; to disagree"
    },
    "Keys": {
      "Hanzi": 5899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5899,
      "Radical": "齒",
      "FrequencyRank": 4917,
      "RadicalIndex": "211.7",
      "StrokeCount": 15
    }
  },
  "餍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5894,
      "Radical": "食",
      "FrequencyRank": 5154,
      "RadicalIndex": "184.6",
      "StrokeCount": 15
    }
  },
  "镜头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngtóu",
      "PrimaryDefinition": "training, lens, scene, shot, camera lens, vertex, optical lens"
    },
    "Keys": {
      "Word": 2639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "高潮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāocháo",
      "PrimaryDefinition": "water, high_tide, crescendo, coming, high tide/water, high tide, climax, upsurge, eagre, springtide, tidemark, sexual climax, blowoff, orgasm, culmination, payoff, wave, high, high water"
    },
    "Keys": {
      "Word": 2488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恶性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èxìng",
      "PrimaryDefinition": "malignant, pernicious, vicious, deadly, malignancy, viciousness, lethal, venomous, fatal, virulent"
    },
    "Keys": {
      "Word": 6415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "匡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāng",
      "PrimaryDefinition": "correct, restore, revise"
    },
    "Keys": {
      "Hanzi": 3563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3563,
      "Radical": "匚",
      "FrequencyRank": 3260,
      "RadicalIndex": "22.4",
      "StrokeCount": 6
    }
  },
  "看样子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn yàngzi",
      "PrimaryDefinition": "it seems, it looks as if"
    },
    "Keys": {
      "Word": 7774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāozhōng",
      "PrimaryDefinition": "senior middle school, senior high school, senior_high_school"
    },
    "Keys": {
      "Word": 679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "奢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shē",
      "PrimaryDefinition": "extravagant, wasteful; exaggerate"
    },
    "Keys": {
      "Hanzi": 2242
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.9",
      "GeneralStandard": 2242,
      "Radical": "大",
      "HSK": 8,
      "FrequencyRank": 2708,
      "StrokeCount": 11
    }
  },
  "蓣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "yam"
    },
    "Keys": {
      "Hanzi": 7491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7491,
      "Radical": "艸",
      "FrequencyRank": 6796,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "火焰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒyàn",
      "PrimaryDefinition": "blaze, flame"
    },
    "Keys": {
      "Word": 7248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "混合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùnhé",
      "PrimaryDefinition": "associate, immingle, muddle, incorporate, mixed, meld, confect, amalgamate, blend in, fuse, interblend, hybrid, admix, mingle, wuzzle, mix, shuffle, mercurify, engraft, concoct, interlace, interweave, immix, go, interfuse, mix_up, sophisticate, marry, combine, mix in, conflate, merge, intermix, jumble, commingle, decompound, intermingle, knead, coalesce, interflow, compound, commix, blend,"
    },
    "Keys": {
      "Word": 4687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "透露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòulù",
      "PrimaryDefinition": "expose, disclose, divulge, develop, impart, unrip, confide, sound, fall, disembosom, get around, reveal, let_on, disclosure, let on, leakage, divulgence, get out, discover, let out, leak, declare, break, give away, bring out"
    },
    "Keys": {
      "Word": 5103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "氖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎi",
      "PrimaryDefinition": "neon"
    },
    "Keys": {
      "Hanzi": 3600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3600,
      "Radical": "气",
      "FrequencyRank": 3602,
      "RadicalIndex": "84.2",
      "StrokeCount": 6
    }
  },
  "诇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiòng",
      "PrimaryDefinition": "to spy; to give information; shrewd"
    },
    "Keys": {
      "Hanzi": 6625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6625,
      "Radical": "言",
      "FrequencyRank": 8485,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "屯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": "village, hamlet; camp; station"
    },
    "Keys": {
      "Hanzi": 103,
      "Word": 9611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "45.1",
      "Grade": 7,
      "GeneralStandard": 103,
      "Radical": "屮",
      "HSK": 9,
      "FrequencyRank": 2864,
      "StrokeCount": 4
    }
  },
  "贫穷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínqióng",
      "PrimaryDefinition": "poor, needy"
    },
    "Keys": {
      "Word": 8535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": "collect, gather up, pick up"
    },
    "Keys": {
      "Hanzi": 4743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4743,
      "Radical": "手",
      "FrequencyRank": 4486,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "高层": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāocéng",
      "PrimaryDefinition": "high-level"
    },
    "Keys": {
      "Word": 4591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "过关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò guān",
      "PrimaryDefinition": "reach standard, pass a barrier, reach a standard, pass test, pass barrier, pass, go through ordeal, pass a test, go through an ordeal"
    },
    "Keys": {
      "Word": 6944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "move lips as when speaking; hesitation"
    },
    "Keys": {
      "Hanzi": 5442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5442,
      "Radical": "口",
      "FrequencyRank": 4125,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "运河": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnhé",
      "PrimaryDefinition": "canal"
    },
    "Keys": {
      "Word": 10575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电灯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàndēng",
      "PrimaryDefinition": "electric light, CL:盞|盏[zhan3]"
    },
    "Keys": {
      "Word": 2413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "黄金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huángjīn",
      "PrimaryDefinition": "gold"
    },
    "Keys": {
      "Word": 2558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "赚钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn qián",
      "PrimaryDefinition": "profit, gain, make profit, cash in on, earn money, make money"
    },
    "Keys": {
      "Word": 5387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "前面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánmian",
      "PrimaryDefinition": "front end, face, forepart, front, in front, ahead, preceding, above, fore, forgoing, frontage, obverse"
    },
    "Keys": {
      "Word": 1810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "陂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "dam, embankment; reservoir"
    },
    "Keys": {
      "Hanzi": 3804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3804,
      "Radical": "阜",
      "FrequencyRank": 4796,
      "RadicalIndex": "170.5",
      "StrokeCount": 7
    }
  },
  "阎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "village gate; surname"
    },
    "Keys": {
      "Hanzi": 2396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.8",
      "GeneralStandard": 2396,
      "Radical": "門",
      "HSK": 9,
      "FrequencyRank": 2473,
      "StrokeCount": 11
    }
  },
  "帘子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánzi",
      "PrimaryDefinition": "curtain"
    },
    "Keys": {
      "Word": 8017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "秘密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìmì",
      "PrimaryDefinition": "secret"
    },
    "Keys": {
      "Word": 2735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "接连": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēlián",
      "PrimaryDefinition": "on end, in a row, in succession"
    },
    "Keys": {
      "Word": 3616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "沙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "sand, gravel, pebbles; granulated"
    },
    "Keys": {
      "Hanzi": 860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 860,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 848,
      "StrokeCount": 7
    }
  },
  "始": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "begin, start; then, only then"
    },
    "Keys": {
      "Hanzi": 1329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1329,
      "Radical": "女",
      "HSK": 3,
      "FrequencyRank": 381,
      "StrokeCount": 8
    }
  },
  "疋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "cloth"
    },
    "Keys": {
      "Radical": 103
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "折磨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhémo",
      "PrimaryDefinition": "to persecute, to torment"
    },
    "Keys": {
      "Word": 10687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笨重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bènzhòng",
      "PrimaryDefinition": "heavy, cumbersome, unwieldy"
    },
    "Keys": {
      "Word": 5571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兴趣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngqù",
      "PrimaryDefinition": "relish, appetite, zestfulness, dish, cup of tea, pastime, hobby, interest, gusto, bag, avocation, zest"
    },
    "Keys": {
      "Word": 3044
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "麂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "species of deer"
    },
    "Keys": {
      "Hanzi": 5547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5547,
      "Radical": "鹿",
      "FrequencyRank": 4713,
      "RadicalIndex": "198.2",
      "StrokeCount": 13
    }
  },
  "技巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìqiǎo",
      "PrimaryDefinition": "technics, artifice, superior skill, acquisition, acquirement, cunning, trick, dexterity, accomplishment, mechanism, craft, craftsmanship, technique, artistry, handiwork, mastery, handicraft, finesse, attainment, sleight, skill, implement, execution, prowess, ingenuity, art, method, dish, mechanics, knack, cup of tea, management, bag, proficiency, address, facility, virtuosity, workman"
    },
    "Keys": {
      "Word": 2585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "问世": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wènshì",
      "PrimaryDefinition": "to be published, to come out"
    },
    "Keys": {
      "Word": 9753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu míng",
      "PrimaryDefinition": "well-known, famous"
    },
    "Keys": {
      "Word": 439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "干吗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gànmá",
      "PrimaryDefinition": "see 幹嘛|干嘛[gan4 ma2]"
    },
    "Keys": {
      "Word": 1479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "虽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": "although, even if"
    },
    "Keys": {
      "Hanzi": 1502,
      "Word": 5057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.3",
      "Grade": 6,
      "GeneralStandard": 1502,
      "Radical": "虫",
      "HSK": 2,
      "FrequencyRank": 504,
      "StrokeCount": 9
    }
  },
  "厥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "personal pronoun he, she, it"
    },
    "Keys": {
      "Hanzi": 5112
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5112,
      "Radical": "厂",
      "FrequencyRank": 2755,
      "RadicalIndex": "27.1",
      "StrokeCount": 12
    }
  },
  "览": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "look at, inspect; perceive"
    },
    "Keys": {
      "Hanzi": 1472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "147.5",
      "GeneralStandard": 1472,
      "Radical": "見",
      "HSK": 5,
      "FrequencyRank": 1886,
      "StrokeCount": 9
    }
  },
  "林": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "forest, grove; surname"
    },
    "Keys": {
      "Hanzi": 1018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1018,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 364,
      "StrokeCount": 8
    }
  },
  "黑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēi",
      "PrimaryDefinition": "black"
    },
    "Keys": {
      "Hanzi": 2655,
      "Radical": 203,
      "Word": 716
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "203",
      "Grade": 2,
      "GeneralStandard": 2655,
      "Radical": "黑",
      "HSK": 2,
      "FrequencyRank": 519,
      "StrokeCount": 12
    }
  },
  "釜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "cauldron, pot, kettle"
    },
    "Keys": {
      "Hanzi": 4586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4586,
      "Radical": "金",
      "FrequencyRank": 3786,
      "RadicalIndex": "167.2",
      "StrokeCount": 10
    }
  },
  "嗑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "eat seeds; reproach; loquacious"
    },
    "Keys": {
      "Hanzi": 5441
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5441,
      "Radical": "口",
      "FrequencyRank": 4594,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "幸好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnghǎo",
      "PrimaryDefinition": "fortunately, luckily"
    },
    "Keys": {
      "Word": 10057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "power"
    },
    "Keys": {
      "Hanzi": 19,
      "Radical": 19,
      "Word": 4819
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19",
      "Grade": 6,
      "GeneralStandard": 19,
      "Radical": "力",
      "HSK": 2,
      "FrequencyRank": 106,
      "StrokeCount": 2
    }
  },
  "饮用水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnyòngshuǐ",
      "PrimaryDefinition": "drinking water, potable water"
    },
    "Keys": {
      "Word": 10396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "指令": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐlìng",
      "PrimaryDefinition": "directive, instruction, dictation, bid, decree, rescript, fiat, injunction, dictate, instructions, order, bidding, mandate, command, edict"
    },
    "Keys": {
      "Word": 10773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "a hollow in the ground, a cavity, depression; undulating, depressed"
    },
    "Keys": {
      "Hanzi": 3852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3852,
      "Radical": "土",
      "FrequencyRank": 4450,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "特长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tècháng",
      "PrimaryDefinition": "personal strength, one's special ability or strong points"
    },
    "Keys": {
      "Word": 9446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亲和力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnhélì",
      "PrimaryDefinition": "(personal) warmth, approachability, accessibility, (in a product) user friendliness, (chemistry) affinity"
    },
    "Keys": {
      "Word": 8717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "整洁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngjié",
      "PrimaryDefinition": "neatly, tidy"
    },
    "Keys": {
      "Word": 10731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "适用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyòng",
      "PrimaryDefinition": "applicable, lend oneself, apply, be applicable, suit"
    },
    "Keys": {
      "Word": 1919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鹮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "spoonbill; ibis; family Threskiornidae"
    },
    "Keys": {
      "Hanzi": 6339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6339,
      "Radical": "鳥",
      "FrequencyRank": 5991,
      "RadicalIndex": "196.13",
      "StrokeCount": 18
    }
  },
  "卞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "to be impatient, in a hurry; excitable"
    },
    "Keys": {
      "Hanzi": 3521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3521,
      "Radical": "卜",
      "FrequencyRank": 4534,
      "RadicalIndex": "25.2",
      "StrokeCount": 4
    }
  },
  "醪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "unclear wine, wine with dregs"
    },
    "Keys": {
      "Hanzi": 6323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6323,
      "Radical": "酉",
      "FrequencyRank": 5193,
      "RadicalIndex": "164.11",
      "StrokeCount": 18
    }
  },
  "颧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "cheek bones"
    },
    "Keys": {
      "Hanzi": 6481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6481,
      "Radical": "頁",
      "FrequencyRank": 4593,
      "RadicalIndex": "181.17",
      "StrokeCount": 23
    }
  },
  "奏效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòu xiào",
      "PrimaryDefinition": "to show results, effective"
    },
    "Keys": {
      "Word": 11004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开支": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāizhī",
      "PrimaryDefinition": "expenditures, expenses, CL:筆|笔[bi3], 項|项[xiang4], to spend money, (coll.) to pay wages"
    },
    "Keys": {
      "Word": 7760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领事馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngshìguǎn",
      "PrimaryDefinition": "consulate"
    },
    "Keys": {
      "Word": 8081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "cheat, double-cross, deceive"
    },
    "Keys": {
      "Hanzi": 2543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.8",
      "GeneralStandard": 2543,
      "Radical": "欠",
      "HSK": 6,
      "FrequencyRank": 1699,
      "StrokeCount": 12
    }
  },
  "扣除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòuchú",
      "PrimaryDefinition": "to deduct"
    },
    "Keys": {
      "Word": 7851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "约束": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuēshù",
      "PrimaryDefinition": "control, restrain, bind"
    },
    "Keys": {
      "Word": 4160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "刃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "edged tool, cutlery, knife edge"
    },
    "Keys": {
      "Hanzi": 68
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 68,
      "Radical": "刀",
      "FrequencyRank": 2700,
      "RadicalIndex": "18.1",
      "StrokeCount": 3
    }
  },
  "豆浆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòujiāng",
      "PrimaryDefinition": "soy milk"
    },
    "Keys": {
      "Word": 6351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xué",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6726
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6726,
      "Radical": "山",
      "RadicalIndex": "46.13",
      "StrokeCount": 8
    }
  },
  "试题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìtí",
      "PrimaryDefinition": "exam question, test topic"
    },
    "Keys": {
      "Word": 1917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "司令": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīlìng",
      "PrimaryDefinition": "commanding officer"
    },
    "Keys": {
      "Word": 9303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "选拔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnbá",
      "PrimaryDefinition": "to select the best"
    },
    "Keys": {
      "Word": 5200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "孤单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūdān",
      "PrimaryDefinition": "lone, lonely, loneliness"
    },
    "Keys": {
      "Word": 6819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuì",
      "PrimaryDefinition": "fall down, drop, sink, go to ruin"
    },
    "Keys": {
      "Hanzi": 917,
      "Word": 10922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 7,
      "GeneralStandard": 917,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 2279,
      "StrokeCount": 7
    }
  },
  "难忘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánwàng",
      "PrimaryDefinition": "unforgettable, memorable"
    },
    "Keys": {
      "Word": 4886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "占有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànyǒu",
      "PrimaryDefinition": "own, have, occupy, hold"
    },
    "Keys": {
      "Word": 4191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "到头来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàotóulái",
      "PrimaryDefinition": "in the end, finally, as a result"
    },
    "Keys": {
      "Word": 6198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "漻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7732,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "爬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pá",
      "PrimaryDefinition": "crawl, creep; climb; scramble"
    },
    "Keys": {
      "Hanzi": 1161,
      "Word": 902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "87.4",
      "Grade": 2,
      "GeneralStandard": 1161,
      "Radical": "爪",
      "HSK": 2,
      "FrequencyRank": 1426,
      "StrokeCount": 8
    }
  },
  "沧桑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāngsāng",
      "PrimaryDefinition": "great changes, abbr. of 滄海桑田|沧海桑田[cang1 hai3 sang1 tian2]"
    },
    "Keys": {
      "Word": 5764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偏向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānxiàng",
      "PrimaryDefinition": "partial towards sth, to prefer, to incline, erroneous tendencies (Leftist or Revisionist deviation)"
    },
    "Keys": {
      "Word": 8523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曲折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūzhé",
      "PrimaryDefinition": "tortuous, winding, complicated"
    },
    "Keys": {
      "Word": 8776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䎃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎn",
      "PrimaryDefinition": "fine down; floss; wool"
    },
    "Keys": {
      "Hanzi": 7169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7169,
      "Radical": "羽",
      "RadicalIndex": "124.5",
      "StrokeCount": 11
    }
  },
  "粲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càn",
      "PrimaryDefinition": "polish; bright, radiant; smiling"
    },
    "Keys": {
      "Hanzi": 5429
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5429,
      "Radical": "米",
      "FrequencyRank": 4810,
      "RadicalIndex": "119.7",
      "StrokeCount": 13
    }
  },
  "低谷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīgǔ",
      "PrimaryDefinition": "valley, trough (as opposed to peaks), fig. low point, lowest ebb, nadir of one's fortunes"
    },
    "Keys": {
      "Word": 6228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掌声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎngshēng",
      "PrimaryDefinition": "clapping, applause"
    },
    "Keys": {
      "Word": 5325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "爱人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àiren",
      "PrimaryDefinition": "spouse (PRC), lover (non-PRC), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "弊端": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìduān",
      "PrimaryDefinition": "malpractice, abuse, corrupt practice"
    },
    "Keys": {
      "Word": 5591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "对峙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìzhì",
      "PrimaryDefinition": "to stand opposite, to confront, confrontation"
    },
    "Keys": {
      "Word": 6384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": "constant, regular, persistent"
    },
    "Keys": {
      "Hanzi": 1685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1685,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1764,
      "StrokeCount": 9
    }
  },
  "蛋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "eggs; term of abuse"
    },
    "Keys": {
      "Hanzi": 2477,
      "Word": 602
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.5",
      "Grade": 2,
      "GeneralStandard": 2477,
      "Radical": "虫",
      "HSK": 1,
      "FrequencyRank": 1387,
      "StrokeCount": 11
    }
  },
  "收集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōují",
      "PrimaryDefinition": "collect, gather"
    },
    "Keys": {
      "Word": 3912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "哙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "swallow, gulp down; greedy"
    },
    "Keys": {
      "Hanzi": 4222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4222,
      "Radical": "口",
      "FrequencyRank": 5981,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "佩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "belt ornament, pendant; wear at waist, tie to the belt; respect"
    },
    "Keys": {
      "Hanzi": 1150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1150,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 1507,
      "StrokeCount": 8
    }
  },
  "开展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāizhǎn",
      "PrimaryDefinition": "develop, launch, unfold"
    },
    "Keys": {
      "Word": 1665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "岘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "steep hill; mountain in Hubei"
    },
    "Keys": {
      "Hanzi": 3715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3715,
      "Radical": "山",
      "FrequencyRank": 5210,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "挂失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà shī",
      "PrimaryDefinition": "to report the loss of something"
    },
    "Keys": {
      "Word": 6857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "受过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu guò",
      "PrimaryDefinition": "Suffer"
    },
    "Keys": {
      "Word": 9192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "high water, flood tides"
    },
    "Keys": {
      "Hanzi": 539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.3",
      "GeneralStandard": 539,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3759,
      "StrokeCount": 6
    }
  },
  "桃树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táoshù",
      "PrimaryDefinition": "peach tree, CL:株[zhu1]"
    },
    "Keys": {
      "Word": 3965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "粒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "grain; small particle"
    },
    "Keys": {
      "Hanzi": 2404,
      "Word": 8008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.5",
      "Grade": 7,
      "GeneralStandard": 2404,
      "Radical": "米",
      "HSK": 7,
      "FrequencyRank": 1714,
      "StrokeCount": 11
    }
  },
  "起源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐyuán",
      "PrimaryDefinition": "origin"
    },
    "Keys": {
      "Word": 8618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "导向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoxiàng",
      "PrimaryDefinition": "orientation"
    },
    "Keys": {
      "Word": 6191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呼吁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūyù",
      "PrimaryDefinition": "to call on (sb to do sth), to appeal (to), an appeal"
    },
    "Keys": {
      "Word": 7111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可悲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěbēi",
      "PrimaryDefinition": "lamentable"
    },
    "Keys": {
      "Word": 7793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抑制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìzhì",
      "PrimaryDefinition": "forbear, chasten, last out, govern, forebear, help, baste, cumber, rein, hamshackle, damp, disable, harness, restrain, muffle, stay, rule, hold in, bottle_up, throttle, constrain, retard, moderate, cork, swallow, hold, mortify, smother, rebuke, withhold, refrain, soften, control, break, curb, oppress, inhibit, ride out, coerce, dominate, bridle, constrict, choke, inhibition, dampen, restraint"
    },
    "Keys": {
      "Word": 10368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèn",
      "PrimaryDefinition": "to move, to shake"
    },
    "Keys": {
      "Hanzi": 6563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6563,
      "Radical": "手",
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "疃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǎn",
      "PrimaryDefinition": "hamlet; area outside city"
    },
    "Keys": {
      "Hanzi": 6233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6233,
      "Radical": "田",
      "FrequencyRank": 5693,
      "RadicalIndex": "102.12",
      "StrokeCount": 17
    }
  },
  "瞬间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnjiān",
      "PrimaryDefinition": "moment, momentary, in a flash"
    },
    "Keys": {
      "Word": 9293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "商城": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngchéng",
      "PrimaryDefinition": "see 商城縣|商城县[Shang1 cheng2 xian4], shopping center, department store"
    },
    "Keys": {
      "Word": 4994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "玛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎ",
      "PrimaryDefinition": "agate; cornelian"
    },
    "Keys": {
      "Hanzi": 603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 603,
      "Radical": "玉",
      "FrequencyRank": 1248,
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "演习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnxí",
      "PrimaryDefinition": "maneuver, exercise, practice, to maneuver"
    },
    "Keys": {
      "Word": 10203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "启": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "open; begin, commence; explain"
    },
    "Keys": {
      "Hanzi": 886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 886,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1320,
      "StrokeCount": 7
    }
  },
  "绣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "embroider; embroidery; ornament"
    },
    "Keys": {
      "Hanzi": 2151,
      "Word": 10087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.7",
      "Grade": 7,
      "GeneralStandard": 2151,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2434,
      "StrokeCount": 10
    }
  },
  "川流不息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuānliú-bùxī",
      "PrimaryDefinition": "the stream flows without stopping (idiom), unending flow"
    },
    "Keys": {
      "Word": 6000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "lively, noisy; clamor, talk loudly"
    },
    "Keys": {
      "Hanzi": 2646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "GeneralStandard": 2646,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2778,
      "StrokeCount": 12
    }
  },
  "跳槽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiào cáo",
      "PrimaryDefinition": "to change jobs, job-hopping"
    },
    "Keys": {
      "Word": 9505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "合作社": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hézuòshè",
      "PrimaryDefinition": "cooperative, cooperation, co-op, artel"
    },
    "Keys": {
      "Word": 7031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓶子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngzi",
      "PrimaryDefinition": "bottle, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "忤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "insubordinate, stubborn; wrong"
    },
    "Keys": {
      "Hanzi": 3786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3786,
      "Radical": "心",
      "FrequencyRank": 5039,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "踯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "waver, hesitate, be irresolute"
    },
    "Keys": {
      "Hanzi": 5917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5917,
      "Radical": "足",
      "FrequencyRank": 5628,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "璟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "luster of gem"
    },
    "Keys": {
      "Hanzi": 6030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6030,
      "Radical": "玉",
      "RadicalIndex": "96.12",
      "StrokeCount": 16
    }
  },
  "疽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "ulcer, carbuncle, abscess"
    },
    "Keys": {
      "Hanzi": 4617
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4617,
      "Radical": "疒",
      "FrequencyRank": 4182,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "瓜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guā",
      "PrimaryDefinition": "melon"
    },
    "Keys": {
      "Hanzi": 279,
      "Radical": 97,
      "Word": 2521
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "97",
      "Grade": 4,
      "GeneralStandard": 279,
      "Radical": "瓜",
      "HSK": 4,
      "FrequencyRank": 1827,
      "StrokeCount": 5
    }
  },
  "上演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngyǎn",
      "PrimaryDefinition": "produce, bring on, put on stage, play, stage, mount, perform, bring out, performance"
    },
    "Keys": {
      "Word": 4997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "谬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miù",
      "PrimaryDefinition": "error, exaggeration; erroneous"
    },
    "Keys": {
      "Hanzi": 3034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.11",
      "GeneralStandard": 3034,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2390,
      "StrokeCount": 13
    }
  },
  "岨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "uneven"
    },
    "Keys": {
      "Hanzi": 6683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6683,
      "Radical": "山",
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "元": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "first; dollar; origin; head"
    },
    "Keys": {
      "Hanzi": 80,
      "Word": 447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.2",
      "Grade": 1,
      "GeneralStandard": 80,
      "Radical": "儿",
      "HSK": 1,
      "FrequencyRank": 370,
      "StrokeCount": 4
    }
  },
  "络绎不绝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luòyì-bùjué",
      "PrimaryDefinition": "continuously, in an endless stream (idiom)"
    },
    "Keys": {
      "Word": 8167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风貌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngmào",
      "PrimaryDefinition": "view, scene, elegant appearance and bearing, appearance, style and features, style and feature"
    },
    "Keys": {
      "Word": 6573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "have, own, possess; exist"
    },
    "Keys": {
      "Hanzi": 390,
      "Word": 438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "74.2",
      "Grade": 1,
      "GeneralStandard": 390,
      "Radical": "月",
      "HSK": 1,
      "FrequencyRank": 8,
      "StrokeCount": 6
    }
  },
  "废墟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèixū",
      "PrimaryDefinition": "ruins"
    },
    "Keys": {
      "Word": 6528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愁眉苦脸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóuméi-kǔliǎn",
      "PrimaryDefinition": "to look anxious (idiom), to look miserable"
    },
    "Keys": {
      "Word": 5938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "举报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔbào",
      "PrimaryDefinition": "to report (malefactors to the police), to denounce"
    },
    "Keys": {
      "Word": 7687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预售": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùshòu",
      "PrimaryDefinition": "sell in advance"
    },
    "Keys": {
      "Word": 10519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "five, company of five; troops"
    },
    "Keys": {
      "Hanzi": 459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 459,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1472,
      "StrokeCount": 6
    }
  },
  "觅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "seek; search"
    },
    "Keys": {
      "Hanzi": 1173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "87.4",
      "GeneralStandard": 1173,
      "Radical": "爪",
      "HSK": 8,
      "FrequencyRank": 2825,
      "StrokeCount": 8
    }
  },
  "吵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎo",
      "PrimaryDefinition": "argue, dispute; disturb, annoy"
    },
    "Keys": {
      "Hanzi": 735,
      "Word": 1341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 3,
      "GeneralStandard": 735,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 2040,
      "StrokeCount": 7
    }
  },
  "外籍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàijí",
      "PrimaryDefinition": "foreign nationality"
    },
    "Keys": {
      "Word": 9638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "companion, mate, colleague"
    },
    "Keys": {
      "Hanzi": 4256
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4256,
      "Radical": "人",
      "FrequencyRank": 4502,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "噙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "hold in mouth; bite"
    },
    "Keys": {
      "Hanzi": 5934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5934,
      "Radical": "口",
      "FrequencyRank": 4599,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "俱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "all, together; accompany"
    },
    "Keys": {
      "Hanzi": 1962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1962,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1909,
      "StrokeCount": 10
    }
  },
  "显出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn chū",
      "PrimaryDefinition": "to express, to exhibit"
    },
    "Keys": {
      "Word": 5161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "对待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìdài",
      "PrimaryDefinition": "treat, approach, handle"
    },
    "Keys": {
      "Word": 1423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "捺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "to press down heavily with the fingers"
    },
    "Keys": {
      "Hanzi": 2165
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2165,
      "Radical": "手",
      "FrequencyRank": 3841,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "墓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "grave, tomb"
    },
    "Keys": {
      "Hanzi": 2853,
      "Word": 4882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.11",
      "Grade": 6,
      "GeneralStandard": 2853,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 1816,
      "StrokeCount": 13
    }
  },
  "良好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánghǎo",
      "PrimaryDefinition": "good, well"
    },
    "Keys": {
      "Word": 2699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "懒得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎnde",
      "PrimaryDefinition": "not to feel like (doing sth), disinclined to"
    },
    "Keys": {
      "Word": 7930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有时候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒushíhou",
      "PrimaryDefinition": "from time to time, on_occasion, occasionally, somewhile, otherwhile, now and again, sometimes, now and then, at times, on occasion, whiles, once in a while"
    },
    "Keys": {
      "Word": 441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "实力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shílì",
      "PrimaryDefinition": "strength, actual strength, efficiency"
    },
    "Keys": {
      "Word": 1900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "遭殃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāo yāng",
      "PrimaryDefinition": "to suffer a calamity"
    },
    "Keys": {
      "Word": 10605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "听从": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīngcóng",
      "PrimaryDefinition": "to listen and obey, to comply with, to heed, to hearken"
    },
    "Keys": {
      "Word": 9512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清醒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxǐng",
      "PrimaryDefinition": "waken, wake up, sober up, be wide-awake, be clear-headed, wide-awake, wake, be sober, awaken, be sober/wide-awake, sober, come alive, awake, come to, regain consciousness, arouse, sober_up"
    },
    "Keys": {
      "Word": 2809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "投票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu piào",
      "PrimaryDefinition": "ballot, tender, bid, poll, cast, cast a vote, vote"
    },
    "Keys": {
      "Word": 5101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "䗖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "rainbow"
    },
    "Keys": {
      "Hanzi": 7772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7772,
      "Radical": "虫",
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "家政": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāzhèng",
      "PrimaryDefinition": "housekeeping"
    },
    "Keys": {
      "Word": 7356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "功率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnglǜ",
      "PrimaryDefinition": "rate of work, power (output)"
    },
    "Keys": {
      "Word": 6793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遗体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yítǐ",
      "PrimaryDefinition": "remains (of a dead person)"
    },
    "Keys": {
      "Word": 10306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "tent; curtain, screen"
    },
    "Keys": {
      "Hanzi": 2297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2297,
      "Radical": "巾",
      "FrequencyRank": 3448,
      "RadicalIndex": "50.8",
      "StrokeCount": 11
    }
  },
  "以上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐshàng",
      "PrimaryDefinition": "the above/foregoing/above-mentioned"
    },
    "Keys": {
      "Word": 1144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "perform, put on; exercise"
    },
    "Keys": {
      "Hanzi": 3167,
      "Word": 2078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 3,
      "GeneralStandard": 3167,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 715,
      "StrokeCount": 14
    }
  },
  "脸盆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎnpén",
      "PrimaryDefinition": "washbowl, basin for washing hands and face, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "员工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuángōng",
      "PrimaryDefinition": "employee, personnel, reason, force, staff, cause"
    },
    "Keys": {
      "Word": 2122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "荑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "sprouts; tares"
    },
    "Keys": {
      "Hanzi": 4110
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4110,
      "Radical": "艸",
      "FrequencyRank": 6285,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "展望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnwàng",
      "PrimaryDefinition": "look into the distance/future"
    },
    "Keys": {
      "Word": 10643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "samarium"
    },
    "Keys": {
      "Hanzi": 6689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6689,
      "Radical": "金",
      "FrequencyRank": 6188,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "太阳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàiyáng",
      "PrimaryDefinition": "Apollo, luminary, daystar, digit, sunlight, solar, sunshine, Phoebus, lamp, sun, Sol, fireball"
    },
    "Keys": {
      "Word": 1008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "盛会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shènghuì",
      "PrimaryDefinition": "pageant, distinguished meeting"
    },
    "Keys": {
      "Word": 9076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zā",
      "PrimaryDefinition": "to suck, to smack the lips; (Cant.) to cheat"
    },
    "Keys": {
      "Hanzi": 3901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3901,
      "Radical": "口",
      "FrequencyRank": 4167,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "噫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "belch; alas"
    },
    "Keys": {
      "Hanzi": 6106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6106,
      "Radical": "口",
      "FrequencyRank": 4489,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "支票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīpiào",
      "PrimaryDefinition": "check (bank), cheque, CL:本[ben3]"
    },
    "Keys": {
      "Word": 10741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侦察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnchá",
      "PrimaryDefinition": "to investigate a crime, to scout, to reconnoiter, reconnaissance, detection, a scout"
    },
    "Keys": {
      "Word": 10693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偶然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒurán",
      "PrimaryDefinition": "accidentally, occasionally"
    },
    "Keys": {
      "Word": 3754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "列入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièrù",
      "PrimaryDefinition": "be listed, be listed/placed, placed, be placed, rank"
    },
    "Keys": {
      "Word": 2707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "见义勇为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànyì-yǒngwéi",
      "PrimaryDefinition": "to see what is right and act courageously (idiom, from Analects), to stand up bravely for the truth, acting heroically in a just cause"
    },
    "Keys": {
      "Word": 7422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "overflow; rushing water, a torrent"
    },
    "Keys": {
      "Hanzi": 3011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3011,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2760,
      "StrokeCount": 13
    }
  },
  "表现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎoxiàn",
      "PrimaryDefinition": "manifestation, expression"
    },
    "Keys": {
      "Word": 1309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "萋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "luxuriant foliage; crowded"
    },
    "Keys": {
      "Hanzi": 4751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4751,
      "Radical": "艸",
      "FrequencyRank": 5734,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "宣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "declare, announce, proclaim"
    },
    "Keys": {
      "Hanzi": 1695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.6",
      "GeneralStandard": 1695,
      "Radical": "宀",
      "HSK": 3,
      "FrequencyRank": 770,
      "StrokeCount": 9
    }
  },
  "他人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tārén",
      "PrimaryDefinition": "others, another person"
    },
    "Keys": {
      "Word": 9384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sháo",
      "PrimaryDefinition": "music of the emperor Shun; beautiful"
    },
    "Keys": {
      "Hanzi": 5786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5786,
      "Radical": "音",
      "FrequencyRank": 3458,
      "RadicalIndex": "180.5",
      "StrokeCount": 14
    }
  },
  "砝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "balance weights"
    },
    "Keys": {
      "Hanzi": 4473
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4473,
      "Radical": "石",
      "FrequencyRank": 5036,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "藏匿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cángnì",
      "PrimaryDefinition": "to cover up, to conceal, to go into hiding"
    },
    "Keys": {
      "Word": 5768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坚强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānqiáng",
      "PrimaryDefinition": "strong, firm, staunch"
    },
    "Keys": {
      "Word": 1596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "胯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuà",
      "PrimaryDefinition": "pelvis; groin; thighs"
    },
    "Keys": {
      "Hanzi": 4589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4589,
      "Radical": "肉",
      "FrequencyRank": 4526,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "手臂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒubì",
      "PrimaryDefinition": "arm"
    },
    "Keys": {
      "Word": 9172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "curtain that forms wall"
    },
    "Keys": {
      "Hanzi": 3712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3712,
      "Radical": "巾",
      "FrequencyRank": 5809,
      "RadicalIndex": "50.4",
      "StrokeCount": 7
    }
  },
  "麻痹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mábì",
      "PrimaryDefinition": "paralysis, palsy, numbness, to benumb, (fig.) to lull, negligent, apathetic"
    },
    "Keys": {
      "Word": 8175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "得体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "détǐ",
      "PrimaryDefinition": "appropriate to the occasion, fitting"
    },
    "Keys": {
      "Word": 6212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "reef; jetty; submerged rocks"
    },
    "Keys": {
      "Hanzi": 3397
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.12",
      "GeneralStandard": 3397,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 2952,
      "StrokeCount": 17
    }
  },
  "抒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "express; eliminate; relieve"
    },
    "Keys": {
      "Hanzi": 659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 659,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 3071,
      "StrokeCount": 7
    }
  },
  "皈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "follow, comply with"
    },
    "Keys": {
      "Hanzi": 4265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4265,
      "Radical": "白",
      "FrequencyRank": 3660,
      "RadicalIndex": "106.4",
      "StrokeCount": 9
    }
  },
  "啊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "a",
      "PrimaryDefinition": "exclamatory particle"
    },
    "Keys": {
      "Hanzi": 1915,
      "Word": 2228
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "Grade": 4,
      "GeneralStandard": 1915,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 753,
      "StrokeCount": 10
    }
  },
  "取代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔdài",
      "PrimaryDefinition": "replace, substitute for, replacement, supersede, substitute, supersession, supplant, displace, preempt, outplace, take the place of, displacement"
    },
    "Keys": {
      "Word": 8783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "a saw; to saw; amputate"
    },
    "Keys": {
      "Hanzi": 2944,
      "Word": 7710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.8",
      "Grade": 7,
      "GeneralStandard": 2944,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 3078,
      "StrokeCount": 13
    }
  },
  "藨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7990,
      "Radical": "艸",
      "FrequencyRank": 9507,
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "槔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5665,
      "Radical": "木",
      "FrequencyRank": 5833,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "传统": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuántǒng",
      "PrimaryDefinition": "rule, patrimony, legacy, custom, heritage, convention, conventionality, routine, normal practice, common practice, tradition"
    },
    "Keys": {
      "Word": 2342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "砫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6970
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6970,
      "Radical": "石",
      "FrequencyRank": 9388,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "笑话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàohua",
      "PrimaryDefinition": "joke, jest"
    },
    "Keys": {
      "Word": 1100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "交给": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāogěi",
      "PrimaryDefinition": "relinquish, give_away, submit, give to, hand/give to, hand to, give_up, reach, hand"
    },
    "Keys": {
      "Word": 764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "庱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chěng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7220,
      "Radical": "广",
      "RadicalIndex": "53.8",
      "StrokeCount": 11
    }
  },
  "话筒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàtǒng",
      "PrimaryDefinition": "microphone, (telephone) receiver, handset"
    },
    "Keys": {
      "Word": 7151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "platinum; thin sheet of metal"
    },
    "Keys": {
      "Hanzi": 4537
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4537,
      "Radical": "金",
      "FrequencyRank": 4697,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "忳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6621,
      "Radical": "心",
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "病房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngfáng",
      "PrimaryDefinition": "sickroom, ward, hospital ward, hospital room"
    },
    "Keys": {
      "Word": 4340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "翊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "flying; assist, help; respect"
    },
    "Keys": {
      "Hanzi": 4941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4941,
      "Radical": "羽",
      "FrequencyRank": 4429,
      "RadicalIndex": "124.5",
      "StrokeCount": 11
    }
  },
  "佩服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèifu",
      "PrimaryDefinition": "admire"
    },
    "Keys": {
      "Word": 8485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牺牲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīshēng",
      "PrimaryDefinition": "sacrifice oneself, die martyr's death, do sth. at the expense of self or sb. else"
    },
    "Keys": {
      "Word": 5154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "偾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "ruin, cause fail; overthrown"
    },
    "Keys": {
      "Hanzi": 4904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4904,
      "Radical": "人",
      "FrequencyRank": 5778,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "展出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnchū",
      "PrimaryDefinition": "exhibit"
    },
    "Keys": {
      "Word": 10640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过渡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòdù",
      "PrimaryDefinition": "to cross over (by ferry), transition, interim, caretaker (administration)"
    },
    "Keys": {
      "Word": 4645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "澍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "timely rain, life-giving rain"
    },
    "Keys": {
      "Hanzi": 5997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5997,
      "Radical": "水",
      "FrequencyRank": 5667,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "谰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "accuse falsely; slander, libel"
    },
    "Keys": {
      "Hanzi": 5825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5825,
      "Radical": "言",
      "FrequencyRank": 5587,
      "RadicalIndex": "149.12",
      "StrokeCount": 14
    }
  },
  "垒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "rampart, military wall"
    },
    "Keys": {
      "Hanzi": 1751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.6",
      "GeneralStandard": 1751,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 2417,
      "StrokeCount": 9
    }
  },
  "贶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "give, grant, bestow; surname"
    },
    "Keys": {
      "Hanzi": 4238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4238,
      "Radical": "貝",
      "FrequencyRank": 6978,
      "RadicalIndex": "154.5",
      "StrokeCount": 9
    }
  },
  "节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "knot, node, joint; section"
    },
    "Keys": {
      "Hanzi": 209,
      "Word": 4728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.2",
      "Grade": 6,
      "GeneralStandard": 209,
      "Radical": "艸",
      "HSK": 2,
      "FrequencyRank": 514,
      "StrokeCount": 5
    }
  },
  "响声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngshēng",
      "PrimaryDefinition": "noise"
    },
    "Keys": {
      "Word": 5164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "袷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "lined garment"
    },
    "Keys": {
      "Hanzi": 5000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5000,
      "Radical": "衣",
      "FrequencyRank": 5322,
      "RadicalIndex": "145.6",
      "StrokeCount": 11
    }
  },
  "桊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7047,
      "Radical": "木",
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "恳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěn",
      "PrimaryDefinition": "sincere, earnest, cordial"
    },
    "Keys": {
      "Hanzi": 2130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 2130,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 2265,
      "StrokeCount": 10
    }
  },
  "䓬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7126,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 14
    }
  },
  "强调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángdiào",
      "PrimaryDefinition": "underscore, spotlight, place emphasis on, point_up, highlight, point, point up, enforce, emphasise, urge, underline, overstress, give emphasis to, play up, keynote, insist, punctuate, accent, betone, foreground, emphasize, emphatic, stress, set off, overemphasize, accentuate, bring out"
    },
    "Keys": {
      "Word": 1813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "熟练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shúliàn",
      "PrimaryDefinition": "practiced, proficient, skilled, skillful"
    },
    "Keys": {
      "Word": 2886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "腈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 5227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5227,
      "Radical": "肉",
      "FrequencyRank": 6461,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "幻影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànyǐng",
      "PrimaryDefinition": "phantom, mirage"
    },
    "Keys": {
      "Word": 7172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "为人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéirén",
      "PrimaryDefinition": "to conduct oneself, behavior, conduct, personal character, for sb, for others' interest"
    },
    "Keys": {
      "Word": 9695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "个别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèbié",
      "PrimaryDefinition": "specifically, very few, specific, one or two exceptional, individual, exceptional"
    },
    "Keys": {
      "Word": 2496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "音像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnxiàng",
      "PrimaryDefinition": "audio and video, audiovisual"
    },
    "Keys": {
      "Word": 5255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "经济": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngjì",
      "PrimaryDefinition": "economy, financial condition, income"
    },
    "Keys": {
      "Word": 1636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "大款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàkuǎn",
      "PrimaryDefinition": "very wealthy person"
    },
    "Keys": {
      "Word": 6123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "默契": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòqì",
      "PrimaryDefinition": "tacit/secret agreement, tacit agreement, understanding, secret agreement, privity, tacit"
    },
    "Keys": {
      "Word": 8338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngshì",
      "PrimaryDefinition": "consul"
    },
    "Keys": {
      "Word": 8080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "着重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuózhòng",
      "PrimaryDefinition": "underscore, accent, concentrate, emphasize, attach importance, stress, set off, underline, accentuate, bring out"
    },
    "Keys": {
      "Word": 10938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "决策": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juécè",
      "PrimaryDefinition": "policy decision"
    },
    "Keys": {
      "Word": 4771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "邛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "in distress; a mound"
    },
    "Keys": {
      "Hanzi": 3529
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3529,
      "Radical": "邑",
      "FrequencyRank": 4885,
      "RadicalIndex": "163.3",
      "StrokeCount": 5
    }
  },
  "镨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "praseodymium"
    },
    "Keys": {
      "Hanzi": 7942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7942,
      "Radical": "金",
      "FrequencyRank": 6592,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "攴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pū",
      "PrimaryDefinition": "rap"
    },
    "Keys": {
      "Radical": 66
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "人格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réngé",
      "PrimaryDefinition": "lot, fibre, fiber, character, spirit, selfhood, manhood, personality"
    },
    "Keys": {
      "Word": 8839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相继": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngjì",
      "PrimaryDefinition": "about, one_after_another, one after another, in succession"
    },
    "Keys": {
      "Word": 9925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "习惯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíguàn",
      "PrimaryDefinition": "habit, custom"
    },
    "Keys": {
      "Word": 1075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "谲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "cunning, crafty, sly, wily"
    },
    "Keys": {
      "Hanzi": 5826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5826,
      "Radical": "言",
      "FrequencyRank": 4819,
      "RadicalIndex": "149.12",
      "StrokeCount": 14
    }
  },
  "萼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "the stem and calyx of a flower; a younger brother"
    },
    "Keys": {
      "Hanzi": 5086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5086,
      "Radical": "艸",
      "FrequencyRank": 4072,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "媆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎn",
      "PrimaryDefinition": "young, tender, soft, delicate"
    },
    "Keys": {
      "Hanzi": 7448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7448,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "害臊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài sào",
      "PrimaryDefinition": "to be bashful, to feel ashamed"
    },
    "Keys": {
      "Word": 6976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "kill, slay; wound, injure, hurt"
    },
    "Keys": {
      "Hanzi": 4060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4060,
      "Radical": "戈",
      "FrequencyRank": 4825,
      "RadicalIndex": "62.4",
      "StrokeCount": 8
    }
  },
  "免除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎnchú",
      "PrimaryDefinition": "to prevent, to avoid, to excuse, to exempt, to relieve, (of a debt) to remit"
    },
    "Keys": {
      "Word": 8277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "眊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "dim-sighted; dull, bewildered"
    },
    "Keys": {
      "Hanzi": 4193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4193,
      "Radical": "目",
      "FrequencyRank": 6854,
      "RadicalIndex": "109.4",
      "StrokeCount": 9
    }
  },
  "榨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "to press or extract juices; a press to extract juices; a vegetable"
    },
    "Keys": {
      "Hanzi": 3082,
      "Word": 10630
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "Grade": 7,
      "GeneralStandard": 3082,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 3657,
      "StrokeCount": 14
    }
  },
  "流失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúshī",
      "PrimaryDefinition": "run off, be washed away"
    },
    "Keys": {
      "Word": 8104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lún",
      "PrimaryDefinition": "wheel; revolve, turn; recur"
    },
    "Keys": {
      "Hanzi": 1061,
      "Word": 2718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.4",
      "Grade": 4,
      "GeneralStandard": 1061,
      "Radical": "車",
      "HSK": 4,
      "FrequencyRank": 1096,
      "StrokeCount": 8
    }
  },
  "加油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā yóu",
      "PrimaryDefinition": "gas, lubricate, oil, refuel, gas up, embolden, accelerate, lubrication, fuel, cheer, recreate, hearten, make an extra effort"
    },
    "Keys": {
      "Word": 750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "菅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "coarse grass, themedia forskali"
    },
    "Keys": {
      "Hanzi": 4765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4765,
      "Radical": "艸",
      "FrequencyRank": 5235,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "搭档": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dādàng",
      "PrimaryDefinition": "to cooperate, partner"
    },
    "Keys": {
      "Word": 4465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "栈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "warehouse; tavern, inn"
    },
    "Keys": {
      "Hanzi": 1425
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1425,
      "Radical": "木",
      "FrequencyRank": 3192,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "怕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pà",
      "PrimaryDefinition": "to fear, be afraid of; apprehensive"
    },
    "Keys": {
      "Hanzi": 1269,
      "Word": 1777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "Grade": 3,
      "GeneralStandard": 1269,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 631,
      "StrokeCount": 8
    }
  },
  "洵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "true, real, truly, really"
    },
    "Keys": {
      "Hanzi": 4344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4344,
      "Radical": "水",
      "FrequencyRank": 4163,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "壅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "to obstruct"
    },
    "Keys": {
      "Hanzi": 6165
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6165,
      "Radical": "土",
      "FrequencyRank": 5055,
      "RadicalIndex": "32.13",
      "StrokeCount": 16
    }
  },
  "贱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "mean, low; cheap, worthless"
    },
    "Keys": {
      "Hanzi": 1520,
      "Word": 7433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "Grade": 7,
      "GeneralStandard": 1520,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 2589,
      "StrokeCount": 9
    }
  },
  "银幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínmù",
      "PrimaryDefinition": "movie screen"
    },
    "Keys": {
      "Word": 10386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "早餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎocān",
      "PrimaryDefinition": "breaker, petit dejeuner, brekker, dejeuner, breakfast, morning tea"
    },
    "Keys": {
      "Word": 1195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "朝夕相处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāoxīxiāngchǔ",
      "PrimaryDefinition": "Get along the eve"
    },
    "Keys": {
      "Word": 10669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rào",
      "PrimaryDefinition": "entwine; wind around; surround"
    },
    "Keys": {
      "Hanzi": 1755,
      "Word": 3842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "Grade": 5,
      "GeneralStandard": 1755,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 1485,
      "StrokeCount": 9
    }
  },
  "动感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dònggǎn",
      "PrimaryDefinition": "innervation, dynamism"
    },
    "Keys": {
      "Word": 6332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒng",
      "PrimaryDefinition": "heel; follow; visit, call on"
    },
    "Keys": {
      "Hanzi": 6093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6093,
      "Radical": "足",
      "FrequencyRank": 3695,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "显示器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnshìqì",
      "PrimaryDefinition": "monitor, display, caller ID, indicator"
    },
    "Keys": {
      "Word": 9896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "结晶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéjīng",
      "PrimaryDefinition": "to crystallize, crystallization, crystal, crystalline, (fig.) the fruit (of labor etc)"
    },
    "Keys": {
      "Word": 7526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "青年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngnián",
      "PrimaryDefinition": "young people, adolescent, youngster, springal, boy, youth, youths, stripling, young, hobbledehoy, younker"
    },
    "Keys": {
      "Word": 931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "机动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīdòng",
      "PrimaryDefinition": "locomotive, motorized, power-driven, adaptable, flexible (use, treatment, timing etc)"
    },
    "Keys": {
      "Word": 7265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "漪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "ripples on water; swirling"
    },
    "Keys": {
      "Hanzi": 5807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5807,
      "Radical": "水",
      "FrequencyRank": 3676,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "适量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìliàng",
      "PrimaryDefinition": "appropriate amount"
    },
    "Keys": {
      "Word": 9161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "sturgeon"
    },
    "Keys": {
      "Hanzi": 6281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6281,
      "Radical": "魚",
      "FrequencyRank": 5990,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "众志成城": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngzhì-chéngchéng",
      "PrimaryDefinition": "unity of will is an impregnable stronghold (idiom)"
    },
    "Keys": {
      "Word": 10828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榜样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎngyàng",
      "PrimaryDefinition": "example, model, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 5508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "to hibernate"
    },
    "Keys": {
      "Hanzi": 5073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5073,
      "Radical": "虫",
      "FrequencyRank": 4177,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "闵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "mourn, grieve; urge on, incite"
    },
    "Keys": {
      "Hanzi": 3763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3763,
      "Radical": "門",
      "FrequencyRank": 3643,
      "RadicalIndex": "169.4",
      "StrokeCount": 7
    }
  },
  "徭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "conscript labor, compulsory labor"
    },
    "Keys": {
      "Hanzi": 5508
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5508,
      "Radical": "彳",
      "FrequencyRank": 4959,
      "RadicalIndex": "60.1",
      "StrokeCount": 13
    }
  },
  "马上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎshàng",
      "PrimaryDefinition": "in a short time, at_once, instantaneously, promptly, at once, incontinent, in the same breath, directively, instantly, slapbang, pronto, right_away, immediately, shortly, without delay, right away, tout-de-suite, forthwith, presently, in_no_time, at a time, soon, hereupon, in a few seconds, before long, at one time, outright, straightaway, in a minute, directly, momently, in a f"
    },
    "Keys": {
      "Word": 223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "漕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáo",
      "PrimaryDefinition": "transport by water; canal transportation"
    },
    "Keys": {
      "Hanzi": 5801
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5801,
      "Radical": "水",
      "FrequencyRank": 4375,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "遗传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíchuán",
      "PrimaryDefinition": "bequeath, pass on to next generation"
    },
    "Keys": {
      "Word": 3094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "揖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "salute, bow; defer to, yield"
    },
    "Keys": {
      "Hanzi": 5066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5066,
      "Radical": "手",
      "FrequencyRank": 3505,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "主页": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔyè",
      "PrimaryDefinition": "home page"
    },
    "Keys": {
      "Word": 10858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "困扰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùnrǎo",
      "PrimaryDefinition": "perplexity, puzzle"
    },
    "Keys": {
      "Word": 3674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "逵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "thoroughfare, crossroads"
    },
    "Keys": {
      "Hanzi": 4727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4727,
      "Radical": "辵",
      "FrequencyRank": 3778,
      "RadicalIndex": "162.8",
      "StrokeCount": 11
    }
  },
  "无情无义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúqíngwúyì",
      "PrimaryDefinition": "completely lacking any feeling or sense of justice (idiom), cold and ruthless"
    },
    "Keys": {
      "Word": 9780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòchéng",
      "PrimaryDefinition": "unconscious process, mechanism, process, act, round, course"
    },
    "Keys": {
      "Word": 1535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "真相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnxiàng",
      "PrimaryDefinition": "fact, bottom, low-down, the real facts, dinkum, truth, real/true situation, size, score, real, lowdown, real situation, the real facts/truth, verity, the actual state of affairs, true situation, the real truth, the real situation"
    },
    "Keys": {
      "Word": 4201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "病毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngdú",
      "PrimaryDefinition": "worm, viral, virus"
    },
    "Keys": {
      "Word": 3261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "穿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuān",
      "PrimaryDefinition": "penetrate, pierce, drill; wear"
    },
    "Keys": {
      "Hanzi": 1701,
      "Word": 53
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.4",
      "Grade": 1,
      "GeneralStandard": 1701,
      "Radical": "穴",
      "HSK": 1,
      "FrequencyRank": 785,
      "StrokeCount": 9
    }
  },
  "凭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "lean on, depend on, rely on"
    },
    "Keys": {
      "Hanzi": 1148,
      "Word": 3783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "16.6",
      "Grade": 5,
      "GeneralStandard": 1148,
      "Radical": "几",
      "HSK": 5,
      "FrequencyRank": 1410,
      "StrokeCount": 8
    }
  },
  "复原": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù yuán",
      "PrimaryDefinition": "to restore (sth) to (its) former condition, to recover from illness, recovery"
    },
    "Keys": {
      "Word": 6637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "劈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "cut apart, split, chop"
    },
    "Keys": {
      "Hanzi": 3311,
      "Word": 8504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.13",
      "Grade": 7,
      "GeneralStandard": 3311,
      "Radical": "刀",
      "HSK": 8,
      "FrequencyRank": 2487,
      "StrokeCount": 15
    }
  },
  "婌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7268,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "签证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānzhèng",
      "PrimaryDefinition": "visa"
    },
    "Keys": {
      "Word": 3799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "深思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnsī",
      "PrimaryDefinition": "ruminate, politic, chew, ponder deeply, consider carefully, speculate, bethink, rumination, chew the cud, perpend"
    },
    "Keys": {
      "Word": 9033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒufǎ",
      "PrimaryDefinition": "manoeuvering, artifice, manner, hanky-panky, legerdemain, tactical manoeuver, trick, tactical maneuver, maneuver, technique, play, tactic, manoeuvre, modus_operandi, prestidigitation, stroke, tact, skill, maneuvering, sleight, implement, touch, gimmick"
    },
    "Keys": {
      "Word": 3916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "谪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "charge, blame; disgrace; demote"
    },
    "Keys": {
      "Hanzi": 5603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5603,
      "Radical": "言",
      "FrequencyRank": 5115,
      "RadicalIndex": "149.11",
      "StrokeCount": 13
    }
  },
  "县": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "county, district, subdivision"
    },
    "Keys": {
      "Hanzi": 720,
      "Word": 3015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "28.5",
      "Grade": 4,
      "GeneralStandard": 720,
      "Radical": "厶",
      "HSK": 4,
      "FrequencyRank": 877,
      "StrokeCount": 7
    }
  },
  "犁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "plow"
    },
    "Keys": {
      "Hanzi": 2314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2314,
      "Radical": "牛",
      "FrequencyRank": 3176,
      "RadicalIndex": "93.7",
      "StrokeCount": 11
    }
  },
  "高低": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāodī",
      "PrimaryDefinition": "height, altitude (aviation), pitch (music), ups and downs (success or failure), whether sth is right or wrong, comparative strength, weight, depth, stature, (spoken interjection) anyway, whatever, eventually, in the end"
    },
    "Keys": {
      "Word": 6694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大街小巷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjiē-xiǎoxiàng",
      "PrimaryDefinition": "great streets and small alleys (idiom), everywhere in the city"
    },
    "Keys": {
      "Word": 6120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "name, rank, title, position"
    },
    "Keys": {
      "Hanzi": 505,
      "Word": 872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 2,
      "GeneralStandard": 505,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 203,
      "StrokeCount": 6
    }
  },
  "星期日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Xīngqīrì",
      "PrimaryDefinition": "Sunday, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "下落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàluò",
      "PrimaryDefinition": "whereabouts, to drop, to fall"
    },
    "Keys": {
      "Word": 9863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "性命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngmìng",
      "PrimaryDefinition": "life"
    },
    "Keys": {
      "Word": 10062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "be healed, cured, recover"
    },
    "Keys": {
      "Hanzi": 834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.2",
      "GeneralStandard": 834,
      "Radical": "疒",
      "HSK": 4,
      "FrequencyRank": 949,
      "StrokeCount": 7
    }
  },
  "侂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuō",
      "PrimaryDefinition": "to commission, to entrust to, to depute; to request, to ask"
    },
    "Keys": {
      "Hanzi": 6701
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6701,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "浠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4656,
      "Radical": "水",
      "FrequencyRank": 5125,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "高档": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāodàng",
      "PrimaryDefinition": "superior quality, high grade, top grade"
    },
    "Keys": {
      "Word": 4593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "强制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángzhì",
      "PrimaryDefinition": "to enforce, enforcement, forcibly, compulsory"
    },
    "Keys": {
      "Word": 8695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "废": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "abrogate, terminate, discard"
    },
    "Keys": {
      "Hanzi": 1220,
      "Word": 6522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.5",
      "Grade": 7,
      "GeneralStandard": 1220,
      "Radical": "广",
      "HSK": 6,
      "FrequencyRank": 1376,
      "StrokeCount": 8
    }
  },
  "目的地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùdìdì",
      "PrimaryDefinition": "bourn, goal, destination, terminus, bourne"
    },
    "Keys": {
      "Word": 8349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoxin",
      "PrimaryDefinition": "beware, watch_out, keep one's eyes skinned, keep one's eyes peeled, careful, cautious, be careful, see, watch, guard, ware, cautionary, keep one's eyes open, take care, look_out, look out"
    },
    "Keys": {
      "Word": 1094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "那里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàlǐ",
      "PrimaryDefinition": "that place"
    },
    "Keys": {
      "Word": 253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "紧张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnzhāng",
      "PrimaryDefinition": "be nervous"
    },
    "Keys": {
      "Word": 1630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "塥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7477
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7477,
      "Radical": "土",
      "FrequencyRank": 8676,
      "RadicalIndex": "32.1",
      "StrokeCount": 13
    }
  },
  "时空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíkōng",
      "PrimaryDefinition": "time and space, space-time"
    },
    "Keys": {
      "Word": 9110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "deep red"
    },
    "Keys": {
      "Hanzi": 7479
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7479,
      "Radical": "赤",
      "FrequencyRank": 8358,
      "RadicalIndex": "155.6",
      "StrokeCount": 13
    }
  },
  "歇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiē",
      "PrimaryDefinition": "rest, stop, lodge"
    },
    "Keys": {
      "Hanzi": 2908,
      "Word": 4057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "76.9",
      "Grade": 5,
      "GeneralStandard": 2908,
      "Radical": "欠",
      "HSK": 5,
      "FrequencyRank": 1828,
      "StrokeCount": 13
    }
  },
  "翃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "fly"
    },
    "Keys": {
      "Hanzi": 6974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6974,
      "Radical": "羽",
      "FrequencyRank": 7268,
      "RadicalIndex": "124.4",
      "StrokeCount": 10
    }
  },
  "姐姐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiějie",
      "PrimaryDefinition": "sis, big_sister, elder sister, sister"
    },
    "Keys": {
      "Word": 177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "血缘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuèyuán",
      "PrimaryDefinition": "bloodline"
    },
    "Keys": {
      "Word": 10143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā chū",
      "PrimaryDefinition": "voice, send_out, give_forth, send out, exhale, sound, fetch, pass off, shoot, effuse, issue, emanate, raise, give, shed, send/give out, utter, go, escape, proceed, send, give forth, give_off, give off, fall, eradiate, emit, flash, scintillate, issue_forth, give_out, give out, clank, reek, place, breathe"
    },
    "Keys": {
      "Word": 1429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "香烟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngyān",
      "PrimaryDefinition": "cigarette, incense smoke"
    },
    "Keys": {
      "Word": 9935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pū",
      "PrimaryDefinition": "fall forward; lie prostrate, prone; servant"
    },
    "Keys": {
      "Hanzi": 131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "GeneralStandard": 131,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 1812,
      "StrokeCount": 4
    }
  },
  "傥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎng",
      "PrimaryDefinition": "if, supposing, in case"
    },
    "Keys": {
      "Hanzi": 5212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5212,
      "Radical": "人",
      "FrequencyRank": 4829,
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒ",
      "PrimaryDefinition": "large rope, cable; rules, laws; to demand, to exact; to search, inquire; isolated"
    },
    "Keys": {
      "Hanzi": 1851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 1851,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 805,
      "StrokeCount": 10
    }
  },
  "艘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "counter for ships, vessels"
    },
    "Keys": {
      "Hanzi": 3273,
      "Word": 9339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "137.1",
      "Grade": 7,
      "GeneralStandard": 3273,
      "Radical": "舟",
      "HSK": 7,
      "FrequencyRank": 1470,
      "StrokeCount": 15
    }
  },
  "崎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "rough, uneven, jagged, rugged"
    },
    "Keys": {
      "Hanzi": 2293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2293,
      "Radical": "山",
      "FrequencyRank": 2751,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "泯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "destroy, eliminate; perish"
    },
    "Keys": {
      "Hanzi": 4022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4022,
      "Radical": "水",
      "FrequencyRank": 4320,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "自来水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìláishuǐ",
      "PrimaryDefinition": "running/tap water, running, tap_water, running water, tap water"
    },
    "Keys": {
      "Word": 5392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "漋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7731
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7731,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 14
    }
  },
  "谿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "valley, gorge; mountain stream"
    },
    "Keys": {
      "Hanzi": 7959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7959,
      "Radical": "谷",
      "FrequencyRank": 6347,
      "RadicalIndex": "150.1",
      "StrokeCount": 17
    }
  },
  "恢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "restore; big, great, immense, vast"
    },
    "Keys": {
      "Hanzi": 1686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1686,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1364,
      "StrokeCount": 9
    }
  },
  "布置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhì",
      "PrimaryDefinition": "dispose, embellish, arrange, set up, lay, assign, fix up, upholster"
    },
    "Keys": {
      "Word": 2281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "个子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèzi",
      "PrimaryDefinition": "height, stature, build"
    },
    "Keys": {
      "Word": 680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "堨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "daub"
    },
    "Keys": {
      "Hanzi": 7295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7295,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "寥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "few, scarce; empty, deserted"
    },
    "Keys": {
      "Hanzi": 3176
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.11",
      "GeneralStandard": 3176,
      "Radical": "宀",
      "HSK": 9,
      "FrequencyRank": 3116,
      "StrokeCount": 14
    }
  },
  "大面积": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàmiànjī",
      "PrimaryDefinition": "large area"
    },
    "Keys": {
      "Word": 6124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "the throat; to quarrel, choke"
    },
    "Keys": {
      "Hanzi": 5471
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5471,
      "Radical": "口",
      "FrequencyRank": 5391,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "兴建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngjiàn",
      "PrimaryDefinition": "build, construct"
    },
    "Keys": {
      "Word": 10039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "persimmon"
    },
    "Keys": {
      "Hanzi": 1436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1436,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 3355,
      "StrokeCount": 9
    }
  },
  "嫽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "play with; (Cant.) to provoke"
    },
    "Keys": {
      "Hanzi": 7829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7829,
      "Radical": "女",
      "RadicalIndex": "38.12",
      "StrokeCount": 15
    }
  },
  "毹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5512,
      "Radical": "毛",
      "FrequencyRank": 7996,
      "RadicalIndex": "82.9",
      "StrokeCount": 13
    }
  },
  "审定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěndìng",
      "PrimaryDefinition": "to examine and approve, to finalize"
    },
    "Keys": {
      "Word": 9040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发愁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā chóu",
      "PrimaryDefinition": "to worry, to fret, to be anxious, to become sad"
    },
    "Keys": {
      "Word": 6432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūsè",
      "PrimaryDefinition": "outstanding, remarkable, splendid"
    },
    "Keys": {
      "Word": 2334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "富裕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùyù",
      "PrimaryDefinition": "plentiful, abundant, rich"
    },
    "Keys": {
      "Word": 6644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狂热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuángrè",
      "PrimaryDefinition": "zealotry, fanatical, feverish"
    },
    "Keys": {
      "Word": 7888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "采矿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎi kuàng",
      "PrimaryDefinition": "mining"
    },
    "Keys": {
      "Word": 5744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "农村": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngcūn",
      "PrimaryDefinition": "country, village, countryside, rural area"
    },
    "Keys": {
      "Word": 1773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "秃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū",
      "PrimaryDefinition": "bald"
    },
    "Keys": {
      "Hanzi": 764,
      "Word": 9575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.2",
      "Grade": 7,
      "GeneralStandard": 764,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 2521,
      "StrokeCount": 7
    }
  },
  "碓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": "pestle"
    },
    "Keys": {
      "Hanzi": 5418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5418,
      "Radical": "石",
      "FrequencyRank": 5370,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "蟛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "a land-crab"
    },
    "Keys": {
      "Hanzi": 6335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6335,
      "Radical": "虫",
      "FrequencyRank": 5270,
      "RadicalIndex": "142.12",
      "StrokeCount": 18
    }
  },
  "取胜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔshèng",
      "PrimaryDefinition": "to score a victory, to prevail over one's opponents"
    },
    "Keys": {
      "Word": 8790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "endlessly long, boundless, vast"
    },
    "Keys": {
      "Hanzi": 2755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2755,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2966,
      "StrokeCount": 12
    }
  },
  "低下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīxià",
      "PrimaryDefinition": "low status, lowly, to lower (one's head)"
    },
    "Keys": {
      "Word": 6232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7327,
      "Radical": "鳥",
      "FrequencyRank": 7073,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "赂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "bribe; give present"
    },
    "Keys": {
      "Hanzi": 1926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "GeneralStandard": 1926,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 3270,
      "StrokeCount": 10
    }
  },
  "冬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōng",
      "PrimaryDefinition": "winter, 11th lunar month"
    },
    "Keys": {
      "Hanzi": 295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.3",
      "GeneralStandard": 295,
      "Radical": "冫",
      "HSK": 2,
      "FrequencyRank": 1384,
      "StrokeCount": 5
    }
  },
  "字迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìjì",
      "PrimaryDefinition": "handwriting"
    },
    "Keys": {
      "Word": 10975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "arrange in order; display"
    },
    "Keys": {
      "Hanzi": 4280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4280,
      "Radical": "肉",
      "FrequencyRank": 4734,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "甫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "begin; man, father; great; a distance of ten li"
    },
    "Keys": {
      "Hanzi": 691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 691,
      "Radical": "用",
      "FrequencyRank": 2152,
      "RadicalIndex": "101.2",
      "StrokeCount": 7
    }
  },
  "橹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": "oar, scull; row, scull"
    },
    "Keys": {
      "Hanzi": 6061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6061,
      "Radical": "木",
      "FrequencyRank": 4134,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "坑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēng",
      "PrimaryDefinition": "pit, hole; bury, trap; harry"
    },
    "Keys": {
      "Hanzi": 645,
      "Word": 7819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 7,
      "GeneralStandard": 645,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2242,
      "StrokeCount": 7
    }
  },
  "赌博": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔbó",
      "PrimaryDefinition": "gamble"
    },
    "Keys": {
      "Word": 4519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "创造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngzào",
      "PrimaryDefinition": "create, produce, bring about"
    },
    "Keys": {
      "Word": 1370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "去掉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùdiào",
      "PrimaryDefinition": "to get rid of, to exclude, to eliminate, to remove, to delete, to strip out, to extract"
    },
    "Keys": {
      "Word": 4956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piào",
      "PrimaryDefinition": "slip of paper or bamboo; ticket"
    },
    "Keys": {
      "Hanzi": 2235,
      "Word": 282
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.6",
      "Grade": 1,
      "GeneralStandard": 2235,
      "Radical": "示",
      "HSK": 1,
      "FrequencyRank": 910,
      "StrokeCount": 11
    }
  },
  "中级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngjí",
      "PrimaryDefinition": "middle level (in a hierarchy)"
    },
    "Keys": {
      "Word": 1222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "错误": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòwù",
      "PrimaryDefinition": "error, blunder"
    },
    "Keys": {
      "Word": 1380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "queen, empress, sovereign; (simp. for 後) behind, rear, after"
    },
    "Keys": {
      "Hanzi": 482,
      "Word": 145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 1,
      "GeneralStandard": 482,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 48,
      "StrokeCount": 6
    }
  },
  "凉快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángkuai",
      "PrimaryDefinition": "nice and cold, pleasantly cool"
    },
    "Keys": {
      "Word": 848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "末日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòrì",
      "PrimaryDefinition": "Judgment Day (in Christian eschatology), last day, end, final days, doomsday"
    },
    "Keys": {
      "Word": 8327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": "to reel"
    },
    "Keys": {
      "Hanzi": 6193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6193,
      "Radical": "糸",
      "FrequencyRank": 7772,
      "RadicalIndex": "120.13",
      "StrokeCount": 16
    }
  },
  "效力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàolì",
      "PrimaryDefinition": "effect"
    },
    "Keys": {
      "Word": 9985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàyī",
      "PrimaryDefinition": "overcoat, topcoat, cloak, CL:件[jian4]"
    },
    "Keys": {
      "Word": 594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "袭击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíjī",
      "PrimaryDefinition": "attack (esp. surprise attack), raid, to attack"
    },
    "Keys": {
      "Word": 9832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "才": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cái",
      "PrimaryDefinition": "talent, ability; just, only"
    },
    "Keys": {
      "Hanzi": 29,
      "Word": 2284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64",
      "Grade": 4,
      "GeneralStandard": 29,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 235,
      "StrokeCount": 3
    }
  },
  "书记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūji",
      "PrimaryDefinition": "scribe, penpusher, clerkship, amanuensis, secretarial assistant, pencil pusher, actuary, recorder, secretary, secretariat, clerk, scrivener"
    },
    "Keys": {
      "Word": 9208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嚓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cā",
      "PrimaryDefinition": "a cracking or snapping sound"
    },
    "Keys": {
      "Hanzi": 6236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6236,
      "Radical": "口",
      "FrequencyRank": 3600,
      "RadicalIndex": "30.14",
      "StrokeCount": 17
    }
  },
  "嫖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piáo",
      "PrimaryDefinition": "patronize prostitutes, frequent"
    },
    "Keys": {
      "Hanzi": 5832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5832,
      "Radical": "女",
      "FrequencyRank": 3463,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "仲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòng",
      "PrimaryDefinition": "middle brother; go between, mediator; surname"
    },
    "Keys": {
      "Hanzi": 465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 465,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 1706,
      "StrokeCount": 6
    }
  },
  "螟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "kind of caterpillar, larva"
    },
    "Keys": {
      "Hanzi": 6103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6103,
      "Radical": "虫",
      "FrequencyRank": 5188,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "迷惑不解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míhuòbùjiě",
      "PrimaryDefinition": "to feel puzzled"
    },
    "Keys": {
      "Word": 8256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "baboon"
    },
    "Keys": {
      "Hanzi": 3988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3988,
      "Radical": "犬",
      "FrequencyRank": 3281,
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "棓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "hit, strike"
    },
    "Keys": {
      "Hanzi": 7329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7329,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "不知不觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhī-bùjué",
      "PrimaryDefinition": "unconsciously, unaware"
    },
    "Keys": {
      "Word": 5724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "网": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "net"
    },
    "Keys": {
      "Hanzi": 442,
      "Radical": 122,
      "Word": 1054
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "122",
      "Grade": 2,
      "GeneralStandard": 442,
      "Radical": "网",
      "HSK": 1,
      "FrequencyRank": 605,
      "StrokeCount": 6
    }
  },
  "劝告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quàngào",
      "PrimaryDefinition": "to advise, to urge, to exhort, exhortation, advice, CL:席[xi2]"
    },
    "Keys": {
      "Word": 8810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "a jade table conferred upon feudal princes by the emperor as a symbol of dignity and authority"
    },
    "Keys": {
      "Hanzi": 6926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6926,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "鸦雀无声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāquè-wúshēng",
      "PrimaryDefinition": "lit. crow and peacock make no sound, absolute silence (idiom), not a single voice can be heard, absolute silence"
    },
    "Keys": {
      "Word": 10161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "近日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnrì",
      "PrimaryDefinition": "in the past few days, recently, in the last few days"
    },
    "Keys": {
      "Word": 4744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "要不然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàoburán",
      "PrimaryDefinition": "conj.: otherwise, or else, or"
    },
    "Keys": {
      "Word": 5222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐn",
      "PrimaryDefinition": "article, product, commodity"
    },
    "Keys": {
      "Hanzi": 1503,
      "Word": 3776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 5,
      "GeneralStandard": 1503,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 308,
      "StrokeCount": 9
    }
  },
  "适宜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyí",
      "PrimaryDefinition": "suitable, appropriate"
    },
    "Keys": {
      "Word": 9158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "针": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "needle; pin; tack; acupuncture"
    },
    "Keys": {
      "Hanzi": 757,
      "Word": 3160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.2",
      "Grade": 4,
      "GeneralStandard": 757,
      "Radical": "金",
      "HSK": 4,
      "FrequencyRank": 1116,
      "StrokeCount": 7
    }
  },
  "厉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "whetstone; grind, sharpen; whet"
    },
    "Keys": {
      "Hanzi": 215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.3",
      "GeneralStandard": 215,
      "Radical": "厂",
      "HSK": 5,
      "FrequencyRank": 1339,
      "StrokeCount": 5
    }
  },
  "棣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "kerria japonica plant, cherry"
    },
    "Keys": {
      "Hanzi": 5104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5104,
      "Radical": "木",
      "FrequencyRank": 4213,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "自私": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìsī",
      "PrimaryDefinition": "selfish, self-centered"
    },
    "Keys": {
      "Word": 10982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "in disorder, scattered, tangled"
    },
    "Keys": {
      "Hanzi": 937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 937,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 1181,
      "StrokeCount": 7
    }
  },
  "中餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngcān",
      "PrimaryDefinition": "Chinese meal, Chinese food, CL:份[fen4],頓|顿[dun4]"
    },
    "Keys": {
      "Word": 1226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "象征": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngzhēng",
      "PrimaryDefinition": "symbol, emblem, token"
    },
    "Keys": {
      "Word": 4048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "后遗症": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuyízhèng",
      "PrimaryDefinition": "aftereffect, hangover, sequela, sequelae"
    },
    "Keys": {
      "Word": 7100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韵味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnwèi",
      "PrimaryDefinition": "implicit charm in rhyme or sound, hinted appeal, interest"
    },
    "Keys": {
      "Word": 10578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蟾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "toad"
    },
    "Keys": {
      "Hanzi": 6392
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6392,
      "Radical": "虫",
      "FrequencyRank": 3934,
      "RadicalIndex": "142.13",
      "StrokeCount": 19
    }
  },
  "寓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "residence; lodge; dwell"
    },
    "Keys": {
      "Hanzi": 2782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.9",
      "GeneralStandard": 2782,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 2199,
      "StrokeCount": 12
    }
  },
  "什么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénme",
      "PrimaryDefinition": "anything, what"
    },
    "Keys": {
      "Word": 351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "入场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù chǎng",
      "PrimaryDefinition": "to enter the venue for a meeting, to enter into an examination, to enter a stadium, arena etc"
    },
    "Keys": {
      "Word": 8900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngyíng",
      "PrimaryDefinition": "manage, run, engage in"
    },
    "Keys": {
      "Word": 1643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "编写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānxiě",
      "PrimaryDefinition": "to compile"
    },
    "Keys": {
      "Word": 5600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "general name for the hornless deer; to collect to band together"
    },
    "Keys": {
      "Hanzi": 6162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6162,
      "Radical": "鹿",
      "FrequencyRank": 5947,
      "RadicalIndex": "198.5",
      "StrokeCount": 16
    }
  },
  "变形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn xíng",
      "PrimaryDefinition": "deformation, to become deformed, to change shape, to morph"
    },
    "Keys": {
      "Word": 4332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "圆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "circle; round, circular; complete"
    },
    "Keys": {
      "Hanzi": 1922,
      "Word": 3132
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.7",
      "Grade": 4,
      "GeneralStandard": 1922,
      "Radical": "囗",
      "HSK": 4,
      "FrequencyRank": 1145,
      "StrokeCount": 10
    }
  },
  "胭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "rouge, cosmetics"
    },
    "Keys": {
      "Hanzi": 4592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4592,
      "Radical": "肉",
      "FrequencyRank": 4247,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "芃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "luxuriant growth"
    },
    "Keys": {
      "Hanzi": 6526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6526,
      "Radical": "艸",
      "FrequencyRank": 7971,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "锚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "anchor"
    },
    "Keys": {
      "Hanzi": 2937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2937,
      "Radical": "金",
      "FrequencyRank": 3500,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "卷入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juǎnrù",
      "PrimaryDefinition": "be drawn into, be involved in"
    },
    "Keys": {
      "Word": 7714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "材料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáiliào",
      "PrimaryDefinition": "making, aggregate, stuff, ingredients, food, ingredient, data, fodder, material, datum, stock, makings, data point, timber"
    },
    "Keys": {
      "Word": 2286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "泡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pào",
      "PrimaryDefinition": "bubbles, suds; blister; soak"
    },
    "Keys": {
      "Hanzi": 1251,
      "Word": 4898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 6,
      "GeneralStandard": 1251,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 2000,
      "StrokeCount": 8
    }
  },
  "球拍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúpāi",
      "PrimaryDefinition": "racket"
    },
    "Keys": {
      "Word": 4949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "超标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo biāo",
      "PrimaryDefinition": "to cross the limit, to be over the accepted norm, excessive"
    },
    "Keys": {
      "Word": 5827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "温柔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnróu",
      "PrimaryDefinition": "gentle and soft"
    },
    "Keys": {
      "Word": 9726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陆地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùdì",
      "PrimaryDefinition": "dry land (as opposed to the sea)"
    },
    "Keys": {
      "Word": 2715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "榄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "olive"
    },
    "Keys": {
      "Hanzi": 2866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2866,
      "Radical": "木",
      "FrequencyRank": 3607,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "栴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "sandalwood"
    },
    "Keys": {
      "Hanzi": 6962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6962,
      "Radical": "木",
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "顽皮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánpí",
      "PrimaryDefinition": "naughty"
    },
    "Keys": {
      "Word": 5127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "曛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "twilight; sunset"
    },
    "Keys": {
      "Hanzi": 6329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6329,
      "Radical": "日",
      "FrequencyRank": 5834,
      "RadicalIndex": "72.14",
      "StrokeCount": 18
    }
  },
  "煁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "hearth; (Cant.) soft, tender"
    },
    "Keys": {
      "Hanzi": 7581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7581,
      "Radical": "火",
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "迅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "quick, hasty, rapid, sudden"
    },
    "Keys": {
      "Hanzi": 565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.3",
      "GeneralStandard": 565,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 1090,
      "StrokeCount": 6
    }
  },
  "胺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "amine"
    },
    "Keys": {
      "Hanzi": 4597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4597,
      "Radical": "肉",
      "FrequencyRank": 3433,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "呜咽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūyè",
      "PrimaryDefinition": "to sob, to whimper"
    },
    "Keys": {
      "Word": 9757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "更是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèngshì",
      "PrimaryDefinition": "even more"
    },
    "Keys": {
      "Word": 4605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蘩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "Artemisia stellariana"
    },
    "Keys": {
      "Hanzi": 6422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6422,
      "Radical": "艸",
      "FrequencyRank": 5813,
      "RadicalIndex": "140.17",
      "StrokeCount": 20
    }
  },
  "祲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "ominous or sinister spirit; vigor"
    },
    "Keys": {
      "Hanzi": 7253
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7253,
      "Radical": "示",
      "FrequencyRank": 8320,
      "RadicalIndex": "113.7",
      "StrokeCount": 11
    }
  },
  "蟥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "leech"
    },
    "Keys": {
      "Hanzi": 6230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6230,
      "Radical": "虫",
      "FrequencyRank": 6026,
      "RadicalIndex": "142.12",
      "StrokeCount": 17
    }
  },
  "繁体字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fántǐzì",
      "PrimaryDefinition": "traditional Chinese character"
    },
    "Keys": {
      "Word": 6464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尾巴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěiba",
      "PrimaryDefinition": "appendage, a person shadowing sb., stern, tail-like part, servile adherent, tail"
    },
    "Keys": {
      "Word": 2974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "郈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "place in Shandong province"
    },
    "Keys": {
      "Hanzi": 6704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6704,
      "Radical": "邑",
      "FrequencyRank": 8017,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "眼镜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnjìng",
      "PrimaryDefinition": "cheater, lorgnon, spectacles, specs, glasses, spectacle, barnacles, eyeglasses, barnacle, eyeglass, glass"
    },
    "Keys": {
      "Word": 3071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "洞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "cave, grotto; ravine; hole"
    },
    "Keys": {
      "Hanzi": 1669,
      "Word": 3430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 5,
      "GeneralStandard": 1669,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1015,
      "StrokeCount": 9
    }
  },
  "多云": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōyún",
      "PrimaryDefinition": "cloudy (meteorology)"
    },
    "Keys": {
      "Word": 646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "柜台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guìtái",
      "PrimaryDefinition": "sales counter, front desk, bar, (of markets, medicines etc) OTC (over-the-counter), variant of 櫃檯|柜台[gui4 tai2]"
    },
    "Keys": {
      "Word": 6921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùchéng",
      "PrimaryDefinition": "won't do, unable to, (at the end of a rhetorical question) can that be?"
    },
    "Keys": {
      "Word": 4353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "历经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìjīng",
      "PrimaryDefinition": "have experienced"
    },
    "Keys": {
      "Word": 7997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "procedure; gallop; sudden(ly)"
    },
    "Keys": {
      "Hanzi": 3436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.14",
      "GeneralStandard": 3436,
      "Radical": "馬",
      "HSK": 8,
      "FrequencyRank": 2045,
      "StrokeCount": 17
    }
  },
  "性别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngbié",
      "PrimaryDefinition": "sexuality, grammatical gender, sexual distinction, sex, gender"
    },
    "Keys": {
      "Word": 2064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "认真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènzhēn",
      "PrimaryDefinition": "serious, earnest, take for real"
    },
    "Keys": {
      "Word": 303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "性能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngnéng",
      "PrimaryDefinition": "operation, function (of machine, functioning, natural capacity, etc.), capability, behavior, capacity, performance, function, funtion, property, function (of machine/etc.)"
    },
    "Keys": {
      "Word": 4069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "匿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "hide; go into hiding"
    },
    "Keys": {
      "Hanzi": 1776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "23.9",
      "GeneralStandard": 1776,
      "Radical": "匸",
      "HSK": 7,
      "FrequencyRank": 2804,
      "StrokeCount": 10
    }
  },
  "一举": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījǔ",
      "PrimaryDefinition": "a move, an action, in one move, at a stroke, in one go"
    },
    "Keys": {
      "Word": 10329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沙子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāzi",
      "PrimaryDefinition": "sand, grit, CL:粒[li4],把[ba3]"
    },
    "Keys": {
      "Word": 1872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "自强不息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìqiáng-bùxī",
      "PrimaryDefinition": "to strive unremittingly, self-improvement"
    },
    "Keys": {
      "Word": 10958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "取而代之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔérdàizhī",
      "PrimaryDefinition": "to substitute for sb, to remove and replace"
    },
    "Keys": {
      "Word": 8784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoliàn",
      "PrimaryDefinition": "training, drillmaster, wise man, tutor, drilling, handler, coacher, coach, instructor, private instructor, trainer, mentor"
    },
    "Keys": {
      "Word": 1613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "锩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juǎn",
      "PrimaryDefinition": "to bend iron"
    },
    "Keys": {
      "Hanzi": 5490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5490,
      "Radical": "金",
      "FrequencyRank": 5553,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "犇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēn",
      "PrimaryDefinition": "run fast, flee; rush about; run"
    },
    "Keys": {
      "Hanzi": 7384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7384,
      "Radical": "牛",
      "RadicalIndex": "93.8",
      "StrokeCount": 12
    }
  },
  "发火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā huǒ",
      "PrimaryDefinition": "to catch fire, to ignite, to detonate, to get angry"
    },
    "Keys": {
      "Word": 6439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làng",
      "PrimaryDefinition": "herb, Scopolia japonica"
    },
    "Keys": {
      "Hanzi": 4444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4444,
      "Radical": "艸",
      "FrequencyRank": 6154,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "斧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "axe, hatchet; chop, hew"
    },
    "Keys": {
      "Hanzi": 1170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "69.4",
      "GeneralStandard": 1170,
      "Radical": "斤",
      "HSK": 8,
      "FrequencyRank": 2771,
      "StrokeCount": 8
    }
  },
  "化纤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàxiān",
      "PrimaryDefinition": "synthetic fiber"
    },
    "Keys": {
      "Word": 7141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "计算机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìsuànjī",
      "PrimaryDefinition": "computer, (Tw) calculator, CL:臺|台[tai2]"
    },
    "Keys": {
      "Word": 748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "止步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ bù",
      "PrimaryDefinition": "to halt, to stop, to go no farther"
    },
    "Keys": {
      "Word": 10767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sū",
      "PrimaryDefinition": "butter; flaky, crispy, light, fluffy"
    },
    "Keys": {
      "Hanzi": 2586,
      "Word": 9341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.5",
      "Grade": 7,
      "GeneralStandard": 2586,
      "Radical": "酉",
      "HSK": 8,
      "FrequencyRank": 3516,
      "StrokeCount": 12
    }
  },
  "岷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": "min mountain, min river"
    },
    "Keys": {
      "Hanzi": 3932
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3932,
      "Radical": "山",
      "FrequencyRank": 4693,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "落下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luòxià",
      "PrimaryDefinition": "fall/lag behind"
    },
    "Keys": {
      "Word": 8173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一揽子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlǎnzi",
      "PrimaryDefinition": "all-inclusive, undiscriminating"
    },
    "Keys": {
      "Word": 10333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胶水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoshuǐ",
      "PrimaryDefinition": "glue"
    },
    "Keys": {
      "Word": 3613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "搔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāo",
      "PrimaryDefinition": "to scratch"
    },
    "Keys": {
      "Hanzi": 2539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2539,
      "Radical": "手",
      "FrequencyRank": 3454,
      "RadicalIndex": "64.1",
      "StrokeCount": 12
    }
  },
  "骄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "spirited horse; haughty"
    },
    "Keys": {
      "Hanzi": 1756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.6",
      "GeneralStandard": 1756,
      "Radical": "馬",
      "HSK": 6,
      "FrequencyRank": 2248,
      "StrokeCount": 9
    }
  },
  "谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "talk; conversation; surname"
    },
    "Keys": {
      "Hanzi": 2127,
      "Word": 1951
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "Grade": 3,
      "GeneralStandard": 2127,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 474,
      "StrokeCount": 10
    }
  },
  "钻空子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuān kòngzi",
      "PrimaryDefinition": "lit. to drill a hole, to take advantage of a loophole, to exploit an advantage, to seize the opportunity (esp. to do sth bad)"
    },
    "Keys": {
      "Word": 11012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūlù",
      "PrimaryDefinition": "way out, outlet, employment opportunities"
    },
    "Keys": {
      "Word": 4427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蒋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng",
      "PrimaryDefinition": "surname; hydropyrum latifalium"
    },
    "Keys": {
      "Hanzi": 2555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2555,
      "Radical": "艸",
      "FrequencyRank": 1172,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "石油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíyóu",
      "PrimaryDefinition": "crude, rock oil, crude oil, oil, coal oil, fossil_oil, fossil oil, petroleum"
    },
    "Keys": {
      "Word": 1894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "将来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānglái",
      "PrimaryDefinition": "in the future, future, the future, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "漫长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màncháng",
      "PrimaryDefinition": "very long, endless"
    },
    "Keys": {
      "Word": 3710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "手套": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒutào",
      "PrimaryDefinition": "glove, mitten, CL:雙|双[shuang1],隻|只[zhi1]"
    },
    "Keys": {
      "Word": 2881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "相连": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānglián",
      "PrimaryDefinition": "communicate, be joined, interconnect, be linked together, intercommunicate, interlink, meet, attach"
    },
    "Keys": {
      "Word": 9924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "grain offered in ritual sacrifice; millet"
    },
    "Keys": {
      "Hanzi": 7418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7418,
      "Radical": "米",
      "FrequencyRank": 5749,
      "RadicalIndex": "119.6",
      "StrokeCount": 12
    }
  },
  "解放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěfàng",
      "PrimaryDefinition": "liberate, emancipate"
    },
    "Keys": {
      "Word": 3619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "糊涂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hútu",
      "PrimaryDefinition": "muddled, silly, confused"
    },
    "Keys": {
      "Word": 7119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "查出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháchū",
      "PrimaryDefinition": "find, look_up, smell, investigate, find_out, find out, smoke_out, detect, track_down, smoke out, examine"
    },
    "Keys": {
      "Word": 4382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "膨胀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péngzhàng",
      "PrimaryDefinition": "expand, swell, inflate"
    },
    "Keys": {
      "Word": 8495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "a whetstone; to polish"
    },
    "Keys": {
      "Hanzi": 4480
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4480,
      "Radical": "石",
      "FrequencyRank": 4166,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "做梦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò mèng",
      "PrimaryDefinition": "to dream, to have a dream, fig. illusion, fantasy, pipe dream"
    },
    "Keys": {
      "Word": 3220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "迎合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínghé",
      "PrimaryDefinition": "to cater to, to pander to"
    },
    "Keys": {
      "Word": 10414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "throat; pharynx"
    },
    "Keys": {
      "Hanzi": 1504,
      "Word": 10209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 7,
      "GeneralStandard": 1504,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2031,
      "StrokeCount": 9
    }
  },
  "没意思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi yìsi",
      "PrimaryDefinition": "boring, of no interest"
    },
    "Keys": {
      "Word": 8222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐ",
      "PrimaryDefinition": "rice"
    },
    "Keys": {
      "Hanzi": 533,
      "Radical": 119,
      "Word": 1741
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119",
      "Grade": 3,
      "GeneralStandard": 533,
      "Radical": "米",
      "HSK": 1,
      "FrequencyRank": 575,
      "StrokeCount": 6
    }
  },
  "策略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèlüè",
      "PrimaryDefinition": "coup, resource, enginery, wile, stratagem, gambit, practice, plant, diplomacy, artifice, trap, twist, tactical manoeuver, policy, game, mechanism, game plan, ambush, chicanery, finesse, lying in wait, maneuvering, joker, tactics, gimmick, manoeuvering, plan, device, art, tactical maneuver, maneuver, measure, play, tactic, manoeuvre, mechanics, tack, ambuscade, trickery, strategy, setup, r"
    },
    "Keys": {
      "Word": 4379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "抚养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔyǎng",
      "PrimaryDefinition": "to foster, to bring up, to raise"
    },
    "Keys": {
      "Word": 6612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韧性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènxìng",
      "PrimaryDefinition": "toughness"
    },
    "Keys": {
      "Word": 8869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呼应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūyìng",
      "PrimaryDefinition": "to conform (with), to echo, to correlate well, (linguistics) agreement"
    },
    "Keys": {
      "Word": 7110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "环节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánjié",
      "PrimaryDefinition": "round segment, segment (of annelid worms), connection, link, sector, annular ring"
    },
    "Keys": {
      "Word": 3553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "罢了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàle",
      "PrimaryDefinition": "particle: elucidating a previous proposition so as to diminish its effect"
    },
    "Keys": {
      "Word": 4299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "阴暗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnàn",
      "PrimaryDefinition": "dim, dark, overcast, darkness, shadow, (fig.) dismal, gloomy, somber, murky, shadowy (side)"
    },
    "Keys": {
      "Word": 10381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "农作物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngzuòwù",
      "PrimaryDefinition": "(farm) crops"
    },
    "Keys": {
      "Word": 8438
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打搅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎjiǎo",
      "PrimaryDefinition": "to disturb, to trouble"
    },
    "Keys": {
      "Word": 6102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "skull"
    },
    "Keys": {
      "Hanzi": 2252
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2252,
      "Radical": "頁",
      "FrequencyRank": 2961,
      "RadicalIndex": "181.5",
      "StrokeCount": 11
    }
  },
  "牤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "māng",
      "PrimaryDefinition": "a bull"
    },
    "Keys": {
      "Hanzi": 3727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3727,
      "Radical": "牛",
      "RadicalIndex": "93.3",
      "StrokeCount": 7
    }
  },
  "消遣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāoqiǎn",
      "PrimaryDefinition": "to while the time away, amusement, pastime, recreation, to make sport of"
    },
    "Keys": {
      "Word": 9958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "形影不离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngyǐng-bùlí",
      "PrimaryDefinition": "inseparable (as form and shadow)"
    },
    "Keys": {
      "Word": 10050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下雪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàxuě",
      "PrimaryDefinition": "to snow"
    },
    "Keys": {
      "Word": 1085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "论述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùnshù",
      "PrimaryDefinition": "exposition and argumentation"
    },
    "Keys": {
      "Word": 8164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "extensive, full; fill; complete"
    },
    "Keys": {
      "Hanzi": 1313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "57.5",
      "GeneralStandard": 1313,
      "Radical": "弓",
      "HSK": 7,
      "FrequencyRank": 1785,
      "StrokeCount": 8
    }
  },
  "媲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "marry off, pair, match; compare"
    },
    "Keys": {
      "Hanzi": 5606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.1",
      "GeneralStandard": 5606,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 3911,
      "StrokeCount": 13
    }
  },
  "分化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnhuà",
      "PrimaryDefinition": "disintegrate"
    },
    "Keys": {
      "Word": 6537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嶅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7473
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7473,
      "Radical": "山",
      "RadicalIndex": "46.11",
      "StrokeCount": 13
    }
  },
  "刺绣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìxiù",
      "PrimaryDefinition": "to embroider, embroidery"
    },
    "Keys": {
      "Word": 6051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咱们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zánmen",
      "PrimaryDefinition": "we, you and I"
    },
    "Keys": {
      "Word": 1191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "丢失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diūshī",
      "PrimaryDefinition": "to lose, lost"
    },
    "Keys": {
      "Word": 6322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "总经理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngjīnglǐ",
      "PrimaryDefinition": "CEO, general_manager, chief executive officer, chief operating officer, executive, managing director, general manager, president"
    },
    "Keys": {
      "Word": 5401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "泌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "to seep out, excrete"
    },
    "Keys": {
      "Hanzi": 1256
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1256,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2609,
      "StrokeCount": 8
    }
  },
  "髑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "skull"
    },
    "Keys": {
      "Hanzi": 6472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6472,
      "Radical": "骨",
      "FrequencyRank": 5727,
      "RadicalIndex": "188.13",
      "StrokeCount": 22
    }
  },
  "关掉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāndiào",
      "PrimaryDefinition": "to switch off, to shut off"
    },
    "Keys": {
      "Word": 6865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā bìng",
      "PrimaryDefinition": "onset, outbreak (of a disease)"
    },
    "Keys": {
      "Word": 4536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "暹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "rise; advance, go forward"
    },
    "Keys": {
      "Hanzi": 5909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5909,
      "Radical": "日",
      "FrequencyRank": 4620,
      "RadicalIndex": "72.12",
      "StrokeCount": 15
    }
  },
  "镜子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngzi",
      "PrimaryDefinition": "looking_glass, gaper, looking glass, mirror, spectacles, glass, glasses"
    },
    "Keys": {
      "Word": 2641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "依法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīfǎ",
      "PrimaryDefinition": "in conformity with legal provisions, lawfully, in accordance with the law, wrongfully, legally, de jure, according to law"
    },
    "Keys": {
      "Word": 4099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "玭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3828,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "唑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "(chem.) azole"
    },
    "Keys": {
      "Hanzi": 4519
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4519,
      "Radical": "口",
      "FrequencyRank": 4349,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "补": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔ",
      "PrimaryDefinition": "mend, patch, fix, repair, restore"
    },
    "Keys": {
      "Hanzi": 888,
      "Word": 1316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.2",
      "Grade": 3,
      "GeneralStandard": 888,
      "Radical": "衣",
      "HSK": 3,
      "FrequencyRank": 944,
      "StrokeCount": 7
    }
  },
  "科普": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēpǔ",
      "PrimaryDefinition": "popular science, popularization of science, abbr. of 科學普及|科学普及"
    },
    "Keys": {
      "Word": 7789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铺路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pū lù",
      "PrimaryDefinition": "to pave (with paving stones), to lay a road, to give a present to sb to ensure success"
    },
    "Keys": {
      "Word": 8579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "事项": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìxiàng",
      "PrimaryDefinition": "issue, item, topic, subject, particular, transaction, matter"
    },
    "Keys": {
      "Word": 9141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "cricket, locust; anxious"
    },
    "Keys": {
      "Hanzi": 5072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5072,
      "Radical": "虫",
      "FrequencyRank": 5009,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "效益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoyì",
      "PrimaryDefinition": "beneficial result, benefit"
    },
    "Keys": {
      "Word": 9982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "关节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānjié",
      "PrimaryDefinition": "joint"
    },
    "Keys": {
      "Word": 6866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "税": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuì",
      "PrimaryDefinition": "taxes"
    },
    "Keys": {
      "Hanzi": 2679,
      "Word": 5044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.7",
      "Grade": 6,
      "GeneralStandard": 2679,
      "Radical": "禾",
      "HSK": 6,
      "FrequencyRank": 781,
      "StrokeCount": 12
    }
  },
  "北极": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běijí",
      "PrimaryDefinition": "the North Pole, the Arctic Pole, the north magnetic pole"
    },
    "Keys": {
      "Word": 3247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "shoes, footwear in general"
    },
    "Keys": {
      "Hanzi": 3207,
      "Word": 1103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "177.6",
      "Grade": 2,
      "GeneralStandard": 3207,
      "Radical": "革",
      "HSK": 2,
      "FrequencyRank": 1638,
      "StrokeCount": 15
    }
  },
  "吱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "chirping, squeaking, hissing"
    },
    "Keys": {
      "Hanzi": 723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 723,
      "Radical": "口",
      "FrequencyRank": 2805,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "扉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "door panel"
    },
    "Keys": {
      "Hanzi": 5304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5304,
      "Radical": "戶",
      "FrequencyRank": 4034,
      "RadicalIndex": "63.8",
      "StrokeCount": 12
    }
  },
  "斜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "slanting, sloping, inclined"
    },
    "Keys": {
      "Hanzi": 2347,
      "Word": 4059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "68.7",
      "Grade": 5,
      "GeneralStandard": 2347,
      "Radical": "斗",
      "HSK": 5,
      "FrequencyRank": 1786,
      "StrokeCount": 11
    }
  },
  "钩子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōuzi",
      "PrimaryDefinition": "hook"
    },
    "Keys": {
      "Word": 6812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "if, supposing; as if; like, as"
    },
    "Keys": {
      "Hanzi": 578,
      "Word": 4977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "Grade": 6,
      "GeneralStandard": 578,
      "Radical": "女",
      "HSK": 2,
      "FrequencyRank": 67,
      "StrokeCount": 6
    }
  },
  "老实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoshi",
      "PrimaryDefinition": "honest, frank, well-behaved, good, simple-minded, naive"
    },
    "Keys": {
      "Word": 2685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "青少年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngshàonián",
      "PrimaryDefinition": "adolescent, stilyaga, teens, young person, teenager, teenagers, young people and teenagers, youths, youth, stripling, hobbledehoy, youngsters, younker"
    },
    "Keys": {
      "Word": 929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "便于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànyú",
      "PrimaryDefinition": "easy to, convenient for"
    },
    "Keys": {
      "Word": 3259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "烺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎng",
      "PrimaryDefinition": "(said of fire) bright"
    },
    "Keys": {
      "Hanzi": 7231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7231,
      "Radical": "火",
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "糈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": "sacrificial rice; rations; pay"
    },
    "Keys": {
      "Hanzi": 7808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7808,
      "Radical": "米",
      "FrequencyRank": 7257,
      "RadicalIndex": "119.9",
      "StrokeCount": 15
    }
  },
  "铴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": "gong"
    },
    "Keys": {
      "Hanzi": 7186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7186,
      "Radical": "金",
      "RadicalIndex": "167.7",
      "StrokeCount": 11
    }
  },
  "不便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùbiàn",
      "PrimaryDefinition": "inconvenient, inappropriate"
    },
    "Keys": {
      "Word": 4345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "祖传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔchuán",
      "PrimaryDefinition": "passed on from ancestors, handed down from generation to generation"
    },
    "Keys": {
      "Word": 11008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "fall over; lie down; take turns"
    },
    "Keys": {
      "Hanzi": 1960,
      "Word": 608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 2,
      "GeneralStandard": 1960,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 608,
      "StrokeCount": 10
    }
  },
  "袖珍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiùzhēn",
      "PrimaryDefinition": "pocket-sized, pocket (book etc)"
    },
    "Keys": {
      "Word": 5195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "堠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "battlements, battlemented walls"
    },
    "Keys": {
      "Hanzi": 7297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7297,
      "Radical": "土",
      "FrequencyRank": 6986,
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "苦笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔxiào",
      "PrimaryDefinition": "to force a smile, a bitter laugh"
    },
    "Keys": {
      "Word": 7862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7769,
      "Radical": "日",
      "RadicalIndex": "72.11",
      "StrokeCount": 15
    }
  },
  "彻底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèdǐ",
      "PrimaryDefinition": "thorough, drastic, exhaustive, outright, complete"
    },
    "Keys": {
      "Word": 2307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鲏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7563,
      "Radical": "魚",
      "FrequencyRank": 6623,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "打招呼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ zhāohu",
      "PrimaryDefinition": "to greet sb by word or action, to give prior notice"
    },
    "Keys": {
      "Word": 6109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浮力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúlì",
      "PrimaryDefinition": "buoyancy"
    },
    "Keys": {
      "Word": 6605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "门诊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménzhěn",
      "PrimaryDefinition": "outpatient service"
    },
    "Keys": {
      "Word": 3719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "厂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "cliff"
    },
    "Keys": {
      "Hanzi": 6,
      "Radical": 27,
      "Word": 1336
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "27",
      "Grade": 3,
      "GeneralStandard": 6,
      "Radical": "厂",
      "HSK": 3,
      "FrequencyRank": 963,
      "StrokeCount": 2
    }
  },
  "看上去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànshangqu",
      "PrimaryDefinition": "it would appear, it seems (that)"
    },
    "Keys": {
      "Word": 1668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "按键": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànjiàn",
      "PrimaryDefinition": "button or key (on a device), keystroke, CL:個|个[ge4], to press a button"
    },
    "Keys": {
      "Word": 5441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俗话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "súhuà",
      "PrimaryDefinition": "common saying, proverb"
    },
    "Keys": {
      "Word": 9343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nài",
      "PrimaryDefinition": "crab-apple tree; endure, bear"
    },
    "Keys": {
      "Hanzi": 4139
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4139,
      "Radical": "木",
      "FrequencyRank": 6394,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "疡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "ulcers, sores; infection"
    },
    "Keys": {
      "Hanzi": 3999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3999,
      "Radical": "疒",
      "FrequencyRank": 3372,
      "RadicalIndex": "104.4",
      "StrokeCount": 8
    }
  },
  "拿出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náchū",
      "PrimaryDefinition": "ante, produce, take_out, get out, pose, take out, bring forth, take away, bring out"
    },
    "Keys": {
      "Word": 877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "闩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuān",
      "PrimaryDefinition": "bolt, latch, crossbar"
    },
    "Keys": {
      "Hanzi": 3522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3522,
      "Radical": "門",
      "FrequencyRank": 4110,
      "RadicalIndex": "169.1",
      "StrokeCount": 4
    }
  },
  "瞑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "close eyes"
    },
    "Keys": {
      "Hanzi": 5904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5904,
      "Radical": "目",
      "FrequencyRank": 4229,
      "RadicalIndex": "109.1",
      "StrokeCount": 15
    }
  },
  "猎犬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièquǎn",
      "PrimaryDefinition": "hound, hunting dog"
    },
    "Keys": {
      "Word": 8053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宣言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānyán",
      "PrimaryDefinition": "declaration, manifesto"
    },
    "Keys": {
      "Word": 10111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旺盛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngshèng",
      "PrimaryDefinition": "vigorous, prosperous, exuberant"
    },
    "Keys": {
      "Word": 9680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掠夺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüèduó",
      "PrimaryDefinition": "to plunder, to rob"
    },
    "Keys": {
      "Word": 8151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "milfoil, plant used in divination"
    },
    "Keys": {
      "Hanzi": 5369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5369,
      "Radical": "艸",
      "FrequencyRank": 6185,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "证据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngjù",
      "PrimaryDefinition": "support, telltale, testification, witness, case, testimonial, documentation, grounds, evidential, token, proof, earnest, testimony, cogent evidence, attestation, testament, evidence"
    },
    "Keys": {
      "Word": 2152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "航行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángxíng",
      "PrimaryDefinition": "sail, fly"
    },
    "Keys": {
      "Word": 6998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禁忌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnjì",
      "PrimaryDefinition": "tabu, vetanda, taboo, nono, contraindication"
    },
    "Keys": {
      "Word": 7588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黑心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēixīn",
      "PrimaryDefinition": "ruthless and lacking in conscience, vicious mind full of hatred and jealousy, black core (flaw in pottery)"
    },
    "Keys": {
      "Word": 7062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "听话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng huà",
      "PrimaryDefinition": "to do what one is told, obedient"
    },
    "Keys": {
      "Word": 9514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忍不住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěnbuzhù",
      "PrimaryDefinition": "unable to endure, can't help but do something, can't help but do sth., cannot help, unable to bear, can't bear"
    },
    "Keys": {
      "Word": 3841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "捐款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juānkuǎn",
      "PrimaryDefinition": "to donate money, to contribute funds, donation, contribution (of money)"
    },
    "Keys": {
      "Word": 4767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蹯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8035
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8035,
      "Radical": "足",
      "FrequencyRank": 6717,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "条款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáokuǎn",
      "PrimaryDefinition": "title, item, purview, detail, statute title, stipulation, proviso, point, article, provisions, provision, term, clause, condition"
    },
    "Keys": {
      "Word": 9496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "随心所欲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíxīnsuǒyù",
      "PrimaryDefinition": "to follow one's heart's desires, to do as one pleases (idiom)"
    },
    "Keys": {
      "Word": 9368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "实现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíxiàn",
      "PrimaryDefinition": "realize, achieve, bring about"
    },
    "Keys": {
      "Word": 977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "未免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèimiǎn",
      "PrimaryDefinition": "unavoidably, can't help, really, rather"
    },
    "Keys": {
      "Word": 9716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánr",
      "PrimaryDefinition": "to play, to have fun, to hang out"
    },
    "Keys": {
      "Word": 367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "深夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnyè",
      "PrimaryDefinition": "very late at night"
    },
    "Keys": {
      "Word": 9035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "to taste, to sip; to spit; the sound of sipping; to surprise"
    },
    "Keys": {
      "Hanzi": 4845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4845,
      "Radical": "口",
      "FrequencyRank": 4615,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "瘠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "thin, emaciated; barren"
    },
    "Keys": {
      "Hanzi": 5985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5985,
      "Radical": "疒",
      "FrequencyRank": 4003,
      "RadicalIndex": "104.1",
      "StrokeCount": 15
    }
  },
  "沟通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōutōng",
      "PrimaryDefinition": "communicate, link up"
    },
    "Keys": {
      "Word": 3506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "孤零零": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūlínglíng",
      "PrimaryDefinition": "lone, isolated and without help, all alone, solitary"
    },
    "Keys": {
      "Word": 6821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "respectful, polite, reverent"
    },
    "Keys": {
      "Hanzi": 1820
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1820,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1742,
      "StrokeCount": 10
    }
  },
  "抱怨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàoyuan",
      "PrimaryDefinition": "croak, kvetch, scold, quarrel, bitch, squeal, complain, bellyache, plain, grumble, whimper, natter, grizzle, repine, nark, complaint, sound off, nag, whine, quetch, grouch, kick, yammer, backbite, squawk, crab, beef, yawp, rail, rabbit, gripe, inveigh, grouse, moan, holler"
    },
    "Keys": {
      "Word": 3240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鄞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "county in Zhejiang province"
    },
    "Keys": {
      "Hanzi": 5370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5370,
      "Radical": "邑",
      "FrequencyRank": 5863,
      "RadicalIndex": "163.11",
      "StrokeCount": 13
    }
  },
  "豮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fén",
      "PrimaryDefinition": "castrate pig"
    },
    "Keys": {
      "Hanzi": 7853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7853,
      "Radical": "豕",
      "RadicalIndex": "152.9",
      "StrokeCount": 16
    }
  },
  "鲘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7704,
      "Radical": "",
      "RadicalIndex": "",
      "StrokeCount": 14
    }
  },
  "喜欢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐhuan",
      "PrimaryDefinition": "like, love, be fond of, be happy/elated"
    },
    "Keys": {
      "Word": 385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "危机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēijī",
      "PrimaryDefinition": "ambush, clutch, climacteric, crisis, precipice, hump, conjuncture, juncture, sharpness, gravity, edge"
    },
    "Keys": {
      "Word": 5136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "节俭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéjiǎn",
      "PrimaryDefinition": "frugal, economical"
    },
    "Keys": {
      "Word": 7518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūxi",
      "PrimaryDefinition": "to yield interest, profit etc, to exhale (Buddhism), future prospects, profit, to mature, to grow up"
    },
    "Keys": {
      "Word": 5971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赠送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zèngsòng",
      "PrimaryDefinition": "present, complimentary, ladle, give_away, present ... as a gift, proffer, gift, give, present as gift, donate, compliment, give ... as a present"
    },
    "Keys": {
      "Word": 4187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "摭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "pick up, gather"
    },
    "Keys": {
      "Hanzi": 5638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5638,
      "Radical": "手",
      "FrequencyRank": 5721,
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "谱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "list, table; musical score"
    },
    "Keys": {
      "Hanzi": 3181,
      "Word": 8584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.12",
      "Grade": 7,
      "GeneralStandard": 3181,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 2274,
      "StrokeCount": 14
    }
  },
  "䗴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8000,
      "Radical": "虫",
      "RadicalIndex": "142.13",
      "StrokeCount": 19
    }
  },
  "胴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "the large intestine; the body"
    },
    "Keys": {
      "Hanzi": 4591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4591,
      "Radical": "肉",
      "FrequencyRank": 4782,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "毙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "kill; die violent death"
    },
    "Keys": {
      "Hanzi": 1878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "81.6",
      "GeneralStandard": 1878,
      "Radical": "比",
      "HSK": 8,
      "FrequencyRank": 2534,
      "StrokeCount": 10
    }
  },
  "梳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "comb; brush"
    },
    "Keys": {
      "Hanzi": 2228,
      "Word": 9212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "Grade": 7,
      "GeneralStandard": 2228,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2717,
      "StrokeCount": 11
    }
  },
  "件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "numerary adjunct for article; matter"
    },
    "Keys": {
      "Hanzi": 466,
      "Word": 757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 2,
      "GeneralStandard": 466,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 250,
      "StrokeCount": 6
    }
  },
  "佘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shé",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 3737
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3737,
      "Radical": "人",
      "FrequencyRank": 4761,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "周围": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōuwéi",
      "PrimaryDefinition": "all around, circumference, circumambience, compass, ambit, periphery, circumambiency, round, surrounding, perimeter, environment, precinct, vicinity, circuit, ambience, around"
    },
    "Keys": {
      "Word": 2186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "冲洗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngxǐ",
      "PrimaryDefinition": "to rinse, to wash, to develop (photographic film)"
    },
    "Keys": {
      "Word": 5914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哭笑不得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūxiào-bùdé",
      "PrimaryDefinition": "lit. not to know whether to laugh or cry (idiom), both funny and extremely embarrassing, between laughter and tears"
    },
    "Keys": {
      "Word": 7857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "传奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánqí",
      "PrimaryDefinition": "legendry, romance, Tang and Song short stories, Ming and Qing poetic dramas, fable, short_story, legend"
    },
    "Keys": {
      "Word": 6006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "chronic disease"
    },
    "Keys": {
      "Hanzi": 5542
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5542,
      "Radical": "疒",
      "FrequencyRank": 5399,
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "闼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "door; gate"
    },
    "Keys": {
      "Hanzi": 4316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4316,
      "Radical": "門",
      "FrequencyRank": 4824,
      "RadicalIndex": "169.6",
      "StrokeCount": 9
    }
  },
  "大大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàda",
      "PrimaryDefinition": "greatly, enormously, (dialect) dad, uncle"
    },
    "Keys": {
      "Word": 586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "就": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "just, simply; to come, go to; to approach, near"
    },
    "Keys": {
      "Hanzi": 2728,
      "Word": 182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "43.9",
      "Grade": 1,
      "GeneralStandard": 2728,
      "Radical": "尢",
      "HSK": 1,
      "FrequencyRank": 27,
      "StrokeCount": 12
    }
  },
  "獠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "to hunt at night by torches"
    },
    "Keys": {
      "Hanzi": 5976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5976,
      "Radical": "犬",
      "FrequencyRank": 4625,
      "RadicalIndex": "94.12",
      "StrokeCount": 15
    }
  },
  "只好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐhǎo",
      "PrimaryDefinition": "can only, have no choice but, have to, be forced to"
    },
    "Keys": {
      "Word": 2166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "步骤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhòu",
      "PrimaryDefinition": "process, instance, demarche, method, measure, approach, procedure, place, step, move"
    },
    "Keys": {
      "Word": 5728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "避": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "avoid; turn aside; escape; hide"
    },
    "Keys": {
      "Hanzi": 3385,
      "Word": 2264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.13",
      "Grade": 4,
      "GeneralStandard": 3385,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 991,
      "StrokeCount": 16
    }
  },
  "网站": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngzhàn",
      "PrimaryDefinition": "website, network station, node"
    },
    "Keys": {
      "Word": 1057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "大海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎhǎi",
      "PrimaryDefinition": "seaway, sea, the open sea"
    },
    "Keys": {
      "Word": 589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "站": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "stand up; a stand, station"
    },
    "Keys": {
      "Hanzi": 2041,
      "Word": 1204
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.5",
      "Grade": 2,
      "GeneralStandard": 2041,
      "Radical": "立",
      "HSK": 1,
      "FrequencyRank": 544,
      "StrokeCount": 10
    }
  },
  "心目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnmù",
      "PrimaryDefinition": "mind, view"
    },
    "Keys": {
      "Word": 10010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "bamboo basket; bamboo frame"
    },
    "Keys": {
      "Hanzi": 6124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6124,
      "Radical": "竹",
      "FrequencyRank": 4336,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "席位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíwèi",
      "PrimaryDefinition": "a seat (in a theater, stadium etc), parliamentary or congressional seat"
    },
    "Keys": {
      "Word": 9831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxiàng",
      "PrimaryDefinition": "elephant"
    },
    "Keys": {
      "Word": 3386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "铸造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùzào",
      "PrimaryDefinition": "to cast (pour metal into a mold)"
    },
    "Keys": {
      "Word": 10873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热闹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènao",
      "PrimaryDefinition": "lively, buzzing with excitement"
    },
    "Keys": {
      "Word": 2823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "菂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7128,
      "Radical": "艸",
      "FrequencyRank": 7870,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "羡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "envy, admire; praise; covet"
    },
    "Keys": {
      "Hanzi": 2740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "123.6",
      "GeneralStandard": 2740,
      "Radical": "羊",
      "HSK": 7,
      "FrequencyRank": 2732,
      "StrokeCount": 12
    }
  },
  "窗户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuānghu",
      "PrimaryDefinition": "window, casement"
    },
    "Keys": {
      "Word": 2340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "河畔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hépàn",
      "PrimaryDefinition": "riverside, river plain"
    },
    "Keys": {
      "Word": 7044
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "事物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìwù",
      "PrimaryDefinition": "proposition, pigeon, object, thing, affair, un"
    },
    "Keys": {
      "Word": 2869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "寸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cùn",
      "PrimaryDefinition": "inch"
    },
    "Keys": {
      "Hanzi": 31,
      "Radical": 41,
      "Word": 3367
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "41",
      "Grade": 5,
      "GeneralStandard": 31,
      "Radical": "寸",
      "HSK": 4,
      "FrequencyRank": 1904,
      "StrokeCount": 3
    }
  },
  "哆嗦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōsuo",
      "PrimaryDefinition": "to tremble, to shiver, uncontrolled shaking of the body"
    },
    "Keys": {
      "Word": 6399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ bāo",
      "PrimaryDefinition": "to wrap, to pack, to put leftovers in a doggy bag for take-out"
    },
    "Keys": {
      "Word": 3375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "精髓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngsuǐ",
      "PrimaryDefinition": "marrow, pith, quintessence, essence"
    },
    "Keys": {
      "Word": 7620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "hesitate, falter, be undecided"
    },
    "Keys": {
      "Hanzi": 6332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6332,
      "Radical": "足",
      "FrequencyRank": 3322,
      "RadicalIndex": "157.13",
      "StrokeCount": 18
    }
  },
  "公安局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngānjú",
      "PrimaryDefinition": "public security bureau (government office similar in function to a police station)"
    },
    "Keys": {
      "Word": 6759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "iron, press"
    },
    "Keys": {
      "Hanzi": 6015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.11",
      "GeneralStandard": 6015,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 4498,
      "StrokeCount": 15
    }
  },
  "手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒu",
      "PrimaryDefinition": "hand"
    },
    "Keys": {
      "Hanzi": 121,
      "Radical": 64,
      "Word": 336
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64",
      "Grade": 1,
      "GeneralStandard": 121,
      "Radical": "手",
      "HSK": 1,
      "FrequencyRank": 143,
      "StrokeCount": 4
    }
  },
  "檑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7925,
      "Radical": "木",
      "FrequencyRank": 7574,
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "佤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎ",
      "PrimaryDefinition": "the Va (Wa) nationality, living in Yunnan"
    },
    "Keys": {
      "Hanzi": 3605
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3605,
      "Radical": "人",
      "FrequencyRank": 5502,
      "RadicalIndex": "9.5",
      "StrokeCount": 6
    }
  },
  "肩负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānfù",
      "PrimaryDefinition": "to shoulder (a burden), to bear, to suffer (a disadvantage)"
    },
    "Keys": {
      "Word": 7384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逞强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chěng qiáng",
      "PrimaryDefinition": "to show off, to try to be brave"
    },
    "Keys": {
      "Word": 5890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谈判": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tánpàn",
      "PrimaryDefinition": "negociate, treat, talks, negotiation, negotiate, talk terms, parley, palaver, negotiations, imparl"
    },
    "Keys": {
      "Word": 1949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "砍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎn",
      "PrimaryDefinition": "hack, chop, cut, fell"
    },
    "Keys": {
      "Hanzi": 1454,
      "Word": 7768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.4",
      "Grade": 7,
      "GeneralStandard": 1454,
      "Radical": "石",
      "HSK": 7,
      "FrequencyRank": 2217,
      "StrokeCount": 9
    }
  },
  "显": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "manifest, display; evident, clear"
    },
    "Keys": {
      "Hanzi": 1485,
      "Word": 4032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "Grade": 5,
      "GeneralStandard": 1485,
      "Radical": "日",
      "HSK": 3,
      "FrequencyRank": 469,
      "StrokeCount": 9
    }
  },
  "腭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "palate, roof of mouth"
    },
    "Keys": {
      "Hanzi": 5520
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5520,
      "Radical": "肉",
      "FrequencyRank": 5294,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "in front, forward; preceding"
    },
    "Keys": {
      "Hanzi": 1650,
      "Word": 288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.7",
      "Grade": 1,
      "GeneralStandard": 1650,
      "Radical": "刀",
      "HSK": 1,
      "FrequencyRank": 93,
      "StrokeCount": 9
    }
  },
  "蕃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān",
      "PrimaryDefinition": "foreign things"
    },
    "Keys": {
      "Hanzi": 5880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5880,
      "Radical": "艸",
      "FrequencyRank": 3646,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "赳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "grand, valiant"
    },
    "Keys": {
      "Hanzi": 4099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4099,
      "Radical": "走",
      "FrequencyRank": 4530,
      "RadicalIndex": "156.2",
      "StrokeCount": 9
    }
  },
  "来到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láidào",
      "PrimaryDefinition": "arrive, come"
    },
    "Keys": {
      "Word": 203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "勤快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínkuai",
      "PrimaryDefinition": "diligent, hardworking"
    },
    "Keys": {
      "Word": 8730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎo",
      "PrimaryDefinition": "lake, fishpond, swamps"
    },
    "Keys": {
      "Hanzi": 1260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1260,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2480,
      "StrokeCount": 8
    }
  },
  "进修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnxiū",
      "PrimaryDefinition": "engage in advanced studies, take refresher course"
    },
    "Keys": {
      "Word": 7583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灭绝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mièjué",
      "PrimaryDefinition": "to extinguish, to become extinct, to die out"
    },
    "Keys": {
      "Word": 8296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "pit, cellar"
    },
    "Keys": {
      "Hanzi": 2785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2785,
      "Radical": "穴",
      "FrequencyRank": 3317,
      "RadicalIndex": "116.7",
      "StrokeCount": 12
    }
  },
  "类型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèixíng",
      "PrimaryDefinition": "category, species, genre, portrait, nature, stamp, cast, type"
    },
    "Keys": {
      "Word": 2689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "萦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "entangle, entwine, coil"
    },
    "Keys": {
      "Hanzi": 4767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4767,
      "Radical": "艸",
      "FrequencyRank": 3903,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "客房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèfáng",
      "PrimaryDefinition": "guest room"
    },
    "Keys": {
      "Word": 7812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wà",
      "PrimaryDefinition": "fat"
    },
    "Keys": {
      "Hanzi": 7554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7554,
      "Radical": "肉",
      "FrequencyRank": 7694,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "courtyard, yard, court; school"
    },
    "Keys": {
      "Hanzi": 1734,
      "Word": 1183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.7",
      "Grade": 2,
      "GeneralStandard": 1734,
      "Radical": "阜",
      "HSK": 1,
      "FrequencyRank": 338,
      "StrokeCount": 9
    }
  },
  "僵化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānghuà",
      "PrimaryDefinition": "to become rigid"
    },
    "Keys": {
      "Word": 7441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "红色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngsè",
      "PrimaryDefinition": "red color"
    },
    "Keys": {
      "Word": 721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "舍得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shě de",
      "PrimaryDefinition": "to be willing to part with sth"
    },
    "Keys": {
      "Word": 3872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "一锅粥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīguōzhōu",
      "PrimaryDefinition": "(lit.) a pot of porridge, (fig.) a complete mess"
    },
    "Keys": {
      "Word": 10326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倡导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàngdǎo",
      "PrimaryDefinition": "to advocate, to initiate, to propose, to be a proponent of (an idea or school of thought)"
    },
    "Keys": {
      "Word": 3318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "紧急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnjí",
      "PrimaryDefinition": "urgent, critical"
    },
    "Keys": {
      "Word": 1639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鸳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": "male mandarin duck (Aix galericulata)"
    },
    "Keys": {
      "Hanzi": 2010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2010,
      "Radical": "鳥",
      "FrequencyRank": 3866,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "绮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "fine thin silk; elegant, beautifu"
    },
    "Keys": {
      "Hanzi": 5029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5029,
      "Radical": "糸",
      "FrequencyRank": 3565,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "电源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànyuán",
      "PrimaryDefinition": "electric power source"
    },
    "Keys": {
      "Word": 2416
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "僇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "humiliate; treat with contempt"
    },
    "Keys": {
      "Hanzi": 7549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7549,
      "Radical": "人",
      "RadicalIndex": "9.11",
      "StrokeCount": 13
    }
  },
  "负面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùmiàn",
      "PrimaryDefinition": "negative, negativeness, reverse (side), reverse"
    },
    "Keys": {
      "Word": 6623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "对照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìzhào",
      "PrimaryDefinition": "contrast, compare"
    },
    "Keys": {
      "Word": 6383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怀孕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huái yùn",
      "PrimaryDefinition": "gravid, expect, in a family way, with child, pregnant, be pregnant, gestate, impregnate, carry, bear, carry to term, be expecting, conceive"
    },
    "Keys": {
      "Word": 7156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "素材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùcái",
      "PrimaryDefinition": "material, source material, medium"
    },
    "Keys": {
      "Word": 9352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "据说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùshuō",
      "PrimaryDefinition": "they say, it is said, it is said that..., reputedly, allegedly"
    },
    "Keys": {
      "Word": 1657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "呻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "groan, moan; recite with intonation"
    },
    "Keys": {
      "Hanzi": 1095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1095,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2929,
      "StrokeCount": 8
    }
  },
  "名誉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngyù",
      "PrimaryDefinition": "honour, laurel, celebrity, worship, character, odor, credit, repute, fame, name, rep, reputation, honorary, distinction, regard, honor"
    },
    "Keys": {
      "Word": 4871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "美丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měilì",
      "PrimaryDefinition": "beautiful"
    },
    "Keys": {
      "Word": 1737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "熵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng",
      "PrimaryDefinition": "entropy"
    },
    "Keys": {
      "Hanzi": 5995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5995,
      "Radical": "火",
      "FrequencyRank": 4221,
      "RadicalIndex": "86.11",
      "StrokeCount": 15
    }
  },
  "鸭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "duck; Anas species (various)"
    },
    "Keys": {
      "Hanzi": 1892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "196.5",
      "GeneralStandard": 1892,
      "Radical": "鳥",
      "HSK": 5,
      "FrequencyRank": 2133,
      "StrokeCount": 10
    }
  },
  "落后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò hòu",
      "PrimaryDefinition": "fall/lay behind"
    },
    "Keys": {
      "Word": 1725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "董": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒng",
      "PrimaryDefinition": "direct, supervise; surname"
    },
    "Keys": {
      "Hanzi": 2551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "GeneralStandard": 2551,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 1629,
      "StrokeCount": 12
    }
  },
  "遭到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāodào",
      "PrimaryDefinition": "endure, encounter, meet with, run into, suffer, soak_up"
    },
    "Keys": {
      "Word": 5306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "脚步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎobù",
      "PrimaryDefinition": "footfall, tread, pace, foot, footstep, step"
    },
    "Keys": {
      "Word": 3615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "基金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jījīn",
      "PrimaryDefinition": "finances, endowment fund, foundation, fundation, treasury, fund, corpus, endowment, exchequer, investment firm, investment trust, investment company"
    },
    "Keys": {
      "Word": 3576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "家用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāyòng",
      "PrimaryDefinition": "family used"
    },
    "Keys": {
      "Word": 7353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "幸福": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngfú",
      "PrimaryDefinition": "happy"
    },
    "Keys": {
      "Word": 2060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "身份": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnfen",
      "PrimaryDefinition": "state, dignity, station, estate, body, estate of the realm, capacity, status, standing, caste, doctorate, place, identity, condition"
    },
    "Keys": {
      "Word": 2851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "所谓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒwèi",
      "PrimaryDefinition": "so-called"
    },
    "Keys": {
      "Word": 9379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "sit; seat; ride, travel by"
    },
    "Keys": {
      "Hanzi": 797,
      "Word": 495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 1,
      "GeneralStandard": 797,
      "Radical": "土",
      "HSK": 1,
      "FrequencyRank": 611,
      "StrokeCount": 7
    }
  },
  "阗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "a place in Xinjiang province"
    },
    "Keys": {
      "Hanzi": 5553
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5553,
      "Radical": "門",
      "FrequencyRank": 5190,
      "RadicalIndex": "169.1",
      "StrokeCount": 13
    }
  },
  "扩大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòdà",
      "PrimaryDefinition": "enlarge, expand, extend, swell, dilate"
    },
    "Keys": {
      "Word": 2672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "桴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "raft; drum stick; ridge pole"
    },
    "Keys": {
      "Hanzi": 4774
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4774,
      "Radical": "木",
      "FrequencyRank": 6141,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "缛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù",
      "PrimaryDefinition": "decorative, adorned, elegant"
    },
    "Keys": {
      "Hanzi": 5612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5612,
      "Radical": "糸",
      "FrequencyRank": 5057,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "檀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "sandalwood, hardwood; surname"
    },
    "Keys": {
      "Hanzi": 3396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3396,
      "Radical": "木",
      "FrequencyRank": 3421,
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "僻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "out-of-the-way, remote; unorthodox"
    },
    "Keys": {
      "Hanzi": 3271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.13",
      "GeneralStandard": 3271,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 2827,
      "StrokeCount": 15
    }
  },
  "濑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lài",
      "PrimaryDefinition": "swift current; rapids"
    },
    "Keys": {
      "Hanzi": 6174
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6174,
      "Radical": "水",
      "FrequencyRank": 4161,
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "巇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "a crack; hazardous"
    },
    "Keys": {
      "Hanzi": 8058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8058,
      "Radical": "山",
      "RadicalIndex": "46.17",
      "StrokeCount": 20
    }
  },
  "喀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kā",
      "PrimaryDefinition": "vomit; used in transliterations"
    },
    "Keys": {
      "Hanzi": 5160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5160,
      "Radical": "口",
      "FrequencyRank": 2469,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "麑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "fawn, young deer"
    },
    "Keys": {
      "Hanzi": 8046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8046,
      "Radical": "鹿",
      "FrequencyRank": 7192,
      "RadicalIndex": "198.8",
      "StrokeCount": 19
    }
  },
  "牲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "sacrificial animal; animal"
    },
    "Keys": {
      "Hanzi": 1544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "93.5",
      "GeneralStandard": 1544,
      "Radical": "牛",
      "HSK": 6,
      "FrequencyRank": 1582,
      "StrokeCount": 9
    }
  },
  "男子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánzǐ",
      "PrimaryDefinition": "man, male"
    },
    "Keys": {
      "Word": 1756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "聋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "deaf"
    },
    "Keys": {
      "Hanzi": 2245,
      "Word": 8116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "128.5",
      "Grade": 7,
      "GeneralStandard": 2245,
      "Radical": "耳",
      "HSK": 8,
      "FrequencyRank": 2873,
      "StrokeCount": 11
    }
  },
  "郿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "county in Shaanxi province"
    },
    "Keys": {
      "Hanzi": 5011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5011,
      "Radical": "邑",
      "FrequencyRank": 6426,
      "RadicalIndex": "163.9",
      "StrokeCount": 11
    }
  },
  "䓨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "(simplified form of 罃) long-necked bottle; an earthenware jar with a small mouth and two or four ears"
    },
    "Keys": {
      "Hanzi": 7132
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7132,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 14
    }
  },
  "髎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "hip bone"
    },
    "Keys": {
      "Hanzi": 8060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8060,
      "Radical": "骨",
      "RadicalIndex": "188.11",
      "StrokeCount": 20
    }
  },
  "实在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shízai",
      "PrimaryDefinition": "indeed, really, honestly, in fact, as a matter of fact"
    },
    "Keys": {
      "Word": 979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "囹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "prison, enclosure"
    },
    "Keys": {
      "Hanzi": 3937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3937,
      "Radical": "囗",
      "FrequencyRank": 5392,
      "RadicalIndex": "31.5",
      "StrokeCount": 8
    }
  },
  "投身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóushēn",
      "PrimaryDefinition": "to throw oneself into sth"
    },
    "Keys": {
      "Word": 9565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粗糙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūcāo",
      "PrimaryDefinition": "crude, gruff, rough, coarse"
    },
    "Keys": {
      "Word": 6070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "wooden figure buried with dead"
    },
    "Keys": {
      "Hanzi": 4266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4266,
      "Radical": "人",
      "FrequencyRank": 4381,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "终结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngjié",
      "PrimaryDefinition": "end, conclusion, to come to an end, to terminate (sth)"
    },
    "Keys": {
      "Word": 10819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "thatch; fix, repair; pile up"
    },
    "Keys": {
      "Hanzi": 5084
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5084,
      "Radical": "艸",
      "FrequencyRank": 4975,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "撸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5864,
      "Radical": "手",
      "FrequencyRank": 3937,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "缺乏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quēfá",
      "PrimaryDefinition": "be short of, lack"
    },
    "Keys": {
      "Word": 3829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "炫耀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuànyào",
      "PrimaryDefinition": "show_off, prank, flourish, boast, show off, strut, sport, splurge, swank, flaunt, make a display of, feature, brandish, flash"
    },
    "Keys": {
      "Word": 10127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "母亲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔqin",
      "PrimaryDefinition": "venter, materfamilias, mother, mither, sultana, maternal, female parent, mater"
    },
    "Keys": {
      "Word": 1752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "相当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngdāng",
      "PrimaryDefinition": "quite, fairly, considerably"
    },
    "Keys": {
      "Word": 2038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "对白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìbái",
      "PrimaryDefinition": "stage dialog"
    },
    "Keys": {
      "Word": 6377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腹泻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùxiè",
      "PrimaryDefinition": "diarrhea, to have the runs"
    },
    "Keys": {
      "Word": 6646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凭借": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngjiè",
      "PrimaryDefinition": "to rely on, to depend on, by means of, thanks to, sth that one relies on"
    },
    "Keys": {
      "Word": 8558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "distant place; remote; deep"
    },
    "Keys": {
      "Hanzi": 5776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5776,
      "Radical": "夕",
      "FrequencyRank": 5035,
      "RadicalIndex": "36.11",
      "StrokeCount": 14
    }
  },
  "敬业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngyè",
      "PrimaryDefinition": "to be dedicated to one's work, to respect one's work"
    },
    "Keys": {
      "Word": 7647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畏缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèisuō",
      "PrimaryDefinition": "to cower, to flinch, to quail, to recoil"
    },
    "Keys": {
      "Word": 9721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn",
      "PrimaryDefinition": "shift, move, turn"
    },
    "Keys": {
      "Hanzi": 1059,
      "Word": 5386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.4",
      "Grade": 6,
      "GeneralStandard": 1059,
      "Radical": "車",
      "HSK": 3,
      "FrequencyRank": 376,
      "StrokeCount": 8
    }
  },
  "珌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "ornament"
    },
    "Keys": {
      "Hanzi": 6764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6764,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "分解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnjiě",
      "PrimaryDefinition": "to resolve, to decompose, to break down"
    },
    "Keys": {
      "Word": 3459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "艰险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānxiǎn",
      "PrimaryDefinition": "difficult and dangerous, hardships and perils"
    },
    "Keys": {
      "Word": 7387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雪山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuěshān",
      "PrimaryDefinition": "Snow mountain"
    },
    "Keys": {
      "Word": 10138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赶快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnkuài",
      "PrimaryDefinition": "quickly, hastily"
    },
    "Keys": {
      "Word": 1474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不屑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxiè",
      "PrimaryDefinition": "to disdain to do sth, to think sth not worth doing, to feel it beneath one's dignity"
    },
    "Keys": {
      "Word": 5678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大纲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàgāng",
      "PrimaryDefinition": "synopsis, outline, program, leading principles"
    },
    "Keys": {
      "Word": 3379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "损伤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔnshāng",
      "PrimaryDefinition": "to harm, to damage, to injure, impairment, loss, disability"
    },
    "Keys": {
      "Word": 9373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "台风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táifēng",
      "PrimaryDefinition": "typhoon"
    },
    "Keys": {
      "Word": 3956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "觞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng",
      "PrimaryDefinition": "wine vessel; propose toast; feast"
    },
    "Keys": {
      "Hanzi": 5243
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5243,
      "Radical": "角",
      "FrequencyRank": 5139,
      "RadicalIndex": "148.5",
      "StrokeCount": 12
    }
  },
  "欲望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùwàng",
      "PrimaryDefinition": "appetence, indirect request, wish, appetite, stomach, concupiscence, desire, Kama, appetency, hunger, thirst, lech, orexis, rage, passion"
    },
    "Keys": {
      "Word": 10523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "profound"
    },
    "Keys": {
      "Hanzi": 304,
      "Radical": 95,
      "Word": 10116
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "95",
      "Grade": 7,
      "GeneralStandard": 304,
      "Radical": "玄",
      "HSK": 9,
      "FrequencyRank": 1717,
      "StrokeCount": 5
    }
  },
  "料到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liào dào",
      "PrimaryDefinition": "to foresee, to anticipate"
    },
    "Keys": {
      "Word": 8046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuáng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7776,
      "Radical": "口",
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "武装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔzhuāng",
      "PrimaryDefinition": "arm"
    },
    "Keys": {
      "Word": 9801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "首次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒucì",
      "PrimaryDefinition": "first, first time, for the first time"
    },
    "Keys": {
      "Word": 5037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "终身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngshēn",
      "PrimaryDefinition": "lifetime, life, marriage, all one's life, lifelong"
    },
    "Keys": {
      "Word": 4243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "烧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "burn; bake; heat; roast"
    },
    "Keys": {
      "Hanzi": 2060,
      "Word": 2845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "Grade": 4,
      "GeneralStandard": 2060,
      "Radical": "火",
      "HSK": 4,
      "FrequencyRank": 1201,
      "StrokeCount": 10
    }
  },
  "复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "return; repeat; repeatedly"
    },
    "Keys": {
      "Hanzi": 1553
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "35.6",
      "GeneralStandard": 1553,
      "Radical": "夊",
      "HSK": 2,
      "FrequencyRank": 426,
      "StrokeCount": 9
    }
  },
  "通告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōnggào",
      "PrimaryDefinition": "to announce, to give notice"
    },
    "Keys": {
      "Word": 9522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěguì",
      "PrimaryDefinition": "valuable, praiseworthy"
    },
    "Keys": {
      "Word": 7799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒugǎn",
      "PrimaryDefinition": "taste, texture (of food), how food feels in the mouth"
    },
    "Keys": {
      "Word": 7838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "被子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèizi",
      "PrimaryDefinition": "quilt, CL:床[chuang2]"
    },
    "Keys": {
      "Word": 1297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "手机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒujī",
      "PrimaryDefinition": "cell phone, mobile phone, CL:部[bu4],支[zhi1]"
    },
    "Keys": {
      "Word": 335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "火山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒshān",
      "PrimaryDefinition": "volcano"
    },
    "Keys": {
      "Word": 7246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㠇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "name of a mountain ridge"
    },
    "Keys": {
      "Hanzi": 7782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7782,
      "Radical": "山",
      "RadicalIndex": "46.12",
      "StrokeCount": 15
    }
  },
  "包裹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāoguǒ",
      "PrimaryDefinition": "to wrap up, to bind up, bundle, parcel, package, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "砄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6811,
      "Radical": "石",
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "斗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒu",
      "PrimaryDefinition": "dipper"
    },
    "Keys": {
      "Hanzi": 166,
      "Radical": 68,
      "Word": 6349
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "68",
      "Grade": 7,
      "GeneralStandard": 166,
      "Radical": "斗",
      "HSK": 4,
      "FrequencyRank": 580,
      "StrokeCount": 4
    }
  },
  "文章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénzhāng",
      "PrimaryDefinition": "nonsense, hidden meaning, writing, hidden, written material, article, implied meaning, essay, writings, literary works, patch, hidden/implied meaning"
    },
    "Keys": {
      "Word": 2015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "艾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "artemisia, mugwort; translit."
    },
    "Keys": {
      "Hanzi": 207
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.2",
      "GeneralStandard": 207,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 1291,
      "StrokeCount": 5
    }
  },
  "䴔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "the fishing cormorant"
    },
    "Keys": {
      "Hanzi": 7224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7224,
      "Radical": "",
      "FrequencyRank": 9020,
      "RadicalIndex": "",
      "StrokeCount": 17
    }
  },
  "光滑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānghua",
      "PrimaryDefinition": "glossy, sleek, smooth"
    },
    "Keys": {
      "Word": 6901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嶦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7871,
      "Radical": "山",
      "RadicalIndex": "46.13",
      "StrokeCount": 16
    }
  },
  "冬天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngtiān",
      "PrimaryDefinition": "winter, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "plan, plot; strategem; scheme"
    },
    "Keys": {
      "Hanzi": 168,
      "Word": 7316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.2",
      "Grade": 7,
      "GeneralStandard": 168,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 251,
      "StrokeCount": 4
    }
  },
  "舾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "(Cant.) equipment on a ship"
    },
    "Keys": {
      "Hanzi": 7397
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7397,
      "Radical": "舟",
      "FrequencyRank": 5924,
      "RadicalIndex": "137.6",
      "StrokeCount": 12
    }
  },
  "余地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúdì",
      "PrimaryDefinition": "margin, leeway"
    },
    "Keys": {
      "Word": 10493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "make; seek, get; barley; low"
    },
    "Keys": {
      "Hanzi": 3643
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3643,
      "Radical": "牛",
      "FrequencyRank": 2854,
      "RadicalIndex": "93.2",
      "StrokeCount": 6
    }
  },
  "䴕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "a woodpecker"
    },
    "Keys": {
      "Hanzi": 7152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7152,
      "Radical": "",
      "FrequencyRank": 8752,
      "RadicalIndex": "",
      "StrokeCount": 17
    }
  },
  "合格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hégé",
      "PrimaryDefinition": "measure_up, reach standard, measure up, qualify, nail, make it, pass, qualified"
    },
    "Keys": {
      "Word": 1545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "斓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "multicolored"
    },
    "Keys": {
      "Hanzi": 6161
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6161,
      "Radical": "文",
      "FrequencyRank": 3994,
      "RadicalIndex": "67.12",
      "StrokeCount": 16
    }
  },
  "荷花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héhuā",
      "PrimaryDefinition": "lotus"
    },
    "Keys": {
      "Word": 7045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缆车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎnchē",
      "PrimaryDefinition": "cable car"
    },
    "Keys": {
      "Word": 7929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扩建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòjiàn",
      "PrimaryDefinition": "extend (factory/mine/etc.)"
    },
    "Keys": {
      "Word": 7901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "early; bright; respect"
    },
    "Keys": {
      "Hanzi": 7159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7159,
      "Radical": "日",
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "恶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "evil, wicked, bad, foul"
    },
    "Keys": {
      "Hanzi": 1829,
      "Word": 6411
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "Grade": 7,
      "GeneralStandard": 1829,
      "Radical": "心",
      "HSK": 4,
      "FrequencyRank": 792,
      "StrokeCount": 10
    }
  },
  "健康": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànkāng",
      "PrimaryDefinition": "healthy, sound"
    },
    "Keys": {
      "Word": 761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "衢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "highway; thoroughfare, intersection"
    },
    "Keys": {
      "Hanzi": 6489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6489,
      "Radical": "行",
      "FrequencyRank": 4387,
      "RadicalIndex": "144.18",
      "StrokeCount": 24
    }
  },
  "阐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "explain, clarify, elucidate"
    },
    "Keys": {
      "Hanzi": 2397
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.8",
      "GeneralStandard": 2397,
      "Radical": "門",
      "HSK": 9,
      "FrequencyRank": 2197,
      "StrokeCount": 11
    }
  },
  "桑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāng",
      "PrimaryDefinition": "mulberry tree; surname"
    },
    "Keys": {
      "Hanzi": 2149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 2149,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 1528,
      "StrokeCount": 10
    }
  },
  "经历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnglì",
      "PrimaryDefinition": "go through, undergo, experience"
    },
    "Keys": {
      "Word": 1640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "打球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎqiú",
      "PrimaryDefinition": "play a ball game using arms/hands"
    },
    "Keys": {
      "Word": 60
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "整体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngtǐ",
      "PrimaryDefinition": "integrity, allness, entirety, integer, integral, monolith, entireness, ensemble, wholeness, entire, tout ensemble, whole, totality, unity"
    },
    "Keys": {
      "Word": 2148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "劳动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láodong",
      "PrimaryDefinition": "work, labor"
    },
    "Keys": {
      "Word": 3681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "黔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "black; Guizhou"
    },
    "Keys": {
      "Hanzi": 3353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3353,
      "Radical": "黑",
      "FrequencyRank": 3989,
      "RadicalIndex": "203.4",
      "StrokeCount": 16
    }
  },
  "感恩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn ēn",
      "PrimaryDefinition": "to be grateful"
    },
    "Keys": {
      "Word": 6675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "build, establish, erect, found"
    },
    "Keys": {
      "Hanzi": 1302,
      "Word": 1600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "54.6",
      "Grade": 3,
      "GeneralStandard": 1302,
      "Radical": "廴",
      "HSK": 3,
      "FrequencyRank": 244,
      "StrokeCount": 8
    }
  },
  "粱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "better varieties of millet"
    },
    "Keys": {
      "Hanzi": 3021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3021,
      "Radical": "米",
      "FrequencyRank": 3599,
      "RadicalIndex": "119.7",
      "StrokeCount": 13
    }
  },
  "焊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "weld, solder"
    },
    "Keys": {
      "Hanzi": 2408,
      "Word": 6989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.7",
      "Grade": 7,
      "GeneralStandard": 2408,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 4087,
      "StrokeCount": 11
    }
  },
  "蛄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "mole cricket"
    },
    "Keys": {
      "Hanzi": 4832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4832,
      "Radical": "虫",
      "FrequencyRank": 5027,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "悲欢离合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēihuān-líhé",
      "PrimaryDefinition": "joys and sorrows, partings and reunions, the vicissitudes of life"
    },
    "Keys": {
      "Word": 5549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "产生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnshēng",
      "PrimaryDefinition": "come into being, produce, engender, emerge"
    },
    "Keys": {
      "Word": 1333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "妖怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāoguài",
      "PrimaryDefinition": "monster, devil"
    },
    "Keys": {
      "Word": 10230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāng",
      "PrimaryDefinition": "female sheep, ewe"
    },
    "Keys": {
      "Hanzi": 4684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4684,
      "Radical": "爿",
      "RadicalIndex": "90.6",
      "StrokeCount": 10
    }
  },
  "狩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "winter hunting; imperial tour"
    },
    "Keys": {
      "Hanzi": 4294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4294,
      "Radical": "犬",
      "FrequencyRank": 3190,
      "RadicalIndex": "94.6",
      "StrokeCount": 9
    }
  },
  "发言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāyán",
      "PrimaryDefinition": "speak, make statement/speech"
    },
    "Keys": {
      "Word": 1435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "浡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "burst forth; rise; vigorous"
    },
    "Keys": {
      "Hanzi": 7055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7055,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "厝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": "cut or engrave; a grave or tombstone"
    },
    "Keys": {
      "Hanzi": 4471
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4471,
      "Radical": "厂",
      "FrequencyRank": 4839,
      "RadicalIndex": "27.8",
      "StrokeCount": 10
    }
  },
  "遽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "suddenly, unexpectedly; at once"
    },
    "Keys": {
      "Hanzi": 6082
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6082,
      "Radical": "辵",
      "FrequencyRank": 3949,
      "RadicalIndex": "162.13",
      "StrokeCount": 16
    }
  },
  "竞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "contend, vie, compete"
    },
    "Keys": {
      "Hanzi": 2043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "117.5",
      "GeneralStandard": 2043,
      "Radical": "立",
      "HSK": 5,
      "FrequencyRank": 1100,
      "StrokeCount": 10
    }
  },
  "麋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "elk; surname"
    },
    "Keys": {
      "Hanzi": 6291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6291,
      "Radical": "鹿",
      "FrequencyRank": 4702,
      "RadicalIndex": "198.6",
      "StrokeCount": 17
    }
  },
  "衰弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāiruò",
      "PrimaryDefinition": "weak, feeble"
    },
    "Keys": {
      "Word": 9243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "行为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngwéi",
      "PrimaryDefinition": "fact, demeanor, form, deportment, dealing, behavior, doings, course, act, point, goings-on, demeanour, procedure, lapse, going, bearing, turn, behaviour, activity, ongoing, thing, human action, conduct, commitment, human activity, behavioral, agency, deed, comportment, action"
    },
    "Keys": {
      "Word": 1115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "原材料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuáncáiliào",
      "PrimaryDefinition": "raw materials, unprocessed materials"
    },
    "Keys": {
      "Word": 10540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "初等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūděng",
      "PrimaryDefinition": "elementary (i.e. easy)"
    },
    "Keys": {
      "Word": 4433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "医生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshēng",
      "PrimaryDefinition": "Galen, MD, physician, Dr., medic, doc, hakeem, medico, Aesculapius, medical man, surgeon, leech, doctor"
    },
    "Keys": {
      "Word": 425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "铕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "europium"
    },
    "Keys": {
      "Hanzi": 7179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7179,
      "Radical": "金",
      "FrequencyRank": 6793,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "最后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìhòu",
      "PrimaryDefinition": "final, last, finally, ultimate"
    },
    "Keys": {
      "Word": 491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "融化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rónghuà",
      "PrimaryDefinition": "to melt, to thaw, to dissolve, to blend into, to combine, to fuse"
    },
    "Keys": {
      "Word": 8888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "佑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "help, protect, bless"
    },
    "Keys": {
      "Hanzi": 773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 773,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 2775,
      "StrokeCount": 7
    }
  },
  "椽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuán",
      "PrimaryDefinition": "beams, rafters, supports"
    },
    "Keys": {
      "Hanzi": 5408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5408,
      "Radical": "木",
      "FrequencyRank": 4405,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "埂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěng",
      "PrimaryDefinition": "ditches for irrigation; hole"
    },
    "Keys": {
      "Hanzi": 1784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1784,
      "Radical": "土",
      "FrequencyRank": 4239,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "踬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "stumble, totter; fail, be frustrated"
    },
    "Keys": {
      "Hanzi": 5915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5915,
      "Radical": "足",
      "FrequencyRank": 6538,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "䐃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "fat in the abdomen or intestine, protuberances of the muscle, a fetus inside the belly"
    },
    "Keys": {
      "Hanzi": 7402
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7402,
      "Radical": "肉",
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "媵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìng",
      "PrimaryDefinition": "a maid who accompanies bride to her new home; to escort; a concubine"
    },
    "Keys": {
      "Hanzi": 5523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5523,
      "Radical": "女",
      "FrequencyRank": 6712,
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "八卦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāguà",
      "PrimaryDefinition": "the eight divinatory trigrams of the Book of Changes 易經|易经[Yi4 jing1], gossip, gossipy"
    },
    "Keys": {
      "Word": 5457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洗衣机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐyījī",
      "PrimaryDefinition": "washer, washing machine, CL:臺|台[tai2]"
    },
    "Keys": {
      "Word": 1076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "影子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngzi",
      "PrimaryDefinition": "umbra, sign, silhouette, reflection, trace, shadow, vague impression"
    },
    "Keys": {
      "Word": 3108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "忙碌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mánglù",
      "PrimaryDefinition": "be busy, bustle about"
    },
    "Keys": {
      "Word": 8202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无形中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxíngzhōng",
      "PrimaryDefinition": "imperceptibly, virtually"
    },
    "Keys": {
      "Word": 9791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寒冷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánlěng",
      "PrimaryDefinition": "cold, frigid"
    },
    "Keys": {
      "Word": 2534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "刺激": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìjī",
      "PrimaryDefinition": "stimulate, provoke, irritate, upset"
    },
    "Keys": {
      "Word": 2351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "渑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "name of a river in Shandong"
    },
    "Keys": {
      "Hanzi": 4974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4974,
      "Radical": "水",
      "FrequencyRank": 5623,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "报复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàofu",
      "PrimaryDefinition": "to make reprisals, to retaliate, revenge, retaliation"
    },
    "Keys": {
      "Word": 5536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "配偶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèiǒu",
      "PrimaryDefinition": "spouse"
    },
    "Keys": {
      "Word": 8488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "部分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùfen",
      "PrimaryDefinition": "partial, department, section, partialness, slice, pars, part, det.: part, percent, partage, sphere, component, divvy, segment, portion, fraction, sector, chapter, share, percentage, piece, division, sectional, parcel"
    },
    "Keys": {
      "Word": 541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "巧妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎomiào",
      "PrimaryDefinition": "felicitous, skillful, ingenious, clever, shrewd"
    },
    "Keys": {
      "Word": 4935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "歌舞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēwǔ",
      "PrimaryDefinition": "singing and dancing"
    },
    "Keys": {
      "Word": 6727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "男孩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánháir",
      "PrimaryDefinition": "boy, erhua variant of 男孩[nan2 hai2]"
    },
    "Keys": {
      "Word": 258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "规定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīdìng",
      "PrimaryDefinition": "rules, disciplines, regulations"
    },
    "Keys": {
      "Word": 1528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蛲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "worms"
    },
    "Keys": {
      "Hanzi": 5143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5143,
      "Radical": "虫",
      "FrequencyRank": 5835,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "protect, guard, defend, shelter"
    },
    "Keys": {
      "Hanzi": 649,
      "Word": 4671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 6,
      "GeneralStandard": 649,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 529,
      "StrokeCount": 7
    }
  },
  "惘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "disconcerted, dejected, discouraged"
    },
    "Keys": {
      "Hanzi": 4987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4987,
      "Radical": "心",
      "FrequencyRank": 3425,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "醺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "get drunk, be intoxicated"
    },
    "Keys": {
      "Hanzi": 6450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6450,
      "Radical": "酉",
      "FrequencyRank": 4131,
      "RadicalIndex": "164.14",
      "StrokeCount": 21
    }
  },
  "祖籍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔjí",
      "PrimaryDefinition": "ancestral hometown, original domicile (and civil registration)"
    },
    "Keys": {
      "Word": 11009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngnián",
      "PrimaryDefinition": "in those days, then, in those years, during that time, that very same year"
    },
    "Keys": {
      "Word": 3402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "前不久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánbùjiǔ",
      "PrimaryDefinition": "not long ago, not long before"
    },
    "Keys": {
      "Word": 8661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "a cassock or robe of a monk"
    },
    "Keys": {
      "Hanzi": 5574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5574,
      "Radical": "衣",
      "FrequencyRank": 4784,
      "RadicalIndex": "145.7",
      "StrokeCount": 13
    }
  },
  "修改": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūgǎi",
      "PrimaryDefinition": "revise, amend, alter"
    },
    "Keys": {
      "Word": 2067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鹜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "duck"
    },
    "Keys": {
      "Hanzi": 5840
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5840,
      "Radical": "鳥",
      "FrequencyRank": 5093,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "酹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèi",
      "PrimaryDefinition": "to pour out a libation; to sprinkle"
    },
    "Keys": {
      "Hanzi": 5674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5674,
      "Radical": "酉",
      "FrequencyRank": 7091,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "垄断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒngduàn",
      "PrimaryDefinition": "monopolize"
    },
    "Keys": {
      "Word": 8118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bái",
      "PrimaryDefinition": "white"
    },
    "Keys": {
      "Hanzi": 275,
      "Radical": 106,
      "Word": 1270
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "106",
      "Grade": 3,
      "GeneralStandard": 275,
      "Radical": "白",
      "HSK": 1,
      "FrequencyRank": 286,
      "StrokeCount": 5
    }
  },
  "玉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "jade"
    },
    "Keys": {
      "Hanzi": 190,
      "Radical": 96,
      "Word": 3123
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96",
      "Grade": 4,
      "GeneralStandard": 190,
      "Radical": "玉",
      "HSK": 4,
      "FrequencyRank": 1001,
      "StrokeCount": 5
    }
  },
  "由来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóulái",
      "PrimaryDefinition": "origin"
    },
    "Keys": {
      "Word": 10459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暴风骤雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàofēng-zhòuyǔ",
      "PrimaryDefinition": "violent wind and rainstorm, hurricane, tempest"
    },
    "Keys": {
      "Word": 5540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piào",
      "PrimaryDefinition": "fast, speedy"
    },
    "Keys": {
      "Hanzi": 5697
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5697,
      "Radical": "口",
      "FrequencyRank": 5162,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "抬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "lift, carry"
    },
    "Keys": {
      "Hanzi": 993,
      "Word": 3959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 5,
      "GeneralStandard": 993,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1386,
      "StrokeCount": 8
    }
  },
  "穄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "panicled millet"
    },
    "Keys": {
      "Hanzi": 7876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7876,
      "Radical": "禾",
      "FrequencyRank": 9039,
      "RadicalIndex": "115.11",
      "StrokeCount": 16
    }
  },
  "日常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìcháng",
      "PrimaryDefinition": "daily, everyday"
    },
    "Keys": {
      "Word": 1859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "逃避": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táobì",
      "PrimaryDefinition": "get by, sidestep, get_around, weasel, fly, avoid, evade, ditch, shirk, evasive, fudge, escape, retreat, elude, put off, get off, skive, hedge, welsh, circumvent, wangle, duck, skirt, get away, get out, bilk, hide, ware, evasion, flee, parry, dodge"
    },
    "Keys": {
      "Word": 9433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跨越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàyuè",
      "PrimaryDefinition": "leap over, bestride, straddle, overstretch, span, stride, soar, spraddle, cut across, stride across"
    },
    "Keys": {
      "Word": 7875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曾经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céngjīng",
      "PrimaryDefinition": "in one case, , one time, formerly, syne, at one time, of all time, once, ever"
    },
    "Keys": {
      "Word": 1332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "烷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": "alkane"
    },
    "Keys": {
      "Hanzi": 4961
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4961,
      "Radical": "火",
      "FrequencyRank": 4379,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "体能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐnéng",
      "PrimaryDefinition": "physical capability, stamina"
    },
    "Keys": {
      "Word": 9466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòu",
      "PrimaryDefinition": "narrow; crude, coarse; ugly"
    },
    "Keys": {
      "Hanzi": 1317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.6",
      "GeneralStandard": 1317,
      "Radical": "阜",
      "HSK": 7,
      "FrequencyRank": 2660,
      "StrokeCount": 8
    }
  },
  "蚊子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénzi",
      "PrimaryDefinition": "culex, mosquito, punkie"
    },
    "Keys": {
      "Word": 9743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngdān",
      "PrimaryDefinition": "list of items"
    },
    "Keys": {
      "Word": 8743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "激发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīfā",
      "PrimaryDefinition": "spark, incite, suggest, explode, excitation, foster, motivate, arouse, stir, draw on, set off, energize, excite, stimulation, stimulate"
    },
    "Keys": {
      "Word": 7279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柏树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎishù",
      "PrimaryDefinition": "cypress tree, Taiwan pr. [bo2 shu4]"
    },
    "Keys": {
      "Word": 5474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "面红耳赤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànhóng-ěrchì",
      "PrimaryDefinition": "flushed with anger (or excitement)"
    },
    "Keys": {
      "Word": 8284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脱节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuō jié",
      "PrimaryDefinition": "to come apart"
    },
    "Keys": {
      "Word": 9616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuyòng",
      "PrimaryDefinition": "belong, profit, count, useful, avail, powerful"
    },
    "Keys": {
      "Word": 443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "窝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "nest; cave, den; hiding place"
    },
    "Keys": {
      "Hanzi": 2784,
      "Word": 9752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.7",
      "Grade": 7,
      "GeneralStandard": 2784,
      "Radical": "穴",
      "HSK": 7,
      "FrequencyRank": 1962,
      "StrokeCount": 12
    }
  },
  "氾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "overflow, flood, inundate"
    },
    "Keys": {
      "Hanzi": 6517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6517,
      "Radical": "水",
      "RadicalIndex": "85.2",
      "StrokeCount": 5
    }
  },
  "晞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "dry, expose sun; dawn"
    },
    "Keys": {
      "Hanzi": 4822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4822,
      "Radical": "日",
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "姹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chà",
      "PrimaryDefinition": "beautiful, colorful; girl"
    },
    "Keys": {
      "Hanzi": 4388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4388,
      "Radical": "女",
      "FrequencyRank": 5276,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "三": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sān",
      "PrimaryDefinition": "three"
    },
    "Keys": {
      "Hanzi": 22,
      "Word": 311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.2",
      "Grade": 1,
      "GeneralStandard": 22,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 125,
      "StrokeCount": 3
    }
  },
  "恸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòng",
      "PrimaryDefinition": "sadness, grief; mourn; be moved"
    },
    "Keys": {
      "Hanzi": 4349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4349,
      "Radical": "心",
      "FrequencyRank": 4516,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "缩水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō shuǐ",
      "PrimaryDefinition": "to shrink (in the wash), fig. to shrink (of profits etc)"
    },
    "Keys": {
      "Word": 9376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尽早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnzǎo",
      "PrimaryDefinition": "as early as possible"
    },
    "Keys": {
      "Word": 7567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "境外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngwài",
      "PrimaryDefinition": "outside (a country's) borders"
    },
    "Keys": {
      "Word": 7654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骚扰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāorǎo",
      "PrimaryDefinition": "harass, molest"
    },
    "Keys": {
      "Word": 8926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "资深": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīshēn",
      "PrimaryDefinition": "senior"
    },
    "Keys": {
      "Word": 10944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "破产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòchǎn",
      "PrimaryDefinition": "go bankrupt, come to naught"
    },
    "Keys": {
      "Word": 2787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "高额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoé",
      "PrimaryDefinition": "high quota, large amount"
    },
    "Keys": {
      "Word": 6696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "调试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáoshì",
      "PrimaryDefinition": "to debug, to adjust components during testing, debugging"
    },
    "Keys": {
      "Word": 9502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "cheekbone; path, road; intersection"
    },
    "Keys": {
      "Hanzi": 4931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4931,
      "Radical": "首",
      "FrequencyRank": 4618,
      "RadicalIndex": "185.2",
      "StrokeCount": 11
    }
  },
  "原地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuándì",
      "PrimaryDefinition": "(in) the original place, the place where one currently is, place of origin, local (product)"
    },
    "Keys": {
      "Word": 10541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "私": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "private, personal; secret"
    },
    "Keys": {
      "Hanzi": 766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.2",
      "GeneralStandard": 766,
      "Radical": "禾",
      "HSK": 5,
      "FrequencyRank": 1023,
      "StrokeCount": 7
    }
  },
  "屼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "mountain; a bare hill"
    },
    "Keys": {
      "Hanzi": 6533
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6533,
      "Radical": "山",
      "RadicalIndex": "46.3",
      "StrokeCount": 6
    }
  },
  "改造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎizào",
      "PrimaryDefinition": "recast, rebuild, re-form, reconstruct, remake, transformation, remould, transmake, reform, transform, remodel, reorganize, retread, remold, remodify, reshape, remoulding, redo, alter, recreate, transmute, make over, refashion, revamp"
    },
    "Keys": {
      "Word": 1468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "懿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "virtuous, admirable, esteemed"
    },
    "Keys": {
      "Hanzi": 6465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6465,
      "Radical": "心",
      "FrequencyRank": 2932,
      "RadicalIndex": "61.18",
      "StrokeCount": 22
    }
  },
  "公布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngbù",
      "PrimaryDefinition": "promulgate, announce, publish"
    },
    "Keys": {
      "Word": 1502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "痉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "convulsions, fits"
    },
    "Keys": {
      "Hanzi": 4621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4621,
      "Radical": "疒",
      "FrequencyRank": 3430,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "水晶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐjīng",
      "PrimaryDefinition": "quartz, rock crystal, rock_crystal, rhinestone, crystal, pebble"
    },
    "Keys": {
      "Word": 9261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琢磨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuómó",
      "PrimaryDefinition": "to carve and polish (jade), to polish and refine a literary work, to ponder, to mull over, to think through, Taiwan pr. [zhuo2 mo2]"
    },
    "Keys": {
      "Word": 11023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "grasp with hand, pinch"
    },
    "Keys": {
      "Hanzi": 2527,
      "Word": 7658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2527,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3065,
      "StrokeCount": 12
    }
  },
  "鄘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "state in Henan province"
    },
    "Keys": {
      "Hanzi": 7574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7574,
      "Radical": "邑",
      "FrequencyRank": 7768,
      "RadicalIndex": "163.11",
      "StrokeCount": 13
    }
  },
  "卖弄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màinong",
      "PrimaryDefinition": "to show off, to make a display of"
    },
    "Keys": {
      "Word": 8193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "an inferior gem"
    },
    "Keys": {
      "Hanzi": 7283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7283,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "陶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "pottery, ceramics"
    },
    "Keys": {
      "Hanzi": 2137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.8",
      "GeneralStandard": 2137,
      "Radical": "阜",
      "HSK": 8,
      "FrequencyRank": 1601,
      "StrokeCount": 10
    }
  },
  "受益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu yì",
      "PrimaryDefinition": "to benefit from, profit"
    },
    "Keys": {
      "Word": 9200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng xué",
      "PrimaryDefinition": "attend school, be at school"
    },
    "Keys": {
      "Word": 318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "项": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "neck, nape of neck; sum; funds"
    },
    "Keys": {
      "Hanzi": 1370,
      "Word": 3024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.3",
      "Grade": 4,
      "GeneralStandard": 1370,
      "Radical": "頁",
      "HSK": 4,
      "FrequencyRank": 571,
      "StrokeCount": 9
    }
  },
  "资历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīlì",
      "PrimaryDefinition": "qualifications, experience, seniority"
    },
    "Keys": {
      "Word": 10942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "roar"
    },
    "Keys": {
      "Hanzi": 6719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6719,
      "Radical": "水",
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "药": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "leaf of Dahurian angelica plant; medicine"
    },
    "Keys": {
      "Hanzi": 1423,
      "Word": 1129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "Grade": 2,
      "GeneralStandard": 1423,
      "Radical": "艸",
      "HSK": 2,
      "FrequencyRank": 662,
      "StrokeCount": 9
    }
  },
  "调度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàodù",
      "PrimaryDefinition": "to dispatch (vehicles, staff etc), to schedule, to manage, dispatcher, scheduler"
    },
    "Keys": {
      "Word": 6292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "消": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "vanish, die out; melt away"
    },
    "Keys": {
      "Hanzi": 2071,
      "Word": 9955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 7,
      "GeneralStandard": 2071,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 439,
      "StrokeCount": 10
    }
  },
  "嗒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dā",
      "PrimaryDefinition": "absent-minded"
    },
    "Keys": {
      "Hanzi": 5129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5129,
      "Radical": "口",
      "FrequencyRank": 3497,
      "RadicalIndex": "30.1",
      "StrokeCount": 12
    }
  },
  "欸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "sigh; an exclamatory sound"
    },
    "Keys": {
      "Hanzi": 5026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5026,
      "Radical": "欠",
      "RadicalIndex": "76.7",
      "StrokeCount": 11
    }
  },
  "电力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànlì",
      "PrimaryDefinition": "electrical line of force, electrical energy, power, electricity, potential, electric power"
    },
    "Keys": {
      "Word": 4508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嬴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "to win; to have a surplus; surname"
    },
    "Keys": {
      "Hanzi": 6164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6164,
      "Radical": "女",
      "FrequencyRank": 4511,
      "RadicalIndex": "38.14",
      "StrokeCount": 16
    }
  },
  "裤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kù",
      "PrimaryDefinition": "trousers, pants"
    },
    "Keys": {
      "Hanzi": 2791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.7",
      "GeneralStandard": 2791,
      "Radical": "衣",
      "HSK": 3,
      "FrequencyRank": 2090,
      "StrokeCount": 12
    }
  },
  "当中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngzhōng",
      "PrimaryDefinition": "in the middle/center"
    },
    "Keys": {
      "Word": 1397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "旋转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánzhuǎn",
      "PrimaryDefinition": "revolve, gyrate, spin"
    },
    "Keys": {
      "Word": 5199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蟮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "type of earthworm"
    },
    "Keys": {
      "Hanzi": 6338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6338,
      "Radical": "虫",
      "FrequencyRank": 4280,
      "RadicalIndex": "142.12",
      "StrokeCount": 18
    }
  },
  "库": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kù",
      "PrimaryDefinition": "armory, treasury, storehouse"
    },
    "Keys": {
      "Hanzi": 832,
      "Word": 3669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.4",
      "Grade": 5,
      "GeneralStandard": 832,
      "Radical": "广",
      "HSK": 5,
      "FrequencyRank": 1097,
      "StrokeCount": 7
    }
  },
  "猊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "lion; wild beast; wild horse"
    },
    "Keys": {
      "Hanzi": 7216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7216,
      "Radical": "犬",
      "FrequencyRank": 5928,
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "刊物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kānwù",
      "PrimaryDefinition": "publication, periodical"
    },
    "Keys": {
      "Word": 7764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "匝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zā",
      "PrimaryDefinition": "full circle; encircle"
    },
    "Keys": {
      "Hanzi": 3534
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3534,
      "Radical": "匚",
      "FrequencyRank": 4170,
      "RadicalIndex": "22.3",
      "StrokeCount": 5
    }
  },
  "追究": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuījiū",
      "PrimaryDefinition": "to investigate, to look into"
    },
    "Keys": {
      "Word": 5390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "财务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáiwù",
      "PrimaryDefinition": "finance, financial affairs, financial"
    },
    "Keys": {
      "Word": 5736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shào",
      "PrimaryDefinition": "surname; various place names"
    },
    "Keys": {
      "Hanzi": 3816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3816,
      "Radical": "邑",
      "FrequencyRank": 2814,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "僧人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sēngrén",
      "PrimaryDefinition": "monk"
    },
    "Keys": {
      "Word": 8933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "承包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngbāo",
      "PrimaryDefinition": "to contract, to undertake (a job)"
    },
    "Keys": {
      "Word": 5880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "winding, curving; swagger"
    },
    "Keys": {
      "Hanzi": 4892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4892,
      "Radical": "辵",
      "FrequencyRank": 5185,
      "RadicalIndex": "162.8",
      "StrokeCount": 11
    }
  },
  "指教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐjiào",
      "PrimaryDefinition": "to give advice or comments"
    },
    "Keys": {
      "Word": 10774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "之所以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīsuǒyǐ",
      "PrimaryDefinition": "conj.: why"
    },
    "Keys": {
      "Word": 10742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎn",
      "PrimaryDefinition": "gall bladder; bravery, courage"
    },
    "Keys": {
      "Hanzi": 1593,
      "Word": 3398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "Grade": 5,
      "GeneralStandard": 1593,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1352,
      "StrokeCount": 9
    }
  },
  "发掘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fājué",
      "PrimaryDefinition": "excavate, unearth, explore"
    },
    "Keys": {
      "Word": 6438
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尽快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnkuài",
      "PrimaryDefinition": "as quickly/soon as possible"
    },
    "Keys": {
      "Word": 2631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "撇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piē",
      "PrimaryDefinition": "discard, abandon, throw away"
    },
    "Keys": {
      "Hanzi": 3064,
      "Word": 8531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 7,
      "GeneralStandard": 3064,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2882,
      "StrokeCount": 14
    }
  },
  "分离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnlí",
      "PrimaryDefinition": "to separate"
    },
    "Keys": {
      "Word": 3458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "高峰期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāofēngqī",
      "PrimaryDefinition": "peak period, rush hour"
    },
    "Keys": {
      "Word": 6699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砂糖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shātáng",
      "PrimaryDefinition": "granulated sugar"
    },
    "Keys": {
      "Word": 8938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "paper or cloth pasted together"
    },
    "Keys": {
      "Hanzi": 5820
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5820,
      "Radical": "衣",
      "FrequencyRank": 6230,
      "RadicalIndex": "145.9",
      "StrokeCount": 14
    }
  },
  "殛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "to put to death to imprison for life"
    },
    "Keys": {
      "Hanzi": 5114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5114,
      "Radical": "歹",
      "FrequencyRank": 5731,
      "RadicalIndex": "78.9",
      "StrokeCount": 12
    }
  },
  "排队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái duì",
      "PrimaryDefinition": "stand in line, queue"
    },
    "Keys": {
      "Word": 898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "摧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuī",
      "PrimaryDefinition": "destroy, break, injure"
    },
    "Keys": {
      "Hanzi": 3057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.11",
      "GeneralStandard": 3057,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2166,
      "StrokeCount": 14
    }
  },
  "纡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yū",
      "PrimaryDefinition": "bend, turn, twist; distort"
    },
    "Keys": {
      "Hanzi": 3644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3644,
      "Radical": "糸",
      "FrequencyRank": 5285,
      "RadicalIndex": "120.3",
      "StrokeCount": 6
    }
  },
  "中止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngzhǐ",
      "PrimaryDefinition": "to cease, to suspend, to break off, to stop, to discontinue"
    },
    "Keys": {
      "Word": 10817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "目睹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùdǔ",
      "PrimaryDefinition": "behold, see with one's own eyes, witness"
    },
    "Keys": {
      "Word": 8348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "贰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èr",
      "PrimaryDefinition": "number two"
    },
    "Keys": {
      "Hanzi": 1353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1353,
      "Radical": "弋",
      "FrequencyRank": 3894,
      "RadicalIndex": "56.6",
      "StrokeCount": 9
    }
  },
  "输血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū xuè",
      "PrimaryDefinition": "to transfuse blood, to give aid and support"
    },
    "Keys": {
      "Word": 9223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4191
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4191,
      "Radical": "邑",
      "FrequencyRank": 4751,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "宏观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngguān",
      "PrimaryDefinition": "macro-, macroscopic, holistic"
    },
    "Keys": {
      "Word": 7084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "dragonfly, libellulidae"
    },
    "Keys": {
      "Hanzi": 4839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4839,
      "Radical": "虫",
      "FrequencyRank": 4563,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "传输": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánshū",
      "PrimaryDefinition": "transmission, transmit"
    },
    "Keys": {
      "Word": 4440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guài",
      "PrimaryDefinition": "parted; fork; certain"
    },
    "Keys": {
      "Hanzi": 3525
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3525,
      "Radical": "大",
      "RadicalIndex": "37.1",
      "StrokeCount": 4
    }
  },
  "钪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàng",
      "PrimaryDefinition": "scandium"
    },
    "Keys": {
      "Hanzi": 6849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6849,
      "Radical": "金",
      "FrequencyRank": 5440,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "端正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duānzhèng",
      "PrimaryDefinition": "upright, regular, proper, correct"
    },
    "Keys": {
      "Word": 6368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò",
      "PrimaryDefinition": "pass, pass through, go across"
    },
    "Keys": {
      "Hanzi": 379,
      "Word": 705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.3",
      "Grade": 2,
      "GeneralStandard": 379,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 46,
      "StrokeCount": 6
    }
  },
  "竞技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngjì",
      "PrimaryDefinition": "competition of skill (e.g. sports), athletics tournament"
    },
    "Keys": {
      "Word": 7635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口吃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuchī",
      "PrimaryDefinition": "to stammer, to stutter, also pr. [kou3 ji2]"
    },
    "Keys": {
      "Word": 7837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "scar, cicatrix; birthmark"
    },
    "Keys": {
      "Hanzi": 1628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.4",
      "GeneralStandard": 1628,
      "Radical": "疒",
      "HSK": 6,
      "FrequencyRank": 3055,
      "StrokeCount": 9
    }
  },
  "能干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nénggàn",
      "PrimaryDefinition": "capable, competent"
    },
    "Keys": {
      "Word": 2763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "新式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshì",
      "PrimaryDefinition": "new style, latest type"
    },
    "Keys": {
      "Word": 10034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耳闻目睹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěrwén-mùdǔ",
      "PrimaryDefinition": "to witness personally"
    },
    "Keys": {
      "Word": 6427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "steel; hard, strong, tough"
    },
    "Keys": {
      "Hanzi": 1529,
      "Word": 6687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "Grade": 7,
      "GeneralStandard": 1529,
      "Radical": "金",
      "HSK": 5,
      "FrequencyRank": 1609,
      "StrokeCount": 9
    }
  },
  "仇人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóurén",
      "PrimaryDefinition": "foe, one's personal enemy"
    },
    "Keys": {
      "Word": 5935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "建议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànyì",
      "PrimaryDefinition": "propose, suggest, recommend"
    },
    "Keys": {
      "Word": 1607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "液晶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèjīng",
      "PrimaryDefinition": "liquid crystal"
    },
    "Keys": {
      "Word": 10270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "设法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèfǎ",
      "PrimaryDefinition": "contrive, think up a method, devise, try"
    },
    "Keys": {
      "Word": 9006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "养成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎngchéng",
      "PrimaryDefinition": "to cultivate, to raise, to form (a habit), to acquire"
    },
    "Keys": {
      "Word": 3077
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "隗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "high; lofty; surname"
    },
    "Keys": {
      "Hanzi": 5015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5015,
      "Radical": "阜",
      "FrequencyRank": 4940,
      "RadicalIndex": "170.1",
      "StrokeCount": 11
    }
  },
  "魆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "black"
    },
    "Keys": {
      "Hanzi": 5762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5762,
      "Radical": "鬼",
      "FrequencyRank": 6184,
      "RadicalIndex": "194.5",
      "StrokeCount": 14
    }
  },
  "啫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhě",
      "PrimaryDefinition": "(Cant.) interjection of warning; phonetic; penis"
    },
    "Keys": {
      "Hanzi": 7156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7156,
      "Radical": "口",
      "RadicalIndex": "30.9",
      "StrokeCount": 11
    }
  },
  "忉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāo",
      "PrimaryDefinition": "grieved; distressed in mind"
    },
    "Keys": {
      "Hanzi": 6518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6518,
      "Radical": "心",
      "FrequencyRank": 6315,
      "RadicalIndex": "61.2",
      "StrokeCount": 5
    }
  },
  "开机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi jī",
      "PrimaryDefinition": "to start an engine, to boot up (a computer), to press Ctrl-Alt-Delete, to begin shooting a film or TV show"
    },
    "Keys": {
      "Word": 803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "起草": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ cǎo",
      "PrimaryDefinition": "to make a draft, to draw up (plans)"
    },
    "Keys": {
      "Word": 8613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "spine, backbone; ridge"
    },
    "Keys": {
      "Hanzi": 2033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 2033,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 2228,
      "StrokeCount": 10
    }
  },
  "脏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàng",
      "PrimaryDefinition": "internal organs, viscera; dirty"
    },
    "Keys": {
      "Hanzi": 1998,
      "Word": 1193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "Grade": 2,
      "GeneralStandard": 1998,
      "Radical": "肉",
      "HSK": 2,
      "FrequencyRank": 1634,
      "StrokeCount": 10
    }
  },
  "另一方面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìngyīfāngmiàn",
      "PrimaryDefinition": "conj.: on the other hand"
    },
    "Keys": {
      "Word": 1715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "更新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēngxīn",
      "PrimaryDefinition": "renew, replace"
    },
    "Keys": {
      "Word": 3499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "东": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōng",
      "PrimaryDefinition": "east, eastern, eastward"
    },
    "Keys": {
      "Hanzi": 225,
      "Word": 84
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "Grade": 1,
      "GeneralStandard": 225,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 194,
      "StrokeCount": 5
    }
  },
  "舱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāng",
      "PrimaryDefinition": "hold of ship; cabin"
    },
    "Keys": {
      "Hanzi": 1979,
      "Word": 5765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "137.4",
      "Grade": 7,
      "GeneralStandard": 1979,
      "Radical": "舟",
      "HSK": 7,
      "FrequencyRank": 1846,
      "StrokeCount": 10
    }
  },
  "铁路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiělù",
      "PrimaryDefinition": "rails, railroad track, loop-line, rail, Pullman, railway, road, couchette, chemmy, railroad"
    },
    "Keys": {
      "Word": 1973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "羯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "wether, castrated ram; deer skin"
    },
    "Keys": {
      "Hanzi": 5987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5987,
      "Radical": "羊",
      "FrequencyRank": 4952,
      "RadicalIndex": "123.9",
      "StrokeCount": 15
    }
  },
  "刭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "cut throat"
    },
    "Keys": {
      "Hanzi": 3818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3818,
      "Radical": "刀",
      "FrequencyRank": 7433,
      "RadicalIndex": "18.5",
      "StrokeCount": 7
    }
  },
  "糕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "cakes, pastry"
    },
    "Keys": {
      "Hanzi": 3375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "119.1",
      "GeneralStandard": 3375,
      "Radical": "米",
      "HSK": 5,
      "FrequencyRank": 2413,
      "StrokeCount": 16
    }
  },
  "术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "art, skill, special feat; method, technique"
    },
    "Keys": {
      "Hanzi": 211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "GeneralStandard": 211,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 328,
      "StrokeCount": 5
    }
  },
  "脐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "abdominal area of crab; navel"
    },
    "Keys": {
      "Hanzi": 1999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1999,
      "Radical": "肉",
      "FrequencyRank": 3830,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "黛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "blacken eyebrows; black"
    },
    "Keys": {
      "Hanzi": 6261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6261,
      "Radical": "黑",
      "FrequencyRank": 2612,
      "RadicalIndex": "203.5",
      "StrokeCount": 17
    }
  },
  "多半": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōbàn",
      "PrimaryDefinition": "det : the greater part"
    },
    "Keys": {
      "Word": 4528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "祚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "throne; bless; blessing, happiness"
    },
    "Keys": {
      "Hanzi": 4367
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4367,
      "Radical": "示",
      "FrequencyRank": 4799,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "就座": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù zuò",
      "PrimaryDefinition": "Seating seat"
    },
    "Keys": {
      "Word": 7674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "brood over eggs; have confidence"
    },
    "Keys": {
      "Hanzi": 3739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3739,
      "Radical": "子",
      "FrequencyRank": 3473,
      "RadicalIndex": "39.4",
      "StrokeCount": 7
    }
  },
  "耪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǎng",
      "PrimaryDefinition": "plow, cultivate"
    },
    "Keys": {
      "Hanzi": 6028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6028,
      "Radical": "耒",
      "FrequencyRank": 5572,
      "RadicalIndex": "127.1",
      "StrokeCount": 16
    }
  },
  "放置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngzhì",
      "PrimaryDefinition": "dispose, feed, set, locate, go, plant, pose, sit, lay, stick, shove, repose, bestow, sock, lay aside, perch, put, position, deposit, depose, get, lay up, mount, place, localize"
    },
    "Keys": {
      "Word": 6506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "scratch; dig up; crawl; crouch"
    },
    "Keys": {
      "Hanzi": 201,
      "Word": 5460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.2",
      "Grade": 7,
      "GeneralStandard": 201,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3121,
      "StrokeCount": 5
    }
  },
  "先天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāntiān",
      "PrimaryDefinition": "a priori, congenital, natural, innately, innate, inborn"
    },
    "Keys": {
      "Word": 9882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoren",
      "PrimaryDefinition": "oldster, ancient, old woman, crock, one's aged parents or grandparents, old man/woman, one's aged parents/grandparents, Sir, old person, gaffer, pop, one's aged parents, the aged/old, old_man, senior_citizen, oldie, greybeard, old man, graybeard, old, grandparents, the old, the aged, senior citizen, old_boy, old boy, woman, old-timer, old man or woman, one's aged grandparents"
    },
    "Keys": {
      "Word": 205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "必修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìxiū",
      "PrimaryDefinition": "required/obligatory (course), obligatory, obligatory (course), required"
    },
    "Keys": {
      "Word": 4327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "出血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūxiě",
      "PrimaryDefinition": "shed blood, have a hemorrhage, bleeding, bleed, spend money, phlebotomize, hemorrhage, hemorrhagic, leech"
    },
    "Keys": {
      "Word": 5972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "启蒙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ méng",
      "PrimaryDefinition": "to instruct the young, to initiate, to awake sb from ignorance, to free sb from prejudice or superstition, primer, enlightened, the Enlightenment, Western learning from the late Qing dynasty"
    },
    "Keys": {
      "Word": 8609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "何况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hékuàng",
      "PrimaryDefinition": "conj.: much less, let alone, all the more"
    },
    "Keys": {
      "Word": 7035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敏捷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐnjié",
      "PrimaryDefinition": "nimble, quick, shrewd"
    },
    "Keys": {
      "Word": 8301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "头发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóufa",
      "PrimaryDefinition": "barba, chevelure, lock, head of hair, hair, tress, mane, hair (on human head)"
    },
    "Keys": {
      "Word": 1040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "售票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu piào",
      "PrimaryDefinition": "Ticket sales"
    },
    "Keys": {
      "Word": 9205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "招标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo biāo",
      "PrimaryDefinition": "invite tenders/bids"
    },
    "Keys": {
      "Word": 10660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnglǐ",
      "PrimaryDefinition": "amaldar, intendant, manager, steward, executive, managing director, exec, director, skipper, syndic, executive director"
    },
    "Keys": {
      "Word": 794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "橛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "a post, a stake; an axle"
    },
    "Keys": {
      "Hanzi": 6057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6057,
      "Radical": "木",
      "FrequencyRank": 4998,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "心肠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīncháng",
      "PrimaryDefinition": "heart, intention, one's inclination, state of mind, to have the heart for sth, mood"
    },
    "Keys": {
      "Word": 10003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuó",
      "PrimaryDefinition": "move, shift on one side"
    },
    "Keys": {
      "Hanzi": 1397,
      "Word": 8449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1397,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2204,
      "StrokeCount": 9
    }
  },
  "浪费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làngfèi",
      "PrimaryDefinition": "waste, squander"
    },
    "Keys": {
      "Word": 1684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "换取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànqǔ",
      "PrimaryDefinition": "get in return, buy, exchange ... for, barter ... for, exchange sth. for, exchange"
    },
    "Keys": {
      "Word": 7177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "座谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòtán",
      "PrimaryDefinition": "forum, symposium"
    },
    "Keys": {
      "Word": 11033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn",
      "PrimaryDefinition": "iodine"
    },
    "Keys": {
      "Hanzi": 2877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2877,
      "Radical": "石",
      "FrequencyRank": 3681,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "噬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "bite; gnaw; snap at"
    },
    "Keys": {
      "Hanzi": 6105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6105,
      "Radical": "口",
      "FrequencyRank": 3228,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "及格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí gé",
      "PrimaryDefinition": "to pass an exam or a test, to meet a minimum standard"
    },
    "Keys": {
      "Word": 2578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "答": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "answer, reply; return; assent to"
    },
    "Keys": {
      "Hanzi": 2687,
      "Word": 3371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 5,
      "GeneralStandard": 2687,
      "Radical": "竹",
      "HSK": 1,
      "FrequencyRank": 559,
      "StrokeCount": 12
    }
  },
  "接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "receive; continue; catch; connect"
    },
    "Keys": {
      "Hanzi": 2185,
      "Word": 786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 2,
      "GeneralStandard": 2185,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 247,
      "StrokeCount": 11
    }
  },
  "精通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngtōng",
      "PrimaryDefinition": "to be proficient in, to master (a subject)"
    },
    "Keys": {
      "Word": 7622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "moist, wet, dampen"
    },
    "Keys": {
      "Hanzi": 4653
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4653,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "枧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "bamboo tube, wooden peg; spout"
    },
    "Keys": {
      "Hanzi": 3878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3878,
      "Radical": "木",
      "FrequencyRank": 7085,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "浜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāng",
      "PrimaryDefinition": "creek, stream; beach, sea coast"
    },
    "Keys": {
      "Hanzi": 4655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4655,
      "Radical": "水",
      "FrequencyRank": 4339,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "中华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zhōnghuá",
      "PrimaryDefinition": "the Chinese nation, China"
    },
    "Keys": {
      "Word": 5365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "型号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xínghào",
      "PrimaryDefinition": "model number"
    },
    "Keys": {
      "Word": 3042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "代表团": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàibiǎotuán",
      "PrimaryDefinition": "contingent, delegacy, mission, deputation, delegation"
    },
    "Keys": {
      "Word": 1390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "渐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "gradually"
    },
    "Keys": {
      "Hanzi": 2417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2417,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 870,
      "StrokeCount": 11
    }
  },
  "圢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": "paths between fields"
    },
    "Keys": {
      "Hanzi": 6510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6510,
      "Radical": "土",
      "RadicalIndex": "32.2",
      "StrokeCount": 5
    }
  },
  "报告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàogào",
      "PrimaryDefinition": "report, speech, (student) term paper"
    },
    "Keys": {
      "Word": 1288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "远近闻名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnjìnwénmíng",
      "PrimaryDefinition": "Far-known"
    },
    "Keys": {
      "Word": 10554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "解除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěchú",
      "PrimaryDefinition": "remove, relieve, get rid of"
    },
    "Keys": {
      "Word": 3617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "纰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "spoiled silk; hem of dress; mista"
    },
    "Keys": {
      "Hanzi": 3822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3822,
      "Radical": "糸",
      "FrequencyRank": 5277,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "场合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnghé",
      "PrimaryDefinition": "conjuncture, situation, event, occasion, social occasion, affair"
    },
    "Keys": {
      "Word": 1338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "攀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pān",
      "PrimaryDefinition": "climb; pull; hang on to"
    },
    "Keys": {
      "Hanzi": 3456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.15",
      "GeneralStandard": 3456,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2439,
      "StrokeCount": 19
    }
  },
  "入选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù xuǎn",
      "PrimaryDefinition": "select, be selected, be chosen"
    },
    "Keys": {
      "Word": 8905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "养老": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎng lǎo",
      "PrimaryDefinition": "to provide for the elderly (family members), to enjoy a life in retirement"
    },
    "Keys": {
      "Word": 5218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "父亲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùqin",
      "PrimaryDefinition": "parent, father, pere, Pa, begetter, governor, abba, male parent, pater, guv"
    },
    "Keys": {
      "Word": 1463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "瑢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "gem ornaments for belts"
    },
    "Keys": {
      "Hanzi": 7626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7626,
      "Radical": "玉",
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "饵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "bait; bait, entice; dumplings"
    },
    "Keys": {
      "Hanzi": 1610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.6",
      "GeneralStandard": 1610,
      "Radical": "食",
      "HSK": 8,
      "FrequencyRank": 3135,
      "StrokeCount": 9
    }
  },
  "刚才": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngcái",
      "PrimaryDefinition": "(just) a moment ago"
    },
    "Keys": {
      "Word": 675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "上涨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngzhǎng",
      "PrimaryDefinition": "rise, go up (of water level/prices/etc.)"
    },
    "Keys": {
      "Word": 3867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "客气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèqi",
      "PrimaryDefinition": "be polite, stand on ceremony"
    },
    "Keys": {
      "Word": 3662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "预计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùjì",
      "PrimaryDefinition": "estimate, view, predict, expect, calculate in advance, anticipate"
    },
    "Keys": {
      "Word": 2120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "帝国主义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìguózhǔyì",
      "PrimaryDefinition": "imperialism"
    },
    "Keys": {
      "Word": 6258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "能力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nénglì",
      "PrimaryDefinition": "department, potency, ability, acquisition, acquirement, capacity, performance, oomph, sufficiency, energy, competence, accomplishment, size, might, faculty, hand, coequal, efficiency, inner resources, capability, giftie, attainment, capableness, zip, skill, competency, power"
    },
    "Keys": {
      "Word": 1766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "棫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "thorny shrub with yellow flowers; a kind of oak"
    },
    "Keys": {
      "Hanzi": 7323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7323,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "挑战": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎo zhàn",
      "PrimaryDefinition": "a challenge to battle/contest"
    },
    "Keys": {
      "Word": 2936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "外币": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàibì",
      "PrimaryDefinition": "foreign currency"
    },
    "Keys": {
      "Word": 5116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "拜托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàituō",
      "PrimaryDefinition": "to request sb to do sth, please!"
    },
    "Keys": {
      "Word": 5481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "footpath, trail; track"
    },
    "Keys": {
      "Hanzi": 6229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6229,
      "Radical": "足",
      "FrequencyRank": 3935,
      "RadicalIndex": "157.1",
      "StrokeCount": 17
    }
  },
  "胃口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèikou",
      "PrimaryDefinition": "appetite, liking"
    },
    "Keys": {
      "Word": 9724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mián",
      "PrimaryDefinition": "cotton; cotton padded"
    },
    "Keys": {
      "Hanzi": 2574,
      "Word": 4859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "Grade": 6,
      "GeneralStandard": 2574,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1967,
      "StrokeCount": 12
    }
  },
  "吖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "used in translation; (Cant.) final particle"
    },
    "Keys": {
      "Hanzi": 6532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6532,
      "Radical": "口",
      "FrequencyRank": 6243,
      "RadicalIndex": "30.3",
      "StrokeCount": 6
    }
  },
  "湿度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīdù",
      "PrimaryDefinition": "humidity level"
    },
    "Keys": {
      "Word": 9101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钞票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāopiào",
      "PrimaryDefinition": "bank note, paper money, bill"
    },
    "Keys": {
      "Word": 5826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "be concerned, worry about"
    },
    "Keys": {
      "Hanzi": 1882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1882,
      "Radical": "心",
      "HSK": 4,
      "FrequencyRank": 901,
      "StrokeCount": 10
    }
  },
  "某": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǒu",
      "PrimaryDefinition": "certain thing or person"
    },
    "Keys": {
      "Hanzi": 1399,
      "Word": 1751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 3,
      "GeneralStandard": 1399,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 517,
      "StrokeCount": 9
    }
  },
  "领军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐng jūn",
      "PrimaryDefinition": "to lead troups, (fig.) to lead, leading"
    },
    "Keys": {
      "Word": 8078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "step on, tread on; stumble, slip"
    },
    "Keys": {
      "Hanzi": 3408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.1",
      "GeneralStandard": 3408,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 3678,
      "StrokeCount": 17
    }
  },
  "嚎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "cry loudly, yell, scream"
    },
    "Keys": {
      "Hanzi": 3413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3413,
      "Radical": "口",
      "FrequencyRank": 3241,
      "RadicalIndex": "30.14",
      "StrokeCount": 17
    }
  },
  "歃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shà",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5496,
      "Radical": "欠",
      "FrequencyRank": 6211,
      "RadicalIndex": "76.9",
      "StrokeCount": 13
    }
  },
  "鸡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "chicken"
    },
    "Keys": {
      "Hanzi": 928,
      "Word": 743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "196.2",
      "Grade": 2,
      "GeneralStandard": 928,
      "Radical": "鳥",
      "HSK": 1,
      "FrequencyRank": 1391,
      "StrokeCount": 7
    }
  },
  "沥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "trickle, drip; strain; dregs"
    },
    "Keys": {
      "Hanzi": 859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 859,
      "Radical": "水",
      "FrequencyRank": 3598,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "符号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúhào",
      "PrimaryDefinition": "sign, denotation, mark, icon, symbolical, symbol, notation, token, insignia, note, symbolic"
    },
    "Keys": {
      "Word": 2480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "众多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngduō",
      "PrimaryDefinition": "multitudinous, numerous"
    },
    "Keys": {
      "Word": 4247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "屋子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūzi",
      "PrimaryDefinition": "room, house"
    },
    "Keys": {
      "Word": 2018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "炎症": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánzhèng",
      "PrimaryDefinition": "inflammation"
    },
    "Keys": {
      "Word": 10181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuǎn",
      "PrimaryDefinition": "pant, gasp, breathe heavily"
    },
    "Keys": {
      "Hanzi": 2642,
      "Word": 6014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "Grade": 7,
      "GeneralStandard": 2642,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1977,
      "StrokeCount": 12
    }
  },
  "一经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījīng",
      "PrimaryDefinition": "Once"
    },
    "Keys": {
      "Word": 10330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnyì",
      "PrimaryDefinition": "regard, kindly feeling, intention, purpose"
    },
    "Keys": {
      "Word": 10019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "批判": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīpàn",
      "PrimaryDefinition": "criticize"
    },
    "Keys": {
      "Word": 8503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "信箱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnxiāng",
      "PrimaryDefinition": "post-office box, mailbox, letter_box, letter box, postbox, box"
    },
    "Keys": {
      "Word": 4065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "琄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "scabbard; traces, reins"
    },
    "Keys": {
      "Hanzi": 7102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7102,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "贪婪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tānlán",
      "PrimaryDefinition": "avaricious, greedy, rapacious, insatiable, avid"
    },
    "Keys": {
      "Word": 9396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胖子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàngzi",
      "PrimaryDefinition": "fat person, fatty"
    },
    "Keys": {
      "Word": 2773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "身躯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnqū",
      "PrimaryDefinition": "body"
    },
    "Keys": {
      "Word": 9021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "期望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīwàng",
      "PrimaryDefinition": "expectation, hope"
    },
    "Keys": {
      "Word": 3793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "团": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuán",
      "PrimaryDefinition": "sphere, ball, circle; mass, lump"
    },
    "Keys": {
      "Hanzi": 425,
      "Word": 1985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.3",
      "Grade": 3,
      "GeneralStandard": 425,
      "Radical": "囗",
      "HSK": 3,
      "FrequencyRank": 405,
      "StrokeCount": 6
    }
  },
  "蹽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6385
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6385,
      "Radical": "足",
      "FrequencyRank": 8297,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "挣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "strive, endeavor, struggle"
    },
    "Keys": {
      "Hanzi": 1391,
      "Word": 4215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 5,
      "GeneralStandard": 1391,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1875,
      "StrokeCount": 9
    }
  },
  "莉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "white jasmine"
    },
    "Keys": {
      "Hanzi": 1825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1825,
      "Radical": "艸",
      "FrequencyRank": 1878,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "燃放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ránfàng",
      "PrimaryDefinition": "to light, to set off (firecrackers etc)"
    },
    "Keys": {
      "Word": 8821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "放学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng xué",
      "PrimaryDefinition": "have winter holidays, classes are over, finish classes, have summer holidays, finish classes (for the day)"
    },
    "Keys": {
      "Word": 105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "朸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6528,
      "Radical": "木",
      "RadicalIndex": "75.2",
      "StrokeCount": 6
    }
  },
  "艚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáo",
      "PrimaryDefinition": "ship"
    },
    "Keys": {
      "Hanzi": 7957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7957,
      "Radical": "舟",
      "FrequencyRank": 6017,
      "RadicalIndex": "137.11",
      "StrokeCount": 17
    }
  },
  "畜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "livestock, domestic animals"
    },
    "Keys": {
      "Hanzi": 2047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.5",
      "GeneralStandard": 2047,
      "Radical": "田",
      "HSK": 8,
      "FrequencyRank": 2030,
      "StrokeCount": 10
    }
  },
  "奥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "mysterious, obscure, profound"
    },
    "Keys": {
      "Hanzi": 2701
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.9",
      "GeneralStandard": 2701,
      "Radical": "大",
      "HSK": 8,
      "FrequencyRank": 972,
      "StrokeCount": 12
    }
  },
  "不服气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùfúqì",
      "PrimaryDefinition": "Uncomfortable"
    },
    "Keys": {
      "Word": 5695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "device for carrying a tripod"
    },
    "Keys": {
      "Hanzi": 4541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4541,
      "Radical": "金",
      "FrequencyRank": 4809,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "图像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túxiàng",
      "PrimaryDefinition": "picture, graph, icon, image"
    },
    "Keys": {
      "Word": 9578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngjiān",
      "PrimaryDefinition": "center, middle"
    },
    "Keys": {
      "Word": 475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "蕤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruí",
      "PrimaryDefinition": "drooping leaves; fringe soft, delicate"
    },
    "Keys": {
      "Hanzi": 5876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5876,
      "Radical": "艸",
      "FrequencyRank": 6222,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "瞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēn",
      "PrimaryDefinition": "glare with anger"
    },
    "Keys": {
      "Hanzi": 5903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5903,
      "Radical": "目",
      "FrequencyRank": 5846,
      "RadicalIndex": "109.1",
      "StrokeCount": 15
    }
  },
  "璒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7834,
      "Radical": "玉",
      "RadicalIndex": "96.12",
      "StrokeCount": 16
    }
  },
  "吊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": "condole, mourn, pity; hang"
    },
    "Keys": {
      "Hanzi": 428,
      "Word": 4509
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 6,
      "GeneralStandard": 428,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2050,
      "StrokeCount": 6
    }
  },
  "借用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièyòng",
      "PrimaryDefinition": "use sth. for another purpose, have the loan of, use ... for another purpose, borrow"
    },
    "Keys": {
      "Word": 7557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōu",
      "PrimaryDefinition": "small fish; small; minnow"
    },
    "Keys": {
      "Hanzi": 7887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7887,
      "Radical": "魚",
      "FrequencyRank": 5526,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "觿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "a bodkin made of ivory, horn"
    },
    "Keys": {
      "Hanzi": 8104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8104,
      "Radical": "角",
      "FrequencyRank": 7675,
      "RadicalIndex": "148.18",
      "StrokeCount": 25
    }
  },
  "惙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuò",
      "PrimaryDefinition": "sad, melancholy, grieving, mournful"
    },
    "Keys": {
      "Hanzi": 7246
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7246,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "红眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngyǎn",
      "PrimaryDefinition": "Red eye"
    },
    "Keys": {
      "Word": 7083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěxíng",
      "PrimaryDefinition": "feasible"
    },
    "Keys": {
      "Word": 7806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐng",
      "PrimaryDefinition": "mountain ridge, mountain peak"
    },
    "Keys": {
      "Hanzi": 1111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.5",
      "GeneralStandard": 1111,
      "Radical": "山",
      "HSK": 9,
      "FrequencyRank": 2214,
      "StrokeCount": 8
    }
  },
  "腊月": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làyuè",
      "PrimaryDefinition": "twelfth lunar month"
    },
    "Keys": {
      "Word": 7911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "封锁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngsuǒ",
      "PrimaryDefinition": "to blockade, to seal off"
    },
    "Keys": {
      "Word": 6587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中医": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngyī",
      "PrimaryDefinition": "trad. Ch. medicine, doctor of trad. Ch. medicine"
    },
    "Keys": {
      "Word": 1227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "情侣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínglǚ",
      "PrimaryDefinition": "sweethearts, lovers"
    },
    "Keys": {
      "Word": 8759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niándǐ",
      "PrimaryDefinition": "the end of the year, year-end"
    },
    "Keys": {
      "Word": 1770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "晅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎn",
      "PrimaryDefinition": "light of the sun; to dry in the sun"
    },
    "Keys": {
      "Hanzi": 6983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6983,
      "Radical": "日",
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "次数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìshù",
      "PrimaryDefinition": "number of times, frequancy, frequency, absolute frequency, legal case, oftenness, lawsuit, time, frequence"
    },
    "Keys": {
      "Word": 4456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "皎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "white; bright, brilliant; clear"
    },
    "Keys": {
      "Hanzi": 4912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4912,
      "Radical": "白",
      "FrequencyRank": 4351,
      "RadicalIndex": "106.6",
      "StrokeCount": 11
    }
  },
  "埋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mái",
      "PrimaryDefinition": "bury, secrete, conceal"
    },
    "Keys": {
      "Hanzi": 1794,
      "Word": 4848
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.7",
      "Grade": 6,
      "GeneralStandard": 1794,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 1640,
      "StrokeCount": 10
    }
  },
  "勿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "must not, do not; without, never"
    },
    "Keys": {
      "Hanzi": 152,
      "Word": 9807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "20.2",
      "Grade": 7,
      "GeneralStandard": 152,
      "Radical": "勹",
      "HSK": 8,
      "FrequencyRank": 2563,
      "StrokeCount": 4
    }
  },
  "下午": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàwǔ",
      "PrimaryDefinition": "afternoon, CL:個|个[ge4], p.m."
    },
    "Keys": {
      "Word": 392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "热门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèmén",
      "PrimaryDefinition": "favorite, in great demand, popular"
    },
    "Keys": {
      "Word": 3835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "竑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "be vast and endless; broad"
    },
    "Keys": {
      "Hanzi": 4313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4313,
      "Radical": "立",
      "FrequencyRank": 4654,
      "RadicalIndex": "117.4",
      "StrokeCount": 9
    }
  },
  "鲜活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānhuó",
      "PrimaryDefinition": "vivid, lively, (of food ingredients) live or fresh"
    },
    "Keys": {
      "Word": 9887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià qu",
      "PrimaryDefinition": "go on, continue, go down, descend, take_up, descent"
    },
    "Keys": {
      "Word": 2027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "碍事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài shì",
      "PrimaryDefinition": "(usu used in the negative) to be of importance or to matter, to be in the way, to be a hindrance"
    },
    "Keys": {
      "Word": 5435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "日期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìqī",
      "PrimaryDefinition": "calendar, when, date"
    },
    "Keys": {
      "Word": 306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "睎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "to long for; to gaze at"
    },
    "Keys": {
      "Hanzi": 7350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7350,
      "Radical": "目",
      "FrequencyRank": 9066,
      "RadicalIndex": "109.7",
      "StrokeCount": 12
    }
  },
  "苏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 677,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 590,
      "StrokeCount": 7
    }
  },
  "看作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànzuò",
      "PrimaryDefinition": "to look upon as, to regard as"
    },
    "Keys": {
      "Word": 4788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "硬件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngjiàn",
      "PrimaryDefinition": "hardware"
    },
    "Keys": {
      "Word": 4125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "违章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi zhāng",
      "PrimaryDefinition": "to break the rules, to violate regulations"
    },
    "Keys": {
      "Word": 9697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "fine-toothed comb; comb hair"
    },
    "Keys": {
      "Hanzi": 6126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6126,
      "Radical": "竹",
      "FrequencyRank": 5946,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "艺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "art; talent, ability; craft"
    },
    "Keys": {
      "Hanzi": 86
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.1",
      "GeneralStandard": 86,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 786,
      "StrokeCount": 4
    }
  },
  "丫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "forked; bifurcation"
    },
    "Keys": {
      "Hanzi": 56
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "2.2",
      "GeneralStandard": 56,
      "Radical": "丨",
      "HSK": 7,
      "FrequencyRank": 2682,
      "StrokeCount": 3
    }
  },
  "鼽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "clogged nose"
    },
    "Keys": {
      "Hanzi": 7880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7880,
      "Radical": "鼻",
      "RadicalIndex": "209.2",
      "StrokeCount": 16
    }
  },
  "疴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "illness, sickness, disease; pain"
    },
    "Keys": {
      "Hanzi": 4615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4615,
      "Radical": "疒",
      "FrequencyRank": 5773,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "治疗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìliáo",
      "PrimaryDefinition": "treatment, cure"
    },
    "Keys": {
      "Word": 3178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "旅店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚdiàn",
      "PrimaryDefinition": "inn, small hotel"
    },
    "Keys": {
      "Word": 4845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "椀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "bowl; cup; dish"
    },
    "Keys": {
      "Hanzi": 7332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7332,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "龃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "irregular teeth; discord"
    },
    "Keys": {
      "Hanzi": 5426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5426,
      "Radical": "齒",
      "FrequencyRank": 5018,
      "RadicalIndex": "211.5",
      "StrokeCount": 13
    }
  },
  "归宿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīsù",
      "PrimaryDefinition": "place to return to, home, final destination, ending"
    },
    "Keys": {
      "Word": 6914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "测试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèshì",
      "PrimaryDefinition": "test"
    },
    "Keys": {
      "Word": 2296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "寇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòu",
      "PrimaryDefinition": "bandits, thieves; enemy; invade"
    },
    "Keys": {
      "Hanzi": 2451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2451,
      "Radical": "宀",
      "FrequencyRank": 2567,
      "RadicalIndex": "40.8",
      "StrokeCount": 11
    }
  },
  "陈旧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénjiù",
      "PrimaryDefinition": "old-fashioned"
    },
    "Keys": {
      "Word": 5857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "整天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngtiān",
      "PrimaryDefinition": "all day long, whole day"
    },
    "Keys": {
      "Word": 2146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "软实力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎnshílì",
      "PrimaryDefinition": "soft power (i.e. media influence, propaganda and cultural ties in lieu of military power)"
    },
    "Keys": {
      "Word": 8907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnsi",
      "PrimaryDefinition": "thought, idea, thinking, state of mind, mood"
    },
    "Keys": {
      "Word": 10012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "真情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnqíng",
      "PrimaryDefinition": "real situation, the truth"
    },
    "Keys": {
      "Word": 10700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "路况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùkuàng",
      "PrimaryDefinition": "road condition(s) (e.g. surface, traffic flow etc)"
    },
    "Keys": {
      "Word": 8132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒsù",
      "PrimaryDefinition": "at top speed, at a tremendous lick"
    },
    "Keys": {
      "Word": 7247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòng",
      "PrimaryDefinition": "syllable"
    },
    "Keys": {
      "Hanzi": 6982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6982,
      "Radical": "口",
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "协调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiétiáo",
      "PrimaryDefinition": "coordinate, harmonize, bring into line"
    },
    "Keys": {
      "Word": 5178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "果酱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒjiàng",
      "PrimaryDefinition": "jam"
    },
    "Keys": {
      "Word": 4641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "愉快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúkuài",
      "PrimaryDefinition": "be happy, be cheerful"
    },
    "Keys": {
      "Word": 5280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "纺织": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎngzhī",
      "PrimaryDefinition": "spinning and weaving, textile"
    },
    "Keys": {
      "Word": 6501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "原先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánxiān",
      "PrimaryDefinition": "ab initio, at_first, initially, at the start, in_the_beginning, at first, originally"
    },
    "Keys": {
      "Word": 4154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "检查": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnchá",
      "PrimaryDefinition": "check, inspect, examine"
    },
    "Keys": {
      "Word": 756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "评": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "appraise, criticize, evaluate"
    },
    "Keys": {
      "Hanzi": 887,
      "Word": 4909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "Grade": 6,
      "GeneralStandard": 887,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 809,
      "StrokeCount": 7
    }
  },
  "分工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn gōng",
      "PrimaryDefinition": "division of labour, divide the work"
    },
    "Keys": {
      "Word": 4562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "香蕉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngjiāo",
      "PrimaryDefinition": "banana"
    },
    "Keys": {
      "Word": 2045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "刑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "punishment, penalty; law"
    },
    "Keys": {
      "Hanzi": 351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "GeneralStandard": 351,
      "Radical": "刀",
      "HSK": 9,
      "FrequencyRank": 1087,
      "StrokeCount": 6
    }
  },
  "垍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6772,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "旗袍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qípáo",
      "PrimaryDefinition": "Chinese-style dress, cheongsam"
    },
    "Keys": {
      "Word": 8603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo",
      "PrimaryDefinition": "boil vegetables for a very short time; scald"
    },
    "Keys": {
      "Hanzi": 5272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5272,
      "Radical": "火",
      "FrequencyRank": 5673,
      "RadicalIndex": "86.8",
      "StrokeCount": 12
    }
  },
  "晃荡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàngdang",
      "PrimaryDefinition": "to rock, to sway, to shake"
    },
    "Keys": {
      "Word": 7200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒ",
      "PrimaryDefinition": "companion, colleague; utensils"
    },
    "Keys": {
      "Hanzi": 475,
      "Word": 2564
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 4,
      "GeneralStandard": 475,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 1047,
      "StrokeCount": 6
    }
  },
  "预订": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùdìng",
      "PrimaryDefinition": "to place an order, to book ahead"
    },
    "Keys": {
      "Word": 3125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "麹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "yeast, leaven; surname"
    },
    "Keys": {
      "Hanzi": 7748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7748,
      "Radical": "麥",
      "FrequencyRank": 7541,
      "RadicalIndex": "199.8",
      "StrokeCount": 15
    }
  },
  "练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "to practice, drill, exercise, train"
    },
    "Keys": {
      "Hanzi": 1337,
      "Word": 846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 2,
      "GeneralStandard": 1337,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 1005,
      "StrokeCount": 8
    }
  },
  "摔倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāidǎo",
      "PrimaryDefinition": "to fall down, to slip and fall, to throw sb to the ground"
    },
    "Keys": {
      "Word": 3928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "room, home, house, chamber"
    },
    "Keys": {
      "Hanzi": 1697,
      "Word": 1923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.6",
      "Grade": 3,
      "GeneralStandard": 1697,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 708,
      "StrokeCount": 9
    }
  },
  "不要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyào",
      "PrimaryDefinition": "never, noway, don't want, don't (do sth.)"
    },
    "Keys": {
      "Word": 533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "by means of; thereby, therefore; consider as; in order to"
    },
    "Keys": {
      "Hanzi": 182,
      "Word": 10313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "Grade": 7,
      "GeneralStandard": 182,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 23,
      "StrokeCount": 4
    }
  },
  "故事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùshi",
      "PrimaryDefinition": "spiel, recital, relation, scam, rede, plot, yarn, narration, story, fiction, tale, history, legend, narrative"
    },
    "Keys": {
      "Word": 690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "摇晃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáohuang",
      "PrimaryDefinition": "to rock, to shake, to sway"
    },
    "Keys": {
      "Word": 10236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "理论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐlùn",
      "PrimaryDefinition": "theory"
    },
    "Keys": {
      "Word": 1696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "修路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūlù",
      "PrimaryDefinition": "to repair a road"
    },
    "Keys": {
      "Word": 10082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痛心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòngxīn",
      "PrimaryDefinition": "lacerate, pained, harrowing, grieved, distressed, deplore"
    },
    "Keys": {
      "Word": 9549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shài",
      "PrimaryDefinition": "dry in sun, expose to sun"
    },
    "Keys": {
      "Hanzi": 1887,
      "Word": 2836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.6",
      "Grade": 4,
      "GeneralStandard": 1887,
      "Radical": "日",
      "HSK": 4,
      "FrequencyRank": 2630,
      "StrokeCount": 10
    }
  },
  "荣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "glory, honor; flourish, prosper"
    },
    "Keys": {
      "Hanzi": 1415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "GeneralStandard": 1415,
      "Radical": "艸",
      "HSK": 5,
      "FrequencyRank": 993,
      "StrokeCount": 9
    }
  },
  "猜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāi",
      "PrimaryDefinition": "guess, conjecture, suppose; feel"
    },
    "Keys": {
      "Hanzi": 2364,
      "Word": 3292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.8",
      "Grade": 5,
      "GeneralStandard": 2364,
      "Radical": "犬",
      "HSK": 5,
      "FrequencyRank": 1598,
      "StrokeCount": 11
    }
  },
  "制定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìdìng",
      "PrimaryDefinition": "formulate, draft"
    },
    "Keys": {
      "Word": 2176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "全文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánwén",
      "PrimaryDefinition": "entire text, full text"
    },
    "Keys": {
      "Word": 8809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suàn",
      "PrimaryDefinition": "garlic"
    },
    "Keys": {
      "Hanzi": 2847,
      "Word": 9356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.1",
      "Grade": 7,
      "GeneralStandard": 2847,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 3444,
      "StrokeCount": 13
    }
  },
  "氲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūn",
      "PrimaryDefinition": "life giving influences of nature; spirit of harmony; prosperity"
    },
    "Keys": {
      "Hanzi": 5494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5494,
      "Radical": "气",
      "FrequencyRank": 4298,
      "RadicalIndex": "84.1",
      "StrokeCount": 13
    }
  },
  "疥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "scabies, itch"
    },
    "Keys": {
      "Hanzi": 4310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4310,
      "Radical": "疒",
      "FrequencyRank": 5031,
      "RadicalIndex": "104.4",
      "StrokeCount": 9
    }
  },
  "吧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ba",
      "PrimaryDefinition": "emphatic final particle"
    },
    "Keys": {
      "Hanzi": 747,
      "Word": 9
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 1,
      "GeneralStandard": 747,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 470,
      "StrokeCount": 7
    }
  },
  "到位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàowèi",
      "PrimaryDefinition": "to get to the intended location, to be in place, to be in position, precise, well (done)"
    },
    "Keys": {
      "Word": 6199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "remaining, left-over; lonely"
    },
    "Keys": {
      "Hanzi": 3505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3505,
      "Radical": "子",
      "FrequencyRank": 4691,
      "RadicalIndex": "39",
      "StrokeCount": 3
    }
  },
  "拼命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīn mìng",
      "PrimaryDefinition": "go all out regardless of danger to one's life, fight desperately, exerting the utmost strength, risk life, make death-defying effort, defy death, risk one's life, with all one's might"
    },
    "Keys": {
      "Word": 8534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèn",
      "PrimaryDefinition": "dust, earth; a bank of earth; to dig; to bring together"
    },
    "Keys": {
      "Hanzi": 6565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6565,
      "Radical": "土",
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "尽管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnguǎn",
      "PrimaryDefinition": "conj.: even though, despite"
    },
    "Keys": {
      "Word": 3625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "不太": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù tài",
      "PrimaryDefinition": "Not too"
    },
    "Keys": {
      "Word": 529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "篁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "bamboo grove; bamboo"
    },
    "Keys": {
      "Hanzi": 5957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5957,
      "Radical": "竹",
      "FrequencyRank": 5968,
      "RadicalIndex": "118.9",
      "StrokeCount": 15
    }
  },
  "一时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshí",
      "PrimaryDefinition": "a period of time, a while, for a short while, temporary, momentary, at the same time"
    },
    "Keys": {
      "Word": 5241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嵊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng",
      "PrimaryDefinition": "district in Shaohsing, Chekiang"
    },
    "Keys": {
      "Hanzi": 5478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5478,
      "Radical": "山",
      "FrequencyRank": 5236,
      "RadicalIndex": "46.1",
      "StrokeCount": 13
    }
  },
  "袜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wà",
      "PrimaryDefinition": "socks, stockings"
    },
    "Keys": {
      "Hanzi": 2115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.5",
      "GeneralStandard": 2115,
      "Radical": "衣",
      "HSK": 4,
      "FrequencyRank": 3045,
      "StrokeCount": 10
    }
  },
  "训": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "teach, instruct; exegesis"
    },
    "Keys": {
      "Hanzi": 319,
      "Word": 10155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.3",
      "Grade": 7,
      "GeneralStandard": 319,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 1022,
      "StrokeCount": 5
    }
  },
  "远远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnyuǎn",
      "PrimaryDefinition": "distant"
    },
    "Keys": {
      "Word": 5294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "说闲话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō xiánhuà",
      "PrimaryDefinition": "gossip"
    },
    "Keys": {
      "Word": 9298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挨着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āizhe",
      "PrimaryDefinition": "near"
    },
    "Keys": {
      "Word": 4288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "西北": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīběi",
      "PrimaryDefinition": "northwest, Northwest China, the Northwest"
    },
    "Keys": {
      "Word": 1069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "定论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìnglùn",
      "PrimaryDefinition": "final conclusion, accepted argument"
    },
    "Keys": {
      "Word": 6312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "提心吊胆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíxīn-diàodǎn",
      "PrimaryDefinition": "(saying) to be very scared and on edge"
    },
    "Keys": {
      "Word": 9460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亚运会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yàyùnhuì",
      "PrimaryDefinition": "Asian Games"
    },
    "Keys": {
      "Word": 3058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "霎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shà",
      "PrimaryDefinition": "light rain, drizzle; an instant; passing"
    },
    "Keys": {
      "Hanzi": 3338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3338,
      "Radical": "雨",
      "FrequencyRank": 3314,
      "RadicalIndex": "173.8",
      "StrokeCount": 16
    }
  },
  "盈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "fill; full, overflowing; surplus"
    },
    "Keys": {
      "Hanzi": 1745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "108.4",
      "GeneralStandard": 1745,
      "Radical": "皿",
      "HSK": 9,
      "FrequencyRank": 2111,
      "StrokeCount": 9
    }
  },
  "镔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "high quality iron"
    },
    "Keys": {
      "Hanzi": 5953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5953,
      "Radical": "金",
      "FrequencyRank": 6399,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "面条儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miàntiáor",
      "PrimaryDefinition": "noodles, erhua variant of 麵條|面条[mian4 tiao2]"
    },
    "Keys": {
      "Word": 241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "招": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo",
      "PrimaryDefinition": "beckon, summon; recruit, levy"
    },
    "Keys": {
      "Hanzi": 988,
      "Word": 5330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 6,
      "GeneralStandard": 988,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 941,
      "StrokeCount": 8
    }
  },
  "至关重要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìguānzhòngyào",
      "PrimaryDefinition": "extremely important, vital, crucial, essential"
    },
    "Keys": {
      "Word": 10782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "短裤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnkù",
      "PrimaryDefinition": "short pants, shorts"
    },
    "Keys": {
      "Word": 1418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "萹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn",
      "PrimaryDefinition": "grass"
    },
    "Keys": {
      "Hanzi": 7320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7320,
      "Radical": "艸",
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "一点儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdiǎnr",
      "PrimaryDefinition": "det.: a little bit"
    },
    "Keys": {
      "Word": 433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "阳性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yángxìng",
      "PrimaryDefinition": "positive, masculine"
    },
    "Keys": {
      "Word": 10217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跟随": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnsuí",
      "PrimaryDefinition": "keep abreast, succeed, heel, come after, keep up, follow, tag, pursue"
    },
    "Keys": {
      "Word": 3496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "吽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3709,
      "Radical": "口",
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "出动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūdòng",
      "PrimaryDefinition": "to start out on a trip, to dispatch troops"
    },
    "Keys": {
      "Word": 4425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "奴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nú",
      "PrimaryDefinition": "slave, servant"
    },
    "Keys": {
      "Hanzi": 333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.2",
      "GeneralStandard": 333,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 1402,
      "StrokeCount": 5
    }
  },
  "谣言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáoyán",
      "PrimaryDefinition": "rumor"
    },
    "Keys": {
      "Word": 10232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.8",
      "GeneralStandard": 2793,
      "Radical": "示",
      "HSK": 9,
      "FrequencyRank": 2196,
      "StrokeCount": 12
    }
  },
  "瑄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "a ornamental piece of jade several inches across with a hole in the center"
    },
    "Keys": {
      "Hanzi": 5344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5344,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "梴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chān",
      "PrimaryDefinition": "long"
    },
    "Keys": {
      "Hanzi": 6963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6963,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 10
    }
  },
  "不宜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyí",
      "PrimaryDefinition": "be unsuitable"
    },
    "Keys": {
      "Word": 5717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "器械": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìxiè",
      "PrimaryDefinition": "apparatus, instrument, equipment, weapon"
    },
    "Keys": {
      "Word": 8635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "樯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "mast, yard-arm, boom"
    },
    "Keys": {
      "Hanzi": 5884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5884,
      "Radical": "木",
      "FrequencyRank": 5100,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "餐桌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānzhuō",
      "PrimaryDefinition": "dining table, dinner table"
    },
    "Keys": {
      "Word": 5753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "统计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒngjì",
      "PrimaryDefinition": "statistics"
    },
    "Keys": {
      "Word": 2942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "推": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuī",
      "PrimaryDefinition": "push, expel; push forward"
    },
    "Keys": {
      "Hanzi": 2174,
      "Word": 1048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 2,
      "GeneralStandard": 2174,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 505,
      "StrokeCount": 11
    }
  },
  "网上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngshàng",
      "PrimaryDefinition": "online"
    },
    "Keys": {
      "Word": 371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "flow, circulate, drift; class"
    },
    "Keys": {
      "Hanzi": 2080,
      "Word": 857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 2,
      "GeneralStandard": 2080,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 396,
      "StrokeCount": 10
    }
  },
  "自豪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìháo",
      "PrimaryDefinition": "pride, pride oneself on"
    },
    "Keys": {
      "Word": 4276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "理事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐshì",
      "PrimaryDefinition": "member of council"
    },
    "Keys": {
      "Word": 7986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大肆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàsì",
      "PrimaryDefinition": "wantonly, without restraint (of enemy or malefactor), unbridled"
    },
    "Keys": {
      "Word": 6132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坏事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàishì",
      "PrimaryDefinition": "rascality, evildoing, misdoing, villainy, roguery, bad thing, malfeasance, evil deed, caper"
    },
    "Keys": {
      "Word": 7159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交头接耳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāotóu-jiēěr",
      "PrimaryDefinition": "to whisper to one another's ear"
    },
    "Keys": {
      "Word": 7462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夏天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàtiān",
      "PrimaryDefinition": "summer, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "平坦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngtǎn",
      "PrimaryDefinition": "level, even, smooth, flat"
    },
    "Keys": {
      "Word": 3779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "僬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "clever; alert in mind pigmies"
    },
    "Keys": {
      "Hanzi": 7693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7693,
      "Radical": "人",
      "FrequencyRank": 6121,
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "族": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zú",
      "PrimaryDefinition": "a family clan, ethnic group, tribe"
    },
    "Keys": {
      "Hanzi": 2392,
      "Word": 5410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "70.7",
      "Grade": 6,
      "GeneralStandard": 2392,
      "Radical": "方",
      "HSK": 3,
      "FrequencyRank": 549,
      "StrokeCount": 11
    }
  },
  "问候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wènhòu",
      "PrimaryDefinition": "to give one's respects, to send a greeting"
    },
    "Keys": {
      "Word": 2987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "名气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngqi",
      "PrimaryDefinition": "reputation, fame"
    },
    "Keys": {
      "Word": 8308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "rushes"
    },
    "Keys": {
      "Hanzi": 7490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7490,
      "Radical": "艸",
      "FrequencyRank": 8803,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "港": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎng",
      "PrimaryDefinition": "port, harbor; small stream; bay"
    },
    "Keys": {
      "Hanzi": 2749,
      "Word": 6690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 7,
      "GeneralStandard": 2749,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 927,
      "StrokeCount": 12
    }
  },
  "宝石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoshí",
      "PrimaryDefinition": "precious stone, gem"
    },
    "Keys": {
      "Word": 2252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "反应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnyìng",
      "PrimaryDefinition": "reaction, response, repercussion"
    },
    "Keys": {
      "Word": 1438
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "诗歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīgē",
      "PrimaryDefinition": "poem, CL:本[ben3],首[shou3],段[duan4]"
    },
    "Keys": {
      "Word": 3897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "悲哀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēiāi",
      "PrimaryDefinition": "grieved, sorrowful"
    },
    "Keys": {
      "Word": 5546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "amputate, cut off"
    },
    "Keys": {
      "Hanzi": 3971
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3971,
      "Radical": "刀",
      "FrequencyRank": 3959,
      "RadicalIndex": "18.6",
      "StrokeCount": 8
    }
  },
  "卫生间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèishēngjiān",
      "PrimaryDefinition": "bathroom, toilet, WC, CL:間|间[jian1]"
    },
    "Keys": {
      "Word": 2007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "炆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "(Cant.) to simmer, cook over a slow fire"
    },
    "Keys": {
      "Hanzi": 6718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6718,
      "Radical": "火",
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuē",
      "PrimaryDefinition": "treaty, agreement, covenant"
    },
    "Keys": {
      "Hanzi": 593,
      "Word": 2124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.3",
      "Grade": 3,
      "GeneralStandard": 593,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 424,
      "StrokeCount": 6
    }
  },
  "依次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīcì",
      "PrimaryDefinition": "in order, in succession"
    },
    "Keys": {
      "Word": 5229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "有序": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuxù",
      "PrimaryDefinition": "regular, orderly, successive, in order"
    },
    "Keys": {
      "Word": 10483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "基本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīběn",
      "PrimaryDefinition": "fundamental, essential, main"
    },
    "Keys": {
      "Word": 1570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "膝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "knee"
    },
    "Keys": {
      "Hanzi": 3274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.11",
      "GeneralStandard": 3274,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2307,
      "StrokeCount": 15
    }
  },
  "实体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shítǐ",
      "PrimaryDefinition": "essence, reality, entity, substantive, substantiality, substantialness, existence, substratum, noumenon, substance, materiality"
    },
    "Keys": {
      "Word": 9117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔ",
      "PrimaryDefinition": "divination"
    },
    "Keys": {
      "Radical": 25,
      "Hanzi": 8
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "25",
      "GeneralStandard": 8,
      "Radical": "卜",
      "HSK": 8,
      "FrequencyRank": 1979,
      "StrokeCount": 2
    }
  },
  "㿠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7198,
      "Radical": "白",
      "RadicalIndex": "106.6",
      "StrokeCount": 11
    }
  },
  "转告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎngào",
      "PrimaryDefinition": "to pass on, to communicate, to transmit"
    },
    "Keys": {
      "Word": 3197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "病症": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngzhèng",
      "PrimaryDefinition": "disease, illness"
    },
    "Keys": {
      "Word": 5657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèn",
      "PrimaryDefinition": "coffin; tung tree"
    },
    "Keys": {
      "Hanzi": 7499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7499,
      "Radical": "木",
      "FrequencyRank": 5532,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "咫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "foot measure of Zhou dynasty"
    },
    "Keys": {
      "Hanzi": 4376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4376,
      "Radical": "口",
      "FrequencyRank": 4196,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "承": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "inherit, receive; succeed"
    },
    "Keys": {
      "Hanzi": 1315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 1315,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 639,
      "StrokeCount": 8
    }
  },
  "鄹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōu",
      "PrimaryDefinition": "name of a state; surname"
    },
    "Keys": {
      "Hanzi": 7837
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7837,
      "Radical": "邑",
      "FrequencyRank": 6166,
      "RadicalIndex": "163.14",
      "StrokeCount": 16
    }
  },
  "履": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "footwear, shoes; walk on, tread"
    },
    "Keys": {
      "Hanzi": 3312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.12",
      "GeneralStandard": 3312,
      "Radical": "尸",
      "HSK": 8,
      "FrequencyRank": 1802,
      "StrokeCount": 15
    }
  },
  "碹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7664,
      "Radical": "石",
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "镘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "trowel"
    },
    "Keys": {
      "Hanzi": 6114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6114,
      "Radical": "金",
      "FrequencyRank": 7778,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "发言人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāyánrén",
      "PrimaryDefinition": "voice, spokesman, representative, interpreter, prophet, addresser, spokesperson, prolocutor, addressor, fugleman, coryphaeus"
    },
    "Keys": {
      "Word": 4541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "佼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "beautiful, handsome, good-looking"
    },
    "Keys": {
      "Hanzi": 3964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3964,
      "Radical": "人",
      "FrequencyRank": 3993,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "帨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuì",
      "PrimaryDefinition": "handkerchief, kerchief, shawl"
    },
    "Keys": {
      "Hanzi": 7001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7001,
      "Radical": "巾",
      "RadicalIndex": "50.7",
      "StrokeCount": 10
    }
  },
  "朴素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔsù",
      "PrimaryDefinition": "plain and simple, unadorned, simple living, not frivolous"
    },
    "Keys": {
      "Word": 8582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "to pour wine or tea into a cup; to gauge"
    },
    "Keys": {
      "Hanzi": 2846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "68.9",
      "GeneralStandard": 2846,
      "Radical": "斗",
      "HSK": 6,
      "FrequencyRank": 3167,
      "StrokeCount": 13
    }
  },
  "衽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "lapel; hidden lapel of coat"
    },
    "Keys": {
      "Hanzi": 4361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4361,
      "Radical": "衣",
      "FrequencyRank": 5736,
      "RadicalIndex": "145.4",
      "StrokeCount": 9
    }
  },
  "菼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": "rush or sedge"
    },
    "Keys": {
      "Hanzi": 7130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7130,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "定时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngshí",
      "PrimaryDefinition": "to fix a time, fixed time, timed (of explosive etc)"
    },
    "Keys": {
      "Word": 4512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "屋顶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūdǐng",
      "PrimaryDefinition": "roofing, rigging, rooftree, cap, roof top, house top, rooftop, attic, roof, housetop"
    },
    "Keys": {
      "Word": 9759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兄弟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngdi",
      "PrimaryDefinition": "brothers"
    },
    "Keys": {
      "Word": 3047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "吟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "sing, hum; recite; type of poetry"
    },
    "Keys": {
      "Hanzi": 740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 740,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2015,
      "StrokeCount": 7
    }
  },
  "方向盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngxiàngpán",
      "PrimaryDefinition": "steering wheel"
    },
    "Keys": {
      "Word": 6485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánshēn",
      "PrimaryDefinition": "mutton, the whole body, em, whole body, mut"
    },
    "Keys": {
      "Word": 948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "橙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "orange"
    },
    "Keys": {
      "Hanzi": 3331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.12",
      "GeneralStandard": 3331,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 3571,
      "StrokeCount": 16
    }
  },
  "廉价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánjià",
      "PrimaryDefinition": "cheaply-priced, low-cost"
    },
    "Keys": {
      "Word": 8023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒutóu",
      "PrimaryDefinition": "in hand (e.g. cash)"
    },
    "Keys": {
      "Word": 9181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "评选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngxuǎn",
      "PrimaryDefinition": "to select on the basis of a vote or consensus"
    },
    "Keys": {
      "Word": 4912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "尨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "shaggy haired dog; variegated"
    },
    "Keys": {
      "Hanzi": 6584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6584,
      "Radical": "尢",
      "RadicalIndex": "43.4",
      "StrokeCount": 7
    }
  },
  "资讯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīxùn",
      "PrimaryDefinition": "stuff, message, information, news, info"
    },
    "Keys": {
      "Word": 10952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悬殊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánshū",
      "PrimaryDefinition": "widely different, large disparity"
    },
    "Keys": {
      "Word": 10120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辞呈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíchéng",
      "PrimaryDefinition": "(written) resignation"
    },
    "Keys": {
      "Word": 6038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "ground, fertile land"
    },
    "Keys": {
      "Hanzi": 4736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4736,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "提高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí gāo",
      "PrimaryDefinition": "raise, heighten, enhance, increase, improve"
    },
    "Keys": {
      "Word": 1018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "憙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "like, love, enjoy; joyful thing"
    },
    "Keys": {
      "Hanzi": 7835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7835,
      "Radical": "心",
      "RadicalIndex": "61.12",
      "StrokeCount": 16
    }
  },
  "糖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "sugar; candy; sweets"
    },
    "Keys": {
      "Hanzi": 3374,
      "Word": 1953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.1",
      "Grade": 3,
      "GeneralStandard": 3374,
      "Radical": "米",
      "HSK": 3,
      "FrequencyRank": 1746,
      "StrokeCount": 16
    }
  },
  "索性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒxìng",
      "PrimaryDefinition": "you might as well (do it), simply, just"
    },
    "Keys": {
      "Word": 9382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "all, every, everybody"
    },
    "Keys": {
      "Hanzi": 1465,
      "Word": 7497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "106.4",
      "Grade": 7,
      "GeneralStandard": 1465,
      "Radical": "白",
      "HSK": 7,
      "FrequencyRank": 1419,
      "StrokeCount": 9
    }
  },
  "剖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pōu",
      "PrimaryDefinition": "split in two, slice; dissect"
    },
    "Keys": {
      "Hanzi": 2042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.8",
      "GeneralStandard": 2042,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 2484,
      "StrokeCount": 10
    }
  },
  "序": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "series, serial order, sequence"
    },
    "Keys": {
      "Hanzi": 840,
      "Word": 10101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.4",
      "Grade": 7,
      "GeneralStandard": 840,
      "Radical": "广",
      "HSK": 4,
      "FrequencyRank": 836,
      "StrokeCount": 7
    }
  },
  "解析": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěxī",
      "PrimaryDefinition": "to analyze, to resolve, (math.) analysis, analytic"
    },
    "Keys": {
      "Word": 7543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晓得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎode",
      "PrimaryDefinition": "tell, know, ken"
    },
    "Keys": {
      "Word": 5173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "筏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "raft"
    },
    "Keys": {
      "Hanzi": 2686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2686,
      "Radical": "竹",
      "FrequencyRank": 3785,
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "仇恨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóuhèn",
      "PrimaryDefinition": "to hate, hatred, enmity, hostility"
    },
    "Keys": {
      "Word": 5934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "chronic disease, chronic illness; sorrow"
    },
    "Keys": {
      "Hanzi": 1215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1215,
      "Radical": "疒",
      "FrequencyRank": 3203,
      "RadicalIndex": "104.3",
      "StrokeCount": 8
    }
  },
  "联赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánsài",
      "PrimaryDefinition": "loop, league matches, league match"
    },
    "Keys": {
      "Word": 4824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "禚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "place name"
    },
    "Keys": {
      "Hanzi": 7740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7740,
      "Radical": "示",
      "RadicalIndex": "113.1",
      "StrokeCount": 14
    }
  },
  "瞳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "pupil of eye"
    },
    "Keys": {
      "Hanzi": 3404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3404,
      "Radical": "目",
      "FrequencyRank": 3733,
      "RadicalIndex": "109.12",
      "StrokeCount": 17
    }
  },
  "串": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàn",
      "PrimaryDefinition": "string; relatives; conspire"
    },
    "Keys": {
      "Hanzi": 736,
      "Word": 4447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "2.6",
      "Grade": 6,
      "GeneralStandard": 736,
      "Radical": "丨",
      "HSK": 6,
      "FrequencyRank": 1832,
      "StrokeCount": 7
    }
  },
  "畛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "border, boundary; raised path"
    },
    "Keys": {
      "Hanzi": 4506
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4506,
      "Radical": "田",
      "FrequencyRank": 5466,
      "RadicalIndex": "102.5",
      "StrokeCount": 10
    }
  },
  "㙦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7698,
      "Radical": "土",
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "大使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshǐ",
      "PrimaryDefinition": "elchee, embassador, ambassadorial, nuncio, ambassadorship, ambassador"
    },
    "Keys": {
      "Word": 4480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "对方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìfāng",
      "PrimaryDefinition": "other party, the other party, fellow, party, other side, adversary, other side/party"
    },
    "Keys": {
      "Word": 1424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "妆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāng",
      "PrimaryDefinition": "to adorn oneself, dress up, use make-up"
    },
    "Keys": {
      "Hanzi": 512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "GeneralStandard": 512,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2639,
      "StrokeCount": 6
    }
  },
  "跷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2914,
      "Radical": "足",
      "FrequencyRank": 3720,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "预": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "prepare, arrange; in advance"
    },
    "Keys": {
      "Hanzi": 2148
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.4",
      "GeneralStandard": 2148,
      "Radical": "頁",
      "HSK": 3,
      "FrequencyRank": 647,
      "StrokeCount": 10
    }
  },
  "边远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānyuǎn",
      "PrimaryDefinition": "far from the center, remote, outlying"
    },
    "Keys": {
      "Word": 5597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎn",
      "PrimaryDefinition": "draw back, fold back; collect"
    },
    "Keys": {
      "Hanzi": 2350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "66.7",
      "GeneralStandard": 2350,
      "Radical": "攴",
      "HSK": 8,
      "FrequencyRank": 2925,
      "StrokeCount": 11
    }
  },
  "擅自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànzì",
      "PrimaryDefinition": "without permission, of one's own initiative"
    },
    "Keys": {
      "Word": 8958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7507
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7507,
      "Radical": "石",
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "赶上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn shàng",
      "PrimaryDefinition": "to keep up with, to catch up with, to overtake, to chance upon, in time for"
    },
    "Keys": {
      "Word": 4585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùróng",
      "PrimaryDefinition": "must not, cannot, to not allow, cannot tolerate"
    },
    "Keys": {
      "Word": 5714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "mulberry fruit"
    },
    "Keys": {
      "Hanzi": 5080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5080,
      "Radical": "艸",
      "FrequencyRank": 5969,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "誊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "copy, transcribe"
    },
    "Keys": {
      "Hanzi": 2997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2997,
      "Radical": "言",
      "FrequencyRank": 4971,
      "RadicalIndex": "149.6",
      "StrokeCount": 13
    }
  },
  "苟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒu",
      "PrimaryDefinition": "careless, frivolous; illicit; grammatical particle: if, but, if only; surname; grass name; distinguish DKW 30853 (ji4)"
    },
    "Keys": {
      "Hanzi": 1007
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1007,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 2886,
      "StrokeCount": 8
    }
  },
  "竹子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhúzi",
      "PrimaryDefinition": "bamboo"
    },
    "Keys": {
      "Word": 4248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "记住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì zhu",
      "PrimaryDefinition": "to remember, to bear in mind, to learn by heart"
    },
    "Keys": {
      "Word": 164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "嵅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7363,
      "Radical": "山",
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "慢慢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànmàn",
      "PrimaryDefinition": "slowly; gradually"
    },
    "Keys": {
      "Word": 1729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "插手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā shǒu",
      "PrimaryDefinition": "to get involved in, to meddle, interference"
    },
    "Keys": {
      "Word": 5784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一块儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīkuàir",
      "PrimaryDefinition": "together"
    },
    "Keys": {
      "Word": 429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "情调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngdiào",
      "PrimaryDefinition": "sentiment, tone and mood, taste"
    },
    "Keys": {
      "Word": 8757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "活力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huólì",
      "PrimaryDefinition": "juvenility, sprightliness, pulse, youthfulness, stamina, bounciness, birr, sparkle, will, vigor, life, energy, vinegar, zap, vim, viridity, pride, livingness, vis, starch, get-up-and-go, mercury, vitality, zing, ginger, sap, pepper, zip, bang, verve, verdure, lifeblood, devilry, dynamism, nature, oomph, vigour, flush, punch, ambition, virility, life-blood, liveliness, pazazz, peppiness, pep, p"
    },
    "Keys": {
      "Word": 3565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "麻烦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máfan",
      "PrimaryDefinition": "trouble sb., bother"
    },
    "Keys": {
      "Word": 1726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "善良": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànliáng",
      "PrimaryDefinition": "good and kind, decent"
    },
    "Keys": {
      "Word": 2839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "豆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòu",
      "PrimaryDefinition": "bean"
    },
    "Keys": {
      "Radical": 151,
      "Hanzi": 696
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "151",
      "GeneralStandard": 696,
      "Radical": "豆",
      "HSK": 4,
      "FrequencyRank": 1793,
      "StrokeCount": 7
    }
  },
  "当地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngdì",
      "PrimaryDefinition": "locality, the place (named/mentioned/etc.)"
    },
    "Keys": {
      "Word": 1396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "眼界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnjiè",
      "PrimaryDefinition": "ken, scope"
    },
    "Keys": {
      "Word": 10194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèbié",
      "PrimaryDefinition": "unusual, specially"
    },
    "Keys": {
      "Word": 1015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "铊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "thallium"
    },
    "Keys": {
      "Hanzi": 4542
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4542,
      "Radical": "金",
      "FrequencyRank": 6491,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "康复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāngfù",
      "PrimaryDefinition": "to recuperate, to recover (health), to convalesce"
    },
    "Keys": {
      "Word": 4789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "意料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìliào",
      "PrimaryDefinition": "to anticipate, to expect"
    },
    "Keys": {
      "Word": 10373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": "large tie-beams"
    },
    "Keys": {
      "Hanzi": 4158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4158,
      "Radical": "木",
      "FrequencyRank": 6974,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "缵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǎn",
      "PrimaryDefinition": "continue, carry on, succeed"
    },
    "Keys": {
      "Hanzi": 6419
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6419,
      "Radical": "糸",
      "FrequencyRank": 6432,
      "RadicalIndex": "120.16",
      "StrokeCount": 19
    }
  },
  "地方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìfang",
      "PrimaryDefinition": "country, soil, terrain, part, clime, way, territory, room, neighbourhood, tinderbox, locality (as distinct from the central administrat, child, tract, province, position, respect, where, region, space, place, local"
    },
    "Keys": {
      "Word": 2405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "绿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "green; chlorine"
    },
    "Keys": {
      "Hanzi": 2498,
      "Word": 861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "Grade": 2,
      "GeneralStandard": 2498,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 1088,
      "StrokeCount": 11
    }
  },
  "猛烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měngliè",
      "PrimaryDefinition": "fierce, violent (criticism etc)"
    },
    "Keys": {
      "Word": 8253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "橘子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júzi",
      "PrimaryDefinition": "tangerine, CL:個|个[ge4],瓣[ban4]"
    },
    "Keys": {
      "Word": 7685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngshì",
      "PrimaryDefinition": "public affairs, official (matters, duties etc)"
    },
    "Keys": {
      "Word": 6774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "ammonia; hydrogen nitride"
    },
    "Keys": {
      "Hanzi": 1542
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1542,
      "Radical": "气",
      "FrequencyRank": 2637,
      "RadicalIndex": "84.5",
      "StrokeCount": 9
    }
  },
  "反馈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnkuì",
      "PrimaryDefinition": "to send back information, feedback"
    },
    "Keys": {
      "Word": 6473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zěn",
      "PrimaryDefinition": "what? why? how?"
    },
    "Keys": {
      "Hanzi": 1543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1543,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 382,
      "StrokeCount": 9
    }
  },
  "平息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngxī",
      "PrimaryDefinition": "to settle (a dispute), to quieten down, to suppress"
    },
    "Keys": {
      "Word": 8552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "那样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàyàng",
      "PrimaryDefinition": "that kind of, like that"
    },
    "Keys": {
      "Word": 883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "祀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "to sacrifice, worship"
    },
    "Keys": {
      "Hanzi": 891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.3",
      "GeneralStandard": 891,
      "Radical": "示",
      "HSK": 9,
      "FrequencyRank": 3123,
      "StrokeCount": 7
    }
  },
  "褟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "inner shirt or singlet"
    },
    "Keys": {
      "Hanzi": 6012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6012,
      "Radical": "衣",
      "RadicalIndex": "145.1",
      "StrokeCount": 15
    }
  },
  "劄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "brief note; official communique"
    },
    "Keys": {
      "Hanzi": 7692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7692,
      "Radical": "竹",
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "麻将": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "májiàng",
      "PrimaryDefinition": "mahjong, CL:副[fu4]"
    },
    "Keys": {
      "Word": 8177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "王牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wángpái",
      "PrimaryDefinition": "trump card"
    },
    "Keys": {
      "Word": 9673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "青春": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngchūn",
      "PrimaryDefinition": "springtime, flower, prime, juvenility, youthfulness, one's youth, young adulthood, youth, prime of life, youthhood, juvenescence, may"
    },
    "Keys": {
      "Word": 2806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": "color"
    },
    "Keys": {
      "Hanzi": 509,
      "Radical": 139,
      "Word": 2831
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "139",
      "Grade": 4,
      "GeneralStandard": 509,
      "Radical": "色",
      "HSK": 2,
      "FrequencyRank": 304,
      "StrokeCount": 6
    }
  },
  "漾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàng",
      "PrimaryDefinition": "overflow; swirl, ripple; to be tosssed by waves"
    },
    "Keys": {
      "Hanzi": 3166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "GeneralStandard": 3166,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3697,
      "StrokeCount": 14
    }
  },
  "兑现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìxiàn",
      "PrimaryDefinition": "(of a check etc) to cash, to honor a commitment"
    },
    "Keys": {
      "Word": 6387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "假设": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎshè",
      "PrimaryDefinition": "suppose, assume, presume"
    },
    "Keys": {
      "Word": 7360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "劢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mài",
      "PrimaryDefinition": "put forth effort, strive forward"
    },
    "Keys": {
      "Hanzi": 3537
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3537,
      "Radical": "力",
      "FrequencyRank": 5570,
      "RadicalIndex": "19.3",
      "StrokeCount": 5
    }
  },
  "泳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "dive; swim"
    },
    "Keys": {
      "Hanzi": 1257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1257,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 2607,
      "StrokeCount": 8
    }
  },
  "喧哗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānhuá",
      "PrimaryDefinition": "hubbub, clamor, to make a racket"
    },
    "Keys": {
      "Word": 10114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7017,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "这边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèbiān",
      "PrimaryDefinition": "this side, here"
    },
    "Keys": {
      "Word": 461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "向导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngdǎo",
      "PrimaryDefinition": "guide"
    },
    "Keys": {
      "Word": 4045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "余额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúé",
      "PrimaryDefinition": "vacancies yet to be filled, remains, balance, remaining sum"
    },
    "Keys": {
      "Word": 10494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": "cicada, broad locust"
    },
    "Keys": {
      "Hanzi": 5710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5710,
      "Radical": "虫",
      "FrequencyRank": 6754,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "紧紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnjǐn",
      "PrimaryDefinition": "closely, tightly"
    },
    "Keys": {
      "Word": 3623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "施压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīyā",
      "PrimaryDefinition": "to pressure"
    },
    "Keys": {
      "Word": 9099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大陆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàlù",
      "PrimaryDefinition": "Ch. mainland"
    },
    "Keys": {
      "Word": 2377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "同事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngshì",
      "PrimaryDefinition": "brother, coadjutor, workmate, friend, yokefellow, co-worker, fellow worker, confrere, colleague, stablemate, companion, crew, workfellow, staff, socius, coworker, bedfellow, fellow, haver, rival, comrade"
    },
    "Keys": {
      "Word": 1037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "高手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoshǒu",
      "PrimaryDefinition": "dab, master-hand, expert, master, facility, proficient, ace"
    },
    "Keys": {
      "Word": 4599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "挽回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn huí",
      "PrimaryDefinition": "retrieval, recover, retrieve, save, redeem, deliver"
    },
    "Keys": {
      "Word": 9654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一路上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlùshàng",
      "PrimaryDefinition": "All the way"
    },
    "Keys": {
      "Word": 5235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "开场白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāichǎngbái",
      "PrimaryDefinition": "prologue of play, opening remarks, preamble (of speeches, articles etc)"
    },
    "Keys": {
      "Word": 7743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "博客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bókè",
      "PrimaryDefinition": "blog (loanword), weblog, blogger"
    },
    "Keys": {
      "Word": 3263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "弢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "bow case; scabbard; sheathe"
    },
    "Keys": {
      "Hanzi": 6734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6734,
      "Radical": "弓",
      "RadicalIndex": "57.5",
      "StrokeCount": 8
    }
  },
  "莒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "hemp-like plant; taro; herb"
    },
    "Keys": {
      "Hanzi": 4113
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4113,
      "Radical": "艸",
      "FrequencyRank": 5148,
      "RadicalIndex": "140.7",
      "StrokeCount": 9
    }
  },
  "伴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "companion, comrade, partner; accompany"
    },
    "Keys": {
      "Hanzi": 785,
      "Word": 5499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 7,
      "GeneralStandard": 785,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 1298,
      "StrokeCount": 7
    }
  },
  "引擎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnqíng",
      "PrimaryDefinition": "engine"
    },
    "Keys": {
      "Word": 10389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "充": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "fill, be full, supply"
    },
    "Keys": {
      "Hanzi": 525,
      "Word": 5916
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.4",
      "Grade": 7,
      "GeneralStandard": 525,
      "Radical": "儿",
      "HSK": 3,
      "FrequencyRank": 690,
      "StrokeCount": 6
    }
  },
  "诋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "slander, comdemn, reproach"
    },
    "Keys": {
      "Hanzi": 3797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3797,
      "Radical": "言",
      "FrequencyRank": 4464,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "概论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàilùn",
      "PrimaryDefinition": "outline, introduction, survey, general discussion"
    },
    "Keys": {
      "Word": 6663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矛头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máotóu",
      "PrimaryDefinition": "spearhead, barb, an attack or criticism"
    },
    "Keys": {
      "Word": 8211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "sacrficial victim; gift; grain"
    },
    "Keys": {
      "Hanzi": 3751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3751,
      "Radical": "食",
      "FrequencyRank": 5163,
      "RadicalIndex": "184.4",
      "StrokeCount": 7
    }
  },
  "上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng",
      "PrimaryDefinition": "top; superior, highest; go up, send up"
    },
    "Keys": {
      "Hanzi": 36,
      "Word": 310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.2",
      "Grade": 1,
      "GeneralStandard": 36,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 16,
      "StrokeCount": 3
    }
  },
  "纲领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānglǐng",
      "PrimaryDefinition": "summary, creed, programme, political program, political platform, guiding principle, digest, outline, program, platform"
    },
    "Keys": {
      "Word": 6684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "a low-built house"
    },
    "Keys": {
      "Hanzi": 7222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7222,
      "Radical": "广",
      "FrequencyRank": 7254,
      "RadicalIndex": "53.8",
      "StrokeCount": 11
    }
  },
  "孺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "child; blood relation; affection"
    },
    "Keys": {
      "Hanzi": 6304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6304,
      "Radical": "子",
      "FrequencyRank": 4084,
      "RadicalIndex": "39.14",
      "StrokeCount": 17
    }
  },
  "直到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhídào",
      "PrimaryDefinition": "until, up to"
    },
    "Keys": {
      "Word": 2161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐ",
      "PrimaryDefinition": "this, these; in this case, then"
    },
    "Keys": {
      "Hanzi": 411,
      "Word": 2348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "77.2",
      "Grade": 4,
      "GeneralStandard": 411,
      "Radical": "止",
      "HSK": 3,
      "FrequencyRank": 116,
      "StrokeCount": 6
    }
  },
  "元首": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánshǒu",
      "PrimaryDefinition": "head of state"
    },
    "Keys": {
      "Word": 10536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饲养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìyǎng",
      "PrimaryDefinition": "raise (animals/etc.)"
    },
    "Keys": {
      "Word": 9331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "phosphorus; water rushing between"
    },
    "Keys": {
      "Hanzi": 3398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3398,
      "Radical": "石",
      "FrequencyRank": 2956,
      "RadicalIndex": "112.12",
      "StrokeCount": 17
    }
  },
  "促销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cùxiāo",
      "PrimaryDefinition": "merchandise, sell"
    },
    "Keys": {
      "Word": 2356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "自我": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìwǒ",
      "PrimaryDefinition": "self, oneself"
    },
    "Keys": {
      "Word": 5395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "汜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "a stream which leaves the main branch then later returns"
    },
    "Keys": {
      "Hanzi": 3627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3627,
      "Radical": "水",
      "FrequencyRank": 5816,
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "ball, sphere, globe; round"
    },
    "Keys": {
      "Hanzi": 2155,
      "Word": 298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96.7",
      "Grade": 1,
      "GeneralStandard": 2155,
      "Radical": "玉",
      "HSK": 1,
      "FrequencyRank": 628,
      "StrokeCount": 11
    }
  },
  "戚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "relative; be related to; sad"
    },
    "Keys": {
      "Hanzi": 2239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "62.7",
      "GeneralStandard": 2239,
      "Radical": "戈",
      "HSK": 7,
      "FrequencyRank": 2316,
      "StrokeCount": 11
    }
  },
  "男性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánxìng",
      "PrimaryDefinition": "brother, male, him, man, male sex, adult male, mankind, masculine, mother's son, masculinity"
    },
    "Keys": {
      "Word": 3737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "对应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìyìng",
      "PrimaryDefinition": "correspondence, harmonize, map, homologous, corresponding, mapping, agree, reciprocal, counterpart, represent, stand for, accord, equate, homologize, correspond"
    },
    "Keys": {
      "Word": 3436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "耷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dā",
      "PrimaryDefinition": "ears hanging down"
    },
    "Keys": {
      "Hanzi": 4172
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4172,
      "Radical": "耳",
      "FrequencyRank": 4120,
      "RadicalIndex": "128.3",
      "StrokeCount": 9
    }
  },
  "认可": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènkě",
      "PrimaryDefinition": "to approve, approval, acknowledgment, OK"
    },
    "Keys": {
      "Word": 1853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "足以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zúyǐ",
      "PrimaryDefinition": "sufficient/enough to"
    },
    "Keys": {
      "Word": 5408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "颀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "tall and slim"
    },
    "Keys": {
      "Hanzi": 4583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4583,
      "Radical": "斤",
      "FrequencyRank": 5062,
      "RadicalIndex": "69.6",
      "StrokeCount": 10
    }
  },
  "比比皆是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐbǐ-jiēshì",
      "PrimaryDefinition": "can be found everywhere"
    },
    "Keys": {
      "Word": 5579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "势力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìli",
      "PrimaryDefinition": "strength, influence, potency, weight, hold, leverage, yoke, sway, force, steam, presence, power, puissance"
    },
    "Keys": {
      "Word": 3908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "推辞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīcí",
      "PrimaryDefinition": "to decline (an appointment, invitation etc)"
    },
    "Keys": {
      "Word": 9595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摔跤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāi jiāo",
      "PrimaryDefinition": "to trip and fall, to wrestle, wrestling (sports)"
    },
    "Keys": {
      "Word": 9246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎng",
      "PrimaryDefinition": "lie down, recline"
    },
    "Keys": {
      "Hanzi": 3270,
      "Word": 2918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "158.8",
      "Grade": 4,
      "GeneralStandard": 3270,
      "Radical": "身",
      "HSK": 4,
      "FrequencyRank": 1608,
      "StrokeCount": 15
    }
  },
  "冷酷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngkù",
      "PrimaryDefinition": "grim, unfeeling, callous"
    },
    "Keys": {
      "Word": 7966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "flood; overflowing"
    },
    "Keys": {
      "Hanzi": 3768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3768,
      "Radical": "水",
      "FrequencyRank": 6433,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "忧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "sad, grieved; grief, melancholy"
    },
    "Keys": {
      "Hanzi": 874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "GeneralStandard": 874,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1461,
      "StrokeCount": 7
    }
  },
  "立足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì zú",
      "PrimaryDefinition": "to stand, to have a footing, to be established, to base oneself on"
    },
    "Keys": {
      "Word": 8004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "规格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīgé",
      "PrimaryDefinition": "quality requirements, requirement, specification, standard, spec, criterion, measure, touchstone, specifications"
    },
    "Keys": {
      "Word": 6916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "beak, bill, snout; pant"
    },
    "Keys": {
      "Hanzi": 5162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5162,
      "Radical": "口",
      "FrequencyRank": 3990,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "难说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánshuō",
      "PrimaryDefinition": "Hard to say"
    },
    "Keys": {
      "Word": 8382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "混浊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùnzhuó",
      "PrimaryDefinition": "turbid, muddy, dirty"
    },
    "Keys": {
      "Word": 7233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巫婆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūpó",
      "PrimaryDefinition": "witch, sorceress, female shaman"
    },
    "Keys": {
      "Word": 9756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lài",
      "PrimaryDefinition": "rely, depend on; accuse falsely"
    },
    "Keys": {
      "Hanzi": 2872,
      "Word": 4807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.9",
      "Grade": 6,
      "GeneralStandard": 2872,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 1365,
      "StrokeCount": 13
    }
  },
  "癃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "weakness, infirmity retention of urine"
    },
    "Keys": {
      "Hanzi": 6159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6159,
      "Radical": "疒",
      "FrequencyRank": 6904,
      "RadicalIndex": "104.12",
      "StrokeCount": 16
    }
  },
  "路人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùrén",
      "PrimaryDefinition": "passer-by, stranger"
    },
    "Keys": {
      "Word": 8134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "横": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": "across"
    },
    "Keys": {
      "Hanzi": 3213,
      "Word": 7069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.11",
      "Grade": 7,
      "GeneralStandard": 3213,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1330,
      "StrokeCount": 15
    }
  },
  "褐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "coarse woolen cloth; dull, dark"
    },
    "Keys": {
      "Hanzi": 3179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3179,
      "Radical": "衣",
      "FrequencyRank": 2654,
      "RadicalIndex": "145.9",
      "StrokeCount": 14
    }
  },
  "消息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāoxi",
      "PrimaryDefinition": "communication, witting, message, item, news, peep, info, intelligence, notice, information, advertisement, tidings, advert, word"
    },
    "Keys": {
      "Word": 2048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "逗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòu",
      "PrimaryDefinition": "tempt, allure, arouse, stir"
    },
    "Keys": {
      "Hanzi": 1854,
      "Word": 6353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 7,
      "GeneralStandard": 1854,
      "Radical": "辵",
      "HSK": 7,
      "FrequencyRank": 2587,
      "StrokeCount": 10
    }
  },
  "圪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3572
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3572,
      "Radical": "土",
      "FrequencyRank": 4139,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "贝壳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèiké",
      "PrimaryDefinition": "cowrie, shell, cameo, conch, seashell, valve, clamshell"
    },
    "Keys": {
      "Word": 5553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "附近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùjìn",
      "PrimaryDefinition": "neighborship, neighborhood, precinct, vicinity, neighbourhood"
    },
    "Keys": {
      "Word": 2481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "酶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "enzymes; (Cant.) soft"
    },
    "Keys": {
      "Hanzi": 5673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5673,
      "Radical": "酉",
      "FrequencyRank": 3058,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "亹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mén",
      "PrimaryDefinition": "progress; busy, untiring; exert"
    },
    "Keys": {
      "Hanzi": 8097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8097,
      "Radical": "亠",
      "RadicalIndex": "8.19",
      "StrokeCount": 22
    }
  },
  "龇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "to show the teeth; crooked teeth"
    },
    "Keys": {
      "Hanzi": 5690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5690,
      "Radical": "齒",
      "FrequencyRank": 4525,
      "RadicalIndex": "211.6",
      "StrokeCount": 14
    }
  },
  "珏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "two pieces of jade joined together"
    },
    "Keys": {
      "Hanzi": 4084
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4084,
      "Radical": "玉",
      "FrequencyRank": 5068,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "拨款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōkuǎn",
      "PrimaryDefinition": "to allocate funds, appropriation"
    },
    "Keys": {
      "Word": 5658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "请": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng",
      "PrimaryDefinition": "ask, request; invite; please"
    },
    "Keys": {
      "Hanzi": 2108,
      "Word": 292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "Grade": 1,
      "GeneralStandard": 2108,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 421,
      "StrokeCount": 10
    }
  },
  "新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "new, recent, fresh, modern"
    },
    "Keys": {
      "Hanzi": 2994,
      "Word": 408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "69.9",
      "Grade": 1,
      "GeneralStandard": 2994,
      "Radical": "斤",
      "HSK": 1,
      "FrequencyRank": 161,
      "StrokeCount": 13
    }
  },
  "评委": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngwěi",
      "PrimaryDefinition": "evaluation committee, judging panel, judging panel member, adjudicator, abbr. for 評選委員會委員|评选委员会委员[ping2 xuan3 wei3 yuan2 hui4 wei3 yuan2]"
    },
    "Keys": {
      "Word": 8560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "string; hypotenuse, crescent"
    },
    "Keys": {
      "Hanzi": 1314,
      "Word": 9890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.5",
      "Grade": 7,
      "GeneralStandard": 1314,
      "Radical": "弓",
      "HSK": 8,
      "FrequencyRank": 2258,
      "StrokeCount": 8
    }
  },
  "於": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "in, at, on; interjection alas!"
    },
    "Keys": {
      "Hanzi": 6716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6716,
      "Radical": "方",
      "FrequencyRank": 2025,
      "RadicalIndex": "70.4",
      "StrokeCount": 8
    }
  },
  "瞽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "blind; blind musician; stupid"
    },
    "Keys": {
      "Hanzi": 6314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6314,
      "Radical": "目",
      "FrequencyRank": 5909,
      "RadicalIndex": "109.13",
      "StrokeCount": 18
    }
  },
  "禋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "offer sacrifice; sacrifice"
    },
    "Keys": {
      "Hanzi": 7604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7604,
      "Radical": "示",
      "FrequencyRank": 7245,
      "RadicalIndex": "113.9",
      "StrokeCount": 13
    }
  },
  "难以置信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nányǐzhìxìn",
      "PrimaryDefinition": "hard to believe, incredible"
    },
    "Keys": {
      "Word": 8384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2231,
      "Radical": "木",
      "FrequencyRank": 2681,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "孓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "beautiful"
    },
    "Keys": {
      "Hanzi": 3506
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3506,
      "Radical": "子",
      "FrequencyRank": 6370,
      "RadicalIndex": "39",
      "StrokeCount": 3
    }
  },
  "源头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuántóu",
      "PrimaryDefinition": "source, fountainhead"
    },
    "Keys": {
      "Word": 10550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "必要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìyào",
      "PrimaryDefinition": "necessary, indispensable"
    },
    "Keys": {
      "Word": 1301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "牥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6692,
      "Radical": "牛",
      "RadicalIndex": "93.4",
      "StrokeCount": 8
    }
  },
  "丹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "cinnabar (native HgS); vermilion (artificial HgS used as pigment)"
    },
    "Keys": {
      "Hanzi": 155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "3.3",
      "GeneralStandard": 155,
      "Radical": "丶",
      "HSK": 9,
      "FrequencyRank": 1280,
      "StrokeCount": 4
    }
  },
  "功力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnglì",
      "PrimaryDefinition": "effect, efficacy, craftsmanship, skill, capability, touch"
    },
    "Keys": {
      "Word": 6787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎohuà",
      "PrimaryDefinition": "to age (of person or object), becoming old"
    },
    "Keys": {
      "Word": 7948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "解散": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěsàn",
      "PrimaryDefinition": "disperse, dismiss, separate, disband, disincorporate, demobilize, disbandment, dissolve, usher out, let, unmerge, dismissal, break_up, demob, dissolution, break up"
    },
    "Keys": {
      "Word": 7539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "画儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàr5",
      "PrimaryDefinition": "picture, drawing, painting"
    },
    "Keys": {
      "Word": 729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "淠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4972,
      "Radical": "水",
      "FrequencyRank": 6341,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "泄气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xièqì",
      "PrimaryDefinition": "to leak (gas), to be discouraged, to despair, (disparaging) pathetic, to vent one's anger, (of a tire) to be flat"
    },
    "Keys": {
      "Word": 9997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎn",
      "PrimaryDefinition": "ammonium"
    },
    "Keys": {
      "Hanzi": 4886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4886,
      "Radical": "金",
      "FrequencyRank": 5095,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "之一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīyī",
      "PrimaryDefinition": "one of (sth), one out of a multitude, one (third, quarter, percent etc)"
    },
    "Keys": {
      "Word": 3170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "埼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "headland"
    },
    "Keys": {
      "Hanzi": 7109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7109,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "紫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "purple, violet; amethyst; surname"
    },
    "Keys": {
      "Hanzi": 2604,
      "Word": 4275
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 5,
      "GeneralStandard": 2604,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 1646,
      "StrokeCount": 12
    }
  },
  "狐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "species of fox"
    },
    "Keys": {
      "Hanzi": 1196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1196,
      "Radical": "犬",
      "FrequencyRank": 2321,
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "犹豫不决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuyù-bùjué",
      "PrimaryDefinition": "hesitancy, indecision, to waver"
    },
    "Keys": {
      "Word": 10464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "brush"
    },
    "Keys": {
      "Radical": 129,
      "Hanzi": 3635
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3635,
      "Radical": "聿",
      "FrequencyRank": 3526,
      "RadicalIndex": "129",
      "StrokeCount": 6
    }
  },
  "肼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "hydrazine"
    },
    "Keys": {
      "Hanzi": 3977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3977,
      "Radical": "肉",
      "FrequencyRank": 5202,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "潜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "hide; hidden, secret, latent"
    },
    "Keys": {
      "Hanzi": 3290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 3290,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1112,
      "StrokeCount": 15
    }
  },
  "动用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngyòng",
      "PrimaryDefinition": "put to use, draw on, put ... to use, use, employ, employment"
    },
    "Keys": {
      "Word": 6340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "slave"
    },
    "Keys": {
      "Radical": 171,
      "Hanzi": 1305
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "171",
      "GeneralStandard": 1305,
      "Radical": "隶",
      "HSK": 7,
      "FrequencyRank": 1801,
      "StrokeCount": 8
    }
  },
  "姞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "concubine"
    },
    "Keys": {
      "Hanzi": 6911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6911,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "考虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎolǜ",
      "PrimaryDefinition": "think over, consider"
    },
    "Keys": {
      "Word": 2660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "璐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "beautiful variety of jade"
    },
    "Keys": {
      "Hanzi": 6197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6197,
      "Radical": "玉",
      "FrequencyRank": 3810,
      "RadicalIndex": "96.12",
      "StrokeCount": 17
    }
  },
  "赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sài",
      "PrimaryDefinition": "compete, contend; contest, race"
    },
    "Keys": {
      "Hanzi": 3172,
      "Word": 4984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.11",
      "Grade": 6,
      "GeneralStandard": 3172,
      "Radical": "宀",
      "HSK": 3,
      "FrequencyRank": 1064,
      "StrokeCount": 14
    }
  },
  "直径": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíjìng",
      "PrimaryDefinition": "diameter"
    },
    "Keys": {
      "Word": 10760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "错觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòjué",
      "PrimaryDefinition": "misconception, illusion, misperception"
    },
    "Keys": {
      "Word": 6090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7108
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7108,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "采纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎinà",
      "PrimaryDefinition": "to accept, to adopt"
    },
    "Keys": {
      "Word": 4363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "瑷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "fine quality jade"
    },
    "Keys": {
      "Hanzi": 7625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7625,
      "Radical": "玉",
      "FrequencyRank": 5936,
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "追逐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīzhú",
      "PrimaryDefinition": "pursue, chase, seek"
    },
    "Keys": {
      "Word": 10928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "銎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "eyehole to hang an axe by"
    },
    "Keys": {
      "Hanzi": 7634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7634,
      "Radical": "金",
      "FrequencyRank": 5842,
      "RadicalIndex": "167.6",
      "StrokeCount": 14
    }
  },
  "竞赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngsài",
      "PrimaryDefinition": "contest, competition"
    },
    "Keys": {
      "Word": 3631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "极限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíxiàn",
      "PrimaryDefinition": "breaking point, level best, utmost, limit/maximum, maximum, bounds, extreme, limitation, outside, boundary, uttermost, breaking_point, extremity, upper limit, limit"
    },
    "Keys": {
      "Word": 7297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "protactinium (Pa)"
    },
    "Keys": {
      "Hanzi": 7938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7938,
      "Radical": "金",
      "FrequencyRank": 7837,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "瘟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēn",
      "PrimaryDefinition": "epidemic, plague, pestilence"
    },
    "Keys": {
      "Hanzi": 3146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.1",
      "GeneralStandard": 3146,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 3285,
      "StrokeCount": 14
    }
  },
  "貌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "countenance, appearance"
    },
    "Keys": {
      "Hanzi": 3131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "153.7",
      "GeneralStandard": 3131,
      "Radical": "豸",
      "HSK": 5,
      "FrequencyRank": 1595,
      "StrokeCount": 14
    }
  },
  "委": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "appoint, send, commission"
    },
    "Keys": {
      "Hanzi": 1133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1133,
      "Radical": "女",
      "HSK": 5,
      "FrequencyRank": 457,
      "StrokeCount": 8
    }
  },
  "递交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìjiāo",
      "PrimaryDefinition": "to present, to give, to hand over, to hand in, to lay before"
    },
    "Keys": {
      "Word": 6259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "考核": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎohé",
      "PrimaryDefinition": "to examine, to check up on, to assess, to review, appraisal, review, evaluation"
    },
    "Keys": {
      "Word": 3651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "弘扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngyáng",
      "PrimaryDefinition": "to enhance, to promote, to enrich"
    },
    "Keys": {
      "Word": 7077
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēng",
      "PrimaryDefinition": "syllable; (Cant.) to chase, drive away"
    },
    "Keys": {
      "Hanzi": 5905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5905,
      "Radical": "口",
      "FrequencyRank": 4443,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "赢得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngdé",
      "PrimaryDefinition": "nail down, rake_in, carry_off, nail, peg, conquer, try_for, gain, turn, win, garner, claim, earn, carry, capture, take, notch, nobble"
    },
    "Keys": {
      "Word": 3110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "璺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèn",
      "PrimaryDefinition": "a crack, as in porcelain"
    },
    "Keys": {
      "Hanzi": 6439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6439,
      "Radical": "玉",
      "FrequencyRank": 6356,
      "RadicalIndex": "96.14",
      "StrokeCount": 20
    }
  },
  "需": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "need, require, must"
    },
    "Keys": {
      "Hanzi": 3095,
      "Word": 10098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.6",
      "Grade": 7,
      "GeneralStandard": 3095,
      "Radical": "雨",
      "HSK": 3,
      "FrequencyRank": 408,
      "StrokeCount": 14
    }
  },
  "十": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "ten"
    },
    "Keys": {
      "Hanzi": 4,
      "Radical": 24,
      "Word": 327
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "24",
      "Grade": 1,
      "GeneralStandard": 4,
      "Radical": "十",
      "HSK": 1,
      "FrequencyRank": 112,
      "StrokeCount": 2
    }
  },
  "停业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng yè",
      "PrimaryDefinition": "to cease trading (temporarily or permanently), to close down"
    },
    "Keys": {
      "Word": 9520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "销毁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāohuǐ",
      "PrimaryDefinition": "to destroy (by melting or burning), to obliterate"
    },
    "Keys": {
      "Word": 9961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8096,
      "Radical": "魚",
      "FrequencyRank": 7298,
      "RadicalIndex": "195.14",
      "StrokeCount": 22
    }
  },
  "窣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sū",
      "PrimaryDefinition": "to rush out of a den; rustling, whispering"
    },
    "Keys": {
      "Hanzi": 5595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5595,
      "Radical": "穴",
      "FrequencyRank": 4907,
      "RadicalIndex": "116.8",
      "StrokeCount": 13
    }
  },
  "国防": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guófáng",
      "PrimaryDefinition": "national defence, national defense"
    },
    "Keys": {
      "Word": 6930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "tool, implement; draw up, write"
    },
    "Keys": {
      "Hanzi": 1077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "12.6",
      "GeneralStandard": 1077,
      "Radical": "八",
      "HSK": 3,
      "FrequencyRank": 391,
      "StrokeCount": 8
    }
  },
  "腐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "rot, decay, spoil; rotten"
    },
    "Keys": {
      "Hanzi": 3144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.8",
      "GeneralStandard": 3144,
      "Radical": "肉",
      "HSK": 4,
      "FrequencyRank": 1576,
      "StrokeCount": 14
    }
  },
  "案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "table, bench; legal case"
    },
    "Keys": {
      "Hanzi": 2107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 2107,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 518,
      "StrokeCount": 10
    }
  },
  "进化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnhuà",
      "PrimaryDefinition": "evolution, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "任务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènwu",
      "PrimaryDefinition": "imperative, assignment, cue, duty assignment, task, service, chore, labor, errand, job, lookout, undertaking, mission, military mission, duty, project, role"
    },
    "Keys": {
      "Word": 1858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "整数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngshù",
      "PrimaryDefinition": "whole number, integer (math.), round figure"
    },
    "Keys": {
      "Word": 10737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiā",
      "PrimaryDefinition": "hold; gather with hand; choke"
    },
    "Keys": {
      "Hanzi": 2181,
      "Word": 8637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2181,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 3494,
      "StrokeCount": 11
    }
  },
  "吨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": "metric ton; (Cant.) to babble, gibberish"
    },
    "Keys": {
      "Hanzi": 730,
      "Word": 3438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 5,
      "GeneralStandard": 730,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1700,
      "StrokeCount": 7
    }
  },
  "埆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "stony"
    },
    "Keys": {
      "Hanzi": 6945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6945,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "场面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngmiàn",
      "PrimaryDefinition": "occasion, tableaux, locale, front, theatre, appearance, facade, theater, scene, spectacle, scope"
    },
    "Keys": {
      "Word": 3316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "replace, replacement (of person or generation); era, generation"
    },
    "Keys": {
      "Hanzi": 271,
      "Word": 1387
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "Grade": 3,
      "GeneralStandard": 271,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 174,
      "StrokeCount": 5
    }
  },
  "晚上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnshang",
      "PrimaryDefinition": "evening, night, CL:個|个[ge4], in the evening"
    },
    "Keys": {
      "Word": 370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "寡妇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎfu",
      "PrimaryDefinition": "widow"
    },
    "Keys": {
      "Word": 6852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "编": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "knit, weave; arrange; compile"
    },
    "Keys": {
      "Hanzi": 2822,
      "Word": 2269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "Grade": 4,
      "GeneralStandard": 2822,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 858,
      "StrokeCount": 12
    }
  },
  "鬥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòu",
      "PrimaryDefinition": "fight"
    },
    "Keys": {
      "Radical": 191
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "牿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7188,
      "Radical": "牛",
      "FrequencyRank": 8506,
      "RadicalIndex": "93.7",
      "StrokeCount": 11
    }
  },
  "以后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐhòu",
      "PrimaryDefinition": "afterwards, later, future, in the future, hereafter"
    },
    "Keys": {
      "Word": 1143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "笑容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoróng",
      "PrimaryDefinition": "grinning, smiling expression, laugh, smiling, a smile, a smiling face, smile, grin"
    },
    "Keys": {
      "Word": 5174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "靠拢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàolǒng",
      "PrimaryDefinition": "to draw close to"
    },
    "Keys": {
      "Word": 7785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "若干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruògān",
      "PrimaryDefinition": "det.: a certain number, how many"
    },
    "Keys": {
      "Word": 8910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wā",
      "PrimaryDefinition": "dig, dig out, gouge out, scoop"
    },
    "Keys": {
      "Hanzi": 1394,
      "Word": 5114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 6,
      "GeneralStandard": 1394,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1860,
      "StrokeCount": 9
    }
  },
  "冽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "cold and raw; pure, clear"
    },
    "Keys": {
      "Hanzi": 3994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3994,
      "Radical": "冫",
      "FrequencyRank": 4356,
      "RadicalIndex": "15.6",
      "StrokeCount": 8
    }
  },
  "觳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "an ancient measure; a goblet; mean; frightened"
    },
    "Keys": {
      "Hanzi": 6202
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6202,
      "Radical": "角",
      "FrequencyRank": 5575,
      "RadicalIndex": "148.1",
      "StrokeCount": 17
    }
  },
  "花瓣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huābàn",
      "PrimaryDefinition": "petal, CL:片[pian4]"
    },
    "Keys": {
      "Word": 7125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "toward, direction, trend"
    },
    "Keys": {
      "Hanzi": 480,
      "Word": 1087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 2,
      "GeneralStandard": 480,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 146,
      "StrokeCount": 6
    }
  },
  "戏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "theatrical play, show"
    },
    "Keys": {
      "Hanzi": 584,
      "Word": 4027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.2",
      "Grade": 5,
      "GeneralStandard": 584,
      "Radical": "戈",
      "HSK": 3,
      "FrequencyRank": 1033,
      "StrokeCount": 6
    }
  },
  "万古长青": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngǔ-chángqīng",
      "PrimaryDefinition": "remain fresh, last forever, eternal"
    },
    "Keys": {
      "Word": 9661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酒楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔlóu",
      "PrimaryDefinition": "restaurant"
    },
    "Keys": {
      "Word": 7662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妹妹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèimei",
      "PrimaryDefinition": "little_sister, sis, younger sister, little sister, sister"
    },
    "Keys": {
      "Word": 234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "细胞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìbāo",
      "PrimaryDefinition": "cell"
    },
    "Keys": {
      "Word": 5156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "航运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángyùn",
      "PrimaryDefinition": "shipping, transport"
    },
    "Keys": {
      "Word": 6997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "a pennant, flag, banner; to signal to"
    },
    "Keys": {
      "Hanzi": 5980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5980,
      "Radical": "麻",
      "FrequencyRank": 4353,
      "RadicalIndex": "200.4",
      "StrokeCount": 15
    }
  },
  "汇合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìhé",
      "PrimaryDefinition": "confluence, to converge, to join, to fuse, fusion"
    },
    "Keys": {
      "Word": 7215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "praise, commend, honor, cite"
    },
    "Keys": {
      "Hanzi": 3280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3280,
      "Radical": "衣",
      "FrequencyRank": 3539,
      "RadicalIndex": "145.9",
      "StrokeCount": 15
    }
  },
  "䃅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "(simplified form 磾) dyestuff (black colored mineral)"
    },
    "Keys": {
      "Hanzi": 7508
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7508,
      "Radical": "石",
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "时常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shícháng",
      "PrimaryDefinition": "now_and_again, always, frequently, from_time_to_time, somewhile, oft, often"
    },
    "Keys": {
      "Word": 3898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng",
      "PrimaryDefinition": "hear; understand; obey, comply"
    },
    "Keys": {
      "Hanzi": 739,
      "Word": 361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 1,
      "GeneralStandard": 739,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 285,
      "StrokeCount": 7
    }
  },
  "扛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "káng",
      "PrimaryDefinition": "carry on shoulders; lift"
    },
    "Keys": {
      "Hanzi": 354,
      "Word": 7778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "Grade": 7,
      "GeneralStandard": 354,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2989,
      "StrokeCount": 6
    }
  },
  "岛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo",
      "PrimaryDefinition": "island"
    },
    "Keys": {
      "Hanzi": 820,
      "Word": 4494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "46.4",
      "Grade": 6,
      "GeneralStandard": 820,
      "Radical": "山",
      "HSK": 6,
      "FrequencyRank": 798,
      "StrokeCount": 7
    }
  },
  "鞔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "sides or uppers of shoes to stretch a skin on a frame for a drum; (Cant.) to cover"
    },
    "Keys": {
      "Hanzi": 7839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7839,
      "Radical": "革",
      "FrequencyRank": 5912,
      "RadicalIndex": "177.7",
      "StrokeCount": 16
    }
  },
  "带路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài lù",
      "PrimaryDefinition": "to lead the way, to guide, to show the way, fig. to instruct"
    },
    "Keys": {
      "Word": 6148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "薄弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bóruò",
      "PrimaryDefinition": "weak, frail"
    },
    "Keys": {
      "Word": 3270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "难点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nándiǎn",
      "PrimaryDefinition": "difficulty"
    },
    "Keys": {
      "Word": 8377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "懵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "stupid, ignorant, dull"
    },
    "Keys": {
      "Hanzi": 6363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6363,
      "Radical": "心",
      "FrequencyRank": 3823,
      "RadicalIndex": "61.16",
      "StrokeCount": 18
    }
  },
  "歅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7502
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7502,
      "Radical": "欠",
      "RadicalIndex": "76.9",
      "StrokeCount": 13
    }
  },
  "药水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàoshuǐ",
      "PrimaryDefinition": "Yaksu in North Korea, near the border with Liaoning and Jiling province, medicine in liquid form, bottled medicine, lotion"
    },
    "Keys": {
      "Word": 1131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "珇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6759,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "储": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": "save money, store, reserve; heir"
    },
    "Keys": {
      "Hanzi": 2697
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.1",
      "GeneralStandard": 2697,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1526,
      "StrokeCount": 12
    }
  },
  "艰巨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānjù",
      "PrimaryDefinition": "arduous, terrible (task), very difficult, formidable"
    },
    "Keys": {
      "Word": 7385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "pear; opera; cut, slash"
    },
    "Keys": {
      "Hanzi": 2313,
      "Word": 3680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "Grade": 5,
      "GeneralStandard": 2313,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 3004,
      "StrokeCount": 11
    }
  },
  "头脑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóunǎo",
      "PrimaryDefinition": "pericranium, leader, head, noggin, brain, brains, main threads, clue, nous, loaf, bean, psyche, headpiece, mind, pate, skull"
    },
    "Keys": {
      "Word": 1979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "意识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìshi",
      "PrimaryDefinition": "consciousness, witting, sensibility, conscious, conscience, ideology, awareness, subconscious, mentality"
    },
    "Keys": {
      "Word": 4117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "免不了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎnbuliǎo",
      "PrimaryDefinition": "unavoidably"
    },
    "Keys": {
      "Word": 8276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㙍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7118,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "窍门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàomén",
      "PrimaryDefinition": "a trick, an ingenious method, know-how, the knack (of doing sth)"
    },
    "Keys": {
      "Word": 8703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "millet wine"
    },
    "Keys": {
      "Hanzi": 6966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6966,
      "Radical": "酉",
      "RadicalIndex": "164.3",
      "StrokeCount": 10
    }
  },
  "要害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàohài",
      "PrimaryDefinition": "vital part, (fig.) key point, crucial"
    },
    "Keys": {
      "Word": 10246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "同伙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tónghuǒ",
      "PrimaryDefinition": "colleague, co-conspirator, accomplice"
    },
    "Keys": {
      "Word": 9534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "sly, cunning, shrewd; artful"
    },
    "Keys": {
      "Hanzi": 6340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6340,
      "Radical": "黑",
      "FrequencyRank": 4160,
      "RadicalIndex": "203.6",
      "StrokeCount": 18
    }
  },
  "峨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "lofty"
    },
    "Keys": {
      "Hanzi": 1920
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1920,
      "Radical": "山",
      "FrequencyRank": 3063,
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "反过来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnguolai",
      "PrimaryDefinition": "conversely, in reverse order, in an opposite direction"
    },
    "Keys": {
      "Word": 6471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "媲美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìměi",
      "PrimaryDefinition": "to match, is comparable with"
    },
    "Keys": {
      "Word": 8514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "简短": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnduǎn",
      "PrimaryDefinition": "brief, short-duration"
    },
    "Keys": {
      "Word": 7409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kài",
      "PrimaryDefinition": "anger, wrath, hatred, enmity"
    },
    "Keys": {
      "Hanzi": 3787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3787,
      "Radical": "心",
      "FrequencyRank": 5024,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "遢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "careless, negligent, slipshod"
    },
    "Keys": {
      "Hanzi": 5450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5450,
      "Radical": "辵",
      "FrequencyRank": 5040,
      "RadicalIndex": "162.1",
      "StrokeCount": 13
    }
  },
  "完全": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánquán",
      "PrimaryDefinition": "completely, fully, wholly, absolutely"
    },
    "Keys": {
      "Word": 1046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "商贩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngfàn",
      "PrimaryDefinition": "trader, peddler"
    },
    "Keys": {
      "Word": 8966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "bamboo flute"
    },
    "Keys": {
      "Hanzi": 3124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3124,
      "Radical": "竹",
      "FrequencyRank": 3634,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "见钱眼开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànqián-yǎnkāi",
      "PrimaryDefinition": "to open one's eyes wide at the sight of profit (idiom), thinking of nothing but personal gain, money-grubbing"
    },
    "Keys": {
      "Word": 7416
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "检验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnyàn",
      "PrimaryDefinition": "test, examine, inspect"
    },
    "Keys": {
      "Word": 3595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "流域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúyù",
      "PrimaryDefinition": "river basin, valley, drainage area"
    },
    "Keys": {
      "Word": 8112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "半夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànyè",
      "PrimaryDefinition": "midnight, in the middle of the night"
    },
    "Keys": {
      "Word": 511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "惊险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngxiǎn",
      "PrimaryDefinition": "thrilling, a thriller"
    },
    "Keys": {
      "Word": 7605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "千钧一发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānjūn-yīfà",
      "PrimaryDefinition": "a thousand pounds hangs by a thread (idiom), imminent peril, a matter of life or death"
    },
    "Keys": {
      "Word": 8647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烟囱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāncōng",
      "PrimaryDefinition": "chimney"
    },
    "Keys": {
      "Word": 10167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肢体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhītǐ",
      "PrimaryDefinition": "member, limbs and trunk, solid body substance, body, subfigure, limbs"
    },
    "Keys": {
      "Word": 10752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "used in old names"
    },
    "Keys": {
      "Hanzi": 7396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7396,
      "Radical": "人",
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "空难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngnàn",
      "PrimaryDefinition": "air crash, aviation accident or incident"
    },
    "Keys": {
      "Word": 7820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "scrofulous lumps or swellings"
    },
    "Keys": {
      "Hanzi": 4308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4308,
      "Radical": "疒",
      "FrequencyRank": 6585,
      "RadicalIndex": "104.4",
      "StrokeCount": 9
    }
  },
  "荒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāng",
      "PrimaryDefinition": "wasteland, desert; uncultivated"
    },
    "Keys": {
      "Hanzi": 1412,
      "Word": 7184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "Grade": 7,
      "GeneralStandard": 1412,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 1328,
      "StrokeCount": 9
    }
  },
  "收据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōujù",
      "PrimaryDefinition": "receipt, CL:張|张[zhang1]"
    },
    "Keys": {
      "Word": 9164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "要求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāoqiú",
      "PrimaryDefinition": "request, demand"
    },
    "Keys": {
      "Word": 1127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "笑脸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoliǎn",
      "PrimaryDefinition": "smiling face, smiley :) ☺, CL:副[fu4]"
    },
    "Keys": {
      "Word": 5172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "都": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōu",
      "PrimaryDefinition": "metropolis, capital; all, the whole; elegant, refined"
    },
    "Keys": {
      "Hanzi": 1801,
      "Word": 89
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "163.9",
      "Grade": 1,
      "GeneralStandard": 1801,
      "Radical": "邑",
      "HSK": 1,
      "FrequencyRank": 68,
      "StrokeCount": 10
    }
  },
  "似乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìhū",
      "PrimaryDefinition": "it seems, quasi, apparently, seemingly, as if"
    },
    "Keys": {
      "Word": 2901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": "speech, talk, language; dialect"
    },
    "Keys": {
      "Hanzi": 1296,
      "Word": 147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "Grade": 1,
      "GeneralStandard": 1296,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 170,
      "StrokeCount": 8
    }
  },
  "晫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "bright"
    },
    "Keys": {
      "Hanzi": 7351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7351,
      "Radical": "日",
      "RadicalIndex": "72.8",
      "StrokeCount": 12
    }
  },
  "正能量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngnéngliàng",
      "PrimaryDefinition": "positive energy"
    },
    "Keys": {
      "Word": 10733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "beautiful jade; star"
    },
    "Keys": {
      "Hanzi": 5857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5857,
      "Radical": "玉",
      "FrequencyRank": 2065,
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "文件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénjiàn",
      "PrimaryDefinition": "page, documental, archive, writ, documents, data file, paper, document, judicial writ, literature, circular, documentation, portfolio, archives, file, written document, papers"
    },
    "Keys": {
      "Word": 2014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "臬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "law, rule; door post"
    },
    "Keys": {
      "Hanzi": 4578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4578,
      "Radical": "自",
      "FrequencyRank": 5135,
      "RadicalIndex": "132.4",
      "StrokeCount": 10
    }
  },
  "韧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "strong and pliable, resilient"
    },
    "Keys": {
      "Hanzi": 610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "178.3",
      "GeneralStandard": 610,
      "Radical": "韋",
      "HSK": 9,
      "FrequencyRank": 3137,
      "StrokeCount": 7
    }
  },
  "风流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngliú",
      "PrimaryDefinition": "distinguished and accomplished, outstanding, talented in letters and unconventional in lifestyle, romantic, dissolute, loose"
    },
    "Keys": {
      "Word": 6572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "背面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèimiàn",
      "PrimaryDefinition": "the back, the reverse side, the wrong side"
    },
    "Keys": {
      "Word": 5556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhài",
      "PrimaryDefinition": "stockade, stronghold, outpost; brothel"
    },
    "Keys": {
      "Hanzi": 3171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.11",
      "GeneralStandard": 3171,
      "Radical": "宀",
      "HSK": 9,
      "FrequencyRank": 2313,
      "StrokeCount": 14
    }
  },
  "抵押": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐyā",
      "PrimaryDefinition": "to provide (an asset) as security for a loan, to put up collateral"
    },
    "Keys": {
      "Word": 6240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "fly upward, soar; contest"
    },
    "Keys": {
      "Hanzi": 5067
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5067,
      "Radical": "頁",
      "FrequencyRank": 3544,
      "RadicalIndex": "181.6",
      "StrokeCount": 12
    }
  },
  "牙刷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáshuā",
      "PrimaryDefinition": "toothbrush, CL:把[ba3]"
    },
    "Keys": {
      "Word": 3059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "职位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíwèi",
      "PrimaryDefinition": "slot, appointment, character, berth, post, archbishopric, job, ministry, situation, office, position, spot, billet, place, posting"
    },
    "Keys": {
      "Word": 4224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "逃亡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táowáng",
      "PrimaryDefinition": "to flee, flight (from danger), fugitive"
    },
    "Keys": {
      "Word": 9434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "气愤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìfèn",
      "PrimaryDefinition": "indignant, furious"
    },
    "Keys": {
      "Word": 8620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gào",
      "PrimaryDefinition": "zirconium"
    },
    "Keys": {
      "Hanzi": 5183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5183,
      "Radical": "金",
      "FrequencyRank": 5501,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "寻觅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnmì",
      "PrimaryDefinition": "to look for"
    },
    "Keys": {
      "Word": 10148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "换位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn wèi",
      "PrimaryDefinition": "transposition"
    },
    "Keys": {
      "Word": 7175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "授权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòuquán",
      "PrimaryDefinition": "empower, authorize"
    },
    "Keys": {
      "Word": 9201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "割": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "cut, divide, partition; cede"
    },
    "Keys": {
      "Hanzi": 2779,
      "Word": 6724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.1",
      "Grade": 7,
      "GeneralStandard": 2779,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 1665,
      "StrokeCount": 12
    }
  },
  "自然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìran",
      "PrimaryDefinition": "natural world"
    },
    "Keys": {
      "Word": 2213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "胞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "womb, placenta, fetal membrane"
    },
    "Keys": {
      "Hanzi": 1595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "GeneralStandard": 1595,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 1341,
      "StrokeCount": 9
    }
  },
  "温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēn",
      "PrimaryDefinition": "lukewarm"
    },
    "Keys": {
      "Hanzi": 2757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2757,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 867,
      "StrokeCount": 12
    }
  },
  "立方米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìfāngmǐ",
      "PrimaryDefinition": "cubic meter (unit of volume)"
    },
    "Keys": {
      "Word": 7999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāng",
      "PrimaryDefinition": "bamboo basket or chest"
    },
    "Keys": {
      "Hanzi": 2680,
      "Word": 7885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 7,
      "GeneralStandard": 2680,
      "Radical": "竹",
      "HSK": 9,
      "FrequencyRank": 3419,
      "StrokeCount": 12
    }
  },
  "唪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěng",
      "PrimaryDefinition": "to recite, to intone or chant"
    },
    "Keys": {
      "Hanzi": 7155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7155,
      "Radical": "口",
      "FrequencyRank": 7253,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "吒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "shout, roar, bellow; scold"
    },
    "Keys": {
      "Hanzi": 6531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6531,
      "Radical": "口",
      "FrequencyRank": 5303,
      "RadicalIndex": "30.3",
      "StrokeCount": 6
    }
  },
  "论坛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùntán",
      "PrimaryDefinition": "forum (for discussion)"
    },
    "Keys": {
      "Word": 8161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抚恤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔxù",
      "PrimaryDefinition": "(of an organization that has a duty of care) to give financial support to relatives of sb who has died or suffered serious injury"
    },
    "Keys": {
      "Word": 6611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "今年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnnián",
      "PrimaryDefinition": "this year"
    },
    "Keys": {
      "Word": 176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "龚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "give, present; reverential"
    },
    "Keys": {
      "Hanzi": 4801
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4801,
      "Radical": "龍",
      "FrequencyRank": 3184,
      "RadicalIndex": "212.6",
      "StrokeCount": 11
    }
  },
  "鳄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "alligator"
    },
    "Keys": {
      "Hanzi": 3424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "195.9",
      "GeneralStandard": 3424,
      "Radical": "魚",
      "HSK": 7,
      "FrequencyRank": 3781,
      "StrokeCount": 17
    }
  },
  "褯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7826,
      "Radical": "衣",
      "FrequencyRank": 7416,
      "RadicalIndex": "145.1",
      "StrokeCount": 15
    }
  },
  "疐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "fall, stumble, falter; hindered"
    },
    "Keys": {
      "Hanzi": 7653
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7653,
      "Radical": "疋",
      "RadicalIndex": "103.9",
      "StrokeCount": 14
    }
  },
  "腯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "strong"
    },
    "Keys": {
      "Hanzi": 7556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7556,
      "Radical": "肉",
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "蕨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "pteris aquilina, common bracken"
    },
    "Keys": {
      "Hanzi": 5875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5875,
      "Radical": "艸",
      "FrequencyRank": 4289,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "治安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìān",
      "PrimaryDefinition": "peace, order, public security, police, public order, security, public order/security"
    },
    "Keys": {
      "Word": 4235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "忙乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mángluàn",
      "PrimaryDefinition": "rushed and muddled"
    },
    "Keys": {
      "Word": 8204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "课文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèwén",
      "PrimaryDefinition": "text, CL:篇[pian1]"
    },
    "Keys": {
      "Word": 198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "剧组": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùzǔ",
      "PrimaryDefinition": "cast and crew, performers and production team"
    },
    "Keys": {
      "Word": 7707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水库": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐkù",
      "PrimaryDefinition": "reservoir"
    },
    "Keys": {
      "Word": 3935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "惹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rě",
      "PrimaryDefinition": "irritate, vex, offend, incite"
    },
    "Keys": {
      "Hanzi": 2547,
      "Word": 8830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "Grade": 7,
      "GeneralStandard": 2547,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 2272,
      "StrokeCount": 12
    }
  },
  "湴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "(Cant.) mud, mire"
    },
    "Keys": {
      "Hanzi": 7239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7239,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "容纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngnà",
      "PrimaryDefinition": "have capacity for, receive, admit, take, accommodate, store, recipient, seat, hold, accept, carry, bear, contain, have a capacity of"
    },
    "Keys": {
      "Word": 8883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "印象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìnxiàng",
      "PrimaryDefinition": "effect, mark, impress, embossment, impressional, image, imprint, engram, sound, intuition, impression"
    },
    "Keys": {
      "Word": 2097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "沣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "river in Shanxi province"
    },
    "Keys": {
      "Hanzi": 3766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3766,
      "Radical": "水",
      "FrequencyRank": 4376,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "鸽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "pigeon, dove; Columba species (various)"
    },
    "Keys": {
      "Hanzi": 2349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "196.6",
      "GeneralStandard": 2349,
      "Radical": "鳥",
      "HSK": 7,
      "FrequencyRank": 2694,
      "StrokeCount": 11
    }
  },
  "追": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuī",
      "PrimaryDefinition": "pursue, chase after; expel"
    },
    "Keys": {
      "Hanzi": 1577,
      "Word": 2208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 3,
      "GeneralStandard": 1577,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 768,
      "StrokeCount": 9
    }
  },
  "缠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "wrap, wind around; tie, bind"
    },
    "Keys": {
      "Hanzi": 3046,
      "Word": 5799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.1",
      "Grade": 7,
      "GeneralStandard": 3046,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 2046,
      "StrokeCount": 13
    }
  },
  "容许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngxǔ",
      "PrimaryDefinition": "let_in, have, receive, can, permission, permit, allow for, may, adhibit, might, admissive, provide for, admit, concede, cede, leave, suffer, tolerate, allow"
    },
    "Keys": {
      "Word": 8885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "路过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùguò",
      "PrimaryDefinition": "to pass by or through"
    },
    "Keys": {
      "Word": 4847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "聒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guā",
      "PrimaryDefinition": "clamor, din, hubbub"
    },
    "Keys": {
      "Hanzi": 5078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5078,
      "Radical": "耳",
      "FrequencyRank": 5079,
      "RadicalIndex": "128.6",
      "StrokeCount": 12
    }
  },
  "重伤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngshāng",
      "PrimaryDefinition": "seriously hurt, serious injury"
    },
    "Keys": {
      "Word": 10832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "low, damp land, marsh, swamp"
    },
    "Keys": {
      "Hanzi": 6189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6189,
      "Radical": "阜",
      "FrequencyRank": 6267,
      "RadicalIndex": "170.14",
      "StrokeCount": 16
    }
  },
  "凶手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngshǒu",
      "PrimaryDefinition": "murderer, assassin"
    },
    "Keys": {
      "Word": 5194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "礞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7994,
      "Radical": "石",
      "FrequencyRank": 7088,
      "RadicalIndex": "112.14",
      "StrokeCount": 18
    }
  },
  "简称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnchēng",
      "PrimaryDefinition": "be called sth. for short"
    },
    "Keys": {
      "Word": 7408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上个月": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànggèyuè",
      "PrimaryDefinition": "last month"
    },
    "Keys": {
      "Word": 2841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "祆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "Ormazda, god of the Zoroastrians; extended to god of the Manicheans"
    },
    "Keys": {
      "Hanzi": 4047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4047,
      "Radical": "示",
      "FrequencyRank": 6108,
      "RadicalIndex": "113.4",
      "StrokeCount": 8
    }
  },
  "胖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàng",
      "PrimaryDefinition": "fat, obese; fatty"
    },
    "Keys": {
      "Hanzi": 1596,
      "Word": 1786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "Grade": 3,
      "GeneralStandard": 1596,
      "Radical": "肉",
      "HSK": 3,
      "FrequencyRank": 1893,
      "StrokeCount": 9
    }
  },
  "水平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐpíng",
      "PrimaryDefinition": "level, horizontal, grade, degree, standard, horizontality"
    },
    "Keys": {
      "Word": 992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "学分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéfēn",
      "PrimaryDefinition": "point, semester hour, course credit, credit"
    },
    "Keys": {
      "Word": 3051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "慈善": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "císhàn",
      "PrimaryDefinition": "benevolent, charitable, philanthropic, benevolently"
    },
    "Keys": {
      "Word": 6041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōnglì",
      "PrimaryDefinition": "neutral"
    },
    "Keys": {
      "Word": 10810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灾害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāihài",
      "PrimaryDefinition": "tragedy, cataclysm, visitation, fatality, ravage, catastrophe, disaster, suffering, calamity"
    },
    "Keys": {
      "Word": 4167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "演讲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnjiǎng",
      "PrimaryDefinition": "lecture, speech"
    },
    "Keys": {
      "Word": 3073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "橡胶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngjiāo",
      "PrimaryDefinition": "rubber, caoutchouc"
    },
    "Keys": {
      "Word": 9954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䎖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": "soaring up in the sky"
    },
    "Keys": {
      "Hanzi": 8024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8024,
      "Radical": "羽",
      "RadicalIndex": "124.12",
      "StrokeCount": 18
    }
  },
  "殁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "die; death; dead"
    },
    "Keys": {
      "Hanzi": 3891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3891,
      "Radical": "歹",
      "FrequencyRank": 4457,
      "RadicalIndex": "78.4",
      "StrokeCount": 8
    }
  },
  "就任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùrèn",
      "PrimaryDefinition": "to take office, to assume a post"
    },
    "Keys": {
      "Word": 7670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拿走": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "názǒu",
      "PrimaryDefinition": "to take away"
    },
    "Keys": {
      "Word": 4883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "播": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "sow, spread; broadcast; cast away, reject"
    },
    "Keys": {
      "Hanzi": 3199,
      "Word": 4342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 6,
      "GeneralStandard": 3199,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 1275,
      "StrokeCount": 15
    }
  },
  "成分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngfen",
      "PrimaryDefinition": "constitution, class/economic status, composition, economic status, component_part, ingredient, one's profession or economic status, profession, component part, grammatical constituent, class, constituent, one's class status, class status, element, component, metal"
    },
    "Keys": {
      "Word": 4410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "功课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngkè",
      "PrimaryDefinition": "task, schoolwork, homework, preparation, prep, subject, lesson"
    },
    "Keys": {
      "Word": 1506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "损人利己": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔnrén-lìjǐ",
      "PrimaryDefinition": "harming others for one's personal benefit (idiom), personal gain to the detriment of others"
    },
    "Keys": {
      "Word": 9372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "trail, tow, drag, pull"
    },
    "Keys": {
      "Hanzi": 3592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3592,
      "Radical": "曰",
      "FrequencyRank": 3364,
      "RadicalIndex": "73.2",
      "StrokeCount": 6
    }
  },
  "湜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "transparent, clear"
    },
    "Keys": {
      "Hanzi": 5281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5281,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "蹿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuān",
      "PrimaryDefinition": "leap; to jump; to spurt out"
    },
    "Keys": {
      "Hanzi": 6389
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6389,
      "Radical": "足",
      "FrequencyRank": 3784,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "飒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sà",
      "PrimaryDefinition": "the sound of the wind; bleak; melancholy"
    },
    "Keys": {
      "Hanzi": 4315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4315,
      "Radical": "風",
      "FrequencyRank": 4300,
      "RadicalIndex": "182.5",
      "StrokeCount": 9
    }
  },
  "公立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnglì",
      "PrimaryDefinition": "public, established and maintained by the government"
    },
    "Keys": {
      "Word": 6778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顺差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnchā",
      "PrimaryDefinition": "(trade or budget) surplus"
    },
    "Keys": {
      "Word": 9279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "显而易见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnéryìjiàn",
      "PrimaryDefinition": "clearly and easy to see (idiom), obviously, clearly, it goes without saying"
    },
    "Keys": {
      "Word": 9893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不必": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùbì",
      "PrimaryDefinition": "need not, not have to"
    },
    "Keys": {
      "Word": 1313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "同舟共济": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngzhōu-gòngjì",
      "PrimaryDefinition": "cross a river in the same boat (idiom), fig. having common interests, obliged to collaborate towards common goals"
    },
    "Keys": {
      "Word": 9541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全方位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánfāngwèi",
      "PrimaryDefinition": "all around, omni-directional, complete, holistic, comprehensive"
    },
    "Keys": {
      "Word": 8803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngyì",
      "PrimaryDefinition": "intention, purpose"
    },
    "Keys": {
      "Word": 10447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "sprinkle, spray; spill, splash"
    },
    "Keys": {
      "Hanzi": 2760,
      "Word": 7435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 7,
      "GeneralStandard": 2760,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2949,
      "StrokeCount": 12
    }
  },
  "荀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "surname; ancient state; plant"
    },
    "Keys": {
      "Hanzi": 4124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4124,
      "Radical": "艸",
      "FrequencyRank": 3690,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "恿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "instigate, incite; to alarm"
    },
    "Keys": {
      "Hanzi": 5025
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5025,
      "Radical": "心",
      "FrequencyRank": 3913,
      "RadicalIndex": "61.7",
      "StrokeCount": 11
    }
  },
  "婪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "covet; covetous, avaricious"
    },
    "Keys": {
      "Hanzi": 2222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.8",
      "GeneralStandard": 2222,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 3112,
      "StrokeCount": 11
    }
  },
  "甸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diān",
      "PrimaryDefinition": "suburbs of capital; govern; crops"
    },
    "Keys": {
      "Hanzi": 809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.2",
      "GeneralStandard": 809,
      "Radical": "田",
      "HSK": 9,
      "FrequencyRank": 2588,
      "StrokeCount": 7
    }
  },
  "铃声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língshēng",
      "PrimaryDefinition": "ring, ringtone, bell stroke, tintinnabulation"
    },
    "Keys": {
      "Word": 3697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "信件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnjiàn",
      "PrimaryDefinition": "letter (sent by mail)"
    },
    "Keys": {
      "Word": 10036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "媒体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méitǐ",
      "PrimaryDefinition": "mass media, media, medium"
    },
    "Keys": {
      "Word": 1735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "建筑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànzhù",
      "PrimaryDefinition": "build, construct, erect"
    },
    "Keys": {
      "Word": 3605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "苉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6572
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6572,
      "Radical": "艸",
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "病情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngqíng",
      "PrimaryDefinition": "patient's condition"
    },
    "Keys": {
      "Word": 4338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "下班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià bān",
      "PrimaryDefinition": "get_off"
    },
    "Keys": {
      "Word": 387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "爝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6458
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6458,
      "Radical": "火",
      "FrequencyRank": 6942,
      "RadicalIndex": "86.18",
      "StrokeCount": 21
    }
  },
  "厾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dū",
      "PrimaryDefinition": "lightly tap; through away; drop; sentence final particle"
    },
    "Keys": {
      "Hanzi": 3637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3637,
      "Radical": "乙",
      "RadicalIndex": "5.5",
      "StrokeCount": 6
    }
  },
  "开枪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi qiāng",
      "PrimaryDefinition": "to open fire, to shoot a gun"
    },
    "Keys": {
      "Word": 7755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "cheek bone; protective; assist"
    },
    "Keys": {
      "Hanzi": 2250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.7",
      "GeneralStandard": 2250,
      "Radical": "車",
      "HSK": 5,
      "FrequencyRank": 2033,
      "StrokeCount": 11
    }
  },
  "魔鬼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móguǐ",
      "PrimaryDefinition": "devil"
    },
    "Keys": {
      "Word": 8323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "to tattoo; to brand the face of criminals"
    },
    "Keys": {
      "Hanzi": 6433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6433,
      "Radical": "黑",
      "FrequencyRank": 6278,
      "RadicalIndex": "203.8",
      "StrokeCount": 20
    }
  },
  "拜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bài",
      "PrimaryDefinition": "do obeisance, bow, kowtow"
    },
    "Keys": {
      "Hanzi": 1538
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 1538,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1218,
      "StrokeCount": 9
    }
  },
  "肉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ròu",
      "PrimaryDefinition": "meat"
    },
    "Keys": {
      "Hanzi": 443,
      "Radical": 130,
      "Word": 307
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130",
      "Grade": 1,
      "GeneralStandard": 443,
      "Radical": "肉",
      "HSK": 1,
      "FrequencyRank": 1009,
      "StrokeCount": 6
    }
  },
  "椹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": "a chopping board"
    },
    "Keys": {
      "Hanzi": 7492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7492,
      "Radical": "木",
      "FrequencyRank": 6182,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "趣味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùwèi",
      "PrimaryDefinition": "playfulness, merriment, savor, camp, interest, salt, delight, savour, liking, fun, gout, preference, spice, lust, gusto, palate, taste, delectation"
    },
    "Keys": {
      "Word": 8796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "mountain in Henan province"
    },
    "Keys": {
      "Hanzi": 3552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3552,
      "Radical": "邑",
      "FrequencyRank": 4092,
      "RadicalIndex": "163.3",
      "StrokeCount": 5
    }
  },
  "伺候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìhou",
      "PrimaryDefinition": "to serve, to wait upon"
    },
    "Keys": {
      "Word": 6048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "swine"
    },
    "Keys": {
      "Hanzi": 5331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5331,
      "Radical": "彐",
      "FrequencyRank": 6104,
      "RadicalIndex": "58.9",
      "StrokeCount": 12
    }
  },
  "锍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7380
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7380,
      "Radical": "金",
      "FrequencyRank": 6048,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "孽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "evil; son of concubine; ghost"
    },
    "Keys": {
      "Hanzi": 3452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3452,
      "Radical": "子",
      "FrequencyRank": 2942,
      "RadicalIndex": "39.17",
      "StrokeCount": 19
    }
  },
  "审视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnshì",
      "PrimaryDefinition": "to look closely at, to examine"
    },
    "Keys": {
      "Word": 9046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "勹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "wrap"
    },
    "Keys": {
      "Radical": 20
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "奋力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènlì",
      "PrimaryDefinition": "to do everything one can, to spare no effort, to strive"
    },
    "Keys": {
      "Word": 6555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": "fragrant; virtuous; beautiful"
    },
    "Keys": {
      "Hanzi": 670
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 670,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1586,
      "StrokeCount": 7
    }
  },
  "倧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7021,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "连": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "join, connect; continuous; even"
    },
    "Keys": {
      "Hanzi": 708,
      "Word": 1702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 3,
      "GeneralStandard": 708,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 399,
      "StrokeCount": 7
    }
  },
  "撬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "lift, raise; pry open"
    },
    "Keys": {
      "Hanzi": 3198,
      "Word": 8709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 7,
      "GeneralStandard": 3198,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 3613,
      "StrokeCount": 15
    }
  },
  "大会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàhuì",
      "PrimaryDefinition": "synod, moot, conference, plenary meeting, congress, rally, plenum, mass meeting, plenary session"
    },
    "Keys": {
      "Word": 2372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "计划": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìhuà",
      "PrimaryDefinition": "plan, project, program"
    },
    "Keys": {
      "Word": 747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "指南": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐnán",
      "PrimaryDefinition": "to guide, guidebook"
    },
    "Keys": {
      "Word": 10778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "strike"
    },
    "Keys": {
      "Hanzi": 7150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7150,
      "Radical": "石",
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "进攻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngōng",
      "PrimaryDefinition": "attack, assault"
    },
    "Keys": {
      "Word": 4743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "雁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "wild goose"
    },
    "Keys": {
      "Hanzi": 2593
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "172.4",
      "GeneralStandard": 2593,
      "Radical": "隹",
      "HSK": 9,
      "FrequencyRank": 2553,
      "StrokeCount": 12
    }
  },
  "残疾人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánjírén",
      "PrimaryDefinition": "disabled person"
    },
    "Keys": {
      "Word": 4369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "急迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jípò",
      "PrimaryDefinition": "urgent, pressing, imperative"
    },
    "Keys": {
      "Word": 7302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "争论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnglùn",
      "PrimaryDefinition": "to argue, to debate, to contend, argument, contention, controversy, debate, CL:次[ci4],場|场[chang3]"
    },
    "Keys": {
      "Word": 3163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "赞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàn",
      "PrimaryDefinition": "help, support, assist, aid"
    },
    "Keys": {
      "Hanzi": 3355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.12",
      "GeneralStandard": 3355,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 1179,
      "StrokeCount": 16
    }
  },
  "桅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "mast of ship"
    },
    "Keys": {
      "Hanzi": 4460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4460,
      "Radical": "木",
      "FrequencyRank": 3558,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "岳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "mountain peak; surname"
    },
    "Keys": {
      "Hanzi": 1137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.5",
      "GeneralStandard": 1137,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 1844,
      "StrokeCount": 8
    }
  },
  "绅士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnshì",
      "PrimaryDefinition": "gentleman"
    },
    "Keys": {
      "Word": 9026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "to slip and sprain a blimb"
    },
    "Keys": {
      "Hanzi": 5914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5914,
      "Radical": "足",
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "旯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lá",
      "PrimaryDefinition": "nook, corner"
    },
    "Keys": {
      "Hanzi": 3591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3591,
      "Radical": "日",
      "FrequencyRank": 5243,
      "RadicalIndex": "72.2",
      "StrokeCount": 6
    }
  },
  "琢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuó",
      "PrimaryDefinition": "polish jade; cut jade"
    },
    "Keys": {
      "Hanzi": 2503
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.8",
      "GeneralStandard": 2503,
      "Radical": "玉",
      "HSK": 8,
      "FrequencyRank": 2921,
      "StrokeCount": 12
    }
  },
  "洗澡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ zǎo",
      "PrimaryDefinition": "have a bath, lave, take a bath, tub, bath, wash, bathing, bathe"
    },
    "Keys": {
      "Word": 1078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "最近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìjìn",
      "PrimaryDefinition": "recent, recently, these days, latest, soon, nearest (of locations), shortest (of routes)"
    },
    "Keys": {
      "Word": 1250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "与": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "and; with; to; for; give, grant"
    },
    "Keys": {
      "Hanzi": 34,
      "Word": 5281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.3",
      "Grade": 6,
      "GeneralStandard": 34,
      "Radical": "一",
      "HSK": 4,
      "FrequencyRank": 108,
      "StrokeCount": 3
    }
  },
  "线索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànsuǒ",
      "PrimaryDefinition": "track, thread, cue, lead, handhold, purchase, scent, train of thought, clue, trail, key, clew, pointer"
    },
    "Keys": {
      "Word": 4038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "遗弃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíqì",
      "PrimaryDefinition": "to leave, to abandon"
    },
    "Keys": {
      "Word": 10303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "stick, club, truncheon; hit"
    },
    "Keys": {
      "Hanzi": 2562,
      "Word": 3232
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "Grade": 5,
      "GeneralStandard": 2562,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 2288,
      "StrokeCount": 12
    }
  },
  "俗话说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "súhuàshuō",
      "PrimaryDefinition": "as the proverb says, as they say..."
    },
    "Keys": {
      "Word": 9344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "pattern, model, type; law; mold"
    },
    "Keys": {
      "Hanzi": 1363,
      "Word": 3041
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.6",
      "Grade": 4,
      "GeneralStandard": 1363,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 556,
      "StrokeCount": 9
    }
  },
  "海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎi",
      "PrimaryDefinition": "sea, ocean; maritime"
    },
    "Keys": {
      "Hanzi": 2074,
      "Word": 704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 2,
      "GeneralStandard": 2074,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 189,
      "StrokeCount": 10
    }
  },
  "杰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "hero; heroic, outstanding"
    },
    "Keys": {
      "Hanzi": 1031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1031,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1129,
      "StrokeCount": 8
    }
  },
  "食欲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíyù",
      "PrimaryDefinition": "appetite"
    },
    "Keys": {
      "Word": 5024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "衿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "collar or lapel of garment"
    },
    "Keys": {
      "Hanzi": 4362
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4362,
      "Radical": "衣",
      "FrequencyRank": 5748,
      "RadicalIndex": "145.4",
      "StrokeCount": 9
    }
  },
  "鹍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "a bird resembling the crane"
    },
    "Keys": {
      "Hanzi": 7518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7518,
      "Radical": "鳥",
      "FrequencyRank": 8004,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "诞辰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànchén",
      "PrimaryDefinition": "birthday"
    },
    "Keys": {
      "Word": 6168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chěn",
      "PrimaryDefinition": "gritty"
    },
    "Keys": {
      "Hanzi": 5423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5423,
      "Radical": "石",
      "FrequencyRank": 4931,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "midpoint, space; place, locality"
    },
    "Keys": {
      "Hanzi": 847,
      "Word": 171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.4",
      "Grade": 1,
      "GeneralStandard": 847,
      "Radical": "門",
      "HSK": 1,
      "FrequencyRank": 135,
      "StrokeCount": 7
    }
  },
  "笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": "smile, laugh, giggle; snicker"
    },
    "Keys": {
      "Hanzi": 1952,
      "Word": 405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.4",
      "Grade": 1,
      "GeneralStandard": 1952,
      "Radical": "竹",
      "HSK": 1,
      "FrequencyRank": 346,
      "StrokeCount": 10
    }
  },
  "诚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "sincere, honest; true, real"
    },
    "Keys": {
      "Hanzi": 1291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "GeneralStandard": 1291,
      "Radical": "言",
      "HSK": 4,
      "FrequencyRank": 1154,
      "StrokeCount": 8
    }
  },
  "干事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gànshi",
      "PrimaryDefinition": "administrator, executive secretary"
    },
    "Keys": {
      "Word": 6683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舒畅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūchàng",
      "PrimaryDefinition": "happy, entirely free from worry"
    },
    "Keys": {
      "Word": 9216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngxìn",
      "PrimaryDefinition": "communicate by letter, correspond"
    },
    "Keys": {
      "Word": 1972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "嘛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ma",
      "PrimaryDefinition": "final exclamatory particle"
    },
    "Keys": {
      "Hanzi": 3110,
      "Word": 4853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.11",
      "Grade": 6,
      "GeneralStandard": 3110,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 1628,
      "StrokeCount": 14
    }
  },
  "向前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngqián",
      "PrimaryDefinition": "forward, onward"
    },
    "Keys": {
      "Word": 4052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "bottom, underneath, underside"
    },
    "Keys": {
      "Hanzi": 1212,
      "Word": 2406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.5",
      "Grade": 4,
      "GeneralStandard": 1212,
      "Radical": "广",
      "HSK": 3,
      "FrequencyRank": 543,
      "StrokeCount": 8
    }
  },
  "跋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bá",
      "PrimaryDefinition": "go by foot; epilogue, colophon"
    },
    "Keys": {
      "Hanzi": 2629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2629,
      "Radical": "足",
      "FrequencyRank": 2917,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "案件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànjiàn",
      "PrimaryDefinition": "a police case, law case, case (of law/etc.), a legal case, case"
    },
    "Keys": {
      "Word": 5445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耳目一新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěrmù-yīxīn",
      "PrimaryDefinition": "a pleasant change, a breath of fresh air, refreshing"
    },
    "Keys": {
      "Word": 6425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岸上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànshàng",
      "PrimaryDefinition": "ashore, on the riverbank"
    },
    "Keys": {
      "Word": 3223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "日趋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìqū",
      "PrimaryDefinition": "gradually, day_by_day"
    },
    "Keys": {
      "Word": 8874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4629,
      "Radical": "門",
      "FrequencyRank": 5356,
      "RadicalIndex": "169.7",
      "StrokeCount": 10
    }
  },
  "物业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùyè",
      "PrimaryDefinition": "property, real estate, abbr. for 物業管理|物业管理[wu4 ye4 guan3 li3], property management"
    },
    "Keys": {
      "Word": 4021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "桂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "cassia or cinnamon"
    },
    "Keys": {
      "Hanzi": 1835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 1835,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 1930,
      "StrokeCount": 10
    }
  },
  "物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "thing, substance, creature"
    },
    "Keys": {
      "Hanzi": 1127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "93.4",
      "GeneralStandard": 1127,
      "Radical": "牛",
      "HSK": 2,
      "FrequencyRank": 142,
      "StrokeCount": 8
    }
  },
  "瘸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qué",
      "PrimaryDefinition": "lameness, paralysis of hands, leg"
    },
    "Keys": {
      "Hanzi": 3369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.11",
      "GeneralStandard": 3369,
      "Radical": "疒",
      "HSK": 6,
      "FrequencyRank": 3943,
      "StrokeCount": 16
    }
  },
  "哲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "wise, sagacious; wise man, sage"
    },
    "Keys": {
      "Hanzi": 1802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1802,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 1117,
      "StrokeCount": 10
    }
  },
  "素食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùshí",
      "PrimaryDefinition": "vegetables, vegetarian food"
    },
    "Keys": {
      "Word": 9357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "house, home, residence; family"
    },
    "Keys": {
      "Hanzi": 2099,
      "Word": 751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "Grade": 2,
      "GeneralStandard": 2099,
      "Radical": "宀",
      "HSK": 1,
      "FrequencyRank": 56,
      "StrokeCount": 10
    }
  },
  "鲕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ér",
      "PrimaryDefinition": "roe"
    },
    "Keys": {
      "Hanzi": 7700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7700,
      "Radical": "魚",
      "FrequencyRank": 6799,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "精打细算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngdǎ-xìsuàn",
      "PrimaryDefinition": "meticulous planning and careful accounting (idiom)"
    },
    "Keys": {
      "Word": 7611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéyuán",
      "PrimaryDefinition": "trainee, member of institution of learning, student"
    },
    "Keys": {
      "Word": 5203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夏令营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàlìngyíng",
      "PrimaryDefinition": "summer camp"
    },
    "Keys": {
      "Word": 9879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "careful, sincere, honest; trust"
    },
    "Keys": {
      "Hanzi": 4355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4355,
      "Radical": "心",
      "FrequencyRank": 5117,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "还款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán kuǎn",
      "PrimaryDefinition": "repayment, to pay back money"
    },
    "Keys": {
      "Word": 7164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "力量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìliang",
      "PrimaryDefinition": "fibre, strength, potency, ability, guts, kick, cogency, point, dint, energy, mightiness, physical strength, demon, force, punch, whiz, tooth, might, forcefulness, exercise, fiber, powerfulness, lift, pith, leverage, agency, push, muscle, power"
    },
    "Keys": {
      "Word": 1699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "清凉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngliáng",
      "PrimaryDefinition": "fresh and cool, refreshing"
    },
    "Keys": {
      "Word": 8747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "shade, shelter; protect"
    },
    "Keys": {
      "Hanzi": 1420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.7",
      "GeneralStandard": 1420,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2669,
      "StrokeCount": 9
    }
  },
  "测算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèsuàn",
      "PrimaryDefinition": "to take measurements and calculate"
    },
    "Keys": {
      "Word": 5778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngshēng",
      "PrimaryDefinition": "rise"
    },
    "Keys": {
      "Word": 1871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "止咳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐké",
      "PrimaryDefinition": "to suppress coughing"
    },
    "Keys": {
      "Word": 10770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盛气凌人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngqì-língrén",
      "PrimaryDefinition": "overbearing, arrogant bully"
    },
    "Keys": {
      "Word": 9077
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "太平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàipíng",
      "PrimaryDefinition": "place name, peace and security"
    },
    "Keys": {
      "Word": 9393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "未": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "not yet; 8th terrestrial branch"
    },
    "Keys": {
      "Hanzi": 192,
      "Word": 9712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "Grade": 7,
      "GeneralStandard": 192,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 385,
      "StrokeCount": 5
    }
  },
  "惊喜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngxǐ",
      "PrimaryDefinition": "nice surprise, to be pleasantly surprised"
    },
    "Keys": {
      "Word": 4751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "后天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòutiān",
      "PrimaryDefinition": "the day after tomorrow, post-natal, acquired (not innate), a posteriori"
    },
    "Keys": {
      "Word": 148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "俩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎ",
      "PrimaryDefinition": "two, pair"
    },
    "Keys": {
      "Hanzi": 1557,
      "Word": 2709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "Grade": 4,
      "GeneralStandard": 1557,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 1574,
      "StrokeCount": 9
    }
  },
  "罪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuì",
      "PrimaryDefinition": "crime, sin, vice; evil; hardship"
    },
    "Keys": {
      "Hanzi": 2932,
      "Word": 5414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "122.8",
      "Grade": 6,
      "GeneralStandard": 2932,
      "Radical": "网",
      "HSK": 6,
      "FrequencyRank": 718,
      "StrokeCount": 13
    }
  },
  "笼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "cage; cage-like basket"
    },
    "Keys": {
      "Hanzi": 2318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.5",
      "GeneralStandard": 2318,
      "Radical": "竹",
      "HSK": 7,
      "FrequencyRank": 1928,
      "StrokeCount": 11
    }
  },
  "竭力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiélì",
      "PrimaryDefinition": "to do one's utmost"
    },
    "Keys": {
      "Word": 7533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "side-room, wing; theatre box"
    },
    "Keys": {
      "Hanzi": 2238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.9",
      "GeneralStandard": 2238,
      "Radical": "厂",
      "HSK": 8,
      "FrequencyRank": 2467,
      "StrokeCount": 11
    }
  },
  "解答": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiědá",
      "PrimaryDefinition": "answer, explain"
    },
    "Keys": {
      "Word": 7534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "完备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánbèi",
      "PrimaryDefinition": "perfect"
    },
    "Keys": {
      "Word": 9646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "办法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànfǎ",
      "PrimaryDefinition": "road, expedient, policy, agency, measure, way, method, means"
    },
    "Keys": {
      "Word": 505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "直播": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíbō",
      "PrimaryDefinition": "live broadcast (not recorded), direct Internet broadcasting, (agriculture) direct seeding"
    },
    "Keys": {
      "Word": 2159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "钺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "broad-axe, a battle axe, halberd"
    },
    "Keys": {
      "Hanzi": 4532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4532,
      "Radical": "金",
      "FrequencyRank": 5507,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "沭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "river in Shantung"
    },
    "Keys": {
      "Hanzi": 4010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4010,
      "Radical": "水",
      "FrequencyRank": 6379,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "修建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūjiàn",
      "PrimaryDefinition": "to build, to construct"
    },
    "Keys": {
      "Word": 4075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "氏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "clan"
    },
    "Keys": {
      "Radical": 83,
      "Hanzi": 151
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "83",
      "GeneralStandard": 151,
      "Radical": "氏",
      "HSK": 8,
      "FrequencyRank": 1500,
      "StrokeCount": 4
    }
  },
  "莲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "lotus, water lily; paradise"
    },
    "Keys": {
      "Hanzi": 1823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 1823,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1837,
      "StrokeCount": 10
    }
  },
  "探亲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn qīn",
      "PrimaryDefinition": "go home to visit one's family, visit family, go to visit one's relatives"
    },
    "Keys": {
      "Word": 9418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo",
      "PrimaryDefinition": "pray; entreat, beg, plead; prayer"
    },
    "Keys": {
      "Hanzi": 2464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.7",
      "GeneralStandard": 2464,
      "Radical": "示",
      "HSK": 7,
      "FrequencyRank": 2483,
      "StrokeCount": 11
    }
  },
  "竣工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn gōng",
      "PrimaryDefinition": "to complete a project"
    },
    "Keys": {
      "Word": 7736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "absent-minded, confused"
    },
    "Keys": {
      "Hanzi": 4990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4990,
      "Radical": "心",
      "FrequencyRank": 3293,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "化险为夷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàxiǎn-wéiyí",
      "PrimaryDefinition": "to turn peril into safety (idiom), to avert disaster"
    },
    "Keys": {
      "Word": 7142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亮相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng xiàng",
      "PrimaryDefinition": "to strike a pose (Chinese opera), (fig.) to make a public appearance, to come out in public (revealing one's true personality, opinions etc), (of a product) to appear on the market or at a trade show etc"
    },
    "Keys": {
      "Word": 8038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "high door; high gate; high, lofty"
    },
    "Keys": {
      "Hanzi": 4632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4632,
      "Radical": "門",
      "FrequencyRank": 5334,
      "RadicalIndex": "169.7",
      "StrokeCount": 10
    }
  },
  "中心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxīn",
      "PrimaryDefinition": "center, heart, hub"
    },
    "Keys": {
      "Word": 1225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "电梯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàntī",
      "PrimaryDefinition": "lift, elevator"
    },
    "Keys": {
      "Word": 2415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "买卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎimai",
      "PrimaryDefinition": "buying and selling, business deal/transaction, (small) merchant enterprise"
    },
    "Keys": {
      "Word": 3709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "伤残": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngcán",
      "PrimaryDefinition": "disabled, maimed, crippled, (of objects) damaged"
    },
    "Keys": {
      "Word": 8961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "包子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāozi",
      "PrimaryDefinition": "steamed stuffed bun, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 16
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "檠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "stand for lamp; frame for bow"
    },
    "Keys": {
      "Hanzi": 6051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6051,
      "Radical": "木",
      "FrequencyRank": 6105,
      "RadicalIndex": "75.13",
      "StrokeCount": 16
    }
  },
  "鳅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "loach"
    },
    "Keys": {
      "Hanzi": 6280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6280,
      "Radical": "魚",
      "FrequencyRank": 4462,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "相伴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngbàn",
      "PrimaryDefinition": "to accompany sb, to accompany each other"
    },
    "Keys": {
      "Word": 9916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1327,
      "Word": 1114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "Grade": 2,
      "GeneralStandard": 1327,
      "Radical": "女",
      "HSK": 2,
      "FrequencyRank": 1149,
      "StrokeCount": 8
    }
  },
  "呵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ā",
      "PrimaryDefinition": "scold; laughing sound; yawn"
    },
    "Keys": {
      "Hanzi": 1085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1085,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 1861,
      "StrokeCount": 8
    }
  },
  "没错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méicuò",
      "PrimaryDefinition": "that's right, sure!, rest assured!, that's good, can't go wrong"
    },
    "Keys": {
      "Word": 2728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "人口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénkǒu",
      "PrimaryDefinition": "population"
    },
    "Keys": {
      "Word": 954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "静止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngzhǐ",
      "PrimaryDefinition": "still, immobile, static, stationary"
    },
    "Keys": {
      "Word": 7650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6992,
      "Radical": "田",
      "RadicalIndex": "102.4",
      "StrokeCount": 10
    }
  },
  "敫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "ancient musical instrument"
    },
    "Keys": {
      "Hanzi": 5507
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5507,
      "Radical": "攴",
      "FrequencyRank": 5937,
      "RadicalIndex": "66.9",
      "StrokeCount": 13
    }
  },
  "浰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7061,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "下属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàshǔ",
      "PrimaryDefinition": "subordinate, underling"
    },
    "Keys": {
      "Word": 9868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "条件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáojiàn",
      "PrimaryDefinition": "mood, formula, conditional, precondition, prerequisite, requisition, factor, qualification, requirement, stipulation, proviso, circumstances, provision, term, reservation, if, condition"
    },
    "Keys": {
      "Word": 1025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "装修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngxiū",
      "PrimaryDefinition": "repair, renovate (house/etc.), decorate, renovate (house, etc.), renovate, furnish, renovation, fit up"
    },
    "Keys": {
      "Word": 3200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "哇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wa",
      "PrimaryDefinition": "vomit; cry of an infant"
    },
    "Keys": {
      "Hanzi": 1482,
      "Word": 5119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 6,
      "GeneralStandard": 1482,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2433,
      "StrokeCount": 9
    }
  },
  "声明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngmíng",
      "PrimaryDefinition": "statement, pronouncement"
    },
    "Keys": {
      "Word": 1890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "徐徐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúxú",
      "PrimaryDefinition": "slowly, gently"
    },
    "Keys": {
      "Word": 10096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": "to squat; to crouch"
    },
    "Keys": {
      "Hanzi": 6388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6388,
      "Radical": "足",
      "FrequencyRank": 7550,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "刿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "to cut, injure, stab, stick on"
    },
    "Keys": {
      "Hanzi": 3930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3930,
      "Radical": "刀",
      "FrequencyRank": 6008,
      "RadicalIndex": "18.6",
      "StrokeCount": 8
    }
  },
  "摇篮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáolán",
      "PrimaryDefinition": "cradle"
    },
    "Keys": {
      "Word": 10238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí",
      "PrimaryDefinition": "return, turn around; a time"
    },
    "Keys": {
      "Hanzi": 438,
      "Word": 737
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.3",
      "Grade": 2,
      "GeneralStandard": 438,
      "Radical": "囗",
      "HSK": 1,
      "FrequencyRank": 172,
      "StrokeCount": 6
    }
  },
  "释": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "interprete, elucidate; release"
    },
    "Keys": {
      "Hanzi": 2710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "165.5",
      "GeneralStandard": 2710,
      "Radical": "釆",
      "HSK": 4,
      "FrequencyRank": 813,
      "StrokeCount": 12
    }
  },
  "酃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "the spirit of a being, which acts upon others spirit; divine; efficacious"
    },
    "Keys": {
      "Hanzi": 6377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6377,
      "Radical": "邑",
      "FrequencyRank": 5878,
      "RadicalIndex": "163.17",
      "StrokeCount": 19
    }
  },
  "此起彼伏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐqǐ-bǐfú",
      "PrimaryDefinition": "up here, down there (idiom), to rise and fall in succession, no sooner one subsides, the next arises, repeating continuously, occurring again and again (of applause, fires, waves, protests, conflicts, uprisings etc)"
    },
    "Keys": {
      "Word": 6046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "unpolished gem, uncarved gem"
    },
    "Keys": {
      "Hanzi": 6029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6029,
      "Radical": "玉",
      "FrequencyRank": 3906,
      "RadicalIndex": "96.12",
      "StrokeCount": 16
    }
  },
  "加班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā bān",
      "PrimaryDefinition": "to work overtime"
    },
    "Keys": {
      "Word": 2593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "加紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiājǐn",
      "PrimaryDefinition": "to intensify, to speed up, to step up"
    },
    "Keys": {
      "Word": 7344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庆贺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìnghè",
      "PrimaryDefinition": "to congratulate, to celebrate"
    },
    "Keys": {
      "Word": 8767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "概况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàikuàng",
      "PrimaryDefinition": "general situation, summary"
    },
    "Keys": {
      "Word": 6661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "ancient music instrument; harp"
    },
    "Keys": {
      "Hanzi": 5958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5958,
      "Radical": "竹",
      "FrequencyRank": 6633,
      "RadicalIndex": "118.9",
      "StrokeCount": 15
    }
  },
  "文具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénjù",
      "PrimaryDefinition": "stationery, item of stationery (pen, pencil, eraser, pencil sharpener etc)"
    },
    "Keys": {
      "Word": 9733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnzhe",
      "PrimaryDefinition": "based on..., in conformance with.., taking as one's main principle"
    },
    "Keys": {
      "Word": 5567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伯母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bómǔ",
      "PrimaryDefinition": "wife of father's elder brother, aunt, (polite form of address for a woman who is about the age of one's mother), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 5667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "游船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuchuán",
      "PrimaryDefinition": "Boat"
    },
    "Keys": {
      "Word": 10467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "受惊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu jīng",
      "PrimaryDefinition": "startled"
    },
    "Keys": {
      "Word": 9197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驾照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàzhào",
      "PrimaryDefinition": "driver's license"
    },
    "Keys": {
      "Word": 3591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "伊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "third person pronoun; he, she, this, that"
    },
    "Keys": {
      "Hanzi": 478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 478,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 761,
      "StrokeCount": 6
    }
  },
  "枥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "type of oak; stable"
    },
    "Keys": {
      "Hanzi": 3874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3874,
      "Radical": "木",
      "FrequencyRank": 5293,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "协": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "be united; cooperate"
    },
    "Keys": {
      "Hanzi": 383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.4",
      "GeneralStandard": 383,
      "Radical": "十",
      "HSK": 5,
      "FrequencyRank": 735,
      "StrokeCount": 6
    }
  },
  "鳢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "snakehead"
    },
    "Keys": {
      "Hanzi": 6454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6454,
      "Radical": "魚",
      "FrequencyRank": 5737,
      "RadicalIndex": "195.13",
      "StrokeCount": 21
    }
  },
  "蜃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": "marine monster which can change its shape; water spouts; clams"
    },
    "Keys": {
      "Hanzi": 5416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5416,
      "Radical": "虫",
      "FrequencyRank": 4284,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "悛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quān",
      "PrimaryDefinition": "repent, reform"
    },
    "Keys": {
      "Hanzi": 4664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4664,
      "Radical": "心",
      "FrequencyRank": 6250,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "谙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "versed in, fully acquainted with"
    },
    "Keys": {
      "Hanzi": 5007
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5007,
      "Radical": "言",
      "FrequencyRank": 3927,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "当日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngrì",
      "PrimaryDefinition": "on that day, that very day, the same day"
    },
    "Keys": {
      "Word": 6175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "包装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāozhuāng",
      "PrimaryDefinition": "package, packing, packaging"
    },
    "Keys": {
      "Word": 3230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèn",
      "PrimaryDefinition": "aching of limbs, muscular pains"
    },
    "Keys": {
      "Hanzi": 6876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6876,
      "Radical": "疒",
      "RadicalIndex": "104.4",
      "StrokeCount": 9
    }
  },
  "美女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měinǚ",
      "PrimaryDefinition": "looker, bombshell, siren, cookie, peach, dish, sweetheart, peri, lulu, beautiful woman, charmer, smasher, belle, knockout, mantrap, Venus, stunner, beauty, lovely"
    },
    "Keys": {
      "Word": 2731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "中介": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngjiè",
      "PrimaryDefinition": "to act as intermediary, to link, intermediate, inter-, agency, agent"
    },
    "Keys": {
      "Word": 3181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "痴迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chīmí",
      "PrimaryDefinition": "infatuated, obsessed"
    },
    "Keys": {
      "Word": 5897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鬷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "pot"
    },
    "Keys": {
      "Hanzi": 8033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8033,
      "Radical": "鬲",
      "RadicalIndex": "193.9",
      "StrokeCount": 19
    }
  },
  "看出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn chū",
      "PrimaryDefinition": "perceive, tell, see, espy, discover, discern, make out, descry"
    },
    "Keys": {
      "Word": 3649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "龀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèn",
      "PrimaryDefinition": "lose baby teeth and get adult teeth"
    },
    "Keys": {
      "Hanzi": 4489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4489,
      "Radical": "齒",
      "FrequencyRank": 5568,
      "RadicalIndex": "211.2",
      "StrokeCount": 10
    }
  },
  "飨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng",
      "PrimaryDefinition": "host banquet; banquet"
    },
    "Keys": {
      "Hanzi": 5338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5338,
      "Radical": "食",
      "FrequencyRank": 4724,
      "RadicalIndex": "184.3",
      "StrokeCount": 12
    }
  },
  "叔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "29.6",
      "GeneralStandard": 1065,
      "Radical": "又",
      "HSK": 4,
      "FrequencyRank": 1422,
      "StrokeCount": 8
    }
  },
  "搁置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēzhì",
      "PrimaryDefinition": "to shelve, to set aside"
    },
    "Keys": {
      "Word": 6722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口哨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒushào",
      "PrimaryDefinition": "whistle"
    },
    "Keys": {
      "Word": 7843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "记者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìzhě",
      "PrimaryDefinition": "journalist, writer, correspondent, newspaperwoman, newspaperman, gazetteer, newswriter, reporter, pressman, newshound, newsman, newsperson"
    },
    "Keys": {
      "Word": 1580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "水源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐyuán",
      "PrimaryDefinition": "headwaters, waterhead, source of water"
    },
    "Keys": {
      "Word": 9270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "ant-hill, small mound; hill"
    },
    "Keys": {
      "Hanzi": 4098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4098,
      "Radical": "土",
      "FrequencyRank": 6898,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "呼唤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūhuàn",
      "PrimaryDefinition": "to call out (a name etc), to shout"
    },
    "Keys": {
      "Word": 7107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "extend; reach; come up to; and"
    },
    "Keys": {
      "Hanzi": 52,
      "Word": 7285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.2",
      "Grade": 7,
      "GeneralStandard": 52,
      "Radical": "又",
      "HSK": 3,
      "FrequencyRank": 198,
      "StrokeCount": 3
    }
  },
  "癍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "unhealthy marks on the skin"
    },
    "Keys": {
      "Hanzi": 6290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6290,
      "Radical": "疒",
      "FrequencyRank": 6730,
      "RadicalIndex": "104.12",
      "StrokeCount": 17
    }
  },
  "棺材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāncai",
      "PrimaryDefinition": "coffin, CL:具[ju4],口[kou3]"
    },
    "Keys": {
      "Word": 6880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "危害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēihài",
      "PrimaryDefinition": "to jeopardize, to harm, to endanger, harmful effect, damage, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "妍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "beautiful, handsome; seductive"
    },
    "Keys": {
      "Hanzi": 3806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3806,
      "Radical": "女",
      "FrequencyRank": 4708,
      "RadicalIndex": "38.6",
      "StrokeCount": 7
    }
  },
  "一些": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxiē",
      "PrimaryDefinition": "det.: some, a few, a little"
    },
    "Keys": {
      "Word": 435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "隐藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐncáng",
      "PrimaryDefinition": "cache, hold back, hidden, blot_out, suppress, disguise, concealment, remain under cover, lurk, secrete, reserve, recess, hoodwink, dissemble, stash, veil, hold in, bury, hide, conceal, occult, mask, ensconce, cover_up, palliate, obscure, hoard"
    },
    "Keys": {
      "Word": 5257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "保姆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎomǔ",
      "PrimaryDefinition": "nanny, housekeeper"
    },
    "Keys": {
      "Word": 5520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒujī",
      "PrimaryDefinition": "organic"
    },
    "Keys": {
      "Word": 10474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎo",
      "PrimaryDefinition": "still waters; river in Jiangsu"
    },
    "Keys": {
      "Hanzi": 4018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4018,
      "Radical": "水",
      "FrequencyRank": 7314,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "烦躁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánzào",
      "PrimaryDefinition": "jittery, twitchy, fidgety"
    },
    "Keys": {
      "Word": 6461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "红薯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngshǔ",
      "PrimaryDefinition": "sweet potato"
    },
    "Keys": {
      "Word": 7082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沿岸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánàn",
      "PrimaryDefinition": "coast, banks, seacoast, bank"
    },
    "Keys": {
      "Word": 10185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嚆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāo",
      "PrimaryDefinition": "give forth sound, make noise"
    },
    "Keys": {
      "Hanzi": 6088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6088,
      "Radical": "口",
      "FrequencyRank": 6631,
      "RadicalIndex": "30.14",
      "StrokeCount": 16
    }
  },
  "感觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnjué",
      "PrimaryDefinition": "sense perception, feeling"
    },
    "Keys": {
      "Word": 672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "喑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "to be dumb; the sobbing of infants"
    },
    "Keys": {
      "Hanzi": 5156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5156,
      "Radical": "口",
      "FrequencyRank": 4868,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "唳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "cry of bird; cry"
    },
    "Keys": {
      "Hanzi": 4852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4852,
      "Radical": "口",
      "FrequencyRank": 5661,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "煎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "fry in fat or oil; boil in water"
    },
    "Keys": {
      "Hanzi": 3000,
      "Word": 7400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.9",
      "Grade": 7,
      "GeneralStandard": 3000,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 2893,
      "StrokeCount": 13
    }
  },
  "相关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngguān",
      "PrimaryDefinition": "cognate, relational, interrelate, associated, be interrelated, relevant, related, be mutually related"
    },
    "Keys": {
      "Word": 2042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "回信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíxìn",
      "PrimaryDefinition": "to reply, to write back, letter written in reply, CL:封[feng1]"
    },
    "Keys": {
      "Word": 3563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "葡萄酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pútaojiǔ",
      "PrimaryDefinition": "(grape) wine"
    },
    "Keys": {
      "Word": 3786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "定居": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng jū",
      "PrimaryDefinition": "settle down"
    },
    "Keys": {
      "Word": 6311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多种": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōzhǒng",
      "PrimaryDefinition": "many kinds of, multiple, diverse, multi-"
    },
    "Keys": {
      "Word": 2436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "劭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shào",
      "PrimaryDefinition": "encourage; to excel; excellent"
    },
    "Keys": {
      "Hanzi": 3817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3817,
      "Radical": "力",
      "FrequencyRank": 5012,
      "RadicalIndex": "19.5",
      "StrokeCount": 7
    }
  },
  "树林": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùlín",
      "PrimaryDefinition": "forest, motte, woods, wood, planting, grove, timberland, woodland, hurst, timber"
    },
    "Keys": {
      "Word": 2888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "凝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "coagulate; congeal; freeze"
    },
    "Keys": {
      "Hanzi": 3370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.14",
      "GeneralStandard": 3370,
      "Radical": "冫",
      "HSK": 8,
      "FrequencyRank": 1631,
      "StrokeCount": 16
    }
  },
  "叉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā",
      "PrimaryDefinition": "crotch; fork, prong"
    },
    "Keys": {
      "Hanzi": 71,
      "Word": 3305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.1",
      "Grade": 5,
      "GeneralStandard": 71,
      "Radical": "又",
      "HSK": 5,
      "FrequencyRank": 2231,
      "StrokeCount": 3
    }
  },
  "灌溉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàngài",
      "PrimaryDefinition": "irrigate"
    },
    "Keys": {
      "Word": 6894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "志愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìyuàn",
      "PrimaryDefinition": "aspiration, wish, ideal"
    },
    "Keys": {
      "Word": 2178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "慢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "slow(ly), leisurely, sluggish"
    },
    "Keys": {
      "Hanzi": 3169,
      "Word": 226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.11",
      "Grade": 1,
      "GeneralStandard": 3169,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 822,
      "StrokeCount": 14
    }
  },
  "除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "eliminate, remove, except"
    },
    "Keys": {
      "Hanzi": 1732,
      "Word": 4434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.7",
      "Grade": 6,
      "GeneralStandard": 1732,
      "Radical": "阜",
      "HSK": 3,
      "FrequencyRank": 464,
      "StrokeCount": 9
    }
  },
  "梿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4771,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "符合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúhé",
      "PrimaryDefinition": "fulfil, conform, fit, tally with, fill, come_up_to, cohere, satisfy, accord, coincide, assort, equate, answer, tally, consist, meet, be in keeping with, chime_in, fulfill, look, accord/tally with, relate, chime, live up to, square, accord with, conform to, correspond"
    },
    "Keys": {
      "Word": 2478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "潭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "deep pool, lake; deep, profound"
    },
    "Keys": {
      "Hanzi": 3293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 3293,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2750,
      "StrokeCount": 15
    }
  },
  "模式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móshì",
      "PrimaryDefinition": "example, normal, rule, formula, pattern, form, shape, style, way, mode, convention, fashion, model"
    },
    "Keys": {
      "Word": 3732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "待遇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàiyù",
      "PrimaryDefinition": "treatment (salary/etc.)"
    },
    "Keys": {
      "Word": 2382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "沙滩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shātān",
      "PrimaryDefinition": "sandy beach"
    },
    "Keys": {
      "Word": 8937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "薨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng",
      "PrimaryDefinition": "death of prince; swarming"
    },
    "Keys": {
      "Hanzi": 6050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6050,
      "Radical": "艸",
      "FrequencyRank": 5775,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "荄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāi",
      "PrimaryDefinition": "roots"
    },
    "Keys": {
      "Hanzi": 6789
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6789,
      "Radical": "艸",
      "FrequencyRank": 7739,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "抽烟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu yān",
      "PrimaryDefinition": "smoke (pipe/etc.)"
    },
    "Keys": {
      "Word": 2333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "防疫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángyì",
      "PrimaryDefinition": "disease prevention, protection against epidemic"
    },
    "Keys": {
      "Word": 6493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "结论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiélùn",
      "PrimaryDefinition": "ultimate, sequitur, consequent, ending, close, inference, closing, end matter, afterword, consequence, verdict, finality, back matter, conclusion, peroration, summation, summing-up, end, upshot, illation, outcome, conclusion (of a syllogism)"
    },
    "Keys": {
      "Word": 2629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "穜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒng",
      "PrimaryDefinition": "rice"
    },
    "Keys": {
      "Hanzi": 7949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7949,
      "Radical": "禾",
      "FrequencyRank": 8855,
      "RadicalIndex": "115.12",
      "StrokeCount": 17
    }
  },
  "峡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "gorge, strait, ravine; isthmus"
    },
    "Keys": {
      "Hanzi": 1518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.6",
      "GeneralStandard": 1518,
      "Radical": "山",
      "HSK": 7,
      "FrequencyRank": 1727,
      "StrokeCount": 9
    }
  },
  "幪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "cover, shelter, screen; protect"
    },
    "Keys": {
      "Hanzi": 7869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7869,
      "Radical": "巾",
      "RadicalIndex": "50.14",
      "StrokeCount": 16
    }
  },
  "装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāng",
      "PrimaryDefinition": "dress, clothes, attire; fill"
    },
    "Keys": {
      "Hanzi": 2726,
      "Word": 1237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.6",
      "Grade": 2,
      "GeneralStandard": 2726,
      "Radical": "衣",
      "HSK": 2,
      "FrequencyRank": 467,
      "StrokeCount": 12
    }
  },
  "簇拥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cùyōng",
      "PrimaryDefinition": "to crowd around, to escort"
    },
    "Keys": {
      "Word": 6075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "精品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngpǐn",
      "PrimaryDefinition": "plum, showpiece, fine work, quality goods, top quality article, article of fine quality"
    },
    "Keys": {
      "Word": 4749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "得以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "déyǐ",
      "PrimaryDefinition": "can, so that...can(may)..."
    },
    "Keys": {
      "Word": 3410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "顾客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùkè",
      "PrimaryDefinition": "chap, client, shopper, correspondent, constituency, patronage, trade, market, guest, clientele, newspaperman, newspaperwoman, newswriter, pressman, customer agent, customer, business"
    },
    "Keys": {
      "Word": 694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蒹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "reed, phragmites communis"
    },
    "Keys": {
      "Hanzi": 5387
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5387,
      "Radical": "艸",
      "FrequencyRank": 6704,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "焰火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànhuǒ",
      "PrimaryDefinition": "fireworks"
    },
    "Keys": {
      "Word": 10215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòdù",
      "PrimaryDefinition": "too, excessively"
    },
    "Keys": {
      "Word": 3532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "扁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn",
      "PrimaryDefinition": "flat; tablet, signboard"
    },
    "Keys": {
      "Hanzi": 1708,
      "Word": 4329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "63.5",
      "Grade": 6,
      "GeneralStandard": 1708,
      "Radical": "戶",
      "HSK": 6,
      "FrequencyRank": 1737,
      "StrokeCount": 9
    }
  },
  "籴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "purchase grains; store grain"
    },
    "Keys": {
      "Hanzi": 3974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3974,
      "Radical": "米",
      "FrequencyRank": 5564,
      "RadicalIndex": "119.2",
      "StrokeCount": 8
    }
  },
  "局面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júmiàn",
      "PrimaryDefinition": "aspect, picture, state of affairs, situation, conjuncture, facet, position, phase"
    },
    "Keys": {
      "Word": 3636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "和谐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héxié",
      "PrimaryDefinition": "harmonious, concordant"
    },
    "Keys": {
      "Word": 4661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "试探": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìtan",
      "PrimaryDefinition": "to sound out, to probe, to feel out, to try out"
    },
    "Keys": {
      "Word": 9144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "基于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīyú",
      "PrimaryDefinition": "on the basis of, in view of, because of"
    },
    "Keys": {
      "Word": 7277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "holly"
    },
    "Keys": {
      "Hanzi": 6800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6800,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "常用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángyòng",
      "PrimaryDefinition": "in common usage"
    },
    "Keys": {
      "Word": 553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "几": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī, jǐ",
      "PrimaryDefinition": "table"
    },
    "Keys": {
      "Hanzi": 14,
      "Radical": 16,
      "Word": 162
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "16",
      "Grade": 1,
      "GeneralStandard": 14,
      "Radical": "几",
      "HSK": 1,
      "FrequencyRank": 211,
      "StrokeCount": 2
    }
  },
  "疁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7867
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7867,
      "Radical": "田",
      "RadicalIndex": "102.11",
      "StrokeCount": 16
    }
  },
  "引领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnlǐng",
      "PrimaryDefinition": "to crane one's neck, to await eagerly, to lead, to show the way"
    },
    "Keys": {
      "Word": 10390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "转悠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuànyou",
      "PrimaryDefinition": "to roll, to wander around, to appear repeatedly"
    },
    "Keys": {
      "Word": 10900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肿瘤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒngliú",
      "PrimaryDefinition": "tumor"
    },
    "Keys": {
      "Word": 10823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnxìng",
      "PrimaryDefinition": "natural instincts, nature, inherent quality"
    },
    "Keys": {
      "Word": 5566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "道教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Dàojiào",
      "PrimaryDefinition": "Taoism, Daoism (Chinese system of beliefs)"
    },
    "Keys": {
      "Word": 4495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "应有尽有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngyǒu-jìnyǒu",
      "PrimaryDefinition": "everything that should be here is here (idiom), all one can think of is on hand, to have all one needs"
    },
    "Keys": {
      "Word": 10409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàihào",
      "PrimaryDefinition": "nickname"
    },
    "Keys": {
      "Word": 9637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "scaly dragon with four legs"
    },
    "Keys": {
      "Hanzi": 5150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5150,
      "Radical": "虫",
      "FrequencyRank": 3467,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "艨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "long and narrow war-boat"
    },
    "Keys": {
      "Hanzi": 6402
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6402,
      "Radical": "舟",
      "FrequencyRank": 6252,
      "RadicalIndex": "137.14",
      "StrokeCount": 19
    }
  },
  "水产品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐchǎnpǐn",
      "PrimaryDefinition": "Water products"
    },
    "Keys": {
      "Word": 3932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "快乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàilè",
      "PrimaryDefinition": "happy, cheerful"
    },
    "Keys": {
      "Word": 824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "晡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bū",
      "PrimaryDefinition": "late afternoon"
    },
    "Keys": {
      "Hanzi": 4812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4812,
      "Radical": "日",
      "FrequencyRank": 6142,
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "地带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìdài",
      "PrimaryDefinition": "belt, side, corridor, zone, geographical zone, terrain, region, district"
    },
    "Keys": {
      "Word": 3413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "羱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "sheep"
    },
    "Keys": {
      "Hanzi": 7895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7895,
      "Radical": "羊",
      "RadicalIndex": "123.1",
      "StrokeCount": 16
    }
  },
  "宝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": "treasure, jewel; precious, rare"
    },
    "Keys": {
      "Hanzi": 1274,
      "Word": 2247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "Grade": 4,
      "GeneralStandard": 1274,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 811,
      "StrokeCount": 8
    }
  },
  "下周": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàzhōu",
      "PrimaryDefinition": "next week"
    },
    "Keys": {
      "Word": 1079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "小于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoyú",
      "PrimaryDefinition": "less than, <"
    },
    "Keys": {
      "Word": 5171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "前来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánlái",
      "PrimaryDefinition": "come"
    },
    "Keys": {
      "Word": 4932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "䴗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jú",
      "PrimaryDefinition": "a shrike"
    },
    "Keys": {
      "Hanzi": 7668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7668,
      "Radical": "",
      "FrequencyRank": 7526,
      "RadicalIndex": "",
      "StrokeCount": 20
    }
  },
  "如愿以偿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúyuàn-yǐcháng",
      "PrimaryDefinition": "to have one's wish fulfilled"
    },
    "Keys": {
      "Word": 8897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngqián",
      "PrimaryDefinition": "current, today's, modern, present, to be facing (us)"
    },
    "Keys": {
      "Word": 3403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "定为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng wèi",
      "PrimaryDefinition": "set to"
    },
    "Keys": {
      "Word": 6313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敲诈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāozhà",
      "PrimaryDefinition": "to rip off, to extort (money), extortion, blackmail"
    },
    "Keys": {
      "Word": 8700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸟巢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎocháo",
      "PrimaryDefinition": "bird's nest, nickname for Beijing 2008 Olympic stadium"
    },
    "Keys": {
      "Word": 8421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "保持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎochí",
      "PrimaryDefinition": "keep, maintain, preserve"
    },
    "Keys": {
      "Word": 1276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "忪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "quiet, calm, tranquil, peaceful"
    },
    "Keys": {
      "Hanzi": 3790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3790,
      "Radical": "心",
      "FrequencyRank": 5274,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "畅通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàngtōng",
      "PrimaryDefinition": "unimpeded, free-flowing, straight path, unclogged, move without obstruction"
    },
    "Keys": {
      "Word": 4398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "椿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūn",
      "PrimaryDefinition": "father; a plant with white flowers"
    },
    "Keys": {
      "Hanzi": 2862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2862,
      "Radical": "木",
      "FrequencyRank": 4197,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "皋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "the high land along a river"
    },
    "Keys": {
      "Hanzi": 4579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4579,
      "Radical": "白",
      "FrequencyRank": 3983,
      "RadicalIndex": "106.5",
      "StrokeCount": 10
    }
  },
  "幡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān",
      "PrimaryDefinition": "pennant, banner, streamer, flag"
    },
    "Keys": {
      "Hanzi": 5940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5940,
      "Radical": "巾",
      "FrequencyRank": 3437,
      "RadicalIndex": "50.12",
      "StrokeCount": 15
    }
  },
  "求证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúzhèng",
      "PrimaryDefinition": "to seek proof, to seek confirmation"
    },
    "Keys": {
      "Word": 8773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "招揽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāolǎn",
      "PrimaryDefinition": "to attract (customers), to drum up (trade)"
    },
    "Keys": {
      "Word": 10664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大臣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàchén",
      "PrimaryDefinition": "courtier, court, secretary, referendary, vizier, government minister, viziership, high officials, cabinet ministers, president, chancellor, minister"
    },
    "Keys": {
      "Word": 6112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "古怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔguài",
      "PrimaryDefinition": "eccentric, grotesque, oddly, queer"
    },
    "Keys": {
      "Word": 6825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "很难说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hěn nánshuō",
      "PrimaryDefinition": "Hard to say"
    },
    "Keys": {
      "Word": 4662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "妣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3809,
      "Radical": "女",
      "FrequencyRank": 6159,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "茚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìn",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 3866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3866,
      "Radical": "艸",
      "FrequencyRank": 7764,
      "RadicalIndex": "140.6",
      "StrokeCount": 8
    }
  },
  "腋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "armpit"
    },
    "Keys": {
      "Hanzi": 2714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2714,
      "Radical": "肉",
      "FrequencyRank": 3593,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "猜谜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāi mí",
      "PrimaryDefinition": "Guess"
    },
    "Keys": {
      "Word": 5732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倒闭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎobì",
      "PrimaryDefinition": "to go bankrupt, to close down"
    },
    "Keys": {
      "Word": 2392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "坐落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòluò",
      "PrimaryDefinition": "to be situated, to be located (of a building)"
    },
    "Keys": {
      "Word": 11030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一路平安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlù-píngān",
      "PrimaryDefinition": "to have a pleasant journey, Bon voyage!"
    },
    "Keys": {
      "Word": 1138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "哱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6987,
      "Radical": "口",
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "倘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎng",
      "PrimaryDefinition": "if, supposing, in event of"
    },
    "Keys": {
      "Hanzi": 1961
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1961,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2118,
      "StrokeCount": 10
    }
  },
  "篇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piān",
      "PrimaryDefinition": "chapter, section, essay, article"
    },
    "Keys": {
      "Hanzi": 3268,
      "Word": 905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.9",
      "Grade": 2,
      "GeneralStandard": 3268,
      "Radical": "竹",
      "HSK": 2,
      "FrequencyRank": 1008,
      "StrokeCount": 15
    }
  },
  "恩人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēnrén",
      "PrimaryDefinition": "a benefactor, a person who has significantly helped sb else"
    },
    "Keys": {
      "Word": 4534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "推行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīxíng",
      "PrimaryDefinition": "carry out, practice"
    },
    "Keys": {
      "Word": 3992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "樘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5887,
      "Radical": "木",
      "FrequencyRank": 5948,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "生日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngri",
      "PrimaryDefinition": "birthday"
    },
    "Keys": {
      "Word": 332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "惎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "injure, harm, murder"
    },
    "Keys": {
      "Hanzi": 7302
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7302,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 12
    }
  },
  "补考": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔkǎo",
      "PrimaryDefinition": "to sit for a makeup exam, to resit an exam, makeup exam, resit"
    },
    "Keys": {
      "Word": 4349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "衰老": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāilǎo",
      "PrimaryDefinition": "to age, to deteriorate with age, old and weak"
    },
    "Keys": {
      "Word": 9244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "从来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cónglái",
      "PrimaryDefinition": "all along, at all times, always"
    },
    "Keys": {
      "Word": 1375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "能量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néngliàng",
      "PrimaryDefinition": "energy, capabilities"
    },
    "Keys": {
      "Word": 3743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无辜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúgū",
      "PrimaryDefinition": "innocent, innocence, not guilty (law)"
    },
    "Keys": {
      "Word": 9765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生词": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngcí",
      "PrimaryDefinition": "new word (in textbook), word that is unfamiliar or not yet studied, CL:組|组[zu3],個|个[ge4]"
    },
    "Keys": {
      "Word": 970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "棍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùn",
      "PrimaryDefinition": "stick, cudgel; scoundrel"
    },
    "Keys": {
      "Hanzi": 2572,
      "Word": 6927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "Grade": 7,
      "GeneralStandard": 2572,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2255,
      "StrokeCount": 12
    }
  },
  "包含": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāohán",
      "PrimaryDefinition": "comprehend, consist of, subtend, imply, boast, go, colligate, include, hold, encompass, embrace, bear, carry, comprise, incorporate, cover, embody, connote, consist in, house, subsume, contain"
    },
    "Keys": {
      "Word": 2248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "微型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēixíng",
      "PrimaryDefinition": "miniature, micro-, tiny"
    },
    "Keys": {
      "Word": 9693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "髀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "buttocks; thigh, thigh bone"
    },
    "Keys": {
      "Hanzi": 6244
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6244,
      "Radical": "骨",
      "FrequencyRank": 5978,
      "RadicalIndex": "188.8",
      "StrokeCount": 17
    }
  },
  "缤纷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīnfēn",
      "PrimaryDefinition": "vast and various, rich and diverse"
    },
    "Keys": {
      "Word": 5645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "lineage, ancestry; ancestor, clan"
    },
    "Keys": {
      "Hanzi": 1275,
      "Word": 10978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "Grade": 7,
      "GeneralStandard": 1275,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 727,
      "StrokeCount": 8
    }
  },
  "匆忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōngmáng",
      "PrimaryDefinition": "hasty, hurried"
    },
    "Keys": {
      "Word": 6055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卫生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèishēng",
      "PrimaryDefinition": "hygiene, health, sanitation"
    },
    "Keys": {
      "Word": 2008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "便是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànshì",
      "PrimaryDefinition": "even if"
    },
    "Keys": {
      "Word": 4334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "球迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúmí",
      "PrimaryDefinition": "fan (ball sports), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "预言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùyán",
      "PrimaryDefinition": "to predict, prophecy"
    },
    "Keys": {
      "Word": 10522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宣传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānchuán",
      "PrimaryDefinition": "propagandist, publicist"
    },
    "Keys": {
      "Word": 2071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "狉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "fox-cub"
    },
    "Keys": {
      "Hanzi": 6712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6712,
      "Radical": "犬",
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "醮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "religious service; Daoist or Buddhist ceremony"
    },
    "Keys": {
      "Hanzi": 6375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6375,
      "Radical": "酉",
      "FrequencyRank": 4056,
      "RadicalIndex": "164.12",
      "StrokeCount": 19
    }
  },
  "拿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ná",
      "PrimaryDefinition": "take, hold, grasp; bring; with"
    },
    "Keys": {
      "Hanzi": 1983,
      "Word": 250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 1,
      "GeneralStandard": 1983,
      "Radical": "手",
      "HSK": 1,
      "FrequencyRank": 645,
      "StrokeCount": 10
    }
  },
  "胧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "condition or appearance of moon"
    },
    "Keys": {
      "Hanzi": 1592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "GeneralStandard": 1592,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 2837,
      "StrokeCount": 9
    }
  },
  "除非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúfēi",
      "PrimaryDefinition": "conj.: only if/when, unless"
    },
    "Keys": {
      "Word": 3346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瑔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7468,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "午": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "noon; 7th terrestrial branch"
    },
    "Keys": {
      "Hanzi": 119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.2",
      "GeneralStandard": 119,
      "Radical": "十",
      "HSK": 1,
      "FrequencyRank": 1004,
      "StrokeCount": 4
    }
  },
  "交警": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojǐng",
      "PrimaryDefinition": "traffic police, abbr. for 交通警察"
    },
    "Keys": {
      "Word": 1608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "航海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánghǎi",
      "PrimaryDefinition": "sailing, navigation, voyage by sea"
    },
    "Keys": {
      "Word": 6994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "tutor, teacher; assist; surname"
    },
    "Keys": {
      "Hanzi": 2691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.1",
      "GeneralStandard": 2691,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1887,
      "StrokeCount": 12
    }
  },
  "课程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèchéng",
      "PrimaryDefinition": "course of instruction, lesson, course, course of lectures, course of study, curricular, class, curricula, syllabus, curriculum, program"
    },
    "Keys": {
      "Word": 1676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "冫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīng",
      "PrimaryDefinition": "ice"
    },
    "Keys": {
      "Radical": 15
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "僭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "assume, usurp"
    },
    "Keys": {
      "Hanzi": 5758
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5758,
      "Radical": "人",
      "FrequencyRank": 3820,
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "选手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnshǒu",
      "PrimaryDefinition": "participant, basketeer, athlete selected for a sports meet, basketball player, jock, contestant, cap, cager, athlete, player, champ, performer, player selected as contestant"
    },
    "Keys": {
      "Word": 2073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "薿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǐ",
      "PrimaryDefinition": "flower"
    },
    "Keys": {
      "Hanzi": 7923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7923,
      "Radical": "艸",
      "FrequencyRank": 7294,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "澌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "to exhaust; to drain dry"
    },
    "Keys": {
      "Hanzi": 5998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5998,
      "Radical": "水",
      "FrequencyRank": 6217,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "不翼而飞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyìérfēi",
      "PrimaryDefinition": "to disappear without trace, to vanish all of a sudden, to spread fast, to spread like wildfire"
    },
    "Keys": {
      "Word": 5683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "muscle tissue; meat on bones"
    },
    "Keys": {
      "Hanzi": 495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.2",
      "GeneralStandard": 495,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1935,
      "StrokeCount": 6
    }
  },
  "笺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "note, memo; stationery; comments"
    },
    "Keys": {
      "Hanzi": 4893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4893,
      "Radical": "竹",
      "FrequencyRank": 3731,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "伪造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěizào",
      "PrimaryDefinition": "to forge, to fake, to counterfeit"
    },
    "Keys": {
      "Word": 9702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "请教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐngjiào",
      "PrimaryDefinition": "consultation, Please enlighten me., seek advice, take counsel, consult, ask, ask for advice"
    },
    "Keys": {
      "Word": 1821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "骓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuī",
      "PrimaryDefinition": "piebald horse"
    },
    "Keys": {
      "Hanzi": 5034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5034,
      "Radical": "馬",
      "FrequencyRank": 5258,
      "RadicalIndex": "187.8",
      "StrokeCount": 11
    }
  },
  "个体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gètǐ",
      "PrimaryDefinition": "unit, individual, individuality"
    },
    "Keys": {
      "Word": 2498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "滘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "a branching river (used in place names)"
    },
    "Keys": {
      "Hanzi": 7596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7596,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "白菜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báicài",
      "PrimaryDefinition": "Chinese cabbage, pak choi, CL:棵[ke1], 個|个[ge4]"
    },
    "Keys": {
      "Word": 1268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鲑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "salmon; spheroides vermicularis"
    },
    "Keys": {
      "Hanzi": 5768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5768,
      "Radical": "魚",
      "FrequencyRank": 4340,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "霍乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòluàn",
      "PrimaryDefinition": "cholera"
    },
    "Keys": {
      "Word": 7259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "作物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòwù",
      "PrimaryDefinition": "crop"
    },
    "Keys": {
      "Word": 11029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耻笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐxiào",
      "PrimaryDefinition": "to sneer at sb, to ridicule"
    },
    "Keys": {
      "Word": 5909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "等到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děngdào",
      "PrimaryDefinition": "until"
    },
    "Keys": {
      "Word": 614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "盹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔn",
      "PrimaryDefinition": "to doze; to nap; to nod"
    },
    "Keys": {
      "Hanzi": 1478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "GeneralStandard": 1478,
      "Radical": "目",
      "HSK": 9,
      "FrequencyRank": 4220,
      "StrokeCount": 9
    }
  },
  "摆设": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎishe",
      "PrimaryDefinition": "furnish and decorate (room)"
    },
    "Keys": {
      "Word": 5477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "册": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cè",
      "PrimaryDefinition": "book, volume, register, list"
    },
    "Keys": {
      "Hanzi": 290,
      "Word": 3298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "13.3",
      "Grade": 5,
      "GeneralStandard": 290,
      "Radical": "冂",
      "HSK": 5,
      "FrequencyRank": 1525,
      "StrokeCount": 5
    }
  },
  "人气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénqì",
      "PrimaryDefinition": "popularity, personality, character"
    },
    "Keys": {
      "Word": 8844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "round or oval covered-baskets with short legs"
    },
    "Keys": {
      "Hanzi": 7877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7877,
      "Radical": "竹",
      "FrequencyRank": 7186,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "煴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūn",
      "PrimaryDefinition": "sultriness, stuffiness"
    },
    "Keys": {
      "Hanzi": 7583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7583,
      "Radical": "火",
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "硭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "a crude saltpetre"
    },
    "Keys": {
      "Hanzi": 4790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4790,
      "Radical": "石",
      "FrequencyRank": 6039,
      "RadicalIndex": "112.7",
      "StrokeCount": 11
    }
  },
  "赴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "go to; attend, be present"
    },
    "Keys": {
      "Hanzi": 1377,
      "Word": 6632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.2",
      "Grade": 7,
      "GeneralStandard": 1377,
      "Radical": "走",
      "HSK": 8,
      "FrequencyRank": 1843,
      "StrokeCount": 9
    }
  },
  "篯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7878,
      "Radical": "竹",
      "FrequencyRank": 5877,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "槚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "small evergreen shrub"
    },
    "Keys": {
      "Hanzi": 7649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7649,
      "Radical": "木",
      "RadicalIndex": "75.13",
      "StrokeCount": 14
    }
  },
  "不难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù nán",
      "PrimaryDefinition": "Not difficult"
    },
    "Keys": {
      "Word": 5707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "few, scarce; empty, deserted"
    },
    "Keys": {
      "Hanzi": 5816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5816,
      "Radical": "宀",
      "FrequencyRank": 5537,
      "RadicalIndex": "40.11",
      "StrokeCount": 14
    }
  },
  "嶂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "cliff; mountain barrier"
    },
    "Keys": {
      "Hanzi": 5726
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5726,
      "Radical": "山",
      "FrequencyRank": 4869,
      "RadicalIndex": "46.11",
      "StrokeCount": 14
    }
  },
  "重量级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngliàngjí",
      "PrimaryDefinition": "heavyweight (boxing etc)"
    },
    "Keys": {
      "Word": 10831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn chéng",
      "PrimaryDefinition": "divide, split a bonus, divide into, divide (into)"
    },
    "Keys": {
      "Word": 3456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "平面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngmiàn",
      "PrimaryDefinition": "smooth, planar, planeness, plane, tabulate, level, flatness, two-dimensionality, table, sheet, horizontal surface, flat"
    },
    "Keys": {
      "Word": 8550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěshì",
      "PrimaryDefinition": "conj.: but, yet, however"
    },
    "Keys": {
      "Word": 814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "悚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒng",
      "PrimaryDefinition": "afraid, scared, frightened"
    },
    "Keys": {
      "Hanzi": 4659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4659,
      "Radical": "心",
      "FrequencyRank": 3552,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "嬛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "apt, clever; sycophant, flatterer"
    },
    "Keys": {
      "Hanzi": 7913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7913,
      "Radical": "女",
      "RadicalIndex": "38.13",
      "StrokeCount": 16
    }
  },
  "壮实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngshi",
      "PrimaryDefinition": "robust, sturdy"
    },
    "Keys": {
      "Word": 10917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěn",
      "PrimaryDefinition": "consult carefully with, counsel"
    },
    "Keys": {
      "Hanzi": 4676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4676,
      "Radical": "言",
      "FrequencyRank": 6058,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "春节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Chūn Jié",
      "PrimaryDefinition": "Spring Festival (Chinese New Year)"
    },
    "Keys": {
      "Word": 574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "和平共处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hépínggòngchǔ",
      "PrimaryDefinition": "peaceful coexistence of nations, societies etc"
    },
    "Keys": {
      "Word": 7040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "最初": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìchū",
      "PrimaryDefinition": "first, primary, initial, original, at first, initially, originally"
    },
    "Keys": {
      "Word": 3219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "叱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "scold, shout at, bawl out"
    },
    "Keys": {
      "Hanzi": 3539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3539,
      "Radical": "口",
      "FrequencyRank": 3181,
      "RadicalIndex": "30.2",
      "StrokeCount": 5
    }
  },
  "优惠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuhuì",
      "PrimaryDefinition": "give preferential/special treatment"
    },
    "Keys": {
      "Word": 4132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "上映": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngyìng",
      "PrimaryDefinition": "to show (a movie), to screen"
    },
    "Keys": {
      "Word": 8987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "误导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùdǎo",
      "PrimaryDefinition": "to mislead, to misguide, misleading"
    },
    "Keys": {
      "Word": 9812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "繁忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánmáng",
      "PrimaryDefinition": "busy"
    },
    "Keys": {
      "Word": 6463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水泥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐní",
      "PrimaryDefinition": "cement"
    },
    "Keys": {
      "Word": 5043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "进一步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnyībù",
      "PrimaryDefinition": "go step further"
    },
    "Keys": {
      "Word": 1633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "潢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "expanse of water, lake, pond"
    },
    "Keys": {
      "Hanzi": 5800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5800,
      "Radical": "水",
      "FrequencyRank": 4551,
      "RadicalIndex": "85.12",
      "StrokeCount": 14
    }
  },
  "浃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "saturate, drench; damp, wet"
    },
    "Keys": {
      "Hanzi": 4335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4335,
      "Radical": "水",
      "FrequencyRank": 4960,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "蒽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēn",
      "PrimaryDefinition": "anthracene"
    },
    "Keys": {
      "Hanzi": 5375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5375,
      "Radical": "艸",
      "FrequencyRank": 6909,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "帮助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāngzhù",
      "PrimaryDefinition": "aid, bridge_over, help, bestead, bring_on, tide_over, serve, support, facilitate, accommodate, carry_through, assist, administer, attend_to, assistance, befriend, further, favour"
    },
    "Keys": {
      "Word": 508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "桑拿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāngná",
      "PrimaryDefinition": "sauna (loanword)"
    },
    "Keys": {
      "Word": 8921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "precious, valuable, rare"
    },
    "Keys": {
      "Hanzi": 1358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.5",
      "GeneralStandard": 1358,
      "Radical": "玉",
      "HSK": 5,
      "FrequencyRank": 1314,
      "StrokeCount": 9
    }
  },
  "插": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā",
      "PrimaryDefinition": "insert, stick into; plant"
    },
    "Keys": {
      "Hanzi": 2526,
      "Word": 3304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 5,
      "GeneralStandard": 2526,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1495,
      "StrokeCount": 12
    }
  },
  "出身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūshēn",
      "PrimaryDefinition": "class origin, family background;one's previous experience/occupation"
    },
    "Keys": {
      "Word": 5966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "sickle"
    },
    "Keys": {
      "Hanzi": 3444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3444,
      "Radical": "金",
      "FrequencyRank": 3824,
      "RadicalIndex": "167.13",
      "StrokeCount": 18
    }
  },
  "昱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "bright light, sunlight; dazzling"
    },
    "Keys": {
      "Hanzi": 4200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4200,
      "Radical": "日",
      "FrequencyRank": 4596,
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "挑起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎoqǐ",
      "PrimaryDefinition": "to provoke, to stir up, to incite"
    },
    "Keys": {
      "Word": 9503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嵇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "mountain in Henan; surname"
    },
    "Keys": {
      "Hanzi": 5201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5201,
      "Radical": "山",
      "FrequencyRank": 4138,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "怨气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànqì",
      "PrimaryDefinition": "grievance, resentment, complaint"
    },
    "Keys": {
      "Word": 10557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7328,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "thick; substantial; greatly"
    },
    "Keys": {
      "Hanzi": 1449,
      "Word": 2546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "27.7",
      "Grade": 4,
      "GeneralStandard": 1449,
      "Radical": "厂",
      "HSK": 4,
      "FrequencyRank": 1235,
      "StrokeCount": 9
    }
  },
  "蘘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráng",
      "PrimaryDefinition": "a kind of wild ginger"
    },
    "Keys": {
      "Hanzi": 8052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8052,
      "Radical": "艸",
      "FrequencyRank": 8825,
      "RadicalIndex": "140.17",
      "StrokeCount": 20
    }
  },
  "僖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "joy, gladness, delight; surname"
    },
    "Keys": {
      "Hanzi": 5755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5755,
      "Radical": "人",
      "FrequencyRank": 4739,
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "绊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "loop, catch; fetter, shackle"
    },
    "Keys": {
      "Hanzi": 1346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1346,
      "Radical": "糸",
      "FrequencyRank": 3183,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "绰号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuòhào",
      "PrimaryDefinition": "nickname"
    },
    "Keys": {
      "Word": 6034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赛跑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàipǎo",
      "PrimaryDefinition": "race (running), to race (running)"
    },
    "Keys": {
      "Word": 8917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夺冠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duó guàn",
      "PrimaryDefinition": "to seize the crown, fig. to win a championship, to win gold medal"
    },
    "Keys": {
      "Word": 6402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "man, male adult, husband; those"
    },
    "Keys": {
      "Hanzi": 79
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.1",
      "GeneralStandard": 79,
      "Radical": "大",
      "HSK": 3,
      "FrequencyRank": 377,
      "StrokeCount": 4
    }
  },
  "署": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "public office"
    },
    "Keys": {
      "Hanzi": 2930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "122.8",
      "GeneralStandard": 2930,
      "Radical": "网",
      "HSK": 7,
      "FrequencyRank": 1379,
      "StrokeCount": 13
    }
  },
  "视角": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjiǎo",
      "PrimaryDefinition": "viewpoint, angle on sth, perspective"
    },
    "Keys": {
      "Word": 9149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "a variety of bivalves"
    },
    "Keys": {
      "Hanzi": 4511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4511,
      "Radical": "虫",
      "FrequencyRank": 5664,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "穿上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuānshang",
      "PrimaryDefinition": "assume, wear, put_on, slip, enclothe, put on"
    },
    "Keys": {
      "Word": 2339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "半途而废": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàntúérfèi",
      "PrimaryDefinition": "to give up halfway (idiom), leave sth unfinished"
    },
    "Keys": {
      "Word": 5496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnqī",
      "PrimaryDefinition": "the current period, this term (usually in finance)"
    },
    "Keys": {
      "Word": 4318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "莘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "long; numerous; a marsh plant whose root is used for medicine"
    },
    "Keys": {
      "Hanzi": 4441
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4441,
      "Radical": "艸",
      "FrequencyRank": 4432,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "撰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "compose, write, compile"
    },
    "Keys": {
      "Hanzi": 3205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "GeneralStandard": 3205,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 2300,
      "StrokeCount": 15
    }
  },
  "匙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shi",
      "PrimaryDefinition": "spoon; surname"
    },
    "Keys": {
      "Hanzi": 2259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "21.9",
      "GeneralStandard": 2259,
      "Radical": "匕",
      "HSK": 7,
      "FrequencyRank": 2476,
      "StrokeCount": 11
    }
  },
  "一心一意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxīn-yīyì",
      "PrimaryDefinition": "concentrating one's thoughts and efforts, single-minded, bent on, intently"
    },
    "Keys": {
      "Word": 10349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情谊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngyì",
      "PrimaryDefinition": "friendship, camaraderie"
    },
    "Keys": {
      "Word": 8761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuò",
      "PrimaryDefinition": "promise; assent, approve"
    },
    "Keys": {
      "Hanzi": 2111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "GeneralStandard": 2111,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 969,
      "StrokeCount": 10
    }
  },
  "但愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànyuàn",
      "PrimaryDefinition": "wish, if only..., I wish that..., may, might"
    },
    "Keys": {
      "Word": 6166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "支援": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīyuán",
      "PrimaryDefinition": "backstop, support, side, help, patronize, root, stand by, bear, angel, hold out, back, see through, assist, assistance, sustain, back up"
    },
    "Keys": {
      "Word": 5347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "察觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chájué",
      "PrimaryDefinition": "realize, be aware"
    },
    "Keys": {
      "Word": 5792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "continue, carry on; succeed"
    },
    "Keys": {
      "Hanzi": 2488,
      "Word": 10106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "Grade": 7,
      "GeneralStandard": 2488,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 552,
      "StrokeCount": 11
    }
  },
  "宣布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānbù",
      "PrimaryDefinition": "declare, proclaim, announce"
    },
    "Keys": {
      "Word": 2070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": "much, many; more than, over"
    },
    "Keys": {
      "Hanzi": 507,
      "Word": 641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "36.3",
      "Grade": 2,
      "GeneralStandard": 507,
      "Radical": "夕",
      "HSK": 1,
      "FrequencyRank": 61,
      "StrokeCount": 6
    }
  },
  "嗽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòu",
      "PrimaryDefinition": "cough, gargle, clear throat"
    },
    "Keys": {
      "Hanzi": 3103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.11",
      "GeneralStandard": 3103,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2815,
      "StrokeCount": 14
    }
  },
  "捐献": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juānxiàn",
      "PrimaryDefinition": "to donate, to contribute, donation, contribution"
    },
    "Keys": {
      "Word": 7713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茄子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiézi",
      "PrimaryDefinition": "eggplant (Solanum melongena L.), aubergine, brinjal, Guinea squash, phonetic 'cheese' (when being photographed), equivalent of 'say cheese'"
    },
    "Keys": {
      "Word": 4940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "茎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "stem, stalk"
    },
    "Keys": {
      "Hanzi": 1014,
      "Word": 7590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "Grade": 7,
      "GeneralStandard": 1014,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2675,
      "StrokeCount": 8
    }
  },
  "而且": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "érqiě",
      "PrimaryDefinition": "conj.: furthermore, and"
    },
    "Keys": {
      "Word": 647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "束缚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùfù",
      "PrimaryDefinition": "tie up, fetter"
    },
    "Keys": {
      "Word": 9229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "零件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língjiàn",
      "PrimaryDefinition": "section, part, components, spares, spare parts, parts, accessory"
    },
    "Keys": {
      "Word": 8073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家庭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiātíng",
      "PrimaryDefinition": "hearthstone, background, fireside, establishment, household, hearth, domestic, hearthside, menage, home, family unit, family, house"
    },
    "Keys": {
      "Word": 752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "怩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "shy, timid, bashful; look ashamed"
    },
    "Keys": {
      "Hanzi": 4032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4032,
      "Radical": "心",
      "FrequencyRank": 4766,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "盲目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mángmù",
      "PrimaryDefinition": "blind"
    },
    "Keys": {
      "Word": 8207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "to roast; to burn"
    },
    "Keys": {
      "Hanzi": 6172
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6172,
      "Radical": "火",
      "FrequencyRank": 4442,
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "淀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "shallow water, swamp; swampy"
    },
    "Keys": {
      "Hanzi": 2431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2431,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3159,
      "StrokeCount": 11
    }
  },
  "掉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": "turn, move; shake, wag; drop down"
    },
    "Keys": {
      "Hanzi": 2170,
      "Word": 626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 2,
      "GeneralStandard": 2170,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 849,
      "StrokeCount": 11
    }
  },
  "镪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "coins, money, wealth"
    },
    "Keys": {
      "Hanzi": 6251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6251,
      "Radical": "金",
      "FrequencyRank": 6190,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "剂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "medicinal preparation"
    },
    "Keys": {
      "Hanzi": 1216,
      "Word": 7332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 7,
      "GeneralStandard": 1216,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 1546,
      "StrokeCount": 8
    }
  },
  "责": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "GeneralStandard": 948,
      "Radical": "貝",
      "HSK": 3,
      "FrequencyRank": 535,
      "StrokeCount": 8
    }
  },
  "匦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "small box; chest, casket"
    },
    "Keys": {
      "Hanzi": 3835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3835,
      "Radical": "匚",
      "FrequencyRank": 4956,
      "RadicalIndex": "22.6",
      "StrokeCount": 8
    }
  },
  "姓氏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngshì",
      "PrimaryDefinition": "family name"
    },
    "Keys": {
      "Word": 10063
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "固然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùrán",
      "PrimaryDefinition": "conj.: though, although"
    },
    "Keys": {
      "Word": 6838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "examine, investigate; delay"
    },
    "Keys": {
      "Hanzi": 3260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.1",
      "GeneralStandard": 3260,
      "Radical": "禾",
      "HSK": 7,
      "FrequencyRank": 2427,
      "StrokeCount": 15
    }
  },
  "扭转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔzhuǎn",
      "PrimaryDefinition": "turn round/back, reverse, remedy (a situation)"
    },
    "Keys": {
      "Word": 8431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "塾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shú",
      "PrimaryDefinition": "village school; private tutorage"
    },
    "Keys": {
      "Hanzi": 5779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5779,
      "Radical": "土",
      "FrequencyRank": 3867,
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "go, depart; past, formerly"
    },
    "Keys": {
      "Hanzi": 1160,
      "Word": 1058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "60.5",
      "Grade": 2,
      "GeneralStandard": 1160,
      "Radical": "彳",
      "HSK": 2,
      "FrequencyRank": 369,
      "StrokeCount": 8
    }
  },
  "小溪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoxī",
      "PrimaryDefinition": "brook, streamlet"
    },
    "Keys": {
      "Word": 9974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "black clods of earth; shop, hut"
    },
    "Keys": {
      "Hanzi": 3841
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3841,
      "Radical": "土",
      "FrequencyRank": 6454,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "好好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎohǎo",
      "PrimaryDefinition": "well, carefully, nicely, properly"
    },
    "Keys": {
      "Word": 1540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "赶不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnbushàng",
      "PrimaryDefinition": "can't keep up with, can't catch up with, cannot overtake"
    },
    "Keys": {
      "Word": 4584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "晴天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngtiān",
      "PrimaryDefinition": "clear sky, sunny day"
    },
    "Keys": {
      "Word": 933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "攥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuàn",
      "PrimaryDefinition": "(coll.) hold; grip; grasp"
    },
    "Keys": {
      "Hanzi": 6480
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6480,
      "Radical": "手",
      "FrequencyRank": 3827,
      "RadicalIndex": "64.2",
      "StrokeCount": 23
    }
  },
  "盗窃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàoqiè",
      "PrimaryDefinition": "to steal"
    },
    "Keys": {
      "Word": 6203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "boundary, border, frontier"
    },
    "Keys": {
      "Hanzi": 3472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.14",
      "GeneralStandard": 3472,
      "Radical": "田",
      "HSK": 9,
      "FrequencyRank": 2036,
      "StrokeCount": 19
    }
  },
  "裨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "aid, benefit, help; supplement"
    },
    "Keys": {
      "Hanzi": 5598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5598,
      "Radical": "衣",
      "FrequencyRank": 4230,
      "RadicalIndex": "145.8",
      "StrokeCount": 13
    }
  },
  "运动员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùndòngyuán",
      "PrimaryDefinition": "athlete, CL:名[ming2],個|个[ge4]"
    },
    "Keys": {
      "Word": 3139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "辐射": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúshè",
      "PrimaryDefinition": "radiancy, Geigers, radiation, radiant"
    },
    "Keys": {
      "Word": 6608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngyù",
      "PrimaryDefinition": "demesne, line of business, field of operation, country, background, terrain, realm, part, arena, sphere of influence, setting, territory, precinct, radius, scope, domain, sphere, frontier, orbit, preserve, area, world, kingdom, ambit, signory, province, region, field, universe, hemisphere"
    },
    "Keys": {
      "Word": 8087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "签约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān yuē",
      "PrimaryDefinition": "sign a contract"
    },
    "Keys": {
      "Word": 3800
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "赇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "bribe"
    },
    "Keys": {
      "Hanzi": 4862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4862,
      "Radical": "貝",
      "FrequencyRank": 7106,
      "RadicalIndex": "154.7",
      "StrokeCount": 11
    }
  },
  "知足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīzú",
      "PrimaryDefinition": "content with one's situation, to know contentment (hence happiness)"
    },
    "Keys": {
      "Word": 10750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当今": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngjīn",
      "PrimaryDefinition": "current, present, now, nowadays"
    },
    "Keys": {
      "Word": 6173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "fluorine"
    },
    "Keys": {
      "Hanzi": 4248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4248,
      "Radical": "气",
      "FrequencyRank": 3701,
      "RadicalIndex": "84.5",
      "StrokeCount": 9
    }
  },
  "如何": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúhé",
      "PrimaryDefinition": "how"
    },
    "Keys": {
      "Word": 1860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "狮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "lion"
    },
    "Keys": {
      "Hanzi": 1601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.6",
      "GeneralStandard": 1601,
      "Radical": "犬",
      "HSK": 7,
      "FrequencyRank": 2211,
      "StrokeCount": 9
    }
  },
  "摩托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mótuō",
      "PrimaryDefinition": "motor (loanword), motorbike"
    },
    "Keys": {
      "Word": 3734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "酡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "flushed; rubicund"
    },
    "Keys": {
      "Hanzi": 5110
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5110,
      "Radical": "酉",
      "FrequencyRank": 6450,
      "RadicalIndex": "164.5",
      "StrokeCount": 12
    }
  },
  "谡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "rise up; raise up"
    },
    "Keys": {
      "Hanzi": 5312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5312,
      "Radical": "言",
      "FrequencyRank": 4258,
      "RadicalIndex": "149.1",
      "StrokeCount": 12
    }
  },
  "勉强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎnqiǎng",
      "PrimaryDefinition": "do with difficulty"
    },
    "Keys": {
      "Word": 8280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羡慕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànmù",
      "PrimaryDefinition": "to envy, to admire"
    },
    "Keys": {
      "Word": 9911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēng",
      "PrimaryDefinition": "swelling, rising, dispersing"
    },
    "Keys": {
      "Hanzi": 5581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5581,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "不安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùān",
      "PrimaryDefinition": "be anxious; be uneasy; be restless"
    },
    "Keys": {
      "Word": 1320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùguǎn",
      "PrimaryDefinition": "conj.: no matter, regardless of"
    },
    "Keys": {
      "Word": 2279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "泄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "leak; vent; flow; reveal"
    },
    "Keys": {
      "Hanzi": 1242,
      "Word": 9994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 7,
      "GeneralStandard": 1242,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2010,
      "StrokeCount": 8
    }
  },
  "哌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pài",
      "PrimaryDefinition": "used in translation"
    },
    "Keys": {
      "Hanzi": 4221
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4221,
      "Radical": "口",
      "FrequencyRank": 5755,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "出卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūmài",
      "PrimaryDefinition": "sell_out, traffic, sell out, barter_away, peach, sell sb. down the river, lead astray, betray, sell, betrayal, go_back_on, offer for sale, double-cross, deceive"
    },
    "Keys": {
      "Word": 5960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénqíng",
      "PrimaryDefinition": "human feelings, favor, gift, present"
    },
    "Keys": {
      "Word": 8843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "step, pace; walk, stroll"
    },
    "Keys": {
      "Hanzi": 710,
      "Word": 1324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "77.3",
      "Grade": 3,
      "GeneralStandard": 710,
      "Radical": "止",
      "HSK": 3,
      "FrequencyRank": 349,
      "StrokeCount": 7
    }
  },
  "交朋友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo péngyou",
      "PrimaryDefinition": "to make friends, (dialect) to start an affair with sb"
    },
    "Keys": {
      "Word": 765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "意外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìwài",
      "PrimaryDefinition": "accident, mishap"
    },
    "Keys": {
      "Word": 2094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "着急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zháo jí",
      "PrimaryDefinition": "worry, feel anxious"
    },
    "Keys": {
      "Word": 3157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瓜子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāzǐ",
      "PrimaryDefinition": "melon seeds"
    },
    "Keys": {
      "Word": 6851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "魔术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móshù",
      "PrimaryDefinition": "magic"
    },
    "Keys": {
      "Word": 8324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下决心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià juéxīn",
      "PrimaryDefinition": "to determine, to resolve"
    },
    "Keys": {
      "Word": 9860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "insult, ridicule, disgrace"
    },
    "Keys": {
      "Hanzi": 1566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1566,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 2621,
      "StrokeCount": 9
    }
  },
  "交涉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoshè",
      "PrimaryDefinition": "to negotiate, relating to"
    },
    "Keys": {
      "Word": 7459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "红扑扑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngpūpū",
      "PrimaryDefinition": "red, rosy, flushed"
    },
    "Keys": {
      "Word": 7080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亲身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnshēn",
      "PrimaryDefinition": "in person, personally, first-hand"
    },
    "Keys": {
      "Word": 8724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "襚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "grave clothes"
    },
    "Keys": {
      "Hanzi": 7973
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7973,
      "Radical": "衣",
      "RadicalIndex": "145.13",
      "StrokeCount": 17
    }
  },
  "操场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāochǎng",
      "PrimaryDefinition": "schoolyard, school ground, school yard, drill ground, sportsground, playing field, playground, sports ground"
    },
    "Keys": {
      "Word": 2292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "难处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánchu",
      "PrimaryDefinition": "trouble, difficulty, problem"
    },
    "Keys": {
      "Word": 8373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "sand; pebbles, gravel; gritty"
    },
    "Keys": {
      "Hanzi": 1451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.4",
      "GeneralStandard": 1451,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 2713,
      "StrokeCount": 9
    }
  },
  "剕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6980,
      "Radical": "刀",
      "RadicalIndex": "18.8",
      "StrokeCount": 10
    }
  },
  "捧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pěng",
      "PrimaryDefinition": "hold up in two hands"
    },
    "Keys": {
      "Hanzi": 2160,
      "Word": 8496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2160,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2353,
      "StrokeCount": 11
    }
  },
  "便捷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànjié",
      "PrimaryDefinition": "convenient and fast"
    },
    "Keys": {
      "Word": 5614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "研发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánfā",
      "PrimaryDefinition": "research and develop, R&D, research and develop something, research and development"
    },
    "Keys": {
      "Word": 5212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "欠条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàntiáo",
      "PrimaryDefinition": "IOU, certificate of indebtedness"
    },
    "Keys": {
      "Word": 8684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "bits, scraps, crumbs, fragments"
    },
    "Keys": {
      "Hanzi": 2133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.7",
      "GeneralStandard": 2133,
      "Radical": "尸",
      "HSK": 9,
      "FrequencyRank": 2516,
      "StrokeCount": 10
    }
  },
  "终生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngshēng",
      "PrimaryDefinition": "all one's life"
    },
    "Keys": {
      "Word": 10821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "复合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùhé",
      "PrimaryDefinition": "complex, compound, composite, hybrid"
    },
    "Keys": {
      "Word": 6634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5988,
      "Radical": "羊",
      "FrequencyRank": 5632,
      "RadicalIndex": "123.9",
      "StrokeCount": 15
    }
  },
  "晨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "early morning, daybreak"
    },
    "Keys": {
      "Hanzi": 2260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.7",
      "GeneralStandard": 2260,
      "Radical": "日",
      "HSK": 2,
      "FrequencyRank": 1388,
      "StrokeCount": 11
    }
  },
  "人权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénquán",
      "PrimaryDefinition": "human rights"
    },
    "Keys": {
      "Word": 4970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "单元": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānyuán",
      "PrimaryDefinition": "module, unit, unit of teaching materials, residential unit"
    },
    "Keys": {
      "Word": 1392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "全场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánchǎng",
      "PrimaryDefinition": "everyone present, the whole audience, across-the-board, unanimously, whole duration (of a competition or match)"
    },
    "Keys": {
      "Word": 1829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "饲料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìliào",
      "PrimaryDefinition": "feed, fodder"
    },
    "Keys": {
      "Word": 9330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伤脑筋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng nǎojīn",
      "PrimaryDefinition": "to be a real headache, to find sth a real headache, to beat one's brains"
    },
    "Keys": {
      "Word": 8963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "hill; elder; empty; a name"
    },
    "Keys": {
      "Hanzi": 268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "GeneralStandard": 268,
      "Radical": "一",
      "HSK": 9,
      "FrequencyRank": 1929,
      "StrokeCount": 5
    }
  },
  "窗帘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuānglián",
      "PrimaryDefinition": "window curtains"
    },
    "Keys": {
      "Word": 3354
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "娵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "star"
    },
    "Keys": {
      "Hanzi": 7263
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7263,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "师范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīfàn",
      "PrimaryDefinition": "teacher-training, pedagogical"
    },
    "Keys": {
      "Word": 9092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "唤起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànqǐ",
      "PrimaryDefinition": "arouse, call, recall"
    },
    "Keys": {
      "Word": 7179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "科目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēmù",
      "PrimaryDefinition": "discipline, headings in account book, school subject, school course, school subject/course, subject, category of subjects, course"
    },
    "Keys": {
      "Word": 7788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笔记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐjì",
      "PrimaryDefinition": "short sketches, adversaria, notes, note"
    },
    "Keys": {
      "Word": 516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "漠然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòrán",
      "PrimaryDefinition": "indifferent, apathetic, cold"
    },
    "Keys": {
      "Word": 8332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxué",
      "PrimaryDefinition": "middle_school, grammar_school, secondary school, Ch. learning, middle school, high_school, grammar school"
    },
    "Keys": {
      "Word": 478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "没辙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méizhé",
      "PrimaryDefinition": "at one's wit's end, unable to find a way out"
    },
    "Keys": {
      "Word": 8219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shísù",
      "PrimaryDefinition": "speed per hour"
    },
    "Keys": {
      "Word": 9111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnqián",
      "PrimaryDefinition": "principal, capital"
    },
    "Keys": {
      "Word": 5565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "americium"
    },
    "Keys": {
      "Hanzi": 7687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7687,
      "Radical": "金",
      "FrequencyRank": 6016,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "埽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sào",
      "PrimaryDefinition": "broom; to sweep, clear away"
    },
    "Keys": {
      "Hanzi": 4742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4742,
      "Radical": "土",
      "FrequencyRank": 4582,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "徇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "comply with, follow; display"
    },
    "Keys": {
      "Hanzi": 4269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4269,
      "Radical": "彳",
      "FrequencyRank": 4085,
      "RadicalIndex": "60.6",
      "StrokeCount": 9
    }
  },
  "笙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "small gourd-shaped musical instrument"
    },
    "Keys": {
      "Hanzi": 2320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2320,
      "Radical": "竹",
      "FrequencyRank": 3891,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "传承": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánchéng",
      "PrimaryDefinition": "pass down through generations"
    },
    "Keys": {
      "Word": 6005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "优良": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuliáng",
      "PrimaryDefinition": "fine, good"
    },
    "Keys": {
      "Word": 3112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "僦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "to heir; to rent"
    },
    "Keys": {
      "Hanzi": 7694
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7694,
      "Radical": "人",
      "FrequencyRank": 5438,
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "汧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "name of a river in Shangdong; marsh; float"
    },
    "Keys": {
      "Hanzi": 6610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6610,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "涄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7058,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "埪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7116,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 11
    }
  },
  "腴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "fat; fertile, rich; plump, soft"
    },
    "Keys": {
      "Hanzi": 5231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5231,
      "Radical": "肉",
      "FrequencyRank": 4548,
      "RadicalIndex": "130.9",
      "StrokeCount": 12
    }
  },
  "监督": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāndū",
      "PrimaryDefinition": "supervise, superintend, control"
    },
    "Keys": {
      "Word": 4708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一概而论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīgàiérlùn",
      "PrimaryDefinition": "to lump different matters together (idiom)"
    },
    "Keys": {
      "Word": 10287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "比试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐshi",
      "PrimaryDefinition": "to have a competition, to measure with one's hand or arm, to make a gesture of measuring"
    },
    "Keys": {
      "Word": 5584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nī",
      "PrimaryDefinition": "maid, servant girl; cute girl"
    },
    "Keys": {
      "Hanzi": 1328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1328,
      "Radical": "女",
      "FrequencyRank": 2124,
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "篱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "bamboo or wooden fence; hedge"
    },
    "Keys": {
      "Hanzi": 3360
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3360,
      "Radical": "竹",
      "FrequencyRank": 3158,
      "RadicalIndex": "118.11",
      "StrokeCount": 16
    }
  },
  "丑陋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒulòu",
      "PrimaryDefinition": "ugly"
    },
    "Keys": {
      "Word": 5947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "假定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎdìng",
      "PrimaryDefinition": "to assume, to suppose, supposed, so-called, assumption, hypothesis"
    },
    "Keys": {
      "Word": 7361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嚼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "prattle, be glib"
    },
    "Keys": {
      "Hanzi": 3479,
      "Word": 7476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.18",
      "Grade": 7,
      "GeneralStandard": 3479,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2731,
      "StrokeCount": 20
    }
  },
  "醛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "aldehyde"
    },
    "Keys": {
      "Hanzi": 6068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6068,
      "Radical": "酉",
      "FrequencyRank": 4793,
      "RadicalIndex": "164.1",
      "StrokeCount": 16
    }
  },
  "切断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiē duàn",
      "PrimaryDefinition": "sever, shut_off, amputate, disconnect, cut_off, joint, cut off, mutilate, cutting_off, lop, hang_up, interrupt, hang up, guillotine"
    },
    "Keys": {
      "Word": 8707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "petal; segment; valves"
    },
    "Keys": {
      "Hanzi": 3468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "97.14",
      "GeneralStandard": 3468,
      "Radical": "瓜",
      "HSK": 8,
      "FrequencyRank": 2915,
      "StrokeCount": 19
    }
  },
  "外交官": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàijiāoguān",
      "PrimaryDefinition": "diplomat"
    },
    "Keys": {
      "Word": 2960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "呈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "submit, show; appear; petition"
    },
    "Keys": {
      "Hanzi": 716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 716,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1563,
      "StrokeCount": 7
    }
  },
  "莫名其妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòmíng-qímiào",
      "PrimaryDefinition": "unfathomable mystery (idiom), subtle and ineffable, unable to make head or tail of it, boring (e.g. movie)"
    },
    "Keys": {
      "Word": 8331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "潖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pá",
      "PrimaryDefinition": "name of a certain river"
    },
    "Keys": {
      "Hanzi": 7813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7813,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "改动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎidòng",
      "PrimaryDefinition": "to alter, to modify, to revise"
    },
    "Keys": {
      "Word": 6653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粗略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūlüè",
      "PrimaryDefinition": "rough (not precise or accurate), cursory"
    },
    "Keys": {
      "Word": 6072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "解雇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěgù",
      "PrimaryDefinition": "to fire, to sack, to dismiss, to terminate employment"
    },
    "Keys": {
      "Word": 7538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐzhǔn",
      "PrimaryDefinition": "level, plane, standard"
    },
    "Keys": {
      "Word": 9273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liào",
      "PrimaryDefinition": "consider, conjecture; materials, ingredients"
    },
    "Keys": {
      "Hanzi": 2054,
      "Word": 4833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "68.6",
      "Grade": 6,
      "GeneralStandard": 2054,
      "Radical": "斗",
      "HSK": 4,
      "FrequencyRank": 557,
      "StrokeCount": 10
    }
  },
  "泛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "to drift, float; careless, reckless"
    },
    "Keys": {
      "Hanzi": 865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 865,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1327,
      "StrokeCount": 7
    }
  },
  "对付": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìfu",
      "PrimaryDefinition": "counter, deal/cope with, face, confront, cope with, fix, make do, handle, deal, tackle, deal with"
    },
    "Keys": {
      "Word": 2434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "sustain, support; hold, grasp"
    },
    "Keys": {
      "Hanzi": 1367,
      "Word": 5904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1367,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 357,
      "StrokeCount": 9
    }
  },
  "窄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎi",
      "PrimaryDefinition": "narrow, tight; narrow-minded"
    },
    "Keys": {
      "Hanzi": 2104,
      "Word": 10631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.5",
      "Grade": 7,
      "GeneralStandard": 2104,
      "Radical": "穴",
      "HSK": 8,
      "FrequencyRank": 2244,
      "StrokeCount": 10
    }
  },
  "耀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "shine, sparkle, dazzle; glory"
    },
    "Keys": {
      "Hanzi": 3476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124.14",
      "GeneralStandard": 3476,
      "Radical": "羽",
      "HSK": 6,
      "FrequencyRank": 1804,
      "StrokeCount": 20
    }
  },
  "熥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tēng",
      "PrimaryDefinition": "heat up by steaming"
    },
    "Keys": {
      "Hanzi": 5799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5799,
      "Radical": "火",
      "RadicalIndex": "86.11",
      "StrokeCount": 14
    }
  },
  "急剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíjù",
      "PrimaryDefinition": "rapid, sudden"
    },
    "Keys": {
      "Word": 7301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "(same as 埠) a wharf, dock, jetty; a trading center, port; place name (e.g., 深水埗 in Hong Kong)"
    },
    "Keys": {
      "Hanzi": 6942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6942,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "申报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnbào",
      "PrimaryDefinition": "report to higher body, declare (dutiable goods)"
    },
    "Keys": {
      "Word": 9018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "afar, distant; old, advanced in"
    },
    "Keys": {
      "Hanzi": 5315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5315,
      "Radical": "辵",
      "FrequencyRank": 3852,
      "RadicalIndex": "162.9",
      "StrokeCount": 12
    }
  },
  "第一手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìyīshǒu",
      "PrimaryDefinition": "first-hand"
    },
    "Keys": {
      "Word": 6260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "同等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngděng",
      "PrimaryDefinition": "coequal, status, of same rank/status, on an equal footing, on an equal basis, of same class/rank/status, of same class, rank"
    },
    "Keys": {
      "Word": 9532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páng",
      "PrimaryDefinition": "bulky; thick and large; confused"
    },
    "Keys": {
      "Hanzi": 6808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6808,
      "Radical": "厂",
      "RadicalIndex": "27.7",
      "StrokeCount": 9
    }
  },
  "以免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐmiǎn",
      "PrimaryDefinition": "conj.: in order to avoid, so as not to"
    },
    "Keys": {
      "Word": 10315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "happy"
    },
    "Keys": {
      "Hanzi": 7206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7206,
      "Radical": "心",
      "RadicalIndex": "61.7",
      "StrokeCount": 11
    }
  },
  "杂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zá",
      "PrimaryDefinition": "mixed, blended; mix, mingle"
    },
    "Keys": {
      "Hanzi": 498,
      "Word": 5303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "Grade": 6,
      "GeneralStandard": 498,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 853,
      "StrokeCount": 6
    }
  },
  "哀求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āiqiú",
      "PrimaryDefinition": "to entreat, to implore, to plead"
    },
    "Keys": {
      "Word": 5424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "言辞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáncí",
      "PrimaryDefinition": "words, expression, what one says"
    },
    "Keys": {
      "Word": 10176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà",
      "PrimaryDefinition": "fortune telling; tell fortunes"
    },
    "Keys": {
      "Hanzi": 954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "25.6",
      "GeneralStandard": 954,
      "Radical": "卜",
      "HSK": 7,
      "FrequencyRank": 3119,
      "StrokeCount": 8
    }
  },
  "镞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zú",
      "PrimaryDefinition": "arrowhead, barb; swift, quick"
    },
    "Keys": {
      "Hanzi": 6118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6118,
      "Radical": "金",
      "FrequencyRank": 5762,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "机器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīqì",
      "PrimaryDefinition": "enginery, life, machinery, machine, plant, apparatus, engine, gizmo"
    },
    "Keys": {
      "Word": 1569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "弥补": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míbǔ",
      "PrimaryDefinition": "supply, atone, make_up, renew, make up, remedy, redeem, make good, compensate, recuperate, counterbalance"
    },
    "Keys": {
      "Word": 8257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竹竿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhúgān",
      "PrimaryDefinition": "bamboo"
    },
    "Keys": {
      "Word": 10850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "常识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángshí",
      "PrimaryDefinition": "nous, reason, mother_wit, general knowledge, common_sense, mother wit, horse sense, public knowledge, common sense, sense, good sense, gumption, commonsense, wisdom"
    },
    "Keys": {
      "Word": 2302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "拦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "obstruct, impede, bar, hinder"
    },
    "Keys": {
      "Hanzi": 982,
      "Word": 7924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 982,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1996,
      "StrokeCount": 8
    }
  },
  "功效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngxiào",
      "PrimaryDefinition": "efficacy, effect"
    },
    "Keys": {
      "Word": 6789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6762,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "课题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kètí",
      "PrimaryDefinition": "task, problem, question for study, subject, topic, question for discussion, discussion, topical, question for study/discussion"
    },
    "Keys": {
      "Word": 3663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "说道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōdao",
      "PrimaryDefinition": "talk over, say"
    },
    "Keys": {
      "Word": 9292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánjí",
      "PrimaryDefinition": "grade, year (in school, college etc), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "消沉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāochén",
      "PrimaryDefinition": "depressed, bad mood, low spirit"
    },
    "Keys": {
      "Word": 9956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "level ground; Japanese measure"
    },
    "Keys": {
      "Hanzi": 960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.5",
      "GeneralStandard": 960,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2716,
      "StrokeCount": 8
    }
  },
  "景象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngxiàng",
      "PrimaryDefinition": "prospects, spectacle, scene, picture, sight, show"
    },
    "Keys": {
      "Word": 3629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "屡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "frequently, often, again and again"
    },
    "Keys": {
      "Hanzi": 2801,
      "Word": 8144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.9",
      "Grade": 7,
      "GeneralStandard": 2801,
      "Radical": "尸",
      "HSK": 9,
      "FrequencyRank": 2450,
      "StrokeCount": 12
    }
  },
  "硗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": "barren land; sandy soil"
    },
    "Keys": {
      "Hanzi": 4793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4793,
      "Radical": "石",
      "FrequencyRank": 5539,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "降落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàngluò",
      "PrimaryDefinition": "descend, land, drop (in water level, etc.)"
    },
    "Keys": {
      "Word": 2617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "嗅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "smell, scent, sniff; olfactive"
    },
    "Keys": {
      "Hanzi": 2927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.1",
      "GeneralStandard": 2927,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2703,
      "StrokeCount": 13
    }
  },
  "温习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnxí",
      "PrimaryDefinition": "to review (a lesson etc)"
    },
    "Keys": {
      "Word": 9728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "觎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "desire strongly, covet, long for"
    },
    "Keys": {
      "Hanzi": 5511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5511,
      "Radical": "見",
      "FrequencyRank": 4520,
      "RadicalIndex": "147.9",
      "StrokeCount": 13
    }
  },
  "髃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "shoulder bone"
    },
    "Keys": {
      "Hanzi": 8002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8002,
      "Radical": "骨",
      "RadicalIndex": "188.9",
      "StrokeCount": 18
    }
  },
  "霞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "rosy clouds"
    },
    "Keys": {
      "Hanzi": 3400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "173.9",
      "GeneralStandard": 3400,
      "Radical": "雨",
      "HSK": 9,
      "FrequencyRank": 2021,
      "StrokeCount": 17
    }
  },
  "阡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "footpaths between fields; paths"
    },
    "Keys": {
      "Hanzi": 3559
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3559,
      "Radical": "阜",
      "FrequencyRank": 5147,
      "RadicalIndex": "170.3",
      "StrokeCount": 5
    }
  },
  "归还": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīhuán",
      "PrimaryDefinition": "to return sth, to revert"
    },
    "Keys": {
      "Word": 6909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "改良": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎiliáng",
      "PrimaryDefinition": "improve, ameliorate"
    },
    "Keys": {
      "Word": 6654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "审判": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnpàn",
      "PrimaryDefinition": "a trial, to try sb"
    },
    "Keys": {
      "Word": 9044
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "投诉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóusù",
      "PrimaryDefinition": "complaint, to complain, to register a complaint (esp. as a customer)"
    },
    "Keys": {
      "Word": 2947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "嚏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "sneeze"
    },
    "Keys": {
      "Hanzi": 6225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.14",
      "GeneralStandard": 6225,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 3897,
      "StrokeCount": 17
    }
  },
  "国徽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóhuī",
      "PrimaryDefinition": "national emblem, coat of arms"
    },
    "Keys": {
      "Word": 6932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "残酷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánkù",
      "PrimaryDefinition": "brutal, ruthless"
    },
    "Keys": {
      "Word": 4370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "轻蔑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngmiè",
      "PrimaryDefinition": "to contempt, to disdain, pejorative"
    },
    "Keys": {
      "Word": 8735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光缆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānglǎn",
      "PrimaryDefinition": "optical cable"
    },
    "Keys": {
      "Word": 6903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "há",
      "PrimaryDefinition": "clam"
    },
    "Keys": {
      "Hanzi": 2638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2638,
      "Radical": "虫",
      "FrequencyRank": 3732,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "殒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔn",
      "PrimaryDefinition": "die, perish; vanish; fall"
    },
    "Keys": {
      "Hanzi": 4802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4802,
      "Radical": "歹",
      "FrequencyRank": 4590,
      "RadicalIndex": "78.7",
      "StrokeCount": 11
    }
  },
  "距离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùlí",
      "PrimaryDefinition": "distance"
    },
    "Keys": {
      "Word": 2647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "游戏机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuxìjī",
      "PrimaryDefinition": "video game, game machine"
    },
    "Keys": {
      "Word": 5273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "堪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kān",
      "PrimaryDefinition": "adequately capable of, worthy of"
    },
    "Keys": {
      "Hanzi": 2509
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.9",
      "GeneralStandard": 2509,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 1811,
      "StrokeCount": 12
    }
  },
  "与时俱进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔshí-jùjìn",
      "PrimaryDefinition": "abreast of modern developments, to keep up with the times, progressive, timely"
    },
    "Keys": {
      "Word": 10507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "hut, cottage; name of a mountain"
    },
    "Keys": {
      "Hanzi": 839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 839,
      "Radical": "广",
      "FrequencyRank": 2927,
      "RadicalIndex": "53.4",
      "StrokeCount": 7
    }
  },
  "䲟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìn",
      "PrimaryDefinition": "(simplified form of 鮣) a kind of fish, live in the ocean, dark brown color with two white vertical marks; flat shaped head"
    },
    "Keys": {
      "Hanzi": 7560
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7560,
      "Radical": "",
      "FrequencyRank": 8750,
      "RadicalIndex": "",
      "StrokeCount": 16
    }
  },
  "髅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "skull; skeleton"
    },
    "Keys": {
      "Hanzi": 6342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6342,
      "Radical": "骨",
      "FrequencyRank": 3761,
      "RadicalIndex": "188.9",
      "StrokeCount": 18
    }
  },
  "跛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǒ",
      "PrimaryDefinition": "lame"
    },
    "Keys": {
      "Hanzi": 2632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2632,
      "Radical": "足",
      "FrequencyRank": 3877,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "酎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "double-fermented wine, vintage wine"
    },
    "Keys": {
      "Hanzi": 6965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6965,
      "Radical": "酉",
      "FrequencyRank": 7582,
      "RadicalIndex": "164.3",
      "StrokeCount": 10
    }
  },
  "摄影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèyǐng",
      "PrimaryDefinition": "photography"
    },
    "Keys": {
      "Word": 3882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "故乡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùxiāng",
      "PrimaryDefinition": "country, home, patria, place of birth, native place, hometown, homeland, motherland, birthplace"
    },
    "Keys": {
      "Word": 1515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "残忍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánrěn",
      "PrimaryDefinition": "cruel, mean, merciless, ruthless"
    },
    "Keys": {
      "Word": 5758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "species of bean; piper longtum"
    },
    "Keys": {
      "Hanzi": 4112
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4112,
      "Radical": "艸",
      "FrequencyRank": 6477,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "至今": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìjīn",
      "PrimaryDefinition": "thus_far, up_to_now, until_now, so far, to this day, up to now, to date, hitherto, erenow"
    },
    "Keys": {
      "Word": 2173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "麸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "bran"
    },
    "Keys": {
      "Hanzi": 4715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4715,
      "Radical": "麥",
      "FrequencyRank": 5774,
      "RadicalIndex": "199.4",
      "StrokeCount": 11
    }
  },
  "绿灯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜdēng",
      "PrimaryDefinition": "green light"
    },
    "Keys": {
      "Word": 8147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诉说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùshuō",
      "PrimaryDefinition": "tell, relate, recount"
    },
    "Keys": {
      "Word": 9347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脑海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎohǎi",
      "PrimaryDefinition": "brain, mind"
    },
    "Keys": {
      "Word": 8386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "澪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7904,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "指着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐzhe",
      "PrimaryDefinition": "Poke"
    },
    "Keys": {
      "Word": 5361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "震": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "shake, quake, tremor; excite"
    },
    "Keys": {
      "Hanzi": 3229,
      "Word": 10712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.7",
      "Grade": 7,
      "GeneralStandard": 3229,
      "Radical": "雨",
      "HSK": 5,
      "FrequencyRank": 1104,
      "StrokeCount": 15
    }
  },
  "昀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "sun light; used in personal names"
    },
    "Keys": {
      "Hanzi": 3904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3904,
      "Radical": "日",
      "FrequencyRank": 5412,
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "咪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1514,
      "Radical": "口",
      "FrequencyRank": 3313,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "剧场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùchǎng",
      "PrimaryDefinition": "theater"
    },
    "Keys": {
      "Word": 1654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "佣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "commission fee"
    },
    "Keys": {
      "Hanzi": 780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 780,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 2546,
      "StrokeCount": 7
    }
  },
  "簕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lè",
      "PrimaryDefinition": "(Cant.) thorn"
    },
    "Keys": {
      "Hanzi": 7951
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7951,
      "Radical": "竹",
      "FrequencyRank": 8783,
      "RadicalIndex": "118.1",
      "StrokeCount": 17
    }
  },
  "扇子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànzi",
      "PrimaryDefinition": "fan, CL:把[ba3]"
    },
    "Keys": {
      "Word": 3863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无所作为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúsuǒzuòwéi",
      "PrimaryDefinition": "attempting nothing and accomplishing nothing (idiom), without any initiative or drive, feckless"
    },
    "Keys": {
      "Word": 9784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěguān",
      "PrimaryDefinition": "goodly, substantial"
    },
    "Keys": {
      "Word": 7798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "齇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8103,
      "Radical": "鼻",
      "RadicalIndex": "209.11",
      "StrokeCount": 25
    }
  },
  "毯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": "rug, carpet, blanket"
    },
    "Keys": {
      "Hanzi": 2672
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "82.8",
      "GeneralStandard": 2672,
      "Radical": "毛",
      "HSK": 7,
      "FrequencyRank": 2711,
      "StrokeCount": 12
    }
  },
  "有所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒusuǒ",
      "PrimaryDefinition": "somewhat, to some extent"
    },
    "Keys": {
      "Word": 10479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "correct, rectify, straighten out"
    },
    "Keys": {
      "Hanzi": 2310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "111.6",
      "GeneralStandard": 2310,
      "Radical": "矢",
      "HSK": 8,
      "FrequencyRank": 2916,
      "StrokeCount": 11
    }
  },
  "熄火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī huǒ",
      "PrimaryDefinition": "(of fire, lamp etc) to go out, to put out (fire), (fig.) to die down, (of a vehicle) to stall"
    },
    "Keys": {
      "Word": 9825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nài",
      "PrimaryDefinition": "endure, bear; resist; patient"
    },
    "Keys": {
      "Hanzi": 1456,
      "Word": 8369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "126.3",
      "Grade": 7,
      "GeneralStandard": 1456,
      "Radical": "而",
      "HSK": 5,
      "FrequencyRank": 1409,
      "StrokeCount": 9
    }
  },
  "日益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìyì",
      "PrimaryDefinition": "increasingly"
    },
    "Keys": {
      "Word": 8876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㧑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "(a simplified form 撝) to wave, to assist"
    },
    "Keys": {
      "Hanzi": 6567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6567,
      "Radical": "手",
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "屉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "drawer; tray; pad; screen"
    },
    "Keys": {
      "Hanzi": 1307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.5",
      "GeneralStandard": 1307,
      "Radical": "尸",
      "HSK": 7,
      "FrequencyRank": 3127,
      "StrokeCount": 8
    }
  },
  "间隙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànxì",
      "PrimaryDefinition": "interval, gap, clearance"
    },
    "Keys": {
      "Word": 7427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尽量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnliàng",
      "PrimaryDefinition": "to the best of one's ability"
    },
    "Keys": {
      "Word": 1627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "运气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnqi",
      "PrimaryDefinition": "fortune, luck"
    },
    "Keys": {
      "Word": 3140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "cotton cloth, textiles, linen"
    },
    "Keys": {
      "Hanzi": 218,
      "Word": 1323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.2",
      "Grade": 3,
      "GeneralStandard": 218,
      "Radical": "巾",
      "HSK": 3,
      "FrequencyRank": 380,
      "StrokeCount": 5
    }
  },
  "动作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngzuò",
      "PrimaryDefinition": "demeanor, motion, behavior, performance, demeanour, play, turn, operation, operative, looseness, agency, gesticulation, movement, gesture, comportment, business, action, move"
    },
    "Keys": {
      "Word": 87
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "真空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnkōng",
      "PrimaryDefinition": "vacuum"
    },
    "Keys": {
      "Word": 10698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēi",
      "PrimaryDefinition": "be silent, be quiet"
    },
    "Keys": {
      "Hanzi": 3252,
      "Word": 7063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.12",
      "Grade": 7,
      "GeneralStandard": 3252,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2423,
      "StrokeCount": 15
    }
  },
  "鸷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "hawk, vulture"
    },
    "Keys": {
      "Hanzi": 4732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4732,
      "Radical": "鳥",
      "FrequencyRank": 5330,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "类似": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèisì",
      "PrimaryDefinition": "analogize, conform, analogic, be similar to, resemble, similar, analogous"
    },
    "Keys": {
      "Word": 1691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "郴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēn",
      "PrimaryDefinition": "county in Hunan province; surname"
    },
    "Keys": {
      "Hanzi": 4449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4449,
      "Radical": "邑",
      "FrequencyRank": 5383,
      "RadicalIndex": "163.8",
      "StrokeCount": 10
    }
  },
  "倾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "upset, pour out, overflow"
    },
    "Keys": {
      "Hanzi": 1959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1959,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1260,
      "StrokeCount": 10
    }
  },
  "膳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "meals, provisions, board"
    },
    "Keys": {
      "Hanzi": 6136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.12",
      "GeneralStandard": 6136,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 3300,
      "StrokeCount": 16
    }
  },
  "柒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "number seven"
    },
    "Keys": {
      "Hanzi": 1666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1666,
      "Radical": "木",
      "FrequencyRank": 6786,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "地面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìmiàn",
      "PrimaryDefinition": "(earth's) surface, ground, floor, region"
    },
    "Keys": {
      "Word": 2407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "俾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "so that, in order that; to cause; (Cant.) to give (synonymous with Mandarin 給)"
    },
    "Keys": {
      "Hanzi": 4572
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4572,
      "Radical": "人",
      "FrequencyRank": 3519,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "方向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngxiang",
      "PrimaryDefinition": "path, set, directional, aspect, tenor, course, lay, exposure, way, range, aim, trend, direction, bearing, orientation, hand, tack, way of life, quarter, channel, line, heading"
    },
    "Keys": {
      "Word": 656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "堋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "bury"
    },
    "Keys": {
      "Hanzi": 4729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4729,
      "Radical": "土",
      "FrequencyRank": 5290,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "section, department, science"
    },
    "Keys": {
      "Hanzi": 1551,
      "Word": 810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.4",
      "Grade": 2,
      "GeneralStandard": 1551,
      "Radical": "禾",
      "HSK": 2,
      "FrequencyRank": 277,
      "StrokeCount": 9
    }
  },
  "蚝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "hairy and poisonous caterpillars"
    },
    "Keys": {
      "Hanzi": 4512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4512,
      "Radical": "虫",
      "FrequencyRank": 4882,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "筹集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóují",
      "PrimaryDefinition": "to collect money, to raise funds"
    },
    "Keys": {
      "Word": 5945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "继父": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìfù",
      "PrimaryDefinition": "stepfather"
    },
    "Keys": {
      "Word": 7336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7552,
      "Radical": "谷",
      "FrequencyRank": 9878,
      "RadicalIndex": "150.6",
      "StrokeCount": 13
    }
  },
  "搁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "place, put, lay down; delay"
    },
    "Keys": {
      "Hanzi": 2533,
      "Word": 6721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2533,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2380,
      "StrokeCount": 12
    }
  },
  "鿍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàng",
      "PrimaryDefinition": "hill; long and narrow highland; used in place names"
    },
    "Keys": {
      "Hanzi": 6774
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6774,
      "Radical": "土",
      "RadicalIndex": "32.6"
    }
  },
  "茂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "thick, lush, dense; talented"
    },
    "Keys": {
      "Hanzi": 1003
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1003,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2309,
      "StrokeCount": 8
    }
  },
  "表情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎoqíng",
      "PrimaryDefinition": "brow, air, countenance, glow, look, cast, visage, expression, facial expression, gesture, business, dramatics, facial gesture"
    },
    "Keys": {
      "Word": 2270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "寻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "seek, search, look for; ancient"
    },
    "Keys": {
      "Hanzi": 563,
      "Word": 10147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "58.3",
      "Grade": 7,
      "GeneralStandard": 563,
      "Radical": "彐",
      "HSK": 4,
      "FrequencyRank": 962,
      "StrokeCount": 6
    }
  },
  "穸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "the gloom of the grave a tomb or grave; death"
    },
    "Keys": {
      "Hanzi": 6727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6727,
      "Radical": "穴",
      "FrequencyRank": 5943,
      "RadicalIndex": "116.3",
      "StrokeCount": 8
    }
  },
  "茗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "tea; tea plant"
    },
    "Keys": {
      "Hanzi": 4125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4125,
      "Radical": "艸",
      "FrequencyRank": 4030,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "声音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngyīn",
      "PrimaryDefinition": "voice, vocal, language, huskiness, vox, vocalization, audio, noise, sonic, tone, sound, phone, audio frequency"
    },
    "Keys": {
      "Word": 971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "甩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǎi",
      "PrimaryDefinition": "throw away, discard"
    },
    "Keys": {
      "Hanzi": 284,
      "Word": 9247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "101",
      "Grade": 7,
      "GeneralStandard": 284,
      "Radical": "用",
      "HSK": 7,
      "FrequencyRank": 2603,
      "StrokeCount": 5
    }
  },
  "枭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "owl thus, something evil"
    },
    "Keys": {
      "Hanzi": 3991
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3991,
      "Radical": "木",
      "FrequencyRank": 3885,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "总理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒnglǐ",
      "PrimaryDefinition": "premier, prime minister"
    },
    "Keys": {
      "Word": 3212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "藏品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cángpǐn",
      "PrimaryDefinition": "museum piece, collector's item, precious object"
    },
    "Keys": {
      "Word": 5767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zā",
      "PrimaryDefinition": "press, squeeze hard; force"
    },
    "Keys": {
      "Hanzi": 6782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6782,
      "Radical": "手",
      "FrequencyRank": 6608,
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "扫墓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎo mù",
      "PrimaryDefinition": "to sweep the tombs (of one's ancestors), the Qingming festival"
    },
    "Keys": {
      "Word": 8931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāo",
      "PrimaryDefinition": "harass, bother, annoy, disturb"
    },
    "Keys": {
      "Hanzi": 2823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.9",
      "GeneralStandard": 2823,
      "Radical": "馬",
      "HSK": 7,
      "FrequencyRank": 2237,
      "StrokeCount": 12
    }
  },
  "焙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "dry over slow fire; bake; roast"
    },
    "Keys": {
      "Hanzi": 5274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5274,
      "Radical": "火",
      "FrequencyRank": 3955,
      "RadicalIndex": "86.8",
      "StrokeCount": 12
    }
  },
  "栒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "cross bar"
    },
    "Keys": {
      "Hanzi": 6964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6964,
      "Radical": "木",
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "挖掘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wājué",
      "PrimaryDefinition": "excavate, unearth"
    },
    "Keys": {
      "Word": 9629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "确凿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèzáo",
      "PrimaryDefinition": "definite, conclusive, undeniable, authentic, also pr. [que4 zuo4]"
    },
    "Keys": {
      "Word": 8820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "看热闹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn rènao",
      "PrimaryDefinition": "to enjoy watching a bustling scene, to go where the crowds are"
    },
    "Keys": {
      "Word": 7772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnjì",
      "PrimaryDefinition": "acting, performing skills"
    },
    "Keys": {
      "Word": 10200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāng",
      "PrimaryDefinition": "a coir-palm"
    },
    "Keys": {
      "Hanzi": 6960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6960,
      "Radical": "木",
      "FrequencyRank": 6990,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎo",
      "PrimaryDefinition": "skillful, ingenious, clever"
    },
    "Keys": {
      "Hanzi": 197,
      "Word": 1815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "48.2",
      "Grade": 3,
      "GeneralStandard": 197,
      "Radical": "工",
      "HSK": 3,
      "FrequencyRank": 1219,
      "StrokeCount": 5
    }
  },
  "跳动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàodòng",
      "PrimaryDefinition": "to throb, to pulse, to bounce, to jiggle, to jump about"
    },
    "Keys": {
      "Word": 9507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěyǐ",
      "PrimaryDefinition": "can, may"
    },
    "Keys": {
      "Word": 817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "晏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "peaceful, quiet; clear; late in the day"
    },
    "Keys": {
      "Hanzi": 4502
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4502,
      "Radical": "日",
      "FrequencyRank": 3811,
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "忏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàn",
      "PrimaryDefinition": "regret, repent; confess sins"
    },
    "Keys": {
      "Hanzi": 3630
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3630,
      "Radical": "心",
      "FrequencyRank": 3177,
      "RadicalIndex": "61.3",
      "StrokeCount": 6
    }
  },
  "攻关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng guān",
      "PrimaryDefinition": "to storm a strategic pass, fig. to tackle a key problem"
    },
    "Keys": {
      "Word": 6792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè",
      "PrimaryDefinition": "a thorny tree; sugarcane"
    },
    "Keys": {
      "Hanzi": 4142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4142,
      "Radical": "木",
      "FrequencyRank": 5583,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "涉嫌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèxián",
      "PrimaryDefinition": "alleged, be a suspect, be suspected of being involved"
    },
    "Keys": {
      "Word": 9013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大大咧咧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàda-liēliē",
      "PrimaryDefinition": "carefree, offhand, casual"
    },
    "Keys": {
      "Word": 6114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大哥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàgē",
      "PrimaryDefinition": "eldest brother, elder brother, gang leader"
    },
    "Keys": {
      "Word": 2370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "耿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěng",
      "PrimaryDefinition": "bright, shining; have guts"
    },
    "Keys": {
      "Hanzi": 1817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.4",
      "GeneralStandard": 1817,
      "Radical": "耳",
      "HSK": 9,
      "FrequencyRank": 2687,
      "StrokeCount": 10
    }
  },
  "羧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": "carboxyl group"
    },
    "Keys": {
      "Hanzi": 5555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5555,
      "Radical": "羊",
      "FrequencyRank": 5676,
      "RadicalIndex": "123.7",
      "StrokeCount": 13
    }
  },
  "设备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèbèi",
      "PrimaryDefinition": "installation, set, device, appointment, plant, fixing, system, appliance, fixture, fitting, accommodation, fitment, finishing, unit, furnishings, materiel, contrivance, paraphernalia, facilities, rig, equipage, setout, facility, fixings, equipment"
    },
    "Keys": {
      "Word": 1875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "每当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měidāng",
      "PrimaryDefinition": "whenever, every time"
    },
    "Keys": {
      "Word": 8228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "从此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngcǐ",
      "PrimaryDefinition": "hereon, hence, henceforth, thereupon, therefrom, from now on, from then on"
    },
    "Keys": {
      "Word": 2353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "味道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèidao",
      "PrimaryDefinition": "gustatory perception, gust, tang, taste perception, feeling, savor, flavor, taste sensation, savour, streak, flavour, relish, sapidity, smatch, soupcon, tincture, tasting, smell, smack, gustatory sensation, suggestion, snack, taste"
    },
    "Keys": {
      "Word": 1062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "推开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīkāi",
      "PrimaryDefinition": "to push open (a gate etc), to push away, to reject, to decline"
    },
    "Keys": {
      "Word": 1991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "开幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi mù",
      "PrimaryDefinition": "open, inaugurate (meeting/etc.), raise curtain"
    },
    "Keys": {
      "Word": 3645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "借鉴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièjiàn",
      "PrimaryDefinition": "to use other people's experience, to borrow from a source, to use as reference"
    },
    "Keys": {
      "Word": 4740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "泥潭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nítán",
      "PrimaryDefinition": "quagmire"
    },
    "Keys": {
      "Word": 8402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūn",
      "PrimaryDefinition": "patient, earnest; earnestly"
    },
    "Keys": {
      "Hanzi": 2126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2126,
      "Radical": "言",
      "FrequencyRank": 4027,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "接着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēzhe",
      "PrimaryDefinition": "next, immediately after"
    },
    "Keys": {
      "Word": 779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "瓖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "inlay"
    },
    "Keys": {
      "Hanzi": 8076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8076,
      "Radical": "玉",
      "RadicalIndex": "96.17",
      "StrokeCount": 21
    }
  },
  "辿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chān",
      "PrimaryDefinition": "follow, pursue"
    },
    "Keys": {
      "Hanzi": 6535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6535,
      "Radical": "辵",
      "RadicalIndex": "162.3",
      "StrokeCount": 6
    }
  },
  "漼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuǐ",
      "PrimaryDefinition": "having the appearance of depth"
    },
    "Keys": {
      "Hanzi": 7727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7727,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùn",
      "PrimaryDefinition": "debate; discuss; discourse"
    },
    "Keys": {
      "Hanzi": 556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 556,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 205,
      "StrokeCount": 6
    }
  },
  "鹞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "sparrow hawk; kite shaped like"
    },
    "Keys": {
      "Hanzi": 5965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5965,
      "Radical": "鳥",
      "FrequencyRank": 4147,
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "围": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "surround, encircle, corral"
    },
    "Keys": {
      "Hanzi": 728,
      "Word": 2011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.4",
      "Grade": 3,
      "GeneralStandard": 728,
      "Radical": "囗",
      "HSK": 3,
      "FrequencyRank": 576,
      "StrokeCount": 7
    }
  },
  "朳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 6527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6527,
      "Radical": "木",
      "RadicalIndex": "75.2",
      "StrokeCount": 6
    }
  },
  "靿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "the leg of a boot"
    },
    "Keys": {
      "Hanzi": 5648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5648,
      "Radical": "革",
      "FrequencyRank": 7419,
      "RadicalIndex": "177.5",
      "StrokeCount": 14
    }
  },
  "波浪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōlàng",
      "PrimaryDefinition": "wave"
    },
    "Keys": {
      "Word": 4341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "空荡荡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngdàngdàng",
      "PrimaryDefinition": "absolutely empty (space), complete vacuum"
    },
    "Keys": {
      "Word": 7821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒudòng",
      "PrimaryDefinition": "manual, manually operated, manual gear-change"
    },
    "Keys": {
      "Word": 9174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "船长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánzhǎng",
      "PrimaryDefinition": "captain (of a boat), skipper"
    },
    "Keys": {
      "Word": 4446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "顿时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùnshí",
      "PrimaryDefinition": "suddenly, at once, immediately"
    },
    "Keys": {
      "Word": 6390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大伙儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàhuǒr5",
      "PrimaryDefinition": "everybody, everyone, we all, erhua variant of 大伙[da4 huo3]"
    },
    "Keys": {
      "Word": 3381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "寓言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùyán",
      "PrimaryDefinition": "fable, CL:則|则[ze2]"
    },
    "Keys": {
      "Word": 10526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "加以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāyǐ",
      "PrimaryDefinition": "handle; treat (used before polysyllabic verbs or verbal nouns)"
    },
    "Keys": {
      "Word": 3588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "农历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nónglì",
      "PrimaryDefinition": "farmer's_calendar, lunar calendar, lunar_calendar"
    },
    "Keys": {
      "Word": 8435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "囷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūn",
      "PrimaryDefinition": "round-shaped storage bin for grain"
    },
    "Keys": {
      "Hanzi": 6687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6687,
      "Radical": "囗",
      "RadicalIndex": "31.5",
      "StrokeCount": 8
    }
  },
  "娘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niáng",
      "PrimaryDefinition": "mother; young girl; woman; wife"
    },
    "Keys": {
      "Hanzi": 2144,
      "Word": 8420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.7",
      "Grade": 7,
      "GeneralStandard": 2144,
      "Radical": "女",
      "HSK": 3,
      "FrequencyRank": 881,
      "StrokeCount": 10
    }
  },
  "围墙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéiqiáng",
      "PrimaryDefinition": "enclosing wall"
    },
    "Keys": {
      "Word": 9699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7100
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7100,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "撞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàng",
      "PrimaryDefinition": "knock against, bump into, collide"
    },
    "Keys": {
      "Hanzi": 3202,
      "Word": 4268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 5,
      "GeneralStandard": 3202,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1540,
      "StrokeCount": 15
    }
  },
  "骧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "gallop about with head uplifted"
    },
    "Keys": {
      "Hanzi": 6447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6447,
      "Radical": "馬",
      "FrequencyRank": 4475,
      "RadicalIndex": "187.17",
      "StrokeCount": 20
    }
  },
  "难以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nányǐ",
      "PrimaryDefinition": "be difficult to"
    },
    "Keys": {
      "Word": 3742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "网点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngdiǎn",
      "PrimaryDefinition": "node in a network, branch, website"
    },
    "Keys": {
      "Word": 9669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "be lucky; by chance, by luck"
    },
    "Keys": {
      "Hanzi": 1142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1142,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 3359,
      "StrokeCount": 8
    }
  },
  "振兴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènxīng",
      "PrimaryDefinition": "Zhengxing district of Dandong city 丹東市|丹东市[Dan1 dong1 shi4], Liaoning, to revive, to revitalize, to invigorate, to re-energize"
    },
    "Keys": {
      "Word": 10708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": "female mandarin duck (Aix galericulata)"
    },
    "Keys": {
      "Hanzi": 1910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1910,
      "Radical": "鳥",
      "FrequencyRank": 3975,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "双向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngxiàng",
      "PrimaryDefinition": "bipartite, double-edged, two-sided, bidirectional, double-faced"
    },
    "Keys": {
      "Word": 9253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuān",
      "PrimaryDefinition": "to jump"
    },
    "Keys": {
      "Hanzi": 6482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6482,
      "Radical": "足",
      "FrequencyRank": 6930,
      "RadicalIndex": "157.16",
      "StrokeCount": 23
    }
  },
  "响应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngyìng",
      "PrimaryDefinition": "to respond to, answer, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 9944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "找": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎo",
      "PrimaryDefinition": "search, seek, look for; find"
    },
    "Keys": {
      "Hanzi": 621,
      "Word": 459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 1,
      "GeneralStandard": 621,
      "Radical": "手",
      "HSK": 1,
      "FrequencyRank": 466,
      "StrokeCount": 7
    }
  },
  "玖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔ",
      "PrimaryDefinition": "black-colored jade"
    },
    "Keys": {
      "Hanzi": 602
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 602,
      "Radical": "玉",
      "FrequencyRank": 5072,
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "飓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "cyclone, typhoon, gale"
    },
    "Keys": {
      "Hanzi": 5242
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5242,
      "Radical": "風",
      "FrequencyRank": 4112,
      "RadicalIndex": "182.8",
      "StrokeCount": 12
    }
  },
  "长期以来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángqīyǐlái",
      "PrimaryDefinition": "ever since a long time ago"
    },
    "Keys": {
      "Word": 5810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "thin, emaciated, lean; meager"
    },
    "Keys": {
      "Hanzi": 3147,
      "Word": 3919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.1",
      "Grade": 5,
      "GeneralStandard": 3147,
      "Radical": "疒",
      "HSK": 5,
      "FrequencyRank": 1841,
      "StrokeCount": 14
    }
  },
  "馘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guó",
      "PrimaryDefinition": "cut off left ear; tally dead enemy"
    },
    "Keys": {
      "Hanzi": 7971
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7971,
      "Radical": "首",
      "FrequencyRank": 6759,
      "RadicalIndex": "185.8",
      "StrokeCount": 17
    }
  },
  "蹈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo",
      "PrimaryDefinition": "stamp feet; dance"
    },
    "Keys": {
      "Hanzi": 3409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.1",
      "GeneralStandard": 3409,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 2659,
      "StrokeCount": 17
    }
  },
  "内": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèi",
      "PrimaryDefinition": "inside"
    },
    "Keys": {
      "Hanzi": 116,
      "Word": 1762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "11.2",
      "Grade": 3,
      "GeneralStandard": 116,
      "Radical": "入",
      "HSK": 3,
      "FrequencyRank": 175,
      "StrokeCount": 4
    }
  },
  "教授": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoshòu",
      "PrimaryDefinition": "professor"
    },
    "Keys": {
      "Word": 2620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "呔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāi",
      "PrimaryDefinition": "(Cant.) a necktie, a tire"
    },
    "Keys": {
      "Hanzi": 3701
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3701,
      "Radical": "口",
      "FrequencyRank": 5137,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "弱势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruòshì",
      "PrimaryDefinition": "the weak"
    },
    "Keys": {
      "Word": 8911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "港口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎngkǒu",
      "PrimaryDefinition": "harbour, port, seaport, haven, harbor"
    },
    "Keys": {
      "Word": 4590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "予以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔyǐ",
      "PrimaryDefinition": "give"
    },
    "Keys": {
      "Word": 10509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "mackerel"
    },
    "Keys": {
      "Hanzi": 7885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7885,
      "Radical": "魚",
      "FrequencyRank": 6310,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "帡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "shelter, screen, awning"
    },
    "Keys": {
      "Hanzi": 6842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6842,
      "Radical": "巾",
      "RadicalIndex": "50.6",
      "StrokeCount": 9
    }
  },
  "语法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔfǎ",
      "PrimaryDefinition": "phraseology, parlance, language, syntax, locution, sentence structure, grammar, expression, wording, phrasing, phrase structure, diction"
    },
    "Keys": {
      "Word": 3122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "死亡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǐwáng",
      "PrimaryDefinition": "be dead/doomed"
    },
    "Keys": {
      "Word": 5050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "汇聚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìjù",
      "PrimaryDefinition": "convergence, to come together"
    },
    "Keys": {
      "Word": 7220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "oil, fat, grease, lard; paints"
    },
    "Keys": {
      "Hanzi": 1248,
      "Word": 1169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 2,
      "GeneralStandard": 1248,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 948,
      "StrokeCount": 8
    }
  },
  "万": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàn",
      "PrimaryDefinition": "ten thousand; innumerable"
    },
    "Keys": {
      "Hanzi": 35,
      "Word": 1055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.2",
      "Grade": 2,
      "GeneralStandard": 35,
      "Radical": "一",
      "HSK": 2,
      "FrequencyRank": 322,
      "StrokeCount": 3
    }
  },
  "轮换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnhuàn",
      "PrimaryDefinition": "duty roster"
    },
    "Keys": {
      "Word": 8157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "超": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo",
      "PrimaryDefinition": "jump over, leap over; surpass"
    },
    "Keys": {
      "Hanzi": 2517,
      "Word": 4401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.5",
      "Grade": 6,
      "GeneralStandard": 2517,
      "Radical": "走",
      "HSK": 2,
      "FrequencyRank": 754,
      "StrokeCount": 12
    }
  },
  "缺席": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quē xí",
      "PrimaryDefinition": "be absent (from meeting/etc.)"
    },
    "Keys": {
      "Word": 8818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "reed, Miscanthus saccariflorus"
    },
    "Keys": {
      "Hanzi": 4440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4440,
      "Radical": "艸",
      "FrequencyRank": 4555,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "踹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuài",
      "PrimaryDefinition": "trample, tread on, kick; to crush"
    },
    "Keys": {
      "Hanzi": 6092,
      "Word": 5999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.9",
      "Grade": 7,
      "GeneralStandard": 6092,
      "Radical": "足",
      "HSK": 8,
      "FrequencyRank": 4485,
      "StrokeCount": 16
    }
  },
  "越过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè guò",
      "PrimaryDefinition": "to cross over, to transcend, to cover distance, to overcome, to rise above"
    },
    "Keys": {
      "Word": 10569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无家可归": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wújiā-kěguī",
      "PrimaryDefinition": "homeless"
    },
    "Keys": {
      "Word": 9769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùjiàn",
      "PrimaryDefinition": "envision, predict, foresee, previse, foreknow, prefigure, envisage"
    },
    "Keys": {
      "Word": 10515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "擂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "rub, grind; grind with a mortar and pestle; triturate"
    },
    "Keys": {
      "Hanzi": 3316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3316,
      "Radical": "手",
      "FrequencyRank": 3685,
      "RadicalIndex": "64.13",
      "StrokeCount": 16
    }
  },
  "勉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "endeavor, make effort; urge"
    },
    "Keys": {
      "Hanzi": 1599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.7",
      "GeneralStandard": 1599,
      "Radical": "力",
      "HSK": 7,
      "FrequencyRank": 2078,
      "StrokeCount": 9
    }
  },
  "奴隶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "núlì",
      "PrimaryDefinition": "slave"
    },
    "Keys": {
      "Word": 8443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "a kind of jade"
    },
    "Keys": {
      "Hanzi": 6934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6934,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "阳台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yángtái",
      "PrimaryDefinition": "veranda, pergola, patio, terrace, mirador, trysting place, balcony, gallery, piazza, gazebo, deck, verandah"
    },
    "Keys": {
      "Word": 3074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "插嘴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā zuǐ",
      "PrimaryDefinition": "to interrupt (sb talking), to butt in, to cut into a conversation"
    },
    "Keys": {
      "Word": 5786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "to level off; to trim; to pare down"
    },
    "Keys": {
      "Hanzi": 6559
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6559,
      "Radical": "刀",
      "RadicalIndex": "18.5",
      "StrokeCount": 7
    }
  },
  "壁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "partition wall; walls of a house"
    },
    "Keys": {
      "Hanzi": 3384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.13",
      "GeneralStandard": 3384,
      "Radical": "土",
      "HSK": 5,
      "FrequencyRank": 1380,
      "StrokeCount": 16
    }
  },
  "喜庆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐqìng",
      "PrimaryDefinition": "jubilation, festive"
    },
    "Keys": {
      "Word": 9841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "官司": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānsi",
      "PrimaryDefinition": "lawsuit, CL:場|场[chang2]"
    },
    "Keys": {
      "Word": 4630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "自私自利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìsī-zìlì",
      "PrimaryDefinition": "everything for self and selfish profit (idiom), with no regard for others, selfish, mercenary"
    },
    "Keys": {
      "Word": 10966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "deceive, lie; eyes half-closed"
    },
    "Keys": {
      "Hanzi": 3232,
      "Word": 8196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.1",
      "Grade": 7,
      "GeneralStandard": 3232,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 2405,
      "StrokeCount": 15
    }
  },
  "当下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngxià",
      "PrimaryDefinition": "immediately, at once, at that moment, at the moment"
    },
    "Keys": {
      "Word": 6178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5255,
      "Radical": "疒",
      "FrequencyRank": 7308,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "创意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngyì",
      "PrimaryDefinition": "create new meanings, get-up-and-go, unique idea, new idea"
    },
    "Keys": {
      "Word": 4450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唾液": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuòyè",
      "PrimaryDefinition": "saliva"
    },
    "Keys": {
      "Word": 9627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "襟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "lapel, collar"
    },
    "Keys": {
      "Hanzi": 3449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3449,
      "Radical": "衣",
      "FrequencyRank": 2843,
      "RadicalIndex": "145.13",
      "StrokeCount": 18
    }
  },
  "执行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíxíng",
      "PrimaryDefinition": "carry out, execute"
    },
    "Keys": {
      "Word": 4219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "壮胆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàng dǎn",
      "PrimaryDefinition": "Vigorous"
    },
    "Keys": {
      "Word": 10909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "儆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "warn; warning"
    },
    "Keys": {
      "Hanzi": 5756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5756,
      "Radical": "人",
      "FrequencyRank": 4519,
      "RadicalIndex": "9.13",
      "StrokeCount": 14
    }
  },
  "鹨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liù",
      "PrimaryDefinition": "Anthus species (various)"
    },
    "Keys": {
      "Hanzi": 7914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7914,
      "Radical": "鳥",
      "FrequencyRank": 5484,
      "RadicalIndex": "196.11",
      "StrokeCount": 16
    }
  },
  "唿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "sad; (Cant.) a bit, part"
    },
    "Keys": {
      "Hanzi": 4844
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4844,
      "Radical": "口",
      "FrequencyRank": 5771,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "发财": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fācái",
      "PrimaryDefinition": "to get rich"
    },
    "Keys": {
      "Word": 6431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "raised path between fields"
    },
    "Keys": {
      "Hanzi": 7494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7494,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "牢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "prison; stable, pen; secure"
    },
    "Keys": {
      "Hanzi": 880,
      "Word": 4812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "93.3",
      "Grade": 6,
      "GeneralStandard": 880,
      "Radical": "牛",
      "HSK": 6,
      "FrequencyRank": 1696,
      "StrokeCount": 7
    }
  },
  "疫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "epidemic, plague, pestilence"
    },
    "Keys": {
      "Hanzi": 1627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.4",
      "GeneralStandard": 1627,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 1791,
      "StrokeCount": 9
    }
  },
  "毌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "old form of 貫; component in 贯,貫,實, etc.; not to be confused with 毋"
    },
    "Keys": {
      "Hanzi": 6507
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6507,
      "Radical": "毋",
      "RadicalIndex": "80",
      "StrokeCount": 4
    }
  },
  "内幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèimù",
      "PrimaryDefinition": "inside story, non-public information, behind the scenes, internal"
    },
    "Keys": {
      "Word": 8394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6523,
      "Radical": "土",
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "探求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tànqiú",
      "PrimaryDefinition": "to seek, to pursue, to investigate"
    },
    "Keys": {
      "Word": 9419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5070,
      "Radical": "老",
      "FrequencyRank": 6231,
      "RadicalIndex": "125.6",
      "StrokeCount": 12
    }
  },
  "兵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīng",
      "PrimaryDefinition": "soldier, troops"
    },
    "Keys": {
      "Hanzi": 768,
      "Word": 2276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.5",
      "Grade": 4,
      "GeneralStandard": 768,
      "Radical": "八",
      "HSK": 4,
      "FrequencyRank": 398,
      "StrokeCount": 7
    }
  },
  "苄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "benzyl"
    },
    "Keys": {
      "Hanzi": 3675
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3675,
      "Radical": "艸",
      "FrequencyRank": 5318,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "系": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "system; line, link, connection"
    },
    "Keys": {
      "Hanzi": 825,
      "Word": 2588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.1",
      "Grade": 4,
      "GeneralStandard": 825,
      "Radical": "糸",
      "HSK": 1,
      "FrequencyRank": 216,
      "StrokeCount": 7
    }
  },
  "抗生素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàngshēngsù",
      "PrimaryDefinition": "antibiotic"
    },
    "Keys": {
      "Word": 7782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偶像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒuxiàng",
      "PrimaryDefinition": "mammet, god, Baal, hoodoo, icon, idol, image, effigy, fetich, fetish, model, graven image, matinee idol, swami, iconology, voodoo, joss, obeah, pagod, juju"
    },
    "Keys": {
      "Word": 3755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "各奔前程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèbèn-qiánchéng",
      "PrimaryDefinition": "each goes his own way (idiom), each person has his own life to lead"
    },
    "Keys": {
      "Word": 6738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "立体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìtǐ",
      "PrimaryDefinition": "three-dimensional, stereoscopic"
    },
    "Keys": {
      "Word": 8005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "the snarling of dogs"
    },
    "Keys": {
      "Hanzi": 4605
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4605,
      "Radical": "犬",
      "FrequencyRank": 6169,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "梳子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūzi",
      "PrimaryDefinition": "comb, CL:把[ba3]"
    },
    "Keys": {
      "Word": 9214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": "swaddling cloth; infancy"
    },
    "Keys": {
      "Hanzi": 5821
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5821,
      "Radical": "衣",
      "FrequencyRank": 4966,
      "RadicalIndex": "145.9",
      "StrokeCount": 14
    }
  },
  "支出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīchū",
      "PrimaryDefinition": "defrayal, payout, outgo, outgoing, expense, disbursal, expenditure, outlay, disbursement"
    },
    "Keys": {
      "Word": 4220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "良": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "good, virtuous, respectable"
    },
    "Keys": {
      "Hanzi": 884,
      "Word": 8030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "138.1",
      "Grade": 7,
      "GeneralStandard": 884,
      "Radical": "艮",
      "HSK": 4,
      "FrequencyRank": 835,
      "StrokeCount": 7
    }
  },
  "巉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "steep, rugged, jagged, precipitous"
    },
    "Keys": {
      "Hanzi": 6431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6431,
      "Radical": "山",
      "RadicalIndex": "46.17",
      "StrokeCount": 20
    }
  },
  "游戏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuxì",
      "PrimaryDefinition": "game, CL:場|场[chang3], to play"
    },
    "Keys": {
      "Word": 2112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "前边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánbian",
      "PrimaryDefinition": "front, the front side, in front of"
    },
    "Keys": {
      "Word": 289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "知识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīshi",
      "PrimaryDefinition": "lear, know-how, understanding, ken, instruction, science, sophistication, consciousness, light, awareness, cognizance, knowledge, lore, mastery, know, cognition, acquaintanceship, mind, intellect, knowingness, equipment"
    },
    "Keys": {
      "Word": 472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "铭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "inscribe, engrave"
    },
    "Keys": {
      "Hanzi": 2307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.6",
      "GeneralStandard": 2307,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 2061,
      "StrokeCount": 11
    }
  },
  "耿直": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěngzhí",
      "PrimaryDefinition": "honest, frank, candid"
    },
    "Keys": {
      "Word": 6750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "bud"
    },
    "Keys": {
      "Hanzi": 5376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5376,
      "Radical": "艸",
      "FrequencyRank": 3922,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "侵略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnlüè",
      "PrimaryDefinition": "invade"
    },
    "Keys": {
      "Word": 8715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "民俗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínsú",
      "PrimaryDefinition": "folklore, folk, folk custom, folkways"
    },
    "Keys": {
      "Word": 8298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "brown bear, ursus arctos"
    },
    "Keys": {
      "Hanzi": 5723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5723,
      "Radical": "网",
      "FrequencyRank": 5639,
      "RadicalIndex": "122.9",
      "StrokeCount": 14
    }
  },
  "撼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "move, shake; (Cant.) to fight"
    },
    "Keys": {
      "Hanzi": 3315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.13",
      "GeneralStandard": 3315,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2987,
      "StrokeCount": 16
    }
  },
  "提速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí sù",
      "PrimaryDefinition": "to increase the specified cruising speed, to pick up speed, to speed up"
    },
    "Keys": {
      "Word": 9459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "法语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Fǎyǔ",
      "PrimaryDefinition": "French (language)"
    },
    "Keys": {
      "Word": 4547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "到期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào qī",
      "PrimaryDefinition": "to fall due (loan etc), to expire (visa etc), to mature (investment bond etc)"
    },
    "Keys": {
      "Word": 4492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "寥寥无几": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáoliáo-wújǐ",
      "PrimaryDefinition": "just a very few (idiom), tiny number, not many at all, You count them on your fingers."
    },
    "Keys": {
      "Word": 8044
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "默默": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòmò",
      "PrimaryDefinition": "quietly, silently"
    },
    "Keys": {
      "Word": 2752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "棪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 7331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7331,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "太阳能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàiyángnéng",
      "PrimaryDefinition": "solar energy"
    },
    "Keys": {
      "Word": 5067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "样本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàngběn",
      "PrimaryDefinition": "sample, specimen"
    },
    "Keys": {
      "Word": 10228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "night, dark; in night; by night"
    },
    "Keys": {
      "Hanzi": 1209,
      "Word": 1132
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "36.5",
      "Grade": 2,
      "GeneralStandard": 1209,
      "Radical": "夕",
      "HSK": 2,
      "FrequencyRank": 665,
      "StrokeCount": 8
    }
  },
  "茴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí",
      "PrimaryDefinition": "fennel, aniseed"
    },
    "Keys": {
      "Hanzi": 4115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4115,
      "Radical": "艸",
      "FrequencyRank": 5388,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "来源于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láiyuányú",
      "PrimaryDefinition": "to originate in"
    },
    "Keys": {
      "Word": 7923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánjiā",
      "PrimaryDefinition": "whole family"
    },
    "Keys": {
      "Word": 946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "械": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "weapons; implements, instruments"
    },
    "Keys": {
      "Hanzi": 2219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "GeneralStandard": 2219,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1657,
      "StrokeCount": 11
    }
  },
  "食用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíyòng",
      "PrimaryDefinition": "food product, to use as food, edible"
    },
    "Keys": {
      "Word": 9122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "argonium"
    },
    "Keys": {
      "Hanzi": 4548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4548,
      "Radical": "气",
      "FrequencyRank": 4914,
      "RadicalIndex": "84.6",
      "StrokeCount": 10
    }
  },
  "勤奋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínfèn",
      "PrimaryDefinition": "hardworking, diligent"
    },
    "Keys": {
      "Word": 3815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鹇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "silver pheasant, Lophura nycthemera; Lophura species (various)"
    },
    "Keys": {
      "Hanzi": 5265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5265,
      "Radical": "鳥",
      "FrequencyRank": 6307,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "塍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "a raised path between fields, a dike"
    },
    "Keys": {
      "Hanzi": 5522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5522,
      "Radical": "土",
      "FrequencyRank": 6584,
      "RadicalIndex": "32.1",
      "StrokeCount": 13
    }
  },
  "萩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "scandent hop; tree"
    },
    "Keys": {
      "Hanzi": 7312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7312,
      "Radical": "艸",
      "FrequencyRank": 7028,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "馒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "steamed bread; steamed dumplings"
    },
    "Keys": {
      "Hanzi": 3138
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.11",
      "GeneralStandard": 3138,
      "Radical": "食",
      "HSK": 6,
      "FrequencyRank": 3628,
      "StrokeCount": 14
    }
  },
  "榖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "paper mulberry"
    },
    "Keys": {
      "Hanzi": 5641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5641,
      "Radical": "木",
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "郾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "county in Henan province"
    },
    "Keys": {
      "Hanzi": 4781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4781,
      "Radical": "邑",
      "FrequencyRank": 5770,
      "RadicalIndex": "163.9",
      "StrokeCount": 11
    }
  },
  "坎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎn",
      "PrimaryDefinition": "pit, hole; snare, trap; crisis"
    },
    "Keys": {
      "Hanzi": 639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 639,
      "Radical": "土",
      "FrequencyRank": 2446,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "扶持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúchí",
      "PrimaryDefinition": "to help, to assist"
    },
    "Keys": {
      "Word": 6598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笨蛋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèndàn",
      "PrimaryDefinition": "fool, idiot"
    },
    "Keys": {
      "Word": 5570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "heron, egret; Ardea species (various)"
    },
    "Keys": {
      "Hanzi": 6334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6334,
      "Radical": "鳥",
      "FrequencyRank": 4451,
      "RadicalIndex": "196.13",
      "StrokeCount": 18
    }
  },
  "埭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "a dam, a jock; inclined plane on a canal, where boats can be hauled up or down"
    },
    "Keys": {
      "Hanzi": 4741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4741,
      "Radical": "土",
      "FrequencyRank": 6970,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "反驳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnbó",
      "PrimaryDefinition": "refute, retort, negate"
    },
    "Keys": {
      "Word": 6466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "window; lead enlightenment"
    },
    "Keys": {
      "Hanzi": 5960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5960,
      "Radical": "片",
      "FrequencyRank": 5368,
      "RadicalIndex": "91.11",
      "StrokeCount": 15
    }
  },
  "壶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "jar, pot, jug, vase; surname"
    },
    "Keys": {
      "Hanzi": 1812,
      "Word": 4677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "33.7",
      "Grade": 6,
      "GeneralStandard": 1812,
      "Radical": "士",
      "HSK": 6,
      "FrequencyRank": 2518,
      "StrokeCount": 10
    }
  },
  "纷纷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnfēn",
      "PrimaryDefinition": "in droves, one_after_another, numerous and in great confusion"
    },
    "Keys": {
      "Word": 2471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "呼吸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūxī",
      "PrimaryDefinition": "take a breather, respiratory, take a breath, respire, catch one's breath, inspire, breathe, rest, suspire, respiration"
    },
    "Keys": {
      "Word": 2550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "茂盛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màoshèng",
      "PrimaryDefinition": "lush"
    },
    "Keys": {
      "Word": 8212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pā",
      "PrimaryDefinition": "flowers"
    },
    "Keys": {
      "Hanzi": 5088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5088,
      "Radical": "艸",
      "FrequencyRank": 5158,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "突如其来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūrúqílái",
      "PrimaryDefinition": "to arise abruptly, to arrive suddenly, happening suddenly"
    },
    "Keys": {
      "Word": 9579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "售价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòujià",
      "PrimaryDefinition": "asking price, selling price, selling_price, sell price, price"
    },
    "Keys": {
      "Word": 9203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "失效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī xiào",
      "PrimaryDefinition": "to fail, to lose effectiveness"
    },
    "Keys": {
      "Word": 9089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "eel"
    },
    "Keys": {
      "Hanzi": 6406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6406,
      "Radical": "魚",
      "FrequencyRank": 4152,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "黎明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "límíng",
      "PrimaryDefinition": "dawn, daybreak"
    },
    "Keys": {
      "Word": 7977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "消费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāofèi",
      "PrimaryDefinition": "expenditure"
    },
    "Keys": {
      "Word": 2046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "接济": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiējì",
      "PrimaryDefinition": "to give material assistance to"
    },
    "Keys": {
      "Word": 7505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赞助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànzhù",
      "PrimaryDefinition": "support, assist"
    },
    "Keys": {
      "Word": 3147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "晊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6984,
      "Radical": "日",
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "承载": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngzài",
      "PrimaryDefinition": "to bear the weight, to sustain"
    },
    "Keys": {
      "Word": 5881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一路顺风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlùshùnfēng",
      "PrimaryDefinition": "to have a pleasant journey (idiom)"
    },
    "Keys": {
      "Word": 1140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "省略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnglüè",
      "PrimaryDefinition": "to leave out, an omission"
    },
    "Keys": {
      "Word": 9070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忘不了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngbùliǎo",
      "PrimaryDefinition": "cannot forget"
    },
    "Keys": {
      "Word": 9678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yū",
      "PrimaryDefinition": "mud, sediment; clog up, silt up"
    },
    "Keys": {
      "Hanzi": 2429
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2429,
      "Radical": "水",
      "FrequencyRank": 3576,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "失败": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shībài",
      "PrimaryDefinition": "be defeated, fail, lose"
    },
    "Keys": {
      "Word": 2854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "漉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "filter, strain; wet, dripping"
    },
    "Keys": {
      "Hanzi": 5808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5808,
      "Radical": "水",
      "FrequencyRank": 3658,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "轳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "pulley, windlass, capstan"
    },
    "Keys": {
      "Hanzi": 4180
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4180,
      "Radical": "車",
      "FrequencyRank": 5519,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "鹂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "Chinese oriole; Oriolus oriolus"
    },
    "Keys": {
      "Hanzi": 5111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5111,
      "Radical": "鳥",
      "FrequencyRank": 5427,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "袤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "longitude, lengthwise; length"
    },
    "Keys": {
      "Hanzi": 4944
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4944,
      "Radical": "衣",
      "FrequencyRank": 4129,
      "RadicalIndex": "145.5",
      "StrokeCount": 11
    }
  },
  "交谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāotán",
      "PrimaryDefinition": "converse, chat"
    },
    "Keys": {
      "Word": 7460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翠绿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuìlǜ",
      "PrimaryDefinition": "greenish-blue, emerald green"
    },
    "Keys": {
      "Word": 6082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "提升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíshēng",
      "PrimaryDefinition": "promote, hoist, elevate"
    },
    "Keys": {
      "Word": 5079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "再三": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàisān",
      "PrimaryDefinition": "repeatedly, again and again, over and over again, thrice, time and again"
    },
    "Keys": {
      "Word": 3143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "下来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià lai",
      "PrimaryDefinition": "come down from a higher place"
    },
    "Keys": {
      "Word": 2024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "热爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèài",
      "PrimaryDefinition": "love ardently"
    },
    "Keys": {
      "Word": 1842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "水灵灵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐlínglíng",
      "PrimaryDefinition": "vivid, full of life, fresh"
    },
    "Keys": {
      "Word": 9264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顺心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùn xīn",
      "PrimaryDefinition": "happy, satisfactory"
    },
    "Keys": {
      "Word": 9286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "field; farm"
    },
    "Keys": {
      "Hanzi": 4210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4210,
      "Radical": "田",
      "FrequencyRank": 5483,
      "RadicalIndex": "102.4",
      "StrokeCount": 9
    }
  },
  "淘汰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táotài",
      "PrimaryDefinition": "weed out, competition, eliminate through selection, supersede, superannuate, weed, eliminate through selection or competition, screen_out, eliminate through competition, eliminate through selection/competition, fall into disuse, die out, eliminate, comb out, weed_out, selective"
    },
    "Keys": {
      "Word": 9440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "piece of jade with hole in it"
    },
    "Keys": {
      "Hanzi": 3450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3450,
      "Radical": "玉",
      "FrequencyRank": 3589,
      "RadicalIndex": "96.13",
      "StrokeCount": 18
    }
  },
  "辛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "bitter"
    },
    "Keys": {
      "Radical": 160,
      "Hanzi": 841
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "160",
      "GeneralStandard": 841,
      "Radical": "辛",
      "HSK": 5,
      "FrequencyRank": 1463,
      "StrokeCount": 7
    }
  },
  "佟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "a name"
    },
    "Keys": {
      "Hanzi": 3733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3733,
      "Radical": "人",
      "FrequencyRank": 3180,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "细腻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnì",
      "PrimaryDefinition": "fine and smooth, exquisite, minute"
    },
    "Keys": {
      "Word": 9848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "seek; demand, request; beseech"
    },
    "Keys": {
      "Hanzi": 690,
      "Word": 938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.2",
      "Grade": 2,
      "GeneralStandard": 690,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 312,
      "StrokeCount": 7
    }
  },
  "磴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèng",
      "PrimaryDefinition": "steps on ledge, cliff, or hill"
    },
    "Keys": {
      "Hanzi": 6218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6218,
      "Radical": "石",
      "FrequencyRank": 6014,
      "RadicalIndex": "112.12",
      "StrokeCount": 17
    }
  },
  "美滋滋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měizīzī",
      "PrimaryDefinition": "very happy, elated"
    },
    "Keys": {
      "Word": 8240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "商讨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngtǎo",
      "PrimaryDefinition": "to discuss, to deliberate"
    },
    "Keys": {
      "Word": 8967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6514,
      "Radical": "冫",
      "RadicalIndex": "15.3",
      "StrokeCount": 5
    }
  },
  "须": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "must, have to, necessary"
    },
    "Keys": {
      "Hanzi": 1585,
      "Word": 10088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "59.6",
      "Grade": 7,
      "GeneralStandard": 1585,
      "Radical": "彡",
      "HSK": 2,
      "FrequencyRank": 444,
      "StrokeCount": 9
    }
  },
  "工艺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyì",
      "PrimaryDefinition": "technology, craft"
    },
    "Keys": {
      "Word": 3497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "动不动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngbudòng",
      "PrimaryDefinition": "apt to happen (usually of sth undesirable), frequently, happening easily (e.g. accident or illness)"
    },
    "Keys": {
      "Word": 6330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūshì",
      "PrimaryDefinition": "to show, to take out and show to others, to display"
    },
    "Keys": {
      "Word": 5967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒng",
      "PrimaryDefinition": "pail, bucket, tub; cask, keg"
    },
    "Keys": {
      "Hanzi": 2230,
      "Word": 9547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "Grade": 7,
      "GeneralStandard": 2230,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2411,
      "StrokeCount": 11
    }
  },
  "山寨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānzhài",
      "PrimaryDefinition": "fortified hill village, mountain stronghold (esp. of bandits), (fig.) knockoff (goods), counterfeit, imitation"
    },
    "Keys": {
      "Word": 8949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "事宜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyí",
      "PrimaryDefinition": "arrangements, matters concerned, affairs, arrangement, personal business"
    },
    "Keys": {
      "Word": 9145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可怕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěpà",
      "PrimaryDefinition": "fearful, terrible"
    },
    "Keys": {
      "Word": 813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "报考": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàokǎo",
      "PrimaryDefinition": "enter oneself for an examination"
    },
    "Keys": {
      "Word": 4306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "培养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péiyǎng",
      "PrimaryDefinition": "foster, train, develop, culture"
    },
    "Keys": {
      "Word": 2776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "陆续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùxù",
      "PrimaryDefinition": "continuously, one after another, in succession"
    },
    "Keys": {
      "Word": 2714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "排列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páiliè",
      "PrimaryDefinition": "arrange, put in order"
    },
    "Keys": {
      "Word": 2769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "婞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "hate"
    },
    "Keys": {
      "Hanzi": 7262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7262,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "气势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìshì",
      "PrimaryDefinition": "air, powder, imposing manner, aura, manner, pith, vigor, atmosphere, vigour, verve, vehemence, fervor, momentum"
    },
    "Keys": {
      "Word": 8625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发源地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāyuándì",
      "PrimaryDefinition": "place of origin, birthplace, source"
    },
    "Keys": {
      "Word": 6448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "细": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "fine, tiny; slender, thin"
    },
    "Keys": {
      "Hanzi": 1340,
      "Word": 3002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 4,
      "GeneralStandard": 1340,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 597,
      "StrokeCount": 8
    }
  },
  "址": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "site, location, land for house"
    },
    "Keys": {
      "Hanzi": 623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "GeneralStandard": 623,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 1848,
      "StrokeCount": 7
    }
  },
  "喆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "a sage; wise; sagacious"
    },
    "Keys": {
      "Hanzi": 7294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7294,
      "Radical": "口",
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "指导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐdǎo",
      "PrimaryDefinition": "guide, direct"
    },
    "Keys": {
      "Word": 2170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "成语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngyǔ",
      "PrimaryDefinition": "Chinese set expression, typically of 4 characters, often alluding to a story or historical quotation, idiom, proverb, saying, adage, CL:條|条[tiao2],本[ben3],句[ju4]"
    },
    "Keys": {
      "Word": 3325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "镎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ná",
      "PrimaryDefinition": "neptunium (Np)"
    },
    "Keys": {
      "Hanzi": 7788
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7788,
      "Radical": "金",
      "FrequencyRank": 6449,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "落差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luòchā",
      "PrimaryDefinition": "drop in elevation, (fig.) gap (in wages, expectations etc), disparity"
    },
    "Keys": {
      "Word": 8169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "处境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔjìng",
      "PrimaryDefinition": "situation, site, plight, situation (usu. unfavorable), predicament, circumstances"
    },
    "Keys": {
      "Word": 5987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "horse with a bluish white color"
    },
    "Keys": {
      "Hanzi": 5846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5846,
      "Radical": "馬",
      "FrequencyRank": 6675,
      "RadicalIndex": "187.11",
      "StrokeCount": 14
    }
  },
  "联想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánxiǎng",
      "PrimaryDefinition": "abbr. for 聯想集團|联想集团[Lian2 xiang3 Ji2 tuan2], to associate (cognitively), to make an associative connection, mental association, word prediction and auto-complete functions of input method editing programs"
    },
    "Keys": {
      "Word": 3690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "渴望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěwàng",
      "PrimaryDefinition": "to thirst for, to long for"
    },
    "Keys": {
      "Word": 3659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "嗉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5435,
      "Radical": "口",
      "FrequencyRank": 5351,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "嘟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dū",
      "PrimaryDefinition": "sound of horn tooting"
    },
    "Keys": {
      "Hanzi": 5440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5440,
      "Radical": "口",
      "FrequencyRank": 2838,
      "RadicalIndex": "30.12",
      "StrokeCount": 13
    }
  },
  "辔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "bridle of horse, reins"
    },
    "Keys": {
      "Hanzi": 5613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5613,
      "Radical": "口",
      "FrequencyRank": 4619,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "稍候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāohòu",
      "PrimaryDefinition": "Wait a moment"
    },
    "Keys": {
      "Word": 8998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诉苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù kǔ",
      "PrimaryDefinition": "to grumble, to complain, grievance"
    },
    "Keys": {
      "Word": 9346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "定期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngqī",
      "PrimaryDefinition": "fixed (of time), periodically, regularly"
    },
    "Keys": {
      "Word": 1413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "expensive, costly, valuable"
    },
    "Keys": {
      "Hanzi": 1495,
      "Word": 127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "Grade": 1,
      "GeneralStandard": 1495,
      "Radical": "貝",
      "HSK": 1,
      "FrequencyRank": 873,
      "StrokeCount": 9
    }
  },
  "去除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùchú",
      "PrimaryDefinition": "to remove, to dislodge"
    },
    "Keys": {
      "Word": 8792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "墕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7629,
      "Radical": "土",
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "乡下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngxia",
      "PrimaryDefinition": "country, countryside, rural, village, province, country(side), mofussil, back_country"
    },
    "Keys": {
      "Word": 9915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "bow"
    },
    "Keys": {
      "Hanzi": 63,
      "Radical": 57,
      "Word": 6764
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57",
      "Grade": 7,
      "GeneralStandard": 63,
      "Radical": "弓",
      "HSK": 8,
      "FrequencyRank": 2229,
      "StrokeCount": 3
    }
  },
  "迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "traces, impressions, footprints"
    },
    "Keys": {
      "Hanzi": 1623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "GeneralStandard": 1623,
      "Radical": "辵",
      "HSK": 7,
      "FrequencyRank": 1098,
      "StrokeCount": 9
    }
  },
  "听说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīngshuō",
      "PrimaryDefinition": "hear, understand, learn, rumor, hear that..., hear/understand that..., be told, understand that..., hear of"
    },
    "Keys": {
      "Word": 1026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "支付": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīfù",
      "PrimaryDefinition": "pay (money), defray"
    },
    "Keys": {
      "Word": 2156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "无非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúfēi",
      "PrimaryDefinition": "nothing but, than, simply, only"
    },
    "Keys": {
      "Word": 9764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "名额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngé",
      "PrimaryDefinition": "number of people allowed, quota of people"
    },
    "Keys": {
      "Word": 4869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不景气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù jǐngqì",
      "PrimaryDefinition": "depressive, in depression, in depressing state, recession"
    },
    "Keys": {
      "Word": 5699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "看见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn jian",
      "PrimaryDefinition": "seeing, behold, see, descry, show, perceive, lay eyes on, lamp, spy, show up, sight, catch sight of, catch sight of"
    },
    "Keys": {
      "Word": 193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "㮾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7736,
      "Radical": "木",
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "圳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "furrow in field, small drainage ditch"
    },
    "Keys": {
      "Hanzi": 3573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3573,
      "Radical": "土",
      "FrequencyRank": 2137,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "以至于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐzhìyú",
      "PrimaryDefinition": "conj.: down/up to, to such an extent as to..., so...that..."
    },
    "Keys": {
      "Word": 10316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "废话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèihuà",
      "PrimaryDefinition": "nonsense, rubbish, superfluous words, You don't say!, No kidding! (gently sarcastic)"
    },
    "Keys": {
      "Word": 6524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "四处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìchù",
      "PrimaryDefinition": "about, all around, everywhere, everyplace, all over, throughout, around"
    },
    "Keys": {
      "Word": 5051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "救援": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùyuán",
      "PrimaryDefinition": "to save, to support, to help, to assist"
    },
    "Keys": {
      "Word": 4759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鳚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "general term of a kind of fish, a snake-like fish"
    },
    "Keys": {
      "Hanzi": 8044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8044,
      "Radical": "魚",
      "FrequencyRank": 5789,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "改装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎizhuāng",
      "PrimaryDefinition": "to change one's costume, to repackage, to remodel, to refit, to modify, to convert"
    },
    "Keys": {
      "Word": 4579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "充分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngfèn",
      "PrimaryDefinition": "full, ample, abundant"
    },
    "Keys": {
      "Word": 2329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "亲属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnshǔ",
      "PrimaryDefinition": "kin, kindred, relatives"
    },
    "Keys": {
      "Word": 4941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嫂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.1",
      "GeneralStandard": 2810,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 2142,
      "StrokeCount": 12
    }
  },
  "锷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "high, lofty; edge of knife"
    },
    "Keys": {
      "Hanzi": 5736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5736,
      "Radical": "金",
      "FrequencyRank": 4710,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "制约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìyuē",
      "PrimaryDefinition": "to restrict, condition"
    },
    "Keys": {
      "Word": 4236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "梦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèng",
      "PrimaryDefinition": "dream; visionary; wishful"
    },
    "Keys": {
      "Hanzi": 2221,
      "Word": 2734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "Grade": 4,
      "GeneralStandard": 2221,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 865,
      "StrokeCount": 11
    }
  },
  "崴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎi",
      "PrimaryDefinition": "high, lofty; precipitous"
    },
    "Keys": {
      "Hanzi": 5165
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5165,
      "Radical": "山",
      "FrequencyRank": 4687,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "贺卡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèkǎ",
      "PrimaryDefinition": "greeting card, congratulation card"
    },
    "Keys": {
      "Word": 3540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "䂮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüè",
      "PrimaryDefinition": "stone, to sharpen a knife, sharp-pointed; sharp, vigorous; energetic; keen"
    },
    "Keys": {
      "Hanzi": 7098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7098,
      "Radical": "石",
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "伪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "false, counterfeit, bogus"
    },
    "Keys": {
      "Hanzi": 476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 476,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 1556,
      "StrokeCount": 6
    }
  },
  "饭馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fànguǎn",
      "PrimaryDefinition": "restaurant, CL:家[jia1]"
    },
    "Keys": {
      "Word": 650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "俣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "big"
    },
    "Keys": {
      "Hanzi": 4263
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4263,
      "Radical": "人",
      "FrequencyRank": 4919,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "练习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liànxí",
      "PrimaryDefinition": "practice, exercise"
    },
    "Keys": {
      "Word": 845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "菜市场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càishìchǎng",
      "PrimaryDefinition": "food market"
    },
    "Keys": {
      "Word": 5748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "fat; seal (moon radical DKW: 14342 is different)"
    },
    "Keys": {
      "Hanzi": 6709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6709,
      "Radical": "肉",
      "FrequencyRank": 5867,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "征求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngqiú",
      "PrimaryDefinition": "ask_for, seek, solicit"
    },
    "Keys": {
      "Word": 3166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "烦闷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánmèn",
      "PrimaryDefinition": "moody, gloomy"
    },
    "Keys": {
      "Word": 6459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打交道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ jiāodao",
      "PrimaryDefinition": "to come into contact with, to have dealings"
    },
    "Keys": {
      "Word": 6101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "精妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngmiào",
      "PrimaryDefinition": "exquisite, fine and delicate (usu. of works of art)"
    },
    "Keys": {
      "Word": 7615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàduì",
      "PrimaryDefinition": "a military group, production brigade, a large number (of)"
    },
    "Keys": {
      "Word": 6116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuàn",
      "PrimaryDefinition": "usurp"
    },
    "Keys": {
      "Hanzi": 3358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3358,
      "Radical": "竹",
      "FrequencyRank": 3406,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "共有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngyǒu",
      "PrimaryDefinition": "to have altogether, in all"
    },
    "Keys": {
      "Word": 1512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "公园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyuán",
      "PrimaryDefinition": "common, green, garden, commons, park"
    },
    "Keys": {
      "Word": 688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "酪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lào",
      "PrimaryDefinition": "cream, cheese; koumiss"
    },
    "Keys": {
      "Hanzi": 2873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2873,
      "Radical": "酉",
      "FrequencyRank": 3667,
      "RadicalIndex": "164.6",
      "StrokeCount": 13
    }
  },
  "边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "edge, margin, side, border"
    },
    "Keys": {
      "Hanzi": 337,
      "Word": 520
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.2",
      "Grade": 2,
      "GeneralStandard": 337,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 316,
      "StrokeCount": 5
    }
  },
  "差不多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàbuduō",
      "PrimaryDefinition": "good enough"
    },
    "Keys": {
      "Word": 549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "牯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "cow; bull; ox"
    },
    "Keys": {
      "Hanzi": 4249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4249,
      "Radical": "牛",
      "FrequencyRank": 5439,
      "RadicalIndex": "93.5",
      "StrokeCount": 9
    }
  },
  "卧铺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wòpù",
      "PrimaryDefinition": "a bed (on a train), a couchette"
    },
    "Keys": {
      "Word": 5143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "杻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒu",
      "PrimaryDefinition": "ligustrum sinenese, tree"
    },
    "Keys": {
      "Hanzi": 3883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3883,
      "Radical": "木",
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "似是而非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìshìérfēi",
      "PrimaryDefinition": "apparently right but actually wrong, specious (idiom)"
    },
    "Keys": {
      "Word": 9328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "征集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngjí",
      "PrimaryDefinition": "to collect, to recruit"
    },
    "Keys": {
      "Word": 10724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寿司": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòusī",
      "PrimaryDefinition": "sushi"
    },
    "Keys": {
      "Word": 3915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "㛚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7088,
      "Radical": "女",
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "按时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànshí",
      "PrimaryDefinition": "on time, before deadline, on schedule"
    },
    "Keys": {
      "Word": 2233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "筹码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóumǎ",
      "PrimaryDefinition": "counter, jetton, dib, poker chip, chip, bargaining chip, medium_of_exchange"
    },
    "Keys": {
      "Word": 5943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "符": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "i.d. tag, tally, symbol, charm"
    },
    "Keys": {
      "Hanzi": 2321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.5",
      "GeneralStandard": 2321,
      "Radical": "竹",
      "HSK": 4,
      "FrequencyRank": 1205,
      "StrokeCount": 11
    }
  },
  "急性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíxìng",
      "PrimaryDefinition": "acute (of diseases)"
    },
    "Keys": {
      "Word": 7303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盒子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hézi",
      "PrimaryDefinition": "box, case, casket"
    },
    "Keys": {
      "Word": 3542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "菟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "dodder; creeper"
    },
    "Keys": {
      "Hanzi": 4759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4759,
      "Radical": "艸",
      "FrequencyRank": 5189,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "逆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "disobey, rebel; rebel, traitor"
    },
    "Keys": {
      "Hanzi": 1652,
      "Word": 8408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 7,
      "GeneralStandard": 1652,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 1975,
      "StrokeCount": 9
    }
  },
  "兼容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānróng",
      "PrimaryDefinition": "compatible"
    },
    "Keys": {
      "Word": 7398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毕业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì yè",
      "PrimaryDefinition": "graduate, finish school"
    },
    "Keys": {
      "Word": 2261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "上当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng dàng",
      "PrimaryDefinition": "be duped, be taken in, get_stuck, be fooled, be tricked"
    },
    "Keys": {
      "Word": 4996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "私事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīshì",
      "PrimaryDefinition": "personal matters"
    },
    "Keys": {
      "Word": 9312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "善于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànyú",
      "PrimaryDefinition": "excel, surpass, stand out, be adept in, be good at"
    },
    "Keys": {
      "Word": 2838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǜ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6973
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6973,
      "Radical": "心",
      "FrequencyRank": 6784,
      "RadicalIndex": "61.6",
      "StrokeCount": 10
    }
  },
  "末": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "final, last, end; insignificant"
    },
    "Keys": {
      "Hanzi": 193,
      "Word": 2751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "Grade": 4,
      "GeneralStandard": 193,
      "Radical": "木",
      "HSK": 2,
      "FrequencyRank": 1164,
      "StrokeCount": 5
    }
  },
  "齐全": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíquán",
      "PrimaryDefinition": "all in readiness, assort, complete"
    },
    "Keys": {
      "Word": 3789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "聃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "ears without rims; a personal name"
    },
    "Keys": {
      "Hanzi": 4745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4745,
      "Radical": "耳",
      "FrequencyRank": 5598,
      "RadicalIndex": "128.5",
      "StrokeCount": 11
    }
  },
  "沺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "turbulent"
    },
    "Keys": {
      "Hanzi": 6720
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6720,
      "Radical": "水",
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "湃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pài",
      "PrimaryDefinition": "sound of waves; turbulent"
    },
    "Keys": {
      "Hanzi": 2762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2762,
      "Radical": "水",
      "FrequencyRank": 3818,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "一句话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījùhuà",
      "PrimaryDefinition": "in a word, in short"
    },
    "Keys": {
      "Word": 4107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "狗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒu",
      "PrimaryDefinition": "dog, canis familiaris"
    },
    "Keys": {
      "Hanzi": 1198,
      "Word": 691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.5",
      "Grade": 2,
      "GeneralStandard": 1198,
      "Radical": "犬",
      "HSK": 2,
      "FrequencyRank": 1281,
      "StrokeCount": 8
    }
  },
  "棵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "numerary adjunct for trees"
    },
    "Keys": {
      "Hanzi": 2571,
      "Word": 2664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "Grade": 4,
      "GeneralStandard": 2571,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 2108,
      "StrokeCount": 12
    }
  },
  "星座": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngzuò",
      "PrimaryDefinition": "constellation, astrological sign, CL:張|张[zhang1]"
    },
    "Keys": {
      "Word": 10043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "录": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "copy, write down, record"
    },
    "Keys": {
      "Hanzi": 1304,
      "Word": 1719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "58.5",
      "Grade": 3,
      "GeneralStandard": 1304,
      "Radical": "彐",
      "HSK": 3,
      "FrequencyRank": 919,
      "StrokeCount": 8
    }
  },
  "或者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòzhě",
      "PrimaryDefinition": "conj.: or, either...or..."
    },
    "Keys": {
      "Word": 742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "隐性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnxìng",
      "PrimaryDefinition": "hidden, crypto-, recessive (gene)"
    },
    "Keys": {
      "Word": 10403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǔ",
      "PrimaryDefinition": "arrow-tip"
    },
    "Keys": {
      "Hanzi": 7087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7087,
      "Radical": "石",
      "FrequencyRank": 8223,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "默读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòdú",
      "PrimaryDefinition": "to read in silence"
    },
    "Keys": {
      "Word": 8334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "the cuticle of the bamboo a broad-leaved bamboo"
    },
    "Keys": {
      "Hanzi": 5747
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5747,
      "Radical": "竹",
      "FrequencyRank": 6700,
      "RadicalIndex": "118.9",
      "StrokeCount": 14
    }
  },
  "哂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěn",
      "PrimaryDefinition": "smile, laugh at, sneer at; (Cant.) a final particle indicating doing something to the full extent possible"
    },
    "Keys": {
      "Hanzi": 4196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4196,
      "Radical": "口",
      "FrequencyRank": 4716,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "捃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4425
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4425,
      "Radical": "手",
      "FrequencyRank": 7040,
      "RadicalIndex": "64.7",
      "StrokeCount": 10
    }
  },
  "终止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngzhǐ",
      "PrimaryDefinition": "stop, end"
    },
    "Keys": {
      "Word": 4244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "虷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "worm"
    },
    "Keys": {
      "Hanzi": 6833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6833,
      "Radical": "虫",
      "RadicalIndex": "142.3",
      "StrokeCount": 9
    }
  },
  "摴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū",
      "PrimaryDefinition": "to stretch out, to unroll; comfortable, easy (used for 舒); 摴蒲 is the name of a traditional play"
    },
    "Keys": {
      "Hanzi": 7633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7633,
      "Radical": "手",
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "崂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "Laoshan, mountain in Shandong"
    },
    "Keys": {
      "Hanzi": 4520
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4520,
      "Radical": "山",
      "FrequencyRank": 4592,
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "结合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéhé",
      "PrimaryDefinition": "combine, unite, integrate, link, be joined in wedlock"
    },
    "Keys": {
      "Word": 1618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "奄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "ere long; remain, tarry; feeble"
    },
    "Keys": {
      "Hanzi": 3889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3889,
      "Radical": "大",
      "FrequencyRank": 3462,
      "RadicalIndex": "37.5",
      "StrokeCount": 8
    }
  },
  "租金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūjīn",
      "PrimaryDefinition": "rent"
    },
    "Keys": {
      "Word": 5406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "声望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngwàng",
      "PrimaryDefinition": "mana, celebrity, character, prestige, odour, repute, fame, name, prestigiousness, caste, renown, reputation, popularity"
    },
    "Keys": {
      "Word": 9067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngqī",
      "PrimaryDefinition": "middle (of a period of time), medium-term (plan, forecast etc)"
    },
    "Keys": {
      "Word": 5368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "泐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lè",
      "PrimaryDefinition": "to write; to indite; veins in minerals"
    },
    "Keys": {
      "Hanzi": 3782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3782,
      "Radical": "水",
      "FrequencyRank": 6610,
      "RadicalIndex": "85.5",
      "StrokeCount": 7
    }
  },
  "归来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīlái",
      "PrimaryDefinition": "come back, be back, return, go back to"
    },
    "Keys": {
      "Word": 6911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "提名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí míng",
      "PrimaryDefinition": "nominate"
    },
    "Keys": {
      "Word": 9457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "需要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūyào",
      "PrimaryDefinition": "need, want, require, demand"
    },
    "Keys": {
      "Word": 2069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "催眠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuīmián",
      "PrimaryDefinition": "lull, hypnotize, mesmerize, hypnotic, lull (to sleep)"
    },
    "Keys": {
      "Word": 6079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "rocky, hilly, uneven"
    },
    "Keys": {
      "Hanzi": 6969
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6969,
      "Radical": "石",
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "性价比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngjiàbǐ",
      "PrimaryDefinition": "quality-price ratio"
    },
    "Keys": {
      "Word": 10060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "佴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èr",
      "PrimaryDefinition": "a second, an assistant"
    },
    "Keys": {
      "Hanzi": 6693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6693,
      "Radical": "人",
      "FrequencyRank": 6265,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "网络": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngluò",
      "PrimaryDefinition": "Internet, network (computing, telecommunications, transport etc)"
    },
    "Keys": {
      "Word": 2966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "浩劫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàojié",
      "PrimaryDefinition": "calamity, catastrophe, apocalypse"
    },
    "Keys": {
      "Word": 7021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中央": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngyāng",
      "PrimaryDefinition": "central authorities (of state/party/etc.)"
    },
    "Keys": {
      "Word": 4240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "涌现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngxiàn",
      "PrimaryDefinition": "to emerge in large numbers, to spring up, to emerge prominently"
    },
    "Keys": {
      "Word": 10440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "日复一日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìfùyīrì",
      "PrimaryDefinition": "day after day"
    },
    "Keys": {
      "Word": 8871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "not progressing; to be checked"
    },
    "Keys": {
      "Hanzi": 5352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5352,
      "Radical": "走",
      "FrequencyRank": 4969,
      "RadicalIndex": "156.6",
      "StrokeCount": 13
    }
  },
  "玚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6558,
      "Radical": "玉",
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "昫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "warm"
    },
    "Keys": {
      "Hanzi": 6829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6829,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "烠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7049
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7049,
      "Radical": "火",
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "在乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàihu",
      "PrimaryDefinition": "care about, take ... to heart, lie in, rest with, worry, care, mind"
    },
    "Keys": {
      "Word": 3142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "再也": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàiyě",
      "PrimaryDefinition": "(not) any more"
    },
    "Keys": {
      "Word": 4175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "烹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēng",
      "PrimaryDefinition": "boil, cook; quick fry, stir fry"
    },
    "Keys": {
      "Hanzi": 2377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.7",
      "GeneralStandard": 2377,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 3461,
      "StrokeCount": 11
    }
  },
  "塆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5074,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "澶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "still water, placid, tranquil"
    },
    "Keys": {
      "Hanzi": 6180
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6180,
      "Radical": "水",
      "FrequencyRank": 5906,
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "蛴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "maggots, grubs"
    },
    "Keys": {
      "Hanzi": 5149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5149,
      "Radical": "虫",
      "FrequencyRank": 4978,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "物流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùliú",
      "PrimaryDefinition": "distribution (business), logistics"
    },
    "Keys": {
      "Word": 9809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "微信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Wēixìn",
      "PrimaryDefinition": "Weixin or WeChat (mobile text and voice messaging service developed by Tencent 騰訊|腾讯[Teng2 xun4])"
    },
    "Keys": {
      "Word": 2970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "漈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "shore; waterside"
    },
    "Keys": {
      "Hanzi": 7730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7730,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "犰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "armadillo"
    },
    "Keys": {
      "Hanzi": 3549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3549,
      "Radical": "犬",
      "FrequencyRank": 6643,
      "RadicalIndex": "94.2",
      "StrokeCount": 5
    }
  },
  "取决于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔjuéyú",
      "PrimaryDefinition": "depending on"
    },
    "Keys": {
      "Word": 8786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "防卫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángwèi",
      "PrimaryDefinition": "defend"
    },
    "Keys": {
      "Word": 6491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缓缓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎnhuǎn",
      "PrimaryDefinition": "postpone, postponement, little by little, slowly, put off, gradually, delay"
    },
    "Keys": {
      "Word": 7170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "trample"
    },
    "Keys": {
      "Hanzi": 5141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5141,
      "Radical": "足",
      "FrequencyRank": 5524,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "锲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "sickle; cut, carve, engrave"
    },
    "Keys": {
      "Hanzi": 5733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.9",
      "GeneralStandard": 5733,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 4771,
      "StrokeCount": 14
    }
  },
  "脎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sà",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 7030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7030,
      "Radical": "肉",
      "FrequencyRank": 8286,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "冤枉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuānwang",
      "PrimaryDefinition": "to accuse wrongly, to treat unjustly, injustice, wronged, not worthwhile"
    },
    "Keys": {
      "Word": 10539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngfèi",
      "PrimaryDefinition": "at public expense"
    },
    "Keys": {
      "Word": 6761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嶟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7783,
      "Radical": "山",
      "RadicalIndex": "46.12",
      "StrokeCount": 15
    }
  },
  "专程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānchéng",
      "PrimaryDefinition": "special trip"
    },
    "Keys": {
      "Word": 10879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "sword, dagger, saber"
    },
    "Keys": {
      "Hanzi": 1587,
      "Word": 4712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.7",
      "Grade": 6,
      "GeneralStandard": 1587,
      "Radical": "刀",
      "HSK": 6,
      "FrequencyRank": 869,
      "StrokeCount": 9
    }
  },
  "观摩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānmó",
      "PrimaryDefinition": "view and emulate"
    },
    "Keys": {
      "Word": 6872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "stop, prevent; defeated, dejected"
    },
    "Keys": {
      "Hanzi": 1247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1247,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2878,
      "StrokeCount": 8
    }
  },
  "当着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngzhe",
      "PrimaryDefinition": "in front of, in the presence of"
    },
    "Keys": {
      "Word": 6180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páng",
      "PrimaryDefinition": "side; by side, close by, near"
    },
    "Keys": {
      "Hanzi": 2045,
      "Word": 3763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "70.6",
      "Grade": 5,
      "GeneralStandard": 2045,
      "Radical": "方",
      "HSK": 1,
      "FrequencyRank": 981,
      "StrokeCount": 10
    }
  },
  "整个": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěnggè",
      "PrimaryDefinition": "whole, entire, total"
    },
    "Keys": {
      "Word": 2144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "怨恨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànhèn",
      "PrimaryDefinition": "to resent, to harbor a grudge against, to loathe, resentment, rancor"
    },
    "Keys": {
      "Word": 10555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "water-chestnut, water caltrop"
    },
    "Keys": {
      "Hanzi": 2199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2199,
      "Radical": "艸",
      "FrequencyRank": 2802,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "罐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "jar, jug, pitcher, pot"
    },
    "Keys": {
      "Hanzi": 3499,
      "Word": 6896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "121.18",
      "Grade": 7,
      "GeneralStandard": 3499,
      "Radical": "缶",
      "HSK": 7,
      "FrequencyRank": 2410,
      "StrokeCount": 23
    }
  },
  "寮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "shanty, hut, shack"
    },
    "Keys": {
      "Hanzi": 6008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6008,
      "Radical": "宀",
      "FrequencyRank": 4567,
      "RadicalIndex": "40.12",
      "StrokeCount": 15
    }
  },
  "一半": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībàn",
      "PrimaryDefinition": "det.: one half, in part, half"
    },
    "Keys": {
      "Word": 431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "零售": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língshòu",
      "PrimaryDefinition": "retail, (sell) retail"
    },
    "Keys": {
      "Word": 8075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哎呀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āiyā",
      "PrimaryDefinition": "jeez, of wonder, alas, wirra, swounds, lummy, shock, admiration"
    },
    "Keys": {
      "Word": 5425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "縢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "bind, tie up, restrain; cord"
    },
    "Keys": {
      "Hanzi": 7884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7884,
      "Radical": "糸",
      "FrequencyRank": 8325,
      "RadicalIndex": "120.1",
      "StrokeCount": 16
    }
  },
  "忽悠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūyou",
      "PrimaryDefinition": "to rock, to sway, to flicker (e.g. of lights reflected on water), to flutter (e.g. of a flag), to trick sb into doing sth, to dupe, to con"
    },
    "Keys": {
      "Word": 7113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "理科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐkē",
      "PrimaryDefinition": "the sciences (as opposed to the humanities 文科[wen2 ke1])"
    },
    "Keys": {
      "Word": 7984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "英俊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngjùn",
      "PrimaryDefinition": "handsome"
    },
    "Keys": {
      "Word": 10410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巡逻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnluó",
      "PrimaryDefinition": "patrol"
    },
    "Keys": {
      "Word": 10150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刁难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāonàn",
      "PrimaryDefinition": "to be hard on sb, to deliberately make things difficult"
    },
    "Keys": {
      "Word": 6285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瀛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "sea, ocean"
    },
    "Keys": {
      "Hanzi": 6414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6414,
      "Radical": "水",
      "FrequencyRank": 3890,
      "RadicalIndex": "85.16",
      "StrokeCount": 19
    }
  },
  "不值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhí",
      "PrimaryDefinition": "not worth"
    },
    "Keys": {
      "Word": 4359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "前仰后合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiányǎng-hòuhé",
      "PrimaryDefinition": "to sway to and fro, to rock back and forth"
    },
    "Keys": {
      "Word": 8671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "臧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāng",
      "PrimaryDefinition": "good, right, generous; command"
    },
    "Keys": {
      "Hanzi": 5681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5681,
      "Radical": "臣",
      "FrequencyRank": 4304,
      "RadicalIndex": "131.8",
      "StrokeCount": 14
    }
  },
  "嘻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "mirthful, happy; interjection"
    },
    "Keys": {
      "Hanzi": 3236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3236,
      "Radical": "口",
      "FrequencyRank": 2432,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "职责": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhízé",
      "PrimaryDefinition": "duty, responsibility, obligation"
    },
    "Keys": {
      "Word": 5353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "郃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "county in Shanxi province"
    },
    "Keys": {
      "Hanzi": 6707
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6707,
      "Radical": "邑",
      "FrequencyRank": 5282,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "弱点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruòdiǎn",
      "PrimaryDefinition": "Achilles' heel, soft spot, failing, weakness, underbelly, defect, foible, infirmity, weak_point, blot, frailty, drawback, Achilles'_heel, soft_spot, weak point, vulnerability"
    },
    "Keys": {
      "Word": 8912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㶲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòng",
      "PrimaryDefinition": "measuring unit for thermodynamics"
    },
    "Keys": {
      "Hanzi": 6879
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6879,
      "Radical": "火",
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "筅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "bamboo brush; halberd"
    },
    "Keys": {
      "Hanzi": 7391
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7391,
      "Radical": "竹",
      "FrequencyRank": 7342,
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "昉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎng",
      "PrimaryDefinition": "bright dawn, daybreak; appear"
    },
    "Keys": {
      "Hanzi": 3906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3906,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "匼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6669,
      "Radical": "匸",
      "RadicalIndex": "23.6",
      "StrokeCount": 8
    }
  },
  "截然不同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiérán-bùtóng",
      "PrimaryDefinition": "entirely different, different as black and white"
    },
    "Keys": {
      "Word": 7531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "佺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "fairy"
    },
    "Keys": {
      "Hanzi": 6698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6698,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "欺诈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīzhà",
      "PrimaryDefinition": "to cheat"
    },
    "Keys": {
      "Word": 8592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "produce, give birth to; educate"
    },
    "Keys": {
      "Hanzi": 1225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "GeneralStandard": 1225,
      "Radical": "肉",
      "HSK": 2,
      "FrequencyRank": 609,
      "StrokeCount": 8
    }
  },
  "榫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔn",
      "PrimaryDefinition": "mortise and tenon; fit into"
    },
    "Keys": {
      "Hanzi": 5663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5663,
      "Radical": "木",
      "FrequencyRank": 4910,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "风范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngfàn",
      "PrimaryDefinition": "air, manner, model, paragon, demeanor"
    },
    "Keys": {
      "Word": 6571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丧失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàngshī",
      "PrimaryDefinition": "cost, forfeiture, forfeit, give_away, lapse, loss, bereaved, lose, jump"
    },
    "Keys": {
      "Word": 4989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "曹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáo",
      "PrimaryDefinition": "ministry officials; surname"
    },
    "Keys": {
      "Hanzi": 2233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "73.7",
      "GeneralStandard": 2233,
      "Radical": "曰",
      "HSK": 9,
      "FrequencyRank": 1570,
      "StrokeCount": 11
    }
  },
  "贽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "gift superior; gift given"
    },
    "Keys": {
      "Hanzi": 4423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4423,
      "Radical": "貝",
      "FrequencyRank": 5402,
      "RadicalIndex": "154.6",
      "StrokeCount": 10
    }
  },
  "野外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěwài",
      "PrimaryDefinition": "air, out-of-doors, open, outdoors, open air, open country, balefire, field"
    },
    "Keys": {
      "Word": 10259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "thread needle, sew stitch, string"
    },
    "Keys": {
      "Hanzi": 597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 597,
      "Radical": "糸",
      "FrequencyRank": 4173,
      "RadicalIndex": "120.3",
      "StrokeCount": 6
    }
  },
  "附带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùdài",
      "PrimaryDefinition": "supplementary, incidentally, in parentheses, by chance, in passing, additionally, secondary, subsidiary, to attach"
    },
    "Keys": {
      "Word": 6627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丛林": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cónglín",
      "PrimaryDefinition": "underbrush, chaparral, bosquet, brake, bosk, jungle, forest, copse, jungly, brushwood, grove, thickset, underwood, thicket, mott, Budd. monastery"
    },
    "Keys": {
      "Word": 6065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pài",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7317,
      "Radical": "艸",
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "鼢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fén",
      "PrimaryDefinition": "a variety of mole"
    },
    "Keys": {
      "Hanzi": 6260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6260,
      "Radical": "鼠",
      "FrequencyRank": 7423,
      "RadicalIndex": "208.4",
      "StrokeCount": 17
    }
  },
  "秽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "dirty, unclean; immoral, obscene"
    },
    "Keys": {
      "Hanzi": 2315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.6",
      "GeneralStandard": 2315,
      "Radical": "禾",
      "HSK": 8,
      "FrequencyRank": 3096,
      "StrokeCount": 11
    }
  },
  "一致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīzhì",
      "PrimaryDefinition": "symphonize, coheiress, identical, quadrate, accord, coincide, equate, homologize, answer, comport, concur, consist, unanimous, chime_in, agree, jibe, unite, square, correspond, consort"
    },
    "Keys": {
      "Word": 3086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "萑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "grass used for making mats; Huanpu, place famous for robbers"
    },
    "Keys": {
      "Hanzi": 4756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4756,
      "Radical": "艸",
      "FrequencyRank": 5796,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "纪实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìshí",
      "PrimaryDefinition": "record of actual events, documentary (factual rather than fictional)"
    },
    "Keys": {
      "Word": 7326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上诉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngsù",
      "PrimaryDefinition": "to appeal (a judicial case), appeal"
    },
    "Keys": {
      "Word": 8980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "broken piece of jade"
    },
    "Keys": {
      "Hanzi": 3832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3832,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "背": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "back; back side; behind; betray"
    },
    "Keys": {
      "Hanzi": 1467,
      "Word": 1284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "Grade": 3,
      "GeneralStandard": 1467,
      "Radical": "肉",
      "HSK": 2,
      "FrequencyRank": 787,
      "StrokeCount": 9
    }
  },
  "娠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "pregnant"
    },
    "Keys": {
      "Hanzi": 4688
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4688,
      "Radical": "女",
      "FrequencyRank": 3590,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "绐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "cheat, fool, pretend"
    },
    "Keys": {
      "Hanzi": 6754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6754,
      "Radical": "糸",
      "FrequencyRank": 6780,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "鳖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biē",
      "PrimaryDefinition": "turtle"
    },
    "Keys": {
      "Hanzi": 3470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3470,
      "Radical": "魚",
      "FrequencyRank": 3960,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "媸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "an ugly woman"
    },
    "Keys": {
      "Hanzi": 5609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5609,
      "Radical": "女",
      "FrequencyRank": 4980,
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "有朝一日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuzhāo-yīrì",
      "PrimaryDefinition": "one day, sometime in the future"
    },
    "Keys": {
      "Word": 10486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "whispering"
    },
    "Keys": {
      "Hanzi": 2619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2619,
      "Radical": "口",
      "FrequencyRank": 3311,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "怎样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zěnyàng",
      "PrimaryDefinition": "no matter how, in any way, in a certain way, how?, what, how"
    },
    "Keys": {
      "Word": 1200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "口头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒutou",
      "PrimaryDefinition": "oral, verbal"
    },
    "Keys": {
      "Word": 7845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "袂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèi",
      "PrimaryDefinition": "sleeves"
    },
    "Keys": {
      "Hanzi": 4363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4363,
      "Radical": "衣",
      "FrequencyRank": 4479,
      "RadicalIndex": "145.4",
      "StrokeCount": 9
    }
  },
  "名义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngyì",
      "PrimaryDefinition": "title, name"
    },
    "Keys": {
      "Word": 4872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "参考": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānkǎo",
      "PrimaryDefinition": "consult, refer to"
    },
    "Keys": {
      "Word": 2289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "啰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luō",
      "PrimaryDefinition": "phonetic"
    },
    "Keys": {
      "Hanzi": 2286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2286,
      "Radical": "网",
      "HSK": 6,
      "RadicalIndex": "122.3",
      "StrokeCount": 11
    }
  },
  "让步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ràng bù",
      "PrimaryDefinition": "to concede, to give in, to yield, a concession"
    },
    "Keys": {
      "Word": 8824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "原则": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánzé",
      "PrimaryDefinition": "god, rule, formula, regulation, principle, fundamental, axiom, basis, linguistic rule, faith, principium"
    },
    "Keys": {
      "Word": 3133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "客户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèhù",
      "PrimaryDefinition": "clientage, custom, customer, client, business, patronage, clientele"
    },
    "Keys": {
      "Word": 3660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "都会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūhuì",
      "PrimaryDefinition": "big city, city, metropolis"
    },
    "Keys": {
      "Word": 6354
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口碑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒubēi",
      "PrimaryDefinition": "public praise, public reputation, commonly held opinions, current idiom"
    },
    "Keys": {
      "Word": 7835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "首批": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒupī",
      "PrimaryDefinition": "First batch"
    },
    "Keys": {
      "Word": 9189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "追求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīqiú",
      "PrimaryDefinition": "seek, pursue, woo, court"
    },
    "Keys": {
      "Word": 3204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "填": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "fill in, fill up; make good"
    },
    "Keys": {
      "Hanzi": 2834,
      "Word": 2931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.1",
      "Grade": 4,
      "GeneralStandard": 2834,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 1970,
      "StrokeCount": 13
    }
  },
  "火炬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒjù",
      "PrimaryDefinition": "a torch, CL:把[ba3]"
    },
    "Keys": {
      "Word": 7243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诊所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěnsuǒ",
      "PrimaryDefinition": "clinic"
    },
    "Keys": {
      "Word": 10703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "place, locale; department"
    },
    "Keys": {
      "Hanzi": 294,
      "Word": 2338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "34.2",
      "Grade": 4,
      "GeneralStandard": 294,
      "Radical": "夂",
      "HSK": 2,
      "FrequencyRank": 206,
      "StrokeCount": 5
    }
  },
  "密度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìdù",
      "PrimaryDefinition": "density, texture, thickness, consistency, compactness, concentration, denseness"
    },
    "Keys": {
      "Word": 8269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "马": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎ",
      "PrimaryDefinition": "horse"
    },
    "Keys": {
      "Hanzi": 72,
      "Radical": 187,
      "Word": 1730
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187",
      "Grade": 3,
      "GeneralStandard": 72,
      "Radical": "馬",
      "HSK": 1,
      "FrequencyRank": 276,
      "StrokeCount": 3
    }
  },
  "施行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīxíng",
      "PrimaryDefinition": "put in force, execute, implement, apply, perform"
    },
    "Keys": {
      "Word": 9098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéyè",
      "PrimaryDefinition": "one's studies, schoolwork"
    },
    "Keys": {
      "Word": 10136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "charming, fascinating; gay"
    },
    "Keys": {
      "Hanzi": 5830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5830,
      "Radical": "女",
      "FrequencyRank": 3520,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "诗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "poetry; poem, verse, ode"
    },
    "Keys": {
      "Hanzi": 1288,
      "Word": 2858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "Grade": 4,
      "GeneralStandard": 1288,
      "Radical": "言",
      "HSK": 4,
      "FrequencyRank": 906,
      "StrokeCount": 8
    }
  },
  "僚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "companion, colleague; officials; bureaucracy; a pretty face"
    },
    "Keys": {
      "Hanzi": 3126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.12",
      "GeneralStandard": 3126,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 2234,
      "StrokeCount": 14
    }
  },
  "足迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zújì",
      "PrimaryDefinition": "slot, track, footprint, footstep, wake, footmark, trace, trail, spoor, step"
    },
    "Keys": {
      "Word": 11016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "限于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànyú",
      "PrimaryDefinition": "be confined, restrict, be limited to, be confined to, limited to"
    },
    "Keys": {
      "Word": 9908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "测验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèyàn",
      "PrimaryDefinition": "test"
    },
    "Keys": {
      "Word": 5779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "譬如说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìrúshuō",
      "PrimaryDefinition": "for example, such as"
    },
    "Keys": {
      "Word": 8515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "misfit; disordant; music instrument; hoe"
    },
    "Keys": {
      "Hanzi": 5178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5178,
      "Radical": "金",
      "FrequencyRank": 7726,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "变革": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàngé",
      "PrimaryDefinition": "change, revolution"
    },
    "Keys": {
      "Word": 5605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "cough; pant; roar"
    },
    "Keys": {
      "Hanzi": 1890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1890,
      "Radical": "口",
      "FrequencyRank": 2908,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "浓郁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngyù",
      "PrimaryDefinition": "rich, strong, heavy (fragrance), dense, full-bodied, intense"
    },
    "Keys": {
      "Word": 8439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "turquoise"
    },
    "Keys": {
      "Hanzi": 5855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5855,
      "Radical": "玉",
      "FrequencyRank": 6903,
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "友谊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuyì",
      "PrimaryDefinition": "fellowship, comity, communion, attachment, companionship, friendly relationship, sodality, association, friendship, good-fellowship, friendliness, company, palship"
    },
    "Keys": {
      "Word": 4142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "岫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "mountain peak; cave, cavern"
    },
    "Keys": {
      "Hanzi": 3926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3926,
      "Radical": "山",
      "FrequencyRank": 4745,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "甘心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānxīn",
      "PrimaryDefinition": "be reconciled, be willing, pleased, resigned to, be content with, be reconciled to, be reconciled/resigned to, be resigned to, resign oneself to"
    },
    "Keys": {
      "Word": 6667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "便道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàndào",
      "PrimaryDefinition": "pavement, sidewalk, shortcut, makeshift road"
    },
    "Keys": {
      "Word": 5610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悬挂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánguà",
      "PrimaryDefinition": "to suspend, to hang, (vehicle) suspension"
    },
    "Keys": {
      "Word": 10118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔn",
      "PrimaryDefinition": "economize; abide by, comply with"
    },
    "Keys": {
      "Hanzi": 5866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5866,
      "Radical": "手",
      "FrequencyRank": 6490,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "敲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": "strike, beat, pound, hammer; rap"
    },
    "Keys": {
      "Hanzi": 3140,
      "Word": 3814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.1",
      "Grade": 5,
      "GeneralStandard": 3140,
      "Radical": "攴",
      "HSK": 5,
      "FrequencyRank": 1732,
      "StrokeCount": 14
    }
  },
  "时装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shízhuāng",
      "PrimaryDefinition": "fashion, fashionable clothes"
    },
    "Keys": {
      "Word": 5020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "杷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pá",
      "PrimaryDefinition": "loquat"
    },
    "Keys": {
      "Hanzi": 3884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3884,
      "Radical": "木",
      "FrequencyRank": 5215,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "椭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǒ",
      "PrimaryDefinition": "oval-shaped, elliptical, tubular"
    },
    "Keys": {
      "Hanzi": 2579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2579,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 3312,
      "StrokeCount": 12
    }
  },
  "一卡通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīkǎtōng",
      "PrimaryDefinition": "Yikatong (Beijing public transport smart card)"
    },
    "Keys": {
      "Word": 10332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "文明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénmíng",
      "PrimaryDefinition": "civilization, culture"
    },
    "Keys": {
      "Word": 2013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "取消": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔxiāo",
      "PrimaryDefinition": "cancel, abolish, nullify"
    },
    "Keys": {
      "Word": 1825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "大方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàfang",
      "PrimaryDefinition": "expert, scholar, mother earth, a type of green tea, generous, magnanimous, stylish, in good taste, easy-mannered, natural and relaxed"
    },
    "Keys": {
      "Word": 2369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "元素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánsù",
      "PrimaryDefinition": "element"
    },
    "Keys": {
      "Word": 5285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "open; throw away"
    },
    "Keys": {
      "Hanzi": 6866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6866,
      "Radical": "肉",
      "FrequencyRank": 6997,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "实质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shízhì",
      "PrimaryDefinition": "quintessence, substantial, essence, meat, parenchyma, kernel, substantiality, measure, being, quiddity, solidity, substance, matter"
    },
    "Keys": {
      "Word": 9119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有意思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuyìsi",
      "PrimaryDefinition": "interesting, meaningful, enjoyable"
    },
    "Keys": {
      "Word": 1176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "积累": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīlěi",
      "PrimaryDefinition": "to accumulate, accumulation, cumulative, cumulatively"
    },
    "Keys": {
      "Word": 2575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "便利店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànlìdiàn",
      "PrimaryDefinition": "convenience store"
    },
    "Keys": {
      "Word": 5613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回首": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíshǒu",
      "PrimaryDefinition": "to turn around, to look back, to recollect"
    },
    "Keys": {
      "Word": 7207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "scold, bellow, shout at, roar"
    },
    "Keys": {
      "Hanzi": 4226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4226,
      "Radical": "口",
      "FrequencyRank": 4730,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "蒨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "lush vegetation, luxuriant growth"
    },
    "Keys": {
      "Hanzi": 7485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7485,
      "Radical": "艸",
      "FrequencyRank": 6795,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "飑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "storm"
    },
    "Keys": {
      "Hanzi": 4293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4293,
      "Radical": "風",
      "FrequencyRank": 7109,
      "RadicalIndex": "182.5",
      "StrokeCount": 9
    }
  },
  "㬊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "light; bright, clear, intelligent; clever"
    },
    "Keys": {
      "Hanzi": 7520
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7520,
      "Radical": "日",
      "RadicalIndex": "72.9",
      "StrokeCount": 13
    }
  },
  "觭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "one turning up and one turning down"
    },
    "Keys": {
      "Hanzi": 7804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7804,
      "Radical": "角",
      "FrequencyRank": 8479,
      "RadicalIndex": "148.8",
      "StrokeCount": 15
    }
  },
  "历届": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìjiè",
      "PrimaryDefinition": "all previous (meetings, sessions etc)"
    },
    "Keys": {
      "Word": 7995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diān",
      "PrimaryDefinition": "summit of mountain, mountain top"
    },
    "Keys": {
      "Hanzi": 3461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.16",
      "GeneralStandard": 3461,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 3775,
      "StrokeCount": 19
    }
  },
  "显现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnxiàn",
      "PrimaryDefinition": "demonstrate, come on, show, uncover, show_up, express, come out, manifest oneself, show up, turn up, appear, reveal, reveal oneself, emerge, show_off, appearance, run, evince, surface, kithe, take_on, unveil, break, bring out"
    },
    "Keys": {
      "Word": 9897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "估计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūjì",
      "PrimaryDefinition": "forecast, set, evaluate, rate, gauge, measure, count, assess, calculate, estimate, reckon, appraisal, compute, estimated, value, appraise, put, overrate, make, place, size_up"
    },
    "Keys": {
      "Word": 3509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "酣畅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hānchàng",
      "PrimaryDefinition": "unrestrained, cheerful lack of inhibition, esp. for drinking or sleeping, to drink with abandon"
    },
    "Keys": {
      "Word": 6978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "霖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "long spell of rain, copious rain"
    },
    "Keys": {
      "Hanzi": 6076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6076,
      "Radical": "雨",
      "FrequencyRank": 3133,
      "RadicalIndex": "173.8",
      "StrokeCount": 16
    }
  },
  "尿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niào",
      "PrimaryDefinition": "urine; urinate"
    },
    "Keys": {
      "Hanzi": 904,
      "Word": 8423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.4",
      "Grade": 7,
      "GeneralStandard": 904,
      "Radical": "尸",
      "HSK": 7,
      "FrequencyRank": 1877,
      "StrokeCount": 7
    }
  },
  "录制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùzhì",
      "PrimaryDefinition": "to record (video or audio)"
    },
    "Keys": {
      "Word": 8128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "be pregnant, pregnancy"
    },
    "Keys": {
      "Hanzi": 338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.2",
      "GeneralStandard": 338,
      "Radical": "子",
      "HSK": 7,
      "FrequencyRank": 2094,
      "StrokeCount": 5
    }
  },
  "房地产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángdìchǎn",
      "PrimaryDefinition": "blockbuster, real estate, realty, real_estate, real property, property"
    },
    "Keys": {
      "Word": 6496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暖烘烘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuǎnhōnghōng",
      "PrimaryDefinition": "nice and warm, cosy, heartwarming"
    },
    "Keys": {
      "Word": 8444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "天赋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānfù",
      "PrimaryDefinition": "gift, innate skill"
    },
    "Keys": {
      "Word": 9480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怜惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánxī",
      "PrimaryDefinition": "to take pity on, to feel tenderness toward"
    },
    "Keys": {
      "Word": 8016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "a sad, uneasy countenance"
    },
    "Keys": {
      "Hanzi": 3785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3785,
      "Radical": "心",
      "FrequencyRank": 3326,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "粗心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūxīn",
      "PrimaryDefinition": "careless, thoughtless"
    },
    "Keys": {
      "Word": 2355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "平常心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngchángxīn",
      "PrimaryDefinition": "Ordinary heart"
    },
    "Keys": {
      "Word": 8546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒu",
      "PrimaryDefinition": "old man; elder"
    },
    "Keys": {
      "Hanzi": 4260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4260,
      "Radical": "又",
      "FrequencyRank": 3804,
      "RadicalIndex": "29.8",
      "StrokeCount": 9
    }
  },
  "局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jú",
      "PrimaryDefinition": "bureau, office; circumstance"
    },
    "Keys": {
      "Hanzi": 907,
      "Word": 4766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.4",
      "Grade": 6,
      "GeneralStandard": 907,
      "Radical": "尸",
      "HSK": 4,
      "FrequencyRank": 483,
      "StrokeCount": 7
    }
  },
  "灯泡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēngpào",
      "PrimaryDefinition": "light bulb, see also 電燈泡|电灯泡[dian4 deng1 pao4], third-wheel or unwanted third party spoiling a couple's date (slang), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 6220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兑换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìhuàn",
      "PrimaryDefinition": "to convert, to exchange"
    },
    "Keys": {
      "Word": 6386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "值得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí de",
      "PrimaryDefinition": "be worth, merit, pay, rate, behove, worthwhile, worth, worthy, deserve, call_for, command"
    },
    "Keys": {
      "Word": 2162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "延": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "delay, postpone, defer"
    },
    "Keys": {
      "Hanzi": 464,
      "Word": 10170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "54.4",
      "Grade": 7,
      "GeneralStandard": 464,
      "Radical": "廴",
      "HSK": 4,
      "FrequencyRank": 1103,
      "StrokeCount": 6
    }
  },
  "实习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíxí",
      "PrimaryDefinition": "practice, do fieldwork"
    },
    "Keys": {
      "Word": 974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "巧合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎohé",
      "PrimaryDefinition": "coincidence, coincidental, to coincide"
    },
    "Keys": {
      "Word": 8704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "素质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùzhì",
      "PrimaryDefinition": "calibre, fibre, stuff, vocation, diathesis, caliber, character, strain, predisposition, quality, timber"
    },
    "Keys": {
      "Word": 5055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "造价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàojià",
      "PrimaryDefinition": "construction cost"
    },
    "Keys": {
      "Word": 10612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嶙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "precipitous"
    },
    "Keys": {
      "Hanzi": 5941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5941,
      "Radical": "山",
      "FrequencyRank": 4467,
      "RadicalIndex": "46.12",
      "StrokeCount": 15
    }
  },
  "纯洁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúnjié",
      "PrimaryDefinition": "pure, clean and honest, to purify"
    },
    "Keys": {
      "Word": 6029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐguǒ",
      "PrimaryDefinition": "fruit"
    },
    "Keys": {
      "Word": 342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "忙活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mánghuo",
      "PrimaryDefinition": "to be really busy, pressing business"
    },
    "Keys": {
      "Word": 8206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "付款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù kuǎn",
      "PrimaryDefinition": "ante_up, ante up, foot, pay out, pay up, pay, shell_out, cash, pay a sum of money, disburse"
    },
    "Keys": {
      "Word": 6624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "wings; fin"
    },
    "Keys": {
      "Hanzi": 1859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124.4",
      "GeneralStandard": 1859,
      "Radical": "羽",
      "HSK": 7,
      "FrequencyRank": 2066,
      "StrokeCount": 10
    }
  },
  "开辟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāipì",
      "PrimaryDefinition": "to open up, to set up, to establish"
    },
    "Keys": {
      "Word": 7753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "step"
    },
    "Keys": {
      "Radical": 60,
      "Hanzi": 6503
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6503,
      "Radical": "彳",
      "RadicalIndex": "60",
      "StrokeCount": 3
    }
  },
  "苗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáo",
      "PrimaryDefinition": "sprouts; Miao nationality"
    },
    "Keys": {
      "Hanzi": 1005,
      "Word": 8289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "Grade": 7,
      "GeneralStandard": 1005,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 1920,
      "StrokeCount": 8
    }
  },
  "柜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "cabinet, cupboard; shop counter"
    },
    "Keys": {
      "Hanzi": 1022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1022,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 1994,
      "StrokeCount": 8
    }
  },
  "萘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nài",
      "PrimaryDefinition": "naphthalene"
    },
    "Keys": {
      "Hanzi": 4750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4750,
      "Radical": "艸",
      "FrequencyRank": 5630,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüè",
      "PrimaryDefinition": "approximately, roughly; outline"
    },
    "Keys": {
      "Hanzi": 2277,
      "Word": 8155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102.6",
      "Grade": 7,
      "GeneralStandard": 2277,
      "Radical": "田",
      "HSK": 6,
      "FrequencyRank": 704,
      "StrokeCount": 11
    }
  },
  "召开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàokāi",
      "PrimaryDefinition": "convene, convoke"
    },
    "Keys": {
      "Word": 3158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "那": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "that, that one, those"
    },
    "Keys": {
      "Hanzi": 564,
      "Word": 878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "163.4",
      "Grade": 2,
      "GeneralStandard": 564,
      "Radical": "邑",
      "HSK": 1,
      "FrequencyRank": 38,
      "StrokeCount": 6
    }
  },
  "菝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7120,
      "Radical": "艸",
      "FrequencyRank": 6493,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "书面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūmiàn",
      "PrimaryDefinition": "written, in written form, in writing"
    },
    "Keys": {
      "Word": 9209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèyǒu",
      "PrimaryDefinition": "characteristic, especial"
    },
    "Keys": {
      "Word": 3969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "读书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú shū",
      "PrimaryDefinition": "read, study, attend school"
    },
    "Keys": {
      "Word": 90
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "回家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí jiā",
      "PrimaryDefinition": "return home"
    },
    "Keys": {
      "Word": 154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "睿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": "shrewd, astute, clever, keen"
    },
    "Keys": {
      "Hanzi": 5692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5692,
      "Radical": "目",
      "FrequencyRank": 3407,
      "RadicalIndex": "109.9",
      "StrokeCount": 14
    }
  },
  "先例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānlì",
      "PrimaryDefinition": "antecedent, precedent"
    },
    "Keys": {
      "Word": 9883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuà",
      "PrimaryDefinition": "carry"
    },
    "Keys": {
      "Hanzi": 1372,
      "Word": 7873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1372,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 4005,
      "StrokeCount": 9
    }
  },
  "达到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá dào",
      "PrimaryDefinition": "extend to, find, fulfil, compass, encompass, extend, gain, answer, achievement, succeed, fulfill, live_up_to, attainment, strain, bring_up, achieve, get to, touch, strive, make, accomplish, progress to, come, hit, come_out, obtain, reach, attain, clock_up, suffice, do, get, arrive_at"
    },
    "Keys": {
      "Word": 1381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "我们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǒmen",
      "PrimaryDefinition": "we"
    },
    "Keys": {
      "Word": 377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "重复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngfù",
      "PrimaryDefinition": "repeat, duplicate"
    },
    "Keys": {
      "Word": 560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "跶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "stumble, slip"
    },
    "Keys": {
      "Hanzi": 5453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5453,
      "Radical": "足",
      "FrequencyRank": 6059,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "新陈代谢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnchén-dàixiè",
      "PrimaryDefinition": "metabolism (biology), the new replaces the old (idiom)"
    },
    "Keys": {
      "Word": 10027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "僻静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìjìng",
      "PrimaryDefinition": "lonely, secluded"
    },
    "Keys": {
      "Word": 8516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngjié",
      "PrimaryDefinition": "plot, verisimilitude, buildup, scenario, literary argument, argument, circumstances, story, clue, intrigue, details, action"
    },
    "Keys": {
      "Word": 3824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "之中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīzhōng",
      "PrimaryDefinition": "inside, among, within"
    },
    "Keys": {
      "Word": 4217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "深度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēndù",
      "PrimaryDefinition": "measurement, fullness, profundity, depth, level, deepness, fulness, profoundness"
    },
    "Keys": {
      "Word": 3883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "键盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànpán",
      "PrimaryDefinition": "keyboard"
    },
    "Keys": {
      "Word": 3606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "授予": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòuyǔ",
      "PrimaryDefinition": "to award, to confer"
    },
    "Keys": {
      "Word": 9204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "vinegar; pickle; acid"
    },
    "Keys": {
      "Hanzi": 6376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6376,
      "Radical": "酉",
      "FrequencyRank": 4445,
      "RadicalIndex": "164.12",
      "StrokeCount": 19
    }
  },
  "致辞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì cí",
      "PrimaryDefinition": "to express in words or writing, to make a speech (esp. short introduction, vote of thanks, afterword, funeral homily etc), to address (an audience), same as 致詞|致词"
    },
    "Keys": {
      "Word": 10797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "转达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎndá",
      "PrimaryDefinition": "to pass on, to convey, to communicate"
    },
    "Keys": {
      "Word": 10889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "所作所为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒzuò-suǒwéi",
      "PrimaryDefinition": "one's conduct and deeds"
    },
    "Keys": {
      "Word": 9380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "heat with fire; stew"
    },
    "Keys": {
      "Hanzi": 4005,
      "Word": 6392
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.4",
      "Grade": 7,
      "GeneralStandard": 4005,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 3857,
      "StrokeCount": 8
    }
  },
  "剔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tī",
      "PrimaryDefinition": "pick out; scrape off; scrape meat"
    },
    "Keys": {
      "Hanzi": 1896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.8",
      "GeneralStandard": 1896,
      "Radical": "刀",
      "HSK": 8,
      "FrequencyRank": 3156,
      "StrokeCount": 10
    }
  },
  "理想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐxiǎng",
      "PrimaryDefinition": "ideal,"
    },
    "Keys": {
      "Word": 840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "怙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "rely on, presume on; persist in"
    },
    "Keys": {
      "Hanzi": 4025
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4025,
      "Radical": "心",
      "FrequencyRank": 5675,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "一塌糊涂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yītāhútú",
      "PrimaryDefinition": "muddled and completely collapsing (idiom), in an awful condition, complete shambles, a total mess"
    },
    "Keys": {
      "Word": 10341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "chair, seat"
    },
    "Keys": {
      "Hanzi": 2569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2569,
      "Radical": "木",
      "HSK": 2,
      "FrequencyRank": 1663,
      "StrokeCount": 12
    }
  },
  "清淡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngdàn",
      "PrimaryDefinition": "light (of food, not greasy or strongly flavored), insipid, slack (sales)"
    },
    "Keys": {
      "Word": 8748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ē",
      "PrimaryDefinition": "to ease nature"
    },
    "Keys": {
      "Hanzi": 4680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4680,
      "Radical": "尸",
      "FrequencyRank": 5435,
      "RadicalIndex": "44.8",
      "StrokeCount": 10
    }
  },
  "接到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēdào",
      "PrimaryDefinition": "welcome, receive, meet"
    },
    "Keys": {
      "Word": 776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "海内外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎinèiwài",
      "PrimaryDefinition": "domestic and international, at home and abroad"
    },
    "Keys": {
      "Word": 6967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒu",
      "PrimaryDefinition": "kind of aspen found in Sichuan"
    },
    "Keys": {
      "Hanzi": 4156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4156,
      "Radical": "木",
      "FrequencyRank": 4578,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "岛屿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoyǔ",
      "PrimaryDefinition": "island, islands, islands and islets, isle"
    },
    "Keys": {
      "Word": 6192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煤炭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méitàn",
      "PrimaryDefinition": "coal"
    },
    "Keys": {
      "Word": 8230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "收看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōukàn",
      "PrimaryDefinition": "watch, tune in, watch (e.g., receive, look in, watch (e.g., TV), tune into, TV)"
    },
    "Keys": {
      "Word": 1922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "泼冷水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō lěngshuǐ",
      "PrimaryDefinition": "lit. to pour cold water on, fig. to dampen one's enthusiasm"
    },
    "Keys": {
      "Word": 8564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔ",
      "PrimaryDefinition": "knot; button; handle, knob; tie"
    },
    "Keys": {
      "Hanzi": 942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 942,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 1767,
      "StrokeCount": 7
    }
  },
  "运行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnxíng",
      "PrimaryDefinition": "to move along one's course (of celestial bodies etc), (fig.) to function, to be in operation, (of a train service etc) to operate, to run, (of a computer) to run"
    },
    "Keys": {
      "Word": 4163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù",
      "PrimaryDefinition": "enter"
    },
    "Keys": {
      "Hanzi": 11,
      "Radical": 11,
      "Word": 4980
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "11",
      "Grade": 6,
      "GeneralStandard": 11,
      "Radical": "入",
      "HSK": 2,
      "FrequencyRank": 210,
      "StrokeCount": 2
    }
  },
  "指甲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐjia",
      "PrimaryDefinition": "fingernail"
    },
    "Keys": {
      "Word": 4231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "师父": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīfu",
      "PrimaryDefinition": "guru, address for nun/etc., teacher, tutor, address for monk, professional, master, nun, a polite form of address to a monk or nun, address for monk/nun/etc., etc."
    },
    "Keys": {
      "Word": 5014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "治学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìxué",
      "PrimaryDefinition": "scholarship, high-level study, to do scholarly research, to pursue a high level of study"
    },
    "Keys": {
      "Word": 10793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "理由": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐyóu",
      "PrimaryDefinition": "excuse, reasoning, reason, wherefore, argument, meaning, account, cause, room, alibi, substance, warranty, matter, justification, occasion, score, warrant, ground, sake, why, grounds, consideration, self-justification, skill"
    },
    "Keys": {
      "Word": 1697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "推移": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīyí",
      "PrimaryDefinition": "(of time) to elapse or pass, (of a situation) to develop or evolve"
    },
    "Keys": {
      "Word": 9602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōmíng",
      "PrimaryDefinition": "explain, illustrate, show"
    },
    "Keys": {
      "Word": 994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蟹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "crab, brachyura"
    },
    "Keys": {
      "Hanzi": 3464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3464,
      "Radical": "虫",
      "FrequencyRank": 3049,
      "RadicalIndex": "142.13",
      "StrokeCount": 19
    }
  },
  "暗地里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àndìli",
      "PrimaryDefinition": "secretly, inwardly, on the sly"
    },
    "Keys": {
      "Word": 5446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滞留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìliú",
      "PrimaryDefinition": "to detain, retention"
    },
    "Keys": {
      "Word": 10806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "文物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénwù",
      "PrimaryDefinition": "historical relics, cultural objects, artifact, historical relic, cultural relic, cultural/historical objects/relics, cultural, historical objects, relics"
    },
    "Keys": {
      "Word": 9742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎn",
      "PrimaryDefinition": "jaundice; disorders of stomach"
    },
    "Keys": {
      "Hanzi": 4616
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4616,
      "Radical": "疒",
      "FrequencyRank": 4533,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "玩具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánjù",
      "PrimaryDefinition": "yo-yo, bauble, plaything, toy, teether, knickknack"
    },
    "Keys": {
      "Word": 2001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "眇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "blind in one eye; minute, minuscule"
    },
    "Keys": {
      "Hanzi": 4192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4192,
      "Radical": "目",
      "FrequencyRank": 5993,
      "RadicalIndex": "109.4",
      "StrokeCount": 9
    }
  },
  "推进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuījìn",
      "PrimaryDefinition": "to impel, to carry forward, to push on, to advance, to drive forward"
    },
    "Keys": {
      "Word": 1990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "昤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "sunshine"
    },
    "Keys": {
      "Hanzi": 6828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6828,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "鲸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "whale"
    },
    "Keys": {
      "Hanzi": 3366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3366,
      "Radical": "魚",
      "FrequencyRank": 2618,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "碟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "small dish, plate"
    },
    "Keys": {
      "Hanzi": 3090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.9",
      "GeneralStandard": 3090,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 2835,
      "StrokeCount": 14
    }
  },
  "顺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùn",
      "PrimaryDefinition": "obey, submit to, go along with"
    },
    "Keys": {
      "Hanzi": 1559,
      "Word": 5045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.3",
      "Grade": 6,
      "GeneralStandard": 1559,
      "Radical": "頁",
      "HSK": 2,
      "FrequencyRank": 938,
      "StrokeCount": 9
    }
  },
  "附属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùshǔ",
      "PrimaryDefinition": "adjunct, belong, pertain, appertain, affiliated, be attached, attached, adjective, auxiliary, be affiliated, subsidiary"
    },
    "Keys": {
      "Word": 6630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "father, papa"
    },
    "Keys": {
      "Hanzi": 1171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "88.4",
      "GeneralStandard": 1171,
      "Radical": "父",
      "HSK": 1,
      "FrequencyRank": 1050,
      "StrokeCount": 8
    }
  },
  "九": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔ",
      "PrimaryDefinition": "nine"
    },
    "Keys": {
      "Hanzi": 15,
      "Word": 184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "5.1",
      "Grade": 1,
      "GeneralStandard": 15,
      "Radical": "乙",
      "HSK": 1,
      "FrequencyRank": 445,
      "StrokeCount": 2
    }
  },
  "包容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāoróng",
      "PrimaryDefinition": "forgive, pardon, contain"
    },
    "Keys": {
      "Word": 5512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "译": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "translate; decode; encode"
    },
    "Keys": {
      "Hanzi": 898,
      "Word": 10370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "Grade": 7,
      "GeneralStandard": 898,
      "Radical": "言",
      "HSK": 4,
      "FrequencyRank": 1198,
      "StrokeCount": 7
    }
  },
  "酵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "yeast, leaven"
    },
    "Keys": {
      "Hanzi": 3086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.7",
      "GeneralStandard": 3086,
      "Radical": "酉",
      "HSK": 9,
      "FrequencyRank": 3531,
      "StrokeCount": 14
    }
  },
  "蝼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "a mole cricket, Gryllotalpa africana"
    },
    "Keys": {
      "Hanzi": 5928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5928,
      "Radical": "虫",
      "FrequencyRank": 5523,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "观点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāndiǎn",
      "PrimaryDefinition": "view, aspect, stand, point_of_view, light, eyes, framework, sentiment, bet, standpoint, point of view, eye, viewpoint, slant, perspective, position, angle"
    },
    "Keys": {
      "Word": 697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "限度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàndù",
      "PrimaryDefinition": "limitation, limit"
    },
    "Keys": {
      "Word": 9904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "美元": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měiyuán",
      "PrimaryDefinition": "m.[standard]"
    },
    "Keys": {
      "Word": 1740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "逼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bī",
      "PrimaryDefinition": "compel, pressure, force; bother"
    },
    "Keys": {
      "Hanzi": 2582,
      "Word": 4322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "Grade": 6,
      "GeneralStandard": 2582,
      "Radical": "辵",
      "HSK": 6,
      "FrequencyRank": 1559,
      "StrokeCount": 12
    }
  },
  "蹢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "hoof; a falter; to hesitate"
    },
    "Keys": {
      "Hanzi": 7997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7997,
      "Radical": "足",
      "RadicalIndex": "157.11",
      "StrokeCount": 18
    }
  },
  "毫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "fine hair; measure of length"
    },
    "Keys": {
      "Hanzi": 2376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "82.7",
      "GeneralStandard": 2376,
      "Radical": "毛",
      "HSK": 4,
      "FrequencyRank": 879,
      "StrokeCount": 11
    }
  },
  "鸤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "turtledove, cuckoo"
    },
    "Keys": {
      "Hanzi": 6733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6733,
      "Radical": "鳥",
      "RadicalIndex": "196.3",
      "StrokeCount": 8
    }
  },
  "四合院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìhéyuàn",
      "PrimaryDefinition": "courtyard house (type of Chinese residence)"
    },
    "Keys": {
      "Word": 9323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腐化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔhuà",
      "PrimaryDefinition": "to rot, to decay, to become corrupt"
    },
    "Keys": {
      "Word": 6618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuduàn",
      "PrimaryDefinition": "resource, recourse, vehicle, wile, stratagem, gambit, artifice, instrument, twist, road, trick, ploy, shift, resort, polity, step, instrumentation, plan of attack, communication, artillery, finesse, means, channel, approach, implement, medium, gimmick, attack, device, refuge, method, instrumentality, stepping-stone, gateway, ministry, measure, way, tactic, expedient, quomodo, machinery, t"
    },
    "Keys": {
      "Word": 3914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "decide, settle, fix"
    },
    "Keys": {
      "Hanzi": 1276,
      "Word": 2417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "Grade": 4,
      "GeneralStandard": 1276,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 77,
      "StrokeCount": 8
    }
  },
  "过日子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò rìzi",
      "PrimaryDefinition": "live, get along, practice economy"
    },
    "Keys": {
      "Word": 6953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皮肤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pífū",
      "PrimaryDefinition": "leather, chap, integument, hide, cutis, complexion, brunet, cutaneous, bark, fell, derma, brunette, skin, transplant"
    },
    "Keys": {
      "Word": 3768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "顺利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnlì",
      "PrimaryDefinition": "smooth, successful, without a hitch"
    },
    "Keys": {
      "Word": 993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "怀念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáiniàn",
      "PrimaryDefinition": "cherish memory of, think of"
    },
    "Keys": {
      "Word": 2553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "读音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúyīn",
      "PrimaryDefinition": "pronunciation, literary (rather than colloquial) pronunciation of a Chinese character"
    },
    "Keys": {
      "Word": 632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "自力更生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìlì-gēngshēng",
      "PrimaryDefinition": "regeneration through one's own effort (idiom), self-reliance"
    },
    "Keys": {
      "Word": 10957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "游泳池": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuyǒngchí",
      "PrimaryDefinition": "swimming pool, CL:場|场[chang3]"
    },
    "Keys": {
      "Word": 4139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "苑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "pasture, park, garden; mansion"
    },
    "Keys": {
      "Hanzi": 1008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1008,
      "Radical": "艸",
      "FrequencyRank": 2853,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "铚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "a sickle"
    },
    "Keys": {
      "Hanzi": 7183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7183,
      "Radical": "金",
      "FrequencyRank": 7035,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "趾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "toe; tracks, footprints"
    },
    "Keys": {
      "Hanzi": 2274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2274,
      "Radical": "足",
      "FrequencyRank": 3164,
      "RadicalIndex": "157.4",
      "StrokeCount": 11
    }
  },
  "布局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjú",
      "PrimaryDefinition": "arrangement, composition, layout, opening (chess jargon)"
    },
    "Keys": {
      "Word": 5726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑞雪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruìxuě",
      "PrimaryDefinition": "timely snow"
    },
    "Keys": {
      "Word": 8908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "look; look out for; respect"
    },
    "Keys": {
      "Hanzi": 3441
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.13",
      "GeneralStandard": 3441,
      "Radical": "目",
      "HSK": 9,
      "FrequencyRank": 2960,
      "StrokeCount": 18
    }
  },
  "魍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "demons, mountain spirits"
    },
    "Keys": {
      "Hanzi": 6266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6266,
      "Radical": "鬼",
      "FrequencyRank": 5298,
      "RadicalIndex": "194.8",
      "StrokeCount": 17
    }
  },
  "侧重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèzhòng",
      "PrimaryDefinition": "to place particular emphasis on"
    },
    "Keys": {
      "Word": 5777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": "to chop straw fine for animals"
    },
    "Keys": {
      "Hanzi": 6954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6954,
      "Radical": "艸",
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "虞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "concerned about, anxious, worried"
    },
    "Keys": {
      "Hanzi": 5430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5430,
      "Radical": "虍",
      "FrequencyRank": 2936,
      "RadicalIndex": "141.7",
      "StrokeCount": 13
    }
  },
  "汔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3624,
      "Radical": "水",
      "FrequencyRank": 6852,
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "劳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "labor, toil, do manual work"
    },
    "Keys": {
      "Hanzi": 674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.5",
      "GeneralStandard": 674,
      "Radical": "力",
      "HSK": 5,
      "FrequencyRank": 679,
      "StrokeCount": 7
    }
  },
  "崛起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéqǐ",
      "PrimaryDefinition": "to rise abruptly (to a towering position), to tower over, to spring up, to emerge suddenly, the emergence (e.g. of a power)"
    },
    "Keys": {
      "Word": 7725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "scenery, view; conditions"
    },
    "Keys": {
      "Hanzi": 2626,
      "Word": 4761
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "Grade": 6,
      "GeneralStandard": 2626,
      "Radical": "日",
      "HSK": 3,
      "FrequencyRank": 814,
      "StrokeCount": 12
    }
  },
  "锌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "zinc"
    },
    "Keys": {
      "Hanzi": 2665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2665,
      "Radical": "金",
      "FrequencyRank": 3533,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "造福": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàofú",
      "PrimaryDefinition": "to benefit (e.g. the people)"
    },
    "Keys": {
      "Word": 10613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "龟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "turtle"
    },
    "Keys": {
      "Hanzi": 808,
      "Radical": 213,
      "Word": 6915
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "213",
      "Grade": 7,
      "GeneralStandard": 808,
      "Radical": "龜",
      "HSK": 8,
      "FrequencyRank": 2392,
      "StrokeCount": 7
    }
  },
  "鲛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "shark"
    },
    "Keys": {
      "Hanzi": 5771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5771,
      "Radical": "魚",
      "FrequencyRank": 5194,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "廑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "a hut; careful"
    },
    "Keys": {
      "Hanzi": 7714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7714,
      "Radical": "广",
      "FrequencyRank": 6762,
      "RadicalIndex": "53.11",
      "StrokeCount": 14
    }
  },
  "直觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíjué",
      "PrimaryDefinition": "intuition"
    },
    "Keys": {
      "Word": 10762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "建立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànlì",
      "PrimaryDefinition": "establish, set up, found"
    },
    "Keys": {
      "Word": 1602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "寐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèi",
      "PrimaryDefinition": "sleep; be asleep"
    },
    "Keys": {
      "Hanzi": 5302
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5302,
      "Radical": "宀",
      "FrequencyRank": 3825,
      "RadicalIndex": "40.9",
      "StrokeCount": 12
    }
  },
  "乡村": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngcūn",
      "PrimaryDefinition": "country, countryside, campong, rural, rural area, village, Kampong, hamlet"
    },
    "Keys": {
      "Word": 4039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "按说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànshuō",
      "PrimaryDefinition": "in the ordinary course of events, ordinarily, normally"
    },
    "Keys": {
      "Word": 5448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhá",
      "PrimaryDefinition": "sickle for cutting grass or hay"
    },
    "Keys": {
      "Hanzi": 4871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4871,
      "Radical": "金",
      "FrequencyRank": 4496,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "掘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "dig, excavate; excavate cave"
    },
    "Keys": {
      "Hanzi": 2190
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2190,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2212,
      "StrokeCount": 11
    }
  },
  "绳子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shéngzi",
      "PrimaryDefinition": "cord, string, rope, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 9068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "权威": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánwēi",
      "PrimaryDefinition": "authoritativeness, sanction, pontiff, prince, pundit, a person of authority, authority, princedom, muscle, power, pope"
    },
    "Keys": {
      "Word": 8798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "边缘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānyuán",
      "PrimaryDefinition": "margin, brink, periphery, bead, brim, borderline, skirt, lip, fringe, delimitation, flange, threshold, boundary line, perimeter, rand, outer boundary, hem, line, border, verge, marginality, marge, skirting, edging, edge, corner, rim"
    },
    "Keys": {
      "Word": 4326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "坚信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānxìn",
      "PrimaryDefinition": "to believe firmly, without any doubt"
    },
    "Keys": {
      "Word": 7381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "只有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐyǒu",
      "PrimaryDefinition": "can only, have no choice but, to be forced to"
    },
    "Keys": {
      "Word": 2167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蔓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "creeping plants, tendrils, vines"
    },
    "Keys": {
      "Hanzi": 3069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.11",
      "GeneralStandard": 3069,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2829,
      "StrokeCount": 14
    }
  },
  "朝三暮四": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāosān-mùsì",
      "PrimaryDefinition": "lit. say three in the morning but four in the evening (idiom), to change sth that is already settled upon, indecisive, to blow hot and cold"
    },
    "Keys": {
      "Word": 10668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胶囊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāonáng",
      "PrimaryDefinition": "capsule (pharm.), caplet"
    },
    "Keys": {
      "Word": 7469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "便宜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànyí",
      "PrimaryDefinition": "cheap"
    },
    "Keys": {
      "Word": 907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "凶狠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōnghěn",
      "PrimaryDefinition": "cruel, vicious, fierce and malicious, vengeful"
    },
    "Keys": {
      "Word": 10069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "astatine"
    },
    "Keys": {
      "Hanzi": 4474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4474,
      "Radical": "石",
      "FrequencyRank": 5772,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "犋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5198,
      "Radical": "牛",
      "FrequencyRank": 7317,
      "RadicalIndex": "93.8",
      "StrokeCount": 12
    }
  },
  "恐龙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒnglóng",
      "PrimaryDefinition": "carnosaur, tyrannosaurus, Titanosaurus, dinosaur, dinosaurian"
    },
    "Keys": {
      "Word": 7829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "霜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāng",
      "PrimaryDefinition": "frost; crystallized; candied"
    },
    "Keys": {
      "Hanzi": 3399,
      "Word": 9254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.9",
      "Grade": 7,
      "GeneralStandard": 3399,
      "Radical": "雨",
      "HSK": 8,
      "FrequencyRank": 2631,
      "StrokeCount": 17
    }
  },
  "谕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "proclaim, instruct; edict"
    },
    "Keys": {
      "Hanzi": 5004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5004,
      "Radical": "言",
      "FrequencyRank": 2576,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "丐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "beggar; beg; give"
    },
    "Keys": {
      "Hanzi": 84
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.3",
      "GeneralStandard": 84,
      "Radical": "一",
      "HSK": 8,
      "FrequencyRank": 2606,
      "StrokeCount": 4
    }
  },
  "螋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "spider millipede"
    },
    "Keys": {
      "Hanzi": 5925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5925,
      "Radical": "虫",
      "FrequencyRank": 6371,
      "RadicalIndex": "142.1",
      "StrokeCount": 15
    }
  },
  "弶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "a snare"
    },
    "Keys": {
      "Hanzi": 7259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7259,
      "Radical": "弓",
      "RadicalIndex": "57.8",
      "StrokeCount": 11
    }
  },
  "设定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèdìng",
      "PrimaryDefinition": "frame, time"
    },
    "Keys": {
      "Word": 9005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "制作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìzuò",
      "PrimaryDefinition": "make, manufacture"
    },
    "Keys": {
      "Word": 2177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "唵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎn",
      "PrimaryDefinition": "used in Buddhist texts to transliterate non-Chinese sounds; to eat with the hand"
    },
    "Keys": {
      "Hanzi": 4823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4823,
      "Radical": "口",
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "劣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "bad, inferior; slightly"
    },
    "Keys": {
      "Hanzi": 416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.4",
      "GeneralStandard": 416,
      "Radical": "力",
      "HSK": 8,
      "FrequencyRank": 1902,
      "StrokeCount": 6
    }
  },
  "冈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "ridge or crest of hill"
    },
    "Keys": {
      "Hanzi": 115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "13.2",
      "GeneralStandard": 115,
      "Radical": "冂",
      "HSK": 9,
      "FrequencyRank": 2047,
      "StrokeCount": 4
    }
  },
  "粿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒ",
      "PrimaryDefinition": "rice cake"
    },
    "Keys": {
      "Hanzi": 5792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5792,
      "Radical": "米",
      "FrequencyRank": 9770,
      "RadicalIndex": "119.8",
      "StrokeCount": 14
    }
  },
  "捐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": "contribute; give up, renounce"
    },
    "Keys": {
      "Hanzi": 1797,
      "Word": 4765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 6,
      "GeneralStandard": 1797,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2235,
      "StrokeCount": 10
    }
  },
  "今日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnrì",
      "PrimaryDefinition": "today"
    },
    "Keys": {
      "Word": 3621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "衾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīn",
      "PrimaryDefinition": "coverlet, quilt"
    },
    "Keys": {
      "Hanzi": 4588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4588,
      "Radical": "衣",
      "FrequencyRank": 5103,
      "RadicalIndex": "145.4",
      "StrokeCount": 10
    }
  },
  "实话实说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíhuà-shíshuō",
      "PrimaryDefinition": "to tell the truth, to tell it as it is"
    },
    "Keys": {
      "Word": 9115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "西": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "west"
    },
    "Keys": {
      "Hanzi": 384,
      "Radical": 146,
      "Word": 381
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "146",
      "Grade": 1,
      "GeneralStandard": 384,
      "Radical": "襾",
      "HSK": 1,
      "FrequencyRank": 167,
      "StrokeCount": 6
    }
  },
  "描": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáo",
      "PrimaryDefinition": "copy, trace, sketch; depict"
    },
    "Keys": {
      "Hanzi": 2163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "GeneralStandard": 2163,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1246,
      "StrokeCount": 11
    }
  },
  "传真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánzhēn",
      "PrimaryDefinition": "portraiture, facsimile, fax"
    },
    "Keys": {
      "Word": 3353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "搭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dā",
      "PrimaryDefinition": "join together, attach to; add to"
    },
    "Keys": {
      "Hanzi": 2511,
      "Word": 4462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 6,
      "GeneralStandard": 2511,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1842,
      "StrokeCount": 12
    }
  },
  "天经地义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānjīng-dìyì",
      "PrimaryDefinition": "lit. heaven's law and earth's principle (idiom), fig. right and proper, right and unalterable, a matter of course"
    },
    "Keys": {
      "Word": 9478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饱和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎohé",
      "PrimaryDefinition": "saturation"
    },
    "Keys": {
      "Word": 5516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "土壤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔrǎng",
      "PrimaryDefinition": "soil"
    },
    "Keys": {
      "Word": 9586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "装饰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngshì",
      "PrimaryDefinition": "ornament"
    },
    "Keys": {
      "Word": 4267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "预定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùdìng",
      "PrimaryDefinition": "predetermination, predetermine, forespeak, preordain, engage, budget, reserve, phase, slate, fix in advance, predestine, state, preconcert, prearrange, take, schedule, destine, bespeak, foreordain, predestinate"
    },
    "Keys": {
      "Word": 10511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "专用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānyòng",
      "PrimaryDefinition": "use for special purpose"
    },
    "Keys": {
      "Word": 5383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "沙龙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shālóng",
      "PrimaryDefinition": "salon (loanword)"
    },
    "Keys": {
      "Word": 8935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泛滥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fànlàn",
      "PrimaryDefinition": "to be in flood, to overflow (the banks), to inundate, to spread unchecked"
    },
    "Keys": {
      "Word": 6481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嬉笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīxiào",
      "PrimaryDefinition": "to be laughing and playing, to giggle"
    },
    "Keys": {
      "Word": 9828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo",
      "PrimaryDefinition": "bright, luminous; illustrious"
    },
    "Keys": {
      "Hanzi": 1491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1491,
      "Radical": "日",
      "FrequencyRank": 2190,
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "鿏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mài",
      "PrimaryDefinition": "meitnerium"
    },
    "Keys": {
      "Hanzi": 7373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7373,
      "Radical": "",
      "RadicalIndex": ""
    }
  },
  "旦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "dawn; morning; day"
    },
    "Keys": {
      "Hanzi": 235
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.1",
      "GeneralStandard": 235,
      "Radical": "日",
      "HSK": 5,
      "FrequencyRank": 1300,
      "StrokeCount": 5
    }
  },
  "房间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángjiān",
      "PrimaryDefinition": "apartment, aspect, houseroom, room, chamber, bedroom"
    },
    "Keys": {
      "Word": 100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "傻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎ",
      "PrimaryDefinition": "foolish, silly, stupid; an imbecile"
    },
    "Keys": {
      "Hanzi": 2960,
      "Word": 3861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.11",
      "Grade": 5,
      "GeneralStandard": 2960,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1989,
      "StrokeCount": 13
    }
  },
  "调研": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàoyán",
      "PrimaryDefinition": "to investigate and research, research, investigation"
    },
    "Keys": {
      "Word": 4510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "收支": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuzhī",
      "PrimaryDefinition": "cash flow, financial balance, income and expenditure"
    },
    "Keys": {
      "Word": 9171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "a leather knee-pad worn during sacrifices"
    },
    "Keys": {
      "Hanzi": 6766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6766,
      "Radical": "韋",
      "FrequencyRank": 8714,
      "RadicalIndex": "178.5",
      "StrokeCount": 9
    }
  },
  "力所能及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìsuǒnéngjí",
      "PrimaryDefinition": "as far as one's capabilities extend (idiom), to the best of one's ability, within one's powers"
    },
    "Keys": {
      "Word": 7993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7388,
      "Radical": "竹",
      "FrequencyRank": 7303,
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "行列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángliè",
      "PrimaryDefinition": "parade, procession, ranks, waiting line, cortege, promenade, row, single file, Indian file, column, queue, lineup, file, rank"
    },
    "Keys": {
      "Word": 6992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "墨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "ink; writing"
    },
    "Keys": {
      "Hanzi": 3255,
      "Word": 8333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.12",
      "Grade": 7,
      "GeneralStandard": 3255,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 1493,
      "StrokeCount": 15
    }
  },
  "衰减": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāijiǎn",
      "PrimaryDefinition": "to weaken, to attenuate"
    },
    "Keys": {
      "Word": 9241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冷笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngxiào",
      "PrimaryDefinition": "to sneer, to laugh grimly, grin of dissatisfaction (bitterness, helplessness, indignation etc), bitter, grim, sarcastic or angry smile"
    },
    "Keys": {
      "Word": 7972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "swell, inflate, expand"
    },
    "Keys": {
      "Hanzi": 1184,
      "Word": 10658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 7,
      "GeneralStandard": 1184,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 2083,
      "StrokeCount": 8
    }
  },
  "东部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngbù",
      "PrimaryDefinition": "orient, East, eastward, east"
    },
    "Keys": {
      "Word": 1414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "扫兴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎo xìng",
      "PrimaryDefinition": "to have one's spirits dampened, to feel disappointed"
    },
    "Keys": {
      "Word": 8929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiyǔ",
      "PrimaryDefinition": "foreign language"
    },
    "Keys": {
      "Word": 373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "赃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāng",
      "PrimaryDefinition": "booty, loot, stolen goods; bribe"
    },
    "Keys": {
      "Hanzi": 1927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "GeneralStandard": 1927,
      "Radical": "貝",
      "HSK": 9,
      "FrequencyRank": 3296,
      "StrokeCount": 10
    }
  },
  "高明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāomíng",
      "PrimaryDefinition": "brilliant, wise"
    },
    "Keys": {
      "Word": 6702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "quiet, still, motionless; gentle"
    },
    "Keys": {
      "Hanzi": 3049,
      "Word": 1646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "174.6",
      "Grade": 3,
      "GeneralStandard": 3049,
      "Radical": "青",
      "HSK": 2,
      "FrequencyRank": 722,
      "StrokeCount": 14
    }
  },
  "字眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìyǎn",
      "PrimaryDefinition": "wording"
    },
    "Keys": {
      "Word": 10977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔyì",
      "PrimaryDefinition": "tenet, principle, hang, system, ticket, creed, philosophy, school of thought, evangel, ism, gospel, masonry, maxim, doctrine, -ism"
    },
    "Keys": {
      "Word": 10859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咽喉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yānhóu",
      "PrimaryDefinition": "throat"
    },
    "Keys": {
      "Word": 10165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùbèi",
      "PrimaryDefinition": "get ready, dight, set, ready, busk, preparation, store, set up, prepare, line, gear up, provide_for, provide, equip, outfit"
    },
    "Keys": {
      "Word": 4147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "釆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "distinguish"
    },
    "Keys": {
      "Radical": 165
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "移动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yídòng",
      "PrimaryDefinition": "move, shift"
    },
    "Keys": {
      "Word": 3090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "龙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "dragon"
    },
    "Keys": {
      "Hanzi": 221,
      "Radical": 212,
      "Word": 1717
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "212",
      "Grade": 3,
      "GeneralStandard": 221,
      "Radical": "龍",
      "HSK": 3,
      "FrequencyRank": 696,
      "StrokeCount": 5
    }
  },
  "网球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngqiú",
      "PrimaryDefinition": "lawn tennis, tennis, follow-through, tennis ball"
    },
    "Keys": {
      "Word": 1056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "摇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "wag, swing, wave; shake; scull"
    },
    "Keys": {
      "Hanzi": 2841,
      "Word": 3079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 4,
      "GeneralStandard": 2841,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 940,
      "StrokeCount": 13
    }
  },
  "坥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6651
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6651,
      "Radical": "土",
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "顾问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùwèn",
      "PrimaryDefinition": "counsellor, advisor, councillor, friend, consultant, counsellorship, counselorship, adviser, counselor"
    },
    "Keys": {
      "Word": 3513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "萆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "the castor-oil plant also used for other plants"
    },
    "Keys": {
      "Hanzi": 7127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7127,
      "Radical": "艸",
      "FrequencyRank": 6375,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "底蕴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐyùn",
      "PrimaryDefinition": "inside information, concrete details"
    },
    "Keys": {
      "Word": 6245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "充满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngmǎn",
      "PrimaryDefinition": "swarm, be brimming, inhabit, be brimming with, teem, crawl, fill, lard, pullulate with, brim, be permeated with, bathe, be filled with, permeated with, abound in, implement, exuberate, fill up, flow, abound, burst, perfuse, foam, bristle, crackle, inform, pervade, be imbued with, simmer, glut, teem in, permeate, congest, be full of, reek, charge, suffuse, pullulate, overfill, swell"
    },
    "Keys": {
      "Word": 1359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "支": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "branch"
    },
    "Keys": {
      "Hanzi": 89,
      "Radical": 65,
      "Word": 3171
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "65",
      "Grade": 4,
      "GeneralStandard": 89,
      "Radical": "支",
      "HSK": 3,
      "FrequencyRank": 437,
      "StrokeCount": 4
    }
  },
  "坉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6562
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6562,
      "Radical": "土",
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "那时候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà shíhou",
      "PrimaryDefinition": "at that time"
    },
    "Keys": {
      "Word": 881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "核": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "seed, kernel, core, nut; atom"
    },
    "Keys": {
      "Hanzi": 1848,
      "Word": 7046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 7,
      "GeneralStandard": 1848,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 828,
      "StrokeCount": 10
    }
  },
  "暖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuǎn",
      "PrimaryDefinition": "warm, genial"
    },
    "Keys": {
      "Hanzi": 2906,
      "Word": 3752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.9",
      "Grade": 5,
      "GeneralStandard": 2906,
      "Radical": "日",
      "HSK": 3,
      "FrequencyRank": 1745,
      "StrokeCount": 13
    }
  },
  "滇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diān",
      "PrimaryDefinition": "Yunnan province"
    },
    "Keys": {
      "Hanzi": 3007
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3007,
      "Radical": "水",
      "FrequencyRank": 3534,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "瞄准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáo zhǔn",
      "PrimaryDefinition": "to take aim at, to target"
    },
    "Keys": {
      "Word": 8291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "give birth child; complaisant"
    },
    "Keys": {
      "Hanzi": 4692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4692,
      "Radical": "女",
      "FrequencyRank": 4144,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "好久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎojiǔ",
      "PrimaryDefinition": "quite a while"
    },
    "Keys": {
      "Word": 711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "罅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià",
      "PrimaryDefinition": "crack, fissure, split"
    },
    "Keys": {
      "Hanzi": 6253
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6253,
      "Radical": "缶",
      "FrequencyRank": 4673,
      "RadicalIndex": "121.11",
      "StrokeCount": 17
    }
  },
  "漠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "desert; aloof, indifferent, cool"
    },
    "Keys": {
      "Hanzi": 3006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "GeneralStandard": 3006,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1777,
      "StrokeCount": 13
    }
  },
  "蜴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "lizard"
    },
    "Keys": {
      "Hanzi": 5708
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5708,
      "Radical": "虫",
      "FrequencyRank": 4597,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "栉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "comb; comb out; weed out, elimininate"
    },
    "Keys": {
      "Hanzi": 4140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4140,
      "Radical": "木",
      "FrequencyRank": 4647,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "疵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cī",
      "PrimaryDefinition": "flaw, fault, defect; disease"
    },
    "Keys": {
      "Hanzi": 4940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4940,
      "Radical": "疒",
      "FrequencyRank": 3986,
      "RadicalIndex": "104.5",
      "StrokeCount": 11
    }
  },
  "揆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "prime minister; to guess, estimate"
    },
    "Keys": {
      "Hanzi": 5076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5076,
      "Radical": "手",
      "FrequencyRank": 4614,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "竟敢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng gǎn",
      "PrimaryDefinition": "to have the impertinence, to have the cheek to"
    },
    "Keys": {
      "Word": 7638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "sink, submerge; addicted to; surname"
    },
    "Keys": {
      "Hanzi": 870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 870,
      "Radical": "水",
      "FrequencyRank": 1681,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "质疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìyí",
      "PrimaryDefinition": "call in question, challenge"
    },
    "Keys": {
      "Word": 10792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": "raw meat; rank, strong-smelling"
    },
    "Keys": {
      "Hanzi": 2970,
      "Word": 10044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.9",
      "Grade": 7,
      "GeneralStandard": 2970,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 2747,
      "StrokeCount": 13
    }
  },
  "纣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "name of an emperor; saddle part"
    },
    "Keys": {
      "Hanzi": 3645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3645,
      "Radical": "糸",
      "FrequencyRank": 4329,
      "RadicalIndex": "120.3",
      "StrokeCount": 6
    }
  },
  "胸部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngbù",
      "PrimaryDefinition": "chest, breast, thorax"
    },
    "Keys": {
      "Word": 3046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "拟定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǐdìng",
      "PrimaryDefinition": "formulate, conjecture"
    },
    "Keys": {
      "Word": 8405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "wet, moist, humid, damp; illness"
    },
    "Keys": {
      "Hanzi": 2756,
      "Word": 2862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 4,
      "GeneralStandard": 2756,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1743,
      "StrokeCount": 12
    }
  },
  "颙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóng",
      "PrimaryDefinition": "solemn, large, grand, majestic"
    },
    "Keys": {
      "Hanzi": 5908
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5908,
      "Radical": "頁",
      "FrequencyRank": 6537,
      "RadicalIndex": "181.9",
      "StrokeCount": 15
    }
  },
  "崃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "mountain in Sichuan province"
    },
    "Keys": {
      "Hanzi": 4521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4521,
      "Radical": "山",
      "FrequencyRank": 5446,
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "嫌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "hate, detest; suspect; criticize"
    },
    "Keys": {
      "Hanzi": 3041,
      "Word": 5160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.1",
      "Grade": 6,
      "GeneralStandard": 3041,
      "Radical": "女",
      "HSK": 6,
      "FrequencyRank": 1826,
      "StrokeCount": 13
    }
  },
  "依据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījù",
      "PrimaryDefinition": "according to, on the basis of, judging by"
    },
    "Keys": {
      "Word": 4101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鞮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "leather shoes"
    },
    "Keys": {
      "Hanzi": 7986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7986,
      "Radical": "革",
      "FrequencyRank": 6826,
      "RadicalIndex": "177.9",
      "StrokeCount": 18
    }
  },
  "痱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "heat rash, prickly heat; ulcers"
    },
    "Keys": {
      "Hanzi": 5541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5541,
      "Radical": "疒",
      "FrequencyRank": 5724,
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "警官": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngguān",
      "PrimaryDefinition": "constable, police officer"
    },
    "Keys": {
      "Word": 7631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "wide, spacious, great, vast"
    },
    "Keys": {
      "Hanzi": 879
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.4",
      "GeneralStandard": 879,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 1798,
      "StrokeCount": 7
    }
  },
  "镋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7787,
      "Radical": "金",
      "RadicalIndex": "167.2",
      "StrokeCount": 15
    }
  },
  "期限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīxiàn",
      "PrimaryDefinition": "time limit, allowed time, deadline, term"
    },
    "Keys": {
      "Word": 2792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "好家伙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎojiāhuo",
      "PrimaryDefinition": "my God!, oh boy!, man!"
    },
    "Keys": {
      "Word": 7007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雇主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùzhǔ",
      "PrimaryDefinition": "gaffer, beanfeast, master, governor, boss, employer, hirer"
    },
    "Keys": {
      "Word": 6849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发起人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāqǐrén",
      "PrimaryDefinition": "proposer, initiator, founding member"
    },
    "Keys": {
      "Word": 6441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "构建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòujiàn",
      "PrimaryDefinition": "to construct (sth abstract)"
    },
    "Keys": {
      "Word": 4619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "看来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànlai",
      "PrimaryDefinition": "it looks as if, it appears, it seems, it look as if"
    },
    "Keys": {
      "Word": 2656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "嵎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "mountain recess; canyon"
    },
    "Keys": {
      "Hanzi": 5168
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5168,
      "Radical": "山",
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "坨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "a lump, heap"
    },
    "Keys": {
      "Hanzi": 3849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3849,
      "Radical": "土",
      "FrequencyRank": 5617,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "驱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "spur a horse on; expel, drive away"
    },
    "Keys": {
      "Hanzi": 930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.4",
      "GeneralStandard": 930,
      "Radical": "馬",
      "HSK": 7,
      "FrequencyRank": 1511,
      "StrokeCount": 7
    }
  },
  "墚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "mountain range"
    },
    "Keys": {
      "Hanzi": 7636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7636,
      "Radical": "土",
      "FrequencyRank": 8300,
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "预赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùsài",
      "PrimaryDefinition": "preliminary contest"
    },
    "Keys": {
      "Word": 10518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "送给": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sònggěi",
      "PrimaryDefinition": "present to, to give as a present, send to, give, send/present to, to present"
    },
    "Keys": {
      "Word": 999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "邓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèng",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.2",
      "GeneralStandard": 185,
      "Radical": "邑",
      "HSK": 9,
      "FrequencyRank": 1614,
      "StrokeCount": 4
    }
  },
  "惯性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guànxìng",
      "PrimaryDefinition": "inertia"
    },
    "Keys": {
      "Word": 6892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "rain"
    },
    "Keys": {
      "Hanzi": 1041,
      "Radical": 173,
      "Word": 446
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173",
      "Grade": 1,
      "GeneralStandard": 1041,
      "Radical": "雨",
      "HSK": 1,
      "FrequencyRank": 928,
      "StrokeCount": 8
    }
  },
  "局势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júshì",
      "PrimaryDefinition": "situation"
    },
    "Keys": {
      "Word": 7682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "匪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "bandits, robbers, gangsters"
    },
    "Keys": {
      "Hanzi": 1780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "22.8",
      "GeneralStandard": 1780,
      "Radical": "匚",
      "HSK": 9,
      "FrequencyRank": 2201,
      "StrokeCount": 10
    }
  },
  "玩意儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wányìr5",
      "PrimaryDefinition": "toy, plaything, thing, act, trick (in a performance, stage show, acrobatics etc), erhua variant of 玩意[wan2 yi4]"
    },
    "Keys": {
      "Word": 9652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "合乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héhū",
      "PrimaryDefinition": "conform with/to, accord, tally with, correspond to, accord with, conform_to, conform with, conform to, accord/tally with, to"
    },
    "Keys": {
      "Word": 7026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "数码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùmǎ",
      "PrimaryDefinition": "number, numerals, figures, digital, amount, numerical code"
    },
    "Keys": {
      "Word": 2891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "combine, unite, join; gather"
    },
    "Keys": {
      "Hanzi": 488,
      "Word": 1543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 3,
      "GeneralStandard": 488,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 171,
      "StrokeCount": 6
    }
  },
  "鲜明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānmíng",
      "PrimaryDefinition": "bright (of color), distinct, distinctive"
    },
    "Keys": {
      "Word": 3012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "创立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuànglì",
      "PrimaryDefinition": "found, originate"
    },
    "Keys": {
      "Word": 3357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "启迪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐdí",
      "PrimaryDefinition": "to edify, enlightenment"
    },
    "Keys": {
      "Word": 8610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "a despatch boat"
    },
    "Keys": {
      "Hanzi": 7550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7550,
      "Radical": "舟",
      "RadicalIndex": "137.7",
      "StrokeCount": 13
    }
  },
  "南北": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánběi",
      "PrimaryDefinition": "north and south, from east to west, north-south"
    },
    "Keys": {
      "Word": 3738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "颋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": "straight"
    },
    "Keys": {
      "Hanzi": 7385
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7385,
      "Radical": "頁",
      "FrequencyRank": 7998,
      "RadicalIndex": "181.6",
      "StrokeCount": 12
    }
  },
  "框": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāng",
      "PrimaryDefinition": "frame; framework; door frame"
    },
    "Keys": {
      "Hanzi": 1833,
      "Word": 7892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 7,
      "GeneralStandard": 1833,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2144,
      "StrokeCount": 10
    }
  },
  "大米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàmǐ",
      "PrimaryDefinition": "(husked) rice"
    },
    "Keys": {
      "Word": 4475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "灌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "pour; water; irrigate, flood"
    },
    "Keys": {
      "Hanzi": 3486,
      "Word": 6893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.18",
      "Grade": 7,
      "GeneralStandard": 3486,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2077,
      "StrokeCount": 20
    }
  },
  "糟糕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāogāo",
      "PrimaryDefinition": "terrible, Too bad!, What a mess!"
    },
    "Keys": {
      "Word": 4177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疲倦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píjuàn",
      "PrimaryDefinition": "to tire, tired"
    },
    "Keys": {
      "Word": 8509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艰苦奋斗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānkǔ-fèndòu",
      "PrimaryDefinition": "to struggle arduously"
    },
    "Keys": {
      "Word": 7386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "所以": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒyǐ",
      "PrimaryDefinition": "conj.: so, therefore, as a result"
    },
    "Keys": {
      "Word": 1002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "place, lay out; set aside"
    },
    "Keys": {
      "Hanzi": 2931,
      "Word": 10807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "122.8",
      "Grade": 7,
      "GeneralStandard": 2931,
      "Radical": "网",
      "HSK": 4,
      "FrequencyRank": 677,
      "StrokeCount": 13
    }
  },
  "学校": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéxiào",
      "PrimaryDefinition": "seminary, schoolhouse, educational institution, school, institution"
    },
    "Keys": {
      "Word": 418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "心安理得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnān-lǐdé",
      "PrimaryDefinition": "to have a clear conscience, to have no qualms about sth"
    },
    "Keys": {
      "Word": 10001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuénián",
      "PrimaryDefinition": "school/academic year"
    },
    "Keys": {
      "Word": 3050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "激素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīsù",
      "PrimaryDefinition": "hormone"
    },
    "Keys": {
      "Word": 7287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7652
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7652,
      "Radical": "木",
      "FrequencyRank": 6136,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "箔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "reed screen; frame for growing silkworms"
    },
    "Keys": {
      "Hanzi": 5750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5750,
      "Radical": "竹",
      "FrequencyRank": 4366,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luàn",
      "PrimaryDefinition": "confusion, state of chaos, revolt"
    },
    "Keys": {
      "Hanzi": 762,
      "Word": 1728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "5.6",
      "Grade": 3,
      "GeneralStandard": 762,
      "Radical": "乙",
      "HSK": 3,
      "FrequencyRank": 765,
      "StrokeCount": 7
    }
  },
  "希望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīwàng",
      "PrimaryDefinition": "hope, wish"
    },
    "Keys": {
      "Word": 2026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "罢休": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàxiū",
      "PrimaryDefinition": "to give up, to abandon (a goal etc), to let sth go, forget it, let the matter drop"
    },
    "Keys": {
      "Word": 5468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "求职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúzhí",
      "PrimaryDefinition": "seek for job"
    },
    "Keys": {
      "Word": 4952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "骝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "famous horse"
    },
    "Keys": {
      "Hanzi": 5614
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5614,
      "Radical": "馬",
      "FrequencyRank": 6427,
      "RadicalIndex": "187.1",
      "StrokeCount": 13
    }
  },
  "廉政": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánzhèng",
      "PrimaryDefinition": "honest or clean politics"
    },
    "Keys": {
      "Word": 8026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùpíng",
      "PrimaryDefinition": "dissatisfaction"
    },
    "Keys": {
      "Word": 5708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "益处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìchu",
      "PrimaryDefinition": "benefit"
    },
    "Keys": {
      "Word": 10377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "结果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē guǒ",
      "PrimaryDefinition": "result, outcome, consequence"
    },
    "Keys": {
      "Word": 7500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "塔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎ",
      "PrimaryDefinition": "tower, spire, tall building"
    },
    "Keys": {
      "Hanzi": 2510,
      "Word": 5061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.1",
      "Grade": 6,
      "GeneralStandard": 2510,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 1030,
      "StrokeCount": 12
    }
  },
  "锓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐn",
      "PrimaryDefinition": "carve"
    },
    "Keys": {
      "Hanzi": 7383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7383,
      "Radical": "金",
      "FrequencyRank": 6131,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "妈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mā",
      "PrimaryDefinition": "mother, mama"
    },
    "Keys": {
      "Hanzi": 583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "GeneralStandard": 583,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 750,
      "StrokeCount": 6
    }
  },
  "具体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùtǐ",
      "PrimaryDefinition": "concrete, specific, particular"
    },
    "Keys": {
      "Word": 1655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "伎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "talent, skill, ability"
    },
    "Keys": {
      "Hanzi": 3602
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3602,
      "Radical": "人",
      "FrequencyRank": 3332,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "轨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "track, rut, path"
    },
    "Keys": {
      "Hanzi": 404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.2",
      "GeneralStandard": 404,
      "Radical": "車",
      "HSK": 6,
      "FrequencyRank": 1883,
      "StrokeCount": 6
    }
  },
  "滚动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔndòng",
      "PrimaryDefinition": "to roll, (to do sth) in a loop, to scroll (computing), to progressively expand (economics), to rumble (of thunder)"
    },
    "Keys": {
      "Word": 6926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "追随": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīsuí",
      "PrimaryDefinition": "follow"
    },
    "Keys": {
      "Word": 10918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "固执": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùzhi",
      "PrimaryDefinition": "obstinate, stubborn"
    },
    "Keys": {
      "Word": 6839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngcǎi",
      "PrimaryDefinition": "luster, splendor, radiance, brilliance"
    },
    "Keys": {
      "Word": 6898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "财经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáijīng",
      "PrimaryDefinition": "finance and economics"
    },
    "Keys": {
      "Word": 5734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "觫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "to start, tremble with fear"
    },
    "Keys": {
      "Hanzi": 5774
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5774,
      "Radical": "角",
      "FrequencyRank": 6605,
      "RadicalIndex": "148.7",
      "StrokeCount": 14
    }
  },
  "兰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "orchid; elegant, graceful"
    },
    "Keys": {
      "Hanzi": 306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "12.3",
      "GeneralStandard": 306,
      "Radical": "八",
      "HSK": 9,
      "FrequencyRank": 642,
      "StrokeCount": 5
    }
  },
  "蝰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "vipera russelii siamensis"
    },
    "Keys": {
      "Hanzi": 5923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5923,
      "Radical": "虫",
      "FrequencyRank": 6430,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "痧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "cholera; colic"
    },
    "Keys": {
      "Hanzi": 5259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5259,
      "Radical": "疒",
      "FrequencyRank": 5801,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "gold; metals in general; money"
    },
    "Keys": {
      "Hanzi": 1166,
      "Word": 1625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167",
      "Grade": 3,
      "GeneralStandard": 1166,
      "Radical": "金",
      "HSK": 3,
      "FrequencyRank": 260,
      "StrokeCount": 8
    }
  },
  "琶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pá",
      "PrimaryDefinition": "guitar-like instrument"
    },
    "Keys": {
      "Hanzi": 5044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5044,
      "Radical": "玉",
      "FrequencyRank": 3612,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "经贸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngmào",
      "PrimaryDefinition": "economy and trade"
    },
    "Keys": {
      "Word": 7594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "wrap, pack, bundle; package"
    },
    "Keys": {
      "Hanzi": 298,
      "Word": 15
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "20.3",
      "Grade": 1,
      "GeneralStandard": 298,
      "Radical": "勹",
      "HSK": 1,
      "FrequencyRank": 454,
      "StrokeCount": 5
    }
  },
  "表达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎodá",
      "PrimaryDefinition": "express, convey, voice"
    },
    "Keys": {
      "Word": 1302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "姥姥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎolao",
      "PrimaryDefinition": "(coll.) mother's mother, maternal grandmother"
    },
    "Keys": {
      "Word": 7953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bei",
      "PrimaryDefinition": "final particle of assertion"
    },
    "Keys": {
      "Hanzi": 3708
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3708,
      "Radical": "口",
      "FrequencyRank": 3962,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "录像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùxiàng",
      "PrimaryDefinition": "to videotape, to videorecord, video recording, CL:盤|盘[pan2]"
    },
    "Keys": {
      "Word": 4842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "同行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngháng",
      "PrimaryDefinition": "person of the same profession, of the same trade, occupation or industry, to journey together"
    },
    "Keys": {
      "Word": 5097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "此处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐchù",
      "PrimaryDefinition": "this place, here (literary)"
    },
    "Keys": {
      "Word": 4452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "喃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nán",
      "PrimaryDefinition": "keep talking, chattering; mumble"
    },
    "Keys": {
      "Hanzi": 5130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5130,
      "Radical": "口",
      "FrequencyRank": 2233,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "饨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": "stuffed dumplings"
    },
    "Keys": {
      "Hanzi": 3750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3750,
      "Radical": "食",
      "FrequencyRank": 4332,
      "RadicalIndex": "184.4",
      "StrokeCount": 7
    }
  },
  "萍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "duckweed; wandering, traveling"
    },
    "Keys": {
      "Hanzi": 2211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2211,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2180,
      "StrokeCount": 11
    }
  },
  "殃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": "misfortune, disaster, calamity"
    },
    "Keys": {
      "Hanzi": 1461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "78.5",
      "GeneralStandard": 1461,
      "Radical": "歹",
      "HSK": 8,
      "FrequencyRank": 3562,
      "StrokeCount": 9
    }
  },
  "埋怨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mányuàn",
      "PrimaryDefinition": "to complain, to grumble (about), to reproach, to blame"
    },
    "Keys": {
      "Word": 8195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "flow"
    },
    "Keys": {
      "Hanzi": 7428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7428,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "高尚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoshàng",
      "PrimaryDefinition": "noble, lofty, refined, exquisite"
    },
    "Keys": {
      "Word": 2493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "大致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàzhì",
      "PrimaryDefinition": "roughly"
    },
    "Keys": {
      "Word": 3390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "讧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòng",
      "PrimaryDefinition": "confusion; internal strife"
    },
    "Keys": {
      "Hanzi": 3555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3555,
      "Radical": "言",
      "FrequencyRank": 5205,
      "RadicalIndex": "149.3",
      "StrokeCount": 5
    }
  },
  "板块": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎnkuài",
      "PrimaryDefinition": "block, slab, tectonic plate, continental plate"
    },
    "Keys": {
      "Word": 5491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "bake"
    },
    "Keys": {
      "Hanzi": 7721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7721,
      "Radical": "火",
      "RadicalIndex": "86.1",
      "StrokeCount": 14
    }
  },
  "暝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "dark, obscure"
    },
    "Keys": {
      "Hanzi": 5701
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5701,
      "Radical": "日",
      "FrequencyRank": 6019,
      "RadicalIndex": "72.1",
      "StrokeCount": 14
    }
  },
  "鳕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuě",
      "PrimaryDefinition": "codfish"
    },
    "Keys": {
      "Hanzi": 6405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6405,
      "Radical": "魚",
      "FrequencyRank": 5114,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "确立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèlì",
      "PrimaryDefinition": "radicate, entrench, establishment, build, establish, establish firmly"
    },
    "Keys": {
      "Word": 3830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "去处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùchù",
      "PrimaryDefinition": "place, destination"
    },
    "Keys": {
      "Word": 8794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "palace"
    },
    "Keys": {
      "Hanzi": 1698,
      "Word": 4612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.6",
      "Grade": 6,
      "GeneralStandard": 1698,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 982,
      "StrokeCount": 9
    }
  },
  "脞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuǒ",
      "PrimaryDefinition": "minced meat; trifles"
    },
    "Keys": {
      "Hanzi": 7211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7211,
      "Radical": "肉",
      "FrequencyRank": 6735,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "神秘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénmì",
      "PrimaryDefinition": "mysterious, mystical"
    },
    "Keys": {
      "Word": 2853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "菊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jú",
      "PrimaryDefinition": "chrysanthemum"
    },
    "Keys": {
      "Hanzi": 2209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2209,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2509,
      "StrokeCount": 11
    }
  },
  "鼬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "weasel, mustela itatis"
    },
    "Keys": {
      "Hanzi": 6350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6350,
      "Radical": "鼠",
      "FrequencyRank": 5410,
      "RadicalIndex": "208.5",
      "StrokeCount": 18
    }
  },
  "宁静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níngjìng",
      "PrimaryDefinition": "peaceful, tranquil, quiet"
    },
    "Keys": {
      "Word": 2764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "铭记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngjì",
      "PrimaryDefinition": "to engrave in one's memory"
    },
    "Keys": {
      "Word": 8312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngyǔ",
      "PrimaryDefinition": "wind and rain, the elements, trials and hardships"
    },
    "Keys": {
      "Word": 6581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "book cover; satchel or bag"
    },
    "Keys": {
      "Hanzi": 3927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3927,
      "Radical": "巾",
      "FrequencyRank": 5374,
      "RadicalIndex": "50.5",
      "StrokeCount": 8
    }
  },
  "踢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tī",
      "PrimaryDefinition": "kick"
    },
    "Keys": {
      "Hanzi": 3241,
      "Word": 5077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.8",
      "Grade": 6,
      "GeneralStandard": 3241,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 2444,
      "StrokeCount": 15
    }
  },
  "馑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "time of famine or crop failure"
    },
    "Keys": {
      "Hanzi": 5777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5777,
      "Radical": "食",
      "FrequencyRank": 4473,
      "RadicalIndex": "184.11",
      "StrokeCount": 14
    }
  },
  "折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "break off, snap; bend"
    },
    "Keys": {
      "Hanzi": 632,
      "Word": 3159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 4,
      "GeneralStandard": 632,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1131,
      "StrokeCount": 7
    }
  },
  "协议书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiéyìshū",
      "PrimaryDefinition": "contract, protocol"
    },
    "Keys": {
      "Word": 4058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "渊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": "surge up, bubble up, gush forth"
    },
    "Keys": {
      "Hanzi": 2423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2423,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2192,
      "StrokeCount": 11
    }
  },
  "荏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěn",
      "PrimaryDefinition": "beans; soft, pliable; herb"
    },
    "Keys": {
      "Hanzi": 4120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4120,
      "Radical": "艸",
      "FrequencyRank": 5660,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "陪葬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péizàng",
      "PrimaryDefinition": "to be buried with or next to dead person (of deceased's partner, or of funerary objects)"
    },
    "Keys": {
      "Word": 8482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谜语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míyǔ",
      "PrimaryDefinition": "riddle, conundrum, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 8265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "观察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānchá",
      "PrimaryDefinition": "observe, survey, inspect"
    },
    "Keys": {
      "Word": 1518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "红包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngbāo",
      "PrimaryDefinition": "money wrapped in red as a gift, bonus payment, kickback, bribe"
    },
    "Keys": {
      "Word": 2545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "falcon; Accipiter species (various)"
    },
    "Keys": {
      "Hanzi": 3447,
      "Word": 10413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "196.13",
      "Grade": 7,
      "GeneralStandard": 3447,
      "Radical": "鳥",
      "HSK": 7,
      "FrequencyRank": 1927,
      "StrokeCount": 18
    }
  },
  "风沙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngshā",
      "PrimaryDefinition": "sand blown by wind, sandstorm"
    },
    "Keys": {
      "Word": 6578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "只不过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐbuguò",
      "PrimaryDefinition": "only, merely, nothing but, no more than, it's just that ..."
    },
    "Keys": {
      "Word": 4226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "fiery, violent, vehement, ardent"
    },
    "Keys": {
      "Hanzi": 1871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "GeneralStandard": 1871,
      "Radical": "火",
      "HSK": 3,
      "FrequencyRank": 802,
      "StrokeCount": 10
    }
  },
  "打通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ tōng",
      "PrimaryDefinition": "to open access, to establish contact, to remove a block, to put through (a phone connection)"
    },
    "Keys": {
      "Word": 6107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7801
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7801,
      "Radical": "魚",
      "FrequencyRank": 7584,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "对弈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìyì",
      "PrimaryDefinition": "to play go, chess etc"
    },
    "Keys": {
      "Word": 6382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预兆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùzhào",
      "PrimaryDefinition": "omen, sign (of sth yet to occur), prior indication, to foreshadow"
    },
    "Keys": {
      "Word": 10527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "assist, help, lead by hand"
    },
    "Keys": {
      "Hanzi": 4400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4400,
      "Radical": "手",
      "FrequencyRank": 5220,
      "RadicalIndex": "64.6",
      "StrokeCount": 10
    }
  },
  "陀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "steep bank, rough terrain"
    },
    "Keys": {
      "Hanzi": 3803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3803,
      "Radical": "阜",
      "FrequencyRank": 2143,
      "RadicalIndex": "170.5",
      "StrokeCount": 7
    }
  },
  "试图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìtú",
      "PrimaryDefinition": "attempt, pretend, set about, try, seek, undertake"
    },
    "Keys": {
      "Word": 3909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "哭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": "weep, cry, wail"
    },
    "Keys": {
      "Hanzi": 1907,
      "Word": 826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "Grade": 2,
      "GeneralStandard": 1907,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 1210,
      "StrokeCount": 10
    }
  },
  "新潮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīncháo",
      "PrimaryDefinition": "modern, fashionable"
    },
    "Keys": {
      "Word": 10025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "漱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "gargle, rinse; wash, scour"
    },
    "Keys": {
      "Hanzi": 3162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3162,
      "Radical": "水",
      "FrequencyRank": 3402,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "珉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": "stone resembling jade"
    },
    "Keys": {
      "Hanzi": 4091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4091,
      "Radical": "玉",
      "FrequencyRank": 6400,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "木材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùcái",
      "PrimaryDefinition": "patina, heartwood, wood, stuff, lumber, cabinet wood, timber"
    },
    "Keys": {
      "Word": 8343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "tadpole"
    },
    "Keys": {
      "Hanzi": 3249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3249,
      "Radical": "虫",
      "FrequencyRank": 4114,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "是不是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìbùshì",
      "PrimaryDefinition": "is or isn't, yes or no, whether or not"
    },
    "Keys": {
      "Word": 333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "雇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "employ, to hire"
    },
    "Keys": {
      "Hanzi": 2789,
      "Word": 6845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "172.4",
      "Grade": 7,
      "GeneralStandard": 2789,
      "Radical": "隹",
      "HSK": 7,
      "FrequencyRank": 1817,
      "StrokeCount": 12
    }
  },
  "钹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "cymbals"
    },
    "Keys": {
      "Hanzi": 4531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4531,
      "Radical": "金",
      "FrequencyRank": 5312,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "双打": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngdǎ",
      "PrimaryDefinition": "foursome, doubles"
    },
    "Keys": {
      "Word": 5041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "尖端": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānduān",
      "PrimaryDefinition": "sharp pointed end, the tip, the cusp, tip-top, most advanced and sophisticated, highest peak, the best"
    },
    "Keys": {
      "Word": 7373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāofèi",
      "PrimaryDefinition": "Pay a fee"
    },
    "Keys": {
      "Word": 1606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "硎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "whetstone"
    },
    "Keys": {
      "Hanzi": 4789
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4789,
      "Radical": "石",
      "FrequencyRank": 4849,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "磕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "hit; collide, knock into; sound"
    },
    "Keys": {
      "Hanzi": 3225,
      "Word": 7790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.1",
      "Grade": 7,
      "GeneralStandard": 3225,
      "Radical": "石",
      "HSK": 7,
      "FrequencyRank": 2991,
      "StrokeCount": 15
    }
  },
  "蓇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "follicles of plants"
    },
    "Keys": {
      "Hanzi": 7311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7311,
      "Radical": "艸",
      "FrequencyRank": 7548,
      "RadicalIndex": "140.1",
      "StrokeCount": 12
    }
  },
  "纳税": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà shuì",
      "PrimaryDefinition": "to pay taxes"
    },
    "Keys": {
      "Word": 8365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǐ",
      "PrimaryDefinition": "to step; a stride equal to half a pace"
    },
    "Keys": {
      "Hanzi": 5452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5452,
      "Radical": "足",
      "FrequencyRank": 6120,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "昴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎo",
      "PrimaryDefinition": "one of the 28 constellations"
    },
    "Keys": {
      "Hanzi": 4199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4199,
      "Radical": "日",
      "FrequencyRank": 5682,
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "common, normal, frequent, regular"
    },
    "Keys": {
      "Hanzi": 2257,
      "Word": 39
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.8",
      "Grade": 1,
      "GeneralStandard": 2257,
      "Radical": "巾",
      "HSK": 1,
      "FrequencyRank": 187,
      "StrokeCount": 11
    }
  },
  "沟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "ditch, drain, narrow waterway"
    },
    "Keys": {
      "Hanzi": 868,
      "Word": 3507
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "Grade": 5,
      "GeneralStandard": 868,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1610,
      "StrokeCount": 7
    }
  },
  "部件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjiàn",
      "PrimaryDefinition": "part, component"
    },
    "Keys": {
      "Word": 5729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngtōng",
      "PrimaryDefinition": "all, entire, complete"
    },
    "Keys": {
      "Word": 9528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "blaze"
    },
    "Keys": {
      "Hanzi": 7901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7901,
      "Radical": "火",
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "背叛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèipàn",
      "PrimaryDefinition": "to betray"
    },
    "Keys": {
      "Word": 5559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngbǐ",
      "PrimaryDefinition": "liken, equate, compare, comparison"
    },
    "Keys": {
      "Word": 2039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不慎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùshèn",
      "PrimaryDefinition": "incautious, inattentive"
    },
    "Keys": {
      "Word": 5674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鄄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4785,
      "Radical": "邑",
      "FrequencyRank": 5591,
      "RadicalIndex": "163.9",
      "StrokeCount": 11
    }
  },
  "桲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "po",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7137,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "烹调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēngtiáo",
      "PrimaryDefinition": "to cook, cooking"
    },
    "Keys": {
      "Word": 8492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "申领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnlǐng",
      "PrimaryDefinition": "to apply (for license, visa etc)"
    },
    "Keys": {
      "Word": 9015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逐步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhúbù",
      "PrimaryDefinition": "step by step, progressively"
    },
    "Keys": {
      "Word": 3187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "好奇心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàoqíxīn",
      "PrimaryDefinition": "interest in sth, curiosity, inquisitive"
    },
    "Keys": {
      "Word": 7017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráo",
      "PrimaryDefinition": "fuel, firewood; stubble; Wikstroemia japonica"
    },
    "Keys": {
      "Hanzi": 6786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6786,
      "Radical": "艸",
      "FrequencyRank": 5272,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "屏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "folding screen, shield"
    },
    "Keys": {
      "Hanzi": 1724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.6",
      "GeneralStandard": 1724,
      "Radical": "尸",
      "HSK": 6,
      "FrequencyRank": 1891,
      "StrokeCount": 9
    }
  },
  "倒塌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎotā",
      "PrimaryDefinition": "to collapse (of building), to topple over"
    },
    "Keys": {
      "Word": 6196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "请坐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng zuò",
      "PrimaryDefinition": "Sitting"
    },
    "Keys": {
      "Word": 297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "拿手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náshǒu",
      "PrimaryDefinition": "expert in, good at"
    },
    "Keys": {
      "Word": 8359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "要点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàodiǎn",
      "PrimaryDefinition": "nub, head, essentials, highlight, core, effect, message, issue, detail, key strongpoint, kernel, hinge, upshot, best, capitulation, item, essential, point, salient point, substance, outline, main points, crux, essence, burden, knot, salience, pith, pivot, sum, marrow, gist"
    },
    "Keys": {
      "Word": 10245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生活费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnghuófèi",
      "PrimaryDefinition": "cost of living, living expenses, alimony"
    },
    "Keys": {
      "Word": 5010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "降价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng jià",
      "PrimaryDefinition": "lower prices"
    },
    "Keys": {
      "Word": 2614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "等待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děngdài",
      "PrimaryDefinition": "wait for, await"
    },
    "Keys": {
      "Word": 1403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不耐烦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù nàifán",
      "PrimaryDefinition": "impatience, impatient"
    },
    "Keys": {
      "Word": 3271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "双方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngfāng",
      "PrimaryDefinition": "both, the two parties, two parties, both sides"
    },
    "Keys": {
      "Word": 1939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "勾结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōujié",
      "PrimaryDefinition": "to collude with, to collaborate with, to gang up with"
    },
    "Keys": {
      "Word": 6810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "增多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngduō",
      "PrimaryDefinition": "increase (in number/quantity)"
    },
    "Keys": {
      "Word": 4182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "补课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔ kè",
      "PrimaryDefinition": "to make up missed lesson, to reschedule a class"
    },
    "Keys": {
      "Word": 4351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "竘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7044,
      "Radical": "立",
      "RadicalIndex": "117.5",
      "StrokeCount": 10
    }
  },
  "汊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chà",
      "PrimaryDefinition": "a branching stream"
    },
    "Keys": {
      "Hanzi": 3628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3628,
      "Radical": "水",
      "FrequencyRank": 5283,
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "享有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngyǒu",
      "PrimaryDefinition": "enjoy (right/etc.)"
    },
    "Keys": {
      "Word": 9941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "excrement, shit, dung"
    },
    "Keys": {
      "Hanzi": 1725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1725,
      "Radical": "尸",
      "FrequencyRank": 3336,
      "RadicalIndex": "44.6",
      "StrokeCount": 9
    }
  },
  "手指": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuzhǐ",
      "PrimaryDefinition": "finger"
    },
    "Keys": {
      "Word": 1928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "文娱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wényú",
      "PrimaryDefinition": "cultural recreation, entertainment"
    },
    "Keys": {
      "Word": 5144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "锭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "spindle, slab, cake, tablet"
    },
    "Keys": {
      "Hanzi": 5491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5491,
      "Radical": "金",
      "FrequencyRank": 4199,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "加重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāzhòng",
      "PrimaryDefinition": "make heavier, punctuate, increase, make or become more serious, weight, become more serious, exacerbate, aggravate, exasperate, make more serious, become heavier, increase the weight of, make"
    },
    "Keys": {
      "Word": 7347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "butcher, slaughter, massacre"
    },
    "Keys": {
      "Hanzi": 2472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.9",
      "GeneralStandard": 2472,
      "Radical": "尸",
      "HSK": 7,
      "FrequencyRank": 2171,
      "StrokeCount": 11
    }
  },
  "伴随": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànsuí",
      "PrimaryDefinition": "accompany, follow"
    },
    "Keys": {
      "Word": 5502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mài",
      "PrimaryDefinition": "wheat"
    },
    "Keys": {
      "Radical": 199,
      "Hanzi": 601
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "199",
      "GeneralStandard": 601,
      "Radical": "麥",
      "HSK": 6,
      "FrequencyRank": 1171,
      "StrokeCount": 7
    }
  },
  "暴雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàoyǔ",
      "PrimaryDefinition": "torrential rain, rainstorm, CL:場|场[chang2],陣|阵[zhen4]"
    },
    "Keys": {
      "Word": 4308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "琀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "gems of pearls formerly put into the mouth of a corpse"
    },
    "Keys": {
      "Hanzi": 7104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7104,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "下调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàdiào",
      "PrimaryDefinition": "to demote, to pass down to a lower unit, to adjust downwards, to lower (prices, wages etc)"
    },
    "Keys": {
      "Word": 9870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "西方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīfāng",
      "PrimaryDefinition": "the Occident, West, New World, occidental, westward, west, the West, the west, Hesperian, occident, Western, sunset, western hemisphere, western"
    },
    "Keys": {
      "Word": 1072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "朝代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháodài",
      "PrimaryDefinition": "dynasty, reign (of a king)"
    },
    "Keys": {
      "Word": 5831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倾销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxiāo",
      "PrimaryDefinition": "to dump (goods, products)"
    },
    "Keys": {
      "Word": 8740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "强大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángdà",
      "PrimaryDefinition": "big and powerful, formidable"
    },
    "Keys": {
      "Word": 1812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "一边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībiān",
      "PrimaryDefinition": "at the same time, simultaneously;on the one/other hand"
    },
    "Keys": {
      "Word": 432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "直升机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíshēngjī",
      "PrimaryDefinition": "helicopter, CL:架[jia4]"
    },
    "Keys": {
      "Word": 5352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "歧视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíshì",
      "PrimaryDefinition": "treat with bias, despise"
    },
    "Keys": {
      "Word": 8598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà",
      "PrimaryDefinition": "jacket, overcoat, coat; gown"
    },
    "Keys": {
      "Hanzi": 3031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3031,
      "Radical": "衣",
      "FrequencyRank": 3197,
      "RadicalIndex": "145.8",
      "StrokeCount": 13
    }
  },
  "师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "teacher, master, specialist"
    },
    "Keys": {
      "Hanzi": 413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.3",
      "GeneralStandard": 413,
      "Radical": "巾",
      "HSK": 1,
      "FrequencyRank": 333,
      "StrokeCount": 6
    }
  },
  "慈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "kind, charitable, benevolent"
    },
    "Keys": {
      "Hanzi": 3002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "GeneralStandard": 3002,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1487,
      "StrokeCount": 13
    }
  },
  "衄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǜ",
      "PrimaryDefinition": "epistaxis, nosebleed; to be defeated"
    },
    "Keys": {
      "Hanzi": 4582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4582,
      "Radical": "血",
      "FrequencyRank": 5839,
      "RadicalIndex": "143.4",
      "StrokeCount": 10
    }
  },
  "桀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "chicken roost; ancient emperor"
    },
    "Keys": {
      "Hanzi": 4607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4607,
      "Radical": "木",
      "FrequencyRank": 4097,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "为止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéizhǐ",
      "PrimaryDefinition": "until, (used in combination with words like 到[dao4] or 至[zhi4] in constructs of the form 到...為止|到...为止)"
    },
    "Keys": {
      "Word": 3999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "稆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "wild grain"
    },
    "Keys": {
      "Hanzi": 7189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7189,
      "Radical": "禾",
      "FrequencyRank": 8371,
      "RadicalIndex": "115.6",
      "StrokeCount": 11
    }
  },
  "嘀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "backbite"
    },
    "Keys": {
      "Hanzi": 3111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3111,
      "Radical": "口",
      "FrequencyRank": 3299,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "培训": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péixùn",
      "PrimaryDefinition": "cultivate, train"
    },
    "Keys": {
      "Word": 2774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "放大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng dà",
      "PrimaryDefinition": "megascopic, blow_up, magnify, blow up, amplify, enhance, enlarge, enlargement"
    },
    "Keys": {
      "Word": 3453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "管教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnjiào",
      "PrimaryDefinition": "subject sb. to discipline, restrain and instruct"
    },
    "Keys": {
      "Word": 6882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "富": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "abundant, ample; rich, wealthy"
    },
    "Keys": {
      "Hanzi": 2781,
      "Word": 1469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.9",
      "Grade": 3,
      "GeneralStandard": 2781,
      "Radical": "宀",
      "HSK": 3,
      "FrequencyRank": 733,
      "StrokeCount": 12
    }
  },
  "祭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "sacrifice to, worship"
    },
    "Keys": {
      "Hanzi": 2371,
      "Word": 7338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.6",
      "Grade": 7,
      "GeneralStandard": 2371,
      "Radical": "示",
      "HSK": 7,
      "FrequencyRank": 1782,
      "StrokeCount": 11
    }
  },
  "吓人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià rén",
      "PrimaryDefinition": "Scary"
    },
    "Keys": {
      "Word": 9878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悲观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēiguān",
      "PrimaryDefinition": "pessimistic"
    },
    "Keys": {
      "Word": 5548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蠕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "eumenes polifomis, kind of wasp"
    },
    "Keys": {
      "Hanzi": 3478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3478,
      "Radical": "虫",
      "FrequencyRank": 3512,
      "RadicalIndex": "142.14",
      "StrokeCount": 20
    }
  },
  "星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": "a star, planet; any point of light"
    },
    "Keys": {
      "Hanzi": 1488
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "GeneralStandard": 1488,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 537,
      "StrokeCount": 9
    }
  },
  "识字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí zì",
      "PrimaryDefinition": "to learn to read"
    },
    "Keys": {
      "Word": 5030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "袭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "raid, attack; inherit"
    },
    "Keys": {
      "Hanzi": 2246
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "212.6",
      "GeneralStandard": 2246,
      "Radical": "龍",
      "HSK": 7,
      "FrequencyRank": 1213,
      "StrokeCount": 11
    }
  },
  "一连": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlián",
      "PrimaryDefinition": "in a row, in succession, running"
    },
    "Keys": {
      "Word": 10334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打磨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎmo",
      "PrimaryDefinition": "polish, grind"
    },
    "Keys": {
      "Word": 6106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "城市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngshì",
      "PrimaryDefinition": "city, town, CL:座[zuo4]"
    },
    "Keys": {
      "Word": 1355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "宽松": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānsong",
      "PrimaryDefinition": "to relax (policy), relaxed"
    },
    "Keys": {
      "Word": 7881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "put forth great effort; exhaust"
    },
    "Keys": {
      "Hanzi": 3150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "117.9",
      "GeneralStandard": 3150,
      "Radical": "立",
      "HSK": 8,
      "FrequencyRank": 1987,
      "StrokeCount": 14
    }
  },
  "装置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngzhì",
      "PrimaryDefinition": "install, fit"
    },
    "Keys": {
      "Word": 3201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "炳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "bright, luminous; glorious"
    },
    "Keys": {
      "Hanzi": 4324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4324,
      "Radical": "火",
      "FrequencyRank": 3227,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "珑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "a gem cut like dragon"
    },
    "Keys": {
      "Hanzi": 4087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4087,
      "Radical": "玉",
      "FrequencyRank": 3797,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "心血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxuè",
      "PrimaryDefinition": "painstaking care/effort"
    },
    "Keys": {
      "Word": 10018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "运动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùndong",
      "PrimaryDefinition": "sports, athletics, exercise, movement, campaign"
    },
    "Keys": {
      "Word": 1189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "䦃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "(simplified form) to dig with a hoe, a big hoe"
    },
    "Keys": {
      "Hanzi": 6111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6111,
      "Radical": "",
      "FrequencyRank": 9711,
      "RadicalIndex": "",
      "StrokeCount": 20
    }
  },
  "普通话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔtōnghuà",
      "PrimaryDefinition": "Mandarin (common language), Putonghua (common speech of the Chinese language), ordinary speech"
    },
    "Keys": {
      "Word": 916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "孤立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūlì",
      "PrimaryDefinition": "isolate, isolated"
    },
    "Keys": {
      "Word": 6820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "幅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "piece, strip, breadth of, hem"
    },
    "Keys": {
      "Hanzi": 2648,
      "Word": 3472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.9",
      "Grade": 5,
      "GeneralStandard": 2648,
      "Radical": "巾",
      "HSK": 5,
      "FrequencyRank": 1444,
      "StrokeCount": 12
    }
  },
  "金融": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnróng",
      "PrimaryDefinition": "finance, banking"
    },
    "Keys": {
      "Word": 4752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "恚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "anger, rage"
    },
    "Keys": {
      "Hanzi": 4412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4412,
      "Radical": "心",
      "FrequencyRank": 3951,
      "RadicalIndex": "61.6",
      "StrokeCount": 10
    }
  },
  "触": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "butt, ram, gore; touch"
    },
    "Keys": {
      "Hanzi": 2980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "148.6",
      "GeneralStandard": 2980,
      "Radical": "角",
      "HSK": 5,
      "FrequencyRank": 1207,
      "StrokeCount": 13
    }
  },
  "动静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngjing",
      "PrimaryDefinition": "sound of activity or people talking, news of activity"
    },
    "Keys": {
      "Word": 6334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòng lì",
      "PrimaryDefinition": "exert self, exert oneself, put forth one's strenght, exert self (physically)"
    },
    "Keys": {
      "Word": 10444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "repeatedly, frequently"
    },
    "Keys": {
      "Hanzi": 1123
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.5",
      "GeneralStandard": 1123,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 3018,
      "StrokeCount": 8
    }
  },
  "正当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngdāng",
      "PrimaryDefinition": "proper, legitimate"
    },
    "Keys": {
      "Word": 5340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "呼声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūshēng",
      "PrimaryDefinition": "a shout, fig. opinion or demand, esp. expressed by a group"
    },
    "Keys": {
      "Word": 7109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 5098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5098,
      "Radical": "木",
      "FrequencyRank": 4399,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "轩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "carriage; high; wide; balcony"
    },
    "Keys": {
      "Hanzi": 709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 709,
      "Radical": "車",
      "FrequencyRank": 2389,
      "RadicalIndex": "159.3",
      "StrokeCount": 7
    }
  },
  "饸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4300
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4300,
      "Radical": "食",
      "FrequencyRank": 6790,
      "RadicalIndex": "184.6",
      "StrokeCount": 9
    }
  },
  "颠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diān",
      "PrimaryDefinition": "top, peak, summit; upset"
    },
    "Keys": {
      "Hanzi": 3327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.1",
      "GeneralStandard": 3327,
      "Radical": "頁",
      "HSK": 8,
      "FrequencyRank": 2134,
      "StrokeCount": 16
    }
  },
  "患有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànyǒu",
      "PrimaryDefinition": "to contract (an illness), to be afflicted with, to suffer from"
    },
    "Keys": {
      "Word": 7181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shào",
      "PrimaryDefinition": "(Cant.) to glance"
    },
    "Keys": {
      "Hanzi": 5123
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5123,
      "Radical": "目",
      "FrequencyRank": 7732,
      "RadicalIndex": "109.7",
      "StrokeCount": 12
    }
  },
  "贲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēn",
      "PrimaryDefinition": "forge ahead; energetic; surname"
    },
    "Keys": {
      "Hanzi": 4100
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4100,
      "Radical": "貝",
      "FrequencyRank": 4733,
      "RadicalIndex": "154.5",
      "StrokeCount": 9
    }
  },
  "上空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngkōng",
      "PrimaryDefinition": "in the sky, overhead"
    },
    "Keys": {
      "Word": 8974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "如下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúxià",
      "PrimaryDefinition": "as follows"
    },
    "Keys": {
      "Word": 3852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "佐料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǒliào",
      "PrimaryDefinition": "condiments, seasoning"
    },
    "Keys": {
      "Word": 11026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顽强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánqiáng",
      "PrimaryDefinition": "tenacious, hard to defeat"
    },
    "Keys": {
      "Word": 5128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "hold in mouth; bite; (Cant.) not, negation"
    },
    "Keys": {
      "Hanzi": 4500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4500,
      "Radical": "口",
      "FrequencyRank": 3202,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "营业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngyè",
      "PrimaryDefinition": "do business"
    },
    "Keys": {
      "Word": 3107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "饻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6874,
      "Radical": "食",
      "FrequencyRank": 9647,
      "RadicalIndex": "184.6",
      "StrokeCount": 9
    }
  },
  "菘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "celery, cabbage"
    },
    "Keys": {
      "Hanzi": 4748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4748,
      "Radical": "艸",
      "FrequencyRank": 5999,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "沁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìn",
      "PrimaryDefinition": "soak into, seep in, percolate"
    },
    "Keys": {
      "Hanzi": 872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 872,
      "Radical": "水",
      "FrequencyRank": 3025,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "鳈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "name of fish"
    },
    "Keys": {
      "Hanzi": 7967
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7967,
      "Radical": "魚",
      "FrequencyRank": 6667,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "气象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìxiàng",
      "PrimaryDefinition": "meteorologic, advection, isotherm, meteorology, cirrocumulus, meteorological, altostratus, atmosphere, prevailing spirit/atmosphere, scene, nimbus, prevailing atmosphere, climatic phenomenon, cirrostratus, cumulonimbus, prevailing spirit, meteorological phenomena, altocumulus"
    },
    "Keys": {
      "Word": 3794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "必定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìdìng",
      "PrimaryDefinition": "inevitably, sure, for sure, by all means, needs, necessarily, sure enough, sure as shooting, be sure to, surely, certainly, of necessity, for certain"
    },
    "Keys": {
      "Word": 5588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湖泊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "húpō",
      "PrimaryDefinition": "lake"
    },
    "Keys": {
      "Word": 7117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "maintain, keep whole or intact"
    },
    "Keys": {
      "Hanzi": 485,
      "Word": 943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "11.4",
      "Grade": 2,
      "GeneralStandard": 485,
      "Radical": "入",
      "HSK": 2,
      "FrequencyRank": 124,
      "StrokeCount": 6
    }
  },
  "晾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng",
      "PrimaryDefinition": "air-dry; sun-dry"
    },
    "Keys": {
      "Hanzi": 2625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "GeneralStandard": 2625,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 3909,
      "StrokeCount": 12
    }
  },
  "利润": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìrùn",
      "PrimaryDefinition": "profit"
    },
    "Keys": {
      "Word": 3687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "寿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "old age, long life; lifespan"
    },
    "Keys": {
      "Hanzi": 599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "41.4",
      "GeneralStandard": 599,
      "Radical": "寸",
      "HSK": 5,
      "FrequencyRank": 1615,
      "StrokeCount": 7
    }
  },
  "内涵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèihán",
      "PrimaryDefinition": "intension, comprehension, intention, connotation"
    },
    "Keys": {
      "Word": 8393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "请进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐngjìn",
      "PrimaryDefinition": "'please come in'"
    },
    "Keys": {
      "Word": 296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "看望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànwang",
      "PrimaryDefinition": "to visit, to pay a call to"
    },
    "Keys": {
      "Word": 2658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "珽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": "jade tablet"
    },
    "Keys": {
      "Hanzi": 6933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6933,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 10
    }
  },
  "市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "market, fair; city, town; trade"
    },
    "Keys": {
      "Hanzi": 301,
      "Word": 982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.2",
      "Grade": 2,
      "GeneralStandard": 301,
      "Radical": "巾",
      "HSK": 2,
      "FrequencyRank": 254,
      "StrokeCount": 5
    }
  },
  "学会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xué huì",
      "PrimaryDefinition": "learned society, (scholarly) association"
    },
    "Keys": {
      "Word": 5201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "倡议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàngyì",
      "PrimaryDefinition": "to suggest, to initiate, proposal, initiative"
    },
    "Keys": {
      "Word": 5824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǐ",
      "PrimaryDefinition": "unopened flowers, flower buds"
    },
    "Keys": {
      "Hanzi": 3210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3210,
      "Radical": "艸",
      "FrequencyRank": 3265,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "孀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāng",
      "PrimaryDefinition": "widow"
    },
    "Keys": {
      "Hanzi": 6446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6446,
      "Radical": "女",
      "FrequencyRank": 4368,
      "RadicalIndex": "38.17",
      "StrokeCount": 20
    }
  },
  "郎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "gentleman, young man; husband"
    },
    "Keys": {
      "Hanzi": 1287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.6",
      "GeneralStandard": 1287,
      "Radical": "邑",
      "HSK": 4,
      "FrequencyRank": 1457,
      "StrokeCount": 8
    }
  },
  "无知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúzhī",
      "PrimaryDefinition": "ignorant, stupid"
    },
    "Keys": {
      "Word": 9798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "共同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngtóng",
      "PrimaryDefinition": "common, joint"
    },
    "Keys": {
      "Word": 1509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "月份": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèfèn",
      "PrimaryDefinition": "month"
    },
    "Keys": {
      "Word": 1185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "苠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6662,
      "Radical": "艸",
      "FrequencyRank": 7744,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "畬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shē",
      "PrimaryDefinition": "reclaimed field"
    },
    "Keys": {
      "Hanzi": 7398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7398,
      "Radical": "田",
      "RadicalIndex": "102.7",
      "StrokeCount": 12
    }
  },
  "引经据典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnjīng-jùdiǎn",
      "PrimaryDefinition": "lit. to quote the classics, to quote chapter and verse (idiom)"
    },
    "Keys": {
      "Word": 10388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怀旧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáijiù",
      "PrimaryDefinition": "fond remembrance of times past, nostalgia"
    },
    "Keys": {
      "Word": 7154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㧟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎi",
      "PrimaryDefinition": "(simplified form of 擓) to wipe; to scrub; to rub, to dust; to clean, (a dialect) to scratch with fingers lightly, (a dialect) to carry on the arm"
    },
    "Keys": {
      "Hanzi": 3848
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3848,
      "Radical": "手",
      "FrequencyRank": 7963,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "信仰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnyǎng",
      "PrimaryDefinition": "belief"
    },
    "Keys": {
      "Word": 5189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "难免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánmiǎn",
      "PrimaryDefinition": "hard to avoid"
    },
    "Keys": {
      "Word": 2756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "裥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "folds"
    },
    "Keys": {
      "Hanzi": 5307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5307,
      "Radical": "衣",
      "FrequencyRank": 5531,
      "RadicalIndex": "145.7",
      "StrokeCount": 12
    }
  },
  "雷同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léitóng",
      "PrimaryDefinition": "mirroring others, identical"
    },
    "Keys": {
      "Word": 7959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngcháng",
      "PrimaryDefinition": "normal, commonly, customarily, more often than not, general, usual, on average, as a rule, ordinarily, mostly, as usual, typically, generally, normally, by and large, unremarkably, on the average, usually"
    },
    "Keys": {
      "Word": 1971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "歌咏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēyǒng",
      "PrimaryDefinition": "singing"
    },
    "Keys": {
      "Word": 6728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哪怕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎpà",
      "PrimaryDefinition": "even, even if, even though, no matter how"
    },
    "Keys": {
      "Word": 2757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "功夫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngfu",
      "PrimaryDefinition": "workmanship, skill, art"
    },
    "Keys": {
      "Word": 1510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "销量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāoliàng",
      "PrimaryDefinition": "sales volume"
    },
    "Keys": {
      "Word": 9962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敬佩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngpèi",
      "PrimaryDefinition": "to esteem, to admire"
    },
    "Keys": {
      "Word": 7645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèn",
      "PrimaryDefinition": "gloomy, depressed, melancholy"
    },
    "Keys": {
      "Hanzi": 848,
      "Word": 8245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.4",
      "Grade": 7,
      "GeneralStandard": 848,
      "Radical": "門",
      "HSK": 7,
      "FrequencyRank": 1830,
      "StrokeCount": 7
    }
  },
  "浍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "irrigation ditch, trench; river"
    },
    "Keys": {
      "Hanzi": 4342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4342,
      "Radical": "水",
      "FrequencyRank": 6543,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "佰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi",
      "PrimaryDefinition": "hundred"
    },
    "Keys": {
      "Hanzi": 3952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3952,
      "Radical": "人",
      "FrequencyRank": 5437,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "弩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǔ",
      "PrimaryDefinition": "cross-bow, bow; downward stroke"
    },
    "Keys": {
      "Hanzi": 4068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4068,
      "Radical": "弓",
      "FrequencyRank": 3470,
      "RadicalIndex": "57.5",
      "StrokeCount": 8
    }
  },
  "乃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎi",
      "PrimaryDefinition": "then; really, indeed; as it turned out, after all; namely"
    },
    "Keys": {
      "Hanzi": 20,
      "Word": 8371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "4.1",
      "Grade": 7,
      "GeneralStandard": 20,
      "Radical": "丿",
      "HSK": 8,
      "FrequencyRank": 1165,
      "StrokeCount": 2
    }
  },
  "军事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnshì",
      "PrimaryDefinition": "war, martial, military affairs, legion, military"
    },
    "Keys": {
      "Word": 4776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "屈服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūfú",
      "PrimaryDefinition": "to surrender, to yield"
    },
    "Keys": {
      "Word": 8779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "她们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāmen",
      "PrimaryDefinition": "they (female)"
    },
    "Keys": {
      "Word": 353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "帐篷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàngpeng",
      "PrimaryDefinition": "tent, CL:頂|顶[ding3],座[zuo4]"
    },
    "Keys": {
      "Word": 10654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "髁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "thigh bone, hipbone; kneecap"
    },
    "Keys": {
      "Hanzi": 6243
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6243,
      "Radical": "骨",
      "FrequencyRank": 5080,
      "RadicalIndex": "188.8",
      "StrokeCount": 17
    }
  },
  "迭起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diéqǐ",
      "PrimaryDefinition": "continuously arising, to arise repeatedly"
    },
    "Keys": {
      "Word": 6296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "small sand bank, islet"
    },
    "Keys": {
      "Hanzi": 4964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4964,
      "Radical": "水",
      "FrequencyRank": 4879,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "觑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qù",
      "PrimaryDefinition": "peep at; watch, spy on"
    },
    "Keys": {
      "Hanzi": 5901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5901,
      "Radical": "見",
      "FrequencyRank": 3506,
      "RadicalIndex": "147.11",
      "StrokeCount": 15
    }
  },
  "讨论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎolùn",
      "PrimaryDefinition": "to discuss, to take something up with someone, to talk about"
    },
    "Keys": {
      "Word": 1009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "姬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "beauty; imperial concubine"
    },
    "Keys": {
      "Hanzi": 4687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4687,
      "Radical": "女",
      "FrequencyRank": 2683,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "瑶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "precious jade"
    },
    "Keys": {
      "Hanzi": 5622
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5622,
      "Radical": "玉",
      "FrequencyRank": 2900,
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "光荣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngróng",
      "PrimaryDefinition": "honor, glory, credit"
    },
    "Keys": {
      "Word": 3518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "严格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yángé",
      "PrimaryDefinition": "rigorously enforce, rigorous, tight, grim, strict"
    },
    "Keys": {
      "Word": 3063
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "抑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "press down, repress; curb, hinder"
    },
    "Keys": {
      "Hanzi": 641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 641,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1748,
      "StrokeCount": 7
    }
  },
  "团伙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuánhuǒ",
      "PrimaryDefinition": "(criminal) gang, gang member, accomplice, crony"
    },
    "Keys": {
      "Word": 9589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蠓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "midges; sandflies"
    },
    "Keys": {
      "Hanzi": 6391
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6391,
      "Radical": "虫",
      "FrequencyRank": 5165,
      "RadicalIndex": "142.14",
      "StrokeCount": 19
    }
  },
  "戤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "to infringe a trade-mark to pledge an article"
    },
    "Keys": {
      "Hanzi": 7614
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7614,
      "Radical": "戈",
      "FrequencyRank": 7747,
      "RadicalIndex": "62.9",
      "StrokeCount": 13
    }
  },
  "寂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "still, silent, quiet, desolate"
    },
    "Keys": {
      "Hanzi": 2454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.8",
      "GeneralStandard": 2454,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 1739,
      "StrokeCount": 11
    }
  },
  "棺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "coffin"
    },
    "Keys": {
      "Hanzi": 2577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2577,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2686,
      "StrokeCount": 12
    }
  },
  "鞴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "saddle up horse; drive horse"
    },
    "Keys": {
      "Hanzi": 6370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6370,
      "Radical": "革",
      "FrequencyRank": 5415,
      "RadicalIndex": "177.1",
      "StrokeCount": 19
    }
  },
  "分析": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnxī",
      "PrimaryDefinition": "analyze"
    },
    "Keys": {
      "Word": 3462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "走开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒukāi",
      "PrimaryDefinition": "to leave, to walk away, to beat it, to move aside"
    },
    "Keys": {
      "Word": 1246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "解释": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěshì",
      "PrimaryDefinition": "explain, interpret, analyze"
    },
    "Keys": {
      "Word": 2630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "惝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎng",
      "PrimaryDefinition": "alarmed, agitated"
    },
    "Keys": {
      "Hanzi": 4986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4986,
      "Radical": "心",
      "FrequencyRank": 6553,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "沄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "billows"
    },
    "Keys": {
      "Hanzi": 6614
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6614,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "口语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuyǔ",
      "PrimaryDefinition": "colloquial speech, spoken language, vernacular language, slander, gossip, CL:門|门[men2]"
    },
    "Keys": {
      "Word": 2667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "奢望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēwàng",
      "PrimaryDefinition": "an extravagant hope, to have excessive expectations"
    },
    "Keys": {
      "Word": 9003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "membrane lining inside of reed"
    },
    "Keys": {
      "Hanzi": 4437
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4437,
      "Radical": "艸",
      "FrequencyRank": 5953,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "蹂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róu",
      "PrimaryDefinition": "trample under foot, tread on"
    },
    "Keys": {
      "Hanzi": 3345
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3345,
      "Radical": "足",
      "FrequencyRank": 3815,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "审批": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnpī",
      "PrimaryDefinition": "to examine and approve, to endorse"
    },
    "Keys": {
      "Word": 9043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蘑菇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mógu",
      "PrimaryDefinition": "mushroom, to pester, to dawdle"
    },
    "Keys": {
      "Word": 8322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "dried rice, dry provisions"
    },
    "Keys": {
      "Hanzi": 7807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7807,
      "Radical": "米",
      "FrequencyRank": 5898,
      "RadicalIndex": "119.9",
      "StrokeCount": 15
    }
  },
  "拳头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quántou",
      "PrimaryDefinition": "fist, clenched fist, CL:個|个[ge4], competitive (product)"
    },
    "Keys": {
      "Word": 8814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艰难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānnán",
      "PrimaryDefinition": "difficult, hard"
    },
    "Keys": {
      "Word": 3594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "洲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "continent; island; islet"
    },
    "Keys": {
      "Hanzi": 1680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1680,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 701,
      "StrokeCount": 9
    }
  },
  "答案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dáàn",
      "PrimaryDefinition": "result, script, resolution, solution, answer, key"
    },
    "Keys": {
      "Word": 2361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "镠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "pure gold"
    },
    "Keys": {
      "Hanzi": 6119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6119,
      "Radical": "金",
      "FrequencyRank": 6408,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "阕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "close, shut; watch tower"
    },
    "Keys": {
      "Hanzi": 5268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5268,
      "Radical": "門",
      "FrequencyRank": 4929,
      "RadicalIndex": "169.9",
      "StrokeCount": 12
    }
  },
  "簏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "a box, a basket"
    },
    "Keys": {
      "Hanzi": 7953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7953,
      "Radical": "竹",
      "FrequencyRank": 6684,
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "印证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìnzhèng",
      "PrimaryDefinition": "confirm, verify"
    },
    "Keys": {
      "Word": 10408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "簸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǒ",
      "PrimaryDefinition": "winnower"
    },
    "Keys": {
      "Hanzi": 3462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.13",
      "GeneralStandard": 3462,
      "Radical": "竹",
      "HSK": 6,
      "FrequencyRank": 3586,
      "StrokeCount": 19
    }
  },
  "发票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāpiào",
      "PrimaryDefinition": "invoice, receipt or bill for purchase"
    },
    "Keys": {
      "Word": 2445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "极端": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíduān",
      "PrimaryDefinition": "extreme, exceeding"
    },
    "Keys": {
      "Word": 4697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "觥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "a cup made of horn obstinate"
    },
    "Keys": {
      "Hanzi": 5535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5535,
      "Radical": "角",
      "FrequencyRank": 4532,
      "RadicalIndex": "148.6",
      "StrokeCount": 13
    }
  },
  "察看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chákàn",
      "PrimaryDefinition": "to watch, to look carefully at"
    },
    "Keys": {
      "Word": 5793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "澴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "river in Hubei province"
    },
    "Keys": {
      "Hanzi": 7906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7906,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "其实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíshí",
      "PrimaryDefinition": "as a matter of fact, actually, in fact"
    },
    "Keys": {
      "Word": 1802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "泂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "clear and deep (of water); wide"
    },
    "Keys": {
      "Hanzi": 6721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6721,
      "Radical": "水",
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "处于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔyú",
      "PrimaryDefinition": "go, be in, be (in a certain condition)"
    },
    "Keys": {
      "Word": 2336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "心愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnyuàn",
      "PrimaryDefinition": "breathing"
    },
    "Keys": {
      "Word": 5182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蓝色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lánsè",
      "PrimaryDefinition": "blue, blue (color), blueness"
    },
    "Keys": {
      "Word": 831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "丰富": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngfù",
      "PrimaryDefinition": "rich, abundant, plentiful"
    },
    "Keys": {
      "Word": 1456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "就诊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù zhěn",
      "PrimaryDefinition": "to see a doctor, to seek medical advice"
    },
    "Keys": {
      "Word": 7672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3698,
      "Radical": "口",
      "FrequencyRank": 5177,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "谎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎng",
      "PrimaryDefinition": "lie"
    },
    "Keys": {
      "Hanzi": 2461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.9",
      "GeneralStandard": 2461,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2207,
      "StrokeCount": 11
    }
  },
  "机构": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīgòu",
      "PrimaryDefinition": "internal structure of an organization, motion, gadget, instrumentality, institute, organ, mechanism, gear, representation, mechanics, machinery, apparatus, establishment, institution, means, organization, agency, setup, delegacy, outfit"
    },
    "Keys": {
      "Word": 2572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "皦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "bright white; bright, clear"
    },
    "Keys": {
      "Hanzi": 8012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8012,
      "Radical": "白",
      "RadicalIndex": "106.13",
      "StrokeCount": 18
    }
  },
  "从中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngzhōng",
      "PrimaryDefinition": "out of, from among, therefrom"
    },
    "Keys": {
      "Word": 3365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "批发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīfā",
      "PrimaryDefinition": "wholesale, bulk trade, distribution"
    },
    "Keys": {
      "Word": 8502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裁决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáijué",
      "PrimaryDefinition": "ruling, adjudication"
    },
    "Keys": {
      "Word": 5743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "导游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoyóu",
      "PrimaryDefinition": "tour guide, guidebook, to conduct a tour"
    },
    "Keys": {
      "Word": 2391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "话语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàyǔ",
      "PrimaryDefinition": "utterance, discourse, word, speech, spoken language, endearment, remark"
    },
    "Keys": {
      "Word": 7152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坏人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàirén",
      "PrimaryDefinition": "hellion, bad person, scoundrel, badman, baddie, villain, malefactor, evildoer"
    },
    "Keys": {
      "Word": 731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "沿途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yántú",
      "PrimaryDefinition": "on the way, throughout a journey"
    },
    "Keys": {
      "Word": 10183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnsè",
      "PrimaryDefinition": "inherent qualities, natural qualities, distinctive character, true qualities, natural color"
    },
    "Keys": {
      "Word": 5575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "船员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuányuán",
      "PrimaryDefinition": "crew, sailorman, sailor, shipmate, crewman, tarpaulin, old salt, seaman, bargeman, tar, boatman, Jack-tar, mariner, sea dog, gob, waister, seafarer"
    },
    "Keys": {
      "Word": 4444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "钥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "key; lock"
    },
    "Keys": {
      "Hanzi": 1531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "GeneralStandard": 1531,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2481,
      "StrokeCount": 9
    }
  },
  "美容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měiróng",
      "PrimaryDefinition": "improve looks"
    },
    "Keys": {
      "Word": 4855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "骠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "charger, steed; swift, valiant"
    },
    "Keys": {
      "Hanzi": 5841
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5841,
      "Radical": "馬",
      "FrequencyRank": 5321,
      "RadicalIndex": "187.11",
      "StrokeCount": 14
    }
  },
  "鸟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎo",
      "PrimaryDefinition": "bird"
    },
    "Keys": {
      "Hanzi": 296,
      "Radical": 196,
      "Word": 893
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "196",
      "Grade": 2,
      "GeneralStandard": 296,
      "Radical": "鳥",
      "HSK": 2,
      "FrequencyRank": 1263,
      "StrokeCount": 5
    }
  },
  "气候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìhòu",
      "PrimaryDefinition": "situation, clime, climatic, climate, weather, climactic"
    },
    "Keys": {
      "Word": 1804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "绿色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜsè",
      "PrimaryDefinition": "verdure, green, greenness, green color, verdancy, bice, virescence, vert, pistachio, viridity"
    },
    "Keys": {
      "Word": 862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "膝盖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīgài",
      "PrimaryDefinition": "knee"
    },
    "Keys": {
      "Word": 9827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7531,
      "Radical": "金",
      "FrequencyRank": 7957,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "峭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "steep, precipitous, rugged"
    },
    "Keys": {
      "Hanzi": 1919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.7",
      "GeneralStandard": 1919,
      "Radical": "山",
      "HSK": 6,
      "FrequencyRank": 2990,
      "StrokeCount": 10
    }
  },
  "音乐会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnyuèhuì",
      "PrimaryDefinition": "musicale, philharmonic, concert"
    },
    "Keys": {
      "Word": 1160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "从容不迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngróng-bùpò",
      "PrimaryDefinition": "calm, unruffled"
    },
    "Keys": {
      "Word": 6060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒu",
      "PrimaryDefinition": "tadpole"
    },
    "Keys": {
      "Hanzi": 1902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1902,
      "Radical": "虫",
      "FrequencyRank": 4043,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "磨难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mónàn",
      "PrimaryDefinition": "a torment, a trial, tribulation, a cross (to bear), well-tried"
    },
    "Keys": {
      "Word": 8319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纠纷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiūfēn",
      "PrimaryDefinition": "entanglement, embarrassment, cobweb, skein, embroilment, dissension, complication, issue, conflict, confusion, dispute, shindig, knottiness, dustup, aggro, tangle, betanglement, maze, imbroglio, strife"
    },
    "Keys": {
      "Word": 4754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一番": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì fān",
      "PrimaryDefinition": "Some"
    },
    "Keys": {
      "Word": 5238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "肥料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féiliào",
      "PrimaryDefinition": "fertilizer, manure"
    },
    "Keys": {
      "Word": 6515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "clear, manifest, obvious"
    },
    "Keys": {
      "Hanzi": 3149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "59.11",
      "GeneralStandard": 3149,
      "Radical": "彡",
      "HSK": 9,
      "FrequencyRank": 2953,
      "StrokeCount": 14
    }
  },
  "撤换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèhuàn",
      "PrimaryDefinition": "recall, dismiss and replace"
    },
    "Keys": {
      "Word": 5848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐlì",
      "PrimaryDefinition": "water conservancy, irrigation works"
    },
    "Keys": {
      "Word": 9262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "mat, pad; rely on; pretext"
    },
    "Keys": {
      "Hanzi": 3390
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3390,
      "Radical": "艸",
      "FrequencyRank": 2615,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "封闭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngbì",
      "PrimaryDefinition": "resistant to anything new"
    },
    "Keys": {
      "Word": 2472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "腧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "insertion point in acupuncture; acupoint"
    },
    "Keys": {
      "Hanzi": 5521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5521,
      "Radical": "肉",
      "FrequencyRank": 6299,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "眼里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnli",
      "PrimaryDefinition": "In the eyes"
    },
    "Keys": {
      "Word": 3076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "看得出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàndechū",
      "PrimaryDefinition": "Look"
    },
    "Keys": {
      "Word": 7770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "flame, blaze; glowing, blazing"
    },
    "Keys": {
      "Hanzi": 2748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.8",
      "GeneralStandard": 2748,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 2259,
      "StrokeCount": 12
    }
  },
  "恩情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēnqíng",
      "PrimaryDefinition": "kindness, affection, grace, favor"
    },
    "Keys": {
      "Word": 6421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "设": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "build; establish; display"
    },
    "Keys": {
      "Hanzi": 560,
      "Word": 9004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "Grade": 7,
      "GeneralStandard": 560,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 302,
      "StrokeCount": 6
    }
  },
  "笪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "a coarse mat of rushes or bamboo"
    },
    "Keys": {
      "Hanzi": 4896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4896,
      "Radical": "竹",
      "FrequencyRank": 5231,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "的确": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "díquè",
      "PrimaryDefinition": "certainly, surely"
    },
    "Keys": {
      "Word": 2403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "调皮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáopí",
      "PrimaryDefinition": "naughty, mischievous, unruly"
    },
    "Keys": {
      "Word": 2934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "雩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "offer sacrifice for rain"
    },
    "Keys": {
      "Hanzi": 4806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4806,
      "Radical": "雨",
      "FrequencyRank": 6612,
      "RadicalIndex": "173.3",
      "StrokeCount": 11
    }
  },
  "地铁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìtiě",
      "PrimaryDefinition": "subway, metro"
    },
    "Keys": {
      "Word": 618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "本领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnlǐng",
      "PrimaryDefinition": "skill, ability, capability, CL:項|项[xiang4],個|个[ge4]"
    },
    "Keys": {
      "Word": 1291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "廉洁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánjié",
      "PrimaryDefinition": "honest, not coercive, honesty, integrity, incorruptible"
    },
    "Keys": {
      "Word": 8025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "半信半疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànxìn-bànyí",
      "PrimaryDefinition": "half doubting, dubious, skeptical"
    },
    "Keys": {
      "Word": 5497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuhài",
      "PrimaryDefinition": "destructive, harmful, damaging"
    },
    "Keys": {
      "Word": 4141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "时间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjiān",
      "PrimaryDefinition": "moment, day, clock time, fourth_dimension, relativity, hour, duration, time, when, while, temporal, economy"
    },
    "Keys": {
      "Word": 329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "价值观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàzhíguān",
      "PrimaryDefinition": "values, valuation"
    },
    "Keys": {
      "Word": 7364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用不着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngbuzháo",
      "PrimaryDefinition": "not need, have no use for"
    },
    "Keys": {
      "Word": 4128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "泄露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xièlù",
      "PrimaryDefinition": "to leak (information), to divulge, also pr. [xie4 lou4]"
    },
    "Keys": {
      "Word": 9995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔ",
      "PrimaryDefinition": "leek"
    },
    "Keys": {
      "Radical": 179,
      "Hanzi": 1466
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1466,
      "Radical": "韭",
      "FrequencyRank": 4227,
      "RadicalIndex": "179",
      "StrokeCount": 9
    }
  },
  "屹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "to rise high; to stand erect"
    },
    "Keys": {
      "Hanzi": 435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.3",
      "GeneralStandard": 435,
      "Radical": "山",
      "HSK": 9,
      "FrequencyRank": 3851,
      "StrokeCount": 6
    }
  },
  "矜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "pity, feel sorry for, show sympat"
    },
    "Keys": {
      "Hanzi": 4392
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4392,
      "Radical": "矛",
      "FrequencyRank": 3661,
      "RadicalIndex": "110.4",
      "StrokeCount": 9
    }
  },
  "燚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7900,
      "Radical": "火",
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "报废": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào fèi",
      "PrimaryDefinition": "to scrap, to be written off"
    },
    "Keys": {
      "Word": 5533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "weak, lame"
    },
    "Keys": {
      "Hanzi": 5065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5065,
      "Radical": "走",
      "FrequencyRank": 4802,
      "RadicalIndex": "156.5",
      "StrokeCount": 12
    }
  },
  "月": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "moon"
    },
    "Keys": {
      "Hanzi": 150,
      "Radical": 74,
      "Word": 449
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "74",
      "Grade": 1,
      "GeneralStandard": 150,
      "Radical": "月",
      "HSK": 1,
      "FrequencyRank": 169,
      "StrokeCount": 4
    }
  },
  "畀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "to give"
    },
    "Keys": {
      "Hanzi": 3909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3909,
      "Radical": "田",
      "FrequencyRank": 5859,
      "RadicalIndex": "102.3",
      "StrokeCount": 8
    }
  },
  "英镑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngbàng",
      "PrimaryDefinition": "pound sterling"
    },
    "Keys": {
      "Word": 10411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "soft leather"
    },
    "Keys": {
      "Radical": 178,
      "Hanzi": 3509
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3509,
      "Radical": "韋",
      "FrequencyRank": 1667,
      "RadicalIndex": "178",
      "StrokeCount": 4
    }
  },
  "姊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "elder sister"
    },
    "Keys": {
      "Hanzi": 921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 921,
      "Radical": "女",
      "FrequencyRank": 2236,
      "RadicalIndex": "38.5",
      "StrokeCount": 7
    }
  },
  "微波炉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēibōlú",
      "PrimaryDefinition": "microwave oven, CL:臺|台[tai2]"
    },
    "Keys": {
      "Word": 5139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "依": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "rely on, be set in; consent, obey a wish"
    },
    "Keys": {
      "Hanzi": 1153,
      "Word": 10277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "Grade": 7,
      "GeneralStandard": 1153,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 568,
      "StrokeCount": 8
    }
  },
  "聪明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōngming",
      "PrimaryDefinition": "intelligent, bright, clever"
    },
    "Keys": {
      "Word": 3361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "取款机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔkuǎnjī",
      "PrimaryDefinition": "ATM"
    },
    "Keys": {
      "Word": 4958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "昆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "elder brother; descendants"
    },
    "Keys": {
      "Hanzi": 1080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1080,
      "Radical": "日",
      "HSK": 8,
      "FrequencyRank": 1759,
      "StrokeCount": 8
    }
  },
  "一无所知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīwú-suǒzhī",
      "PrimaryDefinition": "not knowing anything at all (idiom), completely ignorant, without an inkling"
    },
    "Keys": {
      "Word": 10347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "医药": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyào",
      "PrimaryDefinition": "medicament, medicine, curative, medication, remedy, physic"
    },
    "Keys": {
      "Word": 5228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "转机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnjī",
      "PrimaryDefinition": "turn for better"
    },
    "Keys": {
      "Word": 10894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjú",
      "PrimaryDefinition": "general situation, present conditions"
    },
    "Keys": {
      "Word": 6122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "Chinese cypress, Chinese juniper"
    },
    "Keys": {
      "Hanzi": 4459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4459,
      "Radical": "木",
      "FrequencyRank": 4101,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "痤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuó",
      "PrimaryDefinition": "a swelling of the lymph nodes"
    },
    "Keys": {
      "Hanzi": 5257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5257,
      "Radical": "疒",
      "FrequencyRank": 4924,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "追踪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīzōng",
      "PrimaryDefinition": "follow trail, track"
    },
    "Keys": {
      "Word": 10921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxīn",
      "PrimaryDefinition": "pure and fresh, refreshing (of style/fashion/etc.)"
    },
    "Keys": {
      "Word": 8751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拥抱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōngbào",
      "PrimaryDefinition": "embrace"
    },
    "Keys": {
      "Word": 4126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "捣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo",
      "PrimaryDefinition": "hull; thresh; beat, attack"
    },
    "Keys": {
      "Hanzi": 1811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "GeneralStandard": 1811,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2723,
      "StrokeCount": 10
    }
  },
  "干扰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānrǎo",
      "PrimaryDefinition": "disturb, interfere"
    },
    "Keys": {
      "Word": 3479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "句子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùzi",
      "PrimaryDefinition": "sentence"
    },
    "Keys": {
      "Word": 801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "氮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "nitrogen"
    },
    "Keys": {
      "Hanzi": 2671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2671,
      "Radical": "气",
      "FrequencyRank": 3482,
      "RadicalIndex": "84.8",
      "StrokeCount": 12
    }
  },
  "绷带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēngdài",
      "PrimaryDefinition": "bandage (loanword)"
    },
    "Keys": {
      "Word": 5573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6868
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6868,
      "Radical": "肉",
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "看不起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànbuqǐ",
      "PrimaryDefinition": "to look down upon, to despise"
    },
    "Keys": {
      "Word": 2657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "讠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "speech"
    },
    "Keys": {
      "Radical": 149
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "褡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dā",
      "PrimaryDefinition": "girdle; loincloth; pouch, bag"
    },
    "Keys": {
      "Hanzi": 5819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5819,
      "Radical": "衣",
      "FrequencyRank": 4887,
      "RadicalIndex": "145.1",
      "StrokeCount": 14
    }
  },
  "就算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùsuàn",
      "PrimaryDefinition": "conj.: even if, granted that"
    },
    "Keys": {
      "Word": 4762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "殿堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàntáng",
      "PrimaryDefinition": "mansion, manse, hall, palace, temple, residence, mansion house"
    },
    "Keys": {
      "Word": 6284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "干燥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānzào",
      "PrimaryDefinition": "dry, arid"
    },
    "Keys": {
      "Word": 6666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "超速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāosù",
      "PrimaryDefinition": "to exceed the speed limit, to speed, high-speed"
    },
    "Keys": {
      "Word": 5830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔbàn",
      "PrimaryDefinition": "sponsor, direct, direction, host, give"
    },
    "Keys": {
      "Word": 4249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "要是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàoshi",
      "PrimaryDefinition": "conj.: if, suppose, in case"
    },
    "Keys": {
      "Word": 2086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "伉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàng",
      "PrimaryDefinition": "compare, match; pair; spouse"
    },
    "Keys": {
      "Hanzi": 3609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3609,
      "Radical": "人",
      "FrequencyRank": 4989,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "胚胎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēitāi",
      "PrimaryDefinition": "embryo"
    },
    "Keys": {
      "Word": 8484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "退出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì chū",
      "PrimaryDefinition": "withdraw from, quit"
    },
    "Keys": {
      "Word": 1993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "孩子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háizi",
      "PrimaryDefinition": "kid, issue, chap, daughter, children, sonny, son or daughter, child, spawn, boy, kipper, son"
    },
    "Keys": {
      "Word": 130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "国内": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guónèi",
      "PrimaryDefinition": "interior (of country), interior, home, intestine, internal, domestic"
    },
    "Keys": {
      "Word": 1530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "重要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngyào",
      "PrimaryDefinition": "important, significant, major"
    },
    "Keys": {
      "Word": 481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "蚊帐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénzhàng",
      "PrimaryDefinition": "mosquito net, CL:頂|顶[ding3]"
    },
    "Keys": {
      "Word": 9741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōu",
      "PrimaryDefinition": "sing; songs"
    },
    "Keys": {
      "Hanzi": 3631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3631,
      "Radical": "言",
      "FrequencyRank": 4770,
      "RadicalIndex": "149.4",
      "StrokeCount": 6
    }
  },
  "一下子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxiàzi",
      "PrimaryDefinition": "in a short while, all at once, all of a sudden"
    },
    "Keys": {
      "Word": 4109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "不像话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù xiànghuà",
      "PrimaryDefinition": "unreasonable, shocking, outrageous"
    },
    "Keys": {
      "Word": 5677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "be familiar with; disrespect"
    },
    "Keys": {
      "Hanzi": 3986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3986,
      "Radical": "犬",
      "FrequencyRank": 5592,
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "瑧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7621,
      "Radical": "玉",
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "其": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "his, her, its, their; that"
    },
    "Keys": {
      "Hanzi": 996,
      "Word": 3787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.6",
      "Grade": 5,
      "GeneralStandard": 996,
      "Radical": "八",
      "HSK": 2,
      "FrequencyRank": 85,
      "StrokeCount": 8
    }
  },
  "名副其实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngfùqíshí",
      "PrimaryDefinition": "not just in name only, but also in reality (idiom)"
    },
    "Keys": {
      "Word": 8302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饭碗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fànwǎn",
      "PrimaryDefinition": "rice bowl, fig. livelihood, job, way of making a living"
    },
    "Keys": {
      "Word": 6480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèng",
      "PrimaryDefinition": "character used for place names"
    },
    "Keys": {
      "Hanzi": 7107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7107,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "书法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūfǎ",
      "PrimaryDefinition": "calligraphy"
    },
    "Keys": {
      "Word": 3920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "打败": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ bài",
      "PrimaryDefinition": "rout, outplay, whip, scupper, wallop, mop up, finish, suffer a defeat, overrule, overwhelm, flog, rack up, whop, be beaten, overpower, subdue, overmatch, snooker, be defeated, larrup, outfight, overmaster, floor, whup, knock_off, best, drub, defeat, euchre, confound, lick, fall, outrival, worst, overthrow, convince, vanquish, beat, pip"
    },
    "Keys": {
      "Word": 2363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "半天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàntiān",
      "PrimaryDefinition": "half of the day, a long time, quite a while, midair, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 12
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "北": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běi",
      "PrimaryDefinition": "north; northern; northward"
    },
    "Keys": {
      "Hanzi": 227,
      "Word": 22
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "21.3",
      "Grade": 1,
      "GeneralStandard": 227,
      "Radical": "匕",
      "HSK": 1,
      "FrequencyRank": 315,
      "StrokeCount": 5
    }
  },
  "下边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàbian",
      "PrimaryDefinition": "under, the underside, below"
    },
    "Keys": {
      "Word": 388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "修理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūlǐ",
      "PrimaryDefinition": "repair, mend, fix"
    },
    "Keys": {
      "Word": 3048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "事先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìxiān",
      "PrimaryDefinition": "ahead, beforehand, in advance, in_advance, aforetime, aforehand"
    },
    "Keys": {
      "Word": 2870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "牾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "to oppose; to gore"
    },
    "Keys": {
      "Hanzi": 4889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4889,
      "Radical": "牛",
      "FrequencyRank": 6522,
      "RadicalIndex": "93.7",
      "StrokeCount": 11
    }
  },
  "证明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngmíng",
      "PrimaryDefinition": "prove, testify, bear out"
    },
    "Keys": {
      "Word": 2157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不得而知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùdéérzhī",
      "PrimaryDefinition": "unknown, unable to find out"
    },
    "Keys": {
      "Word": 5692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "樟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "camphor tree"
    },
    "Keys": {
      "Hanzi": 3217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3217,
      "Radical": "木",
      "FrequencyRank": 4063,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "减": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "decrease, subtract, diminish"
    },
    "Keys": {
      "Hanzi": 2375,
      "Word": 2604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.9",
      "Grade": 4,
      "GeneralStandard": 2375,
      "Radical": "冫",
      "HSK": 4,
      "FrequencyRank": 857,
      "StrokeCount": 11
    }
  },
  "个": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gè",
      "PrimaryDefinition": "numerary adjunct, piece; single"
    },
    "Keys": {
      "Hanzi": 45,
      "Word": 118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "2.2",
      "Grade": 1,
      "GeneralStandard": 45,
      "Radical": "丨",
      "HSK": 1,
      "FrequencyRank": 12,
      "StrokeCount": 3
    }
  },
  "涓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": "brook, stream; select; pure"
    },
    "Keys": {
      "Hanzi": 4652
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4652,
      "Radical": "水",
      "FrequencyRank": 4050,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "镉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "cadmium"
    },
    "Keys": {
      "Hanzi": 5947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5947,
      "Radical": "金",
      "FrequencyRank": 5007,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "卷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juǎn",
      "PrimaryDefinition": "scroll; curl; make a comeback"
    },
    "Keys": {
      "Hanzi": 1231,
      "Word": 2654
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "26.6",
      "Grade": 4,
      "GeneralStandard": 1231,
      "Radical": "卩",
      "HSK": 4,
      "FrequencyRank": 1016,
      "StrokeCount": 8
    }
  },
  "合同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hétong",
      "PrimaryDefinition": "(business) contract, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "跨国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàguó",
      "PrimaryDefinition": "transnationa, transnational, multinational"
    },
    "Keys": {
      "Word": 7874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒu",
      "PrimaryDefinition": "hug, embrace; drag, pull"
    },
    "Keys": {
      "Hanzi": 2535,
      "Word": 8121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2535,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2795,
      "StrokeCount": 12
    }
  },
  "民办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínbàn",
      "PrimaryDefinition": "run by the local people, privately operated"
    },
    "Keys": {
      "Word": 8297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "scissors; cut, clip; annhilate"
    },
    "Keys": {
      "Hanzi": 7809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7809,
      "Radical": "羽",
      "FrequencyRank": 4765,
      "RadicalIndex": "124.9",
      "StrokeCount": 15
    }
  },
  "讯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "inquire; ask; examine; reproach"
    },
    "Keys": {
      "Hanzi": 322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.3",
      "GeneralStandard": 322,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 1238,
      "StrokeCount": 5
    }
  },
  "绍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shào",
      "PrimaryDefinition": "continue, carry on; join"
    },
    "Keys": {
      "Hanzi": 1348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "GeneralStandard": 1348,
      "Radical": "糸",
      "HSK": 1,
      "FrequencyRank": 1234,
      "StrokeCount": 8
    }
  },
  "起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "rise, stand up; go up; begin"
    },
    "Keys": {
      "Hanzi": 1789,
      "Word": 286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.3",
      "Grade": 1,
      "GeneralStandard": 1789,
      "Radical": "走",
      "HSK": 1,
      "FrequencyRank": 75,
      "StrokeCount": 10
    }
  },
  "怎么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zěnme",
      "PrimaryDefinition": "how"
    },
    "Keys": {
      "Word": 457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "许可证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔkězhèng",
      "PrimaryDefinition": "license, authorization, permit"
    },
    "Keys": {
      "Word": 10099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拘束": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūshù",
      "PrimaryDefinition": "to restrict, to restrain, constrained, awkward, ill at ease, uncomfortable, reticent"
    },
    "Keys": {
      "Word": 7677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "平和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínghé",
      "PrimaryDefinition": "calm, pacify, composed, moderate, gentle"
    },
    "Keys": {
      "Word": 8548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诉讼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùsòng",
      "PrimaryDefinition": "lawsuit"
    },
    "Keys": {
      "Word": 9349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盼望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pànwàng",
      "PrimaryDefinition": "hope/long for, look forward to"
    },
    "Keys": {
      "Word": 4897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "婠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7272,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "核能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hénéng",
      "PrimaryDefinition": "nuclear energy"
    },
    "Keys": {
      "Word": 7049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接二连三": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēèr-liánsān",
      "PrimaryDefinition": "one after another (idiom), in quick succession"
    },
    "Keys": {
      "Word": 7504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "子弹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐdàn",
      "PrimaryDefinition": "bullet, CL:粒[li4],顆|颗[ke1],發|发[fa1]"
    },
    "Keys": {
      "Word": 4271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "指望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐwang",
      "PrimaryDefinition": "to hope for sth, to count on, hope"
    },
    "Keys": {
      "Word": 10777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "魂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hún",
      "PrimaryDefinition": "soul, spirit"
    },
    "Keys": {
      "Hanzi": 2830,
      "Word": 7229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "194.4",
      "Grade": 7,
      "GeneralStandard": 2830,
      "Radical": "鬼",
      "HSK": 7,
      "FrequencyRank": 1348,
      "StrokeCount": 13
    }
  },
  "任期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènqī",
      "PrimaryDefinition": "term of office"
    },
    "Keys": {
      "Word": 8865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "mustard plant; mustard; tiny"
    },
    "Keys": {
      "Hanzi": 667
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 667,
      "Radical": "艸",
      "FrequencyRank": 4002,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "轻型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxíng",
      "PrimaryDefinition": "light (machinery, aircraft etc)"
    },
    "Keys": {
      "Word": 8736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi dòng",
      "PrimaryDefinition": "to start, to set in motion, to move, to march, to dig in (eating), to tuck in (eating)"
    },
    "Keys": {
      "Word": 7744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欣赏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshǎng",
      "PrimaryDefinition": "appreciate, enjoy, admire"
    },
    "Keys": {
      "Word": 4064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "海绵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎimián",
      "PrimaryDefinition": "sponge (zoology), sponge (piece of absorbent material, either natural or made from plastic etc), foam rubber"
    },
    "Keys": {
      "Word": 6965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "靠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kào",
      "PrimaryDefinition": "lean on, trust, depend on; near"
    },
    "Keys": {
      "Hanzi": 3259,
      "Word": 808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "175.7",
      "Grade": 2,
      "GeneralStandard": 3259,
      "Radical": "非",
      "HSK": 2,
      "FrequencyRank": 808,
      "StrokeCount": 15
    }
  },
  "茈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "a plant yielding a red dye"
    },
    "Keys": {
      "Hanzi": 6787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6787,
      "Radical": "艸",
      "FrequencyRank": 7026,
      "RadicalIndex": "140.5",
      "StrokeCount": 9
    }
  },
  "慷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāng",
      "PrimaryDefinition": "ardent; generous, magnanimous"
    },
    "Keys": {
      "Hanzi": 3170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.11",
      "GeneralStandard": 3170,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 2935,
      "StrokeCount": 14
    }
  },
  "平静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngjìng",
      "PrimaryDefinition": "calm, quiet, tranquil"
    },
    "Keys": {
      "Word": 2783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "相同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngtóng",
      "PrimaryDefinition": "be identical/alike, uniform, be identical, overlap, alike, identical, be alike"
    },
    "Keys": {
      "Word": 1080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "涐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7060,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "风趣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngqù",
      "PrimaryDefinition": "charm, humor, wit, humorous, witty"
    },
    "Keys": {
      "Word": 6577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "fur"
    },
    "Keys": {
      "Hanzi": 123,
      "Radical": 82,
      "Word": 1731
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "82",
      "Grade": 3,
      "GeneralStandard": 123,
      "Radical": "毛",
      "HSK": 1,
      "FrequencyRank": 623,
      "StrokeCount": 4
    }
  },
  "园林": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánlín",
      "PrimaryDefinition": "gardens, park, landscape garden"
    },
    "Keys": {
      "Word": 4153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "民意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínyì",
      "PrimaryDefinition": "public_opinion, popular will"
    },
    "Keys": {
      "Word": 4870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "谢谢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xièxie",
      "PrimaryDefinition": "thank"
    },
    "Keys": {
      "Word": 407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "芯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "pith from rush (juncus effusus)"
    },
    "Keys": {
      "Hanzi": 673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 673,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 2539,
      "StrokeCount": 7
    }
  },
  "就是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùshì",
      "PrimaryDefinition": "conj.: even if, even"
    },
    "Keys": {
      "Word": 1648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "硬币": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngbì",
      "PrimaryDefinition": "coin, CL:枚[mei2]"
    },
    "Keys": {
      "Word": 10426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎng",
      "PrimaryDefinition": "plunder, rob, take by force"
    },
    "Keys": {
      "Hanzi": 637,
      "Word": 3812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 5,
      "GeneralStandard": 637,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1412,
      "StrokeCount": 7
    }
  },
  "明媚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngmèi",
      "PrimaryDefinition": "bright and beautiful"
    },
    "Keys": {
      "Word": 8310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肌肤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīfū",
      "PrimaryDefinition": "skin"
    },
    "Keys": {
      "Word": 7269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好似": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎosì",
      "PrimaryDefinition": "to seem, to be like"
    },
    "Keys": {
      "Word": 4657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "覆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "cover; tip over; return; reply"
    },
    "Keys": {
      "Hanzi": 3440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "146.12",
      "GeneralStandard": 3440,
      "Radical": "襾",
      "HSK": 8,
      "FrequencyRank": 1823,
      "StrokeCount": 18
    }
  },
  "咣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāng",
      "PrimaryDefinition": "the sound of large door closing"
    },
    "Keys": {
      "Hanzi": 4214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4214,
      "Radical": "口",
      "FrequencyRank": 4546,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "苫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "rush or straw matting"
    },
    "Keys": {
      "Hanzi": 3858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3858,
      "Radical": "艸",
      "FrequencyRank": 4623,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "district, region, boundary; land"
    },
    "Keys": {
      "Hanzi": 2164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.8",
      "GeneralStandard": 2164,
      "Radical": "土",
      "HSK": 5,
      "FrequencyRank": 847,
      "StrokeCount": 11
    }
  },
  "身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "body"
    },
    "Keys": {
      "Radical": 158,
      "Hanzi": 786
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "158",
      "GeneralStandard": 786,
      "Radical": "身",
      "HSK": 1,
      "FrequencyRank": 164,
      "StrokeCount": 7
    }
  },
  "尘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "dust, dirt, ashes, cinders"
    },
    "Keys": {
      "Hanzi": 414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "42.3",
      "GeneralStandard": 414,
      "Radical": "小",
      "HSK": 8,
      "FrequencyRank": 1718,
      "StrokeCount": 6
    }
  },
  "串门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàn mén",
      "PrimaryDefinition": "to call on sb, to drop in, to visit sb's home"
    },
    "Keys": {
      "Word": 6016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叔叔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūshu",
      "PrimaryDefinition": "father's younger brother, uncle, uncle (child's address for young males)"
    },
    "Keys": {
      "Word": 2885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "差错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chācuò",
      "PrimaryDefinition": "mistake, slip-up, fault, error (in data transmission), accident, mishap"
    },
    "Keys": {
      "Word": 5782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "工会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnghuì",
      "PrimaryDefinition": "trade/labor union"
    },
    "Keys": {
      "Word": 6752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主题歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔtígē",
      "PrimaryDefinition": "Theme song"
    },
    "Keys": {
      "Word": 10856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "embarrassed; ill at ease"
    },
    "Keys": {
      "Hanzi": 2883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "43.1",
      "GeneralStandard": 2883,
      "Radical": "尢",
      "HSK": 7,
      "FrequencyRank": 2726,
      "StrokeCount": 13
    }
  },
  "咍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāi",
      "PrimaryDefinition": "laugh"
    },
    "Keys": {
      "Hanzi": 6680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6680,
      "Radical": "口",
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "人次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réncì",
      "PrimaryDefinition": "man-times, man-times (analogous to \"man-hours\")"
    },
    "Keys": {
      "Word": 8838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngmian",
      "PrimaryDefinition": "surface of, aspect, higher authority, upper side, the higher authorities, on top/surface of, on surface of, top, the higher-ups, top side, upside, on top of, respect, above, on top, regard"
    },
    "Keys": {
      "Word": 1868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "茶馆儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháguǎnr5",
      "PrimaryDefinition": "a teashop"
    },
    "Keys": {
      "Word": 5788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùmiǎn",
      "PrimaryDefinition": "unavoidably"
    },
    "Keys": {
      "Word": 3280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "自然界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìránjiè",
      "PrimaryDefinition": "nature, the natural world"
    },
    "Keys": {
      "Word": 10961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càn",
      "PrimaryDefinition": "vivid, illuminating; bright"
    },
    "Keys": {
      "Hanzi": 852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.3",
      "GeneralStandard": 852,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 2591,
      "StrokeCount": 7
    }
  },
  "透彻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòuchè",
      "PrimaryDefinition": "penetrating, thorough, incisive"
    },
    "Keys": {
      "Word": 9567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彩霞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎixiá",
      "PrimaryDefinition": "clouds tinged with sunset hues"
    },
    "Keys": {
      "Word": 5747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "take in, absorb; act as deputy"
    },
    "Keys": {
      "Hanzi": 2832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "GeneralStandard": 2832,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1403,
      "StrokeCount": 13
    }
  },
  "次日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìrì",
      "PrimaryDefinition": "next day, the morrow"
    },
    "Keys": {
      "Word": 6047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūshēng",
      "PrimaryDefinition": "be born"
    },
    "Keys": {
      "Word": 568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "把柄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎbǐng",
      "PrimaryDefinition": "handle, (fig.) information that can be used against sb"
    },
    "Keys": {
      "Word": 5462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò qu",
      "PrimaryDefinition": "go over, pass by"
    },
    "Keys": {
      "Word": 1537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "人力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénlì",
      "PrimaryDefinition": "strength, manpower, resources, manual labor"
    },
    "Keys": {
      "Word": 3840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "口香糖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuxiāngtáng",
      "PrimaryDefinition": "chewing gum"
    },
    "Keys": {
      "Word": 7847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "种类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒnglèi",
      "PrimaryDefinition": "kidney, description, race, form, nature, manner, variety, feather, ordering, ilk, style, class, order, stamp, type, genus, category, stripe, kind, strain, cast, shape, denomination, species, persuasion, league, classification, breed, sort"
    },
    "Keys": {
      "Word": 3182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "濡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "immerse, moisten; wet, damp"
    },
    "Keys": {
      "Hanzi": 6293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6293,
      "Radical": "水",
      "FrequencyRank": 4566,
      "RadicalIndex": "85.14",
      "StrokeCount": 17
    }
  },
  "侠义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáyì",
      "PrimaryDefinition": "chivalrous, chivalry, knight-errantry"
    },
    "Keys": {
      "Word": 9849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "防治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángzhì",
      "PrimaryDefinition": "prevent and cure, prophylaxis and treatment"
    },
    "Keys": {
      "Word": 3454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "弋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "shoot with a bow"
    },
    "Keys": {
      "Radical": 56,
      "Hanzi": 3504
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3504,
      "Radical": "弋",
      "FrequencyRank": 4265,
      "RadicalIndex": "56",
      "StrokeCount": 3
    }
  },
  "戳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuō",
      "PrimaryDefinition": "prick, stab; stamp, seal, chop"
    },
    "Keys": {
      "Hanzi": 3451,
      "Word": 6033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.14",
      "Grade": 7,
      "GeneralStandard": 3451,
      "Radical": "戈",
      "HSK": 7,
      "FrequencyRank": 2944,
      "StrokeCount": 18
    }
  },
  "痄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "scrofulous swellings and sores, mumps"
    },
    "Keys": {
      "Hanzi": 7041
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7041,
      "Radical": "疒",
      "FrequencyRank": 7731,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "纠缠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiūchán",
      "PrimaryDefinition": "to be in a tangle, to nag"
    },
    "Keys": {
      "Word": 7657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòu",
      "PrimaryDefinition": "fistula, sore, ulcer, goiter"
    },
    "Keys": {
      "Hanzi": 5783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5783,
      "Radical": "疒",
      "FrequencyRank": 5324,
      "RadicalIndex": "104.9",
      "StrokeCount": 14
    }
  },
  "绲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔn",
      "PrimaryDefinition": "cord; woven belt; hem; hemming"
    },
    "Keys": {
      "Hanzi": 5033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5033,
      "Radical": "糸",
      "FrequencyRank": 5646,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "挹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "to bale out; to decant liquids"
    },
    "Keys": {
      "Hanzi": 4418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4418,
      "Radical": "手",
      "FrequencyRank": 5899,
      "RadicalIndex": "64.7",
      "StrokeCount": 10
    }
  },
  "阵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "column, row or file of troops"
    },
    "Keys": {
      "Hanzi": 571,
      "Word": 3162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.4",
      "Grade": 4,
      "GeneralStandard": 571,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 788,
      "StrokeCount": 6
    }
  },
  "而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ér",
      "PrimaryDefinition": "and"
    },
    "Keys": {
      "Hanzi": 392,
      "Radical": 126,
      "Word": 2437
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "126",
      "Grade": 4,
      "GeneralStandard": 392,
      "Radical": "而",
      "HSK": 2,
      "FrequencyRank": 36,
      "StrokeCount": 6
    }
  },
  "子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "child"
    },
    "Keys": {
      "Hanzi": 64,
      "Radical": 39,
      "Word": 486
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "39",
      "Grade": 1,
      "GeneralStandard": 64,
      "Radical": "子",
      "HSK": 1,
      "FrequencyRank": 37,
      "StrokeCount": 3
    }
  },
  "阪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎn",
      "PrimaryDefinition": "hillside farmland; slope"
    },
    "Keys": {
      "Hanzi": 3640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3640,
      "Radical": "阜",
      "FrequencyRank": 3595,
      "RadicalIndex": "170.4",
      "StrokeCount": 6
    }
  },
  "民众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínzhòng",
      "PrimaryDefinition": "civil, crowd, masses, populace, rank_and_file, the masses, the masses of the people, the populace, demos, mob, multitude, plebs, commons, the common people, the people, million, public"
    },
    "Keys": {
      "Word": 8300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "攸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "distant, far; adverbial prefix"
    },
    "Keys": {
      "Hanzi": 3730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3730,
      "Radical": "攴",
      "FrequencyRank": 3416,
      "RadicalIndex": "66.3",
      "StrokeCount": 7
    }
  },
  "断裂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duànliè",
      "PrimaryDefinition": "fracture, rupture, to break apart"
    },
    "Keys": {
      "Word": 6374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地狱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìyù",
      "PrimaryDefinition": "the pit, hell, infernal, pandemonium, abyss, underworld, ballyhack, Hades, hades, blaze, Sheol, infernal region, Gehenna, Tartarus, Avernus, Topheth, Tophet, heck, nether region, Scheol, Acheron, perdition, the lower regions, Abaddon, netherworld, Hel, Inferno, inferno, Hell"
    },
    "Keys": {
      "Word": 6253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘴唇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǐchún",
      "PrimaryDefinition": "lip, lips, kisser"
    },
    "Keys": {
      "Word": 11017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "Daishan, one of the Five Sacred Mountains in China"
    },
    "Keys": {
      "Hanzi": 3956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3956,
      "Radical": "山",
      "FrequencyRank": 4088,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "皖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "Anhui province"
    },
    "Keys": {
      "Hanzi": 2699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2699,
      "Radical": "白",
      "FrequencyRank": 3247,
      "RadicalIndex": "106.7",
      "StrokeCount": 12
    }
  },
  "草地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎodì",
      "PrimaryDefinition": "lawn, meadow, sod, turf, CL:片[pian4]"
    },
    "Keys": {
      "Word": 546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "疱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pào",
      "PrimaryDefinition": "acne"
    },
    "Keys": {
      "Hanzi": 4619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4619,
      "Radical": "疒",
      "FrequencyRank": 4113,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "鉴别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànbié",
      "PrimaryDefinition": "to differentiate, to distinguish"
    },
    "Keys": {
      "Word": 7436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咧嘴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liě zuǐ",
      "PrimaryDefinition": "to grin"
    },
    "Keys": {
      "Word": 8048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "懒惰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎnduò",
      "PrimaryDefinition": "idle, lazy"
    },
    "Keys": {
      "Word": 7931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窗子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāngzi",
      "PrimaryDefinition": "window"
    },
    "Keys": {
      "Word": 2345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "嚅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "talk indistinctly and falteringly"
    },
    "Keys": {
      "Hanzi": 6226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6226,
      "Radical": "口",
      "FrequencyRank": 4156,
      "RadicalIndex": "30.14",
      "StrokeCount": 17
    }
  },
  "入侵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rùqīn",
      "PrimaryDefinition": "invade"
    },
    "Keys": {
      "Word": 8903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醒悟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐngwù",
      "PrimaryDefinition": "to come to oneself, to come to realize, to come to see the truth, to wake up to reality"
    },
    "Keys": {
      "Word": 10052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "紊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěn",
      "PrimaryDefinition": "confused, disorder"
    },
    "Keys": {
      "Hanzi": 2036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 2036,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 3113,
      "StrokeCount": 10
    }
  },
  "爆冷门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàolěngmén",
      "PrimaryDefinition": "an upset (esp. in sports), unexpected turn of events, to pull off a coup, a breakthrough"
    },
    "Keys": {
      "Word": 5544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "放假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng jià",
      "PrimaryDefinition": "have a day off, have a vacation, have day off, have or be on vacation, have or be on holiday, have a holiday, vacation"
    },
    "Keys": {
      "Word": 103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "难关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánguān",
      "PrimaryDefinition": "difficulty, crisis"
    },
    "Keys": {
      "Word": 8379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "组装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔzhuāng",
      "PrimaryDefinition": "to assemble and install"
    },
    "Keys": {
      "Word": 11007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "destroy, exterminate, annihilate"
    },
    "Keys": {
      "Hanzi": 3048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3048,
      "Radical": "刀",
      "FrequencyRank": 2632,
      "RadicalIndex": "18.14",
      "StrokeCount": 13
    }
  },
  "颟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mān",
      "PrimaryDefinition": "dawdling; thoughtless, careless"
    },
    "Keys": {
      "Hanzi": 6048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6048,
      "Radical": "頁",
      "FrequencyRank": 6248,
      "RadicalIndex": "181.1",
      "StrokeCount": 16
    }
  },
  "阑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "door screen; railing fence"
    },
    "Keys": {
      "Hanzi": 5266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5266,
      "Radical": "門",
      "FrequencyRank": 3716,
      "RadicalIndex": "169.9",
      "StrokeCount": 12
    }
  },
  "勋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "meritorious deed; merits; rank"
    },
    "Keys": {
      "Hanzi": 1506
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.7",
      "GeneralStandard": 1506,
      "Radical": "力",
      "HSK": 7,
      "FrequencyRank": 2349,
      "StrokeCount": 9
    }
  },
  "靥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "dimples"
    },
    "Keys": {
      "Hanzi": 5892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5892,
      "Radical": "厂",
      "FrequencyRank": 4853,
      "RadicalIndex": "27.13",
      "StrokeCount": 15
    }
  },
  "肛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "anus"
    },
    "Keys": {
      "Hanzi": 804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 804,
      "Radical": "肉",
      "FrequencyRank": 3394,
      "RadicalIndex": "130.3",
      "StrokeCount": 7
    }
  },
  "遏制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èzhì",
      "PrimaryDefinition": "to check, to contain, to hold back, to keep within limits, to constrain, to restrain"
    },
    "Keys": {
      "Word": 6417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "童话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tónghuà",
      "PrimaryDefinition": "fairy story, fairy tale, fairy-tale, children's stories, fairytale, fairy tales"
    },
    "Keys": {
      "Word": 2940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "哈哈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāha",
      "PrimaryDefinition": "sound of laughter, haha"
    },
    "Keys": {
      "Word": 1536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "任命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènmìng",
      "PrimaryDefinition": "to appoint and nominate, (job) appointment, CL:紙|纸[zhi3]"
    },
    "Keys": {
      "Word": 8863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热衷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèzhōng",
      "PrimaryDefinition": "to feel strongly about, to be fond of, obsession, deep commitment"
    },
    "Keys": {
      "Word": 8835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6743,
      "Radical": "女",
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "碰到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèngdào",
      "PrimaryDefinition": "come across, run_into, encounter, come_across, run into, touch, run_across, meet"
    },
    "Keys": {
      "Word": 903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "薁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "vine"
    },
    "Keys": {
      "Hanzi": 7756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7756,
      "Radical": "艸",
      "RadicalIndex": "140.13",
      "StrokeCount": 15
    }
  },
  "着力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó lì",
      "PrimaryDefinition": "to put effort into sth, to try really hard"
    },
    "Keys": {
      "Word": 10931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "riddle, conundrum; puzzle"
    },
    "Keys": {
      "Hanzi": 2468,
      "Word": 8261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.9",
      "Grade": 7,
      "GeneralStandard": 2468,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2471,
      "StrokeCount": 11
    }
  },
  "哲学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhéxué",
      "PrimaryDefinition": "ism, philosophic, philosophical, philosophy, school of thought, doctrine"
    },
    "Keys": {
      "Word": 5335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "缀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuì",
      "PrimaryDefinition": "patch together, link, connect"
    },
    "Keys": {
      "Hanzi": 2499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2499,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 3002,
      "StrokeCount": 11
    }
  },
  "没事儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méishìr",
      "PrimaryDefinition": "to have spare time, free from work, it's not important, it's nothing, never mind"
    },
    "Keys": {
      "Word": 233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "澭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7907,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "梢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "pointed tip of something long like a branch; rudder"
    },
    "Keys": {
      "Hanzi": 2225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "GeneralStandard": 2225,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2785,
      "StrokeCount": 11
    }
  },
  "辚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "rumbling of vehicles; threshold"
    },
    "Keys": {
      "Hanzi": 6080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6080,
      "Radical": "車",
      "FrequencyRank": 5973,
      "RadicalIndex": "159.12",
      "StrokeCount": 16
    }
  },
  "拖欠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōqiàn",
      "PrimaryDefinition": "in arrears, behind in payments, to default on one's debts"
    },
    "Keys": {
      "Word": 9613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赞扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànyáng",
      "PrimaryDefinition": "to praise, to approve of, to show approval"
    },
    "Keys": {
      "Word": 10603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "享": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng",
      "PrimaryDefinition": "enjoy"
    },
    "Keys": {
      "Hanzi": 1206,
      "Word": 9939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "8.6",
      "Grade": 7,
      "GeneralStandard": 1206,
      "Radical": "亠",
      "HSK": 5,
      "FrequencyRank": 1227,
      "StrokeCount": 8
    }
  },
  "恐怖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒngbù",
      "PrimaryDefinition": "fearful, horrible"
    },
    "Keys": {
      "Word": 7825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùrán",
      "PrimaryDefinition": "conj.: otherwise, if not, or else"
    },
    "Keys": {
      "Word": 2282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìng",
      "PrimaryDefinition": "illness, sickness, disease"
    },
    "Keys": {
      "Hanzi": 2027,
      "Word": 27
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.5",
      "Grade": 1,
      "GeneralStandard": 2027,
      "Radical": "疒",
      "HSK": 1,
      "FrequencyRank": 427,
      "StrokeCount": 10
    }
  },
  "父母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùmǔ",
      "PrimaryDefinition": "parental, parents, father and mother, folks"
    },
    "Keys": {
      "Word": 1460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "误差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùchā",
      "PrimaryDefinition": "difference, error, inaccuracy"
    },
    "Keys": {
      "Word": 9811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "书柜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūguì",
      "PrimaryDefinition": "bookcase"
    },
    "Keys": {
      "Word": 3923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "结冰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié bīng",
      "PrimaryDefinition": "to freeze"
    },
    "Keys": {
      "Word": 7525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掉头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào tóu",
      "PrimaryDefinition": "to turn one's head, to turn round, to turn about"
    },
    "Keys": {
      "Word": 6294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "hundred million; many"
    },
    "Keys": {
      "Hanzi": 44,
      "Word": 1152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.1",
      "Grade": 2,
      "GeneralStandard": 44,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 1057,
      "StrokeCount": 3
    }
  },
  "中午": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngwǔ",
      "PrimaryDefinition": "noon, midday, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "坦克": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎnkè",
      "PrimaryDefinition": "tank (military vehicle) (loanword)"
    },
    "Keys": {
      "Word": 9411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "lose; articles lost; omit"
    },
    "Keys": {
      "Hanzi": 2633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "GeneralStandard": 2633,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 892,
      "StrokeCount": 12
    }
  },
  "币": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "currency, coins, legal tender"
    },
    "Keys": {
      "Hanzi": 134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.1",
      "GeneralStandard": 134,
      "Radical": "巾",
      "HSK": 3,
      "FrequencyRank": 1188,
      "StrokeCount": 4
    }
  },
  "庄园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngyuán",
      "PrimaryDefinition": "a manor, feudal land, a villa and park"
    },
    "Keys": {
      "Word": 10905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "魉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎng",
      "PrimaryDefinition": "a kind of monster"
    },
    "Keys": {
      "Hanzi": 6132
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6132,
      "Radical": "鬼",
      "FrequencyRank": 5043,
      "RadicalIndex": "194.7",
      "StrokeCount": 16
    }
  },
  "隽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": "superior, outstanding, talented"
    },
    "Keys": {
      "Hanzi": 4575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4575,
      "Radical": "隹",
      "FrequencyRank": 4236,
      "RadicalIndex": "172.2",
      "StrokeCount": 10
    }
  },
  "各": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gè",
      "PrimaryDefinition": "each, individually, every, all"
    },
    "Keys": {
      "Hanzi": 506,
      "Word": 1487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 3,
      "GeneralStandard": 506,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 209,
      "StrokeCount": 6
    }
  },
  "㸌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "to twinkle; bright light in glimpses; flashing"
    },
    "Keys": {
      "Hanzi": 8064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8064,
      "Radical": "火",
      "RadicalIndex": "86.16",
      "StrokeCount": 20
    }
  },
  "愈来愈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùláiyù",
      "PrimaryDefinition": "even more, more_and_more, more and more"
    },
    "Keys": {
      "Word": 10530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "强迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎngpò",
      "PrimaryDefinition": "require, oust, press-gang, impose, obtrude, coerce, sandbag, coercion, force (sb. to do sth.), enforce, pressure, compel, force, compulsory, push out, hustle, constrain, blackmail, strain, starve, oblige, thrust out, thrust, bear, impel, bulldoze, dragoon, force-feed, blackjack, high-pressure"
    },
    "Keys": {
      "Word": 3810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "顺着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnzhe",
      "PrimaryDefinition": "to follow, following, along"
    },
    "Keys": {
      "Word": 9287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐng",
      "PrimaryDefinition": "drunk; intoxicated; tipsy"
    },
    "Keys": {
      "Hanzi": 5415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5415,
      "Radical": "酉",
      "FrequencyRank": 5183,
      "RadicalIndex": "164.6",
      "StrokeCount": 13
    }
  },
  "失利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī lì",
      "PrimaryDefinition": "to lose, to suffer defeat"
    },
    "Keys": {
      "Word": 9083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "central; center, middle; in the midst of; hit (target); attain"
    },
    "Keys": {
      "Hanzi": 113,
      "Word": 474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "2.3",
      "Grade": 1,
      "GeneralStandard": 113,
      "Radical": "丨",
      "HSK": 1,
      "FrequencyRank": 14,
      "StrokeCount": 4
    }
  },
  "叼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "holding in mouth"
    },
    "Keys": {
      "Hanzi": 252,
      "Word": 6286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 7,
      "GeneralStandard": 252,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 3703,
      "StrokeCount": 5
    }
  },
  "水货": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐhuò",
      "PrimaryDefinition": "smuggled goods, unauthorized goods"
    },
    "Keys": {
      "Word": 9263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5464,
      "Radical": "田",
      "FrequencyRank": 4207,
      "RadicalIndex": "102.8",
      "StrokeCount": 13
    }
  },
  "强硬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángyìng",
      "PrimaryDefinition": "tough, unyielding, hard-line"
    },
    "Keys": {
      "Word": 8691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烤鸭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎoyā",
      "PrimaryDefinition": "roast duck"
    },
    "Keys": {
      "Word": 3653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "附加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùjiā",
      "PrimaryDefinition": "hang on, supervene, suffix, additional, affix, superimpose, inset, tack on, tack, attachment, tag on, append, add, subjoin, annex, engraft, attach"
    },
    "Keys": {
      "Word": 6629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "banyan tree"
    },
    "Keys": {
      "Hanzi": 3083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3083,
      "Radical": "木",
      "FrequencyRank": 4127,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "台阶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táijiē",
      "PrimaryDefinition": "steps, flight of steps, step (over obstacle), fig. way out of an embarrassing situation, bench (geology)"
    },
    "Keys": {
      "Word": 2914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "枪毙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāngbì",
      "PrimaryDefinition": "to execute by firing squad, to shoot dead, fig. to discard, to get rid of"
    },
    "Keys": {
      "Word": 8687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䜣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "(simplified form of 訢) (same as 欣) joy; delight; happy"
    },
    "Keys": {
      "Hanzi": 6549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6549,
      "Radical": "",
      "FrequencyRank": 6088,
      "RadicalIndex": "",
      "StrokeCount": 11
    }
  },
  "莽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎng",
      "PrimaryDefinition": "thicket, underbrush; poisonous"
    },
    "Keys": {
      "Hanzi": 1821
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 1821,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2714,
      "StrokeCount": 10
    }
  },
  "舫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎng",
      "PrimaryDefinition": "fancy boat, yacht"
    },
    "Keys": {
      "Hanzi": 4585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4585,
      "Radical": "舟",
      "FrequencyRank": 4469,
      "RadicalIndex": "137.4",
      "StrokeCount": 10
    }
  },
  "企业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐyè",
      "PrimaryDefinition": "proposition, business enterprise, business organization, business concern, concern, commercial enterprise, endeavour, endeavor, industry, business, project, corporation, enterprise"
    },
    "Keys": {
      "Word": 2793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "宇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "house; building, structure; eaves"
    },
    "Keys": {
      "Hanzi": 545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.3",
      "GeneralStandard": 545,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 1156,
      "StrokeCount": 6
    }
  },
  "忞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6715,
      "Radical": "心",
      "RadicalIndex": "61.4",
      "StrokeCount": 8
    }
  },
  "雳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "thunderclap, crashing thunder"
    },
    "Keys": {
      "Hanzi": 2598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2598,
      "Radical": "雨",
      "FrequencyRank": 3550,
      "RadicalIndex": "173.4",
      "StrokeCount": 12
    }
  },
  "运营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnyíng",
      "PrimaryDefinition": "to be in operation, to do business, (of train, bus etc) to be in service, operation, service"
    },
    "Keys": {
      "Word": 10576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "sole"
    },
    "Keys": {
      "Hanzi": 5526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5526,
      "Radical": "魚",
      "FrequencyRank": 5028,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "筇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "bamboo name; bamboo staff"
    },
    "Keys": {
      "Hanzi": 4894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4894,
      "Radical": "竹",
      "FrequencyRank": 5976,
      "RadicalIndex": "118.6",
      "StrokeCount": 11
    }
  },
  "成长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngzhǎng",
      "PrimaryDefinition": "grow up, mature"
    },
    "Keys": {
      "Word": 1353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "偷偷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōutōu",
      "PrimaryDefinition": "on the QT, secretly, stealthily, on the Q.T., in secret"
    },
    "Keys": {
      "Word": 3986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "明明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngmíng",
      "PrimaryDefinition": "obviously, plainly"
    },
    "Keys": {
      "Word": 3727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "砖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuān",
      "PrimaryDefinition": "tile, brick"
    },
    "Keys": {
      "Hanzi": 1447,
      "Word": 10887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.4",
      "Grade": 7,
      "GeneralStandard": 1447,
      "Radical": "石",
      "HSK": 8,
      "FrequencyRank": 2355,
      "StrokeCount": 9
    }
  },
  "借助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièzhù",
      "PrimaryDefinition": "to draw support from, with the help of"
    },
    "Keys": {
      "Word": 7556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánmiàn",
      "PrimaryDefinition": "overall, comprehensive, all-round, all-out"
    },
    "Keys": {
      "Word": 1830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "自理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìlǐ",
      "PrimaryDefinition": "to take care of oneself, to provide for oneself"
    },
    "Keys": {
      "Word": 10956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnzhōng",
      "PrimaryDefinition": "central point, in one's thoughts, in one's heart"
    },
    "Keys": {
      "Word": 1104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "空气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngqì",
      "PrimaryDefinition": "air, airspace, atmosphere, aerial, deflector, atmospherics"
    },
    "Keys": {
      "Word": 820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "保管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoguǎn",
      "PrimaryDefinition": "to hold in safekeeping, to have in one's care, to guarantee, certainly, surely, custodian, curator"
    },
    "Keys": {
      "Word": 5519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "里头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐtou",
      "PrimaryDefinition": "inside, interior"
    },
    "Keys": {
      "Word": 839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "敦厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūnhòu",
      "PrimaryDefinition": "genuine, honest and sincere"
    },
    "Keys": {
      "Word": 6388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "river in qinghai province"
    },
    "Keys": {
      "Hanzi": 5286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5286,
      "Radical": "水",
      "FrequencyRank": 4862,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "缬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "patterned silk; tie knot"
    },
    "Keys": {
      "Hanzi": 6021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6021,
      "Radical": "糸",
      "FrequencyRank": 5169,
      "RadicalIndex": "120.12",
      "StrokeCount": 15
    }
  },
  "灾区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāiqū",
      "PrimaryDefinition": "disaster area, stricken region"
    },
    "Keys": {
      "Word": 4169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "兴奋剂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngfènjì",
      "PrimaryDefinition": "stimulant, doping (in athletics)"
    },
    "Keys": {
      "Word": 10040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "门槛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménkǎn",
      "PrimaryDefinition": "doorstep, sill, threshold, fig. knack or trick (esp. scheme to get sth cheaper)"
    },
    "Keys": {
      "Word": 8243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxíng",
      "PrimaryDefinition": "to become shaped, to become formed"
    },
    "Keys": {
      "Word": 5874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "除此之外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúcǐzhīwài",
      "PrimaryDefinition": "additionally, in addition, with the exception of this, in_addition, to boot, excepting this"
    },
    "Keys": {
      "Word": 5983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牵涉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānshè",
      "PrimaryDefinition": "involve, drag in"
    },
    "Keys": {
      "Word": 8653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng",
      "PrimaryDefinition": "measure, quantity, capacity"
    },
    "Keys": {
      "Hanzi": 2616,
      "Word": 2698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "166.5",
      "Grade": 4,
      "GeneralStandard": 2616,
      "Radical": "里",
      "HSK": 2,
      "FrequencyRank": 241,
      "StrokeCount": 12
    }
  },
  "庸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "usual, common, ordinary, mediocre"
    },
    "Keys": {
      "Hanzi": 2386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.8",
      "GeneralStandard": 2386,
      "Radical": "广",
      "HSK": 9,
      "FrequencyRank": 2148,
      "StrokeCount": 11
    }
  },
  "胆小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎnxiǎo",
      "PrimaryDefinition": "cowardice, timid"
    },
    "Keys": {
      "Word": 3397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "品行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐnxíng",
      "PrimaryDefinition": "behavior, moral conduct"
    },
    "Keys": {
      "Word": 8540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扩展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòzhǎn",
      "PrimaryDefinition": "expand, spread, extend"
    },
    "Keys": {
      "Word": 2673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "垂头丧气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuítóu-sàngqì",
      "PrimaryDefinition": "hanging one's head dispiritedly (idiom), dejected, crestfallen"
    },
    "Keys": {
      "Word": 6025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bū",
      "PrimaryDefinition": "flee, run away, leave debt unsettled"
    },
    "Keys": {
      "Hanzi": 4465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4465,
      "Radical": "辵",
      "FrequencyRank": 5074,
      "RadicalIndex": "162.7",
      "StrokeCount": 10
    }
  },
  "客车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèchē",
      "PrimaryDefinition": "coach, bus, passenger train"
    },
    "Keys": {
      "Word": 4794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "箴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "needle, probe; admon"
    },
    "Keys": {
      "Hanzi": 5955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5955,
      "Radical": "竹",
      "FrequencyRank": 4285,
      "RadicalIndex": "118.9",
      "StrokeCount": 15
    }
  },
  "机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "desk; machine; moment"
    },
    "Keys": {
      "Hanzi": 377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "GeneralStandard": 377,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 111,
      "StrokeCount": 6
    }
  },
  "逛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàng",
      "PrimaryDefinition": "ramble, stroll, roam, wander"
    },
    "Keys": {
      "Hanzi": 2003,
      "Word": 2519
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 4,
      "GeneralStandard": 2003,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 2642,
      "StrokeCount": 10
    }
  },
  "撤离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèlí",
      "PrimaryDefinition": "to withdraw from, to evacuate"
    },
    "Keys": {
      "Word": 4408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鼫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "marmot; squirrel"
    },
    "Keys": {
      "Hanzi": 8010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8010,
      "Radical": "鼠",
      "FrequencyRank": 8747,
      "RadicalIndex": "208.5",
      "StrokeCount": 18
    }
  },
  "意想不到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìxiǎngbùdào",
      "PrimaryDefinition": "unexpected, previously unimagined"
    },
    "Keys": {
      "Word": 5248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鹫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "condor, vulture"
    },
    "Keys": {
      "Hanzi": 6285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6285,
      "Radical": "鳥",
      "FrequencyRank": 4384,
      "RadicalIndex": "196.12",
      "StrokeCount": 17
    }
  },
  "驳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "varicolored, variegated; mixed"
    },
    "Keys": {
      "Hanzi": 935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.4",
      "GeneralStandard": 935,
      "Radical": "馬",
      "HSK": 8,
      "FrequencyRank": 2022,
      "StrokeCount": 7
    }
  },
  "生长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngzhǎng",
      "PrimaryDefinition": "grow, develop"
    },
    "Keys": {
      "Word": 1888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "珝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6940,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "出发点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūfādiǎn",
      "PrimaryDefinition": "terminus a quo, base, takeoff, starting_point, start, starting_post, beachhead, springboard, starting, starting point, starting post"
    },
    "Keys": {
      "Word": 5954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外婆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàipó",
      "PrimaryDefinition": "granny, maternal grandmother, grandma, grandmother"
    },
    "Keys": {
      "Word": 9644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": "capture, seize"
    },
    "Keys": {
      "Hanzi": 4720
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4720,
      "Radical": "手",
      "FrequencyRank": 3468,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "宝宝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎobao",
      "PrimaryDefinition": "pet, sonny, darling baby, precious baby, baby, precious, poppet, sweetie, precious/darling baby"
    },
    "Keys": {
      "Word": 2249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "获胜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò shèng",
      "PrimaryDefinition": "triumphant, triumph, beat, win victory, be victorious, conquer, gain, prevail, win"
    },
    "Keys": {
      "Word": 7256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "十分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shífēn",
      "PrimaryDefinition": "very, fully, utterly, extremely"
    },
    "Keys": {
      "Word": 972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "山岭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānlǐng",
      "PrimaryDefinition": "mountain ridge"
    },
    "Keys": {
      "Word": 8947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "lead"
    },
    "Keys": {
      "Hanzi": 1934,
      "Word": 8656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "Grade": 7,
      "GeneralStandard": 1934,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 2641,
      "StrokeCount": 10
    }
  },
  "甜头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiántou",
      "PrimaryDefinition": "sweet taste (of power, success etc), benefit"
    },
    "Keys": {
      "Word": 9494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "少儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàoér",
      "PrimaryDefinition": "child"
    },
    "Keys": {
      "Word": 5002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "婘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7271,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "夹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "be wedged or inserted between"
    },
    "Keys": {
      "Hanzi": 402,
      "Word": 3587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.5",
      "Grade": 5,
      "GeneralStandard": 402,
      "Radical": "一",
      "HSK": 5,
      "FrequencyRank": 1758,
      "StrokeCount": 6
    }
  },
  "影迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngmí",
      "PrimaryDefinition": "film enthusiast, movie fan, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 5261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "含糊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánhu",
      "PrimaryDefinition": "ambiguous, vague, careless, perfunctory"
    },
    "Keys": {
      "Word": 6980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "lay down; retire from office"
    },
    "Keys": {
      "Hanzi": 1536,
      "Word": 10004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "26.6",
      "Grade": 7,
      "GeneralStandard": 1536,
      "Radical": "卩",
      "HSK": 8,
      "FrequencyRank": 2479,
      "StrokeCount": 9
    }
  },
  "翘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "turn up, lift, elevate, raise"
    },
    "Keys": {
      "Hanzi": 2601,
      "Word": 8705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "124.6",
      "Grade": 7,
      "GeneralStandard": 2601,
      "Radical": "羽",
      "HSK": 7,
      "FrequencyRank": 2992,
      "StrokeCount": 12
    }
  },
  "绌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "sew, stitch; insufficient"
    },
    "Keys": {
      "Hanzi": 4080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4080,
      "Radical": "糸",
      "FrequencyRank": 4831,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "蠼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8105,
      "Radical": "虫",
      "FrequencyRank": 4767,
      "RadicalIndex": "142.2",
      "StrokeCount": 26
    }
  },
  "年龄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánlíng",
      "PrimaryDefinition": "summer, age"
    },
    "Keys": {
      "Word": 3745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "belt, girdle, band, strap, zone"
    },
    "Keys": {
      "Hanzi": 1407,
      "Word": 596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.6",
      "Grade": 2,
      "GeneralStandard": 1407,
      "Radical": "巾",
      "HSK": 2,
      "FrequencyRank": 342,
      "StrokeCount": 9
    }
  },
  "佛教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Fójiào",
      "PrimaryDefinition": "Bodhisattva, Buddhist religion, Buddhism"
    },
    "Keys": {
      "Word": 4568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "常见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángjiàn",
      "PrimaryDefinition": "commonly seen"
    },
    "Keys": {
      "Word": 552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "邶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3693,
      "Radical": "邑",
      "FrequencyRank": 7183,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "鹧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè",
      "PrimaryDefinition": "partridge"
    },
    "Keys": {
      "Hanzi": 6151
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6151,
      "Radical": "鳥",
      "FrequencyRank": 5306,
      "RadicalIndex": "196.11",
      "StrokeCount": 16
    }
  },
  "手续费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuxùfèi",
      "PrimaryDefinition": "service charge, processing fee, commission"
    },
    "Keys": {
      "Word": 5032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鸬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "cormorant"
    },
    "Keys": {
      "Hanzi": 4490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4490,
      "Radical": "鳥",
      "FrequencyRank": 4640,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "锁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒ",
      "PrimaryDefinition": "lock, padlock; shackles, chains"
    },
    "Keys": {
      "Hanzi": 2660,
      "Word": 3954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "Grade": 5,
      "GeneralStandard": 2660,
      "Radical": "金",
      "HSK": 5,
      "FrequencyRank": 1558,
      "StrokeCount": 12
    }
  },
  "单边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānbiān",
      "PrimaryDefinition": "unilateral"
    },
    "Keys": {
      "Word": 6158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎofèi",
      "PrimaryDefinition": "tip, gratuity"
    },
    "Keys": {
      "Word": 5169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "事业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyè",
      "PrimaryDefinition": "proposition, task, pidgin, institution, undertaking, deed, facilities, life history, endeavour, endeavor, cause, career, project, action, enterprise"
    },
    "Keys": {
      "Word": 1915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "阮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎn",
      "PrimaryDefinition": "ancient musical instrument: surname"
    },
    "Keys": {
      "Hanzi": 3639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3639,
      "Radical": "阜",
      "FrequencyRank": 3210,
      "RadicalIndex": "170.4",
      "StrokeCount": 6
    }
  },
  "娇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "seductive and loveable; tender"
    },
    "Keys": {
      "Hanzi": 1739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "GeneralStandard": 1739,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 2158,
      "StrokeCount": 9
    }
  },
  "会议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìyì",
      "PrimaryDefinition": "synod, moot, conference, board, convocation, soviet, congress, talks, indaba, negotiation, junta, group discussion, convention, meeting place, session, council, rendezvous, colloquy, coming together, powwow, seance, meeting, forum, consultation, roll-up, get-together, assembly"
    },
    "Keys": {
      "Word": 1565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "朐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "warm"
    },
    "Keys": {
      "Hanzi": 4287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4287,
      "Radical": "月",
      "FrequencyRank": 5482,
      "RadicalIndex": "74.5",
      "StrokeCount": 9
    }
  },
  "它": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "it; other"
    },
    "Keys": {
      "Hanzi": 314,
      "Word": 1005
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.2",
      "Grade": 2,
      "GeneralStandard": 314,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 107,
      "StrokeCount": 5
    }
  },
  "蹼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "webbed feet of waterfowl"
    },
    "Keys": {
      "Hanzi": 6386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6386,
      "Radical": "足",
      "FrequencyRank": 5104,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "血液": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuèyè",
      "PrimaryDefinition": "blood"
    },
    "Keys": {
      "Word": 5204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "具备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùbèi",
      "PrimaryDefinition": "be provided with, supply, render, have, furnish, possess, provide, possession"
    },
    "Keys": {
      "Word": 2651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蹁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pián",
      "PrimaryDefinition": "to walk with a limp"
    },
    "Keys": {
      "Hanzi": 6096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6096,
      "Radical": "足",
      "FrequencyRank": 6646,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "传说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánshuō",
      "PrimaryDefinition": "legend"
    },
    "Keys": {
      "Word": 1368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "乱七八糟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luànqībāzāo",
      "PrimaryDefinition": "everything in disorder (idiom), in a hideous mess, at sixes and sevens"
    },
    "Keys": {
      "Word": 8152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "献": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "offer, present; show, display"
    },
    "Keys": {
      "Hanzi": 2861,
      "Word": 4036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.9",
      "Grade": 5,
      "GeneralStandard": 2861,
      "Radical": "犬",
      "HSK": 5,
      "FrequencyRank": 1192,
      "StrokeCount": 13
    }
  },
  "槐树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáishù",
      "PrimaryDefinition": "locust tree (Sophora japonica)"
    },
    "Keys": {
      "Word": 7158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngwǎng",
      "PrimaryDefinition": "to lead to"
    },
    "Keys": {
      "Word": 9527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "the spirit of the earth; (used for 只) only, merely, but"
    },
    "Keys": {
      "Hanzi": 4050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4050,
      "Radical": "示",
      "FrequencyRank": 5064,
      "RadicalIndex": "113.4",
      "StrokeCount": 8
    }
  },
  "平日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngrì",
      "PrimaryDefinition": "ordinary day, everyday, ordinarily, usually"
    },
    "Keys": {
      "Word": 8555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuō",
      "PrimaryDefinition": "to get ahead; to stride; to excel"
    },
    "Keys": {
      "Hanzi": 5911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5911,
      "Radical": "足",
      "FrequencyRank": 5159,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "跚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "stagger, limp"
    },
    "Keys": {
      "Hanzi": 5138
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5138,
      "Radical": "足",
      "FrequencyRank": 3888,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "walk"
    },
    "Keys": {
      "Hanzi": 483,
      "Radical": 144,
      "Word": 1541
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "144",
      "Grade": 3,
      "GeneralStandard": 483,
      "Radical": "行",
      "HSK": 1,
      "FrequencyRank": 53,
      "StrokeCount": 6
    }
  },
  "字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zì",
      "PrimaryDefinition": "letter, character, word"
    },
    "Keys": {
      "Hanzi": 548,
      "Word": 485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "39.3",
      "Grade": 1,
      "GeneralStandard": 548,
      "Radical": "子",
      "HSK": 1,
      "FrequencyRank": 393,
      "StrokeCount": 6
    }
  },
  "狠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hěn",
      "PrimaryDefinition": "vicious, cruel; severely, extreme"
    },
    "Keys": {
      "Hanzi": 1606,
      "Word": 4665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.6",
      "Grade": 6,
      "GeneralStandard": 1606,
      "Radical": "犬",
      "HSK": 6,
      "FrequencyRank": 1654,
      "StrokeCount": 9
    }
  },
  "酒鬼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔguǐ",
      "PrimaryDefinition": "drunkard"
    },
    "Keys": {
      "Word": 3634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "睫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "eyelashes"
    },
    "Keys": {
      "Hanzi": 2899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2899,
      "Radical": "目",
      "FrequencyRank": 3415,
      "RadicalIndex": "109.8",
      "StrokeCount": 13
    }
  },
  "后裔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuyì",
      "PrimaryDefinition": "descendant"
    },
    "Keys": {
      "Word": 7101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "修正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūzhèng",
      "PrimaryDefinition": "revise, amend, correct"
    },
    "Keys": {
      "Word": 10078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "槊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "spear, lance"
    },
    "Keys": {
      "Hanzi": 5796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5796,
      "Radical": "木",
      "FrequencyRank": 5300,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "吸收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīshōu",
      "PrimaryDefinition": "absorb, suck up, assimilate, recruit"
    },
    "Keys": {
      "Word": 2996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "圫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6524,
      "Radical": "土",
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "鲜艳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānyàn",
      "PrimaryDefinition": "bright-colored, gaily-colored"
    },
    "Keys": {
      "Word": 4031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "秒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "beard of grain or corn; a second"
    },
    "Keys": {
      "Hanzi": 1547,
      "Word": 3723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.4",
      "Grade": 5,
      "GeneralStandard": 1547,
      "Radical": "禾",
      "HSK": 5,
      "FrequencyRank": 2028,
      "StrokeCount": 9
    }
  },
  "几率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīlǜ",
      "PrimaryDefinition": "probability, odds"
    },
    "Keys": {
      "Word": 7263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拥挤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōngjǐ",
      "PrimaryDefinition": "crowded, pushed and squeezed together"
    },
    "Keys": {
      "Word": 10430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煽动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāndòng",
      "PrimaryDefinition": "to incite, to instigate"
    },
    "Keys": {
      "Word": 8953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "空地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng-dì",
      "PrimaryDefinition": "gap, area, open, vacant lot, clear, open ground/space, vacancy, open ground, building site, open space, space, frontage, sandlot, clearing, opening"
    },
    "Keys": {
      "Word": 7831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "enjoy, be glad, be pleased"
    },
    "Keys": {
      "Hanzi": 4034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4034,
      "Radical": "心",
      "FrequencyRank": 6297,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "东道主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngdàozhǔ",
      "PrimaryDefinition": "host, official host (e.g. venue for games or a conference)"
    },
    "Keys": {
      "Word": 6324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǔ",
      "PrimaryDefinition": "parched wheat or rice; broken grain"
    },
    "Keys": {
      "Hanzi": 6167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6167,
      "Radical": "米",
      "FrequencyRank": 6352,
      "RadicalIndex": "119.1",
      "StrokeCount": 16
    }
  },
  "滋味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīwèi",
      "PrimaryDefinition": "relish, richness, tang, sapidity, sapor, tinge, mouthful, savor, tasting, smack, flavor, savour, zest, taste, flavour"
    },
    "Keys": {
      "Word": 10947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "a kind of jade"
    },
    "Keys": {
      "Hanzi": 7832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7832,
      "Radical": "玉",
      "RadicalIndex": "96.13",
      "StrokeCount": 16
    }
  },
  "渡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "cross, ferry over; ferry"
    },
    "Keys": {
      "Hanzi": 2765,
      "Word": 4524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 6,
      "GeneralStandard": 2765,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1406,
      "StrokeCount": 12
    }
  },
  "凉水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángshuǐ",
      "PrimaryDefinition": "cool water, unboiled water"
    },
    "Keys": {
      "Word": 1710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "凇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "dewdrop; icicle"
    },
    "Keys": {
      "Hanzi": 4610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4610,
      "Radical": "冫",
      "FrequencyRank": 4506,
      "RadicalIndex": "15.8",
      "StrokeCount": 10
    }
  },
  "锡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "tin, stannum; bestow, confer"
    },
    "Keys": {
      "Hanzi": 2938,
      "Word": 9826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.8",
      "Grade": 7,
      "GeneralStandard": 2938,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 2058,
      "StrokeCount": 13
    }
  },
  "悔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǐ",
      "PrimaryDefinition": "repent, show remorse, regret"
    },
    "Keys": {
      "Hanzi": 2094
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "GeneralStandard": 2094,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1853,
      "StrokeCount": 10
    }
  },
  "措施": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòshī",
      "PrimaryDefinition": "measure, demarche, corrective, move, step"
    },
    "Keys": {
      "Word": 2357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "图片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túpiàn",
      "PrimaryDefinition": "pictorial matter, picture, photograph, pic, artwork"
    },
    "Keys": {
      "Word": 1041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "人工智能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réngōngzhìnéng",
      "PrimaryDefinition": "artificial intelligence (AI)"
    },
    "Keys": {
      "Word": 8840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "到来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàolái",
      "PrimaryDefinition": "arrive"
    },
    "Keys": {
      "Word": 3406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "挑衅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎoxìn",
      "PrimaryDefinition": "to provoke, provocation"
    },
    "Keys": {
      "Word": 9504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "行使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngshǐ",
      "PrimaryDefinition": "exercise, perform"
    },
    "Keys": {
      "Word": 10046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōng",
      "PrimaryDefinition": "onomatopoetic, a thumping sound"
    },
    "Keys": {
      "Hanzi": 3916
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3916,
      "Radical": "口",
      "FrequencyRank": 2950,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "宣誓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān shì",
      "PrimaryDefinition": "to swear an oath (of office), to make a vow"
    },
    "Keys": {
      "Word": 10110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "防止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángzhǐ",
      "PrimaryDefinition": "estop, guard against, preclude, prevention, inhibit, avoidance, help, cumber, prevent, forbid, prohibit, obviate, restrain, deter, avoid, head_off, debar, guard, help oneself, ward_off, constrain, ward, encumber, avert, ward off, disenable, forfend, shield, countercheck, forestall, keep_from, foreclose"
    },
    "Keys": {
      "Word": 1443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "乃至": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎizhì",
      "PrimaryDefinition": "conj.: even, go so far as to"
    },
    "Keys": {
      "Word": 8368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "季节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìjié",
      "PrimaryDefinition": "season"
    },
    "Keys": {
      "Word": 2594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "退让": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuìràng",
      "PrimaryDefinition": "to move aside, to get out of the way, to back down, to concede"
    },
    "Keys": {
      "Word": 9606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "博": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "gamble, play games; wide, broad"
    },
    "Keys": {
      "Hanzi": 2521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.1",
      "GeneralStandard": 2521,
      "Radical": "十",
      "HSK": 5,
      "FrequencyRank": 965,
      "StrokeCount": 12
    }
  },
  "炊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuī",
      "PrimaryDefinition": "cook; meal"
    },
    "Keys": {
      "Hanzi": 1235
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.4",
      "GeneralStandard": 1235,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 3333,
      "StrokeCount": 8
    }
  },
  "军人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnrén",
      "PrimaryDefinition": "soldiery, soldier, swordsman, buffcoat, armyman, serviceman, military"
    },
    "Keys": {
      "Word": 3643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "丧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàng",
      "PrimaryDefinition": "mourning; mourn; funeral"
    },
    "Keys": {
      "Hanzi": 1034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.6",
      "GeneralStandard": 1034,
      "Radical": "十",
      "HSK": 6,
      "FrequencyRank": 1404,
      "StrokeCount": 8
    }
  },
  "贪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tān",
      "PrimaryDefinition": "be greedy, covet; covetous"
    },
    "Keys": {
      "Hanzi": 1176,
      "Word": 9395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "Grade": 7,
      "GeneralStandard": 1176,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 1874,
      "StrokeCount": 8
    }
  },
  "瑓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7462,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "身心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnxīn",
      "PrimaryDefinition": "body and mind"
    },
    "Keys": {
      "Word": 9023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "strong, powerful, energetic"
    },
    "Keys": {
      "Hanzi": 2802,
      "Word": 1811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.9",
      "Grade": 3,
      "GeneralStandard": 2802,
      "Radical": "弓",
      "HSK": 3,
      "FrequencyRank": 292,
      "StrokeCount": 12
    }
  },
  "看起来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànqilai",
      "PrimaryDefinition": "it looks as if, on the face of it, it appears, it seems, ostensibly, seemingly, apparently, it look as if"
    },
    "Keys": {
      "Word": 1667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "埫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7111,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "牙膏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yágāo",
      "PrimaryDefinition": "toothpaste, CL:管[guan3]"
    },
    "Keys": {
      "Word": 10163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乞讨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐtǎo",
      "PrimaryDefinition": "to beg, to go begging"
    },
    "Keys": {
      "Word": 8606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "印章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìnzhāng",
      "PrimaryDefinition": "seal, signet, chop, stamp, CL:方[fang1]"
    },
    "Keys": {
      "Word": 10406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōbushàng",
      "PrimaryDefinition": "to be unable to say or tell, to not be worth mentioning"
    },
    "Keys": {
      "Word": 9290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìn",
      "PrimaryDefinition": "rent, hire; hired person"
    },
    "Keys": {
      "Hanzi": 1965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "GeneralStandard": 1965,
      "Radical": "貝",
      "HSK": 8,
      "FrequencyRank": 3101,
      "StrokeCount": 10
    }
  },
  "鸻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4913,
      "Radical": "鳥",
      "FrequencyRank": 6392,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "触目惊心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùmù-jīngxīn",
      "PrimaryDefinition": "lit. shocks the eye, astonishes the heart (idiom), shocking, horrible to see, a ghastly sight"
    },
    "Keys": {
      "Word": 5995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìduàn",
      "PrimaryDefinition": "section, district"
    },
    "Keys": {
      "Word": 6249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎn",
      "PrimaryDefinition": "upright and strong; amiable"
    },
    "Keys": {
      "Hanzi": 3958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 3958,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 3315,
      "StrokeCount": 8
    }
  },
  "崭新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnxīn",
      "PrimaryDefinition": "newly, bran-new, spick-and-span, spic-and-span, brand-new, spick and span, completely new"
    },
    "Keys": {
      "Word": 10645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "thread; detailed, precise"
    },
    "Keys": {
      "Hanzi": 2820,
      "Word": 8143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "Grade": 7,
      "GeneralStandard": 2820,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2894,
      "StrokeCount": 12
    }
  },
  "天下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānxià",
      "PrimaryDefinition": "rule, world, China, domination, everywhere under heaven"
    },
    "Keys": {
      "Word": 5083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "助威": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù wēi",
      "PrimaryDefinition": "to cheer for, to encourage, to boost the morale of"
    },
    "Keys": {
      "Word": 10865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倒数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàoshǔ",
      "PrimaryDefinition": "to count backwards (from 10 down to 0), to count down, from the bottom (lines on a page), from the back (rows of seats), inverse number, reciprocal (math.)"
    },
    "Keys": {
      "Word": 6201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发泄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāxiè",
      "PrimaryDefinition": "to give vent to (one's feelings)"
    },
    "Keys": {
      "Word": 6449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "change"
    },
    "Keys": {
      "Hanzi": 1806,
      "Word": 735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 2,
      "GeneralStandard": 1806,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 824,
      "StrokeCount": 10
    }
  },
  "萝卜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luóbo",
      "PrimaryDefinition": "radish (Raphanus sativus), esp. white radish 白蘿蔔|白萝卜[bai2 luo2 bo5], CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 8165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "形状": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngzhuàng",
      "PrimaryDefinition": "set, figuration, physiognomy, form, appearance, shape"
    },
    "Keys": {
      "Word": 2059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "彻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "penetrate, pervade; penetrating"
    },
    "Keys": {
      "Hanzi": 792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.4",
      "GeneralStandard": 792,
      "Radical": "彳",
      "HSK": 4,
      "FrequencyRank": 1240,
      "StrokeCount": 7
    }
  },
  "被": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2118,
      "Word": 1292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.5",
      "Grade": 3,
      "GeneralStandard": 2118,
      "Radical": "衣",
      "HSK": 3,
      "FrequencyRank": 154,
      "StrokeCount": 10
    }
  },
  "教材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàocái",
      "PrimaryDefinition": "textbook, teaching material"
    },
    "Keys": {
      "Word": 1612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "弯曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wānqū",
      "PrimaryDefinition": "distort"
    },
    "Keys": {
      "Word": 5126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "净": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "clean, pure; cleanse"
    },
    "Keys": {
      "Hanzi": 1221,
      "Word": 4756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.6",
      "Grade": 6,
      "GeneralStandard": 1221,
      "Radical": "冫",
      "HSK": 1,
      "FrequencyRank": 1377,
      "StrokeCount": 8
    }
  },
  "掷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "throw, hurl, cast, fling"
    },
    "Keys": {
      "Hanzi": 2186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2186,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2614,
      "StrokeCount": 11
    }
  },
  "平常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngcháng",
      "PrimaryDefinition": "ordinary, common"
    },
    "Keys": {
      "Word": 912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "酌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "serve wine; feast; deliberate"
    },
    "Keys": {
      "Hanzi": 1857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.3",
      "GeneralStandard": 1857,
      "Radical": "酉",
      "HSK": 9,
      "FrequencyRank": 2984,
      "StrokeCount": 10
    }
  },
  "郐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6706,
      "Radical": "邑",
      "FrequencyRank": 7228,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "彡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "hair"
    },
    "Keys": {
      "Radical": 59
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "大片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàpiàn",
      "PrimaryDefinition": "wide expanse, large area, vast stretch, extending widely, blockbuster movie"
    },
    "Keys": {
      "Word": 6128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "入境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù jìng",
      "PrimaryDefinition": "enter a country"
    },
    "Keys": {
      "Word": 8904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "ram, he-goat"
    },
    "Keys": {
      "Hanzi": 7228
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7228,
      "Radical": "羊",
      "FrequencyRank": 6337,
      "RadicalIndex": "123.5",
      "StrokeCount": 11
    }
  },
  "不耻下问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùchǐ-xiàwèn",
      "PrimaryDefinition": "not feel ashamed to ask and learn from one's subordinates"
    },
    "Keys": {
      "Word": 5689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "二手车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èrshǒuchē",
      "PrimaryDefinition": "second-hand car"
    },
    "Keys": {
      "Word": 6428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "壑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "bed of torrent, narrow ravine"
    },
    "Keys": {
      "Hanzi": 6223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6223,
      "Radical": "土",
      "FrequencyRank": 3880,
      "RadicalIndex": "32.14",
      "StrokeCount": 17
    }
  },
  "课堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kètáng",
      "PrimaryDefinition": "classroom, CL:間|间[jian1]"
    },
    "Keys": {
      "Word": 819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蛇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shé",
      "PrimaryDefinition": "snake"
    },
    "Keys": {
      "Hanzi": 2280,
      "Word": 3869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.5",
      "Grade": 5,
      "GeneralStandard": 2280,
      "Radical": "虫",
      "HSK": 5,
      "FrequencyRank": 1689,
      "StrokeCount": 11
    }
  },
  "同一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngyī",
      "PrimaryDefinition": "identical, the same"
    },
    "Keys": {
      "Word": 5099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "泠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "nice and cool, mild and comfortable"
    },
    "Keys": {
      "Hanzi": 4016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4016,
      "Radical": "水",
      "FrequencyRank": 5602,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "表扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎoyáng",
      "PrimaryDefinition": "praise, commend"
    },
    "Keys": {
      "Word": 2271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "新娘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnniáng",
      "PrimaryDefinition": "bride"
    },
    "Keys": {
      "Word": 3035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "赅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāi",
      "PrimaryDefinition": "prepared for; inclusive"
    },
    "Keys": {
      "Hanzi": 4526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4526,
      "Radical": "貝",
      "FrequencyRank": 5401,
      "RadicalIndex": "154.6",
      "StrokeCount": 10
    }
  },
  "户外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùwài",
      "PrimaryDefinition": "air, outdoor, open_air, out-of-doors, open, outdoors, open air, open-air"
    },
    "Keys": {
      "Word": 4670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "矗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "straight, upright, erect, lofty"
    },
    "Keys": {
      "Hanzi": 3500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3500,
      "Radical": "目",
      "FrequencyRank": 3029,
      "RadicalIndex": "109.19",
      "StrokeCount": 24
    }
  },
  "㻬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū",
      "PrimaryDefinition": "a kind of jade"
    },
    "Keys": {
      "Hanzi": 7747
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7747,
      "Radical": "玉",
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "柜子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guìzi",
      "PrimaryDefinition": "cupboard, cabinet"
    },
    "Keys": {
      "Word": 3524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiě",
      "PrimaryDefinition": "write; draw, sketch; compose"
    },
    "Keys": {
      "Hanzi": 316,
      "Word": 406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "14.3",
      "Grade": 1,
      "GeneralStandard": 316,
      "Radical": "冖",
      "HSK": 1,
      "FrequencyRank": 448,
      "StrokeCount": 5
    }
  },
  "岙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "island"
    },
    "Keys": {
      "Hanzi": 6600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6600,
      "Radical": "山",
      "FrequencyRank": 6495,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "疳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "childhood diseases"
    },
    "Keys": {
      "Hanzi": 4614
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4614,
      "Radical": "疒",
      "FrequencyRank": 5988,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "运转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnzhuǎn",
      "PrimaryDefinition": "revolve, turn round, work, operate"
    },
    "Keys": {
      "Word": 10577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "steep bank by stream; jiujiang"
    },
    "Keys": {
      "Hanzi": 4346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4346,
      "Radical": "水",
      "FrequencyRank": 4870,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "请问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐngwèn",
      "PrimaryDefinition": "Excuse me, may I ask...?"
    },
    "Keys": {
      "Word": 295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "郇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "an ancient feudal State in Shaanxi"
    },
    "Keys": {
      "Hanzi": 3984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3984,
      "Radical": "邑",
      "FrequencyRank": 7096,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "绘声绘色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìshēng-huìsè",
      "PrimaryDefinition": "vivid and colorful (idiom), true to life, lively and realistic"
    },
    "Keys": {
      "Word": 7224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "mother-of-pearl"
    },
    "Keys": {
      "Hanzi": 4406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4406,
      "Radical": "玉",
      "FrequencyRank": 6976,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "膨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "to swell; swollen, bloated, inflated"
    },
    "Keys": {
      "Hanzi": 3364
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.12",
      "GeneralStandard": 3364,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 2415,
      "StrokeCount": 16
    }
  },
  "上衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngyī",
      "PrimaryDefinition": "jacket, upper outer garment, CL:件[jian4]"
    },
    "Keys": {
      "Word": 1873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "滉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàng",
      "PrimaryDefinition": "deep"
    },
    "Keys": {
      "Hanzi": 7591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7591,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "可能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěnéng",
      "PrimaryDefinition": "probably, maybe"
    },
    "Keys": {
      "Word": 815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "峄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "a range of peaks in Shandong and Jiangsu; the name of a mountain"
    },
    "Keys": {
      "Hanzi": 3935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3935,
      "Radical": "山",
      "FrequencyRank": 5405,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "盛开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngkāi",
      "PrimaryDefinition": "blooming, in full flower"
    },
    "Keys": {
      "Word": 9079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "源源不断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuányuánbùduàn",
      "PrimaryDefinition": "a steady flow (idiom), an unending stream"
    },
    "Keys": {
      "Word": 10551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "深信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnxìn",
      "PrimaryDefinition": "to believe firmly"
    },
    "Keys": {
      "Word": 9032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "囿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "pen up; limit, constrain"
    },
    "Keys": {
      "Hanzi": 4219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4219,
      "Radical": "囗",
      "FrequencyRank": 4603,
      "RadicalIndex": "31.6",
      "StrokeCount": 9
    }
  },
  "蓟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "circium, thistles; surname"
    },
    "Keys": {
      "Hanzi": 5380
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5380,
      "Radical": "艸",
      "FrequencyRank": 4290,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "杠铃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gànglíng",
      "PrimaryDefinition": "barbell"
    },
    "Keys": {
      "Word": 6689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "保重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎozhòng",
      "PrimaryDefinition": "to take care of oneself"
    },
    "Keys": {
      "Word": 5528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "犷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎng",
      "PrimaryDefinition": "fierce, rude, uncivilized"
    },
    "Keys": {
      "Hanzi": 3617
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3617,
      "Radical": "犬",
      "FrequencyRank": 4624,
      "RadicalIndex": "94.3",
      "StrokeCount": 6
    }
  },
  "改进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎijìn",
      "PrimaryDefinition": "improve"
    },
    "Keys": {
      "Word": 1470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "哝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóng",
      "PrimaryDefinition": "whisper"
    },
    "Keys": {
      "Hanzi": 4227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4227,
      "Radical": "口",
      "FrequencyRank": 3581,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "喜悦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐyuè",
      "PrimaryDefinition": "happy, joyous"
    },
    "Keys": {
      "Word": 9844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "cormorant"
    },
    "Keys": {
      "Hanzi": 5797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5797,
      "Radical": "鳥",
      "FrequencyRank": 5133,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "腐烂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔlàn",
      "PrimaryDefinition": "to rot, to putrefy, (fig.) corrupt"
    },
    "Keys": {
      "Word": 6619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "disregard, slight, disdain"
    },
    "Keys": {
      "Hanzi": 3393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.14",
      "GeneralStandard": 3393,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 3755,
      "StrokeCount": 17
    }
  },
  "异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "different, unusual, strange"
    },
    "Keys": {
      "Hanzi": 568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "55.3",
      "GeneralStandard": 568,
      "Radical": "廾",
      "HSK": 6,
      "FrequencyRank": 709,
      "StrokeCount": 6
    }
  },
  "抛开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāokāi",
      "PrimaryDefinition": "to throw out, to get rid of"
    },
    "Keys": {
      "Word": 8474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "star"
    },
    "Keys": {
      "Hanzi": 6597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6597,
      "Radical": "口",
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "缘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "hem, margin; reason, cause; fate"
    },
    "Keys": {
      "Hanzi": 2824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "GeneralStandard": 2824,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 1274,
      "StrokeCount": 12
    }
  },
  "穆斯林": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Mùsīlín",
      "PrimaryDefinition": "Muslim"
    },
    "Keys": {
      "Word": 8358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "surname; numerous, many; black"
    },
    "Keys": {
      "Hanzi": 3262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "202.3",
      "GeneralStandard": 3262,
      "Radical": "黍",
      "HSK": 8,
      "FrequencyRank": 1476,
      "StrokeCount": 15
    }
  },
  "净化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnghuà",
      "PrimaryDefinition": "to purify"
    },
    "Keys": {
      "Word": 7634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "edible mushrooms"
    },
    "Keys": {
      "Hanzi": 7989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7989,
      "Radical": "艸",
      "FrequencyRank": 8339,
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "充实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngshí",
      "PrimaryDefinition": "substantiate, enrich, replenish"
    },
    "Keys": {
      "Word": 5919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亲情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnqíng",
      "PrimaryDefinition": "affection, family love, love, esp. within a married couple or between parents and children"
    },
    "Keys": {
      "Word": 8722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赶往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnwǎng",
      "PrimaryDefinition": "to hurry to (somewhere)"
    },
    "Keys": {
      "Word": 6671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稍后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāohòu",
      "PrimaryDefinition": "later on"
    },
    "Keys": {
      "Word": 8993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "界限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièxiàn",
      "PrimaryDefinition": "compass, ceiling, bounds, demarcation line, boundary, bourn, tether, purlieu, demarcation, precinct, pale, circumscription, borderland, limits, bound, ambit, butting, Rubicon, terminus, terminal point, confines, end, dividing line, extent, verge, terminus ad quem, limit, division, marge"
    },
    "Keys": {
      "Word": 7554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情怀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínghuái",
      "PrimaryDefinition": "feelings, mood"
    },
    "Keys": {
      "Word": 8756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "佛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "Buddha; of Buddhism; merciful person; Buddhist image; the dead (Jap.)"
    },
    "Keys": {
      "Hanzi": 789,
      "Word": 4569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 6,
      "GeneralStandard": 789,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 771,
      "StrokeCount": 7
    }
  },
  "洽谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàtán",
      "PrimaryDefinition": "hold trade talk, negotiate"
    },
    "Keys": {
      "Word": 8638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趋势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūshì",
      "PrimaryDefinition": "stream, gravitation, tendency, wind, tide, drift, sequel, trend, current, direction, inclination, tendence, momentum, flow"
    },
    "Keys": {
      "Word": 2813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "踅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xué",
      "PrimaryDefinition": "to walk around; turn back midway"
    },
    "Keys": {
      "Hanzi": 5637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5637,
      "Radical": "足",
      "FrequencyRank": 5268,
      "RadicalIndex": "157.7",
      "StrokeCount": 14
    }
  },
  "严": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "strict, rigorous, rigid; stern"
    },
    "Keys": {
      "Hanzi": 671,
      "Word": 3064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.6",
      "Grade": 4,
      "GeneralStandard": 671,
      "Radical": "一",
      "HSK": 4,
      "FrequencyRank": 545,
      "StrokeCount": 7
    }
  },
  "东南": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngnán",
      "PrimaryDefinition": "sou'-east, Southeast China, southeast, SE"
    },
    "Keys": {
      "Word": 627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "嫌弃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiánqì",
      "PrimaryDefinition": "to avoid sb (out of dislike), to turn one's back on sb, to ignore"
    },
    "Keys": {
      "Word": 9892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "括弧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòhú",
      "PrimaryDefinition": "parenthesis"
    },
    "Keys": {
      "Word": 7904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冗长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǒngcháng",
      "PrimaryDefinition": "long and tedious, redundant, superfluous, supernumerary, verbose (of writing)"
    },
    "Keys": {
      "Word": 8889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说干就干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōgànjiùgàn",
      "PrimaryDefinition": "Talk to dry"
    },
    "Keys": {
      "Word": 9294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "标示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāoshì",
      "PrimaryDefinition": "label"
    },
    "Keys": {
      "Word": 5626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "明亮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngliàng",
      "PrimaryDefinition": "brighten, well-lit, shining, bright"
    },
    "Keys": {
      "Word": 3725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "媛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "beauty, beautiful woman"
    },
    "Keys": {
      "Hanzi": 5321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5321,
      "Radical": "女",
      "FrequencyRank": 4078,
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "柃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6799,
      "Radical": "木",
      "FrequencyRank": 7994,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "酝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "liquor, spirits, wine; ferment"
    },
    "Keys": {
      "Hanzi": 2236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.4",
      "GeneralStandard": 2236,
      "Radical": "酉",
      "HSK": 8,
      "FrequencyRank": 3362,
      "StrokeCount": 11
    }
  },
  "挑选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāoxuǎn",
      "PrimaryDefinition": "choose, select"
    },
    "Keys": {
      "Word": 2933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "执法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhífǎ",
      "PrimaryDefinition": "to enforce a law, law enforcement"
    },
    "Keys": {
      "Word": 10755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāo",
      "PrimaryDefinition": "end of spine; buttocks, sacrum"
    },
    "Keys": {
      "Hanzi": 3558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3558,
      "Radical": "尸",
      "FrequencyRank": 6155,
      "RadicalIndex": "44.2",
      "StrokeCount": 5
    }
  },
  "揶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yé",
      "PrimaryDefinition": "make fun of, ridicule"
    },
    "Keys": {
      "Hanzi": 4716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4716,
      "Radical": "手",
      "FrequencyRank": 5389,
      "RadicalIndex": "64.9",
      "StrokeCount": 11
    }
  },
  "预约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùyuē",
      "PrimaryDefinition": "make an appointment"
    },
    "Keys": {
      "Word": 5283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "上方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngfāng",
      "PrimaryDefinition": "Above"
    },
    "Keys": {
      "Word": 8971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyì",
      "PrimaryDefinition": "uneasy"
    },
    "Keys": {
      "Word": 3275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "药店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàodiàn",
      "PrimaryDefinition": "pharmacy"
    },
    "Keys": {
      "Word": 1133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "听见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng jian",
      "PrimaryDefinition": "hear"
    },
    "Keys": {
      "Word": 359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "不定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùdìng",
      "PrimaryDefinition": "uncertain, indeterminate, indefinite, undetermined"
    },
    "Keys": {
      "Word": 5673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sū",
      "PrimaryDefinition": "be reborn; resuscitate, revive"
    },
    "Keys": {
      "Hanzi": 7335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7335,
      "Radical": "生",
      "RadicalIndex": "100.7",
      "StrokeCount": 12
    }
  },
  "茓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xué",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3869,
      "Radical": "艸",
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "钎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "tool for boring holes"
    },
    "Keys": {
      "Hanzi": 3940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3940,
      "Radical": "金",
      "FrequencyRank": 5894,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "聂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "whisper; surname"
    },
    "Keys": {
      "Hanzi": 1819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1819,
      "Radical": "耳",
      "FrequencyRank": 2797,
      "RadicalIndex": "128.4",
      "StrokeCount": 10
    }
  },
  "威信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēixìn",
      "PrimaryDefinition": "Weixin county in Zhaotong 昭通[Zhao1 tong1], Yunnan, prestige, reputation, trust, credit with the people"
    },
    "Keys": {
      "Word": 9689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "an ancient place in the state of Wu"
    },
    "Keys": {
      "Hanzi": 3528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3528,
      "Radical": "邑",
      "FrequencyRank": 6669,
      "RadicalIndex": "163.3",
      "StrokeCount": 5
    }
  },
  "因": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "cause, reason; by; because (of)"
    },
    "Keys": {
      "Hanzi": 430,
      "Word": 5251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.3",
      "Grade": 6,
      "GeneralStandard": 430,
      "Radical": "囗",
      "HSK": 2,
      "FrequencyRank": 96,
      "StrokeCount": 6
    }
  },
  "在内": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàinèi",
      "PrimaryDefinition": "(included) in it, among them"
    },
    "Keys": {
      "Word": 4172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "荞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "buckwheat"
    },
    "Keys": {
      "Hanzi": 4118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4118,
      "Radical": "艸",
      "FrequencyRank": 5033,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎi",
      "PrimaryDefinition": "hue, color; variegated colors"
    },
    "Keys": {
      "Hanzi": 2353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "59.8",
      "GeneralStandard": 2353,
      "Radical": "彡",
      "HSK": 3,
      "FrequencyRank": 1177,
      "StrokeCount": 11
    }
  },
  "浮现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúxiàn",
      "PrimaryDefinition": "appear before one's eyes, float, suggest, come up, rise up, surface, ray, rise, appear, emerge"
    },
    "Keys": {
      "Word": 6606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "介": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "forerunner, herald, harbinger; to lie between; sea shell; to wear armor"
    },
    "Keys": {
      "Hanzi": 140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "GeneralStandard": 140,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 831,
      "StrokeCount": 4
    }
  },
  "阶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "stairs, steps; rank, degree"
    },
    "Keys": {
      "Hanzi": 574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.4",
      "GeneralStandard": 574,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 745,
      "StrokeCount": 6
    }
  },
  "魅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèi",
      "PrimaryDefinition": "kind of forest demon, elf"
    },
    "Keys": {
      "Hanzi": 3130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "194.5",
      "GeneralStandard": 3130,
      "Radical": "鬼",
      "HSK": 7,
      "FrequencyRank": 2662,
      "StrokeCount": 14
    }
  },
  "励志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìzhì",
      "PrimaryDefinition": "to encourage, encouragement"
    },
    "Keys": {
      "Word": 8007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "重新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngxīn",
      "PrimaryDefinition": "over_again, newly, afresh, anew, again"
    },
    "Keys": {
      "Word": 561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "车辆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēliàng",
      "PrimaryDefinition": "voiture, vehicular traffic, vehicle, cars, vehicle traffic, car, vehicles, carriage, rolling_stock, equipment"
    },
    "Keys": {
      "Word": 557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pò",
      "PrimaryDefinition": "coerce, force, compel; urgent"
    },
    "Keys": {
      "Hanzi": 1156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.5",
      "GeneralStandard": 1156,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 1006,
      "StrokeCount": 8
    }
  },
  "观念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānniàn",
      "PrimaryDefinition": "conception, intension, theory, philosophy, idolum, connotation, idea, sense, presentation, notion, concept, construct, intellection, presentment, thought, notional, conceit"
    },
    "Keys": {
      "Word": 1521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "盎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àng",
      "PrimaryDefinition": "cup; pot; bowl; abundant"
    },
    "Keys": {
      "Hanzi": 4518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4518,
      "Radical": "皿",
      "FrequencyRank": 3410,
      "RadicalIndex": "108.5",
      "StrokeCount": 10
    }
  },
  "神气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénqì",
      "PrimaryDefinition": "expression, manner, vigorous, impressive, lofty, pretentious"
    },
    "Keys": {
      "Word": 9036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "kind of bat"
    },
    "Keys": {
      "Hanzi": 3247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3247,
      "Radical": "虫",
      "FrequencyRank": 3335,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "脑筋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎojīn",
      "PrimaryDefinition": "way of thinking, ideas, head, mind, skull, brains"
    },
    "Keys": {
      "Word": 8388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "想起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng qǐ",
      "PrimaryDefinition": "retrieve, think back, envisage, hit, echo, remember, think_of, call to mind, recall, recollect, think of"
    },
    "Keys": {
      "Word": 1086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "伢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "child"
    },
    "Keys": {
      "Hanzi": 3604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3604,
      "Radical": "人",
      "FrequencyRank": 3346,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "缫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāo",
      "PrimaryDefinition": "draw, reel"
    },
    "Keys": {
      "Hanzi": 5848
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5848,
      "Radical": "糸",
      "FrequencyRank": 4547,
      "RadicalIndex": "120.11",
      "StrokeCount": 14
    }
  },
  "擒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "catch, capture, seize, arrest"
    },
    "Keys": {
      "Hanzi": 3200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3200,
      "Radical": "手",
      "FrequencyRank": 2850,
      "RadicalIndex": "64.13",
      "StrokeCount": 15
    }
  },
  "肚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "belly; abdomen; bowels"
    },
    "Keys": {
      "Hanzi": 805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.3",
      "GeneralStandard": 805,
      "Radical": "肉",
      "HSK": 4,
      "FrequencyRank": 1800,
      "StrokeCount": 7
    }
  },
  "父子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzǐ",
      "PrimaryDefinition": "father and son"
    },
    "Keys": {
      "Word": 4572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "昊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "summer time; sky; heaven"
    },
    "Keys": {
      "Hanzi": 3897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3897,
      "Radical": "日",
      "FrequencyRank": 3956,
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "妄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàng",
      "PrimaryDefinition": "absurd, foolish, reckless; false"
    },
    "Keys": {
      "Hanzi": 526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "GeneralStandard": 526,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2097,
      "StrokeCount": 6
    }
  },
  "疟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nüè",
      "PrimaryDefinition": "intermittent fever; malaria"
    },
    "Keys": {
      "Hanzi": 1213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1213,
      "Radical": "疒",
      "FrequencyRank": 4153,
      "RadicalIndex": "104.3",
      "StrokeCount": 8
    }
  },
  "赚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "make money, earn; gain, profit"
    },
    "Keys": {
      "Hanzi": 3112,
      "Word": 5389
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.1",
      "Grade": 6,
      "GeneralStandard": 3112,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 2182,
      "StrokeCount": 14
    }
  },
  "回报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíbào",
      "PrimaryDefinition": "repay, reciprocate, retaliate"
    },
    "Keys": {
      "Word": 3557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "挺好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐnghǎo",
      "PrimaryDefinition": "very good"
    },
    "Keys": {
      "Word": 1033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鲫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "Carassius auratus, crucian carp"
    },
    "Keys": {
      "Hanzi": 3277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3277,
      "Radical": "魚",
      "FrequencyRank": 4198,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "报刊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàokān",
      "PrimaryDefinition": "newspapers and periodicals, the press"
    },
    "Keys": {
      "Word": 4301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "晐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6990,
      "Radical": "日",
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "半场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànchǎng",
      "PrimaryDefinition": "half of a game or contest, half-court"
    },
    "Keys": {
      "Word": 5492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "là",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7774
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7774,
      "Radical": "虫",
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "要素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàosù",
      "PrimaryDefinition": "basics, ingredient, essential, key element, constituent, fashioning, element, core, making, stuff, essential factor, factor, essence, strand, elemental, devising, chemistry, requisite, momentum"
    },
    "Keys": {
      "Word": 5225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "甬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "path; river in Ningbo; Ningbo"
    },
    "Keys": {
      "Hanzi": 3819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3819,
      "Radical": "用",
      "FrequencyRank": 4038,
      "RadicalIndex": "101.2",
      "StrokeCount": 7
    }
  },
  "爟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "fire"
    },
    "Keys": {
      "Hanzi": 8085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8085,
      "Radical": "火",
      "RadicalIndex": "86.18",
      "StrokeCount": 21
    }
  },
  "证书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngshū",
      "PrimaryDefinition": "credentials, certificate"
    },
    "Keys": {
      "Word": 4211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "心想事成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxiǎng-shìchéng",
      "PrimaryDefinition": "to have one's wishes come true, wish you the best!"
    },
    "Keys": {
      "Word": 10016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妨害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánghài",
      "PrimaryDefinition": "traverse, impair, bar, cumber, be harmful to, trammel, encumber, sabotage, molest, obstruct, blanket, impairment, disturb, jeopardize, annoy, put in jeopardy"
    },
    "Keys": {
      "Word": 6497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "tense, excited, irritable"
    },
    "Keys": {
      "Hanzi": 3477
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.13",
      "GeneralStandard": 3477,
      "Radical": "足",
      "HSK": 8,
      "FrequencyRank": 2451,
      "StrokeCount": 20
    }
  },
  "状态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngtài",
      "PrimaryDefinition": "repair, form, lie, state (of affairs), state of affairs, fix, status, going, plight, phase, condition, state, trimness, attitude, trim, shape, situation, posture, position, fettle, mode, predicament"
    },
    "Keys": {
      "Word": 2205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "底层": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐcéng",
      "PrimaryDefinition": "first floor, understratum, bottom, underside, the lowest rung, first, undersurface, underlayer, ground level, ground floor, first/ground floor, substratum, first_floor, basement"
    },
    "Keys": {
      "Word": 6243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䏲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "(same as 胅) protruded bones, swelling, a crooked nose"
    },
    "Keys": {
      "Hanzi": 7213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7213,
      "Radical": "肉",
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "崧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "high mountain; lofty, eminent"
    },
    "Keys": {
      "Hanzi": 7170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7170,
      "Radical": "山",
      "FrequencyRank": 5014,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "起初": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐchū",
      "PrimaryDefinition": "initio, ab initio, first, primarily, early, in_the_beginning, originally, at_first, in the beginning, initially, at the start, at first, early on"
    },
    "Keys": {
      "Word": 8615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遭遇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāoyù",
      "PrimaryDefinition": "meet with, encounter"
    },
    "Keys": {
      "Word": 5308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "苴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "sackcloth; female hemp plant"
    },
    "Keys": {
      "Hanzi": 3860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3860,
      "Radical": "艸",
      "FrequencyRank": 5491,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "维持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéichí",
      "PrimaryDefinition": "keep, preserve"
    },
    "Keys": {
      "Word": 2971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "脆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "crisp; fragile, frail; brittle"
    },
    "Keys": {
      "Hanzi": 1994,
      "Word": 3366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "Grade": 5,
      "GeneralStandard": 1994,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1976,
      "StrokeCount": 10
    }
  },
  "翀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "fly upward"
    },
    "Keys": {
      "Hanzi": 7090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7090,
      "Radical": "羽",
      "FrequencyRank": 7246,
      "RadicalIndex": "124.4",
      "StrokeCount": 10
    }
  },
  "矮小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎixiǎo",
      "PrimaryDefinition": "short and small, low and small, undersized"
    },
    "Keys": {
      "Word": 2232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "焓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "(Cant.) onomatopoetic, the sound of a fiercely-burning fire"
    },
    "Keys": {
      "Hanzi": 4958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4958,
      "Radical": "火",
      "FrequencyRank": 4902,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "蜀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "name of an ancient state"
    },
    "Keys": {
      "Hanzi": 2934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.7",
      "GeneralStandard": 2934,
      "Radical": "虫",
      "HSK": 9,
      "FrequencyRank": 2602,
      "StrokeCount": 13
    }
  },
  "有限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuxiàn",
      "PrimaryDefinition": "limited, finite"
    },
    "Keys": {
      "Word": 3118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "出众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzhòng",
      "PrimaryDefinition": "to stand out, outstanding"
    },
    "Keys": {
      "Word": 5976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "excessive, dissolute; delighted"
    },
    "Keys": {
      "Hanzi": 7600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7600,
      "Radical": "心",
      "RadicalIndex": "61.1",
      "StrokeCount": 13
    }
  },
  "款项": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎnxiàng",
      "PrimaryDefinition": "a sum of money, fund"
    },
    "Keys": {
      "Word": 7884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "川": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuān",
      "PrimaryDefinition": "river"
    },
    "Keys": {
      "Radical": 47,
      "Hanzi": 43
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "47",
      "GeneralStandard": 43,
      "Radical": "巛",
      "HSK": 9,
      "FrequencyRank": 1109,
      "StrokeCount": 3
    }
  },
  "裾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "lapel, border of garment; skirt"
    },
    "Keys": {
      "Hanzi": 5599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5599,
      "Radical": "衣",
      "FrequencyRank": 5428,
      "RadicalIndex": "145.8",
      "StrokeCount": 13
    }
  },
  "耻辱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐrǔ",
      "PrimaryDefinition": "disgrace, shame, humiliation"
    },
    "Keys": {
      "Word": 5908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "天长地久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāncháng-dìjiǔ",
      "PrimaryDefinition": "enduring while the world lasts (idiom), eternal"
    },
    "Keys": {
      "Word": 9474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帽子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màozi",
      "PrimaryDefinition": "castor, lid, cap, brand, headgear, chapeau, titfer, hat, headpiece, label, amice, tag"
    },
    "Keys": {
      "Word": 2726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "淯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "name of river; old name of Baihe in Henan"
    },
    "Keys": {
      "Hanzi": 7238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7238,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "碰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèng",
      "PrimaryDefinition": "collide, bump into"
    },
    "Keys": {
      "Hanzi": 2880,
      "Word": 901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.8",
      "Grade": 2,
      "GeneralStandard": 2880,
      "Radical": "石",
      "HSK": 2,
      "FrequencyRank": 1371,
      "StrokeCount": 13
    }
  },
  "剧目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùmù",
      "PrimaryDefinition": "repertoire, list of plays or operas"
    },
    "Keys": {
      "Word": 7702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùxiān",
      "PrimaryDefinition": "beforehand, in advance"
    },
    "Keys": {
      "Word": 10521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "变为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànwéi",
      "PrimaryDefinition": "change to, become"
    },
    "Keys": {
      "Word": 1300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "引进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnjìn",
      "PrimaryDefinition": "recommend, introduce from elsewhere"
    },
    "Keys": {
      "Word": 3104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "过头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòtóu",
      "PrimaryDefinition": "to overdo it, to overstep the limit, excessively, above one's head, overhead"
    },
    "Keys": {
      "Word": 6954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "安检": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānjiǎn",
      "PrimaryDefinition": "safety check"
    },
    "Keys": {
      "Word": 4290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不够": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùgòu",
      "PrimaryDefinition": "not enough, insufficient, inadequate"
    },
    "Keys": {
      "Word": 526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "学堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuétáng",
      "PrimaryDefinition": "college, school (old)"
    },
    "Keys": {
      "Word": 10134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "defend borders, guard frontiers"
    },
    "Keys": {
      "Hanzi": 3588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3588,
      "Radical": "戈",
      "FrequencyRank": 3767,
      "RadicalIndex": "62.2",
      "StrokeCount": 6
    }
  },
  "悦耳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèěr",
      "PrimaryDefinition": "sweet-sounding, beautiful (of sound)"
    },
    "Keys": {
      "Word": 10566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "pimple, sore, boil"
    },
    "Keys": {
      "Hanzi": 3759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3759,
      "Radical": "疒",
      "FrequencyRank": 5416,
      "RadicalIndex": "104.2",
      "StrokeCount": 7
    }
  },
  "更": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèng",
      "PrimaryDefinition": "more, still further, much more"
    },
    "Keys": {
      "Hanzi": 693,
      "Word": 681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73.3",
      "Grade": 2,
      "GeneralStandard": 693,
      "Radical": "曰",
      "HSK": 2,
      "FrequencyRank": 221,
      "StrokeCount": 7
    }
  },
  "乞求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐqiú",
      "PrimaryDefinition": "to beg"
    },
    "Keys": {
      "Word": 8608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梅花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méihuā",
      "PrimaryDefinition": "plum blossom, clubs (on playing cards), wintersweet"
    },
    "Keys": {
      "Word": 4854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "往年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngnián",
      "PrimaryDefinition": "in former years, in previous years"
    },
    "Keys": {
      "Word": 5134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "野心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěxīn",
      "PrimaryDefinition": "greed, careerism, ambition, ambitiousness, wild ambition"
    },
    "Keys": {
      "Word": 10260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蘑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "type of edible mushroom"
    },
    "Keys": {
      "Hanzi": 3454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.16",
      "GeneralStandard": 3454,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 3528,
      "StrokeCount": 19
    }
  },
  "张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "stretch, extend, expand; sheet"
    },
    "Keys": {
      "Hanzi": 909,
      "Word": 2135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.4",
      "Grade": 3,
      "GeneralStandard": 909,
      "Radical": "弓",
      "HSK": 3,
      "FrequencyRank": 318,
      "StrokeCount": 7
    }
  },
  "季度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìdù",
      "PrimaryDefinition": "quarter of a year, season (sports)"
    },
    "Keys": {
      "Word": 2590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "宫殿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngdiàn",
      "PrimaryDefinition": "palatium, dome, alcazar, palace"
    },
    "Keys": {
      "Word": 6797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "all, any, every; ordinary, common"
    },
    "Keys": {
      "Hanzi": 50,
      "Word": 6458
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "16.1",
      "Grade": 7,
      "GeneralStandard": 50,
      "Radical": "几",
      "HSK": 6,
      "FrequencyRank": 1013,
      "StrokeCount": 3
    }
  },
  "繁殖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánzhí",
      "PrimaryDefinition": "breed, reproduce, propagate"
    },
    "Keys": {
      "Word": 4548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "痓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7223,
      "Radical": "疒",
      "RadicalIndex": "104.6",
      "StrokeCount": 11
    }
  },
  "忍心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěn xīn",
      "PrimaryDefinition": "to have the heart to do sth, to steel oneself to a task"
    },
    "Keys": {
      "Word": 8860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "mill"
    },
    "Keys": {
      "Hanzi": 4873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4873,
      "Radical": "金",
      "FrequencyRank": 4786,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "诙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "tease, joke with; ridicule, mock"
    },
    "Keys": {
      "Hanzi": 4043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4043,
      "Radical": "言",
      "FrequencyRank": 3978,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "稳健": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěnjiàn",
      "PrimaryDefinition": "firm, stable and steady"
    },
    "Keys": {
      "Word": 9748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "死": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǐ",
      "PrimaryDefinition": "die; dead; death"
    },
    "Keys": {
      "Hanzi": 400,
      "Word": 1943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "78.2",
      "Grade": 3,
      "GeneralStandard": 400,
      "Radical": "歹",
      "HSK": 3,
      "FrequencyRank": 317,
      "StrokeCount": 6
    }
  },
  "摇滚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáogǔn",
      "PrimaryDefinition": "rock 'n' roll (music), to rock, to fall off"
    },
    "Keys": {
      "Word": 10235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渗透": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèntòu",
      "PrimaryDefinition": "to permeate, to infiltrate, to pervade, osmosis"
    },
    "Keys": {
      "Word": 9048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庆祝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìngzhù",
      "PrimaryDefinition": "fete, celebration, keep, festive, commemorate, celebrate, observe, rejoice, felicitate"
    },
    "Keys": {
      "Word": 1826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "一无所有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīwú-suǒyǒu",
      "PrimaryDefinition": "not having anything at all (idiom), utterly lacking, without two sticks to rub together"
    },
    "Keys": {
      "Word": 10346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婴儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngér",
      "PrimaryDefinition": "babe, baby infant, wean, neonate, papoose, nestling, parvulus, baby, cheeper, child, bantling, nursling, bambino, infant, nurseling, infantile, suckling, chrisom"
    },
    "Keys": {
      "Word": 10412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "něi",
      "PrimaryDefinition": "hungry, starving, famished"
    },
    "Keys": {
      "Hanzi": 2013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.7",
      "GeneralStandard": 2013,
      "Radical": "食",
      "HSK": 9,
      "FrequencyRank": 4162,
      "StrokeCount": 10
    }
  },
  "镖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "dart, spear, harpoon; escort"
    },
    "Keys": {
      "Hanzi": 6112
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6112,
      "Radical": "金",
      "FrequencyRank": 2727,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "吸引": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīyǐn",
      "PrimaryDefinition": "engross, arrest, appeal, fascinate, attraction, fetch, draw, transfix, pull in, entrance, pull, enamour, spellbind, enamor, capture, trance, captivate, call, grip, fascination, absorb, bewitch, tempt, becharm, speak_to, fix, engage, catch, rivet, enchant, beguile, charm, seduce, take, enwrap, magnetize, draw in, attract"
    },
    "Keys": {
      "Word": 2998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "镀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "plate, coat, gild"
    },
    "Keys": {
      "Hanzi": 3115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3115,
      "Radical": "金",
      "FrequencyRank": 3931,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "耻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐ",
      "PrimaryDefinition": "shame, humiliation; ashamed"
    },
    "Keys": {
      "Hanzi": 1816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.4",
      "GeneralStandard": 1816,
      "Radical": "耳",
      "HSK": 7,
      "FrequencyRank": 2169,
      "StrokeCount": 10
    }
  },
  "夜间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèjian",
      "PrimaryDefinition": "nighttime, evening or night (e.g. classes)"
    },
    "Keys": {
      "Word": 4098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鄅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7199,
      "Radical": "邑",
      "RadicalIndex": "163.9",
      "StrokeCount": 11
    }
  },
  "无所谓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúsuǒwèi",
      "PrimaryDefinition": "not deserve the name of, be indifferent, cannot be designated as, not matter, can't be considered as"
    },
    "Keys": {
      "Word": 2988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "傍晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàngwǎn",
      "PrimaryDefinition": "in the evening, when night falls, towards evening, at night fall, at dusk"
    },
    "Keys": {
      "Word": 4302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "锹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": "shovel"
    },
    "Keys": {
      "Hanzi": 3113
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3113,
      "Radical": "金",
      "FrequencyRank": 4137,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "共性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngxìng",
      "PrimaryDefinition": "overall character"
    },
    "Keys": {
      "Word": 6805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "症状": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngzhuàng",
      "PrimaryDefinition": "symptom"
    },
    "Keys": {
      "Word": 5344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "立刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìkè",
      "PrimaryDefinition": "in a short time, at_once, promptly, offhand, at once, anon, instantly, straightway, now, slapbang, pronto, right_away, immediately, without delay, right away, forthwith, presto, at a time, before long, in a minute, swith, directly, in no time, incontinent, subito, without_delay, in real time, shortly, tout-de-suite, away, soon, presently, in a few seconds, very fast, chop-chop, at o"
    },
    "Keys": {
      "Word": 1703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "贷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "lend; borrow; pardon"
    },
    "Keys": {
      "Hanzi": 1558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "GeneralStandard": 1558,
      "Radical": "貝",
      "HSK": 5,
      "FrequencyRank": 1605,
      "StrokeCount": 9
    }
  },
  "廉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "upright, honorable, honest"
    },
    "Keys": {
      "Hanzi": 2992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.1",
      "GeneralStandard": 2992,
      "Radical": "广",
      "HSK": 7,
      "FrequencyRank": 1818,
      "StrokeCount": 13
    }
  },
  "慧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "bright, intelligent; intelligence"
    },
    "Keys": {
      "Hanzi": 3189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.11",
      "GeneralStandard": 3189,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1505,
      "StrokeCount": 15
    }
  },
  "饳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6714,
      "Radical": "食",
      "FrequencyRank": 9646,
      "RadicalIndex": "184.5",
      "StrokeCount": 8
    }
  },
  "时隔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shígé",
      "PrimaryDefinition": "separated in time (usu. followed by a quantity of time)"
    },
    "Keys": {
      "Word": 9105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoshī",
      "PrimaryDefinition": "beak, don, school teacher, schoolman, teacher, schoolmaster, preceptor, professor, pedagogue, schoolteacher, coach, master, instructor, educator, preceptorship"
    },
    "Keys": {
      "Word": 771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "追尾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuī wěi",
      "PrimaryDefinition": "to tailgate, to hit the car in front as a result of tailgating"
    },
    "Keys": {
      "Word": 10919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "sound of a cat; bite; laugh"
    },
    "Keys": {
      "Hanzi": 6831
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6831,
      "Radical": "口",
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "佯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "pretend, feign; false, deceitful"
    },
    "Keys": {
      "Hanzi": 3965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3965,
      "Radical": "人",
      "FrequencyRank": 3393,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "尥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3589,
      "Radical": "尢",
      "FrequencyRank": 5851,
      "RadicalIndex": "43.3",
      "StrokeCount": 6
    }
  },
  "页": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "page"
    },
    "Keys": {
      "Hanzi": 393,
      "Radical": 181,
      "Word": 422
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181",
      "Grade": 1,
      "GeneralStandard": 393,
      "Radical": "頁",
      "HSK": 1,
      "FrequencyRank": 1128,
      "StrokeCount": 6
    }
  },
  "赍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "present"
    },
    "Keys": {
      "Hanzi": 5100
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5100,
      "Radical": "貝",
      "FrequencyRank": 5246,
      "RadicalIndex": "154.8",
      "StrokeCount": 12
    }
  },
  "失去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīqù",
      "PrimaryDefinition": "desert, shear, outgrow, loss, lost, miss, lose"
    },
    "Keys": {
      "Word": 1896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "泱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": "great, expansive; agitated"
    },
    "Keys": {
      "Hanzi": 4013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4013,
      "Radical": "水",
      "FrequencyRank": 4801,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "潽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7819,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "兴起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngqǐ",
      "PrimaryDefinition": "rise, spring up, be on the upgrade"
    },
    "Keys": {
      "Word": 10041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "古人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔrén",
      "PrimaryDefinition": "forefathers, our forefather, ancient, ancients, antiquity"
    },
    "Keys": {
      "Word": 6829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胸有成竹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngyǒuchéngzhú",
      "PrimaryDefinition": "to plan in advance (idiom), a card up one's sleeve, forewarned is forearmed"
    },
    "Keys": {
      "Word": 10070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 6571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6571,
      "Radical": "艸",
      "FrequencyRank": 5825,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "耍赖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǎlài",
      "PrimaryDefinition": "to act shamelessly, to refuse to acknowledge that one has lost the game, or made a promise etc, to act dumb, to act as if sth never happened"
    },
    "Keys": {
      "Word": 9240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎ",
      "PrimaryDefinition": "card, punch card; calorie"
    },
    "Keys": {
      "Hanzi": 226,
      "Word": 8636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "25.3",
      "Grade": 7,
      "GeneralStandard": 226,
      "Radical": "卜",
      "HSK": 2,
      "FrequencyRank": 717,
      "StrokeCount": 5
    }
  },
  "紧缺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnquē",
      "PrimaryDefinition": "in short supply, scarce"
    },
    "Keys": {
      "Word": 7571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "celery"
    },
    "Keys": {
      "Hanzi": 666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 666,
      "Radical": "艸",
      "FrequencyRank": 3740,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "仪表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíbiǎo",
      "PrimaryDefinition": "appearance, bearing, meter (i.e. measuring instrument)"
    },
    "Keys": {
      "Word": 10298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "draw, sketch, paint"
    },
    "Keys": {
      "Hanzi": 1757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "GeneralStandard": 1757,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 1809,
      "StrokeCount": 9
    }
  },
  "卬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áng",
      "PrimaryDefinition": "lofty; high; raise; high-priced"
    },
    "Keys": {
      "Hanzi": 6504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6504,
      "Radical": "卩",
      "RadicalIndex": "26.2",
      "StrokeCount": 4
    }
  },
  "旬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "ten-day period; period of time"
    },
    "Keys": {
      "Hanzi": 500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.2",
      "GeneralStandard": 500,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2332,
      "StrokeCount": 6
    }
  },
  "绰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuò",
      "PrimaryDefinition": "graceful, delicate; spacious"
    },
    "Keys": {
      "Hanzi": 2490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2490,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 2930,
      "StrokeCount": 11
    }
  },
  "破裂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòliè",
      "PrimaryDefinition": "split up, flaw, rip, rend, splinter, part, break away, come_apart, chip off, come apart, bust, broken, break up, separate, chip, fracture, fall apart, split, break_down, crack, rupture, break off, come off, check, break, burst"
    },
    "Keys": {
      "Word": 8573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毛病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máobing",
      "PrimaryDefinition": "trouble, mishap, breakdown, defect, shortcoming, fault, mistake, illness"
    },
    "Keys": {
      "Word": 1733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "灵巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língqiǎo",
      "PrimaryDefinition": "deft, nimble, ingenious"
    },
    "Keys": {
      "Word": 8070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "话费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàfèi",
      "PrimaryDefinition": "call charge"
    },
    "Keys": {
      "Word": 7150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "津": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "ferry; saliva; ford"
    },
    "Keys": {
      "Hanzi": 1683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1683,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 1353,
      "StrokeCount": 9
    }
  },
  "酰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "acyl"
    },
    "Keys": {
      "Hanzi": 5413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5413,
      "Radical": "酉",
      "FrequencyRank": 4022,
      "RadicalIndex": "164.6",
      "StrokeCount": 13
    }
  },
  "夷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "ancient barbarian tribes"
    },
    "Keys": {
      "Hanzi": 403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.3",
      "GeneralStandard": 403,
      "Radical": "大",
      "HSK": 9,
      "FrequencyRank": 2337,
      "StrokeCount": 6
    }
  },
  "洒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎ",
      "PrimaryDefinition": "sprinkle; scatter; pour; to wipe away; to shiver"
    },
    "Keys": {
      "Hanzi": 1665,
      "Word": 3855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 5,
      "GeneralStandard": 1665,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 2283,
      "StrokeCount": 9
    }
  },
  "去世": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùshì",
      "PrimaryDefinition": "die, pass away"
    },
    "Keys": {
      "Word": 1828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "娼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "prostitute, harlot"
    },
    "Keys": {
      "Hanzi": 5019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5019,
      "Radical": "女",
      "FrequencyRank": 3578,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "则": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "rule, law, regulation; grades"
    },
    "Keys": {
      "Hanzi": 440,
      "Word": 10617
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "Grade": 7,
      "GeneralStandard": 440,
      "Radical": "刀",
      "HSK": 4,
      "FrequencyRank": 284,
      "StrokeCount": 6
    }
  },
  "柖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sháo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6803,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "推动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuī dòng",
      "PrimaryDefinition": "push forward, promote"
    },
    "Keys": {
      "Word": 1988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "好歹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎodǎi",
      "PrimaryDefinition": "good and bad, most unfortunate occurrence, in any case, whatever"
    },
    "Keys": {
      "Word": 7003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5991
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5991,
      "Radical": "米",
      "FrequencyRank": 6149,
      "RadicalIndex": "119.9",
      "StrokeCount": 15
    }
  },
  "清晨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngchén",
      "PrimaryDefinition": "early morning"
    },
    "Keys": {
      "Word": 3820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "喵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miāo",
      "PrimaryDefinition": "the mew of the cat"
    },
    "Keys": {
      "Hanzi": 4819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4819,
      "Radical": "口",
      "FrequencyRank": 4550,
      "RadicalIndex": "30.9",
      "StrokeCount": 11
    }
  },
  "境界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngjiè",
      "PrimaryDefinition": "confine, state, delimitation, extent reached, ambit, bourne, realm, hem, boundary, bourn, plane attained, region, precinct, mete, division"
    },
    "Keys": {
      "Word": 7652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "充足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngzú",
      "PrimaryDefinition": "ample"
    },
    "Keys": {
      "Word": 3336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "舥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7026,
      "Radical": "舟",
      "RadicalIndex": "137.4",
      "StrokeCount": 10
    }
  },
  "存心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cúnxīn",
      "PrimaryDefinition": "deliberately"
    },
    "Keys": {
      "Word": 6084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "fragrant smell of grain; aromatic"
    },
    "Keys": {
      "Hanzi": 3584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3584,
      "Radical": "艸",
      "FrequencyRank": 5156,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "热线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèxiàn",
      "PrimaryDefinition": "hotline (communications link)"
    },
    "Keys": {
      "Word": 4969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "擅长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàncháng",
      "PrimaryDefinition": "excel, surpass, stand out, be good at, be skilled in, be expert in"
    },
    "Keys": {
      "Word": 8957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "以往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐwǎng",
      "PrimaryDefinition": "in the past, formerly"
    },
    "Keys": {
      "Word": 4114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "砘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "a kind of farm tool"
    },
    "Keys": {
      "Hanzi": 4166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4166,
      "Radical": "石",
      "FrequencyRank": 6160,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wài",
      "PrimaryDefinition": "out, outside, external; foreign"
    },
    "Keys": {
      "Hanzi": 293,
      "Word": 363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "36.2",
      "Grade": 1,
      "GeneralStandard": 293,
      "Radical": "夕",
      "HSK": 1,
      "FrequencyRank": 131,
      "StrokeCount": 5
    }
  },
  "箐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìng",
      "PrimaryDefinition": "to draw a bamboo bow or crossbow"
    },
    "Keys": {
      "Hanzi": 5742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5742,
      "Radical": "竹",
      "FrequencyRank": 6728,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "思路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīlù",
      "PrimaryDefinition": "train of thought, thinking, reason, reasoning"
    },
    "Keys": {
      "Word": 9318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "small, narrow, tiny, little"
    },
    "Keys": {
      "Hanzi": 6509
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6509,
      "Radical": "戈",
      "FrequencyRank": 6653,
      "RadicalIndex": "62.1",
      "StrokeCount": 5
    }
  },
  "界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "boundary, limit; domain; society; the world"
    },
    "Keys": {
      "Hanzi": 1496,
      "Word": 4737
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102.4",
      "Grade": 6,
      "GeneralStandard": 1496,
      "Radical": "田",
      "HSK": 3,
      "FrequencyRank": 288,
      "StrokeCount": 9
    }
  },
  "审核": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnhé",
      "PrimaryDefinition": "examine and verify"
    },
    "Keys": {
      "Word": 9041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìlì",
      "PrimaryDefinition": "independent, self-reliant, self-sustaining, to stand on one's own feet, to support oneself"
    },
    "Keys": {
      "Word": 10960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "to string together, to bind up"
    },
    "Keys": {
      "Hanzi": 5038
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5038,
      "Radical": "糸",
      "FrequencyRank": 4528,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "轨迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐjì",
      "PrimaryDefinition": "track, orbit, trail, locus"
    },
    "Keys": {
      "Word": 6920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "slander, defame, misrepresent"
    },
    "Keys": {
      "Hanzi": 5006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5006,
      "Radical": "言",
      "FrequencyRank": 4437,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "仲裁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngcái",
      "PrimaryDefinition": "arbitration"
    },
    "Keys": {
      "Word": 10826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèdà",
      "PrimaryDefinition": "Extraordinary"
    },
    "Keys": {
      "Word": 5071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "钾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "potassium"
    },
    "Keys": {
      "Hanzi": 1931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1931,
      "Radical": "金",
      "FrequencyRank": 3801,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "坦诚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎnchéng",
      "PrimaryDefinition": "candid, frank, plain dealing"
    },
    "Keys": {
      "Word": 9409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "增添": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngtiān",
      "PrimaryDefinition": "increase, accession, throw in, point_up, add, subjoin, addition, lend"
    },
    "Keys": {
      "Word": 10624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "探索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tànsuǒ",
      "PrimaryDefinition": "explore, probe"
    },
    "Keys": {
      "Word": 5066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "揣测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuǎicè",
      "PrimaryDefinition": "to guess, to conjecture"
    },
    "Keys": {
      "Word": 5997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当务之急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngwùzhījí",
      "PrimaryDefinition": "top priority job, matter of vital importance"
    },
    "Keys": {
      "Word": 6177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "髡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "to shear tree; ancient punishment"
    },
    "Keys": {
      "Hanzi": 5349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5349,
      "Radical": "髟",
      "FrequencyRank": 4754,
      "RadicalIndex": "190.3",
      "StrokeCount": 13
    }
  },
  "无关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúguān",
      "PrimaryDefinition": "have nothing to do with"
    },
    "Keys": {
      "Word": 5147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "坦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": "flat, smooth; self-possessed"
    },
    "Keys": {
      "Hanzi": 962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.5",
      "GeneralStandard": 962,
      "Radical": "土",
      "HSK": 5,
      "FrequencyRank": 1017,
      "StrokeCount": 8
    }
  },
  "听到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīngdào",
      "PrimaryDefinition": "hear"
    },
    "Keys": {
      "Word": 358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "前台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiántái",
      "PrimaryDefinition": "stage, proscenium, foreground in politics etc (sometimes derog.), front desk, reception desk, (computing) front-end, foreground"
    },
    "Keys": {
      "Word": 8669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "platform; unit; term of address"
    },
    "Keys": {
      "Hanzi": 342,
      "Word": 1947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 3,
      "GeneralStandard": 342,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 388,
      "StrokeCount": 5
    }
  },
  "奡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "arrogant"
    },
    "Keys": {
      "Hanzi": 7338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7338,
      "Radical": "大",
      "RadicalIndex": "37.9",
      "StrokeCount": 12
    }
  },
  "缶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǒu",
      "PrimaryDefinition": "jar"
    },
    "Keys": {
      "Radical": 121,
      "Hanzi": 3598
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3598,
      "Radical": "缶",
      "FrequencyRank": 5328,
      "RadicalIndex": "121",
      "StrokeCount": 6
    }
  },
  "可口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěkǒu",
      "PrimaryDefinition": "tasty, to taste good"
    },
    "Keys": {
      "Word": 7800
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "postal, mail; post office"
    },
    "Keys": {
      "Hanzi": 732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.5",
      "GeneralStandard": 732,
      "Radical": "邑",
      "HSK": 3,
      "FrequencyRank": 1652,
      "StrokeCount": 7
    }
  },
  "蘖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "stump, sprout"
    },
    "Keys": {
      "Hanzi": 6423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6423,
      "Radical": "艸",
      "FrequencyRank": 5666,
      "RadicalIndex": "140.17",
      "StrokeCount": 20
    }
  },
  "洽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qià",
      "PrimaryDefinition": "to blend with, be in harmony; to penetrate; to cover; a river in Shenxi"
    },
    "Keys": {
      "Hanzi": 1674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1674,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 3039,
      "StrokeCount": 9
    }
  },
  "菜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cài",
      "PrimaryDefinition": "vegetables; dish, order; food"
    },
    "Keys": {
      "Hanzi": 2207,
      "Word": 33
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "Grade": 1,
      "GeneralStandard": 2207,
      "Radical": "艸",
      "HSK": 1,
      "FrequencyRank": 1266,
      "StrokeCount": 11
    }
  },
  "姿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1630
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "GeneralStandard": 1630,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 1899,
      "StrokeCount": 9
    }
  },
  "戊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "5th heavenly stem"
    },
    "Keys": {
      "Hanzi": 220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 220,
      "Radical": "戈",
      "FrequencyRank": 3689,
      "RadicalIndex": "62.1",
      "StrokeCount": 5
    }
  },
  "架势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàshi",
      "PrimaryDefinition": "attitude, position (on an issue etc)"
    },
    "Keys": {
      "Word": 7369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蘧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "a plant which resembles wheat but has no edible grain"
    },
    "Keys": {
      "Hanzi": 6372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6372,
      "Radical": "艸",
      "FrequencyRank": 6175,
      "RadicalIndex": "140.17",
      "StrokeCount": 19
    }
  },
  "鳘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "codfish"
    },
    "Keys": {
      "Hanzi": 8038
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8038,
      "Radical": "魚",
      "FrequencyRank": 7187,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "人类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénlèi",
      "PrimaryDefinition": "man, wight, human_being, human, human race, mankind, individual, human species, Homo_sapiens, populace, creature, hominid, world, mortality, microcosm, humans, humanitarianism, humanity, humanness, human beings/species, people, species, humankind, human beings, public, mortal"
    },
    "Keys": {
      "Word": 1844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "其次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qícì",
      "PrimaryDefinition": "conj.: next, second"
    },
    "Keys": {
      "Word": 1803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "效率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàolǜ",
      "PrimaryDefinition": "sufficiency, effectualness, effectuality, productiveness, usefulness, efficiency, effectivity, effectiveness"
    },
    "Keys": {
      "Word": 3031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "偰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7192,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "垃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lā",
      "PrimaryDefinition": "garbage, refuse, waste"
    },
    "Keys": {
      "Hanzi": 980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.5",
      "GeneralStandard": 980,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 2628,
      "StrokeCount": 8
    }
  },
  "美人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měirén",
      "PrimaryDefinition": "beauty, belle"
    },
    "Keys": {
      "Word": 8236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "dry; first hexagram; warming principle of the sun, penetrating and fertilizing, heavenly generative principle (male)"
    },
    "Keys": {
      "Hanzi": 2215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2215,
      "Radical": "乙",
      "FrequencyRank": 1999,
      "RadicalIndex": "5.1",
      "StrokeCount": 11
    }
  },
  "萧条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāotiáo",
      "PrimaryDefinition": "bleak, desolate, (economic) depression or slump"
    },
    "Keys": {
      "Word": 9959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "花纹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāwén",
      "PrimaryDefinition": "decorative design"
    },
    "Keys": {
      "Word": 7127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "arsenic"
    },
    "Keys": {
      "Hanzi": 4167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4167,
      "Radical": "石",
      "FrequencyRank": 4949,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "墦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "tomb"
    },
    "Keys": {
      "Hanzi": 7753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7753,
      "Radical": "土",
      "RadicalIndex": "32.12",
      "StrokeCount": 15
    }
  },
  "鲳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "the silvery pomfret, Stromateoides argenteus"
    },
    "Keys": {
      "Hanzi": 6142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6142,
      "Radical": "魚",
      "FrequencyRank": 6372,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "蹇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "lame, crippled; unlucky"
    },
    "Keys": {
      "Hanzi": 6298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6298,
      "Radical": "足",
      "FrequencyRank": 5073,
      "RadicalIndex": "157.1",
      "StrokeCount": 17
    }
  },
  "惊讶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngyà",
      "PrimaryDefinition": "amazed, astounded"
    },
    "Keys": {
      "Word": 7608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎi",
      "PrimaryDefinition": "seize, catch; reach, arrive"
    },
    "Keys": {
      "Hanzi": 2469,
      "Word": 6143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.8",
      "Grade": 7,
      "GeneralStandard": 2469,
      "Radical": "辵",
      "HSK": 7,
      "FrequencyRank": 2216,
      "StrokeCount": 11
    }
  },
  "摅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "spread, disperse; vent, set forth"
    },
    "Keys": {
      "Hanzi": 5354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5354,
      "Radical": "手",
      "FrequencyRank": 6689,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "卫星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèixīng",
      "PrimaryDefinition": "man-made satellite, artificial satellite, moon, acolyte, satellite"
    },
    "Keys": {
      "Word": 4007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "七": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "seven"
    },
    "Keys": {
      "Hanzi": 7,
      "Word": 283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.1",
      "Grade": 1,
      "GeneralStandard": 7,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 530,
      "StrokeCount": 2
    }
  },
  "母子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔzǐ",
      "PrimaryDefinition": "mother and child, parent and subsidiary (companies), principal and interest"
    },
    "Keys": {
      "Word": 4884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "猹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "wild animal mentioned in short story by Lu Xun"
    },
    "Keys": {
      "Hanzi": 5240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5240,
      "Radical": "犬",
      "FrequencyRank": 6954,
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "液": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "sap, juice, liquid, fluid"
    },
    "Keys": {
      "Hanzi": 2428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2428,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1451,
      "StrokeCount": 11
    }
  },
  "矻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": "toil, slave away; busy"
    },
    "Keys": {
      "Hanzi": 6668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6668,
      "Radical": "石",
      "FrequencyRank": 6540,
      "RadicalIndex": "112.3",
      "StrokeCount": 8
    }
  },
  "钯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎ",
      "PrimaryDefinition": "palladium"
    },
    "Keys": {
      "Hanzi": 4246
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4246,
      "Radical": "金",
      "FrequencyRank": 5949,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "有的放矢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒudì-fàngshǐ",
      "PrimaryDefinition": "Title"
    },
    "Keys": {
      "Word": 10473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发炎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā yán",
      "PrimaryDefinition": "inflame, inflammation, inflamed"
    },
    "Keys": {
      "Word": 4543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "熙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "bright, splendid, glorious"
    },
    "Keys": {
      "Hanzi": 3075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.1",
      "GeneralStandard": 3075,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 2485,
      "StrokeCount": 14
    }
  },
  "合资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hézī",
      "PrimaryDefinition": "joint venture, collect money, pool capital, raise funds, enter into partnership"
    },
    "Keys": {
      "Word": 7030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "化肥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàféi",
      "PrimaryDefinition": "fertilizer"
    },
    "Keys": {
      "Word": 7139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "vexed"
    },
    "Keys": {
      "Hanzi": 4350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4350,
      "Radical": "心",
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "时段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíduàn",
      "PrimaryDefinition": "slot, time slot, period of time"
    },
    "Keys": {
      "Word": 9103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "神圣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénshèng",
      "PrimaryDefinition": "sacred, holy"
    },
    "Keys": {
      "Word": 9037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "soak, immerse, dip, percolate"
    },
    "Keys": {
      "Hanzi": 2085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2085,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2354,
      "StrokeCount": 10
    }
  },
  "婊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎo",
      "PrimaryDefinition": "whore, prostitute"
    },
    "Keys": {
      "Hanzi": 5017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5017,
      "Radical": "女",
      "FrequencyRank": 4237,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "福利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúlì",
      "PrimaryDefinition": "well-being, fringe_benefit, material benefits, social welfare, weal, welfare"
    },
    "Keys": {
      "Word": 3471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蹚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": "tread through mud and water"
    },
    "Keys": {
      "Hanzi": 6333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6333,
      "Radical": "足",
      "FrequencyRank": 5535,
      "RadicalIndex": "157.11",
      "StrokeCount": 18
    }
  },
  "驸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "extra horse; imperial son-in-law"
    },
    "Keys": {
      "Hanzi": 4078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4078,
      "Radical": "馬",
      "FrequencyRank": 4218,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "好比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎobǐ",
      "PrimaryDefinition": "like, may be likened to, be just like, can be compared to"
    },
    "Keys": {
      "Word": 7004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "blessing, happiness, prosperity"
    },
    "Keys": {
      "Hanzi": 4365
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4365,
      "Radical": "示",
      "FrequencyRank": 4988,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "秋天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiūtiān",
      "PrimaryDefinition": "autumn, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "巴士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāshì",
      "PrimaryDefinition": "bus"
    },
    "Keys": {
      "Word": 2241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "弊病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìbìng",
      "PrimaryDefinition": "malady, evil, malpractice, drawback, disadvantage"
    },
    "Keys": {
      "Word": 5590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "oar; boat"
    },
    "Keys": {
      "Hanzi": 5097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5097,
      "Radical": "木",
      "FrequencyRank": 6328,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "锖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "the color of the a mineral"
    },
    "Keys": {
      "Hanzi": 7528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7528,
      "Radical": "金",
      "FrequencyRank": 6146,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "挨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "near, close by, next to, towards, against; to wait; to lean on"
    },
    "Keys": {
      "Hanzi": 1815,
      "Word": 4289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 6,
      "GeneralStandard": 1815,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2012,
      "StrokeCount": 10
    }
  },
  "冲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "soar; pour boiling water over"
    },
    "Keys": {
      "Hanzi": 511,
      "Word": 4423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.4",
      "Grade": 6,
      "GeneralStandard": 511,
      "Radical": "冫",
      "HSK": 4,
      "FrequencyRank": 702,
      "StrokeCount": 6
    }
  },
  "癖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "craving, weakness for; indigestion"
    },
    "Keys": {
      "Hanzi": 6359
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6359,
      "Radical": "疒",
      "FrequencyRank": 3669,
      "RadicalIndex": "104.13",
      "StrokeCount": 18
    }
  },
  "噻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāi",
      "PrimaryDefinition": "character used in translation"
    },
    "Keys": {
      "Hanzi": 6107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6107,
      "Radical": "口",
      "FrequencyRank": 5857,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "癜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "erythema"
    },
    "Keys": {
      "Hanzi": 6358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6358,
      "Radical": "疒",
      "FrequencyRank": 4411,
      "RadicalIndex": "104.13",
      "StrokeCount": 18
    }
  },
  "一声不吭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshēngbùkēng",
      "PrimaryDefinition": "to not say a word"
    },
    "Keys": {
      "Word": 10340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诊断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěnduàn",
      "PrimaryDefinition": "diagnose"
    },
    "Keys": {
      "Word": 4202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "弄虚作假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nòngxū-zuòjiǎ",
      "PrimaryDefinition": "to practice fraud (idiom), by trickery"
    },
    "Keys": {
      "Word": 8442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnchū",
      "PrimaryDefinition": "show, perform"
    },
    "Keys": {
      "Word": 2083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "触觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùjué",
      "PrimaryDefinition": "touch, sense of touch"
    },
    "Keys": {
      "Word": 5993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "a kind of water-beetle cash, then dollars and money generally"
    },
    "Keys": {
      "Hanzi": 4507
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4507,
      "Radical": "虫",
      "FrequencyRank": 4967,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "涫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4980,
      "Radical": "水",
      "FrequencyRank": 5048,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "一个劲儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīgejìnr5",
      "PrimaryDefinition": "continuously, persistently, incessantly, erhua variant of 一個勁|一个劲[yi1 ge4 jin4]"
    },
    "Keys": {
      "Word": 10288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "juice, liquor, fluid, sap, gravy, sauce"
    },
    "Keys": {
      "Hanzi": 308,
      "Word": 10743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.2",
      "Grade": 7,
      "GeneralStandard": 308,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 2540,
      "StrokeCount": 5
    }
  },
  "名利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínglì",
      "PrimaryDefinition": "fame and gain, fame and wealth"
    },
    "Keys": {
      "Word": 8305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngtiān",
      "PrimaryDefinition": "(coll.) all day long, all the time"
    },
    "Keys": {
      "Word": 5872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "推荐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuījiàn",
      "PrimaryDefinition": "recommend"
    },
    "Keys": {
      "Word": 9596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "wail, crying of child; swear at"
    },
    "Keys": {
      "Hanzi": 3914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3914,
      "Radical": "口",
      "FrequencyRank": 3476,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "颁发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bānfā",
      "PrimaryDefinition": "issue, promulgate, award"
    },
    "Keys": {
      "Word": 5484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "grind; polish; rub; wear out; a millstone"
    },
    "Keys": {
      "Hanzi": 3367,
      "Word": 4875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.11",
      "Grade": 6,
      "GeneralStandard": 3367,
      "Radical": "石",
      "HSK": 6,
      "FrequencyRank": 1537,
      "StrokeCount": 16
    }
  },
  "骑车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíchē",
      "PrimaryDefinition": "to cycle"
    },
    "Keys": {
      "Word": 920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "孝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": "filial piety, obedience; mourning"
    },
    "Keys": {
      "Hanzi": 638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.4",
      "GeneralStandard": 638,
      "Radical": "子",
      "HSK": 9,
      "FrequencyRank": 2116,
      "StrokeCount": 7
    }
  },
  "墨水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòshuǐ",
      "PrimaryDefinition": "ink, CL:瓶[ping2]"
    },
    "Keys": {
      "Word": 4878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "责备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zébèi",
      "PrimaryDefinition": "to blame, to criticize, condemnation, reproach"
    },
    "Keys": {
      "Word": 10619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "until, when; seize, arrest"
    },
    "Keys": {
      "Hanzi": 4073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4073,
      "Radical": "辵",
      "FrequencyRank": 4517,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "骊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "a pure black horse; a pair of horses"
    },
    "Keys": {
      "Hanzi": 4700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4700,
      "Radical": "馬",
      "FrequencyRank": 4826,
      "RadicalIndex": "187.7",
      "StrokeCount": 10
    }
  },
  "氤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "hanging fog, misty"
    },
    "Keys": {
      "Hanzi": 4549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4549,
      "Radical": "气",
      "FrequencyRank": 4841,
      "RadicalIndex": "84.6",
      "StrokeCount": 10
    }
  },
  "出差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū chāi",
      "PrimaryDefinition": "to go on an official or business trip"
    },
    "Keys": {
      "Word": 3341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "綦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "dark grey. variegated. superlative"
    },
    "Keys": {
      "Hanzi": 5642
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5642,
      "Radical": "糸",
      "FrequencyRank": 5599,
      "RadicalIndex": "120.8",
      "StrokeCount": 14
    }
  },
  "舆论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúlùn",
      "PrimaryDefinition": "sense, vox_populi, popular opinion, public opinion, opinion, public_opinion, consensus, vox populi"
    },
    "Keys": {
      "Word": 10501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "徉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "wonder, rove, stray; hesitating"
    },
    "Keys": {
      "Hanzi": 4270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4270,
      "Radical": "彳",
      "FrequencyRank": 5495,
      "RadicalIndex": "60.6",
      "StrokeCount": 9
    }
  },
  "跑车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǎochē",
      "PrimaryDefinition": "racing bicycle, sporting bicycle, sports car, logging truck, on the job (of a train conductor)"
    },
    "Keys": {
      "Word": 8477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù",
      "PrimaryDefinition": "moist, humid, muggy"
    },
    "Keys": {
      "Hanzi": 5573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5573,
      "Radical": "水",
      "FrequencyRank": 6510,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "说明书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōmíngshū",
      "PrimaryDefinition": "(technical) manual, (book of) directions, synopsis (of play/etc.)"
    },
    "Keys": {
      "Word": 5047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "腙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 7403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7403,
      "Radical": "肉",
      "FrequencyRank": 7577,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "扰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎo",
      "PrimaryDefinition": "disturb, annoy, agitate"
    },
    "Keys": {
      "Hanzi": 618
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 618,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1483,
      "StrokeCount": 7
    }
  },
  "富有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùyǒu",
      "PrimaryDefinition": "be rich/wealthy"
    },
    "Keys": {
      "Word": 4582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "生态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngtài",
      "PrimaryDefinition": "ecological, ecosystem, biology, ecology, biota"
    },
    "Keys": {
      "Word": 9058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "da",
      "PrimaryDefinition": "pimples"
    },
    "Keys": {
      "Hanzi": 3145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.1",
      "GeneralStandard": 3145,
      "Radical": "疒",
      "HSK": 6,
      "FrequencyRank": 3549,
      "StrokeCount": 14
    }
  },
  "讽刺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěngcì",
      "PrimaryDefinition": "satirize, mock"
    },
    "Keys": {
      "Word": 6594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喜出望外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐchūwàngwài",
      "PrimaryDefinition": "to be pleased beyond one's expectations (idiom), overjoyed at the turn of events"
    },
    "Keys": {
      "Word": 9835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "white hempen cloth worn by mourners"
    },
    "Keys": {
      "Hanzi": 6917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6917,
      "Radical": "糸",
      "FrequencyRank": 6969,
      "RadicalIndex": "120.6",
      "StrokeCount": 9
    }
  },
  "牢牢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láoláo",
      "PrimaryDefinition": "firmly, safely"
    },
    "Keys": {
      "Word": 7942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忽视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūshì",
      "PrimaryDefinition": "ignore, overlook, neglect"
    },
    "Keys": {
      "Word": 2548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "战略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànlüè",
      "PrimaryDefinition": "stratagem, tactical, strategics, tactics, strategy"
    },
    "Keys": {
      "Word": 5321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "污": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "filthy, dirty, impure, polluted"
    },
    "Keys": {
      "Hanzi": 537
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.3",
      "GeneralStandard": 537,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1405,
      "StrokeCount": 6
    }
  },
  "其中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qízhōng",
      "PrimaryDefinition": "among, of them, in it, among them, which, them, det.: it, in, in which"
    },
    "Keys": {
      "Word": 917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "赡养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànyǎng",
      "PrimaryDefinition": "to support, to provide support for, to maintain"
    },
    "Keys": {
      "Word": 8960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "流泪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú lèi",
      "PrimaryDefinition": "to shed tears"
    },
    "Keys": {
      "Word": 8099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "观光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānguāng",
      "PrimaryDefinition": "rubberneck, take in, visit, sightsee, travel to, go sightseeing, tour"
    },
    "Keys": {
      "Word": 4629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "登录": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēnglù",
      "PrimaryDefinition": "to register, to log in"
    },
    "Keys": {
      "Word": 2401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "墀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "porch; courtyard; steps leading"
    },
    "Keys": {
      "Hanzi": 5868
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5868,
      "Radical": "土",
      "FrequencyRank": 5847,
      "RadicalIndex": "32.11",
      "StrokeCount": 15
    }
  },
  "鄙视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐshì",
      "PrimaryDefinition": "to despise, to disdain, to look down upon"
    },
    "Keys": {
      "Word": 5586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "延续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánxù",
      "PrimaryDefinition": "continue, go on, last"
    },
    "Keys": {
      "Word": 3065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "延缓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánhuǎn",
      "PrimaryDefinition": "to defer, to postpone, to put off, to retard, to slow sth down"
    },
    "Keys": {
      "Word": 10171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "the countenance changing"
    },
    "Keys": {
      "Hanzi": 7257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7257,
      "Radical": "色",
      "FrequencyRank": 7992,
      "RadicalIndex": "139.5",
      "StrokeCount": 11
    }
  },
  "鞁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7640,
      "Radical": "革",
      "RadicalIndex": "177.5",
      "StrokeCount": 14
    }
  },
  "繄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "be; particle; sigh, alas"
    },
    "Keys": {
      "Hanzi": 7929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7929,
      "Radical": "糸",
      "RadicalIndex": "120.11",
      "StrokeCount": 17
    }
  },
  "铱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "iridium"
    },
    "Keys": {
      "Hanzi": 4884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4884,
      "Radical": "金",
      "FrequencyRank": 4787,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "温和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnhé",
      "PrimaryDefinition": "mild, moderate, temperate, tame, gentle"
    },
    "Keys": {
      "Word": 4008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "澹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "calm, quiet, tranquil"
    },
    "Keys": {
      "Hanzi": 6178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6178,
      "Radical": "水",
      "FrequencyRank": 4261,
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "旷课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng kè",
      "PrimaryDefinition": "to play truant, to cut classes"
    },
    "Keys": {
      "Word": 7889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "初步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūbù",
      "PrimaryDefinition": "initially, preliminarily, tentatively"
    },
    "Keys": {
      "Word": 1360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "黄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "yellow"
    },
    "Keys": {
      "Hanzi": 2201,
      "Radical": 201,
      "Word": 734
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "201",
      "Grade": 2,
      "GeneralStandard": 2201,
      "Radical": "黃",
      "HSK": 2,
      "FrequencyRank": 561,
      "StrokeCount": 11
    }
  },
  "遭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāo",
      "PrimaryDefinition": "come across, meet with, encounter"
    },
    "Keys": {
      "Hanzi": 3085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.11",
      "GeneralStandard": 3085,
      "Radical": "辵",
      "HSK": 6,
      "FrequencyRank": 1048,
      "StrokeCount": 14
    }
  },
  "精力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnglì",
      "PrimaryDefinition": "stamina, get-up, birr, spirits, vigor, energy, cheer, vinegar, vim, whiz, pride, starch, get-up-and-go, mercury, zing, ginger, sap, verve, zip, git-up, pizazz, power, sinew, go, dynamism, oomph, vigour, foison, ambition, moxie, gimp, peppiness, pazazz, pep, pizzaz, snap, stingo, spirit, drive, pith, pizzazz, last, push, steam"
    },
    "Keys": {
      "Word": 2640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恋恋不舍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liànliàn-bùshě",
      "PrimaryDefinition": "reluctant to part"
    },
    "Keys": {
      "Word": 8029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阻拦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔlán",
      "PrimaryDefinition": "to stop, to obstruct"
    },
    "Keys": {
      "Word": 11003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng",
      "PrimaryDefinition": "ancient string music instrument"
    },
    "Keys": {
      "Hanzi": 5751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5751,
      "Radical": "竹",
      "FrequencyRank": 6683,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "隐情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnqíng",
      "PrimaryDefinition": "sth one wishes to keep secret, ulterior motive, a subject best avoided"
    },
    "Keys": {
      "Word": 10399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酗酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùjiǔ",
      "PrimaryDefinition": "heavy drinking, to get drunk, to drink to excess"
    },
    "Keys": {
      "Word": 10104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "己": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "oneself"
    },
    "Keys": {
      "Radical": 49,
      "Hanzi": 60
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "49",
      "GeneralStandard": 60,
      "Radical": "己",
      "HSK": 2,
      "FrequencyRank": 162,
      "StrokeCount": 3
    }
  },
  "翅膀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chìbǎng",
      "PrimaryDefinition": "pinion, vane, plumage, pennon, flier, wing"
    },
    "Keys": {
      "Word": 5911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "sides of chariot where weapons"
    },
    "Keys": {
      "Hanzi": 4807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4807,
      "Radical": "車",
      "FrequencyRank": 3557,
      "RadicalIndex": "159.7",
      "StrokeCount": 11
    }
  },
  "哃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6835,
      "Radical": "口",
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "add to, increase, augment"
    },
    "Keys": {
      "Hanzi": 335,
      "Word": 749
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.3",
      "Grade": 2,
      "GeneralStandard": 335,
      "Radical": "力",
      "HSK": 2,
      "FrequencyRank": 166,
      "StrokeCount": 5
    }
  },
  "馞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7875,
      "Radical": "香",
      "RadicalIndex": "186.7",
      "StrokeCount": 16
    }
  },
  "祸害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòhai",
      "PrimaryDefinition": "disaster, harm, scourge, bad person, to damage, to harm, to wreck"
    },
    "Keys": {
      "Word": 7258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo xué",
      "PrimaryDefinition": "teach and study"
    },
    "Keys": {
      "Word": 773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "公示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngshì",
      "PrimaryDefinition": "Publicity"
    },
    "Keys": {
      "Word": 6773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "民警": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínjǐng",
      "PrimaryDefinition": "civil police, PRC police, abbr. for 人民警察"
    },
    "Keys": {
      "Word": 4866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "报纸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàozhǐ",
      "PrimaryDefinition": "paper, headliner, newspaper publisher, tabloid, copyreader, newspaper, rag, courant, newsprint, sheet, gazette, dailies"
    },
    "Keys": {
      "Word": 512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "嗟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "sigh, alas"
    },
    "Keys": {
      "Hanzi": 5157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5157,
      "Radical": "口",
      "FrequencyRank": 3875,
      "RadicalIndex": "30.1",
      "StrokeCount": 12
    }
  },
  "白天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báitian",
      "PrimaryDefinition": "daytime, during the day, day, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 6
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "必需": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìxū",
      "PrimaryDefinition": "need"
    },
    "Keys": {
      "Word": 3248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "碨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7662,
      "Radical": "石",
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "boundary, frontier; area, region"
    },
    "Keys": {
      "Hanzi": 3061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.11",
      "GeneralStandard": 3061,
      "Radical": "土",
      "HSK": 3,
      "FrequencyRank": 582,
      "StrokeCount": 14
    }
  },
  "举世瞩目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔshì-zhǔmù",
      "PrimaryDefinition": "to receive worldwide attention"
    },
    "Keys": {
      "Word": 7692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㽏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7729,
      "Radical": "甘",
      "RadicalIndex": "99.9",
      "StrokeCount": 14
    }
  },
  "醇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chún",
      "PrimaryDefinition": "rich, good as wine; pure, unmixed"
    },
    "Keys": {
      "Hanzi": 3223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.8",
      "GeneralStandard": 3223,
      "Radical": "酉",
      "HSK": 9,
      "FrequencyRank": 2560,
      "StrokeCount": 15
    }
  },
  "教科书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàokēshū",
      "PrimaryDefinition": "textbook"
    },
    "Keys": {
      "Word": 7493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苦难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔnàn",
      "PrimaryDefinition": "suffering"
    },
    "Keys": {
      "Word": 7861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "封顶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng dǐng",
      "PrimaryDefinition": "to put a roof (on a building), to cap the roof (finishing a building project), fig. to put a ceiling (on spending, prize, ambition etc), to top off, fig. to reach the highest point (of growth, profit, interest rates), to stop growing (of plant bud or branch)"
    },
    "Keys": {
      "Word": 6584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "身高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngāo",
      "PrimaryDefinition": "height, stature, height (of person)"
    },
    "Keys": {
      "Word": 2852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "此外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐwài",
      "PrimaryDefinition": "conj.: besides, in addition, moreover"
    },
    "Keys": {
      "Word": 2349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "雠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "enemy, rival, opponent"
    },
    "Keys": {
      "Hanzi": 6351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6351,
      "Radical": "隹",
      "FrequencyRank": 5545,
      "RadicalIndex": "172.1",
      "StrokeCount": 18
    }
  },
  "眭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": "evil look of deep-set eyes"
    },
    "Keys": {
      "Hanzi": 4809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4809,
      "Radical": "目",
      "FrequencyRank": 7179,
      "RadicalIndex": "109.6",
      "StrokeCount": 11
    }
  },
  "琉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "sparkling stone; glazed, opaque"
    },
    "Keys": {
      "Hanzi": 2158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2158,
      "Radical": "玉",
      "FrequencyRank": 2962,
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "横向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héngxiàng",
      "PrimaryDefinition": "horizontal, orthogonal, perpendicular, lateral, crosswise"
    },
    "Keys": {
      "Word": 7068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "体会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐhuì",
      "PrimaryDefinition": "know/learn from experience, realize"
    },
    "Keys": {
      "Word": 1957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "耳光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěrguāng",
      "PrimaryDefinition": "a slap on the face, CL:記|记[ji4]"
    },
    "Keys": {
      "Word": 6423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiháng",
      "PrimaryDefinition": "layman, amateur"
    },
    "Keys": {
      "Word": 9636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuì",
      "PrimaryDefinition": "climb down rope; hang by rope"
    },
    "Keys": {
      "Hanzi": 5336
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5336,
      "Radical": "糸",
      "FrequencyRank": 5656,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "疼痛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téngtòng",
      "PrimaryDefinition": "pain, soreness, fester, suffer, hurt, ache, sore"
    },
    "Keys": {
      "Word": 5075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "革": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "leather"
    },
    "Keys": {
      "Radical": 177,
      "Hanzi": 1403
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "177",
      "GeneralStandard": 1403,
      "Radical": "革",
      "HSK": 5,
      "FrequencyRank": 521,
      "StrokeCount": 9
    }
  },
  "初衷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzhōng",
      "PrimaryDefinition": "original intention or aspiration"
    },
    "Keys": {
      "Word": 5982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "就职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù zhí",
      "PrimaryDefinition": "to take office, to assume a post"
    },
    "Keys": {
      "Word": 7673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硬朗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìnglang",
      "PrimaryDefinition": "robust, healthy"
    },
    "Keys": {
      "Word": 10432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "red"
    },
    "Keys": {
      "Radical": 155,
      "Hanzi": 631
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "155",
      "GeneralStandard": 631,
      "Radical": "赤",
      "HSK": 9,
      "FrequencyRank": 1660,
      "StrokeCount": 7
    }
  },
  "卡车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎchē",
      "PrimaryDefinition": "teamster, motortruck, lorry, camion, truck"
    },
    "Keys": {
      "Word": 7737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "由于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuyú",
      "PrimaryDefinition": "conj.: owing/due/thanks to"
    },
    "Keys": {
      "Word": 2110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "昵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "intimate, close; approach"
    },
    "Keys": {
      "Hanzi": 4201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4201,
      "Radical": "日",
      "FrequencyRank": 4089,
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "作用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòyòng",
      "PrimaryDefinition": "action, function, effect, intention, motive"
    },
    "Keys": {
      "Word": 1254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "甏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèng",
      "PrimaryDefinition": "a squat jar for holding wine, sauces etc."
    },
    "Keys": {
      "Hanzi": 6041
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6041,
      "Radical": "瓦",
      "FrequencyRank": 6306,
      "RadicalIndex": "98.12",
      "StrokeCount": 16
    }
  },
  "本身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnshēn",
      "PrimaryDefinition": "per se, self, oneself, identity, itself"
    },
    "Keys": {
      "Word": 4319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "愐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "bashful; to consider"
    },
    "Keys": {
      "Hanzi": 7439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7439,
      "Radical": "心",
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "珠宝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūbǎo",
      "PrimaryDefinition": "pearls, jewels, precious stones"
    },
    "Keys": {
      "Word": 5373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "妻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "wife"
    },
    "Keys": {
      "Hanzi": 1056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1056,
      "Radical": "女",
      "HSK": 4,
      "FrequencyRank": 1076,
      "StrokeCount": 8
    }
  },
  "遛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "take walk, stroll"
    },
    "Keys": {
      "Hanzi": 5536,
      "Word": 8111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.1",
      "Grade": 7,
      "GeneralStandard": 5536,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 4552,
      "StrokeCount": 13
    }
  },
  "皮球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píqiú",
      "PrimaryDefinition": "ball (made of rubber, leather etc)"
    },
    "Keys": {
      "Word": 4900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一般来说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībānláishuō",
      "PrimaryDefinition": "generally speaking"
    },
    "Keys": {
      "Word": 3098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "work"
    },
    "Keys": {
      "Radical": 48,
      "Hanzi": 26
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "48",
      "GeneralStandard": 26,
      "Radical": "工",
      "HSK": 1,
      "FrequencyRank": 118,
      "StrokeCount": 3
    }
  },
  "满足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎnzú",
      "PrimaryDefinition": "fulfill, fit, cope with, fill, be satisfied/contented, gloat, contented, arrive, be satisfied, satisfy, sate, gratify, serve, meet, appease, replete, supply, satisfied, fulfill, suffice, assuage, satiate, content, be contented, conform to, suit, match"
    },
    "Keys": {
      "Word": 1727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "其后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíhòu",
      "PrimaryDefinition": "next, later, after that"
    },
    "Keys": {
      "Word": 8595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "a pit, trap; the appearance of a small, meandering stream"
    },
    "Keys": {
      "Hanzi": 6611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6611,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "test, examine, inspect; verify"
    },
    "Keys": {
      "Hanzi": 2152,
      "Word": 10212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.7",
      "Grade": 7,
      "GeneralStandard": 2152,
      "Radical": "馬",
      "HSK": 3,
      "FrequencyRank": 534,
      "StrokeCount": 10
    }
  },
  "逦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "meandering, winding"
    },
    "Keys": {
      "Hanzi": 4470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4470,
      "Radical": "辵",
      "FrequencyRank": 5471,
      "RadicalIndex": "162.7",
      "StrokeCount": 10
    }
  },
  "膻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "a rank odor; a technical term from Chinese medicine for the center of the chest"
    },
    "Keys": {
      "Hanzi": 6273
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6273,
      "Radical": "肉",
      "FrequencyRank": 5647,
      "RadicalIndex": "130.13",
      "StrokeCount": 17
    }
  },
  "衃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7022,
      "Radical": "血",
      "RadicalIndex": "143.4",
      "StrokeCount": 10
    }
  },
  "寰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "great domain, country, world"
    },
    "Keys": {
      "Hanzi": 6183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6183,
      "Radical": "宀",
      "FrequencyRank": 3963,
      "RadicalIndex": "40.13",
      "StrokeCount": 16
    }
  },
  "通知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngzhī",
      "PrimaryDefinition": "notify, inform"
    },
    "Keys": {
      "Word": 1034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "海关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiguān",
      "PrimaryDefinition": "chophouse, customhouse, customshouse, custom, haikwan, customs, douane"
    },
    "Keys": {
      "Word": 1538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "欺骗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīpiàn",
      "PrimaryDefinition": "cheat, dupe"
    },
    "Keys": {
      "Word": 8590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不见得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjiànde",
      "PrimaryDefinition": "not likely, not necessarily/likely, likely, unlikely, not necessarily"
    },
    "Keys": {
      "Word": 5672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "形成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngchéng",
      "PrimaryDefinition": "take shape, form"
    },
    "Keys": {
      "Word": 2056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "月饼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèbing",
      "PrimaryDefinition": "moon cake"
    },
    "Keys": {
      "Word": 4161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "詟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "fear; envy; loquacious"
    },
    "Keys": {
      "Hanzi": 7342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7342,
      "Radical": "言",
      "FrequencyRank": 8417,
      "RadicalIndex": "149.5",
      "StrokeCount": 12
    }
  },
  "宗旨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōngzhǐ",
      "PrimaryDefinition": "animus, object, purpose, aim, religion, bearing"
    },
    "Keys": {
      "Word": 10979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窗口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāngkǒu",
      "PrimaryDefinition": "window, wicket, ticket window"
    },
    "Keys": {
      "Word": 4448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "郝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎo",
      "PrimaryDefinition": "surname; place in modern Shanxi"
    },
    "Keys": {
      "Hanzi": 4103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4103,
      "Radical": "邑",
      "FrequencyRank": 3094,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "㳚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "flowing of the water"
    },
    "Keys": {
      "Hanzi": 6884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6884,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "潞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "river in northern china"
    },
    "Keys": {
      "Hanzi": 6176
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6176,
      "Radical": "水",
      "FrequencyRank": 4263,
      "RadicalIndex": "85.12",
      "StrokeCount": 16
    }
  },
  "詹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "surname; talk too much, verbose"
    },
    "Keys": {
      "Hanzi": 5524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5524,
      "Radical": "言",
      "FrequencyRank": 2533,
      "RadicalIndex": "149.6",
      "StrokeCount": 13
    }
  },
  "淴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7237,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "镓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "gallium"
    },
    "Keys": {
      "Hanzi": 5952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5952,
      "Radical": "金",
      "FrequencyRank": 6079,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "嫠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "a widow"
    },
    "Keys": {
      "Hanzi": 5627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5627,
      "Radical": "女",
      "FrequencyRank": 5201,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "登陆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng lù",
      "PrimaryDefinition": "to land, to come ashore, to make landfall (of typhoon etc), to log in"
    },
    "Keys": {
      "Word": 6222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǘ",
      "PrimaryDefinition": "village of twenty-five families"
    },
    "Keys": {
      "Hanzi": 4317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4317,
      "Radical": "門",
      "FrequencyRank": 4175,
      "RadicalIndex": "169.6",
      "StrokeCount": 9
    }
  },
  "动弹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngtan",
      "PrimaryDefinition": "to budge"
    },
    "Keys": {
      "Word": 6337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陆军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùjūn",
      "PrimaryDefinition": "army"
    },
    "Keys": {
      "Word": 4841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "樽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūn",
      "PrimaryDefinition": "goblet; jar, jug; lush"
    },
    "Keys": {
      "Hanzi": 6062
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6062,
      "Radical": "木",
      "FrequencyRank": 4612,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "躯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "body"
    },
    "Keys": {
      "Hanzi": 2335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "158.4",
      "GeneralStandard": 2335,
      "Radical": "身",
      "HSK": 9,
      "FrequencyRank": 2310,
      "StrokeCount": 11
    }
  },
  "抵制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐzhì",
      "PrimaryDefinition": "boycott"
    },
    "Keys": {
      "Word": 6242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "terminate, end, finish; quit"
    },
    "Keys": {
      "Hanzi": 2736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "117.7",
      "GeneralStandard": 2736,
      "Radical": "立",
      "HSK": 9,
      "FrequencyRank": 3704,
      "StrokeCount": 12
    }
  },
  "裒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "póu",
      "PrimaryDefinition": "collect, gather, assemble; praise"
    },
    "Keys": {
      "Hanzi": 5252
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5252,
      "Radical": "衣",
      "FrequencyRank": 6787,
      "RadicalIndex": "145.7",
      "StrokeCount": 12
    }
  },
  "爱国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àiguó",
      "PrimaryDefinition": "love one's country, patriotic"
    },
    "Keys": {
      "Word": 2229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "叛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "rebel; rebellion; rebellious"
    },
    "Keys": {
      "Hanzi": 1644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "29.7",
      "GeneralStandard": 1644,
      "Radical": "又",
      "HSK": 7,
      "FrequencyRank": 1779,
      "StrokeCount": 9
    }
  },
  "谅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng",
      "PrimaryDefinition": "excuse, forgive; guess, presume"
    },
    "Keys": {
      "Hanzi": 2125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "GeneralStandard": 2125,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 2120,
      "StrokeCount": 10
    }
  },
  "划时代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàshídài",
      "PrimaryDefinition": "epoch-marking"
    },
    "Keys": {
      "Word": 7145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "butterfly"
    },
    "Keys": {
      "Hanzi": 3245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.9",
      "GeneralStandard": 3245,
      "Radical": "虫",
      "HSK": 5,
      "FrequencyRank": 2306,
      "StrokeCount": 15
    }
  },
  "遇险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù xiǎn",
      "PrimaryDefinition": "to get into difficulties, to meet with danger"
    },
    "Keys": {
      "Word": 10525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瀼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráng",
      "PrimaryDefinition": "river in Henan province; flowing"
    },
    "Keys": {
      "Hanzi": 8068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8068,
      "Radical": "水",
      "RadicalIndex": "85.17",
      "StrokeCount": 20
    }
  },
  "唢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒ",
      "PrimaryDefinition": "flute-like musical instrument"
    },
    "Keys": {
      "Hanzi": 4514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4514,
      "Radical": "口",
      "FrequencyRank": 6003,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "借条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiètiáo",
      "PrimaryDefinition": "receipt for a loan, IOU"
    },
    "Keys": {
      "Word": 7555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "go to, arrive, been to"
    },
    "Keys": {
      "Hanzi": 1063,
      "Word": 65
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 1,
      "GeneralStandard": 1063,
      "Radical": "刀",
      "HSK": 1,
      "FrequencyRank": 22,
      "StrokeCount": 8
    }
  },
  "腐蚀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔshí",
      "PrimaryDefinition": "corrosion, to corrode (degrade chemically), to rot, corruption"
    },
    "Keys": {
      "Word": 6620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菜单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càidān",
      "PrimaryDefinition": "menu, CL:份[fen4],張|张[zhang1]"
    },
    "Keys": {
      "Word": 542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "擅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "monopolize; claim; arbitrarily; to dare"
    },
    "Keys": {
      "Hanzi": 3318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.13",
      "GeneralStandard": 3318,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2377,
      "StrokeCount": 16
    }
  },
  "评论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínglùn",
      "PrimaryDefinition": "comment, commentary, review"
    },
    "Keys": {
      "Word": 3781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "佻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāo",
      "PrimaryDefinition": "frivolous; unsteady; delay"
    },
    "Keys": {
      "Hanzi": 3961
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3961,
      "Radical": "人",
      "FrequencyRank": 5161,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "white (silk); plain; vegetarian; formerly; normally"
    },
    "Keys": {
      "Hanzi": 1775,
      "Word": 9350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "Grade": 7,
      "GeneralStandard": 1775,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 661,
      "StrokeCount": 10
    }
  },
  "理财": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ cái",
      "PrimaryDefinition": "financial management, finance"
    },
    "Keys": {
      "Word": 4816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "濂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "a waterfall; a river in Hunan"
    },
    "Keys": {
      "Hanzi": 6181
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6181,
      "Radical": "水",
      "FrequencyRank": 4313,
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "贯彻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guànchè",
      "PrimaryDefinition": "carry out, implement"
    },
    "Keys": {
      "Word": 6887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4603,
      "Radical": "犬",
      "FrequencyRank": 6644,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "前期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánqī",
      "PrimaryDefinition": "preceding period, early stage"
    },
    "Keys": {
      "Word": 8664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "要么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàome",
      "PrimaryDefinition": "or, either one or the other"
    },
    "Keys": {
      "Word": 5224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "遥控": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáokòng",
      "PrimaryDefinition": "remote control"
    },
    "Keys": {
      "Word": 10239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跑道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǎodào",
      "PrimaryDefinition": "track, lane, starting-rail, runway, course, chicane, tract, racetrack, racecourse, raceway"
    },
    "Keys": {
      "Word": 8478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4092
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4092,
      "Radical": "玉",
      "FrequencyRank": 5061,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "羸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "weak, lean, emaciated, exhausted"
    },
    "Keys": {
      "Hanzi": 6410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6410,
      "Radical": "羊",
      "FrequencyRank": 5419,
      "RadicalIndex": "123.13",
      "StrokeCount": 19
    }
  },
  "悃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔn",
      "PrimaryDefinition": "sincere, genuine, honest, loyal"
    },
    "Keys": {
      "Hanzi": 7068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7068,
      "Radical": "心",
      "FrequencyRank": 6583,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "旺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàng",
      "PrimaryDefinition": "prosper; prosperous; increase"
    },
    "Keys": {
      "Hanzi": 1076,
      "Word": 9682
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "Grade": 7,
      "GeneralStandard": 1076,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 2198,
      "StrokeCount": 8
    }
  },
  "角度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎodù",
      "PrimaryDefinition": "aspect, perspective, degree, arcdegree, point of view, angle"
    },
    "Keys": {
      "Word": 768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蚺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rán",
      "PrimaryDefinition": "a boa constrictor"
    },
    "Keys": {
      "Hanzi": 7167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7167,
      "Radical": "虫",
      "FrequencyRank": 6411,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "商量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngliang",
      "PrimaryDefinition": "talk, consult, arrange, confer, talk over, discussion, advise, discuss, concert"
    },
    "Keys": {
      "Word": 962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "野": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yě",
      "PrimaryDefinition": "open country, field; wilderness"
    },
    "Keys": {
      "Hanzi": 2265,
      "Word": 5226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "166.4",
      "Grade": 6,
      "GeneralStandard": 2265,
      "Radical": "里",
      "HSK": 6,
      "FrequencyRank": 845,
      "StrokeCount": 11
    }
  },
  "群": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qún",
      "PrimaryDefinition": "(same as 羣) group, crowd, multitude, mob"
    },
    "Keys": {
      "Hanzi": 3035,
      "Word": 1838
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "123.7",
      "Grade": 3,
      "GeneralStandard": 3035,
      "Radical": "羊",
      "HSK": 3,
      "FrequencyRank": 570,
      "StrokeCount": 13
    }
  },
  "合法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héfǎ",
      "PrimaryDefinition": "legal, well-formed, rightful, lawful, legitimate"
    },
    "Keys": {
      "Word": 1544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "爬山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páshān",
      "PrimaryDefinition": "mountaineer, climb mountain, climb"
    },
    "Keys": {
      "Word": 896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "畸形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīxíng",
      "PrimaryDefinition": "deformity, abnormality"
    },
    "Keys": {
      "Word": 7278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一辈子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībèizi",
      "PrimaryDefinition": "(for) a lifetime"
    },
    "Keys": {
      "Word": 4103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "继承": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìchéng",
      "PrimaryDefinition": "inherit, carry on"
    },
    "Keys": {
      "Word": 3583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "车间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chējiān",
      "PrimaryDefinition": "workshop, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 5838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénglì",
      "PrimaryDefinition": "found, establish, be tenable, hold water"
    },
    "Keys": {
      "Word": 1351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "槛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎn",
      "PrimaryDefinition": "threshold, door-sill"
    },
    "Keys": {
      "Hanzi": 3079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "GeneralStandard": 3079,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 3379,
      "StrokeCount": 14
    }
  },
  "广义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎngyì",
      "PrimaryDefinition": "broad sense"
    },
    "Keys": {
      "Word": 6907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "崡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7175
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7175,
      "Radical": "山",
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "捕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔ",
      "PrimaryDefinition": "arrest, catch, seize"
    },
    "Keys": {
      "Hanzi": 1783,
      "Word": 4354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 6,
      "GeneralStandard": 1783,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1312,
      "StrokeCount": 10
    }
  },
  "总是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngshì",
      "PrimaryDefinition": "always"
    },
    "Keys": {
      "Word": 2219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "舵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": "rudder, helm"
    },
    "Keys": {
      "Hanzi": 2346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137.5",
      "GeneralStandard": 2346,
      "Radical": "舟",
      "HSK": 9,
      "FrequencyRank": 3088,
      "StrokeCount": 11
    }
  },
  "平台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngtái",
      "PrimaryDefinition": "patio, terrace, hathpace, platform"
    },
    "Keys": {
      "Word": 4910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "流露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúlù",
      "PrimaryDefinition": "betray/reveal unintentionally"
    },
    "Keys": {
      "Word": 8101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎn",
      "PrimaryDefinition": "distant, remote, far; profound"
    },
    "Keys": {
      "Hanzi": 608,
      "Word": 448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 1,
      "GeneralStandard": 608,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 386,
      "StrokeCount": 7
    }
  },
  "崤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáo",
      "PrimaryDefinition": "mountain in Henan"
    },
    "Keys": {
      "Hanzi": 4860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4860,
      "Radical": "山",
      "FrequencyRank": 5905,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "就读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùdú",
      "PrimaryDefinition": "study, attend school"
    },
    "Keys": {
      "Word": 7668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "战术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànshù",
      "PrimaryDefinition": "tactical, war, tactics, military tactics, tactic, swordcraft"
    },
    "Keys": {
      "Word": 5320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "基层": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīcéng",
      "PrimaryDefinition": "grass-roots unit, basic/primary level"
    },
    "Keys": {
      "Word": 7274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoyú",
      "PrimaryDefinition": "greater than, to exceed"
    },
    "Keys": {
      "Word": 3488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "筠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "bamboo skin; bamboo"
    },
    "Keys": {
      "Hanzi": 5500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5500,
      "Radical": "竹",
      "FrequencyRank": 4581,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "琮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóng",
      "PrimaryDefinition": "octagonal piece of jade with hole in middle"
    },
    "Keys": {
      "Hanzi": 5052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5052,
      "Radical": "玉",
      "FrequencyRank": 5336,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "腻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "greasy, oily, dirty; smooth"
    },
    "Keys": {
      "Hanzi": 2968
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.9",
      "GeneralStandard": 2968,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 2819,
      "StrokeCount": 13
    }
  },
  "逞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chěng",
      "PrimaryDefinition": "indulge oneself; brag, show off"
    },
    "Keys": {
      "Hanzi": 1886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "GeneralStandard": 1886,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 3175,
      "StrokeCount": 10
    }
  },
  "伭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "dark, somber; deep, profound"
    },
    "Keys": {
      "Hanzi": 6603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6603,
      "Radical": "人",
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "比起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐqǐ",
      "PrimaryDefinition": "compared with"
    },
    "Keys": {
      "Word": 5583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "a marine crab"
    },
    "Keys": {
      "Hanzi": 7355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7355,
      "Radical": "虫",
      "FrequencyRank": 5555,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "社论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèlùn",
      "PrimaryDefinition": "editorial (in a newspaper), CL:篇[pian1]"
    },
    "Keys": {
      "Word": 9009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèzhì",
      "PrimaryDefinition": "special, unique"
    },
    "Keys": {
      "Word": 9450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎn",
      "PrimaryDefinition": "fried round cakes of wheat flour"
    },
    "Keys": {
      "Hanzi": 5978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5978,
      "Radical": "食",
      "FrequencyRank": 5269,
      "RadicalIndex": "184.12",
      "StrokeCount": 15
    }
  },
  "搦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuò",
      "PrimaryDefinition": "grasp, seize, take in hand"
    },
    "Keys": {
      "Hanzi": 5365
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5365,
      "Radical": "手",
      "FrequencyRank": 6562,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "疔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "carbuncle, boil, ulcer"
    },
    "Keys": {
      "Hanzi": 3758
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3758,
      "Radical": "疒",
      "FrequencyRank": 6359,
      "RadicalIndex": "104.2",
      "StrokeCount": 7
    }
  },
  "体检": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐjiǎn",
      "PrimaryDefinition": "abbr. for 體格檢查|体格检查[ti3 ge2 jian3 cha2]"
    },
    "Keys": {
      "Word": 2926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "拱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒng",
      "PrimaryDefinition": "fold hands on breast; bow, salute"
    },
    "Keys": {
      "Hanzi": 1369,
      "Word": 6802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1369,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 2573,
      "StrokeCount": 9
    }
  },
  "统": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒng",
      "PrimaryDefinition": "govern, command, control; unite"
    },
    "Keys": {
      "Hanzi": 1765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "GeneralStandard": 1765,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 264,
      "StrokeCount": 9
    }
  },
  "出演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūyǎn",
      "PrimaryDefinition": "to appear (in a show etc), an appearance (on stage etc)"
    },
    "Keys": {
      "Word": 5973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "cinnabar, vermilion; surname"
    },
    "Keys": {
      "Hanzi": 445
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "GeneralStandard": 445,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 1120,
      "StrokeCount": 6
    }
  },
  "滧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiao",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7595,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "莫过于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòguòyú",
      "PrimaryDefinition": "nothing is more...than"
    },
    "Keys": {
      "Word": 8330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7860,
      "Radical": "日",
      "RadicalIndex": "72.12",
      "StrokeCount": 16
    }
  },
  "大事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshì",
      "PrimaryDefinition": "important matter, nevermind, capper, overall situation, important events, major event, significant national events, major issue"
    },
    "Keys": {
      "Word": 3384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瞰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn",
      "PrimaryDefinition": "watch, spy; overlook; look down"
    },
    "Keys": {
      "Hanzi": 6086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6086,
      "Radical": "目",
      "FrequencyRank": 3895,
      "RadicalIndex": "109.12",
      "StrokeCount": 16
    }
  },
  "不论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùlùn",
      "PrimaryDefinition": "conj.: no matter how/who/what/etc."
    },
    "Keys": {
      "Word": 1315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "辊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔn",
      "PrimaryDefinition": "turn round, revolve; roller"
    },
    "Keys": {
      "Hanzi": 5117
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5117,
      "Radical": "車",
      "FrequencyRank": 5845,
      "RadicalIndex": "159.8",
      "StrokeCount": 12
    }
  },
  "皇宫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánggōng",
      "PrimaryDefinition": "imperial palace"
    },
    "Keys": {
      "Word": 7189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "resent, hate; indignant"
    },
    "Keys": {
      "Hanzi": 2770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2770,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1508,
      "StrokeCount": 12
    }
  },
  "屦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "straw sandals; tread on"
    },
    "Keys": {
      "Hanzi": 6016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6016,
      "Radical": "尸",
      "FrequencyRank": 6987,
      "RadicalIndex": "44.12",
      "StrokeCount": 15
    }
  },
  "外科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàikē",
      "PrimaryDefinition": "surgery, chirurgery, surgical, surgical department"
    },
    "Keys": {
      "Word": 5120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "核桃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hétao",
      "PrimaryDefinition": "walnut, CL:個|个[ge4],棵[ke1]"
    },
    "Keys": {
      "Word": 7050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "威慑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēishè",
      "PrimaryDefinition": "to cower by military force, deterrence"
    },
    "Keys": {
      "Word": 9687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相依为命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngyī-wéimìng",
      "PrimaryDefinition": "mutually dependent for life (idiom), to rely upon one another for survival, interdependent"
    },
    "Keys": {
      "Word": 9928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仅仅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnjǐn",
      "PrimaryDefinition": "strictly, solely, exclusively, entirely, alone, merely, only, purely, simply, scantly, narrowly, barely, just"
    },
    "Keys": {
      "Word": 1629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "自信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìxìn",
      "PrimaryDefinition": "self-confidence"
    },
    "Keys": {
      "Word": 3210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "綮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "embroidered banner"
    },
    "Keys": {
      "Hanzi": 5817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5817,
      "Radical": "糸",
      "FrequencyRank": 6685,
      "RadicalIndex": "120.8",
      "StrokeCount": 14
    }
  },
  "保存": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎocún",
      "PrimaryDefinition": "preserve, conserve, keep"
    },
    "Keys": {
      "Word": 1277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "眼光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnguāng",
      "PrimaryDefinition": "brainstorm, eye, penetration, view, brainwave, way of looking at things, foresight, discernment, insight, vision, judgment, sight, taste"
    },
    "Keys": {
      "Word": 4093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "低于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīyú",
      "PrimaryDefinition": "be lower than"
    },
    "Keys": {
      "Word": 3414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "爸爸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàba",
      "PrimaryDefinition": "old man, father, papa, pappa, daddy, pa, begetter, pop, dada, dad, male parent, pater, pappy, pops, poppa"
    },
    "Keys": {
      "Word": 3
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "跃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "skip, jump, frolic"
    },
    "Keys": {
      "Hanzi": 2276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.4",
      "GeneralStandard": 2276,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 1516,
      "StrokeCount": 11
    }
  },
  "筹备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóubèi",
      "PrimaryDefinition": "prepare, arrange, plan"
    },
    "Keys": {
      "Word": 5944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "fruit"
    },
    "Keys": {
      "Hanzi": 5101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5101,
      "Radical": "木",
      "FrequencyRank": 4031,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "混乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùnluàn",
      "PrimaryDefinition": "confused, chaotic"
    },
    "Keys": {
      "Word": 4686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "辞职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí zhí",
      "PrimaryDefinition": "resign"
    },
    "Keys": {
      "Word": 3360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "掀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "lift, raise; stir"
    },
    "Keys": {
      "Hanzi": 2176,
      "Word": 9885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2176,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2468,
      "StrokeCount": 11
    }
  },
  "杀手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāshǒu",
      "PrimaryDefinition": "killer, murderer, hit man, (sports) formidable player"
    },
    "Keys": {
      "Word": 8934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "齉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàng",
      "PrimaryDefinition": "stoppage of the nose to speak with a nasal twang"
    },
    "Keys": {
      "Hanzi": 6500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6500,
      "Radical": "鼻",
      "RadicalIndex": "209.22",
      "StrokeCount": 36
    }
  },
  "椪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèng",
      "PrimaryDefinition": "Machilus nanmu, variety of evergreen"
    },
    "Keys": {
      "Hanzi": 5103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5103,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "搞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "to clear, clarify"
    },
    "Keys": {
      "Hanzi": 2842,
      "Word": 3490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 5,
      "GeneralStandard": 2842,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1146,
      "StrokeCount": 13
    }
  },
  "旆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "flag ornament; flags, banners"
    },
    "Keys": {
      "Hanzi": 4626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4626,
      "Radical": "方",
      "FrequencyRank": 5436,
      "RadicalIndex": "70.6",
      "StrokeCount": 10
    }
  },
  "酿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàng",
      "PrimaryDefinition": "brew, ferment"
    },
    "Keys": {
      "Hanzi": 3088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.7",
      "GeneralStandard": 3088,
      "Radical": "酉",
      "HSK": 8,
      "FrequencyRank": 2764,
      "StrokeCount": 14
    }
  },
  "度过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùguò",
      "PrimaryDefinition": "pass, spend (time/etc.)"
    },
    "Keys": {
      "Word": 2429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "意料之外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìliàozhīwài",
      "PrimaryDefinition": "contrary to expectation, unexpected"
    },
    "Keys": {
      "Word": 10374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒ",
      "PrimaryDefinition": "hide, secrete; avoid, escape"
    },
    "Keys": {
      "Hanzi": 2962,
      "Word": 3439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "158.6",
      "Grade": 5,
      "GeneralStandard": 2962,
      "Radical": "身",
      "HSK": 5,
      "FrequencyRank": 1644,
      "StrokeCount": 13
    }
  },
  "理睬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐcǎi",
      "PrimaryDefinition": "to heed, to pay attention to"
    },
    "Keys": {
      "Word": 7982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枢纽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūniǔ",
      "PrimaryDefinition": "hub (e.g. of traffic network), hinge, pivot, fulcrum"
    },
    "Keys": {
      "Word": 9213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánchéng",
      "PrimaryDefinition": "the whole distance, from beginning to end"
    },
    "Keys": {
      "Word": 8801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒng",
      "PrimaryDefinition": "mountain located between Shanxi"
    },
    "Keys": {
      "Hanzi": 3802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3802,
      "Radical": "阜",
      "FrequencyRank": 3353,
      "RadicalIndex": "170.5",
      "StrokeCount": 7
    }
  },
  "散步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàn bù",
      "PrimaryDefinition": "take a walk"
    },
    "Keys": {
      "Word": 1863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "镜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "mirror; lens; glass; glasses"
    },
    "Keys": {
      "Hanzi": 3354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.11",
      "GeneralStandard": 3354,
      "Radical": "金",
      "HSK": 4,
      "FrequencyRank": 1251,
      "StrokeCount": 16
    }
  },
  "闹钟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàozhōng",
      "PrimaryDefinition": "alarm clock"
    },
    "Keys": {
      "Word": 2760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "砟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎ",
      "PrimaryDefinition": "stone tablet; monument"
    },
    "Keys": {
      "Hanzi": 4478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4478,
      "Radical": "石",
      "FrequencyRank": 6429,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "时好时坏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíhǎoshíhuài",
      "PrimaryDefinition": "sometimes good, sometimes bad"
    },
    "Keys": {
      "Word": 9107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "套": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tào",
      "PrimaryDefinition": "case, cover, wrapper, envelope"
    },
    "Keys": {
      "Hanzi": 1869,
      "Word": 1011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.7",
      "Grade": 2,
      "GeneralStandard": 1869,
      "Radical": "大",
      "HSK": 2,
      "FrequencyRank": 1091,
      "StrokeCount": 10
    }
  },
  "经久不息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngjiǔ-bùxī",
      "PrimaryDefinition": "Last forever"
    },
    "Keys": {
      "Word": 7593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "视野": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyě",
      "PrimaryDefinition": "visual_field, eyeshot, view, ken, horizon, visual field, field of regard, purview, field of view, coast, sight, eyesight, field of vision, field, sight-shot, eyereach"
    },
    "Keys": {
      "Word": 9153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "儳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "obstinate; stupid; uneven; mix"
    },
    "Keys": {
      "Hanzi": 8039
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8039,
      "Radical": "人",
      "RadicalIndex": "9.17",
      "StrokeCount": 19
    }
  },
  "帐子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàngzi",
      "PrimaryDefinition": "mosquito net, CL:頂|顶[ding3]"
    },
    "Keys": {
      "Word": 10656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēng",
      "PrimaryDefinition": "river in Shandong; space inside"
    },
    "Keys": {
      "Hanzi": 6729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6729,
      "Radical": "示",
      "RadicalIndex": "113.4",
      "StrokeCount": 8
    }
  },
  "冢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒng",
      "PrimaryDefinition": "burial mound, mausoleum; grand"
    },
    "Keys": {
      "Hanzi": 4669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4669,
      "Radical": "冖",
      "FrequencyRank": 2840,
      "RadicalIndex": "14.8",
      "StrokeCount": 10
    }
  },
  "返还": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnhuán",
      "PrimaryDefinition": "restitution, return of something to its original owner, remittance"
    },
    "Keys": {
      "Word": 6478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小卒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎozú",
      "PrimaryDefinition": "foot soldier, minor figure, a nobody, (chess) pawn"
    },
    "Keys": {
      "Word": 9978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "花瓶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāpíng",
      "PrimaryDefinition": "flower vase, fig. just a pretty face, CL:對|对[dui4]"
    },
    "Keys": {
      "Word": 4672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "董事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒngshì",
      "PrimaryDefinition": "director, trustee"
    },
    "Keys": {
      "Word": 6326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "surname; bee-like insect"
    },
    "Keys": {
      "Hanzi": 1010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1010,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 705,
      "StrokeCount": 9
    }
  },
  "可乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kělè",
      "PrimaryDefinition": "amusing, entertaining, (loanword) cola"
    },
    "Keys": {
      "Word": 1673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "重心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngxīn",
      "PrimaryDefinition": "focus, fulcrum, heart, focusing, focussing, barycenter, centroid, centre of gravity, centering, centre_of_gravity, center of gravity, center_of_gravity, core"
    },
    "Keys": {
      "Word": 10838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奔跑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēnpǎo",
      "PrimaryDefinition": "to run"
    },
    "Keys": {
      "Word": 4316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐ",
      "PrimaryDefinition": "water"
    },
    "Keys": {
      "Hanzi": 117,
      "Radical": 85,
      "Word": 341
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85",
      "Grade": 1,
      "GeneralStandard": 117,
      "Radical": "水",
      "HSK": 1,
      "FrequencyRank": 202,
      "StrokeCount": 4
    }
  },
  "首": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒu",
      "PrimaryDefinition": "head"
    },
    "Keys": {
      "Hanzi": 1651,
      "Radical": 185,
      "Word": 5034
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "185",
      "Grade": 6,
      "GeneralStandard": 1651,
      "Radical": "首",
      "HSK": 3,
      "FrequencyRank": 481,
      "StrokeCount": 9
    }
  },
  "郊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "suburbs; waste land, open spaces"
    },
    "Keys": {
      "Hanzi": 1218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.6",
      "GeneralStandard": 1218,
      "Radical": "邑",
      "HSK": 5,
      "FrequencyRank": 2351,
      "StrokeCount": 8
    }
  },
  "退却": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuìquè",
      "PrimaryDefinition": "to retreat, to shrink back"
    },
    "Keys": {
      "Word": 9605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "住处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùchu",
      "PrimaryDefinition": "domicile, hangout, dwelling, lodging, residency, domiciliation, diggings, abode, digs, quarters, habitation, lodgings, roost, living quarters, pad, residence"
    },
    "Keys": {
      "Word": 10867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喉咙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóulong",
      "PrimaryDefinition": "throat"
    },
    "Keys": {
      "Word": 7087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāi",
      "PrimaryDefinition": "fall ground, stumble, trip"
    },
    "Keys": {
      "Hanzi": 3063,
      "Word": 3929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.11",
      "Grade": 5,
      "GeneralStandard": 3063,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 2270,
      "StrokeCount": 14
    }
  },
  "出口成章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūkǒu-chéngzhāng",
      "PrimaryDefinition": "to speak like a printed book, quick and clever talking, the gift of the gab"
    },
    "Keys": {
      "Word": 5959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喜剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐjù",
      "PrimaryDefinition": "Thalia, comedy, sock"
    },
    "Keys": {
      "Word": 4025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "支柱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīzhù",
      "PrimaryDefinition": "mainstay, pillar, prop, backbone"
    },
    "Keys": {
      "Word": 10744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuó",
      "PrimaryDefinition": "rich"
    },
    "Keys": {
      "Hanzi": 5215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5215,
      "Radical": "人",
      "FrequencyRank": 3777,
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "邀请": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāoqǐng",
      "PrimaryDefinition": "invite"
    },
    "Keys": {
      "Word": 4092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "钇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "yttrium"
    },
    "Keys": {
      "Hanzi": 3597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3597,
      "Radical": "金",
      "FrequencyRank": 5910,
      "RadicalIndex": "167.1",
      "StrokeCount": 6
    }
  },
  "挥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "direct; wipe away; squander"
    },
    "Keys": {
      "Hanzi": 1396,
      "Word": 7201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1396,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 742,
      "StrokeCount": 9
    }
  },
  "招收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāoshōu",
      "PrimaryDefinition": "recruit, take in"
    },
    "Keys": {
      "Word": 10670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7225,
      "Radical": "立",
      "RadicalIndex": "117.8",
      "StrokeCount": 11
    }
  },
  "拧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "pinch; twist, wring; determined"
    },
    "Keys": {
      "Hanzi": 985,
      "Word": 8427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 985,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3178,
      "StrokeCount": 8
    }
  },
  "丏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "parapet; invisible"
    },
    "Keys": {
      "Hanzi": 3511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3511,
      "Radical": "一",
      "RadicalIndex": "1.3",
      "StrokeCount": 4
    }
  },
  "通顺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngshùn",
      "PrimaryDefinition": "smooth, clear and coherent"
    },
    "Keys": {
      "Word": 9524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "突然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūrán",
      "PrimaryDefinition": "suddenly, presto, unexpectedly, abruptly, at once, all_at_once, all together, all of a sudden, all at once"
    },
    "Keys": {
      "Word": 1982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "锦旗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnqí",
      "PrimaryDefinition": "Banner"
    },
    "Keys": {
      "Word": 7573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "槽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáo",
      "PrimaryDefinition": "trough; manger; vat, tank; groove; a distillery"
    },
    "Keys": {
      "Hanzi": 3214,
      "Word": 5773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.11",
      "Grade": 7,
      "GeneralStandard": 3214,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2993,
      "StrokeCount": 15
    }
  },
  "执着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhízhuó",
      "PrimaryDefinition": "rigid, punctilious"
    },
    "Keys": {
      "Word": 10756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "warm, comfortable; warm, genial"
    },
    "Keys": {
      "Hanzi": 5449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.9",
      "GeneralStandard": 5449,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 3886,
      "StrokeCount": 13
    }
  },
  "华丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huálì",
      "PrimaryDefinition": "magnificent, gorgeous"
    },
    "Keys": {
      "Word": 7129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "fearful, cautious, wary"
    },
    "Keys": {
      "Hanzi": 3077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "10.12",
      "GeneralStandard": 3077,
      "Radical": "儿",
      "HSK": 9,
      "FrequencyRank": 3117,
      "StrokeCount": 14
    }
  },
  "疫苗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìmiáo",
      "PrimaryDefinition": "vaccine"
    },
    "Keys": {
      "Word": 10371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "设施": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèshī",
      "PrimaryDefinition": "installation, facilities"
    },
    "Keys": {
      "Word": 2849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "不怎么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzěnme",
      "PrimaryDefinition": "not very, not particularly"
    },
    "Keys": {
      "Word": 4360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "开场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi chǎng",
      "PrimaryDefinition": "to begin, to open, to start, beginning of an event"
    },
    "Keys": {
      "Word": 7742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伤害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānghài",
      "PrimaryDefinition": "harm"
    },
    "Keys": {
      "Word": 2837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "备用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèiyòng",
      "PrimaryDefinition": "reserve, spare, alternate, backup"
    },
    "Keys": {
      "Word": 5557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "mayfly; kind of large insect"
    },
    "Keys": {
      "Hanzi": 5462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5462,
      "Radical": "虫",
      "FrequencyRank": 5707,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "重合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chónghé",
      "PrimaryDefinition": "to match up, to coincide"
    },
    "Keys": {
      "Word": 5925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "眯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mī",
      "PrimaryDefinition": "be blinded"
    },
    "Keys": {
      "Hanzi": 2262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.6",
      "GeneralStandard": 2262,
      "Radical": "目",
      "HSK": 6,
      "FrequencyRank": 2955,
      "StrokeCount": 11
    }
  },
  "造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "construct, build, make, begin; prepare"
    },
    "Keys": {
      "Hanzi": 1940,
      "Word": 2133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 3,
      "GeneralStandard": 1940,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 354,
      "StrokeCount": 10
    }
  },
  "晋升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnshēng",
      "PrimaryDefinition": "to promote to a higher position"
    },
    "Keys": {
      "Word": 7586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "clear weather, fine weather"
    },
    "Keys": {
      "Hanzi": 2611,
      "Word": 935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "Grade": 2,
      "GeneralStandard": 2611,
      "Radical": "日",
      "HSK": 2,
      "FrequencyRank": 1968,
      "StrokeCount": 12
    }
  },
  "弘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "enlarge, expand; liberal, great"
    },
    "Keys": {
      "Hanzi": 329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "57.2",
      "GeneralStandard": 329,
      "Radical": "弓",
      "HSK": 9,
      "FrequencyRank": 2619,
      "StrokeCount": 5
    }
  },
  "你": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǐ",
      "PrimaryDefinition": "you, second person pronoun"
    },
    "Keys": {
      "Hanzi": 782,
      "Word": 267
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 1,
      "GeneralStandard": 782,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 32,
      "StrokeCount": 7
    }
  },
  "进行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnxíng",
      "PrimaryDefinition": "be in progress, go on, carry on/out"
    },
    "Keys": {
      "Word": 790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "啷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lāng",
      "PrimaryDefinition": "a clanging or rattling sound"
    },
    "Keys": {
      "Hanzi": 4851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4851,
      "Radical": "口",
      "FrequencyRank": 4570,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "腼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "modest"
    },
    "Keys": {
      "Hanzi": 5519
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5519,
      "Radical": "肉",
      "FrequencyRank": 4178,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "作文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòwén",
      "PrimaryDefinition": "composition"
    },
    "Keys": {
      "Word": 1253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "嵚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīn",
      "PrimaryDefinition": "lofty, towering"
    },
    "Keys": {
      "Hanzi": 7365
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7365,
      "Radical": "山",
      "RadicalIndex": "46.12",
      "StrokeCount": 12
    }
  },
  "劣势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièshì",
      "PrimaryDefinition": "inferior, disadvantaged"
    },
    "Keys": {
      "Word": 8051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "城镇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngzhèn",
      "PrimaryDefinition": "cities and towns"
    },
    "Keys": {
      "Word": 4415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "垌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "a field; used in place names"
    },
    "Keys": {
      "Hanzi": 4102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4102,
      "Radical": "土",
      "FrequencyRank": 9151,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "䴓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "general term for a group of small birds"
    },
    "Keys": {
      "Hanzi": 6806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6806,
      "Radical": "",
      "FrequencyRank": 8751,
      "RadicalIndex": "",
      "StrokeCount": 15
    }
  },
  "里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "village"
    },
    "Keys": {
      "Hanzi": 721,
      "Radical": 166,
      "Word": 210
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "166",
      "Grade": 1,
      "GeneralStandard": 721,
      "Radical": "里",
      "HSK": 1,
      "FrequencyRank": 50,
      "StrokeCount": 7
    }
  },
  "缢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "hang, strangle"
    },
    "Keys": {
      "Hanzi": 5617
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5617,
      "Radical": "糸",
      "FrequencyRank": 4965,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "艇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": "small boat, dugout, punt"
    },
    "Keys": {
      "Hanzi": 2706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137.7",
      "GeneralStandard": 2706,
      "Radical": "舟",
      "HSK": 8,
      "FrequencyRank": 1372,
      "StrokeCount": 12
    }
  },
  "时间表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjiānbiǎo",
      "PrimaryDefinition": "time_sheet, schedule, timetable, time sheet"
    },
    "Keys": {
      "Word": 9106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "千": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "thousand; many, numerous; very; (Cant.) a cheater, swindler"
    },
    "Keys": {
      "Hanzi": 41,
      "Word": 924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "24.1",
      "Grade": 2,
      "GeneralStandard": 41,
      "Radical": "十",
      "HSK": 2,
      "FrequencyRank": 599,
      "StrokeCount": 3
    }
  },
  "仿佛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎngfú",
      "PrimaryDefinition": "to seem, as if, alike, similar"
    },
    "Keys": {
      "Word": 4558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "劫持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéchí",
      "PrimaryDefinition": "to kidnap, to hijack, to abduct, to hold under duress"
    },
    "Keys": {
      "Word": 7523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "windlass, pulley, capstan; wheel"
    },
    "Keys": {
      "Hanzi": 5898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5898,
      "Radical": "車",
      "FrequencyRank": 3819,
      "RadicalIndex": "159.11",
      "StrokeCount": 15
    }
  },
  "广": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎng",
      "PrimaryDefinition": "shelter"
    },
    "Keys": {
      "Hanzi": 53,
      "Radical": 53,
      "Word": 3520
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53",
      "Grade": 5,
      "GeneralStandard": 53,
      "Radical": "广",
      "HSK": 2,
      "FrequencyRank": 468,
      "StrokeCount": 3
    }
  },
  "埴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "soil with large clay content"
    },
    "Keys": {
      "Hanzi": 4717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4717,
      "Radical": "土",
      "FrequencyRank": 6867,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "文化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénhuà",
      "PrimaryDefinition": "civilization, literacy, acculturation, Kultur, vandalism, cultural, education, schooling, culture"
    },
    "Keys": {
      "Word": 2012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "断定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàndìng",
      "PrimaryDefinition": "to conclude, to determine, to come to a judgment"
    },
    "Keys": {
      "Word": 6373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不相上下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxiāngshàngxià",
      "PrimaryDefinition": "equally matched, about the same"
    },
    "Keys": {
      "Word": 5715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàoqí",
      "PrimaryDefinition": "curious, full of curiosity"
    },
    "Keys": {
      "Word": 1542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "承认": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngrèn",
      "PrimaryDefinition": "receive, acknowledge, okay, approbate, validate, recognize, avow, admittance, count, confess, sanctify, grant, countersign, avouch, admit, acknowledgement, reckon, own, sanction, give diplomatic recognition, concede, cede, give, ratify, agree, recognise, accept, recognition, yield, allow, endorse, profess"
    },
    "Keys": {
      "Word": 2316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蓐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù",
      "PrimaryDefinition": "straw bed mat; rushes"
    },
    "Keys": {
      "Hanzi": 5372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5372,
      "Radical": "艸",
      "FrequencyRank": 6647,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "广播": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎngbō",
      "PrimaryDefinition": "broadcast, air"
    },
    "Keys": {
      "Word": 1525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "官吏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānlì",
      "PrimaryDefinition": "bureaucrat, official"
    },
    "Keys": {
      "Word": 6876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "name of a river in Jiangsu"
    },
    "Keys": {
      "Hanzi": 4967
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4967,
      "Radical": "水",
      "FrequencyRank": 4040,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "尊严": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnyán",
      "PrimaryDefinition": "honour, dignity, loftiness, face, worship, self-regard, majesty, sanctity, self-respect, dignified, stateliness, self-esteem, honor"
    },
    "Keys": {
      "Word": 11019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "休": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "rest, stop; retire; do not!"
    },
    "Keys": {
      "Hanzi": 458
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 458,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 1082,
      "StrokeCount": 6
    }
  },
  "听力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīnglì",
      "PrimaryDefinition": "hearing, listening ability"
    },
    "Keys": {
      "Word": 1970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "俘获": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúhuò",
      "PrimaryDefinition": "to capture (enemy property or personnel), capture (physics: absorption of subatomic particle by an atom or nucleus)"
    },
    "Keys": {
      "Word": 6603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "住宿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùsù",
      "PrimaryDefinition": "stay, put up, get accommodations"
    },
    "Keys": {
      "Word": 10871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坚守": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānshǒu",
      "PrimaryDefinition": "to hold fast to, to stick to"
    },
    "Keys": {
      "Word": 7380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "科研": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēyán",
      "PrimaryDefinition": "(scientific) research"
    },
    "Keys": {
      "Word": 4793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": "people, subjects, citizens"
    },
    "Keys": {
      "Hanzi": 327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "83.1",
      "GeneralStandard": 327,
      "Radical": "氏",
      "HSK": 3,
      "FrequencyRank": 113,
      "StrokeCount": 5
    }
  },
  "樊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "a railing; a fence an enclosed place"
    },
    "Keys": {
      "Hanzi": 5888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5888,
      "Radical": "木",
      "FrequencyRank": 3282,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "縻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "halter for ox; tie up, harness"
    },
    "Keys": {
      "Hanzi": 6288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6288,
      "Radical": "糸",
      "FrequencyRank": 5629,
      "RadicalIndex": "120.11",
      "StrokeCount": 17
    }
  },
  "惩罚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngfá",
      "PrimaryDefinition": "penalty, punishment, to punish"
    },
    "Keys": {
      "Word": 5886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "stop up, obstruct"
    },
    "Keys": {
      "Hanzi": 2456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.6",
      "GeneralStandard": 2456,
      "Radical": "穴",
      "HSK": 7,
      "FrequencyRank": 3235,
      "StrokeCount": 11
    }
  },
  "去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qù",
      "PrimaryDefinition": "go away, leave, depart"
    },
    "Keys": {
      "Hanzi": 204,
      "Word": 304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "28.3",
      "Grade": 1,
      "GeneralStandard": 204,
      "Radical": "厶",
      "HSK": 1,
      "FrequencyRank": 64,
      "StrokeCount": 5
    }
  },
  "急忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jímáng",
      "PrimaryDefinition": "in haste, hurriedly"
    },
    "Keys": {
      "Word": 2582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "伋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "deceptive"
    },
    "Keys": {
      "Hanzi": 6512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6512,
      "Radical": "人",
      "RadicalIndex": "9.4",
      "StrokeCount": 5
    }
  },
  "浐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6895,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 9
    }
  },
  "出来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū lai",
      "PrimaryDefinition": "come forward, issue, come up, step up, step to the fore, out (toward one), come_out, pop out, come out, rise, come to the fore, step forward, emergence, come forth, emerge"
    },
    "Keys": {
      "Word": 48
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "骜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "wild horse, mustang; wild"
    },
    "Keys": {
      "Hanzi": 5347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5347,
      "Radical": "馬",
      "FrequencyRank": 5565,
      "RadicalIndex": "187.1",
      "StrokeCount": 13
    }
  },
  "填写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiánxiě",
      "PrimaryDefinition": "fill in a form"
    },
    "Keys": {
      "Word": 9492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "后台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòutái",
      "PrimaryDefinition": "backstage area, behind-the-scenes supporter, (computing) back-end, background"
    },
    "Keys": {
      "Word": 7097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòfèn",
      "PrimaryDefinition": "excessively, over-"
    },
    "Keys": {
      "Word": 2526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瘭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "a whitlow"
    },
    "Keys": {
      "Hanzi": 7893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7893,
      "Radical": "疒",
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "镈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "large bell; hoe, spade"
    },
    "Keys": {
      "Hanzi": 7786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7786,
      "Radical": "金",
      "FrequencyRank": 7221,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "处方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔfāng",
      "PrimaryDefinition": "medical prescription, recipe, formula"
    },
    "Keys": {
      "Word": 5986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎ",
      "PrimaryDefinition": "small (used in place names)"
    },
    "Keys": {
      "Hanzi": 3560
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3560,
      "Radical": "小",
      "FrequencyRank": 6013,
      "RadicalIndex": "42.2",
      "StrokeCount": 5
    }
  },
  "没落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòluò",
      "PrimaryDefinition": "to decline, to wane"
    },
    "Keys": {
      "Word": 8326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "膊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "shoulders, upper arms"
    },
    "Keys": {
      "Hanzi": 3133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.1",
      "GeneralStandard": 3133,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2440,
      "StrokeCount": 14
    }
  },
  "成本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngběn",
      "PrimaryDefinition": "cost"
    },
    "Keys": {
      "Word": 3324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "演练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnliàn",
      "PrimaryDefinition": "drill, practice"
    },
    "Keys": {
      "Word": 10199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "cook"
    },
    "Keys": {
      "Hanzi": 2529,
      "Word": 5378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.8",
      "Grade": 6,
      "GeneralStandard": 2529,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 2582,
      "StrokeCount": 12
    }
  },
  "说真的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōzhēnde",
      "PrimaryDefinition": "honestly"
    },
    "Keys": {
      "Word": 9300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "补助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔzhù",
      "PrimaryDefinition": "subsidize"
    },
    "Keys": {
      "Word": 4352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "科幻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēhuàn",
      "PrimaryDefinition": "science fiction, abbr. for 科學幻想|科学幻想[ke1 xue2 huan4 xiang3]"
    },
    "Keys": {
      "Word": 7787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "beautiful jade, precious stones"
    },
    "Keys": {
      "Hanzi": 5049
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5049,
      "Radical": "玉",
      "FrequencyRank": 4557,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "诞生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànshēng",
      "PrimaryDefinition": "be born, come into being, emerge"
    },
    "Keys": {
      "Word": 4483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "完了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánliǎo",
      "PrimaryDefinition": "to be finished, to be done for, ruined, gone to the dogs, oh no"
    },
    "Keys": {
      "Word": 3995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "一定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdìng",
      "PrimaryDefinition": "must"
    },
    "Keys": {
      "Word": 1135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "笔记本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐjìběn",
      "PrimaryDefinition": "notebook (stationery), CL:本[ben3], notebook (computing)"
    },
    "Keys": {
      "Word": 519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "疍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7083,
      "Radical": "疋",
      "RadicalIndex": "103.5",
      "StrokeCount": 10
    }
  },
  "呢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ne",
      "PrimaryDefinition": "interrogative or emphatic final; (Cant.) this"
    },
    "Keys": {
      "Hanzi": 1102,
      "Word": 265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "Grade": 1,
      "GeneralStandard": 1102,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 383,
      "StrokeCount": 8
    }
  },
  "晕倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūndǎo",
      "PrimaryDefinition": "to faint, to swoon, to black out, to become unconscious"
    },
    "Keys": {
      "Word": 10570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跟前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnqian",
      "PrimaryDefinition": "the front (of), (in) front, (in) sb's presence, just before (a date), (of children, parents etc) at one's side, living with one"
    },
    "Keys": {
      "Word": 3494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "对象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìxiàng",
      "PrimaryDefinition": "boy/girl friend, mate, fair game, boy friend, prey, boy, object, quarry, target, girl friend, catch, match"
    },
    "Keys": {
      "Word": 1425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鞑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "tatars"
    },
    "Keys": {
      "Hanzi": 5871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5871,
      "Radical": "革",
      "FrequencyRank": 4128,
      "RadicalIndex": "177.6",
      "StrokeCount": 15
    }
  },
  "失控": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī kòng",
      "PrimaryDefinition": "to go out of control"
    },
    "Keys": {
      "Word": 9082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "簧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "reed of woodwind instrument"
    },
    "Keys": {
      "Hanzi": 3417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3417,
      "Radical": "竹",
      "FrequencyRank": 3370,
      "RadicalIndex": "118.12",
      "StrokeCount": 17
    }
  },
  "国宝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóbǎo",
      "PrimaryDefinition": "national treasure"
    },
    "Keys": {
      "Word": 6929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "brook, mountain stream"
    },
    "Keys": {
      "Hanzi": 2082
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2082,
      "Radical": "水",
      "FrequencyRank": 3828,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "尽头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìntóu",
      "PrimaryDefinition": "end, extremity, limit"
    },
    "Keys": {
      "Word": 7575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "些": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiē",
      "PrimaryDefinition": "little, few; rather, somewhat"
    },
    "Keys": {
      "Hanzi": 1069,
      "Word": 3032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7.5",
      "Grade": 4,
      "GeneralStandard": 1069,
      "Radical": "二",
      "HSK": 1,
      "FrequencyRank": 86,
      "StrokeCount": 8
    }
  },
  "俯首": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔshǒu",
      "PrimaryDefinition": "to bend one's head"
    },
    "Keys": {
      "Word": 6615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说起来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō qǐlái",
      "PrimaryDefinition": "Say"
    },
    "Keys": {
      "Word": 9297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妇女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùnǚ",
      "PrimaryDefinition": "feme, womankind, female, femininity, cummer, woman, femme, quean, bevy, matron, toots, womanhood, feminine, womenfolk, women"
    },
    "Keys": {
      "Word": 4575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "来临": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láilín",
      "PrimaryDefinition": "arrive, come, approach"
    },
    "Keys": {
      "Word": 7920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "weak; fragile, delicate"
    },
    "Keys": {
      "Hanzi": 2134,
      "Word": 2828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.7",
      "Grade": 4,
      "GeneralStandard": 2134,
      "Radical": "弓",
      "HSK": 4,
      "FrequencyRank": 1038,
      "StrokeCount": 10
    }
  },
  "梁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "bridge; beam; rafters; surname"
    },
    "Keys": {
      "Hanzi": 2436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "GeneralStandard": 2436,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1416,
      "StrokeCount": 11
    }
  },
  "颁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "confer, bestow; publish, promulgate"
    },
    "Keys": {
      "Hanzi": 1990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.4",
      "GeneralStandard": 1990,
      "Radical": "頁",
      "HSK": 7,
      "FrequencyRank": 2319,
      "StrokeCount": 10
    }
  },
  "荒谬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāngmiù",
      "PrimaryDefinition": "absurd, preposterous"
    },
    "Keys": {
      "Word": 7187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "七嘴八舌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīzuǐ-bāshé",
      "PrimaryDefinition": "lively discussion with everybody talking at once"
    },
    "Keys": {
      "Word": 8586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "薯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "yam, tuber, potato"
    },
    "Keys": {
      "Hanzi": 3321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.14",
      "GeneralStandard": 3321,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 3363,
      "StrokeCount": 16
    }
  },
  "滨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "beach, sea coast; river bank"
    },
    "Keys": {
      "Hanzi": 3018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3018,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2474,
      "StrokeCount": 13
    }
  },
  "开拓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāituò",
      "PrimaryDefinition": "open up, develop, enlarge/expand (territory/etc.)"
    },
    "Keys": {
      "Word": 7757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "随时随地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíshí-suídì",
      "PrimaryDefinition": "anytime and anywhere"
    },
    "Keys": {
      "Word": 9367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "根据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnjù",
      "PrimaryDefinition": "on the basis of, according to"
    },
    "Keys": {
      "Word": 2500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "改邪归正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎixié-guīzhèng",
      "PrimaryDefinition": "to mend one's ways (idiom), to turn over a new leaf"
    },
    "Keys": {
      "Word": 6658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūn",
      "PrimaryDefinition": "meat diet; strong smelling"
    },
    "Keys": {
      "Hanzi": 1416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "GeneralStandard": 1416,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 4295,
      "StrokeCount": 9
    }
  },
  "蓄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "store, save, hoard, gather"
    },
    "Keys": {
      "Hanzi": 2856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.1",
      "GeneralStandard": 2856,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 1985,
      "StrokeCount": 13
    }
  },
  "工整": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzhěng",
      "PrimaryDefinition": "fine work, carefully and neatly done"
    },
    "Keys": {
      "Word": 6756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地下室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìxiàshì",
      "PrimaryDefinition": "rumpus_room, soutane, undercroft, rooms built below ground, ground_floor, serdab, cellar, hypogeum, crypt, subterranean, silo, subterrane, souterrain, vault, cellarage, basement"
    },
    "Keys": {
      "Word": 4503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāng",
      "PrimaryDefinition": "bladder"
    },
    "Keys": {
      "Hanzi": 4590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4590,
      "Radical": "肉",
      "FrequencyRank": 3847,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "芟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "mow, cut; weed out; scythe"
    },
    "Keys": {
      "Hanzi": 3674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3674,
      "Radical": "艸",
      "FrequencyRank": 5445,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "劾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "examine into, impeach, charge"
    },
    "Keys": {
      "Hanzi": 4002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4002,
      "Radical": "力",
      "FrequencyRank": 3597,
      "RadicalIndex": "19.6",
      "StrokeCount": 8
    }
  },
  "劳务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láowù",
      "PrimaryDefinition": "service (work done for money), services (as in 'goods and services')"
    },
    "Keys": {
      "Word": 7940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "essential oil of butter"
    },
    "Keys": {
      "Hanzi": 6070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6070,
      "Radical": "酉",
      "FrequencyRank": 5966,
      "RadicalIndex": "164.9",
      "StrokeCount": 16
    }
  },
  "镗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": "boring tool"
    },
    "Keys": {
      "Hanzi": 6113
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6113,
      "Radical": "金",
      "FrequencyRank": 6229,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "耳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "ear"
    },
    "Keys": {
      "Radical": 128,
      "Hanzi": 369
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128",
      "GeneralStandard": 369,
      "Radical": "耳",
      "HSK": 4,
      "FrequencyRank": 887,
      "StrokeCount": 6
    }
  },
  "枕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "pillow"
    },
    "Keys": {
      "Hanzi": 1033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1033,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2605,
      "StrokeCount": 8
    }
  },
  "鞬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "a quiver on a horse; a store"
    },
    "Keys": {
      "Hanzi": 6207
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6207,
      "Radical": "革",
      "FrequencyRank": 6401,
      "RadicalIndex": "177.9",
      "StrokeCount": 17
    }
  },
  "鸥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōu",
      "PrimaryDefinition": "seagull, tern; Larus species (various)"
    },
    "Keys": {
      "Hanzi": 1459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1459,
      "Radical": "鳥",
      "FrequencyRank": 3371,
      "RadicalIndex": "196.4",
      "StrokeCount": 9
    }
  },
  "缺失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quēshī",
      "PrimaryDefinition": "lacuna, drawback, gaps and omissions, deletion, defect, hole"
    },
    "Keys": {
      "Word": 8816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高尔夫球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoěrfūqiú",
      "PrimaryDefinition": "golf ball"
    },
    "Keys": {
      "Word": 6697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "to entertain, feast; a feast, banquet"
    },
    "Keys": {
      "Hanzi": 2101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "GeneralStandard": 2101,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 2155,
      "StrokeCount": 10
    }
  },
  "捅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒng",
      "PrimaryDefinition": "jab"
    },
    "Keys": {
      "Hanzi": 1813,
      "Word": 9546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1813,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 3361,
      "StrokeCount": 10
    }
  },
  "砻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "grind, sharpen; mill"
    },
    "Keys": {
      "Hanzi": 4483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4483,
      "Radical": "龍",
      "FrequencyRank": 5566,
      "RadicalIndex": "212.5",
      "StrokeCount": 10
    }
  },
  "远见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnjiàn",
      "PrimaryDefinition": "vision"
    },
    "Keys": {
      "Word": 10553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "kiln; coal mine pit"
    },
    "Keys": {
      "Hanzi": 2457,
      "Word": 10233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.6",
      "Grade": 7,
      "GeneralStandard": 2457,
      "Radical": "穴",
      "HSK": 9,
      "FrequencyRank": 2342,
      "StrokeCount": 11
    }
  },
  "嗓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎng",
      "PrimaryDefinition": "voice; throat"
    },
    "Keys": {
      "Hanzi": 2929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.1",
      "GeneralStandard": 2929,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2496,
      "StrokeCount": 13
    }
  },
  "潮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháo",
      "PrimaryDefinition": "tide; moist, wet, damp; flow"
    },
    "Keys": {
      "Hanzi": 3292,
      "Word": 2306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "Grade": 4,
      "GeneralStandard": 3292,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1302,
      "StrokeCount": 15
    }
  },
  "平淡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngdàn",
      "PrimaryDefinition": "flat, dull, ordinary, nothing special"
    },
    "Keys": {
      "Word": 8547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "装备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngbèi",
      "PrimaryDefinition": "equipment, outfit"
    },
    "Keys": {
      "Word": 5391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "火热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒrè",
      "PrimaryDefinition": "fiery, burning, fervent, ardent, passionate"
    },
    "Keys": {
      "Word": 7245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "树荫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùyīn",
      "PrimaryDefinition": "shade of a tree"
    },
    "Keys": {
      "Word": 9233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "排除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páichú",
      "PrimaryDefinition": "get rid of, remove, eliminate"
    },
    "Keys": {
      "Word": 3757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "胎儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāiér",
      "PrimaryDefinition": "unborn child, fetus, embryo"
    },
    "Keys": {
      "Word": 9392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "pull out, pluck; take hold of"
    },
    "Keys": {
      "Hanzi": 4106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4106,
      "Radical": "手",
      "RadicalIndex": "64.12",
      "StrokeCount": 9
    }
  },
  "安装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānzhuāng",
      "PrimaryDefinition": "install, erect, fix, mount"
    },
    "Keys": {
      "Word": 1263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "墓碑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùbēi",
      "PrimaryDefinition": "gravestone, tombstone"
    },
    "Keys": {
      "Word": 8357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "介绍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièshào",
      "PrimaryDefinition": "introduce, present, recommend, suggest, let know, brief"
    },
    "Keys": {
      "Word": 175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "沧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāng",
      "PrimaryDefinition": "blue, dark green; cold"
    },
    "Keys": {
      "Hanzi": 866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 866,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3023,
      "StrokeCount": 7
    }
  },
  "揕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "roll up the sleeves; fight bare-fisted; strike, stab"
    },
    "Keys": {
      "Hanzi": 7291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7291,
      "Radical": "手",
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "骁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "excellent horse; brave, valiant"
    },
    "Keys": {
      "Hanzi": 4394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4394,
      "Radical": "馬",
      "FrequencyRank": 4404,
      "RadicalIndex": "187.6",
      "StrokeCount": 9
    }
  },
  "介入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièrù",
      "PrimaryDefinition": "intervene, interpose, get involved"
    },
    "Keys": {
      "Word": 7544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "积蓄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīxù",
      "PrimaryDefinition": "to save, to put aside, savings"
    },
    "Keys": {
      "Word": 7272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6684,
      "Radical": "山",
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "环境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánjìng",
      "PrimaryDefinition": "circumambiency, environmental, circumstances, environment, entourage, surround, atmosphere, setting, surroundings, ambiance, condition, context, circumstance, surrounding, frame, milieu, ambient, consideration, environs, medium, ambience"
    },
    "Keys": {
      "Word": 1564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "封建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngjiàn",
      "PrimaryDefinition": "system of enfeoffment, feudalism, feudal, feudalistic"
    },
    "Keys": {
      "Word": 6586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6822,
      "Radical": "目",
      "RadicalIndex": "109.4",
      "StrokeCount": 9
    }
  },
  "野营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěyíng",
      "PrimaryDefinition": "to camp, field lodgings"
    },
    "Keys": {
      "Word": 10261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "bark"
    },
    "Keys": {
      "Hanzi": 724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 724,
      "Radical": "口",
      "FrequencyRank": 3426,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "以下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐxià",
      "PrimaryDefinition": "the following"
    },
    "Keys": {
      "Word": 1147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "根源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnyuán",
      "PrimaryDefinition": "rootage, parent, origin, spore, source, rootstock, germ, springhead, cradle, whence, paternity, spawn, mother, root, seed, fount, fountainhead"
    },
    "Keys": {
      "Word": 6741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "paralysis; impotence"
    },
    "Keys": {
      "Hanzi": 5543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5543,
      "Radical": "疒",
      "FrequencyRank": 4653,
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "匸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "hiding enclosure"
    },
    "Keys": {
      "Radical": 23
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "或是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòshì",
      "PrimaryDefinition": "conj.: or, perhaps"
    },
    "Keys": {
      "Word": 3571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "招募": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāomù",
      "PrimaryDefinition": "recruit, enlist"
    },
    "Keys": {
      "Word": 10663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "a kind of mineral"
    },
    "Keys": {
      "Hanzi": 4795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4795,
      "Radical": "石",
      "FrequencyRank": 6047,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "致使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìshǐ",
      "PrimaryDefinition": "become, render, go, get, result in, cause, make"
    },
    "Keys": {
      "Word": 10801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōu",
      "PrimaryDefinition": "bowl, cup; small tray"
    },
    "Keys": {
      "Hanzi": 3890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3890,
      "Radical": "瓦",
      "FrequencyRank": 5180,
      "RadicalIndex": "98.4",
      "StrokeCount": 8
    }
  },
  "吴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "name of warring state; surname"
    },
    "Keys": {
      "Hanzi": 718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 718,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 1135,
      "StrokeCount": 7
    }
  },
  "抢救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎngjiù",
      "PrimaryDefinition": "rush to save"
    },
    "Keys": {
      "Word": 3809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "number; several, count; fate"
    },
    "Keys": {
      "Hanzi": 2999,
      "Word": 997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.9",
      "Grade": 2,
      "GeneralStandard": 2999,
      "Radical": "攴",
      "HSK": 2,
      "FrequencyRank": 231,
      "StrokeCount": 13
    }
  },
  "合理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hélǐ",
      "PrimaryDefinition": "plausible, rational, reasonable, legitimate, equitable, sensible"
    },
    "Keys": {
      "Word": 1546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "带头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài tóu",
      "PrimaryDefinition": "to take the lead, to be the first, to set an example"
    },
    "Keys": {
      "Word": 6149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "取得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔdé",
      "PrimaryDefinition": "gain, acquire, obtain"
    },
    "Keys": {
      "Word": 944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "闺女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīnü",
      "PrimaryDefinition": "maiden, unmarried woman, daughter"
    },
    "Keys": {
      "Word": 6918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "创建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngjiàn",
      "PrimaryDefinition": "to found, to establish"
    },
    "Keys": {
      "Word": 4449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "公众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzhòng",
      "PrimaryDefinition": "community, the public, masses, the people, public"
    },
    "Keys": {
      "Word": 4607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "草": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎo",
      "PrimaryDefinition": "grass, straw, thatch, herbs"
    },
    "Keys": {
      "Hanzi": 1408,
      "Word": 548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "Grade": 2,
      "GeneralStandard": 1408,
      "Radical": "艸",
      "HSK": 2,
      "FrequencyRank": 789,
      "StrokeCount": 9
    }
  },
  "答辩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dábiàn",
      "PrimaryDefinition": "to reply (to an accusation), to defend one's dissertation"
    },
    "Keys": {
      "Word": 6097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "周年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōunián",
      "PrimaryDefinition": "anniversary, annual"
    },
    "Keys": {
      "Word": 1231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "钖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "frontlet"
    },
    "Keys": {
      "Hanzi": 6691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6691,
      "Radical": "金",
      "FrequencyRank": 8104,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "胶带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāodài",
      "PrimaryDefinition": "adhesive tape, rubber belt, recording tape"
    },
    "Keys": {
      "Word": 3612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "盖子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàizi",
      "PrimaryDefinition": "cover, lid, shell"
    },
    "Keys": {
      "Word": 6660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稻草": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàocǎo",
      "PrimaryDefinition": "rice straw"
    },
    "Keys": {
      "Word": 6206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "定向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngxiàng",
      "PrimaryDefinition": "to orientate, directional, directed, orienteering"
    },
    "Keys": {
      "Word": 6314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "life"
    },
    "Keys": {
      "Hanzi": 263,
      "Radical": 100,
      "Word": 1889
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "100",
      "Grade": 3,
      "GeneralStandard": 263,
      "Radical": "生",
      "HSK": 1,
      "FrequencyRank": 34,
      "StrokeCount": 5
    }
  },
  "后顾之忧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòugùzhīyōu",
      "PrimaryDefinition": "fears of trouble in the rear (idiom), family worries (obstructing freedom of action), worries about the future consequences, often in negative expressions, meaning 'no worries about anything'"
    },
    "Keys": {
      "Word": 7093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "部长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhǎng",
      "PrimaryDefinition": "commissar, manager, superintendent, cabinet minister, head of a section, minister, political commissar, ministerial, secretary, government minister, president, head of a department"
    },
    "Keys": {
      "Word": 1327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "成品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngpǐn",
      "PrimaryDefinition": "end, end product, turnoff, end/finished product, finished product, facture"
    },
    "Keys": {
      "Word": 4411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "竺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "India; bamboo; surname"
    },
    "Keys": {
      "Hanzi": 3948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3948,
      "Radical": "竹",
      "FrequencyRank": 3439,
      "RadicalIndex": "118.2",
      "StrokeCount": 8
    }
  },
  "气温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìwēn",
      "PrimaryDefinition": "atmospheric temperature, mercury, temperature, air temperature"
    },
    "Keys": {
      "Word": 925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "玄机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánjī",
      "PrimaryDefinition": "profound theory (in Daoism and Buddhism), mysterious principles"
    },
    "Keys": {
      "Word": 10117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "师生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī-shēng",
      "PrimaryDefinition": "teacher and student"
    },
    "Keys": {
      "Word": 5017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "抉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "choose, select; gouge, pluck out"
    },
    "Keys": {
      "Hanzi": 3657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3657,
      "Radical": "手",
      "FrequencyRank": 3575,
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "礼品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐpǐn",
      "PrimaryDefinition": "present, beneficence, gift"
    },
    "Keys": {
      "Word": 7979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé",
      "PrimaryDefinition": "obtain, get, gain, acquire"
    },
    "Keys": {
      "Hanzi": 2341,
      "Word": 2396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "60.8",
      "Grade": 4,
      "GeneralStandard": 2341,
      "Radical": "彳",
      "HSK": 1,
      "FrequencyRank": 39,
      "StrokeCount": 11
    }
  },
  "植": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "plant, trees, plants; grow"
    },
    "Keys": {
      "Hanzi": 2566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2566,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 1124,
      "StrokeCount": 12
    }
  },
  "恽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "devise, plan, deliberate; consult"
    },
    "Keys": {
      "Hanzi": 4357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4357,
      "Radical": "心",
      "FrequencyRank": 4712,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "诧异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàyì",
      "PrimaryDefinition": "flabbergasted, astonished"
    },
    "Keys": {
      "Word": 5794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冷水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngshuǐ",
      "PrimaryDefinition": "cold water, unboiled water, fig. not yet ready (of plans)"
    },
    "Keys": {
      "Word": 4815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鲨鱼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāyú",
      "PrimaryDefinition": "shark"
    },
    "Keys": {
      "Word": 8940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "glorious, as sun; daylight, sunlight; one of the seven planets of pre-modern astronomy (the sun, the moon, Mercury, Venus, Mars, Jupiter, and Saturn)"
    },
    "Keys": {
      "Hanzi": 6331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6331,
      "Radical": "日",
      "FrequencyRank": 4180,
      "RadicalIndex": "72.14",
      "StrokeCount": 18
    }
  },
  "悬念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánniàn",
      "PrimaryDefinition": "suspense in a movie, play etc, concern for sb's welfare"
    },
    "Keys": {
      "Word": 10119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "spirit, soul; spiritual world"
    },
    "Keys": {
      "Hanzi": 900,
      "Word": 8063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "58.4",
      "Grade": 7,
      "GeneralStandard": 900,
      "Radical": "彐",
      "HSK": 6,
      "FrequencyRank": 734,
      "StrokeCount": 7
    }
  },
  "鼠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "rat"
    },
    "Keys": {
      "Hanzi": 2958,
      "Radical": 208,
      "Word": 3924
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "208",
      "Grade": 5,
      "GeneralStandard": 2958,
      "Radical": "鼠",
      "HSK": 5,
      "FrequencyRank": 1693,
      "StrokeCount": 13
    }
  },
  "版": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎn",
      "PrimaryDefinition": "printing blocks; edition"
    },
    "Keys": {
      "Hanzi": 1143,
      "Word": 3231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "91.4",
      "Grade": 5,
      "GeneralStandard": 1143,
      "Radical": "片",
      "HSK": 5,
      "FrequencyRank": 810,
      "StrokeCount": 8
    }
  },
  "得知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dézhī",
      "PrimaryDefinition": "find, hear, see, have, succeed, learn, get_onto, know, have learned of"
    },
    "Keys": {
      "Word": 6216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ",
      "PrimaryDefinition": "strike, hit, beat; fight; attack"
    },
    "Keys": {
      "Hanzi": 196,
      "Word": 3372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.2",
      "Grade": 5,
      "GeneralStandard": 196,
      "Radical": "手",
      "HSK": 1,
      "FrequencyRank": 223,
      "StrokeCount": 5
    }
  },
  "低调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīdiào",
      "PrimaryDefinition": "low pitch, quiet (voice), subdued, low-key, low-profile"
    },
    "Keys": {
      "Word": 6226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "算是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suànshì",
      "PrimaryDefinition": "regard_as, considered to be"
    },
    "Keys": {
      "Word": 5056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "风和日丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnghé-rìlì",
      "PrimaryDefinition": "moderate wind, beautiful sun (idiom), fine sunny weather, esp. in springtime"
    },
    "Keys": {
      "Word": 6567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "比不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐbùshàng",
      "PrimaryDefinition": "not compare with"
    },
    "Keys": {
      "Word": 5582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "其间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíjiān",
      "PrimaryDefinition": "in between, within that interval, in the meantime"
    },
    "Keys": {
      "Word": 8594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "flintstone; beacon, signal fire; torch"
    },
    "Keys": {
      "Hanzi": 6173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6173,
      "Radical": "火",
      "FrequencyRank": 4808,
      "RadicalIndex": "86.13",
      "StrokeCount": 16
    }
  },
  "悲痛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēitòng",
      "PrimaryDefinition": "grieved, sorrowful"
    },
    "Keys": {
      "Word": 5551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "关机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān jī",
      "PrimaryDefinition": "to turn off (a machine or device), to finish shooting a film"
    },
    "Keys": {
      "Word": 695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "半岛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàndǎo",
      "PrimaryDefinition": "peninsula"
    },
    "Keys": {
      "Word": 5493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "leg, foot; base, leg, foundation"
    },
    "Keys": {
      "Hanzi": 2355,
      "Word": 772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.7",
      "Grade": 2,
      "GeneralStandard": 2355,
      "Radical": "肉",
      "HSK": 2,
      "FrequencyRank": 790,
      "StrokeCount": 11
    }
  },
  "官僚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānliáo",
      "PrimaryDefinition": "bureaucrats, bureaucracy"
    },
    "Keys": {
      "Word": 6877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quē",
      "PrimaryDefinition": "watch tower; palace"
    },
    "Keys": {
      "Hanzi": 5554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5554,
      "Radical": "門",
      "FrequencyRank": 3452,
      "RadicalIndex": "169.1",
      "StrokeCount": 13
    }
  },
  "资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "property; wealth; capital"
    },
    "Keys": {
      "Hanzi": 2039
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "GeneralStandard": 2039,
      "Radical": "貝",
      "HSK": 3,
      "FrequencyRank": 257,
      "StrokeCount": 10
    }
  },
  "眼前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnqián",
      "PrimaryDefinition": "before one's eyes"
    },
    "Keys": {
      "Word": 2079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "产物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnwù",
      "PrimaryDefinition": "result, development, child, offspring, product, outcome, fruit, progeny, spawn"
    },
    "Keys": {
      "Word": 5802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": "red, brown, bay; neat, harmonious"
    },
    "Keys": {
      "Hanzi": 7096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7096,
      "Radical": "馬",
      "FrequencyRank": 8374,
      "RadicalIndex": "187.7",
      "StrokeCount": 10
    }
  },
  "涟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "flowing water; ripples; weeping"
    },
    "Keys": {
      "Hanzi": 4647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4647,
      "Radical": "水",
      "FrequencyRank": 3889,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "柚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "pomelo, grapefruit"
    },
    "Keys": {
      "Hanzi": 4149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4149,
      "Radical": "木",
      "FrequencyRank": 4553,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "眷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": "take interest in, care for"
    },
    "Keys": {
      "Hanzi": 2401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2401,
      "Radical": "目",
      "FrequencyRank": 2967,
      "RadicalIndex": "109.6",
      "StrokeCount": 11
    }
  },
  "弈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "Chinese chess"
    },
    "Keys": {
      "Hanzi": 4305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "55.6",
      "GeneralStandard": 4305,
      "Radical": "廾",
      "HSK": 9,
      "FrequencyRank": 4421,
      "StrokeCount": 9
    }
  },
  "首先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuxiān",
      "PrimaryDefinition": "first (of all), in the first place"
    },
    "Keys": {
      "Word": 1929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "长跑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángpǎo",
      "PrimaryDefinition": "long-distance running"
    },
    "Keys": {
      "Word": 4389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "斯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "this, thus, such; to lop off; emphatic particle"
    },
    "Keys": {
      "Hanzi": 2541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "69.8",
      "GeneralStandard": 2541,
      "Radical": "斤",
      "HSK": 7,
      "FrequencyRank": 168,
      "StrokeCount": 12
    }
  },
  "烃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng",
      "PrimaryDefinition": "hydrocarbon"
    },
    "Keys": {
      "Hanzi": 4330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4330,
      "Radical": "火",
      "FrequencyRank": 4918,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "泔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "water from washing rice to boil thick, as gruel"
    },
    "Keys": {
      "Hanzi": 4009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4009,
      "Radical": "水",
      "FrequencyRank": 5880,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "倓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7020,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "物价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùjià",
      "PrimaryDefinition": "commodity prices"
    },
    "Keys": {
      "Word": 4019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "索赔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒpéi",
      "PrimaryDefinition": "to ask for compensation, to claim damages, claim for damages"
    },
    "Keys": {
      "Word": 9381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "classic works; pass through"
    },
    "Keys": {
      "Hanzi": 1350,
      "Word": 7591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 7,
      "GeneralStandard": 1350,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 62,
      "StrokeCount": 8
    }
  },
  "钜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "steel, iron; great"
    },
    "Keys": {
      "Hanzi": 6845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6845,
      "Radical": "金",
      "FrequencyRank": 4606,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "外套": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàitào",
      "PrimaryDefinition": "stragulum, overclothes, outerwear, pall, surcoat, overcoat, loose coat, greatcoat, manta, outer garment, topcoat, coat, bolero, lagging, benny"
    },
    "Keys": {
      "Word": 2961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "劁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5759,
      "Radical": "刀",
      "FrequencyRank": 6471,
      "RadicalIndex": "18.12",
      "StrokeCount": 14
    }
  },
  "瞪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèng",
      "PrimaryDefinition": "stare at"
    },
    "Keys": {
      "Hanzi": 3406,
      "Word": 6225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.12",
      "Grade": 7,
      "GeneralStandard": 3406,
      "Radical": "目",
      "HSK": 8,
      "FrequencyRank": 2049,
      "StrokeCount": 17
    }
  },
  "瘟疫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnyì",
      "PrimaryDefinition": "epidemic, plague, pestilence, diseased"
    },
    "Keys": {
      "Word": 9732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "de",
      "PrimaryDefinition": "possessive, adjectival suffix"
    },
    "Keys": {
      "Hanzi": 1155,
      "Word": 71
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "106.3",
      "Grade": 1,
      "GeneralStandard": 1155,
      "Radical": "白",
      "HSK": 1,
      "FrequencyRank": 1,
      "StrokeCount": 8
    }
  },
  "清洁工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngjié gōng",
      "PrimaryDefinition": "cleaner, janitor, garbage collector"
    },
    "Keys": {
      "Word": 4945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嘧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "pyrimidine"
    },
    "Keys": {
      "Hanzi": 5722
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5722,
      "Radical": "口",
      "FrequencyRank": 5121,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "拢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒng",
      "PrimaryDefinition": "collect, bring together"
    },
    "Keys": {
      "Hanzi": 958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 958,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 2374,
      "StrokeCount": 8
    }
  },
  "漶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "indecipherable"
    },
    "Keys": {
      "Hanzi": 5804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5804,
      "Radical": "水",
      "FrequencyRank": 6496,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "穿过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuān guò",
      "PrimaryDefinition": "thread, pass through, traverse, penetrate, hook, go through, riddle, pierce, twist, go across, cleave, pass, lace, put_through, lace up, cut_through, cut across, cross, break, shoot"
    },
    "Keys": {
      "Word": 6001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外援": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiyuán",
      "PrimaryDefinition": "external help, foreign aid"
    },
    "Keys": {
      "Word": 9647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6887,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 9
    }
  },
  "平凡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngfán",
      "PrimaryDefinition": "ordinary, common"
    },
    "Keys": {
      "Word": 4907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "楙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "name of plant; lush"
    },
    "Keys": {
      "Hanzi": 7501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7501,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "冠军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guànjūn",
      "PrimaryDefinition": "champion"
    },
    "Keys": {
      "Word": 3516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "勾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "hook, join, connect; entice"
    },
    "Keys": {
      "Hanzi": 158,
      "Word": 6809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "20.2",
      "Grade": 7,
      "GeneralStandard": 158,
      "Radical": "勹",
      "HSK": 7,
      "FrequencyRank": 2034,
      "StrokeCount": 4
    }
  },
  "冶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yě",
      "PrimaryDefinition": "smelt, fuse metals; cast, found"
    },
    "Keys": {
      "Hanzi": 843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.5",
      "GeneralStandard": 843,
      "Radical": "冫",
      "HSK": 9,
      "FrequencyRank": 2792,
      "StrokeCount": 7
    }
  },
  "喝彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè cǎi",
      "PrimaryDefinition": "to acclaim, to cheer"
    },
    "Keys": {
      "Word": 7055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "检测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎncè",
      "PrimaryDefinition": "to detect, to test, detection, sensing"
    },
    "Keys": {
      "Word": 2600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "劝阻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quànzǔ",
      "PrimaryDefinition": "to advise against, to dissuade"
    },
    "Keys": {
      "Word": 8812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāng miàn",
      "PrimaryDefinition": "directly, face_to_face, face to face, in one's presence"
    },
    "Keys": {
      "Word": 6174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ō",
      "PrimaryDefinition": "moan; interjection for pain, sad"
    },
    "Keys": {
      "Hanzi": 5933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5933,
      "Radical": "口",
      "FrequencyRank": 2347,
      "RadicalIndex": "30.13",
      "StrokeCount": 15
    }
  },
  "复制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzhì",
      "PrimaryDefinition": "duplicate, reproduce"
    },
    "Keys": {
      "Word": 2483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "野炊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěchuī",
      "PrimaryDefinition": "Picnic"
    },
    "Keys": {
      "Word": 10255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屡次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚcì",
      "PrimaryDefinition": "repeatedly, time and again"
    },
    "Keys": {
      "Word": 8142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "龠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "flute"
    },
    "Keys": {
      "Radical": 214,
      "Hanzi": 6267
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6267,
      "Radical": "龠",
      "FrequencyRank": 6897,
      "RadicalIndex": "214",
      "StrokeCount": 17
    }
  },
  "懂得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒngde",
      "PrimaryDefinition": "experience, know, live, understanding, understand, twig, gaum, grasp"
    },
    "Keys": {
      "Word": 628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "小偷儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎotōur5",
      "PrimaryDefinition": "Thief"
    },
    "Keys": {
      "Word": 4055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "耸立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒnglì",
      "PrimaryDefinition": "to stand tall, to tower aloft"
    },
    "Keys": {
      "Word": 9333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柿子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìzi",
      "PrimaryDefinition": "persimmon, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 9157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "close; be silent, be unable speak"
    },
    "Keys": {
      "Hanzi": 6089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6089,
      "Radical": "口",
      "FrequencyRank": 4195,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "复兴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùxīng",
      "PrimaryDefinition": "Fuxing district of Handan city 邯鄲市|邯郸市[Han2 dan1 shi4], Hebei, Fuxing or Fuhsing township in Taoyuan county 桃園縣|桃园县[Tao2 yuan2 xian4], north Taiwan, to revive, to rejuvenate"
    },
    "Keys": {
      "Word": 6636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tī",
      "PrimaryDefinition": "ladder, steps, stairs; lean"
    },
    "Keys": {
      "Hanzi": 2229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.7",
      "GeneralStandard": 2229,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 1672,
      "StrokeCount": 11
    }
  },
  "依托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yītuō",
      "PrimaryDefinition": "to rely on, to depend on"
    },
    "Keys": {
      "Word": 10279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "印": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìn",
      "PrimaryDefinition": "print, seal, stamp, chop, mark"
    },
    "Keys": {
      "Hanzi": 285,
      "Word": 5258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "26.4",
      "Grade": 6,
      "GeneralStandard": 285,
      "Radical": "卩",
      "HSK": 2,
      "FrequencyRank": 640,
      "StrokeCount": 5
    }
  },
  "馨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "fragrant, aromatic; distant fragrance"
    },
    "Keys": {
      "Hanzi": 3475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "186.11",
      "GeneralStandard": 3475,
      "Radical": "香",
      "HSK": 8,
      "FrequencyRank": 2586,
      "StrokeCount": 20
    }
  },
  "威力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēilì",
      "PrimaryDefinition": "world power, superpower, major power, rod, great power, prowess, tooth, might, power"
    },
    "Keys": {
      "Word": 9688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "和气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héqi",
      "PrimaryDefinition": "friendly, polite, amiable"
    },
    "Keys": {
      "Word": 7041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "explain, expound, comment on"
    },
    "Keys": {
      "Hanzi": 4054
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4054,
      "Radical": "言",
      "FrequencyRank": 3683,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "荥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "a county in Henan; the rising and dashing of waves"
    },
    "Keys": {
      "Hanzi": 4130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4130,
      "Radical": "艸",
      "FrequencyRank": 5047,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "疲惫不堪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píbèibùkān",
      "PrimaryDefinition": "Exhausted"
    },
    "Keys": {
      "Word": 8507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "administrative division"
    },
    "Keys": {
      "Hanzi": 4375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4375,
      "Radical": "邑",
      "FrequencyRank": 2289,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "珊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "coral"
    },
    "Keys": {
      "Hanzi": 1360
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1360,
      "Radical": "玉",
      "FrequencyRank": 2555,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "铞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7184,
      "Radical": "金",
      "FrequencyRank": 7748,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "膑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìn",
      "PrimaryDefinition": "the kneecap"
    },
    "Keys": {
      "Hanzi": 5767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5767,
      "Radical": "肉",
      "FrequencyRank": 4579,
      "RadicalIndex": "130.1",
      "StrokeCount": 14
    }
  },
  "优越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuyuè",
      "PrimaryDefinition": "excel, exceed, superior, outstanding, transcend"
    },
    "Keys": {
      "Word": 10450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "感兴趣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn xìngqù",
      "PrimaryDefinition": "be interested, be interested in"
    },
    "Keys": {
      "Word": 2487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "驲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rì",
      "PrimaryDefinition": "post horse, relay horse"
    },
    "Keys": {
      "Hanzi": 6639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6639,
      "Radical": "馬",
      "RadicalIndex": "187.4",
      "StrokeCount": 7
    }
  },
  "腒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "game"
    },
    "Keys": {
      "Hanzi": 7404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7404,
      "Radical": "肉",
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "逾期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú qī",
      "PrimaryDefinition": "to be overdue, to fail to meet a deadline, to be behind in doing sth"
    },
    "Keys": {
      "Word": 10498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎo",
      "PrimaryDefinition": "sacrifice"
    },
    "Keys": {
      "Hanzi": 7031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7031,
      "Radical": "月",
      "RadicalIndex": "74.6",
      "StrokeCount": 10
    }
  },
  "想念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngniàn",
      "PrimaryDefinition": "imagine, long to see again, remember with longing, think_of, miss, yearn"
    },
    "Keys": {
      "Word": 3021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "虍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǔ",
      "PrimaryDefinition": "tiger"
    },
    "Keys": {
      "Radical": 141
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "宀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "roof"
    },
    "Keys": {
      "Radical": 40
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "埚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guō",
      "PrimaryDefinition": "crucible"
    },
    "Keys": {
      "Hanzi": 4417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4417,
      "Radical": "土",
      "FrequencyRank": 6530,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "骺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "tip of a long bone"
    },
    "Keys": {
      "Hanzi": 5943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5943,
      "Radical": "骨",
      "FrequencyRank": 6277,
      "RadicalIndex": "188.6",
      "StrokeCount": 15
    }
  },
  "办事处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànshìchù",
      "PrimaryDefinition": "office, agency"
    },
    "Keys": {
      "Word": 4297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "够呛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòuqiàng",
      "PrimaryDefinition": "unbearable, terrible, enough, unlikely"
    },
    "Keys": {
      "Word": 6816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "未成年人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèichéngniánrén",
      "PrimaryDefinition": "minor (i.e. person under 18)"
    },
    "Keys": {
      "Word": 9714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发脾气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā píqi",
      "PrimaryDefinition": "to get angry"
    },
    "Keys": {
      "Word": 6440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "ceremony, rites gifts; admire"
    },
    "Keys": {
      "Hanzi": 274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "GeneralStandard": 274,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1283,
      "StrokeCount": 5
    }
  },
  "桕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "tallow tree"
    },
    "Keys": {
      "Hanzi": 4457
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4457,
      "Radical": "木",
      "FrequencyRank": 6251,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "睡着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuìzháo",
      "PrimaryDefinition": "asleep, fall_asleep, go_off, nod off, drift off, doze off, fall asleep, dope off, flake, drowse off, conk, dormant, flake out, drop off"
    },
    "Keys": {
      "Word": 2897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "交付": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāofù",
      "PrimaryDefinition": "to hand over, to deliver"
    },
    "Keys": {
      "Word": 7453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "进展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnzhǎn",
      "PrimaryDefinition": "progress, advance"
    },
    "Keys": {
      "Word": 1634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "俎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ",
      "PrimaryDefinition": "chopping board or block; painted"
    },
    "Keys": {
      "Hanzi": 4274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4274,
      "Radical": "人",
      "FrequencyRank": 4909,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "表明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎomíng",
      "PrimaryDefinition": "argue, utter, disclose, enunciate, indicate, speak_for, make clear, indication, prove, point, express, manifest, tell, state clearly, give, denote, evince, unfold, portend, declare, kithe, make known, signalize"
    },
    "Keys": {
      "Word": 1307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "裛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "to wrap and bind; damp; a book bag"
    },
    "Keys": {
      "Hanzi": 7570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7570,
      "Radical": "衣",
      "FrequencyRank": 8805,
      "RadicalIndex": "145.7",
      "StrokeCount": 13
    }
  },
  "留言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúyán",
      "PrimaryDefinition": "to leave a message, to leave one's comments, message"
    },
    "Keys": {
      "Word": 4835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "理智": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐzhì",
      "PrimaryDefinition": "reason, intellect, rationality, rational"
    },
    "Keys": {
      "Word": 4820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胡同儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hútòngr5",
      "PrimaryDefinition": "Alley"
    },
    "Keys": {
      "Word": 3544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "卣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "wine pot"
    },
    "Keys": {
      "Hanzi": 3695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3695,
      "Radical": "卜",
      "FrequencyRank": 5459,
      "RadicalIndex": "25.5",
      "StrokeCount": 7
    }
  },
  "铯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": "cesium"
    },
    "Keys": {
      "Hanzi": 4882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4882,
      "Radical": "金",
      "FrequencyRank": 5914,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "夏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià",
      "PrimaryDefinition": "summer; great, grand, big"
    },
    "Keys": {
      "Hanzi": 1862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "35.7",
      "GeneralStandard": 1862,
      "Radical": "夊",
      "HSK": 2,
      "FrequencyRank": 1126,
      "StrokeCount": 10
    }
  },
  "广泛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎngfàn",
      "PrimaryDefinition": "extensive, wide-ranging"
    },
    "Keys": {
      "Word": 3521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瑚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "coral; person of virtue"
    },
    "Keys": {
      "Hanzi": 5340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5340,
      "Radical": "玉",
      "FrequencyRank": 3140,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "震撼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènhàn",
      "PrimaryDefinition": "shock, shake"
    },
    "Keys": {
      "Word": 10713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "探望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tànwàng",
      "PrimaryDefinition": "look about, visit"
    },
    "Keys": {
      "Word": 9420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎng",
      "PrimaryDefinition": "roomy, spacious, open, broad"
    },
    "Keys": {
      "Hanzi": 2607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "66.8",
      "GeneralStandard": 2607,
      "Radical": "攴",
      "HSK": 7,
      "FrequencyRank": 2592,
      "StrokeCount": 12
    }
  },
  "停车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng chē",
      "PrimaryDefinition": "park the car"
    },
    "Keys": {
      "Word": 1027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "制止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìzhǐ",
      "PrimaryDefinition": "forbear, restrict, oppress, prevention, hold back, inhibit, allay, stop, prevent, staunch, turn back, arrest, stanch, suppress, interdict, discourage, pull_up, abate, blink, quell, restrain, deter, repress, crush, omit, shush, countercheck, check, curb, contain"
    },
    "Keys": {
      "Word": 10787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大模大样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàmú-dàyàng",
      "PrimaryDefinition": "Large model"
    },
    "Keys": {
      "Word": 6126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "condition, situation; furthermore"
    },
    "Keys": {
      "Hanzi": 830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.5",
      "GeneralStandard": 830,
      "Radical": "冫",
      "HSK": 3,
      "FrequencyRank": 419,
      "StrokeCount": 7
    }
  },
  "校长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàozhǎng",
      "PrimaryDefinition": "prexy, head, principal, headteacher, schoolmaster, master, warden, president, beak, rector, superintendent, director, head teacher, preceptor, headmaster, chancellor, school principal"
    },
    "Keys": {
      "Word": 1098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "不算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùsuàn",
      "PrimaryDefinition": "Not count"
    },
    "Keys": {
      "Word": 5676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脱口而出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōkǒuérchū",
      "PrimaryDefinition": "to blurt out, to let slip (an indiscreet remark)"
    },
    "Keys": {
      "Word": 9617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倒车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo chē",
      "PrimaryDefinition": "to change buses, trains etc, to reverse (a vehicle), to drive backwards"
    },
    "Keys": {
      "Word": 2394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "啼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "weep, whimper; howl, twitter"
    },
    "Keys": {
      "Hanzi": 2645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2645,
      "Radical": "口",
      "FrequencyRank": 2913,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "璜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "a semicircular jade ornament used as a pendant"
    },
    "Keys": {
      "Hanzi": 5852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5852,
      "Radical": "玉",
      "FrequencyRank": 4188,
      "RadicalIndex": "96.12",
      "StrokeCount": 15
    }
  },
  "画家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàjiā",
      "PrimaryDefinition": "painterly, creative person, artist, painter, penman, artists"
    },
    "Keys": {
      "Word": 730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "开工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi gōng",
      "PrimaryDefinition": "to begin work (of a factory or engineering operation), to start a construction job"
    },
    "Keys": {
      "Word": 7748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揭露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēlù",
      "PrimaryDefinition": "to expose, to unmask, to ferret out, to disclose, disclosure"
    },
    "Keys": {
      "Word": 7515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缓慢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎnmàn",
      "PrimaryDefinition": "largo, slow"
    },
    "Keys": {
      "Word": 7174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "见面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn miàn",
      "PrimaryDefinition": "meet, see"
    },
    "Keys": {
      "Word": 168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "留恋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúliàn",
      "PrimaryDefinition": "reluctant to leave, to hate to have to go, to recall fondly"
    },
    "Keys": {
      "Word": 8089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "subdue, overcome; cut down"
    },
    "Keys": {
      "Hanzi": 4134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4134,
      "Radical": "刀",
      "RadicalIndex": "18.7",
      "StrokeCount": 9
    }
  },
  "吸管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīguǎn",
      "PrimaryDefinition": "(drinking) straw, pipette, eyedropper, snorkel, CL:支[zhi1]"
    },
    "Keys": {
      "Word": 2995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "熊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xióng",
      "PrimaryDefinition": "a bear; brilliant; bright; surname"
    },
    "Keys": {
      "Hanzi": 3185,
      "Word": 4071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.1",
      "Grade": 5,
      "GeneralStandard": 3185,
      "Radical": "火",
      "HSK": 5,
      "FrequencyRank": 1741,
      "StrokeCount": 14
    }
  },
  "弟子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìzǐ",
      "PrimaryDefinition": "scholar, pupil, disciple, student, follower, child, adherent, youngster"
    },
    "Keys": {
      "Word": 6256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "bitter cold, miserable, dreary"
    },
    "Keys": {
      "Hanzi": 2015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.8",
      "GeneralStandard": 2015,
      "Radical": "冫",
      "HSK": 9,
      "FrequencyRank": 2352,
      "StrokeCount": 10
    }
  },
  "没说的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méishuōde",
      "PrimaryDefinition": "really good"
    },
    "Keys": {
      "Word": 8217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "合影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héyǐng",
      "PrimaryDefinition": "to take a joint photo, group photo"
    },
    "Keys": {
      "Word": 7029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": "farm, harvest grain; stingy"
    },
    "Keys": {
      "Hanzi": 6123
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6123,
      "Radical": "禾",
      "FrequencyRank": 4068,
      "RadicalIndex": "115.11",
      "StrokeCount": 16
    }
  },
  "复活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùhuó",
      "PrimaryDefinition": "resurrection"
    },
    "Keys": {
      "Word": 6635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "agree"
    },
    "Keys": {
      "Hanzi": 5222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5222,
      "Radical": "羽",
      "FrequencyRank": 5066,
      "RadicalIndex": "124.6",
      "StrokeCount": 12
    }
  },
  "禁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "restrict, prohibit, forbid"
    },
    "Keys": {
      "Hanzi": 2863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.8",
      "GeneralStandard": 2863,
      "Radical": "示",
      "HSK": 4,
      "FrequencyRank": 986,
      "StrokeCount": 13
    }
  },
  "集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "assemble, collect together"
    },
    "Keys": {
      "Hanzi": 2694,
      "Word": 4699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "172.4",
      "Grade": 6,
      "GeneralStandard": 2694,
      "Radical": "隹",
      "HSK": 3,
      "FrequencyRank": 406,
      "StrokeCount": 12
    }
  },
  "摁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èn",
      "PrimaryDefinition": "to press with the hand"
    },
    "Keys": {
      "Hanzi": 5355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5355,
      "Radical": "手",
      "FrequencyRank": 4705,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "搓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuō",
      "PrimaryDefinition": "to rub or roll between the hands"
    },
    "Keys": {
      "Hanzi": 2534,
      "Word": 6086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 7,
      "GeneralStandard": 2534,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3098,
      "StrokeCount": 12
    }
  },
  "鬼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "ghost"
    },
    "Keys": {
      "Hanzi": 1573,
      "Radical": 194,
      "Word": 3522
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "194",
      "Grade": 5,
      "GeneralStandard": 1573,
      "Radical": "鬼",
      "HSK": 5,
      "FrequencyRank": 1042,
      "StrokeCount": 9
    }
  },
  "忘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàng",
      "PrimaryDefinition": "forget; neglect; miss, omit"
    },
    "Keys": {
      "Hanzi": 844,
      "Word": 375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.3",
      "Grade": 1,
      "GeneralStandard": 844,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 1056,
      "StrokeCount": 7
    }
  },
  "夫人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fūren",
      "PrimaryDefinition": "Lady, lady, memsahib, milady, Dona, Mistress, dona, grande dame, donna, Mrs, mum, Senora, grace, senora, Mrs., madam, Frau, miladi, missus, senhora, wife, ma'am, Madame, madame, missis, Ladyship, Signora, signora"
    },
    "Keys": {
      "Word": 2476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "心脏病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnzàngbìng",
      "PrimaryDefinition": "heart disease"
    },
    "Keys": {
      "Word": 5185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhǐ",
      "PrimaryDefinition": "incessantly, without end, more than, not limited to"
    },
    "Keys": {
      "Word": 3287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蠃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǒ",
      "PrimaryDefinition": "the solitary wasp"
    },
    "Keys": {
      "Hanzi": 8048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8048,
      "Radical": "虫",
      "FrequencyRank": 6866,
      "RadicalIndex": "142.13",
      "StrokeCount": 19
    }
  },
  "嘎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gā",
      "PrimaryDefinition": "sound of laughter; bad, malevolent"
    },
    "Keys": {
      "Hanzi": 5699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5699,
      "Radical": "口",
      "FrequencyRank": 2852,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "沉闷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénmèn",
      "PrimaryDefinition": "oppressive (of weather), heavy, depressed, not happy, (of sound) dull, muffled"
    },
    "Keys": {
      "Word": 5852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尊重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnzhòng",
      "PrimaryDefinition": "respect, value, esteem"
    },
    "Keys": {
      "Word": 4286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "迫不及待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòbùjídài",
      "PrimaryDefinition": "too impatient to wait"
    },
    "Keys": {
      "Word": 8565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8102,
      "Radical": "酉",
      "FrequencyRank": 7956,
      "RadicalIndex": "164.17",
      "StrokeCount": 24
    }
  },
  "得意扬扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "déyì-yángyáng",
      "PrimaryDefinition": "joyfully satisfied, to be immensely proud of oneself, proudly, an air of complacency, variant of 得意洋洋[de2 yi4 yang2 yang2]"
    },
    "Keys": {
      "Word": 6215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偷看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōukàn",
      "PrimaryDefinition": "to peep, to peek, to steal a glance"
    },
    "Keys": {
      "Word": 9550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róu",
      "PrimaryDefinition": "track"
    },
    "Keys": {
      "Radical": 114
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "促使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cùshǐ",
      "PrimaryDefinition": "incite, prod, prompt, spur, send, motive, induce, trigger, urge, bring on, cause, propel, shame, instigate, impel, actuate, motivate, bear on, push, get, move"
    },
    "Keys": {
      "Word": 2358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "衮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔn",
      "PrimaryDefinition": "ceremonial dress worn by the emperor"
    },
    "Keys": {
      "Hanzi": 4622
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4622,
      "Radical": "衣",
      "FrequencyRank": 4843,
      "RadicalIndex": "145.4",
      "StrokeCount": 10
    }
  },
  "槭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "maple"
    },
    "Keys": {
      "Hanzi": 5885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5885,
      "Radical": "木",
      "FrequencyRank": 4943,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "书橱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūchú",
      "PrimaryDefinition": "bookcase"
    },
    "Keys": {
      "Word": 9206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "您": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2331,
      "Word": 270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "Grade": 1,
      "GeneralStandard": 2331,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 726,
      "StrokeCount": 11
    }
  },
  "分别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnbié",
      "PrimaryDefinition": "separately, respectively"
    },
    "Keys": {
      "Word": 1453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "出入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūrù",
      "PrimaryDefinition": "come in and go out"
    },
    "Keys": {
      "Word": 4429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "凿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "záo",
      "PrimaryDefinition": "chisel; bore, pierce"
    },
    "Keys": {
      "Hanzi": 2605,
      "Word": 10606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "17.1",
      "Grade": 7,
      "GeneralStandard": 2605,
      "Radical": "凵",
      "HSK": 8,
      "FrequencyRank": 2696,
      "StrokeCount": 12
    }
  },
  "奖品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngpǐn",
      "PrimaryDefinition": "accolade, gainings, trophy, award, prize, prix, gree"
    },
    "Keys": {
      "Word": 7448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngyuàn",
      "PrimaryDefinition": "willingness, would rather (agree to X than Y)"
    },
    "Keys": {
      "Word": 8762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "embankment; dam"
    },
    "Keys": {
      "Hanzi": 629,
      "Word": 5467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 7,
      "GeneralStandard": 629,
      "Radical": "土",
      "HSK": 9,
      "FrequencyRank": 2786,
      "StrokeCount": 7
    }
  },
  "挓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "to open out; to expand"
    },
    "Keys": {
      "Hanzi": 6779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6779,
      "Radical": "手",
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "剅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6805,
      "Radical": "刀",
      "RadicalIndex": "18.7",
      "StrokeCount": 9
    }
  },
  "檗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bò",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 6302
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6302,
      "Radical": "木",
      "FrequencyRank": 6614,
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "窎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": "deep; distant"
    },
    "Keys": {
      "Hanzi": 7074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7074,
      "Radical": "穴",
      "FrequencyRank": 8796,
      "RadicalIndex": "116.5",
      "StrokeCount": 10
    }
  },
  "凫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "wild duck, teal; swim"
    },
    "Keys": {
      "Hanzi": 3620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3620,
      "Radical": "几",
      "FrequencyRank": 5226,
      "RadicalIndex": "16.4",
      "StrokeCount": 6
    }
  },
  "嫔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pín",
      "PrimaryDefinition": "court lady; palace maid"
    },
    "Keys": {
      "Hanzi": 5608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5608,
      "Radical": "女",
      "FrequencyRank": 3995,
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "讨人喜欢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎorénxǐhuan",
      "PrimaryDefinition": "to attract people's affection, charming, delightful"
    },
    "Keys": {
      "Word": 9444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "particle of completed action"
    },
    "Keys": {
      "Hanzi": 927,
      "Word": 10317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "111.2",
      "Grade": 7,
      "GeneralStandard": 927,
      "Radical": "矢",
      "HSK": 9,
      "FrequencyRank": 2069,
      "StrokeCount": 7
    }
  },
  "自责": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìzé",
      "PrimaryDefinition": "to blame oneself"
    },
    "Keys": {
      "Word": 10971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "每逢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měiféng",
      "PrimaryDefinition": "every time, on each occasion, whenever"
    },
    "Keys": {
      "Word": 8229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "千家万户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānjiā-wànhù",
      "PrimaryDefinition": "every family (idiom)"
    },
    "Keys": {
      "Word": 8645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "后头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòutou",
      "PrimaryDefinition": "behind, in the back, the rear side, later, in future"
    },
    "Keys": {
      "Word": 2547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "剥削": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōxuē",
      "PrimaryDefinition": "to exploit, exploitation"
    },
    "Keys": {
      "Word": 5666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "a small door or window; a hole in the wall to cut through a wall"
    },
    "Keys": {
      "Hanzi": 7734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7734,
      "Radical": "穴",
      "FrequencyRank": 6076,
      "RadicalIndex": "116.9",
      "StrokeCount": 14
    }
  },
  "刺骨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìgǔ",
      "PrimaryDefinition": "piercing, cutting, bone-chilling, penetrating (cold)"
    },
    "Keys": {
      "Word": 6050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "a red spotted lizard, used as medicine"
    },
    "Keys": {
      "Hanzi": 4513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4513,
      "Radical": "虫",
      "FrequencyRank": 5153,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "策划": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèhuà",
      "PrimaryDefinition": "to plot, to scheme, to bring about, to engineer, planning, producer, planner"
    },
    "Keys": {
      "Word": 4378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chài",
      "PrimaryDefinition": "a kind of scorpion"
    },
    "Keys": {
      "Hanzi": 4183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4183,
      "Radical": "虫",
      "FrequencyRank": 5554,
      "RadicalIndex": "142.3",
      "StrokeCount": 9
    }
  },
  "舍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shě",
      "PrimaryDefinition": "house, dwelling; dwell, reside"
    },
    "Keys": {
      "Hanzi": 1165
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "135.2",
      "GeneralStandard": 1165,
      "Radical": "舌",
      "HSK": 5,
      "FrequencyRank": 1344,
      "StrokeCount": 8
    }
  },
  "现任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànrèn",
      "PrimaryDefinition": "be incumbent, be currently in office"
    },
    "Keys": {
      "Word": 9900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "简洁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnjié",
      "PrimaryDefinition": "concise, succinct, pithy"
    },
    "Keys": {
      "Word": 7411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "axe-handle; stalk, bough; surname"
    },
    "Keys": {
      "Hanzi": 4141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4141,
      "Radical": "木",
      "FrequencyRank": 1903,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "辒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēn",
      "PrimaryDefinition": "hearse"
    },
    "Keys": {
      "Hanzi": 7511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7511,
      "Radical": "車",
      "FrequencyRank": 7766,
      "RadicalIndex": "159.9",
      "StrokeCount": 13
    }
  },
  "篆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "seal script; seal, official stamp"
    },
    "Keys": {
      "Hanzi": 5959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5959,
      "Radical": "竹",
      "FrequencyRank": 4254,
      "RadicalIndex": "118.9",
      "StrokeCount": 15
    }
  },
  "津贴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīntiē",
      "PrimaryDefinition": "allowance"
    },
    "Keys": {
      "Word": 7561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "court-dress jewels"
    },
    "Keys": {
      "Hanzi": 6557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6557,
      "Radical": "玉",
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "健壮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànzhuàng",
      "PrimaryDefinition": "robust, healthy, sturdy"
    },
    "Keys": {
      "Word": 7434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěn",
      "PrimaryDefinition": "cultivate, reclaim, to farm land"
    },
    "Keys": {
      "Hanzi": 1719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.6",
      "GeneralStandard": 1719,
      "Radical": "土",
      "HSK": 9,
      "FrequencyRank": 3038,
      "StrokeCount": 9
    }
  },
  "角落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎoluò",
      "PrimaryDefinition": "nook, quoin, angle, corner"
    },
    "Keys": {
      "Word": 7480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "录取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùqǔ",
      "PrimaryDefinition": "enroll, recruit, admit"
    },
    "Keys": {
      "Word": 2717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "常态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángtài",
      "PrimaryDefinition": "normal, normalcy, normal conditions, normality, order, conditions, normal behavior/conditions, normal behavior"
    },
    "Keys": {
      "Word": 5816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": "sever, cut off; interrupt"
    },
    "Keys": {
      "Hanzi": 2405,
      "Word": 1420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "69.7",
      "Grade": 3,
      "GeneralStandard": 2405,
      "Radical": "斤",
      "HSK": 3,
      "FrequencyRank": 434,
      "StrokeCount": 11
    }
  },
  "旨在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐzài",
      "PrimaryDefinition": "to have as its purpose, to be intended to, to aim to (do sth)"
    },
    "Keys": {
      "Word": 10771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "smoke, fog, vapor; smoke, cure"
    },
    "Keys": {
      "Hanzi": 3119,
      "Word": 10146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.1",
      "Grade": 7,
      "GeneralStandard": 3119,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 2997,
      "StrokeCount": 14
    }
  },
  "豹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "leopard, panther; surname"
    },
    "Keys": {
      "Hanzi": 1989,
      "Word": 5539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "153.3",
      "Grade": 7,
      "GeneralStandard": 1989,
      "Radical": "豸",
      "HSK": 8,
      "FrequencyRank": 2507,
      "StrokeCount": 10
    }
  },
  "衬衫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chènshān",
      "PrimaryDefinition": "shirt, blouse, CL:件[jian4]"
    },
    "Keys": {
      "Word": 1342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "斑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "mottled, striped, freckle"
    },
    "Keys": {
      "Hanzi": 2505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "67.8",
      "GeneralStandard": 2505,
      "Radical": "文",
      "HSK": 8,
      "FrequencyRank": 2053,
      "StrokeCount": 12
    }
  },
  "稳定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěndìng",
      "PrimaryDefinition": "stable, steady"
    },
    "Keys": {
      "Word": 2982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhě",
      "PrimaryDefinition": "that which; he who; those who"
    },
    "Keys": {
      "Hanzi": 969,
      "Word": 2137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "125.4",
      "Grade": 3,
      "GeneralStandard": 969,
      "Radical": "老",
      "HSK": 2,
      "FrequencyRank": 103,
      "StrokeCount": 8
    }
  },
  "瞩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "watch carefully, stare at, focus on"
    },
    "Keys": {
      "Hanzi": 3405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.12",
      "GeneralStandard": 3405,
      "Radical": "目",
      "HSK": 8,
      "FrequencyRank": 3320,
      "StrokeCount": 17
    }
  },
  "无可奈何": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúkě-nàihé",
      "PrimaryDefinition": "have no way out, have no alternative, abbr. to 無奈|无奈[wu2 nai4]"
    },
    "Keys": {
      "Word": 9775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "专利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānlì",
      "PrimaryDefinition": "patent"
    },
    "Keys": {
      "Word": 4262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "捂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "resist"
    },
    "Keys": {
      "Hanzi": 1785,
      "Word": 9802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1785,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 3092,
      "StrokeCount": 10
    }
  },
  "犀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "rhinoceros; sharp, well-tempered"
    },
    "Keys": {
      "Hanzi": 2799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2799,
      "Radical": "牛",
      "FrequencyRank": 3490,
      "RadicalIndex": "93.8",
      "StrokeCount": 12
    }
  },
  "芠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6575,
      "Radical": "艸",
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "搠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "to daub; to thrust"
    },
    "Keys": {
      "Hanzi": 5361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5361,
      "Radical": "手",
      "FrequencyRank": 6541,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "交界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojiè",
      "PrimaryDefinition": "common boundary, common border"
    },
    "Keys": {
      "Word": 7456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yū",
      "PrimaryDefinition": "doctrinaire, abstruse, unrealistic"
    },
    "Keys": {
      "Hanzi": 350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 350,
      "Radical": "辵",
      "FrequencyRank": 2959,
      "RadicalIndex": "162.3",
      "StrokeCount": 6
    }
  },
  "锾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "measure; money, coins"
    },
    "Keys": {
      "Hanzi": 7683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7683,
      "Radical": "金",
      "FrequencyRank": 6247,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "玡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3827,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "后人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòurén",
      "PrimaryDefinition": "posperity, descendants, posterity, later generations"
    },
    "Keys": {
      "Word": 7096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黑色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēisè",
      "PrimaryDefinition": "black"
    },
    "Keys": {
      "Word": 720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "武力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔlì",
      "PrimaryDefinition": "military unit, armory, armoury, armed force, saber, arsenal, military force, armed strength, armed might, force, bayonet, force of arms"
    },
    "Keys": {
      "Word": 9800
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7713,
      "Radical": "冫",
      "RadicalIndex": "15.12",
      "StrokeCount": 14
    }
  },
  "文献": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénxiàn",
      "PrimaryDefinition": "document, bibliography, data related to a country's history, bibliographic, literature, data, data/documents related to a country's history/culture/etc., documents related to a country's culture, documents related to a country's history, culture, etc."
    },
    "Keys": {
      "Word": 9738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "签": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "sign, endorse; slip of paper"
    },
    "Keys": {
      "Hanzi": 2953,
      "Word": 8663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.7",
      "Grade": 7,
      "GeneralStandard": 2953,
      "Radical": "竹",
      "HSK": 5,
      "FrequencyRank": 1254,
      "StrokeCount": 13
    }
  },
  "狍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páo",
      "PrimaryDefinition": "species of deer found in north China"
    },
    "Keys": {
      "Hanzi": 3987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3987,
      "Radical": "犬",
      "FrequencyRank": 5804,
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "问卷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wènjuàn",
      "PrimaryDefinition": "questionnaire"
    },
    "Keys": {
      "Word": 9751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "pleased, contented, gratified"
    },
    "Keys": {
      "Hanzi": 2096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "GeneralStandard": 2096,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 1995,
      "StrokeCount": 10
    }
  },
  "喘息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuǎnxī",
      "PrimaryDefinition": "to gasp for breath, to take a breather"
    },
    "Keys": {
      "Word": 6015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "legendary auspicious animal"
    },
    "Keys": {
      "Hanzi": 6408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6408,
      "Radical": "鹿",
      "FrequencyRank": 4703,
      "RadicalIndex": "198.8",
      "StrokeCount": 19
    }
  },
  "窘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "embarrassed; hard-pressed"
    },
    "Keys": {
      "Hanzi": 2787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.7",
      "GeneralStandard": 2787,
      "Radical": "穴",
      "HSK": 9,
      "FrequencyRank": 2979,
      "StrokeCount": 12
    }
  },
  "逞能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chěng néng",
      "PrimaryDefinition": "to show off one's ability, to boast one's merits"
    },
    "Keys": {
      "Word": 5889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "defile, mountain pass, gorge"
    },
    "Keys": {
      "Hanzi": 3805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3805,
      "Radical": "阜",
      "FrequencyRank": 5710,
      "RadicalIndex": "170.5",
      "StrokeCount": 7
    }
  },
  "邲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "good-looking; name of a place"
    },
    "Keys": {
      "Hanzi": 6626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6626,
      "Radical": "邑",
      "FrequencyRank": 7020,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "贬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn",
      "PrimaryDefinition": "decrease, lower; censure, criticize"
    },
    "Keys": {
      "Hanzi": 1116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "GeneralStandard": 1116,
      "Radical": "貝",
      "HSK": 9,
      "FrequencyRank": 2536,
      "StrokeCount": 8
    }
  },
  "乞丐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐgài",
      "PrimaryDefinition": "beggar"
    },
    "Keys": {
      "Word": 8605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "休养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūyǎng",
      "PrimaryDefinition": "to recuperate, to recover, to convalesce"
    },
    "Keys": {
      "Word": 10075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "崀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làng",
      "PrimaryDefinition": "place name in hunan province"
    },
    "Keys": {
      "Hanzi": 7002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7002,
      "Radical": "山",
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "顸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hān",
      "PrimaryDefinition": "large face, flat face; stupid"
    },
    "Keys": {
      "Hanzi": 4090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4090,
      "Radical": "干",
      "FrequencyRank": 6214,
      "RadicalIndex": "51.6",
      "StrokeCount": 9
    }
  },
  "溍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "water"
    },
    "Keys": {
      "Hanzi": 7588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7588,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "湘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "Hunan province"
    },
    "Keys": {
      "Hanzi": 2752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2752,
      "Radical": "水",
      "FrequencyRank": 2245,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "提倡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíchàng",
      "PrimaryDefinition": "advocate, encourage, recommend"
    },
    "Keys": {
      "Word": 3971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "唰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuā",
      "PrimaryDefinition": "swish, rustle"
    },
    "Keys": {
      "Hanzi": 4853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4853,
      "Radical": "口",
      "FrequencyRank": 4471,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "现成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànchéng",
      "PrimaryDefinition": "ready-made, readily available"
    },
    "Keys": {
      "Word": 9898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "排练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páiliàn",
      "PrimaryDefinition": "to rehearse, rehearsal"
    },
    "Keys": {
      "Word": 8457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妄想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngxiǎng",
      "PrimaryDefinition": "to attempt vainly, a vain attempt, delusion"
    },
    "Keys": {
      "Word": 9675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熏陶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūntáo",
      "PrimaryDefinition": "to seep in, to influence, to nurture, influence, training"
    },
    "Keys": {
      "Word": 10145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "插图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chātú",
      "PrimaryDefinition": "illustration"
    },
    "Keys": {
      "Word": 5785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "revolve, move in orbit; return"
    },
    "Keys": {
      "Hanzi": 2393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "70.7",
      "GeneralStandard": 2393,
      "Radical": "方",
      "HSK": 6,
      "FrequencyRank": 1383,
      "StrokeCount": 11
    }
  },
  "害怕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàipà",
      "PrimaryDefinition": "dread, be afraid/scared, fearful, frighten, fear, frightened, shrink_from, be afraid, funk, scared, be scared, tremble"
    },
    "Keys": {
      "Word": 1539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "霄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "sky; clouds, mist; night"
    },
    "Keys": {
      "Hanzi": 3230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3230,
      "Radical": "雨",
      "FrequencyRank": 3129,
      "RadicalIndex": "173.7",
      "StrokeCount": 15
    }
  },
  "脸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎn",
      "PrimaryDefinition": "face; cheek; reputation"
    },
    "Keys": {
      "Hanzi": 2359,
      "Word": 844
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.7",
      "Grade": 2,
      "GeneralStandard": 2359,
      "Radical": "肉",
      "HSK": 2,
      "FrequencyRank": 615,
      "StrokeCount": 11
    }
  },
  "回味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíwèi",
      "PrimaryDefinition": "to reflect on, to ponder over, aftertaste"
    },
    "Keys": {
      "Word": 7209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biào",
      "PrimaryDefinition": "divide, distribute"
    },
    "Keys": {
      "Hanzi": 4563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4563,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "藜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "Chenopodium album, pigweed"
    },
    "Keys": {
      "Hanzi": 6320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6320,
      "Radical": "艸",
      "FrequencyRank": 4556,
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "芨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "name of a plant, the roots are used for various purposes"
    },
    "Keys": {
      "Hanzi": 3581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3581,
      "Radical": "艸",
      "FrequencyRank": 4928,
      "RadicalIndex": "140.4",
      "StrokeCount": 6
    }
  },
  "觏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "meet or see unexpectedly"
    },
    "Keys": {
      "Hanzi": 5625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5625,
      "Radical": "見",
      "FrequencyRank": 6711,
      "RadicalIndex": "147.1",
      "StrokeCount": 14
    }
  },
  "仨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sā",
      "PrimaryDefinition": "(coll.) three (cannot be followed by a measure word)"
    },
    "Keys": {
      "Hanzi": 3541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3541,
      "Radical": "人",
      "FrequencyRank": 4685,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "逝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "pass away; die"
    },
    "Keys": {
      "Hanzi": 1803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "GeneralStandard": 1803,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 2102,
      "StrokeCount": 10
    }
  },
  "公主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzhǔ",
      "PrimaryDefinition": "princess"
    },
    "Keys": {
      "Word": 4613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "英勇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngyǒng",
      "PrimaryDefinition": "heroic, gallant, valiant"
    },
    "Keys": {
      "Word": 3106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "敲边鼓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo biāngǔ",
      "PrimaryDefinition": "to back sb up, to support sb in an argument, (lit. to beat nearby drum)"
    },
    "Keys": {
      "Word": 8698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清脆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngcuì",
      "PrimaryDefinition": "sharp and clear, crisp, melodious, ringing, tinkling, silvery (of sound), fragile, frail, also written 輕脆|轻脆"
    },
    "Keys": {
      "Word": 8745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "a black ram"
    },
    "Keys": {
      "Hanzi": 7045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7045,
      "Radical": "羊",
      "RadicalIndex": "123.4",
      "StrokeCount": 10
    }
  },
  "佥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "all, together, unanimous"
    },
    "Keys": {
      "Hanzi": 3738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3738,
      "Radical": "人",
      "FrequencyRank": 5613,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "香料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngliào",
      "PrimaryDefinition": "spice, flavoring, condiment, perfume"
    },
    "Keys": {
      "Word": 9931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用功": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yònggōng",
      "PrimaryDefinition": "study, studious, hardworking, niggle, grind"
    },
    "Keys": {
      "Word": 10445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "grey horse"
    },
    "Keys": {
      "Hanzi": 6918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6918,
      "Radical": "馬",
      "FrequencyRank": 6951,
      "RadicalIndex": "187.6",
      "StrokeCount": 9
    }
  },
  "鳏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "huge fish; widower; bachelor"
    },
    "Keys": {
      "Hanzi": 6354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6354,
      "Radical": "魚",
      "FrequencyRank": 5382,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "将近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāngjìn",
      "PrimaryDefinition": "almost"
    },
    "Keys": {
      "Word": 1604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "镝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "dysprosium"
    },
    "Keys": {
      "Hanzi": 6117
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6117,
      "Radical": "金",
      "FrequencyRank": 6358,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "刷新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāxīn",
      "PrimaryDefinition": "to renovate, to refurbish, to refresh (computer window), to write a new page (in history), to break (a record)"
    },
    "Keys": {
      "Word": 9237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相互": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānghù",
      "PrimaryDefinition": "each other, mutually, one another, in return, reciprocally"
    },
    "Keys": {
      "Word": 2041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "体谅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐliàng",
      "PrimaryDefinition": "make allowances for"
    },
    "Keys": {
      "Word": 9464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "feudal title or rank"
    },
    "Keys": {
      "Hanzi": 3421
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "87.14",
      "GeneralStandard": 3421,
      "Radical": "爪",
      "HSK": 7,
      "FrequencyRank": 1925,
      "StrokeCount": 17
    }
  },
  "进而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnér",
      "PrimaryDefinition": "conj.: proceeding to the next step"
    },
    "Keys": {
      "Word": 7582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宿舍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùshè",
      "PrimaryDefinition": "berth, serai, hall, dorm room, lodging_house, dormitory room, dorm, hostel, billet, dormitory, living quarters, house"
    },
    "Keys": {
      "Word": 3944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "改名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎimíng",
      "PrimaryDefinition": "to change one's name"
    },
    "Keys": {
      "Word": 6655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "instrument"
    },
    "Keys": {
      "Hanzi": 6798
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6798,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "厕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cè",
      "PrimaryDefinition": "mingle with; toilet, lavatory"
    },
    "Keys": {
      "Hanzi": 1047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.6",
      "GeneralStandard": 1047,
      "Radical": "厂",
      "HSK": 6,
      "FrequencyRank": 2920,
      "StrokeCount": 8
    }
  },
  "过往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòwǎng",
      "PrimaryDefinition": "to come and go, to have friendly relations with, in the past, previous"
    },
    "Keys": {
      "Word": 6955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔn",
      "PrimaryDefinition": "roller"
    },
    "Keys": {
      "Hanzi": 5896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5896,
      "Radical": "石",
      "FrequencyRank": 5987,
      "RadicalIndex": "112.1",
      "StrokeCount": 15
    }
  },
  "资料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīliào",
      "PrimaryDefinition": "food, source, info, means, data, information, material, datum, data point, matter"
    },
    "Keys": {
      "Word": 3205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "豳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "a Zhou-dynasty state"
    },
    "Keys": {
      "Hanzi": 6222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6222,
      "Radical": "豕",
      "FrequencyRank": 6279,
      "RadicalIndex": "152.1",
      "StrokeCount": 17
    }
  },
  "讲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng",
      "PrimaryDefinition": "explain; discuss; talk"
    },
    "Keys": {
      "Hanzi": 550,
      "Word": 759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "Grade": 2,
      "GeneralStandard": 550,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 555,
      "StrokeCount": 6
    }
  },
  "硔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7145,
      "Radical": "石",
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "鲦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": "minnow"
    },
    "Keys": {
      "Hanzi": 7800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7800,
      "Radical": "魚",
      "FrequencyRank": 6606,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "拤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3838
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3838,
      "Radical": "手",
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "研讨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yántǎo",
      "PrimaryDefinition": "deliberate, discuss"
    },
    "Keys": {
      "Word": 10187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火箭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒjiàn",
      "PrimaryDefinition": "rocket, CL:枚[mei2]"
    },
    "Keys": {
      "Word": 4689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "FALSE"
    },
    "Keys": {
      "Hanzi": 2253,
      "Word": 10089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "141.5",
      "Grade": 7,
      "GeneralStandard": 2253,
      "Radical": "虍",
      "HSK": 5,
      "FrequencyRank": 1071,
      "StrokeCount": 11
    }
  },
  "来往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láiwang",
      "PrimaryDefinition": "associate, contact"
    },
    "Keys": {
      "Word": 4806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "stomach; gizzard of fowl"
    },
    "Keys": {
      "Hanzi": 1494,
      "Word": 4009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "Grade": 5,
      "GeneralStandard": 1494,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1957,
      "StrokeCount": 9
    }
  },
  "捐助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juānzhù",
      "PrimaryDefinition": "to donate, to offer (aid), contribution, donation"
    },
    "Keys": {
      "Word": 4769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "酷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kù",
      "PrimaryDefinition": "strong, stimulating; very"
    },
    "Keys": {
      "Hanzi": 3087,
      "Word": 4802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.7",
      "Grade": 6,
      "GeneralStandard": 3087,
      "Radical": "酉",
      "HSK": 6,
      "FrequencyRank": 1867,
      "StrokeCount": 14
    }
  },
  "赈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "relieve, aid distressed; rich"
    },
    "Keys": {
      "Hanzi": 4863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4863,
      "Radical": "貝",
      "FrequencyRank": 4123,
      "RadicalIndex": "154.7",
      "StrokeCount": 11
    }
  },
  "盍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "what? why not?"
    },
    "Keys": {
      "Hanzi": 4426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4426,
      "Radical": "皿",
      "FrequencyRank": 5625,
      "RadicalIndex": "108.5",
      "StrokeCount": 10
    }
  },
  "恁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèn",
      "PrimaryDefinition": "that, like this, thus, so, such"
    },
    "Keys": {
      "Hanzi": 4569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4569,
      "Radical": "心",
      "FrequencyRank": 5173,
      "RadicalIndex": "61.6",
      "StrokeCount": 10
    }
  },
  "键": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "door bolt, lock bolt; key"
    },
    "Keys": {
      "Hanzi": 2943,
      "Word": 3607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.8",
      "Grade": 5,
      "GeneralStandard": 2943,
      "Radical": "金",
      "HSK": 5,
      "FrequencyRank": 1471,
      "StrokeCount": 13
    }
  },
  "种子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒngzi",
      "PrimaryDefinition": "pippin, berry, pip, edible seed, key, spore, seed, canary seed"
    },
    "Keys": {
      "Word": 2185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "瀱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8066,
      "Radical": "水",
      "RadicalIndex": "85.17",
      "StrokeCount": 20
    }
  },
  "巳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "the hours from 9 to 11; 6th terrestrial branch"
    },
    "Keys": {
      "Hanzi": 62
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 62,
      "Radical": "己",
      "FrequencyRank": 3381,
      "RadicalIndex": "49",
      "StrokeCount": 3
    }
  },
  "陟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "climb, scale, ascend; proceed"
    },
    "Keys": {
      "Hanzi": 4381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4381,
      "Radical": "阜",
      "FrequencyRank": 5764,
      "RadicalIndex": "170.7",
      "StrokeCount": 9
    }
  },
  "荨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "nettle"
    },
    "Keys": {
      "Hanzi": 4132
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4132,
      "Radical": "艸",
      "FrequencyRank": 4259,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "辞去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíqù",
      "PrimaryDefinition": "to resign, to quit"
    },
    "Keys": {
      "Word": 6039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngguò",
      "PrimaryDefinition": "pass, go through, undergo"
    },
    "Keys": {
      "Word": 792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "阘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "upper-story door or window"
    },
    "Keys": {
      "Hanzi": 7579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7579,
      "Radical": "門",
      "FrequencyRank": 7178,
      "RadicalIndex": "169.1",
      "StrokeCount": 13
    }
  },
  "时刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíkè",
      "PrimaryDefinition": "moment, occasion, juncture, sand, hour, time"
    },
    "Keys": {
      "Word": 1901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "打开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ kāi",
      "PrimaryDefinition": "unscrew, undo, break_out, open up, unbar, unpack, unwind, shoot, turn, switch_on, open, switch on, unroll, turn/switch on, unclose, straighten, unfold, crack, unwrap, ope, turn_on, turn on, chop"
    },
    "Keys": {
      "Word": 59
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "蛏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "razor clam"
    },
    "Keys": {
      "Hanzi": 4840
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4840,
      "Radical": "虫",
      "FrequencyRank": 5694,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "亲友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnyǒu",
      "PrimaryDefinition": "gossip, kith, hail-fellow, relatives and friends, kith and kin, sidekick, familiar, inseparable"
    },
    "Keys": {
      "Word": 8727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "各位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèwèi",
      "PrimaryDefinition": "everybody, all (guests, colleagues etc), all of you"
    },
    "Keys": {
      "Word": 1490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "level, rank, class; grade"
    },
    "Keys": {
      "Hanzi": 594,
      "Word": 745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.3",
      "Grade": 2,
      "GeneralStandard": 594,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 415,
      "StrokeCount": 6
    }
  },
  "孤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "orphan, fatherless; solitary"
    },
    "Keys": {
      "Hanzi": 1319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.5",
      "GeneralStandard": 1319,
      "Radical": "子",
      "HSK": 6,
      "FrequencyRank": 1334,
      "StrokeCount": 8
    }
  },
  "髓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǐ",
      "PrimaryDefinition": "bone marrow; essences, substances"
    },
    "Keys": {
      "Hanzi": 3494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "188.13",
      "GeneralStandard": 3494,
      "Radical": "骨",
      "HSK": 8,
      "FrequencyRank": 2808,
      "StrokeCount": 21
    }
  },
  "怪不得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàibude",
      "PrimaryDefinition": "no wonder!, so that's why!"
    },
    "Keys": {
      "Word": 6862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发抖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fādǒu",
      "PrimaryDefinition": "to tremble, to shake, to shiver"
    },
    "Keys": {
      "Word": 6434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哪知道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎzhīdào",
      "PrimaryDefinition": "Where to know"
    },
    "Keys": {
      "Word": 8364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夜晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèwǎn",
      "PrimaryDefinition": "night, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 10267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "草案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎoàn",
      "PrimaryDefinition": "ground plan, plan, blueprint, a protocol, skeleton, a draft plan, a draft, sketch, rough, an outline, draft"
    },
    "Keys": {
      "Word": 5774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "bore with awl; bright, charming"
    },
    "Keys": {
      "Hanzi": 7455
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7455,
      "Radical": "矛",
      "FrequencyRank": 7243,
      "RadicalIndex": "110.7",
      "StrokeCount": 12
    }
  },
  "国籍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guójí",
      "PrimaryDefinition": "nationality"
    },
    "Keys": {
      "Word": 3526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "会面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì miàn",
      "PrimaryDefinition": "to meet with, meeting"
    },
    "Keys": {
      "Word": 7219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "廊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "corridor, porch, veranda"
    },
    "Keys": {
      "Hanzi": 2384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.9",
      "GeneralStandard": 2384,
      "Radical": "广",
      "HSK": 7,
      "FrequencyRank": 2075,
      "StrokeCount": 11
    }
  },
  "秤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèng",
      "PrimaryDefinition": "balance, scale, steelyard"
    },
    "Keys": {
      "Hanzi": 1943,
      "Word": 5891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "Grade": 7,
      "GeneralStandard": 1943,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 3737,
      "StrokeCount": 10
    }
  },
  "祠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "ancestral temple; offer sacrifice"
    },
    "Keys": {
      "Hanzi": 1713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1713,
      "Radical": "示",
      "FrequencyRank": 3319,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "空白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòngbái",
      "PrimaryDefinition": "blank space"
    },
    "Keys": {
      "Word": 7830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "投": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu",
      "PrimaryDefinition": "throw, cast, fling, pitch; jump"
    },
    "Keys": {
      "Hanzi": 643,
      "Word": 2945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 4,
      "GeneralStandard": 643,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 516,
      "StrokeCount": 7
    }
  },
  "瑰宝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guībǎo",
      "PrimaryDefinition": "gem, (fig.) rare and valuable item, gem, treasure"
    },
    "Keys": {
      "Word": 6919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "axe"
    },
    "Keys": {
      "Hanzi": 137,
      "Radical": 69,
      "Word": 785
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "69",
      "Grade": 2,
      "GeneralStandard": 137,
      "Radical": "斤",
      "HSK": 2,
      "FrequencyRank": 1866,
      "StrokeCount": 4
    }
  },
  "捷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "win, victory, triumph"
    },
    "Keys": {
      "Hanzi": 2167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2167,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 1789,
      "StrokeCount": 11
    }
  },
  "标签": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāoqiān",
      "PrimaryDefinition": "mark, ticket, gummed label, tab, markup, sticker, label, tally, tag"
    },
    "Keys": {
      "Word": 5625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迁就": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānjiù",
      "PrimaryDefinition": "to yield, to adapt to, to accommodate to (sth)"
    },
    "Keys": {
      "Word": 8649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāsong",
      "PrimaryDefinition": "to transmit, to dispatch, to issue (an official document or credential)"
    },
    "Keys": {
      "Word": 1434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "吃亏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī kuī",
      "PrimaryDefinition": "get the worst of it, come to grief, suffer loss, suffer losses"
    },
    "Keys": {
      "Word": 5895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "freezing; stopped up, closed off"
    },
    "Keys": {
      "Hanzi": 6544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6544,
      "Radical": "冫",
      "FrequencyRank": 8997,
      "RadicalIndex": "15.4",
      "StrokeCount": 6
    }
  },
  "蔟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "frame on which silkworms spin"
    },
    "Keys": {
      "Hanzi": 5651
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5651,
      "Radical": "艸",
      "FrequencyRank": 7418,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "吉普": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jípǔ",
      "PrimaryDefinition": "Jeep (car brand)"
    },
    "Keys": {
      "Word": 7289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丑闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒuwén",
      "PrimaryDefinition": "scandal"
    },
    "Keys": {
      "Word": 5948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng",
      "PrimaryDefinition": "disappointed, dissatisfied"
    },
    "Keys": {
      "Hanzi": 3788
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3788,
      "Radical": "心",
      "FrequencyRank": 3409,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "边疆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānjiāng",
      "PrimaryDefinition": "border area, borderland, frontier, frontier region"
    },
    "Keys": {
      "Word": 5596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6656,
      "Radical": "耳",
      "FrequencyRank": 6738,
      "RadicalIndex": "128.2",
      "StrokeCount": 8
    }
  },
  "情人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngrén",
      "PrimaryDefinition": "inamorato, steady, sweetie, Gill, jo, girlfriend, swain, valentine, paramour, turtledove, jill, girl, sweetling, mouse, heart-throb, lassie, truelove, soul mate, flame, ladybird, ladylove, lover, sweetheart, mistress, admirer, hon, lovey, inamorata, honeybunch, turtle, sweeting, bedmate"
    },
    "Keys": {
      "Word": 8760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènwài",
      "PrimaryDefinition": "exceptionally, not one's responsibility or job"
    },
    "Keys": {
      "Word": 6553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光辉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānghuī",
      "PrimaryDefinition": "radiancy, irradiancy, blaze, sparkle, fire, shininess, shine, resplendency, glory, glare, magnificence, sheen, lustre, grandeur, splendor, lucidity, coruscation, refulgence, grandness, burnish, brilliance, flame, glow, luminosity, glitter, luster, refulgency, radiance, irradiance, glowing, halo, resplendence, splendour, effulgence"
    },
    "Keys": {
      "Word": 4634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "苛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "small, petty; harsh, rigorous"
    },
    "Keys": {
      "Hanzi": 1001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1001,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 3095,
      "StrokeCount": 8
    }
  },
  "冂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "down box"
    },
    "Keys": {
      "Radical": 13
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "期末": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīmò",
      "PrimaryDefinition": "end of term"
    },
    "Keys": {
      "Word": 2788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "麈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "species of deer"
    },
    "Keys": {
      "Hanzi": 6163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6163,
      "Radical": "鹿",
      "FrequencyRank": 6586,
      "RadicalIndex": "198.5",
      "StrokeCount": 16
    }
  },
  "细致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìzhì",
      "PrimaryDefinition": "careful, meticulous, painstaking"
    },
    "Keys": {
      "Word": 3004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "呼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "breathe sigh, exhale; call, shout"
    },
    "Keys": {
      "Hanzi": 1099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1099,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 843,
      "StrokeCount": 8
    }
  },
  "变成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn chéng",
      "PrimaryDefinition": "grow_into, change into, go, form, grow into, change state, develop into, turn to, fall, turn_into, be, turn, become, turn into, grow, run, get"
    },
    "Keys": {
      "Word": 524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "童": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "child, boy, servant boy; virgin"
    },
    "Keys": {
      "Hanzi": 2735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "117.7",
      "GeneralStandard": 2735,
      "Radical": "立",
      "HSK": 4,
      "FrequencyRank": 1229,
      "StrokeCount": 12
    }
  },
  "嵘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "high, steep; lofty, towering"
    },
    "Keys": {
      "Hanzi": 5163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5163,
      "Radical": "山",
      "FrequencyRank": 4746,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "政权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngquán",
      "PrimaryDefinition": "rein, political power, political/state power, state power, power, regime, political"
    },
    "Keys": {
      "Word": 5343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "莫非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòfēi",
      "PrimaryDefinition": "can it be possible that, could it be"
    },
    "Keys": {
      "Word": 8329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "còu",
      "PrimaryDefinition": "between the skin and the flesh"
    },
    "Keys": {
      "Hanzi": 5517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5517,
      "Radical": "肉",
      "FrequencyRank": 6999,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "稳固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěngù",
      "PrimaryDefinition": "stable, steady, firm, to stabilize"
    },
    "Keys": {
      "Word": 9747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "御": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "drive, ride; chariot; manage"
    },
    "Keys": {
      "Hanzi": 2704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.8",
      "GeneralStandard": 2704,
      "Radical": "彳",
      "HSK": 7,
      "FrequencyRank": 1381,
      "StrokeCount": 12
    }
  },
  "驻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "to be stationed at, reside at; to stop"
    },
    "Keys": {
      "Hanzi": 1345,
      "Word": 5381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.5",
      "Grade": 6,
      "GeneralStandard": 1345,
      "Radical": "馬",
      "HSK": 6,
      "FrequencyRank": 1288,
      "StrokeCount": 8
    }
  },
  "上门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng mén",
      "PrimaryDefinition": "to drop in, to visit, to lock a door, (of a shop) to close, to go and live with one's wife's family, in effect becoming a member of her family"
    },
    "Keys": {
      "Word": 2843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "相对而言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngduì éryán",
      "PrimaryDefinition": "Relatively speaking"
    },
    "Keys": {
      "Word": 9921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "对面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìmiàn",
      "PrimaryDefinition": "opposite"
    },
    "Keys": {
      "Word": 640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "瑆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7464,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "公函": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnghán",
      "PrimaryDefinition": "official letter"
    },
    "Keys": {
      "Word": 6765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "照顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàogu",
      "PrimaryDefinition": "look after, care for, attend to, patronize (as customer)"
    },
    "Keys": {
      "Word": 1208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "出洋相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū yángxiàng",
      "PrimaryDefinition": "to make a fool of oneself"
    },
    "Keys": {
      "Word": 5974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "作业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòyè",
      "PrimaryDefinition": "school assignment, work, task, operation, production"
    },
    "Keys": {
      "Word": 1259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "领略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐnglüè",
      "PrimaryDefinition": "to have a taste of, to realize, to appreciate"
    },
    "Keys": {
      "Word": 8079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "质地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìdì",
      "PrimaryDefinition": "texture, background (texture), grain, quality, character, disposition"
    },
    "Keys": {
      "Word": 10789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恰恰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàqià",
      "PrimaryDefinition": "exactly, just, precisely"
    },
    "Keys": {
      "Word": 4924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "荧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "shine, shimmer; shining, dazzling"
    },
    "Keys": {
      "Hanzi": 1417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "GeneralStandard": 1417,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 3400,
      "StrokeCount": 9
    }
  },
  "登山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng shān",
      "PrimaryDefinition": "engage in mountain-climbing, sport"
    },
    "Keys": {
      "Word": 2402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蛆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "maggots"
    },
    "Keys": {
      "Hanzi": 4834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4834,
      "Radical": "虫",
      "FrequencyRank": 4251,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "烂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làn",
      "PrimaryDefinition": "rotten, spoiled, decayed"
    },
    "Keys": {
      "Hanzi": 1660,
      "Word": 3677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "Grade": 5,
      "GeneralStandard": 1660,
      "Radical": "火",
      "HSK": 5,
      "FrequencyRank": 1754,
      "StrokeCount": 9
    }
  },
  "迸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèng",
      "PrimaryDefinition": "gush out; burst forth; split"
    },
    "Keys": {
      "Hanzi": 4321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.8",
      "GeneralStandard": 4321,
      "Radical": "辵",
      "HSK": 6,
      "FrequencyRank": 3290,
      "StrokeCount": 9
    }
  },
  "眢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": "eyes without brightness"
    },
    "Keys": {
      "Hanzi": 7038
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7038,
      "Radical": "目",
      "FrequencyRank": 6436,
      "RadicalIndex": "109.5",
      "StrokeCount": 10
    }
  },
  "乩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "to divine"
    },
    "Keys": {
      "Hanzi": 3590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3590,
      "Radical": "乙",
      "FrequencyRank": 4609,
      "RadicalIndex": "5.5",
      "StrokeCount": 6
    }
  },
  "蚋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": "gnat, mosquito"
    },
    "Keys": {
      "Hanzi": 4510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4510,
      "Radical": "虫",
      "FrequencyRank": 5218,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "青": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "blue"
    },
    "Keys": {
      "Hanzi": 947,
      "Radical": 174,
      "Word": 3817
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "174",
      "Grade": 5,
      "GeneralStandard": 947,
      "Radical": "青",
      "HSK": 2,
      "FrequencyRank": 497,
      "StrokeCount": 8
    }
  },
  "有望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuwàng",
      "PrimaryDefinition": "hopeful, promising"
    },
    "Keys": {
      "Word": 10480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "glistening plumage; reflection of the sun on water"
    },
    "Keys": {
      "Hanzi": 7915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7915,
      "Radical": "羽",
      "FrequencyRank": 8081,
      "RadicalIndex": "124.1",
      "StrokeCount": 16
    }
  },
  "大约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàyuē",
      "PrimaryDefinition": "about, around, probably, likely"
    },
    "Keys": {
      "Word": 1386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "低迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīmí",
      "PrimaryDefinition": "low"
    },
    "Keys": {
      "Word": 6230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "取暖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ nuǎn",
      "PrimaryDefinition": "to warm oneself (by a fire etc)"
    },
    "Keys": {
      "Word": 8788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎn",
      "PrimaryDefinition": "a grain of rice; rice gruel mixed with meat"
    },
    "Keys": {
      "Hanzi": 5795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5795,
      "Radical": "米",
      "FrequencyRank": 5561,
      "RadicalIndex": "119.8",
      "StrokeCount": 14
    }
  },
  "正好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènghǎo",
      "PrimaryDefinition": "just, exactly"
    },
    "Keys": {
      "Word": 1219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鼩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8011,
      "Radical": "鼠",
      "FrequencyRank": 6596,
      "RadicalIndex": "208.5",
      "StrokeCount": 18
    }
  },
  "垙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6769,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "辩论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànlùn",
      "PrimaryDefinition": "argue, debate"
    },
    "Keys": {
      "Word": 2267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "矶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "jetty; submerged rock; eddy"
    },
    "Keys": {
      "Hanzi": 3686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3686,
      "Radical": "石",
      "FrequencyRank": 3232,
      "RadicalIndex": "112.2",
      "StrokeCount": 7
    }
  },
  "宽广": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānguǎng",
      "PrimaryDefinition": "wide, vast, extensive, broad"
    },
    "Keys": {
      "Word": 2669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "淦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàn",
      "PrimaryDefinition": "river in Jiangxi province; water leaking into a boat"
    },
    "Keys": {
      "Hanzi": 4975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4975,
      "Radical": "水",
      "FrequencyRank": 4611,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "典型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnxíng",
      "PrimaryDefinition": "typical, representative"
    },
    "Keys": {
      "Word": 2411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "保养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoyǎng",
      "PrimaryDefinition": "take good care of one's health, maintain, keep in good repair"
    },
    "Keys": {
      "Word": 3237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "狝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "hunt; autumn hunting; to capture with a fine net"
    },
    "Keys": {
      "Hanzi": 6713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6713,
      "Radical": "犬",
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "被迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèipò",
      "PrimaryDefinition": "forced, be forced, be compelled/forced, be compelled"
    },
    "Keys": {
      "Word": 2266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "枇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "loquat"
    },
    "Keys": {
      "Hanzi": 3875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3875,
      "Radical": "木",
      "FrequencyRank": 5358,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "循环": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnhuán",
      "PrimaryDefinition": "circulate, cycle.circulatory system"
    },
    "Keys": {
      "Word": 5207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "篥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "bugle, 觱篥 bìlì Tatar (Tartar) horn"
    },
    "Keys": {
      "Hanzi": 6125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6125,
      "Radical": "竹",
      "FrequencyRank": 7025,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "心里话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnlihuà",
      "PrimaryDefinition": "(to express one's) true feelings, what is on one's mind, secret mind"
    },
    "Keys": {
      "Word": 10008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肯定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěndìng",
      "PrimaryDefinition": "affirm, approve, regard as positive"
    },
    "Keys": {
      "Word": 3668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "传染病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánrǎnbìng",
      "PrimaryDefinition": "infectious disease, contagious disease, pestilence"
    },
    "Keys": {
      "Word": 6008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怎么办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zěnmebàn",
      "PrimaryDefinition": "what should someone do?"
    },
    "Keys": {
      "Word": 1197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "盲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "blind; unperceptive, shortsighted"
    },
    "Keys": {
      "Hanzi": 1222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.3",
      "GeneralStandard": 1222,
      "Radical": "目",
      "HSK": 6,
      "FrequencyRank": 2079,
      "StrokeCount": 8
    }
  },
  "识别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíbié",
      "PrimaryDefinition": "to distinguish, to discern"
    },
    "Keys": {
      "Word": 9112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "sound of ripping or giggling"
    },
    "Keys": {
      "Hanzi": 4498
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4498,
      "Radical": "口",
      "FrequencyRank": 3632,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "炸弹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàdàn",
      "PrimaryDefinition": "bomb, CL:枚[mei2],顆|颗[ke1]"
    },
    "Keys": {
      "Word": 5313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "轼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "horizontal wooden bar in front"
    },
    "Keys": {
      "Hanzi": 4484
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4484,
      "Radical": "車",
      "FrequencyRank": 2926,
      "RadicalIndex": "159.6",
      "StrokeCount": 10
    }
  },
  "漦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "saliva; spittle; flowing downstream"
    },
    "Keys": {
      "Hanzi": 7750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7750,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 15
    }
  },
  "外来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàilái",
      "PrimaryDefinition": "foreign, adventive, externally, incoming, external, outside, exotic"
    },
    "Keys": {
      "Word": 5123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "独身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúshēn",
      "PrimaryDefinition": "unmarried, single"
    },
    "Keys": {
      "Word": 6360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "决心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéxīn",
      "PrimaryDefinition": "determination, resolution"
    },
    "Keys": {
      "Word": 1661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "胨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "a kind of protein"
    },
    "Keys": {
      "Hanzi": 4279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4279,
      "Radical": "肉",
      "FrequencyRank": 5397,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "叮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "exhort or enjoin repeatedly"
    },
    "Keys": {
      "Hanzi": 241
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 241,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2611,
      "StrokeCount": 5
    }
  },
  "镩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuān",
      "PrimaryDefinition": "pick, poker"
    },
    "Keys": {
      "Hanzi": 6250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6250,
      "Radical": "金",
      "FrequencyRank": 7068,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "外边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàibian",
      "PrimaryDefinition": "outside, outer surface, abroad, place other than one's home"
    },
    "Keys": {
      "Word": 364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "镛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "large bell used as musical instrument"
    },
    "Keys": {
      "Hanzi": 6116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6116,
      "Radical": "金",
      "FrequencyRank": 5640,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "罐头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàntou",
      "PrimaryDefinition": "tin, can, canned goods"
    },
    "Keys": {
      "Word": 6897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "方式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngshì",
      "PrimaryDefinition": "path, plan, pattern, tenor, system, method, manner, style, way, guise, fashion, wise, means, shape, how, embodiment, agency, gate, mode, sort"
    },
    "Keys": {
      "Word": 1444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "电话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànhuà",
      "PrimaryDefinition": "blower, phone call, headphone, earpiece, telephone, telephone_call, buzz, earphone, horn, telephone set, phone_call, telephony, phone"
    },
    "Keys": {
      "Word": 76
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "药材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàocái",
      "PrimaryDefinition": "drug ingredients"
    },
    "Keys": {
      "Word": 10241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演唱会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnchànghuì",
      "PrimaryDefinition": "vocal recital"
    },
    "Keys": {
      "Word": 2081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "拒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "ward off with hand, defend"
    },
    "Keys": {
      "Hanzi": 620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 620,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1331,
      "StrokeCount": 7
    }
  },
  "转动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn dòng",
      "PrimaryDefinition": "turn (round), move"
    },
    "Keys": {
      "Word": 5385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "专": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuān",
      "PrimaryDefinition": "monopolize, take sole possession"
    },
    "Keys": {
      "Hanzi": 83
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.3",
      "GeneralStandard": 83,
      "Radical": "一",
      "HSK": 3,
      "FrequencyRank": 485,
      "StrokeCount": 4
    }
  },
  "樗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū",
      "PrimaryDefinition": "Ailanthus glandulosa or A. altissima, a kind of tree useless as timber"
    },
    "Keys": {
      "Hanzi": 5886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5886,
      "Radical": "木",
      "FrequencyRank": 5525,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "鸢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": "kite; Milvus species (various)"
    },
    "Keys": {
      "Hanzi": 3895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3895,
      "Radical": "鳥",
      "FrequencyRank": 4922,
      "RadicalIndex": "196.3",
      "StrokeCount": 8
    }
  },
  "边境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānjìng",
      "PrimaryDefinition": "frontier, border"
    },
    "Keys": {
      "Word": 3258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "君子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnzǐ",
      "PrimaryDefinition": "a man of noble character, man of noble character, gentleman, man of worth, sovereign"
    },
    "Keys": {
      "Word": 7732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "调侃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáokǎn",
      "PrimaryDefinition": "to ridicule, to tease, to mock, idle talk, chitchat"
    },
    "Keys": {
      "Word": 9499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出毛病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū máobing",
      "PrimaryDefinition": "a problem appears, to break down"
    },
    "Keys": {
      "Word": 5961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "a large, pear-shaped earthenware jar"
    },
    "Keys": {
      "Hanzi": 4414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4414,
      "Radical": "土",
      "FrequencyRank": 6958,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "违法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi fǎ",
      "PrimaryDefinition": "illegal, be illegal, offend, violate, violate law, transgress, illegitimate, break the law"
    },
    "Keys": {
      "Word": 4000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "不解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjiě",
      "PrimaryDefinition": "not understand"
    },
    "Keys": {
      "Word": 5697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "选举": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnjǔ",
      "PrimaryDefinition": "election"
    },
    "Keys": {
      "Word": 5202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "裘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "fur garments; surname"
    },
    "Keys": {
      "Hanzi": 5409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5409,
      "Radical": "衣",
      "FrequencyRank": 2868,
      "RadicalIndex": "145.7",
      "StrokeCount": 13
    }
  },
  "利索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìsuo",
      "PrimaryDefinition": "nimble"
    },
    "Keys": {
      "Word": 8012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䝙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū",
      "PrimaryDefinition": "a kind of animal like a tiger; fierce wild beasts"
    },
    "Keys": {
      "Hanzi": 7209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7209,
      "Radical": "豸",
      "RadicalIndex": "153.4",
      "StrokeCount": 11
    }
  },
  "整治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngzhì",
      "PrimaryDefinition": "renovate, repair, dredge (river/etc.), punish, discipline, do, work at, prepare, make ready, arrange, regulate, deal with problem or adversary"
    },
    "Keys": {
      "Word": 5341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "媳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "daughter-in-law"
    },
    "Keys": {
      "Hanzi": 3039
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.1",
      "GeneralStandard": 3039,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 2393,
      "StrokeCount": 13
    }
  },
  "上周": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngzhōu",
      "PrimaryDefinition": "last week"
    },
    "Keys": {
      "Word": 964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "争议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngyì",
      "PrimaryDefinition": "controvert, controversy, dispute, disputation"
    },
    "Keys": {
      "Word": 4206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "一连串": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīliánchuàn",
      "PrimaryDefinition": "a string of, in a row, a series of, a chain of, a succession of, in succession"
    },
    "Keys": {
      "Word": 10335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "wailing of child; chirp"
    },
    "Keys": {
      "Hanzi": 5154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5154,
      "Radical": "口",
      "FrequencyRank": 4081,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "小学生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎo xuésheng",
      "PrimaryDefinition": "(primary) pupil, schoolboy, schoolgirl"
    },
    "Keys": {
      "Word": 404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "胜任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngrèn",
      "PrimaryDefinition": "qualified, competent (professionally), to be up to a task"
    },
    "Keys": {
      "Word": 9074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎng",
      "PrimaryDefinition": "hydroxide"
    },
    "Keys": {
      "Hanzi": 4952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4952,
      "Radical": "羊",
      "FrequencyRank": 5059,
      "RadicalIndex": "123.5",
      "StrokeCount": 11
    }
  },
  "广场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎngchǎng",
      "PrimaryDefinition": "mews, agora, public square, concourse, carrefour, piazza, plaza, square, campus, place"
    },
    "Keys": {
      "Word": 698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "走廊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒuláng",
      "PrimaryDefinition": "slype, porch, hallway, foyer, corridor, hall, passageway, gallery, walkway, piazza, vestibule, ambulatory, verandah, alure, passage, aisle"
    },
    "Keys": {
      "Word": 10995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "rhenium"
    },
    "Keys": {
      "Hanzi": 5179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5179,
      "Radical": "金",
      "FrequencyRank": 7005,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "冏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "(archaic form of 炯) light, bright"
    },
    "Keys": {
      "Hanzi": 6598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6598,
      "Radical": "冂",
      "RadicalIndex": "13.5",
      "StrokeCount": 7
    }
  },
  "帑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎng",
      "PrimaryDefinition": "a treasury; public funds"
    },
    "Keys": {
      "Hanzi": 4067
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4067,
      "Radical": "巾",
      "FrequencyRank": 5546,
      "RadicalIndex": "50.5",
      "StrokeCount": 8
    }
  },
  "日报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìbào",
      "PrimaryDefinition": "daily newspaper"
    },
    "Keys": {
      "Word": 959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "㬚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "light; bright, clear"
    },
    "Keys": {
      "Hanzi": 7862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7862,
      "Radical": "日",
      "RadicalIndex": "72.12",
      "StrokeCount": 16
    }
  },
  "下海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià hǎi",
      "PrimaryDefinition": "to go out to sea, to enter the sea (to swim etc), (fig.) to take the plunge (e.g. leave a secure job, or enter prostitution etc)"
    },
    "Keys": {
      "Word": 9859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "捞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lāo",
      "PrimaryDefinition": "scoop out of water; dredge, fish"
    },
    "Keys": {
      "Hanzi": 1781,
      "Word": 7937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1781,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2508,
      "StrokeCount": 10
    }
  },
  "涪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "river in Sichuan province"
    },
    "Keys": {
      "Hanzi": 4978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4978,
      "Radical": "水",
      "FrequencyRank": 5245,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "转移": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnyí",
      "PrimaryDefinition": "shift, transfer, divert, change, transform"
    },
    "Keys": {
      "Word": 3202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "腹部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùbù",
      "PrimaryDefinition": "abdomen, belly, flank"
    },
    "Keys": {
      "Word": 6645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn",
      "PrimaryDefinition": "look, see; examine, scrutinize"
    },
    "Keys": {
      "Hanzi": 1539,
      "Word": 4785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "Grade": 6,
      "GeneralStandard": 1539,
      "Radical": "目",
      "HSK": 1,
      "FrequencyRank": 76,
      "StrokeCount": 9
    }
  },
  "缓解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎnjiě",
      "PrimaryDefinition": "to bring relief, to alleviate (a crisis), to dull (a pain)"
    },
    "Keys": {
      "Word": 2557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "样品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàngpǐn",
      "PrimaryDefinition": "sample, specimen"
    },
    "Keys": {
      "Word": 10229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "田": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "field"
    },
    "Keys": {
      "Hanzi": 244,
      "Radical": 102,
      "Word": 5082
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102",
      "Grade": 6,
      "GeneralStandard": 244,
      "Radical": "田",
      "HSK": 6,
      "FrequencyRank": 778,
      "StrokeCount": 5
    }
  },
  "与日俱增": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔrì-jùzēng",
      "PrimaryDefinition": "to increase steadily, to grow with each passing day"
    },
    "Keys": {
      "Word": 10506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàjí",
      "PrimaryDefinition": "low ranking, low level, underclass, subordinate"
    },
    "Keys": {
      "Word": 9862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "tinkling sound tinkling of pendant gems"
    },
    "Keys": {
      "Hanzi": 6647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6647,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "摊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tān",
      "PrimaryDefinition": "spread out, open; apportion"
    },
    "Keys": {
      "Hanzi": 2844,
      "Word": 9398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 7,
      "GeneralStandard": 2844,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2154,
      "StrokeCount": 13
    }
  },
  "树木": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùmù",
      "PrimaryDefinition": "trees"
    },
    "Keys": {
      "Word": 9230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "传递": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuándì",
      "PrimaryDefinition": "transmit, deliver, transfer"
    },
    "Keys": {
      "Word": 3352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "辅助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔzhù",
      "PrimaryDefinition": "assist"
    },
    "Keys": {
      "Word": 3473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "景区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngqū",
      "PrimaryDefinition": "scenic area"
    },
    "Keys": {
      "Word": 7629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屹立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìlì",
      "PrimaryDefinition": "to tower, to stand straight (of person's bearing)"
    },
    "Keys": {
      "Word": 10360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "应急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìng jí",
      "PrimaryDefinition": "to respond to an emergency, to meet a contingency, (attributive) emergency"
    },
    "Keys": {
      "Word": 5264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "痴心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chīxīn",
      "PrimaryDefinition": "infatuation"
    },
    "Keys": {
      "Word": 5898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎozhuǎn",
      "PrimaryDefinition": "to improve, to take a turn for the better, improvement"
    },
    "Keys": {
      "Word": 4654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "榴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "pomegranate"
    },
    "Keys": {
      "Hanzi": 3080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3080,
      "Radical": "木",
      "FrequencyRank": 2644,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "de",
      "PrimaryDefinition": "earth; soil, ground; region"
    },
    "Keys": {
      "Hanzi": 366,
      "Word": 68
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.3",
      "Grade": 1,
      "GeneralStandard": 366,
      "Radical": "土",
      "HSK": 1,
      "FrequencyRank": 21,
      "StrokeCount": 6
    }
  },
  "硝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "saltpeter, niter; to tan"
    },
    "Keys": {
      "Hanzi": 2590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2590,
      "Radical": "石",
      "FrequencyRank": 3085,
      "RadicalIndex": "112.7",
      "StrokeCount": 12
    }
  },
  "珫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6935,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "歉意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiànyì",
      "PrimaryDefinition": "apology, regret"
    },
    "Keys": {
      "Word": 8686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苦练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔliàn",
      "PrimaryDefinition": "to train hard, to practice diligently, hard work, blood, sweat, and tears"
    },
    "Keys": {
      "Word": 7860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "kind of necklace"
    },
    "Keys": {
      "Hanzi": 4408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4408,
      "Radical": "玉",
      "FrequencyRank": 4586,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "牁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "mooring stake; painter, mooring"
    },
    "Keys": {
      "Hanzi": 4378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4378,
      "Radical": "爿",
      "RadicalIndex": "90.5",
      "StrokeCount": 9
    }
  },
  "燃油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rányóu",
      "PrimaryDefinition": "fuel oil"
    },
    "Keys": {
      "Word": 8823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呈现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxiàn",
      "PrimaryDefinition": "assume, crop out, dish, appearance, show, lay out, present, basset, appear, take_on, emergence, emerge, come forth"
    },
    "Keys": {
      "Word": 5875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕴藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùncáng",
      "PrimaryDefinition": "to hold in store, to contain (untapped reserves etc)"
    },
    "Keys": {
      "Word": 10581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "留下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú xià",
      "PrimaryDefinition": "stay, leave behind, leave, allocate, devote, entail, stick, stay behind, leave_behind, stay put, stick around, remain"
    },
    "Keys": {
      "Word": 853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "优点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōudiǎn",
      "PrimaryDefinition": "strong, merit, vantage, excellency, perfection, assets, beaut, advantage, excellence, upside, strong_point, good point, virtue, desert, desirability, strong point, long_suit, beauty, strong/good point, desirableness"
    },
    "Keys": {
      "Word": 2105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蒲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "type of rush; vine"
    },
    "Keys": {
      "Hanzi": 2857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2857,
      "Radical": "艸",
      "FrequencyRank": 2344,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "业绩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèjì",
      "PrimaryDefinition": "achievement"
    },
    "Keys": {
      "Word": 10264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "clothes; wear, dress"
    },
    "Keys": {
      "Hanzi": 1190,
      "Word": 4570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "74.4",
      "Grade": 6,
      "GeneralStandard": 1190,
      "Radical": "月",
      "HSK": 1,
      "FrequencyRank": 365,
      "StrokeCount": 8
    }
  },
  "转播": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnbō",
      "PrimaryDefinition": "relay (radio/TV broadcast)"
    },
    "Keys": {
      "Word": 10888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "various species of pine and fir"
    },
    "Keys": {
      "Hanzi": 685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 685,
      "Radical": "木",
      "FrequencyRank": 2718,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "关心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānxīn",
      "PrimaryDefinition": "be concerned about"
    },
    "Keys": {
      "Word": 696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "体操": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐcāo",
      "PrimaryDefinition": "jerk, physical exertion, workout, exercise, physical_exercise, gymnastic, calisthenics, exercising, gymnastics, physical exercise, gym"
    },
    "Keys": {
      "Word": 2925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "crane; Grus species (various)"
    },
    "Keys": {
      "Hanzi": 3308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "196.1",
      "GeneralStandard": 3308,
      "Radical": "鳥",
      "HSK": 9,
      "FrequencyRank": 2624,
      "StrokeCount": 15
    }
  },
  "癀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "jaundice"
    },
    "Keys": {
      "Hanzi": 7892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7892,
      "Radical": "疒",
      "FrequencyRank": 9333,
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "元宵节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yuánxiāojié",
      "PrimaryDefinition": "Lantern Festival, the final event of the Spring Festival 春節|春节, on 15th of first month of the lunar calendar"
    },
    "Keys": {
      "Word": 10537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "折合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhéhé",
      "PrimaryDefinition": "to convert into, to amount to, to be equivalent to"
    },
    "Keys": {
      "Word": 10685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "tangled; entwined; crooked"
    },
    "Keys": {
      "Hanzi": 4612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4612,
      "Radical": "手",
      "FrequencyRank": 3350,
      "RadicalIndex": "64.6",
      "StrokeCount": 10
    }
  },
  "控制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòngzhì",
      "PrimaryDefinition": "control, dominate, command"
    },
    "Keys": {
      "Word": 3667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "新郎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnláng",
      "PrimaryDefinition": "groom, bridegroom"
    },
    "Keys": {
      "Word": 3034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "近视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnshì",
      "PrimaryDefinition": "shortsighted, nearsighted, myopia"
    },
    "Keys": {
      "Word": 4745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "璬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7919,
      "Radical": "玉",
      "RadicalIndex": "96.13",
      "StrokeCount": 17
    }
  },
  "面前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànqián",
      "PrimaryDefinition": "in_front, in front of, in the front of, in the face of, front, presence, before"
    },
    "Keys": {
      "Word": 870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "啡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "morphine; coffee"
    },
    "Keys": {
      "Hanzi": 2272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2272,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 2326,
      "StrokeCount": 11
    }
  },
  "嵁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kān",
      "PrimaryDefinition": "rugged"
    },
    "Keys": {
      "Hanzi": 7360
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7360,
      "Radical": "山",
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "擿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tī",
      "PrimaryDefinition": "to select, to pick out from, to discard"
    },
    "Keys": {
      "Hanzi": 7922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7922,
      "Radical": "手",
      "RadicalIndex": "64.15",
      "StrokeCount": 17
    }
  },
  "守候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuhòu",
      "PrimaryDefinition": "to wait for, to expect, to keep watch, to watch over, to nurse"
    },
    "Keys": {
      "Word": 9184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "捆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔn",
      "PrimaryDefinition": "tie up; bind, truss up; bundle"
    },
    "Keys": {
      "Hanzi": 1796,
      "Word": 7897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1796,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2640,
      "StrokeCount": 10
    }
  },
  "得天独厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "détiān-dúhòu",
      "PrimaryDefinition": "blessed by heaven (idiom), enjoying exceptional advantages, favored by nature"
    },
    "Keys": {
      "Word": 6213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōme",
      "PrimaryDefinition": "to what extent, to what degree, however, what, how"
    },
    "Keys": {
      "Word": 644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "砜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 4170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4170,
      "Radical": "石",
      "FrequencyRank": 5995,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "带动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài dòng",
      "PrimaryDefinition": "drive, spur on, bring along"
    },
    "Keys": {
      "Word": 1391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "应当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngdāng",
      "PrimaryDefinition": "should"
    },
    "Keys": {
      "Word": 2098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "猿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "ape"
    },
    "Keys": {
      "Hanzi": 2978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2978,
      "Radical": "犬",
      "FrequencyRank": 2312,
      "RadicalIndex": "94.1",
      "StrokeCount": 13
    }
  },
  "深处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnchù",
      "PrimaryDefinition": "depth, bowel, recesses, profound, oceanic abyss, depths, deep, recess"
    },
    "Keys": {
      "Word": 3886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "高贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoguì",
      "PrimaryDefinition": "noble, high"
    },
    "Keys": {
      "Word": 6698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回馈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíkuì",
      "PrimaryDefinition": "reward, feedback"
    },
    "Keys": {
      "Word": 7205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "廪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐn",
      "PrimaryDefinition": "granary; stockpile"
    },
    "Keys": {
      "Hanzi": 6155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6155,
      "Radical": "广",
      "FrequencyRank": 5447,
      "RadicalIndex": "53.13",
      "StrokeCount": 16
    }
  },
  "原": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "source, origin, beginning"
    },
    "Keys": {
      "Hanzi": 1868,
      "Word": 5288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "27.8",
      "Grade": 6,
      "GeneralStandard": 1868,
      "Radical": "厂",
      "HSK": 2,
      "FrequencyRank": 193,
      "StrokeCount": 10
    }
  },
  "诫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "warn, admonish; warning"
    },
    "Keys": {
      "Hanzi": 1704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "GeneralStandard": 1704,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 2770,
      "StrokeCount": 9
    }
  },
  "以外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐwài",
      "PrimaryDefinition": "other than, except, beyond, outside"
    },
    "Keys": {
      "Word": 1145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "评论员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínglùnyuán",
      "PrimaryDefinition": "commentator"
    },
    "Keys": {
      "Word": 8554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "美食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měishí",
      "PrimaryDefinition": "cate, luxury, good food, delicious food, fleshpot"
    },
    "Keys": {
      "Word": 1742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "原始": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánshǐ",
      "PrimaryDefinition": "original, firsthand, primeval, primitive"
    },
    "Keys": {
      "Word": 4155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "工商界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngshāngjiè",
      "PrimaryDefinition": "industrial and commercial circles"
    },
    "Keys": {
      "Word": 6753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巧克力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎokèlì",
      "PrimaryDefinition": "chocolate (loanword), CL:塊|块[kuai4]"
    },
    "Keys": {
      "Word": 2802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "陬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōu",
      "PrimaryDefinition": "corner, cranny, nook, niche"
    },
    "Keys": {
      "Hanzi": 4681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4681,
      "Radical": "阜",
      "FrequencyRank": 6221,
      "RadicalIndex": "170.8",
      "StrokeCount": 10
    }
  },
  "仳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "separate, part company"
    },
    "Keys": {
      "Hanzi": 6537
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6537,
      "Radical": "人",
      "FrequencyRank": 7350,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "瑬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7818,
      "Radical": "玉",
      "RadicalIndex": "96.1",
      "StrokeCount": 15
    }
  },
  "投入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóurù",
      "PrimaryDefinition": "throw/put into"
    },
    "Keys": {
      "Word": 2948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "风格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnggé",
      "PrimaryDefinition": "plan, signature, idiom, kind, aroma, manner, idiosyncrasy, negligence, style, mode, tone, expressive style, artistic style, touch"
    },
    "Keys": {
      "Word": 2468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "扼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "grasp, clutch; choke, strangle"
    },
    "Keys": {
      "Hanzi": 619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 619,
      "Radical": "手",
      "FrequencyRank": 2931,
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "冰棍儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīnggùnr5",
      "PrimaryDefinition": "ice lolly, popsicle"
    },
    "Keys": {
      "Word": 5646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辩解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànjiě",
      "PrimaryDefinition": "to explain, to justify, to defend (a point of view etc), to provide an explanation, to try to defend oneself"
    },
    "Keys": {
      "Word": 5621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "the woof of a woven item"
    },
    "Keys": {
      "Hanzi": 5328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5328,
      "Radical": "糸",
      "FrequencyRank": 5002,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "传言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuányán",
      "PrimaryDefinition": "rumor, hearsay"
    },
    "Keys": {
      "Word": 4442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "脑子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎozi",
      "PrimaryDefinition": "brain, mind, head"
    },
    "Keys": {
      "Word": 3748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "洚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "a flood"
    },
    "Keys": {
      "Hanzi": 6892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6892,
      "Radical": "水",
      "FrequencyRank": 7337,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "崦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "a mountain in Kansu, where there is a cave into which the sun is said to sink at night"
    },
    "Keys": {
      "Hanzi": 4857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4857,
      "Radical": "山",
      "FrequencyRank": 6168,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "脸色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎnsè",
      "PrimaryDefinition": "brow, countenance, facial_expression, look, facial expression, colouring, complexion"
    },
    "Keys": {
      "Word": 3696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "衰竭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāijié",
      "PrimaryDefinition": "organ failure, exhaustion, prostration (medicine)"
    },
    "Keys": {
      "Word": 9242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挂号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà hào",
      "PrimaryDefinition": "to register (at a hospital etc), to send by registered mail"
    },
    "Keys": {
      "Word": 6855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "势头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìtou",
      "PrimaryDefinition": "power, momentum, tendency, impetus, situation, the look of things"
    },
    "Keys": {
      "Word": 9136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "ornamental cap"
    },
    "Keys": {
      "Hanzi": 4258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4258,
      "Radical": "人",
      "FrequencyRank": 5634,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "实际上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjìshang",
      "PrimaryDefinition": "in fact, in reality, as a matter of fact, in practice"
    },
    "Keys": {
      "Word": 1898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "热带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèdài",
      "PrimaryDefinition": "tropical zone, Torrid Zone, savanna, torrid, the tropics, the torrid zone, tropical_zone, tropical, tropic, tropics"
    },
    "Keys": {
      "Word": 8833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "skin; superficial, shallow"
    },
    "Keys": {
      "Hanzi": 1180
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "GeneralStandard": 1180,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1790,
      "StrokeCount": 8
    }
  },
  "晶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "crystal; clear, bright; radiant"
    },
    "Keys": {
      "Hanzi": 2620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "GeneralStandard": 2620,
      "Radical": "日",
      "HSK": 7,
      "FrequencyRank": 1725,
      "StrokeCount": 12
    }
  },
  "过于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòyú",
      "PrimaryDefinition": "to a fault, unduly, too_much, troppo, over-, excessively, too, too much"
    },
    "Keys": {
      "Word": 3529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "节气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéqi",
      "PrimaryDefinition": "solar term"
    },
    "Keys": {
      "Word": 7521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "美妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měimiào",
      "PrimaryDefinition": "beautiful, wonderful, splendid"
    },
    "Keys": {
      "Word": 8237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "往事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngshì",
      "PrimaryDefinition": "past, the past, past events"
    },
    "Keys": {
      "Word": 9674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "plantain or banana palm; fragrant"
    },
    "Keys": {
      "Hanzi": 676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 676,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 3126,
      "StrokeCount": 7
    }
  },
  "禤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "surname Xuan"
    },
    "Keys": {
      "Hanzi": 7827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7827,
      "Radical": "示",
      "FrequencyRank": 8847,
      "RadicalIndex": "113.11",
      "StrokeCount": 15
    }
  },
  "洗礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐlǐ",
      "PrimaryDefinition": "baptism (lit. or fig.)"
    },
    "Keys": {
      "Word": 9836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "type of elm"
    },
    "Keys": {
      "Hanzi": 5404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5404,
      "Radical": "木",
      "FrequencyRank": 4617,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "狮子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīzi",
      "PrimaryDefinition": "leonine, Panthera leo, king of beasts, lion"
    },
    "Keys": {
      "Word": 9094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "办公室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàngōngshì",
      "PrimaryDefinition": "agency, office, authority, government agency, bureau"
    },
    "Keys": {
      "Word": 506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "牵挂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānguà",
      "PrimaryDefinition": "to worry about, to be concerned about"
    },
    "Keys": {
      "Word": 8654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慌乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāngluàn",
      "PrimaryDefinition": "frenetic, hurried"
    },
    "Keys": {
      "Word": 7186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6622
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6622,
      "Radical": "心",
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "打官司": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ guānsi",
      "PrimaryDefinition": "to file a lawsuit, to sue, to dispute"
    },
    "Keys": {
      "Word": 4470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "它们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāmen",
      "PrimaryDefinition": "they, they (non-human)"
    },
    "Keys": {
      "Word": 1006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "饥饿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīè",
      "PrimaryDefinition": "hunger, starvation, famine"
    },
    "Keys": {
      "Word": 7262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lún",
      "PrimaryDefinition": "be lost; sink, be submerged"
    },
    "Keys": {
      "Hanzi": 863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 863,
      "Radical": "水",
      "FrequencyRank": 2733,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "村": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūn",
      "PrimaryDefinition": "village, hamlet; uncouth, vulgar"
    },
    "Keys": {
      "Hanzi": 682,
      "Word": 1378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "Grade": 3,
      "GeneralStandard": 682,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 712,
      "StrokeCount": 7
    }
  },
  "嶝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèng",
      "PrimaryDefinition": "path leading up a mountain"
    },
    "Keys": {
      "Hanzi": 5942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5942,
      "Radical": "山",
      "FrequencyRank": 5615,
      "RadicalIndex": "46.12",
      "StrokeCount": 15
    }
  },
  "习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "practice; flapping wings"
    },
    "Keys": {
      "Hanzi": 70
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.1",
      "GeneralStandard": 70,
      "Radical": "冫",
      "HSK": 1,
      "FrequencyRank": 676,
      "StrokeCount": 3
    }
  },
  "窠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "nest; hole, indention; den"
    },
    "Keys": {
      "Hanzi": 5594
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5594,
      "Radical": "穴",
      "FrequencyRank": 4118,
      "RadicalIndex": "116.8",
      "StrokeCount": 13
    }
  },
  "高兴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoxìng",
      "PrimaryDefinition": "glad, happy, cheerful, merry, pleased"
    },
    "Keys": {
      "Word": 112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "幸存": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngcún",
      "PrimaryDefinition": "survivor (of a disaster)"
    },
    "Keys": {
      "Word": 10056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "引发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnfā",
      "PrimaryDefinition": "trigger, motivate, set, evoke, initiate, evoke emotion, initiation"
    },
    "Keys": {
      "Word": 10387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒ",
      "PrimaryDefinition": "fire"
    },
    "Keys": {
      "Hanzi": 164,
      "Radical": 86,
      "Word": 2565
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86",
      "Grade": 4,
      "GeneralStandard": 164,
      "Radical": "火",
      "HSK": 1,
      "FrequencyRank": 433,
      "StrokeCount": 4
    }
  },
  "随机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíjī",
      "PrimaryDefinition": "according to the situation, pragmatic, random"
    },
    "Keys": {
      "Word": 9363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "me",
      "PrimaryDefinition": "interrogative particle; repetition of a tune small; tender"
    },
    "Keys": {
      "Hanzi": 48
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.2",
      "GeneralStandard": 48,
      "Radical": "丿",
      "HSK": 1,
      "FrequencyRank": 63,
      "StrokeCount": 3
    }
  },
  "集会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíhuì",
      "PrimaryDefinition": "assemble, rally, gather"
    },
    "Keys": {
      "Word": 7312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "snipe, kingfisher"
    },
    "Keys": {
      "Hanzi": 6308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6308,
      "Radical": "鳥",
      "FrequencyRank": 4881,
      "RadicalIndex": "196.12",
      "StrokeCount": 17
    }
  },
  "一眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyǎn",
      "PrimaryDefinition": "a glance, a quick look, a glimpse"
    },
    "Keys": {
      "Word": 10354
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7593
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7593,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "煓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7586,
      "Radical": "火",
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "塄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léng",
      "PrimaryDefinition": "elevated bank in field"
    },
    "Keys": {
      "Hanzi": 5068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5068,
      "Radical": "土",
      "FrequencyRank": 6374,
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "邃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "profound, detailed; deep"
    },
    "Keys": {
      "Hanzi": 6300
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6300,
      "Radical": "辵",
      "FrequencyRank": 3950,
      "RadicalIndex": "162.13",
      "StrokeCount": 17
    }
  },
  "红火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hónghuo",
      "PrimaryDefinition": "prosperous"
    },
    "Keys": {
      "Word": 7079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "��": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7824,
      "Radical": "",
      "RadicalIndex": "",
      "StrokeCount": 23
    }
  },
  "美观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měiguān",
      "PrimaryDefinition": "pleasing to the eye"
    },
    "Keys": {
      "Word": 8231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "球员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúyuán",
      "PrimaryDefinition": "ballplayer, player, ball team member"
    },
    "Keys": {
      "Word": 4953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "渠道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qúdào",
      "PrimaryDefinition": "irrigation ditch, (fig.) channel, means"
    },
    "Keys": {
      "Word": 4954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "湓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pén",
      "PrimaryDefinition": "an affluent of the River Yangtze near Kiukiang"
    },
    "Keys": {
      "Hanzi": 7430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7430,
      "Radical": "水",
      "FrequencyRank": 6011,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "犹豫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuyù",
      "PrimaryDefinition": "hesitant, fudge, oscillate, stagger, waver, be irresolute, scruple, irresolute, crane, pause, yo-yo, vacillate, hang, weaken, boggle, swither, dacker, balk, demur, swiver, hesitate"
    },
    "Keys": {
      "Word": 4138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "地质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìzhì",
      "PrimaryDefinition": "geology"
    },
    "Keys": {
      "Word": 6255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "累计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěijì",
      "PrimaryDefinition": "to accumulate, cumulative"
    },
    "Keys": {
      "Word": 7961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自相矛盾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìxiāng-máodùn",
      "PrimaryDefinition": "to contradict oneself, self-contradictory, inconsistent"
    },
    "Keys": {
      "Word": 10965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阿拉伯语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Ālābóyǔ",
      "PrimaryDefinition": "Arabic (language)"
    },
    "Keys": {
      "Word": 5422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喜好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐhào",
      "PrimaryDefinition": "to like, fond of, to prefer, to love, one's tastes, preference"
    },
    "Keys": {
      "Word": 9837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惊奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngqí",
      "PrimaryDefinition": "wonder, be surprised/amazed"
    },
    "Keys": {
      "Word": 7602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "fear, dread, awe, reverence"
    },
    "Keys": {
      "Hanzi": 1492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.4",
      "GeneralStandard": 1492,
      "Radical": "田",
      "HSK": 8,
      "FrequencyRank": 2039,
      "StrokeCount": 9
    }
  },
  "垮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎ",
      "PrimaryDefinition": "be defeated, fail, collapse"
    },
    "Keys": {
      "Hanzi": 1371,
      "Word": 7872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.6",
      "Grade": 7,
      "GeneralStandard": 1371,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2674,
      "StrokeCount": 9
    }
  },
  "塑造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùzào",
      "PrimaryDefinition": "model, mold, portray"
    },
    "Keys": {
      "Word": 9355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大公无私": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàgōng-wúsī",
      "PrimaryDefinition": "selfless, impartial"
    },
    "Keys": {
      "Word": 6118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "专辑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānjí",
      "PrimaryDefinition": "album, special issue, record album, special issue of periodical, short films, special collection of pamphlets, short films, etc., special collection of pamphlets, short f, etc."
    },
    "Keys": {
      "Word": 4261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "迎接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngjiē",
      "PrimaryDefinition": "greet, receive, welcome, accolade, meet"
    },
    "Keys": {
      "Word": 2100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "调料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáoliào",
      "PrimaryDefinition": "condiment, seasoning, flavoring"
    },
    "Keys": {
      "Word": 9501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祖母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔmǔ",
      "PrimaryDefinition": "father's mother, paternal grandmother"
    },
    "Keys": {
      "Word": 5412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "怛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "grieved, saddened; worried"
    },
    "Keys": {
      "Hanzi": 4028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4028,
      "Radical": "心",
      "FrequencyRank": 5641,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "洗手间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐshǒujiān",
      "PrimaryDefinition": "toilet, lavatory, washroom"
    },
    "Keys": {
      "Word": 384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "沉淀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéndiàn",
      "PrimaryDefinition": "to settle, to precipitate (solid sediment out of a solution)"
    },
    "Keys": {
      "Word": 5850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "同样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngyàng",
      "PrimaryDefinition": "same, equal, similar"
    },
    "Keys": {
      "Word": 1036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "周密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōumì",
      "PrimaryDefinition": "careful, thorough, meticulous, dense, impenetrable"
    },
    "Keys": {
      "Word": 10840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年迈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánmài",
      "PrimaryDefinition": "old, aged"
    },
    "Keys": {
      "Word": 8413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祝福": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùfú",
      "PrimaryDefinition": "invoke blessing, wish happiness to"
    },
    "Keys": {
      "Word": 3190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "不对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù duì",
      "PrimaryDefinition": "incorrect, wrong, amiss, abnormal, queer"
    },
    "Keys": {
      "Word": 34
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "学者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuézhě",
      "PrimaryDefinition": "academic, literate, pandit, letters, litterateur, learned person, literati, philomath, pundit, academician, faculty member, scholar, bookman, clerk, student, scholarly person, man of letters, man of learning, speleologist, doctor, clerisy, initiate, swami, punditry, savant, learned man, sophist"
    },
    "Keys": {
      "Word": 4081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "全力以赴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánlìyǐfù",
      "PrimaryDefinition": "to do at all costs, to make an all-out effort"
    },
    "Keys": {
      "Word": 8805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "gem, precious stone, jade"
    },
    "Keys": {
      "Hanzi": 5047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5047,
      "Radical": "玉",
      "FrequencyRank": 3288,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "坦然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎnrán",
      "PrimaryDefinition": "calm, undisturbed"
    },
    "Keys": {
      "Word": 9412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "个头儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gètóur5",
      "PrimaryDefinition": "size, height, stature"
    },
    "Keys": {
      "Word": 6736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "准许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔnxǔ",
      "PrimaryDefinition": "to allow, to grant, to permit"
    },
    "Keys": {
      "Word": 10923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāitóu",
      "PrimaryDefinition": "beginning, to start"
    },
    "Keys": {
      "Word": 4784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "邰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "surname; state in modern Shanxi"
    },
    "Keys": {
      "Hanzi": 3820
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3820,
      "Radical": "邑",
      "FrequencyRank": 5824,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "不良": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùliáng",
      "PrimaryDefinition": "unhealthy, harmful, bad"
    },
    "Keys": {
      "Word": 3285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòng",
      "PrimaryDefinition": "use"
    },
    "Keys": {
      "Hanzi": 283,
      "Radical": 101,
      "Word": 440
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "101",
      "Grade": 1,
      "GeneralStandard": 283,
      "Radical": "用",
      "HSK": 1,
      "FrequencyRank": 51,
      "StrokeCount": 5
    }
  },
  "碗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "bowl, small dish"
    },
    "Keys": {
      "Hanzi": 2881,
      "Word": 1053
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.8",
      "Grade": 2,
      "GeneralStandard": 2881,
      "Radical": "石",
      "HSK": 2,
      "FrequencyRank": 1939,
      "StrokeCount": 13
    }
  },
  "货物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòwù",
      "PrimaryDefinition": "portage, freight, hotshot, shipment, transit, payload, lading, load, commodity, traffic, salework, shipload, bale, consignment, ware, store, cargo, goods, loading, article, merchandise, invoice, charge, freightage"
    },
    "Keys": {
      "Word": 7254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "words, speech, expression, phrase"
    },
    "Keys": {
      "Hanzi": 2947,
      "Word": 6037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "135.7",
      "Grade": 7,
      "GeneralStandard": 2947,
      "Radical": "舌",
      "HSK": 5,
      "FrequencyRank": 1469,
      "StrokeCount": 13
    }
  },
  "命令": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìnglìng",
      "PrimaryDefinition": "commend, order"
    },
    "Keys": {
      "Word": 3728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "日前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìqián",
      "PrimaryDefinition": "the other day, a few days ago"
    },
    "Keys": {
      "Word": 8872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "give birth, bring forth, produce"
    },
    "Keys": {
      "Hanzi": 522,
      "Word": 5800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.1",
      "Grade": 7,
      "GeneralStandard": 522,
      "Radical": "立",
      "HSK": 3,
      "FrequencyRank": 159,
      "StrokeCount": 6
    }
  },
  "有的是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒudeshì",
      "PrimaryDefinition": "have plenty of, there's no lack of"
    },
    "Keys": {
      "Word": 2115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "市场经济": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìchǎng jīngjì",
      "PrimaryDefinition": "market economy"
    },
    "Keys": {
      "Word": 9134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǔn",
      "PrimaryDefinition": "guess, suppose, conjecture"
    },
    "Keys": {
      "Hanzi": 3629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3629,
      "Radical": "心",
      "FrequencyRank": 3000,
      "RadicalIndex": "61.3",
      "StrokeCount": 6
    }
  },
  "小人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎorén",
      "PrimaryDefinition": "person of low social status (old), I, me (used to refer humbly to oneself), nasty person, vile character"
    },
    "Keys": {
      "Word": 9973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "獾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huān",
      "PrimaryDefinition": "the badger"
    },
    "Keys": {
      "Hanzi": 6445
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6445,
      "Radical": "犬",
      "FrequencyRank": 4312,
      "RadicalIndex": "94.18",
      "StrokeCount": 20
    }
  },
  "明年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngnián",
      "PrimaryDefinition": "next year"
    },
    "Keys": {
      "Word": 244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "浙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè",
      "PrimaryDefinition": "Zhejiang province; river"
    },
    "Keys": {
      "Hanzi": 2066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2066,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2119,
      "StrokeCount": 10
    }
  },
  "荐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "repeat, reoccur; recommend"
    },
    "Keys": {
      "Hanzi": 1405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "GeneralStandard": 1405,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 2367,
      "StrokeCount": 9
    }
  },
  "歌曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēqǔ",
      "PrimaryDefinition": "melody, aria, song, madrigal, chant, lied, tune"
    },
    "Keys": {
      "Word": 3491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "肚子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔzi",
      "PrimaryDefinition": "kyte, venter, womb, tummy, belly, stomach, paunch, abdomen, breadbasket, wame, tripe"
    },
    "Keys": {
      "Word": 2427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "矼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "stone bridge; stepping stones"
    },
    "Keys": {
      "Hanzi": 6667
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6667,
      "Radical": "石",
      "RadicalIndex": "112.3",
      "StrokeCount": 8
    }
  },
  "妫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "family name"
    },
    "Keys": {
      "Hanzi": 3812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3812,
      "Radical": "女",
      "FrequencyRank": 4474,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "稍微": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāowēi",
      "PrimaryDefinition": "kind_of, sort_of, a little bit, some, slightly, passably, somewhat, moderately, a little, ratherish, a bit"
    },
    "Keys": {
      "Word": 3868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "采取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎiqǔ",
      "PrimaryDefinition": "operate, assume, adopt, accept, strike, take, take up, take over, borrow"
    },
    "Keys": {
      "Word": 1329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "怏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàng",
      "PrimaryDefinition": "discontented, dispirited, sad"
    },
    "Keys": {
      "Hanzi": 4029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4029,
      "Radical": "心",
      "FrequencyRank": 4369,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "那儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàr",
      "PrimaryDefinition": "that place"
    },
    "Keys": {
      "Word": 256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "泉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "spring, fountain; wealth, money"
    },
    "Keys": {
      "Hanzi": 1572,
      "Word": 3828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 5,
      "GeneralStandard": 1572,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1641,
      "StrokeCount": 9
    }
  },
  "迪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "enlighten, advance; progress"
    },
    "Keys": {
      "Hanzi": 1091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.5",
      "GeneralStandard": 1091,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 1440,
      "StrokeCount": 8
    }
  },
  "短暂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnzàn",
      "PrimaryDefinition": "fleet, of short duration, brief, transient"
    },
    "Keys": {
      "Word": 6370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "前途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiántú",
      "PrimaryDefinition": "lookout, future, prospects, prospect, futurity, career"
    },
    "Keys": {
      "Word": 2798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "歼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "annihilate, wipe out, kill off"
    },
    "Keys": {
      "Hanzi": 706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "78.3",
      "GeneralStandard": 706,
      "Radical": "歹",
      "HSK": 9,
      "FrequencyRank": 1815,
      "StrokeCount": 7
    }
  },
  "沿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "follow a course, go along"
    },
    "Keys": {
      "Hanzi": 1250,
      "Word": 5210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 6,
      "GeneralStandard": 1250,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1182,
      "StrokeCount": 8
    }
  },
  "眵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "eyes diseased and dim"
    },
    "Keys": {
      "Hanzi": 4815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4815,
      "Radical": "目",
      "FrequencyRank": 7318,
      "RadicalIndex": "109.6",
      "StrokeCount": 11
    }
  },
  "随手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíshǒu",
      "PrimaryDefinition": "conveniently, without extra trouble, while doing it, in passing"
    },
    "Keys": {
      "Word": 2910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "银行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínháng",
      "PrimaryDefinition": "jug, depository financial institution, bank building, banking company, banking concern, bank"
    },
    "Keys": {
      "Word": 1163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "恩赐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēncì",
      "PrimaryDefinition": "favor, to give charity to sb out of pity"
    },
    "Keys": {
      "Word": 6419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "the temporal bone"
    },
    "Keys": {
      "Hanzi": 6045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6045,
      "Radical": "頁",
      "FrequencyRank": 5037,
      "RadicalIndex": "181.9",
      "StrokeCount": 16
    }
  },
  "亲人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnrén",
      "PrimaryDefinition": "those dear to one, one's parents, spouse, children, etc., one's family members, dear ones, relative, close relatives, folks"
    },
    "Keys": {
      "Word": 1818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "各地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèdì",
      "PrimaryDefinition": "in all parts of (a country), various regions"
    },
    "Keys": {
      "Word": 1491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "嫚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mān",
      "PrimaryDefinition": "scorn, despise; be rude, affront"
    },
    "Keys": {
      "Hanzi": 5834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5834,
      "Radical": "女",
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "涕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "tear; snivel, nasal mucus"
    },
    "Keys": {
      "Hanzi": 2083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2083,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 3006,
      "StrokeCount": 10
    }
  },
  "反弹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎntán",
      "PrimaryDefinition": "rebound"
    },
    "Keys": {
      "Word": 6476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "权益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quányì",
      "PrimaryDefinition": "rights and interests"
    },
    "Keys": {
      "Word": 8800
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "net; snare; pressure come or go"
    },
    "Keys": {
      "Hanzi": 4523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4523,
      "Radical": "网",
      "FrequencyRank": 5741,
      "RadicalIndex": "122.5",
      "StrokeCount": 10
    }
  },
  "茫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "vast, boundless, widespread"
    },
    "Keys": {
      "Hanzi": 1413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "GeneralStandard": 1413,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1951,
      "StrokeCount": 9
    }
  },
  "有着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuzhe",
      "PrimaryDefinition": "to have, to possess"
    },
    "Keys": {
      "Word": 4145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "涌入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngrù",
      "PrimaryDefinition": "Influence"
    },
    "Keys": {
      "Word": 10439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "严禁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánjìn",
      "PrimaryDefinition": "to strictly prohibit"
    },
    "Keys": {
      "Word": 10174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "name of a river"
    },
    "Keys": {
      "Hanzi": 6615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6615,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "华语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Huáyǔ",
      "PrimaryDefinition": "Chinese language"
    },
    "Keys": {
      "Word": 3547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "接听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiētīng",
      "PrimaryDefinition": "to answer the phone"
    },
    "Keys": {
      "Word": 7511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "若": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "if, supposing, assuming; similar"
    },
    "Keys": {
      "Hanzi": 1002,
      "Word": 4982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "Grade": 6,
      "GeneralStandard": 1002,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 651,
      "StrokeCount": 8
    }
  },
  "郏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "county in Henan province; surname"
    },
    "Keys": {
      "Hanzi": 3892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3892,
      "Radical": "邑",
      "FrequencyRank": 4554,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "嚷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎng",
      "PrimaryDefinition": "shout, brawl, make uproar, cry"
    },
    "Keys": {
      "Hanzi": 3480,
      "Word": 8825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.17",
      "Grade": 7,
      "GeneralStandard": 3480,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2256,
      "StrokeCount": 20
    }
  },
  "花园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāyuán",
      "PrimaryDefinition": "flower garden"
    },
    "Keys": {
      "Word": 726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "harmony, peace; peaceful, calm"
    },
    "Keys": {
      "Hanzi": 1131,
      "Word": 143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "Grade": 1,
      "GeneralStandard": 1131,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 19,
      "StrokeCount": 8
    }
  },
  "点击率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnjīlǜ",
      "PrimaryDefinition": "click-through rate (CTR) (Internet)"
    },
    "Keys": {
      "Word": 6266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人民币": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénmínbì",
      "PrimaryDefinition": "RMB, PRC currency"
    },
    "Keys": {
      "Word": 1847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "好学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàoxué",
      "PrimaryDefinition": "eager to study, studious, erudite"
    },
    "Keys": {
      "Word": 4656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "家禽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāqín",
      "PrimaryDefinition": "poultry, domestic fowl"
    },
    "Keys": {
      "Word": 7354
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "少见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎojiàn",
      "PrimaryDefinition": "rare, seldom seen, unique"
    },
    "Keys": {
      "Word": 8995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēng",
      "PrimaryDefinition": "sound of flying bees, airplanes"
    },
    "Keys": {
      "Hanzi": 2928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2928,
      "Radical": "口",
      "FrequencyRank": 2783,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "乐观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèguān",
      "PrimaryDefinition": "be optimistic; be hopeful"
    },
    "Keys": {
      "Word": 1690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "醚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "ether"
    },
    "Keys": {
      "Hanzi": 6071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6071,
      "Radical": "酉",
      "FrequencyRank": 5302,
      "RadicalIndex": "164.1",
      "StrokeCount": 16
    }
  },
  "忍耐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěnnài",
      "PrimaryDefinition": "forbear, stand, go, stomach, abide, put up, hold_out, suppress, sustain, brave out, persevere, remain, support, endure, put up with, exercise restraint, suffer, restrain oneself, brave, bear, brook, resist, exercise patience, tolerate, thole, weather"
    },
    "Keys": {
      "Word": 8859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "事实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìshí",
      "PrimaryDefinition": "mood, circumstance, fact, factual, reality, verity, case, deed, sooth, troth, actuality, truth, matter, matter of fact"
    },
    "Keys": {
      "Word": 1913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "恰好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàhǎo",
      "PrimaryDefinition": "as it turns out, by lucky coincidence, (of number, time, size etc) just right"
    },
    "Keys": {
      "Word": 4922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "莫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "do not, is not, can not; negative"
    },
    "Keys": {
      "Hanzi": 1824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.7",
      "GeneralStandard": 1824,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 955,
      "StrokeCount": 10
    }
  },
  "痴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "foolish, stupid, dumb, silly"
    },
    "Keys": {
      "Hanzi": 2990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.8",
      "GeneralStandard": 2990,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 2225,
      "StrokeCount": 13
    }
  },
  "工序": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngxù",
      "PrimaryDefinition": "working procedure, process"
    },
    "Keys": {
      "Word": 6755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "invite, welcome; meet, intercept"
    },
    "Keys": {
      "Hanzi": 3362,
      "Word": 10231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.13",
      "Grade": 7,
      "GeneralStandard": 3362,
      "Radical": "辵",
      "HSK": 5,
      "FrequencyRank": 1854,
      "StrokeCount": 16
    }
  },
  "嚯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6382
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6382,
      "Radical": "口",
      "FrequencyRank": 5852,
      "RadicalIndex": "30.16",
      "StrokeCount": 19
    }
  },
  "算账": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suàn zhàng",
      "PrimaryDefinition": "Calculate account"
    },
    "Keys": {
      "Word": 9360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姑姑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūgu",
      "PrimaryDefinition": "paternal aunt, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 4621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "沃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "water, irrigate; fertile, rich"
    },
    "Keys": {
      "Hanzi": 862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 862,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 1808,
      "StrokeCount": 7
    }
  },
  "蚓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "earthworm"
    },
    "Keys": {
      "Hanzi": 1903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1903,
      "Radical": "虫",
      "FrequencyRank": 4102,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "瞍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒu",
      "PrimaryDefinition": "blind; no pupil in the eye"
    },
    "Keys": {
      "Hanzi": 7670
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7670,
      "Radical": "目",
      "FrequencyRank": 6705,
      "RadicalIndex": "109.1",
      "StrokeCount": 14
    }
  },
  "对不起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìbuqǐ",
      "PrimaryDefinition": "be unfair to, I'm sorry, let ... down, let sb. down, excuse me"
    },
    "Keys": {
      "Word": 91
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "刍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "mow, cut grass; hay, fodder"
    },
    "Keys": {
      "Hanzi": 3550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3550,
      "Radical": "彐",
      "FrequencyRank": 4465,
      "RadicalIndex": "58.2",
      "StrokeCount": 5
    }
  },
  "财富": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáifù",
      "PrimaryDefinition": "opulence, pile, worth, fortune, moneybag, riches, mammon, substance, wealth, wealthy, gold, lucre, means, shekel, pelf, opulency, money, oof, nabobery"
    },
    "Keys": {
      "Word": 2287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "租赁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūlìn",
      "PrimaryDefinition": "to rent, to lease, to hire"
    },
    "Keys": {
      "Word": 11000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "身边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnbiān",
      "PrimaryDefinition": "nearby, side, at hand"
    },
    "Keys": {
      "Word": 965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "查": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "investigate, examine, seek into"
    },
    "Keys": {
      "Hanzi": 1431,
      "Word": 551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 2,
      "GeneralStandard": 1431,
      "Radical": "木",
      "HSK": 2,
      "FrequencyRank": 459,
      "StrokeCount": 9
    }
  },
  "婫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7269,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "奖杯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngbēi",
      "PrimaryDefinition": "trophy cup"
    },
    "Keys": {
      "Word": 7446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "街": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "street, road, thoroughfare"
    },
    "Keys": {
      "Hanzi": 2702,
      "Word": 780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "144.6",
      "Grade": 2,
      "GeneralStandard": 2702,
      "Radical": "行",
      "HSK": 2,
      "FrequencyRank": 1101,
      "StrokeCount": 12
    }
  },
  "戴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "wear on top; support"
    },
    "Keys": {
      "Hanzi": 3388,
      "Word": 2383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.14",
      "Grade": 4,
      "GeneralStandard": 3388,
      "Radical": "戈",
      "HSK": 4,
      "FrequencyRank": 1228,
      "StrokeCount": 17
    }
  },
  "会见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìjiàn",
      "PrimaryDefinition": "to meet with (sb who is paying a visit), CL:次[ci4]"
    },
    "Keys": {
      "Word": 4681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "正视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngshì",
      "PrimaryDefinition": "face squarely, face, face up to, look squarely at, envisage"
    },
    "Keys": {
      "Word": 10734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "勘探": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāntàn",
      "PrimaryDefinition": "to explore, to survey, to prospect (for oil etc), prospecting"
    },
    "Keys": {
      "Word": 7765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénshì",
      "PrimaryDefinition": "personnel, ways of the world, what is humanly possible, human affairs, occurrences in human life, consciousness of the outside world, personnel matters"
    },
    "Keys": {
      "Word": 8846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "hide, conceal; hidden, secret"
    },
    "Keys": {
      "Hanzi": 2480
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.9",
      "GeneralStandard": 2480,
      "Radical": "阜",
      "HSK": 6,
      "FrequencyRank": 1034,
      "StrokeCount": 11
    }
  },
  "能源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néngyuán",
      "PrimaryDefinition": "energy sources, energy, juice, power sources, energy resources, the sources of energy, sources of energy"
    },
    "Keys": {
      "Word": 8400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "友好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuhǎo",
      "PrimaryDefinition": "Youhao district of Yichun city 伊春市[Yi1 chun1 shi4], Heilongjiang, friendly, amicable, close friend"
    },
    "Keys": {
      "Word": 1172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "抄写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāoxiě",
      "PrimaryDefinition": "to copy, to transcribe"
    },
    "Keys": {
      "Word": 2303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "葜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiā",
      "PrimaryDefinition": "smilax china"
    },
    "Keys": {
      "Hanzi": 7301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7301,
      "Radical": "艸",
      "FrequencyRank": 6009,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "牌照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páizhào",
      "PrimaryDefinition": "(business) licence, vehicle licence, car registration, licence plate"
    },
    "Keys": {
      "Word": 8461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "silent, still, lonely, solitary"
    },
    "Keys": {
      "Hanzi": 3026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.11",
      "GeneralStandard": 3026,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 2601,
      "StrokeCount": 13
    }
  },
  "妊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "conceive, be pregnant"
    },
    "Keys": {
      "Hanzi": 3810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3810,
      "Radical": "女",
      "FrequencyRank": 3523,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "跌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diē",
      "PrimaryDefinition": "stumble, slip, fall down; stamp"
    },
    "Keys": {
      "Hanzi": 2630,
      "Word": 4513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.5",
      "Grade": 6,
      "GeneralStandard": 2630,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 1590,
      "StrokeCount": 12
    }
  },
  "十足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shízú",
      "PrimaryDefinition": "1 0 0 percent;out-and-out;downright"
    },
    "Keys": {
      "Word": 3899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "阔绰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòchuò",
      "PrimaryDefinition": "ostentatious, extravagant, liberal with money"
    },
    "Keys": {
      "Word": 7905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自由自在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìyóu-zìzài",
      "PrimaryDefinition": "free and easy (idiom), carefree, leisurely"
    },
    "Keys": {
      "Word": 10970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "墒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng",
      "PrimaryDefinition": "wet tilth"
    },
    "Keys": {
      "Hanzi": 5640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5640,
      "Radical": "土",
      "FrequencyRank": 4440,
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "耳朵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěrduo",
      "PrimaryDefinition": "auricle, lobe, lobule, ear, auditory, lap, lug, lappet"
    },
    "Keys": {
      "Word": 3440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "离职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lízhí",
      "PrimaryDefinition": "to retire, to leave office, to quit a job"
    },
    "Keys": {
      "Word": 7976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "展览": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnlǎn",
      "PrimaryDefinition": "exhibit, show, display"
    },
    "Keys": {
      "Word": 4186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "信用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnyòng",
      "PrimaryDefinition": "honour, trustfulness, confidence, trustingness, trustworthiness, repute, affiance, credit, reposal, trust, tick, reputation, credence, jawbone, honor"
    },
    "Keys": {
      "Word": 5190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "栏目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lánmù",
      "PrimaryDefinition": "regular column or segment (in a publication or broadcast program), program (TV or radio)"
    },
    "Keys": {
      "Word": 4810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "仍然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réngrán",
      "PrimaryDefinition": "still, yet"
    },
    "Keys": {
      "Word": 1856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "勺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sháo",
      "PrimaryDefinition": "spoon, ladle; unit of volume"
    },
    "Keys": {
      "Hanzi": 49,
      "Word": 5000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "20.1",
      "Grade": 6,
      "GeneralStandard": 49,
      "Radical": "勹",
      "HSK": 6,
      "FrequencyRank": 3275,
      "StrokeCount": 3
    }
  },
  "见外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànwài",
      "PrimaryDefinition": "to regard somebody as an outsider"
    },
    "Keys": {
      "Word": 7420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "看中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn zhòng",
      "PrimaryDefinition": "to have a preference for, to fancy, to choose after consideration, to settle on"
    },
    "Keys": {
      "Word": 7775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "throw ground; know, be familiar"
    },
    "Keys": {
      "Hanzi": 4744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4744,
      "Radical": "手",
      "FrequencyRank": 5206,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "芾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "flower; small; little; lush"
    },
    "Keys": {
      "Hanzi": 3662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3662,
      "Radical": "艸",
      "FrequencyRank": 5082,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "翔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáng",
      "PrimaryDefinition": "soar, glide, hover; detailed"
    },
    "Keys": {
      "Hanzi": 2739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124.6",
      "GeneralStandard": 2739,
      "Radical": "羽",
      "HSK": 8,
      "FrequencyRank": 2184,
      "StrokeCount": 12
    }
  },
  "辛勤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnqín",
      "PrimaryDefinition": "hardworking, industrious"
    },
    "Keys": {
      "Word": 10023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "serve, attend upon; attendant, servant; samurai"
    },
    "Keys": {
      "Hanzi": 1136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1136,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 1871,
      "StrokeCount": 8
    }
  },
  "阐述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnshù",
      "PrimaryDefinition": "to expound (a position), to elaborate (on a topic), to treat (a subject)"
    },
    "Keys": {
      "Word": 5806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "fearful, apprehensive, perturbed"
    },
    "Keys": {
      "Hanzi": 4988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4988,
      "Radical": "心",
      "FrequencyRank": 3561,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "指头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐtou",
      "PrimaryDefinition": "finger, toe, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 5360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "䢼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "name of a state in old times, name of a pavilion"
    },
    "Keys": {
      "Hanzi": 6657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6657,
      "Radical": "邑",
      "RadicalIndex": "163.6",
      "StrokeCount": 9
    }
  },
  "建成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànchéng",
      "PrimaryDefinition": "to establish, to build"
    },
    "Keys": {
      "Word": 1601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "唏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "weep or sob; grieve"
    },
    "Keys": {
      "Hanzi": 4517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4517,
      "Radical": "口",
      "FrequencyRank": 4453,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "辶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuò",
      "PrimaryDefinition": "walk"
    },
    "Keys": {
      "Radical": 162
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "囤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "grain basket, bin for grain"
    },
    "Keys": {
      "Hanzi": 750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 750,
      "Radical": "囗",
      "FrequencyRank": 4543,
      "RadicalIndex": "31.4",
      "StrokeCount": 7
    }
  },
  "牙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "tooth"
    },
    "Keys": {
      "Hanzi": 102,
      "Radical": 92,
      "Word": 3056
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "92",
      "Grade": 4,
      "GeneralStandard": 102,
      "Radical": "牙",
      "HSK": 4,
      "FrequencyRank": 997,
      "StrokeCount": 4
    }
  },
  "革命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gémìng",
      "PrimaryDefinition": "revolt"
    },
    "Keys": {
      "Word": 6729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "drip, dribble, trickle"
    },
    "Keys": {
      "Hanzi": 4969
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4969,
      "Radical": "水",
      "FrequencyRank": 4444,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "由": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "cause, reason; from"
    },
    "Keys": {
      "Hanzi": 245,
      "Word": 2106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102",
      "Grade": 3,
      "GeneralStandard": 245,
      "Radical": "田",
      "HSK": 2,
      "FrequencyRank": 136,
      "StrokeCount": 5
    }
  },
  "鲯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "coryphaena hippurus"
    },
    "Keys": {
      "Hanzi": 7886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7886,
      "Radical": "魚",
      "FrequencyRank": 7528,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "目的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùdì",
      "PrimaryDefinition": "view, pretence, objective, goal, purport, intention, bourn, object, motive, motivation, meaning, aim, end point, hent, target, scope, sense of purpose, errand, purposefulness, sake, function, end, purpose, quaesitum, intent"
    },
    "Keys": {
      "Word": 875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "含有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hányǒu",
      "PrimaryDefinition": "have, involve, infer, imply, tinge, include, number, carry, contain"
    },
    "Keys": {
      "Word": 2531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "谤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "slander, libel"
    },
    "Keys": {
      "Hanzi": 2797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.1",
      "GeneralStandard": 2797,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 3253,
      "StrokeCount": 12
    }
  },
  "航天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángtiān",
      "PrimaryDefinition": "space flight"
    },
    "Keys": {
      "Word": 6995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夜总会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèzǒnghuì",
      "PrimaryDefinition": "nightclub, nightspot"
    },
    "Keys": {
      "Word": 10269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "the declining sun in the west"
    },
    "Keys": {
      "Hanzi": 6825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6825,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "稹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7791,
      "Radical": "禾",
      "FrequencyRank": 4947,
      "RadicalIndex": "115.1",
      "StrokeCount": 15
    }
  },
  "市区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìqū",
      "PrimaryDefinition": "urban area, city proper, row, urban district, downtown, urban_area"
    },
    "Keys": {
      "Word": 2867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "彦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "elegant"
    },
    "Keys": {
      "Hanzi": 4314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4314,
      "Radical": "彡",
      "FrequencyRank": 2559,
      "RadicalIndex": "59.6",
      "StrokeCount": 9
    }
  },
  "絷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "confine, tie up; imprison, shackle"
    },
    "Keys": {
      "Hanzi": 7298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7298,
      "Radical": "糸",
      "FrequencyRank": 6794,
      "RadicalIndex": "120.6",
      "StrokeCount": 12
    }
  },
  "数目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùmù",
      "PrimaryDefinition": "figure, count, amount, number"
    },
    "Keys": {
      "Word": 3930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "stop, halt; stay, detain, keep"
    },
    "Keys": {
      "Hanzi": 2009,
      "Word": 854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102.5",
      "Grade": 2,
      "GeneralStandard": 2009,
      "Radical": "田",
      "HSK": 2,
      "FrequencyRank": 554,
      "StrokeCount": 10
    }
  },
  "燮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "harmonize, blend; adjust"
    },
    "Keys": {
      "Hanzi": 6284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6284,
      "Radical": "火",
      "FrequencyRank": 4433,
      "RadicalIndex": "86.13",
      "StrokeCount": 17
    }
  },
  "队员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìyuán",
      "PrimaryDefinition": "team member"
    },
    "Keys": {
      "Word": 1422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "瑃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7461,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "脊梁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐliang",
      "PrimaryDefinition": "backbone, spine"
    },
    "Keys": {
      "Word": 7314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "其余": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíyú",
      "PrimaryDefinition": "etceteras, det.: others, remainder, the rest"
    },
    "Keys": {
      "Word": 2795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "甜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "sweet, sweetness"
    },
    "Keys": {
      "Hanzi": 2311,
      "Word": 1962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "99.6",
      "Grade": 3,
      "GeneralStandard": 2311,
      "Radical": "甘",
      "HSK": 3,
      "FrequencyRank": 2020,
      "StrokeCount": 11
    }
  },
  "伽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3735,
      "Radical": "人",
      "FrequencyRank": 2532,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "试验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyàn",
      "PrimaryDefinition": "test, experiment"
    },
    "Keys": {
      "Word": 1916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "愉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "pleasant, delightful; please"
    },
    "Keys": {
      "Hanzi": 2777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2777,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1982,
      "StrokeCount": 12
    }
  },
  "咝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "hiss; call to come; syllable"
    },
    "Keys": {
      "Hanzi": 3922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3922,
      "Radical": "口",
      "FrequencyRank": 4367,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "老汉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎohàn",
      "PrimaryDefinition": "old man, I (an old man referring to himself)"
    },
    "Keys": {
      "Word": 7947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "檎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "small red apple"
    },
    "Keys": {
      "Hanzi": 6060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6060,
      "Radical": "木",
      "FrequencyRank": 6192,
      "RadicalIndex": "75.13",
      "StrokeCount": 16
    }
  },
  "肢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "human limbs; animal feet"
    },
    "Keys": {
      "Hanzi": 1182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "GeneralStandard": 1182,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 2335,
      "StrokeCount": 8
    }
  },
  "鲙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "minced fish; hash"
    },
    "Keys": {
      "Hanzi": 7705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7705,
      "Radical": "魚",
      "FrequencyRank": 7151,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "前景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánjǐng",
      "PrimaryDefinition": "outlook, perspective, prospect, expectation, vista, foreground"
    },
    "Keys": {
      "Word": 3802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "工夫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngfu",
      "PrimaryDefinition": "workmanship, skill, art"
    },
    "Keys": {
      "Word": 1497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "删除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānchú",
      "PrimaryDefinition": "delete, leave out"
    },
    "Keys": {
      "Word": 8951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "维护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéihù",
      "PrimaryDefinition": "safeguard, defend, uphold"
    },
    "Keys": {
      "Word": 2972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "昄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎn",
      "PrimaryDefinition": "big"
    },
    "Keys": {
      "Hanzi": 6675
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6675,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "需求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūqiú",
      "PrimaryDefinition": "need, requisition, demand, want, desiderative, requirement, call"
    },
    "Keys": {
      "Word": 2068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "烤肉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎoròu",
      "PrimaryDefinition": "barbecue (lit. roast meat)"
    },
    "Keys": {
      "Word": 3650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "槐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huái",
      "PrimaryDefinition": "locust tree"
    },
    "Keys": {
      "Hanzi": 2868
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "GeneralStandard": 2868,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 3120,
      "StrokeCount": 13
    }
  },
  "日语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Rìyǔ",
      "PrimaryDefinition": "Japanese language"
    },
    "Keys": {
      "Word": 4976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "阻碍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔài",
      "PrimaryDefinition": "hinder, block, impede, bar"
    },
    "Keys": {
      "Word": 4282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "按理说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànlǐshuō",
      "PrimaryDefinition": "it is reasonable to say that..."
    },
    "Keys": {
      "Word": 5442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外公": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàigōng",
      "PrimaryDefinition": "maternal grandfather"
    },
    "Keys": {
      "Word": 9635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "sand and gravel"
    },
    "Keys": {
      "Hanzi": 5417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5417,
      "Radical": "石",
      "FrequencyRank": 4790,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "贵族": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guìzú",
      "PrimaryDefinition": "baron, aristocratism, atheling, nobleman, jarl, grandee, raja, aristocracy, patrician, blue blood, lord, magnifico, noble, aristocrat, baronage, peer, mogul, nobility, patricianhood, peerage, sirdar, amir, nobles"
    },
    "Keys": {
      "Word": 6924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热水器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèshuǐqì",
      "PrimaryDefinition": "water heater"
    },
    "Keys": {
      "Word": 4968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "此事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐshì",
      "PrimaryDefinition": "This matter"
    },
    "Keys": {
      "Word": 4455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "染": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎn",
      "PrimaryDefinition": "dye; be contagious; infect"
    },
    "Keys": {
      "Hanzi": 1675,
      "Word": 3834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 5,
      "GeneralStandard": 1675,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 1141,
      "StrokeCount": 9
    }
  },
  "埔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "plain, arena; port, market"
    },
    "Keys": {
      "Hanzi": 4413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4413,
      "Radical": "土",
      "FrequencyRank": 3124,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "停泊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngbó",
      "PrimaryDefinition": "to anchor, anchorage, mooring (of a ship)"
    },
    "Keys": {
      "Word": 9513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "pendant girdle ornament"
    },
    "Keys": {
      "Hanzi": 7833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7833,
      "Radical": "玉",
      "RadicalIndex": "96.13",
      "StrokeCount": 16
    }
  },
  "柬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "letter, invitation; choose"
    },
    "Keys": {
      "Hanzi": 1442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1442,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 3047,
      "StrokeCount": 9
    }
  },
  "徕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "induce, encourage to come"
    },
    "Keys": {
      "Hanzi": 4584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4584,
      "Radical": "彳",
      "FrequencyRank": 4781,
      "RadicalIndex": "60.7",
      "StrokeCount": 10
    }
  },
  "殴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōu",
      "PrimaryDefinition": "beat, fight with fists, hit"
    },
    "Keys": {
      "Hanzi": 1054
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "79.4",
      "GeneralStandard": 1054,
      "Radical": "殳",
      "HSK": 8,
      "FrequencyRank": 3354,
      "StrokeCount": 8
    }
  },
  "确定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèdìng",
      "PrimaryDefinition": "find, determination, make a point, pin down, narrow down, corroborate, prove, jell, settle, express, ensure, clench, assure, confirm, decide firmly, see, certain, define, clinch, nail_down, ascertain, determinate, guarantee, nail down, insure, fix, nail, peg, sustain, state, stipulate, secure, make_sure, seal, make sure, verify, find out, affirm, determine"
    },
    "Keys": {
      "Word": 1837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "演变": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnbiàn",
      "PrimaryDefinition": "transmute"
    },
    "Keys": {
      "Word": 10197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恶化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èhuà",
      "PrimaryDefinition": "worsen"
    },
    "Keys": {
      "Word": 6414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "overflow, brim over; full"
    },
    "Keys": {
      "Hanzi": 3016,
      "Word": 10378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "Grade": 7,
      "GeneralStandard": 3016,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2454,
      "StrokeCount": 13
    }
  },
  "升高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnggāo",
      "PrimaryDefinition": "up, hike, ascend, come up, arise, move up, climb, go up, lift, wax, loft, soar, heighten, rise, mount, escalate"
    },
    "Keys": {
      "Word": 3889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "record; keep in mind, remember"
    },
    "Keys": {
      "Hanzi": 323,
      "Word": 166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.3",
      "Grade": 1,
      "GeneralStandard": 323,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 306,
      "StrokeCount": 5
    }
  },
  "侠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "chivalrous person; knight-errant"
    },
    "Keys": {
      "Hanzi": 1141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1141,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 1216,
      "StrokeCount": 8
    }
  },
  "无缘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúyuán",
      "PrimaryDefinition": "to have no opportunity, no way (of doing sth), no chance, no connection, not placed (in a competition), (in pop lyrics) no chance of love, no place to be together etc"
    },
    "Keys": {
      "Word": 9795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "spleen, pancreas; disposition"
    },
    "Keys": {
      "Hanzi": 2713,
      "Word": 8511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.8",
      "Grade": 7,
      "GeneralStandard": 2713,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 2223,
      "StrokeCount": 12
    }
  },
  "准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔn",
      "PrimaryDefinition": "approve, allow, permit; in accord"
    },
    "Keys": {
      "Hanzi": 2024,
      "Word": 2206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.8",
      "Grade": 3,
      "GeneralStandard": 2024,
      "Radical": "冫",
      "HSK": 1,
      "FrequencyRank": 379,
      "StrokeCount": 10
    }
  },
  "凑合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "còuhe",
      "PrimaryDefinition": "to bring together, to make do in a bad situation, to just get by, to improvise, passable, not too bad"
    },
    "Keys": {
      "Word": 6067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "rare, unusual, scarce; sparse"
    },
    "Keys": {
      "Hanzi": 2678,
      "Word": 9820
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.7",
      "Grade": 7,
      "GeneralStandard": 2678,
      "Radical": "禾",
      "HSK": 8,
      "FrequencyRank": 1788,
      "StrokeCount": 12
    }
  },
  "威": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "pomp, power; powerful; dominate"
    },
    "Keys": {
      "Hanzi": 1444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "GeneralStandard": 1444,
      "Radical": "女",
      "HSK": 6,
      "FrequencyRank": 622,
      "StrokeCount": 9
    }
  },
  "赏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎng",
      "PrimaryDefinition": "reward, grant, bestow; appreciate"
    },
    "Keys": {
      "Hanzi": 2609,
      "Word": 2842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.8",
      "Grade": 4,
      "GeneralStandard": 2609,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 1450,
      "StrokeCount": 12
    }
  },
  "淜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "roar of dashing waves"
    },
    "Keys": {
      "Hanzi": 7236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7236,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "罍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "large earthenware wine jar"
    },
    "Keys": {
      "Hanzi": 8080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8080,
      "Radical": "缶",
      "FrequencyRank": 8457,
      "RadicalIndex": "121.15",
      "StrokeCount": 21
    }
  },
  "凝聚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níngjù",
      "PrimaryDefinition": "condense (of vapor), coalesce, concentrate"
    },
    "Keys": {
      "Word": 8424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歹徒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎitú",
      "PrimaryDefinition": "evildoer, ruffian, scoundrel"
    },
    "Keys": {
      "Word": 6142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "包括": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāokuò",
      "PrimaryDefinition": "consist_of, consist of, subtend, embrace, comprise, incorporate, embody, involve, count, subsume, take_in, comprehend, incorporation, include, comprize, run, cover, package, consist in"
    },
    "Keys": {
      "Word": 2246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瘳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu",
      "PrimaryDefinition": "to be healed; to reform"
    },
    "Keys": {
      "Hanzi": 6160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6160,
      "Radical": "疒",
      "FrequencyRank": 6993,
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "跪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "kneel"
    },
    "Keys": {
      "Hanzi": 2917,
      "Word": 4636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.6",
      "Grade": 6,
      "GeneralStandard": 2917,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 2128,
      "StrokeCount": 13
    }
  },
  "楷模": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎimó",
      "PrimaryDefinition": "model, example"
    },
    "Keys": {
      "Word": 7762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "植物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíwù",
      "PrimaryDefinition": "vegetal, flora, herb, plant, grower, vegetable, plant life, vegetation, botanical, vegetive, dwarf, vegetative"
    },
    "Keys": {
      "Word": 3173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "饲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "raise animals; feed; nourish"
    },
    "Keys": {
      "Hanzi": 1203
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.5",
      "GeneralStandard": 1203,
      "Radical": "食",
      "HSK": 9,
      "FrequencyRank": 1971,
      "StrokeCount": 8
    }
  },
  "纵容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòngróng",
      "PrimaryDefinition": "to indulge, to connive at"
    },
    "Keys": {
      "Word": 10989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "增": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": "increase, add to, augment"
    },
    "Keys": {
      "Hanzi": 3204,
      "Word": 4179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.12",
      "Grade": 5,
      "GeneralStandard": 3204,
      "Radical": "土",
      "HSK": 3,
      "FrequencyRank": 446,
      "StrokeCount": 15
    }
  },
  "大声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshēng",
      "PrimaryDefinition": "loud, loud voice"
    },
    "Keys": {
      "Word": 593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "廾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒng",
      "PrimaryDefinition": "hands joined"
    },
    "Keys": {
      "Radical": 55
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "告诫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàojiè",
      "PrimaryDefinition": "to warn, to admonish"
    },
    "Keys": {
      "Word": 6714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "后期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuqī",
      "PrimaryDefinition": "late stage, later period"
    },
    "Keys": {
      "Word": 7094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "high"
    },
    "Keys": {
      "Hanzi": 2021,
      "Radical": 189,
      "Word": 113
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "189",
      "Grade": 1,
      "GeneralStandard": 2021,
      "Radical": "高",
      "HSK": 1,
      "FrequencyRank": 134,
      "StrokeCount": 10
    }
  },
  "矿泉水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàngquánshuǐ",
      "PrimaryDefinition": "mineral spring water, CL:瓶[ping2],杯[bei1]"
    },
    "Keys": {
      "Word": 2670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "主流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔliú",
      "PrimaryDefinition": "main stream/current, essential/main aspect/trend"
    },
    "Keys": {
      "Word": 5379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "忝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎn",
      "PrimaryDefinition": "disgraced; ashamed; self-deprecating"
    },
    "Keys": {
      "Hanzi": 3834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3834,
      "Radical": "心",
      "FrequencyRank": 4729,
      "RadicalIndex": "61.4",
      "StrokeCount": 8
    }
  },
  "锘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuò",
      "PrimaryDefinition": "nobelium (No)"
    },
    "Keys": {
      "Hanzi": 7530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7530,
      "Radical": "金",
      "FrequencyRank": 6147,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "跏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "sit cross-legged; squat"
    },
    "Keys": {
      "Hanzi": 5140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5140,
      "Radical": "足",
      "FrequencyRank": 5127,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "议论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìlùn",
      "PrimaryDefinition": "debate, discuss"
    },
    "Keys": {
      "Word": 3100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "专门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānmén",
      "PrimaryDefinition": "special, specialized"
    },
    "Keys": {
      "Word": 2198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "缉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "to sew in close stitches"
    },
    "Keys": {
      "Hanzi": 2816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "GeneralStandard": 2816,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 3165,
      "StrokeCount": 12
    }
  },
  "一天到晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yītiān-dàowǎn",
      "PrimaryDefinition": "all day long"
    },
    "Keys": {
      "Word": 10344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "rake"
    },
    "Keys": {
      "Hanzi": 1769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1769,
      "Radical": "耒",
      "FrequencyRank": 4126,
      "RadicalIndex": "127.4",
      "StrokeCount": 10
    }
  },
  "犍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "a bullock; a fabulous monster"
    },
    "Keys": {
      "Hanzi": 5200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5200,
      "Radical": "牛",
      "FrequencyRank": 5551,
      "RadicalIndex": "93.9",
      "StrokeCount": 12
    }
  },
  "满怀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎnhuái",
      "PrimaryDefinition": "to have one's heart filled with, (to collide) full on, (of farm animals) heavy with young"
    },
    "Keys": {
      "Word": 8198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荣誉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngyù",
      "PrimaryDefinition": "honour, accolade, palm, commendation, credit, lustre, kudos, luster, bay, garland, izzat, laurels, glorification, glory, distinction, honor"
    },
    "Keys": {
      "Word": 8879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顺其自然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnqízìrán",
      "PrimaryDefinition": "to let nature take its course (idiom)"
    },
    "Keys": {
      "Word": 9282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "a chariot, carriage; a carriage pull-bar"
    },
    "Keys": {
      "Hanzi": 4486
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4486,
      "Radical": "車",
      "FrequencyRank": 6201,
      "RadicalIndex": "159.6",
      "StrokeCount": 10
    }
  },
  "头疼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóuténg",
      "PrimaryDefinition": "headache"
    },
    "Keys": {
      "Word": 5100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "照例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàolì",
      "PrimaryDefinition": "as a rule, as usual, usually"
    },
    "Keys": {
      "Word": 10677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "marquis, lord; target in archery"
    },
    "Keys": {
      "Hanzi": 1576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1576,
      "Radical": "人",
      "FrequencyRank": 1756,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "现象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànxiàng",
      "PrimaryDefinition": "phenomenon, phenomenal, appearance"
    },
    "Keys": {
      "Word": 2040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "过期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò qī",
      "PrimaryDefinition": "to be overdue, to exceed the time limit, to expire (as in expiration date)"
    },
    "Keys": {
      "Word": 6950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔ",
      "PrimaryDefinition": "a chemical compound; divination; to consider"
    },
    "Keys": {
      "Hanzi": 3538
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3538,
      "Radical": "口",
      "FrequencyRank": 5213,
      "RadicalIndex": "30.2",
      "StrokeCount": 5
    }
  },
  "本事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnshi",
      "PrimaryDefinition": "literary source material, know-how, proficiency, skill, prowess, this matter, ability, capability"
    },
    "Keys": {
      "Word": 1299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "解围": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiě wéi",
      "PrimaryDefinition": "to lift a siege, to help sb out of trouble or embarrassment"
    },
    "Keys": {
      "Word": 7542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "district in Anhui province"
    },
    "Keys": {
      "Hanzi": 5293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5293,
      "Radical": "水",
      "FrequencyRank": 5777,
      "RadicalIndex": "85.1",
      "StrokeCount": 12
    }
  },
  "违": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "disobey, violate, defy; be apart from"
    },
    "Keys": {
      "Hanzi": 609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "GeneralStandard": 609,
      "Radical": "辵",
      "HSK": 5,
      "FrequencyRank": 1184,
      "StrokeCount": 7
    }
  },
  "抽象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōuxiàng",
      "PrimaryDefinition": "abstract"
    },
    "Keys": {
      "Word": 5932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "divide; small unit of time etc."
    },
    "Keys": {
      "Hanzi": 146,
      "Word": 657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.2",
      "Grade": 2,
      "GeneralStandard": 146,
      "Radical": "刀",
      "HSK": 1,
      "FrequencyRank": 79,
      "StrokeCount": 4
    }
  },
  "箢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5752,
      "Radical": "竹",
      "FrequencyRank": 7450,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "长短": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángduǎn",
      "PrimaryDefinition": "length, duration, accident, right and wrong, good and bad, long and short"
    },
    "Keys": {
      "Word": 4388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "笛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "bamboo flute; whistle"
    },
    "Keys": {
      "Hanzi": 2319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.5",
      "GeneralStandard": 2319,
      "Radical": "竹",
      "HSK": 9,
      "FrequencyRank": 2561,
      "StrokeCount": 11
    }
  },
  "孝敬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàojìng",
      "PrimaryDefinition": "to show filial respect, to give presents (to one's elders or superiors), to support one's aged parents"
    },
    "Keys": {
      "Word": 9977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6902,
      "Radical": "戶",
      "RadicalIndex": "63.5",
      "StrokeCount": 9
    }
  },
  "花费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāfei",
      "PrimaryDefinition": "spend, expend"
    },
    "Keys": {
      "Word": 4673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "潦草": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáocǎo",
      "PrimaryDefinition": "careless, slovenly, illegible (of handwriting)"
    },
    "Keys": {
      "Word": 8043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "檄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "call arms; urgency"
    },
    "Keys": {
      "Hanzi": 6212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6212,
      "Radical": "木",
      "FrequencyRank": 4689,
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "潜在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánzài",
      "PrimaryDefinition": "potential, latent"
    },
    "Keys": {
      "Word": 8680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēn",
      "PrimaryDefinition": "kindness, mercy, charity"
    },
    "Keys": {
      "Hanzi": 1909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1909,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 888,
      "StrokeCount": 10
    }
  },
  "悫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "sincerity, honesty; modest"
    },
    "Keys": {
      "Hanzi": 4740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4740,
      "Radical": "心",
      "FrequencyRank": 6144,
      "RadicalIndex": "61.7",
      "StrokeCount": 11
    }
  },
  "改为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎiwéi",
      "PrimaryDefinition": "change ... into, change to"
    },
    "Keys": {
      "Word": 6657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交叉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāochā",
      "PrimaryDefinition": "cut, alternate, intercross, stagger, decussate, intersect, overlap, overlapping, intersection, cross, crisscross"
    },
    "Keys": {
      "Word": 7451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "词语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíyǔ",
      "PrimaryDefinition": "word (general term including monosyllables through to short phrases), term (e.g. technical term), expression"
    },
    "Keys": {
      "Word": 579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "宏大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngdà",
      "PrimaryDefinition": "great, grand"
    },
    "Keys": {
      "Word": 4666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "骱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7527,
      "Radical": "骨",
      "FrequencyRank": 5665,
      "RadicalIndex": "188.4",
      "StrokeCount": 13
    }
  },
  "特": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tè",
      "PrimaryDefinition": "special, unique, distinguished"
    },
    "Keys": {
      "Hanzi": 1938,
      "Word": 5073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "93.6",
      "Grade": 6,
      "GeneralStandard": 1938,
      "Radical": "牛",
      "HSK": 2,
      "FrequencyRank": 173,
      "StrokeCount": 10
    }
  },
  "攮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎng",
      "PrimaryDefinition": "to fend off; to stab"
    },
    "Keys": {
      "Hanzi": 6495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6495,
      "Radical": "手",
      "FrequencyRank": 6679,
      "RadicalIndex": "64.22",
      "StrokeCount": 25
    }
  },
  "比例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐlì",
      "PrimaryDefinition": "proportional, rate, proportionality, quotient, proportion, proportionment, scale, ratio, balance"
    },
    "Keys": {
      "Word": 1294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "吲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "smile at; sneer at"
    },
    "Keys": {
      "Hanzi": 3711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3711,
      "Radical": "口",
      "FrequencyRank": 6349,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "内存": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèicún",
      "PrimaryDefinition": "internal storage, computer memory, random access memory (RAM)"
    },
    "Keys": {
      "Word": 8391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "崿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "cliffs"
    },
    "Keys": {
      "Hanzi": 7364
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7364,
      "Radical": "山",
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "孬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nāo",
      "PrimaryDefinition": "bad; cowardly"
    },
    "Keys": {
      "Hanzi": 4472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4472,
      "Radical": "子",
      "FrequencyRank": 5588,
      "RadicalIndex": "39.7",
      "StrokeCount": 10
    }
  },
  "骎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīn",
      "PrimaryDefinition": "galloping; speeding"
    },
    "Keys": {
      "Hanzi": 4706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4706,
      "Radical": "馬",
      "FrequencyRank": 6593,
      "RadicalIndex": "187.7",
      "StrokeCount": 10
    }
  },
  "羔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "lamb, kid"
    },
    "Keys": {
      "Hanzi": 2050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2050,
      "Radical": "羊",
      "FrequencyRank": 3604,
      "RadicalIndex": "123.4",
      "StrokeCount": 10
    }
  },
  "治病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìbìng",
      "PrimaryDefinition": "to treat an illness"
    },
    "Keys": {
      "Word": 5364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "留神": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú shén",
      "PrimaryDefinition": "to take care, to be careful"
    },
    "Keys": {
      "Word": 8094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngxìn",
      "PrimaryDefinition": "deem, believe, credit, esteem, accredit, feel, trow, count, have faith in, guess, lippen, buy, be convinced of, hold, think, trust, accept, know, hone, believe_in, favour, believe in, hope"
    },
    "Keys": {
      "Word": 1082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "疏散": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūsàn",
      "PrimaryDefinition": "to scatter, to disperse, to evacuate, scattered, to relax"
    },
    "Keys": {
      "Word": 9218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": "mosquito larva"
    },
    "Keys": {
      "Hanzi": 7524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7524,
      "Radical": "虫",
      "FrequencyRank": 8414,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "肄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "learn, practice, study; toil"
    },
    "Keys": {
      "Hanzi": 5532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5532,
      "Radical": "聿",
      "FrequencyRank": 5563,
      "RadicalIndex": "129.7",
      "StrokeCount": 13
    }
  },
  "滢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "clear, pure water; lucid; glossy"
    },
    "Keys": {
      "Hanzi": 5570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5570,
      "Radical": "水",
      "FrequencyRank": 4634,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "戾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "perverse, recalcitrant, rebellious"
    },
    "Keys": {
      "Hanzi": 4042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4042,
      "Radical": "戶",
      "FrequencyRank": 4232,
      "RadicalIndex": "63.4",
      "StrokeCount": 8
    }
  },
  "没有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méiyou",
      "PrimaryDefinition": "not have, there is not, be without"
    },
    "Keys": {
      "Word": 232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "肋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lē",
      "PrimaryDefinition": "ribs; chest"
    },
    "Keys": {
      "Hanzi": 496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 496,
      "Radical": "肉",
      "FrequencyRank": 3324,
      "RadicalIndex": "130.2",
      "StrokeCount": 6
    }
  },
  "乘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "ride, ascend; avail oneself of; numerary adjunct for vehicles"
    },
    "Keys": {
      "Hanzi": 1941,
      "Word": 3328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "4.9",
      "Grade": 5,
      "GeneralStandard": 1941,
      "Radical": "丿",
      "HSK": 5,
      "FrequencyRank": 1231,
      "StrokeCount": 10
    }
  },
  "樱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "cherry, cherry blossom"
    },
    "Keys": {
      "Hanzi": 3215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3215,
      "Radical": "木",
      "FrequencyRank": 2781,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "久仰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔyǎng",
      "PrimaryDefinition": "honorific: I've long looked forward to meeting you., It's an honor to meet you at last."
    },
    "Keys": {
      "Word": 7660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "压抑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāyì",
      "PrimaryDefinition": "constrain, inhibit"
    },
    "Keys": {
      "Word": 10157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxiàn",
      "PrimaryDefinition": "infinite, limitless"
    },
    "Keys": {
      "Word": 2990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "殍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piǎo",
      "PrimaryDefinition": "to starve to death"
    },
    "Keys": {
      "Hanzi": 4804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4804,
      "Radical": "歹",
      "FrequencyRank": 6053,
      "RadicalIndex": "78.7",
      "StrokeCount": 11
    }
  },
  "坦白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎnbái",
      "PrimaryDefinition": "frank, candid"
    },
    "Keys": {
      "Word": 9410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "崒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zú",
      "PrimaryDefinition": "rocky peaks; lofty and dangerous"
    },
    "Keys": {
      "Hanzi": 7173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7173,
      "Radical": "山",
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "入学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù xué",
      "PrimaryDefinition": "enter a school, enrol, college, enter school, enter, start school, matriculate, enter college"
    },
    "Keys": {
      "Word": 4981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "题目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tímù",
      "PrimaryDefinition": "exam questions, head, lemma, theme, thesis, examination questions, question, text, title, ground, subject, topic, exercise problems, chapter, caption, rubric, heading"
    },
    "Keys": {
      "Word": 1959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "谈论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tánlùn",
      "PrimaryDefinition": "discuss"
    },
    "Keys": {
      "Word": 9405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "mountain"
    },
    "Keys": {
      "Hanzi": 39,
      "Radical": 46,
      "Word": 308
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "46",
      "Grade": 1,
      "GeneralStandard": 39,
      "Radical": "山",
      "HSK": 1,
      "FrequencyRank": 259,
      "StrokeCount": 3
    }
  },
  "蚄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6993,
      "Radical": "虫",
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "鸠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "pigeon; collect, assemble"
    },
    "Keys": {
      "Hanzi": 3748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3748,
      "Radical": "鳥",
      "FrequencyRank": 3442,
      "RadicalIndex": "196.2",
      "StrokeCount": 7
    }
  },
  "暴躁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàozào",
      "PrimaryDefinition": "irascible, irritable, violent"
    },
    "Keys": {
      "Word": 5542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "虱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "louse; bug; parasite"
    },
    "Keys": {
      "Hanzi": 4071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4071,
      "Radical": "虫",
      "FrequencyRank": 4013,
      "RadicalIndex": "142.2",
      "StrokeCount": 8
    }
  },
  "慷慨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāngkǎi",
      "PrimaryDefinition": "vehement, fervent, generous, giving, liberal"
    },
    "Keys": {
      "Word": 7776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无足轻重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúzúqīngzhòng",
      "PrimaryDefinition": "insignificant"
    },
    "Keys": {
      "Word": 9796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "oar, paddle"
    },
    "Keys": {
      "Hanzi": 5396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5396,
      "Radical": "木",
      "FrequencyRank": 4788,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "衔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "bit; hold in mouth, bite; gag"
    },
    "Keys": {
      "Hanzi": 2342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.8",
      "GeneralStandard": 2342,
      "Radical": "彳",
      "HSK": 7,
      "FrequencyRank": 2328,
      "StrokeCount": 11
    }
  },
  "发型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàxíng",
      "PrimaryDefinition": "hairstyle, coiffure, hairdo"
    },
    "Keys": {
      "Word": 6452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "靴子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuēzi",
      "PrimaryDefinition": "boots"
    },
    "Keys": {
      "Word": 10129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "indigestion; buboes, lymphatic inflammation"
    },
    "Keys": {
      "Hanzi": 7043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7043,
      "Radical": "疒",
      "FrequencyRank": 8942,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "幻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "illusion, fantasy, mirage"
    },
    "Keys": {
      "Hanzi": 189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "52.1",
      "GeneralStandard": 189,
      "Radical": "幺",
      "HSK": 6,
      "FrequencyRank": 1417,
      "StrokeCount": 4
    }
  },
  "谌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "sincere, faithful; surname"
    },
    "Keys": {
      "Hanzi": 4994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4994,
      "Radical": "言",
      "FrequencyRank": 4130,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "机灵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīling",
      "PrimaryDefinition": "clever, quick-witted"
    },
    "Keys": {
      "Word": 7267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénxing",
      "PrimaryDefinition": "reason, hypostasis, humanitarianism, normal human feelings, humanness, humanity, humanism, human nature, flesh, humanistic, manhood"
    },
    "Keys": {
      "Word": 8855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎng",
      "PrimaryDefinition": "trickle; flow down; drip"
    },
    "Keys": {
      "Hanzi": 2419,
      "Word": 9428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 7,
      "GeneralStandard": 2419,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2994,
      "StrokeCount": 11
    }
  },
  "匾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn",
      "PrimaryDefinition": "flat, round split-bamboo contain"
    },
    "Keys": {
      "Hanzi": 2248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2248,
      "Radical": "匸",
      "FrequencyRank": 3501,
      "RadicalIndex": "23.9",
      "StrokeCount": 11
    }
  },
  "塑料袋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùliàodài",
      "PrimaryDefinition": "plastic bag"
    },
    "Keys": {
      "Word": 2906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "预防": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùfáng",
      "PrimaryDefinition": "prevent, guard against"
    },
    "Keys": {
      "Word": 2119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蛱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "kind of butterfly"
    },
    "Keys": {
      "Hanzi": 5142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5142,
      "Radical": "虫",
      "FrequencyRank": 5381,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "开张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi zhāng",
      "PrimaryDefinition": "to open a business, first transaction of a business day"
    },
    "Keys": {
      "Word": 7759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "magpie; Pica species (various)"
    },
    "Keys": {
      "Hanzi": 2851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2851,
      "Radical": "鳥",
      "FrequencyRank": 3525,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "翻天覆地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāntiān-fùdì",
      "PrimaryDefinition": "sky and the earth turning upside down (idiom), fig. complete confusion, everything turned on its head"
    },
    "Keys": {
      "Word": 6457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎi",
      "PrimaryDefinition": "a plant name"
    },
    "Keys": {
      "Hanzi": 4137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4137,
      "Radical": "艸",
      "FrequencyRank": 8245,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎ",
      "PrimaryDefinition": "law, rule, regulation, statute; France, French"
    },
    "Keys": {
      "Hanzi": 1241,
      "Word": 2447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 4,
      "GeneralStandard": 1241,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 65,
      "StrokeCount": 8
    }
  },
  "硼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "borax, boron"
    },
    "Keys": {
      "Hanzi": 5419
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5419,
      "Radical": "石",
      "FrequencyRank": 4602,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "诬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "make false accusation; defame"
    },
    "Keys": {
      "Hanzi": 1706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "GeneralStandard": 1706,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 3069,
      "StrokeCount": 9
    }
  },
  "半年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànnián",
      "PrimaryDefinition": "half a year"
    },
    "Keys": {
      "Word": 11
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "睾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "testicle"
    },
    "Keys": {
      "Hanzi": 5763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5763,
      "Radical": "目",
      "FrequencyRank": 3529,
      "RadicalIndex": "109.9",
      "StrokeCount": 14
    }
  },
  "执": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "hold in hand; keep; carry out"
    },
    "Keys": {
      "Hanzi": 363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "GeneralStandard": 363,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 763,
      "StrokeCount": 6
    }
  },
  "公斤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjīn",
      "PrimaryDefinition": "m.[standard]"
    },
    "Keys": {
      "Word": 684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "入场券": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rùchǎngquàn",
      "PrimaryDefinition": "admission ticket"
    },
    "Keys": {
      "Word": 8902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诚恳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngkěn",
      "PrimaryDefinition": "sincere"
    },
    "Keys": {
      "Word": 5876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēng",
      "PrimaryDefinition": "7th heavenly stem"
    },
    "Keys": {
      "Hanzi": 1219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1219,
      "Radical": "广",
      "FrequencyRank": 3072,
      "RadicalIndex": "53.5",
      "StrokeCount": 8
    }
  },
  "翙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7366,
      "Radical": "羽",
      "FrequencyRank": 6475,
      "RadicalIndex": "124.6",
      "StrokeCount": 12
    }
  },
  "公道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngdao",
      "PrimaryDefinition": "justice, fairness, public highway, fair, equitable"
    },
    "Keys": {
      "Word": 6760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "感情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnqíng",
      "PrimaryDefinition": "warmheartedness, reticence, sensation, affection, susceptibility, feeling, feelings, heart, affective, withers, expression, tenderness, emotion, sentiment, fondness, soul, affectionateness, soulfulness, passion"
    },
    "Keys": {
      "Word": 1480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "缱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎn",
      "PrimaryDefinition": "attached to, inseparable; entangl"
    },
    "Keys": {
      "Hanzi": 6192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6192,
      "Radical": "糸",
      "FrequencyRank": 5798,
      "RadicalIndex": "120.13",
      "StrokeCount": 16
    }
  },
  "痴呆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chīdāi",
      "PrimaryDefinition": "imbecility, dementia"
    },
    "Keys": {
      "Word": 5896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎng",
      "PrimaryDefinition": "palm of hand, sole of foot, paw"
    },
    "Keys": {
      "Hanzi": 2610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2610,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 890,
      "StrokeCount": 12
    }
  },
  "青蛙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngwā",
      "PrimaryDefinition": "frog, CL:隻|只[zhi1], (slang) ugly guy"
    },
    "Keys": {
      "Word": 8733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuàn",
      "PrimaryDefinition": "rinse; cook or boil in juice"
    },
    "Keys": {
      "Hanzi": 2433,
      "Word": 9248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 7,
      "GeneralStandard": 2433,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 4527,
      "StrokeCount": 11
    }
  },
  "奈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nài",
      "PrimaryDefinition": "but, how; bear, stand, endure"
    },
    "Keys": {
      "Hanzi": 1048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.5",
      "GeneralStandard": 1048,
      "Radical": "大",
      "HSK": 5,
      "FrequencyRank": 1624,
      "StrokeCount": 8
    }
  },
  "防": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fáng",
      "PrimaryDefinition": "defend; prevent; embankment"
    },
    "Keys": {
      "Hanzi": 576,
      "Word": 1442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.4",
      "Grade": 3,
      "GeneralStandard": 576,
      "Radical": "阜",
      "HSK": 3,
      "FrequencyRank": 548,
      "StrokeCount": 6
    }
  },
  "荦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "brindled ox; brindled in color"
    },
    "Keys": {
      "Hanzi": 4131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4131,
      "Radical": "艸",
      "FrequencyRank": 6111,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "症": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng",
      "PrimaryDefinition": "disease, illness, ailment"
    },
    "Keys": {
      "Hanzi": 2026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.5",
      "GeneralStandard": 2026,
      "Radical": "疒",
      "HSK": 6,
      "FrequencyRank": 1258,
      "StrokeCount": 10
    }
  },
  "萏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "lotus"
    },
    "Keys": {
      "Hanzi": 4760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4760,
      "Radical": "艸",
      "FrequencyRank": 6533,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "night, evening; late"
    },
    "Keys": {
      "Hanzi": 2270,
      "Word": 368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.7",
      "Grade": 1,
      "GeneralStandard": 2270,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 641,
      "StrokeCount": 11
    }
  },
  "辌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": "a hearse; a carriage"
    },
    "Keys": {
      "Hanzi": 7344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7344,
      "Radical": "車",
      "FrequencyRank": 7765,
      "RadicalIndex": "159.8",
      "StrokeCount": 12
    }
  },
  "哪里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎli",
      "PrimaryDefinition": "where"
    },
    "Keys": {
      "Word": 247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "遭受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāoshòu",
      "PrimaryDefinition": "suffer"
    },
    "Keys": {
      "Word": 5307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "熘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liū",
      "PrimaryDefinition": "to steam"
    },
    "Keys": {
      "Hanzi": 5798
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5798,
      "Radical": "火",
      "FrequencyRank": 5417,
      "RadicalIndex": "86.1",
      "StrokeCount": 14
    }
  },
  "膳食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànshí",
      "PrimaryDefinition": "meal"
    },
    "Keys": {
      "Word": 8959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhóu",
      "PrimaryDefinition": "axle, axletree; pivot; axis"
    },
    "Keys": {
      "Hanzi": 1462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.5",
      "GeneralStandard": 1462,
      "Radical": "車",
      "HSK": 9,
      "FrequencyRank": 2438,
      "StrokeCount": 9
    }
  },
  "把": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎ",
      "PrimaryDefinition": "hold, take; guard; regard as"
    },
    "Keys": {
      "Hanzi": 655,
      "Word": 1267
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 3,
      "GeneralStandard": 655,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 110,
      "StrokeCount": 7
    }
  },
  "蜷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5711,
      "Radical": "虫",
      "FrequencyRank": 3673,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "磜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7852,
      "Radical": "石",
      "FrequencyRank": 7711,
      "RadicalIndex": "112.1",
      "StrokeCount": 16
    }
  },
  "鹃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": "cuckoo"
    },
    "Keys": {
      "Hanzi": 2640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2640,
      "Radical": "鳥",
      "FrequencyRank": 3051,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "蚣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "centipede"
    },
    "Keys": {
      "Hanzi": 1900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1900,
      "Radical": "虫",
      "FrequencyRank": 4109,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "炫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "shine, glitter; show off, flaunt"
    },
    "Keys": {
      "Hanzi": 1659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "GeneralStandard": 1659,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 3292,
      "StrokeCount": 9
    }
  },
  "南方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánfāng",
      "PrimaryDefinition": "S, due south, the southern part of the country, South, south, southern part of country, the South, southern"
    },
    "Keys": {
      "Word": 884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "㑇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "(a simplified form) clever; ingenious; cute; pretty"
    },
    "Keys": {
      "Hanzi": 6602
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6602,
      "Radical": "人",
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "亟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "urgently, immediately, extremely"
    },
    "Keys": {
      "Hanzi": 4062
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4062,
      "Radical": "二",
      "FrequencyRank": 3619,
      "RadicalIndex": "7.6",
      "StrokeCount": 8
    }
  },
  "难怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánguài",
      "PrimaryDefinition": "no wonder"
    },
    "Keys": {
      "Word": 8376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "help, assist, repay kindness"
    },
    "Keys": {
      "Hanzi": 3953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3953,
      "Radical": "人",
      "FrequencyRank": 6320,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "外部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàibù",
      "PrimaryDefinition": "outer, surface, external, outwall, outside, exterior"
    },
    "Keys": {
      "Word": 5117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "悬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "hang, suspend, hoist; be hung"
    },
    "Keys": {
      "Hanzi": 2264,
      "Word": 5198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "Grade": 6,
      "GeneralStandard": 2264,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1879,
      "StrokeCount": 11
    }
  },
  "殄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎn",
      "PrimaryDefinition": "to end; to exterminate"
    },
    "Keys": {
      "Hanzi": 4176
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4176,
      "Radical": "歹",
      "FrequencyRank": 6018,
      "RadicalIndex": "78.5",
      "StrokeCount": 9
    }
  },
  "家喻户晓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāyù-hùxiǎo",
      "PrimaryDefinition": "understood by everyone (idiom), well known, a household name"
    },
    "Keys": {
      "Word": 7355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "evening, dusk, sunset; ending"
    },
    "Keys": {
      "Hanzi": 3067
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.11",
      "GeneralStandard": 3067,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2768,
      "StrokeCount": 14
    }
  },
  "忒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tè",
      "PrimaryDefinition": "excessive; too; very-usually of objectionable things; to err; to mistake; changeable"
    },
    "Keys": {
      "Hanzi": 3689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3689,
      "Radical": "心",
      "FrequencyRank": 3144,
      "RadicalIndex": "61.3",
      "StrokeCount": 7
    }
  },
  "物品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùpǐn",
      "PrimaryDefinition": "whatchamacallit, sundry, stuff, commodity, affair, physical object, goods, article, object, content, whatsis, res, sundries"
    },
    "Keys": {
      "Word": 5148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "畯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "rustic; crude"
    },
    "Keys": {
      "Hanzi": 7356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7356,
      "Radical": "田",
      "RadicalIndex": "102.7",
      "StrokeCount": 12
    }
  },
  "讻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōng",
      "PrimaryDefinition": "noisily; uproarious; troublesome"
    },
    "Keys": {
      "Hanzi": 6550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6550,
      "Radical": "言",
      "RadicalIndex": "149.4",
      "StrokeCount": 6
    }
  },
  "来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "come, coming; return, returning"
    },
    "Keys": {
      "Hanzi": 707,
      "Word": 202
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "Grade": 1,
      "GeneralStandard": 707,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 15,
      "StrokeCount": 7
    }
  },
  "时不时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíbùshí",
      "PrimaryDefinition": "from time to time"
    },
    "Keys": {
      "Word": 9104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "glance at, look at, see"
    },
    "Keys": {
      "Hanzi": 3402,
      "Word": 3813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.12",
      "Grade": 5,
      "GeneralStandard": 3402,
      "Radical": "目",
      "HSK": 5,
      "FrequencyRank": 1551,
      "StrokeCount": 17
    }
  },
  "儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ér",
      "PrimaryDefinition": "legs"
    },
    "Keys": {
      "Radical": 10,
      "Hanzi": 12
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "10",
      "GeneralStandard": 12,
      "Radical": "儿",
      "HSK": 1,
      "FrequencyRank": 192,
      "StrokeCount": 2
    }
  },
  "譬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "metaphor, simile, example"
    },
    "Keys": {
      "Hanzi": 3487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.13",
      "GeneralStandard": 3487,
      "Radical": "言",
      "HSK": 9,
      "FrequencyRank": 2441,
      "StrokeCount": 20
    }
  },
  "哭泣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūqì",
      "PrimaryDefinition": "to weep"
    },
    "Keys": {
      "Word": 7856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暂时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànshí",
      "PrimaryDefinition": "temporarily"
    },
    "Keys": {
      "Word": 4173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鳌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "huge sea turtle"
    },
    "Keys": {
      "Hanzi": 6311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6311,
      "Radical": "魚",
      "FrequencyRank": 3450,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "无关紧要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúguān-jǐnyào",
      "PrimaryDefinition": "indifferent, insignificant"
    },
    "Keys": {
      "Word": 9766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "透明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòumíng",
      "PrimaryDefinition": "diaphanous, see-through, crystalline, transparent"
    },
    "Keys": {
      "Word": 2955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "弄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nòng",
      "PrimaryDefinition": "do, play or fiddle with; alley"
    },
    "Keys": {
      "Hanzi": 600,
      "Word": 894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "55.4",
      "Grade": 2,
      "GeneralStandard": 600,
      "Radical": "廾",
      "HSK": 2,
      "FrequencyRank": 1053,
      "StrokeCount": 7
    }
  },
  "同情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngqíng",
      "PrimaryDefinition": "sympathize with"
    },
    "Keys": {
      "Word": 2941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "居住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūzhù",
      "PrimaryDefinition": "inhabit, occupy, shack_up, locate, domicile, shack, abide, subsist, settle, live, howff, reside, indwell, populate, lodge in, quarter, dwell, people, belong, bide, hang_out, house, residence"
    },
    "Keys": {
      "Word": 2646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "车厢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēxiāng",
      "PrimaryDefinition": "carriage, CL:節|节[jie2]"
    },
    "Keys": {
      "Word": 5842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojiē",
      "PrimaryDefinition": "(of two things) to come into contact, to meet, to hand over to, to take over from, to associate with, to have friendly relations with, to have sexual intercourse"
    },
    "Keys": {
      "Word": 7455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "pucker up lips in smile; press"
    },
    "Keys": {
      "Hanzi": 3851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3851,
      "Radical": "手",
      "FrequencyRank": 3729,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "在场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàichǎng",
      "PrimaryDefinition": "present, be present, be on the spot, stand_by, spot, be on the scene"
    },
    "Keys": {
      "Word": 4171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "汽车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìchē",
      "PrimaryDefinition": "automotive vehicle, machine, wheels, motorcar, motor vehicle, car, automotive, auto, wheel, autocar, motor, automobile, hoot, mobile, bus"
    },
    "Keys": {
      "Word": 287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "使命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐmìng",
      "PrimaryDefinition": "vocation, calling, military mission, mission, embassy, errand"
    },
    "Keys": {
      "Word": 9125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "快活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàihuo",
      "PrimaryDefinition": "merry, cheerful, liven, jaunty, jolly, brighten, happy"
    },
    "Keys": {
      "Word": 3670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无话可说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúhuàkěshuō",
      "PrimaryDefinition": "to have nothing to say (idiom)"
    },
    "Keys": {
      "Word": 9768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "mountain range; pointed mountain"
    },
    "Keys": {
      "Hanzi": 1615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1615,
      "Radical": "山",
      "FrequencyRank": 3789,
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "儴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8040
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8040,
      "Radical": "人",
      "RadicalIndex": "9.17",
      "StrokeCount": 19
    }
  },
  "昡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "long day; extended; relaxed"
    },
    "Keys": {
      "Hanzi": 6830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6830,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "没完没了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méiwán-méiliǎo",
      "PrimaryDefinition": "without end, incessantly, on and on"
    },
    "Keys": {
      "Word": 8220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搞笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎoxiào",
      "PrimaryDefinition": "funny, hilarious"
    },
    "Keys": {
      "Word": 6711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "bother, vex, trouble; troublesome"
    },
    "Keys": {
      "Hanzi": 2059,
      "Word": 2452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "Grade": 4,
      "GeneralStandard": 2059,
      "Radical": "火",
      "HSK": 3,
      "FrequencyRank": 1253,
      "StrokeCount": 10
    }
  },
  "改编": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎibiān",
      "PrimaryDefinition": "adapt, rearrange, revise"
    },
    "Keys": {
      "Word": 6651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "幼儿园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòuéryuán",
      "PrimaryDefinition": "kindergarten, nursery school"
    },
    "Keys": {
      "Word": 3120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "相提并论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngtí-bìnglùn",
      "PrimaryDefinition": "to discuss two disparate things together (idiom), to mention on equal terms, to place on a par with, (often with negatives: impossible to mention X in the same breath as Y)"
    },
    "Keys": {
      "Word": 9927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "rising sun; sunshine"
    },
    "Keys": {
      "Hanzi": 3704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3704,
      "Radical": "日",
      "RadicalIndex": "72.3",
      "StrokeCount": 7
    }
  },
  "蕹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèng",
      "PrimaryDefinition": "Ipomoea aquatica used as a vegetable"
    },
    "Keys": {
      "Hanzi": 7844
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7844,
      "Radical": "艸",
      "FrequencyRank": 6186,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "举": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "raise, lift up; recommend"
    },
    "Keys": {
      "Hanzi": 1693,
      "Word": 800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "3.8",
      "Grade": 2,
      "GeneralStandard": 1693,
      "Radical": "丶",
      "HSK": 2,
      "FrequencyRank": 586,
      "StrokeCount": 9
    }
  },
  "惴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuì",
      "PrimaryDefinition": "afraid, apprehensive, nervous"
    },
    "Keys": {
      "Hanzi": 5297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5297,
      "Radical": "心",
      "FrequencyRank": 3725,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "昼夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòuyè",
      "PrimaryDefinition": "day and night, period of 24 hours, continuously, without stop"
    },
    "Keys": {
      "Word": 10841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "lovely, beautiful; docile, obedie"
    },
    "Keys": {
      "Hanzi": 4304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4304,
      "Radical": "女",
      "FrequencyRank": 6289,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "本能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnnéng",
      "PrimaryDefinition": "whim, appetence, vagary, instinct, instinctive, native ability, inherent aptitude, impulse, caprice, faculty"
    },
    "Keys": {
      "Word": 5564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē bān",
      "PrimaryDefinition": "to take over (from those working the previous shift), to take over (in a leadership role etc), to succeed sb"
    },
    "Keys": {
      "Word": 7502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "scar, mole"
    },
    "Keys": {
      "Hanzi": 5984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5984,
      "Radical": "疒",
      "FrequencyRank": 3714,
      "RadicalIndex": "104.1",
      "StrokeCount": 15
    }
  },
  "力争": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìzhēng",
      "PrimaryDefinition": "to work hard for, to do all one can, to contend strongly"
    },
    "Keys": {
      "Word": 7994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泥土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nítǔ",
      "PrimaryDefinition": "dirt, muck, earth, marl, soil, soli, clay, clart"
    },
    "Keys": {
      "Word": 8404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发扬光大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāyáng-guāngdà",
      "PrimaryDefinition": "to develop and promote, to carry forward, to bring to great height of development"
    },
    "Keys": {
      "Word": 6446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "历史": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìshǐ",
      "PrimaryDefinition": "past records, historical, history"
    },
    "Keys": {
      "Word": 2694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恰恰相反": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàqiàxiāngfǎn",
      "PrimaryDefinition": "Contrary"
    },
    "Keys": {
      "Word": 8641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "noticeable, large; clear, distinct"
    },
    "Keys": {
      "Hanzi": 4567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4567,
      "Radical": "人",
      "FrequencyRank": 5720,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "艰苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānkǔ",
      "PrimaryDefinition": "arduous"
    },
    "Keys": {
      "Word": 3593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "碶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "a flood-gate, a water-gate"
    },
    "Keys": {
      "Hanzi": 7659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7659,
      "Radical": "石",
      "FrequencyRank": 6293,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "崭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎn",
      "PrimaryDefinition": "high, steep, precipitous; new"
    },
    "Keys": {
      "Hanzi": 2294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.8",
      "GeneralStandard": 2294,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 3035,
      "StrokeCount": 11
    }
  },
  "溶解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngjiě",
      "PrimaryDefinition": "to dissolve"
    },
    "Keys": {
      "Word": 8886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià shān",
      "PrimaryDefinition": "descend mountain, descend hill"
    },
    "Keys": {
      "Word": 9865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "振": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "raise, excite, arouse action"
    },
    "Keys": {
      "Hanzi": 1786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "GeneralStandard": 1786,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1140,
      "StrokeCount": 10
    }
  },
  "叶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "to harmonize, to rhyme; to unite; (borrowed for) leaf"
    },
    "Keys": {
      "Hanzi": 238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 238,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 920,
      "StrokeCount": 5
    }
  },
  "玫瑰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méigui",
      "PrimaryDefinition": "rosebush, rose"
    },
    "Keys": {
      "Word": 8221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "匠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "craftsman, artisan; workman"
    },
    "Keys": {
      "Hanzi": 394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "22.4",
      "GeneralStandard": 394,
      "Radical": "匚",
      "HSK": 8,
      "FrequencyRank": 2110,
      "StrokeCount": 6
    }
  },
  "原来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánlái",
      "PrimaryDefinition": "originally, formerly"
    },
    "Keys": {
      "Word": 1179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "舰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "warship"
    },
    "Keys": {
      "Hanzi": 1978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137.4",
      "GeneralStandard": 1978,
      "Radical": "舟",
      "HSK": 6,
      "FrequencyRank": 851,
      "StrokeCount": 10
    }
  },
  "开发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāifa",
      "PrimaryDefinition": "develop, open up, exploit"
    },
    "Keys": {
      "Word": 1663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "遇上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùshàng",
      "PrimaryDefinition": "Encounter"
    },
    "Keys": {
      "Word": 10528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuàn",
      "PrimaryDefinition": "oven, cooking stove; cook"
    },
    "Keys": {
      "Hanzi": 6499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6499,
      "Radical": "火",
      "FrequencyRank": 6143,
      "RadicalIndex": "86.25",
      "StrokeCount": 30
    }
  },
  "项目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngmù",
      "PrimaryDefinition": "event, head, item, particular, article, point, specific, entry, listing, clause, project"
    },
    "Keys": {
      "Word": 3023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "孰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shú",
      "PrimaryDefinition": "who? which? what? which one?"
    },
    "Keys": {
      "Hanzi": 4935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4935,
      "Radical": "子",
      "FrequencyRank": 3568,
      "RadicalIndex": "39.8",
      "StrokeCount": 11
    }
  },
  "帝国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìguó",
      "PrimaryDefinition": "Reich, imperial, empery, empire"
    },
    "Keys": {
      "Word": 6257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "图书馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túshūguǎn",
      "PrimaryDefinition": "library"
    },
    "Keys": {
      "Word": 362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "浒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǔ",
      "PrimaryDefinition": "riverbank, shore"
    },
    "Keys": {
      "Hanzi": 4345
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4345,
      "Radical": "水",
      "FrequencyRank": 3794,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "了却": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎoquè",
      "PrimaryDefinition": "to resolve, to settle"
    },
    "Keys": {
      "Word": 8045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岩石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánshí",
      "PrimaryDefinition": "muck, chimney, rock, petrous, stone"
    },
    "Keys": {
      "Word": 10180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘴巴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǐba",
      "PrimaryDefinition": "jaw, cheeks, kisser, mouth"
    },
    "Keys": {
      "Word": 3216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "电子邮件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànzǐyóujiàn",
      "PrimaryDefinition": "email, CL:封[feng1],份[fen4]"
    },
    "Keys": {
      "Word": 1408
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "阴性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnxìng",
      "PrimaryDefinition": "negative, feminine"
    },
    "Keys": {
      "Word": 10384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "mandarin fish"
    },
    "Keys": {
      "Hanzi": 6442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6442,
      "Radical": "魚",
      "FrequencyRank": 6451,
      "RadicalIndex": "195.12",
      "StrokeCount": 20
    }
  },
  "悱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "to be desirous of speaking"
    },
    "Keys": {
      "Hanzi": 4985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4985,
      "Radical": "心",
      "FrequencyRank": 5901,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "姻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "relatives by marriage"
    },
    "Keys": {
      "Hanzi": 1738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "GeneralStandard": 1738,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2007,
      "StrokeCount": 9
    }
  },
  "庋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "a cupboard or pantry to store"
    },
    "Keys": {
      "Hanzi": 3757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3757,
      "Radical": "广",
      "FrequencyRank": 4422,
      "RadicalIndex": "53.4",
      "StrokeCount": 7
    }
  },
  "嫜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7744,
      "Radical": "女",
      "FrequencyRank": 6290,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "鞨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "tribe"
    },
    "Keys": {
      "Hanzi": 6316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6316,
      "Radical": "革",
      "FrequencyRank": 7444,
      "RadicalIndex": "177.9",
      "StrokeCount": 18
    }
  },
  "画面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàmiàn",
      "PrimaryDefinition": "frame, tableau, picture plane, picture, general appearance of a picture, picture_plane, appearance, TV screen, video"
    },
    "Keys": {
      "Word": 3551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "审查": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnchá",
      "PrimaryDefinition": "examine, investigate"
    },
    "Keys": {
      "Word": 5007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "卮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "measuring cup; wine container"
    },
    "Keys": {
      "Hanzi": 3547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3547,
      "Radical": "卩",
      "FrequencyRank": 5316,
      "RadicalIndex": "26.3",
      "StrokeCount": 5
    }
  },
  "捐赠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juānzèng",
      "PrimaryDefinition": "contribute (as gift), donate, present"
    },
    "Keys": {
      "Word": 4768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "景色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngsè",
      "PrimaryDefinition": "view, scenic, outlook, perspective, scene, scenery, prospect, landscape"
    },
    "Keys": {
      "Word": 1644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "翟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "surname; a kind of pheasant; plumes"
    },
    "Keys": {
      "Hanzi": 5838
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5838,
      "Radical": "羽",
      "FrequencyRank": 3919,
      "RadicalIndex": "124.8",
      "StrokeCount": 14
    }
  },
  "徂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cú",
      "PrimaryDefinition": "go, advance; to; die"
    },
    "Keys": {
      "Hanzi": 3970
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3970,
      "Radical": "彳",
      "FrequencyRank": 6500,
      "RadicalIndex": "60.5",
      "StrokeCount": 8
    }
  },
  "心酸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn suān",
      "PrimaryDefinition": "to feel sad"
    },
    "Keys": {
      "Word": 10014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "pellet, bullet, shot, shell"
    },
    "Keys": {
      "Hanzi": 2473,
      "Word": 3960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.8",
      "Grade": 5,
      "GeneralStandard": 2473,
      "Radical": "弓",
      "HSK": 5,
      "FrequencyRank": 632,
      "StrokeCount": 11
    }
  },
  "导师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoshī",
      "PrimaryDefinition": "don, guide of a great cause, hierophant, teacher, tutor, imam, supervisor, leader in a cause, preceptor, professor, preceptorship, adviser, teacher in charge of class, mentor, counselor"
    },
    "Keys": {
      "Word": 6190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陶醉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táozuì",
      "PrimaryDefinition": "to be infatuated with, to be drunk with, to be enchanted with, to revel in"
    },
    "Keys": {
      "Word": 9437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "等级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děngjí",
      "PrimaryDefinition": "graduated table, rate, degree, graduation, gradation, ordering, magnitude, caste, grade, social status, ranking, order, class, gree, scale of measurement, step, stream, estate, station, rating, value, echelon, order and degree, ordered series, scale, rubric, notch, division, rank, order of magnitude"
    },
    "Keys": {
      "Word": 3411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "井": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "well, mine shaft, pit"
    },
    "Keys": {
      "Hanzi": 77,
      "Word": 4750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7.2",
      "Grade": 6,
      "GeneralStandard": 77,
      "Radical": "二",
      "HSK": 6,
      "FrequencyRank": 1431,
      "StrokeCount": 4
    }
  },
  "圌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuán",
      "PrimaryDefinition": "fence"
    },
    "Keys": {
      "Hanzi": 7368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7368,
      "Radical": "囗",
      "RadicalIndex": "31.9",
      "StrokeCount": 12
    }
  },
  "工地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngdì",
      "PrimaryDefinition": "construction site"
    },
    "Keys": {
      "Word": 6751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnghuó",
      "PrimaryDefinition": "life, existence, livelihood"
    },
    "Keys": {
      "Word": 973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "一应俱全": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyīng-jùquán",
      "PrimaryDefinition": "with everything needed available"
    },
    "Keys": {
      "Word": 10352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "没劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi jìn",
      "PrimaryDefinition": "to have no strength, to feel weak, exhausted, feeling listless, boring, of no interest"
    },
    "Keys": {
      "Word": 8218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "转学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn xué",
      "PrimaryDefinition": "to change schools, to transfer to another college"
    },
    "Keys": {
      "Word": 10893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "见过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànguò",
      "PrimaryDefinition": "See"
    },
    "Keys": {
      "Word": 758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "渴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kě",
      "PrimaryDefinition": "thirsty, parched; yearn, pine"
    },
    "Keys": {
      "Hanzi": 2758,
      "Word": 195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 1,
      "GeneralStandard": 2758,
      "Radical": "水",
      "HSK": 1,
      "FrequencyRank": 1972,
      "StrokeCount": 12
    }
  },
  "借": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "borrow; lend; make pretext of"
    },
    "Keys": {
      "Hanzi": 1955,
      "Word": 787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 2,
      "GeneralStandard": 1955,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 984,
      "StrokeCount": 10
    }
  },
  "得益于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "déyìyú",
      "PrimaryDefinition": "Benefited from"
    },
    "Keys": {
      "Word": 6214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùcuò",
      "PrimaryDefinition": "not bad, pretty good;correct, right"
    },
    "Keys": {
      "Word": 525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "抱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "embrace, hold in arms, enfold"
    },
    "Keys": {
      "Hanzi": 978,
      "Word": 2254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 4,
      "GeneralStandard": 978,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1122,
      "StrokeCount": 8
    }
  },
  "仿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎng",
      "PrimaryDefinition": "imitate, copy; as if"
    },
    "Keys": {
      "Hanzi": 474,
      "Word": 6498
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 7,
      "GeneralStandard": 474,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1259,
      "StrokeCount": 6
    }
  },
  "旅游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚyóu",
      "PrimaryDefinition": "trip, journey, tourism, travel, tour, to travel"
    },
    "Keys": {
      "Word": 865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鞧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "a leather strap"
    },
    "Keys": {
      "Hanzi": 6318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6318,
      "Radical": "革",
      "FrequencyRank": 8248,
      "RadicalIndex": "177.9",
      "StrokeCount": 18
    }
  },
  "举世闻名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔshì-wénmíng",
      "PrimaryDefinition": "world-famous (idiom)"
    },
    "Keys": {
      "Word": 7690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "to fish; seize; pursue; surname"
    },
    "Keys": {
      "Hanzi": 2425
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2425,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 1770,
      "StrokeCount": 11
    }
  },
  "缩短": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō duǎn",
      "PrimaryDefinition": "shorten, curtail, cut down"
    },
    "Keys": {
      "Word": 2913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "必须": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìxū",
      "PrimaryDefinition": "must"
    },
    "Keys": {
      "Word": 522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "永久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngjiǔ",
      "PrimaryDefinition": "everlasting, perpetual, lasting, forever, permanent"
    },
    "Keys": {
      "Word": 10434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "to respect, reverence; respectfully; 3rd terrestrial branch"
    },
    "Keys": {
      "Hanzi": 2452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2452,
      "Radical": "宀",
      "FrequencyRank": 3422,
      "RadicalIndex": "40.8",
      "StrokeCount": 11
    }
  },
  "打岔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ chà",
      "PrimaryDefinition": "interruption, to interrupt (esp. talk), to change the subject"
    },
    "Keys": {
      "Word": 6098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "路子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùzi",
      "PrimaryDefinition": "method, way, approach"
    },
    "Keys": {
      "Word": 8140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尾气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěiqì",
      "PrimaryDefinition": "exhaust (i.e. waste gas from engine), emissions"
    },
    "Keys": {
      "Word": 9705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阻挠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔnáo",
      "PrimaryDefinition": "to thwart, to obstruct (sth)"
    },
    "Keys": {
      "Word": 11006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuò",
      "PrimaryDefinition": "respectful reply of assent to superiors"
    },
    "Keys": {
      "Hanzi": 4818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4818,
      "Radical": "口",
      "FrequencyRank": 4174,
      "RadicalIndex": "30.9",
      "StrokeCount": 11
    }
  },
  "陕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎn",
      "PrimaryDefinition": "mountain pass; Shanxi province"
    },
    "Keys": {
      "Hanzi": 1320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1320,
      "Radical": "阜",
      "FrequencyRank": 2159,
      "RadicalIndex": "170.6",
      "StrokeCount": 8
    }
  },
  "钌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎo",
      "PrimaryDefinition": "ruthenium"
    },
    "Keys": {
      "Hanzi": 3723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3723,
      "Radical": "金",
      "FrequencyRank": 5742,
      "RadicalIndex": "167.2",
      "StrokeCount": 7
    }
  },
  "鄂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "Hubei province; startled"
    },
    "Keys": {
      "Hanzi": 2283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2283,
      "Radical": "邑",
      "FrequencyRank": 2946,
      "RadicalIndex": "163.9",
      "StrokeCount": 11
    }
  },
  "质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "matter, material, substance"
    },
    "Keys": {
      "Hanzi": 1157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.4",
      "GeneralStandard": 1157,
      "Radical": "厂",
      "HSK": 4,
      "FrequencyRank": 404,
      "StrokeCount": 8
    }
  },
  "巯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "an atom group"
    },
    "Keys": {
      "Hanzi": 5323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5323,
      "Radical": "工",
      "FrequencyRank": 6242,
      "RadicalIndex": "48.9",
      "StrokeCount": 12
    }
  },
  "蓠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "gracilaria verrucosa"
    },
    "Keys": {
      "Hanzi": 5384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5384,
      "Radical": "艸",
      "FrequencyRank": 7598,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "撕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "rip, tear; buy cloth"
    },
    "Keys": {
      "Hanzi": 3191,
      "Word": 9320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 7,
      "GeneralStandard": 3191,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2334,
      "StrokeCount": 15
    }
  },
  "从头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngtóu",
      "PrimaryDefinition": "anew, from the start"
    },
    "Keys": {
      "Word": 6061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成熟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngshú",
      "PrimaryDefinition": "ripe, mature"
    },
    "Keys": {
      "Word": 1350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "驮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "carry on back"
    },
    "Keys": {
      "Hanzi": 590,
      "Word": 9621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.3",
      "Grade": 7,
      "GeneralStandard": 590,
      "Radical": "馬",
      "HSK": 9,
      "FrequencyRank": 3427,
      "StrokeCount": 6
    }
  },
  "多年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōnián",
      "PrimaryDefinition": "for many years"
    },
    "Keys": {
      "Word": 2435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "滞后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìhòu",
      "PrimaryDefinition": "to lag behind"
    },
    "Keys": {
      "Word": 10805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "濒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "approach, be on verge of; near"
    },
    "Keys": {
      "Hanzi": 3377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.13",
      "GeneralStandard": 3377,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 3153,
      "StrokeCount": 16
    }
  },
  "收藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōucáng",
      "PrimaryDefinition": "collect, store up"
    },
    "Keys": {
      "Word": 5029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "昪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "delighted; pleased"
    },
    "Keys": {
      "Hanzi": 6832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6832,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "立功": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì gōng",
      "PrimaryDefinition": "Stand up"
    },
    "Keys": {
      "Word": 8001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热潮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rècháo",
      "PrimaryDefinition": "rush, surge, spate, upsurge"
    },
    "Keys": {
      "Word": 8831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "服从": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúcóng",
      "PrimaryDefinition": "to obey (an order), to comply, to defer"
    },
    "Keys": {
      "Word": 3469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瘗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "bury, inter"
    },
    "Keys": {
      "Hanzi": 7716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7716,
      "Radical": "疒",
      "FrequencyRank": 6611,
      "RadicalIndex": "104.9",
      "StrokeCount": 14
    }
  },
  "媞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "at ease"
    },
    "Keys": {
      "Hanzi": 7449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7449,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "昺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "bright, glorious; brilliant"
    },
    "Keys": {
      "Hanzi": 6819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6819,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "diagram; chart, map, picture"
    },
    "Keys": {
      "Hanzi": 1119,
      "Word": 1981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.5",
      "Grade": 3,
      "GeneralStandard": 1119,
      "Radical": "囗",
      "HSK": 1,
      "FrequencyRank": 476,
      "StrokeCount": 8
    }
  },
  "辈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "generation, lifetime; class"
    },
    "Keys": {
      "Hanzi": 2602,
      "Word": 3242
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "175.4",
      "Grade": 5,
      "GeneralStandard": 2602,
      "Radical": "非",
      "HSK": 5,
      "FrequencyRank": 1477,
      "StrokeCount": 12
    }
  },
  "告知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàozhī",
      "PrimaryDefinition": "acknowledge, expose, divulge, disclose, intimation, notify, impart, inform, communicate, apprize, notification, advise, give notice, assure, reveal, let on, tell, discover, acquaint, let out, send word, declare, instruct, apprise, break, give away, bring out"
    },
    "Keys": {
      "Word": 6716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "这些": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèxiē",
      "PrimaryDefinition": "det.: these"
    },
    "Keys": {
      "Word": 465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "加入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiārù",
      "PrimaryDefinition": "join, accede to"
    },
    "Keys": {
      "Word": 2596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "过道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòdào",
      "PrimaryDefinition": "passageway, corridor, aisle"
    },
    "Keys": {
      "Word": 6945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "dried up; exhausted, tired; dry"
    },
    "Keys": {
      "Hanzi": 4973
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4973,
      "Radical": "水",
      "FrequencyRank": 3865,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "冷冻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngdòng",
      "PrimaryDefinition": "to freeze, to deep-freeze"
    },
    "Keys": {
      "Word": 7965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饮料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnliào",
      "PrimaryDefinition": "stuff, bibation, drinkable, beverage, belly-wash, potation, quencher, soft_drink, slop, brewage, potable, drink"
    },
    "Keys": {
      "Word": 4121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "进出口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnchūkǒu",
      "PrimaryDefinition": "import and export"
    },
    "Keys": {
      "Word": 7581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "drought; dry; dry land"
    },
    "Keys": {
      "Hanzi": 714,
      "Word": 6987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.3",
      "Grade": 7,
      "GeneralStandard": 714,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2523,
      "StrokeCount": 7
    }
  },
  "丢掉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diūdiào",
      "PrimaryDefinition": "to lose, to throw away, to discard, to cast away"
    },
    "Keys": {
      "Word": 6317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "殇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng",
      "PrimaryDefinition": "die young; national mourning"
    },
    "Keys": {
      "Hanzi": 4175
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4175,
      "Radical": "歹",
      "FrequencyRank": 5198,
      "RadicalIndex": "78.5",
      "StrokeCount": 9
    }
  },
  "开创": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāichuàng",
      "PrimaryDefinition": "start, initiate"
    },
    "Keys": {
      "Word": 4779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "变更": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàngēng",
      "PrimaryDefinition": "change, modify"
    },
    "Keys": {
      "Word": 4331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "隆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "prosperous, plentiful, abundant"
    },
    "Keys": {
      "Hanzi": 2479
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.9",
      "GeneralStandard": 2479,
      "Radical": "阜",
      "HSK": 7,
      "FrequencyRank": 1400,
      "StrokeCount": 11
    }
  },
  "处置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔzhì",
      "PrimaryDefinition": "deal with, manage, dispose of, punish"
    },
    "Keys": {
      "Word": 5988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "靓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "make up face; ornament; quiet"
    },
    "Keys": {
      "Hanzi": 5050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5050,
      "Radical": "青",
      "FrequencyRank": 4636,
      "RadicalIndex": "174.4",
      "StrokeCount": 12
    }
  },
  "牮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4262,
      "Radical": "牛",
      "RadicalIndex": "93.5",
      "StrokeCount": 9
    }
  },
  "会计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàiji",
      "PrimaryDefinition": "accountant, bookkeeper"
    },
    "Keys": {
      "Word": 2671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "骄傲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoào",
      "PrimaryDefinition": "be proud, take pride in"
    },
    "Keys": {
      "Word": 4718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "邳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "a department in the State of Lu; in what is now Shantung or N. China"
    },
    "Keys": {
      "Hanzi": 3685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3685,
      "Radical": "邑",
      "FrequencyRank": 6080,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "致敬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìjìng",
      "PrimaryDefinition": "to greet, to pay respects to"
    },
    "Keys": {
      "Word": 10795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "居民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūmín",
      "PrimaryDefinition": "municipality, population, citizen, denizen, townie, people, dweller, populace, residenter, resident, towny, inhabitant, habitant, liver"
    },
    "Keys": {
      "Word": 2644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "遣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎn",
      "PrimaryDefinition": "send, dispatch; send off, exile"
    },
    "Keys": {
      "Hanzi": 2921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.1",
      "GeneralStandard": 2921,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 1780,
      "StrokeCount": 13
    }
  },
  "感到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎndào",
      "PrimaryDefinition": "feel, sense"
    },
    "Keys": {
      "Word": 669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "猴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "monkey, ape; monkey-like"
    },
    "Keys": {
      "Hanzi": 2721,
      "Word": 3546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.9",
      "Grade": 5,
      "GeneralStandard": 2721,
      "Radical": "犬",
      "HSK": 5,
      "FrequencyRank": 2187,
      "StrokeCount": 12
    }
  },
  "心里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnli",
      "PrimaryDefinition": "chest, heart, mind"
    },
    "Keys": {
      "Word": 1101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蜇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhē",
      "PrimaryDefinition": "jellyfish"
    },
    "Keys": {
      "Hanzi": 5356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5356,
      "Radical": "虫",
      "FrequencyRank": 4645,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "椐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5105,
      "Radical": "木",
      "FrequencyRank": 6815,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "堙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "bury; dam, block up"
    },
    "Keys": {
      "Hanzi": 5064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5064,
      "Radical": "土",
      "FrequencyRank": 6531,
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔ",
      "PrimaryDefinition": "wine, spirits, liquor, alcoholic beverage"
    },
    "Keys": {
      "Hanzi": 2069,
      "Word": 797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.3",
      "Grade": 2,
      "GeneralStandard": 2069,
      "Radical": "酉",
      "HSK": 2,
      "FrequencyRank": 797,
      "StrokeCount": 10
    }
  },
  "埋没": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máimò",
      "PrimaryDefinition": "to engulf, to bury, to overlook, to stifle, to neglect, to fall into oblivion"
    },
    "Keys": {
      "Word": 8188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "refuse, dregs, lees, sediment"
    },
    "Keys": {
      "Hanzi": 2753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2753,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3196,
      "StrokeCount": 12
    }
  },
  "过滤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòlǜ",
      "PrimaryDefinition": "to filter, filter"
    },
    "Keys": {
      "Word": 6949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒurén",
      "PrimaryDefinition": "someone, people, anyone, there is someone there, occupied (as in restroom)"
    },
    "Keys": {
      "Word": 1175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "舜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùn",
      "PrimaryDefinition": "legendary ruler"
    },
    "Keys": {
      "Hanzi": 5225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5225,
      "Radical": "舛",
      "FrequencyRank": 3182,
      "RadicalIndex": "136.6",
      "StrokeCount": 12
    }
  },
  "自费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìfèi",
      "PrimaryDefinition": "at one's own expense, be at one's own expense"
    },
    "Keys": {
      "Word": 10953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拖延": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōyán",
      "PrimaryDefinition": "delay, put off"
    },
    "Keys": {
      "Word": 9615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "游泳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuyǒng",
      "PrimaryDefinition": "fin, swim, bathe"
    },
    "Keys": {
      "Word": 2114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "地上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìshang",
      "PrimaryDefinition": "on the ground, on the floor"
    },
    "Keys": {
      "Word": 70
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "胳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "armpit, arms"
    },
    "Keys": {
      "Hanzi": 1997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 1997,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2490,
      "StrokeCount": 10
    }
  },
  "沸腾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèiténg",
      "PrimaryDefinition": "(of a liquid) to boil, (of sentiments etc) to boil over, to flare up, to be impassioned"
    },
    "Keys": {
      "Word": 6530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "sugar, syrup; malt sugar; sticky"
    },
    "Keys": {
      "Hanzi": 3622
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3622,
      "Radical": "食",
      "FrequencyRank": 5006,
      "RadicalIndex": "184.3",
      "StrokeCount": 6
    }
  },
  "定义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngyì",
      "PrimaryDefinition": "definition"
    },
    "Keys": {
      "Word": 6318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "关键": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānjiàn",
      "PrimaryDefinition": "nub, turning_point, issue, crux, anchor, crux of the matter, juncture, hinge, mainstay, linchpin, lynchpin, key, backbone"
    },
    "Keys": {
      "Word": 3515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "发誓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā shì",
      "PrimaryDefinition": "to vow, to pledge, to swear"
    },
    "Keys": {
      "Word": 6443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "a kind of woolen fabric"
    },
    "Keys": {
      "Hanzi": 6238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6238,
      "Radical": "网",
      "FrequencyRank": 7032,
      "RadicalIndex": "122.12",
      "StrokeCount": 17
    }
  },
  "过年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guònian",
      "PrimaryDefinition": "celebrate/spend New Year"
    },
    "Keys": {
      "Word": 701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "流入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúrù",
      "PrimaryDefinition": "to flow into, to drift into, influx, inflow"
    },
    "Keys": {
      "Word": 8103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "问路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèn lù",
      "PrimaryDefinition": "to ask for directions, to ask the way (to some place)"
    },
    "Keys": {
      "Word": 1065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "向上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngshàng",
      "PrimaryDefinition": "upward, up, to advance, to try to improve oneself, to make progress"
    },
    "Keys": {
      "Word": 4047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "耐心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàixīn",
      "PrimaryDefinition": "nerves, forbearance, longanimity, endurance, patience"
    },
    "Keys": {
      "Word": 3739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "剞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "carving or engraving knife; grave"
    },
    "Keys": {
      "Hanzi": 4482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4482,
      "Radical": "刀",
      "FrequencyRank": 7417,
      "RadicalIndex": "18.8",
      "StrokeCount": 10
    }
  },
  "蛎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "oyster"
    },
    "Keys": {
      "Hanzi": 4833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4833,
      "Radical": "虫",
      "FrequencyRank": 4426,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "棕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "hemp palm; palm tree"
    },
    "Keys": {
      "Hanzi": 2576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2576,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 2774,
      "StrokeCount": 12
    }
  },
  "口味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuwèi",
      "PrimaryDefinition": "a person's taste, flavor of food"
    },
    "Keys": {
      "Word": 7846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鉴赏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànshǎng",
      "PrimaryDefinition": "to appreciate"
    },
    "Keys": {
      "Word": 7437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苗条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáotiao",
      "PrimaryDefinition": "(of a woman) slim, slender, graceful"
    },
    "Keys": {
      "Word": 8286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "押": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "mortgage, pledge; deposit; to pawn; to arrest, detain, guard"
    },
    "Keys": {
      "Hanzi": 965,
      "Word": 10160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 965,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1775,
      "StrokeCount": 8
    }
  },
  "蓂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "lucky place"
    },
    "Keys": {
      "Hanzi": 7489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7489,
      "Radical": "艸",
      "FrequencyRank": 8044,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "嘲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháo",
      "PrimaryDefinition": "ridicule, deride, scorn, jeer at"
    },
    "Keys": {
      "Hanzi": 3238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.12",
      "GeneralStandard": 3238,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2325,
      "StrokeCount": 15
    }
  },
  "灼热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuórè",
      "PrimaryDefinition": "burning hot, scorching, worried"
    },
    "Keys": {
      "Word": 10926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "真假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnjiǎ",
      "PrimaryDefinition": "genuine or fake, true or false"
    },
    "Keys": {
      "Word": 10699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "封面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngmiàn",
      "PrimaryDefinition": "lid, book binding, title page of thread-bound book, front cover, front and back cover of book, back, cover, binding, title page"
    },
    "Keys": {
      "Word": 6585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "存放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cúnfàng",
      "PrimaryDefinition": "leave with, leave in sb.'s care, deposit (money)"
    },
    "Keys": {
      "Word": 6083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kù",
      "PrimaryDefinition": "trousers"
    },
    "Keys": {
      "Hanzi": 4393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4393,
      "Radical": "糸",
      "FrequencyRank": 5259,
      "RadicalIndex": "120.6",
      "StrokeCount": 9
    }
  },
  "掬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "to grasp or hold with both hands"
    },
    "Keys": {
      "Hanzi": 4731
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4731,
      "Radical": "手",
      "FrequencyRank": 4398,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuán",
      "PrimaryDefinition": "ship, boat, vessel"
    },
    "Keys": {
      "Hanzi": 2345,
      "Word": 575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "137.5",
      "Grade": 2,
      "GeneralStandard": 2345,
      "Radical": "舟",
      "HSK": 2,
      "FrequencyRank": 614,
      "StrokeCount": 11
    }
  },
  "表格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎogé",
      "PrimaryDefinition": "tabular array, tabulation, table, form, blank, tabular matter"
    },
    "Keys": {
      "Word": 1305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "旨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "purpose, aim; excellent"
    },
    "Keys": {
      "Hanzi": 501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.2",
      "GeneralStandard": 501,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 1685,
      "StrokeCount": 6
    }
  },
  "赶紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnjǐn",
      "PrimaryDefinition": "hasten, double-quick, losing no time, hurriedly"
    },
    "Keys": {
      "Word": 1475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "平安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngān",
      "PrimaryDefinition": "safe and sound, quiet and stable"
    },
    "Keys": {
      "Word": 908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "究竟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiūjìng",
      "PrimaryDefinition": "actually, exactly, after all, in the end"
    },
    "Keys": {
      "Word": 2643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "怖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "terror, fear; frighten; terrified"
    },
    "Keys": {
      "Hanzi": 1267
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1267,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1776,
      "StrokeCount": 8
    }
  },
  "变异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànyì",
      "PrimaryDefinition": "variation"
    },
    "Keys": {
      "Word": 5608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过半": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòbàn",
      "PrimaryDefinition": "over fifty percent, more than half"
    },
    "Keys": {
      "Word": 6941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沿海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánhǎi",
      "PrimaryDefinition": "coast, coastal, along the coast, littoral"
    },
    "Keys": {
      "Word": 5209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鹾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuó",
      "PrimaryDefinition": "salty; salt"
    },
    "Keys": {
      "Hanzi": 7857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7857,
      "Radical": "鹵",
      "FrequencyRank": 5836,
      "RadicalIndex": "197.9",
      "StrokeCount": 16
    }
  },
  "周": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "Zhou dynasty; circumference"
    },
    "Keys": {
      "Hanzi": 1192,
      "Word": 1229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "Grade": 2,
      "GeneralStandard": 1192,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 490,
      "StrokeCount": 8
    }
  },
  "妯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhóu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4065,
      "Radical": "女",
      "FrequencyRank": 5343,
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "殴打": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōudǎ",
      "PrimaryDefinition": "beat up, come to blows"
    },
    "Keys": {
      "Word": 8450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "small boat with windows; houseboat"
    },
    "Keys": {
      "Hanzi": 7204
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7204,
      "Radical": "舟",
      "FrequencyRank": 7327,
      "RadicalIndex": "137.5",
      "StrokeCount": 11
    }
  },
  "证实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngshí",
      "PrimaryDefinition": "confirm, verify"
    },
    "Keys": {
      "Word": 4210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "北部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běibù",
      "PrimaryDefinition": "the north, the northern part of the country, northern part"
    },
    "Keys": {
      "Word": 1286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "哒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dā",
      "PrimaryDefinition": "a sound made to get a horse to move forward"
    },
    "Keys": {
      "Hanzi": 6827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6827,
      "Radical": "口",
      "FrequencyRank": 3741,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "成年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng nián",
      "PrimaryDefinition": "to grow to adulthood, fully grown, the whole year"
    },
    "Keys": {
      "Word": 5869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "改版": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎi bǎn",
      "PrimaryDefinition": "to revise the current edition, revised edition"
    },
    "Keys": {
      "Word": 6650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彩票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎipiào",
      "PrimaryDefinition": "lottery ticket"
    },
    "Keys": {
      "Word": 3295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "髻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "hair rolled up in a bun, topknot"
    },
    "Keys": {
      "Hanzi": 6036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6036,
      "Radical": "髟",
      "FrequencyRank": 4140,
      "RadicalIndex": "190.6",
      "StrokeCount": 16
    }
  },
  "屠杀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túshā",
      "PrimaryDefinition": "to massacre, massacre, bloodbath, carnage"
    },
    "Keys": {
      "Word": 9584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一概": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīgài",
      "PrimaryDefinition": "all, without any exceptions, categorically"
    },
    "Keys": {
      "Word": 10286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cì",
      "PrimaryDefinition": "give, bestow favors; appoint"
    },
    "Keys": {
      "Hanzi": 2653,
      "Word": 6052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.8",
      "Grade": 7,
      "GeneralStandard": 2653,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 2072,
      "StrokeCount": 12
    }
  },
  "裉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèn",
      "PrimaryDefinition": "seam in a garment"
    },
    "Keys": {
      "Hanzi": 5001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5001,
      "Radical": "衣",
      "FrequencyRank": 5732,
      "RadicalIndex": "145.6",
      "StrokeCount": 11
    }
  },
  "雍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "harmony, union; harmonious"
    },
    "Keys": {
      "Hanzi": 5551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5551,
      "Radical": "隹",
      "FrequencyRank": 3327,
      "RadicalIndex": "172.5",
      "StrokeCount": 13
    }
  },
  "短期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnqī",
      "PrimaryDefinition": "short term, short-term"
    },
    "Keys": {
      "Word": 1421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "出租车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzūchē",
      "PrimaryDefinition": "taxi, (Taiwan) rental car"
    },
    "Keys": {
      "Word": 572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "锅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guō",
      "PrimaryDefinition": "cooking-pot, saucepan"
    },
    "Keys": {
      "Hanzi": 2662,
      "Word": 3527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "Grade": 5,
      "GeneralStandard": 2662,
      "Radical": "金",
      "HSK": 5,
      "FrequencyRank": 2074,
      "StrokeCount": 12
    }
  },
  "活跃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huóyuè",
      "PrimaryDefinition": "animate, kick around, active, invigoration, knock_about, cavort, enliven, vitalize, knock about, energize, flourish, invigorate, brighten, kick about, vitalization, vivify, move"
    },
    "Keys": {
      "Word": 4688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "撰写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuànxiě",
      "PrimaryDefinition": "write"
    },
    "Keys": {
      "Word": 10901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6574,
      "Radical": "艸",
      "FrequencyRank": 6128,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "摒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "expel, cast off; arrange"
    },
    "Keys": {
      "Hanzi": 5075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5075,
      "Radical": "手",
      "FrequencyRank": 3791,
      "RadicalIndex": "64.11",
      "StrokeCount": 12
    }
  },
  "气魄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìpò",
      "PrimaryDefinition": "spirit, boldness, positive outlook, imposing attitude"
    },
    "Keys": {
      "Word": 8628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "rustic, vulgar, unpolished; mean"
    },
    "Keys": {
      "Hanzi": 4264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4264,
      "Radical": "人",
      "FrequencyRank": 4358,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "可": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kě",
      "PrimaryDefinition": "may, can, -able; possibly"
    },
    "Keys": {
      "Hanzi": 212,
      "Word": 3657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 5,
      "GeneralStandard": 212,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 30,
      "StrokeCount": 5
    }
  },
  "柔和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róuhé",
      "PrimaryDefinition": "gentle, soft"
    },
    "Keys": {
      "Word": 8891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngjié",
      "PrimaryDefinition": "complex"
    },
    "Keys": {
      "Word": 8758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "jade ring; earrings; surname"
    },
    "Keys": {
      "Hanzi": 6196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6196,
      "Radical": "玉",
      "FrequencyRank": 6081,
      "RadicalIndex": "96.13",
      "StrokeCount": 17
    }
  },
  "娣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "younger sister, sister-in-law"
    },
    "Keys": {
      "Hanzi": 4694
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4694,
      "Radical": "女",
      "FrequencyRank": 4727,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "专著": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānzhù",
      "PrimaryDefinition": "monograph, specialized text"
    },
    "Keys": {
      "Word": 10886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "依旧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījiù",
      "PrimaryDefinition": "as_usual, as was common, as usual, still, as before"
    },
    "Keys": {
      "Word": 4102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "邻居": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línjū",
      "PrimaryDefinition": "neighbor"
    },
    "Keys": {
      "Word": 3698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "墐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "to build with soil, plaster over with mud"
    },
    "Keys": {
      "Hanzi": 7631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7631,
      "Radical": "土",
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "迤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "wind; walk out of straight path"
    },
    "Keys": {
      "Hanzi": 3949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3949,
      "Radical": "辵",
      "FrequencyRank": 4732,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "很": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hěn",
      "PrimaryDefinition": "very, quite, much"
    },
    "Keys": {
      "Hanzi": 1584,
      "Word": 144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "60.6",
      "Grade": 1,
      "GeneralStandard": 1584,
      "Radical": "彳",
      "HSK": 1,
      "FrequencyRank": 138,
      "StrokeCount": 9
    }
  },
  "持之以恒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chízhīyǐhéng",
      "PrimaryDefinition": "to pursue unremittingly (idiom), to persevere"
    },
    "Keys": {
      "Word": 5906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "视为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìwéi",
      "PrimaryDefinition": "regard_as, deem, count, regard as, consider as, repute, look_on, regard/consider as, look_at, call, regard"
    },
    "Keys": {
      "Word": 3918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "触摸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùmō",
      "PrimaryDefinition": "to touch"
    },
    "Keys": {
      "Word": 5994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心灵手巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnlíngshǒuqiǎo",
      "PrimaryDefinition": "capable, clever, dexterous"
    },
    "Keys": {
      "Word": 10009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邮箱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuxiāng",
      "PrimaryDefinition": "mailbox, post office box"
    },
    "Keys": {
      "Word": 2111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "芏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6525
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6525,
      "Radical": "艸",
      "FrequencyRank": 5111,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "无须": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxū",
      "PrimaryDefinition": "need not, not obliged to, not necessarily"
    },
    "Keys": {
      "Word": 9792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "结束": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéshù",
      "PrimaryDefinition": "end, close, conclude"
    },
    "Keys": {
      "Word": 1622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "接手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēshǒu",
      "PrimaryDefinition": "to take over (duties etc), catcher (baseball etc)"
    },
    "Keys": {
      "Word": 7509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接轨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē guǐ",
      "PrimaryDefinition": "railtrack connection, to integrate into sth, to dock, to connect, to be in step with, to bring into line with, to align"
    },
    "Keys": {
      "Word": 7503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kā",
      "PrimaryDefinition": "coffee; a phonetic"
    },
    "Keys": {
      "Hanzi": 1104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1104,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 2379,
      "StrokeCount": 8
    }
  },
  "堵车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔ chē",
      "PrimaryDefinition": "traffic jam, choking"
    },
    "Keys": {
      "Word": 2428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "党": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎng",
      "PrimaryDefinition": "political party, gang, faction"
    },
    "Keys": {
      "Hanzi": 1885,
      "Word": 4485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.8",
      "Grade": 6,
      "GeneralStandard": 1885,
      "Radical": "儿",
      "HSK": 6,
      "FrequencyRank": 411,
      "StrokeCount": 10
    }
  },
  "氪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "krypton"
    },
    "Keys": {
      "Hanzi": 4888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4888,
      "Radical": "气",
      "FrequencyRank": 5279,
      "RadicalIndex": "84.7",
      "StrokeCount": 11
    }
  },
  "计算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìsuàn",
      "PrimaryDefinition": "count, calculate, compute"
    },
    "Keys": {
      "Word": 1578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "丝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "silk; fine thread; wire; strings"
    },
    "Keys": {
      "Hanzi": 347,
      "Word": 9306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "Grade": 7,
      "GeneralStandard": 347,
      "Radical": "一",
      "HSK": 7,
      "FrequencyRank": 1026,
      "StrokeCount": 5
    }
  },
  "兼职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānzhí",
      "PrimaryDefinition": "to hold concurrent posts, concurrent job, moonlighting"
    },
    "Keys": {
      "Word": 7399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "small dish; window"
    },
    "Keys": {
      "Hanzi": 7493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7493,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "下次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàcì",
      "PrimaryDefinition": "next time"
    },
    "Keys": {
      "Word": 390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "镫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèng",
      "PrimaryDefinition": "lamp; a kind of cooking vessel"
    },
    "Keys": {
      "Hanzi": 6252
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6252,
      "Radical": "金",
      "FrequencyRank": 5605,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "葫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "bottle-gourd"
    },
    "Keys": {
      "Hanzi": 2545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2545,
      "Radical": "艸",
      "FrequencyRank": 3246,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "报答": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàodá",
      "PrimaryDefinition": "to repay, to requite"
    },
    "Keys": {
      "Word": 3234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "棚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "tent, awning; booth; shed"
    },
    "Keys": {
      "Hanzi": 2575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2575,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2448,
      "StrokeCount": 12
    }
  },
  "快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "rapid, quick, speedy, fast; soon"
    },
    "Keys": {
      "Hanzi": 876,
      "Word": 201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "Grade": 1,
      "GeneralStandard": 876,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 366,
      "StrokeCount": 7
    }
  },
  "公正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzhèng",
      "PrimaryDefinition": "impartial"
    },
    "Keys": {
      "Word": 3503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "观看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānkàn",
      "PrimaryDefinition": "eye, view, watch, spectate, eyeball"
    },
    "Keys": {
      "Word": 1520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "自行车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìxíngchē",
      "PrimaryDefinition": "two-wheeler, roadster, machine, bicycle, wheel, push-bike, cycle, motorcycle, bike, velocipede"
    },
    "Keys": {
      "Word": 1239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "宙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "time as concept; infinite time"
    },
    "Keys": {
      "Hanzi": 1280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "GeneralStandard": 1280,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 1421,
      "StrokeCount": 8
    }
  },
  "涡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "swirl, whirlpool, eddy"
    },
    "Keys": {
      "Hanzi": 2072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2072,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2759,
      "StrokeCount": 10
    }
  },
  "虐待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nüèdài",
      "PrimaryDefinition": "to mistreat, to maltreat, to abuse, mistreatment, maltreatment"
    },
    "Keys": {
      "Word": 8445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遇难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù nàn",
      "PrimaryDefinition": "to perish, to be killed"
    },
    "Keys": {
      "Word": 10524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猖狂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāngkuáng",
      "PrimaryDefinition": "savage, furious"
    },
    "Keys": {
      "Word": 5808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "smell, fragrance, aroma"
    },
    "Keys": {
      "Hanzi": 6661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6661,
      "Radical": "艸",
      "FrequencyRank": 7247,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "回收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíshōu",
      "PrimaryDefinition": "recycle"
    },
    "Keys": {
      "Word": 3559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "少不了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎobuliǎo",
      "PrimaryDefinition": "cannot do without, to be unavoidable, are bound to be many"
    },
    "Keys": {
      "Word": 8996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "安全": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānquán",
      "PrimaryDefinition": "safe, secure"
    },
    "Keys": {
      "Word": 501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "journey, trip; schedule, agenda"
    },
    "Keys": {
      "Hanzi": 2677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.7",
      "GeneralStandard": 2677,
      "Radical": "禾",
      "HSK": 3,
      "FrequencyRank": 314,
      "StrokeCount": 12
    }
  },
  "富豪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùháo",
      "PrimaryDefinition": "rich and powerful person"
    },
    "Keys": {
      "Word": 6641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "替换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tìhuàn",
      "PrimaryDefinition": "to exchange, to replace, to substitute for, to switch"
    },
    "Keys": {
      "Word": 9471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谨慎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnshèn",
      "PrimaryDefinition": "cautious, circumspect"
    },
    "Keys": {
      "Word": 7576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粗心大意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūxīn-dàyì",
      "PrimaryDefinition": "negligent, careless, inadvertent"
    },
    "Keys": {
      "Word": 6073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "栎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "chestnut-leaved oak; oak"
    },
    "Keys": {
      "Hanzi": 4155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4155,
      "Radical": "木",
      "FrequencyRank": 4721,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "特产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèchǎn",
      "PrimaryDefinition": "special local product, (regional) specialty"
    },
    "Keys": {
      "Word": 9443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "书店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūdiàn",
      "PrimaryDefinition": "bookstore"
    },
    "Keys": {
      "Word": 339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "稳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěn",
      "PrimaryDefinition": "stable, firm, solid, steady"
    },
    "Keys": {
      "Hanzi": 3118,
      "Word": 2981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.9",
      "Grade": 4,
      "GeneralStandard": 3118,
      "Radical": "禾",
      "HSK": 4,
      "FrequencyRank": 1055,
      "StrokeCount": 14
    }
  },
  "前提": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiántí",
      "PrimaryDefinition": "precondition, prerequisite, reason, premiss, antecedent, stipulation, premise, presupposition, hypothesis, induction, condition"
    },
    "Keys": {
      "Word": 3806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "上课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng kè",
      "PrimaryDefinition": "attend class, go to class;give lesson/lecture"
    },
    "Keys": {
      "Word": 315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "代言人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàiyánrén",
      "PrimaryDefinition": "spokesperson"
    },
    "Keys": {
      "Word": 6146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "女性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚxìng",
      "PrimaryDefinition": "lady, female sex, womankind, feminie, female, sex, petticoat, femininity, female person, woman, she, patroness, womanhood, fair, feminine"
    },
    "Keys": {
      "Word": 3753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "炉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "fireplace, stove, oven, furnace"
    },
    "Keys": {
      "Hanzi": 1238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.4",
      "GeneralStandard": 1238,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 1980,
      "StrokeCount": 8
    }
  },
  "条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": "clause, condition; string, stripe"
    },
    "Keys": {
      "Hanzi": 816,
      "Word": 1024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "Grade": 2,
      "GeneralStandard": 816,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 214,
      "StrokeCount": 7
    }
  },
  "交替": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāotì",
      "PrimaryDefinition": "to replace, alternately, in turn"
    },
    "Keys": {
      "Word": 7461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "daphne genkwa, poisonous plant"
    },
    "Keys": {
      "Hanzi": 3659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3659,
      "Radical": "艸",
      "FrequencyRank": 5350,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "专心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānxīn",
      "PrimaryDefinition": "occupy, immerge, pore, zoom in, concentrate, betake, dive, pay attention, concentrate effort, advert, give ear, center, rivet, concentrate one's attention, focus, hang, pay heed, devote, apply, be absorbed, be absorbed in, centre, attend"
    },
    "Keys": {
      "Word": 3194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "猖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "mad, wild, reckless, unruly"
    },
    "Keys": {
      "Hanzi": 2369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.8",
      "GeneralStandard": 2369,
      "Radical": "犬",
      "HSK": 9,
      "FrequencyRank": 3392,
      "StrokeCount": 11
    }
  },
  "笄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "hairpin; fifteen year old girl"
    },
    "Keys": {
      "Hanzi": 4556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4556,
      "Radical": "竹",
      "FrequencyRank": 5703,
      "RadicalIndex": "118.6",
      "StrokeCount": 10
    }
  },
  "同时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngshí",
      "PrimaryDefinition": "at the same time, simultaneously"
    },
    "Keys": {
      "Word": 1035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "女婿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚxu",
      "PrimaryDefinition": "daughter's husband, son-in-law"
    },
    "Keys": {
      "Word": 8446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "价值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàzhí",
      "PrimaryDefinition": "value, worth"
    },
    "Keys": {
      "Word": 1593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "总而言之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngéryánzhī",
      "PrimaryDefinition": "in short, in a word, in brief"
    },
    "Keys": {
      "Word": 10993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèxìng",
      "PrimaryDefinition": "attribute, distinctive feature, tang, a character, appanage, a characteristic, nature, trait, differentiation, data, habit, lineament, shtick, tone, distinction, identity, property, characteristic, specificity, ring, specialty, hair, character, cast, idiosyncrasy, specific, diagnosis, chemistry, feature, peculiarity, quality"
    },
    "Keys": {
      "Word": 3968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "皇后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánghòu",
      "PrimaryDefinition": "kaiserin, queen consort, queen, empress, queen_consort, Kaiserin"
    },
    "Keys": {
      "Word": 7192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "廆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7416,
      "Radical": "广",
      "RadicalIndex": "53.1",
      "StrokeCount": 12
    }
  },
  "渣子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāzi",
      "PrimaryDefinition": "Slag"
    },
    "Keys": {
      "Word": 10626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "要领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàolǐng",
      "PrimaryDefinition": "main aspects, essentials, gist"
    },
    "Keys": {
      "Word": 10248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ",
      "PrimaryDefinition": "marry, take wife"
    },
    "Keys": {
      "Hanzi": 2197,
      "Word": 8791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.8",
      "Grade": 7,
      "GeneralStandard": 2197,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2494,
      "StrokeCount": 11
    }
  },
  "阴天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīntiān",
      "PrimaryDefinition": "cloudy day, overcast sky"
    },
    "Keys": {
      "Word": 1157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "战胜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànshèng",
      "PrimaryDefinition": "to prevail over, to defeat, to surmount"
    },
    "Keys": {
      "Word": 3151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "克制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèzhì",
      "PrimaryDefinition": "to restrain, to control, restraint, self-control"
    },
    "Keys": {
      "Word": 7809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7757,
      "Radical": "艸",
      "FrequencyRank": 6354,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "汽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "steam, vapor, gas"
    },
    "Keys": {
      "Hanzi": 861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 861,
      "Radical": "水",
      "HSK": 1,
      "FrequencyRank": 1200,
      "StrokeCount": 7
    }
  },
  "休闲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūxián",
      "PrimaryDefinition": "leisure, relaxation, not working, idle, to enjoy leisure, to lie fallow"
    },
    "Keys": {
      "Word": 4072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "徐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xú",
      "PrimaryDefinition": "slowly, quietly, calmly; composed, dignified"
    },
    "Keys": {
      "Hanzi": 1976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.7",
      "GeneralStandard": 1976,
      "Radical": "彳",
      "HSK": 9,
      "FrequencyRank": 1313,
      "StrokeCount": 10
    }
  },
  "偏差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānchā",
      "PrimaryDefinition": "deviation, error"
    },
    "Keys": {
      "Word": 8517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "管辖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnxiá",
      "PrimaryDefinition": "to administer, to have jurisdiction (over)"
    },
    "Keys": {
      "Word": 6884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnshuō",
      "PrimaryDefinition": "speech, address"
    },
    "Keys": {
      "Word": 10202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shē",
      "PrimaryDefinition": "to cultivate land by first setting fire to it"
    },
    "Keys": {
      "Hanzi": 5219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5219,
      "Radical": "田",
      "FrequencyRank": 5102,
      "RadicalIndex": "102.7",
      "StrokeCount": 12
    }
  },
  "嘡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5716,
      "Radical": "口",
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "dry"
    },
    "Keys": {
      "Hanzi": 23,
      "Radical": 51,
      "Word": 110
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "51",
      "Grade": 1,
      "GeneralStandard": 23,
      "Radical": "干",
      "HSK": 1,
      "FrequencyRank": 353,
      "StrokeCount": 3
    }
  },
  "亲朋好友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnpénghǎoyǒu",
      "PrimaryDefinition": "friends and family, kith and kin"
    },
    "Keys": {
      "Word": 8719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "络": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "enmesh, wrap around; web, net"
    },
    "Keys": {
      "Hanzi": 1761
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "GeneralStandard": 1761,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 1118,
      "StrokeCount": 9
    }
  },
  "垱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4101,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "吃苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī kǔ",
      "PrimaryDefinition": "to bear hardships"
    },
    "Keys": {
      "Word": 5894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "a kind of crab; worm, leech"
    },
    "Keys": {
      "Hanzi": 5704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5704,
      "Radical": "虫",
      "FrequencyRank": 7070,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "螭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "a dragon whose horns have not grown; cruel"
    },
    "Keys": {
      "Hanzi": 6101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6101,
      "Radical": "虫",
      "FrequencyRank": 5509,
      "RadicalIndex": "142.11",
      "StrokeCount": 16
    }
  },
  "首要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuyào",
      "PrimaryDefinition": "chief, paramountcy, premiership, primacy, of first importance"
    },
    "Keys": {
      "Word": 9191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "政治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngzhì",
      "PrimaryDefinition": "paternalism, trouble, government, policy, political relation, political science, polity, politics, political affairs, political"
    },
    "Keys": {
      "Word": 3167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "袱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "a piece of cloth used wrap bundles"
    },
    "Keys": {
      "Hanzi": 2463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.6",
      "GeneralStandard": 2463,
      "Radical": "衣",
      "HSK": 8,
      "FrequencyRank": 3352,
      "StrokeCount": 11
    }
  },
  "猗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "exclamation of admiration"
    },
    "Keys": {
      "Hanzi": 4925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4925,
      "Radical": "犬",
      "FrequencyRank": 5853,
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "车展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēzhǎn",
      "PrimaryDefinition": "motor show"
    },
    "Keys": {
      "Word": 4405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "珍视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnshì",
      "PrimaryDefinition": "to place great importance on, to treasure"
    },
    "Keys": {
      "Word": 10697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "社会主义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèhuìzhǔyì",
      "PrimaryDefinition": "socialist, socialism"
    },
    "Keys": {
      "Word": 9008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "另": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìng",
      "PrimaryDefinition": "another, separate, other"
    },
    "Keys": {
      "Hanzi": 256,
      "Word": 4836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 6,
      "GeneralStandard": 256,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 489,
      "StrokeCount": 5
    }
  },
  "们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "men",
      "PrimaryDefinition": "adjunct pronoun indicate plural"
    },
    "Keys": {
      "Hanzi": 273,
      "Word": 239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "Grade": 1,
      "GeneralStandard": 273,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 13,
      "StrokeCount": 5
    }
  },
  "骟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "geld, castrate"
    },
    "Keys": {
      "Hanzi": 5619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5619,
      "Radical": "馬",
      "FrequencyRank": 7315,
      "RadicalIndex": "187.1",
      "StrokeCount": 13
    }
  },
  "旰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàn",
      "PrimaryDefinition": "sunset, dusk; evening"
    },
    "Keys": {
      "Hanzi": 3697
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3697,
      "Radical": "日",
      "FrequencyRank": 6183,
      "RadicalIndex": "72.3",
      "StrokeCount": 7
    }
  },
  "输出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūchū",
      "PrimaryDefinition": "export"
    },
    "Keys": {
      "Word": 3922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "敢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "to dare, venture; bold, brave"
    },
    "Keys": {
      "Hanzi": 2470,
      "Word": 1476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.8",
      "Grade": 3,
      "GeneralStandard": 2470,
      "Radical": "攴",
      "HSK": 3,
      "FrequencyRank": 795,
      "StrokeCount": 11
    }
  },
  "顺理成章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnlǐ-chéngzhāng",
      "PrimaryDefinition": "logical, only to be expected, rational and clearly structured (of text)"
    },
    "Keys": {
      "Word": 9281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7131,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "锟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "ancient treasured sword"
    },
    "Keys": {
      "Hanzi": 5487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5487,
      "Radical": "金",
      "FrequencyRank": 4644,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "冲动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngdòng",
      "PrimaryDefinition": "get excited, be impetuous"
    },
    "Keys": {
      "Word": 3334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "主妇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔfù",
      "PrimaryDefinition": "housewife, hostess"
    },
    "Keys": {
      "Word": 10851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "勍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "strong, powerful, mighty; violent"
    },
    "Keys": {
      "Hanzi": 7040
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7040,
      "Radical": "力",
      "RadicalIndex": "19.8",
      "StrokeCount": 10
    }
  },
  "营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "encampment, barracks; manage"
    },
    "Keys": {
      "Hanzi": 2214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2214,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 536,
      "StrokeCount": 11
    }
  },
  "妃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "wife, spouse; imperial concubine"
    },
    "Keys": {
      "Hanzi": 580
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 580,
      "Radical": "女",
      "FrequencyRank": 2574,
      "RadicalIndex": "38.3",
      "StrokeCount": 6
    }
  },
  "坏处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàichu",
      "PrimaryDefinition": "harm, troubles, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "狃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔ",
      "PrimaryDefinition": "to covet; to be accustomed"
    },
    "Keys": {
      "Hanzi": 6608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6608,
      "Radical": "犬",
      "FrequencyRank": 6861,
      "RadicalIndex": "94.4",
      "StrokeCount": 7
    }
  },
  "侃大山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎn dàshān",
      "PrimaryDefinition": "to chatter idly, to gossip, to boast or brag"
    },
    "Keys": {
      "Word": 7769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "stop"
    },
    "Keys": {
      "Hanzi": 109,
      "Radical": 77,
      "Word": 5354
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "77",
      "Grade": 6,
      "GeneralStandard": 109,
      "Radical": "止",
      "HSK": 3,
      "FrequencyRank": 596,
      "StrokeCount": 4
    }
  },
  "底线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐxiàn",
      "PrimaryDefinition": "to underline, bottom line, base line (in sports), baseline, minimum, spy, plant"
    },
    "Keys": {
      "Word": 6244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "查看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chákàn",
      "PrimaryDefinition": "to look over, to examine, to check up, to ferret out"
    },
    "Keys": {
      "Word": 4383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "舀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎo",
      "PrimaryDefinition": "dip, ladle; ladle"
    },
    "Keys": {
      "Hanzi": 1986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1986,
      "Radical": "臼",
      "FrequencyRank": 4302,
      "RadicalIndex": "134.4",
      "StrokeCount": 10
    }
  },
  "实践": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjiàn",
      "PrimaryDefinition": "practice"
    },
    "Keys": {
      "Word": 5023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "沆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàng",
      "PrimaryDefinition": "a ferry; fog; flowing"
    },
    "Keys": {
      "Hanzi": 3780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3780,
      "Radical": "水",
      "FrequencyRank": 5873,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "转向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn xiàng",
      "PrimaryDefinition": "to change direction, fig. to change one's stance, to get lost, to lose one's way"
    },
    "Keys": {
      "Word": 4266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "贵宾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guìbīn",
      "PrimaryDefinition": "honored guest, VIP, visiting fireman, visitant, honoured guest, distinguished guest, visiting_fireman, honored/distinguished guest, honored"
    },
    "Keys": {
      "Word": 6922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "膜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "membrane; to kneel and worship"
    },
    "Keys": {
      "Hanzi": 3132,
      "Word": 4877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.11",
      "Grade": 6,
      "GeneralStandard": 3132,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 1945,
      "StrokeCount": 14
    }
  },
  "贴近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiējìn",
      "PrimaryDefinition": "to press close to, to snuggle close, intimate"
    },
    "Keys": {
      "Word": 9511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7187,
      "Radical": "牛",
      "RadicalIndex": "93.7",
      "StrokeCount": 11
    }
  },
  "践": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "trample, tread upon, walk on"
    },
    "Keys": {
      "Hanzi": 2628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.5",
      "GeneralStandard": 2628,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 1578,
      "StrokeCount": 12
    }
  },
  "高价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāojià",
      "PrimaryDefinition": "high price"
    },
    "Keys": {
      "Word": 2490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "浮躁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúzào",
      "PrimaryDefinition": "fickle and impatient, restless, giddy, scatterbrained"
    },
    "Keys": {
      "Word": 6607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豆子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòuzi",
      "PrimaryDefinition": "bean, pea, CL:顆|颗[ke1]"
    },
    "Keys": {
      "Word": 6352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": "large stringed musical instrument; dignified, massive; sound of wind"
    },
    "Keys": {
      "Hanzi": 2825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2825,
      "Radical": "玉",
      "FrequencyRank": 1694,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "上瘾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng yǐn",
      "PrimaryDefinition": "to get into a habit, to become addicted"
    },
    "Keys": {
      "Word": 8985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "万万": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wànwàn",
      "PrimaryDefinition": "absolutely, wholly"
    },
    "Keys": {
      "Word": 9665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēn",
      "PrimaryDefinition": "treasure, valuables"
    },
    "Keys": {
      "Hanzi": 5055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5055,
      "Radical": "玉",
      "FrequencyRank": 3360,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "wind"
    },
    "Keys": {
      "Hanzi": 154,
      "Radical": 182,
      "Word": 109
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "182",
      "Grade": 1,
      "GeneralStandard": 154,
      "Radical": "風",
      "HSK": 1,
      "FrequencyRank": 348,
      "StrokeCount": 4
    }
  },
  "夜里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèli",
      "PrimaryDefinition": "during the night, at night, nighttime"
    },
    "Keys": {
      "Word": 1134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "殳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "weapon"
    },
    "Keys": {
      "Radical": 79,
      "Hanzi": 6505
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6505,
      "Radical": "殳",
      "FrequencyRank": 6005,
      "RadicalIndex": "79",
      "StrokeCount": 4
    }
  },
  "弟弟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìdi",
      "PrimaryDefinition": "brother, little brother, younger brother, cadet, little_brother, viscount"
    },
    "Keys": {
      "Word": 73
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "麻木": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mámù",
      "PrimaryDefinition": "numb, insensitive, apathetic"
    },
    "Keys": {
      "Word": 8176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "助理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùlǐ",
      "PrimaryDefinition": "aide, adjunct, coadjutant, assistant, ancillary, auxiliary"
    },
    "Keys": {
      "Word": 4253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "庼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7221
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7221,
      "Radical": "广",
      "RadicalIndex": "53.11",
      "StrokeCount": 11
    }
  },
  "卡通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎtōng",
      "PrimaryDefinition": "cartoon"
    },
    "Keys": {
      "Word": 7739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "改": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎi",
      "PrimaryDefinition": "change, alter; improve, remodel"
    },
    "Keys": {
      "Hanzi": 908,
      "Word": 666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.3",
      "Grade": 2,
      "GeneralStandard": 908,
      "Radical": "攴",
      "HSK": 2,
      "FrequencyRank": 350,
      "StrokeCount": 7
    }
  },
  "堡垒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎolěi",
      "PrimaryDefinition": "fort"
    },
    "Keys": {
      "Word": 5530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演播室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnbōshì",
      "PrimaryDefinition": "broadcasting studio"
    },
    "Keys": {
      "Word": 10198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "area, district, region, ward"
    },
    "Keys": {
      "Hanzi": 94,
      "Word": 1827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "23.2",
      "Grade": 3,
      "GeneralStandard": 94,
      "Radical": "匸",
      "HSK": 3,
      "FrequencyRank": 265,
      "StrokeCount": 4
    }
  },
  "胲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7032,
      "Radical": "肉",
      "FrequencyRank": 7338,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "谁知道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuí zhīdào",
      "PrimaryDefinition": "God knows..., Who would have imagined...?"
    },
    "Keys": {
      "Word": 9012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愿望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànwàng",
      "PrimaryDefinition": "wish, desire, pleasure, Eros, will, orexis, desirability, dream, wishfulness, mind, mirage, wistfulness, aspiration, ambition, hope"
    },
    "Keys": {
      "Word": 2123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "逯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "leave without reason; surname"
    },
    "Keys": {
      "Hanzi": 5010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5010,
      "Radical": "辵",
      "FrequencyRank": 5855,
      "RadicalIndex": "162.8",
      "StrokeCount": 11
    }
  },
  "恰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qià",
      "PrimaryDefinition": "just, exactly, precisely; proper"
    },
    "Keys": {
      "Hanzi": 1690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1690,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1308,
      "StrokeCount": 9
    }
  },
  "例外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìwài",
      "PrimaryDefinition": "excepted"
    },
    "Keys": {
      "Word": 3688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "超前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāoqián",
      "PrimaryDefinition": "to be ahead of one's time, to surpass or outdo one's predecessors, to be ahead of the pack, to take the lead, advanced"
    },
    "Keys": {
      "Word": 5829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "strong, unyielding, forceful"
    },
    "Keys": {
      "Hanzi": 5270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5270,
      "Radical": "辵",
      "FrequencyRank": 5596,
      "RadicalIndex": "162.9",
      "StrokeCount": 12
    }
  },
  "看得起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàndeqǐ",
      "PrimaryDefinition": "to show respect for, to think highly of"
    },
    "Keys": {
      "Word": 4787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "弸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "a bow stretched to the full; tensely drawn"
    },
    "Keys": {
      "Hanzi": 7258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7258,
      "Radical": "弓",
      "RadicalIndex": "57.8",
      "StrokeCount": 11
    }
  },
  "成人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngrén",
      "PrimaryDefinition": "grownup, adult, majority, manhood, grown-up"
    },
    "Keys": {
      "Word": 2312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xué",
      "PrimaryDefinition": "learning, knowledge; school"
    },
    "Keys": {
      "Hanzi": 1273,
      "Word": 416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "39.5",
      "Grade": 1,
      "GeneralStandard": 1273,
      "Radical": "子",
      "HSK": 1,
      "FrequencyRank": 66,
      "StrokeCount": 8
    }
  },
  "官员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānyuán",
      "PrimaryDefinition": "beg, official, mandarinate, administrative official, office-bearer, officer, bureaucrat, placeman, federal agent, government officials, agent, officialism, functionary, director, officeholder, officialdom, myrmidon"
    },
    "Keys": {
      "Word": 6879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "alone; without friends or relativ"
    },
    "Keys": {
      "Hanzi": 3871
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3871,
      "Radical": "艸",
      "FrequencyRank": 6194,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "随意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suí yì",
      "PrimaryDefinition": "as one pleases"
    },
    "Keys": {
      "Word": 3946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7042,
      "Radical": "疒",
      "FrequencyRank": 7626,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "脑袋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎodai",
      "PrimaryDefinition": "head"
    },
    "Keys": {
      "Word": 2758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "后续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuxù",
      "PrimaryDefinition": "follow up"
    },
    "Keys": {
      "Word": 7099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "period of time; date; time limit"
    },
    "Keys": {
      "Hanzi": 2542,
      "Word": 1799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "74.8",
      "Grade": 3,
      "GeneralStandard": 2542,
      "Radical": "月",
      "HSK": 1,
      "FrequencyRank": 253,
      "StrokeCount": 12
    }
  },
  "裢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "folding purse inserted in belt"
    },
    "Keys": {
      "Hanzi": 5305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5305,
      "Radical": "衣",
      "FrequencyRank": 5237,
      "RadicalIndex": "145.7",
      "StrokeCount": 12
    }
  },
  "绉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "crepe, crape, wrinkles, creases"
    },
    "Keys": {
      "Hanzi": 4079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4079,
      "Radical": "糸",
      "FrequencyRank": 4672,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "长辈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎngbèi",
      "PrimaryDefinition": "seniority, better, elder, superior, senior, elder member of family, eldership, elder generation, superordinate, higher-up"
    },
    "Keys": {
      "Word": 10655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嵬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "high; rugged, rocky, precipitous"
    },
    "Keys": {
      "Hanzi": 5170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5170,
      "Radical": "山",
      "FrequencyRank": 5510,
      "RadicalIndex": "46.1",
      "StrokeCount": 12
    }
  },
  "壮大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngdà",
      "PrimaryDefinition": "to expand, to strengthen"
    },
    "Keys": {
      "Word": 10908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "徛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "to cross over"
    },
    "Keys": {
      "Hanzi": 7201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7201,
      "Radical": "彳",
      "RadicalIndex": "60.8",
      "StrokeCount": 11
    }
  },
  "枵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "hollo stump of a tree; empty, thin"
    },
    "Keys": {
      "Hanzi": 4148
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4148,
      "Radical": "木",
      "FrequencyRank": 5686,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "鲨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "shark"
    },
    "Keys": {
      "Hanzi": 3294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "195.7",
      "GeneralStandard": 3294,
      "Radical": "魚",
      "HSK": 7,
      "FrequencyRank": 3664,
      "StrokeCount": 15
    }
  },
  "戽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "to bale out water"
    },
    "Keys": {
      "Hanzi": 4044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4044,
      "Radical": "戶",
      "FrequencyRank": 6351,
      "RadicalIndex": "63.4",
      "StrokeCount": 8
    }
  },
  "起码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐmǎ",
      "PrimaryDefinition": "at least"
    },
    "Keys": {
      "Word": 3797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "仅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "only, merely, solely, just"
    },
    "Keys": {
      "Hanzi": 136,
      "Word": 1628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "Grade": 3,
      "GeneralStandard": 136,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 494,
      "StrokeCount": 4
    }
  },
  "鸨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": "bustard; procuress; Otis species (various)"
    },
    "Keys": {
      "Hanzi": 4289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4289,
      "Radical": "鳥",
      "FrequencyRank": 5000,
      "RadicalIndex": "196.4",
      "StrokeCount": 9
    }
  },
  "饪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "cooked food; cook until well done"
    },
    "Keys": {
      "Hanzi": 3752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.4",
      "GeneralStandard": 3752,
      "Radical": "食",
      "HSK": 6,
      "FrequencyRank": 4491,
      "StrokeCount": 7
    }
  },
  "敬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "respect, honor; respectfully"
    },
    "Keys": {
      "Hanzi": 2553,
      "Word": 7640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.9",
      "Grade": 7,
      "GeneralStandard": 2553,
      "Radical": "攴",
      "HSK": 5,
      "FrequencyRank": 1209,
      "StrokeCount": 12
    }
  },
  "绽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "ripped seam, rend, crack"
    },
    "Keys": {
      "Hanzi": 2497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2497,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 3130,
      "StrokeCount": 11
    }
  },
  "玮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "type of jade; rare, valuable"
    },
    "Keys": {
      "Hanzi": 3826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3826,
      "Radical": "玉",
      "FrequencyRank": 5058,
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "现有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànyǒu",
      "PrimaryDefinition": "now available, existing"
    },
    "Keys": {
      "Word": 4034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "气派": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìpài",
      "PrimaryDefinition": "imposing manner or style"
    },
    "Keys": {
      "Word": 8624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "writing brush, pen, pencil"
    },
    "Keys": {
      "Hanzi": 3328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3328,
      "Radical": "羽",
      "FrequencyRank": 1881,
      "RadicalIndex": "124.1",
      "StrokeCount": 16
    }
  },
  "瑛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "luster of gem; crystal"
    },
    "Keys": {
      "Hanzi": 5046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5046,
      "Radical": "玉",
      "FrequencyRank": 2998,
      "RadicalIndex": "96.9",
      "StrokeCount": 12
    }
  },
  "浓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóng",
      "PrimaryDefinition": "thick, strong, concentrated"
    },
    "Keys": {
      "Hanzi": 1682,
      "Word": 2765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 4,
      "GeneralStandard": 1682,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1585,
      "StrokeCount": 9
    }
  },
  "颢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "luminous; white, hoary"
    },
    "Keys": {
      "Hanzi": 6330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6330,
      "Radical": "頁",
      "FrequencyRank": 5021,
      "RadicalIndex": "181.12",
      "StrokeCount": 18
    }
  },
  "锤子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuízi",
      "PrimaryDefinition": "hammer, CL:把[ba3]"
    },
    "Keys": {
      "Word": 6027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打印": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎyìn",
      "PrimaryDefinition": "to print, to seal, to stamp"
    },
    "Keys": {
      "Word": 584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "吏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "government official, magistrate"
    },
    "Keys": {
      "Hanzi": 381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "GeneralStandard": 381,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2311,
      "StrokeCount": 6
    }
  },
  "突击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūjī",
      "PrimaryDefinition": "sudden and violent attack, assault, fig. rushed job, concentrated effort to finish a job quickly"
    },
    "Keys": {
      "Word": 9576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罚款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fákuǎn",
      "PrimaryDefinition": "to fine, penalty, fine (monetary)"
    },
    "Keys": {
      "Word": 3448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "专注": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānzhù",
      "PrimaryDefinition": "be absorbed in"
    },
    "Keys": {
      "Word": 10885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnmiàn",
      "PrimaryDefinition": "reverse side, backside, the other side (of a problem etc), negative, bad"
    },
    "Keys": {
      "Word": 6474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "进入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnrù",
      "PrimaryDefinition": "go_into, get_in, debouch, get in, go into, move into, come_into, pass, step_in, lapse, get_into, set in, go in, get into, kick in, penetrate, intromission, pierce, come_in, come in, enter, entry, sink, impenetrate"
    },
    "Keys": {
      "Word": 788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "疗法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáofǎ",
      "PrimaryDefinition": "therapy, cure, treatment, therapeutics"
    },
    "Keys": {
      "Word": 8041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuò",
      "PrimaryDefinition": "person"
    },
    "Keys": {
      "Hanzi": 7264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7264,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 11
    }
  },
  "圃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "garden, cultivated field"
    },
    "Keys": {
      "Hanzi": 1906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1906,
      "Radical": "囗",
      "FrequencyRank": 3472,
      "RadicalIndex": "31.7",
      "StrokeCount": 10
    }
  },
  "值班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí bān",
      "PrimaryDefinition": "to work a shift, on duty"
    },
    "Keys": {
      "Word": 4223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "转折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnzhé",
      "PrimaryDefinition": "shift in the trend of events, turnaround, plot shift in a book, turn in the conversation"
    },
    "Keys": {
      "Word": 10896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揣摩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuǎimó",
      "PrimaryDefinition": "to analyze, to try to figure out, to try to fathom"
    },
    "Keys": {
      "Word": 5998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "now, here; this; time, year"
    },
    "Keys": {
      "Hanzi": 1653,
      "Word": 10940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.6",
      "Grade": 7,
      "GeneralStandard": 1653,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 1781,
      "StrokeCount": 9
    }
  },
  "机器人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīqìrén",
      "PrimaryDefinition": "mechanical person, robot, android"
    },
    "Keys": {
      "Word": 3572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "频繁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínfán",
      "PrimaryDefinition": "frequent, often"
    },
    "Keys": {
      "Word": 3775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng",
      "PrimaryDefinition": "think, speculate, plan, consider"
    },
    "Keys": {
      "Hanzi": 2867,
      "Word": 396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "Grade": 1,
      "GeneralStandard": 2867,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 99,
      "StrokeCount": 13
    }
  },
  "巽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "5th of the 8 trigrams; south-east; mild, modest, obedient"
    },
    "Keys": {
      "Hanzi": 5318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5318,
      "Radical": "己",
      "FrequencyRank": 5255,
      "RadicalIndex": "49.9",
      "StrokeCount": 12
    }
  },
  "能够": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nénggòu",
      "PrimaryDefinition": "can, able, would, may"
    },
    "Keys": {
      "Word": 889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "是非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìfēi",
      "PrimaryDefinition": "right and wrong, quarrel, dispute"
    },
    "Keys": {
      "Word": 9155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "目前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùqián",
      "PrimaryDefinition": "at the present time, currently"
    },
    "Keys": {
      "Word": 1754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "橦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 7847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7847,
      "Radical": "木",
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "与此同时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔcǐtóngshí",
      "PrimaryDefinition": "at the same time, meanwhile"
    },
    "Keys": {
      "Word": 10502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "考生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎoshēng",
      "PrimaryDefinition": "candidate for an entrance examination, examinee"
    },
    "Keys": {
      "Word": 807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "诚挚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngzhì",
      "PrimaryDefinition": "sincere, cordial"
    },
    "Keys": {
      "Word": 5879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià tái",
      "PrimaryDefinition": "fall out of power, leave office, step down from the stage/platform"
    },
    "Keys": {
      "Word": 9869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "oysters, mussels; mother-of-pearl"
    },
    "Keys": {
      "Hanzi": 1898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1898,
      "Radical": "虫",
      "FrequencyRank": 3947,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "说服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō fú",
      "PrimaryDefinition": "persuade, talk sb. over"
    },
    "Keys": {
      "Word": 2900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "烁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "shine, glitter, sparkle"
    },
    "Keys": {
      "Hanzi": 1657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "GeneralStandard": 1657,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 2702,
      "StrokeCount": 9
    }
  },
  "磋商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuōshāng",
      "PrimaryDefinition": "to consult, to discuss seriously, to negotiate, to confer, negotiations, consultations"
    },
    "Keys": {
      "Word": 6087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": "small round object; pellet, pill"
    },
    "Keys": {
      "Hanzi": 51,
      "Word": 9645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "3.2",
      "Grade": 7,
      "GeneralStandard": 51,
      "Radical": "丶",
      "HSK": 8,
      "FrequencyRank": 2462,
      "StrokeCount": 3
    }
  },
  "夊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": "go slowly"
    },
    "Keys": {
      "Radical": 35
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "箖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7691,
      "Radical": "竹",
      "FrequencyRank": 7172,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "靖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "pacify; appease; calm, peaceful"
    },
    "Keys": {
      "Hanzi": 2993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2993,
      "Radical": "青",
      "FrequencyRank": 2545,
      "RadicalIndex": "174.5",
      "StrokeCount": 13
    }
  },
  "咕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "mumble, mutter, murmur; rumble"
    },
    "Keys": {
      "Hanzi": 1083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1083,
      "Radical": "口",
      "FrequencyRank": 2418,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "供不应求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngbùyìngqiú",
      "PrimaryDefinition": "supply does not meet demand"
    },
    "Keys": {
      "Word": 6794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "test, try, experiment"
    },
    "Keys": {
      "Hanzi": 1286,
      "Word": 331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "Grade": 1,
      "GeneralStandard": 1286,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 643,
      "StrokeCount": 8
    }
  },
  "铃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "bell"
    },
    "Keys": {
      "Hanzi": 1933,
      "Word": 3702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "Grade": 5,
      "GeneralStandard": 1933,
      "Radical": "金",
      "HSK": 5,
      "FrequencyRank": 1969,
      "StrokeCount": 10
    }
  },
  "倭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "dwarf; dwarfish, short"
    },
    "Keys": {
      "Hanzi": 4570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4570,
      "Radical": "人",
      "FrequencyRank": 3390,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "兼顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāngù",
      "PrimaryDefinition": "take account of, give consideration to, deal with two or more things"
    },
    "Keys": {
      "Word": 7396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垃圾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lājī",
      "PrimaryDefinition": "leavings, crap, sordes, trumpery, culch, garbage, filth, debris, cultch, sweeping, trashery, raffle, slush, truck, detritus, rubbish, trash, dirt, muck, litter, offscouring, offal, rubble, recrement, dust, rejectamenta, refuse, junk, gook"
    },
    "Keys": {
      "Word": 2675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "念头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàntou",
      "PrimaryDefinition": "thing, purport, spirit, intention, idea, vision, notion, thought, train, intent"
    },
    "Keys": {
      "Word": 8418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuī",
      "PrimaryDefinition": "gimlet, awl, drill, auger; bore"
    },
    "Keys": {
      "Hanzi": 2941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2941,
      "Radical": "金",
      "FrequencyRank": 3064,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "无论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúlùn",
      "PrimaryDefinition": "conj.: no matter what/how/etc., regardless"
    },
    "Keys": {
      "Word": 2986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鬟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "dress hair in coiled knot; maid"
    },
    "Keys": {
      "Hanzi": 6477
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6477,
      "Radical": "髟",
      "FrequencyRank": 5902,
      "RadicalIndex": "190.13",
      "StrokeCount": 23
    }
  },
  "开始": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāishǐ",
      "PrimaryDefinition": "begin, start"
    },
    "Keys": {
      "Word": 1664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "得当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dédàng",
      "PrimaryDefinition": "appropriate, suitable"
    },
    "Keys": {
      "Word": 6208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "moat, trench, pit, cavity"
    },
    "Keys": {
      "Hanzi": 4808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4808,
      "Radical": "土",
      "FrequencyRank": 3420,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "芰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "water caltrop"
    },
    "Keys": {
      "Hanzi": 6569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6569,
      "Radical": "艸",
      "FrequencyRank": 5332,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "专职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānzhí",
      "PrimaryDefinition": "special duty, assigned full time to a task"
    },
    "Keys": {
      "Word": 10884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桥梁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáoliáng",
      "PrimaryDefinition": "bridge, fig. connection between two areas"
    },
    "Keys": {
      "Word": 4936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "清明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngmíng",
      "PrimaryDefinition": "Qingming or Pure Brightness, 5th of the 24 solar terms 二十四節氣|二十四节气[er4 shi2 si4 jie2 qi5] 5th-19th April, Pure Brightness Festival or Tomb Sweeping Day (in early April), clear and bright, sober and calm, (of a government or administration) well ordered"
    },
    "Keys": {
      "Word": 8749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出售": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūshòu",
      "PrimaryDefinition": "offer for sale, sell"
    },
    "Keys": {
      "Word": 2337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "实事求是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíshì-qiúshì",
      "PrimaryDefinition": "to seek truth from facts (idiom), to be practical and realistic"
    },
    "Keys": {
      "Word": 9116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姑娘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūniang",
      "PrimaryDefinition": "quiff, cummer, gill, calico, girlie, missy, moll, damsel, fille, girl, jill, miss, judy, jeune fille, sheila, jane, lass, colleen, toots, daughter, young girl, lassie"
    },
    "Keys": {
      "Word": 1511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "之下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīxià",
      "PrimaryDefinition": "under the condition of, under, beneath, below"
    },
    "Keys": {
      "Word": 4218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "轪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6585,
      "Radical": "車",
      "FrequencyRank": 7828,
      "RadicalIndex": "159.3",
      "StrokeCount": 7
    }
  },
  "如醉如痴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúzuì-rúchī",
      "PrimaryDefinition": "lit. as if drunk and stupefied (idiom), intoxicated by sth, obsessed with, mad about sth, also written 如癡如醉|如痴如醉[ru2 chi1 ru2 zui4]"
    },
    "Keys": {
      "Word": 8899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "a parapet on a city wall"
    },
    "Keys": {
      "Hanzi": 7085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7085,
      "Radical": "阜",
      "FrequencyRank": 6750,
      "RadicalIndex": "170.8",
      "StrokeCount": 10
    }
  },
  "规划": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīhuà",
      "PrimaryDefinition": "plan, program"
    },
    "Keys": {
      "Word": 3523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "雄伟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xióngwěi",
      "PrimaryDefinition": "grand, imposing, magnificent, majestic"
    },
    "Keys": {
      "Word": 4070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "赋予": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùyǔ",
      "PrimaryDefinition": "indue, endow, lend, girt, gift, endue, entrust, inspire, give, impart, confer, grant"
    },
    "Keys": {
      "Word": 6640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěài",
      "PrimaryDefinition": "lovable, likeable, lovely"
    },
    "Keys": {
      "Word": 812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "得到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé dào",
      "PrimaryDefinition": "to get, to obtain, to receive"
    },
    "Keys": {
      "Word": 64
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "事故": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìgù",
      "PrimaryDefinition": "circumstance, trouble, mischance, accident, mishap, affair, bad luck, natural event, happening, occurrence"
    },
    "Keys": {
      "Word": 1911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "大笔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàbǐ",
      "PrimaryDefinition": "Large"
    },
    "Keys": {
      "Word": 6111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "模范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mófàn",
      "PrimaryDefinition": "model, fine example"
    },
    "Keys": {
      "Word": 3730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "服饰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúshì",
      "PrimaryDefinition": "neckline, attire, furnishings, costume, dress, trappings, finery, rig-out, apparel, dress and personal adornment"
    },
    "Keys": {
      "Word": 6600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "示意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyì",
      "PrimaryDefinition": "to hint, to indicate (an idea to sb)"
    },
    "Keys": {
      "Word": 9129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "snail"
    },
    "Keys": {
      "Hanzi": 5926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5926,
      "Radical": "虫",
      "FrequencyRank": 6412,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "愁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "anxiety; to worry about, be anxious"
    },
    "Keys": {
      "Hanzi": 2951,
      "Word": 3338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "Grade": 5,
      "GeneralStandard": 2951,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 2113,
      "StrokeCount": 13
    }
  },
  "戮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "kill, massacre; oppress"
    },
    "Keys": {
      "Hanzi": 6019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6019,
      "Radical": "戈",
      "FrequencyRank": 3318,
      "RadicalIndex": "62.11",
      "StrokeCount": 15
    }
  },
  "蠢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔn",
      "PrimaryDefinition": "wriggle; stupid; silly; fat"
    },
    "Keys": {
      "Hanzi": 3488,
      "Word": 6032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.15",
      "Grade": 7,
      "GeneralStandard": 3488,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2195,
      "StrokeCount": 21
    }
  },
  "洪水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngshuǐ",
      "PrimaryDefinition": "deluge, flood"
    },
    "Keys": {
      "Word": 4667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "谜底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mídǐ",
      "PrimaryDefinition": "answer to a riddle"
    },
    "Keys": {
      "Word": 8262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "regret, be sorry; alarmed"
    },
    "Keys": {
      "Hanzi": 2448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2448,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 3592,
      "StrokeCount": 11
    }
  },
  "禾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "grain"
    },
    "Keys": {
      "Radical": 115,
      "Hanzi": 267
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115",
      "GeneralStandard": 267,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 3587,
      "StrokeCount": 5
    }
  },
  "电台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàntái",
      "PrimaryDefinition": "transceiver, transmitter-receiver, broadcasting station"
    },
    "Keys": {
      "Word": 1407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "箨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuò",
      "PrimaryDefinition": "bamboo sheath, bamboo shoots"
    },
    "Keys": {
      "Hanzi": 7690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7690,
      "Radical": "竹",
      "FrequencyRank": 6343,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "装扮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngbàn",
      "PrimaryDefinition": "to decorate, to adorn, to dress up, to disguise oneself"
    },
    "Keys": {
      "Word": 10907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "立方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìfāng",
      "PrimaryDefinition": "cube (math.), abbr. for 立方體|立方体[li4 fang1 ti3], abbr. for 立方米[li4 fang1 mi3]"
    },
    "Keys": {
      "Word": 8000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "京": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "capital city"
    },
    "Keys": {
      "Hanzi": 1205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "8.6",
      "GeneralStandard": 1205,
      "Radical": "亠",
      "HSK": 1,
      "FrequencyRank": 566,
      "StrokeCount": 8
    }
  },
  "滔滔不绝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāotāo-bùjué",
      "PrimaryDefinition": "unceasing torrent (idiom), talking non-stop, gabbling forty to the dozen"
    },
    "Keys": {
      "Word": 9431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敬请": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngqǐng",
      "PrimaryDefinition": "please (do sth) (deferential form)"
    },
    "Keys": {
      "Word": 7646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒu",
      "PrimaryDefinition": "lotus root"
    },
    "Keys": {
      "Hanzi": 3437
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3437,
      "Radical": "艸",
      "FrequencyRank": 3920,
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "防御": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángyù",
      "PrimaryDefinition": "defense, to defend"
    },
    "Keys": {
      "Word": 6494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "建设": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànshè",
      "PrimaryDefinition": "construction"
    },
    "Keys": {
      "Word": 1603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "北边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běibian",
      "PrimaryDefinition": "north, north side, northern part, to the north of"
    },
    "Keys": {
      "Word": 19
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "裁定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáidìng",
      "PrimaryDefinition": "ruling"
    },
    "Keys": {
      "Word": 5741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不已": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyǐ",
      "PrimaryDefinition": "incessantly, endlessly"
    },
    "Keys": {
      "Word": 5716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "plant louse, aphids"
    },
    "Keys": {
      "Hanzi": 4508
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4508,
      "Radical": "虫",
      "FrequencyRank": 4415,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "倪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "feeble, tiny, young and weak"
    },
    "Keys": {
      "Hanzi": 4571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4571,
      "Radical": "人",
      "FrequencyRank": 3257,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "青春期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngchūnqī",
      "PrimaryDefinition": "puberty, adolescence"
    },
    "Keys": {
      "Word": 8732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帖子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiězi",
      "PrimaryDefinition": "card, invitation, message, (forum) post"
    },
    "Keys": {
      "Word": 9510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngyuē",
      "PrimaryDefinition": "to agree (on a meeting place, date etc), to reach agreement, to make an appointment"
    },
    "Keys": {
      "Word": 9930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìn",
      "PrimaryDefinition": "press"
    },
    "Keys": {
      "Hanzi": 5069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5069,
      "Radical": "手",
      "FrequencyRank": 4836,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "下棋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià qí",
      "PrimaryDefinition": "to play chess"
    },
    "Keys": {
      "Word": 9864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "临床": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línchuáng",
      "PrimaryDefinition": "clinical"
    },
    "Keys": {
      "Word": 8062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "a bamboo basket for carrying earth"
    },
    "Keys": {
      "Hanzi": 5956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5956,
      "Radical": "竹",
      "FrequencyRank": 5192,
      "RadicalIndex": "118.9",
      "StrokeCount": 15
    }
  },
  "男女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánnǚ",
      "PrimaryDefinition": "men and women"
    },
    "Keys": {
      "Word": 2754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "牛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niú",
      "PrimaryDefinition": "cow"
    },
    "Keys": {
      "Hanzi": 120,
      "Radical": 93,
      "Word": 3750
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "93",
      "Grade": 5,
      "GeneralStandard": 120,
      "Radical": "牛",
      "HSK": 1,
      "FrequencyRank": 1018,
      "StrokeCount": 4
    }
  },
  "觖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "to long for; dissatisfied to criticize"
    },
    "Keys": {
      "Hanzi": 7218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7218,
      "Radical": "角",
      "FrequencyRank": 7451,
      "RadicalIndex": "148.4",
      "StrokeCount": 11
    }
  },
  "挟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "clasp under arm; hold to bosom"
    },
    "Keys": {
      "Hanzi": 1374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "GeneralStandard": 1374,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2816,
      "StrokeCount": 9
    }
  },
  "吮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔn",
      "PrimaryDefinition": "suck with mouth, sip, lick"
    },
    "Keys": {
      "Hanzi": 752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 752,
      "Radical": "口",
      "FrequencyRank": 3699,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "细微": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìwēi",
      "PrimaryDefinition": "tiny, minute, fine, subtle, sensitive (instruments)"
    },
    "Keys": {
      "Word": 9845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yītǐ",
      "PrimaryDefinition": "an organic/integral whole, all concerned"
    },
    "Keys": {
      "Word": 10342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "foot path between rice fields"
    },
    "Keys": {
      "Hanzi": 1318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.6",
      "GeneralStandard": 1318,
      "Radical": "阜",
      "HSK": 7,
      "FrequencyRank": 2243,
      "StrokeCount": 8
    }
  },
  "尊贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnguì",
      "PrimaryDefinition": "respected, respectable, honorable"
    },
    "Keys": {
      "Word": 11020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燕子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànzi",
      "PrimaryDefinition": "barn_swallow, swallow"
    },
    "Keys": {
      "Word": 10214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔn",
      "PrimaryDefinition": "meet"
    },
    "Keys": {
      "Hanzi": 7777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7777,
      "Radical": "口",
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "柴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chái",
      "PrimaryDefinition": "firewood, faggots, fuel"
    },
    "Keys": {
      "Hanzi": 1880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1880,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 1981,
      "StrokeCount": 10
    }
  },
  "妨碍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángài",
      "PrimaryDefinition": "preclude, clog, set_back, prevent, trammel, arrest, counteract, set back, impede, foul, hamper, discourage, thwart, obstruction, impediment, stymie, hinder, deter, debar, halter, intercept, interfere, retard, jam, detain, spoke, hobble, resist, balk, countercheck, stymy, blockade, stonewall, stop, beset, embarrass, hamstring, forbid, kibosh, handicap, block, prohibit, snag, fox, cramp, encumb"
    },
    "Keys": {
      "Word": 6495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "别人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biéren",
      "PrimaryDefinition": "others, other people, another person"
    },
    "Keys": {
      "Word": 26
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "砬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lá",
      "PrimaryDefinition": "big stone"
    },
    "Keys": {
      "Hanzi": 6971
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6971,
      "Radical": "石",
      "FrequencyRank": 6038,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "模型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móxíng",
      "PrimaryDefinition": "formula, pattern, mould, mold, modeling, construct, die, convention, exponent, plaything, former, modelling, model, simulation, normal, rule, moulding, molding, shape, matrix, clay sculpture, restoration, toy"
    },
    "Keys": {
      "Word": 2750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "勤劳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínláo",
      "PrimaryDefinition": "hardworking, ply, industrious"
    },
    "Keys": {
      "Word": 8729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稍稍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāoshāo",
      "PrimaryDefinition": "somewhat, a little, slightly"
    },
    "Keys": {
      "Word": 8994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōng chē",
      "PrimaryDefinition": "be open to traffic, have transport service"
    },
    "Keys": {
      "Word": 9521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "派": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pài",
      "PrimaryDefinition": "school of thought, sect, branch"
    },
    "Keys": {
      "Hanzi": 1673,
      "Word": 1782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 3,
      "GeneralStandard": 1673,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 532,
      "StrokeCount": 9
    }
  },
  "勔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "endeavor, make effort; urge"
    },
    "Keys": {
      "Hanzi": 7151
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7151,
      "Radical": "力",
      "RadicalIndex": "19.9",
      "StrokeCount": 11
    }
  },
  "以及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐjí",
      "PrimaryDefinition": "conj.: as well as, along with, and"
    },
    "Keys": {
      "Word": 3096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "申请": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnqǐng",
      "PrimaryDefinition": "apply for"
    },
    "Keys": {
      "Word": 2847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "爱心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àixīn",
      "PrimaryDefinition": "liking, pity, love, benevolence, mercy, fondness, commitment"
    },
    "Keys": {
      "Word": 1261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "潴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "pond; a pool"
    },
    "Keys": {
      "Hanzi": 5806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5806,
      "Radical": "水",
      "FrequencyRank": 5367,
      "RadicalIndex": "85.12",
      "StrokeCount": 14
    }
  },
  "知名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīmíng",
      "PrimaryDefinition": "noted, famous"
    },
    "Keys": {
      "Word": 5349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "传达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuándá",
      "PrimaryDefinition": "pass on, transmit, communicate"
    },
    "Keys": {
      "Word": 3351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "誓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "swear, pledge; oath"
    },
    "Keys": {
      "Hanzi": 3060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "GeneralStandard": 3060,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2095,
      "StrokeCount": 14
    }
  },
  "浊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "muddy, turbid, dirty, filthy"
    },
    "Keys": {
      "Hanzi": 1668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1668,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2985,
      "StrokeCount": 9
    }
  },
  "制造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìzào",
      "PrimaryDefinition": "make, manufacture, engineer, create, fabricate"
    },
    "Keys": {
      "Word": 2179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "砉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6755,
      "Radical": "石",
      "FrequencyRank": 5216,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "为难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéinán",
      "PrimaryDefinition": "disoblige, feel awkward, make things difficult for, create difficulties, feel embarrassed, embarrass, create difficulties (for sb.), be in a quandary, snarl, torment, bewilder, ride, bewildering"
    },
    "Keys": {
      "Word": 3996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "毖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "guard against, take care; caution"
    },
    "Keys": {
      "Hanzi": 4184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4184,
      "Radical": "比",
      "FrequencyRank": 5054,
      "RadicalIndex": "81.5",
      "StrokeCount": 9
    }
  },
  "拘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "restrain, seize, detain"
    },
    "Keys": {
      "Hanzi": 976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 976,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2098,
      "StrokeCount": 8
    }
  },
  "开采": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāicǎi",
      "PrimaryDefinition": "mine, extract, exploit"
    },
    "Keys": {
      "Word": 7741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7797,
      "Radical": "舟",
      "FrequencyRank": 5560,
      "RadicalIndex": "137.9",
      "StrokeCount": 15
    }
  },
  "庾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "granary; storehouse"
    },
    "Keys": {
      "Hanzi": 4937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4937,
      "Radical": "广",
      "FrequencyRank": 4997,
      "RadicalIndex": "53.9",
      "StrokeCount": 11
    }
  },
  "词汇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíhuì",
      "PrimaryDefinition": "mental lexicon, vocabulary, wordbook, lexicon, word-stock, lexis, words and phrases"
    },
    "Keys": {
      "Word": 2347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "组织": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔzhī",
      "PrimaryDefinition": "tissue, nerve, weave, organization, organized system"
    },
    "Keys": {
      "Word": 4283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "打造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎzào",
      "PrimaryDefinition": "to create, to build, to develop, to forge (of metal)"
    },
    "Keys": {
      "Word": 4473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "night"
    },
    "Keys": {
      "Radical": 36,
      "Hanzi": 46
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "36",
      "GeneralStandard": 46,
      "Radical": "夕",
      "HSK": 5,
      "FrequencyRank": 2003,
      "StrokeCount": 3
    }
  },
  "陵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "hill, mound; mausoleum"
    },
    "Keys": {
      "Hanzi": 2135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.8",
      "GeneralStandard": 2135,
      "Radical": "阜",
      "HSK": 9,
      "FrequencyRank": 1965,
      "StrokeCount": 10
    }
  },
  "啖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "eat, feed; chew, bite; entice"
    },
    "Keys": {
      "Hanzi": 4848
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4848,
      "Radical": "口",
      "FrequencyRank": 5479,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "基因": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīyīn",
      "PrimaryDefinition": "gene"
    },
    "Keys": {
      "Word": 7275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "放松": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngsōng",
      "PrimaryDefinition": "relax, slacken, loosen"
    },
    "Keys": {
      "Word": 2461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "槜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7651
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7651,
      "Radical": "木",
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "物质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùzhì",
      "PrimaryDefinition": "stuff, material, substance, materiality, matter"
    },
    "Keys": {
      "Word": 4022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "噔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng",
      "PrimaryDefinition": "syllable; (Cant.) for (a recipient of pity or sympathy)"
    },
    "Keys": {
      "Hanzi": 5937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5937,
      "Radical": "口",
      "FrequencyRank": 4726,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "兕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "a female rhinoceros"
    },
    "Keys": {
      "Hanzi": 3718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3718,
      "Radical": "儿",
      "FrequencyRank": 6937,
      "RadicalIndex": "10.6",
      "StrokeCount": 7
    }
  },
  "得失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "déshī",
      "PrimaryDefinition": "gains and losses, success and failure, merits and demerits"
    },
    "Keys": {
      "Word": 6210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèn",
      "PrimaryDefinition": "foolish, stupid, dull; awkward"
    },
    "Keys": {
      "Hanzi": 2317,
      "Word": 2262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.5",
      "Grade": 4,
      "GeneralStandard": 2317,
      "Radical": "竹",
      "HSK": 4,
      "FrequencyRank": 2323,
      "StrokeCount": 11
    }
  },
  "涵义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hányì",
      "PrimaryDefinition": "content, meaning, connotation, implication"
    },
    "Keys": {
      "Word": 6984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gá",
      "PrimaryDefinition": "used in transliterations"
    },
    "Keys": {
      "Hanzi": 5907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5907,
      "Radical": "口",
      "FrequencyRank": 3644,
      "RadicalIndex": "30.13",
      "StrokeCount": 15
    }
  },
  "袖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "sleeve; put something in sleeve"
    },
    "Keys": {
      "Hanzi": 2116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.5",
      "GeneralStandard": 2116,
      "Radical": "衣",
      "HSK": 6,
      "FrequencyRank": 1686,
      "StrokeCount": 10
    }
  },
  "解救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiějiù",
      "PrimaryDefinition": "to rescue, to help out of difficulties, to save the situation"
    },
    "Keys": {
      "Word": 7536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuán",
      "PrimaryDefinition": "jadelike precious stone; jade-colored"
    },
    "Keys": {
      "Hanzi": 6763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6763,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "疑点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yídiǎn",
      "PrimaryDefinition": "a doubtful point"
    },
    "Keys": {
      "Word": 10309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "囗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "enclosure"
    },
    "Keys": {
      "Radical": 31
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "爱护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àihù",
      "PrimaryDefinition": "cherish, treasure, take good care of"
    },
    "Keys": {
      "Word": 2231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "此次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐcì",
      "PrimaryDefinition": "this time"
    },
    "Keys": {
      "Word": 4454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "试行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìxíng",
      "PrimaryDefinition": "to try out, to test"
    },
    "Keys": {
      "Word": 9146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "begin, commence, originate"
    },
    "Keys": {
      "Hanzi": 3178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "129.8",
      "GeneralStandard": 3178,
      "Radical": "聿",
      "HSK": 8,
      "FrequencyRank": 3206,
      "StrokeCount": 14
    }
  },
  "汤圆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāngyuán",
      "PrimaryDefinition": "boiled balls of glutinous rice flour, eaten during the Lantern Festival"
    },
    "Keys": {
      "Word": 9422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顶多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐngduō",
      "PrimaryDefinition": "at most, at best"
    },
    "Keys": {
      "Word": 6302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "社交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèjiāo",
      "PrimaryDefinition": "social_intercourse, converse, social interaction, social contact, interaction, social contact/interaction, coterie, sociality, commerce"
    },
    "Keys": {
      "Word": 9007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "澈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "thoroughly, completely"
    },
    "Keys": {
      "Hanzi": 3297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 3297,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 3365,
      "StrokeCount": 15
    }
  },
  "一代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdài",
      "PrimaryDefinition": "generation"
    },
    "Keys": {
      "Word": 5233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "跟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēn",
      "PrimaryDefinition": "heel; to follow, accompany; with"
    },
    "Keys": {
      "Hanzi": 2920,
      "Word": 120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.6",
      "Grade": 1,
      "GeneralStandard": 2920,
      "Radical": "足",
      "HSK": 1,
      "FrequencyRank": 541,
      "StrokeCount": 13
    }
  },
  "白色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báisè",
      "PrimaryDefinition": "White, white, whiteness, White (as symbol of anticommunism), white (color)"
    },
    "Keys": {
      "Word": 507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "乌云": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūyún",
      "PrimaryDefinition": "black cloud"
    },
    "Keys": {
      "Word": 5146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "干杯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān bēi",
      "PrimaryDefinition": "to drink a toast, Cheers! (proposing a toast), Here's to you!, Bottoms up!, lit. dry cup"
    },
    "Keys": {
      "Word": 671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "伊斯兰教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yīsīlánjiào",
      "PrimaryDefinition": "Islam"
    },
    "Keys": {
      "Word": 10275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7920
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7920,
      "Radical": "玉",
      "RadicalIndex": "96.13",
      "StrokeCount": 17
    }
  },
  "戏曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìqǔ",
      "PrimaryDefinition": "traditional opera, drama, trad. opera, singing parts in ^chuan2 qi2 ^ and ^za2, singing parts in ^chuan2qi2^ and ^za2ju4^"
    },
    "Keys": {
      "Word": 5157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "擤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐng",
      "PrimaryDefinition": "to blow the nose with fingers"
    },
    "Keys": {
      "Hanzi": 6200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6200,
      "Radical": "手",
      "FrequencyRank": 5361,
      "RadicalIndex": "64.14",
      "StrokeCount": 17
    }
  },
  "猎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "hunt; field sports"
    },
    "Keys": {
      "Hanzi": 2366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.8",
      "GeneralStandard": 2366,
      "Radical": "犬",
      "HSK": 7,
      "FrequencyRank": 1687,
      "StrokeCount": 11
    }
  },
  "襞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "fold, pleat, crease"
    },
    "Keys": {
      "Hanzi": 6417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6417,
      "Radical": "衣",
      "FrequencyRank": 6470,
      "RadicalIndex": "145.13",
      "StrokeCount": 19
    }
  },
  "济": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "help, aid, relieve; ferry, cross"
    },
    "Keys": {
      "Hanzi": 1678
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1678,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 360,
      "StrokeCount": 9
    }
  },
  "过节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò jié",
      "PrimaryDefinition": "to celebrate a festival, after the celebrations (i.e. once the festival is over)"
    },
    "Keys": {
      "Word": 6947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "控告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kònggào",
      "PrimaryDefinition": "charge, accuse"
    },
    "Keys": {
      "Word": 7833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "专人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānrén",
      "PrimaryDefinition": "person specially assigned for a task/job"
    },
    "Keys": {
      "Word": 10882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "补偿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔcháng",
      "PrimaryDefinition": "compensate, make up"
    },
    "Keys": {
      "Word": 3273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "艹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎo",
      "PrimaryDefinition": "grass"
    },
    "Keys": {
      "Radical": 140
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "品牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐnpái",
      "PrimaryDefinition": "brand, make, trademark"
    },
    "Keys": {
      "Word": 4906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "乸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎ",
      "PrimaryDefinition": "(Cant.) feminine suffix"
    },
    "Keys": {
      "Hanzi": 6741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6741,
      "Radical": "毋",
      "RadicalIndex": "80.3",
      "StrokeCount": 8
    }
  },
  "高调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāodiào",
      "PrimaryDefinition": "high-sounding speech, bombast, high-profile"
    },
    "Keys": {
      "Word": 6695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "happy, joyous, delighted"
    },
    "Keys": {
      "Hanzi": 1158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.4",
      "GeneralStandard": 1158,
      "Radical": "欠",
      "HSK": 5,
      "FrequencyRank": 1523,
      "StrokeCount": 8
    }
  },
  "炎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "flame, blaze; hot"
    },
    "Keys": {
      "Hanzi": 1237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.4",
      "GeneralStandard": 1237,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 1324,
      "StrokeCount": 8
    }
  },
  "怆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàng",
      "PrimaryDefinition": "sad, broken-hearted, disconsolate"
    },
    "Keys": {
      "Hanzi": 3791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3791,
      "Radical": "心",
      "FrequencyRank": 4096,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "瓠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "bottle gourd; calabash; pot"
    },
    "Keys": {
      "Hanzi": 4798
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4798,
      "Radical": "瓜",
      "FrequencyRank": 5820,
      "RadicalIndex": "97.6",
      "StrokeCount": 11
    }
  },
  "臊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāo",
      "PrimaryDefinition": "rank; rancid; frowzy, fetid; bashful"
    },
    "Keys": {
      "Hanzi": 3423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.13",
      "GeneralStandard": 3423,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 4389,
      "StrokeCount": 17
    }
  },
  "体力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐlì",
      "PrimaryDefinition": "breath, brawn, strength, bottom, main, thews, stamina, vigor, energy, physical strength, vigour, vim, physical power, strength of body, vitality, physical power/strength, thew, muscle, sinew"
    },
    "Keys": {
      "Word": 3978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "玫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "rose"
    },
    "Keys": {
      "Hanzi": 950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.4",
      "GeneralStandard": 950,
      "Radical": "玉",
      "HSK": 7,
      "FrequencyRank": 2303,
      "StrokeCount": 8
    }
  },
  "慥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "sincere, earnest"
    },
    "Keys": {
      "Hanzi": 7599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7599,
      "Radical": "心",
      "RadicalIndex": "61.11",
      "StrokeCount": 13
    }
  },
  "有待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒudài",
      "PrimaryDefinition": "be pending, await, remain (to be done), remain"
    },
    "Keys": {
      "Word": 10472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "未必": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèibì",
      "PrimaryDefinition": "may_not, not necessarily, may not"
    },
    "Keys": {
      "Word": 2975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "陶冶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táoyě",
      "PrimaryDefinition": "mold/shape character/taste/etc."
    },
    "Keys": {
      "Word": 9435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "防汛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángxùn",
      "PrimaryDefinition": "flood control, anti-flood (precautions)"
    },
    "Keys": {
      "Word": 6492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "质朴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìpǔ",
      "PrimaryDefinition": "simple, plain, unadorned, unaffected, unsophisticated, rustic, earthy"
    },
    "Keys": {
      "Word": 10791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "coccyx"
    },
    "Keys": {
      "Hanzi": 5731
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5731,
      "Radical": "骨",
      "FrequencyRank": 4811,
      "RadicalIndex": "188.5",
      "StrokeCount": 14
    }
  },
  "晚点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn diǎn",
      "PrimaryDefinition": "(of trains etc) late, delayed, behind schedule, light dinner"
    },
    "Keys": {
      "Word": 2962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "腿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǐ",
      "PrimaryDefinition": "legs, thighs"
    },
    "Keys": {
      "Hanzi": 2976,
      "Word": 1042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.1",
      "Grade": 2,
      "GeneralStandard": 2976,
      "Radical": "肉",
      "HSK": 2,
      "FrequencyRank": 1351,
      "StrokeCount": 13
    }
  },
  "偏见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānjiàn",
      "PrimaryDefinition": "prejudice"
    },
    "Keys": {
      "Word": 8519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喜事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐshì",
      "PrimaryDefinition": "happy occasion, wedding"
    },
    "Keys": {
      "Word": 9840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彩虹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎihóng",
      "PrimaryDefinition": "rainbow, CL:道[dao4]"
    },
    "Keys": {
      "Word": 5745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "监": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "supervise, control, direct"
    },
    "Keys": {
      "Hanzi": 1883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "108.5",
      "GeneralStandard": 1883,
      "Radical": "皿",
      "HSK": 6,
      "FrequencyRank": 838,
      "StrokeCount": 10
    }
  },
  "咔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kā",
      "PrimaryDefinition": "used in translation"
    },
    "Keys": {
      "Hanzi": 3908
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3908,
      "Radical": "口",
      "FrequencyRank": 3754,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "密集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìjí",
      "PrimaryDefinition": "concentrated, crowded together"
    },
    "Keys": {
      "Word": 8272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "high, lofty, noble; steep, perilous"
    },
    "Keys": {
      "Hanzi": 4237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4237,
      "Radical": "山",
      "FrequencyRank": 4958,
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "违约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi yuē",
      "PrimaryDefinition": "to break a promise, to violate an agreement, to default (on a loan or contract)"
    },
    "Keys": {
      "Word": 9696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "错位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò wèi",
      "PrimaryDefinition": "to misplace, displacement (e.g. of broken bones), out of alignment, faulty contact, erroneous judgment, inversion (medical, e.g. breach delivery)"
    },
    "Keys": {
      "Word": 6091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "部位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùwèi",
      "PrimaryDefinition": "segment, part, capsule, aspect, position, region, situs, place"
    },
    "Keys": {
      "Word": 3286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "绱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng",
      "PrimaryDefinition": "the piece of leather used for soles on Chinese shoes; a patch"
    },
    "Keys": {
      "Hanzi": 5031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5031,
      "Radical": "糸",
      "FrequencyRank": 5179,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "鄀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "state in Henan province"
    },
    "Keys": {
      "Hanzi": 6952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6952,
      "Radical": "邑",
      "RadicalIndex": "163.9",
      "StrokeCount": 10
    }
  },
  "设想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèxiǎng",
      "PrimaryDefinition": "imagine, assume, have consideration for"
    },
    "Keys": {
      "Word": 3873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蚂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎ",
      "PrimaryDefinition": "ant; leech"
    },
    "Keys": {
      "Hanzi": 1501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.3",
      "GeneralStandard": 1501,
      "Radical": "虫",
      "HSK": 6,
      "FrequencyRank": 2888,
      "StrokeCount": 9
    }
  },
  "鬃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "mane; neck bristles"
    },
    "Keys": {
      "Hanzi": 6313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6313,
      "Radical": "髟",
      "FrequencyRank": 4327,
      "RadicalIndex": "190.8",
      "StrokeCount": 18
    }
  },
  "垛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒ",
      "PrimaryDefinition": "heap, pile; pile up, heap up"
    },
    "Keys": {
      "Hanzi": 1388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1388,
      "Radical": "土",
      "FrequencyRank": 4009,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "蚍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "mussels; various shellfish"
    },
    "Keys": {
      "Hanzi": 4509
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4509,
      "Radical": "虫",
      "FrequencyRank": 5945,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "姤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "mate; copulate; good"
    },
    "Keys": {
      "Hanzi": 6913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6913,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "埋伏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máifu",
      "PrimaryDefinition": "to ambush, to lie in wait for, to lie low, ambush"
    },
    "Keys": {
      "Word": 8187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光环": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānghuán",
      "PrimaryDefinition": "halo, ring of light"
    },
    "Keys": {
      "Word": 6902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "在线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàixiàn",
      "PrimaryDefinition": "online"
    },
    "Keys": {
      "Word": 10591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "护士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùshi",
      "PrimaryDefinition": "graduate nurse, nana, nurse, sister, trained nurse, (hospital) nurse"
    },
    "Keys": {
      "Word": 2552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "豨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "pig, hog"
    },
    "Keys": {
      "Hanzi": 5682
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5682,
      "Radical": "豕",
      "FrequencyRank": 6603,
      "RadicalIndex": "152.7",
      "StrokeCount": 14
    }
  },
  "忽然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūrán",
      "PrimaryDefinition": "suddenly"
    },
    "Keys": {
      "Word": 722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "风力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnglì",
      "PrimaryDefinition": "wind force, wind power"
    },
    "Keys": {
      "Word": 6570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沮丧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔsàng",
      "PrimaryDefinition": "depressed, disheartened"
    },
    "Keys": {
      "Word": 7686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "county in Hebei province"
    },
    "Keys": {
      "Hanzi": 4636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4636,
      "Radical": "邑",
      "FrequencyRank": 4045,
      "RadicalIndex": "163.8",
      "StrokeCount": 10
    }
  },
  "如同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rútóng",
      "PrimaryDefinition": "like, similar to"
    },
    "Keys": {
      "Word": 3850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "书桌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūzhuō",
      "PrimaryDefinition": "desk, CL:張|张[zhang1]"
    },
    "Keys": {
      "Word": 3921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "痒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎng",
      "PrimaryDefinition": "itch"
    },
    "Keys": {
      "Hanzi": 2382,
      "Word": 10227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.6",
      "Grade": 7,
      "GeneralStandard": 2382,
      "Radical": "疒",
      "HSK": 7,
      "FrequencyRank": 2646,
      "StrokeCount": 11
    }
  },
  "鳍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "fin"
    },
    "Keys": {
      "Hanzi": 3446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3446,
      "Radical": "魚",
      "FrequencyRank": 2801,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "侗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "big; ignorant; rude, rustic"
    },
    "Keys": {
      "Hanzi": 3957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3957,
      "Radical": "人",
      "FrequencyRank": 4406,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "为什么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèishénme",
      "PrimaryDefinition": "why, why?, how come, why is it that?, whereat, whereto"
    },
    "Keys": {
      "Word": 1059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "飧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūn",
      "PrimaryDefinition": "evening meal, supper; cooked food"
    },
    "Keys": {
      "Hanzi": 5247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5247,
      "Radical": "食",
      "FrequencyRank": 4494,
      "RadicalIndex": "184.3",
      "StrokeCount": 12
    }
  },
  "退休金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuìxiūjīn",
      "PrimaryDefinition": "retirement pay, pension"
    },
    "Keys": {
      "Word": 9608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "crow, rook, raven; black, dark"
    },
    "Keys": {
      "Hanzi": 157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.3",
      "GeneralStandard": 157,
      "Radical": "丿",
      "HSK": 6,
      "FrequencyRank": 1244,
      "StrokeCount": 4
    }
  },
  "怠慢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàimàn",
      "PrimaryDefinition": "to slight, to neglect"
    },
    "Keys": {
      "Word": 6153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "实地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shídì",
      "PrimaryDefinition": "concretely, on the ground, on the ground/spot, spot, on the spot"
    },
    "Keys": {
      "Word": 9114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "misfortune, calamity, disaster"
    },
    "Keys": {
      "Hanzi": 2465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.7",
      "GeneralStandard": 2465,
      "Radical": "示",
      "HSK": 7,
      "FrequencyRank": 1870,
      "StrokeCount": 11
    }
  },
  "沉浸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénjìn",
      "PrimaryDefinition": "to soak, to permeate, to immerse"
    },
    "Keys": {
      "Word": 5851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "līn",
      "PrimaryDefinition": "to haul; to lift; to take"
    },
    "Keys": {
      "Hanzi": 973,
      "Word": 8064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 973,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3380,
      "StrokeCount": 8
    }
  },
  "悠闲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuxián",
      "PrimaryDefinition": "leisurely, carefree, relaxed"
    },
    "Keys": {
      "Word": 10456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反抗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnkàng",
      "PrimaryDefinition": "revolt, resist"
    },
    "Keys": {
      "Word": 4553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "莛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "stalks of grasses, etc."
    },
    "Keys": {
      "Hanzi": 4117
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4117,
      "Radical": "艸",
      "FrequencyRank": 7235,
      "RadicalIndex": "140.7",
      "StrokeCount": 9
    }
  },
  "岔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chà",
      "PrimaryDefinition": "diverge, branch off; fork in road"
    },
    "Keys": {
      "Hanzi": 802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.4",
      "GeneralStandard": 802,
      "Radical": "山",
      "HSK": 9,
      "FrequencyRank": 3134,
      "StrokeCount": 7
    }
  },
  "出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū",
      "PrimaryDefinition": "go out, send out; stand; produce"
    },
    "Keys": {
      "Hanzi": 330,
      "Word": 49
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "17.3",
      "Grade": 1,
      "GeneralStandard": 330,
      "Radical": "凵",
      "HSK": 1,
      "FrequencyRank": 28,
      "StrokeCount": 5
    }
  },
  "罶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǔ",
      "PrimaryDefinition": "fishing trap"
    },
    "Keys": {
      "Hanzi": 7779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7779,
      "Radical": "网",
      "FrequencyRank": 7223,
      "RadicalIndex": "122.1",
      "StrokeCount": 15
    }
  },
  "烽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "signal fire; tower where signal"
    },
    "Keys": {
      "Hanzi": 4959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4959,
      "Radical": "火",
      "FrequencyRank": 3991,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "讵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "an interjection used express surprise"
    },
    "Keys": {
      "Hanzi": 3632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3632,
      "Radical": "言",
      "FrequencyRank": 5264,
      "RadicalIndex": "149.5",
      "StrokeCount": 6
    }
  },
  "继而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìér",
      "PrimaryDefinition": "then, afterwards"
    },
    "Keys": {
      "Word": 7335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "fog, mist, vapor, fine spray"
    },
    "Keys": {
      "Hanzi": 2886,
      "Word": 9815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.5",
      "Grade": 7,
      "GeneralStandard": 2886,
      "Radical": "雨",
      "HSK": 7,
      "FrequencyRank": 1670,
      "StrokeCount": 13
    }
  },
  "时事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíshì",
      "PrimaryDefinition": "current trends, the present situation, how things are going"
    },
    "Keys": {
      "Word": 3902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "耜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "spade-shaped tool; plow"
    },
    "Keys": {
      "Hanzi": 4710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4710,
      "Radical": "耒",
      "FrequencyRank": 6213,
      "RadicalIndex": "127.5",
      "StrokeCount": 11
    }
  },
  "潏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "land reclaimed from a wetlands/river, dike/embankment/man-made island"
    },
    "Keys": {
      "Hanzi": 7821
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7821,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "局部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júbù",
      "PrimaryDefinition": "partial, department, section, parts, part, det.: part, maculation, region, spot, dapple, fleck, patch, division, speckle"
    },
    "Keys": {
      "Word": 7681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēn",
      "PrimaryDefinition": "pull"
    },
    "Keys": {
      "Hanzi": 3842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3842,
      "Radical": "手",
      "FrequencyRank": 5131,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "降温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng wēn",
      "PrimaryDefinition": "to become cooler, to lower the temperature, cooling, (of interest, activity etc) to decline"
    },
    "Keys": {
      "Word": 2627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "音节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnjié",
      "PrimaryDefinition": "syllable, key, pitch"
    },
    "Keys": {
      "Word": 1158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "信号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnhào",
      "PrimaryDefinition": "sign, cue, beacon, code, beacon fire, messenger, signal, semaphore, signaling, waft"
    },
    "Keys": {
      "Word": 1107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "迷路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí lù",
      "PrimaryDefinition": "to lose the way, lost, labyrinth, labyrinthus vestibularis (of the inner ear)"
    },
    "Keys": {
      "Word": 8259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèn",
      "PrimaryDefinition": "mountain"
    },
    "Keys": {
      "Radical": 138,
      "Hanzi": 3636
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3636,
      "Radical": "艮",
      "FrequencyRank": 4564,
      "RadicalIndex": "138",
      "StrokeCount": 6
    }
  },
  "离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "rare beast; strange; elegant"
    },
    "Keys": {
      "Hanzi": 2035,
      "Word": 835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "114.6",
      "Grade": 2,
      "GeneralStandard": 2035,
      "Radical": "禸",
      "HSK": 2,
      "FrequencyRank": 418,
      "StrokeCount": 10
    }
  },
  "岌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "perilous, hazardous; high, steep"
    },
    "Keys": {
      "Hanzi": 3593
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3593,
      "Radical": "山",
      "FrequencyRank": 4205,
      "RadicalIndex": "46.4",
      "StrokeCount": 6
    }
  },
  "收拾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōushi",
      "PrimaryDefinition": "put in order, tidy up"
    },
    "Keys": {
      "Word": 3913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "出版社": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūbǎnshè",
      "PrimaryDefinition": "publisher, press"
    },
    "Keys": {
      "Word": 5950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā",
      "PrimaryDefinition": "fermentation, brewing"
    },
    "Keys": {
      "Hanzi": 7336
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7336,
      "Radical": "酉",
      "FrequencyRank": 7955,
      "RadicalIndex": "164.6",
      "StrokeCount": 12
    }
  },
  "朦胧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménglóng",
      "PrimaryDefinition": "hazy"
    },
    "Keys": {
      "Word": 8250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "intestines; emotions; sausage"
    },
    "Keys": {
      "Hanzi": 807,
      "Word": 3312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.3",
      "Grade": 5,
      "GeneralStandard": 807,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1921,
      "StrokeCount": 7
    }
  },
  "毁灭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǐmiè",
      "PrimaryDefinition": "to perish, to ruin, to destroy"
    },
    "Keys": {
      "Word": 7217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "转眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnyǎn",
      "PrimaryDefinition": "in a flash, in the blink of an eye, to glance"
    },
    "Keys": {
      "Word": 10902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "螠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6102,
      "Radical": "虫",
      "FrequencyRank": 9903,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "鲐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "globefish, tetraodon"
    },
    "Keys": {
      "Hanzi": 5531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5531,
      "Radical": "魚",
      "FrequencyRank": 6742,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "病人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngrén",
      "PrimaryDefinition": "shut-in, patient, subject, sufferer, case, valetudinarian, invalid"
    },
    "Keys": {
      "Word": 28
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "柙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "cage, pen for wild animals"
    },
    "Keys": {
      "Hanzi": 4147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4147,
      "Radical": "木",
      "FrequencyRank": 6150,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "海啸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎixiào",
      "PrimaryDefinition": "tsunami"
    },
    "Keys": {
      "Word": 6970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "捉迷藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō mícáng",
      "PrimaryDefinition": "to play hide-and-seek"
    },
    "Keys": {
      "Word": 10927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlù",
      "PrimaryDefinition": "taking the same route, throughtout the journey, all the way, all along, going the same way, through"
    },
    "Keys": {
      "Word": 4110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "份": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "portion, part; duty"
    },
    "Keys": {
      "Hanzi": 471,
      "Word": 664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 2,
      "GeneralStandard": 471,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 784,
      "StrokeCount": 6
    }
  },
  "削弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuēruò",
      "PrimaryDefinition": "to weaken, to impair, to cripple"
    },
    "Keys": {
      "Word": 10128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "luxuriant, thick; ornamental"
    },
    "Keys": {
      "Hanzi": 3076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.11",
      "GeneralStandard": 3076,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 3188,
      "StrokeCount": 14
    }
  },
  "尺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 175,
      "Word": 2321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.1",
      "Grade": 4,
      "GeneralStandard": 175,
      "Radical": "尸",
      "HSK": 4,
      "FrequencyRank": 1474,
      "StrokeCount": 4
    }
  },
  "噌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cēng",
      "PrimaryDefinition": "scold, shout at"
    },
    "Keys": {
      "Hanzi": 5936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5936,
      "Radical": "口",
      "FrequencyRank": 5644,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "殷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "many, great; abundant, flourishing"
    },
    "Keys": {
      "Hanzi": 1977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "79.6",
      "GeneralStandard": 1977,
      "Radical": "殳",
      "HSK": 9,
      "FrequencyRank": 2365,
      "StrokeCount": 10
    }
  },
  "喾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kù",
      "PrimaryDefinition": "emperor"
    },
    "Keys": {
      "Hanzi": 5301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5301,
      "Radical": "口",
      "FrequencyRank": 5708,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "忍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěn",
      "PrimaryDefinition": "endure, bear, suffer; forbear"
    },
    "Keys": {
      "Hanzi": 925,
      "Word": 3839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.3",
      "Grade": 5,
      "GeneralStandard": 925,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1127,
      "StrokeCount": 7
    }
  },
  "接班人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēbān rén",
      "PrimaryDefinition": "successor"
    },
    "Keys": {
      "Word": 7501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "resist, oppose; deny; off-set"
    },
    "Keys": {
      "Hanzi": 975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 975,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1119,
      "StrokeCount": 8
    }
  },
  "导火索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎohuǒsuǒ",
      "PrimaryDefinition": "a fuse (for explosive)"
    },
    "Keys": {
      "Word": 6189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "libation"
    },
    "Keys": {
      "Hanzi": 7446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7446,
      "Radical": "示",
      "FrequencyRank": 7219,
      "RadicalIndex": "113.8",
      "StrokeCount": 12
    }
  },
  "獗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "unruly, wild, violent, lawless"
    },
    "Keys": {
      "Hanzi": 5975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5975,
      "Radical": "犬",
      "FrequencyRank": 3899,
      "RadicalIndex": "94.12",
      "StrokeCount": 15
    }
  },
  "纴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "weave, lay warp"
    },
    "Keys": {
      "Hanzi": 3823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3823,
      "Radical": "糸",
      "FrequencyRank": 8406,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "期盼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīpàn",
      "PrimaryDefinition": "look forward to"
    },
    "Keys": {
      "Word": 8589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāichú",
      "PrimaryDefinition": "to expel"
    },
    "Keys": {
      "Word": 7745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沉重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénzhòng",
      "PrimaryDefinition": "heavy, hard, serious, critical"
    },
    "Keys": {
      "Word": 2311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "一线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxiàn",
      "PrimaryDefinition": "front line"
    },
    "Keys": {
      "Word": 10273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "危": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "dangerous, precarious; high"
    },
    "Keys": {
      "Hanzi": 499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "26.4",
      "GeneralStandard": 499,
      "Radical": "卩",
      "HSK": 3,
      "FrequencyRank": 826,
      "StrokeCount": 6
    }
  },
  "团队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuánduì",
      "PrimaryDefinition": "team"
    },
    "Keys": {
      "Word": 5107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "俯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "bow down, face down, look down"
    },
    "Keys": {
      "Hanzi": 1966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1966,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2261,
      "StrokeCount": 10
    }
  },
  "供": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "supply, provide for; offer in worship"
    },
    "Keys": {
      "Hanzi": 1138,
      "Word": 6795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "Grade": 7,
      "GeneralStandard": 1138,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 550,
      "StrokeCount": 8
    }
  },
  "互信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùxìn",
      "PrimaryDefinition": "mutual trust"
    },
    "Keys": {
      "Word": 7122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "应用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngyòng",
      "PrimaryDefinition": "application, use"
    },
    "Keys": {
      "Word": 2104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "笑声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoshēng",
      "PrimaryDefinition": "laughter"
    },
    "Keys": {
      "Word": 5175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "枷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "cangue scaffold"
    },
    "Keys": {
      "Hanzi": 4159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4159,
      "Radical": "木",
      "FrequencyRank": 3663,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "朝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháo",
      "PrimaryDefinition": "dynasty; morning"
    },
    "Keys": {
      "Hanzi": 2559,
      "Word": 1340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "74.8",
      "Grade": 3,
      "GeneralStandard": 2559,
      "Radical": "月",
      "HSK": 3,
      "FrequencyRank": 593,
      "StrokeCount": 12
    }
  },
  "采访": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎifǎng",
      "PrimaryDefinition": "cover, interview, gather news"
    },
    "Keys": {
      "Word": 2291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "渗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": "soak through, infiltrate"
    },
    "Keys": {
      "Hanzi": 2437,
      "Word": 9050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 7,
      "GeneralStandard": 2437,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2520,
      "StrokeCount": 11
    }
  },
  "龛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kān",
      "PrimaryDefinition": "niche, shrine"
    },
    "Keys": {
      "Hanzi": 4919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4919,
      "Radical": "龍",
      "FrequencyRank": 4015,
      "RadicalIndex": "212.6",
      "StrokeCount": 11
    }
  },
  "监狱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānyù",
      "PrimaryDefinition": "prison, jail"
    },
    "Keys": {
      "Word": 7394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5472,
      "Radical": "口",
      "FrequencyRank": 5248,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "研究": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánjiū",
      "PrimaryDefinition": "study, research"
    },
    "Keys": {
      "Word": 3067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "点子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnzi",
      "PrimaryDefinition": "drop (of liquid), drop, idea, hint, dot, beat (of percussion instruments), beat, spot, key point, speck, pointer"
    },
    "Keys": {
      "Word": 6271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "指": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "finger, toe; point, indicate"
    },
    "Keys": {
      "Hanzi": 1389,
      "Word": 2169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 3,
      "GeneralStandard": 1389,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 261,
      "StrokeCount": 9
    }
  },
  "礼拜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐbài",
      "PrimaryDefinition": "week"
    },
    "Keys": {
      "Word": 3682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "儇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "clever, nimble"
    },
    "Keys": {
      "Hanzi": 7792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7792,
      "Radical": "人",
      "FrequencyRank": 6574,
      "RadicalIndex": "9.13",
      "StrokeCount": 15
    }
  },
  "条约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáoyuē",
      "PrimaryDefinition": "accord, understanding, pact, convention, concord, agreement, treaty"
    },
    "Keys": {
      "Word": 9498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6108
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6108,
      "Radical": "口",
      "FrequencyRank": 4309,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "皛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7794
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7794,
      "Radical": "白",
      "RadicalIndex": "106.1",
      "StrokeCount": 15
    }
  },
  "禁不住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnbuzhù",
      "PrimaryDefinition": "can't help it, can't bear it"
    },
    "Keys": {
      "Word": 7564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāishuǐ",
      "PrimaryDefinition": "boiled water, boiling water"
    },
    "Keys": {
      "Word": 2655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "通行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngxíng",
      "PrimaryDefinition": "pass/go through, be current or of general use"
    },
    "Keys": {
      "Word": 5091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "私自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīzì",
      "PrimaryDefinition": "private, personal, secretly, without explicit approval"
    },
    "Keys": {
      "Word": 9315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欢聚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huānjù",
      "PrimaryDefinition": "to get together socially, to celebrate, party, celebration"
    },
    "Keys": {
      "Word": 7161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùgǎn",
      "PrimaryDefinition": "to have a premonition, premonition"
    },
    "Keys": {
      "Word": 10512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": "felicitous omen; auspicious"
    },
    "Keys": {
      "Hanzi": 2827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.9",
      "GeneralStandard": 2827,
      "Radical": "玉",
      "HSK": 9,
      "FrequencyRank": 1332,
      "StrokeCount": 13
    }
  },
  "霸占": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàzhàn",
      "PrimaryDefinition": "to occupy by force, to seize, to dominate"
    },
    "Keys": {
      "Word": 5471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "资助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīzhù",
      "PrimaryDefinition": "to subsidize, to provide financial aid, subsidy"
    },
    "Keys": {
      "Word": 4273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "使唤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐhuan",
      "PrimaryDefinition": "to order sb about, to use sb"
    },
    "Keys": {
      "Word": 9124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "支撑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīcheng",
      "PrimaryDefinition": "prop up, sustain, support"
    },
    "Keys": {
      "Word": 5348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "冷酷无情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngkù-wúqíng",
      "PrimaryDefinition": "cold-hearted, unfeeling, callous"
    },
    "Keys": {
      "Word": 7967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "班长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bānzhǎng",
      "PrimaryDefinition": "monitor, (work) team leader, squad leader, team leader, class monitor"
    },
    "Keys": {
      "Word": 503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "抟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuán",
      "PrimaryDefinition": "roll around with hand; model"
    },
    "Keys": {
      "Hanzi": 3650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3650,
      "Radical": "手",
      "FrequencyRank": 4659,
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "佚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "indulge in pleasures; flee"
    },
    "Keys": {
      "Hanzi": 3731
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3731,
      "Radical": "人",
      "FrequencyRank": 3805,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "南部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánbù",
      "PrimaryDefinition": "meridional, the southern part of the country, southern part, South, south, the south"
    },
    "Keys": {
      "Word": 1757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "凉爽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángshuǎng",
      "PrimaryDefinition": "cool and refreshing"
    },
    "Keys": {
      "Word": 8033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēng",
      "PrimaryDefinition": "eager, ardent, impulsive; anxious"
    },
    "Keys": {
      "Hanzi": 4027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4027,
      "Radical": "心",
      "FrequencyRank": 3636,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "倌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "assistant in wine shop, groom"
    },
    "Keys": {
      "Hanzi": 4576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4576,
      "Radical": "人",
      "FrequencyRank": 4255,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "预期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùqī",
      "PrimaryDefinition": "expect, anticipate"
    },
    "Keys": {
      "Word": 4151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "皂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "soap; black; menial servant"
    },
    "Keys": {
      "Hanzi": 787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "106.2",
      "GeneralStandard": 787,
      "Radical": "白",
      "HSK": 7,
      "FrequencyRank": 3087,
      "StrokeCount": 7
    }
  },
  "软件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎnjiàn",
      "PrimaryDefinition": "(computer) software"
    },
    "Keys": {
      "Word": 3851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疾病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíbìng",
      "PrimaryDefinition": "trouble, attack, evil, ailment, affection, misery, malfunction, complaint, infirmity, disease, ill, illness, malady, decline, sickness, condition"
    },
    "Keys": {
      "Word": 4700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "西餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīcān",
      "PrimaryDefinition": "Western-style food, CL:份[fen4],頓|顿[dun4]"
    },
    "Keys": {
      "Word": 1074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "铘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yé",
      "PrimaryDefinition": "sword"
    },
    "Keys": {
      "Hanzi": 7182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7182,
      "Radical": "金",
      "RadicalIndex": "167.7",
      "StrokeCount": 11
    }
  },
  "严谨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánjǐn",
      "PrimaryDefinition": "rigorous, strict, compact, well-knit"
    },
    "Keys": {
      "Word": 10173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "白领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báilǐng",
      "PrimaryDefinition": "white collar, business person"
    },
    "Keys": {
      "Word": 4293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不肯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù kěn",
      "PrimaryDefinition": "Refuse"
    },
    "Keys": {
      "Word": 5704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "门当户对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méndāng-hùduì",
      "PrimaryDefinition": "the families are well-matched in terms of social status (idiom), (of a prospective marriage partner) an appropriate match"
    },
    "Keys": {
      "Word": 8242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔyǎn",
      "PrimaryDefinition": "act leading role (in play/film)"
    },
    "Keys": {
      "Word": 10861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "China grass, ramie (Boehmeria nivea)"
    },
    "Keys": {
      "Hanzi": 6660
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6660,
      "Radical": "艸",
      "FrequencyRank": 7800,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "抨击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēngjī",
      "PrimaryDefinition": "attack (in speech/writing), assail"
    },
    "Keys": {
      "Word": 8491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鼓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "drum"
    },
    "Keys": {
      "Hanzi": 2837,
      "Radical": 207,
      "Word": 3508
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "207",
      "Grade": 5,
      "GeneralStandard": 2837,
      "Radical": "鼓",
      "HSK": 5,
      "FrequencyRank": 1123,
      "StrokeCount": 13
    }
  },
  "魃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bá",
      "PrimaryDefinition": "drought demon"
    },
    "Keys": {
      "Hanzi": 5761
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5761,
      "Radical": "鬼",
      "FrequencyRank": 5262,
      "RadicalIndex": "194.5",
      "StrokeCount": 14
    }
  },
  "并列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngliè",
      "PrimaryDefinition": "appose, collocate, stand side by side, be juxtaposed, juxtapose"
    },
    "Keys": {
      "Word": 5652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "致富": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìfù",
      "PrimaryDefinition": "to become rich"
    },
    "Keys": {
      "Word": 10799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "储备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔbèi",
      "PrimaryDefinition": "reserves, to store up"
    },
    "Keys": {
      "Word": 5989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réngōng",
      "PrimaryDefinition": "man-made, artificiality, manual work, manpower, art, labor"
    },
    "Keys": {
      "Word": 1845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "profit, benefit; advantage"
    },
    "Keys": {
      "Hanzi": 2055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "108.5",
      "GeneralStandard": 2055,
      "Radical": "皿",
      "HSK": 4,
      "FrequencyRank": 649,
      "StrokeCount": 10
    }
  },
  "软": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎn",
      "PrimaryDefinition": "soft, flexible, pliable; weak"
    },
    "Keys": {
      "Hanzi": 1062,
      "Word": 3853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.4",
      "Grade": 5,
      "GeneralStandard": 1062,
      "Radical": "車",
      "HSK": 5,
      "FrequencyRank": 1043,
      "StrokeCount": 8
    }
  },
  "重中之重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngzhōngzhīzhòng",
      "PrimaryDefinition": "of the utmost importance, of highest priority"
    },
    "Keys": {
      "Word": 10835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì",
      "PrimaryDefinition": "molt"
    },
    "Keys": {
      "Hanzi": 2926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2926,
      "Radical": "虫",
      "FrequencyRank": 3624,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "终": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "end; finally, in the end"
    },
    "Keys": {
      "Hanzi": 1344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "GeneralStandard": 1344,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 558,
      "StrokeCount": 8
    }
  },
  "有空儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒukòngr5",
      "PrimaryDefinition": "available"
    },
    "Keys": {
      "Word": 1171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "揭示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēshì",
      "PrimaryDefinition": "to show, to make known"
    },
    "Keys": {
      "Word": 7517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "to soak, to steep in water"
    },
    "Keys": {
      "Hanzi": 4651
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4651,
      "Radical": "水",
      "FrequencyRank": 5463,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "适合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìhé",
      "PrimaryDefinition": "qualify, conform, fit, go, befit, quadrate, will, accord, tailor, beseem, serve, comport, gee, answer, become, fit_in, service, pertain, like, accommodate, agree, belong, characterize, make, suit, match"
    },
    "Keys": {
      "Word": 1918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "客运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèyùn",
      "PrimaryDefinition": "passenger traffic, (Tw) intercity bus"
    },
    "Keys": {
      "Word": 7816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "exclamatory particle"
    },
    "Keys": {
      "Hanzi": 3518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3518,
      "Radical": "八",
      "FrequencyRank": 2772,
      "RadicalIndex": "12.2",
      "StrokeCount": 4
    }
  },
  "觟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7567,
      "Radical": "角",
      "RadicalIndex": "148.6",
      "StrokeCount": 13
    }
  },
  "走近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒujìn",
      "PrimaryDefinition": "approach"
    },
    "Keys": {
      "Word": 10994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4283,
      "Radical": "肉",
      "FrequencyRank": 5896,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "搞鬼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo guǐ",
      "PrimaryDefinition": "to make mischief, to play tricks in secret"
    },
    "Keys": {
      "Word": 6710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòjiǎng",
      "PrimaryDefinition": "to overpraise, to flatter"
    },
    "Keys": {
      "Word": 6946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "路线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùxiàn",
      "PrimaryDefinition": "track, path, cart track, way of life, itinerary, route, cartroad, course, road, channel, line, way, routeway, pipeline, curriculum"
    },
    "Keys": {
      "Word": 1720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "冰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīng",
      "PrimaryDefinition": "ice; ice-cold"
    },
    "Keys": {
      "Hanzi": 513,
      "Word": 2273
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.4",
      "Grade": 4,
      "GeneralStandard": 513,
      "Radical": "冫",
      "HSK": 4,
      "FrequencyRank": 1070,
      "StrokeCount": 6
    }
  },
  "缩小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō xiǎo",
      "PrimaryDefinition": "recede, dwindle away, narrow, foreshorten, shrinkage, narrow down, abridge, minify, reduction, puncture, compress, specialize, scale down, dwindle, deflate, minish, take in, reduce, lessen, shorten, diminish, dwindle down, shrink, subside, deescalate, retract"
    },
    "Keys": {
      "Word": 2915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "玞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "an inferior agate"
    },
    "Keys": {
      "Hanzi": 6646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6646,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "杌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "the stump f a tree, hence, sterility; a square stool"
    },
    "Keys": {
      "Hanzi": 3678
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3678,
      "Radical": "木",
      "FrequencyRank": 6170,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "浬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "nautical mile"
    },
    "Keys": {
      "Hanzi": 7057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7057,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "向来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànglái",
      "PrimaryDefinition": "typically, heretofore, always, all along, forever"
    },
    "Keys": {
      "Word": 9947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鑫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "used in names"
    },
    "Keys": {
      "Hanzi": 6490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6490,
      "Radical": "金",
      "FrequencyRank": 3843,
      "RadicalIndex": "167.16",
      "StrokeCount": 24
    }
  },
  "本意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnyì",
      "PrimaryDefinition": "original idea, real intention, etymon"
    },
    "Keys": {
      "Word": 5568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "yoke, collar"
    },
    "Keys": {
      "Hanzi": 3893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3893,
      "Radical": "車",
      "FrequencyRank": 4880,
      "RadicalIndex": "159.4",
      "StrokeCount": 8
    }
  },
  "剽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piāo",
      "PrimaryDefinition": "rob, plunder; slice off; fast"
    },
    "Keys": {
      "Hanzi": 5410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5410,
      "Radical": "刀",
      "FrequencyRank": 4386,
      "RadicalIndex": "18.11",
      "StrokeCount": 13
    }
  },
  "灰色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huīsè",
      "PrimaryDefinition": "pessimistic, grey, ashy, grayness, ambiguous, grizzle, gloomy, gray, greyness, obscure"
    },
    "Keys": {
      "Word": 3556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "乖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāi",
      "PrimaryDefinition": "rebel; crafty, shrewd"
    },
    "Keys": {
      "Hanzi": 1128,
      "Word": 6858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "4.7",
      "Grade": 7,
      "GeneralStandard": 1128,
      "Radical": "丿",
      "HSK": 7,
      "FrequencyRank": 2470,
      "StrokeCount": 8
    }
  },
  "螵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piāo",
      "PrimaryDefinition": "a chrysalis"
    },
    "Keys": {
      "Hanzi": 6232
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6232,
      "Radical": "虫",
      "FrequencyRank": 6842,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "新鲜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxian",
      "PrimaryDefinition": "freshen, novel, fresh, new, strange"
    },
    "Keys": {
      "Word": 3036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "缅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "distant, remote; think of"
    },
    "Keys": {
      "Hanzi": 2814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "GeneralStandard": 2814,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2676,
      "StrokeCount": 12
    }
  },
  "放过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngguò",
      "PrimaryDefinition": "let off, let slip, forgive, let off/slip, slip"
    },
    "Keys": {
      "Word": 6502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "制品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìpǐn",
      "PrimaryDefinition": "manufacture, ware, facture, manufactured goods, products"
    },
    "Keys": {
      "Word": 10788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "tellurium"
    },
    "Keys": {
      "Hanzi": 5679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5679,
      "Radical": "石",
      "FrequencyRank": 6376,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "大爷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàye",
      "PrimaryDefinition": "arrogant idler, self-centered show-off, (coll.) father's older brother, uncle, term of respect for older man"
    },
    "Keys": {
      "Word": 2379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "涉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "ford stream, wade across"
    },
    "Keys": {
      "Hanzi": 2070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2070,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1239,
      "StrokeCount": 10
    }
  },
  "上楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànglóu",
      "PrimaryDefinition": "to go upstairs"
    },
    "Keys": {
      "Word": 2844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "call; name, brand; address; say"
    },
    "Keys": {
      "Hanzi": 1948,
      "Word": 3319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "Grade": 5,
      "GeneralStandard": 1948,
      "Radical": "禾",
      "HSK": 2,
      "FrequencyRank": 449,
      "StrokeCount": 10
    }
  },
  "世故": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìgu",
      "PrimaryDefinition": "the ways of the world, sophisticated, worldly-wise"
    },
    "Keys": {
      "Word": 9132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huái",
      "PrimaryDefinition": "bosom, breast; carry in bosom"
    },
    "Keys": {
      "Hanzi": 873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "GeneralStandard": 873,
      "Radical": "心",
      "HSK": 4,
      "FrequencyRank": 762,
      "StrokeCount": 7
    }
  },
  "集中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jízhōng",
      "PrimaryDefinition": "concentrate, centralize, focus, put together"
    },
    "Keys": {
      "Word": 1576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "生成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngchéng",
      "PrimaryDefinition": "to generate, to produce, generated, produced"
    },
    "Keys": {
      "Word": 3888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "嗄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "á",
      "PrimaryDefinition": "hoarse of voice"
    },
    "Keys": {
      "Hanzi": 5447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5447,
      "Radical": "口",
      "FrequencyRank": 5029,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "不过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùguò",
      "PrimaryDefinition": "conj.: but, however, only"
    },
    "Keys": {
      "Word": 528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "正义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngyì",
      "PrimaryDefinition": "justice"
    },
    "Keys": {
      "Word": 4212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "公共": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnggòng",
      "PrimaryDefinition": "common, commonly, publicly, public, communal"
    },
    "Keys": {
      "Word": 1503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "bright, light, brilliant; clear"
    },
    "Keys": {
      "Hanzi": 1087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1087,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 121,
      "StrokeCount": 8
    }
  },
  "瑝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7467,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "逃跑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táopǎo",
      "PrimaryDefinition": "to flee from sth, to run away, to escape"
    },
    "Keys": {
      "Word": 3961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "董事长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒngshìzhǎng",
      "PrimaryDefinition": "board chair"
    },
    "Keys": {
      "Word": 6328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曙光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔguāng",
      "PrimaryDefinition": "dawn, fig. the dawn of a new era"
    },
    "Keys": {
      "Word": 9227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "mountain stream, creek"
    },
    "Keys": {
      "Hanzi": 3012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3012,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2194,
      "StrokeCount": 13
    }
  },
  "宠爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒngài",
      "PrimaryDefinition": "to dote on sb"
    },
    "Keys": {
      "Word": 5929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "往常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngcháng",
      "PrimaryDefinition": "habitually (in the past), as one used to do formerly, as it used to be"
    },
    "Keys": {
      "Word": 9671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "飞跃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēiyuè",
      "PrimaryDefinition": "to leap"
    },
    "Keys": {
      "Word": 6511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纳闷儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàmènr5",
      "PrimaryDefinition": "puzzled, bewildered, erhua variant of 納悶|纳闷[na4 men4]"
    },
    "Keys": {
      "Word": 8363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "used in place names; Japanese -nuta; Korean -dae"
    },
    "Keys": {
      "Hanzi": 6694
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6694,
      "Radical": "土",
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "整理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěnglǐ",
      "PrimaryDefinition": "put in order, arrange"
    },
    "Keys": {
      "Word": 2143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "桐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "name applied various trees"
    },
    "Keys": {
      "Hanzi": 1839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1839,
      "Radical": "木",
      "FrequencyRank": 2712,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "采集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎijí",
      "PrimaryDefinition": "oyster, collect, gather, pick up and put together"
    },
    "Keys": {
      "Word": 5749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "本子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnzi",
      "PrimaryDefinition": "book, notebook, edition, CL:本[ben3]"
    },
    "Keys": {
      "Word": 23
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "砌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "to build, pile up; stone steps, brick walk"
    },
    "Keys": {
      "Hanzi": 1450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.4",
      "GeneralStandard": 1450,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 3221,
      "StrokeCount": 9
    }
  },
  "请帖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐngtiě",
      "PrimaryDefinition": "invitation card, written invitation"
    },
    "Keys": {
      "Word": 8763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "boat"
    },
    "Keys": {
      "Radical": 137,
      "Hanzi": 484
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137",
      "GeneralStandard": 484,
      "Radical": "舟",
      "HSK": 9,
      "FrequencyRank": 2224,
      "StrokeCount": 6
    }
  },
  "泞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nìng",
      "PrimaryDefinition": "mud; miry, muddy, stagnant"
    },
    "Keys": {
      "Hanzi": 1254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1254,
      "Radical": "水",
      "FrequencyRank": 3936,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "蝴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "butterfly"
    },
    "Keys": {
      "Hanzi": 3246
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.9",
      "GeneralStandard": 3246,
      "Radical": "虫",
      "HSK": 5,
      "FrequencyRank": 2964,
      "StrokeCount": 15
    }
  },
  "鲣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "skipjack, bonito"
    },
    "Keys": {
      "Hanzi": 5971
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5971,
      "Radical": "魚",
      "FrequencyRank": 7261,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "数量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùliàng",
      "PrimaryDefinition": "magnitude, scalar, aggregate, deal, measure, numerical quantity, measurement, sum, worth, amount, quantity, number"
    },
    "Keys": {
      "Word": 1940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "narrate, state, express"
    },
    "Keys": {
      "Hanzi": 1032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.5",
      "GeneralStandard": 1032,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 674,
      "StrokeCount": 8
    }
  },
  "起伏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐfú",
      "PrimaryDefinition": "rise and fall, undulate"
    },
    "Keys": {
      "Word": 8619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎ",
      "PrimaryDefinition": "horn, bugle; lama; final particle"
    },
    "Keys": {
      "Hanzi": 2621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "GeneralStandard": 2621,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2677,
      "StrokeCount": 12
    }
  },
  "戆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàng",
      "PrimaryDefinition": "stupid, simple, simple-minded"
    },
    "Keys": {
      "Hanzi": 6498
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6498,
      "Radical": "心",
      "FrequencyRank": 5706,
      "RadicalIndex": "61.21",
      "StrokeCount": 25
    }
  },
  "附件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùjiàn",
      "PrimaryDefinition": "enclosure, attachment (email), appendix"
    },
    "Keys": {
      "Word": 3475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "诼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "slander; gossip; rumors"
    },
    "Keys": {
      "Hanzi": 4670
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4670,
      "Radical": "言",
      "FrequencyRank": 5699,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "轷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 6816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6816,
      "Radical": "車",
      "FrequencyRank": 9008,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "狈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "a legendary animal with short forelegs which rode a wolf"
    },
    "Keys": {
      "Hanzi": 813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.4",
      "GeneralStandard": 813,
      "Radical": "犬",
      "HSK": 9,
      "FrequencyRank": 3090,
      "StrokeCount": 7
    }
  },
  "恹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "feeble, sickly; tranquil, calm"
    },
    "Keys": {
      "Hanzi": 4351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4351,
      "Radical": "心",
      "FrequencyRank": 5184,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "甾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāi",
      "PrimaryDefinition": "ground that has been under cultivation for one year; evil, calamity"
    },
    "Keys": {
      "Hanzi": 4083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4083,
      "Radical": "田",
      "FrequencyRank": 5907,
      "RadicalIndex": "102.3",
      "StrokeCount": 8
    }
  },
  "煜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "bright, shining, brilliant"
    },
    "Keys": {
      "Hanzi": 5560
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5560,
      "Radical": "火",
      "FrequencyRank": 3834,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "东奔西走": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngbēnxīzǒu",
      "PrimaryDefinition": "to run this way and that (idiom), to rush about busily, to bustle about, to hopscotch, also 東跑西顛|东跑西颠[dong1 pao3 xi1 dian1]"
    },
    "Keys": {
      "Word": 6323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "insects that eat books, clothes"
    },
    "Keys": {
      "Hanzi": 2279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2279,
      "Radical": "虫",
      "FrequencyRank": 3984,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "用得着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngdezháo",
      "PrimaryDefinition": "to be able to use, useable, to have a use for sth, (in interrogative sentence) to be necessary to"
    },
    "Keys": {
      "Word": 5266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "crooked, bent; wrong, false"
    },
    "Keys": {
      "Hanzi": 424,
      "Word": 8781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73.2",
      "Grade": 7,
      "GeneralStandard": 424,
      "Radical": "曰",
      "HSK": 5,
      "FrequencyRank": 1066,
      "StrokeCount": 6
    }
  },
  "依赖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlài",
      "PrimaryDefinition": "rely/depend on"
    },
    "Keys": {
      "Word": 5230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蜍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "toad"
    },
    "Keys": {
      "Hanzi": 5461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5461,
      "Radical": "虫",
      "FrequencyRank": 5307,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "滟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "overflowing, billowing; wavy"
    },
    "Keys": {
      "Hanzi": 5566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5566,
      "Radical": "水",
      "FrequencyRank": 5594,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "寝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐn",
      "PrimaryDefinition": "sleep, rest; bed chamber"
    },
    "Keys": {
      "Hanzi": 3029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.1",
      "GeneralStandard": 3029,
      "Radical": "宀",
      "HSK": 8,
      "FrequencyRank": 2806,
      "StrokeCount": 13
    }
  },
  "职工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhígōng",
      "PrimaryDefinition": "workers, staff, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "亏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuī",
      "PrimaryDefinition": "lose, fail; damage; deficient"
    },
    "Keys": {
      "Hanzi": 25,
      "Word": 3675
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7.1",
      "Grade": 5,
      "GeneralStandard": 25,
      "Radical": "二",
      "HSK": 5,
      "FrequencyRank": 1738,
      "StrokeCount": 3
    }
  },
  "事实上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìshíshang",
      "PrimaryDefinition": "in fact, in reality, actually, as a matter of fact, de facto, ipso facto"
    },
    "Keys": {
      "Word": 1914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "芦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "rushes, reeds"
    },
    "Keys": {
      "Hanzi": 672
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 672,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2477,
      "StrokeCount": 7
    }
  },
  "刚好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānghǎo",
      "PrimaryDefinition": "exact, just right"
    },
    "Keys": {
      "Word": 4587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鹏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "fabulous bird of enormous size"
    },
    "Keys": {
      "Hanzi": 2974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "196.8",
      "GeneralStandard": 2974,
      "Radical": "鳥",
      "HSK": 9,
      "FrequencyRank": 1926,
      "StrokeCount": 13
    }
  },
  "略微": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüèwēi",
      "PrimaryDefinition": "a little bit, slightly"
    },
    "Keys": {
      "Word": 8153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7466,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "顺手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnshǒu",
      "PrimaryDefinition": "easily, without trouble, while one is at it, in passing, handy"
    },
    "Keys": {
      "Word": 9285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lào",
      "PrimaryDefinition": "hanker"
    },
    "Keys": {
      "Hanzi": 7745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7745,
      "Radical": "女",
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "费用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèiyong",
      "PrimaryDefinition": "tax, cost, expense, expenditure, due, rate, exes, outlay, outgo, fee, terms, toll, price, disbursement, fare, expenses, disbursal, retainer, pay, damage, charge, monetary value, consideration, outgoing"
    },
    "Keys": {
      "Word": 1452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "重大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngdà",
      "PrimaryDefinition": "great, major, crucial, weighty, whang, fatal, significant, grave"
    },
    "Keys": {
      "Word": 2187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "糙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāo",
      "PrimaryDefinition": "coarse, harsh, rough, unpolished rice"
    },
    "Keys": {
      "Hanzi": 3373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "119.11",
      "GeneralStandard": 3373,
      "Radical": "米",
      "HSK": 8,
      "FrequencyRank": 3118,
      "StrokeCount": 16
    }
  },
  "攉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō",
      "PrimaryDefinition": "to beckon; to urge"
    },
    "Keys": {
      "Hanzi": 6367
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6367,
      "Radical": "手",
      "FrequencyRank": 6726,
      "RadicalIndex": "64.16",
      "StrokeCount": 19
    }
  },
  "传授": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánshòu",
      "PrimaryDefinition": "to impart, to pass on, to teach"
    },
    "Keys": {
      "Word": 6010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "超级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāojí",
      "PrimaryDefinition": "super"
    },
    "Keys": {
      "Word": 1343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "瞫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěn",
      "PrimaryDefinition": "look"
    },
    "Keys": {
      "Hanzi": 7932
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7932,
      "Radical": "目",
      "FrequencyRank": 8025,
      "RadicalIndex": "109.12",
      "StrokeCount": 17
    }
  },
  "赔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péi",
      "PrimaryDefinition": "indemnify, suffer loss"
    },
    "Keys": {
      "Hanzi": 2654,
      "Word": 3760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.8",
      "Grade": 5,
      "GeneralStandard": 2654,
      "Radical": "貝",
      "HSK": 5,
      "FrequencyRank": 1734,
      "StrokeCount": 12
    }
  },
  "丫头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yātou",
      "PrimaryDefinition": "girl, servant girl, (used deprecatingly, but sometimes also as a term of endearment)"
    },
    "Keys": {
      "Word": 10154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "to compare; to match; to equal"
    },
    "Keys": {
      "Hanzi": 6587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6587,
      "Radical": "土",
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "汭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": "confluence of two streams; bend"
    },
    "Keys": {
      "Hanzi": 6617
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6617,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "纳入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàrù",
      "PrimaryDefinition": "take_in, bring ... into, to intake, integrate, to subsume, channel ... into, incorporate, channel into, bring into, to bring into, to fit into"
    },
    "Keys": {
      "Word": 8366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "设立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèlì",
      "PrimaryDefinition": "establish, found"
    },
    "Keys": {
      "Word": 1876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "裙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qún",
      "PrimaryDefinition": "skirt, apron, petticoat"
    },
    "Keys": {
      "Hanzi": 2792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.7",
      "GeneralStandard": 2792,
      "Radical": "衣",
      "HSK": 3,
      "FrequencyRank": 2431,
      "StrokeCount": 12
    }
  },
  "常常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángcháng",
      "PrimaryDefinition": "oftentimes, ofttimes, commonly, ordinarily, oft, often, a great deal, normally, frequently, unremarkably, much, usually"
    },
    "Keys": {
      "Word": 38
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "肸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "spread out; smilling or laughing"
    },
    "Keys": {
      "Hanzi": 6710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6710,
      "Radical": "肉",
      "FrequencyRank": 9102,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "槃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pán",
      "PrimaryDefinition": "tray; turn, rotate; search"
    },
    "Keys": {
      "Hanzi": 7697
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7697,
      "Radical": "木",
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "宁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "calm, peaceful, serene; healthy"
    },
    "Keys": {
      "Hanzi": 312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.2",
      "GeneralStandard": 312,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 1019,
      "StrokeCount": 5
    }
  },
  "傻瓜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎguā",
      "PrimaryDefinition": "idiot, fool"
    },
    "Keys": {
      "Word": 8941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "sorrowful, depressed, unhappy"
    },
    "Keys": {
      "Hanzi": 4662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4662,
      "Radical": "心",
      "FrequencyRank": 5550,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "挺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": "to stand upright, straighten; rigid"
    },
    "Keys": {
      "Hanzi": 1382,
      "Word": 2938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 4,
      "GeneralStandard": 1382,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 1467,
      "StrokeCount": 9
    }
  },
  "指数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐshù",
      "PrimaryDefinition": "exponential, index, index number, index (number), indicant, exponential function, exponent, power, index_number, indicator"
    },
    "Keys": {
      "Word": 5358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "槁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "wither; withered, rotten, dead"
    },
    "Keys": {
      "Hanzi": 5667
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5667,
      "Radical": "木",
      "FrequencyRank": 4428,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "丙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "third; 3rd heavenly stem"
    },
    "Keys": {
      "Hanzi": 213,
      "Word": 5648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "Grade": 7,
      "GeneralStandard": 213,
      "Radical": "一",
      "HSK": 8,
      "FrequencyRank": 2645,
      "StrokeCount": 5
    }
  },
  "琈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7103,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "美满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měimǎn",
      "PrimaryDefinition": "happy, blissful"
    },
    "Keys": {
      "Word": 8235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "a kind of pigeon; treron permagna"
    },
    "Keys": {
      "Hanzi": 5732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5732,
      "Radical": "鳥",
      "FrequencyRank": 4800,
      "RadicalIndex": "196.1",
      "StrokeCount": 14
    }
  },
  "樵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "woodcutter; firewood; gather wood"
    },
    "Keys": {
      "Hanzi": 6059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6059,
      "Radical": "木",
      "FrequencyRank": 3386,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "盗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "rob, steal; thief, bandit"
    },
    "Keys": {
      "Hanzi": 2388,
      "Word": 6202
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.6",
      "Grade": 7,
      "GeneralStandard": 2388,
      "Radical": "皿",
      "HSK": 6,
      "FrequencyRank": 1619,
      "StrokeCount": 11
    }
  },
  "在家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàijiā",
      "PrimaryDefinition": "At home"
    },
    "Keys": {
      "Word": 453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "擢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "pull up, draw up; select"
    },
    "Keys": {
      "Hanzi": 6204
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6204,
      "Radical": "手",
      "FrequencyRank": 4477,
      "RadicalIndex": "64.14",
      "StrokeCount": 17
    }
  },
  "大众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàzhòng",
      "PrimaryDefinition": "the broad masses, roughscuff, the public, herd, concourse, commonality, riffraff, masses, commonness, the masses, throng, multitude, commonage, commonalty, plebeian, plebs, horde, the people/masses, community, the people, million, mass, public"
    },
    "Keys": {
      "Word": 2378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "倔强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéjiàng",
      "PrimaryDefinition": "stubborn, obstinate, unbending"
    },
    "Keys": {
      "Word": 7726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "god of cereals; minister of agriculture"
    },
    "Keys": {
      "Hanzi": 5954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5954,
      "Radical": "禾",
      "FrequencyRank": 3887,
      "RadicalIndex": "115.1",
      "StrokeCount": 15
    }
  },
  "耶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yé",
      "PrimaryDefinition": "used in transliteration"
    },
    "Keys": {
      "Hanzi": 3853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3853,
      "Radical": "耳",
      "FrequencyRank": 1174,
      "RadicalIndex": "128.3",
      "StrokeCount": 8
    }
  },
  "高科技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāokējì",
      "PrimaryDefinition": "high technology, high tech"
    },
    "Keys": {
      "Word": 4597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "突破": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū pò",
      "PrimaryDefinition": "make a breakthrough, surmount"
    },
    "Keys": {
      "Word": 3988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "眼下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnxià",
      "PrimaryDefinition": "now, at present, subocular (medicine)"
    },
    "Keys": {
      "Word": 10196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "秸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "stalks of millet, corn"
    },
    "Keys": {
      "Hanzi": 2312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2312,
      "Radical": "禾",
      "FrequencyRank": 3840,
      "RadicalIndex": "115.6",
      "StrokeCount": 11
    }
  },
  "硐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "variant of 洞, cave; chamber; pit"
    },
    "Keys": {
      "Hanzi": 4794
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4794,
      "Radical": "石",
      "FrequencyRank": 5425,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "缭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "wind round, rap around, bind"
    },
    "Keys": {
      "Hanzi": 3314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3314,
      "Radical": "糸",
      "FrequencyRank": 3637,
      "RadicalIndex": "120.12",
      "StrokeCount": 15
    }
  },
  "酂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuó",
      "PrimaryDefinition": "collect; small administration"
    },
    "Keys": {
      "Hanzi": 8005
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8005,
      "Radical": "邑",
      "FrequencyRank": 7571,
      "RadicalIndex": "163.16",
      "StrokeCount": 18
    }
  },
  "请客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng kè",
      "PrimaryDefinition": "to give a dinner party, to entertain guests, to invite to dinner"
    },
    "Keys": {
      "Word": 934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "官兵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānbīng",
      "PrimaryDefinition": "officers and men, government troops"
    },
    "Keys": {
      "Word": 6875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兴旺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngwàng",
      "PrimaryDefinition": "prosperous, thriving, to prosper, to flourish"
    },
    "Keys": {
      "Word": 5192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "木": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Radical": 75,
      "Hanzi": 87
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75",
      "GeneralStandard": 87,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 694,
      "StrokeCount": 4
    }
  },
  "鼹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "a kind of insectivorous rodent"
    },
    "Keys": {
      "Hanzi": 6483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6483,
      "Radical": "鼠",
      "FrequencyRank": 4932,
      "RadicalIndex": "208.1",
      "StrokeCount": 23
    }
  },
  "闿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "open; peaceful"
    },
    "Keys": {
      "Hanzi": 4318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4318,
      "Radical": "門",
      "FrequencyRank": 4696,
      "RadicalIndex": "169.1",
      "StrokeCount": 9
    }
  },
  "栐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6802,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "铽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tè",
      "PrimaryDefinition": "terbium"
    },
    "Keys": {
      "Hanzi": 7376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7376,
      "Radical": "金",
      "FrequencyRank": 7654,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "宦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "officialdom, government official"
    },
    "Keys": {
      "Hanzi": 1696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1696,
      "Radical": "宀",
      "FrequencyRank": 2976,
      "RadicalIndex": "40.6",
      "StrokeCount": 9
    }
  },
  "馌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "carry meal to workers in field"
    },
    "Keys": {
      "Hanzi": 7569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7569,
      "Radical": "食",
      "RadicalIndex": "184.1",
      "StrokeCount": 13
    }
  },
  "面包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànbāo",
      "PrimaryDefinition": "bun, staff of life, cookie, breadstuff, bread"
    },
    "Keys": {
      "Word": 240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "嵫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "a hill in Shantung"
    },
    "Keys": {
      "Hanzi": 5174
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5174,
      "Radical": "山",
      "FrequencyRank": 6140,
      "RadicalIndex": "46.1",
      "StrokeCount": 12
    }
  },
  "兜售": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōushòu",
      "PrimaryDefinition": "to hawk, to peddle"
    },
    "Keys": {
      "Word": 6346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "月票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèpiào",
      "PrimaryDefinition": "monthly ticket"
    },
    "Keys": {
      "Word": 10562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鬘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "beautiful hair"
    },
    "Keys": {
      "Hanzi": 8077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8077,
      "Radical": "髟",
      "FrequencyRank": 6525,
      "RadicalIndex": "190.11",
      "StrokeCount": 21
    }
  },
  "鲅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "fish name"
    },
    "Keys": {
      "Hanzi": 5525
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5525,
      "Radical": "魚",
      "FrequencyRank": 5451,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "坐下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòxià",
      "PrimaryDefinition": "subside, sit, sit down, sink, sit_down"
    },
    "Keys": {
      "Word": 496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "界定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièdìng",
      "PrimaryDefinition": "define"
    },
    "Keys": {
      "Word": 7549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耗费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàofèi",
      "PrimaryDefinition": "to waste, to spend, to consume, to squander"
    },
    "Keys": {
      "Word": 7019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎo",
      "PrimaryDefinition": "show, express, manifest, display"
    },
    "Keys": {
      "Hanzi": 951,
      "Word": 530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.3",
      "Grade": 2,
      "GeneralStandard": 951,
      "Radical": "衣",
      "HSK": 2,
      "FrequencyRank": 177,
      "StrokeCount": 8
    }
  },
  "仙女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānnǚ",
      "PrimaryDefinition": "fairy"
    },
    "Keys": {
      "Word": 9881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "执照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhízhào",
      "PrimaryDefinition": "endorsement, charter, countenance, imprimatur, permit, warrant, sanction, qualification, certificate, licence, license"
    },
    "Keys": {
      "Word": 10754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "勇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "brave, courageous, fierce"
    },
    "Keys": {
      "Hanzi": 1746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.7",
      "GeneralStandard": 1746,
      "Radical": "力",
      "HSK": 4,
      "FrequencyRank": 1206,
      "StrokeCount": 9
    }
  },
  "名著": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngzhù",
      "PrimaryDefinition": "masterpiece"
    },
    "Keys": {
      "Word": 8311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "已经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐjīng",
      "PrimaryDefinition": "already"
    },
    "Keys": {
      "Word": 1139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "淙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóng",
      "PrimaryDefinition": "gurgling sound of water"
    },
    "Keys": {
      "Hanzi": 4979
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4979,
      "Radical": "水",
      "FrequencyRank": 4172,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "帜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "flag, pennant; sign; fasten"
    },
    "Keys": {
      "Hanzi": 1109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.5",
      "GeneralStandard": 1109,
      "Radical": "巾",
      "HSK": 8,
      "FrequencyRank": 2813,
      "StrokeCount": 8
    }
  },
  "工资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzī",
      "PrimaryDefinition": "earning, hire, remuneration, screw, laborage, pay, paycheck, wages, earnings, pay_packet, salary, wage_scale, differential, wage"
    },
    "Keys": {
      "Word": 1501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "家长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāzhǎng",
      "PrimaryDefinition": "masterman, housemaster, graybeard, patriarch, parent of schoolchildren, patriarchal, goodman, patriarchate, paterfamilias, the head of a family, genearch, patriarchy, householder, head of household, the parent or guadian of a child"
    },
    "Keys": {
      "Word": 753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "read, study; pronounce"
    },
    "Keys": {
      "Hanzi": 2112,
      "Word": 88
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "Grade": 1,
      "GeneralStandard": 2112,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 752,
      "StrokeCount": 10
    }
  },
  "公认": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngrèn",
      "PrimaryDefinition": "publicly known (to be), accepted (as)"
    },
    "Keys": {
      "Word": 3500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "小路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎolù",
      "PrimaryDefinition": "minor road, lane, pathway, trail"
    },
    "Keys": {
      "Word": 9968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "rattan box, suitcase, or case"
    },
    "Keys": {
      "Hanzi": 5744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5744,
      "Radical": "竹",
      "FrequencyRank": 5474,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "顽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": "obstinate, stubborn; recalcitrant"
    },
    "Keys": {
      "Hanzi": 1778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.4",
      "GeneralStandard": 1778,
      "Radical": "頁",
      "HSK": 6,
      "FrequencyRank": 2024,
      "StrokeCount": 10
    }
  },
  "洼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wā",
      "PrimaryDefinition": "hollow; pit; depression; swamp"
    },
    "Keys": {
      "Hanzi": 1662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1662,
      "Radical": "水",
      "FrequencyRank": 3226,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "love, be fond of, like"
    },
    "Keys": {
      "Hanzi": 1987,
      "Word": 1
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "87.6",
      "Grade": 1,
      "GeneralStandard": 1987,
      "Radical": "爪",
      "HSK": 1,
      "FrequencyRank": 394,
      "StrokeCount": 10
    }
  },
  "势必": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìbì",
      "PrimaryDefinition": "certainly will, be bound to"
    },
    "Keys": {
      "Word": 9135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "dry by exposing sun"
    },
    "Keys": {
      "Hanzi": 7767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7767,
      "Radical": "日",
      "RadicalIndex": "72.11",
      "StrokeCount": 15
    }
  },
  "绝技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéjì",
      "PrimaryDefinition": "consummate skill, supreme feat, tour-de-force, stunt"
    },
    "Keys": {
      "Word": 7722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāo",
      "PrimaryDefinition": "mugwort, artemisia; give off"
    },
    "Keys": {
      "Hanzi": 5382
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5382,
      "Radical": "艸",
      "FrequencyRank": 4305,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "基督教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Jīdūjiào",
      "PrimaryDefinition": "Christian religion, Christianity, Christendom, Christianism, Christian"
    },
    "Keys": {
      "Word": 4693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "乘坐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngzuò",
      "PrimaryDefinition": "to ride (in a vehicle)"
    },
    "Keys": {
      "Word": 3330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "袼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7251,
      "Radical": "衣",
      "FrequencyRank": 5808,
      "RadicalIndex": "145.6",
      "StrokeCount": 11
    }
  },
  "墟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "high mound; hilly countryside; wasteland"
    },
    "Keys": {
      "Hanzi": 3055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.12",
      "GeneralStandard": 3055,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 2958,
      "StrokeCount": 14
    }
  },
  "心病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnbìng",
      "PrimaryDefinition": "anxiety, sore point, secret worry, mental disorder, heart disease (medicine)"
    },
    "Keys": {
      "Word": 10002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàyóu",
      "PrimaryDefinition": "lower reaches (of river), lower level"
    },
    "Keys": {
      "Word": 9874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抗拒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàngjù",
      "PrimaryDefinition": "resist, defy"
    },
    "Keys": {
      "Word": 7780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuxù",
      "PrimaryDefinition": "formality, procedures, formalities, procedure"
    },
    "Keys": {
      "Word": 1925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "振作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènzuò",
      "PrimaryDefinition": "to bestir oneself, to pull oneself together, to cheer up, to uplift, to stimulate"
    },
    "Keys": {
      "Word": 10709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "提醒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí xǐng",
      "PrimaryDefinition": "remind, warn, alert to"
    },
    "Keys": {
      "Word": 2921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "慎重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shènzhòng",
      "PrimaryDefinition": "careful, cautious, serious, discreet"
    },
    "Keys": {
      "Word": 9049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "draw water from well; imbibe"
    },
    "Keys": {
      "Hanzi": 3626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3626,
      "Radical": "水",
      "FrequencyRank": 3374,
      "RadicalIndex": "85.4",
      "StrokeCount": 6
    }
  },
  "主管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔguǎn",
      "PrimaryDefinition": "person in charge"
    },
    "Keys": {
      "Word": 4252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "电视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànshì",
      "PrimaryDefinition": "TV, box, iconoscope, video, television set, teevee, orthicon, huckster, TV set, radiovision, television, telecasting, airwave, telly"
    },
    "Keys": {
      "Word": 81
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "柈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "container"
    },
    "Keys": {
      "Hanzi": 4157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4157,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "社团": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shètuán",
      "PrimaryDefinition": "gild, college, lodge, association, organization, club, body of persons, society, mass organization, order, league, guild, confraternity, corporation"
    },
    "Keys": {
      "Word": 9010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "模": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "model, standard, pattern; copy"
    },
    "Keys": {
      "Hanzi": 3078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.11",
      "GeneralStandard": 3078,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 689,
      "StrokeCount": 14
    }
  },
  "店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "shop, store; inn, hotel"
    },
    "Keys": {
      "Hanzi": 1208,
      "Word": 621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.5",
      "Grade": 2,
      "GeneralStandard": 1208,
      "Radical": "广",
      "HSK": 1,
      "FrequencyRank": 1041,
      "StrokeCount": 8
    }
  },
  "脖子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bózi",
      "PrimaryDefinition": "cervix, neck"
    },
    "Keys": {
      "Word": 5669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "升级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng jí",
      "PrimaryDefinition": "upgrade, go up (in grade/etc.), escalate"
    },
    "Keys": {
      "Word": 5008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "发生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāshēng",
      "PrimaryDefinition": "happen, occur, take place"
    },
    "Keys": {
      "Word": 1433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "捌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "break open, split open; an accounting version of the numeral eight"
    },
    "Keys": {
      "Hanzi": 1800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1800,
      "Radical": "手",
      "FrequencyRank": 5580,
      "RadicalIndex": "64.7",
      "StrokeCount": 10
    }
  },
  "假日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàrì",
      "PrimaryDefinition": "holiday, non-working day"
    },
    "Keys": {
      "Word": 4707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "浮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "to float, drift, waft; to exceed; superfluous"
    },
    "Keys": {
      "Hanzi": 2077,
      "Word": 4574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 6,
      "GeneralStandard": 2077,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1462,
      "StrokeCount": 10
    }
  },
  "垫底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàndǐ",
      "PrimaryDefinition": "Bottom"
    },
    "Keys": {
      "Word": 6279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "天线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānxiàn",
      "PrimaryDefinition": "antenna, mast, connection with high-ranking officials"
    },
    "Keys": {
      "Word": 9483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "deficient, lacking, insufficient"
    },
    "Keys": {
      "Hanzi": 3155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.1",
      "GeneralStandard": 3155,
      "Radical": "欠",
      "HSK": 6,
      "FrequencyRank": 2149,
      "StrokeCount": 14
    }
  },
  "炭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn",
      "PrimaryDefinition": "charcoal; coal; carbon"
    },
    "Keys": {
      "Hanzi": 1517,
      "Word": 9416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "Grade": 7,
      "GeneralStandard": 1517,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 2369,
      "StrokeCount": 9
    }
  },
  "遥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "far away, distant, remote"
    },
    "Keys": {
      "Hanzi": 2967
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "GeneralStandard": 2967,
      "Radical": "辵",
      "HSK": 7,
      "FrequencyRank": 1715,
      "StrokeCount": 13
    }
  },
  "选项": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnxiàng",
      "PrimaryDefinition": "to make a choice (between several alternatives), a choice, an option, an alternative"
    },
    "Keys": {
      "Word": 10124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "变幻莫测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànhuànmòcè",
      "PrimaryDefinition": "to change unpredictably, unpredictable, erratic, treacherous"
    },
    "Keys": {
      "Word": 5609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7607,
      "Radical": "示",
      "RadicalIndex": "113.9",
      "StrokeCount": 13
    }
  },
  "礼物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐwù",
      "PrimaryDefinition": "tribute, giving, proffer, gift, compliment, box, benefice, acknowledgment, present, souvenir"
    },
    "Keys": {
      "Word": 841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "宾馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīnguǎn",
      "PrimaryDefinition": "guesthouse, lodge, hotel, CL:個|个[ge4],家[jia1]"
    },
    "Keys": {
      "Word": 3260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "贿赂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìlù",
      "PrimaryDefinition": "to bribe, a bribe"
    },
    "Keys": {
      "Word": 7225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gě",
      "PrimaryDefinition": "excellent; to commend to be able to"
    },
    "Keys": {
      "Hanzi": 7089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7089,
      "Radical": "口",
      "FrequencyRank": 6050,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "枚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "stalk of shrub, trunk of tree"
    },
    "Keys": {
      "Hanzi": 1023,
      "Word": 8225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 7,
      "GeneralStandard": 1023,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 1884,
      "StrokeCount": 8
    }
  },
  "抵御": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐyù",
      "PrimaryDefinition": "to resist, to withstand"
    },
    "Keys": {
      "Word": 6241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "flog, whip; chastise"
    },
    "Keys": {
      "Hanzi": 4097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4097,
      "Radical": "手",
      "FrequencyRank": 4510,
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "藩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān",
      "PrimaryDefinition": "fence; boundary; outlying border"
    },
    "Keys": {
      "Hanzi": 6322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6322,
      "Radical": "艸",
      "FrequencyRank": 2622,
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "目录": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùlù",
      "PrimaryDefinition": "beadroll, docket, catalog, contents, listing, catalogue, directory, muster, list, table of contents, bill, inventory, tabulation, cataloguer, table, cataloger, tabular matter, schedule"
    },
    "Keys": {
      "Word": 8350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亲手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnshǒu",
      "PrimaryDefinition": "personally, with one's own hands"
    },
    "Keys": {
      "Word": 8725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "year; age; harvest"
    },
    "Keys": {
      "Hanzi": 436,
      "Word": 349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "46.3",
      "Grade": 1,
      "GeneralStandard": 436,
      "Radical": "山",
      "HSK": 1,
      "FrequencyRank": 772,
      "StrokeCount": 6
    }
  },
  "同胞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngbāo",
      "PrimaryDefinition": "offspring of same parents, paisano, neighbour, countrymen, countryfolk, fellow countryman, neighbor, compatriot, countryman, cousin"
    },
    "Keys": {
      "Word": 5094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "倜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "raise high; unrestrained"
    },
    "Keys": {
      "Hanzi": 4573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4573,
      "Radical": "人",
      "FrequencyRank": 4806,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "这时候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè shíhòu",
      "PrimaryDefinition": "At this moment"
    },
    "Keys": {
      "Word": 1210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "找出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎochū",
      "PrimaryDefinition": "rout out, find, unearth, rout up, rout, quest, observe, notice, grub, seek_out, dig_out, seek out, discover, find_out, root_out, spy, spot, detect, smoke out"
    },
    "Keys": {
      "Word": 1205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "倒霉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo méi",
      "PrimaryDefinition": "to have bad luck, to be out of luck"
    },
    "Keys": {
      "Word": 6195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歌手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēshǒu",
      "PrimaryDefinition": "warbler, voice, vocalist, singer, bulbul, performer, lark, songster"
    },
    "Keys": {
      "Word": 1486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "募": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "levy, raise; summon; recruit"
    },
    "Keys": {
      "Hanzi": 2549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.11",
      "GeneralStandard": 2549,
      "Radical": "力",
      "HSK": 8,
      "FrequencyRank": 2657,
      "StrokeCount": 12
    }
  },
  "锉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5185,
      "Radical": "金",
      "FrequencyRank": 4866,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "范围": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fànwéi",
      "PrimaryDefinition": "demesne, latitude, shot, compass, extensity, panorama, parameter, boundary, arena, stretch, territory, radius, spectrum, scope, extension, sphere, orbit, circumscription, limits, purview, grasp, extent, region, space, limit, hemisphere, horizon, envelope, bounds, realm, terrain, tether, sphere of influence, range, circuit, domain, precinct, reach, area, length, spread, bailiwick, ambit, con"
    },
    "Keys": {
      "Word": 1440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "钠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "sodium, natrium; sharpen wood"
    },
    "Keys": {
      "Hanzi": 1530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1530,
      "Radical": "金",
      "FrequencyRank": 3479,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "骨头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔtou",
      "PrimaryDefinition": "os, strong character, bone, character, person of a certain character"
    },
    "Keys": {
      "Word": 2509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "馉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7411
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7411,
      "Radical": "食",
      "FrequencyRank": 8087,
      "RadicalIndex": "184.1",
      "StrokeCount": 12
    }
  },
  "滩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tān",
      "PrimaryDefinition": "bank, a sandbar, shoal; rapids"
    },
    "Keys": {
      "Hanzi": 3022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3022,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2062,
      "StrokeCount": 13
    }
  },
  "口气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuqi",
      "PrimaryDefinition": "tone, note, manner of speaking, tone of voice, implication"
    },
    "Keys": {
      "Word": 7842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "犨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu",
      "PrimaryDefinition": "panting of cow; grunting of ox"
    },
    "Keys": {
      "Hanzi": 8061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8061,
      "Radical": "牛",
      "RadicalIndex": "93.16",
      "StrokeCount": 20
    }
  },
  "摆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi",
      "PrimaryDefinition": "put, place; display; swing, sway"
    },
    "Keys": {
      "Hanzi": 2838,
      "Word": 2239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 4,
      "GeneralStandard": 2838,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1158,
      "StrokeCount": 13
    }
  },
  "泃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "river in Henan"
    },
    "Keys": {
      "Hanzi": 6723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6723,
      "Radical": "水",
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "鲀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": "blowfish, globefish, Spheroides vermicular"
    },
    "Keys": {
      "Hanzi": 5236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5236,
      "Radical": "魚",
      "FrequencyRank": 5586,
      "RadicalIndex": "195.4",
      "StrokeCount": 12
    }
  },
  "专家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānjiā",
      "PrimaryDefinition": "guru, pundit, adept, expertise, professional person, specializer, specialist, proficient, whiz, oner, expert, swami, savant, dab, professional, authority, stunner, technician"
    },
    "Keys": {
      "Word": 2197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "伈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐn",
      "PrimaryDefinition": "nervous, fearful"
    },
    "Keys": {
      "Hanzi": 6539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6539,
      "Radical": "人",
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "诎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "bend, stoop, crouch; to yield"
    },
    "Keys": {
      "Hanzi": 6627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6627,
      "Radical": "言",
      "FrequencyRank": 5378,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "挨打": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ái dǎ",
      "PrimaryDefinition": "to take a beating, to get thrashed, to come under attack"
    },
    "Keys": {
      "Word": 4292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "庥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "shade; shelter, protection"
    },
    "Keys": {
      "Hanzi": 4307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4307,
      "Radical": "广",
      "FrequencyRank": 5292,
      "RadicalIndex": "53.6",
      "StrokeCount": 9
    }
  },
  "沉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "sink, submerge; addicted to"
    },
    "Keys": {
      "Hanzi": 871,
      "Word": 2309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "Grade": 4,
      "GeneralStandard": 871,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 747,
      "StrokeCount": 7
    }
  },
  "漯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "river in northern Shandong"
    },
    "Keys": {
      "Hanzi": 5803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5803,
      "Radical": "水",
      "FrequencyRank": 6054,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "寒假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánjià",
      "PrimaryDefinition": "winter vacation"
    },
    "Keys": {
      "Word": 2533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "口试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒushì",
      "PrimaryDefinition": "oral examination, oral test"
    },
    "Keys": {
      "Word": 4798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "噜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lū",
      "PrimaryDefinition": "verbose, talkative; mumbling"
    },
    "Keys": {
      "Hanzi": 5935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5935,
      "Radical": "口",
      "FrequencyRank": 3143,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "丢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diū",
      "PrimaryDefinition": "discard"
    },
    "Keys": {
      "Hanzi": 447,
      "Word": 3424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.5",
      "Grade": 5,
      "GeneralStandard": 447,
      "Radical": "一",
      "HSK": 5,
      "FrequencyRank": 1639,
      "StrokeCount": 6
    }
  },
  "花样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāyàng",
      "PrimaryDefinition": "pattern, variety"
    },
    "Keys": {
      "Word": 7128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偏远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānyuǎn",
      "PrimaryDefinition": "remote, faraway"
    },
    "Keys": {
      "Word": 8525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "beating of ocean; surging of water"
    },
    "Keys": {
      "Hanzi": 5278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5278,
      "Radical": "水",
      "FrequencyRank": 6200,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "尪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wāng",
      "PrimaryDefinition": "lame"
    },
    "Keys": {
      "Hanzi": 6583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6583,
      "Radical": "尢",
      "RadicalIndex": "43.4",
      "StrokeCount": 7
    }
  },
  "籁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lài",
      "PrimaryDefinition": "bamboo flute; pipe; various sound"
    },
    "Keys": {
      "Hanzi": 6399
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6399,
      "Radical": "竹",
      "FrequencyRank": 4150,
      "RadicalIndex": "118.13",
      "StrokeCount": 19
    }
  },
  "囫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "entire, whole"
    },
    "Keys": {
      "Hanzi": 3720
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3720,
      "Radical": "囗",
      "FrequencyRank": 5108,
      "RadicalIndex": "31.4",
      "StrokeCount": 7
    }
  },
  "峘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6836,
      "Radical": "山",
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "阻止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔzhǐ",
      "PrimaryDefinition": "prevent, stop, prohibit"
    },
    "Keys": {
      "Word": 3215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "葎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "Humulus japonicus"
    },
    "Keys": {
      "Hanzi": 7315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7315,
      "Radical": "艸",
      "FrequencyRank": 5650,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "毕业生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìyèshēng",
      "PrimaryDefinition": "alumnus, postgraduate, grad, alum, alumna, graduating class, graduate"
    },
    "Keys": {
      "Word": 2263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "絮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "waste cotton, raw silk or cotton"
    },
    "Keys": {
      "Hanzi": 2809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "GeneralStandard": 2809,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 3125,
      "StrokeCount": 12
    }
  },
  "蒯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎi",
      "PrimaryDefinition": "a rush, of which various things are made"
    },
    "Keys": {
      "Hanzi": 5379
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5379,
      "Radical": "艸",
      "FrequencyRank": 5003,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "仕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "official; serve government"
    },
    "Keys": {
      "Hanzi": 3542
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3542,
      "Radical": "人",
      "FrequencyRank": 3042,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "定金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngjīn",
      "PrimaryDefinition": "down payment, advance payment"
    },
    "Keys": {
      "Word": 6310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "居": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "live, dwell, reside, sit"
    },
    "Keys": {
      "Hanzi": 1308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.5",
      "GeneralStandard": 1308,
      "Radical": "尸",
      "HSK": 4,
      "FrequencyRank": 678,
      "StrokeCount": 8
    }
  },
  "火柴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒchái",
      "PrimaryDefinition": "match (for lighting fire), CL:根[gen1],盒[he2]"
    },
    "Keys": {
      "Word": 3569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "焦躁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāozào",
      "PrimaryDefinition": "fretful, impatient"
    },
    "Keys": {
      "Word": 7475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "车道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēdào",
      "PrimaryDefinition": "traffic lane, driveway"
    },
    "Keys": {
      "Word": 5836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēng",
      "PrimaryDefinition": "strike, beat, stroke; jingling"
    },
    "Keys": {
      "Hanzi": 5180
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5180,
      "Radical": "金",
      "FrequencyRank": 4111,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "骐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "piebald horse; excellent horse"
    },
    "Keys": {
      "Hanzi": 5028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5028,
      "Radical": "馬",
      "FrequencyRank": 6298,
      "RadicalIndex": "187.8",
      "StrokeCount": 11
    }
  },
  "芝麻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīma",
      "PrimaryDefinition": "sesame (seed)"
    },
    "Keys": {
      "Word": 10745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūn",
      "PrimaryDefinition": "get married; marriage, wedding"
    },
    "Keys": {
      "Hanzi": 2481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.8",
      "GeneralStandard": 2481,
      "Radical": "女",
      "HSK": 3,
      "FrequencyRank": 942,
      "StrokeCount": 11
    }
  },
  "爪子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎzi",
      "PrimaryDefinition": "(animal's) claw"
    },
    "Keys": {
      "Word": 10877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "确切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèqiè",
      "PrimaryDefinition": "correct, accurate, clear and unambiguous, precise, exact, definite, tangible"
    },
    "Keys": {
      "Word": 8815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qù",
      "PrimaryDefinition": "alone; quiet, still"
    },
    "Keys": {
      "Hanzi": 5267
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5267,
      "Radical": "門",
      "FrequencyRank": 5142,
      "RadicalIndex": "169.9",
      "StrokeCount": 12
    }
  },
  "难题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nántí",
      "PrimaryDefinition": "problem, Gordian knot, grief, sticker, question, enigma, difficult problem, puzzler, spinosity, stickler, a hard nut to crack, puzzle, toughie, poser, difficulty, crux, nut, challenge, tickler, conundrum, knot, hump, knottiness, stinker, puzzlement, teaser, stumper, twister"
    },
    "Keys": {
      "Word": 891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "机密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīmì",
      "PrimaryDefinition": "secret, classified (information)"
    },
    "Keys": {
      "Word": 7266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "僧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sēng",
      "PrimaryDefinition": "Buddhist priest, monk; san of Sanskrit sangha"
    },
    "Keys": {
      "Hanzi": 3127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.12",
      "GeneralStandard": 3127,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2013,
      "StrokeCount": 14
    }
  },
  "鲜花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānhuā",
      "PrimaryDefinition": "flower, fresh flowers, CL:朵[duo3]"
    },
    "Keys": {
      "Word": 3011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "担当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāndāng",
      "PrimaryDefinition": "to take upon oneself, to assume"
    },
    "Keys": {
      "Word": 6156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "髹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "red lacquer; to lacquer"
    },
    "Keys": {
      "Hanzi": 6038
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6038,
      "Radical": "髟",
      "FrequencyRank": 6254,
      "RadicalIndex": "190.6",
      "StrokeCount": 16
    }
  },
  "舣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "to moor a boat to the bank"
    },
    "Keys": {
      "Hanzi": 6861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6861,
      "Radical": "舟",
      "FrequencyRank": 8377,
      "RadicalIndex": "137.3",
      "StrokeCount": 9
    }
  },
  "海岸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiàn",
      "PrimaryDefinition": "seashore, coastal, seaside, tidewater, seacoast, shore, coast, staith, rivage, seaboard, sea, beach"
    },
    "Keys": {
      "Word": 6960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "intelligent, clever, bright"
    },
    "Keys": {
      "Hanzi": 3206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.9",
      "GeneralStandard": 3206,
      "Radical": "耳",
      "HSK": 5,
      "FrequencyRank": 1669,
      "StrokeCount": 15
    }
  },
  "判决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pànjué",
      "PrimaryDefinition": "adjudge"
    },
    "Keys": {
      "Word": 8469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "copper, brass, bronze"
    },
    "Keys": {
      "Hanzi": 2306,
      "Word": 9543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.6",
      "Grade": 7,
      "GeneralStandard": 2306,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 1772,
      "StrokeCount": 11
    }
  },
  "诒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "bequeath, pass on to future generations"
    },
    "Keys": {
      "Hanzi": 3800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3800,
      "Radical": "言",
      "FrequencyRank": 4352,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "思念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīniàn",
      "PrimaryDefinition": "to think of, to long for, to miss"
    },
    "Keys": {
      "Word": 9316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倾斜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxié",
      "PrimaryDefinition": "to incline, to lean, to slant, to slope, to tilt"
    },
    "Keys": {
      "Word": 8742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "corridor, hallway; luxuriant"
    },
    "Keys": {
      "Hanzi": 3756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3756,
      "Radical": "广",
      "FrequencyRank": 4417,
      "RadicalIndex": "53.4",
      "StrokeCount": 7
    }
  },
  "黉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "school"
    },
    "Keys": {
      "Hanzi": 7912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7912,
      "Radical": "黃",
      "FrequencyRank": 7225,
      "RadicalIndex": "201.5",
      "StrokeCount": 16
    }
  },
  "敕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "an imperial order or decree"
    },
    "Keys": {
      "Hanzi": 4783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4783,
      "Radical": "攴",
      "FrequencyRank": 3435,
      "RadicalIndex": "66.7",
      "StrokeCount": 11
    }
  },
  "栀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "gardenia"
    },
    "Keys": {
      "Hanzi": 4153
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4153,
      "Radical": "木",
      "FrequencyRank": 5421,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "爆发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàofā",
      "PrimaryDefinition": "erupt, burst/break out"
    },
    "Keys": {
      "Word": 4312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "方便面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngbiànmiàn",
      "PrimaryDefinition": "instant noodles"
    },
    "Keys": {
      "Word": 652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "年轻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánqīng",
      "PrimaryDefinition": "junior, young"
    },
    "Keys": {
      "Word": 892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "旄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "a kind of ancient flag; old"
    },
    "Keys": {
      "Hanzi": 4627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4627,
      "Radical": "方",
      "FrequencyRank": 6107,
      "RadicalIndex": "70.6",
      "StrokeCount": 10
    }
  },
  "凛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐn",
      "PrimaryDefinition": "to shiver with cold or fear"
    },
    "Keys": {
      "Hanzi": 3284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3284,
      "Radical": "冫",
      "FrequencyRank": 3014,
      "RadicalIndex": "15.13",
      "StrokeCount": 15
    }
  },
  "错过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòguò",
      "PrimaryDefinition": "pass up, muff, throw_away, lose, let slip, miss, overslip, balk, overlook, cross, pass_up, slip"
    },
    "Keys": {
      "Word": 4463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "钣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎn",
      "PrimaryDefinition": "plate"
    },
    "Keys": {
      "Hanzi": 4242
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4242,
      "Radical": "金",
      "FrequencyRank": 5705,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "伐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "cut down, subjugate, attack"
    },
    "Keys": {
      "Hanzi": 463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 463,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 1810,
      "StrokeCount": 6
    }
  },
  "拨打": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōdǎ",
      "PrimaryDefinition": "to call, to dial"
    },
    "Keys": {
      "Word": 4339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "两栖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎngqī",
      "PrimaryDefinition": "amphibious, dual-talented, able to work in two different lines"
    },
    "Keys": {
      "Word": 8035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎn",
      "PrimaryDefinition": "turn over, roll"
    },
    "Keys": {
      "Hanzi": 3097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3097,
      "Radical": "車",
      "FrequencyRank": 3535,
      "RadicalIndex": "159.1",
      "StrokeCount": 14
    }
  },
  "垢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "dirt, filth, stains; dirty"
    },
    "Keys": {
      "Hanzi": 1384
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1384,
      "Radical": "土",
      "FrequencyRank": 3284,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "敢情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnqing",
      "PrimaryDefinition": "actually, as it turns out, indeed, of course"
    },
    "Keys": {
      "Word": 6672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "wide open eyes; to gaze in astonishment"
    },
    "Keys": {
      "Hanzi": 3896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3896,
      "Radical": "目",
      "FrequencyRank": 5897,
      "RadicalIndex": "109.3",
      "StrokeCount": 8
    }
  },
  "同学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngxué",
      "PrimaryDefinition": "class fellow, fellow student, condisciple, classmate, schoolmate, schoolfellow, form of address used in speaking to a student"
    },
    "Keys": {
      "Word": 365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "悈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "to enjoin upon; urgent"
    },
    "Keys": {
      "Hanzi": 7067
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7067,
      "Radical": "心",
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "饮水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnshuǐ",
      "PrimaryDefinition": "drinking water"
    },
    "Keys": {
      "Word": 10395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "概括": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàikuò",
      "PrimaryDefinition": "to summarize, to generalize, briefly, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "颥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "the temporal bone"
    },
    "Keys": {
      "Hanzi": 8055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8055,
      "Radical": "頁",
      "FrequencyRank": 7185,
      "RadicalIndex": "181.14",
      "StrokeCount": 20
    }
  },
  "建造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànzào",
      "PrimaryDefinition": "build, construct, make"
    },
    "Keys": {
      "Word": 3602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "裈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "trousers, pants, shorts, drawers"
    },
    "Keys": {
      "Hanzi": 7252
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7252,
      "Radical": "衣",
      "FrequencyRank": 7190,
      "RadicalIndex": "145.6",
      "StrokeCount": 11
    }
  },
  "栲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎo",
      "PrimaryDefinition": "mangrove"
    },
    "Keys": {
      "Hanzi": 4447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4447,
      "Radical": "木",
      "FrequencyRank": 6418,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "豁出去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō chuqu",
      "PrimaryDefinition": "to throw caution to the wind, to press one's luck, to go for broke"
    },
    "Keys": {
      "Word": 7235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桃花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táohuā",
      "PrimaryDefinition": "Peach flower"
    },
    "Keys": {
      "Word": 3967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "义工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìgōng",
      "PrimaryDefinition": "volunteer"
    },
    "Keys": {
      "Word": 10355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piàn",
      "PrimaryDefinition": "slice"
    },
    "Keys": {
      "Hanzi": 130,
      "Radical": 91,
      "Word": 906
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "91",
      "Grade": 2,
      "GeneralStandard": 130,
      "Radical": "片",
      "HSK": 2,
      "FrequencyRank": 455,
      "StrokeCount": 4
    }
  },
  "涠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "still water"
    },
    "Keys": {
      "Hanzi": 4650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4650,
      "Radical": "水",
      "FrequencyRank": 6083,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "敢于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnyú",
      "PrimaryDefinition": "to have the courage to do sth, to dare to, bold in"
    },
    "Keys": {
      "Word": 4592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "时光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíguāng",
      "PrimaryDefinition": "years, times, days, hour, time"
    },
    "Keys": {
      "Word": 3900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鹈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "pelican"
    },
    "Keys": {
      "Hanzi": 5276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5276,
      "Radical": "鳥",
      "FrequencyRank": 5868,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "蜘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "spider"
    },
    "Keys": {
      "Hanzi": 3108
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3108,
      "Radical": "虫",
      "FrequencyRank": 3043,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "歌声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēshēng",
      "PrimaryDefinition": "voice, song, singing, sound of singing, singing voice"
    },
    "Keys": {
      "Word": 1484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "突发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūfā",
      "PrimaryDefinition": "burst out or occur suddenly"
    },
    "Keys": {
      "Word": 9573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酒店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔdiàn",
      "PrimaryDefinition": "wineshop, public house"
    },
    "Keys": {
      "Word": 795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "介意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè yì",
      "PrimaryDefinition": "to care about, to take offense, to mind"
    },
    "Keys": {
      "Word": 7545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手脚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒujiǎo",
      "PrimaryDefinition": "hand and foot, motions (of hands/feet), underhand method, trick"
    },
    "Keys": {
      "Word": 9176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāng",
      "PrimaryDefinition": "vulgar person, country man"
    },
    "Keys": {
      "Hanzi": 3608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3608,
      "Radical": "人",
      "FrequencyRank": 5659,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "辍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuò",
      "PrimaryDefinition": "stop, suspend, halt"
    },
    "Keys": {
      "Hanzi": 5120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5120,
      "Radical": "車",
      "FrequencyRank": 4060,
      "RadicalIndex": "159.8",
      "StrokeCount": 12
    }
  },
  "颊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "cheeks, jaw"
    },
    "Keys": {
      "Hanzi": 2597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.6",
      "GeneralStandard": 2597,
      "Radical": "頁",
      "HSK": 8,
      "FrequencyRank": 2655,
      "StrokeCount": 12
    }
  },
  "诓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāng",
      "PrimaryDefinition": "cheat, swindle, lie"
    },
    "Keys": {
      "Hanzi": 4038
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4038,
      "Radical": "言",
      "FrequencyRank": 4937,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "煺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5565,
      "Radical": "火",
      "FrequencyRank": 6025,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "诲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "teach, instruct; encourage, urge"
    },
    "Keys": {
      "Hanzi": 1716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1716,
      "Radical": "言",
      "FrequencyRank": 3510,
      "RadicalIndex": "149.7",
      "StrokeCount": 9
    }
  },
  "部队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùduì",
      "PrimaryDefinition": "military personnel, troops, troop, regular army, soldiery, army, party, company"
    },
    "Keys": {
      "Word": 4362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "评审": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngshěn",
      "PrimaryDefinition": "judge, referee, umpire"
    },
    "Keys": {
      "Word": 8557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "完好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánhǎo",
      "PrimaryDefinition": "intact, in good condition"
    },
    "Keys": {
      "Word": 9650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "客机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèjī",
      "PrimaryDefinition": "passenger plane"
    },
    "Keys": {
      "Word": 7814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "织": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "weave, knit; organize, unite"
    },
    "Keys": {
      "Hanzi": 1342,
      "Word": 5350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 6,
      "GeneralStandard": 1342,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 578,
      "StrokeCount": 8
    }
  },
  "天桥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānqiáo",
      "PrimaryDefinition": "Tianqiao district of Ji'nan city 濟南市|济南市[Ji3 nan2 shi4], Shandong, Tianqiao district in Beijing, formerly a center of folk culture, overhead walkway, pedestrian bridge"
    },
    "Keys": {
      "Word": 9481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扭曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔqū",
      "PrimaryDefinition": "distort"
    },
    "Keys": {
      "Word": 8429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7642
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7642,
      "Radical": "艸",
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "埌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làng",
      "PrimaryDefinition": "waste"
    },
    "Keys": {
      "Hanzi": 6947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6947,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "碳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn",
      "PrimaryDefinition": "carbon"
    },
    "Keys": {
      "Hanzi": 3092,
      "Word": 9424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.9",
      "Grade": 7,
      "GeneralStandard": 3092,
      "Radical": "石",
      "HSK": 8,
      "FrequencyRank": 2740,
      "StrokeCount": 14
    }
  },
  "澽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7905,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "脖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "neck"
    },
    "Keys": {
      "Hanzi": 2356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.7",
      "GeneralStandard": 2356,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2175,
      "StrokeCount": 11
    }
  },
  "堾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7289,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "要强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàoqiáng",
      "PrimaryDefinition": "eager to excel, eager to get ahead in life, strong-minded"
    },
    "Keys": {
      "Word": 10249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "徙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.8",
      "GeneralStandard": 2340,
      "Radical": "彳",
      "HSK": 6,
      "FrequencyRank": 3411,
      "StrokeCount": 11
    }
  },
  "乐器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèqì",
      "PrimaryDefinition": "chime, axe, musical instrument, instrument"
    },
    "Keys": {
      "Word": 10563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "农场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngchǎng",
      "PrimaryDefinition": "rancher, pen, farming, wick, ranch, hacienda, grange, farm, homestead, farmstead, plantation, steading, land"
    },
    "Keys": {
      "Word": 8434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亲切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnqiè",
      "PrimaryDefinition": "cordial, genial, warm"
    },
    "Keys": {
      "Word": 1817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "饴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "sweet-meats; sweet-cakes; syrup"
    },
    "Keys": {
      "Hanzi": 3993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3993,
      "Radical": "食",
      "FrequencyRank": 5355,
      "RadicalIndex": "184.5",
      "StrokeCount": 8
    }
  },
  "垞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "small mound; place name; hillock"
    },
    "Keys": {
      "Hanzi": 6778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6778,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "强加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángjiā",
      "PrimaryDefinition": "to impose, to force upon"
    },
    "Keys": {
      "Word": 8688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "high or tall"
    },
    "Keys": {
      "Hanzi": 4231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4231,
      "Radical": "山",
      "RadicalIndex": "46.12",
      "StrokeCount": 9
    }
  },
  "细菌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìjūn",
      "PrimaryDefinition": "bacillus, bacilli, bacteria, B, bacteriological, microbe, contaminant, bacterium, clump, germ, microphyte, bacterial"
    },
    "Keys": {
      "Word": 5158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "高傲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoào",
      "PrimaryDefinition": "arrogant, haughty, proud"
    },
    "Keys": {
      "Word": 6692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "观测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāncè",
      "PrimaryDefinition": "observe, survey"
    },
    "Keys": {
      "Word": 6870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "the name of a certain stars; the god who is supposed to live in them"
    },
    "Keys": {
      "Hanzi": 4522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4522,
      "Radical": "网",
      "FrequencyRank": 3980,
      "RadicalIndex": "122.5",
      "StrokeCount": 10
    }
  },
  "感叹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎntàn",
      "PrimaryDefinition": "to sigh (with feeling), to lament"
    },
    "Keys": {
      "Word": 6680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèqì",
      "PrimaryDefinition": "steam, heat, CL:股[gu3]"
    },
    "Keys": {
      "Word": 8832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渊源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuānyuán",
      "PrimaryDefinition": "origin, source"
    },
    "Keys": {
      "Word": 10533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7205,
      "Radical": "鳥",
      "FrequencyRank": 8279,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "璘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "luster of jade"
    },
    "Keys": {
      "Hanzi": 6033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6033,
      "Radical": "玉",
      "RadicalIndex": "96.12",
      "StrokeCount": 16
    }
  },
  "浛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7063,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "麝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "musk deer"
    },
    "Keys": {
      "Hanzi": 6456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6456,
      "Radical": "鹿",
      "FrequencyRank": 4053,
      "RadicalIndex": "198.1",
      "StrokeCount": 21
    }
  },
  "彻夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèyè",
      "PrimaryDefinition": "the whole night"
    },
    "Keys": {
      "Word": 5846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小时候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoshíhou",
      "PrimaryDefinition": "in one's childhood"
    },
    "Keys": {
      "Word": 1092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "行走": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngzǒu",
      "PrimaryDefinition": "mooch, tread, go, make, navigate, go on foot, walk"
    },
    "Keys": {
      "Word": 10048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "任人宰割": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènrén-zǎigē",
      "PrimaryDefinition": "to get trampled on (idiom), to be taken advantage of"
    },
    "Keys": {
      "Word": 8866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "rising sun; brilliance; radiant"
    },
    "Keys": {
      "Hanzi": 502
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.2",
      "GeneralStandard": 502,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2846,
      "StrokeCount": 6
    }
  },
  "僔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7695,
      "Radical": "人",
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "原料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánliào",
      "PrimaryDefinition": "making, stuff, feedstock, ingredient, primary, raw_material, fodder, material, staple, raw material"
    },
    "Keys": {
      "Word": 3131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎo",
      "PrimaryDefinition": "good, excellent, fine; well"
    },
    "Keys": {
      "Hanzi": 581,
      "Word": 2541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "Grade": 4,
      "GeneralStandard": 581,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 82,
      "StrokeCount": 6
    }
  },
  "亲自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnzì",
      "PrimaryDefinition": "personally, in person, firsthand"
    },
    "Keys": {
      "Word": 1823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "度假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù jià",
      "PrimaryDefinition": "spend one's holidays"
    },
    "Keys": {
      "Word": 6366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "试卷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjuàn",
      "PrimaryDefinition": "examination paper, test paper, CL:份[fen4],張|张[zhang1]"
    },
    "Keys": {
      "Word": 2872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "作客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò kè",
      "PrimaryDefinition": "to live somewhere as a visitor, to stay with sb as a guest, to sojourn"
    },
    "Keys": {
      "Word": 11036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "clam; oyster"
    },
    "Keys": {
      "Hanzi": 7699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7699,
      "Radical": "魚",
      "FrequencyRank": 7583,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "抱歉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàoqiàn",
      "PrimaryDefinition": "to be sorry, to feel apologetic, sorry!"
    },
    "Keys": {
      "Word": 4304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "弇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "cover over, hide; narrow-necked"
    },
    "Keys": {
      "Hanzi": 6862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6862,
      "Radical": "廾",
      "RadicalIndex": "55.6",
      "StrokeCount": 9
    }
  },
  "压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "press; oppress; crush; pressure"
    },
    "Keys": {
      "Hanzi": 385,
      "Word": 2075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "27.4",
      "Grade": 3,
      "GeneralStandard": 385,
      "Radical": "厂",
      "HSK": 3,
      "FrequencyRank": 756,
      "StrokeCount": 6
    }
  },
  "抒情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūqíng",
      "PrimaryDefinition": "to express emotion, lyric"
    },
    "Keys": {
      "Word": 9211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "chess; any game similar to chess"
    },
    "Keys": {
      "Hanzi": 2564,
      "Word": 8601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "Grade": 7,
      "GeneralStandard": 2564,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2089,
      "StrokeCount": 12
    }
  },
  "同盟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngméng",
      "PrimaryDefinition": "alliance"
    },
    "Keys": {
      "Word": 9537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "arsenic"
    },
    "Keys": {
      "Hanzi": 4477
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4477,
      "Radical": "石",
      "FrequencyRank": 4452,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "謇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "stutter; speak out boldly"
    },
    "Keys": {
      "Hanzi": 6299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6299,
      "Radical": "言",
      "FrequencyRank": 4913,
      "RadicalIndex": "149.1",
      "StrokeCount": 17
    }
  },
  "绡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "raw silk fabric"
    },
    "Keys": {
      "Hanzi": 4701
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4701,
      "Radical": "糸",
      "FrequencyRank": 6191,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "当即": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngjí",
      "PrimaryDefinition": "at once, on the spot"
    },
    "Keys": {
      "Word": 6172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "去年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qùnián",
      "PrimaryDefinition": "last year"
    },
    "Keys": {
      "Word": 299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "鹑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chún",
      "PrimaryDefinition": "quail; Turnix species (various)"
    },
    "Keys": {
      "Hanzi": 5538
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5538,
      "Radical": "鳥",
      "FrequencyRank": 4348,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "腐朽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔxiǔ",
      "PrimaryDefinition": "rotten, decayed, decadent, degenerate"
    },
    "Keys": {
      "Word": 6621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抵挡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐdǎng",
      "PrimaryDefinition": "to resist, to hold back, to stop, to ward off, to withstand"
    },
    "Keys": {
      "Word": 6238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "感触": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnchù",
      "PrimaryDefinition": "feel, texture, sentiment, thoughts and feelings, feeling, tactile property"
    },
    "Keys": {
      "Word": 6674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "连夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liányè",
      "PrimaryDefinition": "that very night, through the night, for several nights in a row"
    },
    "Keys": {
      "Word": 8018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "blocked or separated; to prevent"
    },
    "Keys": {
      "Hanzi": 4319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.6",
      "GeneralStandard": 4319,
      "Radical": "門",
      "HSK": 9,
      "FrequencyRank": 4004,
      "StrokeCount": 9
    }
  },
  "颓废": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuífèi",
      "PrimaryDefinition": "decadent, dispirited, depressed, dejected"
    },
    "Keys": {
      "Word": 9603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "silk"
    },
    "Keys": {
      "Radical": 120
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "推迟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīchí",
      "PrimaryDefinition": "to postpone, to put off, to defer"
    },
    "Keys": {
      "Word": 2953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "张扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāngyáng",
      "PrimaryDefinition": "Zhang Yang (1967-), PRC film director and screenwriter, to display ostentatiously, to bring out into the open, to make public, to spread around, flamboyant, brash"
    },
    "Keys": {
      "Word": 10651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "空虚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngxū",
      "PrimaryDefinition": "hollow, emptiness, meaningless"
    },
    "Keys": {
      "Word": 7824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驱动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūdòng",
      "PrimaryDefinition": "prompt, urge, spur on, drive"
    },
    "Keys": {
      "Word": 8778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "abruptly, suddenly; abrupt"
    },
    "Keys": {
      "Hanzi": 4928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4928,
      "Radical": "犬",
      "FrequencyRank": 3316,
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "塱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎng",
      "PrimaryDefinition": "place name in Guangdong province"
    },
    "Keys": {
      "Hanzi": 7601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7601,
      "Radical": "土",
      "RadicalIndex": "32.1",
      "StrokeCount": 13
    }
  },
  "伴奏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànzòu",
      "PrimaryDefinition": "accompany (with musical instruments)"
    },
    "Keys": {
      "Word": 5503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "kiosk, pavilion"
    },
    "Keys": {
      "Hanzi": 5664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5664,
      "Radical": "木",
      "FrequencyRank": 4333,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "生物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngwù",
      "PrimaryDefinition": "living thing, organism"
    },
    "Keys": {
      "Word": 9061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "mother horse"
    },
    "Keys": {
      "Hanzi": 5032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5032,
      "Radical": "馬",
      "FrequencyRank": 7746,
      "RadicalIndex": "187.8",
      "StrokeCount": 11
    }
  },
  "出资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū zī",
      "PrimaryDefinition": "to fund, to put money into sth, to invest"
    },
    "Keys": {
      "Word": 5978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū",
      "PrimaryDefinition": "protrude, bulge out, convex"
    },
    "Keys": {
      "Hanzi": 229,
      "Word": 9571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "17.3",
      "Grade": 7,
      "GeneralStandard": 229,
      "Radical": "凵",
      "HSK": 9,
      "FrequencyRank": 2857,
      "StrokeCount": 5
    }
  },
  "舻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "bow or prow of boat"
    },
    "Keys": {
      "Hanzi": 4916
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4916,
      "Radical": "舟",
      "FrequencyRank": 5308,
      "RadicalIndex": "137.5",
      "StrokeCount": 11
    }
  },
  "坦率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎnshuài",
      "PrimaryDefinition": "frank (discussion), blunt, open"
    },
    "Keys": {
      "Word": 9413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不由自主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyóu-zìzhǔ",
      "PrimaryDefinition": "can't help, involuntarily (idiom)"
    },
    "Keys": {
      "Word": 5719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下意识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàyìshi",
      "PrimaryDefinition": "subconscious mind"
    },
    "Keys": {
      "Word": 9875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "捍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "ward off, guard against, defend"
    },
    "Keys": {
      "Hanzi": 1792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "GeneralStandard": 1792,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3255,
      "StrokeCount": 10
    }
  },
  "开夜车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi yèchē",
      "PrimaryDefinition": "to burn the midnight oil, to work late into the night"
    },
    "Keys": {
      "Word": 4782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "目不转睛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùbùzhuǎnjīng",
      "PrimaryDefinition": "unable to take one's eyes off (idiom), to gaze steadily, to stare"
    },
    "Keys": {
      "Word": 8345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā",
      "PrimaryDefinition": "fork of a tree; a pitchfork"
    },
    "Keys": {
      "Hanzi": 3681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3681,
      "Radical": "木",
      "FrequencyRank": 4529,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "主要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔyào",
      "PrimaryDefinition": "main, chief, principal, major"
    },
    "Keys": {
      "Word": 1248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "异性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìxìng",
      "PrimaryDefinition": "metamerism, isomerism, heterosexual, opposite sex, different nature"
    },
    "Keys": {
      "Word": 10363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爷爷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yéye",
      "PrimaryDefinition": "grandad, Grandpa, granddad, grandpa, (paternal) grandfather, gramps, granddaddy, grandfather"
    },
    "Keys": {
      "Word": 421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "啕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "wail"
    },
    "Keys": {
      "Hanzi": 4843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4843,
      "Radical": "口",
      "FrequencyRank": 4425,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "球场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúchǎng",
      "PrimaryDefinition": "playing_field, gridiron, court, ball field, field, pitch, course, park, ballpark"
    },
    "Keys": {
      "Word": 937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "层": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céng",
      "PrimaryDefinition": "storey, layer, floor, stratum"
    },
    "Keys": {
      "Hanzi": 902,
      "Word": 547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.4",
      "Grade": 2,
      "GeneralStandard": 902,
      "Radical": "尸",
      "HSK": 2,
      "FrequencyRank": 699,
      "StrokeCount": 7
    }
  },
  "敏锐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐnruì",
      "PrimaryDefinition": "keen, incisive, acute, sharp"
    },
    "Keys": {
      "Word": 8304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晚年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnnián",
      "PrimaryDefinition": "one's later years"
    },
    "Keys": {
      "Word": 9663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "tent; screen, mosquito net"
    },
    "Keys": {
      "Hanzi": 755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.4",
      "GeneralStandard": 755,
      "Radical": "巾",
      "HSK": 7,
      "FrequencyRank": 1603,
      "StrokeCount": 7
    }
  },
  "鹡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "wagtail"
    },
    "Keys": {
      "Hanzi": 7806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7806,
      "Radical": "鳥",
      "FrequencyRank": 6932,
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "基地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīdì",
      "PrimaryDefinition": "base"
    },
    "Keys": {
      "Word": 3578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "喈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "music; melody"
    },
    "Keys": {
      "Hanzi": 5134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5134,
      "Radical": "口",
      "FrequencyRank": 6652,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "蠋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "caterpillar"
    },
    "Keys": {
      "Hanzi": 8036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8036,
      "Radical": "虫",
      "FrequencyRank": 6934,
      "RadicalIndex": "142.13",
      "StrokeCount": 19
    }
  },
  "莺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "oriole, green finch; Sylvia species (various)"
    },
    "Keys": {
      "Hanzi": 1831
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1831,
      "Radical": "艸",
      "FrequencyRank": 2902,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "大姐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjiě",
      "PrimaryDefinition": "big sister, elder sister, older sister (also polite term of address for a girl or woman slightly older than the speaker)"
    },
    "Keys": {
      "Word": 2373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "等候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děnghòu",
      "PrimaryDefinition": "hold back, wait, look, expect, abide, await, hold off, tarry, expectation, hang_on"
    },
    "Keys": {
      "Word": 3412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "野兽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěshòu",
      "PrimaryDefinition": "beast, wild animal"
    },
    "Keys": {
      "Word": 10258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奖牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngpái",
      "PrimaryDefinition": "medal (awarded as a prize)"
    },
    "Keys": {
      "Word": 7447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酸甜苦辣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suān-tián-kǔ-là",
      "PrimaryDefinition": "sour, sweet, bitter and spicy hot, fig. the joys and sorrows of life"
    },
    "Keys": {
      "Word": 3945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "聘用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìnyòng",
      "PrimaryDefinition": "to employ, to hire"
    },
    "Keys": {
      "Word": 8542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàn",
      "PrimaryDefinition": "ceremonial libation cup"
    },
    "Keys": {
      "Hanzi": 6420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6420,
      "Radical": "玉",
      "FrequencyRank": 4011,
      "RadicalIndex": "96.16",
      "StrokeCount": 20
    }
  },
  "债": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhài",
      "PrimaryDefinition": "debt, loan, liabilities"
    },
    "Keys": {
      "Hanzi": 1954,
      "Word": 5316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 6,
      "GeneralStandard": 1954,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1223,
      "StrokeCount": 10
    }
  },
  "岈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3714,
      "Radical": "山",
      "FrequencyRank": 7083,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "不亚于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyàyú",
      "PrimaryDefinition": "no less than, not inferior to"
    },
    "Keys": {
      "Word": 5680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扫描": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎomiáo",
      "PrimaryDefinition": "scan"
    },
    "Keys": {
      "Word": 8928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piě",
      "PrimaryDefinition": "kohl rabi"
    },
    "Keys": {
      "Hanzi": 3856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3856,
      "Radical": "艸",
      "FrequencyRank": 5404,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "大雁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàyàn",
      "PrimaryDefinition": "wild goose, CL:隻|只[zhi1]"
    },
    "Keys": {
      "Word": 6138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "约定俗成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuēdìng-súchéng",
      "PrimaryDefinition": "established by popular usage (idiom), common usage agreement, customary convention"
    },
    "Keys": {
      "Word": 10559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钟头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngtóu",
      "PrimaryDefinition": "hour"
    },
    "Keys": {
      "Word": 5371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "来源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láiyuán",
      "PrimaryDefinition": "resource, wellspring, origin, provenance, quarter, root, source, beginning, springhead, fodder, fount, quarry, fountain, mint, parentage, whence, paternity"
    },
    "Keys": {
      "Word": 2681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "圣贤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngxián",
      "PrimaryDefinition": "a sage, wise and holy man, virtuous ruler, Buddhist lama, wine"
    },
    "Keys": {
      "Word": 9073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "土匪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔfěi",
      "PrimaryDefinition": "bandit"
    },
    "Keys": {
      "Word": 9585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "消毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāodú",
      "PrimaryDefinition": "to disinfect, to sterilize"
    },
    "Keys": {
      "Word": 4050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鄑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "kick; place in Shandong province"
    },
    "Keys": {
      "Hanzi": 7316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7316,
      "Radical": "邑",
      "FrequencyRank": 9293,
      "RadicalIndex": "163.1",
      "StrokeCount": 12
    }
  },
  "太极": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàijí",
      "PrimaryDefinition": "the Absolute or Supreme Ultimate, the source of all things according to some interpretations of Chinese mythology"
    },
    "Keys": {
      "Word": 9390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "古朴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔpǔ",
      "PrimaryDefinition": "simple and unadorned (of art, architecture etc)"
    },
    "Keys": {
      "Word": 6828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": "bend, curve"
    },
    "Keys": {
      "Hanzi": 1616,
      "Word": 2963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.6",
      "Grade": 4,
      "GeneralStandard": 1616,
      "Radical": "弓",
      "HSK": 4,
      "FrequencyRank": 1662,
      "StrokeCount": 9
    }
  },
  "显然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnrán",
      "PrimaryDefinition": "evidently, manifestly, clear, visibly, plainly, plain, distinctly, clearly, patently, apparently, obviously"
    },
    "Keys": {
      "Word": 2028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "寁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎn",
      "PrimaryDefinition": "quick, fast"
    },
    "Keys": {
      "Hanzi": 7247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7247,
      "Radical": "宀",
      "RadicalIndex": "40.8",
      "StrokeCount": 11
    }
  },
  "不得已": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùdéyǐ",
      "PrimaryDefinition": "to act against one's will, to have no alternative but to, to have to, to have no choice, must"
    },
    "Keys": {
      "Word": 5694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "貊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "leopard; ancient tribe in northeastern China"
    },
    "Keys": {
      "Hanzi": 5513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5513,
      "Radical": "豸",
      "FrequencyRank": 6498,
      "RadicalIndex": "153.6",
      "StrokeCount": 13
    }
  },
  "癫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diān",
      "PrimaryDefinition": "crazy, mad; madness, mania, insan"
    },
    "Keys": {
      "Hanzi": 6455
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6455,
      "Radical": "疒",
      "FrequencyRank": 2887,
      "RadicalIndex": "104.16",
      "StrokeCount": 21
    }
  },
  "特价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèjià",
      "PrimaryDefinition": "special price"
    },
    "Keys": {
      "Word": 2922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "输入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūrù",
      "PrimaryDefinition": "import, input"
    },
    "Keys": {
      "Word": 1935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "脒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐ",
      "PrimaryDefinition": "open; throw away"
    },
    "Keys": {
      "Hanzi": 4596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4596,
      "Radical": "肉",
      "FrequencyRank": 6699,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "奇怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíguài",
      "PrimaryDefinition": "wonder"
    },
    "Keys": {
      "Word": 1807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "骖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cān",
      "PrimaryDefinition": "two outside ones in three horse"
    },
    "Keys": {
      "Hanzi": 5039
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5039,
      "Radical": "馬",
      "FrequencyRank": 6338,
      "RadicalIndex": "187.8",
      "StrokeCount": 11
    }
  },
  "屁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "break wind; fart; buttocks"
    },
    "Keys": {
      "Hanzi": 903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.4",
      "GeneralStandard": 903,
      "Radical": "尸",
      "HSK": 6,
      "FrequencyRank": 2295,
      "StrokeCount": 7
    }
  },
  "冉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎn",
      "PrimaryDefinition": "tender; weak; proceed gradually"
    },
    "Keys": {
      "Hanzi": 258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 258,
      "Radical": "冂",
      "FrequencyRank": 3070,
      "RadicalIndex": "13.3",
      "StrokeCount": 5
    }
  },
  "拜见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàijiàn",
      "PrimaryDefinition": "to pay a formal visit, to call to pay respects, to meet one's senior or superior"
    },
    "Keys": {
      "Word": 5483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "armor, chain mail"
    },
    "Keys": {
      "Hanzi": 4870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4870,
      "Radical": "金",
      "FrequencyRank": 4314,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "荒凉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāngliáng",
      "PrimaryDefinition": "desolate"
    },
    "Keys": {
      "Word": 7185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "橐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "a sack, a bag opening at both ends"
    },
    "Keys": {
      "Hanzi": 6066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6066,
      "Radical": "木",
      "FrequencyRank": 4560,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "并": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 531,
      "Word": 2277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "51.5",
      "Grade": 4,
      "GeneralStandard": 531,
      "Radical": "干",
      "HSK": 3,
      "FrequencyRank": 141,
      "StrokeCount": 6
    }
  },
  "填空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián kòng",
      "PrimaryDefinition": "to fill a job vacancy, to fill in a blank (e.g. on questionnaire or exam paper)"
    },
    "Keys": {
      "Word": 2930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "赡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "support, aid; rich, elegant"
    },
    "Keys": {
      "Hanzi": 3414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.13",
      "GeneralStandard": 3414,
      "Radical": "貝",
      "HSK": 9,
      "FrequencyRank": 3902,
      "StrokeCount": 17
    }
  },
  "会诊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì zhěn",
      "PrimaryDefinition": "consultation (medical), to meet for diagnosis, (by extension) consultation of different specialists"
    },
    "Keys": {
      "Word": 7223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圆形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánxíng",
      "PrimaryDefinition": "round, circular"
    },
    "Keys": {
      "Word": 10546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口腔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuqiāng",
      "PrimaryDefinition": "oral cavity"
    },
    "Keys": {
      "Word": 7841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "comply with, follow along; thereupon"
    },
    "Keys": {
      "Hanzi": 2746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "GeneralStandard": 2746,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 2017,
      "StrokeCount": 12
    }
  },
  "惨痛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎntòng",
      "PrimaryDefinition": "bitter, painful, deeply distressed"
    },
    "Keys": {
      "Word": 5760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "deep red; river in Shanxi provinc"
    },
    "Keys": {
      "Hanzi": 4397
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4397,
      "Radical": "糸",
      "FrequencyRank": 3987,
      "RadicalIndex": "120.6",
      "StrokeCount": 9
    }
  },
  "圬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "plaster over with layer of mud"
    },
    "Keys": {
      "Hanzi": 3569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3569,
      "Radical": "土",
      "FrequencyRank": 6985,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "唣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "chatter"
    },
    "Keys": {
      "Hanzi": 4516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4516,
      "Radical": "口",
      "FrequencyRank": 6753,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "挂钩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàgōu",
      "PrimaryDefinition": "hook (on which to hang sth), to couple, to link together, to establish contact with, hook, coupling links (e.g. between two railway coaches)"
    },
    "Keys": {
      "Word": 6854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzì",
      "PrimaryDefinition": "come from, be out of"
    },
    "Keys": {
      "Word": 5979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "股市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔshì",
      "PrimaryDefinition": "stock exchange, securities market, stock market, stock_market"
    },
    "Keys": {
      "Word": 6832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "襻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "a loop; a belt or band"
    },
    "Keys": {
      "Hanzi": 6492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6492,
      "Radical": "衣",
      "FrequencyRank": 6420,
      "RadicalIndex": "145.19",
      "StrokeCount": 24
    }
  },
  "特质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèzhì",
      "PrimaryDefinition": "attribute, distinctive feature, make-up, particularity, ethos, a speciality, point, lineament, colour, identification, property, distinction, thing, speciality, difference, specialty, character, idiosyncrasy, feeling of identity, distinctiveness, specialness, makeup, spot, dimension, peculiarity, quality"
    },
    "Keys": {
      "Word": 9449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "实际": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjì",
      "PrimaryDefinition": "practical, literal"
    },
    "Keys": {
      "Word": 975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "佁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6606,
      "Radical": "人",
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "回顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huígù",
      "PrimaryDefinition": "look back, review"
    },
    "Keys": {
      "Word": 3558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "高峰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāofēng",
      "PrimaryDefinition": "summit, apex, alp, vertex, acme, peak, height, pinnacle, high, all-time high"
    },
    "Keys": {
      "Word": 4594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "推敲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīqiāo",
      "PrimaryDefinition": "to think over"
    },
    "Keys": {
      "Word": 9598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "输送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūsòng",
      "PrimaryDefinition": "to transport, to convey, to deliver"
    },
    "Keys": {
      "Word": 9222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "压迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāpò",
      "PrimaryDefinition": "oppress, repress, constrict"
    },
    "Keys": {
      "Word": 5206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "茹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "roots; vegetables; eat; bear"
    },
    "Keys": {
      "Hanzi": 4136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4136,
      "Radical": "艸",
      "FrequencyRank": 3532,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "寻常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúncháng",
      "PrimaryDefinition": "usual, common, ordinary"
    },
    "Keys": {
      "Word": 10149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诱人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòurén",
      "PrimaryDefinition": "attractive, alluring, captivating, to attract, to captivate"
    },
    "Keys": {
      "Word": 10492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "carbuncle, sore, ulcer, abscess"
    },
    "Keys": {
      "Hanzi": 4618
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4618,
      "Radical": "疒",
      "FrequencyRank": 4950,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "宽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuān",
      "PrimaryDefinition": "broad, wide; spacious, vast"
    },
    "Keys": {
      "Hanzi": 2098,
      "Word": 2674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "Grade": 4,
      "GeneralStandard": 2098,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 1155,
      "StrokeCount": 10
    }
  },
  "旧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "old, ancient; former, past"
    },
    "Keys": {
      "Hanzi": 232,
      "Word": 1652
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.1",
      "Grade": 3,
      "GeneralStandard": 232,
      "Radical": "日",
      "HSK": 3,
      "FrequencyRank": 915,
      "StrokeCount": 5
    }
  },
  "信贷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìndài",
      "PrimaryDefinition": "credit, borrowed money"
    },
    "Keys": {
      "Word": 10035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "前往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánwǎng",
      "PrimaryDefinition": "depart, cruise, go_to, go, leave, go to, proceed to, pay, seek, leave for"
    },
    "Keys": {
      "Word": 1809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "系列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìliè",
      "PrimaryDefinition": "succession, set, spectrum, course, train, series"
    },
    "Keys": {
      "Word": 3001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "味儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèir5",
      "PrimaryDefinition": "taste"
    },
    "Keys": {
      "Word": 2980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "探": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn",
      "PrimaryDefinition": "find, locate; search, grope for"
    },
    "Keys": {
      "Hanzi": 2188,
      "Word": 9414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2188,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 917,
      "StrokeCount": 11
    }
  },
  "情报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngbào",
      "PrimaryDefinition": "poop, intelligence, intelligencer, tip-off, information, info, gen, dope"
    },
    "Keys": {
      "Word": 8753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "humpback; stoop"
    },
    "Keys": {
      "Hanzi": 3603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3603,
      "Radical": "人",
      "FrequencyRank": 6109,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "鸮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "owl"
    },
    "Keys": {
      "Hanzi": 4503
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4503,
      "Radical": "鳥",
      "FrequencyRank": 6353,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "馄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hún",
      "PrimaryDefinition": "dumpling soup, wonton"
    },
    "Keys": {
      "Hanzi": 4933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4933,
      "Radical": "食",
      "FrequencyRank": 4631,
      "RadicalIndex": "184.8",
      "StrokeCount": 11
    }
  },
  "画蛇添足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàshé-tiānzú",
      "PrimaryDefinition": "lit. draw legs on a snake (idiom), fig. to ruin the effect by adding sth superfluous, to overdo it"
    },
    "Keys": {
      "Word": 7148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "催促": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuīcù",
      "PrimaryDefinition": "to urge"
    },
    "Keys": {
      "Word": 6078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "货车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòchē",
      "PrimaryDefinition": "truck, van, freight wagon"
    },
    "Keys": {
      "Word": 7253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "财力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáilì",
      "PrimaryDefinition": "financial resources"
    },
    "Keys": {
      "Word": 5735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6724,
      "Radical": "水",
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "万无一失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wànwúyīshī",
      "PrimaryDefinition": "surefire, absolutely safe (idiom)"
    },
    "Keys": {
      "Word": 9664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "能耐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néngnai",
      "PrimaryDefinition": "ability, capability"
    },
    "Keys": {
      "Word": 8401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4764,
      "Radical": "艸",
      "FrequencyRank": 6134,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "城乡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxiāng",
      "PrimaryDefinition": "urban and rural, town and country"
    },
    "Keys": {
      "Word": 4414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "特定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèdìng",
      "PrimaryDefinition": "specifically, ad-hoc, given, specially designated, specific, specialized, ad hoc, specified"
    },
    "Keys": {
      "Word": 3970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "翾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "fly; to flit"
    },
    "Keys": {
      "Hanzi": 8037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8037,
      "Radical": "羽",
      "RadicalIndex": "124.13",
      "StrokeCount": 19
    }
  },
  "首席": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuxí",
      "PrimaryDefinition": "chief (representative, correspondent etc)"
    },
    "Keys": {
      "Word": 5036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "form, shape, appearance"
    },
    "Keys": {
      "Hanzi": 604,
      "Word": 5193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "59.4",
      "Grade": 6,
      "GeneralStandard": 604,
      "Radical": "彡",
      "HSK": 3,
      "FrequencyRank": 269,
      "StrokeCount": 7
    }
  },
  "报亭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàotíng",
      "PrimaryDefinition": "kiosk, newsstand"
    },
    "Keys": {
      "Word": 5535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "八": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "eight"
    },
    "Keys": {
      "Hanzi": 9,
      "Radical": 12,
      "Word": 5
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12",
      "Grade": 1,
      "GeneralStandard": 9,
      "Radical": "八",
      "HSK": 1,
      "FrequencyRank": 451,
      "StrokeCount": 2
    }
  },
  "特点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèdiǎn",
      "PrimaryDefinition": "distinctive feature, trait, point, flavor, singularity, flavour, distinction, characteristic, excellence, speciality, specialty, idiosyncrasy, salience, distinctiveness, specialness, feature, spot, peculiarity"
    },
    "Keys": {
      "Word": 1012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "伺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cì",
      "PrimaryDefinition": "serve, wait upon, attend; examine"
    },
    "Keys": {
      "Hanzi": 788
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 788,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 2782,
      "StrokeCount": 7
    }
  },
  "媄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měi",
      "PrimaryDefinition": "beautiful"
    },
    "Keys": {
      "Hanzi": 7453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7453,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "驼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "a camel; humpbacked; to carry on the back"
    },
    "Keys": {
      "Hanzi": 1347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1347,
      "Radical": "馬",
      "FrequencyRank": 2638,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "头号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóuhào",
      "PrimaryDefinition": "first rate, top rank, number one"
    },
    "Keys": {
      "Word": 9556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "限制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànzhì",
      "PrimaryDefinition": "restrictiveness"
    },
    "Keys": {
      "Word": 3016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "数据库": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùjùkù",
      "PrimaryDefinition": "database"
    },
    "Keys": {
      "Word": 9236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": "tremble, shiver, shudder, quiver"
    },
    "Keys": {
      "Hanzi": 1511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "GeneralStandard": 1511,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 3231,
      "StrokeCount": 9
    }
  },
  "光明磊落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngmíng-lěiluò",
      "PrimaryDefinition": "open and candid (idiom), straightforward and upright"
    },
    "Keys": {
      "Word": 6905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摆脱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi tuō",
      "PrimaryDefinition": "disembroil, free oneself, cast/shake off, fling_off, break away from, shake_off, disinvolve, disentangle, shake off, shift, wangle, cast off, extricate oneself from, shake, waste, free oneself from, disburden, ditch, manage, lose, cast, unload, extricate oneself, prescind, unship, slip, wriggle, shunt"
    },
    "Keys": {
      "Word": 2242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "月底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèdǐ",
      "PrimaryDefinition": "end of the month"
    },
    "Keys": {
      "Word": 3136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蓬勃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péngbó",
      "PrimaryDefinition": "full of vitality, vigorous, rising, flourishing"
    },
    "Keys": {
      "Word": 8494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "札": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhá",
      "PrimaryDefinition": "letter, note; correspondence"
    },
    "Keys": {
      "Hanzi": 3532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3532,
      "Radical": "木",
      "FrequencyRank": 3211,
      "RadicalIndex": "75.1",
      "StrokeCount": 5
    }
  },
  "抽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu",
      "PrimaryDefinition": "draw out, pull out; sprout"
    },
    "Keys": {
      "Hanzi": 966,
      "Word": 2330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 4,
      "GeneralStandard": 966,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1178,
      "StrokeCount": 8
    }
  },
  "早上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎoshang",
      "PrimaryDefinition": "early morning, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "化验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàyàn",
      "PrimaryDefinition": "laboratory test, chemical experiment, assay"
    },
    "Keys": {
      "Word": 7143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纯粹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúncuì",
      "PrimaryDefinition": "unmixed, unalloyed, pure, unadulterated"
    },
    "Keys": {
      "Word": 6028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "a place in eastern Shandong"
    },
    "Keys": {
      "Hanzi": 6929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6929,
      "Radical": "玉",
      "FrequencyRank": 5112,
      "RadicalIndex": "96.7",
      "StrokeCount": 10
    }
  },
  "环球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánqiú",
      "PrimaryDefinition": "around the world, worldwide"
    },
    "Keys": {
      "Word": 7166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "起程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ chéng",
      "PrimaryDefinition": "to set out, to leave"
    },
    "Keys": {
      "Word": 8614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纲要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngyào",
      "PrimaryDefinition": "outline, essential points"
    },
    "Keys": {
      "Word": 6686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "red, vermilion; name of ancient"
    },
    "Keys": {
      "Hanzi": 817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 817,
      "Radical": "彡",
      "FrequencyRank": 4075,
      "RadicalIndex": "59.4",
      "StrokeCount": 7
    }
  },
  "核对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héduì",
      "PrimaryDefinition": "to check, to verify, to audit, to examine"
    },
    "Keys": {
      "Word": 7048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "真是的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnshide",
      "PrimaryDefinition": "Really! (interj. of annoyance or frustration)"
    },
    "Keys": {
      "Word": 10702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "social custom; manners; courtesy"
    },
    "Keys": {
      "Hanzi": 318,
      "Word": 3684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.1",
      "Grade": 5,
      "GeneralStandard": 318,
      "Radical": "示",
      "HSK": 2,
      "FrequencyRank": 926,
      "StrokeCount": 5
    }
  },
  "探险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn xiǎn",
      "PrimaryDefinition": "explore, adventure"
    },
    "Keys": {
      "Word": 9421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聘任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìnrèn",
      "PrimaryDefinition": "to appoint (to a position), appointed"
    },
    "Keys": {
      "Word": 8545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "grapes"
    },
    "Keys": {
      "Hanzi": 2552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "GeneralStandard": 2552,
      "Radical": "艸",
      "HSK": 5,
      "FrequencyRank": 2130,
      "StrokeCount": 12
    }
  },
  "镬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "cauldron, large iron pot"
    },
    "Keys": {
      "Hanzi": 6344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6344,
      "Radical": "金",
      "FrequencyRank": 6061,
      "RadicalIndex": "167.13",
      "StrokeCount": 18
    }
  },
  "审美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěnměi",
      "PrimaryDefinition": "esthetics, appreciating the arts, taste"
    },
    "Keys": {
      "Word": 9042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎn",
      "PrimaryDefinition": "a dual pronoun, I, you and me, we two"
    },
    "Keys": {
      "Hanzi": 4298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4298,
      "Radical": "日",
      "FrequencyRank": 6447,
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "典范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnfàn",
      "PrimaryDefinition": "quintessence, nonpareil, example, specimen, nonsuch, apotheosis, ideal, nonesuch, prototype, monument, paragon, role model, soul, saint, model"
    },
    "Keys": {
      "Word": 6267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "里面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐmian",
      "PrimaryDefinition": "inside, interior"
    },
    "Keys": {
      "Word": 1693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "牛奶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niúnǎi",
      "PrimaryDefinition": "cow's milk"
    },
    "Keys": {
      "Word": 271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "栌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "supporting block; sumac, loquat"
    },
    "Keys": {
      "Hanzi": 4146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4146,
      "Radical": "木",
      "FrequencyRank": 6001,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "炼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "smelt, refine; distill, condense"
    },
    "Keys": {
      "Hanzi": 1655,
      "Word": 8027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "Grade": 7,
      "GeneralStandard": 1655,
      "Radical": "火",
      "HSK": 4,
      "FrequencyRank": 1763,
      "StrokeCount": 9
    }
  },
  "棤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7321
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7321,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "洈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6891,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "road, path, street; journey"
    },
    "Keys": {
      "Hanzi": 2918,
      "Word": 222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.6",
      "Grade": 1,
      "GeneralStandard": 2918,
      "Radical": "足",
      "HSK": 1,
      "FrequencyRank": 305,
      "StrokeCount": 13
    }
  },
  "作为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòwéi",
      "PrimaryDefinition": "regard as, look on as, take for"
    },
    "Keys": {
      "Word": 3218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "射击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèjī",
      "PrimaryDefinition": "shoot, fire"
    },
    "Keys": {
      "Word": 3877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鬈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "fine growth of hair curly hair"
    },
    "Keys": {
      "Hanzi": 6312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6312,
      "Radical": "髟",
      "FrequencyRank": 5094,
      "RadicalIndex": "190.8",
      "StrokeCount": 18
    }
  },
  "整齐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngqí",
      "PrimaryDefinition": "in good order, neat, tidy, even, regular"
    },
    "Keys": {
      "Word": 2145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "引诱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnyòu",
      "PrimaryDefinition": "lure, seduce"
    },
    "Keys": {
      "Word": 10393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "night, evening, dark"
    },
    "Keys": {
      "Hanzi": 2100
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "GeneralStandard": 2100,
      "Radical": "宀",
      "HSK": 8,
      "FrequencyRank": 3131,
      "StrokeCount": 10
    }
  },
  "潋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "waves, ripples; overflow"
    },
    "Keys": {
      "Hanzi": 5805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5805,
      "Radical": "水",
      "FrequencyRank": 6369,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "顾虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùlǜ",
      "PrimaryDefinition": "misgivings, apprehensions"
    },
    "Keys": {
      "Word": 6844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "courageous, brave; violent"
    },
    "Keys": {
      "Hanzi": 2093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2093,
      "Radical": "心",
      "FrequencyRank": 3073,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "扮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "dress up; dress up as"
    },
    "Keys": {
      "Hanzi": 636,
      "Word": 5500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 636,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1974,
      "StrokeCount": 7
    }
  },
  "权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "power, right, authority"
    },
    "Keys": {
      "Hanzi": 378,
      "Word": 4959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "Grade": 6,
      "GeneralStandard": 378,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 297,
      "StrokeCount": 6
    }
  },
  "类别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèibié",
      "PrimaryDefinition": "category, cubbyhole, categorization, race, kind, categorisation, reduction, persuasion, regimentation, rubric, classification, sort"
    },
    "Keys": {
      "Word": 7962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恐惧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒngjù",
      "PrimaryDefinition": "fear, dread"
    },
    "Keys": {
      "Word": 7828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piē",
      "PrimaryDefinition": "take fleeting glance at"
    },
    "Keys": {
      "Hanzi": 6168
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6168,
      "Radical": "目",
      "FrequencyRank": 2706,
      "RadicalIndex": "109.12",
      "StrokeCount": 16
    }
  },
  "票价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piàojià",
      "PrimaryDefinition": "ticket price, fare, admission fee"
    },
    "Keys": {
      "Word": 1792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "陞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "promote, rise, ascend"
    },
    "Keys": {
      "Hanzi": 6909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6909,
      "Radical": "阜",
      "FrequencyRank": 7554,
      "RadicalIndex": "170.7",
      "StrokeCount": 9
    }
  },
  "槎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "raft; time, occasion; to hew"
    },
    "Keys": {
      "Hanzi": 5403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5403,
      "Radical": "木",
      "FrequencyRank": 5143,
      "RadicalIndex": "75.1",
      "StrokeCount": 13
    }
  },
  "巴不得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bābude",
      "PrimaryDefinition": "(coll.) to be eager for, to long for, to look forward to"
    },
    "Keys": {
      "Word": 5458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "低温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīwēn",
      "PrimaryDefinition": "low temperature"
    },
    "Keys": {
      "Word": 4497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "频率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínlǜ",
      "PrimaryDefinition": "relative frequency, frequency, periodicity"
    },
    "Keys": {
      "Word": 8538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "万分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wànfēn",
      "PrimaryDefinition": "very much, extremely, one ten thousandth part"
    },
    "Keys": {
      "Word": 9659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "背景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèijǐng",
      "PrimaryDefinition": "context, circumstance, locale, background, ground, stage setting, mise en scene, groundwork, background knowledge, frame, milieu, backcloth, scene, setting, backdrop"
    },
    "Keys": {
      "Word": 2255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "缐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7459,
      "Radical": "糸",
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "岠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6595,
      "Radical": "山",
      "RadicalIndex": "46.5",
      "StrokeCount": 7
    }
  },
  "套餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàocān",
      "PrimaryDefinition": "set meal, product or service package (e.g. for a cell phone subscription)"
    },
    "Keys": {
      "Word": 2917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "谓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "say, tell; call, name; be called"
    },
    "Keys": {
      "Hanzi": 2466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.9",
      "GeneralStandard": 2466,
      "Radical": "言",
      "HSK": 4,
      "FrequencyRank": 945,
      "StrokeCount": 11
    }
  },
  "慌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāng",
      "PrimaryDefinition": "nervous, panicky, frantic"
    },
    "Keys": {
      "Hanzi": 2771,
      "Word": 3555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "Grade": 5,
      "GeneralStandard": 2771,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1650,
      "StrokeCount": 12
    }
  },
  "橘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jú",
      "PrimaryDefinition": "orange, tangerine"
    },
    "Keys": {
      "Hanzi": 3332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.12",
      "GeneralStandard": 3332,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 3614,
      "StrokeCount": 16
    }
  },
  "汽油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìyóu",
      "PrimaryDefinition": "gasoline"
    },
    "Keys": {
      "Word": 2799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "电子版": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànzǐbǎn",
      "PrimaryDefinition": "e-edition"
    },
    "Keys": {
      "Word": 3422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "阀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "powerful and influential group"
    },
    "Keys": {
      "Hanzi": 1638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.6",
      "GeneralStandard": 1638,
      "Radical": "門",
      "HSK": 9,
      "FrequencyRank": 2671,
      "StrokeCount": 9
    }
  },
  "鳙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "bighead"
    },
    "Keys": {
      "Hanzi": 6407
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6407,
      "Radical": "魚",
      "FrequencyRank": 5892,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "笞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "bamboo rod used for beatings"
    },
    "Keys": {
      "Hanzi": 4903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4903,
      "Radical": "竹",
      "FrequencyRank": 4583,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "力不从心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìbùcóngxīn",
      "PrimaryDefinition": "less capable than desirable (idiom), not as strong as one would wish, the spirit is willing but the flesh is weak"
    },
    "Keys": {
      "Word": 7990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惊叹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngtàn",
      "PrimaryDefinition": "to exclaim in admiration, a gasp of surprise"
    },
    "Keys": {
      "Word": 7603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng shēn",
      "PrimaryDefinition": "to go on a journey, to leave"
    },
    "Keys": {
      "Word": 6336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "画展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàzhǎn",
      "PrimaryDefinition": "art/paintings exhibition, art_exhibition, exhibition of paintings, art exhibition, art, paintings exhibition"
    },
    "Keys": {
      "Word": 7149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胆子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎnzi",
      "PrimaryDefinition": "courage, nerve, guts"
    },
    "Keys": {
      "Word": 6165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zú",
      "PrimaryDefinition": "foot"
    },
    "Keys": {
      "Hanzi": 731,
      "Radical": 157,
      "Word": 5407
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157",
      "Grade": 6,
      "GeneralStandard": 731,
      "Radical": "足",
      "HSK": 3,
      "FrequencyRank": 527,
      "StrokeCount": 7
    }
  },
  "传出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánchū",
      "PrimaryDefinition": "to transmit outwards, to disseminate, efferent (nerve)"
    },
    "Keys": {
      "Word": 4443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "臻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "reach, arrive; utmost, superior"
    },
    "Keys": {
      "Hanzi": 6081
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6081,
      "Radical": "至",
      "FrequencyRank": 3298,
      "RadicalIndex": "133.1",
      "StrokeCount": 16
    }
  },
  "芝士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīshì",
      "PrimaryDefinition": "cheese (loanword)"
    },
    "Keys": {
      "Word": 10746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "jade pointed at top"
    },
    "Keys": {
      "Hanzi": 3570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3570,
      "Radical": "土",
      "FrequencyRank": 3537,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "回到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huídào",
      "PrimaryDefinition": "remount, retour, recur, turn back, regress, regain, revert, go_back, return to, return, go back to"
    },
    "Keys": {
      "Word": 153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "变迁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànqiān",
      "PrimaryDefinition": "vicissitudinary, change"
    },
    "Keys": {
      "Word": 5607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曝光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào guāng",
      "PrimaryDefinition": "expose"
    },
    "Keys": {
      "Word": 5543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叉子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāzi",
      "PrimaryDefinition": "fork, CL:把[ba3]"
    },
    "Keys": {
      "Word": 3300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "畅销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàngxiāo",
      "PrimaryDefinition": "sell well"
    },
    "Keys": {
      "Word": 5823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屏幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngmù",
      "PrimaryDefinition": "screen (TV, computer or movie)"
    },
    "Keys": {
      "Word": 4911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "觚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "jug, winecup, goblet; square"
    },
    "Keys": {
      "Hanzi": 5244
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5244,
      "Radical": "角",
      "FrequencyRank": 6138,
      "RadicalIndex": "148.5",
      "StrokeCount": 12
    }
  },
  "深入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnrù",
      "PrimaryDefinition": "thorough, deep-going"
    },
    "Keys": {
      "Word": 1881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "狭隘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáài",
      "PrimaryDefinition": "narrow, tight, narrow minded, lacking in experience"
    },
    "Keys": {
      "Word": 9852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "throat, gullet, larynx; guttural"
    },
    "Keys": {
      "Hanzi": 2643
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "GeneralStandard": 2643,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2096,
      "StrokeCount": 12
    }
  },
  "完美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánměi",
      "PrimaryDefinition": "perfect, consummate"
    },
    "Keys": {
      "Word": 1997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "轻微": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngwēi",
      "PrimaryDefinition": "light, trifling, trivial"
    },
    "Keys": {
      "Word": 8737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "远方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnfāng",
      "PrimaryDefinition": "distance, beyond, beyondness, distant place, corner"
    },
    "Keys": {
      "Word": 5296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "低碳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dītàn",
      "PrimaryDefinition": "Low carbon"
    },
    "Keys": {
      "Word": 6231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瀌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "plenty"
    },
    "Keys": {
      "Hanzi": 8027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8027,
      "Radical": "水",
      "RadicalIndex": "85.15",
      "StrokeCount": 18
    }
  },
  "痔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "hemorrhoids, piles"
    },
    "Keys": {
      "Hanzi": 4938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4938,
      "Radical": "疒",
      "FrequencyRank": 3768,
      "RadicalIndex": "104.6",
      "StrokeCount": 11
    }
  },
  "地域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìyù",
      "PrimaryDefinition": "tract, area, latitude, quarter, territory, climate, region, scope, district, terrain, climactic"
    },
    "Keys": {
      "Word": 6254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "unit of weight, one twenty-fourth of a Chinese ounce (liang)"
    },
    "Keys": {
      "Hanzi": 4872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4872,
      "Radical": "金",
      "FrequencyRank": 3588,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "不约而同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyuēértóng",
      "PrimaryDefinition": "happen to coincide, coincidentally do sth."
    },
    "Keys": {
      "Word": 5722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "让座": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ràng zuò",
      "PrimaryDefinition": "to give up one's seat for sb"
    },
    "Keys": {
      "Word": 4965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "通俗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngsú",
      "PrimaryDefinition": "pop, common, popular"
    },
    "Keys": {
      "Word": 9525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "活该": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huógāi",
      "PrimaryDefinition": "(coll.) serve sb right, deservedly, ought, should"
    },
    "Keys": {
      "Word": 7236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "dispute, argue, debate, discuss"
    },
    "Keys": {
      "Hanzi": 3372,
      "Word": 5618
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "160.9",
      "Grade": 7,
      "GeneralStandard": 3372,
      "Radical": "辛",
      "HSK": 4,
      "FrequencyRank": 1355,
      "StrokeCount": 16
    }
  },
  "冥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "dark, gloomy, night; deep"
    },
    "Keys": {
      "Hanzi": 2121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2121,
      "Radical": "冖",
      "FrequencyRank": 2739,
      "RadicalIndex": "14.8",
      "StrokeCount": 10
    }
  },
  "褊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn",
      "PrimaryDefinition": "cramped, narrow, crowded; mean"
    },
    "Keys": {
      "Hanzi": 5823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5823,
      "Radical": "衣",
      "FrequencyRank": 5944,
      "RadicalIndex": "145.9",
      "StrokeCount": 14
    }
  },
  "秫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shú",
      "PrimaryDefinition": "glutinous variety of millet"
    },
    "Keys": {
      "Hanzi": 4554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4554,
      "Radical": "禾",
      "FrequencyRank": 5884,
      "RadicalIndex": "115.5",
      "StrokeCount": 10
    }
  },
  "汐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "night tides, evening ebb tide"
    },
    "Keys": {
      "Hanzi": 3625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3625,
      "Radical": "水",
      "FrequencyRank": 4186,
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "南": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nán",
      "PrimaryDefinition": "south; southern part; southward"
    },
    "Keys": {
      "Hanzi": 1422,
      "Word": 262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "24.7",
      "Grade": 1,
      "GeneralStandard": 1422,
      "Radical": "十",
      "HSK": 1,
      "FrequencyRank": 307,
      "StrokeCount": 9
    }
  },
  "说老实话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōlǎoshihuà",
      "PrimaryDefinition": "Truth"
    },
    "Keys": {
      "Word": 9296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià",
      "PrimaryDefinition": "under, underneath, below; down; inferior; bring down"
    },
    "Keys": {
      "Hanzi": 30,
      "Word": 1077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.2",
      "Grade": 2,
      "GeneralStandard": 30,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 42,
      "StrokeCount": 3
    }
  },
  "浼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měi",
      "PrimaryDefinition": "to request; to ask a favour of; to pollute, contaminate"
    },
    "Keys": {
      "Hanzi": 7064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7064,
      "Radical": "水",
      "FrequencyRank": 5934,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "器材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìcái",
      "PrimaryDefinition": "materials, materiel, equipment"
    },
    "Keys": {
      "Word": 8634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冒昧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màomèi",
      "PrimaryDefinition": "bold, presumptuous, to take the liberty of"
    },
    "Keys": {
      "Word": 8215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎ",
      "PrimaryDefinition": "cymbals"
    },
    "Keys": {
      "Hanzi": 6397
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6397,
      "Radical": "金",
      "FrequencyRank": 7557,
      "RadicalIndex": "167.13",
      "StrokeCount": 19
    }
  },
  "挂念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàniàn",
      "PrimaryDefinition": "concerned"
    },
    "Keys": {
      "Word": 6856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "state"
    },
    "Keys": {
      "Hanzi": 7197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7197,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "教养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoyǎng",
      "PrimaryDefinition": "to train, to educate, to bring up, to nurture, education, culture, upbringing, early conditioning"
    },
    "Keys": {
      "Word": 7494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéfèi",
      "PrimaryDefinition": "entrance_fee, tuition fee, premium, schooling, tuition"
    },
    "Keys": {
      "Word": 2074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "遗憾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíhàn",
      "PrimaryDefinition": "regret, pity"
    },
    "Keys": {
      "Word": 5239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "办不到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànbudào",
      "PrimaryDefinition": "impossible, can't be done, no can do, unable to accomplish"
    },
    "Keys": {
      "Word": 5489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shú",
      "PrimaryDefinition": "buy, redeem; ransome; atone for"
    },
    "Keys": {
      "Hanzi": 2652,
      "Word": 9224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.8",
      "Grade": 7,
      "GeneralStandard": 2652,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 2863,
      "StrokeCount": 12
    }
  },
  "反差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnchā",
      "PrimaryDefinition": "contrast, discrepancy"
    },
    "Keys": {
      "Word": 6467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "contribute for drinks; pool money"
    },
    "Keys": {
      "Hanzi": 8054
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8054,
      "Radical": "酉",
      "FrequencyRank": 5750,
      "RadicalIndex": "164.13",
      "StrokeCount": 20
    }
  },
  "呵护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēhù",
      "PrimaryDefinition": "to bless, to cherish, to take good care of, to conserve"
    },
    "Keys": {
      "Word": 7022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuō",
      "PrimaryDefinition": "drag, tow, haul; delay, prolong"
    },
    "Keys": {
      "Hanzi": 968,
      "Word": 5112
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 6,
      "GeneralStandard": 968,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1492,
      "StrokeCount": 8
    }
  },
  "隐形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnxíng",
      "PrimaryDefinition": "invisible"
    },
    "Keys": {
      "Word": 10402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "袅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎo",
      "PrimaryDefinition": "curling upwards; wavering gently"
    },
    "Keys": {
      "Hanzi": 4608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4608,
      "Radical": "衣",
      "FrequencyRank": 3517,
      "RadicalIndex": "145.4",
      "StrokeCount": 10
    }
  },
  "交际": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojì",
      "PrimaryDefinition": "communication, social intercourse"
    },
    "Keys": {
      "Word": 2619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "舒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "open up, unfold, stretch out; comfortable, easy"
    },
    "Keys": {
      "Hanzi": 2707
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "135.6",
      "GeneralStandard": 2707,
      "Radical": "舌",
      "HSK": 2,
      "FrequencyRank": 1459,
      "StrokeCount": 12
    }
  },
  "单身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānshēn",
      "PrimaryDefinition": "unmarried, single"
    },
    "Keys": {
      "Word": 6161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "返": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎn",
      "PrimaryDefinition": "return, revert to, restore"
    },
    "Keys": {
      "Hanzi": 794
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "GeneralStandard": 794,
      "Radical": "辵",
      "HSK": 5,
      "FrequencyRank": 1430,
      "StrokeCount": 7
    }
  },
  "急诊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jízhěn",
      "PrimaryDefinition": "emergency call, emergency (medical) treatment"
    },
    "Keys": {
      "Word": 7305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毛巾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máojīn",
      "PrimaryDefinition": "washcloth, flannel, Turkish towel, face towel, face cloth, hand_towel, towel, washrag, napkin, terry towel, hand towel"
    },
    "Keys": {
      "Word": 2724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "杓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "handle of cup, ladle, spoon; name of a constellation"
    },
    "Keys": {
      "Hanzi": 3679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3679,
      "Radical": "木",
      "FrequencyRank": 5690,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "耽误": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānwu",
      "PrimaryDefinition": "to delay, to hold up, to waste time, to interfere with"
    },
    "Keys": {
      "Word": 6163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài",
      "PrimaryDefinition": "helium"
    },
    "Keys": {
      "Hanzi": 4550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4550,
      "Radical": "气",
      "FrequencyRank": 3972,
      "RadicalIndex": "84.6",
      "StrokeCount": 10
    }
  },
  "诱发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòufā",
      "PrimaryDefinition": "to induce, to cause, to elicit, to trigger"
    },
    "Keys": {
      "Word": 10491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "trace, copy, duplicate; pattern"
    },
    "Keys": {
      "Hanzi": 3068
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3068,
      "Radical": "手",
      "FrequencyRank": 3008,
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "不好意思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù hǎoyìsi",
      "PrimaryDefinition": "embarrassed, be ill at ease, find it embarrassing, feel embarrassed"
    },
    "Keys": {
      "Word": 531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "避免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìmiǎn",
      "PrimaryDefinition": "forbear, preclude, refrain_from, forebear, escape, help, elude, leapfrog, refrain from, stave_off, evite, shun, obviate, avoid, evade, waive, help oneself, ward_off, ward, miss, avert, ward off, balk, refrain"
    },
    "Keys": {
      "Word": 2265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "演绎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnyì",
      "PrimaryDefinition": "(of a story etc) to unfold, to play out, to develop (a technique etc), to enact, (logic) to deduce, to infer"
    },
    "Keys": {
      "Word": 10206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人均": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénjūn",
      "PrimaryDefinition": "per capita"
    },
    "Keys": {
      "Word": 8841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牲畜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngchù",
      "PrimaryDefinition": "domesticated animals, livestock"
    },
    "Keys": {
      "Word": 9069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "残留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánliú",
      "PrimaryDefinition": "to remain, left over, surplus, remnant"
    },
    "Keys": {
      "Word": 5755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "体质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐzhì",
      "PrimaryDefinition": "habit, constitution, crasis, habitus, health, habitude, physique"
    },
    "Keys": {
      "Word": 9472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忌讳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìhui",
      "PrimaryDefinition": "taboo, to avoid as taboo, to abstain from"
    },
    "Keys": {
      "Word": 7330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "prostitute"
    },
    "Keys": {
      "Hanzi": 918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 918,
      "Radical": "女",
      "FrequencyRank": 2777,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "放纵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngzòng",
      "PrimaryDefinition": "to indulge, to pamper, to connive at, permissive, indulgent, self-indulgent, unrestrained, undisciplined, uncultured, boorish"
    },
    "Keys": {
      "Word": 6507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "wizard, sorcerer, witch, shaman"
    },
    "Keys": {
      "Hanzi": 686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "48.4",
      "GeneralStandard": 686,
      "Radical": "工",
      "HSK": 8,
      "FrequencyRank": 2189,
      "StrokeCount": 7
    }
  },
  "咱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zán",
      "PrimaryDefinition": "we, us"
    },
    "Keys": {
      "Hanzi": 1508,
      "Word": 1192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 2,
      "GeneralStandard": 1508,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 1265,
      "StrokeCount": 9
    }
  },
  "咉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": "an echo, a sound to vomit, as an infant"
    },
    "Keys": {
      "Hanzi": 6678
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6678,
      "Radical": "口",
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "中途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngtú",
      "PrimaryDefinition": "halfway, midstream, midway"
    },
    "Keys": {
      "Word": 10809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吊销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàoxiāo",
      "PrimaryDefinition": "to suspend (an agreement), to revoke"
    },
    "Keys": {
      "Word": 6290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "jealous, envious; fear"
    },
    "Keys": {
      "Hanzi": 910,
      "Word": 7329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.3",
      "Grade": 7,
      "GeneralStandard": 910,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 1922,
      "StrokeCount": 7
    }
  },
  "花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huā",
      "PrimaryDefinition": "flower; blossoms"
    },
    "Keys": {
      "Hanzi": 665,
      "Word": 2551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "Grade": 4,
      "GeneralStandard": 665,
      "Radical": "艸",
      "HSK": 1,
      "FrequencyRank": 410,
      "StrokeCount": 7
    }
  },
  "过失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòshī",
      "PrimaryDefinition": "error, fault, (law) negligence, delinquency"
    },
    "Keys": {
      "Word": 6952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng",
      "PrimaryDefinition": "prize, reward; give award to"
    },
    "Keys": {
      "Hanzi": 1618,
      "Word": 2611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.6",
      "Grade": 4,
      "GeneralStandard": 1618,
      "Radical": "大",
      "HSK": 4,
      "FrequencyRank": 1233,
      "StrokeCount": 9
    }
  },
  "闸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhá",
      "PrimaryDefinition": "sluice; flood gate, canal lock"
    },
    "Keys": {
      "Hanzi": 1227,
      "Word": 10625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.5",
      "Grade": 7,
      "GeneralStandard": 1227,
      "Radical": "門",
      "HSK": 9,
      "FrequencyRank": 3280,
      "StrokeCount": 8
    }
  },
  "恝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "carefree; indifferent manner; without sorrow"
    },
    "Keys": {
      "Hanzi": 6925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6925,
      "Radical": "心",
      "FrequencyRank": 7163,
      "RadicalIndex": "61.6",
      "StrokeCount": 10
    }
  },
  "朋友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péngyou",
      "PrimaryDefinition": "amigo, connections, kith, friend, boy, crony, ami, boy/girl friend, companion, mate, boy friend or girl friend, compadre, boy friend, Mac, paisano, matey, old_boy, sport, folks, pard, hearty, Kamerad, cocker, cobber, girl friend, pardner, company"
    },
    "Keys": {
      "Word": 281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "怪异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàiyì",
      "PrimaryDefinition": "monstrous, strange, strange phenomenon"
    },
    "Keys": {
      "Word": 6864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有两下子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu liǎngxiàzi",
      "PrimaryDefinition": "to have real skill, to know one's stuff"
    },
    "Keys": {
      "Word": 10477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háng",
      "PrimaryDefinition": "baste"
    },
    "Keys": {
      "Hanzi": 4396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4396,
      "Radical": "糸",
      "FrequencyRank": 9735,
      "RadicalIndex": "120.6",
      "StrokeCount": 9
    }
  },
  "挠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "scratch; disturb, bother; submit"
    },
    "Keys": {
      "Hanzi": 1375,
      "Word": 8383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1375,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2794,
      "StrokeCount": 9
    }
  },
  "纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "admit, take, receive, accept"
    },
    "Keys": {
      "Hanzi": 934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 934,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 684,
      "StrokeCount": 7
    }
  },
  "熬夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo yè",
      "PrimaryDefinition": "to stay up late or all night"
    },
    "Keys": {
      "Word": 5454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赔钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péi qián",
      "PrimaryDefinition": "to lose money, to pay for damages"
    },
    "Keys": {
      "Word": 8483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发怒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā nù",
      "PrimaryDefinition": "to get angry"
    },
    "Keys": {
      "Word": 4540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "遗产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíchǎn",
      "PrimaryDefinition": "heritage, legacy, inheritance, bequest, CL:筆|笔[bi3]"
    },
    "Keys": {
      "Word": 3092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "奋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "strive, exert effort; arouse"
    },
    "Keys": {
      "Hanzi": 1051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.5",
      "GeneralStandard": 1051,
      "Radical": "大",
      "HSK": 4,
      "FrequencyRank": 1268,
      "StrokeCount": 8
    }
  },
  "阌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "wen xiang, Henan province"
    },
    "Keys": {
      "Hanzi": 7227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7227,
      "Radical": "門",
      "FrequencyRank": 5242,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "意义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyì",
      "PrimaryDefinition": "importance, significance, denotation, purport, drift, signality, emptiness, meaning, construction, bearing, substance, import, effect, insignificancy, hang, signification, sense"
    },
    "Keys": {
      "Word": 2093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "岽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōng",
      "PrimaryDefinition": "place name in Guangxi province"
    },
    "Keys": {
      "Hanzi": 6682
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6682,
      "Radical": "山",
      "FrequencyRank": 6272,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "美景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měijǐng",
      "PrimaryDefinition": "vision, beauty_spot, beautiful sight, beautiful scenery"
    },
    "Keys": {
      "Word": 8234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎng",
      "PrimaryDefinition": "to push over or push back"
    },
    "Keys": {
      "Hanzi": 5366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5366,
      "Radical": "手",
      "FrequencyRank": 4686,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "敝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "break, destroy; broken, tattered"
    },
    "Keys": {
      "Hanzi": 4955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4955,
      "Radical": "攴",
      "FrequencyRank": 3259,
      "RadicalIndex": "66.8",
      "StrokeCount": 11
    }
  },
  "拜访": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàifǎng",
      "PrimaryDefinition": "pay visit, call on"
    },
    "Keys": {
      "Word": 3228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "郦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4163,
      "Radical": "邑",
      "FrequencyRank": 5123,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "吸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "inhale, suck in; absorb; attract"
    },
    "Keys": {
      "Hanzi": 431,
      "Word": 2994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 4,
      "GeneralStandard": 431,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 924,
      "StrokeCount": 6
    }
  },
  "氛围": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnwéi",
      "PrimaryDefinition": "ambience, atmosphere"
    },
    "Keys": {
      "Word": 6544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "be fond of, have weakness for"
    },
    "Keys": {
      "Hanzi": 2902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.1",
      "GeneralStandard": 2902,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2988,
      "StrokeCount": 13
    }
  },
  "橥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "wooden peg, post or stick"
    },
    "Keys": {
      "Hanzi": 7803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7803,
      "Radical": "木",
      "FrequencyRank": 6060,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "足够": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zúgòu",
      "PrimaryDefinition": "fully, enough, last, amply, suffice, sufficiently"
    },
    "Keys": {
      "Word": 2218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "审": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěn",
      "PrimaryDefinition": "examine, investigate; judge"
    },
    "Keys": {
      "Hanzi": 1279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "GeneralStandard": 1279,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 746,
      "StrokeCount": 8
    }
  },
  "于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "in, on, at; go to; surname"
    },
    "Keys": {
      "Hanzi": 24,
      "Word": 5278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7.1",
      "Grade": 6,
      "GeneralStandard": 24,
      "Radical": "二",
      "HSK": 2,
      "FrequencyRank": 40,
      "StrokeCount": 3
    }
  },
  "迦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "character for transliteration"
    },
    "Keys": {
      "Hanzi": 4072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4072,
      "Radical": "辵",
      "FrequencyRank": 2522,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "阴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 575,
      "Word": 1167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.4",
      "Grade": 2,
      "GeneralStandard": 575,
      "Radical": "阜",
      "HSK": 2,
      "FrequencyRank": 987,
      "StrokeCount": 6
    }
  },
  "捣乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo luàn",
      "PrimaryDefinition": "to disturb, to look for trouble, to stir up a row, to bother sb intentionally"
    },
    "Keys": {
      "Word": 6193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "to collect, gather, store; profound"
    },
    "Keys": {
      "Hanzi": 3212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.14",
      "GeneralStandard": 3212,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2633,
      "StrokeCount": 15
    }
  },
  "储蓄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔxù",
      "PrimaryDefinition": "to deposit money, to save, savings"
    },
    "Keys": {
      "Word": 5990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谈到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tándào",
      "PrimaryDefinition": "cite, speak_to, name, hit, about, speak, refer, speak about, mention, advert, allude, speak_of, speak of, talk about, bring up, touch_on, touch on, touch"
    },
    "Keys": {
      "Word": 9404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hái",
      "PrimaryDefinition": "skeleton, body; leg bone"
    },
    "Keys": {
      "Hanzi": 5945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5945,
      "Radical": "骨",
      "FrequencyRank": 2707,
      "RadicalIndex": "188.6",
      "StrokeCount": 15
    }
  },
  "哔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "used in transliterations"
    },
    "Keys": {
      "Hanzi": 4204
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4204,
      "Radical": "口",
      "FrequencyRank": 5434,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "取笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔxiào",
      "PrimaryDefinition": "to tease, to make fun of"
    },
    "Keys": {
      "Word": 8789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "great, robust; extraordinary"
    },
    "Keys": {
      "Hanzi": 454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 454,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 1107,
      "StrokeCount": 6
    }
  },
  "蜊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "clam"
    },
    "Keys": {
      "Hanzi": 5460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5460,
      "Radical": "虫",
      "FrequencyRank": 4822,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "赞成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànchéng",
      "PrimaryDefinition": "endorsement, agree with, stand, assent, favor, cotton, uphold, subscribe, support, see, clear, approve, agree, sympathize, second, approve of, jibe, assist, indorse, optional, plump_for, favour, endorse"
    },
    "Keys": {
      "Word": 3144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "晢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "light of stars; shine"
    },
    "Keys": {
      "Hanzi": 7113
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7113,
      "Radical": "日",
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "蜂蜜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngmì",
      "PrimaryDefinition": "honey"
    },
    "Keys": {
      "Word": 6590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7956,
      "Radical": "斤",
      "RadicalIndex": "69.13",
      "StrokeCount": 17
    }
  },
  "感冒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnmào",
      "PrimaryDefinition": "catch cold"
    },
    "Keys": {
      "Word": 1477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "致命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìmìng",
      "PrimaryDefinition": "take off, fatal, deadly, mortal"
    },
    "Keys": {
      "Word": 10800
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不一定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyīdìng",
      "PrimaryDefinition": "not sure, maybe"
    },
    "Keys": {
      "Word": 539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "巨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "large, great, enormous; chief"
    },
    "Keys": {
      "Hanzi": 101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "48.2",
      "GeneralStandard": 101,
      "Radical": "工",
      "HSK": 4,
      "FrequencyRank": 913,
      "StrokeCount": 4
    }
  },
  "推翻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuī fān",
      "PrimaryDefinition": "overturn, topple, repudiate, reverse"
    },
    "Keys": {
      "Word": 9594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "reed"
    },
    "Keys": {
      "Hanzi": 663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 663,
      "Radical": "艸",
      "FrequencyRank": 3233,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "舒适": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūshì",
      "PrimaryDefinition": "comfortable, cozy"
    },
    "Keys": {
      "Word": 2884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "老朋友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎopéngyou",
      "PrimaryDefinition": "old friend, (slang) period, menstruation"
    },
    "Keys": {
      "Word": 834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "骗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piàn",
      "PrimaryDefinition": "swindle, cheat out of, defraud"
    },
    "Keys": {
      "Hanzi": 2821,
      "Word": 3771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.9",
      "Grade": 5,
      "GeneralStandard": 2821,
      "Radical": "馬",
      "HSK": 5,
      "FrequencyRank": 1503,
      "StrokeCount": 12
    }
  },
  "和睦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hémù",
      "PrimaryDefinition": "peaceful relations, harmonious"
    },
    "Keys": {
      "Word": 7039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìn",
      "PrimaryDefinition": "rush used for making mats; surnam"
    },
    "Keys": {
      "Hanzi": 5652
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5652,
      "Radical": "艸",
      "FrequencyRank": 5291,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "能耗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nénghào",
      "PrimaryDefinition": "Energy consumption"
    },
    "Keys": {
      "Word": 8398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "錾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàn",
      "PrimaryDefinition": "engraving tool, chisel"
    },
    "Keys": {
      "Hanzi": 6079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6079,
      "Radical": "金",
      "FrequencyRank": 4938,
      "RadicalIndex": "167.8",
      "StrokeCount": 16
    }
  },
  "棘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "jujube tree; thorns, brambles"
    },
    "Keys": {
      "Hanzi": 2584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2584,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2870,
      "StrokeCount": 12
    }
  },
  "嗥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "roar, call out, wail; bark, yelp"
    },
    "Keys": {
      "Hanzi": 5468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5468,
      "Radical": "口",
      "FrequencyRank": 4287,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "一瞬间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshùnjiān",
      "PrimaryDefinition": "split second"
    },
    "Keys": {
      "Word": 10294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dònglì",
      "PrimaryDefinition": "driving force, impetus, motivity, (motive) power, wheel, (motive) power/force, agency, vector, dynamics, force, motive_power, momentum, power"
    },
    "Keys": {
      "Word": 1417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "洇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4336
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4336,
      "Radical": "水",
      "FrequencyRank": 6225,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "炘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "brilliant, shining, bright"
    },
    "Keys": {
      "Hanzi": 4006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4006,
      "Radical": "火",
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "袖手旁观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiùshǒu-pángguān",
      "PrimaryDefinition": "to watch with folded arms (idiom), to look on without lifting a finger"
    },
    "Keys": {
      "Word": 10084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suí",
      "PrimaryDefinition": "soothe, appease, pacify"
    },
    "Keys": {
      "Hanzi": 4703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4703,
      "Radical": "糸",
      "FrequencyRank": 2791,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "葖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7318
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7318,
      "Radical": "艸",
      "FrequencyRank": 8466,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "㫰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làng",
      "PrimaryDefinition": "light; bright; brilliant; clean; clever, to expose to sunlight, to dry something in an opening for ventilation area or a cool place"
    },
    "Keys": {
      "Hanzi": 7158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7158,
      "Radical": "日",
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "见识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànshi",
      "PrimaryDefinition": "knowledge and experience, to increase one's knowledge"
    },
    "Keys": {
      "Word": 7419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "cerium"
    },
    "Keys": {
      "Hanzi": 4540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4540,
      "Radical": "金",
      "FrequencyRank": 6462,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "打架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ jià",
      "PrimaryDefinition": "struggle, scrap, wrangle, fight, come to blows, box, scuffle, brawl"
    },
    "Keys": {
      "Word": 3377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "跐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cī",
      "PrimaryDefinition": "to trample, to step, to walk on the ball of the foot"
    },
    "Keys": {
      "Hanzi": 5455
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5455,
      "Radical": "足",
      "FrequencyRank": 7029,
      "RadicalIndex": "157.5",
      "StrokeCount": 13
    }
  },
  "梧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "Chinese parasoltree, Sterculia platanifolia"
    },
    "Keys": {
      "Hanzi": 2224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2224,
      "Radical": "木",
      "FrequencyRank": 3356,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "飞行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēixíng",
      "PrimaryDefinition": "fly"
    },
    "Keys": {
      "Word": 1450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "攽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6708
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6708,
      "Radical": "攴",
      "RadicalIndex": "66.4",
      "StrokeCount": 8
    }
  },
  "水手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐshǒu",
      "PrimaryDefinition": "mariner, sailor, seaman"
    },
    "Keys": {
      "Word": 9268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "prudent, cautious; attentive"
    },
    "Keys": {
      "Hanzi": 3030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.11",
      "GeneralStandard": 3030,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 1917,
      "StrokeCount": 13
    }
  },
  "添": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiān",
      "PrimaryDefinition": "append, add to; increase"
    },
    "Keys": {
      "Hanzi": 2411,
      "Word": 5084
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 6,
      "GeneralStandard": 2411,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1859,
      "StrokeCount": 11
    }
  },
  "壕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "trench, ditch, channel, moat"
    },
    "Keys": {
      "Hanzi": 6201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6201,
      "Radical": "土",
      "FrequencyRank": 3077,
      "RadicalIndex": "32.14",
      "StrokeCount": 17
    }
  },
  "宅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhái",
      "PrimaryDefinition": "residence, dwelling, home; grave"
    },
    "Keys": {
      "Hanzi": 547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.3",
      "GeneralStandard": 547,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 1858,
      "StrokeCount": 6
    }
  },
  "芬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "fragrance, aroma; perfume"
    },
    "Keys": {
      "Hanzi": 668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 668,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1890,
      "StrokeCount": 7
    }
  },
  "乞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "beg; request"
    },
    "Keys": {
      "Hanzi": 42
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "5.2",
      "GeneralStandard": 42,
      "Radical": "乙",
      "HSK": 8,
      "FrequencyRank": 2429,
      "StrokeCount": 3
    }
  },
  "亩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔ",
      "PrimaryDefinition": "Chinese land measure; fields"
    },
    "Keys": {
      "Hanzi": 829,
      "Word": 8340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "8.5",
      "Grade": 7,
      "GeneralStandard": 829,
      "Radical": "亠",
      "HSK": 9,
      "FrequencyRank": 2360,
      "StrokeCount": 7
    }
  },
  "精美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngměi",
      "PrimaryDefinition": "exquisite, elegant"
    },
    "Keys": {
      "Word": 4748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "皤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pó",
      "PrimaryDefinition": "white, grey; corpulent"
    },
    "Keys": {
      "Hanzi": 6265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6265,
      "Radical": "白",
      "FrequencyRank": 6051,
      "RadicalIndex": "106.12",
      "StrokeCount": 17
    }
  },
  "炜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "brilliant red; glowing"
    },
    "Keys": {
      "Hanzi": 4003
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4003,
      "Radical": "火",
      "FrequencyRank": 4864,
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "桨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng",
      "PrimaryDefinition": "oar, paddle"
    },
    "Keys": {
      "Hanzi": 2017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 2017,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2939,
      "StrokeCount": 10
    }
  },
  "员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "member; personnel, staff member"
    },
    "Keys": {
      "Hanzi": 737,
      "Word": 2121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 3,
      "GeneralStandard": 737,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 200,
      "StrokeCount": 7
    }
  },
  "虫子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngzi",
      "PrimaryDefinition": "insect, bug, worm, CL:條|条[tiao2],隻|只[zhi1]"
    },
    "Keys": {
      "Word": 2328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "华裔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáyì",
      "PrimaryDefinition": "foreign citizen of Ch. origin"
    },
    "Keys": {
      "Word": 7132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "hook"
    },
    "Keys": {
      "Radical": 6
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "全力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánlì",
      "PrimaryDefinition": "all one's strength, wholehearted dedication"
    },
    "Keys": {
      "Word": 4960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "惭愧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánkuì",
      "PrimaryDefinition": "ashamed"
    },
    "Keys": {
      "Word": 5759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "sorrow, grief; sorry, sad"
    },
    "Keys": {
      "Hanzi": 2603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2603,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1166,
      "StrokeCount": 12
    }
  },
  "患病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn bìng",
      "PrimaryDefinition": "to fall ill"
    },
    "Keys": {
      "Word": 7180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàdì",
      "PrimaryDefinition": "terra_firma, mother earth, mold, earth, soli"
    },
    "Keys": {
      "Word": 6115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "住户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùhù",
      "PrimaryDefinition": "denizen, resident, inhabitant, building, household, dweller"
    },
    "Keys": {
      "Word": 10866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "fragrant, luxuriant; the Philippines"
    },
    "Keys": {
      "Hanzi": 2202
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2202,
      "Radical": "艸",
      "FrequencyRank": 1418,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "粜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiào",
      "PrimaryDefinition": "sell grains"
    },
    "Keys": {
      "Hanzi": 5013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5013,
      "Radical": "米",
      "FrequencyRank": 5687,
      "RadicalIndex": "119.5",
      "StrokeCount": 11
    }
  },
  "午餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔcān",
      "PrimaryDefinition": "midday meal, lunch"
    },
    "Keys": {
      "Word": 1066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "保障": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎozhàng",
      "PrimaryDefinition": "ensure, guarantee, safeguard"
    },
    "Keys": {
      "Word": 5526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēng",
      "PrimaryDefinition": "syllable"
    },
    "Keys": {
      "Hanzi": 5718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5718,
      "Radical": "口",
      "FrequencyRank": 5034,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "抛弃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāoqì",
      "PrimaryDefinition": "dispose, cast aside, toss away, toss, lurch, throw out, put away, reject, abandonment, chuck, ditch, jettison, slough, renounce, flee, throw away, give up, desert, cast out, desolate, doff, chuck_out, fling, jilt, forgo, chuck out, discard, abandon, toss out, cast away, forsake"
    },
    "Keys": {
      "Word": 8476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngwéi",
      "PrimaryDefinition": "find, join, comprise, turn to, commemorate, sample, entomb, come_up, commence, get hold, learn, add up, necessitate, run, make up, constitute, make, form, come, amount, represent, fall, presage, change, be, turn, turn into, become, line up, come up, shape, portend, foretell, get"
    },
    "Keys": {
      "Word": 563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "再说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàishuō",
      "PrimaryDefinition": "furthermore, besides"
    },
    "Keys": {
      "Word": 5305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "何": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "what, why, where, which, how"
    },
    "Keys": {
      "Hanzi": 771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 771,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 229,
      "StrokeCount": 7
    }
  },
  "旐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "an embroidered pennant"
    },
    "Keys": {
      "Hanzi": 7420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7420,
      "Radical": "方",
      "RadicalIndex": "70.8",
      "StrokeCount": 12
    }
  },
  "秩序": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìxù",
      "PrimaryDefinition": "order, sequence"
    },
    "Keys": {
      "Word": 10803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "temperate, frugal, economical"
    },
    "Keys": {
      "Hanzi": 1567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1567,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2891,
      "StrokeCount": 9
    }
  },
  "苓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "fungus, tuber; licorice"
    },
    "Keys": {
      "Hanzi": 3865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3865,
      "Radical": "艸",
      "FrequencyRank": 4194,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "榅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēn",
      "PrimaryDefinition": "pillar, cryptomeria"
    },
    "Keys": {
      "Hanzi": 7495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7495,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "满意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎnyì",
      "PrimaryDefinition": "satisfy, well-pleasing, pleased, satisfied"
    },
    "Keys": {
      "Word": 866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "媚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèi",
      "PrimaryDefinition": "charming, attractive; flatter"
    },
    "Keys": {
      "Hanzi": 2811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.9",
      "GeneralStandard": 2811,
      "Radical": "女",
      "HSK": 8,
      "FrequencyRank": 2291,
      "StrokeCount": 12
    }
  },
  "感激": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnjī",
      "PrimaryDefinition": "feel grateful/indebted"
    },
    "Keys": {
      "Word": 6676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "single, individual, only; lone"
    },
    "Keys": {
      "Hanzi": 1232,
      "Word": 2388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.6",
      "Grade": 4,
      "GeneralStandard": 1232,
      "Radical": "八",
      "HSK": 2,
      "FrequencyRank": 389,
      "StrokeCount": 8
    }
  },
  "微妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēimiào",
      "PrimaryDefinition": "subtle"
    },
    "Keys": {
      "Word": 9692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chòng",
      "PrimaryDefinition": "ancient weapon, blunderbuss"
    },
    "Keys": {
      "Hanzi": 4885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4885,
      "Radical": "金",
      "FrequencyRank": 5787,
      "RadicalIndex": "167.5",
      "StrokeCount": 11
    }
  },
  "郗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "city under the Chou Dyn. a surname"
    },
    "Keys": {
      "Hanzi": 4273
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4273,
      "Radical": "邑",
      "FrequencyRank": 6459,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": "correct, right; facing, opposed"
    },
    "Keys": {
      "Hanzi": 341,
      "Word": 643
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "41.2",
      "Grade": 2,
      "GeneralStandard": 341,
      "Radical": "寸",
      "HSK": 1,
      "FrequencyRank": 33,
      "StrokeCount": 5
    }
  },
  "愤怒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènnù",
      "PrimaryDefinition": "indignant, angry"
    },
    "Keys": {
      "Word": 4563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "标志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāozhì",
      "PrimaryDefinition": "earmark, banner, standing for, logo, symbol, marker, stamp, sign, symbolization, mark, emblem, seal, logotype, symbolizing, blip, marking, insignia"
    },
    "Keys": {
      "Word": 2268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "愚公移山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yúgōng-yíshān",
      "PrimaryDefinition": "the old man moves mountains (idiom), fig. where there's a will, there's a way"
    },
    "Keys": {
      "Word": 10500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "prison, jail"
    },
    "Keys": {
      "Hanzi": 4515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4515,
      "Radical": "囗",
      "FrequencyRank": 5456,
      "RadicalIndex": "31.7",
      "StrokeCount": 10
    }
  },
  "沸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "boil, bubble up, gush"
    },
    "Keys": {
      "Hanzi": 1259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1259,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2698,
      "StrokeCount": 8
    }
  },
  "鳉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "name of fish"
    },
    "Keys": {
      "Hanzi": 7968
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7968,
      "Radical": "魚",
      "FrequencyRank": 7215,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "焖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèn",
      "PrimaryDefinition": "simmer, cook over slow fire"
    },
    "Keys": {
      "Hanzi": 4960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4960,
      "Radical": "火",
      "FrequencyRank": 5207,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "毒品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúpǐn",
      "PrimaryDefinition": "kif, kaif, narcotics, drug, weed, grass, narcotic drugs, drugs"
    },
    "Keys": {
      "Word": 4517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "动画片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dònghuàpiàn",
      "PrimaryDefinition": "animated film"
    },
    "Keys": {
      "Word": 2420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "出游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūyóu",
      "PrimaryDefinition": "to go on a tour, to have an outing"
    },
    "Keys": {
      "Word": 5975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抢夺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎngduó",
      "PrimaryDefinition": "to plunder, to pillage, to forcibly take"
    },
    "Keys": {
      "Word": 8696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疏导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūdǎo",
      "PrimaryDefinition": "to dredge, to open up a path for, to remove obstructions, to clear the way, to enlighten, persuasion"
    },
    "Keys": {
      "Word": 9217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7438,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 12
    }
  },
  "双手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngshǒu",
      "PrimaryDefinition": "lily-white, both hands"
    },
    "Keys": {
      "Word": 3931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瘫痪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tānhuàn",
      "PrimaryDefinition": "paralysis, be paralyzed (body, transportation, etc)"
    },
    "Keys": {
      "Word": 9401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "环保": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánbǎo",
      "PrimaryDefinition": "environmental protection"
    },
    "Keys": {
      "Word": 1563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "踝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huái",
      "PrimaryDefinition": "ankle"
    },
    "Keys": {
      "Hanzi": 5912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5912,
      "Radical": "足",
      "FrequencyRank": 3893,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "兴奋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngfèn",
      "PrimaryDefinition": "excited"
    },
    "Keys": {
      "Word": 3038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "徽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "a badge, insignia"
    },
    "Keys": {
      "Hanzi": 3420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.14",
      "GeneralStandard": 3420,
      "Radical": "彳",
      "HSK": 9,
      "FrequencyRank": 2277,
      "StrokeCount": 17
    }
  },
  "船舶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánbó",
      "PrimaryDefinition": "shipping, boats"
    },
    "Keys": {
      "Word": 6012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái",
      "PrimaryDefinition": "actor; vaudeville show; insincere"
    },
    "Keys": {
      "Hanzi": 4565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4565,
      "Radical": "人",
      "FrequencyRank": 4981,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "疲劳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píláo",
      "PrimaryDefinition": "fatigue, wearily, weariness, weary"
    },
    "Keys": {
      "Word": 8510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "取款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ kuǎn",
      "PrimaryDefinition": "to withdraw money from a bank"
    },
    "Keys": {
      "Word": 4955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "弟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "young brother; junior; i, me"
    },
    "Keys": {
      "Hanzi": 854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "57.4",
      "GeneralStandard": 854,
      "Radical": "弓",
      "HSK": 1,
      "FrequencyRank": 816,
      "StrokeCount": 7
    }
  },
  "横七竖八": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héngqī-shùbā",
      "PrimaryDefinition": "in disorder, at sixes and sevens (idiom)"
    },
    "Keys": {
      "Word": 7067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāguāng",
      "PrimaryDefinition": "to shine"
    },
    "Keys": {
      "Word": 6435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "克": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "gram; overcome; transliteration"
    },
    "Keys": {
      "Hanzi": 675,
      "Word": 816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.5",
      "Grade": 2,
      "GeneralStandard": 675,
      "Radical": "儿",
      "HSK": 2,
      "FrequencyRank": 262,
      "StrokeCount": 7
    }
  },
  "俪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "spouse, couple, pair"
    },
    "Keys": {
      "Hanzi": 4259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4259,
      "Radical": "人",
      "FrequencyRank": 4648,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "扯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chě",
      "PrimaryDefinition": "rip up, tear down; raise; haul"
    },
    "Keys": {
      "Hanzi": 624,
      "Word": 5845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 624,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2084,
      "StrokeCount": 7
    }
  },
  "径": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "narrow path; diameter; direct"
    },
    "Keys": {
      "Hanzi": 1163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.5",
      "GeneralStandard": 1163,
      "Radical": "彳",
      "HSK": 6,
      "FrequencyRank": 1307,
      "StrokeCount": 8
    }
  },
  "奖励": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnglì",
      "PrimaryDefinition": "reward, award"
    },
    "Keys": {
      "Word": 3609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "主席": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔxí",
      "PrimaryDefinition": "chairwoman, presiding officer, president (of organization, chair, chair (of meeting), prolocutor, chairperson, subchairman, chairmanship, state), chairman, chair/president (of organization/state), moderator, president"
    },
    "Keys": {
      "Word": 3189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "葙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "feather cockscomb (Celosia argentea)"
    },
    "Keys": {
      "Hanzi": 7304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7304,
      "Radical": "艸",
      "FrequencyRank": 7361,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "俗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sú",
      "PrimaryDefinition": "social customs; vulgar, unrefined"
    },
    "Keys": {
      "Hanzi": 1568,
      "Word": 9345
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "Grade": 7,
      "GeneralStandard": 1568,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 1354,
      "StrokeCount": 9
    }
  },
  "乔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "tall, lofty; proud, stately"
    },
    "Keys": {
      "Hanzi": 452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.3",
      "GeneralStandard": 452,
      "Radical": "大",
      "HSK": 9,
      "FrequencyRank": 1488,
      "StrokeCount": 6
    }
  },
  "胡说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "húshuō",
      "PrimaryDefinition": "to talk nonsense, drivel"
    },
    "Keys": {
      "Word": 7115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6880,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "粽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòng",
      "PrimaryDefinition": "dumpling made of glutinous rice"
    },
    "Keys": {
      "Hanzi": 5794
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "119.8",
      "GeneralStandard": 5794,
      "Radical": "米",
      "HSK": 9,
      "FrequencyRank": 5076,
      "StrokeCount": 14
    }
  },
  "回落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíluò",
      "PrimaryDefinition": "to fall back, to return to low level after a rise (in water level, price etc)"
    },
    "Keys": {
      "Word": 7206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卫视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèishì",
      "PrimaryDefinition": "satellite TV, abbr. of 衛星電視|卫星电视"
    },
    "Keys": {
      "Word": 9713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "少量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎoliàng",
      "PrimaryDefinition": "dribblet, morsel, modicum, scattering, a few, whit, inch, small, daub, det.: a few, slug, element, dash, haet, dearth, fleabite, sprinkle, little, shred, smidgen, soupcon, a little/few, bit, suspicion, mouthful, tot, littleness, trifle, snack, small quantity, spot, few, groat, paucity, nibble, smattering, small amount, ounce, rag, lick, spatter, tad, skerrick, pennyworth, small amount/quanti"
    },
    "Keys": {
      "Word": 8997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "流动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúdòng",
      "PrimaryDefinition": "flow, go from place to place"
    },
    "Keys": {
      "Word": 3701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "之类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīlèi",
      "PrimaryDefinition": "conj.: such like"
    },
    "Keys": {
      "Word": 5346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎn",
      "PrimaryDefinition": "item, article; clause; fund"
    },
    "Keys": {
      "Hanzi": 2508
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.8",
      "GeneralStandard": 2508,
      "Radical": "欠",
      "HSK": 5,
      "FrequencyRank": 807,
      "StrokeCount": 12
    }
  },
  "厨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "kitchen; closet; cupboard"
    },
    "Keys": {
      "Hanzi": 2587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.1",
      "GeneralStandard": 2587,
      "Radical": "厂",
      "HSK": 5,
      "FrequencyRank": 2132,
      "StrokeCount": 12
    }
  },
  "格局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "géjú",
      "PrimaryDefinition": "pattern, setup, structure"
    },
    "Keys": {
      "Word": 6732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "散文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎnwén",
      "PrimaryDefinition": "prose"
    },
    "Keys": {
      "Word": 3859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "pattern, standard, form; style"
    },
    "Keys": {
      "Hanzi": 1845,
      "Word": 6730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 7,
      "GeneralStandard": 1845,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 325,
      "StrokeCount": 10
    }
  },
  "眼神": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnshén",
      "PrimaryDefinition": "eye, expression in one's eyes, sight, eyesight"
    },
    "Keys": {
      "Word": 10195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "port city"
    },
    "Keys": {
      "Hanzi": 2175
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2175,
      "Radical": "土",
      "FrequencyRank": 3443,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "倮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǒ",
      "PrimaryDefinition": "bare, naked, uncovered"
    },
    "Keys": {
      "Hanzi": 7016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7016,
      "Radical": "人",
      "FrequencyRank": 5652,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "招数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāoshù",
      "PrimaryDefinition": "move (in chess, on stage, in martial arts), gambit, trick, scheme, movement, same as 著數|着数[zhao1 shu4]"
    },
    "Keys": {
      "Word": 10667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùshì",
      "PrimaryDefinition": "to indicate, to foretell, to forebode, to betoken"
    },
    "Keys": {
      "Word": 10517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "flatter, truckle"
    },
    "Keys": {
      "Hanzi": 4675
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4675,
      "Radical": "言",
      "FrequencyRank": 3925,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "命题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìng tí",
      "PrimaryDefinition": "proposition (logic, math.), to assign an essay topic"
    },
    "Keys": {
      "Word": 8316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "attend, be present; arrive at"
    },
    "Keys": {
      "Hanzi": 4435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4435,
      "Radical": "艸",
      "FrequencyRank": 4840,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "know, perceive, comprehend"
    },
    "Keys": {
      "Hanzi": 1122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "111.3",
      "GeneralStandard": 1122,
      "Radical": "矢",
      "HSK": 1,
      "FrequencyRank": 123,
      "StrokeCount": 8
    }
  },
  "二氧化碳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èryǎnghuàtàn",
      "PrimaryDefinition": "carbon dioxide CO2"
    },
    "Keys": {
      "Word": 6429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "信念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnniàn",
      "PrimaryDefinition": "confession, tenet, strong belief, belief, creed, persuasion, conviction, opinion, faith"
    },
    "Keys": {
      "Word": 4067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蝮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "venomous snake, viper"
    },
    "Keys": {
      "Hanzi": 5924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5924,
      "Radical": "虫",
      "FrequencyRank": 4419,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "忠心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxīn",
      "PrimaryDefinition": "good faith, devotion, loyalty, dedication"
    },
    "Keys": {
      "Word": 5369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "商务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngwù",
      "PrimaryDefinition": "commercial affairs, commercial, commerce, business"
    },
    "Keys": {
      "Word": 2840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "否则": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǒuzé",
      "PrimaryDefinition": "conj.: otherwise, if not, or else"
    },
    "Keys": {
      "Word": 2473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "充沛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngpèi",
      "PrimaryDefinition": "abundant, plentiful, vigorous"
    },
    "Keys": {
      "Word": 5918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "团体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuántǐ",
      "PrimaryDefinition": "fraternity, sect, phalanx, collective, body, group, sodality, element, organisation, corporation, confession, college, establishment, brotherhood, community, organization, squad, union, administration, society, army, commonwealth, consort, company, lineup, combine, governance, clan, combination, fellowship, organizational, bloc, team, party, connection, family, brass, squadron"
    },
    "Keys": {
      "Word": 1987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "篙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "pole used punt boat"
    },
    "Keys": {
      "Hanzi": 6128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6128,
      "Radical": "竹",
      "FrequencyRank": 4628,
      "RadicalIndex": "118.1",
      "StrokeCount": 16
    }
  },
  "街头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiētóu",
      "PrimaryDefinition": "street corner, street_corner, street (corner), street"
    },
    "Keys": {
      "Word": 4726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一直": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīzhí",
      "PrimaryDefinition": "e'er, away, continuously, all the way, right, forever, all_along, always, the whole way, all along, straight, slap, through, ever, evermore"
    },
    "Keys": {
      "Word": 1151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "触犯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùfàn",
      "PrimaryDefinition": "to offend"
    },
    "Keys": {
      "Word": 5992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒ",
      "PrimaryDefinition": "fruit; result"
    },
    "Keys": {
      "Hanzi": 1079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1079,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 165,
      "StrokeCount": 8
    }
  },
  "正面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngmiàn",
      "PrimaryDefinition": "right side, head, head-on, face, front, the right side, facade, obverse/right side, the obverse side, frontispiece, frontal, frontage, obverse side, obverse"
    },
    "Keys": {
      "Word": 10732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "默": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "silent; quiet, still; dark"
    },
    "Keys": {
      "Hanzi": 3352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "203.4",
      "GeneralStandard": 3352,
      "Radical": "黑",
      "HSK": 4,
      "FrequencyRank": 1031,
      "StrokeCount": 16
    }
  },
  "侧面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèmiàn",
      "PrimaryDefinition": "lateral side, side, aspect, profile"
    },
    "Keys": {
      "Word": 5776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7677,
      "Radical": "囗",
      "RadicalIndex": "31.11",
      "StrokeCount": 14
    }
  },
  "憋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biē",
      "PrimaryDefinition": "to suppress inner feelings; hasty"
    },
    "Keys": {
      "Hanzi": 3289,
      "Word": 5635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.12",
      "Grade": 7,
      "GeneralStandard": 3289,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 3141,
      "StrokeCount": 15
    }
  },
  "无效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxiào",
      "PrimaryDefinition": "of/to no avail, null and void"
    },
    "Keys": {
      "Word": 5149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唱歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng gē",
      "PrimaryDefinition": "sing"
    },
    "Keys": {
      "Word": 41
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "庆典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìngdiǎn",
      "PrimaryDefinition": "celebration"
    },
    "Keys": {
      "Word": 8765
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "眚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěng",
      "PrimaryDefinition": "disease of the eyes; crime, fault"
    },
    "Keys": {
      "Hanzi": 7010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7010,
      "Radical": "目",
      "FrequencyRank": 6682,
      "RadicalIndex": "109.5",
      "StrokeCount": 10
    }
  },
  "颈部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngbù",
      "PrimaryDefinition": "neck"
    },
    "Keys": {
      "Word": 7628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngyuán",
      "PrimaryDefinition": "mobilize, arouse"
    },
    "Keys": {
      "Word": 3429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "许多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔduō",
      "PrimaryDefinition": "lot, scores, lots, sight, pot, heap, quantity, plenty, slews, ton, battalion, many, scads, tidy sum, power, spate, chunk, pile, crowd, vastness, batch, lashings, whole lot, mint, mound, pocketful, large number, trunkful, ream, mickle, great deal, flock, mass, slew, heaps, a lot of, piles, whole slew, mess, raff, slue, gobs, much, lotta, multitude, volume, wad, store, raft, tons, satiety, rafts, doze"
    },
    "Keys": {
      "Word": 1119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "刚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "hard, tough, rigid, strong"
    },
    "Keys": {
      "Hanzi": 441,
      "Word": 674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "Grade": 2,
      "GeneralStandard": 441,
      "Radical": "刀",
      "HSK": 2,
      "FrequencyRank": 621,
      "StrokeCount": 6
    }
  },
  "樾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "the shade of trees"
    },
    "Keys": {
      "Hanzi": 6056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6056,
      "Radical": "木",
      "FrequencyRank": 4777,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "动听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngtīng",
      "PrimaryDefinition": "pleasant to listen to"
    },
    "Keys": {
      "Word": 6338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuli",
      "PrimaryDefinition": "in hand, (a situation is) in sb's hands"
    },
    "Keys": {
      "Word": 2876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "纼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "a rope for leading cattle or horse"
    },
    "Keys": {
      "Hanzi": 6644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6644,
      "Radical": "糸",
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "新兴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxīng",
      "PrimaryDefinition": "burgeoning, new and developing, newly_arisen, rising"
    },
    "Keys": {
      "Word": 5187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "上头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngtou",
      "PrimaryDefinition": "(of alcohol) to go to one's head, (old) (of a bride-to-be) to bind one's hair into a bun, (of a prostitute) to receive a patron for the first time, above, on top of, on the surface of"
    },
    "Keys": {
      "Word": 8982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "难听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nántīng",
      "PrimaryDefinition": "unpleasant to hear, coarse, vulgar, offensive, shameful"
    },
    "Keys": {
      "Word": 888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "掂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diān",
      "PrimaryDefinition": "heft, hold or weigh in palm"
    },
    "Keys": {
      "Hanzi": 2183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2183,
      "Radical": "手",
      "FrequencyRank": 4020,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "嚚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "argumentative, talkative"
    },
    "Keys": {
      "Hanzi": 8001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8001,
      "Radical": "口",
      "RadicalIndex": "30.15",
      "StrokeCount": 18
    }
  },
  "许可": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔkě",
      "PrimaryDefinition": "permit, allow"
    },
    "Keys": {
      "Word": 4082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "一手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshǒu",
      "PrimaryDefinition": "a skill, mastery of a trade, by oneself, without outside help"
    },
    "Keys": {
      "Word": 10343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "展示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnshì",
      "PrimaryDefinition": "reveal, lay bare"
    },
    "Keys": {
      "Word": 4190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "奋斗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèndòu",
      "PrimaryDefinition": "struggle, fight, strive"
    },
    "Keys": {
      "Word": 2467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "烶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7051,
      "Radical": "火",
      "RadicalIndex": "86.7",
      "StrokeCount": 10
    }
  },
  "杄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6580
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6580,
      "Radical": "木",
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "小看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎokàn",
      "PrimaryDefinition": "to look down on, to underestimate"
    },
    "Keys": {
      "Word": 9966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "媱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7613,
      "Radical": "女",
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "所属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒshǔ",
      "PrimaryDefinition": "subsidiary"
    },
    "Keys": {
      "Word": 9378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "太": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "very, too, much; big; extreme"
    },
    "Keys": {
      "Hanzi": 93,
      "Word": 356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.1",
      "Grade": 1,
      "GeneralStandard": 93,
      "Radical": "大",
      "HSK": 1,
      "FrequencyRank": 240,
      "StrokeCount": 4
    }
  },
  "白酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báijiǔ",
      "PrimaryDefinition": "spirit usually distilled from sorghum or maize, white spirit"
    },
    "Keys": {
      "Word": 3226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "一点点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdiǎndiǎn",
      "PrimaryDefinition": "a little bit"
    },
    "Keys": {
      "Word": 1148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "格外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "géwài",
      "PrimaryDefinition": "piu, especially, all the more, helluva, particularly"
    },
    "Keys": {
      "Word": 2491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "胆怯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎnqiè",
      "PrimaryDefinition": "timid, cowardly"
    },
    "Keys": {
      "Word": 6164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng",
      "PrimaryDefinition": "commerce, business, trade"
    },
    "Keys": {
      "Hanzi": 2391
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2391,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 402,
      "StrokeCount": 11
    }
  },
  "脆弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuìruò",
      "PrimaryDefinition": "fragile, frail, weak"
    },
    "Keys": {
      "Word": 6081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诱惑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòuhuò",
      "PrimaryDefinition": "tempt, seduce, lure"
    },
    "Keys": {
      "Word": 10490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跳伞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàosǎn",
      "PrimaryDefinition": "to parachute, to bail out, parachute jumping"
    },
    "Keys": {
      "Word": 9506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "机场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīchǎng",
      "PrimaryDefinition": "landing field, flying field, airfield, airport, airdrome, field, aerodrome"
    },
    "Keys": {
      "Word": 159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "河": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "river; stream; yellow river"
    },
    "Keys": {
      "Hanzi": 1244,
      "Word": 717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 2,
      "GeneralStandard": 1244,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 574,
      "StrokeCount": 8
    }
  },
  "敌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "enemy, foe, rival; resist"
    },
    "Keys": {
      "Hanzi": 1942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "135.4",
      "GeneralStandard": 1942,
      "Radical": "舌",
      "HSK": 4,
      "FrequencyRank": 523,
      "StrokeCount": 10
    }
  },
  "父": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "father"
    },
    "Keys": {
      "Radical": 88,
      "Hanzi": 141
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "88",
      "GeneralStandard": 141,
      "Radical": "父",
      "HSK": 3,
      "FrequencyRank": 589,
      "StrokeCount": 4
    }
  },
  "毅然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìrán",
      "PrimaryDefinition": "firmly, resolutely, without hesitation"
    },
    "Keys": {
      "Word": 10379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "edible bean; surname"
    },
    "Keys": {
      "Hanzi": 2550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2550,
      "Radical": "艸",
      "FrequencyRank": 1919,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "汨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "Mi(luo) river in Hunan province where Qu Yuan drowned himself; to sink; used (erroneously) for 汩"
    },
    "Keys": {
      "Hanzi": 3774
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3774,
      "Radical": "水",
      "FrequencyRank": 5089,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "脚印": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎoyìn",
      "PrimaryDefinition": "footprint"
    },
    "Keys": {
      "Word": 4722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "名牌儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngpáir5",
      "PrimaryDefinition": "Famous brand"
    },
    "Keys": {
      "Word": 2745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "你们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǐmen",
      "PrimaryDefinition": "you, you (plural), you-all"
    },
    "Keys": {
      "Word": 269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "亲近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnjìn",
      "PrimaryDefinition": "hobnob, hang out, affiliate, close, be close to, know, be on intimate terms with, intimate"
    },
    "Keys": {
      "Word": 8720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "身体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēntǐ",
      "PrimaryDefinition": "soma, system, physical structure, body, health, carcase, corpus, frame, person, somatic, organic structure, personage"
    },
    "Keys": {
      "Word": 324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "搏斗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bódòu",
      "PrimaryDefinition": "to wrestle, to fight, to struggle"
    },
    "Keys": {
      "Word": 5671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "betel pepper; Amorphophallus konjac"
    },
    "Keys": {
      "Hanzi": 5385
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5385,
      "Radical": "艸",
      "FrequencyRank": 8535,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "源泉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánquán",
      "PrimaryDefinition": "fountainhead, well-spring, water source, fig. origin"
    },
    "Keys": {
      "Word": 10548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "现代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàndài",
      "PrimaryDefinition": "modern"
    },
    "Keys": {
      "Word": 2034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "栋梁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngliáng",
      "PrimaryDefinition": "ridgepole, ridgepole and beams, person able to bear heavy responsibility, mainstay (of organization), pillar (of state)"
    },
    "Keys": {
      "Word": 6343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "suck; chew, masticate"
    },
    "Keys": {
      "Hanzi": 3911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 3911,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 3498,
      "StrokeCount": 8
    }
  },
  "恭维": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngwei",
      "PrimaryDefinition": "to praise, to speak highly of, compliment, praise"
    },
    "Keys": {
      "Word": 6800
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开垦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāikěn",
      "PrimaryDefinition": "to clear a wild area for cultivation, to put under the plow"
    },
    "Keys": {
      "Word": 7749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喜酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐjiǔ",
      "PrimaryDefinition": "wedding feast, liquor drunk at a wedding feast"
    },
    "Keys": {
      "Word": 9839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒ",
      "PrimaryDefinition": "outer-coffin; vault"
    },
    "Keys": {
      "Hanzi": 5102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5102,
      "Radical": "木",
      "FrequencyRank": 5784,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "筦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn",
      "PrimaryDefinition": "a key; to be in charge; a pipe"
    },
    "Keys": {
      "Hanzi": 7545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7545,
      "Radical": "竹",
      "FrequencyRank": 7428,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "轻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "light; easy, simple; gentle"
    },
    "Keys": {
      "Hanzi": 1463,
      "Word": 930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.5",
      "Grade": 2,
      "GeneralStandard": 1463,
      "Radical": "車",
      "HSK": 2,
      "FrequencyRank": 460,
      "StrokeCount": 9
    }
  },
  "忑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tè",
      "PrimaryDefinition": "fearful; nervous; timid"
    },
    "Keys": {
      "Hanzi": 3682
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3682,
      "Radical": "心",
      "FrequencyRank": 4165,
      "RadicalIndex": "61.3",
      "StrokeCount": 7
    }
  },
  "蹬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng",
      "PrimaryDefinition": "step on, tread on; lose energy"
    },
    "Keys": {
      "Hanzi": 3460,
      "Word": 6223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.12",
      "Grade": 7,
      "GeneralStandard": 3460,
      "Radical": "足",
      "HSK": 8,
      "FrequencyRank": 3349,
      "StrokeCount": 19
    }
  },
  "銮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "bells hung on horse; bells hung"
    },
    "Keys": {
      "Hanzi": 5778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5778,
      "Radical": "金",
      "FrequencyRank": 4211,
      "RadicalIndex": "167.6",
      "StrokeCount": 14
    }
  },
  "桔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jú",
      "PrimaryDefinition": "Chinese bellflower; well-swept; the inner fibers of corn-stalks"
    },
    "Keys": {
      "Hanzi": 1836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 1836,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 3304,
      "StrokeCount": 10
    }
  },
  "拐杖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎizhàng",
      "PrimaryDefinition": "crutches, crutch, walking stick"
    },
    "Keys": {
      "Word": 6861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电视剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànshìjù",
      "PrimaryDefinition": "TV play, soap opera, CL:部[bu4]"
    },
    "Keys": {
      "Word": 1410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "镍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "nickel"
    },
    "Keys": {
      "Hanzi": 5949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5949,
      "Radical": "金",
      "FrequencyRank": 4438,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "愠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "angry"
    },
    "Keys": {
      "Hanzi": 5294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5294,
      "Radical": "心",
      "FrequencyRank": 4535,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "山顶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāndǐng",
      "PrimaryDefinition": "hilltop"
    },
    "Keys": {
      "Word": 8948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 3586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3586,
      "Radical": "厂",
      "FrequencyRank": 5493,
      "RadicalIndex": "27.4",
      "StrokeCount": 6
    }
  },
  "胶片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāopiàn",
      "PrimaryDefinition": "(photographic) film"
    },
    "Keys": {
      "Word": 7470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "polish"
    },
    "Keys": {
      "Hanzi": 5390
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5390,
      "Radical": "艸",
      "FrequencyRank": 6756,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "隔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "separate, partition"
    },
    "Keys": {
      "Hanzi": 2805,
      "Word": 2494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.1",
      "Grade": 4,
      "GeneralStandard": 2805,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 1319,
      "StrokeCount": 12
    }
  },
  "声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "sound, voice, noise; tone; music"
    },
    "Keys": {
      "Hanzi": 654,
      "Word": 3890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "33.4",
      "Grade": 5,
      "GeneralStandard": 654,
      "Radical": "士",
      "HSK": 2,
      "FrequencyRank": 195,
      "StrokeCount": 7
    }
  },
  "功": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "achievement, merit, good result"
    },
    "Keys": {
      "Hanzi": 202,
      "Word": 6783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.3",
      "Grade": 7,
      "GeneralStandard": 202,
      "Radical": "力",
      "HSK": 3,
      "FrequencyRank": 452,
      "StrokeCount": 5
    }
  },
  "恢复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huīfù",
      "PrimaryDefinition": "resume, renew, recover, regain, restore, reinstate, rehabilitate"
    },
    "Keys": {
      "Word": 3562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无可厚非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúkě-hòufēi",
      "PrimaryDefinition": "see 未可厚非[wei4 ke3 hou4 fei1]"
    },
    "Keys": {
      "Word": 9773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "to deal in spirits"
    },
    "Keys": {
      "Hanzi": 5108
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5108,
      "Radical": "酉",
      "FrequencyRank": 6479,
      "RadicalIndex": "164.5",
      "StrokeCount": 12
    }
  },
  "片面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piànmiàn",
      "PrimaryDefinition": "unilateral, one-sided"
    },
    "Keys": {
      "Word": 2780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "身子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnzi",
      "PrimaryDefinition": "body"
    },
    "Keys": {
      "Word": 9025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猩猩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngxing",
      "PrimaryDefinition": "orangutan"
    },
    "Keys": {
      "Word": 10042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "crockery, porcelain, chinaware"
    },
    "Keys": {
      "Hanzi": 2038,
      "Word": 6035
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "98.6",
      "Grade": 7,
      "GeneralStandard": 2038,
      "Radical": "瓦",
      "HSK": 8,
      "FrequencyRank": 2285,
      "StrokeCount": 10
    }
  },
  "平方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngfāng",
      "PrimaryDefinition": "square (as in square foot, square mile, square root)"
    },
    "Keys": {
      "Word": 2781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "短信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnxìn",
      "PrimaryDefinition": "text message, SMS"
    },
    "Keys": {
      "Word": 634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "公开信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngkāixìn",
      "PrimaryDefinition": "open letter"
    },
    "Keys": {
      "Word": 6767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "感动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎndòng",
      "PrimaryDefinition": "be touched, be moved, affect, feel, stir, thrill, melt, magnetize, inspire, touch, reach, move"
    },
    "Keys": {
      "Word": 670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "桦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": "type of birch"
    },
    "Keys": {
      "Hanzi": 1842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1842,
      "Radical": "木",
      "FrequencyRank": 3856,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "返回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnhuí",
      "PrimaryDefinition": "come back, recur, turn back, come, hark back, regress, turn vehicle around, revert, come/go back, enter, return, go back, recall, take back, backtrack"
    },
    "Keys": {
      "Word": 3451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "步伐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùfá",
      "PrimaryDefinition": "pace, (measured) step, march"
    },
    "Keys": {
      "Word": 5730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "远程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnchéng",
      "PrimaryDefinition": "remote, long distance, long range"
    },
    "Keys": {
      "Word": 10552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "节省": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéshěng",
      "PrimaryDefinition": "saving, to save, to use sparingly, to cut down on"
    },
    "Keys": {
      "Word": 2624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蝥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "a fly which is used similarly to cantharides"
    },
    "Keys": {
      "Hanzi": 6020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6020,
      "Radical": "虫",
      "FrequencyRank": 6628,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "接见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiējiàn",
      "PrimaryDefinition": "to receive sb, to grant an interview"
    },
    "Keys": {
      "Word": 7506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "to clap hands; to cheer"
    },
    "Keys": {
      "Hanzi": 3656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3656,
      "Radical": "手",
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "庖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páo",
      "PrimaryDefinition": "kitchen; cooking, cuisine"
    },
    "Keys": {
      "Hanzi": 3996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3996,
      "Radical": "广",
      "FrequencyRank": 4149,
      "RadicalIndex": "53.5",
      "StrokeCount": 8
    }
  },
  "嘴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǐ",
      "PrimaryDefinition": "mouth, lips"
    },
    "Keys": {
      "Hanzi": 3342,
      "Word": 1249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.12",
      "Grade": 2,
      "GeneralStandard": 3342,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 1010,
      "StrokeCount": 16
    }
  },
  "颁奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān jiǎng",
      "PrimaryDefinition": "present reward/honor"
    },
    "Keys": {
      "Word": 5485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不幸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxìng",
      "PrimaryDefinition": "unfortunate"
    },
    "Keys": {
      "Word": 3274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "聩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "deaf"
    },
    "Keys": {
      "Hanzi": 5869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5869,
      "Radical": "耳",
      "FrequencyRank": 5209,
      "RadicalIndex": "128.9",
      "StrokeCount": 15
    }
  },
  "灯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng",
      "PrimaryDefinition": "lantern, lamp"
    },
    "Keys": {
      "Hanzi": 534,
      "Word": 615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.2",
      "Grade": 2,
      "GeneralStandard": 534,
      "Radical": "火",
      "HSK": 2,
      "FrequencyRank": 1115,
      "StrokeCount": 6
    }
  },
  "乂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "govern, control, manage; nurture"
    },
    "Keys": {
      "Hanzi": 3501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3501,
      "Radical": "丿",
      "RadicalIndex": "4.1",
      "StrokeCount": 2
    }
  },
  "什": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shén",
      "PrimaryDefinition": "file of ten soldiers; mixed, miscellaneous"
    },
    "Keys": {
      "Hanzi": 129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "GeneralStandard": 129,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 156,
      "StrokeCount": 4
    }
  },
  "舶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "large, ocean-going vessel"
    },
    "Keys": {
      "Hanzi": 2344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137.5",
      "GeneralStandard": 2344,
      "Radical": "舟",
      "HSK": 9,
      "FrequencyRank": 2318,
      "StrokeCount": 11
    }
  },
  "箩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "bamboo basket"
    },
    "Keys": {
      "Hanzi": 3122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3122,
      "Radical": "竹",
      "FrequencyRank": 4446,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "对立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìlì",
      "PrimaryDefinition": "oppose, set sth. against, be antagonist to"
    },
    "Keys": {
      "Word": 3435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "精致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngzhì",
      "PrimaryDefinition": "fine, exquisite, delicate"
    },
    "Keys": {
      "Word": 7626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "feud, fight, quarrel"
    },
    "Keys": {
      "Hanzi": 4949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4949,
      "Radical": "門",
      "FrequencyRank": 4894,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "贩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "peddler, hawker, street merchant"
    },
    "Keys": {
      "Hanzi": 1115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "GeneralStandard": 1115,
      "Radical": "貝",
      "HSK": 8,
      "FrequencyRank": 2466,
      "StrokeCount": 8
    }
  },
  "化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": "change, convert, reform; -ize"
    },
    "Keys": {
      "Hanzi": 132,
      "Word": 1558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "21.2",
      "Grade": 3,
      "GeneralStandard": 132,
      "Radical": "匕",
      "HSK": 3,
      "FrequencyRank": 178,
      "StrokeCount": 4
    }
  },
  "藁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "straw, hay; dry, withered"
    },
    "Keys": {
      "Hanzi": 6211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6211,
      "Radical": "艸",
      "FrequencyRank": 6044,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "渔民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúmín",
      "PrimaryDefinition": "fisherman, peterman, fisherfolk"
    },
    "Keys": {
      "Word": 10497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "重建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngjiàn",
      "PrimaryDefinition": "to rebuild, to reestablish, reconstruction, rebuilding"
    },
    "Keys": {
      "Word": 4419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "宥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "forgive, pardon, indulge"
    },
    "Keys": {
      "Hanzi": 4358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4358,
      "Radical": "宀",
      "FrequencyRank": 4707,
      "RadicalIndex": "40.6",
      "StrokeCount": 9
    }
  },
  "密码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìmǎ",
      "PrimaryDefinition": "code, secret code, password, pin number"
    },
    "Keys": {
      "Word": 2737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "统筹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒngchóu",
      "PrimaryDefinition": "plan as a whole"
    },
    "Keys": {
      "Word": 9542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玩耍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánshuǎ",
      "PrimaryDefinition": "to play (as children do), to amuse oneself"
    },
    "Keys": {
      "Word": 9651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缺口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quēkǒu",
      "PrimaryDefinition": "nick, jag, gap, shortfall"
    },
    "Keys": {
      "Word": 8813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "廓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuò",
      "PrimaryDefinition": "broad, wide, open, empty; to expand"
    },
    "Keys": {
      "Hanzi": 2989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.11",
      "GeneralStandard": 2989,
      "Radical": "广",
      "HSK": 8,
      "FrequencyRank": 2911,
      "StrokeCount": 13
    }
  },
  "丰硕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngshuò",
      "PrimaryDefinition": "plentiful, substantial, rich (in resources etc)"
    },
    "Keys": {
      "Word": 6562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7962,
      "Radical": "魚",
      "FrequencyRank": 8391,
      "RadicalIndex": "195.1",
      "StrokeCount": 17
    }
  },
  "晓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎo",
      "PrimaryDefinition": "dawn, daybreak; clear, explicit"
    },
    "Keys": {
      "Hanzi": 1889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.6",
      "GeneralStandard": 1889,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 1357,
      "StrokeCount": 10
    }
  },
  "荖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "betel pepper"
    },
    "Keys": {
      "Hanzi": 6783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6783,
      "Radical": "艸",
      "FrequencyRank": 8291,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "描写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáoxiě",
      "PrimaryDefinition": "describe, depict, portray"
    },
    "Keys": {
      "Word": 2743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "桉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "eucalyptus"
    },
    "Keys": {
      "Hanzi": 4462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4462,
      "Radical": "木",
      "FrequencyRank": 5278,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "力度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìdù",
      "PrimaryDefinition": "strength, vigor, efforts, (music) dynamics"
    },
    "Keys": {
      "Word": 7992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "详": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáng",
      "PrimaryDefinition": "detailed, complete, thorough"
    },
    "Keys": {
      "Hanzi": 1301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "GeneralStandard": 1301,
      "Radical": "言",
      "HSK": 5,
      "FrequencyRank": 1439,
      "StrokeCount": 8
    }
  },
  "鞲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "leather arm guard"
    },
    "Keys": {
      "Hanzi": 6369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6369,
      "Radical": "革",
      "FrequencyRank": 6049,
      "RadicalIndex": "177.1",
      "StrokeCount": 19
    }
  },
  "饔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "breakfast; eat prepared food"
    },
    "Keys": {
      "Hanzi": 6475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6475,
      "Radical": "食",
      "FrequencyRank": 5019,
      "RadicalIndex": "184.13",
      "StrokeCount": 22
    }
  },
  "筷子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàizi",
      "PrimaryDefinition": "chopsticks, CL:對|对[dui4],根[gen1],把[ba3],雙|双[shuang1]"
    },
    "Keys": {
      "Word": 827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "舅舅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùjiu",
      "PrimaryDefinition": "mother's brother, maternal uncle (informal), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 7675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "点头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎntóu",
      "PrimaryDefinition": "take a bow, nod one's head, noddle, nod"
    },
    "Keys": {
      "Word": 622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "poison, venom; poisonous"
    },
    "Keys": {
      "Hanzi": 1362,
      "Word": 3433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "80.4",
      "Grade": 5,
      "GeneralStandard": 1362,
      "Radical": "毋",
      "HSK": 5,
      "FrequencyRank": 947,
      "StrokeCount": 9
    }
  },
  "肝脏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānzàng",
      "PrimaryDefinition": "liver"
    },
    "Keys": {
      "Word": 6669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "列为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièwéi",
      "PrimaryDefinition": "be classified as"
    },
    "Keys": {
      "Word": 2708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "狱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "prison, jail; case; lawsuit"
    },
    "Keys": {
      "Hanzi": 1605
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.6",
      "GeneralStandard": 1605,
      "Radical": "犬",
      "HSK": 7,
      "FrequencyRank": 1597,
      "StrokeCount": 9
    }
  },
  "大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dà",
      "PrimaryDefinition": "big"
    },
    "Keys": {
      "Hanzi": 32,
      "Radical": 37,
      "Word": 62
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37",
      "Grade": 1,
      "GeneralStandard": 32,
      "Radical": "大",
      "HSK": 1,
      "FrequencyRank": 17,
      "StrokeCount": 3
    }
  },
  "熠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "bright and sparkling"
    },
    "Keys": {
      "Hanzi": 5996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5996,
      "Radical": "火",
      "FrequencyRank": 4210,
      "RadicalIndex": "86.11",
      "StrokeCount": 15
    }
  },
  "这样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèyàng",
      "PrimaryDefinition": "so, such, like this, this way"
    },
    "Keys": {
      "Word": 1214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "想法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngfa",
      "PrimaryDefinition": "view, stance, mentality, idea, notion, sentiment, judgment, construct, thought, opinion, conception, viewpoint, judgement, belief, mind-set, what one has in mind, persuasion, perspective, concept, mind, impression"
    },
    "Keys": {
      "Word": 1084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "紊乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěnluàn",
      "PrimaryDefinition": "disorder, chaos"
    },
    "Keys": {
      "Word": 9746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "理解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐjiě",
      "PrimaryDefinition": "understand, comprehend"
    },
    "Keys": {
      "Word": 1698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "如意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú yì",
      "PrimaryDefinition": "as one wishes"
    },
    "Keys": {
      "Word": 8896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suān",
      "PrimaryDefinition": "a fabulous beast"
    },
    "Keys": {
      "Hanzi": 7037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7037,
      "Radical": "犬",
      "FrequencyRank": 4637,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "替身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tìshēn",
      "PrimaryDefinition": "stand-in, substitute, body double, stuntman, scapegoat, fall guy, to stand in for sb else"
    },
    "Keys": {
      "Word": 9473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撤销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèxiāo",
      "PrimaryDefinition": "cancel, rescind, revoke"
    },
    "Keys": {
      "Word": 4407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "容颜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngyán",
      "PrimaryDefinition": "mien, complexion"
    },
    "Keys": {
      "Word": 8884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "parrot"
    },
    "Keys": {
      "Hanzi": 3350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3350,
      "Radical": "鳥",
      "FrequencyRank": 3488,
      "RadicalIndex": "196.11",
      "StrokeCount": 16
    }
  },
  "规矩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīju",
      "PrimaryDefinition": "rule, custom"
    },
    "Keys": {
      "Word": 6917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "难以想象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nányǐxiǎngxiàng",
      "PrimaryDefinition": "Unimaginable"
    },
    "Keys": {
      "Word": 8381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "园地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuándì",
      "PrimaryDefinition": "special page in periodical, special section in periodical, park/garden area, special section, croft, page in periodical, garden area, special section/page in periodical, garden plot, park area, field, scope, park"
    },
    "Keys": {
      "Word": 5287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "短处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnchu",
      "PrimaryDefinition": "shortcoming, defect, fault, one's weak points"
    },
    "Keys": {
      "Word": 1419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "灵敏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língmǐn",
      "PrimaryDefinition": "smart, clever, sensitive, keen, quick, sharp"
    },
    "Keys": {
      "Word": 8069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "great, gigantic; puppet"
    },
    "Keys": {
      "Hanzi": 4908
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4908,
      "Radical": "人",
      "FrequencyRank": 3946,
      "RadicalIndex": "9.1",
      "StrokeCount": 11
    }
  },
  "长久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángjiǔ",
      "PrimaryDefinition": "permanently, long, for a long time"
    },
    "Keys": {
      "Word": 4392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "power, force; tendency"
    },
    "Keys": {
      "Hanzi": 977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.6",
      "GeneralStandard": 977,
      "Radical": "力",
      "HSK": 3,
      "FrequencyRank": 506,
      "StrokeCount": 8
    }
  },
  "夥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒ",
      "PrimaryDefinition": "companion; partner; assistant"
    },
    "Keys": {
      "Hanzi": 7669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7669,
      "Radical": "夕",
      "FrequencyRank": 4577,
      "RadicalIndex": "36.11",
      "StrokeCount": 14
    }
  },
  "哨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shào",
      "PrimaryDefinition": "whistle, blow whistle; chirp"
    },
    "Keys": {
      "Hanzi": 1904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1904,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2383,
      "StrokeCount": 10
    }
  },
  "凤凰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènghuáng",
      "PrimaryDefinition": "Fenghuang county in Xiangxi Tujia and Miao autonomous prefecture 湘西土家族苗族自治州[Xiang1 xi1 Tu3 jia1 zu2 Miao2 zu2 zi4 zhi4 zhou1], phoenix"
    },
    "Keys": {
      "Word": 6595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欺负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīfu",
      "PrimaryDefinition": "browbeat, take advantage of, pick on"
    },
    "Keys": {
      "Word": 4918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "动工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng gōng",
      "PrimaryDefinition": "begin construction"
    },
    "Keys": {
      "Word": 6333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pó",
      "PrimaryDefinition": "old woman; grandmother"
    },
    "Keys": {
      "Hanzi": 2435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.8",
      "GeneralStandard": 2435,
      "Radical": "女",
      "HSK": 4,
      "FrequencyRank": 1347,
      "StrokeCount": 11
    }
  },
  "何处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héchù",
      "PrimaryDefinition": "where"
    },
    "Keys": {
      "Word": 7033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "春季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūnjì",
      "PrimaryDefinition": "springtime"
    },
    "Keys": {
      "Word": 2346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "岳父": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèfù",
      "PrimaryDefinition": "wife's father, father-in-law"
    },
    "Keys": {
      "Word": 10564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邮局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóujú",
      "PrimaryDefinition": "post office"
    },
    "Keys": {
      "Word": 3115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "没收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòshōu",
      "PrimaryDefinition": "to confiscate, to seize"
    },
    "Keys": {
      "Word": 4876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "电网": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànwǎng",
      "PrimaryDefinition": "electrical network"
    },
    "Keys": {
      "Word": 6274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "正确": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngquè",
      "PrimaryDefinition": "correct, true, exact, proper, right"
    },
    "Keys": {
      "Word": 1215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "可想而知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěxiǎngérzhī",
      "PrimaryDefinition": "it is obvious that..., as one can well imagine..."
    },
    "Keys": {
      "Word": 7803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "lady"
    },
    "Keys": {
      "Hanzi": 5831
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5831,
      "Radical": "女",
      "FrequencyRank": 5645,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "魄力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòlì",
      "PrimaryDefinition": "courage, daring, boldness, resolution, drive"
    },
    "Keys": {
      "Word": 8575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": "timorous; nervous"
    },
    "Keys": {
      "Hanzi": 3694
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3694,
      "Radical": "心",
      "FrequencyRank": 4164,
      "RadicalIndex": "61.3",
      "StrokeCount": 7
    }
  },
  "退休": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuìxiū",
      "PrimaryDefinition": "to retire, to go into retirement, to step down"
    },
    "Keys": {
      "Word": 1994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "阽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6632,
      "Radical": "阜",
      "FrequencyRank": 6938,
      "RadicalIndex": "170.5",
      "StrokeCount": 7
    }
  },
  "梌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7141,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "剥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "peel"
    },
    "Keys": {
      "Hanzi": 2129,
      "Word": 5514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.8",
      "Grade": 7,
      "GeneralStandard": 2129,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 1959,
      "StrokeCount": 10
    }
  },
  "差额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāé",
      "PrimaryDefinition": "balance (financial), discrepancy (in a sum or quota), difference"
    },
    "Keys": {
      "Word": 5783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "长假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángjià",
      "PrimaryDefinition": "long vacation, refers to one week national holiday in PRC starting 1st May and 1st Oct"
    },
    "Keys": {
      "Word": 4387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "大脑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànǎo",
      "PrimaryDefinition": "brain, cerebrum"
    },
    "Keys": {
      "Word": 3383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "架子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàzi",
      "PrimaryDefinition": "shelf, frame, stand, framework, airs, arrogance"
    },
    "Keys": {
      "Word": 7371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "má",
      "PrimaryDefinition": "hemp"
    },
    "Keys": {
      "Hanzi": 2379,
      "Radical": 200,
      "Word": 8174
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "200",
      "Grade": 7,
      "GeneralStandard": 2379,
      "Radical": "麻",
      "HSK": 3,
      "FrequencyRank": 1108,
      "StrokeCount": 11
    }
  },
  "吉他": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jítā",
      "PrimaryDefinition": "guitar (loanword), CL:把[ba3]"
    },
    "Keys": {
      "Word": 7290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "excessive; scour, wash out"
    },
    "Keys": {
      "Hanzi": 858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 858,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2969,
      "StrokeCount": 7
    }
  },
  "愎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "obstinate, stubborn, headstrong"
    },
    "Keys": {
      "Hanzi": 5299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5299,
      "Radical": "心",
      "FrequencyRank": 4663,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "国旗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóqí",
      "PrimaryDefinition": "flag, ensign, national flag"
    },
    "Keys": {
      "Word": 4639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "俶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "start, begin; beginning; arrange"
    },
    "Keys": {
      "Hanzi": 4566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4566,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "举世无双": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔshì-wúshuāng",
      "PrimaryDefinition": "unrivaled (idiom), world number one, unique, unequaled"
    },
    "Keys": {
      "Word": 7691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "cackling of fowls to gag, to vomit"
    },
    "Keys": {
      "Hanzi": 5445
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5445,
      "Radical": "口",
      "FrequencyRank": 4610,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "处理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔlǐ",
      "PrimaryDefinition": "handle, deal with, dispose of, process"
    },
    "Keys": {
      "Word": 1365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "宁愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nìngyuàn",
      "PrimaryDefinition": "prefer, (would) rather, better, choose"
    },
    "Keys": {
      "Word": 8428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谴责": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎnzé",
      "PrimaryDefinition": "condemn, denounce, censure"
    },
    "Keys": {
      "Word": 8682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kài",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6717,
      "Radical": "火",
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "淹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "drown; cover with liquid, steep"
    },
    "Keys": {
      "Hanzi": 2415,
      "Word": 10169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 7,
      "GeneralStandard": 2415,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2580,
      "StrokeCount": 11
    }
  },
  "罢工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàgōng",
      "PrimaryDefinition": "go on strike"
    },
    "Keys": {
      "Word": 4291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáng",
      "PrimaryDefinition": "hide, conceal; hoard, store up"
    },
    "Keys": {
      "Hanzi": 3392,
      "Word": 4373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.14",
      "Grade": 6,
      "GeneralStandard": 3392,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 907,
      "StrokeCount": 17
    }
  },
  "溆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "river in Hunan"
    },
    "Keys": {
      "Hanzi": 5287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5287,
      "Radical": "水",
      "FrequencyRank": 5366,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "抢劫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎngjié",
      "PrimaryDefinition": "to rob, looting"
    },
    "Keys": {
      "Word": 8699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "species of water plant"
    },
    "Keys": {
      "Hanzi": 6956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6956,
      "Radical": "艸",
      "FrequencyRank": 9902,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "歌唱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēchàng",
      "PrimaryDefinition": "sing"
    },
    "Keys": {
      "Word": 4609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "溜达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liūda",
      "PrimaryDefinition": "to stroll, to go for a walk"
    },
    "Keys": {
      "Word": 8086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焚烧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fénshāo",
      "PrimaryDefinition": "to burn, to set on fire"
    },
    "Keys": {
      "Word": 6548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聘请": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìnqǐng",
      "PrimaryDefinition": "engage, invite"
    },
    "Keys": {
      "Word": 4904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "吃不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chībushàng",
      "PrimaryDefinition": "unable to get anything to eat, to miss a meal"
    },
    "Keys": {
      "Word": 5892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒhou",
      "PrimaryDefinition": "heat control, maturity, crucial moment"
    },
    "Keys": {
      "Word": 7241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎng",
      "PrimaryDefinition": "surname of the mother of Mencius"
    },
    "Keys": {
      "Hanzi": 3516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3516,
      "Radical": "人",
      "FrequencyRank": 7373,
      "RadicalIndex": "9.2",
      "StrokeCount": 4
    }
  },
  "深刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnkè",
      "PrimaryDefinition": "deep, profound"
    },
    "Keys": {
      "Word": 1879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "无理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúlǐ",
      "PrimaryDefinition": "irrational, unreasonable"
    },
    "Keys": {
      "Word": 9774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "贫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pín",
      "PrimaryDefinition": "poor, impoverished, needy"
    },
    "Keys": {
      "Hanzi": 1178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "GeneralStandard": 1178,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 1359,
      "StrokeCount": 8
    }
  },
  "板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎn",
      "PrimaryDefinition": "plank, board; iron or tin plate"
    },
    "Keys": {
      "Hanzi": 1025,
      "Word": 1274
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 3,
      "GeneralStandard": 1025,
      "Radical": "木",
      "HSK": 2,
      "FrequencyRank": 930,
      "StrokeCount": 8
    }
  },
  "崶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7359
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7359,
      "Radical": "山",
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "浩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "great, numerous, vast, abundant"
    },
    "Keys": {
      "Hanzi": 2073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2073,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 1864,
      "StrokeCount": 10
    }
  },
  "舅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "134.7",
      "GeneralStandard": 2957,
      "Radical": "臼",
      "HSK": 8,
      "FrequencyRank": 2375,
      "StrokeCount": 13
    }
  },
  "远离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnlí",
      "PrimaryDefinition": "be far away, stand aloof, be far off (the mark/etc.)"
    },
    "Keys": {
      "Word": 5295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "无": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "not"
    },
    "Keys": {
      "Hanzi": 81,
      "Radical": 71,
      "Word": 2983
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "71",
      "Grade": 4,
      "GeneralStandard": 81,
      "Radical": "无",
      "HSK": 4,
      "FrequencyRank": 105,
      "StrokeCount": 4
    }
  },
  "消灭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāomiè",
      "PrimaryDefinition": "eradicate, die out, eliminate, abolish, wipe out, annihilate, kill, slake, decimate, carry off, disappear, exterminate, lapse, expunge, abolition, knock out, quell, destroy, pass away, kill off, deafen, extermination, detruncate, liquidate, snuff_out, snuff out, extirpate, snuff, elimination, perish, discreate, vanish, extinguish"
    },
    "Keys": {
      "Word": 5168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "峗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6840
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6840,
      "Radical": "山",
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "茜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "madder, rubia cordifolia; reeds"
    },
    "Keys": {
      "Hanzi": 4108
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4108,
      "Radical": "艸",
      "FrequencyRank": 3097,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "斧子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔzi",
      "PrimaryDefinition": "axe, hatchet, CL:把[ba3]"
    },
    "Keys": {
      "Word": 6614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隐患": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnhuàn",
      "PrimaryDefinition": "a danger concealed within sth, hidden damage, misfortune not visible from the surface"
    },
    "Keys": {
      "Word": 10398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "划分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàfēn",
      "PrimaryDefinition": "divide, differentiate"
    },
    "Keys": {
      "Word": 3552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "取经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ jīng",
      "PrimaryDefinition": "to journey to India on a quest for the Buddhist scriptures, to learn by studying another's experience"
    },
    "Keys": {
      "Word": 8785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "直奔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíbèn",
      "PrimaryDefinition": "to go straight to, to make a beeline for"
    },
    "Keys": {
      "Word": 10757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "nail, spike; pursue closely"
    },
    "Keys": {
      "Hanzi": 758,
      "Word": 6309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.2",
      "Grade": 7,
      "GeneralStandard": 758,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2267,
      "StrokeCount": 7
    }
  },
  "腰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "waist; kidney"
    },
    "Keys": {
      "Hanzi": 2969,
      "Word": 3075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.9",
      "Grade": 4,
      "GeneralStandard": 2969,
      "Radical": "肉",
      "HSK": 4,
      "FrequencyRank": 1458,
      "StrokeCount": 13
    }
  },
  "鄚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7309,
      "Radical": "邑",
      "FrequencyRank": 7195,
      "RadicalIndex": "163.11",
      "StrokeCount": 12
    }
  },
  "世界杯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjièbēi",
      "PrimaryDefinition": "World Cup"
    },
    "Keys": {
      "Word": 1910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "馒头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mántou",
      "PrimaryDefinition": "steamed roll, steamed bun, steamed bread, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 4851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "母鸡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔjī",
      "PrimaryDefinition": "hen"
    },
    "Keys": {
      "Word": 4880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "牡丹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔdan",
      "PrimaryDefinition": "Mudan District of Heze City 菏澤市|菏泽市[He2 ze2 Shi4], Shandong, Mutan township in Pingtung County 屏東縣|屏东县[Ping2 dong1 Xian4], Taiwan, tree peony (Paeonia suffruticosa)"
    },
    "Keys": {
      "Word": 8341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "hungry; greedy for; hunger"
    },
    "Keys": {
      "Hanzi": 2012,
      "Word": 95
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.7",
      "Grade": 1,
      "GeneralStandard": 2012,
      "Radical": "食",
      "HSK": 1,
      "FrequencyRank": 1911,
      "StrokeCount": 10
    }
  },
  "相应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngying",
      "PrimaryDefinition": "to correspond, answering (one another), to agree (among the part), corresponding, relevant, appropriate, (modify) accordingly"
    },
    "Keys": {
      "Word": 4043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "擘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāi",
      "PrimaryDefinition": "thumb; break, tear open, rip"
    },
    "Keys": {
      "Hanzi": 6303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6303,
      "Radical": "手",
      "FrequencyRank": 5448,
      "RadicalIndex": "64.13",
      "StrokeCount": 17
    }
  },
  "怯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "lacking in courage, afraid"
    },
    "Keys": {
      "Hanzi": 1266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1266,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 2488,
      "StrokeCount": 8
    }
  },
  "宽度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāndù",
      "PrimaryDefinition": "width"
    },
    "Keys": {
      "Word": 3672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "棨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "tally or wooden pass"
    },
    "Keys": {
      "Hanzi": 7443
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7443,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "敬酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng jiǔ",
      "PrimaryDefinition": "to toast, to propose a toast"
    },
    "Keys": {
      "Word": 7643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìn",
      "PrimaryDefinition": "trust, believe; letter"
    },
    "Keys": {
      "Hanzi": 1570,
      "Word": 2062
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "Grade": 3,
      "GeneralStandard": 1570,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 176,
      "StrokeCount": 9
    }
  },
  "焦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "burned, scorched; anxious, vexed"
    },
    "Keys": {
      "Hanzi": 2695,
      "Word": 7471
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.8",
      "Grade": 7,
      "GeneralStandard": 2695,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 1554,
      "StrokeCount": 12
    }
  },
  "钿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "hairpin; gold inlaid work, filigree"
    },
    "Keys": {
      "Hanzi": 4535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4535,
      "Radical": "金",
      "FrequencyRank": 5171,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "全年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánnián",
      "PrimaryDefinition": "the whole year, all year long"
    },
    "Keys": {
      "Word": 947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "年前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánqián",
      "PrimaryDefinition": "...years ago"
    },
    "Keys": {
      "Word": 3747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "崇高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chónggāo",
      "PrimaryDefinition": "lofty, sublime"
    },
    "Keys": {
      "Word": 5926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吃喝玩乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chīhē-wánlè",
      "PrimaryDefinition": "to eat, drink and be merry (idiom), to abandon oneself to a life of pleasure"
    },
    "Keys": {
      "Word": 5893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhě",
      "PrimaryDefinition": "pleat, crease, wrinkles"
    },
    "Keys": {
      "Hanzi": 6185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6185,
      "Radical": "衣",
      "FrequencyRank": 3769,
      "RadicalIndex": "145.11",
      "StrokeCount": 16
    }
  },
  "秩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "order; orderly; salary; decade"
    },
    "Keys": {
      "Hanzi": 1947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "GeneralStandard": 1947,
      "Radical": "禾",
      "HSK": 7,
      "FrequencyRank": 1749,
      "StrokeCount": 10
    }
  },
  "任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "trust to, rely on, appoint; to bear, duty, office; allow"
    },
    "Keys": {
      "Hanzi": 467,
      "Word": 1854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 3,
      "GeneralStandard": 467,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 186,
      "StrokeCount": 6
    }
  },
  "废品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèipǐn",
      "PrimaryDefinition": "production rejects, seconds, scrap, discarded material"
    },
    "Keys": {
      "Word": 6525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huā",
      "PrimaryDefinition": "rushing sound; gush forward"
    },
    "Keys": {
      "Hanzi": 1507
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "GeneralStandard": 1507,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2486,
      "StrokeCount": 9
    }
  },
  "提拔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíba",
      "PrimaryDefinition": "to promote to a higher job, to select for promotion"
    },
    "Keys": {
      "Word": 9456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "surname; ginger"
    },
    "Keys": {
      "Hanzi": 1643,
      "Word": 7438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "Grade": 7,
      "GeneralStandard": 1643,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2394,
      "StrokeCount": 9
    }
  },
  "酴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "leaven, yeast; wine"
    },
    "Keys": {
      "Hanzi": 7658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7658,
      "Radical": "酉",
      "FrequencyRank": 7422,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "接替": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiētì",
      "PrimaryDefinition": "to replace, to take over (a position or post)"
    },
    "Keys": {
      "Word": 7514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "surname; kill, destroy"
    },
    "Keys": {
      "Hanzi": 517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "GeneralStandard": 517,
      "Radical": "刀",
      "HSK": 9,
      "FrequencyRank": 751,
      "StrokeCount": 6
    }
  },
  "周旋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōuxuán",
      "PrimaryDefinition": "to mix with others, to socialize, to deal with, to contend"
    },
    "Keys": {
      "Word": 10839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7290,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néng",
      "PrimaryDefinition": "to be able; can, permitted to; ability"
    },
    "Keys": {
      "Hanzi": 2146,
      "Word": 268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "Grade": 1,
      "GeneralStandard": 2146,
      "Radical": "肉",
      "HSK": 1,
      "FrequencyRank": 35,
      "StrokeCount": 10
    }
  },
  "涍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7054
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7054,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "搅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "disturb, agitate, stir up"
    },
    "Keys": {
      "Hanzi": 2536,
      "Word": 7483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2536,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2679,
      "StrokeCount": 12
    }
  },
  "果真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒzhēn",
      "PrimaryDefinition": "really, as expected"
    },
    "Keys": {
      "Word": 6939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7136,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "辞典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cídiǎn",
      "PrimaryDefinition": "dictionary (of Chinese compound words), also written 詞典|词典[ci2 dian3], CL:本[ben3],部[bu4]"
    },
    "Keys": {
      "Word": 3358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "小提琴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎotíqín",
      "PrimaryDefinition": "violin"
    },
    "Keys": {
      "Word": 9975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "王子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wángzǐ",
      "PrimaryDefinition": "king's son, prince"
    },
    "Keys": {
      "Word": 5130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "黑夜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēiyè",
      "PrimaryDefinition": "night"
    },
    "Keys": {
      "Word": 4660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "只见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐjiàn",
      "PrimaryDefinition": "Only see"
    },
    "Keys": {
      "Word": 4227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "人物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénwù",
      "PrimaryDefinition": "character, persona, original, spirit, figure painting, character in literature, figure, person in literature, person, fish, personage, personality"
    },
    "Keys": {
      "Word": 3838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "医务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīwù",
      "PrimaryDefinition": "medical affairs"
    },
    "Keys": {
      "Word": 10278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歌迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēmí",
      "PrimaryDefinition": "lover of song, fan of a singer"
    },
    "Keys": {
      "Word": 1485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "回想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíxiǎng",
      "PrimaryDefinition": "look_back, retrospect, recur, recollection, cut back, think back, echo, remember, reflect, recollect, recall, recapture, go back, flash_back, commemorate, retroject, retrace, flash back"
    },
    "Keys": {
      "Word": 7211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "town, market place; suppress"
    },
    "Keys": {
      "Hanzi": 3256,
      "Word": 5337
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.1",
      "Grade": 6,
      "GeneralStandard": 3256,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 1002,
      "StrokeCount": 15
    }
  },
  "谊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "friendship; appropriate, suitable"
    },
    "Keys": {
      "Hanzi": 2128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "GeneralStandard": 2128,
      "Radical": "言",
      "HSK": 5,
      "FrequencyRank": 2136,
      "StrokeCount": 10
    }
  },
  "幸运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngyùn",
      "PrimaryDefinition": "very fortunate, lucky"
    },
    "Keys": {
      "Word": 2061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "中庸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngyōng",
      "PrimaryDefinition": "the Doctrine of the Mean, one of the Four Books 四書|四书[Si4 shu1], golden mean (Confucianism), (literary) (of person) mediocre, ordinary"
    },
    "Keys": {
      "Word": 10814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "干什么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gànshénme",
      "PrimaryDefinition": "do something"
    },
    "Keys": {
      "Word": 117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "致力于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìlìyú",
      "PrimaryDefinition": "Dedicate"
    },
    "Keys": {
      "Word": 10798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "针灸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnjiǔ",
      "PrimaryDefinition": "acupuncture and moxibustion, to give or have acupuncture and moxibustion"
    },
    "Keys": {
      "Word": 10694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "航天员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángtiānyuán",
      "PrimaryDefinition": "astronaut"
    },
    "Keys": {
      "Word": 6996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "corner"
    },
    "Keys": {
      "Hanzi": 7071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7071,
      "Radical": "宀",
      "RadicalIndex": "40.7",
      "StrokeCount": 10
    }
  },
  "呖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "used in onomatopoetic expressions"
    },
    "Keys": {
      "Hanzi": 3702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3702,
      "Radical": "口",
      "FrequencyRank": 5864,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "阳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 572
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.4",
      "GeneralStandard": 572,
      "Radical": "阜",
      "HSK": 2,
      "FrequencyRank": 650,
      "StrokeCount": 6
    }
  },
  "醋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "vinegar; jealousy, envy"
    },
    "Keys": {
      "Hanzi": 3222,
      "Word": 4460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.8",
      "Grade": 6,
      "GeneralStandard": 3222,
      "Radical": "酉",
      "HSK": 6,
      "FrequencyRank": 3076,
      "StrokeCount": 15
    }
  },
  "吩咐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnfu",
      "PrimaryDefinition": "to tell, to instruct, to command"
    },
    "Keys": {
      "Word": 6545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学艺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéyì",
      "PrimaryDefinition": "to learn a skill or art"
    },
    "Keys": {
      "Word": 10135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牵头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān tóu",
      "PrimaryDefinition": "to lead (an animal by the head), to take the lead, to coordinate (a combined operation), to mediate, a go-between (e.g. marriage broker)"
    },
    "Keys": {
      "Word": 8655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凶恶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngè",
      "PrimaryDefinition": "variant of 兇惡|凶恶, fierce, ferocious, fiendish, frightening"
    },
    "Keys": {
      "Word": 10065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yē",
      "PrimaryDefinition": "phonetic used in Korean place names"
    },
    "Keys": {
      "Hanzi": 7013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7013,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 10
    }
  },
  "伯父": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bófù",
      "PrimaryDefinition": "father's elder brother, term of respect for older man, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 5670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "bud, sprout, shoot"
    },
    "Keys": {
      "Hanzi": 664,
      "Word": 10166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "Grade": 7,
      "GeneralStandard": 664,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2737,
      "StrokeCount": 7
    }
  },
  "互动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùdòng",
      "PrimaryDefinition": "relate"
    },
    "Keys": {
      "Word": 4668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "头头是道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóutóu-shìdào",
      "PrimaryDefinition": "clear and logical"
    },
    "Keys": {
      "Word": 9558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6914,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "鞭炮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānpào",
      "PrimaryDefinition": "firecrackers, string of small firecrackers, CL:枚[mei2]"
    },
    "Keys": {
      "Word": 5604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淘气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo qì",
      "PrimaryDefinition": "naughty, mischievous"
    },
    "Keys": {
      "Word": 9439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㛃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "clean; pure; clear, usually used for names"
    },
    "Keys": {
      "Hanzi": 6757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6757,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "据悉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùxī",
      "PrimaryDefinition": "according to reports, it is reported (that)"
    },
    "Keys": {
      "Word": 7708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "speak ill of, vilify; berate"
    },
    "Keys": {
      "Hanzi": 4678
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4678,
      "Radical": "言",
      "FrequencyRank": 5590,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "漳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "name of a river in Henan"
    },
    "Keys": {
      "Hanzi": 5809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5809,
      "Radical": "水",
      "FrequencyRank": 3813,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "灰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "ashes; dust; lime, mortar"
    },
    "Keys": {
      "Hanzi": 397,
      "Word": 7198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.2",
      "Grade": 7,
      "GeneralStandard": 397,
      "Radical": "火",
      "HSK": 5,
      "FrequencyRank": 1311,
      "StrokeCount": 6
    }
  },
  "膈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "diaphragm"
    },
    "Keys": {
      "Hanzi": 5766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5766,
      "Radical": "肉",
      "FrequencyRank": 5398,
      "RadicalIndex": "130.1",
      "StrokeCount": 14
    }
  },
  "呦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "the bleating of the deer"
    },
    "Keys": {
      "Hanzi": 3921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3921,
      "Radical": "口",
      "FrequencyRank": 5390,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "西边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xībian",
      "PrimaryDefinition": "west, west side, western part, to the west of"
    },
    "Keys": {
      "Word": 382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "羓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "dried meat"
    },
    "Keys": {
      "Hanzi": 7046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7046,
      "Radical": "羊",
      "RadicalIndex": "123.4",
      "StrokeCount": 10
    }
  },
  "萳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7303,
      "Radical": "艸",
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "陪同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péitóng",
      "PrimaryDefinition": "accompany"
    },
    "Keys": {
      "Word": 4899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīqǐ",
      "PrimaryDefinition": "in_chorus, together, in all, in the same place, holus-bolus, altogether, in company"
    },
    "Keys": {
      "Word": 434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "蓉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "hibiscus; Chengdu, Sichuan"
    },
    "Keys": {
      "Hanzi": 2858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2858,
      "Radical": "艸",
      "FrequencyRank": 2779,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "降": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàng",
      "PrimaryDefinition": "descend, fall, drop; lower, down"
    },
    "Keys": {
      "Hanzi": 1321,
      "Word": 2615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.6",
      "Grade": 4,
      "GeneralStandard": 1321,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 744,
      "StrokeCount": 8
    }
  },
  "岍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6593
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6593,
      "Radical": "山",
      "FrequencyRank": 9783,
      "RadicalIndex": "46.6",
      "StrokeCount": 7
    }
  },
  "土地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔdi",
      "PrimaryDefinition": "country, acre, terra, soil, premise, realm, territory, dirt, local god of the land, farming, earth, ground, kingdom, holding, agrarian, solid ground, benefice, esplees, tract, village god, glebe, terra firma, land, dry land"
    },
    "Keys": {
      "Word": 2954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "构造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòuzào",
      "PrimaryDefinition": "constitution, configuration, make-up, build, anatomy, construction, structure, tectonic, fabric, compages, making, contexture, texture, conformation, apparatus, formation, architecture, makeup"
    },
    "Keys": {
      "Word": 2506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "书架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūjià",
      "PrimaryDefinition": "bookshelf, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "简": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "simple, terse, succinct, a letter"
    },
    "Keys": {
      "Hanzi": 2954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.7",
      "GeneralStandard": 2954,
      "Radical": "竹",
      "HSK": 3,
      "FrequencyRank": 716,
      "StrokeCount": 13
    }
  },
  "小组": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎozǔ",
      "PrimaryDefinition": "squad, section, unit, group"
    },
    "Keys": {
      "Word": 1096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "燠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "warm; warmth"
    },
    "Keys": {
      "Hanzi": 6171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6171,
      "Radical": "火",
      "FrequencyRank": 6212,
      "RadicalIndex": "86.13",
      "StrokeCount": 16
    }
  },
  "懑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèn",
      "PrimaryDefinition": "be sick at heart, sorrowful, sad"
    },
    "Keys": {
      "Hanzi": 6292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6292,
      "Radical": "心",
      "FrequencyRank": 4480,
      "RadicalIndex": "61.13",
      "StrokeCount": 17
    }
  },
  "匿名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nìmíng",
      "PrimaryDefinition": "anonymous"
    },
    "Keys": {
      "Word": 8407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "embroidered official or sacrificial robe"
    },
    "Keys": {
      "Hanzi": 6381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6381,
      "Radical": "黹",
      "FrequencyRank": 6029,
      "RadicalIndex": "204.7",
      "StrokeCount": 19
    }
  },
  "认同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèntóng",
      "PrimaryDefinition": "identify, have meeting of the minds"
    },
    "Keys": {
      "Word": 4973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "盼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "look, gaze; expect, hope for"
    },
    "Keys": {
      "Hanzi": 1480,
      "Word": 8468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "Grade": 7,
      "GeneralStandard": 1480,
      "Radical": "目",
      "HSK": 6,
      "FrequencyRank": 2174,
      "StrokeCount": 9
    }
  },
  "吾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "i, my, our; resist, impede"
    },
    "Keys": {
      "Hanzi": 695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 695,
      "Radical": "口",
      "FrequencyRank": 1649,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "枹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "drumstick"
    },
    "Keys": {
      "Hanzi": 6801
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6801,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "普通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔtōng",
      "PrimaryDefinition": "ordinary, common, average"
    },
    "Keys": {
      "Word": 918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "归根到底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīgēndàodǐ",
      "PrimaryDefinition": "after all, in the final analysis, ultimately"
    },
    "Keys": {
      "Word": 6908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "rafters supporting roof tiles"
    },
    "Keys": {
      "Hanzi": 5649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5649,
      "Radical": "瓦",
      "FrequencyRank": 7161,
      "RadicalIndex": "98.11",
      "StrokeCount": 14
    }
  },
  "重返": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngfǎn",
      "PrimaryDefinition": "to return to"
    },
    "Keys": {
      "Word": 5922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "sprout"
    },
    "Keys": {
      "Radical": 45
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "今天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīntiān",
      "PrimaryDefinition": "today, at the present, now"
    },
    "Keys": {
      "Word": 178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "棘手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíshǒu",
      "PrimaryDefinition": "thorny (problem), intractable"
    },
    "Keys": {
      "Word": 7308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暑期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔqī",
      "PrimaryDefinition": "summer vacation time"
    },
    "Keys": {
      "Word": 9228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "救治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùzhì",
      "PrimaryDefinition": "to rescue and give medical treatment"
    },
    "Keys": {
      "Word": 7665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "river in Shandong province"
    },
    "Keys": {
      "Hanzi": 4982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4982,
      "Radical": "水",
      "FrequencyRank": 3649,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "举例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ lì",
      "PrimaryDefinition": "instance, quote, adduce, give an example"
    },
    "Keys": {
      "Word": 7689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芭蕾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bālěi",
      "PrimaryDefinition": "ballet (loanword)"
    },
    "Keys": {
      "Word": 5461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèn",
      "PrimaryDefinition": "soft, tender, delicate; young"
    },
    "Keys": {
      "Hanzi": 3183,
      "Word": 8396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.11",
      "Grade": 7,
      "GeneralStandard": 3183,
      "Radical": "女",
      "HSK": 8,
      "FrequencyRank": 2500,
      "StrokeCount": 14
    }
  },
  "馅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "filling, stuffing; secret"
    },
    "Keys": {
      "Hanzi": 2372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.8",
      "GeneralStandard": 2372,
      "Radical": "食",
      "HSK": 9,
      "FrequencyRank": 3765,
      "StrokeCount": 11
    }
  },
  "跺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": "stamp feet, step"
    },
    "Keys": {
      "Hanzi": 2916
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2916,
      "Radical": "足",
      "FrequencyRank": 3738,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "家伙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāhuo",
      "PrimaryDefinition": "household dish, implement or furniture, domestic animal, (coll.) guy, chap, weapon"
    },
    "Keys": {
      "Word": 7349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "警察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngchá",
      "PrimaryDefinition": "police officer, cop, trooper, bluecoat, finest, jack, peon, policeman, police force, constable, policemen, flattie, fuzz, constabulary, shamus, bull, bobby, robert, copper, tipstaff, rozzer, flatfoot, john, peeler, Vopo, law, runner, officer, gumshoe, pig, police, the police, beetle-crusher, flic"
    },
    "Keys": {
      "Word": 1645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "行情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángqíng",
      "PrimaryDefinition": "prices, quotation, (market) quotations, market, price, quotations"
    },
    "Keys": {
      "Word": 6993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "be noisy; chirp loudly"
    },
    "Keys": {
      "Hanzi": 3349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.13",
      "GeneralStandard": 3349,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2799,
      "StrokeCount": 16
    }
  },
  "软弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎnruò",
      "PrimaryDefinition": "weak, feeble, flabby"
    },
    "Keys": {
      "Word": 8906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "reject, abandon, discard"
    },
    "Keys": {
      "Hanzi": 842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "55.4",
      "GeneralStandard": 842,
      "Radical": "廾",
      "HSK": 5,
      "FrequencyRank": 1105,
      "StrokeCount": 7
    }
  },
  "钘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "ancient wine vessel"
    },
    "Keys": {
      "Hanzi": 6843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6843,
      "Radical": "金",
      "FrequencyRank": 7114,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "热情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèqíng",
      "PrimaryDefinition": "enthusiastic"
    },
    "Keys": {
      "Word": 951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "倾听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngtīng",
      "PrimaryDefinition": "listen attentively to"
    },
    "Keys": {
      "Word": 8739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "督促": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūcù",
      "PrimaryDefinition": "to supervise and urge completion of a task, to urge on"
    },
    "Keys": {
      "Word": 6355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艺人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìrén",
      "PrimaryDefinition": "creative person, artiste, entertainer, artist, artisan, cackler, actor, handcraftsman, performer"
    },
    "Keys": {
      "Word": 5245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "晪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7352,
      "Radical": "日",
      "RadicalIndex": "72.8",
      "StrokeCount": 12
    }
  },
  "鲧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔn",
      "PrimaryDefinition": "giant fish; father of emperor"
    },
    "Keys": {
      "Hanzi": 5973
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5973,
      "Radical": "魚",
      "FrequencyRank": 5120,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "庆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìng",
      "PrimaryDefinition": "congratulate, celebrate"
    },
    "Keys": {
      "Hanzi": 515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.3",
      "GeneralStandard": 515,
      "Radical": "广",
      "HSK": 3,
      "FrequencyRank": 1269,
      "StrokeCount": 6
    }
  },
  "峰回路转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnghuí-lùzhuǎn",
      "PrimaryDefinition": "the mountain road twists around each new peak (idiom), (of a mountain road) twisting and turning, fig. an opportunity has come unexpectedly, things have taken a new turn"
    },
    "Keys": {
      "Word": 6588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遵守": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnshǒu",
      "PrimaryDefinition": "honour, comply with, obey, keep, hold back, abide, keep back, comply, observe, abide_by, observance, abide by, respect, restrain, honor"
    },
    "Keys": {
      "Word": 4287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "自由": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìyóu",
      "PrimaryDefinition": "free, unrestrained"
    },
    "Keys": {
      "Word": 1240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "愚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "stupid, doltish, foolish"
    },
    "Keys": {
      "Hanzi": 2905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2905,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1895,
      "StrokeCount": 13
    }
  },
  "喻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "metaphor, analogy; example; like"
    },
    "Keys": {
      "Hanzi": 2644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "GeneralStandard": 2644,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2273,
      "StrokeCount": 12
    }
  },
  "毓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "give birth to; bring up, educate"
    },
    "Keys": {
      "Hanzi": 5754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5754,
      "Radical": "毋",
      "FrequencyRank": 2986,
      "RadicalIndex": "80.9",
      "StrokeCount": 14
    }
  },
  "煌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "bright, shining, luminous"
    },
    "Keys": {
      "Hanzi": 3004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.9",
      "GeneralStandard": 3004,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 2276,
      "StrokeCount": 13
    }
  },
  "球队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúduì",
      "PrimaryDefinition": "side, team, ball game team"
    },
    "Keys": {
      "Word": 939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "随着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suízhe",
      "PrimaryDefinition": "along with, in the wake of, in pace with"
    },
    "Keys": {
      "Word": 3951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "曙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "bright, light of rising sun"
    },
    "Keys": {
      "Hanzi": 3407
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.14",
      "GeneralStandard": 3407,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 3385,
      "StrokeCount": 17
    }
  },
  "有点儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒudiǎnr5",
      "PrimaryDefinition": "slightly, a little, somewhat"
    },
    "Keys": {
      "Word": 1173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "衩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎ",
      "PrimaryDefinition": "the open seam of a garment which allows freedom of movement"
    },
    "Keys": {
      "Hanzi": 4046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4046,
      "Radical": "衣",
      "FrequencyRank": 4675,
      "RadicalIndex": "145.3",
      "StrokeCount": 8
    }
  },
  "绝招": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juézhāo",
      "PrimaryDefinition": "unique skill, unexpected tricky move (as a last resort), masterstroke, finishing blow"
    },
    "Keys": {
      "Word": 7724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堪称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kānchēng",
      "PrimaryDefinition": "may be rated as, can be rated as..."
    },
    "Keys": {
      "Word": 7767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "真挚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnzhì",
      "PrimaryDefinition": "sincere, sincerity"
    },
    "Keys": {
      "Word": 10704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎn",
      "PrimaryDefinition": "sincere; real, true; truth"
    },
    "Keys": {
      "Hanzi": 5539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5539,
      "Radical": "亠",
      "RadicalIndex": "8.11",
      "StrokeCount": 13
    }
  },
  "呕吐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒutù",
      "PrimaryDefinition": "heave, barf, sick, puke, gag, vomit, cat, upchuck, reject, spew, vomitive, disgorge, bring_up, be sick, regorge, heave_up, throw up"
    },
    "Keys": {
      "Word": 8451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "攻读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngdú",
      "PrimaryDefinition": "to major (in a field), to study a specialty to obtain a higher degree"
    },
    "Keys": {
      "Word": 6790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "a guide to goodness"
    },
    "Keys": {
      "Hanzi": 4320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4320,
      "Radical": "羊",
      "FrequencyRank": 7224,
      "RadicalIndex": "123.3",
      "StrokeCount": 9
    }
  },
  "当选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngxuǎn",
      "PrimaryDefinition": "get elected"
    },
    "Keys": {
      "Word": 3404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "奶奶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎinai",
      "PrimaryDefinition": "nanna, address for married woman, granny, gran, grandmother, grandma, respectful form of address for an old woman, paternal grandmother"
    },
    "Keys": {
      "Word": 257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "消失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāoshī",
      "PrimaryDefinition": "disappear, vanish, dissolve"
    },
    "Keys": {
      "Word": 2047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "伙伴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒbàn",
      "PrimaryDefinition": "partner, companion, comrade"
    },
    "Keys": {
      "Word": 2567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "一早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīzǎo",
      "PrimaryDefinition": "early in the morning, at dawn"
    },
    "Keys": {
      "Word": 10353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嬿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "lovely"
    },
    "Keys": {
      "Hanzi": 8050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8050,
      "Radical": "女",
      "RadicalIndex": "38.16",
      "StrokeCount": 19
    }
  },
  "出手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū shǒu",
      "PrimaryDefinition": "to dispose of, to spend (money), to undertake a task"
    },
    "Keys": {
      "Word": 5968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "排名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái míng",
      "PrimaryDefinition": "put names in order"
    },
    "Keys": {
      "Word": 1780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "醢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎi",
      "PrimaryDefinition": "minced pickled meat; mince"
    },
    "Keys": {
      "Hanzi": 6215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6215,
      "Radical": "酉",
      "FrequencyRank": 5363,
      "RadicalIndex": "164.1",
      "StrokeCount": 17
    }
  },
  "鲼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "fish"
    },
    "Keys": {
      "Hanzi": 6276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6276,
      "Radical": "魚",
      "FrequencyRank": 7959,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "礼堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐtáng",
      "PrimaryDefinition": "assembly hall, auditorium, CL:座[zuo4],處|处[chu4]"
    },
    "Keys": {
      "Word": 4817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "救济": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùjì",
      "PrimaryDefinition": "emergency relief, to help the needy with cash or goods"
    },
    "Keys": {
      "Word": 7664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guótǔ",
      "PrimaryDefinition": "territorial dominion, state, country, colony, soil, nation, realm, soli, province, territory, mandate, dominion, land"
    },
    "Keys": {
      "Word": 6934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不可避免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùkěbìmiǎn",
      "PrimaryDefinition": "unavoidably"
    },
    "Keys": {
      "Word": 5703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "面试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànshì",
      "PrimaryDefinition": "to be interviewed (as a candidate), interview"
    },
    "Keys": {
      "Word": 2742
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "但": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "only; but, however, yet, still"
    },
    "Keys": {
      "Hanzi": 774,
      "Word": 600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 2,
      "GeneralStandard": 774,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 95,
      "StrokeCount": 7
    }
  },
  "醨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "dregs of wine"
    },
    "Keys": {
      "Hanzi": 7928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7928,
      "Radical": "酉",
      "FrequencyRank": 7624,
      "RadicalIndex": "164.11",
      "StrokeCount": 17
    }
  },
  "鬲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "cauldron"
    },
    "Keys": {
      "Radical": 193,
      "Hanzi": 4467
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4467,
      "Radical": "鬲",
      "FrequencyRank": 6855,
      "RadicalIndex": "193",
      "StrokeCount": 10
    }
  },
  "叨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāo",
      "PrimaryDefinition": "talkative; quarrelous"
    },
    "Keys": {
      "Hanzi": 255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 255,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2844,
      "StrokeCount": 5
    }
  },
  "山谷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngǔ",
      "PrimaryDefinition": "mountain valley"
    },
    "Keys": {
      "Word": 4988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "傃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7392
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7392,
      "Radical": "人",
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "推测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīcè",
      "PrimaryDefinition": "infer, conjecture, guess"
    },
    "Keys": {
      "Word": 9591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "升学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng xué",
      "PrimaryDefinition": "enter higher school"
    },
    "Keys": {
      "Word": 5009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虔诚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánchéng",
      "PrimaryDefinition": "pious, devout, sincere"
    },
    "Keys": {
      "Word": 8674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "那么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàme",
      "PrimaryDefinition": "so"
    },
    "Keys": {
      "Word": 880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "扃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiōng",
      "PrimaryDefinition": "a door bar placed outside a door"
    },
    "Keys": {
      "Hanzi": 4359
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4359,
      "Radical": "戶",
      "FrequencyRank": 6457,
      "RadicalIndex": "63.5",
      "StrokeCount": 9
    }
  },
  "髟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "long hair"
    },
    "Keys": {
      "Radical": 190
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "即使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíshǐ",
      "PrimaryDefinition": "conj.: even, even if/though"
    },
    "Keys": {
      "Word": 3577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi",
      "PrimaryDefinition": "open; initiate, begin, start"
    },
    "Keys": {
      "Hanzi": 76,
      "Word": 185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "55.1",
      "Grade": 1,
      "GeneralStandard": 76,
      "Radical": "廾",
      "HSK": 1,
      "FrequencyRank": 94,
      "StrokeCount": 4
    }
  },
  "解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiě",
      "PrimaryDefinition": "loosen, unfasten, untie; explain"
    },
    "Keys": {
      "Hanzi": 2981,
      "Word": 4734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "148.6",
      "Grade": 6,
      "GeneralStandard": 2981,
      "Radical": "角",
      "HSK": 3,
      "FrequencyRank": 201,
      "StrokeCount": 13
    }
  },
  "逼迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīpò",
      "PrimaryDefinition": "to force, to compel, to coerce"
    },
    "Keys": {
      "Word": 5578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "rue, herb used to keep insects away; to cut grass or weeds; art, talent, ability, craft, technique, performance, acting, trick, stunt"
    },
    "Keys": {
      "Hanzi": 3661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3661,
      "Radical": "艸",
      "FrequencyRank": 3099,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "霹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "thunder, crashing thunder"
    },
    "Keys": {
      "Hanzi": 3491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3491,
      "Radical": "雨",
      "FrequencyRank": 3438,
      "RadicalIndex": "173.13",
      "StrokeCount": 21
    }
  },
  "晚报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnbào",
      "PrimaryDefinition": "evening newspaper, (in a newspaper's name) Evening News"
    },
    "Keys": {
      "Word": 1049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "容量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngliàng",
      "PrimaryDefinition": "volume, measure of capacity, can, capability, capacity (of container), capacity, bulk, volumetric, canful, content"
    },
    "Keys": {
      "Word": 8882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "訄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6873,
      "Radical": "言",
      "FrequencyRank": 7110,
      "RadicalIndex": "149.2",
      "StrokeCount": 9
    }
  },
  "稃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5203
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5203,
      "Radical": "禾",
      "FrequencyRank": 7240,
      "RadicalIndex": "115.7",
      "StrokeCount": 12
    }
  },
  "酆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "name of Zhou period state"
    },
    "Keys": {
      "Hanzi": 6426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6426,
      "Radical": "邑",
      "FrequencyRank": 4964,
      "RadicalIndex": "163.18",
      "StrokeCount": 20
    }
  },
  "罱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "fishing net"
    },
    "Keys": {
      "Hanzi": 5724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5724,
      "Radical": "网",
      "FrequencyRank": 5761,
      "RadicalIndex": "122.9",
      "StrokeCount": 14
    }
  },
  "诵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng",
      "PrimaryDefinition": "recite, chant, repeat"
    },
    "Keys": {
      "Hanzi": 1718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "GeneralStandard": 1718,
      "Radical": "言",
      "HSK": 9,
      "FrequencyRank": 2557,
      "StrokeCount": 9
    }
  },
  "尺度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐdù",
      "PrimaryDefinition": "measure, scale"
    },
    "Keys": {
      "Word": 5907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "态度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàidu",
      "PrimaryDefinition": "stance, treatment, demeanor, dealing, deportment, pose, behavior, manner, demeanour, way, bearing, attitude, air, behaviour, front, mien, conduct, mental attitude, approach, posture, address, position, bedside_manner, movement, gesture, comportment, presence, sort"
    },
    "Keys": {
      "Word": 1010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "唁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "express condolence"
    },
    "Keys": {
      "Hanzi": 1912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1912,
      "Radical": "口",
      "FrequencyRank": 4847,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "独唱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúchàng",
      "PrimaryDefinition": "(in singing) solo, to solo"
    },
    "Keys": {
      "Word": 6357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo",
      "PrimaryDefinition": "paper money, bank notes; copy"
    },
    "Keys": {
      "Hanzi": 1527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "GeneralStandard": 1527,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2647,
      "StrokeCount": 9
    }
  },
  "内需": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèixū",
      "PrimaryDefinition": "domestic demand"
    },
    "Keys": {
      "Word": 8397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "空军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngjūn",
      "PrimaryDefinition": "airforce, air_force, Luftwaffe, air force, squadron"
    },
    "Keys": {
      "Word": 4796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "少年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàonián",
      "PrimaryDefinition": "nipper, covey, early youth (10-16), juvenility, adolescent, youngster, teens, gossoon, boy, boy or girl of early youth, spalpeen, juvenile, callan, callant, shaver, lad, laddie, juvenile person, teenager, stripling, young boy, early youth, younker"
    },
    "Keys": {
      "Word": 966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鳡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8083,
      "Radical": "魚",
      "FrequencyRank": 6233,
      "RadicalIndex": "195.13",
      "StrokeCount": 21
    }
  },
  "法院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎyuàn",
      "PrimaryDefinition": "judicature, (law) court, judicatory, bar, courthouse, law court, court, tribunal, judiciary, banc, court of justice, jug, banco, forensic"
    },
    "Keys": {
      "Word": 2450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "作出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòchū",
      "PrimaryDefinition": "make (decision/etc.)"
    },
    "Keys": {
      "Word": 3217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "忸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔ",
      "PrimaryDefinition": "blush, be bashful, be ashamed"
    },
    "Keys": {
      "Hanzi": 3793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3793,
      "Radical": "心",
      "FrequencyRank": 4813,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "耔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "to hoe up the earth around plants"
    },
    "Keys": {
      "Hanzi": 6756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6756,
      "Radical": "耒",
      "RadicalIndex": "127.3",
      "StrokeCount": 9
    }
  },
  "挽救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnjiù",
      "PrimaryDefinition": "to save, to remedy, to rescue"
    },
    "Keys": {
      "Word": 9656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shú",
      "PrimaryDefinition": "well-cooked; ripe; familiar with"
    },
    "Keys": {
      "Hanzi": 3278,
      "Word": 989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.11",
      "Grade": 2,
      "GeneralStandard": 3278,
      "Radical": "火",
      "HSK": 2,
      "FrequencyRank": 1035,
      "StrokeCount": 15
    }
  },
  "睽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "staring"
    },
    "Keys": {
      "Hanzi": 5694
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5694,
      "Radical": "目",
      "FrequencyRank": 3958,
      "RadicalIndex": "109.9",
      "StrokeCount": 14
    }
  },
  "投降": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóuxiáng",
      "PrimaryDefinition": "to surrender, surrender"
    },
    "Keys": {
      "Word": 9566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "fish scales"
    },
    "Keys": {
      "Hanzi": 3483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3483,
      "Radical": "魚",
      "FrequencyRank": 2763,
      "RadicalIndex": "195.12",
      "StrokeCount": 20
    }
  },
  "星期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngqī",
      "PrimaryDefinition": "Sunday, week"
    },
    "Keys": {
      "Word": 410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "趺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "sit cross-legged; back of the foo"
    },
    "Keys": {
      "Hanzi": 4828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4828,
      "Radical": "足",
      "FrequencyRank": 4669,
      "RadicalIndex": "157.4",
      "StrokeCount": 11
    }
  },
  "克隆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèlóng",
      "PrimaryDefinition": "clone (loanword)"
    },
    "Keys": {
      "Word": 7808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒu",
      "PrimaryDefinition": "broom, broomstick"
    },
    "Keys": {
      "Hanzi": 1306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1306,
      "Radical": "巾",
      "FrequencyRank": 3630,
      "RadicalIndex": "50.5",
      "StrokeCount": 8
    }
  },
  "兼任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānrèn",
      "PrimaryDefinition": "hold a concurrent post, part-time, hold concurrent post"
    },
    "Keys": {
      "Word": 7397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庙会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miàohuì",
      "PrimaryDefinition": "temple fair"
    },
    "Keys": {
      "Word": 8294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "corpse"
    },
    "Keys": {
      "Radical": 44,
      "Hanzi": 59
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44",
      "GeneralStandard": 59,
      "Radical": "尸",
      "HSK": 7,
      "FrequencyRank": 1517,
      "StrokeCount": 3
    }
  },
  "优美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuměi",
      "PrimaryDefinition": "graceful, exquisite"
    },
    "Keys": {
      "Word": 3114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "北方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běifāng",
      "PrimaryDefinition": "norland, septentrion, compass north, northern part of country, northernness, northern, magnetic north, northward, north, the North, the northern part of the country"
    },
    "Keys": {
      "Word": 513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "殂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cú",
      "PrimaryDefinition": "to die"
    },
    "Keys": {
      "Hanzi": 4174
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4174,
      "Radical": "歹",
      "FrequencyRank": 6734,
      "RadicalIndex": "78.5",
      "StrokeCount": 9
    }
  },
  "揭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiē",
      "PrimaryDefinition": "raise, lift up; surname"
    },
    "Keys": {
      "Hanzi": 2522,
      "Word": 4732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 6,
      "GeneralStandard": 2522,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1666,
      "StrokeCount": 12
    }
  },
  "客观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèguān",
      "PrimaryDefinition": "objective"
    },
    "Keys": {
      "Word": 1672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "劳累": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láolèi",
      "PrimaryDefinition": "tired, exhausted, worn out, to toil"
    },
    "Keys": {
      "Word": 7939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挑剔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāoti",
      "PrimaryDefinition": "picky, fussy"
    },
    "Keys": {
      "Word": 9495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nòu",
      "PrimaryDefinition": "hoe, rake; weed"
    },
    "Keys": {
      "Hanzi": 6027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6027,
      "Radical": "耒",
      "FrequencyRank": 4899,
      "RadicalIndex": "127.1",
      "StrokeCount": 16
    }
  },
  "不足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzú",
      "PrimaryDefinition": "not be enough, not be worth (doing sth.), cannot, should not"
    },
    "Keys": {
      "Word": 3289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "样子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàngzi",
      "PrimaryDefinition": "tendency, pattern, form, manner, likelihood, way, resemblance, semblance, sample, guise, fashion, wise, model, air, mien, appearance, shape, suggestion, gesture, sort"
    },
    "Keys": {
      "Word": 1126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "光盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngpán",
      "PrimaryDefinition": "compact disc, CD or DVD, CD ROM, CL:片[pian4],張|张[zhang1]"
    },
    "Keys": {
      "Word": 2520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "惟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "but, however, nevertheless; only"
    },
    "Keys": {
      "Hanzi": 2444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2444,
      "Radical": "心",
      "FrequencyRank": 1856,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "钤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "lock, latch; stamp, seal"
    },
    "Keys": {
      "Hanzi": 4243
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4243,
      "Radical": "金",
      "FrequencyRank": 5092,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "鳂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "name of fish"
    },
    "Keys": {
      "Hanzi": 7966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7966,
      "Radical": "魚",
      "FrequencyRank": 8831,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "神经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénjīng",
      "PrimaryDefinition": "nervus, nerve"
    },
    "Keys": {
      "Word": 3885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "缚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "to tie"
    },
    "Keys": {
      "Hanzi": 3044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.1",
      "GeneralStandard": 3044,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 2577,
      "StrokeCount": 13
    }
  },
  "颁布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bānbù",
      "PrimaryDefinition": "to issue, to proclaim, to enact (laws, decrees etc)"
    },
    "Keys": {
      "Word": 5486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窃取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qièqǔ",
      "PrimaryDefinition": "to steal, to seize"
    },
    "Keys": {
      "Word": 8712
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "ragout, cook, braise"
    },
    "Keys": {
      "Hanzi": 4639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4639,
      "Radical": "火",
      "FrequencyRank": 4828,
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "赦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "forgive, remit, pardon"
    },
    "Keys": {
      "Hanzi": 2172
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2172,
      "Radical": "赤",
      "FrequencyRank": 3107,
      "RadicalIndex": "155.4",
      "StrokeCount": 11
    }
  },
  "琎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7099,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "跹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "wander about, walk around; revolve"
    },
    "Keys": {
      "Hanzi": 5457
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5457,
      "Radical": "足",
      "FrequencyRank": 5323,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "五": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "five; surname"
    },
    "Keys": {
      "Hanzi": 88,
      "Word": 379
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7.2",
      "Grade": 1,
      "GeneralStandard": 88,
      "Radical": "二",
      "HSK": 1,
      "FrequencyRank": 279,
      "StrokeCount": 4
    }
  },
  "客流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèliú",
      "PrimaryDefinition": "Passenger flow"
    },
    "Keys": {
      "Word": 7815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有口无心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒukǒu-wúxīn",
      "PrimaryDefinition": "to speak harshly but without any bad intent (idiom)"
    },
    "Keys": {
      "Word": 10475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "果实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒshí",
      "PrimaryDefinition": "fruitery, fruitage, gains, fruits, fruit, fructification"
    },
    "Keys": {
      "Word": 2524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "订购": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìnggòu",
      "PrimaryDefinition": "to place an order, to subscribe"
    },
    "Keys": {
      "Word": 6306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "castor-oil plant, Ricinus commumis"
    },
    "Keys": {
      "Hanzi": 5377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5377,
      "Radical": "艸",
      "FrequencyRank": 4955,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "呲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cī",
      "PrimaryDefinition": "give a talking-to"
    },
    "Keys": {
      "Hanzi": 4207
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4207,
      "Radical": "口",
      "FrequencyRank": 5364,
      "RadicalIndex": "30.5",
      "StrokeCount": 9
    }
  },
  "下课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià kè",
      "PrimaryDefinition": "finish class, get out of class"
    },
    "Keys": {
      "Word": 391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "豚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": "small pig, suckling pig; suffle"
    },
    "Keys": {
      "Hanzi": 2358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2358,
      "Radical": "豕",
      "FrequencyRank": 3307,
      "RadicalIndex": "152.4",
      "StrokeCount": 11
    }
  },
  "优化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuhuà",
      "PrimaryDefinition": "optimization, to optimize, to make superior"
    },
    "Keys": {
      "Word": 10448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "僎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "collect"
    },
    "Keys": {
      "Hanzi": 7696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7696,
      "Radical": "人",
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "异想天开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìxiǎng-tiānkāi",
      "PrimaryDefinition": "to imagine the wildest thing, to indulge in fantasy"
    },
    "Keys": {
      "Word": 10362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duó",
      "PrimaryDefinition": "stroll, pace, walk slowly"
    },
    "Keys": {
      "Hanzi": 3343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3343,
      "Radical": "足",
      "FrequencyRank": 3009,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "俨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "grave, respectful, majestic"
    },
    "Keys": {
      "Hanzi": 4257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4257,
      "Radical": "人",
      "FrequencyRank": 3559,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "奖项": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngxiàng",
      "PrimaryDefinition": "award, prize, CL:項|项[xiang4]"
    },
    "Keys": {
      "Word": 7449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "scatter, spread; praise"
    },
    "Keys": {
      "Hanzi": 368,
      "Word": 10218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "Grade": 7,
      "GeneralStandard": 368,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1084,
      "StrokeCount": 6
    }
  },
  "当场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngchǎng",
      "PrimaryDefinition": "on the spot, then and there"
    },
    "Keys": {
      "Word": 3399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "丝毫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīháo",
      "PrimaryDefinition": "scintilla, shred, shadow, vestige, det.: in the slightest amount, degree"
    },
    "Keys": {
      "Word": 9307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "fall, drop; net income, surplus"
    },
    "Keys": {
      "Hanzi": 2557,
      "Word": 3673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "Grade": 5,
      "GeneralStandard": 2557,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 496,
      "StrokeCount": 12
    }
  },
  "导演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎoyǎn",
      "PrimaryDefinition": "director"
    },
    "Keys": {
      "Word": 1399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "呒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "unclear; an expletive"
    },
    "Keys": {
      "Hanzi": 3699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3699,
      "Radical": "口",
      "FrequencyRank": 5781,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "发电机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fādiànjī",
      "PrimaryDefinition": "electricity generator, dynamo"
    },
    "Keys": {
      "Word": 6433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāmíng",
      "PrimaryDefinition": "invention"
    },
    "Keys": {
      "Word": 1432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "难看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánkàn",
      "PrimaryDefinition": "ugly, unsightly"
    },
    "Keys": {
      "Word": 886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "猢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "a kind of monkey found in W.China"
    },
    "Keys": {
      "Hanzi": 5239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5239,
      "Radical": "犬",
      "FrequencyRank": 5783,
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "恋爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liànài",
      "PrimaryDefinition": "love, romantic attachment"
    },
    "Keys": {
      "Word": 3694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "柑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "tangerine, loose-skinned orange"
    },
    "Keys": {
      "Hanzi": 1426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1426,
      "Radical": "木",
      "FrequencyRank": 4276,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "唠叨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láodao",
      "PrimaryDefinition": "to prattle, to chatter away, to nag, garrulous, nagging"
    },
    "Keys": {
      "Word": 7943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěxī",
      "PrimaryDefinition": "it's a pity, unfortunately, it's too bad"
    },
    "Keys": {
      "Word": 3658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "收养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuyǎng",
      "PrimaryDefinition": "to take in and care for (an elderly person, a dog etc), to adopt (a child), adoption"
    },
    "Keys": {
      "Word": 5031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蜂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "bee, wasp, hornet"
    },
    "Keys": {
      "Hanzi": 2925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.7",
      "GeneralStandard": 2925,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 1912,
      "StrokeCount": 13
    }
  },
  "蕲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "variety of artemisia seek"
    },
    "Keys": {
      "Hanzi": 5881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5881,
      "Radical": "艸",
      "FrequencyRank": 5333,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "芩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "salt marsh plant"
    },
    "Keys": {
      "Hanzi": 3671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3671,
      "Radical": "艸",
      "FrequencyRank": 4642,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "她": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "she, her"
    },
    "Keys": {
      "Hanzi": 582,
      "Word": 352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "Grade": 1,
      "GeneralStandard": 582,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 91,
      "StrokeCount": 6
    }
  },
  "衒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "brag; show off, promote oneself"
    },
    "Keys": {
      "Hanzi": 7202
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7202,
      "Radical": "行",
      "FrequencyRank": 7270,
      "RadicalIndex": "144.5",
      "StrokeCount": 11
    }
  },
  "缙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "red silk"
    },
    "Keys": {
      "Hanzi": 5610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5610,
      "Radical": "糸",
      "FrequencyRank": 4842,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "家电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiādiàn",
      "PrimaryDefinition": "electrical household appliance, electrical home appliance, household electrical appliances"
    },
    "Keys": {
      "Word": 4703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "前任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánrèn",
      "PrimaryDefinition": "predecessor"
    },
    "Keys": {
      "Word": 8666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "加深": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāshēn",
      "PrimaryDefinition": "to deepen"
    },
    "Keys": {
      "Word": 7346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳄鱼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èyú",
      "PrimaryDefinition": "alligator, crocodile"
    },
    "Keys": {
      "Word": 6418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "活泼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huópo",
      "PrimaryDefinition": "lively, vivacious, vivid"
    },
    "Keys": {
      "Word": 3567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "渡过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùguò",
      "PrimaryDefinition": "pass, spend (time/etc.)"
    },
    "Keys": {
      "Word": 6367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鼱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8081
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8081,
      "Radical": "鼠",
      "FrequencyRank": 7413,
      "RadicalIndex": "208.8",
      "StrokeCount": 21
    }
  },
  "鳃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāi",
      "PrimaryDefinition": "fish gills"
    },
    "Keys": {
      "Hanzi": 6279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6279,
      "Radical": "魚",
      "FrequencyRank": 3816,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "洓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6882,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "扫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎo",
      "PrimaryDefinition": "sweep, clear away; exterminate"
    },
    "Keys": {
      "Hanzi": 365,
      "Word": 2830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "Grade": 4,
      "GeneralStandard": 365,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1435,
      "StrokeCount": 6
    }
  },
  "引导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐndǎo",
      "PrimaryDefinition": "guide, lead"
    },
    "Keys": {
      "Word": 3102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "资源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīyuán",
      "PrimaryDefinition": "resource, natural resources, mine, resources"
    },
    "Keys": {
      "Word": 3207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "妖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "strange, weird, supernatural"
    },
    "Keys": {
      "Hanzi": 920
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.4",
      "GeneralStandard": 920,
      "Radical": "女",
      "HSK": 8,
      "FrequencyRank": 1869,
      "StrokeCount": 7
    }
  },
  "讨厌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎo yàn",
      "PrimaryDefinition": "disincline, to loathe, be disgusted with, to have a dislike of, scunner, to dislike, despise, keck, to take an aversion to, loathe, loathing, disrelish, hate, detest, dislike, disgusting, disfavor"
    },
    "Keys": {
      "Word": 3966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "经度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngdù",
      "PrimaryDefinition": "longitude"
    },
    "Keys": {
      "Word": 7592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàduō",
      "PrimaryDefinition": "for the most part, many, most, the greater part, mostly"
    },
    "Keys": {
      "Word": 2367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "只能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐnéng",
      "PrimaryDefinition": "can only, obliged to do sth, to have no other choice"
    },
    "Keys": {
      "Word": 1217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "死心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǐ xīn",
      "PrimaryDefinition": "to give up, to admit failure, to drop the matter, to reconcile oneself to loss, to have no more illusions about"
    },
    "Keys": {
      "Word": 9321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": "square"
    },
    "Keys": {
      "Hanzi": 163,
      "Radical": 70,
      "Word": 2458
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "70",
      "Grade": 4,
      "GeneralStandard": 163,
      "Radical": "方",
      "HSK": 1,
      "FrequencyRank": 60,
      "StrokeCount": 4
    }
  },
  "醉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuì",
      "PrimaryDefinition": "intoxicated, drunk; addicted to"
    },
    "Keys": {
      "Hanzi": 3224,
      "Word": 4284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.8",
      "Grade": 5,
      "GeneralStandard": 3224,
      "Radical": "酉",
      "HSK": 5,
      "FrequencyRank": 1783,
      "StrokeCount": 15
    }
  },
  "舵手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duòshǒu",
      "PrimaryDefinition": "helmsman"
    },
    "Keys": {
      "Word": 6404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "grasshopper; (edible) locust; cicada"
    },
    "Keys": {
      "Hanzi": 4838
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4838,
      "Radical": "虫",
      "FrequencyRank": 4410,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "大部分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàbùfen",
      "PrimaryDefinition": "det.: most of, the majority of"
    },
    "Keys": {
      "Word": 585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "卡子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎzi",
      "PrimaryDefinition": "clip, hair fastener, checkpoint"
    },
    "Keys": {
      "Word": 8640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biě",
      "PrimaryDefinition": "shrivelled up, dried up; vexed"
    },
    "Keys": {
      "Hanzi": 3281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3281,
      "Radical": "疒",
      "FrequencyRank": 3812,
      "RadicalIndex": "104.1",
      "StrokeCount": 15
    }
  },
  "邻国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línguó",
      "PrimaryDefinition": "bordering country, neighbor country, neighboring countries, surrounding countries"
    },
    "Keys": {
      "Word": 8058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "jug, pitcher, vase, jar, bottle"
    },
    "Keys": {
      "Hanzi": 2051,
      "Word": 915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "98.6",
      "Grade": 2,
      "GeneralStandard": 2051,
      "Radical": "瓦",
      "HSK": 2,
      "FrequencyRank": 1703,
      "StrokeCount": 10
    }
  },
  "永恒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒnghéng",
      "PrimaryDefinition": "eternalize, perpetual, eternize, eternal"
    },
    "Keys": {
      "Word": 10433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": "noise of rage, cry out in anger"
    },
    "Keys": {
      "Hanzi": 1103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1103,
      "Radical": "口",
      "FrequencyRank": 3325,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "争光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng guāng",
      "PrimaryDefinition": "to win an honor, to strive to win a prize"
    },
    "Keys": {
      "Word": 10718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "何苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hékǔ",
      "PrimaryDefinition": "why bother?, is it worth the trouble?"
    },
    "Keys": {
      "Word": 7034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "repay, recompense; restitution"
    },
    "Keys": {
      "Hanzi": 2327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "GeneralStandard": 2327,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1329,
      "StrokeCount": 11
    }
  },
  "栻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 6957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6957,
      "Radical": "木",
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "酷似": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùsì",
      "PrimaryDefinition": "to strikingly resemble"
    },
    "Keys": {
      "Word": 7865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guō",
      "PrimaryDefinition": "small green frog; cicada"
    },
    "Keys": {
      "Hanzi": 5707
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5707,
      "Radical": "虫",
      "FrequencyRank": 4858,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "准时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔnshí",
      "PrimaryDefinition": "on time, punctual, on schedule"
    },
    "Keys": {
      "Word": 3203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瀚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "vast, wide, extensive"
    },
    "Keys": {
      "Hanzi": 6412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6412,
      "Radical": "水",
      "FrequencyRank": 3666,
      "RadicalIndex": "85.16",
      "StrokeCount": 19
    }
  },
  "嗳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "interjection; exclamation"
    },
    "Keys": {
      "Hanzi": 5470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5470,
      "Radical": "口",
      "FrequencyRank": 3837,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "收回": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōu huí",
      "PrimaryDefinition": "take back, call in, recall, withdraw, countermand"
    },
    "Keys": {
      "Word": 2873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瘫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tān",
      "PrimaryDefinition": "paralysis, palsy, numbness"
    },
    "Keys": {
      "Hanzi": 3283,
      "Word": 9400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.1",
      "Grade": 7,
      "GeneralStandard": 3283,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 2823,
      "StrokeCount": 15
    }
  },
  "悲伤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēishāng",
      "PrimaryDefinition": "sad, sorrowful"
    },
    "Keys": {
      "Word": 3238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "风采": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngcǎi",
      "PrimaryDefinition": "carriage, graceful bearing, colour, mien, elegant demeanour, literary grace, elegant demeanor"
    },
    "Keys": {
      "Word": 6565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特邀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèyāo",
      "PrimaryDefinition": "special invitation"
    },
    "Keys": {
      "Word": 9453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "to receive blessings in a sincere spirit"
    },
    "Keys": {
      "Hanzi": 7739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7739,
      "Radical": "示",
      "FrequencyRank": 6845,
      "RadicalIndex": "113.1",
      "StrokeCount": 14
    }
  },
  "谜团": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mítuán",
      "PrimaryDefinition": "riddle, enigma, unpredictable situation, elusive matters"
    },
    "Keys": {
      "Word": 8263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慈祥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cíxiáng",
      "PrimaryDefinition": "kindly, benevolent (often of older person)"
    },
    "Keys": {
      "Word": 6042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "前赴后继": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánfù-hòujì",
      "PrimaryDefinition": "to advance dauntlessly in wave upon wave (idiom)"
    },
    "Keys": {
      "Word": 8662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吃饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī fàn",
      "PrimaryDefinition": "feed, dine, make a living, keep alive, have a meal, eat"
    },
    "Keys": {
      "Word": 47
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "减免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnmiǎn",
      "PrimaryDefinition": "to reduce or waive (taxes, punishment, rent, tuition etc)"
    },
    "Keys": {
      "Word": 7404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǔ",
      "PrimaryDefinition": "willow tree; pleasure"
    },
    "Keys": {
      "Hanzi": 1434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1434,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 1557,
      "StrokeCount": 9
    }
  },
  "郤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "crack, opening; surname"
    },
    "Keys": {
      "Hanzi": 4275
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4275,
      "Radical": "邑",
      "FrequencyRank": 6656,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "珕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6939,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "钳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "pincers, pliers, tongs; to compress"
    },
    "Keys": {
      "Hanzi": 1929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "GeneralStandard": 1929,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 3344,
      "StrokeCount": 10
    }
  },
  "喜洋洋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐyángyáng",
      "PrimaryDefinition": "radiant with joy"
    },
    "Keys": {
      "Word": 9842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剥夺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōduó",
      "PrimaryDefinition": "to deprive, to expropriate, to strip (sb of his property)"
    },
    "Keys": {
      "Word": 5663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒng",
      "PrimaryDefinition": "urgent"
    },
    "Keys": {
      "Hanzi": 4909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4909,
      "Radical": "人",
      "FrequencyRank": 5862,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "薤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "allium bakeri; shallots, scallion"
    },
    "Keys": {
      "Hanzi": 6049
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6049,
      "Radical": "艸",
      "FrequencyRank": 6805,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "容光焕发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngguāng-huànfā",
      "PrimaryDefinition": "face glowing (idiom), looking radiant, all smiles"
    },
    "Keys": {
      "Word": 8880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǔ",
      "PrimaryDefinition": "you"
    },
    "Keys": {
      "Hanzi": 541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 541,
      "Radical": "水",
      "FrequencyRank": 2547,
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "再生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàishēng",
      "PrimaryDefinition": "be reborn, regenerate"
    },
    "Keys": {
      "Word": 5304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "所在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒzài",
      "PrimaryDefinition": "ubiety, location, place, locality"
    },
    "Keys": {
      "Word": 3953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "色彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sècǎi",
      "PrimaryDefinition": "hue, tincture, palette, tint, blazonry, leavening, tinct, grain, color, shade"
    },
    "Keys": {
      "Word": 2833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鞳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7985,
      "Radical": "革",
      "RadicalIndex": "177.1",
      "StrokeCount": 18
    }
  },
  "带有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàiyǒu",
      "PrimaryDefinition": "have, partake of"
    },
    "Keys": {
      "Word": 3394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "受到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòudào",
      "PrimaryDefinition": "experience, receive, come in for, suffer, undergo, come_in_for, be given, come_to, be subjected to"
    },
    "Keys": {
      "Word": 987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "计较": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìjiào",
      "PrimaryDefinition": "argue, arguement, wrangle, care, dispute, think over, haggle over, haggling over, mind, fuss about, discuss in minute detail"
    },
    "Keys": {
      "Word": 7318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "微不足道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēibùzúdào",
      "PrimaryDefinition": "negligible, insignificant"
    },
    "Keys": {
      "Word": 9690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7930,
      "Radical": "石",
      "FrequencyRank": 8596,
      "RadicalIndex": "112.12",
      "StrokeCount": 17
    }
  },
  "逄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páng",
      "PrimaryDefinition": "a surname"
    },
    "Keys": {
      "Hanzi": 4297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4297,
      "Radical": "辵",
      "FrequencyRank": 5983,
      "RadicalIndex": "162.6",
      "StrokeCount": 9
    }
  },
  "保卫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎowèi",
      "PrimaryDefinition": "to defend, to safeguard"
    },
    "Keys": {
      "Word": 3233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "政策": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngcè",
      "PrimaryDefinition": "statism, tack, line, policy, statecraft"
    },
    "Keys": {
      "Word": 5342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "意志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìzhì",
      "PrimaryDefinition": "volition, will, zap, determination, pleasure, heart, willpower"
    },
    "Keys": {
      "Word": 4119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "以前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐqián",
      "PrimaryDefinition": "langsyne, ago, in the past, formerly, prevenient, before, previously"
    },
    "Keys": {
      "Word": 1141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "上述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngshù",
      "PrimaryDefinition": "above-mentioned"
    },
    "Keys": {
      "Word": 8978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngduì",
      "PrimaryDefinition": "be opposite, be face to face"
    },
    "Keys": {
      "Word": 9922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "数字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùzì",
      "PrimaryDefinition": "numeral, numerical, digit, amount, number, numeric, figure, digital, quantity"
    },
    "Keys": {
      "Word": 991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "侘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chà",
      "PrimaryDefinition": "disappointed, forlorn"
    },
    "Keys": {
      "Hanzi": 6703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6703,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "怫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "sorry, anxious; depressed"
    },
    "Keys": {
      "Hanzi": 4033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4033,
      "Radical": "心",
      "FrequencyRank": 5716,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "着火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zháo huǒ",
      "PrimaryDefinition": "to ignite, to burn"
    },
    "Keys": {
      "Word": 3156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "南极": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánjí",
      "PrimaryDefinition": "south pole"
    },
    "Keys": {
      "Word": 3740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "晁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháo",
      "PrimaryDefinition": "morning, dawn; surname"
    },
    "Keys": {
      "Hanzi": 4501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4501,
      "Radical": "日",
      "FrequencyRank": 4393,
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "瓀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruǎn",
      "PrimaryDefinition": "gem"
    },
    "Keys": {
      "Hanzi": 7981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7981,
      "Radical": "玉",
      "RadicalIndex": "96.14",
      "StrokeCount": 18
    }
  },
  "小康": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎokāng",
      "PrimaryDefinition": "Xiaokang, a Confucian near-ideal state of society, second only to Datong 大同[Da4 tong2], moderately affluent, well-off, a period of peace and prosperity"
    },
    "Keys": {
      "Word": 9967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仔细": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐxì",
      "PrimaryDefinition": "careful, be careful, look out"
    },
    "Keys": {
      "Word": 4272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "稠密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóumì",
      "PrimaryDefinition": "dense"
    },
    "Keys": {
      "Word": 5937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敏感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐngǎn",
      "PrimaryDefinition": "subtle, susceptible, vulnerable, be sensitive, sensitive"
    },
    "Keys": {
      "Word": 3724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "芊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "exuberant and vigorous foliage"
    },
    "Keys": {
      "Hanzi": 3578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3578,
      "Radical": "艸",
      "FrequencyRank": 5642,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "酬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "toast; reward, recompense"
    },
    "Keys": {
      "Hanzi": 2874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.6",
      "GeneralStandard": 2874,
      "Radical": "酉",
      "HSK": 7,
      "FrequencyRank": 2005,
      "StrokeCount": 13
    }
  },
  "哕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "belch; vomit"
    },
    "Keys": {
      "Hanzi": 4215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4215,
      "Radical": "口",
      "FrequencyRank": 7236,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "修订": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūdìng",
      "PrimaryDefinition": "revise"
    },
    "Keys": {
      "Word": 10080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "商店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngdiàn",
      "PrimaryDefinition": "concern, hospital, business, store, shoppe, shop"
    },
    "Keys": {
      "Word": 312
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "寿命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòumìng",
      "PrimaryDefinition": "life"
    },
    "Keys": {
      "Word": 9193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "栾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "name of tree; a part of cornice"
    },
    "Keys": {
      "Hanzi": 4611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4611,
      "Radical": "木",
      "FrequencyRank": 4377,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "碱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "alkaline, alkali, lye, salt"
    },
    "Keys": {
      "Hanzi": 3091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3091,
      "Radical": "石",
      "FrequencyRank": 2872,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "螺丝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luósī",
      "PrimaryDefinition": "screw"
    },
    "Keys": {
      "Word": 8166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "stringed musical instrument; kite"
    },
    "Keys": {
      "Hanzi": 2689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "GeneralStandard": 2689,
      "Radical": "竹",
      "HSK": 8,
      "FrequencyRank": 3436,
      "StrokeCount": 12
    }
  },
  "发射": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāshè",
      "PrimaryDefinition": "launch, project, discharge, shoot, transmit, emit"
    },
    "Keys": {
      "Word": 3444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "铏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": "sacrificial cauldron"
    },
    "Keys": {
      "Hanzi": 7176
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7176,
      "Radical": "金",
      "FrequencyRank": 7103,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "谮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zèn",
      "PrimaryDefinition": "to slander"
    },
    "Keys": {
      "Hanzi": 5818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5818,
      "Radical": "言",
      "FrequencyRank": 6361,
      "RadicalIndex": "149.12",
      "StrokeCount": 14
    }
  },
  "失传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīchuán",
      "PrimaryDefinition": "(of skills etc) to die out, lost, extinct"
    },
    "Keys": {
      "Word": 9080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "特地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèdì",
      "PrimaryDefinition": "purposely, specially, on purpose"
    },
    "Keys": {
      "Word": 5072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "牵制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānzhì",
      "PrimaryDefinition": "to control, to curb, to restrict, to impede, to pin down (enemy troops)"
    },
    "Keys": {
      "Word": 8657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "hoe; mattock"
    },
    "Keys": {
      "Hanzi": 7685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7685,
      "Radical": "金",
      "FrequencyRank": 8705,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "喜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "like, love, enjoy; joyful thing"
    },
    "Keys": {
      "Hanzi": 2523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "GeneralStandard": 2523,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 668,
      "StrokeCount": 12
    }
  },
  "感人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnrén",
      "PrimaryDefinition": "touching, moving"
    },
    "Keys": {
      "Word": 4588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "出场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū chǎng",
      "PrimaryDefinition": "to appear (on stage, in a show, in a photo etc), to play (for a team), to enter (arena or stage), to send sb out (e.g. off the field for a foul)"
    },
    "Keys": {
      "Word": 4424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "创伤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāngshāng",
      "PrimaryDefinition": "wound, injury, trauma"
    },
    "Keys": {
      "Word": 6017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "从未": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngwèi",
      "PrimaryDefinition": "ne'er, never"
    },
    "Keys": {
      "Word": 6062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "共鸣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngmíng",
      "PrimaryDefinition": "sympathy, fellow feeling, vibration, consonance, resonance, sympathetic response"
    },
    "Keys": {
      "Word": 6803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "给予": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐyǔ",
      "PrimaryDefinition": "bring, feed, offer, afford, hold_out, impart, adduce, lend, grant, contribute, given, concede, give to, give, bestow, add, put, render, deal, ladle, administer, assign, give_in, allow"
    },
    "Keys": {
      "Word": 4706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "生理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnglǐ",
      "PrimaryDefinition": "physiological, peristalsis, autacoid, endocrinology, chyle, diastole, chyme, physiology, phagocyte"
    },
    "Keys": {
      "Word": 9052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3696,
      "Radical": "邑",
      "FrequencyRank": 4431,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "瑜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "flawless gem or jewel"
    },
    "Keys": {
      "Hanzi": 5342
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5342,
      "Radical": "玉",
      "FrequencyRank": 2875,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "菩萨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "púsà",
      "PrimaryDefinition": "Buddhist idol, a term applied to a kindhearted person, kindhearted person, Bodhisattva, joss, Buddha, Boddhisatva"
    },
    "Keys": {
      "Word": 8581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "comfort, console, calm"
    },
    "Keys": {
      "Hanzi": 3310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.11",
      "GeneralStandard": 3310,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1632,
      "StrokeCount": 15
    }
  },
  "颚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "jaw"
    },
    "Keys": {
      "Hanzi": 5931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5931,
      "Radical": "頁",
      "FrequencyRank": 3996,
      "RadicalIndex": "181.9",
      "StrokeCount": 15
    }
  },
  "网页": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngyè",
      "PrimaryDefinition": "web page"
    },
    "Keys": {
      "Word": 5133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "毯子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎnzi",
      "PrimaryDefinition": "blanket, CL:條|条[tiao2],張|张[zhang1],床[chuang2],面[mian4]"
    },
    "Keys": {
      "Word": 9415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "截止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiézhǐ",
      "PrimaryDefinition": "end, cut off, expire, close"
    },
    "Keys": {
      "Word": 4733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "咩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miē",
      "PrimaryDefinition": "the bleating of sheep; (Cant.) an interrogative particle"
    },
    "Keys": {
      "Hanzi": 4225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4225,
      "Radical": "口",
      "FrequencyRank": 4752,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "招聘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāopìn",
      "PrimaryDefinition": "to invite applications for a job, to recruit"
    },
    "Keys": {
      "Word": 5334
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "赞叹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàntàn",
      "PrimaryDefinition": "to exclaim in admiration"
    },
    "Keys": {
      "Word": 10598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cè",
      "PrimaryDefinition": "measure, estimate, conjecture"
    },
    "Keys": {
      "Hanzi": 1670,
      "Word": 2295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 4,
      "GeneralStandard": 1670,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 861,
      "StrokeCount": 9
    }
  },
  "蒇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "finish, complete; solve; complete"
    },
    "Keys": {
      "Hanzi": 7307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7307,
      "Radical": "艸",
      "FrequencyRank": 7435,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "驭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "drive, ride; manage, control"
    },
    "Keys": {
      "Hanzi": 3562
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.2",
      "GeneralStandard": 3562,
      "Radical": "馬",
      "HSK": 8,
      "FrequencyRank": 3424,
      "StrokeCount": 5
    }
  },
  "停放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngfàng",
      "PrimaryDefinition": "park, place"
    },
    "Keys": {
      "Word": 9518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "wrinkles, creases, folds"
    },
    "Keys": {
      "Hanzi": 2011,
      "Word": 10843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "107.5",
      "Grade": 7,
      "GeneralStandard": 2011,
      "Radical": "皮",
      "HSK": 8,
      "FrequencyRank": 1954,
      "StrokeCount": 10
    }
  },
  "披": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "wear; split; crack"
    },
    "Keys": {
      "Hanzi": 990,
      "Word": 3767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 5,
      "GeneralStandard": 990,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 2032,
      "StrokeCount": 8
    }
  },
  "性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "nature, character, sex"
    },
    "Keys": {
      "Hanzi": 1268,
      "Word": 2063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "Grade": 3,
      "GeneralStandard": 1268,
      "Radical": "心",
      "HSK": 3,
      "FrequencyRank": 122,
      "StrokeCount": 8
    }
  },
  "分明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnmíng",
      "PrimaryDefinition": "clear, distinct, evidently, clearly"
    },
    "Keys": {
      "Word": 6539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扣留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòuliú",
      "PrimaryDefinition": "to detain, to arrest, to hold, to confiscate"
    },
    "Keys": {
      "Word": 7853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赢家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngjia",
      "PrimaryDefinition": "winner"
    },
    "Keys": {
      "Word": 10419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐng",
      "PrimaryDefinition": "rice tassel; sharp point; clever"
    },
    "Keys": {
      "Hanzi": 2979
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.7",
      "GeneralStandard": 2979,
      "Radical": "頁",
      "HSK": 8,
      "FrequencyRank": 2514,
      "StrokeCount": 13
    }
  },
  "水槽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐcáo",
      "PrimaryDefinition": "sink"
    },
    "Keys": {
      "Word": 9260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6838
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6838,
      "Radical": "山",
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "蒌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "artemisia stelleriana"
    },
    "Keys": {
      "Hanzi": 5090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5090,
      "Radical": "艸",
      "FrequencyRank": 5887,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "淡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "weak, watery; insipid, tasteless"
    },
    "Keys": {
      "Hanzi": 2430,
      "Word": 2393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 4,
      "GeneralStandard": 2430,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1293,
      "StrokeCount": 11
    }
  },
  "鲱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "herring"
    },
    "Keys": {
      "Hanzi": 6140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6140,
      "Radical": "魚",
      "FrequencyRank": 5489,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "碚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "suburb"
    },
    "Keys": {
      "Hanzi": 5421
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5421,
      "Radical": "石",
      "FrequencyRank": 5113,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "信息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnxī",
      "PrimaryDefinition": "information, news, message"
    },
    "Keys": {
      "Word": 1110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "实施": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíshī",
      "PrimaryDefinition": "put into effect, implement"
    },
    "Keys": {
      "Word": 2864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "翳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "shade, screen; to hide, screen"
    },
    "Keys": {
      "Hanzi": 6216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6216,
      "Radical": "羽",
      "FrequencyRank": 4968,
      "RadicalIndex": "124.11",
      "StrokeCount": 17
    }
  },
  "家具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāju",
      "PrimaryDefinition": "household goods, movable, furnishings, furniture, home furnishings, household stuff, implement, household furnishings, fitment"
    },
    "Keys": {
      "Word": 1586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "移交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíjiāo",
      "PrimaryDefinition": "to transfer, to hand over"
    },
    "Keys": {
      "Word": 10299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轻而易举": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngéryìjǔ",
      "PrimaryDefinition": "easy, with no difficulty"
    },
    "Keys": {
      "Word": 8734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "佖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6604,
      "Radical": "人",
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "眺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiào",
      "PrimaryDefinition": "look at, gaze at, scan, survey"
    },
    "Keys": {
      "Hanzi": 4814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4814,
      "Radical": "目",
      "FrequencyRank": 3351,
      "RadicalIndex": "109.6",
      "StrokeCount": 11
    }
  },
  "依然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīrán",
      "PrimaryDefinition": "notwithstanding, natheless, withal, even so, yet, nonetheless, nevertheless, all the same, however, still, as before"
    },
    "Keys": {
      "Word": 3088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "行驶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngshǐ",
      "PrimaryDefinition": "go (of vehicles/boats/etc.)"
    },
    "Keys": {
      "Word": 4066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "强壮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángzhuàng",
      "PrimaryDefinition": "strong, sturdy, robust"
    },
    "Keys": {
      "Word": 4934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "谞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "knowledge; discrimination; treachery"
    },
    "Keys": {
      "Hanzi": 7256
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7256,
      "Radical": "言",
      "FrequencyRank": 6758,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "赑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "strong"
    },
    "Keys": {
      "Hanzi": 7370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7370,
      "Radical": "貝",
      "FrequencyRank": 9283,
      "RadicalIndex": "154.14",
      "StrokeCount": 12
    }
  },
  "任职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn zhí",
      "PrimaryDefinition": "assignment, be in office, serve, hold a position, hold a post"
    },
    "Keys": {
      "Word": 8868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāo",
      "PrimaryDefinition": "knife"
    },
    "Keys": {
      "Hanzi": 18,
      "Radical": 18,
      "Word": 1398
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18",
      "Grade": 3,
      "GeneralStandard": 18,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 1067,
      "StrokeCount": 2
    }
  },
  "忘掉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàng diào",
      "PrimaryDefinition": "to forget"
    },
    "Keys": {
      "Word": 9677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7881,
      "Radical": "行",
      "RadicalIndex": "144.1",
      "StrokeCount": 16
    }
  },
  "十字路口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shízì lùkǒu",
      "PrimaryDefinition": "crossroads, intersection"
    },
    "Keys": {
      "Word": 9102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分配": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnpèi",
      "PrimaryDefinition": "distribute, allot, assign"
    },
    "Keys": {
      "Word": 1455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "配合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèihe",
      "PrimaryDefinition": "coordinate, cooperate"
    },
    "Keys": {
      "Word": 1784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "海边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎibiān",
      "PrimaryDefinition": "coast, seaside, seashore, beach"
    },
    "Keys": {
      "Word": 706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "卅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sà",
      "PrimaryDefinition": "thirty, thirtieth"
    },
    "Keys": {
      "Hanzi": 3512
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3512,
      "Radical": "十",
      "FrequencyRank": 4090,
      "RadicalIndex": "24.2",
      "StrokeCount": 4
    }
  },
  "戭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "spear"
    },
    "Keys": {
      "Hanzi": 7825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7825,
      "Radical": "戈",
      "RadicalIndex": "62.11",
      "StrokeCount": 15
    }
  },
  "偎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "cling to, cuddle, embrace, fondle"
    },
    "Keys": {
      "Hanzi": 2329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2329,
      "Radical": "人",
      "FrequencyRank": 3656,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "编辑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānjí",
      "PrimaryDefinition": "edit, compile"
    },
    "Keys": {
      "Word": 3253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蛙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wā",
      "PrimaryDefinition": "frog"
    },
    "Keys": {
      "Hanzi": 2634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.6",
      "GeneralStandard": 2634,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2904,
      "StrokeCount": 12
    }
  },
  "推广": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīguǎng",
      "PrimaryDefinition": "popularize, spread, extend"
    },
    "Keys": {
      "Word": 1989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "对手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìshǒu",
      "PrimaryDefinition": "mate, competitor, opposite, antagonist, comparative, contender, adversary, opponent, opposition, counterpart, equal, counterworker, rival, match"
    },
    "Keys": {
      "Word": 1426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "好感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎogǎn",
      "PrimaryDefinition": "good opinion, favorable impression"
    },
    "Keys": {
      "Word": 7005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngyuán",
      "PrimaryDefinition": "member"
    },
    "Keys": {
      "Word": 1352
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "雕塑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāosù",
      "PrimaryDefinition": "a statue, a Buddhist image, sculpture, to carve"
    },
    "Keys": {
      "Word": 6289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "study; repair; cultivate"
    },
    "Keys": {
      "Hanzi": 1560,
      "Word": 2066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 3,
      "GeneralStandard": 1560,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 740,
      "StrokeCount": 9
    }
  },
  "淆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáo",
      "PrimaryDefinition": "confused, in disarray, mixed up"
    },
    "Keys": {
      "Hanzi": 2422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2422,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 3263,
      "StrokeCount": 11
    }
  },
  "凋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "be withered, fallen; exhausted"
    },
    "Keys": {
      "Hanzi": 4623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4623,
      "Radical": "冫",
      "FrequencyRank": 3675,
      "RadicalIndex": "15.8",
      "StrokeCount": 10
    }
  },
  "袯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "raincoat"
    },
    "Keys": {
      "Hanzi": 7079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7079,
      "Radical": "衣",
      "RadicalIndex": "145.6",
      "StrokeCount": 10
    }
  },
  "葵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "sunflower; measure"
    },
    "Keys": {
      "Hanzi": 2561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2561,
      "Radical": "艸",
      "FrequencyRank": 3580,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "郑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.6",
      "GeneralStandard": 1229,
      "Radical": "邑",
      "HSK": 8,
      "FrequencyRank": 1132,
      "StrokeCount": 8
    }
  },
  "薛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuē",
      "PrimaryDefinition": "kind of marsh grass; feudal state"
    },
    "Keys": {
      "Hanzi": 3322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3322,
      "Radical": "艸",
      "FrequencyRank": 1953,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "旱灾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hànzāi",
      "PrimaryDefinition": "drought"
    },
    "Keys": {
      "Word": 6988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "犯愁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn chóu",
      "PrimaryDefinition": "to worry, to be anxious"
    },
    "Keys": {
      "Word": 6479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夺取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duóqǔ",
      "PrimaryDefinition": "to seize, to capture, to wrest control of"
    },
    "Keys": {
      "Word": 4533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鲥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5972,
      "Radical": "魚",
      "FrequencyRank": 6110,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "见效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn xiào",
      "PrimaryDefinition": "to have the desired effect"
    },
    "Keys": {
      "Word": 7421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "修补": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūbǔ",
      "PrimaryDefinition": "to mend"
    },
    "Keys": {
      "Word": 10076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngzuò",
      "PrimaryDefinition": "regard_as, regard as, look upon as, treat as, presume"
    },
    "Keys": {
      "Word": 4490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "爚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "bright; fiery"
    },
    "Keys": {
      "Hanzi": 8086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8086,
      "Radical": "火",
      "RadicalIndex": "86.17",
      "StrokeCount": 21
    }
  },
  "个案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèàn",
      "PrimaryDefinition": "case (in law/etc.)"
    },
    "Keys": {
      "Word": 6737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "vague, dim; twilight"
    },
    "Keys": {
      "Hanzi": 6821
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6821,
      "Radical": "日",
      "RadicalIndex": "72.16",
      "StrokeCount": 9
    }
  },
  "毋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "do not; not; surname; rad. 80"
    },
    "Keys": {
      "Hanzi": 3527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3527,
      "Radical": "毋",
      "FrequencyRank": 2876,
      "RadicalIndex": "80",
      "StrokeCount": 4
    }
  },
  "随即": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíjí",
      "PrimaryDefinition": "therewith, thereupon, presently, hereupon, soon after that, immediately"
    },
    "Keys": {
      "Word": 9365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "cut, mince, slice, carve"
    },
    "Keys": {
      "Hanzi": 107,
      "Word": 2805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.2",
      "Grade": 4,
      "GeneralStandard": 107,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 337,
      "StrokeCount": 4
    }
  },
  "蜜月": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìyuè",
      "PrimaryDefinition": "honeymoon"
    },
    "Keys": {
      "Word": 8273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "康": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāng",
      "PrimaryDefinition": "peaceful, quiet; happy, healthy"
    },
    "Keys": {
      "Hanzi": 2385
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.8",
      "GeneralStandard": 2385,
      "Radical": "广",
      "HSK": 2,
      "FrequencyRank": 900,
      "StrokeCount": 11
    }
  },
  "开车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi chē",
      "PrimaryDefinition": "to drive a car"
    },
    "Keys": {
      "Word": 186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "女生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚshēng",
      "PrimaryDefinition": "schoolgirl, girl student, female student, female, girl, woman student, co-ed"
    },
    "Keys": {
      "Word": 276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "止血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐxuè",
      "PrimaryDefinition": "to staunch (bleeding), hemostatic (drug)"
    },
    "Keys": {
      "Word": 10769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "合唱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héchàng",
      "PrimaryDefinition": "choir, do a chorus, sing in a chorus, chorus, descant"
    },
    "Keys": {
      "Word": 7024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngdù",
      "PrimaryDefinition": "elegance (for men), elegant demeanor, grace, poise"
    },
    "Keys": {
      "Word": 3463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "歪曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wāiqū",
      "PrimaryDefinition": "to distort, to misrepresent"
    },
    "Keys": {
      "Word": 9633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒ",
      "PrimaryDefinition": "trifling, petty; troublesome"
    },
    "Keys": {
      "Hanzi": 2156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2156,
      "Radical": "玉",
      "FrequencyRank": 2834,
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "姝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "a beautiful girl"
    },
    "Keys": {
      "Hanzi": 4385
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4385,
      "Radical": "女",
      "FrequencyRank": 6063,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "伞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎn",
      "PrimaryDefinition": "umbrella, parasol, parachute"
    },
    "Keys": {
      "Hanzi": 493,
      "Word": 2832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 4,
      "GeneralStandard": 493,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 2202,
      "StrokeCount": 6
    }
  },
  "无比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúbǐ",
      "PrimaryDefinition": "unparalleled, matchless"
    },
    "Keys": {
      "Word": 9758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "捡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "to pick up"
    },
    "Keys": {
      "Hanzi": 1804,
      "Word": 4710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 6,
      "GeneralStandard": 1804,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2753,
      "StrokeCount": 10
    }
  },
  "忻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "delightful, joyful, pleasant"
    },
    "Keys": {
      "Hanzi": 3789
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3789,
      "Radical": "心",
      "FrequencyRank": 4346,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "舳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "stern of ship"
    },
    "Keys": {
      "Hanzi": 7203
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7203,
      "Radical": "舟",
      "FrequencyRank": 6227,
      "RadicalIndex": "137.5",
      "StrokeCount": 11
    }
  },
  "热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rè",
      "PrimaryDefinition": "hot; heat; fever; restless; zeal"
    },
    "Keys": {
      "Hanzi": 1809,
      "Word": 300
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "Grade": 1,
      "GeneralStandard": 1809,
      "Radical": "火",
      "HSK": 1,
      "FrequencyRank": 606,
      "StrokeCount": 10
    }
  },
  "捻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎn",
      "PrimaryDefinition": "to twist or nip with the fingers"
    },
    "Keys": {
      "Hanzi": 2178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2178,
      "Radical": "手",
      "FrequencyRank": 3471,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "独立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúlì",
      "PrimaryDefinition": "stand alone"
    },
    "Keys": {
      "Word": 2422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "侈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐ",
      "PrimaryDefinition": "luxurious, extravagant"
    },
    "Keys": {
      "Hanzi": 1152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1152,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 3081,
      "StrokeCount": 8
    }
  },
  "颜色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánsè",
      "PrimaryDefinition": "companion, countenance, pigment, coloring, tincture, dyestuff, colour, facial expression, tinct, grain, colouring, color"
    },
    "Keys": {
      "Word": 1121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "冲击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngjī",
      "PrimaryDefinition": "lash, pound, charge, assault"
    },
    "Keys": {
      "Word": 4417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "黍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "millet"
    },
    "Keys": {
      "Radical": 202,
      "Hanzi": 5202
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5202,
      "Radical": "黍",
      "FrequencyRank": 5081,
      "RadicalIndex": "202",
      "StrokeCount": 12
    }
  },
  "棉花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miánhua",
      "PrimaryDefinition": "cotton"
    },
    "Keys": {
      "Word": 8274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "dogwood"
    },
    "Keys": {
      "Hanzi": 4116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4116,
      "Radical": "艸",
      "FrequencyRank": 5478,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "鹔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "turquoise kingfisher"
    },
    "Keys": {
      "Hanzi": 7609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7609,
      "Radical": "鳥",
      "RadicalIndex": "196.12",
      "StrokeCount": 13
    }
  },
  "梗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěng",
      "PrimaryDefinition": "stem of flower, branch of plant"
    },
    "Keys": {
      "Hanzi": 2223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2223,
      "Radical": "木",
      "FrequencyRank": 2919,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "鹎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "bird"
    },
    "Keys": {
      "Hanzi": 7548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7548,
      "Radical": "鳥",
      "FrequencyRank": 6419,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "浇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "spray, water, sprinkle"
    },
    "Keys": {
      "Hanzi": 1667,
      "Word": 7466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 7,
      "GeneralStandard": 1667,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2866,
      "StrokeCount": 9
    }
  },
  "䢺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6633,
      "Radical": "邑",
      "RadicalIndex": "163.5",
      "StrokeCount": 8
    }
  },
  "损害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔnhài",
      "PrimaryDefinition": "injury, damage"
    },
    "Keys": {
      "Word": 3952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "停车位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngchēwèi",
      "PrimaryDefinition": "parking spot"
    },
    "Keys": {
      "Word": 9516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "车站": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēzhàn",
      "PrimaryDefinition": "station"
    },
    "Keys": {
      "Word": 46
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "塅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7296
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7296,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "担任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānrèn",
      "PrimaryDefinition": "hold the post of, take charge of, assume, take_charge, fill, shoulder, adopt, skipper, assume the office of, assume office of, act_as, take, act as, play, undertake, take over, take on"
    },
    "Keys": {
      "Word": 2384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "躐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "to stride over; to step across"
    },
    "Keys": {
      "Hanzi": 6471
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6471,
      "Radical": "足",
      "FrequencyRank": 6764,
      "RadicalIndex": "157.15",
      "StrokeCount": 22
    }
  },
  "刎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěn",
      "PrimaryDefinition": "behead, cut throat"
    },
    "Keys": {
      "Hanzi": 3616
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3616,
      "Radical": "刀",
      "FrequencyRank": 5110,
      "RadicalIndex": "18.4",
      "StrokeCount": 6
    }
  },
  "隹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuī",
      "PrimaryDefinition": "short-tailed bird"
    },
    "Keys": {
      "Radical": 172,
      "Hanzi": 6699
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6699,
      "Radical": "隹",
      "FrequencyRank": 5837,
      "RadicalIndex": "172",
      "StrokeCount": 8
    }
  },
  "泺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "river in Shandong province"
    },
    "Keys": {
      "Hanzi": 4017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4017,
      "Radical": "水",
      "FrequencyRank": 6739,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "行家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángjia",
      "PrimaryDefinition": "connoisseur, expert, veteran"
    },
    "Keys": {
      "Word": 6991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "行动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngdòng",
      "PrimaryDefinition": "action, operation"
    },
    "Keys": {
      "Word": 1112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "贳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "borrow; pardon; loan"
    },
    "Keys": {
      "Hanzi": 4111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4111,
      "Radical": "貝",
      "FrequencyRank": 6977,
      "RadicalIndex": "154.5",
      "StrokeCount": 9
    }
  },
  "发动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fādòng",
      "PrimaryDefinition": "start, launch, mobilize, arouse"
    },
    "Keys": {
      "Word": 1431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "回国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí guó",
      "PrimaryDefinition": "return to one's country"
    },
    "Keys": {
      "Word": 738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "着眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóyǎn",
      "PrimaryDefinition": "to have one's eyes on (a goal), having sth in mind, to concentrate"
    },
    "Keys": {
      "Word": 10936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "包袱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāofu",
      "PrimaryDefinition": "cloth wrapper, bundle wrapped in cloth, burden, millstone round one's neck, punch line (in cross-talk)"
    },
    "Keys": {
      "Word": 5509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "复苏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùsū",
      "PrimaryDefinition": "variant of 復甦|复苏[fu4 su1], to recover (health, economic), to resuscitate, anabiosis"
    },
    "Keys": {
      "Word": 4577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "瓞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "young melons just forming"
    },
    "Keys": {
      "Hanzi": 7027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7027,
      "Radical": "瓜",
      "FrequencyRank": 8020,
      "RadicalIndex": "97.5",
      "StrokeCount": 10
    }
  },
  "开业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi yè",
      "PrimaryDefinition": "to open a business, to open a practice, open (for business)"
    },
    "Keys": {
      "Word": 1666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "出台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū tái",
      "PrimaryDefinition": "to officially launch (a policy, program etc), to appear on stage, to appear publicly, (of a bar girl) to leave with a client"
    },
    "Keys": {
      "Word": 4432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "赀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "property; wealth; to count"
    },
    "Keys": {
      "Hanzi": 6981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6981,
      "Radical": "貝",
      "FrequencyRank": 5889,
      "RadicalIndex": "154.6",
      "StrokeCount": 10
    }
  },
  "面临": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànlín",
      "PrimaryDefinition": "have, face, confront, envisage, flank, present, be faced with, be up against, bide, meet"
    },
    "Keys": {
      "Word": 2740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "旁边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pángbiān",
      "PrimaryDefinition": "by the side of, side, adjacency, near by"
    },
    "Keys": {
      "Word": 278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "屈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "bend, flex; bent, crooked; crouch"
    },
    "Keys": {
      "Hanzi": 1311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.5",
      "GeneralStandard": 1311,
      "Radical": "尸",
      "HSK": 8,
      "FrequencyRank": 1684,
      "StrokeCount": 8
    }
  },
  "账号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànghào",
      "PrimaryDefinition": "account, username"
    },
    "Keys": {
      "Word": 10659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "徊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huái",
      "PrimaryDefinition": "linger, walk to and fro, hesitain"
    },
    "Keys": {
      "Hanzi": 1581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.6",
      "GeneralStandard": 1581,
      "Radical": "彳",
      "HSK": 8,
      "FrequencyRank": 3003,
      "StrokeCount": 9
    }
  },
  "医学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxué",
      "PrimaryDefinition": "medicine, practice of medicine, medical science, leechcraft, physic, iatrology, medical"
    },
    "Keys": {
      "Word": 3083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "不服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùfú",
      "PrimaryDefinition": "not to accept sth, to want to have sth overruled or changed, to refuse to obey or comply, to refuse to accept as final, to remain unconvinced by, not to give in to"
    },
    "Keys": {
      "Word": 5701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒng",
      "PrimaryDefinition": "favorite, concubine; favor"
    },
    "Keys": {
      "Hanzi": 1277,
      "Word": 5928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "Grade": 7,
      "GeneralStandard": 1277,
      "Radical": "宀",
      "HSK": 6,
      "FrequencyRank": 2422,
      "StrokeCount": 8
    }
  },
  "疭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòng",
      "PrimaryDefinition": "spasms or convulsions in young children, caused by indigestion"
    },
    "Keys": {
      "Hanzi": 4311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4311,
      "Radical": "疒",
      "RadicalIndex": "104.4",
      "StrokeCount": 9
    }
  },
  "写字楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiězìlóu",
      "PrimaryDefinition": "office building"
    },
    "Keys": {
      "Word": 5179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "加油站": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāyóuzhàn",
      "PrimaryDefinition": "pit, petrol_station, filling station, gasoline station, filling_station, gas station, gas_station, petrol station"
    },
    "Keys": {
      "Word": 2598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "疯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "crazy, insane, mentally ill"
    },
    "Keys": {
      "Hanzi": 1626,
      "Word": 3468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.4",
      "Grade": 5,
      "GeneralStandard": 1626,
      "Radical": "疒",
      "HSK": 5,
      "FrequencyRank": 1549,
      "StrokeCount": 9
    }
  },
  "图纸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túzhǐ",
      "PrimaryDefinition": "blueprint, drawing, design plans, graph paper"
    },
    "Keys": {
      "Word": 9581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下旬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàxún",
      "PrimaryDefinition": "last third of the month"
    },
    "Keys": {
      "Word": 9871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐ",
      "PrimaryDefinition": "beautiful color of gem; flaw in"
    },
    "Keys": {
      "Hanzi": 6930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6930,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 10
    }
  },
  "登": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng",
      "PrimaryDefinition": "rise, mount, board, climb"
    },
    "Keys": {
      "Hanzi": 2813,
      "Word": 2399
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "105.7",
      "Grade": 4,
      "GeneralStandard": 2813,
      "Radical": "癶",
      "HSK": 4,
      "FrequencyRank": 817,
      "StrokeCount": 12
    }
  },
  "动物园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngwùyuán",
      "PrimaryDefinition": "zoological_garden, zoo, zoological garden, menagerie"
    },
    "Keys": {
      "Word": 630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "艰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "difficult, hard; distressing"
    },
    "Keys": {
      "Hanzi": 1335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "29.6",
      "GeneralStandard": 1335,
      "Radical": "又",
      "HSK": 5,
      "FrequencyRank": 1647,
      "StrokeCount": 8
    }
  },
  "丢脸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diū liǎn",
      "PrimaryDefinition": "to lose face, humiliation"
    },
    "Keys": {
      "Word": 6319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóng",
      "PrimaryDefinition": "pus"
    },
    "Keys": {
      "Hanzi": 2002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2002,
      "Radical": "肉",
      "FrequencyRank": 3376,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "闪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎn",
      "PrimaryDefinition": "flash; avoid, dodge, evade"
    },
    "Keys": {
      "Hanzi": 305,
      "Word": 2834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.2",
      "Grade": 4,
      "GeneralStandard": 305,
      "Radical": "門",
      "HSK": 4,
      "FrequencyRank": 1113,
      "StrokeCount": 5
    }
  },
  "度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "degree, system; manner; to consider"
    },
    "Keys": {
      "Hanzi": 1622,
      "Word": 6365
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.6",
      "Grade": 7,
      "GeneralStandard": 1622,
      "Radical": "广",
      "HSK": 2,
      "FrequencyRank": 184,
      "StrokeCount": 9
    }
  },
  "生平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngpíng",
      "PrimaryDefinition": "life, all one's life, all one's life (of deceased)"
    },
    "Keys": {
      "Word": 9055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大厅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàtīng",
      "PrimaryDefinition": "big/main hall, parlor"
    },
    "Keys": {
      "Word": 3385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疗养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáoyǎng",
      "PrimaryDefinition": "to get well, to heal, to recuperate, to convalesce, convalescence, to nurse"
    },
    "Keys": {
      "Word": 2703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá",
      "PrimaryDefinition": "flowery; illustrious; Chinese"
    },
    "Keys": {
      "Hanzi": 472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.4",
      "GeneralStandard": 472,
      "Radical": "十",
      "HSK": 3,
      "FrequencyRank": 412,
      "StrokeCount": 6
    }
  },
  "形势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngshì",
      "PrimaryDefinition": "circumstances, situation, terrain, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "领会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐnghuì",
      "PrimaryDefinition": "to understand, to comprehend, to grasp"
    },
    "Keys": {
      "Word": 8077
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "flaw in gem; flaw in character"
    },
    "Keys": {
      "Hanzi": 1357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1357,
      "Radical": "玉",
      "FrequencyRank": 4069,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "虺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "large poisonous snake"
    },
    "Keys": {
      "Hanzi": 4173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4173,
      "Radical": "虫",
      "FrequencyRank": 5543,
      "RadicalIndex": "142.3",
      "StrokeCount": 9
    }
  },
  "先前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānqián",
      "PrimaryDefinition": "before, previously"
    },
    "Keys": {
      "Word": 4030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "洗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "wash, rinse; clean, purify"
    },
    "Keys": {
      "Hanzi": 1671,
      "Word": 383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 1,
      "GeneralStandard": 1671,
      "Radical": "水",
      "HSK": 1,
      "FrequencyRank": 1247,
      "StrokeCount": 9
    }
  },
  "总的来说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngdeláishuō",
      "PrimaryDefinition": "generally speaking, to sum up, in summary, in short"
    },
    "Keys": {
      "Word": 10984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肇事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàoshì",
      "PrimaryDefinition": "to cause trouble, to provoke a disturbance"
    },
    "Keys": {
      "Word": 10681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "承担": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngdān",
      "PrimaryDefinition": "bear, undertake, assume"
    },
    "Keys": {
      "Word": 2315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "竽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "ancient woodwind instrument"
    },
    "Keys": {
      "Hanzi": 4253
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4253,
      "Radical": "竹",
      "FrequencyRank": 4935,
      "RadicalIndex": "118.3",
      "StrokeCount": 9
    }
  },
  "切割": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiēgē",
      "PrimaryDefinition": "to cut"
    },
    "Keys": {
      "Word": 8708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仙鹤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānhè",
      "PrimaryDefinition": "red-crowned crane (Grus japonensis)"
    },
    "Keys": {
      "Word": 9880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3811,
      "Radical": "女",
      "FrequencyRank": 5150,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "布满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùmǎn",
      "PrimaryDefinition": "to be covered with, to be filled with"
    },
    "Keys": {
      "Word": 4361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "由此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóucǐ",
      "PrimaryDefinition": "hereby, from this"
    },
    "Keys": {
      "Word": 4136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "淀粉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànfěn",
      "PrimaryDefinition": "starch, amylum C6H10O5"
    },
    "Keys": {
      "Word": 6281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国王": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guówáng",
      "PrimaryDefinition": "regal, crowned_head, Rex, crown, kingdom, Pharaoh, male monarch, roi, king"
    },
    "Keys": {
      "Word": 4640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "短缺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnquē",
      "PrimaryDefinition": "shortage"
    },
    "Keys": {
      "Word": 6369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乐队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèduì",
      "PrimaryDefinition": "orchestra, dance orchestra, dance band, band"
    },
    "Keys": {
      "Word": 2126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "摆动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎidòng",
      "PrimaryDefinition": "to sway, to swing, to move back and forth, to oscillate"
    },
    "Keys": {
      "Word": 2240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "公关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngguān",
      "PrimaryDefinition": "person in public relations"
    },
    "Keys": {
      "Word": 6763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "微": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "small, prefix micro-, trifling"
    },
    "Keys": {
      "Hanzi": 2965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.1",
      "GeneralStandard": 2965,
      "Radical": "彳",
      "HSK": 4,
      "FrequencyRank": 653,
      "StrokeCount": 13
    }
  },
  "安稳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānwěn",
      "PrimaryDefinition": "smooth and steady"
    },
    "Keys": {
      "Word": 5439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "造纸术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàozhǐshù",
      "PrimaryDefinition": "Papermaking"
    },
    "Keys": {
      "Word": 10616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐng",
      "PrimaryDefinition": "neck; collar; lead, guide"
    },
    "Keys": {
      "Hanzi": 2354,
      "Word": 1712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.5",
      "Grade": 3,
      "GeneralStandard": 2354,
      "Radical": "頁",
      "HSK": 3,
      "FrequencyRank": 329,
      "StrokeCount": 11
    }
  },
  "期中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīzhōng",
      "PrimaryDefinition": "interim, midterm"
    },
    "Keys": {
      "Word": 2791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "墘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "(Cant.) halfway"
    },
    "Keys": {
      "Hanzi": 7632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7632,
      "Radical": "土",
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "沾光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān guāng",
      "PrimaryDefinition": "to bask in the light, fig. to benefit from association with sb or sth, reflected glory"
    },
    "Keys": {
      "Word": 10635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "为此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèicǐ",
      "PrimaryDefinition": "for this reason, with regards to this, in this respect, in order to do this, to this end"
    },
    "Keys": {
      "Word": 5141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "芼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "to choose; to select; greens"
    },
    "Keys": {
      "Hanzi": 3668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3668,
      "Radical": "艸",
      "FrequencyRank": 9815,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "虬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "young dragon"
    },
    "Keys": {
      "Hanzi": 3707
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3707,
      "Radical": "虫",
      "FrequencyRank": 4242,
      "RadicalIndex": "142.1",
      "StrokeCount": 7
    }
  },
  "髽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuā",
      "PrimaryDefinition": "to dress the hair"
    },
    "Keys": {
      "Hanzi": 7921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7921,
      "Radical": "髟",
      "FrequencyRank": 9012,
      "RadicalIndex": "190.7",
      "StrokeCount": 17
    }
  },
  "增长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngzhǎng",
      "PrimaryDefinition": "increase, grow"
    },
    "Keys": {
      "Word": 2134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "嘉年华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāniánhuá",
      "PrimaryDefinition": "carnival (loanword)"
    },
    "Keys": {
      "Word": 7358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "species of yew"
    },
    "Keys": {
      "Hanzi": 7345
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7345,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "波及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōjí",
      "PrimaryDefinition": "affect, spread to"
    },
    "Keys": {
      "Word": 5659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "to braid, twist; twisted or braided"
    },
    "Keys": {
      "Hanzi": 7277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7277,
      "Radical": "糸",
      "FrequencyRank": 7355,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "注视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùshì",
      "PrimaryDefinition": "to watch attentively, to gaze at"
    },
    "Keys": {
      "Word": 4256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "重点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngdiǎn",
      "PrimaryDefinition": "taproot, accent, emphasis, focal point, point, keystone, hinge, salient point, stress, gravity"
    },
    "Keys": {
      "Word": 1232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "选民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnmín",
      "PrimaryDefinition": "elector, voter, constituent, constituency, vote, electorate"
    },
    "Keys": {
      "Word": 10123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lài",
      "PrimaryDefinition": "squint at; sidelong glance"
    },
    "Keys": {
      "Hanzi": 2612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2612,
      "Radical": "目",
      "FrequencyRank": 3969,
      "RadicalIndex": "109.7",
      "StrokeCount": 12
    }
  },
  "亚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "Asia; second"
    },
    "Keys": {
      "Hanzi": 373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.5",
      "GeneralStandard": 373,
      "Radical": "一",
      "HSK": 4,
      "FrequencyRank": 420,
      "StrokeCount": 6
    }
  },
  "琭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "jade like stone"
    },
    "Keys": {
      "Hanzi": 7288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7288,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "芙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "hibiscus"
    },
    "Keys": {
      "Hanzi": 661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 661,
      "Radical": "艸",
      "FrequencyRank": 2627,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "蟀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuài",
      "PrimaryDefinition": "cricket"
    },
    "Keys": {
      "Hanzi": 3412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3412,
      "Radical": "虫",
      "FrequencyRank": 3792,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "椰子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yēzi",
      "PrimaryDefinition": "coconut"
    },
    "Keys": {
      "Word": 10253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锁定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒdìng",
      "PrimaryDefinition": "to lock (a door), to close with a latch, to lock into place, a lock, a latch, to lock a computer file (to prevent it being overwritten), to lock (denying access to a computer system or device or files, e.g. by password-protection), to focus attention on, to target"
    },
    "Keys": {
      "Word": 9383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7062
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7062,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "make; work; compose, write; act, perform"
    },
    "Keys": {
      "Hanzi": 777,
      "Word": 5417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 6,
      "GeneralStandard": 777,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 49,
      "StrokeCount": 7
    }
  },
  "主权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔquán",
      "PrimaryDefinition": "imperium, ascendance, reign, ascendency, ascendancy, possession, empire, majesty, signory, paramountcy, lordship, sovereign rights, sovereignty, raj, supremacy, dominion, ownership"
    },
    "Keys": {
      "Word": 10857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楼道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóudào",
      "PrimaryDefinition": "corridor, passageway (in storied building)"
    },
    "Keys": {
      "Word": 4838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "汪洋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wāngyáng",
      "PrimaryDefinition": "vast body of water, CL:片[pian4]"
    },
    "Keys": {
      "Word": 9666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一旁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīpáng",
      "PrimaryDefinition": "aside, to the side of"
    },
    "Keys": {
      "Word": 10339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒng",
      "PrimaryDefinition": "fear; fearful, apprehensive"
    },
    "Keys": {
      "Hanzi": 1810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1810,
      "Radical": "心",
      "HSK": 3,
      "FrequencyRank": 891,
      "StrokeCount": 10
    }
  },
  "比方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐfang",
      "PrimaryDefinition": "analogy, instance, for instance"
    },
    "Keys": {
      "Word": 3245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蝽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūn",
      "PrimaryDefinition": "bedbug"
    },
    "Keys": {
      "Hanzi": 5920
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5920,
      "Radical": "虫",
      "FrequencyRank": 5745,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "池塘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chítáng",
      "PrimaryDefinition": "pool, pond"
    },
    "Keys": {
      "Word": 5899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "率领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuàilǐng",
      "PrimaryDefinition": "lead, head, command"
    },
    "Keys": {
      "Word": 3933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "人家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénjia",
      "PrimaryDefinition": "I (used by females), dwelling, I, sb. else, sb. else's house, family, household"
    },
    "Keys": {
      "Word": 2824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "钟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "clock; bell"
    },
    "Keys": {
      "Hanzi": 1528,
      "Word": 2182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "Grade": 3,
      "GeneralStandard": 1528,
      "Radical": "金",
      "HSK": 2,
      "FrequencyRank": 905,
      "StrokeCount": 9
    }
  },
  "看病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn bìng",
      "PrimaryDefinition": "to visit a doctor, to see a patient"
    },
    "Keys": {
      "Word": 190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "收音机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuyīnjī",
      "PrimaryDefinition": "radio, CL:臺|台[tai2]"
    },
    "Keys": {
      "Word": 1926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "站住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànzhù",
      "PrimaryDefinition": "to stand"
    },
    "Keys": {
      "Word": 1202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "好容易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoróngyi",
      "PrimaryDefinition": "with great difficulty, to have a hard time (convincing sb, relinquishing sth etc)"
    },
    "Keys": {
      "Word": 4655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "半": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "half"
    },
    "Keys": {
      "Hanzi": 307,
      "Word": 10
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "24.3",
      "Grade": 1,
      "GeneralStandard": 307,
      "Radical": "十",
      "HSK": 1,
      "FrequencyRank": 513,
      "StrokeCount": 5
    }
  },
  "弗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "not, negative"
    },
    "Keys": {
      "Hanzi": 328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 328,
      "Radical": "弓",
      "FrequencyRank": 1257,
      "RadicalIndex": "57.2",
      "StrokeCount": 5
    }
  },
  "增强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngqiáng",
      "PrimaryDefinition": "strengthen, enhance"
    },
    "Keys": {
      "Word": 4183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "啮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "bite, gnaw"
    },
    "Keys": {
      "Hanzi": 4829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4829,
      "Radical": "口",
      "FrequencyRank": 4400,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "阁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "chamber, pavilion; cabinet"
    },
    "Keys": {
      "Hanzi": 1639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.6",
      "GeneralStandard": 1639,
      "Radical": "門",
      "HSK": 8,
      "FrequencyRank": 1682,
      "StrokeCount": 9
    }
  },
  "古迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔjì",
      "PrimaryDefinition": "places of historic interest, historical sites, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 6826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "重叠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngdié",
      "PrimaryDefinition": "telescope, duplicate, superpose, overlap, repeated, lap"
    },
    "Keys": {
      "Word": 5921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "oak; spinous evergreen tree; to clear away trees"
    },
    "Keys": {
      "Hanzi": 4151
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4151,
      "Radical": "木",
      "FrequencyRank": 5247,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "钧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "unit of measure equivalent to thirty catties"
    },
    "Keys": {
      "Hanzi": 1533
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "GeneralStandard": 1533,
      "Radical": "金",
      "HSK": 9,
      "FrequencyRank": 2504,
      "StrokeCount": 9
    }
  },
  "禾苗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hémiáo",
      "PrimaryDefinition": "seedling (of rice or other grain), CL:棵[ke1]"
    },
    "Keys": {
      "Word": 7023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "收留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuliú",
      "PrimaryDefinition": "to offer shelter, to have sb in one's care"
    },
    "Keys": {
      "Word": 9168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāi",
      "PrimaryDefinition": "dull; dull-minded, simple, stupid"
    },
    "Keys": {
      "Hanzi": 722,
      "Word": 3389
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 5,
      "GeneralStandard": 722,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1338,
      "StrokeCount": 7
    }
  },
  "明智": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngzhì",
      "PrimaryDefinition": "sensible, wise, judicious, sagacious"
    },
    "Keys": {
      "Word": 8313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "rest room"
    },
    "Keys": {
      "Hanzi": 4817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4817,
      "Radical": "囗",
      "FrequencyRank": 8424,
      "RadicalIndex": "31.8",
      "StrokeCount": 11
    }
  },
  "诰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gào",
      "PrimaryDefinition": "inform, notify, admonish, order"
    },
    "Keys": {
      "Hanzi": 4371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4371,
      "Radical": "言",
      "FrequencyRank": 3967,
      "RadicalIndex": "149.7",
      "StrokeCount": 9
    }
  },
  "鼻子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bízi",
      "PrimaryDefinition": "nasal, hooter, nozzle, snoot, sneezer, boko, schnozzle, conk, smeller, snout, beezer, proboscis, olfactory organ, snitch, nose, olfactory"
    },
    "Keys": {
      "Word": 3244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúshù",
      "PrimaryDefinition": "countlessness, thousand, limitlessness, infinitude, boundlessness, trillions, millions, myriad, innumerableness, unboundedness, billions, zillions, jillions, host, countless, legion, det.: innumerable, incalculability, million, infiniteness, innumerability, immensity, umpteen"
    },
    "Keys": {
      "Word": 2989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "忍受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěnshòu",
      "PrimaryDefinition": "forbear, hack, dree, stomach, abide, hurt, aby, hold still for, stick, brave out, live on, ache, endure, cut, swallow, suffer, brave, brook, bide, thole, digest, receive, stand, absorb, go, put up, hold out, live, hold up, sustain, abye, support, stand_for, bear, stand for, survive, last, tolerate, weather"
    },
    "Keys": {
      "Word": 3845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "有幸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuxìng",
      "PrimaryDefinition": "fortunately"
    },
    "Keys": {
      "Word": 10482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宠物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒngwù",
      "PrimaryDefinition": "pet"
    },
    "Keys": {
      "Word": 4420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "䓛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "molecular formula (C18H12), to brush"
    },
    "Keys": {
      "Hanzi": 7134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7134,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 14
    }
  },
  "锢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "run metal into cracks; confine"
    },
    "Keys": {
      "Hanzi": 5488
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5488,
      "Radical": "金",
      "FrequencyRank": 3948,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "堆砌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duīqì",
      "PrimaryDefinition": "lit. to pile up (bricks), to pack, fig. to pad out (writing with fancy phrases), ornate rhetoric"
    },
    "Keys": {
      "Word": 6375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "sad, mournful, pitiful; pity"
    },
    "Keys": {
      "Hanzi": 1619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "GeneralStandard": 1619,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1751,
      "StrokeCount": 9
    }
  },
  "荮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "grass"
    },
    "Keys": {
      "Hanzi": 4138
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4138,
      "Radical": "艸",
      "FrequencyRank": 9746,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "尽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "exhaust, use up; deplete"
    },
    "Keys": {
      "Hanzi": 566,
      "Word": 7566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.3",
      "Grade": 7,
      "GeneralStandard": 566,
      "Radical": "尸",
      "HSK": 3,
      "FrequencyRank": 488,
      "StrokeCount": 6
    }
  },
  "必不可少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìbùkěshǎo",
      "PrimaryDefinition": "absolutely necessary, indispensable, essential"
    },
    "Keys": {
      "Word": 5587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cái",
      "PrimaryDefinition": "material, stuff; timber; talent"
    },
    "Keys": {
      "Hanzi": 681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "GeneralStandard": 681,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 952,
      "StrokeCount": 7
    }
  },
  "耢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lào",
      "PrimaryDefinition": "a kind of farm tool"
    },
    "Keys": {
      "Hanzi": 5339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5339,
      "Radical": "耒",
      "FrequencyRank": 7749,
      "RadicalIndex": "127.7",
      "StrokeCount": 13
    }
  },
  "越来越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèláiyuè",
      "PrimaryDefinition": "more_and_more, more and more"
    },
    "Keys": {
      "Word": 1187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "玶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "name of one kind of jade"
    },
    "Keys": {
      "Hanzi": 6758
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6758,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "遍地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàndì",
      "PrimaryDefinition": "everywhere, all over"
    },
    "Keys": {
      "Word": 4336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "浈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "river in Guangdong province"
    },
    "Keys": {
      "Hanzi": 6886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6886,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "锤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": "balance weight on scale; hammer"
    },
    "Keys": {
      "Hanzi": 2940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.8",
      "GeneralStandard": 2940,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2937,
      "StrokeCount": 13
    }
  },
  "噘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juē",
      "PrimaryDefinition": "pouting"
    },
    "Keys": {
      "Hanzi": 5910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5910,
      "Radical": "口",
      "FrequencyRank": 4719,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "潮湿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháoshī",
      "PrimaryDefinition": "damp, moist"
    },
    "Keys": {
      "Word": 2308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "旗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "banner, flag, streamer"
    },
    "Keys": {
      "Hanzi": 3152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "70.1",
      "GeneralStandard": 3152,
      "Radical": "方",
      "HSK": 6,
      "FrequencyRank": 1407,
      "StrokeCount": 14
    }
  },
  "齿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐ",
      "PrimaryDefinition": "tooth"
    },
    "Keys": {
      "Radical": 211,
      "Hanzi": 1068
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "211",
      "GeneralStandard": 1068,
      "Radical": "齒",
      "HSK": 7,
      "FrequencyRank": 1773,
      "StrokeCount": 8
    }
  },
  "假使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎshǐ",
      "PrimaryDefinition": "conj.: if, in case, in the event that"
    },
    "Keys": {
      "Word": 7365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "众所周知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngsuǒzhōuzhī",
      "PrimaryDefinition": "see 眾所周知|众所周知[zhong4 suo3 zhou1 zhi1]"
    },
    "Keys": {
      "Word": 10827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "medicinal plant"
    },
    "Keys": {
      "Hanzi": 6570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6570,
      "Radical": "艸",
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "洺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "river in Hebei province"
    },
    "Keys": {
      "Hanzi": 6893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6893,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "蓬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "type of raspberry; fairyland"
    },
    "Keys": {
      "Hanzi": 2855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.11",
      "GeneralStandard": 2855,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2398,
      "StrokeCount": 13
    }
  },
  "写字台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiězìtái",
      "PrimaryDefinition": "writing desk"
    },
    "Keys": {
      "Word": 5181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "国歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guógē",
      "PrimaryDefinition": "national anthem"
    },
    "Keys": {
      "Word": 4643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "盥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "wash"
    },
    "Keys": {
      "Hanzi": 6129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6129,
      "Radical": "皿",
      "FrequencyRank": 4758,
      "RadicalIndex": "108.11",
      "StrokeCount": 16
    }
  },
  "车型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēxíng",
      "PrimaryDefinition": "Model"
    },
    "Keys": {
      "Word": 5843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāi",
      "PrimaryDefinition": "an exclamation"
    },
    "Keys": {
      "Hanzi": 5473
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.1",
      "GeneralStandard": 5473,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 3294,
      "StrokeCount": 13
    }
  },
  "可恶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěwù",
      "PrimaryDefinition": "hateful, abominable"
    },
    "Keys": {
      "Word": 7802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎo",
      "PrimaryDefinition": "flea; louse"
    },
    "Keys": {
      "Hanzi": 1749
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1749,
      "Radical": "虫",
      "FrequencyRank": 4054,
      "RadicalIndex": "142.4",
      "StrokeCount": 9
    }
  },
  "参与": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānyù",
      "PrimaryDefinition": "participate in"
    },
    "Keys": {
      "Word": 2290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "主人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔrén",
      "PrimaryDefinition": "lord, housemaster, host, symposiarch, marse, bwana, padrone, boss, master, baas, provider, mast, owner, landlord"
    },
    "Keys": {
      "Word": 1234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蕖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5654
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5654,
      "Radical": "艸",
      "FrequencyRank": 5803,
      "RadicalIndex": "140.12",
      "StrokeCount": 14
    }
  },
  "遮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhē",
      "PrimaryDefinition": "cover, shield, protect"
    },
    "Keys": {
      "Hanzi": 3143,
      "Word": 10683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.11",
      "Grade": 7,
      "GeneralStandard": 3143,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 2135,
      "StrokeCount": 14
    }
  },
  "单调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāndiào",
      "PrimaryDefinition": "monotonous"
    },
    "Keys": {
      "Word": 2389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "食品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shípǐn",
      "PrimaryDefinition": "menu, vivers, viand, chow, eatable, provisions, tuck, foodstuff, viands, nourishment, comestible, tack, nutrient, victual, food, grocery, victualage, cuisine"
    },
    "Keys": {
      "Word": 1904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "莰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6949,
      "Radical": "艸",
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "演示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnshì",
      "PrimaryDefinition": "to demonstrate, to show, presentation, demonstration"
    },
    "Keys": {
      "Word": 10201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倾家荡产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngjiā-dàngchǎn",
      "PrimaryDefinition": "to lose a family fortune (idiom)"
    },
    "Keys": {
      "Word": 8738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3649,
      "Radical": "玉",
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "晚期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnqī",
      "PrimaryDefinition": "later period, end stage, terminal"
    },
    "Keys": {
      "Word": 9658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自从": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìcóng",
      "PrimaryDefinition": "since"
    },
    "Keys": {
      "Word": 2210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miào",
      "PrimaryDefinition": "mysterious, subtle; exquisite"
    },
    "Keys": {
      "Hanzi": 919,
      "Word": 4862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.4",
      "Grade": 6,
      "GeneralStandard": 919,
      "Radical": "女",
      "HSK": 6,
      "FrequencyRank": 1250,
      "StrokeCount": 7
    }
  },
  "会员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìyuán",
      "PrimaryDefinition": "member"
    },
    "Keys": {
      "Word": 1566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "赫然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèrán",
      "PrimaryDefinition": "with astonishment, with a shock, awe-inspiringly, impressively, furiously (angry)"
    },
    "Keys": {
      "Word": 7056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "kind of wood from which perfume"
    },
    "Keys": {
      "Hanzi": 4757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4757,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 11
    }
  },
  "茉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "white jasmine"
    },
    "Keys": {
      "Hanzi": 998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 998,
      "Radical": "艸",
      "FrequencyRank": 4064,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "模拟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mónǐ",
      "PrimaryDefinition": "imitate, simulate"
    },
    "Keys": {
      "Word": 8318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "clear"
    },
    "Keys": {
      "Hanzi": 4334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4334,
      "Radical": "水",
      "FrequencyRank": 4338,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "近来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnlái",
      "PrimaryDefinition": "newly, of late, lately, fresh, new, latterly, in recent times, freshly, yesterday, late, recently"
    },
    "Keys": {
      "Word": 3627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "looks, appearance; figure, form"
    },
    "Keys": {
      "Hanzi": 2105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "GeneralStandard": 2105,
      "Radical": "宀",
      "HSK": 3,
      "FrequencyRank": 442,
      "StrokeCount": 10
    }
  },
  "打量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎliang",
      "PrimaryDefinition": "estimate, conjecture, size up, reckon, look up and down, suppose, give the once over, think, look ... up and down, measure with the eye, give the eye, calculate"
    },
    "Keys": {
      "Word": 6104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "fetters, shackles, handcuffs"
    },
    "Keys": {
      "Hanzi": 4452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4452,
      "Radical": "木",
      "FrequencyRank": 4722,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "蕴涵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnhán",
      "PrimaryDefinition": "to contain, to accumulate, to embrace, implicit condition, implication, entailment"
    },
    "Keys": {
      "Word": 10580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7267
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7267,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "冰箱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīngxiāng",
      "PrimaryDefinition": "frig, electric refrigerator, fridge, freezer, refrigerator, deepfreeze, icebox, reefer, cooler, refrigeratory"
    },
    "Keys": {
      "Word": 2274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "专卖店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānmàidiàn",
      "PrimaryDefinition": "specialty store"
    },
    "Keys": {
      "Word": 10881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "强项": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángxiàng",
      "PrimaryDefinition": "key strength, strong suit, specialty"
    },
    "Keys": {
      "Word": 8690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "增产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng chǎn",
      "PrimaryDefinition": "to increase production"
    },
    "Keys": {
      "Word": 4180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "整整": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěngzhěng",
      "PrimaryDefinition": "fully, full, whole"
    },
    "Keys": {
      "Word": 2147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "虓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "roar"
    },
    "Keys": {
      "Hanzi": 7033
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7033,
      "Radical": "虍",
      "RadicalIndex": "141.4",
      "StrokeCount": 10
    }
  },
  "哈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hā",
      "PrimaryDefinition": "sound of laughter"
    },
    "Keys": {
      "Hanzi": 1510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "GeneralStandard": 1510,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 713,
      "StrokeCount": 9
    }
  },
  "摞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "to pile up"
    },
    "Keys": {
      "Hanzi": 5634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5634,
      "Radical": "手",
      "FrequencyRank": 4299,
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "刷牙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuā yá",
      "PrimaryDefinition": "to brush one's teeth"
    },
    "Keys": {
      "Word": 2893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "梠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "a small beam supporting the rafters at the eaves"
    },
    "Keys": {
      "Hanzi": 6961
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6961,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 10
    }
  },
  "别提了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biétíle",
      "PrimaryDefinition": "say no more, don't bring it up, drop the subject"
    },
    "Keys": {
      "Word": 5641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "何必": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hébì",
      "PrimaryDefinition": "why must...?, there is no need"
    },
    "Keys": {
      "Word": 7032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎibào",
      "PrimaryDefinition": "throwaway, poster, bill, flier, flyer, broadside, a playbill, a placard, circular, playbill, placard, broadsheet, handbill, a poster"
    },
    "Keys": {
      "Word": 4646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "呀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ya",
      "PrimaryDefinition": "particle used to express surprise or mild emphasis; (Cant.) slurred form of the number ten"
    },
    "Keys": {
      "Hanzi": 729,
      "Word": 3060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 4,
      "GeneralStandard": 729,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 929,
      "StrokeCount": 7
    }
  },
  "拥有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōngyǒu",
      "PrimaryDefinition": "occupy, have, receive, aught, hold back, keep, keep back, owe, retain, own, possess, possession, hold, seize, take, acquire, corral, hold on"
    },
    "Keys": {
      "Word": 4127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "躏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìn",
      "PrimaryDefinition": "trample down, oppress, overrun"
    },
    "Keys": {
      "Hanzi": 3492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3492,
      "Radical": "足",
      "FrequencyRank": 3821,
      "RadicalIndex": "157.14",
      "StrokeCount": 21
    }
  },
  "偏僻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānpì",
      "PrimaryDefinition": "remote, desolate, far from the city"
    },
    "Keys": {
      "Word": 8521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "商场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngchǎng",
      "PrimaryDefinition": "department store, bazar, line of business, field of operation, grocery store, emporium, bazaar, grocery, mall, commercial circle, field, market"
    },
    "Keys": {
      "Word": 309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "吉祥物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíxiángwù",
      "PrimaryDefinition": "mascot"
    },
    "Keys": {
      "Word": 7291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "金钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnqián",
      "PrimaryDefinition": "pewter, splosh, green, Oscar, siller, gelt, tin, coin, roll, dollar sign, funds, dollar mark, shekels, wealth, lucre, pecuniary, dinero, bankroll, dough, scratch, bread, means, moolah, shekel, shiner, cabbage, kale, spondulicks, pelf, oof, money, loot, wampum, brass, dollar"
    },
    "Keys": {
      "Word": 4741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "大体上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàtǐshàng",
      "PrimaryDefinition": "overall, in general terms"
    },
    "Keys": {
      "Word": 6134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "mushrooms, fungus; mildew, mold"
    },
    "Keys": {
      "Hanzi": 5874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5874,
      "Radical": "艸",
      "FrequencyRank": 6444,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "长城": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Chángchéng",
      "PrimaryDefinition": "impregnable bulwark, the Great Wall, Great Wall"
    },
    "Keys": {
      "Word": 1335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "二维码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èrwéimǎ",
      "PrimaryDefinition": "two-dimensional barcode, QR code"
    },
    "Keys": {
      "Word": 3442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "龁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "gnaw, bite, nibble"
    },
    "Keys": {
      "Hanzi": 7153
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7153,
      "Radical": "齒",
      "FrequencyRank": 6851,
      "RadicalIndex": "211.3",
      "StrokeCount": 11
    }
  },
  "发展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāzhǎn",
      "PrimaryDefinition": "develop, expand, grow"
    },
    "Keys": {
      "Word": 1436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鳓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lè",
      "PrimaryDefinition": "Chinese herring; shad"
    },
    "Keys": {
      "Hanzi": 6403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6403,
      "Radical": "魚",
      "FrequencyRank": 7046,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "目瞪口呆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùdèng-kǒudāi",
      "PrimaryDefinition": "dumbstruck (idiom), stupefied, stunned"
    },
    "Keys": {
      "Word": 8347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皇上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huángshang",
      "PrimaryDefinition": "the emperor, Your majesty the emperor, His imperial majesty"
    },
    "Keys": {
      "Word": 7191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāng",
      "PrimaryDefinition": "stake, post; affair, matter"
    },
    "Keys": {
      "Hanzi": 1846,
      "Word": 10924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 7,
      "GeneralStandard": 1846,
      "Radical": "木",
      "HSK": 7,
      "FrequencyRank": 2491,
      "StrokeCount": 10
    }
  },
  "哥哥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēge",
      "PrimaryDefinition": "big brother, elder brother, big_brother"
    },
    "Keys": {
      "Word": 115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "行业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángyè",
      "PrimaryDefinition": "metier, mystery, profession, line, game, craft, calling, trade, industry, biz, walk_of_life, racket, living"
    },
    "Keys": {
      "Word": 2538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "feel, perceive, emotion"
    },
    "Keys": {
      "Hanzi": 2875,
      "Word": 6673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "Grade": 7,
      "GeneralStandard": 2875,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 243,
      "StrokeCount": 13
    }
  },
  "腮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāi",
      "PrimaryDefinition": "lower part of face; jaw; gills of a fish"
    },
    "Keys": {
      "Hanzi": 2971
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2971,
      "Radical": "肉",
      "FrequencyRank": 3185,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "衎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn",
      "PrimaryDefinition": "to give pleasure; pleased, happy"
    },
    "Keys": {
      "Hanzi": 6860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6860,
      "Radical": "行",
      "RadicalIndex": "144.3",
      "StrokeCount": 9
    }
  },
  "䏝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3978,
      "Radical": "肉",
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "却": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "still, but; decline; retreat"
    },
    "Keys": {
      "Hanzi": 658,
      "Word": 2817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "26.5",
      "Grade": 4,
      "GeneralStandard": 658,
      "Radical": "卩",
      "HSK": 4,
      "FrequencyRank": 287,
      "StrokeCount": 7
    }
  },
  "不辞而别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùcíérbié",
      "PrimaryDefinition": "to leave without saying good-bye"
    },
    "Keys": {
      "Word": 5690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "犬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quǎn",
      "PrimaryDefinition": "dog; radical number 94"
    },
    "Keys": {
      "Hanzi": 92
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94",
      "GeneralStandard": 92,
      "Radical": "犬",
      "HSK": 8,
      "FrequencyRank": 2649,
      "StrokeCount": 4
    }
  },
  "指出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐchū",
      "PrimaryDefinition": "point, point_out, catch_out, bespeak, indicate, denote, point out (that), note, show, designate, point out"
    },
    "Keys": {
      "Word": 2171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "床位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuángwèi",
      "PrimaryDefinition": "bed (in hospital, hotel, train etc), berth, bunk"
    },
    "Keys": {
      "Word": 6018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "失踪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī zōng",
      "PrimaryDefinition": "be missing"
    },
    "Keys": {
      "Word": 9090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "两口子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎngkǒuzi",
      "PrimaryDefinition": "husband and wife"
    },
    "Keys": {
      "Word": 8034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "ear of grain; tassel; Guangzhou"
    },
    "Keys": {
      "Hanzi": 3415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3415,
      "Radical": "禾",
      "FrequencyRank": 3378,
      "RadicalIndex": "115.12",
      "StrokeCount": 17
    }
  },
  "蕞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuì",
      "PrimaryDefinition": "little, small, tiny; petty"
    },
    "Keys": {
      "Hanzi": 5877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5877,
      "Radical": "艸",
      "FrequencyRank": 6651,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "狼狈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lángbèi",
      "PrimaryDefinition": "in a difficult situation, to cut a sorry figure, scoundrel! (derog.)"
    },
    "Keys": {
      "Word": 7934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年薪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánxīn",
      "PrimaryDefinition": "annual salary"
    },
    "Keys": {
      "Word": 8411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圆满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánmǎn",
      "PrimaryDefinition": "satisfactory"
    },
    "Keys": {
      "Word": 3134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "溦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "drizzle; (variant) valley; (variant) mold"
    },
    "Keys": {
      "Hanzi": 7592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7592,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "儿童": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "értóng",
      "PrimaryDefinition": "kid, nipper, enfant, tyke, youngster, small fry, minor, shaver, nestling, tike, child, chit, fry, children, infant, tiddler, small-fry"
    },
    "Keys": {
      "Word": 2439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "化身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàshēn",
      "PrimaryDefinition": "incarnation, reincarnation, embodiment (of abstract idea), personification"
    },
    "Keys": {
      "Word": 7140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "休眠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūmián",
      "PrimaryDefinition": "to be dormant (biology), inactive (volcano), to hibernate (computing)"
    },
    "Keys": {
      "Word": 10073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "勖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "enjoin, advise, preach to"
    },
    "Keys": {
      "Hanzi": 4821
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4821,
      "Radical": "力",
      "FrequencyRank": 4974,
      "RadicalIndex": "19.9",
      "StrokeCount": 11
    }
  },
  "赓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēng",
      "PrimaryDefinition": "continue"
    },
    "Keys": {
      "Hanzi": 5260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5260,
      "Radical": "广",
      "FrequencyRank": 3803,
      "RadicalIndex": "53.9",
      "StrokeCount": 12
    }
  },
  "元老": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánlǎo",
      "PrimaryDefinition": "senior figure, elder, doyen"
    },
    "Keys": {
      "Word": 10535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "究": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "examine, investigate"
    },
    "Keys": {
      "Hanzi": 881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.2",
      "GeneralStandard": 881,
      "Radical": "穴",
      "HSK": 4,
      "FrequencyRank": 429,
      "StrokeCount": 7
    }
  },
  "欣喜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxǐ",
      "PrimaryDefinition": "happy"
    },
    "Keys": {
      "Word": 10026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎopǐn",
      "PrimaryDefinition": "short, simple literary or artistic creation, essay, skit"
    },
    "Keys": {
      "Word": 9969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "room made of stone; watchtower"
    },
    "Keys": {
      "Hanzi": 5420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5420,
      "Radical": "石",
      "FrequencyRank": 3659,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "哓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "garrulous; disturbed; restless"
    },
    "Keys": {
      "Hanzi": 4203
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4203,
      "Radical": "口",
      "FrequencyRank": 6216,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "坟墓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fénmù",
      "PrimaryDefinition": "grave, tomb"
    },
    "Keys": {
      "Word": 6547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "富强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùqiáng",
      "PrimaryDefinition": "rich and powerful"
    },
    "Keys": {
      "Word": 6642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùliào",
      "PrimaryDefinition": "unexpectedly"
    },
    "Keys": {
      "Word": 4347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "创新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngxīn",
      "PrimaryDefinition": "bring forth new ideas, blaze new trails"
    },
    "Keys": {
      "Word": 1369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "手工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒugōng",
      "PrimaryDefinition": "handwork"
    },
    "Keys": {
      "Word": 2879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "庸俗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōngsú",
      "PrimaryDefinition": "filthy, vulgar, debased"
    },
    "Keys": {
      "Word": 10431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4399
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4399,
      "Radical": "耒",
      "FrequencyRank": 7181,
      "RadicalIndex": "127.4",
      "StrokeCount": 10
    }
  },
  "婶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2482,
      "Radical": "女",
      "FrequencyRank": 2971,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "好吃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎochī",
      "PrimaryDefinition": "delicious, good to eat, tasty, enjoy eating good food, gluttonous"
    },
    "Keys": {
      "Word": 137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "娀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "name of a concubine of Di Ku, father of the mythical Yao"
    },
    "Keys": {
      "Hanzi": 6910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6910,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "莆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "a kind of legendary tree"
    },
    "Keys": {
      "Hanzi": 4428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4428,
      "Radical": "艸",
      "FrequencyRank": 4927,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "从早到晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngzǎodàowǎn",
      "PrimaryDefinition": "From early as late"
    },
    "Keys": {
      "Word": 6064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cán",
      "PrimaryDefinition": "ashamed, humiliated; shameful"
    },
    "Keys": {
      "Hanzi": 2440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2440,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 2943,
      "StrokeCount": 11
    }
  },
  "蝣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "mayfly (Ephemera strigata)"
    },
    "Keys": {
      "Hanzi": 5927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5927,
      "Radical": "虫",
      "FrequencyRank": 5795,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "罩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "basket for catching fish; cover"
    },
    "Keys": {
      "Hanzi": 2933,
      "Word": 10679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "122.8",
      "Grade": 7,
      "GeneralStandard": 2933,
      "Radical": "网",
      "HSK": 7,
      "FrequencyRank": 1978,
      "StrokeCount": 13
    }
  },
  "打印机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎyìnjī",
      "PrimaryDefinition": "printer"
    },
    "Keys": {
      "Word": 4471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "崾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎo",
      "PrimaryDefinition": "place name in Shanxi province"
    },
    "Keys": {
      "Hanzi": 7362
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7362,
      "Radical": "山",
      "FrequencyRank": 5365,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "香味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngwèi",
      "PrimaryDefinition": "spicery, incense, redolence, odor, aroma, spiciness, sweetness, fragrance, flavor, bouquet, sapidness, flavour, sapidity, perfume, spice, smell, snuff, scent, fragrancy, balm, nose, zest"
    },
    "Keys": {
      "Word": 9934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烟花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yānhuā",
      "PrimaryDefinition": "fireworks, prostitute (esp. in Yuan theater)"
    },
    "Keys": {
      "Word": 5208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "癿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qié",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6540,
      "Radical": "白",
      "RadicalIndex": "106.1",
      "StrokeCount": 6
    }
  },
  "啪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pā",
      "PrimaryDefinition": "syllable"
    },
    "Keys": {
      "Hanzi": 2266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2266,
      "Radical": "口",
      "FrequencyRank": 2812,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "供奉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngfèng",
      "PrimaryDefinition": "to consecrate, to enshrine and worship, an offering (to one's ancestors), a sacrifice (to a God)"
    },
    "Keys": {
      "Word": 6806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7091,
      "Radical": "羽",
      "FrequencyRank": 7817,
      "RadicalIndex": "124.4",
      "StrokeCount": 10
    }
  },
  "枪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "spear, lance; gun, rifle"
    },
    "Keys": {
      "Hanzi": 1027,
      "Word": 3805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 5,
      "GeneralStandard": 1027,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 874,
      "StrokeCount": 8
    }
  },
  "艾滋病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àizībìng",
      "PrimaryDefinition": "AIDS (loanword)"
    },
    "Keys": {
      "Word": 5427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "调查": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàochá",
      "PrimaryDefinition": "investigate, look into, survey"
    },
    "Keys": {
      "Word": 1411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "䗪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè",
      "PrimaryDefinition": "a kind of worm; can be used in Chinese medicine"
    },
    "Keys": {
      "Hanzi": 7970
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7970,
      "Radical": "虫",
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "减轻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnqīng",
      "PrimaryDefinition": "lighten, ease, mitigate"
    },
    "Keys": {
      "Word": 3596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "起步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ bù",
      "PrimaryDefinition": "start (a task)"
    },
    "Keys": {
      "Word": 8612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngchē",
      "PrimaryDefinition": "bus, cars provided by an organization"
    },
    "Keys": {
      "Word": 6758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㳘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "a fountain or spring flows downwards, sound of the flowing water"
    },
    "Keys": {
      "Hanzi": 6896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6896,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "痂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "scab"
    },
    "Keys": {
      "Hanzi": 4620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4620,
      "Radical": "疒",
      "FrequencyRank": 4897,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "好多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoduō",
      "PrimaryDefinition": "det : many, much"
    },
    "Keys": {
      "Word": 709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "结实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēshi",
      "PrimaryDefinition": "to bear fruit, rugged, sturdy, strong, durable, buff (physique)"
    },
    "Keys": {
      "Word": 1616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鼓掌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ zhǎng",
      "PrimaryDefinition": "clap one's hands; applaud"
    },
    "Keys": {
      "Word": 3511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "健全": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànquán",
      "PrimaryDefinition": "sound, perfect"
    },
    "Keys": {
      "Word": 3603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "焌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "to light"
    },
    "Keys": {
      "Hanzi": 7232
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7232,
      "Radical": "火",
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "溴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "bromine"
    },
    "Keys": {
      "Hanzi": 5579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5579,
      "Radical": "水",
      "FrequencyRank": 4990,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "抵触": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐchù",
      "PrimaryDefinition": "to conflict, to contradict"
    },
    "Keys": {
      "Word": 6237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过敏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòmǐn",
      "PrimaryDefinition": "oversensitive, allergic, allergy"
    },
    "Keys": {
      "Word": 3530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "仂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lè",
      "PrimaryDefinition": "surplus or excess; remainder"
    },
    "Keys": {
      "Hanzi": 3517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3517,
      "Radical": "人",
      "FrequencyRank": 6946,
      "RadicalIndex": "9.2",
      "StrokeCount": 4
    }
  },
  "民主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínzhǔ",
      "PrimaryDefinition": "democracy, democratic rights"
    },
    "Keys": {
      "Word": 4867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "肺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "lungs"
    },
    "Keys": {
      "Hanzi": 1181,
      "Word": 4561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 6,
      "GeneralStandard": 1181,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 2044,
      "StrokeCount": 8
    }
  },
  "锻炼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duànliàn",
      "PrimaryDefinition": "to toughen, to temper, to engage in physical exercise, to work out, (fig.) to develop one's skills, to train oneself"
    },
    "Keys": {
      "Word": 2431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "担忧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānyōu",
      "PrimaryDefinition": "be apprehensive"
    },
    "Keys": {
      "Word": 4482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "箦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "bed mat; mat"
    },
    "Keys": {
      "Hanzi": 5743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5743,
      "Radical": "竹",
      "FrequencyRank": 7036,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "曩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎng",
      "PrimaryDefinition": "in ancient times, of old, former"
    },
    "Keys": {
      "Hanzi": 6453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6453,
      "Radical": "日",
      "FrequencyRank": 5888,
      "RadicalIndex": "72.17",
      "StrokeCount": 21
    }
  },
  "辖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "linchpin of wheel; control"
    },
    "Keys": {
      "Hanzi": 3096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.1",
      "GeneralStandard": 3096,
      "Radical": "車",
      "HSK": 8,
      "FrequencyRank": 1643,
      "StrokeCount": 14
    }
  },
  "真诚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnchéng",
      "PrimaryDefinition": "sincere, genuine, true"
    },
    "Keys": {
      "Word": 4200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "矿藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàngcáng",
      "PrimaryDefinition": "mineral resources"
    },
    "Keys": {
      "Word": 7891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēshòu",
      "PrimaryDefinition": "receive (honors, have, receive, acknowledge, sweep up, espouse, etc.), come to grips, adopt, embrace, greet, welcome, hook on, seize on, take up, accede, taken, entertain, latch on, buy, fasten on, swallow, imbibe, accept, receive (honors/etc.), undergo, get to grips, take, adapt, acceptance, thole"
    },
    "Keys": {
      "Word": 777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "翥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "to soar; to take off"
    },
    "Keys": {
      "Hanzi": 5636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5636,
      "Radical": "羽",
      "FrequencyRank": 6123,
      "RadicalIndex": "124.9",
      "StrokeCount": 14
    }
  },
  "筼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "tall bamboo"
    },
    "Keys": {
      "Hanzi": 7543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7543,
      "Radical": "竹",
      "FrequencyRank": 7752,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "境内": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngnèi",
      "PrimaryDefinition": "within the borders, internal (to a country, province, city etc), domestic"
    },
    "Keys": {
      "Word": 7653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "stallion; promote"
    },
    "Keys": {
      "Hanzi": 5319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5319,
      "Radical": "馬",
      "FrequencyRank": 6077,
      "RadicalIndex": "187.9",
      "StrokeCount": 12
    }
  },
  "曼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "long, extended, vast; beautiful"
    },
    "Keys": {
      "Hanzi": 2268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2268,
      "Radical": "日",
      "FrequencyRank": 1224,
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "干活儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gànhuór5",
      "PrimaryDefinition": "to work, manual labor"
    },
    "Keys": {
      "Word": 673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "出土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū tǔ",
      "PrimaryDefinition": "be unearthed, come out of the ground"
    },
    "Keys": {
      "Word": 5970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "circle, surround; encircle"
    },
    "Keys": {
      "Hanzi": 6110
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6110,
      "Radical": "囗",
      "FrequencyRank": 5244,
      "RadicalIndex": "31.13",
      "StrokeCount": 16
    }
  },
  "漷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒ",
      "PrimaryDefinition": "river in Hebei province"
    },
    "Keys": {
      "Hanzi": 7594
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7594,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 13
    }
  },
  "言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "words, speech; speak, say"
    },
    "Keys": {
      "Hanzi": 826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149",
      "GeneralStandard": 826,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 355,
      "StrokeCount": 7
    }
  },
  "质量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìliàng",
      "PrimaryDefinition": "weight, molar, mass, quantity, quality"
    },
    "Keys": {
      "Word": 3176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "罚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "penalty, fine; punish, penalize"
    },
    "Keys": {
      "Hanzi": 1519,
      "Word": 3446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "122.4",
      "Grade": 5,
      "GeneralStandard": 1519,
      "Radical": "网",
      "HSK": 5,
      "FrequencyRank": 1215,
      "StrokeCount": 9
    }
  },
  "爽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǎng",
      "PrimaryDefinition": "happy, cheerful; refreshing"
    },
    "Keys": {
      "Hanzi": 2244,
      "Word": 5042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "89.7",
      "Grade": 6,
      "GeneralStandard": 2244,
      "Radical": "爻",
      "HSK": 6,
      "FrequencyRank": 2378,
      "StrokeCount": 11
    }
  },
  "确": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "sure, certain; real, true"
    },
    "Keys": {
      "Hanzi": 2591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.7",
      "GeneralStandard": 2591,
      "Radical": "石",
      "HSK": 2,
      "FrequencyRank": 331,
      "StrokeCount": 12
    }
  },
  "莶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "vine"
    },
    "Keys": {
      "Hanzi": 6953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6953,
      "Radical": "艸",
      "FrequencyRank": 6286,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "蔬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "vegetables, greens"
    },
    "Keys": {
      "Hanzi": 3211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.11",
      "GeneralStandard": 3211,
      "Radical": "艸",
      "HSK": 5,
      "FrequencyRank": 2721,
      "StrokeCount": 15
    }
  },
  "得不偿失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "débùchángshī",
      "PrimaryDefinition": "the gains do not make up for the losses (idiom)"
    },
    "Keys": {
      "Word": 6207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "目标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùbiāo",
      "PrimaryDefinition": "polestar, objective, goal, bourn, object, meaning, aim, end point, target, cause, landmark, butt, lodestar, bull's eye, mark, tee, cynosure, end, bell ringer, loadstar, quaesitum, destination"
    },
    "Keys": {
      "Word": 1753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "成绩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngjì",
      "PrimaryDefinition": "execution, carrying into action, achievement, result, showing, carrying out, performance, grade, success, doing, batting average"
    },
    "Keys": {
      "Word": 558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "椴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": "poplar, aspen"
    },
    "Keys": {
      "Hanzi": 5398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5398,
      "Radical": "木",
      "FrequencyRank": 5472,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "广大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎngdà",
      "PrimaryDefinition": "large, wide, vast, ample, large-scale, numerous, wide-spread"
    },
    "Keys": {
      "Word": 1529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "机票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīpiào",
      "PrimaryDefinition": "air ticket, passenger ticket, CL:張|张[zhang1]"
    },
    "Keys": {
      "Word": 161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "站台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàntái",
      "PrimaryDefinition": "platform (at a railway station)"
    },
    "Keys": {
      "Word": 5324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "拟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǐ",
      "PrimaryDefinition": "draft; intend, plan, propose"
    },
    "Keys": {
      "Hanzi": 657,
      "Word": 8406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 657,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1541,
      "StrokeCount": 7
    }
  },
  "跳舞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiào wǔ",
      "PrimaryDefinition": "hop, trip the light fantastic, trip, cakewalk, dancing, dance, saltate, hoof, step"
    },
    "Keys": {
      "Word": 1968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "陲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": "frontier, border"
    },
    "Keys": {
      "Hanzi": 4686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4686,
      "Radical": "阜",
      "FrequencyRank": 4804,
      "RadicalIndex": "170.8",
      "StrokeCount": 10
    }
  },
  "哪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎ",
      "PrimaryDefinition": "which? where? how?"
    },
    "Keys": {
      "Hanzi": 1515,
      "Word": 2753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "Grade": 4,
      "GeneralStandard": 1515,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 652,
      "StrokeCount": 9
    }
  },
  "钽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": "tantalum"
    },
    "Keys": {
      "Hanzi": 4533
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4533,
      "Radical": "金",
      "FrequencyRank": 6162,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "塌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "fall in ruins, collapse"
    },
    "Keys": {
      "Hanzi": 2836,
      "Word": 9388
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.1",
      "Grade": 7,
      "GeneralStandard": 2836,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2465,
      "StrokeCount": 13
    }
  },
  "祖宗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔzong",
      "PrimaryDefinition": "ancestor, forebear"
    },
    "Keys": {
      "Word": 11011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "等于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děngyú",
      "PrimaryDefinition": "be equal to, tantamount to, equal, be equal, amount, reach, be, be equivalent to"
    },
    "Keys": {
      "Word": 617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "貂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "marten, sable, mink"
    },
    "Keys": {
      "Hanzi": 5226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5226,
      "Radical": "豸",
      "FrequencyRank": 4306,
      "RadicalIndex": "153.5",
      "StrokeCount": 12
    }
  },
  "奋勇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènyǒng",
      "PrimaryDefinition": "dauntless, to summon up courage and determination, using extreme force of will"
    },
    "Keys": {
      "Word": 6556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "少女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàonǚ",
      "PrimaryDefinition": "signorina, tomato, puss, teens, Gill, bobbysocker, gill, trick, nymph, chick, pussy, missy, maiden, wench, bobbysoxer, demoiselle, girlish, damsel, girl, sheila, miss, jeune fille, squab, wren, pigeon, colleen, maid, burd, bud, lassie, virgin, girlishness, number, gal, skirt, fille, teenager, quean, lass, quail, shrimp, young girl, maidish"
    },
    "Keys": {
      "Word": 9002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "evil spirit; evil influence"
    },
    "Keys": {
      "Hanzi": 2136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2136,
      "Radical": "示",
      "FrequencyRank": 3414,
      "RadicalIndex": "113.5",
      "StrokeCount": 10
    }
  },
  "亲热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnrè",
      "PrimaryDefinition": "affectionate, intimate, warmhearted, to get intimate with sb"
    },
    "Keys": {
      "Word": 8723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瀹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "to boil; to wash, to cleanse, to soak"
    },
    "Keys": {
      "Hanzi": 8067
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8067,
      "Radical": "水",
      "FrequencyRank": 7238,
      "RadicalIndex": "85.17",
      "StrokeCount": 20
    }
  },
  "大学生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxuéshēng",
      "PrimaryDefinition": "man, university/college student, college man, university student, colleger, university, undergrad, undergraduate, college boy, college student, collegian, university_student, undergraduate student, geeks"
    },
    "Keys": {
      "Word": 63
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "踮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn",
      "PrimaryDefinition": "tip toe"
    },
    "Keys": {
      "Hanzi": 5916
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5916,
      "Radical": "足",
      "FrequencyRank": 4661,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "禘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "imperial ancestor worship"
    },
    "Keys": {
      "Hanzi": 7606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7606,
      "Radical": "示",
      "FrequencyRank": 7366,
      "RadicalIndex": "113.9",
      "StrokeCount": 13
    }
  },
  "充电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng diàn",
      "PrimaryDefinition": "charge, electricize, refresh"
    },
    "Keys": {
      "Word": 2325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "暴风雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàofēngyǔ",
      "PrimaryDefinition": "rainstorm, storm, tempest"
    },
    "Keys": {
      "Word": 4303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "消防": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāofáng",
      "PrimaryDefinition": "fight/prevent fire"
    },
    "Keys": {
      "Word": 4053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "茼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "Chrysanthemum coronarium"
    },
    "Keys": {
      "Hanzi": 4114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4114,
      "Radical": "艸",
      "FrequencyRank": 7098,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "钗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāi",
      "PrimaryDefinition": "ornamental hairpin"
    },
    "Keys": {
      "Hanzi": 3944
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3944,
      "Radical": "金",
      "FrequencyRank": 3631,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "洪亮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngliàng",
      "PrimaryDefinition": "loud and clear, resonant"
    },
    "Keys": {
      "Word": 7086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rùn",
      "PrimaryDefinition": "intercalary; extra, surplus"
    },
    "Keys": {
      "Hanzi": 845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 845,
      "Radical": "門",
      "FrequencyRank": 4183,
      "RadicalIndex": "169.4",
      "StrokeCount": 7
    }
  },
  "桯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng",
      "PrimaryDefinition": "table"
    },
    "Keys": {
      "Hanzi": 7139
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7139,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "无精打采": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wújīng-dǎcǎi",
      "PrimaryDefinition": "dispirited and downcast (idiom), listless, in low spirits, washed out"
    },
    "Keys": {
      "Word": 9771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冷落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngluò",
      "PrimaryDefinition": "desolate, unfrequented, to treat sb coldly, to snub, to cold shoulder"
    },
    "Keys": {
      "Word": 7968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镶嵌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngqiàn",
      "PrimaryDefinition": "to inlay, to embed, to set (e.g. a jewel in a ring), tiling, tesselation"
    },
    "Keys": {
      "Word": 9938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "縠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "crepe"
    },
    "Keys": {
      "Hanzi": 6043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6043,
      "Radical": "糸",
      "FrequencyRank": 6778,
      "RadicalIndex": "120.1",
      "StrokeCount": 16
    }
  },
  "查明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chámíng",
      "PrimaryDefinition": "to investigate and find out, to ascertain"
    },
    "Keys": {
      "Word": 5790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "pods of leguminous plants; pods"
    },
    "Keys": {
      "Hanzi": 4109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4109,
      "Radical": "艸",
      "FrequencyRank": 4119,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "酗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "drunk, to become violent under the influence of alcohol"
    },
    "Keys": {
      "Hanzi": 2237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "164.4",
      "GeneralStandard": 2237,
      "Radical": "酉",
      "HSK": 7,
      "FrequencyRank": 4193,
      "StrokeCount": 11
    }
  },
  "湮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "bury, sink, block up; stain"
    },
    "Keys": {
      "Hanzi": 5279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5279,
      "Radical": "水",
      "FrequencyRank": 4059,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "出汗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū hàn",
      "PrimaryDefinition": "to perspire, to sweat"
    },
    "Keys": {
      "Word": 3343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "扩散": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòsàn",
      "PrimaryDefinition": "to spread, to proliferate, to diffuse, spread, proliferation, diffusion"
    },
    "Keys": {
      "Word": 7902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸡蛋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīdàn",
      "PrimaryDefinition": "(chicken) egg, hen's egg, CL:個|个[ge4],打[da2]"
    },
    "Keys": {
      "Word": 160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "剐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎ",
      "PrimaryDefinition": "cut, cut flesh from bones"
    },
    "Keys": {
      "Hanzi": 4216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4216,
      "Radical": "刀",
      "FrequencyRank": 4794,
      "RadicalIndex": "18.7",
      "StrokeCount": 9
    }
  },
  "见到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàndào",
      "PrimaryDefinition": "see, meet, perceive"
    },
    "Keys": {
      "Word": 760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "不见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjiàn",
      "PrimaryDefinition": "not to see, not to meet, to have disappeared, to be missing"
    },
    "Keys": {
      "Word": 4344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "争取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngqǔ",
      "PrimaryDefinition": "strive for, fight for, wrangle, work hard for, contest, go for, strive/fight for, contend, try_for, woo, try for, strive, win over, scramble, do all one can to"
    },
    "Keys": {
      "Word": 2139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "账户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànghù",
      "PrimaryDefinition": "bank account, online account"
    },
    "Keys": {
      "Word": 5328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "果树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒshù",
      "PrimaryDefinition": "fruit tree, CL:棵[ke1]"
    },
    "Keys": {
      "Word": 4642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "菔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "turnip"
    },
    "Keys": {
      "Hanzi": 4758
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4758,
      "Radical": "艸",
      "FrequencyRank": 5581,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "用来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yònglái",
      "PrimaryDefinition": "in order to, so as to"
    },
    "Keys": {
      "Word": 4131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "云": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "say, speak; clouds"
    },
    "Keys": {
      "Hanzi": 82,
      "Word": 1190
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7.2",
      "Grade": 2,
      "GeneralStandard": 82,
      "Radical": "二",
      "HSK": 2,
      "FrequencyRank": 692,
      "StrokeCount": 4
    }
  },
  "卓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "profound, brilliant, lofty"
    },
    "Keys": {
      "Hanzi": 1070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.6",
      "GeneralStandard": 1070,
      "Radical": "十",
      "HSK": 8,
      "FrequencyRank": 1942,
      "StrokeCount": 8
    }
  },
  "斐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "graceful, elegant, beautiful"
    },
    "Keys": {
      "Hanzi": 5122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5122,
      "Radical": "文",
      "FrequencyRank": 2972,
      "RadicalIndex": "67.8",
      "StrokeCount": 12
    }
  },
  "代号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàihào",
      "PrimaryDefinition": "code name"
    },
    "Keys": {
      "Word": 6144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䅟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎn",
      "PrimaryDefinition": "(simplified form of 穇) varieties of millet; panicled millet, ear of grain producing no fruit, short grains"
    },
    "Keys": {
      "Hanzi": 7540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7540,
      "Radical": "禾",
      "RadicalIndex": "115.8",
      "StrokeCount": 13
    }
  },
  "吓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià",
      "PrimaryDefinition": "scare, frighten; intimidate"
    },
    "Keys": {
      "Hanzi": 422,
      "Word": 4029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 5,
      "GeneralStandard": 422,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1466,
      "StrokeCount": 6
    }
  },
  "掞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "easy, smooth; quiet; suave"
    },
    "Keys": {
      "Hanzi": 7115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7115,
      "Radical": "手",
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "衬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèn",
      "PrimaryDefinition": "underwear; inner garments"
    },
    "Keys": {
      "Hanzi": 1292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.3",
      "GeneralStandard": 1292,
      "Radical": "衣",
      "HSK": 3,
      "FrequencyRank": 2502,
      "StrokeCount": 8
    }
  },
  "详尽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiángjìn",
      "PrimaryDefinition": "thorough and detailed, exhaustive, the tedious details in full"
    },
    "Keys": {
      "Word": 9940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "triumphant; triumph, victory"
    },
    "Keys": {
      "Hanzi": 1112
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "16.6",
      "GeneralStandard": 1112,
      "Radical": "几",
      "HSK": 9,
      "FrequencyRank": 1447,
      "StrokeCount": 8
    }
  },
  "场地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngdì",
      "PrimaryDefinition": "grounds, site, ground, yard, precinct, space, course, place, park, property"
    },
    "Keys": {
      "Word": 4394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "钱包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánbāo",
      "PrimaryDefinition": "purse, wallet"
    },
    "Keys": {
      "Word": 291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "泻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "drain off, leak; flow, pour down"
    },
    "Keys": {
      "Hanzi": 1255,
      "Word": 9999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 7,
      "GeneralStandard": 1255,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2715,
      "StrokeCount": 8
    }
  },
  "佾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "a row or file of dancers"
    },
    "Keys": {
      "Hanzi": 3962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3962,
      "Radical": "人",
      "FrequencyRank": 6518,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "器官": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìguān",
      "PrimaryDefinition": "appendage, member, variety meat, penis, apparatus, organs, organ, transplant, phallus, extremity"
    },
    "Keys": {
      "Word": 2797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "片段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piànduàn",
      "PrimaryDefinition": "part, extract, fragment"
    },
    "Keys": {
      "Word": 8527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纠正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiūzhèng",
      "PrimaryDefinition": "correct, redress"
    },
    "Keys": {
      "Word": 4755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "公墓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngmù",
      "PrimaryDefinition": "public cemetery"
    },
    "Keys": {
      "Word": 6768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "a border; a limit; a dike; a frontier; a boundary"
    },
    "Keys": {
      "Hanzi": 4722
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4722,
      "Radical": "土",
      "FrequencyRank": 6702,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "壮丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuànglì",
      "PrimaryDefinition": "magnificence, magnificent, majestic, glorious"
    },
    "Keys": {
      "Word": 10910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苍蝇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāngying",
      "PrimaryDefinition": "housefly, CL:隻|只[zhi1]"
    },
    "Keys": {
      "Word": 5763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "inkstone"
    },
    "Keys": {
      "Hanzi": 1453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1453,
      "Radical": "石",
      "FrequencyRank": 4121,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "除外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúwài",
      "PrimaryDefinition": "to exclude, not including sth (when counting or listing), except for"
    },
    "Keys": {
      "Word": 5985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "魑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "a mountain demon resembling a tiger"
    },
    "Keys": {
      "Hanzi": 6401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6401,
      "Radical": "鬼",
      "FrequencyRank": 4792,
      "RadicalIndex": "194.11",
      "StrokeCount": 19
    }
  },
  "害羞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài xiū",
      "PrimaryDefinition": "be shy, bashful, coy, be bashful, shy, blush"
    },
    "Keys": {
      "Word": 6977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6826,
      "Radical": "日",
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "侉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎ",
      "PrimaryDefinition": "to speak with an accent; big and clumsy"
    },
    "Keys": {
      "Hanzi": 3954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3954,
      "Radical": "人",
      "FrequencyRank": 6199,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "碾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎn",
      "PrimaryDefinition": "roller, crush; roll"
    },
    "Keys": {
      "Hanzi": 3228
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3228,
      "Radical": "石",
      "FrequencyRank": 3684,
      "RadicalIndex": "112.1",
      "StrokeCount": 15
    }
  },
  "移民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yímín",
      "PrimaryDefinition": "emigrant/immigrant"
    },
    "Keys": {
      "Word": 3093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "盈利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínglì",
      "PrimaryDefinition": "profit, gain"
    },
    "Keys": {
      "Word": 10417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": "fall, sink, let fall; degenerate"
    },
    "Keys": {
      "Hanzi": 2475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.9",
      "GeneralStandard": 2475,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2666,
      "StrokeCount": 11
    }
  },
  "掣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "drag, pull; hinder by pulling back"
    },
    "Keys": {
      "Hanzi": 5191
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5191,
      "Radical": "手",
      "FrequencyRank": 3596,
      "RadicalIndex": "64.8",
      "StrokeCount": 12
    }
  },
  "患者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànzhě",
      "PrimaryDefinition": "patient, subject, sufferer"
    },
    "Keys": {
      "Word": 4678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "居高临下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūgāo-línxià",
      "PrimaryDefinition": "to live high and look down (idiom), to overlook, to tower above, to occupy the high ground, fig. arrogance based on one's social position"
    },
    "Keys": {
      "Word": 7678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "联系": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánxì",
      "PrimaryDefinition": "integrate, relate, link, get in touch with"
    },
    "Keys": {
      "Word": 1708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐ",
      "PrimaryDefinition": "body; group, class, body, unit; inferior"
    },
    "Keys": {
      "Hanzi": 770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 770,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 149,
      "StrokeCount": 7
    }
  },
  "凭证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngzhèng",
      "PrimaryDefinition": "proof, certificate, receipt, voucher"
    },
    "Keys": {
      "Word": 8561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耐性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàixìng",
      "PrimaryDefinition": "patience, endurance"
    },
    "Keys": {
      "Word": 8372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海藻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎizǎo",
      "PrimaryDefinition": "seaweed, marine alga, kelp"
    },
    "Keys": {
      "Word": 6973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "minced meat or fish"
    },
    "Keys": {
      "Hanzi": 4593
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4593,
      "Radical": "肉",
      "FrequencyRank": 5101,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "急转弯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jízhuǎnwān",
      "PrimaryDefinition": "to make a sudden turn"
    },
    "Keys": {
      "Word": 7306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无偿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúcháng",
      "PrimaryDefinition": "free, no charge, at no cost"
    },
    "Keys": {
      "Word": 9761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "surname; ancient state"
    },
    "Keys": {
      "Hanzi": 1378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "156.2",
      "GeneralStandard": 1378,
      "Radical": "走",
      "HSK": 9,
      "FrequencyRank": 1169,
      "StrokeCount": 9
    }
  },
  "百合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎihé",
      "PrimaryDefinition": "lily"
    },
    "Keys": {
      "Word": 5475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎng",
      "PrimaryDefinition": "bind, tie, fasten"
    },
    "Keys": {
      "Hanzi": 1752,
      "Word": 5505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "Grade": 7,
      "GeneralStandard": 1752,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 2421,
      "StrokeCount": 9
    }
  },
  "经验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngyàn",
      "PrimaryDefinition": "experience"
    },
    "Keys": {
      "Word": 1638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "黹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "embroidery"
    },
    "Keys": {
      "Radical": 204,
      "Hanzi": 7348
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7348,
      "Radical": "黹",
      "FrequencyRank": 6783,
      "RadicalIndex": "204",
      "StrokeCount": 12
    }
  },
  "如一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúyī",
      "PrimaryDefinition": "As one"
    },
    "Keys": {
      "Word": 4978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "芷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "angelica, type of iris"
    },
    "Keys": {
      "Hanzi": 3665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3665,
      "Radical": "艸",
      "FrequencyRank": 4466,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "碥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7665,
      "Radical": "石",
      "FrequencyRank": 6363,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "累": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèi",
      "PrimaryDefinition": "tired; implicate, involve; bother"
    },
    "Keys": {
      "Hanzi": 2282,
      "Word": 208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 1,
      "GeneralStandard": 2282,
      "Radical": "糸",
      "HSK": 1,
      "FrequencyRank": 1323,
      "StrokeCount": 11
    }
  },
  "甯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "peaceful"
    },
    "Keys": {
      "Hanzi": 7442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7442,
      "Radical": "用",
      "FrequencyRank": 5935,
      "RadicalIndex": "101.7",
      "StrokeCount": 12
    }
  },
  "过硬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòyìng",
      "PrimaryDefinition": "to have perfect mastery of sth, to be up to the mark"
    },
    "Keys": {
      "Word": 6958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夫妻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fūqī",
      "PrimaryDefinition": "couple, spouse, pair, man and wife, husband and wife"
    },
    "Keys": {
      "Word": 2475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "公司": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngsī",
      "PrimaryDefinition": "place of business, business organization, incorporation, establishment, business concern, office, concern, business establishment, business, enterprise, company, corporation"
    },
    "Keys": {
      "Word": 689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "楼下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóuxià",
      "PrimaryDefinition": "downstairs"
    },
    "Keys": {
      "Word": 217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "高原": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoyuán",
      "PrimaryDefinition": "plateau, CL:片[pian4]"
    },
    "Keys": {
      "Word": 3487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "偶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒu",
      "PrimaryDefinition": "accidentally, coincidently; an idol"
    },
    "Keys": {
      "Hanzi": 2328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "GeneralStandard": 2328,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1361,
      "StrokeCount": 11
    }
  },
  "男士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánshì",
      "PrimaryDefinition": "gent, man, men, menfolk"
    },
    "Keys": {
      "Word": 2755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "遗物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíwù",
      "PrimaryDefinition": "hangover, legacy, survival, memento, survivor, things left behind by the deceased, holdover, souvenir, keepsake, reliquiae, things left by deceased, remnant"
    },
    "Keys": {
      "Word": 10305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "儦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "milling"
    },
    "Keys": {
      "Hanzi": 7954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7954,
      "Radical": "人",
      "RadicalIndex": "9.15",
      "StrokeCount": 17
    }
  },
  "掏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "take out; pull out; clean out"
    },
    "Keys": {
      "Hanzi": 2180,
      "Word": 5070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 6,
      "GeneralStandard": 2180,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2218,
      "StrokeCount": 11
    }
  },
  "晟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "clear, bright; splendor, brightness"
    },
    "Keys": {
      "Hanzi": 4495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4495,
      "Radical": "日",
      "FrequencyRank": 5488,
      "RadicalIndex": "72.7",
      "StrokeCount": 10
    }
  },
  "协同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiétóng",
      "PrimaryDefinition": "to cooperate, in coordination with, coordinated, collaborate, collaboration, collaborative"
    },
    "Keys": {
      "Word": 9986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "mynah; Erithacus species (various)"
    },
    "Keys": {
      "Hanzi": 4600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4600,
      "Radical": "鳥",
      "FrequencyRank": 6062,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "职权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíquán",
      "PrimaryDefinition": "authority, power over others"
    },
    "Keys": {
      "Word": 10764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当事人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngshìrén",
      "PrimaryDefinition": "interested parties, those directly involved, principal, client, the party concerned, privy, party, litigant, person concerned"
    },
    "Keys": {
      "Word": 6176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "附": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "adhere to, append; rely on near to"
    },
    "Keys": {
      "Hanzi": 916,
      "Word": 6626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.5",
      "Grade": 7,
      "GeneralStandard": 916,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 923,
      "StrokeCount": 7
    }
  },
  "面对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànduì",
      "PrimaryDefinition": "face up, stand up, face, look_out_on, confront, front, affront, envisage, accost, breast"
    },
    "Keys": {
      "Word": 1743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "吃惊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī jīng",
      "PrimaryDefinition": "to be startled, to be shocked, to be amazed"
    },
    "Keys": {
      "Word": 2319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "看管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kānguǎn",
      "PrimaryDefinition": "to look after"
    },
    "Keys": {
      "Word": 4786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "觇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chān",
      "PrimaryDefinition": "peek; spy on, watch; investigate"
    },
    "Keys": {
      "Hanzi": 4185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4185,
      "Radical": "見",
      "FrequencyRank": 6409,
      "RadicalIndex": "147.5",
      "StrokeCount": 9
    }
  },
  "深": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "deep; depth; far; very, extreme"
    },
    "Keys": {
      "Hanzi": 2432,
      "Word": 1880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 3,
      "GeneralStandard": 2432,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 401,
      "StrokeCount": 11
    }
  },
  "穷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "poor, destitute, impoverished"
    },
    "Keys": {
      "Hanzi": 882,
      "Word": 2811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.2",
      "Grade": 4,
      "GeneralStandard": 882,
      "Radical": "穴",
      "HSK": 4,
      "FrequencyRank": 1343,
      "StrokeCount": 7
    }
  },
  "贞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "virtuous, chaste, pure; loyal"
    },
    "Keys": {
      "Hanzi": 412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "25.4",
      "GeneralStandard": 412,
      "Radical": "卜",
      "HSK": 9,
      "FrequencyRank": 2366,
      "StrokeCount": 6
    }
  },
  "鳛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "the weather or dojo loach, Misgurnus anguillicaudatus"
    },
    "Keys": {
      "Hanzi": 8045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8045,
      "Radical": "魚",
      "FrequencyRank": 8204,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "宣称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānchēng",
      "PrimaryDefinition": "predicate, enounce, vouch, avow, make_out, lay_down, uphold, assert, declaration, make out, swan, pronounce, aver, swear, assertion, proclaim, certify, give_out, profession, declare, affirm, put forward, profess"
    },
    "Keys": {
      "Word": 10105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 778,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 821,
      "StrokeCount": 7
    }
  },
  "沛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "abundant, full, copious; sudden"
    },
    "Keys": {
      "Hanzi": 857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 857,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3032,
      "StrokeCount": 7
    }
  },
  "慝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tè",
      "PrimaryDefinition": "do evil in secret; evil, vice"
    },
    "Keys": {
      "Hanzi": 5626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5626,
      "Radical": "心",
      "FrequencyRank": 6523,
      "RadicalIndex": "61.11",
      "StrokeCount": 14
    }
  },
  "城里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénglǐ",
      "PrimaryDefinition": "In the city"
    },
    "Keys": {
      "Word": 3327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "较劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào jìn",
      "PrimaryDefinition": "to match one's strength with, to compete, more competitive, to set oneself against sb, disobliging, to make a special effort"
    },
    "Keys": {
      "Word": 7490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "省钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěngqián",
      "PrimaryDefinition": "to save money"
    },
    "Keys": {
      "Word": 5011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "非法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēifǎ",
      "PrimaryDefinition": "illegal, wrongfully, wrongful, unlawful, illegitimately, illicitly, unlawfully, lawlessly, nefariously, nefarious, illegally, illegitimate, lawless, illicit"
    },
    "Keys": {
      "Word": 6514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毁坏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǐhuài",
      "PrimaryDefinition": "to damage, to devastate, to vandalize, damage, destruction"
    },
    "Keys": {
      "Word": 7214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "注射": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùshè",
      "PrimaryDefinition": "inject"
    },
    "Keys": {
      "Word": 4257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "皮带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pídài",
      "PrimaryDefinition": "strap, leather belt, CL:條|条[tiao2],根[gen1]"
    },
    "Keys": {
      "Word": 8506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "be burned, to char (in cooking)"
    },
    "Keys": {
      "Hanzi": 5559
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5559,
      "Radical": "火",
      "FrequencyRank": 5818,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "蔬菜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūcài",
      "PrimaryDefinition": "vegetal, kale, greenstuff, veggie, greengrocery, kail, greens, vegetables, truck, veg, sass, vegetable, vegetative"
    },
    "Keys": {
      "Word": 3925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "学位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéwèi",
      "PrimaryDefinition": "academic_degree, degree, academic degree"
    },
    "Keys": {
      "Word": 4079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鹒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēng",
      "PrimaryDefinition": "oriole"
    },
    "Keys": {
      "Hanzi": 7575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7575,
      "Radical": "鳥",
      "FrequencyRank": 8915,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "趑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5353,
      "Radical": "走",
      "FrequencyRank": 6413,
      "RadicalIndex": "156.6",
      "StrokeCount": 13
    }
  },
  "饹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "le",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4301,
      "Radical": "食",
      "FrequencyRank": 6819,
      "RadicalIndex": "184.6",
      "StrokeCount": 9
    }
  },
  "作者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòzhě",
      "PrimaryDefinition": "composer, author, writer, wright, paragrapher, auteur, paternity"
    },
    "Keys": {
      "Word": 2226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "端": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duān",
      "PrimaryDefinition": "end, extreme; head; beginning"
    },
    "Keys": {
      "Hanzi": 3151,
      "Word": 4520
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.9",
      "Grade": 6,
      "GeneralStandard": 3151,
      "Radical": "立",
      "HSK": 6,
      "FrequencyRank": 916,
      "StrokeCount": 14
    }
  },
  "侔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "equal"
    },
    "Keys": {
      "Hanzi": 3969
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3969,
      "Radical": "人",
      "FrequencyRank": 5769,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "灼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "burn; broil; cauterize; bright"
    },
    "Keys": {
      "Hanzi": 853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.3",
      "GeneralStandard": 853,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 2867,
      "StrokeCount": 7
    }
  },
  "拂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "shake off, brush away; dust"
    },
    "Keys": {
      "Hanzi": 986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 986,
      "Radical": "手",
      "FrequencyRank": 2623,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "姽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "good"
    },
    "Keys": {
      "Hanzi": 6915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6915,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "标语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāoyǔ",
      "PrimaryDefinition": "written slogan, placard, CL:幅[fu2],張|张[zhang1],條|条[tiao2]"
    },
    "Keys": {
      "Word": 5627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": "kind of thick stiff silk"
    },
    "Keys": {
      "Hanzi": 2150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2150,
      "Radical": "糸",
      "FrequencyRank": 2385,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "珛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6927,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "随时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíshí",
      "PrimaryDefinition": "whenever, whene'er, always, readily, whenever necessary, at all times, as occasion demands, at any time"
    },
    "Keys": {
      "Word": 1001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "具有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùyǒu",
      "PrimaryDefinition": "be provided with, have, have got, possess, hold, possession, carry, inhere, bear"
    },
    "Keys": {
      "Word": 1653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "信赖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnlài",
      "PrimaryDefinition": "trust, count on, have faith in"
    },
    "Keys": {
      "Word": 10038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēi",
      "PrimaryDefinition": "unstrained spirits"
    },
    "Keys": {
      "Hanzi": 5891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5891,
      "Radical": "酉",
      "FrequencyRank": 7092,
      "RadicalIndex": "164.8",
      "StrokeCount": 15
    }
  },
  "天文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānwén",
      "PrimaryDefinition": "astronomy"
    },
    "Keys": {
      "Word": 3979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "听写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīngxiě",
      "PrimaryDefinition": "dictate, dictation, CL:次[ci4]"
    },
    "Keys": {
      "Word": 360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "殖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "breed, spawn; increase; prosper"
    },
    "Keys": {
      "Hanzi": 2594
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "78.8",
      "GeneralStandard": 2594,
      "Radical": "歹",
      "HSK": 6,
      "FrequencyRank": 1438,
      "StrokeCount": 12
    }
  },
  "融洽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngqià",
      "PrimaryDefinition": "harmonious, friendly relations, on good terms with one another"
    },
    "Keys": {
      "Word": 8890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "釐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "manage, control; 1/1000 of a foot"
    },
    "Keys": {
      "Hanzi": 7982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7982,
      "Radical": "里",
      "FrequencyRank": 6670,
      "RadicalIndex": "166.11",
      "StrokeCount": 18
    }
  },
  "风险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngxiǎn",
      "PrimaryDefinition": "peril, danger, risk, hazard"
    },
    "Keys": {
      "Word": 1457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "忧愁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuchóu",
      "PrimaryDefinition": "to be worried"
    },
    "Keys": {
      "Word": 10452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "府": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "prefecture; prefect; government"
    },
    "Keys": {
      "Hanzi": 1211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.5",
      "GeneralStandard": 1211,
      "Radical": "广",
      "HSK": 4,
      "FrequencyRank": 417,
      "StrokeCount": 8
    }
  },
  "上游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngyóu",
      "PrimaryDefinition": "upper reaches of river, advanced position"
    },
    "Keys": {
      "Word": 8986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "唬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǔ",
      "PrimaryDefinition": "to intimidate; to scare"
    },
    "Keys": {
      "Hanzi": 2281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2281,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 3161,
      "StrokeCount": 11
    }
  },
  "安慰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānwèi",
      "PrimaryDefinition": "comfort, console"
    },
    "Keys": {
      "Word": 3221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "搜寻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōuxún",
      "PrimaryDefinition": "search/look for, seek"
    },
    "Keys": {
      "Word": 9340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娇惯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoguàn",
      "PrimaryDefinition": "to pamper, to coddle, to spoil"
    },
    "Keys": {
      "Word": 7467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "beast, animal; bestial"
    },
    "Keys": {
      "Hanzi": 2407
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "12.9",
      "GeneralStandard": 2407,
      "Radical": "八",
      "HSK": 7,
      "FrequencyRank": 1602,
      "StrokeCount": 11
    }
  },
  "䣘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "(ancient form of 堂) a hall, an office; a reception room, a court of justice"
    },
    "Keys": {
      "Hanzi": 7516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7516,
      "Radical": "邑",
      "RadicalIndex": "163.11",
      "StrokeCount": 14
    }
  },
  "觉得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéde",
      "PrimaryDefinition": "imagine, guess, believe, reckon, suspect, suppose, think, listen, feel, consider, think_of, appear, conceive"
    },
    "Keys": {
      "Word": 183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "单方面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānfāngmiàn",
      "PrimaryDefinition": "unilateral"
    },
    "Keys": {
      "Word": 6160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "努力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǔlì",
      "PrimaryDefinition": "make great effort, try hard"
    },
    "Keys": {
      "Word": 895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "籼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "non-glutinous long grain rice"
    },
    "Keys": {
      "Hanzi": 4322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4322,
      "Radical": "米",
      "FrequencyRank": 5925,
      "RadicalIndex": "119.3",
      "StrokeCount": 9
    }
  },
  "烝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "rise, steam; many, numerous"
    },
    "Keys": {
      "Hanzi": 7086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7086,
      "Radical": "火",
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "藤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "rattan, cane; creeper plant"
    },
    "Keys": {
      "Hanzi": 3439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.15",
      "GeneralStandard": 3439,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2200,
      "StrokeCount": 18
    }
  },
  "航班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángbān",
      "PrimaryDefinition": "scheduled flight, flight number, plane, scheduled sailing, sailing number, passenger ship"
    },
    "Keys": {
      "Word": 2535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "fabulous mythical bird"
    },
    "Keys": {
      "Hanzi": 7811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7811,
      "Radical": "鳥",
      "FrequencyRank": 6308,
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "顺序": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnxù",
      "PrimaryDefinition": "system, method, taxis, gradation, consecution, succession, order, ranking, rota, sequence, orderliness, turn"
    },
    "Keys": {
      "Word": 2902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "上场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng chǎng",
      "PrimaryDefinition": "appear on the stage, enter, appear, enter the playing court, enter the playing field, enter the stage, appear on stage"
    },
    "Keys": {
      "Word": 8970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喜怒哀乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ-nù-āi-lè",
      "PrimaryDefinition": "four types of human emotions, namely: happiness 歡喜|欢喜[huan1 xi3], anger 憤怒|愤怒[fen4 nu4], sorrow 悲哀[bei1 ai1] and joy 快樂|快乐[kuai4 le4]"
    },
    "Keys": {
      "Word": 9838
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "enemy, hate, hatred, enmity"
    },
    "Keys": {
      "Hanzi": 133,
      "Word": 5933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "Grade": 7,
      "GeneralStandard": 133,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 1536,
      "StrokeCount": 4
    }
  },
  "私人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīrén",
      "PrimaryDefinition": "one's own man, private, personal relative, personal friend/relative, personal friend, confidant"
    },
    "Keys": {
      "Word": 3939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "stand"
    },
    "Keys": {
      "Hanzi": 302,
      "Radical": 117,
      "Word": 3692
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117",
      "Grade": 5,
      "GeneralStandard": 302,
      "Radical": "立",
      "HSK": 3,
      "FrequencyRank": 197,
      "StrokeCount": 5
    }
  },
  "驺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōu",
      "PrimaryDefinition": "mounted escort; groom"
    },
    "Keys": {
      "Hanzi": 6751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6751,
      "Radical": "馬",
      "FrequencyRank": 5979,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "排放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páifàng",
      "PrimaryDefinition": "discharge, drain off (gas/etc.)"
    },
    "Keys": {
      "Word": 8455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "差别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chābié",
      "PrimaryDefinition": "disparity, contrast, difference, discrepancy, differentiation, distinction, differential"
    },
    "Keys": {
      "Word": 3301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "公共汽车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnggòng qìchē",
      "PrimaryDefinition": "bus, CL:輛|辆[liang4],班[ban1]"
    },
    "Keys": {
      "Word": 682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "个性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèxìng",
      "PrimaryDefinition": "kidney, specific property, personalism, fibre, individuation, personhood, particularity, fiber, character, individual character, self-identity, mentality, idiosyncrasy, spirit, individuality, individualism, selfhood, personality"
    },
    "Keys": {
      "Word": 1488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "scholar"
    },
    "Keys": {
      "Radical": 33,
      "Hanzi": 28
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "33",
      "GeneralStandard": 28,
      "Radical": "士",
      "HSK": 4,
      "FrequencyRank": 372,
      "StrokeCount": 3
    }
  },
  "象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "elephant; ivory; figure, image"
    },
    "Keys": {
      "Hanzi": 2361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "152.5",
      "GeneralStandard": 2361,
      "Radical": "豕",
      "HSK": 3,
      "FrequencyRank": 300,
      "StrokeCount": 11
    }
  },
  "息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "rest, put stop to, end, cease"
    },
    "Keys": {
      "Hanzi": 1973
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1973,
      "Radical": "心",
      "HSK": 1,
      "FrequencyRank": 428,
      "StrokeCount": 10
    }
  },
  "走弯路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒu wānlù",
      "PrimaryDefinition": "to take an indirect route, to waste one's time by using an inappropriate method"
    },
    "Keys": {
      "Word": 10997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火锅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒguō",
      "PrimaryDefinition": "hotpot"
    },
    "Keys": {
      "Word": 7240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焦急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojí",
      "PrimaryDefinition": "anxiety, anxious"
    },
    "Keys": {
      "Word": 7473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "善意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shànyì",
      "PrimaryDefinition": "good_faith, grace, good intention, benevolence, love, goodwill, good will"
    },
    "Keys": {
      "Word": 8955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càn",
      "PrimaryDefinition": "gems; luster of gems; lustrous"
    },
    "Keys": {
      "Hanzi": 6195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6195,
      "Radical": "玉",
      "FrequencyRank": 4638,
      "RadicalIndex": "96.13",
      "StrokeCount": 17
    }
  },
  "坚实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānshí",
      "PrimaryDefinition": "firm and substantial, solid"
    },
    "Keys": {
      "Word": 7379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7393,
      "Radical": "人",
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "鲟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "sturgeon"
    },
    "Keys": {
      "Hanzi": 5772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5772,
      "Radical": "魚",
      "FrequencyRank": 4670,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "立交桥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìjiāoqiáo",
      "PrimaryDefinition": "overpass, flyover"
    },
    "Keys": {
      "Word": 8003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "small cup or bowl"
    },
    "Keys": {
      "Hanzi": 4213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4213,
      "Radical": "皿",
      "FrequencyRank": 3998,
      "RadicalIndex": "108.4",
      "StrokeCount": 9
    }
  },
  "哉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāi",
      "PrimaryDefinition": "final exclamatory particle"
    },
    "Keys": {
      "Hanzi": 1381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1381,
      "Radical": "口",
      "FrequencyRank": 2758,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "寝室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐnshì",
      "PrimaryDefinition": "bedchamber, sleeping room, roost, chamber, dormitory, bower, lying, bedroom"
    },
    "Keys": {
      "Word": 8731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饱满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎomǎn",
      "PrimaryDefinition": "full, plump"
    },
    "Keys": {
      "Word": 5517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "簰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái",
      "PrimaryDefinition": "bamboo raft"
    },
    "Keys": {
      "Hanzi": 8009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8009,
      "Radical": "竹",
      "FrequencyRank": 7592,
      "RadicalIndex": "118.12",
      "StrokeCount": 18
    }
  },
  "抵达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐdá",
      "PrimaryDefinition": "reach, arrive at"
    },
    "Keys": {
      "Word": 4499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "莿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cì",
      "PrimaryDefinition": "thorn"
    },
    "Keys": {
      "Hanzi": 7123
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7123,
      "Radical": "艸",
      "FrequencyRank": 7973,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "棁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "joist; cane"
    },
    "Keys": {
      "Hanzi": 4777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4777,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "郯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "name of a small ancient principality which was situated in what is now part of Shantung and Kiangsu"
    },
    "Keys": {
      "Hanzi": 4642
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4642,
      "Radical": "邑",
      "FrequencyRank": 5228,
      "RadicalIndex": "163.8",
      "StrokeCount": 10
    }
  },
  "闶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāng",
      "PrimaryDefinition": "door"
    },
    "Keys": {
      "Hanzi": 6609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6609,
      "Radical": "門",
      "FrequencyRank": 4797,
      "RadicalIndex": "169.4",
      "StrokeCount": 7
    }
  },
  "碧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "jade; green, blue"
    },
    "Keys": {
      "Hanzi": 3050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.9",
      "GeneralStandard": 3050,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 2165,
      "StrokeCount": 14
    }
  },
  "香油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngyóu",
      "PrimaryDefinition": "sesame oil, perfumed oil"
    },
    "Keys": {
      "Word": 9936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "choose; select; pick up; gather"
    },
    "Keys": {
      "Hanzi": 961,
      "Word": 7401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 961,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2965,
      "StrokeCount": 8
    }
  },
  "生产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngchǎn",
      "PrimaryDefinition": "produce, manufacture"
    },
    "Keys": {
      "Word": 1882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "宬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "archives; surname"
    },
    "Keys": {
      "Hanzi": 6900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6900,
      "Radical": "宀",
      "RadicalIndex": "40.7",
      "StrokeCount": 9
    }
  },
  "橞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7845,
      "Radical": "木",
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "挝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "to beat; to strike"
    },
    "Keys": {
      "Hanzi": 4095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4095,
      "Radical": "手",
      "FrequencyRank": 3710,
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "瞵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "to stare at"
    },
    "Keys": {
      "Hanzi": 7933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7933,
      "Radical": "目",
      "FrequencyRank": 8509,
      "RadicalIndex": "109.12",
      "StrokeCount": 17
    }
  },
  "风波": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngbō",
      "PrimaryDefinition": "crisis, disturbance"
    },
    "Keys": {
      "Word": 6564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "change; easy"
    },
    "Keys": {
      "Hanzi": 1088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1088,
      "Radical": "日",
      "HSK": 3,
      "FrequencyRank": 461,
      "StrokeCount": 8
    }
  },
  "对于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìyú",
      "PrimaryDefinition": "in regard) to, toward, at, for"
    },
    "Keys": {
      "Word": 2433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "中外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngwài",
      "PrimaryDefinition": "China and foreign countries"
    },
    "Keys": {
      "Word": 5367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "相当于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngdāngyú",
      "PrimaryDefinition": "muster, be equal to, equivalent, amount"
    },
    "Keys": {
      "Word": 9920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枯燥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūzào",
      "PrimaryDefinition": "dry and dull, uninteresting, dry-as-dust"
    },
    "Keys": {
      "Word": 7855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "drink with others; drink heavily"
    },
    "Keys": {
      "Hanzi": 7655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7655,
      "Radical": "酉",
      "FrequencyRank": 6860,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "拓宽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuòkuān",
      "PrimaryDefinition": "broaden"
    },
    "Keys": {
      "Word": 9626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "困难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùnnan",
      "PrimaryDefinition": "difficult"
    },
    "Keys": {
      "Word": 1682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "急于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíyú",
      "PrimaryDefinition": "anxious, anxious to (do sth.), eager, desperate"
    },
    "Keys": {
      "Word": 7304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōnghuà",
      "PrimaryDefinition": "converse, communicate by telephone"
    },
    "Keys": {
      "Word": 5092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "单位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānwèi",
      "PrimaryDefinition": "department, unit, denomination, module, building block, unit of measurement, monad, unit (in measurement or organization)"
    },
    "Keys": {
      "Word": 598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cān",
      "PrimaryDefinition": "eat, dine; meal; food"
    },
    "Keys": {
      "Hanzi": 3341,
      "Word": 4367
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.7",
      "Grade": 6,
      "GeneralStandard": 3341,
      "Radical": "食",
      "HSK": 2,
      "FrequencyRank": 1465,
      "StrokeCount": 16
    }
  },
  "垸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "a dyke, embankment"
    },
    "Keys": {
      "Hanzi": 4424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4424,
      "Radical": "土",
      "FrequencyRank": 6677,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "坏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huài",
      "PrimaryDefinition": "rotten, spoilt, bad, broken down"
    },
    "Keys": {
      "Hanzi": 616,
      "Word": 149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 1,
      "GeneralStandard": 616,
      "Radical": "土",
      "HSK": 1,
      "FrequencyRank": 832,
      "StrokeCount": 7
    }
  },
  "偕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "together; be in order"
    },
    "Keys": {
      "Hanzi": 4906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4906,
      "Radical": "人",
      "FrequencyRank": 3705,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "减速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn sù",
      "PrimaryDefinition": "to reduce speed, to slow down, to take it easy"
    },
    "Keys": {
      "Word": 7406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "a picture, image, figure; to resemble"
    },
    "Keys": {
      "Hanzi": 2961,
      "Word": 9950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.12",
      "Grade": 7,
      "GeneralStandard": 2961,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 294,
      "StrokeCount": 13
    }
  },
  "裆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāng",
      "PrimaryDefinition": "crotch or seat of pants; pants"
    },
    "Keys": {
      "Hanzi": 4999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4999,
      "Radical": "衣",
      "FrequencyRank": 4099,
      "RadicalIndex": "145.6",
      "StrokeCount": 11
    }
  },
  "岐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "high; majestic; fork in road"
    },
    "Keys": {
      "Hanzi": 3713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3713,
      "Radical": "山",
      "FrequencyRank": 3928,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "宽容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānróng",
      "PrimaryDefinition": "lenient, tolerant, indulgent, charitable, to forgive"
    },
    "Keys": {
      "Word": 7880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阵容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènróng",
      "PrimaryDefinition": "troop arrangement, battle formation, lineup (of a sports team etc)"
    },
    "Keys": {
      "Word": 10707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "adversity, difficulty, distress"
    },
    "Keys": {
      "Hanzi": 3514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.2",
      "GeneralStandard": 3514,
      "Radical": "厂",
      "HSK": 8,
      "FrequencyRank": 2407,
      "StrokeCount": 4
    }
  },
  "分组": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn zǔ",
      "PrimaryDefinition": "divide into groups"
    },
    "Keys": {
      "Word": 1454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "协助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiézhù",
      "PrimaryDefinition": "assist, help"
    },
    "Keys": {
      "Word": 5180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "趵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "noise of tramping feet"
    },
    "Keys": {
      "Hanzi": 4504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4504,
      "Radical": "足",
      "FrequencyRank": 6118,
      "RadicalIndex": "157.3",
      "StrokeCount": 10
    }
  },
  "毫无": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háowú",
      "PrimaryDefinition": "completely lack"
    },
    "Keys": {
      "Word": 7001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拍卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāimài",
      "PrimaryDefinition": "to auction, auction sale, to sell at a reduced price"
    },
    "Keys": {
      "Word": 8453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "a reed leaf whistle"
    },
    "Keys": {
      "Hanzi": 4901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4901,
      "Radical": "竹",
      "FrequencyRank": 5219,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "学士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéshì",
      "PrimaryDefinition": "bachelor's degree, person holding a university degree"
    },
    "Keys": {
      "Word": 10132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "comet"
    },
    "Keys": {
      "Hanzi": 3683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3683,
      "Radical": "子",
      "FrequencyRank": 4394,
      "RadicalIndex": "39.4",
      "StrokeCount": 7
    }
  },
  "稼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jià",
      "PrimaryDefinition": "sow grain; sheaves of grain"
    },
    "Keys": {
      "Hanzi": 3264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.1",
      "GeneralStandard": 3264,
      "Radical": "禾",
      "HSK": 8,
      "FrequencyRank": 2800,
      "StrokeCount": 15
    }
  },
  "睨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "look askance at, glare at; squint"
    },
    "Keys": {
      "Hanzi": 5436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5436,
      "Radical": "目",
      "FrequencyRank": 4448,
      "RadicalIndex": "109.8",
      "StrokeCount": 13
    }
  },
  "世": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "generation; world; era"
    },
    "Keys": {
      "Hanzi": 206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "GeneralStandard": 206,
      "Radical": "一",
      "HSK": 3,
      "FrequencyRank": 181,
      "StrokeCount": 5
    }
  },
  "举止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔzhǐ",
      "PrimaryDefinition": "bearing, manner, mien"
    },
    "Keys": {
      "Word": 7694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīng",
      "PrimaryDefinition": "beautiful, attractive, charming, graceful"
    },
    "Keys": {
      "Hanzi": 4690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4690,
      "Radical": "女",
      "FrequencyRank": 5284,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "角色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juésè",
      "PrimaryDefinition": "theatrical role, character, persona, part, function, office, fictional character, actor, fictitious character, personage, role"
    },
    "Keys": {
      "Word": 2652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "没想到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méixiǎngdào",
      "PrimaryDefinition": "unexpectedly"
    },
    "Keys": {
      "Word": 2729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蒄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7319,
      "Radical": "艸",
      "FrequencyRank": 8973,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "储存": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔcún",
      "PrimaryDefinition": "lay in/up, store, stockpile"
    },
    "Keys": {
      "Word": 4437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "执意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíyì",
      "PrimaryDefinition": "insist on, be bent on"
    },
    "Keys": {
      "Word": 10753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒu",
      "PrimaryDefinition": "a hill in Hunan"
    },
    "Keys": {
      "Hanzi": 3928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3928,
      "Radical": "山",
      "FrequencyRank": 5933,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu",
      "PrimaryDefinition": "head; top; chief, first; boss"
    },
    "Keys": {
      "Hanzi": 310,
      "Word": 1978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.2",
      "Grade": 3,
      "GeneralStandard": 310,
      "Radical": "大",
      "HSK": 2,
      "FrequencyRank": 147,
      "StrokeCount": 5
    }
  },
  "有益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuyì",
      "PrimaryDefinition": "profit, benefit, conduce, advantageous, be helpful, conducive, subserve, avail, suit"
    },
    "Keys": {
      "Word": 10484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēsòng",
      "PrimaryDefinition": "picking up and dropping off, greeting and sending off, shuttle (transport service)"
    },
    "Keys": {
      "Word": 7510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磨损": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mósǔn",
      "PrimaryDefinition": "wear and tear, abrasion"
    },
    "Keys": {
      "Word": 8320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "憭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎo",
      "PrimaryDefinition": "clear; intelligible; severe; cold"
    },
    "Keys": {
      "Hanzi": 7822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7822,
      "Radical": "心",
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "惊慌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnghuāng",
      "PrimaryDefinition": "to panic, to be alarmed"
    },
    "Keys": {
      "Word": 7600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niān",
      "PrimaryDefinition": "withered, faded, decayed; calm"
    },
    "Keys": {
      "Hanzi": 5643
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5643,
      "Radical": "艸",
      "FrequencyRank": 4737,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "离婚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí hūn",
      "PrimaryDefinition": "divorce"
    },
    "Keys": {
      "Word": 1692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guài",
      "PrimaryDefinition": "strange, unusual, peculiar"
    },
    "Keys": {
      "Hanzi": 1271,
      "Word": 3517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "Grade": 5,
      "GeneralStandard": 1271,
      "Radical": "心",
      "HSK": 3,
      "FrequencyRank": 775,
      "StrokeCount": 8
    }
  },
  "脉搏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màibó",
      "PrimaryDefinition": "pulse (both medical and figurative)"
    },
    "Keys": {
      "Word": 8192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "hoop; bind, surround"
    },
    "Keys": {
      "Hanzi": 5745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5745,
      "Radical": "竹",
      "FrequencyRank": 3764,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "彭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "name of ancient country; surname"
    },
    "Keys": {
      "Hanzi": 2524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2524,
      "Radical": "彡",
      "FrequencyRank": 1501,
      "RadicalIndex": "59.9",
      "StrokeCount": 12
    }
  },
  "亏本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuī běn",
      "PrimaryDefinition": "to make a loss"
    },
    "Keys": {
      "Word": 7894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "袗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "unlined garments; hem of garment"
    },
    "Keys": {
      "Hanzi": 7078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7078,
      "Radical": "衣",
      "FrequencyRank": 8386,
      "RadicalIndex": "145.5",
      "StrokeCount": 10
    }
  },
  "握": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "grasp, hold fast, take by hand"
    },
    "Keys": {
      "Hanzi": 2538,
      "Word": 4011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 5,
      "GeneralStandard": 2538,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 1032,
      "StrokeCount": 12
    }
  },
  "球星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúxīng",
      "PrimaryDefinition": "sports star (ball sport)"
    },
    "Keys": {
      "Word": 4951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "走私": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒu sī",
      "PrimaryDefinition": "smuggle"
    },
    "Keys": {
      "Word": 5404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "恰巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàqiǎo",
      "PrimaryDefinition": "fortunately, unexpectedly, by coincidence"
    },
    "Keys": {
      "Word": 8643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngděng",
      "PrimaryDefinition": "equal, equally, equivalent"
    },
    "Keys": {
      "Word": 4040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "陪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péi",
      "PrimaryDefinition": "accompany, be with, keep company"
    },
    "Keys": {
      "Hanzi": 2139,
      "Word": 3759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.8",
      "Grade": 5,
      "GeneralStandard": 2139,
      "Radical": "阜",
      "HSK": 5,
      "FrequencyRank": 1664,
      "StrokeCount": 10
    }
  },
  "志愿者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìyuànzhě",
      "PrimaryDefinition": "volunteer"
    },
    "Keys": {
      "Word": 2174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "科技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kējì",
      "PrimaryDefinition": "technology, tech, engineering science, applied science, science and technology, technological, engineering"
    },
    "Keys": {
      "Word": 1671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "le",
      "PrimaryDefinition": "to finish; particle of completed action"
    },
    "Keys": {
      "Hanzi": 17,
      "Word": 1711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "6.1",
      "Grade": 3,
      "GeneralStandard": 17,
      "Radical": "亅",
      "HSK": 1,
      "FrequencyRank": 5,
      "StrokeCount": 2
    }
  },
  "扺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6566,
      "Radical": "手",
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "溃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "flooding river; militarily defeat"
    },
    "Keys": {
      "Hanzi": 2759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2759,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 1916,
      "StrokeCount": 12
    }
  },
  "悠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "long, far, remote, distant; liesurely"
    },
    "Keys": {
      "Hanzi": 2326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "GeneralStandard": 2326,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 2055,
      "StrokeCount": 11
    }
  },
  "仰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎng",
      "PrimaryDefinition": "raise the head to look; look up to, rely on, admire"
    },
    "Keys": {
      "Hanzi": 473,
      "Word": 5216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 6,
      "GeneralStandard": 473,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1368,
      "StrokeCount": 6
    }
  },
  "紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "tense, tight, taut; firm, secure"
    },
    "Keys": {
      "Hanzi": 1884,
      "Word": 1631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "Grade": 3,
      "GeneralStandard": 1884,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 560,
      "StrokeCount": 10
    }
  },
  "缮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "repair, mend; rewrite, transcribe"
    },
    "Keys": {
      "Hanzi": 6022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6022,
      "Radical": "糸",
      "FrequencyRank": 3981,
      "RadicalIndex": "120.12",
      "StrokeCount": 15
    }
  },
  "建树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànshù",
      "PrimaryDefinition": "to make a contribution, to establish, to found, contribution"
    },
    "Keys": {
      "Word": 7429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醒目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐngmù",
      "PrimaryDefinition": "eye-grabbing (headline), striking (illustration)"
    },
    "Keys": {
      "Word": 10054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "测定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèdìng",
      "PrimaryDefinition": "to survey and evaluate"
    },
    "Keys": {
      "Word": 4377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "幽默": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōumò",
      "PrimaryDefinition": "humor"
    },
    "Keys": {
      "Word": 4135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "悲惨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēicǎn",
      "PrimaryDefinition": "tragic, pathetic, miserable"
    },
    "Keys": {
      "Word": 4311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "同步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngbù",
      "PrimaryDefinition": "synchronize"
    },
    "Keys": {
      "Word": 9535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "luxuriant, flourishing; used for various plants"
    },
    "Keys": {
      "Hanzi": 5083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5083,
      "Radical": "艸",
      "FrequencyRank": 6322,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "骨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "bone"
    },
    "Keys": {
      "Radical": 188,
      "Hanzi": 1523
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "188",
      "GeneralStandard": 1523,
      "Radical": "骨",
      "HSK": 4,
      "FrequencyRank": 1036,
      "StrokeCount": 9
    }
  },
  "波涛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōtāo",
      "PrimaryDefinition": "great waves, billows"
    },
    "Keys": {
      "Word": 5664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "捧场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pěng chǎng",
      "PrimaryDefinition": "to cheer on (originally esp. as paid stooge), to root for sb, to sing sb's praises, to flatter"
    },
    "Keys": {
      "Word": 8497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn",
      "PrimaryDefinition": "cliff, ledge, bank; step"
    },
    "Keys": {
      "Hanzi": 7850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7850,
      "Radical": "石",
      "FrequencyRank": 7733,
      "RadicalIndex": "112.11",
      "StrokeCount": 16
    }
  },
  "感谢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnxiè",
      "PrimaryDefinition": "thank, be grateful"
    },
    "Keys": {
      "Word": 678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "姘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīn",
      "PrimaryDefinition": "illicit sexual relations"
    },
    "Keys": {
      "Hanzi": 4387
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4387,
      "Radical": "女",
      "FrequencyRank": 4942,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "付": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "give, deliver, pay, hand over; entrust"
    },
    "Keys": {
      "Hanzi": 269,
      "Word": 1466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "Grade": 3,
      "GeneralStandard": 269,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 820,
      "StrokeCount": 5
    }
  },
  "颎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "bright"
    },
    "Keys": {
      "Hanzi": 5246
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5246,
      "Radical": "火",
      "FrequencyRank": 5669,
      "RadicalIndex": "86.8",
      "StrokeCount": 12
    }
  },
  "楝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "Melia japonica"
    },
    "Keys": {
      "Hanzi": 5395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5395,
      "Radical": "木",
      "FrequencyRank": 5723,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "开启": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāiqǐ",
      "PrimaryDefinition": "open"
    },
    "Keys": {
      "Word": 7754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掏钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāoqián",
      "PrimaryDefinition": "Save money"
    },
    "Keys": {
      "Word": 9429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nú",
      "PrimaryDefinition": "a bird-cage"
    },
    "Keys": {
      "Hanzi": 7191
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7191,
      "Radical": "竹",
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "王后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánghòu",
      "PrimaryDefinition": "queen, CL:個|个[ge4],位[wei4]"
    },
    "Keys": {
      "Word": 5129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "操纵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāozòng",
      "PrimaryDefinition": "operate, control, rig, manipulate"
    },
    "Keys": {
      "Word": 4374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鬒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "black, glossy hair"
    },
    "Keys": {
      "Hanzi": 8051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8051,
      "Radical": "髟",
      "RadicalIndex": "190.1",
      "StrokeCount": 20
    }
  },
  "潇洒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāosǎ",
      "PrimaryDefinition": "confident and at ease, free and easy"
    },
    "Keys": {
      "Word": 9963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": "name of a district in Sichuan"
    },
    "Keys": {
      "Hanzi": 6545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6545,
      "Radical": "邑",
      "FrequencyRank": 6905,
      "RadicalIndex": "163.4",
      "StrokeCount": 6
    }
  },
  "带队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài duì",
      "PrimaryDefinition": "Lead"
    },
    "Keys": {
      "Word": 6147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "farewell party; see off, send off"
    },
    "Keys": {
      "Hanzi": 3992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3992,
      "Radical": "食",
      "FrequencyRank": 4658,
      "RadicalIndex": "184.5",
      "StrokeCount": 8
    }
  },
  "口径": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒujìng",
      "PrimaryDefinition": "caliber, diameter of opening"
    },
    "Keys": {
      "Word": 7839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水龙头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐlóngtóu",
      "PrimaryDefinition": "faucet, tap"
    },
    "Keys": {
      "Word": 9267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒng",
      "PrimaryDefinition": "precious stone; county in Sichuan"
    },
    "Keys": {
      "Hanzi": 4402
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4402,
      "Radical": "玉",
      "FrequencyRank": 6469,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "run; simplified form of 運 luck, fortune; ship, transport"
    },
    "Keys": {
      "Hanzi": 611,
      "Word": 4164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 5,
      "GeneralStandard": 611,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 345,
      "StrokeCount": 7
    }
  },
  "经费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngfèi",
      "PrimaryDefinition": "finances, monetary resource, expenses, expense, expenditure, pecuniary resource, fund, outlay, funds, outgo, cash in hand"
    },
    "Keys": {
      "Word": 3628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "咸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "together; all, completely; united"
    },
    "Keys": {
      "Hanzi": 1443,
      "Word": 3013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 4,
      "GeneralStandard": 1443,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 2525,
      "StrokeCount": 9
    }
  },
  "国外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guówài",
      "PrimaryDefinition": "overseas"
    },
    "Keys": {
      "Word": 128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "杕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "alone"
    },
    "Keys": {
      "Hanzi": 6578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6578,
      "Radical": "木",
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "产业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnyè",
      "PrimaryDefinition": "estate, manufacture, industry, domain, manufacturing, property"
    },
    "Keys": {
      "Word": 3311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "掖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yē",
      "PrimaryDefinition": "support with the arms; stick in, tuck in; fold up"
    },
    "Keys": {
      "Hanzi": 4733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4733,
      "Radical": "手",
      "FrequencyRank": 3863,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "监视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānshì",
      "PrimaryDefinition": "to monitor, to keep a close watch over, surveillance"
    },
    "Keys": {
      "Word": 7393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "to resent; contended; satisfied"
    },
    "Keys": {
      "Hanzi": 5590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5590,
      "Radical": "心",
      "FrequencyRank": 5621,
      "RadicalIndex": "61.1",
      "StrokeCount": 13
    }
  },
  "退学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì xué",
      "PrimaryDefinition": "to quit school"
    },
    "Keys": {
      "Word": 9607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "steam; evaporate"
    },
    "Keys": {
      "Hanzi": 2860,
      "Word": 10729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.1",
      "Grade": 7,
      "GeneralStandard": 2860,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2358,
      "StrokeCount": 13
    }
  },
  "纯净水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúnjìngshuǐ",
      "PrimaryDefinition": "pure water"
    },
    "Keys": {
      "Word": 2344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "完蛋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán dàn",
      "PrimaryDefinition": "(coll.) to be done for"
    },
    "Keys": {
      "Word": 9648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒu",
      "PrimaryDefinition": "vomit; annoy, enrage"
    },
    "Keys": {
      "Hanzi": 725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 725,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2855,
      "StrokeCount": 7
    }
  },
  "销售": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāoshòu",
      "PrimaryDefinition": "sell, market"
    },
    "Keys": {
      "Word": 3026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "缓和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎnhé",
      "PrimaryDefinition": "relax, ease up, mitigate, appease"
    },
    "Keys": {
      "Word": 7169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫦娥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Chángé",
      "PrimaryDefinition": "Chang'e, the lady in the moon (Chinese mythology), one of the Chang'e series of PRC lunar spacecraft"
    },
    "Keys": {
      "Word": 5819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "明朗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínglǎng",
      "PrimaryDefinition": "bright, clear, obvious, forthright, open-minded, bright and cheerful"
    },
    "Keys": {
      "Word": 8309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "周期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōuqī",
      "PrimaryDefinition": "period, cycle"
    },
    "Keys": {
      "Word": 4246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "罪犯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìfàn",
      "PrimaryDefinition": "criminal"
    },
    "Keys": {
      "Word": 11024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuān",
      "PrimaryDefinition": "grievance, injustice, wrong"
    },
    "Keys": {
      "Hanzi": 2124,
      "Word": 10534
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "14.8",
      "Grade": 7,
      "GeneralStandard": 2124,
      "Radical": "冖",
      "HSK": 8,
      "FrequencyRank": 2396,
      "StrokeCount": 10
    }
  },
  "绕行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ràoxíng",
      "PrimaryDefinition": "detour, long way around"
    },
    "Keys": {
      "Word": 8829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "工作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzuò",
      "PrimaryDefinition": "work, job"
    },
    "Keys": {
      "Word": 122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "发热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā rè",
      "PrimaryDefinition": "to have a high temperature, feverish, unable to think calmly, to emit heat"
    },
    "Keys": {
      "Word": 6442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "女子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚzǐ",
      "PrimaryDefinition": "lady, female, femme, young lady, adult female, missy, young woman, fem, moll, womankind, fille, damsel, girl, miss, woman, wren, blade"
    },
    "Keys": {
      "Word": 1775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "荷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "lotus, water lily, holland"
    },
    "Keys": {
      "Hanzi": 1826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.7",
      "GeneralStandard": 1826,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1427,
      "StrokeCount": 10
    }
  },
  "刚毅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngyì",
      "PrimaryDefinition": "resolute, steadfast, stalwart"
    },
    "Keys": {
      "Word": 6685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "halter; restrain, hold, control"
    },
    "Keys": {
      "Hanzi": 6237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6237,
      "Radical": "网",
      "FrequencyRank": 3297,
      "RadicalIndex": "122.12",
      "StrokeCount": 17
    }
  },
  "安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "peaceful, tranquil, quiet"
    },
    "Keys": {
      "Hanzi": 549,
      "Word": 2236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.3",
      "Grade": 4,
      "GeneralStandard": 549,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 232,
      "StrokeCount": 6
    }
  },
  "向往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngwǎng",
      "PrimaryDefinition": "to yearn for, to look forward to"
    },
    "Keys": {
      "Word": 9948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "beautiful, handsome, pretty"
    },
    "Keys": {
      "Hanzi": 4386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4386,
      "Radical": "女",
      "FrequencyRank": 4676,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "蛘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "a weevil found in rice, etc."
    },
    "Keys": {
      "Hanzi": 5151
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5151,
      "Radical": "虫",
      "FrequencyRank": 5485,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "坛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "altar; arena, examination hall"
    },
    "Keys": {
      "Hanzi": 614,
      "Word": 9403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 7,
      "GeneralStandard": 614,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 1806,
      "StrokeCount": 7
    }
  },
  "舸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gě",
      "PrimaryDefinition": "large boat, barge"
    },
    "Keys": {
      "Hanzi": 4915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4915,
      "Radical": "舟",
      "FrequencyRank": 4936,
      "RadicalIndex": "137.5",
      "StrokeCount": 11
    }
  },
  "体贴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐtiē",
      "PrimaryDefinition": "be considerate, be thoughtful"
    },
    "Keys": {
      "Word": 9465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回忆录": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíyìlù",
      "PrimaryDefinition": "memoir"
    },
    "Keys": {
      "Word": 7212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "允": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔn",
      "PrimaryDefinition": "to grant, to allow, to consent"
    },
    "Keys": {
      "Hanzi": 183
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "10.2",
      "GeneralStandard": 183,
      "Radical": "儿",
      "HSK": 6,
      "FrequencyRank": 1443,
      "StrokeCount": 4
    }
  },
  "小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎo",
      "PrimaryDefinition": "small"
    },
    "Keys": {
      "Hanzi": 37,
      "Radical": 42,
      "Word": 1095
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "42",
      "Grade": 2,
      "GeneralStandard": 37,
      "Radical": "小",
      "HSK": 1,
      "FrequencyRank": 83,
      "StrokeCount": 3
    }
  },
  "鸫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōng",
      "PrimaryDefinition": "thrush; Turdus species (various)"
    },
    "Keys": {
      "Hanzi": 4487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4487,
      "Radical": "鳥",
      "FrequencyRank": 5627,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "捭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi",
      "PrimaryDefinition": "to open; to spread out"
    },
    "Keys": {
      "Hanzi": 4726
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4726,
      "Radical": "手",
      "FrequencyRank": 5900,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "堆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duī",
      "PrimaryDefinition": "heap, pile, crowd; pile up"
    },
    "Keys": {
      "Hanzi": 2173,
      "Word": 3434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.8",
      "Grade": 5,
      "GeneralStandard": 2173,
      "Radical": "土",
      "HSK": 5,
      "FrequencyRank": 1370,
      "StrokeCount": 11
    }
  },
  "搜索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōusuǒ",
      "PrimaryDefinition": "to search (a place), to search (a database etc)"
    },
    "Keys": {
      "Word": 3943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "饥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "hunger, starving; hungry; a famine"
    },
    "Keys": {
      "Hanzi": 299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.2",
      "GeneralStandard": 299,
      "Radical": "食",
      "HSK": 7,
      "FrequencyRank": 2173,
      "StrokeCount": 5
    }
  },
  "除夕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúxī",
      "PrimaryDefinition": "lunar New Year's Eve"
    },
    "Keys": {
      "Word": 3349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "品位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐnwèi",
      "PrimaryDefinition": "rank, grade, quality, (aesthetic) taste"
    },
    "Keys": {
      "Word": 8541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "擎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíng",
      "PrimaryDefinition": "lift up, hold up, support"
    },
    "Keys": {
      "Hanzi": 3324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.13",
      "GeneralStandard": 3324,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 3052,
      "StrokeCount": 16
    }
  },
  "鳊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "bream"
    },
    "Keys": {
      "Hanzi": 6282
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6282,
      "Radical": "魚",
      "FrequencyRank": 5400,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "亲眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnyǎn",
      "PrimaryDefinition": "with one's own eyes, personally"
    },
    "Keys": {
      "Word": 4946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "峙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "stand erect, stand up; pile up"
    },
    "Keys": {
      "Hanzi": 4230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.6",
      "GeneralStandard": 4230,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 3046,
      "StrokeCount": 9
    }
  },
  "内在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèizài",
      "PrimaryDefinition": "inhesion, intrinsic, internality, inherent, internal, immanence, inherence"
    },
    "Keys": {
      "Word": 3744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鄙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "mean; low"
    },
    "Keys": {
      "Hanzi": 2903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.11",
      "GeneralStandard": 2903,
      "Radical": "邑",
      "HSK": 7,
      "FrequencyRank": 2510,
      "StrokeCount": 13
    }
  },
  "演唱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnchàng",
      "PrimaryDefinition": "sing in performance"
    },
    "Keys": {
      "Word": 2080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "功能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngnéng",
      "PrimaryDefinition": "functional, niche, function, competence, capacity, anatomy"
    },
    "Keys": {
      "Word": 1508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "暖气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuǎnqì",
      "PrimaryDefinition": "central heating, heater, warm air"
    },
    "Keys": {
      "Word": 2767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "婳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": "tranquil"
    },
    "Keys": {
      "Hanzi": 7266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7266,
      "Radical": "女",
      "RadicalIndex": "38.7",
      "StrokeCount": 11
    }
  },
  "强占": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángzhàn",
      "PrimaryDefinition": "to occupy by force"
    },
    "Keys": {
      "Word": 8694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "elderly person; very old, senile"
    },
    "Keys": {
      "Hanzi": 4420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4420,
      "Radical": "老",
      "FrequencyRank": 4373,
      "RadicalIndex": "125.4",
      "StrokeCount": 10
    }
  },
  "勰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "peaceful, harmonious"
    },
    "Keys": {
      "Hanzi": 6018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6018,
      "Radical": "力",
      "FrequencyRank": 5506,
      "RadicalIndex": "19.13",
      "StrokeCount": 15
    }
  },
  "嗦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suo",
      "PrimaryDefinition": "suck"
    },
    "Keys": {
      "Hanzi": 2904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.1",
      "GeneralStandard": 2904,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 3089,
      "StrokeCount": 13
    }
  },
  "瘙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sào",
      "PrimaryDefinition": "itch"
    },
    "Keys": {
      "Hanzi": 5784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5784,
      "Radical": "疒",
      "FrequencyRank": 4657,
      "RadicalIndex": "104.1",
      "StrokeCount": 14
    }
  },
  "杂志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zázhì",
      "PrimaryDefinition": "journal, bulletin, mag, magazine, periodical"
    },
    "Keys": {
      "Word": 2128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "互相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùxiāng",
      "PrimaryDefinition": "mutual, each other, mutually, one another, reciprocally"
    },
    "Keys": {
      "Word": 1556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "担心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān xīn",
      "PrimaryDefinition": "worry, feel anxious"
    },
    "Keys": {
      "Word": 2386
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "赜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "abstruse, deep, profound"
    },
    "Keys": {
      "Hanzi": 5882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5882,
      "Radical": "匚",
      "FrequencyRank": 4972,
      "RadicalIndex": "22.13",
      "StrokeCount": 15
    }
  },
  "泥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "mud, mire; earth, clay; plaster"
    },
    "Keys": {
      "Hanzi": 1258,
      "Word": 4892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 6,
      "GeneralStandard": 1258,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1499,
      "StrokeCount": 8
    }
  },
  "求助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúzhù",
      "PrimaryDefinition": "invoke, resort, seek help, recur, bring_in, turn to ... for help, appeal"
    },
    "Keys": {
      "Word": 8777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "眶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "eye socket; rim of eye"
    },
    "Keys": {
      "Hanzi": 2258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.6",
      "GeneralStandard": 2258,
      "Radical": "目",
      "HSK": 6,
      "FrequencyRank": 3191,
      "StrokeCount": 11
    }
  },
  "颦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pín",
      "PrimaryDefinition": "frown, knit brows; with knitted"
    },
    "Keys": {
      "Hanzi": 6452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6452,
      "Radical": "十",
      "FrequencyRank": 4851,
      "RadicalIndex": "24.19",
      "StrokeCount": 21
    }
  },
  "导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎo",
      "PrimaryDefinition": "direct, guide, lead, conduct"
    },
    "Keys": {
      "Hanzi": 567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "41.3",
      "GeneralStandard": 567,
      "Radical": "寸",
      "HSK": 3,
      "FrequencyRank": 343,
      "StrokeCount": 6
    }
  },
  "假如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎrú",
      "PrimaryDefinition": "conj.: if, supposing, in case"
    },
    "Keys": {
      "Word": 2599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "毅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "resolute, decisive, firm, persist"
    },
    "Keys": {
      "Hanzi": 3286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "79.11",
      "GeneralStandard": 3286,
      "Radical": "殳",
      "HSK": 9,
      "FrequencyRank": 1946,
      "StrokeCount": 15
    }
  },
  "郄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 3972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3972,
      "Radical": "邑",
      "FrequencyRank": 6803,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "这就是说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèjiùshìshuō",
      "PrimaryDefinition": "This means that"
    },
    "Keys": {
      "Word": 5336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "憷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "painful; suffering; privation"
    },
    "Keys": {
      "Hanzi": 7909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7909,
      "Radical": "心",
      "FrequencyRank": 5805,
      "RadicalIndex": "61.13",
      "StrokeCount": 16
    }
  },
  "雌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "female; feminine; gentle, soft"
    },
    "Keys": {
      "Hanzi": 3098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "172.5",
      "GeneralStandard": 3098,
      "Radical": "隹",
      "HSK": 6,
      "FrequencyRank": 2382,
      "StrokeCount": 14
    }
  },
  "得意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "déyì",
      "PrimaryDefinition": "be proud of; be exalted; be complacent"
    },
    "Keys": {
      "Word": 2395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "利用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìyòng",
      "PrimaryDefinition": "use, utilize, take advantage of, exploit"
    },
    "Keys": {
      "Word": 1701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùlǐ",
      "PrimaryDefinition": "to refuse to acknowledge, to pay no attention to, to take no notice of, to ignore"
    },
    "Keys": {
      "Word": 5705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "调控": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáokòng",
      "PrimaryDefinition": "to regulate, to control"
    },
    "Keys": {
      "Word": 9500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "匹配": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐpèi",
      "PrimaryDefinition": "to mate or marry, to match, matching, compatible"
    },
    "Keys": {
      "Word": 8512
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "调动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàodòng",
      "PrimaryDefinition": "to transfer, to maneuver (troops etc), movement of personnel, to mobilize, to bring into play"
    },
    "Keys": {
      "Word": 3425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "髦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "flowing hair of young child"
    },
    "Keys": {
      "Hanzi": 5630
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "190.4",
      "GeneralStandard": 5630,
      "Radical": "髟",
      "HSK": 7,
      "FrequencyRank": 3149,
      "StrokeCount": 14
    }
  },
  "首饰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒushi",
      "PrimaryDefinition": "jewelry, head ornament"
    },
    "Keys": {
      "Word": 9190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiē",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5059,
      "Radical": "手",
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "卒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zú",
      "PrimaryDefinition": "soldier; servant; at last, finally"
    },
    "Keys": {
      "Hanzi": 1217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.6",
      "GeneralStandard": 1217,
      "Radical": "十",
      "HSK": 9,
      "FrequencyRank": 2584,
      "StrokeCount": 8
    }
  },
  "祾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5308,
      "Radical": "示",
      "FrequencyRank": 8954,
      "RadicalIndex": "113.8",
      "StrokeCount": 12
    }
  },
  "墣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7752,
      "Radical": "土",
      "RadicalIndex": "32.12",
      "StrokeCount": 15
    }
  },
  "上流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngliú",
      "PrimaryDefinition": "upper class"
    },
    "Keys": {
      "Word": 8975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zì",
      "PrimaryDefinition": "indulge oneself, unrestrained"
    },
    "Keys": {
      "Hanzi": 4625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4625,
      "Radical": "心",
      "FrequencyRank": 3968,
      "RadicalIndex": "61.6",
      "StrokeCount": 10
    }
  },
  "鲹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "a horse mackerel"
    },
    "Keys": {
      "Hanzi": 7889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7889,
      "Radical": "魚",
      "FrequencyRank": 7718,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "浆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "any thick fluid; starch; broth"
    },
    "Keys": {
      "Hanzi": 2018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 2018,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2626,
      "StrokeCount": 10
    }
  },
  "途径": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tújìng",
      "PrimaryDefinition": "track, path, pathway, footpath, avenue, gateway, channel, road, way, pipeline, curriculum"
    },
    "Keys": {
      "Word": 5104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "香水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngshuǐ",
      "PrimaryDefinition": "perfumery, cologne, aroma, perfume, fragrance, scent, rosewood, fragrancy"
    },
    "Keys": {
      "Word": 9932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发酵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fājiào",
      "PrimaryDefinition": "to ferment, fermentation"
    },
    "Keys": {
      "Word": 6437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāng",
      "PrimaryDefinition": "tall bamboo"
    },
    "Keys": {
      "Hanzi": 7389
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7389,
      "Radical": "竹",
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "递给": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìgěi",
      "PrimaryDefinition": "Handle"
    },
    "Keys": {
      "Word": 3418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "熛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "blaze"
    },
    "Keys": {
      "Hanzi": 7812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7812,
      "Radical": "火",
      "RadicalIndex": "86.11",
      "StrokeCount": 15
    }
  },
  "空间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngjiān",
      "PrimaryDefinition": "elbow room, allowance, margin, roomage, open air/sky/space, road, leeway, way, open air, sheets, room, tolerance, opening, clear, sky, spacial, interspace, vacuity, spatial, dimension, open sky/space, space, place, vacuum"
    },
    "Keys": {
      "Word": 2662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "尽力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn lì",
      "PrimaryDefinition": "do_one's_best, do all one can, render, take pains, be at pains, take_pains, stretch, strain, strive, try one's best"
    },
    "Keys": {
      "Word": 2632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "服务器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúwùqì",
      "PrimaryDefinition": "server (computer), CL:臺|台[tai2]"
    },
    "Keys": {
      "Word": 6601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "喇叭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎba",
      "PrimaryDefinition": "horn (automobile etc), loudspeaker, brass wind instrument, trumpet, suona 鎖吶|锁呐[suo3 na4]"
    },
    "Keys": {
      "Word": 7910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "harmony; pleasure, joy; be glad"
    },
    "Keys": {
      "Hanzi": 1272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1272,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 2519,
      "StrokeCount": 8
    }
  },
  "学科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuékē",
      "PrimaryDefinition": "study, area, school subject, science, subject field, subject, course, subject area, discipline, branch of learning, course of study, field of study, branch of knowledge, field"
    },
    "Keys": {
      "Word": 4078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "锰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "manganese"
    },
    "Keys": {
      "Hanzi": 2945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2945,
      "Radical": "金",
      "FrequencyRank": 4204,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "成功": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénggōng",
      "PrimaryDefinition": "succeed"
    },
    "Keys": {
      "Word": 1347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "图案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túàn",
      "PrimaryDefinition": "miniature, device, pattern, illumination, emblem, embossment, design, imprint, cutout, designing, type"
    },
    "Keys": {
      "Word": 2952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "花卉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāhuì",
      "PrimaryDefinition": "flowers and plants"
    },
    "Keys": {
      "Word": 7126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "图表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túbiǎo",
      "PrimaryDefinition": "chart, diagram"
    },
    "Keys": {
      "Word": 9577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "foothill"
    },
    "Keys": {
      "Hanzi": 6631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6631,
      "Radical": "山",
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "合作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hézuò",
      "PrimaryDefinition": "cooperate, collaborate, work together"
    },
    "Keys": {
      "Word": 1554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "炮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pào",
      "PrimaryDefinition": "large gun, cannon; artillery"
    },
    "Keys": {
      "Hanzi": 1658,
      "Word": 4905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "Grade": 6,
      "GeneralStandard": 1658,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 914,
      "StrokeCount": 9
    }
  },
  "越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "exceed, go beyond; the more ..."
    },
    "Keys": {
      "Hanzi": 2514,
      "Word": 1188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.5",
      "Grade": 2,
      "GeneralStandard": 2514,
      "Radical": "走",
      "HSK": 2,
      "FrequencyRank": 440,
      "StrokeCount": 12
    }
  },
  "嫕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "compliant, yielding; easy-going"
    },
    "Keys": {
      "Hanzi": 7742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7742,
      "Radical": "女",
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "侨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "sojourn, lodge"
    },
    "Keys": {
      "Hanzi": 1149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1149,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2340,
      "StrokeCount": 8
    }
  },
  "宰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎi",
      "PrimaryDefinition": "to slaughter; to rule"
    },
    "Keys": {
      "Hanzi": 2106,
      "Word": 10589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "Grade": 7,
      "GeneralStandard": 2106,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 2080,
      "StrokeCount": 10
    }
  },
  "团聚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuánjù",
      "PrimaryDefinition": "to reunite, to have a reunion"
    },
    "Keys": {
      "Word": 9588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "各个": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gègè",
      "PrimaryDefinition": "every, various, separately, one by one"
    },
    "Keys": {
      "Word": 2497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "保守": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoshǒu",
      "PrimaryDefinition": "conservative"
    },
    "Keys": {
      "Word": 2253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "抗衡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànghéng",
      "PrimaryDefinition": "contend against"
    },
    "Keys": {
      "Word": 7779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "创": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàng",
      "PrimaryDefinition": "establish, create; knife cut"
    },
    "Keys": {
      "Hanzi": 494,
      "Word": 6019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "Grade": 7,
      "GeneralStandard": 494,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 635,
      "StrokeCount": 6
    }
  },
  "蜥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "lizard"
    },
    "Keys": {
      "Hanzi": 5705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5705,
      "Radical": "虫",
      "FrequencyRank": 4307,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "悼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "grieve, mourn, lament; grieved"
    },
    "Keys": {
      "Hanzi": 2441
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2441,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 2963,
      "StrokeCount": 11
    }
  },
  "砗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chē",
      "PrimaryDefinition": "giant clam, tridacna gigas"
    },
    "Keys": {
      "Hanzi": 4165
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4165,
      "Radical": "石",
      "FrequencyRank": 5603,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "嫂子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎozi",
      "PrimaryDefinition": "(coll.) older brother's wife, sister-in-law, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 8930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": "hole, cave; cellar; underground"
    },
    "Keys": {
      "Hanzi": 3028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.8",
      "GeneralStandard": 3028,
      "Radical": "穴",
      "HSK": 9,
      "FrequencyRank": 2924,
      "StrokeCount": 13
    }
  },
  "焚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fén",
      "PrimaryDefinition": "burn"
    },
    "Keys": {
      "Hanzi": 2568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.8",
      "GeneralStandard": 2568,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 2498,
      "StrokeCount": 12
    }
  },
  "爱理不理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àilǐbùlǐ",
      "PrimaryDefinition": "to look cold and indifferent, standoffish"
    },
    "Keys": {
      "Word": 5432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小伙子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎohuǒzi",
      "PrimaryDefinition": "young man, young guy, lad, youngster, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "有效期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuxiàoqī",
      "PrimaryDefinition": "period of validity, sell-by date"
    },
    "Keys": {
      "Word": 10481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu",
      "PrimaryDefinition": "lid"
    },
    "Keys": {
      "Radical": 8
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "棋子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qízǐ",
      "PrimaryDefinition": "chess piece, CL:個|个[ge4],顆|颗[ke1]"
    },
    "Keys": {
      "Word": 8602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焦距": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāojù",
      "PrimaryDefinition": "focal length, focal distance"
    },
    "Keys": {
      "Word": 7472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "町": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng",
      "PrimaryDefinition": "raised path between fields"
    },
    "Keys": {
      "Hanzi": 3706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3706,
      "Radical": "田",
      "FrequencyRank": 4001,
      "RadicalIndex": "102.2",
      "StrokeCount": 7
    }
  },
  "跟踪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnzōng",
      "PrimaryDefinition": "track"
    },
    "Keys": {
      "Word": 6745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "樨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "scrambled eggs"
    },
    "Keys": {
      "Hanzi": 6063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6063,
      "Radical": "木",
      "FrequencyRank": 6446,
      "RadicalIndex": "75.11",
      "StrokeCount": 16
    }
  },
  "肃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "pay respects; reverently"
    },
    "Keys": {
      "Hanzi": 1303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "129.4",
      "GeneralStandard": 1303,
      "Radical": "聿",
      "HSK": 5,
      "FrequencyRank": 1518,
      "StrokeCount": 8
    }
  },
  "故障": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùzhàng",
      "PrimaryDefinition": "malfunction, break"
    },
    "Keys": {
      "Word": 4623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "丰厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnghòu",
      "PrimaryDefinition": "generous, ample"
    },
    "Keys": {
      "Word": 6560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "按摩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànmó",
      "PrimaryDefinition": "massage"
    },
    "Keys": {
      "Word": 3225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "将": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "will, going to, future; general"
    },
    "Keys": {
      "Hanzi": 1617,
      "Word": 3608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "90.6",
      "Grade": 5,
      "GeneralStandard": 1617,
      "Radical": "爿",
      "HSK": 3,
      "FrequencyRank": 132,
      "StrokeCount": 9
    }
  },
  "掺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "càn",
      "PrimaryDefinition": "mix, blend, adulterate"
    },
    "Keys": {
      "Hanzi": 2191,
      "Word": 5795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2191,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3308,
      "StrokeCount": 11
    }
  },
  "合情合理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héqíng-hélǐ",
      "PrimaryDefinition": "reasonable and fair (idiom)"
    },
    "Keys": {
      "Word": 7028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厕所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèsuǒ",
      "PrimaryDefinition": "john, W.C., can, jakes, lavatory, lav, cloaca, crapper, privy, water closet, bathroom, bog, washroom, closet, convenience, stool, rear, latrine, loo, facility, toilet, John, cloakroom"
    },
    "Keys": {
      "Word": 4376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "危及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēijí",
      "PrimaryDefinition": "to endanger, to jeopardize, a danger (to life, national security etc)"
    },
    "Keys": {
      "Word": 9683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "子孙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐsūn",
      "PrimaryDefinition": "posterity, rod, scion, materialization, progeniture, offspring, descendant, children and grandchildren, spawn, son, generation, issue, increase, descendent, seed, child, descendants, fruit, get, progeny"
    },
    "Keys": {
      "Word": 10949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "thank; decline"
    },
    "Keys": {
      "Hanzi": 2795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.1",
      "GeneralStandard": 2795,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 897,
      "StrokeCount": 12
    }
  },
  "左": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǒ",
      "PrimaryDefinition": "left; east; unorthodox, improper"
    },
    "Keys": {
      "Hanzi": 214,
      "Word": 494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "48.2",
      "Grade": 1,
      "GeneralStandard": 214,
      "Radical": "工",
      "HSK": 1,
      "FrequencyRank": 782,
      "StrokeCount": 5
    }
  },
  "小朋友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎopéngyǒu",
      "PrimaryDefinition": "child (adult address to child), little boy, children, little boy/girl, girl, little girl, little boy or girl, child"
    },
    "Keys": {
      "Word": 402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "抄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo",
      "PrimaryDefinition": "copy, confiscate, seize"
    },
    "Keys": {
      "Hanzi": 626,
      "Word": 2305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 4,
      "GeneralStandard": 626,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 2213,
      "StrokeCount": 7
    }
  },
  "天然气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānránqì",
      "PrimaryDefinition": "natural gas"
    },
    "Keys": {
      "Word": 3977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "迈进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màijìn",
      "PrimaryDefinition": "forge ahead, stride forward, advance with big strides"
    },
    "Keys": {
      "Word": 8191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公仆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngpú",
      "PrimaryDefinition": "public servant, CL:個|个[ge4],位[wei4]"
    },
    "Keys": {
      "Word": 6770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèn",
      "PrimaryDefinition": "ranunculus"
    },
    "Keys": {
      "Hanzi": 6795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6795,
      "Radical": "艸",
      "FrequencyRank": 6443,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "外资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàizī",
      "PrimaryDefinition": "foreign capital"
    },
    "Keys": {
      "Word": 5124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "深化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnhuà",
      "PrimaryDefinition": "to deepen, to intensify"
    },
    "Keys": {
      "Word": 5004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "考察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎochá",
      "PrimaryDefinition": "inspect, make on-the-spot investigation, observe and study"
    },
    "Keys": {
      "Word": 2659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "锒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "lock"
    },
    "Keys": {
      "Hanzi": 5188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5188,
      "Radical": "金",
      "FrequencyRank": 5340,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "价格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàgé",
      "PrimaryDefinition": "price"
    },
    "Keys": {
      "Word": 1591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "维生素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéishēngsù",
      "PrimaryDefinition": "vitamin"
    },
    "Keys": {
      "Word": 5140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "垂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": "let down; suspend, hand; down"
    },
    "Keys": {
      "Hanzi": 1125,
      "Word": 6024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.5",
      "Grade": 7,
      "GeneralStandard": 1125,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 1592,
      "StrokeCount": 8
    }
  },
  "天平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānpíng",
      "PrimaryDefinition": "scales (to weigh things)"
    },
    "Keys": {
      "Word": 9479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "混淆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùnxiáo",
      "PrimaryDefinition": "to obscure, to confuse, to mix up, to blur, to mislead"
    },
    "Keys": {
      "Word": 7232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "差距": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chājù",
      "PrimaryDefinition": "spatial difference"
    },
    "Keys": {
      "Word": 3302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "祥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáng",
      "PrimaryDefinition": "good luck, good omen; happiness"
    },
    "Keys": {
      "Hanzi": 2119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.6",
      "GeneralStandard": 2119,
      "Radical": "示",
      "HSK": 6,
      "FrequencyRank": 1674,
      "StrokeCount": 10
    }
  },
  "辉煌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huīhuáng",
      "PrimaryDefinition": "glorious, effulgent, brilliant"
    },
    "Keys": {
      "Word": 7203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zè",
      "PrimaryDefinition": "afternoon; the sun in the afternoon sky; to decline"
    },
    "Keys": {
      "Hanzi": 3900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3900,
      "Radical": "日",
      "FrequencyRank": 6273,
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "估": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "merchant; estimate, guess, presume"
    },
    "Keys": {
      "Hanzi": 769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 769,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1326,
      "StrokeCount": 7
    }
  },
  "通知书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngzhīshū",
      "PrimaryDefinition": "Notice"
    },
    "Keys": {
      "Word": 2939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "谋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "plan, scheme; strategem"
    },
    "Keys": {
      "Hanzi": 2459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.9",
      "GeneralStandard": 2459,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 989,
      "StrokeCount": 11
    }
  },
  "丰收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngshōu",
      "PrimaryDefinition": "bumper harvest"
    },
    "Keys": {
      "Word": 3460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "梾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7138
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7138,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "铀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "uranium"
    },
    "Keys": {
      "Hanzi": 4536
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4536,
      "Radical": "金",
      "FrequencyRank": 3105,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "公积金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjījīn",
      "PrimaryDefinition": "official reserves, accumulated fund"
    },
    "Keys": {
      "Word": 6766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "停顿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngdùn",
      "PrimaryDefinition": "to halt, to break off, pause (in speech)"
    },
    "Keys": {
      "Word": 9517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "abalone; dried fish; surname"
    },
    "Keys": {
      "Hanzi": 2977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2977,
      "Radical": "魚",
      "FrequencyRank": 2073,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "口号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuhào",
      "PrimaryDefinition": "motto, shibboleth, slogan, watch word, watchcry, catchword, war_cry, cant, watchword, buzzword"
    },
    "Keys": {
      "Word": 3666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "裎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "to take off the clothes and expose the body. to carry in the girdle"
    },
    "Keys": {
      "Hanzi": 5306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5306,
      "Radical": "衣",
      "FrequencyRank": 5788,
      "RadicalIndex": "145.7",
      "StrokeCount": 12
    }
  },
  "早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎo",
      "PrimaryDefinition": "early; soon; morning"
    },
    "Keys": {
      "Hanzi": 419,
      "Word": 454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.2",
      "Grade": 1,
      "GeneralStandard": 419,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 462,
      "StrokeCount": 6
    }
  },
  "送到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng dào",
      "PrimaryDefinition": "Deliver"
    },
    "Keys": {
      "Word": 996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "精细": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngxì",
      "PrimaryDefinition": "fine, meticulous, careful"
    },
    "Keys": {
      "Word": 7621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "jade; rare, precious; elegant; (Cant.) to coagulate"
    },
    "Keys": {
      "Hanzi": 2504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2504,
      "Radical": "玉",
      "FrequencyRank": 2205,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "叫作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàozuò",
      "PrimaryDefinition": "to call, to be called"
    },
    "Keys": {
      "Word": 770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "亳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "name of district in Anhui; capital of Yin"
    },
    "Keys": {
      "Hanzi": 4613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4613,
      "Radical": "亠",
      "FrequencyRank": 5679,
      "RadicalIndex": "8.8",
      "StrokeCount": 10
    }
  },
  "旻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": "heaven"
    },
    "Keys": {
      "Hanzi": 3905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3905,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "施工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī gōng",
      "PrimaryDefinition": "work on (of construction)"
    },
    "Keys": {
      "Word": 9096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎ",
      "PrimaryDefinition": "grope, press, span"
    },
    "Keys": {
      "Hanzi": 3844
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3844,
      "Radical": "手",
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "袋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "pocket, bag, sack, pouch"
    },
    "Keys": {
      "Hanzi": 2325,
      "Word": 2381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.5",
      "Grade": 4,
      "GeneralStandard": 2325,
      "Radical": "衣",
      "HSK": 4,
      "FrequencyRank": 1310,
      "StrokeCount": 11
    }
  },
  "项链": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngliàn",
      "PrimaryDefinition": "necklace, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 9949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "long necked jar or bottle"
    },
    "Keys": {
      "Hanzi": 5729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5729,
      "Radical": "缶",
      "FrequencyRank": 4374,
      "RadicalIndex": "121.8",
      "StrokeCount": 14
    }
  },
  "打牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ pái",
      "PrimaryDefinition": "to play mahjong or cards"
    },
    "Keys": {
      "Word": 4468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": "to capture, imprison, seize; a prison"
    },
    "Keys": {
      "Hanzi": 1072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "141.2",
      "GeneralStandard": 1072,
      "Radical": "虍",
      "HSK": 8,
      "FrequencyRank": 2475,
      "StrokeCount": 8
    }
  },
  "裱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎo",
      "PrimaryDefinition": "to mount maps or scrolls to paste"
    },
    "Keys": {
      "Hanzi": 5596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5596,
      "Radical": "衣",
      "FrequencyRank": 4850,
      "RadicalIndex": "145.8",
      "StrokeCount": 13
    }
  },
  "煟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "bright room"
    },
    "Keys": {
      "Hanzi": 7585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7585,
      "Radical": "火",
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "腨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7555,
      "Radical": "肉",
      "FrequencyRank": 8884,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "撅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juē",
      "PrimaryDefinition": "protrude; snap, break; dig"
    },
    "Keys": {
      "Hanzi": 5862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5862,
      "Radical": "手",
      "FrequencyRank": 4115,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "气体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìtǐ",
      "PrimaryDefinition": "exhaust, fume, gaseous, gas, exhaust fumes, pneumatic, gaseity, mofette, fumes"
    },
    "Keys": {
      "Word": 3803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "漖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "(Cant.) a branching river (used in toponyms)"
    },
    "Keys": {
      "Hanzi": 7723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7723,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "席": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "seat; mat; take seat; banquet"
    },
    "Keys": {
      "Hanzi": 2023,
      "Word": 9830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.7",
      "Grade": 7,
      "GeneralStandard": 2023,
      "Radical": "巾",
      "HSK": 4,
      "FrequencyRank": 894,
      "StrokeCount": 10
    }
  },
  "喟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "heave sigh, sigh"
    },
    "Keys": {
      "Hanzi": 5153
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5153,
      "Radical": "口",
      "FrequencyRank": 4423,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "貔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "fox, leopard, panther"
    },
    "Keys": {
      "Hanzi": 6271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6271,
      "Radical": "豸",
      "FrequencyRank": 6414,
      "RadicalIndex": "153.1",
      "StrokeCount": 17
    }
  },
  "昌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "light of sun; good, proper"
    },
    "Keys": {
      "Hanzi": 1084
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1084,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 1606,
      "StrokeCount": 8
    }
  },
  "肫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūn",
      "PrimaryDefinition": "the gizzard of a fowl; honest, sincere"
    },
    "Keys": {
      "Hanzi": 3981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3981,
      "Radical": "肉",
      "FrequencyRank": 5513,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "撵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎn",
      "PrimaryDefinition": "drive away, expel, oust"
    },
    "Keys": {
      "Hanzi": 3190
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3190,
      "Radical": "手",
      "FrequencyRank": 3793,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "煞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "malignant deity; baleful, noxious; strike dead"
    },
    "Keys": {
      "Hanzi": 2982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2982,
      "Radical": "火",
      "FrequencyRank": 2280,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "仟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "one thousand; leader of one thousand men"
    },
    "Keys": {
      "Hanzi": 3543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3543,
      "Radical": "人",
      "FrequencyRank": 5443,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "车位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēwèi",
      "PrimaryDefinition": "parking spot, unloading point, garage place, stand for taxi"
    },
    "Keys": {
      "Word": 5841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "lame"
    },
    "Keys": {
      "Radical": 43,
      "Hanzi": 6502
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6502,
      "Radical": "尢",
      "FrequencyRank": 6516,
      "RadicalIndex": "43",
      "StrokeCount": 3
    }
  },
  "吩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "order, command, instruct"
    },
    "Keys": {
      "Hanzi": 741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 741,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2299,
      "StrokeCount": 7
    }
  },
  "面面俱到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànmiàn-jùdào",
      "PrimaryDefinition": "(idiom) take care of everything, handle everything"
    },
    "Keys": {
      "Word": 8285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一成不变": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīchéng-bùbiàn",
      "PrimaryDefinition": "nothing much changes (idiom), always the same, stuck in a rut"
    },
    "Keys": {
      "Word": 10320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冲刺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngcì",
      "PrimaryDefinition": "to sprint, to spurt, to dash, big effort"
    },
    "Keys": {
      "Word": 5912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "培训班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péixùnbān",
      "PrimaryDefinition": "training class"
    },
    "Keys": {
      "Word": 2775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "眄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "to look askance; looking dull"
    },
    "Keys": {
      "Hanzi": 4188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4188,
      "Radical": "目",
      "FrequencyRank": 6853,
      "RadicalIndex": "109.4",
      "StrokeCount": 9
    }
  },
  "唱片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàngpiàn",
      "PrimaryDefinition": "pressing, waxing, cut, disc, phonogram, phonograph record, record, disk, wax, phonorecord, platter, recording, gramophone record, phonograph recording"
    },
    "Keys": {
      "Word": 2301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "薜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "evergreen shrubs, ligusticum"
    },
    "Keys": {
      "Hanzi": 6054
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6054,
      "Radical": "艸",
      "FrequencyRank": 5529,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "鬻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "sell; child, childish; nourish"
    },
    "Keys": {
      "Hanzi": 6476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6476,
      "Radical": "鬲",
      "FrequencyRank": 5314,
      "RadicalIndex": "193.12",
      "StrokeCount": 22
    }
  },
  "蒱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "gambling game with dice"
    },
    "Keys": {
      "Hanzi": 7484
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7484,
      "Radical": "艸",
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "语气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔqì",
      "PrimaryDefinition": "mood, tenor, strain, modality, manner of speaking, tone, mode, tone of voice, note"
    },
    "Keys": {
      "Word": 10513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāng",
      "PrimaryDefinition": "pendant ornaments, earrings"
    },
    "Keys": {
      "Hanzi": 4404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4404,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "不经意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjīngyì",
      "PrimaryDefinition": "not paying attention, carelessly, by accident"
    },
    "Keys": {
      "Word": 5698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "今": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "now, today, modern era"
    },
    "Keys": {
      "Hanzi": 144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "GeneralStandard": 144,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 336,
      "StrokeCount": 4
    }
  },
  "空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng",
      "PrimaryDefinition": "empty, hollow, bare, deserted"
    },
    "Keys": {
      "Hanzi": 1282,
      "Word": 2663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.3",
      "Grade": 4,
      "GeneralStandard": 1282,
      "Radical": "穴",
      "HSK": 2,
      "FrequencyRank": 272,
      "StrokeCount": 8
    }
  },
  "过不去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòbuqù",
      "PrimaryDefinition": "to make life difficult for, to embarrass, unable to make it through"
    },
    "Keys": {
      "Word": 6942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "abuse, slander; vilify; ridicule"
    },
    "Keys": {
      "Hanzi": 3556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3556,
      "Radical": "言",
      "FrequencyRank": 3679,
      "RadicalIndex": "149.3",
      "StrokeCount": 5
    }
  },
  "三番五次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sānfān-wǔcì",
      "PrimaryDefinition": "over and over again (idiom)"
    },
    "Keys": {
      "Word": 8916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "法官": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎguān",
      "PrimaryDefinition": "sentencer, Bench, judiciary, judgeship, bench, tippet, wig, justice, judicature, magistrate, court, judge, jurist, recorder, hakim, justicer, judgship"
    },
    "Keys": {
      "Word": 2448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "主任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔrèn",
      "PrimaryDefinition": "head, principal, captain, chair, director, head teacher, chief, chairman, school principal"
    },
    "Keys": {
      "Word": 2193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "扆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "screen"
    },
    "Keys": {
      "Hanzi": 7076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7076,
      "Radical": "戶",
      "RadicalIndex": "63.6",
      "StrokeCount": 10
    }
  },
  "㑊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "a kind of disease"
    },
    "Keys": {
      "Hanzi": 6700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6700,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "朝气蓬勃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāoqì-péngbó",
      "PrimaryDefinition": "full of youthful energy (idiom), vigorous, energetic, a bright spark"
    },
    "Keys": {
      "Word": 10666
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "ingot; acrobatic move"
    },
    "Keys": {
      "Hanzi": 5486
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5486,
      "Radical": "金",
      "FrequencyRank": 6117,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "片子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānzi",
      "PrimaryDefinition": "a roll of film, film, movie, record, album"
    },
    "Keys": {
      "Word": 8520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闭幕式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìmùshì",
      "PrimaryDefinition": "closing ceremony"
    },
    "Keys": {
      "Word": 3252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "毫不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàobù",
      "PrimaryDefinition": "nohow, not at all, no, no more, none, nowise, nothing"
    },
    "Keys": {
      "Word": 6999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎn",
      "PrimaryDefinition": "slow, leisurely; to postpone, delay"
    },
    "Keys": {
      "Hanzi": 2818,
      "Word": 7168
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "Grade": 7,
      "GeneralStandard": 2818,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 1111,
      "StrokeCount": 12
    }
  },
  "人手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénshǒu",
      "PrimaryDefinition": "manpower, staff, human hand"
    },
    "Keys": {
      "Word": 8847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肥皂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féizào",
      "PrimaryDefinition": "soap, CL:塊|块[kuai4],條|条[tiao2]"
    },
    "Keys": {
      "Word": 6520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "good luck, good fortune"
    },
    "Keys": {
      "Hanzi": 5309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5309,
      "Radical": "示",
      "FrequencyRank": 3499,
      "RadicalIndex": "113.8",
      "StrokeCount": 12
    }
  },
  "年限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánxiàn",
      "PrimaryDefinition": "age limit, fixed number of years"
    },
    "Keys": {
      "Word": 8410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "争端": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngduān",
      "PrimaryDefinition": "dispute, controversy, conflict"
    },
    "Keys": {
      "Word": 10716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筹措": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóucuò",
      "PrimaryDefinition": "to raise (money)"
    },
    "Keys": {
      "Word": 5941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "lift, raise; praise; hang; flap"
    },
    "Keys": {
      "Hanzi": 5071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5071,
      "Radical": "手",
      "FrequencyRank": 4725,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "跎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "slip, stumble, falter; vacillate"
    },
    "Keys": {
      "Hanzi": 5139
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5139,
      "Radical": "足",
      "FrequencyRank": 5254,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "前夕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánxī",
      "PrimaryDefinition": "eve, the day before"
    },
    "Keys": {
      "Word": 8668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "破碎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòsuì",
      "PrimaryDefinition": "to smash to pieces, to shatter"
    },
    "Keys": {
      "Word": 8574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "ridicule, jeer, mock; inspect"
    },
    "Keys": {
      "Hanzi": 173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.2",
      "GeneralStandard": 173,
      "Radical": "言",
      "HSK": 9,
      "FrequencyRank": 2922,
      "StrokeCount": 4
    }
  },
  "打猎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ liè",
      "PrimaryDefinition": "to go hunting"
    },
    "Keys": {
      "Word": 6105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxī",
      "PrimaryDefinition": "not stint, not hesitate (to do sth.)"
    },
    "Keys": {
      "Word": 5713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎn",
      "PrimaryDefinition": "choose, select; elect; election"
    },
    "Keys": {
      "Hanzi": 1545,
      "Word": 1120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 2,
      "GeneralStandard": 1545,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 499,
      "StrokeCount": 9
    }
  },
  "杲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "bright sun; brilliant; high"
    },
    "Keys": {
      "Hanzi": 3899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3899,
      "Radical": "木",
      "FrequencyRank": 4837,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "骂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mà",
      "PrimaryDefinition": "accuse, blame, curse, scold"
    },
    "Keys": {
      "Hanzi": 1505,
      "Word": 3715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 5,
      "GeneralStandard": 1505,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1449,
      "StrokeCount": 9
    }
  },
  "私营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīyíng",
      "PrimaryDefinition": "privately-owned, private"
    },
    "Keys": {
      "Word": 9313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "interrogative final particle; insignificant, small, tiny"
    },
    "Keys": {
      "Hanzi": 5780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5780,
      "Radical": "麻",
      "FrequencyRank": 2725,
      "RadicalIndex": "200.3",
      "StrokeCount": 14
    }
  },
  "求学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúxué",
      "PrimaryDefinition": "study, attend school"
    },
    "Keys": {
      "Word": 8771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "债务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàiwù",
      "PrimaryDefinition": "debt, liabilities"
    },
    "Keys": {
      "Word": 10632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "non-glutinous rice"
    },
    "Keys": {
      "Hanzi": 5557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5557,
      "Radical": "米",
      "FrequencyRank": 5458,
      "RadicalIndex": "119.7",
      "StrokeCount": 13
    }
  },
  "枝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "branches, limbs; branch off"
    },
    "Keys": {
      "Hanzi": 1019,
      "Word": 5351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 6,
      "GeneralStandard": 1019,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1491,
      "StrokeCount": 8
    }
  },
  "侧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cè",
      "PrimaryDefinition": "side; incline, slant, lean"
    },
    "Keys": {
      "Hanzi": 1147,
      "Word": 4375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "Grade": 6,
      "GeneralStandard": 1147,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1220,
      "StrokeCount": 8
    }
  },
  "老公": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎogong",
      "PrimaryDefinition": "old man, eunuch, married man, hubby, husband"
    },
    "Keys": {
      "Word": 2684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "strong, unyielding, tough, power"
    },
    "Keys": {
      "Hanzi": 926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.5",
      "GeneralStandard": 926,
      "Radical": "力",
      "HSK": 4,
      "FrequencyRank": 1358,
      "StrokeCount": 7
    }
  },
  "消极": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāojí",
      "PrimaryDefinition": "negative, passive, inactive"
    },
    "Keys": {
      "Word": 4056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "木偶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùǒu",
      "PrimaryDefinition": "puppet"
    },
    "Keys": {
      "Word": 8346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "wooden tablet; edition"
    },
    "Keys": {
      "Hanzi": 5119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5119,
      "Radical": "木",
      "FrequencyRank": 6579,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "踩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎi",
      "PrimaryDefinition": "step on"
    },
    "Keys": {
      "Hanzi": 3243,
      "Word": 4365
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.8",
      "Grade": 6,
      "GeneralStandard": 3243,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 2570,
      "StrokeCount": 15
    }
  },
  "噀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "spirt out of the mouth"
    },
    "Keys": {
      "Hanzi": 7778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7778,
      "Radical": "口",
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "心事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshi",
      "PrimaryDefinition": "weight on one's mind, worry"
    },
    "Keys": {
      "Word": 10013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "融合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rónghé",
      "PrimaryDefinition": "mix together, fuse, stick together"
    },
    "Keys": {
      "Word": 4974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "途中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túzhōng",
      "PrimaryDefinition": "en route"
    },
    "Keys": {
      "Word": 2951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "right conduct, righteousness"
    },
    "Keys": {
      "Hanzi": 57
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.2",
      "GeneralStandard": 57,
      "Radical": "丿",
      "HSK": 3,
      "FrequencyRank": 208,
      "StrokeCount": 3
    }
  },
  "绀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàn",
      "PrimaryDefinition": "dark blue or reddish color"
    },
    "Keys": {
      "Hanzi": 4074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4074,
      "Radical": "糸",
      "FrequencyRank": 5122,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "餮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiè",
      "PrimaryDefinition": "a legendary animal; a greedy person"
    },
    "Keys": {
      "Hanzi": 6327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6327,
      "Radical": "食",
      "FrequencyRank": 5263,
      "RadicalIndex": "184.9",
      "StrokeCount": 18
    }
  },
  "托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuō",
      "PrimaryDefinition": "to hold up with palm; to support, rely on"
    },
    "Keys": {
      "Hanzi": 359,
      "Word": 5111
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "Grade": 6,
      "GeneralStandard": 359,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 799,
      "StrokeCount": 6
    }
  },
  "不断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùduàn",
      "PrimaryDefinition": "unceasingly, continuously"
    },
    "Keys": {
      "Word": 1317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "善": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "good, virtuous, charitable, kind"
    },
    "Keys": {
      "Hanzi": 2738,
      "Word": 8956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "Grade": 7,
      "GeneralStandard": 2738,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 749,
      "StrokeCount": 12
    }
  },
  "制服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìfú",
      "PrimaryDefinition": "uniform"
    },
    "Keys": {
      "Word": 10785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìng",
      "PrimaryDefinition": "hard, firm, strong; obstinate"
    },
    "Keys": {
      "Hanzi": 2589,
      "Word": 4124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.7",
      "Grade": 5,
      "GeneralStandard": 2589,
      "Radical": "石",
      "HSK": 5,
      "FrequencyRank": 1170,
      "StrokeCount": 12
    }
  },
  "鬣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6494,
      "Radical": "髟",
      "FrequencyRank": 5372,
      "RadicalIndex": "190.15",
      "StrokeCount": 25
    }
  },
  "叻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lè",
      "PrimaryDefinition": "used in place names; (Cant.) smart, clever"
    },
    "Keys": {
      "Hanzi": 3540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3540,
      "Radical": "口",
      "FrequencyRank": 6223,
      "RadicalIndex": "30.2",
      "StrokeCount": 5
    }
  },
  "玢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "(archaic) a kind of jade"
    },
    "Keys": {
      "Hanzi": 3830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3830,
      "Radical": "玉",
      "FrequencyRank": 5844,
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "纵横交错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zònghéng-jiāocuò",
      "PrimaryDefinition": "criss-crossed (idiom)"
    },
    "Keys": {
      "Word": 10987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "fence, guard; defend; idle time"
    },
    "Keys": {
      "Hanzi": 846,
      "Word": 4037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.4",
      "Grade": 5,
      "GeneralStandard": 846,
      "Radical": "門",
      "HSK": 5,
      "FrequencyRank": 1529,
      "StrokeCount": 7
    }
  },
  "决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "decide, determine, judge"
    },
    "Keys": {
      "Hanzi": 523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "15.4",
      "GeneralStandard": 523,
      "Radical": "冫",
      "HSK": 3,
      "FrequencyRank": 273,
      "StrokeCount": 6
    }
  },
  "玠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "large jade tablet used by officials at court to indicate their ranks"
    },
    "Keys": {
      "Hanzi": 3829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3829,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "馕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náng",
      "PrimaryDefinition": "bread (persian naan)"
    },
    "Keys": {
      "Hanzi": 6497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6497,
      "Radical": "食",
      "FrequencyRank": 4372,
      "RadicalIndex": "184.22",
      "StrokeCount": 25
    }
  },
  "招生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo shēng",
      "PrimaryDefinition": "recruit students"
    },
    "Keys": {
      "Word": 4195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "尺子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐzi",
      "PrimaryDefinition": "rule, ruler (measuring instrument), CL:把[ba3]"
    },
    "Keys": {
      "Word": 2324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "同人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngrén",
      "PrimaryDefinition": "colleagues, fellowship (among people)"
    },
    "Keys": {
      "Word": 9539
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "dike, embankment"
    },
    "Keys": {
      "Hanzi": 3568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3568,
      "Radical": "土",
      "FrequencyRank": 4291,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "芡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "plant allied to the waterlily"
    },
    "Keys": {
      "Hanzi": 3673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3673,
      "Radical": "艸",
      "FrequencyRank": 5200,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "压制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāzhì",
      "PrimaryDefinition": "suppress, stifle, inhibit, press"
    },
    "Keys": {
      "Word": 10159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "场景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngjǐng",
      "PrimaryDefinition": "stage set, set, scenery, spectacle, scene, setting, set decoration"
    },
    "Keys": {
      "Word": 4397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "奂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "be numerous, brilliant"
    },
    "Keys": {
      "Hanzi": 3744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3744,
      "Radical": "大",
      "FrequencyRank": 3774,
      "RadicalIndex": "37.4",
      "StrokeCount": 7
    }
  },
  "吵嘴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎo zuǐ",
      "PrimaryDefinition": "to quarrel"
    },
    "Keys": {
      "Word": 5835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàn",
      "PrimaryDefinition": "bracelet, armlet"
    },
    "Keys": {
      "Hanzi": 3941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3941,
      "Radical": "金",
      "FrequencyRank": 5164,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "稣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sū",
      "PrimaryDefinition": "revive, to rise again; collect"
    },
    "Keys": {
      "Hanzi": 5529
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5529,
      "Radical": "魚",
      "FrequencyRank": 2292,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "政党": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngdǎng",
      "PrimaryDefinition": "political party"
    },
    "Keys": {
      "Word": 5345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "刻苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèkǔ",
      "PrimaryDefinition": "hardworking, assiduous"
    },
    "Keys": {
      "Word": 7811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuā",
      "PrimaryDefinition": "beautiful, handsome, elegant"
    },
    "Keys": {
      "Hanzi": 6912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6912,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "獍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "a mythical animal that eats its mother when it is born; Manchurian tiger"
    },
    "Keys": {
      "Hanzi": 7710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7710,
      "Radical": "犬",
      "FrequencyRank": 6004,
      "RadicalIndex": "94.11",
      "StrokeCount": 14
    }
  },
  "酉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "wine"
    },
    "Keys": {
      "Radical": 164,
      "Hanzi": 698
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 698,
      "Radical": "酉",
      "FrequencyRank": 3839,
      "RadicalIndex": "164",
      "StrokeCount": 7
    }
  },
  "徼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "frontier, border; inspect, patrol"
    },
    "Keys": {
      "Hanzi": 6134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6134,
      "Radical": "彳",
      "FrequencyRank": 6219,
      "RadicalIndex": "60.13",
      "StrokeCount": 16
    }
  },
  "槱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "firewood for sacrifice"
    },
    "Keys": {
      "Hanzi": 7760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7760,
      "Radical": "木",
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "蒐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "collect, gather, assemble; seek; spring hunt; assemble for war"
    },
    "Keys": {
      "Hanzi": 7313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7313,
      "Radical": "艸",
      "FrequencyRank": 6707,
      "RadicalIndex": "140.1",
      "StrokeCount": 12
    }
  },
  "貆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "small badger; porcupine"
    },
    "Keys": {
      "Hanzi": 7553
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7553,
      "Radical": "豸",
      "RadicalIndex": "153.6",
      "StrokeCount": 13
    }
  },
  "新款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnkuǎn",
      "PrimaryDefinition": "new style, latest fashion, new model"
    },
    "Keys": {
      "Word": 10028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒu",
      "PrimaryDefinition": "see, look, gaze at"
    },
    "Keys": {
      "Hanzi": 3101,
      "Word": 5949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.9",
      "Grade": 7,
      "GeneralStandard": 3101,
      "Radical": "目",
      "HSK": 9,
      "FrequencyRank": 2767,
      "StrokeCount": 14
    }
  },
  "脘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "internal cavity of stomach"
    },
    "Keys": {
      "Hanzi": 4922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4922,
      "Radical": "肉",
      "FrequencyRank": 5766,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "锐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": "sharp, keen, acute, pointed"
    },
    "Keys": {
      "Hanzi": 2666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "GeneralStandard": 2666,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 1851,
      "StrokeCount": 12
    }
  },
  "宪法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànfǎ",
      "PrimaryDefinition": "constitution, charter"
    },
    "Keys": {
      "Word": 9906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宝藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎozàng",
      "PrimaryDefinition": "precious (mineral) deposits, (budd.) the treasure of Buddha's law"
    },
    "Keys": {
      "Word": 5518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "船只": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánzhī",
      "PrimaryDefinition": "boat, watercraft, marine, ship, vessel"
    },
    "Keys": {
      "Word": 4445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "公然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngrán",
      "PrimaryDefinition": "openly, publicly, undisguised"
    },
    "Keys": {
      "Word": 6772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "殿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "hall; palace; temple"
    },
    "Keys": {
      "Hanzi": 3036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "79.9",
      "GeneralStandard": 3036,
      "Radical": "殳",
      "HSK": 8,
      "FrequencyRank": 1555,
      "StrokeCount": 13
    }
  },
  "鼒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "large tripod caldron with small mouth"
    },
    "Keys": {
      "Hanzi": 7759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7759,
      "Radical": "鼎",
      "RadicalIndex": "206.3",
      "StrokeCount": 15
    }
  },
  "启动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐdòng",
      "PrimaryDefinition": "start"
    },
    "Keys": {
      "Word": 3788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瓻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "jar"
    },
    "Keys": {
      "Hanzi": 7208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7208,
      "Radical": "瓦",
      "RadicalIndex": "98.7",
      "StrokeCount": 11
    }
  },
  "孙子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūnzi",
      "PrimaryDefinition": "son's son, grandson"
    },
    "Keys": {
      "Word": 2911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "菇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "mushrooms"
    },
    "Keys": {
      "Hanzi": 2218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2218,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 3287,
      "StrokeCount": 11
    }
  },
  "通道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngdào",
      "PrimaryDefinition": "path, scuttle, headway, corridor, passageway, gateway, gallery, close, walkway, areaway, hatchway, thoroughfare, lobby, winze, passage, aisle, opening, gangway, gap, passage(way), clearance, passing, headroom, enterclose, inlet, jackladder, access, byway"
    },
    "Keys": {
      "Word": 5089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "簪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zān",
      "PrimaryDefinition": "hairpin, clasp; wear in hair"
    },
    "Keys": {
      "Hanzi": 6349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6349,
      "Radical": "竹",
      "FrequencyRank": 4297,
      "RadicalIndex": "118.12",
      "StrokeCount": 18
    }
  },
  "假冒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎmào",
      "PrimaryDefinition": "to impersonate, to pose as (someone else), to counterfeit, to palm off (a fake as a genuine)"
    },
    "Keys": {
      "Word": 7359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuàn",
      "PrimaryDefinition": "run away; revise, edit; expel"
    },
    "Keys": {
      "Hanzi": 2783,
      "Word": 6076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "116.7",
      "Grade": 7,
      "GeneralStandard": 2783,
      "Radical": "穴",
      "HSK": 8,
      "FrequencyRank": 2461,
      "StrokeCount": 12
    }
  },
  "方面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngmiàn",
      "PrimaryDefinition": "side, aspect, sector, part, light, facet, behalf, way, respect, direction, bearing, field, phase, sphere, angle, hand"
    },
    "Keys": {
      "Word": 654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "阅读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèdú",
      "PrimaryDefinition": "study, read, peruse"
    },
    "Keys": {
      "Word": 3138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "翚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5324,
      "Radical": "羽",
      "FrequencyRank": 8408,
      "RadicalIndex": "124.6",
      "StrokeCount": 12
    }
  },
  "总统": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngtǒng",
      "PrimaryDefinition": "president (of a republic)"
    },
    "Keys": {
      "Word": 3213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "连锁店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánsuǒdiàn",
      "PrimaryDefinition": "chain store"
    },
    "Keys": {
      "Word": 8015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "总部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngbù",
      "PrimaryDefinition": "base of operations, head office, armory, base, headquarters"
    },
    "Keys": {
      "Word": 5400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "莹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "luster of gems; bright, lustrous"
    },
    "Keys": {
      "Hanzi": 1830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.7",
      "GeneralStandard": 1830,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2668,
      "StrokeCount": 10
    }
  },
  "人群": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénqún",
      "PrimaryDefinition": "multitude, scrooge, herd, ruck, scrouge, meiny, crowd, troop, confluence, drove, throng, flock, gang"
    },
    "Keys": {
      "Word": 1848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "script"
    },
    "Keys": {
      "Hanzi": 161,
      "Radical": 67,
      "Word": 9731
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "67",
      "Grade": 7,
      "GeneralStandard": 161,
      "Radical": "文",
      "HSK": 1,
      "FrequencyRank": 148,
      "StrokeCount": 4
    }
  },
  "坤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "earth; feminine, female"
    },
    "Keys": {
      "Hanzi": 964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 964,
      "Radical": "土",
      "FrequencyRank": 2910,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài",
      "PrimaryDefinition": "injure, harm; destroy, kill"
    },
    "Keys": {
      "Hanzi": 2097,
      "Word": 3531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "Grade": 5,
      "GeneralStandard": 2097,
      "Radical": "宀",
      "HSK": 3,
      "FrequencyRank": 579,
      "StrokeCount": 10
    }
  },
  "窊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wā",
      "PrimaryDefinition": "pit, vault; store in pit; (Cant.) 手窊, the arm"
    },
    "Keys": {
      "Hanzi": 7073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7073,
      "Radical": "穴",
      "FrequencyRank": 8322,
      "RadicalIndex": "116.5",
      "StrokeCount": 10
    }
  },
  "琡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7285,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "归结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guījié",
      "PrimaryDefinition": "to sum up, to conclude, in a nutshell, the end (of a story)"
    },
    "Keys": {
      "Word": 6910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "檞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiě",
      "PrimaryDefinition": "a kind of oak"
    },
    "Keys": {
      "Hanzi": 7927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7927,
      "Radical": "木",
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "拆迁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāiqiān",
      "PrimaryDefinition": "to demolish a building and relocate the inhabitants"
    },
    "Keys": {
      "Word": 4384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "欣欣向荣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxīn-xiàngróng",
      "PrimaryDefinition": "luxuriant growth (idiom), flourishing, thriving"
    },
    "Keys": {
      "Word": 10024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "fire; wild fires"
    },
    "Keys": {
      "Hanzi": 6326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6326,
      "Radical": "火",
      "FrequencyRank": 5929,
      "RadicalIndex": "86.14",
      "StrokeCount": 18
    }
  },
  "炀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "roast; scorch; melt; flame"
    },
    "Keys": {
      "Hanzi": 3765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3765,
      "Radical": "火",
      "FrequencyRank": 4395,
      "RadicalIndex": "86.3",
      "StrokeCount": 7
    }
  },
  "涉及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèjí",
      "PrimaryDefinition": "come to, pertain, involvement, implicate, entail, relate, cover, deal, concern, bear on, run_on, involve, touch on, refer, touch upon, deal with, touch, come_to, relate to"
    },
    "Keys": {
      "Word": 5005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "体制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐzhì",
      "PrimaryDefinition": "frame, organization, system of organization, framework, system, apparatus, framing, organisation"
    },
    "Keys": {
      "Word": 9469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuó",
      "PrimaryDefinition": "a dwarf"
    },
    "Keys": {
      "Hanzi": 5192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5192,
      "Radical": "矢",
      "FrequencyRank": 5541,
      "RadicalIndex": "111.7",
      "StrokeCount": 12
    }
  },
  "威风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēifēng",
      "PrimaryDefinition": "might, awe-inspiring authority, impressive"
    },
    "Keys": {
      "Word": 9686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尚未": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngwèi",
      "PrimaryDefinition": "as yet, til now, heretofore, yet, thus far, so far, until now, up to now, hitherto, not_yet, not yet"
    },
    "Keys": {
      "Word": 8989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duān",
      "PrimaryDefinition": "specialized; concentrated"
    },
    "Keys": {
      "Hanzi": 6837
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6837,
      "Radical": "而",
      "FrequencyRank": 9047,
      "RadicalIndex": "126.3",
      "StrokeCount": 9
    }
  },
  "隐瞒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnmán",
      "PrimaryDefinition": "to conceal, to hide (a taboo subject), to cover up the truth"
    },
    "Keys": {
      "Word": 10400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "留心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú xīn",
      "PrimaryDefinition": "to be careful, to pay attention to"
    },
    "Keys": {
      "Word": 8093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāo",
      "PrimaryDefinition": "a bladder"
    },
    "Keys": {
      "Hanzi": 4921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4921,
      "Radical": "肉",
      "FrequencyRank": 6817,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "身影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnyǐng",
      "PrimaryDefinition": "figure, silhouette, form"
    },
    "Keys": {
      "Word": 9024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "profession, business; GB radical 111"
    },
    "Keys": {
      "Hanzi": 231,
      "Word": 10262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "Grade": 7,
      "GeneralStandard": 231,
      "Radical": "一",
      "HSK": 2,
      "FrequencyRank": 130,
      "StrokeCount": 5
    }
  },
  "卉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "general term for plants; myriads"
    },
    "Keys": {
      "Hanzi": 200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.3",
      "GeneralStandard": 200,
      "Radical": "十",
      "HSK": 9,
      "FrequencyRank": 3341,
      "StrokeCount": 5
    }
  },
  "烟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "smoke, soot; opium; tobacco, cigarettes"
    },
    "Keys": {
      "Hanzi": 2062,
      "Word": 2077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "Grade": 3,
      "GeneralStandard": 2062,
      "Radical": "火",
      "HSK": 3,
      "FrequencyRank": 967,
      "StrokeCount": 10
    }
  },
  "铖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7181
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7181,
      "Radical": "金",
      "FrequencyRank": 6488,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "郊外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāowài",
      "PrimaryDefinition": "outskirts"
    },
    "Keys": {
      "Word": 7465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoděng",
      "PrimaryDefinition": "higher, advanced"
    },
    "Keys": {
      "Word": 4595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "狡猾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎohuá",
      "PrimaryDefinition": "crafty, cunning, sly"
    },
    "Keys": {
      "Word": 7479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōbiān",
      "PrimaryDefinition": "multilateral"
    },
    "Keys": {
      "Word": 6391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自尊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìzūn",
      "PrimaryDefinition": "self-worth, self-regard, pridefulness, pride, self-pride, self-respect, self-importance, self-esteem"
    },
    "Keys": {
      "Word": 10973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "后盾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòudùn",
      "PrimaryDefinition": "support, backing"
    },
    "Keys": {
      "Word": 7092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遗愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíyuàn",
      "PrimaryDefinition": "final wishes of the departed"
    },
    "Keys": {
      "Word": 10308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "wart; tumor; goiter; papule"
    },
    "Keys": {
      "Hanzi": 4309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4309,
      "Radical": "疒",
      "FrequencyRank": 3776,
      "RadicalIndex": "104.4",
      "StrokeCount": 9
    }
  },
  "顼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "grieved"
    },
    "Keys": {
      "Hanzi": 4403
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4403,
      "Radical": "玉",
      "FrequencyRank": 3579,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "牦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "tail; hair; yak"
    },
    "Keys": {
      "Hanzi": 3947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3947,
      "Radical": "牛",
      "FrequencyRank": 5337,
      "RadicalIndex": "93.4",
      "StrokeCount": 8
    }
  },
  "褥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù",
      "PrimaryDefinition": "mattress, cushion; bedding"
    },
    "Keys": {
      "Hanzi": 3306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3306,
      "Radical": "衣",
      "FrequencyRank": 3453,
      "RadicalIndex": "145.1",
      "StrokeCount": 15
    }
  },
  "咖啡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāfēi",
      "PrimaryDefinition": "arabica, coffee, cafe, Java, java, mocha, blackstrap"
    },
    "Keys": {
      "Word": 1660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "创业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngyè",
      "PrimaryDefinition": "start undertaking, do pioneering work"
    },
    "Keys": {
      "Word": 1373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "琚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "girdle ornaments"
    },
    "Keys": {
      "Hanzi": 5056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5056,
      "Radical": "玉",
      "FrequencyRank": 6604,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "灶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "kitchen stove, cooking stove"
    },
    "Keys": {
      "Hanzi": 851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.3",
      "GeneralStandard": 851,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 2691,
      "StrokeCount": 7
    }
  },
  "镚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèng",
      "PrimaryDefinition": "small coin"
    },
    "Keys": {
      "Hanzi": 6115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6115,
      "Radical": "金",
      "FrequencyRank": 7307,
      "RadicalIndex": "167.11",
      "StrokeCount": 16
    }
  },
  "海军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎijūn",
      "PrimaryDefinition": "marine, naval, admiralty, jolly, navy"
    },
    "Keys": {
      "Word": 4651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "顶尖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐngjiān",
      "PrimaryDefinition": "towering"
    },
    "Keys": {
      "Word": 6304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "获得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòdé",
      "PrimaryDefinition": "find, cop, acquirement, come_into, gain, draw, pick_up, pull, achievement, score, procure, come_by, get hold, purchase, nab, come upon, derive, latch, incur, achieve, make, bring, receive, arrive, come_out, pocket, obtain, luck into, sack, win, enter upon, pick up, garner, harvest, attain, come up, line up, extract, secure, possess, earn, reap, accrue, cover, take, acquire, get, arrive_at"
    },
    "Keys": {
      "Word": 2569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "品质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐnzhì",
      "PrimaryDefinition": "calibre, appraisal, caliber, character, self, intrinsic, brew, quality, timber, sort"
    },
    "Keys": {
      "Word": 2779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "㴔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "(same as 潝) the noise of flowing water, swift flowing water (same as non-classical form of 澀) rough; harsh; not smooth, a slightly bitter taste"
    },
    "Keys": {
      "Hanzi": 7431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7431,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "墁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "to plaster; to pave"
    },
    "Keys": {
      "Hanzi": 5632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5632,
      "Radical": "土",
      "FrequencyRank": 6678,
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "茁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "to sprout, flourish; sprouts appearing above-ground; vigorous"
    },
    "Keys": {
      "Hanzi": 1012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1012,
      "Radical": "艸",
      "FrequencyRank": 4472,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "泗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "mucous; nasal flow; sniffle; river in Shandong"
    },
    "Keys": {
      "Hanzi": 4015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4015,
      "Radical": "水",
      "FrequencyRank": 4388,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "埵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒ",
      "PrimaryDefinition": "hardened dirt or clay; cluster"
    },
    "Keys": {
      "Hanzi": 4723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4723,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "临街": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línjiē",
      "PrimaryDefinition": "facing the street"
    },
    "Keys": {
      "Word": 8060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "select, choose; surname"
    },
    "Keys": {
      "Hanzi": 5990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5990,
      "Radical": "辵",
      "FrequencyRank": 5067,
      "RadicalIndex": "162.12",
      "StrokeCount": 15
    }
  },
  "金属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnshǔ",
      "PrimaryDefinition": "spangle, metallic, metalline, tincture, malleability, metal, metals in general"
    },
    "Keys": {
      "Word": 7559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悬崖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuányá",
      "PrimaryDefinition": "precipice, overhanging cliff"
    },
    "Keys": {
      "Word": 10121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "意向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìxiàng",
      "PrimaryDefinition": "intention, purpose, intent, inclination, disposition"
    },
    "Keys": {
      "Word": 10376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "内地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèidì",
      "PrimaryDefinition": "mainland China (PRC excluding Hong Kong and Macau, but including islands such as Hainan), Japan (used in Taiwan during Japanese colonization), inland, interior, hinterland"
    },
    "Keys": {
      "Word": 4889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiān",
      "PrimaryDefinition": "sky, heaven; god, celestial"
    },
    "Keys": {
      "Hanzi": 78,
      "Word": 354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.1",
      "Grade": 1,
      "GeneralStandard": 78,
      "Radical": "大",
      "HSK": 1,
      "FrequencyRank": 78,
      "StrokeCount": 4
    }
  },
  "歼灭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānmiè",
      "PrimaryDefinition": "to wipe out, to crush, to annihilate"
    },
    "Keys": {
      "Word": 7378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "steps leading throne; throne"
    },
    "Keys": {
      "Hanzi": 4380
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4380,
      "Radical": "阜",
      "FrequencyRank": 2667,
      "RadicalIndex": "170.7",
      "StrokeCount": 9
    }
  },
  "契": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "deed, contract, bond; engrave"
    },
    "Keys": {
      "Hanzi": 1352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.6",
      "GeneralStandard": 1352,
      "Radical": "大",
      "HSK": 8,
      "FrequencyRank": 1819,
      "StrokeCount": 9
    }
  },
  "开关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāiguān",
      "PrimaryDefinition": "power switch, to open a gate"
    },
    "Keys": {
      "Word": 4778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "掎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "drag aside, pull; drag one foot"
    },
    "Keys": {
      "Hanzi": 7110
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7110,
      "Radical": "手",
      "FrequencyRank": 7551,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "衬衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chènyī",
      "PrimaryDefinition": "shirt, CL:件[jian4]"
    },
    "Keys": {
      "Word": 1345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "叇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7751
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7751,
      "Radical": "厶",
      "RadicalIndex": "28.13",
      "StrokeCount": 15
    }
  },
  "积": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "accumulate, store up, amass"
    },
    "Keys": {
      "Hanzi": 1945,
      "Word": 7270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "Grade": 7,
      "GeneralStandard": 1945,
      "Radical": "禾",
      "HSK": 3,
      "FrequencyRank": 728,
      "StrokeCount": 10
    }
  },
  "糖果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tángguǒ",
      "PrimaryDefinition": "candy, CL:粒[li4]"
    },
    "Keys": {
      "Word": 9426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "薏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6052,
      "Radical": "艸",
      "FrequencyRank": 4728,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "螯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "nippers"
    },
    "Keys": {
      "Hanzi": 6035
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6035,
      "Radical": "虫",
      "FrequencyRank": 5610,
      "RadicalIndex": "142.11",
      "StrokeCount": 16
    }
  },
  "征": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "invade, attack, conquer"
    },
    "Keys": {
      "Hanzi": 1159,
      "Word": 10721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "60.5",
      "Grade": 7,
      "GeneralStandard": 1159,
      "Radical": "彳",
      "HSK": 4,
      "FrequencyRank": 739,
      "StrokeCount": 8
    }
  },
  "炣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kě",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6877,
      "Radical": "火",
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "踽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "to walk alone; self-reliant"
    },
    "Keys": {
      "Hanzi": 6094
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6094,
      "Radical": "足",
      "FrequencyRank": 5222,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "画龙点睛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàlóng-diǎnjīng",
      "PrimaryDefinition": "to paint a dragon and dot in the eyes (idiom), fig. to add the vital finishing touch, the crucial point that brings the subject to life, a few words to clinch the point"
    },
    "Keys": {
      "Word": 7147
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "dyspepsia, spleen infection"
    },
    "Keys": {
      "Hanzi": 5256
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5256,
      "Radical": "疒",
      "FrequencyRank": 4093,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "过后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòhòu",
      "PrimaryDefinition": "after the event"
    },
    "Keys": {
      "Word": 4644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "也": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yě",
      "PrimaryDefinition": "also; classical final particle of strong affirmation or identity"
    },
    "Keys": {
      "Hanzi": 66,
      "Word": 424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "5.2",
      "Grade": 1,
      "GeneralStandard": 66,
      "Radical": "乙",
      "HSK": 1,
      "FrequencyRank": 31,
      "StrokeCount": 3
    }
  },
  "身材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēncái",
      "PrimaryDefinition": "stature, figure"
    },
    "Keys": {
      "Word": 2848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "延期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán qī",
      "PrimaryDefinition": "to delay, to extend, to postpone, to defer"
    },
    "Keys": {
      "Word": 3062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "墼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6065,
      "Radical": "土",
      "FrequencyRank": 6304,
      "RadicalIndex": "32.13",
      "StrokeCount": 16
    }
  },
  "高山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoshān",
      "PrimaryDefinition": "jebel, high mountain, mountain, alp, mount, ben"
    },
    "Keys": {
      "Word": 6703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "登机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng jī",
      "PrimaryDefinition": "to board a plane"
    },
    "Keys": {
      "Word": 6221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìfā",
      "PrimaryDefinition": "spontaneous"
    },
    "Keys": {
      "Word": 10954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遥远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáoyuǎn",
      "PrimaryDefinition": "distant, faraway, remote"
    },
    "Keys": {
      "Word": 10240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "thick, strong (beverage)"
    },
    "Keys": {
      "Hanzi": 5672
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5672,
      "Radical": "酉",
      "FrequencyRank": 6040,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "稿子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎozi",
      "PrimaryDefinition": "draft of a document, script, manuscript, mental plan, precedent"
    },
    "Keys": {
      "Word": 4598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "皿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "dish"
    },
    "Keys": {
      "Radical": 108,
      "Hanzi": 259
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 259,
      "Radical": "皿",
      "FrequencyRank": 3763,
      "RadicalIndex": "108",
      "StrokeCount": 5
    }
  },
  "咧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liě",
      "PrimaryDefinition": "stretch mouth, grimace, grin"
    },
    "Keys": {
      "Hanzi": 1490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "GeneralStandard": 1490,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2741,
      "StrokeCount": 9
    }
  },
  "驱逐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūzhú",
      "PrimaryDefinition": "to expel, to deport, banishment"
    },
    "Keys": {
      "Word": 8780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "直接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíjiē",
      "PrimaryDefinition": "direct, immediate"
    },
    "Keys": {
      "Word": 1218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "边界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānjiè",
      "PrimaryDefinition": "confine, margin, bounds, marchland, boundary, demarcation line, demarcation, borderline, precinct, skirt, frontier, list, borderland, delimitation, butting, bound, boundary line, perimeter, outskirts, coast, march, line, border, mete, limit, skirting, edge"
    },
    "Keys": {
      "Word": 5595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "投奔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóubèn",
      "PrimaryDefinition": "to seek shelter, to seek asylum"
    },
    "Keys": {
      "Word": 9560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "读者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúzhě",
      "PrimaryDefinition": "audience, subscriber, reader"
    },
    "Keys": {
      "Word": 1416
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "上班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng bān",
      "PrimaryDefinition": "start work, go to work, be on duty"
    },
    "Keys": {
      "Word": 313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "盏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎn",
      "PrimaryDefinition": "small cup or container"
    },
    "Keys": {
      "Hanzi": 1779,
      "Word": 10641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.5",
      "Grade": 7,
      "GeneralStandard": 1779,
      "Radical": "皿",
      "HSK": 8,
      "FrequencyRank": 2803,
      "StrokeCount": 10
    }
  },
  "狷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": "rash, impetuous, impulsive"
    },
    "Keys": {
      "Hanzi": 4601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4601,
      "Radical": "犬",
      "FrequencyRank": 5730,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "大夫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàfū",
      "PrimaryDefinition": "a senior official in feudal China, physician, senior official, doctor"
    },
    "Keys": {
      "Word": 1389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "古": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "old, classic, ancient"
    },
    "Keys": {
      "Hanzi": 208,
      "Word": 1513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 3,
      "GeneralStandard": 208,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 509,
      "StrokeCount": 5
    }
  },
  "长达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángdá",
      "PrimaryDefinition": "lengthen out to"
    },
    "Keys": {
      "Word": 5809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "与其": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔqí",
      "PrimaryDefinition": "rather than"
    },
    "Keys": {
      "Word": 10503
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuān",
      "PrimaryDefinition": "hurry; to throw; to urge"
    },
    "Keys": {
      "Hanzi": 5867
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5867,
      "Radical": "手",
      "FrequencyRank": 5780,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huó",
      "PrimaryDefinition": "live, exist, survive; lively"
    },
    "Keys": {
      "Hanzi": 1672,
      "Word": 1568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 3,
      "GeneralStandard": 1672,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 219,
      "StrokeCount": 9
    }
  },
  "基础": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīchǔ",
      "PrimaryDefinition": "base, foundation"
    },
    "Keys": {
      "Word": 1574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "乜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miē",
      "PrimaryDefinition": "to squint; (Cant.) what? huh?"
    },
    "Keys": {
      "Hanzi": 3502
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3502,
      "Radical": "乙",
      "FrequencyRank": 5265,
      "RadicalIndex": "5.1",
      "StrokeCount": 2
    }
  },
  "僳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "minority name"
    },
    "Keys": {
      "Hanzi": 5757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5757,
      "Radical": "人",
      "FrequencyRank": 5386,
      "RadicalIndex": "9.12",
      "StrokeCount": 14
    }
  },
  "归": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "return; return to, revert to"
    },
    "Keys": {
      "Hanzi": 234,
      "Word": 2518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "58.2",
      "Grade": 4,
      "GeneralStandard": 234,
      "Radical": "彐",
      "HSK": 4,
      "FrequencyRank": 933,
      "StrokeCount": 5
    }
  },
  "殪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "die; kill, exterminate"
    },
    "Keys": {
      "Hanzi": 6075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6075,
      "Radical": "歹",
      "FrequencyRank": 7255,
      "RadicalIndex": "78.12",
      "StrokeCount": 16
    }
  },
  "栝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4456,
      "Radical": "木",
      "FrequencyRank": 7263,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "垟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6777,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "heart"
    },
    "Keys": {
      "Hanzi": 174,
      "Radical": 61,
      "Word": 2052
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61",
      "Grade": 3,
      "GeneralStandard": 174,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 90,
      "StrokeCount": 4
    }
  },
  "杂技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zájì",
      "PrimaryDefinition": "acrobatics, CL:場|场[chang3]"
    },
    "Keys": {
      "Word": 10583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公寓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyù",
      "PrimaryDefinition": "flats, apartment house, public housing"
    },
    "Keys": {
      "Word": 6779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "此刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐkè",
      "PrimaryDefinition": "this moment, now, at present"
    },
    "Keys": {
      "Word": 3362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "核实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héshí",
      "PrimaryDefinition": "to verify, to check"
    },
    "Keys": {
      "Word": 7051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "告别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàobié",
      "PrimaryDefinition": "valedictory, take_leave, part from, leave, parting, say good-bye to, bid farewell to"
    },
    "Keys": {
      "Word": 1483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "丑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒu",
      "PrimaryDefinition": "clown, comedian; 2nd terrestrial branch"
    },
    "Keys": {
      "Hanzi": 177,
      "Word": 3340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.3",
      "Grade": 5,
      "GeneralStandard": 177,
      "Radical": "一",
      "HSK": 5,
      "FrequencyRank": 1901,
      "StrokeCount": 4
    }
  },
  "固定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùdìng",
      "PrimaryDefinition": "set, plant, imbed, fix, strap, jell, fixed, affix, peg, stick, clench, rivet, regularization, rigidify, embed, infix, anchor, fixate, hold, regularize, implant, stitch, stabile, engraft, fasten, immobilize, moor, peg down, flat"
    },
    "Keys": {
      "Word": 2510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "想象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngxiàng",
      "PrimaryDefinition": "to imagine, to fancy, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "澥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "a blocked stream; gulf"
    },
    "Keys": {
      "Hanzi": 6179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6179,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "完毕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánbì",
      "PrimaryDefinition": "end, conclude, complete, completion, finish"
    },
    "Keys": {
      "Word": 9649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "basket, bucket"
    },
    "Keys": {
      "Hanzi": 5503
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5503,
      "Radical": "竹",
      "FrequencyRank": 6158,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "守": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒu",
      "PrimaryDefinition": "defend, protect, guard, conserve"
    },
    "Keys": {
      "Hanzi": 546,
      "Word": 2877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.3",
      "Grade": 4,
      "GeneralStandard": 546,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 796,
      "StrokeCount": 6
    }
  },
  "扈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "escort, retinue; insolent"
    },
    "Keys": {
      "Hanzi": 4996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4996,
      "Radical": "戶",
      "FrequencyRank": 3917,
      "RadicalIndex": "63.7",
      "StrokeCount": 11
    }
  },
  "老师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoshī",
      "PrimaryDefinition": "rabbi, professor, swami, instructor, teacher, mast"
    },
    "Keys": {
      "Word": 206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "tree; plant; set up, establish"
    },
    "Keys": {
      "Hanzi": 1439,
      "Word": 340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 1,
      "GeneralStandard": 1439,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 697,
      "StrokeCount": 9
    }
  },
  "丕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "great, grand, glorious, distinguished"
    },
    "Keys": {
      "Hanzi": 3535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3535,
      "Radical": "一",
      "FrequencyRank": 3748,
      "RadicalIndex": "1.4",
      "StrokeCount": 5
    }
  },
  "瘅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "to hate bitterly; drought; dry"
    },
    "Keys": {
      "Hanzi": 7573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7573,
      "Radical": "疒",
      "FrequencyRank": 7446,
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "高效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoxiào",
      "PrimaryDefinition": "efficient, highly effective"
    },
    "Keys": {
      "Word": 6704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénshì",
      "PrimaryDefinition": "public figure, personage"
    },
    "Keys": {
      "Word": 3837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "踣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "to stumble and fall prone stiff in death"
    },
    "Keys": {
      "Hanzi": 7771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7771,
      "Radical": "足",
      "FrequencyRank": 7037,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "菡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "buds, lotus buds"
    },
    "Keys": {
      "Hanzi": 4769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4769,
      "Radical": "艸",
      "FrequencyRank": 6167,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "涢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7059,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 10
    }
  },
  "矩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "111.5",
      "GeneralStandard": 1540,
      "Radical": "矢",
      "HSK": 7,
      "FrequencyRank": 2160,
      "StrokeCount": 9
    }
  },
  "艟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "ancient warship"
    },
    "Keys": {
      "Hanzi": 6352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6352,
      "Radical": "舟",
      "FrequencyRank": 6100,
      "RadicalIndex": "137.12",
      "StrokeCount": 18
    }
  },
  "电讯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànxùn",
      "PrimaryDefinition": "telegraph, telecommunications, telecommunication, flash, telegraphic dispatch"
    },
    "Keys": {
      "Word": 6277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòu",
      "PrimaryDefinition": "smallpox"
    },
    "Keys": {
      "Hanzi": 2731
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2731,
      "Radical": "疒",
      "FrequencyRank": 4106,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "属于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔyú",
      "PrimaryDefinition": "fall under, be part of, pertain, appertain, belong, relegate, fall into, belong_to, fall_under, reside, belong to, attach"
    },
    "Keys": {
      "Word": 1938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "闭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "shut, close; obstruct, block up"
    },
    "Keys": {
      "Hanzi": 527,
      "Word": 4328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.3",
      "Grade": 6,
      "GeneralStandard": 527,
      "Radical": "門",
      "HSK": 4,
      "FrequencyRank": 1267,
      "StrokeCount": 6
    }
  },
  "密不可分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìbùkěfēn",
      "PrimaryDefinition": "inextricably linked (idiom), inseparable"
    },
    "Keys": {
      "Word": 8268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "an intelligence report; to spy; spying"
    },
    "Keys": {
      "Hanzi": 2460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.9",
      "GeneralStandard": 2460,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2391,
      "StrokeCount": 11
    }
  },
  "送别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng bié",
      "PrimaryDefinition": "farewell"
    },
    "Keys": {
      "Word": 9335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "old"
    },
    "Keys": {
      "Hanzi": 360,
      "Radical": 125,
      "Word": 836
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "125",
      "Grade": 2,
      "GeneralStandard": 360,
      "Radical": "老",
      "HSK": 1,
      "FrequencyRank": 179,
      "StrokeCount": 6
    }
  },
  "查询": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháxún",
      "PrimaryDefinition": "inquire into/about"
    },
    "Keys": {
      "Word": 3303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "硿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7509
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7509,
      "Radical": "石",
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "路边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùbiān",
      "PrimaryDefinition": "curb, roadside, wayside"
    },
    "Keys": {
      "Word": 863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "高度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāodù",
      "PrimaryDefinition": "highly, greatly"
    },
    "Keys": {
      "Word": 3483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "猱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "a monkey with yellow hair"
    },
    "Keys": {
      "Hanzi": 5245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5245,
      "Radical": "犬",
      "FrequencyRank": 6431,
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "嗣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "to connect, inherit; descendants, heirs"
    },
    "Keys": {
      "Hanzi": 5466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5466,
      "Radical": "口",
      "FrequencyRank": 2693,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "摘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāi",
      "PrimaryDefinition": "pluck, pick; select; specify"
    },
    "Keys": {
      "Hanzi": 3062,
      "Word": 4184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.11",
      "Grade": 5,
      "GeneralStandard": 3062,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 2026,
      "StrokeCount": 14
    }
  },
  "耍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǎ",
      "PrimaryDefinition": "frolic, play, amuse, play with"
    },
    "Keys": {
      "Hanzi": 1457,
      "Word": 9238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "126.3",
      "Grade": 7,
      "GeneralStandard": 1457,
      "Radical": "而",
      "HSK": 7,
      "FrequencyRank": 2260,
      "StrokeCount": 9
    }
  },
  "专柜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānguì",
      "PrimaryDefinition": "shoppe"
    },
    "Keys": {
      "Word": 10891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "误区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùqū",
      "PrimaryDefinition": "mistaken ideas, misconceptions, the error of one's ways"
    },
    "Keys": {
      "Word": 9814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "倡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng",
      "PrimaryDefinition": "guide, leader; lead, introduce"
    },
    "Keys": {
      "Hanzi": 1963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1963,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 2185,
      "StrokeCount": 10
    }
  },
  "纱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "gauze, thin silk; yarn, thread"
    },
    "Keys": {
      "Hanzi": 932,
      "Word": 8936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "Grade": 7,
      "GeneralStandard": 932,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 2430,
      "StrokeCount": 7
    }
  },
  "赞赏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànshǎng",
      "PrimaryDefinition": "to admire, to praise, to appreciate"
    },
    "Keys": {
      "Word": 3146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "离奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líqí",
      "PrimaryDefinition": "odd, bizarre"
    },
    "Keys": {
      "Word": 7975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāng",
      "PrimaryDefinition": "blue; green"
    },
    "Keys": {
      "Hanzi": 669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 669,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 1728,
      "StrokeCount": 7
    }
  },
  "灵机一动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língjī yī dòng",
      "PrimaryDefinition": "a bright idea suddenly occurs (idiom), to hit upon an inspiration, to be struck by a brainwave"
    },
    "Keys": {
      "Word": 8068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "to peck; (Cant.) to slander"
    },
    "Keys": {
      "Hanzi": 2271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2271,
      "Radical": "口",
      "FrequencyRank": 3449,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "精神病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngshénbìng",
      "PrimaryDefinition": "mental disorder, psychosis"
    },
    "Keys": {
      "Word": 7619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "smooth board; chessboard; chess"
    },
    "Keys": {
      "Hanzi": 4145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4145,
      "Radical": "木",
      "FrequencyRank": 5099,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cì",
      "PrimaryDefinition": "order, sequence; next"
    },
    "Keys": {
      "Hanzi": 521,
      "Word": 2350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "76.2",
      "Grade": 4,
      "GeneralStandard": 521,
      "Radical": "欠",
      "HSK": 1,
      "FrequencyRank": 183,
      "StrokeCount": 6
    }
  },
  "撑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "prop up, support; brace"
    },
    "Keys": {
      "Hanzi": 3196,
      "Word": 4409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 6,
      "GeneralStandard": 3196,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2183,
      "StrokeCount": 15
    }
  },
  "借口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièkǒu",
      "PrimaryDefinition": "to use as an excuse, on the pretext, excuse, pretext"
    },
    "Keys": {
      "Word": 7553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鱿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "cuttlefish"
    },
    "Keys": {
      "Hanzi": 5235
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5235,
      "Radical": "魚",
      "FrequencyRank": 4622,
      "RadicalIndex": "195.4",
      "StrokeCount": 12
    }
  },
  "伤口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngkǒu",
      "PrimaryDefinition": "wound, cut"
    },
    "Keys": {
      "Word": 4990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "泪水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèishuǐ",
      "PrimaryDefinition": "teardrop, tears"
    },
    "Keys": {
      "Word": 2687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "贬值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎn zhí",
      "PrimaryDefinition": "to become devaluated, to devaluate, to depreciate"
    },
    "Keys": {
      "Word": 5606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跟上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēn shàng",
      "PrimaryDefinition": "to catch up with, to keep pace with"
    },
    "Keys": {
      "Word": 6744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "果然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒrán",
      "PrimaryDefinition": "really, as expected, sure enough"
    },
    "Keys": {
      "Word": 1533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "哄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng",
      "PrimaryDefinition": "coax; beguile, cheat, deceive"
    },
    "Keys": {
      "Hanzi": 1483,
      "Word": 7073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 7,
      "GeneralStandard": 1483,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2513,
      "StrokeCount": 9
    }
  },
  "渌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "strain"
    },
    "Keys": {
      "Hanzi": 4981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4981,
      "Radical": "水",
      "FrequencyRank": 4814,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "taste, smell, odor; delicacy"
    },
    "Keys": {
      "Hanzi": 1078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1078,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 844,
      "StrokeCount": 8
    }
  },
  "鱽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāo",
      "PrimaryDefinition": "the mullet"
    },
    "Keys": {
      "Hanzi": 7034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7034,
      "Radical": "魚",
      "FrequencyRank": 8736,
      "RadicalIndex": "195.2",
      "StrokeCount": 10
    }
  },
  "逻辑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luóji",
      "PrimaryDefinition": "logic"
    },
    "Keys": {
      "Word": 3706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "招牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāopai",
      "PrimaryDefinition": "plate, sign, shop sign, fascia, signboard, placard, facia, nameboard"
    },
    "Keys": {
      "Word": 10665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "化妆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà zhuāng",
      "PrimaryDefinition": "make_up, bepaint, masquerade, put on make-up, make up, apply makeup, titivate"
    },
    "Keys": {
      "Word": 7144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "濉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6175
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6175,
      "Radical": "水",
      "FrequencyRank": 5191,
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "柄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "handle, lever, knob; authority"
    },
    "Keys": {
      "Hanzi": 1428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1428,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2241,
      "StrokeCount": 9
    }
  },
  "物资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùzī",
      "PrimaryDefinition": "goods, supplies"
    },
    "Keys": {
      "Word": 9813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "檬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "type of locust oracacia"
    },
    "Keys": {
      "Hanzi": 3394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3394,
      "Radical": "木",
      "FrequencyRank": 3939,
      "RadicalIndex": "75.14",
      "StrokeCount": 17
    }
  },
  "齐心协力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíxīn-xiélì",
      "PrimaryDefinition": "to work with a common purpose (idiom), to make concerted efforts, to pull together, to work as one"
    },
    "Keys": {
      "Word": 8593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "希": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "rare; hope, expect, strive for"
    },
    "Keys": {
      "Hanzi": 796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.4",
      "GeneralStandard": 796,
      "Radical": "巾",
      "HSK": 3,
      "FrequencyRank": 508,
      "StrokeCount": 7
    }
  },
  "役": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "service; a servant, laborer; to serve"
    },
    "Keys": {
      "Hanzi": 793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.4",
      "GeneralStandard": 793,
      "Radical": "彳",
      "HSK": 8,
      "FrequencyRank": 1245,
      "StrokeCount": 7
    }
  },
  "一阵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīzhèn",
      "PrimaryDefinition": "a burst, a fit, a peal, a spell (period of time)"
    },
    "Keys": {
      "Word": 10297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyàng",
      "PrimaryDefinition": "same, equal, similar"
    },
    "Keys": {
      "Word": 436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "勃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "suddenly, sudden, quick"
    },
    "Keys": {
      "Hanzi": 1440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.7",
      "GeneralStandard": 1440,
      "Radical": "力",
      "HSK": 9,
      "FrequencyRank": 1533,
      "StrokeCount": 9
    }
  },
  "腊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "là",
      "PrimaryDefinition": "year end sacrifice; dried meat"
    },
    "Keys": {
      "Hanzi": 2712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.8",
      "GeneralStandard": 2712,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 1497,
      "StrokeCount": 12
    }
  },
  "用户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yònghù",
      "PrimaryDefinition": "customer, subscriber, enjoyer, user, consumer"
    },
    "Keys": {
      "Word": 4129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "联邦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánbāng",
      "PrimaryDefinition": "federacy, union, federal, nation, confederation, commonwealth, federation, confederacy"
    },
    "Keys": {
      "Word": 8020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不堪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùkān",
      "PrimaryDefinition": "can't bear/stand"
    },
    "Keys": {
      "Word": 5700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng",
      "PrimaryDefinition": "picture, scroll; one of pair of"
    },
    "Keys": {
      "Hanzi": 4233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4233,
      "Radical": "巾",
      "FrequencyRank": 3563,
      "RadicalIndex": "50.6",
      "StrokeCount": 9
    }
  },
  "半路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànlù",
      "PrimaryDefinition": "halfway, midway, on the way"
    },
    "Keys": {
      "Word": 5494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎng",
      "PrimaryDefinition": "counsel, advice; speak out"
    },
    "Keys": {
      "Hanzi": 5310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5310,
      "Radical": "言",
      "FrequencyRank": 5146,
      "RadicalIndex": "149.1",
      "StrokeCount": 12
    }
  },
  "检察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnchá",
      "PrimaryDefinition": "to inspect, (law) to prosecute, to investigate"
    },
    "Keys": {
      "Word": 7402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸭子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāzi",
      "PrimaryDefinition": "duck, male prostitute (slang)"
    },
    "Keys": {
      "Word": 4087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "惊醒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngxing",
      "PrimaryDefinition": "to rouse, to be woken by sth, to wake with a start, to sleep lightly"
    },
    "Keys": {
      "Word": 7607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "如此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúcǐ",
      "PrimaryDefinition": "thus, like this, such"
    },
    "Keys": {
      "Word": 3847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "檐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "eaves of house; brim, rim"
    },
    "Keys": {
      "Hanzi": 3395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3395,
      "Radical": "木",
      "FrequencyRank": 3102,
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "搽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "smear; rub, wipe; anoint"
    },
    "Keys": {
      "Hanzi": 5061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5061,
      "Radical": "手",
      "FrequencyRank": 4565,
      "RadicalIndex": "64.1",
      "StrokeCount": 12
    }
  },
  "无意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúyì",
      "PrimaryDefinition": "have no intention, not be inclined to, have no intention to"
    },
    "Keys": {
      "Word": 9793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoyù",
      "PrimaryDefinition": "education"
    },
    "Keys": {
      "Word": 774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鲃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "a kind of fish; a bonito, (same as 鮁) shark"
    },
    "Keys": {
      "Hanzi": 7406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7406,
      "Radical": "魚",
      "FrequencyRank": 5861,
      "RadicalIndex": "195.4",
      "StrokeCount": 12
    }
  },
  "激": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "arouse, excite, incite; quickly"
    },
    "Keys": {
      "Hanzi": 3379
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.13",
      "GeneralStandard": 3379,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 710,
      "StrokeCount": 16
    }
  },
  "兢兢业业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngjīng-yèyè",
      "PrimaryDefinition": "cautious and conscientious"
    },
    "Keys": {
      "Word": 7610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "smooth; active; clever, sharp"
    },
    "Keys": {
      "Hanzi": 1565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1565,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 3564,
      "StrokeCount": 9
    }
  },
  "古老": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔlǎo",
      "PrimaryDefinition": "ancient, old"
    },
    "Keys": {
      "Word": 3510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "上司": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngsi",
      "PrimaryDefinition": "superior, boss"
    },
    "Keys": {
      "Word": 8979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "住址": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùzhǐ",
      "PrimaryDefinition": "address"
    },
    "Keys": {
      "Word": 10868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuī",
      "PrimaryDefinition": "grand, stately; secure, lasting"
    },
    "Keys": {
      "Hanzi": 3924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3924,
      "Radical": "山",
      "FrequencyRank": 5768,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "跳远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàoyuǎn",
      "PrimaryDefinition": "long jump (athletics)"
    },
    "Keys": {
      "Word": 1965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "凶残": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngcán",
      "PrimaryDefinition": "savage"
    },
    "Keys": {
      "Word": 10064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伤亡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngwáng",
      "PrimaryDefinition": "casualties, injuries and deaths"
    },
    "Keys": {
      "Word": 4991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "撷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "pick up, gather up; hold in lap"
    },
    "Keys": {
      "Hanzi": 5861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5861,
      "Radical": "手",
      "FrequencyRank": 4717,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "鹖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "crossbill, species of nightingale"
    },
    "Keys": {
      "Hanzi": 7671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7671,
      "Radical": "鳥",
      "FrequencyRank": 8523,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "把关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎ guān",
      "PrimaryDefinition": "to guard a pass, to check on sth"
    },
    "Keys": {
      "Word": 5463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "普": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "universal, general, widespread"
    },
    "Keys": {
      "Hanzi": 2741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "GeneralStandard": 2741,
      "Radical": "日",
      "HSK": 2,
      "FrequencyRank": 630,
      "StrokeCount": 12
    }
  },
  "垎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6775
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6775,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "倞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "far"
    },
    "Keys": {
      "Hanzi": 7018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7018,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "权衡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánhéng",
      "PrimaryDefinition": "to consider, to weigh (a matter), to balance (pros and cons)"
    },
    "Keys": {
      "Word": 8797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "入口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù kǒu",
      "PrimaryDefinition": "adit, window, gateway, propylaeum, entranceway, entree, entrance, portal, in door, threshold, inlet, approach, door, entry, entering, access, entryway, intake, ingress"
    },
    "Keys": {
      "Word": 960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "镆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "sword"
    },
    "Keys": {
      "Hanzi": 7785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7785,
      "Radical": "金",
      "FrequencyRank": 5657,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "古今中外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔjīn-zhōngwài",
      "PrimaryDefinition": "at all times and in all places (idiom)"
    },
    "Keys": {
      "Word": 6827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒng",
      "PrimaryDefinition": "bind; firm, secure, strong"
    },
    "Keys": {
      "Hanzi": 361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "48.3",
      "GeneralStandard": 361,
      "Radical": "工",
      "HSK": 6,
      "FrequencyRank": 2384,
      "StrokeCount": 6
    }
  },
  "扊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "upright bar for fastening door"
    },
    "Keys": {
      "Hanzi": 7444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7444,
      "Radical": "戶",
      "RadicalIndex": "63.8",
      "StrokeCount": 12
    }
  },
  "耩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng",
      "PrimaryDefinition": "to plough. to sow"
    },
    "Keys": {
      "Hanzi": 6026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6026,
      "Radical": "耒",
      "FrequencyRank": 7319,
      "RadicalIndex": "127.1",
      "StrokeCount": 16
    }
  },
  "替代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tìdài",
      "PrimaryDefinition": "replace, supervene upon, replacement, supersede, substitute for, alternate, substitute, supersession, displace, supplant"
    },
    "Keys": {
      "Word": 2927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "古代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔdài",
      "PrimaryDefinition": "ancient times, olden times"
    },
    "Keys": {
      "Word": 1514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "圹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "tomb, grave: prairie; open wilderness"
    },
    "Keys": {
      "Hanzi": 3574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3574,
      "Radical": "土",
      "FrequencyRank": 6492,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "葑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "the rape-turnip"
    },
    "Keys": {
      "Hanzi": 5079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5079,
      "Radical": "艸",
      "FrequencyRank": 5930,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "分割": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngē",
      "PrimaryDefinition": "cut apart, break up, partition"
    },
    "Keys": {
      "Word": 6535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朴实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔshí",
      "PrimaryDefinition": "plain, simple, guileless, down-to-earth, sincere and honest"
    },
    "Keys": {
      "Word": 8580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "(archaic form) his, her, its, their; that"
    },
    "Keys": {
      "Hanzi": 3508
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3508,
      "Radical": "二",
      "FrequencyRank": 5651,
      "RadicalIndex": "7.2",
      "StrokeCount": 4
    }
  },
  "抹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǒ",
      "PrimaryDefinition": "smear, apply, wipe off, erase"
    },
    "Keys": {
      "Hanzi": 953,
      "Word": 8325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 953,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2087,
      "StrokeCount": 8
    }
  },
  "走进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒujìn",
      "PrimaryDefinition": "enter, step_in, walk in, go in"
    },
    "Keys": {
      "Word": 1243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "改正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎizhèng",
      "PrimaryDefinition": "to correct, to amend, to put right, correction, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "骣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "horse without saddle"
    },
    "Keys": {
      "Hanzi": 6024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6024,
      "Radical": "馬",
      "FrequencyRank": 9914,
      "RadicalIndex": "187.12",
      "StrokeCount": 15
    }
  },
  "磨合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móhé",
      "PrimaryDefinition": "to break in, to wear in"
    },
    "Keys": {
      "Word": 8321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电视台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànshìtái",
      "PrimaryDefinition": "television station, station, spreader, broadcaster, TV station"
    },
    "Keys": {
      "Word": 1406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "汶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèn",
      "PrimaryDefinition": "a river in Shandong province"
    },
    "Keys": {
      "Hanzi": 3779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3779,
      "Radical": "水",
      "FrequencyRank": 3719,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "死心塌地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǐxīn-tādì",
      "PrimaryDefinition": "to be hell-bent on, dead set on sth, unswerving"
    },
    "Keys": {
      "Word": 9322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6696,
      "Radical": "人",
      "RadicalIndex": "9.7",
      "StrokeCount": 8
    }
  },
  "脱颖而出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōyǐngérchū",
      "PrimaryDefinition": "stand out, leap out, come to the fore, jump out, jump, show itself"
    },
    "Keys": {
      "Word": 9620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饮食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnshí",
      "PrimaryDefinition": "dietetic, fare, food and drink, trencher, refection, diet"
    },
    "Keys": {
      "Word": 4123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2681,
      "Word": 613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 2,
      "GeneralStandard": 2681,
      "Radical": "竹",
      "HSK": 1,
      "FrequencyRank": 158,
      "StrokeCount": 12
    }
  },
  "分为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnwéi",
      "PrimaryDefinition": "divide ... into, fall_into, divide(into)"
    },
    "Keys": {
      "Word": 2465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "胝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "callous, corn"
    },
    "Keys": {
      "Hanzi": 4286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4286,
      "Radical": "肉",
      "FrequencyRank": 4773,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "虒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "an amphibious beast resembling a tiger with one horn; place name"
    },
    "Keys": {
      "Hanzi": 7023
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7023,
      "Radical": "虍",
      "FrequencyRank": 7189,
      "RadicalIndex": "141.4",
      "StrokeCount": 10
    }
  },
  "弁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "conical cap worn during Zhou dynasty"
    },
    "Keys": {
      "Hanzi": 3561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3561,
      "Radical": "廾",
      "FrequencyRank": 4544,
      "RadicalIndex": "55.2",
      "StrokeCount": 5
    }
  },
  "收购": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōugòu",
      "PrimaryDefinition": "purchase, buy"
    },
    "Keys": {
      "Word": 3911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "诈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "cheat, defraud, swindle; trick"
    },
    "Keys": {
      "Hanzi": 893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "GeneralStandard": 893,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 2403,
      "StrokeCount": 7
    }
  },
  "吭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēng",
      "PrimaryDefinition": "throat"
    },
    "Keys": {
      "Hanzi": 746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 746,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 3067,
      "StrokeCount": 7
    }
  },
  "双胞胎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngbāotāi",
      "PrimaryDefinition": "twin, CL:對|对[dui4]"
    },
    "Keys": {
      "Word": 9249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "救灾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù zāi",
      "PrimaryDefinition": "to relieve disaster, to help disaster victims"
    },
    "Keys": {
      "Word": 3633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "坂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎn",
      "PrimaryDefinition": "hillside, inconvenient places"
    },
    "Keys": {
      "Hanzi": 3653
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3653,
      "Radical": "土",
      "FrequencyRank": 4000,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "观感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngǎn",
      "PrimaryDefinition": "one's impressions, observations"
    },
    "Keys": {
      "Word": 6871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "合计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héji",
      "PrimaryDefinition": "to add up the total, to figure what sth amounts to, to consider"
    },
    "Keys": {
      "Word": 7027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "事件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjiàn",
      "PrimaryDefinition": "fact, event, page, incidental, go, case, job, natural event, timing, proceedings, social occasion, happening, occurrence, matter, occasion, incident, shebang, affair, chapter, concernment"
    },
    "Keys": {
      "Word": 1912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "收缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōusuō",
      "PrimaryDefinition": "to pull back, to shrink, to contract, (physiology) systole"
    },
    "Keys": {
      "Word": 9169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "协会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiéhuì",
      "PrimaryDefinition": "an association, a society, CL:個|个[ge4],家[jia1]"
    },
    "Keys": {
      "Word": 5176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "长期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángqī",
      "PrimaryDefinition": "long term, long time, long range (of a forecast)"
    },
    "Keys": {
      "Word": 1337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "一带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdài",
      "PrimaryDefinition": "neighborhood, area, region, district"
    },
    "Keys": {
      "Word": 4104
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "中型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxíng",
      "PrimaryDefinition": "medium sized"
    },
    "Keys": {
      "Word": 10811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "议程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìchéng",
      "PrimaryDefinition": "agenda"
    },
    "Keys": {
      "Word": 10356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "tunnel, underground passageway, path to a tomb"
    },
    "Keys": {
      "Hanzi": 3182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.13",
      "GeneralStandard": 3182,
      "Radical": "阜",
      "HSK": 7,
      "FrequencyRank": 3342,
      "StrokeCount": 14
    }
  },
  "疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "doubt, question, suspect"
    },
    "Keys": {
      "Hanzi": 3136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "103.9",
      "GeneralStandard": 3136,
      "Radical": "疋",
      "HSK": 4,
      "FrequencyRank": 698,
      "StrokeCount": 14
    }
  },
  "柢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": "root, base; bottom of object"
    },
    "Keys": {
      "Hanzi": 4154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4154,
      "Radical": "木",
      "FrequencyRank": 5362,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "礼服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐfú",
      "PrimaryDefinition": "ceremonial robe, plumage, trappings, ceremonial dress, tux, robe, full_dress, black tie, full dress, gown, ceremonial robe or dress, dress, ball gown, dinner jacket, vestment, ceremonial robe/dress, formal attire, tuxedo, full-dress"
    },
    "Keys": {
      "Word": 7978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "prepare"
    },
    "Keys": {
      "Hanzi": 6875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6875,
      "Radical": "广",
      "RadicalIndex": "53.6",
      "StrokeCount": 9
    }
  },
  "枳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "trifoliate orange; hedge thorn; (Cant.) a plug"
    },
    "Keys": {
      "Hanzi": 4150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4150,
      "Radical": "木",
      "FrequencyRank": 5304,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "叶子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèzi",
      "PrimaryDefinition": "foliage, frond, leafage, leaf, leave"
    },
    "Keys": {
      "Word": 3087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "咒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "curse, damn, incantation"
    },
    "Keys": {
      "Hanzi": 1096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1096,
      "Radical": "口",
      "FrequencyRank": 2286,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "荆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "thorns; brambles; my wife; cane"
    },
    "Keys": {
      "Hanzi": 1401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.8",
      "GeneralStandard": 1401,
      "Radical": "刀",
      "HSK": 9,
      "FrequencyRank": 2551,
      "StrokeCount": 9
    }
  },
  "完善": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánshàn",
      "PrimaryDefinition": "perfect, consummate"
    },
    "Keys": {
      "Word": 2000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "坷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kě",
      "PrimaryDefinition": "clod of earth, lump of soil"
    },
    "Keys": {
      "Hanzi": 955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 955,
      "Radical": "土",
      "FrequencyRank": 3872,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "帱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "cover up; curtain"
    },
    "Keys": {
      "Hanzi": 6996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6996,
      "Radical": "巾",
      "FrequencyRank": 7770,
      "RadicalIndex": "50.7",
      "StrokeCount": 10
    }
  },
  "峒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "mountain in Gansu province"
    },
    "Keys": {
      "Hanzi": 4234
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4234,
      "Radical": "山",
      "FrequencyRank": 4633,
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "扩张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòzhāng",
      "PrimaryDefinition": "expand, enlarge, extend, dilate"
    },
    "Keys": {
      "Word": 7903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一旦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdàn",
      "PrimaryDefinition": "conj.: once, some time or other"
    },
    "Keys": {
      "Word": 4106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "交流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoliú",
      "PrimaryDefinition": "exchange, interflow, interchange"
    },
    "Keys": {
      "Word": 1609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "原汁原味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánzhī-yuánwèi",
      "PrimaryDefinition": "original, authentic"
    },
    "Keys": {
      "Word": 10545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4956,
      "Radical": "火",
      "FrequencyRank": 5648,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "千克": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānkè",
      "PrimaryDefinition": "kilogram"
    },
    "Keys": {
      "Word": 926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "衡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": "measure, weigh, judge, consider"
    },
    "Keys": {
      "Hanzi": 3363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "144.1",
      "GeneralStandard": 3363,
      "Radical": "行",
      "HSK": 6,
      "FrequencyRank": 1340,
      "StrokeCount": 16
    }
  },
  "优势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōushì",
      "PrimaryDefinition": "ascendence, laterality, preponderancy, vantage, ascendancy, ascendent, transcendency, dominance, predominance, draw, gree, upper_hand, prevalence, advantage, mastery, ascendency, supremacy, advantageousness, control, preeminence, dominion, jump, edge, better, deadwood, overweight, superiority, domination, sovereignty, preponderance, ascendance, transcendence, prepotency, hank, asce"
    },
    "Keys": {
      "Word": 2107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "争吵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngchǎo",
      "PrimaryDefinition": "quarrel, wrangle"
    },
    "Keys": {
      "Word": 10715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "消除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāochú",
      "PrimaryDefinition": "smooth, lull, removal, cancel, eradicate, rase, to clear up, turn back, counteract, iron_out, wipe, drive off, abate, die, eliminate, drive away, obviate, avoid, exorcise, chase away, wipe out, annihilate, run off, unblock, drive out, stamp out, kill, lift, disarm, get rid of, slake, rid of, decimate, to remove, demagnetize, undo, to clear, sublate, carry off, blue-pencil, exterminate, sa"
    },
    "Keys": {
      "Word": 4051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "渝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "change; chongqing"
    },
    "Keys": {
      "Hanzi": 2763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2763,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3641,
      "StrokeCount": 12
    }
  },
  "游览": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóulǎn",
      "PrimaryDefinition": "go sight-seeing, tour, visit"
    },
    "Keys": {
      "Word": 10468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "send, deliver, present; cause"
    },
    "Keys": {
      "Hanzi": 1879,
      "Word": 10796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "133.3",
      "Grade": 7,
      "GeneralStandard": 1879,
      "Radical": "至",
      "HSK": 4,
      "FrequencyRank": 524,
      "StrokeCount": 10
    }
  },
  "不用说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyòngshuō",
      "PrimaryDefinition": "Needless to say"
    },
    "Keys": {
      "Word": 5686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宣读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuāndú",
      "PrimaryDefinition": "to read out loud to an audience, a prepared speech (e.g. to a party conference)"
    },
    "Keys": {
      "Word": 10108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骨折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔzhé",
      "PrimaryDefinition": "to suffer a fracture, (of a bone) to break, fracture"
    },
    "Keys": {
      "Word": 6834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "着想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóxiǎng",
      "PrimaryDefinition": "to give thought (to others), to consider (other people's needs), also pr. [zhao2 xiang3]"
    },
    "Keys": {
      "Word": 10935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "塘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "pond; tank; dike, embankment"
    },
    "Keys": {
      "Hanzi": 2843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.1",
      "GeneralStandard": 2843,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 2436,
      "StrokeCount": 13
    }
  },
  "抵消": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐxiāo",
      "PrimaryDefinition": "to counteract, to cancel out, to offset"
    },
    "Keys": {
      "Word": 6239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "river in Hubei province"
    },
    "Keys": {
      "Hanzi": 7433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7433,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 12
    }
  },
  "更换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnghuàn",
      "PrimaryDefinition": "change, replace"
    },
    "Keys": {
      "Word": 3495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "气息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìxī",
      "PrimaryDefinition": "breath, flatus, look, feeling, spirit, smell, snuff, feel, breathing, flavor, tone, taste, flavour"
    },
    "Keys": {
      "Word": 8627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下令": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià lìng",
      "PrimaryDefinition": "give orders, order, give order, call_out, appoint, prescribe, call out, dictate"
    },
    "Keys": {
      "Word": 9861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiòng",
      "PrimaryDefinition": "long, faraway; pre-eminent"
    },
    "Keys": {
      "Hanzi": 7709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7709,
      "Radical": "夊",
      "RadicalIndex": "35.11",
      "StrokeCount": 14
    }
  },
  "氅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎng",
      "PrimaryDefinition": "overcoat; down feathers"
    },
    "Keys": {
      "Hanzi": 6083
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6083,
      "Radical": "毛",
      "FrequencyRank": 5075,
      "RadicalIndex": "82.12",
      "StrokeCount": 16
    }
  },
  "镕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "fuse, melt, smelt; mold"
    },
    "Keys": {
      "Hanzi": 7790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7790,
      "Radical": "金",
      "FrequencyRank": 3283,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "槠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "Oak"
    },
    "Keys": {
      "Hanzi": 5669
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5669,
      "Radical": "木",
      "FrequencyRank": 6135,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "惊诧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngchà",
      "PrimaryDefinition": "to be surprised, to be amazed, to be stunned"
    },
    "Keys": {
      "Word": 7599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迄今": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìjīn",
      "PrimaryDefinition": "so far, to date, until now"
    },
    "Keys": {
      "Word": 8633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "hill"
    },
    "Keys": {
      "Hanzi": 6681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6681,
      "Radical": "山",
      "FrequencyRank": 5713,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "粪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "manure, dung, shit, excrement, night soil"
    },
    "Keys": {
      "Hanzi": 2742,
      "Word": 6557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.6",
      "Grade": 7,
      "GeneralStandard": 2742,
      "Radical": "米",
      "HSK": 8,
      "FrequencyRank": 2884,
      "StrokeCount": 12
    }
  },
  "墈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn",
      "PrimaryDefinition": "cliff"
    },
    "Keys": {
      "Hanzi": 7630
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7630,
      "Radical": "土",
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "葭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "bulrush, reed; flute, whistle"
    },
    "Keys": {
      "Hanzi": 5093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5093,
      "Radical": "艸",
      "FrequencyRank": 5735,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "对比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìbǐ",
      "PrimaryDefinition": "ratio, correlation"
    },
    "Keys": {
      "Word": 2430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "凤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèng",
      "PrimaryDefinition": "male phoenix; symbol of joy"
    },
    "Keys": {
      "Hanzi": 159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "16.2",
      "GeneralStandard": 159,
      "Radical": "几",
      "HSK": 7,
      "FrequencyRank": 1504,
      "StrokeCount": 4
    }
  },
  "呐喊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàhǎn",
      "PrimaryDefinition": "shout, rallying cry, cheering, to shout"
    },
    "Keys": {
      "Word": 8362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zì",
      "PrimaryDefinition": "oneself"
    },
    "Keys": {
      "Hanzi": 477,
      "Radical": 132,
      "Word": 3206
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "132",
      "Grade": 4,
      "GeneralStandard": 477,
      "Radical": "自",
      "HSK": 2,
      "FrequencyRank": 43,
      "StrokeCount": 6
    }
  },
  "不如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùrú",
      "PrimaryDefinition": "be unequal/inferior to"
    },
    "Keys": {
      "Word": 534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "安心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānxīn",
      "PrimaryDefinition": "reassured, disburden, set one's mind at rest, feel at ease, reassuring, keep one's mind on sth., be relieved"
    },
    "Keys": {
      "Word": 5444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lè",
      "PrimaryDefinition": "happy, glad; enjoyable; music"
    },
    "Keys": {
      "Hanzi": 287,
      "Word": 1689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "4.4",
      "Grade": 3,
      "GeneralStandard": 287,
      "Radical": "丿",
      "HSK": 2,
      "FrequencyRank": 619,
      "StrokeCount": 5
    }
  },
  "敬爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngài",
      "PrimaryDefinition": "respect and love"
    },
    "Keys": {
      "Word": 7641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "这么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhème",
      "PrimaryDefinition": "so, such, this way, like this"
    },
    "Keys": {
      "Word": 1209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "恐吓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒnghè",
      "PrimaryDefinition": "threaten, intimidate"
    },
    "Keys": {
      "Word": 7826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "wrong"
    },
    "Keys": {
      "Hanzi": 1064,
      "Radical": 175,
      "Word": 6512
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "175",
      "Grade": 7,
      "GeneralStandard": 1064,
      "Radical": "非",
      "HSK": 1,
      "FrequencyRank": 283,
      "StrokeCount": 8
    }
  },
  "候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "wait; expect; visit; greet"
    },
    "Keys": {
      "Hanzi": 1964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1964,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 341,
      "StrokeCount": 10
    }
  },
  "以身作则": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐshēn-zuòzé",
      "PrimaryDefinition": "to set an example (idiom), to serve as a model"
    },
    "Keys": {
      "Word": 10314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nán",
      "PrimaryDefinition": "name of tree, machilus nanmu"
    },
    "Keys": {
      "Hanzi": 5393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5393,
      "Radical": "木",
      "FrequencyRank": 3700,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "飞速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēisù",
      "PrimaryDefinition": "flying speed, rapid (growth)"
    },
    "Keys": {
      "Word": 6508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "end of thread; thread, clue"
    },
    "Keys": {
      "Hanzi": 2487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2487,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 1413,
      "StrokeCount": 11
    }
  },
  "跸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "clear way, make room for; emp"
    },
    "Keys": {
      "Hanzi": 5454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5454,
      "Radical": "足",
      "FrequencyRank": 4987,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "纶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lún",
      "PrimaryDefinition": "green silk thread or tassel"
    },
    "Keys": {
      "Hanzi": 3824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3824,
      "Radical": "糸",
      "FrequencyRank": 3560,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "打击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎjī",
      "PrimaryDefinition": "stun, frustration, blow"
    },
    "Keys": {
      "Word": 3374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "叙述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùshù",
      "PrimaryDefinition": "narrate, recount, relate"
    },
    "Keys": {
      "Word": 10103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "concrete"
    },
    "Keys": {
      "Hanzi": 4479
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4479,
      "Radical": "石",
      "FrequencyRank": 6246,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "头条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóutiáo",
      "PrimaryDefinition": "lead story (on the news)"
    },
    "Keys": {
      "Word": 9557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "precious stone, gem, jewel, pearl"
    },
    "Keys": {
      "Hanzi": 1773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.6",
      "GeneralStandard": 1773,
      "Radical": "玉",
      "HSK": 5,
      "FrequencyRank": 1286,
      "StrokeCount": 10
    }
  },
  "彗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "broomstick; comet"
    },
    "Keys": {
      "Hanzi": 4709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4709,
      "Radical": "彐",
      "FrequencyRank": 3591,
      "RadicalIndex": "58.8",
      "StrokeCount": 11
    }
  },
  "贝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "shell"
    },
    "Keys": {
      "Radical": 154,
      "Hanzi": 114
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154",
      "GeneralStandard": 114,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 1133,
      "StrokeCount": 4
    }
  },
  "打车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎchē",
      "PrimaryDefinition": "to take a taxi (in town), to hitch a lift"
    },
    "Keys": {
      "Word": 57
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "纂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǎn",
      "PrimaryDefinition": "edit, compile; topknot, chignon"
    },
    "Keys": {
      "Hanzi": 6438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6438,
      "Radical": "糸",
      "FrequencyRank": 3536,
      "RadicalIndex": "120.14",
      "StrokeCount": 20
    }
  },
  "又": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "again"
    },
    "Keys": {
      "Hanzi": 21,
      "Radical": 29,
      "Word": 1178
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29",
      "Grade": 2,
      "GeneralStandard": 21,
      "Radical": "又",
      "HSK": 2,
      "FrequencyRank": 126,
      "StrokeCount": 2
    }
  },
  "精练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngliàn",
      "PrimaryDefinition": "(textiles) to scour, to degum (silk), variant of 精煉|精炼[jing1 lian4]"
    },
    "Keys": {
      "Word": 7614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěn",
      "PrimaryDefinition": "kiss; the lips; coinciding"
    },
    "Keys": {
      "Hanzi": 743,
      "Word": 9744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 7,
      "GeneralStandard": 743,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1973,
      "StrokeCount": 7
    }
  },
  "位置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèizhi",
      "PrimaryDefinition": "positioning, site, stand, stance, about-face, lie, locality, spatial relation, setting, situs, locus, slot, ubiety, station, location, emplacement, ubication, situation, niche, positional, position, place, seat"
    },
    "Keys": {
      "Word": 2978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "卯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎo",
      "PrimaryDefinition": "4th terrestrial branch; period from 5-7 a.m."
    },
    "Keys": {
      "Hanzi": 291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 291,
      "Radical": "卩",
      "FrequencyRank": 3926,
      "RadicalIndex": "26.3",
      "StrokeCount": 5
    }
  },
  "绽放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànfàng",
      "PrimaryDefinition": "to blossom"
    },
    "Keys": {
      "Word": 10647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峰会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnghuì",
      "PrimaryDefinition": "summit meeting"
    },
    "Keys": {
      "Word": 4565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "家属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāshǔ",
      "PrimaryDefinition": "blood relative, kinswoman, kinsman, household, kinfolk, kin, dependent, kinsfolk, family members, family dependents, cognate, folk, sib, people, dependents, blood relation, family members/dependents"
    },
    "Keys": {
      "Word": 1590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "上期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngqī",
      "PrimaryDefinition": "Previous period"
    },
    "Keys": {
      "Word": 8977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "line, thread, wire; clue"
    },
    "Keys": {
      "Hanzi": 1336,
      "Word": 2037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 3,
      "GeneralStandard": 1336,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 430,
      "StrokeCount": 8
    }
  },
  "责怪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zéguài",
      "PrimaryDefinition": "to blame, to rebuke"
    },
    "Keys": {
      "Word": 10620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "躅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "walk carefully; hesitate, falter"
    },
    "Keys": {
      "Hanzi": 6429
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6429,
      "Radical": "足",
      "FrequencyRank": 5611,
      "RadicalIndex": "157.13",
      "StrokeCount": 20
    }
  },
  "妹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèi",
      "PrimaryDefinition": "younger sister"
    },
    "Keys": {
      "Hanzi": 1324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1324,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 1185,
      "StrokeCount": 8
    }
  },
  "有毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒudú",
      "PrimaryDefinition": "poisonous"
    },
    "Keys": {
      "Word": 4140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "散布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sànbù",
      "PrimaryDefinition": "to disseminate"
    },
    "Keys": {
      "Word": 8920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动摇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngyáo",
      "PrimaryDefinition": "totter, unnerve, faze, fluctuate, jounce, falter, oscillate, enervate, seesaw, stagger, waver, depolarize, vibrate, shaken, ferment, wabble, shake, churn, teeter, vacillate, wobble, rock, weaken, agitate, unsettle, sway, vacillation, hesitate"
    },
    "Keys": {
      "Word": 2423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "竞争": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngzhēng",
      "PrimaryDefinition": "competition"
    },
    "Keys": {
      "Word": 3632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "驵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎng",
      "PrimaryDefinition": "excellent horse, noble steed"
    },
    "Keys": {
      "Hanzi": 6748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6748,
      "Radical": "馬",
      "FrequencyRank": 6988,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "獬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "a fabulous monster"
    },
    "Keys": {
      "Hanzi": 6149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6149,
      "Radical": "犬",
      "FrequencyRank": 5582,
      "RadicalIndex": "94.13",
      "StrokeCount": 16
    }
  },
  "宸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "imperial; imperial palace"
    },
    "Keys": {
      "Hanzi": 4665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4665,
      "Radical": "宀",
      "FrequencyRank": 4360,
      "RadicalIndex": "40.7",
      "StrokeCount": 10
    }
  },
  "选择": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnzé",
      "PrimaryDefinition": "select, opt"
    },
    "Keys": {
      "Word": 3049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "重申": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngshēn",
      "PrimaryDefinition": "to reaffirm, to reiterate"
    },
    "Keys": {
      "Word": 5923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "安抚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānfǔ",
      "PrimaryDefinition": "to placate, to pacify, to appease"
    },
    "Keys": {
      "Word": 5438
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抚养费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔyǎngfèi",
      "PrimaryDefinition": "child support payment (after a divorce)"
    },
    "Keys": {
      "Word": 6613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "保鲜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo xiān",
      "PrimaryDefinition": "Preservation"
    },
    "Keys": {
      "Word": 5523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骨气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔqì",
      "PrimaryDefinition": "unyielding character, courageous spirit, integrity, moral backbone"
    },
    "Keys": {
      "Word": 6835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下跌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàdiē",
      "PrimaryDefinition": "fall, decline, decrease"
    },
    "Keys": {
      "Word": 9855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拍摄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāishè",
      "PrimaryDefinition": "take (picture), shoot (film)"
    },
    "Keys": {
      "Word": 3758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "歌颂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēsòng",
      "PrimaryDefinition": "to sing the praises of, to extol, to eulogize"
    },
    "Keys": {
      "Word": 6726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顺从": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùncóng",
      "PrimaryDefinition": "obedient, to comply, to submit, to defer"
    },
    "Keys": {
      "Word": 9280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伦理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnlǐ",
      "PrimaryDefinition": "value orientation, value-system, moral, moral principle, morals, ethical, ethical motive, ethic, moral principles, morality, ethics"
    },
    "Keys": {
      "Word": 8156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "机关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīguān",
      "PrimaryDefinition": "mechanism, stratagem, scheme, intrigue"
    },
    "Keys": {
      "Word": 4692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "绿地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜdì",
      "PrimaryDefinition": "greenness, greenery patches, meadow, grassland"
    },
    "Keys": {
      "Word": 8149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn",
      "PrimaryDefinition": "dot, speck, spot; point, degree"
    },
    "Keys": {
      "Hanzi": 1469,
      "Word": 75
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "Grade": 1,
      "GeneralStandard": 1469,
      "Radical": "火",
      "HSK": 1,
      "FrequencyRank": 128,
      "StrokeCount": 9
    }
  },
  "奕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "in sequence, orderly; abundant"
    },
    "Keys": {
      "Hanzi": 4306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4306,
      "Radical": "大",
      "FrequencyRank": 3026,
      "RadicalIndex": "37.6",
      "StrokeCount": 9
    }
  },
  "挟持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiéchí",
      "PrimaryDefinition": "to seize"
    },
    "Keys": {
      "Word": 9990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "dam, dike; used in place names"
    },
    "Keys": {
      "Hanzi": 7112
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7112,
      "Radical": "土",
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "穷人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióngrén",
      "PrimaryDefinition": "pauper, poor people, prole, poor, poor man, the poor, beggar, have-not"
    },
    "Keys": {
      "Word": 2812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蚩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "worm; ignorant, rustic; laugh"
    },
    "Keys": {
      "Hanzi": 4685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4685,
      "Radical": "虫",
      "FrequencyRank": 4458,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "recognize, understand, know"
    },
    "Keys": {
      "Hanzi": 892,
      "Word": 5022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "Grade": 6,
      "GeneralStandard": 892,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 340,
      "StrokeCount": 7
    }
  },
  "掮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "to bear on the shoulders"
    },
    "Keys": {
      "Hanzi": 4739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4739,
      "Radical": "手",
      "FrequencyRank": 4995,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "前所未有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánsuǒwèiyǒu",
      "PrimaryDefinition": "unprecedented"
    },
    "Keys": {
      "Word": 8665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiē",
      "PrimaryDefinition": "C5H8"
    },
    "Keys": {
      "Hanzi": 4754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4754,
      "Radical": "艸",
      "FrequencyRank": 6551,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "要命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàomìng",
      "PrimaryDefinition": "to cause sb's death, very, extremely, frightening, annoying"
    },
    "Keys": {
      "Word": 10251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "gas, vapor, air"
    },
    "Keys": {
      "Hanzi": 1124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "84.4",
      "GeneralStandard": 1124,
      "Radical": "气",
      "HSK": 6,
      "FrequencyRank": 2009,
      "StrokeCount": 8
    }
  },
  "碃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7504,
      "Radical": "石",
      "FrequencyRank": 8027,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "视察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìchá",
      "PrimaryDefinition": "to inspect, an investigation"
    },
    "Keys": {
      "Word": 9151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高考": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāokǎo",
      "PrimaryDefinition": "college entrance exam (especially as abbr. for 普通高等學校招生全國統一考試|普通高等学校招生全国统一考试[Pu3 tong1 Gao1 deng3 Xue2 xiao4 Zhao1 sheng1 Quan2 guo2 Tong3 yi1 Kao3 shi4]), entrance exam for senior government service posts (Taiwan)"
    },
    "Keys": {
      "Word": 4596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "alas, exclamation of surprise or pain"
    },
    "Keys": {
      "Hanzi": 1916,
      "Word": 5431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "Grade": 7,
      "GeneralStandard": 1916,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2091,
      "StrokeCount": 10
    }
  },
  "基准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīzhǔn",
      "PrimaryDefinition": "guideline, foundation, base, norm, standard, criterion, postulate, fundament, principle, groundwork, modulus, yardstick, benchmark, standard criterion, module, cornerstone, touchstone, datum, basis, fiducial"
    },
    "Keys": {
      "Word": 7276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngchu",
      "PrimaryDefinition": "use, value, practical application, function, purpose, point, usefulness, mileage, role"
    },
    "Keys": {
      "Word": 5263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "悖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "be contradictory to, go counter"
    },
    "Keys": {
      "Hanzi": 2090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2090,
      "Radical": "心",
      "FrequencyRank": 3148,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "停车场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngchēchǎng",
      "PrimaryDefinition": "parking_lot, parking area, parking, car_park, parking lot, car park, halt, yard, park"
    },
    "Keys": {
      "Word": 1031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "卡片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎpiàn",
      "PrimaryDefinition": "card"
    },
    "Keys": {
      "Word": 7738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绿茶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜchá",
      "PrimaryDefinition": "green tea"
    },
    "Keys": {
      "Word": 1724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "可耻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěchǐ",
      "PrimaryDefinition": "shameful, disgraceful, ignominious"
    },
    "Keys": {
      "Word": 7796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "malaria pestilential vapors"
    },
    "Keys": {
      "Hanzi": 6158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6158,
      "Radical": "疒",
      "FrequencyRank": 4390,
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "扎实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāshi",
      "PrimaryDefinition": "strong, solid, sturdy, firm, practical, see 扎實|扎实[zha1 shi5]"
    },
    "Keys": {
      "Word": 5314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夸大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuādà",
      "PrimaryDefinition": "to exaggerate"
    },
    "Keys": {
      "Word": 7867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánqiú",
      "PrimaryDefinition": "global, whole world"
    },
    "Keys": {
      "Word": 1831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "以来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐlái",
      "PrimaryDefinition": "since"
    },
    "Keys": {
      "Word": 2089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "磐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pán",
      "PrimaryDefinition": "large rock, boulder; firm"
    },
    "Keys": {
      "Hanzi": 5963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5963,
      "Radical": "石",
      "FrequencyRank": 3640,
      "RadicalIndex": "112.1",
      "StrokeCount": 15
    }
  },
  "美金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měijīn",
      "PrimaryDefinition": "buck, dollar, U.S. dollar"
    },
    "Keys": {
      "Word": 2730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "儒家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Rújiā",
      "PrimaryDefinition": "Confucian school"
    },
    "Keys": {
      "Word": 8898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "flowing and engulfing; brimming"
    },
    "Keys": {
      "Hanzi": 6619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6619,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "鼎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐng",
      "PrimaryDefinition": "tripod"
    },
    "Keys": {
      "Radical": 206,
      "Hanzi": 2617
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "206",
      "GeneralStandard": 2617,
      "Radical": "鼎",
      "HSK": 9,
      "FrequencyRank": 2356,
      "StrokeCount": 12
    }
  },
  "沉默": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénmò",
      "PrimaryDefinition": "taciturn, uncommunicative, silent"
    },
    "Keys": {
      "Word": 2310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "剃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "shave"
    },
    "Keys": {
      "Hanzi": 1661,
      "Word": 9470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.7",
      "Grade": 7,
      "GeneralStandard": 1661,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 3289,
      "StrokeCount": 9
    }
  },
  "酐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "anhydride"
    },
    "Keys": {
      "Hanzi": 4469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4469,
      "Radical": "酉",
      "FrequencyRank": 5848,
      "RadicalIndex": "164.3",
      "StrokeCount": 10
    }
  },
  "留学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú xué",
      "PrimaryDefinition": "study abroad"
    },
    "Keys": {
      "Word": 1716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "树枝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùzhī",
      "PrimaryDefinition": "branch, twig"
    },
    "Keys": {
      "Word": 9235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海峡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎixiá",
      "PrimaryDefinition": "narrow, channel, straits, gullet, sound, strait, gut"
    },
    "Keys": {
      "Word": 6969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "强盗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángdào",
      "PrimaryDefinition": "hijacker, ravener, robber, rifler, freebooter, housebreaker, mugger, stickup, highwayman, brigand, ripoff, robbery, bandit, dacoit"
    },
    "Keys": {
      "Word": 4930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "歧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "fork of road; branching off"
    },
    "Keys": {
      "Hanzi": 1066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "77.4",
      "GeneralStandard": 1066,
      "Radical": "止",
      "HSK": 7,
      "FrequencyRank": 2071,
      "StrokeCount": 8
    }
  },
  "苻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "kind of herb; type of grass"
    },
    "Keys": {
      "Hanzi": 3864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3864,
      "Radical": "艸",
      "FrequencyRank": 4512,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "从而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngér",
      "PrimaryDefinition": "conj.: thus, thereby"
    },
    "Keys": {
      "Word": 3364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "胄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "helmet, headpiece; descendant"
    },
    "Keys": {
      "Hanzi": 4208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4208,
      "Radical": "肉",
      "FrequencyRank": 4268,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "捽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuó",
      "PrimaryDefinition": "clutch, grasp; pull up; contradict"
    },
    "Keys": {
      "Hanzi": 4734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4734,
      "Radical": "手",
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "完整": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wánzhěng",
      "PrimaryDefinition": "complete, integrated, intact"
    },
    "Keys": {
      "Word": 1999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "无不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúbù",
      "PrimaryDefinition": "without exception, invariably"
    },
    "Keys": {
      "Word": 9760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòng",
      "PrimaryDefinition": "pain, ache; sorry, sad; bitter"
    },
    "Keys": {
      "Hanzi": 2734,
      "Word": 9551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.7",
      "Grade": 7,
      "GeneralStandard": 2734,
      "Radical": "疒",
      "HSK": 3,
      "FrequencyRank": 730,
      "StrokeCount": 12
    }
  },
  "道路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàolù",
      "PrimaryDefinition": "track, path, runway, steps, route, course, toby, road, thoroughfare, way"
    },
    "Keys": {
      "Word": 607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "电器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànqì",
      "PrimaryDefinition": "electrical equipment/appliance"
    },
    "Keys": {
      "Word": 4507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "邋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lā",
      "PrimaryDefinition": "rags"
    },
    "Keys": {
      "Hanzi": 6365
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6365,
      "Radical": "辵",
      "FrequencyRank": 4901,
      "RadicalIndex": "162.15",
      "StrokeCount": 18
    }
  },
  "模仿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mófǎng",
      "PrimaryDefinition": "imitate, follow example of"
    },
    "Keys": {
      "Word": 3729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "分赃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn zāng",
      "PrimaryDefinition": "to share the booty, to divide ill-gotten gains"
    },
    "Keys": {
      "Word": 6541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沿着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánzhe",
      "PrimaryDefinition": "to go along, to follow"
    },
    "Keys": {
      "Word": 5211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "荠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "water-chestnuts; caltrop"
    },
    "Keys": {
      "Hanzi": 4126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4126,
      "Radical": "艸",
      "FrequencyRank": 5681,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "萌芽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méngyá",
      "PrimaryDefinition": "to sprout (lit. or fig.), to bud, to germinate, germ, seed, bud"
    },
    "Keys": {
      "Word": 8249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粗鲁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūlu",
      "PrimaryDefinition": "coarse, crude (in one's manner), boorish"
    },
    "Keys": {
      "Word": 6071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一把手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībǎshǒu",
      "PrimaryDefinition": "working hand, member of a work team, participant"
    },
    "Keys": {
      "Word": 10271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiārén",
      "PrimaryDefinition": "relation, homefolk, inmate, kindred, household, relative, people, folks, kin, family members, family, kinsfolk, house"
    },
    "Keys": {
      "Word": 170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "产品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnpǐn",
      "PrimaryDefinition": "baby, brand, output, product, produce, end product, merchandise, fruitage, growth, manufacture, wares, production"
    },
    "Keys": {
      "Word": 2298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "突": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū",
      "PrimaryDefinition": "suddenly, abruptly, unexpectedly"
    },
    "Keys": {
      "Hanzi": 1700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.4",
      "GeneralStandard": 1700,
      "Radical": "穴",
      "HSK": 3,
      "FrequencyRank": 484,
      "StrokeCount": 9
    }
  },
  "老头儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎotóur5",
      "PrimaryDefinition": "see 老頭子|老头子[lao3 tou2 zi5]"
    },
    "Keys": {
      "Word": 1688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "并行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngxíng",
      "PrimaryDefinition": "to proceed in parallel, side by side (of two processes, developments, thoughts etc)"
    },
    "Keys": {
      "Word": 5655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎishuǐ",
      "PrimaryDefinition": "waters, seawater, salt water, sea, the sea, brine"
    },
    "Keys": {
      "Word": 2525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "发行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāháng",
      "PrimaryDefinition": "issue, publish, distribute, put on sale"
    },
    "Keys": {
      "Word": 3445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "自愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìyuàn",
      "PrimaryDefinition": "voluntary, act voluntarily"
    },
    "Keys": {
      "Word": 4281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "柔软": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róuruǎn",
      "PrimaryDefinition": "lithe, whippy, soft"
    },
    "Keys": {
      "Word": 8892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu",
      "PrimaryDefinition": "lovely"
    },
    "Keys": {
      "Hanzi": 7270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7270,
      "Radical": "女",
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "艳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "beautiful, sexy, voluptuous"
    },
    "Keys": {
      "Hanzi": 1770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "139.4",
      "GeneralStandard": 1770,
      "Radical": "色",
      "HSK": 5,
      "FrequencyRank": 2104,
      "StrokeCount": 10
    }
  },
  "蠡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "wood-boring insect; bore into wood"
    },
    "Keys": {
      "Hanzi": 6463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6463,
      "Radical": "虫",
      "FrequencyRank": 4245,
      "RadicalIndex": "142.15",
      "StrokeCount": 21
    }
  },
  "电报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànbào",
      "PrimaryDefinition": "telegram, cable, telegraph, CL:封[feng1],份[fen4]"
    },
    "Keys": {
      "Word": 6272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "melt, fuse; market, sell"
    },
    "Keys": {
      "Hanzi": 2659,
      "Word": 9960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "Grade": 7,
      "GeneralStandard": 2659,
      "Radical": "金",
      "HSK": 4,
      "FrequencyRank": 904,
      "StrokeCount": 12
    }
  },
  "诽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "slander, vilify, condemn"
    },
    "Keys": {
      "Hanzi": 2114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "GeneralStandard": 2114,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 3541,
      "StrokeCount": 10
    }
  },
  "配": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "match, pair; equal; blend"
    },
    "Keys": {
      "Hanzi": 1858,
      "Word": 1787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.3",
      "Grade": 3,
      "GeneralStandard": 1858,
      "Radical": "酉",
      "HSK": 3,
      "FrequencyRank": 738,
      "StrokeCount": 10
    }
  },
  "钲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "kind of gong used in ancient times by troops on the march"
    },
    "Keys": {
      "Hanzi": 4528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4528,
      "Radical": "金",
      "FrequencyRank": 6935,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "黡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "mole, scar, blemish"
    },
    "Keys": {
      "Hanzi": 7993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7993,
      "Radical": "黑",
      "FrequencyRank": 8439,
      "RadicalIndex": "203.6",
      "StrokeCount": 18
    }
  },
  "大气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàqì",
      "PrimaryDefinition": "atmosphere, air, grand air, heavy breathing"
    },
    "Keys": {
      "Word": 6129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衣食住行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī-shí-zhù-xíng",
      "PrimaryDefinition": "clothing, food, housing and transport (idiom), people's basic needs"
    },
    "Keys": {
      "Word": 10276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "model style of Chinese writing"
    },
    "Keys": {
      "Hanzi": 2865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.9",
      "GeneralStandard": 2865,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 3530,
      "StrokeCount": 13
    }
  },
  "暇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "leisure, relaxation, spare time"
    },
    "Keys": {
      "Hanzi": 2910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2910,
      "Radical": "日",
      "FrequencyRank": 2761,
      "RadicalIndex": "72.9",
      "StrokeCount": 13
    }
  },
  "裼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7603,
      "Radical": "衣",
      "FrequencyRank": 5743,
      "RadicalIndex": "145.8",
      "StrokeCount": 13
    }
  },
  "交易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoyì",
      "PrimaryDefinition": "deal, trade, transaction"
    },
    "Keys": {
      "Word": 1611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "杳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎo",
      "PrimaryDefinition": "obscure, dark, mysterious, deep"
    },
    "Keys": {
      "Hanzi": 3877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3877,
      "Radical": "木",
      "FrequencyRank": 4293,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "适时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìshí",
      "PrimaryDefinition": "at right moment, early, timely, betimes"
    },
    "Keys": {
      "Word": 9159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "君": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "sovereign, monarch, ruler, chief, prince"
    },
    "Keys": {
      "Hanzi": 899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 899,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 985,
      "StrokeCount": 7
    }
  },
  "恶意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èyì",
      "PrimaryDefinition": "malice, evil intention"
    },
    "Keys": {
      "Word": 6416
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "ear ornament; stick, insert"
    },
    "Keys": {
      "Hanzi": 4401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4401,
      "Radical": "玉",
      "FrequencyRank": 3904,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "生育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngyù",
      "PrimaryDefinition": "to bear, to give birth, to grow, to rear, to bring up (children)"
    },
    "Keys": {
      "Word": 9064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "申": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "to state to a superior, report; extend; 9th terrestrial branch"
    },
    "Keys": {
      "Hanzi": 240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102",
      "GeneralStandard": 240,
      "Radical": "田",
      "HSK": 4,
      "FrequencyRank": 1110,
      "StrokeCount": 5
    }
  },
  "收取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuqǔ",
      "PrimaryDefinition": "collect, gather"
    },
    "Keys": {
      "Word": 5033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不怎么样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù zěnmeyàng",
      "PrimaryDefinition": "not up to much, very indifferent, nothing great about it, nothing good to be said about it"
    },
    "Keys": {
      "Word": 4358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "只得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐdé",
      "PrimaryDefinition": "have to"
    },
    "Keys": {
      "Word": 5355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "绋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "large rope; rope attached to bier"
    },
    "Keys": {
      "Hanzi": 6753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6753,
      "Radical": "糸",
      "FrequencyRank": 6940,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "宝库": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎokù",
      "PrimaryDefinition": "treasure-house, treasury, treasure-trove (often fig., book of treasured wisdom)"
    },
    "Keys": {
      "Word": 5521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": "ferrule; castrate"
    },
    "Keys": {
      "Hanzi": 6248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6248,
      "Radical": "金",
      "FrequencyRank": 7920,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "楂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "a raft, to hew, to fell trees"
    },
    "Keys": {
      "Hanzi": 5394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5394,
      "Radical": "木",
      "FrequencyRank": 4764,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "羼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàn",
      "PrimaryDefinition": "confuse, mix, interpolate"
    },
    "Keys": {
      "Hanzi": 6462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6462,
      "Radical": "羊",
      "FrequencyRank": 6074,
      "RadicalIndex": "123.15",
      "StrokeCount": 21
    }
  },
  "号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "mark, sign; symbol; number"
    },
    "Keys": {
      "Hanzi": 243,
      "Word": 141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 1,
      "GeneralStandard": 243,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 487,
      "StrokeCount": 5
    }
  },
  "拉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lā",
      "PrimaryDefinition": "pull, drag; seize, hold; lengthen"
    },
    "Keys": {
      "Hanzi": 981,
      "Word": 829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 2,
      "GeneralStandard": 981,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 324,
      "StrokeCount": 8
    }
  },
  "锶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "strontium"
    },
    "Keys": {
      "Hanzi": 5735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5735,
      "Radical": "金",
      "FrequencyRank": 5182,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "传来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánlái",
      "PrimaryDefinition": "spread, arise, salute, deliver something to here"
    },
    "Keys": {
      "Word": 1371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "社区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèqū",
      "PrimaryDefinition": "community"
    },
    "Keys": {
      "Word": 3875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "排球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páiqiú",
      "PrimaryDefinition": "volleyball"
    },
    "Keys": {
      "Word": 900
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "黾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "frog"
    },
    "Keys": {
      "Radical": 205,
      "Hanzi": 3913
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3913,
      "Radical": "黽",
      "FrequencyRank": 5223,
      "RadicalIndex": "205",
      "StrokeCount": 8
    }
  },
  "犹如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóurú",
      "PrimaryDefinition": "like, as, just as, be just as, be just like/as, be just like"
    },
    "Keys": {
      "Word": 10465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "企": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "plan a project; stand on tiptoe"
    },
    "Keys": {
      "Hanzi": 490
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 490,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 450,
      "StrokeCount": 6
    }
  },
  "亮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng",
      "PrimaryDefinition": "bright, brilliant, radiant, light"
    },
    "Keys": {
      "Hanzi": 1621,
      "Word": 850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "8.7",
      "Grade": 2,
      "GeneralStandard": 1621,
      "Radical": "亠",
      "HSK": 2,
      "FrequencyRank": 840,
      "StrokeCount": 9
    }
  },
  "朋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "friend, pal, acquaintance"
    },
    "Keys": {
      "Hanzi": 1185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "74.4",
      "GeneralStandard": 1185,
      "Radical": "月",
      "HSK": 1,
      "FrequencyRank": 882,
      "StrokeCount": 8
    }
  },
  "蔷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "rose"
    },
    "Keys": {
      "Hanzi": 5644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5644,
      "Radical": "艸",
      "FrequencyRank": 3941,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "小曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoqǔ",
      "PrimaryDefinition": "popular song, folk tune, ballad"
    },
    "Keys": {
      "Word": 9972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "to heat; to boil a saucepan"
    },
    "Keys": {
      "Hanzi": 5506,
      "Word": 5515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.9",
      "Grade": 7,
      "GeneralStandard": 5506,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 4865,
      "StrokeCount": 13
    }
  },
  "主动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔdòng",
      "PrimaryDefinition": "on one's own initiative"
    },
    "Keys": {
      "Word": 2190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "菊花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júhuā",
      "PrimaryDefinition": "chrysanthemum, (slang) anus"
    },
    "Keys": {
      "Word": 7684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "安眠药": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānmiányào",
      "PrimaryDefinition": "sleeping pill, CL:粒[li4]"
    },
    "Keys": {
      "Word": 5437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "sharp, sharp-pointed; sharpen"
    },
    "Keys": {
      "Hanzi": 4641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4641,
      "Radical": "刀",
      "FrequencyRank": 6348,
      "RadicalIndex": "18.8",
      "StrokeCount": 10
    }
  },
  "毁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǐ",
      "PrimaryDefinition": "destroy"
    },
    "Keys": {
      "Hanzi": 2956,
      "Word": 4683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "79.9",
      "Grade": 6,
      "GeneralStandard": 2956,
      "Radical": "殳",
      "HSK": 6,
      "FrequencyRank": 1160,
      "StrokeCount": 13
    }
  },
  "丘陵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiūlíng",
      "PrimaryDefinition": "hills"
    },
    "Keys": {
      "Word": 8768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎng",
      "PrimaryDefinition": "the stone base or plinth of a pillar"
    },
    "Keys": {
      "Hanzi": 7763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7763,
      "Radical": "石",
      "FrequencyRank": 6655,
      "RadicalIndex": "112.1",
      "StrokeCount": 15
    }
  },
  "筻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàng",
      "PrimaryDefinition": "place name in Hunan province"
    },
    "Keys": {
      "Hanzi": 7542
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7542,
      "Radical": "竹",
      "FrequencyRank": 8375,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "茄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "eggplant"
    },
    "Keys": {
      "Hanzi": 1013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1013,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 3136,
      "StrokeCount": 8
    }
  },
  "羹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēng",
      "PrimaryDefinition": "soup, broth"
    },
    "Keys": {
      "Hanzi": 3469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3469,
      "Radical": "羊",
      "FrequencyRank": 3907,
      "RadicalIndex": "123.13",
      "StrokeCount": 19
    }
  },
  "男朋友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánpéngyou",
      "PrimaryDefinition": "boyfriend"
    },
    "Keys": {
      "Word": 259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "赘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuì",
      "PrimaryDefinition": "unnecessary, superfluous"
    },
    "Keys": {
      "Hanzi": 3052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3052,
      "Radical": "貝",
      "FrequencyRank": 3574,
      "RadicalIndex": "154.1",
      "StrokeCount": 14
    }
  },
  "书籍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūjí",
      "PrimaryDefinition": "volume, page, classic, books, fascicule, works, literature"
    },
    "Keys": {
      "Word": 9207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7129,
      "Radical": "艸",
      "FrequencyRank": 7368,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "女朋友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚpéngyou",
      "PrimaryDefinition": "jane, girl, girl friend, lady friend, girlfriend, amie"
    },
    "Keys": {
      "Word": 277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "萤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "glow-worm, luminous insect"
    },
    "Keys": {
      "Hanzi": 2213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2213,
      "Radical": "艸",
      "FrequencyRank": 3434,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "显赫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnhè",
      "PrimaryDefinition": "illustrious, celebrated"
    },
    "Keys": {
      "Word": 9895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电饭锅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànfànguō",
      "PrimaryDefinition": "electric rice cooker"
    },
    "Keys": {
      "Word": 3423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "嘶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "neighing of a horse; gravel voiced, husky throated; (Cant.) to hiccough"
    },
    "Keys": {
      "Hanzi": 3237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3237,
      "Radical": "口",
      "FrequencyRank": 2776,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "舞台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔtái",
      "PrimaryDefinition": "board, arena, bandstand, stage, proscenium, boards"
    },
    "Keys": {
      "Word": 2023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "崮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "a mesa, hill with flat top and steep sides"
    },
    "Keys": {
      "Hanzi": 4859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4859,
      "Radical": "山",
      "FrequencyRank": 5098,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "泊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō",
      "PrimaryDefinition": "anchor vessel; lie at anchor"
    },
    "Keys": {
      "Hanzi": 1249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1249,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2043,
      "StrokeCount": 8
    }
  },
  "淏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7233,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "绨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "coarse pongee, kind of heavy silk"
    },
    "Keys": {
      "Hanzi": 4705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4705,
      "Radical": "糸",
      "FrequencyRank": 5811,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "车主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēzhǔ",
      "PrimaryDefinition": "vehicle owner"
    },
    "Keys": {
      "Word": 3320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "整": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěng",
      "PrimaryDefinition": "orderly, neat, tidy; whole"
    },
    "Keys": {
      "Hanzi": 3333,
      "Word": 2142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.11",
      "Grade": 3,
      "GeneralStandard": 3333,
      "Radical": "攴",
      "HSK": 3,
      "FrequencyRank": 416,
      "StrokeCount": 16
    }
  },
  "当代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngdài",
      "PrimaryDefinition": "the present age, the contemporary era"
    },
    "Keys": {
      "Word": 3401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蝎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiē",
      "PrimaryDefinition": "scorpion"
    },
    "Keys": {
      "Hanzi": 3248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3248,
      "Radical": "虫",
      "FrequencyRank": 3929,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "笼罩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒngzhào",
      "PrimaryDefinition": "envelop, shroud"
    },
    "Keys": {
      "Word": 8119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rén",
      "PrimaryDefinition": "humaneness, benevolence, kindness"
    },
    "Keys": {
      "Hanzi": 128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "GeneralStandard": 128,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 1360,
      "StrokeCount": 4
    }
  },
  "一生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshēng",
      "PrimaryDefinition": "lifetime, all, existence, life, throughout one's life, all one's life, career, all/throughout one's life"
    },
    "Keys": {
      "Word": 1150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "技艺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìyì",
      "PrimaryDefinition": "mechanical arts, science, feat, skill, craft, craftsmanship, artistry, craftship, workmanship"
    },
    "Keys": {
      "Word": 7328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "embrace, hug, squeeze; crowd"
    },
    "Keys": {
      "Hanzi": 974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 974,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1059,
      "StrokeCount": 8
    }
  },
  "楹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "column, pillar; numerary adjunct"
    },
    "Keys": {
      "Hanzi": 5407
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5407,
      "Radical": "木",
      "FrequencyRank": 4769,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "偿还": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chánghuán",
      "PrimaryDefinition": "to repay, to reimburse"
    },
    "Keys": {
      "Word": 5818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7762
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7762,
      "Radical": "石",
      "RadicalIndex": "112.1",
      "StrokeCount": 15
    }
  },
  "层次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céngcì",
      "PrimaryDefinition": "administrative levels, rank order, arrangement, arrangement of ideas, stratification, levels, gradation, level, grade, hierarchy, coat, place"
    },
    "Keys": {
      "Word": 3297
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "市民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìmín",
      "PrimaryDefinition": "citizen, burgess, citizenry, metropolitan, townsman, townsfolk, town, towner, civic, residents of a city, townee, burgher, townspeople"
    },
    "Keys": {
      "Word": 5025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "收听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōutīng",
      "PrimaryDefinition": "to listen (to), to listen (in)"
    },
    "Keys": {
      "Word": 1924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "共": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòng",
      "PrimaryDefinition": "together with, all, total; to share"
    },
    "Keys": {
      "Hanzi": 371,
      "Word": 2503
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.4",
      "Grade": 4,
      "GeneralStandard": 371,
      "Radical": "八",
      "HSK": 2,
      "FrequencyRank": 330,
      "StrokeCount": 6
    }
  },
  "搭乘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāchéng",
      "PrimaryDefinition": "travel by, travel, travel (by a conveyance), jet, take, fly, hitching, get_on"
    },
    "Keys": {
      "Word": 6094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奢侈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēchǐ",
      "PrimaryDefinition": "luxurious, extravagant"
    },
    "Keys": {
      "Word": 9001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惦记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànji",
      "PrimaryDefinition": "to think of, to keep thinking about, to be concerned about"
    },
    "Keys": {
      "Word": 6282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戏剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìjù",
      "PrimaryDefinition": "dramatic_work, dramatic composition, theatricals, dramatic work, stage, theatre, theater, drama, dramaturgy, play, dramatic art, boards, dramatics"
    },
    "Keys": {
      "Word": 4028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "葰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7314,
      "Radical": "艸",
      "FrequencyRank": 7095,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "良心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángxīn",
      "PrimaryDefinition": "sense of right and wrong, conscientiousness, conscience, moral sense, scruples"
    },
    "Keys": {
      "Word": 8031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稳妥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěntuǒ",
      "PrimaryDefinition": "dependable"
    },
    "Keys": {
      "Word": 9750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒ",
      "PrimaryDefinition": "cluster of flowers; earlobe"
    },
    "Keys": {
      "Hanzi": 497,
      "Word": 3437
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "Grade": 5,
      "GeneralStandard": 497,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 1571,
      "StrokeCount": 6
    }
  },
  "排斥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páichì",
      "PrimaryDefinition": "repel, exclude, reject"
    },
    "Keys": {
      "Word": 8458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "cheeks; jaw; chin; rear; to nourish"
    },
    "Keys": {
      "Hanzi": 5391
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5391,
      "Radical": "頁",
      "FrequencyRank": 2999,
      "RadicalIndex": "181.7",
      "StrokeCount": 13
    }
  },
  "发电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā diàn",
      "PrimaryDefinition": "electricity generation, generate electricity"
    },
    "Keys": {
      "Word": 4537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "噍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "chew; eat; munch"
    },
    "Keys": {
      "Hanzi": 5932
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5932,
      "Radical": "口",
      "FrequencyRank": 6900,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "迩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "be near, be close; recently"
    },
    "Keys": {
      "Hanzi": 3983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3983,
      "Radical": "辵",
      "FrequencyRank": 4328,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "足智多谋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zúzhì-duōmóu",
      "PrimaryDefinition": "resourceful, full of stratagems"
    },
    "Keys": {
      "Word": 11001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "女人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚren",
      "PrimaryDefinition": "jade, crumpet, female, female person, Gill, bitch, chick, fair, squaw, womenfolk, adult female, doll, momma, wench, skirt, womankind, girl, jill, wife, broad, petticoat, sister, woman, bimbo, bird, jane, she, hen, hairpin, dame, women"
    },
    "Keys": {
      "Word": 280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "竟然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngrán",
      "PrimaryDefinition": "unexpectedly, to one's surprise"
    },
    "Keys": {
      "Word": 2638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "搜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "search, seek; investigate"
    },
    "Keys": {
      "Hanzi": 2528,
      "Word": 3941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 5,
      "GeneralStandard": 2528,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1564,
      "StrokeCount": 12
    }
  },
  "豌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": "peas"
    },
    "Keys": {
      "Hanzi": 3220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3220,
      "Radical": "豆",
      "FrequencyRank": 4215,
      "RadicalIndex": "151.8",
      "StrokeCount": 15
    }
  },
  "年夜饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niányèfàn",
      "PrimaryDefinition": "New Year's Eve family dinner"
    },
    "Keys": {
      "Word": 8412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "命运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìngyùn",
      "PrimaryDefinition": "foredoom, lot, appointment, lottery, fortune, chance, dole, circumstances, allotment, predestination, cup, luck, fare, destiny, portion, kismet, fate, doom, line, fatality, weird, hazard, karma"
    },
    "Keys": {
      "Word": 1749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "佗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "other, he; surname; a load"
    },
    "Keys": {
      "Hanzi": 3734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3734,
      "Radical": "人",
      "FrequencyRank": 4569,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "平民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngmín",
      "PrimaryDefinition": "vulgus, ragtag, commonality, third estate, plebe, populace, civvy, commoner, common people, rabble, the populace, multitude, ceorl, citizen, commonage, commonalty, common person, mister, roturier, plebeian, people, plebs, democracy, commons, the common people, mass, pleb, civilian, common man"
    },
    "Keys": {
      "Word": 8551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎojiā",
      "PrimaryDefinition": "native place, old home, one's original home"
    },
    "Keys": {
      "Word": 2682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "反对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnduì",
      "PrimaryDefinition": "to fight against, to oppose, to be opposed to, opposition"
    },
    "Keys": {
      "Word": 1441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "群众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qúnzhòng",
      "PrimaryDefinition": "swarm, posse, herd, huddle, ruck, concourse, crowd, varletry, the masses/People, lineup, throng, the masses, mob, multitude, press, mobility, clamjamfry, People, crush, jam, horde, the People, confluence, army, flock, mass"
    },
    "Keys": {
      "Word": 3831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "焜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "fire, flames; bright, shining"
    },
    "Keys": {
      "Hanzi": 5273
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5273,
      "Radical": "火",
      "RadicalIndex": "86.8",
      "StrokeCount": 12
    }
  },
  "偷窥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōukuī",
      "PrimaryDefinition": "to peep, to peek, to act as voyeur"
    },
    "Keys": {
      "Word": 9552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "商贾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānggǔ",
      "PrimaryDefinition": "merchant"
    },
    "Keys": {
      "Word": 8968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "valley, ravine"
    },
    "Keys": {
      "Hanzi": 4524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4524,
      "Radical": "山",
      "FrequencyRank": 4323,
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "激起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī qǐ",
      "PrimaryDefinition": "elicit, feed, animate, stir up, arise, pique, enliven, fire, fan, inspire, galvanize, raise, enkindle, stir, arouse, wind up, excite, turn on, provoke, spark, kindle, sex, prompt, evoke, uprouse, ferment, whip_up, awake, rouse, evocation, invigorate, exalt"
    },
    "Keys": {
      "Word": 7284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7148
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7148,
      "Radical": "石",
      "FrequencyRank": 7244,
      "RadicalIndex": "112.12",
      "StrokeCount": 11
    }
  },
  "大胆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàdǎn",
      "PrimaryDefinition": "courageous, audacious, venture"
    },
    "Keys": {
      "Word": 3378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "绻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quǎn",
      "PrimaryDefinition": "affectionate, solicitous"
    },
    "Keys": {
      "Hanzi": 5037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5037,
      "Radical": "糸",
      "FrequencyRank": 4589,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "好运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoyùn",
      "PrimaryDefinition": "good luck"
    },
    "Keys": {
      "Word": 3533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "名字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngzi",
      "PrimaryDefinition": "first_name, forename, moniker, monaker, first name, monicker, cognomen, given name, name, patronymic, appellation"
    },
    "Keys": {
      "Word": 242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "猾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá",
      "PrimaryDefinition": "crafty, cunning, shrewd; deceitful"
    },
    "Keys": {
      "Hanzi": 2720
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.1",
      "GeneralStandard": 2720,
      "Radical": "犬",
      "HSK": 7,
      "FrequencyRank": 3083,
      "StrokeCount": 12
    }
  },
  "至": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "arrive"
    },
    "Keys": {
      "Hanzi": 410,
      "Radical": 133,
      "Word": 4234
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "133",
      "Grade": 5,
      "GeneralStandard": 410,
      "Radical": "至",
      "HSK": 3,
      "FrequencyRank": 267,
      "StrokeCount": 6
    }
  },
  "诮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "criticize, scold, blame, ridicule"
    },
    "Keys": {
      "Hanzi": 4368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4368,
      "Radical": "言",
      "FrequencyRank": 4613,
      "RadicalIndex": "149.7",
      "StrokeCount": 9
    }
  },
  "贪玩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tān wánr5",
      "PrimaryDefinition": "Greedy"
    },
    "Keys": {
      "Word": 9397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吃力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chīlì",
      "PrimaryDefinition": "to entail strenuous effort, to toil at a task, strenuous, laborious, strain"
    },
    "Keys": {
      "Word": 3331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "篇幅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānfu",
      "PrimaryDefinition": "length (of a piece of writing), space occupied on a printed page"
    },
    "Keys": {
      "Word": 8524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "战场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànchǎng",
      "PrimaryDefinition": "field of battle, plain, theatre, camp, cockpit, battlefield, theater, battleground, field, battlefront"
    },
    "Keys": {
      "Word": 5319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "峡谷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiágǔ",
      "PrimaryDefinition": "canyon, gill, ravine"
    },
    "Keys": {
      "Word": 9851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小贩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎofàn",
      "PrimaryDefinition": "peddler, hawker"
    },
    "Keys": {
      "Word": 9965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huífù",
      "PrimaryDefinition": "to reply, to recover, to return (to a previous condition), Re: in reply to (email)"
    },
    "Keys": {
      "Word": 2559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "斛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "dry measure equal to some five or ten dou (舒)"
    },
    "Keys": {
      "Hanzi": 4929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4929,
      "Radical": "斗",
      "FrequencyRank": 4531,
      "RadicalIndex": "68.7",
      "StrokeCount": 11
    }
  },
  "饣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "eat"
    },
    "Keys": {
      "Radical": 184
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "艿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3531,
      "Radical": "艸",
      "FrequencyRank": 4668,
      "RadicalIndex": "140.2",
      "StrokeCount": 5
    }
  },
  "强化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánghuà",
      "PrimaryDefinition": "strengthen, intensify, consolidate"
    },
    "Keys": {
      "Word": 4937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "盾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "shield, shield-shaped object; currency sign for Dutch guilder"
    },
    "Keys": {
      "Hanzi": 1579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "GeneralStandard": 1579,
      "Radical": "目",
      "HSK": 5,
      "FrequencyRank": 1395,
      "StrokeCount": 9
    }
  },
  "刮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guā",
      "PrimaryDefinition": "shave, pare off, scrape"
    },
    "Keys": {
      "Hanzi": 1129,
      "Word": 4625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 6,
      "GeneralStandard": 1129,
      "Radical": "刀",
      "HSK": 6,
      "FrequencyRank": 2350,
      "StrokeCount": 8
    }
  },
  "汉语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Hànyǔ",
      "PrimaryDefinition": "Chinese language, CL:門|门[men2]"
    },
    "Keys": {
      "Word": 133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "哏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gén",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4228
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4228,
      "Radical": "口",
      "FrequencyRank": 6052,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "可怜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kělián",
      "PrimaryDefinition": "condole with, have pity on, sympathize with, abject, compassionate, lugubrious, feel for, pity"
    },
    "Keys": {
      "Word": 3656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "绺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǔ",
      "PrimaryDefinition": "skein; tuft, lock; wrinkle"
    },
    "Keys": {
      "Hanzi": 5036
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5036,
      "Radical": "糸",
      "FrequencyRank": 4492,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "听众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīngzhòng",
      "PrimaryDefinition": "auditor, listener, hearer, audience, auditory, gallery, listeners"
    },
    "Keys": {
      "Word": 1969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "戬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "exterminate, destroy; blessing"
    },
    "Keys": {
      "Hanzi": 5653
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5653,
      "Radical": "戈",
      "FrequencyRank": 5096,
      "RadicalIndex": "62.1",
      "StrokeCount": 14
    }
  },
  "收复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōufù",
      "PrimaryDefinition": "to recover (lost territory etc), to recapture"
    },
    "Keys": {
      "Word": 9163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "inquire, consult, discuss; plan"
    },
    "Keys": {
      "Hanzi": 1629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "GeneralStandard": 1629,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2408,
      "StrokeCount": 9
    }
  },
  "洙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "name of a river in Shandong"
    },
    "Keys": {
      "Hanzi": 4338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4338,
      "Radical": "水",
      "FrequencyRank": 5496,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "抗议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàngyì",
      "PrimaryDefinition": "protest"
    },
    "Keys": {
      "Word": 4792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "引人注目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnrén-zhùmù",
      "PrimaryDefinition": "to attract attention, eye-catching, conspicuous"
    },
    "Keys": {
      "Word": 10392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāyáng",
      "PrimaryDefinition": "to develop, to make full use of"
    },
    "Keys": {
      "Word": 6445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "种植": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngzhí",
      "PrimaryDefinition": "plant, grow"
    },
    "Keys": {
      "Word": 3184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "谧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "calm, quiet, still; cautious"
    },
    "Keys": {
      "Hanzi": 5314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5314,
      "Radical": "言",
      "FrequencyRank": 4058,
      "RadicalIndex": "149.1",
      "StrokeCount": 12
    }
  },
  "卿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "noble, high officer"
    },
    "Keys": {
      "Hanzi": 2006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2006,
      "Radical": "卩",
      "FrequencyRank": 2029,
      "RadicalIndex": "26.9",
      "StrokeCount": 10
    }
  },
  "定做": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngzuò",
      "PrimaryDefinition": "to have something made to order"
    },
    "Keys": {
      "Word": 6316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "silicon"
    },
    "Keys": {
      "Hanzi": 2240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2240,
      "Radical": "石",
      "FrequencyRank": 3331,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "香": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "fragrant"
    },
    "Keys": {
      "Hanzi": 1548,
      "Radical": 186,
      "Word": 2044
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "186",
      "Grade": 3,
      "GeneralStandard": 1548,
      "Radical": "香",
      "HSK": 3,
      "FrequencyRank": 776,
      "StrokeCount": 9
    }
  },
  "相处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngchǔ",
      "PrimaryDefinition": "get along (with one another)"
    },
    "Keys": {
      "Word": 3017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "存款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cúnkuǎn",
      "PrimaryDefinition": "deposit, bank saving"
    },
    "Keys": {
      "Word": 3368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "幻想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànxiǎng",
      "PrimaryDefinition": "imagine, daydream, reverie, stargaze, fantasticate, fancify, dream, visionary, fancy, illusion, fantasize, chimerical, woolgather, illusory, fantasy"
    },
    "Keys": {
      "Word": 4676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "笤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": "a broom, a besom"
    },
    "Keys": {
      "Hanzi": 4900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4900,
      "Radical": "竹",
      "FrequencyRank": 4957,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "喤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "ah; harmony"
    },
    "Keys": {
      "Hanzi": 7358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7358,
      "Radical": "口",
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "疮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāng",
      "PrimaryDefinition": "tumor, boil, sore, wound"
    },
    "Keys": {
      "Hanzi": 1625
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1625,
      "Radical": "疒",
      "FrequencyRank": 3093,
      "RadicalIndex": "104.4",
      "StrokeCount": 9
    }
  },
  "淡季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànjì",
      "PrimaryDefinition": "off season, slow business season, see also 旺季[wang4 ji4]"
    },
    "Keys": {
      "Word": 6170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "靴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuē",
      "PrimaryDefinition": "boots"
    },
    "Keys": {
      "Hanzi": 2849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "177.4",
      "GeneralStandard": 2849,
      "Radical": "革",
      "HSK": 7,
      "FrequencyRank": 3091,
      "StrokeCount": 13
    }
  },
  "逭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "to escape from; to flee, to avoid"
    },
    "Keys": {
      "Hanzi": 7248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7248,
      "Radical": "辵",
      "FrequencyRank": 5955,
      "RadicalIndex": "162.8",
      "StrokeCount": 11
    }
  },
  "开发商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāifāshāng",
      "PrimaryDefinition": "developer (of real estate, a commercial product etc)"
    },
    "Keys": {
      "Word": 7747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "fujian province; a river; a tribe"
    },
    "Keys": {
      "Hanzi": 1637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1637,
      "Radical": "門",
      "FrequencyRank": 3306,
      "RadicalIndex": "169.6",
      "StrokeCount": 9
    }
  },
  "侏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "small, little, tiny, dwarf"
    },
    "Keys": {
      "Hanzi": 3959
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3959,
      "Radical": "人",
      "FrequencyRank": 3674,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "熔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "melt, smelt, fuse; mold"
    },
    "Keys": {
      "Hanzi": 3158
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3158,
      "Radical": "火",
      "FrequencyRank": 3011,
      "RadicalIndex": "86.1",
      "StrokeCount": 14
    }
  },
  "社": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "god of the soil and altars to him; group of families; company, society"
    },
    "Keys": {
      "Hanzi": 890,
      "Word": 3874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.3",
      "Grade": 5,
      "GeneralStandard": 890,
      "Radical": "示",
      "HSK": 3,
      "FrequencyRank": 270,
      "StrokeCount": 7
    }
  },
  "脱身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuō shēn",
      "PrimaryDefinition": "to get away, to escape (from obligations), to free oneself, to disengage"
    },
    "Keys": {
      "Word": 9618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "就近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùjìn",
      "PrimaryDefinition": "nearby, in a close neighborhood"
    },
    "Keys": {
      "Word": 7669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè",
      "PrimaryDefinition": "sugar cane"
    },
    "Keys": {
      "Hanzi": 3072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3072,
      "Radical": "艸",
      "FrequencyRank": 3945,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "韵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "rhyme; vowel"
    },
    "Keys": {
      "Hanzi": 2995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "180.4",
      "GeneralStandard": 2995,
      "Radical": "音",
      "HSK": 9,
      "FrequencyRank": 2501,
      "StrokeCount": 13
    }
  },
  "重任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngrèn",
      "PrimaryDefinition": "heavy responsibility"
    },
    "Keys": {
      "Word": 10833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赞不绝口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànbùjuékǒu",
      "PrimaryDefinition": "to praise without cease (idiom), praise sb to high heaven"
    },
    "Keys": {
      "Word": 10597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎng",
      "PrimaryDefinition": "(Cant.) brake, fern"
    },
    "Keys": {
      "Hanzi": 7488
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7488,
      "Radical": "艸",
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "讫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "finish; conclude, stop; exhaust"
    },
    "Keys": {
      "Hanzi": 3557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3557,
      "Radical": "言",
      "FrequencyRank": 4521,
      "RadicalIndex": "149.3",
      "StrokeCount": 5
    }
  },
  "倒卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎomài",
      "PrimaryDefinition": "to resell at a profit, to speculate"
    },
    "Keys": {
      "Word": 6194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䌹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "(simplified form of 絅) (same as 褧) a garment of one colour with no lining, a dust coat"
    },
    "Keys": {
      "Hanzi": 6750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6750,
      "Radical": "",
      "RadicalIndex": "",
      "StrokeCount": 11
    }
  },
  "螺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "spiral shell; conch; spiral"
    },
    "Keys": {
      "Hanzi": 3410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.11",
      "GeneralStandard": 3410,
      "Radical": "虫",
      "HSK": 8,
      "FrequencyRank": 2386,
      "StrokeCount": 17
    }
  },
  "实惠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíhuì",
      "PrimaryDefinition": "tangible benefit, material advantages, cheap, economical, advantageous (deal), substantial (discount)"
    },
    "Keys": {
      "Word": 3903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "中性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxìng",
      "PrimaryDefinition": "neutral"
    },
    "Keys": {
      "Word": 10812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "濩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "pour down, cascade down; look"
    },
    "Keys": {
      "Hanzi": 7902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7902,
      "Radical": "水",
      "RadicalIndex": "85.14",
      "StrokeCount": 16
    }
  },
  "来得及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láidejí",
      "PrimaryDefinition": "there's still time, able to do sth in time"
    },
    "Keys": {
      "Word": 2680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "飞翔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēixiáng",
      "PrimaryDefinition": "to circle in the air, to soar"
    },
    "Keys": {
      "Word": 6510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "组成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ chéng",
      "PrimaryDefinition": "form, make up (into), compose"
    },
    "Keys": {
      "Word": 1247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鼍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "large reptile, water lizard"
    },
    "Keys": {
      "Hanzi": 6430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6430,
      "Radical": "黽",
      "FrequencyRank": 5562,
      "RadicalIndex": "205.12",
      "StrokeCount": 20
    }
  },
  "叹气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn qì",
      "PrimaryDefinition": "to sigh, to heave a sigh"
    },
    "Keys": {
      "Word": 5065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "篓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒu",
      "PrimaryDefinition": "bamboo basket"
    },
    "Keys": {
      "Hanzi": 3266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3266,
      "Radical": "竹",
      "FrequencyRank": 4012,
      "RadicalIndex": "118.9",
      "StrokeCount": 15
    }
  },
  "和尚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héshang",
      "PrimaryDefinition": "bonze, talapoin, monk, Bhikku, Buddhist monk"
    },
    "Keys": {
      "Word": 7042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "保修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoxiū",
      "PrimaryDefinition": "to promise to keep sth in good repair, guarantee, warranty"
    },
    "Keys": {
      "Word": 5524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阀门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fámén",
      "PrimaryDefinition": "valve (mechanical)"
    },
    "Keys": {
      "Word": 6451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "excellent horse, noble steed"
    },
    "Keys": {
      "Hanzi": 2154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.7",
      "GeneralStandard": 2154,
      "Radical": "馬",
      "HSK": 9,
      "FrequencyRank": 3273,
      "StrokeCount": 10
    }
  },
  "护理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùlǐ",
      "PrimaryDefinition": "nursing"
    },
    "Keys": {
      "Word": 7124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "待会儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāihuir5",
      "PrimaryDefinition": "in a moment, later, Taiwan pr. [dai1 hui3 r5]"
    },
    "Keys": {
      "Word": 4481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rì",
      "PrimaryDefinition": "sun"
    },
    "Keys": {
      "Hanzi": 112,
      "Radical": 72,
      "Word": 305
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72",
      "Grade": 1,
      "GeneralStandard": 112,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 101,
      "StrokeCount": 4
    }
  },
  "无聊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúliáo",
      "PrimaryDefinition": "bored, boring, senseless, silly, stupid"
    },
    "Keys": {
      "Word": 2985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "垏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6781,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "蹩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bié",
      "PrimaryDefinition": "to limp"
    },
    "Keys": {
      "Hanzi": 6361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6361,
      "Radical": "足",
      "FrequencyRank": 4308,
      "RadicalIndex": "157.12",
      "StrokeCount": 18
    }
  },
  "佬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "(Cant.) man, person; mature"
    },
    "Keys": {
      "Hanzi": 3951
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3951,
      "Radical": "人",
      "FrequencyRank": 3323,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "主角": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔjué",
      "PrimaryDefinition": "leading role, title_role, star, protagonist, lead, principal, title role, name part"
    },
    "Keys": {
      "Word": 5377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "缜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "detailed, fine; closely woven"
    },
    "Keys": {
      "Hanzi": 5611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5611,
      "Radical": "糸",
      "FrequencyRank": 4383,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "草原": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎoyuán",
      "PrimaryDefinition": "veld, ley, steppe, plain, pastureland, pasture, grassland, lea, grass, campo, prairie, grazing land, grasslands"
    },
    "Keys": {
      "Word": 3296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "含": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "hold in mouth; cherish; contain"
    },
    "Keys": {
      "Hanzi": 800,
      "Word": 2528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 4,
      "GeneralStandard": 800,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 937,
      "StrokeCount": 7
    }
  },
  "口袋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒudai",
      "PrimaryDefinition": "pocket"
    },
    "Keys": {
      "Word": 2665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "克服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèfú",
      "PrimaryDefinition": "surmount, conquer, put up with (hardship/etc.)"
    },
    "Keys": {
      "Word": 1674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "公式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngshì",
      "PrimaryDefinition": "formula"
    },
    "Keys": {
      "Word": 3501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "影星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngxīng",
      "PrimaryDefinition": "film star"
    },
    "Keys": {
      "Word": 5262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "厮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "servant; to make a disturbance"
    },
    "Keys": {
      "Hanzi": 5675
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5675,
      "Radical": "厂",
      "FrequencyRank": 3114,
      "RadicalIndex": "27.12",
      "StrokeCount": 14
    }
  },
  "编剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānjù",
      "PrimaryDefinition": "to write a play, scenario, dramatist, screenwriter"
    },
    "Keys": {
      "Word": 5598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "步入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùrù",
      "PrimaryDefinition": "go into, walk into"
    },
    "Keys": {
      "Word": 5727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "obscene, licentious, lewd"
    },
    "Keys": {
      "Hanzi": 2424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2424,
      "Radical": "水",
      "FrequencyRank": 2297,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "杀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "kill, slaughter, murder; hurt"
    },
    "Keys": {
      "Hanzi": 487,
      "Word": 3857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "Grade": 5,
      "GeneralStandard": 487,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 587,
      "StrokeCount": 6
    }
  },
  "出现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūxiàn",
      "PrimaryDefinition": "appear, arise, emerge"
    },
    "Keys": {
      "Word": 569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "化解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàjiě",
      "PrimaryDefinition": "remove"
    },
    "Keys": {
      "Word": 4675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "厩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "stable; barnyard"
    },
    "Keys": {
      "Hanzi": 4800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4800,
      "Radical": "厂",
      "FrequencyRank": 4181,
      "RadicalIndex": "27.9",
      "StrokeCount": 11
    }
  },
  "风俗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngsú",
      "PrimaryDefinition": "mores, custom, public decency, manner, institution, ritual, Sunna, manners, rite, guise"
    },
    "Keys": {
      "Word": 2474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "暂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàn",
      "PrimaryDefinition": "temporary"
    },
    "Keys": {
      "Hanzi": 2599,
      "Word": 10595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "Grade": 7,
      "GeneralStandard": 2599,
      "Radical": "日",
      "HSK": 5,
      "FrequencyRank": 1325,
      "StrokeCount": 12
    }
  },
  "隋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suí",
      "PrimaryDefinition": "Sui dynasty; surname"
    },
    "Keys": {
      "Hanzi": 2474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2474,
      "Radical": "阜",
      "FrequencyRank": 2828,
      "RadicalIndex": "170.9",
      "StrokeCount": 11
    }
  },
  "髫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5860,
      "Radical": "髟",
      "FrequencyRank": 6566,
      "RadicalIndex": "190.5",
      "StrokeCount": 15
    }
  },
  "娱乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúlè",
      "PrimaryDefinition": "amusement, entertainment, recreation"
    },
    "Keys": {
      "Word": 5279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "耠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō",
      "PrimaryDefinition": "till, dig"
    },
    "Keys": {
      "Hanzi": 5041
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5041,
      "Radical": "耒",
      "RadicalIndex": "127.6",
      "StrokeCount": 12
    }
  },
  "宽敞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānchang",
      "PrimaryDefinition": "spacious, wide"
    },
    "Keys": {
      "Word": 7877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赞许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànxǔ",
      "PrimaryDefinition": "to praise, to laud"
    },
    "Keys": {
      "Word": 10601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "结识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéshí",
      "PrimaryDefinition": "to get to know sb, to meet sb for the first time"
    },
    "Keys": {
      "Word": 7528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "small bamboo basket for holding"
    },
    "Keys": {
      "Hanzi": 5749
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5749,
      "Radical": "竹",
      "FrequencyRank": 5890,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "哟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yō",
      "PrimaryDefinition": "ah, final particle"
    },
    "Keys": {
      "Hanzi": 1516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1516,
      "Radical": "口",
      "FrequencyRank": 2524,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "还原": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán yuán",
      "PrimaryDefinition": "to restore to the original state, to reconstruct (an event), reduction (chemistry)"
    },
    "Keys": {
      "Word": 7165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "look at, inspect, observe, see"
    },
    "Keys": {
      "Hanzi": 1294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "147.4",
      "GeneralStandard": 1294,
      "Radical": "見",
      "HSK": 1,
      "FrequencyRank": 438,
      "StrokeCount": 8
    }
  },
  "珂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "inferior kind of jade"
    },
    "Keys": {
      "Hanzi": 4086
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4086,
      "Radical": "玉",
      "FrequencyRank": 3622,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "废除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèichú",
      "PrimaryDefinition": "abolish, abrogate, repeal"
    },
    "Keys": {
      "Word": 6523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "thunder"
    },
    "Keys": {
      "Hanzi": 2884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "173.5",
      "GeneralStandard": 2884,
      "Radical": "雨",
      "HSK": 4,
      "FrequencyRank": 686,
      "StrokeCount": 13
    }
  },
  "犒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kào",
      "PrimaryDefinition": "entertain victorious soldiers"
    },
    "Keys": {
      "Hanzi": 5741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5741,
      "Radical": "牛",
      "FrequencyRank": 4699,
      "RadicalIndex": "93.1",
      "StrokeCount": 14
    }
  },
  "阚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎn",
      "PrimaryDefinition": "glance, peep; roar, growl"
    },
    "Keys": {
      "Hanzi": 5789
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5789,
      "Radical": "門",
      "FrequencyRank": 6176,
      "RadicalIndex": "169.11",
      "StrokeCount": 14
    }
  },
  "维修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéixiū",
      "PrimaryDefinition": "keep in (good) repair, maintain"
    },
    "Keys": {
      "Word": 2973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "同感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tónggǎn",
      "PrimaryDefinition": "(have the) same feeling, similar impression, common feeling"
    },
    "Keys": {
      "Word": 9533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迟到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí dào",
      "PrimaryDefinition": "tardy, be late, arrive late, be/come/arrive late, come, late, come/arrive late, be"
    },
    "Keys": {
      "Word": 2322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "起来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ  lai",
      "PrimaryDefinition": "[aspect]"
    },
    "Keys": {
      "Word": 285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "全都": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quándōu",
      "PrimaryDefinition": "all, without exception"
    },
    "Keys": {
      "Word": 3823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "超车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo chē",
      "PrimaryDefinition": "to overtake (another car)"
    },
    "Keys": {
      "Word": 5828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昨天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuótiān",
      "PrimaryDefinition": "yesterday"
    },
    "Keys": {
      "Word": 492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "蚯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "earthworm"
    },
    "Keys": {
      "Hanzi": 2278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2278,
      "Radical": "虫",
      "FrequencyRank": 4116,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "永": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "long, perpetual, eternal, forever"
    },
    "Keys": {
      "Hanzi": 324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 324,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 842,
      "StrokeCount": 5
    }
  },
  "正直": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngzhí",
      "PrimaryDefinition": "upright, upstanding, honest"
    },
    "Keys": {
      "Word": 10735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "收入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōurù",
      "PrimaryDefinition": "earning, incoming, gainings, finance, ingathering, proceed, receipts, income, coming-in, taking, pocket, earnings, fruit, revenue, proceeds, living"
    },
    "Keys": {
      "Word": 985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "螫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "poison; sting; poisonous insect"
    },
    "Keys": {
      "Hanzi": 6199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6199,
      "Radical": "虫",
      "FrequencyRank": 5017,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "揽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "grasp, take hold of; monopolize"
    },
    "Keys": {
      "Hanzi": 2518,
      "Word": 7928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2518,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2807,
      "StrokeCount": 12
    }
  },
  "活儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huór5",
      "PrimaryDefinition": "work, (lots of) things to do"
    },
    "Keys": {
      "Word": 7238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荧光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngguāng",
      "PrimaryDefinition": "fluorescence, fluorescent"
    },
    "Keys": {
      "Word": 10416
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 444,
      "Word": 273
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "51.3",
      "Grade": 1,
      "GeneralStandard": 444,
      "Radical": "干",
      "HSK": 1,
      "FrequencyRank": 45,
      "StrokeCount": 6
    }
  },
  "衷心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxīn",
      "PrimaryDefinition": "heartfelt, wholehearted, cordial"
    },
    "Keys": {
      "Word": 10824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呼救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūjiù",
      "PrimaryDefinition": "to call for help"
    },
    "Keys": {
      "Word": 7108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "率先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuàixiān",
      "PrimaryDefinition": "take lead/initiative"
    },
    "Keys": {
      "Word": 2898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "几乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīhū",
      "PrimaryDefinition": "chiefly, anear, about, near, just_about, most, well-nigh, almost, closely, practically, intimately, all but, nearly, just about, virtually, much, nigh"
    },
    "Keys": {
      "Word": 2574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "撖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7637,
      "Radical": "手",
      "RadicalIndex": "64.12",
      "StrokeCount": 14
    }
  },
  "坟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fén",
      "PrimaryDefinition": "grave, mound; bulge; bulging"
    },
    "Keys": {
      "Hanzi": 644,
      "Word": 6546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 7,
      "GeneralStandard": 644,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2370,
      "StrokeCount": 7
    }
  },
  "上旬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngxún",
      "PrimaryDefinition": "first third of a month"
    },
    "Keys": {
      "Word": 8984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "jump"
    },
    "Keys": {
      "Hanzi": 8078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8078,
      "Radical": "走",
      "FrequencyRank": 8671,
      "RadicalIndex": "156.14",
      "StrokeCount": 21
    }
  },
  "蔽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "cover, hide, conceal; shelter"
    },
    "Keys": {
      "Hanzi": 3073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.12",
      "GeneralStandard": 3073,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2121,
      "StrokeCount": 14
    }
  },
  "跂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "creeping, crawling"
    },
    "Keys": {
      "Hanzi": 7163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7163,
      "Radical": "足",
      "FrequencyRank": 7621,
      "RadicalIndex": "157.4",
      "StrokeCount": 11
    }
  },
  "精简": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngjiǎn",
      "PrimaryDefinition": "to simplify, to reduce"
    },
    "Keys": {
      "Word": 7613
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "eyesight obscured; to feel ashamed"
    },
    "Keys": {
      "Hanzi": 5879
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5879,
      "Radical": "目",
      "FrequencyRank": 7031,
      "RadicalIndex": "109.11",
      "StrokeCount": 15
    }
  },
  "楗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "bar of door, bolt of lock"
    },
    "Keys": {
      "Hanzi": 7333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7333,
      "Radical": "木",
      "FrequencyRank": 8305,
      "RadicalIndex": "75.9",
      "StrokeCount": 12
    }
  },
  "采用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎiyòng",
      "PrimaryDefinition": "invoke, admit, assume, adoptive, use, adopt, select for use, embrace, accept, employment, take, introduce, take up, take_on, take over, take on, borrow, employ, adoption"
    },
    "Keys": {
      "Word": 1331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "滋润": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīrùn",
      "PrimaryDefinition": "moist, humid, to moisten, to provide moisture, comfortably off"
    },
    "Keys": {
      "Word": 10946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sā",
      "PrimaryDefinition": "release, cast away, let go; disperse; relax"
    },
    "Keys": {
      "Hanzi": 3192,
      "Word": 8914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.12",
      "Grade": 7,
      "GeneralStandard": 3192,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1445,
      "StrokeCount": 15
    }
  },
  "短片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎnpiàn",
      "PrimaryDefinition": "short film, video clip"
    },
    "Keys": {
      "Word": 4523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "看成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànchéng",
      "PrimaryDefinition": "take for/as, as, look upon .. as, take for, take as, regard ... as"
    },
    "Keys": {
      "Word": 3647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "雕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "engrave, inlay, carve; exhaust; used for 鵰 an eagle, vulture"
    },
    "Keys": {
      "Hanzi": 3365,
      "Word": 6287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "172.8",
      "Grade": 7,
      "GeneralStandard": 3365,
      "Radical": "隹",
      "HSK": 8,
      "FrequencyRank": 1829,
      "StrokeCount": 16
    }
  },
  "辨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "distinguish, discriminate"
    },
    "Keys": {
      "Hanzi": 3371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "160.9",
      "GeneralStandard": 3371,
      "Radical": "辛",
      "HSK": 8,
      "FrequencyRank": 1910,
      "StrokeCount": 16
    }
  },
  "结尾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéwěi",
      "PrimaryDefinition": "ending, coda, to wind up"
    },
    "Keys": {
      "Word": 7529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仍旧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réngjiù",
      "PrimaryDefinition": "still, as before"
    },
    "Keys": {
      "Word": 3848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "噩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "bad, ill-omened, unlucky"
    },
    "Keys": {
      "Hanzi": 3329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3329,
      "Radical": "口",
      "FrequencyRank": 3408,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "公交车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjiāochē",
      "PrimaryDefinition": "public transport vehicle, town bus, CL:輛|辆[liang4]"
    },
    "Keys": {
      "Word": 683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "篼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōu",
      "PrimaryDefinition": "mountain sedan chair; (Cant.) a simple container without a cover"
    },
    "Keys": {
      "Hanzi": 6257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6257,
      "Radical": "竹",
      "FrequencyRank": 8311,
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "活动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huódong",
      "PrimaryDefinition": "activity, maneuver, behavior"
    },
    "Keys": {
      "Word": 740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "呃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "belch; hiccup"
    },
    "Keys": {
      "Hanzi": 3703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3703,
      "Radical": "口",
      "FrequencyRank": 3173,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "账": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "accounts; bill, debt; credit"
    },
    "Keys": {
      "Hanzi": 1114,
      "Word": 5327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "Grade": 6,
      "GeneralStandard": 1114,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 2179,
      "StrokeCount": 8
    }
  },
  "天上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānshang",
      "PrimaryDefinition": "celestial, heavenly"
    },
    "Keys": {
      "Word": 1023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "烊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "to smelt; to melt"
    },
    "Keys": {
      "Hanzi": 4640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4640,
      "Radical": "火",
      "FrequencyRank": 4961,
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "喷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēn",
      "PrimaryDefinition": "spurt, blow out, puff out"
    },
    "Keys": {
      "Hanzi": 2618,
      "Word": 3765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "Grade": 5,
      "GeneralStandard": 2618,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1729,
      "StrokeCount": 12
    }
  },
  "镧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "lanthanum"
    },
    "Keys": {
      "Hanzi": 6249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6249,
      "Radical": "金",
      "FrequencyRank": 5547,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "纯朴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúnpǔ",
      "PrimaryDefinition": "simple and honest, unsophisticated, guileless, variant of 淳樸|淳朴[chun2 pu3]"
    },
    "Keys": {
      "Word": 6030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "basin; cup"
    },
    "Keys": {
      "Hanzi": 3833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3833,
      "Radical": "皿",
      "FrequencyRank": 4100,
      "RadicalIndex": "108.3",
      "StrokeCount": 8
    }
  },
  "垭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "character used in place names"
    },
    "Keys": {
      "Hanzi": 4094
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4094,
      "Radical": "土",
      "FrequencyRank": 5317,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "晰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "clear, evident; clearly"
    },
    "Keys": {
      "Hanzi": 2615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "GeneralStandard": 2615,
      "Radical": "日",
      "HSK": 7,
      "FrequencyRank": 2220,
      "StrokeCount": 12
    }
  },
  "蜈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "centipede"
    },
    "Keys": {
      "Hanzi": 2922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2922,
      "Radical": "虫",
      "FrequencyRank": 3918,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "喁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóng",
      "PrimaryDefinition": "the mouth of a fish at the surface of the water, gasping for breath"
    },
    "Keys": {
      "Hanzi": 5152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5152,
      "Radical": "口",
      "FrequencyRank": 5739,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "轿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "sedan-chair, palanquin"
    },
    "Keys": {
      "Hanzi": 1875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.6",
      "GeneralStandard": 1875,
      "Radical": "車",
      "HSK": 7,
      "FrequencyRank": 2447,
      "StrokeCount": 10
    }
  },
  "鹏程万里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péngchéng-wànlǐ",
      "PrimaryDefinition": "the fabled roc flies ten thousand miles (idiom), one's future prospects are brilliant"
    },
    "Keys": {
      "Word": 8493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "木头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùtou",
      "PrimaryDefinition": "slow-witted, blockhead, log (of wood, timber etc), CL:塊|块[kuai4],根[gen1]"
    },
    "Keys": {
      "Word": 1755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "踏上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàshàng",
      "PrimaryDefinition": "to set foot on, to step on or into"
    },
    "Keys": {
      "Word": 9387
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "white; bright; clear; clean"
    },
    "Keys": {
      "Hanzi": 8094
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8094,
      "Radical": "白",
      "RadicalIndex": "106.18",
      "StrokeCount": 22
    }
  },
  "唯一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéiyī",
      "PrimaryDefinition": "sole, one and only, only, unique"
    },
    "Keys": {
      "Word": 4005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "定心丸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngxīnwán",
      "PrimaryDefinition": "tranquilizer, sth that sets one's mind at ease"
    },
    "Keys": {
      "Word": 6315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "respect, look up to, revere"
    },
    "Keys": {
      "Hanzi": 4369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4369,
      "Radical": "示",
      "FrequencyRank": 4203,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "瑀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "stone resembling jade; agate"
    },
    "Keys": {
      "Hanzi": 7469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7469,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "忽略": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūlüè",
      "PrimaryDefinition": "overpass, fail, pretermit, outlook, pass, overlook, neglectful, snub, cut, neglect, marginalize, miss, lose sight of, disregard, slur, ignore, forget"
    },
    "Keys": {
      "Word": 4669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "损坏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔnhuài",
      "PrimaryDefinition": "to damage, to injure"
    },
    "Keys": {
      "Word": 9371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niào",
      "PrimaryDefinition": "urea"
    },
    "Keys": {
      "Hanzi": 4923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4923,
      "Radical": "肉",
      "FrequencyRank": 5394,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "招呼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāohu",
      "PrimaryDefinition": "take care lest, receive, yoo-hoo, notify, take care of, recognize, say hello to, greet, halloo, speak, notification, come up to, hail, work hard, tell, beckon, get into a fight, address, accost, mind, ask, call"
    },
    "Keys": {
      "Word": 3155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "铫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": "large hoe; surname; a spear"
    },
    "Keys": {
      "Hanzi": 4879
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4879,
      "Radical": "金",
      "FrequencyRank": 6706,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "幸亏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngkuī",
      "PrimaryDefinition": "fortunately, luckily"
    },
    "Keys": {
      "Word": 10058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "phlegm, mucus, spittle"
    },
    "Keys": {
      "Hanzi": 2991,
      "Word": 9408
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.8",
      "Grade": 7,
      "GeneralStandard": 2991,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 3044,
      "StrokeCount": 13
    }
  },
  "旴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "dawn"
    },
    "Keys": {
      "Hanzi": 6589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6589,
      "Radical": "日",
      "RadicalIndex": "72.3",
      "StrokeCount": 7
    }
  },
  "硌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4796,
      "Radical": "石",
      "FrequencyRank": 5552,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "倔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "stubborn, obstinate, intransigent; firm"
    },
    "Keys": {
      "Hanzi": 1974,
      "Word": 7727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 7,
      "GeneralStandard": 1974,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 3542,
      "StrokeCount": 10
    }
  },
  "发愣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fālèng",
      "PrimaryDefinition": "to daydream, in a daze"
    },
    "Keys": {
      "Word": 6444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞩目": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔmù",
      "PrimaryDefinition": "fix eyes on"
    },
    "Keys": {
      "Word": 10864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开朗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāilǎng",
      "PrimaryDefinition": "clear up (of weather)"
    },
    "Keys": {
      "Word": 7752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "訾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "bad-mouth; criticize; defects"
    },
    "Keys": {
      "Hanzi": 5428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5428,
      "Radical": "言",
      "FrequencyRank": 6132,
      "RadicalIndex": "149.5",
      "StrokeCount": 13
    }
  },
  "岑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cén",
      "PrimaryDefinition": "steep, precipitous; peak"
    },
    "Keys": {
      "Hanzi": 3716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3716,
      "Radical": "山",
      "FrequencyRank": 3466,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "神话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénhuà",
      "PrimaryDefinition": "fairy story, fairy tale, mythos, mythic, fable, fairytale, myth, mythology"
    },
    "Keys": {
      "Word": 2856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "靳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5371,
      "Radical": "革",
      "FrequencyRank": 3973,
      "RadicalIndex": "177.4",
      "StrokeCount": 13
    }
  },
  "滑梯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huátī",
      "PrimaryDefinition": "(children's) sliding board, a slide"
    },
    "Keys": {
      "Word": 7137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "语言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔyán",
      "PrimaryDefinition": "linguistic communication, lingual, language, idiom, tongue, oral communication, speech, perspicuity, lingo, contumely, Fortran, parole, natural language, spoken language, linguistic process"
    },
    "Keys": {
      "Word": 1177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "参赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cān sài",
      "PrimaryDefinition": "take part in match, enter competition"
    },
    "Keys": {
      "Word": 4364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "底下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐxia",
      "PrimaryDefinition": "under, below, beneath"
    },
    "Keys": {
      "Word": 1404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "粉碎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěnsuì",
      "PrimaryDefinition": "to crush, to smash, to shatter"
    },
    "Keys": {
      "Word": 6551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùxí",
      "PrimaryDefinition": "to prepare a lesson"
    },
    "Keys": {
      "Word": 2125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "中学生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxuéshēng",
      "PrimaryDefinition": "middle-school student, high school student"
    },
    "Keys": {
      "Word": 479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "加上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāshang",
      "PrimaryDefinition": "conj.: in addition (to)"
    },
    "Keys": {
      "Word": 3585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "茳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6793,
      "Radical": "艸",
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "石头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shítou",
      "PrimaryDefinition": "stony, chimney, rock, lapis, stone"
    },
    "Keys": {
      "Word": 1893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "而是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "érshì",
      "PrimaryDefinition": "conj.: if not A, then B"
    },
    "Keys": {
      "Word": 2441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "臂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "arm"
    },
    "Keys": {
      "Hanzi": 3434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.13",
      "GeneralStandard": 3434,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 1688,
      "StrokeCount": 17
    }
  },
  "海滨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎibīn",
      "PrimaryDefinition": "shore, seaside"
    },
    "Keys": {
      "Word": 6962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíqī",
      "PrimaryDefinition": "epoch, season, date, period_of_time, phase, day, occasion, estate, period, length, period (of time), phase angle, stage, conjuncture, chapter, times, era, leg, guardianship"
    },
    "Keys": {
      "Word": 5019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "勚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "work hard; belabored; toil"
    },
    "Keys": {
      "Hanzi": 7125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7125,
      "Radical": "力",
      "RadicalIndex": "19.12",
      "StrokeCount": 11
    }
  },
  "回忆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíyì",
      "PrimaryDefinition": "recollect, recall"
    },
    "Keys": {
      "Word": 3568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "骤然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòurán",
      "PrimaryDefinition": "suddenly, abruptly"
    },
    "Keys": {
      "Word": 10842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海浪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎilàng",
      "PrimaryDefinition": "sea wave"
    },
    "Keys": {
      "Word": 4648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "单打": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāndǎ",
      "PrimaryDefinition": "play by oneself"
    },
    "Keys": {
      "Word": 4484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "进度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìndù",
      "PrimaryDefinition": "pace, rate of advance, schedule, rate, planned speed, gradation, tempo"
    },
    "Keys": {
      "Word": 7580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赐教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìjiào",
      "PrimaryDefinition": "Enlighten me"
    },
    "Keys": {
      "Word": 6053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钥匙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàoshi",
      "PrimaryDefinition": "key"
    },
    "Keys": {
      "Word": 10250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "bamboo box used carry books"
    },
    "Keys": {
      "Hanzi": 4254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4254,
      "Radical": "竹",
      "FrequencyRank": 3662,
      "RadicalIndex": "118.4",
      "StrokeCount": 9
    }
  },
  "转型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn xíng",
      "PrimaryDefinition": "change, transform"
    },
    "Keys": {
      "Word": 10892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "果汁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒzhī",
      "PrimaryDefinition": "fruit juice"
    },
    "Keys": {
      "Word": 1534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "休克": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūkè",
      "PrimaryDefinition": "shock (loanword), to go into shock"
    },
    "Keys": {
      "Word": 10072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不适": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùshì",
      "PrimaryDefinition": "unwell, indisposed"
    },
    "Keys": {
      "Word": 5679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刻舟求剑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèzhōu-qiújiàn",
      "PrimaryDefinition": "lit. a notch on the side of a boat to locate a sword dropped overboard (idiom), fig. an action made pointless by changed circumstances"
    },
    "Keys": {
      "Word": 7813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疏通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūtōng",
      "PrimaryDefinition": "to unblock, to dredge, to clear the way, to get things flowing, to facilitate, to mediate, to lobby, to explicate (a text)"
    },
    "Keys": {
      "Word": 9219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "水管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐguǎn",
      "PrimaryDefinition": "water pipe"
    },
    "Keys": {
      "Word": 9258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纽带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔdài",
      "PrimaryDefinition": "tie, link, bond"
    },
    "Keys": {
      "Word": 8432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "white millet"
    },
    "Keys": {
      "Hanzi": 3582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3582,
      "Radical": "艸",
      "FrequencyRank": 5895,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "浴室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùshì",
      "PrimaryDefinition": "W.C., bathroom, shower room, balneary, water closet, loo, toilet, closet, bath"
    },
    "Keys": {
      "Word": 10510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "来宾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láibīn",
      "PrimaryDefinition": "guest, visitant, invitee, visitor"
    },
    "Keys": {
      "Word": 7915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7369
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7369,
      "Radical": "囗",
      "RadicalIndex": "31.9",
      "StrokeCount": 12
    }
  },
  "倍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "times, fold, multiple times"
    },
    "Keys": {
      "Hanzi": 1967,
      "Word": 2256
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 4,
      "GeneralStandard": 1967,
      "Radical": "人",
      "HSK": 4,
      "FrequencyRank": 1392,
      "StrokeCount": 10
    }
  },
  "蒂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "peduncle or stem of plants"
    },
    "Keys": {
      "Hanzi": 2556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "GeneralStandard": 2556,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1429,
      "StrokeCount": 12
    }
  },
  "蠲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": "the millipede sometimes confounded with the grow-worm; bright, clear"
    },
    "Keys": {
      "Hanzi": 6486
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6486,
      "Radical": "虫",
      "FrequencyRank": 5339,
      "RadicalIndex": "142.17",
      "StrokeCount": 23
    }
  },
  "市长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìzhǎng",
      "PrimaryDefinition": "alcalde, city manager, mayor, portreeve, mayoral, civic leader, provost, civil leader, burgomaster"
    },
    "Keys": {
      "Word": 981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "在于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàiyú",
      "PrimaryDefinition": "be at, on, lie, in, depend on, lie_in, rest with, lie in, be determined by, consist_in, consist in"
    },
    "Keys": {
      "Word": 3145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "难受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánshòu",
      "PrimaryDefinition": "to feel unwell, to suffer pain, to be difficult to bear"
    },
    "Keys": {
      "Word": 887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "宋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng",
      "PrimaryDefinition": "Song dynasty; surname"
    },
    "Keys": {
      "Hanzi": 878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.4",
      "GeneralStandard": 878,
      "Radical": "宀",
      "HSK": 9,
      "FrequencyRank": 990,
      "StrokeCount": 7
    }
  },
  "鏖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "to fight to the end, engage in a fierce battle"
    },
    "Keys": {
      "Hanzi": 6409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6409,
      "Radical": "金",
      "FrequencyRank": 4857,
      "RadicalIndex": "167.11",
      "StrokeCount": 19
    }
  },
  "会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "assemble, meet together; meeting"
    },
    "Keys": {
      "Hanzi": 486,
      "Word": 739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 2,
      "GeneralStandard": 486,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 29,
      "StrokeCount": 6
    }
  },
  "摩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "rub, scour, grind; friction"
    },
    "Keys": {
      "Hanzi": 3279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.11",
      "GeneralStandard": 3279,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1162,
      "StrokeCount": 15
    }
  },
  "侵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīn",
      "PrimaryDefinition": "invade, encroach upon, raid"
    },
    "Keys": {
      "Hanzi": 1574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1574,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1086,
      "StrokeCount": 9
    }
  },
  "爻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "lines on a trigram"
    },
    "Keys": {
      "Radical": 89,
      "Hanzi": 3520
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3520,
      "Radical": "爻",
      "FrequencyRank": 4680,
      "RadicalIndex": "89",
      "StrokeCount": 4
    }
  },
  "唧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "chirping of insects; pump; (Cant.) a final particle"
    },
    "Keys": {
      "Hanzi": 1914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1914,
      "Radical": "口",
      "FrequencyRank": 3757,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "同志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngzhì",
      "PrimaryDefinition": "brother, pal, chum, crony, coterie, confrere, colleague, frater, tovarisch, compeer, paisano, brotherhood, copemate, brethren, sidekick, fellow, buddy, tavarish, comrade"
    },
    "Keys": {
      "Word": 9540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轮胎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúntāi",
      "PrimaryDefinition": "tire, pneumatic tire"
    },
    "Keys": {
      "Word": 8159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chái",
      "PrimaryDefinition": "a company, companion; together"
    },
    "Keys": {
      "Hanzi": 3963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3963,
      "Radical": "人",
      "FrequencyRank": 5429,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "漏洞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòudòng",
      "PrimaryDefinition": "leak, flow, hole, loophole"
    },
    "Keys": {
      "Word": 3704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "出国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū guó",
      "PrimaryDefinition": "go abroad (from one's own country)"
    },
    "Keys": {
      "Word": 565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "桁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": "cross-beams of roof"
    },
    "Keys": {
      "Hanzi": 4458
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4458,
      "Radical": "木",
      "FrequencyRank": 5380,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "敦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": "esteem; honest, candid, sincere"
    },
    "Keys": {
      "Hanzi": 2729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "66.8",
      "GeneralStandard": 2729,
      "Radical": "攴",
      "HSK": 9,
      "FrequencyRank": 1722,
      "StrokeCount": 12
    }
  },
  "绂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "ribbon or cord used attach ornaments"
    },
    "Keys": {
      "Hanzi": 4076
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4076,
      "Radical": "糸",
      "FrequencyRank": 6417,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "闻名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénmíng",
      "PrimaryDefinition": "known, famous, know sb. by repute, be familiar with sb.'s name, well-known, famed"
    },
    "Keys": {
      "Word": 9740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "silk cloth, satin damask"
    },
    "Keys": {
      "Hanzi": 2495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2495,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 2635,
      "StrokeCount": 11
    }
  },
  "崌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "mountain name"
    },
    "Keys": {
      "Hanzi": 7174
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7174,
      "Radical": "山",
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "咳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāi",
      "PrimaryDefinition": "cough"
    },
    "Keys": {
      "Hanzi": 1513,
      "Word": 3654
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 5,
      "GeneralStandard": 1513,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 2373,
      "StrokeCount": 9
    }
  },
  "糵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "fermenting grain; grain which has sprouted; yeast"
    },
    "Keys": {
      "Hanzi": 8089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8089,
      "Radical": "米",
      "FrequencyRank": 7846,
      "RadicalIndex": "119.17",
      "StrokeCount": 22
    }
  },
  "月亮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèliang",
      "PrimaryDefinition": "moonlight, Cynthia, moon, lune, Phoebe, Diana, lunar"
    },
    "Keys": {
      "Word": 1186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "往往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngwǎng",
      "PrimaryDefinition": "oftentimes, ofttimes, always, more often than not, frequently, oft, often"
    },
    "Keys": {
      "Word": 2002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "补给": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔjǐ",
      "PrimaryDefinition": "supply, replenishment, to replenish"
    },
    "Keys": {
      "Word": 5685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "big-mouthed flounder"
    },
    "Keys": {
      "Hanzi": 8016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8016,
      "Radical": "魚",
      "FrequencyRank": 8077,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "人们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénmen",
      "PrimaryDefinition": "workforce, hands, work force, folk, people, they, the people, men, manpower, common people"
    },
    "Keys": {
      "Word": 953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "隆重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóngzhòng",
      "PrimaryDefinition": "grand, prosperous, ceremonious, solemn"
    },
    "Keys": {
      "Word": 8117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "sweet smelling, rich in aroma; (Cant.) to move, hit"
    },
    "Keys": {
      "Hanzi": 1043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.6",
      "GeneralStandard": 1043,
      "Radical": "邑",
      "HSK": 7,
      "FrequencyRank": 1915,
      "StrokeCount": 8
    }
  },
  "沉稳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénwěn",
      "PrimaryDefinition": "steady, calm, unflustered"
    },
    "Keys": {
      "Word": 5855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "the eastern egret"
    },
    "Keys": {
      "Hanzi": 4708
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4708,
      "Radical": "鳥",
      "FrequencyRank": 5663,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "介于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièyú",
      "PrimaryDefinition": "between, intermediate, to lie between"
    },
    "Keys": {
      "Word": 7548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外贸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàimào",
      "PrimaryDefinition": "foreign trade"
    },
    "Keys": {
      "Word": 9639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光临": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānglín",
      "PrimaryDefinition": "(formal) to honor with one's presence, to attend"
    },
    "Keys": {
      "Word": 2516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "近代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìndài",
      "PrimaryDefinition": "the not-very-distant past, modern times, excluding recent decades, (in the context of Chinese history) the period from the Opium Wars until the May 4th Movement (mid-19th century to 1919), capitalist times (pre-1949)"
    },
    "Keys": {
      "Word": 2636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "认知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènzhī",
      "PrimaryDefinition": "cognize, perceive"
    },
    "Keys": {
      "Word": 8864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摄像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèxiàng",
      "PrimaryDefinition": "to videotape"
    },
    "Keys": {
      "Word": 3878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "谖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "forget; lie, cheat, deceive"
    },
    "Keys": {
      "Hanzi": 5005
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5005,
      "Radical": "言",
      "FrequencyRank": 4576,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "流血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúxuè",
      "PrimaryDefinition": "shed blood, hemorrhage, bloody, bleed"
    },
    "Keys": {
      "Word": 8108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng xiào",
      "PrimaryDefinition": "to take effect, to go into effect"
    },
    "Keys": {
      "Word": 9060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "late, tardy; slow; delay"
    },
    "Keys": {
      "Hanzi": 906,
      "Word": 3335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 5,
      "GeneralStandard": 906,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 1374,
      "StrokeCount": 7
    }
  },
  "灯光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēngguāng",
      "PrimaryDefinition": "light, lighting, the light of a lamp, illuminance, glim, illumination, lamplight"
    },
    "Keys": {
      "Word": 2398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鬓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìn",
      "PrimaryDefinition": "hair on temples"
    },
    "Keys": {
      "Hanzi": 3473
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3473,
      "Radical": "髟",
      "FrequencyRank": 3749,
      "RadicalIndex": "190.1",
      "StrokeCount": 20
    }
  },
  "课本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèběn",
      "PrimaryDefinition": "text edition, textbook, text, school text, schoolbook"
    },
    "Keys": {
      "Word": 197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "扣押": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòuyā",
      "PrimaryDefinition": "to detain, to hold in custody, to distrain, to seize property"
    },
    "Keys": {
      "Word": 7854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "落户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò hù",
      "PrimaryDefinition": "to settle, to set up home"
    },
    "Keys": {
      "Word": 8172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心脏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnzàng",
      "PrimaryDefinition": "ticker, cardiac, center, pump, heart"
    },
    "Keys": {
      "Word": 5183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "枣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎo",
      "PrimaryDefinition": "date tree; dates, jujubes; surname"
    },
    "Keys": {
      "Hanzi": 1040,
      "Word": 10610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 7,
      "GeneralStandard": 1040,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2851,
      "StrokeCount": 8
    }
  },
  "笠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "bamboo hat; bamboo covering"
    },
    "Keys": {
      "Hanzi": 4898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4898,
      "Radical": "竹",
      "FrequencyRank": 3278,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "理会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐhuì",
      "PrimaryDefinition": "understand, comprehend, take notice of, pay attention to"
    },
    "Keys": {
      "Word": 7983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuō",
      "PrimaryDefinition": "argue, quarrel, squabble; far"
    },
    "Keys": {
      "Hanzi": 7154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7154,
      "Radical": "辵",
      "RadicalIndex": "162.8",
      "StrokeCount": 11
    }
  },
  "联手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánshǒu",
      "PrimaryDefinition": "lit. to join hands, to act together"
    },
    "Keys": {
      "Word": 4830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "惬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "be satisfied, be comfortable"
    },
    "Keys": {
      "Hanzi": 4983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4983,
      "Radical": "心",
      "FrequencyRank": 3976,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "素不相识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùbùxiāngshí",
      "PrimaryDefinition": "to be total strangers (idiom)"
    },
    "Keys": {
      "Word": 9351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "win; surplus, gain, profit"
    },
    "Keys": {
      "Hanzi": 3427,
      "Word": 2103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "8.15",
      "Grade": 3,
      "GeneralStandard": 3427,
      "Radical": "亠",
      "HSK": 3,
      "FrequencyRank": 1836,
      "StrokeCount": 17
    }
  },
  "纬度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěidù",
      "PrimaryDefinition": "latitude"
    },
    "Keys": {
      "Word": 9706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "omen; million; mega; also trillion. China = million; Japan and Taiwan = trillion"
    },
    "Keys": {
      "Hanzi": 489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "10.4",
      "GeneralStandard": 489,
      "Radical": "儿",
      "HSK": 8,
      "FrequencyRank": 2246,
      "StrokeCount": 6
    }
  },
  "浕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4347,
      "Radical": "水",
      "RadicalIndex": "85.14",
      "StrokeCount": 9
    }
  },
  "饷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng",
      "PrimaryDefinition": "rations and pay for soldiers"
    },
    "Keys": {
      "Hanzi": 4299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4299,
      "Radical": "食",
      "FrequencyRank": 3103,
      "RadicalIndex": "184.6",
      "StrokeCount": 9
    }
  },
  "矛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "spear"
    },
    "Keys": {
      "Radical": 110,
      "Hanzi": 343
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "110",
      "GeneralStandard": 343,
      "Radical": "矛",
      "HSK": 5,
      "FrequencyRank": 1441,
      "StrokeCount": 5
    }
  },
  "滑冰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá bīng",
      "PrimaryDefinition": "to skate, skating"
    },
    "Keys": {
      "Word": 7135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "以致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐzhì",
      "PrimaryDefinition": "conj.: so that, with the result that"
    },
    "Keys": {
      "Word": 10318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "囚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "prisoner, convict; confine"
    },
    "Keys": {
      "Hanzi": 261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "31.2",
      "GeneralStandard": 261,
      "Radical": "囗",
      "HSK": 7,
      "FrequencyRank": 2594,
      "StrokeCount": 5
    }
  },
  "觉悟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéwù",
      "PrimaryDefinition": "to come to understand, to realize, consciousness, awareness, Buddhist enlightenment (Sanskrit: cittotpāda)"
    },
    "Keys": {
      "Word": 4772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "担子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànzi",
      "PrimaryDefinition": "carrying pole and the loads on it, burden, task, responsibility, CL:副[fu4]"
    },
    "Keys": {
      "Word": 6167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "towns in Shandong province"
    },
    "Keys": {
      "Hanzi": 6804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6804,
      "Radical": "邑",
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "莸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "caryopteris divaricata"
    },
    "Keys": {
      "Hanzi": 4439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4439,
      "Radical": "艸",
      "FrequencyRank": 5354,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "疼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "aches, pains; be fond of; love"
    },
    "Keys": {
      "Hanzi": 2031,
      "Word": 1013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.5",
      "Grade": 2,
      "GeneralStandard": 2031,
      "Radical": "疒",
      "HSK": 2,
      "FrequencyRank": 1710,
      "StrokeCount": 10
    }
  },
  "犯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "commit crime, violate; criminal"
    },
    "Keys": {
      "Hanzi": 292,
      "Word": 4552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.2",
      "Grade": 6,
      "GeneralStandard": 292,
      "Radical": "犬",
      "HSK": 6,
      "FrequencyRank": 767,
      "StrokeCount": 5
    }
  },
  "假期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàqī",
      "PrimaryDefinition": "season, holiday, leave, period of leave, vacation"
    },
    "Keys": {
      "Word": 755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "锂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "lithium"
    },
    "Keys": {
      "Hanzi": 5182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5182,
      "Radical": "金",
      "FrequencyRank": 4873,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "簇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "swarm, crowd together, cluster"
    },
    "Keys": {
      "Hanzi": 3418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.11",
      "GeneralStandard": 3418,
      "Radical": "竹",
      "HSK": 9,
      "FrequencyRank": 2896,
      "StrokeCount": 17
    }
  },
  "西部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xībù",
      "PrimaryDefinition": "western part, West, westward, west, the west"
    },
    "Keys": {
      "Word": 2022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "旅客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚkè",
      "PrimaryDefinition": "journeyer, guest, hotel guest, traveler, traffic, wayfarer, viator, rider, traveller, passer, passenger"
    },
    "Keys": {
      "Word": 859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "梳理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūlǐ",
      "PrimaryDefinition": "to comb, fig. to sort out"
    },
    "Keys": {
      "Word": 9215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "庇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "cover, shield, shelter, protect"
    },
    "Keys": {
      "Hanzi": 833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.4",
      "GeneralStandard": 833,
      "Radical": "广",
      "HSK": 6,
      "FrequencyRank": 2793,
      "StrokeCount": 7
    }
  },
  "叚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "FALSE"
    },
    "Keys": {
      "Hanzi": 6907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6907,
      "Radical": "又",
      "RadicalIndex": "29.7",
      "StrokeCount": 9
    }
  },
  "大棚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàpéng",
      "PrimaryDefinition": "Greenhouse"
    },
    "Keys": {
      "Word": 6127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "得了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "déle",
      "PrimaryDefinition": "all right!, that's enough!, (emphatically, in rhetorical questions) possible"
    },
    "Keys": {
      "Word": 3409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "地理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìlǐ",
      "PrimaryDefinition": "geographical, geographic, geographics, geographical features of a place, geography"
    },
    "Keys": {
      "Word": 6251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùliào",
      "PrimaryDefinition": "expect, predict, anticipate"
    },
    "Keys": {
      "Word": 10516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多元": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōyuán",
      "PrimaryDefinition": "multiplex, multicomponent, multivariate, poly-"
    },
    "Keys": {
      "Word": 6398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "abundant, rich, plentiful"
    },
    "Keys": {
      "Hanzi": 2790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.7",
      "GeneralStandard": 2790,
      "Radical": "衣",
      "HSK": 8,
      "FrequencyRank": 2023,
      "StrokeCount": 12
    }
  },
  "树叶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùyè",
      "PrimaryDefinition": "leaves, foliage, leafage, leaf, leave, leaves (of trees)"
    },
    "Keys": {
      "Word": 2890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "理性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐxìng",
      "PrimaryDefinition": "reason"
    },
    "Keys": {
      "Word": 7988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摇头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo tóu",
      "PrimaryDefinition": "shake one's head"
    },
    "Keys": {
      "Word": 4094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "登记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēng jì",
      "PrimaryDefinition": "register, check in"
    },
    "Keys": {
      "Word": 2400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "猎人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièrén",
      "PrimaryDefinition": "shikaree, nimrod, yager, chasseur, chaser, jager, huntsman, jaeger, venerer, hunter"
    },
    "Keys": {
      "Word": 8054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "五颜六色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔyán-liùsè",
      "PrimaryDefinition": "multi-colored, every color under the sun"
    },
    "Keys": {
      "Word": 2991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "制订": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìdìng",
      "PrimaryDefinition": "work/map out, formulate"
    },
    "Keys": {
      "Word": 3174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "有声有色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒushēng-yǒusè",
      "PrimaryDefinition": "having sound and color (idiom), vivid, dazzling"
    },
    "Keys": {
      "Word": 10476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "证人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngren",
      "PrimaryDefinition": "witness"
    },
    "Keys": {
      "Word": 10740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.6",
      "GeneralStandard": 1635,
      "Radical": "門",
      "HSK": 9,
      "FrequencyRank": 3347,
      "StrokeCount": 9
    }
  },
  "为期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéiqī",
      "PrimaryDefinition": "by/lasting (a definite time)"
    },
    "Keys": {
      "Word": 3998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "漭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎng",
      "PrimaryDefinition": "vast; expansive"
    },
    "Keys": {
      "Hanzi": 5569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5569,
      "Radical": "水",
      "FrequencyRank": 8696,
      "RadicalIndex": "85.11",
      "StrokeCount": 13
    }
  },
  "鲿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "codfish"
    },
    "Keys": {
      "Hanzi": 7964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7964,
      "Radical": "魚",
      "FrequencyRank": 6723,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "散": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàn",
      "PrimaryDefinition": "scatter, disperse, break up"
    },
    "Keys": {
      "Hanzi": 2546,
      "Word": 3854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.8",
      "Grade": 5,
      "GeneralStandard": 2546,
      "Radical": "攴",
      "HSK": 3,
      "FrequencyRank": 866,
      "StrokeCount": 12
    }
  },
  "倥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng",
      "PrimaryDefinition": "boorish, ignorant; urgent, pressing"
    },
    "Keys": {
      "Hanzi": 4577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4577,
      "Radical": "人",
      "FrequencyRank": 5678,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "凸显": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūxiǎn",
      "PrimaryDefinition": "relieve, to make prominent or effective by contrast"
    },
    "Keys": {
      "Word": 9572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 3742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3742,
      "Radical": "肉",
      "FrequencyRank": 5512,
      "RadicalIndex": "130.3",
      "StrokeCount": 7
    }
  },
  "长效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángxiào",
      "PrimaryDefinition": "to be effective over an extended period"
    },
    "Keys": {
      "Word": 5811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鼠标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔbiāo",
      "PrimaryDefinition": "mouse (computing)"
    },
    "Keys": {
      "Word": 3926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "文科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénkē",
      "PrimaryDefinition": "liberal arts, humanities"
    },
    "Keys": {
      "Word": 9734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奥秘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àomì",
      "PrimaryDefinition": "secret, mystery"
    },
    "Keys": {
      "Word": 5455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "文字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénzì",
      "PrimaryDefinition": "writing, phraseology, writing style, language, endearment, script, written language, text, writing phraseology, writing style/phraseology, character, textual, characters, symbolic representation, version, word-sign, literal, letter, word, literalness"
    },
    "Keys": {
      "Word": 2021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "手势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒushì",
      "PrimaryDefinition": "sign, pantomime, gesticulation, motion, sign_language, gesture, signal"
    },
    "Keys": {
      "Word": 9178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "probe, poke, prick, pierce"
    },
    "Keys": {
      "Hanzi": 3571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3571,
      "Radical": "手",
      "FrequencyRank": 5757,
      "RadicalIndex": "64.3",
      "StrokeCount": 6
    }
  },
  "推断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīduàn",
      "PrimaryDefinition": "to infer, to deduce, to predict, to extrapolate"
    },
    "Keys": {
      "Word": 9593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "salt, pickle; be dirty"
    },
    "Keys": {
      "Hanzi": 5228
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5228,
      "Radical": "肉",
      "FrequencyRank": 4219,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "猫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "māo",
      "PrimaryDefinition": "cat"
    },
    "Keys": {
      "Hanzi": 2367,
      "Word": 867
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.9",
      "Grade": 2,
      "GeneralStandard": 2367,
      "Radical": "犬",
      "HSK": 2,
      "FrequencyRank": 1673,
      "StrokeCount": 11
    }
  },
  "绅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "girdle; tie, bind; gentry"
    },
    "Keys": {
      "Hanzi": 1339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "GeneralStandard": 1339,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 2554,
      "StrokeCount": 8
    }
  },
  "暗示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànshì",
      "PrimaryDefinition": "(drop a) hint, suggest"
    },
    "Keys": {
      "Word": 2235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "硕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "great, eminent; large, big"
    },
    "Keys": {
      "Hanzi": 2241
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.6",
      "GeneralStandard": 2241,
      "Radical": "石",
      "HSK": 5,
      "FrequencyRank": 2304,
      "StrokeCount": 11
    }
  },
  "三明治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sānmíngzhì",
      "PrimaryDefinition": "sandwich (loanword), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 4986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "节约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéyuē",
      "PrimaryDefinition": "economize, save"
    },
    "Keys": {
      "Word": 1624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "示威": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì wēi",
      "PrimaryDefinition": "put on show of force, demonstrate, march"
    },
    "Keys": {
      "Word": 9128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sháo",
      "PrimaryDefinition": "rush plant"
    },
    "Keys": {
      "Hanzi": 3873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3873,
      "Radical": "艸",
      "FrequencyRank": 5319,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "泰斗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàidǒu",
      "PrimaryDefinition": "leading scholar of his time, magnate"
    },
    "Keys": {
      "Word": 9394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réng",
      "PrimaryDefinition": "yet, still, as ever; again; keep -ing, continuing"
    },
    "Keys": {
      "Hanzi": 135,
      "Word": 1857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "Grade": 3,
      "GeneralStandard": 135,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 601,
      "StrokeCount": 4
    }
  },
  "额外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "éwài",
      "PrimaryDefinition": "extra, added, additional"
    },
    "Keys": {
      "Word": 6410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "韬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "sheath, scabbard, bow case"
    },
    "Keys": {
      "Hanzi": 5628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5628,
      "Radical": "韋",
      "FrequencyRank": 3652,
      "RadicalIndex": "178.1",
      "StrokeCount": 14
    }
  },
  "雪上加霜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuěshàng-jiāshuāng",
      "PrimaryDefinition": "to add hail to snow (idiom), one disaster on top of another, to make things worse in a bad situation"
    },
    "Keys": {
      "Word": 10139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "予": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "I, me; to give"
    },
    "Keys": {
      "Hanzi": 184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "6.3",
      "GeneralStandard": 184,
      "Radical": "亅",
      "HSK": 6,
      "FrequencyRank": 925,
      "StrokeCount": 4
    }
  },
  "澉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "to wash name of a place"
    },
    "Keys": {
      "Hanzi": 5811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5811,
      "Radical": "水",
      "FrequencyRank": 7101,
      "RadicalIndex": "85.12",
      "StrokeCount": 14
    }
  },
  "搬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "transfer, move, remove, shift"
    },
    "Keys": {
      "Hanzi": 2840,
      "Word": 1269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 3,
      "GeneralStandard": 2840,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 1766,
      "StrokeCount": 13
    }
  },
  "赞美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànměi",
      "PrimaryDefinition": "to admire, to praise, to eulogize"
    },
    "Keys": {
      "Word": 10602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巨额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùé",
      "PrimaryDefinition": "large sum (of money), a huge amount"
    },
    "Keys": {
      "Word": 7696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnzheng",
      "PrimaryDefinition": "anyway, anyhow, in any case"
    },
    "Keys": {
      "Word": 1439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蜮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "a fabulous creature like a turtle; a toad"
    },
    "Keys": {
      "Hanzi": 5706
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5706,
      "Radical": "虫",
      "FrequencyRank": 5567,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "孕育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnyù",
      "PrimaryDefinition": "to be pregnant, to produce offspring, to nurture (a development, school of thought, artwork etc), fig. replete with (culture etc)"
    },
    "Keys": {
      "Word": 10573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "手掌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuzhǎng",
      "PrimaryDefinition": "palm"
    },
    "Keys": {
      "Word": 9183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "master, chief owner; host; lord"
    },
    "Keys": {
      "Hanzi": 300,
      "Word": 10849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "3.4",
      "Grade": 7,
      "GeneralStandard": 300,
      "Radical": "丶",
      "HSK": 2,
      "FrequencyRank": 87,
      "StrokeCount": 5
    }
  },
  "亡羊补牢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wángyáng-bǔláo",
      "PrimaryDefinition": "lit. to mend the pen after sheep are lost (idiom), fig. to act belatedly, better late than never, to lock the stable door after the horse has bolted"
    },
    "Keys": {
      "Word": 9667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乒乓球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīngpāngqiú",
      "PrimaryDefinition": "table tennis, ping-pong, table tennis ball, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 8543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一部分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībùfen",
      "PrimaryDefinition": "portion, part of, subset"
    },
    "Keys": {
      "Word": 1136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "薢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "woody climbing plant"
    },
    "Keys": {
      "Hanzi": 7843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7843,
      "Radical": "艸",
      "FrequencyRank": 7872,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "增大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngdà",
      "PrimaryDefinition": "to enlarge, to amplify, to magnify"
    },
    "Keys": {
      "Word": 4181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "韫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn",
      "PrimaryDefinition": "secrete, hide, conceal"
    },
    "Keys": {
      "Hanzi": 5348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5348,
      "Radical": "韋",
      "FrequencyRank": 5668,
      "RadicalIndex": "178.9",
      "StrokeCount": 13
    }
  },
  "让": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ràng",
      "PrimaryDefinition": "allow, permit, yield, concede"
    },
    "Keys": {
      "Hanzi": 317,
      "Word": 952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.3",
      "Grade": 2,
      "GeneralStandard": 317,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 339,
      "StrokeCount": 5
    }
  },
  "尊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūn",
      "PrimaryDefinition": "respect, revere, venerate; honor"
    },
    "Keys": {
      "Hanzi": 2743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "41.9",
      "GeneralStandard": 2743,
      "Radical": "寸",
      "HSK": 5,
      "FrequencyRank": 1134,
      "StrokeCount": 12
    }
  },
  "激活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīhuó",
      "PrimaryDefinition": "to activate"
    },
    "Keys": {
      "Word": 7282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篮球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lánqiú",
      "PrimaryDefinition": "basketball"
    },
    "Keys": {
      "Word": 832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "妇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "married women; woman; wife"
    },
    "Keys": {
      "Hanzi": 579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "GeneralStandard": 579,
      "Radical": "女",
      "HSK": 4,
      "FrequencyRank": 932,
      "StrokeCount": 6
    }
  },
  "飞船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēichuán",
      "PrimaryDefinition": "airship, spaceship"
    },
    "Keys": {
      "Word": 4559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "空中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngzhōng",
      "PrimaryDefinition": "in_the_air, air, in the sky, sky, aerial, open air/sky, open air, space, open sky, midair"
    },
    "Keys": {
      "Word": 3665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "日子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìzi",
      "PrimaryDefinition": "yom, day, a particular day, livelihood, life, date, days, time"
    },
    "Keys": {
      "Word": 958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": "result, effect; effectiveness"
    },
    "Keys": {
      "Hanzi": 2034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "66.6",
      "GeneralStandard": 2034,
      "Radical": "攴",
      "HSK": 3,
      "FrequencyRank": 551,
      "StrokeCount": 10
    }
  },
  "踊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "leap, jump"
    },
    "Keys": {
      "Hanzi": 3104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.7",
      "GeneralStandard": 3104,
      "Radical": "足",
      "HSK": 9,
      "FrequencyRank": 3884,
      "StrokeCount": 14
    }
  },
  "锬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "long spear"
    },
    "Keys": {
      "Hanzi": 7536
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7536,
      "Radical": "金",
      "FrequencyRank": 6189,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "魋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuí",
      "PrimaryDefinition": "bear"
    },
    "Keys": {
      "Hanzi": 7955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7955,
      "Radical": "鬼",
      "FrequencyRank": 7168,
      "RadicalIndex": "194.8",
      "StrokeCount": 17
    }
  },
  "均衡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnhéng",
      "PrimaryDefinition": "balance"
    },
    "Keys": {
      "Word": 7730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国庆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Guóqìng",
      "PrimaryDefinition": "National Day"
    },
    "Keys": {
      "Word": 1532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "虽然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suīrán",
      "PrimaryDefinition": "conj.: though, although"
    },
    "Keys": {
      "Word": 1003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "羽毛球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔmáoqiú",
      "PrimaryDefinition": "shuttlecock, badminton, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 4146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "开天辟地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāitiān-pìdì",
      "PrimaryDefinition": "to split heaven and earth apart (idiom), refers to the Pangu 盤古|盘古[Pan2 gu3] creation myth"
    },
    "Keys": {
      "Word": 7756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祭奠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìdiàn",
      "PrimaryDefinition": "to offer sacrifices (to one's ancestors), to hold or attend a memorial service"
    },
    "Keys": {
      "Word": 7339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偷懒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōu lǎn",
      "PrimaryDefinition": "to goof off, to be lazy"
    },
    "Keys": {
      "Word": 9553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "建筑师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànzhùshī",
      "PrimaryDefinition": "surveyor, architect"
    },
    "Keys": {
      "Word": 7430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "转让": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnràng",
      "PrimaryDefinition": "transfer (technology, goods etc), conveyancing (property)"
    },
    "Keys": {
      "Word": 4265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "受苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu kǔ",
      "PrimaryDefinition": "to suffer hardship"
    },
    "Keys": {
      "Word": 9198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìdao",
      "PrimaryDefinition": "tunnel, causeway, authentic, genuine, typical, from a place known for the product, thorough, conscientious"
    },
    "Keys": {
      "Word": 6248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噪声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàoshēng",
      "PrimaryDefinition": "noise"
    },
    "Keys": {
      "Word": 10615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "觐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "have imperial audience"
    },
    "Keys": {
      "Hanzi": 5870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5870,
      "Radical": "見",
      "FrequencyRank": 4435,
      "RadicalIndex": "147.11",
      "StrokeCount": 15
    }
  },
  "幂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "cover-cloth, cover with cloth"
    },
    "Keys": {
      "Hanzi": 5311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5311,
      "Radical": "冖",
      "FrequencyRank": 5384,
      "RadicalIndex": "14.1",
      "StrokeCount": 12
    }
  },
  "别致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biézhì",
      "PrimaryDefinition": "unusual, unique, variant of 別緻|别致[bie2 zhi4]"
    },
    "Keys": {
      "Word": 5643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "to loose, take off, get rid of"
    },
    "Keys": {
      "Hanzi": 6541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6541,
      "Radical": "用",
      "RadicalIndex": "101.1",
      "StrokeCount": 6
    }
  },
  "合适": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héshì",
      "PrimaryDefinition": "set, fit, accommodate, befit, suitable, right, appropriate, belong, beseem, becoming, suit"
    },
    "Keys": {
      "Word": 715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "恰到好处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàdào-hǎochù",
      "PrimaryDefinition": "it's just perfect, it's just right"
    },
    "Keys": {
      "Word": 8639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "man of sixty; aged, old"
    },
    "Keys": {
      "Hanzi": 4419
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4419,
      "Radical": "老",
      "FrequencyRank": 3779,
      "RadicalIndex": "125.4",
      "StrokeCount": 10
    }
  },
  "鞣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róu",
      "PrimaryDefinition": "tan, soften"
    },
    "Keys": {
      "Hanzi": 6319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6319,
      "Radical": "革",
      "FrequencyRank": 5085,
      "RadicalIndex": "177.9",
      "StrokeCount": 18
    }
  },
  "地球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìqiú",
      "PrimaryDefinition": "the earth/globe, globe, world, Earth, ball, terrene, earth, the globe, the earth"
    },
    "Keys": {
      "Word": 619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "状": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàng",
      "PrimaryDefinition": "form; appearance; shape; official"
    },
    "Keys": {
      "Hanzi": 828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.3",
      "GeneralStandard": 828,
      "Radical": "犬",
      "HSK": 3,
      "FrequencyRank": 624,
      "StrokeCount": 7
    }
  },
  "嗜好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìhào",
      "PrimaryDefinition": "hobby, indulgence, habit, addiction"
    },
    "Keys": {
      "Word": 9162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "折腾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēteng",
      "PrimaryDefinition": "to toss from side to side (e.g. sleeplessly), to repeat sth over and over again, to torment sb, to play crazy, to squander (time, money)"
    },
    "Keys": {
      "Word": 10682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打雷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ léi",
      "PrimaryDefinition": "to rumble with thunder, clap of thunder"
    },
    "Keys": {
      "Word": 2362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "艋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "small boat"
    },
    "Keys": {
      "Hanzi": 5764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5764,
      "Radical": "舟",
      "FrequencyRank": 6513,
      "RadicalIndex": "137.8",
      "StrokeCount": 14
    }
  },
  "臃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "swell up; swelling; fat"
    },
    "Keys": {
      "Hanzi": 6275
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6275,
      "Radical": "肉",
      "FrequencyRank": 4439,
      "RadicalIndex": "130.13",
      "StrokeCount": 17
    }
  },
  "献血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn xiě",
      "PrimaryDefinition": "to donate blood"
    },
    "Keys": {
      "Word": 9912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàibiǎo",
      "PrimaryDefinition": "shell, outward appearance, outwall, mess, presentation, resemblance, externality, semblance, guise, visual aspect, outwardness, exterior, rind, showing, look, superficies, likeness, mien, appearance, vizard, surface, garment, external, getup"
    },
    "Keys": {
      "Word": 9634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": "transfer, move, change; tune"
    },
    "Keys": {
      "Hanzi": 2123,
      "Word": 1409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "Grade": 3,
      "GeneralStandard": 2123,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 400,
      "StrokeCount": 10
    }
  },
  "晒太阳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shài tàiyang",
      "PrimaryDefinition": "Sun"
    },
    "Keys": {
      "Word": 8943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "pre-dawn"
    },
    "Keys": {
      "Hanzi": 4824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4824,
      "Radical": "日",
      "FrequencyRank": 4682,
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "婚姻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūnyīn",
      "PrimaryDefinition": "connubiality, wedlock, marital, union, marriage, married couple, matrimonial, match, man and wife, matrimony"
    },
    "Keys": {
      "Word": 7227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埋藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máicáng",
      "PrimaryDefinition": "to bury, to hide by burying, hidden"
    },
    "Keys": {
      "Word": 8185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "halberd with crescent blade"
    },
    "Keys": {
      "Hanzi": 5092
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5092,
      "Radical": "戈",
      "FrequencyRank": 3726,
      "RadicalIndex": "62.8",
      "StrokeCount": 12
    }
  },
  "疯子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngzi",
      "PrimaryDefinition": "madman, lunatic"
    },
    "Keys": {
      "Word": 6589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杨树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yángshù",
      "PrimaryDefinition": "poplar tree, various trees of genus Populus"
    },
    "Keys": {
      "Word": 10219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": "break, smash; broken, busted"
    },
    "Keys": {
      "Hanzi": 2879,
      "Word": 3950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.8",
      "Grade": 5,
      "GeneralStandard": 2879,
      "Radical": "石",
      "HSK": 5,
      "FrequencyRank": 1420,
      "StrokeCount": 13
    }
  },
  "警": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "guard, watch; alert, alarm"
    },
    "Keys": {
      "Hanzi": 3453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.13",
      "GeneralStandard": 3453,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 687,
      "StrokeCount": 19
    }
  },
  "卵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǎn",
      "PrimaryDefinition": "egg; ovum; roe; spawn"
    },
    "Keys": {
      "Hanzi": 818,
      "Word": 8150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "26.5",
      "Grade": 7,
      "GeneralStandard": 818,
      "Radical": "卩",
      "HSK": 8,
      "FrequencyRank": 2008,
      "StrokeCount": 7
    }
  },
  "贸易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màoyì",
      "PrimaryDefinition": "deal, carriage trade, trade, business, commerce"
    },
    "Keys": {
      "Word": 3716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "奠定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàndìng",
      "PrimaryDefinition": "establish, settle"
    },
    "Keys": {
      "Word": 6283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuài",
      "PrimaryDefinition": "commander, commander-in-chief"
    },
    "Keys": {
      "Hanzi": 233,
      "Word": 2895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.2",
      "Grade": 4,
      "GeneralStandard": 233,
      "Radical": "巾",
      "HSK": 4,
      "FrequencyRank": 1888,
      "StrokeCount": 5
    }
  },
  "全国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánguó",
      "PrimaryDefinition": "whole nation, nationwide, countrywide, national"
    },
    "Keys": {
      "Word": 950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "爵士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéshì",
      "PrimaryDefinition": "knight, Sir, (loanword) jazz"
    },
    "Keys": {
      "Word": 7728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "振动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèndòng",
      "PrimaryDefinition": "to vibrate, to shake, vibration"
    },
    "Keys": {
      "Word": 4204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "摧毁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuīhuǐ",
      "PrimaryDefinition": "take_out, blot_out, bang up, blast, devastate, mow_down, zap, tobreak, wreck, smash up, bust up, smite, destroy, bust, demolish, knock_down, destruction, ravage, unbuild, liquidate, smash, deflower, knock_off, break, wrack"
    },
    "Keys": {
      "Word": 6080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "官方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānfāng",
      "PrimaryDefinition": "authorities, official, authority, regime, authorization, government"
    },
    "Keys": {
      "Word": 2514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "暖和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuǎnhuo",
      "PrimaryDefinition": "warm, nice and warm"
    },
    "Keys": {
      "Word": 1776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "眨眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎ yǎn",
      "PrimaryDefinition": "to blink, to wink, in the twinkling of an eye"
    },
    "Keys": {
      "Word": 10627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搜集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōují",
      "PrimaryDefinition": "to gather, to collect"
    },
    "Keys": {
      "Word": 9336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "近年来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnniánlái",
      "PrimaryDefinition": "for the past few years"
    },
    "Keys": {
      "Word": 7584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教育部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Jiàoyùbù",
      "PrimaryDefinition": "Education, Ministry of Education, Department of Education, Education Department"
    },
    "Keys": {
      "Word": 4724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "讹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "swindle, cheat; erroneous, wrong"
    },
    "Keys": {
      "Hanzi": 555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 555,
      "Radical": "言",
      "HSK": 9,
      "FrequencyRank": 3782,
      "StrokeCount": 6
    }
  },
  "假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "falsehood, deception; vacation"
    },
    "Keys": {
      "Hanzi": 2337,
      "Word": 754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "Grade": 2,
      "GeneralStandard": 2337,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 636,
      "StrokeCount": 11
    }
  },
  "潘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pān",
      "PrimaryDefinition": "surname; water in which rice has been rinsed; a river that flows into the Han"
    },
    "Keys": {
      "Hanzi": 3296
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 3296,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2070,
      "StrokeCount": 15
    }
  },
  "文人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénrén",
      "PrimaryDefinition": "man of letters, scholar, literati, civilian (vs. military)"
    },
    "Keys": {
      "Word": 9737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沼泽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎozé",
      "PrimaryDefinition": "marsh, swamp, wetlands, glade"
    },
    "Keys": {
      "Word": 10672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "频": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pín",
      "PrimaryDefinition": "frequently, again and again"
    },
    "Keys": {
      "Hanzi": 2892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.7",
      "GeneralStandard": 2892,
      "Radical": "頁",
      "HSK": 5,
      "FrequencyRank": 1535,
      "StrokeCount": 13
    }
  },
  "人员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rényuán",
      "PrimaryDefinition": "strength, personnel, force, staff"
    },
    "Keys": {
      "Word": 1850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "纩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "cotton; silk"
    },
    "Keys": {
      "Hanzi": 6554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6554,
      "Radical": "糸",
      "FrequencyRank": 6831,
      "RadicalIndex": "120.3",
      "StrokeCount": 6
    }
  },
  "崽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎi",
      "PrimaryDefinition": "a child, a servant; a diminutive"
    },
    "Keys": {
      "Hanzi": 5169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5169,
      "Radical": "山",
      "FrequencyRank": 3712,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "反思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnsī",
      "PrimaryDefinition": "to think back over sth, to review, to revisit, to rethink, reflection, reassessment"
    },
    "Keys": {
      "Word": 6475
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "包围": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāowéi",
      "PrimaryDefinition": "to surround, to encircle, to hem in"
    },
    "Keys": {
      "Word": 3229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "婢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "servant girl; your servant"
    },
    "Keys": {
      "Hanzi": 5020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5020,
      "Radical": "女",
      "FrequencyRank": 3082,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "砾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "gravel, pebbles"
    },
    "Keys": {
      "Hanzi": 1865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1865,
      "Radical": "石",
      "FrequencyRank": 3511,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "贪污": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tānwū",
      "PrimaryDefinition": "corrupt, venal"
    },
    "Keys": {
      "Word": 9399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "divination with stalks of plants; divining rod"
    },
    "Keys": {
      "Hanzi": 5502
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5502,
      "Radical": "竹",
      "FrequencyRank": 5559,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "地铁站": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìtiězhàn",
      "PrimaryDefinition": "subway station"
    },
    "Keys": {
      "Word": 620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "狡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "cunning, deceitful, treacherous"
    },
    "Keys": {
      "Hanzi": 1604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.6",
      "GeneralStandard": 1604,
      "Radical": "犬",
      "HSK": 7,
      "FrequencyRank": 2670,
      "StrokeCount": 9
    }
  },
  "恃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "rely on, presume on, trust to"
    },
    "Keys": {
      "Hanzi": 1684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1684,
      "Radical": "心",
      "FrequencyRank": 3219,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "温馨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnxīn",
      "PrimaryDefinition": "warm and fragrant"
    },
    "Keys": {
      "Word": 9730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "千变万化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānbiàn-wànhuà",
      "PrimaryDefinition": "countless changes, constant permutation"
    },
    "Keys": {
      "Word": 8644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斑点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāndiǎn",
      "PrimaryDefinition": "spot, stain, speckle"
    },
    "Keys": {
      "Word": 5487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yū",
      "PrimaryDefinition": "a hematoma, contusion; extravasted blood"
    },
    "Keys": {
      "Hanzi": 7572
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7572,
      "Radical": "疒",
      "FrequencyRank": 4157,
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "用法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngfǎ",
      "PrimaryDefinition": "hang, usage, use"
    },
    "Keys": {
      "Word": 5265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "细节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìjié",
      "PrimaryDefinition": "circumstance, minutiae, nicety, minutia, speciality, item, detail, particulars, punctilio, particular, point, specific, respect, regard, details"
    },
    "Keys": {
      "Word": 3003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "快车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàichē",
      "PrimaryDefinition": "express (train, bus etc)"
    },
    "Keys": {
      "Word": 4801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鸩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "a bird resembling the secretary falcon"
    },
    "Keys": {
      "Hanzi": 4373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4373,
      "Radical": "鳥",
      "FrequencyRank": 5144,
      "RadicalIndex": "196.4",
      "StrokeCount": 9
    }
  },
  "攘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎng",
      "PrimaryDefinition": "seize, take by force; repel"
    },
    "Keys": {
      "Hanzi": 6421
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.17",
      "GeneralStandard": 6421,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 3404,
      "StrokeCount": 20
    }
  },
  "或多或少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòduōhuòshǎo",
      "PrimaryDefinition": "more or less"
    },
    "Keys": {
      "Word": 7251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鳁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēn",
      "PrimaryDefinition": "sardine"
    },
    "Keys": {
      "Hanzi": 7965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7965,
      "Radical": "魚",
      "FrequencyRank": 7152,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "谔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "honest speech, straightforward"
    },
    "Keys": {
      "Hanzi": 5003
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5003,
      "Radical": "言",
      "FrequencyRank": 4341,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "编造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānzào",
      "PrimaryDefinition": "to compile, to draw up, to fabricate, to invent, to concoct, to make up, to cook up"
    },
    "Keys": {
      "Word": 5601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí lai",
      "PrimaryDefinition": "come back, get_back, recur, revisit, come, return, be back, come_back"
    },
    "Keys": {
      "Word": 156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "稔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěn",
      "PrimaryDefinition": "ripe grain; harvest; to know, be familiar with"
    },
    "Keys": {
      "Hanzi": 5499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5499,
      "Radical": "禾",
      "FrequencyRank": 5151,
      "RadicalIndex": "115.8",
      "StrokeCount": 13
    }
  },
  "咙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "throat"
    },
    "Keys": {
      "Hanzi": 1089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1089,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 3027,
      "StrokeCount": 8
    }
  },
  "俏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "like, similar; resemble; pretty"
    },
    "Keys": {
      "Hanzi": 1561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1561,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2880,
      "StrokeCount": 9
    }
  },
  "后悔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuhuǐ",
      "PrimaryDefinition": "regret, repent"
    },
    "Keys": {
      "Word": 3543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "供应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyìng",
      "PrimaryDefinition": "supply"
    },
    "Keys": {
      "Word": 2502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "嗤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "laugh at, ridicule, sneer; snort"
    },
    "Keys": {
      "Hanzi": 5475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5475,
      "Radical": "口",
      "FrequencyRank": 3254,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "人数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénshù",
      "PrimaryDefinition": "number of people"
    },
    "Keys": {
      "Word": 955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "飞行员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēixíngyuán",
      "PrimaryDefinition": "pilot, aviator"
    },
    "Keys": {
      "Word": 4560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "师资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīzī",
      "PrimaryDefinition": "persons qualifies to teach, teachers, person qualified to teach"
    },
    "Keys": {
      "Word": 9095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "Draba nemerosa bebe carpa"
    },
    "Keys": {
      "Hanzi": 5089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5089,
      "Radical": "艸",
      "FrequencyRank": 6326,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "农民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngmín",
      "PrimaryDefinition": "mujik, farmhand, granger, boor, farm worker, peasantry, countrymen, farmer, agriculture, husbandman, muzhik, peasant, fieldhand, moujik, fellah, muzjik"
    },
    "Keys": {
      "Word": 1771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "幄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "tent; mosquito net"
    },
    "Keys": {
      "Hanzi": 5175
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5175,
      "Radical": "巾",
      "FrequencyRank": 4823,
      "RadicalIndex": "50.9",
      "StrokeCount": 12
    }
  },
  "削": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuē",
      "PrimaryDefinition": "scrape off, pare, trim"
    },
    "Keys": {
      "Hanzi": 1475,
      "Word": 9957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.7",
      "Grade": 7,
      "GeneralStandard": 1475,
      "Radical": "刀",
      "HSK": 8,
      "FrequencyRank": 1794,
      "StrokeCount": 9
    }
  },
  "前者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánzhě",
      "PrimaryDefinition": "predecessor, the former, former"
    },
    "Keys": {
      "Word": 8672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féi",
      "PrimaryDefinition": "fat, plump, obese; fertile"
    },
    "Keys": {
      "Hanzi": 1189,
      "Word": 2462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 4,
      "GeneralStandard": 1189,
      "Radical": "肉",
      "HSK": 4,
      "FrequencyRank": 1620,
      "StrokeCount": 8
    }
  },
  "不得了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù déliǎo",
      "PrimaryDefinition": "desperately serious, disastrous, extremely, exceedingly"
    },
    "Keys": {
      "Word": 3277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "考场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎochǎng",
      "PrimaryDefinition": "exam room"
    },
    "Keys": {
      "Word": 4791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "躲避": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒbì",
      "PrimaryDefinition": "hide (oneself), elude, dodge"
    },
    "Keys": {
      "Word": 6405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纪律": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìlǜ",
      "PrimaryDefinition": "disciplinary, discipline, ferule, morale, regime, laws and regulations"
    },
    "Keys": {
      "Word": 2587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "窿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "mine shaft; cavity, hole"
    },
    "Keys": {
      "Hanzi": 3383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.12",
      "GeneralStandard": 3383,
      "Radical": "穴",
      "HSK": 9,
      "FrequencyRank": 3845,
      "StrokeCount": 16
    }
  },
  "害虫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàichóng",
      "PrimaryDefinition": "injurious insect, pest"
    },
    "Keys": {
      "Word": 6975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "ingots, bars of metal; hurry"
    },
    "Keys": {
      "Hanzi": 4874
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4874,
      "Radical": "金",
      "FrequencyRank": 4709,
      "RadicalIndex": "167.7",
      "StrokeCount": 11
    }
  },
  "禁止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnzhǐ",
      "PrimaryDefinition": "estop, inhibit, bar, outlaw, banish, forbid, suppress, interdict, defend, taboo, veto, prohibit, enjoin, blackball, restrain, debar, proscribe, negative, disallow, relegate, nix, prohibition, ban"
    },
    "Keys": {
      "Word": 2634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "忠于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngyú",
      "PrimaryDefinition": "to be loyal to"
    },
    "Keys": {
      "Word": 10818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "rash; addiction, craving, habit"
    },
    "Keys": {
      "Hanzi": 3368,
      "Word": 10405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.11",
      "Grade": 7,
      "GeneralStandard": 3368,
      "Radical": "疒",
      "HSK": 7,
      "FrequencyRank": 3258,
      "StrokeCount": 16
    }
  },
  "璋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "jade plaything; jade ornament"
    },
    "Keys": {
      "Hanzi": 5856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5856,
      "Radical": "玉",
      "FrequencyRank": 3267,
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "资格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīge",
      "PrimaryDefinition": "claim, credential, prerequisite, character, capability, qualifications, requirement, qualification, capacity, root/basis part of a character, status, sufficiency, seniority, competence, fitness, competency, condition"
    },
    "Keys": {
      "Word": 2207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "伤感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānggǎn",
      "PrimaryDefinition": "sad, emotional, sentimental, pathos"
    },
    "Keys": {
      "Word": 8965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "house; room; building, shelter"
    },
    "Keys": {
      "Hanzi": 1722,
      "Word": 4020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.6",
      "Grade": 5,
      "GeneralStandard": 1722,
      "Radical": "尸",
      "HSK": 3,
      "FrequencyRank": 863,
      "StrokeCount": 9
    }
  },
  "阶级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiējí",
      "PrimaryDefinition": "state, social class, degree, people, gradation, echelon, persuasion, position, order, class, remove, social status, notch, parity, social rank, sort, step, condition, rank, social station"
    },
    "Keys": {
      "Word": 7496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麻醉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mázuì",
      "PrimaryDefinition": "anesthesize, poison, drug"
    },
    "Keys": {
      "Word": 8179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎn",
      "PrimaryDefinition": "blush, turn red"
    },
    "Keys": {
      "Hanzi": 4724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4724,
      "Radical": "赤",
      "FrequencyRank": 5467,
      "RadicalIndex": "155.5",
      "StrokeCount": 11
    }
  },
  "勘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kān",
      "PrimaryDefinition": "investigate; compare; collate"
    },
    "Keys": {
      "Hanzi": 2195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.9",
      "GeneralStandard": 2195,
      "Radical": "力",
      "HSK": 9,
      "FrequencyRank": 2585,
      "StrokeCount": 11
    }
  },
  "鸹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guā",
      "PrimaryDefinition": "the crow"
    },
    "Keys": {
      "Hanzi": 4890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4890,
      "Radical": "鳥",
      "FrequencyRank": 5187,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "公鸡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjī",
      "PrimaryDefinition": "cock, rooster"
    },
    "Keys": {
      "Word": 4606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "惩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "punish, reprimand; warn"
    },
    "Keys": {
      "Hanzi": 2703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2703,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1821,
      "StrokeCount": 12
    }
  },
  "累积": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěijī",
      "PrimaryDefinition": "accumulate"
    },
    "Keys": {
      "Word": 7960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "想方设法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngfāng-shèfǎ",
      "PrimaryDefinition": "to think up every possible method (idiom), to devise ways and means, to try this, that and the other"
    },
    "Keys": {
      "Word": 9946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "担负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānfù",
      "PrimaryDefinition": "bear, shoulder, take on, be charged with"
    },
    "Keys": {
      "Word": 6157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liāo",
      "PrimaryDefinition": "lift up, raise; leave, depart"
    },
    "Keys": {
      "Hanzi": 3193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3193,
      "Radical": "手",
      "FrequencyRank": 3514,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "管子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnzi",
      "PrimaryDefinition": "Guanzi or Guan Zhong 管仲 (-645 BC), famous politician of Qi 齊國|齐国 of Spring and Autumn period, Guanzi, classical book containing writings of Guan Zhong and his school, tube, pipe, drinking straw, CL:根[gen1]"
    },
    "Keys": {
      "Word": 6886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "周到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōudào",
      "PrimaryDefinition": "thoughtful, considerate, attentive, thorough, also pr. [zhou1 dao5]"
    },
    "Keys": {
      "Word": 10837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōng",
      "PrimaryDefinition": "footprints, traces, tracks"
    },
    "Keys": {
      "Hanzi": 3244
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "157.8",
      "GeneralStandard": 3244,
      "Radical": "足",
      "HSK": 7,
      "FrequencyRank": 1532,
      "StrokeCount": 15
    }
  },
  "邪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "wrong, evil, depraved, vicious, perverse, heterodox"
    },
    "Keys": {
      "Hanzi": 405,
      "Word": 9987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "163.4",
      "Grade": 7,
      "GeneralStandard": 405,
      "Radical": "邑",
      "HSK": 7,
      "FrequencyRank": 1539,
      "StrokeCount": 6
    }
  },
  "泪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèi",
      "PrimaryDefinition": "tears; weep, cry"
    },
    "Keys": {
      "Hanzi": 1246,
      "Word": 2686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 4,
      "GeneralStandard": 1246,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1271,
      "StrokeCount": 8
    }
  },
  "杧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "mango (Mangifera indica)"
    },
    "Keys": {
      "Hanzi": 6581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6581,
      "Radical": "木",
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "觋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": "wizard"
    },
    "Keys": {
      "Hanzi": 4773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4773,
      "Radical": "見",
      "FrequencyRank": 6452,
      "RadicalIndex": "147.7",
      "StrokeCount": 11
    }
  },
  "顾不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùbushàng",
      "PrimaryDefinition": "cannot attend to or manage"
    },
    "Keys": {
      "Word": 6842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīndé",
      "PrimaryDefinition": "what one has learned from work/study/etc., study, what one has learned from work, insight, acquaintanceship, what one has learned from study/etc., etc., what one has learned from work, study, etc."
    },
    "Keys": {
      "Word": 10005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诀别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juébié",
      "PrimaryDefinition": "to bid farewell, to part (usually with little hope of meeting again)"
    },
    "Keys": {
      "Word": 7718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盘子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pánzi",
      "PrimaryDefinition": "plate, pan, salver, bowl, dish, tray"
    },
    "Keys": {
      "Word": 2772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "复印": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùyìn",
      "PrimaryDefinition": "to photocopy, to duplicate a document"
    },
    "Keys": {
      "Word": 1465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "温度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēndù",
      "PrimaryDefinition": "barothermohygrograph, temperature, barothermohygrogram"
    },
    "Keys": {
      "Word": 1070
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "冼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "a surname"
    },
    "Keys": {
      "Hanzi": 3995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3995,
      "Radical": "冫",
      "FrequencyRank": 5800,
      "RadicalIndex": "15.6",
      "StrokeCount": 8
    }
  },
  "趴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pā",
      "PrimaryDefinition": "lying prone, leaning over"
    },
    "Keys": {
      "Hanzi": 1493,
      "Word": 8454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.2",
      "Grade": 7,
      "GeneralStandard": 1493,
      "Radical": "足",
      "HSK": 7,
      "FrequencyRank": 2977,
      "StrokeCount": 9
    }
  },
  "诚信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxìn",
      "PrimaryDefinition": "genuine, honest, in good faith, honesty, integrity"
    },
    "Keys": {
      "Word": 2317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "纪录片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìlùpiàn",
      "PrimaryDefinition": "newsreel, documentary (film or TV program), CL:部[bu4]"
    },
    "Keys": {
      "Word": 7322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "guest, visitor; surname; submit"
    },
    "Keys": {
      "Hanzi": 2102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.7",
      "GeneralStandard": 2102,
      "Radical": "宀",
      "HSK": 5,
      "FrequencyRank": 1534,
      "StrokeCount": 10
    }
  },
  "㭕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "a board (on the back of a donkey) for carrying things; saddle"
    },
    "Keys": {
      "Hanzi": 6797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6797,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "滍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7597,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "薅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāo",
      "PrimaryDefinition": "to weed; to eradicate"
    },
    "Keys": {
      "Hanzi": 6055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6055,
      "Radical": "艸",
      "FrequencyRank": 6010,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "勇敢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒnggǎn",
      "PrimaryDefinition": "brave, courageous"
    },
    "Keys": {
      "Word": 3109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "退票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuìpiào",
      "PrimaryDefinition": "to bounce (a check), to return a ticket, ticket refund"
    },
    "Keys": {
      "Word": 5108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "树立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùlì",
      "PrimaryDefinition": "to set up, to establish"
    },
    "Keys": {
      "Word": 9231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "认": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "recognize, know, understand"
    },
    "Keys": {
      "Hanzi": 171,
      "Word": 3843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.2",
      "Grade": 5,
      "GeneralStandard": 171,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 213,
      "StrokeCount": 4
    }
  },
  "午饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔfàn",
      "PrimaryDefinition": "lunch, CL:份[fen4],頓|顿[dun4],次[ci4],餐[can1]"
    },
    "Keys": {
      "Word": 380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "荪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūn",
      "PrimaryDefinition": "aromatic grass; iris, flower"
    },
    "Keys": {
      "Hanzi": 4135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4135,
      "Radical": "艸",
      "FrequencyRank": 4051,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "头部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóubù",
      "PrimaryDefinition": "costard, crest, caput, head, nose, top section"
    },
    "Keys": {
      "Word": 9555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "日历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìlì",
      "PrimaryDefinition": "calendar, CL:張|张[zhang1],本[ben3]"
    },
    "Keys": {
      "Word": 2825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "海量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiliàng",
      "PrimaryDefinition": "huge volume"
    },
    "Keys": {
      "Word": 6964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "后来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòulái",
      "PrimaryDefinition": "afterwards, later"
    },
    "Keys": {
      "Word": 723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "铑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "rhodium"
    },
    "Keys": {
      "Hanzi": 4865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4865,
      "Radical": "金",
      "FrequencyRank": 6031,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "嗬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hē",
      "PrimaryDefinition": "(Cant.) interrogative particle"
    },
    "Keys": {
      "Hanzi": 5443
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5443,
      "Radical": "口",
      "FrequencyRank": 4141,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "门口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménkǒu",
      "PrimaryDefinition": "porch, portal, doorway, gateway, threshold, room access, door, entering, entrance"
    },
    "Keys": {
      "Word": 236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "楸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "mallotus japonicus, deciduous tre"
    },
    "Keys": {
      "Hanzi": 5397
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5397,
      "Radical": "木",
      "FrequencyRank": 5691,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "早已": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎoyǐ",
      "PrimaryDefinition": "already, since, long ago, long ago/since, long since"
    },
    "Keys": {
      "Word": 2129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "下功夫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià gōngfu",
      "PrimaryDefinition": "see 下工夫[xia4 gong1 fu5]"
    },
    "Keys": {
      "Word": 9858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàichū",
      "PrimaryDefinition": "go out"
    },
    "Keys": {
      "Word": 5118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "糯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuò",
      "PrimaryDefinition": "glutinous rice; glutinous, sticky"
    },
    "Keys": {
      "Hanzi": 3485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3485,
      "Radical": "米",
      "FrequencyRank": 4208,
      "RadicalIndex": "119.14",
      "StrokeCount": 20
    }
  },
  "督": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dū",
      "PrimaryDefinition": "supervise, oversee, direct"
    },
    "Keys": {
      "Hanzi": 2891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.8",
      "GeneralStandard": 2891,
      "Radical": "目",
      "HSK": 6,
      "FrequencyRank": 946,
      "StrokeCount": 13
    }
  },
  "编排": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānpái",
      "PrimaryDefinition": "to arrange, to lay out"
    },
    "Keys": {
      "Word": 5602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椅子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐzi",
      "PrimaryDefinition": "chair"
    },
    "Keys": {
      "Word": 1146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "暕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7517,
      "Radical": "日",
      "RadicalIndex": "72.9",
      "StrokeCount": 13
    }
  },
  "伸手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn shǒu",
      "PrimaryDefinition": "ask for help, stretch one's hand, meddle in, stretch, hold out one's hand, stretch out one's hand, reach"
    },
    "Keys": {
      "Word": 9016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "机遇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīyù",
      "PrimaryDefinition": "opportunity, favorable circumstance, stroke of luck"
    },
    "Keys": {
      "Word": 2580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "贮藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùcáng",
      "PrimaryDefinition": "to store up, to hoard, deposits"
    },
    "Keys": {
      "Word": 10869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不同寻常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùtóngxúncháng",
      "PrimaryDefinition": "unusual"
    },
    "Keys": {
      "Word": 5711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6936,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "岗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎng",
      "PrimaryDefinition": "post; position"
    },
    "Keys": {
      "Hanzi": 754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.4",
      "GeneralStandard": 754,
      "Radical": "山",
      "HSK": 6,
      "FrequencyRank": 1795,
      "StrokeCount": 7
    }
  },
  "肖像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoxiàng",
      "PrimaryDefinition": "portrait (painting, photo etc), (in a general sense) representation of a person, likeness"
    },
    "Keys": {
      "Word": 9981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "11th terrestrial branch"
    },
    "Keys": {
      "Hanzi": 387
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 387,
      "Radical": "戈",
      "FrequencyRank": 3921,
      "RadicalIndex": "62.2",
      "StrokeCount": 6
    }
  },
  "婚纱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūnshā",
      "PrimaryDefinition": "gown, wedding dress"
    },
    "Keys": {
      "Word": 7226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "只要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐyào",
      "PrimaryDefinition": "conj.: so long as, provided"
    },
    "Keys": {
      "Word": 1221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "琔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7287,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "囊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náng",
      "PrimaryDefinition": "bag, purse, sack; put in bag"
    },
    "Keys": {
      "Hanzi": 3496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.19",
      "GeneralStandard": 3496,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2041,
      "StrokeCount": 22
    }
  },
  "旅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "trip, journey; travel; traveler"
    },
    "Keys": {
      "Hanzi": 2046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "70.6",
      "GeneralStandard": 2046,
      "Radical": "方",
      "HSK": 2,
      "FrequencyRank": 950,
      "StrokeCount": 10
    }
  },
  "国有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóyǒu",
      "PrimaryDefinition": "belong to the state, nationalized, state-owned"
    },
    "Keys": {
      "Word": 6936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "住院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù yuàn",
      "PrimaryDefinition": "be hospitalized"
    },
    "Keys": {
      "Word": 1235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "摄影师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèyǐngshī",
      "PrimaryDefinition": "photographer, cameraman"
    },
    "Keys": {
      "Word": 3880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蕙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "species of fragrant orchid"
    },
    "Keys": {
      "Hanzi": 5872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5872,
      "Radical": "艸",
      "FrequencyRank": 4042,
      "RadicalIndex": "140.12",
      "StrokeCount": 15
    }
  },
  "出院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū yuàn",
      "PrimaryDefinition": "to leave hospital, to be discharged from hospital"
    },
    "Keys": {
      "Word": 570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "患": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "suffer, worry about; suffering"
    },
    "Keys": {
      "Hanzi": 2285,
      "Word": 7178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "Grade": 7,
      "GeneralStandard": 2285,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1249,
      "StrokeCount": 11
    }
  },
  "文学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénxué",
      "PrimaryDefinition": "literary, philology, letters, letter, literature"
    },
    "Keys": {
      "Word": 2016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "风浪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnglàng",
      "PrimaryDefinition": "wind and waves, stormy sea"
    },
    "Keys": {
      "Word": 6569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遗嘱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yízhǔ",
      "PrimaryDefinition": "testament, will"
    },
    "Keys": {
      "Word": 10307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "humpback; surname"
    },
    "Keys": {
      "Hanzi": 4910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4910,
      "Radical": "人",
      "FrequencyRank": 4573,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "伯伯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bóbo",
      "PrimaryDefinition": "uncle-in-law, father's elder brother, uncle"
    },
    "Keys": {
      "Word": 5665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "日后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìhòu",
      "PrimaryDefinition": "in the future"
    },
    "Keys": {
      "Word": 8873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "go quickly or swiftly; hurry"
    },
    "Keys": {
      "Hanzi": 596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.3",
      "GeneralStandard": 596,
      "Radical": "馬",
      "HSK": 9,
      "FrequencyRank": 2100,
      "StrokeCount": 6
    }
  },
  "嘚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dē",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5720
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5720,
      "Radical": "口",
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "讲学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng xué",
      "PrimaryDefinition": "to lecture (on branch of learning)"
    },
    "Keys": {
      "Word": 7445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "醭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bú",
      "PrimaryDefinition": "molds on liquids; scum"
    },
    "Keys": {
      "Hanzi": 8034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8034,
      "Radical": "酉",
      "FrequencyRank": 5989,
      "RadicalIndex": "164.12",
      "StrokeCount": 19
    }
  },
  "佝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "rickets"
    },
    "Keys": {
      "Hanzi": 3732
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3732,
      "Radical": "人",
      "FrequencyRank": 5065,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "保佑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoyòu",
      "PrimaryDefinition": "to bless and protect, blessing"
    },
    "Keys": {
      "Word": 5525
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "hasten, hurry; be attracted to"
    },
    "Keys": {
      "Hanzi": 2516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "156.5",
      "GeneralStandard": 2516,
      "Radical": "走",
      "HSK": 4,
      "FrequencyRank": 1486,
      "StrokeCount": 12
    }
  },
  "验收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànshōu",
      "PrimaryDefinition": "to inspect and accept, acceptance"
    },
    "Keys": {
      "Word": 10211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "毡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "felt; rug, carpet"
    },
    "Keys": {
      "Hanzi": 1541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1541,
      "Radical": "毛",
      "FrequencyRank": 3451,
      "RadicalIndex": "82.5",
      "StrokeCount": 9
    }
  },
  "赊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shē",
      "PrimaryDefinition": "buy and sell on credit, distant"
    },
    "Keys": {
      "Hanzi": 4864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4864,
      "Radical": "貝",
      "FrequencyRank": 4501,
      "RadicalIndex": "154.7",
      "StrokeCount": 11
    }
  },
  "罗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luō",
      "PrimaryDefinition": "net for catching birds; gauze"
    },
    "Keys": {
      "Hanzi": 1108,
      "Word": 8163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "122.3",
      "Grade": 7,
      "GeneralStandard": 1108,
      "Radical": "网",
      "HSK": 7,
      "FrequencyRank": 392,
      "StrokeCount": 8
    }
  },
  "㵐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "name of a river in Hubei Province, name of a state in ancient times"
    },
    "Keys": {
      "Hanzi": 7815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7815,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "喂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "interjection to call attention"
    },
    "Keys": {
      "Hanzi": 2641,
      "Word": 2979
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "Grade": 4,
      "GeneralStandard": 2641,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 1988,
      "StrokeCount": 12
    }
  },
  "徨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "doubtful, irresolute, vacillating"
    },
    "Keys": {
      "Hanzi": 5217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5217,
      "Radical": "彳",
      "FrequencyRank": 4133,
      "RadicalIndex": "60.9",
      "StrokeCount": 12
    }
  },
  "精明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngmíng",
      "PrimaryDefinition": "astute, shrewd"
    },
    "Keys": {
      "Word": 7616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyì",
      "PrimaryDefinition": "public good/welfare"
    },
    "Keys": {
      "Word": 6775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䥽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō",
      "PrimaryDefinition": "(simplified form of 鏺) farm tool; blade in both side with a long handle used to cut weeds, to exterminate; to settle disorders"
    },
    "Keys": {
      "Hanzi": 4546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4546,
      "Radical": "",
      "RadicalIndex": "",
      "StrokeCount": 13
    }
  },
  "正是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngshì",
      "PrimaryDefinition": "(emphasizes that sth is precisely or exactly as stated), precisely, exactly, even, if, just like, in the same way as"
    },
    "Keys": {
      "Word": 1213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "配套": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi tào",
      "PrimaryDefinition": "to form a complete set, coherent"
    },
    "Keys": {
      "Word": 3764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "协作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiézuò",
      "PrimaryDefinition": "cooperation, coordination"
    },
    "Keys": {
      "Word": 9988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鄯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "district in Gansu during the Tang dynasty"
    },
    "Keys": {
      "Hanzi": 5790
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5790,
      "Radical": "邑",
      "FrequencyRank": 5199,
      "RadicalIndex": "163.12",
      "StrokeCount": 14
    }
  },
  "报销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào xiāo",
      "PrimaryDefinition": "to submit an expense account, to apply for reimbursement, to write off, to wipe out"
    },
    "Keys": {
      "Word": 5537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "fermium"
    },
    "Keys": {
      "Hanzi": 7686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7686,
      "Radical": "金",
      "FrequencyRank": 6098,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "不时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùshí",
      "PrimaryDefinition": "betweentimes, every so often, frequently, somewhile, at any time, every now and then, often"
    },
    "Keys": {
      "Word": 3281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "栊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "cage, pen; set of bars"
    },
    "Keys": {
      "Hanzi": 4143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4143,
      "Radical": "木",
      "FrequencyRank": 6357,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔ",
      "PrimaryDefinition": "mother"
    },
    "Keys": {
      "Hanzi": 345,
      "Radical": 80,
      "Word": 4879
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "80.1",
      "Grade": 6,
      "GeneralStandard": 345,
      "Radical": "毋",
      "HSK": 3,
      "FrequencyRank": 565,
      "StrokeCount": 5
    }
  },
  "觉醒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéxǐng",
      "PrimaryDefinition": "to awaken, to come to realize, awakened to the truth, the truth dawns upon one, scales fall from the eyes, to become aware"
    },
    "Keys": {
      "Word": 7721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "研究生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánjiūshēng",
      "PrimaryDefinition": "don, postgraduate, grad student, grad, graduate student, student, graduate_student"
    },
    "Keys": {
      "Word": 3068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "点名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn míng",
      "PrimaryDefinition": "roll call, to mention sb by name, (to call or praise or criticize sb) by name"
    },
    "Keys": {
      "Word": 2412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "堇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "yellow loam; clay; season; few"
    },
    "Keys": {
      "Hanzi": 4749
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4749,
      "Radical": "土",
      "FrequencyRank": 5758,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "小声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoshēng",
      "PrimaryDefinition": "in a low voice, (speak) in whispers"
    },
    "Keys": {
      "Word": 1091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "指定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐdìng",
      "PrimaryDefinition": "earmark, appointed, specify, set, domicile, appointment, ticket, fix, name, delegate, specified, express, named, consign, designate, assignment, state, depute, appoint, nominate, dictate, assign, destine, determine"
    },
    "Keys": {
      "Word": 5359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "养老金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnglǎojīn",
      "PrimaryDefinition": "pension"
    },
    "Keys": {
      "Word": 10221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6217,
      "Radical": "石",
      "FrequencyRank": 6453,
      "RadicalIndex": "112.12",
      "StrokeCount": 17
    }
  },
  "说谎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō huǎng",
      "PrimaryDefinition": "prevaricate, fable, lie, tell a lie, falsify"
    },
    "Keys": {
      "Word": 9295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "cord binding on hilt of sword"
    },
    "Keys": {
      "Hanzi": 5335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5335,
      "Radical": "糸",
      "FrequencyRank": 6180,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "医疗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīliáo",
      "PrimaryDefinition": "treat, cure"
    },
    "Keys": {
      "Word": 3082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "霭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎi",
      "PrimaryDefinition": "cloudy sky, haze; calm, peaceful"
    },
    "Keys": {
      "Hanzi": 6379
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6379,
      "Radical": "雨",
      "FrequencyRank": 4316,
      "RadicalIndex": "173.11",
      "StrokeCount": 19
    }
  },
  "限定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàndìng",
      "PrimaryDefinition": "prescribe limit to, demarcate, specify, qualify, restrict, restriction, set limit to, specialize, prescribe a limit to, prescribe, prescribe/set limit to, set a limit to, restrain, delimitate, define, terminate, ration, determine, limit, delineate, delimit"
    },
    "Keys": {
      "Word": 9902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "tungsten, wolfram"
    },
    "Keys": {
      "Hanzi": 7532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7532,
      "Radical": "金",
      "FrequencyRank": 9314,
      "RadicalIndex": "167.15",
      "StrokeCount": 13
    }
  },
  "预告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùgào",
      "PrimaryDefinition": "to forecast, to predict, advance notice"
    },
    "Keys": {
      "Word": 10514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "grain, kernel"
    },
    "Keys": {
      "Hanzi": 3100,
      "Word": 3655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.8",
      "Grade": 5,
      "GeneralStandard": 3100,
      "Radical": "頁",
      "HSK": 5,
      "FrequencyRank": 1448,
      "StrokeCount": 14
    }
  },
  "转变": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnbiàn",
      "PrimaryDefinition": "change, transform"
    },
    "Keys": {
      "Word": 2203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "再现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàixiàn",
      "PrimaryDefinition": "to recreate, to reconstruct (a historical relic)"
    },
    "Keys": {
      "Word": 10590
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "rise, ascent; peaceful; peace"
    },
    "Keys": {
      "Hanzi": 6674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6674,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "嫭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7743
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7743,
      "Radical": "女",
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "优雅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuyǎ",
      "PrimaryDefinition": "graceful, elegant, gracious"
    },
    "Keys": {
      "Word": 10451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "千万": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānwàn",
      "PrimaryDefinition": "by all means; absolutely"
    },
    "Keys": {
      "Word": 1805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "公职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzhí",
      "PrimaryDefinition": "public office/employment"
    },
    "Keys": {
      "Word": 6786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "档案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngàn",
      "PrimaryDefinition": "data file, documentation, record, archive, archives, file, dossier"
    },
    "Keys": {
      "Word": 4491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "新手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshǒu",
      "PrimaryDefinition": "new hand, novice, raw recruit"
    },
    "Keys": {
      "Word": 10032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "spare; excuse from; evade"
    },
    "Keys": {
      "Hanzi": 810,
      "Word": 8275
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.5",
      "Grade": 7,
      "GeneralStandard": 810,
      "Radical": "儿",
      "HSK": 4,
      "FrequencyRank": 755,
      "StrokeCount": 7
    }
  },
  "胜出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngchū",
      "PrimaryDefinition": "Win"
    },
    "Keys": {
      "Word": 9072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "潦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "to flood; a puddle; without care"
    },
    "Keys": {
      "Hanzi": 6000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 6000,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3739,
      "StrokeCount": 15
    }
  },
  "刳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": "cut out, dig, rip up, scoop out"
    },
    "Keys": {
      "Hanzi": 3888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3888,
      "Radical": "刀",
      "FrequencyRank": 6607,
      "RadicalIndex": "18.6",
      "StrokeCount": 8
    }
  },
  "订": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "draw up agreement; arrange"
    },
    "Keys": {
      "Hanzi": 169,
      "Word": 1412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.2",
      "Grade": 3,
      "GeneralStandard": 169,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 1176,
      "StrokeCount": 4
    }
  },
  "视觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjué",
      "PrimaryDefinition": "seeing, vision, visual sense, visual sensation, ocular, eyesight, sight, visual"
    },
    "Keys": {
      "Word": 9150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一共": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīgòng",
      "PrimaryDefinition": "altogether, in all, all told"
    },
    "Keys": {
      "Word": 1142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "失明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī míng",
      "PrimaryDefinition": "to lose one's eyesight, to become blind, blindness"
    },
    "Keys": {
      "Word": 9087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "进场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn chǎng",
      "PrimaryDefinition": "march into arena"
    },
    "Keys": {
      "Word": 7578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "翈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7157,
      "Radical": "羽",
      "FrequencyRank": 9241,
      "RadicalIndex": "124.5",
      "StrokeCount": 11
    }
  },
  "全能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánnéng",
      "PrimaryDefinition": "omnipotent, all-round, strong in every area"
    },
    "Keys": {
      "Word": 8806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "起诉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐsù",
      "PrimaryDefinition": "to sue, to bring a lawsuit against, to prosecute"
    },
    "Keys": {
      "Word": 4923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "浏览器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúlǎnqì",
      "PrimaryDefinition": "browser (software)"
    },
    "Keys": {
      "Word": 8088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "获奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò jiǎng",
      "PrimaryDefinition": "to win an award"
    },
    "Keys": {
      "Word": 2570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "垵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎn",
      "PrimaryDefinition": "cover with earth; a pit; a hole"
    },
    "Keys": {
      "Hanzi": 6780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6780,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "步行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxíng",
      "PrimaryDefinition": "hoof it, tread, ambulate, leg it, foot, pedestrianize, footslog, tramp, go on foot, hoof, step, locomote, walk"
    },
    "Keys": {
      "Word": 2283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鞒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "mud shoe, sledge for the feet"
    },
    "Keys": {
      "Hanzi": 5873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5873,
      "Radical": "革",
      "FrequencyRank": 5500,
      "RadicalIndex": "177.6",
      "StrokeCount": 15
    }
  },
  "要不是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàobushi",
      "PrimaryDefinition": "if it were not for, but for"
    },
    "Keys": {
      "Word": 10243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "努": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǔ",
      "PrimaryDefinition": "to exert, strive, make an effort; to pout"
    },
    "Keys": {
      "Hanzi": 924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.5",
      "GeneralStandard": 924,
      "Radical": "力",
      "HSK": 2,
      "FrequencyRank": 1081,
      "StrokeCount": 7
    }
  },
  "运动会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùndònghuì",
      "PrimaryDefinition": "sports meeting, sport, gymkhana, athletic meeting, fixture, gate, game, sports meet, games, sports_meeting, meet"
    },
    "Keys": {
      "Word": 3137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "涅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "blacken; black mud, slime"
    },
    "Keys": {
      "Hanzi": 4649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4649,
      "Radical": "水",
      "FrequencyRank": 2343,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "科学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēxué",
      "PrimaryDefinition": "ology, scientific, science, scientific knowledge"
    },
    "Keys": {
      "Word": 811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "溷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùn",
      "PrimaryDefinition": "privy, latrine; turbid, dirty"
    },
    "Keys": {
      "Hanzi": 5576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5576,
      "Radical": "水",
      "FrequencyRank": 6559,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "含量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánliàng",
      "PrimaryDefinition": "content, quantity contained"
    },
    "Keys": {
      "Word": 2530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "黩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "to dishonor, defile, corrupt; soiled"
    },
    "Keys": {
      "Hanzi": 6432
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6432,
      "Radical": "黑",
      "FrequencyRank": 5166,
      "RadicalIndex": "203.8",
      "StrokeCount": 20
    }
  },
  "露天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùtiān",
      "PrimaryDefinition": "in the open air, outdoors"
    },
    "Keys": {
      "Word": 8137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "先锋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānfēng",
      "PrimaryDefinition": "vanguard, pioneer"
    },
    "Keys": {
      "Word": 5159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "提": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "hold in hand; lift in hand"
    },
    "Keys": {
      "Hanzi": 2520,
      "Word": 1014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 2,
      "GeneralStandard": 2520,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 196,
      "StrokeCount": 12
    }
  },
  "傧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "entertain guests"
    },
    "Keys": {
      "Hanzi": 5213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5213,
      "Radical": "人",
      "FrequencyRank": 5738,
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "悻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "anger, vexation; angry, indignant"
    },
    "Keys": {
      "Hanzi": 4984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4984,
      "Radical": "心",
      "FrequencyRank": 3677,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "振奋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènfèn",
      "PrimaryDefinition": "to stir oneself up, to raise one's spirits, to inspire"
    },
    "Keys": {
      "Word": 10710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "癔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "hysterical"
    },
    "Keys": {
      "Hanzi": 6357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6357,
      "Radical": "疒",
      "FrequencyRank": 5942,
      "RadicalIndex": "104.13",
      "StrokeCount": 18
    }
  },
  "心急如焚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnjí-rúfén",
      "PrimaryDefinition": "Anxious"
    },
    "Keys": {
      "Word": 10006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàlóu",
      "PrimaryDefinition": "Go downstairs"
    },
    "Keys": {
      "Word": 3007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "家族": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāzú",
      "PrimaryDefinition": "tribe, covey, family line, race, household, menage, ilk, kinfolk, kin, clan, kinsfolk, phratry, kinship group, kindred, folk, people, kin group, sept, family"
    },
    "Keys": {
      "Word": 7357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōu",
      "PrimaryDefinition": "raise, lift up; tight-fisted"
    },
    "Keys": {
      "Hanzi": 617,
      "Word": 7834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 617,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 4103,
      "StrokeCount": 7
    }
  },
  "骼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gé",
      "PrimaryDefinition": "bone; skeleton; corpse"
    },
    "Keys": {
      "Hanzi": 5944
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5944,
      "Radical": "骨",
      "FrequencyRank": 3459,
      "RadicalIndex": "188.6",
      "StrokeCount": 15
    }
  },
  "卢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "cottage, hut; surname; black"
    },
    "Keys": {
      "Hanzi": 230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 230,
      "Radical": "卜",
      "FrequencyRank": 1711,
      "RadicalIndex": "25.3",
      "StrokeCount": 5
    }
  },
  "吉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "lucky, propitious, good"
    },
    "Keys": {
      "Hanzi": 356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "GeneralStandard": 356,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 856,
      "StrokeCount": 6
    }
  },
  "办理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànlǐ",
      "PrimaryDefinition": "handle, manage, attend, conduct, enact, transact, transaction"
    },
    "Keys": {
      "Word": 1273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "埤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "add, increase, attach; low fence"
    },
    "Keys": {
      "Hanzi": 4725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4725,
      "Radical": "土",
      "FrequencyRank": 6740,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "拨通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōtōng",
      "PrimaryDefinition": "Dial"
    },
    "Keys": {
      "Word": 5660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "否决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǒujué",
      "PrimaryDefinition": "to veto, to overrule"
    },
    "Keys": {
      "Word": 6597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "这样一来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèyàngyīlái",
      "PrimaryDefinition": "thus"
    },
    "Keys": {
      "Word": 10691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miè",
      "PrimaryDefinition": "disdain, disregard; slight"
    },
    "Keys": {
      "Hanzi": 3070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.11",
      "GeneralStandard": 3070,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2575,
      "StrokeCount": 14
    }
  },
  "折叠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhédié",
      "PrimaryDefinition": "to fold, collapsible, folding (bicycle, antenna, bed etc)"
    },
    "Keys": {
      "Word": 10686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "居然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūrán",
      "PrimaryDefinition": "unexpectedly, to one's surprise, go so far as to"
    },
    "Keys": {
      "Word": 3635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "谒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "visit, pay respects"
    },
    "Keys": {
      "Hanzi": 5002
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5002,
      "Radical": "言",
      "FrequencyRank": 3389,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "有劲儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒujìnr5",
      "PrimaryDefinition": "Strength"
    },
    "Keys": {
      "Word": 3117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "法制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎzhì",
      "PrimaryDefinition": "legal system and institutions, made in France"
    },
    "Keys": {
      "Word": 3449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "姥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": "maternal grandmother; midwife"
    },
    "Keys": {
      "Hanzi": 1736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "GeneralStandard": 1736,
      "Radical": "女",
      "HSK": 8,
      "FrequencyRank": 2874,
      "StrokeCount": 9
    }
  },
  "不光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùguāng",
      "PrimaryDefinition": "not the only one, not only"
    },
    "Keys": {
      "Word": 1321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "偲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāi",
      "PrimaryDefinition": "talented; urgent"
    },
    "Keys": {
      "Hanzi": 7196
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7196,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "思索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīsuǒ",
      "PrimaryDefinition": "study, puzzle, deliberate, cogitate, devise, think deeply, speculate, consider, contemplate, meditate, ponder"
    },
    "Keys": {
      "Word": 9319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "concubine"
    },
    "Keys": {
      "Hanzi": 4001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4001,
      "Radical": "女",
      "FrequencyRank": 3242,
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "傍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "by side of, beside, near, close"
    },
    "Keys": {
      "Hanzi": 2696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.1",
      "GeneralStandard": 2696,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 2558,
      "StrokeCount": 12
    }
  },
  "谵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "talkative; incoherent talk"
    },
    "Keys": {
      "Hanzi": 6014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6014,
      "Radical": "言",
      "FrequencyRank": 5872,
      "RadicalIndex": "149.13",
      "StrokeCount": 15
    }
  },
  "苦力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔlì",
      "PrimaryDefinition": "bitter work, hard toil, (loanword) coolie, unskilled Chinese laborer in colonial times"
    },
    "Keys": {
      "Word": 7859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吹了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuīle",
      "PrimaryDefinition": "failed, busted, to have not succeeded, to have died, to have parted company, to have chilled (of a relationship)"
    },
    "Keys": {
      "Word": 6021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíyìng",
      "PrimaryDefinition": "echo, react, answer, respond"
    },
    "Keys": {
      "Word": 4679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "都市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūshì",
      "PrimaryDefinition": "city, metropolis"
    },
    "Keys": {
      "Word": 4521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "签名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānmíng",
      "PrimaryDefinition": "sign one's name, autograph"
    },
    "Keys": {
      "Word": 3798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxíng",
      "PrimaryDefinition": "invisible"
    },
    "Keys": {
      "Word": 9790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "cover, hide, protect"
    },
    "Keys": {
      "Hanzi": 2400,
      "Word": 2489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.6",
      "Grade": 4,
      "GeneralStandard": 2400,
      "Radical": "皿",
      "HSK": 4,
      "FrequencyRank": 1052,
      "StrokeCount": 11
    }
  },
  "推卸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīxiè",
      "PrimaryDefinition": "to avoid (esp. responsibility), to shift (the blame), to pass the buck"
    },
    "Keys": {
      "Word": 9599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "再度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàidù",
      "PrimaryDefinition": "once again, anew, eftsoons, second time, again, a second time, once more/again, once more"
    },
    "Keys": {
      "Word": 10588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "beautiful jade, gem"
    },
    "Keys": {
      "Hanzi": 2502
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2502,
      "Radical": "玉",
      "FrequencyRank": 2400,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "有没有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuméiyǒu",
      "PrimaryDefinition": "have or not have"
    },
    "Keys": {
      "Word": 5276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "往后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnghòu",
      "PrimaryDefinition": "from now on, in the future, time to come"
    },
    "Keys": {
      "Word": 5131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "退": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì",
      "PrimaryDefinition": "step back, retreat, withdraw"
    },
    "Keys": {
      "Hanzi": 1720,
      "Word": 1992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 3,
      "GeneralStandard": 1720,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 723,
      "StrokeCount": 9
    }
  },
  "灌输": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guànshū",
      "PrimaryDefinition": "to imbue with, to inculcate, to instill into, to teach, to impart, to channel water to another place"
    },
    "Keys": {
      "Word": 6895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "贡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòng",
      "PrimaryDefinition": "offer tribute; tribute, gifts"
    },
    "Keys": {
      "Hanzi": 627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.3",
      "GeneralStandard": 627,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 1548,
      "StrokeCount": 7
    }
  },
  "帝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "supreme ruler, emperor; god"
    },
    "Keys": {
      "Hanzi": 1633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.6",
      "GeneralStandard": 1633,
      "Radical": "巾",
      "HSK": 6,
      "FrequencyRank": 612,
      "StrokeCount": 9
    }
  },
  "华人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huárén",
      "PrimaryDefinition": "Chinese, Ch. people"
    },
    "Keys": {
      "Word": 1557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "电信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànxìn",
      "PrimaryDefinition": "telegraphy, telecommunications, telecommunication, telecom"
    },
    "Keys": {
      "Word": 6276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pà",
      "PrimaryDefinition": "turban, kerchief, veil; wrap"
    },
    "Keys": {
      "Hanzi": 1110
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.5",
      "GeneralStandard": 1110,
      "Radical": "巾",
      "HSK": 8,
      "FrequencyRank": 1519,
      "StrokeCount": 8
    }
  },
  "虚弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūruò",
      "PrimaryDefinition": "weak, in poor health"
    },
    "Keys": {
      "Word": 10094
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎo",
      "PrimaryDefinition": "agate; cornelian"
    },
    "Keys": {
      "Hanzi": 2829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2829,
      "Radical": "玉",
      "FrequencyRank": 3428,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "瞻仰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhānyǎng",
      "PrimaryDefinition": "to revere, to admire"
    },
    "Keys": {
      "Word": 10636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "final particle"
    },
    "Keys": {
      "Hanzi": 4224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4224,
      "Radical": "口",
      "FrequencyRank": 2744,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "一事无成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshì-wúchéng",
      "PrimaryDefinition": "to have achieved nothing, to be a total failure, to get nowhere"
    },
    "Keys": {
      "Word": 10293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宴会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànhuì",
      "PrimaryDefinition": "banquet, feast, dinner party, CL:席[xi2],個|个[ge4]"
    },
    "Keys": {
      "Word": 5215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嫁妆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàzhuang",
      "PrimaryDefinition": "dowry"
    },
    "Keys": {
      "Word": 7372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "普遍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔbiàn",
      "PrimaryDefinition": "universal, general, widespread, common"
    },
    "Keys": {
      "Word": 1797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "叩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòu",
      "PrimaryDefinition": "knock, ask; kowtow, bow"
    },
    "Keys": {
      "Hanzi": 254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 254,
      "Radical": "口",
      "FrequencyRank": 2898,
      "RadicalIndex": "30.2",
      "StrokeCount": 5
    }
  },
  "斥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "to scold, upbraid, accuse, reproach"
    },
    "Keys": {
      "Hanzi": 278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "69.1",
      "GeneralStandard": 278,
      "Radical": "斤",
      "HSK": 8,
      "FrequencyRank": 1857,
      "StrokeCount": 5
    }
  },
  "毅力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìlì",
      "PrimaryDefinition": "perseverance, willpower"
    },
    "Keys": {
      "Word": 10380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎo",
      "PrimaryDefinition": "few, less, inadequate"
    },
    "Keys": {
      "Hanzi": 110,
      "Word": 320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "42.1",
      "Grade": 1,
      "GeneralStandard": 110,
      "Radical": "小",
      "HSK": 1,
      "FrequencyRank": 233,
      "StrokeCount": 4
    }
  },
  "破案": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pò àn",
      "PrimaryDefinition": "to solve a case, shabby old table"
    },
    "Keys": {
      "Word": 8568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "境遇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngyù",
      "PrimaryDefinition": "circumstance"
    },
    "Keys": {
      "Word": 7655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1333,
      "Radical": "厶",
      "FrequencyRank": 4396,
      "RadicalIndex": "28.6",
      "StrokeCount": 8
    }
  },
  "郊区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoqū",
      "PrimaryDefinition": "suburban district, outskirts"
    },
    "Keys": {
      "Word": 3614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "房租": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángzū",
      "PrimaryDefinition": "rent for a room or house"
    },
    "Keys": {
      "Word": 1451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "诀窍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéqiào",
      "PrimaryDefinition": "secret, trick, knack, key"
    },
    "Keys": {
      "Word": 7719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "权力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánlì",
      "PrimaryDefinition": "influence, rod, authoritativeness, clutches, whip_hand, dominance, say-so, reins, force, emirate, might, pretension, station, commission, grasp, leverage, danger, authority, place, power, arm, disposal, puissance"
    },
    "Keys": {
      "Word": 4961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "酒水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔshuǐ",
      "PrimaryDefinition": "beverage, a drink"
    },
    "Keys": {
      "Word": 4757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "亚军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàjūn",
      "PrimaryDefinition": "second place (in a sports contest), runner-up"
    },
    "Keys": {
      "Word": 4086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瓦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎ",
      "PrimaryDefinition": "tile"
    },
    "Keys": {
      "Hanzi": 108,
      "Radical": 98,
      "Word": 9630
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "98",
      "Grade": 7,
      "GeneralStandard": 108,
      "Radical": "瓦",
      "HSK": 7,
      "FrequencyRank": 1195,
      "StrokeCount": 4
    }
  },
  "商品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngpǐn",
      "PrimaryDefinition": "article, product, merchandise, commodity, wares, ware, goods"
    },
    "Keys": {
      "Word": 1867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "状况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngkuàng",
      "PrimaryDefinition": "repair, background, picture, state (of affairs), state of affairs, status, gear, scene, setting, scope, condition, state, circumstance, trimness, context, showing, trim, factor, condition/state (of affairs), situation, shoes, consideration, place"
    },
    "Keys": {
      "Word": 2204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "造成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàochéng",
      "PrimaryDefinition": "make_for, give rise to, create, set_up, strike, creation, cause, contribute, bring_about, procure, give, bring about, cut_out, necessitate, shape, present, bring_forth, generate, yield, make, breed"
    },
    "Keys": {
      "Word": 2130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鳣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "sturgeon"
    },
    "Keys": {
      "Hanzi": 8084
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8084,
      "Radical": "魚",
      "FrequencyRank": 7781,
      "RadicalIndex": "195.13",
      "StrokeCount": 21
    }
  },
  "红灯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngdēng",
      "PrimaryDefinition": "red light"
    },
    "Keys": {
      "Word": 7078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暗杀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànshā",
      "PrimaryDefinition": "to assassinate"
    },
    "Keys": {
      "Word": 5453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "潍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "county in Shandong province"
    },
    "Keys": {
      "Hanzi": 5812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5812,
      "Radical": "水",
      "FrequencyRank": 4775,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "攻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "attack, assault; criticize"
    },
    "Keys": {
      "Hanzi": 630,
      "Word": 6791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.3",
      "Grade": 7,
      "GeneralStandard": 630,
      "Radical": "攴",
      "HSK": 6,
      "FrequencyRank": 588,
      "StrokeCount": 7
    }
  },
  "攀升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pānshēng",
      "PrimaryDefinition": "to clamber up, (of prices etc) to rise"
    },
    "Keys": {
      "Word": 8463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诸多": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūduō",
      "PrimaryDefinition": "det.: a good deal, a lot of"
    },
    "Keys": {
      "Word": 10846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "首府": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒufǔ",
      "PrimaryDefinition": "capital city of an autonomous region"
    },
    "Keys": {
      "Word": 9188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "wet"
    },
    "Keys": {
      "Hanzi": 5575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5575,
      "Radical": "水",
      "FrequencyRank": 7229,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "搌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎn",
      "PrimaryDefinition": "to wipe tears; to bind"
    },
    "Keys": {
      "Hanzi": 7483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7483,
      "Radical": "手",
      "FrequencyRank": 8301,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "浲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7065,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "跳高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàogāo",
      "PrimaryDefinition": "high jump (athletics)"
    },
    "Keys": {
      "Word": 1964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "乖巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāiqiǎo",
      "PrimaryDefinition": "clever (child), smart, lovable, cute"
    },
    "Keys": {
      "Word": 6859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陈列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénliè",
      "PrimaryDefinition": "display, exhibit"
    },
    "Keys": {
      "Word": 5858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "flushed with drink, drunk"
    },
    "Keys": {
      "Hanzi": 5280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5280,
      "Radical": "水",
      "FrequencyRank": 4504,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "有所不同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒusuǒbùtóng",
      "PrimaryDefinition": "to differ to some extent (idiom)"
    },
    "Keys": {
      "Word": 10478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "happiness; congratulations"
    },
    "Keys": {
      "Hanzi": 6186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6186,
      "Radical": "示",
      "FrequencyRank": 2085,
      "RadicalIndex": "113.12",
      "StrokeCount": 16
    }
  },
  "公约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyuē",
      "PrimaryDefinition": "convention, pact, joint pledge"
    },
    "Keys": {
      "Word": 6780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全世界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánshìjiè",
      "PrimaryDefinition": "worldwide, entire world"
    },
    "Keys": {
      "Word": 3825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "监控": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānkòng",
      "PrimaryDefinition": "to monitor"
    },
    "Keys": {
      "Word": 7392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搁浅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē qiǎn",
      "PrimaryDefinition": "to be stranded (of ship), to run aground, fig. to run into difficulties and stop"
    },
    "Keys": {
      "Word": 6723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "receptacle, vessel; instrument"
    },
    "Keys": {
      "Hanzi": 3348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.13",
      "GeneralStandard": 3348,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 441,
      "StrokeCount": 16
    }
  },
  "佳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "good, auspicious; beautiful; delightful"
    },
    "Keys": {
      "Hanzi": 1135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1135,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1583,
      "StrokeCount": 8
    }
  },
  "畔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "boundary path dividing fields"
    },
    "Keys": {
      "Hanzi": 1899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.5",
      "GeneralStandard": 1899,
      "Radical": "田",
      "HSK": 9,
      "FrequencyRank": 2833,
      "StrokeCount": 10
    }
  },
  "彼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "that, there, those"
    },
    "Keys": {
      "Hanzi": 1162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.5",
      "GeneralStandard": 1162,
      "Radical": "彳",
      "HSK": 5,
      "FrequencyRank": 1256,
      "StrokeCount": 8
    }
  },
  "丰盛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngshèng",
      "PrimaryDefinition": "rich, sumptuous"
    },
    "Keys": {
      "Word": 6563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "威胁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēixié",
      "PrimaryDefinition": "threat"
    },
    "Keys": {
      "Word": 5137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "管家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnjiā",
      "PrimaryDefinition": "housekeeper, butler, manage one's household"
    },
    "Keys": {
      "Word": 6881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèng",
      "PrimaryDefinition": "pump"
    },
    "Keys": {
      "Hanzi": 1452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1452,
      "Radical": "水",
      "FrequencyRank": 3800,
      "RadicalIndex": "85.5",
      "StrokeCount": 9
    }
  },
  "绦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "silk cord, ribbon"
    },
    "Keys": {
      "Hanzi": 4704
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4704,
      "Radical": "糸",
      "FrequencyRank": 4908,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "痨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "consumption; tuberculosis"
    },
    "Keys": {
      "Hanzi": 5254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5254,
      "Radical": "疒",
      "FrequencyRank": 4886,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "酸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suān",
      "PrimaryDefinition": "tart, sour; acid; stiff; spoiled"
    },
    "Keys": {
      "Hanzi": 3089,
      "Word": 2907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.7",
      "Grade": 4,
      "GeneralStandard": 3089,
      "Radical": "酉",
      "HSK": 4,
      "FrequencyRank": 1456,
      "StrokeCount": 14
    }
  },
  "驷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "team of four horses; horses"
    },
    "Keys": {
      "Hanzi": 4077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4077,
      "Radical": "馬",
      "FrequencyRank": 5344,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "改日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎirì",
      "PrimaryDefinition": "another day, some other day"
    },
    "Keys": {
      "Word": 6656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慢慢来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànmànlái",
      "PrimaryDefinition": "take your time, take it easy"
    },
    "Keys": {
      "Word": 8203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "眙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "to gaze at"
    },
    "Keys": {
      "Hanzi": 4497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4497,
      "Radical": "目",
      "FrequencyRank": 6130,
      "RadicalIndex": "109.5",
      "StrokeCount": 10
    }
  },
  "预报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùbào",
      "PrimaryDefinition": "forecast"
    },
    "Keys": {
      "Word": 2118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōu",
      "PrimaryDefinition": "gather together, collect; harvest"
    },
    "Keys": {
      "Hanzi": 573,
      "Word": 990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.2",
      "Grade": 2,
      "GeneralStandard": 573,
      "Radical": "攴",
      "HSK": 2,
      "FrequencyRank": 351,
      "StrokeCount": 6
    }
  },
  "楔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiē",
      "PrimaryDefinition": "wedge; gatepost; foreword"
    },
    "Keys": {
      "Hanzi": 5392
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5392,
      "Radical": "木",
      "FrequencyRank": 3846,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "负责人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzérén",
      "PrimaryDefinition": "sponsor, point man, leading cadre, person in charge, undertaker, keeper"
    },
    "Keys": {
      "Word": 3474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "秋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "autumn, fall; year"
    },
    "Keys": {
      "Hanzi": 1550
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.4",
      "GeneralStandard": 1550,
      "Radical": "禾",
      "HSK": 2,
      "FrequencyRank": 1151,
      "StrokeCount": 9
    }
  },
  "雇员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùyuán",
      "PrimaryDefinition": "employee"
    },
    "Keys": {
      "Word": 6848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "世代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìdài",
      "PrimaryDefinition": "generation, period, epoch"
    },
    "Keys": {
      "Word": 9130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "majestic, solemn, reverent; calm"
    },
    "Keys": {
      "Hanzi": 3356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.11",
      "GeneralStandard": 3356,
      "Radical": "禾",
      "HSK": 7,
      "FrequencyRank": 1683,
      "StrokeCount": 16
    }
  },
  "蜜蜂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìfēng",
      "PrimaryDefinition": "bee, honeybee, CL:隻|只[zhi1],群[qun2]"
    },
    "Keys": {
      "Word": 8270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高速公路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāosùgōnglù",
      "PrimaryDefinition": "expressway, highway, freeway"
    },
    "Keys": {
      "Word": 1482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "利息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìxī",
      "PrimaryDefinition": "premium, usury, accrual, interest"
    },
    "Keys": {
      "Word": 2697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "婚礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūnlǐ",
      "PrimaryDefinition": "wedding ceremony, wedding, CL:場|场[chang3]"
    },
    "Keys": {
      "Word": 2562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "别具匠心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biéjù-jiàngxīn",
      "PrimaryDefinition": "Unique"
    },
    "Keys": {
      "Word": 5636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "catalpa ovata"
    },
    "Keys": {
      "Hanzi": 4776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4776,
      "Radical": "木",
      "FrequencyRank": 3750,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "推出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīchū",
      "PrimaryDefinition": "present to public"
    },
    "Keys": {
      "Word": 5110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "掊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "póu",
      "PrimaryDefinition": "to extract; injure"
    },
    "Keys": {
      "Hanzi": 4735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4735,
      "Radical": "手",
      "FrequencyRank": 6609,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "垄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒng",
      "PrimaryDefinition": "grave, mound; ridge in field"
    },
    "Keys": {
      "Hanzi": 1055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "212.3",
      "GeneralStandard": 1055,
      "Radical": "龍",
      "HSK": 9,
      "FrequencyRank": 2336,
      "StrokeCount": 8
    }
  },
  "轵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "end of axle; divergent"
    },
    "Keys": {
      "Hanzi": 6815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6815,
      "Radical": "車",
      "FrequencyRank": 7264,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "概念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàiniàn",
      "PrimaryDefinition": "estimate, conception, view, intention, image, estimation, idea, notion, concept, construct, paradox, approximation, impression"
    },
    "Keys": {
      "Word": 1471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "操作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāozuò",
      "PrimaryDefinition": "operate, manipulate"
    },
    "Keys": {
      "Word": 2293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "号称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàochēng",
      "PrimaryDefinition": "be known as, claim to be"
    },
    "Keys": {
      "Word": 7015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "背包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēibāo",
      "PrimaryDefinition": "knapsack, rucksack, infantry pack, field pack, blanket roll, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "藿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "lophanthus rugosus, betony"
    },
    "Keys": {
      "Hanzi": 6371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6371,
      "Radical": "艸",
      "FrequencyRank": 5174,
      "RadicalIndex": "140.16",
      "StrokeCount": 19
    }
  },
  "众人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngrén",
      "PrimaryDefinition": "everybody"
    },
    "Keys": {
      "Word": 10829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "footbridge; toll, levy; monopoly"
    },
    "Keys": {
      "Hanzi": 5670
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5670,
      "Radical": "木",
      "FrequencyRank": 4380,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "褰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6182,
      "Radical": "衣",
      "FrequencyRank": 5964,
      "RadicalIndex": "145.1",
      "StrokeCount": 16
    }
  },
  "独家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dújiā",
      "PrimaryDefinition": "exclusive"
    },
    "Keys": {
      "Word": 6358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垫子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànzi",
      "PrimaryDefinition": "cushion, mat, pad"
    },
    "Keys": {
      "Word": 6280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": "team, group; army unit"
    },
    "Keys": {
      "Hanzi": 180,
      "Word": 636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.2",
      "Grade": 2,
      "GeneralStandard": 180,
      "Radical": "阜",
      "HSK": 2,
      "FrequencyRank": 268,
      "StrokeCount": 4
    }
  },
  "协定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiédìng",
      "PrimaryDefinition": "agreement, accord, pact"
    },
    "Keys": {
      "Word": 9984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "展现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnxiàn",
      "PrimaryDefinition": "unfold before one's eyes"
    },
    "Keys": {
      "Word": 4189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "所有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒyǒu",
      "PrimaryDefinition": "det.: all"
    },
    "Keys": {
      "Word": 1004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鼻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bí",
      "PrimaryDefinition": "nose"
    },
    "Keys": {
      "Radical": 209,
      "Hanzi": 3128
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "209",
      "GeneralStandard": 3128,
      "Radical": "鼻",
      "HSK": 5,
      "FrequencyRank": 1335,
      "StrokeCount": 14
    }
  },
  "轰炸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōngzhà",
      "PrimaryDefinition": "bomb"
    },
    "Keys": {
      "Word": 7072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "编号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānhào",
      "PrimaryDefinition": "to number, numbering, serial number"
    },
    "Keys": {
      "Word": 5599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘉宾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiābīn",
      "PrimaryDefinition": "esteemed guest, honored guest, guest (on a show)"
    },
    "Keys": {
      "Word": 4705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一贯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīguàn",
      "PrimaryDefinition": "consistent, persistent, all along"
    },
    "Keys": {
      "Word": 5234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "only; yes"
    },
    "Keys": {
      "Hanzi": 2288,
      "Word": 9700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "Grade": 7,
      "GeneralStandard": 2288,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1094,
      "StrokeCount": 11
    }
  },
  "哎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "interjection of surprise"
    },
    "Keys": {
      "Hanzi": 1082,
      "Word": 5423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 7,
      "GeneralStandard": 1082,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2294,
      "StrokeCount": 8
    }
  },
  "炸药": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàyào",
      "PrimaryDefinition": "explosive (material)"
    },
    "Keys": {
      "Word": 5317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "真心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnxīn",
      "PrimaryDefinition": "sincere, genuine, true"
    },
    "Keys": {
      "Word": 10701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēdài",
      "PrimaryDefinition": "receive (guests), serve (customers)"
    },
    "Keys": {
      "Word": 1615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "万一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wànyī",
      "PrimaryDefinition": "conj.: just in case, if by any chance"
    },
    "Keys": {
      "Word": 2964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "咡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èr",
      "PrimaryDefinition": "side of mouth"
    },
    "Keys": {
      "Hanzi": 6823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6823,
      "Radical": "口",
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "乘客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngkè",
      "PrimaryDefinition": "fare, rider, passenger"
    },
    "Keys": {
      "Word": 3333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "拓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "expand; open up; support or push"
    },
    "Keys": {
      "Hanzi": 957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 957,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 2238,
      "StrokeCount": 8
    }
  },
  "毵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sān",
      "PrimaryDefinition": "long feathers; scraggy"
    },
    "Keys": {
      "Hanzi": 7454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7454,
      "Radical": "毛",
      "FrequencyRank": 6554,
      "RadicalIndex": "82.8",
      "StrokeCount": 12
    }
  },
  "钊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo",
      "PrimaryDefinition": "endeavor, strive; encourage; cut"
    },
    "Keys": {
      "Hanzi": 3721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3721,
      "Radical": "金",
      "FrequencyRank": 3334,
      "RadicalIndex": "167.2",
      "StrokeCount": 7
    }
  },
  "拌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "mix"
    },
    "Keys": {
      "Hanzi": 984,
      "Word": 5506
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 984,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3570,
      "StrokeCount": 8
    }
  },
  "吕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "surname; a musical note"
    },
    "Keys": {
      "Hanzi": 426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "GeneralStandard": 426,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 1716,
      "StrokeCount": 6
    }
  },
  "军队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnduì",
      "PrimaryDefinition": "military personnel, troops, armed forces, armament, military, soldiery, force, Sabaoth, array, army troops, military unit, host, military force, cohort, battalion, legion, troop, regular army, army, armed_forces, rank"
    },
    "Keys": {
      "Word": 4777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "叫好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào hǎo",
      "PrimaryDefinition": "to applaud, to cheer"
    },
    "Keys": {
      "Word": 7488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多年来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōniánlái",
      "PrimaryDefinition": "for the past many years"
    },
    "Keys": {
      "Word": 6395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "relax"
    },
    "Keys": {
      "Hanzi": 7440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7440,
      "Radical": "心",
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "夺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duó",
      "PrimaryDefinition": "take by force, rob, snatch"
    },
    "Keys": {
      "Hanzi": 396,
      "Word": 4531
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.3",
      "Grade": 6,
      "GeneralStandard": 396,
      "Radical": "大",
      "HSK": 6,
      "FrequencyRank": 1199,
      "StrokeCount": 6
    }
  },
  "嗵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōng",
      "PrimaryDefinition": "used for sound"
    },
    "Keys": {
      "Hanzi": 5476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5476,
      "Radical": "口",
      "FrequencyRank": 4731,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "后果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuguǒ",
      "PrimaryDefinition": "aftereffect, aftermath, consequent, wake, backwash, sequel, consequence, subsequence"
    },
    "Keys": {
      "Word": 1550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "慵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "indolent, easy-going, lazy"
    },
    "Keys": {
      "Hanzi": 5813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5813,
      "Radical": "心",
      "FrequencyRank": 4772,
      "RadicalIndex": "61.11",
      "StrokeCount": 14
    }
  },
  "面对面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànduìmiàn",
      "PrimaryDefinition": "face to face"
    },
    "Keys": {
      "Word": 4860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "洎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "until; till; soup; to soak"
    },
    "Keys": {
      "Hanzi": 4340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4340,
      "Radical": "水",
      "FrequencyRank": 6501,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "镶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "insert, inlay, set, mount; fill"
    },
    "Keys": {
      "Hanzi": 3497,
      "Word": 9937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.17",
      "Grade": 7,
      "GeneralStandard": 3497,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 2974,
      "StrokeCount": 22
    }
  },
  "茨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "thatching; caltrop, Tribulus terrestris"
    },
    "Keys": {
      "Hanzi": 4128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4128,
      "Radical": "艸",
      "FrequencyRank": 1936,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "靶子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎzi",
      "PrimaryDefinition": "target"
    },
    "Keys": {
      "Word": 5466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "burning-hot, intense; to burn, blaze; splendid, illustrious"
    },
    "Keys": {
      "Hanzi": 4326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4326,
      "Radical": "火",
      "FrequencyRank": 3277,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "吸纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnà",
      "PrimaryDefinition": "to take in, to absorb, to admit, to accept"
    },
    "Keys": {
      "Word": 9816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zè",
      "PrimaryDefinition": "slanting, oblique; oblique tones"
    },
    "Keys": {
      "Hanzi": 3513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3513,
      "Radical": "人",
      "FrequencyRank": 4282,
      "RadicalIndex": "9.2",
      "StrokeCount": 4
    }
  },
  "之外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīwài",
      "PrimaryDefinition": "beyond, apart from, excluding"
    },
    "Keys": {
      "Word": 4216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "看似": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànsì",
      "PrimaryDefinition": "looks like"
    },
    "Keys": {
      "Word": 7771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7164,
      "Radical": "虫",
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔ",
      "PrimaryDefinition": "bitter; hardship, suffering"
    },
    "Keys": {
      "Hanzi": 999,
      "Word": 2666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "Grade": 4,
      "GeneralStandard": 999,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 634,
      "StrokeCount": 8
    }
  },
  "总计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngjì",
      "PrimaryDefinition": "(grand) total"
    },
    "Keys": {
      "Word": 10985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怠工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài gōng",
      "PrimaryDefinition": "to slacken off in one's work, to go slow (as a form of strike)"
    },
    "Keys": {
      "Word": 6152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "to strain spirits; river in Guangxi; abundant; bright"
    },
    "Keys": {
      "Hanzi": 7437
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7437,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "法庭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎtíng",
      "PrimaryDefinition": "judicature, areopagy, court, courtroom, tribunal, banc, forum, gate, banco, lockup, lawcourt, bailey"
    },
    "Keys": {
      "Word": 4542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "稑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "rice"
    },
    "Keys": {
      "Hanzi": 7538
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7538,
      "Radical": "禾",
      "FrequencyRank": 9224,
      "RadicalIndex": "115.8",
      "StrokeCount": 13
    }
  },
  "不由得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyóude",
      "PrimaryDefinition": "can't help, cannot but"
    },
    "Keys": {
      "Word": 5721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沙漠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāmò",
      "PrimaryDefinition": "desert"
    },
    "Keys": {
      "Word": 3858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "櫆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "Polaris, the north star"
    },
    "Keys": {
      "Hanzi": 7926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7926,
      "Radical": "木",
      "RadicalIndex": "75.14",
      "StrokeCount": 17
    }
  },
  "澄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "purify water by allowing sediment to settle; clear, pure"
    },
    "Keys": {
      "Hanzi": 3299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.12",
      "GeneralStandard": 3299,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2756,
      "StrokeCount": 15
    }
  },
  "划": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": "to row or paddle boat; to scratch"
    },
    "Keys": {
      "Hanzi": 407,
      "Word": 2554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "Grade": 4,
      "GeneralStandard": 407,
      "Radical": "刀",
      "HSK": 2,
      "FrequencyRank": 522,
      "StrokeCount": 6
    }
  },
  "派遣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàiqiǎn",
      "PrimaryDefinition": "to send (on a mission), to dispatch"
    },
    "Keys": {
      "Word": 8462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "fly; gallop; run; prance; rise"
    },
    "Keys": {
      "Hanzi": 2975,
      "Word": 9452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.9",
      "Grade": 7,
      "GeneralStandard": 2975,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 1635,
      "StrokeCount": 13
    }
  },
  "早晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎowǎn",
      "PrimaryDefinition": "morning and evening, sooner or later"
    },
    "Keys": {
      "Word": 5309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "字母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìmǔ",
      "PrimaryDefinition": "letter (of the alphabet), CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "日程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìchéng",
      "PrimaryDefinition": "schedule, itinerary, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 8870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中华民族": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zhōnghuámínzú",
      "PrimaryDefinition": "the Chinese nation, Ch. people"
    },
    "Keys": {
      "Word": 2181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "单独": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāndú",
      "PrimaryDefinition": "tete-a-tete, apart, unaccompanied, individually, singly, solely, alone, on one's own"
    },
    "Keys": {
      "Word": 2390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "当之无愧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngzhī-wúkuì",
      "PrimaryDefinition": "fully deserving, without any reservations (idiom), entirely worthy (of a title, honor etc)"
    },
    "Keys": {
      "Word": 6181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": "lutetium"
    },
    "Keys": {
      "Hanzi": 7941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7941,
      "Radical": "金",
      "FrequencyRank": 7854,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "成千上万": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngqiān-shàngwàn",
      "PrimaryDefinition": "lit. by the thousands and tens of thousands (idiom), untold numbers, innumerable, thousands upon thousands"
    },
    "Keys": {
      "Word": 5870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "集邮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí yóu",
      "PrimaryDefinition": "stamp collecting, philately"
    },
    "Keys": {
      "Word": 7310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嵋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "Omei mountain in Sichuan"
    },
    "Keys": {
      "Hanzi": 5176
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5176,
      "Radical": "山",
      "FrequencyRank": 3796,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "醴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "sweet wine; sweet spring"
    },
    "Keys": {
      "Hanzi": 6424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6424,
      "Radical": "酉",
      "FrequencyRank": 4877,
      "RadicalIndex": "164.13",
      "StrokeCount": 20
    }
  },
  "难得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nándé",
      "PrimaryDefinition": "hard to come by, rare, seldom"
    },
    "Keys": {
      "Word": 3741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "黪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎn",
      "PrimaryDefinition": "grey black"
    },
    "Keys": {
      "Hanzi": 6434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6434,
      "Radical": "黑",
      "FrequencyRank": 7323,
      "RadicalIndex": "203.8",
      "StrokeCount": 20
    }
  },
  "时髦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shímáo",
      "PrimaryDefinition": "in vogue, fashionable"
    },
    "Keys": {
      "Word": 9108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "large waterfowl with naked head; Garrulus glandarius"
    },
    "Keys": {
      "Hanzi": 7689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7689,
      "Radical": "鳥",
      "FrequencyRank": 9206,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "汗水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hànshuǐ",
      "PrimaryDefinition": "sweat, perspiration"
    },
    "Keys": {
      "Word": 6986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棍子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùnzi",
      "PrimaryDefinition": "stick, rod"
    },
    "Keys": {
      "Word": 6928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "危急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēijí",
      "PrimaryDefinition": "critical, desperate (situation)"
    },
    "Keys": {
      "Word": 9685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "raise voice, yell out loud, shout; stammer"
    },
    "Keys": {
      "Hanzi": 738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 738,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2899,
      "StrokeCount": 7
    }
  },
  "梦见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèngjiàn",
      "PrimaryDefinition": "to dream about (sth or sb), to see in a dream"
    },
    "Keys": {
      "Word": 2732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "独自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúzì",
      "PrimaryDefinition": "unaccompanied, solely, by oneself, alone"
    },
    "Keys": {
      "Word": 2424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "形态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngtài",
      "PrimaryDefinition": "configuration, figuration, pattern, conformation, form, modality, appearance, contour, shape, morphology, morph"
    },
    "Keys": {
      "Word": 4068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "搏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "seize; spring upon; strike"
    },
    "Keys": {
      "Hanzi": 2835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "GeneralStandard": 2835,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2372,
      "StrokeCount": 13
    }
  },
  "温暖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnnuǎn",
      "PrimaryDefinition": "warm"
    },
    "Keys": {
      "Word": 2010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鳀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "anchovy"
    },
    "Keys": {
      "Hanzi": 6278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6278,
      "Radical": "魚",
      "FrequencyRank": 6931,
      "RadicalIndex": "195.9",
      "StrokeCount": 17
    }
  },
  "原型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánxíng",
      "PrimaryDefinition": "model, prototype, archetype"
    },
    "Keys": {
      "Word": 10543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "handful; to grasp, seize, pick up with fingers; (Cant.) to take, carry"
    },
    "Keys": {
      "Hanzi": 5062
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5062,
      "Radical": "手",
      "FrequencyRank": 6890,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "哥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "elder brother"
    },
    "Keys": {
      "Hanzi": 1852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1852,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 804,
      "StrokeCount": 10
    }
  },
  "硕士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuòshì",
      "PrimaryDefinition": "Master (of Arts), M.A., eminent scholar"
    },
    "Keys": {
      "Word": 3938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "美德": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měidé",
      "PrimaryDefinition": "good, virtue, merit, goodness, virture, moral excellence, excellency"
    },
    "Keys": {
      "Word": 8232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhài",
      "PrimaryDefinition": "a wasting disease"
    },
    "Keys": {
      "Hanzi": 6157
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6157,
      "Radical": "疒",
      "FrequencyRank": 6821,
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "自主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìzhǔ",
      "PrimaryDefinition": "be one's own master, take initiative"
    },
    "Keys": {
      "Word": 2217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "恤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "show pity; relieve, help"
    },
    "Keys": {
      "Hanzi": 1689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1689,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 3214,
      "StrokeCount": 9
    }
  },
  "宏伟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngwěi",
      "PrimaryDefinition": "grand, imposing, magnificent"
    },
    "Keys": {
      "Word": 7085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "imperial signet, royal signet"
    },
    "Keys": {
      "Hanzi": 4599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4599,
      "Radical": "玉",
      "FrequencyRank": 3758,
      "RadicalIndex": "96.5",
      "StrokeCount": 10
    }
  },
  "一模一样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīmú-yīyàng",
      "PrimaryDefinition": "exactly the same (idiom), carbon copy, also pr. [yi1 mo2 yi1 yang4]"
    },
    "Keys": {
      "Word": 5240
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "规律": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīlǜ",
      "PrimaryDefinition": "law, regular pattern"
    },
    "Keys": {
      "Word": 2522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn",
      "PrimaryDefinition": "public building"
    },
    "Keys": {
      "Hanzi": 2373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.8",
      "GeneralStandard": 2373,
      "Radical": "食",
      "HSK": 1,
      "FrequencyRank": 1011,
      "StrokeCount": 11
    }
  },
  "转载": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnzǎi",
      "PrimaryDefinition": "to forward (a shipment), to reprint sth published elsewhere, Taiwan pr. [zhuan3 zai4]"
    },
    "Keys": {
      "Word": 10895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厚道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòudao",
      "PrimaryDefinition": "kind and honest, generous, sincere"
    },
    "Keys": {
      "Word": 7103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7445
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7445,
      "Radical": "衣",
      "FrequencyRank": 5885,
      "RadicalIndex": "145.7",
      "StrokeCount": 12
    }
  },
  "集资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jízī",
      "PrimaryDefinition": "to raise money, to accumulate funds"
    },
    "Keys": {
      "Word": 7315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "osmium"
    },
    "Keys": {
      "Hanzi": 5184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5184,
      "Radical": "金",
      "FrequencyRank": 6145,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "典礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnlǐ",
      "PrimaryDefinition": "religious rite, celebration, ceremony, ceremonial occasion, exercise, observance, function, ceremonial, ritual, order, rite"
    },
    "Keys": {
      "Word": 3419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蜡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "là",
      "PrimaryDefinition": "wax; candle; waxy, glazed; maggot; as a non-simplified form sometimes used as an equivalent to 䄍, meaning imperial harvest"
    },
    "Keys": {
      "Hanzi": 3106,
      "Word": 7912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.8",
      "Grade": 7,
      "GeneralStandard": 3106,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2593,
      "StrokeCount": 14
    }
  },
  "守护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuhù",
      "PrimaryDefinition": "to guard, to protect"
    },
    "Keys": {
      "Word": 9187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6870,
      "Radical": "肉",
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "谭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 3177
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3177,
      "Radical": "言",
      "FrequencyRank": 2145,
      "RadicalIndex": "149.12",
      "StrokeCount": 14
    }
  },
  "婉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "amiable, congenial; restrained"
    },
    "Keys": {
      "Hanzi": 2483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.8",
      "GeneralStandard": 2483,
      "Radical": "女",
      "HSK": 8,
      "FrequencyRank": 2798,
      "StrokeCount": 11
    }
  },
  "巨大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùdà",
      "PrimaryDefinition": "gigantic, gargantuan, huge"
    },
    "Keys": {
      "Word": 2648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蹴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "kick; tread on; leap; solemn"
    },
    "Keys": {
      "Hanzi": 6387
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6387,
      "Radical": "足",
      "FrequencyRank": 4948,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "班级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bānjí",
      "PrimaryDefinition": "classes and grades in school"
    },
    "Keys": {
      "Word": 1271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "有助于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuzhùyú",
      "PrimaryDefinition": "helpful to, lead, advantage, redound, be conducive, conduce, be conducive to, conduce to, sell, contribute to, subserve, avail, contribute"
    },
    "Keys": {
      "Word": 10488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "glass"
    },
    "Keys": {
      "Hanzi": 3051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.11",
      "GeneralStandard": 3051,
      "Radical": "玉",
      "HSK": 5,
      "FrequencyRank": 1894,
      "StrokeCount": 14
    }
  },
  "效果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoguǒ",
      "PrimaryDefinition": "operation, execution, result, effect, background, sound effects, purpose, point, background signal, fruitage, outcome, force, punch"
    },
    "Keys": {
      "Word": 2051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "减压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn yā",
      "PrimaryDefinition": "to reduce pressure, to relax"
    },
    "Keys": {
      "Word": 7407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhé",
      "PrimaryDefinition": "wagon ruts, wheel tracks"
    },
    "Keys": {
      "Hanzi": 3339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.12",
      "GeneralStandard": 3339,
      "Radical": "車",
      "HSK": 9,
      "FrequencyRank": 3208,
      "StrokeCount": 16
    }
  },
  "蹲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dūn",
      "PrimaryDefinition": "squat, crouch; idle about"
    },
    "Keys": {
      "Hanzi": 3458,
      "Word": 4532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.12",
      "Grade": 6,
      "GeneralStandard": 3458,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 2497,
      "StrokeCount": 19
    }
  },
  "受骗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu piàn",
      "PrimaryDefinition": "be taken in, be fooled, be cheated, be deceived, bite"
    },
    "Keys": {
      "Word": 9202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒhuā",
      "PrimaryDefinition": "spark, sparkle"
    },
    "Keys": {
      "Word": 7242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíér",
      "PrimaryDefinition": "occasionally, from time to time"
    },
    "Keys": {
      "Word": 5021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "阇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dū",
      "PrimaryDefinition": "a Buddhist high priest"
    },
    "Keys": {
      "Hanzi": 4945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4945,
      "Radical": "門",
      "FrequencyRank": 6460,
      "RadicalIndex": "169.9",
      "StrokeCount": 11
    }
  },
  "秾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóng",
      "PrimaryDefinition": "thick, lush, in clusters"
    },
    "Keys": {
      "Hanzi": 4891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4891,
      "Radical": "禾",
      "FrequencyRank": 6668,
      "RadicalIndex": "115.13",
      "StrokeCount": 11
    }
  },
  "联合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánhé",
      "PrimaryDefinition": "unite, ally"
    },
    "Keys": {
      "Word": 1709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "似的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìde",
      "PrimaryDefinition": "seems as if, rather like, Taiwan pr. [si4 de5]"
    },
    "Keys": {
      "Word": 2868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "稞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "grain ready for grinding; grain"
    },
    "Keys": {
      "Hanzi": 5497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5497,
      "Radical": "禾",
      "FrequencyRank": 5576,
      "RadicalIndex": "115.8",
      "StrokeCount": 13
    }
  },
  "总体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngtǐ",
      "PrimaryDefinition": "completely, totally, total, entire, overall, population (statistics)"
    },
    "Keys": {
      "Word": 4279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "流量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúliàng",
      "PrimaryDefinition": "flow rate, rate, throughput, site traffic (Internet)"
    },
    "Keys": {
      "Word": 8100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "射": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "shoot, eject, issue forth, emit"
    },
    "Keys": {
      "Hanzi": 1971,
      "Word": 3876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "41.7",
      "Grade": 5,
      "GeneralStandard": 1971,
      "Radical": "寸",
      "HSK": 5,
      "FrequencyRank": 703,
      "StrokeCount": 10
    }
  },
  "碰钉子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèng dīngzi",
      "PrimaryDefinition": "to meet with a rebuff"
    },
    "Keys": {
      "Word": 8498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běng",
      "PrimaryDefinition": "ornament"
    },
    "Keys": {
      "Hanzi": 5042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5042,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "玃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "a large ape found in W. China"
    },
    "Keys": {
      "Hanzi": 8101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8101,
      "Radical": "犬",
      "RadicalIndex": "94.2",
      "StrokeCount": 23
    }
  },
  "瑂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7472,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "早饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎofàn",
      "PrimaryDefinition": "breakfast, CL:份[fen4],頓|顿[dun4],次[ci4],餐[can1]"
    },
    "Keys": {
      "Word": 455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "对策": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìcè",
      "PrimaryDefinition": "expedient, cure, countermeasure, antidote, way to deal with a situation, countermove"
    },
    "Keys": {
      "Word": 6378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "beer"
    },
    "Keys": {
      "Hanzi": 2289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2289,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 2849,
      "StrokeCount": 11
    }
  },
  "勇气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngqì",
      "PrimaryDefinition": "gameness, pluck, guts, mettle, braveness, grit, heart, moxie, courage, backbone, boldness, pride, gutsiness, nerve, pecker, courageousness, valour, spirit, spunk, gallantry, valor, virtue, audacity, bravery, pluckiness, gumption, sand, gut, valiance"
    },
    "Keys": {
      "Word": 3113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "崟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "cliffs"
    },
    "Keys": {
      "Hanzi": 7171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7171,
      "Radical": "山",
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "镑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "pound sterling"
    },
    "Keys": {
      "Hanzi": 3258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.1",
      "GeneralStandard": 3258,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2515,
      "StrokeCount": 15
    }
  },
  "意愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyuàn",
      "PrimaryDefinition": "will, wish, desire, inclination, aspiration, request, pleasure"
    },
    "Keys": {
      "Word": 5249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "重播": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngbō",
      "PrimaryDefinition": "Replay"
    },
    "Keys": {
      "Word": 5920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "examine, inspect, review, read"
    },
    "Keys": {
      "Hanzi": 2048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "169.7",
      "GeneralStandard": 2048,
      "Radical": "門",
      "HSK": 4,
      "FrequencyRank": 1489,
      "StrokeCount": 10
    }
  },
  "附和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùhè",
      "PrimaryDefinition": "to agree, to go along with, to echo (what sb says)"
    },
    "Keys": {
      "Word": 6628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "品尝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐncháng",
      "PrimaryDefinition": "to taste a small amount, to sample"
    },
    "Keys": {
      "Word": 8537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祖父": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔfù",
      "PrimaryDefinition": "grandad, gramps, grandfather, grandsire, (paternal) grandfather, granddad, grandpa, granddaddy"
    },
    "Keys": {
      "Word": 5409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夸奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuājiǎng",
      "PrimaryDefinition": "to praise, to applaud, to compliment"
    },
    "Keys": {
      "Word": 7868
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàotiáo",
      "PrimaryDefinition": "creed, doctrine, religious dogma"
    },
    "Keys": {
      "Word": 7492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "twins"
    },
    "Keys": {
      "Hanzi": 4303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.6",
      "GeneralStandard": 4303,
      "Radical": "子",
      "HSK": 9,
      "FrequencyRank": 4292,
      "StrokeCount": 9
    }
  },
  "透支": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòuzhī",
      "PrimaryDefinition": "(bank) overdraft"
    },
    "Keys": {
      "Word": 9569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抵抗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐkàng",
      "PrimaryDefinition": "resist, stand up to"
    },
    "Keys": {
      "Word": 4500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "戣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "lance"
    },
    "Keys": {
      "Hanzi": 7616
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7616,
      "Radical": "戈",
      "RadicalIndex": "62.9",
      "StrokeCount": 13
    }
  },
  "崇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóng",
      "PrimaryDefinition": "esteem, honor, revere, venerate"
    },
    "Keys": {
      "Hanzi": 2299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.8",
      "GeneralStandard": 2299,
      "Radical": "山",
      "HSK": 6,
      "FrequencyRank": 1482,
      "StrokeCount": 11
    }
  },
  "体现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐxiàn",
      "PrimaryDefinition": "to embody, to reflect, to incarnate"
    },
    "Keys": {
      "Word": 1958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "奶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 332,
      "Word": 255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.2",
      "Grade": 1,
      "GeneralStandard": 332,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 1278,
      "StrokeCount": 5
    }
  },
  "比如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐrú",
      "PrimaryDefinition": "for example, for instance, such as"
    },
    "Keys": {
      "Word": 515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "悯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "pity, sympathize with, grieve for"
    },
    "Keys": {
      "Hanzi": 2095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2095,
      "Radical": "心",
      "FrequencyRank": 2968,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "气馁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìněi",
      "PrimaryDefinition": "to be discouraged"
    },
    "Keys": {
      "Word": 8622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6986,
      "Radical": "口",
      "FrequencyRank": 8303,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "help, aid, assist"
    },
    "Keys": {
      "Hanzi": 719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.5",
      "GeneralStandard": 719,
      "Radical": "力",
      "HSK": 2,
      "FrequencyRank": 607,
      "StrokeCount": 7
    }
  },
  "首都": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒudū",
      "PrimaryDefinition": "capital (of country)"
    },
    "Keys": {
      "Word": 1927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "儒学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúxué",
      "PrimaryDefinition": "Confucianism"
    },
    "Keys": {
      "Word": 8901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "extensive, wide, broad; empty"
    },
    "Keys": {
      "Hanzi": 727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.3",
      "GeneralStandard": 727,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2735,
      "StrokeCount": 7
    }
  },
  "杜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "stop, prevent; restrict; surname"
    },
    "Keys": {
      "Hanzi": 680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "GeneralStandard": 680,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 1277,
      "StrokeCount": 7
    }
  },
  "偏偏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānpiān",
      "PrimaryDefinition": "just, but, only"
    },
    "Keys": {
      "Word": 8522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自己": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìjǐ",
      "PrimaryDefinition": "lonesome, number_one, own, ego, self, oneself, name"
    },
    "Keys": {
      "Word": 1236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "决赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juésài",
      "PrimaryDefinition": "runoff, fight-off, cup_final, playoff, cup final, match_game, finals, final, run-off"
    },
    "Keys": {
      "Word": 1658
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "峻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "high, steep, towering; stern"
    },
    "Keys": {
      "Hanzi": 1923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.7",
      "GeneralStandard": 1923,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 2506,
      "StrokeCount": 10
    }
  },
  "讷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nè",
      "PrimaryDefinition": "slow of speech; mumble; stammer"
    },
    "Keys": {
      "Hanzi": 3634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3634,
      "Radical": "言",
      "FrequencyRank": 3916,
      "RadicalIndex": "149.4",
      "StrokeCount": 6
    }
  },
  "酱油": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàngyóu",
      "PrimaryDefinition": "soy sauce"
    },
    "Keys": {
      "Word": 4719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嬗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "succession to the throne"
    },
    "Keys": {
      "Hanzi": 6190
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6190,
      "Radical": "女",
      "FrequencyRank": 4848,
      "RadicalIndex": "38.13",
      "StrokeCount": 16
    }
  },
  "踞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "crouch, squat; sit, occupy"
    },
    "Keys": {
      "Hanzi": 5919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5919,
      "Radical": "足",
      "FrequencyRank": 3651,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "骋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chěng",
      "PrimaryDefinition": "gallop horse; hasten, hurry"
    },
    "Keys": {
      "Hanzi": 4702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4702,
      "Radical": "馬",
      "FrequencyRank": 4028,
      "RadicalIndex": "187.7",
      "StrokeCount": 10
    }
  },
  "煤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "coal, coke, charcoal, carbon"
    },
    "Keys": {
      "Hanzi": 3003,
      "Word": 3717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.9",
      "Grade": 5,
      "GeneralStandard": 3003,
      "Radical": "火",
      "HSK": 5,
      "FrequencyRank": 1740,
      "StrokeCount": 13
    }
  },
  "形容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngróng",
      "PrimaryDefinition": "characterize, description, describe"
    },
    "Keys": {
      "Word": 3039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "友善": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒushàn",
      "PrimaryDefinition": "friendly"
    },
    "Keys": {
      "Word": 10471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi",
      "PrimaryDefinition": "expenses, expenditures, fee"
    },
    "Keys": {
      "Hanzi": 1726,
      "Word": 1449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "Grade": 3,
      "GeneralStandard": 1726,
      "Radical": "貝",
      "HSK": 3,
      "FrequencyRank": 486,
      "StrokeCount": 9
    }
  },
  "轾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "low rear portion of cart"
    },
    "Keys": {
      "Hanzi": 4485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4485,
      "Radical": "車",
      "FrequencyRank": 6203,
      "RadicalIndex": "159.6",
      "StrokeCount": 10
    }
  },
  "郭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guō",
      "PrimaryDefinition": "outer part (of a city); surname"
    },
    "Keys": {
      "Hanzi": 2022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.8",
      "GeneralStandard": 2022,
      "Radical": "邑",
      "HSK": 9,
      "FrequencyRank": 1813,
      "StrokeCount": 10
    }
  },
  "蜢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "grasshopper"
    },
    "Keys": {
      "Hanzi": 5714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5714,
      "Radical": "虫",
      "FrequencyRank": 5313,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "回扣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíkòu",
      "PrimaryDefinition": "brokerage, a commission paid to a middleman, euphemism for a bribe, a kickback"
    },
    "Keys": {
      "Word": 7204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "直观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíguān",
      "PrimaryDefinition": "direct observation, directly perceived through the senses, intuitive, audiovisual"
    },
    "Keys": {
      "Word": 10758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": "to prick; to cut blocks, to engrave"
    },
    "Keys": {
      "Hanzi": 7092
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7092,
      "Radical": "刀",
      "RadicalIndex": "18.8",
      "StrokeCount": 10
    }
  },
  "颜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "face, facial appearance"
    },
    "Keys": {
      "Hanzi": 3285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.9",
      "GeneralStandard": 3285,
      "Radical": "頁",
      "HSK": 2,
      "FrequencyRank": 1545,
      "StrokeCount": 15
    }
  },
  "岬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "cape; promontory, headland"
    },
    "Keys": {
      "Hanzi": 3925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3925,
      "Radical": "山",
      "FrequencyRank": 4077,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "鲺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "cat fish"
    },
    "Keys": {
      "Hanzi": 7888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7888,
      "Radical": "魚",
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "山坡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānpō",
      "PrimaryDefinition": "hillside, mountain slope"
    },
    "Keys": {
      "Word": 4992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嗓子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎngzi",
      "PrimaryDefinition": "throat, voice, CL:把[ba3]"
    },
    "Keys": {
      "Word": 8922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìrú",
      "PrimaryDefinition": "freedom of action, unobstructed, unconstrained, smoothly, with ease, freely"
    },
    "Keys": {
      "Word": 10963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "clear, deep pool of water"
    },
    "Keys": {
      "Hanzi": 4023
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4023,
      "Radical": "水",
      "FrequencyRank": 4409,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "瘁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "feel tired, be weary, be worn out"
    },
    "Keys": {
      "Hanzi": 5545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5545,
      "Radical": "疒",
      "FrequencyRank": 4325,
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "接力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēlì",
      "PrimaryDefinition": "relay"
    },
    "Keys": {
      "Word": 7507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "指向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐxiàng",
      "PrimaryDefinition": "head, point to, hold, point, maneuver, direct, steer, gear, pitch, manouevre, guide"
    },
    "Keys": {
      "Word": 10779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "配送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèisòng",
      "PrimaryDefinition": "distribution, delivery"
    },
    "Keys": {
      "Word": 8487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "burn"
    },
    "Keys": {
      "Hanzi": 7984
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7984,
      "Radical": "火",
      "RadicalIndex": "86.15",
      "StrokeCount": 18
    }
  },
  "般": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "sort, manner, kind, category"
    },
    "Keys": {
      "Hanzi": 1980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137.4",
      "GeneralStandard": 1980,
      "Radical": "舟",
      "HSK": 2,
      "FrequencyRank": 629,
      "StrokeCount": 10
    }
  },
  "关爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānài",
      "PrimaryDefinition": "care for"
    },
    "Keys": {
      "Word": 4627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "扅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "gate bar, bolt"
    },
    "Keys": {
      "Hanzi": 7075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7075,
      "Radical": "戶",
      "RadicalIndex": "63.6",
      "StrokeCount": 10
    }
  },
  "全局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánjú",
      "PrimaryDefinition": "overall situation"
    },
    "Keys": {
      "Word": 8804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "alley, lane"
    },
    "Keys": {
      "Hanzi": 1406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "49.6",
      "GeneralStandard": 1406,
      "Radical": "己",
      "HSK": 9,
      "FrequencyRank": 2399,
      "StrokeCount": 9
    }
  },
  "东边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngbiān",
      "PrimaryDefinition": "east, east side, eastern part, to the east of"
    },
    "Keys": {
      "Word": 83
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "碰见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèng jian",
      "PrimaryDefinition": "to run into, to meet (unexpectedly), to bump into"
    },
    "Keys": {
      "Word": 904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "尽情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnqíng",
      "PrimaryDefinition": "fully, as much as one likes"
    },
    "Keys": {
      "Word": 7574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "barley"
    },
    "Keys": {
      "Hanzi": 3677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3677,
      "Radical": "艸",
      "FrequencyRank": 4874,
      "RadicalIndex": "140.5",
      "StrokeCount": 7
    }
  },
  "垫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "advance money, pay for another"
    },
    "Keys": {
      "Hanzi": 1390,
      "Word": 6278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.6",
      "Grade": 7,
      "GeneralStandard": 1390,
      "Radical": "土",
      "HSK": 7,
      "FrequencyRank": 2597,
      "StrokeCount": 9
    }
  },
  "偏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piān",
      "PrimaryDefinition": "inclined one side; slanting"
    },
    "Keys": {
      "Hanzi": 2334,
      "Word": 4902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "Grade": 6,
      "GeneralStandard": 2334,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1204,
      "StrokeCount": 11
    }
  },
  "伦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lún",
      "PrimaryDefinition": "normal human relationships"
    },
    "Keys": {
      "Hanzi": 470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 470,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 970,
      "StrokeCount": 6
    }
  },
  "内外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèiwài",
      "PrimaryDefinition": "inside and outside, domestic and foreign"
    },
    "Keys": {
      "Word": 4888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "协议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiéyì",
      "PrimaryDefinition": "agreement"
    },
    "Keys": {
      "Word": 4063
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "频道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píndào",
      "PrimaryDefinition": "channel, transmission channel, frequency channel"
    },
    "Keys": {
      "Word": 3773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "娥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "be beautiful; good; surname"
    },
    "Keys": {
      "Hanzi": 2143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.7",
      "GeneralStandard": 2143,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 2784,
      "StrokeCount": 10
    }
  },
  "亸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒ",
      "PrimaryDefinition": "read aloud, recite, chant; droop"
    },
    "Keys": {
      "Hanzi": 7891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7891,
      "Radical": "口",
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "注重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùzhòng",
      "PrimaryDefinition": "lay stress on, pay attention to"
    },
    "Keys": {
      "Word": 4259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "耱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "a kind of farm tool"
    },
    "Keys": {
      "Hanzi": 6464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6464,
      "Radical": "耒",
      "FrequencyRank": 7116,
      "RadicalIndex": "127.16",
      "StrokeCount": 22
    }
  },
  "相通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngtōng",
      "PrimaryDefinition": "interlinked, connected, communicating, in communication, accommodating"
    },
    "Keys": {
      "Word": 9929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "amaranth"
    },
    "Keys": {
      "Hanzi": 3667
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3667,
      "Radical": "艸",
      "FrequencyRank": 4820,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "澧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "river in northern Hunan province"
    },
    "Keys": {
      "Hanzi": 6177
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6177,
      "Radical": "水",
      "FrequencyRank": 4755,
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "骛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "gallop; rush about; pursue, run"
    },
    "Keys": {
      "Hanzi": 5327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5327,
      "Radical": "馬",
      "FrequencyRank": 4621,
      "RadicalIndex": "187.9",
      "StrokeCount": 12
    }
  },
  "妨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fáng",
      "PrimaryDefinition": "interfere with, impede, obstruct"
    },
    "Keys": {
      "Hanzi": 922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.4",
      "GeneralStandard": 922,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 1873,
      "StrokeCount": 7
    }
  },
  "不得不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùdébù",
      "PrimaryDefinition": "cannot but, have to"
    },
    "Keys": {
      "Word": 1319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "萧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "common artemisia; dejected"
    },
    "Keys": {
      "Hanzi": 2216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2216,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 1898,
      "StrokeCount": 11
    }
  },
  "前后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánhòu",
      "PrimaryDefinition": "front and rear, around or about, altogether"
    },
    "Keys": {
      "Word": 1806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "洮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "cleanse; river in Gansu province"
    },
    "Keys": {
      "Hanzi": 4343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4343,
      "Radical": "水",
      "FrequencyRank": 5637,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "国家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guójiā",
      "PrimaryDefinition": "sirkar, state, country, patria, a people, nationality, nation, republic, soli, body politic, clime, national, civil order, polity, commonwealth, land, res publica"
    },
    "Keys": {
      "Word": 126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "做饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò fàn",
      "PrimaryDefinition": "do the cooking, prepare a meal, prepare meal"
    },
    "Keys": {
      "Word": 1260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "亡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wáng",
      "PrimaryDefinition": "death, destroyed; lose, perish"
    },
    "Keys": {
      "Hanzi": 54
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "8.1",
      "GeneralStandard": 54,
      "Radical": "亠",
      "HSK": 6,
      "FrequencyRank": 957,
      "StrokeCount": 3
    }
  },
  "关闭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānbì",
      "PrimaryDefinition": "close, shut (down)"
    },
    "Keys": {
      "Word": 2513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "飞往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi wǎng",
      "PrimaryDefinition": "Go to"
    },
    "Keys": {
      "Word": 6509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé",
      "PrimaryDefinition": "technetium"
    },
    "Keys": {
      "Hanzi": 5485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5485,
      "Radical": "金",
      "FrequencyRank": 6015,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "还是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háishi",
      "PrimaryDefinition": "still, nevertheless"
    },
    "Keys": {
      "Word": 129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "桠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "the forking branch of a tree"
    },
    "Keys": {
      "Hanzi": 6958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6958,
      "Radical": "木",
      "FrequencyRank": 4832,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "半边天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànbiāntiān",
      "PrimaryDefinition": "half the sky, women of the new society, womenfolk"
    },
    "Keys": {
      "Word": 5490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéwen",
      "PrimaryDefinition": "lear, ology, lore, knowledge, learning, acquirement, learnedness, cognition, erudition, letter, eruditeness, scholarship"
    },
    "Keys": {
      "Word": 3053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "潜水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánshuǐ",
      "PrimaryDefinition": "dive, go under water"
    },
    "Keys": {
      "Word": 8681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电影院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànyǐng yuàn",
      "PrimaryDefinition": "cinema, movie (house)"
    },
    "Keys": {
      "Word": 82
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "顺便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnbiàn",
      "PrimaryDefinition": "incidentally, conveniently, en passant, obiter, apropos, in passing, handily"
    },
    "Keys": {
      "Word": 9277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "还": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hái",
      "PrimaryDefinition": "still, yet, also, besides"
    },
    "Keys": {
      "Hanzi": 704,
      "Word": 134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 1,
      "GeneralStandard": 704,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 80,
      "StrokeCount": 7
    }
  },
  "议员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyuán",
      "PrimaryDefinition": "councillor, member of legislative body, deputy, councilman, council member, legislator, solon, Congresswoman, Member of Parliament, assemblyman, Congressman"
    },
    "Keys": {
      "Word": 10359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "name of a river in Shanxi"
    },
    "Keys": {
      "Hanzi": 5282
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5282,
      "Radical": "水",
      "FrequencyRank": 3620,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "中小学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxiǎoxué",
      "PrimaryDefinition": "primary and middle school"
    },
    "Keys": {
      "Word": 1224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "勠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "join forces, unite"
    },
    "Keys": {
      "Hanzi": 7615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7615,
      "Radical": "力",
      "RadicalIndex": "19.11",
      "StrokeCount": 13
    }
  },
  "楞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léng",
      "PrimaryDefinition": "used for Ceylon in Buddhist texts"
    },
    "Keys": {
      "Hanzi": 7497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7497,
      "Radical": "木",
      "FrequencyRank": 3223,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "积极": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jījí",
      "PrimaryDefinition": "positive, active, energetic, vigorous"
    },
    "Keys": {
      "Word": 1571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "这会儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèhuìr5",
      "PrimaryDefinition": "(coll.) now, this moment, also pr. [zhe4 hui3 r5]"
    },
    "Keys": {
      "Word": 10690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "故": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "ancient, old; reason, because"
    },
    "Keys": {
      "Hanzi": 1418,
      "Word": 6840
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.5",
      "Grade": 7,
      "GeneralStandard": 1418,
      "Radical": "攴",
      "HSK": 2,
      "FrequencyRank": 572,
      "StrokeCount": 9
    }
  },
  "仑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lún",
      "PrimaryDefinition": "logical reasons, logical order"
    },
    "Keys": {
      "Hanzi": 143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 143,
      "Radical": "人",
      "FrequencyRank": 2139,
      "RadicalIndex": "9.2",
      "StrokeCount": 4
    }
  },
  "芎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiōng",
      "PrimaryDefinition": "a kind of herb"
    },
    "Keys": {
      "Hanzi": 3583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3583,
      "Radical": "艸",
      "FrequencyRank": 5116,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "山冈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngāng",
      "PrimaryDefinition": "mound, small hill"
    },
    "Keys": {
      "Word": 8946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "mark, symbol, label, sign; stand the bole of a tree"
    },
    "Keys": {
      "Hanzi": 1424,
      "Word": 5623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 7,
      "GeneralStandard": 1424,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 473,
      "StrokeCount": 9
    }
  },
  "表率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎoshuài",
      "PrimaryDefinition": "example, model"
    },
    "Keys": {
      "Word": 5633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāokōng",
      "PrimaryDefinition": "high altitude, upper air"
    },
    "Keys": {
      "Word": 6700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "obscure, dim, ambiguous, vague"
    },
    "Keys": {
      "Hanzi": 5700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.1",
      "GeneralStandard": 5700,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 3802,
      "StrokeCount": 14
    }
  },
  "阈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "threshold; separated, confined"
    },
    "Keys": {
      "Hanzi": 4946
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4946,
      "Radical": "門",
      "FrequencyRank": 4701,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "曲线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūxiàn",
      "PrimaryDefinition": "curve, curved line, indirect, in a roundabout way"
    },
    "Keys": {
      "Word": 8775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "第一线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìyīxiàn",
      "PrimaryDefinition": "forefront, front/first line"
    },
    "Keys": {
      "Word": 6261
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拉开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lā kai",
      "PrimaryDefinition": "to pull open, to pull apart, to space out, to increase"
    },
    "Keys": {
      "Word": 2677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恩怨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēnyuàn",
      "PrimaryDefinition": "gratitude and grudges, resentment, grudges, grievances"
    },
    "Keys": {
      "Word": 6424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "indistinct, dim; minute; distant"
    },
    "Keys": {
      "Hanzi": 5333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5333,
      "Radical": "糸",
      "FrequencyRank": 4549,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "皇帝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huángdì",
      "PrimaryDefinition": "emperor"
    },
    "Keys": {
      "Word": 4680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "香肠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngcháng",
      "PrimaryDefinition": "sausage, CL:根[gen1]"
    },
    "Keys": {
      "Word": 4041
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "颈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "neck, throat"
    },
    "Keys": {
      "Hanzi": 2485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.5",
      "GeneralStandard": 2485,
      "Radical": "頁",
      "HSK": 9,
      "FrequencyRank": 2117,
      "StrokeCount": 11
    }
  },
  "踟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "hesitate, undecided; embarrassed"
    },
    "Keys": {
      "Hanzi": 5913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5913,
      "Radical": "足",
      "FrequencyRank": 6041,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "磋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuō",
      "PrimaryDefinition": "polish, buff; scrutinize"
    },
    "Keys": {
      "Hanzi": 5680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.1",
      "GeneralStandard": 5680,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 3261,
      "StrokeCount": 14
    }
  },
  "收费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōu fèi",
      "PrimaryDefinition": "collect fees, charge"
    },
    "Keys": {
      "Word": 1921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "看护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kānhù",
      "PrimaryDefinition": "to nurse, to look after, to watch over, (old) hospital nurse"
    },
    "Keys": {
      "Word": 7766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "duty, profession; office, post"
    },
    "Keys": {
      "Hanzi": 2192
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.5",
      "GeneralStandard": 2192,
      "Radical": "耳",
      "HSK": 3,
      "FrequencyRank": 616,
      "StrokeCount": 11
    }
  },
  "茽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6788
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6788,
      "Radical": "艸",
      "FrequencyRank": 8626,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "溱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "river in Henan"
    },
    "Keys": {
      "Hanzi": 5567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5567,
      "Radical": "水",
      "FrequencyRank": 6478,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "锊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüè",
      "PrimaryDefinition": "6 oz; ancient measurement"
    },
    "Keys": {
      "Hanzi": 7379
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7379,
      "Radical": "金",
      "FrequencyRank": 5923,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huà",
      "PrimaryDefinition": "a painting, picture, drawing; to draw"
    },
    "Keys": {
      "Hanzi": 1036,
      "Word": 728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102.3",
      "Grade": 2,
      "GeneralStandard": 1036,
      "Radical": "田",
      "HSK": 2,
      "FrequencyRank": 883,
      "StrokeCount": 8
    }
  },
  "呣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ḿ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3920
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3920,
      "Radical": "口",
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "迕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "obstinate, perverse"
    },
    "Keys": {
      "Hanzi": 3724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3724,
      "Radical": "辵",
      "FrequencyRank": 5882,
      "RadicalIndex": "162.4",
      "StrokeCount": 7
    }
  },
  "当天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngtiān",
      "PrimaryDefinition": "on that day, the same day"
    },
    "Keys": {
      "Word": 4487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "毛笔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máobǐ",
      "PrimaryDefinition": "writing brush, CL:枝[zhi1],管[guan3]"
    },
    "Keys": {
      "Word": 3711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "姐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiě",
      "PrimaryDefinition": "elder sister, young lady"
    },
    "Keys": {
      "Hanzi": 1326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1326,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 830,
      "StrokeCount": 8
    }
  },
  "韩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "fence; surname; Korea"
    },
    "Keys": {
      "Hanzi": 2558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "178.8",
      "GeneralStandard": 2558,
      "Radical": "韋",
      "HSK": 9,
      "FrequencyRank": 1221,
      "StrokeCount": 12
    }
  },
  "舆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "cart, palanquin, sedan chair"
    },
    "Keys": {
      "Hanzi": 3125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "12.12",
      "GeneralStandard": 3125,
      "Radical": "八",
      "HSK": 8,
      "FrequencyRank": 2341,
      "StrokeCount": 14
    }
  },
  "埏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "a boundary, a limit"
    },
    "Keys": {
      "Hanzi": 6771
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6771,
      "Radical": "土",
      "FrequencyRank": 6998,
      "RadicalIndex": "32.7",
      "StrokeCount": 9
    }
  },
  "琵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "guitar-like instrument"
    },
    "Keys": {
      "Hanzi": 5043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5043,
      "Radical": "玉",
      "FrequencyRank": 3721,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "说法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōfa",
      "PrimaryDefinition": "way of saying sth., wording, formulation, statement, version, argument"
    },
    "Keys": {
      "Word": 3937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "喧闹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānnào",
      "PrimaryDefinition": "to make a noise, noisy"
    },
    "Keys": {
      "Word": 10115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdù",
      "PrimaryDefinition": "once"
    },
    "Keys": {
      "Word": 10284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "to swim; float, drift; wander, roam"
    },
    "Keys": {
      "Hanzi": 2766,
      "Word": 2113
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "Grade": 3,
      "GeneralStandard": 2766,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 695,
      "StrokeCount": 12
    }
  },
  "诹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōu",
      "PrimaryDefinition": "consult, confer; select, choose"
    },
    "Keys": {
      "Hanzi": 4668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4668,
      "Radical": "言",
      "FrequencyRank": 5004,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "漂亮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piàoliang",
      "PrimaryDefinition": "posh, handsome, brilliant, remarkable, splendid, beautiful, smart"
    },
    "Keys": {
      "Word": 910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "掀起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānqǐ",
      "PrimaryDefinition": "lift, raise, surge, cause to surge, start (movement/etc.)"
    },
    "Keys": {
      "Word": 9886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萎缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěisuō",
      "PrimaryDefinition": "wither, shrivel, shrink, sag, atrophy"
    },
    "Keys": {
      "Word": 9711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "holy, sacred, sage; simplification of 堊 chalk; daub with chalk"
    },
    "Keys": {
      "Hanzi": 4129
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4129,
      "Radical": "土",
      "FrequencyRank": 4608,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "洗涤剂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐdíjì",
      "PrimaryDefinition": "cleaning agent, detergent"
    },
    "Keys": {
      "Word": 9833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "illness, disease, sickness; to hate"
    },
    "Keys": {
      "Hanzi": 2028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.5",
      "GeneralStandard": 2028,
      "Radical": "疒",
      "HSK": 6,
      "FrequencyRank": 1273,
      "StrokeCount": 10
    }
  },
  "感染力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnrǎnlì",
      "PrimaryDefinition": "inspiration, infectious (enthusiasm)"
    },
    "Keys": {
      "Word": 6679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "失误": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīwù",
      "PrimaryDefinition": "mistake"
    },
    "Keys": {
      "Word": 3895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "事迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjì",
      "PrimaryDefinition": "deed, past achievement, important event of the past"
    },
    "Keys": {
      "Word": 9142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "measles; rash; fever"
    },
    "Keys": {
      "Hanzi": 2030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2030,
      "Radical": "疒",
      "FrequencyRank": 3215,
      "RadicalIndex": "104.5",
      "StrokeCount": 10
    }
  },
  "撒谎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sā huǎng",
      "PrimaryDefinition": "to tell lies"
    },
    "Keys": {
      "Word": 8913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "难得一见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nándéyījiàn",
      "PrimaryDefinition": "rarely seen"
    },
    "Keys": {
      "Word": 8375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng rèn",
      "PrimaryDefinition": "take up an official post, assume post, incept, assume office"
    },
    "Keys": {
      "Word": 8976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "推算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīsuàn",
      "PrimaryDefinition": "to calculate, to reckon, to extrapolate (in calculation)"
    },
    "Keys": {
      "Word": 9600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "chick, fledging; infant, toddler"
    },
    "Keys": {
      "Hanzi": 2983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2983,
      "Radical": "隹",
      "FrequencyRank": 3484,
      "RadicalIndex": "172.5",
      "StrokeCount": 13
    }
  },
  "水面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐmiàn",
      "PrimaryDefinition": "water surface"
    },
    "Keys": {
      "Word": 9266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邮政": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuzhèng",
      "PrimaryDefinition": "postal service, postal"
    },
    "Keys": {
      "Word": 10463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "切实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qièshí",
      "PrimaryDefinition": "feasible, realistic, practical, earnestly, conscientiously"
    },
    "Keys": {
      "Word": 4938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "珐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fà",
      "PrimaryDefinition": "enamel, cloissoné"
    },
    "Keys": {
      "Hanzi": 4085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4085,
      "Radical": "玉",
      "FrequencyRank": 5432,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "互助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùzhù",
      "PrimaryDefinition": "Huzhu Tuzu autonomous county in Haidong prefecture 海東地區|海东地区[Hai3 dong1 di4 qu1], Qinghai, to help each other"
    },
    "Keys": {
      "Word": 7123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàng",
      "PrimaryDefinition": "shape, form, pattern, style"
    },
    "Keys": {
      "Hanzi": 1849,
      "Word": 5219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 6,
      "GeneralStandard": 1849,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 88,
      "StrokeCount": 10
    }
  },
  "座谈会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòtánhuì",
      "PrimaryDefinition": "colloquium, symposiac, panel_discussion, an informal discussion meeting, symposium, teach-in, forum, symposia, conversazione, panel discussion"
    },
    "Keys": {
      "Word": 5420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "红": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "red, vermillion; blush, flush"
    },
    "Keys": {
      "Hanzi": 589,
      "Word": 719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.3",
      "Grade": 2,
      "GeneralStandard": 589,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 502,
      "StrokeCount": 6
    }
  },
  "水域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐyù",
      "PrimaryDefinition": "water, waters, water area, body of water"
    },
    "Keys": {
      "Word": 9272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎo",
      "PrimaryDefinition": "angered, filled with hate"
    },
    "Keys": {
      "Hanzi": 1691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1691,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1704,
      "StrokeCount": 9
    }
  },
  "上边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngbian",
      "PrimaryDefinition": "the top, above, overhead, upwards, the top margin, above-mentioned, those higher up"
    },
    "Keys": {
      "Word": 317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "幖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7675
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7675,
      "Radical": "巾",
      "RadicalIndex": "50.11",
      "StrokeCount": 14
    }
  },
  "掠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lüè",
      "PrimaryDefinition": "rob, ransack, plunder; pass by"
    },
    "Keys": {
      "Hanzi": 2182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2182,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 1868,
      "StrokeCount": 11
    }
  },
  "跑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǎo",
      "PrimaryDefinition": "run, flee, leave in hurry"
    },
    "Keys": {
      "Hanzi": 2631,
      "Word": 279
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.5",
      "Grade": 1,
      "GeneralStandard": 2631,
      "Radical": "足",
      "HSK": 1,
      "FrequencyRank": 864,
      "StrokeCount": 12
    }
  },
  "楼上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóushàng",
      "PrimaryDefinition": "upstairs"
    },
    "Keys": {
      "Word": 216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "相比之下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngbǐzhīxià",
      "PrimaryDefinition": "by comparison"
    },
    "Keys": {
      "Word": 9917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "音量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnliàng",
      "PrimaryDefinition": "intensity, volume, quantity, loudness, volume of sound"
    },
    "Keys": {
      "Word": 5253
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "趸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔn",
      "PrimaryDefinition": "sell or buy wholesale; store"
    },
    "Keys": {
      "Hanzi": 4488
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4488,
      "Radical": "足",
      "FrequencyRank": 5347,
      "RadicalIndex": "157.3",
      "StrokeCount": 10
    }
  },
  "不起眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùqǐyǎn",
      "PrimaryDefinition": "unremarkable, nothing out of the ordinary"
    },
    "Keys": {
      "Word": 5709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "屾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6534
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6534,
      "Radical": "山",
      "RadicalIndex": "46.3",
      "StrokeCount": 6
    }
  },
  "贫富": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínfù",
      "PrimaryDefinition": "poor and rich"
    },
    "Keys": {
      "Word": 8533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "earthenware basin; alms bowl"
    },
    "Keys": {
      "Hanzi": 4530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4530,
      "Radical": "金",
      "FrequencyRank": 3584,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "阜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "mound"
    },
    "Keys": {
      "Radical": 170,
      "Hanzi": 3968
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3968,
      "Radical": "阜",
      "FrequencyRank": 3445,
      "RadicalIndex": "170",
      "StrokeCount": 8
    }
  },
  "偈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "brave; martial; hasty; scudding"
    },
    "Keys": {
      "Hanzi": 4907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4907,
      "Radical": "人",
      "FrequencyRank": 4283,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "亢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàng",
      "PrimaryDefinition": "high, proud; violent, excessive; skilled; name"
    },
    "Keys": {
      "Hanzi": 162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 162,
      "Radical": "亠",
      "FrequencyRank": 3268,
      "RadicalIndex": "8.2",
      "StrokeCount": 4
    }
  },
  "机会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīhui",
      "PrimaryDefinition": "scouth, occasion, good luck, hap, liberty, fortune, opportunity, chance, joss, hazard, happy chance, probability, luck, scope, room, break, turn, opening"
    },
    "Keys": {
      "Word": 744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "睹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔ",
      "PrimaryDefinition": "look at, gaze at; observe"
    },
    "Keys": {
      "Hanzi": 2896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.9",
      "GeneralStandard": 2896,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 2568,
      "StrokeCount": 13
    }
  },
  "啧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "interjection of approval or admiration"
    },
    "Keys": {
      "Hanzi": 4811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4811,
      "Radical": "口",
      "FrequencyRank": 3698,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "乡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "country; rural; village"
    },
    "Keys": {
      "Hanzi": 73,
      "Word": 4044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "52",
      "Grade": 5,
      "GeneralStandard": 73,
      "Radical": "幺",
      "HSK": 3,
      "FrequencyRank": 922,
      "StrokeCount": 3
    }
  },
  "承诺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngnuò",
      "PrimaryDefinition": "promise"
    },
    "Keys": {
      "Word": 4412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "苏醒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūxǐng",
      "PrimaryDefinition": "to come to, to awaken, to regain consciousness"
    },
    "Keys": {
      "Word": 9342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炉子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúzi",
      "PrimaryDefinition": "stove, oven, furnace"
    },
    "Keys": {
      "Word": 8127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "断断续续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duànduàn-xùxù",
      "PrimaryDefinition": "intermittent, off and on, discontinuous, stop-go, stammering, disjointed, inarticulate"
    },
    "Keys": {
      "Word": 6372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāonà",
      "PrimaryDefinition": "to pay (taxes or dues)"
    },
    "Keys": {
      "Word": 7458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "a skirt; long robe for women, having no sleeves and fasten down the front"
    },
    "Keys": {
      "Hanzi": 3934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3934,
      "Radical": "巾",
      "FrequencyRank": 5722,
      "RadicalIndex": "50.5",
      "StrokeCount": 8
    }
  },
  "财产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáichǎn",
      "PrimaryDefinition": "aught, belonging, fortune, material possession, cash, riches, substance, assets, property, wealth, belongings, estate, seizin, thing, holding, possession, means, goods, asset, pelf, money, propertied, seisin, res, having"
    },
    "Keys": {
      "Word": 2288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "flat, level, even; peaceful"
    },
    "Keys": {
      "Hanzi": 222,
      "Word": 909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "51.2",
      "Grade": 2,
      "GeneralStandard": 222,
      "Radical": "干",
      "HSK": 2,
      "FrequencyRank": 215,
      "StrokeCount": 5
    }
  },
  "尖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "sharp, pointed, acute, keen"
    },
    "Keys": {
      "Hanzi": 415,
      "Word": 4721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "42.3",
      "Grade": 6,
      "GeneralStandard": 415,
      "Radical": "小",
      "HSK": 6,
      "FrequencyRank": 1321,
      "StrokeCount": 6
    }
  },
  "浑身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "húnshēn",
      "PrimaryDefinition": "all over, from head to foot"
    },
    "Keys": {
      "Word": 7228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "义务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìwù",
      "PrimaryDefinition": "duty, obligation, volunteer duty"
    },
    "Keys": {
      "Word": 3099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "车上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chē shàng",
      "PrimaryDefinition": "Car"
    },
    "Keys": {
      "Word": 42
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "大数据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshùjù",
      "PrimaryDefinition": "Big Data"
    },
    "Keys": {
      "Word": 6131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíhou",
      "PrimaryDefinition": "day, moment, (a point in) time, (duration of) time, time"
    },
    "Keys": {
      "Word": 328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "遵循": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnxún",
      "PrimaryDefinition": "track, abide by, follow, conform to, adhere to"
    },
    "Keys": {
      "Word": 11021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "感染": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnrǎn",
      "PrimaryDefinition": "infect, influence, affect"
    },
    "Keys": {
      "Word": 6678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "表决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎojué",
      "PrimaryDefinition": "(decide by) vote"
    },
    "Keys": {
      "Word": 5631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洗衣粉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐyīfěn",
      "PrimaryDefinition": "laundry detergent, washing powder"
    },
    "Keys": {
      "Word": 5155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "廖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liào",
      "PrimaryDefinition": "surname; name of an ancient state"
    },
    "Keys": {
      "Hanzi": 5785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5785,
      "Radical": "广",
      "FrequencyRank": 2890,
      "RadicalIndex": "53.11",
      "StrokeCount": 14
    }
  },
  "姒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "wife of elder brother"
    },
    "Keys": {
      "Hanzi": 3814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3814,
      "Radical": "女",
      "FrequencyRank": 5214,
      "RadicalIndex": "38.5",
      "StrokeCount": 7
    }
  },
  "深厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnhòu",
      "PrimaryDefinition": "deep, profound, solid, deep-seated"
    },
    "Keys": {
      "Word": 2850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "冲浪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōnglàng",
      "PrimaryDefinition": "to surf, surfing"
    },
    "Keys": {
      "Word": 5913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi",
      "PrimaryDefinition": "cypress, cedar"
    },
    "Keys": {
      "Hanzi": 1432
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1432,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 1596,
      "StrokeCount": 9
    }
  },
  "邾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "a feudal state which existed B.C.700-469; now in Shandong"
    },
    "Keys": {
      "Hanzi": 3945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3945,
      "Radical": "邑",
      "FrequencyRank": 6848,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "融": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "melt, fuse; blend, harmonize"
    },
    "Keys": {
      "Hanzi": 3334,
      "Word": 8887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.1",
      "Grade": 7,
      "GeneralStandard": 3334,
      "Radical": "虫",
      "HSK": 6,
      "FrequencyRank": 1225,
      "StrokeCount": 16
    }
  },
  "忧虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōulǜ",
      "PrimaryDefinition": "to worry, anxiety (about)"
    },
    "Keys": {
      "Word": 10453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóng",
      "PrimaryDefinition": "enjoy, amuse, please; joy"
    },
    "Keys": {
      "Hanzi": 7245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7245,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "大师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshī",
      "PrimaryDefinition": "great master, lord, workmaster, artiste, Very Reverend, overlord, master, priest, maestro, non-Christian priest, Great Master"
    },
    "Keys": {
      "Word": 4478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "风餐露宿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngcān-lùsù",
      "PrimaryDefinition": "Wild meal"
    },
    "Keys": {
      "Word": 6566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "forehead; tablet, plaque; fixed"
    },
    "Keys": {
      "Hanzi": 3304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.9",
      "GeneralStandard": 3304,
      "Radical": "頁",
      "HSK": 6,
      "FrequencyRank": 936,
      "StrokeCount": 15
    }
  },
  "渟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "(of water) not flowing; clear"
    },
    "Keys": {
      "Hanzi": 7432
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7432,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "任何": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènhé",
      "PrimaryDefinition": "aught, det.: any"
    },
    "Keys": {
      "Word": 1855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "厂家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngjiā",
      "PrimaryDefinition": "factory, factory owners"
    },
    "Keys": {
      "Word": 5820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "餐馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānguǎn",
      "PrimaryDefinition": "chophouse, restaurant, porterhouse, osteria, cafe, caff"
    },
    "Keys": {
      "Word": 3293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "妥当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǒdang",
      "PrimaryDefinition": "appropriate, proper, ready"
    },
    "Keys": {
      "Word": 9622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "社会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèhuì",
      "PrimaryDefinition": "community, society, monde, social, public"
    },
    "Keys": {
      "Word": 1878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "洄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí",
      "PrimaryDefinition": "a back-water; an eddy a whirlpool"
    },
    "Keys": {
      "Hanzi": 4337
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4337,
      "Radical": "水",
      "FrequencyRank": 4184,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "秉承": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐngchéng",
      "PrimaryDefinition": "to take orders, to receive commands, to carry on (a tradition)"
    },
    "Keys": {
      "Word": 5649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "strike, hit, beat, hammer"
    },
    "Keys": {
      "Hanzi": 7324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7324,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "铎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duó",
      "PrimaryDefinition": "bell; surname"
    },
    "Keys": {
      "Hanzi": 4547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4547,
      "Radical": "金",
      "FrequencyRank": 3688,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "不仅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjǐn",
      "PrimaryDefinition": "conj.: not only"
    },
    "Keys": {
      "Word": 1322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "前天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiántiān",
      "PrimaryDefinition": "the day before yesterday"
    },
    "Keys": {
      "Word": 293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "延长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáncháng",
      "PrimaryDefinition": "lengthen, prolong, extend"
    },
    "Keys": {
      "Word": 3061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "自称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìchēng",
      "PrimaryDefinition": "purport, claim to be, give_out, profess, call oneself"
    },
    "Keys": {
      "Word": 10950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "chase, expel; one by one"
    },
    "Keys": {
      "Hanzi": 1870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "GeneralStandard": 1870,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 954,
      "StrokeCount": 10
    }
  },
  "探讨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàntǎo",
      "PrimaryDefinition": "inquire into, explore"
    },
    "Keys": {
      "Word": 5068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "大有可为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàyǒu-kěwéi",
      "PrimaryDefinition": "with great prospects for the future (idiom), well worth doing"
    },
    "Keys": {
      "Word": 6140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "机制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīzhì",
      "PrimaryDefinition": "mechanism"
    },
    "Keys": {
      "Word": 3575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "诗人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīrén",
      "PrimaryDefinition": "muse, rhymist, swan, lark, Parnassus, Maker, minstrel, harmonist, metrist, maker, bulbul, songster, versifier, poet, singer, eisteddfod, bard"
    },
    "Keys": {
      "Word": 2859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "大家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjiā",
      "PrimaryDefinition": "sundry, great master, rich and influential family, pontiff, all of us, everyone, authority, master, distinguished family"
    },
    "Keys": {
      "Word": 588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "洣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐ",
      "PrimaryDefinition": "Mi river in Hunan, tributary of Xiangjiang"
    },
    "Keys": {
      "Hanzi": 6898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6898,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "押金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yājīn",
      "PrimaryDefinition": "deposit, down payment"
    },
    "Keys": {
      "Word": 4083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "敲门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo mén",
      "PrimaryDefinition": "to knock on a door"
    },
    "Keys": {
      "Word": 3818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "仓库": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāngkù",
      "PrimaryDefinition": "depot, storehouse, warehouse"
    },
    "Keys": {
      "Word": 4371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "微笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēixiào",
      "PrimaryDefinition": "smile"
    },
    "Keys": {
      "Word": 2967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "簝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8008,
      "Radical": "竹",
      "RadicalIndex": "118.12",
      "StrokeCount": 18
    }
  },
  "法规": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎguī",
      "PrimaryDefinition": "rule of law, proscription, ordinance, codification, laws and regulations, law, legislation, interdiction, rule, code, statutes, statute, canon, legislative act, law and regulations, prescription, prohibition, ban"
    },
    "Keys": {
      "Word": 3450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "榜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎng",
      "PrimaryDefinition": "placard; list of successful exam candidates"
    },
    "Keys": {
      "Hanzi": 3081
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "GeneralStandard": 3081,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 2556,
      "StrokeCount": 14
    }
  },
  "波折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōzhé",
      "PrimaryDefinition": "twists and turns"
    },
    "Keys": {
      "Word": 5662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "hatred, enmity, resentment"
    },
    "Keys": {
      "Hanzi": 1608,
      "Word": 4156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "Grade": 5,
      "GeneralStandard": 1608,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1637,
      "StrokeCount": 9
    }
  },
  "反响": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnxiǎng",
      "PrimaryDefinition": "repercussions, reaction, echo"
    },
    "Keys": {
      "Word": 4550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "辩护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànhù",
      "PrimaryDefinition": "to speak in defense of, to argue in favor of, to defend, to plead"
    },
    "Keys": {
      "Word": 5619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艺术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìshù",
      "PrimaryDefinition": "beaux-arts, aesthetical, artistic production, artistic, ars, art, academy, aesthetic, artistic creation, skill, craft, artistry, esthetic"
    },
    "Keys": {
      "Word": 2092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "茫然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mángrán",
      "PrimaryDefinition": "ignorant, in the dark"
    },
    "Keys": {
      "Word": 8208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chè",
      "PrimaryDefinition": "split, tear, open"
    },
    "Keys": {
      "Hanzi": 3846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3846,
      "Radical": "土",
      "FrequencyRank": 6355,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "隼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔn",
      "PrimaryDefinition": "aquiline (nose); a falcon"
    },
    "Keys": {
      "Hanzi": 4574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4574,
      "Radical": "隹",
      "FrequencyRank": 4094,
      "RadicalIndex": "172.2",
      "StrokeCount": 10
    }
  },
  "出山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū shān",
      "PrimaryDefinition": "to leave the mountain (of a hermit), to come out of obscurity to a government job, to take a leading position"
    },
    "Keys": {
      "Word": 5965
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fābù",
      "PrimaryDefinition": "issue, announce"
    },
    "Keys": {
      "Word": 3447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "被捕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèibǔ",
      "PrimaryDefinition": "to be arrested, under arrest"
    },
    "Keys": {
      "Word": 5560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "faint sound, whisper"
    },
    "Keys": {
      "Hanzi": 6184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6184,
      "Radical": "穴",
      "FrequencyRank": 4963,
      "RadicalIndex": "116.11",
      "StrokeCount": 16
    }
  },
  "戒备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièbèi",
      "PrimaryDefinition": "to take precautions, to guard against (emergency)"
    },
    "Keys": {
      "Word": 7546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鉴于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànyú",
      "PrimaryDefinition": "conj.: in view of, seeing that"
    },
    "Keys": {
      "Word": 7439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "覆盖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùgài",
      "PrimaryDefinition": "to cover"
    },
    "Keys": {
      "Word": 6648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng shì",
      "PrimaryDefinition": "go/appear on market"
    },
    "Keys": {
      "Word": 4995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "下坠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàzhuì",
      "PrimaryDefinition": "Fall"
    },
    "Keys": {
      "Word": 9876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甜美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiánměi",
      "PrimaryDefinition": "sweet, pleasant, happy"
    },
    "Keys": {
      "Word": 9487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䏡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "uncooked or raw meat, an organic compound; Proteose"
    },
    "Keys": {
      "Hanzi": 6865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6865,
      "Radical": "肉",
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "拾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "pick up, collect, tidy up; accounting form of the numeral ten"
    },
    "Keys": {
      "Hanzi": 1386,
      "Word": 3904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 5,
      "GeneralStandard": 1386,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1961,
      "StrokeCount": 9
    }
  },
  "欠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "lack"
    },
    "Keys": {
      "Hanzi": 153,
      "Radical": 76,
      "Word": 3804
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "76",
      "Grade": 5,
      "GeneralStandard": 153,
      "Radical": "欠",
      "HSK": 5,
      "FrequencyRank": 1948,
      "StrokeCount": 4
    }
  },
  "道歉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào qiàn",
      "PrimaryDefinition": "apologize"
    },
    "Keys": {
      "Word": 4496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "赕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎn",
      "PrimaryDefinition": "fine"
    },
    "Keys": {
      "Hanzi": 5177
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5177,
      "Radical": "貝",
      "FrequencyRank": 6924,
      "RadicalIndex": "154.8",
      "StrokeCount": 12
    }
  },
  "比赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐsài",
      "PrimaryDefinition": "match, competition"
    },
    "Keys": {
      "Word": 1295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "寻求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnqiú",
      "PrimaryDefinition": "seek, explore"
    },
    "Keys": {
      "Word": 4080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "解读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiědú",
      "PrimaryDefinition": "to decipher, to decode, to interpret"
    },
    "Keys": {
      "Word": 7535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "红茶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngchá",
      "PrimaryDefinition": "tea, bohea, black_tea, black tea"
    },
    "Keys": {
      "Word": 1548
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "举措": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔcuò",
      "PrimaryDefinition": "to move, to act, action, decision, conduct, manner"
    },
    "Keys": {
      "Word": 7688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuǐ",
      "PrimaryDefinition": "lustre of gems; glitter; shine"
    },
    "Keys": {
      "Hanzi": 5853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5853,
      "Radical": "玉",
      "FrequencyRank": 4692,
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "真理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnlǐ",
      "PrimaryDefinition": "god, true, gospel truth, verity, true statement, truism, gospel, truth, veritas"
    },
    "Keys": {
      "Word": 4203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "王国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wángguó",
      "PrimaryDefinition": "kingdom, realm, domain"
    },
    "Keys": {
      "Word": 9668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瀵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8069,
      "Radical": "水",
      "RadicalIndex": "85.17",
      "StrokeCount": 20
    }
  },
  "仪器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíqì",
      "PrimaryDefinition": "instrumentation, device, setup, apparatus, instrumental, instrument, armamentarium"
    },
    "Keys": {
      "Word": 5236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "哪些": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎxiē",
      "PrimaryDefinition": "det.: which"
    },
    "Keys": {
      "Word": 249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "壤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎng",
      "PrimaryDefinition": "soil, loam, earth; rich"
    },
    "Keys": {
      "Hanzi": 3474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.17",
      "GeneralStandard": 3474,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 2412,
      "StrokeCount": 20
    }
  },
  "旿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6673,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "匍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "crawl; lie prostrate"
    },
    "Keys": {
      "Hanzi": 4290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4290,
      "Radical": "勹",
      "FrequencyRank": 4202,
      "RadicalIndex": "20.7",
      "StrokeCount": 9
    }
  },
  "岳母": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèmǔ",
      "PrimaryDefinition": "wife's mother, mother-in-law"
    },
    "Keys": {
      "Word": 10565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "见证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànzhèng",
      "PrimaryDefinition": "to be witness to, witness, evidence"
    },
    "Keys": {
      "Word": 7423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pù",
      "PrimaryDefinition": "sun, air in sun, expose or dry in the sun"
    },
    "Keys": {
      "Hanzi": 3457
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.15",
      "GeneralStandard": 3457,
      "Radical": "日",
      "HSK": 7,
      "FrequencyRank": 3431,
      "StrokeCount": 19
    }
  },
  "念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàn",
      "PrimaryDefinition": "think of, recall, study"
    },
    "Keys": {
      "Hanzi": 1177,
      "Word": 1769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "Grade": 3,
      "GeneralStandard": 1177,
      "Radical": "心",
      "HSK": 3,
      "FrequencyRank": 477,
      "StrokeCount": 8
    }
  },
  "诔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "eulogize, praise the dead"
    },
    "Keys": {
      "Hanzi": 4039
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4039,
      "Radical": "言",
      "FrequencyRank": 5227,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "廋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "conceal, hide; search for, seek"
    },
    "Keys": {
      "Hanzi": 7415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7415,
      "Radical": "广",
      "RadicalIndex": "53.1",
      "StrokeCount": 12
    }
  },
  "圣诞节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Shèngdàn Jié",
      "PrimaryDefinition": "Christmas time, Christmas season, Christmas"
    },
    "Keys": {
      "Word": 5013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "煃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǐ",
      "PrimaryDefinition": "remove, get rid of; scatter"
    },
    "Keys": {
      "Hanzi": 7582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7582,
      "Radical": "火",
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "遘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "to meet; to come across"
    },
    "Keys": {
      "Hanzi": 7475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7475,
      "Radical": "辵",
      "FrequencyRank": 7177,
      "RadicalIndex": "162.1",
      "StrokeCount": 13
    }
  },
  "择": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "select, choose, pick out"
    },
    "Keys": {
      "Hanzi": 992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 992,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 961,
      "StrokeCount": 8
    }
  },
  "堧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruán",
      "PrimaryDefinition": "adjoin"
    },
    "Keys": {
      "Hanzi": 7293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7293,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "论文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùnwén",
      "PrimaryDefinition": "disquisition, memoir, paper, discourse, theme, treatise, thesis, article, tractate, dissertation"
    },
    "Keys": {
      "Word": 2720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "颡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎng",
      "PrimaryDefinition": "the forehead; to kowtow"
    },
    "Keys": {
      "Hanzi": 6191
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6191,
      "Radical": "頁",
      "FrequencyRank": 6253,
      "RadicalIndex": "181.1",
      "StrokeCount": 16
    }
  },
  "袆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "ceremonial gowns of a queen"
    },
    "Keys": {
      "Hanzi": 6903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6903,
      "Radical": "衣",
      "FrequencyRank": 6846,
      "RadicalIndex": "145.4",
      "StrokeCount": 9
    }
  },
  "波": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "waves, breakers; undulations"
    },
    "Keys": {
      "Hanzi": 1261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1261,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 664,
      "StrokeCount": 8
    }
  },
  "翩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piān",
      "PrimaryDefinition": "fly, flutter"
    },
    "Keys": {
      "Hanzi": 3305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3305,
      "Radical": "羽",
      "FrequencyRank": 3234,
      "RadicalIndex": "124.9",
      "StrokeCount": 15
    }
  },
  "切身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qièshēn",
      "PrimaryDefinition": "direct, concerning oneself, personal"
    },
    "Keys": {
      "Word": 8711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "jewel, gem; glitter of gems"
    },
    "Keys": {
      "Hanzi": 5051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5051,
      "Radical": "玉",
      "FrequencyRank": 5132,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "二手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èrshǒu",
      "PrimaryDefinition": "indirectly acquired, second-hand (information, equipment etc), assistant"
    },
    "Keys": {
      "Word": 2442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "滠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": "river in Hubei province"
    },
    "Keys": {
      "Hanzi": 7587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7587,
      "Radical": "水",
      "FrequencyRank": 7100,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "半决赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànjuésài",
      "PrimaryDefinition": "semifinals"
    },
    "Keys": {
      "Word": 4298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "锣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "gong"
    },
    "Keys": {
      "Hanzi": 2939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2939,
      "Radical": "金",
      "FrequencyRank": 3162,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "耥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāng",
      "PrimaryDefinition": "farm tool"
    },
    "Keys": {
      "Hanzi": 5620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5620,
      "Radical": "耒",
      "FrequencyRank": 8309,
      "RadicalIndex": "127.8",
      "StrokeCount": 14
    }
  },
  "匹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "bolt of cloth; counter for horses"
    },
    "Keys": {
      "Hanzi": 99,
      "Word": 3778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "23.2",
      "Grade": 5,
      "GeneralStandard": 99,
      "Radical": "匸",
      "HSK": 5,
      "FrequencyRank": 1908,
      "StrokeCount": 4
    }
  },
  "客厅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kètīng",
      "PrimaryDefinition": "front room, livingroom, parlour, salon, family room, parlor, drawing_room, living_room, drawing room, sitting_room, living room, sitting room, drawing, drawing/living room"
    },
    "Keys": {
      "Word": 3664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鹠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "the owl"
    },
    "Keys": {
      "Hanzi": 7805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7805,
      "Radical": "鳥",
      "FrequencyRank": 9349,
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "股东": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔdōng",
      "PrimaryDefinition": "raider, shareholder, interest, old fogy, shareowner, curio, antique, partner, stockholder, interest group"
    },
    "Keys": {
      "Word": 4633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "自学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìxué",
      "PrimaryDefinition": "self-study, to study on one's own"
    },
    "Keys": {
      "Word": 5396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "矮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎi",
      "PrimaryDefinition": "short, dwarf; low"
    },
    "Keys": {
      "Hanzi": 2946,
      "Word": 2230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "111.8",
      "Grade": 4,
      "GeneralStandard": 2946,
      "Radical": "矢",
      "HSK": 4,
      "FrequencyRank": 2027,
      "StrokeCount": 13
    }
  },
  "钙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "calcium"
    },
    "Keys": {
      "Hanzi": 1525,
      "Word": 6659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "Grade": 7,
      "GeneralStandard": 1525,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 2951,
      "StrokeCount": 9
    }
  },
  "大名鼎鼎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàmíng-dǐngdǐng",
      "PrimaryDefinition": "grand reputation, renowned, famous"
    },
    "Keys": {
      "Word": 6125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "to burn, set afire; to illuminate; a signal lamp"
    },
    "Keys": {
      "Hanzi": 6170
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6170,
      "Radical": "火",
      "FrequencyRank": 4041,
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "沽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "buy and sell; inferior in quality"
    },
    "Keys": {
      "Hanzi": 1243
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1243,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3492,
      "StrokeCount": 8
    }
  },
  "表彰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎozhāng",
      "PrimaryDefinition": "to honor, to commend, to cite (in dispatches)"
    },
    "Keys": {
      "Word": 5634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "lizard"
    },
    "Keys": {
      "Hanzi": 5921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5921,
      "Radical": "虫",
      "FrequencyRank": 6148,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "笔试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐshì",
      "PrimaryDefinition": "written examination, paper test (for an applicant)"
    },
    "Keys": {
      "Word": 4325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "甜蜜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiánmì",
      "PrimaryDefinition": "sweet, happy"
    },
    "Keys": {
      "Word": 9488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "building of two or more stories"
    },
    "Keys": {
      "Hanzi": 2870,
      "Word": 215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.9",
      "Grade": 1,
      "GeneralStandard": 2870,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 876,
      "StrokeCount": 13
    }
  },
  "深深": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnshēn",
      "PrimaryDefinition": "deep, profound"
    },
    "Keys": {
      "Word": 5006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "乐园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèyuán",
      "PrimaryDefinition": "Elysian Fields, paradise, Valhalla, Eden, playground, Elysium, fairyland"
    },
    "Keys": {
      "Word": 7957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "grave, tomb, cemetery"
    },
    "Keys": {
      "Hanzi": 3870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3870,
      "Radical": "艸",
      "FrequencyRank": 5697,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "缪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "wind around, bind; prepare"
    },
    "Keys": {
      "Hanzi": 5847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5847,
      "Radical": "糸",
      "FrequencyRank": 3286,
      "RadicalIndex": "120.11",
      "StrokeCount": 14
    }
  },
  "兀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "to cut off the feet"
    },
    "Keys": {
      "Hanzi": 3503
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3503,
      "Radical": "儿",
      "FrequencyRank": 2859,
      "RadicalIndex": "10.1",
      "StrokeCount": 3
    }
  },
  "电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "electricity; electric; lightning"
    },
    "Keys": {
      "Hanzi": 242,
      "Word": 77
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73.1",
      "Grade": 1,
      "GeneralStandard": 242,
      "Radical": "曰",
      "HSK": 1,
      "FrequencyRank": 230,
      "StrokeCount": 5
    }
  },
  "发觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fājué",
      "PrimaryDefinition": "light upon, discovery, find, see, espy, discover, perceive, strike, come upon, detection, come across, happen upon, detect, chance upon, chance on"
    },
    "Keys": {
      "Word": 3443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "小说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoshuō",
      "PrimaryDefinition": "novel, offprint, fiction"
    },
    "Keys": {
      "Word": 1093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "全长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quáncháng",
      "PrimaryDefinition": "overall length, span"
    },
    "Keys": {
      "Word": 8802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迎来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínglái",
      "PrimaryDefinition": "Ushered"
    },
    "Keys": {
      "Word": 5259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一般": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yībān",
      "PrimaryDefinition": "general, ordinary, common"
    },
    "Keys": {
      "Word": 1149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "游客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóukè",
      "PrimaryDefinition": "traveler, tourist, (online gaming) guest player"
    },
    "Keys": {
      "Word": 1174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "私有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīyǒu",
      "PrimaryDefinition": "private, privately-owned"
    },
    "Keys": {
      "Word": 9314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "关于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānyú",
      "PrimaryDefinition": "about, with regard to, concerning"
    },
    "Keys": {
      "Word": 2517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "发育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāyù",
      "PrimaryDefinition": "grow, develop"
    },
    "Keys": {
      "Word": 6447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "to wake up from sleep; conscious"
    },
    "Keys": {
      "Hanzi": 1694,
      "Word": 4727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "147.5",
      "Grade": 6,
      "GeneralStandard": 1694,
      "Radical": "見",
      "HSK": 1,
      "FrequencyRank": 327,
      "StrokeCount": 9
    }
  },
  "镇定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèndìng",
      "PrimaryDefinition": "calm, unperturbed, cool"
    },
    "Keys": {
      "Word": 10714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㬎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "(an ancient form of 顯) motes in a sunbeam, bright, fibrous, to manifest; to display, to be illustrious, evident, to seem; to appear, cocoons; chrysalis, will not have a pleasant conversation"
    },
    "Keys": {
      "Hanzi": 7672
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7672,
      "Radical": "日",
      "RadicalIndex": "72.1",
      "StrokeCount": 14
    }
  },
  "激光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīguāng",
      "PrimaryDefinition": "laser"
    },
    "Keys": {
      "Word": 7280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "节日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiérì",
      "PrimaryDefinition": "festival, holiday"
    },
    "Keys": {
      "Word": 783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蠖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "inch-worm; looper caterpiller"
    },
    "Keys": {
      "Hanzi": 6390
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6390,
      "Radical": "虫",
      "FrequencyRank": 5010,
      "RadicalIndex": "142.14",
      "StrokeCount": 19
    }
  },
  "宕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": "stone quarry; cave dwelling"
    },
    "Keys": {
      "Hanzi": 4035
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4035,
      "Radical": "宀",
      "FrequencyRank": 4231,
      "RadicalIndex": "40.5",
      "StrokeCount": 8
    }
  },
  "婀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ē",
      "PrimaryDefinition": "be beautiful, be graceful"
    },
    "Keys": {
      "Hanzi": 4696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4696,
      "Radical": "女",
      "FrequencyRank": 4585,
      "RadicalIndex": "38.8",
      "StrokeCount": 10
    }
  },
  "绝大多数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juédàduōshù",
      "PrimaryDefinition": "det.: most of, majority"
    },
    "Keys": {
      "Word": 4774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "纤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "fine, delicate; minute; graceful"
    },
    "Keys": {
      "Hanzi": 591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.3",
      "GeneralStandard": 591,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 2106,
      "StrokeCount": 6
    }
  },
  "妁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "act as go-between"
    },
    "Keys": {
      "Hanzi": 3642
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3642,
      "Radical": "女",
      "FrequencyRank": 6334,
      "RadicalIndex": "38.3",
      "StrokeCount": 6
    }
  },
  "跳水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàoshuǐ",
      "PrimaryDefinition": "dive"
    },
    "Keys": {
      "Word": 5086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "笼统": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒngtǒng",
      "PrimaryDefinition": "general, broad, sweeping, lacking in detail, vague"
    },
    "Keys": {
      "Word": 8120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睡眠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuìmián",
      "PrimaryDefinition": "sleep"
    },
    "Keys": {
      "Word": 3940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "疗效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáoxiào",
      "PrimaryDefinition": "curative effect"
    },
    "Keys": {
      "Word": 8042
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鼻涕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bítì",
      "PrimaryDefinition": "nasal mucus, snivel"
    },
    "Keys": {
      "Word": 5581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "解脱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiětuō",
      "PrimaryDefinition": "free (extricate) oneself, release from worldly cares"
    },
    "Keys": {
      "Word": 7540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "征收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngshōu",
      "PrimaryDefinition": "levy, collect"
    },
    "Keys": {
      "Word": 10723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "归纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīnà",
      "PrimaryDefinition": "sum up, conclude, induce"
    },
    "Keys": {
      "Word": 6912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "recklessly, foolishly; wildly"
    },
    "Keys": {
      "Hanzi": 1419
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "GeneralStandard": 1419,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 806,
      "StrokeCount": 9
    }
  },
  "正版": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngbǎn",
      "PrimaryDefinition": "genuine, legal, see also 盜版|盗版[dao4 ban3]"
    },
    "Keys": {
      "Word": 4207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "一齐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīqí",
      "PrimaryDefinition": "simul, in unison, together, simultaneously"
    },
    "Keys": {
      "Word": 5243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "开玩笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi wánxiào",
      "PrimaryDefinition": "kid, spoof, jive, gag, make fun of, josh, daff, rot, quip, chaff, rib, banter, jolly, fun, sport, put_on, clown, fool, droll, jape, joke, skylark, jest, jocose, crack a joke"
    },
    "Keys": {
      "Word": 188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "司空见惯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīkōng-jiànguàn",
      "PrimaryDefinition": "a common occurrence (idiom)"
    },
    "Keys": {
      "Word": 9302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "殚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "utmost, entirely, quite; use up"
    },
    "Keys": {
      "Hanzi": 5113
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5113,
      "Radical": "歹",
      "FrequencyRank": 5170,
      "RadicalIndex": "78.8",
      "StrokeCount": 12
    }
  },
  "䓖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qióng",
      "PrimaryDefinition": "(simplified form of 藭) Cnidium officinale, a kind of medicinal herb"
    },
    "Keys": {
      "Hanzi": 6955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6955,
      "Radical": "艸",
      "FrequencyRank": 9764,
      "RadicalIndex": "140.7",
      "StrokeCount": 13
    }
  },
  "实行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíxíng",
      "PrimaryDefinition": "put into effect, fulfil, put ... into practice, practise, practice, put into practice/effect, live, pass, obtain, prosecute, cause, phase, meet, carry_out, pull, effect, operate, carry out, actualize, use, effectuate, put ... into effect, perform, apply, put into practice, run, implement, execute, do, make, wage, implementation"
    },
    "Keys": {
      "Word": 1899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "杀害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāhài",
      "PrimaryDefinition": "cut_down, liquidate, killing, kill, slay, cut_off, grease, remove, murder, destroy, do in, waste, ice, knock off"
    },
    "Keys": {
      "Word": 8932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "指点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐdiǎn",
      "PrimaryDefinition": "to point out, to indicate, to give directions, to show how (to do sth), to censure, to pick at"
    },
    "Keys": {
      "Word": 10772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "受理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòulǐ",
      "PrimaryDefinition": "accept and hear a case"
    },
    "Keys": {
      "Word": 9199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "precedent, example; regulation"
    },
    "Keys": {
      "Hanzi": 1140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1140,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 547,
      "StrokeCount": 8
    }
  },
  "妲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "concubine of last ruler of the Shang dynasty"
    },
    "Keys": {
      "Hanzi": 4064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4064,
      "Radical": "女",
      "FrequencyRank": 4830,
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "圮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "destroyed, ruined; to subvert to injure"
    },
    "Keys": {
      "Hanzi": 3576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3576,
      "Radical": "土",
      "FrequencyRank": 5444,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "捕捉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔzhuō",
      "PrimaryDefinition": "seizure, seize, tackle, capture, snare, nobble, get, catch"
    },
    "Keys": {
      "Word": 5687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暂停": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàntíng",
      "PrimaryDefinition": "suspense, suspended, suspend, intermit, set aside, discontinue, remit, stop, suspension, pause, break, time-out"
    },
    "Keys": {
      "Word": 4174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "昒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6676,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "捩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "twist with hands; snap, tear"
    },
    "Keys": {
      "Hanzi": 4738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4738,
      "Radical": "手",
      "FrequencyRank": 5827,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "䴘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tī",
      "PrimaryDefinition": "a kind of bird"
    },
    "Keys": {
      "Hanzi": 7795
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7795,
      "Radical": "",
      "FrequencyRank": 6933,
      "RadicalIndex": "",
      "StrokeCount": 21
    }
  },
  "嘲笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháoxiào",
      "PrimaryDefinition": "to jeer at, to deride, to ridicule, mockery, derision"
    },
    "Keys": {
      "Word": 5834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "郛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "outer walls of city; suburbs"
    },
    "Keys": {
      "Hanzi": 4277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4277,
      "Radical": "邑",
      "FrequencyRank": 5069,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "良性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángxìng",
      "PrimaryDefinition": "plus, benign, positive"
    },
    "Keys": {
      "Word": 8032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "创始人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngshǐrén",
      "PrimaryDefinition": "creator, founder, initiator"
    },
    "Keys": {
      "Word": 6020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爱惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àixī",
      "PrimaryDefinition": "cherish, treasure"
    },
    "Keys": {
      "Word": 5434
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "cook down, to boil; endure"
    },
    "Keys": {
      "Hanzi": 3053,
      "Word": 5451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.11",
      "Grade": 7,
      "GeneralStandard": 3053,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 2705,
      "StrokeCount": 14
    }
  },
  "脟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7212,
      "Radical": "肉",
      "FrequencyRank": 9477,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "惯例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guànlì",
      "PrimaryDefinition": "custom, pattern, practice, manner, tradition, routine, law, wont, habit, codex, ritual, convention, convenance, consuetude, usual practice, rule, use, observance, institution, modus operandi, precedent, conventionality, rite, praxis, natural law"
    },
    "Keys": {
      "Word": 6891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guān",
      "PrimaryDefinition": "see, observe, view; appearance"
    },
    "Keys": {
      "Hanzi": 586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "147.2",
      "GeneralStandard": 586,
      "Radical": "見",
      "HSK": 2,
      "FrequencyRank": 334,
      "StrokeCount": 6
    }
  },
  "从容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngróng",
      "PrimaryDefinition": "calm, unhurried, leisurely"
    },
    "Keys": {
      "Word": 6059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "激励": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīlì",
      "PrimaryDefinition": "encourage, impel, urge"
    },
    "Keys": {
      "Word": 7283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "药方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàofāng",
      "PrimaryDefinition": "prescription"
    },
    "Keys": {
      "Word": 10242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "concourse; flow together, gather"
    },
    "Keys": {
      "Hanzi": 309,
      "Word": 2560
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.2",
      "Grade": 4,
      "GeneralStandard": 309,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1187,
      "StrokeCount": 5
    }
  },
  "夸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuā",
      "PrimaryDefinition": "extravagant, luxurious; handsome"
    },
    "Keys": {
      "Hanzi": 395,
      "Word": 7866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.3",
      "Grade": 7,
      "GeneralStandard": 395,
      "Radical": "大",
      "HSK": 7,
      "FrequencyRank": 1955,
      "StrokeCount": 6
    }
  },
  "懂事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒng shì",
      "PrimaryDefinition": "sensible, thoughtful, intelligent"
    },
    "Keys": {
      "Word": 6329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò jìng",
      "PrimaryDefinition": "to pass through a country's territory, transit"
    },
    "Keys": {
      "Word": 6948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "单薄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānbó",
      "PrimaryDefinition": "weak, frail, thin, flimsy"
    },
    "Keys": {
      "Word": 6159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卤味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔwèi",
      "PrimaryDefinition": "food prepared by stewing in soy sauce and spices, variant of 滷味|卤味[lu3 wei4]"
    },
    "Keys": {
      "Word": 8125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diē",
      "PrimaryDefinition": "father, daddy"
    },
    "Keys": {
      "Hanzi": 1985,
      "Word": 6295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "88.6",
      "Grade": 7,
      "GeneralStandard": 1985,
      "Radical": "父",
      "HSK": 7,
      "FrequencyRank": 2004,
      "StrokeCount": 10
    }
  },
  "占领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànlǐng",
      "PrimaryDefinition": "to occupy (a territory), to hold"
    },
    "Keys": {
      "Word": 4188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "锄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "hoe; eradicate"
    },
    "Keys": {
      "Hanzi": 2661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2661,
      "Radical": "金",
      "FrequencyRank": 3545,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "猷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "plan, scheme; plan, plot; way"
    },
    "Keys": {
      "Hanzi": 5558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5558,
      "Radical": "犬",
      "FrequencyRank": 4934,
      "RadicalIndex": "94.9",
      "StrokeCount": 13
    }
  },
  "安逸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānyì",
      "PrimaryDefinition": "easy and comfortable, easy"
    },
    "Keys": {
      "Word": 5443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "休假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū jià",
      "PrimaryDefinition": "have holiday/vacation/leave"
    },
    "Keys": {
      "Word": 1118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "争分夺秒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngfēn-duómiǎo",
      "PrimaryDefinition": "lit. fight minutes, snatch seconds (idiom), a race against time, making every second count"
    },
    "Keys": {
      "Word": 10717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quántǐ",
      "PrimaryDefinition": "great, entirety, general, ensemble, altogether, collectivity, entire, totality, whole body, whole, all, pie, crew, be-all, plenary assembly, en_bloc, wholeness, gamut"
    },
    "Keys": {
      "Word": 949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "伤痕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānghén",
      "PrimaryDefinition": "scar, bruise"
    },
    "Keys": {
      "Word": 8962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咳嗽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "késou",
      "PrimaryDefinition": "to cough, CL:陣|阵[zhen4]"
    },
    "Keys": {
      "Word": 7792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "传记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuànjì",
      "PrimaryDefinition": "biography, CL:篇[pian1],部[bu4]"
    },
    "Keys": {
      "Word": 10899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "栟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēn",
      "PrimaryDefinition": "hemp palm"
    },
    "Keys": {
      "Hanzi": 4461
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4461,
      "Radical": "木",
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "下面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàmian",
      "PrimaryDefinition": "next, lower level, underside, following, underneath, subordinate, undersurface"
    },
    "Keys": {
      "Word": 2031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "持久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chíjiǔ",
      "PrimaryDefinition": "lasting, enduring, persistent, permanent, protracted, endurance, persistence, to last long"
    },
    "Keys": {
      "Word": 5905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǘ",
      "PrimaryDefinition": "donkey, ass"
    },
    "Keys": {
      "Hanzi": 941
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 941,
      "Radical": "馬",
      "FrequencyRank": 2543,
      "RadicalIndex": "187.4",
      "StrokeCount": 7
    }
  },
  "正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng",
      "PrimaryDefinition": "right, proper, correct"
    },
    "Keys": {
      "Hanzi": 198,
      "Word": 2150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "77.1",
      "Grade": 3,
      "GeneralStandard": 198,
      "Radical": "止",
      "HSK": 1,
      "FrequencyRank": 129,
      "StrokeCount": 5
    }
  },
  "翻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān",
      "PrimaryDefinition": "flip over, upset, capsize"
    },
    "Keys": {
      "Hanzi": 3445,
      "Word": 2453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "124.12",
      "Grade": 4,
      "GeneralStandard": 3445,
      "Radical": "羽",
      "HSK": 4,
      "FrequencyRank": 1027,
      "StrokeCount": 18
    }
  },
  "谋求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móuqiú",
      "PrimaryDefinition": "go for, be in quest of, seek to gain, strive for, endeavor, try for, seek"
    },
    "Keys": {
      "Word": 8337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "来自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láizì",
      "PrimaryDefinition": "come from, originate from, come, stem/originate from, attach_to, stem, come/stem/originate from, hail"
    },
    "Keys": {
      "Word": 828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "谈话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tánhuà",
      "PrimaryDefinition": "talk, speech"
    },
    "Keys": {
      "Word": 1950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "裁判": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáipàn",
      "PrimaryDefinition": "judge, referee, umpire"
    },
    "Keys": {
      "Word": 3291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "背后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèihòu",
      "PrimaryDefinition": "rear, in the rear, behind, rearward, at the back, backside, back end"
    },
    "Keys": {
      "Word": 1289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "陌生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòshēng",
      "PrimaryDefinition": "strange, unfamiliar"
    },
    "Keys": {
      "Word": 8328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兜儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōur5",
      "PrimaryDefinition": "pocket, bag, to wrap up or hold in a bag, to move in a circle, to canvas or solicit, to take responsibility for, to disclose in detail, combat armor (old), erhua variant of 兜[dou1]"
    },
    "Keys": {
      "Word": 6345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "点心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnxin",
      "PrimaryDefinition": "light refreshments, pastry, dimsum (in Cantonese cooking), dessert"
    },
    "Keys": {
      "Word": 6269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脱落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōluò",
      "PrimaryDefinition": "to drop off, to come off, to lose (hair etc), to omit (a character when writing)"
    },
    "Keys": {
      "Word": 9619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāng",
      "PrimaryDefinition": "arsenic"
    },
    "Keys": {
      "Hanzi": 8091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8091,
      "Radical": "石",
      "RadicalIndex": "112.17",
      "StrokeCount": 22
    }
  },
  "嘱咐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔfu",
      "PrimaryDefinition": "to tell, to exhort, injunction"
    },
    "Keys": {
      "Word": 10863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "surname; consent, approve"
    },
    "Keys": {
      "Hanzi": 4272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4272,
      "Radical": "人",
      "FrequencyRank": 3251,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "琥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǔ",
      "PrimaryDefinition": "jewel in shape of tiger; amber"
    },
    "Keys": {
      "Hanzi": 5048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5048,
      "Radical": "玉",
      "FrequencyRank": 4456,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "打折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ zhé",
      "PrimaryDefinition": "to give a discount"
    },
    "Keys": {
      "Word": 2365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "潜力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánlì",
      "PrimaryDefinition": "bottom, possibility, ability, potency, capability, capableness, potentiality, potential, potential(ity), latent capacity"
    },
    "Keys": {
      "Word": 4931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "农产品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngchǎnpǐn",
      "PrimaryDefinition": "agricultural products, farm produce"
    },
    "Keys": {
      "Word": 3751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "绝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "to cut, sever, break off, terminate"
    },
    "Keys": {
      "Hanzi": 1762,
      "Word": 4773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "Grade": 6,
      "GeneralStandard": 1762,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 562,
      "StrokeCount": 9
    }
  },
  "否定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǒudìng",
      "PrimaryDefinition": "negate, deny"
    },
    "Keys": {
      "Word": 1458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鲤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": "carp"
    },
    "Keys": {
      "Hanzi": 3276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3276,
      "Radical": "魚",
      "FrequencyRank": 3204,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "严重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánzhòng",
      "PrimaryDefinition": "serious, significant, acute, critical, severe, grave"
    },
    "Keys": {
      "Word": 3066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "筹办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóubàn",
      "PrimaryDefinition": "to arrange, to make preparations"
    },
    "Keys": {
      "Word": 5940
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趁机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chènjī",
      "PrimaryDefinition": "seizing the chance, take advantage of occasion, taking advantage of the occasion, seize chance"
    },
    "Keys": {
      "Word": 5862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng",
      "PrimaryDefinition": "laud, acclaim; hymn; ode"
    },
    "Keys": {
      "Hanzi": 1991
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.4",
      "GeneralStandard": 1991,
      "Radical": "頁",
      "HSK": 9,
      "FrequencyRank": 2414,
      "StrokeCount": 10
    }
  },
  "告状": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gào zhuàng",
      "PrimaryDefinition": "to tell on sb, to complain (to a teacher, a superior etc), to bring a lawsuit"
    },
    "Keys": {
      "Word": 6717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huítóu",
      "PrimaryDefinition": "turn one's head, turn round, repent"
    },
    "Keys": {
      "Word": 3561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "商业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngyè",
      "PrimaryDefinition": "business enterprise, commercial, people, mercantilism, commercial pursuit, commercial enterprise, business sector, merchantry, trade, mercantile pursuit, business, commerce"
    },
    "Keys": {
      "Word": 1866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "谟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "scheme, plan; plan; be without"
    },
    "Keys": {
      "Hanzi": 5303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5303,
      "Radical": "言",
      "FrequencyRank": 3172,
      "RadicalIndex": "149.1",
      "StrokeCount": 12
    }
  },
  "既": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "already; de facto; since; then"
    },
    "Keys": {
      "Hanzi": 1721,
      "Word": 2591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "71.5",
      "Grade": 4,
      "GeneralStandard": 1721,
      "Radical": "无",
      "HSK": 4,
      "FrequencyRank": 724,
      "StrokeCount": 9
    }
  },
  "揉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róu",
      "PrimaryDefinition": "rub, massage; crush by hand"
    },
    "Keys": {
      "Hanzi": 2540,
      "Word": 8894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2540,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2818,
      "StrokeCount": 12
    }
  },
  "期间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qījiān",
      "PrimaryDefinition": "length, period, time period, amount of time, duration, course, interim, period of time, time, standing, chapter, tract, space, term, streak, efflux"
    },
    "Keys": {
      "Word": 2790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蜚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "cockroach"
    },
    "Keys": {
      "Hanzi": 5687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5687,
      "Radical": "虫",
      "FrequencyRank": 4632,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "批": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "comment, criticize; wholesale"
    },
    "Keys": {
      "Hanzi": 622,
      "Word": 2777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 4,
      "GeneralStandard": 622,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 569,
      "StrokeCount": 7
    }
  },
  "了解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎojiě",
      "PrimaryDefinition": "understand, comprehend, find out, acquaint oneself with"
    },
    "Keys": {
      "Word": 2704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "记号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìhao",
      "PrimaryDefinition": "earmark, symbolism, reader, impress, indicium, symbol, notation, tick, token, marker, type, sign, score, signature, mark, chip, character, seal, crack, marking, dent, ostent, indicia, check, impression"
    },
    "Keys": {
      "Word": 7319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāi",
      "PrimaryDefinition": "decline, falter, decrease; weaken"
    },
    "Keys": {
      "Hanzi": 2019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.4",
      "GeneralStandard": 2019,
      "Radical": "衣",
      "HSK": 8,
      "FrequencyRank": 1702,
      "StrokeCount": 10
    }
  },
  "好像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoxiàng",
      "PrimaryDefinition": "seem, be like"
    },
    "Keys": {
      "Word": 714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "炙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "roast, broil; toast; cauterize"
    },
    "Keys": {
      "Hanzi": 3990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3990,
      "Radical": "火",
      "FrequencyRank": 3672,
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "韪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "right; proper; perpriety"
    },
    "Keys": {
      "Hanzi": 5433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5433,
      "Radical": "韋",
      "FrequencyRank": 5229,
      "RadicalIndex": "178.9",
      "StrokeCount": 13
    }
  },
  "户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "door"
    },
    "Keys": {
      "Hanzi": 170,
      "Radical": 63,
      "Word": 2549
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "63",
      "Grade": 4,
      "GeneralStandard": 170,
      "Radical": "戶",
      "HSK": 4,
      "FrequencyRank": 801,
      "StrokeCount": 4
    }
  },
  "笔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "writing brush; write; stroke"
    },
    "Keys": {
      "Hanzi": 1951,
      "Word": 517
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.4",
      "Grade": 2,
      "GeneralStandard": 1951,
      "Radical": "竹",
      "HSK": 2,
      "FrequencyRank": 956,
      "StrokeCount": 10
    }
  },
  "勤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "industrious, diligent, attentive"
    },
    "Keys": {
      "Hanzi": 2848
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.11",
      "GeneralStandard": 2848,
      "Radical": "力",
      "HSK": 5,
      "FrequencyRank": 1565,
      "StrokeCount": 13
    }
  },
  "闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "hear; smell; make known; news"
    },
    "Keys": {
      "Hanzi": 1636,
      "Word": 1064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.6",
      "Grade": 2,
      "GeneralStandard": 1636,
      "Radical": "門",
      "HSK": 2,
      "FrequencyRank": 825,
      "StrokeCount": 9
    }
  },
  "郑重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngzhòng",
      "PrimaryDefinition": "serious, solemn"
    },
    "Keys": {
      "Word": 10738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "狭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "narrow, limited; narrow-minded"
    },
    "Keys": {
      "Hanzi": 1600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.6",
      "GeneralStandard": 1600,
      "Radical": "犬",
      "HSK": 9,
      "FrequencyRank": 2018,
      "StrokeCount": 9
    }
  },
  "刷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuā",
      "PrimaryDefinition": "brush; clean with brush, scrub"
    },
    "Keys": {
      "Hanzi": 1310,
      "Word": 2892
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 4,
      "GeneralStandard": 1310,
      "Radical": "刀",
      "HSK": 4,
      "FrequencyRank": 1824,
      "StrokeCount": 8
    }
  },
  "直": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "straight, erect, vertical"
    },
    "Keys": {
      "Hanzi": 1011,
      "Word": 2160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.3",
      "Grade": 3,
      "GeneralStandard": 1011,
      "Radical": "目",
      "HSK": 2,
      "FrequencyRank": 255,
      "StrokeCount": 8
    }
  },
  "老大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎodà",
      "PrimaryDefinition": "leader, eldest child (in a family), guvnor, old, governor, boss, eldest child, master of a sailing vessel"
    },
    "Keys": {
      "Word": 7946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剪刀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎndāo",
      "PrimaryDefinition": "scissors, CL:把[ba3]"
    },
    "Keys": {
      "Word": 3599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "舷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "the sides of a boat, bulwarks; a gunwale"
    },
    "Keys": {
      "Hanzi": 4918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4918,
      "Radical": "舟",
      "FrequencyRank": 3109,
      "RadicalIndex": "137.5",
      "StrokeCount": 11
    }
  },
  "领取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngqǔ",
      "PrimaryDefinition": "draw, receive"
    },
    "Keys": {
      "Word": 4840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "工商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngshāng",
      "PrimaryDefinition": "industrial and commercial circles, business circles, industry and commerce"
    },
    "Keys": {
      "Word": 4603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "发愤图强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāfèn-túqiáng",
      "PrimaryDefinition": "to be strongly determined to succeed (idiom)"
    },
    "Keys": {
      "Word": 6436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒ",
      "PrimaryDefinition": "place, location; numerary adjunct"
    },
    "Keys": {
      "Hanzi": 1164,
      "Word": 5063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "63.4",
      "Grade": 6,
      "GeneralStandard": 1164,
      "Radical": "戶",
      "HSK": 2,
      "FrequencyRank": 54,
      "StrokeCount": 8
    }
  },
  "思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "think, consider, ponder; final particle"
    },
    "Keys": {
      "Hanzi": 1500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1500,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 298,
      "StrokeCount": 9
    }
  },
  "仡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "strong; valiant"
    },
    "Keys": {
      "Hanzi": 3544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3544,
      "Radical": "人",
      "FrequencyRank": 6006,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "巾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "towel"
    },
    "Keys": {
      "Radical": 50,
      "Hanzi": 40
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50",
      "GeneralStandard": 40,
      "Radical": "巾",
      "HSK": 4,
      "FrequencyRank": 2281,
      "StrokeCount": 3
    }
  },
  "扰乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎoluàn",
      "PrimaryDefinition": "to disturb, to perturb, to harass"
    },
    "Keys": {
      "Word": 8827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gá",
      "PrimaryDefinition": "a child toy"
    },
    "Keys": {
      "Hanzi": 4186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4186,
      "Radical": "小",
      "FrequencyRank": 9006,
      "RadicalIndex": "42.6",
      "StrokeCount": 9
    }
  },
  "斋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāi",
      "PrimaryDefinition": "vegetarian diet; study"
    },
    "Keys": {
      "Hanzi": 2029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2029,
      "Radical": "文",
      "FrequencyRank": 2404,
      "RadicalIndex": "67.6",
      "StrokeCount": 10
    }
  },
  "僵局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāngjú",
      "PrimaryDefinition": "impasse, deadlock"
    },
    "Keys": {
      "Word": 7442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "两边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎngbiān",
      "PrimaryDefinition": "either side, both sides"
    },
    "Keys": {
      "Word": 2700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "误会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùhuì",
      "PrimaryDefinition": "misunderstanding"
    },
    "Keys": {
      "Word": 2993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "夫妇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fūfù",
      "PrimaryDefinition": "conjugality, couple, man_and_wife, husband and wife"
    },
    "Keys": {
      "Word": 2477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "随": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suí",
      "PrimaryDefinition": "follow, listen to, submit to"
    },
    "Keys": {
      "Hanzi": 2476,
      "Word": 1945
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.1",
      "Grade": 3,
      "GeneralStandard": 2476,
      "Radical": "阜",
      "HSK": 2,
      "FrequencyRank": 498,
      "StrokeCount": 11
    }
  },
  "燊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "luxuriant"
    },
    "Keys": {
      "Hanzi": 7899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7899,
      "Radical": "火",
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "缧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "a chain or rope used bind criminals"
    },
    "Keys": {
      "Hanzi": 5844
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5844,
      "Radical": "糸",
      "FrequencyRank": 6075,
      "RadicalIndex": "120.11",
      "StrokeCount": 14
    }
  },
  "后代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòudài",
      "PrimaryDefinition": "descendant, posterity, descendants, later generations, ages, limb, later ages, get, descendent, later periods, offspring, later periods in history, progeny"
    },
    "Keys": {
      "Word": 7091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "繁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "complicated, complex, difficult"
    },
    "Keys": {
      "Hanzi": 3419
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.11",
      "GeneralStandard": 3419,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 1296,
      "StrokeCount": 17
    }
  },
  "平稳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngwěn",
      "PrimaryDefinition": "smooth, steady"
    },
    "Keys": {
      "Word": 2785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鼷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "a mouse"
    },
    "Keys": {
      "Hanzi": 8099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8099,
      "Radical": "鼠",
      "FrequencyRank": 6865,
      "RadicalIndex": "208.1",
      "StrokeCount": 23
    }
  },
  "围巾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéijīn",
      "PrimaryDefinition": "scarf, shawl, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 2969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "眼泪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnlèi",
      "PrimaryDefinition": "tear, teardrop, tears, eyedrop, waterworks"
    },
    "Keys": {
      "Word": 3072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "渐渐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànjiàn",
      "PrimaryDefinition": "gradually, by degrees, little by little"
    },
    "Keys": {
      "Word": 2608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "打听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎting",
      "PrimaryDefinition": "to ask about, to make some inquiries, to ask around"
    },
    "Keys": {
      "Word": 1384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "过瘾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò yǐn",
      "PrimaryDefinition": "to satisfy a craving, to get a kick out of sth, gratifying, immensely enjoyable, satisfying, fulfilling"
    },
    "Keys": {
      "Word": 6957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "unpolished rice; brown rice"
    },
    "Keys": {
      "Hanzi": 4953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4953,
      "Radical": "米",
      "FrequencyRank": 5733,
      "RadicalIndex": "119.5",
      "StrokeCount": 11
    }
  },
  "偡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7193,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "庹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǒ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4936,
      "Radical": "广",
      "FrequencyRank": 5918,
      "RadicalIndex": "53.8",
      "StrokeCount": 11
    }
  },
  "常规": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángguī",
      "PrimaryDefinition": "code of conduct, conventions, common practice, routine (medical procedure etc)"
    },
    "Keys": {
      "Word": 4391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "沓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "connected, joined; repeated"
    },
    "Keys": {
      "Hanzi": 3936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3936,
      "Radical": "水",
      "FrequencyRank": 3806,
      "RadicalIndex": "85.4",
      "StrokeCount": 8
    }
  },
  "著作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùzuò",
      "PrimaryDefinition": "composition, writing, composing, oeuvre, work, opus, literature, writings, book, production, bookmaking, body of work"
    },
    "Keys": {
      "Word": 3196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "崄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "high, steep, precipitous"
    },
    "Keys": {
      "Hanzi": 7000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7000,
      "Radical": "山",
      "RadicalIndex": "46.13",
      "StrokeCount": 10
    }
  },
  "乏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fá",
      "PrimaryDefinition": "lack; poor"
    },
    "Keys": {
      "Hanzi": 147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.4",
      "GeneralStandard": 147,
      "Radical": "丿",
      "HSK": 5,
      "FrequencyRank": 1399,
      "StrokeCount": 4
    }
  },
  "茸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rōng",
      "PrimaryDefinition": "soft, downy; buds, sprouts"
    },
    "Keys": {
      "Hanzi": 1402
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1402,
      "Radical": "艸",
      "FrequencyRank": 3343,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "孝顺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoshun",
      "PrimaryDefinition": "filial, show filial obedience"
    },
    "Keys": {
      "Word": 9979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāng",
      "PrimaryDefinition": "eagle"
    },
    "Keys": {
      "Hanzi": 8092
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8092,
      "Radical": "鳥",
      "RadicalIndex": "196.17",
      "StrokeCount": 22
    }
  },
  "配音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi yīn",
      "PrimaryDefinition": "dubbing (filmmaking)"
    },
    "Keys": {
      "Word": 8489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīn",
      "PrimaryDefinition": "respect, admire; respectful"
    },
    "Keys": {
      "Hanzi": 1532
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "GeneralStandard": 1532,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 2147,
      "StrokeCount": 9
    }
  },
  "表面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎomiàn",
      "PrimaryDefinition": "crust, superficial, facing, show, exterior, obverse, rind, face, superficies, top, outside, appearance, facade, surface, bosom, window dressing"
    },
    "Keys": {
      "Word": 1306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "统治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒngzhì",
      "PrimaryDefinition": "dominate, rule"
    },
    "Keys": {
      "Word": 9545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "膛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "chest; hollow space, cavity"
    },
    "Keys": {
      "Hanzi": 3275
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.11",
      "GeneralStandard": 3275,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 2865,
      "StrokeCount": 15
    }
  },
  "人民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénmín",
      "PrimaryDefinition": "multitude, citizen, quarter, Volk, hoi polloi, subject, people, nationals, masses, the people, mass, demotic"
    },
    "Keys": {
      "Word": 1846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "晃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎng",
      "PrimaryDefinition": "bright, dazzling; to sway, shake"
    },
    "Keys": {
      "Hanzi": 1893,
      "Word": 7194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.6",
      "Grade": 7,
      "GeneralStandard": 1893,
      "Radical": "日",
      "HSK": 7,
      "FrequencyRank": 1796,
      "StrokeCount": 10
    }
  },
  "沉迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénmí",
      "PrimaryDefinition": "to be engrossed, to be absorbed with, to lose oneself in, to be addicted to"
    },
    "Keys": {
      "Word": 5853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒu",
      "PrimaryDefinition": "plow side by side; team of two"
    },
    "Keys": {
      "Hanzi": 5849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5849,
      "Radical": "耒",
      "FrequencyRank": 4888,
      "RadicalIndex": "127.9",
      "StrokeCount": 15
    }
  },
  "抑郁症": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyùzhèng",
      "PrimaryDefinition": "clinical depression"
    },
    "Keys": {
      "Word": 10369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "流水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúshuǐ",
      "PrimaryDefinition": "presto, turnover, flowing water, turnover (in business), flowing/running water, flowing, running water"
    },
    "Keys": {
      "Word": 8105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "son-in-law; husband"
    },
    "Keys": {
      "Hanzi": 2812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.9",
      "GeneralStandard": 2812,
      "Radical": "女",
      "HSK": 8,
      "FrequencyRank": 3115,
      "StrokeCount": 12
    }
  },
  "吸取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīqǔ",
      "PrimaryDefinition": "to absorb, to draw (a lesson, insight etc), to assimilate"
    },
    "Keys": {
      "Word": 9817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "之后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīhòu",
      "PrimaryDefinition": "later, behind, at the back of, after"
    },
    "Keys": {
      "Word": 3169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "潆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "tiny stream; swirl around"
    },
    "Keys": {
      "Hanzi": 7724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7724,
      "Radical": "水",
      "FrequencyRank": 5088,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "组建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔjiàn",
      "PrimaryDefinition": "to organize, to set up, to establish"
    },
    "Keys": {
      "Word": 11014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáo",
      "PrimaryDefinition": "river in Hebei province"
    },
    "Keys": {
      "Hanzi": 6894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6894,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "奥运会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Àoyùnhuì",
      "PrimaryDefinition": "abbr. for 奧林匹克運動會|奥林匹克运动会, Olympic Games, the Olympics"
    },
    "Keys": {
      "Word": 5459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngchà",
      "PrimaryDefinition": "differ"
    },
    "Keys": {
      "Word": 9919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "必将": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìjiāng",
      "PrimaryDefinition": "inevitably"
    },
    "Keys": {
      "Word": 4324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "下乡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià xiāng",
      "PrimaryDefinition": "to go to the countryside"
    },
    "Keys": {
      "Word": 9872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轮流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnliú",
      "PrimaryDefinition": "by turns, in turn"
    },
    "Keys": {
      "Word": 8158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7322,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "痣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "spots, moles; birthmark"
    },
    "Keys": {
      "Hanzi": 5253
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5253,
      "Radical": "疒",
      "FrequencyRank": 4143,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "鄠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "county in Shanxi province"
    },
    "Keys": {
      "Hanzi": 7510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7510,
      "Radical": "邑",
      "FrequencyRank": 7769,
      "RadicalIndex": "163.11",
      "StrokeCount": 13
    }
  },
  "历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "history; calendar"
    },
    "Keys": {
      "Hanzi": 95
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.2",
      "GeneralStandard": 95,
      "Radical": "厂",
      "HSK": 3,
      "FrequencyRank": 480,
      "StrokeCount": 4
    }
  },
  "峿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "mountain"
    },
    "Keys": {
      "Hanzi": 6998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6998,
      "Radical": "山",
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "鹟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7798
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7798,
      "Radical": "鳥",
      "FrequencyRank": 7402,
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "铨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "weigh, measure; select officials"
    },
    "Keys": {
      "Hanzi": 4876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4876,
      "Radical": "金",
      "FrequencyRank": 4749,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "路灯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùdēng",
      "PrimaryDefinition": "street lamp, street light"
    },
    "Keys": {
      "Word": 8135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "组": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ",
      "PrimaryDefinition": "class; section, department"
    },
    "Keys": {
      "Hanzi": 1338,
      "Word": 1245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.5",
      "Grade": 2,
      "GeneralStandard": 1338,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 358,
      "StrokeCount": 8
    }
  },
  "汇款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìkuǎn",
      "PrimaryDefinition": "to remit money, remittance"
    },
    "Keys": {
      "Word": 3564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "合并": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hébìng",
      "PrimaryDefinition": "merge, amalgamate"
    },
    "Keys": {
      "Word": 3536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "省": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěng",
      "PrimaryDefinition": "province; save, economize"
    },
    "Keys": {
      "Hanzi": 1474,
      "Word": 976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "Grade": 2,
      "GeneralStandard": 1474,
      "Radical": "目",
      "HSK": 2,
      "FrequencyRank": 666,
      "StrokeCount": 9
    }
  },
  "煸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "to stir-fry before broiling or stewing"
    },
    "Keys": {
      "Hanzi": 5564
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5564,
      "Radical": "火",
      "FrequencyRank": 5609,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "铥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diū",
      "PrimaryDefinition": "thulium"
    },
    "Keys": {
      "Hanzi": 7185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7185,
      "Radical": "金",
      "FrequencyRank": 7445,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "票房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piàofáng",
      "PrimaryDefinition": "box office"
    },
    "Keys": {
      "Word": 8530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "省事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěngshì",
      "PrimaryDefinition": "to simplify matters, to save trouble, to handle administrative work"
    },
    "Keys": {
      "Word": 9071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "按": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "put hand on, press down with hand"
    },
    "Keys": {
      "Hanzi": 1395,
      "Word": 1265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 3,
      "GeneralStandard": 1395,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 573,
      "StrokeCount": 9
    }
  },
  "辞退": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cítuì",
      "PrimaryDefinition": "to dismiss, to discharge, to fire"
    },
    "Keys": {
      "Word": 6040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "墓地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùdì",
      "PrimaryDefinition": "cemetery, graveyard"
    },
    "Keys": {
      "Word": 8356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "a chin strap; tassel; to annoy, bother"
    },
    "Keys": {
      "Hanzi": 5845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5845,
      "Radical": "糸",
      "FrequencyRank": 4136,
      "RadicalIndex": "120.11",
      "StrokeCount": 14
    }
  },
  "自然而然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìránérrán",
      "PrimaryDefinition": "involuntary, automatically"
    },
    "Keys": {
      "Word": 10959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngchē",
      "PrimaryDefinition": "to get on or into (a bus, train, car etc)"
    },
    "Keys": {
      "Word": 314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "湍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuān",
      "PrimaryDefinition": "rapid water current, rapids"
    },
    "Keys": {
      "Hanzi": 5283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5283,
      "Radical": "水",
      "FrequencyRank": 3850,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "聊天儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo tiānr5",
      "PrimaryDefinition": "to chat, to gossip, erhua variant of 聊天[liao2 tian1]"
    },
    "Keys": {
      "Word": 4828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "适当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìdàng",
      "PrimaryDefinition": "beseem, proper, suitable, suit"
    },
    "Keys": {
      "Word": 5027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "提出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí chū",
      "PrimaryDefinition": "put forward, pose, raise"
    },
    "Keys": {
      "Word": 1016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "路面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùmiàn",
      "PrimaryDefinition": "pavement"
    },
    "Keys": {
      "Word": 8133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "phonetic used in pyridine and pyrimidine"
    },
    "Keys": {
      "Hanzi": 4850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4850,
      "Radical": "口",
      "FrequencyRank": 4785,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "共享": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngxiǎng",
      "PrimaryDefinition": "enjoy together, share"
    },
    "Keys": {
      "Word": 3505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "郊游": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoyóu",
      "PrimaryDefinition": "to go for an outing, to go on an excursion"
    },
    "Keys": {
      "Word": 7464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒng",
      "PrimaryDefinition": "opening, hole, orifice; great"
    },
    "Keys": {
      "Hanzi": 179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.1",
      "GeneralStandard": 179,
      "Radical": "子",
      "HSK": 9,
      "FrequencyRank": 1289,
      "StrokeCount": 4
    }
  },
  "然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rán",
      "PrimaryDefinition": "yes, certainly; pledge, promise"
    },
    "Keys": {
      "Hanzi": 2723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.8",
      "GeneralStandard": 2723,
      "Radical": "火",
      "HSK": 2,
      "FrequencyRank": 55,
      "StrokeCount": 12
    }
  },
  "叠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "pile; be piled up; fold up"
    },
    "Keys": {
      "Hanzi": 3043,
      "Word": 6297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.11",
      "Grade": 7,
      "GeneralStandard": 3043,
      "Radical": "又",
      "HSK": 8,
      "FrequencyRank": 2271,
      "StrokeCount": 13
    }
  },
  "集装箱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jízhuāngxiāng",
      "PrimaryDefinition": "container (for shipping)"
    },
    "Keys": {
      "Word": 7311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnyuán",
      "PrimaryDefinition": "actor or actress, playactor, imitator, stage manager, barnstormer, actor, performer, impersonator, cast, performing artist, dramatis personae, role player, histrionic, thespian, trouper, player, cast of characters, stager, histrion"
    },
    "Keys": {
      "Word": 2082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "narrow pass, strategic point"
    },
    "Keys": {
      "Hanzi": 1733,
      "Word": 5162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.7",
      "Grade": 6,
      "GeneralStandard": 1733,
      "Radical": "阜",
      "HSK": 3,
      "FrequencyRank": 672,
      "StrokeCount": 9
    }
  },
  "宜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "suitable, right, fitting, proper"
    },
    "Keys": {
      "Hanzi": 1278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "GeneralStandard": 1278,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 1290,
      "StrokeCount": 8
    }
  },
  "痹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "paralysis, numbness"
    },
    "Keys": {
      "Hanzi": 2988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.8",
      "GeneralStandard": 2988,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 3391,
      "StrokeCount": 13
    }
  },
  "廷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "court"
    },
    "Keys": {
      "Hanzi": 448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "54.4",
      "GeneralStandard": 448,
      "Radical": "廴",
      "HSK": 9,
      "FrequencyRank": 1626,
      "StrokeCount": 6
    }
  },
  "中断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngduàn",
      "PrimaryDefinition": "to cut short, to break off, to discontinue, to interrupt"
    },
    "Keys": {
      "Word": 4237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "仃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "lonely, solitary"
    },
    "Keys": {
      "Hanzi": 3515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3515,
      "Radical": "人",
      "FrequencyRank": 4973,
      "RadicalIndex": "9.2",
      "StrokeCount": 4
    }
  },
  "丿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piě",
      "PrimaryDefinition": "slash"
    },
    "Keys": {
      "Radical": 4
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "加工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā gōng",
      "PrimaryDefinition": "process"
    },
    "Keys": {
      "Word": 1584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "便利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànlì",
      "PrimaryDefinition": "facilitate"
    },
    "Keys": {
      "Word": 3255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "喷泉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēnquán",
      "PrimaryDefinition": "fountain"
    },
    "Keys": {
      "Word": 8490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "low wall"
    },
    "Keys": {
      "Hanzi": 4096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4096,
      "Radical": "土",
      "FrequencyRank": 2948,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "考验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎoyàn",
      "PrimaryDefinition": "test, trial"
    },
    "Keys": {
      "Word": 1669
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "马路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎlù",
      "PrimaryDefinition": "drive, road, driveway, riding, a road, carriageway, avenue, street, a street used by vehicular traffic"
    },
    "Keys": {
      "Word": 221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "垿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6946
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6946,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "愀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5298,
      "Radical": "心",
      "FrequencyRank": 5481,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "签字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān zì",
      "PrimaryDefinition": "to sign (one's name), signature"
    },
    "Keys": {
      "Word": 3801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "刮风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāfēng",
      "PrimaryDefinition": "to be windy"
    },
    "Keys": {
      "Word": 6853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老人家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎorenjia",
      "PrimaryDefinition": "parent, parents, a respectful form of address for an old person, old person"
    },
    "Keys": {
      "Word": 7949
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谈不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tánbushàng",
      "PrimaryDefinition": "to be out of the question"
    },
    "Keys": {
      "Word": 9402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窘迫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒngpò",
      "PrimaryDefinition": "poverty-stricken, very poor, hard-pressed, in a predicament, embarrassed"
    },
    "Keys": {
      "Word": 7656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珍贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnguì",
      "PrimaryDefinition": "valuable, precious"
    },
    "Keys": {
      "Word": 4197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鲷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": "pagrosomus major, porgy"
    },
    "Keys": {
      "Hanzi": 6145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6145,
      "Radical": "魚",
      "FrequencyRank": 6042,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "舭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7024,
      "Radical": "舟",
      "FrequencyRank": 6512,
      "RadicalIndex": "137.4",
      "StrokeCount": 10
    }
  },
  "评判": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngpàn",
      "PrimaryDefinition": "to judge (a competition), to appraise"
    },
    "Keys": {
      "Word": 8556
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "天性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānxìng",
      "PrimaryDefinition": "natural instincts, nature"
    },
    "Keys": {
      "Word": 9485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 821,
      "Word": 8475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.5",
      "Grade": 7,
      "GeneralStandard": 821,
      "Radical": "刀",
      "HSK": 9,
      "FrequencyRank": 3623,
      "StrokeCount": 7
    }
  },
  "洱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěr",
      "PrimaryDefinition": "a lake in Yunnan"
    },
    "Keys": {
      "Hanzi": 4331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4331,
      "Radical": "水",
      "FrequencyRank": 5109,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "惩处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngchǔ",
      "PrimaryDefinition": "to punish, to administer justice"
    },
    "Keys": {
      "Word": 5885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "dirty, useless, weak, powerless; cracked, a flaw"
    },
    "Keys": {
      "Hanzi": 6009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6009,
      "Radical": "穴",
      "FrequencyRank": 5821,
      "RadicalIndex": "116.1",
      "StrokeCount": 15
    }
  },
  "平方米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngfāngmǐ",
      "PrimaryDefinition": "square meter"
    },
    "Keys": {
      "Word": 4914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "炅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "brilliance"
    },
    "Keys": {
      "Hanzi": 3907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3907,
      "Radical": "火",
      "FrequencyRank": 5817,
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "记得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì de",
      "PrimaryDefinition": "come to mind, think back, remember, recollect, recall, rememberance, mind, retain, place, spring to mind, bear in mind"
    },
    "Keys": {
      "Word": 163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "鹯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "hawk; Butastur indicus"
    },
    "Keys": {
      "Hanzi": 8018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8018,
      "Radical": "鳥",
      "FrequencyRank": 7585,
      "RadicalIndex": "196.13",
      "StrokeCount": 18
    }
  },
  "烦恼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánnǎo",
      "PrimaryDefinition": "vexation"
    },
    "Keys": {
      "Word": 6460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxiào",
      "PrimaryDefinition": "effect, result"
    },
    "Keys": {
      "Word": 3323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "大型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxíng",
      "PrimaryDefinition": "large-size (machines/factories/etc.)"
    },
    "Keys": {
      "Word": 2376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瑾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "brilliance of gems; fine jade"
    },
    "Keys": {
      "Hanzi": 5851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5851,
      "Radical": "玉",
      "FrequencyRank": 3799,
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "食宿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí-sù",
      "PrimaryDefinition": "board and lodging, room and board"
    },
    "Keys": {
      "Word": 9121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "化石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàshí",
      "PrimaryDefinition": "fossil"
    },
    "Keys": {
      "Word": 3550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "保质期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎozhìqī",
      "PrimaryDefinition": "date of use (on foodstuffs), best before date"
    },
    "Keys": {
      "Word": 5527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "内阁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèigé",
      "PrimaryDefinition": "cabinet"
    },
    "Keys": {
      "Word": 8390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "romantic; tender; charming"
    },
    "Keys": {
      "Hanzi": 5787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5787,
      "Radical": "方",
      "FrequencyRank": 4845,
      "RadicalIndex": "70.1",
      "StrokeCount": 14
    }
  },
  "父女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùnǚ",
      "PrimaryDefinition": "father and daughter"
    },
    "Keys": {
      "Word": 4571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "得手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé shǒu",
      "PrimaryDefinition": "to go smoothly, to come off, to succeed"
    },
    "Keys": {
      "Word": 6211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "橡皮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngpí",
      "PrimaryDefinition": "rubber, an eraser, CL:塊|块[kuai4]"
    },
    "Keys": {
      "Word": 9953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàn",
      "PrimaryDefinition": "sigh, admire"
    },
    "Keys": {
      "Hanzi": 257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 257,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 1299,
      "StrokeCount": 5
    }
  },
  "使得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐde",
      "PrimaryDefinition": "render, wreck, compel, cause, make"
    },
    "Keys": {
      "Word": 3905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "开发区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāifāqū",
      "PrimaryDefinition": "development zone"
    },
    "Keys": {
      "Word": 7746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隐约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnyuē",
      "PrimaryDefinition": "vague, faint, indistinct"
    },
    "Keys": {
      "Word": 10404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chái",
      "PrimaryDefinition": "wolf; cruel, wicked, mean"
    },
    "Keys": {
      "Hanzi": 1988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1988,
      "Radical": "豸",
      "FrequencyRank": 4248,
      "RadicalIndex": "153.3",
      "StrokeCount": 10
    }
  },
  "辆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng",
      "PrimaryDefinition": "numerary adjunct for vehicles"
    },
    "Keys": {
      "Hanzi": 2251,
      "Word": 849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.7",
      "Grade": 2,
      "GeneralStandard": 2251,
      "Radical": "車",
      "HSK": 2,
      "FrequencyRank": 1375,
      "StrokeCount": 11
    }
  },
  "刺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cì",
      "PrimaryDefinition": "stab; prick, irritate; prod"
    },
    "Keys": {
      "Hanzi": 1039,
      "Word": 2352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 4,
      "GeneralStandard": 1039,
      "Radical": "刀",
      "HSK": 4,
      "FrequencyRank": 1058,
      "StrokeCount": 8
    }
  },
  "长远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángyuǎn",
      "PrimaryDefinition": "long-range, long, long-term, far-reaching"
    },
    "Keys": {
      "Word": 4390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "严密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánmì",
      "PrimaryDefinition": "strict, tight (organization, surveillance etc)"
    },
    "Keys": {
      "Word": 10177
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "dried meat; preserved fruits"
    },
    "Keys": {
      "Hanzi": 2357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2357,
      "Radical": "肉",
      "FrequencyRank": 3017,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "醒来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐnglái",
      "PrimaryDefinition": "waken, awaken, rouse, wake up, wake"
    },
    "Keys": {
      "Word": 10051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "指示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐshì",
      "PrimaryDefinition": "indicate, point out, instruct"
    },
    "Keys": {
      "Word": 4230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "及时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíshí",
      "PrimaryDefinition": "timely, in time, promptly"
    },
    "Keys": {
      "Word": 1573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "称呼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēnghu",
      "PrimaryDefinition": "call, address"
    },
    "Keys": {
      "Word": 5865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "a supply cart, covered wagon, dray"
    },
    "Keys": {
      "Hanzi": 5121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5121,
      "Radical": "車",
      "FrequencyRank": 4142,
      "RadicalIndex": "159.8",
      "StrokeCount": 12
    }
  },
  "劼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "be discreet, prudent, cautious"
    },
    "Keys": {
      "Hanzi": 3843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3843,
      "Radical": "力",
      "RadicalIndex": "19.6",
      "StrokeCount": 8
    }
  },
  "信誉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnyù",
      "PrimaryDefinition": "prestige, distinction, reputation, trust"
    },
    "Keys": {
      "Word": 10037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "cart wheel with no spokes"
    },
    "Keys": {
      "Hanzi": 6978
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6978,
      "Radical": "車",
      "FrequencyRank": 8504,
      "RadicalIndex": "159.6",
      "StrokeCount": 10
    }
  },
  "盛行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngxíng",
      "PrimaryDefinition": "be current, be in vogue"
    },
    "Keys": {
      "Word": 5015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "霉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "mildew, mold; moldy, mildewed"
    },
    "Keys": {
      "Hanzi": 3231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "173.7",
      "GeneralStandard": 3231,
      "Radical": "雨",
      "HSK": 7,
      "FrequencyRank": 2425,
      "StrokeCount": 15
    }
  },
  "实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "real, true; honest, sincere"
    },
    "Keys": {
      "Hanzi": 1285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.5",
      "GeneralStandard": 1285,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 100,
      "StrokeCount": 8
    }
  },
  "珖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàng",
      "PrimaryDefinition": "carat"
    },
    "Keys": {
      "Hanzi": 6931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6931,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "即将": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíjiāng",
      "PrimaryDefinition": "in a short time, promptly, on the point of, at once, instantly, pronto, immediately, shortly, without delay, tout-de-suite, right away, forthwith, presently, soon, in a few seconds, before long, straightaway, in a minute, about to"
    },
    "Keys": {
      "Word": 2581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "莓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "moss; edible berries"
    },
    "Keys": {
      "Hanzi": 4433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4433,
      "Radical": "艸",
      "FrequencyRank": 4076,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "蒗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5389
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5389,
      "Radical": "艸",
      "FrequencyRank": 6624,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "快速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàisù",
      "PrimaryDefinition": "expeditious, high-speed, fast, rapid, clip"
    },
    "Keys": {
      "Word": 1680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "晔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "bright; radiant; thriving"
    },
    "Keys": {
      "Hanzi": 6989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6989,
      "Radical": "日",
      "FrequencyRank": 4441,
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "站立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànlì",
      "PrimaryDefinition": "to stand, standing, on one's feet"
    },
    "Keys": {
      "Word": 10646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "送行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng xíng",
      "PrimaryDefinition": "to see someone off, to throw someone a send-off party"
    },
    "Keys": {
      "Word": 5054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "首脑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒunǎo",
      "PrimaryDefinition": "head (of state), summit (meeting), leader"
    },
    "Keys": {
      "Word": 5035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "震动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèndòng",
      "PrimaryDefinition": "shake, shock, vibrate"
    },
    "Keys": {
      "Word": 10711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "ramble, stroll, jaunt, loiter"
    },
    "Keys": {
      "Hanzi": 4492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4492,
      "Radical": "辵",
      "FrequencyRank": 3556,
      "RadicalIndex": "162.7",
      "StrokeCount": 10
    }
  },
  "崇拜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngbài",
      "PrimaryDefinition": "worship, adore"
    },
    "Keys": {
      "Word": 4421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "臜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zā",
      "PrimaryDefinition": "dirty; filthy"
    },
    "Keys": {
      "Hanzi": 6441
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6441,
      "Radical": "肉",
      "FrequencyRank": 7079,
      "RadicalIndex": "130.16",
      "StrokeCount": 20
    }
  },
  "亏损": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuīsǔn",
      "PrimaryDefinition": "deficit, (financial) loss"
    },
    "Keys": {
      "Word": 7895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "vast, large, grand, expansive"
    },
    "Keys": {
      "Hanzi": 6459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6459,
      "Radical": "水",
      "FrequencyRank": 5030,
      "RadicalIndex": "85.18",
      "StrokeCount": 21
    }
  },
  "洛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "river in Shanxi province; city"
    },
    "Keys": {
      "Hanzi": 1676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1676,
      "Radical": "水",
      "FrequencyRank": 978,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "一方面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīfāngmiàn",
      "PrimaryDefinition": "conj.: on one side, on the one hand..., on the other hand..., for one thing..., for another..."
    },
    "Keys": {
      "Word": 2091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "接纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēnà",
      "PrimaryDefinition": "accept"
    },
    "Keys": {
      "Word": 7508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "积淀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīdiàn",
      "PrimaryDefinition": "deposits accumulated over long periods, fig. valuable experience, accumulated wisdom"
    },
    "Keys": {
      "Word": 7271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎo",
      "PrimaryDefinition": "the convovulvus; parasitic plants such as mistletoe; Ribes ambiguum"
    },
    "Keys": {
      "Hanzi": 3868
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3868,
      "Radical": "艸",
      "FrequencyRank": 4983,
      "RadicalIndex": "140.6",
      "StrokeCount": 8
    }
  },
  "马戏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎxì",
      "PrimaryDefinition": "circus"
    },
    "Keys": {
      "Word": 8183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuō",
      "PrimaryDefinition": "error, mistake, slip; failure"
    },
    "Keys": {
      "Hanzi": 6095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6095,
      "Radical": "足",
      "FrequencyRank": 5828,
      "RadicalIndex": "157.1",
      "StrokeCount": 16
    }
  },
  "孅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "slender, thin; sharp-pointed"
    },
    "Keys": {
      "Hanzi": 8071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8071,
      "Radical": "女",
      "RadicalIndex": "38.17",
      "StrokeCount": 20
    }
  },
  "窍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "hole, opening, aperture"
    },
    "Keys": {
      "Hanzi": 2103
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.5",
      "GeneralStandard": 2103,
      "Radical": "穴",
      "HSK": 8,
      "FrequencyRank": 3020,
      "StrokeCount": 10
    }
  },
  "百科全书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎikēquánshū",
      "PrimaryDefinition": "encyclopedia, CL:本[ben3],集[ji2]"
    },
    "Keys": {
      "Word": 5473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "exegesis, explanation; explain"
    },
    "Keys": {
      "Hanzi": 3794
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3794,
      "Radical": "言",
      "FrequencyRank": 5077,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "打工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ gōng",
      "PrimaryDefinition": "work part-time while studying in college, work as an employee, etc."
    },
    "Keys": {
      "Word": 581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "信用卡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnyòngkǎ",
      "PrimaryDefinition": "charge plate, charge card, credit card, credit_card, charge_card, bank card"
    },
    "Keys": {
      "Word": 1109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "讱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "hesitate to say, reluctant to speak"
    },
    "Keys": {
      "Hanzi": 6521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6521,
      "Radical": "言",
      "FrequencyRank": 8057,
      "RadicalIndex": "149.3",
      "StrokeCount": 5
    }
  },
  "岁数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suìshu",
      "PrimaryDefinition": "age (number of years old)"
    },
    "Keys": {
      "Word": 5060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "分类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn lèi",
      "PrimaryDefinition": "sort"
    },
    "Keys": {
      "Word": 3457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "昔日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīrì",
      "PrimaryDefinition": "formerly, in olden days"
    },
    "Keys": {
      "Word": 9819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "旺季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngjì",
      "PrimaryDefinition": "busy season, peak period, see also 淡季[dan4 ji4]"
    },
    "Keys": {
      "Word": 9679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "cobalt; household iron"
    },
    "Keys": {
      "Hanzi": 4529
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4529,
      "Radical": "金",
      "FrequencyRank": 4783,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "啦啦队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lālāduì",
      "PrimaryDefinition": "support team, cheering squad, cheerleader"
    },
    "Keys": {
      "Word": 7909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mài",
      "PrimaryDefinition": "sell; betray; show off"
    },
    "Keys": {
      "Hanzi": 1042,
      "Word": 864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "24.6",
      "Grade": 2,
      "GeneralStandard": 1042,
      "Radical": "十",
      "HSK": 2,
      "FrequencyRank": 974,
      "StrokeCount": 8
    }
  },
  "屣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "straw sandals or slippers that have no heel-backs"
    },
    "Keys": {
      "Hanzi": 5828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5828,
      "Radical": "尸",
      "FrequencyRank": 6262,
      "RadicalIndex": "44.11",
      "StrokeCount": 14
    }
  },
  "迟疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chíyí",
      "PrimaryDefinition": "to hesitate"
    },
    "Keys": {
      "Word": 5902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "认错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn cuò",
      "PrimaryDefinition": "to admit an error, to acknowledge one's mistake"
    },
    "Keys": {
      "Word": 8861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "对联": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìlián",
      "PrimaryDefinition": "rhyming couplet, pair of lines of verse written vertically down the sides of a doorway, CL:副[fu4],幅[fu2]"
    },
    "Keys": {
      "Word": 6381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "会场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìchǎng",
      "PrimaryDefinition": "assembly_hall, fraternity house, conference, assembly hall, conference/assembly hall, theater, meeting-place, chapterhouse, meeting place, venue, conference hall, frat house"
    },
    "Keys": {
      "Word": 7218
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cài",
      "PrimaryDefinition": "surname; species of tortoise"
    },
    "Keys": {
      "Hanzi": 3071
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3071,
      "Radical": "艸",
      "FrequencyRank": 2226,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "多亏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōkuī",
      "PrimaryDefinition": "thanks to, luckily"
    },
    "Keys": {
      "Word": 6397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "béng",
      "PrimaryDefinition": "there is no need"
    },
    "Keys": {
      "Hanzi": 4164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "101.4",
      "GeneralStandard": 4164,
      "Radical": "用",
      "HSK": 6,
      "FrequencyRank": 3396,
      "StrokeCount": 9
    }
  },
  "不如说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùrú shuō",
      "PrimaryDefinition": "rather say"
    },
    "Keys": {
      "Word": 5710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "a kind of plant"
    },
    "Keys": {
      "Hanzi": 3663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3663,
      "Radical": "艸",
      "FrequencyRank": 6209,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "短": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǎn",
      "PrimaryDefinition": "short; brief; deficient, lacking"
    },
    "Keys": {
      "Hanzi": 2669,
      "Word": 633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "111.7",
      "Grade": 2,
      "GeneralStandard": 2669,
      "Radical": "矢",
      "HSK": 2,
      "FrequencyRank": 889,
      "StrokeCount": 12
    }
  },
  "鱾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "name of fish"
    },
    "Keys": {
      "Hanzi": 7214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7214,
      "Radical": "魚",
      "FrequencyRank": 7398,
      "RadicalIndex": "195.3",
      "StrokeCount": 11
    }
  },
  "拘留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūliú",
      "PrimaryDefinition": "to detain (a prisoner), to keep sb in custody"
    },
    "Keys": {
      "Word": 7676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "零": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "zero; fragment, fraction"
    },
    "Keys": {
      "Hanzi": 2885,
      "Word": 213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.5",
      "Grade": 1,
      "GeneralStandard": 2885,
      "Radical": "雨",
      "HSK": 1,
      "FrequencyRank": 1342,
      "StrokeCount": 13
    }
  },
  "本人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnrén",
      "PrimaryDefinition": "myself, principal, in person, I, me, oneself, identity"
    },
    "Keys": {
      "Word": 3243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "保留": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoliú",
      "PrimaryDefinition": "continue to have, retain, hold back, reserve"
    },
    "Keys": {
      "Word": 1279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "绁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "to contract, draw in, reduce, shorten"
    },
    "Keys": {
      "Hanzi": 4075
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4075,
      "Radical": "糸",
      "FrequencyRank": 6521,
      "RadicalIndex": "120.5",
      "StrokeCount": 8
    }
  },
  "氕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piē",
      "PrimaryDefinition": "hydrogen-1, protium"
    },
    "Keys": {
      "Hanzi": 6511
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6511,
      "Radical": "气",
      "FrequencyRank": 7374,
      "RadicalIndex": "84.1",
      "StrokeCount": 5
    }
  },
  "本来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnlái",
      "PrimaryDefinition": "originally, at first, of course"
    },
    "Keys": {
      "Word": 1290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "分数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnshù",
      "PrimaryDefinition": "point, score, mark, grade, fraction, football score, numeric"
    },
    "Keys": {
      "Word": 659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "淋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "drip, soak, drench; perfectly"
    },
    "Keys": {
      "Hanzi": 2413,
      "Word": 8065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 7,
      "GeneralStandard": 2413,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2168,
      "StrokeCount": 11
    }
  },
  "一晃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīhuǎng",
      "PrimaryDefinition": "(of passing time) in an instant, (of a sight) in a flash"
    },
    "Keys": {
      "Word": 10291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乔装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáozhuāng",
      "PrimaryDefinition": "to pretend, to feign, to disguise oneself"
    },
    "Keys": {
      "Word": 8701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shén",
      "PrimaryDefinition": "great extent; considerably"
    },
    "Keys": {
      "Hanzi": 1400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "99.4",
      "GeneralStandard": 1400,
      "Radical": "甘",
      "HSK": 4,
      "FrequencyRank": 626,
      "StrokeCount": 9
    }
  },
  "溁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7427,
      "Radical": "水",
      "RadicalIndex": "85.14",
      "StrokeCount": 12
    }
  },
  "红酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngjiǔ",
      "PrimaryDefinition": "Red wine"
    },
    "Keys": {
      "Word": 1549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "使用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐyòng",
      "PrimaryDefinition": "use, employ, apply"
    },
    "Keys": {
      "Word": 980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "主持人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔchírén",
      "PrimaryDefinition": "anchor, emcee, host, symposiarch, anchorman, director, impresario, chair, chairperson, linkman, presenter, compere, master of ceremonies, moderator"
    },
    "Keys": {
      "Word": 5375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "大多数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàduōshù",
      "PrimaryDefinition": "det.: most of, the majority of"
    },
    "Keys": {
      "Word": 587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèi",
      "PrimaryDefinition": "class, group, kind, category"
    },
    "Keys": {
      "Hanzi": 1646,
      "Word": 1694
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.6",
      "Grade": 3,
      "GeneralStandard": 1646,
      "Radical": "大",
      "HSK": 3,
      "FrequencyRank": 311,
      "StrokeCount": 9
    }
  },
  "出道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū dào",
      "PrimaryDefinition": "to make one's first public performance (of an entertainer etc), to start one's career"
    },
    "Keys": {
      "Word": 5953
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蟋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "cricket"
    },
    "Keys": {
      "Hanzi": 3411
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3411,
      "Radical": "虫",
      "FrequencyRank": 3780,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "反而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnér",
      "PrimaryDefinition": "conj.: on the contrary, instead"
    },
    "Keys": {
      "Word": 2454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "缆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "hawser, heavy-duty rope, cable"
    },
    "Keys": {
      "Hanzi": 2815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.9",
      "GeneralStandard": 2815,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2821,
      "StrokeCount": 12
    }
  },
  "遏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "stop, suppress, curb, check; a bar"
    },
    "Keys": {
      "Hanzi": 2624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "GeneralStandard": 2624,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 2701,
      "StrokeCount": 12
    }
  },
  "过来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò lai",
      "PrimaryDefinition": "come over/up, up, come over, come up, come_over, come here"
    },
    "Keys": {
      "Word": 700
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "匜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "basin; container for wine"
    },
    "Keys": {
      "Hanzi": 3536
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3536,
      "Radical": "匚",
      "RadicalIndex": "22.3",
      "StrokeCount": 5
    }
  },
  "娓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "comply; complying, agreeable"
    },
    "Keys": {
      "Hanzi": 4695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4695,
      "Radical": "女",
      "FrequencyRank": 3999,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "鿎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎ",
      "PrimaryDefinition": "(tǎ) place names in Guangdong and Zhejiang; (dá) cobblestone, water gate"
    },
    "Keys": {
      "Hanzi": 7146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7146,
      "Radical": "石",
      "RadicalIndex": "112.7"
    }
  },
  "方针": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngzhēn",
      "PrimaryDefinition": "policy, guiding principle"
    },
    "Keys": {
      "Word": 2459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "回升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíshēng",
      "PrimaryDefinition": "rise again (after a fall), pick up"
    },
    "Keys": {
      "Word": 7208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "advance, make progress, enter"
    },
    "Keys": {
      "Hanzi": 605,
      "Word": 179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 1,
      "GeneralStandard": 605,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 81,
      "StrokeCount": 7
    }
  },
  "深入人心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnrù-rénxīn",
      "PrimaryDefinition": "to enter deeply into people's hearts, to have a real impact on the people (idiom)"
    },
    "Keys": {
      "Word": 9030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铅笔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānbǐ",
      "PrimaryDefinition": "(lead) pencil, CL:支[zhi1],枝[zhi1],桿|杆[gan3]"
    },
    "Keys": {
      "Word": 4925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "变质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànzhì",
      "PrimaryDefinition": "to degenerate, to go bad, to deteriorate, metamorphosis"
    },
    "Keys": {
      "Word": 5611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冷战": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngzhan",
      "PrimaryDefinition": "cold war"
    },
    "Keys": {
      "Word": 7971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāitong",
      "PrimaryDefinition": "to open up (windows for air, ideas for discussion, transport routes etc), open-minded"
    },
    "Keys": {
      "Word": 4780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "坚决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānjué",
      "PrimaryDefinition": "firm, determined"
    },
    "Keys": {
      "Word": 1599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "靼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "tartars"
    },
    "Keys": {
      "Hanzi": 5646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5646,
      "Radical": "革",
      "FrequencyRank": 4192,
      "RadicalIndex": "177.5",
      "StrokeCount": 14
    }
  },
  "担": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "carry, bear, undertake"
    },
    "Keys": {
      "Hanzi": 963,
      "Word": 6155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 963,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 720,
      "StrokeCount": 8
    }
  },
  "亲生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnshēng",
      "PrimaryDefinition": "one's own (child) (i.e. one's child by birth), biological (parents), birth (parents)"
    },
    "Keys": {
      "Word": 8726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "五星级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔxīngjí",
      "PrimaryDefinition": "five-star (hotel)"
    },
    "Keys": {
      "Word": 9799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoyuǎn",
      "PrimaryDefinition": "very far away"
    },
    "Keys": {
      "Word": 7951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "个儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèr5",
      "PrimaryDefinition": "size, height, stature"
    },
    "Keys": {
      "Word": 3492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "甑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zèng",
      "PrimaryDefinition": "boiler for steaming rice, pot"
    },
    "Keys": {
      "Hanzi": 6169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6169,
      "Radical": "瓦",
      "FrequencyRank": 4863,
      "RadicalIndex": "98.12",
      "StrokeCount": 16
    }
  },
  "多数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōshù",
      "PrimaryDefinition": "trillions, millions, mort, relative majority, throng, det : majority, prevalence, multitude, lac, Host, host, bulk, horde, raft, million, lakh, rimption, pack, skit, strength, acre, generality, ruck, chunk, number, shoal, multiplicity, billions, preponderance, jillions, zillions, grist, world, majority, meiny, regiment, legion, mass, plurality"
    },
    "Keys": {
      "Word": 645
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "光碟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngdié",
      "PrimaryDefinition": "compact disk, compact disc, CD ROM, compact_disk, CD"
    },
    "Keys": {
      "Word": 6899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7917,
      "Radical": "玉",
      "RadicalIndex": "96.13",
      "StrokeCount": 17
    }
  },
  "违背": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéibèi",
      "PrimaryDefinition": "violate, sin, go_against, infringe, go against, violation, belie, transgress, contravene, disobey, breach, break, run counter to"
    },
    "Keys": {
      "Word": 9698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "医院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyuàn",
      "PrimaryDefinition": "spital, hospital, infirmary, hospice"
    },
    "Keys": {
      "Word": 426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "期待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīdài",
      "PrimaryDefinition": "look forward to"
    },
    "Keys": {
      "Word": 2789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "函授": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánshòu",
      "PrimaryDefinition": "to teach by correspondence"
    },
    "Keys": {
      "Word": 6982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "书房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūfáng",
      "PrimaryDefinition": "study (room), studio, CL:間|间[jian1]"
    },
    "Keys": {
      "Word": 5038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "强行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángxíng",
      "PrimaryDefinition": "force"
    },
    "Keys": {
      "Word": 8692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凉鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángxié",
      "PrimaryDefinition": "sandal"
    },
    "Keys": {
      "Word": 4825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "拔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bá",
      "PrimaryDefinition": "uproot, pull out"
    },
    "Keys": {
      "Hanzi": 959,
      "Word": 3224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 5,
      "GeneralStandard": 959,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1502,
      "StrokeCount": 8
    }
  },
  "皑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ái",
      "PrimaryDefinition": "brilliant white"
    },
    "Keys": {
      "Hanzi": 4911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4911,
      "Radical": "白",
      "FrequencyRank": 3952,
      "RadicalIndex": "106.6",
      "StrokeCount": 11
    }
  },
  "粪便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènbiàn",
      "PrimaryDefinition": "excrement, feces, night soil"
    },
    "Keys": {
      "Word": 6558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笛子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dízi",
      "PrimaryDefinition": "bamboo flute, CL:管[guan3]"
    },
    "Keys": {
      "Word": 6236
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嬬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "mistress, concubine; weak"
    },
    "Keys": {
      "Hanzi": 7977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7977,
      "Radical": "女",
      "RadicalIndex": "38.14",
      "StrokeCount": 17
    }
  },
  "腆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎn",
      "PrimaryDefinition": "prosperous; good; protruding"
    },
    "Keys": {
      "Hanzi": 5230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5230,
      "Radical": "肉",
      "FrequencyRank": 4025,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "声称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngchēng",
      "PrimaryDefinition": "to claim, to state, to proclaim, to assert"
    },
    "Keys": {
      "Word": 9065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": "look like, resemble, be like"
    },
    "Keys": {
      "Hanzi": 713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.3",
      "GeneralStandard": 713,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 2019,
      "StrokeCount": 7
    }
  },
  "国学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóxué",
      "PrimaryDefinition": "Chinese national culture, studies of ancient Chinese civilization, the Imperial College (history)"
    },
    "Keys": {
      "Word": 6935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "两岸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎngàn",
      "PrimaryDefinition": "both sides of a river, both sides of the Taiwan Straits (I.e., Chinese Mainland and Taiwan)"
    },
    "Keys": {
      "Word": 3695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "伸缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnsuō",
      "PrimaryDefinition": "to lengthen and shorten, flexible, adjustable, retractable, extensible, telescoping (collapsible)"
    },
    "Keys": {
      "Word": 9017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "bud, sprout"
    },
    "Keys": {
      "Hanzi": 2203
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2203,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2879,
      "StrokeCount": 11
    }
  },
  "傈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "tribe"
    },
    "Keys": {
      "Hanzi": 5209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5209,
      "Radical": "人",
      "FrequencyRank": 5289,
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "后者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuzhě",
      "PrimaryDefinition": "latter, the latter"
    },
    "Keys": {
      "Word": 7102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoxīn",
      "PrimaryDefinition": "kindness, good intentions"
    },
    "Keys": {
      "Word": 7011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": "forge metal; temper, refine"
    },
    "Keys": {
      "Hanzi": 3114
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.9",
      "GeneralStandard": 3114,
      "Radical": "金",
      "HSK": 4,
      "FrequencyRank": 2531,
      "StrokeCount": 14
    }
  },
  "腺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "gland"
    },
    "Keys": {
      "Hanzi": 2973,
      "Word": 9913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.9",
      "Grade": 7,
      "GeneralStandard": 2973,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 2257,
      "StrokeCount": 13
    }
  },
  "合成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héchéng",
      "PrimaryDefinition": "prefabricate, composition, compound, synthesis, synthesize, synthesise, synthetic, synergistic, composite, compose"
    },
    "Keys": {
      "Word": 3537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "申办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnbàn",
      "PrimaryDefinition": "apply for"
    },
    "Keys": {
      "Word": 9014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "long legged spider; octopus; chrysalis of mantis"
    },
    "Keys": {
      "Hanzi": 5459
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5459,
      "Radical": "虫",
      "FrequencyRank": 6545,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "亍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "to take small steps; Korean place name"
    },
    "Keys": {
      "Hanzi": 6501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6501,
      "Radical": "二",
      "RadicalIndex": "7.1",
      "StrokeCount": 3
    }
  },
  "天真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānzhēn",
      "PrimaryDefinition": "naive, innocent"
    },
    "Keys": {
      "Word": 2929
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "硕果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuòguǒ",
      "PrimaryDefinition": "major achievement, great work, triumphant success"
    },
    "Keys": {
      "Word": 9301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngyǎng",
      "PrimaryDefinition": "adoption, to adopt (a child)"
    },
    "Keys": {
      "Word": 8085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "享受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngshòu",
      "PrimaryDefinition": "enjoy"
    },
    "Keys": {
      "Word": 4046
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "公里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnglǐ",
      "PrimaryDefinition": "m.[standard]"
    },
    "Keys": {
      "Word": 687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "芯片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnpiàn",
      "PrimaryDefinition": "computer chip, microchip"
    },
    "Keys": {
      "Word": 10020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "提起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíqǐ",
      "PrimaryDefinition": "mention, speak of"
    },
    "Keys": {
      "Word": 3972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "那些": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàxiē",
      "PrimaryDefinition": "det.: those"
    },
    "Keys": {
      "Word": 254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "锃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zèng",
      "PrimaryDefinition": "polish"
    },
    "Keys": {
      "Hanzi": 5181
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5181,
      "Radical": "金",
      "FrequencyRank": 4821,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "儋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "a small jar; to bear a burden; a load of two"
    },
    "Keys": {
      "Hanzi": 5961
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5961,
      "Radical": "人",
      "FrequencyRank": 5810,
      "RadicalIndex": "9.13",
      "StrokeCount": 15
    }
  },
  "所长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒcháng",
      "PrimaryDefinition": "head of an institute/etc."
    },
    "Keys": {
      "Word": 1948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "海外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiwài",
      "PrimaryDefinition": "oversea, overseas, abroad"
    },
    "Keys": {
      "Word": 4649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "图书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túshu",
      "PrimaryDefinition": "picture_book, books"
    },
    "Keys": {
      "Word": 5102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "睚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "corner of eye; stare"
    },
    "Keys": {
      "Hanzi": 5431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5431,
      "Radical": "目",
      "FrequencyRank": 6152,
      "RadicalIndex": "109.8",
      "StrokeCount": 13
    }
  },
  "谯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "tower; surname"
    },
    "Keys": {
      "Hanzi": 5824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5824,
      "Radical": "言",
      "FrequencyRank": 5155,
      "RadicalIndex": "149.12",
      "StrokeCount": 14
    }
  },
  "药物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàowù",
      "PrimaryDefinition": "healer, pharmic, medicine, druggery, curative, drug, pharmaceuticals, medicines, medicaments, medicinal, medicament, pharmaceutical, medication, remedy, drugs"
    },
    "Keys": {
      "Word": 3078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "仅次于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn cìyú",
      "PrimaryDefinition": "second only to..., (in second place) preceded only by..."
    },
    "Keys": {
      "Word": 7565
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng jiāo",
      "PrimaryDefinition": "conclude a transaction, clinch a deal, strike a bargain, close a deal"
    },
    "Keys": {
      "Word": 3322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "勇往直前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngwǎng-zhíqián",
      "PrimaryDefinition": "to advance bravely"
    },
    "Keys": {
      "Word": 10436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒ",
      "PrimaryDefinition": "the solitary wasp"
    },
    "Keys": {
      "Hanzi": 7674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7674,
      "Radical": "虫",
      "FrequencyRank": 6691,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "牍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "writing tablet; documents, books"
    },
    "Keys": {
      "Hanzi": 5211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5211,
      "Radical": "片",
      "FrequencyRank": 4235,
      "RadicalIndex": "91.8",
      "StrokeCount": 12
    }
  },
  "上报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng bào",
      "PrimaryDefinition": "to report to one's superiors, to appear in the news, to reply to a letter"
    },
    "Keys": {
      "Word": 8969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "影片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngpiàn",
      "PrimaryDefinition": "celluloid, movie_film, flick, production, film, pic, movie"
    },
    "Keys": {
      "Word": 1166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "依靠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīkào",
      "PrimaryDefinition": "rely/depend on"
    },
    "Keys": {
      "Word": 3084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "自在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìzai",
      "PrimaryDefinition": "comfortable, unrestrained, at_ease, free"
    },
    "Keys": {
      "Word": 5398
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "镣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liào",
      "PrimaryDefinition": "fetters"
    },
    "Keys": {
      "Hanzi": 6247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6247,
      "Radical": "金",
      "FrequencyRank": 4403,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "学历": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuélì",
      "PrimaryDefinition": "education, record of formal schooling, antecedents"
    },
    "Keys": {
      "Word": 10131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǒ",
      "PrimaryDefinition": "satisfactory, appropriate"
    },
    "Keys": {
      "Hanzi": 799,
      "Word": 9624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.4",
      "Grade": 7,
      "GeneralStandard": 799,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 1840,
      "StrokeCount": 7
    }
  },
  "越发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèfā",
      "PrimaryDefinition": "increasingly, more and more, ever more, all the more"
    },
    "Keys": {
      "Word": 10567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "松": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "pine tree; fir tree"
    },
    "Keys": {
      "Hanzi": 1026,
      "Word": 2905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "Grade": 4,
      "GeneralStandard": 1026,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 895,
      "StrokeCount": 8
    }
  },
  "未经": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèijīng",
      "PrimaryDefinition": "not having undergone, without (having gone though a certain process)"
    },
    "Keys": {
      "Word": 9715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宣泄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānxiè",
      "PrimaryDefinition": "to drain (by leading off water), to unburden oneself, to divulge, to leak a secret"
    },
    "Keys": {
      "Word": 10112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "念念不忘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niànniàn-bùwàng",
      "PrimaryDefinition": "to keep in mind constantly (idiom)"
    },
    "Keys": {
      "Word": 8415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō",
      "PrimaryDefinition": "lean one side; very, rather"
    },
    "Keys": {
      "Hanzi": 2484,
      "Word": 8566
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.5",
      "Grade": 7,
      "GeneralStandard": 2484,
      "Radical": "頁",
      "HSK": 8,
      "FrequencyRank": 1560,
      "StrokeCount": 11
    }
  },
  "诸如此类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūrúcǐlèi",
      "PrimaryDefinition": "things like this (idiom), and so on, and the rest, etc"
    },
    "Keys": {
      "Word": 10847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "座位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòwei",
      "PrimaryDefinition": "seat, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "嶲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "a name of an old town in Sichuan; cuckoo; revolution of a wheel"
    },
    "Keys": {
      "Hanzi": 7780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7780,
      "Radical": "山",
      "RadicalIndex": "46.13",
      "StrokeCount": 15
    }
  },
  "裙子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qúnzi",
      "PrimaryDefinition": "skirt, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 1840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "堵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔ",
      "PrimaryDefinition": "wall; stop, prevent; stop up"
    },
    "Keys": {
      "Hanzi": 2161,
      "Word": 2426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.9",
      "Grade": 4,
      "GeneralStandard": 2161,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 2284,
      "StrokeCount": 11
    }
  },
  "籥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "key; woodwind instrument"
    },
    "Keys": {
      "Hanzi": 8098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8098,
      "Radical": "竹",
      "FrequencyRank": 7066,
      "RadicalIndex": "118.17",
      "StrokeCount": 23
    }
  },
  "测量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèliáng",
      "PrimaryDefinition": "survey, measure"
    },
    "Keys": {
      "Word": 2294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "怄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "òu",
      "PrimaryDefinition": "annoyed"
    },
    "Keys": {
      "Hanzi": 3784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3784,
      "Radical": "心",
      "FrequencyRank": 5536,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "催": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuī",
      "PrimaryDefinition": "press, urge"
    },
    "Keys": {
      "Hanzi": 2959,
      "Word": 6077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.11",
      "Grade": 7,
      "GeneralStandard": 2959,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 2064,
      "StrokeCount": 13
    }
  },
  "来信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láixìn",
      "PrimaryDefinition": "incoming letter, send a letter here"
    },
    "Keys": {
      "Word": 3676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "长大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎng dà",
      "PrimaryDefinition": "vegetate, be brought up, grow_up, maturate, grow, grow up, bulk, age, mature"
    },
    "Keys": {
      "Word": 1203
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "得出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé chū",
      "PrimaryDefinition": "to obtain (results), to arrive at (a conclusion)"
    },
    "Keys": {
      "Word": 611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "扶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "support, help; protect; hold on"
    },
    "Keys": {
      "Hanzi": 612,
      "Word": 3467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 5,
      "GeneralStandard": 612,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1612,
      "StrokeCount": 7
    }
  },
  "城墙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngqiáng",
      "PrimaryDefinition": "enceinte, city wall, rampart"
    },
    "Keys": {
      "Word": 5882
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "紧缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnsuō",
      "PrimaryDefinition": "(economics) to reduce, to curtail, to cut back, to tighten, austerity, tightening, crunch"
    },
    "Keys": {
      "Word": 7572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàng",
      "PrimaryDefinition": "the brick-bed in northern China"
    },
    "Keys": {
      "Hanzi": 1236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1236,
      "Radical": "火",
      "FrequencyRank": 2975,
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "壮观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngguān",
      "PrimaryDefinition": "sublime, spectacular, magnificent"
    },
    "Keys": {
      "Word": 5388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "加热": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā rè",
      "PrimaryDefinition": "to heat"
    },
    "Keys": {
      "Word": 3582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "作对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò duì",
      "PrimaryDefinition": "to set oneself against, to oppose, to make a pair"
    },
    "Keys": {
      "Word": 11027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "crime, criminal offense"
    },
    "Keys": {
      "Hanzi": 2560
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "160.5",
      "GeneralStandard": 2560,
      "Radical": "辛",
      "HSK": 7,
      "FrequencyRank": 2596,
      "StrokeCount": 12
    }
  },
  "詈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "scold, abuse verbally, curse"
    },
    "Keys": {
      "Hanzi": 5167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5167,
      "Radical": "言",
      "FrequencyRank": 4626,
      "RadicalIndex": "149.5",
      "StrokeCount": 12
    }
  },
  "旅程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚchéng",
      "PrimaryDefinition": "travel plan, journeying, itinerary, route, stage, peregrination, journey"
    },
    "Keys": {
      "Word": 8138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泄密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè mì",
      "PrimaryDefinition": "to leak secrets"
    },
    "Keys": {
      "Word": 9996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "报警": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào jǐng",
      "PrimaryDefinition": "to sound an alarm, to report sth to the police"
    },
    "Keys": {
      "Word": 3235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "抓住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuā zhù",
      "PrimaryDefinition": "clutch, grip, grab, kep, nail, grapple, get_hold_of, catch, seize hold of, take_hold_of, grip sb.'s attention, cleek, grasp, hold, nab, seize, tackle, capture, snatch, take, take hold of, prehend, catch hold of, glaum, hold on"
    },
    "Keys": {
      "Word": 2199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "晷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "shadows of sun; time; sundial"
    },
    "Keys": {
      "Hanzi": 5133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5133,
      "Radical": "日",
      "FrequencyRank": 5799,
      "RadicalIndex": "72.8",
      "StrokeCount": 12
    }
  },
  "蹜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "walk carefully"
    },
    "Keys": {
      "Hanzi": 7998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7998,
      "Radical": "足",
      "RadicalIndex": "157.11",
      "StrokeCount": 18
    }
  },
  "居民楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūmínlóu",
      "PrimaryDefinition": "Residential building"
    },
    "Keys": {
      "Word": 7679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎn",
      "PrimaryDefinition": "reverse, opposite, contrary, anti"
    },
    "Keys": {
      "Hanzi": 139,
      "Word": 2456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.2",
      "Grade": 4,
      "GeneralStandard": 139,
      "Radical": "又",
      "HSK": 3,
      "FrequencyRank": 237,
      "StrokeCount": 4
    }
  },
  "荣获": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rónghuò",
      "PrimaryDefinition": "have the honor to win"
    },
    "Keys": {
      "Word": 8877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "cheerful"
    },
    "Keys": {
      "Hanzi": 7244
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7244,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "现行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànxíng",
      "PrimaryDefinition": "operation, in force/operation, currently in operation, in effect, force, currently in effect, in effect/force/operation, active, current"
    },
    "Keys": {
      "Word": 9901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "身份证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnfènzhèng",
      "PrimaryDefinition": "identity card, ID"
    },
    "Keys": {
      "Word": 1877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "伾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "mighty"
    },
    "Keys": {
      "Hanzi": 6601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6601,
      "Radical": "人",
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "旅行社": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚxíngshè",
      "PrimaryDefinition": "a tourist agency, travel_agency, a travel agency, travel service/agent, travel service, travel agent, travel agency"
    },
    "Keys": {
      "Word": 1723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "模样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "múyàng",
      "PrimaryDefinition": "sign, air, aspect, form, look, front, appearance, shape, suggestion"
    },
    "Keys": {
      "Word": 3735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "栏杆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lángān",
      "PrimaryDefinition": "railing, banister"
    },
    "Keys": {
      "Word": 7926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "moss, lichen"
    },
    "Keys": {
      "Hanzi": 6210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6210,
      "Radical": "艸",
      "FrequencyRank": 4187,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "裂痕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièhén",
      "PrimaryDefinition": "crack, gap, split"
    },
    "Keys": {
      "Word": 8057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俱乐部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùlèbù",
      "PrimaryDefinition": "club, (social) club, casino, Verein"
    },
    "Keys": {
      "Word": 3639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "煨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "to stew, simmer"
    },
    "Keys": {
      "Hanzi": 5561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5561,
      "Radical": "火",
      "FrequencyRank": 4855,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "戈壁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēbì",
      "PrimaryDefinition": "Gobi (desert)"
    },
    "Keys": {
      "Word": 6718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汉字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Hànzì",
      "PrimaryDefinition": "Chinese character, CL:個|个[ge4], Japanese: kanji, Korean: hanja, Vietnamese: hán tự"
    },
    "Keys": {
      "Word": 135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "鋆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "gold; character used in personal name"
    },
    "Keys": {
      "Hanzi": 5865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5865,
      "Radical": "金",
      "FrequencyRank": 3923,
      "RadicalIndex": "167.7",
      "StrokeCount": 15
    }
  },
  "罘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "screen used in ancient times"
    },
    "Keys": {
      "Hanzi": 4232
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4232,
      "Radical": "网",
      "FrequencyRank": 5614,
      "RadicalIndex": "122.4",
      "StrokeCount": 9
    }
  },
  "学习": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéxí",
      "PrimaryDefinition": "study, learn, emulate"
    },
    "Keys": {
      "Word": 417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "计时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìshí",
      "PrimaryDefinition": "to measure time, to time, to reckon by time"
    },
    "Keys": {
      "Word": 7320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "天气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānqì",
      "PrimaryDefinition": "sky, elements, weather condition, atmospheric condition, weather"
    },
    "Keys": {
      "Word": 357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "瓶颈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngjǐng",
      "PrimaryDefinition": "bottleneck"
    },
    "Keys": {
      "Word": 8563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ",
      "PrimaryDefinition": "impede, hinder, obstruct; oppose"
    },
    "Keys": {
      "Hanzi": 915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.5",
      "GeneralStandard": 915,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 1175,
      "StrokeCount": 7
    }
  },
  "叫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "cry, shout; hail, greet, call"
    },
    "Keys": {
      "Hanzi": 253,
      "Word": 1620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 3,
      "GeneralStandard": 253,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 387,
      "StrokeCount": 5
    }
  },
  "驿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "relay station"
    },
    "Keys": {
      "Hanzi": 4081
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4081,
      "Radical": "馬",
      "FrequencyRank": 3648,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "畋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "till land, cultivate; hunt"
    },
    "Keys": {
      "Hanzi": 4209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4209,
      "Radical": "田",
      "FrequencyRank": 6067,
      "RadicalIndex": "102.4",
      "StrokeCount": 9
    }
  },
  "皞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "bright, brilliant"
    },
    "Keys": {
      "Hanzi": 7793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7793,
      "Radical": "白",
      "RadicalIndex": "106.1",
      "StrokeCount": 15
    }
  },
  "释放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìfàng",
      "PrimaryDefinition": "release, set free"
    },
    "Keys": {
      "Word": 9160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "报名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào míng",
      "PrimaryDefinition": "register, inscribe, enter, enroll, enrol, enter one's name, sign up, recruit"
    },
    "Keys": {
      "Word": 509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "解剖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěpōu",
      "PrimaryDefinition": "dissect"
    },
    "Keys": {
      "Word": 7537
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无忧无虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúyōu-wúlǜ",
      "PrimaryDefinition": "carefree and without worries (idiom)"
    },
    "Keys": {
      "Word": 9794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "澎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēng",
      "PrimaryDefinition": "splatter"
    },
    "Keys": {
      "Hanzi": 3291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3291,
      "Radical": "水",
      "FrequencyRank": 3481,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "绩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "spin; achievements"
    },
    "Keys": {
      "Hanzi": 2486
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2486,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 1547,
      "StrokeCount": 11
    }
  },
  "平价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngjià",
      "PrimaryDefinition": "reasonably priced, inexpensive, to keep prices down, (currency exchange) parity"
    },
    "Keys": {
      "Word": 8549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "直线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíxiàn",
      "PrimaryDefinition": "straight line, sharply (rise or fall)"
    },
    "Keys": {
      "Word": 4221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "指挥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐhuī",
      "PrimaryDefinition": "conduct, direct, command"
    },
    "Keys": {
      "Word": 3175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "邂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "unexpected meeting; encounter by"
    },
    "Keys": {
      "Hanzi": 6150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6150,
      "Radical": "辵",
      "FrequencyRank": 4605,
      "RadicalIndex": "162.13",
      "StrokeCount": 16
    }
  },
  "痕迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hénjì",
      "PrimaryDefinition": "spark, taint, scrape, show, imprint, trace, vestige, spoor, sign, track, nick, mark, print, scar, scratch, scent, ash, vestigial, trail, impression"
    },
    "Keys": {
      "Word": 7064
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酣睡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hānshuì",
      "PrimaryDefinition": "to sleep soundly, to fall into a deep sleep"
    },
    "Keys": {
      "Word": 6979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不要紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù yàojǐn",
      "PrimaryDefinition": "unimportant, not serious, it doesn't matter, never mind, it looks all right, but"
    },
    "Keys": {
      "Word": 2278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "霁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "to clear up after rain; to cease be angry"
    },
    "Keys": {
      "Hanzi": 5685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5685,
      "Radical": "雨",
      "FrequencyRank": 4698,
      "RadicalIndex": "173.6",
      "StrokeCount": 14
    }
  },
  "琪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "type of jade"
    },
    "Keys": {
      "Hanzi": 5045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5045,
      "Radical": "玉",
      "FrequencyRank": 3138,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "蓼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎo",
      "PrimaryDefinition": "smartweed, polygonum"
    },
    "Keys": {
      "Hanzi": 5659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5659,
      "Radical": "艸",
      "FrequencyRank": 5931,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "冯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féng",
      "PrimaryDefinition": "surname; gallop; by dint of"
    },
    "Keys": {
      "Hanzi": 303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.2",
      "GeneralStandard": 303,
      "Radical": "馬",
      "HSK": 9,
      "FrequencyRank": 1719,
      "StrokeCount": 5
    }
  },
  "调解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáojiě",
      "PrimaryDefinition": "to mediate, to bring parties to an agreement"
    },
    "Keys": {
      "Word": 3981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "来龙去脉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láilóng-qùmài",
      "PrimaryDefinition": "the rise and fall of the terrain (idiom), (fig.) the whole sequence of events, causes and effects"
    },
    "Keys": {
      "Word": 7921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "trumpet"
    },
    "Keys": {
      "Hanzi": 247
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 247,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2704,
      "StrokeCount": 5
    }
  },
  "狨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4291,
      "Radical": "犬",
      "FrequencyRank": 9822,
      "RadicalIndex": "94.6",
      "StrokeCount": 9
    }
  },
  "槌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": "hammer, mallet; strike, beat"
    },
    "Keys": {
      "Hanzi": 5399
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5399,
      "Radical": "木",
      "FrequencyRank": 4105,
      "RadicalIndex": "75.1",
      "StrokeCount": 13
    }
  },
  "颌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "mouth; jaw"
    },
    "Keys": {
      "Hanzi": 5221
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5221,
      "Radical": "頁",
      "FrequencyRank": 3279,
      "RadicalIndex": "181.6",
      "StrokeCount": 12
    }
  },
  "深受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnshòu",
      "PrimaryDefinition": "passive signifier"
    },
    "Keys": {
      "Word": 9031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嵝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǒu",
      "PrimaryDefinition": "Goulou mountain peak in hunan"
    },
    "Keys": {
      "Hanzi": 5173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5173,
      "Radical": "山",
      "FrequencyRank": 5814,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "糠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāng",
      "PrimaryDefinition": "chaff, bran, husks; poor"
    },
    "Keys": {
      "Hanzi": 3429
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3429,
      "Radical": "米",
      "FrequencyRank": 4169,
      "RadicalIndex": "119.11",
      "StrokeCount": 17
    }
  },
  "吹捧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuīpěng",
      "PrimaryDefinition": "to flatter, to laud sb's accomplishments, adulation"
    },
    "Keys": {
      "Word": 6023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迟迟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chíchí",
      "PrimaryDefinition": "late (with a task etc), slow"
    },
    "Keys": {
      "Word": 5901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guó",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7401,
      "Radical": "肉",
      "FrequencyRank": 6660,
      "RadicalIndex": "130.11",
      "StrokeCount": 12
    }
  },
  "讲课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng kè",
      "PrimaryDefinition": "teach, lecture"
    },
    "Keys": {
      "Word": 4716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "难道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nándào",
      "PrimaryDefinition": "Do you really mean to say that..."
    },
    "Keys": {
      "Word": 1759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "转换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnhuàn",
      "PrimaryDefinition": "change, transform"
    },
    "Keys": {
      "Word": 4264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "制度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìdù",
      "PrimaryDefinition": "ism, organization, dispensation, regimen, machine, regulations, form, system, regime, organisation, economy, institutional, institution"
    },
    "Keys": {
      "Word": 2175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "洁净": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéjìng",
      "PrimaryDefinition": "clean, to cleanse"
    },
    "Keys": {
      "Word": 7524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuyì",
      "PrimaryDefinition": "have a mind to, be interested in"
    },
    "Keys": {
      "Word": 10485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㺄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "(same as 狳) (a variant of 貐) a kind of beast"
    },
    "Keys": {
      "Hanzi": 7410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7410,
      "Radical": "犬",
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "望见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàngjiàn",
      "PrimaryDefinition": "to espy, to spot"
    },
    "Keys": {
      "Word": 5135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "榰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7647,
      "Radical": "木",
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "处长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùzhǎng",
      "PrimaryDefinition": "department, section chief, commissioner, department/office head, department head, head of a department, office head"
    },
    "Keys": {
      "Word": 4439
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "原告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuángào",
      "PrimaryDefinition": "complainant, plaintiff"
    },
    "Keys": {
      "Word": 5289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "悟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "to apprehend, realize, become aware"
    },
    "Keys": {
      "Hanzi": 2091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "GeneralStandard": 2091,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1668,
      "StrokeCount": 10
    }
  },
  "匆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "hastily, in haste, hurriedly"
    },
    "Keys": {
      "Hanzi": 289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "20.3",
      "GeneralStandard": 289,
      "Radical": "勹",
      "HSK": 8,
      "FrequencyRank": 1622,
      "StrokeCount": 5
    }
  },
  "扪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mén",
      "PrimaryDefinition": "stoke, pat, feel by hand, grope"
    },
    "Keys": {
      "Hanzi": 3575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3575,
      "Radical": "手",
      "FrequencyRank": 4416,
      "RadicalIndex": "64.3",
      "StrokeCount": 6
    }
  },
  "的话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dehuà",
      "PrimaryDefinition": "conj.: if"
    },
    "Keys": {
      "Word": 612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鲩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "carp"
    },
    "Keys": {
      "Hanzi": 5974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5974,
      "Radical": "魚",
      "FrequencyRank": 6686,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "禔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "happiness; peace; good fortune"
    },
    "Keys": {
      "Hanzi": 7605
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7605,
      "Radical": "示",
      "FrequencyRank": 9028,
      "RadicalIndex": "113.9",
      "StrokeCount": 13
    }
  },
  "讶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "express surprise, be surprised"
    },
    "Keys": {
      "Hanzi": 553
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 553,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2162,
      "StrokeCount": 6
    }
  },
  "载": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zài",
      "PrimaryDefinition": "load; carry; transport, convey"
    },
    "Keys": {
      "Hanzi": 1787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "62.6",
      "GeneralStandard": 1787,
      "Radical": "戈",
      "HSK": 4,
      "FrequencyRank": 977,
      "StrokeCount": 10
    }
  },
  "精": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "essence; semen; spirit"
    },
    "Keys": {
      "Hanzi": 3153,
      "Word": 4747
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.8",
      "Grade": 6,
      "GeneralStandard": 3153,
      "Radical": "米",
      "HSK": 3,
      "FrequencyRank": 435,
      "StrokeCount": 14
    }
  },
  "就要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùyào",
      "PrimaryDefinition": "will, shall, to be going to"
    },
    "Keys": {
      "Word": 796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "雄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xióng",
      "PrimaryDefinition": "male of species; hero; manly"
    },
    "Keys": {
      "Hanzi": 2596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "172.4",
      "GeneralStandard": 2596,
      "Radical": "隹",
      "HSK": 5,
      "FrequencyRank": 1054,
      "StrokeCount": 12
    }
  },
  "从": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóng",
      "PrimaryDefinition": "from, by, since, whence, through"
    },
    "Keys": {
      "Hanzi": 142,
      "Word": 55
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "Grade": 1,
      "GeneralStandard": 142,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 98,
      "StrokeCount": 4
    }
  },
  "嫦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "name of a moon goddess"
    },
    "Keys": {
      "Hanzi": 5833
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.11",
      "GeneralStandard": 5833,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 3166,
      "StrokeCount": 14
    }
  },
  "崛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "towering, eminent; rise abruptly"
    },
    "Keys": {
      "Hanzi": 2300
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.8",
      "GeneralStandard": 2300,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 3068,
      "StrokeCount": 11
    }
  },
  "特征": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèzhēng",
      "PrimaryDefinition": "impress, trait, hallmark, cachet, stamp, characteristic, speciality, cast, feature, characteristic marks, features, artistic style, touch, trademark, earmark, particularity, nature, genius, imprint, lineament, mold, shtick, distinction, specificity, physiognomy, idiom, signature, character, salience, diagnosis, syndrome, stigma, stylemark, signalment"
    },
    "Keys": {
      "Word": 2920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "换言之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànyánzhī",
      "PrimaryDefinition": "in other words"
    },
    "Keys": {
      "Word": 7176
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "Chinese people; Chinese language"
    },
    "Keys": {
      "Hanzi": 311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.2",
      "GeneralStandard": 311,
      "Radical": "水",
      "HSK": 1,
      "FrequencyRank": 711,
      "StrokeCount": 5
    }
  },
  "变化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànhuà",
      "PrimaryDefinition": "change, transformation"
    },
    "Keys": {
      "Word": 1298
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "仁慈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réncí",
      "PrimaryDefinition": "benevolent, charitable, kind, kindly, kindness, merciful"
    },
    "Keys": {
      "Word": 8857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成问题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng wèntí",
      "PrimaryDefinition": "Problem"
    },
    "Keys": {
      "Word": 5873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "ward off, evade; parry; block"
    },
    "Keys": {
      "Hanzi": 5358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5358,
      "Radical": "手",
      "FrequencyRank": 4057,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "高铁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāotiě",
      "PrimaryDefinition": "high speed rail"
    },
    "Keys": {
      "Word": 2492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "阴影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnyǐng",
      "PrimaryDefinition": "umbrage, shadow, shadiness, hatching, spectre, hatch, blight, umbra, penumbra, cloud, umber, shade, shadowiness"
    },
    "Keys": {
      "Word": 5254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "楒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7496,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "廿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàn",
      "PrimaryDefinition": "twenty, twentieth"
    },
    "Keys": {
      "Hanzi": 3510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3510,
      "Radical": "廾",
      "FrequencyRank": 3383,
      "RadicalIndex": "55.1",
      "StrokeCount": 4
    }
  },
  "圆珠笔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánzhūbǐ",
      "PrimaryDefinition": "ballpoint pen, CL:支[zhi1],枝[zhi1]"
    },
    "Keys": {
      "Word": 5291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鞯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "saddle blanket"
    },
    "Keys": {
      "Hanzi": 6315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6315,
      "Radical": "革",
      "FrequencyRank": 4514,
      "RadicalIndex": "177.9",
      "StrokeCount": 18
    }
  },
  "抄袭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāoxí",
      "PrimaryDefinition": "to plagiarize, to copy, to attack the flank or rear of an enemy"
    },
    "Keys": {
      "Word": 5825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心慌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn huāng",
      "PrimaryDefinition": "to be flustered, (dialect) irregular heart-beat"
    },
    "Keys": {
      "Word": 10007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "就医": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù yī",
      "PrimaryDefinition": "seek medical advice"
    },
    "Keys": {
      "Word": 7671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "媾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "marry; be on friendly terms with"
    },
    "Keys": {
      "Hanzi": 5604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5604,
      "Radical": "女",
      "FrequencyRank": 4524,
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "下岗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià gǎng",
      "PrimaryDefinition": "to come off sentry duty, to lay off (a worker), laid-off"
    },
    "Keys": {
      "Word": 9857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "a kind of jade"
    },
    "Keys": {
      "Hanzi": 5623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5623,
      "Radical": "玉",
      "FrequencyRank": 5267,
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "湛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "deep; profound; clear; tranquil, placid"
    },
    "Keys": {
      "Hanzi": 5277
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5277,
      "Radical": "水",
      "FrequencyRank": 3189,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "董事会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒngshìhuì",
      "PrimaryDefinition": "board, board of directors, board of administration, syndicate, directorate, board of regents, board of trustees, board_of_directors, directory"
    },
    "Keys": {
      "Word": 6327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "差点儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàdiǎnr5",
      "PrimaryDefinition": "not quite, not good enough, almost, barely, a bit different, just about to, nearly starting, on the verge of, erhua variant of 差點|差点[cha4 dian3]"
    },
    "Keys": {
      "Word": 3306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "人生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénshēng",
      "PrimaryDefinition": "lifetime, life, lifespan, human life, time of life"
    },
    "Keys": {
      "Word": 1849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "筵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "bamboo mat; feast, banquet"
    },
    "Keys": {
      "Hanzi": 5206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5206,
      "Radical": "竹",
      "FrequencyRank": 3876,
      "RadicalIndex": "118.7",
      "StrokeCount": 12
    }
  },
  "世袭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìxí",
      "PrimaryDefinition": "succession, inheritance, hereditary"
    },
    "Keys": {
      "Word": 9133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "参展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cān zhǎn",
      "PrimaryDefinition": "participate in exhibition"
    },
    "Keys": {
      "Word": 4366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "罄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìng",
      "PrimaryDefinition": "exhaust, run out, use up; empty"
    },
    "Keys": {
      "Hanzi": 6203
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6203,
      "Radical": "缶",
      "FrequencyRank": 4542,
      "RadicalIndex": "121.11",
      "StrokeCount": 17
    }
  },
  "袈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiā",
      "PrimaryDefinition": "Buddhist cassock"
    },
    "Keys": {
      "Hanzi": 5023
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5023,
      "Radical": "衣",
      "FrequencyRank": 4833,
      "RadicalIndex": "145.5",
      "StrokeCount": 11
    }
  },
  "侵害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnhài",
      "PrimaryDefinition": "encroach on (other's rights), violate"
    },
    "Keys": {
      "Word": 8714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厨房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúfáng",
      "PrimaryDefinition": "office, cookery, cookhouse, kitchen, cookroom, but, caboose"
    },
    "Keys": {
      "Word": 3347
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "潮流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháoliú",
      "PrimaryDefinition": "stream, tidal flow, tidal_current, tideway, trend, movement, wind, sea, fashion, tide, tidal current"
    },
    "Keys": {
      "Word": 2304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "篷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péng",
      "PrimaryDefinition": "awning, covering; sail; boat"
    },
    "Keys": {
      "Hanzi": 3359
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.11",
      "GeneralStandard": 3359,
      "Radical": "竹",
      "HSK": 7,
      "FrequencyRank": 2579,
      "StrokeCount": 16
    }
  },
  "迷失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míshī",
      "PrimaryDefinition": "to lose (one's bearings), to get lost"
    },
    "Keys": {
      "Word": 8260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèn",
      "PrimaryDefinition": "dust, earth; a bank of earth; to dig; to bring together"
    },
    "Keys": {
      "Hanzi": 3741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3741,
      "Radical": "土",
      "FrequencyRank": 6582,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "失恋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī liàn",
      "PrimaryDefinition": "to lose one's love, to break up (in a romantic relationship), to feel jilted"
    },
    "Keys": {
      "Word": 9084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "scissors; cut, divide, separate"
    },
    "Keys": {
      "Hanzi": 2406,
      "Word": 3598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.9",
      "Grade": 5,
      "GeneralStandard": 2406,
      "Radical": "刀",
      "HSK": 5,
      "FrequencyRank": 2227,
      "StrokeCount": 11
    }
  },
  "夏季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàjì",
      "PrimaryDefinition": "summer"
    },
    "Keys": {
      "Word": 3008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瓿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "jar, pot"
    },
    "Keys": {
      "Hanzi": 5262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5262,
      "Radical": "瓦",
      "FrequencyRank": 6024,
      "RadicalIndex": "98.8",
      "StrokeCount": 12
    }
  },
  "禽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "birds, fowl; surname;; capture"
    },
    "Keys": {
      "Hanzi": 2711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "114.8",
      "GeneralStandard": 2711,
      "Radical": "禸",
      "HSK": 9,
      "FrequencyRank": 2562,
      "StrokeCount": 12
    }
  },
  "涤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "wash, cleanse, purify; sweep"
    },
    "Keys": {
      "Hanzi": 2079
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2079,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3218,
      "StrokeCount": 10
    }
  },
  "氨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "ammonia; hydrogen nitride"
    },
    "Keys": {
      "Hanzi": 1937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1937,
      "Radical": "气",
      "FrequencyRank": 3028,
      "RadicalIndex": "84.6",
      "StrokeCount": 10
    }
  },
  "鲁莽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔmǎng",
      "PrimaryDefinition": "hot-headed, impulsive, reckless"
    },
    "Keys": {
      "Word": 8126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shē",
      "PrimaryDefinition": "a wild cat; 猞猁, a lynx"
    },
    "Keys": {
      "Hanzi": 4927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4927,
      "Radical": "犬",
      "FrequencyRank": 6224,
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "超越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāoyuè",
      "PrimaryDefinition": "overpass, get_ahead, outdo, overshoot, outstrip, overstep, excel, outgo, outgrow, outmatch, overpeer, surpass, tower, exceed, overrun, outrun, transcend, cut_across, forerun, outclass, soar, transgress, antecede, beggar, break, outdistance, surmount"
    },
    "Keys": {
      "Word": 3317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "宽厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuānhòu",
      "PrimaryDefinition": "tolerant, generous, magnanimous, thick and broad (build), thick and deep (voice)"
    },
    "Keys": {
      "Word": 7879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聚会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùhuì",
      "PrimaryDefinition": "get-together, gathering"
    },
    "Keys": {
      "Word": 2649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "如实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúshí",
      "PrimaryDefinition": "as things really are, realistic"
    },
    "Keys": {
      "Word": 8895
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàng",
      "PrimaryDefinition": "bury, inter"
    },
    "Keys": {
      "Hanzi": 2548,
      "Word": 10607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "Grade": 7,
      "GeneralStandard": 2548,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 2060,
      "StrokeCount": 12
    }
  },
  "责任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zérèn",
      "PrimaryDefinition": "need, care, encumbrance, buck, accountability, blame, obligation, turn, load, responsibleness, incumbrance, responsibility, onus, commission, burden, commitment, loading, trust, pigeon, charge, business, fault, place, liability, duty, guardianship"
    },
    "Keys": {
      "Word": 2131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "崔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuī",
      "PrimaryDefinition": "high, lofty, towering; surname"
    },
    "Keys": {
      "Hanzi": 2296
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2296,
      "Radical": "山",
      "FrequencyRank": 2552,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "愈演愈烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùyǎn-yùliè",
      "PrimaryDefinition": "ever more critical, problems get more and more intense"
    },
    "Keys": {
      "Word": 10531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngtài",
      "PrimaryDefinition": "situation, the movement (in a certain sphere of human activity), development, trend, dynamic state, dynamic, trends"
    },
    "Keys": {
      "Word": 3428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "出具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūjù",
      "PrimaryDefinition": "to issue (document, certificate etc), to provide"
    },
    "Keys": {
      "Word": 5958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔ",
      "PrimaryDefinition": "ancestor, forefather; grandfather"
    },
    "Keys": {
      "Hanzi": 1710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.5",
      "GeneralStandard": 1710,
      "Radical": "示",
      "HSK": 6,
      "FrequencyRank": 1025,
      "StrokeCount": 9
    }
  },
  "药品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàopǐn",
      "PrimaryDefinition": "pharmaceutical, medicines and chemical reagents, drug, restorative"
    },
    "Keys": {
      "Word": 5221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "氐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "name of an ancient tribe"
    },
    "Keys": {
      "Hanzi": 3548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3548,
      "Radical": "氏",
      "FrequencyRank": 4355,
      "RadicalIndex": "83.1",
      "StrokeCount": 5
    }
  },
  "躔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "follow in, imitate; rut, path"
    },
    "Keys": {
      "Hanzi": 8093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8093,
      "Radical": "足",
      "FrequencyRank": 7321,
      "RadicalIndex": "157.15",
      "StrokeCount": 22
    }
  },
  "涨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎng",
      "PrimaryDefinition": "rise in price"
    },
    "Keys": {
      "Hanzi": 2086,
      "Word": 5329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 6,
      "GeneralStandard": 2086,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1803,
      "StrokeCount": 10
    }
  },
  "转弯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn wān",
      "PrimaryDefinition": "to turn, to go around a corner"
    },
    "Keys": {
      "Word": 3199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "速度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùdù",
      "PrimaryDefinition": "pacing, wings, rate, quickness, fastness, velocity, swiftness, lick, pace, rapidity, speed, career, tempo"
    },
    "Keys": {
      "Word": 1944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "path, road, street; method, way"
    },
    "Keys": {
      "Hanzi": 2745,
      "Word": 606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "Grade": 2,
      "GeneralStandard": 2745,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 52,
      "StrokeCount": 12
    }
  },
  "岂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "how? what?"
    },
    "Keys": {
      "Hanzi": 439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.3",
      "GeneralStandard": 439,
      "Radical": "山",
      "HSK": 9,
      "FrequencyRank": 1726,
      "StrokeCount": 6
    }
  },
  "转身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎn shēn",
      "PrimaryDefinition": "turn round, go_about, face about, turn"
    },
    "Keys": {
      "Word": 3198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "堍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4730,
      "Radical": "土",
      "FrequencyRank": 6889,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "没": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "not, have not, none; drown, sink"
    },
    "Keys": {
      "Hanzi": 867,
      "Word": 229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "Grade": 1,
      "GeneralStandard": 867,
      "Radical": "水",
      "HSK": 1,
      "FrequencyRank": 72,
      "StrokeCount": 7
    }
  },
  "发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fā",
      "PrimaryDefinition": "issue, dispatch, send out; hair"
    },
    "Keys": {
      "Hanzi": 339,
      "Word": 648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.3",
      "Grade": 2,
      "GeneralStandard": 339,
      "Radical": "又",
      "HSK": 2,
      "FrequencyRank": 47,
      "StrokeCount": 5
    }
  },
  "廒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "a granary"
    },
    "Keys": {
      "Hanzi": 7571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7571,
      "Radical": "广",
      "FrequencyRank": 7336,
      "RadicalIndex": "53.11",
      "StrokeCount": 13
    }
  },
  "罕见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎnjiàn",
      "PrimaryDefinition": "rare, rarely seen"
    },
    "Keys": {
      "Word": 6985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "ashamed, conscience-stricken"
    },
    "Keys": {
      "Hanzi": 2776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "GeneralStandard": 2776,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 2275,
      "StrokeCount": 12
    }
  },
  "拊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "slap; pat; tap; handle of a vessel"
    },
    "Keys": {
      "Hanzi": 3845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3845,
      "Radical": "手",
      "FrequencyRank": 5779,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "僵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "stiff and motionless, stock still"
    },
    "Keys": {
      "Hanzi": 3269,
      "Word": 7440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.13",
      "Grade": 7,
      "GeneralStandard": 3269,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 2333,
      "StrokeCount": 15
    }
  },
  "鲖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "snakefish"
    },
    "Keys": {
      "Hanzi": 7702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7702,
      "Radical": "魚",
      "FrequencyRank": 9932,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "乓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāng",
      "PrimaryDefinition": "used with ping for ping pong"
    },
    "Keys": {
      "Hanzi": 457
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.5",
      "GeneralStandard": 457,
      "Radical": "丿",
      "HSK": 8,
      "FrequencyRank": 3502,
      "StrokeCount": 6
    }
  },
  "擞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒu",
      "PrimaryDefinition": "shake, tremble, quake; flutter"
    },
    "Keys": {
      "Hanzi": 6042
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6042,
      "Radical": "手",
      "FrequencyRank": 3870,
      "RadicalIndex": "64.13",
      "StrokeCount": 16
    }
  },
  "路途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùtú",
      "PrimaryDefinition": "Route"
    },
    "Keys": {
      "Word": 8136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讲座": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngzuò",
      "PrimaryDefinition": "lecture, professorship, cathedra, talk-in, course of lecture, chair, course of lectures"
    },
    "Keys": {
      "Word": 2610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "徘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái",
      "PrimaryDefinition": "walk back and forth, hesitate"
    },
    "Keys": {
      "Hanzi": 2339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.8",
      "GeneralStandard": 2339,
      "Radical": "彳",
      "HSK": 8,
      "FrequencyRank": 3084,
      "StrokeCount": 11
    }
  },
  "蜱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "a tick, mite"
    },
    "Keys": {
      "Hanzi": 5709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5709,
      "Radical": "虫",
      "FrequencyRank": 6524,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "鹄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "target"
    },
    "Keys": {
      "Hanzi": 5199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5199,
      "Radical": "鳥",
      "FrequencyRank": 4238,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "负责": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzé",
      "PrimaryDefinition": "preside, be responsible for, see, entail, sponsor, be in charge of, blame, answer, undertake, liable, respond"
    },
    "Keys": {
      "Word": 1464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "憨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hān",
      "PrimaryDefinition": "foolish, silly, coquettish"
    },
    "Keys": {
      "Hanzi": 3309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3309,
      "Radical": "心",
      "FrequencyRank": 3722,
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "缎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": "satin"
    },
    "Keys": {
      "Hanzi": 2817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2817,
      "Radical": "糸",
      "FrequencyRank": 3108,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "也就是说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yějiùshìshuō",
      "PrimaryDefinition": "i.e."
    },
    "Keys": {
      "Word": 10254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "in opposition; distant from; separated"
    },
    "Keys": {
      "Hanzi": 5451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5451,
      "Radical": "日",
      "FrequencyRank": 5760,
      "RadicalIndex": "72.9",
      "StrokeCount": 13
    }
  },
  "晖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "sunshine; light, bright, radiant"
    },
    "Keys": {
      "Hanzi": 6991
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6991,
      "Radical": "日",
      "FrequencyRank": 3489,
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "耳机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěrjī",
      "PrimaryDefinition": "headphones, earphones, telephone receiver"
    },
    "Keys": {
      "Word": 2443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "胬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5022,
      "Radical": "肉",
      "FrequencyRank": 6473,
      "RadicalIndex": "130.5",
      "StrokeCount": 11
    }
  },
  "勤工俭学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngōng-jiǎnxué",
      "PrimaryDefinition": "to work part time while studying, work-study program"
    },
    "Keys": {
      "Word": 8728
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "适应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìyìng",
      "PrimaryDefinition": "be accustomed to, conform, be used to, fit, humor, gear, orient, phase, adjust, accommodate, be accustomed, be inure to, get used to, adaptation, adapt, suit"
    },
    "Keys": {
      "Word": 1920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "通讯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngxùn",
      "PrimaryDefinition": "communication, news report, newsletter"
    },
    "Keys": {
      "Word": 5096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "名贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngguì",
      "PrimaryDefinition": "famous and valuable, rare, precious"
    },
    "Keys": {
      "Word": 8303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昆虫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūnchóng",
      "PrimaryDefinition": "bug, creeper, hexapod, coreid, insect, dor, coreid bug"
    },
    "Keys": {
      "Word": 7896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǔ",
      "PrimaryDefinition": "humiliate, insult, abuse"
    },
    "Keys": {
      "Hanzi": 1860
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "161.3",
      "GeneralStandard": 1860,
      "Radical": "辰",
      "HSK": 7,
      "FrequencyRank": 1907,
      "StrokeCount": 10
    }
  },
  "映": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìng",
      "PrimaryDefinition": "project; reflect light"
    },
    "Keys": {
      "Hanzi": 1487,
      "Word": 10427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "Grade": 7,
      "GeneralStandard": 1487,
      "Radical": "日",
      "HSK": 4,
      "FrequencyRank": 1316,
      "StrokeCount": 9
    }
  },
  "佞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nìng",
      "PrimaryDefinition": "flattery; glib"
    },
    "Keys": {
      "Hanzi": 3728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3728,
      "Radical": "人",
      "FrequencyRank": 4690,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "野餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěcān",
      "PrimaryDefinition": "picnic, to have a picnic"
    },
    "Keys": {
      "Word": 10256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "chin, jowl; give nod"
    },
    "Keys": {
      "Hanzi": 5516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5516,
      "Radical": "頁",
      "FrequencyRank": 4158,
      "RadicalIndex": "181.7",
      "StrokeCount": 13
    }
  },
  "意思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìsi",
      "PrimaryDefinition": "significance, token of infection, intention, interest, idea, trace, meaning, thought, opinion, substance, etc., import, a token of appreciation/etc., indirect request, wish, a token of affection, desire, appreciation, fun, signification, gratitude, sense, hint, a token of affection/appreciation/etc., suggestion, mind, intent"
    },
    "Keys": {
      "Word": 1156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "杩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mà",
      "PrimaryDefinition": "headboard"
    },
    "Keys": {
      "Hanzi": 6582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6582,
      "Radical": "木",
      "FrequencyRank": 6335,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "瑱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": "a gem used as ear plug; a jade earring"
    },
    "Keys": {
      "Hanzi": 7624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7624,
      "Radical": "玉",
      "RadicalIndex": "96.1",
      "StrokeCount": 14
    }
  },
  "嗅觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiùjué",
      "PrimaryDefinition": "sense of smell"
    },
    "Keys": {
      "Word": 10086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒ",
      "PrimaryDefinition": "chemical element; (Cant.) backing, support of someone powerful; a little"
    },
    "Keys": {
      "Hanzi": 4223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4223,
      "Radical": "口",
      "FrequencyRank": 6558,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "拽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāi",
      "PrimaryDefinition": "drag, tow; throw; twist"
    },
    "Keys": {
      "Hanzi": 1380,
      "Word": 10876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1380,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 3248,
      "StrokeCount": 9
    }
  },
  "遑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "leisure, leisurely; hurry about"
    },
    "Keys": {
      "Hanzi": 5214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5214,
      "Radical": "辵",
      "FrequencyRank": 5020,
      "RadicalIndex": "162.9",
      "StrokeCount": 12
    }
  },
  "苒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǎn",
      "PrimaryDefinition": "lush; successively, in order"
    },
    "Keys": {
      "Hanzi": 3861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3861,
      "Radical": "艸",
      "FrequencyRank": 6094,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "逮捕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàibǔ",
      "PrimaryDefinition": "make an arrest"
    },
    "Keys": {
      "Word": 6154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "连忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánmáng",
      "PrimaryDefinition": "promptly, at once"
    },
    "Keys": {
      "Word": 1704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "矿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "mine; mineral, ore"
    },
    "Keys": {
      "Hanzi": 1045,
      "Word": 4803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.3",
      "Grade": 6,
      "GeneralStandard": 1045,
      "Radical": "石",
      "HSK": 4,
      "FrequencyRank": 1385,
      "StrokeCount": 8
    }
  },
  "幽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "quiet, secluded, tranquil; dark"
    },
    "Keys": {
      "Hanzi": 1524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "52.6",
      "GeneralStandard": 1524,
      "Radical": "幺",
      "HSK": 5,
      "FrequencyRank": 1636,
      "StrokeCount": 9
    }
  },
  "劓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "cut off nose"
    },
    "Keys": {
      "Hanzi": 6130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6130,
      "Radical": "刀",
      "FrequencyRank": 6733,
      "RadicalIndex": "18.14",
      "StrokeCount": 16
    }
  },
  "规范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīfàn",
      "PrimaryDefinition": "constraint, norm"
    },
    "Keys": {
      "Word": 1531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "先后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānhòu",
      "PrimaryDefinition": "early or late, priority, in succession, one after another"
    },
    "Keys": {
      "Word": 4033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "巴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "greatly desire, anxiously hope"
    },
    "Keys": {
      "Hanzi": 178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "49.1",
      "GeneralStandard": 178,
      "Radical": "己",
      "HSK": 4,
      "FrequencyRank": 546,
      "StrokeCount": 4
    }
  },
  "名称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngchēng",
      "PrimaryDefinition": "title, denotation, compellation, appellative, designation, name, denomination, calling, rubric, appellation"
    },
    "Keys": {
      "Word": 871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "昏迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūnmí",
      "PrimaryDefinition": "to lose consciousness, to be in a coma, stupor, coma, stunned, disoriented"
    },
    "Keys": {
      "Word": 7230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "例子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìzi",
      "PrimaryDefinition": "exemplification, example, representative, illustration, instance, piece, case"
    },
    "Keys": {
      "Word": 843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "冖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "cover"
    },
    "Keys": {
      "Radical": 14
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "翎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "feather; plume; wing"
    },
    "Keys": {
      "Hanzi": 4920
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4920,
      "Radical": "羽",
      "FrequencyRank": 3417,
      "RadicalIndex": "124.5",
      "StrokeCount": 11
    }
  },
  "哄堂大笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōngtáng-dàxiào",
      "PrimaryDefinition": "the whole room roaring with laughter (idiom)"
    },
    "Keys": {
      "Word": 7074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "极少数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíshǎoshù",
      "PrimaryDefinition": "extremely few, a small minority"
    },
    "Keys": {
      "Word": 7295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kān",
      "PrimaryDefinition": "subjugate, subdue, quell; kill"
    },
    "Keys": {
      "Hanzi": 5368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5368,
      "Radical": "戈",
      "FrequencyRank": 4817,
      "RadicalIndex": "62.9",
      "StrokeCount": 13
    }
  },
  "养生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎngshēng",
      "PrimaryDefinition": "to maintain good health, to raise a child or animal, curing (of concrete etc)"
    },
    "Keys": {
      "Word": 10224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "魁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "chief; leader; best; monstrous"
    },
    "Keys": {
      "Hanzi": 2963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "194.4",
      "GeneralStandard": 2963,
      "Radical": "鬼",
      "HSK": 8,
      "FrequencyRank": 2599,
      "StrokeCount": 13
    }
  },
  "失业率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīyèlǜ",
      "PrimaryDefinition": "unemployment rate"
    },
    "Keys": {
      "Word": 9091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "book"
    },
    "Keys": {
      "Hanzi": 5753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5753,
      "Radical": "竹",
      "FrequencyRank": 3864,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "文凭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénpíng",
      "PrimaryDefinition": "diploma"
    },
    "Keys": {
      "Word": 9735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "收敛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuliǎn",
      "PrimaryDefinition": "to vanish, to moderate, to exercise restraint, to curb (one's mirth, arrogance etc), to astringe, (math.) to converge"
    },
    "Keys": {
      "Word": 9165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分辨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnbiàn",
      "PrimaryDefinition": "distinguish, differentiate"
    },
    "Keys": {
      "Word": 6531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "violent, brutal, tyrannical"
    },
    "Keys": {
      "Hanzi": 3234
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.11",
      "GeneralStandard": 3234,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 1028,
      "StrokeCount": 15
    }
  },
  "有的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒude",
      "PrimaryDefinition": "det : some"
    },
    "Keys": {
      "Word": 437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "依依不舍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyī-bùshě",
      "PrimaryDefinition": "reluctant to part (idiom), broken-hearted at having to leave"
    },
    "Keys": {
      "Word": 10280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎo",
      "PrimaryDefinition": "yellow dirt mount"
    },
    "Keys": {
      "Hanzi": 3929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3929,
      "Radical": "山",
      "FrequencyRank": 4812,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "铲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "spade, shovel, trowel, scoop"
    },
    "Keys": {
      "Hanzi": 2308,
      "Word": 5804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.6",
      "Grade": 7,
      "GeneralStandard": 2308,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 3057,
      "StrokeCount": 11
    }
  },
  "锑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tī",
      "PrimaryDefinition": "antimony"
    },
    "Keys": {
      "Hanzi": 5187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5187,
      "Radical": "金",
      "FrequencyRank": 5396,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "常温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángwēn",
      "PrimaryDefinition": "room temperature, ordinary temperatures"
    },
    "Keys": {
      "Word": 5817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "误解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùjiě",
      "PrimaryDefinition": "misunderstanding"
    },
    "Keys": {
      "Word": 4023
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "碣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "stone tablet"
    },
    "Keys": {
      "Hanzi": 5678
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5678,
      "Radical": "石",
      "FrequencyRank": 4779,
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "战斗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàndòu",
      "PrimaryDefinition": "to fight, to battle, CL:場|场[chang2],次[ci4]"
    },
    "Keys": {
      "Word": 3149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "教学楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoxuélóu",
      "PrimaryDefinition": "teaching block, school building"
    },
    "Keys": {
      "Word": 174
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "铝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "aluminum"
    },
    "Keys": {
      "Hanzi": 2305,
      "Word": 8141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.6",
      "Grade": 7,
      "GeneralStandard": 2305,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 3217,
      "StrokeCount": 11
    }
  },
  "换成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànchéng",
      "PrimaryDefinition": "change to"
    },
    "Keys": {
      "Word": 7173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相反": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngfǎn",
      "PrimaryDefinition": "offending, adverse, opposite, converse, opposite, contrary, oppose, reverse"
    },
    "Keys": {
      "Word": 3018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "裤子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùzi",
      "PrimaryDefinition": "breeches, pant, unmentionables, britches, trouser, continuation, breeks, bags, unmentionable, inexpressible, pants, pantaloon, trousers"
    },
    "Keys": {
      "Word": 1678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "强劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángjìng",
      "PrimaryDefinition": "strong, forceful, sturdy, powerful"
    },
    "Keys": {
      "Word": 8689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䃎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "name of a place (usually to be used in naming a place) (interchangeable 砟) small piece of coal"
    },
    "Keys": {
      "Hanzi": 7660
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7660,
      "Radical": "石",
      "RadicalIndex": "112.9",
      "StrokeCount": 14
    }
  },
  "夂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "go"
    },
    "Keys": {
      "Radical": 34
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "直视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíshì",
      "PrimaryDefinition": "to look straight at"
    },
    "Keys": {
      "Word": 10761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "male adult; robust, vigorous; 4th heavenly stem"
    },
    "Keys": {
      "Hanzi": 5,
      "Word": 6298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.1",
      "Grade": 7,
      "GeneralStandard": 5,
      "Radical": "一",
      "HSK": 7,
      "FrequencyRank": 1168,
      "StrokeCount": 2
    }
  },
  "颛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuān",
      "PrimaryDefinition": "good, honest; simple; respectful"
    },
    "Keys": {
      "Hanzi": 5938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5938,
      "Radical": "頁",
      "FrequencyRank": 5492,
      "RadicalIndex": "181.9",
      "StrokeCount": 15
    }
  },
  "蓁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "abundant, luxuriant vegetation"
    },
    "Keys": {
      "Hanzi": 5367
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5367,
      "Radical": "艸",
      "FrequencyRank": 6630,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "货运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòyùn",
      "PrimaryDefinition": "freight transport, cargo, transported goods"
    },
    "Keys": {
      "Word": 7255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "a necklace made of precious stones"
    },
    "Keys": {
      "Hanzi": 5854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5854,
      "Radical": "玉",
      "FrequencyRank": 5791,
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "警惕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngtì",
      "PrimaryDefinition": "to be on the alert, vigilant, alert, on guard, to warn"
    },
    "Keys": {
      "Word": 7632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàidì",
      "PrimaryDefinition": "parts of the country other than where one is, place other than where one is"
    },
    "Keys": {
      "Word": 1043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "绘画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìhuà",
      "PrimaryDefinition": "painting"
    },
    "Keys": {
      "Word": 4685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "检讨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎntǎo",
      "PrimaryDefinition": "discuss thoroughly, self-criticize"
    },
    "Keys": {
      "Word": 7403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒng",
      "PrimaryDefinition": "swell; swollen; swelling"
    },
    "Keys": {
      "Hanzi": 1183,
      "Word": 5370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 6,
      "GeneralStandard": 1183,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 1792,
      "StrokeCount": 8
    }
  },
  "不满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùmǎn",
      "PrimaryDefinition": "resentful, dissatisfied"
    },
    "Keys": {
      "Word": 532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "一毛不拔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīmáo-bùbá",
      "PrimaryDefinition": "stingy (idiom)"
    },
    "Keys": {
      "Word": 10336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "透": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòu",
      "PrimaryDefinition": "penetrate, pass through"
    },
    "Keys": {
      "Hanzi": 1950,
      "Word": 2949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 4,
      "GeneralStandard": 1950,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 1077,
      "StrokeCount": 10
    }
  },
  "航": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háng",
      "PrimaryDefinition": "sail; navigate; ship, boat"
    },
    "Keys": {
      "Hanzi": 1981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "137.4",
      "GeneralStandard": 1981,
      "Radical": "舟",
      "HSK": 4,
      "FrequencyRank": 773,
      "StrokeCount": 10
    }
  },
  "形式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngshì",
      "PrimaryDefinition": "circumstance, form, kind, modality, reading, interpretation, variety, shape, embodiment, species, mode, version, sort"
    },
    "Keys": {
      "Word": 2057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "宇航员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔhángyuán",
      "PrimaryDefinition": "astronaut"
    },
    "Keys": {
      "Word": 5282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "飙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "whirlwind, stormy gale"
    },
    "Keys": {
      "Hanzi": 6074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "182.12",
      "GeneralStandard": 6074,
      "Radical": "風",
      "HSK": 9,
      "FrequencyRank": 4091,
      "StrokeCount": 16
    }
  },
  "猯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7409,
      "Radical": "犬",
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "入门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rùmén",
      "PrimaryDefinition": "entrance door, to enter a door, introduction (to a subject)"
    },
    "Keys": {
      "Word": 3849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "淳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chún",
      "PrimaryDefinition": "honest, simple, unsophisticated; cyanogen; ethane dinitrile"
    },
    "Keys": {
      "Hanzi": 2427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2427,
      "Radical": "水",
      "FrequencyRank": 3147,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "拯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěng",
      "PrimaryDefinition": "help, save, aid; lift, raise"
    },
    "Keys": {
      "Hanzi": 1398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "GeneralStandard": 1398,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2754,
      "StrokeCount": 9
    }
  },
  "燃烧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ránshāo",
      "PrimaryDefinition": "burn"
    },
    "Keys": {
      "Word": 2821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蟊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "a fly which is used similarly to cantharides"
    },
    "Keys": {
      "Hanzi": 6307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6307,
      "Radical": "虫",
      "FrequencyRank": 5414,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "佽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cì",
      "PrimaryDefinition": "aid; help"
    },
    "Keys": {
      "Hanzi": 6702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6702,
      "Radical": "人",
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "啜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuài",
      "PrimaryDefinition": "sip, suck up; sob, weep"
    },
    "Keys": {
      "Hanzi": 4854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4854,
      "Radical": "口",
      "FrequencyRank": 4008,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "豫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "relaxed, comfortable, at ease"
    },
    "Keys": {
      "Hanzi": 3313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "152.9",
      "GeneralStandard": 3313,
      "Radical": "豕",
      "HSK": 5,
      "FrequencyRank": 1956,
      "StrokeCount": 15
    }
  },
  "低估": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīgū",
      "PrimaryDefinition": "to underestimate, to underrate"
    },
    "Keys": {
      "Word": 6227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "立即": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìjí",
      "PrimaryDefinition": "at_once, off-hand, promptly, at once, down, instantly, directively, straightway, herewith, thereupon, ex tempore, without_delay, pronto, right_away, instant, straight, immediately, in_short_order, right away, instanter, straightaway, in short order, off-the-cuff"
    },
    "Keys": {
      "Word": 2695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "缡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "a bridal veil; to tie; to bind"
    },
    "Keys": {
      "Hanzi": 5616
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5616,
      "Radical": "糸",
      "FrequencyRank": 5913,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "志气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìqi",
      "PrimaryDefinition": "ambition, resolve, backbone, drive, spirit"
    },
    "Keys": {
      "Word": 10784
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "意味着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìwèizhe",
      "PrimaryDefinition": "to signify, to mean, to imply"
    },
    "Keys": {
      "Word": 4118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "注": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "concentrate, focus, direct"
    },
    "Keys": {
      "Hanzi": 1252,
      "Word": 10870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 7,
      "GeneralStandard": 1252,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 492,
      "StrokeCount": 8
    }
  },
  "鸾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "fabulous bird"
    },
    "Keys": {
      "Hanzi": 4934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4934,
      "Radical": "鳥",
      "FrequencyRank": 3912,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "癸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "10th heavenly stem"
    },
    "Keys": {
      "Hanzi": 1748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1748,
      "Radical": "癶",
      "FrequencyRank": 4414,
      "RadicalIndex": "105.4",
      "StrokeCount": 9
    }
  },
  "鲜血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānxuè",
      "PrimaryDefinition": "blood"
    },
    "Keys": {
      "Word": 9889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "fierce dog; interjection of pleas; (Cant.) strange"
    },
    "Keys": {
      "Hanzi": 7341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7341,
      "Radical": "欠",
      "FrequencyRank": 5908,
      "RadicalIndex": "76.8",
      "StrokeCount": 12
    }
  },
  "顾及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù jí",
      "PrimaryDefinition": "study, give consideration to, consider, take into account, consult, attend to, allow_for"
    },
    "Keys": {
      "Word": 6843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "至此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìcǐ",
      "PrimaryDefinition": "up until now, so far"
    },
    "Keys": {
      "Word": 10781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "tortoise shell"
    },
    "Keys": {
      "Hanzi": 4088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4088,
      "Radical": "玉",
      "FrequencyRank": 5464,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "遹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "comply with, obey; shun, avoid"
    },
    "Keys": {
      "Hanzi": 7830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7830,
      "Radical": "辵",
      "FrequencyRank": 6626,
      "RadicalIndex": "162.12",
      "StrokeCount": 15
    }
  },
  "疏忽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūhu",
      "PrimaryDefinition": "ignore, neglect"
    },
    "Keys": {
      "Word": 9221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "传染": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánrǎn",
      "PrimaryDefinition": "infect, be contagious"
    },
    "Keys": {
      "Word": 6007
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "夜班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèbān",
      "PrimaryDefinition": "night shift"
    },
    "Keys": {
      "Word": 10263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "尚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng",
      "PrimaryDefinition": "still, yet; even; fairly, rather"
    },
    "Keys": {
      "Hanzi": 1075,
      "Word": 8988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "42.5",
      "Grade": 7,
      "GeneralStandard": 1075,
      "Radical": "小",
      "HSK": 4,
      "FrequencyRank": 878,
      "StrokeCount": 8
    }
  },
  "及早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jízǎo",
      "PrimaryDefinition": "at an early date, as soon as possible"
    },
    "Keys": {
      "Word": 7288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "档次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngcì",
      "PrimaryDefinition": "grade, class, quality, level"
    },
    "Keys": {
      "Word": 6186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "数据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùjù",
      "PrimaryDefinition": "data"
    },
    "Keys": {
      "Word": 2889
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "濞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6295,
      "Radical": "水",
      "FrequencyRank": 5982,
      "RadicalIndex": "85.14",
      "StrokeCount": 17
    }
  },
  "阿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ā",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.5",
      "GeneralStandard": 913,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 471,
      "StrokeCount": 7
    }
  },
  "鼯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "flying squirrel"
    },
    "Keys": {
      "Hanzi": 6440
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6440,
      "Radical": "鼠",
      "FrequencyRank": 6807,
      "RadicalIndex": "208.7",
      "StrokeCount": 20
    }
  },
  "筤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "young bamboo"
    },
    "Keys": {
      "Hanzi": 7546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7546,
      "Radical": "竹",
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "分泌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnmì",
      "PrimaryDefinition": "to secrete, secretion"
    },
    "Keys": {
      "Word": 6538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "言论": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánlùn",
      "PrimaryDefinition": "lecture, open discussion, talking to, speech"
    },
    "Keys": {
      "Word": 10178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "wicker, bamboo"
    },
    "Keys": {
      "Hanzi": 5205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5205,
      "Radical": "竹",
      "FrequencyRank": 6539,
      "RadicalIndex": "118.6",
      "StrokeCount": 12
    }
  },
  "轶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "rush forth; surpass, excel"
    },
    "Keys": {
      "Hanzi": 4181
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4181,
      "Radical": "車",
      "FrequencyRank": 4046,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "鞍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "saddle; any saddle-shaped object"
    },
    "Keys": {
      "Hanzi": 3208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3208,
      "Radical": "革",
      "FrequencyRank": 3168,
      "RadicalIndex": "177.6",
      "StrokeCount": 15
    }
  },
  "食堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shítáng",
      "PrimaryDefinition": "mess_hall, eatery, hall, buttery, commissariat, (institutional) dining room, mess hall, mess, refectory, dining room, canteen"
    },
    "Keys": {
      "Word": 2863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "耐人寻味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàirénxúnwèi",
      "PrimaryDefinition": "thought-provoking, worth thinking over, to provide food for thought"
    },
    "Keys": {
      "Word": 8370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "憬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "rouse, awaken; become conscious"
    },
    "Keys": {
      "Hanzi": 6006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6006,
      "Radical": "心",
      "FrequencyRank": 3693,
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "诤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "to expostulate; to remonstrate"
    },
    "Keys": {
      "Hanzi": 4056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4056,
      "Radical": "言",
      "FrequencyRank": 4449,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "各自": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèzì",
      "PrimaryDefinition": "each, respective, severalty, oneself"
    },
    "Keys": {
      "Word": 1494
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "冲撞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngzhuàng",
      "PrimaryDefinition": "to collide, jerking motion, to impinge, to offend, to provoke"
    },
    "Keys": {
      "Word": 5915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "差": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chà",
      "PrimaryDefinition": "to differ; different, wrong; nearly, almost; an officer"
    },
    "Keys": {
      "Hanzi": 1640,
      "Word": 35
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "48.7",
      "Grade": 1,
      "GeneralStandard": 1640,
      "Radical": "工",
      "HSK": 1,
      "FrequencyRank": 732,
      "StrokeCount": 9
    }
  },
  "欲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "desire, want, long for; intend"
    },
    "Keys": {
      "Hanzi": 2352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.7",
      "GeneralStandard": 2352,
      "Radical": "欠",
      "HSK": 6,
      "FrequencyRank": 1045,
      "StrokeCount": 11
    }
  },
  "厘米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "límǐ",
      "PrimaryDefinition": "centimeter"
    },
    "Keys": {
      "Word": 2690
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "进出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnchū",
      "PrimaryDefinition": "go in and out"
    },
    "Keys": {
      "Word": 7579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng",
      "PrimaryDefinition": "a moment; a measure of area equal to 100 mu or 6.7 hectares; to lean"
    },
    "Keys": {
      "Hanzi": 1058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.2",
      "GeneralStandard": 1058,
      "Radical": "頁",
      "HSK": 9,
      "FrequencyRank": 2724,
      "StrokeCount": 8
    }
  },
  "荩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "a kind of weed; faithfulness"
    },
    "Keys": {
      "Hanzi": 4133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4133,
      "Radical": "艸",
      "FrequencyRank": 4408,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "破灭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòmiè",
      "PrimaryDefinition": "to be shattered, to be annihilated (of hope, illusions etc)"
    },
    "Keys": {
      "Word": 8576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "indulge in; be negligent"
    },
    "Keys": {
      "Hanzi": 1818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.4",
      "GeneralStandard": 1818,
      "Radical": "耳",
      "HSK": 7,
      "FrequencyRank": 2658,
      "StrokeCount": 10
    }
  },
  "邽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "name of a county in Han dynasty"
    },
    "Keys": {
      "Hanzi": 6649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6649,
      "Radical": "邑",
      "FrequencyRank": 6509,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "暴力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàolì",
      "PrimaryDefinition": "violence, force"
    },
    "Keys": {
      "Word": 4310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鲲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": "spawn; roe; fy"
    },
    "Keys": {
      "Hanzi": 6141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6141,
      "Radical": "魚",
      "FrequencyRank": 5578,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "称作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēngzuò",
      "PrimaryDefinition": "to be called, to be known as"
    },
    "Keys": {
      "Word": 5866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngxiàn",
      "PrimaryDefinition": "ray_of_light, shaft, beam, beam of light, radiation, light beam, sunray, light, lighting, ray, ray of light, shaft of light"
    },
    "Keys": {
      "Word": 3519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "配置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèizhì",
      "PrimaryDefinition": "dispose (troops/etc.), deploy"
    },
    "Keys": {
      "Word": 4901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "双重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngchóng",
      "PrimaryDefinition": "twofold, dual, diploid, double"
    },
    "Keys": {
      "Word": 9252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎn",
      "PrimaryDefinition": "immature locusts"
    },
    "Keys": {
      "Hanzi": 5922
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5922,
      "Radical": "虫",
      "FrequencyRank": 6301,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "欢快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huānkuài",
      "PrimaryDefinition": "cheerful and lighthearted, lively"
    },
    "Keys": {
      "Word": 7162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "twist, wring; intertwine; winch"
    },
    "Keys": {
      "Hanzi": 1763,
      "Word": 7478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "Grade": 7,
      "GeneralStandard": 1763,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 2672,
      "StrokeCount": 9
    }
  },
  "橄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "olive"
    },
    "Keys": {
      "Hanzi": 3218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3218,
      "Radical": "木",
      "FrequencyRank": 3493,
      "RadicalIndex": "75.12",
      "StrokeCount": 15
    }
  },
  "嘱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "order, tell, instruct, leave word"
    },
    "Keys": {
      "Hanzi": 3253
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.12",
      "GeneralStandard": 3253,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2156,
      "StrokeCount": 15
    }
  },
  "儒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "Confucian scholar"
    },
    "Keys": {
      "Hanzi": 3361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.14",
      "GeneralStandard": 3361,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2006,
      "StrokeCount": 16
    }
  },
  "作风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòfēng",
      "PrimaryDefinition": "path, way of life, idiom, style, way, style of work, expressive style, fashion"
    },
    "Keys": {
      "Word": 11028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "china grass, ramie"
    },
    "Keys": {
      "Hanzi": 3676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3676,
      "Radical": "艸",
      "FrequencyRank": 5453,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "夺魁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duókuí",
      "PrimaryDefinition": "to seize, to win"
    },
    "Keys": {
      "Word": 6401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "violent, savage, cruel; bold"
    },
    "Keys": {
      "Hanzi": 2370,
      "Word": 4856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.8",
      "Grade": 6,
      "GeneralStandard": 2370,
      "Radical": "犬",
      "HSK": 6,
      "FrequencyRank": 1157,
      "StrokeCount": 11
    }
  },
  "学子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuézǐ",
      "PrimaryDefinition": "student, disciple"
    },
    "Keys": {
      "Word": 10137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "线条": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàntiáo",
      "PrimaryDefinition": "line (in drawing, bar, etc.), printing, line (in drawing/printing/etc.), contour, lines, striation, stria, line, trace, streak, tracing"
    },
    "Keys": {
      "Word": 9905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ér",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6736,
      "Radical": "阜",
      "RadicalIndex": "170.6",
      "StrokeCount": 8
    }
  },
  "惮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "dread, shrink from, shirk, fear"
    },
    "Keys": {
      "Hanzi": 4992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 4992,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 3601,
      "StrokeCount": 11
    }
  },
  "傣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎi",
      "PrimaryDefinition": "the Dai minority living in South China"
    },
    "Keys": {
      "Hanzi": 5208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5208,
      "Radical": "人",
      "FrequencyRank": 3836,
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "勐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měng",
      "PrimaryDefinition": "imperial degree; daoist magic"
    },
    "Keys": {
      "Hanzi": 4682
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4682,
      "Radical": "力",
      "FrequencyRank": 5460,
      "RadicalIndex": "19.8",
      "StrokeCount": 10
    }
  },
  "高大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāodà",
      "PrimaryDefinition": "tall and big, great, massive"
    },
    "Keys": {
      "Word": 3485
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鲈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "sea perch, sea bass"
    },
    "Keys": {
      "Hanzi": 5528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5528,
      "Radical": "魚",
      "FrequencyRank": 4317,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "扇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "fan; door panel"
    },
    "Keys": {
      "Hanzi": 2113,
      "Word": 3862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "63.6",
      "Grade": 5,
      "GeneralStandard": 2113,
      "Radical": "戶",
      "HSK": 5,
      "FrequencyRank": 1993,
      "StrokeCount": 10
    }
  },
  "确实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèshí",
      "PrimaryDefinition": "really, certainly, truly, indeed"
    },
    "Keys": {
      "Word": 1839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "冒充": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màochōng",
      "PrimaryDefinition": "to feign, to pretend to be, to pass oneself off as"
    },
    "Keys": {
      "Word": 8214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出租": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzū",
      "PrimaryDefinition": "hire, let, rent"
    },
    "Keys": {
      "Word": 571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "醇厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúnhòu",
      "PrimaryDefinition": "mellow and rich, simple and kind"
    },
    "Keys": {
      "Word": 6031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丑恶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒuè",
      "PrimaryDefinition": "ugly, repulsive"
    },
    "Keys": {
      "Word": 5946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūn",
      "PrimaryDefinition": "confused, stupid, dull, senile"
    },
    "Keys": {
      "Hanzi": 7243
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7243,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "地下水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìxiàshuǐ",
      "PrimaryDefinition": "groundwater"
    },
    "Keys": {
      "Word": 6252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "chlorine"
    },
    "Keys": {
      "Hanzi": 2673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2673,
      "Radical": "气",
      "FrequencyRank": 3013,
      "RadicalIndex": "84.8",
      "StrokeCount": 12
    }
  },
  "黄色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huángsè",
      "PrimaryDefinition": "chrome, yellowness, yellow, salacity, or"
    },
    "Keys": {
      "Word": 736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "备受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèishòu",
      "PrimaryDefinition": "fully experience (good or bad)"
    },
    "Keys": {
      "Word": 5555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辛苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnkǔ",
      "PrimaryDefinition": "try, endure hardship, work hard, tug, tote, lug"
    },
    "Keys": {
      "Word": 4062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "地区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìqū",
      "PrimaryDefinition": "section, country, area, latitude, zone, terrain, parts, locality, sector, province, prefecture, region, district, faubourg"
    },
    "Keys": {
      "Word": 1405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "囚犯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúfàn",
      "PrimaryDefinition": "prisoner, convict"
    },
    "Keys": {
      "Word": 8769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惨重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎnzhòng",
      "PrimaryDefinition": "disastrous"
    },
    "Keys": {
      "Word": 5761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīqiè",
      "PrimaryDefinition": "det.: everything, every"
    },
    "Keys": {
      "Word": 2087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "匐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "fall prostrate; crawl"
    },
    "Keys": {
      "Hanzi": 4924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4924,
      "Radical": "勹",
      "FrequencyRank": 4278,
      "RadicalIndex": "20.9",
      "StrokeCount": 11
    }
  },
  "哑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎ",
      "PrimaryDefinition": "dumb, mute; become hoarse"
    },
    "Keys": {
      "Hanzi": 1484,
      "Word": 10164
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 7,
      "GeneralStandard": 1484,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2368,
      "StrokeCount": 9
    }
  },
  "酸奶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suānnǎi",
      "PrimaryDefinition": "yogurt"
    },
    "Keys": {
      "Word": 2909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "继": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "continue, maintain, carry on"
    },
    "Keys": {
      "Hanzi": 2153,
      "Word": 7334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.7",
      "Grade": 7,
      "GeneralStandard": 2153,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 655,
      "StrokeCount": 10
    }
  },
  "更加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèngjiā",
      "PrimaryDefinition": "(even) more"
    },
    "Keys": {
      "Word": 1495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "束": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "bind, control, restrain; bale"
    },
    "Keys": {
      "Hanzi": 694,
      "Word": 1937
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "Grade": 3,
      "GeneralStandard": 694,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 998,
      "StrokeCount": 7
    }
  },
  "诽谤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěibàng",
      "PrimaryDefinition": "to slander, to libel"
    },
    "Keys": {
      "Word": 6521
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "街道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēdào",
      "PrimaryDefinition": "neighborhood, mews, gate, residential district, neighbourhood, street"
    },
    "Keys": {
      "Word": 2623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "钍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔ",
      "PrimaryDefinition": "thorium"
    },
    "Keys": {
      "Hanzi": 3939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3939,
      "Radical": "金",
      "FrequencyRank": 4649,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "孤儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūér",
      "PrimaryDefinition": "orphan"
    },
    "Keys": {
      "Word": 4617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "硬盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngpán",
      "PrimaryDefinition": "hard disk"
    },
    "Keys": {
      "Word": 10428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": "dried out, withered, decayed"
    },
    "Keys": {
      "Hanzi": 1427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1427,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2188,
      "StrokeCount": 9
    }
  },
  "拄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔ",
      "PrimaryDefinition": "lean on; post; prod; ridicule"
    },
    "Keys": {
      "Hanzi": 979,
      "Word": 10862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 979,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 4240,
      "StrokeCount": 8
    }
  },
  "出任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūrèn",
      "PrimaryDefinition": "take up the post of"
    },
    "Keys": {
      "Word": 5964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "势不可当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìbùkědāng",
      "PrimaryDefinition": "impossible to resist (idiom), an irresistible force"
    },
    "Keys": {
      "Word": 9137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忽高忽低": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūgāohūdī",
      "PrimaryDefinition": "I suddenly"
    },
    "Keys": {
      "Word": 7112
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嚣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "be noisy; treat with contempt"
    },
    "Keys": {
      "Hanzi": 3443
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3443,
      "Radical": "口",
      "FrequencyRank": 2982,
      "RadicalIndex": "30.15",
      "StrokeCount": 18
    }
  },
  "亭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "pavilion; erect"
    },
    "Keys": {
      "Hanzi": 1620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "8.7",
      "GeneralStandard": 1620,
      "Radical": "亠",
      "HSK": 9,
      "FrequencyRank": 2002,
      "StrokeCount": 9
    }
  },
  "唝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6985,
      "Radical": "口",
      "RadicalIndex": "30.1",
      "StrokeCount": 10
    }
  },
  "提交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíjiāo",
      "PrimaryDefinition": "to submit (a report etc), to refer (a problem) to sb"
    },
    "Keys": {
      "Word": 5078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "血": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuě",
      "PrimaryDefinition": "blood"
    },
    "Keys": {
      "Hanzi": 479,
      "Radical": 143,
      "Word": 2050
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "143",
      "Grade": 3,
      "GeneralStandard": 479,
      "Radical": "血",
      "HSK": 3,
      "FrequencyRank": 658,
      "StrokeCount": 6
    }
  },
  "鲞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng",
      "PrimaryDefinition": "dried fish"
    },
    "Keys": {
      "Hanzi": 5791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5791,
      "Radical": "魚",
      "FrequencyRank": 6806,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "租": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zū",
      "PrimaryDefinition": "rent, lease; rental; tax"
    },
    "Keys": {
      "Hanzi": 1944,
      "Word": 1244
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "Grade": 2,
      "GeneralStandard": 1944,
      "Radical": "禾",
      "HSK": 2,
      "FrequencyRank": 1397,
      "StrokeCount": 10
    }
  },
  "走过场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒu guòchǎng",
      "PrimaryDefinition": "to go through the motions"
    },
    "Keys": {
      "Word": 10992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěxìn",
      "PrimaryDefinition": "trustworthy"
    },
    "Keys": {
      "Word": 7805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氧气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎngqì",
      "PrimaryDefinition": "O, atomic number 8, oxygen"
    },
    "Keys": {
      "Word": 5220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "急救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíjiù",
      "PrimaryDefinition": "first aid, emergency treatment"
    },
    "Keys": {
      "Word": 4698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "生命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngmìng",
      "PrimaryDefinition": "breath, god, head, anima, days, organism, blood, life, living thing, skin, longevity, being, vital, life form"
    },
    "Keys": {
      "Word": 1886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "果园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒyuán",
      "PrimaryDefinition": "arbour, garden, orchard"
    },
    "Keys": {
      "Word": 6938
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饶恕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráoshù",
      "PrimaryDefinition": "to forgive, to pardon, to spare"
    },
    "Keys": {
      "Word": 8828
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "委婉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěiwǎn",
      "PrimaryDefinition": "tactful, euphemistic, (of voice etc) suave, soft"
    },
    "Keys": {
      "Word": 9707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "应酬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngchou",
      "PrimaryDefinition": "social niceties, social interaction, a dinner party"
    },
    "Keys": {
      "Word": 10422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "百": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi",
      "PrimaryDefinition": "one hundred; numerous, many"
    },
    "Keys": {
      "Hanzi": 389,
      "Word": 8
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "106.1",
      "Grade": 1,
      "GeneralStandard": 389,
      "Radical": "白",
      "HSK": 1,
      "FrequencyRank": 407,
      "StrokeCount": 6
    }
  },
  "祯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "lucky, auspicious, good omen"
    },
    "Keys": {
      "Hanzi": 4673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4673,
      "Radical": "示",
      "FrequencyRank": 3838,
      "RadicalIndex": "113.6",
      "StrokeCount": 10
    }
  },
  "杏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "apricot; almond"
    },
    "Keys": {
      "Hanzi": 684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 684,
      "Radical": "木",
      "FrequencyRank": 2790,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "生死": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng-sǐ",
      "PrimaryDefinition": "or death, life or death, life and/or death, life and death, life and"
    },
    "Keys": {
      "Word": 9059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "釉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "glaze"
    },
    "Keys": {
      "Hanzi": 5223
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5223,
      "Radical": "釆",
      "FrequencyRank": 2923,
      "RadicalIndex": "165.5",
      "StrokeCount": 12
    }
  },
  "弛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "loosen, relax, unstring a bow"
    },
    "Keys": {
      "Hanzi": 569
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "57.3",
      "GeneralStandard": 569,
      "Radical": "弓",
      "HSK": 9,
      "FrequencyRank": 3358,
      "StrokeCount": 6
    }
  },
  "护照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùzhào",
      "PrimaryDefinition": "passport"
    },
    "Keys": {
      "Word": 724
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "缘分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánfen",
      "PrimaryDefinition": "fate or chance that brings people together, predestined affinity or relationship, (Budd.) destiny"
    },
    "Keys": {
      "Word": 10547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "局限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júxiàn",
      "PrimaryDefinition": "to limit, to confine, to restrict sth within set boundaries"
    },
    "Keys": {
      "Word": 7683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "lawrencium (Lr)"
    },
    "Keys": {
      "Hanzi": 7374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7374,
      "Radical": "金",
      "FrequencyRank": 8592,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "尉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "officer, military rank"
    },
    "Keys": {
      "Hanzi": 2471
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2471,
      "Radical": "寸",
      "FrequencyRank": 2126,
      "RadicalIndex": "41.8",
      "StrokeCount": 11
    }
  },
  "孟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèng",
      "PrimaryDefinition": "first in series; great, eminent"
    },
    "Keys": {
      "Hanzi": 1316
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.5",
      "GeneralStandard": 1316,
      "Radical": "子",
      "HSK": 9,
      "FrequencyRank": 1575,
      "StrokeCount": 8
    }
  },
  "精疲力竭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngpí-lìjié",
      "PrimaryDefinition": "spirit weary, strength exhausted (idiom), spent, drained, washed out"
    },
    "Keys": {
      "Word": 7617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": "lady"
    },
    "Keys": {
      "Hanzi": 4383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4383,
      "Radical": "女",
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "诖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà",
      "PrimaryDefinition": "error, mistake; deceive, mislead"
    },
    "Keys": {
      "Hanzi": 4040
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4040,
      "Radical": "言",
      "FrequencyRank": 4982,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "能人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néngrén",
      "PrimaryDefinition": "capable person, Homo habilis, extinct species of upright East African hominid"
    },
    "Keys": {
      "Word": 8399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒu",
      "PrimaryDefinition": "a wine flagon"
    },
    "Keys": {
      "Hanzi": 6851
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6851,
      "Radical": "金",
      "FrequencyRank": 5717,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "方方面面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngfāng-miànmiàn",
      "PrimaryDefinition": "all sides, all aspects, multi-faceted"
    },
    "Keys": {
      "Word": 6484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēshōu",
      "PrimaryDefinition": "receive, take over (property/etc.)"
    },
    "Keys": {
      "Word": 4725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "下场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàchang",
      "PrimaryDefinition": "to leave (the stage, an exam room, the playing field etc), to take part in some activity, to take an examination (in the imperial examination system), the end, to conclude"
    },
    "Keys": {
      "Word": 9856
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìxíng",
      "PrimaryDefinition": "topography, physique, landscape, terrain, chorography, landform"
    },
    "Keys": {
      "Word": 3415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "龅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "teeth protrude"
    },
    "Keys": {
      "Hanzi": 5427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5427,
      "Radical": "齒",
      "FrequencyRank": 4662,
      "RadicalIndex": "211.5",
      "StrokeCount": 13
    }
  },
  "铜牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngpái",
      "PrimaryDefinition": "bronze medal, bronze plaque bearing a business name or logo etc, CL:枚[mei2]"
    },
    "Keys": {
      "Word": 5098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "初期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūqī",
      "PrimaryDefinition": "initial stage, beginning period"
    },
    "Keys": {
      "Word": 3344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "敉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐ",
      "PrimaryDefinition": "pacify, soothe, stabilize"
    },
    "Keys": {
      "Hanzi": 7048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7048,
      "Radical": "攴",
      "FrequencyRank": 6086,
      "RadicalIndex": "66.6",
      "StrokeCount": 10
    }
  },
  "流转": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúzhuǎn",
      "PrimaryDefinition": "to be on the move, to roam or wander, to circulate (of goods or capital)"
    },
    "Keys": {
      "Word": 8109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "气味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìwèi",
      "PrimaryDefinition": "tang, wind, odor, savor, aurae, tint, flavor, leavening, savour, flavour, leaven, odour, aura, smatch, tinge, smell, smack, scent, taste"
    },
    "Keys": {
      "Word": 8626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "甥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2667
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2667,
      "Radical": "生",
      "FrequencyRank": 3809,
      "RadicalIndex": "100.7",
      "StrokeCount": 12
    }
  },
  "冲突": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngtū",
      "PrimaryDefinition": "conflict, clash"
    },
    "Keys": {
      "Word": 3337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "煊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuān",
      "PrimaryDefinition": "warm"
    },
    "Keys": {
      "Hanzi": 5563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5563,
      "Radical": "火",
      "FrequencyRank": 4185,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "咆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páo",
      "PrimaryDefinition": "roar"
    },
    "Keys": {
      "Hanzi": 3917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3917,
      "Radical": "口",
      "FrequencyRank": 3369,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "车号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēhào",
      "PrimaryDefinition": "Car number"
    },
    "Keys": {
      "Word": 4403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "椎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": "hammer, mallet; vertebra"
    },
    "Keys": {
      "Hanzi": 2573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2573,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 2455,
      "StrokeCount": 12
    }
  },
  "北京": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Běijīng",
      "PrimaryDefinition": "capital of Red China, Beijing, Peking, Beijing (Peking), Peiping"
    },
    "Keys": {
      "Word": 21
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "伙食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒshi",
      "PrimaryDefinition": "food, meals"
    },
    "Keys": {
      "Word": 7250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "ascend, go up, rise"
    },
    "Keys": {
      "Hanzi": 5458
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5458,
      "Radical": "足",
      "FrequencyRank": 4014,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "苦心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔxīn",
      "PrimaryDefinition": "painstaking effort, to take a lot of trouble, laborious at pains"
    },
    "Keys": {
      "Word": 7864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "betel-nut tree"
    },
    "Keys": {
      "Hanzi": 2578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2578,
      "Radical": "木",
      "FrequencyRank": 4337,
      "RadicalIndex": "75.9",
      "StrokeCount": 12
    }
  },
  "拐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎi",
      "PrimaryDefinition": "kidnap, abduct; turn"
    },
    "Keys": {
      "Hanzi": 967,
      "Word": 4626
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 6,
      "GeneralStandard": 967,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 2232,
      "StrokeCount": 8
    }
  },
  "钦佩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnpèi",
      "PrimaryDefinition": "to admire, to look up to, to respect sb greatly"
    },
    "Keys": {
      "Word": 8713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "劝说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quànshuō",
      "PrimaryDefinition": "to persuade, persuasion, to advise"
    },
    "Keys": {
      "Word": 8811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "名声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngshēng",
      "PrimaryDefinition": "reputation"
    },
    "Keys": {
      "Word": 8306
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一下儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxiàr",
      "PrimaryDefinition": "(used after a verb) give it a go, to do (sth for a bit to give it a try), one time, once, in a while, all of a sudden, all at once, erhua variant of 一下[yi1 xia4]"
    },
    "Keys": {
      "Word": 4108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "改变": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎibiàn",
      "PrimaryDefinition": "change, transform"
    },
    "Keys": {
      "Word": 667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "味精": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèijīng",
      "PrimaryDefinition": "monosodium glutamate (MSG)"
    },
    "Keys": {
      "Word": 9718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "selenium"
    },
    "Keys": {
      "Hanzi": 4791
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4791,
      "Radical": "石",
      "FrequencyRank": 4846,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "放肆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngsì",
      "PrimaryDefinition": "wanton, unbridled, presumptuous, impudent"
    },
    "Keys": {
      "Word": 6504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熟人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shúrén",
      "PrimaryDefinition": "acquaintance, friend"
    },
    "Keys": {
      "Word": 1934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "苦恼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔnǎo",
      "PrimaryDefinition": "vexed, worried"
    },
    "Keys": {
      "Word": 7863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自尊心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìzūnxīn",
      "PrimaryDefinition": "self-respect, self-esteem, ego"
    },
    "Keys": {
      "Word": 10974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngshòu",
      "PrimaryDefinition": "to undergo (hardship), to endure, to withstand"
    },
    "Keys": {
      "Word": 7596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnwèn",
      "PrimaryDefinition": "to ask (a question) in reply, to answer a question with a question, rhetorical question"
    },
    "Keys": {
      "Word": 4549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "紧接着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnjiēzhe",
      "PrimaryDefinition": "follow close behind"
    },
    "Keys": {
      "Word": 7569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "德": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé",
      "PrimaryDefinition": "ethics, morality, virtue"
    },
    "Keys": {
      "Hanzi": 3272,
      "Word": 6218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "60.12",
      "Grade": 7,
      "GeneralStandard": 3272,
      "Radical": "彳",
      "HSK": 5,
      "FrequencyRank": 256,
      "StrokeCount": 15
    }
  },
  "传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuán",
      "PrimaryDefinition": "summon; propagate, transmit"
    },
    "Keys": {
      "Hanzi": 455,
      "Word": 10898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 7,
      "GeneralStandard": 455,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 332,
      "StrokeCount": 6
    }
  },
  "初中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzhōng",
      "PrimaryDefinition": "junior high school, abbr. for 初級中學|初级中学[chu1 ji2 zhong1 xue2]"
    },
    "Keys": {
      "Word": 1363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蚰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "millipede"
    },
    "Keys": {
      "Hanzi": 4835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4835,
      "Radical": "虫",
      "FrequencyRank": 5823,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "陆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "land, continental; army"
    },
    "Keys": {
      "Hanzi": 912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.5",
      "GeneralStandard": 912,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 675,
      "StrokeCount": 7
    }
  },
  "新奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnqí",
      "PrimaryDefinition": "novelty, new and odd"
    },
    "Keys": {
      "Word": 10030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "weed"
    },
    "Keys": {
      "Hanzi": 1767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1767,
      "Radical": "耒",
      "FrequencyRank": 4035,
      "RadicalIndex": "127.4",
      "StrokeCount": 10
    }
  },
  "姿态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zītài",
      "PrimaryDefinition": "mannerism, set, stance, pose, attitude, affectation, port, shadow, likeness, carriage, posture, position, movement, gesture, tournure, sort"
    },
    "Keys": {
      "Word": 10939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "于是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúshì",
      "PrimaryDefinition": "conj.: thereupon, hence, consequently, as a result"
    },
    "Keys": {
      "Word": 3121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "庄稼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngjia",
      "PrimaryDefinition": "farm crop, CL:種|种[zhong3]"
    },
    "Keys": {
      "Word": 10903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "标致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāozhi",
      "PrimaryDefinition": "Peugeot, beautiful (of woman), pretty"
    },
    "Keys": {
      "Word": 5629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妒忌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùjì",
      "PrimaryDefinition": "to be jealous of (sb's achievements etc), to be envious, envy"
    },
    "Keys": {
      "Word": 6364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踏实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāshi",
      "PrimaryDefinition": "dependable, at ease, solid"
    },
    "Keys": {
      "Word": 5059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "自动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìdòng",
      "PrimaryDefinition": "automatic"
    },
    "Keys": {
      "Word": 2212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "虔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "act with reverence; reverent"
    },
    "Keys": {
      "Hanzi": 4491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "141.4",
      "GeneralStandard": 4491,
      "Radical": "虍",
      "HSK": 7,
      "FrequencyRank": 2832,
      "StrokeCount": 10
    }
  },
  "慭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìn",
      "PrimaryDefinition": "cautious; willing; but"
    },
    "Keys": {
      "Hanzi": 7765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7765,
      "Radical": "心",
      "RadicalIndex": "61.11",
      "StrokeCount": 15
    }
  },
  "递": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "hand over, deliver; substitute"
    },
    "Keys": {
      "Hanzi": 2064,
      "Word": 3416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 5,
      "GeneralStandard": 2064,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 1538,
      "StrokeCount": 10
    }
  },
  "漆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "varnish, lacquer, paint"
    },
    "Keys": {
      "Hanzi": 3161,
      "Word": 8591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 7,
      "GeneralStandard": 3161,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2296,
      "StrokeCount": 14
    }
  },
  "围绕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéirào",
      "PrimaryDefinition": "to revolve around, to center on (an issue)"
    },
    "Keys": {
      "Word": 4002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "抽签": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu qiān",
      "PrimaryDefinition": "to perform divination with sticks, to draw lots, a ballot (in share dealing)"
    },
    "Keys": {
      "Word": 5930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "near, close; approach; intimate"
    },
    "Keys": {
      "Hanzi": 791,
      "Word": 793
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 2,
      "GeneralStandard": 791,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 374,
      "StrokeCount": 7
    }
  },
  "后边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòubian",
      "PrimaryDefinition": "back, rear, behind"
    },
    "Keys": {
      "Word": 146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "谣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "sing; folksong, ballad; rumor"
    },
    "Keys": {
      "Hanzi": 2796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.1",
      "GeneralStandard": 2796,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2600,
      "StrokeCount": 12
    }
  },
  "荡漾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngyàng",
      "PrimaryDefinition": "to ripple, to undulate"
    },
    "Keys": {
      "Word": 6185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuzi",
      "PrimaryDefinition": "hole, opening, cut, gap, gash, my husband or wife, classifier for people (used for indicating the number of people in a family etc), precedent"
    },
    "Keys": {
      "Word": 7850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "type of willow; tamarisk"
    },
    "Keys": {
      "Hanzi": 4160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4160,
      "Radical": "木",
      "FrequencyRank": 6486,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "网友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngyǒu",
      "PrimaryDefinition": "online friend, Internet user"
    },
    "Keys": {
      "Word": 372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "多心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōxīn",
      "PrimaryDefinition": "oversensitive, suspicious"
    },
    "Keys": {
      "Word": 6396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "a small kidney bean"
    },
    "Keys": {
      "Hanzi": 4468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4468,
      "Radical": "豆",
      "FrequencyRank": 6173,
      "RadicalIndex": "151.3",
      "StrokeCount": 10
    }
  },
  "交换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāohuàn",
      "PrimaryDefinition": "reciprocate, switching, barter, exchange, commute, switch, communicate, interchange, bandy, shift, trade, batter, truck, swop, change, counterchange, swap, clear, buy, transpose, change over, commune, turn around"
    },
    "Keys": {
      "Word": 2618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "梯子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīzi",
      "PrimaryDefinition": "ladder, stepladder"
    },
    "Keys": {
      "Word": 9454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "进口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn kǒu",
      "PrimaryDefinition": "import"
    },
    "Keys": {
      "Word": 2633
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "遄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuán",
      "PrimaryDefinition": "to hurry; to go to and fro"
    },
    "Keys": {
      "Hanzi": 5166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5166,
      "Radical": "辵",
      "FrequencyRank": 5956,
      "RadicalIndex": "162.9",
      "StrokeCount": 12
    }
  },
  "以便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐbiàn",
      "PrimaryDefinition": "conj.: so that, in order to, with the aim of"
    },
    "Keys": {
      "Word": 4113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "玤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "a kind of gem inferior to jade"
    },
    "Keys": {
      "Hanzi": 6645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6645,
      "Radical": "玉",
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "定位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngwèi",
      "PrimaryDefinition": "fixed position"
    },
    "Keys": {
      "Word": 4514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "啁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo",
      "PrimaryDefinition": "chirp, twitter, twittering"
    },
    "Keys": {
      "Hanzi": 4842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4842,
      "Radical": "口",
      "FrequencyRank": 5549,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běn",
      "PrimaryDefinition": "root, origin, source; basis"
    },
    "Keys": {
      "Hanzi": 210,
      "Word": 4315
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.1",
      "Grade": 6,
      "GeneralStandard": 210,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 92,
      "StrokeCount": 5
    }
  },
  "蓑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": "rain coat made of straw, coir, etc."
    },
    "Keys": {
      "Hanzi": 5381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5381,
      "Radical": "艸",
      "FrequencyRank": 4481,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "觊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "covet, long for, desire"
    },
    "Keys": {
      "Hanzi": 4525
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4525,
      "Radical": "見",
      "FrequencyRank": 4591,
      "RadicalIndex": "147.6",
      "StrokeCount": 10
    }
  },
  "央": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": "center, conclude, run out; beg"
    },
    "Keys": {
      "Hanzi": 249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.2",
      "GeneralStandard": 249,
      "Radical": "大",
      "HSK": 5,
      "FrequencyRank": 800,
      "StrokeCount": 5
    }
  },
  "大吃一惊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàchī-yījīng",
      "PrimaryDefinition": "to have a surprise (idiom), shocked or startled, gobsmacked"
    },
    "Keys": {
      "Word": 6113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": "name of feudal state; clear"
    },
    "Keys": {
      "Hanzi": 2864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.9",
      "GeneralStandard": 2864,
      "Radical": "木",
      "HSK": 2,
      "FrequencyRank": 859,
      "StrokeCount": 13
    }
  },
  "祋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6728,
      "Radical": "示",
      "FrequencyRank": 7964,
      "RadicalIndex": "113.4",
      "StrokeCount": 8
    }
  },
  "那会儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàhuìr5",
      "PrimaryDefinition": "Then"
    },
    "Keys": {
      "Word": 879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "籽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "seed, pip, pit, stone"
    },
    "Keys": {
      "Hanzi": 1648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1648,
      "Radical": "米",
      "FrequencyRank": 3567,
      "RadicalIndex": "119.3",
      "StrokeCount": 9
    }
  },
  "晶莹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngyíng",
      "PrimaryDefinition": "sparkling and translucent"
    },
    "Keys": {
      "Word": 7609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùn",
      "PrimaryDefinition": "jokes, obsene jests; joke"
    },
    "Keys": {
      "Hanzi": 4058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4058,
      "Radical": "言",
      "FrequencyRank": 4629,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "潾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "clear water"
    },
    "Keys": {
      "Hanzi": 7820
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7820,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "奸诈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānzhà",
      "PrimaryDefinition": "treachery, devious, a rogue"
    },
    "Keys": {
      "Word": 7375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "健美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànměi",
      "PrimaryDefinition": "healthy and beautiful, to do fitness exercises, abbr. for 健美運動|健美运动[jian4 mei3 yun4 dong4]"
    },
    "Keys": {
      "Word": 7432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "开放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāifàng",
      "PrimaryDefinition": "lift a ban/etc., be turned on, be in operation"
    },
    "Keys": {
      "Word": 1662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "比较": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐjiào",
      "PrimaryDefinition": "relatively, fairly"
    },
    "Keys": {
      "Word": 1293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "称为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēngwéi",
      "PrimaryDefinition": "call as"
    },
    "Keys": {
      "Word": 1346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "踊跃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngyuè",
      "PrimaryDefinition": "enthusiastical, leap, jump, eager"
    },
    "Keys": {
      "Word": 10442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "匀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "equal"
    },
    "Keys": {
      "Hanzi": 156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "20.2",
      "GeneralStandard": 156,
      "Radical": "勹",
      "HSK": 9,
      "FrequencyRank": 2692,
      "StrokeCount": 4
    }
  },
  "退役": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì yì",
      "PrimaryDefinition": "to retire from the military, sport etc, to demobilize, to decommission, retired from use"
    },
    "Keys": {
      "Word": 9610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "位子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèizi",
      "PrimaryDefinition": "seat, place"
    },
    "Keys": {
      "Word": 9719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娇气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoqi",
      "PrimaryDefinition": "delicate, squeamish, finicky"
    },
    "Keys": {
      "Word": 7468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěng",
      "PrimaryDefinition": "well rope"
    },
    "Keys": {
      "Hanzi": 4699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4699,
      "Radical": "糸",
      "FrequencyRank": 7162,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "一目了然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīmù-liǎorán",
      "PrimaryDefinition": "obvious at a glance (idiom)"
    },
    "Keys": {
      "Word": 10292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngcháng",
      "PrimaryDefinition": "everyday, daily, alway, often, a great deal, frequently, sempre, much, regularly, constantly, evermore, day-to-day"
    },
    "Keys": {
      "Word": 791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "根治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnzhì",
      "PrimaryDefinition": "to bring under permanent control, to effect a radical cure"
    },
    "Keys": {
      "Word": 6743
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "塬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "plateau"
    },
    "Keys": {
      "Hanzi": 5350
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5350,
      "Radical": "土",
      "FrequencyRank": 5875,
      "RadicalIndex": "32.1",
      "StrokeCount": 13
    }
  },
  "拴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuān",
      "PrimaryDefinition": "bind with rope, fasten"
    },
    "Keys": {
      "Hanzi": 1385,
      "Word": 9250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 1385,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3170,
      "StrokeCount": 9
    }
  },
  "怊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6725,
      "Radical": "心",
      "FrequencyRank": 9168,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "不通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùtōng",
      "PrimaryDefinition": "to be obstructed, to be blocked up, to be impassable, to make no sense, to be illogical"
    },
    "Keys": {
      "Word": 4357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "对称": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìchèn",
      "PrimaryDefinition": "symmetry, symmetrical"
    },
    "Keys": {
      "Word": 6379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "事态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìtài",
      "PrimaryDefinition": "situation, existing state of affairs"
    },
    "Keys": {
      "Word": 9139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "然后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ránhòu",
      "PrimaryDefinition": "therewith, and so, and_then, and then, thereupon, after that, so, then, with that"
    },
    "Keys": {
      "Word": 956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "池": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chí",
      "PrimaryDefinition": "pool, pond; moat; cistern"
    },
    "Keys": {
      "Hanzi": 540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.3",
      "GeneralStandard": 540,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1709,
      "StrokeCount": 6
    }
  },
  "生病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng bìng",
      "PrimaryDefinition": "come down, ail, fall ill, traik, sick, sicken"
    },
    "Keys": {
      "Word": 325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "电影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànyǐng",
      "PrimaryDefinition": "cinematography, moving_picture, pix, kinema, picture, film, movie, moving picture, flick, cinema, motion_picture, cinematic, celluloid, picture show, motion picture, flicker"
    },
    "Keys": {
      "Word": 80
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "年画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánhuà",
      "PrimaryDefinition": "New Year picture, New Year pictures"
    },
    "Keys": {
      "Word": 8409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "a hamper, wicker basket"
    },
    "Keys": {
      "Hanzi": 4899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4899,
      "Radical": "竹",
      "FrequencyRank": 4674,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "遆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7419
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7419,
      "Radical": "辵",
      "FrequencyRank": 7191,
      "RadicalIndex": "162.9",
      "StrokeCount": 12
    }
  },
  "纵然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòngrán",
      "PrimaryDefinition": "even if, even though"
    },
    "Keys": {
      "Word": 10988
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "构想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòuxiǎng",
      "PrimaryDefinition": "proposition"
    },
    "Keys": {
      "Word": 6814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "deliver, submit; hand over"
    },
    "Keys": {
      "Hanzi": 3387,
      "Word": 7484
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.13",
      "Grade": 7,
      "GeneralStandard": 3387,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 1834,
      "StrokeCount": 16
    }
  },
  "赶到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎndào",
      "PrimaryDefinition": "leave for, hurry to"
    },
    "Keys": {
      "Word": 1473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "摇摆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáobǎi",
      "PrimaryDefinition": "to sway, to wobble, to waver"
    },
    "Keys": {
      "Word": 10234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuě",
      "PrimaryDefinition": "snow; wipe away shame, avenge"
    },
    "Keys": {
      "Hanzi": 2249,
      "Word": 1122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "173.3",
      "Grade": 2,
      "GeneralStandard": 2249,
      "Radical": "雨",
      "HSK": 2,
      "FrequencyRank": 1003,
      "StrokeCount": 11
    }
  },
  "结构": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiégòu",
      "PrimaryDefinition": "constitution, configuration, framing, structural, design, underframe, framework, construction, structure, schematism, economy, fabric, mechanics, composition, machinery, contexture, texture, constellation, fable, ordonnance, skeleton, organization, frame, architecture, chemistry, make"
    },
    "Keys": {
      "Word": 2625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "囔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nāng",
      "PrimaryDefinition": "muttering, indistinct speech"
    },
    "Keys": {
      "Hanzi": 6496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6496,
      "Radical": "口",
      "FrequencyRank": 4082,
      "RadicalIndex": "30.22",
      "StrokeCount": 25
    }
  },
  "浚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùn",
      "PrimaryDefinition": "dredge"
    },
    "Keys": {
      "Hanzi": 4658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4658,
      "Radical": "水",
      "FrequencyRank": 3753,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "蛊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "poison; venom; harm; bewitch"
    },
    "Keys": {
      "Hanzi": 4836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4836,
      "Radical": "虫",
      "FrequencyRank": 3808,
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "查找": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cházhǎo",
      "PrimaryDefinition": "to search for, to look up"
    },
    "Keys": {
      "Word": 5791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "sections in vegetable farm"
    },
    "Keys": {
      "Hanzi": 4827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4827,
      "Radical": "田",
      "FrequencyRank": 4795,
      "RadicalIndex": "102.6",
      "StrokeCount": 11
    }
  },
  "别扭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bièniu",
      "PrimaryDefinition": "awkward, difficult, uncomfortable, not agreeing, at loggerheads, gauche"
    },
    "Keys": {
      "Word": 5642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "a rock or cliff"
    },
    "Keys": {
      "Hanzi": 3886
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3886,
      "Radical": "石",
      "FrequencyRank": 4999,
      "RadicalIndex": "112.3",
      "StrokeCount": 8
    }
  },
  "公顷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngqǐng",
      "PrimaryDefinition": "m.[standard]"
    },
    "Keys": {
      "Word": 6771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyòng",
      "PrimaryDefinition": "be for public use"
    },
    "Keys": {
      "Word": 6781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6677,
      "Radical": "日",
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": "allow, permit; promise; betroth"
    },
    "Keys": {
      "Hanzi": 554,
      "Word": 10097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "Grade": 7,
      "GeneralStandard": 554,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 263,
      "StrokeCount": 6
    }
  },
  "调整": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáozhěng",
      "PrimaryDefinition": "adjust, readjust, regulate, restructure, balance"
    },
    "Keys": {
      "Word": 1966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "瓮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèng",
      "PrimaryDefinition": "earthen jar; jar for ashes"
    },
    "Keys": {
      "Hanzi": 3975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3975,
      "Radical": "瓦",
      "FrequencyRank": 3873,
      "RadicalIndex": "98.4",
      "StrokeCount": 8
    }
  },
  "祏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "shrine"
    },
    "Keys": {
      "Hanzi": 6904
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6904,
      "Radical": "示",
      "FrequencyRank": 6380,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "列车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièchē",
      "PrimaryDefinition": "train"
    },
    "Keys": {
      "Word": 2706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "方言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngyán",
      "PrimaryDefinition": "the first Chinese dialect dictionary, edited by Yang Xiong 揚雄|扬雄[Yang2 Xiong2] in 1st century, containing over 9000 characters, topolect, dialect"
    },
    "Keys": {
      "Word": 6486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悲剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēijù",
      "PrimaryDefinition": "tragedy"
    },
    "Keys": {
      "Word": 3239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "漹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7722
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7722,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "帅哥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuàigē",
      "PrimaryDefinition": "handsome guy, lady-killer, handsome (form of address)"
    },
    "Keys": {
      "Word": 2896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "礼貌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐmào",
      "PrimaryDefinition": "courtesy, politeness, manners"
    },
    "Keys": {
      "Word": 3683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "䓫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "name of a mountain, in Taiwan Province"
    },
    "Keys": {
      "Hanzi": 7124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7124,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 14
    }
  },
  "冔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": "cap worn during the Yin dynasty"
    },
    "Keys": {
      "Hanzi": 6988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6988,
      "Radical": "冂",
      "RadicalIndex": "13.8",
      "StrokeCount": 10
    }
  },
  "飏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "soar, fly, float; scatter"
    },
    "Keys": {
      "Hanzi": 6607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6607,
      "Radical": "風",
      "FrequencyRank": 6116,
      "RadicalIndex": "182.9",
      "StrokeCount": 7
    }
  },
  "绷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běng",
      "PrimaryDefinition": "bind, draw firmly, strap"
    },
    "Keys": {
      "Hanzi": 2494,
      "Word": 5574
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "Grade": 7,
      "GeneralStandard": 2494,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 2978,
      "StrokeCount": 11
    }
  },
  "安定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āndìng",
      "PrimaryDefinition": "stabilize"
    },
    "Keys": {
      "Word": 5436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惊慌失措": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnghuāng-shīcuò",
      "PrimaryDefinition": "to lose one's head out of fear (idiom)"
    },
    "Keys": {
      "Word": 7601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说不定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōbudìng",
      "PrimaryDefinition": "perhaps, maybe"
    },
    "Keys": {
      "Word": 2899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "诱饵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòuěr",
      "PrimaryDefinition": "bait"
    },
    "Keys": {
      "Word": 10489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "燋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "scorch, burn, scald; torch"
    },
    "Keys": {
      "Hanzi": 7897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7897,
      "Radical": "火",
      "RadicalIndex": "86.12",
      "StrokeCount": 16
    }
  },
  "破解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòjiě",
      "PrimaryDefinition": "to break (a bond, constraint etc), to explain, to unravel, to decipher, to decode"
    },
    "Keys": {
      "Word": 8570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "零食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língshí",
      "PrimaryDefinition": "between-meal nibbles, snacks"
    },
    "Keys": {
      "Word": 2711
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "新生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshēng",
      "PrimaryDefinition": "new student"
    },
    "Keys": {
      "Word": 10031
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蘸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "dip (a brush); remarry"
    },
    "Keys": {
      "Hanzi": 6466,
      "Word": 10649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.19",
      "Grade": 7,
      "GeneralStandard": 6466,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 3944,
      "StrokeCount": 22
    }
  },
  "轺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "small light carriage"
    },
    "Keys": {
      "Hanzi": 6818
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6818,
      "Radical": "車",
      "FrequencyRank": 6862,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "路程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùchéng",
      "PrimaryDefinition": "distance travelled, journey"
    },
    "Keys": {
      "Word": 8129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóhuà",
      "PrimaryDefinition": "trad. Ch. painting"
    },
    "Keys": {
      "Word": 6931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "legendary archer"
    },
    "Keys": {
      "Hanzi": 4390
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4390,
      "Radical": "羽",
      "FrequencyRank": 4860,
      "RadicalIndex": "124.3",
      "StrokeCount": 9
    }
  },
  "蓝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "blue; indigo plant; surname"
    },
    "Keys": {
      "Hanzi": 2852,
      "Word": 830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.1",
      "Grade": 2,
      "GeneralStandard": 2852,
      "Radical": "艸",
      "HSK": 2,
      "FrequencyRank": 1190,
      "StrokeCount": 13
    }
  },
  "秕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "empty grain or rice husk, chaff"
    },
    "Keys": {
      "Hanzi": 4251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4251,
      "Radical": "禾",
      "FrequencyRank": 6313,
      "RadicalIndex": "115.4",
      "StrokeCount": 9
    }
  },
  "压倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā dǎo",
      "PrimaryDefinition": "to overwhelm, to overpower, overwhelming"
    },
    "Keys": {
      "Word": 10156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "to induce to come; to encourage"
    },
    "Keys": {
      "Hanzi": 6854
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6854,
      "Radical": "人",
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "首创": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuchuàng",
      "PrimaryDefinition": "to create, original creation, to be the first to do sth"
    },
    "Keys": {
      "Word": 9186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坚韧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānrèn",
      "PrimaryDefinition": "tough and durable, tenacious"
    },
    "Keys": {
      "Word": 7377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出难题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū nántí",
      "PrimaryDefinition": "to raise a tough question"
    },
    "Keys": {
      "Word": 5962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老伴儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎobànr5",
      "PrimaryDefinition": "(of an elderly couple) husband or wife, erhua variant of 老伴[lao3 ban4]"
    },
    "Keys": {
      "Word": 7945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凝固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nínggù",
      "PrimaryDefinition": "to freeze, to solidify, to congeal, fig. with rapt attention"
    },
    "Keys": {
      "Word": 8425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "着手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóshǒu",
      "PrimaryDefinition": "put hand to, initiatory, begin, set to, set_out, institute, proceed, start up, turn_to, fall, spring-clean, embark, go_about, undertake, accede to, enter upon, get to work, set about, commence, enter, put one's hand to, start"
    },
    "Keys": {
      "Word": 10934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "山峰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānfēng",
      "PrimaryDefinition": "(mountain) peak"
    },
    "Keys": {
      "Word": 4987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "追悼会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīdàohuì",
      "PrimaryDefinition": "a memorial service, a funeral service"
    },
    "Keys": {
      "Word": 10914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "停止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíngzhǐ",
      "PrimaryDefinition": "cease, flunk, desist, pass, suspend, stint, pass_off, break_up, stash, cut, stay, cessation, adjourn, rain_out, give, detain, blow_over, seize, give up, balk, cheese, abstain, refrain, knock_off, rest, abandon, break, knock off, drop, go, tie_up, stop, interdict, strike, lay_off, stow, cut off, discontinue, pause, leave_off, leave off, set aside, give_out, cut_out, give_up, delay, quit, cut out"
    },
    "Keys": {
      "Word": 1974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "萸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "dogwood"
    },
    "Keys": {
      "Hanzi": 4755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4755,
      "Radical": "艸",
      "FrequencyRank": 5149,
      "RadicalIndex": "140.9",
      "StrokeCount": 11
    }
  },
  "饕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "gluttonous, greedy, covetous"
    },
    "Keys": {
      "Hanzi": 6470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6470,
      "Radical": "食",
      "FrequencyRank": 4324,
      "RadicalIndex": "184.13",
      "StrokeCount": 22
    }
  },
  "群体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qúntǐ",
      "PrimaryDefinition": "population, community, society, grouping, group, colony, groups"
    },
    "Keys": {
      "Word": 3832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "杨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "willow, poplar, aspen; surname"
    },
    "Keys": {
      "Hanzi": 689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "GeneralStandard": 689,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 1062,
      "StrokeCount": 7
    }
  },
  "灵活": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línghuó",
      "PrimaryDefinition": "nimble, agile, flexible, elastic"
    },
    "Keys": {
      "Word": 4831
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "提示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíshì",
      "PrimaryDefinition": "to point out, to remind (sb of sth), to suggest, suggestion, tip, reminder, notice"
    },
    "Keys": {
      "Word": 3973
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "歙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6135,
      "Radical": "欠",
      "FrequencyRank": 4946,
      "RadicalIndex": "76.12",
      "StrokeCount": 16
    }
  },
  "秘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "secret, mysterious, abstruse"
    },
    "Keys": {
      "Hanzi": 1949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "GeneralStandard": 1949,
      "Radical": "禾",
      "HSK": 4,
      "FrequencyRank": 896,
      "StrokeCount": 10
    }
  },
  "上网": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng wǎng",
      "PrimaryDefinition": "to go online, to connect to the Internet, (of a document etc) to be uploaded to the Internet, (tennis, volleyball etc) to move in close to the net"
    },
    "Keys": {
      "Word": 316
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "恐怕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒngpà",
      "PrimaryDefinition": "perhaps"
    },
    "Keys": {
      "Word": 1683
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "罢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "cease, finish, stop; give up"
    },
    "Keys": {
      "Hanzi": 1918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "122.5",
      "GeneralStandard": 1918,
      "Radical": "网",
      "HSK": 6,
      "FrequencyRank": 1305,
      "StrokeCount": 10
    }
  },
  "因素": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnsù",
      "PrimaryDefinition": "consideration, ingredient, constituent, factor, strand, component, element"
    },
    "Keys": {
      "Word": 5250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "无边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúbiān",
      "PrimaryDefinition": "without boundary, not bordered"
    },
    "Keys": {
      "Word": 5145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "收获": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuhuò",
      "PrimaryDefinition": "harvest, gains, results"
    },
    "Keys": {
      "Word": 2875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "葴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7306
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7306,
      "Radical": "艸",
      "FrequencyRank": 7311,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "会晤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìwù",
      "PrimaryDefinition": "to meet, meeting, conference"
    },
    "Keys": {
      "Word": 7221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拉锁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lāsuǒ",
      "PrimaryDefinition": "zipper"
    },
    "Keys": {
      "Word": 7908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "shout"
    },
    "Keys": {
      "Hanzi": 4218
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4218,
      "Radical": "口",
      "FrequencyRank": 4347,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "别说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biéshuō",
      "PrimaryDefinition": "Don't say"
    },
    "Keys": {
      "Word": 5640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蟏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "a kind of spider with long legs"
    },
    "Keys": {
      "Hanzi": 7935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7935,
      "Radical": "虫",
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "嶍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7676
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7676,
      "Radical": "山",
      "RadicalIndex": "46.11",
      "StrokeCount": 14
    }
  },
  "竹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "bamboo"
    },
    "Keys": {
      "Radical": 118,
      "Hanzi": 450
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118",
      "GeneralStandard": 450,
      "Radical": "竹",
      "HSK": 5,
      "FrequencyRank": 1588,
      "StrokeCount": 6
    }
  },
  "创办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngbàn",
      "PrimaryDefinition": "found, launch, establishment, set up, establish"
    },
    "Keys": {
      "Word": 4451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "秣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "fodder, horse feed; feed horse"
    },
    "Keys": {
      "Hanzi": 4553
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4553,
      "Radical": "禾",
      "FrequencyRank": 4903,
      "RadicalIndex": "115.5",
      "StrokeCount": 10
    }
  },
  "导致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎozhì",
      "PrimaryDefinition": "bring, lead, bring_about, lead to, incite, result in, conduce, bring about, produce, bring_on, render, set off, spark_off, inspire, inflict, cause, land"
    },
    "Keys": {
      "Word": 2397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "钓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào",
      "PrimaryDefinition": "fish; fishhook; tempt, lure"
    },
    "Keys": {
      "Hanzi": 1120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.3",
      "GeneralStandard": 1120,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2305,
      "StrokeCount": 8
    }
  },
  "魅力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèilì",
      "PrimaryDefinition": "personal appeal, allurement, charisma, fascination, spell, attractive feature, glitter, witchery, lure, appeal, grace, loveliness, attraction, allure, enticement, seduction, glamour, pazazz, charm, pishogue, prestige, enchantment, glamor, witchcraft, magnetism, attractiveness, appealingness, hypnotism, bewitchment, invitation, personal magnetism, captivation"
    },
    "Keys": {
      "Word": 8241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赞叹不已": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zàntànbùyǐ",
      "PrimaryDefinition": "to be full of praise (idiom)"
    },
    "Keys": {
      "Word": 10599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piǎo",
      "PrimaryDefinition": "glare at; look askance at; squint"
    },
    "Keys": {
      "Hanzi": 6084
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6084,
      "Radical": "目",
      "FrequencyRank": 3627,
      "RadicalIndex": "109.11",
      "StrokeCount": 16
    }
  },
  "芒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "Miscanthus sinensis"
    },
    "Keys": {
      "Hanzi": 372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.3",
      "GeneralStandard": 372,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 2251,
      "StrokeCount": 6
    }
  },
  "没什么": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méishénme",
      "PrimaryDefinition": "nothing, it doesn't matter, it's nothing, never mind"
    },
    "Keys": {
      "Word": 231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "过意不去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòyìbùqù",
      "PrimaryDefinition": "to feel very apologetic"
    },
    "Keys": {
      "Word": 6956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豪华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háohuá",
      "PrimaryDefinition": "luxurious, sumptuous"
    },
    "Keys": {
      "Word": 7002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngdiǎn",
      "PrimaryDefinition": "canon, classic, classics, sutra, scriptures, scripture, Bible"
    },
    "Keys": {
      "Word": 2637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "猕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "macacus monkey"
    },
    "Keys": {
      "Hanzi": 4930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4930,
      "Radical": "犬",
      "FrequencyRank": 4681,
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "襜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chān",
      "PrimaryDefinition": "flutter; shaking or vibrating"
    },
    "Keys": {
      "Hanzi": 8028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8028,
      "Radical": "衣",
      "RadicalIndex": "145.13",
      "StrokeCount": 18
    }
  },
  "偭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn",
      "PrimaryDefinition": "transgress"
    },
    "Keys": {
      "Hanzi": 7195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7195,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "复杂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzá",
      "PrimaryDefinition": "complicated, complex"
    },
    "Keys": {
      "Word": 1467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "疯狂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngkuáng",
      "PrimaryDefinition": "insane, frenzied, unbridled"
    },
    "Keys": {
      "Word": 3466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "渰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "(of cloud) forming or rising"
    },
    "Keys": {
      "Hanzi": 7429
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7429,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "洹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "river in Henan province"
    },
    "Keys": {
      "Hanzi": 4332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4332,
      "Radical": "水",
      "FrequencyRank": 4646,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "心声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshēng",
      "PrimaryDefinition": "heartfelt wish, aspiration, thinking"
    },
    "Keys": {
      "Word": 10011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "延误": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánwù",
      "PrimaryDefinition": "to delay, to be held up, to miss (an opportunity), delay, holdup"
    },
    "Keys": {
      "Word": 10172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冰山": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīngshān",
      "PrimaryDefinition": "iceberg, CL:座[zuo4]"
    },
    "Keys": {
      "Word": 5650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瀍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "river in Henan province"
    },
    "Keys": {
      "Hanzi": 8026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8026,
      "Radical": "水",
      "RadicalIndex": "85.15",
      "StrokeCount": 18
    }
  },
  "枞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "fir tree"
    },
    "Keys": {
      "Hanzi": 3881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3881,
      "Radical": "木",
      "FrequencyRank": 4497,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "锎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi",
      "PrimaryDefinition": "caesium"
    },
    "Keys": {
      "Hanzi": 7381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7381,
      "Radical": "金",
      "FrequencyRank": 6226,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "勋章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūnzhāng",
      "PrimaryDefinition": "medal, decoration"
    },
    "Keys": {
      "Word": 10144
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8053
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8053,
      "Radical": "木",
      "RadicalIndex": "75.17",
      "StrokeCount": 20
    }
  },
  "瀣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "sea mist; vapor"
    },
    "Keys": {
      "Hanzi": 6413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6413,
      "Radical": "水",
      "FrequencyRank": 5960,
      "RadicalIndex": "85.16",
      "StrokeCount": 19
    }
  },
  "这": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhè",
      "PrimaryDefinition": "this, the, here"
    },
    "Keys": {
      "Hanzi": 837,
      "Word": 462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 1,
      "GeneralStandard": 837,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 11,
      "StrokeCount": 7
    }
  },
  "刻意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèyì",
      "PrimaryDefinition": "fastidiously, sedulously, conscious, purposely, painstakingly"
    },
    "Keys": {
      "Word": 7810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礁石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoshí",
      "PrimaryDefinition": "reef"
    },
    "Keys": {
      "Word": 7477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "a state in Shandong province"
    },
    "Keys": {
      "Hanzi": 6650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6650,
      "Radical": "邑",
      "FrequencyRank": 7390,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "想到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng dào",
      "PrimaryDefinition": "imagine, ideate, think, envisage, anticipate, bethink, dream, recollect, think_of, call to mind, think of, occur, conceive of"
    },
    "Keys": {
      "Word": 1083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "窗台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāngtái",
      "PrimaryDefinition": "window sill, window ledge"
    },
    "Keys": {
      "Word": 2341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎng",
      "PrimaryDefinition": "open space, field, market"
    },
    "Keys": {
      "Hanzi": 367,
      "Word": 554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.3",
      "Grade": 2,
      "GeneralStandard": 367,
      "Radical": "土",
      "HSK": 1,
      "FrequencyRank": 249,
      "StrokeCount": 6
    }
  },
  "流淌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liútǎng",
      "PrimaryDefinition": "to flow"
    },
    "Keys": {
      "Word": 8107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "准备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔnbèi",
      "PrimaryDefinition": "prepare, get ready"
    },
    "Keys": {
      "Word": 483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "碑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "stone tablet; gravestone"
    },
    "Keys": {
      "Hanzi": 2878,
      "Word": 5552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.8",
      "Grade": 7,
      "GeneralStandard": 2878,
      "Radical": "石",
      "HSK": 8,
      "FrequencyRank": 2203,
      "StrokeCount": 13
    }
  },
  "缦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "plain silk; simple, plain"
    },
    "Keys": {
      "Hanzi": 5843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5843,
      "Radical": "糸",
      "FrequencyRank": 5186,
      "RadicalIndex": "120.11",
      "StrokeCount": 14
    }
  },
  "蒙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "cover; ignorant; suffer; mongolia"
    },
    "Keys": {
      "Hanzi": 2859,
      "Word": 4857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.1",
      "Grade": 6,
      "GeneralStandard": 2859,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 1039,
      "StrokeCount": 13
    }
  },
  "本土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běntǔ",
      "PrimaryDefinition": "mainland, native, one's native country, metropolitan territory, continent"
    },
    "Keys": {
      "Word": 4321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "渠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "ditch, canal, channel, gutter"
    },
    "Keys": {
      "Hanzi": 2416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2416,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 2315,
      "StrokeCount": 11
    }
  },
  "烧毁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāohuǐ",
      "PrimaryDefinition": "to burn, to burn down"
    },
    "Keys": {
      "Word": 8991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "price"
    },
    "Keys": {
      "Hanzi": 1956,
      "Word": 2163
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 3,
      "GeneralStandard": 1956,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 600,
      "StrokeCount": 10
    }
  },
  "从业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngyè",
      "PrimaryDefinition": "to practice (a trade)"
    },
    "Keys": {
      "Word": 6063
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奶粉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎifěn",
      "PrimaryDefinition": "powdered milk"
    },
    "Keys": {
      "Word": 4885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "确认": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèrèn",
      "PrimaryDefinition": "countersign, confirm, affirmation, identify with certainty, identify, acknowledgement, acknowledge, quiet, confirmation, recognize, seal, verify, uphold, affirm, notarize"
    },
    "Keys": {
      "Word": 2818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "惑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "confuse, mislead, baffle; doubt"
    },
    "Keys": {
      "Hanzi": 2581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2581,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1600,
      "StrokeCount": 12
    }
  },
  "赟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūn",
      "PrimaryDefinition": "affable, agreeable, pleasant"
    },
    "Keys": {
      "Hanzi": 6153
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6153,
      "Radical": "貝",
      "FrequencyRank": 6580,
      "RadicalIndex": "154.12",
      "StrokeCount": 16
    }
  },
  "工厂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngchǎng",
      "PrimaryDefinition": "layout, mill, factory, remuneration, workshop, industrial plant, plant, hacienda, manufactory, works, shop"
    },
    "Keys": {
      "Word": 1500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "博物馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bówùguǎn",
      "PrimaryDefinition": "museum"
    },
    "Keys": {
      "Word": 3268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "主宰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔzǎi",
      "PrimaryDefinition": "dominate, dictate, decide"
    },
    "Keys": {
      "Word": 10860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枕头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěntou",
      "PrimaryDefinition": "pillow"
    },
    "Keys": {
      "Word": 10705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "中秋节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zhōngqiūjié",
      "PrimaryDefinition": "the Mid-Autumn Festival on 15th of 8th lunar month"
    },
    "Keys": {
      "Word": 4238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "倘若": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎngruò",
      "PrimaryDefinition": "conj.: if, supposing, in case"
    },
    "Keys": {
      "Word": 9427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "根深蒂固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnshēn-dìgù",
      "PrimaryDefinition": "deep-rooted (problem etc)"
    },
    "Keys": {
      "Word": 6740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乘车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngchē",
      "PrimaryDefinition": "to ride (in a car or carriage), to drive, to motor"
    },
    "Keys": {
      "Word": 3329
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "例如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìrú",
      "PrimaryDefinition": "for instance/example, e.g., such as"
    },
    "Keys": {
      "Word": 842
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "海滩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎitān",
      "PrimaryDefinition": "seabeach, seaside, sea beach, foreshore, beach"
    },
    "Keys": {
      "Word": 6968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "造假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàojiǎ",
      "PrimaryDefinition": "to counterfeit, to pass off a fake as genuine"
    },
    "Keys": {
      "Word": 10611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "transplant; plant; dill, Anethum graveolens"
    },
    "Keys": {
      "Hanzi": 4429
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4429,
      "Radical": "艸",
      "FrequencyRank": 6133,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "遵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūn",
      "PrimaryDefinition": "obey, comply with, follow; honor"
    },
    "Keys": {
      "Hanzi": 3288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.12",
      "GeneralStandard": 3288,
      "Radical": "辵",
      "HSK": 5,
      "FrequencyRank": 1593,
      "StrokeCount": 15
    }
  },
  "缁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "black silk"
    },
    "Keys": {
      "Hanzi": 5040
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5040,
      "Radical": "糸",
      "FrequencyRank": 5299,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "腑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "bowels, entrails, internal organs"
    },
    "Keys": {
      "Hanzi": 5232
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5232,
      "Radical": "肉",
      "FrequencyRank": 3491,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "响起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎngqǐ",
      "PrimaryDefinition": "ring_out, ring out, speak, sound, start to ring"
    },
    "Keys": {
      "Word": 9945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "失灵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī líng",
      "PrimaryDefinition": "out of order (of machine), not working properly, a failing (of a system)"
    },
    "Keys": {
      "Word": 9085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "土生土长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔshēng-tǔzhǎng",
      "PrimaryDefinition": "locally born and bred, indigenous, home-grown"
    },
    "Keys": {
      "Word": 9587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō",
      "PrimaryDefinition": "a kind of tool"
    },
    "Keys": {
      "Hanzi": 7533
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7533,
      "Radical": "金",
      "FrequencyRank": 6097,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "到底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàodǐ",
      "PrimaryDefinition": "to the end, at last, after all"
    },
    "Keys": {
      "Word": 1402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "湄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5292,
      "Radical": "水",
      "FrequencyRank": 3639,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "稀少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīshǎo",
      "PrimaryDefinition": "sparse, rare"
    },
    "Keys": {
      "Word": 9822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng",
      "PrimaryDefinition": "leftovers, residue, remains"
    },
    "Keys": {
      "Hanzi": 2675,
      "Word": 3893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.1",
      "Grade": 5,
      "GeneralStandard": 2675,
      "Radical": "刀",
      "HSK": 5,
      "FrequencyRank": 1446,
      "StrokeCount": 12
    }
  },
  "沪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "Shanghai; river near Shanghai"
    },
    "Keys": {
      "Hanzi": 869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 869,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2734,
      "StrokeCount": 7
    }
  },
  "厦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shà",
      "PrimaryDefinition": "big building, mansion"
    },
    "Keys": {
      "Hanzi": 2588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.1",
      "GeneralStandard": 2588,
      "Radical": "厂",
      "HSK": 7,
      "FrequencyRank": 2264,
      "StrokeCount": 12
    }
  },
  "在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zài",
      "PrimaryDefinition": "be at, in, on; consist in, rest"
    },
    "Keys": {
      "Hanzi": 388,
      "Word": 452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.3",
      "Grade": 1,
      "GeneralStandard": 388,
      "Radical": "土",
      "HSK": 1,
      "FrequencyRank": 6,
      "StrokeCount": 6
    }
  },
  "润": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rùn",
      "PrimaryDefinition": "soft, moist; sleek; freshen"
    },
    "Keys": {
      "Hanzi": 2081,
      "Word": 8909
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 7,
      "GeneralStandard": 2081,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1369,
      "StrokeCount": 10
    }
  },
  "恕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "forgive, excuse, show mercy"
    },
    "Keys": {
      "Hanzi": 2142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 2142,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 2314,
      "StrokeCount": 10
    }
  },
  "南瓜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nángua",
      "PrimaryDefinition": "pumpkin"
    },
    "Keys": {
      "Word": 8374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuò",
      "PrimaryDefinition": "expand, enlarge, stretch"
    },
    "Keys": {
      "Hanzi": 364,
      "Word": 7900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.3",
      "Grade": 7,
      "GeneralStandard": 364,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1051,
      "StrokeCount": 6
    }
  },
  "诿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "pass buck, lay blame on others"
    },
    "Keys": {
      "Hanzi": 4674
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4674,
      "Radical": "言",
      "FrequencyRank": 4296,
      "RadicalIndex": "149.8",
      "StrokeCount": 10
    }
  },
  "薪水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnshui",
      "PrimaryDefinition": "stipend, emolument, remuneration, screw, pay, wages, earnings, fee, compensation, pay_packet, salary, wage, living"
    },
    "Keys": {
      "Word": 5188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "欧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ōu",
      "PrimaryDefinition": "translit.: Europe; ohm; surname"
    },
    "Keys": {
      "Hanzi": 1053
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.4",
      "GeneralStandard": 1053,
      "Radical": "欠",
      "HSK": 9,
      "FrequencyRank": 823,
      "StrokeCount": 8
    }
  },
  "鳝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "eel"
    },
    "Keys": {
      "Hanzi": 6443
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6443,
      "Radical": "魚",
      "FrequencyRank": 4598,
      "RadicalIndex": "195.12",
      "StrokeCount": 20
    }
  },
  "撂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liào",
      "PrimaryDefinition": "put down, put aside; drop"
    },
    "Keys": {
      "Hanzi": 5633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5633,
      "Radical": "手",
      "FrequencyRank": 3807,
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "偃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "cease, lay off, lay down"
    },
    "Keys": {
      "Hanzi": 4905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4905,
      "Radical": "人",
      "FrequencyRank": 4171,
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "莞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn",
      "PrimaryDefinition": "smiling; a kind of aquatic herb, clubrush, Scirpus lacustris"
    },
    "Keys": {
      "Hanzi": 4443
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4443,
      "Radical": "艸",
      "FrequencyRank": 4260,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "一系列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxìliè",
      "PrimaryDefinition": "a series of, a string of"
    },
    "Keys": {
      "Word": 10296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "憩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "rest, take rest"
    },
    "Keys": {
      "Hanzi": 6122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6122,
      "Radical": "心",
      "FrequencyRank": 4397,
      "RadicalIndex": "61.12",
      "StrokeCount": 16
    }
  },
  "占": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "divine; observe; versify"
    },
    "Keys": {
      "Hanzi": 228,
      "Word": 1201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "25.3",
      "Grade": 2,
      "GeneralStandard": 228,
      "Radical": "卜",
      "HSK": 2,
      "FrequencyRank": 737,
      "StrokeCount": 5
    }
  },
  "丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "beautiful, magnificent, elegant"
    },
    "Keys": {
      "Hanzi": 699
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "3.7",
      "GeneralStandard": 699,
      "Radical": "丶",
      "HSK": 3,
      "FrequencyRank": 834,
      "StrokeCount": 7
    }
  },
  "靠近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàojìn",
      "PrimaryDefinition": "come near, hug, stand_by, come on, nigh, snuggle, go up, be close to, near, be nearby, approach, draw near, cling, meet"
    },
    "Keys": {
      "Word": 3652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "厄运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èyùn",
      "PrimaryDefinition": "bad luck, misfortune, adversity"
    },
    "Keys": {
      "Word": 6412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "处处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùchù",
      "PrimaryDefinition": "everywhere, in all respects"
    },
    "Keys": {
      "Word": 4438
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蹦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèng",
      "PrimaryDefinition": "hop, leap, jump; bright"
    },
    "Keys": {
      "Hanzi": 3442,
      "Word": 5577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.11",
      "Grade": 7,
      "GeneralStandard": 3442,
      "Radical": "足",
      "HSK": 7,
      "FrequencyRank": 2839,
      "StrokeCount": 18
    }
  },
  "掩饰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnshì",
      "PrimaryDefinition": "cover up, gloss over"
    },
    "Keys": {
      "Word": 10191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "tumor, lump, goiter"
    },
    "Keys": {
      "Hanzi": 3282
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.1",
      "GeneralStandard": 3282,
      "Radical": "疒",
      "HSK": 7,
      "FrequencyRank": 2141,
      "StrokeCount": 15
    }
  },
  "酒精": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔjīng",
      "PrimaryDefinition": "ethanol, alcoholic, spirits of wine, alcohol, ardent spirits, ethyl alcohol, spirit, aqua vitae"
    },
    "Keys": {
      "Word": 7661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盛大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngdà",
      "PrimaryDefinition": "grand, majestic, magnificent, Shanda Entertainment (PRC computer game company)"
    },
    "Keys": {
      "Word": 9075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5748,
      "Radical": "竹",
      "FrequencyRank": 7449,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "亦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "also, too; likewise"
    },
    "Keys": {
      "Hanzi": 516,
      "Word": 10361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "8.4",
      "Grade": 7,
      "GeneralStandard": 516,
      "Radical": "亠",
      "HSK": 8,
      "FrequencyRank": 886,
      "StrokeCount": 6
    }
  },
  "一道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdào",
      "PrimaryDefinition": "together"
    },
    "Keys": {
      "Word": 5232
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "辫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "braid; pigtail, plait, queue"
    },
    "Keys": {
      "Hanzi": 3426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "160.1",
      "GeneralStandard": 3426,
      "Radical": "辛",
      "HSK": 8,
      "FrequencyRank": 3240,
      "StrokeCount": 17
    }
  },
  "浏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "clear; bright; whistling"
    },
    "Keys": {
      "Hanzi": 1677
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1677,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2841,
      "StrokeCount": 9
    }
  },
  "治愈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìyù",
      "PrimaryDefinition": "to cure"
    },
    "Keys": {
      "Word": 10794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趁早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chènzǎo",
      "PrimaryDefinition": "as soon as possible, at the first opportunity, the sooner the better, before it's too late"
    },
    "Keys": {
      "Word": 5863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒushì",
      "PrimaryDefinition": "be occupied/busy, if problems arise"
    },
    "Keys": {
      "Word": 5277
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "伥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "ghost of one devoured by tiger"
    },
    "Keys": {
      "Hanzi": 3607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3607,
      "Radical": "人",
      "FrequencyRank": 5589,
      "RadicalIndex": "9.5",
      "StrokeCount": 6
    }
  },
  "黯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "dark, black; sullen, dreary"
    },
    "Keys": {
      "Hanzi": 3493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3493,
      "Radical": "音",
      "FrequencyRank": 2881,
      "RadicalIndex": "180.12",
      "StrokeCount": 21
    }
  },
  "馏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "distill, distillation"
    },
    "Keys": {
      "Hanzi": 2985
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2985,
      "Radical": "食",
      "FrequencyRank": 4700,
      "RadicalIndex": "184.1",
      "StrokeCount": 13
    }
  },
  "陔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāi",
      "PrimaryDefinition": "a step, a terrace, a grade a ledge"
    },
    "Keys": {
      "Hanzi": 4063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4063,
      "Radical": "阜",
      "FrequencyRank": 6581,
      "RadicalIndex": "170.6",
      "StrokeCount": 8
    }
  },
  "智商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìshāng",
      "PrimaryDefinition": "IQ (intelligence quotient)"
    },
    "Keys": {
      "Word": 10804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "see"
    },
    "Keys": {
      "Hanzi": 118,
      "Radical": 147,
      "Word": 172
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "147",
      "Grade": 1,
      "GeneralStandard": 118,
      "Radical": "見",
      "HSK": 1,
      "FrequencyRank": 153,
      "StrokeCount": 4
    }
  },
  "岁月": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suìyuè",
      "PrimaryDefinition": "precession, years, days, time"
    },
    "Keys": {
      "Word": 3948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "戒指": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièzhi",
      "PrimaryDefinition": "(finger) ring"
    },
    "Keys": {
      "Word": 7550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老太太": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎotàitai",
      "PrimaryDefinition": "old lady, your (my/his/etc.) mother"
    },
    "Keys": {
      "Word": 1687
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "崖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "cliff, precipice; precipitous"
    },
    "Keys": {
      "Hanzi": 2292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.8",
      "GeneralStandard": 2292,
      "Radical": "山",
      "HSK": 8,
      "FrequencyRank": 2247,
      "StrokeCount": 11
    }
  },
  "直达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhídá",
      "PrimaryDefinition": "to go nonstop to, through (as in 'through train'), nonstop"
    },
    "Keys": {
      "Word": 10759
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "we (Shanghai dialect)"
    },
    "Keys": {
      "Hanzi": 6605
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6605,
      "Radical": "人",
      "FrequencyRank": 4454,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "灭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miè",
      "PrimaryDefinition": "extinguish; wipe out, exterminate"
    },
    "Keys": {
      "Hanzi": 223,
      "Word": 4863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.1",
      "Grade": 6,
      "GeneralStandard": 223,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 953,
      "StrokeCount": 5
    }
  },
  "澂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "clear and still water"
    },
    "Keys": {
      "Hanzi": 7816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7816,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "翱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "soar, roam"
    },
    "Keys": {
      "Hanzi": 6131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6131,
      "Radical": "羽",
      "FrequencyRank": 4148,
      "RadicalIndex": "124.1",
      "StrokeCount": 16
    }
  },
  "磻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pán",
      "PrimaryDefinition": "a tributary of the Wei river in Shanxi"
    },
    "Keys": {
      "Hanzi": 7931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7931,
      "Radical": "石",
      "FrequencyRank": 6458,
      "RadicalIndex": "112.12",
      "StrokeCount": 17
    }
  },
  "嗞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "to consult about, to plan; to report in writing to an equal; a despatch"
    },
    "Keys": {
      "Hanzi": 5159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5159,
      "Radical": "口",
      "RadicalIndex": "30.1",
      "StrokeCount": 12
    }
  },
  "面子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànzi",
      "PrimaryDefinition": "reputation, face, prestige"
    },
    "Keys": {
      "Word": 3726
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "获": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "obtain, get, receive; seize"
    },
    "Keys": {
      "Hanzi": 1827,
      "Word": 2568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.7",
      "Grade": 4,
      "GeneralStandard": 1827,
      "Radical": "艸",
      "HSK": 4,
      "FrequencyRank": 688,
      "StrokeCount": 10
    }
  },
  "恬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": "quiet, calm, tranquil, peaceful"
    },
    "Keys": {
      "Hanzi": 1688
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1688,
      "Radical": "心",
      "FrequencyRank": 3717,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "策": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cè",
      "PrimaryDefinition": "scheme, plan; to whip; urge"
    },
    "Keys": {
      "Hanzi": 2683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "GeneralStandard": 2683,
      "Radical": "竹",
      "HSK": 6,
      "FrequencyRank": 714,
      "StrokeCount": 12
    }
  },
  "盘算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pánsuan",
      "PrimaryDefinition": "to plot, to scheme, to calculate"
    },
    "Keys": {
      "Word": 8464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荣幸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngxìng",
      "PrimaryDefinition": "honored (to have the privilege of ...)"
    },
    "Keys": {
      "Word": 8878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "减弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnruò",
      "PrimaryDefinition": "to weaken, to fall off"
    },
    "Keys": {
      "Word": 7405
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灵魂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línghún",
      "PrimaryDefinition": "god, anima, Psyche, psyche, spirit, pneuma, ghost, manes, soul"
    },
    "Keys": {
      "Word": 8067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "螳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "mantis"
    },
    "Keys": {
      "Hanzi": 6234
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6234,
      "Radical": "虫",
      "FrequencyRank": 4267,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "珅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6760,
      "Radical": "玉",
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "他们": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāmen",
      "PrimaryDefinition": "they"
    },
    "Keys": {
      "Word": 350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "剧院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùyuàn",
      "PrimaryDefinition": "theater"
    },
    "Keys": {
      "Word": 7705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "move, shift, change; transfer; relocate a capital city"
    },
    "Keys": {
      "Hanzi": 451,
      "Word": 8650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.3",
      "Grade": 7,
      "GeneralStandard": 451,
      "Radical": "辵",
      "HSK": 6,
      "FrequencyRank": 1630,
      "StrokeCount": 6
    }
  },
  "懋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "splendid, grand, majestic"
    },
    "Keys": {
      "Hanzi": 6214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6214,
      "Radical": "心",
      "FrequencyRank": 4350,
      "RadicalIndex": "61.13",
      "StrokeCount": 17
    }
  },
  "血脉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuèmài",
      "PrimaryDefinition": "blood vessels"
    },
    "Keys": {
      "Word": 10140
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiū",
      "PrimaryDefinition": "surname; hill; mound; grave"
    },
    "Keys": {
      "Hanzi": 3729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3729,
      "Radical": "邑",
      "FrequencyRank": 2452,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "个人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèrén",
      "PrimaryDefinition": "individual person, man_jack, personal, individual (person), man jack, individual, individuality, identity"
    },
    "Keys": {
      "Word": 1489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "杜绝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùjué",
      "PrimaryDefinition": "to put an end to"
    },
    "Keys": {
      "Word": 6363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "moult"
    },
    "Keys": {
      "Hanzi": 4877
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4877,
      "Radical": "金",
      "FrequencyRank": 4683,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "外汇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàihuì",
      "PrimaryDefinition": "foreign exchange"
    },
    "Keys": {
      "Word": 2959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "气管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìguǎn",
      "PrimaryDefinition": "windpipe, trachea, respiratory tract, air duct, gas pipe"
    },
    "Keys": {
      "Word": 8621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "前沿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiányán",
      "PrimaryDefinition": "front-line, forward position, outpost, extending ahead, frontier (of science, technology etc)"
    },
    "Keys": {
      "Word": 8673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnjī",
      "PrimaryDefinition": "to strike back, to beat back, to counterattack"
    },
    "Keys": {
      "Word": 6472
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "part, division, section"
    },
    "Keys": {
      "Hanzi": 2044,
      "Word": 1326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "163.8",
      "Grade": 3,
      "GeneralStandard": 2044,
      "Radical": "邑",
      "HSK": 2,
      "FrequencyRank": 84,
      "StrokeCount": 10
    }
  },
  "程度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngdù",
      "PrimaryDefinition": "latitude, rate, degree, point, measure, way, grade, gree, pitch, cut, length, plane, stage, level, extent, scale, notch"
    },
    "Keys": {
      "Word": 1357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "淅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "water used wash rice; to wash ric"
    },
    "Keys": {
      "Hanzi": 4966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4966,
      "Radical": "水",
      "FrequencyRank": 4616,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "傒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "personal name; servant"
    },
    "Keys": {
      "Hanzi": 7395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7395,
      "Radical": "人",
      "RadicalIndex": "9.1",
      "StrokeCount": 12
    }
  },
  "晚安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnān",
      "PrimaryDefinition": "Good night!, Good evening!"
    },
    "Keys": {
      "Word": 1050
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "狭小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáxiǎo",
      "PrimaryDefinition": "narrow and small, narrow"
    },
    "Keys": {
      "Word": 9853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恶心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěxin",
      "PrimaryDefinition": "nausea, to feel sick, disgust, nauseating, to embarrass (deliberately), bad habit, vicious habit, vice"
    },
    "Keys": {
      "Word": 2438
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恍然大悟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎngrán-dàwù",
      "PrimaryDefinition": "to suddenly realize, to suddenly see the light"
    },
    "Keys": {
      "Word": 7193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǒ",
      "PrimaryDefinition": "bare, nude; undress, strip"
    },
    "Keys": {
      "Hanzi": 3032,
      "Word": 8171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.8",
      "Grade": 7,
      "GeneralStandard": 3032,
      "Radical": "衣",
      "HSK": 7,
      "FrequencyRank": 2164,
      "StrokeCount": 13
    }
  },
  "函": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "correspondence; a case; a box"
    },
    "Keys": {
      "Hanzi": 1322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "17.6",
      "GeneralStandard": 1322,
      "Radical": "凵",
      "HSK": 9,
      "FrequencyRank": 1950,
      "StrokeCount": 8
    }
  },
  "匚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": "right open box"
    },
    "Keys": {
      "Radical": 22
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "进步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnbù",
      "PrimaryDefinition": "progress, advance, improve"
    },
    "Keys": {
      "Word": 1632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "堵塞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔsè",
      "PrimaryDefinition": "to clog up, blockage"
    },
    "Keys": {
      "Word": 6362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "采": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎi",
      "PrimaryDefinition": "collect, gather; pick, pluck"
    },
    "Keys": {
      "Hanzi": 1172,
      "Word": 5742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "165.1",
      "Grade": 7,
      "GeneralStandard": 1172,
      "Radical": "釆",
      "HSK": 3,
      "FrequencyRank": 585,
      "StrokeCount": 8
    }
  },
  "瞭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎo",
      "PrimaryDefinition": "bright, clear; clear-sighted"
    },
    "Keys": {
      "Hanzi": 3401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3401,
      "Radical": "目",
      "FrequencyRank": 4345,
      "RadicalIndex": "109.12",
      "StrokeCount": 17
    }
  },
  "嵌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "inlay, set in; fall into; rugged"
    },
    "Keys": {
      "Hanzi": 2647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.9",
      "GeneralStandard": 2647,
      "Radical": "山",
      "HSK": 9,
      "FrequencyRank": 2831,
      "StrokeCount": 12
    }
  },
  "受不了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòubuliǎo",
      "PrimaryDefinition": "unbearable, unable to endure, can't stand"
    },
    "Keys": {
      "Word": 2880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "耒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "plow"
    },
    "Keys": {
      "Radical": 127,
      "Hanzi": 3564
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3564,
      "Radical": "耒",
      "FrequencyRank": 5701,
      "RadicalIndex": "127",
      "StrokeCount": 6
    }
  },
  "榆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "elm tree"
    },
    "Keys": {
      "Hanzi": 2869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2869,
      "Radical": "木",
      "FrequencyRank": 3272,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "劲头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìntóu",
      "PrimaryDefinition": "enthusiasm, zeal, vigor, strength"
    },
    "Keys": {
      "Word": 7585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénzào",
      "PrimaryDefinition": "man-made, synthetic, artificial"
    },
    "Keys": {
      "Word": 8854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "to spread (name, news); to be known"
    },
    "Keys": {
      "Hanzi": 7480
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7480,
      "Radical": "手",
      "RadicalIndex": "64.11",
      "StrokeCount": 13
    }
  },
  "鞘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiào",
      "PrimaryDefinition": "scabbard, sheath"
    },
    "Keys": {
      "Hanzi": 6047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6047,
      "Radical": "革",
      "FrequencyRank": 3036,
      "RadicalIndex": "177.7",
      "StrokeCount": 16
    }
  },
  "罢免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàmiǎn",
      "PrimaryDefinition": "to remove sb from their post, to dismiss"
    },
    "Keys": {
      "Word": 5465
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老乡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoxiāng",
      "PrimaryDefinition": "fellow townsman, fellow villager, sb from the same hometown"
    },
    "Keys": {
      "Word": 4813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "凑巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "còuqiǎo",
      "PrimaryDefinition": "fortuitously, luckily, as chance has it"
    },
    "Keys": {
      "Word": 6068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "fishhawk bow or prow"
    },
    "Keys": {
      "Hanzi": 7810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7810,
      "Radical": "鳥",
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "骚乱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāoluàn",
      "PrimaryDefinition": "disturbance, riot, to create a disturbance"
    },
    "Keys": {
      "Word": 8925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "薪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "fuel, firewood; salary"
    },
    "Keys": {
      "Hanzi": 3325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.13",
      "GeneralStandard": 3325,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 2167,
      "StrokeCount": 16
    }
  },
  "鲠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěng",
      "PrimaryDefinition": "fish bones; honest, upright"
    },
    "Keys": {
      "Hanzi": 5968
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5968,
      "Radical": "魚",
      "FrequencyRank": 5612,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "网址": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngzhǐ",
      "PrimaryDefinition": "website, web address, URL"
    },
    "Keys": {
      "Word": 2968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "钓鱼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diào yú",
      "PrimaryDefinition": "go fishing, fish, go angling, angle"
    },
    "Keys": {
      "Word": 6291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鞅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5647,
      "Radical": "革",
      "FrequencyRank": 3412,
      "RadicalIndex": "177.5",
      "StrokeCount": 14
    }
  },
  "蔹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎn",
      "PrimaryDefinition": "wild vine, vitis pentaphylla"
    },
    "Keys": {
      "Hanzi": 7644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7644,
      "Radical": "艸",
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "白白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báibái",
      "PrimaryDefinition": "in vain, to no purpose, for nothing, white"
    },
    "Keys": {
      "Word": 5470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "上去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng qu",
      "PrimaryDefinition": "to go up"
    },
    "Keys": {
      "Word": 1869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "女孩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚháir",
      "PrimaryDefinition": "girl, erhua variant of 女孩[nu:3 hai2]"
    },
    "Keys": {
      "Word": 274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "朕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4595,
      "Radical": "月",
      "FrequencyRank": 3054,
      "RadicalIndex": "74.6",
      "StrokeCount": 10
    }
  },
  "东方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngfāng",
      "PrimaryDefinition": "oriental, dawning, northeast, eastward, E, Orient, orient, orientation, the Orient, Surname, sunrise, eastern, eastern hemisphere, Dongfang, due east, East, the East, east, the east"
    },
    "Keys": {
      "Word": 624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "刈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "cut off, reap, mow; sickle"
    },
    "Keys": {
      "Hanzi": 3519
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3519,
      "Radical": "刀",
      "FrequencyRank": 4738,
      "RadicalIndex": "18.2",
      "StrokeCount": 4
    }
  },
  "倏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "hastily, suddenly, abruptly"
    },
    "Keys": {
      "Hanzi": 4568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4568,
      "Radical": "人",
      "FrequencyRank": 2796,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "干脆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāncuì",
      "PrimaryDefinition": "direct, forthright"
    },
    "Keys": {
      "Word": 3477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鼓舞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔwǔ",
      "PrimaryDefinition": "inspire, hearten, dance for joy, rejoice"
    },
    "Keys": {
      "Word": 6836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "美好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měihǎo",
      "PrimaryDefinition": "fine, happy, glorious"
    },
    "Keys": {
      "Word": 1738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "副作用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùzuòyòng",
      "PrimaryDefinition": "side-effect"
    },
    "Keys": {
      "Word": 6638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "cliff; rocks; mountain"
    },
    "Keys": {
      "Hanzi": 1106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.5",
      "GeneralStandard": 1106,
      "Radical": "山",
      "HSK": 7,
      "FrequencyRank": 1423,
      "StrokeCount": 8
    }
  },
  "辏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "còu",
      "PrimaryDefinition": "hubs of wheel; converge around"
    },
    "Keys": {
      "Hanzi": 5425
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5425,
      "Radical": "車",
      "FrequencyRank": 4354,
      "RadicalIndex": "159.9",
      "StrokeCount": 13
    }
  },
  "见仁见智": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànrén-jiànzhì",
      "PrimaryDefinition": "opinions differ (idiom)"
    },
    "Keys": {
      "Word": 7418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "再次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàicì",
      "PrimaryDefinition": "once again, once_more, second time, again, a second time, over, third, once more/again, once more"
    },
    "Keys": {
      "Word": 4168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "做生意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò shēngyi",
      "PrimaryDefinition": "merchandise, deal, trade, sell, do business"
    },
    "Keys": {
      "Word": 11034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "ferrule"
    },
    "Keys": {
      "Hanzi": 4609
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4609,
      "Radical": "食",
      "FrequencyRank": 4490,
      "RadicalIndex": "184.7",
      "StrokeCount": 10
    }
  },
  "纽扣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔkòu",
      "PrimaryDefinition": "button"
    },
    "Keys": {
      "Word": 8433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "palm"
    },
    "Keys": {
      "Hanzi": 7142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7142,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "大意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàyi",
      "PrimaryDefinition": "general idea, main idea, careless"
    },
    "Keys": {
      "Word": 6139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāo",
      "PrimaryDefinition": "throw (away)"
    },
    "Keys": {
      "Hanzi": 642,
      "Word": 8473
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 642,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1692,
      "StrokeCount": 7
    }
  },
  "无微不至": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúwēi-bùzhì",
      "PrimaryDefinition": "in every possible way (idiom), meticulous"
    },
    "Keys": {
      "Word": 9786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "橙汁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngzhī",
      "PrimaryDefinition": "orange juice, CL:瓶[ping2],杯[bei1],罐[guan4],盒[he2]"
    },
    "Keys": {
      "Word": 5888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "州": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "administrative division, state"
    },
    "Keys": {
      "Hanzi": 535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "47.3",
      "GeneralStandard": 535,
      "Radical": "巛",
      "HSK": 9,
      "FrequencyRank": 721,
      "StrokeCount": 6
    }
  },
  "笾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "bamboo container for food"
    },
    "Keys": {
      "Hanzi": 4902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4902,
      "Radical": "竹",
      "FrequencyRank": 5252,
      "RadicalIndex": "118.6",
      "StrokeCount": 11
    }
  },
  "轨道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐdào",
      "PrimaryDefinition": "circle, path, trajectory, railroad track, orbital, course, proper way of doing things, trackage, track, orbit, flight, rail, railway, trackway, tramroad, career, orb"
    },
    "Keys": {
      "Word": 4635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "为主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéizhǔ",
      "PrimaryDefinition": "give first place to"
    },
    "Keys": {
      "Word": 4006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "铌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "niobium"
    },
    "Keys": {
      "Hanzi": 4544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4544,
      "Radical": "金",
      "FrequencyRank": 6178,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "肝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "liver"
    },
    "Keys": {
      "Hanzi": 803,
      "Word": 4581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.3",
      "Grade": 6,
      "GeneralStandard": 803,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 1760,
      "StrokeCount": 7
    }
  },
  "旗帜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qízhì",
      "PrimaryDefinition": "flag, stand, banner, colors, standard, colours, pennon, ensign, streamer, national flag, rallying point, signal flag"
    },
    "Keys": {
      "Word": 8604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎimiàn",
      "PrimaryDefinition": "offing, sea surface, offshore, sea level, rim"
    },
    "Keys": {
      "Word": 6966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遮盖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēgài",
      "PrimaryDefinition": "to hide, to cover (one's tracks)"
    },
    "Keys": {
      "Word": 10684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "推销": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīxiāo",
      "PrimaryDefinition": "promote sale of, peddle"
    },
    "Keys": {
      "Word": 2956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "跱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "stop"
    },
    "Keys": {
      "Hanzi": 7522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7522,
      "Radical": "足",
      "FrequencyRank": 8487,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "晋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "advance, increase; promote"
    },
    "Keys": {
      "Hanzi": 1828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.6",
      "GeneralStandard": 1828,
      "Radical": "日",
      "HSK": 8,
      "FrequencyRank": 1645,
      "StrokeCount": 10
    }
  },
  "懒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎn",
      "PrimaryDefinition": "lazy, languid, listless"
    },
    "Keys": {
      "Hanzi": 3380,
      "Word": 4823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.13",
      "Grade": 6,
      "GeneralStandard": 3380,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 2230,
      "StrokeCount": 16
    }
  },
  "本质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnzhì",
      "PrimaryDefinition": "suchness, basic nature, intrinsic quality, substantial, essentiality, innate character, entity, essential quality, basics, inbeing, essential, nature, self, feather, intrinsic, quiddity, being, substance, metal, quintessence, principle, kind, hypostasis, essence, fiber, internal, spirit, inwardness, woof, basis, gist"
    },
    "Keys": {
      "Word": 4320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "填充": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiánchōng",
      "PrimaryDefinition": "pad, padding"
    },
    "Keys": {
      "Word": 9491
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "county in Shaanxi province"
    },
    "Keys": {
      "Hanzi": 6542
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6542,
      "Radical": "邑",
      "FrequencyRank": 6508,
      "RadicalIndex": "163.4",
      "StrokeCount": 6
    }
  },
  "遍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "everywhere, all over, throughout"
    },
    "Keys": {
      "Hanzi": 2788,
      "Word": 523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "Grade": 2,
      "GeneralStandard": 2788,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 1012,
      "StrokeCount": 12
    }
  },
  "铛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāng",
      "PrimaryDefinition": "frying pan; warming vessel"
    },
    "Keys": {
      "Hanzi": 2304
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2304,
      "Radical": "金",
      "FrequencyRank": 4436,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "地址": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìzhǐ",
      "PrimaryDefinition": "location, domicile, street address, address, name and address, destination"
    },
    "Keys": {
      "Word": 2410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "锕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ā",
      "PrimaryDefinition": "actinium (Ac)"
    },
    "Keys": {
      "Hanzi": 5190
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5190,
      "Radical": "金",
      "FrequencyRank": 5341,
      "RadicalIndex": "167.8",
      "StrokeCount": 12
    }
  },
  "粉丝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěnsī",
      "PrimaryDefinition": "bean vermicelli, mung bean starch noodles, Chinese vermicelli, cellophane noodles, CL:把[ba3], fan (loanword), enthusiast for sb or sth"
    },
    "Keys": {
      "Word": 6550
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "内行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèiháng",
      "PrimaryDefinition": "expert, adept, experienced, an expert, a professional"
    },
    "Keys": {
      "Word": 8392
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoshí",
      "PrimaryDefinition": "hour"
    },
    "Keys": {
      "Word": 401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "躲藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duǒcáng",
      "PrimaryDefinition": "to conceal oneself, to go into hiding, to take cover"
    },
    "Keys": {
      "Word": 6403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小吃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎochī",
      "PrimaryDefinition": "snack, refreshments, CL:家[jia1]"
    },
    "Keys": {
      "Word": 3028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "哦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ó",
      "PrimaryDefinition": "oh? really? is that so?"
    },
    "Keys": {
      "Hanzi": 1908,
      "Word": 8448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "Grade": 7,
      "GeneralStandard": 1908,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1913,
      "StrokeCount": 10
    }
  },
  "本科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běnkē",
      "PrimaryDefinition": "undergraduate course, undergraduate (attributive)"
    },
    "Keys": {
      "Word": 2258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "水灾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐzāi",
      "PrimaryDefinition": "flood, flood damage"
    },
    "Keys": {
      "Word": 3936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "闹着玩儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàozhewánr5",
      "PrimaryDefinition": "to play games, to joke around, to play a joke on sb"
    },
    "Keys": {
      "Word": 8389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "dark; obscure; in secret, covert"
    },
    "Keys": {
      "Hanzi": 2909,
      "Word": 2238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.9",
      "Grade": 4,
      "GeneralStandard": 2909,
      "Radical": "日",
      "HSK": 4,
      "FrequencyRank": 829,
      "StrokeCount": 13
    }
  },
  "癯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "thin, emaciated; worn, tired"
    },
    "Keys": {
      "Hanzi": 6484
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6484,
      "Radical": "疒",
      "FrequencyRank": 5499,
      "RadicalIndex": "104.18",
      "StrokeCount": 23
    }
  },
  "到达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàodá",
      "PrimaryDefinition": "find, arrive at, go_into, get_in, go, come, show, hit, strike, arrive, fetch_up, range, show_up, gain, come_to, win, reach, come to, attain, lead, go_down, come_in, arrival, get_at, get to, get, touch, make, come_through, arrive_at, land"
    },
    "Keys": {
      "Word": 1400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "涌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "surge up, bubble up, gush forth"
    },
    "Keys": {
      "Hanzi": 2089,
      "Word": 10438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 7,
      "GeneralStandard": 2089,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 1642,
      "StrokeCount": 10
    }
  },
  "动画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dònghuà",
      "PrimaryDefinition": "(animated) cartoon"
    },
    "Keys": {
      "Word": 4516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嫡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "legal wife, child of legal wife"
    },
    "Keys": {
      "Hanzi": 5836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5836,
      "Radical": "女",
      "FrequencyRank": 3746,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "就地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùdì",
      "PrimaryDefinition": "locally, on the spot"
    },
    "Keys": {
      "Word": 7667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "候选人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuxuǎnrén",
      "PrimaryDefinition": "aspirant, nominee, campaigner, candidate, candidate (for election)"
    },
    "Keys": {
      "Word": 7105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "之内": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīnèi",
      "PrimaryDefinition": "inside of, among, within"
    },
    "Keys": {
      "Word": 4214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "跳跃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiàoyuè",
      "PrimaryDefinition": "to jump, to leap, to bound, to skip"
    },
    "Keys": {
      "Word": 9508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "着落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóluò",
      "PrimaryDefinition": "whereabouts, place to settle, reliable source (of funds etc), (of responsibility for a matter) to rest with sb, settlement, solution"
    },
    "Keys": {
      "Word": 10932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "小气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoqi",
      "PrimaryDefinition": "stingy, petty, miserly, narrow-minded"
    },
    "Keys": {
      "Word": 9970
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诚实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngshi",
      "PrimaryDefinition": "honest"
    },
    "Keys": {
      "Word": 2313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恰如其分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàrú-qífèn",
      "PrimaryDefinition": "to say or do sth appropriate (idiom)"
    },
    "Keys": {
      "Word": 8642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "罨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "medical compress; fish net"
    },
    "Keys": {
      "Hanzi": 5477
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5477,
      "Radical": "网",
      "FrequencyRank": 6156,
      "RadicalIndex": "122.8",
      "StrokeCount": 13
    }
  },
  "高温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāowēn",
      "PrimaryDefinition": "heat, high temperature"
    },
    "Keys": {
      "Word": 3486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "通报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngbào",
      "PrimaryDefinition": "to inform, to notify, to announce, circular, bulletin, (scientific) journal"
    },
    "Keys": {
      "Word": 5087
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "尺寸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǐcun",
      "PrimaryDefinition": "measurement, dimension"
    },
    "Keys": {
      "Word": 2327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "㸆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6411
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6411,
      "Radical": "火",
      "RadicalIndex": "86.15",
      "StrokeCount": 19
    }
  },
  "睡袋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuìdài",
      "PrimaryDefinition": "sleeping bag"
    },
    "Keys": {
      "Word": 9276
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刷子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāzi",
      "PrimaryDefinition": "brush, scrub, CL:把[ba3]"
    },
    "Keys": {
      "Word": 2894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "扭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔ",
      "PrimaryDefinition": "turn, twist, wrench; seize, grasp"
    },
    "Keys": {
      "Hanzi": 653,
      "Word": 4893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 6,
      "GeneralStandard": 653,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1805,
      "StrokeCount": 7
    }
  },
  "关注": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānzhù",
      "PrimaryDefinition": "follow with interest, pay close attention to"
    },
    "Keys": {
      "Word": 1517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "促进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cùjìn",
      "PrimaryDefinition": "rush, help, step_up, advancement, progress, acceleration, subserve, encourage, facilitate, anticipate, accelerate, assist, fillip, expedite, advance, promotive, boost, further, provoke, hurry, redound, cultivate, induce, hasten, catalyze, speed, promote, contribute, impel, promotion, push, forward, stimulate, whet"
    },
    "Keys": {
      "Word": 2360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "埯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎn",
      "PrimaryDefinition": "cover with earth; a pit; a hole"
    },
    "Keys": {
      "Hanzi": 4718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4718,
      "Radical": "土",
      "FrequencyRank": 6755,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "锸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā",
      "PrimaryDefinition": "spade, shovel; marking pin"
    },
    "Keys": {
      "Hanzi": 5737
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5737,
      "Radical": "金",
      "FrequencyRank": 5426,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "胛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "the shoulder, shoulder blade"
    },
    "Keys": {
      "Hanzi": 4281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4281,
      "Radical": "肉",
      "FrequencyRank": 4715,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "衡量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héngliang",
      "PrimaryDefinition": "weigh, measure, judge"
    },
    "Keys": {
      "Word": 4664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "天使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānshǐ",
      "PrimaryDefinition": "angel"
    },
    "Keys": {
      "Word": 9484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "仙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "Taoist super-being, transcendent, immortal"
    },
    "Keys": {
      "Hanzi": 272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "GeneralStandard": 272,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 1255,
      "StrokeCount": 5
    }
  },
  "公路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnglù",
      "PrimaryDefinition": "trunk road, calzada, route, main road, highroad, highway, road, thoroughfare, causeway, chaussee"
    },
    "Keys": {
      "Word": 685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "薇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "Osmunda regalis, a species of fern"
    },
    "Keys": {
      "Hanzi": 3323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3323,
      "Radical": "艸",
      "FrequencyRank": 3193,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "镌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": "engraving tool; carve, engrave"
    },
    "Keys": {
      "Hanzi": 5948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5948,
      "Radical": "金",
      "FrequencyRank": 4233,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "酅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "raise feet; town in Shandong province"
    },
    "Keys": {
      "Hanzi": 8059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8059,
      "Radical": "邑",
      "RadicalIndex": "163.18",
      "StrokeCount": 20
    }
  },
  "潸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "weep; tears flowing"
    },
    "Keys": {
      "Hanzi": 5999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5999,
      "Radical": "水",
      "FrequencyRank": 5126,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "绤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "cloth"
    },
    "Keys": {
      "Hanzi": 7095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7095,
      "Radical": "糸",
      "FrequencyRank": 8618,
      "RadicalIndex": "120.7",
      "StrokeCount": 10
    }
  },
  "办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": "manage, do, handle; deal with"
    },
    "Keys": {
      "Hanzi": 181,
      "Word": 504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.2",
      "Grade": 2,
      "GeneralStandard": 181,
      "Radical": "力",
      "HSK": 2,
      "FrequencyRank": 367,
      "StrokeCount": 4
    }
  },
  "最": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuì",
      "PrimaryDefinition": "most, extremely, exceedingly"
    },
    "Keys": {
      "Hanzi": 2614,
      "Word": 488
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73.8",
      "Grade": 1,
      "GeneralStandard": 2614,
      "Radical": "曰",
      "HSK": 1,
      "FrequencyRank": 139,
      "StrokeCount": 12
    }
  },
  "霾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mái",
      "PrimaryDefinition": "misty, foggy; dust storm"
    },
    "Keys": {
      "Hanzi": 6468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6468,
      "Radical": "雨",
      "FrequencyRank": 4319,
      "RadicalIndex": "173.14",
      "StrokeCount": 22
    }
  },
  "精心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngxīn",
      "PrimaryDefinition": "with utmost care, fine, meticulous, detailed"
    },
    "Keys": {
      "Word": 7623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "…极了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "…jíle",
      "PrimaryDefinition": "…Extremely"
    },
    "Keys": {
      "Word": 1575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "棰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5099,
      "Radical": "木",
      "FrequencyRank": 6511,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "趿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "to tread on; slipshod"
    },
    "Keys": {
      "Hanzi": 4505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4505,
      "Radical": "足",
      "FrequencyRank": 5238,
      "RadicalIndex": "157.4",
      "StrokeCount": 10
    }
  },
  "睃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5126,
      "Radical": "目",
      "FrequencyRank": 5958,
      "RadicalIndex": "109.7",
      "StrokeCount": 12
    }
  },
  "月初": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèchū",
      "PrimaryDefinition": "start of month, early in the month"
    },
    "Keys": {
      "Word": 10560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "to look at closely; nearsighted; dim; indistinct vision"
    },
    "Keys": {
      "Hanzi": 5839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5839,
      "Radical": "目",
      "FrequencyRank": 6487,
      "RadicalIndex": "109.9",
      "StrokeCount": 14
    }
  },
  "剧团": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùtuán",
      "PrimaryDefinition": "opera troupe, theater company, showman, rep, theatrical company, troupe, company"
    },
    "Keys": {
      "Word": 7704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "因为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnwei",
      "PrimaryDefinition": "conj.: because, for, on account of"
    },
    "Keys": {
      "Word": 1154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "变换": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànhuàn",
      "PrimaryDefinition": "to transform, to convert, to vary, to alternate, a transformation"
    },
    "Keys": {
      "Word": 4333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "狁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔn",
      "PrimaryDefinition": "a tribe of Scythian nomads"
    },
    "Keys": {
      "Hanzi": 3747
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3747,
      "Radical": "犬",
      "FrequencyRank": 6520,
      "RadicalIndex": "94.4",
      "StrokeCount": 7
    }
  },
  "盔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuī",
      "PrimaryDefinition": "helmet; bowl; basin"
    },
    "Keys": {
      "Hanzi": 2243
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2243,
      "Radical": "皿",
      "FrequencyRank": 3030,
      "RadicalIndex": "108.6",
      "StrokeCount": 11
    }
  },
  "炬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "torch"
    },
    "Keys": {
      "Hanzi": 1233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "GeneralStandard": 1233,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 3429,
      "StrokeCount": 8
    }
  },
  "荃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "aromatic herb; fine cloth"
    },
    "Keys": {
      "Hanzi": 4122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4122,
      "Radical": "艸",
      "FrequencyRank": 3756,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "顾全大局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùquándàjú",
      "PrimaryDefinition": "to take the big picture into consideration (idiom), to work for the benefits of all"
    },
    "Keys": {
      "Word": 6846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "valley"
    },
    "Keys": {
      "Radical": 150,
      "Hanzi": 798
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "150",
      "GeneralStandard": 798,
      "Radical": "谷",
      "HSK": 6,
      "FrequencyRank": 1095,
      "StrokeCount": 7
    }
  },
  "阔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuò",
      "PrimaryDefinition": "broad, ample, wide; be apart"
    },
    "Keys": {
      "Hanzi": 2737,
      "Word": 4805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.9",
      "Grade": 6,
      "GeneralStandard": 2737,
      "Radical": "門",
      "HSK": 6,
      "FrequencyRank": 1675,
      "StrokeCount": 12
    }
  },
  "舞厅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔtīng",
      "PrimaryDefinition": "dance hall, ballroom, CL:間|间[jian1]"
    },
    "Keys": {
      "Word": 9804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "导弹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎodàn",
      "PrimaryDefinition": "guided missile, cruise missile, missile, CL:枚[mei2]"
    },
    "Keys": {
      "Word": 6187
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "species of Taiwan pigeon"
    },
    "Keys": {
      "Hanzi": 4445
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4445,
      "Radical": "鳥",
      "FrequencyRank": 5008,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "捉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "grasp, clutch; catch, seize"
    },
    "Keys": {
      "Hanzi": 1795,
      "Word": 5394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 6,
      "GeneralStandard": 1795,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1822,
      "StrokeCount": 10
    }
  },
  "譬如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìrú",
      "PrimaryDefinition": "for example, such as"
    },
    "Keys": {
      "Word": 8513
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "no, not; un-; negative prefix"
    },
    "Keys": {
      "Hanzi": 91,
      "Word": 36
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.3",
      "Grade": 1,
      "GeneralStandard": 91,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 4,
      "StrokeCount": 4
    }
  },
  "孢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāo",
      "PrimaryDefinition": "spore"
    },
    "Keys": {
      "Hanzi": 4061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4061,
      "Radical": "子",
      "FrequencyRank": 4216,
      "RadicalIndex": "39.5",
      "StrokeCount": 8
    }
  },
  "倩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàn",
      "PrimaryDefinition": "beautiful, lovely; son-in-law"
    },
    "Keys": {
      "Hanzi": 4562
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4562,
      "Radical": "人",
      "FrequencyRank": 2583,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "拇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔ",
      "PrimaryDefinition": "thumb; big toe"
    },
    "Keys": {
      "Hanzi": 994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 994,
      "Radical": "手",
      "FrequencyRank": 3031,
      "RadicalIndex": "64.5",
      "StrokeCount": 8
    }
  },
  "何时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héshí",
      "PrimaryDefinition": "when"
    },
    "Keys": {
      "Word": 7036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "字体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìtǐ",
      "PrimaryDefinition": "calligraphic style, typeface, font"
    },
    "Keys": {
      "Word": 10976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滥用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lànyòng",
      "PrimaryDefinition": "abuse, misuse"
    },
    "Keys": {
      "Word": 7932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "憧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "irresolute, indecisive; yearn for"
    },
    "Keys": {
      "Hanzi": 6007
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6007,
      "Radical": "心",
      "FrequencyRank": 3626,
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "调节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáojié",
      "PrimaryDefinition": "regulate, adjust"
    },
    "Keys": {
      "Word": 3984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "嗔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēn",
      "PrimaryDefinition": "be angry at, scold, rebuke"
    },
    "Keys": {
      "Hanzi": 5444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5444,
      "Radical": "口",
      "FrequencyRank": 3546,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "出去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū qu",
      "PrimaryDefinition": "go_out, go/get out, get_out, go out, get out, go, exit"
    },
    "Keys": {
      "Word": 50
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "媒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "go-between, matchmaker; medium"
    },
    "Keys": {
      "Hanzi": 2808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.9",
      "GeneralStandard": 2808,
      "Radical": "女",
      "HSK": 3,
      "FrequencyRank": 1506,
      "StrokeCount": 12
    }
  },
  "稿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "draft, manuscript, rough copy"
    },
    "Keys": {
      "Hanzi": 3263
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.1",
      "GeneralStandard": 3263,
      "Radical": "禾",
      "HSK": 6,
      "FrequencyRank": 1587,
      "StrokeCount": 15
    }
  },
  "金额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīné",
      "PrimaryDefinition": "amount of money, sum_of_money, sum, money, penny, amount/sum of money, sum of money, amount"
    },
    "Keys": {
      "Word": 4738
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "珍重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnzhòng",
      "PrimaryDefinition": "precious, extremely valuable, (honorific) Please take good care of yourself!"
    },
    "Keys": {
      "Word": 10696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āi",
      "PrimaryDefinition": "einsteinium (Es)"
    },
    "Keys": {
      "Hanzi": 7684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7684,
      "Radical": "金",
      "FrequencyRank": 5941,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "旵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6590
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6590,
      "Radical": "日",
      "RadicalIndex": "72.3",
      "StrokeCount": 7
    }
  },
  "搴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "extract; seize; pluck up"
    },
    "Keys": {
      "Hanzi": 5814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5814,
      "Radical": "手",
      "FrequencyRank": 6012,
      "RadicalIndex": "64.1",
      "StrokeCount": 14
    }
  },
  "此时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐshí",
      "PrimaryDefinition": "now, this moment"
    },
    "Keys": {
      "Word": 3363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "蹒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pán",
      "PrimaryDefinition": "to jump over; to limp"
    },
    "Keys": {
      "Hanzi": 6228
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6228,
      "Radical": "足",
      "FrequencyRank": 4016,
      "RadicalIndex": "157.1",
      "StrokeCount": 17
    }
  },
  "嘞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lei",
      "PrimaryDefinition": "(Cant.) final particle used for polite refusal"
    },
    "Keys": {
      "Hanzi": 5695
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5695,
      "Radical": "口",
      "FrequencyRank": 5655,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "迫害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòhài",
      "PrimaryDefinition": "to persecute, persecution"
    },
    "Keys": {
      "Word": 8567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "become cloudy, overcast"
    },
    "Keys": {
      "Hanzi": 3898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3898,
      "Radical": "日",
      "FrequencyRank": 4272,
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "猬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "vulgar; wanton; low; many; varied; a hedgehog, porcupine"
    },
    "Keys": {
      "Hanzi": 2719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2719,
      "Radical": "犬",
      "FrequencyRank": 4455,
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "沏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "infuse"
    },
    "Keys": {
      "Hanzi": 3771,
      "Word": 8587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "Grade": 7,
      "GeneralStandard": 3771,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 4487,
      "StrokeCount": 7
    }
  },
  "蝘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "gecko, kind of cicada"
    },
    "Keys": {
      "Hanzi": 7773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7773,
      "Radical": "虫",
      "FrequencyRank": 7358,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "里程碑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐchéngbēi",
      "PrimaryDefinition": "milestone"
    },
    "Keys": {
      "Word": 7981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "兆头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàotou",
      "PrimaryDefinition": "omen, portent, sign"
    },
    "Keys": {
      "Word": 10673
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔchí",
      "PrimaryDefinition": "preside, head, care of, take care of, preside over, chair, minister, sponsor, direct, uphold, direction, take charge/care of, take charge of, moderate, emcee, celebrate, manage, host, mastermind, officiate, stand for, managment, run, take charge, compere"
    },
    "Keys": {
      "Word": 2189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "锴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "high quality iron"
    },
    "Keys": {
      "Hanzi": 5734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5734,
      "Radical": "金",
      "FrequencyRank": 4916,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "濠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "moat, trench, ditch"
    },
    "Keys": {
      "Hanzi": 6296
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6296,
      "Radical": "水",
      "FrequencyRank": 5025,
      "RadicalIndex": "85.14",
      "StrokeCount": 17
    }
  },
  "相传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngchuán",
      "PrimaryDefinition": "tradition has it that..., according to legend, pass on from one to another, hand down from one to another, hand down or pass on from one to another"
    },
    "Keys": {
      "Word": 9918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎo",
      "PrimaryDefinition": "splendid, magnificent; algae"
    },
    "Keys": {
      "Hanzi": 3455
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.16",
      "GeneralStandard": 3455,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2652,
      "StrokeCount": 19
    }
  },
  "庙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miào",
      "PrimaryDefinition": "temple, shrine; imperial court"
    },
    "Keys": {
      "Hanzi": 1210,
      "Word": 8292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.5",
      "Grade": 7,
      "GeneralStandard": 1210,
      "Radical": "广",
      "HSK": 8,
      "FrequencyRank": 1889,
      "StrokeCount": 8
    }
  },
  "根": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēn",
      "PrimaryDefinition": "root, base(d on); foundation"
    },
    "Keys": {
      "Hanzi": 1850,
      "Word": 2499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 4,
      "GeneralStandard": 1850,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 352,
      "StrokeCount": 10
    }
  },
  "从没": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngméi",
      "PrimaryDefinition": "never (in the past), never did"
    },
    "Keys": {
      "Word": 4459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "溲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "urinate; soak, drench"
    },
    "Keys": {
      "Hanzi": 5285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5285,
      "Radical": "水",
      "FrequencyRank": 6084,
      "RadicalIndex": "85.1",
      "StrokeCount": 12
    }
  },
  "鹅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "goose"
    },
    "Keys": {
      "Hanzi": 2674,
      "Word": 6409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "196.7",
      "Grade": 7,
      "GeneralStandard": 2674,
      "Radical": "鳥",
      "HSK": 8,
      "FrequencyRank": 2172,
      "StrokeCount": 12
    }
  },
  "算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suàn",
      "PrimaryDefinition": "count, calculate, figure; plan"
    },
    "Keys": {
      "Hanzi": 3121,
      "Word": 998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.8",
      "Grade": 2,
      "GeneralStandard": 3121,
      "Radical": "竹",
      "HSK": 2,
      "FrequencyRank": 403,
      "StrokeCount": 14
    }
  },
  "丞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "assist, aid, rescue"
    },
    "Keys": {
      "Hanzi": 3641
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3641,
      "Radical": "一",
      "FrequencyRank": 3340,
      "RadicalIndex": "1.5",
      "StrokeCount": 6
    }
  },
  "侵权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnquán",
      "PrimaryDefinition": "to infringe the rights of, to violate, infringement"
    },
    "Keys": {
      "Word": 8718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "còu",
      "PrimaryDefinition": "piece together, assemble"
    },
    "Keys": {
      "Hanzi": 2374,
      "Word": 6066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.9",
      "Grade": 7,
      "GeneralStandard": 2374,
      "Radical": "冫",
      "HSK": 7,
      "FrequencyRank": 2193,
      "StrokeCount": 11
    }
  },
  "制成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìchéng",
      "PrimaryDefinition": "hew, make, successfully complete"
    },
    "Keys": {
      "Word": 4233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "食物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíwù",
      "PrimaryDefinition": "scran, board, ingesta, sustentation, provender, viand, chow, aliment, pabulum, edibles, prog, nurture, cheer, eatable, edible, alimentary, eatables, chowchow, nutrient, diet, bread, trencher, sustenance, nutriment, nosh, nutrition, victuals, cib., vivers, eating, toke, meat, cib, grub, peck, viands, comestible, nourishment, fare, victual, food, commons, fodder, scoff, victualage, muckamuck, ta"
    },
    "Keys": {
      "Word": 978
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "溹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7589,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "玒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6555,
      "Radical": "玉",
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "喝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hē",
      "PrimaryDefinition": "drink; shout, call out"
    },
    "Keys": {
      "Hanzi": 2639,
      "Word": 142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "Grade": 1,
      "GeneralStandard": 2639,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 983,
      "StrokeCount": 12
    }
  },
  "跑步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǎo bù",
      "PrimaryDefinition": "to run, to jog, (military) to march at the double"
    },
    "Keys": {
      "Word": 1785
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "天堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāntáng",
      "PrimaryDefinition": "paradise, heaven"
    },
    "Keys": {
      "Word": 5081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "放心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng xīn",
      "PrimaryDefinition": "disburden, set one's mind at rest, set one's mood at rest, be at ease, feel relieved, rest assured"
    },
    "Keys": {
      "Word": 660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "嘹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "used describe clarity of voice; resonant"
    },
    "Keys": {
      "Hanzi": 3239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3239,
      "Radical": "口",
      "FrequencyRank": 4643,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "骗人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piàn rén",
      "PrimaryDefinition": "to cheat sb, a scam"
    },
    "Keys": {
      "Word": 8526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "luxuriance of growth"
    },
    "Keys": {
      "Hanzi": 4766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4766,
      "Radical": "艸",
      "FrequencyRank": 5320,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "优质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuzhì",
      "PrimaryDefinition": "excellent quality"
    },
    "Keys": {
      "Word": 5269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "抓紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuā jǐn",
      "PrimaryDefinition": "to grasp firmly, to pay special attention to, to rush in, to make the most of"
    },
    "Keys": {
      "Word": 3193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "好客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàokè",
      "PrimaryDefinition": "hospitality, to treat guests well, to enjoy having guests, hospitable, friendly"
    },
    "Keys": {
      "Word": 7016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㘎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎn",
      "PrimaryDefinition": "(simplified form) the roars of a tiger"
    },
    "Keys": {
      "Hanzi": 7936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7936,
      "Radical": "口",
      "RadicalIndex": "30.15",
      "StrokeCount": 18
    }
  },
  "捋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "to pluck; to gather in the fingers; to rub; to scrape off"
    },
    "Keys": {
      "Hanzi": 4422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4422,
      "Radical": "手",
      "FrequencyRank": 4049,
      "RadicalIndex": "64.7",
      "StrokeCount": 10
    }
  },
  "狄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "tribe from northern china; surnam"
    },
    "Keys": {
      "Hanzi": 3746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3746,
      "Radical": "犬",
      "FrequencyRank": 2219,
      "RadicalIndex": "94.4",
      "StrokeCount": 7
    }
  },
  "确信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèxìn",
      "PrimaryDefinition": "to be convinced, to be sure, to firmly believe, to be positive that, definite news"
    },
    "Keys": {
      "Word": 8817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "前线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánxiàn",
      "PrimaryDefinition": "front line, military front, workface, cutting edge"
    },
    "Keys": {
      "Word": 8670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "头衔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóuxián",
      "PrimaryDefinition": "title, rank, appellation"
    },
    "Keys": {
      "Word": 9561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "to pour"
    },
    "Keys": {
      "Hanzi": 6548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6548,
      "Radical": "水",
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "玓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "pearly"
    },
    "Keys": {
      "Hanzi": 6556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6556,
      "Radical": "玉",
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "笮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "boards which support tiles on roof"
    },
    "Keys": {
      "Hanzi": 4897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4897,
      "Radical": "竹",
      "FrequencyRank": 5296,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "蝉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "cicada; continuous"
    },
    "Keys": {
      "Hanzi": 3109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3109,
      "Radical": "虫",
      "FrequencyRank": 3048,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "多媒体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōméitǐ",
      "PrimaryDefinition": "multi-media"
    },
    "Keys": {
      "Word": 4530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "好评": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎopíng",
      "PrimaryDefinition": "reputation, high opinion, favorable comment, conceit, favorable review, esteem"
    },
    "Keys": {
      "Word": 7008
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "富翁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùwēng",
      "PrimaryDefinition": "rich person, millionaire, billionaire"
    },
    "Keys": {
      "Word": 6643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分歧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnqí",
      "PrimaryDefinition": "divergent, difference (of opinion, position), disagreement, bifurcation (math.)"
    },
    "Keys": {
      "Word": 6540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "广阔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎngkuò",
      "PrimaryDefinition": "wide, vast"
    },
    "Keys": {
      "Word": 4632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "特意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèyì",
      "PrimaryDefinition": "specially, intentionally"
    },
    "Keys": {
      "Word": 5076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "窒息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìxī",
      "PrimaryDefinition": "to choke, to stifle, to suffocate"
    },
    "Keys": {
      "Word": 10802
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wā",
      "PrimaryDefinition": "a goddess, the mythological sister and successor to Fuxi"
    },
    "Keys": {
      "Hanzi": 4691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4691,
      "Radical": "女",
      "FrequencyRank": 4241,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "翻译": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fānyì",
      "PrimaryDefinition": "translate, interpret"
    },
    "Keys": {
      "Word": 2451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鲔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "kind of sturgeon, tuna"
    },
    "Keys": {
      "Hanzi": 5769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5769,
      "Radical": "魚",
      "FrequencyRank": 6069,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "推理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuīlǐ",
      "PrimaryDefinition": "reasoning, speculative, inference"
    },
    "Keys": {
      "Word": 9597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "行政": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngzhèng",
      "PrimaryDefinition": "administration"
    },
    "Keys": {
      "Word": 10047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "廛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "a store, shop; ground alloted to a retainer"
    },
    "Keys": {
      "Hanzi": 5981
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5981,
      "Radical": "广",
      "FrequencyRank": 5753,
      "RadicalIndex": "53.12",
      "StrokeCount": 15
    }
  },
  "惋惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnxī",
      "PrimaryDefinition": "to regret, to feel that it is a great pity, to feel sorry for sb"
    },
    "Keys": {
      "Word": 9660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "貘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "the panther; the tapir"
    },
    "Keys": {
      "Hanzi": 6269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6269,
      "Radical": "豸",
      "FrequencyRank": 6325,
      "RadicalIndex": "153.11",
      "StrokeCount": 17
    }
  },
  "掩盖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎngài",
      "PrimaryDefinition": "to conceal, to hide behind, to cover up"
    },
    "Keys": {
      "Word": 10189
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "买": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎi",
      "PrimaryDefinition": "buy, purchase; bribe, persuade"
    },
    "Keys": {
      "Hanzi": 588,
      "Word": 224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.3",
      "Grade": 1,
      "GeneralStandard": 588,
      "Radical": "大",
      "HSK": 1,
      "FrequencyRank": 758,
      "StrokeCount": 6
    }
  },
  "铮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "clanging sound; small gong"
    },
    "Keys": {
      "Hanzi": 4881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4881,
      "Radical": "金",
      "FrequencyRank": 3154,
      "RadicalIndex": "167.8",
      "StrokeCount": 11
    }
  },
  "压力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yālì",
      "PrimaryDefinition": "force per unit area, P, strain, overwhelming force, pressure sensation, burdensomeness, oppressiveness, pressure, onerousness, stress, compulsion, constraint, muscle, lading"
    },
    "Keys": {
      "Word": 2076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "嫫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5605
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5605,
      "Radical": "女",
      "FrequencyRank": 6350,
      "RadicalIndex": "38.11",
      "StrokeCount": 13
    }
  },
  "忆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "remember, reflect upon; memory"
    },
    "Keys": {
      "Hanzi": 167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "GeneralStandard": 167,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1333,
      "StrokeCount": 4
    }
  },
  "瘰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǒ",
      "PrimaryDefinition": "scrofula, swellings"
    },
    "Keys": {
      "Hanzi": 6154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6154,
      "Radical": "疒",
      "FrequencyRank": 6360,
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "牢固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láogù",
      "PrimaryDefinition": "firm, secure"
    },
    "Keys": {
      "Word": 7941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "廨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "government office, public office"
    },
    "Keys": {
      "Hanzi": 6152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6152,
      "Radical": "广",
      "FrequencyRank": 6276,
      "RadicalIndex": "53.13",
      "StrokeCount": 16
    }
  },
  "瓤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráng",
      "PrimaryDefinition": "flesh, core, pulp, pith"
    },
    "Keys": {
      "Hanzi": 3498
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3498,
      "Radical": "瓜",
      "FrequencyRank": 5046,
      "RadicalIndex": "97.17",
      "StrokeCount": 22
    }
  },
  "狯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "sly, cunning, crafty"
    },
    "Keys": {
      "Hanzi": 4292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4292,
      "Radical": "犬",
      "FrequencyRank": 5782,
      "RadicalIndex": "94.6",
      "StrokeCount": 9
    }
  },
  "嶒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céng",
      "PrimaryDefinition": "towering, lofty, steep"
    },
    "Keys": {
      "Hanzi": 7784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7784,
      "Radical": "山",
      "RadicalIndex": "46.12",
      "StrokeCount": 15
    }
  },
  "后备箱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòubèixiāng",
      "PrimaryDefinition": "trunk, boot (of a car)"
    },
    "Keys": {
      "Word": 7090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "颠倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāndǎo",
      "PrimaryDefinition": "to turn upside down, to reverse, back to front, confused, deranged, crazy"
    },
    "Keys": {
      "Word": 6262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大同小异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàtóng-xiǎoyì",
      "PrimaryDefinition": "virtually the same, differing only on small points"
    },
    "Keys": {
      "Word": 6135
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎng",
      "PrimaryDefinition": "clear, bright; distinct"
    },
    "Keys": {
      "Hanzi": 2109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "74.7",
      "GeneralStandard": 2109,
      "Radical": "月",
      "HSK": 5,
      "FrequencyRank": 1276,
      "StrokeCount": 10
    }
  },
  "总算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngsuàn",
      "PrimaryDefinition": "at long last, finally"
    },
    "Keys": {
      "Word": 4280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "姐妹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiěmèi",
      "PrimaryDefinition": "sisters, siblings, sister (school, city etc)"
    },
    "Keys": {
      "Word": 2628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "孙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūn",
      "PrimaryDefinition": "grandchild, descendent; surname"
    },
    "Keys": {
      "Hanzi": 570
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.3",
      "GeneralStandard": 570,
      "Radical": "子",
      "HSK": 4,
      "FrequencyRank": 995,
      "StrokeCount": 6
    }
  },
  "争气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng qì",
      "PrimaryDefinition": "to work hard for sth, to resolve on improvement, determined not to fall short"
    },
    "Keys": {
      "Word": 10719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuī",
      "PrimaryDefinition": "blow; puff; brag, boast"
    },
    "Keys": {
      "Hanzi": 744,
      "Word": 573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 2,
      "GeneralStandard": 744,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 1390,
      "StrokeCount": 7
    }
  },
  "蔸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōu",
      "PrimaryDefinition": "counter for plant"
    },
    "Keys": {
      "Hanzi": 5650
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5650,
      "Radical": "艸",
      "FrequencyRank": 7306,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "营救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngjiù",
      "PrimaryDefinition": "to rescue"
    },
    "Keys": {
      "Word": 10418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàng",
      "PrimaryDefinition": "stir-fry or boil in water or oil then cook with a sauce"
    },
    "Keys": {
      "Hanzi": 4007
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4007,
      "Radical": "火",
      "FrequencyRank": 5423,
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "欻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuā",
      "PrimaryDefinition": "sudden, abrupt, quick"
    },
    "Keys": {
      "Hanzi": 7424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7424,
      "Radical": "欠",
      "RadicalIndex": "76.8",
      "StrokeCount": 12
    }
  },
  "荁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "celery"
    },
    "Keys": {
      "Hanzi": 6784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6784,
      "Radical": "艸",
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "淑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "good, pure, virtuous, charming"
    },
    "Keys": {
      "Hanzi": 2418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2418,
      "Radical": "水",
      "FrequencyRank": 2643,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "俟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "wait for, wait until, as soon as"
    },
    "Keys": {
      "Hanzi": 4267
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4267,
      "Radical": "人",
      "FrequencyRank": 3605,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "足球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zúqiú",
      "PrimaryDefinition": "soccer, football"
    },
    "Keys": {
      "Word": 2221
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "钱财": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáncái",
      "PrimaryDefinition": "wealth, money"
    },
    "Keys": {
      "Word": 8676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráng",
      "PrimaryDefinition": "stalks of grain; lush, abundant"
    },
    "Keys": {
      "Hanzi": 6474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6474,
      "Radical": "禾",
      "FrequencyRank": 5152,
      "RadicalIndex": "115.17",
      "StrokeCount": 22
    }
  },
  "运作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnzuò",
      "PrimaryDefinition": "operate, work"
    },
    "Keys": {
      "Word": 5299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "帆船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fānchuán",
      "PrimaryDefinition": "sailboat"
    },
    "Keys": {
      "Word": 6454
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "参谋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānmóu",
      "PrimaryDefinition": "staff officer, to give advice"
    },
    "Keys": {
      "Word": 5751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōu",
      "PrimaryDefinition": "name of an ancient state; surname"
    },
    "Keys": {
      "Hanzi": 3749
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3749,
      "Radical": "邑",
      "FrequencyRank": 3139,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "艉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7551,
      "Radical": "舟",
      "FrequencyRank": 5556,
      "RadicalIndex": "137.7",
      "StrokeCount": 13
    }
  },
  "通过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngguò",
      "PrimaryDefinition": "adopt, pass, carry (motion/legislation)"
    },
    "Keys": {
      "Word": 1038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "绳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shéng",
      "PrimaryDefinition": "rope, string, cord; control"
    },
    "Keys": {
      "Hanzi": 2491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2491,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 1983,
      "StrokeCount": 11
    }
  },
  "少林寺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Shàolínsì",
      "PrimaryDefinition": "Shaolin Temple, Buddhist monastery famous for its kung fu monks"
    },
    "Keys": {
      "Word": 8999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "epilepsy, convulsions"
    },
    "Keys": {
      "Hanzi": 5258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5258,
      "Radical": "疒",
      "FrequencyRank": 4019,
      "RadicalIndex": "104.7",
      "StrokeCount": 12
    }
  },
  "参照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānzhào",
      "PrimaryDefinition": "to consult a reference, to refer to (another document)"
    },
    "Keys": {
      "Word": 5754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "river in Shansi province"
    },
    "Keys": {
      "Hanzi": 4644
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4644,
      "Radical": "水",
      "FrequencyRank": 5462,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "皇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "royal, imperial; ruler, superior"
    },
    "Keys": {
      "Hanzi": 1571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "106.4",
      "GeneralStandard": 1571,
      "Radical": "白",
      "HSK": 6,
      "FrequencyRank": 759,
      "StrokeCount": 9
    }
  },
  "牧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "tend cattle, shepherd"
    },
    "Keys": {
      "Hanzi": 1126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "93.4",
      "GeneralStandard": 1126,
      "Radical": "牛",
      "HSK": 8,
      "FrequencyRank": 1580,
      "StrokeCount": 8
    }
  },
  "芮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3666,
      "Radical": "艸",
      "FrequencyRank": 4108,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "蹀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "to skip, to dance; to put the foot down"
    },
    "Keys": {
      "Hanzi": 6091
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6091,
      "Radical": "足",
      "FrequencyRank": 6027,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "冒犯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màofàn",
      "PrimaryDefinition": "to offend"
    },
    "Keys": {
      "Word": 8213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "能不能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néng bùnéng",
      "PrimaryDefinition": "Can you"
    },
    "Keys": {
      "Word": 1764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "唤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "call"
    },
    "Keys": {
      "Hanzi": 1911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1911,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 1835,
      "StrokeCount": 10
    }
  },
  "甘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "sweet"
    },
    "Keys": {
      "Radical": 99,
      "Hanzi": 205
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "99",
      "GeneralStandard": 205,
      "Radical": "甘",
      "HSK": 9,
      "FrequencyRank": 1408,
      "StrokeCount": 5
    }
  },
  "脿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7400,
      "Radical": "肉",
      "FrequencyRank": 9480,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "降临": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànglín",
      "PrimaryDefinition": "to descend, to arrive, to come"
    },
    "Keys": {
      "Word": 7450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "bridge; beam, crosspiece"
    },
    "Keys": {
      "Hanzi": 1841,
      "Word": 1816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 3,
      "GeneralStandard": 1841,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 1292,
      "StrokeCount": 10
    }
  },
  "惠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "favor, benefit, confer kindness"
    },
    "Keys": {
      "Hanzi": 2580
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2580,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1524,
      "StrokeCount": 12
    }
  },
  "行李": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngli",
      "PrimaryDefinition": "clobber, impedimenta, dunnage, luggage, baggage"
    },
    "Keys": {
      "Word": 2054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "看好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànhǎo",
      "PrimaryDefinition": "believe something/someone will be worthy in the future"
    },
    "Keys": {
      "Word": 4790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鹌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "quail; Coturnix coturnix"
    },
    "Keys": {
      "Hanzi": 5424
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5424,
      "Radical": "鳥",
      "FrequencyRank": 4741,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "小心翼翼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoxīn-yìyì",
      "PrimaryDefinition": "with great care, cautiously"
    },
    "Keys": {
      "Word": 9976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜡烛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làzhú",
      "PrimaryDefinition": "candle"
    },
    "Keys": {
      "Word": 7913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "knot, tie; join, connect"
    },
    "Keys": {
      "Hanzi": 1754,
      "Word": 7499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "Grade": 7,
      "GeneralStandard": 1754,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 236,
      "StrokeCount": 9
    }
  },
  "猜测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāicè",
      "PrimaryDefinition": "guess, surmise"
    },
    "Keys": {
      "Word": 3288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "温度计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēndùjì",
      "PrimaryDefinition": "thermometer, thermograph"
    },
    "Keys": {
      "Word": 9725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "台上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táishàng",
      "PrimaryDefinition": "on stage"
    },
    "Keys": {
      "Word": 2916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "定价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngjià",
      "PrimaryDefinition": "to set a price, to fix a price"
    },
    "Keys": {
      "Word": 4511
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "嶓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "Boshan, mountain in Shaanxi"
    },
    "Keys": {
      "Hanzi": 7781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7781,
      "Radical": "山",
      "RadicalIndex": "46.12",
      "StrokeCount": 15
    }
  },
  "大小": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxiǎo",
      "PrimaryDefinition": "calibre, measurement, admeasurement, bulk, proportion, magnitude, extent, degree of seniority, dimension, girth, size, adults and children"
    },
    "Keys": {
      "Word": 599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "怪物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàiwu",
      "PrimaryDefinition": "monster, freak, eccentric person"
    },
    "Keys": {
      "Word": 6863
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "左右": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǒyòu",
      "PrimaryDefinition": "left and right"
    },
    "Keys": {
      "Word": 2223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "打针": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ zhēn",
      "PrimaryDefinition": "to give or have an injection"
    },
    "Keys": {
      "Word": 2366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "公民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngmín",
      "PrimaryDefinition": "freeman, citizen, denizen, national, civic, freewoman, commonwealth, burgher, civics"
    },
    "Keys": {
      "Word": 1507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "祈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "pray; entreat, beseech"
    },
    "Keys": {
      "Hanzi": 1295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113.4",
      "GeneralStandard": 1295,
      "Radical": "示",
      "HSK": 7,
      "FrequencyRank": 2443,
      "StrokeCount": 8
    }
  },
  "标榜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāobǎng",
      "PrimaryDefinition": "excessively praise, parade, glorify, advertisement, boast, boost, advertise, flaunt"
    },
    "Keys": {
      "Word": 5622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèng",
      "PrimaryDefinition": "bench; stool"
    },
    "Keys": {
      "Hanzi": 3186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "16.12",
      "GeneralStandard": 3186,
      "Radical": "几",
      "HSK": 8,
      "FrequencyRank": 2680,
      "StrokeCount": 14
    }
  },
  "对得起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìdeqǐ",
      "PrimaryDefinition": "not to let sb down, to treat sb fairly, be worthy of"
    },
    "Keys": {
      "Word": 6380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "排": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái",
      "PrimaryDefinition": "row, rank, line"
    },
    "Keys": {
      "Hanzi": 2168,
      "Word": 1779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 3,
      "GeneralStandard": 2168,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 682,
      "StrokeCount": 11
    }
  },
  "刑法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngfa",
      "PrimaryDefinition": "criminal law"
    },
    "Keys": {
      "Word": 10045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "放到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng dào",
      "PrimaryDefinition": "Put in"
    },
    "Keys": {
      "Word": 1447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "睬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎi",
      "PrimaryDefinition": "notice; pay attention to"
    },
    "Keys": {
      "Hanzi": 2901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.8",
      "GeneralStandard": 2901,
      "Radical": "目",
      "HSK": 8,
      "FrequencyRank": 3250,
      "StrokeCount": 13
    }
  },
  "培育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péiyù",
      "PrimaryDefinition": "cultivate, foster, breed"
    },
    "Keys": {
      "Word": 2778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "恍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎng",
      "PrimaryDefinition": "seemingly; absent-minded"
    },
    "Keys": {
      "Hanzi": 1687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "GeneralStandard": 1687,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 2535,
      "StrokeCount": 9
    }
  },
  "好听": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎotīng",
      "PrimaryDefinition": "pleasant to hear"
    },
    "Keys": {
      "Word": 139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "引起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnqǐ",
      "PrimaryDefinition": "elicit, beget, causation, give rise to, incite, pique, engender, bring_on, trigger, fire, compel, kick_up, superindividual, draw, spawn, invite, touch_off, occasion, lead, bring_about, procure, touch off, raise, enkindle, give, poke, produce, capture, generate, stir, arouse, excite, put forward, provoke, breed, bring, conjure up, grip, create, kindle, tempt, set_up, interest, give rise, enga"
    },
    "Keys": {
      "Word": 3103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "后备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòubèi",
      "PrimaryDefinition": "reserve, backup"
    },
    "Keys": {
      "Word": 7089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěi",
      "PrimaryDefinition": "type of yew"
    },
    "Keys": {
      "Hanzi": 5661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5661,
      "Radical": "木",
      "FrequencyRank": 5250,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "翛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "look of rumpled feathers; bedraggled; hasty; rapid flight"
    },
    "Keys": {
      "Hanzi": 7394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7394,
      "Radical": "羽",
      "FrequencyRank": 6829,
      "RadicalIndex": "124.7",
      "StrokeCount": 12
    }
  },
  "驯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "tame, docile, obedient"
    },
    "Keys": {
      "Hanzi": 592,
      "Word": 10152
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.3",
      "Grade": 7,
      "GeneralStandard": 592,
      "Radical": "馬",
      "HSK": 8,
      "FrequencyRank": 2820,
      "StrokeCount": 6
    }
  },
  "敷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "spread, diffuse; apply, paint"
    },
    "Keys": {
      "Hanzi": 3219,
      "Word": 6599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.11",
      "Grade": 7,
      "GeneralStandard": 3219,
      "Radical": "攴",
      "HSK": 8,
      "FrequencyRank": 2788,
      "StrokeCount": 15
    }
  },
  "浏览": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúlǎn",
      "PrimaryDefinition": "thumb, glance, riff, scan, riffle, leaf, riffle through, pass over, browse, dip_into, skim, skim through, run_through, flick, flip, dip into, glance over, peruse"
    },
    "Keys": {
      "Word": 8091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "角": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "horn"
    },
    "Keys": {
      "Hanzi": 814,
      "Radical": 148,
      "Word": 767
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "148",
      "Grade": 2,
      "GeneralStandard": 814,
      "Radical": "角",
      "HSK": 2,
      "FrequencyRank": 736,
      "StrokeCount": 7
    }
  },
  "燃气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ránqì",
      "PrimaryDefinition": "natural gas"
    },
    "Keys": {
      "Word": 8822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "永远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngyuǎn",
      "PrimaryDefinition": "e'er, always, for_good, evermore, ever, forever"
    },
    "Keys": {
      "Word": 1170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "薄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báo",
      "PrimaryDefinition": "thin, slight, weak; poor, stingy"
    },
    "Keys": {
      "Hanzi": 3326,
      "Word": 2259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.13",
      "Grade": 4,
      "GeneralStandard": 3326,
      "Radical": "艸",
      "HSK": 4,
      "FrequencyRank": 1433,
      "StrokeCount": 16
    }
  },
  "光芒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngmáng",
      "PrimaryDefinition": "ray_of_light, rays of light, brilliant ray, radiance"
    },
    "Keys": {
      "Word": 6904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "查处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháchǔ",
      "PrimaryDefinition": "to investigate and handle (a criminal case)"
    },
    "Keys": {
      "Word": 5789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "宿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "stop, rest, lodge, stay overnight; constellation"
    },
    "Keys": {
      "Hanzi": 2455
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.8",
      "GeneralStandard": 2455,
      "Radical": "宀",
      "HSK": 5,
      "FrequencyRank": 1752,
      "StrokeCount": 11
    }
  },
  "易拉罐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìlāguàn",
      "PrimaryDefinition": "pull-top can, easy-open can (with ring-pull)"
    },
    "Keys": {
      "Word": 10372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8047,
      "Radical": "鹿",
      "FrequencyRank": 8005,
      "RadicalIndex": "198.8",
      "StrokeCount": 19
    }
  },
  "土豆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔdòu",
      "PrimaryDefinition": "potato, CL:個|个[ge4], (Tw) peanut, CL:顆|颗[ke1]"
    },
    "Keys": {
      "Word": 3987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "髂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qià",
      "PrimaryDefinition": "the pelvis"
    },
    "Keys": {
      "Hanzi": 6343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6343,
      "Radical": "骨",
      "FrequencyRank": 5297,
      "RadicalIndex": "188.9",
      "StrokeCount": 18
    }
  },
  "内科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèikē",
      "PrimaryDefinition": "internal medicine, general medicine"
    },
    "Keys": {
      "Word": 2762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "欢迎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huānyíng",
      "PrimaryDefinition": "invite, greet, take in, receive, welcome, gratulate, embrace, salute"
    },
    "Keys": {
      "Word": 733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "一言一行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyán-yìxíng",
      "PrimaryDefinition": "every word and action (idiom)"
    },
    "Keys": {
      "Word": 10351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "试点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìdiǎn",
      "PrimaryDefinition": "test point, to carry out trial, pilot scheme"
    },
    "Keys": {
      "Word": 5026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "迥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "distant, far; separated; different"
    },
    "Keys": {
      "Hanzi": 3931
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3931,
      "Radical": "辵",
      "FrequencyRank": 3638,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "湔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "wash, cleanse; purge"
    },
    "Keys": {
      "Hanzi": 5289
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5289,
      "Radical": "水",
      "FrequencyRank": 5920,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "鹐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "to peck, as birds"
    },
    "Keys": {
      "Hanzi": 5533
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5533,
      "Radical": "",
      "FrequencyRank": 8280,
      "RadicalIndex": "",
      "StrokeCount": 13
    }
  },
  "衅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìn",
      "PrimaryDefinition": "consecrate sacrificial vessels by smearing blood; rift"
    },
    "Keys": {
      "Hanzi": 2338
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "143.5",
      "GeneralStandard": 2338,
      "Radical": "血",
      "HSK": 8,
      "FrequencyRank": 3146,
      "StrokeCount": 11
    }
  },
  "虤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7858,
      "Radical": "虍",
      "RadicalIndex": "141.1",
      "StrokeCount": 16
    }
  },
  "应邀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngyāo",
      "PrimaryDefinition": "on invitation, at sb.'s invitation, receive an invitation"
    },
    "Keys": {
      "Word": 10425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芦花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúhuā",
      "PrimaryDefinition": "Reed"
    },
    "Keys": {
      "Word": 8123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "jealous, envious"
    },
    "Keys": {
      "Hanzi": 923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.4",
      "GeneralStandard": 923,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2598,
      "StrokeCount": 7
    }
  },
  "吃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "eat; drink; suffer, endure, bear"
    },
    "Keys": {
      "Hanzi": 429,
      "Word": 45
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 1,
      "GeneralStandard": 429,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 475,
      "StrokeCount": 6
    }
  },
  "涎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "saliva"
    },
    "Keys": {
      "Hanzi": 4339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4339,
      "Radical": "水",
      "FrequencyRank": 3771,
      "RadicalIndex": "85.7",
      "StrokeCount": 9
    }
  },
  "聱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "bent and twisted; too complicated"
    },
    "Keys": {
      "Hanzi": 6034
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6034,
      "Radical": "耳",
      "FrequencyRank": 6515,
      "RadicalIndex": "128.11",
      "StrokeCount": 16
    }
  },
  "集团": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jítuán",
      "PrimaryDefinition": "population, aggregate, circle, clique, grouping, bloc, phalanx, group, knot, aggregation"
    },
    "Keys": {
      "Word": 3581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "吐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǔ",
      "PrimaryDefinition": "vomit, spew out, cough up"
    },
    "Keys": {
      "Hanzi": 421,
      "Word": 3989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 5,
      "GeneralStandard": 421,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1653,
      "StrokeCount": 6
    }
  },
  "赶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "pursue, follow; expel, drive away"
    },
    "Keys": {
      "Hanzi": 1788,
      "Word": 1472
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.3",
      "Grade": 3,
      "GeneralStandard": 1788,
      "Radical": "走",
      "HSK": 3,
      "FrequencyRank": 908,
      "StrokeCount": 10
    }
  },
  "眼睛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnjing",
      "PrimaryDefinition": "eye"
    },
    "Keys": {
      "Word": 1123
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "屐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "wooden shoes, clogs"
    },
    "Keys": {
      "Hanzi": 4679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4679,
      "Radical": "尸",
      "FrequencyRank": 4991,
      "RadicalIndex": "44.7",
      "StrokeCount": 10
    }
  },
  "脂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "fat, grease, lard; grease"
    },
    "Keys": {
      "Hanzi": 1995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.6",
      "GeneralStandard": 1995,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2206,
      "StrokeCount": 10
    }
  },
  "番茄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fānqié",
      "PrimaryDefinition": "tomato"
    },
    "Keys": {
      "Word": 4545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "亲密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnmì",
      "PrimaryDefinition": "chum, intimate, close"
    },
    "Keys": {
      "Word": 2803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "婻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7447,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "腚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "buttock"
    },
    "Keys": {
      "Hanzi": 5233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5233,
      "Radical": "肉",
      "FrequencyRank": 5672,
      "RadicalIndex": "130.8",
      "StrokeCount": 12
    }
  },
  "批评": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīpíng",
      "PrimaryDefinition": "criticize"
    },
    "Keys": {
      "Word": 1788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "驾驭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàyù",
      "PrimaryDefinition": "to urge on (of horse), to drive, to steer, to handle, to manage, to master, to dominate"
    },
    "Keys": {
      "Word": 7367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大自然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàzìrán",
      "PrimaryDefinition": "wild, earth, nature, natural state, state of nature"
    },
    "Keys": {
      "Word": 595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "娜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "elegant, graceful, delicate"
    },
    "Keys": {
      "Hanzi": 1741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1741,
      "Radical": "女",
      "FrequencyRank": 1584,
      "RadicalIndex": "38.7",
      "StrokeCount": 9
    }
  },
  "鲻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "mullet"
    },
    "Keys": {
      "Hanzi": 6146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6146,
      "Radical": "魚",
      "FrequencyRank": 6701,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "节能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié néng",
      "PrimaryDefinition": "to save energy, energy-saving"
    },
    "Keys": {
      "Word": 4730
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "此致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐzhì",
      "PrimaryDefinition": "(used at the end of a letter to introduce a polite salutation)"
    },
    "Keys": {
      "Word": 4457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "热心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèxīn",
      "PrimaryDefinition": "be enthusiastic about"
    },
    "Keys": {
      "Word": 2822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "历来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìlái",
      "PrimaryDefinition": "always, throughout (a period of time), (of) all-time"
    },
    "Keys": {
      "Word": 7998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肥沃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féiwò",
      "PrimaryDefinition": "fertile"
    },
    "Keys": {
      "Word": 6519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzhèng",
      "PrimaryDefinition": "notarization, notarized, acknowledgement"
    },
    "Keys": {
      "Word": 6782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穴位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéwèi",
      "PrimaryDefinition": "acupuncture point"
    },
    "Keys": {
      "Word": 10130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "twilight just before sunrise"
    },
    "Keys": {
      "Hanzi": 7861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7861,
      "Radical": "日",
      "RadicalIndex": "72.12",
      "StrokeCount": 16
    }
  },
  "烘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng",
      "PrimaryDefinition": "bake, roast; dry by fire"
    },
    "Keys": {
      "Hanzi": 2058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "GeneralStandard": 2058,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 3266,
      "StrokeCount": 10
    }
  },
  "关联": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānlián",
      "PrimaryDefinition": "related, linked, affiliated"
    },
    "Keys": {
      "Word": 4628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "尸体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shītǐ",
      "PrimaryDefinition": "remains, body, corps, corpus, mort, cadaver, stiff, cadaverine, deader, carcass, corpse, ashes, dust, clay, ash, dead body, corse, bones"
    },
    "Keys": {
      "Word": 9081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "快要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàiyào",
      "PrimaryDefinition": "almost, nearly, almost all"
    },
    "Keys": {
      "Word": 825
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "栖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "perch; roost; stay"
    },
    "Keys": {
      "Hanzi": 1837
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 1837,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2460,
      "StrokeCount": 10
    }
  },
  "落实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luòshí",
      "PrimaryDefinition": "fix/decide in advance, ascertain, make sure, fulfill, implement, put into effect"
    },
    "Keys": {
      "Word": 3707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "转折点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnzhédiǎn",
      "PrimaryDefinition": "turning point, breaking point"
    },
    "Keys": {
      "Word": 10897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骏马": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùnmǎ",
      "PrimaryDefinition": "fine horse, steed"
    },
    "Keys": {
      "Word": 7735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坚硬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānyìng",
      "PrimaryDefinition": "hard, solid"
    },
    "Keys": {
      "Word": 7382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "baby, infant; bother"
    },
    "Keys": {
      "Hanzi": 2301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.8",
      "GeneralStandard": 2301,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2115,
      "StrokeCount": 11
    }
  },
  "冒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "risk, brave, dare"
    },
    "Keys": {
      "Hanzi": 1486,
      "Word": 3713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "13.7",
      "Grade": 5,
      "GeneralStandard": 1486,
      "Radical": "冂",
      "HSK": 3,
      "FrequencyRank": 1222,
      "StrokeCount": 9
    }
  },
  "衣架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījià",
      "PrimaryDefinition": "clothes hanger, clothes rack"
    },
    "Keys": {
      "Word": 2088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蕾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "buds, unopened flowers"
    },
    "Keys": {
      "Hanzi": 3320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.13",
      "GeneralStandard": 3320,
      "Radical": "艸",
      "HSK": 7,
      "FrequencyRank": 3329,
      "StrokeCount": 16
    }
  },
  "括": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuò",
      "PrimaryDefinition": "include, embrace, enclose"
    },
    "Keys": {
      "Hanzi": 1383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "GeneralStandard": 1383,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 850,
      "StrokeCount": 9
    }
  },
  "焉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "thereupon, then; how? why? where?"
    },
    "Keys": {
      "Hanzi": 2169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2169,
      "Radical": "火",
      "FrequencyRank": 2526,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "细心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìxīn",
      "PrimaryDefinition": "careful, attentive"
    },
    "Keys": {
      "Word": 9846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "a wide expanse of water"
    },
    "Keys": {
      "Hanzi": 7371
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7371,
      "Radical": "水",
      "FrequencyRank": 4600,
      "RadicalIndex": "85.8",
      "StrokeCount": 12
    }
  },
  "趟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàng",
      "PrimaryDefinition": "time, occasion; take journey"
    },
    "Keys": {
      "Hanzi": 3195,
      "Word": 5069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.8",
      "Grade": 6,
      "GeneralStandard": 3195,
      "Radical": "走",
      "HSK": 6,
      "FrequencyRank": 2457,
      "StrokeCount": 15
    }
  },
  "鳟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūn",
      "PrimaryDefinition": "barbel"
    },
    "Keys": {
      "Hanzi": 6444
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6444,
      "Radical": "魚",
      "FrequencyRank": 5271,
      "RadicalIndex": "195.12",
      "StrokeCount": 20
    }
  },
  "没法儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méifǎr5",
      "PrimaryDefinition": "Can't"
    },
    "Keys": {
      "Word": 2727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "熙熙攘攘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīxī-rǎngrǎng",
      "PrimaryDefinition": "bustling with activity (idiom)"
    },
    "Keys": {
      "Word": 9824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "stuffed dumplings"
    },
    "Keys": {
      "Hanzi": 1613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.6",
      "GeneralStandard": 1613,
      "Radical": "食",
      "HSK": 2,
      "FrequencyRank": 3650,
      "StrokeCount": 9
    }
  },
  "专制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānzhì",
      "PrimaryDefinition": "autocracy, dictatorship"
    },
    "Keys": {
      "Word": 10883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说到底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōdàodǐ",
      "PrimaryDefinition": "Conclude"
    },
    "Keys": {
      "Word": 9291
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚ",
      "PrimaryDefinition": "woman"
    },
    "Keys": {
      "Hanzi": 67,
      "Radical": 38,
      "Word": 272
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38",
      "Grade": 1,
      "GeneralStandard": 67,
      "Radical": "女",
      "HSK": 1,
      "FrequencyRank": 224,
      "StrokeCount": 3
    }
  },
  "䁖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lōu",
      "PrimaryDefinition": "to see; to look at; to observe"
    },
    "Keys": {
      "Hanzi": 5693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5693,
      "Radical": "目",
      "FrequencyRank": 8946,
      "RadicalIndex": "109.9",
      "StrokeCount": 14
    }
  },
  "茝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6950,
      "Radical": "艸",
      "FrequencyRank": 7614,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "忮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "stubborn; perverse; aggressive"
    },
    "Keys": {
      "Hanzi": 6620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6620,
      "Radical": "心",
      "FrequencyRank": 6632,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "及其": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíqí",
      "PrimaryDefinition": "and, as well as"
    },
    "Keys": {
      "Word": 7286
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顺应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnyìng",
      "PrimaryDefinition": "to comply, to conform to, in tune with, adapting to, to adjust to"
    },
    "Keys": {
      "Word": 9288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "hope for; wish; Hebei province"
    },
    "Keys": {
      "Hanzi": 3340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3340,
      "Radical": "八",
      "FrequencyRank": 2550,
      "RadicalIndex": "12.14",
      "StrokeCount": 16
    }
  },
  "不用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyòng",
      "PrimaryDefinition": "need not"
    },
    "Keys": {
      "Word": 32
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "私下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīxià",
      "PrimaryDefinition": "tete-a-tete, in private/secret, in private, privately, in secret, secret"
    },
    "Keys": {
      "Word": 9311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "葆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": "reserve, preserve; conceal"
    },
    "Keys": {
      "Hanzi": 5087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5087,
      "Radical": "艸",
      "FrequencyRank": 3988,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "鸺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "horned owl, scops chinensis"
    },
    "Keys": {
      "Hanzi": 7194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7194,
      "Radical": "鳥",
      "FrequencyRank": 5975,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "车祸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēhuò",
      "PrimaryDefinition": "smash-up, road accident, traffic accident"
    },
    "Keys": {
      "Word": 5837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "evil, wrong, bad; criminal"
    },
    "Keys": {
      "Hanzi": 3156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "55.12",
      "GeneralStandard": 3156,
      "Radical": "廾",
      "HSK": 7,
      "FrequencyRank": 2348,
      "StrokeCount": 14
    }
  },
  "霅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "thunder"
    },
    "Keys": {
      "Hanzi": 7766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7766,
      "Radical": "雨",
      "FrequencyRank": 6863,
      "RadicalIndex": "173.7",
      "StrokeCount": 15
    }
  },
  "刊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kān",
      "PrimaryDefinition": "publication, periodical; publish"
    },
    "Keys": {
      "Hanzi": 191
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.3",
      "GeneralStandard": 191,
      "Radical": "刀",
      "HSK": 6,
      "FrequencyRank": 1241,
      "StrokeCount": 5
    }
  },
  "教训": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàoxun",
      "PrimaryDefinition": "lesson, moral"
    },
    "Keys": {
      "Word": 2621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "sound"
    },
    "Keys": {
      "Radical": 180,
      "Hanzi": 1632
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "180",
      "GeneralStandard": 1632,
      "Radical": "音",
      "HSK": 2,
      "FrequencyRank": 540,
      "StrokeCount": 9
    }
  },
  "心灵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnlíng",
      "PrimaryDefinition": "psychical, mind, psyche, soul, spirit, mens, heart"
    },
    "Keys": {
      "Word": 5184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "开设": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāishè",
      "PrimaryDefinition": "open (shop/etc.), offer (course/etc.)"
    },
    "Keys": {
      "Word": 4781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "瘿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐng",
      "PrimaryDefinition": "swelling, goiter"
    },
    "Keys": {
      "Hanzi": 6156
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6156,
      "Radical": "疒",
      "FrequencyRank": 5515,
      "RadicalIndex": "104.11",
      "StrokeCount": 16
    }
  },
  "不予": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyǔ",
      "PrimaryDefinition": "to withhold, to refuse"
    },
    "Keys": {
      "Word": 5720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "战": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàn",
      "PrimaryDefinition": "war, fighting, battle"
    },
    "Keys": {
      "Hanzi": 1468
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "62.5",
      "GeneralStandard": 1468,
      "Radical": "戈",
      "HSK": 4,
      "FrequencyRank": 145,
      "StrokeCount": 9
    }
  },
  "瘆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5546,
      "Radical": "疒",
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "爿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "half of a tree trunk"
    },
    "Keys": {
      "Radical": 90,
      "Hanzi": 3526
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3526,
      "Radical": "爿",
      "RadicalIndex": "90",
      "StrokeCount": 4
    }
  },
  "凳子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dèngzi",
      "PrimaryDefinition": "stool, small seat"
    },
    "Keys": {
      "Word": 6224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "磊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "pile of rocks or stones; great"
    },
    "Keys": {
      "Hanzi": 3226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.1",
      "GeneralStandard": 3226,
      "Radical": "石",
      "HSK": 9,
      "FrequencyRank": 3423,
      "StrokeCount": 15
    }
  },
  "蘼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8090,
      "Radical": "艸",
      "FrequencyRank": 5832,
      "RadicalIndex": "140.19",
      "StrokeCount": 22
    }
  },
  "鲜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "fresh; delicious; attractive"
    },
    "Keys": {
      "Hanzi": 3135,
      "Word": 3010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "195.6",
      "Grade": 4,
      "GeneralStandard": 3135,
      "Radical": "魚",
      "HSK": 4,
      "FrequencyRank": 958,
      "StrokeCount": 14
    }
  },
  "罪恶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìè",
      "PrimaryDefinition": "crime, evil, sin"
    },
    "Keys": {
      "Word": 5416
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "不亦乐乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyìlèhū",
      "PrimaryDefinition": "lit. isn't that a joy? (quote from Confucius), fig. (jocularly) extremely, awfully"
    },
    "Keys": {
      "Word": 5681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "asthma; disese of the bowels"
    },
    "Keys": {
      "Hanzi": 7718
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7718,
      "Radical": "疒",
      "FrequencyRank": 7041,
      "RadicalIndex": "104.9",
      "StrokeCount": 14
    }
  },
  "召": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "imperial decree; summon"
    },
    "Keys": {
      "Hanzi": 334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 334,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 1214,
      "StrokeCount": 5
    }
  },
  "鸧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāng",
      "PrimaryDefinition": "oriole"
    },
    "Keys": {
      "Hanzi": 6864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6864,
      "Radical": "鳥",
      "FrequencyRank": 9205,
      "RadicalIndex": "196.4",
      "StrokeCount": 9
    }
  },
  "根本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnběn",
      "PrimaryDefinition": "at all, utterly"
    },
    "Keys": {
      "Word": 1493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "唾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuò",
      "PrimaryDefinition": "spit, spit on; saliva"
    },
    "Keys": {
      "Hanzi": 2287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2287,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 3040,
      "StrokeCount": 11
    }
  },
  "砑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "to grind, to calender, to polish; to roll with a stone roller"
    },
    "Keys": {
      "Hanzi": 6810
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6810,
      "Radical": "石",
      "FrequencyRank": 6292,
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "也好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěhǎo",
      "PrimaryDefinition": "particle: showing hesitant acceptance; (in ~也好, ~也好/罷) showing the acceptance or introduction of choices"
    },
    "Keys": {
      "Word": 4095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "浅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎn",
      "PrimaryDefinition": "shallow, not deep; superficial"
    },
    "Keys": {
      "Hanzi": 1240,
      "Word": 2800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 4,
      "GeneralStandard": 1240,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 1721,
      "StrokeCount": 8
    }
  },
  "挣钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèng qián",
      "PrimaryDefinition": "to make money"
    },
    "Keys": {
      "Word": 4213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "脂肪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīfáng",
      "PrimaryDefinition": "grease, fat, axunge, adipose, fatness, avoirdupois, fattiness, adipose tissue, fatty tissue"
    },
    "Keys": {
      "Word": 10751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuī",
      "PrimaryDefinition": "sackcloth worn on breast during"
    },
    "Keys": {
      "Hanzi": 7619
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7619,
      "Radical": "糸",
      "FrequencyRank": 6560,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "简易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnyì",
      "PrimaryDefinition": "simple and easy, simply constructed, unsophisticated"
    },
    "Keys": {
      "Word": 7415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "髌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìn",
      "PrimaryDefinition": "kneecap"
    },
    "Keys": {
      "Hanzi": 6396
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6396,
      "Radical": "骨",
      "FrequencyRank": 5696,
      "RadicalIndex": "188.1",
      "StrokeCount": 19
    }
  },
  "简要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnyào",
      "PrimaryDefinition": "concise, brief"
    },
    "Keys": {
      "Word": 7414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "达标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dábiāo",
      "PrimaryDefinition": "to reach a set standard"
    },
    "Keys": {
      "Word": 6096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "析": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "split wood; break apart; divide"
    },
    "Keys": {
      "Hanzi": 1024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1024,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 1073,
      "StrokeCount": 8
    }
  },
  "顿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "pause, stop; bow, kowtow; arrange"
    },
    "Keys": {
      "Hanzi": 1877,
      "Word": 1428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.4",
      "Grade": 3,
      "GeneralStandard": 1877,
      "Radical": "頁",
      "HSK": 3,
      "FrequencyRank": 794,
      "StrokeCount": 10
    }
  },
  "兴高采烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnggāo-cǎiliè",
      "PrimaryDefinition": "happy and excited (idiom), in high spirits, in great delight"
    },
    "Keys": {
      "Word": 10053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "startled, alarmed, astonished"
    },
    "Keys": {
      "Hanzi": 2773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2773,
      "Radical": "心",
      "FrequencyRank": 2766,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "翠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "color green; kingfisher"
    },
    "Keys": {
      "Hanzi": 3184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124.8",
      "GeneralStandard": 3184,
      "Radical": "羽",
      "HSK": 9,
      "FrequencyRank": 2177,
      "StrokeCount": 14
    }
  },
  "漫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "overflow of water; spreading"
    },
    "Keys": {
      "Hanzi": 3164,
      "Word": 8199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 7,
      "GeneralStandard": 3164,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1455,
      "StrokeCount": 14
    }
  },
  "一流": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīliú",
      "PrimaryDefinition": "top quality, front ranking"
    },
    "Keys": {
      "Word": 4100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "不可思议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùkě-sīyì",
      "PrimaryDefinition": "inconceivable (idiom), unimaginable, unfathomable"
    },
    "Keys": {
      "Word": 5702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㰀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "a kind of tree"
    },
    "Keys": {
      "Hanzi": 8032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8032,
      "Radical": "木",
      "RadicalIndex": "75.15",
      "StrokeCount": 19
    }
  },
  "锈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "rust, corrode"
    },
    "Keys": {
      "Hanzi": 2663,
      "Word": 10085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "Grade": 7,
      "GeneralStandard": 2663,
      "Radical": "金",
      "HSK": 8,
      "FrequencyRank": 3366,
      "StrokeCount": 12
    }
  },
  "马车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎchē",
      "PrimaryDefinition": "cart, chariot, carriage, buggy"
    },
    "Keys": {
      "Word": 4846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "崩溃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēngkuì",
      "PrimaryDefinition": "collapse, fall apart"
    },
    "Keys": {
      "Word": 5572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "pole; shaft of spear"
    },
    "Keys": {
      "Hanzi": 678,
      "Word": 4583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "Grade": 6,
      "GeneralStandard": 678,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 2011,
      "StrokeCount": 7
    }
  },
  "薹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "cyperus rotundus, type of sedge"
    },
    "Keys": {
      "Hanzi": 6205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6205,
      "Radical": "艸",
      "FrequencyRank": 5711,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "憎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": "hate, detest, abhor; hatred"
    },
    "Keys": {
      "Hanzi": 3303
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3303,
      "Radical": "心",
      "FrequencyRank": 2617,
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "酾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāi",
      "PrimaryDefinition": "strain"
    },
    "Keys": {
      "Hanzi": 7656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7656,
      "Radical": "酉",
      "FrequencyRank": 7780,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "收买": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōumǎi",
      "PrimaryDefinition": "to purchase, to bribe"
    },
    "Keys": {
      "Word": 9166
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "民工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngōng",
      "PrimaryDefinition": "migrant worker, temporary worker enlisted on a public project"
    },
    "Keys": {
      "Word": 4865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "安排": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānpái",
      "PrimaryDefinition": "arrange, plan, fix up, provide (meals/etc.)"
    },
    "Keys": {
      "Word": 1262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "判": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàn",
      "PrimaryDefinition": "judge; discriminate; conclude"
    },
    "Keys": {
      "Hanzi": 849,
      "Word": 4895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.5",
      "Grade": 6,
      "GeneralStandard": 849,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 719,
      "StrokeCount": 7
    }
  },
  "逊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "humble, modest; yield"
    },
    "Keys": {
      "Hanzi": 1728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "GeneralStandard": 1728,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 1697,
      "StrokeCount": 9
    }
  },
  "窃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiè",
      "PrimaryDefinition": "secretly, stealthily; steal; thief"
    },
    "Keys": {
      "Hanzi": 1702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.4",
      "GeneralStandard": 1702,
      "Radical": "穴",
      "HSK": 7,
      "FrequencyRank": 2103,
      "StrokeCount": 9
    }
  },
  "影像": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐngxiàng",
      "PrimaryDefinition": "simulacrum, blip, mental image, picture, portrait, video, image"
    },
    "Keys": {
      "Word": 10421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风暴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngbào",
      "PrimaryDefinition": "tempest, roughness, firestorm, windstorm, squall, blow, tempestuousness, storm"
    },
    "Keys": {
      "Word": 4567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "眸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "pupil of eye; eye"
    },
    "Keys": {
      "Hanzi": 4816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4816,
      "Radical": "目",
      "FrequencyRank": 3368,
      "RadicalIndex": "109.6",
      "StrokeCount": 11
    }
  },
  "欢呼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huānhū",
      "PrimaryDefinition": "to cheer for, to acclaim"
    },
    "Keys": {
      "Word": 7160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "共识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngshí",
      "PrimaryDefinition": "wavelength, common understanding, consensus"
    },
    "Keys": {
      "Word": 6804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "paw"
    },
    "Keys": {
      "Hanzi": 7864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7864,
      "Radical": "足",
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "幔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màn",
      "PrimaryDefinition": "curtain, screen, tent"
    },
    "Keys": {
      "Hanzi": 5725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5725,
      "Radical": "巾",
      "FrequencyRank": 3538,
      "RadicalIndex": "50.11",
      "StrokeCount": 14
    }
  },
  "稍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "little, slightly; rather"
    },
    "Keys": {
      "Hanzi": 2676,
      "Word": 3870
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.7",
      "Grade": 5,
      "GeneralStandard": 2676,
      "Radical": "禾",
      "HSK": 5,
      "FrequencyRank": 1373,
      "StrokeCount": 12
    }
  },
  "顾不得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùbude",
      "PrimaryDefinition": "unable to change sth, unable to deal with"
    },
    "Keys": {
      "Word": 6841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "民间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínjiān",
      "PrimaryDefinition": "grass roots, people-to-people, nongovernmental, among the people, folk, popular"
    },
    "Keys": {
      "Word": 1745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "煽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "stir up, incite, agitate, provoke"
    },
    "Keys": {
      "Hanzi": 3159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.1",
      "GeneralStandard": 3159,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 3086,
      "StrokeCount": 14
    }
  },
  "重量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngliàng",
      "PrimaryDefinition": "avoirdupois weight, weight, ponderousness, heaviness, loading, heft, ponderosity, avoirdupois, heftiness, massiveness, gravity, lading"
    },
    "Keys": {
      "Word": 3188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "美中不足": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měizhōng-bùzú",
      "PrimaryDefinition": "everything is fine except for one small defect (idiom), the fly in the ointment"
    },
    "Keys": {
      "Word": 8238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "lapel, collar; tattered, threadbare"
    },
    "Keys": {
      "Hanzi": 5822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5822,
      "Radical": "衣",
      "FrequencyRank": 4250,
      "RadicalIndex": "145.9",
      "StrokeCount": 14
    }
  },
  "提到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tídào",
      "PrimaryDefinition": "mention, refer to"
    },
    "Keys": {
      "Word": 1017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "极力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jílì",
      "PrimaryDefinition": "sparing no effort, spare no effort, doing one's utmost, do one's utmost to"
    },
    "Keys": {
      "Word": 7294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "door hinge; pivot; center of power"
    },
    "Keys": {
      "Hanzi": 1021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1021,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2616,
      "StrokeCount": 8
    }
  },
  "正在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngzài",
      "PrimaryDefinition": "course of, be, be in process"
    },
    "Keys": {
      "Word": 470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "不了了之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùliǎo-liǎozhī",
      "PrimaryDefinition": "to settle a matter by leaving it unsettled, to end up with nothing definite"
    },
    "Keys": {
      "Word": 5706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可谓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěwèi",
      "PrimaryDefinition": "it could even be said"
    },
    "Keys": {
      "Word": 7801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "无能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúnéng",
      "PrimaryDefinition": "incompetent, incapable"
    },
    "Keys": {
      "Word": 9787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "持有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chíyǒu",
      "PrimaryDefinition": "boast, take, possess, hold, carry"
    },
    "Keys": {
      "Word": 4418
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "体面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐmian",
      "PrimaryDefinition": "dignity, face (as in 'losing face'), honorable, creditable, pretty"
    },
    "Keys": {
      "Word": 9463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhě",
      "PrimaryDefinition": "germanium"
    },
    "Keys": {
      "Hanzi": 5482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5482,
      "Radical": "金",
      "FrequencyRank": 5747,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "寂寞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìmò",
      "PrimaryDefinition": "be lonely"
    },
    "Keys": {
      "Word": 7343
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "停": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "stop, suspend, delay; suitable"
    },
    "Keys": {
      "Hanzi": 2333,
      "Word": 1029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "Grade": 2,
      "GeneralStandard": 2333,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 693,
      "StrokeCount": 11
    }
  },
  "獭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎ",
      "PrimaryDefinition": "otter"
    },
    "Keys": {
      "Hanzi": 6148
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6148,
      "Radical": "犬",
      "FrequencyRank": 4427,
      "RadicalIndex": "94.13",
      "StrokeCount": 16
    }
  },
  "文盲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wénmáng",
      "PrimaryDefinition": "illiterate"
    },
    "Keys": {
      "Word": 9736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngrén",
      "PrimaryDefinition": "moving, fetch, emotional, attractive, touching"
    },
    "Keys": {
      "Word": 1415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "倾诉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngsù",
      "PrimaryDefinition": "to say everything (that is on one's mind)"
    },
    "Keys": {
      "Word": 8741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "龋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ",
      "PrimaryDefinition": "tooth decay"
    },
    "Keys": {
      "Hanzi": 6220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6220,
      "Radical": "齒",
      "FrequencyRank": 3496,
      "RadicalIndex": "211.9",
      "StrokeCount": 17
    }
  },
  "背诵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèisòng",
      "PrimaryDefinition": "to recite, to repeat from memory"
    },
    "Keys": {
      "Word": 5558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "forearm"
    },
    "Keys": {
      "Hanzi": 3980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3980,
      "Radical": "肉",
      "FrequencyRank": 4921,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "结婚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié hūn",
      "PrimaryDefinition": "middle-aisle, intermarry, mate, conjoin, get married, couple, married, espouse, pair, marry, unite, get_married, get hitched with, spouse, splice, hook up with, wed"
    },
    "Keys": {
      "Word": 1619
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "眈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dān",
      "PrimaryDefinition": "gloat, stare at; to delay, to loiter, to hinder"
    },
    "Keys": {
      "Hanzi": 4194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4194,
      "Radical": "目",
      "FrequencyRank": 3979,
      "RadicalIndex": "109.4",
      "StrokeCount": 9
    }
  },
  "莱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lái",
      "PrimaryDefinition": "goosefoot, weed; fallow field"
    },
    "Keys": {
      "Hanzi": 1822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1822,
      "Radical": "艸",
      "FrequencyRank": 1196,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "幼稚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòuzhì",
      "PrimaryDefinition": "young, childish, puerile"
    },
    "Keys": {
      "Word": 10487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恭喜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngxǐ",
      "PrimaryDefinition": "congratulate"
    },
    "Keys": {
      "Word": 6801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liū",
      "PrimaryDefinition": "slide, glide, slip; slippery"
    },
    "Keys": {
      "Hanzi": 3013,
      "Word": 8090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "Grade": 7,
      "GeneralStandard": 3013,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 1923,
      "StrokeCount": 13
    }
  },
  "珹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "type of jade; pearl"
    },
    "Keys": {
      "Hanzi": 6928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6928,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 10
    }
  },
  "逊色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùnsè",
      "PrimaryDefinition": "inferior (often in the combination 毫無遜色|毫无逊色, not in the least inferior)"
    },
    "Keys": {
      "Word": 10153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "答复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dáfu",
      "PrimaryDefinition": "answer, reply"
    },
    "Keys": {
      "Word": 3370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "辇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǎn",
      "PrimaryDefinition": "hand-cart; transport by carriage"
    },
    "Keys": {
      "Hanzi": 5057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5057,
      "Radical": "車",
      "FrequencyRank": 5044,
      "RadicalIndex": "159.8",
      "StrokeCount": 12
    }
  },
  "举重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔzhòng",
      "PrimaryDefinition": "to lift weights, weight-lifting (sports)"
    },
    "Keys": {
      "Word": 7695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "联合国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Liánhéguó",
      "PrimaryDefinition": "alignment, United Nations, United_Nations, alliance, confederation, coalition, confederate, alinement, UN"
    },
    "Keys": {
      "Word": 1706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "饰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "decorate, ornament, adorn"
    },
    "Keys": {
      "Hanzi": 1201
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.5",
      "GeneralStandard": 1201,
      "Radical": "食",
      "HSK": 5,
      "FrequencyRank": 1604,
      "StrokeCount": 8
    }
  },
  "抔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "póu",
      "PrimaryDefinition": "take or hold up in both hands"
    },
    "Keys": {
      "Hanzi": 3651
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3651,
      "Radical": "手",
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "谩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "deceive, insult"
    },
    "Keys": {
      "Hanzi": 5602
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5602,
      "Radical": "言",
      "FrequencyRank": 3924,
      "RadicalIndex": "149.11",
      "StrokeCount": 13
    }
  },
  "风光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngguang",
      "PrimaryDefinition": "scene, view, sight"
    },
    "Keys": {
      "Word": 3464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "睢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": "gaze at, stare at; uninhibited"
    },
    "Keys": {
      "Hanzi": 5437
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5437,
      "Radical": "目",
      "FrequencyRank": 4326,
      "RadicalIndex": "109.8",
      "StrokeCount": 13
    }
  },
  "升温": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng wēn",
      "PrimaryDefinition": "to become hot, temperature rise, (fig.) to intensify, to hot up, to escalate, to get a boost"
    },
    "Keys": {
      "Word": 9051
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "pustules of any kind, a rash or eruption.measles; various kinds of fever"
    },
    "Keys": {
      "Hanzi": 4285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4285,
      "Radical": "肉",
      "FrequencyRank": 5498,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "否": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǒu",
      "PrimaryDefinition": "not, no, negative; final particle"
    },
    "Keys": {
      "Hanzi": 703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 703,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 620,
      "StrokeCount": 7
    }
  },
  "选修": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnxiū",
      "PrimaryDefinition": "study, read, take ... as an elective course, take as an elective, take, learn"
    },
    "Keys": {
      "Word": 4077
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pán",
      "PrimaryDefinition": "tray, plate, dish; examine"
    },
    "Keys": {
      "Hanzi": 2343,
      "Word": 8465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.6",
      "Grade": 7,
      "GeneralStandard": 2343,
      "Radical": "皿",
      "HSK": 4,
      "FrequencyRank": 1049,
      "StrokeCount": 11
    }
  },
  "抑扬顿挫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyáng-dùncuò",
      "PrimaryDefinition": "see 頓挫抑揚|顿挫抑扬[dun4 cuo4 yi4 yang2]"
    },
    "Keys": {
      "Word": 10367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": "rubidium"
    },
    "Keys": {
      "Hanzi": 4887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4887,
      "Radical": "金",
      "FrequencyRank": 6658,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "做到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò dào",
      "PrimaryDefinition": "achievement, accomplishment, achieve, accomplish"
    },
    "Keys": {
      "Word": 1257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "崩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēng",
      "PrimaryDefinition": "rupture, split apart, collapse"
    },
    "Keys": {
      "Hanzi": 2298
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.8",
      "GeneralStandard": 2298,
      "Radical": "山",
      "HSK": 7,
      "FrequencyRank": 2178,
      "StrokeCount": 11
    }
  },
  "外面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàimian",
      "PrimaryDefinition": "surface, outward appearance, without, outside, exterior"
    },
    "Keys": {
      "Word": 1998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "钛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "titanium"
    },
    "Keys": {
      "Hanzi": 4240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4240,
      "Radical": "金",
      "FrequencyRank": 4310,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "通红": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōnghóng",
      "PrimaryDefinition": "very red, red through and through, to blush (deep red)"
    },
    "Keys": {
      "Word": 5090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "名片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngpiàn",
      "PrimaryDefinition": "visiting_card, calling card, card, pasteboard, visiting card, carte, namecard, calling_card, visiting"
    },
    "Keys": {
      "Word": 2747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蚲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7165
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7165,
      "Radical": "虫",
      "RadicalIndex": "142.5",
      "StrokeCount": 11
    }
  },
  "音乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnyuè",
      "PrimaryDefinition": "music"
    },
    "Keys": {
      "Word": 1159
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "抽奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōu jiǎng",
      "PrimaryDefinition": "to draw a prize, a lottery, a raffle"
    },
    "Keys": {
      "Word": 2331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "竟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "finally, after all, at last"
    },
    "Keys": {
      "Hanzi": 2390,
      "Word": 7639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.6",
      "Grade": 7,
      "GeneralStandard": 2390,
      "Radical": "立",
      "HSK": 4,
      "FrequencyRank": 706,
      "StrokeCount": 11
    }
  },
  "购物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòuwù",
      "PrimaryDefinition": "shopping"
    },
    "Keys": {
      "Word": 2507
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "大都": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Dàdū",
      "PrimaryDefinition": "for the most part, mostly"
    },
    "Keys": {
      "Word": 3380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "转交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnjiāo",
      "PrimaryDefinition": "to pass on to sb, to carry and give to sb else"
    },
    "Keys": {
      "Word": 10890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "微博": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēibó",
      "PrimaryDefinition": "micro-blogging, microblog"
    },
    "Keys": {
      "Word": 3997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "伤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāng",
      "PrimaryDefinition": "wound, injury; fall ill from"
    },
    "Keys": {
      "Hanzi": 468,
      "Word": 1865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "Grade": 3,
      "GeneralStandard": 468,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 660,
      "StrokeCount": 6
    }
  },
  "团结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuánjié",
      "PrimaryDefinition": "unite, rally"
    },
    "Keys": {
      "Word": 1986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "桓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "variety of tree; surname"
    },
    "Keys": {
      "Hanzi": 4450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4450,
      "Radical": "木",
      "FrequencyRank": 2541,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "斝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "small jade wine cup"
    },
    "Keys": {
      "Hanzi": 7357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7357,
      "Radical": "斗",
      "RadicalIndex": "68.8",
      "StrokeCount": 12
    }
  },
  "官僚主义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānliáozhǔyì",
      "PrimaryDefinition": "bureaucracy"
    },
    "Keys": {
      "Word": 6878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "pelican"
    },
    "Keys": {
      "Hanzi": 5658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5658,
      "Radical": "鳥",
      "FrequencyRank": 5295,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "绝对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéduì",
      "PrimaryDefinition": "absolutely, definitely"
    },
    "Keys": {
      "Word": 1659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "估算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūsuàn",
      "PrimaryDefinition": "assessment, evaluation"
    },
    "Keys": {
      "Word": 6817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4689
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4689,
      "Radical": "女",
      "FrequencyRank": 5049,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "眉开眼笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méikāi-yǎnxiào",
      "PrimaryDefinition": "brows raised in delight, eyes laughing (idiom), beaming with joy, all smiles"
    },
    "Keys": {
      "Word": 8224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "earthen jug, crock, cistern"
    },
    "Keys": {
      "Hanzi": 1537,
      "Word": 6688
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "121.3",
      "Grade": 7,
      "GeneralStandard": 1537,
      "Radical": "缶",
      "HSK": 8,
      "FrequencyRank": 2817,
      "StrokeCount": 9
    }
  },
  "嵩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng",
      "PrimaryDefinition": "high, lofty; one of the 5 peaks, situated in Hunan"
    },
    "Keys": {
      "Hanzi": 5479
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5479,
      "Radical": "山",
      "FrequencyRank": 3401,
      "RadicalIndex": "46.1",
      "StrokeCount": 13
    }
  },
  "忽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "suddenly, abruptly; neglect"
    },
    "Keys": {
      "Hanzi": 1197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "GeneralStandard": 1197,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 912,
      "StrokeCount": 8
    }
  },
  "伸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "extend, stretch out, open up; trust"
    },
    "Keys": {
      "Hanzi": 775,
      "Word": 3881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 5,
      "GeneralStandard": 775,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1161,
      "StrokeCount": 7
    }
  },
  "互联网": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùliánwǎng",
      "PrimaryDefinition": "Internet"
    },
    "Keys": {
      "Word": 1553
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "扎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "pull up; pierce; struggle free"
    },
    "Keys": {
      "Hanzi": 85,
      "Word": 5311
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "Grade": 6,
      "GeneralStandard": 85,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1411,
      "StrokeCount": 4
    }
  },
  "锦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "brocade, tapestry; embroidered"
    },
    "Keys": {
      "Hanzi": 2942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.8",
      "GeneralStandard": 2942,
      "Radical": "金",
      "HSK": 9,
      "FrequencyRank": 1947,
      "StrokeCount": 13
    }
  },
  "工程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngchéng",
      "PrimaryDefinition": "process, construction, engineering, engineering project, project"
    },
    "Keys": {
      "Word": 2501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "谁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuí",
      "PrimaryDefinition": "who? whom? whose? anyone?"
    },
    "Keys": {
      "Hanzi": 2122,
      "Word": 323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "Grade": 1,
      "GeneralStandard": 2122,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 648,
      "StrokeCount": 10
    }
  },
  "反常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎncháng",
      "PrimaryDefinition": "unusual, abnormal"
    },
    "Keys": {
      "Word": 6468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "距": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2273,
      "Word": 7709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.5",
      "Grade": 7,
      "GeneralStandard": 2273,
      "Radical": "足",
      "HSK": 4,
      "FrequencyRank": 1202,
      "StrokeCount": 11
    }
  },
  "海洋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiyáng",
      "PrimaryDefinition": "briny, profound, main, deep, sea, oceanic, Neptune, brine, nautical, ocean, seas and oceans"
    },
    "Keys": {
      "Word": 4652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蚁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "ants"
    },
    "Keys": {
      "Hanzi": 1499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.3",
      "GeneralStandard": 1499,
      "Radical": "虫",
      "HSK": 6,
      "FrequencyRank": 2517,
      "StrokeCount": 9
    }
  },
  "谐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "harmonize, agree; joke, jest"
    },
    "Keys": {
      "Hanzi": 2462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.9",
      "GeneralStandard": 2462,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 2416,
      "StrokeCount": 11
    }
  },
  "繁荣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánróng",
      "PrimaryDefinition": "flourishing, prosperous, booming"
    },
    "Keys": {
      "Word": 3452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "眨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎ",
      "PrimaryDefinition": "wink"
    },
    "Keys": {
      "Hanzi": 1481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.5",
      "GeneralStandard": 1481,
      "Radical": "目",
      "HSK": 8,
      "FrequencyRank": 2371,
      "StrokeCount": 9
    }
  },
  "抑郁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìyù",
      "PrimaryDefinition": "depressed, despondent, gloomy, depression"
    },
    "Keys": {
      "Word": 10366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓝图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lántú",
      "PrimaryDefinition": "pattern, blueprint, cyanotype, design, project outline"
    },
    "Keys": {
      "Word": 7927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "far, distant, remote; slight"
    },
    "Keys": {
      "Hanzi": 6270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6270,
      "Radical": "辵",
      "FrequencyRank": 4944,
      "RadicalIndex": "162.14",
      "StrokeCount": 17
    }
  },
  "莎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "kind of sedge grass, used anciently for raincoats"
    },
    "Keys": {
      "Hanzi": 4442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4442,
      "Radical": "艸",
      "FrequencyRank": 2125,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "蚀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "nibble away; erode; eclipse"
    },
    "Keys": {
      "Hanzi": 1612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.6",
      "GeneralStandard": 1612,
      "Radical": "食",
      "HSK": 8,
      "FrequencyRank": 2625,
      "StrokeCount": 9
    }
  },
  "一年到头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnián-dàotóu",
      "PrimaryDefinition": "all year round"
    },
    "Keys": {
      "Word": 10337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔdǎo",
      "PrimaryDefinition": "leading, dominant, guiding"
    },
    "Keys": {
      "Word": 4255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "邑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "city"
    },
    "Keys": {
      "Radical": 163,
      "Hanzi": 748
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 748,
      "Radical": "邑",
      "FrequencyRank": 3132,
      "RadicalIndex": "163",
      "StrokeCount": 7
    }
  },
  "鲚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "anchovy"
    },
    "Keys": {
      "Hanzi": 5770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5770,
      "Radical": "魚",
      "FrequencyRank": 5915,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "甗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "earthenware vessel in two parts"
    },
    "Keys": {
      "Hanzi": 8056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8056,
      "Radical": "瓦",
      "RadicalIndex": "98.16",
      "StrokeCount": 20
    }
  },
  "成群结队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngqún-jiéduì",
      "PrimaryDefinition": "making up a group, forming a troupe (idiom), in large numbers, as a large crowd"
    },
    "Keys": {
      "Word": 5871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "think of, remember, miss"
    },
    "Keys": {
      "Hanzi": 2446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2446,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 3752,
      "StrokeCount": 11
    }
  },
  "蛾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "moth"
    },
    "Keys": {
      "Hanzi": 2924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2924,
      "Radical": "虫",
      "FrequencyRank": 3398,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "松绑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōng bǎng",
      "PrimaryDefinition": "to untie, (fig.) to ease restrictions"
    },
    "Keys": {
      "Word": 9332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "操控": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāokòng",
      "PrimaryDefinition": "to control, to manipulate"
    },
    "Keys": {
      "Word": 5772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎ",
      "PrimaryDefinition": "minced and salted fish; to preserve"
    },
    "Keys": {
      "Hanzi": 7558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7558,
      "Radical": "魚",
      "FrequencyRank": 7344,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "构成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu chéng",
      "PrimaryDefinition": "constitute, form, compose, make up"
    },
    "Keys": {
      "Word": 2504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "庞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páng",
      "PrimaryDefinition": "disorderly, messy; huge, big"
    },
    "Keys": {
      "Hanzi": 1207
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.5",
      "GeneralStandard": 1207,
      "Radical": "广",
      "HSK": 8,
      "FrequencyRank": 1849,
      "StrokeCount": 8
    }
  },
  "发布会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fābùhuì",
      "PrimaryDefinition": "news conference, briefing"
    },
    "Keys": {
      "Word": 6430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多劳多得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōláo-duōdé",
      "PrimaryDefinition": "work more and get more"
    },
    "Keys": {
      "Word": 6394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摸索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mōsuo",
      "PrimaryDefinition": "grope, feel about, fumble, try to find out"
    },
    "Keys": {
      "Word": 8317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "模糊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móhu",
      "PrimaryDefinition": "dim, fade, befog, blur, mix up, slur, fade out, blear, confuse, obscure"
    },
    "Keys": {
      "Word": 3731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "比分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐfēn",
      "PrimaryDefinition": "score"
    },
    "Keys": {
      "Word": 2260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "卩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "seal"
    },
    "Keys": {
      "Radical": 26
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "涴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "daub"
    },
    "Keys": {
      "Hanzi": 7240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7240,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "应付": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīngfù",
      "PrimaryDefinition": "hack, cope_with, treat, stand, bridge_over, come_on, do sth. perfunctorily, cope with, handle, cope, do ... perfunctorily, deal/cope with, cut, make do, deal, deal with, respond"
    },
    "Keys": {
      "Word": 10423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清楚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngchu",
      "PrimaryDefinition": "clear"
    },
    "Keys": {
      "Word": 932
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "笫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "bed boards, sleeping mat"
    },
    "Keys": {
      "Hanzi": 7012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7012,
      "Radical": "竹",
      "FrequencyRank": 4923,
      "RadicalIndex": "118.5",
      "StrokeCount": 10
    }
  },
  "瞄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáo",
      "PrimaryDefinition": "take aim at; look at"
    },
    "Keys": {
      "Hanzi": 2898
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.9",
      "GeneralStandard": 2898,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 2565,
      "StrokeCount": 13
    }
  },
  "专栏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānlán",
      "PrimaryDefinition": "column, special column, spot, feature, feature article, columnar"
    },
    "Keys": {
      "Word": 10880
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běn",
      "PrimaryDefinition": "benzene; luxuriant"
    },
    "Keys": {
      "Hanzi": 3855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3855,
      "Radical": "艸",
      "FrequencyRank": 3686,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "坽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6654
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6654,
      "Radical": "土",
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "档": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": "shelf; frame, crosspiece"
    },
    "Keys": {
      "Hanzi": 1838,
      "Word": 4489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 6,
      "GeneralStandard": 1838,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1787,
      "StrokeCount": 10
    }
  },
  "臾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "moment, instant, short while"
    },
    "Keys": {
      "Hanzi": 3955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3955,
      "Radical": "臼",
      "FrequencyRank": 4584,
      "RadicalIndex": "134.2",
      "StrokeCount": 8
    }
  },
  "倾向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxiàng",
      "PrimaryDefinition": "tendency, trend, inclination"
    },
    "Keys": {
      "Word": 4942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虚假": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūjiǎ",
      "PrimaryDefinition": "false, phony, pretense"
    },
    "Keys": {
      "Word": 10093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "处罚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔfá",
      "PrimaryDefinition": "punish, penalize"
    },
    "Keys": {
      "Word": 3348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "碧绿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìlǜ",
      "PrimaryDefinition": "dark green"
    },
    "Keys": {
      "Word": 5589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不妨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùfáng",
      "PrimaryDefinition": "there is no harm in, might as well"
    },
    "Keys": {
      "Word": 5693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shà",
      "PrimaryDefinition": "what?"
    },
    "Keys": {
      "Hanzi": 2290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2290,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2082,
      "StrokeCount": 11
    }
  },
  "窥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuī",
      "PrimaryDefinition": "peep, watch, spy on, pry"
    },
    "Keys": {
      "Hanzi": 3027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.8",
      "GeneralStandard": 3027,
      "Radical": "穴",
      "HSK": 8,
      "FrequencyRank": 2748,
      "StrokeCount": 13
    }
  },
  "迄今为止": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìjīnwéizhǐ",
      "PrimaryDefinition": "so far, up to now, still (not)"
    },
    "Keys": {
      "Word": 8630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "百分点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎifēndiǎn",
      "PrimaryDefinition": "percentage point"
    },
    "Keys": {
      "Word": 4295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "一家人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījiārén",
      "PrimaryDefinition": "house"
    },
    "Keys": {
      "Word": 10328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谦虚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānxū",
      "PrimaryDefinition": "make modest remarks"
    },
    "Keys": {
      "Word": 4926
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "堲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "hate"
    },
    "Keys": {
      "Hanzi": 7082
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7082,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 10
    }
  },
  "俊俏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùnqiào",
      "PrimaryDefinition": "attractive and intelligent, charming, elegant"
    },
    "Keys": {
      "Word": 7734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "feel tired, be exhausted; weak"
    },
    "Keys": {
      "Hanzi": 2032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.5",
      "GeneralStandard": 2032,
      "Radical": "疒",
      "HSK": 8,
      "FrequencyRank": 1778,
      "StrokeCount": 10
    }
  },
  "跗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "the instep"
    },
    "Keys": {
      "Hanzi": 5136
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5136,
      "Radical": "足",
      "FrequencyRank": 5450,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "情不自禁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngbùzìjīn",
      "PrimaryDefinition": "unable to restrain emotions, cannot help"
    },
    "Keys": {
      "Word": 8755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèdiǎn",
      "PrimaryDefinition": "hot spot, point of special interest"
    },
    "Keys": {
      "Word": 4966
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "豉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "fermented beans"
    },
    "Keys": {
      "Hanzi": 4784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4784,
      "Radical": "豆",
      "FrequencyRank": 6021,
      "RadicalIndex": "151.4",
      "StrokeCount": 11
    }
  },
  "藤椅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téngyǐ",
      "PrimaryDefinition": "rattan chair"
    },
    "Keys": {
      "Word": 9451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "财物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáiwù",
      "PrimaryDefinition": "property, belongings"
    },
    "Keys": {
      "Word": 5740
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nào",
      "PrimaryDefinition": "quarrel; dispute hotly"
    },
    "Keys": {
      "Hanzi": 1228,
      "Word": 2759
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169.5",
      "Grade": 4,
      "GeneralStandard": 1228,
      "Radical": "門",
      "HSK": 4,
      "FrequencyRank": 1336,
      "StrokeCount": 8
    }
  },
  "脾气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píqi",
      "PrimaryDefinition": "kidney, dispose, irritation, surliness, behavior, temper, pique, habit, mould, irritability, bile, bad temper, biliousness, temperament, characteristic, dander, pettishness, disposition, stubborn disposition, blood, snappishness, proclivity, peevishness, grain"
    },
    "Keys": {
      "Word": 3769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "书包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūbāo",
      "PrimaryDefinition": "bag, book bag, satchel, schoolbag"
    },
    "Keys": {
      "Word": 337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "柱子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùzi",
      "PrimaryDefinition": "pillar, CL:根[gen1]"
    },
    "Keys": {
      "Word": 5382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "猃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "dog"
    },
    "Keys": {
      "Hanzi": 4604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4604,
      "Radical": "犬",
      "FrequencyRank": 7439,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "出于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūyú",
      "PrimaryDefinition": "proceed from, start from, stem from, start, proceed"
    },
    "Keys": {
      "Word": 3345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "匮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "to lack"
    },
    "Keys": {
      "Hanzi": 4782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4782,
      "Radical": "匚",
      "FrequencyRank": 3727,
      "RadicalIndex": "22.9",
      "StrokeCount": 11
    }
  },
  "外国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiguó",
      "PrimaryDefinition": "foreign, foreign country, outland, outremer"
    },
    "Keys": {
      "Word": 366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "无恶不作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúè-bùzuò",
      "PrimaryDefinition": "not to shrink from any crime (idiom), to commit any imaginable misdeed"
    },
    "Keys": {
      "Word": 9763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "补充": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔchōng",
      "PrimaryDefinition": "supply, supplement, augment, replenishment, complement, eke, fill again, accompany, add, renew, replenish, implement, stock, recruit, refill"
    },
    "Keys": {
      "Word": 1318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "磁盘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cípán",
      "PrimaryDefinition": "(computer) disk"
    },
    "Keys": {
      "Word": 6045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岗位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎngwèi",
      "PrimaryDefinition": "post, station"
    },
    "Keys": {
      "Word": 4589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "贷款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàikuǎn",
      "PrimaryDefinition": "loan, credit"
    },
    "Keys": {
      "Word": 3395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "队长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìzhǎng",
      "PrimaryDefinition": "captain, chieftain, skipper, police chief, header, headman, commandant, police captain, sirdar, team leader"
    },
    "Keys": {
      "Word": 637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "分钟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnzhōng",
      "PrimaryDefinition": "m.[standard]"
    },
    "Keys": {
      "Word": 661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "看得见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàndéjiàn",
      "PrimaryDefinition": "can see, visible"
    },
    "Keys": {
      "Word": 4783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "思前想后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīqián-xiǎnghòu",
      "PrimaryDefinition": "to consider past cause and future effect (idiom), to think over the past and future, to ponder over reasons and connection"
    },
    "Keys": {
      "Word": 9317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牌子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páizi",
      "PrimaryDefinition": "sign, trademark, brand"
    },
    "Keys": {
      "Word": 1781
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "咇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bié",
      "PrimaryDefinition": "fragrant; (Cant.) to eject"
    },
    "Keys": {
      "Hanzi": 6679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6679,
      "Radical": "口",
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "面粉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànfěn",
      "PrimaryDefinition": "flour"
    },
    "Keys": {
      "Word": 8282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "残缺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cánquē",
      "PrimaryDefinition": "badly damaged, shattered"
    },
    "Keys": {
      "Word": 5756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "病床": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngchuáng",
      "PrimaryDefinition": "hospital bed, sickbed"
    },
    "Keys": {
      "Word": 5654
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "膀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎng",
      "PrimaryDefinition": "upper arm; shoulder; wing"
    },
    "Keys": {
      "Hanzi": 3134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.1",
      "GeneralStandard": 3134,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 1941,
      "StrokeCount": 14
    }
  },
  "犯规": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn guī",
      "PrimaryDefinition": "to break the rules, an illegality, a foul"
    },
    "Keys": {
      "Word": 4551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "耳熟能详": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ěrshú-néngxiáng",
      "PrimaryDefinition": "what's frequently heard can be repeated in detail (idiom)"
    },
    "Keys": {
      "Word": 6426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "cut, chop, lop off"
    },
    "Keys": {
      "Hanzi": 4168
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4168,
      "Radical": "斤",
      "FrequencyRank": 4677,
      "RadicalIndex": "69.5",
      "StrokeCount": 9
    }
  },
  "打动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎdòng",
      "PrimaryDefinition": "to move (to pity), arousing (sympathy), touching"
    },
    "Keys": {
      "Word": 4466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shà",
      "PrimaryDefinition": "speaking evil. gobbling sound made by ducks"
    },
    "Keys": {
      "Hanzi": 4846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4846,
      "Radical": "口",
      "FrequencyRank": 7021,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "揍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòu",
      "PrimaryDefinition": "hit, beat; smash, break"
    },
    "Keys": {
      "Hanzi": 2507,
      "Word": 10999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2507,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 3540,
      "StrokeCount": 12
    }
  },
  "参见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānjiàn",
      "PrimaryDefinition": "to refer to, see also, confer (cf.), to pay respect to"
    },
    "Keys": {
      "Word": 5750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衰退": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāituì",
      "PrimaryDefinition": "fail, decline"
    },
    "Keys": {
      "Word": 9245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "椑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēi",
      "PrimaryDefinition": "oval"
    },
    "Keys": {
      "Hanzi": 7325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7325,
      "Radical": "木",
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "提供": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tígōng",
      "PrimaryDefinition": "feed, offer, put_up, afford, proffer, set_up, capitalize, bring_forward, sponsor, extend, ply, provision, sustain, equip, supply, cough_up, bear, render, furnish, cater, billet, provide"
    },
    "Keys": {
      "Word": 2923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "表白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎobái",
      "PrimaryDefinition": "to explain oneself, to express, to reveal one's thoughts or feelings, declaration, confession"
    },
    "Keys": {
      "Word": 5630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "欢乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huānlè",
      "PrimaryDefinition": "happy, joyous, gay"
    },
    "Keys": {
      "Word": 1560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "壸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǔn",
      "PrimaryDefinition": "palace corridor or passageway"
    },
    "Keys": {
      "Hanzi": 7117
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7117,
      "Radical": "士",
      "RadicalIndex": "33.1",
      "StrokeCount": 11
    }
  },
  "殊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "different, special, unusual"
    },
    "Keys": {
      "Hanzi": 1872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "78.6",
      "GeneralStandard": 1872,
      "Radical": "歹",
      "HSK": 4,
      "FrequencyRank": 1191,
      "StrokeCount": 10
    }
  },
  "飘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piāo",
      "PrimaryDefinition": "whirlwind, cyclone; floating"
    },
    "Keys": {
      "Hanzi": 3221,
      "Word": 8529
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "182.11",
      "Grade": 7,
      "GeneralStandard": 3221,
      "Radical": "風",
      "HSK": 7,
      "FrequencyRank": 1527,
      "StrokeCount": 15
    }
  },
  "投资": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóuzī",
      "PrimaryDefinition": "investment"
    },
    "Keys": {
      "Word": 2950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "骅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá",
      "PrimaryDefinition": "an excellent horse"
    },
    "Keys": {
      "Hanzi": 4395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4395,
      "Radical": "馬",
      "FrequencyRank": 4803,
      "RadicalIndex": "187.6",
      "StrokeCount": 9
    }
  },
  "坚持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānchí",
      "PrimaryDefinition": "persist in, insist on"
    },
    "Keys": {
      "Word": 1595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "捍卫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hànwèi",
      "PrimaryDefinition": "to defend, to uphold, to safeguard"
    },
    "Keys": {
      "Word": 6990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7353,
      "Radical": "日",
      "RadicalIndex": "72.8",
      "StrokeCount": 12
    }
  },
  "景点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngdiǎn",
      "PrimaryDefinition": "scenic spot, place of interest (tourism)"
    },
    "Keys": {
      "Word": 4753
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "异常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìcháng",
      "PrimaryDefinition": "unusual, extraordinary, abnormal"
    },
    "Keys": {
      "Word": 5247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "绿化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜhuà",
      "PrimaryDefinition": "make (a place) green, afforest"
    },
    "Keys": {
      "Word": 4849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "情况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngkuàng",
      "PrimaryDefinition": "event, things, state of affairs, circumstantiality, case, circumstances, way, plight, experimental condition, streak, military situation, matter, condition, mood, circumstance, context, occasion, thing, instance, affair, shape, situation, juncture, consideration"
    },
    "Keys": {
      "Word": 1822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "抡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lūn",
      "PrimaryDefinition": "swing, brandish, flourish"
    },
    "Keys": {
      "Hanzi": 635,
      "Word": 8154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 635,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 3869,
      "StrokeCount": 7
    }
  },
  "滚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔn",
      "PrimaryDefinition": "turn"
    },
    "Keys": {
      "Hanzi": 3015,
      "Word": 3525
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 5,
      "GeneralStandard": 3015,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1542,
      "StrokeCount": 13
    }
  },
  "抖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒu",
      "PrimaryDefinition": "tremble, shake, rouse; give shake"
    },
    "Keys": {
      "Hanzi": 648,
      "Word": 6348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 648,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1757,
      "StrokeCount": 7
    }
  },
  "澛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7817,
      "Radical": "水",
      "RadicalIndex": "85.15",
      "StrokeCount": 15
    }
  },
  "丈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "unit of length equal 3.3 meters; gentleman, man, husband"
    },
    "Keys": {
      "Hanzi": 33
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.2",
      "GeneralStandard": 33,
      "Radical": "一",
      "HSK": 4,
      "FrequencyRank": 1242,
      "StrokeCount": 3
    }
  },
  "组长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔzhǎng",
      "PrimaryDefinition": "chief/head of group/section"
    },
    "Keys": {
      "Word": 1251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "磁带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cídài",
      "PrimaryDefinition": "magnetic tape, CL:盤|盘[pan2],盒[he2]"
    },
    "Keys": {
      "Word": 6043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "学院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéyuàn",
      "PrimaryDefinition": "seminary, college, school, hall, institute, academy, faculty"
    },
    "Keys": {
      "Word": 419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "桌子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuōzi",
      "PrimaryDefinition": "mahogany, table, desk"
    },
    "Keys": {
      "Word": 484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "骡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "mule"
    },
    "Keys": {
      "Hanzi": 3187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3187,
      "Radical": "馬",
      "FrequencyRank": 3377,
      "RadicalIndex": "187.11",
      "StrokeCount": 14
    }
  },
  "嗾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒu",
      "PrimaryDefinition": "to set a dog on; incite, instigate"
    },
    "Keys": {
      "Hanzi": 5721
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5721,
      "Radical": "口",
      "FrequencyRank": 5455,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "泷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "raining; wet; soaked; a river in Guangdong"
    },
    "Keys": {
      "Hanzi": 4011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4011,
      "Radical": "水",
      "FrequencyRank": 4977,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "往日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngrì",
      "PrimaryDefinition": "in former days"
    },
    "Keys": {
      "Word": 9676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痛快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòngkuai",
      "PrimaryDefinition": "delighted, to one's heart's content, straightforward, also pr. [tong4 kuai5]"
    },
    "Keys": {
      "Word": 2946
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "海盗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎidào",
      "PrimaryDefinition": "pirate"
    },
    "Keys": {
      "Word": 6963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "伏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "crouch, crawl, lie hidden, conceal"
    },
    "Keys": {
      "Hanzi": 460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 460,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 1389,
      "StrokeCount": 6
    }
  },
  "胜利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shènglì",
      "PrimaryDefinition": "win victory/success"
    },
    "Keys": {
      "Word": 1892
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "背着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēizhe",
      "PrimaryDefinition": "Carry on"
    },
    "Keys": {
      "Word": 4313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "生命线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngmìngxiàn",
      "PrimaryDefinition": "lifeline"
    },
    "Keys": {
      "Word": 9054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǒ",
      "PrimaryDefinition": "fruit of plant; melon"
    },
    "Keys": {
      "Hanzi": 7486
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7486,
      "Radical": "艸",
      "FrequencyRank": 7594,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "谅解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàngjiě",
      "PrimaryDefinition": "to understand, to make allowances for, understanding"
    },
    "Keys": {
      "Word": 8040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "black"
    },
    "Keys": {
      "Hanzi": 6242
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6242,
      "Radical": "黑",
      "FrequencyRank": 3518,
      "RadicalIndex": "203.5",
      "StrokeCount": 17
    }
  },
  "管道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎndào",
      "PrimaryDefinition": "pipeline, conduit, piping, channel (for communication/etc.)"
    },
    "Keys": {
      "Word": 4631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "走投无路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒutóu-wúlù",
      "PrimaryDefinition": "to be at an impasse (idiom), in a tight spot, at the end of one's rope, desperate"
    },
    "Keys": {
      "Word": 10996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "the shin; to pierce; to touch"
    },
    "Keys": {
      "Hanzi": 7770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7770,
      "Radical": "足",
      "FrequencyRank": 7474,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "驾驶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàshǐ",
      "PrimaryDefinition": "drive (vehicle), pilot (ship/plane)"
    },
    "Keys": {
      "Word": 3589
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "诀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "take leave of, bid farewell"
    },
    "Keys": {
      "Hanzi": 562
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 562,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 2845,
      "StrokeCount": 6
    }
  },
  "厉害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìhai",
      "PrimaryDefinition": "terrible, devastating, tough, sharp"
    },
    "Keys": {
      "Word": 3685
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "澡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎo",
      "PrimaryDefinition": "wash, bathe"
    },
    "Keys": {
      "Hanzi": 3378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.13",
      "GeneralStandard": 3378,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 2695,
      "StrokeCount": 16
    }
  },
  "阅览室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèlǎnshì",
      "PrimaryDefinition": "reading room, CL:間|间[jian1]"
    },
    "Keys": {
      "Word": 4162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "东北": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngběi",
      "PrimaryDefinition": "Northeast China, Manchuria"
    },
    "Keys": {
      "Word": 623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "谥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "to confer posthumous titles"
    },
    "Keys": {
      "Hanzi": 5313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5313,
      "Radical": "言",
      "FrequencyRank": 4039,
      "RadicalIndex": "149.1",
      "StrokeCount": 12
    }
  },
  "保密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo mì",
      "PrimaryDefinition": "maintain secrecy, hide, conceal, keep sth. secret, keep secret, hold_back"
    },
    "Keys": {
      "Word": 2257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "簟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "bamboo mat"
    },
    "Keys": {
      "Hanzi": 6348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6348,
      "Radical": "竹",
      "FrequencyRank": 5253,
      "RadicalIndex": "118.12",
      "StrokeCount": 18
    }
  },
  "缤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "flourishing, thriving, abundant"
    },
    "Keys": {
      "Hanzi": 3047
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.1",
      "GeneralStandard": 3047,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 3795,
      "StrokeCount": 13
    }
  },
  "处分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔfèn",
      "PrimaryDefinition": "punishment"
    },
    "Keys": {
      "Word": 3350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "水涨船高": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐzhǎng-chuángāo",
      "PrimaryDefinition": "the tide rises, the boat floats (idiom), fig. to change with the overall trend, to develop according to the situation"
    },
    "Keys": {
      "Word": 9271
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "completed, finished, fixed"
    },
    "Keys": {
      "Hanzi": 401,
      "Word": 4416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.3",
      "Grade": 6,
      "GeneralStandard": 401,
      "Radical": "戈",
      "HSK": 2,
      "FrequencyRank": 59,
      "StrokeCount": 6
    }
  },
  "往来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnglái",
      "PrimaryDefinition": "contact, intercourse"
    },
    "Keys": {
      "Word": 5138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "茯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "china root; medicinal fungus"
    },
    "Keys": {
      "Hanzi": 4119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4119,
      "Radical": "艸",
      "FrequencyRank": 4744,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "黜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "dismiss; demote, downgrade"
    },
    "Keys": {
      "Hanzi": 6241
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6241,
      "Radical": "黑",
      "FrequencyRank": 4159,
      "RadicalIndex": "203.5",
      "StrokeCount": 17
    }
  },
  "轧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "crush by weight; grind"
    },
    "Keys": {
      "Hanzi": 224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 224,
      "Radical": "車",
      "FrequencyRank": 3269,
      "RadicalIndex": "159.1",
      "StrokeCount": 5
    }
  },
  "宝贵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoguì",
      "PrimaryDefinition": "valuable, precious"
    },
    "Keys": {
      "Word": 2251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "osprey, fishhawk"
    },
    "Keys": {
      "Hanzi": 5717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5717,
      "Radical": "鳥",
      "FrequencyRank": 4678,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "吓唬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàhu",
      "PrimaryDefinition": "to scare, to frighten"
    },
    "Keys": {
      "Word": 9877
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4282
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4282,
      "Radical": "肉",
      "FrequencyRank": 5607,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "姚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "handsome, elegant; surname"
    },
    "Keys": {
      "Hanzi": 1740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1740,
      "Radical": "女",
      "FrequencyRank": 2308,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "原本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánběn",
      "PrimaryDefinition": "originally, formerly"
    },
    "Keys": {
      "Word": 10538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoyì",
      "PrimaryDefinition": "good intention, kindness"
    },
    "Keys": {
      "Word": 7013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǒng",
      "PrimaryDefinition": "bright, brilliant, clear; hot"
    },
    "Keys": {
      "Hanzi": 4327
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4327,
      "Radical": "火",
      "FrequencyRank": 2933,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "teach, class"
    },
    "Keys": {
      "Hanzi": 2179,
      "Word": 169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.7",
      "Grade": 1,
      "GeneralStandard": 2179,
      "Radical": "攴",
      "HSK": 1,
      "FrequencyRank": 191,
      "StrokeCount": 11
    }
  },
  "焱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "flames"
    },
    "Keys": {
      "Hanzi": 5275
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5275,
      "Radical": "火",
      "FrequencyRank": 5359,
      "RadicalIndex": "86.8",
      "StrokeCount": 12
    }
  },
  "缌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "coarse cotton cloth used for mourning"
    },
    "Keys": {
      "Hanzi": 5334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5334,
      "Radical": "糸",
      "FrequencyRank": 6115,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "客": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "guest, traveller; customer"
    },
    "Keys": {
      "Hanzi": 1703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.6",
      "GeneralStandard": 1703,
      "Radical": "宀",
      "HSK": 1,
      "FrequencyRank": 583,
      "StrokeCount": 9
    }
  },
  "机舱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīcāng",
      "PrimaryDefinition": "cabin of a plane"
    },
    "Keys": {
      "Word": 7264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráng",
      "PrimaryDefinition": "pray or sacrifice, exorcise"
    },
    "Keys": {
      "Hanzi": 6460
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6460,
      "Radical": "示",
      "FrequencyRank": 5310,
      "RadicalIndex": "113.17",
      "StrokeCount": 21
    }
  },
  "牢记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láojì",
      "PrimaryDefinition": "to keep in mind, to remember"
    },
    "Keys": {
      "Word": 7944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "独立自主": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dúlì-zìzhǔ",
      "PrimaryDefinition": "independent and autonomous (idiom), self-determination, to act independently, to maintain control over one's own affairs"
    },
    "Keys": {
      "Word": 6359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "companion; associate with"
    },
    "Keys": {
      "Hanzi": 1146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1146,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 2661,
      "StrokeCount": 8
    }
  },
  "重型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngxíng",
      "PrimaryDefinition": "heavy, heavy duty, large caliber"
    },
    "Keys": {
      "Word": 10834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhí",
      "PrimaryDefinition": "nephew"
    },
    "Keys": {
      "Hanzi": 1144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1144,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 2664,
      "StrokeCount": 8
    }
  },
  "解开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiě kāi",
      "PrimaryDefinition": "unknot, loose, uncouple, unbuckle, unyoke, reel off, disengage, unreel, unloosen, disentwine, detach, untie, unwreathe, unlock, ravel, disentangle, unbraid, unsnarl, unhook, unfasten, unwind, unroll, tease apart, loosen, unhitch, unlace, undone, tease, unwire, uncord, unwrap, unleash, unthread, undo, disassemble, ravel out, disconnect, unlink, sleave, untangle, unpack, puzzle_out, unbend, u"
    },
    "Keys": {
      "Word": 1623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "翻来覆去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fānlái-fùqù",
      "PrimaryDefinition": "to toss and turn (sleeplessly), again and again"
    },
    "Keys": {
      "Word": 6456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纪念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìniàn",
      "PrimaryDefinition": "commemorate, mark"
    },
    "Keys": {
      "Word": 1581
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "瑕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiá",
      "PrimaryDefinition": "flaw in gem; fault, defect"
    },
    "Keys": {
      "Hanzi": 5345
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5345,
      "Radical": "玉",
      "FrequencyRank": 3842,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "秋季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiūjì",
      "PrimaryDefinition": "autumn, fall"
    },
    "Keys": {
      "Word": 2814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "胤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìn",
      "PrimaryDefinition": "heir, successor; progeny, posterity"
    },
    "Keys": {
      "Hanzi": 4302
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4302,
      "Radical": "肉",
      "FrequencyRank": 3982,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "眉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": "eyebrows; upper margin of book"
    },
    "Keys": {
      "Hanzi": 1729
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "GeneralStandard": 1729,
      "Radical": "目",
      "HSK": 8,
      "FrequencyRank": 1460,
      "StrokeCount": 9
    }
  },
  "剩余": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèngyú",
      "PrimaryDefinition": "remainder, surplus"
    },
    "Keys": {
      "Word": 9078
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "决定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juédìng",
      "PrimaryDefinition": "decide, resolve, make up one's mind, determine"
    },
    "Keys": {
      "Word": 1656
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "分散": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnsàn",
      "PrimaryDefinition": "divert, diversify, diffract, disperse, deconcentrate, decentralize, dispel, disgregate, decentralization, scatter, distract, decentralise, shed, break up, dispersal"
    },
    "Keys": {
      "Word": 2469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "领土": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngtǔ",
      "PrimaryDefinition": "demesne, country, nation, realm, soli, landgrave, princedom, territory, emirate, domain, state, seigneury, estate, seignory, territoriality, acres, possession, signory, territorial, province, landed estate, dominion, land"
    },
    "Keys": {
      "Word": 8083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "西红柿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīhóngshì",
      "PrimaryDefinition": "tomato, CL:隻|只[zhi1]"
    },
    "Keys": {
      "Word": 4024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "从前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngqián",
      "PrimaryDefinition": "previously, formerly, once upon a time"
    },
    "Keys": {
      "Word": 1374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "出访": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūfǎng",
      "PrimaryDefinition": "to travel on business, to visit (a foreign country), same as 外出訪問|外出访问[wai4 chu1 fang3 wen4]"
    },
    "Keys": {
      "Word": 4426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "高昂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoáng",
      "PrimaryDefinition": "held high (head/etc.), high, elated, exalted"
    },
    "Keys": {
      "Word": 6691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "平均": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngjūn",
      "PrimaryDefinition": "average"
    },
    "Keys": {
      "Word": 2782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "加强": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāqiáng",
      "PrimaryDefinition": "strengthen, augment, reinforce"
    },
    "Keys": {
      "Word": 1585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "业务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèwù",
      "PrimaryDefinition": "service, practice, vocational work, line of work, affair, line, occupation, professional work, business, transaction"
    },
    "Keys": {
      "Word": 4097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "花生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāshēng",
      "PrimaryDefinition": "peanut, groundnut, CL:粒[li4]"
    },
    "Keys": {
      "Word": 4674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "零花钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línghuāqián",
      "PrimaryDefinition": "pocket money, allowance"
    },
    "Keys": {
      "Word": 8072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "职务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíwù",
      "PrimaryDefinition": "engagement, job, part, office, line of duty, contribution, ministration, task, responsibility, commission, post, line of work, affair, hat, function, situation, occupation, line, province, share, duties, business, duty"
    },
    "Keys": {
      "Word": 4229
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "立场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìchǎng",
      "PrimaryDefinition": "view, stance, language, stand, ground, capacity, standing, situation, position, perspective, standpoint, stand(point)"
    },
    "Keys": {
      "Word": 3686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "不一会儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù yīhuǐr5",
      "PrimaryDefinition": "Take a short while"
    },
    "Keys": {
      "Word": 540
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "邕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "former or literary name for Nanning (in Guangxi)"
    },
    "Keys": {
      "Hanzi": 4707
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4707,
      "Radical": "邑",
      "FrequencyRank": 4695,
      "RadicalIndex": "163.3",
      "StrokeCount": 10
    }
  },
  "电动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàndòng",
      "PrimaryDefinition": "electric"
    },
    "Keys": {
      "Word": 4504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "迈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mài",
      "PrimaryDefinition": "take a big stride; pass by"
    },
    "Keys": {
      "Hanzi": 408,
      "Word": 8189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.3",
      "Grade": 7,
      "GeneralStandard": 408,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 1698,
      "StrokeCount": 6
    }
  },
  "可不是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěbushì",
      "PrimaryDefinition": "that's just the way it is, exactly!"
    },
    "Keys": {
      "Word": 7794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "hoof; leg of pork; little witch"
    },
    "Keys": {
      "Hanzi": 3344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3344,
      "Radical": "足",
      "FrequencyRank": 2648,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "形象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngxiàng",
      "PrimaryDefinition": "imagery, figure, form, picture, persona, image"
    },
    "Keys": {
      "Word": 2058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鸱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chī",
      "PrimaryDefinition": "kite, horned owl; wine cups"
    },
    "Keys": {
      "Hanzi": 4598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4598,
      "Radical": "鳥",
      "FrequencyRank": 5542,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "对话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìhuà",
      "PrimaryDefinition": "dialogue"
    },
    "Keys": {
      "Word": 639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "链": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "chain, wire, cable; chain, shack"
    },
    "Keys": {
      "Hanzi": 2658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "GeneralStandard": 2658,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2099,
      "StrokeCount": 12
    }
  },
  "殷勤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnqín",
      "PrimaryDefinition": "politely, solicitously, eagerly attentive"
    },
    "Keys": {
      "Word": 10385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēng",
      "PrimaryDefinition": "plow, cultivate"
    },
    "Keys": {
      "Hanzi": 1766
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "127.4",
      "GeneralStandard": 1766,
      "Radical": "耒",
      "HSK": 9,
      "FrequencyRank": 1850,
      "StrokeCount": 10
    }
  },
  "售": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "sell"
    },
    "Keys": {
      "Hanzi": 2332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2332,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 1114,
      "StrokeCount": 11
    }
  },
  "宫廷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngtíng",
      "PrimaryDefinition": "royal, royal/imperial court, cupbearer, court, royal or imperial court, royal court, imperial court, palace"
    },
    "Keys": {
      "Word": 6799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "早日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎorì",
      "PrimaryDefinition": "at an early date, early, soon"
    },
    "Keys": {
      "Word": 10608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大街": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjiē",
      "PrimaryDefinition": "street, main street, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 4474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "届": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "numerary adjunct for time, term"
    },
    "Keys": {
      "Hanzi": 1309,
      "Word": 3620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.5",
      "Grade": 5,
      "GeneralStandard": 1309,
      "Radical": "尸",
      "HSK": 5,
      "FrequencyRank": 1522,
      "StrokeCount": 8
    }
  },
  "地震": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìzhèn",
      "PrimaryDefinition": "quake"
    },
    "Keys": {
      "Word": 3417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "餐饮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānyǐn",
      "PrimaryDefinition": "catering, foods, table"
    },
    "Keys": {
      "Word": 3299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "泫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "weep; cry; shine, glisten"
    },
    "Keys": {
      "Hanzi": 4019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4019,
      "Radical": "水",
      "FrequencyRank": 5843,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "动机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngjī",
      "PrimaryDefinition": "motif, need, reason, instance, ground, incentive, signal, intention, motive, motivation, inducement, cause"
    },
    "Keys": {
      "Word": 3427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "裰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duō",
      "PrimaryDefinition": "to mend clothes"
    },
    "Keys": {
      "Hanzi": 5600
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5600,
      "Radical": "衣",
      "FrequencyRank": 5251,
      "RadicalIndex": "145.8",
      "StrokeCount": 13
    }
  },
  "秀美": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiùměi",
      "PrimaryDefinition": "elegant, graceful"
    },
    "Keys": {
      "Word": 10083
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光泽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngzé",
      "PrimaryDefinition": "Guangze county in Nanping 南平[Nan2 ping2] Fujian, luster, gloss"
    },
    "Keys": {
      "Word": 6906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茂密": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màomì",
      "PrimaryDefinition": "dense (of plant growth), lush"
    },
    "Keys": {
      "Word": 8210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "避难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì nàn",
      "PrimaryDefinition": "refuge, to take refuge, to seek asylum (political etc)"
    },
    "Keys": {
      "Word": 5592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "暴露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàolù",
      "PrimaryDefinition": "unmask, divulge, expose, develop, bewray, unhood, unhusk, uncover, lay bare, show_up, reveal, flash, uncase, debunk, discover, unkennel, revelation, betray, give_away, unveil"
    },
    "Keys": {
      "Word": 4305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "时时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíshí",
      "PrimaryDefinition": "always, constantly, often"
    },
    "Keys": {
      "Word": 5018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "氓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máng",
      "PrimaryDefinition": "people; subjects; vassals"
    },
    "Keys": {
      "Hanzi": 1226
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "83.4",
      "GeneralStandard": 1226,
      "Radical": "氏",
      "HSK": 7,
      "FrequencyRank": 3060,
      "StrokeCount": 8
    }
  },
  "瀔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "river name in Henan province"
    },
    "Keys": {
      "Hanzi": 8025
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8025,
      "Radical": "水",
      "RadicalIndex": "85.15",
      "StrokeCount": 18
    }
  },
  "皓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "bright, luminous; clear; hoary"
    },
    "Keys": {
      "Hanzi": 2698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2698,
      "Radical": "白",
      "FrequencyRank": 3053,
      "RadicalIndex": "106.7",
      "StrokeCount": 12
    }
  },
  "耰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "toothless rake for drawing earth over newly sown grain"
    },
    "Keys": {
      "Hanzi": 8074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8074,
      "Radical": "耒",
      "FrequencyRank": 8409,
      "RadicalIndex": "127.15",
      "StrokeCount": 21
    }
  },
  "明显": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngxiǎn",
      "PrimaryDefinition": "clear, obvious"
    },
    "Keys": {
      "Word": 1750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "狴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "a kind of tapir; a fierce beast depicted on the door of prisons"
    },
    "Keys": {
      "Hanzi": 7035
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7035,
      "Radical": "犬",
      "FrequencyRank": 6575,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "缴纳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎonà",
      "PrimaryDefinition": "to pay (taxes etc)"
    },
    "Keys": {
      "Word": 7486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "程序": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngxù",
      "PrimaryDefinition": "rules of order, parliamentary law, instruction, process, procedure, agenda, instance, parliamentary procedure, system, order, proceeding, programme, method, sequence, course, program"
    },
    "Keys": {
      "Word": 2318
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "粹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "pure; unadulterated; select"
    },
    "Keys": {
      "Hanzi": 3154
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "119.8",
      "GeneralStandard": 3154,
      "Radical": "米",
      "HSK": 7,
      "FrequencyRank": 1736,
      "StrokeCount": 14
    }
  },
  "龈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěn",
      "PrimaryDefinition": "gums"
    },
    "Keys": {
      "Hanzi": 5691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5691,
      "Radical": "齒",
      "FrequencyRank": 3997,
      "RadicalIndex": "211.6",
      "StrokeCount": 14
    }
  },
  "绵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mián",
      "PrimaryDefinition": "cotton wad; wool; soft, downy"
    },
    "Keys": {
      "Hanzi": 2493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2493,
      "Radical": "糸",
      "HSK": 8,
      "FrequencyRank": 2186,
      "StrokeCount": 11
    }
  },
  "础": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": "foundation stone, plinth"
    },
    "Keys": {
      "Hanzi": 1866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.5",
      "GeneralStandard": 1866,
      "Radical": "石",
      "HSK": 3,
      "FrequencyRank": 1014,
      "StrokeCount": 10
    }
  },
  "取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ",
      "PrimaryDefinition": "take, receive, obtain; select"
    },
    "Keys": {
      "Hanzi": 997,
      "Word": 940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.6",
      "Grade": 2,
      "GeneralStandard": 997,
      "Radical": "又",
      "HSK": 2,
      "FrequencyRank": 323,
      "StrokeCount": 8
    }
  },
  "钕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚ",
      "PrimaryDefinition": "neodymium"
    },
    "Keys": {
      "Hanzi": 3943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3943,
      "Radical": "金",
      "FrequencyRank": 5604,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "务必": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùbì",
      "PrimaryDefinition": "by_any_means, always, must, should, be"
    },
    "Keys": {
      "Word": 9805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乳制品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǔzhìpǐn",
      "PrimaryDefinition": "dairy products"
    },
    "Keys": {
      "Word": 4979
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "规": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "rules, regulations, customs, law"
    },
    "Keys": {
      "Hanzi": 952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "147.4",
      "GeneralStandard": 952,
      "Radical": "見",
      "HSK": 3,
      "FrequencyRank": 321,
      "StrokeCount": 8
    }
  },
  "夸夸其谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuākuā-qítán",
      "PrimaryDefinition": "to talk big, to sound off, bombastic, grandiloquent"
    },
    "Keys": {
      "Word": 7869
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "算计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suànji",
      "PrimaryDefinition": "to reckon, to calculate, to plan, to expect, to scheme"
    },
    "Keys": {
      "Word": 9358
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自始至终": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìshǐ-zhìzhōng",
      "PrimaryDefinition": "from start to finish (idiom)"
    },
    "Keys": {
      "Word": 10962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "髋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuān",
      "PrimaryDefinition": "hipbone; hip"
    },
    "Keys": {
      "Hanzi": 6395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6395,
      "Radical": "骨",
      "FrequencyRank": 5086,
      "RadicalIndex": "188.1",
      "StrokeCount": 19
    }
  },
  "鸵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "ostrich"
    },
    "Keys": {
      "Hanzi": 2008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2008,
      "Radical": "鳥",
      "FrequencyRank": 4322,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "怵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "fear, be afraid; shy, timid"
    },
    "Keys": {
      "Hanzi": 4026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4026,
      "Radical": "心",
      "FrequencyRank": 4588,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "筋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "muscles; tendons"
    },
    "Keys": {
      "Hanzi": 2688,
      "Word": 7563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 7,
      "GeneralStandard": 2688,
      "Radical": "竹",
      "HSK": 8,
      "FrequencyRank": 2252,
      "StrokeCount": 12
    }
  },
  "拒绝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùjué",
      "PrimaryDefinition": "deny, rebut, bar, repel, naysay, scout, abnegate, overrule, reject, decline, disclaim, eliminate, refusal, deduct, turn away, snub, shut, thumb, debar, abjure, override, exclude, recant, turndown, withhold, renunciate, retract, repudiate, forswear, turn_down, turn down, nill, kick, brush_off, repulse, rule out, brush off, recoup, pass up, rebuff, rejection, renounce, refuse, nix, foreclose"
    },
    "Keys": {
      "Word": 3638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "隔离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gélí",
      "PrimaryDefinition": "keep apart, segregate, isolate"
    },
    "Keys": {
      "Word": 6735
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "够": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "enough, adequate"
    },
    "Keys": {
      "Hanzi": 2362,
      "Word": 692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "36.8",
      "Grade": 2,
      "GeneralStandard": 2362,
      "Radical": "夕",
      "HSK": 2,
      "FrequencyRank": 538,
      "StrokeCount": 11
    }
  },
  "涯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2414,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2604,
      "StrokeCount": 11
    }
  },
  "颃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háng",
      "PrimaryDefinition": "fly down or downward"
    },
    "Keys": {
      "Hanzi": 4624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4624,
      "Radical": "頁",
      "FrequencyRank": 6703,
      "RadicalIndex": "181.4",
      "StrokeCount": 10
    }
  },
  "弥漫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mímàn",
      "PrimaryDefinition": "to pervade, to fill the air, diffuse, everywhere present, about to inundate (water), permeated by (smoke), filled with (dust), to saturate (the air with fog, smoke etc), variant of 彌漫|弥漫[mi2 man4]"
    },
    "Keys": {
      "Word": 8254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "攻击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjī",
      "PrimaryDefinition": "attack, assault, accuse, vilify"
    },
    "Keys": {
      "Word": 4610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "雇佣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùyōng",
      "PrimaryDefinition": "to employ, to hire"
    },
    "Keys": {
      "Word": 6847
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuō",
      "PrimaryDefinition": "luster of gem; lustrous, bright"
    },
    "Keys": {
      "Hanzi": 7471
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7471,
      "Radical": "玉",
      "RadicalIndex": "96.1",
      "StrokeCount": 13
    }
  },
  "邮票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóupiào",
      "PrimaryDefinition": "postage_stamp, album, label, stamp, postage, postage stamp, stickies"
    },
    "Keys": {
      "Word": 2109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "妤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "beautiful, fair, handsome"
    },
    "Keys": {
      "Hanzi": 3815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3815,
      "Radical": "女",
      "FrequencyRank": 6893,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "铺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pù",
      "PrimaryDefinition": "shop, store; place sleep, bed"
    },
    "Keys": {
      "Hanzi": 2657,
      "Word": 4915
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "Grade": 6,
      "GeneralStandard": 2657,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 1613,
      "StrokeCount": 12
    }
  },
  "服装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúzhuāng",
      "PrimaryDefinition": "turnout, wearing_apparel, clothing, investiture, rigging, costuming, toggery, habilimentation, habiliment, drapery, garb, attire, toilette, frock, costume, dress, investment, garmenture, rig, garment, setout, implement, garniture, getup, outfit"
    },
    "Keys": {
      "Word": 1459
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "可疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěyí",
      "PrimaryDefinition": "suspicious, dubious"
    },
    "Keys": {
      "Word": 7807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànxiàn",
      "PrimaryDefinition": "wire, power cord, CL:根[gen1]"
    },
    "Keys": {
      "Word": 6273
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cèng",
      "PrimaryDefinition": "to shuffle, procrastinate, dilly-dally"
    },
    "Keys": {
      "Hanzi": 3459,
      "Word": 5781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.12",
      "Grade": 7,
      "GeneralStandard": 3459,
      "Radical": "足",
      "HSK": 8,
      "FrequencyRank": 3707,
      "StrokeCount": 19
    }
  },
  "四季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìjì",
      "PrimaryDefinition": "four seasons, namely: spring 春, summer 夏, autumn 秋 and winter 冬"
    },
    "Keys": {
      "Word": 9327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngpíng",
      "PrimaryDefinition": "fair, just, impartial"
    },
    "Keys": {
      "Word": 686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "海底": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎidǐ",
      "PrimaryDefinition": "seabed, seafloor, bottom of the ocean"
    },
    "Keys": {
      "Word": 4650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "恙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàng",
      "PrimaryDefinition": "illness, sickness; indisposition"
    },
    "Keys": {
      "Hanzi": 4633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4633,
      "Radical": "心",
      "FrequencyRank": 3728,
      "RadicalIndex": "61.6",
      "StrokeCount": 10
    }
  },
  "娑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": "dance, frolic; lounge; saunter"
    },
    "Keys": {
      "Hanzi": 4648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4648,
      "Radical": "女",
      "FrequencyRank": 4335,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "屃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "gigantic strength; hercules"
    },
    "Keys": {
      "Hanzi": 6629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6629,
      "Radical": "尸",
      "RadicalIndex": "44.4",
      "StrokeCount": 7
    }
  },
  "精神": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngshen",
      "PrimaryDefinition": "god, guts, heart, pneuma, consciousness, vigor, life, energy, vim, courage, esprit, moral, get-up-and-go, ginger, vitality, Psyche, psyche, spunk, gumption, juice, sand, mind, mental, mens, mickey, mettle, nature, grit, oomph, genius, vigour, substance, psychic, backbone, nous, nerve, pazazz, essence, pep, spirit, inward, drive, push, soul, gist"
    },
    "Keys": {
      "Word": 1641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "共计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngjì",
      "PrimaryDefinition": "to sum up to, to total"
    },
    "Keys": {
      "Word": 3504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "暑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "hot"
    },
    "Keys": {
      "Hanzi": 2613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "GeneralStandard": 2613,
      "Radical": "日",
      "HSK": 4,
      "FrequencyRank": 2743,
      "StrokeCount": 12
    }
  },
  "障碍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàngài",
      "PrimaryDefinition": "discouragement, bottleneck, clog, bar, encumbrance, embarrassment, malfunction, jam-up, baffle, impediment, obstruction, rub, roadblock, stay, rubber, disturbance, balk, holdback, bunker, stymy, remora, obstacle, barrier, stumbling_block, hitch, handicap, lesion, hedge, snag, drawback, let, wall, crab, difficulty, penalty, hang-up, facer, hindrance, hurdle"
    },
    "Keys": {
      "Word": 5331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "楮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": "mulberry; paper"
    },
    "Keys": {
      "Hanzi": 5094
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5094,
      "Radical": "木",
      "FrequencyRank": 5994,
      "RadicalIndex": "75.9",
      "StrokeCount": 12
    }
  },
  "核武器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héwǔqì",
      "PrimaryDefinition": "nuclear weapon"
    },
    "Keys": {
      "Word": 7052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "訚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "speak gently; respectful"
    },
    "Keys": {
      "Hanzi": 4631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4631,
      "Radical": "言",
      "FrequencyRank": 7233,
      "RadicalIndex": "149.3",
      "StrokeCount": 10
    }
  },
  "髢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dí",
      "PrimaryDefinition": "wig"
    },
    "Keys": {
      "Hanzi": 7476
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7476,
      "Radical": "髟",
      "RadicalIndex": "190.3",
      "StrokeCount": 13
    }
  },
  "霈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèi",
      "PrimaryDefinition": "torrential rains, flow of water"
    },
    "Keys": {
      "Hanzi": 5897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5897,
      "Radical": "雨",
      "FrequencyRank": 6300,
      "RadicalIndex": "173.7",
      "StrokeCount": 15
    }
  },
  "鳠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8082
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8082,
      "Radical": "魚",
      "FrequencyRank": 8435,
      "RadicalIndex": "195.13",
      "StrokeCount": 21
    }
  },
  "喱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "gramme; syllable"
    },
    "Keys": {
      "Hanzi": 5131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5131,
      "Radical": "口",
      "FrequencyRank": 5879,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "冁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "smile"
    },
    "Keys": {
      "Hanzi": 8023
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8023,
      "Radical": "八",
      "FrequencyRank": 7762,
      "RadicalIndex": "12.16",
      "StrokeCount": 18
    }
  },
  "阶段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēduàn",
      "PrimaryDefinition": "staged, period, phase angle, stage, gradation, level, peg, grade, remove, phase, leg, step"
    },
    "Keys": {
      "Word": 2622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "罕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎn",
      "PrimaryDefinition": "rare, scarce; surname"
    },
    "Keys": {
      "Hanzi": 895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "122.3",
      "GeneralStandard": 895,
      "Radical": "网",
      "HSK": 8,
      "FrequencyRank": 2221,
      "StrokeCount": 7
    }
  },
  "凰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "female phoenix"
    },
    "Keys": {
      "Hanzi": 2368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "16.9",
      "GeneralStandard": 2368,
      "Radical": "几",
      "HSK": 7,
      "FrequencyRank": 2453,
      "StrokeCount": 11
    }
  },
  "访": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎng",
      "PrimaryDefinition": "visit; ask, inquire"
    },
    "Keys": {
      "Hanzi": 561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 561,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 1037,
      "StrokeCount": 6
    }
  },
  "下降": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàjiàng",
      "PrimaryDefinition": "descend, go/come down, drop, fall, decline"
    },
    "Keys": {
      "Word": 3005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "忠诚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngchéng",
      "PrimaryDefinition": "devoted, loyal, fidelity, loyalty"
    },
    "Keys": {
      "Word": 10815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "决不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juébù",
      "PrimaryDefinition": "nohow, definitely not, anywise, scarcely, noway, nothing, never, in no way"
    },
    "Keys": {
      "Word": 3644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "破旧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòjiù",
      "PrimaryDefinition": "shabby"
    },
    "Keys": {
      "Word": 8571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恼羞成怒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎoxiū-chéngnù",
      "PrimaryDefinition": "to fly into a rage out of humiliation, to be ashamed into anger (idiom)"
    },
    "Keys": {
      "Word": 8385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拍戏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāi xì",
      "PrimaryDefinition": "to shoot a movie"
    },
    "Keys": {
      "Word": 8456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "彷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎng",
      "PrimaryDefinition": "like, resembling; resemble"
    },
    "Keys": {
      "Hanzi": 3736
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3736,
      "Radical": "彳",
      "FrequencyRank": 3487,
      "RadicalIndex": "60.4",
      "StrokeCount": 7
    }
  },
  "揣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāi",
      "PrimaryDefinition": "put things under clothes"
    },
    "Keys": {
      "Hanzi": 2525,
      "Word": 5996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2525,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3034,
      "StrokeCount": 12
    }
  },
  "朦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": "condition or appearance of moon"
    },
    "Keys": {
      "Hanzi": 3422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "74.14",
      "GeneralStandard": 3422,
      "Radical": "月",
      "HSK": 9,
      "FrequencyRank": 2826,
      "StrokeCount": 17
    }
  },
  "话剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàjù",
      "PrimaryDefinition": "stage play, modern drama, CL:臺|台[tai2],部[bu4]"
    },
    "Keys": {
      "Word": 1559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "饮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "drink; swallow; kind of drink"
    },
    "Keys": {
      "Hanzi": 824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.4",
      "GeneralStandard": 824,
      "Radical": "食",
      "HSK": 5,
      "FrequencyRank": 1569,
      "StrokeCount": 7
    }
  },
  "后面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòumian",
      "PrimaryDefinition": "rear, back, in the rear, latter, behind, rearward, at the back"
    },
    "Keys": {
      "Word": 1551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàng",
      "PrimaryDefinition": "to look at, look forward; to hope, expect"
    },
    "Keys": {
      "Hanzi": 2394,
      "Word": 9681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "74.7",
      "Grade": 7,
      "GeneralStandard": 2394,
      "Radical": "月",
      "HSK": 3,
      "FrequencyRank": 326,
      "StrokeCount": 11
    }
  },
  "不许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxǔ",
      "PrimaryDefinition": "can't"
    },
    "Keys": {
      "Word": 3284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "秧歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāngge",
      "PrimaryDefinition": "Yangge, a popular rural folk dance"
    },
    "Keys": {
      "Word": 10216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "值钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíqián",
      "PrimaryDefinition": "valuable, costly, expensive"
    },
    "Keys": {
      "Word": 10763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "fast, quick, clever, smart"
    },
    "Keys": {
      "Hanzi": 2323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "66.7",
      "GeneralStandard": 2323,
      "Radical": "攴",
      "HSK": 5,
      "FrequencyRank": 1436,
      "StrokeCount": 11
    }
  },
  "壬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rén",
      "PrimaryDefinition": "9th heavenly stem"
    },
    "Keys": {
      "Hanzi": 124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 124,
      "Radical": "士",
      "FrequencyRank": 4029,
      "RadicalIndex": "33.1",
      "StrokeCount": 4
    }
  },
  "寄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "send, transmit, mail; rely on"
    },
    "Keys": {
      "Hanzi": 2453,
      "Word": 2595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.8",
      "Grade": 4,
      "GeneralStandard": 2453,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 1611,
      "StrokeCount": 11
    }
  },
  "圣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng",
      "PrimaryDefinition": "holy, sacred"
    },
    "Keys": {
      "Hanzi": 340
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.2",
      "GeneralStandard": 340,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 960,
      "StrokeCount": 5
    }
  },
  "顽固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wángù",
      "PrimaryDefinition": "stubborn, obstinate"
    },
    "Keys": {
      "Word": 9653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rén",
      "PrimaryDefinition": "person"
    },
    "Keys": {
      "Hanzi": 10,
      "Radical": 9,
      "Word": 301
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9",
      "Grade": 1,
      "GeneralStandard": 10,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 7,
      "StrokeCount": 2
    }
  },
  "㳇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6618
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6618,
      "Radical": "水",
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "葱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": "scallions, leeks, green onions"
    },
    "Keys": {
      "Hanzi": 2554,
      "Word": 6056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "Grade": 7,
      "GeneralStandard": 2554,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2907,
      "StrokeCount": 12
    }
  },
  "中国画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zhōngguóhuà",
      "PrimaryDefinition": "Chinese painting"
    },
    "Keys": {
      "Word": 10808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "dam, embankment; weir"
    },
    "Keys": {
      "Hanzi": 956
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 956,
      "Radical": "土",
      "FrequencyRank": 3788,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "洳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rù",
      "PrimaryDefinition": "damp, boggy, marshy"
    },
    "Keys": {
      "Hanzi": 4348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4348,
      "Radical": "水",
      "FrequencyRank": 5622,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "蜓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "dragonfly"
    },
    "Keys": {
      "Hanzi": 2636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2636,
      "Radical": "虫",
      "FrequencyRank": 4083,
      "RadicalIndex": "142.7",
      "StrokeCount": 12
    }
  },
  "沙发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāfā",
      "PrimaryDefinition": "lounge, settee, sofa"
    },
    "Keys": {
      "Word": 1862
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "铟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "indium"
    },
    "Keys": {
      "Hanzi": 4869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4869,
      "Radical": "金",
      "FrequencyRank": 6619,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "造就": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàojiù",
      "PrimaryDefinition": "bring up, train"
    },
    "Keys": {
      "Word": 10614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "luxuriant, flourishing, abundant"
    },
    "Keys": {
      "Hanzi": 4123
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4123,
      "Radical": "艸",
      "FrequencyRank": 4007,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "逃走": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo zǒu",
      "PrimaryDefinition": "run away, flee"
    },
    "Keys": {
      "Word": 3962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "纪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "record, annal, historical account"
    },
    "Keys": {
      "Hanzi": 595
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.3",
      "GeneralStandard": 595,
      "Radical": "糸",
      "HSK": 3,
      "FrequencyRank": 584,
      "StrokeCount": 6
    }
  },
  "少数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎoshù",
      "PrimaryDefinition": "det.: a few"
    },
    "Keys": {
      "Word": 967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "流感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúgǎn",
      "PrimaryDefinition": "flu, influenza"
    },
    "Keys": {
      "Word": 4837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "轱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "wheel; to turn; to revolve"
    },
    "Keys": {
      "Hanzi": 4178
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4178,
      "Radical": "車",
      "FrequencyRank": 4747,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "祧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāo",
      "PrimaryDefinition": "an ancestral hall"
    },
    "Keys": {
      "Hanzi": 7080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7080,
      "Radical": "示",
      "FrequencyRank": 6339,
      "RadicalIndex": "113.6",
      "StrokeCount": 10
    }
  },
  "赭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhě",
      "PrimaryDefinition": "reddish brown; hematite; ochre"
    },
    "Keys": {
      "Hanzi": 5863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5863,
      "Radical": "赤",
      "FrequencyRank": 4789,
      "RadicalIndex": "155.9",
      "StrokeCount": 15
    }
  },
  "荸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bí",
      "PrimaryDefinition": "water chestnut"
    },
    "Keys": {
      "Hanzi": 4427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4427,
      "Radical": "艸",
      "FrequencyRank": 6095,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "叆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "cloudy sky; dark, obscure"
    },
    "Keys": {
      "Hanzi": 5629
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5629,
      "Radical": "厶",
      "RadicalIndex": "28.12",
      "StrokeCount": 14
    }
  },
  "噎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yē",
      "PrimaryDefinition": "choke; hiccup"
    },
    "Keys": {
      "Hanzi": 5906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5906,
      "Radical": "口",
      "FrequencyRank": 3961,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "碍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "obstruct, hinder, block, deter"
    },
    "Keys": {
      "Hanzi": 2876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.8",
      "GeneralStandard": 2876,
      "Radical": "石",
      "HSK": 5,
      "FrequencyRank": 1437,
      "StrokeCount": 13
    }
  },
  "炒作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎozuò",
      "PrimaryDefinition": "stir"
    },
    "Keys": {
      "Word": 4404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "保护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎohù",
      "PrimaryDefinition": "protect, safeguard"
    },
    "Keys": {
      "Word": 1282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "墉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōng",
      "PrimaryDefinition": "wall; fortified wall; small wall"
    },
    "Keys": {
      "Hanzi": 5639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5639,
      "Radical": "土",
      "FrequencyRank": 5754,
      "RadicalIndex": "32.11",
      "StrokeCount": 14
    }
  },
  "同期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngqī",
      "PrimaryDefinition": "the corresponding time period (in a different year etc), concurrent, synchronous"
    },
    "Keys": {
      "Word": 5095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "笑话儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàohuar5",
      "PrimaryDefinition": "Joke"
    },
    "Keys": {
      "Word": 1099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "中文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zhōngwén",
      "PrimaryDefinition": "Chinese, Ch. (written) language, Ch. language, Sinitic"
    },
    "Keys": {
      "Word": 476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "下手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xià shǒu",
      "PrimaryDefinition": "to start, to put one's hand to, to set about, the seat to the right of the main guest"
    },
    "Keys": {
      "Word": 9866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǒ",
      "PrimaryDefinition": "promethium"
    },
    "Keys": {
      "Hanzi": 7005
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7005,
      "Radical": "金",
      "FrequencyRank": 7463,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "再": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zài",
      "PrimaryDefinition": "again, twice, re-"
    },
    "Keys": {
      "Hanzi": 382,
      "Word": 450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "13.4",
      "Grade": 1,
      "GeneralStandard": 382,
      "Radical": "冂",
      "HSK": 1,
      "FrequencyRank": 242,
      "StrokeCount": 6
    }
  },
  "昧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèi",
      "PrimaryDefinition": "obscure, dark; darken"
    },
    "Keys": {
      "Hanzi": 1477
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "GeneralStandard": 1477,
      "Radical": "日",
      "HSK": 8,
      "FrequencyRank": 2376,
      "StrokeCount": 9
    }
  },
  "错综复杂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòzōng-fùzá",
      "PrimaryDefinition": "tangled and complicated (idiom)"
    },
    "Keys": {
      "Word": 6092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐng",
      "PrimaryDefinition": "Indian mallow"
    },
    "Keys": {
      "Hanzi": 3862
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3862,
      "Radical": "艸",
      "FrequencyRank": 7985,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "毗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "help, assist; connect, adjoin"
    },
    "Keys": {
      "Hanzi": 4206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4206,
      "Radical": "田",
      "FrequencyRank": 3441,
      "RadicalIndex": "102.4",
      "StrokeCount": 9
    }
  },
  "更衣室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēngyīshì",
      "PrimaryDefinition": "change room, dressing room, locker room, toilet"
    },
    "Keys": {
      "Word": 6747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 420
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "GeneralStandard": 420,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 2138,
      "StrokeCount": 6
    }
  },
  "交锋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo fēng",
      "PrimaryDefinition": "to cross swords, to have a confrontation (with sb)"
    },
    "Keys": {
      "Word": 7452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "节衣缩食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéyī-suōshí",
      "PrimaryDefinition": "to save on food and clothing (idiom), to live frugally"
    },
    "Keys": {
      "Word": 7520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巨头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùtóu",
      "PrimaryDefinition": "tycoon, magnate, big player (including company, country, school etc), big shot"
    },
    "Keys": {
      "Word": 7698
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灰心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī xīn",
      "PrimaryDefinition": "to lose heart, to be discouraged"
    },
    "Keys": {
      "Word": 7202
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "门铃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménlíng",
      "PrimaryDefinition": "doorbell"
    },
    "Keys": {
      "Word": 8244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "dry ditch, dry moat"
    },
    "Keys": {
      "Hanzi": 5014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5014,
      "Radical": "阜",
      "FrequencyRank": 4334,
      "RadicalIndex": "170.9",
      "StrokeCount": 11
    }
  },
  "逸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "flee, escape, break loose"
    },
    "Keys": {
      "Hanzi": 2363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.8",
      "GeneralStandard": 2363,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 2338,
      "StrokeCount": 11
    }
  },
  "埒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "enclosure, dike, embankment"
    },
    "Keys": {
      "Hanzi": 4421
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4421,
      "Radical": "土",
      "FrequencyRank": 6590,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "强势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángshì",
      "PrimaryDefinition": "powerful influence"
    },
    "Keys": {
      "Word": 4933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "hall; government office"
    },
    "Keys": {
      "Hanzi": 2256,
      "Word": 9423
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.8",
      "Grade": 7,
      "GeneralStandard": 2256,
      "Radical": "土",
      "HSK": 2,
      "FrequencyRank": 980,
      "StrokeCount": 11
    }
  },
  "侵占": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnzhàn",
      "PrimaryDefinition": "to invade and occupy (territory)"
    },
    "Keys": {
      "Word": 8716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "英": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "petal, flower, leaf; brave, a hero; England, English"
    },
    "Keys": {
      "Hanzi": 1006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1006,
      "Radical": "艸",
      "HSK": 2,
      "FrequencyRank": 371,
      "StrokeCount": 8
    }
  },
  "舛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuǎn",
      "PrimaryDefinition": "contrary"
    },
    "Keys": {
      "Radical": 136,
      "Hanzi": 3619
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3619,
      "Radical": "舛",
      "FrequencyRank": 4694,
      "RadicalIndex": "136",
      "StrokeCount": 6
    }
  },
  "彼此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐcǐ",
      "PrimaryDefinition": "each other, one another, I/me too"
    },
    "Keys": {
      "Word": 3251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "啴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎn",
      "PrimaryDefinition": "pant; many"
    },
    "Keys": {
      "Hanzi": 7168
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7168,
      "Radical": "口",
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "牚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7349
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7349,
      "Radical": "牙",
      "RadicalIndex": "92.8",
      "StrokeCount": 12
    }
  },
  "心态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīntài",
      "PrimaryDefinition": "state_of_mind, state of mind, psychology"
    },
    "Keys": {
      "Word": 4060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "逾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "go over, pass over, exceed"
    },
    "Keys": {
      "Hanzi": 2708
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.9",
      "GeneralStandard": 2708,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 2610,
      "StrokeCount": 12
    }
  },
  "面向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànxiàng",
      "PrimaryDefinition": "to face, to turn towards, to incline to, geared towards, catering for, -oriented, facial feature, appearance, aspect, facet"
    },
    "Keys": {
      "Word": 4861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuǎng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3587,
      "Radical": "大",
      "FrequencyRank": 6814,
      "RadicalIndex": "37.3",
      "StrokeCount": 6
    }
  },
  "疲惫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píbèi",
      "PrimaryDefinition": "beaten, exhausted, tired"
    },
    "Keys": {
      "Word": 8508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎng",
      "PrimaryDefinition": "a long day. bright. extended. clear"
    },
    "Keys": {
      "Hanzi": 4374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4374,
      "Radical": "日",
      "FrequencyRank": 4904,
      "RadicalIndex": "72.5",
      "StrokeCount": 9
    }
  },
  "早晨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎochen",
      "PrimaryDefinition": "early morning, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "炱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "soot"
    },
    "Keys": {
      "Hanzi": 4391
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4391,
      "Radical": "火",
      "FrequencyRank": 5965,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "纪念碑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìniànbēi",
      "PrimaryDefinition": "monument"
    },
    "Keys": {
      "Word": 7323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "门票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménpiào",
      "PrimaryDefinition": "entrance/admission ticket, admission ticket, admission fee, admission price, entrance ticket, admission charge, cover_charge, admission, entrance fee, entrance money, pasteboard, price of admission, entrance"
    },
    "Keys": {
      "Word": 237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "胸膛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngtáng",
      "PrimaryDefinition": "chest"
    },
    "Keys": {
      "Word": 10068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngwù",
      "PrimaryDefinition": "thing, critter, crittur, zoic, anthropomorphism, animal, deer, animate being, beast, creature, burrow, sire, worker, fauna, brute"
    },
    "Keys": {
      "Word": 629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "龊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuò",
      "PrimaryDefinition": "narrow, small; dirty"
    },
    "Keys": {
      "Hanzi": 5900
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5900,
      "Radical": "齒",
      "FrequencyRank": 4430,
      "RadicalIndex": "211.7",
      "StrokeCount": 15
    }
  },
  "淟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎn",
      "PrimaryDefinition": "turbid; muddy"
    },
    "Keys": {
      "Hanzi": 7235
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7235,
      "Radical": "水",
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "互访": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùfǎng",
      "PrimaryDefinition": "exchange visits"
    },
    "Keys": {
      "Word": 7121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàng",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 3551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3551,
      "Radical": "邑",
      "FrequencyRank": 4667,
      "RadicalIndex": "163.3",
      "StrokeCount": 5
    }
  },
  "湿润": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīrùn",
      "PrimaryDefinition": "moist"
    },
    "Keys": {
      "Word": 9100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "霓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "rainbow; variegated, colored"
    },
    "Keys": {
      "Hanzi": 6078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6078,
      "Radical": "雨",
      "FrequencyRank": 3609,
      "RadicalIndex": "173.8",
      "StrokeCount": 16
    }
  },
  "听取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīngqǔ",
      "PrimaryDefinition": "to hear (news), to listen to"
    },
    "Keys": {
      "Word": 5088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "陷入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànrù",
      "PrimaryDefinition": "plunge, be immersed in, land oneself in, slump, be caught in, lapse, launch, get_into, fall_in, invaginate, be deep in, engulf, run_into, fall_into, be lost/immersed in, be lost, sink into, tangle, fall into, immerse, sink, be lost in, bed, immersed in, get bogged down in, land, sink/fall into"
    },
    "Keys": {
      "Word": 5163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "雀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "sparrow"
    },
    "Keys": {
      "Hanzi": 2255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "172.3",
      "GeneralStandard": 2255,
      "Radical": "隹",
      "HSK": 9,
      "FrequencyRank": 2331,
      "StrokeCount": 11
    }
  },
  "可靠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěkào",
      "PrimaryDefinition": "reliable, dependable"
    },
    "Keys": {
      "Word": 1670
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "习俗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xísú",
      "PrimaryDefinition": "mores, custom, form, consuetudinary, institute, social custom, Sunna, convention, convenance, consuetude, conventionalism, ways, respectability, usage, manners, consuetudinal, conventionality"
    },
    "Keys": {
      "Word": 9829
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pián",
      "PrimaryDefinition": "callus, calluses"
    },
    "Keys": {
      "Hanzi": 4594
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4594,
      "Radical": "肉",
      "FrequencyRank": 5638,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "臑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nào",
      "PrimaryDefinition": "soft"
    },
    "Keys": {
      "Hanzi": 8013
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8013,
      "Radical": "肉",
      "FrequencyRank": 7348,
      "RadicalIndex": "130.14",
      "StrokeCount": 18
    }
  },
  "通行证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngxíngzhèng",
      "PrimaryDefinition": "a pass (authorization through a checkpoint), a laissez-passer or safe conduct, to authenticate, to confirm name and password on a website, fig. a condition giving access to benefits (e.g. a diploma as a pass to a career)"
    },
    "Keys": {
      "Word": 9530
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "发作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāzuò",
      "PrimaryDefinition": "break out, flare up, explode, have fit of anger, get_into, have a fit of anger, show effect"
    },
    "Keys": {
      "Word": 6450
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "律师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜshī",
      "PrimaryDefinition": "scribe, counsellor, advocate, gown, avocat, solicitor, barrister, lawyer, counsel, jurist, counselor, counselor-at-law, attorney"
    },
    "Keys": {
      "Word": 2716
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "碇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng",
      "PrimaryDefinition": "anchor"
    },
    "Keys": {
      "Hanzi": 5422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5422,
      "Radical": "石",
      "FrequencyRank": 5053,
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "儡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "puppet, dummy"
    },
    "Keys": {
      "Hanzi": 6262
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6262,
      "Radical": "人",
      "FrequencyRank": 3992,
      "RadicalIndex": "9.15",
      "StrokeCount": 17
    }
  },
  "慢车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànchē",
      "PrimaryDefinition": "local bus or train, slow train with many stops"
    },
    "Keys": {
      "Word": 4850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "大腕儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàwànr5",
      "PrimaryDefinition": "Big wrist"
    },
    "Keys": {
      "Word": 6136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ān",
      "PrimaryDefinition": "lid of a caldron; Buddhist cloister"
    },
    "Keys": {
      "Hanzi": 7882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7882,
      "Radical": "皿",
      "FrequencyRank": 7810,
      "RadicalIndex": "108.11",
      "StrokeCount": 16
    }
  },
  "鲵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "Cryptobranchus japonicus; a salamander"
    },
    "Keys": {
      "Hanzi": 6144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6144,
      "Radical": "魚",
      "FrequencyRank": 6174,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "未知数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèizhīshù",
      "PrimaryDefinition": "unknown quantity"
    },
    "Keys": {
      "Word": 9717
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祖先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔxiān",
      "PrimaryDefinition": "parent, father, forbear, forefathers, origin, progenitor, propositus, pedigree, forebear, forefather, antecedent, ascendent, grandfather, ancestor, forerunner, grandsire, stirp, root, ancestry, grandmother, antecedents, forbears, stirps, ascendant, primogenitor, ancestral, ancestors, sire, stock, forebears"
    },
    "Keys": {
      "Word": 11010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "救助": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùzhù",
      "PrimaryDefinition": "to help sb in trouble, aid, assistance"
    },
    "Keys": {
      "Word": 4760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "外文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiwén",
      "PrimaryDefinition": "foreign language (written)"
    },
    "Keys": {
      "Word": 1996
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "重创": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngchuāng",
      "PrimaryDefinition": "to inflict heavy losses, to inflict serious damage"
    },
    "Keys": {
      "Word": 10830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6636
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6636,
      "Radical": "女",
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "拉拢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lālong",
      "PrimaryDefinition": "to rope in, fig. to involve sb, to entice"
    },
    "Keys": {
      "Word": 7907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàchē",
      "PrimaryDefinition": "get off/out of vehicle"
    },
    "Keys": {
      "Word": 389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "世纪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjì",
      "PrimaryDefinition": "one C, centred, C, century, age, hundred, 100"
    },
    "Keys": {
      "Word": 1907
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "尹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "govern; oversee; director"
    },
    "Keys": {
      "Hanzi": 3524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3524,
      "Radical": "尸",
      "FrequencyRank": 2885,
      "RadicalIndex": "44.1",
      "StrokeCount": 4
    }
  },
  "棒球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàngqiú",
      "PrimaryDefinition": "no-hitter, apple, grounder, baseball game, homer, change-up, baseball, extra innings, curve, ball game, horsehide, infielder"
    },
    "Keys": {
      "Word": 5510
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "报社": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàoshè",
      "PrimaryDefinition": "newspaper (i.e. a company), CL:家[jia1]"
    },
    "Keys": {
      "Word": 5534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嬉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "enjoy; play, amuse oneself"
    },
    "Keys": {
      "Hanzi": 6017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.12",
      "GeneralStandard": 6017,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 3375,
      "StrokeCount": 15
    }
  },
  "汹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōng",
      "PrimaryDefinition": "turbulent; noisy, restless"
    },
    "Keys": {
      "Hanzi": 864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 864,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2746,
      "StrokeCount": 7
    }
  },
  "聆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "listen, hear"
    },
    "Keys": {
      "Hanzi": 2194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2194,
      "Radical": "耳",
      "FrequencyRank": 3770,
      "RadicalIndex": "128.5",
      "StrokeCount": 11
    }
  },
  "谏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "remonstrate, admonish"
    },
    "Keys": {
      "Hanzi": 4995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4995,
      "Radical": "言",
      "FrequencyRank": 3050,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "桫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": "horse chestnut"
    },
    "Keys": {
      "Hanzi": 4778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4778,
      "Radical": "木",
      "FrequencyRank": 6255,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "鬯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng",
      "PrimaryDefinition": "sacrificial wine"
    },
    "Keys": {
      "Radical": 192,
      "Hanzi": 7028
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7028,
      "Radical": "鬯",
      "FrequencyRank": 5573,
      "RadicalIndex": "192",
      "StrokeCount": 10
    }
  },
  "携": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "lead by hand, take with; carry"
    },
    "Keys": {
      "Hanzi": 2839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.1",
      "GeneralStandard": 2839,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1964,
      "StrokeCount": 13
    }
  },
  "龢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "in harmony; calm, peaceful"
    },
    "Keys": {
      "Hanzi": 8095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8095,
      "Radical": "龠",
      "FrequencyRank": 4539,
      "RadicalIndex": "214.5",
      "StrokeCount": 22
    }
  },
  "组合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔhé",
      "PrimaryDefinition": "make up, compose, unite"
    },
    "Keys": {
      "Word": 2220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "懈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "idle, relaxed, remiss"
    },
    "Keys": {
      "Hanzi": 3382
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.13",
      "GeneralStandard": 3382,
      "Radical": "心",
      "HSK": 9,
      "FrequencyRank": 2689,
      "StrokeCount": 16
    }
  },
  "告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gào",
      "PrimaryDefinition": "tell, announce, inform; accuse"
    },
    "Keys": {
      "Hanzi": 760,
      "Word": 6712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 7,
      "GeneralStandard": 760,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 310,
      "StrokeCount": 7
    }
  },
  "砺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "whetstone; sharpen"
    },
    "Keys": {
      "Hanzi": 4475
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4475,
      "Radical": "石",
      "FrequencyRank": 4760,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "空调": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngtiáo",
      "PrimaryDefinition": "air conditioner, air-conditioning, air-conditioner"
    },
    "Keys": {
      "Word": 1677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "饺子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎozi",
      "PrimaryDefinition": "dumpling, pot-sticker, CL:個|个[ge4],隻|只[zhi1]"
    },
    "Keys": {
      "Word": 769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "华侨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáqiáo",
      "PrimaryDefinition": "overseas Chinese"
    },
    "Keys": {
      "Word": 7131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "签订": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāndìng",
      "PrimaryDefinition": "conclude and sign (treaty/contract/etc.)"
    },
    "Keys": {
      "Word": 3795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "汀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng",
      "PrimaryDefinition": "sandbar, beach, bank, shore"
    },
    "Keys": {
      "Hanzi": 3553
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3553,
      "Radical": "水",
      "FrequencyRank": 3163,
      "RadicalIndex": "85.2",
      "StrokeCount": 5
    }
  },
  "天分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānfèn",
      "PrimaryDefinition": "natural gift, talent"
    },
    "Keys": {
      "Word": 9477
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "侍候": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìhòu",
      "PrimaryDefinition": "to serve, to wait upon"
    },
    "Keys": {
      "Word": 9143
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎo",
      "PrimaryDefinition": "bake, roast, toast, cook"
    },
    "Keys": {
      "Hanzi": 2057,
      "Word": 7784
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "Grade": 7,
      "GeneralStandard": 2057,
      "Radical": "火",
      "HSK": 5,
      "FrequencyRank": 2629,
      "StrokeCount": 10
    }
  },
  "扞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "ward off, withstand, resist"
    },
    "Keys": {
      "Hanzi": 6522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6522,
      "Radical": "手",
      "RadicalIndex": "64.3",
      "StrokeCount": 6
    }
  },
  "嵴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "mountain ridge"
    },
    "Keys": {
      "Hanzi": 5480
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5480,
      "Radical": "山",
      "FrequencyRank": 5420,
      "RadicalIndex": "46.1",
      "StrokeCount": 13
    }
  },
  "司": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "take charge of, control, manage; officer"
    },
    "Keys": {
      "Hanzi": 325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 325,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 278,
      "StrokeCount": 5
    }
  },
  "甲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "armor, shell; fingernails; 1st heavenly stem"
    },
    "Keys": {
      "Hanzi": 239,
      "Word": 3604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102",
      "Grade": 5,
      "GeneralStandard": 239,
      "Radical": "田",
      "HSK": 5,
      "FrequencyRank": 1106,
      "StrokeCount": 5
    }
  },
  "潜能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánnéng",
      "PrimaryDefinition": "latent, possibility, latent energy, potency, ability, latent ability, ergal, potentiality, latent energy/ability, potential"
    },
    "Keys": {
      "Word": 8677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "tired, weary, fatigued"
    },
    "Keys": {
      "Hanzi": 2722
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "34.9",
      "GeneralStandard": 2722,
      "Radical": "夂",
      "HSK": 8,
      "FrequencyRank": 3007,
      "StrokeCount": 12
    }
  },
  "风水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngshui",
      "PrimaryDefinition": "feng shui, geomancy"
    },
    "Keys": {
      "Word": 6579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "正如": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngrú",
      "PrimaryDefinition": "just as, precisely as"
    },
    "Keys": {
      "Word": 4208
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "奔波": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēnbō",
      "PrimaryDefinition": "rush about, be busy running about"
    },
    "Keys": {
      "Word": 5562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péi",
      "PrimaryDefinition": "berkelium"
    },
    "Keys": {
      "Hanzi": 7535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7535,
      "Radical": "金",
      "FrequencyRank": 6266,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "摈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìn",
      "PrimaryDefinition": "exclude, expel, reject; usher"
    },
    "Keys": {
      "Hanzi": 5362
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5362,
      "Radical": "手",
      "FrequencyRank": 4363,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "谚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "proverb, maxim"
    },
    "Keys": {
      "Hanzi": 2467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2467,
      "Radical": "言",
      "FrequencyRank": 3625,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "注入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùrù",
      "PrimaryDefinition": "inject, mainline, infuse, teem, impregnate, inflood, implant, pump, pour, funnel, pour into, inspire, pour/empty into, influx, empty into, breathe, empty, immit"
    },
    "Keys": {
      "Word": 10875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晴朗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qínglǎng",
      "PrimaryDefinition": "sunny and cloudless"
    },
    "Keys": {
      "Word": 3822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "大门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàmén",
      "PrimaryDefinition": "barndoor, portal, main entrance, front_door, gate, door, entry, main door/gate, front door, main entrance/door/gate, entrance"
    },
    "Keys": {
      "Word": 591
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "据此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùcǐ",
      "PrimaryDefinition": "according to this, on the ground of the above, (formally introduces reported speech in writing)"
    },
    "Keys": {
      "Word": 7706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "harvest"
    },
    "Keys": {
      "Hanzi": 1404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1404,
      "Radical": "艸",
      "FrequencyRank": 3790,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "裳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shang",
      "PrimaryDefinition": "clothes; skirt; beautiful"
    },
    "Keys": {
      "Hanzi": 3099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.8",
      "GeneralStandard": 3099,
      "Radical": "衣",
      "HSK": 6,
      "FrequencyRank": 2861,
      "StrokeCount": 14
    }
  },
  "禄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "blessing, happiness, prosperity"
    },
    "Keys": {
      "Hanzi": 2794
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2794,
      "Radical": "示",
      "FrequencyRank": 2381,
      "RadicalIndex": "113.7",
      "StrokeCount": 12
    }
  },
  "级别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíbié",
      "PrimaryDefinition": "(military) rank, level, grade"
    },
    "Keys": {
      "Word": 7292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奉献": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèngxiàn",
      "PrimaryDefinition": "offer as tribute, present with all respect"
    },
    "Keys": {
      "Word": 4566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "祝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "pray for happiness or blessings"
    },
    "Keys": {
      "Hanzi": 1712,
      "Word": 2195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.5",
      "Grade": 3,
      "GeneralStandard": 1712,
      "Radical": "示",
      "HSK": 3,
      "FrequencyRank": 1651,
      "StrokeCount": 9
    }
  },
  "医": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "cure, heal; doctor, medical"
    },
    "Keys": {
      "Hanzi": 700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "23.5",
      "GeneralStandard": 700,
      "Radical": "匸",
      "HSK": 1,
      "FrequencyRank": 482,
      "StrokeCount": 7
    }
  },
  "好在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎozài",
      "PrimaryDefinition": "luckily, fortunately"
    },
    "Keys": {
      "Word": 7014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō",
      "PrimaryDefinition": "pour, splash, water, sprinkle"
    },
    "Keys": {
      "Hanzi": 1262,
      "Word": 3785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 5,
      "GeneralStandard": 1262,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 2327,
      "StrokeCount": 8
    }
  },
  "粥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "rice gruel, congee"
    },
    "Keys": {
      "Hanzi": 2803,
      "Word": 5376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.6",
      "Grade": 6,
      "GeneralStandard": 2803,
      "Radical": "米",
      "HSK": 6,
      "FrequencyRank": 3021,
      "StrokeCount": 12
    }
  },
  "砵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "(Cant.) port (Eng. loan-word), 西矺 a sideboard (Eng. loan-word)"
    },
    "Keys": {
      "Hanzi": 6968
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6968,
      "Radical": "石",
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "涣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "scatter; scattered"
    },
    "Keys": {
      "Hanzi": 2078
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2078,
      "Radical": "水",
      "FrequencyRank": 3832,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "业余": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèyú",
      "PrimaryDefinition": "unpaid, dilettante, amateur, recreational"
    },
    "Keys": {
      "Word": 3081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "喊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎn",
      "PrimaryDefinition": "shout, call out, yell; howl; cry"
    },
    "Keys": {
      "Hanzi": 2623,
      "Word": 708
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.9",
      "Grade": 2,
      "GeneralStandard": 2623,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 1183,
      "StrokeCount": 12
    }
  },
  "放映": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngyìng",
      "PrimaryDefinition": "to show (a movie), to screen"
    },
    "Keys": {
      "Word": 6505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "物证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùzhèng",
      "PrimaryDefinition": "material evidence"
    },
    "Keys": {
      "Word": 9808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遍布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànbù",
      "PrimaryDefinition": "to cover the whole (area), to be found throughout"
    },
    "Keys": {
      "Word": 5616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoshi",
      "PrimaryDefinition": "always"
    },
    "Keys": {
      "Word": 837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "生存": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngcún",
      "PrimaryDefinition": "live, exist"
    },
    "Keys": {
      "Word": 1883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "确保": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèbǎo",
      "PrimaryDefinition": "secure, insure, ensure, assure, reassure, guarantee"
    },
    "Keys": {
      "Word": 1836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "司法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīfǎ",
      "PrimaryDefinition": "justice, judicature, administration of justice, judicatory, judiciary, judgeship, judicial, judgship"
    },
    "Keys": {
      "Word": 9304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "史": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "history, chronicle, annals"
    },
    "Keys": {
      "Hanzi": 248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "GeneralStandard": 248,
      "Radical": "口",
      "HSK": 4,
      "FrequencyRank": 456,
      "StrokeCount": 5
    }
  },
  "雨水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔshuǐ",
      "PrimaryDefinition": "rain, Rainwater, rainwater, Rainwater (second solar term), rainfall, Rain water (2nd solar term)"
    },
    "Keys": {
      "Word": 4149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "照料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàoliào",
      "PrimaryDefinition": "to tend, to take care of sb"
    },
    "Keys": {
      "Word": 10678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "段落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duànluò",
      "PrimaryDefinition": "phase, time interval, paragraph, (written) passage"
    },
    "Keys": {
      "Word": 6371
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "降低": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàngdī",
      "PrimaryDefinition": "reduce, cut down, drop, lower"
    },
    "Keys": {
      "Word": 2616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "娴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "elegant, refined; skillful"
    },
    "Keys": {
      "Hanzi": 4693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4693,
      "Radical": "女",
      "FrequencyRank": 3209,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "辽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "distant, far"
    },
    "Keys": {
      "Hanzi": 331
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.2",
      "GeneralStandard": 331,
      "Radical": "辵",
      "HSK": 9,
      "FrequencyRank": 2086,
      "StrokeCount": 5
    }
  },
  "笋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔn",
      "PrimaryDefinition": "bamboo shoots"
    },
    "Keys": {
      "Hanzi": 1953
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1953,
      "Radical": "竹",
      "FrequencyRank": 3455,
      "RadicalIndex": "118.4",
      "StrokeCount": 10
    }
  },
  "鳑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8015,
      "Radical": "魚",
      "FrequencyRank": 6602,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "谎话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎnghuà",
      "PrimaryDefinition": "lie"
    },
    "Keys": {
      "Word": 7197
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "修复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūfù",
      "PrimaryDefinition": "to restore, to renovate, restoration, (computing) to fix (a bug)"
    },
    "Keys": {
      "Word": 4073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "靬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7305,
      "Radical": "革",
      "RadicalIndex": "177.3",
      "StrokeCount": 12
    }
  },
  "正式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngshì",
      "PrimaryDefinition": "formal (of actions/speeches/etc.)"
    },
    "Keys": {
      "Word": 2149
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "水稻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐdào",
      "PrimaryDefinition": "rice, paddy, CL:株[zhu1]"
    },
    "Keys": {
      "Word": 9257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "光明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāngmíng",
      "PrimaryDefinition": "bright, promising, openhearted, guileless"
    },
    "Keys": {
      "Word": 1524
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "旞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8021,
      "Radical": "方",
      "RadicalIndex": "70.15",
      "StrokeCount": 18
    }
  },
  "癞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lài",
      "PrimaryDefinition": "leprosy, scabies, mange; shoddy"
    },
    "Keys": {
      "Hanzi": 6356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6356,
      "Radical": "疒",
      "FrequencyRank": 4225,
      "RadicalIndex": "104.13",
      "StrokeCount": 18
    }
  },
  "上下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngxià",
      "PrimaryDefinition": "above and below, up and down, high and low, superior and inferior, senior and junior, old and young, ruler and subject, heaven and earth"
    },
    "Keys": {
      "Word": 3866
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "总数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngshù",
      "PrimaryDefinition": "result, all, amount of money, aggregate, sum total, summation, amount, number, sum, total, tale, sum of money, totality, capita, quantity"
    },
    "Keys": {
      "Word": 4278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无所事事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúsuǒshìshì",
      "PrimaryDefinition": "to have nothing to do, to idle one's time away (idiom)"
    },
    "Keys": {
      "Word": 9783
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "微弱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēiruò",
      "PrimaryDefinition": "faint, feeble, weak"
    },
    "Keys": {
      "Word": 9694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "男人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánren",
      "PrimaryDefinition": "male, man, chap, mankind, buck, bastard, gentleman, jack, gent, men, blighter, cuss, hombre, lad, adult male, groom, husband, bimbo, dog, fellow, mon, menfolk, fella"
    },
    "Keys": {
      "Word": 263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "呼风唤雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūfēng-huànyǔ",
      "PrimaryDefinition": "to call the wind and summon the rain (idiom), to exercise magical powers, fig. to stir up troubles"
    },
    "Keys": {
      "Word": 7106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "填补": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiánbǔ",
      "PrimaryDefinition": "to fill a gap, to fill in a blank (on a form), to overcome a deficiency"
    },
    "Keys": {
      "Word": 9490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "可见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kějiàn",
      "PrimaryDefinition": "therefore"
    },
    "Keys": {
      "Word": 2661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "筹划": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóuhuà",
      "PrimaryDefinition": "to plan and prepare"
    },
    "Keys": {
      "Word": 5942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "enjoin, instruct; charge"
    },
    "Keys": {
      "Hanzi": 3918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3918,
      "Radical": "口",
      "FrequencyRank": 4256,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "部署": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùshǔ",
      "PrimaryDefinition": "dispose, deploy"
    },
    "Keys": {
      "Word": 5731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xié",
      "PrimaryDefinition": "ribs, armpits; flank; threaten"
    },
    "Keys": {
      "Hanzi": 1191
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "GeneralStandard": 1191,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 1494,
      "StrokeCount": 8
    }
  },
  "补贴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔtiē",
      "PrimaryDefinition": "to subsidize, subsidy, allowance, to supplement (one's salary etc), benefit"
    },
    "Keys": {
      "Word": 3278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "常人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángrén",
      "PrimaryDefinition": "ordinary person"
    },
    "Keys": {
      "Word": 5815
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "璆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "beautiful jade; tinkle"
    },
    "Keys": {
      "Hanzi": 7749
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7749,
      "Radical": "玉",
      "RadicalIndex": "96.11",
      "StrokeCount": 15
    }
  },
  "辽阔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáokuò",
      "PrimaryDefinition": "vast, extensive"
    },
    "Keys": {
      "Word": 8039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "enjoy, be contented, joyful"
    },
    "Keys": {
      "Hanzi": 4353
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4353,
      "Radical": "心",
      "FrequencyRank": 3432,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "照常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàocháng",
      "PrimaryDefinition": "as usual"
    },
    "Keys": {
      "Word": 10676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāo",
      "PrimaryDefinition": "sediment, dregs; pickle"
    },
    "Keys": {
      "Hanzi": 3428,
      "Word": 4176
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.11",
      "Grade": 5,
      "GeneralStandard": 3428,
      "Radical": "米",
      "HSK": 5,
      "FrequencyRank": 1918,
      "StrokeCount": 17
    }
  },
  "穴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuè",
      "PrimaryDefinition": "cave"
    },
    "Keys": {
      "Radical": 116,
      "Hanzi": 313
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116",
      "GeneralStandard": 313,
      "Radical": "穴",
      "HSK": 9,
      "FrequencyRank": 1940,
      "StrokeCount": 5
    }
  },
  "贤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xián",
      "PrimaryDefinition": "virtuous, worthy, good; able"
    },
    "Keys": {
      "Hanzi": 1074
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "GeneralStandard": 1074,
      "Radical": "貝",
      "HSK": 9,
      "FrequencyRank": 1943,
      "StrokeCount": 8
    }
  },
  "演艺圈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnyìquān",
      "PrimaryDefinition": "show business"
    },
    "Keys": {
      "Word": 10205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "柳树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǔshù",
      "PrimaryDefinition": "willow"
    },
    "Keys": {
      "Word": 8110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忠实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngshí",
      "PrimaryDefinition": "leal, faithful, true"
    },
    "Keys": {
      "Word": 10816
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "院子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànzi",
      "PrimaryDefinition": "patio, court, courtyard, yard, compound"
    },
    "Keys": {
      "Word": 1194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "著": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhe",
      "PrimaryDefinition": "manifest; (Cant.) to wear"
    },
    "Keys": {
      "Hanzi": 2198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2198,
      "Radical": "艸",
      "HSK": 4,
      "FrequencyRank": 777,
      "StrokeCount": 11
    }
  },
  "每": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měi",
      "PrimaryDefinition": "every, each"
    },
    "Keys": {
      "Hanzi": 767,
      "Word": 1734
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "80.3",
      "Grade": 3,
      "GeneralStandard": 767,
      "Radical": "毋",
      "HSK": 3,
      "FrequencyRank": 359,
      "StrokeCount": 7
    }
  },
  "婺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "name of a star"
    },
    "Keys": {
      "Hanzi": 5326
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5326,
      "Radical": "女",
      "FrequencyRank": 5178,
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "运输": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnshū",
      "PrimaryDefinition": "transport"
    },
    "Keys": {
      "Word": 2127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "窕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎo",
      "PrimaryDefinition": "slender; quiet and modest, charming"
    },
    "Keys": {
      "Hanzi": 4993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4993,
      "Radical": "穴",
      "FrequencyRank": 4684,
      "RadicalIndex": "116.6",
      "StrokeCount": 11
    }
  },
  "终于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngyú",
      "PrimaryDefinition": "in the last analysis, at long last, at last, lastly, ultimately, all things considered, out, on the whole, at_last, eventually, in the final analysis, finally"
    },
    "Keys": {
      "Word": 2183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "价位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàwèi",
      "PrimaryDefinition": "price"
    },
    "Keys": {
      "Word": 7362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "相似": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngsì",
      "PrimaryDefinition": "resemblance, alike, be alike, be similar/alike, match, be similar, resemble"
    },
    "Keys": {
      "Word": 2043
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "武": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "military; martial, warlike"
    },
    "Keys": {
      "Hanzi": 946
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "77.4",
      "GeneralStandard": 946,
      "Radical": "止",
      "HSK": 3,
      "FrequencyRank": 501,
      "StrokeCount": 8
    }
  },
  "人才": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réncái",
      "PrimaryDefinition": "talent, capable person, a talented person, qualified personnel, a person of ability"
    },
    "Keys": {
      "Word": 1843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "存折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cúnzhé",
      "PrimaryDefinition": "passbook, bankbook"
    },
    "Keys": {
      "Word": 6085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阶梯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiētī",
      "PrimaryDefinition": "flight of steps, (fig.) a means of advancement, stepping stone"
    },
    "Keys": {
      "Word": 7498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "啤酒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píjiǔ",
      "PrimaryDefinition": "malt liquor, malt, suds, nappy, wallop, beery, malt_liquor, stout, barley-bree, brewage, beer"
    },
    "Keys": {
      "Word": 1791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "体育馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐyùguǎn",
      "PrimaryDefinition": "stadium, coliseum, gym, turnhall, palestra, gymnasium"
    },
    "Keys": {
      "Word": 1022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "恰当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiàdàng",
      "PrimaryDefinition": "appropriate, proper, suitable, fitting"
    },
    "Keys": {
      "Word": 4921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "埘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "roost"
    },
    "Keys": {
      "Hanzi": 4415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4415,
      "Radical": "土",
      "FrequencyRank": 6804,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "眼红": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnhóng",
      "PrimaryDefinition": "to covet, envious, jealous, green with envy, infuriated, furious"
    },
    "Keys": {
      "Word": 10192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "词": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "words; phrase, expression"
    },
    "Keys": {
      "Hanzi": 897,
      "Word": 578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "Grade": 2,
      "GeneralStandard": 897,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 959,
      "StrokeCount": 7
    }
  },
  "籍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "record, register, list; census"
    },
    "Keys": {
      "Hanzi": 3482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.14",
      "GeneralStandard": 3482,
      "Radical": "竹",
      "HSK": 5,
      "FrequencyRank": 1579,
      "StrokeCount": 20
    }
  },
  "嫁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jià",
      "PrimaryDefinition": "to marry, give a daughter in marriage"
    },
    "Keys": {
      "Hanzi": 3042,
      "Word": 7370
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.1",
      "Grade": 7,
      "GeneralStandard": 3042,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2063,
      "StrokeCount": 13
    }
  },
  "拳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "fist; various forms of boxing"
    },
    "Keys": {
      "Hanzi": 2052,
      "Word": 8808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 7,
      "GeneralStandard": 2052,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1784,
      "StrokeCount": 10
    }
  },
  "盲人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mángrén",
      "PrimaryDefinition": "blind person"
    },
    "Keys": {
      "Word": 4852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "深远": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnyuǎn",
      "PrimaryDefinition": "profound and far-reaching"
    },
    "Keys": {
      "Word": 9034
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绝缘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juéyuán",
      "PrimaryDefinition": "to have no contact with, to be cut off from, (electricity) to insulate"
    },
    "Keys": {
      "Word": 7723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔ",
      "PrimaryDefinition": "cauterize with moxa; moxibustion"
    },
    "Keys": {
      "Hanzi": 819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.3",
      "GeneralStandard": 819,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 4024,
      "StrokeCount": 7
    }
  },
  "空想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngxiǎng",
      "PrimaryDefinition": "daydream, fantasy, to fantasize"
    },
    "Keys": {
      "Word": 7823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chún",
      "PrimaryDefinition": "pure, clean, simple"
    },
    "Keys": {
      "Hanzi": 931,
      "Word": 2343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "Grade": 4,
      "GeneralStandard": 931,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 1125,
      "StrokeCount": 7
    }
  },
  "温室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnshì",
      "PrimaryDefinition": "greenhouse"
    },
    "Keys": {
      "Word": 9729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "犏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piān",
      "PrimaryDefinition": "yak-ox"
    },
    "Keys": {
      "Hanzi": 5495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5495,
      "Radical": "牛",
      "FrequencyRank": 8247,
      "RadicalIndex": "93.9",
      "StrokeCount": 13
    }
  },
  "淮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huái",
      "PrimaryDefinition": "river in Anhui province"
    },
    "Keys": {
      "Hanzi": 2421
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2421,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2293,
      "StrokeCount": 11
    }
  },
  "栩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": "species of oak; be glad, be pleased"
    },
    "Keys": {
      "Hanzi": 4463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4463,
      "Radical": "木",
      "FrequencyRank": 3743,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "浪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làng",
      "PrimaryDefinition": "wave; wasteful, reckless"
    },
    "Keys": {
      "Hanzi": 2084,
      "Word": 7936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "Grade": 7,
      "GeneralStandard": 2084,
      "Radical": "水",
      "HSK": 3,
      "FrequencyRank": 1186,
      "StrokeCount": 10
    }
  },
  "卷子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juǎnzi",
      "PrimaryDefinition": "steamed roll, spring roll, test paper, examination paper"
    },
    "Keys": {
      "Word": 7715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "议会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìhuì",
      "PrimaryDefinition": "Sanhedrin, general assembly, Cortes, Knesset, diet, senate, Majlis, duma, law-makers, forum, junta, parliament, legislature, meeting place, parliamentary, assembly, president, house, legislative assembly"
    },
    "Keys": {
      "Word": 10357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烧烤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāokǎo",
      "PrimaryDefinition": "barbecue, to roast"
    },
    "Keys": {
      "Word": 8992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "pancreas; soap"
    },
    "Keys": {
      "Hanzi": 1993
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1993,
      "Radical": "肉",
      "FrequencyRank": 3238,
      "RadicalIndex": "130.6",
      "StrokeCount": 10
    }
  },
  "薯片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔpiàn",
      "PrimaryDefinition": "fried potato chips"
    },
    "Keys": {
      "Word": 5040
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "奉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèng",
      "PrimaryDefinition": "offer; receive; serve; respect"
    },
    "Keys": {
      "Hanzi": 943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.5",
      "GeneralStandard": 943,
      "Radical": "大",
      "HSK": 6,
      "FrequencyRank": 1382,
      "StrokeCount": 8
    }
  },
  "当晚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngwǎn",
      "PrimaryDefinition": "on that evening, the same evening"
    },
    "Keys": {
      "Word": 6183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "零下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língxià",
      "PrimaryDefinition": "below zero"
    },
    "Keys": {
      "Word": 851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "犄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "animal horns"
    },
    "Keys": {
      "Hanzi": 5197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5197,
      "Radical": "牛",
      "FrequencyRank": 4656,
      "RadicalIndex": "93.8",
      "StrokeCount": 12
    }
  },
  "狼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "wolf"
    },
    "Keys": {
      "Hanzi": 2005,
      "Word": 7933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.7",
      "Grade": 7,
      "GeneralStandard": 2005,
      "Radical": "犬",
      "HSK": 7,
      "FrequencyRank": 1708,
      "StrokeCount": 10
    }
  },
  "爱不释手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àibùshìshǒu",
      "PrimaryDefinition": "to love sth too much to part with it (idiom), to fondle admiringly"
    },
    "Keys": {
      "Word": 5430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "境地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngdì",
      "PrimaryDefinition": "circumstances"
    },
    "Keys": {
      "Word": 7651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瓢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piáo",
      "PrimaryDefinition": "ladle made from dried gourd"
    },
    "Keys": {
      "Hanzi": 3335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3335,
      "Radical": "瓜",
      "FrequencyRank": 3735,
      "RadicalIndex": "97.11",
      "StrokeCount": 16
    }
  },
  "霪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "long and heavy rain"
    },
    "Keys": {
      "Hanzi": 6378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6378,
      "Radical": "雨",
      "FrequencyRank": 6731,
      "RadicalIndex": "173.11",
      "StrokeCount": 19
    }
  },
  "访问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎngwèn",
      "PrimaryDefinition": "visit, call on, interview"
    },
    "Keys": {
      "Word": 1446
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "铧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá",
      "PrimaryDefinition": "spade, shovel, plowshare"
    },
    "Keys": {
      "Hanzi": 4875
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4875,
      "Radical": "金",
      "FrequencyRank": 5530,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "雨衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔyī",
      "PrimaryDefinition": "raincoat, CL:件[jian4]"
    },
    "Keys": {
      "Word": 5284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "子女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐnǚ",
      "PrimaryDefinition": "sons and daughters, children"
    },
    "Keys": {
      "Word": 2211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "始终": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐzhōng",
      "PrimaryDefinition": "all_the_time, from beginning to end, from start to finish, always, all along, evermore, throughout"
    },
    "Keys": {
      "Word": 1905
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "烨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yè",
      "PrimaryDefinition": "bright, glorious, splendid, flame"
    },
    "Keys": {
      "Hanzi": 4638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4638,
      "Radical": "火",
      "FrequencyRank": 4538,
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "遗忘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíwàng",
      "PrimaryDefinition": "misplace, bury, neglect, leave behind, escape, leave, elude, mislay, leave_behind, forget"
    },
    "Keys": {
      "Word": 10304
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng",
      "PrimaryDefinition": "see off, send off; dispatch, give"
    },
    "Keys": {
      "Hanzi": 1645,
      "Word": 347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 1,
      "GeneralStandard": 1645,
      "Radical": "辵",
      "HSK": 1,
      "FrequencyRank": 656,
      "StrokeCount": 9
    }
  },
  "相声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngsheng",
      "PrimaryDefinition": "comic dialog, sketch, crosstalk"
    },
    "Keys": {
      "Word": 4049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "用品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngpǐn",
      "PrimaryDefinition": "succedaneum, articles for use, replacer, substitute"
    },
    "Keys": {
      "Word": 5270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "宛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "seem, as if, crooked"
    },
    "Keys": {
      "Hanzi": 1284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1284,
      "Radical": "宀",
      "FrequencyRank": 2528,
      "RadicalIndex": "40.5",
      "StrokeCount": 8
    }
  },
  "杞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "willow; medlar tree; a small feudal state (Qi)"
    },
    "Keys": {
      "Hanzi": 3680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3680,
      "Radical": "木",
      "FrequencyRank": 3833,
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "惨白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎnbái",
      "PrimaryDefinition": "deathly pale"
    },
    "Keys": {
      "Word": 5762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "墙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "wall"
    },
    "Keys": {
      "Hanzi": 3054,
      "Word": 928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.11",
      "Grade": 2,
      "GeneralStandard": 3054,
      "Radical": "土",
      "HSK": 2,
      "FrequencyRank": 1212,
      "StrokeCount": 14
    }
  },
  "冬季": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngjì",
      "PrimaryDefinition": "winter"
    },
    "Keys": {
      "Word": 2419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "括号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuòhào",
      "PrimaryDefinition": "parentheses, brackets"
    },
    "Keys": {
      "Word": 2676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "会长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìzhǎng",
      "PrimaryDefinition": "president (of club/society/etc.)"
    },
    "Keys": {
      "Word": 4682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "商标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngbiāo",
      "PrimaryDefinition": "marque, mark, trade name, brand, logotype, monotype, nameplate, label, idiograph, trademark"
    },
    "Keys": {
      "Word": 3864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鄱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pó",
      "PrimaryDefinition": "county and lake in Jiangxi"
    },
    "Keys": {
      "Hanzi": 5765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5765,
      "Radical": "邑",
      "FrequencyRank": 4447,
      "RadicalIndex": "163.12",
      "StrokeCount": 14
    }
  },
  "螬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáo",
      "PrimaryDefinition": "grubs in fruit"
    },
    "Keys": {
      "Hanzi": 6231
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6231,
      "Radical": "虫",
      "FrequencyRank": 5726,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "气泡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìpào",
      "PrimaryDefinition": "bubble, blister (in metal), (of beverages) sparkling, carbonated"
    },
    "Keys": {
      "Word": 8623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8006
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8006,
      "Radical": "香",
      "FrequencyRank": 7644,
      "RadicalIndex": "186.1",
      "StrokeCount": 18
    }
  },
  "当然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngrán",
      "PrimaryDefinition": "certainly, of course"
    },
    "Keys": {
      "Word": 1395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "噪音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàoyīn",
      "PrimaryDefinition": "dissonance, row, rumour, noise, discord, squeal, discordance, mush, racket"
    },
    "Keys": {
      "Word": 10618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jià",
      "PrimaryDefinition": "to drive, sail, fly; a cart, carriage"
    },
    "Keys": {
      "Hanzi": 1332,
      "Word": 7368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.5",
      "Grade": 7,
      "GeneralStandard": 1332,
      "Radical": "馬",
      "HSK": 5,
      "FrequencyRank": 1567,
      "StrokeCount": 8
    }
  },
  "划算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huásuàn",
      "PrimaryDefinition": "to calculate, to weigh (pros and cons), to view as profitable, worthwhile, value for money, cost-effective"
    },
    "Keys": {
      "Word": 7130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迟早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chízǎo",
      "PrimaryDefinition": "sooner or later"
    },
    "Keys": {
      "Word": 5903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "miserly, parsimonious, stingy"
    },
    "Keys": {
      "Hanzi": 4660
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4660,
      "Radical": "心",
      "FrequencyRank": 5480,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "肮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āng",
      "PrimaryDefinition": "dirty"
    },
    "Keys": {
      "Hanzi": 1187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1187,
      "Radical": "肉",
      "FrequencyRank": 3194,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "姿势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīshì",
      "PrimaryDefinition": "mannerism, plant, poise, motion, pose, attitude, sign, affectation, port, likeness, carriage, posture, position, setup, gesticulation, gesture, seat"
    },
    "Keys": {
      "Word": 10943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "重视": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòngshì",
      "PrimaryDefinition": "attach importance to, take sth. seriously, value"
    },
    "Keys": {
      "Word": 1228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "獯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "a tribe of Scythians which invaded China during the Hsia dynasty"
    },
    "Keys": {
      "Hanzi": 7969
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7969,
      "Radical": "犬",
      "FrequencyRank": 5411,
      "RadicalIndex": "94.14",
      "StrokeCount": 17
    }
  },
  "冰雪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīngxuě",
      "PrimaryDefinition": "ice and snow"
    },
    "Keys": {
      "Word": 2275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "开花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi huā",
      "PrimaryDefinition": "burst forth, flower, bloom, split apart, explode, blossom, effloresce, unfold, feel elated, break apart, blow"
    },
    "Keys": {
      "Word": 2653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "为了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèile",
      "PrimaryDefinition": "in order to, for the purpose of, so as to"
    },
    "Keys": {
      "Word": 2009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "动脉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngmài",
      "PrimaryDefinition": "artery"
    },
    "Keys": {
      "Word": 6335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讲话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnghuà",
      "PrimaryDefinition": "parley, jaw, turn to, address, talk, speak, say, speak to"
    },
    "Keys": {
      "Word": 762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "垯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "da",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6768,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "自觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìjué",
      "PrimaryDefinition": "be conscious/aware"
    },
    "Keys": {
      "Word": 2214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "陶瓷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táocí",
      "PrimaryDefinition": "pottery and porcelain, ceramic, ceramics, china"
    },
    "Keys": {
      "Word": 9436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "圾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "garbage, rubbish; shaking; danger"
    },
    "Keys": {
      "Hanzi": 362
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "GeneralStandard": 362,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 2634,
      "StrokeCount": 6
    }
  },
  "客人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kèren",
      "PrimaryDefinition": "stranger, guest, caller, client, visitant, invitee, company, visitor"
    },
    "Keys": {
      "Word": 823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "恶劣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èliè",
      "PrimaryDefinition": "vile, nasty"
    },
    "Keys": {
      "Word": 6413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6841
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6841,
      "Radical": "山",
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "迅速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùnsù",
      "PrimaryDefinition": "fast, speedy, rapid, prompt"
    },
    "Keys": {
      "Word": 3057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "哗然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huárán",
      "PrimaryDefinition": "in uproar, commotion, causing a storm of protest, tumultuous, variant of 嘩然|哗然[hua2 ran2]"
    },
    "Keys": {
      "Word": 7134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "代表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàibiǎo",
      "PrimaryDefinition": "representative, delegate"
    },
    "Keys": {
      "Word": 1388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "spirit"
    },
    "Keys": {
      "Radical": 113,
      "Hanzi": 194
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "113",
      "GeneralStandard": 194,
      "Radical": "示",
      "HSK": 2,
      "FrequencyRank": 425,
      "StrokeCount": 5
    }
  },
  "解决": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiějué",
      "PrimaryDefinition": "solve, resolve, settle, dispose of, finish off"
    },
    "Keys": {
      "Word": 1621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "黢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "black"
    },
    "Keys": {
      "Hanzi": 6394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6394,
      "Radical": "黑",
      "FrequencyRank": 5569,
      "RadicalIndex": "203.7",
      "StrokeCount": 19
    }
  },
  "珩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háng",
      "PrimaryDefinition": "the top gem of the pendants"
    },
    "Keys": {
      "Hanzi": 4405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4405,
      "Radical": "玉",
      "FrequencyRank": 5315,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "出事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū shì",
      "PrimaryDefinition": "to have an accident, to meet with a mishap"
    },
    "Keys": {
      "Word": 4430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "守株待兔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuzhū-dàitù",
      "PrimaryDefinition": "lit. to guard a tree-stump, waiting for rabbits (idiom), to wait idly for opportunities, to trust to chance rather than show initiative"
    },
    "Keys": {
      "Word": 9185
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大家庭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjiātíng",
      "PrimaryDefinition": "extended family"
    },
    "Keys": {
      "Word": 6119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凌晨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língchén",
      "PrimaryDefinition": "very early in the morning, in the wee hours"
    },
    "Keys": {
      "Word": 8071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnggào",
      "PrimaryDefinition": "declared, placard, declare, proclaim, herald, announce, bill, decree, advertise"
    },
    "Keys": {
      "Word": 3502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "镂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòu",
      "PrimaryDefinition": "carve, inlay, engrave, tattoo"
    },
    "Keys": {
      "Hanzi": 5740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5740,
      "Radical": "金",
      "FrequencyRank": 4301,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "担保": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānbǎo",
      "PrimaryDefinition": "to guarantee, to vouch for"
    },
    "Keys": {
      "Word": 2385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "好看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎokàn",
      "PrimaryDefinition": "embarrassed, interesting, nice, good-looking, delightful"
    },
    "Keys": {
      "Word": 138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "龌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "narrow, small; dirty"
    },
    "Keys": {
      "Hanzi": 6221
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6221,
      "Radical": "齒",
      "FrequencyRank": 4509,
      "RadicalIndex": "211.9",
      "StrokeCount": 17
    }
  },
  "寻找": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnzhǎo",
      "PrimaryDefinition": "comb, locate, quest, search, scout, chase, reconnoiter, look_around, look_for, look, look for, find_out, pick, reconnoitre, prospect, hunt, seek, rummage, shop"
    },
    "Keys": {
      "Word": 3055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "嵯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuó",
      "PrimaryDefinition": "high, towering; irregular, rugged"
    },
    "Keys": {
      "Hanzi": 5172
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5172,
      "Radical": "山",
      "FrequencyRank": 5954,
      "RadicalIndex": "46.1",
      "StrokeCount": 12
    }
  },
  "丝绸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīchóu",
      "PrimaryDefinition": "silk cloth, silk"
    },
    "Keys": {
      "Word": 9305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "起到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐdào",
      "PrimaryDefinition": "Play"
    },
    "Keys": {
      "Word": 3792
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "公元": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyuán",
      "PrimaryDefinition": "CE (Common Era), Christian Era, AD (Anno Domini)"
    },
    "Keys": {
      "Word": 2505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "休息": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūxi",
      "PrimaryDefinition": "repose, have, lie dormant, respire, take time off, have a rest, take a rest, take off, relax, take_time_off, have/take a rest, rest"
    },
    "Keys": {
      "Word": 414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "住": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "reside, live at, dwell, lodge; stop"
    },
    "Keys": {
      "Hanzi": 783,
      "Word": 482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 1,
      "GeneralStandard": 783,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 309,
      "StrokeCount": 7
    }
  },
  "月球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèqiú",
      "PrimaryDefinition": "the moon"
    },
    "Keys": {
      "Word": 4165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "颤抖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàndǒu",
      "PrimaryDefinition": "to shudder, to shiver, to shake, to tremble"
    },
    "Keys": {
      "Word": 5807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīwèi",
      "PrimaryDefinition": "blindly, stubbornly"
    },
    "Keys": {
      "Word": 10295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "针对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnduì",
      "PrimaryDefinition": "in the light of, in connection with"
    },
    "Keys": {
      "Word": 3161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "quick, prompt, speedy"
    },
    "Keys": {
      "Hanzi": 1853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "GeneralStandard": 1853,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 617,
      "StrokeCount": 10
    }
  },
  "归属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīshǔ",
      "PrimaryDefinition": "belong to"
    },
    "Keys": {
      "Word": 6913
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "收视率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōushìlǜ",
      "PrimaryDefinition": "ratings"
    },
    "Keys": {
      "Word": 9170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6659
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6659,
      "Radical": "艸",
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "污秽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūhuì",
      "PrimaryDefinition": "nasty, sordid, filthy"
    },
    "Keys": {
      "Word": 9755
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "福气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúqi",
      "PrimaryDefinition": "good fortune, a blessing"
    },
    "Keys": {
      "Word": 6609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "正常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngcháng",
      "PrimaryDefinition": "normal, regular"
    },
    "Keys": {
      "Word": 1212
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "怀疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáiyí",
      "PrimaryDefinition": "suspect, doubt"
    },
    "Keys": {
      "Word": 2555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "野蛮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěmán",
      "PrimaryDefinition": "barbarous, uncivilized"
    },
    "Keys": {
      "Word": 10257
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不仅仅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjǐnjǐn",
      "PrimaryDefinition": "not only"
    },
    "Keys": {
      "Word": 4356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "授": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu",
      "PrimaryDefinition": "give to, transmit; confer"
    },
    "Keys": {
      "Hanzi": 2177
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2177,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 968,
      "StrokeCount": 11
    }
  },
  "相辅相成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngfǔ-xiāngchéng",
      "PrimaryDefinition": "to complement one another (idiom)"
    },
    "Keys": {
      "Word": 9923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "属": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔ",
      "PrimaryDefinition": "class, category, type; belong to"
    },
    "Keys": {
      "Hanzi": 2800,
      "Word": 1936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "44.9",
      "Grade": 3,
      "GeneralStandard": 2800,
      "Radical": "尸",
      "HSK": 3,
      "FrequencyRank": 610,
      "StrokeCount": 12
    }
  },
  "并非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngfēi",
      "PrimaryDefinition": "really isn't"
    },
    "Keys": {
      "Word": 5653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "局长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "júzhǎng",
      "PrimaryDefinition": "chief, head of bureau, director (of...), superintendent, commissioner, director"
    },
    "Keys": {
      "Word": 3637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "安置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānzhì",
      "PrimaryDefinition": "find place for, help settle down, arrange for"
    },
    "Keys": {
      "Word": 2234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "小型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎoxíng",
      "PrimaryDefinition": "spyglass, miniature, small-sized, bar, small-scale, pettiness"
    },
    "Keys": {
      "Word": 3030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "幼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "infant, young child; immature"
    },
    "Keys": {
      "Hanzi": 346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "52.2",
      "GeneralStandard": 346,
      "Radical": "幺",
      "HSK": 4,
      "FrequencyRank": 1577,
      "StrokeCount": 5
    }
  },
  "桌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuō",
      "PrimaryDefinition": "table, desk, stand"
    },
    "Keys": {
      "Hanzi": 1881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 1881,
      "Radical": "木",
      "HSK": 1,
      "FrequencyRank": 1193,
      "StrokeCount": 10
    }
  },
  "务实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùshí",
      "PrimaryDefinition": "deal with concrete matters relating to work, try to be practical/pragmatic, pragmatic, try to be pragmatic, try to be practical, deal with concrete matters"
    },
    "Keys": {
      "Word": 9806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "raise, hold high; fly, soar"
    },
    "Keys": {
      "Hanzi": 5592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5592,
      "Radical": "馬",
      "FrequencyRank": 4815,
      "RadicalIndex": "187.1",
      "StrokeCount": 13
    }
  },
  "主观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔguān",
      "PrimaryDefinition": "subjective"
    },
    "Keys": {
      "Word": 4250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "双赢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāngyíng",
      "PrimaryDefinition": "profitable to both sides, a win-win situation"
    },
    "Keys": {
      "Word": 9255
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "看待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàndài",
      "PrimaryDefinition": "apprehend, treatment, treat, look upon, regard"
    },
    "Keys": {
      "Word": 3648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "券": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quàn",
      "PrimaryDefinition": "certificate, ticket; title deeds"
    },
    "Keys": {
      "Hanzi": 1230,
      "Word": 4963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 6,
      "GeneralStandard": 1230,
      "Radical": "刀",
      "HSK": 6,
      "FrequencyRank": 1481,
      "StrokeCount": 8
    }
  },
  "愈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "more and more, even more"
    },
    "Keys": {
      "Hanzi": 2966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2966,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1301,
      "StrokeCount": 13
    }
  },
  "簋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "a square basket of bamboo for holding grain used at sacrifices, feast"
    },
    "Keys": {
      "Hanzi": 6259
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6259,
      "Radical": "竹",
      "FrequencyRank": 6715,
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "由此看来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóucǐkànlái",
      "PrimaryDefinition": "It seems that it seems"
    },
    "Keys": {
      "Word": 10458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": "francium"
    },
    "Keys": {
      "Hanzi": 4245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4245,
      "Radical": "金",
      "FrequencyRank": 5939,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "古典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔdiǎn",
      "PrimaryDefinition": "classical"
    },
    "Keys": {
      "Word": 4618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "通风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngfēng",
      "PrimaryDefinition": "airy, ventilation, to ventilate, to disclose information"
    },
    "Keys": {
      "Word": 9523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挣扎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngzhá",
      "PrimaryDefinition": "pull, buffet, flounder, labour, scrabble, struggle, worry, twist, grapple, tug, baffle, touse, wallow, flounce, writhe, welter, agonize"
    },
    "Keys": {
      "Word": 10727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "language, words; saying, expression"
    },
    "Keys": {
      "Hanzi": 1707
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "GeneralStandard": 1707,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 493,
      "StrokeCount": 9
    }
  },
  "栽培": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāipéi",
      "PrimaryDefinition": "cultivate, grow, foster, train, educate, give/receive patronage"
    },
    "Keys": {
      "Word": 10587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "取缔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔdì",
      "PrimaryDefinition": "ban (publication old custom etc.), punish violator"
    },
    "Keys": {
      "Word": 8787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "株": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "numerary adjunct for trees; root"
    },
    "Keys": {
      "Hanzi": 1840,
      "Word": 10845
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 7,
      "GeneralStandard": 1840,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2482,
      "StrokeCount": 10
    }
  },
  "倦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juàn",
      "PrimaryDefinition": "be tired of, weary"
    },
    "Keys": {
      "Hanzi": 1968
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "GeneralStandard": 1968,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 2329,
      "StrokeCount": 10
    }
  },
  "不懈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùxiè",
      "PrimaryDefinition": "untiring, unremitting, indefatigable"
    },
    "Keys": {
      "Word": 5682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "教堂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàotáng",
      "PrimaryDefinition": "tabernacle, kirk, house_of_God, church, temple, fane, cathedral, church building, bema"
    },
    "Keys": {
      "Word": 4723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "澼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "bleach; wash, clean"
    },
    "Keys": {
      "Hanzi": 7908
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7908,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "晚间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnjiān",
      "PrimaryDefinition": "(in the) evening, night, in the evening, evening, (at) night, at night"
    },
    "Keys": {
      "Word": 9657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "浓重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngzhòng",
      "PrimaryDefinition": "dense, thick, strong, rich (colors), heavy (aroma), deep (friendship), profound (effect)"
    },
    "Keys": {
      "Word": 8441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "多样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōyàng",
      "PrimaryDefinition": "diverse, multiform, various"
    },
    "Keys": {
      "Word": 2440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "垠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "boundary, bank of stream or river"
    },
    "Keys": {
      "Hanzi": 4107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4107,
      "Radical": "土",
      "FrequencyRank": 4331,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "称号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēnghào",
      "PrimaryDefinition": "name, term of address, title"
    },
    "Keys": {
      "Word": 3321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "廙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7715,
      "Radical": "广",
      "RadicalIndex": "53.12",
      "StrokeCount": 14
    }
  },
  "豆腐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòufu",
      "PrimaryDefinition": "tofu, bean curd"
    },
    "Keys": {
      "Word": 2421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "畿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "imperial domain; area near capital"
    },
    "Keys": {
      "Hanzi": 6025
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6025,
      "Radical": "田",
      "FrequencyRank": 4401,
      "RadicalIndex": "102.1",
      "StrokeCount": 15
    }
  },
  "旋律": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánlǜ",
      "PrimaryDefinition": "air, chant, canto, melodic line, rhythm, strain, melodic phrase, melodic, melody, line, aria, movement, cantus, tonal pattern, tune, descant, note"
    },
    "Keys": {
      "Word": 10122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "条例": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáolì",
      "PrimaryDefinition": "rules, regulation, enactment, regulations, ordonnance, ordinance, statute, act, prescription, ordinances"
    },
    "Keys": {
      "Word": 9497
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "castrate; eunuch"
    },
    "Keys": {
      "Hanzi": 4947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4947,
      "Radical": "門",
      "FrequencyRank": 3814,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "提议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíyì",
      "PrimaryDefinition": "propose, suggest"
    },
    "Keys": {
      "Word": 9461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隐私": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnsī",
      "PrimaryDefinition": "secrets, private business, privacy"
    },
    "Keys": {
      "Word": 5256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "场所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngsuǒ",
      "PrimaryDefinition": "site, digging, terrain, locality, point, arena, scene, room, locus, location, locale, stead, scene of action, lieu, amenity, meridian, where, spot, space, land site, place, seat"
    },
    "Keys": {
      "Word": 1339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "赛车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàichē",
      "PrimaryDefinition": "auto race, cycle race, race car"
    },
    "Keys": {
      "Word": 8915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海拔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎibá",
      "PrimaryDefinition": "natural elevation, ALT, height above sea level, altitude, sea_level, height, EL, above sea level, sea level, elevation"
    },
    "Keys": {
      "Word": 6961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "半数": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànshù",
      "PrimaryDefinition": "half, half (the number), det.: half"
    },
    "Keys": {
      "Word": 5495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剧本": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùběn",
      "PrimaryDefinition": "part, playbook, drama, script, play, scenario, libretto"
    },
    "Keys": {
      "Word": 3640
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "录音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùyīn",
      "PrimaryDefinition": "record sound"
    },
    "Keys": {
      "Word": 1722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎn",
      "PrimaryDefinition": "open, unfold; stretch, extend"
    },
    "Keys": {
      "Hanzi": 2131
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.7",
      "GeneralStandard": 2131,
      "Radical": "尸",
      "HSK": 3,
      "FrequencyRank": 275,
      "StrokeCount": 10
    }
  },
  "牧民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùmín",
      "PrimaryDefinition": "herdsman"
    },
    "Keys": {
      "Word": 8355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòu",
      "PrimaryDefinition": "memorialize emperor; report"
    },
    "Keys": {
      "Hanzi": 1354,
      "Word": 5405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.6",
      "Grade": 6,
      "GeneralStandard": 1354,
      "Radical": "大",
      "HSK": 6,
      "FrequencyRank": 1543,
      "StrokeCount": 9
    }
  },
  "奇花异草": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíhuāyìcǎo",
      "PrimaryDefinition": "very rarely seen, unusual (idiom)"
    },
    "Keys": {
      "Word": 8596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "虚拟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūnǐ",
      "PrimaryDefinition": "conjecture, fictitious, pseudo, suppositional, fantasy"
    },
    "Keys": {
      "Word": 10092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "午睡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔshuì",
      "PrimaryDefinition": "to take a nap, siesta"
    },
    "Keys": {
      "Word": 1068
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "挖苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wāku",
      "PrimaryDefinition": "to speak sarcastically, to make cutting remarks"
    },
    "Keys": {
      "Word": 9631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāi",
      "PrimaryDefinition": "sieve, filter, screen; sift"
    },
    "Keys": {
      "Hanzi": 2684,
      "Word": 8942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 7,
      "GeneralStandard": 2684,
      "Radical": "竹",
      "HSK": 8,
      "FrequencyRank": 3388,
      "StrokeCount": 12
    }
  },
  "盐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "salt"
    },
    "Keys": {
      "Hanzi": 1790,
      "Word": 3070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.5",
      "Grade": 4,
      "GeneralStandard": 1790,
      "Radical": "皿",
      "HSK": 4,
      "FrequencyRank": 1885,
      "StrokeCount": 10
    }
  },
  "抨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēng",
      "PrimaryDefinition": "impeach, censure; attack"
    },
    "Keys": {
      "Hanzi": 3837
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "GeneralStandard": 3837,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3397,
      "StrokeCount": 8
    }
  },
  "记忆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìyì",
      "PrimaryDefinition": "memory"
    },
    "Keys": {
      "Word": 3580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "灯笼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dēnglong",
      "PrimaryDefinition": "lantern"
    },
    "Keys": {
      "Word": 6219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "容易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngyì",
      "PrimaryDefinition": "easy"
    },
    "Keys": {
      "Word": 1861
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "硊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7149,
      "Radical": "石",
      "FrequencyRank": 9069,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "招待会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāodàihuì",
      "PrimaryDefinition": "reception, CL:個|个[ge4],次[ci4]"
    },
    "Keys": {
      "Word": 10662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爱情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àiqíng",
      "PrimaryDefinition": "god, warmheartedness, affection, love_affair, heart, kindness, tenderness, bosom, love, fondness, romantic love, affectionateness"
    },
    "Keys": {
      "Word": 499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "桢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "hardwood; supports, posts"
    },
    "Keys": {
      "Hanzi": 4453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4453,
      "Radical": "木",
      "FrequencyRank": 3730,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "攒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎn",
      "PrimaryDefinition": "save, hoard"
    },
    "Keys": {
      "Hanzi": 6368,
      "Word": 10596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.16",
      "Grade": 7,
      "GeneralStandard": 6368,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3548,
      "StrokeCount": 19
    }
  },
  "烟火": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yānhuo",
      "PrimaryDefinition": "smoke and fire, fireworks"
    },
    "Keys": {
      "Word": 10168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "dance, posture, prance; brandish"
    },
    "Keys": {
      "Hanzi": 3116,
      "Word": 4017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "136.8",
      "Grade": 5,
      "GeneralStandard": 3116,
      "Radical": "舛",
      "HSK": 3,
      "FrequencyRank": 1144,
      "StrokeCount": 14
    }
  },
  "还有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háiyǒu",
      "PrimaryDefinition": "conj.: furthermore, in addition"
    },
    "Keys": {
      "Word": 131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "趁着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chènzhe",
      "PrimaryDefinition": "while"
    },
    "Keys": {
      "Word": 5864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "血管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuèguǎn",
      "PrimaryDefinition": "afferent, vas, vena, blood_vessel, vessel, vein, blood vessel"
    },
    "Keys": {
      "Word": 5205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "辛酸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnsuān",
      "PrimaryDefinition": "pungent (taste), bitter, fig. sad, miserable"
    },
    "Keys": {
      "Word": 10021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "reeds, rushes, grass; surname"
    },
    "Keys": {
      "Hanzi": 1016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1016,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2339,
      "StrokeCount": 8
    }
  },
  "阎王": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yánwang",
      "PrimaryDefinition": "same as 閻羅王|阎罗王, Yama, King of Hell, translation of Sanskrit: Yama Raja"
    },
    "Keys": {
      "Word": 10186
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遇到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù dào",
      "PrimaryDefinition": "come across, run_into, receive, meet with, encounter, run into, meet, befall"
    },
    "Keys": {
      "Word": 3130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "莜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "Avena nuda; a bamboo basket"
    },
    "Keys": {
      "Hanzi": 4434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4434,
      "Radical": "艸",
      "FrequencyRank": 5233,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "大楼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàlóu",
      "PrimaryDefinition": "mansion, massif, hall, block, manse, edifice, building, multi-storied building, residence, mansion house"
    },
    "Keys": {
      "Word": 2374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "黑手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēishǒu",
      "PrimaryDefinition": "Blackman"
    },
    "Keys": {
      "Word": 7061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiè",
      "PrimaryDefinition": "warn, caution, admonish"
    },
    "Keys": {
      "Hanzi": 606,
      "Word": 3622
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.3",
      "Grade": 5,
      "GeneralStandard": 606,
      "Radical": "戈",
      "HSK": 5,
      "FrequencyRank": 1566,
      "StrokeCount": 7
    }
  },
  "鏊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "a flat, iron cooking-plate for cakes"
    },
    "Keys": {
      "Hanzi": 6310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6310,
      "Radical": "金",
      "FrequencyRank": 5309,
      "RadicalIndex": "167.11",
      "StrokeCount": 18
    }
  },
  "惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "pity, regret, rue, begrudge"
    },
    "Keys": {
      "Hanzi": 2439
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2439,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1512,
      "StrokeCount": 11
    }
  },
  "治理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìlǐ",
      "PrimaryDefinition": "administer, govern, bring under control, put in order"
    },
    "Keys": {
      "Word": 4239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "獐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "roebuck, hornless river deer"
    },
    "Keys": {
      "Hanzi": 5773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5773,
      "Radical": "犬",
      "FrequencyRank": 5379,
      "RadicalIndex": "94.11",
      "StrokeCount": 14
    }
  },
  "这儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèr",
      "PrimaryDefinition": "now, here"
    },
    "Keys": {
      "Word": 464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "劬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "be diligent, toil, endeavor"
    },
    "Keys": {
      "Hanzi": 3745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3745,
      "Radical": "力",
      "FrequencyRank": 5106,
      "RadicalIndex": "19.5",
      "StrokeCount": 7
    }
  },
  "柠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "lemon"
    },
    "Keys": {
      "Hanzi": 1438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1438,
      "Radical": "木",
      "FrequencyRank": 4252,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "伴侣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànlǚ",
      "PrimaryDefinition": "companion, mate, helpmate, coachfellow, familiar, partner, fere, company"
    },
    "Keys": {
      "Word": 5501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "举动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔdòng",
      "PrimaryDefinition": "behaviour, demeanor, dealing, deportment, motion, proceeding, conduct, behavior, demeanour, movement, comportment, action, move"
    },
    "Keys": {
      "Word": 3641
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "矫正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎozhèng",
      "PrimaryDefinition": "to correct, to rectify (e.g. a physical defect such as hearing or vision), to cure, rectification, correction, to straighten"
    },
    "Keys": {
      "Word": 7481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "透气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòu qì",
      "PrimaryDefinition": "to flow freely (of air), to ventilate, to breathe (of fabric etc), to take a breath of fresh air, to divulge"
    },
    "Keys": {
      "Word": 9570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "steep, sheer; rugged, rough"
    },
    "Keys": {
      "Hanzi": 753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 753,
      "Radical": "山",
      "FrequencyRank": 4065,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "猡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "pig; Lolo aboringinal tribe"
    },
    "Keys": {
      "Hanzi": 4926
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4926,
      "Radical": "犬",
      "FrequencyRank": 6045,
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "皕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7339,
      "Radical": "白",
      "RadicalIndex": "106.7",
      "StrokeCount": 12
    }
  },
  "肥胖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féipàng",
      "PrimaryDefinition": "fat, corpulent"
    },
    "Keys": {
      "Word": 6517
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "婵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "beautiful, lovely, pretty, graceful"
    },
    "Keys": {
      "Hanzi": 5021
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5021,
      "Radical": "女",
      "FrequencyRank": 3970,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "龄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "age; years"
    },
    "Keys": {
      "Hanzi": 2893
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "211.5",
      "GeneralStandard": 2893,
      "Radical": "齒",
      "HSK": 5,
      "FrequencyRank": 1510,
      "StrokeCount": 13
    }
  },
  "蜗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "a snail, Eulota callizoma"
    },
    "Keys": {
      "Hanzi": 2923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2923,
      "Radical": "虫",
      "FrequencyRank": 3723,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "外观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiguān",
      "PrimaryDefinition": "shell, outward appearance, show, externality, visual aspect, exterior, effect, superficies, look, likeness, appearance, facade, surface, presentment, apparel, impression, facies, aspect, complexion, resemblance, semblance, guise, frontage, outwardness, rind, garb, showing, face, veneer, outside, garment, getup"
    },
    "Keys": {
      "Word": 5121
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "暅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7521,
      "Radical": "日",
      "RadicalIndex": "72.9",
      "StrokeCount": 13
    }
  },
  "挫折": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòzhé",
      "PrimaryDefinition": "frustratio, rebuff, defeat, founder, frustration, reverse, breakdown, blight, setback, throwback, abortion, backset, flivver, dash, cross"
    },
    "Keys": {
      "Word": 6088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "修养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūyǎng",
      "PrimaryDefinition": "accomplishment, training, mastery, self-cultivation"
    },
    "Keys": {
      "Word": 4074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "庠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiáng",
      "PrimaryDefinition": "village school; teach"
    },
    "Keys": {
      "Hanzi": 4312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4312,
      "Radical": "广",
      "FrequencyRank": 4537,
      "RadicalIndex": "53.6",
      "StrokeCount": 9
    }
  },
  "艎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "fast boat"
    },
    "Keys": {
      "Hanzi": 7796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7796,
      "Radical": "舟",
      "FrequencyRank": 8532,
      "RadicalIndex": "137.9",
      "StrokeCount": 15
    }
  },
  "高速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāosù",
      "PrimaryDefinition": "great speed, velocity, high speed, high"
    },
    "Keys": {
      "Word": 1481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "貉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "badger; raccoon dog"
    },
    "Keys": {
      "Hanzi": 5515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5515,
      "Radical": "豸",
      "FrequencyRank": 5468,
      "RadicalIndex": "153.6",
      "StrokeCount": 13
    }
  },
  "嚄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō",
      "PrimaryDefinition": "roar"
    },
    "Keys": {
      "Hanzi": 6087
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6087,
      "Radical": "口",
      "RadicalIndex": "30.14",
      "StrokeCount": 16
    }
  },
  "播出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō chū",
      "PrimaryDefinition": "broadcast, disseminate"
    },
    "Keys": {
      "Word": 1310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "宝贝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎobèi",
      "PrimaryDefinition": "treasured object, treasure, darling, baby, cowry, good-for-nothing, odd character"
    },
    "Keys": {
      "Word": 2250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "战友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànyǒu",
      "PrimaryDefinition": "comrade-in-arms, battle companion"
    },
    "Keys": {
      "Word": 5322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "看台": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàntái",
      "PrimaryDefinition": "terrace, spectator's grandstand, viewing platform"
    },
    "Keys": {
      "Word": 7773
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "初次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūcì",
      "PrimaryDefinition": "for the first time, first (meeting, attempt etc)"
    },
    "Keys": {
      "Word": 5980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fènliang",
      "PrimaryDefinition": "(vector) component, quantity, weight, measure"
    },
    "Keys": {
      "Word": 6552
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": "play with, joke, enjoy"
    },
    "Keys": {
      "Hanzi": 944
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.4",
      "GeneralStandard": 944,
      "Radical": "玉",
      "HSK": 1,
      "FrequencyRank": 1072,
      "StrokeCount": 8
    }
  },
  "逖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "far, distant; keep at distance"
    },
    "Keys": {
      "Hanzi": 4606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4606,
      "Radical": "辵",
      "FrequencyRank": 5257,
      "RadicalIndex": "162.7",
      "StrokeCount": 10
    }
  },
  "镁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měi",
      "PrimaryDefinition": "magnesium"
    },
    "Keys": {
      "Hanzi": 5739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5739,
      "Radical": "金",
      "FrequencyRank": 4023,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "负有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùyǒu",
      "PrimaryDefinition": "to be responsible for"
    },
    "Keys": {
      "Word": 6625
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "单一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dānyī",
      "PrimaryDefinition": "one, unitary, singular, monolithic, single"
    },
    "Keys": {
      "Word": 3396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "米饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐfàn",
      "PrimaryDefinition": "(cooked) rice"
    },
    "Keys": {
      "Word": 238
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "罾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": "a large square net, lowered and raised from the bank of the river"
    },
    "Keys": {
      "Hanzi": 6239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6239,
      "Radical": "网",
      "FrequencyRank": 5786,
      "RadicalIndex": "122.12",
      "StrokeCount": 17
    }
  },
  "地毯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìtǎn",
      "PrimaryDefinition": "carpet, footcloth, rug, carpeting, tapis"
    },
    "Keys": {
      "Word": 6250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "提早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tízǎo",
      "PrimaryDefinition": "earlier than planned or expected, in advance, shift to an earlier time"
    },
    "Keys": {
      "Word": 9462
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一动不动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdòngbùdòng",
      "PrimaryDefinition": "motionless"
    },
    "Keys": {
      "Word": 10283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "空儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòngr5",
      "PrimaryDefinition": "spare time, free time"
    },
    "Keys": {
      "Word": 1679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "掩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "to cover (with the hand); shut, conceal; ambush"
    },
    "Keys": {
      "Hanzi": 2166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2166,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1514,
      "StrokeCount": 11
    }
  },
  "水壶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐhú",
      "PrimaryDefinition": "kettle, canteen, watering can"
    },
    "Keys": {
      "Word": 9259
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谈起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tánqǐ",
      "PrimaryDefinition": "speak of, talk about, mention"
    },
    "Keys": {
      "Word": 9407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "濯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "wash out, rinse; cleanse"
    },
    "Keys": {
      "Hanzi": 6297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6297,
      "Radical": "水",
      "FrequencyRank": 4757,
      "RadicalIndex": "85.14",
      "StrokeCount": 17
    }
  },
  "婷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "pretty; attractive; graceful"
    },
    "Keys": {
      "Hanzi": 5322
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5322,
      "Radical": "女",
      "FrequencyRank": 3653,
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "四": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "four"
    },
    "Keys": {
      "Hanzi": 262,
      "Word": 348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.2",
      "Grade": 1,
      "GeneralStandard": 262,
      "Radical": "囗",
      "HSK": 1,
      "FrequencyRank": 226,
      "StrokeCount": 5
    }
  },
  "阻力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔlì",
      "PrimaryDefinition": "resistance, drag, impedimenta, impediment, obstruction, traction, pullback, retarding force"
    },
    "Keys": {
      "Word": 11005
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "驶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "sail, drive, pilot; fast, quick"
    },
    "Keys": {
      "Hanzi": 1341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.5",
      "GeneralStandard": 1341,
      "Radical": "馬",
      "HSK": 5,
      "FrequencyRank": 1520,
      "StrokeCount": 8
    }
  },
  "腱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "tendons"
    },
    "Keys": {
      "Hanzi": 5234
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5234,
      "Radical": "肉",
      "FrequencyRank": 5022,
      "RadicalIndex": "130.9",
      "StrokeCount": 12
    }
  },
  "专长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāncháng",
      "PrimaryDefinition": "department, excellence, metier, knowledge, speciality, specialty, specialism, specialisation, special skill/knowledge, special knowledge, adept, expertise, special skill, medium, forte, specialization"
    },
    "Keys": {
      "Word": 10878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shídài",
      "PrimaryDefinition": "day, period, a period in one's life, epoch, present time, historical period, time, chapter, period in one's life, modern times, historic period, contemporary world, times, era, modern world, age"
    },
    "Keys": {
      "Word": 1897
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "负担": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùdān",
      "PrimaryDefinition": "burden, load, encumbrance"
    },
    "Keys": {
      "Word": 2482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "超出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo chū",
      "PrimaryDefinition": "to exceed, to overstep, to go too far, to encroach"
    },
    "Keys": {
      "Word": 4399
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "好坏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎo-huài",
      "PrimaryDefinition": "good or bad, good and bad, standard, quality"
    },
    "Keys": {
      "Word": 7006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔtǐ",
      "PrimaryDefinition": "main body, body, hypostasis, main body/part, subject, principal part, corpus, main part, part"
    },
    "Keys": {
      "Word": 4251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "收益": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōuyì",
      "PrimaryDefinition": "earnings, profit"
    },
    "Keys": {
      "Word": 2874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "防护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánghù",
      "PrimaryDefinition": "to defend, to protect"
    },
    "Keys": {
      "Word": 6489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "封": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "letter, envelope; feudal"
    },
    "Keys": {
      "Hanzi": 1366,
      "Word": 3465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "41.6",
      "Grade": 5,
      "GeneralStandard": 1366,
      "Radical": "寸",
      "HSK": 2,
      "FrequencyRank": 871,
      "StrokeCount": 9
    }
  },
  "奶牛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎiniú",
      "PrimaryDefinition": "milk cow, dairy cow"
    },
    "Keys": {
      "Word": 4887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "简单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎndān",
      "PrimaryDefinition": "terse, casual, commonplace, simple, oversimplified, ordinary, uncomplicated"
    },
    "Keys": {
      "Word": 1597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "投机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóujī",
      "PrimaryDefinition": "speculate, be opportunistic"
    },
    "Keys": {
      "Word": 9563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "a chemical compound"
    },
    "Keys": {
      "Hanzi": 3979
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3979,
      "Radical": "肉",
      "FrequencyRank": 4515,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "蹐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "take short steps"
    },
    "Keys": {
      "Hanzi": 7934
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7934,
      "Radical": "足",
      "FrequencyRank": 6984,
      "RadicalIndex": "157.1",
      "StrokeCount": 17
    }
  },
  "公共场所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōnggòng chǎngsuǒ",
      "PrimaryDefinition": "Public places"
    },
    "Keys": {
      "Word": 6762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "簃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "side room"
    },
    "Keys": {
      "Hanzi": 7952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7952,
      "Radical": "竹",
      "FrequencyRank": 8450,
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "溥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔ",
      "PrimaryDefinition": "big, great, vast, wide; widespread"
    },
    "Keys": {
      "Hanzi": 5571
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5571,
      "Radical": "水",
      "FrequencyRank": 3882,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "节假日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiéjiàrì",
      "PrimaryDefinition": "public holiday"
    },
    "Keys": {
      "Word": 4729
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "大巴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàbā",
      "PrimaryDefinition": "a big coach, tourist bus"
    },
    "Keys": {
      "Word": 2368
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "受伤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu shāng",
      "PrimaryDefinition": "injure, bruise, injured, be wounded, crush, hurt, sustain an injury, be injured, wounded, maimed, wound"
    },
    "Keys": {
      "Word": 1930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "衷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 2020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.4",
      "GeneralStandard": 2020,
      "Radical": "衣",
      "HSK": 8,
      "FrequencyRank": 2157,
      "StrokeCount": 10
    }
  },
  "兜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōu",
      "PrimaryDefinition": "pouch"
    },
    "Keys": {
      "Hanzi": 2336,
      "Word": 6344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.9",
      "Grade": 7,
      "GeneralStandard": 2336,
      "Radical": "儿",
      "HSK": 8,
      "FrequencyRank": 2512,
      "StrokeCount": 11
    }
  },
  "臣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "minister"
    },
    "Keys": {
      "Radical": 131,
      "Hanzi": 380
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "131",
      "GeneralStandard": 380,
      "Radical": "臣",
      "HSK": 8,
      "FrequencyRank": 1138,
      "StrokeCount": 6
    }
  },
  "汈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6516
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6516,
      "Radical": "水",
      "RadicalIndex": "85.2",
      "StrokeCount": 5
    }
  },
  "姥爷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎoye",
      "PrimaryDefinition": "maternal grandfather (dialectal)"
    },
    "Keys": {
      "Word": 7954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "认识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènshi",
      "PrimaryDefinition": "know, recognize"
    },
    "Keys": {
      "Word": 302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "隅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "corner, nook, remote place"
    },
    "Keys": {
      "Hanzi": 2478
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2478,
      "Radical": "阜",
      "FrequencyRank": 3387,
      "RadicalIndex": "170.9",
      "StrokeCount": 11
    }
  },
  "臭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chòu",
      "PrimaryDefinition": "smell, stink, emit foul odor"
    },
    "Keys": {
      "Hanzi": 1970,
      "Word": 3339
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "132.4",
      "Grade": 5,
      "GeneralStandard": 1970,
      "Radical": "自",
      "HSK": 5,
      "FrequencyRank": 2105,
      "StrokeCount": 10
    }
  },
  "虼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gè",
      "PrimaryDefinition": "a flea; species of beetle"
    },
    "Keys": {
      "Hanzi": 4211
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4211,
      "Radical": "虫",
      "FrequencyRank": 5063,
      "RadicalIndex": "142.3",
      "StrokeCount": 9
    }
  },
  "穙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7948,
      "Radical": "禾",
      "FrequencyRank": 9075,
      "RadicalIndex": "115.12",
      "StrokeCount": 17
    }
  },
  "资产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīchǎn",
      "PrimaryDefinition": "resource, belongings, holding, material possession, asset, capital fund, competency, substance, property, capital, assets"
    },
    "Keys": {
      "Word": 4269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "替": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "change, replace, substitute for"
    },
    "Keys": {
      "Hanzi": 2506,
      "Word": 2928
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73.8",
      "Grade": 4,
      "GeneralStandard": 2506,
      "Radical": "曰",
      "HSK": 4,
      "FrequencyRank": 1079,
      "StrokeCount": 12
    }
  },
  "匕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "ladle"
    },
    "Keys": {
      "Radical": 21,
      "Hanzi": 13
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 13,
      "Radical": "匕",
      "FrequencyRank": 3252,
      "RadicalIndex": "21",
      "StrokeCount": 2
    }
  },
  "讼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sòng",
      "PrimaryDefinition": "accuse; argue, dispute; litigate"
    },
    "Keys": {
      "Hanzi": 557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 557,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 1862,
      "StrokeCount": 6
    }
  },
  "要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yào",
      "PrimaryDefinition": "necessary, essential; necessity"
    },
    "Keys": {
      "Hanzi": 1441,
      "Word": 3080
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "146.3",
      "Grade": 4,
      "GeneralStandard": 1441,
      "Radical": "襾",
      "HSK": 1,
      "FrequencyRank": 26,
      "StrokeCount": 9
    }
  },
  "即便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíbiàn",
      "PrimaryDefinition": "even if, even though, right away, immediately"
    },
    "Keys": {
      "Word": 7299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráo",
      "PrimaryDefinition": "bountiful, abundant, plentiful"
    },
    "Keys": {
      "Hanzi": 1611,
      "Word": 8826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.6",
      "Grade": 7,
      "GeneralStandard": 1611,
      "Radical": "食",
      "HSK": 7,
      "FrequencyRank": 2266,
      "StrokeCount": 9
    }
  },
  "啻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "only, merely; just like; stop at"
    },
    "Keys": {
      "Hanzi": 5263
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5263,
      "Radical": "口",
      "FrequencyRank": 4249,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "气质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìzhì",
      "PrimaryDefinition": "kidney, fibre, charisma, crasis, mettle, humor, temper, predisposition, habit, mould, colour, class, streak, metal, temperament, disposition, strain, habitude, sensitivity, blood, proclivity, karma, gaseity, grain, makings, presence"
    },
    "Keys": {
      "Word": 8629
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "place in Sichuan"
    },
    "Keys": {
      "Hanzi": 5587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5587,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 13
    }
  },
  "祎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "excellent"
    },
    "Keys": {
      "Hanzi": 4048
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4048,
      "Radical": "示",
      "FrequencyRank": 6124,
      "RadicalIndex": "113.4",
      "StrokeCount": 8
    }
  },
  "增加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngjiā",
      "PrimaryDefinition": "increase, raise, add"
    },
    "Keys": {
      "Word": 2132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "膘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "fat; rump"
    },
    "Keys": {
      "Hanzi": 5966
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5966,
      "Radical": "肉",
      "FrequencyRank": 4884,
      "RadicalIndex": "130.11",
      "StrokeCount": 15
    }
  },
  "贴切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiēqiè",
      "PrimaryDefinition": "close-fitting, closest (translation)"
    },
    "Keys": {
      "Word": 9509
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "账单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàngdān",
      "PrimaryDefinition": "bill"
    },
    "Keys": {
      "Word": 10657
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敌人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dírén",
      "PrimaryDefinition": "antagonist, enemy, enemy force, foeman, opponent, adversary, hostile force, foe, opposing force"
    },
    "Keys": {
      "Word": 2404
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "泾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "name of a river"
    },
    "Keys": {
      "Hanzi": 4024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4024,
      "Radical": "水",
      "FrequencyRank": 4459,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "形形色色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngxíng-sèsè",
      "PrimaryDefinition": "all kinds of, all sorts of, every (different) kind of"
    },
    "Keys": {
      "Word": 10049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "revolve, rotate, turn"
    },
    "Keys": {
      "Hanzi": 5657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5657,
      "Radical": "斗",
      "FrequencyRank": 4117,
      "RadicalIndex": "68.1",
      "StrokeCount": 14
    }
  },
  "薳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "name of a herb; surname; (Cant.) a plant stem"
    },
    "Keys": {
      "Hanzi": 7838
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7838,
      "Radical": "艸",
      "FrequencyRank": 7595,
      "RadicalIndex": "140.14",
      "StrokeCount": 16
    }
  },
  "酮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "ketones"
    },
    "Keys": {
      "Hanzi": 5412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5412,
      "Radical": "酉",
      "FrequencyRank": 3905,
      "RadicalIndex": "164.6",
      "StrokeCount": 13
    }
  },
  "下一代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàyīdài",
      "PrimaryDefinition": "the next generation"
    },
    "Keys": {
      "Word": 9873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "莠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "weeds, tares; undesirable, evil"
    },
    "Keys": {
      "Hanzi": 4432
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4432,
      "Radical": "艸",
      "FrequencyRank": 5241,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "溘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "abruptly, suddenly, unexpectedly"
    },
    "Keys": {
      "Hanzi": 5568
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5568,
      "Radical": "水",
      "FrequencyRank": 5881,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "无敌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúdí",
      "PrimaryDefinition": "unequalled, without rival, a paragon"
    },
    "Keys": {
      "Word": 9762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "台球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táiqiú",
      "PrimaryDefinition": "billiards"
    },
    "Keys": {
      "Word": 9389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "dark, unclear, obscure; night"
    },
    "Keys": {
      "Hanzi": 2269
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2269,
      "Radical": "日",
      "FrequencyRank": 3075,
      "RadicalIndex": "72.7",
      "StrokeCount": 11
    }
  },
  "工具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjù",
      "PrimaryDefinition": "stock-in-trade, instrumentality, instrument, ministry, hand_tool, escalator, creature, utensil, material, gear, pawn, lobster-joint, cat's-paw, kit, apparatus, tool, appurtenances, paraphernalia, means, wood, tackle, implement, medium, lever, outfit"
    },
    "Keys": {
      "Word": 1498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "寄托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìtuō",
      "PrimaryDefinition": "to entrust (to sb), to place (one's hope, energy etc) in, a thing in which you invest (your hope, energy etc)"
    },
    "Keys": {
      "Word": 7341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "precious stone"
    },
    "Keys": {
      "Hanzi": 6938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6938,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "摄像机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèxiàngjī",
      "PrimaryDefinition": "video camera, CL:部[bu4]"
    },
    "Keys": {
      "Word": 3879
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "挡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎng",
      "PrimaryDefinition": "obstruct, impede; stop; resist"
    },
    "Keys": {
      "Hanzi": 1379,
      "Word": 3405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 5,
      "GeneralStandard": 1379,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1933,
      "StrokeCount": 9
    }
  },
  "隙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "crack, split, fissure; grudge"
    },
    "Keys": {
      "Hanzi": 2806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.1",
      "GeneralStandard": 2806,
      "Radical": "阜",
      "HSK": 9,
      "FrequencyRank": 2419,
      "StrokeCount": 12
    }
  },
  "飙升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāoshēng",
      "PrimaryDefinition": "to rise rapidly, to soar"
    },
    "Keys": {
      "Word": 5628
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "to become solid, solidify; strength"
    },
    "Keys": {
      "Hanzi": 1093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "31.5",
      "GeneralStandard": 1093,
      "Radical": "囗",
      "HSK": 4,
      "FrequencyRank": 893,
      "StrokeCount": 8
    }
  },
  "称赞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēngzàn",
      "PrimaryDefinition": "to praise, to acclaim, to commend, to compliment"
    },
    "Keys": {
      "Word": 2314
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "第": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "sequence, number; grade, degree"
    },
    "Keys": {
      "Hanzi": 2322,
      "Word": 74
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.5",
      "Grade": 1,
      "GeneralStandard": 2322,
      "Radical": "竹",
      "HSK": 1,
      "FrequencyRank": 114,
      "StrokeCount": 11
    }
  },
  "小丑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎochǒu",
      "PrimaryDefinition": "clown"
    },
    "Keys": {
      "Word": 9964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "信心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnxīn",
      "PrimaryDefinition": "trustfulness, trustingness, confidence, belief, affiance, religion, feeling, fay, trust, positiveness, notion, reliance, religious belief, faith, hope, impression"
    },
    "Keys": {
      "Word": 1108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "霆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng",
      "PrimaryDefinition": "a sudden peal of thunder"
    },
    "Keys": {
      "Hanzi": 5684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5684,
      "Radical": "雨",
      "FrequencyRank": 2571,
      "RadicalIndex": "173.7",
      "StrokeCount": 14
    }
  },
  "雒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luò",
      "PrimaryDefinition": "black horse with white mane"
    },
    "Keys": {
      "Hanzi": 5775
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5775,
      "Radical": "隹",
      "FrequencyRank": 5138,
      "RadicalIndex": "172.6",
      "StrokeCount": 14
    }
  },
  "议": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "consult, talk over, discuss"
    },
    "Keys": {
      "Hanzi": 320,
      "Word": 10358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.3",
      "Grade": 7,
      "GeneralStandard": 320,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 368,
      "StrokeCount": 5
    }
  },
  "隔阂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "géhé",
      "PrimaryDefinition": "misunderstanding, estrangement, (language etc) barrier"
    },
    "Keys": {
      "Word": 6734
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "先生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānsheng",
      "PrimaryDefinition": "doctor of Chinese medicine, Don, mulla, Ms., sir., teacher, gentleman, gospodin, Signor, signor, fortune-teller, babu, baboo, Herr, Mister, bey, mister, bwana, husband, siree, buckra, sirrah, senor, guv, sahib, Mr., rabbi, don, signore, Dan, sir, m'sieur, effendi, Tuan, baas, Monsieur, lording, monsieur, doctor, Mr, milord, Mynheer, signior, esquire, bookkeeper, Senor, rabboni, Senhor, sieur, sen"
    },
    "Keys": {
      "Word": 395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "深情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnqíng",
      "PrimaryDefinition": "deep emotion, deep feeling, deep love"
    },
    "Keys": {
      "Word": 9028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7463,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "鳐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "the nautilus; the ray"
    },
    "Keys": {
      "Hanzi": 6355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6355,
      "Radical": "魚",
      "FrequencyRank": 6344,
      "RadicalIndex": "195.1",
      "StrokeCount": 18
    }
  },
  "一技之长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījìzhīcháng",
      "PrimaryDefinition": "proficiency in a particular field (idiom), skill in a specialized area (idiom)"
    },
    "Keys": {
      "Word": 10289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公款": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngkuǎn",
      "PrimaryDefinition": "public money"
    },
    "Keys": {
      "Word": 6769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "drip; drop of water"
    },
    "Keys": {
      "Hanzi": 3165,
      "Word": 4505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 6,
      "GeneralStandard": 3165,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1896,
      "StrokeCount": 14
    }
  },
  "座": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "seat; stand, base"
    },
    "Keys": {
      "Hanzi": 2025,
      "Word": 1255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.7",
      "Grade": 2,
      "GeneralStandard": 2025,
      "Radical": "广",
      "HSK": 2,
      "FrequencyRank": 812,
      "StrokeCount": 10
    }
  },
  "增进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngjìn",
      "PrimaryDefinition": "enhance, promote, further"
    },
    "Keys": {
      "Word": 5310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "崁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn",
      "PrimaryDefinition": "a place in Taiwan Tainan"
    },
    "Keys": {
      "Hanzi": 6997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6997,
      "Radical": "山",
      "RadicalIndex": "46.7",
      "StrokeCount": 10
    }
  },
  "层面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céngmiàn",
      "PrimaryDefinition": "storey, level, side, story, range, floor, coverage"
    },
    "Keys": {
      "Word": 4380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "丶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn",
      "PrimaryDefinition": "dot"
    },
    "Keys": {
      "Radical": 3
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "踺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5918
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5918,
      "Radical": "足",
      "FrequencyRank": 7945,
      "RadicalIndex": "157.8",
      "StrokeCount": 15
    }
  },
  "国产": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóchǎn",
      "PrimaryDefinition": "made in China, made in our country, make domestically"
    },
    "Keys": {
      "Word": 4637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "税收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuìshōu",
      "PrimaryDefinition": "taxation"
    },
    "Keys": {
      "Word": 9274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "purpose, will, determination; annals"
    },
    "Keys": {
      "Hanzi": 651
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.3",
      "GeneralStandard": 651,
      "Radical": "心",
      "HSK": 3,
      "FrequencyRank": 542,
      "StrokeCount": 7
    }
  },
  "馇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chā",
      "PrimaryDefinition": "stir and cook"
    },
    "Keys": {
      "Hanzi": 5248
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5248,
      "Radical": "食",
      "FrequencyRank": 6000,
      "RadicalIndex": "184.9",
      "StrokeCount": 12
    }
  },
  "肾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": "kidneys; testes, gizzard"
    },
    "Keys": {
      "Hanzi": 1073,
      "Word": 9045
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 7,
      "GeneralStandard": 1073,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2361,
      "StrokeCount": 8
    }
  },
  "勒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lēi",
      "PrimaryDefinition": "strangle, tighten"
    },
    "Keys": {
      "Hanzi": 2200,
      "Word": 7958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.9",
      "Grade": 7,
      "GeneralStandard": 2200,
      "Radical": "力",
      "HSK": 7,
      "FrequencyRank": 966,
      "StrokeCount": 11
    }
  },
  "请柬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐngjiǎn",
      "PrimaryDefinition": "invitation card, written invitation"
    },
    "Keys": {
      "Word": 8764
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接触": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēchù",
      "PrimaryDefinition": "come into contact with, get in touch with, engage, contact"
    },
    "Keys": {
      "Word": 3618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "故意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gùyì",
      "PrimaryDefinition": "purposive, deliberate, willful, intentional"
    },
    "Keys": {
      "Word": 693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "占卜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhānbǔ",
      "PrimaryDefinition": "to divine"
    },
    "Keys": {
      "Word": 10634
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "offer food superior; send gift"
    },
    "Keys": {
      "Hanzi": 2724
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184.9",
      "GeneralStandard": 2724,
      "Radical": "食",
      "HSK": 8,
      "FrequencyRank": 2947,
      "StrokeCount": 12
    }
  },
  "撄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "oppose, offend, run counter to"
    },
    "Keys": {
      "Hanzi": 5635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5635,
      "Radical": "手",
      "FrequencyRank": 6139,
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "嬖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "favorite; a minion"
    },
    "Keys": {
      "Hanzi": 6187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6187,
      "Radical": "女",
      "FrequencyRank": 5700,
      "RadicalIndex": "38.13",
      "StrokeCount": 16
    }
  },
  "晌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎng",
      "PrimaryDefinition": "noon, midday; moment; unit land measurement equal to seven mou (畝), or the area that can be sown in one day"
    },
    "Keys": {
      "Hanzi": 1895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1895,
      "Radical": "日",
      "FrequencyRank": 2822,
      "RadicalIndex": "72.6",
      "StrokeCount": 10
    }
  },
  "轻易": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngyi",
      "PrimaryDefinition": "lightly, rashly"
    },
    "Keys": {
      "Word": 2808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "褪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuì",
      "PrimaryDefinition": "strip, undress; fall off; fade"
    },
    "Keys": {
      "Hanzi": 3180
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3180,
      "Radical": "衣",
      "FrequencyRank": 3339,
      "RadicalIndex": "145.1",
      "StrokeCount": 14
    }
  },
  "衲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nà",
      "PrimaryDefinition": "mend, sew, patch; line; quilt"
    },
    "Keys": {
      "Hanzi": 4360
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4360,
      "Radical": "衣",
      "FrequencyRank": 4660,
      "RadicalIndex": "145.4",
      "StrokeCount": 9
    }
  },
  "镒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "measure of weight for gold"
    },
    "Keys": {
      "Hanzi": 5951
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5951,
      "Radical": "金",
      "FrequencyRank": 5261,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "清除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngchú",
      "PrimaryDefinition": "eliminate, get rid of"
    },
    "Keys": {
      "Word": 8744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "实物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíwù",
      "PrimaryDefinition": "material object, concrete object, original object, in kind, object for practical use, definite thing, reality, matter (physics)"
    },
    "Keys": {
      "Word": 9120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shé",
      "PrimaryDefinition": "tongue"
    },
    "Keys": {
      "Radical": 135,
      "Hanzi": 449
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "135",
      "GeneralStandard": 449,
      "Radical": "舌",
      "HSK": 6,
      "FrequencyRank": 1958,
      "StrokeCount": 6
    }
  },
  "看法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kànfa",
      "PrimaryDefinition": "side, view, mentality, idea, outlook, a way of looking at a thing, opinion, thought, sight, standpoint, hypothesis, attitude, hobbyhorse, viewpoint, possibility, theory, slant, pronouncement, way of looking at sth., thinking, supposition, supposal"
    },
    "Keys": {
      "Word": 805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "圯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "bridge, bank"
    },
    "Keys": {
      "Hanzi": 3577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3577,
      "Radical": "土",
      "FrequencyRank": 6485,
      "RadicalIndex": "32.3",
      "StrokeCount": 6
    }
  },
  "谳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "to decide, judge; a verdict, decision"
    },
    "Keys": {
      "Hanzi": 6010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6010,
      "Radical": "言",
      "FrequencyRank": 6161,
      "RadicalIndex": "149.13",
      "StrokeCount": 15
    }
  },
  "打算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎsuan",
      "PrimaryDefinition": "mean, specify, plan, think, intention, propose, design, purpose, contemplate, allot, destine, calculate, intend, designate, be after"
    },
    "Keys": {
      "Word": 583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "憺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàn",
      "PrimaryDefinition": "peace"
    },
    "Keys": {
      "Hanzi": 7910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7910,
      "Radical": "心",
      "RadicalIndex": "61.13",
      "StrokeCount": 16
    }
  },
  "鸦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yā",
      "PrimaryDefinition": "crow; Corvus species (various)"
    },
    "Keys": {
      "Hanzi": 1464
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "196.4",
      "GeneralStandard": 1464,
      "Radical": "鳥",
      "HSK": 9,
      "FrequencyRank": 2397,
      "StrokeCount": 9
    }
  },
  "洧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "name of a river in honan"
    },
    "Keys": {
      "Hanzi": 4333
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4333,
      "Radical": "水",
      "FrequencyRank": 7099,
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "咦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "exclamation of surprise"
    },
    "Keys": {
      "Hanzi": 4202
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4202,
      "Radical": "口",
      "FrequencyRank": 3080,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "分寸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēncun",
      "PrimaryDefinition": "propriety, appropriate behavior, proper speech or action, within the norms"
    },
    "Keys": {
      "Word": 6533
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瘌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "là",
      "PrimaryDefinition": "severe; poisonous; dangerous; itch and other skin diseases"
    },
    "Keys": {
      "Hanzi": 5781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5781,
      "Radical": "疒",
      "FrequencyRank": 6264,
      "RadicalIndex": "104.9",
      "StrokeCount": 14
    }
  },
  "牝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìn",
      "PrimaryDefinition": "female of species; deep gorge"
    },
    "Keys": {
      "Hanzi": 3601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3601,
      "Radical": "牛",
      "FrequencyRank": 5026,
      "RadicalIndex": "93.2",
      "StrokeCount": 6
    }
  },
  "啵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bo",
      "PrimaryDefinition": "phonetic"
    },
    "Keys": {
      "Hanzi": 4849
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4849,
      "Radical": "口",
      "FrequencyRank": 4244,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "胳膊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēbo",
      "PrimaryDefinition": "arm, CL:隻|只[zhi1],條|条[tiao2],雙|双[shuang1]"
    },
    "Keys": {
      "Word": 6719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "斗志": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòuzhì",
      "PrimaryDefinition": "will to fight, fighting spirit"
    },
    "Keys": {
      "Word": 6350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "养殖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎngzhí",
      "PrimaryDefinition": "to cultivate, cultivation, to further, to encourage"
    },
    "Keys": {
      "Word": 10226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自卑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìbēi",
      "PrimaryDefinition": "feel oneself inferior, have low self-esteem"
    },
    "Keys": {
      "Word": 10951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "sharpen; carefully, thorough"
    },
    "Keys": {
      "Hanzi": 3933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3933,
      "Radical": "刀",
      "FrequencyRank": 6294,
      "RadicalIndex": "18.6",
      "StrokeCount": 8
    }
  },
  "涵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "soak, wet; tolerate, be lenient"
    },
    "Keys": {
      "Hanzi": 2434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "GeneralStandard": 2434,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2330,
      "StrokeCount": 11
    }
  },
  "谋害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móuhài",
      "PrimaryDefinition": "to conspire to murder, to plot against sb's life"
    },
    "Keys": {
      "Word": 8336
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一大早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīdàzǎo",
      "PrimaryDefinition": "at dawn, at first light, first thing in the morning"
    },
    "Keys": {
      "Word": 10285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "step on, trample, tread on, walk"
    },
    "Keys": {
      "Hanzi": 3242,
      "Word": 5062
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.8",
      "Grade": 6,
      "GeneralStandard": 3242,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 1753,
      "StrokeCount": 15
    }
  },
  "莲子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánzǐ",
      "PrimaryDefinition": "lotus seed"
    },
    "Keys": {
      "Word": 8019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōng",
      "PrimaryDefinition": "pass through, common, communicate"
    },
    "Keys": {
      "Hanzi": 2145,
      "Word": 1032
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 2,
      "GeneralStandard": 2145,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 190,
      "StrokeCount": 10
    }
  },
  "烻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7052,
      "Radical": "火",
      "RadicalIndex": "86.7",
      "StrokeCount": 10
    }
  },
  "伣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "like"
    },
    "Keys": {
      "Hanzi": 6538
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6538,
      "Radical": "人",
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "邦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāng",
      "PrimaryDefinition": "nation, country, state"
    },
    "Keys": {
      "Hanzi": 348
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.4",
      "GeneralStandard": 348,
      "Radical": "邑",
      "HSK": 7,
      "FrequencyRank": 1363,
      "StrokeCount": 6
    }
  },
  "国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guó",
      "PrimaryDefinition": "nation, country, nation-state"
    },
    "Keys": {
      "Hanzi": 1081,
      "Word": 125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.5",
      "Grade": 1,
      "GeneralStandard": 1081,
      "Radical": "囗",
      "HSK": 1,
      "FrequencyRank": 20,
      "StrokeCount": 8
    }
  },
  "啦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "la",
      "PrimaryDefinition": "final particle of assertion"
    },
    "Keys": {
      "Hanzi": 2267,
      "Word": 4808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "Grade": 6,
      "GeneralStandard": 2267,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 1194,
      "StrokeCount": 11
    }
  },
  "捯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dáo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4719,
      "Radical": "手",
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "车轴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēzhóu",
      "PrimaryDefinition": "axle, CL:根[gen1]"
    },
    "Keys": {
      "Word": 5844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "袄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎo",
      "PrimaryDefinition": "outer garments; coat, jacket"
    },
    "Keys": {
      "Hanzi": 1709
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1709,
      "Radical": "衣",
      "FrequencyRank": 3480,
      "RadicalIndex": "145.4",
      "StrokeCount": 9
    }
  },
  "婕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "handsome"
    },
    "Keys": {
      "Hanzi": 5018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5018,
      "Radical": "女",
      "FrequencyRank": 4945,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "制裁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìcái",
      "PrimaryDefinition": "sanction, punish"
    },
    "Keys": {
      "Word": 10786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "篮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "basket"
    },
    "Keys": {
      "Hanzi": 3357
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.1",
      "GeneralStandard": 3357,
      "Radical": "竹",
      "HSK": 2,
      "FrequencyRank": 2357,
      "StrokeCount": 16
    }
  },
  "流传": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúchuán",
      "PrimaryDefinition": "spread, circulate, hand down"
    },
    "Keys": {
      "Word": 2712
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "摇摇欲坠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáoyáo-yùzhuì",
      "PrimaryDefinition": "tottering, on the verge of collapse"
    },
    "Keys": {
      "Word": 10237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "应该": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnggāi",
      "PrimaryDefinition": "should, ought to"
    },
    "Keys": {
      "Word": 1162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "环": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "jade ring or bracelet; ring"
    },
    "Keys": {
      "Hanzi": 945,
      "Word": 1561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96.4",
      "Grade": 3,
      "GeneralStandard": 945,
      "Radical": "玉",
      "HSK": 3,
      "FrequencyRank": 681,
      "StrokeCount": 8
    }
  },
  "广告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnggào",
      "PrimaryDefinition": "advertisement"
    },
    "Keys": {
      "Word": 699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鄫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7720
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7720,
      "Radical": "邑",
      "FrequencyRank": 8178,
      "RadicalIndex": "163.12",
      "StrokeCount": 14
    }
  },
  "打倒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ dǎo",
      "PrimaryDefinition": "to overthrow, to knock down, Down with ... !"
    },
    "Keys": {
      "Word": 6099
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "螨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎn",
      "PrimaryDefinition": "insect"
    },
    "Keys": {
      "Hanzi": 6097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6097,
      "Radical": "虫",
      "FrequencyRank": 4641,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "斠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "measure"
    },
    "Keys": {
      "Hanzi": 7627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7627,
      "Radical": "斗",
      "RadicalIndex": "68.1",
      "StrokeCount": 14
    }
  },
  "懂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒng",
      "PrimaryDefinition": "understand, comprehend"
    },
    "Keys": {
      "Hanzi": 3300,
      "Word": 638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.13",
      "Grade": 2,
      "GeneralStandard": 3300,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 1211,
      "StrokeCount": 15
    }
  },
  "鲡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "eel"
    },
    "Keys": {
      "Hanzi": 5969
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5969,
      "Radical": "魚",
      "FrequencyRank": 5600,
      "RadicalIndex": "195.7",
      "StrokeCount": 15
    }
  },
  "总量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngliàng",
      "PrimaryDefinition": "total, overall amount"
    },
    "Keys": {
      "Word": 5403
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "指手画脚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐshǒu-huàjiǎo",
      "PrimaryDefinition": "to gesticulate while talking (idiom), to explain by waving one's hands, to criticize or give orders summarily"
    },
    "Keys": {
      "Word": 10776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "购买": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòumǎi",
      "PrimaryDefinition": "invest, take, buy, make purchases, go shopping, purchase"
    },
    "Keys": {
      "Word": 2508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "亮丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liànglì",
      "PrimaryDefinition": "beautiful, remarkable, brilliant, splendid, smart"
    },
    "Keys": {
      "Word": 8037
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": "pond, pool; wash away, cleanse"
    },
    "Keys": {
      "Hanzi": 1414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.7",
      "GeneralStandard": 1414,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1424,
      "StrokeCount": 9
    }
  },
  "烬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "cinders, ashes, embers; remnants"
    },
    "Keys": {
      "Hanzi": 4643
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4643,
      "Radical": "火",
      "FrequencyRank": 3670,
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "营造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíngzào",
      "PrimaryDefinition": "construct, build"
    },
    "Keys": {
      "Word": 10420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蹑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "tread, step on; follow, track"
    },
    "Keys": {
      "Hanzi": 6227
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6227,
      "Radical": "足",
      "FrequencyRank": 3665,
      "RadicalIndex": "157.1",
      "StrokeCount": 17
    }
  },
  "筥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": "round-shaped bamboo basket for holding rice"
    },
    "Keys": {
      "Hanzi": 7390
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7390,
      "Radical": "竹",
      "FrequencyRank": 8956,
      "RadicalIndex": "118.7",
      "StrokeCount": 12
    }
  },
  "轫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": "a block that keeps a wheel from moving"
    },
    "Keys": {
      "Hanzi": 3691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3691,
      "Radical": "車",
      "FrequencyRank": 5963,
      "RadicalIndex": "159.3",
      "StrokeCount": 7
    }
  },
  "拷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎo",
      "PrimaryDefinition": "torture and interrogate; hit"
    },
    "Keys": {
      "Hanzi": 1368
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1368,
      "Radical": "手",
      "FrequencyRank": 3230,
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "贺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "congratulate; send present"
    },
    "Keys": {
      "Hanzi": 1744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "GeneralStandard": 1744,
      "Radical": "貝",
      "HSK": 5,
      "FrequencyRank": 1690,
      "StrokeCount": 9
    }
  },
  "不但": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùdàn",
      "PrimaryDefinition": "conj.: not only"
    },
    "Keys": {
      "Word": 527
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "夜市": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèshì",
      "PrimaryDefinition": "night market"
    },
    "Keys": {
      "Word": 10265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "náo",
      "PrimaryDefinition": "talkative; clamour; hubbub"
    },
    "Keys": {
      "Hanzi": 3919
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3919,
      "Radical": "口",
      "FrequencyRank": 4718,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "艳丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànlì",
      "PrimaryDefinition": "gorgeous, garish and beautiful"
    },
    "Keys": {
      "Word": 10210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "line, streak, stripe; wrinkle"
    },
    "Keys": {
      "Hanzi": 939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 939,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 1768,
      "StrokeCount": 7
    }
  },
  "长征": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángzhēng",
      "PrimaryDefinition": "Long March (retreat of the Red Army 1934-1935), expedition, long journey"
    },
    "Keys": {
      "Word": 5812
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngyi",
      "PrimaryDefinition": "business enterprise, vocation, practice, merchant, dealing, patronage, deal, commercial enterprise, trade, biz, business, dealings, racket"
    },
    "Keys": {
      "Word": 1887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "诌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "play with words, quip; talk nonse"
    },
    "Keys": {
      "Hanzi": 3798
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3798,
      "Radical": "言",
      "FrequencyRank": 4780,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "贯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "a string of 1000 coins; to go through"
    },
    "Keys": {
      "Hanzi": 1351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "GeneralStandard": 1351,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 1478,
      "StrokeCount": 8
    }
  },
  "为何": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèihé",
      "PrimaryDefinition": "why, for what reason"
    },
    "Keys": {
      "Word": 5142
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "放弃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngqì",
      "PrimaryDefinition": "depart, chuck up the sponge, scuttle, dispense with, throw in the towel, abort, throw in, flunk, foreswear, abnegate, pass, free, lay_down, abandonment, disclaim, part_with, pass_up, abdicate, release, ditch, abjure, jettison, waive, surrender, leave, give, recant, desert, give up, divest, yield, forswear, break, abandon, unguard, back_down, yield up, demit, relinquish, break_with, drop"
    },
    "Keys": {
      "Word": 3455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "并且": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìngqiě",
      "PrimaryDefinition": "conj.: and, besides, moreover"
    },
    "Keys": {
      "Word": 1311
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "泜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "a river in Hebei province"
    },
    "Keys": {
      "Hanzi": 6722
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6722,
      "Radical": "水",
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "上级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngjí",
      "PrimaryDefinition": "higher level/authority"
    },
    "Keys": {
      "Word": 3865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "贩卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fànmài",
      "PrimaryDefinition": "peddle, sell"
    },
    "Keys": {
      "Word": 6483
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiōng",
      "PrimaryDefinition": "wilds"
    },
    "Keys": {
      "Hanzi": 6652
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6652,
      "Radical": "土",
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "钰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "rare treasure"
    },
    "Keys": {
      "Hanzi": 4527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4527,
      "Radical": "金",
      "FrequencyRank": 4518,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "馅儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànr5",
      "PrimaryDefinition": "stuffing, forcemeat, filling, erhua variant of 餡|馅[xian4]"
    },
    "Keys": {
      "Word": 9910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "to fuss"
    },
    "Keys": {
      "Hanzi": 7200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7200,
      "Radical": "人",
      "RadicalIndex": "9.9",
      "StrokeCount": 11
    }
  },
  "缝合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fénghé",
      "PrimaryDefinition": "to sew together, suture (in surgery), to sew up (a wound)"
    },
    "Keys": {
      "Word": 6593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阶层": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēcéng",
      "PrimaryDefinition": "section, circle, degree, social stratum, stratum, echelon, hierarchical, walk in life, walk_of_life, rank"
    },
    "Keys": {
      "Word": 7495
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huán",
      "PrimaryDefinition": "metal ring; measure of currency"
    },
    "Keys": {
      "Hanzi": 8003
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8003,
      "Radical": "金",
      "FrequencyRank": 7462,
      "RadicalIndex": "167.13",
      "StrokeCount": 18
    }
  },
  "醌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5890
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5890,
      "Radical": "酉",
      "FrequencyRank": 6268,
      "RadicalIndex": "164.8",
      "StrokeCount": 15
    }
  },
  "厣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "shell"
    },
    "Keys": {
      "Hanzi": 7144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7144,
      "Radical": "厂",
      "FrequencyRank": 5160,
      "RadicalIndex": "27.9",
      "StrokeCount": 11
    }
  },
  "况且": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàngqiě",
      "PrimaryDefinition": "conj.: moreover, besides, in addition"
    },
    "Keys": {
      "Word": 7890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "add repeated washes of color"
    },
    "Keys": {
      "Hanzi": 2768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2768,
      "Radical": "水",
      "FrequencyRank": 3606,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "镏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "distil; lutetium; surname"
    },
    "Keys": {
      "Hanzi": 5950
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5950,
      "Radical": "金",
      "FrequencyRank": 4951,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "檫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "sassafras tzumu"
    },
    "Keys": {
      "Hanzi": 7992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7992,
      "Radical": "木",
      "FrequencyRank": 6428,
      "RadicalIndex": "75.14",
      "StrokeCount": 18
    }
  },
  "注意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù yì",
      "PrimaryDefinition": "pay attention to, take note of"
    },
    "Keys": {
      "Word": 2196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "搒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "pole; beat"
    },
    "Keys": {
      "Hanzi": 7482
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7482,
      "Radical": "手",
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "贿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "bribe; bribes; riches, wealth"
    },
    "Keys": {
      "Hanzi": 1925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "GeneralStandard": 1925,
      "Radical": "貝",
      "HSK": 7,
      "FrequencyRank": 2569,
      "StrokeCount": 10
    }
  },
  "均匀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnyún",
      "PrimaryDefinition": "even, well-distributed, homogeneous, well-proportioned (figure, body etc)"
    },
    "Keys": {
      "Word": 7731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "货": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "goods, commodities, products"
    },
    "Keys": {
      "Hanzi": 1151,
      "Word": 2573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "Grade": 4,
      "GeneralStandard": 1151,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 818,
      "StrokeCount": 8
    }
  },
  "现场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànchǎng",
      "PrimaryDefinition": "scene (of an incident), location, site, locale, scene of a crime, locality, live, scene, spot, field"
    },
    "Keys": {
      "Word": 2033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "砭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "stone probe; pierce; counsel"
    },
    "Keys": {
      "Hanzi": 4169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4169,
      "Radical": "石",
      "FrequencyRank": 4774,
      "RadicalIndex": "112.5",
      "StrokeCount": 9
    }
  },
  "齑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "break or smash into pieces, pulverize; hash"
    },
    "Keys": {
      "Hanzi": 5986
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5986,
      "Radical": "齊",
      "FrequencyRank": 5595,
      "RadicalIndex": "210.9",
      "StrokeCount": 15
    }
  },
  "愫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "guileless, sincere, honest"
    },
    "Keys": {
      "Hanzi": 5588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5588,
      "Radical": "心",
      "FrequencyRank": 3569,
      "RadicalIndex": "61.1",
      "StrokeCount": 13
    }
  },
  "刖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "cutting off feet as form of punishment"
    },
    "Keys": {
      "Hanzi": 3613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3613,
      "Radical": "刀",
      "FrequencyRank": 5475,
      "RadicalIndex": "18.4",
      "StrokeCount": 6
    }
  },
  "茭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "an aquatic grass, the stalks of which are eaten as a vegetable"
    },
    "Keys": {
      "Hanzi": 4127
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4127,
      "Radical": "艸",
      "FrequencyRank": 6064,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "纥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "inferior silk; tassel, fringe"
    },
    "Keys": {
      "Hanzi": 3646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3646,
      "Radical": "糸",
      "FrequencyRank": 4854,
      "RadicalIndex": "120.3",
      "StrokeCount": 6
    }
  },
  "顺路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnlù",
      "PrimaryDefinition": "by the way, while out doing sth else, conveniently"
    },
    "Keys": {
      "Word": 9283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "请求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐngqiú",
      "PrimaryDefinition": "ask, request, entreat"
    },
    "Keys": {
      "Word": 936
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "流氓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúmáng",
      "PrimaryDefinition": "roughscuff, rip, yobbo, badmash, swashbuckler, rascal, riffraff, scamp, raff, rapscallion, rogue, rowdy, hooliganism, cur, picaroon, varmint, yobo, ruffian, hector, roughneck, gaolbird, scalawag, gorilla, no-good, scallywag, hoodlum, limmer, tearaway, blackguard, picaro, hooligan, hessian, bullyboy, rascallion, indecency, bully, plug-ugly, shoulder-hitter, dingbat, caird, tough, varlet, sc"
    },
    "Keys": {
      "Word": 8102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高跟鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāogēnxié",
      "PrimaryDefinition": "high-heeled shoes"
    },
    "Keys": {
      "Word": 3484
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "癣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎn",
      "PrimaryDefinition": "ringworms"
    },
    "Keys": {
      "Hanzi": 3467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3467,
      "Radical": "疒",
      "FrequencyRank": 4067,
      "RadicalIndex": "104.14",
      "StrokeCount": 19
    }
  },
  "内容": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèiróng",
      "PrimaryDefinition": "subject_matter, content, cognitive content, internal, contents, mental object, meat, substance, matter, lining"
    },
    "Keys": {
      "Word": 1763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "美术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měishù",
      "PrimaryDefinition": "constructivism, beaux arts, beaux-arts, artistic production, artistic creation, painting, fineart, fine_art, cubism, art, fine arts"
    },
    "Keys": {
      "Word": 1739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "墡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "chalk"
    },
    "Keys": {
      "Hanzi": 7754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7754,
      "Radical": "土",
      "RadicalIndex": "32.12",
      "StrokeCount": 15
    }
  },
  "同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "same, similar; together with"
    },
    "Keys": {
      "Hanzi": 427,
      "Word": 5093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.3",
      "Grade": 6,
      "GeneralStandard": 427,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 69,
      "StrokeCount": 6
    }
  },
  "闱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "gate, door; living quarters"
    },
    "Keys": {
      "Hanzi": 3761
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3761,
      "Radical": "門",
      "FrequencyRank": 4575,
      "RadicalIndex": "169.4",
      "StrokeCount": 7
    }
  },
  "脱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuō",
      "PrimaryDefinition": "take off"
    },
    "Keys": {
      "Hanzi": 2360,
      "Word": 2958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.7",
      "Grade": 4,
      "GeneralStandard": 2360,
      "Radical": "肉",
      "HSK": 4,
      "FrequencyRank": 943,
      "StrokeCount": 11
    }
  },
  "伫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3610
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3610,
      "Radical": "人",
      "FrequencyRank": 4074,
      "RadicalIndex": "9.4",
      "StrokeCount": 6
    }
  },
  "汕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "basket for catching fish; bamboo; Swatow (Shantou)"
    },
    "Keys": {
      "Hanzi": 3623
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3623,
      "Radical": "水",
      "FrequencyRank": 3878,
      "RadicalIndex": "85.3",
      "StrokeCount": 6
    }
  },
  "榱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuī",
      "PrimaryDefinition": "rafter"
    },
    "Keys": {
      "Hanzi": 5666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5666,
      "Radical": "木",
      "FrequencyRank": 5785,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "骇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài",
      "PrimaryDefinition": "terrify, frighten, scare; shock"
    },
    "Keys": {
      "Hanzi": 1764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "187.6",
      "GeneralStandard": 1764,
      "Radical": "馬",
      "HSK": 9,
      "FrequencyRank": 2538,
      "StrokeCount": 9
    }
  },
  "体积": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐjī",
      "PrimaryDefinition": "volume, bulk"
    },
    "Keys": {
      "Word": 3974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "联盟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánméng",
      "PrimaryDefinition": "conference, alliance, coalition, hookup, ally, Verein, confederacy, federacy, circuit, combination, Bund, confederation, federation, union, bloc, league, axis"
    },
    "Keys": {
      "Word": 4821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "添加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānjiā",
      "PrimaryDefinition": "to add, to increase"
    },
    "Keys": {
      "Word": 9489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "濋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7903,
      "Radical": "水",
      "RadicalIndex": "85.13",
      "StrokeCount": 16
    }
  },
  "对准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì zhǔn",
      "PrimaryDefinition": "to aim at, to target, to point at, to be directed at, registration, alignment (mechanical engineering)"
    },
    "Keys": {
      "Word": 6385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蟆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "má",
      "PrimaryDefinition": "frog, toad"
    },
    "Keys": {
      "Hanzi": 3346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3346,
      "Radical": "虫",
      "FrequencyRank": 3849,
      "RadicalIndex": "142.11",
      "StrokeCount": 16
    }
  },
  "欢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huān",
      "PrimaryDefinition": "happy, pleased, glad; joy; enjoy"
    },
    "Keys": {
      "Hanzi": 587
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "76.2",
      "GeneralStandard": 587,
      "Radical": "欠",
      "HSK": 1,
      "FrequencyRank": 685,
      "StrokeCount": 6
    }
  },
  "当成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngchéng",
      "PrimaryDefinition": "regard as, take for, consider as, regard/consider as, treat as, regard"
    },
    "Keys": {
      "Word": 4488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "奔赴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēnfù",
      "PrimaryDefinition": "to rush to, to hurry to"
    },
    "Keys": {
      "Word": 5561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "措": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": "place; collect, arrange; employ"
    },
    "Keys": {
      "Hanzi": 2162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2162,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1148,
      "StrokeCount": 11
    }
  },
  "浪漫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làngmàn",
      "PrimaryDefinition": "dissolute, debauched, romantic"
    },
    "Keys": {
      "Word": 3679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "倒计时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàojìshí",
      "PrimaryDefinition": "to count down, countdown"
    },
    "Keys": {
      "Word": 6200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恐慌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒnghuāng",
      "PrimaryDefinition": "panic"
    },
    "Keys": {
      "Word": 7827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "报道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàodào",
      "PrimaryDefinition": "to report (news), report, CL:篇[pian1],份[fen4]"
    },
    "Keys": {
      "Word": 1285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "污水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wūshuǐ",
      "PrimaryDefinition": "slops, sewage, wastewater, sewerage, waste water, drainage, sewer water, slop, foul water, polluted water, waste, effluent"
    },
    "Keys": {
      "Word": 4013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "内部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèibù",
      "PrimaryDefinition": "entrails, bowel, interior, inner, within, bosom, inside, innards, internal, inward"
    },
    "Keys": {
      "Word": 2761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "住房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùfáng",
      "PrimaryDefinition": "housing"
    },
    "Keys": {
      "Word": 1233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "订婚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìng hūn",
      "PrimaryDefinition": "to get engaged"
    },
    "Keys": {
      "Word": 6307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "螽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "katydid"
    },
    "Keys": {
      "Hanzi": 6283
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6283,
      "Radical": "虫",
      "FrequencyRank": 5830,
      "RadicalIndex": "142.11",
      "StrokeCount": 17
    }
  },
  "源于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuányú",
      "PrimaryDefinition": "has its origins in"
    },
    "Keys": {
      "Word": 10549
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "最好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìhǎo",
      "PrimaryDefinition": "had better, it would be best"
    },
    "Keys": {
      "Word": 490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "烘托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōngtuō",
      "PrimaryDefinition": "background (of a painting), backdrop, a foil (to set off something to advantage), to offset (something to advantage)"
    },
    "Keys": {
      "Word": 7076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū tóu",
      "PrimaryDefinition": "to get out of a predicament, to stick out, to take the initiative, remaining odd fraction after a division, a little more than"
    },
    "Keys": {
      "Word": 5969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngdài",
      "PrimaryDefinition": "necktie, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 3699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "潲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shào",
      "PrimaryDefinition": "driving rain; to sprinkle"
    },
    "Keys": {
      "Hanzi": 6001
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6001,
      "Radical": "水",
      "FrequencyRank": 7084,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "莴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wō",
      "PrimaryDefinition": "lettuce"
    },
    "Keys": {
      "Hanzi": 4430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4430,
      "Radical": "艸",
      "FrequencyRank": 5240,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "通宵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngxiāo",
      "PrimaryDefinition": "all night, throughout the night"
    },
    "Keys": {
      "Word": 9529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "将要": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāngyào",
      "PrimaryDefinition": "will, shall, to be going to"
    },
    "Keys": {
      "Word": 3610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "反复": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnfù",
      "PrimaryDefinition": "repeatedly, backward_and_forward"
    },
    "Keys": {
      "Word": 1437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "救护车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùhùchē",
      "PrimaryDefinition": "ambulance, CL:輛|辆[liang4]"
    },
    "Keys": {
      "Word": 7663
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一筹莫展": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīchóu-mòzhǎn",
      "PrimaryDefinition": "to be unable to find a solution, to be at wits' end"
    },
    "Keys": {
      "Word": 10322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讲述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngshù",
      "PrimaryDefinition": "to talk about, to narrate, to give an account"
    },
    "Keys": {
      "Word": 7444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "失业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī yè",
      "PrimaryDefinition": "lose one's job; be out of work"
    },
    "Keys": {
      "Word": 2857
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "无济于事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wújì-yúshì",
      "PrimaryDefinition": "to no avail, of no use"
    },
    "Keys": {
      "Word": 9770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "挫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": "push down; chop down; grind"
    },
    "Keys": {
      "Hanzi": 1805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "GeneralStandard": 1805,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 2426,
      "StrokeCount": 10
    }
  },
  "佳节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiājié",
      "PrimaryDefinition": "festive day, holiday"
    },
    "Keys": {
      "Word": 7348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人缘儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rényuánr5",
      "PrimaryDefinition": "relations with other people, erhua variant of 人緣|人缘[ren2 yuan2]"
    },
    "Keys": {
      "Word": 8853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "别墅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biéshù",
      "PrimaryDefinition": "villa"
    },
    "Keys": {
      "Word": 5639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搭配": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāpèi",
      "PrimaryDefinition": "group, pair"
    },
    "Keys": {
      "Word": 4464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "㛹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pián",
      "PrimaryDefinition": "pretty; exquisite; fine"
    },
    "Keys": {
      "Hanzi": 7450
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7450,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "戈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "spear"
    },
    "Keys": {
      "Radical": 62,
      "Hanzi": 104
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "62",
      "GeneralStandard": 104,
      "Radical": "戈",
      "HSK": 9,
      "FrequencyRank": 1695,
      "StrokeCount": 4
    }
  },
  "情形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngxing",
      "PrimaryDefinition": "circumstance, trimness, event, trim, instance, state of affairs, case, circumstances, status, situation, condition"
    },
    "Keys": {
      "Word": 3826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "爱面子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài miànzi",
      "PrimaryDefinition": "to save face, to worry about losing face, proud of one's reputation, sensitive about losing prestige, sense of propriety"
    },
    "Keys": {
      "Word": 5433
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "alum"
    },
    "Keys": {
      "Hanzi": 1044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1044,
      "Radical": "石",
      "FrequencyRank": 4601,
      "RadicalIndex": "112.3",
      "StrokeCount": 8
    }
  },
  "一身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīshēn",
      "PrimaryDefinition": "whole body, from head to toe, single person, a suit of clothes"
    },
    "Keys": {
      "Word": 4116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "伸张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnzhāng",
      "PrimaryDefinition": "to uphold (e.g. justice or virtue), to promote"
    },
    "Keys": {
      "Word": 9019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "质问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìwèn",
      "PrimaryDefinition": "interrogate, bombard, pose, heckle, call to account, inquire, interrogation, oppugn, question, quiz, ask"
    },
    "Keys": {
      "Word": 10790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "签署": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānshǔ",
      "PrimaryDefinition": "sign/initial (a document)"
    },
    "Keys": {
      "Word": 8659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "托付": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōfù",
      "PrimaryDefinition": "to entrust"
    },
    "Keys": {
      "Word": 9614
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "block up, obstruct; stagnant"
    },
    "Keys": {
      "Hanzi": 2750
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2750,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2302,
      "StrokeCount": 12
    }
  },
  "浭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēng",
      "PrimaryDefinition": "river in Hebei province; (Cant.) a ford; to wade"
    },
    "Keys": {
      "Hanzi": 7056
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7056,
      "Radical": "水",
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "出局": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū jú",
      "PrimaryDefinition": "out, out of game"
    },
    "Keys": {
      "Word": 5957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "民歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngē",
      "PrimaryDefinition": "folk song, CL:支[zhi1],首[shou3]"
    },
    "Keys": {
      "Word": 4864
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "洋溢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yángyì",
      "PrimaryDefinition": "reek, brim with, be permeated with, overflow"
    },
    "Keys": {
      "Word": 10220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "著名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùmíng",
      "PrimaryDefinition": "famous, noted, well-known, celebrated"
    },
    "Keys": {
      "Word": 3192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "私房钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīfangqián",
      "PrimaryDefinition": "secret purse, secret stash of money"
    },
    "Keys": {
      "Word": 9308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "民用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínyòng",
      "PrimaryDefinition": "(for) civilian use"
    },
    "Keys": {
      "Word": 8299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "初": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū",
      "PrimaryDefinition": "beginning, initial, primary"
    },
    "Keys": {
      "Hanzi": 889,
      "Word": 1358
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.5",
      "Grade": 3,
      "GeneralStandard": 889,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 667,
      "StrokeCount": 7
    }
  },
  "走过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒuguò",
      "PrimaryDefinition": "to walk past, to pass by"
    },
    "Keys": {
      "Word": 1242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "粘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "viscous, mucous; glutinous"
    },
    "Keys": {
      "Hanzi": 2402,
      "Word": 10637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.5",
      "Grade": 7,
      "GeneralStandard": 2402,
      "Radical": "米",
      "HSK": 7,
      "FrequencyRank": 2240,
      "StrokeCount": 11
    }
  },
  "点评": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnpíng",
      "PrimaryDefinition": "to comment, a point by point commentary"
    },
    "Keys": {
      "Word": 6268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hài",
      "PrimaryDefinition": "12th terrestrial branch"
    },
    "Keys": {
      "Hanzi": 524
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 524,
      "Radical": "亠",
      "FrequencyRank": 3179,
      "RadicalIndex": "8.4",
      "StrokeCount": 6
    }
  },
  "过时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guò shí",
      "PrimaryDefinition": "old-fashioned, out of date, to be later than the time stipulated or agreed upon"
    },
    "Keys": {
      "Word": 4647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "桂花": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guìhuā",
      "PrimaryDefinition": "osmanthus flowers, Osmanthus fragrans"
    },
    "Keys": {
      "Word": 6925
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皇室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huángshì",
      "PrimaryDefinition": "imperial family/house, royalty, imperial house, royal_family, imperial family, royal house, royal line, house, royal family"
    },
    "Keys": {
      "Word": 7190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "流通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liútōng",
      "PrimaryDefinition": "circulate"
    },
    "Keys": {
      "Word": 3703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "侴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǒu",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 6863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6863,
      "Radical": "人",
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "英语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yīngyǔ",
      "PrimaryDefinition": "English, English (language), profit, surplus"
    },
    "Keys": {
      "Word": 1165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "戛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "lance; tap or strike lightly"
    },
    "Keys": {
      "Hanzi": 4788
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4788,
      "Radical": "戈",
      "FrequencyRank": 4503,
      "RadicalIndex": "62.7",
      "StrokeCount": 11
    }
  },
  "延伸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánshēn",
      "PrimaryDefinition": "extend, stretch"
    },
    "Keys": {
      "Word": 4085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "东张西望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngzhāng-xīwàng",
      "PrimaryDefinition": "to look in all directions (idiom), to glance around"
    },
    "Keys": {
      "Word": 6325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刚刚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānggāng",
      "PrimaryDefinition": "just now, scarcely, fresh, only, exactly, a moment ago, hardly, but, just"
    },
    "Keys": {
      "Word": 676
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎn",
      "PrimaryDefinition": "law, canon; documentation; classic, scripture"
    },
    "Keys": {
      "Hanzi": 1092
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "12.6",
      "GeneralStandard": 1092,
      "Radical": "八",
      "HSK": 2,
      "FrequencyRank": 1044,
      "StrokeCount": 8
    }
  },
  "悼念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàoniàn",
      "PrimaryDefinition": "to grieve"
    },
    "Keys": {
      "Word": 6204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guì",
      "PrimaryDefinition": "acetylene"
    },
    "Keys": {
      "Hanzi": 4008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4008,
      "Radical": "火",
      "FrequencyRank": 4970,
      "RadicalIndex": "86.4",
      "StrokeCount": 8
    }
  },
  "乒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīng",
      "PrimaryDefinition": "used with pong for ping pong"
    },
    "Keys": {
      "Hanzi": 456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.5",
      "GeneralStandard": 456,
      "Radical": "丿",
      "HSK": 8,
      "FrequencyRank": 3447,
      "StrokeCount": 6
    }
  },
  "怀着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáizhe",
      "PrimaryDefinition": "Be with"
    },
    "Keys": {
      "Word": 7157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焕发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànfā",
      "PrimaryDefinition": "to shine, to glow, to irradiate, to flash"
    },
    "Keys": {
      "Word": 7182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎn",
      "PrimaryDefinition": "light of the sun; to dry in the sun"
    },
    "Keys": {
      "Hanzi": 4637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4637,
      "Radical": "火",
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "那边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàbian",
      "PrimaryDefinition": "beyond, that side, there"
    },
    "Keys": {
      "Word": 251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "拍板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāibǎn",
      "PrimaryDefinition": "clapper-board, auctioneer's hammer, to beat time with clappers"
    },
    "Keys": {
      "Word": 8452
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笼子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóngzi",
      "PrimaryDefinition": "cage, coop, basket, container"
    },
    "Keys": {
      "Word": 8115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "formerly; ancient; in beginning"
    },
    "Keys": {
      "Hanzi": 1000
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1000,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2388,
      "StrokeCount": 8
    }
  },
  "展开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎnkāi",
      "PrimaryDefinition": "spread out, unfold, open up, launch, develop"
    },
    "Keys": {
      "Word": 2136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "谫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "shallow; stupid"
    },
    "Keys": {
      "Hanzi": 7608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7608,
      "Radical": "言",
      "FrequencyRank": 6760,
      "RadicalIndex": "149.11",
      "StrokeCount": 13
    }
  },
  "邮件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóujiàn",
      "PrimaryDefinition": "mail, postal items, V-mail, mailing, postal matter, post, postbag, posting"
    },
    "Keys": {
      "Word": 2108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "美味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měiwèi",
      "PrimaryDefinition": "delicious, dainty"
    },
    "Keys": {
      "Word": 8239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "便饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànfàn",
      "PrimaryDefinition": "an ordinary meal, simple home cooking"
    },
    "Keys": {
      "Word": 5612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "fine piece of jade"
    },
    "Keys": {
      "Hanzi": 5341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5341,
      "Radical": "玉",
      "FrequencyRank": 4651,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "纮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "string; vast, expansive"
    },
    "Keys": {
      "Hanzi": 6638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6638,
      "Radical": "糸",
      "FrequencyRank": 6474,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "纵深": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòngshēn",
      "PrimaryDefinition": "depth (from front to rear), depth (into a territory), span (of time), (fig.) depth (of deployment, progress, development etc)"
    },
    "Keys": {
      "Word": 10990
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "连续剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánxùjù",
      "PrimaryDefinition": "serialized drama, dramatic series, show in parts"
    },
    "Keys": {
      "Word": 1705
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "堕落": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duòluò",
      "PrimaryDefinition": "degenerate, sink low"
    },
    "Keys": {
      "Word": 6406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīn",
      "PrimaryDefinition": "dawn; early morning; day"
    },
    "Keys": {
      "Hanzi": 3903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3903,
      "Radical": "日",
      "FrequencyRank": 4750,
      "RadicalIndex": "72.4",
      "StrokeCount": 8
    }
  },
  "发放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāfàng",
      "PrimaryDefinition": "provide, grant, extend"
    },
    "Keys": {
      "Word": 4538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "shinbone; calf of leg"
    },
    "Keys": {
      "Hanzi": 4288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4288,
      "Radical": "肉",
      "FrequencyRank": 4209,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "牵扯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānchě",
      "PrimaryDefinition": "to involve, to implicate"
    },
    "Keys": {
      "Word": 8652
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "理念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐniàn",
      "PrimaryDefinition": "sense, conception, idea"
    },
    "Keys": {
      "Word": 7985
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祈祷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qídǎo",
      "PrimaryDefinition": "pray"
    },
    "Keys": {
      "Word": 8600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu",
      "PrimaryDefinition": "die, dice"
    },
    "Keys": {
      "Hanzi": 5481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5481,
      "Radical": "骨",
      "FrequencyRank": 4226,
      "RadicalIndex": "188.4",
      "StrokeCount": 13
    }
  },
  "滦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "river and county in Hebei province"
    },
    "Keys": {
      "Hanzi": 5582
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5582,
      "Radical": "水",
      "FrequencyRank": 4407,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "原因": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuányīn",
      "PrimaryDefinition": "origin, causation, reason, wherefore, causal, breeder, spore, cause, causal agent, whence, causal agency, occasion, root, subject, causality, why, starter"
    },
    "Keys": {
      "Word": 1180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "獒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "mastiff, large fierce dog"
    },
    "Keys": {
      "Hanzi": 5624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5624,
      "Radical": "犬",
      "FrequencyRank": 6263,
      "RadicalIndex": "94.11",
      "StrokeCount": 14
    }
  },
  "湉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5290,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "牒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "documents, records; dispatch"
    },
    "Keys": {
      "Hanzi": 5505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5505,
      "Radical": "片",
      "FrequencyRank": 4122,
      "RadicalIndex": "91.9",
      "StrokeCount": 13
    }
  },
  "星期天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngqītiān",
      "PrimaryDefinition": "Sunday, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "雕刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diāokè",
      "PrimaryDefinition": "statue"
    },
    "Keys": {
      "Word": 6288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "飞机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēijī",
      "PrimaryDefinition": "aerocraft, airplane, machine, craft, aeroplane, ship, aero, avion, aeronef, plane, aircraft"
    },
    "Keys": {
      "Word": 106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "表态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎo tài",
      "PrimaryDefinition": "to declare one's position, to say where one stands"
    },
    "Keys": {
      "Word": 5638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "如今": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rújīn",
      "PrimaryDefinition": "nowadays, now"
    },
    "Keys": {
      "Word": 2826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "技术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìshù",
      "PrimaryDefinition": "mechanics, know-how, prowess, science, Minerva, superior skill, art, competence, technology, craft, craftsmanship, skill, education, technique, artistry, engineering, technological, equipment, technical"
    },
    "Keys": {
      "Word": 1582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "面积": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànjī",
      "PrimaryDefinition": "area unit, area, superficies, superficial, acreage, expanse, surface_area, square_measure, surface area, content, space, square measure"
    },
    "Keys": {
      "Word": 1747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "报仇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào chóu",
      "PrimaryDefinition": "to take revenge, to avenge"
    },
    "Keys": {
      "Word": 5531
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐ",
      "PrimaryDefinition": "bleat of sheep; surname"
    },
    "Keys": {
      "Hanzi": 6588
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6588,
      "Radical": "羊",
      "FrequencyRank": 6208,
      "RadicalIndex": "123.1",
      "StrokeCount": 7
    }
  },
  "甡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "abundant, numerous; crowd"
    },
    "Keys": {
      "Hanzi": 7011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7011,
      "Radical": "生",
      "RadicalIndex": "100.5",
      "StrokeCount": 10
    }
  },
  "徜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "walking and fro; lingering"
    },
    "Keys": {
      "Hanzi": 4914
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4914,
      "Radical": "彳",
      "FrequencyRank": 5620,
      "RadicalIndex": "60.8",
      "StrokeCount": 11
    }
  },
  "男": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nán",
      "PrimaryDefinition": "male, man; son; baron; surname"
    },
    "Keys": {
      "Hanzi": 733,
      "Word": 260
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "102.2",
      "Grade": 1,
      "GeneralStandard": 733,
      "Radical": "田",
      "HSK": 1,
      "FrequencyRank": 602,
      "StrokeCount": 7
    }
  },
  "闪烁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎnshuò",
      "PrimaryDefinition": "twinkle, flicker, glisten"
    },
    "Keys": {
      "Word": 8954
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìbù",
      "PrimaryDefinition": "state, situation, extent, plight, condition"
    },
    "Keys": {
      "Word": 6247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "theatrical plays, opera, drama"
    },
    "Keys": {
      "Hanzi": 2132,
      "Word": 4770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.8",
      "Grade": 6,
      "GeneralStandard": 2132,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 909,
      "StrokeCount": 10
    }
  },
  "一同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yītóng",
      "PrimaryDefinition": "together"
    },
    "Keys": {
      "Word": 5242
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "神态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shéntài",
      "PrimaryDefinition": "appearance, manner, bearing, deportment, look, expression, mien"
    },
    "Keys": {
      "Word": 9039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "叵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǒ",
      "PrimaryDefinition": "cannot, be unable do, improbable; thereupon"
    },
    "Keys": {
      "Hanzi": 3533
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3533,
      "Radical": "口",
      "FrequencyRank": 4318,
      "RadicalIndex": "30.2",
      "StrokeCount": 5
    }
  },
  "祖国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔguó",
      "PrimaryDefinition": "country, China (used only by Chinese), mother_country, China, old_country, fatherland, home, country of origin, mother country, old country, homeland, native land, motherland, metropolis"
    },
    "Keys": {
      "Word": 5411
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "靶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎ",
      "PrimaryDefinition": "target; splashboard on chariot"
    },
    "Keys": {
      "Hanzi": 2850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "177.4",
      "GeneralStandard": 2850,
      "Radical": "革",
      "HSK": 8,
      "FrequencyRank": 3237,
      "StrokeCount": 13
    }
  },
  "芄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3580
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3580,
      "Radical": "艸",
      "FrequencyRank": 5136,
      "RadicalIndex": "140.3",
      "StrokeCount": 6
    }
  },
  "光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāng",
      "PrimaryDefinition": "light, brilliant, shine; only"
    },
    "Keys": {
      "Hanzi": 417,
      "Word": 1527
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.4",
      "Grade": 3,
      "GeneralStandard": 417,
      "Radical": "儿",
      "HSK": 3,
      "FrequencyRank": 290,
      "StrokeCount": 6
    }
  },
  "孳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "breed in large numbers"
    },
    "Keys": {
      "Hanzi": 5271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5271,
      "Radical": "子",
      "FrequencyRank": 4875,
      "RadicalIndex": "39.1",
      "StrokeCount": 12
    }
  },
  "不再": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzài",
      "PrimaryDefinition": "no more/longer"
    },
    "Keys": {
      "Word": 4346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "翻番": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān fān",
      "PrimaryDefinition": "to double, to increase by a certain number of times"
    },
    "Keys": {
      "Word": 6455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "励": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "strive; encourage"
    },
    "Keys": {
      "Hanzi": 702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "19.5",
      "GeneralStandard": 702,
      "Radical": "力",
      "HSK": 5,
      "FrequencyRank": 1468,
      "StrokeCount": 7
    }
  },
  "眼色": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnsè",
      "PrimaryDefinition": "signal made with one's eyes, meaningful glance"
    },
    "Keys": {
      "Word": 10193
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钳子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánzi",
      "PrimaryDefinition": "pliers, pincers, tongs, forceps, vise, clamp, claw (of a crab etc), CL:把[ba3], (dialect) earring"
    },
    "Keys": {
      "Word": 8675
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "簉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "deputy, subordinate; concubine"
    },
    "Keys": {
      "Hanzi": 7879
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7879,
      "Radical": "竹",
      "RadicalIndex": "118.11",
      "StrokeCount": 16
    }
  },
  "赝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "false; counterfeit; bogus; sham"
    },
    "Keys": {
      "Hanzi": 6073
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6073,
      "Radical": "厂",
      "FrequencyRank": 4714,
      "RadicalIndex": "27.14",
      "StrokeCount": 16
    }
  },
  "芜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "luxurious growth of weeds"
    },
    "Keys": {
      "Hanzi": 662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 662,
      "Radical": "艸",
      "FrequencyRank": 3239,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "蓰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "to increase five-fold"
    },
    "Keys": {
      "Hanzi": 7643
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7643,
      "Radical": "艸",
      "FrequencyRank": 7599,
      "RadicalIndex": "140.11",
      "StrokeCount": 14
    }
  },
  "一向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxiàng",
      "PrimaryDefinition": "typically, all_along, up to now, always, consistently, all along, usually, forever"
    },
    "Keys": {
      "Word": 4111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "圉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "stable, corral, enclosure; frontier, border"
    },
    "Keys": {
      "Hanzi": 4837
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4837,
      "Radical": "囗",
      "FrequencyRank": 6542,
      "RadicalIndex": "31.8",
      "StrokeCount": 11
    }
  },
  "远处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuǎnchù",
      "PrimaryDefinition": "distance, bottom, distant place, distant point"
    },
    "Keys": {
      "Word": 4158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "舢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "sampan"
    },
    "Keys": {
      "Hanzi": 4271
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4271,
      "Radical": "舟",
      "FrequencyRank": 5346,
      "RadicalIndex": "137.3",
      "StrokeCount": 9
    }
  },
  "区别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūbié",
      "PrimaryDefinition": "difference, to distinguish, to discriminate, to make a distinction, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 1832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "燕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "swallow (bird); comfort, enjoy"
    },
    "Keys": {
      "Hanzi": 3319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.12",
      "GeneralStandard": 3319,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 1705,
      "StrokeCount": 16
    }
  },
  "利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "gains, advantage, profit, merit"
    },
    "Keys": {
      "Hanzi": 763,
      "Word": 4822
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.5",
      "Grade": 6,
      "GeneralStandard": 763,
      "Radical": "刀",
      "HSK": 2,
      "FrequencyRank": 155,
      "StrokeCount": 7
    }
  },
  "杵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔ",
      "PrimaryDefinition": "pestle; baton used beat clothes"
    },
    "Keys": {
      "Hanzi": 3879
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3879,
      "Radical": "木",
      "FrequencyRank": 4776,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "纵观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòngguān",
      "PrimaryDefinition": "to survey comprehensively, an overall survey"
    },
    "Keys": {
      "Word": 10986
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玻璃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōli",
      "PrimaryDefinition": "plastic, vitrum, putty, vitreous, nylon, window, windowpane, cutter, glass"
    },
    "Keys": {
      "Word": 3265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "盯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "rivet gaze upon, keep eyes on"
    },
    "Keys": {
      "Hanzi": 715,
      "Word": 6301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.2",
      "Grade": 7,
      "GeneralStandard": 715,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 1906,
      "StrokeCount": 7
    }
  },
  "也许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yěxǔ",
      "PrimaryDefinition": "possibly, peradventure, conceivably, assumedly, mayhap, doubtless, ablings, perhaps, perchance, potentially, probably, maybe, presumably, ablins, just"
    },
    "Keys": {
      "Word": 1130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "里边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐbian",
      "PrimaryDefinition": "inside"
    },
    "Keys": {
      "Word": 211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "口罩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuzhào",
      "PrimaryDefinition": "mask (surgical etc)"
    },
    "Keys": {
      "Word": 7849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "disgrace, shame; ashamed; shy"
    },
    "Keys": {
      "Hanzi": 2049
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "123.5",
      "GeneralStandard": 2049,
      "Radical": "羊",
      "HSK": 7,
      "FrequencyRank": 2048,
      "StrokeCount": 10
    }
  },
  "特快": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèkuài",
      "PrimaryDefinition": "express (train, delivery etc)"
    },
    "Keys": {
      "Word": 5074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "屺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ",
      "PrimaryDefinition": "a hill with trees or grass-- some give the opposite meaning"
    },
    "Keys": {
      "Hanzi": 3594
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3594,
      "Radical": "山",
      "FrequencyRank": 8365,
      "RadicalIndex": "46.3",
      "StrokeCount": 6
    }
  },
  "祥和": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiánghé",
      "PrimaryDefinition": "auspicious and peaceful"
    },
    "Keys": {
      "Word": 9943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "世界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìjiè",
      "PrimaryDefinition": "mundane, creation, world, monde, orb, nature, domain"
    },
    "Keys": {
      "Word": 1908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "儿子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "érzi",
      "PrimaryDefinition": "viscount, man-child, boy, male offspring, son"
    },
    "Keys": {
      "Word": 94
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "倚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "rely on, depend on; lean heavily"
    },
    "Keys": {
      "Hanzi": 1957,
      "Word": 10319
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.8",
      "Grade": 7,
      "GeneralStandard": 1957,
      "Radical": "人",
      "HSK": 9,
      "FrequencyRank": 2620,
      "StrokeCount": 10
    }
  },
  "菰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": "wild rice; Zizania latifolia"
    },
    "Keys": {
      "Hanzi": 4768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4768,
      "Radical": "艸",
      "FrequencyRank": 6129,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "连滚带爬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángǔndàipá",
      "PrimaryDefinition": "rolling and crawling, trying frantically to escape (idiom)"
    },
    "Keys": {
      "Word": 8011
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蘅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héng",
      "PrimaryDefinition": "a fragrant plant the root is medicinal"
    },
    "Keys": {
      "Hanzi": 6373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6373,
      "Radical": "艸",
      "FrequencyRank": 5654,
      "RadicalIndex": "140.16",
      "StrokeCount": 19
    }
  },
  "严厉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánlì",
      "PrimaryDefinition": "stern, toughen, maul, severe"
    },
    "Keys": {
      "Word": 4088
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "溺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nì",
      "PrimaryDefinition": "drown; submerge in water; indulge"
    },
    "Keys": {
      "Hanzi": 3020
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3020,
      "Radical": "水",
      "FrequencyRank": 3291,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "粟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "unhusked millet; grain"
    },
    "Keys": {
      "Hanzi": 2583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2583,
      "Radical": "米",
      "FrequencyRank": 2883,
      "RadicalIndex": "119.6",
      "StrokeCount": 12
    }
  },
  "妞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niū",
      "PrimaryDefinition": "girl"
    },
    "Keys": {
      "Hanzi": 3813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3813,
      "Radical": "女",
      "FrequencyRank": 3200,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "免得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎnde",
      "PrimaryDefinition": "conj.: so as not to, so as to avoid"
    },
    "Keys": {
      "Word": 4858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "埇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "name of bridge"
    },
    "Keys": {
      "Hanzi": 6948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6948,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "冷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěng",
      "PrimaryDefinition": "cold, cool; lonely"
    },
    "Keys": {
      "Hanzi": 838,
      "Word": 209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.5",
      "Grade": 1,
      "GeneralStandard": 838,
      "Radical": "冫",
      "HSK": 1,
      "FrequencyRank": 700,
      "StrokeCount": 7
    }
  },
  "追赶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīgǎn",
      "PrimaryDefinition": "to pursue, to chase after, to accelerate, to catch up with, to overtake"
    },
    "Keys": {
      "Word": 10915
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "fear, be afraid of, dread"
    },
    "Keys": {
      "Hanzi": 2442
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2442,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1616,
      "StrokeCount": 11
    }
  },
  "笏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": "tablet held by someone having authority"
    },
    "Keys": {
      "Hanzi": 4559
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4559,
      "Radical": "竹",
      "FrequencyRank": 4859,
      "RadicalIndex": "118.4",
      "StrokeCount": 10
    }
  },
  "火暴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒbào",
      "PrimaryDefinition": "fiery (temper), popular, flourishing, prosperous, lively, variant of 火爆[huo3 bao4]"
    },
    "Keys": {
      "Word": 7239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "联": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "connect, join; associate, ally"
    },
    "Keys": {
      "Hanzi": 2544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "128.6",
      "GeneralStandard": 2544,
      "Radical": "耳",
      "HSK": 3,
      "FrequencyRank": 356,
      "StrokeCount": 12
    }
  },
  "升": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēng",
      "PrimaryDefinition": "arise, go up; hoist; advance"
    },
    "Keys": {
      "Hanzi": 125,
      "Word": 1884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "24.2",
      "Grade": 3,
      "GeneralStandard": 125,
      "Radical": "十",
      "HSK": 3,
      "FrequencyRank": 837,
      "StrokeCount": 4
    }
  },
  "多方面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōfāngmiàn",
      "PrimaryDefinition": "many-sided, in many aspects"
    },
    "Keys": {
      "Word": 4529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "燃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rán",
      "PrimaryDefinition": "burn; light fire, ignite"
    },
    "Keys": {
      "Hanzi": 3376
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.12",
      "GeneralStandard": 3376,
      "Radical": "火",
      "HSK": 4,
      "FrequencyRank": 1553,
      "StrokeCount": 16
    }
  },
  "誉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "fame, reputation; praise"
    },
    "Keys": {
      "Hanzi": 3024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "GeneralStandard": 3024,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 1676,
      "StrokeCount": 13
    }
  },
  "缯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": "silk fabrics; surname"
    },
    "Keys": {
      "Hanzi": 6023
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6023,
      "Radical": "糸",
      "FrequencyRank": 5719,
      "RadicalIndex": "120.12",
      "StrokeCount": 15
    }
  },
  "健身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn shēn",
      "PrimaryDefinition": "to exercise, to keep fit, to work out, physical exercise"
    },
    "Keys": {
      "Word": 2606
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鞫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "interrogate, question"
    },
    "Keys": {
      "Hanzi": 6317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6317,
      "Radical": "革",
      "FrequencyRank": 5409,
      "RadicalIndex": "177.9",
      "StrokeCount": 18
    }
  },
  "砆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "1/2 pr stone"
    },
    "Keys": {
      "Hanzi": 6809
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6809,
      "Radical": "石",
      "RadicalIndex": "112.4",
      "StrokeCount": 9
    }
  },
  "鹝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "pheasant"
    },
    "Keys": {
      "Hanzi": 7761
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7761,
      "Radical": "鳥",
      "RadicalIndex": "196.1",
      "StrokeCount": 15
    }
  },
  "演戏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn xì",
      "PrimaryDefinition": "pretence, roleplay, playact, act, act in a play, playacting, put on a play, put on or act in a play, pretend, play act, play"
    },
    "Keys": {
      "Word": 10204
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "走": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒu",
      "PrimaryDefinition": "walk"
    },
    "Keys": {
      "Hanzi": 625,
      "Radical": 156,
      "Word": 487
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156",
      "Grade": 1,
      "GeneralStandard": 625,
      "Radical": "走",
      "HSK": 1,
      "FrequencyRank": 207,
      "StrokeCount": 7
    }
  },
  "普通人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔtōngrén",
      "PrimaryDefinition": "ordinary person, private citizen, people, the person in the street"
    },
    "Keys": {
      "Word": 8583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "记录": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìlù",
      "PrimaryDefinition": "record, note"
    },
    "Keys": {
      "Word": 1579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "寓意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùyì",
      "PrimaryDefinition": "moral (of a story), lesson to be learned, implication, message, import, metaphorical meaning"
    },
    "Keys": {
      "Word": 10529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chà",
      "PrimaryDefinition": "be surprised, be shocked"
    },
    "Keys": {
      "Hanzi": 4057
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "GeneralStandard": 4057,
      "Radical": "言",
      "HSK": 9,
      "FrequencyRank": 2728,
      "StrokeCount": 8
    }
  },
  "闪电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎndiàn",
      "PrimaryDefinition": "lightning, CL:道[dao4]"
    },
    "Keys": {
      "Word": 2835
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "防范": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángfàn",
      "PrimaryDefinition": "be on guard, keep lookout"
    },
    "Keys": {
      "Word": 4557
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "私家车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sījiāchē",
      "PrimaryDefinition": "private car"
    },
    "Keys": {
      "Word": 9309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "撮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuō",
      "PrimaryDefinition": "little bit, small amount, pinch"
    },
    "Keys": {
      "Hanzi": 3197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3197,
      "Radical": "手",
      "FrequencyRank": 3485,
      "RadicalIndex": "64.12",
      "StrokeCount": 15
    }
  },
  "说话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō huà",
      "PrimaryDefinition": "philosophize, thrum, talk, peach, babble out, speech, articulate, chat, speak, babble, gossip, blab out, sing, address, tattle, blab, mouth, say"
    },
    "Keys": {
      "Word": 345
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "酚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn",
      "PrimaryDefinition": "carbolic acid; phenol"
    },
    "Keys": {
      "Hanzi": 4787
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4787,
      "Radical": "酉",
      "FrequencyRank": 4391,
      "RadicalIndex": "164.4",
      "StrokeCount": 11
    }
  },
  "蛛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "spider"
    },
    "Keys": {
      "Hanzi": 2635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2635,
      "Radical": "虫",
      "FrequencyRank": 2653,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "亨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēng",
      "PrimaryDefinition": "smoothly, progressing, no trouble"
    },
    "Keys": {
      "Hanzi": 3755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3755,
      "Radical": "亠",
      "FrequencyRank": 2146,
      "RadicalIndex": "8.5",
      "StrokeCount": 7
    }
  },
  "怎么样": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zěnmeyàng",
      "PrimaryDefinition": "How are things?"
    },
    "Keys": {
      "Word": 1198
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "娅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "mutual term of address used by sons-in-law; a brother-in-law"
    },
    "Keys": {
      "Hanzi": 4382
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4382,
      "Radical": "女",
      "FrequencyRank": 2877,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "中奖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòng jiǎng",
      "PrimaryDefinition": "to win a prize, a successful gamble"
    },
    "Keys": {
      "Word": 3183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "打捞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎlāo",
      "PrimaryDefinition": "to salvage, to dredge, to fish out (person or object from the sea)"
    },
    "Keys": {
      "Word": 6103
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "clean, purify, pure"
    },
    "Keys": {
      "Hanzi": 1663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1663,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1531,
      "StrokeCount": 9
    }
  },
  "没准儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méizhǔnr5",
      "PrimaryDefinition": "not sure, maybe"
    },
    "Keys": {
      "Word": 8223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "report to, petition"
    },
    "Keys": {
      "Hanzi": 2987
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2987,
      "Radical": "示",
      "FrequencyRank": 2945,
      "RadicalIndex": "113.8",
      "StrokeCount": 13
    }
  },
  "网吧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngbā",
      "PrimaryDefinition": "Internet café"
    },
    "Keys": {
      "Word": 5132
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "潵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7814,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "照明": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàomíng",
      "PrimaryDefinition": "illuminate"
    },
    "Keys": {
      "Word": 10680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "special pattern of embroidery"
    },
    "Keys": {
      "Hanzi": 6224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6224,
      "Radical": "黹",
      "FrequencyRank": 6435,
      "RadicalIndex": "204.5",
      "StrokeCount": 17
    }
  },
  "拜会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàihuì",
      "PrimaryDefinition": "pay sb. a visit"
    },
    "Keys": {
      "Word": 5479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuè",
      "PrimaryDefinition": "jeer"
    },
    "Keys": {
      "Hanzi": 4998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4998,
      "Radical": "言",
      "FrequencyRank": 3668,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "答应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāying",
      "PrimaryDefinition": "agree, promise"
    },
    "Keys": {
      "Word": 580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "连任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián rèn",
      "PrimaryDefinition": "to continue in (a political) office, to serve for another term of office"
    },
    "Keys": {
      "Word": 8014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "stone"
    },
    "Keys": {
      "Radical": 112,
      "Hanzi": 216
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112",
      "GeneralStandard": 216,
      "Radical": "石",
      "HSK": 3,
      "FrequencyRank": 414,
      "StrokeCount": 5
    }
  },
  "飕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "sound of wind; blow chilly"
    },
    "Keys": {
      "Hanzi": 5534
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5534,
      "Radical": "風",
      "FrequencyRank": 4266,
      "RadicalIndex": "182.1",
      "StrokeCount": 13
    }
  },
  "犭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quǎn",
      "PrimaryDefinition": "dog"
    },
    "Keys": {
      "Radical": 94
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "哗变": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huábiàn",
      "PrimaryDefinition": "mutiny, rebellion"
    },
    "Keys": {
      "Word": 7133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāyuán",
      "PrimaryDefinition": "home, homestead, native heath, homestall, homeland"
    },
    "Keys": {
      "Word": 4704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "陨石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔnshí",
      "PrimaryDefinition": "meteorite, meteor, aerolite, stony meteorite"
    },
    "Keys": {
      "Word": 10572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "西瓜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīgua",
      "PrimaryDefinition": "water melon, watermelon"
    },
    "Keys": {
      "Word": 2992
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "人文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénwén",
      "PrimaryDefinition": "letters, humanity, humanities"
    },
    "Keys": {
      "Word": 8850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "箕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "sieve; dust pan, garbage bag"
    },
    "Keys": {
      "Hanzi": 3120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3120,
      "Radical": "竹",
      "FrequencyRank": 4357,
      "RadicalIndex": "118.8",
      "StrokeCount": 14
    }
  },
  "四周": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìzhōu",
      "PrimaryDefinition": "all around, fringe, surrounding, periphery, environment, outer boundary, on all sides, on every side, vicinity"
    },
    "Keys": {
      "Word": 3942
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "前无古人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánwúgǔrén",
      "PrimaryDefinition": "Before no ancient people"
    },
    "Keys": {
      "Word": 8667
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "职能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhínéng",
      "PrimaryDefinition": "function, role"
    },
    "Keys": {
      "Word": 4225
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "仓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāng",
      "PrimaryDefinition": "granary; berth; sea"
    },
    "Keys": {
      "Hanzi": 149
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.2",
      "GeneralStandard": 149,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 1882,
      "StrokeCount": 4
    }
  },
  "剪子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnzi",
      "PrimaryDefinition": "clippers, scissors, shears, CL:把[ba3]"
    },
    "Keys": {
      "Word": 3600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "载体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàitǐ",
      "PrimaryDefinition": "carrier (chemistry), vector (epidemiology), vehicle or medium"
    },
    "Keys": {
      "Word": 10593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家乡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāxiāng",
      "PrimaryDefinition": "fatherland, country, home, country of origin, native place, mother country, hometown, native land, motherland, homeland"
    },
    "Keys": {
      "Word": 1588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "碌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "rough, uneven, rocky; mediocre"
    },
    "Keys": {
      "Hanzi": 2882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.8",
      "GeneralStandard": 2882,
      "Radical": "石",
      "HSK": 8,
      "FrequencyRank": 2548,
      "StrokeCount": 13
    }
  },
  "有利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒulì",
      "PrimaryDefinition": "be advantageous/beneficial"
    },
    "Keys": {
      "Word": 2116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "带来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài lái",
      "PrimaryDefinition": "bring, redound, impart, bring_on, bring on, express, fetch, lend, convey, contribute, bring about, bestow, carry, bring oneself, add, usher, incur, land"
    },
    "Keys": {
      "Word": 597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "萨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sà",
      "PrimaryDefinition": "Buddhist gods or immortals"
    },
    "Keys": {
      "Hanzi": 2217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.9",
      "GeneralStandard": 2217,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 1046,
      "StrokeCount": 11
    }
  },
  "内衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèiyī",
      "PrimaryDefinition": "undergarment, underwear, CL:件[jian4]"
    },
    "Keys": {
      "Word": 4890
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "棠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "crab apple tree; wild plums"
    },
    "Keys": {
      "Hanzi": 2608
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2608,
      "Radical": "木",
      "FrequencyRank": 2489,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "诸位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūwèi",
      "PrimaryDefinition": "(pron) everyone, Ladies and Gentlemen, Sirs"
    },
    "Keys": {
      "Word": 5374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "宄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǐ",
      "PrimaryDefinition": "a traitor; a villain"
    },
    "Keys": {
      "Hanzi": 6519
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6519,
      "Radical": "宀",
      "FrequencyRank": 5518,
      "RadicalIndex": "40.2",
      "StrokeCount": 5
    }
  },
  "蝙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "bat"
    },
    "Keys": {
      "Hanzi": 3251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3251,
      "Radical": "虫",
      "FrequencyRank": 3829,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "眍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōu",
      "PrimaryDefinition": "sunken"
    },
    "Keys": {
      "Hanzi": 4189
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4189,
      "Radical": "目",
      "FrequencyRank": 6818,
      "RadicalIndex": "109.4",
      "StrokeCount": 9
    }
  },
  "学术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéshù",
      "PrimaryDefinition": "ology, academic, systematic learning, science, learning"
    },
    "Keys": {
      "Word": 3054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "雊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "the crow of a male pheasant"
    },
    "Keys": {
      "Hanzi": 7564
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7564,
      "Radical": "隹",
      "FrequencyRank": 8368,
      "RadicalIndex": "172.5",
      "StrokeCount": 13
    }
  },
  "帮忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāng máng",
      "PrimaryDefinition": "assist, aid, smile, lend a hand, do a favour, help, oblige, give a hand, do favor, do a good turn"
    },
    "Keys": {
      "Word": 14
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "祕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "mysterious, secret, abstruse"
    },
    "Keys": {
      "Hanzi": 6906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6906,
      "Radical": "示",
      "FrequencyRank": 5927,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "提防": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīfang",
      "PrimaryDefinition": "to guard against, to be vigilant, watch you don't (slip), also pr. [ti2 fang2]"
    },
    "Keys": {
      "Word": 6235
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chē",
      "PrimaryDefinition": "cart"
    },
    "Keys": {
      "Hanzi": 100,
      "Radical": 159,
      "Word": 40
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159",
      "Grade": 1,
      "GeneralStandard": 100,
      "Radical": "車",
      "HSK": 1,
      "FrequencyRank": 361,
      "StrokeCount": 4
    }
  },
  "缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": "contract, draw in, reduce"
    },
    "Keys": {
      "Hanzi": 3188,
      "Word": 9375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.11",
      "Grade": 7,
      "GeneralStandard": 3188,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 1304,
      "StrokeCount": 14
    }
  },
  "士气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìqì",
      "PrimaryDefinition": "morale"
    },
    "Keys": {
      "Word": 9127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "痊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quán",
      "PrimaryDefinition": "be healed, be cured; recover"
    },
    "Keys": {
      "Hanzi": 2381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2381,
      "Radical": "疒",
      "FrequencyRank": 3618,
      "RadicalIndex": "104.6",
      "StrokeCount": 11
    }
  },
  "䗛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "a kind of insect"
    },
    "Keys": {
      "Hanzi": 7865
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7865,
      "Radical": "虫",
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "裂缝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièfèng",
      "PrimaryDefinition": "crack, crevice, CL:道[dao4]"
    },
    "Keys": {
      "Word": 8056
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "曦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "sunlight, sunshine, early dawn"
    },
    "Keys": {
      "Hanzi": 6428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6428,
      "Radical": "日",
      "FrequencyRank": 3744,
      "RadicalIndex": "72.16",
      "StrokeCount": 20
    }
  },
  "纾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "loosen, relax, relieve; extricate"
    },
    "Keys": {
      "Hanzi": 3825
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3825,
      "Radical": "糸",
      "FrequencyRank": 4392,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "椸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "rack"
    },
    "Keys": {
      "Hanzi": 7500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7500,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "循序渐进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnxù-jiànjìn",
      "PrimaryDefinition": "in sequence, step by step (idiom), to make steady progress incrementally"
    },
    "Keys": {
      "Word": 10151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一次性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīcìxìng",
      "PrimaryDefinition": "one-off (offer), one-time, single-use, disposable (goods)"
    },
    "Keys": {
      "Word": 5231
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "汾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fén",
      "PrimaryDefinition": "river in Shanxi province"
    },
    "Keys": {
      "Hanzi": 3776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3776,
      "Radical": "水",
      "FrequencyRank": 3766,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "憾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "to regret, remorse; dissatisfied"
    },
    "Keys": {
      "Hanzi": 3381
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.13",
      "GeneralStandard": 3381,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 2269,
      "StrokeCount": 16
    }
  },
  "逡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūn",
      "PrimaryDefinition": "retreat, withdraw, fall back"
    },
    "Keys": {
      "Hanzi": 4698
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4698,
      "Radical": "辵",
      "FrequencyRank": 4984,
      "RadicalIndex": "162.7",
      "StrokeCount": 10
    }
  },
  "澄清": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngqīng",
      "PrimaryDefinition": "clear up, clarify"
    },
    "Keys": {
      "Word": 5887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辫子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànzi",
      "PrimaryDefinition": "plait, braid, pigtail, a mistake or shortcoming that may be exploited by an opponent, handle, CL:根[gen1],條|条[tiao2]"
    },
    "Keys": {
      "Word": 5620
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "炻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "a kind of china"
    },
    "Keys": {
      "Hanzi": 4325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4325,
      "Radical": "火",
      "FrequencyRank": 5986,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "幛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "scroll of silk or cloth presente"
    },
    "Keys": {
      "Hanzi": 5727
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5727,
      "Radical": "巾",
      "FrequencyRank": 5932,
      "RadicalIndex": "50.11",
      "StrokeCount": 14
    }
  },
  "罔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "net; deceive; libel; negative"
    },
    "Keys": {
      "Hanzi": 3938
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3938,
      "Radical": "网",
      "FrequencyRank": 4200,
      "RadicalIndex": "122.3",
      "StrokeCount": 8
    }
  },
  "萌发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méngfā",
      "PrimaryDefinition": "to sprout, to shoot, to bud"
    },
    "Keys": {
      "Word": 8247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "虾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiā",
      "PrimaryDefinition": "shrimp, prawn"
    },
    "Keys": {
      "Hanzi": 1498,
      "Word": 9847
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.3",
      "Grade": 7,
      "GeneralStandard": 1498,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2847,
      "StrokeCount": 9
    }
  },
  "联欢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánhuān",
      "PrimaryDefinition": "to have a get-together, celebration, party"
    },
    "Keys": {
      "Word": 8021
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孕妇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnfù",
      "PrimaryDefinition": "pregnant woman"
    },
    "Keys": {
      "Word": 10571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "优异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuyì",
      "PrimaryDefinition": "excellent, outstanding"
    },
    "Keys": {
      "Word": 10449
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàqī",
      "PrimaryDefinition": "next time"
    },
    "Keys": {
      "Word": 9867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沸沸扬扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèifèi-yángyáng",
      "PrimaryDefinition": "bubbling and gurgling, hubbubing, abuzz"
    },
    "Keys": {
      "Word": 6529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "表演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎoyǎn",
      "PrimaryDefinition": "perform, act, play, demonstrate"
    },
    "Keys": {
      "Word": 1308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "谝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piǎn",
      "PrimaryDefinition": "brag, boast; quibble"
    },
    "Keys": {
      "Hanzi": 5009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5009,
      "Radical": "言",
      "FrequencyRank": 4370,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "粉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěn",
      "PrimaryDefinition": "powder, face powder; plaster"
    },
    "Keys": {
      "Hanzi": 2053,
      "Word": 6549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "119.4",
      "Grade": 7,
      "GeneralStandard": 2053,
      "Radical": "米",
      "HSK": 6,
      "FrequencyRank": 1498,
      "StrokeCount": 10
    }
  },
  "矧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shěn",
      "PrimaryDefinition": "much more, still more; the gums"
    },
    "Keys": {
      "Hanzi": 6852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6852,
      "Radical": "矢",
      "FrequencyRank": 6671,
      "RadicalIndex": "111.4",
      "StrokeCount": 9
    }
  },
  "狸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "fox"
    },
    "Keys": {
      "Hanzi": 2004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2004,
      "Radical": "犬",
      "FrequencyRank": 2738,
      "RadicalIndex": "94.7",
      "StrokeCount": 10
    }
  },
  "一回事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīhuíshì",
      "PrimaryDefinition": "one thing, the same as"
    },
    "Keys": {
      "Word": 10327
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "达成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá chéng",
      "PrimaryDefinition": "reach (an agreement)"
    },
    "Keys": {
      "Word": 3369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "谛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": "careful, attentive"
    },
    "Keys": {
      "Hanzi": 5008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5008,
      "Radical": "言",
      "FrequencyRank": 3338,
      "RadicalIndex": "149.9",
      "StrokeCount": 11
    }
  },
  "琏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎn",
      "PrimaryDefinition": "vessel used to hold grain offerings"
    },
    "Keys": {
      "Hanzi": 4713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4713,
      "Radical": "玉",
      "FrequencyRank": 3817,
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "愣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèng",
      "PrimaryDefinition": "be in a daze"
    },
    "Keys": {
      "Hanzi": 2774,
      "Word": 7974
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "Grade": 7,
      "GeneralStandard": 2774,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 2263,
      "StrokeCount": 12
    }
  },
  "格格不入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gégé-bùrù",
      "PrimaryDefinition": "(idiom) inharmonious, incompatible"
    },
    "Keys": {
      "Word": 6731
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "勾画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōuhuà",
      "PrimaryDefinition": "to sketch out, to delineate"
    },
    "Keys": {
      "Word": 6808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "陨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔn",
      "PrimaryDefinition": "fall, slip; let fall; die"
    },
    "Keys": {
      "Hanzi": 1731
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.7",
      "GeneralStandard": 1731,
      "Radical": "阜",
      "HSK": 8,
      "FrequencyRank": 3295,
      "StrokeCount": 9
    }
  },
  "掩护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnhù",
      "PrimaryDefinition": "to screen, to shield, to cover, protection, cover, CL:面[mian4]"
    },
    "Keys": {
      "Word": 10190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阿姨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āyí",
      "PrimaryDefinition": "aunt, child's address to woman of similar age as parents, girl, babysitter, aunty, elderly unattached woman, cr#4 eche attendant, maiden aunt, family nurse, nursery/cr#4eche attendant, nurse, mother's sister, auntie, nursery eche attendant, child's address to woman of similar age, one's mother's sister"
    },
    "Keys": {
      "Word": 2227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "邘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "state in Henan province"
    },
    "Keys": {
      "Hanzi": 6508
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6508,
      "Radical": "邑",
      "FrequencyRank": 7989,
      "RadicalIndex": "163.3",
      "StrokeCount": 5
    }
  },
  "沿线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánxiàn",
      "PrimaryDefinition": "along the line (e.g. railway), the region near the line"
    },
    "Keys": {
      "Word": 10184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "明白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngbai",
      "PrimaryDefinition": "clarify, downright, recognize, agnize, tumble, follow, make out, catch, understand, discern, think, know, seize, agnise, twig, realize, get, realization"
    },
    "Keys": {
      "Word": 243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "狂欢节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Kuánghuānjié",
      "PrimaryDefinition": "carnival"
    },
    "Keys": {
      "Word": 7887
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "照相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào xiàng",
      "PrimaryDefinition": "to take a photograph"
    },
    "Keys": {
      "Word": 1207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "只顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐgù",
      "PrimaryDefinition": "solely preoccupied (with one thing), engrossed, focusing (on sth), to look after only one aspect"
    },
    "Keys": {
      "Word": 5356
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "醑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": "to strain spirits"
    },
    "Keys": {
      "Hanzi": 7848
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7848,
      "Radical": "酉",
      "FrequencyRank": 8313,
      "RadicalIndex": "164.9",
      "StrokeCount": 16
    }
  },
  "块": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "piece, lump; dollar"
    },
    "Keys": {
      "Hanzi": 652,
      "Word": 200
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "Grade": 1,
      "GeneralStandard": 652,
      "Radical": "土",
      "HSK": 1,
      "FrequencyRank": 793,
      "StrokeCount": 7
    }
  },
  "茏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "tall grass; water-weeds"
    },
    "Keys": {
      "Hanzi": 3857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3857,
      "Radical": "艸",
      "FrequencyRank": 4743,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "翷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8022
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8022,
      "Radical": "羽",
      "RadicalIndex": "124.12",
      "StrokeCount": 18
    }
  },
  "绫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "thin silk, damask silk"
    },
    "Keys": {
      "Hanzi": 5027
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5027,
      "Radical": "糸",
      "FrequencyRank": 3262,
      "RadicalIndex": "120.8",
      "StrokeCount": 11
    }
  },
  "潜艇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiántǐng",
      "PrimaryDefinition": "submarine"
    },
    "Keys": {
      "Word": 8678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "波澜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bōlán",
      "PrimaryDefinition": "billows, great waves (fig. of a story with great momentum)"
    },
    "Keys": {
      "Word": 5661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūn",
      "PrimaryDefinition": "tangled hemp, raveled silk; vague, confused"
    },
    "Keys": {
      "Hanzi": 7458
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7458,
      "Radical": "糸",
      "FrequencyRank": 7546,
      "RadicalIndex": "120.1",
      "StrokeCount": 12
    }
  },
  "恔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "cheerful; bright, sagacious"
    },
    "Keys": {
      "Hanzi": 6899
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6899,
      "Radical": "心",
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "boundary, limit, line"
    },
    "Keys": {
      "Hanzi": 1323,
      "Word": 9903
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.6",
      "Grade": 7,
      "GeneralStandard": 1323,
      "Radical": "阜",
      "HSK": 4,
      "FrequencyRank": 613,
      "StrokeCount": 8
    }
  },
  "虚伪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūwěi",
      "PrimaryDefinition": "false, hypocritical, artificial, sham"
    },
    "Keys": {
      "Word": 10095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "to kneel for a long time, to go down on hands and knees"
    },
    "Keys": {
      "Hanzi": 7673
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7673,
      "Radical": "足",
      "FrequencyRank": 6249,
      "RadicalIndex": "157.7",
      "StrokeCount": 14
    }
  },
  "㟃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "(corrupted form of 司) to have charge of; to preside over, a (governmental) department"
    },
    "Keys": {
      "Hanzi": 6686
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6686,
      "Radical": "山",
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "搞好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo hǎo",
      "PrimaryDefinition": "to do well at, to do a good job"
    },
    "Keys": {
      "Word": 3489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "均": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "equal, even, fair; all, also"
    },
    "Keys": {
      "Hanzi": 640
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "GeneralStandard": 640,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 903,
      "StrokeCount": 7
    }
  },
  "剁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": "chop by pounding, mince, hash"
    },
    "Keys": {
      "Hanzi": 3982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3982,
      "Radical": "刀",
      "FrequencyRank": 4434,
      "RadicalIndex": "18.6",
      "StrokeCount": 8
    }
  },
  "妭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6742,
      "Radical": "女",
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "夜校": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yèxiào",
      "PrimaryDefinition": "evening school, night school"
    },
    "Keys": {
      "Word": 10266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "会意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìyì",
      "PrimaryDefinition": "combined ideogram (one of the Six Methods 六書|六书[liu4 shu1] of forming Chinese characters), Chinese character that combines the meanings of existing elements, also known as joint ideogram or associative compounds, to comprehend without being told explicitly, to cotton on, knowing (smile, glance etc)"
    },
    "Keys": {
      "Word": 7222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "semi-annual ceremony of purification"
    },
    "Keys": {
      "Hanzi": 5601
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5601,
      "Radical": "示",
      "FrequencyRank": 6727,
      "RadicalIndex": "113.9",
      "StrokeCount": 13
    }
  },
  "诏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "decree, proclaim; imperial decree"
    },
    "Keys": {
      "Hanzi": 3799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3799,
      "Radical": "言",
      "FrequencyRank": 2684,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "豪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háo",
      "PrimaryDefinition": "brave, heroic, chivalrous"
    },
    "Keys": {
      "Hanzi": 3141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "152.7",
      "GeneralStandard": 3141,
      "Radical": "豕",
      "HSK": 5,
      "FrequencyRank": 1513,
      "StrokeCount": 14
    }
  },
  "团长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuánzhǎng",
      "PrimaryDefinition": "head of delegation, regimental commander, head of troupe/etc., chief of a delegation, head of a troupe, troupe, head of delegation/troupe/etc., chairman of a delegation, chief of a troupe, colonel, head of a delegation, etc."
    },
    "Keys": {
      "Word": 3991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无奈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúnài",
      "PrimaryDefinition": "be helpless"
    },
    "Keys": {
      "Word": 4015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "补救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔjiù",
      "PrimaryDefinition": "to remedy"
    },
    "Keys": {
      "Word": 5691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凭着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngzhe",
      "PrimaryDefinition": "Depend"
    },
    "Keys": {
      "Word": 8559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不正之风": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhèngzhīfēng",
      "PrimaryDefinition": "unhealthy tendency"
    },
    "Keys": {
      "Word": 5684
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "stone mill; grind; break apart"
    },
    "Keys": {
      "Hanzi": 7147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7147,
      "Radical": "石",
      "FrequencyRank": 7916,
      "RadicalIndex": "112.6",
      "StrokeCount": 11
    }
  },
  "宇宙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔzhòu",
      "PrimaryDefinition": "all, cosmic, existence, world, creation, system, macrocosm, metagalactic, nature, cosmos, universal, universe"
    },
    "Keys": {
      "Word": 10508
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轰动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōngdòng",
      "PrimaryDefinition": "to cause a sensation, to create a stir in (a place), commotion, controversy"
    },
    "Keys": {
      "Word": 7071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngyú",
      "PrimaryDefinition": "use in, use on, use for"
    },
    "Keys": {
      "Word": 4134
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": "eye; eyelet, hole, opening"
    },
    "Keys": {
      "Hanzi": 2263,
      "Word": 1124
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.6",
      "Grade": 2,
      "GeneralStandard": 2263,
      "Radical": "目",
      "HSK": 2,
      "FrequencyRank": 281,
      "StrokeCount": 11
    }
  },
  "梦想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mèngxiǎng",
      "PrimaryDefinition": "fond dream"
    },
    "Keys": {
      "Word": 2733
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "上次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngcì",
      "PrimaryDefinition": "last time"
    },
    "Keys": {
      "Word": 321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "喋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "nag; chatter, babble, twitter"
    },
    "Keys": {
      "Hanzi": 5128
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5128,
      "Radical": "口",
      "FrequencyRank": 3633,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "大包大揽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàbāodàlǎn",
      "PrimaryDefinition": "to take complete charge (idiom)"
    },
    "Keys": {
      "Word": 6110
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "fault, defect; error, mistake"
    },
    "Keys": {
      "Hanzi": 3989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3989,
      "Radical": "口",
      "FrequencyRank": 3225,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "沩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "name of a river in Shanxi"
    },
    "Keys": {
      "Hanzi": 3781
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3781,
      "Radical": "水",
      "FrequencyRank": 7923,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "回去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí  qu",
      "PrimaryDefinition": "go_home, go_back, retrocede, go back, be back, go, return"
    },
    "Keys": {
      "Word": 155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "律": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "statute, principle, regulation"
    },
    "Keys": {
      "Hanzi": 1583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.6",
      "GeneralStandard": 1583,
      "Radical": "彳",
      "HSK": 4,
      "FrequencyRank": 526,
      "StrokeCount": 9
    }
  },
  "趋于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qūyú",
      "PrimaryDefinition": "incline to, trend, tend towards, tend"
    },
    "Keys": {
      "Word": 8782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāi",
      "PrimaryDefinition": "(Cant.) to tear, to rip"
    },
    "Keys": {
      "Hanzi": 2668,
      "Word": 5469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2668,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 4253,
      "StrokeCount": 12
    }
  },
  "魏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "kingdom of Wei; surname"
    },
    "Keys": {
      "Hanzi": 3416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "194.8",
      "GeneralStandard": 3416,
      "Radical": "鬼",
      "HSK": 9,
      "FrequencyRank": 1648,
      "StrokeCount": 17
    }
  },
  "总结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngjié",
      "PrimaryDefinition": "to sum up, to conclude, summary, résumé, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2224
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "和平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hépíng",
      "PrimaryDefinition": "mild, peaceful"
    },
    "Keys": {
      "Word": 1547
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "铍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī",
      "PrimaryDefinition": "beryllium"
    },
    "Keys": {
      "Hanzi": 4545
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4545,
      "Radical": "金",
      "FrequencyRank": 5709,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "出口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū kǒu",
      "PrimaryDefinition": "export"
    },
    "Keys": {
      "Word": 2332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "财": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cái",
      "PrimaryDefinition": "wealth, valuables, riches"
    },
    "Keys": {
      "Hanzi": 756
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.3",
      "GeneralStandard": 756,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 680,
      "StrokeCount": 7
    }
  },
  "清": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "clear, pure, clean; peaceful"
    },
    "Keys": {
      "Hanzi": 2410,
      "Word": 4943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 6,
      "GeneralStandard": 2410,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 335,
      "StrokeCount": 11
    }
  },
  "浓厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nónghòu",
      "PrimaryDefinition": "thick, strong, dense, rich, pronounced"
    },
    "Keys": {
      "Word": 8440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "代理人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàilǐrén",
      "PrimaryDefinition": "agent"
    },
    "Keys": {
      "Word": 6145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "掾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "a general designation of officials"
    },
    "Keys": {
      "Hanzi": 5077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5077,
      "Radical": "手",
      "FrequencyRank": 5441,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "红润": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóngrùn",
      "PrimaryDefinition": "ruddy, rosy, florid"
    },
    "Keys": {
      "Word": 7081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiá",
      "PrimaryDefinition": "tongs, pincers; dagger; sword"
    },
    "Keys": {
      "Hanzi": 4867
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4867,
      "Radical": "金",
      "FrequencyRank": 6536,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "使者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐzhě",
      "PrimaryDefinition": "emissary, envoy"
    },
    "Keys": {
      "Word": 9126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō",
      "PrimaryDefinition": "open up, clear; exempt"
    },
    "Keys": {
      "Hanzi": 3432,
      "Word": 7234
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "150.1",
      "Grade": 7,
      "GeneralStandard": 3432,
      "Radical": "谷",
      "HSK": 9,
      "FrequencyRank": 2954,
      "StrokeCount": 17
    }
  },
  "奸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "crafty, villainous, false"
    },
    "Keys": {
      "Hanzi": 577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.3",
      "GeneralStandard": 577,
      "Radical": "女",
      "HSK": 9,
      "FrequencyRank": 1992,
      "StrokeCount": 6
    }
  },
  "蓝领": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lánlǐng",
      "PrimaryDefinition": "blue collar, common laborer"
    },
    "Keys": {
      "Word": 4811
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "干净": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānjìng",
      "PrimaryDefinition": "clean, clear"
    },
    "Keys": {
      "Word": 111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "幸免": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngmiǎn",
      "PrimaryDefinition": "narrowly and luckily escape"
    },
    "Keys": {
      "Word": 10059
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "见解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànjiě",
      "PrimaryDefinition": "eyeshot, view, horizon, understanding, outlook, light, eyes, spectacle, judgment, sentiment, eyesight, opinion, thought, sight, standpoint, bet, hypothesis, eye, viewpoint, theory, possibility, judgement, slant, doxy, purview, ruling, thinking, supposal, position, persuasion, mind"
    },
    "Keys": {
      "Word": 7417
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tái",
      "PrimaryDefinition": "moss, lichen"
    },
    "Keys": {
      "Hanzi": 1015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1015,
      "Radical": "艸",
      "FrequencyRank": 3106,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "怼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duì",
      "PrimaryDefinition": "hate, abhor; hatred, resentment"
    },
    "Keys": {
      "Hanzi": 4389
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4389,
      "Radical": "心",
      "FrequencyRank": 4748,
      "RadicalIndex": "61.5",
      "StrokeCount": 9
    }
  },
  "找到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎodào",
      "PrimaryDefinition": "light upon, find, discover, run_down, founded, regain, strike, come upon, come across, run down, trace, turn_up, happen upon, get, chance on, chance upon"
    },
    "Keys": {
      "Word": 460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "引入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnrù",
      "PrimaryDefinition": "to draw into, to pull into, to introduce"
    },
    "Keys": {
      "Word": 10391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "富含": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùhán",
      "PrimaryDefinition": "Rich"
    },
    "Keys": {
      "Word": 6639
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áo",
      "PrimaryDefinition": "ramble, roam; travel for pleasure"
    },
    "Keys": {
      "Hanzi": 5346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5346,
      "Radical": "辵",
      "FrequencyRank": 4895,
      "RadicalIndex": "162.11",
      "StrokeCount": 13
    }
  },
  "酊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dīng",
      "PrimaryDefinition": "drunk, intoxicated"
    },
    "Keys": {
      "Hanzi": 4162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4162,
      "Radical": "酉",
      "FrequencyRank": 4791,
      "RadicalIndex": "164.2",
      "StrokeCount": 9
    }
  },
  "螅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "intestinal worm"
    },
    "Keys": {
      "Hanzi": 6100
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6100,
      "Radical": "虫",
      "FrequencyRank": 3724,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "惊人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngrén",
      "PrimaryDefinition": "alarming, whacking, astounding, spectacular, amazing, astonishing"
    },
    "Keys": {
      "Word": 4746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "切除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiēchú",
      "PrimaryDefinition": "excise, resect"
    },
    "Keys": {
      "Word": 8706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "褕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "shirt"
    },
    "Keys": {
      "Hanzi": 7738
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7738,
      "Radical": "衣",
      "RadicalIndex": "145.9",
      "StrokeCount": 14
    }
  },
  "必然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìrán",
      "PrimaryDefinition": "surely, inevitably, pardi, certainly"
    },
    "Keys": {
      "Word": 1296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "汇率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìlǜ",
      "PrimaryDefinition": "interchange, exchange rate, rate of exchange, exchange, exchange_rate"
    },
    "Keys": {
      "Word": 2563
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "星星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīngxing",
      "PrimaryDefinition": "star"
    },
    "Keys": {
      "Word": 1111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "诣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "reach; achievement, accomplishment"
    },
    "Keys": {
      "Hanzi": 4055
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4055,
      "Radical": "言",
      "FrequencyRank": 3475,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "保": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo",
      "PrimaryDefinition": "protect, safeguard, defend, care"
    },
    "Keys": {
      "Hanzi": 1562,
      "Word": 1278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "Grade": 3,
      "GeneralStandard": 1562,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 266,
      "StrokeCount": 9
    }
  },
  "出风头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū fēngtou",
      "PrimaryDefinition": "to push oneself forward, to seek fame, to be in the limelight, same as 出鋒頭|出锋头[chu1 feng1 tou5]"
    },
    "Keys": {
      "Word": 5955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刺耳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cìěr",
      "PrimaryDefinition": "ear-piercing"
    },
    "Keys": {
      "Word": 6049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "难度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nándù",
      "PrimaryDefinition": "trouble, problem"
    },
    "Keys": {
      "Word": 1760
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "磬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìng",
      "PrimaryDefinition": "musical instrument; musical stone"
    },
    "Keys": {
      "Hanzi": 6044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6044,
      "Radical": "石",
      "FrequencyRank": 5327,
      "RadicalIndex": "112.11",
      "StrokeCount": 16
    }
  },
  "侵犯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnfàn",
      "PrimaryDefinition": "violate, infringe on (sb.'s rights)"
    },
    "Keys": {
      "Word": 4939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "舔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiǎn",
      "PrimaryDefinition": "lick with tongue; taste"
    },
    "Keys": {
      "Hanzi": 3117,
      "Word": 9493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "135.8",
      "Grade": 7,
      "GeneralStandard": 3117,
      "Radical": "舌",
      "HSK": 7,
      "FrequencyRank": 3348,
      "StrokeCount": 14
    }
  },
  "赤字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chìzì",
      "PrimaryDefinition": "(financial) deficit, red letter"
    },
    "Keys": {
      "Word": 5910
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "氇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lu",
      "PrimaryDefinition": "thick rough serge from Tibet"
    },
    "Keys": {
      "Hanzi": 6120
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6120,
      "Radical": "毛",
      "FrequencyRank": 6396,
      "RadicalIndex": "82.12",
      "StrokeCount": 16
    }
  },
  "冻结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngjié",
      "PrimaryDefinition": "to freeze (loan, wage, price etc)"
    },
    "Keys": {
      "Word": 6341
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凡是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánshì",
      "PrimaryDefinition": "conj.: whatever"
    },
    "Keys": {
      "Word": 4546
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "技能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnéng",
      "PrimaryDefinition": "know-how, mastery of a technique, ability, acquisition, acquirement, mastery of a skill, proficiency, skill, craft, technical ability, technique, mastery of a skill/technique, facility, quality, faculty"
    },
    "Keys": {
      "Word": 3584
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "庞大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pángdà",
      "PrimaryDefinition": "huge, immense"
    },
    "Keys": {
      "Word": 8471
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接下来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēxiàlái",
      "PrimaryDefinition": "next, immediately after"
    },
    "Keys": {
      "Word": 778
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "讨好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎo hǎo",
      "PrimaryDefinition": "conciliate, have one's labor rewarded, blandish, accommodate, toady, oblige, toady to, curry favour with, grease, massage, propitiate, woo, fawn on, sugar, soft-soap, ingratiate, ingratiate oneself with"
    },
    "Keys": {
      "Word": 9441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "humble, modest"
    },
    "Keys": {
      "Hanzi": 2798
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.1",
      "GeneralStandard": 2798,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 2345,
      "StrokeCount": 12
    }
  },
  "打电话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ diànhuà",
      "PrimaryDefinition": "make telephone call"
    },
    "Keys": {
      "Word": 58
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "飗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "soughing of wind; noise"
    },
    "Keys": {
      "Hanzi": 7711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7711,
      "Radical": "風",
      "FrequencyRank": 7164,
      "RadicalIndex": "182.1",
      "StrokeCount": 14
    }
  },
  "无论如何": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúlùnrúhé",
      "PrimaryDefinition": "disregarding, aught, in any event, anyway, in any case, ought, at any rate, no matter what happens, irrespective, no matter, however, leastwise, anyhow, anywise, irregardless, leastways, whatever may come, disregardless, no matter what, regardless, come hell or high water, in_any_case"
    },
    "Keys": {
      "Word": 9777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "声誉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngyù",
      "PrimaryDefinition": "reputation, fame"
    },
    "Keys": {
      "Word": 9066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "pig, hog, wild boar"
    },
    "Keys": {
      "Hanzi": 2365,
      "Word": 2188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.9",
      "Grade": 3,
      "GeneralStandard": 2365,
      "Radical": "犬",
      "HSK": 3,
      "FrequencyRank": 1762,
      "StrokeCount": 11
    }
  },
  "柱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "pillar, post; support; lean on"
    },
    "Keys": {
      "Hanzi": 1435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "GeneralStandard": 1435,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 1691,
      "StrokeCount": 9
    }
  },
  "判定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàndìng",
      "PrimaryDefinition": "to judge, to decide, judgment, determination"
    },
    "Keys": {
      "Word": 8467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "自身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìshēn",
      "PrimaryDefinition": "self, oneself"
    },
    "Keys": {
      "Word": 2215
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "纲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "heavy rope, hawser; main points"
    },
    "Keys": {
      "Hanzi": 933
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 933,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 1934,
      "StrokeCount": 7
    }
  },
  "昂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áng",
      "PrimaryDefinition": "rise, raise; proud, bold; upright"
    },
    "Keys": {
      "Hanzi": 1090
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "GeneralStandard": 1090,
      "Radical": "日",
      "HSK": 8,
      "FrequencyRank": 1952,
      "StrokeCount": 8
    }
  },
  "存在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cúnzài",
      "PrimaryDefinition": "exist, be"
    },
    "Keys": {
      "Word": 1379
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "常理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chánglǐ",
      "PrimaryDefinition": "common sense, conventional reasoning and morals"
    },
    "Keys": {
      "Word": 5814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎobǎn",
      "PrimaryDefinition": "proprietor, chief, shopkeeper, Tuan, baas, honcho, hirer, storekeeper, milord, bwana, mugwump, cit, market keeper, buckra, governor, boss, joss, tradesman, employer, guv"
    },
    "Keys": {
      "Word": 1686
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "刊登": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāndēng",
      "PrimaryDefinition": "publish in periodical"
    },
    "Keys": {
      "Word": 7763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "藏身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cángshēn",
      "PrimaryDefinition": "to hide, to go into hiding, to take refuge"
    },
    "Keys": {
      "Word": 5769
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搜查": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōuchá",
      "PrimaryDefinition": "to search"
    },
    "Keys": {
      "Word": 9337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "闭幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì mù",
      "PrimaryDefinition": "the curtain falls, lower the curtain, to come to an end (of a meeting)"
    },
    "Keys": {
      "Word": 3250
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "突出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tū chū",
      "PrimaryDefinition": "outstanding"
    },
    "Keys": {
      "Word": 1980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "袒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎn",
      "PrimaryDefinition": "strip; lay bare; bared; naked"
    },
    "Keys": {
      "Hanzi": 4671
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4671,
      "Radical": "衣",
      "FrequencyRank": 3566,
      "RadicalIndex": "145.5",
      "StrokeCount": 10
    }
  },
  "骗子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piànzi",
      "PrimaryDefinition": "swindler, a cheat"
    },
    "Keys": {
      "Word": 3772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "日新月异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìxīn-yuèyì",
      "PrimaryDefinition": "daily renewal, monthly change (idiom), every day sees new developments, rapid progress"
    },
    "Keys": {
      "Word": 8875
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "备课": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi kè",
      "PrimaryDefinition": "(of a teacher) to prepare lessons"
    },
    "Keys": {
      "Word": 5554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "feather"
    },
    "Keys": {
      "Radical": 124,
      "Hanzi": 585
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124",
      "GeneralStandard": 585,
      "Radical": "羽",
      "HSK": 5,
      "FrequencyRank": 1865,
      "StrokeCount": 6
    }
  },
  "要紧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàojǐn",
      "PrimaryDefinition": "important, urgent"
    },
    "Keys": {
      "Word": 10247
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "the virtue of a gentleman; jade"
    },
    "Keys": {
      "Hanzi": 5054
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5054,
      "Radical": "玉",
      "FrequencyRank": 4635,
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "钻研": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuānyán",
      "PrimaryDefinition": "to study meticulously, to delve into"
    },
    "Keys": {
      "Word": 11013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "费劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèi jìn",
      "PrimaryDefinition": "to require effort, strenuous"
    },
    "Keys": {
      "Word": 6532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "堞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dié",
      "PrimaryDefinition": "plate"
    },
    "Keys": {
      "Hanzi": 5060
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5060,
      "Radical": "土",
      "FrequencyRank": 5850,
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "措手不及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuòshǒu-bùjí",
      "PrimaryDefinition": "no time to deal with it (idiom), caught unprepared"
    },
    "Keys": {
      "Word": 6089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "襄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "aid, help, assist; undress"
    },
    "Keys": {
      "Hanzi": 6286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6286,
      "Radical": "衣",
      "FrequencyRank": 2980,
      "RadicalIndex": "145.11",
      "StrokeCount": 17
    }
  },
  "寂静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìjìng",
      "PrimaryDefinition": "quiet"
    },
    "Keys": {
      "Word": 7342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "血栓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuèshuān",
      "PrimaryDefinition": "blood clot, thrombosis"
    },
    "Keys": {
      "Word": 10141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "救命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù mìng",
      "PrimaryDefinition": "to save sb's life, (interj.) Help!, Save me!"
    },
    "Keys": {
      "Word": 4758
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "磲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6072,
      "Radical": "石",
      "FrequencyRank": 6316,
      "RadicalIndex": "112.12",
      "StrokeCount": 16
    }
  },
  "健": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn",
      "PrimaryDefinition": "strong, robust, healthy; strength"
    },
    "Keys": {
      "Hanzi": 1969
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "GeneralStandard": 1969,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 979,
      "StrokeCount": 10
    }
  },
  "遗址": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yízhǐ",
      "PrimaryDefinition": "ruins, remains, monument, relics"
    },
    "Keys": {
      "Word": 10310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "订立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìnglì",
      "PrimaryDefinition": "to conclude (treaty, contract, agreement etc), to set up (a rule etc)"
    },
    "Keys": {
      "Word": 6308
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "序幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùmù",
      "PrimaryDefinition": "prologue"
    },
    "Keys": {
      "Word": 10102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎo",
      "PrimaryDefinition": "bite, gnaw"
    },
    "Keys": {
      "Hanzi": 1512,
      "Word": 4096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 5,
      "GeneralStandard": 1512,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 1658,
      "StrokeCount": 9
    }
  },
  "草坪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎopíng",
      "PrimaryDefinition": "lawn"
    },
    "Keys": {
      "Word": 5775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稀奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīqí",
      "PrimaryDefinition": "rare, strange"
    },
    "Keys": {
      "Word": 9823
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热气球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèqìqiú",
      "PrimaryDefinition": "hot air balloon"
    },
    "Keys": {
      "Word": 8836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "头顶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóudǐng",
      "PrimaryDefinition": "crown of head, head, crown, top/crown of head, top of head, top, vertex, calvaria, pate, poll"
    },
    "Keys": {
      "Word": 9554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "river in Anhui and Jiangsu provinces"
    },
    "Keys": {
      "Hanzi": 5572
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5572,
      "Radical": "水",
      "FrequencyRank": 5865,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "邯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "city in Hebei province; various"
    },
    "Keys": {
      "Hanzi": 3660
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3660,
      "Radical": "邑",
      "FrequencyRank": 3900,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "碰巧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèngqiǎo",
      "PrimaryDefinition": "by chance, by coincidence, to happen to"
    },
    "Keys": {
      "Word": 8499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "来访": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láifǎng",
      "PrimaryDefinition": "come to visit/call"
    },
    "Keys": {
      "Word": 7917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "接近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiējìn",
      "PrimaryDefinition": "approach, near, be close to"
    },
    "Keys": {
      "Word": 1617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "镭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "radium"
    },
    "Keys": {
      "Hanzi": 6345
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6345,
      "Radical": "金",
      "FrequencyRank": 4217,
      "RadicalIndex": "167.13",
      "StrokeCount": 18
    }
  },
  "暗中": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ànzhōng",
      "PrimaryDefinition": "in the dark, in secret, on the sly, surreptitiously"
    },
    "Keys": {
      "Word": 5447
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "性质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngzhì",
      "PrimaryDefinition": "kidney, attribute, fibre, description, affection, temper, nature, self, mold, temperament, property, composition, character, hair, bent, kind, disposition, habitude, meridian, dimension, quality, note, sort"
    },
    "Keys": {
      "Word": 3045
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "彖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuàn",
      "PrimaryDefinition": "a hog; a hedgehog; a porcupine"
    },
    "Keys": {
      "Hanzi": 6923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6923,
      "Radical": "彐",
      "FrequencyRank": 6434,
      "RadicalIndex": "58.6",
      "StrokeCount": 9
    }
  },
  "名单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngdān",
      "PrimaryDefinition": "checklist, register, registry, list, roll, listing, roster, name list"
    },
    "Keys": {
      "Word": 873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "尊敬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnjìng",
      "PrimaryDefinition": "respect, honor, esteem"
    },
    "Keys": {
      "Word": 4285
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "阏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "è",
      "PrimaryDefinition": "block, obstruct, stop up"
    },
    "Keys": {
      "Hanzi": 4951
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4951,
      "Radical": "門",
      "FrequencyRank": 5091,
      "RadicalIndex": "169.8",
      "StrokeCount": 11
    }
  },
  "鎏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "pure gold"
    },
    "Keys": {
      "Hanzi": 6362
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6362,
      "Radical": "金",
      "FrequencyRank": 5172,
      "RadicalIndex": "167.1",
      "StrokeCount": 18
    }
  },
  "严峻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánjùn",
      "PrimaryDefinition": "grim, severe, rigorous"
    },
    "Keys": {
      "Word": 10175
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穿小鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuān xiǎoxié",
      "PrimaryDefinition": "lit. to make sb wear tight shoes (idiom), to make life difficult for sb"
    },
    "Keys": {
      "Word": 6002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "临时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línshí",
      "PrimaryDefinition": "as the time draws near, at the last moment, temporary, interim, ad hoc"
    },
    "Keys": {
      "Word": 2710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "劂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "chisel for engraving; engrave"
    },
    "Keys": {
      "Hanzi": 7666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7666,
      "Radical": "刀",
      "FrequencyRank": 6368,
      "RadicalIndex": "18.12",
      "StrokeCount": 14
    }
  },
  "靽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7639
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7639,
      "Radical": "革",
      "RadicalIndex": "177.5",
      "StrokeCount": 14
    }
  },
  "天主教": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Tiānzhǔjiào",
      "PrimaryDefinition": "Catholicism"
    },
    "Keys": {
      "Word": 9486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎo",
      "PrimaryDefinition": "small, head shaped hill, used in place names"
    },
    "Keys": {
      "Hanzi": 6776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6776,
      "Radical": "土",
      "FrequencyRank": 6022,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "金牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnpái",
      "PrimaryDefinition": "gold medal"
    },
    "Keys": {
      "Word": 1626
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "暴利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàolì",
      "PrimaryDefinition": "sudden huge profits"
    },
    "Keys": {
      "Word": 5541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "废寝忘食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèiqǐn-wàngshí",
      "PrimaryDefinition": "to neglect sleep and forget about food (idiom), to skip one's sleep and meals, to be completely wrapped up in one's work"
    },
    "Keys": {
      "Word": 6526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "火药": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒyào",
      "PrimaryDefinition": "gunpowder"
    },
    "Keys": {
      "Word": 7249
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3700,
      "Radical": "口",
      "FrequencyRank": 4470,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "毂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "hub of wheel"
    },
    "Keys": {
      "Hanzi": 5364
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5364,
      "Radical": "殳",
      "FrequencyRank": 5052,
      "RadicalIndex": "79.9",
      "StrokeCount": 13
    }
  },
  "鄣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "name of an ancient city in N. Jiangsu, near Shandong"
    },
    "Keys": {
      "Hanzi": 7578
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7578,
      "Radical": "邑",
      "FrequencyRank": 5176,
      "RadicalIndex": "163.11",
      "StrokeCount": 13
    }
  },
  "追溯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīsù",
      "PrimaryDefinition": "lit. to go upstream, to trace sth back to, to date from"
    },
    "Keys": {
      "Word": 10916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tí",
      "PrimaryDefinition": "reddish color, red, brown"
    },
    "Keys": {
      "Hanzi": 5332
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5332,
      "Radical": "糸",
      "FrequencyRank": 5794,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "不利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùlì",
      "PrimaryDefinition": "unfavorable, detrimental;unsuccessful"
    },
    "Keys": {
      "Word": 3269
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "美化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měihuà",
      "PrimaryDefinition": "beautify, embellish"
    },
    "Keys": {
      "Word": 8233
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烈士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièshì",
      "PrimaryDefinition": "martyr"
    },
    "Keys": {
      "Word": 8055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荒诞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāngdàn",
      "PrimaryDefinition": "beyond belief, incredible, preposterous, fantastic"
    },
    "Keys": {
      "Word": 7183
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "臼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "mortar"
    },
    "Keys": {
      "Radical": 134,
      "Hanzi": 462
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 462,
      "Radical": "臼",
      "FrequencyRank": 4066,
      "RadicalIndex": "134",
      "StrokeCount": 6
    }
  },
  "耕地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēngdì",
      "PrimaryDefinition": "arable land, to plow land"
    },
    "Keys": {
      "Word": 6749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "太空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàikōng",
      "PrimaryDefinition": "firmament, outer space"
    },
    "Keys": {
      "Word": 3958
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "比如说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐrúshuō",
      "PrimaryDefinition": "For example"
    },
    "Keys": {
      "Word": 514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "拜年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bài nián",
      "PrimaryDefinition": "to pay a New Year call, to wish sb a Happy New Year"
    },
    "Keys": {
      "Word": 5480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "a disease resembling neurosis"
    },
    "Keys": {
      "Hanzi": 1265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1265,
      "Radical": "心",
      "FrequencyRank": 2191,
      "RadicalIndex": "61.5",
      "StrokeCount": 8
    }
  },
  "祝愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùyuàn",
      "PrimaryDefinition": "to wish"
    },
    "Keys": {
      "Word": 5384
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "多久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōjiǔ",
      "PrimaryDefinition": "how long?"
    },
    "Keys": {
      "Word": 642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "真实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnshí",
      "PrimaryDefinition": "true, real, authentic, factual"
    },
    "Keys": {
      "Word": 2138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "互补": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùbǔ",
      "PrimaryDefinition": "complementary, to complement each other"
    },
    "Keys": {
      "Word": 7120
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瑑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "carve, engrave, cut out, sculpt"
    },
    "Keys": {
      "Hanzi": 7474
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7474,
      "Radical": "玉",
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "膙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎng",
      "PrimaryDefinition": "callous skin on the feet"
    },
    "Keys": {
      "Hanzi": 6138
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6138,
      "Radical": "肉",
      "RadicalIndex": "130.11",
      "StrokeCount": 16
    }
  },
  "武器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔqì",
      "PrimaryDefinition": "enginery, steel, arming, armament, weapons system, hardware, armored, armature, munition, weaponry, artillery, implements of war, armory, arms, ammunition, armor, weapon, arm, armoring"
    },
    "Keys": {
      "Word": 2019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "巢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháo",
      "PrimaryDefinition": "nest, living quarter in tree"
    },
    "Keys": {
      "Hanzi": 2500
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "47.8",
      "GeneralStandard": 2500,
      "Radical": "巛",
      "HSK": 9,
      "FrequencyRank": 2298,
      "StrokeCount": 11
    }
  },
  "秉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "grasp, hold; bundle; authority"
    },
    "Keys": {
      "Hanzi": 1134
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.3",
      "GeneralStandard": 1134,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 2809,
      "StrokeCount": 8
    }
  },
  "㥄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "to pity; to commiserate; to have sympathy or compassion for, scared; afraid; fearful"
    },
    "Keys": {
      "Hanzi": 7242
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7242,
      "Radical": "心",
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "一一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyī",
      "PrimaryDefinition": "one_by_one, one after another, one at a time, one by one"
    },
    "Keys": {
      "Word": 10274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年初": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánchū",
      "PrimaryDefinition": "beginning of year"
    },
    "Keys": {
      "Word": 1767
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "溶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "to melt, dissolve; overflowing with"
    },
    "Keys": {
      "Hanzi": 3019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3019,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2278,
      "StrokeCount": 13
    }
  },
  "嚭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǐ",
      "PrimaryDefinition": "mound, lump; stealthily"
    },
    "Keys": {
      "Hanzi": 8031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8031,
      "Radical": "口",
      "RadicalIndex": "30.16",
      "StrokeCount": 19
    }
  },
  "恪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "respectful, reverent"
    },
    "Keys": {
      "Hanzi": 4356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4356,
      "Radical": "心",
      "FrequencyRank": 3483,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "后勤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòuqín",
      "PrimaryDefinition": "logistics"
    },
    "Keys": {
      "Word": 7095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "建筑物": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànzhùwù",
      "PrimaryDefinition": "foundation, shebang, erection, superstructure, buildup, architecture, construct, construction, edifice, building, structure, place, tenement, property, house, fabric"
    },
    "Keys": {
      "Word": 7431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zá",
      "PrimaryDefinition": "smash, crush, break; pound, mash"
    },
    "Keys": {
      "Hanzi": 1863,
      "Word": 10585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.5",
      "Grade": 7,
      "GeneralStandard": 1863,
      "Radical": "石",
      "HSK": 7,
      "FrequencyRank": 2456,
      "StrokeCount": 10
    }
  },
  "嘈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáo",
      "PrimaryDefinition": "noisy"
    },
    "Keys": {
      "Hanzi": 5696
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.11",
      "GeneralStandard": 5696,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 3446,
      "StrokeCount": 14
    }
  },
  "产值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnzhí",
      "PrimaryDefinition": "value of output, output value"
    },
    "Keys": {
      "Word": 5803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领悟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngwù",
      "PrimaryDefinition": "to understand, to comprehend"
    },
    "Keys": {
      "Word": 8084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皮包": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píbāo",
      "PrimaryDefinition": "handbag, briefcase"
    },
    "Keys": {
      "Word": 1793
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "非常": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēicháng",
      "PrimaryDefinition": "extraordinary, unusual, special, very, extremely, highly"
    },
    "Keys": {
      "Word": 107
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "交情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoqing",
      "PrimaryDefinition": "friendship, friendly relations"
    },
    "Keys": {
      "Word": 7457
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎ",
      "PrimaryDefinition": "question-forming particle, why? how? what?; to bite; loud"
    },
    "Keys": {
      "Hanzi": 1097
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1097,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 2830,
      "StrokeCount": 8
    }
  },
  "殆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "dangerous, perilous; endanger"
    },
    "Keys": {
      "Hanzi": 4177
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4177,
      "Radical": "歹",
      "FrequencyRank": 3142,
      "RadicalIndex": "78.5",
      "StrokeCount": 9
    }
  },
  "簖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": "bamboo trap for catching fish"
    },
    "Keys": {
      "Hanzi": 6258
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6258,
      "Radical": "竹",
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "褴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "ragged, tattered, threadbare"
    },
    "Keys": {
      "Hanzi": 6011
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6011,
      "Radical": "衣",
      "FrequencyRank": 4288,
      "RadicalIndex": "145.1",
      "StrokeCount": 15
    }
  },
  "人行道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénxíngdào",
      "PrimaryDefinition": "sidewalk"
    },
    "Keys": {
      "Word": 8851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuài",
      "PrimaryDefinition": "to lap; to suck"
    },
    "Keys": {
      "Hanzi": 5930
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5930,
      "Radical": "口",
      "FrequencyRank": 5917,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "车牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēpái",
      "PrimaryDefinition": "license plate"
    },
    "Keys": {
      "Word": 4406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "幸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "luck(ily), favor, fortunately"
    },
    "Keys": {
      "Hanzi": 983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "51.5",
      "GeneralStandard": 983,
      "Radical": "干",
      "HSK": 3,
      "FrequencyRank": 902,
      "StrokeCount": 8
    }
  },
  "曰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuē",
      "PrimaryDefinition": "say"
    },
    "Keys": {
      "Hanzi": 111,
      "Radical": 73,
      "Word": 10561
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73",
      "Grade": 7,
      "GeneralStandard": 111,
      "Radical": "曰",
      "HSK": 9,
      "FrequencyRank": 1656,
      "StrokeCount": 4
    }
  },
  "考题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎotí",
      "PrimaryDefinition": "exam question"
    },
    "Keys": {
      "Word": 4795
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "自言自语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìyán-zìyǔ",
      "PrimaryDefinition": "to talk to oneself, to think aloud, to soliloquize"
    },
    "Keys": {
      "Word": 5397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "门路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ménlu",
      "PrimaryDefinition": "way of doing sth, the right social connection"
    },
    "Keys": {
      "Word": 8246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "银行卡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínhángkǎ",
      "PrimaryDefinition": "bank card, ATM card"
    },
    "Keys": {
      "Word": 1161
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn",
      "PrimaryDefinition": "pipe, tube, duct; woodwind music"
    },
    "Keys": {
      "Hanzi": 3123,
      "Word": 1526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.8",
      "Grade": 3,
      "GeneralStandard": 3123,
      "Radical": "竹",
      "HSK": 3,
      "FrequencyRank": 252,
      "StrokeCount": 14
    }
  },
  "轹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "run over something with vehicle"
    },
    "Keys": {
      "Hanzi": 6817
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6817,
      "Radical": "車",
      "FrequencyRank": 6816,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "充电器": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngdiànqì",
      "PrimaryDefinition": "battery charger"
    },
    "Keys": {
      "Word": 2326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "买不起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎibuqǐ",
      "PrimaryDefinition": "cannot afford, can't afford buying"
    },
    "Keys": {
      "Word": 8190
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "临": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "draw near, approach; descend"
    },
    "Keys": {
      "Hanzi": 1471,
      "Word": 8059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "2.8",
      "Grade": 7,
      "GeneralStandard": 1471,
      "Radical": "丨",
      "HSK": 4,
      "FrequencyRank": 839,
      "StrokeCount": 9
    }
  },
  "控": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòng",
      "PrimaryDefinition": "accuse, charge; control"
    },
    "Keys": {
      "Hanzi": 2187
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "GeneralStandard": 2187,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 780,
      "StrokeCount": 11
    }
  },
  "嗖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "whizzing sound"
    },
    "Keys": {
      "Hanzi": 5155
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5155,
      "Radical": "口",
      "FrequencyRank": 3751,
      "RadicalIndex": "30.1",
      "StrokeCount": 12
    }
  },
  "朝着": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháozhe",
      "PrimaryDefinition": "facing, advancing (towards)"
    },
    "Keys": {
      "Word": 5832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领队": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngduì",
      "PrimaryDefinition": "leader of a group/team/etc."
    },
    "Keys": {
      "Word": 8082
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zéi",
      "PrimaryDefinition": "cuttlefish"
    },
    "Keys": {
      "Hanzi": 7703
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7703,
      "Radical": "魚",
      "FrequencyRank": 7910,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "管用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn yòng",
      "PrimaryDefinition": "efficacious, useful"
    },
    "Keys": {
      "Word": 6885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7648,
      "Radical": "木",
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "状元": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngyuan",
      "PrimaryDefinition": "top scorer in the palace examination (highest rank of the Imperial examination system), see 榜眼[bang3 yan3] and 探花[tan4 hua1], top scorer in college entrance examination 高考[gao1 kao3], (fig.) the most brilliantly talented person in the field, leading light"
    },
    "Keys": {
      "Word": 10911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "游行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuxíng",
      "PrimaryDefinition": "march, parade, demonstration"
    },
    "Keys": {
      "Word": 5274
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "面部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànbù",
      "PrimaryDefinition": "face (body part)"
    },
    "Keys": {
      "Word": 8283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "somewhat, slightly, at least"
    },
    "Keys": {
      "Hanzi": 2196,
      "Word": 4829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "128.5",
      "Grade": 6,
      "GeneralStandard": 2196,
      "Radical": "耳",
      "HSK": 4,
      "FrequencyRank": 1932,
      "StrokeCount": 11
    }
  },
  "仞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3546,
      "Radical": "人",
      "FrequencyRank": 5516,
      "RadicalIndex": "9.3",
      "StrokeCount": 5
    }
  },
  "实验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíyàn",
      "PrimaryDefinition": "experiment, test"
    },
    "Keys": {
      "Word": 1903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "憕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7823
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7823,
      "Radical": "心",
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "赠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zèng",
      "PrimaryDefinition": "give present; bestow, confer"
    },
    "Keys": {
      "Hanzi": 3351,
      "Word": 4185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.12",
      "Grade": 5,
      "GeneralStandard": 3351,
      "Radical": "貝",
      "HSK": 5,
      "FrequencyRank": 2123,
      "StrokeCount": 16
    }
  },
  "挤压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐyā",
      "PrimaryDefinition": "to squeeze, to press, to extrude"
    },
    "Keys": {
      "Word": 7317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "严肃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánsù",
      "PrimaryDefinition": "serious, solemn"
    },
    "Keys": {
      "Word": 4089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "夯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hāng",
      "PrimaryDefinition": "heavy load, burden; lift up"
    },
    "Keys": {
      "Hanzi": 219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 219,
      "Radical": "大",
      "FrequencyRank": 4574,
      "RadicalIndex": "37.2",
      "StrokeCount": 5
    }
  },
  "簌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "(of flower petals) falling"
    },
    "Keys": {
      "Hanzi": 6255
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6255,
      "Radical": "竹",
      "FrequencyRank": 3954,
      "RadicalIndex": "118.11",
      "StrokeCount": 17
    }
  },
  "旅馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚguǎn",
      "PrimaryDefinition": "gasthaus, caravanserai, nonresident, roadhouse, hotel, lodge, hostler, hostel, fonda, ordinary, inn, hostelry"
    },
    "Keys": {
      "Word": 1721
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "大力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàlì",
      "PrimaryDefinition": "vigorously, energetically"
    },
    "Keys": {
      "Word": 4479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "对外": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duìwài",
      "PrimaryDefinition": "external, foreign, pertaining to external or foreign (affairs)"
    },
    "Keys": {
      "Word": 4526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "豸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "badger"
    },
    "Keys": {
      "Radical": 153,
      "Hanzi": 3740
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3740,
      "Radical": "豸",
      "RadicalIndex": "153",
      "StrokeCount": 7
    }
  },
  "空隙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòngxì",
      "PrimaryDefinition": "crack, gap between two objects, gap in time between two events"
    },
    "Keys": {
      "Word": 7832
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "碏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "què",
      "PrimaryDefinition": "coloured"
    },
    "Keys": {
      "Hanzi": 7505
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7505,
      "Radical": "石",
      "RadicalIndex": "112.8",
      "StrokeCount": 13
    }
  },
  "言行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánxíng",
      "PrimaryDefinition": "words and deeds"
    },
    "Keys": {
      "Word": 10179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "得力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé lì",
      "PrimaryDefinition": "able, capable, competent, efficient"
    },
    "Keys": {
      "Word": 6209
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "古董": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔdǒng",
      "PrimaryDefinition": "old fogey, curio, antique, virtu, vertu, bygone, bric-a-brac"
    },
    "Keys": {
      "Word": 6824
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐn",
      "PrimaryDefinition": "pity, sympathize with"
    },
    "Keys": {
      "Hanzi": 7611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7611,
      "Radical": "心",
      "FrequencyRank": 5465,
      "RadicalIndex": "61.9",
      "StrokeCount": 13
    }
  },
  "风情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngqíng",
      "PrimaryDefinition": "demeanour, flavor, information about wind, local customs, bearing, feelings, flirtatious expressions, amorous feelings"
    },
    "Keys": {
      "Word": 6575
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "酞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "phthalein"
    },
    "Keys": {
      "Hanzi": 4786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4786,
      "Radical": "酉",
      "FrequencyRank": 6556,
      "RadicalIndex": "164.4",
      "StrokeCount": 11
    }
  },
  "依照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīzhào",
      "PrimaryDefinition": "according to, in light of"
    },
    "Keys": {
      "Word": 4105
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "畏惧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèijù",
      "PrimaryDefinition": "to fear, to dread, foreboding"
    },
    "Keys": {
      "Word": 9720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "敔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "gavel"
    },
    "Keys": {
      "Hanzi": 7143
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7143,
      "Radical": "攴",
      "RadicalIndex": "66.7",
      "StrokeCount": 11
    }
  },
  "崆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōng",
      "PrimaryDefinition": "Kongtong mountain"
    },
    "Keys": {
      "Hanzi": 4861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4861,
      "Radical": "山",
      "FrequencyRank": 4996,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "匏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "páo",
      "PrimaryDefinition": "gourd; musical instrument"
    },
    "Keys": {
      "Hanzi": 4799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4799,
      "Radical": "勹",
      "FrequencyRank": 5454,
      "RadicalIndex": "20.9",
      "StrokeCount": 11
    }
  },
  "小姐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎojiě",
      "PrimaryDefinition": "Signorina, Senorita, senorita, signorina, young_lady, damosel, Dona, mum, young lady, damoiselle, missy, tootsy, demoiselle, damsel, Miss, Fraulein, miss, sheila, sister, toots, senhorita, damozel, Ladyship, ladyship, Mademoiselle, mademoiselle"
    },
    "Keys": {
      "Word": 400
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "领先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐng xiān",
      "PrimaryDefinition": "lead, lead (in competition), precede, lead_up, be in lead, show, be in the lead, get the jump"
    },
    "Keys": {
      "Word": 1714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nán",
      "PrimaryDefinition": "difficult, arduous, hard; unable"
    },
    "Keys": {
      "Hanzi": 2147,
      "Word": 266
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.8",
      "Grade": 1,
      "GeneralStandard": 2147,
      "Radical": "又",
      "HSK": 1,
      "FrequencyRank": 295,
      "StrokeCount": 10
    }
  },
  "逝世": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìshì",
      "PrimaryDefinition": "to pass away, to die"
    },
    "Keys": {
      "Word": 9167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "wooden bow; arc, crescent"
    },
    "Keys": {
      "Hanzi": 1312
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "57.5",
      "GeneralStandard": 1312,
      "Radical": "弓",
      "HSK": 9,
      "FrequencyRank": 3062,
      "StrokeCount": 8
    }
  },
  "热量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèliàng",
      "PrimaryDefinition": "heat, quantity of heat, calorific value"
    },
    "Keys": {
      "Word": 3833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "滹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "the bank of a steam"
    },
    "Keys": {
      "Hanzi": 5802
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5802,
      "Radical": "水",
      "FrequencyRank": 6151,
      "RadicalIndex": "85.11",
      "StrokeCount": 14
    }
  },
  "一言不发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīyán-bùfā",
      "PrimaryDefinition": "to not say a word (idiom)"
    },
    "Keys": {
      "Word": 10350
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīng",
      "PrimaryDefinition": "hall, central room"
    },
    "Keys": {
      "Hanzi": 90,
      "Word": 3980
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "27.2",
      "Grade": 5,
      "GeneralStandard": 90,
      "Radical": "厂",
      "HSK": 5,
      "FrequencyRank": 1217,
      "StrokeCount": 4
    }
  },
  "上限": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngxiàn",
      "PrimaryDefinition": "cap, upper limit"
    },
    "Keys": {
      "Word": 8983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "chest cavity; hollow in body"
    },
    "Keys": {
      "Hanzi": 2715,
      "Word": 8693
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.8",
      "Grade": 7,
      "GeneralStandard": 2715,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 1914,
      "StrokeCount": 12
    }
  },
  "做": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "work, make; act"
    },
    "Keys": {
      "Hanzi": 2324,
      "Word": 497
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "Grade": 1,
      "GeneralStandard": 2324,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 246,
      "StrokeCount": 11
    }
  },
  "散发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sànfā",
      "PrimaryDefinition": "spray, radiate, emission, send out, give forth, exhale, distribution, diffuse, distribute, emit, issue, diffusion, emanate, send forth, sent out, give out, reek, furnace"
    },
    "Keys": {
      "Word": 8923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出丑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū chǒu",
      "PrimaryDefinition": "shameful, scandalous, to be humiliated, to make a fool of sb or oneself, to make sb lose face"
    },
    "Keys": {
      "Word": 5952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ráo",
      "PrimaryDefinition": "bent or twisted piece of wood"
    },
    "Keys": {
      "Hanzi": 4451
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4451,
      "Radical": "木",
      "FrequencyRank": 5406,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "涛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tāo",
      "PrimaryDefinition": "large waves"
    },
    "Keys": {
      "Hanzi": 2065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.7",
      "GeneralStandard": 2065,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 2054,
      "StrokeCount": 10
    }
  },
  "斌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "refined, having both appearance"
    },
    "Keys": {
      "Hanzi": 2730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2730,
      "Radical": "文",
      "FrequencyRank": 2442,
      "RadicalIndex": "67.7",
      "StrokeCount": 12
    }
  },
  "不知": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhī",
      "PrimaryDefinition": "not to know, unaware, unknowingly, fig. not to admit (defeat, hardships, tiredness etc)"
    },
    "Keys": {
      "Word": 5723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "机械": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīxiè",
      "PrimaryDefinition": "mechanics, enginery, rig, machinery, mechanism, machine, appliance, mechanical"
    },
    "Keys": {
      "Word": 4694
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guó",
      "PrimaryDefinition": "name of ancient feudal State in Shenxi and Hunan"
    },
    "Keys": {
      "Hanzi": 5964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5964,
      "Radical": "虍",
      "FrequencyRank": 4827,
      "RadicalIndex": "141.9",
      "StrokeCount": 15
    }
  },
  "待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "treat, entertain, receive; wait"
    },
    "Keys": {
      "Hanzi": 1580,
      "Word": 6151
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "60.6",
      "Grade": 7,
      "GeneralStandard": 1580,
      "Radical": "彳",
      "HSK": 3,
      "FrequencyRank": 673,
      "StrokeCount": 9
    }
  },
  "王": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wáng",
      "PrimaryDefinition": "king, ruler; royal; surname"
    },
    "Keys": {
      "Hanzi": 75,
      "Word": 2965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96",
      "Grade": 4,
      "GeneralStandard": 75,
      "Radical": "玉",
      "HSK": 2,
      "FrequencyRank": 299,
      "StrokeCount": 4
    }
  },
  "之间": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhījiān",
      "PrimaryDefinition": "among, between"
    },
    "Keys": {
      "Word": 3172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "至于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìyú",
      "PrimaryDefinition": "as for/to"
    },
    "Keys": {
      "Word": 5362
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "密切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìqiē",
      "PrimaryDefinition": "osculate, intimate, close"
    },
    "Keys": {
      "Word": 2739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "两": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎng",
      "PrimaryDefinition": "two, both, pair, couple; ounce"
    },
    "Keys": {
      "Hanzi": 697,
      "Word": 852
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.6",
      "Grade": 2,
      "GeneralStandard": 697,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 133,
      "StrokeCount": 7
    }
  },
  "钩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōu",
      "PrimaryDefinition": "hook, barb; sickle; stroke with"
    },
    "Keys": {
      "Hanzi": 1534,
      "Word": 6811
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "Grade": 7,
      "GeneralStandard": 1534,
      "Radical": "金",
      "HSK": 7,
      "FrequencyRank": 2254,
      "StrokeCount": 9
    }
  },
  "一刹那": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīchànà",
      "PrimaryDefinition": "a very short time, tick, trice, a moment, shake"
    },
    "Keys": {
      "Word": 10282
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénxuǎn",
      "PrimaryDefinition": "choice among persons"
    },
    "Keys": {
      "Word": 8852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "瞎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiā",
      "PrimaryDefinition": "blind, reckless; rash"
    },
    "Keys": {
      "Hanzi": 3235,
      "Word": 9850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.1",
      "Grade": 7,
      "GeneralStandard": 3235,
      "Radical": "目",
      "HSK": 7,
      "FrequencyRank": 2395,
      "StrokeCount": 15
    }
  },
  "秬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "black millet"
    },
    "Keys": {
      "Hanzi": 6853
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6853,
      "Radical": "禾",
      "FrequencyRank": 7426,
      "RadicalIndex": "115.5",
      "StrokeCount": 9
    }
  },
  "邻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "neighbor; neighborhood"
    },
    "Keys": {
      "Hanzi": 801
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "163.5",
      "GeneralStandard": 801,
      "Radical": "邑",
      "HSK": 5,
      "FrequencyRank": 1659,
      "StrokeCount": 7
    }
  },
  "猺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "jackal; name of a tribe"
    },
    "Keys": {
      "Hanzi": 7565
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7565,
      "Radical": "犬",
      "RadicalIndex": "94.1",
      "StrokeCount": 13
    }
  },
  "分担": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēndān",
      "PrimaryDefinition": "to share (a burden, a cost, a responsibility)"
    },
    "Keys": {
      "Word": 6534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "轰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng",
      "PrimaryDefinition": "rumble, explosion, blast"
    },
    "Keys": {
      "Hanzi": 1057,
      "Word": 7070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.4",
      "Grade": 7,
      "GeneralStandard": 1057,
      "Radical": "車",
      "HSK": 7,
      "FrequencyRank": 1264,
      "StrokeCount": 8
    }
  },
  "繇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "reason, cause"
    },
    "Keys": {
      "Hanzi": 6268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6268,
      "Radical": "糸",
      "FrequencyRank": 5508,
      "RadicalIndex": "120.11",
      "StrokeCount": 17
    }
  },
  "衙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yá",
      "PrimaryDefinition": "public office; official residence"
    },
    "Keys": {
      "Hanzi": 2964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2964,
      "Radical": "行",
      "FrequencyRank": 2769,
      "RadicalIndex": "144.7",
      "StrokeCount": 13
    }
  },
  "竦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒng",
      "PrimaryDefinition": "revere, respect, be in awe of"
    },
    "Keys": {
      "Hanzi": 5261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5261,
      "Radical": "立",
      "FrequencyRank": 4627,
      "RadicalIndex": "117.7",
      "StrokeCount": 12
    }
  },
  "赆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "farewell present"
    },
    "Keys": {
      "Hanzi": 7003
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7003,
      "Radical": "貝",
      "FrequencyRank": 7105,
      "RadicalIndex": "154.6",
      "StrokeCount": 10
    }
  },
  "栏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": "railing, balustrade; animal pan"
    },
    "Keys": {
      "Hanzi": 1437,
      "Word": 7925
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 7,
      "GeneralStandard": 1437,
      "Radical": "木",
      "HSK": 6,
      "FrequencyRank": 2101,
      "StrokeCount": 9
    }
  },
  "虎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǔ",
      "PrimaryDefinition": "tiger; brave, fierce; surname"
    },
    "Keys": {
      "Hanzi": 1071,
      "Word": 3548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "141.2",
      "Grade": 5,
      "GeneralStandard": 1071,
      "Radical": "虍",
      "HSK": 5,
      "FrequencyRank": 1083,
      "StrokeCount": 8
    }
  },
  "分支": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnzhī",
      "PrimaryDefinition": "branch (of company, river etc), to branch, to diverge, to ramify, to subdivide"
    },
    "Keys": {
      "Word": 6542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爆炸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàozhà",
      "PrimaryDefinition": "explode, blow up"
    },
    "Keys": {
      "Word": 4309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "明确": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngquè",
      "PrimaryDefinition": "define, make clear/definite, make definite, definite, make clear"
    },
    "Keys": {
      "Word": 1748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "奠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "pay respect; settle"
    },
    "Keys": {
      "Hanzi": 2744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.9",
      "GeneralStandard": 2744,
      "Radical": "大",
      "HSK": 9,
      "FrequencyRank": 2656,
      "StrokeCount": 12
    }
  },
  "训练": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùnliàn",
      "PrimaryDefinition": "training"
    },
    "Keys": {
      "Word": 2072
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "欢声笑语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huānshēng-xiàoyǔ",
      "PrimaryDefinition": "Laughter"
    },
    "Keys": {
      "Word": 7163
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "主张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔzhāng",
      "PrimaryDefinition": "advocate, stand for, maintain, hold"
    },
    "Keys": {
      "Word": 2191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "坚定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāndìng",
      "PrimaryDefinition": "stabilizing, firm, steadfast, staunch, strengthen"
    },
    "Keys": {
      "Word": 3592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "警车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngchē",
      "PrimaryDefinition": "police car"
    },
    "Keys": {
      "Word": 7630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "suddenly, quickly, abruptly"
    },
    "Keys": {
      "Hanzi": 5373
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5373,
      "Radical": "馬",
      "FrequencyRank": 3160,
      "RadicalIndex": "187.1",
      "StrokeCount": 13
    }
  },
  "名人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngrén",
      "PrimaryDefinition": "notability, luminary, celebrity, don, celeb, hotshot, lion, famous person, eminent peron, anybody, name, adept, star, figure, man of mark, proficient, performer, personality, social lion, nob, famous peron, guiding light, article, eminent person, boss, famous/eminent person, famous, toff, leading light, notable, swell, personage"
    },
    "Keys": {
      "Word": 2746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "秀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "ear of grain; flowering, luxuriant; refined, elegant, graceful"
    },
    "Keys": {
      "Hanzi": 765
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.2",
      "GeneralStandard": 765,
      "Radical": "禾",
      "HSK": 4,
      "FrequencyRank": 1136,
      "StrokeCount": 7
    }
  },
  "从不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngbù",
      "PrimaryDefinition": "ne'er, never"
    },
    "Keys": {
      "Word": 4458
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鹤立鸡群": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèlì-jīqún",
      "PrimaryDefinition": "a crane in a flock of chicken (idiom), way above the common, manifestly superior"
    },
    "Keys": {
      "Word": 7057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "忭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "delighted; pleased"
    },
    "Keys": {
      "Hanzi": 3792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3792,
      "Radical": "心",
      "FrequencyRank": 6891,
      "RadicalIndex": "61.4",
      "StrokeCount": 7
    }
  },
  "矰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēng",
      "PrimaryDefinition": "arrow with attached silk cord"
    },
    "Keys": {
      "Hanzi": 7947
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7947,
      "Radical": "矢",
      "FrequencyRank": 7812,
      "RadicalIndex": "111.12",
      "StrokeCount": 17
    }
  },
  "脉络": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "màiluò",
      "PrimaryDefinition": "arteries and veins, network of blood vessels, vascular system (of a plant or animal), (fig.) fabric (i.e. underlying structure, as in 'social fabric'), overall context"
    },
    "Keys": {
      "Word": 8194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窟窿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kūlong",
      "PrimaryDefinition": "hole, pocket, cavity, loophole, debt"
    },
    "Keys": {
      "Word": 7858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "马后炮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎhòupào",
      "PrimaryDefinition": "lit. firing after the horse, fig. belated action, giving advice in hindsight"
    },
    "Keys": {
      "Word": 8178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "且": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiě",
      "PrimaryDefinition": "moreover, also (post-subject); about to, will soon (pre-verb)"
    },
    "Keys": {
      "Hanzi": 237,
      "Word": 8710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "Grade": 7,
      "GeneralStandard": 237,
      "Radical": "一",
      "HSK": 2,
      "FrequencyRank": 296,
      "StrokeCount": 5
    }
  },
  "输": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "transport, carry, haul"
    },
    "Keys": {
      "Hanzi": 2890,
      "Word": 1932
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "159.9",
      "Grade": 3,
      "GeneralStandard": 2890,
      "Radical": "車",
      "HSK": 3,
      "FrequencyRank": 939,
      "StrokeCount": 13
    }
  },
  "简陋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnlòu",
      "PrimaryDefinition": "simple and crude"
    },
    "Keys": {
      "Word": 7412
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "激烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīliè",
      "PrimaryDefinition": "drastic, fierce, heated, acute, sharp, intense"
    },
    "Keys": {
      "Word": 2577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "雯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "cloud patterns, coloring of cloud"
    },
    "Keys": {
      "Hanzi": 5115
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5115,
      "Radical": "雨",
      "FrequencyRank": 2957,
      "RadicalIndex": "173.4",
      "StrokeCount": 12
    }
  },
  "颍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐng",
      "PrimaryDefinition": "river in Anhui"
    },
    "Keys": {
      "Hanzi": 5238
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5238,
      "Radical": "水",
      "FrequencyRank": 3881,
      "RadicalIndex": "85.8",
      "StrokeCount": 12
    }
  },
  "圻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "border, boundary"
    },
    "Keys": {
      "Hanzi": 3652
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3652,
      "Radical": "土",
      "FrequencyRank": 5001,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "醒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐng",
      "PrimaryDefinition": "wake up; sober up; startle"
    },
    "Keys": {
      "Hanzi": 3336,
      "Word": 3043
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "164.9",
      "Grade": 4,
      "GeneralStandard": 3336,
      "Radical": "酉",
      "HSK": 4,
      "FrequencyRank": 1075,
      "StrokeCount": 16
    }
  },
  "扮演": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bànyǎn",
      "PrimaryDefinition": "play part of, play part of"
    },
    "Keys": {
      "Word": 3227
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "揩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi",
      "PrimaryDefinition": "rub and wipe, dust, clean"
    },
    "Keys": {
      "Hanzi": 2513
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2513,
      "Radical": "手",
      "FrequencyRank": 3671,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "麀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōu",
      "PrimaryDefinition": "female deer; roe, doe"
    },
    "Keys": {
      "Hanzi": 7577
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7577,
      "Radical": "鹿",
      "FrequencyRank": 7783,
      "RadicalIndex": "198.2",
      "StrokeCount": 13
    }
  },
  "放下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàngxià",
      "PrimaryDefinition": "set_down, set down, lay/put down, lay, lower, lay_down, draw, lay down, dismount, put_down, plank, drop_away, deposit, digress, drop_off, depose, put down, place down"
    },
    "Keys": {
      "Word": 655
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "再见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàijiàn",
      "PrimaryDefinition": "good-bye, see you again"
    },
    "Keys": {
      "Word": 451
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "郅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "to go up to. flourishing a superlative"
    },
    "Keys": {
      "Hanzi": 3894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3894,
      "Radical": "邑",
      "FrequencyRank": 4264,
      "RadicalIndex": "163.6",
      "StrokeCount": 8
    }
  },
  "胥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xū",
      "PrimaryDefinition": "all, together, mutually"
    },
    "Keys": {
      "Hanzi": 4379
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4379,
      "Radical": "肉",
      "FrequencyRank": 3611,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "琯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎn",
      "PrimaryDefinition": "a jade tube used as an instrument"
    },
    "Keys": {
      "Hanzi": 5053
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5053,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "醐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "purest cream"
    },
    "Keys": {
      "Hanzi": 6069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6069,
      "Radical": "酉",
      "FrequencyRank": 6002,
      "RadicalIndex": "164.9",
      "StrokeCount": 16
    }
  },
  "眼看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnkàn",
      "PrimaryDefinition": "see sth. happen, watch helplessly"
    },
    "Keys": {
      "Word": 5213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "哼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēng",
      "PrimaryDefinition": "hum; sing softly; groan, moan; (Cant.) an interjecting indicating disapproval"
    },
    "Keys": {
      "Hanzi": 1913,
      "Word": 7066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "Grade": 7,
      "GeneralStandard": 1913,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 1966,
      "StrokeCount": 10
    }
  },
  "选用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎnyòng",
      "PrimaryDefinition": "select and use/apply"
    },
    "Keys": {
      "Word": 10126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "引": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "to pull, draw out, attract; to stretch"
    },
    "Keys": {
      "Hanzi": 176,
      "Word": 3101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "57.1",
      "Grade": 4,
      "GeneralStandard": 176,
      "Radical": "弓",
      "HSK": 4,
      "FrequencyRank": 479,
      "StrokeCount": 4
    }
  },
  "酌情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóqíng",
      "PrimaryDefinition": "to use discretion, to take circumstances into account, to make allowances pertinent to a situation"
    },
    "Keys": {
      "Word": 10930
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "barium"
    },
    "Keys": {
      "Hanzi": 4241
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4241,
      "Radical": "金",
      "FrequencyRank": 3858,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "亵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiè",
      "PrimaryDefinition": "slight, insult, treat with disrespect"
    },
    "Keys": {
      "Hanzi": 5250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5250,
      "Radical": "亠",
      "FrequencyRank": 3495,
      "RadicalIndex": "8.1",
      "StrokeCount": 12
    }
  },
  "管理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎnlǐ",
      "PrimaryDefinition": "manage, supervise, take care of"
    },
    "Keys": {
      "Word": 1522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鼙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "drum carried on horseback"
    },
    "Keys": {
      "Hanzi": 6449
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6449,
      "Radical": "鼓",
      "FrequencyRank": 5677,
      "RadicalIndex": "207.8",
      "StrokeCount": 21
    }
  },
  "划船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá chuán",
      "PrimaryDefinition": "paddle/row boat"
    },
    "Keys": {
      "Word": 1555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "骑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "ride horseback; mount; cavalry"
    },
    "Keys": {
      "Hanzi": 2489,
      "Word": 923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "187.8",
      "Grade": 2,
      "GeneralStandard": 2489,
      "Radical": "馬",
      "HSK": 2,
      "FrequencyRank": 1398,
      "StrokeCount": 11
    }
  },
  "至少": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìshǎo",
      "PrimaryDefinition": "leastwise, leastways, at (the) least, at_least, only, fully, even a little, at least, at any rate, in the least, at least, at the least"
    },
    "Keys": {
      "Word": 2172
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "思想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīxiǎng",
      "PrimaryDefinition": "cogitation, mentation, imagery, imaging, lunatic_fringe, mental imagery, idea, thinking, imagination, ideology, thought, pensee, ideological"
    },
    "Keys": {
      "Word": 1941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "彪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "tiger; tiger stripes; tiger-like"
    },
    "Keys": {
      "Hanzi": 2254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2254,
      "Radical": "彡",
      "FrequencyRank": 2406,
      "RadicalIndex": "59.8",
      "StrokeCount": 11
    }
  },
  "坬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6653
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6653,
      "Radical": "土",
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "迄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "extend, reach; until; till"
    },
    "Keys": {
      "Hanzi": 453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.3",
      "GeneralStandard": 453,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 2549,
      "StrokeCount": 6
    }
  },
  "投射": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóushè",
      "PrimaryDefinition": "to throw (a projectile), to cast (light)"
    },
    "Keys": {
      "Word": 9564
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "方便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāngbiàn",
      "PrimaryDefinition": "convenient"
    },
    "Keys": {
      "Word": 651
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "跟不上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēnbushàng",
      "PrimaryDefinition": "not able to keep up with"
    },
    "Keys": {
      "Word": 6746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "渥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò",
      "PrimaryDefinition": "moisten, soak; great, deep; dye; to enrich"
    },
    "Keys": {
      "Hanzi": 5291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5291,
      "Radical": "水",
      "FrequencyRank": 4191,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "不久": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjiǔ",
      "PrimaryDefinition": "not long (after), before too long, soon, soon after"
    },
    "Keys": {
      "Word": 535
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "泡沫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàomò",
      "PrimaryDefinition": "foam, (soap) bubble, (economic) bubble"
    },
    "Keys": {
      "Word": 8480
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "bawl, yell, shout, cry out"
    },
    "Keys": {
      "Hanzi": 433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 433,
      "Radical": "口",
      "FrequencyRank": 3551,
      "RadicalIndex": "30.3",
      "StrokeCount": 6
    }
  },
  "钅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīn",
      "PrimaryDefinition": "metal"
    },
    "Keys": {
      "Radical": 167
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "西装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīzhuāng",
      "PrimaryDefinition": "suit, Western-style clothes, CL:套[tao4]"
    },
    "Keys": {
      "Word": 4026
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "垾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6943
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6943,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "造型": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàoxíng",
      "PrimaryDefinition": "moulding, molding, style, mold, mold-making, fashioning, mould-making, modelling, model"
    },
    "Keys": {
      "Word": 3148
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "烀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "simmer"
    },
    "Keys": {
      "Hanzi": 4328
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4328,
      "Radical": "火",
      "FrequencyRank": 6398,
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "体系": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐxì",
      "PrimaryDefinition": "scheme, setup, system of rules, system, superstructure, cosmos, schema"
    },
    "Keys": {
      "Word": 9467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "consume, use up; waste, squander"
    },
    "Keys": {
      "Hanzi": 1768,
      "Word": 7018
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "127.4",
      "Grade": 7,
      "GeneralStandard": 1768,
      "Radical": "耒",
      "HSK": 6,
      "FrequencyRank": 1730,
      "StrokeCount": 10
    }
  },
  "研制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánzhì",
      "PrimaryDefinition": "to manufacture, to develop"
    },
    "Keys": {
      "Word": 3069
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "中国": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Zhōngguó",
      "PrimaryDefinition": "Cathay, China, china"
    },
    "Keys": {
      "Word": 473
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "藟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": "vine, creeper; to wind"
    },
    "Keys": {
      "Hanzi": 7988
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7988,
      "Radical": "艸",
      "RadicalIndex": "140.15",
      "StrokeCount": 18
    }
  },
  "俗语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "súyǔ",
      "PrimaryDefinition": "common saying, proverb, colloquial speech"
    },
    "Keys": {
      "Word": 9348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "过错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòcuò",
      "PrimaryDefinition": "mistake, fault, responsibility (for a fault)"
    },
    "Keys": {
      "Word": 6943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "打断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ duàn",
      "PrimaryDefinition": "to interrupt, to break off, to break (a bone)"
    },
    "Keys": {
      "Word": 4467
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "失": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "lose; make mistake, neglect"
    },
    "Keys": {
      "Hanzi": 265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.2",
      "GeneralStandard": 265,
      "Radical": "大",
      "HSK": 3,
      "FrequencyRank": 375,
      "StrokeCount": 5
    }
  },
  "破": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pò",
      "PrimaryDefinition": "break, ruin, destroy; rout"
    },
    "Keys": {
      "Hanzi": 1867,
      "Word": 1800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.5",
      "Grade": 3,
      "GeneralStandard": 1867,
      "Radical": "石",
      "HSK": 3,
      "FrequencyRank": 604,
      "StrokeCount": 10
    }
  },
  "姗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "slander; ridicule; proceed slowly"
    },
    "Keys": {
      "Hanzi": 4066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4066,
      "Radical": "女",
      "FrequencyRank": 3883,
      "RadicalIndex": "38.5",
      "StrokeCount": 8
    }
  },
  "撞击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàngjī",
      "PrimaryDefinition": "to strike, to hit, to ram"
    },
    "Keys": {
      "Word": 10912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "咴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4197,
      "Radical": "口",
      "FrequencyRank": 5558,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "郜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gào",
      "PrimaryDefinition": "name of fief in Shantong bestowed on the eldest son of Wen Wang"
    },
    "Keys": {
      "Hanzi": 4250
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4250,
      "Radical": "邑",
      "FrequencyRank": 6218,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "铸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "melt, cast; coin, mint"
    },
    "Keys": {
      "Hanzi": 2656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.7",
      "GeneralStandard": 2656,
      "Radical": "金",
      "HSK": 9,
      "FrequencyRank": 2530,
      "StrokeCount": 12
    }
  },
  "主意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔyi",
      "PrimaryDefinition": "idea, plan, decision, definite view"
    },
    "Keys": {
      "Word": 2192
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "农": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóng",
      "PrimaryDefinition": "agriculture, farming; farmer"
    },
    "Keys": {
      "Hanzi": 558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "14.4",
      "GeneralStandard": 558,
      "Radical": "冖",
      "HSK": 3,
      "FrequencyRank": 465,
      "StrokeCount": 6
    }
  },
  "天鹅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāné",
      "PrimaryDefinition": "swan"
    },
    "Keys": {
      "Word": 9476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国际": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guójì",
      "PrimaryDefinition": "international"
    },
    "Keys": {
      "Word": 702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "忍饥挨饿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rěnjī-áiè",
      "PrimaryDefinition": "starving, famished"
    },
    "Keys": {
      "Word": 8858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俄语": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Éyǔ",
      "PrimaryDefinition": "Russian (language)"
    },
    "Keys": {
      "Word": 6407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "壹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "number one"
    },
    "Keys": {
      "Hanzi": 2537
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2537,
      "Radical": "士",
      "FrequencyRank": 4652,
      "RadicalIndex": "33.9",
      "StrokeCount": 12
    }
  },
  "观众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānzhòng",
      "PrimaryDefinition": "onlooker, TV audience, attendance, audience, spectator, viewers, followers, crowd, gallery, viewer, following, house, viewing audience"
    },
    "Keys": {
      "Word": 1523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "皮鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píxié",
      "PrimaryDefinition": "leather shoes"
    },
    "Keys": {
      "Word": 3770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "恨不得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hènbude",
      "PrimaryDefinition": "wishing one could do sth, to hate to be unable, itching to do sth"
    },
    "Keys": {
      "Word": 7065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "bright, glorious, splendid"
    },
    "Keys": {
      "Hanzi": 4410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4410,
      "Radical": "玉",
      "FrequencyRank": 5689,
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "同年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngnián",
      "PrimaryDefinition": "the same year"
    },
    "Keys": {
      "Word": 9538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "心胸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnxiōng",
      "PrimaryDefinition": "breadth of mind"
    },
    "Keys": {
      "Word": 10015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gè",
      "PrimaryDefinition": "chromium"
    },
    "Keys": {
      "Hanzi": 4880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4880,
      "Radical": "金",
      "FrequencyRank": 4189,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "恨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèn",
      "PrimaryDefinition": "hatred, dislike; resent, hate"
    },
    "Keys": {
      "Hanzi": 1692,
      "Word": 3541
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.6",
      "Grade": 5,
      "GeneralStandard": 1692,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1295,
      "StrokeCount": 9
    }
  },
  "啉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "stupid; slow"
    },
    "Keys": {
      "Hanzi": 4820
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4820,
      "Radical": "口",
      "FrequencyRank": 5643,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "院长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànzhǎng",
      "PrimaryDefinition": "director/president (of museum/institute/etc.), head of branch of government"
    },
    "Keys": {
      "Word": 1182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "即可": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíkě",
      "PrimaryDefinition": "then could do something"
    },
    "Keys": {
      "Word": 7300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxīn",
      "PrimaryDefinition": "wholeheartedly, heart and soul"
    },
    "Keys": {
      "Word": 10348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逐年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhúnián",
      "PrimaryDefinition": "all the year round, for years, all year, year after year, year in year out"
    },
    "Keys": {
      "Word": 10848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "成就": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngjiù",
      "PrimaryDefinition": "achievement, accomplishment, success"
    },
    "Keys": {
      "Word": 1349
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "或": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "or, either, else; perhaps, maybe"
    },
    "Keys": {
      "Hanzi": 1035,
      "Word": 741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.4",
      "Grade": 2,
      "GeneralStandard": 1035,
      "Radical": "戈",
      "HSK": 2,
      "FrequencyRank": 160,
      "StrokeCount": 8
    }
  },
  "流行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúxíng",
      "PrimaryDefinition": "prevalent, popular, fashionable, in vogue, spread, rage (of contagious disease)"
    },
    "Keys": {
      "Word": 858
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "减肥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn féi",
      "PrimaryDefinition": "lose weight"
    },
    "Keys": {
      "Word": 2601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "左顾右盼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǒgù-yòupàn",
      "PrimaryDefinition": "glancing to left and right (idiom), to look all around"
    },
    "Keys": {
      "Word": 11025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "我": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǒ",
      "PrimaryDefinition": "our, us, i, me, my, we"
    },
    "Keys": {
      "Hanzi": 761,
      "Word": 378
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "62.3",
      "Grade": 1,
      "GeneralStandard": 761,
      "Radical": "戈",
      "HSK": 1,
      "FrequencyRank": 9,
      "StrokeCount": 7
    }
  },
  "玉米": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùmǐ",
      "PrimaryDefinition": "corn, maize, CL:粒[li4]"
    },
    "Keys": {
      "Word": 3126
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "帛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "silks, fabrics; wealth, property"
    },
    "Keys": {
      "Hanzi": 3967
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3967,
      "Radical": "巾",
      "FrequencyRank": 3682,
      "RadicalIndex": "50.5",
      "StrokeCount": 8
    }
  },
  "强烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiángliè",
      "PrimaryDefinition": "strong, intense, violent"
    },
    "Keys": {
      "Word": 1814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "新颖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnyǐng",
      "PrimaryDefinition": "new and original, novel"
    },
    "Keys": {
      "Word": 10033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "shoulders; to shoulder; bear"
    },
    "Keys": {
      "Hanzi": 1289,
      "Word": 3597
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "Grade": 5,
      "GeneralStandard": 1289,
      "Radical": "肉",
      "HSK": 5,
      "FrequencyRank": 1415,
      "StrokeCount": 8
    }
  },
  "岢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kě",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3923
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3923,
      "Radical": "山",
      "FrequencyRank": 5078,
      "RadicalIndex": "46.5",
      "StrokeCount": 8
    }
  },
  "不大": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùdà",
      "PrimaryDefinition": "not very/too, not often"
    },
    "Keys": {
      "Word": 30
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "打扫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎsǎo",
      "PrimaryDefinition": "to clean, to sweep"
    },
    "Keys": {
      "Word": 2364
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "求救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiújiù",
      "PrimaryDefinition": "to cry for help"
    },
    "Keys": {
      "Word": 8772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "species of lark; wagtail; Motacilla species (various)"
    },
    "Keys": {
      "Hanzi": 7029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7029,
      "Radical": "鳥",
      "FrequencyRank": 6781,
      "RadicalIndex": "196.5",
      "StrokeCount": 10
    }
  },
  "母女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǔ-nǚ",
      "PrimaryDefinition": "mother-daughter"
    },
    "Keys": {
      "Word": 4881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "把握": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎwò",
      "PrimaryDefinition": "grasp firmly"
    },
    "Keys": {
      "Word": 1266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "记载": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìzǎi",
      "PrimaryDefinition": "put down in writing, record"
    },
    "Keys": {
      "Word": 2583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "黟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "black and shining; ebony"
    },
    "Keys": {
      "Hanzi": 6341
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6341,
      "Radical": "黑",
      "FrequencyRank": 6765,
      "RadicalIndex": "203.6",
      "StrokeCount": 18
    }
  },
  "奇特": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qítè",
      "PrimaryDefinition": "unusual, geezer, queer, exotic, peculiar"
    },
    "Keys": {
      "Word": 8599
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㧐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒng",
      "PrimaryDefinition": "(simplified form of 㩳) to hold; to grasp, to detain, to uphold, to push, to stand upright"
    },
    "Keys": {
      "Hanzi": 3658
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3658,
      "Radical": "手",
      "RadicalIndex": "64.4",
      "StrokeCount": 7
    }
  },
  "瘃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhú",
      "PrimaryDefinition": "sores from cold"
    },
    "Keys": {
      "Hanzi": 5540
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5540,
      "Radical": "疒",
      "RadicalIndex": "104.8",
      "StrokeCount": 13
    }
  },
  "躬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "body; personally, in person"
    },
    "Keys": {
      "Hanzi": 1972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "158.3",
      "GeneralStandard": 1972,
      "Radical": "身",
      "HSK": 8,
      "FrequencyRank": 2650,
      "StrokeCount": 10
    }
  },
  "逼真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīzhēn",
      "PrimaryDefinition": "lifelike, true to life, distinctly, clearly"
    },
    "Keys": {
      "Word": 5580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "变动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàndòng",
      "PrimaryDefinition": "change, fluctuate"
    },
    "Keys": {
      "Word": 3256
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "裂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "split, crack, break open; rend"
    },
    "Keys": {
      "Hanzi": 2595,
      "Word": 4832
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.6",
      "Grade": 6,
      "GeneralStandard": 2595,
      "Radical": "衣",
      "HSK": 6,
      "FrequencyRank": 1317,
      "StrokeCount": 12
    }
  },
  "兼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "unite, combine; connect; and"
    },
    "Keys": {
      "Hanzi": 2056,
      "Word": 7395
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.8",
      "Grade": 7,
      "GeneralStandard": 2056,
      "Radical": "八",
      "HSK": 7,
      "FrequencyRank": 1515,
      "StrokeCount": 10
    }
  },
  "技": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "skill, ability, talent, ingenuity"
    },
    "Keys": {
      "Hanzi": 615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 615,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 422,
      "StrokeCount": 7
    }
  },
  "烙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lào",
      "PrimaryDefinition": "brand, burn; branding iron"
    },
    "Keys": {
      "Hanzi": 2063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2063,
      "Radical": "火",
      "FrequencyRank": 3440,
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "触动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chùdòng",
      "PrimaryDefinition": "to touch, to stir up (trouble or emotions), to move (sb's emotions or worry)"
    },
    "Keys": {
      "Word": 5991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "brave; cautious; sad"
    },
    "Keys": {
      "Hanzi": 7733
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7733,
      "Radical": "心",
      "RadicalIndex": "61.11",
      "StrokeCount": 14
    }
  },
  "侩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "go-between, broker, proxy"
    },
    "Keys": {
      "Hanzi": 3960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3960,
      "Radical": "人",
      "FrequencyRank": 5225,
      "RadicalIndex": "9.6",
      "StrokeCount": 8
    }
  },
  "巅峰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diānfēng",
      "PrimaryDefinition": "summit, apex, pinnacle (of one's career etc), peak (of a civilization etc)"
    },
    "Keys": {
      "Word": 6264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鼾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hān",
      "PrimaryDefinition": "snore loudly"
    },
    "Keys": {
      "Hanzi": 6264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6264,
      "Radical": "鼻",
      "FrequencyRank": 3822,
      "RadicalIndex": "209.3",
      "StrokeCount": 17
    }
  },
  "监察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānchá",
      "PrimaryDefinition": "to supervise, to control"
    },
    "Keys": {
      "Word": 7389
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pián",
      "PrimaryDefinition": "tree"
    },
    "Keys": {
      "Hanzi": 7498
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7498,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "陷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "submerge, sink, plunge; trap"
    },
    "Keys": {
      "Hanzi": 2138,
      "Word": 9907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.8",
      "Grade": 7,
      "GeneralStandard": 2138,
      "Radical": "阜",
      "HSK": 6,
      "FrequencyRank": 1262,
      "StrokeCount": 10
    }
  },
  "赌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǔ",
      "PrimaryDefinition": "bet, gamble, wager; compete"
    },
    "Keys": {
      "Hanzi": 2651,
      "Word": 4518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.8",
      "Grade": 6,
      "GeneralStandard": 2651,
      "Radical": "貝",
      "HSK": 6,
      "FrequencyRank": 2037,
      "StrokeCount": 12
    }
  },
  "住宅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùzhái",
      "PrimaryDefinition": "shebang, dwelling_house, dwelling, roof, abode, residential, home, toft, howff, housing, habitation, homesite, tenement, place, residence, house, property"
    },
    "Keys": {
      "Word": 5380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "窅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎo",
      "PrimaryDefinition": "far, deep; sunken eyes; sad"
    },
    "Keys": {
      "Hanzi": 7072
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7072,
      "Radical": "穴",
      "FrequencyRank": 8446,
      "RadicalIndex": "116.5",
      "StrokeCount": 10
    }
  },
  "鞠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "bow, bend; rear, raise, nourish"
    },
    "Keys": {
      "Hanzi": 3391
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "177.8",
      "GeneralStandard": 3391,
      "Radical": "革",
      "HSK": 8,
      "FrequencyRank": 3037,
      "StrokeCount": 17
    }
  },
  "颏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "chin"
    },
    "Keys": {
      "Hanzi": 5264
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5264,
      "Radical": "頁",
      "FrequencyRank": 4876,
      "RadicalIndex": "181.6",
      "StrokeCount": 12
    }
  },
  "吸毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī dú",
      "PrimaryDefinition": "take drugs"
    },
    "Keys": {
      "Word": 5153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "损": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǔn",
      "PrimaryDefinition": "diminish; impair; injure"
    },
    "Keys": {
      "Hanzi": 1798,
      "Word": 9374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1798,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 911,
      "StrokeCount": 10
    }
  },
  "栅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "fence; palisade; grid"
    },
    "Keys": {
      "Hanzi": 1433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1433,
      "Radical": "木",
      "FrequencyRank": 2995,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "隧道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suìdào",
      "PrimaryDefinition": "tube, tunnel"
    },
    "Keys": {
      "Word": 9370
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电动车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàndòngchē",
      "PrimaryDefinition": "Electric vehicle"
    },
    "Keys": {
      "Word": 2414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "友人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒurén",
      "PrimaryDefinition": "friend"
    },
    "Keys": {
      "Word": 10470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灾难": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāinàn",
      "PrimaryDefinition": "affliction, tragedy, adversity, catastrophe, wo, misadventure, calamitous, mishap, bad luck, tribulation, smashup, cataclysm, trouble, mischance, disaster, mucker, fate, calamity, scourge, plague, infliction, visitation, misfortune, casualty, suffering, melt-down"
    },
    "Keys": {
      "Word": 4170
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "学时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéshí",
      "PrimaryDefinition": "class hour, period"
    },
    "Keys": {
      "Word": 3052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "彬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "cultivated, well-bred"
    },
    "Keys": {
      "Hanzi": 2220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "59.8",
      "GeneralStandard": 2220,
      "Radical": "彡",
      "HSK": 9,
      "FrequencyRank": 2736,
      "StrokeCount": 11
    }
  },
  "骀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dài",
      "PrimaryDefinition": "an old, tired horse, a jade; tired, exhausted"
    },
    "Keys": {
      "Hanzi": 4082
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4082,
      "Radical": "馬",
      "FrequencyRank": 6445,
      "RadicalIndex": "187.5",
      "StrokeCount": 8
    }
  },
  "观望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānwàng",
      "PrimaryDefinition": "straddle, pussyfoot, look on (from sidelines), swiver, hesitate, waver, look on, wait and see"
    },
    "Keys": {
      "Word": 6874
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "助手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùshǒu",
      "PrimaryDefinition": "assistant, helper"
    },
    "Keys": {
      "Word": 4254
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "挚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "sincere, warm, cordial; surname"
    },
    "Keys": {
      "Hanzi": 1808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "GeneralStandard": 1808,
      "Radical": "手",
      "HSK": 8,
      "FrequencyRank": 3012,
      "StrokeCount": 10
    }
  },
  "集体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jítǐ",
      "PrimaryDefinition": "collective"
    },
    "Keys": {
      "Word": 1577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "诳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuáng",
      "PrimaryDefinition": "deceive, lie, delude, cheat"
    },
    "Keys": {
      "Hanzi": 4372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4372,
      "Radical": "言",
      "FrequencyRank": 4488,
      "RadicalIndex": "149.7",
      "StrokeCount": 9
    }
  },
  "拉动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lā dòng",
      "PrimaryDefinition": "Pull"
    },
    "Keys": {
      "Word": 7906
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "楼梯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóutī",
      "PrimaryDefinition": "stairs, staircase"
    },
    "Keys": {
      "Word": 2713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "怒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nù",
      "PrimaryDefinition": "anger, rage, passion; angry"
    },
    "Keys": {
      "Hanzi": 1742
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1742,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1143,
      "StrokeCount": 9
    }
  },
  "露面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lòu miàn",
      "PrimaryDefinition": "to show one's face, to appear (in public)"
    },
    "Keys": {
      "Word": 8122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "女儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚér",
      "PrimaryDefinition": "daughter, girl"
    },
    "Keys": {
      "Word": 275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "山路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānlù",
      "PrimaryDefinition": "nek, pass, mountain road, mountain trail, ghaut, ghat"
    },
    "Keys": {
      "Word": 8950
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晚饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎnfàn",
      "PrimaryDefinition": "evening meal, dinner, supper, CL:份[fen4],頓|顿[dun4],次[ci4],餐[can1]"
    },
    "Keys": {
      "Word": 369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "渼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měi",
      "PrimaryDefinition": "ripples"
    },
    "Keys": {
      "Hanzi": 7434
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7434,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "裹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒ",
      "PrimaryDefinition": "wrap, bind; encircle, confine"
    },
    "Keys": {
      "Hanzi": 3139,
      "Word": 6940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.8",
      "Grade": 7,
      "GeneralStandard": 3139,
      "Radical": "衣",
      "HSK": 4,
      "FrequencyRank": 2387,
      "StrokeCount": 14
    }
  },
  "瞧不起": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáobuqǐ",
      "PrimaryDefinition": "to look down upon, to hold in contempt"
    },
    "Keys": {
      "Word": 8702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "穿越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuānyuè",
      "PrimaryDefinition": "cut, pass through, cut across, cross"
    },
    "Keys": {
      "Word": 6003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "艰辛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānxīn",
      "PrimaryDefinition": "hardships, arduous, difficult"
    },
    "Keys": {
      "Word": 7388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "eyeball; pupil of eye"
    },
    "Keys": {
      "Hanzi": 2895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.8",
      "GeneralStandard": 2895,
      "Radical": "目",
      "HSK": 2,
      "FrequencyRank": 934,
      "StrokeCount": 13
    }
  },
  "焦虑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāolǜ",
      "PrimaryDefinition": "anxious, apprehensive"
    },
    "Keys": {
      "Word": 7474
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "二": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "èr",
      "PrimaryDefinition": "two"
    },
    "Keys": {
      "Hanzi": 3,
      "Radical": 7,
      "Word": 97
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "7",
      "Grade": 1,
      "GeneralStandard": 3,
      "Radical": "二",
      "HSK": 1,
      "FrequencyRank": 157,
      "StrokeCount": 2
    }
  },
  "泸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lú",
      "PrimaryDefinition": "river in Jiangxi province"
    },
    "Keys": {
      "Hanzi": 4012
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4012,
      "Radical": "水",
      "FrequencyRank": 4736,
      "RadicalIndex": "85.5",
      "StrokeCount": 8
    }
  },
  "霰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎn",
      "PrimaryDefinition": "hail, sleet"
    },
    "Keys": {
      "Hanzi": 6425
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6425,
      "Radical": "雨",
      "FrequencyRank": 4723,
      "RadicalIndex": "173.12",
      "StrokeCount": 20
    }
  },
  "氧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎng",
      "PrimaryDefinition": "oxygen"
    },
    "Keys": {
      "Hanzi": 1936,
      "Word": 10225
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "84.6",
      "Grade": 7,
      "GeneralStandard": 1936,
      "Radical": "气",
      "HSK": 6,
      "FrequencyRank": 1863,
      "StrokeCount": 10
    }
  },
  "大批": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàpī",
      "PrimaryDefinition": "large quantities of"
    },
    "Keys": {
      "Word": 4476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "焞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūn",
      "PrimaryDefinition": "dim"
    },
    "Keys": {
      "Hanzi": 7422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7422,
      "Radical": "火",
      "RadicalIndex": "86.8",
      "StrokeCount": 12
    }
  },
  "汗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàn",
      "PrimaryDefinition": "perspiration, sweat"
    },
    "Keys": {
      "Hanzi": 536,
      "Word": 3535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.3",
      "Grade": 5,
      "GeneralStandard": 536,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1490,
      "StrokeCount": 6
    }
  },
  "饬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "order; command; give command"
    },
    "Keys": {
      "Hanzi": 3754
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3754,
      "Radical": "食",
      "FrequencyRank": 3742,
      "RadicalIndex": "184.4",
      "StrokeCount": 7
    }
  },
  "施": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī",
      "PrimaryDefinition": "grant, bestow; give; act; name"
    },
    "Keys": {
      "Hanzi": 1634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "70.5",
      "GeneralStandard": 1634,
      "Radical": "方",
      "HSK": 4,
      "FrequencyRank": 553,
      "StrokeCount": 9
    }
  },
  "烘干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng gān",
      "PrimaryDefinition": "to dry over a stove"
    },
    "Keys": {
      "Word": 7075
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "关系": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānxi",
      "PrimaryDefinition": "relation, bearing, impact, membership credentials, backdoor connections"
    },
    "Keys": {
      "Word": 1516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "跨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuà",
      "PrimaryDefinition": "straddle, bestride, ride; carry"
    },
    "Keys": {
      "Hanzi": 2913,
      "Word": 4800
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.6",
      "Grade": 6,
      "GeneralStandard": 2913,
      "Radical": "足",
      "HSK": 6,
      "FrequencyRank": 1774,
      "StrokeCount": 13
    }
  },
  "本地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běndì",
      "PrimaryDefinition": "local, this locality"
    },
    "Keys": {
      "Word": 4323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "勇于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngyú",
      "PrimaryDefinition": "be bold in, have the courage to, have courage to, be brave in"
    },
    "Keys": {
      "Word": 10437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "剔除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tīchú",
      "PrimaryDefinition": "to reject, to discard, to get rid of"
    },
    "Keys": {
      "Word": 9455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhǔn",
      "PrimaryDefinition": "not to allow, to forbid, to prohibit"
    },
    "Keys": {
      "Word": 5725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hè",
      "PrimaryDefinition": "bright, radiant, glowing"
    },
    "Keys": {
      "Hanzi": 3058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "155.7",
      "GeneralStandard": 3058,
      "Radical": "赤",
      "HSK": 9,
      "FrequencyRank": 1346,
      "StrokeCount": 14
    }
  },
  "淇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "river in Henan province"
    },
    "Keys": {
      "Hanzi": 4965
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4965,
      "Radical": "水",
      "FrequencyRank": 4010,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "认为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènwéi",
      "PrimaryDefinition": "find, treat, deem, suppose, look_on, repute, discount, esteem, accredit, feel, account, calculate, estimate, take to be, reckon, expect, think, hold, trust, recognise, opine, sound off, adjudge, consideration, consider, regard as, look upon, believe_in, disbelieve, allow, conceive, regard, call, imagine, view, forecast, believe, suspect, rate, animadvert, look on, listen, speak up, figure, c"
    },
    "Keys": {
      "Word": 957
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "保安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoān",
      "PrimaryDefinition": "to ensure public security, to ensure safety (for workers engaged in production), public security, security guard"
    },
    "Keys": {
      "Word": 1275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "话题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàtí",
      "PrimaryDefinition": "talking_point, theme, gambit, subject of a talk, ground, talk, talking point, topic, chapter, topic of conversation"
    },
    "Keys": {
      "Word": 1562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "癌症": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "áizhèng",
      "PrimaryDefinition": "carcinomatosis, cancer"
    },
    "Keys": {
      "Word": 5429
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "禹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "legendary hsia dynasty founder"
    },
    "Keys": {
      "Hanzi": 1575
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1575,
      "Radical": "禸",
      "FrequencyRank": 2889,
      "RadicalIndex": "114.4",
      "StrokeCount": 9
    }
  },
  "开阔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāikuò",
      "PrimaryDefinition": "wide, open (spaces), to open up"
    },
    "Keys": {
      "Word": 7751
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "金字塔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnzìtǎ",
      "PrimaryDefinition": "pyramid (building or structure)"
    },
    "Keys": {
      "Word": 7558
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蓝天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lántiān",
      "PrimaryDefinition": "blue sky"
    },
    "Keys": {
      "Word": 4809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "拼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīn",
      "PrimaryDefinition": "join together, link, incorporate"
    },
    "Keys": {
      "Hanzi": 1393,
      "Word": 3774
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 5,
      "GeneralStandard": 1393,
      "Radical": "手",
      "HSK": 5,
      "FrequencyRank": 1820,
      "StrokeCount": 9
    }
  },
  "宣扬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuānyáng",
      "PrimaryDefinition": "to proclaim, to make public or well known"
    },
    "Keys": {
      "Word": 10113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "现实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànshí",
      "PrimaryDefinition": "reality, actuality"
    },
    "Keys": {
      "Word": 2036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "珣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "name of a kind of jade"
    },
    "Keys": {
      "Hanzi": 4407
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4407,
      "Radical": "玉",
      "RadicalIndex": "96.6",
      "StrokeCount": 10
    }
  },
  "路段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùduàn",
      "PrimaryDefinition": "Section"
    },
    "Keys": {
      "Word": 8131
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miàn",
      "PrimaryDefinition": "face"
    },
    "Keys": {
      "Hanzi": 1455,
      "Radical": 176,
      "Word": 869
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "176",
      "Grade": 2,
      "GeneralStandard": 1455,
      "Radical": "面",
      "HSK": 1,
      "FrequencyRank": 74,
      "StrokeCount": 9
    }
  },
  "砀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàng",
      "PrimaryDefinition": "brilliantly colored stone with veined patterns in it"
    },
    "Keys": {
      "Hanzi": 3887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3887,
      "Radical": "石",
      "FrequencyRank": 5826,
      "RadicalIndex": "112.4",
      "StrokeCount": 8
    }
  },
  "黏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nián",
      "PrimaryDefinition": "stick to; glutinous, sticky; glue"
    },
    "Keys": {
      "Hanzi": 6254,
      "Word": 8416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "202.5",
      "Grade": 7,
      "GeneralStandard": 6254,
      "Radical": "黍",
      "HSK": 8,
      "FrequencyRank": 3645,
      "StrokeCount": 17
    }
  },
  "彐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "snout"
    },
    "Keys": {
      "Radical": 58
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "椟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "cabinet, wardrobe; closet"
    },
    "Keys": {
      "Hanzi": 5096
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5096,
      "Radical": "木",
      "FrequencyRank": 6535,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "韂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàn",
      "PrimaryDefinition": "a saddle-flap. trappings"
    },
    "Keys": {
      "Hanzi": 8088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8088,
      "Radical": "革",
      "FrequencyRank": 8306,
      "RadicalIndex": "177.13",
      "StrokeCount": 22
    }
  },
  "办学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn xué",
      "PrimaryDefinition": "to run a school"
    },
    "Keys": {
      "Word": 4296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "首相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuxiàng",
      "PrimaryDefinition": "prime minister (of Japan or UK etc)"
    },
    "Keys": {
      "Word": 5039
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "随身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíshēn",
      "PrimaryDefinition": "to (carry) on one's person, to (take) with one"
    },
    "Keys": {
      "Word": 9366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "崞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guō",
      "PrimaryDefinition": "mountain in Shanxi"
    },
    "Keys": {
      "Hanzi": 7172
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7172,
      "Radical": "山",
      "FrequencyRank": 5793,
      "RadicalIndex": "46.8",
      "StrokeCount": 11
    }
  },
  "防盗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángdào",
      "PrimaryDefinition": "to guard against theft, anti-theft"
    },
    "Keys": {
      "Word": 6487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "共同体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngtóngtǐ",
      "PrimaryDefinition": "community, integration"
    },
    "Keys": {
      "Word": 6807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "receive, welcome, greet"
    },
    "Keys": {
      "Hanzi": 822,
      "Word": 10415
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.4",
      "Grade": 7,
      "GeneralStandard": 822,
      "Radical": "辵",
      "HSK": 2,
      "FrequencyRank": 1069,
      "StrokeCount": 7
    }
  },
  "主食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔshí",
      "PrimaryDefinition": "main food, staple (rice and noodles)"
    },
    "Keys": {
      "Word": 10855
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "重现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóngxiàn",
      "PrimaryDefinition": "to reappear"
    },
    "Keys": {
      "Word": 5924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "工程师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngchéngshī",
      "PrimaryDefinition": "technologist, engineer, applied scientist"
    },
    "Keys": {
      "Word": 1496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "稚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "young, immature; childhood"
    },
    "Keys": {
      "Hanzi": 2948
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.8",
      "GeneralStandard": 2948,
      "Radical": "禾",
      "HSK": 7,
      "FrequencyRank": 2720,
      "StrokeCount": 13
    }
  },
  "才能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáinéng",
      "PrimaryDefinition": "ability, talent"
    },
    "Keys": {
      "Word": 1328
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "拭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "wipe away stains with cloth"
    },
    "Keys": {
      "Hanzi": 1364
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1364,
      "Radical": "手",
      "FrequencyRank": 3337,
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "鄗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hào",
      "PrimaryDefinition": "county in Hebei province"
    },
    "Keys": {
      "Hanzi": 7413
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7413,
      "Radical": "邑",
      "FrequencyRank": 6552,
      "RadicalIndex": "163.1",
      "StrokeCount": 12
    }
  },
  "不假思索": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjiǎ-sīsuǒ",
      "PrimaryDefinition": "to act without taking time to think (idiom), to react instantly, to fire from the hip"
    },
    "Keys": {
      "Word": 5696
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "檩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐn",
      "PrimaryDefinition": "the bole of a tree; a cross beam; the combing round the hatches of a ship"
    },
    "Keys": {
      "Hanzi": 6213
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6213,
      "Radical": "木",
      "FrequencyRank": 5662,
      "RadicalIndex": "75.13",
      "StrokeCount": 17
    }
  },
  "上来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàng lai",
      "PrimaryDefinition": "come_up, come up"
    },
    "Keys": {
      "Word": 1870
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "灵感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línggǎn",
      "PrimaryDefinition": "motive, afflatus, rage, breathing, brainwave, muse, inspiration, brainchild"
    },
    "Keys": {
      "Word": 8066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "衫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "shirt; robe; gown; jacket"
    },
    "Keys": {
      "Hanzi": 1293
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.3",
      "GeneralStandard": 1293,
      "Radical": "衣",
      "HSK": 3,
      "FrequencyRank": 2153,
      "StrokeCount": 8
    }
  },
  "龆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiáo",
      "PrimaryDefinition": "lose baby teeth and get adult teeth"
    },
    "Keys": {
      "Hanzi": 7514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7514,
      "Radical": "齒",
      "FrequencyRank": 5134,
      "RadicalIndex": "211.5",
      "StrokeCount": 13
    }
  },
  "轸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "cross board at rear of carriage"
    },
    "Keys": {
      "Hanzi": 4182
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4182,
      "Radical": "車",
      "FrequencyRank": 5511,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "惆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "distressed, regretful, sad"
    },
    "Keys": {
      "Hanzi": 4989
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4989,
      "Radical": "心",
      "FrequencyRank": 4079,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "俙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "to pretend, appear as if"
    },
    "Keys": {
      "Hanzi": 6857
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6857,
      "Radical": "人",
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "油画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóuhuà",
      "PrimaryDefinition": "canvas, painting, canvass, oil, oil painting, oil_painting, oil paint"
    },
    "Keys": {
      "Word": 10466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "訇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hōng",
      "PrimaryDefinition": "the sound of a crash"
    },
    "Keys": {
      "Hanzi": 4296
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4296,
      "Radical": "言",
      "FrequencyRank": 5477,
      "RadicalIndex": "149.2",
      "StrokeCount": 9
    }
  },
  "老年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎonián",
      "PrimaryDefinition": "senescence, caducity, senility, old_age, agedness, hoariness, age, old age"
    },
    "Keys": {
      "Word": 833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "弨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāo",
      "PrimaryDefinition": "bow"
    },
    "Keys": {
      "Hanzi": 6735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6735,
      "Radical": "弓",
      "RadicalIndex": "57.5",
      "StrokeCount": 8
    }
  },
  "嫘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": "surname"
    },
    "Keys": {
      "Hanzi": 5835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5835,
      "Radical": "女",
      "FrequencyRank": 5740,
      "RadicalIndex": "38.11",
      "StrokeCount": 14
    }
  },
  "糜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "rice gruel, congee; mashed"
    },
    "Keys": {
      "Hanzi": 6287
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6287,
      "Radical": "米",
      "FrequencyRank": 3713,
      "RadicalIndex": "119.11",
      "StrokeCount": 17
    }
  },
  "庄严": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāngyán",
      "PrimaryDefinition": "solemn, dignified, stately"
    },
    "Keys": {
      "Word": 10904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáo",
      "PrimaryDefinition": "wren"
    },
    "Keys": {
      "Hanzi": 6219
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6219,
      "Radical": "鳥",
      "FrequencyRank": 5345,
      "RadicalIndex": "196.12",
      "StrokeCount": 17
    }
  },
  "垕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "used in place names"
    },
    "Keys": {
      "Hanzi": 6859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6859,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "哞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mōu",
      "PrimaryDefinition": "moo"
    },
    "Keys": {
      "Hanzi": 4229
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4229,
      "Radical": "口",
      "FrequencyRank": 5619,
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "注册": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù cè",
      "PrimaryDefinition": "register"
    },
    "Keys": {
      "Word": 4258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鄌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7417
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7417,
      "Radical": "邑",
      "RadicalIndex": "163.1",
      "StrokeCount": 12
    }
  },
  "壁画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìhuà",
      "PrimaryDefinition": "mural (painting), fresco"
    },
    "Keys": {
      "Word": 5593
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "馔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "to feed, support, provide for; food"
    },
    "Keys": {
      "Hanzi": 5979
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5979,
      "Radical": "食",
      "FrequencyRank": 4891,
      "RadicalIndex": "184.12",
      "StrokeCount": 15
    }
  },
  "搀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chān",
      "PrimaryDefinition": "give helping hand"
    },
    "Keys": {
      "Hanzi": 2531,
      "Word": 5796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "Grade": 7,
      "GeneralStandard": 2531,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 3384,
      "StrokeCount": 12
    }
  },
  "黇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7840
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7840,
      "Radical": "黃",
      "FrequencyRank": 8438,
      "RadicalIndex": "201.5",
      "StrokeCount": 16
    }
  },
  "绒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "silk, cotton, or woolen fabric"
    },
    "Keys": {
      "Hanzi": 1753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "GeneralStandard": 1753,
      "Radical": "糸",
      "HSK": 5,
      "FrequencyRank": 2678,
      "StrokeCount": 9
    }
  },
  "干旱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gānhàn",
      "PrimaryDefinition": "drought, arid, dry"
    },
    "Keys": {
      "Word": 6665
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "告辞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàocí",
      "PrimaryDefinition": "to say goodbye, to take one's leave"
    },
    "Keys": {
      "Word": 6713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沉思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénsī",
      "PrimaryDefinition": "to contemplate, to ponder, contemplation, meditation"
    },
    "Keys": {
      "Word": 5854
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sì",
      "PrimaryDefinition": "court, office; temple, monastery"
    },
    "Keys": {
      "Hanzi": 355,
      "Word": 5052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "41.3",
      "Grade": 6,
      "GeneralStandard": 355,
      "Radical": "寸",
      "HSK": 6,
      "FrequencyRank": 1892,
      "StrokeCount": 6
    }
  },
  "履行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚxíng",
      "PrimaryDefinition": "to fulfill (one's obligations), to carry out (a task), to implement (an agreement), to perform"
    },
    "Keys": {
      "Word": 8145
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "趁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèn",
      "PrimaryDefinition": "take advantage of, avail oneself"
    },
    "Keys": {
      "Hanzi": 2515,
      "Word": 5861
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "156.5",
      "Grade": 7,
      "GeneralStandard": 2515,
      "Radical": "走",
      "HSK": 7,
      "FrequencyRank": 2109,
      "StrokeCount": 12
    }
  },
  "冗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rǒng",
      "PrimaryDefinition": "excessive; superfluous"
    },
    "Keys": {
      "Hanzi": 172
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "14.2",
      "GeneralStandard": 172,
      "Radical": "冖",
      "HSK": 9,
      "FrequencyRank": 3642,
      "StrokeCount": 4
    }
  },
  "芪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "celery"
    },
    "Keys": {
      "Hanzi": 3672
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3672,
      "Radical": "艸",
      "FrequencyRank": 4507,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "朽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǔ",
      "PrimaryDefinition": "decayed, rotten; rot, decay"
    },
    "Keys": {
      "Hanzi": 375
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.2",
      "GeneralStandard": 375,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2544,
      "StrokeCount": 6
    }
  },
  "极度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jídù",
      "PrimaryDefinition": "extremely, plaguey, exceedingly, deadly, vitally, to the utmost, passing, plaguily, badly, plaguy"
    },
    "Keys": {
      "Word": 7293
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fěng",
      "PrimaryDefinition": "recite, incant; satirize"
    },
    "Keys": {
      "Hanzi": 559
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 559,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 2322,
      "StrokeCount": 6
    }
  },
  "简体字": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎntǐzì",
      "PrimaryDefinition": "simplified Chinese character, as opposed to traditional Chinese character 繁體字|繁体字[fan2 ti3 zi4]"
    },
    "Keys": {
      "Word": 7413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "年终": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánzhōng",
      "PrimaryDefinition": "end of the year"
    },
    "Keys": {
      "Word": 8414
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "铁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiě",
      "PrimaryDefinition": "iron; strong, solid, firm"
    },
    "Keys": {
      "Hanzi": 1932,
      "Word": 1967
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "Grade": 3,
      "GeneralStandard": 1932,
      "Radical": "金",
      "HSK": 2,
      "FrequencyRank": 779,
      "StrokeCount": 10
    }
  },
  "锼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "carve"
    },
    "Keys": {
      "Hanzi": 7680
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7680,
      "Radical": "金",
      "FrequencyRank": 5940,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "地板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìbǎn",
      "PrimaryDefinition": "boarding, planking, hatch, plat, floorboard, hatchway, floor board, flooring, planch, floor, footplate"
    },
    "Keys": {
      "Word": 4502
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "徵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "summon, recruit; musical note"
    },
    "Keys": {
      "Hanzi": 5962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5962,
      "Radical": "彳",
      "FrequencyRank": 4086,
      "RadicalIndex": "60.12",
      "StrokeCount": 15
    }
  },
  "丨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "line"
    },
    "Keys": {
      "Radical": 2
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "高血压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoxuèyā",
      "PrimaryDefinition": "high_blood_pressure, high blood pressure, hypertension"
    },
    "Keys": {
      "Word": 6706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "silver carp, hypophthalmiathys"
    },
    "Keys": {
      "Hanzi": 5970
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5970,
      "Radical": "魚",
      "FrequencyRank": 5119,
      "RadicalIndex": "195.8",
      "StrokeCount": 15
    }
  },
  "左边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuǒbian",
      "PrimaryDefinition": "left, the left side, to the left of"
    },
    "Keys": {
      "Word": 493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "澳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "inlet, bay; dock, bank"
    },
    "Keys": {
      "Hanzi": 3295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.13",
      "GeneralStandard": 3295,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 1627,
      "StrokeCount": 15
    }
  },
  "牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pái",
      "PrimaryDefinition": "signboard, placard"
    },
    "Keys": {
      "Hanzi": 2692,
      "Word": 2770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "91.8",
      "Grade": 4,
      "GeneralStandard": 2692,
      "Radical": "片",
      "HSK": 3,
      "FrequencyRank": 1261,
      "StrokeCount": 12
    }
  },
  "保险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎoxiǎn",
      "PrimaryDefinition": "insurance"
    },
    "Keys": {
      "Word": 1280
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "啬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sè",
      "PrimaryDefinition": "miserly, thrifty; stingy"
    },
    "Keys": {
      "Hanzi": 4780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "24.9",
      "GeneralStandard": 4780,
      "Radical": "十",
      "HSK": 6,
      "FrequencyRank": 3860,
      "StrokeCount": 11
    }
  },
  "门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mén",
      "PrimaryDefinition": "gate"
    },
    "Keys": {
      "Hanzi": 55,
      "Radical": 169,
      "Word": 235
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "169",
      "Grade": 1,
      "GeneralStandard": 55,
      "Radical": "門",
      "HSK": 1,
      "FrequencyRank": 185,
      "StrokeCount": 3
    }
  },
  "钬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒ",
      "PrimaryDefinition": "holmium"
    },
    "Keys": {
      "Hanzi": 6850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6850,
      "Radical": "金",
      "FrequencyRank": 6713,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "吡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "blame"
    },
    "Keys": {
      "Hanzi": 3705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3705,
      "Radical": "口",
      "FrequencyRank": 5056,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "因人而异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnrénéryì",
      "PrimaryDefinition": "varying from person to person (idiom), different for each individual"
    },
    "Keys": {
      "Word": 10382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "利率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìlǜ",
      "PrimaryDefinition": "profit, interest rate, rate_of_interest, rate of interest"
    },
    "Keys": {
      "Word": 8006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "处在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǔzài",
      "PrimaryDefinition": "be in, be (in a certain condition), be"
    },
    "Keys": {
      "Word": 3355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "自行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìxíng",
      "PrimaryDefinition": "voluntary, autonomous, by oneself, self-"
    },
    "Keys": {
      "Word": 10968
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "将军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāngjūn",
      "PrimaryDefinition": "problem, mate, general, high-rank officer, marshal, full general, shogun, aga, warlord, tycoon, check"
    },
    "Keys": {
      "Word": 4715
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "从今以后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóngjīnyǐhòu",
      "PrimaryDefinition": "From now on"
    },
    "Keys": {
      "Word": 6057
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "疏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "neglect; careless, lax"
    },
    "Keys": {
      "Hanzi": 2804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "103.7",
      "GeneralStandard": 2804,
      "Radical": "疋",
      "HSK": 7,
      "FrequencyRank": 1897,
      "StrokeCount": 12
    }
  },
  "瀑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pù",
      "PrimaryDefinition": "waterfall, cascade; heavy rain"
    },
    "Keys": {
      "Hanzi": 3448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.15",
      "GeneralStandard": 3448,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 3195,
      "StrokeCount": 18
    }
  },
  "交往": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāowǎng",
      "PrimaryDefinition": "associate, contact"
    },
    "Keys": {
      "Word": 1610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "way, road, path, journey; course"
    },
    "Keys": {
      "Hanzi": 1982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "GeneralStandard": 1982,
      "Radical": "辵",
      "HSK": 4,
      "FrequencyRank": 1085,
      "StrokeCount": 10
    }
  },
  "涔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cén",
      "PrimaryDefinition": "river in Shaanxi; murky torrent"
    },
    "Keys": {
      "Hanzi": 4654
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4654,
      "Radical": "水",
      "FrequencyRank": 4706,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "股票": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔpiào",
      "PrimaryDefinition": "capital stock, share, stock certificate, security, share certificate, stock"
    },
    "Keys": {
      "Word": 4622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "马桶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎtǒng",
      "PrimaryDefinition": "chamber pot, wooden pan used as toilet, toilet bowl"
    },
    "Keys": {
      "Word": 8182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "列": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liè",
      "PrimaryDefinition": "a line; to arrange in order, classify"
    },
    "Keys": {
      "Hanzi": 399,
      "Word": 2705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.4",
      "Grade": 4,
      "GeneralStandard": 399,
      "Radical": "刀",
      "HSK": 4,
      "FrequencyRank": 500,
      "StrokeCount": 6
    }
  },
  "秘书": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìshū",
      "PrimaryDefinition": "secretarial, secretary, clerk, amanuensis, secretarial assistant"
    },
    "Keys": {
      "Word": 2736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "垺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6944
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6944,
      "Radical": "土",
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "槟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīn",
      "PrimaryDefinition": "betel-nut, areca nut"
    },
    "Keys": {
      "Hanzi": 5668
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5668,
      "Radical": "木",
      "FrequencyRank": 4036,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "缃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "light-yellow color"
    },
    "Keys": {
      "Hanzi": 5329
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5329,
      "Radical": "糸",
      "FrequencyRank": 5487,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "蔓延": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànyán",
      "PrimaryDefinition": "spread out, extend"
    },
    "Keys": {
      "Word": 8200
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "饼干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐnggān",
      "PrimaryDefinition": "biscuit, cracker, cookie, CL:片[pian4],塊|块[kuai4]"
    },
    "Keys": {
      "Word": 3262
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "镴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "là",
      "PrimaryDefinition": "solder; tin"
    },
    "Keys": {
      "Hanzi": 6436
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6436,
      "Radical": "金",
      "FrequencyRank": 7148,
      "RadicalIndex": "167.15",
      "StrokeCount": 20
    }
  },
  "食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shí",
      "PrimaryDefinition": "eat; meal; food; KangXi radical number 184"
    },
    "Keys": {
      "Hanzi": 1589
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "184",
      "GeneralStandard": 1589,
      "Radical": "食",
      "HSK": 2,
      "FrequencyRank": 671,
      "StrokeCount": 9
    }
  },
  "现金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànjīn",
      "PrimaryDefinition": "quids, rhino, clink, ready, cash reserve in bank, dough, dust, ducat, kale, cash, blunt, till, ready money"
    },
    "Keys": {
      "Word": 2035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "购": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "buy, purchase; hire"
    },
    "Keys": {
      "Hanzi": 1117,
      "Word": 6815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "Grade": 7,
      "GeneralStandard": 1117,
      "Radical": "貝",
      "HSK": 4,
      "FrequencyRank": 1078,
      "StrokeCount": 8
    }
  },
  "歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "song, lyrics; sing, chant; praise"
    },
    "Keys": {
      "Hanzi": 3084,
      "Word": 116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "76.1",
      "Grade": 1,
      "GeneralStandard": 3084,
      "Radical": "欠",
      "HSK": 1,
      "FrequencyRank": 1040,
      "StrokeCount": 14
    }
  },
  "挑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāo",
      "PrimaryDefinition": "a load carried on the shoulders; to carry"
    },
    "Keys": {
      "Hanzi": 1387,
      "Word": 2932
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 4,
      "GeneralStandard": 1387,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1309,
      "StrokeCount": 9
    }
  },
  "大厦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàshà",
      "PrimaryDefinition": "building, mansion"
    },
    "Keys": {
      "Word": 6130
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "动向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòngxiàng",
      "PrimaryDefinition": "set, development, tendency, trend, movement, drift"
    },
    "Keys": {
      "Word": 6339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "航空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángkōng",
      "PrimaryDefinition": "air, aerospace, aerial, air travel, air power, aeronautical, navigation, voyage, aviation, aeronautic"
    },
    "Keys": {
      "Word": 2536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "巡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "patrol, go on circuit, cruise"
    },
    "Keys": {
      "Hanzi": 598
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "47.4",
      "GeneralStandard": 598,
      "Radical": "巛",
      "HSK": 7,
      "FrequencyRank": 1544,
      "StrokeCount": 6
    }
  },
  "捶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuí",
      "PrimaryDefinition": "strike with stick, lash, beat"
    },
    "Keys": {
      "Hanzi": 2171,
      "Word": 6026
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 7,
      "GeneralStandard": 2171,
      "Radical": "手",
      "HSK": 9,
      "FrequencyRank": 3478,
      "StrokeCount": 11
    }
  },
  "浯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wú",
      "PrimaryDefinition": "name of a river in Shandong"
    },
    "Keys": {
      "Hanzi": 4645
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4645,
      "Radical": "水",
      "FrequencyRank": 5357,
      "RadicalIndex": "85.7",
      "StrokeCount": 10
    }
  },
  "蛐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "cricket; worm"
    },
    "Keys": {
      "Hanzi": 5146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5146,
      "Radical": "虫",
      "FrequencyRank": 4146,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "薰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "a medicinal herb; to cauterize"
    },
    "Keys": {
      "Hanzi": 6209
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6209,
      "Radical": "艸",
      "FrequencyRank": 4055,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "缺陷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quēxiàn",
      "PrimaryDefinition": "gap, deficiency, trouble, flaw, blemishment, blemish, shortage, limitation, incapacity, defect, shortcoming, deformity, defacement, drawback, disfigurement, disadvantage"
    },
    "Keys": {
      "Word": 4964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "长寿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángshòu",
      "PrimaryDefinition": "Changshou suburban district of Chongqing municipality, formerly in Sichuan, longevity, ability to live long, long lived"
    },
    "Keys": {
      "Word": 3310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "鲂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fáng",
      "PrimaryDefinition": "bream"
    },
    "Keys": {
      "Hanzi": 5237
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5237,
      "Radical": "魚",
      "FrequencyRank": 4954,
      "RadicalIndex": "195.4",
      "StrokeCount": 12
    }
  },
  "敞开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎng kāi",
      "PrimaryDefinition": "to open wide, unrestrictedly"
    },
    "Keys": {
      "Word": 5821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耀眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàoyǎn",
      "PrimaryDefinition": "to dazzle, dazzling"
    },
    "Keys": {
      "Word": 10252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "反感": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎngǎn",
      "PrimaryDefinition": "to be disgusted with, to dislike, bad reaction, antipathy"
    },
    "Keys": {
      "Word": 6470
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "be as diligent as possible"
    },
    "Keys": {
      "Hanzi": 3801
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3801,
      "Radical": "子",
      "FrequencyRank": 3150,
      "RadicalIndex": "39.4",
      "StrokeCount": 7
    }
  },
  "中部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngbù",
      "PrimaryDefinition": "central section, middle"
    },
    "Keys": {
      "Word": 2180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "䲠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūn",
      "PrimaryDefinition": "(simplified form 鰆) a kind of fish; long and flat; silver-gray with dark color dots and cross-grained strips"
    },
    "Keys": {
      "Hanzi": 7960
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7960,
      "Radical": "",
      "FrequencyRank": 9713,
      "RadicalIndex": "",
      "StrokeCount": 20
    }
  },
  "喜糖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐtáng",
      "PrimaryDefinition": "sweet given on a happy occasion (esp. wedding)"
    },
    "Keys": {
      "Word": 9843
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "效仿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàofǎng",
      "PrimaryDefinition": "see 仿傚|仿效[fang3 xiao4]"
    },
    "Keys": {
      "Word": 9980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "改革": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎigé",
      "PrimaryDefinition": "reform"
    },
    "Keys": {
      "Word": 3476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "办公": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn gōng",
      "PrimaryDefinition": "work (usu. in an office), transact, work, handle official business"
    },
    "Keys": {
      "Word": 4294
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "欤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "a final particle used to express admiration, doubt, surprise, or to mark a question"
    },
    "Keys": {
      "Hanzi": 3690
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3690,
      "Radical": "欠",
      "FrequencyRank": 3879,
      "RadicalIndex": "76.3",
      "StrokeCount": 7
    }
  },
  "棱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léng",
      "PrimaryDefinition": "squared timber; angle, edge"
    },
    "Keys": {
      "Hanzi": 2563
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2563,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2897,
      "StrokeCount": 12
    }
  },
  "用餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòng cān",
      "PrimaryDefinition": "to eat a meal"
    },
    "Keys": {
      "Word": 10441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大妈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàmā",
      "PrimaryDefinition": "father's elder brother's wife, aunt (affectionate term for an elderly woman)"
    },
    "Keys": {
      "Word": 2375
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "配备": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèibèi",
      "PrimaryDefinition": "equipment"
    },
    "Keys": {
      "Word": 3762
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "森": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sēn",
      "PrimaryDefinition": "forest; luxuriant vegetation"
    },
    "Keys": {
      "Hanzi": 2567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.8",
      "GeneralStandard": 2567,
      "Radical": "木",
      "HSK": 4,
      "FrequencyRank": 1029,
      "StrokeCount": 12
    }
  },
  "种种": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒngzhǒng",
      "PrimaryDefinition": "all kinds of"
    },
    "Keys": {
      "Word": 5372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "隩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "profound, subtle; warm"
    },
    "Keys": {
      "Hanzi": 7741
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7741,
      "Radical": "阜",
      "FrequencyRank": 8936,
      "RadicalIndex": "170.13",
      "StrokeCount": 14
    }
  },
  "彬彬有礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīnbīn-yǒulǐ",
      "PrimaryDefinition": "refined and courteous, urbane"
    },
    "Keys": {
      "Word": 5644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "殣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn",
      "PrimaryDefinition": "die; death; to starve to death"
    },
    "Keys": {
      "Hanzi": 7764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7764,
      "Radical": "歹",
      "RadicalIndex": "78.11",
      "StrokeCount": 15
    }
  },
  "苛刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēkè",
      "PrimaryDefinition": "harsh, severe, demanding"
    },
    "Keys": {
      "Word": 7786
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "秘方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìfāng",
      "PrimaryDefinition": "secret recipe"
    },
    "Keys": {
      "Word": 8264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cè",
      "PrimaryDefinition": "feel anguish, feel compassion"
    },
    "Keys": {
      "Hanzi": 4354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4354,
      "Radical": "心",
      "FrequencyRank": 3896,
      "RadicalIndex": "61.6",
      "StrokeCount": 9
    }
  },
  "襫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "raincoat"
    },
    "Keys": {
      "Hanzi": 8070
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8070,
      "Radical": "衣",
      "FrequencyRank": 8477,
      "RadicalIndex": "145.15",
      "StrokeCount": 20
    }
  },
  "睡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuì",
      "PrimaryDefinition": "sleep, doze"
    },
    "Keys": {
      "Hanzi": 2900,
      "Word": 343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.8",
      "Grade": 1,
      "GeneralStandard": 2900,
      "Radical": "目",
      "HSK": 1,
      "FrequencyRank": 964,
      "StrokeCount": 13
    }
  },
  "画册": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàcè",
      "PrimaryDefinition": "picture album"
    },
    "Keys": {
      "Word": 7146
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "湾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": "bay, cove, inlet, bend of stream"
    },
    "Keys": {
      "Hanzi": 2764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2764,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 855,
      "StrokeCount": 12
    }
  },
  "火车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒchē",
      "PrimaryDefinition": "choo-choo, railroad train, car train, marshalling_yard, puff-puff, train"
    },
    "Keys": {
      "Word": 158
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "纵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòng",
      "PrimaryDefinition": "indulge in, give free reign to"
    },
    "Keys": {
      "Hanzi": 936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 936,
      "Radical": "糸",
      "HSK": 6,
      "FrequencyRank": 1236,
      "StrokeCount": 7
    }
  },
  "鹛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5829
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5829,
      "Radical": "鳥",
      "FrequencyRank": 6336,
      "RadicalIndex": "196.9",
      "StrokeCount": 14
    }
  },
  "疙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gē",
      "PrimaryDefinition": "pimple, sore, boil, wart, pustule"
    },
    "Keys": {
      "Hanzi": 1214
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "104.3",
      "GeneralStandard": 1214,
      "Radical": "疒",
      "HSK": 6,
      "FrequencyRank": 3527,
      "StrokeCount": 8
    }
  },
  "感慨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnkǎi",
      "PrimaryDefinition": "sigh with emotion"
    },
    "Keys": {
      "Word": 6677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "first day of lunar month; the north"
    },
    "Keys": {
      "Hanzi": 4635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4635,
      "Radical": "月",
      "FrequencyRank": 3111,
      "RadicalIndex": "74.6",
      "StrokeCount": 10
    }
  },
  "求医": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúyī",
      "PrimaryDefinition": "to seek medical treatment, to see a doctor"
    },
    "Keys": {
      "Word": 8774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiào",
      "PrimaryDefinition": "jump, leap, vault, bounce; dance"
    },
    "Keys": {
      "Hanzi": 2915,
      "Word": 1963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "157.6",
      "Grade": 3,
      "GeneralStandard": 2915,
      "Radical": "足",
      "HSK": 3,
      "FrequencyRank": 999,
      "StrokeCount": 13
    }
  },
  "标准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāozhǔn",
      "PrimaryDefinition": "standard, criterion"
    },
    "Keys": {
      "Word": 1303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蹰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "hesitate, waver, falter"
    },
    "Keys": {
      "Hanzi": 6383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6383,
      "Radical": "足",
      "FrequencyRank": 5763,
      "RadicalIndex": "157.12",
      "StrokeCount": 19
    }
  },
  "通畅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngchàng",
      "PrimaryDefinition": "unobstructed, clear"
    },
    "Keys": {
      "Word": 9519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "dense, crowded, packed; soupy"
    },
    "Keys": {
      "Hanzi": 2949,
      "Word": 5936
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.8",
      "Grade": 7,
      "GeneralStandard": 2949,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 3367,
      "StrokeCount": 13
    }
  },
  "下个月": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàgèyuè",
      "PrimaryDefinition": "next month"
    },
    "Keys": {
      "Word": 3006
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "苹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "artemisia; duckweed; apple"
    },
    "Keys": {
      "Hanzi": 1004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.5",
      "GeneralStandard": 1004,
      "Radical": "艸",
      "HSK": 3,
      "FrequencyRank": 2478,
      "StrokeCount": 8
    }
  },
  "梵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn",
      "PrimaryDefinition": "Buddhist, Sanskrit"
    },
    "Keys": {
      "Hanzi": 4770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4770,
      "Radical": "木",
      "FrequencyRank": 3276,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "笱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒu",
      "PrimaryDefinition": "a basket trap for fish, placed in the opening of a weir"
    },
    "Keys": {
      "Hanzi": 7190
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7190,
      "Radical": "竹",
      "FrequencyRank": 5449,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "据": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "to occupy, take possession of; a base, position"
    },
    "Keys": {
      "Hanzi": 2189,
      "Word": 4764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.8",
      "Grade": 6,
      "GeneralStandard": 2189,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 313,
      "StrokeCount": 11
    }
  },
  "简化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnhuà",
      "PrimaryDefinition": "simplify"
    },
    "Keys": {
      "Word": 7410
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "而已": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "éryǐ",
      "PrimaryDefinition": "particle: imparting finality (that's all)"
    },
    "Keys": {
      "Word": 6422
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "潇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "sound of beating wind and rain"
    },
    "Keys": {
      "Hanzi": 3160
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "GeneralStandard": 3160,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 3216,
      "StrokeCount": 14
    }
  },
  "犯罪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàn zuì",
      "PrimaryDefinition": "commit crime/offense"
    },
    "Keys": {
      "Word": 4554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "贮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "store, stockpile, hoard"
    },
    "Keys": {
      "Hanzi": 1118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.4",
      "GeneralStandard": 1118,
      "Radical": "貝",
      "HSK": 9,
      "FrequencyRank": 2824,
      "StrokeCount": 8
    }
  },
  "纛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "a banner, a streamer"
    },
    "Keys": {
      "Hanzi": 6493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6493,
      "Radical": "糸",
      "FrequencyRank": 6329,
      "RadicalIndex": "120.19",
      "StrokeCount": 25
    }
  },
  "阱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐng",
      "PrimaryDefinition": "pitfall, trap, snare"
    },
    "Keys": {
      "Hanzi": 3638
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.4",
      "GeneralStandard": 3638,
      "Radical": "阜",
      "HSK": 7,
      "FrequencyRank": 3224,
      "StrokeCount": 6
    }
  },
  "制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "system; establish; overpower"
    },
    "Keys": {
      "Hanzi": 1121,
      "Word": 10783
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 7,
      "GeneralStandard": 1121,
      "Radical": "刀",
      "HSK": 3,
      "FrequencyRank": 163,
      "StrokeCount": 8
    }
  },
  "咏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "sing song or poem, hum, chant"
    },
    "Keys": {
      "Hanzi": 1101
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1101,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 3469,
      "StrokeCount": 8
    }
  },
  "奇迹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíjì",
      "PrimaryDefinition": "miracle, miraculous, wonder, marvel"
    },
    "Keys": {
      "Word": 8597
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "启发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐfā",
      "PrimaryDefinition": "enlighten, stimulate"
    },
    "Keys": {
      "Word": 3790
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "五花八门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔhuā-bāmén",
      "PrimaryDefinition": "myriad, all kinds of, all sorts of"
    },
    "Keys": {
      "Word": 9797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娃娃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wáwa",
      "PrimaryDefinition": "mammet, baby, doll, child"
    },
    "Keys": {
      "Word": 5115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "雄厚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiónghòu",
      "PrimaryDefinition": "robust, strong and solid"
    },
    "Keys": {
      "Word": 10071
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "慕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "long for, desire; admire"
    },
    "Keys": {
      "Hanzi": 3066
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.11",
      "GeneralStandard": 3066,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1990,
      "StrokeCount": 14
    }
  },
  "虚心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūxīn",
      "PrimaryDefinition": "modest"
    },
    "Keys": {
      "Word": 4076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "诜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēn",
      "PrimaryDefinition": "inquire, question; numerous"
    },
    "Keys": {
      "Hanzi": 4052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4052,
      "Radical": "言",
      "FrequencyRank": 4571,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "镊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "tweezers, forceps, pincers"
    },
    "Keys": {
      "Hanzi": 5946
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5946,
      "Radical": "金",
      "FrequencyRank": 5395,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "缣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "fine silk"
    },
    "Keys": {
      "Hanzi": 5618
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5618,
      "Radical": "糸",
      "FrequencyRank": 5876,
      "RadicalIndex": "120.1",
      "StrokeCount": 13
    }
  },
  "枲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "male nettle-hemp"
    },
    "Keys": {
      "Hanzi": 6916
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6916,
      "Radical": "木",
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "滏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5580
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5580,
      "Radical": "水",
      "FrequencyRank": 6902,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "容忍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngrěn",
      "PrimaryDefinition": "tolerate, condone"
    },
    "Keys": {
      "Word": 8881
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoshuō",
      "PrimaryDefinition": "easy to deal with, not a problem, (polite answer) you flatter me"
    },
    "Keys": {
      "Word": 7009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fán",
      "PrimaryDefinition": "vanadium"
    },
    "Keys": {
      "Hanzi": 3942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3942,
      "Radical": "金",
      "FrequencyRank": 5140,
      "RadicalIndex": "167.3",
      "StrokeCount": 8
    }
  },
  "暲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "bright; to rise (of sun)"
    },
    "Keys": {
      "Hanzi": 7768
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7768,
      "Radical": "日",
      "RadicalIndex": "72.11",
      "StrokeCount": 15
    }
  },
  "承受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngshòu",
      "PrimaryDefinition": "bear, support, endure, inherit (legacy/etc.)"
    },
    "Keys": {
      "Word": 2320
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鸸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ér",
      "PrimaryDefinition": "swallow"
    },
    "Keys": {
      "Hanzi": 4797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4797,
      "Radical": "鳥",
      "FrequencyRank": 6082,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "快捷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàijié",
      "PrimaryDefinition": "quick, fast, nimble, agile, (computer) shortcut"
    },
    "Keys": {
      "Word": 7876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "拿到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nádào",
      "PrimaryDefinition": "take, get_hold_of, get"
    },
    "Keys": {
      "Word": 876
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "信任": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnrèn",
      "PrimaryDefinition": "trust, have confidence in"
    },
    "Keys": {
      "Word": 2055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "线路": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànlù",
      "PrimaryDefinition": "wiring, line, route, circuit"
    },
    "Keys": {
      "Word": 5165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "虮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐ",
      "PrimaryDefinition": "louse eggs, nits"
    },
    "Keys": {
      "Hanzi": 3910
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3910,
      "Radical": "虫",
      "FrequencyRank": 5822,
      "RadicalIndex": "142.2",
      "StrokeCount": 8
    }
  },
  "啭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuàn",
      "PrimaryDefinition": "sing, chirp, warble, twitter"
    },
    "Keys": {
      "Hanzi": 4826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4826,
      "Radical": "口",
      "FrequencyRank": 5005,
      "RadicalIndex": "30.8",
      "StrokeCount": 11
    }
  },
  "薮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒu",
      "PrimaryDefinition": "marsh, swamp; wild country"
    },
    "Keys": {
      "Hanzi": 6053
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6053,
      "Radical": "艸",
      "FrequencyRank": 4835,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "兴致": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìngzhì",
      "PrimaryDefinition": "mood, spirits, interest"
    },
    "Keys": {
      "Word": 10055
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "千方百计": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiānfāng-bǎijì",
      "PrimaryDefinition": "lit. thousand ways, a hundred plans (idiom), by every possible means"
    },
    "Keys": {
      "Word": 8646
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "实验室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíyànshì",
      "PrimaryDefinition": "lab, science lab, laboratory"
    },
    "Keys": {
      "Word": 1902
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "殓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàn",
      "PrimaryDefinition": "dress corpse for burial"
    },
    "Keys": {
      "Hanzi": 4803
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4803,
      "Radical": "歹",
      "FrequencyRank": 4461,
      "RadicalIndex": "78.7",
      "StrokeCount": 11
    }
  },
  "眉毛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méimao",
      "PrimaryDefinition": "eyebrow, brow"
    },
    "Keys": {
      "Word": 8226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "abundant, lush, bountiful, plenty"
    },
    "Keys": {
      "Hanzi": 74
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "2.3",
      "GeneralStandard": 74,
      "Radical": "丨",
      "HSK": 3,
      "FrequencyRank": 1189,
      "StrokeCount": 4
    }
  },
  "显著": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnzhù",
      "PrimaryDefinition": "outstand, striking, noticeable, remarkable, appear, marked, notable, outstanding, stand_out"
    },
    "Keys": {
      "Word": 3014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "引用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐnyòng",
      "PrimaryDefinition": "quote, cite, recommend, appoint"
    },
    "Keys": {
      "Word": 10394
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "稀罕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīhan",
      "PrimaryDefinition": "rare, uncommon, rarity, to value as a rarity, to cherish, Taiwan pr. [xi1 han3]"
    },
    "Keys": {
      "Word": 9821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féi",
      "PrimaryDefinition": "name of an affluent of the Poyang Lake"
    },
    "Keys": {
      "Hanzi": 4976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4976,
      "Radical": "水",
      "FrequencyRank": 5083,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "工作量": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzuòliàng",
      "PrimaryDefinition": "workload, volume of work"
    },
    "Keys": {
      "Word": 6757
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "pass by, approach; direct"
    },
    "Keys": {
      "Hanzi": 6745
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6745,
      "Radical": "辵",
      "FrequencyRank": 4495,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "妥善": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuǒshàn",
      "PrimaryDefinition": "appropriate, proper"
    },
    "Keys": {
      "Word": 9623
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēn",
      "PrimaryDefinition": "adze"
    },
    "Keys": {
      "Hanzi": 5483
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5483,
      "Radical": "金",
      "FrequencyRank": 5806,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "水落石出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐluò-shíchū",
      "PrimaryDefinition": "as the water recedes, the rocks appear (idiom), the truth comes to light"
    },
    "Keys": {
      "Word": 9265
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "戥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "děng",
      "PrimaryDefinition": "a small steelyard for weighing money, etc."
    },
    "Keys": {
      "Hanzi": 5446
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5446,
      "Radical": "戈",
      "FrequencyRank": 5977,
      "RadicalIndex": "62.9",
      "StrokeCount": 13
    }
  },
  "脸颊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎnjiá",
      "PrimaryDefinition": "cheeks"
    },
    "Keys": {
      "Word": 8028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "塞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sāi",
      "PrimaryDefinition": "stop up, block, seal, cork; pass, frontier; fortress"
    },
    "Keys": {
      "Hanzi": 3025,
      "Word": 4983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.1",
      "Grade": 6,
      "GeneralStandard": 3025,
      "Radical": "土",
      "HSK": 6,
      "FrequencyRank": 1080,
      "StrokeCount": 13
    }
  },
  "戎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róng",
      "PrimaryDefinition": "arms, armaments; military affair"
    },
    "Keys": {
      "Hanzi": 352
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 352,
      "Radical": "戈",
      "FrequencyRank": 3205,
      "RadicalIndex": "62.2",
      "StrokeCount": 6
    }
  },
  "搭建": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dājiàn",
      "PrimaryDefinition": "to build (esp. with simple materials), to knock together (a temporary shed), to rig up"
    },
    "Keys": {
      "Word": 6095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàtǐ",
      "PrimaryDefinition": "in general, more or less, in rough terms, basically, on the whole, overall situation, the big picture, (Tw) remains (of a dead person)"
    },
    "Keys": {
      "Word": 6133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "粕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pò",
      "PrimaryDefinition": "lees, dregs, sediments left after distilling liquor"
    },
    "Keys": {
      "Hanzi": 4954
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4954,
      "Radical": "米",
      "FrequencyRank": 4655,
      "RadicalIndex": "119.5",
      "StrokeCount": 11
    }
  },
  "园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "garden; park, orchard"
    },
    "Keys": {
      "Hanzi": 726,
      "Word": 5286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.4",
      "Grade": 6,
      "GeneralStandard": 726,
      "Radical": "囗",
      "HSK": 2,
      "FrequencyRank": 988,
      "StrokeCount": 7
    }
  },
  "灿烂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cànlàn",
      "PrimaryDefinition": "brilliant, glitter, splendid, resplend, resplendent, magnificent"
    },
    "Keys": {
      "Word": 5766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "考试": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎoshì",
      "PrimaryDefinition": "examination, test"
    },
    "Keys": {
      "Word": 194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "旃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "silk banner with bent pole"
    },
    "Keys": {
      "Hanzi": 4628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4628,
      "Radical": "方",
      "FrequencyRank": 5181,
      "RadicalIndex": "70.6",
      "StrokeCount": 10
    }
  },
  "作战": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò zhàn",
      "PrimaryDefinition": "do battle, war, fight, battle, conduct operations"
    },
    "Keys": {
      "Word": 5421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鞭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biān",
      "PrimaryDefinition": "whip; whip; string of firecrackers"
    },
    "Keys": {
      "Hanzi": 3438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "177.9",
      "GeneralStandard": 3438,
      "Radical": "革",
      "HSK": 9,
      "FrequencyRank": 2250,
      "StrokeCount": 18
    }
  },
  "秦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.5",
      "GeneralStandard": 1772,
      "Radical": "禾",
      "HSK": 9,
      "FrequencyRank": 1394,
      "StrokeCount": 10
    }
  },
  "颤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàn",
      "PrimaryDefinition": "shiver, tremble; trembling"
    },
    "Keys": {
      "Hanzi": 3465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "181.13",
      "GeneralStandard": 3465,
      "Radical": "頁",
      "HSK": 8,
      "FrequencyRank": 1900,
      "StrokeCount": 19
    }
  },
  "手册": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒucè",
      "PrimaryDefinition": "companion, enchiridion, pamphlet, guidebook, notebook, vade_mecum, leaflet, handbook, booklet, brochure, folder, manual, vade mecum, workbook, guide"
    },
    "Keys": {
      "Word": 9173
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guàn",
      "PrimaryDefinition": "crane, grus japonensis; stork; Ciconia species (various)"
    },
    "Keys": {
      "Hanzi": 6467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6467,
      "Radical": "鳥",
      "FrequencyRank": 3957,
      "RadicalIndex": "196.17",
      "StrokeCount": 22
    }
  },
  "迮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "to rise; contracted; cramped"
    },
    "Keys": {
      "Hanzi": 3946
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3946,
      "Radical": "辵",
      "FrequencyRank": 6576,
      "RadicalIndex": "162.5",
      "StrokeCount": 8
    }
  },
  "失眠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shī mián",
      "PrimaryDefinition": "to suffer from insomnia"
    },
    "Keys": {
      "Word": 9086
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "绝望": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué wàng",
      "PrimaryDefinition": "give up all hope, despair"
    },
    "Keys": {
      "Word": 3642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "隃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "to exceed; a state in Shanxi province"
    },
    "Keys": {
      "Hanzi": 7261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7261,
      "Radical": "阜",
      "FrequencyRank": 7256,
      "RadicalIndex": "170.9",
      "StrokeCount": 11
    }
  },
  "鹋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáo",
      "PrimaryDefinition": "emu"
    },
    "Keys": {
      "Hanzi": 5374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5374,
      "Radical": "鳥",
      "FrequencyRank": 6324,
      "RadicalIndex": "196.9",
      "StrokeCount": 13
    }
  },
  "报": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bào",
      "PrimaryDefinition": "report, tell, announce"
    },
    "Keys": {
      "Hanzi": 656,
      "Word": 5529
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 7,
      "GeneralStandard": 656,
      "Radical": "手",
      "HSK": 2,
      "FrequencyRank": 234,
      "StrokeCount": 7
    }
  },
  "伤势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngshì",
      "PrimaryDefinition": "condition of an injury"
    },
    "Keys": {
      "Word": 8964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "总之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngzhī",
      "PrimaryDefinition": "in a word, tout ensemble, on the whole, all in all, altogether, in short"
    },
    "Keys": {
      "Word": 3214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "职业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíyè",
      "PrimaryDefinition": "metier, vocation, iceman, mystery, engagement, work, walk of life, employment, job, occupational group, ploy, craft, trade, avocation, employ, racket, pursuit, profession, situation, line, occupation, position, calling, walk_of_life, career, billet, duty, living, shop"
    },
    "Keys": {
      "Word": 2165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "好笑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoxiào",
      "PrimaryDefinition": "laughable, funny"
    },
    "Keys": {
      "Word": 7010
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "萎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "wither, wilt"
    },
    "Keys": {
      "Hanzi": 2206
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2206,
      "Radical": "艸",
      "HSK": 9,
      "FrequencyRank": 2757,
      "StrokeCount": 11
    }
  },
  "出面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū miàn",
      "PrimaryDefinition": "act in one's own capacity or on behalf o, act in one's own capacity or on behalf of an organization, act in one's own capacity or on behalf of an organ, appear personally"
    },
    "Keys": {
      "Word": 4428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "打扮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎban",
      "PrimaryDefinition": "dress/make up, deck out, pose as"
    },
    "Keys": {
      "Word": 3373
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "佃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "tenant farmer; be a tenant farmer"
    },
    "Keys": {
      "Hanzi": 776
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 776,
      "Radical": "人",
      "FrequencyRank": 3245,
      "RadicalIndex": "9.5",
      "StrokeCount": 7
    }
  },
  "杭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háng",
      "PrimaryDefinition": "cross stream; navigate"
    },
    "Keys": {
      "Hanzi": 1030
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.4",
      "GeneralStandard": 1030,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2346,
      "StrokeCount": 8
    }
  },
  "众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòng",
      "PrimaryDefinition": "multitude, crowd; masses, public"
    },
    "Keys": {
      "Hanzi": 491
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.4",
      "GeneralStandard": 491,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 510,
      "StrokeCount": 6
    }
  },
  "劣质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lièzhì",
      "PrimaryDefinition": "shoddy, of poor quality"
    },
    "Keys": {
      "Word": 8052
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "progeny, descendants, posterity"
    },
    "Keys": {
      "Hanzi": 5548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.7",
      "GeneralStandard": 5548,
      "Radical": "衣",
      "HSK": 8,
      "FrequencyRank": 2842,
      "StrokeCount": 13
    }
  },
  "使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "cause, send on a mission, order; envoy, messenger, ambassador"
    },
    "Keys": {
      "Hanzi": 1139,
      "Word": 1906
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "Grade": 3,
      "GeneralStandard": 1139,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 119,
      "StrokeCount": 8
    }
  },
  "此前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐqián",
      "PrimaryDefinition": "before this, before then, previously"
    },
    "Keys": {
      "Word": 4453
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "咨询": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīxún",
      "PrimaryDefinition": "to consult, to seek advice, consultation, (sales) inquiry (formal)"
    },
    "Keys": {
      "Word": 5393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "着迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zháo mí",
      "PrimaryDefinition": "to be fascinated, to be captivated"
    },
    "Keys": {
      "Word": 10671
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "编制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biānzhì",
      "PrimaryDefinition": "authorized staff/force"
    },
    "Keys": {
      "Word": 4330
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "鸿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "species of wild swan; vast"
    },
    "Keys": {
      "Hanzi": 2412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2412,
      "Radical": "鳥",
      "FrequencyRank": 1924,
      "RadicalIndex": "196.6",
      "StrokeCount": 11
    }
  },
  "违反": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéifǎn",
      "PrimaryDefinition": "outrage, depart, offend, violate, go_against, deviate, infringe, diverge, vary, go against, violation, contravene, transgress, infringement, infract, breach, break, run counter to"
    },
    "Keys": {
      "Word": 4001
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "愿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "sincere, honest, virtuous"
    },
    "Keys": {
      "Hanzi": 3094,
      "Word": 4159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "Grade": 5,
      "GeneralStandard": 3094,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 598,
      "StrokeCount": 14
    }
  },
  "舴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4917,
      "Radical": "舟",
      "FrequencyRank": 6309,
      "RadicalIndex": "137.5",
      "StrokeCount": 11
    }
  },
  "兔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tù",
      "PrimaryDefinition": "rabbit, hare"
    },
    "Keys": {
      "Hanzi": 1195,
      "Word": 3990
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.6",
      "Grade": 5,
      "GeneralStandard": 1195,
      "Radical": "儿",
      "HSK": 5,
      "FrequencyRank": 2364,
      "StrokeCount": 8
    }
  },
  "霍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huò",
      "PrimaryDefinition": "quickly, suddenly; surname"
    },
    "Keys": {
      "Hanzi": 3337
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "173.8",
      "GeneralStandard": 3337,
      "Radical": "雨",
      "HSK": 9,
      "FrequencyRank": 1442,
      "StrokeCount": 16
    }
  },
  "养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎng",
      "PrimaryDefinition": "raise, rear, bring up; support"
    },
    "Keys": {
      "Hanzi": 1641,
      "Word": 1125
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.7",
      "Grade": 2,
      "GeneralStandard": 1641,
      "Radical": "八",
      "HSK": 2,
      "FrequencyRank": 760,
      "StrokeCount": 9
    }
  },
  "贫困": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínkùn",
      "PrimaryDefinition": "impoverished, poverty"
    },
    "Keys": {
      "Word": 4903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "猄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7217,
      "Radical": "犬",
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "悝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuī",
      "PrimaryDefinition": "to laugh at to pity; afflicted, sad"
    },
    "Keys": {
      "Hanzi": 4661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4661,
      "Radical": "心",
      "FrequencyRank": 6177,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "粽子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zòngzi",
      "PrimaryDefinition": "glutinous rice and choice of filling wrapped in leaves and boiled"
    },
    "Keys": {
      "Word": 10991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "快餐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàicān",
      "PrimaryDefinition": "fast food, snack, quick meal"
    },
    "Keys": {
      "Word": 821
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "知觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhījué",
      "PrimaryDefinition": "perception, consciousness"
    },
    "Keys": {
      "Word": 10747
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "此后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐhòu",
      "PrimaryDefinition": "thenceforth, henceforth, since then, thereafter, after this, hereafter"
    },
    "Keys": {
      "Word": 3359
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "赣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàn",
      "PrimaryDefinition": "Jiangxi province; places therein"
    },
    "Keys": {
      "Hanzi": 3495
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3495,
      "Radical": "貝",
      "FrequencyRank": 3301,
      "RadicalIndex": "154.17",
      "StrokeCount": 21
    }
  },
  "奁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3687
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3687,
      "Radical": "大",
      "FrequencyRank": 5517,
      "RadicalIndex": "37.4",
      "StrokeCount": 7
    }
  },
  "慨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "sigh, regret; generous"
    },
    "Keys": {
      "Hanzi": 2778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2778,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 2208,
      "StrokeCount": 12
    }
  },
  "黑板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēibǎn",
      "PrimaryDefinition": "blackboard, CL:塊|块[kuai4],個|个[ge4]"
    },
    "Keys": {
      "Word": 718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "综上所述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zōngshàngsuǒshù",
      "PrimaryDefinition": "to summarize, a round-up, in summary ..."
    },
    "Keys": {
      "Word": 10981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "贺电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèdiàn",
      "PrimaryDefinition": "congratulatory telegram"
    },
    "Keys": {
      "Word": 7054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "劫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "take by force, coerce; disaster"
    },
    "Keys": {
      "Hanzi": 660,
      "Word": 7522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.5",
      "Grade": 7,
      "GeneralStandard": 660,
      "Radical": "力",
      "HSK": 7,
      "FrequencyRank": 1825,
      "StrokeCount": 7
    }
  },
  "批准": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pī zhǔn",
      "PrimaryDefinition": "to approve, to ratify"
    },
    "Keys": {
      "Word": 1789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "进去": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìn qu",
      "PrimaryDefinition": "enter, entry, go_in, get in, go in"
    },
    "Keys": {
      "Word": 181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "唱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàng",
      "PrimaryDefinition": "sing, chant, call; ditty, song"
    },
    "Keys": {
      "Hanzi": 2284,
      "Word": 44
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "Grade": 1,
      "GeneralStandard": 2284,
      "Radical": "口",
      "HSK": 1,
      "FrequencyRank": 1252,
      "StrokeCount": 11
    }
  },
  "息息相关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīxī-xiāngguān",
      "PrimaryDefinition": "be closely linked, be closely bound up"
    },
    "Keys": {
      "Word": 9818
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "jealousy; be jealous of"
    },
    "Keys": {
      "Hanzi": 3040
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.1",
      "GeneralStandard": 3040,
      "Radical": "女",
      "HSK": 7,
      "FrequencyRank": 2690,
      "StrokeCount": 13
    }
  },
  "捏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niē",
      "PrimaryDefinition": "pick with fingers; knead; mold"
    },
    "Keys": {
      "Hanzi": 1793,
      "Word": 8422
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1793,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2472,
      "StrokeCount": 10
    }
  },
  "诚意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngyì",
      "PrimaryDefinition": "candour, singleness, sincerity, good faith, candor"
    },
    "Keys": {
      "Word": 5878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngtú",
      "PrimaryDefinition": "purpose, service, help, use, value, mileage, avail"
    },
    "Keys": {
      "Word": 3111
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "瘥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chài",
      "PrimaryDefinition": "to recover from any disease; an epidemic"
    },
    "Keys": {
      "Hanzi": 7717
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7717,
      "Radical": "疒",
      "FrequencyRank": 6820,
      "RadicalIndex": "104.1",
      "StrokeCount": 14
    }
  },
  "发达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fādá",
      "PrimaryDefinition": "developed, flourishing"
    },
    "Keys": {
      "Word": 1430
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "琤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēng",
      "PrimaryDefinition": "jade of jade being; tinkle"
    },
    "Keys": {
      "Hanzi": 4409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4409,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 10
    }
  },
  "章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāng",
      "PrimaryDefinition": "composition; chapter, section"
    },
    "Keys": {
      "Hanzi": 2389,
      "Word": 5323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.6",
      "Grade": 6,
      "GeneralStandard": 2389,
      "Radical": "立",
      "HSK": 3,
      "FrequencyRank": 539,
      "StrokeCount": 11
    }
  },
  "抽屉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōuti",
      "PrimaryDefinition": "drawer"
    },
    "Keys": {
      "Word": 5931
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "卫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "guard, protect, defend"
    },
    "Keys": {
      "Hanzi": 65
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "5.2",
      "GeneralStandard": 65,
      "Radical": "乙",
      "HSK": 3,
      "FrequencyRank": 669,
      "StrokeCount": 3
    }
  },
  "胡思乱想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "húsī-luànxiǎng",
      "PrimaryDefinition": "to indulge in flights of fancy (idiom), to let one's imagination run wild"
    },
    "Keys": {
      "Word": 7116
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "举一反三": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔyī-fǎnsān",
      "PrimaryDefinition": "to raise one and infer three, to deduce many things from one case (idiom)"
    },
    "Keys": {
      "Word": 7693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "㙘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "used in naming a place"
    },
    "Keys": {
      "Hanzi": 7292
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7292,
      "Radical": "土",
      "RadicalIndex": "32.9",
      "StrokeCount": 12
    }
  },
  "出名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū míng",
      "PrimaryDefinition": "lend one's name, out, fame, become well-known, become famous, famous, use the name of, be famous, well-known"
    },
    "Keys": {
      "Word": 4431
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "奖金": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngjīn",
      "PrimaryDefinition": "pewter, gainings, money award, gratuity, bounty, stakes, subsidy, prix, premium, incentive, prize money, perquisite, purse, winnings, stake, prize, bonus, gratification"
    },
    "Keys": {
      "Word": 2612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "热水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèshuǐ",
      "PrimaryDefinition": "hot water"
    },
    "Keys": {
      "Word": 4967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "参观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānguān",
      "PrimaryDefinition": "visit, tour"
    },
    "Keys": {
      "Word": 543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "发现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāxiàn",
      "PrimaryDefinition": "find, discover"
    },
    "Keys": {
      "Word": 649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "琲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "necklace"
    },
    "Keys": {
      "Hanzi": 7284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7284,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "狰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "fierce-looking, ferocious"
    },
    "Keys": {
      "Hanzi": 1603
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1603,
      "Radical": "犬",
      "FrequencyRank": 4047,
      "RadicalIndex": "94.6",
      "StrokeCount": 9
    }
  },
  "蔀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "screen"
    },
    "Keys": {
      "Hanzi": 7487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7487,
      "Radical": "艸",
      "FrequencyRank": 6952,
      "RadicalIndex": "140.11",
      "StrokeCount": 13
    }
  },
  "尧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "a legendary ancient emperor-sage"
    },
    "Keys": {
      "Hanzi": 406
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 406,
      "Radical": "儿",
      "FrequencyRank": 3264,
      "RadicalIndex": "10.4",
      "StrokeCount": 6
    }
  },
  "初级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūjí",
      "PrimaryDefinition": "junior, primary"
    },
    "Keys": {
      "Word": 1361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "钢琴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngqín",
      "PrimaryDefinition": "forte-piano, piano, pianoforte"
    },
    "Keys": {
      "Word": 3481
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "带头人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàitóurén",
      "PrimaryDefinition": "Take the leader"
    },
    "Keys": {
      "Word": 6150
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "回归": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huíguī",
      "PrimaryDefinition": "reunification, return, regressive, regress"
    },
    "Keys": {
      "Word": 7210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "淡化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànhuà",
      "PrimaryDefinition": "desalinate"
    },
    "Keys": {
      "Word": 6169
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "地下": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìxia",
      "PrimaryDefinition": "subterranean, underground, secretly, subsurface, secret, secret activity"
    },
    "Keys": {
      "Word": 2409
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "阵营": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhènyíng",
      "PrimaryDefinition": "group of people, camp, faction, sides in a dispute"
    },
    "Keys": {
      "Word": 10706
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "英文": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Yīngwén",
      "PrimaryDefinition": "English, English (language), English language, English_language"
    },
    "Keys": {
      "Word": 1164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "功劳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngláo",
      "PrimaryDefinition": "contribution, meritorious service, credit"
    },
    "Keys": {
      "Word": 6788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuài",
      "PrimaryDefinition": "chopsticks"
    },
    "Keys": {
      "Hanzi": 2955
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.7",
      "GeneralStandard": 2955,
      "Radical": "竹",
      "HSK": 2,
      "FrequencyRank": 3110,
      "StrokeCount": 13
    }
  },
  "连续": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánxù",
      "PrimaryDefinition": "continuously, successively"
    },
    "Keys": {
      "Word": 1707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "奎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "stride of man; one of the twenty-eight lunar mansions"
    },
    "Keys": {
      "Hanzi": 4171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4171,
      "Radical": "大",
      "FrequencyRank": 2608,
      "RadicalIndex": "37.6",
      "StrokeCount": 9
    }
  },
  "百货": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎihuò",
      "PrimaryDefinition": "general merchandise"
    },
    "Keys": {
      "Word": 2237
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "旮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gā",
      "PrimaryDefinition": "nook, corner"
    },
    "Keys": {
      "Hanzi": 3615
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3615,
      "Radical": "日",
      "FrequencyRank": 5287,
      "RadicalIndex": "72.2",
      "StrokeCount": 6
    }
  },
  "缩影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suōyǐng",
      "PrimaryDefinition": "miniature version of sth, microcosm, epitome, (Tw) to microfilm"
    },
    "Keys": {
      "Word": 9377
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "弑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5220,
      "Radical": "弋",
      "FrequencyRank": 4763,
      "RadicalIndex": "56.9",
      "StrokeCount": 12
    }
  },
  "洑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "whirlpool, undercurrent"
    },
    "Keys": {
      "Hanzi": 6889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6889,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "交代": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāodài",
      "PrimaryDefinition": "hand over, explain, make clear, brief, tell, account for, justify oneself, confess"
    },
    "Keys": {
      "Word": 3611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "停电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíng diàn",
      "PrimaryDefinition": "power cut"
    },
    "Keys": {
      "Word": 9515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huì",
      "PrimaryDefinition": "conceal; shun; regard as taboo"
    },
    "Keys": {
      "Hanzi": 551
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.4",
      "GeneralStandard": 551,
      "Radical": "言",
      "HSK": 9,
      "FrequencyRank": 2912,
      "StrokeCount": 6
    }
  },
  "码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎ",
      "PrimaryDefinition": "number, numerals; yard; symbol"
    },
    "Keys": {
      "Hanzi": 1046,
      "Word": 8186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "112.3",
      "Grade": 7,
      "GeneralStandard": 1046,
      "Radical": "石",
      "HSK": 4,
      "FrequencyRank": 1345,
      "StrokeCount": 8
    }
  },
  "出人意料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūrényìliào",
      "PrimaryDefinition": "unexpected (idiom), surprising"
    },
    "Keys": {
      "Word": 5963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "时节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjié",
      "PrimaryDefinition": "season, time"
    },
    "Keys": {
      "Word": 5016
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "毫不犹豫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "háobù yóuyù",
      "PrimaryDefinition": "without the slightest hesitation"
    },
    "Keys": {
      "Word": 7000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "风云": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngyún",
      "PrimaryDefinition": "weather, unstable situation"
    },
    "Keys": {
      "Word": 6582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "alder"
    },
    "Keys": {
      "Hanzi": 4454
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4454,
      "Radical": "木",
      "FrequencyRank": 5984,
      "RadicalIndex": "75.6",
      "StrokeCount": 10
    }
  },
  "事务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìwù",
      "PrimaryDefinition": "pursuit, shebang, routine, affair, work, concern, general affairs, desk, business, matter, transaction"
    },
    "Keys": {
      "Word": 9138
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "貅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "brave, fierce, courageous"
    },
    "Keys": {
      "Hanzi": 5514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5514,
      "Radical": "豸",
      "FrequencyRank": 6673,
      "RadicalIndex": "153.6",
      "StrokeCount": 13
    }
  },
  "鼐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nài",
      "PrimaryDefinition": "incense tripod"
    },
    "Keys": {
      "Hanzi": 5837
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5837,
      "Radical": "鼎",
      "FrequencyRank": 4861,
      "RadicalIndex": "206.2",
      "StrokeCount": 14
    }
  },
  "豆制品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòuzhìpǐn",
      "PrimaryDefinition": "Bean products"
    },
    "Keys": {
      "Word": 3432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "惨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǎn",
      "PrimaryDefinition": "sad, pitiful, wretched; cruel"
    },
    "Keys": {
      "Hanzi": 2449,
      "Word": 4372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "Grade": 6,
      "GeneralStandard": 2449,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1530,
      "StrokeCount": 11
    }
  },
  "迫使": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòshǐ",
      "PrimaryDefinition": "distress, obligate, intrude, exact, haze, enforce, feeze, pressure, tie_down, force, compel, enjoin, flog, insist, condemn, oblige, thrust, impel, necessitate, drive, hurl, sell_up, nail_down, make, corner"
    },
    "Keys": {
      "Word": 8572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "幻觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huànjué",
      "PrimaryDefinition": "illusion, hallucination, figment of one's imagination"
    },
    "Keys": {
      "Word": 7171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pīng",
      "PrimaryDefinition": "to trust to; send a message"
    },
    "Keys": {
      "Hanzi": 6856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6856,
      "Radical": "人",
      "FrequencyRank": 6137,
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "沉甸甸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéndiàndiàn",
      "PrimaryDefinition": "heavy"
    },
    "Keys": {
      "Word": 5849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "之": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "marks preceding phrase as modifier of following phrase; it, him her, them; go to"
    },
    "Keys": {
      "Hanzi": 58,
      "Word": 10739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "4.3",
      "Grade": 7,
      "GeneralStandard": 58,
      "Radical": "丿",
      "HSK": 4,
      "FrequencyRank": 44,
      "StrokeCount": 3
    }
  },
  "杖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "cane, walking stick"
    },
    "Keys": {
      "Hanzi": 683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "GeneralStandard": 683,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2435,
      "StrokeCount": 7
    }
  },
  "呛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "choke by smoke; irritates nose"
    },
    "Keys": {
      "Hanzi": 742,
      "Word": 8685
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 7,
      "GeneralStandard": 742,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 3418,
      "StrokeCount": 7
    }
  },
  "悴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "suffer, become emaciated, haggard"
    },
    "Keys": {
      "Hanzi": 2447
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2447,
      "Radical": "心",
      "FrequencyRank": 3474,
      "RadicalIndex": "61.8",
      "StrokeCount": 11
    }
  },
  "出厂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū chǎng",
      "PrimaryDefinition": "to leave the factory (of finished goods)"
    },
    "Keys": {
      "Word": 5951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "用心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòng xīn",
      "PrimaryDefinition": "be diligent/attentive"
    },
    "Keys": {
      "Word": 5268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "瘐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔ",
      "PrimaryDefinition": "to die in prison from cold and hunger; to treat with cruelty"
    },
    "Keys": {
      "Hanzi": 5544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5544,
      "Radical": "疒",
      "FrequencyRank": 6763,
      "RadicalIndex": "104.9",
      "StrokeCount": 13
    }
  },
  "一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī",
      "PrimaryDefinition": "one"
    },
    "Keys": {
      "Hanzi": 1,
      "Radical": 1,
      "Word": 427
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "1",
      "Grade": 1,
      "GeneralStandard": 1,
      "Radical": "一",
      "HSK": 1,
      "FrequencyRank": 2,
      "StrokeCount": 1
    }
  },
  "握手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wò shǒu",
      "PrimaryDefinition": "to shake hands"
    },
    "Keys": {
      "Word": 2017
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "趼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "callous skin on hands or feet. blisters"
    },
    "Keys": {
      "Hanzi": 7162
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7162,
      "Radical": "足",
      "FrequencyRank": 6206,
      "RadicalIndex": "157.6",
      "StrokeCount": 11
    }
  },
  "荓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6792
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6792,
      "Radical": "艸",
      "RadicalIndex": "140.8",
      "StrokeCount": 9
    }
  },
  "纪念日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìniànrì",
      "PrimaryDefinition": "day of commemoration, memorial day"
    },
    "Keys": {
      "Word": 7325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4069,
      "Radical": "子",
      "FrequencyRank": 6085,
      "RadicalIndex": "39.5",
      "StrokeCount": 8
    }
  },
  "鹉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "species of parrot"
    },
    "Keys": {
      "Hanzi": 2826
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2826,
      "Radical": "鳥",
      "FrequencyRank": 3610,
      "RadicalIndex": "196.8",
      "StrokeCount": 13
    }
  },
  "鼋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "large turtle, sea turtle"
    },
    "Keys": {
      "Hanzi": 5058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5058,
      "Radical": "龜",
      "FrequencyRank": 5232,
      "RadicalIndex": "213.4",
      "StrokeCount": 12
    }
  },
  "蝇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yíng",
      "PrimaryDefinition": "flies"
    },
    "Keys": {
      "Hanzi": 3107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.8",
      "GeneralStandard": 3107,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2722,
      "StrokeCount": 14
    }
  },
  "瓴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "concave channels of tiling a long-necked jar"
    },
    "Keys": {
      "Hanzi": 4278
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4278,
      "Radical": "瓦",
      "FrequencyRank": 5714,
      "RadicalIndex": "98.5",
      "StrokeCount": 9
    }
  },
  "铪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hā",
      "PrimaryDefinition": "hafnium"
    },
    "Keys": {
      "Hanzi": 4878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4878,
      "Radical": "金",
      "FrequencyRank": 6874,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "优先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yōuxiān",
      "PrimaryDefinition": "preferential, preferred, priority"
    },
    "Keys": {
      "Word": 4133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "郧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "county in Hubei province"
    },
    "Keys": {
      "Hanzi": 4217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4217,
      "Radical": "邑",
      "FrequencyRank": 4805,
      "RadicalIndex": "163.7",
      "StrokeCount": 9
    }
  },
  "弹性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tánxìng",
      "PrimaryDefinition": "elasticity"
    },
    "Keys": {
      "Word": 9406
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "总": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒng",
      "PrimaryDefinition": "collect; overall, altogether"
    },
    "Keys": {
      "Hanzi": 1654,
      "Word": 2216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.7",
      "Grade": 3,
      "GeneralStandard": 1654,
      "Radical": "八",
      "HSK": 3,
      "FrequencyRank": 228,
      "StrokeCount": 9
    }
  },
  "现": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàn",
      "PrimaryDefinition": "appear, manifest, become visible"
    },
    "Keys": {
      "Hanzi": 949
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.4",
      "GeneralStandard": 949,
      "Radical": "玉",
      "HSK": 1,
      "FrequencyRank": 70,
      "StrokeCount": 8
    }
  },
  "大学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàxué",
      "PrimaryDefinition": "university, college"
    },
    "Keys": {
      "Word": 61
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "昨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuó",
      "PrimaryDefinition": "yesterday; in former times, past"
    },
    "Keys": {
      "Hanzi": 1489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "GeneralStandard": 1489,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 1475,
      "StrokeCount": 9
    }
  },
  "纸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "paper"
    },
    "Keys": {
      "Hanzi": 938,
      "Word": 1220
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "Grade": 2,
      "GeneralStandard": 938,
      "Radical": "糸",
      "HSK": 2,
      "FrequencyRank": 1020,
      "StrokeCount": 7
    }
  },
  "独": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "alone, single, solitary, only"
    },
    "Keys": {
      "Hanzi": 1602,
      "Word": 6356
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "94.6",
      "Grade": 7,
      "GeneralStandard": 1602,
      "Radical": "犬",
      "HSK": 4,
      "FrequencyRank": 627,
      "StrokeCount": 9
    }
  },
  "娱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "pleasure, enjoyment, amusement"
    },
    "Keys": {
      "Hanzi": 2140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.7",
      "GeneralStandard": 2140,
      "Radical": "女",
      "HSK": 6,
      "FrequencyRank": 2493,
      "StrokeCount": 10
    }
  },
  "文雅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wényǎ",
      "PrimaryDefinition": "elegant, refined"
    },
    "Keys": {
      "Word": 9739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎn",
      "PrimaryDefinition": "reprimand, scold, abuse"
    },
    "Keys": {
      "Hanzi": 3307
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.13",
      "GeneralStandard": 3307,
      "Radical": "言",
      "HSK": 8,
      "FrequencyRank": 2527,
      "StrokeCount": 15
    }
  },
  "媖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "beauty; beautiful"
    },
    "Keys": {
      "Hanzi": 7265
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7265,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 11
    }
  },
  "逼近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bījìn",
      "PrimaryDefinition": "press on towards, approach"
    },
    "Keys": {
      "Word": 5576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "照片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàopiàn",
      "PrimaryDefinition": "snapshot, shot, likeness, picture, photo, icon, image, caption, exposure, photograph, pic, ikon"
    },
    "Keys": {
      "Word": 1206
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "爰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "lead on to; therefore, then"
    },
    "Keys": {
      "Hanzi": 4276
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4276,
      "Radical": "爪",
      "FrequencyRank": 4896,
      "RadicalIndex": "87.5",
      "StrokeCount": 9
    }
  },
  "价钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàqian",
      "PrimaryDefinition": "cost, monetary value, rate, price"
    },
    "Keys": {
      "Word": 1592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "紧凑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐncòu",
      "PrimaryDefinition": "compact, terse, tight (schedule)"
    },
    "Keys": {
      "Word": 7570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huí",
      "PrimaryDefinition": "tapeworm"
    },
    "Keys": {
      "Hanzi": 5147
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5147,
      "Radical": "虫",
      "FrequencyRank": 4032,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "爪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎo",
      "PrimaryDefinition": "claw"
    },
    "Keys": {
      "Radical": 87,
      "Hanzi": 138
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "87",
      "GeneralStandard": 138,
      "Radical": "爪",
      "HSK": 8,
      "FrequencyRank": 2363,
      "StrokeCount": 4
    }
  },
  "抚摸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔmō",
      "PrimaryDefinition": "to gently caress and stroke, to pet, to fondle"
    },
    "Keys": {
      "Word": 6610
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hái",
      "PrimaryDefinition": "baby, child; children"
    },
    "Keys": {
      "Hanzi": 1730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "39.6",
      "GeneralStandard": 1730,
      "Radical": "子",
      "HSK": 1,
      "FrequencyRank": 533,
      "StrokeCount": 9
    }
  },
  "廉正": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánzhèng",
      "PrimaryDefinition": "integrity"
    },
    "Keys": {
      "Word": 8024
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "注定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhùdìng",
      "PrimaryDefinition": "to foreordain, to be bound to, to be destined to, to be doomed to, inevitably"
    },
    "Keys": {
      "Word": 10872
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "烯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "alkene"
    },
    "Keys": {
      "Hanzi": 4957
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4957,
      "Radical": "火",
      "FrequencyRank": 4277,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "规模": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīmó",
      "PrimaryDefinition": "magnitude, dimensions, extent, scale, scope"
    },
    "Keys": {
      "Word": 2529
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "售货员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòuhuòyuán",
      "PrimaryDefinition": "salesperson, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2883
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "关上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānshàng",
      "PrimaryDefinition": "to close (a door), to turn off (light, electrical equipment etc)"
    },
    "Keys": {
      "Word": 124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "焘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "shine, illuminate; cover, envelope"
    },
    "Keys": {
      "Hanzi": 4711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4711,
      "Radical": "火",
      "FrequencyRank": 3213,
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "珍藏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēncáng",
      "PrimaryDefinition": "collection, to collect (valuables)"
    },
    "Keys": {
      "Word": 10695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "荔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "lichee"
    },
    "Keys": {
      "Hanzi": 1421
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1421,
      "Radical": "艸",
      "FrequencyRank": 3848,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "茀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "overgrown with grass, weedy"
    },
    "Keys": {
      "Hanzi": 3872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3872,
      "Radical": "艸",
      "FrequencyRank": 6476,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "职员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíyuán",
      "PrimaryDefinition": "employee, servant, official, staff member, retainer, functionary, operative, office employees, officeholder, personnel, officer, staff_member, office worker, clerk, staffer"
    },
    "Keys": {
      "Word": 10768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "飞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēi",
      "PrimaryDefinition": "fly"
    },
    "Keys": {
      "Hanzi": 69,
      "Radical": 183,
      "Word": 104
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "5.2",
      "Grade": 1,
      "GeneralStandard": 69,
      "Radical": "乙",
      "HSK": 1,
      "FrequencyRank": 347,
      "StrokeCount": 3
    }
  },
  "安静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānjìng",
      "PrimaryDefinition": "lull, quieten, quiesce, dummy, quiet down, quiet, hush, pipe down, peaceful"
    },
    "Keys": {
      "Word": 500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "文艺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wényì",
      "PrimaryDefinition": "literature and art"
    },
    "Keys": {
      "Word": 4012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "冒险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào xiǎn",
      "PrimaryDefinition": "take risks/chances"
    },
    "Keys": {
      "Word": 8216
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "派出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàichū",
      "PrimaryDefinition": "send, dispatch"
    },
    "Keys": {
      "Word": 4896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "柩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "coffin which contains corpse"
    },
    "Keys": {
      "Hanzi": 4144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4144,
      "Radical": "木",
      "FrequencyRank": 4568,
      "RadicalIndex": "75.5",
      "StrokeCount": 9
    }
  },
  "粮食": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liángshi",
      "PrimaryDefinition": "victuals, victualage, provisions, nutrient, ration, sustentation, provision, provender, food, purveyance, sustenance, foodstuff, commissariat, cereals, bread, viands, grain"
    },
    "Keys": {
      "Word": 2701
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "有效": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuxiào",
      "PrimaryDefinition": "run, stand, valid, effective, efficacious, avail, make, hold"
    },
    "Keys": {
      "Word": 2117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "蜻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīng",
      "PrimaryDefinition": "dragonfly"
    },
    "Keys": {
      "Hanzi": 3105
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3105,
      "Radical": "虫",
      "FrequencyRank": 4044,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "尾声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěishēng",
      "PrimaryDefinition": "coda, epilogue, end"
    },
    "Keys": {
      "Word": 9704
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "是否": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìfǒu",
      "PrimaryDefinition": "whether or not, is it so or not"
    },
    "Keys": {
      "Word": 2871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "蟫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yín",
      "PrimaryDefinition": "silverfish"
    },
    "Keys": {
      "Hanzi": 7999
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7999,
      "Radical": "虫",
      "FrequencyRank": 6949,
      "RadicalIndex": "142.12",
      "StrokeCount": 18
    }
  },
  "海湾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiwān",
      "PrimaryDefinition": "(Persian) Gulf, bay, gulf (body of water)"
    },
    "Keys": {
      "Word": 4653
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "辟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "law, rule; open up, develop"
    },
    "Keys": {
      "Hanzi": 3037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "160.6",
      "GeneralStandard": 3037,
      "Radical": "辛",
      "HSK": 9,
      "FrequencyRank": 2140,
      "StrokeCount": 13
    }
  },
  "稌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tú",
      "PrimaryDefinition": "glutinous rice"
    },
    "Keys": {
      "Hanzi": 7386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7386,
      "Radical": "禾",
      "FrequencyRank": 8526,
      "RadicalIndex": "115.7",
      "StrokeCount": 12
    }
  },
  "愿意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuànyi",
      "PrimaryDefinition": "will, be willing, please, wish, like, care"
    },
    "Keys": {
      "Word": 1184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "该": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāi",
      "PrimaryDefinition": "should, ought to, need to"
    },
    "Keys": {
      "Hanzi": 1300,
      "Word": 6649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "Grade": 7,
      "GeneralStandard": 1300,
      "Radical": "言",
      "HSK": 2,
      "FrequencyRank": 319,
      "StrokeCount": 8
    }
  },
  "梏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "handcuffs, manacles, fetters"
    },
    "Keys": {
      "Hanzi": 4772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4772,
      "Radical": "木",
      "FrequencyRank": 4665,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "循": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "obey, comply with, follow"
    },
    "Keys": {
      "Hanzi": 2705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "60.9",
      "GeneralStandard": 2705,
      "Radical": "彳",
      "HSK": 6,
      "FrequencyRank": 1744,
      "StrokeCount": 12
    }
  },
  "阄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "lots (to be drawn); draw lots"
    },
    "Keys": {
      "Hanzi": 4630
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4630,
      "Radical": "門",
      "FrequencyRank": 4798,
      "RadicalIndex": "169.7",
      "StrokeCount": 10
    }
  },
  "一律": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīlǜ",
      "PrimaryDefinition": "equally, without exception"
    },
    "Keys": {
      "Word": 3085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "智能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìnéng",
      "PrimaryDefinition": "intelligent, able, smart (phone, system, bomb etc)"
    },
    "Keys": {
      "Word": 3180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "毳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "fine hair or fur on animals"
    },
    "Keys": {
      "Hanzi": 5194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5194,
      "Radical": "毛",
      "FrequencyRank": 7796,
      "RadicalIndex": "82.8",
      "StrokeCount": 12
    }
  },
  "浓缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngsuō",
      "PrimaryDefinition": "to concentrate (a liquid), concentration, espresso coffee, abbr. for 意式濃縮咖啡|意式浓缩咖啡"
    },
    "Keys": {
      "Word": 8437
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duò",
      "PrimaryDefinition": "indolent, careless, lazy, idle"
    },
    "Keys": {
      "Hanzi": 2772
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2772,
      "Radical": "心",
      "HSK": 8,
      "FrequencyRank": 3310,
      "StrokeCount": 12
    }
  },
  "段": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duàn",
      "PrimaryDefinition": "section, piece, division"
    },
    "Keys": {
      "Hanzi": 1555,
      "Word": 635
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "79.5",
      "Grade": 2,
      "GeneralStandard": 1555,
      "Radical": "殳",
      "HSK": 2,
      "FrequencyRank": 567,
      "StrokeCount": 9
    }
  },
  "盒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "small box or case; casket"
    },
    "Keys": {
      "Hanzi": 2348,
      "Word": 3539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "108.6",
      "Grade": 5,
      "GeneralStandard": 2348,
      "Radical": "皿",
      "HSK": 5,
      "FrequencyRank": 2067,
      "StrokeCount": 11
    }
  },
  "荭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "herb"
    },
    "Keys": {
      "Hanzi": 6796
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6796,
      "Radical": "艸",
      "FrequencyRank": 4500,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "与众不同": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔzhòng-bùtóng",
      "PrimaryDefinition": "to stand out from the masses (idiom)"
    },
    "Keys": {
      "Word": 10505
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畅谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chàngtán",
      "PrimaryDefinition": "to chat, a long talk, verbose, to talk freely to one's heart's content"
    },
    "Keys": {
      "Word": 5822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "灵通": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "língtōng",
      "PrimaryDefinition": "fast and abundant (news), clever, effective"
    },
    "Keys": {
      "Word": 8076
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "抢眼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎngyǎn",
      "PrimaryDefinition": "eye-catching"
    },
    "Keys": {
      "Word": 8697
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "芝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.4",
      "GeneralStandard": 374,
      "Radical": "艸",
      "HSK": 8,
      "FrequencyRank": 2150,
      "StrokeCount": 6
    }
  },
  "务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "affairs, business; must, should"
    },
    "Keys": {
      "Hanzi": 297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "34.2",
      "GeneralStandard": 297,
      "Radical": "夂",
      "HSK": 2,
      "FrequencyRank": 245,
      "StrokeCount": 5
    }
  },
  "肘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒu",
      "PrimaryDefinition": "the elbow; help a person shoulder a load"
    },
    "Keys": {
      "Hanzi": 806
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 806,
      "Radical": "肉",
      "FrequencyRank": 3010,
      "RadicalIndex": "130.3",
      "StrokeCount": 7
    }
  },
  "效应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoyìng",
      "PrimaryDefinition": "effect"
    },
    "Keys": {
      "Word": 9983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "筱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5504
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5504,
      "Radical": "竹",
      "FrequencyRank": 4026,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "看重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kàn zhòng",
      "PrimaryDefinition": "to regard as important, to care about"
    },
    "Keys": {
      "Word": 7777
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "羞愧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūkuì",
      "PrimaryDefinition": "ashamed"
    },
    "Keys": {
      "Word": 10079
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外卖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàimài",
      "PrimaryDefinition": "to go, take out (relates to food bought at a restaurant)"
    },
    "Keys": {
      "Word": 1047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "原理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánlǐ",
      "PrimaryDefinition": "principle, tenet"
    },
    "Keys": {
      "Word": 4152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "索取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suǒqǔ",
      "PrimaryDefinition": "scrounge, extort, subpoena, obtain, charge, demand, exact, ask for"
    },
    "Keys": {
      "Word": 9385
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "副": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "assist; supplement; assistant"
    },
    "Keys": {
      "Hanzi": 2234,
      "Word": 4576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.9",
      "Grade": 6,
      "GeneralStandard": 2234,
      "Radical": "刀",
      "HSK": 6,
      "FrequencyRank": 764,
      "StrokeCount": 11
    }
  },
  "破坏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòhuài",
      "PrimaryDefinition": "violate, destroy"
    },
    "Keys": {
      "Word": 1796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "感想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnxiǎng",
      "PrimaryDefinition": "sentiment, reflections, feeling, thoughts, impressions, impression"
    },
    "Keys": {
      "Word": 3478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "魔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mó",
      "PrimaryDefinition": "demon, evil spirits; magic power"
    },
    "Keys": {
      "Hanzi": 3484
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "194.11",
      "GeneralStandard": 3484,
      "Radical": "鬼",
      "HSK": 7,
      "FrequencyRank": 1180,
      "StrokeCount": 20
    }
  },
  "疝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàn",
      "PrimaryDefinition": "hernia, rupture"
    },
    "Keys": {
      "Hanzi": 3998
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3998,
      "Radical": "疒",
      "FrequencyRank": 5387,
      "RadicalIndex": "104.3",
      "StrokeCount": 8
    }
  },
  "出席": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū xí",
      "PrimaryDefinition": "attend, be present"
    },
    "Keys": {
      "Word": 2335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "deer"
    },
    "Keys": {
      "Hanzi": 2387,
      "Radical": 198,
      "Word": 8130
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "198",
      "Grade": 7,
      "GeneralStandard": 2387,
      "Radical": "鹿",
      "HSK": 7,
      "FrequencyRank": 2056,
      "StrokeCount": 11
    }
  },
  "仿制": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎngzhì",
      "PrimaryDefinition": "to copy, to imitate, to make by imitating a model"
    },
    "Keys": {
      "Word": 6499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎ",
      "PrimaryDefinition": "(Cant.) to get wet by rain; to drip"
    },
    "Keys": {
      "Hanzi": 7426
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7426,
      "Radical": "水",
      "RadicalIndex": "85.1",
      "StrokeCount": 12
    }
  },
  "襕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lán",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7972,
      "Radical": "衣",
      "FrequencyRank": 7248,
      "RadicalIndex": "145.12",
      "StrokeCount": 17
    }
  },
  "螂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "mantis, dung beetle"
    },
    "Keys": {
      "Hanzi": 5713
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5713,
      "Radical": "虫",
      "FrequencyRank": 3594,
      "RadicalIndex": "142.1",
      "StrokeCount": 14
    }
  },
  "中旬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngxún",
      "PrimaryDefinition": "middle third of a month"
    },
    "Keys": {
      "Word": 10813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎn",
      "PrimaryDefinition": "kind of rapier"
    },
    "Keys": {
      "Hanzi": 5186
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5186,
      "Radical": "金",
      "FrequencyRank": 4273,
      "RadicalIndex": "167.7",
      "StrokeCount": 12
    }
  },
  "淬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "temper; dye; soak; change, alter"
    },
    "Keys": {
      "Hanzi": 4977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4977,
      "Radical": "水",
      "FrequencyRank": 4939,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "一再": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīzài",
      "PrimaryDefinition": "repeatedly, again and again, time and again, over_and_over"
    },
    "Keys": {
      "Word": 3089
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "目中无人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùzhōng-wúrén",
      "PrimaryDefinition": "to consider everyone else beneath one (idiom), so arrogant that no-one else matters, condescending, to go about with one's nose in the air"
    },
    "Keys": {
      "Word": 8351
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "砰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēng",
      "PrimaryDefinition": "sound of crashing stones, bang!"
    },
    "Keys": {
      "Hanzi": 1864
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1864,
      "Radical": "石",
      "FrequencyRank": 2869,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "萁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "kind of grasses"
    },
    "Keys": {
      "Hanzi": 4747
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4747,
      "Radical": "艸",
      "FrequencyRank": 6321,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "公": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōng",
      "PrimaryDefinition": "fair, equitable; public; duke"
    },
    "Keys": {
      "Hanzi": 148,
      "Word": 4604
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "12.2",
      "Grade": 6,
      "GeneralStandard": 148,
      "Radical": "八",
      "HSK": 2,
      "FrequencyRank": 115,
      "StrokeCount": 4
    }
  },
  "争夺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngduó",
      "PrimaryDefinition": "to fight over, to contest, to vie over"
    },
    "Keys": {
      "Word": 5338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "蜐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7523
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7523,
      "Radical": "虫",
      "FrequencyRank": 8538,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "木匠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùjiang",
      "PrimaryDefinition": "carpenter"
    },
    "Keys": {
      "Word": 8344
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清晰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngxī",
      "PrimaryDefinition": "distinct, clear (of sound/view)"
    },
    "Keys": {
      "Word": 8750
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "态": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tài",
      "PrimaryDefinition": "manner, bearing, attitude"
    },
    "Keys": {
      "Hanzi": 1052
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "GeneralStandard": 1052,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 528,
      "StrokeCount": 8
    }
  },
  "源": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "spring; source, head; surname"
    },
    "Keys": {
      "Hanzi": 3008
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3008,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 670,
      "StrokeCount": 13
    }
  },
  "行人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngrén",
      "PrimaryDefinition": "foot traffic, passerby, goer, pedestrian, pedestrian traffic, passenger"
    },
    "Keys": {
      "Word": 1113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "显得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnde",
      "PrimaryDefinition": "seem, look, appear"
    },
    "Keys": {
      "Word": 2030
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "峤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiào",
      "PrimaryDefinition": "high pointed mountain"
    },
    "Keys": {
      "Hanzi": 4235
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4235,
      "Radical": "山",
      "FrequencyRank": 5203,
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "能否": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "néngfǒu",
      "PrimaryDefinition": "can or not"
    },
    "Keys": {
      "Word": 4891
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "摆放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎifàng",
      "PrimaryDefinition": "to set up, to arrange, to lay out"
    },
    "Keys": {
      "Word": 5476
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "离不开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí bukāi",
      "PrimaryDefinition": "inseparable, inevitably linked to"
    },
    "Keys": {
      "Word": 2692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "贯穿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guànchuān",
      "PrimaryDefinition": "to run through, a connecting thread from beginning to end, to link"
    },
    "Keys": {
      "Word": 6888
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一会儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīhuìr",
      "PrimaryDefinition": "a moment, a while, in a moment, now...now..., also pr. [yi1 hui3 r5]"
    },
    "Keys": {
      "Word": 1137
    },
    "Types": [
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "总监": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngjiān",
      "PrimaryDefinition": "head, director (of an organizational unit), (police) commissioner, inspector-general, rank of local governor in Tang dynasty administration"
    },
    "Keys": {
      "Word": 5402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "式": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "style, system, formula, rule"
    },
    "Keys": {
      "Hanzi": 349,
      "Word": 3907
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "56.3",
      "Grade": 5,
      "GeneralStandard": 349,
      "Radical": "弋",
      "HSK": 3,
      "FrequencyRank": 303,
      "StrokeCount": 6
    }
  },
  "民族": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mínzú",
      "PrimaryDefinition": "tribe, citizenry, ethnos, ethnic minority/group, race, nationality, group, Volk, nation, folk, people, ethnic group, ethnographer, family, ethnic minority"
    },
    "Keys": {
      "Word": 1746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "神": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shén",
      "PrimaryDefinition": "spirit, god, supernatural being"
    },
    "Keys": {
      "Hanzi": 1711,
      "Word": 3884
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.5",
      "Grade": 5,
      "GeneralStandard": 1711,
      "Radical": "示",
      "HSK": 3,
      "FrequencyRank": 227,
      "StrokeCount": 9
    }
  },
  "祁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "pray; numerous, ample, abundant"
    },
    "Keys": {
      "Hanzi": 3633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3633,
      "Radical": "示",
      "FrequencyRank": 3477,
      "RadicalIndex": "113.3",
      "StrokeCount": 6
    }
  },
  "禺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "district; mountain in Zhejiang"
    },
    "Keys": {
      "Hanzi": 4195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4195,
      "Radical": "禸",
      "FrequencyRank": 3056,
      "RadicalIndex": "114.4",
      "StrokeCount": 9
    }
  },
  "讨价还价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǎojià-huánjià",
      "PrimaryDefinition": "to haggle over price"
    },
    "Keys": {
      "Word": 9442
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "tinkling of jade"
    },
    "Keys": {
      "Hanzi": 1359
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1359,
      "Radical": "玉",
      "FrequencyRank": 1855,
      "RadicalIndex": "96.5",
      "StrokeCount": 9
    }
  },
  "写照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiězhào",
      "PrimaryDefinition": "portrayal"
    },
    "Keys": {
      "Word": 9993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "周末": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōumò",
      "PrimaryDefinition": "weekend"
    },
    "Keys": {
      "Word": 1230
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "养老院": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnglǎoyuàn",
      "PrimaryDefinition": "nursing home"
    },
    "Keys": {
      "Word": 10222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杂乱无章": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "záluàn-wúzhāng",
      "PrimaryDefinition": "disordered and in a mess (idiom), all mixed up and chaotic"
    },
    "Keys": {
      "Word": 10582
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "场馆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngguǎn",
      "PrimaryDefinition": "sporting venue, arena"
    },
    "Keys": {
      "Word": 4396
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "畚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "běn",
      "PrimaryDefinition": "straw basket, hamper"
    },
    "Keys": {
      "Hanzi": 4697
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4697,
      "Radical": "田",
      "FrequencyRank": 5574,
      "RadicalIndex": "102.5",
      "StrokeCount": 10
    }
  },
  "没用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méiyòng",
      "PrimaryDefinition": "useless"
    },
    "Keys": {
      "Word": 1732
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "出版": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūbǎn",
      "PrimaryDefinition": "come off the press, publish, come out"
    },
    "Keys": {
      "Word": 3342
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "吝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìn",
      "PrimaryDefinition": "stingy, miserly, parsimonious"
    },
    "Keys": {
      "Hanzi": 835
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "GeneralStandard": 835,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 3507,
      "StrokeCount": 7
    }
  },
  "火辣辣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒlàlà",
      "PrimaryDefinition": "painful heat, scorching, painful heat, rude and forthright, provocative, hot, sexy"
    },
    "Keys": {
      "Word": 7244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiù",
      "PrimaryDefinition": "coarse variety of jasper or jade"
    },
    "Keys": {
      "Hanzi": 4714
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4714,
      "Radical": "玉",
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "犹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": "like, similar to, just like, as"
    },
    "Keys": {
      "Hanzi": 812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "94.4",
      "GeneralStandard": 812,
      "Radical": "犬",
      "HSK": 5,
      "FrequencyRank": 1230,
      "StrokeCount": 7
    }
  },
  "一干二净": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīgān-èrjìng",
      "PrimaryDefinition": "thoroughly (idiom), completely, one and all, very clean"
    },
    "Keys": {
      "Word": 10324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顶级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǐngjí",
      "PrimaryDefinition": "top-notch, first-rate"
    },
    "Keys": {
      "Word": 6303
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "说情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō qíng",
      "PrimaryDefinition": "to intercede, to plead for sb else"
    },
    "Keys": {
      "Word": 9299
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "憔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáo",
      "PrimaryDefinition": "be worn-out, emaciated, haggard"
    },
    "Keys": {
      "Hanzi": 3301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3301,
      "Radical": "心",
      "FrequencyRank": 3508,
      "RadicalIndex": "61.12",
      "StrokeCount": 15
    }
  },
  "打仗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎ zhàng",
      "PrimaryDefinition": "fight battle, outplay, war, make war, fight, wage war, battle, go to war"
    },
    "Keys": {
      "Word": 6108
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蠊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6393
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6393,
      "Radical": "虫",
      "FrequencyRank": 5352,
      "RadicalIndex": "142.13",
      "StrokeCount": 19
    }
  },
  "弆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6655,
      "Radical": "廾",
      "RadicalIndex": "55.5",
      "StrokeCount": 8
    }
  },
  "稂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "grass; weeds"
    },
    "Keys": {
      "Hanzi": 5204
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5204,
      "Radical": "禾",
      "FrequencyRank": 8008,
      "RadicalIndex": "115.7",
      "StrokeCount": 12
    }
  },
  "罪魁祸首": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìkuí-huòshǒu",
      "PrimaryDefinition": "criminal ringleader, main offender (idiom), main culprit, fig. main cause of a disaster"
    },
    "Keys": {
      "Word": 11018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "孤陋寡闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūlòu-guǎwén",
      "PrimaryDefinition": "ignorant and inexperienced, ill-informed and narrow-minded"
    },
    "Keys": {
      "Word": 6822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揭晓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiēxiǎo",
      "PrimaryDefinition": "to announce publicly, to publish, to make known, to disclose"
    },
    "Keys": {
      "Word": 7516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "朗诵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎngsòng",
      "PrimaryDefinition": "to read aloud with expression, to recite, to declaim"
    },
    "Keys": {
      "Word": 7935
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "猇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāo",
      "PrimaryDefinition": "the scream or roar of a tiger; to intimidate; to scare"
    },
    "Keys": {
      "Hanzi": 7215
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7215,
      "Radical": "犬",
      "RadicalIndex": "94.8",
      "StrokeCount": 11
    }
  },
  "楦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "a last for making shoes; to turn on a lathe"
    },
    "Keys": {
      "Hanzi": 5405
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5405,
      "Radical": "木",
      "FrequencyRank": 5692,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "纺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎng",
      "PrimaryDefinition": "spin, reel, weave; reeled pongee"
    },
    "Keys": {
      "Hanzi": 940
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 940,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2409,
      "StrokeCount": 7
    }
  },
  "浸泡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnpào",
      "PrimaryDefinition": "to steep, to soak, to immerse"
    },
    "Keys": {
      "Word": 7587
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "laugh heartily, laugh aloud"
    },
    "Keys": {
      "Hanzi": 6104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6104,
      "Radical": "口",
      "FrequencyRank": 5124,
      "RadicalIndex": "30.13",
      "StrokeCount": 16
    }
  },
  "滥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "làn",
      "PrimaryDefinition": "flood, overflow; excessive"
    },
    "Keys": {
      "Hanzi": 3010
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3010,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2282,
      "StrokeCount": 13
    }
  },
  "溯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "go upstream, go against current; formerly"
    },
    "Keys": {
      "Hanzi": 3017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3017,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2762,
      "StrokeCount": 13
    }
  },
  "畸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "odd, fractional, remainder, odds"
    },
    "Keys": {
      "Hanzi": 2912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.8",
      "GeneralStandard": 2912,
      "Radical": "田",
      "HSK": 8,
      "FrequencyRank": 3155,
      "StrokeCount": 13
    }
  },
  "溇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7435
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7435,
      "Radical": "水",
      "RadicalIndex": "85.11",
      "StrokeCount": 12
    }
  },
  "葸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐ",
      "PrimaryDefinition": "afraid, bashful"
    },
    "Keys": {
      "Hanzi": 5085
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5085,
      "Radical": "艸",
      "FrequencyRank": 6261,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "防火墙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fánghuǒqiáng",
      "PrimaryDefinition": "firewall, CL:堵[du3]"
    },
    "Keys": {
      "Word": 6490
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "其他": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qítā",
      "PrimaryDefinition": "det.: others, the rest, other, else"
    },
    "Keys": {
      "Word": 919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "专业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuānyè",
      "PrimaryDefinition": "professional"
    },
    "Keys": {
      "Word": 2201
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "拨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "move; dispel; distribute"
    },
    "Keys": {
      "Hanzi": 991,
      "Word": 5656
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 7,
      "GeneralStandard": 991,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1807,
      "StrokeCount": 8
    }
  },
  "拍照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāi zhào",
      "PrimaryDefinition": "take a picture, take (picture), shoot (film), snap, film, take a photograph, take, photograph, shoot"
    },
    "Keys": {
      "Word": 2768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "报酬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàochou",
      "PrimaryDefinition": "emolument, reward, fee, recompense, earnings, gratuity, requital, retribution, price, harvest, remuneration, quittance, pay, consideration, salary, compensation, honorarium, payment, meed, gratification, wage, guerdon"
    },
    "Keys": {
      "Word": 5532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "噗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pū",
      "PrimaryDefinition": "burst"
    },
    "Keys": {
      "Hanzi": 5929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5929,
      "Radical": "口",
      "FrequencyRank": 3465,
      "RadicalIndex": "30.12",
      "StrokeCount": 15
    }
  },
  "政府": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngfǔ",
      "PrimaryDefinition": "sirkar, state, country, nation, governmental, body politic, government, administration, civil order, polity, commonwealth, regime, res publica, land"
    },
    "Keys": {
      "Word": 3165
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "芬芳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnfāng",
      "PrimaryDefinition": "perfume, fragrant"
    },
    "Keys": {
      "Word": 6543
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "昼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòu",
      "PrimaryDefinition": "daytime, daylight"
    },
    "Keys": {
      "Hanzi": 1723
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.6",
      "GeneralStandard": 1723,
      "Radical": "尸",
      "HSK": 9,
      "FrequencyRank": 2651,
      "StrokeCount": 9
    }
  },
  "兴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "thrive, prosper, flourish"
    },
    "Keys": {
      "Hanzi": 544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "12.4",
      "GeneralStandard": 544,
      "Radical": "八",
      "HSK": 1,
      "FrequencyRank": 531,
      "StrokeCount": 6
    }
  },
  "稙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhī",
      "PrimaryDefinition": "grain ready for grinding"
    },
    "Keys": {
      "Hanzi": 7539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7539,
      "Radical": "禾",
      "FrequencyRank": 9421,
      "RadicalIndex": "115.8",
      "StrokeCount": 13
    }
  },
  "懔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐn",
      "PrimaryDefinition": "be afraid of, to be in awe of"
    },
    "Keys": {
      "Hanzi": 7911
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7911,
      "Radical": "心",
      "FrequencyRank": 4720,
      "RadicalIndex": "61.13",
      "StrokeCount": 16
    }
  },
  "桷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "rafter; malus toringo"
    },
    "Keys": {
      "Hanzi": 4775
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4775,
      "Radical": "木",
      "FrequencyRank": 6245,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "中毒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhòng dú",
      "PrimaryDefinition": "poison, taint, be poisoned"
    },
    "Keys": {
      "Word": 4245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "璪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎo",
      "PrimaryDefinition": "pearl pendants on coronet"
    },
    "Keys": {
      "Hanzi": 6198
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6198,
      "Radical": "玉",
      "RadicalIndex": "96.13",
      "StrokeCount": 17
    }
  },
  "关怀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānhuái",
      "PrimaryDefinition": "show loving care/concern for"
    },
    "Keys": {
      "Word": 3514
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "翁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēng",
      "PrimaryDefinition": "old man; father, father-in-law"
    },
    "Keys": {
      "Hanzi": 1992
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "124.4",
      "GeneralStandard": 1992,
      "Radical": "羽",
      "HSK": 7,
      "FrequencyRank": 2112,
      "StrokeCount": 10
    }
  },
  "鄜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fū",
      "PrimaryDefinition": "county in Shanxi province"
    },
    "Keys": {
      "Hanzi": 7576
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7576,
      "Radical": "邑",
      "FrequencyRank": 7602,
      "RadicalIndex": "163.11",
      "StrokeCount": 13
    }
  },
  "蟠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pán",
      "PrimaryDefinition": "coil; coiling, curling; occupy"
    },
    "Keys": {
      "Hanzi": 6337
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6337,
      "Radical": "虫",
      "FrequencyRank": 4274,
      "RadicalIndex": "142.12",
      "StrokeCount": 18
    }
  },
  "率": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "to lead; ratio; rate; limit"
    },
    "Keys": {
      "Hanzi": 2395,
      "Word": 8146
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "95.6",
      "Grade": 7,
      "GeneralStandard": 2395,
      "Radical": "玄",
      "HSK": 4,
      "FrequencyRank": 625,
      "StrokeCount": 11
    }
  },
  "厌烦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànfán",
      "PrimaryDefinition": "bored, fed up with sth, sick of sth"
    },
    "Keys": {
      "Word": 10207
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牙齿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáchǐ",
      "PrimaryDefinition": "ivory, grinder, snapper, chopper, masticator, dental, teeth, pearly, peg, dens, tooth"
    },
    "Keys": {
      "Word": 10162
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "亲爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnài",
      "PrimaryDefinition": "dear, beloved, darling"
    },
    "Keys": {
      "Word": 2804
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "关税": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānshuì",
      "PrimaryDefinition": "customs duty, tariff"
    },
    "Keys": {
      "Word": 6867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "唠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láo",
      "PrimaryDefinition": "chat, jaw, gossip, talk"
    },
    "Keys": {
      "Hanzi": 1891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1891,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 3399,
      "StrokeCount": 10
    }
  },
  "总共": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒnggòng",
      "PrimaryDefinition": "in all, all told, altogether"
    },
    "Keys": {
      "Word": 3211
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "convenience, ease; expedient"
    },
    "Keys": {
      "Hanzi": 1556,
      "Word": 4335
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "Grade": 6,
      "GeneralStandard": 1556,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 271,
      "StrokeCount": 9
    }
  },
  "床": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuáng",
      "PrimaryDefinition": "bed, couch; framework, chassis"
    },
    "Keys": {
      "Hanzi": 831,
      "Word": 51
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.4",
      "Grade": 1,
      "GeneralStandard": 831,
      "Radical": "广",
      "HSK": 1,
      "FrequencyRank": 1068,
      "StrokeCount": 7
    }
  },
  "蚕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cán",
      "PrimaryDefinition": "silkworms"
    },
    "Keys": {
      "Hanzi": 1777
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1777,
      "Radical": "虫",
      "FrequencyRank": 2787,
      "RadicalIndex": "142.4",
      "StrokeCount": 10
    }
  },
  "窈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎo",
      "PrimaryDefinition": "obscure, secluded; refined"
    },
    "Keys": {
      "Hanzi": 4666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4666,
      "Radical": "穴",
      "FrequencyRank": 4541,
      "RadicalIndex": "116.5",
      "StrokeCount": 10
    }
  },
  "枍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6666
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6666,
      "Radical": "木",
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "司机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sījī",
      "PrimaryDefinition": "driver"
    },
    "Keys": {
      "Word": 995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "渍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zì",
      "PrimaryDefinition": "soak, steep; dye; stains; sodden"
    },
    "Keys": {
      "Hanzi": 4963
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4963,
      "Radical": "水",
      "FrequencyRank": 3773,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "景观": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐngguān",
      "PrimaryDefinition": "view, perspective, aspect, scene, prospect, vista, panorama, landscape"
    },
    "Keys": {
      "Word": 7627
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遂心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suì xīn",
      "PrimaryDefinition": "to one's liking"
    },
    "Keys": {
      "Word": 9369
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "擦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cā",
      "PrimaryDefinition": "wipe, scrub, rub, scour; brush"
    },
    "Keys": {
      "Hanzi": 3389,
      "Word": 2285
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.14",
      "Grade": 4,
      "GeneralStandard": 3389,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1761,
      "StrokeCount": 17
    }
  },
  "竖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shù",
      "PrimaryDefinition": "perpendicular, vertical; erect"
    },
    "Keys": {
      "Hanzi": 1473,
      "Word": 9234
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.4",
      "Grade": 7,
      "GeneralStandard": 1473,
      "Radical": "立",
      "HSK": 8,
      "FrequencyRank": 2505,
      "StrokeCount": 9
    }
  },
  "纳税人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nàshuìrén",
      "PrimaryDefinition": "taxpayer"
    },
    "Keys": {
      "Word": 8367
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "珍惜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnxī",
      "PrimaryDefinition": "treasure, value, cherish, foster, appreciate, hold dear, prize, care for"
    },
    "Keys": {
      "Word": 4199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "婧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng",
      "PrimaryDefinition": "modest; supple"
    },
    "Keys": {
      "Hanzi": 5016
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5016,
      "Radical": "女",
      "FrequencyRank": 5759,
      "RadicalIndex": "38.8",
      "StrokeCount": 11
    }
  },
  "商人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngrén",
      "PrimaryDefinition": "merchandiser, trader, businessman, merchant, monger, trafficker, chapman, dealer, cit, merchantman, tradespeople, merchantry, bargainer, man of affairs"
    },
    "Keys": {
      "Word": 963
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "䶮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6814
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6814,
      "Radical": "",
      "FrequencyRank": 8753,
      "RadicalIndex": "",
      "StrokeCount": 20
    }
  },
  "工业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngyè",
      "PrimaryDefinition": "industrial, industry"
    },
    "Keys": {
      "Word": 1499
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "描绘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáohuì",
      "PrimaryDefinition": "depict, describe, portray"
    },
    "Keys": {
      "Word": 8290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "做法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòfa",
      "PrimaryDefinition": "way of doing sth., modus_operandi, practice, manner, method, making sth., way of making sth., shtick, way, way of doing, way of doing/making sth., personal manner"
    },
    "Keys": {
      "Word": 1258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "假装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎzhuāng",
      "PrimaryDefinition": "pretend, feign, simulate"
    },
    "Keys": {
      "Word": 7363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎi",
      "PrimaryDefinition": "death"
    },
    "Keys": {
      "Radical": 78,
      "Hanzi": 96
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "78",
      "GeneralStandard": 96,
      "Radical": "歹",
      "HSK": 8,
      "FrequencyRank": 2905,
      "StrokeCount": 4
    }
  },
  "贺信": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hèxìn",
      "PrimaryDefinition": "Congratulate"
    },
    "Keys": {
      "Word": 7053
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "搬迁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bānqiān",
      "PrimaryDefinition": "to move, to relocate, removal"
    },
    "Keys": {
      "Word": 5488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "困惑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùnhuò",
      "PrimaryDefinition": "perplexed, puzzled"
    },
    "Keys": {
      "Word": 7898
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pō",
      "PrimaryDefinition": "polonium"
    },
    "Keys": {
      "Hanzi": 3722
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3722,
      "Radical": "金",
      "FrequencyRank": 5626,
      "RadicalIndex": "167.2",
      "StrokeCount": 7
    }
  },
  "不禁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùjīn",
      "PrimaryDefinition": "can't help (doing sth.)"
    },
    "Keys": {
      "Word": 4355
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "灰尘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huīchén",
      "PrimaryDefinition": "dust"
    },
    "Keys": {
      "Word": 7199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mán",
      "PrimaryDefinition": "barbarians; barbarous, savage"
    },
    "Keys": {
      "Hanzi": 2727,
      "Word": 8197
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "142.6",
      "Grade": 7,
      "GeneralStandard": 2727,
      "Radical": "虫",
      "HSK": 7,
      "FrequencyRank": 2068,
      "StrokeCount": 12
    }
  },
  "稳重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěnzhòng",
      "PrimaryDefinition": "steady, earnest, staid"
    },
    "Keys": {
      "Word": 9749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "普及": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǔjí",
      "PrimaryDefinition": "popularize, disseminate, spread"
    },
    "Keys": {
      "Word": 1798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "外形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàixíng",
      "PrimaryDefinition": "figure, shape, external form, contour"
    },
    "Keys": {
      "Word": 9643
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "熟悉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shúxi",
      "PrimaryDefinition": "know sth. well, know sb. well, get into, ken, have an intimate knowledge of, acquaint, know sth., know ... well, familiar, know sth./sb. well, be familiar with, sb. well"
    },
    "Keys": {
      "Word": 3927
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "令": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 282,
      "Word": 3700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "Grade": 5,
      "GeneralStandard": 282,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 378,
      "StrokeCount": 5
    }
  },
  "铋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "bismuth"
    },
    "Keys": {
      "Hanzi": 4543
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4543,
      "Radical": "金",
      "FrequencyRank": 5886,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "楼房": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóufáng",
      "PrimaryDefinition": "a building of two or more stories, CL:棟|栋[dong4],幢[zhuang4],座[zuo4]"
    },
    "Keys": {
      "Word": 4839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "…分之…": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "… fēn zhī …",
      "PrimaryDefinition": "... is divided into ..."
    },
    "Keys": {
      "Word": 2466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "㭎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "(simplified form of 棡) a tall tree; a large tree, (interchangeable 扛) to carry on the shoulders of two of more men"
    },
    "Keys": {
      "Hanzi": 6664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6664,
      "Radical": "木",
      "FrequencyRank": 9038,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "碰上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèngshàng",
      "PrimaryDefinition": "to run into, to come upon, to meet"
    },
    "Keys": {
      "Word": 8500
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "运用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnyòng",
      "PrimaryDefinition": "utilize, wield, apply"
    },
    "Keys": {
      "Word": 3141
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鲝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7719,
      "Radical": "魚",
      "FrequencyRank": 7304,
      "RadicalIndex": "195.6",
      "StrokeCount": 14
    }
  },
  "前辈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánbèi",
      "PrimaryDefinition": "senior, older generation, precursor"
    },
    "Keys": {
      "Word": 8660
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祭祀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìsi",
      "PrimaryDefinition": "offer sacrifices to gods/ancestors"
    },
    "Keys": {
      "Word": 7340
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōu",
      "PrimaryDefinition": "to steal, burglar, thief"
    },
    "Keys": {
      "Hanzi": 2330,
      "Word": 3983
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.9",
      "Grade": 5,
      "GeneralStandard": 2330,
      "Radical": "人",
      "HSK": 5,
      "FrequencyRank": 1284,
      "StrokeCount": 11
    }
  },
  "潩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7726
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7726,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 14
    }
  },
  "多次": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōcì",
      "PrimaryDefinition": "many times, repeatedly"
    },
    "Keys": {
      "Word": 2432
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "平等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngděng",
      "PrimaryDefinition": "equal"
    },
    "Keys": {
      "Word": 914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "孤独": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gūdú",
      "PrimaryDefinition": "lonely, solitary"
    },
    "Keys": {
      "Word": 4616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "钮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niǔ",
      "PrimaryDefinition": "button, knob; surname"
    },
    "Keys": {
      "Hanzi": 1535
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1535,
      "Radical": "金",
      "FrequencyRank": 2685,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "才华": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cáihuá",
      "PrimaryDefinition": "literary talent, artistic talent, literary, literacy, flair, literary/artistic talent"
    },
    "Keys": {
      "Word": 5737
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鄢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yān",
      "PrimaryDefinition": "name of a district in Honan"
    },
    "Keys": {
      "Hanzi": 5351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5351,
      "Radical": "邑",
      "FrequencyRank": 5266,
      "RadicalIndex": "163.11",
      "StrokeCount": 13
    }
  },
  "纬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "woof; parallels of latitude"
    },
    "Keys": {
      "Hanzi": 929
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.4",
      "GeneralStandard": 929,
      "Radical": "糸",
      "HSK": 9,
      "FrequencyRank": 2697,
      "StrokeCount": 7
    }
  },
  "相": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāng",
      "PrimaryDefinition": "mutual, reciprocal, each other"
    },
    "Keys": {
      "Hanzi": 1430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.4",
      "GeneralStandard": 1430,
      "Radical": "目",
      "HSK": 2,
      "FrequencyRank": 152,
      "StrokeCount": 9
    }
  },
  "体验": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐyàn",
      "PrimaryDefinition": "learn through practice/experience"
    },
    "Keys": {
      "Word": 1960
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "坞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "entrenchment, bank, low wall"
    },
    "Keys": {
      "Hanzi": 3655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3655,
      "Radical": "土",
      "FrequencyRank": 3157,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "唛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mà",
      "PrimaryDefinition": "mark"
    },
    "Keys": {
      "Hanzi": 4494
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4494,
      "Radical": "口",
      "FrequencyRank": 6833,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "周边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōubiān",
      "PrimaryDefinition": "circumference, computer peripheral, environs, peripheral device, precinct, peripheral, periphery, perimeter"
    },
    "Keys": {
      "Word": 10836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "经商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng shāng",
      "PrimaryDefinition": "to trade, to carry out commercial activities, in business"
    },
    "Keys": {
      "Word": 7595
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "占用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànyòng",
      "PrimaryDefinition": "occupy, take over"
    },
    "Keys": {
      "Word": 10644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肌肉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīròu",
      "PrimaryDefinition": "brawn, muscular, beef, flesh, muscle, thew, sinew"
    },
    "Keys": {
      "Word": 3574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "搜救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōujiù",
      "PrimaryDefinition": "search and rescue"
    },
    "Keys": {
      "Word": 9338
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公开": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngkāi",
      "PrimaryDefinition": "bare, air, unclose, publish, come_out, make known to the public, publicize, divulgate, overt, publicise, public, disclosure, make known to public, unfurl, make public"
    },
    "Keys": {
      "Word": 1504
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "性格": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìnggé",
      "PrimaryDefinition": "fibre, make-up, mettle, humor, nature, complexion, capacity, mentality, mould, mold, being, streak, temperament, personality, character, fiber, disposition, strain, makeup, make"
    },
    "Keys": {
      "Word": 2065
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "码头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎtou",
      "PrimaryDefinition": "commercial/transportation center, port city, landing, pier, marina, commercial center, quay, wharf, levee, landing place, jetty, commercial, stage, commercial and transportation center, transportation center, wharfage, staith, dock"
    },
    "Keys": {
      "Word": 3708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "传人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánrén",
      "PrimaryDefinition": "to teach, to impart, a disciple, descendant"
    },
    "Keys": {
      "Word": 6009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "市场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìchǎng",
      "PrimaryDefinition": "bourse, bazar, marketplace, grocery store, agora, bazaar, securities industry, grocery, piazza, plaza, rialto, mart, pitch, market"
    },
    "Keys": {
      "Word": 1909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "矢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ",
      "PrimaryDefinition": "arrow"
    },
    "Keys": {
      "Radical": 111,
      "Hanzi": 264
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "111",
      "GeneralStandard": 264,
      "Radical": "矢",
      "HSK": 9,
      "FrequencyRank": 2811,
      "StrokeCount": 5
    }
  },
  "雹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "báo",
      "PrimaryDefinition": "hail"
    },
    "Keys": {
      "Hanzi": 2887
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "173.5",
      "GeneralStandard": 2887,
      "Radical": "雨",
      "HSK": 6,
      "FrequencyRank": 4037,
      "StrokeCount": 13
    }
  },
  "只管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐguǎn",
      "PrimaryDefinition": "solely engrossed in one thing, just (one thing, no need to worry about the rest), simply, by all means, please feel free, do not hesitate (to ask for sth)"
    },
    "Keys": {
      "Word": 5357
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "戗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "support"
    },
    "Keys": {
      "Hanzi": 3976
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3976,
      "Radical": "戈",
      "FrequencyRank": 5957,
      "RadicalIndex": "62.4",
      "StrokeCount": 8
    }
  },
  "正宗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngzōng",
      "PrimaryDefinition": "orthodox school, fig. traditional, old school, authentic, genuine"
    },
    "Keys": {
      "Word": 10736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "含蓄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hánxù",
      "PrimaryDefinition": "to contain, to hold, (of a person or style etc) reserved, restrained, (of words, writings) full of hidden meaning, implicit, veiled (criticism)"
    },
    "Keys": {
      "Word": 6981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "劝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quàn",
      "PrimaryDefinition": "recommend, advise, urge"
    },
    "Keys": {
      "Hanzi": 186,
      "Word": 3827
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "19.2",
      "Grade": 5,
      "GeneralStandard": 186,
      "Radical": "力",
      "HSK": 5,
      "FrequencyRank": 1562,
      "StrokeCount": 4
    }
  },
  "求婚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú hūn",
      "PrimaryDefinition": "to propose marriage"
    },
    "Keys": {
      "Word": 8770
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "桃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "peach; marriage; surname"
    },
    "Keys": {
      "Hanzi": 1844,
      "Word": 3964
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "Grade": 5,
      "GeneralStandard": 1844,
      "Radical": "木",
      "HSK": 5,
      "FrequencyRank": 1839,
      "StrokeCount": 10
    }
  },
  "外衣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàiyī",
      "PrimaryDefinition": "outer clothing, semblance, appearance"
    },
    "Keys": {
      "Word": 5122
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "腩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5518
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5518,
      "Radical": "肉",
      "FrequencyRank": 6979,
      "RadicalIndex": "130.9",
      "StrokeCount": 13
    }
  },
  "橡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàng",
      "PrimaryDefinition": "chestnut oak; rubber tree; rubber"
    },
    "Keys": {
      "Hanzi": 3216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.12",
      "GeneralStandard": 3216,
      "Radical": "木",
      "HSK": 8,
      "FrequencyRank": 2871,
      "StrokeCount": 15
    }
  },
  "原创": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuánchuàng",
      "PrimaryDefinition": "originality, creativity, innovation"
    },
    "Keys": {
      "Word": 10542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "沐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "bathe, cleanse, wash, shampoo"
    },
    "Keys": {
      "Hanzi": 856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 856,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3464,
      "StrokeCount": 7
    }
  },
  "天空": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānkōng",
      "PrimaryDefinition": "firmament, heavenly, empyrean, heavens, welkin, blue, canopy, cloud, the heavens, vault, air, sky, the sky, Aether, wild blue yonder, blue air, heaven, blue sky, space"
    },
    "Keys": {
      "Word": 1961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "变": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "change, transform, alter; rebel"
    },
    "Keys": {
      "Hanzi": 1204,
      "Word": 521
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.6",
      "Grade": 2,
      "GeneralStandard": 1204,
      "Radical": "又",
      "HSK": 2,
      "FrequencyRank": 225,
      "StrokeCount": 8
    }
  },
  "妪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "old woman, hag"
    },
    "Keys": {
      "Hanzi": 3808
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3808,
      "Radical": "女",
      "FrequencyRank": 4872,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "简直": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎnzhí",
      "PrimaryDefinition": "simply, really"
    },
    "Keys": {
      "Word": 1598
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "鲎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "king crab"
    },
    "Keys": {
      "Hanzi": 5591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5591,
      "Radical": "魚",
      "FrequencyRank": 5084,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "范畴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fànchóu",
      "PrimaryDefinition": "category"
    },
    "Keys": {
      "Word": 6482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "丧生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sàng shēng",
      "PrimaryDefinition": "to die, to lose one's life"
    },
    "Keys": {
      "Word": 8924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "热烈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèliè",
      "PrimaryDefinition": "ardent"
    },
    "Keys": {
      "Word": 1841
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "乎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hū",
      "PrimaryDefinition": "interrogative or exclamatory final particle"
    },
    "Keys": {
      "Hanzi": 280
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "4.4",
      "GeneralStandard": 280,
      "Radical": "丿",
      "HSK": 4,
      "FrequencyRank": 458,
      "StrokeCount": 5
    }
  },
  "特殊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèshū",
      "PrimaryDefinition": "exceptional, special, particular; peculiar, distinctive"
    },
    "Keys": {
      "Word": 2919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "徒步": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túbù",
      "PrimaryDefinition": "to be on foot"
    },
    "Keys": {
      "Word": 9583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "操劳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāoláo",
      "PrimaryDefinition": "to work hard, to look after"
    },
    "Keys": {
      "Word": 5771
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāng",
      "PrimaryDefinition": "lever, pole, crowbar; sharpen"
    },
    "Keys": {
      "Hanzi": 679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.3",
      "GeneralStandard": 679,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 3066,
      "StrokeCount": 7
    }
  },
  "厌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "dislike, detest, reject; satiate"
    },
    "Keys": {
      "Hanzi": 386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "27.4",
      "GeneralStandard": 386,
      "Radical": "厂",
      "HSK": 5,
      "FrequencyRank": 1633,
      "StrokeCount": 6
    }
  },
  "覃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tán",
      "PrimaryDefinition": "reach to, spread to; extensive"
    },
    "Keys": {
      "Hanzi": 5107
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5107,
      "Radical": "襾",
      "FrequencyRank": 4889,
      "RadicalIndex": "146.6",
      "StrokeCount": 12
    }
  },
  "搛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5360
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5360,
      "Radical": "手",
      "FrequencyRank": 6782,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "主编": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔbiān",
      "PrimaryDefinition": "editor in chief"
    },
    "Keys": {
      "Word": 10852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǜ",
      "PrimaryDefinition": "strain out, filter"
    },
    "Keys": {
      "Hanzi": 3009
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "GeneralStandard": 3009,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 3249,
      "StrokeCount": 13
    }
  },
  "似曾相识": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìcéngxiāngshí",
      "PrimaryDefinition": "déjà vu (the experience of seeing exactly the same situation a second time), seemingly familiar, apparently already acquainted"
    },
    "Keys": {
      "Word": 9326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "阳光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yángguāng",
      "PrimaryDefinition": "sunray, shine, sun, sunlight, sunshine, sunbeam"
    },
    "Keys": {
      "Word": 2085
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "架": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jià",
      "PrimaryDefinition": "rack, stand, prop; prop up"
    },
    "Keys": {
      "Hanzi": 1743,
      "Word": 1594
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "75.5",
      "Grade": 3,
      "GeneralStandard": 1743,
      "Radical": "木",
      "HSK": 3,
      "FrequencyRank": 846,
      "StrokeCount": 9
    }
  },
  "菹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "salted or pickled vegetables"
    },
    "Keys": {
      "Hanzi": 4763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4763,
      "Radical": "艸",
      "FrequencyRank": 5606,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "解体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiě tǐ",
      "PrimaryDefinition": "to break up into components, to disintegrate, to collapse, to crumble"
    },
    "Keys": {
      "Word": 7541
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预测": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùcè",
      "PrimaryDefinition": "calculate, forecast"
    },
    "Keys": {
      "Word": 3127
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "动手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng shǒu",
      "PrimaryDefinition": "attack, get to work, hit out, begin, strike, hit, start work, handle, raise a hand to strike, fall, start action, touch"
    },
    "Keys": {
      "Word": 3426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "追问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuīwèn",
      "PrimaryDefinition": "to question closely, to investigate in detail, to examine minutely, to get to the heart of the matter"
    },
    "Keys": {
      "Word": 10920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "岸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àn",
      "PrimaryDefinition": "bank, shore; beach, coast"
    },
    "Keys": {
      "Hanzi": 1105,
      "Word": 3222
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "46.5",
      "Grade": 5,
      "GeneralStandard": 1105,
      "Radical": "山",
      "HSK": 5,
      "FrequencyRank": 971,
      "StrokeCount": 8
    }
  },
  "喜爱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǐài",
      "PrimaryDefinition": "like, love, be fond of"
    },
    "Keys": {
      "Word": 2999
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "响": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎng",
      "PrimaryDefinition": "make sound, make noise; sound"
    },
    "Keys": {
      "Hanzi": 1509,
      "Word": 1089
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.6",
      "Grade": 2,
      "GeneralStandard": 1509,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 503,
      "StrokeCount": 9
    }
  },
  "流浪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúlàng",
      "PrimaryDefinition": "lead vagrant life"
    },
    "Keys": {
      "Word": 8098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豁达": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòdá",
      "PrimaryDefinition": "optimistic, sanguine, generous, magnanimous, open-minded"
    },
    "Keys": {
      "Word": 7260
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歌星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēxīng",
      "PrimaryDefinition": "singing star"
    },
    "Keys": {
      "Word": 4601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "以为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐwéi",
      "PrimaryDefinition": "imagine, think/believe/consider erroneously, treat, deem, believe, suspect, suppose, consider erroneously, repute, animadvert, esteem, trow, speak up, feel, count, believe/consider erroneously, calculate, view as, guess, ween, look, expect, think erroneously, think, hold, opine, interpret, sound off, take_for, consider, take for"
    },
    "Keys": {
      "Word": 1153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "武术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔshù",
      "PrimaryDefinition": "military skill or technique (in former times), all kinds of martial art sports (some claiming spiritual development), self-defense, tradition of choreographed fights from opera and film (recent usage), also called kungfu 功夫, CL:種|种[zhong3]"
    },
    "Keys": {
      "Word": 2020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "黧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lí",
      "PrimaryDefinition": "a dark, sallow colour"
    },
    "Keys": {
      "Hanzi": 6437
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6437,
      "Radical": "黑",
      "FrequencyRank": 5871,
      "RadicalIndex": "203.8",
      "StrokeCount": 20
    }
  },
  "无法": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúfǎ",
      "PrimaryDefinition": "nohow, unable to, unable, cannot"
    },
    "Keys": {
      "Word": 2984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "尾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěi",
      "PrimaryDefinition": "tail, extremity; end, stern"
    },
    "Keys": {
      "Hanzi": 905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "44.4",
      "GeneralStandard": 905,
      "Radical": "尸",
      "HSK": 4,
      "FrequencyRank": 1282,
      "StrokeCount": 7
    }
  },
  "沫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mò",
      "PrimaryDefinition": "froth, foam, bubbles, suds"
    },
    "Keys": {
      "Hanzi": 1239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1239,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 2495,
      "StrokeCount": 8
    }
  },
  "农民工": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngmíngōng",
      "PrimaryDefinition": "migrant workers"
    },
    "Keys": {
      "Word": 8436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "哽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěng",
      "PrimaryDefinition": "choke (with grief)"
    },
    "Keys": {
      "Hanzi": 4499
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4499,
      "Radical": "口",
      "FrequencyRank": 3694,
      "RadicalIndex": "30.7",
      "StrokeCount": 10
    }
  },
  "锽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "weapon"
    },
    "Keys": {
      "Hanzi": 7681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7681,
      "Radical": "金",
      "FrequencyRank": 7316,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "身上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnshang",
      "PrimaryDefinition": "on the body, at hand, among"
    },
    "Keys": {
      "Word": 322
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "起点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐdiǎn",
      "PrimaryDefinition": "starting point"
    },
    "Keys": {
      "Word": 4928
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "语音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔyīn",
      "PrimaryDefinition": "spoken pronunciation of characters, pronunciation, spoken (vs. written) pronunciation of characters, speech sounds, phonetic"
    },
    "Keys": {
      "Word": 3124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "总额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒngé",
      "PrimaryDefinition": "gross, sum, rental, total, sum total, totality, amount"
    },
    "Keys": {
      "Word": 10983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "instrument"
    },
    "Keys": {
      "Hanzi": 4416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4416,
      "Radical": "土",
      "FrequencyRank": 5548,
      "RadicalIndex": "32.7",
      "StrokeCount": 10
    }
  },
  "委员会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěiyuánhuì",
      "PrimaryDefinition": "board, soviet, commission, committee, court, citizens committee, mission, delegacy, deputation, delegation, council"
    },
    "Keys": {
      "Word": 9710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3554,
      "Radical": "言",
      "FrequencyRank": 5195,
      "RadicalIndex": "149.3",
      "StrokeCount": 5
    }
  },
  "手艺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuyì",
      "PrimaryDefinition": "craftmanship, workmanship, handicraft, trade"
    },
    "Keys": {
      "Word": 9182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "环绕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánrào",
      "PrimaryDefinition": "circle, environ, encincture, encirclement, hoop, surround, cincture, gird, circumvolute, sphere, skirt, encircle, circumfluent, enwreathe, revolve around, border, girdle, girth, enring, rim"
    },
    "Keys": {
      "Word": 7167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "马力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎlì",
      "PrimaryDefinition": "horsepower"
    },
    "Keys": {
      "Word": 8180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "历时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìshí",
      "PrimaryDefinition": "to last, to take (time), period, diachronic"
    },
    "Keys": {
      "Word": 8002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蜒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yán",
      "PrimaryDefinition": "millipede"
    },
    "Keys": {
      "Hanzi": 2637
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2637,
      "Radical": "虫",
      "FrequencyRank": 3655,
      "RadicalIndex": "142.7",
      "StrokeCount": 12
    }
  },
  "农业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nóngyè",
      "PrimaryDefinition": "farm, farming, geoponics, husbandry, agricultural, plow, agriculture"
    },
    "Keys": {
      "Word": 1774
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "诩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xǔ",
      "PrimaryDefinition": "boast, brag; popular, well-known"
    },
    "Keys": {
      "Hanzi": 4059
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4059,
      "Radical": "言",
      "FrequencyRank": 3861,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "洪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": "vast, immense; flood, deluge"
    },
    "Keys": {
      "Hanzi": 1664
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "GeneralStandard": 1664,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1350,
      "StrokeCount": 9
    }
  },
  "蜿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": "creep, crawl"
    },
    "Keys": {
      "Hanzi": 5712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5712,
      "Radical": "虫",
      "FrequencyRank": 3914,
      "RadicalIndex": "142.8",
      "StrokeCount": 14
    }
  },
  "鲌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7559
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7559,
      "Radical": "魚",
      "FrequencyRank": 6234,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "出门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū mén",
      "PrimaryDefinition": "go_out, go on journey, go out, go on a journey, be away from home"
    },
    "Keys": {
      "Word": 567
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "蛳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sī",
      "PrimaryDefinition": "kind of snail with spiral shell"
    },
    "Keys": {
      "Hanzi": 5145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5145,
      "Radical": "虫",
      "FrequencyRank": 4385,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "困境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùnjìng",
      "PrimaryDefinition": "hell, soup, muddle, hell on earth, kettle of fish, embarrassment, mess, straits, stymie, hot potato, jam, hole, swamp, howdo-you-do, dilemma, spot, inferno, corner, difficult position, adversity, scrape, lurch, the pits, mire, box, fix, hot_potato, hardship, squeeze, pickle, dire straits, extremity, bind, puzzledom, morass, difficulty, quagmire, predicament"
    },
    "Keys": {
      "Word": 7899
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "表述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎoshù",
      "PrimaryDefinition": "to formulate, enunciation, to explain sth precisely"
    },
    "Keys": {
      "Word": 5632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "路上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùshang",
      "PrimaryDefinition": "on the road, on a journey, road surface"
    },
    "Keys": {
      "Word": 219
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "脱离": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōlí",
      "PrimaryDefinition": "depart, disaffiliate, sever, wander, disengage, renegade, defect, secede, splinter, break away, drop_out, free, opt, extricate, bolt, separate oneself from, break up, be divorced from, separate, deviate, swerve, break_loose, prescind, escape_from, pervert"
    },
    "Keys": {
      "Word": 3993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "祉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "happiness, blessings, good luck"
    },
    "Keys": {
      "Hanzi": 4049
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4049,
      "Radical": "示",
      "FrequencyRank": 4484,
      "RadicalIndex": "113.4",
      "StrokeCount": 8
    }
  },
  "顺势": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnshì",
      "PrimaryDefinition": "to take advantage, to seize an opportunity, in passing, without taking extra trouble, conveniently"
    },
    "Keys": {
      "Word": 9284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下载": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàzài",
      "PrimaryDefinition": "to download, also pr. [xia4 zai4]"
    },
    "Keys": {
      "Word": 3009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "脩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "dried meat (used as teachers payment in ancient times)"
    },
    "Keys": {
      "Hanzi": 7015
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7015,
      "Radical": "肉",
      "FrequencyRank": 6588,
      "RadicalIndex": "130.7",
      "StrokeCount": 10
    }
  },
  "无疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúyí",
      "PrimaryDefinition": "surely, undoubtedly, beyond doubt, certainty, pardi, certainly, easily"
    },
    "Keys": {
      "Word": 4018
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "钱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qián",
      "PrimaryDefinition": "money, currency, coins"
    },
    "Keys": {
      "Hanzi": 1928,
      "Word": 290
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.5",
      "Grade": 1,
      "GeneralStandard": 1928,
      "Radical": "金",
      "HSK": 1,
      "FrequencyRank": 603,
      "StrokeCount": 10
    }
  },
  "卓越": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóyuè",
      "PrimaryDefinition": "excel, excel at, brilliant, shine, surpassing, paramount, shine at, outstanding"
    },
    "Keys": {
      "Word": 10945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngxīn",
      "PrimaryDefinition": "to take care, to look out"
    },
    "Keys": {
      "Word": 6179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "察": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chá",
      "PrimaryDefinition": "examine, investigate; notice"
    },
    "Keys": {
      "Hanzi": 3174
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.11",
      "GeneralStandard": 3174,
      "Radical": "宀",
      "HSK": 3,
      "FrequencyRank": 564,
      "StrokeCount": 14
    }
  },
  "鳔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biào",
      "PrimaryDefinition": "swimming bladder of fish"
    },
    "Keys": {
      "Hanzi": 6404
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6404,
      "Radical": "魚",
      "FrequencyRank": 5765,
      "RadicalIndex": "195.11",
      "StrokeCount": 19
    }
  },
  "骥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jì",
      "PrimaryDefinition": "thoroughbred horse; refined and"
    },
    "Keys": {
      "Hanzi": 6418
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6418,
      "Radical": "馬",
      "FrequencyRank": 3835,
      "RadicalIndex": "187.16",
      "StrokeCount": 19
    }
  },
  "棼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fén",
      "PrimaryDefinition": "beams in the roof of a house confused, disordered"
    },
    "Keys": {
      "Hanzi": 5095
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5095,
      "Radical": "木",
      "FrequencyRank": 6395,
      "RadicalIndex": "75.8",
      "StrokeCount": 12
    }
  },
  "支配": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīpèi",
      "PrimaryDefinition": "arrange, allocate, budget, control, dominate, govern"
    },
    "Keys": {
      "Word": 4222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "抓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuā",
      "PrimaryDefinition": "scratch; clutch, seize, grab"
    },
    "Keys": {
      "Hanzi": 633,
      "Word": 2194
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "Grade": 3,
      "GeneralStandard": 633,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 992,
      "StrokeCount": 7
    }
  },
  "舞蹈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔdǎo",
      "PrimaryDefinition": "dancing, saltation, nautch, dance, terpsichore, choreography, stage dancing"
    },
    "Keys": {
      "Word": 5151
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "偶尔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǒuěr",
      "PrimaryDefinition": "once in a blue moon, from time to time, hardly ever, every so often, now and again, seldom, infrequently, on occasion, rarely, betweenwhiles, scarcely, unusually, occasionally, sporadically, every now and then, at times, now and then, once in a while"
    },
    "Keys": {
      "Word": 3756
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "果断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǒduàn",
      "PrimaryDefinition": "firm, decisive"
    },
    "Keys": {
      "Word": 6937
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "房子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángzi",
      "PrimaryDefinition": "building (of less than three stories), room, building, property, house"
    },
    "Keys": {
      "Word": 101
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "障": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "separate; shield; barricade"
    },
    "Keys": {
      "Hanzi": 3038
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "170.11",
      "GeneralStandard": 3038,
      "Radical": "阜",
      "HSK": 6,
      "FrequencyRank": 1237,
      "StrokeCount": 13
    }
  },
  "鼗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "small revolving drum with knobs"
    },
    "Keys": {
      "Hanzi": 8041
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8041,
      "Radical": "鼓",
      "FrequencyRank": 5729,
      "RadicalIndex": "207.6",
      "StrokeCount": 19
    }
  },
  "修车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūchē",
      "PrimaryDefinition": "to repair a bike (car etc)"
    },
    "Keys": {
      "Word": 5196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "昏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hūn",
      "PrimaryDefinition": "dusk, nightfall, twilight, dark"
    },
    "Keys": {
      "Hanzi": 1193,
      "Word": 4691
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.4",
      "Grade": 6,
      "GeneralStandard": 1193,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 1561,
      "StrokeCount": 8
    }
  },
  "秘诀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìjué",
      "PrimaryDefinition": "secret know-how, key (to longevity), secret (of happiness), recipe (for success)"
    },
    "Keys": {
      "Word": 8266
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "爆竹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàozhú",
      "PrimaryDefinition": "firecracker"
    },
    "Keys": {
      "Word": 5545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "殉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùn",
      "PrimaryDefinition": "die for cause, be martyr for"
    },
    "Keys": {
      "Hanzi": 1873
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1873,
      "Radical": "歹",
      "FrequencyRank": 3303,
      "RadicalIndex": "78.6",
      "StrokeCount": 10
    }
  },
  "耇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǒu",
      "PrimaryDefinition": "wrinkled face of the elderly"
    },
    "Keys": {
      "Hanzi": 6773
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6773,
      "Radical": "老",
      "FrequencyRank": 8965,
      "RadicalIndex": "125.5",
      "StrokeCount": 9
    }
  },
  "视力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìlì",
      "PrimaryDefinition": "eyeshot, eye, seeing, visual sense, view, beholding, visual perception, vision, visual modality, light, visual sensation, eyesight, sight, sightedness"
    },
    "Keys": {
      "Word": 9154
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "急": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "quick, quickly; urgent, pressing"
    },
    "Keys": {
      "Hanzi": 1609,
      "Word": 746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "Grade": 2,
      "GeneralStandard": 1609,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 657,
      "StrokeCount": 9
    }
  },
  "橱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chú",
      "PrimaryDefinition": "cabinet, wardrobe, cupboard"
    },
    "Keys": {
      "Hanzi": 3330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.12",
      "GeneralStandard": 3330,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 3041,
      "StrokeCount": 16
    }
  },
  "无线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxiàn",
      "PrimaryDefinition": "wireless"
    },
    "Keys": {
      "Word": 9788
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牛仔裤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niúzǎikù",
      "PrimaryDefinition": "jeans, CL:條|条[tiao2]"
    },
    "Keys": {
      "Word": 3749
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "手术室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒushùshì",
      "PrimaryDefinition": "Operating room"
    },
    "Keys": {
      "Word": 9179
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扣人心弦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kòurénxīnxián",
      "PrimaryDefinition": "to excite, to thrill, exciting, thrilling, cliff-hanging"
    },
    "Keys": {
      "Word": 7852
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "只是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐshì",
      "PrimaryDefinition": "merely, only, just, simply be"
    },
    "Keys": {
      "Word": 2168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "枨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "door stop, door jam; touch; (Cant.) to use the body to move someone"
    },
    "Keys": {
      "Hanzi": 3880
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3880,
      "Radical": "木",
      "FrequencyRank": 6327,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "球鞋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiúxié",
      "PrimaryDefinition": "athletic shoes"
    },
    "Keys": {
      "Word": 941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "枅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6663,
      "Radical": "木",
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "行程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíngchéng",
      "PrimaryDefinition": "throw, travel, tenor, itinerary, route, journey, route/distance of travel, distance of travel, stroke, route of travel"
    },
    "Keys": {
      "Word": 5191
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "酋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": "chief of tribe, chieftain"
    },
    "Keys": {
      "Hanzi": 4323
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4323,
      "Radical": "酉",
      "FrequencyRank": 3403,
      "RadicalIndex": "164.2",
      "StrokeCount": 9
    }
  },
  "瞌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "doze off; sleepy"
    },
    "Keys": {
      "Hanzi": 5902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5902,
      "Radical": "目",
      "FrequencyRank": 4061,
      "RadicalIndex": "109.1",
      "StrokeCount": 15
    }
  },
  "诱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "persuade, entice, induce; guide"
    },
    "Keys": {
      "Hanzi": 1715
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "GeneralStandard": 1715,
      "Radical": "言",
      "HSK": 7,
      "FrequencyRank": 1707,
      "StrokeCount": 9
    }
  },
  "骨干": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔgàn",
      "PrimaryDefinition": "diaphysis (long segment of a bone), fig. backbone"
    },
    "Keys": {
      "Word": 6833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "评估": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínggū",
      "PrimaryDefinition": "estimate, evaluate"
    },
    "Keys": {
      "Word": 3782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "前进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiánjìn",
      "PrimaryDefinition": "go forward"
    },
    "Keys": {
      "Word": 1808
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "糒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèi",
      "PrimaryDefinition": "food for a journey; cakes"
    },
    "Keys": {
      "Hanzi": 7896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7896,
      "Radical": "米",
      "RadicalIndex": "119.1",
      "StrokeCount": 16
    }
  },
  "做证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò zhèng",
      "PrimaryDefinition": "Do one"
    },
    "Keys": {
      "Word": 11035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "sacrifice, give up; sacrificial"
    },
    "Keys": {
      "Hanzi": 1939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "93.6",
      "GeneralStandard": 1939,
      "Radical": "牛",
      "HSK": 6,
      "FrequencyRank": 1814,
      "StrokeCount": 10
    }
  },
  "盒饭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héfàn",
      "PrimaryDefinition": "meal in a partitioned box"
    },
    "Keys": {
      "Word": 3538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "战争": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhànzhēng",
      "PrimaryDefinition": "war, warfare"
    },
    "Keys": {
      "Word": 3152
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "麟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lín",
      "PrimaryDefinition": "female of Chinese unicorn"
    },
    "Keys": {
      "Hanzi": 6485
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6485,
      "Radical": "鹿",
      "FrequencyRank": 2752,
      "RadicalIndex": "198.12",
      "StrokeCount": 23
    }
  },
  "稻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào",
      "PrimaryDefinition": "rice growing in field, rice plant"
    },
    "Keys": {
      "Hanzi": 3261
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "115.1",
      "GeneralStandard": 3261,
      "Radical": "禾",
      "HSK": 8,
      "FrequencyRank": 2529,
      "StrokeCount": 15
    }
  },
  "贸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "trade, barter; mixed; rashly"
    },
    "Keys": {
      "Hanzi": 1607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.5",
      "GeneralStandard": 1607,
      "Radical": "貝",
      "HSK": 5,
      "FrequencyRank": 1092,
      "StrokeCount": 9
    }
  },
  "然而": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ránér",
      "PrimaryDefinition": "conj.: even so, but"
    },
    "Keys": {
      "Word": 2820
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "贻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "give to, hand down, bequeath"
    },
    "Keys": {
      "Hanzi": 1522
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1522,
      "Radical": "貝",
      "FrequencyRank": 3504,
      "RadicalIndex": "154.5",
      "StrokeCount": 9
    }
  },
  "荽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suī",
      "PrimaryDefinition": "coriander"
    },
    "Keys": {
      "Hanzi": 4438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4438,
      "Radical": "艸",
      "FrequencyRank": 5715,
      "RadicalIndex": "140.7",
      "StrokeCount": 10
    }
  },
  "皮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": "skin"
    },
    "Keys": {
      "Hanzi": 336,
      "Radical": 107,
      "Word": 1790
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "107",
      "Grade": 3,
      "GeneralStandard": 336,
      "Radical": "皮",
      "HSK": 3,
      "FrequencyRank": 741,
      "StrokeCount": 5
    }
  },
  "梃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐng",
      "PrimaryDefinition": "a club; a stalk; straight"
    },
    "Keys": {
      "Hanzi": 4455
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4455,
      "Radical": "木",
      "FrequencyRank": 6681,
      "RadicalIndex": "75.7",
      "StrokeCount": 10
    }
  },
  "凵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǔ",
      "PrimaryDefinition": "open box"
    },
    "Keys": {
      "Radical": 17
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "嫉妒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jídù",
      "PrimaryDefinition": "to be jealous, to envy, to hate"
    },
    "Keys": {
      "Word": 7313
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当初": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngchū",
      "PrimaryDefinition": "at that time, originally"
    },
    "Keys": {
      "Word": 1393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "消费者": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāofèizhě",
      "PrimaryDefinition": "customer, buyer, consumer"
    },
    "Keys": {
      "Word": 4054
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "湲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "flow"
    },
    "Keys": {
      "Hanzi": 5288
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5288,
      "Radical": "水",
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "跑龙套": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǎo lóngtào",
      "PrimaryDefinition": "to play a small role"
    },
    "Keys": {
      "Word": 8479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "任意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rènyì",
      "PrimaryDefinition": "willfully, at_will, arbitrarily"
    },
    "Keys": {
      "Word": 8867
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "锜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "a kind of pen; a kind of chisel"
    },
    "Keys": {
      "Hanzi": 5484
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5484,
      "Radical": "金",
      "FrequencyRank": 5922,
      "RadicalIndex": "167.8",
      "StrokeCount": 13
    }
  },
  "数额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùé",
      "PrimaryDefinition": "amount, sum of money, fixed number"
    },
    "Keys": {
      "Word": 9239
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "认得": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèn de",
      "PrimaryDefinition": "to recognize, to remember sth (or sb) on seeing it, to know"
    },
    "Keys": {
      "Word": 1851
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "缘故": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuángù",
      "PrimaryDefinition": "score, grounds, reason, cause, sake"
    },
    "Keys": {
      "Word": 5292
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "池子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chízi",
      "PrimaryDefinition": "pond, bathhouse pool, dance floor of a ballroom, (old) stalls (front rows in a theater)"
    },
    "Keys": {
      "Word": 3332
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "卺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "(nuptial) winecups"
    },
    "Keys": {
      "Hanzi": 6740
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6740,
      "Radical": "卩",
      "FrequencyRank": 6635,
      "RadicalIndex": "26.6",
      "StrokeCount": 8
    }
  },
  "使劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǐ jìn",
      "PrimaryDefinition": "to exert all one's strength"
    },
    "Keys": {
      "Word": 2865
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "前头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiántou",
      "PrimaryDefinition": "in front, at the head, ahead, above"
    },
    "Keys": {
      "Word": 2801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "杼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "shuttle of loom; narrow; long-headed; a scrub oak; thin"
    },
    "Keys": {
      "Hanzi": 3885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3885,
      "Radical": "木",
      "FrequencyRank": 5015,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "琴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "Chinese lute or guitar"
    },
    "Keys": {
      "Hanzi": 2501,
      "Word": 3816
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96.8",
      "Grade": 5,
      "GeneralStandard": 2501,
      "Radical": "玉",
      "HSK": 5,
      "FrequencyRank": 1701,
      "StrokeCount": 12
    }
  },
  "万能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wànnéng",
      "PrimaryDefinition": "omnipotent, all-purpose, universal"
    },
    "Keys": {
      "Word": 9662
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "人为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénwéi",
      "PrimaryDefinition": "man-made, artificial"
    },
    "Keys": {
      "Word": 8849
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "实话": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíhuà",
      "PrimaryDefinition": "truth"
    },
    "Keys": {
      "Word": 9113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当众": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāngzhòng",
      "PrimaryDefinition": "in public, in front of everybody"
    },
    "Keys": {
      "Word": 6182
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缺点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quēdiǎn",
      "PrimaryDefinition": "privilege, bug, failing, flaw, demerit, blemish, debit, limitation, mar, defect, glitch, foible, infirmity, blot, vice, drawback, disadvantage, imperfection, blemishment, weakness, tache, defection, shortcoming, malady, frailty, fault"
    },
    "Keys": {
      "Word": 1834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "贵重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guìzhòng",
      "PrimaryDefinition": "precious"
    },
    "Keys": {
      "Word": 6923
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "银牌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yínpái",
      "PrimaryDefinition": "silver medal, CL:枚[mei2]"
    },
    "Keys": {
      "Word": 2096
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "酲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "hangover; uncomfortable"
    },
    "Keys": {
      "Hanzi": 7657
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7657,
      "Radical": "酉",
      "FrequencyRank": 6850,
      "RadicalIndex": "164.7",
      "StrokeCount": 14
    }
  },
  "瘊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóu",
      "PrimaryDefinition": "warts, pimples"
    },
    "Keys": {
      "Hanzi": 5782
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5782,
      "Radical": "疒",
      "FrequencyRank": 7456,
      "RadicalIndex": "104.9",
      "StrokeCount": 14
    }
  },
  "基本上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīběnshang",
      "PrimaryDefinition": "basically, on the whole"
    },
    "Keys": {
      "Word": 1572
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "辰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chén",
      "PrimaryDefinition": "morning"
    },
    "Keys": {
      "Radical": 161,
      "Hanzi": 701
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "161",
      "GeneralStandard": 701,
      "Radical": "辰",
      "HSK": 9,
      "FrequencyRank": 2317,
      "StrokeCount": 7
    }
  },
  "灭亡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mièwáng",
      "PrimaryDefinition": "to be destroyed, to become extinct, to perish, to die out, to destroy, to exterminate"
    },
    "Keys": {
      "Word": 8295
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "久违": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔwéi",
      "PrimaryDefinition": "(haven't done sth) for a long time, a long time since we last met"
    },
    "Keys": {
      "Word": 7659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "摆平": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎi píng",
      "PrimaryDefinition": "to be fair, to be impartial, to settle (a matter etc)"
    },
    "Keys": {
      "Word": 5478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "盗版": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dào bǎn",
      "PrimaryDefinition": "pirated, illegal, see also 正版[zheng4 ban3]"
    },
    "Keys": {
      "Word": 4493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "钻石": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuànshí",
      "PrimaryDefinition": "diamond, watch jewel"
    },
    "Keys": {
      "Word": 11015
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "集结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíjié",
      "PrimaryDefinition": "to assemble, to concentrate, to mass, to build up, to marshal"
    },
    "Keys": {
      "Word": 7309
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "危险": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēixiǎn",
      "PrimaryDefinition": "dangerous, perilous"
    },
    "Keys": {
      "Word": 2003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "嘤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "seek friends; also used in onomatopoetic expressions"
    },
    "Keys": {
      "Hanzi": 5719
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5719,
      "Radical": "口",
      "FrequencyRank": 4224,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "激动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīdòng",
      "PrimaryDefinition": "boil, tingle, kindle, seethe, toss, inflammatory, tizz, excited, fire, throb, carry_away, ferment, effervesce, stirred, bespirit, heat, agitation, hound, agitate, invigorate, burnt, actuate, inflame, stir, agitated, excite, move, impassion"
    },
    "Keys": {
      "Word": 2576
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "筛选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāixuǎn",
      "PrimaryDefinition": "to filter"
    },
    "Keys": {
      "Word": 8945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有趣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuqù",
      "PrimaryDefinition": "merry, fascinating, interesting, fun, amusive, comic, amusing"
    },
    "Keys": {
      "Word": 3119
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "旭日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xùrì",
      "PrimaryDefinition": "the rising sun"
    },
    "Keys": {
      "Word": 10100
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "梆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bāng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1834,
      "Radical": "木",
      "FrequencyRank": 4062,
      "RadicalIndex": "75.7",
      "StrokeCount": 10
    }
  },
  "洸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāng",
      "PrimaryDefinition": "sparkle, glitter"
    },
    "Keys": {
      "Hanzi": 6888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6888,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "相片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàngpiàn",
      "PrimaryDefinition": "exposure, print, semblance, likeness, photo, photograph, photographic print"
    },
    "Keys": {
      "Word": 3025
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "警告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnggào",
      "PrimaryDefinition": "warn, admonish"
    },
    "Keys": {
      "Word": 3630
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "摸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mō",
      "PrimaryDefinition": "gently touch with hand; caress"
    },
    "Keys": {
      "Hanzi": 2833,
      "Word": 2748
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.11",
      "Grade": 4,
      "GeneralStandard": 2833,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1367,
      "StrokeCount": 13
    }
  },
  "翌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "bright; daybreak, dawn; the next day"
    },
    "Keys": {
      "Hanzi": 5024
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5024,
      "Radical": "羽",
      "FrequencyRank": 3708,
      "RadicalIndex": "124.5",
      "StrokeCount": 11
    }
  },
  "圈子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quānzi",
      "PrimaryDefinition": "circle, ring, (social) circle"
    },
    "Keys": {
      "Word": 8799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "纭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yún",
      "PrimaryDefinition": "confused, in disorder; numerous"
    },
    "Keys": {
      "Hanzi": 3821
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3821,
      "Radical": "糸",
      "FrequencyRank": 4262,
      "RadicalIndex": "120.4",
      "StrokeCount": 7
    }
  },
  "观赏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guānshǎng",
      "PrimaryDefinition": "enjoy sight of, see and enjoy"
    },
    "Keys": {
      "Word": 6873
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "验证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yànzhèng",
      "PrimaryDefinition": "test and verify"
    },
    "Keys": {
      "Word": 10213
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "利害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìhai",
      "PrimaryDefinition": "pros and cons, advantages and disadvantages, gains and losses, terrible, formidable, serious, devastating, tough, capable, sharp, severe, fierce"
    },
    "Keys": {
      "Word": 8009
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "矛盾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máodùn",
      "PrimaryDefinition": "contradictory"
    },
    "Keys": {
      "Word": 3714
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "惊天动地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngtiān-dòngdì",
      "PrimaryDefinition": "world-shaking (idiom)"
    },
    "Keys": {
      "Word": 7604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "偏方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piānfāng",
      "PrimaryDefinition": "folk remedy, home remedy"
    },
    "Keys": {
      "Word": 8518
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "角逐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juézhú",
      "PrimaryDefinition": "contend, tussle"
    },
    "Keys": {
      "Word": 7720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "杙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "a tiny wooden post; peg"
    },
    "Keys": {
      "Hanzi": 6579
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6579,
      "Radical": "木",
      "RadicalIndex": "75.3",
      "StrokeCount": 7
    }
  },
  "受灾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu zāi",
      "PrimaryDefinition": "disaster-stricken, to be hit by a natural calamity"
    },
    "Keys": {
      "Word": 3917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "收到": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shōu dào",
      "PrimaryDefinition": "hear, find, achievement, receive, receipt, obtain, achieve, incur, get, come_to"
    },
    "Keys": {
      "Word": 984
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "鬏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiū",
      "PrimaryDefinition": "a coiffure on top of the head"
    },
    "Keys": {
      "Hanzi": 6366
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6366,
      "Radical": "髟",
      "FrequencyRank": 7322,
      "RadicalIndex": "190.9",
      "StrokeCount": 19
    }
  },
  "菽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": "beans and peas, collectively"
    },
    "Keys": {
      "Hanzi": 4752
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4752,
      "Radical": "艸",
      "FrequencyRank": 5618,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "力气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìqi",
      "PrimaryDefinition": "physical strength, effort"
    },
    "Keys": {
      "Word": 2693
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "盉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 4555
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4555,
      "Radical": "皿",
      "FrequencyRank": 9891,
      "RadicalIndex": "108.5",
      "StrokeCount": 10
    }
  },
  "纤维": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānwéi",
      "PrimaryDefinition": "fiber, staple"
    },
    "Keys": {
      "Word": 9884
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "棂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "carved or patterned window sills"
    },
    "Keys": {
      "Hanzi": 4779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4779,
      "Radical": "木",
      "FrequencyRank": 5016,
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "救": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù",
      "PrimaryDefinition": "save, rescue, relieve; help, aid"
    },
    "Keys": {
      "Hanzi": 2232,
      "Word": 1649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.7",
      "Grade": 3,
      "GeneralStandard": 2232,
      "Radical": "攴",
      "HSK": 3,
      "FrequencyRank": 872,
      "StrokeCount": 11
    }
  },
  "智慧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhìhuì",
      "PrimaryDefinition": "lore, sapiency, sconce, wit, intelligence, gumption, savvy, wiseness, soundness, wisdom, grey_matter, sapience"
    },
    "Keys": {
      "Word": 5363
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "评价": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngjià",
      "PrimaryDefinition": "estimate, diagnoses, appraisal, mark, rating, rate, value, assessment, esteem, image, estimation, valuation, appraisement, opinion, rank, remark, evaluation"
    },
    "Keys": {
      "Word": 1794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "霨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "clouding"
    },
    "Keys": {
      "Hanzi": 6380
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6380,
      "Radical": "雨",
      "FrequencyRank": 6736,
      "RadicalIndex": "173.11",
      "StrokeCount": 19
    }
  },
  "日记": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rìjì",
      "PrimaryDefinition": "diary, journal"
    },
    "Keys": {
      "Word": 2827
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "反映": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǎnyìng",
      "PrimaryDefinition": "reflect, mirror, make known"
    },
    "Keys": {
      "Word": 2455
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "牵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiān",
      "PrimaryDefinition": "drag, pull, lead by hand"
    },
    "Keys": {
      "Hanzi": 1458,
      "Word": 4927
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "37.6",
      "Grade": 6,
      "GeneralStandard": 1458,
      "Radical": "大",
      "HSK": 6,
      "FrequencyRank": 1679,
      "StrokeCount": 9
    }
  },
  "镯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuó",
      "PrimaryDefinition": "bracelet, armband; small bell"
    },
    "Keys": {
      "Hanzi": 6346
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6346,
      "Radical": "金",
      "FrequencyRank": 3854,
      "RadicalIndex": "167.13",
      "StrokeCount": 18
    }
  },
  "艄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāo",
      "PrimaryDefinition": "stern of a vessel"
    },
    "Keys": {
      "Hanzi": 5510
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5510,
      "Radical": "舟",
      "FrequencyRank": 4898,
      "RadicalIndex": "137.7",
      "StrokeCount": 13
    }
  },
  "辉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "brightness, luster, brilliance"
    },
    "Keys": {
      "Hanzi": 2606
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "42.9",
      "GeneralStandard": 2606,
      "Radical": "小",
      "HSK": 6,
      "FrequencyRank": 1623,
      "StrokeCount": 12
    }
  },
  "寒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hán",
      "PrimaryDefinition": "cold, wintry, chilly"
    },
    "Keys": {
      "Hanzi": 2780
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.9",
      "GeneralStandard": 2780,
      "Radical": "宀",
      "HSK": 4,
      "FrequencyRank": 1297,
      "StrokeCount": 12
    }
  },
  "是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "indeed, yes, right; to be; demonstrative pronoun, this, that"
    },
    "Keys": {
      "Hanzi": 1479,
      "Word": 334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.5",
      "Grade": 1,
      "GeneralStandard": 1479,
      "Radical": "日",
      "HSK": 1,
      "FrequencyRank": 3,
      "StrokeCount": 9
    }
  },
  "交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": "mix; intersect; exchange, communicate; deliver"
    },
    "Keys": {
      "Hanzi": 519,
      "Word": 763
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "8.4",
      "Grade": 2,
      "GeneralStandard": 519,
      "Radical": "亠",
      "HSK": 2,
      "FrequencyRank": 320,
      "StrokeCount": 6
    }
  },
  "统统": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒngtǒng",
      "PrimaryDefinition": "all, entirely, totally, completely"
    },
    "Keys": {
      "Word": 9544
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xíng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3567
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3567,
      "Radical": "邑",
      "FrequencyRank": 3382,
      "RadicalIndex": "163.4",
      "StrokeCount": 6
    }
  },
  "钢笔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngbǐ",
      "PrimaryDefinition": "fountain pen, CL:支[zhi1]"
    },
    "Keys": {
      "Word": 3482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "钝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "blunt, obtuse; dull; flat; dull-witted"
    },
    "Keys": {
      "Hanzi": 1526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "167.4",
      "GeneralStandard": 1526,
      "Radical": "金",
      "HSK": 6,
      "FrequencyRank": 3059,
      "StrokeCount": 9
    }
  },
  "深切": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēnqiè",
      "PrimaryDefinition": "heartfelt, deep, profound"
    },
    "Keys": {
      "Word": 9027
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嵲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7525
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7525,
      "Radical": "山",
      "RadicalIndex": "46.1",
      "StrokeCount": 13
    }
  },
  "受害": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu hài",
      "PrimaryDefinition": "suffer injury, suffer loss, fall victim, loss, suffer, be affected, victimize, be victimized"
    },
    "Keys": {
      "Word": 9195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "橼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "citrus"
    },
    "Keys": {
      "Hanzi": 6064
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6064,
      "Radical": "木",
      "FrequencyRank": 6751,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "惕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "be cautious, careful, alert"
    },
    "Keys": {
      "Hanzi": 2443
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.8",
      "GeneralStandard": 2443,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 2742,
      "StrokeCount": 11
    }
  },
  "愔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "comfortable, contented, peaceful"
    },
    "Keys": {
      "Hanzi": 5300
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5300,
      "Radical": "心",
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "衣服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīfu",
      "PrimaryDefinition": "clothes, wearing apparel, toggery, gear, tog, wear, bib-and-tucker, drapery, thing, dress, duds, vestment, wardrobe, clout, apparel, clothing, dud, wrapping, vesture, clobber, habit, robe, togs, livery, habilimentation, array, hull, garb, attire, attirement, stitch, rig, garment, raiment, wearable, dressing"
    },
    "Keys": {
      "Word": 423
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "在意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zài yì",
      "PrimaryDefinition": "take notice of, care about, pay attention to, take ... to heart, care, mind"
    },
    "Keys": {
      "Word": 10592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "起床": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐ chuáng",
      "PrimaryDefinition": "uprise, get up from bed, arise, get out of bed, turn out, rise, deck, get_up, awake, get up"
    },
    "Keys": {
      "Word": 284
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "瀑布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pùbù",
      "PrimaryDefinition": "waterfall"
    },
    "Keys": {
      "Word": 8585
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "车轮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēlún",
      "PrimaryDefinition": "wheel"
    },
    "Keys": {
      "Word": 5840
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "刹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shā",
      "PrimaryDefinition": "temple, shrine, monastary"
    },
    "Keys": {
      "Hanzi": 1167
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "GeneralStandard": 1167,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 2249,
      "StrokeCount": 8
    }
  },
  "耗时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàoshí",
      "PrimaryDefinition": "time-consuming, to take a period of (x amount of time)"
    },
    "Keys": {
      "Word": 7020
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "潜移默化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiányí-mòhuà",
      "PrimaryDefinition": "imperceptible influence, to influence secretly"
    },
    "Keys": {
      "Word": 8679
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīnglǐ",
      "PrimaryDefinition": "settle (accounts/etc.), sort out affairs"
    },
    "Keys": {
      "Word": 3819
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "隳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huī",
      "PrimaryDefinition": "to destroy; to overthrow"
    },
    "Keys": {
      "Hanzi": 6305
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6305,
      "Radical": "阜",
      "FrequencyRank": 5636,
      "RadicalIndex": "170.15",
      "StrokeCount": 17
    }
  },
  "两侧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liǎngcè",
      "PrimaryDefinition": "two sides, both sides"
    },
    "Keys": {
      "Word": 4826
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "端午节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Duānwǔjié",
      "PrimaryDefinition": "Dragon Boat Festival (5th day of the 5th lunar month)"
    },
    "Keys": {
      "Word": 4522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "全心全意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánxīn-quányì",
      "PrimaryDefinition": "heart and soul, wholeheartedly"
    },
    "Keys": {
      "Word": 8807
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "思考": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīkǎo",
      "PrimaryDefinition": "ponder over, reflect on"
    },
    "Keys": {
      "Word": 2904
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "招手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāo shǒu",
      "PrimaryDefinition": "to wave, to beckon"
    },
    "Keys": {
      "Word": 4196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "休想": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiūxiǎng",
      "PrimaryDefinition": "don't think (that), don't imagine (that)"
    },
    "Keys": {
      "Word": 10074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhěn",
      "PrimaryDefinition": "examine patient, diagnose"
    },
    "Keys": {
      "Hanzi": 896
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.5",
      "GeneralStandard": 896,
      "Radical": "言",
      "HSK": 5,
      "FrequencyRank": 1401,
      "StrokeCount": 7
    }
  },
  "轮椅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnyǐ",
      "PrimaryDefinition": "wheelchair"
    },
    "Keys": {
      "Word": 2719
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "陈述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chénshù",
      "PrimaryDefinition": "an assertion, to declare, to state"
    },
    "Keys": {
      "Word": 5859
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "牧场": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùchǎng",
      "PrimaryDefinition": "pasture, grazing land, ranch"
    },
    "Keys": {
      "Word": 8353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "塑料": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùliào",
      "PrimaryDefinition": "plastics, CL:種|种[zhong3]"
    },
    "Keys": {
      "Word": 2908
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "舂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōng",
      "PrimaryDefinition": "grind in mortar"
    },
    "Keys": {
      "Hanzi": 4712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4712,
      "Radical": "臼",
      "FrequencyRank": 5145,
      "RadicalIndex": "134.5",
      "StrokeCount": 11
    }
  },
  "完": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": "complete, finish, settle; whole"
    },
    "Keys": {
      "Hanzi": 877,
      "Word": 1044
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "40.4",
      "Grade": 2,
      "GeneralStandard": 877,
      "Radical": "宀",
      "HSK": 2,
      "FrequencyRank": 301,
      "StrokeCount": 7
    }
  },
  "缥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piāo",
      "PrimaryDefinition": "light blue silk; dim; misty"
    },
    "Keys": {
      "Hanzi": 5842
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5842,
      "Radical": "糸",
      "FrequencyRank": 4460,
      "RadicalIndex": "120.11",
      "StrokeCount": 14
    }
  },
  "鞠躬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūgōng",
      "PrimaryDefinition": "to bow, (literary) to bend down"
    },
    "Keys": {
      "Word": 7680
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "同类": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tónglèi",
      "PrimaryDefinition": "similar, same type, alike"
    },
    "Keys": {
      "Word": 9536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蕻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hóng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6046
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6046,
      "Radical": "艸",
      "FrequencyRank": 6519,
      "RadicalIndex": "140.13",
      "StrokeCount": 16
    }
  },
  "馝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "fragrance"
    },
    "Keys": {
      "Hanzi": 7688
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7688,
      "Radical": "香",
      "RadicalIndex": "186.5",
      "StrokeCount": 14
    }
  },
  "亲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīn",
      "PrimaryDefinition": "relatives, parents; intimate the hazel nut or filbert tree a thorny tree"
    },
    "Keys": {
      "Hanzi": 1631,
      "Word": 1819
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "117.4",
      "Grade": 3,
      "GeneralStandard": 1631,
      "Radical": "立",
      "HSK": 3,
      "FrequencyRank": 362,
      "StrokeCount": 9
    }
  },
  "早年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎonián",
      "PrimaryDefinition": "early years, adolescence"
    },
    "Keys": {
      "Word": 10609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēi",
      "PrimaryDefinition": "embryo; unfinished things"
    },
    "Keys": {
      "Hanzi": 1591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "GeneralStandard": 1591,
      "Radical": "肉",
      "HSK": 9,
      "FrequencyRank": 2928,
      "StrokeCount": 9
    }
  },
  "菥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7122
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7122,
      "Radical": "艸",
      "FrequencyRank": 6532,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "狞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "ferocious appearance; hideous"
    },
    "Keys": {
      "Hanzi": 1199
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1199,
      "Radical": "犬",
      "FrequencyRank": 3457,
      "RadicalIndex": "94.5",
      "StrokeCount": 8
    }
  },
  "转化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuǎnhuà",
      "PrimaryDefinition": "to change, to transform, isomerization (chemistry)"
    },
    "Keys": {
      "Word": 4263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "焦点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāodiǎn",
      "PrimaryDefinition": "focal point, point at issue, focal, focus, focal_point, central issue"
    },
    "Keys": {
      "Word": 4720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "觯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "wine goblet made from horn"
    },
    "Keys": {
      "Hanzi": 5977
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5977,
      "Radical": "角",
      "FrequencyRank": 4508,
      "RadicalIndex": "148.8",
      "StrokeCount": 15
    }
  },
  "嵛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yú",
      "PrimaryDefinition": "county in Shandong province"
    },
    "Keys": {
      "Hanzi": 5171
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5171,
      "Radical": "山",
      "FrequencyRank": 5858,
      "RadicalIndex": "46.9",
      "StrokeCount": 12
    }
  },
  "萃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuì",
      "PrimaryDefinition": "dense, thick, close-set; to collect together"
    },
    "Keys": {
      "Hanzi": 4761
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4761,
      "Radical": "艸",
      "FrequencyRank": 3503,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qì",
      "PrimaryDefinition": "steam"
    },
    "Keys": {
      "Hanzi": 122,
      "Radical": 84,
      "Word": 922
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "84",
      "Grade": 2,
      "GeneralStandard": 122,
      "Radical": "气",
      "HSK": 1,
      "FrequencyRank": 217,
      "StrokeCount": 4
    }
  },
  "诘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "question, interrogate"
    },
    "Keys": {
      "Hanzi": 4041
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4041,
      "Radical": "言",
      "FrequencyRank": 3405,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "抚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "pat, console, comfort; pacify"
    },
    "Keys": {
      "Hanzi": 613
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.4",
      "GeneralStandard": 613,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 1720,
      "StrokeCount": 7
    }
  },
  "鞭策": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāncè",
      "PrimaryDefinition": "to spur on, to urge on, to encourage sb (e.g. to make progress)"
    },
    "Keys": {
      "Word": 5603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "惶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáng",
      "PrimaryDefinition": "fearful, afraid, anxious, nervous"
    },
    "Keys": {
      "Hanzi": 2775
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2775,
      "Radical": "心",
      "FrequencyRank": 2320,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "蛭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "leech"
    },
    "Keys": {
      "Hanzi": 5144
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5144,
      "Radical": "虫",
      "FrequencyRank": 4257,
      "RadicalIndex": "142.6",
      "StrokeCount": 12
    }
  },
  "潟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "land impregnated with salt from the tide"
    },
    "Keys": {
      "Hanzi": 6003
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6003,
      "Radical": "水",
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "槿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐn",
      "PrimaryDefinition": "hibiscus"
    },
    "Keys": {
      "Hanzi": 5883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5883,
      "Radical": "木",
      "FrequencyRank": 5118,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "镐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎo",
      "PrimaryDefinition": "stove; bright"
    },
    "Keys": {
      "Hanzi": 3257
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3257,
      "Radical": "金",
      "FrequencyRank": 4222,
      "RadicalIndex": "167.1",
      "StrokeCount": 15
    }
  },
  "滓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": "sediment, lees, dregs"
    },
    "Keys": {
      "Hanzi": 5585
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5585,
      "Radical": "水",
      "FrequencyRank": 4522,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "懦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nuò",
      "PrimaryDefinition": "weak, timid, cowardly"
    },
    "Keys": {
      "Hanzi": 3431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3431,
      "Radical": "心",
      "FrequencyRank": 3174,
      "RadicalIndex": "61.14",
      "StrokeCount": 17
    }
  },
  "位": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèi",
      "PrimaryDefinition": "throne; position, post; rank, status; seat"
    },
    "Keys": {
      "Hanzi": 784,
      "Word": 1061
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "Grade": 2,
      "GeneralStandard": 784,
      "Radical": "人",
      "HSK": 2,
      "FrequencyRank": 182,
      "StrokeCount": 7
    }
  },
  "盟友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méngyǒu",
      "PrimaryDefinition": "ally"
    },
    "Keys": {
      "Word": 8248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wāng",
      "PrimaryDefinition": "vast, extensive, deep; surname"
    },
    "Keys": {
      "Hanzi": 855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.4",
      "GeneralStandard": 855,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 1876,
      "StrokeCount": 7
    }
  },
  "应": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīng",
      "PrimaryDefinition": "should, ought to, must"
    },
    "Keys": {
      "Hanzi": 836,
      "Word": 4130
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "53.4",
      "Grade": 5,
      "GeneralStandard": 836,
      "Radical": "广",
      "HSK": 2,
      "FrequencyRank": 144,
      "StrokeCount": 7
    }
  },
  "肴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "cooked or prepared meat"
    },
    "Keys": {
      "Hanzi": 1169
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "GeneralStandard": 1169,
      "Radical": "肉",
      "HSK": 6,
      "FrequencyRank": 3513,
      "StrokeCount": 8
    }
  },
  "争执": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngzhí",
      "PrimaryDefinition": "to dispute, to disagree, to argue opinionatedly, to wrangle"
    },
    "Keys": {
      "Word": 10722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "公安": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngān",
      "PrimaryDefinition": "(Ministry of) Public Security, public safety, public security"
    },
    "Keys": {
      "Word": 4608
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "膂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚ",
      "PrimaryDefinition": "backbone, spinal column"
    },
    "Keys": {
      "Hanzi": 5788
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5788,
      "Radical": "肉",
      "FrequencyRank": 6037,
      "RadicalIndex": "130.1",
      "StrokeCount": 14
    }
  },
  "受害人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòuhàirén",
      "PrimaryDefinition": "victim"
    },
    "Keys": {
      "Word": 9194
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèng",
      "PrimaryDefinition": "gift"
    },
    "Keys": {
      "Hanzi": 7526
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7526,
      "Radical": "貝",
      "FrequencyRank": 7567,
      "RadicalIndex": "154.9",
      "StrokeCount": 13
    }
  },
  "讲究": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngjiu",
      "PrimaryDefinition": "be particular about, pay attention to, stress, strive for, backbite"
    },
    "Keys": {
      "Word": 2607
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "葬礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zànglǐ",
      "PrimaryDefinition": "burial, funeral"
    },
    "Keys": {
      "Word": 10604
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "音响": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīnxiǎng",
      "PrimaryDefinition": "clank, acoustics, music center, sound, hi-fi set, stereo, audio, hi-fi, acoustic, hi-fi stereo component system"
    },
    "Keys": {
      "Word": 10383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "早期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎoqī",
      "PrimaryDefinition": "early period, early phase, early stage"
    },
    "Keys": {
      "Word": 4178
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "资本主义": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zīběnzhǔyì",
      "PrimaryDefinition": "capitalistic, capitalist economy, capitalism"
    },
    "Keys": {
      "Word": 10941
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嗲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5469,
      "Radical": "口",
      "FrequencyRank": 4711,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "不至于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùzhìyú",
      "PrimaryDefinition": "unlikely to go so far as to, not as bad as"
    },
    "Keys": {
      "Word": 4348
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "事后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìhòu",
      "PrimaryDefinition": "after the event, in hindsight, in retrospect"
    },
    "Keys": {
      "Word": 5028
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "唆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suō",
      "PrimaryDefinition": "make mischief, incite, instigate"
    },
    "Keys": {
      "Hanzi": 1917
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1917,
      "Radical": "口",
      "HSK": 6,
      "FrequencyRank": 3654,
      "StrokeCount": 10
    }
  },
  "伤员": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāngyuán",
      "PrimaryDefinition": "wounded person"
    },
    "Keys": {
      "Word": 4993
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "粤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuè",
      "PrimaryDefinition": "Guangdong and Guangxi provinces; initial particle"
    },
    "Keys": {
      "Hanzi": 2700
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "119.6",
      "GeneralStandard": 2700,
      "Radical": "米",
      "HSK": 9,
      "FrequencyRank": 3151,
      "StrokeCount": 12
    }
  },
  "人身": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rénshēn",
      "PrimaryDefinition": "person, personal, human body"
    },
    "Keys": {
      "Word": 8845
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "来年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láinián",
      "PrimaryDefinition": "next year, the coming year"
    },
    "Keys": {
      "Word": 7922
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "加速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāsù",
      "PrimaryDefinition": "quicken, accelerate, expedite"
    },
    "Keys": {
      "Word": 3586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "折射": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhéshè",
      "PrimaryDefinition": "to refract, refraction, to reflect (in the figurative sense: to show the nature of)"
    },
    "Keys": {
      "Word": 10689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "walk rapidly"
    },
    "Keys": {
      "Hanzi": 4830
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4830,
      "Radical": "足",
      "FrequencyRank": 3330,
      "RadicalIndex": "157.4",
      "StrokeCount": 11
    }
  },
  "悉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "know, learn about, comprehend"
    },
    "Keys": {
      "Hanzi": 2351
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "GeneralStandard": 2351,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1428,
      "StrokeCount": 11
    }
  },
  "委托": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěituō",
      "PrimaryDefinition": "to entrust, to trust, to commission"
    },
    "Keys": {
      "Word": 4004
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "挽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎn",
      "PrimaryDefinition": "pull; pull back, draw back"
    },
    "Keys": {
      "Hanzi": 1807,
      "Word": 9655
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.7",
      "Grade": 7,
      "GeneralStandard": 1807,
      "Radical": "手",
      "HSK": 7,
      "FrequencyRank": 2222,
      "StrokeCount": 10
    }
  },
  "他": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tā",
      "PrimaryDefinition": "other, another; he, she, it"
    },
    "Keys": {
      "Hanzi": 277,
      "Word": 355
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "Grade": 1,
      "GeneralStandard": 277,
      "Radical": "人",
      "HSK": 1,
      "FrequencyRank": 10,
      "StrokeCount": 5
    }
  },
  "外头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàitou",
      "PrimaryDefinition": "outside, out"
    },
    "Keys": {
      "Word": 5125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "节水": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié shuǐ",
      "PrimaryDefinition": "to save water"
    },
    "Keys": {
      "Word": 7519
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "镡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "dagger; small sword"
    },
    "Keys": {
      "Hanzi": 6245
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6245,
      "Radical": "金",
      "FrequencyRank": 6591,
      "RadicalIndex": "167.12",
      "StrokeCount": 17
    }
  },
  "拮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jié",
      "PrimaryDefinition": "laboring hard, occupied; pursue"
    },
    "Keys": {
      "Hanzi": 4093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4093,
      "Radical": "手",
      "FrequencyRank": 4536,
      "RadicalIndex": "64.6",
      "StrokeCount": 9
    }
  },
  "学说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéshuō",
      "PrimaryDefinition": "theory, system, philosophy, school of thought, ism, doctrine, doctrinal, hypothesis"
    },
    "Keys": {
      "Word": 10133
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蚊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "mosquito; gnat"
    },
    "Keys": {
      "Hanzi": 1901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "142.4",
      "GeneralStandard": 1901,
      "Radical": "虫",
      "HSK": 8,
      "FrequencyRank": 2940,
      "StrokeCount": 10
    }
  },
  "虚构": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūgòu",
      "PrimaryDefinition": "to make up, fabrication, fictional, imaginary"
    },
    "Keys": {
      "Word": 10090
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "邬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "various place names; surname"
    },
    "Keys": {
      "Hanzi": 3621
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3621,
      "Radical": "邑",
      "FrequencyRank": 5197,
      "RadicalIndex": "163.4",
      "StrokeCount": 6
    }
  },
  "顾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gù",
      "PrimaryDefinition": "look back; look at; look after"
    },
    "Keys": {
      "Hanzi": 1874,
      "Word": 4624
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "181.4",
      "Grade": 6,
      "GeneralStandard": 1874,
      "Radical": "頁",
      "HSK": 2,
      "FrequencyRank": 815,
      "StrokeCount": 10
    }
  },
  "瑗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuàn",
      "PrimaryDefinition": "large ring of fine jade"
    },
    "Keys": {
      "Hanzi": 5343
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5343,
      "Radical": "玉",
      "FrequencyRank": 6181,
      "RadicalIndex": "96.9",
      "StrokeCount": 13
    }
  },
  "物体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wùtǐ",
      "PrimaryDefinition": "object, body, thrust, substance, matter, physical object"
    },
    "Keys": {
      "Word": 9810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "分布": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnbù",
      "PrimaryDefinition": "be distributed (over an area), be scattered"
    },
    "Keys": {
      "Word": 2463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "诛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "execute, kill, put to death; punish"
    },
    "Keys": {
      "Hanzi": 4051
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4051,
      "Radical": "言",
      "FrequencyRank": 3274,
      "RadicalIndex": "149.6",
      "StrokeCount": 8
    }
  },
  "娃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wá",
      "PrimaryDefinition": "baby; doll; pretty girl"
    },
    "Keys": {
      "Hanzi": 1735
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "GeneralStandard": 1735,
      "Radical": "女",
      "HSK": 6,
      "FrequencyRank": 1833,
      "StrokeCount": 9
    }
  },
  "磁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cí",
      "PrimaryDefinition": "magnetic; magnetism; porcelain"
    },
    "Keys": {
      "Hanzi": 3093
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "112.1",
      "GeneralStandard": 3093,
      "Radical": "石",
      "HSK": 7,
      "FrequencyRank": 1771,
      "StrokeCount": 14
    }
  },
  "创作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuàngzuò",
      "PrimaryDefinition": "creative work, creation"
    },
    "Keys": {
      "Word": 1372
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "京剧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngjù",
      "PrimaryDefinition": "Beijing opera, CL:場|场[chang3],出[chu1]"
    },
    "Keys": {
      "Word": 1637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "口音": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒuyīn",
      "PrimaryDefinition": "oral speech sounds (linguistics), voice, accent"
    },
    "Keys": {
      "Word": 7848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "允许": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǔnxǔ",
      "PrimaryDefinition": "permit, allow"
    },
    "Keys": {
      "Word": 5301
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "矍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "look about in fright or alarm"
    },
    "Keys": {
      "Hanzi": 6427
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6427,
      "Radical": "目",
      "FrequencyRank": 5129,
      "RadicalIndex": "109.15",
      "StrokeCount": 20
    }
  },
  "筒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒng",
      "PrimaryDefinition": "thick piece of bamboo; pipe"
    },
    "Keys": {
      "Hanzi": 2685,
      "Word": 9548
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "118.6",
      "Grade": 7,
      "GeneralStandard": 2685,
      "Radical": "竹",
      "HSK": 7,
      "FrequencyRank": 2088,
      "StrokeCount": 12
    }
  },
  "人道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réndào",
      "PrimaryDefinition": "human sympathy"
    },
    "Keys": {
      "Word": 8837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "起飞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐfēi",
      "PrimaryDefinition": "take off (of planes/etc.)"
    },
    "Keys": {
      "Word": 921
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "窀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūn",
      "PrimaryDefinition": "to bury"
    },
    "Keys": {
      "Hanzi": 6901
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6901,
      "Radical": "穴",
      "FrequencyRank": 5718,
      "RadicalIndex": "116.4",
      "StrokeCount": 9
    }
  },
  "力求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìqiú",
      "PrimaryDefinition": "strive for, make every effort to, do one's best to, strive to"
    },
    "Keys": {
      "Word": 7991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "煦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "kind, gentle, gracious, genial"
    },
    "Keys": {
      "Hanzi": 5448
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5448,
      "Radical": "火",
      "FrequencyRank": 4124,
      "RadicalIndex": "86.9",
      "StrokeCount": 13
    }
  },
  "塝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàng",
      "PrimaryDefinition": "flat bank, plateau"
    },
    "Keys": {
      "Hanzi": 7481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7481,
      "Radical": "土",
      "RadicalIndex": "32.1",
      "StrokeCount": 13
    }
  },
  "道德": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàodé",
      "PrimaryDefinition": "value orientation, value-system, moral, code, moral principle, morals, morale, ethical motive, ethic, virtue, morality_play, morality, ethics, virtuousness, moral excellence"
    },
    "Keys": {
      "Word": 3407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "饫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "surfeited, satiated; confer"
    },
    "Keys": {
      "Hanzi": 3753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3753,
      "Radical": "食",
      "FrequencyRank": 5141,
      "RadicalIndex": "184.4",
      "StrokeCount": 7
    }
  },
  "阼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuò",
      "PrimaryDefinition": "the steps leading to the eastern door"
    },
    "Keys": {
      "Hanzi": 6634
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6634,
      "Radical": "阜",
      "FrequencyRank": 6676,
      "RadicalIndex": "170.5",
      "StrokeCount": 7
    }
  },
  "幞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "turban"
    },
    "Keys": {
      "Hanzi": 5939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5939,
      "Radical": "巾",
      "FrequencyRank": 6709,
      "RadicalIndex": "50.12",
      "StrokeCount": 15
    }
  },
  "亮点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàngdiǎn",
      "PrimaryDefinition": "highlight, bright spot"
    },
    "Keys": {
      "Word": 8036
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "别看": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biékàn",
      "PrimaryDefinition": "Don't look at"
    },
    "Keys": {
      "Word": 5637
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "curtain, screen, tent"
    },
    "Keys": {
      "Hanzi": 2854,
      "Word": 8361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.11",
      "Grade": 7,
      "GeneralStandard": 2854,
      "Radical": "巾",
      "HSK": 5,
      "FrequencyRank": 1315,
      "StrokeCount": 13
    }
  },
  "时机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíjī",
      "PrimaryDefinition": "moment, day, an occasion, occasion, go, an opportune moment, opportunity, conjunction, conjuncture, juncture, a suitable (or right) time, turn, opportune moment"
    },
    "Keys": {
      "Word": 3901
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "人体": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "réntǐ",
      "PrimaryDefinition": "soma, form, body, build, human body, anatomy, figure, chassis, physique, bod, carcass, shape, frame, physical body, material body, flesh, anthropometry"
    },
    "Keys": {
      "Word": 8848
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "臀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tún",
      "PrimaryDefinition": "buttocks"
    },
    "Keys": {
      "Hanzi": 3433
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3433,
      "Radical": "肉",
      "FrequencyRank": 3199,
      "RadicalIndex": "130.13",
      "StrokeCount": 17
    }
  },
  "由此可见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóucǐ-kějiàn",
      "PrimaryDefinition": "from this, it can be seen that..."
    },
    "Keys": {
      "Word": 10460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "少有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎoyǒu",
      "PrimaryDefinition": "rare, infrequent"
    },
    "Keys": {
      "Word": 9000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "流向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúxiàng",
      "PrimaryDefinition": "Flow"
    },
    "Keys": {
      "Word": 8106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "眠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mián",
      "PrimaryDefinition": "close eyes, sleep; hibernate"
    },
    "Keys": {
      "Hanzi": 1888
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "109.5",
      "GeneralStandard": 1888,
      "Radical": "目",
      "HSK": 5,
      "FrequencyRank": 1986,
      "StrokeCount": 10
    }
  },
  "异口同声": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìkǒu-tóngshēng",
      "PrimaryDefinition": "different mouths, same voice, to speak in unison (idiom)"
    },
    "Keys": {
      "Word": 10365
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "峰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "peak, summit; hump of camel"
    },
    "Keys": {
      "Hanzi": 1921
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "46.7",
      "GeneralStandard": 1921,
      "Radical": "山",
      "HSK": 6,
      "FrequencyRank": 1473,
      "StrokeCount": 10
    }
  },
  "宣告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuāngào",
      "PrimaryDefinition": "declare, proclaim"
    },
    "Keys": {
      "Word": 10109
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèng",
      "PrimaryDefinition": "wages, salary, official emolument"
    },
    "Keys": {
      "Hanzi": 4561
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4561,
      "Radical": "人",
      "FrequencyRank": 3910,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "馐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiū",
      "PrimaryDefinition": "food, meal; eat; offer"
    },
    "Keys": {
      "Hanzi": 5537
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5537,
      "Radical": "食",
      "FrequencyRank": 4223,
      "RadicalIndex": "184.11",
      "StrokeCount": 13
    }
  },
  "清明节": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "Qīngmíngjié",
      "PrimaryDefinition": "Qingming or Pure Brightness Festival or Tomb Sweeping Day, celebration for the dead (in early April)"
    },
    "Keys": {
      "Word": 4947
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "复查": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùchá",
      "PrimaryDefinition": "rechecking, re-inspection, reexamination"
    },
    "Keys": {
      "Word": 6631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "遇见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù jian",
      "PrimaryDefinition": "to meet"
    },
    "Keys": {
      "Word": 3129
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "玻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "glass"
    },
    "Keys": {
      "Hanzi": 1361
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.5",
      "GeneralStandard": 1361,
      "Radical": "玉",
      "HSK": 5,
      "FrequencyRank": 1769,
      "StrokeCount": 9
    }
  },
  "荙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": "plantago major"
    },
    "Keys": {
      "Hanzi": 6785
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6785,
      "Radical": "艸",
      "FrequencyRank": 9900,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "地点": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìdiǎn",
      "PrimaryDefinition": "location, site, locale, locality, topographic point, point, venue, spot, situs, land site, place, locus"
    },
    "Keys": {
      "Word": 67
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "娄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóu",
      "PrimaryDefinition": "surname; a constellation; to wear"
    },
    "Keys": {
      "Hanzi": 1649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1649,
      "Radical": "女",
      "FrequencyRank": 4052,
      "RadicalIndex": "38.6",
      "StrokeCount": 9
    }
  },
  "俺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎn",
      "PrimaryDefinition": "personal pronoun, I"
    },
    "Keys": {
      "Hanzi": 1958
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1958,
      "Radical": "人",
      "FrequencyRank": 2749,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "得罪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dézui",
      "PrimaryDefinition": "disoblige, offend, displease, affront"
    },
    "Keys": {
      "Word": 6217
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēng",
      "PrimaryDefinition": "maple tree"
    },
    "Keys": {
      "Hanzi": 1028
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1028,
      "Radical": "木",
      "FrequencyRank": 3229,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "阖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hé",
      "PrimaryDefinition": "close; whole, entire; all; leaf"
    },
    "Keys": {
      "Hanzi": 5552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5552,
      "Radical": "門",
      "FrequencyRank": 4228,
      "RadicalIndex": "169.1",
      "StrokeCount": 13
    }
  },
  "分手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēn shǒu",
      "PrimaryDefinition": "part, part company, separate, say good-bye, break up"
    },
    "Keys": {
      "Word": 2464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "在职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zàizhí",
      "PrimaryDefinition": "be on the job"
    },
    "Keys": {
      "Word": 10594
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "飐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǎn",
      "PrimaryDefinition": "sway in wind"
    },
    "Keys": {
      "Hanzi": 6872
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6872,
      "Radical": "風",
      "FrequencyRank": 8370,
      "RadicalIndex": "182.5",
      "StrokeCount": 9
    }
  },
  "芘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6573
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6573,
      "Radical": "艸",
      "FrequencyRank": 6550,
      "RadicalIndex": "140.4",
      "StrokeCount": 7
    }
  },
  "哺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǔ",
      "PrimaryDefinition": "chew food; feed"
    },
    "Keys": {
      "Hanzi": 1894
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.7",
      "GeneralStandard": 1894,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2810,
      "StrokeCount": 10
    }
  },
  "密封": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìfēng",
      "PrimaryDefinition": "to seal up"
    },
    "Keys": {
      "Word": 8267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鸣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "cry of bird or animal; make sound"
    },
    "Keys": {
      "Hanzi": 1100
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1100,
      "Radical": "口",
      "HSK": 8,
      "FrequencyRank": 1680,
      "StrokeCount": 8
    }
  },
  "道具": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàojù",
      "PrimaryDefinition": "stage props"
    },
    "Keys": {
      "Word": 6205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "恳求": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěnqiú",
      "PrimaryDefinition": "to beg, to beseech, to entreat, entreaty"
    },
    "Keys": {
      "Word": 7817
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "启事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐshì",
      "PrimaryDefinition": "announcement (written, on billboard, letter, newspaper or website), to post information, a notice"
    },
    "Keys": {
      "Word": 3791
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "企图": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐtú",
      "PrimaryDefinition": "attempt, intend"
    },
    "Keys": {
      "Word": 4919
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "堤坝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dībà",
      "PrimaryDefinition": "dam"
    },
    "Keys": {
      "Word": 6234
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "感性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnxìng",
      "PrimaryDefinition": "sensitive"
    },
    "Keys": {
      "Word": 6681
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "袪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qū",
      "PrimaryDefinition": "sleeves; cuff"
    },
    "Keys": {
      "Hanzi": 7077
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7077,
      "Radical": "衣",
      "FrequencyRank": 6732,
      "RadicalIndex": "145.5",
      "StrokeCount": 10
    }
  },
  "梣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cén",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7140,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "洿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "stagnant water; impure, filthy"
    },
    "Keys": {
      "Hanzi": 6883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6883,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "鍪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móu",
      "PrimaryDefinition": "an iron pan; a metal cap"
    },
    "Keys": {
      "Hanzi": 6309
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6309,
      "Radical": "金",
      "FrequencyRank": 4735,
      "RadicalIndex": "167.9",
      "StrokeCount": 17
    }
  },
  "峋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "ranges of hills stretching on beyond another irregular peaks"
    },
    "Keys": {
      "Hanzi": 4236
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4236,
      "Radical": "山",
      "FrequencyRank": 4478,
      "RadicalIndex": "46.6",
      "StrokeCount": 9
    }
  },
  "自以为是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìyǐwéishì",
      "PrimaryDefinition": "to believe oneself infallible (idiom), to be opinionated"
    },
    "Keys": {
      "Word": 10969
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扑面而来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pūmiànérlái",
      "PrimaryDefinition": "lit. sth hits one in the face, directly in one's face, sth assaults the senses, blatant (advertising), eye catching, (a smell) assaults the nostrils"
    },
    "Keys": {
      "Word": 8577
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "crack, chap"
    },
    "Keys": {
      "Hanzi": 4997
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4997,
      "Radical": "皮",
      "FrequencyRank": 6122,
      "RadicalIndex": "107.6",
      "StrokeCount": 11
    }
  },
  "爔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8065
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8065,
      "Radical": "火",
      "RadicalIndex": "86.16",
      "StrokeCount": 20
    }
  },
  "近期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìnqī",
      "PrimaryDefinition": "near in time, in the near future, very soon, recent"
    },
    "Keys": {
      "Word": 1635
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "询问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xúnwèn",
      "PrimaryDefinition": "ask about, inquire"
    },
    "Keys": {
      "Word": 4084
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "山川": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānchuān",
      "PrimaryDefinition": "Mountainous"
    },
    "Keys": {
      "Word": 8944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "召集": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàojí",
      "PrimaryDefinition": "call together, convene"
    },
    "Keys": {
      "Word": 10674
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "钼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù",
      "PrimaryDefinition": "molybdenum"
    },
    "Keys": {
      "Hanzi": 4534
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4534,
      "Radical": "金",
      "FrequencyRank": 5522,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "蛋白质": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dànbáizhì",
      "PrimaryDefinition": "albuminoid, proteide, proteid, albumin, albumen, protein"
    },
    "Keys": {
      "Word": 6171
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乐曲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuèqǔ",
      "PrimaryDefinition": "composition, piece of music, music, musical composition, piece, number, opus"
    },
    "Keys": {
      "Word": 5300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "坚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "hard, strong, firm; resolute"
    },
    "Keys": {
      "Hanzi": 712
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.4",
      "GeneralStandard": 712,
      "Radical": "土",
      "HSK": 3,
      "FrequencyRank": 748,
      "StrokeCount": 7
    }
  },
  "图形": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túxíng",
      "PrimaryDefinition": "motif, delineation, pattern, picture, fig, icon, graphical, graph, design, figure, sketch, construct, diagram, drawing"
    },
    "Keys": {
      "Word": 9580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辅导": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔdǎo",
      "PrimaryDefinition": "coach, tutor"
    },
    "Keys": {
      "Word": 6616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fān",
      "PrimaryDefinition": "sail; boat"
    },
    "Keys": {
      "Hanzi": 437,
      "Word": 6453
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "50.3",
      "Grade": 7,
      "GeneralStandard": 437,
      "Radical": "巾",
      "HSK": 8,
      "FrequencyRank": 2459,
      "StrokeCount": 6
    }
  },
  "蒈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎi",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 7308
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7308,
      "Radical": "艸",
      "FrequencyRank": 8999,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "援": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "aid, assist; lead; cite"
    },
    "Keys": {
      "Hanzi": 2530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.9",
      "GeneralStandard": 2530,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1197,
      "StrokeCount": 12
    }
  },
  "槲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hú",
      "PrimaryDefinition": "type of oak"
    },
    "Keys": {
      "Hanzi": 5889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5889,
      "Radical": "木",
      "FrequencyRank": 5422,
      "RadicalIndex": "75.11",
      "StrokeCount": 15
    }
  },
  "就业": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiù yè",
      "PrimaryDefinition": "get or take up a job"
    },
    "Keys": {
      "Word": 1650
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "懊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ào",
      "PrimaryDefinition": "vexed, worried, nervous; regret"
    },
    "Keys": {
      "Hanzi": 3302
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3302,
      "Radical": "心",
      "FrequencyRank": 3212,
      "RadicalIndex": "61.13",
      "StrokeCount": 15
    }
  },
  "开学": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi xué",
      "PrimaryDefinition": "start school, begin term"
    },
    "Keys": {
      "Word": 809
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "卤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǔ",
      "PrimaryDefinition": "salty"
    },
    "Keys": {
      "Radical": 197,
      "Hanzi": 711
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "25.5",
      "GeneralStandard": 711,
      "Radical": "卜",
      "HSK": 9,
      "FrequencyRank": 3747,
      "StrokeCount": 7
    }
  },
  "敬礼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìng lǐ",
      "PrimaryDefinition": "to salute, salute"
    },
    "Keys": {
      "Word": 7644
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "有关": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒuguān",
      "PrimaryDefinition": "be related"
    },
    "Keys": {
      "Word": 5275
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "心情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnqíng",
      "PrimaryDefinition": "cognitive state, cue, frame of mind, form, stomach, pulse, humor, feeling, temper, state of mind, cheer, humour, frame/state of mind, mood, vein, spirit, feelings, frame, frame_of_mind, habit of mind, mind, tune"
    },
    "Keys": {
      "Word": 1102
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "丰满": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngmǎn",
      "PrimaryDefinition": "Fengman district of Jilin city 吉林市, Jilin province, ample, well developed, fully rounded"
    },
    "Keys": {
      "Word": 6561
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鲉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóu",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7557
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7557,
      "Radical": "魚",
      "FrequencyRank": 6882,
      "RadicalIndex": "195.5",
      "StrokeCount": 13
    }
  },
  "临近": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "línjìn",
      "PrimaryDefinition": "close to, approaching"
    },
    "Keys": {
      "Word": 8061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凄凉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīliáng",
      "PrimaryDefinition": "desolate (place)"
    },
    "Keys": {
      "Word": 8588
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "书写": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūxiě",
      "PrimaryDefinition": "write"
    },
    "Keys": {
      "Word": 9210
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奇妙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qímiào",
      "PrimaryDefinition": "wonderful, marvelous"
    },
    "Keys": {
      "Word": 4916
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "氍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qú",
      "PrimaryDefinition": "fine woollen cloth; a mat used by the emperor in worshipping god"
    },
    "Keys": {
      "Hanzi": 6469
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6469,
      "Radical": "毛",
      "FrequencyRank": 7576,
      "RadicalIndex": "82.18",
      "StrokeCount": 22
    }
  },
  "咺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuǎn",
      "PrimaryDefinition": "majestic"
    },
    "Keys": {
      "Hanzi": 6824
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6824,
      "Radical": "口",
      "RadicalIndex": "30.6",
      "StrokeCount": 9
    }
  },
  "全新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánxīn",
      "PrimaryDefinition": "all new, completely new"
    },
    "Keys": {
      "Word": 4962
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "校园": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàoyuán",
      "PrimaryDefinition": "schoolyard, campus"
    },
    "Keys": {
      "Word": 1097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "询": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xún",
      "PrimaryDefinition": "inquire into, ask about; consult"
    },
    "Keys": {
      "Hanzi": 1299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.6",
      "GeneralStandard": 1299,
      "Radical": "言",
      "HSK": 5,
      "FrequencyRank": 1617,
      "StrokeCount": 8
    }
  },
  "恩惠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ēnhuì",
      "PrimaryDefinition": "favor, grace"
    },
    "Keys": {
      "Word": 6420
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "逢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "féng",
      "PrimaryDefinition": "come upon, happen meet; flatter"
    },
    "Keys": {
      "Hanzi": 2007,
      "Word": 6591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.7",
      "Grade": 7,
      "GeneralStandard": 2007,
      "Radical": "辵",
      "HSK": 8,
      "FrequencyRank": 2181,
      "StrokeCount": 10
    }
  },
  "热腾腾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rèténgténg",
      "PrimaryDefinition": "Fever"
    },
    "Keys": {
      "Word": 8834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "奓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhā",
      "PrimaryDefinition": "extravagant"
    },
    "Keys": {
      "Hanzi": 6813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6813,
      "Radical": "大",
      "RadicalIndex": "37.6",
      "StrokeCount": 9
    }
  },
  "沂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "river in southeast Shandong"
    },
    "Keys": {
      "Hanzi": 3775
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3775,
      "Radical": "水",
      "FrequencyRank": 3787,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "风筝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngzheng",
      "PrimaryDefinition": "kite"
    },
    "Keys": {
      "Word": 6583
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "麓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "foot of hill; foothill"
    },
    "Keys": {
      "Hanzi": 6374
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6374,
      "Radical": "鹿",
      "FrequencyRank": 3583,
      "RadicalIndex": "198.8",
      "StrokeCount": 19
    }
  },
  "洴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píng",
      "PrimaryDefinition": "sound"
    },
    "Keys": {
      "Hanzi": 6897
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6897,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "过剩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòshèng",
      "PrimaryDefinition": "surplus, excess"
    },
    "Keys": {
      "Word": 6951
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外界": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàijiè",
      "PrimaryDefinition": "external world, external/outside world, the outside world, outside, environment, outside world, externality, external, the external world"
    },
    "Keys": {
      "Word": 3994
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "题材": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tícái",
      "PrimaryDefinition": "subject_matter, theme, material, content, subject, subject matter, depicted object"
    },
    "Keys": {
      "Word": 3975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "瓜分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guāfēn",
      "PrimaryDefinition": "to partition, to divide up"
    },
    "Keys": {
      "Word": 6850
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": "silent, quiet, still; anxious"
    },
    "Keys": {
      "Hanzi": 2092
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.7",
      "GeneralStandard": 2092,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1434,
      "StrokeCount": 10
    }
  },
  "手腕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒuwàn",
      "PrimaryDefinition": "wrist"
    },
    "Keys": {
      "Word": 9180
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "帻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "turban; conical cap"
    },
    "Keys": {
      "Hanzi": 4855
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4855,
      "Radical": "巾",
      "FrequencyRank": 6563,
      "RadicalIndex": "50.8",
      "StrokeCount": 11
    }
  },
  "弭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǐ",
      "PrimaryDefinition": "stop, desist, end, quell"
    },
    "Keys": {
      "Hanzi": 4377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4377,
      "Radical": "弓",
      "FrequencyRank": 4871,
      "RadicalIndex": "57.6",
      "StrokeCount": 9
    }
  },
  "流程": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúchéng",
      "PrimaryDefinition": "workflow, course (of river, technological process, process, driftage, manufacturing/technological process/workflow, etc.), course (of river/etc.), course, manufacturing, manufacturing process, technological workflow, circuit, flow"
    },
    "Keys": {
      "Word": 8097
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāo",
      "PrimaryDefinition": "long narrow knife-shaped boat"
    },
    "Keys": {
      "Hanzi": 6705
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6705,
      "Radical": "舟",
      "RadicalIndex": "137.2",
      "StrokeCount": 8
    }
  },
  "楯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "shield; horizontal bar"
    },
    "Keys": {
      "Hanzi": 5400
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5400,
      "Radical": "木",
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "常年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chángnián",
      "PrimaryDefinition": "all year round, for years on end, average year"
    },
    "Keys": {
      "Word": 4393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "付费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù fèi",
      "PrimaryDefinition": "fee, expense, pay"
    },
    "Keys": {
      "Word": 6622
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "袁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "robe; surname"
    },
    "Keys": {
      "Hanzi": 1799
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "145.4",
      "GeneralStandard": 1799,
      "Radical": "衣",
      "HSK": 9,
      "FrequencyRank": 1755,
      "StrokeCount": 10
    }
  },
  "裁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cái",
      "PrimaryDefinition": "cut out; decrease"
    },
    "Keys": {
      "Hanzi": 2532,
      "Word": 5739
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "145.6",
      "Grade": 7,
      "GeneralStandard": 2532,
      "Radical": "衣",
      "HSK": 5,
      "FrequencyRank": 1203,
      "StrokeCount": 12
    }
  },
  "偌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruò",
      "PrimaryDefinition": "thus, so, like, such"
    },
    "Keys": {
      "Hanzi": 4564
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4564,
      "Radical": "人",
      "FrequencyRank": 3985,
      "RadicalIndex": "9.9",
      "StrokeCount": 10
    }
  },
  "瘛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5982
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5982,
      "Radical": "疒",
      "FrequencyRank": 5042,
      "RadicalIndex": "104.1",
      "StrokeCount": 15
    }
  },
  "订单": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìngdān",
      "PrimaryDefinition": "order_form, order, order form, order form for goods, purchase order, indent"
    },
    "Keys": {
      "Word": 6305
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "现在": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànzài",
      "PrimaryDefinition": "now, at present, at the moment, modern, current, nowadays"
    },
    "Keys": {
      "Word": 397
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "药片": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàopiàn",
      "PrimaryDefinition": "a (medicine) pill or tablet, CL:片[pian4]"
    },
    "Keys": {
      "Word": 1128
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "髯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rán",
      "PrimaryDefinition": "beard; mustache"
    },
    "Keys": {
      "Hanzi": 5859
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5859,
      "Radical": "髟",
      "FrequencyRank": 4017,
      "RadicalIndex": "190.5",
      "StrokeCount": 15
    }
  },
  "螈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuán",
      "PrimaryDefinition": "a silkworm"
    },
    "Keys": {
      "Hanzi": 6099
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6099,
      "Radical": "虫",
      "FrequencyRank": 5393,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "参军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cān jūn",
      "PrimaryDefinition": "to join the army"
    },
    "Keys": {
      "Word": 5752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "四面八方": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìmiàn-bāfāng",
      "PrimaryDefinition": "in all directions, all around, far and near"
    },
    "Keys": {
      "Word": 9324
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "适度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìdù",
      "PrimaryDefinition": "moderate"
    },
    "Keys": {
      "Word": 9156
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "届时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jièshí",
      "PrimaryDefinition": "at appointed time, on the occasion"
    },
    "Keys": {
      "Word": 7551
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "领袖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐngxiù",
      "PrimaryDefinition": "foreman, leader, head, guru, cob, doyen, cacique, Duce, duce, gaffer, chief, boss, soul, sovereign, honcho"
    },
    "Keys": {
      "Word": 4834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "酯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐ",
      "PrimaryDefinition": "ester"
    },
    "Keys": {
      "Hanzi": 5414
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5414,
      "Radical": "酉",
      "FrequencyRank": 4412,
      "RadicalIndex": "164.6",
      "StrokeCount": 13
    }
  },
  "仗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàng",
      "PrimaryDefinition": "rely upon; protector; fight; war, weaponry"
    },
    "Keys": {
      "Hanzi": 270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.3",
      "GeneralStandard": 270,
      "Radical": "人",
      "HSK": 7,
      "FrequencyRank": 1712,
      "StrokeCount": 5
    }
  },
  "卧室": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wòshì",
      "PrimaryDefinition": "bedroom, CL:間|间[jian1]"
    },
    "Keys": {
      "Word": 4014
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "劐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huō",
      "PrimaryDefinition": "destroy"
    },
    "Keys": {
      "Hanzi": 7755
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7755,
      "Radical": "刀",
      "FrequencyRank": 7332,
      "RadicalIndex": "18.14",
      "StrokeCount": 15
    }
  },
  "倴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7014,
      "Radical": "人",
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "幌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎng",
      "PrimaryDefinition": "curtain, cloth screen"
    },
    "Keys": {
      "Hanzi": 2935
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2935,
      "Radical": "巾",
      "FrequencyRank": 3243,
      "RadicalIndex": "50.1",
      "StrokeCount": 13
    }
  },
  "难过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánguò",
      "PrimaryDefinition": "uncomfortable, feel bad, feel sorry, have hard time, bad, sad, have a hard time, be grieved, sorry"
    },
    "Keys": {
      "Word": 885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "呷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gā",
      "PrimaryDefinition": "suck, swallow, drink"
    },
    "Keys": {
      "Hanzi": 3912
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3912,
      "Radical": "口",
      "FrequencyRank": 4071,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "快点儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuàidiǎnr5",
      "PrimaryDefinition": "to do sth more quickly, erhua variant of 快點|快点[kuai4 dian3]"
    },
    "Keys": {
      "Word": 822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "抱负": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàofù",
      "PrimaryDefinition": "aspiration, ambition"
    },
    "Keys": {
      "Word": 5538
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēi",
      "PrimaryDefinition": "high, lofty, majestic, eminent"
    },
    "Keys": {
      "Hanzi": 3481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3481,
      "Radical": "山",
      "FrequencyRank": 3201,
      "RadicalIndex": "46.18",
      "StrokeCount": 20
    }
  },
  "俍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liáng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6858
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6858,
      "Radical": "人",
      "RadicalIndex": "9.7",
      "StrokeCount": 9
    }
  },
  "主力": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǔlì",
      "PrimaryDefinition": "brunt, main force, backbone"
    },
    "Keys": {
      "Word": 10853
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "茵": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīn",
      "PrimaryDefinition": "a cushion, mattress; wormwood; Skimmia japon"
    },
    "Keys": {
      "Hanzi": 1410
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1410,
      "Radical": "艸",
      "FrequencyRank": 2765,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "垚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáo",
      "PrimaryDefinition": "mound, roundish mass"
    },
    "Keys": {
      "Hanzi": 6767
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6767,
      "Radical": "土",
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "旌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīng",
      "PrimaryDefinition": "banner or flag adorned with feathers; to signal"
    },
    "Keys": {
      "Hanzi": 4942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4942,
      "Radical": "方",
      "FrequencyRank": 4214,
      "RadicalIndex": "70.7",
      "StrokeCount": 11
    }
  },
  "枘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ruì",
      "PrimaryDefinition": "the handle of tools"
    },
    "Keys": {
      "Hanzi": 6665
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6665,
      "Radical": "木",
      "FrequencyRank": 6312,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "精彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngcǎi",
      "PrimaryDefinition": "brilliant, splendid"
    },
    "Keys": {
      "Word": 1642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "笸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pǒ",
      "PrimaryDefinition": "flat basket for grain"
    },
    "Keys": {
      "Hanzi": 4895
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4895,
      "Radical": "竹",
      "FrequencyRank": 5329,
      "RadicalIndex": "118.5",
      "StrokeCount": 11
    }
  },
  "栓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuān",
      "PrimaryDefinition": "wooden peg, post or stick"
    },
    "Keys": {
      "Hanzi": 1843
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "75.6",
      "GeneralStandard": 1843,
      "Radical": "木",
      "HSK": 9,
      "FrequencyRank": 2938,
      "StrokeCount": 10
    }
  },
  "公务": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngwù",
      "PrimaryDefinition": "official business"
    },
    "Keys": {
      "Word": 6776
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "歪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wāi",
      "PrimaryDefinition": "slant; inclined; askewd, awry"
    },
    "Keys": {
      "Hanzi": 1445,
      "Word": 9632
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "77.5",
      "Grade": 7,
      "GeneralStandard": 1445,
      "Radical": "止",
      "HSK": 7,
      "FrequencyRank": 2239,
      "StrokeCount": 9
    }
  },
  "走后门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǒu hòumén",
      "PrimaryDefinition": "lit. to enter by the back door, fig. to gain influence by pull or unofficial channels, back door or under the counter connections"
    },
    "Keys": {
      "Word": 10998
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乐意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèyì",
      "PrimaryDefinition": "to be willing to do sth, to be ready to do sth, to be happy to do sth, content, satisfied"
    },
    "Keys": {
      "Word": 7956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "工作日": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngzuòrì",
      "PrimaryDefinition": "workday, working day, weekday"
    },
    "Keys": {
      "Word": 3498
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "眩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuàn",
      "PrimaryDefinition": "to confuse; dizzy, dazed, disoriented"
    },
    "Keys": {
      "Hanzi": 4496
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4496,
      "Radical": "目",
      "FrequencyRank": 2909,
      "RadicalIndex": "109.5",
      "StrokeCount": 10
    }
  },
  "现状": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànzhuàng",
      "PrimaryDefinition": "circumstance, present conditions, actuality, current status, present situation, status_quo, status quo"
    },
    "Keys": {
      "Word": 4035
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "大人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàren",
      "PrimaryDefinition": "grownup, adult, milord, respectful salutation for one's seniors/, grown-up, respectful salutation for one's parents, Your Excellency, panjandrum, Highness, respectful salutation for one's parents/seniors/etc., Tuan, Bahadur, bahadur, sahib"
    },
    "Keys": {
      "Word": 592
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "温泉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wēnquán",
      "PrimaryDefinition": "hot_spring, watering_place, thermae, thermal spring, hot spring"
    },
    "Keys": {
      "Word": 9727
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "富人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùrén",
      "PrimaryDefinition": "rich man"
    },
    "Keys": {
      "Word": 4578
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "募捐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mù juān",
      "PrimaryDefinition": "to solicit contributions, to collect donations"
    },
    "Keys": {
      "Word": 8354
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "支持": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhīchí",
      "PrimaryDefinition": "sustain, hold out, bear;support, back, stand by"
    },
    "Keys": {
      "Word": 2155
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "拆除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāichú",
      "PrimaryDefinition": "demolish, remove"
    },
    "Keys": {
      "Word": 3307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "哪儿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǎr",
      "PrimaryDefinition": "where"
    },
    "Keys": {
      "Word": 248
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "鼓动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔdòng",
      "PrimaryDefinition": "to agitate, to arouse, to instigate, to encite"
    },
    "Keys": {
      "Word": 6837
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "却是": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quèshì",
      "PrimaryDefinition": "nevertheless, actually, the fact is ..."
    },
    "Keys": {
      "Word": 4972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "轿车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàochē",
      "PrimaryDefinition": "(horse-drawn) carriage, saloon, passenger car, car, limousine, carriage, coach, bus, sedan"
    },
    "Keys": {
      "Word": 7489
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "揠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "to pull up, to eradicate"
    },
    "Keys": {
      "Hanzi": 5063
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5063,
      "Radical": "手",
      "FrequencyRank": 6779,
      "RadicalIndex": "64.9",
      "StrokeCount": 12
    }
  },
  "乙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐ",
      "PrimaryDefinition": "second"
    },
    "Keys": {
      "Hanzi": 2,
      "Radical": 5,
      "Word": 4112
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "5",
      "Grade": 5,
      "GeneralStandard": 2,
      "Radical": "乙",
      "HSK": 5,
      "FrequencyRank": 1872,
      "StrokeCount": 1
    }
  },
  "舐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "lick with tongue"
    },
    "Keys": {
      "Hanzi": 4552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4552,
      "Radical": "舌",
      "FrequencyRank": 4344,
      "RadicalIndex": "135.4",
      "StrokeCount": 10
    }
  },
  "忿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèn",
      "PrimaryDefinition": "get angry; fury, exasperation"
    },
    "Keys": {
      "Hanzi": 1179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 1179,
      "Radical": "心",
      "FrequencyRank": 3145,
      "RadicalIndex": "61.4",
      "StrokeCount": 8
    }
  },
  "字典": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìdiǎn",
      "PrimaryDefinition": "lexicon, wordbook, lexical, thesaurus, chararter dictionary, dictionary"
    },
    "Keys": {
      "Word": 1241
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "往返": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎngfǎn",
      "PrimaryDefinition": "to go back and forth, to go to and fro, round trip"
    },
    "Keys": {
      "Word": 9672
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "gather up, collect; edit, compile"
    },
    "Keys": {
      "Hanzi": 2889
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.9",
      "GeneralStandard": 2889,
      "Radical": "車",
      "HSK": 5,
      "FrequencyRank": 1362,
      "StrokeCount": 13
    }
  },
  "沌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "chaotic, confused; turbid, murky"
    },
    "Keys": {
      "Hanzi": 3770
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3770,
      "Radical": "水",
      "FrequencyRank": 3309,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "跞": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "walk, move"
    },
    "Keys": {
      "Hanzi": 5137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5137,
      "Radical": "足",
      "FrequencyRank": 5301,
      "RadicalIndex": "157.5",
      "StrokeCount": 12
    }
  },
  "错": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuò",
      "PrimaryDefinition": "error, blunder, mistake; wrong"
    },
    "Keys": {
      "Hanzi": 2936,
      "Word": 54
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "167.8",
      "Grade": 1,
      "GeneralStandard": 2936,
      "Radical": "金",
      "HSK": 1,
      "FrequencyRank": 638,
      "StrokeCount": 13
    }
  },
  "天生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānshēng",
      "PrimaryDefinition": "inbred, born"
    },
    "Keys": {
      "Word": 9482
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "清真寺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngzhēnsì",
      "PrimaryDefinition": "mosque"
    },
    "Keys": {
      "Word": 8752
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "愈合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùhé",
      "PrimaryDefinition": "to heal, to fuse"
    },
    "Keys": {
      "Word": 10532
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fáng",
      "PrimaryDefinition": "animal fat"
    },
    "Keys": {
      "Hanzi": 1188
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.4",
      "GeneralStandard": 1188,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 3005,
      "StrokeCount": 8
    }
  },
  "挂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guà",
      "PrimaryDefinition": "hang, suspend; suspense"
    },
    "Keys": {
      "Hanzi": 1365,
      "Word": 1519
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.6",
      "Grade": 3,
      "GeneralStandard": 1365,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 1232,
      "StrokeCount": 9
    }
  },
  "叫板": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàobǎn",
      "PrimaryDefinition": "to signal the musicians (in Chinese opera, by prolonging a spoken word before attacking a song), (coll.) to challenge"
    },
    "Keys": {
      "Word": 7487
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生硬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngyìng",
      "PrimaryDefinition": "stiff, harsh"
    },
    "Keys": {
      "Word": 9063
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妈妈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "māma",
      "PrimaryDefinition": "mummy, mammy, ma, mother, mamma, mum, mommy, female parent, mom, Mama, mater, mama, momma"
    },
    "Keys": {
      "Word": 220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "酝酿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnniàng",
      "PrimaryDefinition": "(of alcohol) to ferment, (of a crisis) to be brewing, to mull over (an issue), to hold exploratory discussions"
    },
    "Keys": {
      "Word": 10579
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "橑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7846
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7846,
      "Radical": "木",
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "激化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīhuà",
      "PrimaryDefinition": "intensify"
    },
    "Keys": {
      "Word": 7281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "枋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāng",
      "PrimaryDefinition": "sandalwood; tree used as timber"
    },
    "Keys": {
      "Hanzi": 3882
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3882,
      "Radical": "木",
      "FrequencyRank": 5260,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "焗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jú",
      "PrimaryDefinition": "(Cant.) to roast, bake; to suffocate, stuffy"
    },
    "Keys": {
      "Hanzi": 4962
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4962,
      "Radical": "火",
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "嗪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qín",
      "PrimaryDefinition": "character used in translation"
    },
    "Keys": {
      "Hanzi": 5432
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5432,
      "Radical": "口",
      "FrequencyRank": 4742,
      "RadicalIndex": "30.1",
      "StrokeCount": 13
    }
  },
  "砣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuó",
      "PrimaryDefinition": "a stone roller; a heavy stone, a weight, a plummet"
    },
    "Keys": {
      "Hanzi": 4481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4481,
      "Radical": "石",
      "FrequencyRank": 4930,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "颠覆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diānfù",
      "PrimaryDefinition": "overturn, subvert"
    },
    "Keys": {
      "Word": 6263
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "写作": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiězuò",
      "PrimaryDefinition": "writing"
    },
    "Keys": {
      "Word": 2049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "博览会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bólǎnhuì",
      "PrimaryDefinition": "expo, trade fair, exhibition, exposition, fair, Expo"
    },
    "Keys": {
      "Word": 3267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "双": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuāng",
      "PrimaryDefinition": "set of two, pair, couple; both"
    },
    "Keys": {
      "Hanzi": 187,
      "Word": 1942
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "29.2",
      "Grade": 3,
      "GeneralStandard": 187,
      "Radical": "又",
      "HSK": 3,
      "FrequencyRank": 581,
      "StrokeCount": 4
    }
  },
  "丛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cóng",
      "PrimaryDefinition": "bush, shrub; thicket; collection"
    },
    "Keys": {
      "Hanzi": 281
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "1.4",
      "GeneralStandard": 281,
      "Radical": "一",
      "HSK": 7,
      "FrequencyRank": 1678,
      "StrokeCount": 5
    }
  },
  "慌忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāngmáng",
      "PrimaryDefinition": "in a great rush, in a flurry"
    },
    "Keys": {
      "Word": 3554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "坚固": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāngù",
      "PrimaryDefinition": "firm, firmly, hard, stable"
    },
    "Keys": {
      "Word": 2603
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "笆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "bamboo fence"
    },
    "Keys": {
      "Hanzi": 4560
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4560,
      "Radical": "竹",
      "FrequencyRank": 3617,
      "RadicalIndex": "118.4",
      "StrokeCount": 10
    }
  },
  "上午": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shàngwǔ",
      "PrimaryDefinition": "morning, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 319
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "螗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "a kind of cicada"
    },
    "Keys": {
      "Hanzi": 7866
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7866,
      "Radical": "虫",
      "FrequencyRank": 7090,
      "RadicalIndex": "142.1",
      "StrokeCount": 16
    }
  },
  "耏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nài",
      "PrimaryDefinition": "whiskers"
    },
    "Keys": {
      "Hanzi": 6812
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6812,
      "Radical": "而",
      "RadicalIndex": "126.3",
      "StrokeCount": 9
    }
  },
  "放": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàng",
      "PrimaryDefinition": "put, release, free, liberate"
    },
    "Keys": {
      "Hanzi": 1223,
      "Word": 102
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "66.4",
      "Grade": 1,
      "GeneralStandard": 1223,
      "Radical": "攴",
      "HSK": 1,
      "FrequencyRank": 291,
      "StrokeCount": 8
    }
  },
  "号召": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàozhào",
      "PrimaryDefinition": "call, appeal (for supporters)"
    },
    "Keys": {
      "Word": 3534
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "怜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lián",
      "PrimaryDefinition": "pity, sympathize"
    },
    "Keys": {
      "Hanzi": 1270
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.5",
      "GeneralStandard": 1270,
      "Radical": "心",
      "HSK": 5,
      "FrequencyRank": 1599,
      "StrokeCount": 8
    }
  },
  "防盗门": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángdàomén",
      "PrimaryDefinition": "entrance door (for apartment)"
    },
    "Keys": {
      "Word": 6488
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "服用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fúyòng",
      "PrimaryDefinition": "take, use, take (medicine)"
    },
    "Keys": {
      "Word": 6602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傲慢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "àomàn",
      "PrimaryDefinition": "arrogant, haughty"
    },
    "Keys": {
      "Word": 5456
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7286
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7286,
      "Radical": "玉",
      "RadicalIndex": "96.8",
      "StrokeCount": 12
    }
  },
  "突破口": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tūpòkǒu",
      "PrimaryDefinition": "Breakout"
    },
    "Keys": {
      "Word": 9574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "研究所": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yánjiūsuǒ",
      "PrimaryDefinition": "graduate_school, research institute, school, laboratory, graduate school, institute"
    },
    "Keys": {
      "Word": 4091
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "军舰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūnjiàn",
      "PrimaryDefinition": "warship, military naval vessel, CL:艘[sou1]"
    },
    "Keys": {
      "Word": 4775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "朱红": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhūhóng",
      "PrimaryDefinition": "vermilion"
    },
    "Keys": {
      "Word": 10844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "供给": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngjǐ",
      "PrimaryDefinition": "supply, provide, furnish"
    },
    "Keys": {
      "Word": 4611
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "干部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gànbù",
      "PrimaryDefinition": "organizer, staff, cadre"
    },
    "Keys": {
      "Word": 6682
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "泚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cǐ",
      "PrimaryDefinition": "clear; bright and brilliant; sweat"
    },
    "Keys": {
      "Hanzi": 6885
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6885,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "倨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "arrogant, haughty, rude"
    },
    "Keys": {
      "Hanzi": 4581
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4581,
      "Radical": "人",
      "FrequencyRank": 5167,
      "RadicalIndex": "9.8",
      "StrokeCount": 10
    }
  },
  "肩膀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānbǎng",
      "PrimaryDefinition": "shoulder"
    },
    "Keys": {
      "Word": 7383
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "就是说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiùshìshuō",
      "PrimaryDefinition": "that is to say, in other words, namely"
    },
    "Keys": {
      "Word": 4763
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "吸烟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī yān",
      "PrimaryDefinition": "to smoke"
    },
    "Keys": {
      "Word": 2997
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "橇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāo",
      "PrimaryDefinition": "a sledge for transportation"
    },
    "Keys": {
      "Hanzi": 6058
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6058,
      "Radical": "木",
      "FrequencyRank": 4006,
      "RadicalIndex": "75.12",
      "StrokeCount": 16
    }
  },
  "猛然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "měngrán",
      "PrimaryDefinition": "suddenly, abruptly"
    },
    "Keys": {
      "Word": 8251
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "投稿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu gǎo",
      "PrimaryDefinition": "to submit articles for publication, to contribute (writing)"
    },
    "Keys": {
      "Word": 9562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "知己": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhījǐ",
      "PrimaryDefinition": "to know oneself, to be intimate or close, intimate friend"
    },
    "Keys": {
      "Word": 10748
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kēng",
      "PrimaryDefinition": "the sound of stones knocking together"
    },
    "Keys": {
      "Hanzi": 6972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6972,
      "Radical": "石",
      "FrequencyRank": 7425,
      "RadicalIndex": "112.5",
      "StrokeCount": 10
    }
  },
  "点缀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diǎnzhuì",
      "PrimaryDefinition": "to decorate, to adorn, sprinkled, studded, only for show"
    },
    "Keys": {
      "Word": 6270
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "给": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gěi",
      "PrimaryDefinition": "give; by, for"
    },
    "Keys": {
      "Hanzi": 1758,
      "Word": 119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.6",
      "Grade": 1,
      "GeneralStandard": 1758,
      "Radical": "糸",
      "HSK": 1,
      "FrequencyRank": 180,
      "StrokeCount": 9
    }
  },
  "摽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "throw out; push out; strike"
    },
    "Keys": {
      "Hanzi": 5631
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5631,
      "Radical": "手",
      "RadicalIndex": "64.11",
      "StrokeCount": 14
    }
  },
  "犸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mà",
      "PrimaryDefinition": "mammoth"
    },
    "Keys": {
      "Hanzi": 3618
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3618,
      "Radical": "犬",
      "FrequencyRank": 5838,
      "RadicalIndex": "94.3",
      "StrokeCount": 6
    }
  },
  "参加": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cānjiā",
      "PrimaryDefinition": "join, attend, take part in, give (advice/etc.)"
    },
    "Keys": {
      "Word": 545
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "龙舟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóngzhōu",
      "PrimaryDefinition": "dragon boat, imperial boat"
    },
    "Keys": {
      "Word": 8113
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "舄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xì",
      "PrimaryDefinition": "a shoe; the sole of a shoe; magpie"
    },
    "Keys": {
      "Hanzi": 5210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5210,
      "Radical": "臼",
      "FrequencyRank": 6827,
      "RadicalIndex": "134.6",
      "StrokeCount": 12
    }
  },
  "鹲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "méng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7991
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7991,
      "Radical": "鳥",
      "RadicalIndex": "196.14",
      "StrokeCount": 18
    }
  },
  "因此": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīncǐ",
      "PrimaryDefinition": "conj.: therefore, consequently"
    },
    "Keys": {
      "Word": 2095
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "喹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuí",
      "PrimaryDefinition": "chemical compound"
    },
    "Keys": {
      "Hanzi": 5132
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5132,
      "Radical": "口",
      "FrequencyRank": 6071,
      "RadicalIndex": "30.9",
      "StrokeCount": 12
    }
  },
  "旅途": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǚtú",
      "PrimaryDefinition": "way, travel, route, traveling, travelling, trip, journey"
    },
    "Keys": {
      "Word": 8139
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "击": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "strike, hit, beat; attack, fight"
    },
    "Keys": {
      "Hanzi": 195
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "17.3",
      "GeneralStandard": 195,
      "Radical": "凵",
      "HSK": 5,
      "FrequencyRank": 395,
      "StrokeCount": 5
    }
  },
  "革新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "géxīn",
      "PrimaryDefinition": "innovate"
    },
    "Keys": {
      "Word": 4602
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "提前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíqián",
      "PrimaryDefinition": "counter, foresee, shift to an earlier date, set ahead, advance date, advanced, advancement, move up, anticipate, advance, forestall"
    },
    "Keys": {
      "Word": 1956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "保暖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎo nuǎn",
      "PrimaryDefinition": "to stay warm, to protect against the cold"
    },
    "Keys": {
      "Word": 5522
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "指引": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐyǐn",
      "PrimaryDefinition": "to guide, to show, to point (the way), directions, guidance, guidelines"
    },
    "Keys": {
      "Word": 10780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "黑白": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hēibái",
      "PrimaryDefinition": "black and white, right and wrong, good and bad"
    },
    "Keys": {
      "Word": 7060
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xūn",
      "PrimaryDefinition": "a cellar, a store-room"
    },
    "Keys": {
      "Hanzi": 5815
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5815,
      "Radical": "穴",
      "FrequencyRank": 5807,
      "RadicalIndex": "116.9",
      "StrokeCount": 14
    }
  },
  "录音机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lùyīnjī",
      "PrimaryDefinition": "(tape) recording machine, tape recorder, CL:臺|台[tai2]"
    },
    "Keys": {
      "Word": 4844
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "悢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7069
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7069,
      "Radical": "心",
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "夸张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuāzhāng",
      "PrimaryDefinition": "overpitch, overact, vapor, overpaint, overcolor, overstate, overplay, overdraw, overblow, hyperbolize, overcharge, stretch, amplify, enhance, aggrandize, ham, highfalutin, vaunt, magnify, boast, dilate, overdo, exaggerate"
    },
    "Keys": {
      "Word": 7871
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "扑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pū",
      "PrimaryDefinition": "pound, beat, strike; attack"
    },
    "Keys": {
      "Hanzi": 199,
      "Word": 4913
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.2",
      "Grade": 6,
      "GeneralStandard": 199,
      "Radical": "手",
      "HSK": 6,
      "FrequencyRank": 1509,
      "StrokeCount": 5
    }
  },
  "剌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lá",
      "PrimaryDefinition": "slash, cut in two; contradict"
    },
    "Keys": {
      "Hanzi": 4161
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4161,
      "Radical": "刀",
      "FrequencyRank": 3577,
      "RadicalIndex": "18.7",
      "StrokeCount": 9
    }
  },
  "通用": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngyòng",
      "PrimaryDefinition": "common (use), interchangeable"
    },
    "Keys": {
      "Word": 3982
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "心疼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnténg",
      "PrimaryDefinition": "to love dearly, to feel sorry for sb, to regret, to grudge, to be distressed"
    },
    "Keys": {
      "Word": 4061
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "治": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "govern, regulate, administer"
    },
    "Keys": {
      "Hanzi": 1264,
      "Word": 3177
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 4,
      "GeneralStandard": 1264,
      "Radical": "水",
      "HSK": 4,
      "FrequencyRank": 274,
      "StrokeCount": 8
    }
  },
  "叽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "sigh in disapproval; take small"
    },
    "Keys": {
      "Hanzi": 251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 251,
      "Radical": "口",
      "FrequencyRank": 3198,
      "RadicalIndex": "30.2",
      "StrokeCount": 5
    }
  },
  "司长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīzhǎng",
      "PrimaryDefinition": "bureau chief"
    },
    "Keys": {
      "Word": 5048
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "胩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎ",
      "PrimaryDefinition": "an organic compound"
    },
    "Keys": {
      "Hanzi": 6869
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6869,
      "Radical": "肉",
      "FrequencyRank": 6106,
      "RadicalIndex": "130.5",
      "StrokeCount": 9
    }
  },
  "襁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎng",
      "PrimaryDefinition": "swaddling clothes"
    },
    "Keys": {
      "Hanzi": 6301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6301,
      "Radical": "衣",
      "FrequencyRank": 5023,
      "RadicalIndex": "145.11",
      "StrokeCount": 17
    }
  },
  "告诉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gàosu",
      "PrimaryDefinition": "tell, preach, notify, impart, inform, represent, apprize, send word, share, advise, give notice, apprise, assure, let know"
    },
    "Keys": {
      "Word": 114
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "学生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuésheng",
      "PrimaryDefinition": "academic, pupil, pup, tutee, follower, scholar, schoolchild, schoolboy, alumnus, disciple, learner, scholastic, student"
    },
    "Keys": {
      "Word": 415
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "眬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lóng",
      "PrimaryDefinition": "faint, fuzzy, blurred"
    },
    "Keys": {
      "Hanzi": 4493
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4493,
      "Radical": "目",
      "FrequencyRank": 6103,
      "RadicalIndex": "109.5",
      "StrokeCount": 10
    }
  },
  "友": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒu",
      "PrimaryDefinition": "friend, companion; fraternity"
    },
    "Keys": {
      "Hanzi": 97
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "29.2",
      "GeneralStandard": 97,
      "Radical": "又",
      "HSK": 1,
      "FrequencyRank": 594,
      "StrokeCount": 4
    }
  },
  "早就": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǎojiù",
      "PrimaryDefinition": "already at an earlier time"
    },
    "Keys": {
      "Word": 1199
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "免职": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn zhí",
      "PrimaryDefinition": "to relieve sb of his post, to sack, to demote, dismissal, sacking"
    },
    "Keys": {
      "Word": 8279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "腐败": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔbài",
      "PrimaryDefinition": "rotten, putrid, decayed, corrupt"
    },
    "Keys": {
      "Word": 6617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嘏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "felicity, prosperity; large and strong"
    },
    "Keys": {
      "Hanzi": 7646
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7646,
      "Radical": "口",
      "FrequencyRank": 5670,
      "RadicalIndex": "30.11",
      "StrokeCount": 14
    }
  },
  "杪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎo",
      "PrimaryDefinition": "tip of twig, top of tree; twig"
    },
    "Keys": {
      "Hanzi": 3876
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3876,
      "Radical": "木",
      "FrequencyRank": 6035,
      "RadicalIndex": "75.4",
      "StrokeCount": 8
    }
  },
  "溉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gài",
      "PrimaryDefinition": "water, irrigate, flood; wash"
    },
    "Keys": {
      "Hanzi": 2769
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.9",
      "GeneralStandard": 2769,
      "Radical": "水",
      "HSK": 9,
      "FrequencyRank": 3524,
      "StrokeCount": 12
    }
  },
  "游人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóurén",
      "PrimaryDefinition": "a tourist"
    },
    "Keys": {
      "Word": 5272
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "私立": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sīlì",
      "PrimaryDefinition": "privately run, private"
    },
    "Keys": {
      "Word": 9310
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "症结": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngjié",
      "PrimaryDefinition": "hard lump in the abdomen (in Chinese medicine), crux of an issue, main point in an argument, sticking point, deadlock in negotiations"
    },
    "Keys": {
      "Word": 10725
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "媳妇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xífù",
      "PrimaryDefinition": "the wife of a relative of the younger generation's, wife of sb. of younger generation, daughter-in-law, son's wife"
    },
    "Keys": {
      "Word": 9834
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "簠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǔ",
      "PrimaryDefinition": "an ancient vessel used for holding boiled grain"
    },
    "Keys": {
      "Hanzi": 8007
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8007,
      "Radical": "竹",
      "FrequencyRank": 9082,
      "RadicalIndex": "118.12",
      "StrokeCount": 18
    }
  },
  "目光": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùguāng",
      "PrimaryDefinition": "sight, vision, view, gaze, look"
    },
    "Keys": {
      "Word": 3736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "大于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàyú",
      "PrimaryDefinition": "greater than, bigger than, more than, >"
    },
    "Keys": {
      "Word": 3388
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "指南针": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐnánzhēn",
      "PrimaryDefinition": "compass"
    },
    "Keys": {
      "Word": 10775
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "混": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hùn",
      "PrimaryDefinition": "to mix, blend, mingle; to bumble along"
    },
    "Keys": {
      "Hanzi": 2420,
      "Word": 4684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.8",
      "Grade": 6,
      "GeneralStandard": 2420,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 1137,
      "StrokeCount": 11
    }
  },
  "吼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǒu",
      "PrimaryDefinition": "roar, shout; bark, howl"
    },
    "Keys": {
      "Hanzi": 749,
      "Word": 7088
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.4",
      "Grade": 7,
      "GeneralStandard": 749,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2151,
      "StrokeCount": 7
    }
  },
  "津津有味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnjīn-yǒuwèi",
      "PrimaryDefinition": "with keen interest pleasure (idiom), with gusto, to relish, eagerly, with great interest"
    },
    "Keys": {
      "Word": 7562
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pìn",
      "PrimaryDefinition": "engage, employ; betroth"
    },
    "Keys": {
      "Hanzi": 2845,
      "Word": 8544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "128.7",
      "Grade": 7,
      "GeneralStandard": 2845,
      "Radical": "耳",
      "HSK": 6,
      "FrequencyRank": 2253,
      "StrokeCount": 13
    }
  },
  "狂欢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuánghuān",
      "PrimaryDefinition": "party, carousal, hilarity, merriment, whoopee, to carouse"
    },
    "Keys": {
      "Word": 7886
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "误": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "err, make mistake; interfere"
    },
    "Keys": {
      "Hanzi": 1714,
      "Word": 5150
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "Grade": 6,
      "GeneralStandard": 1714,
      "Radical": "言",
      "HSK": 3,
      "FrequencyRank": 854,
      "StrokeCount": 9
    }
  },
  "提问": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tíwèn",
      "PrimaryDefinition": "to question, to quiz, to grill"
    },
    "Keys": {
      "Word": 1955
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "遵照": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zūnzhào",
      "PrimaryDefinition": "in accordance with, to follow (the rules)"
    },
    "Keys": {
      "Word": 11022
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1737,
      "Word": 10301
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "38.6",
      "Grade": 7,
      "GeneralStandard": 1737,
      "Radical": "女",
      "HSK": 4,
      "FrequencyRank": 2287,
      "StrokeCount": 9
    }
  },
  "鉴定": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàndìng",
      "PrimaryDefinition": "to appraise, to identify, to evaluate"
    },
    "Keys": {
      "Word": 4713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "猰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7407
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7407,
      "Radical": "犬",
      "RadicalIndex": "94.9",
      "StrokeCount": 12
    }
  },
  "山区": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shānqū",
      "PrimaryDefinition": "mountain area"
    },
    "Keys": {
      "Word": 3860
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "贾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎ",
      "PrimaryDefinition": "surname; merchant; buy, trade"
    },
    "Keys": {
      "Hanzi": 1856
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "154.6",
      "GeneralStandard": 1856,
      "Radical": "貝",
      "HSK": 9,
      "FrequencyRank": 2051,
      "StrokeCount": 10
    }
  },
  "受贿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shòu huì",
      "PrimaryDefinition": "to accept a bribe, bribery"
    },
    "Keys": {
      "Word": 9196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "面目全非": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miànmù-quánfēi",
      "PrimaryDefinition": "nothing remains the same (idiom), change beyond recognition"
    },
    "Keys": {
      "Word": 8287
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "家里": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiālǐ",
      "PrimaryDefinition": "home"
    },
    "Keys": {
      "Word": 167
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "晕车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùn chē",
      "PrimaryDefinition": "to be carsick"
    },
    "Keys": {
      "Word": 5302
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "层出不穷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céngchū-bùqióng",
      "PrimaryDefinition": "more and more emerge, innumerable succession, breeding like flies (idiom)"
    },
    "Keys": {
      "Word": 5780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "踌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "hesitate, falter; smug, self-satisfied"
    },
    "Keys": {
      "Hanzi": 5702
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5702,
      "Radical": "足",
      "FrequencyRank": 3302,
      "RadicalIndex": "157.7",
      "StrokeCount": 14
    }
  },
  "村庄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cūnzhuāng",
      "PrimaryDefinition": "village, hamlet, CL:座[zuo4]"
    },
    "Keys": {
      "Word": 4461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "城": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéng",
      "PrimaryDefinition": "castle; city, town; municipality"
    },
    "Keys": {
      "Hanzi": 1373,
      "Word": 1354
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.7",
      "Grade": 3,
      "GeneralStandard": 1373,
      "Radical": "土",
      "HSK": 3,
      "FrequencyRank": 413,
      "StrokeCount": 9
    }
  },
  "鹆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "myna; manah; Acridotheres tristis"
    },
    "Keys": {
      "Hanzi": 5224
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5224,
      "Radical": "鳥",
      "FrequencyRank": 5371,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "钨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wū",
      "PrimaryDefinition": "tungsten, wolfram"
    },
    "Keys": {
      "Hanzi": 4244
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4244,
      "Radical": "金",
      "FrequencyRank": 4818,
      "RadicalIndex": "167.5",
      "StrokeCount": 9
    }
  },
  "剜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wān",
      "PrimaryDefinition": "cut, cut out, pick out, scoop out"
    },
    "Keys": {
      "Hanzi": 4667
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4667,
      "Radical": "刀",
      "FrequencyRank": 4671,
      "RadicalIndex": "18.8",
      "StrokeCount": 10
    }
  },
  "夭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yāo",
      "PrimaryDefinition": "young, fresh-looking; die young"
    },
    "Keys": {
      "Hanzi": 126
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 126,
      "Radical": "大",
      "FrequencyRank": 3079,
      "RadicalIndex": "37.1",
      "StrokeCount": 4
    }
  },
  "邮编": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yóubiān",
      "PrimaryDefinition": "postal code, zip code"
    },
    "Keys": {
      "Word": 10461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蝤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7775
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7775,
      "Radical": "虫",
      "FrequencyRank": 7089,
      "RadicalIndex": "142.9",
      "StrokeCount": 15
    }
  },
  "吉利": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jílì",
      "PrimaryDefinition": "Geely, Chinese car make, auspicious, lucky, propitious"
    },
    "Keys": {
      "Word": 4702
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "邴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐng",
      "PrimaryDefinition": "name of a city in the ancient state of Song, in what is now Shandong; pleased"
    },
    "Keys": {
      "Hanzi": 3684
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3684,
      "Radical": "邑",
      "FrequencyRank": 6824,
      "RadicalIndex": "163.5",
      "StrokeCount": 7
    }
  },
  "实况": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shíkuàng",
      "PrimaryDefinition": "actual situation/happening"
    },
    "Keys": {
      "Word": 9118
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "设计师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèjìshī",
      "PrimaryDefinition": "architect, designer, stylist"
    },
    "Keys": {
      "Word": 5003
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "明天": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngtiān",
      "PrimaryDefinition": "tomorrow"
    },
    "Keys": {
      "Word": 245
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "炒股": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎo gǔ",
      "PrimaryDefinition": "(coll.) to speculate in stocks"
    },
    "Keys": {
      "Word": 4402
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "羌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "Qiang nationality; surname"
    },
    "Keys": {
      "Hanzi": 3764
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3764,
      "Radical": "羊",
      "FrequencyRank": 3509,
      "RadicalIndex": "123.2",
      "StrokeCount": 7
    }
  },
  "帽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mào",
      "PrimaryDefinition": "hat, cap; cap-like tops"
    },
    "Keys": {
      "Hanzi": 2649
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "50.9",
      "GeneralStandard": 2649,
      "Radical": "巾",
      "HSK": 4,
      "FrequencyRank": 1750,
      "StrokeCount": 12
    }
  },
  "命名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìng míng",
      "PrimaryDefinition": "to give a name to, to dub, to christen, to designate, named after, naming"
    },
    "Keys": {
      "Word": 8315
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "厂商": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎngshāng",
      "PrimaryDefinition": "factory owner, factories and stores, firm, maker, business organization, business concern, manufacturing business, concern, manufacturer, business"
    },
    "Keys": {
      "Word": 4395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "金子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnzi",
      "PrimaryDefinition": "Kaneko (Japanese surname), gold"
    },
    "Keys": {
      "Word": 7560
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "洘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6881
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6881,
      "Radical": "水",
      "RadicalIndex": "85.6",
      "StrokeCount": 9
    }
  },
  "好处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎochu",
      "PrimaryDefinition": "pull, profit, advantage, stead, benefit, virtu, welfare, virtue, good, behoof, goodness, gain, pap, beauty, beaut"
    },
    "Keys": {
      "Word": 710
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "渎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dú",
      "PrimaryDefinition": "ditch, sluice, gutter, drain"
    },
    "Keys": {
      "Hanzi": 4968
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4968,
      "Radical": "水",
      "FrequencyRank": 3373,
      "RadicalIndex": "85.8",
      "StrokeCount": 11
    }
  },
  "如果说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúguǒshuō",
      "PrimaryDefinition": "conj.: if"
    },
    "Keys": {
      "Word": 8893
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "耤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "plough"
    },
    "Keys": {
      "Hanzi": 7620
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7620,
      "Radical": "耒",
      "RadicalIndex": "127.8",
      "StrokeCount": 14
    }
  },
  "痍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yí",
      "PrimaryDefinition": "wound, bruise, sore"
    },
    "Keys": {
      "Hanzi": 4939
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4939,
      "Radical": "疒",
      "FrequencyRank": 5413,
      "RadicalIndex": "104.6",
      "StrokeCount": 11
    }
  },
  "轲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kē",
      "PrimaryDefinition": "axle; personal name of mencius"
    },
    "Keys": {
      "Hanzi": 4179
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4179,
      "Radical": "車",
      "FrequencyRank": 3853,
      "RadicalIndex": "159.5",
      "StrokeCount": 9
    }
  },
  "晕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yūn",
      "PrimaryDefinition": "halo in sky; fog; dizzy, faint"
    },
    "Keys": {
      "Hanzi": 1897,
      "Word": 5297
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "72.6",
      "Grade": 6,
      "GeneralStandard": 1897,
      "Radical": "日",
      "HSK": 6,
      "FrequencyRank": 2215,
      "StrokeCount": 10
    }
  },
  "一面": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīmiàn",
      "PrimaryDefinition": "at the same time, simultaneously"
    },
    "Keys": {
      "Word": 10290
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "情景": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qíngjǐng",
      "PrimaryDefinition": "scene, sight, circumstances"
    },
    "Keys": {
      "Word": 2810
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "属性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǔxìng",
      "PrimaryDefinition": "attribute, property"
    },
    "Keys": {
      "Word": 9226
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "差异": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāyì",
      "PrimaryDefinition": "gap, departure, spread, variance, divergence, difference, diversity, discrepancy, imparity, disparity, contrast, interval, differentia, distinction"
    },
    "Keys": {
      "Word": 4381
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "铲子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎnzi",
      "PrimaryDefinition": "shovel, spade, trowel, spatula (kitchen utensil), CL:把[ba3]"
    },
    "Keys": {
      "Word": 5805
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "征服": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēngfú",
      "PrimaryDefinition": "to conquer, to subdue, to vanquish"
    },
    "Keys": {
      "Word": 3164
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "玟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wén",
      "PrimaryDefinition": "streaks in jade; gem"
    },
    "Keys": {
      "Hanzi": 6648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6648,
      "Radical": "玉",
      "FrequencyRank": 6534,
      "RadicalIndex": "96.4",
      "StrokeCount": 8
    }
  },
  "头晕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóu yūn",
      "PrimaryDefinition": "dizzy"
    },
    "Keys": {
      "Word": 9559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "硫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liú",
      "PrimaryDefinition": "sulfur"
    },
    "Keys": {
      "Hanzi": 2592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2592,
      "Radical": "石",
      "FrequencyRank": 2730,
      "RadicalIndex": "112.7",
      "StrokeCount": 12
    }
  },
  "艽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāo",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 3530
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3530,
      "Radical": "艸",
      "FrequencyRank": 5680,
      "RadicalIndex": "140.2",
      "StrokeCount": 5
    }
  },
  "右边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòubian",
      "PrimaryDefinition": "right side, right, to the right"
    },
    "Keys": {
      "Word": 445
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "职业病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhíyèbìng",
      "PrimaryDefinition": "occupational disease"
    },
    "Keys": {
      "Word": 10766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凯歌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǎigē",
      "PrimaryDefinition": "triumphal hymn, victory song, paean"
    },
    "Keys": {
      "Word": 7761
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "间谍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàndié",
      "PrimaryDefinition": "spy"
    },
    "Keys": {
      "Word": 7424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "交响乐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāoxiǎngyuè",
      "PrimaryDefinition": "symphony"
    },
    "Keys": {
      "Word": 7463
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "平时": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngshí",
      "PrimaryDefinition": "ordinarily, in normal times, in peacetime"
    },
    "Keys": {
      "Word": 911
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "句": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jù",
      "PrimaryDefinition": "sentence"
    },
    "Keys": {
      "Hanzi": 288,
      "Word": 804
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.2",
      "Grade": 2,
      "GeneralStandard": 288,
      "Radical": "口",
      "HSK": 2,
      "FrequencyRank": 707,
      "StrokeCount": 5
    }
  },
  "尴尬": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngà",
      "PrimaryDefinition": "awkward, embarrassed"
    },
    "Keys": {
      "Word": 6668
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "苗头": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáotou",
      "PrimaryDefinition": "first signs, development (of a situation)"
    },
    "Keys": {
      "Word": 8288
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "当真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàngzhēn",
      "PrimaryDefinition": "to take seriously, serious, No joking, really!"
    },
    "Keys": {
      "Word": 6184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "间隔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàngé",
      "PrimaryDefinition": "compartment, gap, interval, to divide"
    },
    "Keys": {
      "Word": 7426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "举行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔxíng",
      "PrimaryDefinition": "hold (meeting/etc.)"
    },
    "Keys": {
      "Word": 799
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "出境": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū jìng",
      "PrimaryDefinition": "to leave a country or region, emigration, outbound (tourism)"
    },
    "Keys": {
      "Word": 5956
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国会": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guóhuì",
      "PrimaryDefinition": "Storthing, Oireachtas, Cortes, Congress, congress, diet, the Diet, duma, congressional, Capitol, parliament, house"
    },
    "Keys": {
      "Word": 4638
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "侦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "spy, reconnoiter; detective"
    },
    "Keys": {
      "Hanzi": 1145
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.6",
      "GeneralStandard": 1145,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 1479,
      "StrokeCount": 8
    }
  },
  "湫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "a small pond; a damp and narrow place"
    },
    "Keys": {
      "Hanzi": 5284
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5284,
      "Radical": "水",
      "FrequencyRank": 5470,
      "RadicalIndex": "85.9",
      "StrokeCount": 12
    }
  },
  "发挥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fāhuī",
      "PrimaryDefinition": "bring into play, give free rein to, develop (idea/etc.), elaborate"
    },
    "Keys": {
      "Word": 2444
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鲮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "carp"
    },
    "Keys": {
      "Hanzi": 6139
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6139,
      "Radical": "魚",
      "FrequencyRank": 5476,
      "RadicalIndex": "195.8",
      "StrokeCount": 16
    }
  },
  "无情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúqíng",
      "PrimaryDefinition": "merciless, harden, heartless, ruthless"
    },
    "Keys": {
      "Word": 9779
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "海域": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiyù",
      "PrimaryDefinition": "water, waters, maritime space, sea area"
    },
    "Keys": {
      "Word": 6971
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "娟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": "beautiful, graceful"
    },
    "Keys": {
      "Hanzi": 2141
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2141,
      "Radical": "女",
      "FrequencyRank": 2564,
      "RadicalIndex": "38.7",
      "StrokeCount": 10
    }
  },
  "配件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pèijiàn",
      "PrimaryDefinition": "component, part, fitting, accessory, replacement part"
    },
    "Keys": {
      "Word": 8486
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "bamboo pole; penis"
    },
    "Keys": {
      "Hanzi": 1554
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "118.3",
      "GeneralStandard": 1554,
      "Radical": "竹",
      "HSK": 9,
      "FrequencyRank": 2934,
      "StrokeCount": 9
    }
  },
  "嫒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ài",
      "PrimaryDefinition": "(your) daughter"
    },
    "Keys": {
      "Hanzi": 5607
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5607,
      "Radical": "女",
      "FrequencyRank": 5874,
      "RadicalIndex": "38.1",
      "StrokeCount": 13
    }
  },
  "蹅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎ",
      "PrimaryDefinition": "to tread on, walk through"
    },
    "Keys": {
      "Hanzi": 7863
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7863,
      "Radical": "足",
      "FrequencyRank": 7030,
      "RadicalIndex": "157.9",
      "StrokeCount": 16
    }
  },
  "镱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "ytterbium"
    },
    "Keys": {
      "Hanzi": 8004
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8004,
      "Radical": "金",
      "FrequencyRank": 7166,
      "RadicalIndex": "167.13",
      "StrokeCount": 18
    }
  },
  "清静": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qīngjìng",
      "PrimaryDefinition": "quiet, peaceful and quiet"
    },
    "Keys": {
      "Word": 8746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "赉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lài",
      "PrimaryDefinition": "give, present, confer; surname"
    },
    "Keys": {
      "Hanzi": 4805
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4805,
      "Radical": "貝",
      "FrequencyRank": 6078,
      "RadicalIndex": "154.7",
      "StrokeCount": 11
    }
  },
  "敬重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngzhòng",
      "PrimaryDefinition": "to respect deeply, to revere, to esteem"
    },
    "Keys": {
      "Word": 7649
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "俄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "é",
      "PrimaryDefinition": "sudden(ly), soon; Russian"
    },
    "Keys": {
      "Hanzi": 1564
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.7",
      "GeneralStandard": 1564,
      "Radical": "人",
      "HSK": 8,
      "FrequencyRank": 975,
      "StrokeCount": 9
    }
  },
  "车速": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chēsù",
      "PrimaryDefinition": "vehicle speed"
    },
    "Keys": {
      "Word": 5839
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "原有": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuányǒu",
      "PrimaryDefinition": "originally possess, previously exist"
    },
    "Keys": {
      "Word": 4157
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "默默无闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mòmò-wúwén",
      "PrimaryDefinition": "obscure and unknown (idiom), an outsider without any reputation, a nobody, an unknown quantity"
    },
    "Keys": {
      "Word": 8335
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "老字号": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎozìhao",
      "PrimaryDefinition": "shop, firm, or brand of merchandise with a long-established reputation"
    },
    "Keys": {
      "Word": 7952
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "膏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāo",
      "PrimaryDefinition": "grease, fat; paste, ointment"
    },
    "Keys": {
      "Hanzi": 3142
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.1",
      "GeneralStandard": 3142,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 2673,
      "StrokeCount": 14
    }
  },
  "尝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháng",
      "PrimaryDefinition": "taste; experience, experiment"
    },
    "Keys": {
      "Hanzi": 1476,
      "Word": 3313
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "42.6",
      "Grade": 5,
      "GeneralStandard": 1476,
      "Radical": "小",
      "HSK": 5,
      "FrequencyRank": 1621,
      "StrokeCount": 9
    }
  },
  "起劲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐjìn",
      "PrimaryDefinition": "vigorously, energetically, enthusiastically"
    },
    "Keys": {
      "Word": 8616
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "攫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jué",
      "PrimaryDefinition": "snatch away, seize; catch with"
    },
    "Keys": {
      "Hanzi": 6479
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6479,
      "Radical": "手",
      "FrequencyRank": 3692,
      "RadicalIndex": "64.2",
      "StrokeCount": 23
    }
  },
  "彀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòu",
      "PrimaryDefinition": "enough, adequate fully, quite"
    },
    "Keys": {
      "Hanzi": 5363
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5363,
      "Radical": "弓",
      "FrequencyRank": 5457,
      "RadicalIndex": "57.1",
      "StrokeCount": 13
    }
  },
  "名言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míngyán",
      "PrimaryDefinition": "saying, famous remark"
    },
    "Keys": {
      "Word": 8307
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "琅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "láng",
      "PrimaryDefinition": "a variety of white carnelian; pure"
    },
    "Keys": {
      "Hanzi": 2159
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2159,
      "Radical": "玉",
      "FrequencyRank": 3585,
      "RadicalIndex": "96.7",
      "StrokeCount": 11
    }
  },
  "一帆风顺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīfān-fēngshùn",
      "PrimaryDefinition": "propitious wind throughout the journey (idiom), plain sailing, to go smoothly, have a nice trip!"
    },
    "Keys": {
      "Word": 10323
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "非凡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēifán",
      "PrimaryDefinition": "out of the ordinary, unusually (good, talented etc)"
    },
    "Keys": {
      "Word": 6516
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "否认": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fǒurèn",
      "PrimaryDefinition": "deny, disavow, repudiation, naysay, denial, gainsay, reject, veto, negate, disclaim, disown, challenge, dispute, renounce, disaffirm, negative, contradict, contravene, repudiate"
    },
    "Keys": {
      "Word": 1461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "保证": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎozhèng",
      "PrimaryDefinition": "pledge, guarantee"
    },
    "Keys": {
      "Word": 1281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "烫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàng",
      "PrimaryDefinition": "scald, heat; wash; iron clothes"
    },
    "Keys": {
      "Hanzi": 2087,
      "Word": 9430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.6",
      "Grade": 7,
      "GeneralStandard": 2087,
      "Radical": "火",
      "HSK": 7,
      "FrequencyRank": 2903,
      "StrokeCount": 10
    }
  },
  "坚持不懈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānchí-bùxiè",
      "PrimaryDefinition": "to persevere unremittingly (idiom), to keep going until the end"
    },
    "Keys": {
      "Word": 7376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "溟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míng",
      "PrimaryDefinition": "drizzling rain; dark, obscure"
    },
    "Keys": {
      "Hanzi": 5586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5586,
      "Radical": "水",
      "FrequencyRank": 4234,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "鸽子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēzi",
      "PrimaryDefinition": "pigeon, dove, various birds of the family Columbidae"
    },
    "Keys": {
      "Word": 6720
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chāng",
      "PrimaryDefinition": "iris, sweet flag, calamus"
    },
    "Keys": {
      "Hanzi": 4753
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4753,
      "Radical": "艸",
      "FrequencyRank": 5212,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "媂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dì",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7452
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7452,
      "Radical": "女",
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "萚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuò",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7121,
      "Radical": "艸",
      "FrequencyRank": 9117,
      "RadicalIndex": "140.16",
      "StrokeCount": 11
    }
  },
  "忠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "loyalty, devotion, fidelity"
    },
    "Keys": {
      "Hanzi": 1094
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.4",
      "GeneralStandard": 1094,
      "Radical": "心",
      "HSK": 6,
      "FrequencyRank": 1322,
      "StrokeCount": 8
    }
  },
  "过早": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guòzǎo",
      "PrimaryDefinition": "premature, untimely"
    },
    "Keys": {
      "Word": 6959
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "似": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shì",
      "PrimaryDefinition": "resemble, similar to; as if, seem"
    },
    "Keys": {
      "Hanzi": 481
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 481,
      "Radical": "人",
      "HSK": 3,
      "FrequencyRank": 431,
      "StrokeCount": 6
    }
  },
  "漂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piào",
      "PrimaryDefinition": "float, drift; tossed about"
    },
    "Keys": {
      "Hanzi": 3163,
      "Word": 8528
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.11",
      "Grade": 7,
      "GeneralStandard": 3163,
      "Radical": "水",
      "HSK": 2,
      "FrequencyRank": 1568,
      "StrokeCount": 14
    }
  },
  "黄昏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huánghūn",
      "PrimaryDefinition": "dusk, evening, nightfall"
    },
    "Keys": {
      "Word": 7195
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "口才": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kǒucái",
      "PrimaryDefinition": "eloquence"
    },
    "Keys": {
      "Word": 7836
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "算了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suànle",
      "PrimaryDefinition": "Forget about it., That's enough!"
    },
    "Keys": {
      "Word": 5058
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "中等": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōngděng",
      "PrimaryDefinition": "medium"
    },
    "Keys": {
      "Word": 5366
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "膦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lìn",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6137
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6137,
      "Radical": "肉",
      "FrequencyRank": 7087,
      "RadicalIndex": "130.12",
      "StrokeCount": 16
    }
  },
  "旋涡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuánwō",
      "PrimaryDefinition": "spiral, whirlpool, eddy, vortex"
    },
    "Keys": {
      "Word": 10125
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "字幕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìmù",
      "PrimaryDefinition": "caption, subtitle"
    },
    "Keys": {
      "Word": 10980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "残": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cán",
      "PrimaryDefinition": "injure, spoil; oppress; broken"
    },
    "Keys": {
      "Hanzi": 1460,
      "Word": 5757
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "78.5",
      "Grade": 7,
      "GeneralStandard": 1460,
      "Radical": "歹",
      "HSK": 6,
      "FrequencyRank": 1150,
      "StrokeCount": 9
    }
  },
  "太极拳": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tàijíquán",
      "PrimaryDefinition": "shadowboxing or Taiji, T'aichi or T'aichichuan, traditional form of physical exercise or relaxation, a martial art"
    },
    "Keys": {
      "Word": 9391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "契机": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìjī",
      "PrimaryDefinition": "moment, critical point, turning, turning point, juncture, moment o turning point, turning/critical point, momentum"
    },
    "Keys": {
      "Word": 8632
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "脔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luán",
      "PrimaryDefinition": "small lump of meat; sliced meat"
    },
    "Keys": {
      "Hanzi": 5251
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5251,
      "Radical": "肉",
      "FrequencyRank": 6099,
      "RadicalIndex": "130.6",
      "StrokeCount": 12
    }
  },
  "坫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "a stand on which to replace goblets after drinking"
    },
    "Keys": {
      "Hanzi": 3839
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3839,
      "Radical": "土",
      "FrequencyRank": 7160,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "薸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "piáo",
      "PrimaryDefinition": "duckweed"
    },
    "Keys": {
      "Hanzi": 7924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7924,
      "Radical": "艸",
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "寡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guǎ",
      "PrimaryDefinition": "widowed; alone, friendless"
    },
    "Keys": {
      "Hanzi": 3173
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "40.11",
      "GeneralStandard": 3173,
      "Radical": "宀",
      "HSK": 7,
      "FrequencyRank": 2268,
      "StrokeCount": 14
    }
  },
  "靛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diàn",
      "PrimaryDefinition": "indigo; any blue dye"
    },
    "Keys": {
      "Hanzi": 6031
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6031,
      "Radical": "青",
      "FrequencyRank": 5211,
      "RadicalIndex": "174.8",
      "StrokeCount": 16
    }
  },
  "丰富多彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngfù-duōcǎi",
      "PrimaryDefinition": "richly colorful"
    },
    "Keys": {
      "Word": 6559
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "保健": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎojiàn",
      "PrimaryDefinition": "health care, health protection, care for one's health"
    },
    "Keys": {
      "Word": 4300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "谎言": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǎngyán",
      "PrimaryDefinition": "lie"
    },
    "Keys": {
      "Word": 7196
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "干戈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāngē",
      "PrimaryDefinition": "weapons of war, arms"
    },
    "Keys": {
      "Word": 6664
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "举手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǔ shǒu",
      "PrimaryDefinition": "raise one's hand, haul_off, raise one's hand(s), show, put up one's hand"
    },
    "Keys": {
      "Word": 798
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "最终": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuìzhōng",
      "PrimaryDefinition": "final, ultimate"
    },
    "Keys": {
      "Word": 5413
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "年纪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niánjì",
      "PrimaryDefinition": "years, old age, age"
    },
    "Keys": {
      "Word": 1768
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "熜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cōng",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5994
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5994,
      "Radical": "火",
      "RadicalIndex": "86.11",
      "StrokeCount": 15
    }
  },
  "安宁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ānníng",
      "PrimaryDefinition": "peaceful, tranquil, calm, composed, free from worry"
    },
    "Keys": {
      "Word": 5440
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "悌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tì",
      "PrimaryDefinition": "brotherly, respectful"
    },
    "Keys": {
      "Hanzi": 4663
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4663,
      "Radical": "心",
      "FrequencyRank": 4941,
      "RadicalIndex": "61.7",
      "StrokeCount": 10
    }
  },
  "铆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎo",
      "PrimaryDefinition": "rivet"
    },
    "Keys": {
      "Hanzi": 4539
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4539,
      "Radical": "金",
      "FrequencyRank": 5360,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "叙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xù",
      "PrimaryDefinition": "express, state, relate, narrate"
    },
    "Keys": {
      "Hanzi": 1586
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "29.7",
      "GeneralStandard": 1586,
      "Radical": "又",
      "HSK": 8,
      "FrequencyRank": 1607,
      "StrokeCount": 9
    }
  },
  "灾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zāi",
      "PrimaryDefinition": "calamity, disaster, catastrophe"
    },
    "Keys": {
      "Hanzi": 883,
      "Word": 4166
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.3",
      "Grade": 5,
      "GeneralStandard": 883,
      "Radical": "火",
      "HSK": 5,
      "FrequencyRank": 1349,
      "StrokeCount": 7
    }
  },
  "嗯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ń",
      "PrimaryDefinition": "final particle"
    },
    "Keys": {
      "Hanzi": 5467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.1",
      "GeneralStandard": 5467,
      "Radical": "口",
      "HSK": 5,
      "FrequencyRank": 2042,
      "StrokeCount": 13
    }
  },
  "理直气壮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐzhí-qìzhuàng",
      "PrimaryDefinition": "in the right and self-confident (idiom), bold and confident with justice on one's side, to have the courage of one's convictions, just and forceful"
    },
    "Keys": {
      "Word": 7989
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "诸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhū",
      "PrimaryDefinition": "various, all, these; surname"
    },
    "Keys": {
      "Hanzi": 2110
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "149.8",
      "GeneralStandard": 2110,
      "Radical": "言",
      "HSK": 6,
      "FrequencyRank": 1021,
      "StrokeCount": 10
    }
  },
  "斗争": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòuzhēng",
      "PrimaryDefinition": "battle, fight"
    },
    "Keys": {
      "Word": 4515
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "朗读": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǎngdú",
      "PrimaryDefinition": "to read aloud"
    },
    "Keys": {
      "Word": 3678
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "无私": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúsī",
      "PrimaryDefinition": "selfless, unselfish, disinterested, altruistic"
    },
    "Keys": {
      "Word": 9782
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "核心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "héxīn",
      "PrimaryDefinition": "nub, nucleus, meat, heart, core group, substance, center, core, the center, crux, essence, root, kernel, nitty-gritty, inwardness, pith, sum, marrow, bosom, gist"
    },
    "Keys": {
      "Word": 4659
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "馥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "fragrance, scent, aroma"
    },
    "Keys": {
      "Hanzi": 6347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6347,
      "Radical": "香",
      "FrequencyRank": 4664,
      "RadicalIndex": "186.9",
      "StrokeCount": 18
    }
  },
  "闫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàn",
      "PrimaryDefinition": "village gate"
    },
    "Keys": {
      "Hanzi": 6546
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6546,
      "Radical": "門",
      "FrequencyRank": 5593,
      "RadicalIndex": "169.3",
      "StrokeCount": 6
    }
  },
  "沾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhān",
      "PrimaryDefinition": "moisten, wet, soak; touch"
    },
    "Keys": {
      "Hanzi": 1245,
      "Word": 10633
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "Grade": 7,
      "GeneralStandard": 1245,
      "Radical": "水",
      "HSK": 7,
      "FrequencyRank": 2402,
      "StrokeCount": 8
    }
  },
  "难堪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánkān",
      "PrimaryDefinition": "hard to take, embarrassed"
    },
    "Keys": {
      "Word": 8378
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "缅怀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎnhuái",
      "PrimaryDefinition": "to commemorate, to recall fondly, to think of the past"
    },
    "Keys": {
      "Word": 8281
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "高压": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoyā",
      "PrimaryDefinition": "high pressure, high voltage/tension, coercion, tyranny"
    },
    "Keys": {
      "Word": 6707
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "真的": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn de",
      "PrimaryDefinition": "really, truly, indeed"
    },
    "Keys": {
      "Word": 468
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "移植": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yízhí",
      "PrimaryDefinition": "transplant, graft"
    },
    "Keys": {
      "Word": 10300
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鄃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7207
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7207,
      "Radical": "邑",
      "RadicalIndex": "163.9",
      "StrokeCount": 11
    }
  },
  "赒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōu",
      "PrimaryDefinition": "give for charity"
    },
    "Keys": {
      "Hanzi": 7372
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7372,
      "Radical": "貝",
      "FrequencyRank": 6589,
      "RadicalIndex": "154.8",
      "StrokeCount": 12
    }
  },
  "髭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "mustache"
    },
    "Keys": {
      "Hanzi": 6037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6037,
      "Radical": "髟",
      "FrequencyRank": 4816,
      "RadicalIndex": "190.5",
      "StrokeCount": 16
    }
  },
  "地名": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dìmíng",
      "PrimaryDefinition": "place name, toponym"
    },
    "Keys": {
      "Word": 4501
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "秀丽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiùlì",
      "PrimaryDefinition": "pretty, beautiful"
    },
    "Keys": {
      "Word": 10081
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "juān",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7230
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7230,
      "Radical": "火",
      "RadicalIndex": "86.7",
      "StrokeCount": 11
    }
  },
  "蜣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiāng",
      "PrimaryDefinition": "dung beetle"
    },
    "Keys": {
      "Hanzi": 5463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5463,
      "Radical": "虫",
      "FrequencyRank": 5702,
      "RadicalIndex": "142.8",
      "StrokeCount": 13
    }
  },
  "节奏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiézòu",
      "PrimaryDefinition": "pacing, cadency, musical rhythm, pulse, speech rhythm, rhythm, regular recurrence, pulsation, beat, pace, movement, tune, cadence, clip, tempo"
    },
    "Keys": {
      "Word": 4739
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "厨师": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chúshī",
      "PrimaryDefinition": "cook, chef"
    },
    "Keys": {
      "Word": 4436
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "剧情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùqíng",
      "PrimaryDefinition": "story line, story, plot, gut"
    },
    "Keys": {
      "Word": 7703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "间断": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànduàn",
      "PrimaryDefinition": "disconnected, interrupted, suspended"
    },
    "Keys": {
      "Word": 7425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "埝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàn",
      "PrimaryDefinition": "bank, dike; purtuberance from"
    },
    "Keys": {
      "Hanzi": 4728
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4728,
      "Radical": "土",
      "FrequencyRank": 6650,
      "RadicalIndex": "32.8",
      "StrokeCount": 11
    }
  },
  "疒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìng",
      "PrimaryDefinition": "ill"
    },
    "Keys": {
      "Radical": 104
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "起跑线": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qǐpǎoxiàn",
      "PrimaryDefinition": "the starting line (of a race), scratch line (in a relay race)"
    },
    "Keys": {
      "Word": 8617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "作品": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòpǐn",
      "PrimaryDefinition": "writing, musical composition, whole kit and caboodle, works (of literature, artwork, kit and caboodle, product, whole works, creation, full treatment, kit and boodle, whole kit and boodle, works (of literature/art), composition, thing, piece of music, workpiece, output, art), written material, opus, whole shebang, whole caboodle, production, whole kit, piece, workmanship, wo"
    },
    "Keys": {
      "Word": 2222
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "要好": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yàohǎo",
      "PrimaryDefinition": "to be on good terms, to be close friends, striving for self-improvement"
    },
    "Keys": {
      "Word": 5223
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "辋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǎng",
      "PrimaryDefinition": "exterior rim of wheel, felly"
    },
    "Keys": {
      "Hanzi": 5118
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5118,
      "Radical": "車",
      "FrequencyRank": 6362,
      "RadicalIndex": "159.8",
      "StrokeCount": 12
    }
  },
  "阻挡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǔdǎng",
      "PrimaryDefinition": "to stop, to resist, to obstruct"
    },
    "Keys": {
      "Word": 11002
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "巨星": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jùxīng",
      "PrimaryDefinition": "giant, giant star, superstar"
    },
    "Keys": {
      "Word": 7699
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "擐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "to put on"
    },
    "Keys": {
      "Hanzi": 7836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7836,
      "Radical": "手",
      "FrequencyRank": 6899,
      "RadicalIndex": "64.13",
      "StrokeCount": 16
    }
  },
  "充当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chōngdāng",
      "PrimaryDefinition": "to serve as, to act as, to play the role of"
    },
    "Keys": {
      "Word": 5917
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冷淡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngdàn",
      "PrimaryDefinition": "cold, indifferent"
    },
    "Keys": {
      "Word": 7964
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "基": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jī",
      "PrimaryDefinition": "foundation, base"
    },
    "Keys": {
      "Hanzi": 2193
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.8",
      "GeneralStandard": 2193,
      "Radical": "土",
      "HSK": 3,
      "FrequencyRank": 280,
      "StrokeCount": 11
    }
  },
  "运送": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùnsòng",
      "PrimaryDefinition": "transport, convey"
    },
    "Keys": {
      "Word": 10574
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "比": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐ",
      "PrimaryDefinition": "compare"
    },
    "Keys": {
      "Hanzi": 105,
      "Radical": 81,
      "Word": 29
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "81",
      "Grade": 1,
      "GeneralStandard": 105,
      "Radical": "比",
      "HSK": 1,
      "FrequencyRank": 199,
      "StrokeCount": 4
    }
  },
  "照耀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàoyào",
      "PrimaryDefinition": "to shine, to illuminate"
    },
    "Keys": {
      "Word": 5333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "说白了": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuōbáile",
      "PrimaryDefinition": "to speak frankly"
    },
    "Keys": {
      "Word": 9289
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "神仙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shénxian",
      "PrimaryDefinition": "Daoist immortal, supernatural entity, (in modern fiction) fairy, elf, leprechaun etc, fig. lighthearted person"
    },
    "Keys": {
      "Word": 9038
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "窗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuāng",
      "PrimaryDefinition": "window"
    },
    "Keys": {
      "Hanzi": 2786
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "116.7",
      "GeneralStandard": 2786,
      "Radical": "穴",
      "HSK": 4,
      "FrequencyRank": 1074,
      "StrokeCount": 12
    }
  },
  "参": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cān",
      "PrimaryDefinition": "take part in, intervene; ginseng"
    },
    "Keys": {
      "Hanzi": 1334
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "28.6",
      "GeneralStandard": 1334,
      "Radical": "厶",
      "HSK": 2,
      "FrequencyRank": 507,
      "StrokeCount": 8
    }
  },
  "陡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǒu",
      "PrimaryDefinition": "steep, sloping; abruptly, sudden"
    },
    "Keys": {
      "Hanzi": 1727,
      "Word": 6347
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "170.7",
      "Grade": 7,
      "GeneralStandard": 1727,
      "Radical": "阜",
      "HSK": 9,
      "FrequencyRank": 2362,
      "StrokeCount": 9
    }
  },
  "诐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "argue; biased; one-sided"
    },
    "Keys": {
      "Hanzi": 6628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6628,
      "Radical": "言",
      "FrequencyRank": 7349,
      "RadicalIndex": "149.5",
      "StrokeCount": 7
    }
  },
  "轮子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnzi",
      "PrimaryDefinition": "wheel, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 2723
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "空前": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kōngqián",
      "PrimaryDefinition": "unprecedented"
    },
    "Keys": {
      "Word": 7822
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "师长": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīzhǎng",
      "PrimaryDefinition": "teacher"
    },
    "Keys": {
      "Word": 9093
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "癌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ái",
      "PrimaryDefinition": "cancer; marmoset"
    },
    "Keys": {
      "Hanzi": 3425,
      "Word": 5428
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "104.12",
      "Grade": 7,
      "GeneralStandard": 3425,
      "Radical": "疒",
      "HSK": 7,
      "FrequencyRank": 1799,
      "StrokeCount": 17
    }
  },
  "免费": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miǎn fèi",
      "PrimaryDefinition": "be free of charge, free_of_charge, gratuitous, gratis"
    },
    "Keys": {
      "Word": 2741
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "统一": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǒngyī",
      "PrimaryDefinition": "unify, unite, integrate"
    },
    "Keys": {
      "Word": 2944
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "鹱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8017
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8017,
      "Radical": "鳥",
      "FrequencyRank": 5869,
      "RadicalIndex": "196.14",
      "StrokeCount": 18
    }
  },
  "师傅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shīfu",
      "PrimaryDefinition": "emperor, tutor of king, tutor of emperor, professional, address for service workers, master, general term of address in late 7 0 s an, general term of address in late 70s and 80s, master worker, tutor of king/emperor"
    },
    "Keys": {
      "Word": 3896
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "缄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiān",
      "PrimaryDefinition": "seal, close; bind; letter"
    },
    "Keys": {
      "Hanzi": 5330
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5330,
      "Radical": "糸",
      "FrequencyRank": 3908,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "一举一动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yījǔ-yīdòng",
      "PrimaryDefinition": "every movement, each and every move"
    },
    "Keys": {
      "Word": 10331
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蒉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "edible amaranth; straw basket"
    },
    "Keys": {
      "Hanzi": 7310
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7310,
      "Radical": "艸",
      "FrequencyRank": 7710,
      "RadicalIndex": "140.9",
      "StrokeCount": 12
    }
  },
  "箸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhù",
      "PrimaryDefinition": "chopsticks"
    },
    "Keys": {
      "Hanzi": 5746
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5746,
      "Radical": "竹",
      "FrequencyRank": 5239,
      "RadicalIndex": "118.9",
      "StrokeCount": 14
    }
  },
  "伶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "líng",
      "PrimaryDefinition": "lonely, solitary; actor"
    },
    "Keys": {
      "Hanzi": 779
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "9.5",
      "GeneralStandard": 779,
      "Radical": "人",
      "HSK": 6,
      "FrequencyRank": 2906,
      "StrokeCount": 7
    }
  },
  "岜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bā",
      "PrimaryDefinition": "rock mountain"
    },
    "Keys": {
      "Hanzi": 6596
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6596,
      "Radical": "山",
      "FrequencyRank": 6187,
      "RadicalIndex": "46.4",
      "StrokeCount": 7
    }
  },
  "未来": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèilái",
      "PrimaryDefinition": "future, tomorrow, CL:個|个[ge4], approaching, coming, pending"
    },
    "Keys": {
      "Word": 2976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "簿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "register, account book, notebook"
    },
    "Keys": {
      "Hanzi": 3463
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3463,
      "Radical": "竹",
      "FrequencyRank": 2848,
      "RadicalIndex": "118.13",
      "StrokeCount": 19
    }
  },
  "凶猛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngměng",
      "PrimaryDefinition": "fierce, violent, ferocious"
    },
    "Keys": {
      "Word": 10066
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "后退": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòutuì",
      "PrimaryDefinition": "to recoil, to draw back, to fall back, to retreat"
    },
    "Keys": {
      "Word": 7098
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "辨认": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biànrèn",
      "PrimaryDefinition": "to recognize, to identify"
    },
    "Keys": {
      "Word": 5617
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "雎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jū",
      "PrimaryDefinition": "osprey, fishhawk; hold back"
    },
    "Keys": {
      "Hanzi": 5438
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5438,
      "Radical": "隹",
      "FrequencyRank": 5407,
      "RadicalIndex": "172.5",
      "StrokeCount": 13
    }
  },
  "烔": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóng",
      "PrimaryDefinition": "heat"
    },
    "Keys": {
      "Hanzi": 7050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7050,
      "Radical": "火",
      "RadicalIndex": "86.6",
      "StrokeCount": 10
    }
  },
  "比重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǐzhòng",
      "PrimaryDefinition": "proportion, specific gravity"
    },
    "Keys": {
      "Word": 3246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "获取": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huòqǔ",
      "PrimaryDefinition": "to gain, to get, to acquire"
    },
    "Keys": {
      "Word": 2571
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "汴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biàn",
      "PrimaryDefinition": "name of a river in Henan; Henan"
    },
    "Keys": {
      "Hanzi": 3778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3778,
      "Radical": "水",
      "FrequencyRank": 4359,
      "RadicalIndex": "85.4",
      "StrokeCount": 7
    }
  },
  "赶忙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnmáng",
      "PrimaryDefinition": "to hurry, to hasten, to make haste"
    },
    "Keys": {
      "Word": 4586
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "瑰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guī",
      "PrimaryDefinition": "extraordinary, fabulous; rose"
    },
    "Keys": {
      "Hanzi": 2828
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "96.1",
      "GeneralStandard": 2828,
      "Radical": "玉",
      "HSK": 7,
      "FrequencyRank": 2492,
      "StrokeCount": 13
    }
  },
  "郪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qī",
      "PrimaryDefinition": "name of a stream in Sichuan province"
    },
    "Keys": {
      "Hanzi": 6975
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6975,
      "Radical": "邑",
      "FrequencyRank": 7767,
      "RadicalIndex": "163.8",
      "StrokeCount": 10
    }
  },
  "军": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "army, military; soldiers, troops"
    },
    "Keys": {
      "Hanzi": 552
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "159.2",
      "GeneralStandard": 552,
      "Radical": "車",
      "HSK": 5,
      "FrequencyRank": 102,
      "StrokeCount": 6
    }
  },
  "份额": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèné",
      "PrimaryDefinition": "share, portion"
    },
    "Keys": {
      "Word": 6554
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huá",
      "PrimaryDefinition": "slip, slide; slippery, polished"
    },
    "Keys": {
      "Hanzi": 2761,
      "Word": 3549
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.1",
      "Grade": 5,
      "GeneralStandard": 2761,
      "Radical": "水",
      "HSK": 5,
      "FrequencyRank": 1480,
      "StrokeCount": 12
    }
  },
  "气球": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìqiú",
      "PrimaryDefinition": "balloon"
    },
    "Keys": {
      "Word": 2794
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "坩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "earthenware, earthenware vessel"
    },
    "Keys": {
      "Hanzi": 3836
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3836,
      "Radical": "土",
      "FrequencyRank": 6305,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "伪装": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěizhuāng",
      "PrimaryDefinition": "to pretend to be (asleep etc), to disguise oneself as, pretense, disguise, (military) to camouflage, camouflage"
    },
    "Keys": {
      "Word": 9703
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "祐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòu",
      "PrimaryDefinition": "divine intervention, protection"
    },
    "Keys": {
      "Hanzi": 6905
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6905,
      "Radical": "示",
      "FrequencyRank": 4281,
      "RadicalIndex": "113.5",
      "StrokeCount": 9
    }
  },
  "侮辱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔrǔ",
      "PrimaryDefinition": "to insult, to humiliate, dishonor"
    },
    "Keys": {
      "Word": 9803
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biāo",
      "PrimaryDefinition": "running; herd of horses"
    },
    "Keys": {
      "Hanzi": 6924
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6924,
      "Radical": "馬",
      "RadicalIndex": "187.6",
      "StrokeCount": 9
    }
  },
  "铄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuò",
      "PrimaryDefinition": "melt, smelt; shine"
    },
    "Keys": {
      "Hanzi": 4538
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4538,
      "Radical": "金",
      "FrequencyRank": 5473,
      "RadicalIndex": "167.5",
      "StrokeCount": 10
    }
  },
  "钚": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù",
      "PrimaryDefinition": "plutonium"
    },
    "Keys": {
      "Hanzi": 4239
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4239,
      "Radical": "金",
      "FrequencyRank": 4311,
      "RadicalIndex": "167.4",
      "StrokeCount": 9
    }
  },
  "影": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐng",
      "PrimaryDefinition": "shadow; image, reflection; photograph"
    },
    "Keys": {
      "Hanzi": 3240
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "59.12",
      "GeneralStandard": 3240,
      "Radical": "彡",
      "HSK": 1,
      "FrequencyRank": 390,
      "StrokeCount": 15
    }
  },
  "炉灶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúzào",
      "PrimaryDefinition": "stove"
    },
    "Keys": {
      "Word": 8124
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "聍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "níng",
      "PrimaryDefinition": "earwax"
    },
    "Keys": {
      "Hanzi": 7119
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7119,
      "Radical": "耳",
      "FrequencyRank": 6657,
      "RadicalIndex": "128.5",
      "StrokeCount": 11
    }
  },
  "狲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūn",
      "PrimaryDefinition": "monkey"
    },
    "Keys": {
      "Hanzi": 4295
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4295,
      "Radical": "犬",
      "FrequencyRank": 5797,
      "RadicalIndex": "94.6",
      "StrokeCount": 9
    }
  },
  "描述": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "miáoshù",
      "PrimaryDefinition": "describe"
    },
    "Keys": {
      "Word": 2744
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "䴙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pì",
      "PrimaryDefinition": "a kind of bird resembling the duck; much smaller"
    },
    "Keys": {
      "Hanzi": 8029
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8029,
      "Radical": "",
      "FrequencyRank": 7074,
      "RadicalIndex": "",
      "StrokeCount": 24
    }
  },
  "儿女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "érnǚ",
      "PrimaryDefinition": "sons and daughters, young man and woman, children, young males and females, child"
    },
    "Keys": {
      "Word": 3441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "丢人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diū rén",
      "PrimaryDefinition": "to lose face"
    },
    "Keys": {
      "Word": 6321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tà",
      "PrimaryDefinition": "cot, couch, bed"
    },
    "Keys": {
      "Hanzi": 5662
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5662,
      "Radical": "木",
      "FrequencyRank": 3033,
      "RadicalIndex": "75.1",
      "StrokeCount": 14
    }
  },
  "促成": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cùchéng",
      "PrimaryDefinition": "facilitate, effect"
    },
    "Keys": {
      "Word": 6074
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "删": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shān",
      "PrimaryDefinition": "to cut; delete"
    },
    "Keys": {
      "Hanzi": 815,
      "Word": 8952
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.5",
      "Grade": 7,
      "GeneralStandard": 815,
      "Radical": "刀",
      "HSK": 7,
      "FrequencyRank": 2780,
      "StrokeCount": 7
    }
  },
  "证件": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhèngjiàn",
      "PrimaryDefinition": "certificate, papers, credentials, document, ID"
    },
    "Keys": {
      "Word": 2153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "腕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàn",
      "PrimaryDefinition": "wrist"
    },
    "Keys": {
      "Hanzi": 2716
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.8",
      "GeneralStandard": 2716,
      "Radical": "肉",
      "HSK": 7,
      "FrequencyRank": 2581,
      "StrokeCount": 12
    }
  },
  "水分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuǐfèn",
      "PrimaryDefinition": "moisture content, (fig.) overstatement, padding"
    },
    "Keys": {
      "Word": 3934
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "慢性": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mànxìng",
      "PrimaryDefinition": "chronic, slow (in taking effect)"
    },
    "Keys": {
      "Word": 8205
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "争": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēng",
      "PrimaryDefinition": "dispute, fight, contend, strive"
    },
    "Keys": {
      "Hanzi": 508,
      "Word": 2140
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "6.5",
      "Grade": 3,
      "GeneralStandard": 508,
      "Radical": "亅",
      "HSK": 3,
      "FrequencyRank": 344,
      "StrokeCount": 6
    }
  },
  "作家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zuòjiā",
      "PrimaryDefinition": "penster, scribe, ink-slinger, pen, inkslinger, writer, wright, novelist, litterateur, literator, tragedian, composer, author, penman, biobibliography, bookman"
    },
    "Keys": {
      "Word": 1252
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "肓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāng",
      "PrimaryDefinition": "region between heart and diaphragm"
    },
    "Keys": {
      "Hanzi": 3760
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3760,
      "Radical": "肉",
      "FrequencyRank": 4838,
      "RadicalIndex": "130.3",
      "StrokeCount": 7
    }
  },
  "畴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chóu",
      "PrimaryDefinition": "farmland, arable land; category"
    },
    "Keys": {
      "Hanzi": 2627
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "102.7",
      "GeneralStandard": 2627,
      "Radical": "田",
      "HSK": 9,
      "FrequencyRank": 2699,
      "StrokeCount": 12
    }
  },
  "炸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhà",
      "PrimaryDefinition": "to fry in oil; to scald; to explode"
    },
    "Keys": {
      "Hanzi": 1656,
      "Word": 10628
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "86.5",
      "Grade": 7,
      "GeneralStandard": 1656,
      "Radical": "火",
      "HSK": 6,
      "FrequencyRank": 976,
      "StrokeCount": 9
    }
  },
  "脶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luó",
      "PrimaryDefinition": "lines"
    },
    "Keys": {
      "Hanzi": 7210
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7210,
      "Radical": "肉",
      "FrequencyRank": 7997,
      "RadicalIndex": "130.7",
      "StrokeCount": 11
    }
  },
  "特权": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tèquán",
      "PrimaryDefinition": "regalia, privilege, charter, freedom, vested interest, exclusive right, franchise, perquisite, liberty, peculiar, exclusive, prerogative, vested_interest, faculty, indulgence"
    },
    "Keys": {
      "Word": 9448
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "姑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gū",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 1325
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "38.5",
      "GeneralStandard": 1325,
      "Radical": "女",
      "HSK": 3,
      "FrequencyRank": 994,
      "StrokeCount": 8
    }
  },
  "陷阱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiànjǐng",
      "PrimaryDefinition": "sweetener, pit, pitfall, hook, lure, trap, cobweb, shoal, trapan, booby_trap, noose, come-on, booby-trap, fall-trap, ambush, net, snare, quicksand, springe, decoy, trepan, mesh, bait, gin"
    },
    "Keys": {
      "Word": 9909
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "出主意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chū zhǔyì",
      "PrimaryDefinition": "Idea"
    },
    "Keys": {
      "Word": 5977
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāng",
      "PrimaryDefinition": "torrential; voluminous"
    },
    "Keys": {
      "Hanzi": 5584
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5584,
      "Radical": "水",
      "FrequencyRank": 4424,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "酢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5109
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5109,
      "Radical": "酉",
      "FrequencyRank": 4688,
      "RadicalIndex": "164.5",
      "StrokeCount": 12
    }
  },
  "可歌可泣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kěgē-kěqì",
      "PrimaryDefinition": "lit. you can sing or you can cry (idiom), fig. deeply moving, happy and sad, inspiring and tragic"
    },
    "Keys": {
      "Word": 7797
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "马虎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mǎhu",
      "PrimaryDefinition": "careless, sloppy, negligent, skimpy"
    },
    "Keys": {
      "Word": 8184
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lù",
      "PrimaryDefinition": "the green bamboo; greenish bamboo"
    },
    "Keys": {
      "Hanzi": 7133
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7133,
      "Radical": "艸",
      "FrequencyRank": 7469,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "判处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pànchǔ",
      "PrimaryDefinition": "to sentence, to condemn"
    },
    "Keys": {
      "Word": 8466
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "迷恋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "míliàn",
      "PrimaryDefinition": "to be infatuated with, to indulge in"
    },
    "Keys": {
      "Word": 8258
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "体育": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐyù",
      "PrimaryDefinition": "training, physical education, athletics, sports, gymnastic, physical_education, physical training, physical culture, physical education/training, gym"
    },
    "Keys": {
      "Word": 1019
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "搐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chù",
      "PrimaryDefinition": "cramp, spasm, convulsion; twitch"
    },
    "Keys": {
      "Hanzi": 5359
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5359,
      "Radical": "手",
      "FrequencyRank": 3680,
      "RadicalIndex": "64.1",
      "StrokeCount": 13
    }
  },
  "松树": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōngshù",
      "PrimaryDefinition": "pine, pine tree, CL:棵[ke1]"
    },
    "Keys": {
      "Word": 2903
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "孙女": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sūnnü",
      "PrimaryDefinition": "son's daughter, granddaughter"
    },
    "Keys": {
      "Word": 2912
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "无可奉告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúkě-fènggào",
      "PrimaryDefinition": "(idiom) 'no comment'"
    },
    "Keys": {
      "Word": 9772
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "焕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "shining"
    },
    "Keys": {
      "Hanzi": 2409
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.7",
      "GeneralStandard": 2409,
      "Radical": "火",
      "HSK": 9,
      "FrequencyRank": 2773,
      "StrokeCount": 11
    }
  },
  "事情": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shìqing",
      "PrimaryDefinition": "proposition, pie, shebang, thing, affair, occasions, undertaking, concern, question, business, schmear, matter"
    },
    "Keys": {
      "Word": 983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "记忆犹新": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìyì-yóuxīn",
      "PrimaryDefinition": "to remain fresh in one's memory (idiom)"
    },
    "Keys": {
      "Word": 7321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "玕": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gān",
      "PrimaryDefinition": "inferior variety of gem"
    },
    "Keys": {
      "Hanzi": 3648
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3648,
      "Radical": "玉",
      "RadicalIndex": "96.3",
      "StrokeCount": 7
    }
  },
  "掸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎn",
      "PrimaryDefinition": "to dust; a duster"
    },
    "Keys": {
      "Hanzi": 4737
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4737,
      "Radical": "手",
      "FrequencyRank": 4402,
      "RadicalIndex": "64.8",
      "StrokeCount": 11
    }
  },
  "素养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùyǎng",
      "PrimaryDefinition": "(personal) accomplishment, attainment in self-cultivation"
    },
    "Keys": {
      "Word": 9354
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "必": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bì",
      "PrimaryDefinition": "surely, most certainly; must"
    },
    "Keys": {
      "Hanzi": 321,
      "Word": 3254
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "Grade": 5,
      "GeneralStandard": 321,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 248,
      "StrokeCount": 5
    }
  },
  "号码": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàomǎ",
      "PrimaryDefinition": "pressmark, call mark, identification number, (serial) number, call number, no., number"
    },
    "Keys": {
      "Word": 2542
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "骈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pián",
      "PrimaryDefinition": "team of horses; associate, join"
    },
    "Keys": {
      "Hanzi": 4398
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4398,
      "Radical": "馬",
      "FrequencyRank": 4562,
      "RadicalIndex": "187.6",
      "StrokeCount": 9
    }
  },
  "先": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "first, former, previous"
    },
    "Keys": {
      "Hanzi": 446,
      "Word": 394
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "10.4",
      "Grade": 1,
      "GeneralStandard": 446,
      "Radical": "儿",
      "HSK": 1,
      "FrequencyRank": 188,
      "StrokeCount": 6
    }
  },
  "海运": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎiyùn",
      "PrimaryDefinition": "sea transportation, ocean shipping"
    },
    "Keys": {
      "Word": 6972
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "豢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huàn",
      "PrimaryDefinition": "domestic animals; feed, raise"
    },
    "Keys": {
      "Hanzi": 5556
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5556,
      "Radical": "豕",
      "FrequencyRank": 3718,
      "RadicalIndex": "152.6",
      "StrokeCount": 13
    }
  },
  "嘲弄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cháonòng",
      "PrimaryDefinition": "to tease, to poke fun at, to make fun of"
    },
    "Keys": {
      "Word": 5833
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "承办": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chéngbàn",
      "PrimaryDefinition": "undertake"
    },
    "Keys": {
      "Word": 3326
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "饭店": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fàndiàn",
      "PrimaryDefinition": "restraurant, restaurant, hotel"
    },
    "Keys": {
      "Word": 99
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "廴": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǐn",
      "PrimaryDefinition": "stride"
    },
    "Keys": {
      "Radical": 54
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
    ],
    "Metadata": {}
  },
  "升值": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngzhí",
      "PrimaryDefinition": "revalue, appreciate"
    },
    "Keys": {
      "Word": 5012
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "天然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiānrán",
      "PrimaryDefinition": "natural"
    },
    "Keys": {
      "Word": 5080
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "构思": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòusī",
      "PrimaryDefinition": "to design, to plot, to plan out, to compose, to draw a mental sketch, conception, plan, idea, composition"
    },
    "Keys": {
      "Word": 6813
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "如果": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rúguǒ",
      "PrimaryDefinition": "conj.: if"
    },
    "Keys": {
      "Word": 961
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "精益求精": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngyìqiújīng",
      "PrimaryDefinition": "to perfect sth that is already outstanding (idiom), constantly improving"
    },
    "Keys": {
      "Word": 7624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "糖尿病": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tángniàobìng",
      "PrimaryDefinition": "diabetic, diabetes, glycosuria"
    },
    "Keys": {
      "Word": 9425
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蛹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒng",
      "PrimaryDefinition": "chrysalis, larva"
    },
    "Keys": {
      "Hanzi": 5465
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5465,
      "Radical": "虫",
      "FrequencyRank": 3974,
      "RadicalIndex": "142.7",
      "StrokeCount": 13
    }
  },
  "腹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "stomach, belly, abdomen; inside"
    },
    "Keys": {
      "Hanzi": 2972
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "130.9",
      "GeneralStandard": 2972,
      "Radical": "肉",
      "HSK": 8,
      "FrequencyRank": 1589,
      "StrokeCount": 13
    }
  },
  "丈夫": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhàngfu",
      "PrimaryDefinition": "masterman, man, papa, manliness, mister, goodman, married man, husband, hub, hubby"
    },
    "Keys": {
      "Word": 3153
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "谋生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "móushēng",
      "PrimaryDefinition": "to seek one's livelihood, to work to support oneself, to earn a living"
    },
    "Keys": {
      "Word": 8339
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "预算": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yùsuàn",
      "PrimaryDefinition": "estimate, forethought, budget, estimation, budgetary"
    },
    "Keys": {
      "Word": 10520
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "留念": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liúniàn",
      "PrimaryDefinition": "to keep as a souvenir, to recall fondly"
    },
    "Keys": {
      "Word": 8092
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "平原": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "píngyuán",
      "PrimaryDefinition": "champaign, plain, campagna, field, park, flatlands"
    },
    "Keys": {
      "Word": 3780
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "感受": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎnshòu",
      "PrimaryDefinition": "perception, sentiment, mood"
    },
    "Keys": {
      "Word": 1478
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "不停": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùtíng",
      "PrimaryDefinition": "non-stop"
    },
    "Keys": {
      "Word": 3283
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "说": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuō",
      "PrimaryDefinition": "speak, say, talk; scold, upbraid"
    },
    "Keys": {
      "Hanzi": 1717,
      "Word": 344
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "149.7",
      "Grade": 1,
      "GeneralStandard": 1717,
      "Radical": "言",
      "HSK": 1,
      "FrequencyRank": 24,
      "StrokeCount": 9
    }
  },
  "班": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bān",
      "PrimaryDefinition": "class, group, grade; squad; job"
    },
    "Keys": {
      "Hanzi": 1774,
      "Word": 7
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "96.6",
      "Grade": 1,
      "GeneralStandard": 1774,
      "Radical": "玉",
      "HSK": 1,
      "FrequencyRank": 884,
      "StrokeCount": 10
    }
  },
  "衍生": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnshēng",
      "PrimaryDefinition": "derive, form"
    },
    "Keys": {
      "Word": 10188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "竞选": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìngxuǎn",
      "PrimaryDefinition": "campaign (for office), run for"
    },
    "Keys": {
      "Word": 7636
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "料理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liàolǐ",
      "PrimaryDefinition": "to arrange, to handle, to cook, cuisine, art of cooking"
    },
    "Keys": {
      "Word": 8049
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "生动": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngdòng",
      "PrimaryDefinition": "lively, moving, vivid, lifelike"
    },
    "Keys": {
      "Word": 1885
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "连接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánjiē",
      "PrimaryDefinition": "associate, coupled, conjoin, join, cable, interface, tie_in, joint, tie in, couple up, connect, bring together, couple, relate, conjunct, unite, couple_on, tie, couple on, attach, link"
    },
    "Keys": {
      "Word": 3689
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "童年": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tóngnián",
      "PrimaryDefinition": "childhood"
    },
    "Keys": {
      "Word": 2943
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "炟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dá",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6878
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6878,
      "Radical": "火",
      "RadicalIndex": "86.5",
      "StrokeCount": 9
    }
  },
  "滑稽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huáji",
      "PrimaryDefinition": "comical, funny, amusing, old pr. [gu3 ji1], huaji, a form of comedy performance popular in Shanghai, Jiangsu and Zhejiang"
    },
    "Keys": {
      "Word": 7136
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "觜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zī",
      "PrimaryDefinition": "beak"
    },
    "Keys": {
      "Hanzi": 7515
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7515,
      "Radical": "角",
      "FrequencyRank": 7627,
      "RadicalIndex": "148.6",
      "StrokeCount": 13
    }
  },
  "曾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "céng",
      "PrimaryDefinition": "already; sign of past"
    },
    "Keys": {
      "Hanzi": 2747,
      "Word": 2299
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "73.8",
      "Grade": 4,
      "GeneralStandard": 2747,
      "Radical": "曰",
      "HSK": 3,
      "FrequencyRank": 463,
      "StrokeCount": 12
    }
  },
  "打扰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎrǎo",
      "PrimaryDefinition": "to disturb, to bother, to trouble"
    },
    "Keys": {
      "Word": 3376
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "指标": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǐbiāo",
      "PrimaryDefinition": "directive, characteristic, guideline, quota, index, norm, indication, fingerboard, indicant, loadstar, guiding principle, target, needle, fingerpost, pointer, indicator"
    },
    "Keys": {
      "Word": 4228
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "输家": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shūjia",
      "PrimaryDefinition": "loser"
    },
    "Keys": {
      "Word": 9220
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "截至": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiézhì",
      "PrimaryDefinition": "by (specified time), up to"
    },
    "Keys": {
      "Word": 4736
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "宓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mì",
      "PrimaryDefinition": "quiet, silent; in good health"
    },
    "Keys": {
      "Hanzi": 4037
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4037,
      "Radical": "宀",
      "FrequencyRank": 4271,
      "RadicalIndex": "40.5",
      "StrokeCount": 8
    }
  },
  "迓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yà",
      "PrimaryDefinition": "to go to meet, to receive, as a guest"
    },
    "Keys": {
      "Hanzi": 3692
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3692,
      "Radical": "辵",
      "FrequencyRank": 5992,
      "RadicalIndex": "162.4",
      "StrokeCount": 7
    }
  },
  "火灾": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒzāi",
      "PrimaryDefinition": "fire disaster, conflagration"
    },
    "Keys": {
      "Word": 3573
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "携带": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiédài",
      "PrimaryDefinition": "bring, schlep, schlepp, take along, carry"
    },
    "Keys": {
      "Word": 9991
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "胜": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèng",
      "PrimaryDefinition": "victory; excel, be better than"
    },
    "Keys": {
      "Hanzi": 1594,
      "Word": 1891
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "130.5",
      "Grade": 3,
      "GeneralStandard": 1594,
      "Radical": "肉",
      "HSK": 3,
      "FrequencyRank": 743,
      "StrokeCount": 9
    }
  },
  "培": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "péi",
      "PrimaryDefinition": "bank up with dirt; cultivate"
    },
    "Keys": {
      "Hanzi": 2184
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "32.8",
      "GeneralStandard": 2184,
      "Radical": "土",
      "HSK": 4,
      "FrequencyRank": 1152,
      "StrokeCount": 11
    }
  },
  "种": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhǒng",
      "PrimaryDefinition": "seed; race, breed; to plant"
    },
    "Keys": {
      "Hanzi": 1549,
      "Word": 3185
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "115.4",
      "Grade": 4,
      "GeneralStandard": 1549,
      "Radical": "禾",
      "HSK": 3,
      "FrequencyRank": 57,
      "StrokeCount": 9
    }
  },
  "刻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kè",
      "PrimaryDefinition": "carve, engrave; quarter hour"
    },
    "Keys": {
      "Hanzi": 1224,
      "Word": 3661
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "18.6",
      "Grade": 5,
      "GeneralStandard": 1224,
      "Radical": "刀",
      "HSK": 2,
      "FrequencyRank": 618,
      "StrokeCount": 8
    }
  },
  "理发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐ fà",
      "PrimaryDefinition": "a barber, hairdressing"
    },
    "Keys": {
      "Word": 1695
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "应对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngduì",
      "PrimaryDefinition": "reply, answer"
    },
    "Keys": {
      "Word": 5267
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "歌词": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gēcí",
      "PrimaryDefinition": "words of song, lyrics"
    },
    "Keys": {
      "Word": 4600
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "礌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "léi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7995
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7995,
      "Radical": "石",
      "FrequencyRank": 6502,
      "RadicalIndex": "112.13",
      "StrokeCount": 18
    }
  },
  "自信心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zìxìnxīn",
      "PrimaryDefinition": "self-confidence"
    },
    "Keys": {
      "Word": 10967
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坭": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ní",
      "PrimaryDefinition": "mud, mire; to paste, to plaster"
    },
    "Keys": {
      "Hanzi": 3850
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3850,
      "Radical": "土",
      "FrequencyRank": 6808,
      "RadicalIndex": "32.5",
      "StrokeCount": 8
    }
  },
  "慎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèn",
      "PrimaryDefinition": "act with care, be cautious"
    },
    "Keys": {
      "Hanzi": 3023
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.1",
      "GeneralStandard": 3023,
      "Radical": "心",
      "HSK": 7,
      "FrequencyRank": 1765,
      "StrokeCount": 13
    }
  },
  "酿造": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niàngzào",
      "PrimaryDefinition": "to brew, to make (wine, vinegar, soybean paste etc) by fermentation"
    },
    "Keys": {
      "Word": 8419
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一长一短": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yī cháng yī duǎn",
      "PrimaryDefinition": "A long one"
    },
    "Keys": {
      "Word": 10321
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "怂": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǒng",
      "PrimaryDefinition": "instigate, arouse, incite"
    },
    "Keys": {
      "Hanzi": 3973
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3973,
      "Radical": "心",
      "FrequencyRank": 3702,
      "RadicalIndex": "61.4",
      "StrokeCount": 8
    }
  },
  "子弟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐdì",
      "PrimaryDefinition": "sons and younger brothers, children, juniors"
    },
    "Keys": {
      "Word": 10948
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "滨海": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bīnhǎi",
      "PrimaryDefinition": "Binhai (place name), Binhai New District, subprovincial district of Tianjin, Binhai county in Yancheng 鹽城|盐城[Yan2 cheng2], Jiangsu, fictitious city Binhai in political satire, coastal, bordering the sea"
    },
    "Keys": {
      "Word": 5647
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "涵盖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hángài",
      "PrimaryDefinition": "cover, include"
    },
    "Keys": {
      "Word": 6983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "皙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xī",
      "PrimaryDefinition": "white; kind of date"
    },
    "Keys": {
      "Hanzi": 5401
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5401,
      "Radical": "白",
      "FrequencyRank": 4315,
      "RadicalIndex": "106.8",
      "StrokeCount": 13
    }
  },
  "彟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yuē",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8049
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8049,
      "Radical": "彐",
      "RadicalIndex": "58.23",
      "StrokeCount": 19
    }
  },
  "荇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìng",
      "PrimaryDefinition": "a water plant, Nymphoides peltalum"
    },
    "Keys": {
      "Hanzi": 4121
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4121,
      "Radical": "艸",
      "FrequencyRank": 6456,
      "RadicalIndex": "140.6",
      "StrokeCount": 9
    }
  },
  "尖锐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānruì",
      "PrimaryDefinition": "sharp-pointed, sharpen, penetrate, acuminate, shrill, sharp, sharpened, intense, penetrating, piercing, incisive, acute"
    },
    "Keys": {
      "Word": 7374
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "印刷术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìnshuāshù",
      "PrimaryDefinition": "printing, printing technology"
    },
    "Keys": {
      "Word": 10407
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pēi",
      "PrimaryDefinition": "expression of reprimand"
    },
    "Keys": {
      "Hanzi": 3902
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3902,
      "Radical": "口",
      "FrequencyRank": 3868,
      "RadicalIndex": "30.5",
      "StrokeCount": 8
    }
  },
  "痛苦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòngkǔ",
      "PrimaryDefinition": "be painful; pain"
    },
    "Keys": {
      "Word": 1976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "把手": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bǎshou",
      "PrimaryDefinition": "handle, grip, knob"
    },
    "Keys": {
      "Word": 5464
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "垧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǎng",
      "PrimaryDefinition": "variable unit of land measure"
    },
    "Keys": {
      "Hanzi": 4104
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4104,
      "Radical": "土",
      "FrequencyRank": 6275,
      "RadicalIndex": "32.6",
      "StrokeCount": 9
    }
  },
  "针锋相对": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēnfēng-xiāngduì",
      "PrimaryDefinition": "to oppose each other with equal harshness (idiom), tit for tat, measure for measure"
    },
    "Keys": {
      "Word": 10692
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "跣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎn",
      "PrimaryDefinition": "bare footed"
    },
    "Keys": {
      "Hanzi": 5456
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5456,
      "Radical": "足",
      "FrequencyRank": 5704,
      "RadicalIndex": "157.6",
      "StrokeCount": 13
    }
  },
  "天才": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tiāncái",
      "PrimaryDefinition": "genius, talent, gift"
    },
    "Keys": {
      "Word": 3976
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "图画": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "túhuà",
      "PrimaryDefinition": "drawing, picture"
    },
    "Keys": {
      "Word": 1983
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "甚至于": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shènzhìyú",
      "PrimaryDefinition": "even (to the point of), so much so that"
    },
    "Keys": {
      "Word": 9047
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "规则": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guīzé",
      "PrimaryDefinition": "rule, regulation"
    },
    "Keys": {
      "Word": 2523
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "一口气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīkǒuqì",
      "PrimaryDefinition": "without a break, at one go, in one breath, holus-bolus"
    },
    "Keys": {
      "Word": 4115
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "慌张": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huāngzhang",
      "PrimaryDefinition": "confused, flustered"
    },
    "Keys": {
      "Word": 7188
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "不以为然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bùyǐwéirán",
      "PrimaryDefinition": "disagree, not approve of, acceptable, object to, consider sth. not so, take exception to, right"
    },
    "Keys": {
      "Word": 5718
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蠹": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dù",
      "PrimaryDefinition": "moth; insects which eat into clot"
    },
    "Keys": {
      "Hanzi": 6487
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6487,
      "Radical": "虫",
      "FrequencyRank": 4607,
      "RadicalIndex": "142.18",
      "StrokeCount": 24
    }
  },
  "显示": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiǎnshì",
      "PrimaryDefinition": "demonstrate, argue, exhibit, prove, display, show, illustrate, manifest, demonstration, announce, stamp, unroll, witness, write, give, evince, dangle, betray, bespeak, say, profess, develop, indicate, signal, flaunt, read, register, manifestation, speak, reveal, evidence, tell, shew, proclaim, discover, stage, bear, establish, record, prognosticate, betoken, note, signalize"
    },
    "Keys": {
      "Word": 2032
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "菌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jūn",
      "PrimaryDefinition": "mushroom; germ, microbe"
    },
    "Keys": {
      "Hanzi": 2205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "140.8",
      "GeneralStandard": 2205,
      "Radical": "艸",
      "HSK": 6,
      "FrequencyRank": 1733,
      "StrokeCount": 11
    }
  },
  "西医": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīyī",
      "PrimaryDefinition": "Western medicine, a doctor trained in Western medicine"
    },
    "Keys": {
      "Word": 1073
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "机智": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīzhì",
      "PrimaryDefinition": "quick-witted, resourceful"
    },
    "Keys": {
      "Word": 7268
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "理所当然": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐsuǒdāngrán",
      "PrimaryDefinition": "of course, naturally"
    },
    "Keys": {
      "Word": 7987
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "供暖": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngnuǎn",
      "PrimaryDefinition": "Heating"
    },
    "Keys": {
      "Word": 6796
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "妩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wǔ",
      "PrimaryDefinition": "charming, enchanting"
    },
    "Keys": {
      "Hanzi": 3807
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3807,
      "Radical": "女",
      "FrequencyRank": 4073,
      "RadicalIndex": "38.4",
      "StrokeCount": 7
    }
  },
  "招待": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāodài",
      "PrimaryDefinition": "receive (guests), serve (customers)"
    },
    "Keys": {
      "Word": 10661
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "靸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sǎ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 5082
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5082,
      "Radical": "革",
      "FrequencyRank": 6857,
      "RadicalIndex": "177.4",
      "StrokeCount": 12
    }
  },
  "间接": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiànjiē",
      "PrimaryDefinition": "collaterally, indirect, collateral, indirectly, roundabout, secondhand"
    },
    "Keys": {
      "Word": 3601
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "殡": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bìn",
      "PrimaryDefinition": "encoffin; embalm; funeral"
    },
    "Keys": {
      "Hanzi": 5683
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5683,
      "Radical": "歹",
      "FrequencyRank": 3965,
      "RadicalIndex": "78.1",
      "StrokeCount": 14
    }
  },
  "馋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "gluttonous, greedy; lewd, lecherous"
    },
    "Keys": {
      "Hanzi": 2725,
      "Word": 5797
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "184.9",
      "Grade": 7,
      "GeneralStandard": 2725,
      "Radical": "食",
      "HSK": 9,
      "FrequencyRank": 3844,
      "StrokeCount": 12
    }
  },
  "得分": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dé fēn",
      "PrimaryDefinition": "score"
    },
    "Keys": {
      "Word": 1401
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "股份": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔfèn",
      "PrimaryDefinition": "equity, concern, share, stake, stock, interest"
    },
    "Keys": {
      "Word": 6830
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "乐趣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lèqù",
      "PrimaryDefinition": "amusement, playfulness, fun, merriment, pleasure, sweet, delight, bang, entertainment, enjoyment, pleasance, joy"
    },
    "Keys": {
      "Word": 2688
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "通缉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tōngjī",
      "PrimaryDefinition": "to order the arrest of sb as criminal, to list as wanted"
    },
    "Keys": {
      "Word": 9526
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "谶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chèn",
      "PrimaryDefinition": "prophecy, hint, omen"
    },
    "Keys": {
      "Hanzi": 6416
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6416,
      "Radical": "言",
      "FrequencyRank": 4762,
      "RadicalIndex": "149.17",
      "StrokeCount": 19
    }
  },
  "打发": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dǎfa",
      "PrimaryDefinition": "to dispatch sb to do sth, to make sb leave, to pass (the time), (old) to make arrangements, (old) to bestow (alms etc)"
    },
    "Keys": {
      "Word": 4469
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "遁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dùn",
      "PrimaryDefinition": "hide, conceal oneself; escape"
    },
    "Keys": {
      "Hanzi": 5216
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5216,
      "Radical": "辵",
      "FrequencyRank": 3222,
      "RadicalIndex": "162.9",
      "StrokeCount": 12
    }
  },
  "梼": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táo",
      "PrimaryDefinition": "block of wood; blockhead; stupid"
    },
    "Keys": {
      "Hanzi": 7135
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7135,
      "Radical": "木",
      "RadicalIndex": "75.7",
      "StrokeCount": 11
    }
  },
  "拍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāi",
      "PrimaryDefinition": "clap, tap, beat; beat or rhythm"
    },
    "Keys": {
      "Hanzi": 970,
      "Word": 1778
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "64.5",
      "Grade": 3,
      "GeneralStandard": 970,
      "Radical": "手",
      "HSK": 3,
      "FrequencyRank": 1167,
      "StrokeCount": 8
    }
  },
  "陧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niè",
      "PrimaryDefinition": "in disorder; a dangerous condition of the State"
    },
    "Keys": {
      "Hanzi": 6908
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6908,
      "Radical": "阜",
      "FrequencyRank": 7158,
      "RadicalIndex": "170.7",
      "StrokeCount": 9
    }
  },
  "筢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pá",
      "PrimaryDefinition": "rake"
    },
    "Keys": {
      "Hanzi": 5501
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5501,
      "Radical": "竹",
      "FrequencyRank": 7320,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "设置": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shèzhì",
      "PrimaryDefinition": "set/put up, install"
    },
    "Keys": {
      "Word": 2846
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "意": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yì",
      "PrimaryDefinition": "thought, idea, opinion; think"
    },
    "Keys": {
      "Hanzi": 2996
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "61.9",
      "GeneralStandard": 2996,
      "Radical": "心",
      "HSK": 2,
      "FrequencyRank": 104,
      "StrokeCount": 13
    }
  },
  "逅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hòu",
      "PrimaryDefinition": "meet unexpectedly"
    },
    "Keys": {
      "Hanzi": 4268
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4268,
      "Radical": "辵",
      "FrequencyRank": 4468,
      "RadicalIndex": "162.6",
      "StrokeCount": 9
    }
  },
  "频频": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pínpín",
      "PrimaryDefinition": "again and again, repeatedly"
    },
    "Keys": {
      "Word": 8536
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "晤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wù",
      "PrimaryDefinition": "have interview with; meet"
    },
    "Keys": {
      "Hanzi": 4813
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.7",
      "GeneralStandard": 4813,
      "Radical": "日",
      "HSK": 9,
      "FrequencyRank": 2719,
      "StrokeCount": 11
    }
  },
  "媪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "ǎo",
      "PrimaryDefinition": "old woman; lower-class woman"
    },
    "Keys": {
      "Hanzi": 5320
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5320,
      "Radical": "女",
      "FrequencyRank": 5196,
      "RadicalIndex": "38.9",
      "StrokeCount": 12
    }
  },
  "素描": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sùmiáo",
      "PrimaryDefinition": "sketch"
    },
    "Keys": {
      "Word": 9353
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "精确": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīngquè",
      "PrimaryDefinition": "elegant, accurate, precise, exact"
    },
    "Keys": {
      "Word": 7618
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "三角": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sānjiǎo",
      "PrimaryDefinition": "triangle"
    },
    "Keys": {
      "Word": 8918
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "裸露": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "luǒlù",
      "PrimaryDefinition": "naked, bare, uncovered, exposed"
    },
    "Keys": {
      "Word": 8168
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "汹涌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiōngyǒng",
      "PrimaryDefinition": "to surge up violently (of ocean, river, lake etc), turbulent"
    },
    "Keys": {
      "Word": 10067
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "臌": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǔ",
      "PrimaryDefinition": "dropsical swelling; puffy bloated"
    },
    "Keys": {
      "Hanzi": 6272
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6272,
      "Radical": "肉",
      "FrequencyRank": 6980,
      "RadicalIndex": "130.13",
      "StrokeCount": 17
    }
  },
  "筶": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7544
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7544,
      "Radical": "竹",
      "FrequencyRank": 8857,
      "RadicalIndex": "118.7",
      "StrokeCount": 13
    }
  },
  "喂养": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wèiyǎng",
      "PrimaryDefinition": "to feed (a child, domestic animal etc), to keep, to raise (an animal)"
    },
    "Keys": {
      "Word": 9722
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "应聘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìngpìn",
      "PrimaryDefinition": "to accept a job offer, to apply for an advertised position"
    },
    "Keys": {
      "Word": 10424
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "联络": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánluò",
      "PrimaryDefinition": "contact"
    },
    "Keys": {
      "Word": 3691
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "氘": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dāo",
      "PrimaryDefinition": "deuterium"
    },
    "Keys": {
      "Hanzi": 3599
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3599,
      "Radical": "气",
      "FrequencyRank": 5280,
      "RadicalIndex": "84.2",
      "StrokeCount": 6
    }
  },
  "智": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhì",
      "PrimaryDefinition": "wisdom, knowledge, intelligence"
    },
    "Keys": {
      "Hanzi": 2670
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "72.8",
      "GeneralStandard": 2670,
      "Radical": "日",
      "HSK": 4,
      "FrequencyRank": 885,
      "StrokeCount": 12
    }
  },
  "囟": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xìn",
      "PrimaryDefinition": "top of the head; skull"
    },
    "Keys": {
      "Hanzi": 3611
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3611,
      "Radical": "囗",
      "FrequencyRank": 5175,
      "RadicalIndex": "31.3",
      "StrokeCount": 6
    }
  },
  "蒺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jí",
      "PrimaryDefinition": "furze; gorse"
    },
    "Keys": {
      "Hanzi": 5383
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5383,
      "Radical": "艸",
      "FrequencyRank": 5311,
      "RadicalIndex": "140.1",
      "StrokeCount": 13
    }
  },
  "着实": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuóshí",
      "PrimaryDefinition": "truly, indeed, severely, harshly"
    },
    "Keys": {
      "Word": 10933
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "嫌疑": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiányí",
      "PrimaryDefinition": "cloud, suspicion"
    },
    "Keys": {
      "Word": 9894
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "坍": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tān",
      "PrimaryDefinition": "collapse; landslide"
    },
    "Keys": {
      "Hanzi": 3654
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3654,
      "Radical": "土",
      "FrequencyRank": 3515,
      "RadicalIndex": "32.4",
      "StrokeCount": 7
    }
  },
  "雱": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pāng",
      "PrimaryDefinition": "snowing heavily"
    },
    "Keys": {
      "Hanzi": 5116
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5116,
      "Radical": "雨",
      "FrequencyRank": 5375,
      "RadicalIndex": "173.4",
      "StrokeCount": 12
    }
  },
  "透过": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tòuguò",
      "PrimaryDefinition": "via"
    },
    "Keys": {
      "Word": 9568
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "贡献": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gòngxiàn",
      "PrimaryDefinition": "contribution"
    },
    "Keys": {
      "Word": 4615
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "用人": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yòngren",
      "PrimaryDefinition": "make use of personnel, employ people"
    },
    "Keys": {
      "Word": 10443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "女士": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nǚshì",
      "PrimaryDefinition": "senorita, lady, Ms., mum, Senora, educated girl, educated woman, madonna, madam, Miss, woman, ma'am, Madame, madame, gentlewoman, senhorita, dame, mademoiselle"
    },
    "Keys": {
      "Word": 2766
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "麻辣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "málà",
      "PrimaryDefinition": "hot and numbing"
    },
    "Keys": {
      "Word": 8181
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "笊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhào",
      "PrimaryDefinition": "ladle, bamboo skimmer"
    },
    "Keys": {
      "Hanzi": 4558
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4558,
      "Radical": "竹",
      "FrequencyRank": 5353,
      "RadicalIndex": "118.4",
      "StrokeCount": 10
    }
  },
  "铰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎo",
      "PrimaryDefinition": "hinge; shears, scissors"
    },
    "Keys": {
      "Hanzi": 4883
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4883,
      "Radical": "金",
      "FrequencyRank": 4630,
      "RadicalIndex": "167.6",
      "StrokeCount": 11
    }
  },
  "轮廓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lúnkuò",
      "PrimaryDefinition": "outline, contour, rough sketch"
    },
    "Keys": {
      "Word": 8160
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "被告": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bèigào",
      "PrimaryDefinition": "tenant, indictee, accused, respondent, defendant, suspect, the accused, appellee"
    },
    "Keys": {
      "Word": 4317
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "锺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhōng",
      "PrimaryDefinition": "cup, glass, goblet; surname"
    },
    "Keys": {
      "Hanzi": 7679
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7679,
      "Radical": "金",
      "FrequencyRank": 4201,
      "RadicalIndex": "167.9",
      "StrokeCount": 14
    }
  },
  "分享": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēnxiǎng",
      "PrimaryDefinition": "share (joy/rights/etc.), partake of"
    },
    "Keys": {
      "Word": 3461
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "敩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 7441
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7441,
      "Radical": "攴",
      "RadicalIndex": "66.16",
      "StrokeCount": 12
    }
  },
  "骇人听闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hàirén-tīngwén",
      "PrimaryDefinition": "shocking, horrifying, atrocious, terrible"
    },
    "Keys": {
      "Word": 6974
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "鹁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bó",
      "PrimaryDefinition": "species of pigeon"
    },
    "Keys": {
      "Hanzi": 5106
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5106,
      "Radical": "鳥",
      "FrequencyRank": 5577,
      "RadicalIndex": "196.7",
      "StrokeCount": 12
    }
  },
  "建交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiàn jiāo",
      "PrimaryDefinition": "to establish diplomatic relations"
    },
    "Keys": {
      "Word": 7428
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "呙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guō",
      "PrimaryDefinition": "chat, jaw, gossip, talk; mouth"
    },
    "Keys": {
      "Hanzi": 6591
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6591,
      "Radical": "口",
      "FrequencyRank": 6323,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "杯子": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bēizi",
      "PrimaryDefinition": "tumbler, drinking glass, cup, glass"
    },
    "Keys": {
      "Word": 18
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "堤": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dī",
      "PrimaryDefinition": "dike"
    },
    "Keys": {
      "Hanzi": 2519,
      "Word": 6233
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "32.9",
      "Grade": 7,
      "GeneralStandard": 2519,
      "Radical": "土",
      "HSK": 8,
      "FrequencyRank": 2464,
      "StrokeCount": 12
    }
  },
  "会谈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huìtán",
      "PrimaryDefinition": "talk"
    },
    "Keys": {
      "Word": 3566
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "幕后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mùhòu",
      "PrimaryDefinition": "behind the scenes"
    },
    "Keys": {
      "Word": 8360
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "产地": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chǎndì",
      "PrimaryDefinition": "the source (of a product), place of origin, manufacturing location"
    },
    "Keys": {
      "Word": 5801
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "下雨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiàyǔ",
      "PrimaryDefinition": "rain"
    },
    "Keys": {
      "Word": 393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "氙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiān",
      "PrimaryDefinition": "xenon"
    },
    "Keys": {
      "Hanzi": 3725
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3725,
      "Radical": "气",
      "FrequencyRank": 6030,
      "RadicalIndex": "84.3",
      "StrokeCount": 7
    }
  },
  "迷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mí",
      "PrimaryDefinition": "bewitch, charm, infatuate"
    },
    "Keys": {
      "Hanzi": 1647,
      "Word": 1744
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "162.6",
      "Grade": 3,
      "GeneralStandard": 1647,
      "Radical": "辵",
      "HSK": 3,
      "FrequencyRank": 1153,
      "StrokeCount": 9
    }
  },
  "刹车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shāchē",
      "PrimaryDefinition": "to brake (when driving), to stop, to switch off, to check (bad habits), a brake"
    },
    "Keys": {
      "Word": 8939
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "礼仪": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǐyí",
      "PrimaryDefinition": "etiquette, ceremony"
    },
    "Keys": {
      "Word": 7980
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "张灯结彩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhāngdēng-jiécǎi",
      "PrimaryDefinition": "to be decorated with lanterns and colored banners (idiom)"
    },
    "Keys": {
      "Word": 10648
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "蔃": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiáng",
      "PrimaryDefinition": "(Cant.) roots of plants"
    },
    "Keys": {
      "Hanzi": 7758
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7758,
      "Radical": "艸",
      "RadicalIndex": "140.11",
      "StrokeCount": 15
    }
  },
  "挨家挨户": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "āijiā-āihù",
      "PrimaryDefinition": "to go from house to house, house-to-house (search)"
    },
    "Keys": {
      "Word": 5426
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "大奖赛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàjiǎngsài",
      "PrimaryDefinition": "Grand prize"
    },
    "Keys": {
      "Word": 3382
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "传媒": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chuánméi",
      "PrimaryDefinition": "media"
    },
    "Keys": {
      "Word": 4441
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "霸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bà",
      "PrimaryDefinition": "rule by might rather than right"
    },
    "Keys": {
      "Hanzi": 3489
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "173.13",
      "GeneralStandard": 3489,
      "Radical": "雨",
      "HSK": 9,
      "FrequencyRank": 1838,
      "StrokeCount": 21
    }
  },
  "茆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "máo",
      "PrimaryDefinition": "species of grass, water mallows"
    },
    "Keys": {
      "Hanzi": 3867
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3867,
      "Radical": "艸",
      "FrequencyRank": 6629,
      "RadicalIndex": "140.5",
      "StrokeCount": 8
    }
  },
  "泽": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zé",
      "PrimaryDefinition": "marsh, swamp; grace, brilliance"
    },
    "Keys": {
      "Hanzi": 1263
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "85.5",
      "GeneralStandard": 1263,
      "Radical": "水",
      "HSK": 8,
      "FrequencyRank": 951,
      "StrokeCount": 8
    }
  },
  "表面上": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "biǎomiànshang",
      "PrimaryDefinition": "Surface on the surface"
    },
    "Keys": {
      "Word": 4337
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "夙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sù",
      "PrimaryDefinition": "early in morning, dawn; previous"
    },
    "Keys": {
      "Hanzi": 3614
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3614,
      "Radical": "夕",
      "FrequencyRank": 4303,
      "RadicalIndex": "36.3",
      "StrokeCount": 6
    }
  },
  "愦": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kuì",
      "PrimaryDefinition": "confused, troubled, muddle-headed"
    },
    "Keys": {
      "Hanzi": 5296
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5296,
      "Radical": "心",
      "FrequencyRank": 5744,
      "RadicalIndex": "61.9",
      "StrokeCount": 12
    }
  },
  "吻合": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wěnhé",
      "PrimaryDefinition": "to be a good fit, to be identical with, to adjust oneself to, to fit in"
    },
    "Keys": {
      "Word": 9745
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "虸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zǐ",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6834
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6834,
      "Radical": "虫",
      "RadicalIndex": "142.3",
      "StrokeCount": 9
    }
  },
  "濮": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pú",
      "PrimaryDefinition": "county in Henan province"
    },
    "Keys": {
      "Hanzi": 6294
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6294,
      "Radical": "水",
      "FrequencyRank": 4279,
      "RadicalIndex": "85.14",
      "StrokeCount": 17
    }
  },
  "缝": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fèng",
      "PrimaryDefinition": "sew, mend"
    },
    "Keys": {
      "Hanzi": 3045,
      "Word": 6592
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "120.1",
      "Grade": 7,
      "GeneralStandard": 3045,
      "Radical": "糸",
      "HSK": 7,
      "FrequencyRank": 1852,
      "StrokeCount": 13
    }
  },
  "发表": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fābiǎo",
      "PrimaryDefinition": "voice, enounce, enunciate, publish, free, sound, announce, pronounce, deliver, circulation, put_out, release, issue, vend, circularize, give, give_out, denote, present, circulate, publicize, blazon, promulgate, mouth"
    },
    "Keys": {
      "Word": 1427
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "永不": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǒngbù",
      "PrimaryDefinition": "never, will never"
    },
    "Keys": {
      "Word": 10435
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "顺畅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shùnchàng",
      "PrimaryDefinition": "smooth and unhindered, fluent"
    },
    "Keys": {
      "Word": 9278
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "讲解": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǎngjiě",
      "PrimaryDefinition": "to explain"
    },
    "Keys": {
      "Word": 7443
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "榈": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lǘ",
      "PrimaryDefinition": "palm"
    },
    "Keys": {
      "Hanzi": 5402
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5402,
      "Radical": "木",
      "FrequencyRank": 3971,
      "RadicalIndex": "75.9",
      "StrokeCount": 13
    }
  },
  "连锁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "liánsuǒ",
      "PrimaryDefinition": "to interlock, to be linked, chain (store etc)"
    },
    "Keys": {
      "Word": 8013
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "困": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kùn",
      "PrimaryDefinition": "to surround, beseige; to be surrounded; difficult"
    },
    "Keys": {
      "Hanzi": 734,
      "Word": 1681
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "31.4",
      "Grade": 3,
      "GeneralStandard": 734,
      "Radical": "囗",
      "HSK": 3,
      "FrequencyRank": 868,
      "StrokeCount": 7
    }
  },
  "学期": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xuéqī",
      "PrimaryDefinition": "school term, semester"
    },
    "Keys": {
      "Word": 1117
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "孵化": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fūhuà",
      "PrimaryDefinition": "breeding, to incubate, innovation (esp. in commerce and marketing)"
    },
    "Keys": {
      "Word": 6596
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "电池": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànchí",
      "PrimaryDefinition": "galvanic pile, electric battery, pile, D, battery, cell, (electric) cell, electric_battery, element, voltaic pile"
    },
    "Keys": {
      "Word": 3421
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "各种": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gèzhǒng",
      "PrimaryDefinition": "every kind of, all kinds of, various kinds"
    },
    "Keys": {
      "Word": 1492
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "福": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fú",
      "PrimaryDefinition": "happiness, good fortune, blessing"
    },
    "Keys": {
      "Hanzi": 3033,
      "Word": 1462
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "113.9",
      "Grade": 3,
      "GeneralStandard": 3033,
      "Radical": "示",
      "HSK": 3,
      "FrequencyRank": 683,
      "StrokeCount": 13
    }
  },
  "东西": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dōngxi",
      "PrimaryDefinition": "thing, creature"
    },
    "Keys": {
      "Word": 85
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "风味": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fēngwèi",
      "PrimaryDefinition": "race, gust, special flavour, savor, flavor, savour, local color, relish, sapor, spice, tasting, local colour, smack, gusto, snack, savoriness, special flavor, flavorsomeness, zest, taste"
    },
    "Keys": {
      "Word": 6580
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "冷气": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěngqì",
      "PrimaryDefinition": "air conditioning"
    },
    "Keys": {
      "Word": 4814
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "冻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dòng",
      "PrimaryDefinition": "freeze; cold, congeal; jelly"
    },
    "Keys": {
      "Hanzi": 827,
      "Word": 3431
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "15.5",
      "Grade": 5,
      "GeneralStandard": 827,
      "Radical": "冫",
      "HSK": 5,
      "FrequencyRank": 2127,
      "StrokeCount": 7
    }
  },
  "内向": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nèixiàng",
      "PrimaryDefinition": "reserved (personality), introverted, (economics etc) domestic-oriented"
    },
    "Keys": {
      "Word": 8395
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "傺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chì",
      "PrimaryDefinition": "to hinder; to detain"
    },
    "Keys": {
      "Hanzi": 7547
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7547,
      "Radical": "人",
      "FrequencyRank": 6311,
      "RadicalIndex": "9.11",
      "StrokeCount": 13
    }
  },
  "火腿": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "huǒtuǐ",
      "PrimaryDefinition": "ham, CL:個|个[ge4]"
    },
    "Keys": {
      "Word": 3570
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "随便": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suí biàn",
      "PrimaryDefinition": "informal, willful, do as one pleases, wanton, casual, random, careless"
    },
    "Keys": {
      "Word": 1000
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "高级": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāojí",
      "PrimaryDefinition": "high-level, high-class, quality"
    },
    "Keys": {
      "Word": 677
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "庄": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhuāng",
      "PrimaryDefinition": "village, hamlet; villa, manor"
    },
    "Keys": {
      "Hanzi": 514
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "53.3",
      "GeneralStandard": 514,
      "Radical": "广",
      "HSK": 6,
      "FrequencyRank": 1024,
      "StrokeCount": 6
    }
  },
  "汆": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cuān",
      "PrimaryDefinition": "to parboil; hot water kettle; to boil"
    },
    "Keys": {
      "Hanzi": 3612
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3612,
      "Radical": "水",
      "FrequencyRank": 6885,
      "RadicalIndex": "85.2",
      "StrokeCount": 6
    }
  },
  "擀": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gǎn",
      "PrimaryDefinition": "roll flat"
    },
    "Keys": {
      "Hanzi": 6039
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6039,
      "Radical": "手",
      "FrequencyRank": 5469,
      "RadicalIndex": "64.13",
      "StrokeCount": 16
    }
  },
  "溏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "táng",
      "PrimaryDefinition": "pool; not hardened, semi-soft"
    },
    "Keys": {
      "Hanzi": 5583
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5583,
      "Radical": "水",
      "FrequencyRank": 6068,
      "RadicalIndex": "85.1",
      "StrokeCount": 13
    }
  },
  "先进": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiānjìn",
      "PrimaryDefinition": "advance"
    },
    "Keys": {
      "Word": 2029
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "缗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mín",
      "PrimaryDefinition": "fishing-line; cord; string of coi"
    },
    "Keys": {
      "Hanzi": 5337
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5337,
      "Radical": "糸",
      "FrequencyRank": 5538,
      "RadicalIndex": "120.9",
      "StrokeCount": 12
    }
  },
  "纨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wán",
      "PrimaryDefinition": "white silk, fine silk; gauze"
    },
    "Keys": {
      "Hanzi": 3647
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3647,
      "Radical": "糸",
      "FrequencyRank": 5335,
      "RadicalIndex": "120.3",
      "StrokeCount": 6
    }
  },
  "操": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cāo",
      "PrimaryDefinition": "conduct, run, control, manage"
    },
    "Keys": {
      "Hanzi": 3317
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "64.13",
      "GeneralStandard": 3317,
      "Radical": "手",
      "HSK": 4,
      "FrequencyRank": 1173,
      "StrokeCount": 16
    }
  },
  "乡亲": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiāngqīn",
      "PrimaryDefinition": "fellow countryman (from the same village), local people, villager, the folks back home"
    },
    "Keys": {
      "Word": 9914
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "一行": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yīxíng",
      "PrimaryDefinition": "party, delegation"
    },
    "Keys": {
      "Word": 5244
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "到处": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàochù",
      "PrimaryDefinition": "universally, everywhere, everyplace, far and near, about, around, far and wide, all_over, high_and_low, all over, throughout, high and low, far_and_near, passim, abroad, at all places"
    },
    "Keys": {
      "Word": 605
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "真": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zhēn",
      "PrimaryDefinition": "real, actual, true, genuine"
    },
    "Keys": {
      "Hanzi": 1832,
      "Word": 467
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "109.5",
      "Grade": 1,
      "GeneralStandard": 1832,
      "Radical": "目",
      "HSK": 1,
      "FrequencyRank": 204,
      "StrokeCount": 10
    }
  },
  "茶道": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chádào",
      "PrimaryDefinition": "Japanese tea ceremony, sado"
    },
    "Keys": {
      "Word": 5787
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "䲢": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "téng",
      "PrimaryDefinition": "(simplified form of 鰧) a kind of fish; stout; bluish gray color; big flat head; big mouth and small eyes; usually stay in the bottom of the shallow sea; half bured in the muddy sand"
    },
    "Keys": {
      "Hanzi": 8014
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8014,
      "Radical": "",
      "RadicalIndex": "",
      "StrokeCount": 21
    }
  },
  "代替": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "dàitì",
      "PrimaryDefinition": "fill in, replace, supply, supersede, replacement, substitute for, surrogate, oust, substitute, stand_in, stand in, cover, supplant, take the place of, fill_in, sub"
    },
    "Keys": {
      "Word": 2380
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "命": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "mìng",
      "PrimaryDefinition": "life; destiny, fate, luck; an order, instruction"
    },
    "Keys": {
      "Hanzi": 1168,
      "Word": 8314
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "Grade": 7,
      "GeneralStandard": 1168,
      "Radical": "口",
      "HSK": 3,
      "FrequencyRank": 258,
      "StrokeCount": 8
    }
  },
  "渔船": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yúchuán",
      "PrimaryDefinition": "fisherman, fishermen, fisherboat, fisher, fishing boat, wherry"
    },
    "Keys": {
      "Word": 10496
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "隔壁": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gébì",
      "PrimaryDefinition": "next door"
    },
    "Keys": {
      "Word": 3493
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "缰": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiāng",
      "PrimaryDefinition": "reins, bridle"
    },
    "Keys": {
      "Hanzi": 3386
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3386,
      "Radical": "糸",
      "FrequencyRank": 3855,
      "RadicalIndex": "120.13",
      "StrokeCount": 16
    }
  },
  "潺": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chán",
      "PrimaryDefinition": "sound of flowing water"
    },
    "Keys": {
      "Hanzi": 6005
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6005,
      "Radical": "水",
      "FrequencyRank": 4033,
      "RadicalIndex": "85.12",
      "StrokeCount": 15
    }
  },
  "防守": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fángshǒu",
      "PrimaryDefinition": "to defend, to protect (against)"
    },
    "Keys": {
      "Word": 4555
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "年度": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "niándù",
      "PrimaryDefinition": "(school/etc.) year"
    },
    "Keys": {
      "Word": 3746
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "拖累": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuōlěi",
      "PrimaryDefinition": "to encumber, to be a burden on, to implicate"
    },
    "Keys": {
      "Word": 9612
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "寺庙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sìmiào",
      "PrimaryDefinition": "temple, monastery, shrine"
    },
    "Keys": {
      "Word": 9325
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "睡觉": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shuì jiào",
      "PrimaryDefinition": "turn_in, log Z's, doss, slumber, catch some Z's, go to bed, sleep, doss down, crash, go_to_sleep, hit_the_sack, kip"
    },
    "Keys": {
      "Word": 346
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "付出": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fùchū",
      "PrimaryDefinition": "pay, expend"
    },
    "Keys": {
      "Word": 2479
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "心理": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnlǐ",
      "PrimaryDefinition": "mental_state, psychology, mentality"
    },
    "Keys": {
      "Word": 3033
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "不敢当": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bù gǎndāng",
      "PrimaryDefinition": "lit. I dare not (accept the honor), fig. I don't deserve your praise, you flatter me"
    },
    "Keys": {
      "Word": 3279
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "退缩": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tuìsuō",
      "PrimaryDefinition": "to shrink back, to cower"
    },
    "Keys": {
      "Word": 9609
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "演奏": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yǎnzòu",
      "PrimaryDefinition": "give instrumental performance"
    },
    "Keys": {
      "Word": 5214
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "生涯": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shēngyá",
      "PrimaryDefinition": "career, pilgrimage, profession, time"
    },
    "Keys": {
      "Word": 9062
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "虐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nüè",
      "PrimaryDefinition": "cruel, harsh, oppressive"
    },
    "Keys": {
      "Hanzi": 1470
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "141.3",
      "GeneralStandard": 1470,
      "Radical": "虍",
      "HSK": 7,
      "FrequencyRank": 2665,
      "StrokeCount": 9
    }
  },
  "气氛": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìfēn",
      "PrimaryDefinition": "mood, air, look, feeling, aura, aurae, spirit, smell, genius loci, feel, flavor, atmosphere, tone, karma, ambiance, ambience"
    },
    "Keys": {
      "Word": 4920
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "维": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wéi",
      "PrimaryDefinition": "maintain, preserve, safeguard"
    },
    "Keys": {
      "Hanzi": 2492
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "120.8",
      "GeneralStandard": 2492,
      "Radical": "糸",
      "HSK": 4,
      "FrequencyRank": 520,
      "StrokeCount": 11
    }
  },
  "办事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bàn shì",
      "PrimaryDefinition": "dispose, handle affairs, work"
    },
    "Keys": {
      "Word": 2243
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "洋": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "ocean, sea; foreign; western"
    },
    "Keys": {
      "Hanzi": 1679,
      "Word": 5217
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "85.6",
      "Grade": 6,
      "GeneralStandard": 1679,
      "Radical": "水",
      "HSK": 6,
      "FrequencyRank": 803,
      "StrokeCount": 9
    }
  },
  "存": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cún",
      "PrimaryDefinition": "exist, live, be; survive; remain"
    },
    "Keys": {
      "Hanzi": 391,
      "Word": 1377
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "39.3",
      "Grade": 3,
      "GeneralStandard": 391,
      "Radical": "子",
      "HSK": 3,
      "FrequencyRank": 384,
      "StrokeCount": 6
    }
  },
  "电车": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "diànchē",
      "PrimaryDefinition": "trolleybus, CL:輛|辆[liang4]"
    },
    "Keys": {
      "Word": 4506
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "手术": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "shǒushù",
      "PrimaryDefinition": "operation"
    },
    "Keys": {
      "Word": 2878
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "燥": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zào",
      "PrimaryDefinition": "dry, parched, arid; quick-tempered"
    },
    "Keys": {
      "Hanzi": 3430
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "86.13",
      "GeneralStandard": 3430,
      "Radical": "火",
      "HSK": 8,
      "FrequencyRank": 2613,
      "StrokeCount": 17
    }
  },
  "监护": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānhù",
      "PrimaryDefinition": "to act as a guardian"
    },
    "Keys": {
      "Word": 7391
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "破除": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pòchú",
      "PrimaryDefinition": "to eliminate, to do away with, to get rid of"
    },
    "Keys": {
      "Word": 8569
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "菠": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "bō",
      "PrimaryDefinition": "spinach and similar greens"
    },
    "Keys": {
      "Hanzi": 2212
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 2212,
      "Radical": "艸",
      "FrequencyRank": 4540,
      "RadicalIndex": "140.8",
      "StrokeCount": 11
    }
  },
  "薷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "rú",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 6208
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6208,
      "Radical": "艸",
      "FrequencyRank": 6295,
      "RadicalIndex": "140.14",
      "StrokeCount": 17
    }
  },
  "高雅": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāoyǎ",
      "PrimaryDefinition": "dainty, elegance, elegant"
    },
    "Keys": {
      "Word": 6708
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "骷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kū",
      "PrimaryDefinition": "skeleton"
    },
    "Keys": {
      "Hanzi": 5730
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5730,
      "Radical": "骨",
      "FrequencyRank": 3745,
      "RadicalIndex": "188.5",
      "StrokeCount": 14
    }
  },
  "高涨": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gāozhàng",
      "PrimaryDefinition": "to surge up, to rise, (of tensions etc) to run high"
    },
    "Keys": {
      "Word": 6709
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "今后": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jīnhòu",
      "PrimaryDefinition": "hereafter, henceforth, in the future, from now on"
    },
    "Keys": {
      "Word": 789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "工科": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "gōngkē",
      "PrimaryDefinition": "engineering as an academic subject"
    },
    "Keys": {
      "Word": 6754
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "极为": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jíwéi",
      "PrimaryDefinition": "all, absolutely, extremely, dead, utterly, entirely, eminently, exceedingly, vitally, helluva, definitely, superlatively, totally, completely"
    },
    "Keys": {
      "Word": 7296
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "吣": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìn",
      "PrimaryDefinition": "vomit"
    },
    "Keys": {
      "Hanzi": 3710
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 3710,
      "Radical": "口",
      "FrequencyRank": 6073,
      "RadicalIndex": "30.4",
      "StrokeCount": 7
    }
  },
  "馊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "sōu",
      "PrimaryDefinition": "spoiled, rotten, stale, rancid"
    },
    "Keys": {
      "Hanzi": 5249
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 5249,
      "Radical": "食",
      "FrequencyRank": 4753,
      "RadicalIndex": "184.9",
      "StrokeCount": 12
    }
  },
  "监管": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiānguǎn",
      "PrimaryDefinition": "to oversee, to take charge of, to supervise, to administer, supervisory, supervision"
    },
    "Keys": {
      "Word": 7390
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "外交": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wàijiāo",
      "PrimaryDefinition": "diplomatics, diplomatic negotiations, diplomacy, nonintervention, diplomatism, diplomatic, foreign affairs"
    },
    "Keys": {
      "Word": 1995
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 3
    }
  },
  "尽可能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jǐnkěnéng",
      "PrimaryDefinition": "as far as possible, to do one's utmost"
    },
    "Keys": {
      "Word": 3624
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "融入": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "róngrù",
      "PrimaryDefinition": "melt, thaw, blend, merge"
    },
    "Keys": {
      "Word": 4975
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "多功能": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "duōgōngnéng",
      "PrimaryDefinition": "multi-functional, multi-function"
    },
    "Keys": {
      "Word": 6393
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "增收": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "zēngshōu",
      "PrimaryDefinition": "Increasing"
    },
    "Keys": {
      "Word": 10621
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "全部": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quánbù",
      "PrimaryDefinition": "lot, aggregate, all, gross, entirety, allness, shebang, caboodle, complete, bunch, sum, total, gamut, entire, det.: all, totality, whole, everything"
    },
    "Keys": {
      "Word": 945
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "南边": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "nánbian",
      "PrimaryDefinition": "south, south side, southern part, to the south of"
    },
    "Keys": {
      "Word": 264
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 1
    }
  },
  "咐": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "fù",
      "PrimaryDefinition": "instruct, order"
    },
    "Keys": {
      "Hanzi": 1098
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.5",
      "GeneralStandard": 1098,
      "Radical": "口",
      "HSK": 7,
      "FrequencyRank": 2093,
      "StrokeCount": 8
    }
  },
  "蹙": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "cù",
      "PrimaryDefinition": "urgent, suddenly; grieve, lament"
    },
    "Keys": {
      "Hanzi": 6324
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6324,
      "Radical": "足",
      "FrequencyRank": 3977,
      "RadicalIndex": "157.11",
      "StrokeCount": 18
    }
  },
  "癗": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lěi",
      "PrimaryDefinition": ""
    },
    "Keys": {
      "Hanzi": 8019
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 8019,
      "Radical": "疒",
      "RadicalIndex": "104.13",
      "StrokeCount": 18
    }
  },
  "契约": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qìyuē",
      "PrimaryDefinition": "legal instrument, charter, bond, bargain, covenant, legal document, compact, stipulation, agreement, instrument, bargaining, pact, obligation, clause, paction, booking, affiance, indenture, promise, article, contract, concordat, official document, deed, handfast"
    },
    "Keys": {
      "Word": 8631
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "畎": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "quǎn",
      "PrimaryDefinition": "a drain between fields, irrigation; to flow"
    },
    "Keys": {
      "Hanzi": 4205
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4205,
      "Radical": "田",
      "FrequencyRank": 6342,
      "RadicalIndex": "102.4",
      "StrokeCount": 9
    }
  },
  "出走": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "chūzǒu",
      "PrimaryDefinition": "leave, run away, flee"
    },
    "Keys": {
      "Word": 5981
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "凓": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "lì",
      "PrimaryDefinition": "shiver; severe cold"
    },
    "Keys": {
      "Hanzi": 7412
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 7412,
      "Radical": "冫",
      "RadicalIndex": "15.1",
      "StrokeCount": 12
    }
  },
  "彧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yù",
      "PrimaryDefinition": "refined, cultured, polished"
    },
    "Keys": {
      "Hanzi": 4466
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 4466,
      "Radical": "彡",
      "RadicalIndex": "59.7",
      "StrokeCount": 10
    }
  },
  "新闻": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xīnwén",
      "PrimaryDefinition": "unco, leak, intelligence, newsworthiness, witting, journalistic, information, journalism, courant, news"
    },
    "Keys": {
      "Word": 1106
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "议题": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yìtí",
      "PrimaryDefinition": "item on the agenda, head, question, topic for discussion, topic for/under discussion, topic under discussion, subject under discussion, under discussion, topic for"
    },
    "Keys": {
      "Word": 5246
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 6
    }
  },
  "奇": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qí",
      "PrimaryDefinition": "strange, unusual, uncanny, occult"
    },
    "Keys": {
      "Hanzi": 1050
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "37.5",
      "GeneralStandard": 1050,
      "Radical": "大",
      "HSK": 3,
      "FrequencyRank": 563,
      "StrokeCount": 8
    }
  },
  "酒吧": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jiǔbā",
      "PrimaryDefinition": "bar, pub, saloon, CL:家[jia1]"
    },
    "Keys": {
      "Word": 2642
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "无线电": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "wúxiàndiàn",
      "PrimaryDefinition": "radio"
    },
    "Keys": {
      "Word": 9789
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "肷": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "qiǎn",
      "PrimaryDefinition": "area between waist and hips"
    },
    "Keys": {
      "Hanzi": 6711
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "GeneralStandard": 6711,
      "Radical": "肉",
      "FrequencyRank": 5712,
      "RadicalIndex": "130.4",
      "StrokeCount": 8
    }
  },
  "羊": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "yáng",
      "PrimaryDefinition": "sheep"
    },
    "Keys": {
      "Hanzi": 530,
      "Radical": 123,
      "Word": 2084
    },
    "Types": [
ZhLanguageUnitTypes.Radical,
ZhLanguageUnitTypes.Hanzi,
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "RadicalIndex": "123",
      "Grade": 3,
      "GeneralStandard": 530,
      "Radical": "羊",
      "HSK": 3,
      "FrequencyRank": 1337,
      "StrokeCount": 6
    }
  },
  "体重": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "tǐzhòng",
      "PrimaryDefinition": "body weight, beef, (body) weight, avoirdupois, weight"
    },
    "Keys": {
      "Word": 2924
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 4
    }
  },
  "迹象": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "jìxiàng",
      "PrimaryDefinition": "breath, gleam, spark, intimation, indication, trace, vestige, glint, evidence, sign, showing, mark, shadow, glimmer, appearance, relic, hint, phenomenon, augury"
    },
    "Keys": {
      "Word": 7333
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "派别": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "pàibié",
      "PrimaryDefinition": "denomination, group, school, faction, school of thought"
    },
    "Keys": {
      "Word": 8460
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "国民": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "guómín",
      "PrimaryDefinition": "citizen, country, citizenry, a people, nationality, nation, folk, subject, people, nationals, national, commonwealth, son, land"
    },
    "Keys": {
      "Word": 3528
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 5
    }
  },
  "开心": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "kāi xīn",
      "PrimaryDefinition": "feel happy, rejoice"
    },
    "Keys": {
      "Word": 806
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "随处可见": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "suíchùkějiàn",
      "PrimaryDefinition": "Everywhere"
    },
    "Keys": {
      "Word": 9361
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 7
    }
  },
  "好事": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "hǎoshì",
      "PrimaryDefinition": "good action, deed, thing or work (also sarcastic, 'a fine thing indeed'), charity, happy occasion, Daoist or Buddhist ceremony for the souls of the dead, to be meddlesome"
    },
    "Keys": {
      "Word": 713
    },
    "Types": [
ZhLanguageUnitTypes.Word,
    ],
    "Metadata": {
      "Grade": 2
    }
  },
  "啸": {
    "Properties": {
      "TargetDefinition": "",
      "Reading": "xiào",
      "PrimaryDefinition": "roar, howl, scream; whistle"
    },
    "Keys": {
      "Hanzi": 2291
    },
    "Types": [
ZhLanguageUnitTypes.Hanzi,
    ],
    "Metadata": {
      "RadicalIndex": "30.8",
      "GeneralStandard": 2291,
      "Radical": "口",
      "HSK": 9,
      "FrequencyRank": 2301,
      "StrokeCount": 11
    }
  }
}
