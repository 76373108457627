export const CnReadings: { [unit: string]: (string | null)[] } = 

{
  "缎": [
    "duan4"
  ],
  "捐赠": [
    "juan1 zeng4"
  ],
  "凛": [
    "lin3"
  ],
  "物体": [
    "wu4 ti3"
  ],
  "环节": [
    "huan2 jie2"
  ],
  "怨恨": [
    "yuan4 hen4"
  ],
  "筹划": [
    "chou2 hua4"
  ],
  "掊": [
    "pou2",
    "pou3"
  ],
  "笨重": [
    "ben4 zhong4"
  ],
  "…分之…": [
    null
  ],
  "蜻": [
    "qing1"
  ],
  "祭祀": [
    "ji4 si4"
  ],
  "蚝": [
    "hao2"
  ],
  "镭": [
    "lei2"
  ],
  "局势": [
    "ju2 shi4"
  ],
  "庆幸": [
    "qing4 xing4"
  ],
  "轿": [
    "jiao4"
  ],
  "叼": [
    "diao1"
  ],
  "椤": [
    "luo2"
  ],
  "双": [
    "Shuang1",
    "shuang1"
  ],
  "腹泻": [
    "fu4 xie4"
  ],
  "焆": [
    "juan1"
  ],
  "采取": [
    "cai3 qu3"
  ],
  "好心人": [
    "hao3 xin1 ren2"
  ],
  "扦": [
    "qian1"
  ],
  "不曾": [
    "bu4 ceng2"
  ],
  "常用": [
    "chang2 yong4"
  ],
  "木偶": [
    "mu4 ou3"
  ],
  "帐篷": [
    "zhang4 peng5"
  ],
  "公务员": [
    "gong1 wu4 yuan2"
  ],
  "公正": [
    "gong1 zheng4"
  ],
  "吨": [
    "dun1"
  ],
  "胄": [
    "zhou4"
  ],
  "必要": [
    "bi4 yao4"
  ],
  "邃": [
    "sui4"
  ],
  "诜": [
    "shen1"
  ],
  "彟": [
    "huo4"
  ],
  "剂": [
    "ji4"
  ],
  "浪费": [
    "lang4 fei4"
  ],
  "弛": [
    "chi2"
  ],
  "藜": [
    "li2"
  ],
  "专著": [
    "zhuan1 zhu4"
  ],
  "知名": [
    "zhi1 ming2"
  ],
  "卿": [
    "qing1"
  ],
  "时空": [
    "shi2 kong1"
  ],
  "英": [
    "Ying1",
    "ying1"
  ],
  "招揽": [
    "zhao1 lan3"
  ],
  "率": [
    "lu:4",
    "shuai4"
  ],
  "透支": [
    "tou4 zhi1"
  ],
  "化": [
    "hua1",
    "hua4"
  ],
  "幸运": [
    "xing4 yun4"
  ],
  "薯片": [
    "shu3 pian4"
  ],
  "葆": [
    "bao3"
  ],
  "庸俗": [
    "yong1 su2"
  ],
  "待": [
    "dai1",
    "dai4"
  ],
  "勋": [
    "xun1",
    "xun1",
    "xun1"
  ],
  "陈列": [
    "chen2 lie4"
  ],
  "地理": [
    "di4 li3"
  ],
  "芼": [
    "mao4"
  ],
  "薮": [
    "sou3"
  ],
  "不像话": [
    "bu4 xiang4 hua4"
  ],
  "畴": [
    "chou2"
  ],
  "就算": [
    "jiu4 suan4"
  ],
  "炱": [
    "tai2"
  ],
  "梭": [
    "suo1"
  ],
  "身上": [
    "shen1 shang5"
  ],
  "报复": [
    "bao4 fu4"
  ],
  "畏惧": [
    "wei4 ju4"
  ],
  "降落": [
    "jiang4 luo4"
  ],
  "拉拢": [
    "la1 long3"
  ],
  "姗": [
    "shan1"
  ],
  "展览会": [
    "zhan3 lan3 hui4"
  ],
  "鼽": [
    "qiu2"
  ],
  "窍门": [
    "qiao4 men2"
  ],
  "封闭": [
    "feng1 bi4"
  ],
  "侬": [
    "nong2"
  ],
  "泄": [
    "xie4",
    "xie4"
  ],
  "从没": [
    "cong2 mei2"
  ],
  "情谊": [
    "qing2 yi4"
  ],
  "瓦": [
    "wa3"
  ],
  "睃": [
    "suo1"
  ],
  "郇": [
    "xun2"
  ],
  "副": [
    "fu4"
  ],
  "同事": [
    "tong2 shi4"
  ],
  "机灵": [
    "ji1 ling5"
  ],
  "鲆": [
    "ping2"
  ],
  "鹤立鸡群": [
    "he4 li4 ji1 qun2"
  ],
  "巨星": [
    "ju4 xing1"
  ],
  "佴": [
    "er4"
  ],
  "赇": [
    "qiu2"
  ],
  "隆重": [
    "long2 zhong4"
  ],
  "香蕉": [
    "xiang1 jiao1"
  ],
  "逛": [
    "guang4"
  ],
  "收看": [
    "shou1 kan4"
  ],
  "刷新": [
    "shua1 xin1"
  ],
  "边疆": [
    "bian1 jiang1"
  ],
  "违约": [
    "wei2 yue1"
  ],
  "严格": [
    "yan2 ge2"
  ],
  "身价": [
    "shen1 jia4"
  ],
  "蚓": [
    "yin3"
  ],
  "冲浪": [
    "chong1 lang4"
  ],
  "才能": [
    "cai2 neng2"
  ],
  "埵": [
    "duo3"
  ],
  "膛": [
    "tang2"
  ],
  "晴": [
    "qing2"
  ],
  "主食": [
    "zhu3 shi2"
  ],
  "消遣": [
    "xiao1 qian3"
  ],
  "张贴": [
    "zhang1 tie1"
  ],
  "蟾": [
    "chan2"
  ],
  "酩": [
    "ming3"
  ],
  "假设": [
    "jia3 she4"
  ],
  "传输": [
    "chuan2 shu1"
  ],
  "除外": [
    "chu2 wai4"
  ],
  "耳目一新": [
    "er3 mu4 yi1 xin1"
  ],
  "瓶": [
    "ping2",
    "ping2"
  ],
  "后遗症": [
    "hou4 yi2 zheng4"
  ],
  "重型": [
    "zhong4 xing2"
  ],
  "劝": [
    "quan4"
  ],
  "触目惊心": [
    "chu4 mu4 jing1 xin1"
  ],
  "应聘": [
    "ying4 pin4"
  ],
  "铬": [
    "ge4"
  ],
  "揿": [
    "qin4",
    "qin4"
  ],
  "义工": [
    "yi4 gong1"
  ],
  "畋": [
    "tian2"
  ],
  "揄": [
    "yu2"
  ],
  "博客": [
    "bo2 ke4"
  ],
  "屠": [
    "Tu2",
    "tu2"
  ],
  "穙": [
    null
  ],
  "科": [
    "ke1"
  ],
  "噱": [
    "jue2"
  ],
  "输家": [
    "shu1 jia1"
  ],
  "感兴趣": [
    "gan3 xing4 qu4"
  ],
  "黑客": [
    "hei1 ke4"
  ],
  "平时": [
    "ping2 shi2"
  ],
  "宏观": [
    "hong2 guan1"
  ],
  "名义": [
    "ming2 yi4"
  ],
  "惭": [
    "can2",
    "can2"
  ],
  "上限": [
    "shang4 xian4"
  ],
  "必然": [
    "bi4 ran2"
  ],
  "集装箱": [
    "ji2 zhuang1 xiang1"
  ],
  "蜍": [
    "chu2"
  ],
  "承担": [
    "cheng2 dan1"
  ],
  "愚公移山": [
    "yu2 gong1 yi2 shan1"
  ],
  "单边": [
    "dan1 bian1"
  ],
  "亳": [
    "Bo2"
  ],
  "扉": [
    "fei1"
  ],
  "宝贵": [
    "bao3 gui4"
  ],
  "丑恶": [
    "chou3 e4"
  ],
  "此致": [
    "ci3 zhi4"
  ],
  "脉搏": [
    "mai4 bo2"
  ],
  "再见": [
    "zai4 jian4"
  ],
  "棻": [
    "fen1"
  ],
  "吹了": [
    "chui1 le5"
  ],
  "被子": [
    "bei4 zi5"
  ],
  "相继": [
    "xiang1 ji4"
  ],
  "特邀": [
    "te4 yao1"
  ],
  "沔": [
    "mian3"
  ],
  "籽": [
    "zi3"
  ],
  "西红柿": [
    "xi1 hong2 shi4"
  ],
  "疒": [
    "ne4"
  ],
  "半途而废": [
    "ban4 tu2 er2 fei4"
  ],
  "不予": [
    null
  ],
  "褓": [
    "bao3",
    "bao3"
  ],
  "曲": [
    "Qu1",
    "qu1",
    "qu3",
    "qu1",
    "qu1"
  ],
  "仔": [
    "zai3",
    "zi1",
    "zi3"
  ],
  "足球": [
    "zu2 qiu2"
  ],
  "系列": [
    "xi4 lie4"
  ],
  "妖怪": [
    "yao1 guai4"
  ],
  "订单": [
    "ding4 dan1"
  ],
  "裢": [
    "lian2"
  ],
  "裹": [
    "guo3"
  ],
  "关注": [
    "guan1 zhu4"
  ],
  "氢": [
    "qing1"
  ],
  "年底": [
    "nian2 di3"
  ],
  "倩": [
    "qian4"
  ],
  "众志成城": [
    "zhong4 zhi4 cheng2 cheng2"
  ],
  "晊": [
    null
  ],
  "股票": [
    "gu3 piao4"
  ],
  "帘": [
    "lian2",
    "lian2"
  ],
  "抃": [
    "bian4"
  ],
  "挖掘": [
    "wa1 jue2"
  ],
  "锟": [
    "kun1"
  ],
  "遭遇": [
    "zao1 yu4"
  ],
  "华": [
    "Hua2",
    "Hua4",
    "hua1",
    "hua2"
  ],
  "矿泉水": [
    "kuang4 quan2 shui3"
  ],
  "人选": [
    "ren2 xuan3"
  ],
  "倜": [
    "ti4"
  ],
  "私自": [
    "si1 zi4"
  ],
  "探索": [
    "tan4 suo3"
  ],
  "风度": [
    "feng1 du4"
  ],
  "舷": [
    "xian2"
  ],
  "椰子": [
    "ye1 zi5"
  ],
  "敞": [
    "chang3"
  ],
  "二": [
    "er4"
  ],
  "梅花": [
    "mei2 hua1"
  ],
  "嶍": [
    null
  ],
  "证实": [
    "zheng4 shi2"
  ],
  "盟": [
    "meng2"
  ],
  "律师": [
    "lu:4 shi1"
  ],
  "鿍": [
    "gang4"
  ],
  "栓": [
    "shuan1"
  ],
  "预定": [
    "yu4 ding4"
  ],
  "舞厅": [
    "wu3 ting1"
  ],
  "出售": [
    "chu1 shou4"
  ],
  "下周": [
    "xia4 zhou1"
  ],
  "衣": [
    "yi1",
    "yi4"
  ],
  "以下": [
    "yi3 xia4"
  ],
  "如意": [
    "ru2 yi4"
  ],
  "绺": [
    "liu3"
  ],
  "讳": [
    "hui4"
  ],
  "理性": [
    "li3 xing4"
  ],
  "开会": [
    "kai1 hui4"
  ],
  "耳": [
    "er3"
  ],
  "势": [
    "shi4"
  ],
  "相识": [
    "xiang1 shi2"
  ],
  "潆": [
    "ying2"
  ],
  "单方面": [
    "dan1 fang1 mian4"
  ],
  "提拔": [
    "ti2 ba2"
  ],
  "力求": [
    "li4 qiu2"
  ],
  "扫描": [
    "sao3 miao2"
  ],
  "协定": [
    "xie2 ding4"
  ],
  "住院": [
    "zhu4 yuan4"
  ],
  "盼": [
    "pan4"
  ],
  "砾": [
    "li4"
  ],
  "期末": [
    "qi1 mo4"
  ],
  "纲领": [
    "gang1 ling3"
  ],
  "看成": [
    "kan4 cheng2"
  ],
  "痒": [
    "yang3",
    "yang3"
  ],
  "渚": [
    "zhu3"
  ],
  "猛": [
    "meng3"
  ],
  "淌": [
    "tang3"
  ],
  "祥和": [
    "xiang2 he2"
  ],
  "混乱": [
    "hun4 luan4"
  ],
  "布置": [
    "bu4 zhi4"
  ],
  "鳝": [
    "shan4",
    "shan4"
  ],
  "炜": [
    "wei3"
  ],
  "平坦": [
    "ping2 tan3"
  ],
  "毋": [
    "Wu2",
    "wu2"
  ],
  "就是": [
    "jiu4 shi4"
  ],
  "至于": [
    "zhi4 yu2"
  ],
  "没": [
    "mei2",
    "mo4"
  ],
  "盈": [
    "ying2"
  ],
  "钐": [
    "shan1",
    "shan4"
  ],
  "不论": [
    "bu4 lun4"
  ],
  "唁": [
    "yan4"
  ],
  "纯洁": [
    "chun2 jie2"
  ],
  "诚实": [
    "cheng2 shi2"
  ],
  "测量": [
    "ce4 liang2"
  ],
  "他们": [
    "ta1 men5"
  ],
  "胀": [
    "zhang4"
  ],
  "脐": [
    "qi2"
  ],
  "市场": [
    "shi4 chang3"
  ],
  "朏": [
    "fei3"
  ],
  "当": [
    "dang1",
    "dang1",
    "dang4"
  ],
  "伊斯兰教": [
    "Yi1 si1 lan2 jiao4"
  ],
  "苘": [
    "qing3"
  ],
  "风和日丽": [
    "feng1 he2 ri4 li4"
  ],
  "担负": [
    "dan1 fu4"
  ],
  "火候": [
    "huo3 hou5"
  ],
  "小费": [
    "xiao3 fei4"
  ],
  "开枪": [
    "kai1 qiang1"
  ],
  "社区": [
    "she4 qu1"
  ],
  "魏": [
    "Wei4",
    "wei4"
  ],
  "动工": [
    "dong4 gong1"
  ],
  "鬻": [
    "yu4"
  ],
  "浑": [
    "hun2"
  ],
  "嘧": [
    "mi4"
  ],
  "自觉": [
    "zi4 jue2"
  ],
  "渔民": [
    "yu2 min2"
  ],
  "钻": [
    "zuan4",
    "zuan1",
    "zuan4"
  ],
  "需求": [
    "xu1 qiu2"
  ],
  "盲目": [
    "mang2 mu4"
  ],
  "椒": [
    "jiao1"
  ],
  "壶": [
    "hu2"
  ],
  "施工": [
    "shi1 gong1"
  ],
  "左右": [
    "zuo3 you4"
  ],
  "手术室": [
    "shou3 shu4 shi4"
  ],
  "璩": [
    "Qu2",
    "qu2"
  ],
  "小组": [
    "xiao3 zu3"
  ],
  "箐": [
    "qing4"
  ],
  "受灾": [
    "shou4 zai1"
  ],
  "搂": [
    "lou1",
    "lou3"
  ],
  "预赛": [
    "yu4 sai4"
  ],
  "肤": [
    "fu1"
  ],
  "业务": [
    "ye4 wu4"
  ],
  "它": [
    "ta1",
    "ta1"
  ],
  "传出": [
    "chuan2 chu1"
  ],
  "忠于": [
    "zhong1 yu2"
  ],
  "惔": [
    "tan2"
  ],
  "为主": [
    "wei2 zhu3"
  ],
  "裁": [
    "cai2"
  ],
  "悲痛": [
    "bei1 tong4"
  ],
  "蹜": [
    "suo1"
  ],
  "保健": [
    "bao3 jian4"
  ],
  "人数": [
    "ren2 shu4"
  ],
  "能": [
    "Neng2",
    "neng2"
  ],
  "韧": [
    "ren4",
    "ren4",
    "ren4"
  ],
  "流向": [
    "liu2 xiang4"
  ],
  "朝": [
    "Chao2",
    "chao2",
    "zhao1"
  ],
  "铂": [
    "bo2"
  ],
  "陧": [
    "nie4"
  ],
  "集会": [
    "ji2 hui4"
  ],
  "甏": [
    "beng4"
  ],
  "斗志": [
    "dou4 zhi4"
  ],
  "高兴": [
    "gao1 xing4"
  ],
  "登记": [
    "deng1 ji4"
  ],
  "废物": [
    "fei4 wu4"
  ],
  "大众": [
    "Da4 zhong4",
    "da4 zhong4"
  ],
  "蜉": [
    "fu2"
  ],
  "香油": [
    "xiang1 you2"
  ],
  "咐": [
    "fu4"
  ],
  "举世瞩目": [
    "ju3 shi4 zhu3 mu4"
  ],
  "庐": [
    "lu2"
  ],
  "视力": [
    "shi4 li4"
  ],
  "呐喊": [
    "na4 han3"
  ],
  "菩": [
    "pu2"
  ],
  "修复": [
    "xiu1 fu4"
  ],
  "相通": [
    "xiang1 tong1"
  ],
  "宓": [
    "Mi4",
    "mi4"
  ],
  "崖": [
    "ya2"
  ],
  "勤工俭学": [
    "qin2 gong1 jian3 xue2"
  ],
  "贞": [
    "zhen1"
  ],
  "瑶": [
    "Yao2",
    "yao2"
  ],
  "落户": [
    "luo4 hu4"
  ],
  "反思": [
    "fan3 si1"
  ],
  "抗争": [
    "kang4 zheng1"
  ],
  "榴": [
    "liu2"
  ],
  "㤘": [
    "zhou4"
  ],
  "升温": [
    "sheng1 wen1"
  ],
  "每当": [
    "mei3 dang1"
  ],
  "取笑": [
    "qu3 xiao4"
  ],
  "动摇": [
    "dong4 yao2"
  ],
  "葖": [
    "tu1"
  ],
  "浮力": [
    "fu2 li4"
  ],
  "好笑": [
    "hao3 xiao4"
  ],
  "风尚": [
    "feng1 shang4"
  ],
  "扭转": [
    "niu3 zhuan3"
  ],
  "臃": [
    "yong1"
  ],
  "散布": [
    "san4 bu4"
  ],
  "蜓": [
    "ting2"
  ],
  "制": [
    "zhi4",
    "zhi4"
  ],
  "应用": [
    "ying4 yong4"
  ],
  "馞": [
    null
  ],
  "家族": [
    "jia1 zu2"
  ],
  "馒": [
    "man2"
  ],
  "造假": [
    "zao4 jia3"
  ],
  "合同": [
    "he2 tong5"
  ],
  "习惯": [
    "xi2 guan4"
  ],
  "自信心": [
    "zi4 xin4 xin1"
  ],
  "试验": [
    "shi4 yan4"
  ],
  "勤劳": [
    "qin2 lao2"
  ],
  "鳟": [
    "zun1"
  ],
  "垴": [
    "nao3"
  ],
  "又": [
    "you4"
  ],
  "噶": [
    "ga2"
  ],
  "山岭": [
    "shan1 ling3"
  ],
  "法规": [
    "fa3 gui1"
  ],
  "寓意": [
    "yu4 yi4"
  ],
  "逮": [
    "dai3",
    "dai4"
  ],
  "行业": [
    "hang2 ye4"
  ],
  "骤": [
    "zhou4"
  ],
  "谫": [
    "jian3"
  ],
  "处处": [
    "chu4 chu4"
  ],
  "转眼": [
    "zhuan3 yan3"
  ],
  "驿": [
    "yi4"
  ],
  "牲畜": [
    "sheng1 chu4"
  ],
  "亥": [
    "hai4"
  ],
  "多亏": [
    "duo1 kui1"
  ],
  "姑": [
    "gu1"
  ],
  "扅": [
    null
  ],
  "采": [
    "cai3",
    "cai3",
    "cai4"
  ],
  "见义勇为": [
    "jian4 yi4 yong3 wei2"
  ],
  "西": [
    "Xi1",
    "xi1"
  ],
  "髟": [
    "biao1"
  ],
  "迷路": [
    "mi2 lu4"
  ],
  "基督教": [
    "Ji1 du1 jiao4"
  ],
  "摆动": [
    "bai3 dong4"
  ],
  "嵇": [
    "Ji1"
  ],
  "空前": [
    "kong1 qian2"
  ],
  "享有": [
    "xiang3 you3"
  ],
  "肇": [
    "zhao4",
    "zhao4"
  ],
  "敢于": [
    "gan3 yu2"
  ],
  "腼": [
    "mian3"
  ],
  "蒗": [
    "lang4"
  ],
  "敬佩": [
    "jing4 pei4"
  ],
  "炅": [
    "Gui4",
    "jiong3"
  ],
  "僎": [
    "zhuan4"
  ],
  "爝": [
    "jue2"
  ],
  "槔": [
    "gao1"
  ],
  "慈祥": [
    "ci2 xiang2"
  ],
  "日益": [
    "ri4 yi4"
  ],
  "文章": [
    "wen2 zhang1"
  ],
  "往事": [
    "wang3 shi4"
  ],
  "䥽": [
    null
  ],
  "便条": [
    "bian4 tiao2"
  ],
  "换": [
    "huan4"
  ],
  "汰": [
    "tai4"
  ],
  "状元": [
    "zhuang4 yuan2"
  ],
  "恰恰": [
    "qia4 qia4"
  ],
  "舌": [
    "ji1",
    "she2"
  ],
  "咀": [
    "ju3",
    "zui3"
  ],
  "平民": [
    "ping2 min2"
  ],
  "少数": [
    "shao3 shu4"
  ],
  "腺": [
    "xian4"
  ],
  "坨": [
    "tuo2"
  ],
  "桃树": [
    "tao2 shu4"
  ],
  "辞呈": [
    "ci2 cheng2"
  ],
  "书店": [
    "shu1 dian4"
  ],
  "时时": [
    "shi2 shi2"
  ],
  "茔": [
    "ying2"
  ],
  "涣": [
    "huan4"
  ],
  "评": [
    "ping2"
  ],
  "镛": [
    "yong1"
  ],
  "洱": [
    "er3"
  ],
  "娓": [
    "wei3"
  ],
  "抟": [
    "tuan2"
  ],
  "追问": [
    "zhui1 wen4"
  ],
  "觳": [
    "hu2"
  ],
  "中毒": [
    "zhong4 du2"
  ],
  "循序渐进": [
    "xun2 xu4 jian4 jin4"
  ],
  "丨": [
    "gun3",
    "shu4"
  ],
  "临时": [
    "lin2 shi2"
  ],
  "口香糖": [
    "kou3 xiang1 tang2"
  ],
  "暴力": [
    "bao4 li4"
  ],
  "俍": [
    "liang2"
  ],
  "鸽": [
    "ge1"
  ],
  "埪": [
    null
  ],
  "怡": [
    "yi2"
  ],
  "骥": [
    "ji4"
  ],
  "资源": [
    "Zi1 yuan2",
    "zi1 yuan2"
  ],
  "永久": [
    "yong3 jiu3"
  ],
  "鸡蛋": [
    "ji1 dan4"
  ],
  "洗": [
    "xi3"
  ],
  "晚安": [
    "wan3 an1"
  ],
  "悲惨": [
    "bei1 can3"
  ],
  "男孩儿": [
    "nan2 hai2 r5"
  ],
  "一概而论": [
    "yi1 gai4 er2 lun4"
  ],
  "治理": [
    "zhi4 li3"
  ],
  "溜": [
    "liu1",
    "liu4"
  ],
  "香烟": [
    "xiang1 yan1"
  ],
  "腾": [
    "teng2"
  ],
  "描述": [
    "miao2 shu4"
  ],
  "鞘": [
    "qiao4"
  ],
  "蔸": [
    "dou1"
  ],
  "珷": [
    "wu3"
  ],
  "炒": [
    "chao3"
  ],
  "背心": [
    "bei4 xin1"
  ],
  "囗": [
    "wei2"
  ],
  "告状": [
    "gao4 zhuang4"
  ],
  "座位": [
    "zuo4 wei4"
  ],
  "远程": [
    "yuan3 cheng2"
  ],
  "彰": [
    "zhang1"
  ],
  "晪": [
    "tian3"
  ],
  "胳膊": [
    "ge1 bo5"
  ],
  "胡同儿": [
    "hu2 tong4 r5"
  ],
  "超": [
    "chao1"
  ],
  "旋": [
    "xuan2",
    "xuan4"
  ],
  "警惕": [
    "jing3 ti4"
  ],
  "填补": [
    "tian2 bu3"
  ],
  "限度": [
    "xian4 du4"
  ],
  "甫": [
    "fu3"
  ],
  "捏": [
    "nie1",
    "nie1"
  ],
  "祜": [
    "hu4"
  ],
  "殍": [
    "piao3"
  ],
  "庹": [
    "tuo3"
  ],
  "篇": [
    "pian1"
  ],
  "乘坐": [
    "cheng2 zuo4"
  ],
  "上当": [
    "shang4 dang4"
  ],
  "排放": [
    "pai2 fang4"
  ],
  "辨": [
    "bian4"
  ],
  "摧": [
    "cui1"
  ],
  "哲": [
    "zhe2"
  ],
  "乐": [
    "Le4",
    "Yue4",
    "le4",
    "yue4"
  ],
  "总算": [
    "zong3 suan4"
  ],
  "贪玩儿": [
    "tan1 wan2 r5"
  ],
  "手": [
    "shou3"
  ],
  "许": [
    "Xu3",
    "xu3"
  ],
  "橥": [
    "zhu1"
  ],
  "强行": [
    "qiang2 xing2"
  ],
  "老人家": [
    "lao3 ren2 jia1"
  ],
  "槽": [
    "cao2"
  ],
  "始": [
    "shi3"
  ],
  "守护": [
    "shou3 hu4"
  ],
  "小说": [
    "xiao3 shuo1"
  ],
  "婘": [
    null
  ],
  "外贸": [
    "wai4 mao4"
  ],
  "暾": [
    "tun1"
  ],
  "潢": [
    "huang2"
  ],
  "场": [
    "chang2",
    "chang3",
    "chang2",
    "chang3"
  ],
  "口才": [
    "kou3 cai2"
  ],
  "根本": [
    "gen1 ben3"
  ],
  "阋": [
    "xi4"
  ],
  "玩": [
    "wan2",
    "wan2"
  ],
  "蒇": [
    "chan3"
  ],
  "尺度": [
    "chi3 du4"
  ],
  "干脆": [
    "gan1 cui4"
  ],
  "安排": [
    "an1 pai2"
  ],
  "稻": [
    "dao4"
  ],
  "衬衣": [
    "chen4 yi1"
  ],
  "琟": [
    null
  ],
  "不易": [
    "bu4 yi4"
  ],
  "衅": [
    "xin4"
  ],
  "简历": [
    "jian3 li4"
  ],
  "带动": [
    "dai4 dong4"
  ],
  "违": [
    "wei2"
  ],
  "石头": [
    "shi2 tou5"
  ],
  "跑": [
    "pao2",
    "pao3"
  ],
  "节衣缩食": [
    "jie2 yi1 suo1 shi2"
  ],
  "国民": [
    "guo2 min2"
  ],
  "留恋": [
    "liu2 lian4"
  ],
  "方方面面": [
    "fang1 fang1 mian4 mian4"
  ],
  "悲哀": [
    "bei1 ai1"
  ],
  "善良": [
    "shan4 liang2"
  ],
  "肥胖": [
    "fei2 pang4"
  ],
  "做客": [
    "zuo4 ke4"
  ],
  "弯曲": [
    "wan1 qu1"
  ],
  "起诉": [
    "qi3 su4"
  ],
  "造就": [
    "zao4 jiu4"
  ],
  "立场": [
    "li4 chang3"
  ],
  "环境": [
    "huan2 jing4"
  ],
  "卵": [
    "luan3"
  ],
  "上诉": [
    "shang4 su4"
  ],
  "仟": [
    "qian1"
  ],
  "宧": [
    "yi2"
  ],
  "特地": [
    "te4 di4"
  ],
  "胆怯": [
    "dan3 qie4"
  ],
  "学时": [
    "xue2 shi2"
  ],
  "难听": [
    "nan2 ting1"
  ],
  "潜艇": [
    "qian2 ting3"
  ],
  "埫": [
    "shang3",
    "tang3"
  ],
  "丰收": [
    "feng1 shou1"
  ],
  "哀": [
    "Ai1",
    "ai1"
  ],
  "啫": [
    "zhe3"
  ],
  "涟": [
    "lian2"
  ],
  "椋": [
    "liang2"
  ],
  "瑬": [
    null
  ],
  "深夜": [
    "shen1 ye4"
  ],
  "打发": [
    "da3 fa5"
  ],
  "仡": [
    "ge1",
    "yi4"
  ],
  "电信": [
    "dian4 xin4"
  ],
  "闶": [
    "kang1"
  ],
  "嵅": [
    null
  ],
  "休闲": [
    "xiu1 xian2"
  ],
  "蜜蜂": [
    "mi4 feng1"
  ],
  "皆": [
    "jie1"
  ],
  "滋味": [
    "zi1 wei4"
  ],
  "连续": [
    "lian2 xu4"
  ],
  "熇": [
    "kao3"
  ],
  "丰硕": [
    "feng1 shuo4"
  ],
  "焰火": [
    "yan4 huo3"
  ],
  "花生": [
    "hua1 sheng1"
  ],
  "滂": [
    "pang1"
  ],
  "窟窿": [
    "ku1 long5"
  ],
  "懋": [
    "mao4"
  ],
  "勇气": [
    "yong3 qi4"
  ],
  "大多": [
    "da4 duo1"
  ],
  "要紧": [
    "yao4 jin3"
  ],
  "本领": [
    "ben3 ling3"
  ],
  "假定": [
    "jia3 ding4"
  ],
  "激起": [
    "ji1 qi3"
  ],
  "稻草": [
    "dao4 cao3"
  ],
  "烟": [
    "yan1",
    "yan1"
  ],
  "蝻": [
    "nan3"
  ],
  "坏处": [
    "huai4 chu5"
  ],
  "带": [
    "dai4"
  ],
  "捻": [
    "nian3"
  ],
  "坎": [
    "kan3",
    "kan3"
  ],
  "痛苦": [
    "tong4 ku3"
  ],
  "水温": [
    "shui3 wen1"
  ],
  "免职": [
    "mian3 zhi2"
  ],
  "钒": [
    "fan2"
  ],
  "钲": [
    "zheng1"
  ],
  "听说": [
    "ting1 shuo1"
  ],
  "眨": [
    "zha3"
  ],
  "棂": [
    "ling2",
    "ling2"
  ],
  "花卉": [
    "hua1 hui4"
  ],
  "驳": [
    "bo2",
    "bo2"
  ],
  "趯": [
    "ti4",
    "yue4"
  ],
  "瘀": [
    "yu1"
  ],
  "速度": [
    "su4 du4"
  ],
  "佟": [
    "Tong2"
  ],
  "好心": [
    "hao3 xin1"
  ],
  "价值观": [
    "jia4 zhi2 guan1"
  ],
  "犭": [
    "quan3"
  ],
  "要好": [
    "yao4 hao3"
  ],
  "璥": [
    "jing3"
  ],
  "蠹": [
    "du4"
  ],
  "深刻": [
    "shen1 ke4"
  ],
  "从中": [
    "cong2 zhong1"
  ],
  "瘃": [
    "zhu2"
  ],
  "蛔": [
    "hui2",
    "hui2",
    "hui2",
    "hui2",
    "hui2"
  ],
  "医": [
    "yi1"
  ],
  "标本": [
    "biao1 ben3"
  ],
  "应邀": [
    "ying4 yao1"
  ],
  "归纳": [
    "gui1 na4"
  ],
  "想法": [
    "xiang3 fa3"
  ],
  "鲒": [
    "jie2"
  ],
  "磐": [
    "pan2"
  ],
  "不宜": [
    "bu4 yi2"
  ],
  "瞫": [
    "Shen3"
  ],
  "新年": [
    "xin1 nian2"
  ],
  "奸": [
    "jian1",
    "jian1"
  ],
  "晚期": [
    "wan3 qi1"
  ],
  "湑": [
    "xu3"
  ],
  "泔": [
    "gan1"
  ],
  "催": [
    "cui1"
  ],
  "上旬": [
    "shang4 xun2"
  ],
  "解决": [
    "jie3 jue2"
  ],
  "搭": [
    "da1"
  ],
  "㛃": [
    "jie2"
  ],
  "惊诧": [
    "jing1 cha4"
  ],
  "禹": [
    "Yu3"
  ],
  "洞": [
    "dong4"
  ],
  "梳理": [
    "shu1 li3"
  ],
  "未成年人": [
    "wei4 cheng2 nian2 ren2"
  ],
  "主流": [
    "zhu3 liu2"
  ],
  "遭受": [
    "zao1 shou4"
  ],
  "外貌": [
    "wai4 mao4"
  ],
  "功课": [
    "gong1 ke4"
  ],
  "存款": [
    "cun2 kuan3"
  ],
  "在线": [
    "zai4 xian4"
  ],
  "发生": [
    "fa1 sheng1"
  ],
  "母子": [
    "mu3 zi3"
  ],
  "已经": [
    "yi3 jing1"
  ],
  "火箭": [
    "huo3 jian4"
  ],
  "姓": [
    "xing4"
  ],
  "上学": [
    "shang4 xue2"
  ],
  "簋": [
    "gui3"
  ],
  "肚": [
    "du3",
    "du4"
  ],
  "樊": [
    "Fan2",
    "fan2"
  ],
  "厮": [
    "si1",
    "si1"
  ],
  "倒塌": [
    "dao3 ta1"
  ],
  "穴": [
    "xue2"
  ],
  "总体": [
    "zong3 ti3"
  ],
  "撺": [
    "cuan1"
  ],
  "救护车": [
    "jiu4 hu4 che1"
  ],
  "篙": [
    "gao1"
  ],
  "钠": [
    "na4"
  ],
  "姑姑": [
    "gu1 gu5"
  ],
  "仲裁": [
    "zhong4 cai2"
  ],
  "观众": [
    "guan1 zhong4"
  ],
  "吖": [
    "a1"
  ],
  "马力": [
    "ma3 li4"
  ],
  "河流": [
    "he2 liu2"
  ],
  "偃": [
    "Yan3",
    "yan3"
  ],
  "俐": [
    "li4"
  ],
  "写照": [
    "xie3 zhao4"
  ],
  "盗窃": [
    "dao4 qie4"
  ],
  "菇": [
    "gu1"
  ],
  "坚固": [
    "jian1 gu4"
  ],
  "捆": [
    "kun3",
    "kun3"
  ],
  "遗忘": [
    "yi2 wang4"
  ],
  "颜色": [
    "yan2 se4"
  ],
  "㧟": [
    "kuai3"
  ],
  "谛": [
    "di4"
  ],
  "猇": [
    "xiao1"
  ],
  "岱": [
    "Dai4"
  ],
  "嗳": [
    "ai3",
    "ai4"
  ],
  "独一无二": [
    "du2 yi1 wu2 er4"
  ],
  "蒎": [
    "pai4"
  ],
  "忒": [
    "te4",
    "tei1"
  ],
  "天堂": [
    "tian1 tang2"
  ],
  "名额": [
    "ming2 e2"
  ],
  "蕲": [
    "qi2"
  ],
  "删": [
    "shan1"
  ],
  "妊": [
    "ren4",
    "ren4"
  ],
  "豚": [
    "tun2"
  ],
  "成才": [
    "cheng2 cai2"
  ],
  "垈": [
    "dai4"
  ],
  "嫡": [
    "di2"
  ],
  "国有": [
    "guo2 you3"
  ],
  "谋生": [
    "mou2 sheng1"
  ],
  "嶒": [
    "ceng2"
  ],
  "西部": [
    "xi1 bu4"
  ],
  "髁": [
    "ke1"
  ],
  "跳槽": [
    "tiao4 cao2"
  ],
  "比较": [
    "bi3 jiao4"
  ],
  "衮": [
    "gun3"
  ],
  "依赖": [
    "yi1 lai4"
  ],
  "公众": [
    "gong1 zhong4"
  ],
  "承受": [
    "cheng2 shou4"
  ],
  "不同": [
    "bu4 tong2"
  ],
  "说情": [
    "shuo1 qing2"
  ],
  "嗾": [
    "sou3"
  ],
  "旨在": [
    "zhi3 zai4"
  ],
  "啦": [
    "la1",
    "la5"
  ],
  "祸害": [
    "huo4 hai4"
  ],
  "骇人听闻": [
    "hai4 ren2 ting1 wen2"
  ],
  "破灭": [
    "po4 mie4"
  ],
  "内幕": [
    "nei4 mu4"
  ],
  "豫": [
    "Yu4",
    "yu4"
  ],
  "飒": [
    "sa4",
    "sa4"
  ],
  "纠纷": [
    "jiu1 fen1"
  ],
  "本": [
    "ben3"
  ],
  "息息相关": [
    "xi1 xi1 xiang1 guan1"
  ],
  "净化": [
    "jing4 hua4"
  ],
  "阎": [
    "Yan2",
    "yan2"
  ],
  "莸": [
    "you2"
  ],
  "兢兢业业": [
    "jing1 jing1 ye4 ye4"
  ],
  "想方设法": [
    "xiang3 fang1 she4 fa3"
  ],
  "合计": [
    "he2 ji4"
  ],
  "秉": [
    "Bing3",
    "bing3"
  ],
  "口音": [
    "kou3 yin1",
    "kou3 yin5"
  ],
  "霏": [
    "fei1"
  ],
  "絮叨": [
    "xu4 dao5"
  ],
  "锴": [
    "kai3"
  ],
  "分明": [
    "fen1 ming2"
  ],
  "愀": [
    "qiao3"
  ],
  "麻痹": [
    "ma2 bi4"
  ],
  "关掉": [
    "guan1 diao4"
  ],
  "军": [
    "jun1"
  ],
  "了": [
    "le5",
    "liao3",
    "liao3",
    "liao4"
  ],
  "条": [
    "tiao2"
  ],
  "栗": [
    "li4",
    "Li4",
    "li4"
  ],
  "装": [
    "zhuang1"
  ],
  "笑脸": [
    "xiao4 lian3"
  ],
  "手势": [
    "shou3 shi4"
  ],
  "稳健": [
    "wen3 jian4"
  ],
  "媂": [
    null
  ],
  "对比": [
    "dui4 bi3"
  ],
  "韬": [
    "tao1"
  ],
  "管子": [
    "Guan3 zi3",
    "guan3 zi5"
  ],
  "欠缺": [
    "qian4 que1"
  ],
  "隼": [
    "sun3"
  ],
  "各式各样": [
    "ge4 shi4 ge4 yang4"
  ],
  "了解": [
    "liao3 jie3",
    "liao3 jie3"
  ],
  "液体": [
    "ye4 ti3"
  ],
  "灰": [
    "hui1"
  ],
  "交纳": [
    "jiao1 na4"
  ],
  "单薄": [
    "dan1 bo2"
  ],
  "轰炸": [
    "hong1 zha4"
  ],
  "大厦": [
    "da4 sha4"
  ],
  "星星": [
    "xing1 xing5"
  ],
  "具": [
    "ju4"
  ],
  "亹": [
    "men2",
    "wei3"
  ],
  "嗞": [
    "zi1"
  ],
  "胣": [
    null
  ],
  "观感": [
    "guan1 gan3"
  ],
  "技能": [
    "ji4 neng2"
  ],
  "后代": [
    "hou4 dai4"
  ],
  "幻想": [
    "huan4 xiang3"
  ],
  "寂寞": [
    "ji4 mo4"
  ],
  "逻辑": [
    "luo2 ji5"
  ],
  "躁": [
    "zao4"
  ],
  "瑁": [
    "mao4"
  ],
  "撄": [
    "ying1"
  ],
  "孕": [
    "yun4"
  ],
  "中央": [
    "zhong1 yang1"
  ],
  "鹙": [
    "qiu1"
  ],
  "焊": [
    "han4",
    "han4",
    "han4"
  ],
  "薁": [
    "yu4"
  ],
  "贷": [
    "dai4"
  ],
  "磊": [
    "lei3"
  ],
  "悔恨": [
    "hui3 hen4"
  ],
  "埝": [
    "nian4"
  ],
  "蚨": [
    "fu2"
  ],
  "说闲话": [
    "shuo1 xian2 hua4"
  ],
  "枅": [
    null
  ],
  "癯": [
    "qu2"
  ],
  "䴕": [
    "lie4"
  ],
  "自动": [
    "zi4 dong4"
  ],
  "出于": [
    "chu1 yu2"
  ],
  "闯": [
    "chuang3"
  ],
  "荚": [
    "jia2"
  ],
  "撷": [
    "xie2"
  ],
  "学员": [
    "xue2 yuan2"
  ],
  "公用": [
    "gong1 yong4"
  ],
  "股民": [
    "gu3 min2"
  ],
  "发愁": [
    "fa1 chou2"
  ],
  "漻": [
    null
  ],
  "统一": [
    "Tong3 yi1",
    "tong3 yi1"
  ],
  "王后": [
    "wang2 hou4"
  ],
  "佾": [
    "yi4"
  ],
  "遒": [
    "qiu2"
  ],
  "证": [
    "zheng4",
    "zheng4"
  ],
  "淤": [
    "yu1"
  ],
  "内需": [
    "nei4 xu1"
  ],
  "鄘": [
    "Yong1"
  ],
  "契约": [
    "qi4 yue1"
  ],
  "签名": [
    "qian1 ming2"
  ],
  "持续": [
    "chi2 xu4"
  ],
  "罕见": [
    "han3 jian4"
  ],
  "防止": [
    "fang2 zhi3"
  ],
  "过滤": [
    "guo4 lu:4"
  ],
  "犟": [
    "jiang4"
  ],
  "毅然": [
    "yi4 ran2"
  ],
  "旅行社": [
    "lu:3 xing2 she4"
  ],
  "漠": [
    "mo4"
  ],
  "穿着": [
    "chuan1 zhuo2"
  ],
  "傍": [
    "bang4"
  ],
  "芡": [
    "qian4"
  ],
  "论坛": [
    "lun4 tan2"
  ],
  "明明": [
    "ming2 ming2"
  ],
  "反问": [
    "fan3 wen4"
  ],
  "一边": [
    "yi1 bian1"
  ],
  "寸": [
    "cun4"
  ],
  "长远": [
    "chang2 yuan3"
  ],
  "铻": [
    "wu2"
  ],
  "产值": [
    "chan3 zhi2"
  ],
  "没什么": [
    "mei2 shen2 me5"
  ],
  "首府": [
    "shou3 fu3"
  ],
  "馅儿": [
    "xian4 r5"
  ],
  "克制": [
    "ke4 zhi4"
  ],
  "璜": [
    "huang2"
  ],
  "入场": [
    "ru4 chang3"
  ],
  "渴望": [
    "ke3 wang4"
  ],
  "鉴于": [
    "jian4 yu2"
  ],
  "凉水": [
    "liang2 shui3"
  ],
  "筅": [
    "xian3"
  ],
  "短片": [
    "duan3 pian4"
  ],
  "觱": [
    "bi4"
  ],
  "扑面而来": [
    "pu1 mian4 er2 lai2"
  ],
  "就餐": [
    "jiu4 can1"
  ],
  "扎根": [
    "zha1 gen1"
  ],
  "幖": [
    null
  ],
  "橙汁": [
    "cheng2 zhi1"
  ],
  "敲边鼓": [
    "qiao1 bian1 gu3"
  ],
  "工人": [
    "gong1 ren2"
  ],
  "截然不同": [
    "jie2 ran2 bu4 tong2"
  ],
  "准备": [
    "zhun3 bei4"
  ],
  "储存": [
    "chu3 cun2"
  ],
  "谈到": [
    "tan2 dao4"
  ],
  "恫": [
    "dong4"
  ],
  "蠋": [
    "zhu2"
  ],
  "骛": [
    "wu4"
  ],
  "品德": [
    "pin3 de2"
  ],
  "厢": [
    "xiang1"
  ],
  "玻璃": [
    "bo1 li5"
  ],
  "荟": [
    "hui4"
  ],
  "概率": [
    "gai4 lu:4"
  ],
  "诊断": [
    "zhen3 duan4"
  ],
  "语法": [
    "yu3 fa3"
  ],
  "蝇": [
    "ying2"
  ],
  "可以": [
    "ke3 yi3"
  ],
  "麂": [
    "ji3"
  ],
  "遄": [
    "chuan2"
  ],
  "分类": [
    "fen1 lei4"
  ],
  "动手": [
    "dong4 shou3"
  ],
  "美妙": [
    "mei3 miao4"
  ],
  "鹌": [
    "an1"
  ],
  "细节": [
    "xi4 jie2"
  ],
  "觞": [
    "shang1"
  ],
  "瑄": [
    "xuan1"
  ],
  "钴": [
    "gu3"
  ],
  "耿": [
    "Geng3",
    "geng3"
  ],
  "忍": [
    "ren3"
  ],
  "救灾": [
    "jiu4 zai1"
  ],
  "伟大": [
    "wei3 da4"
  ],
  "眼神": [
    "yan3 shen2"
  ],
  "收到": [
    "shou1 dao4"
  ],
  "骅": [
    "hua2"
  ],
  "稠": [
    "chou2"
  ],
  "生效": [
    "sheng1 xiao4"
  ],
  "偡": [
    "zhan4"
  ],
  "成交": [
    "cheng2 jiao1"
  ],
  "葺": [
    "qi4"
  ],
  "酒楼": [
    "jiu3 lou2"
  ],
  "烜": [
    "xuan3"
  ],
  "无形中": [
    "wu2 xing2 zhong1"
  ],
  "测定": [
    "ce4 ding4"
  ],
  "国产": [
    "guo2 chan3"
  ],
  "冬天": [
    "dong1 tian1"
  ],
  "磙": [
    "gun3"
  ],
  "墣": [
    null
  ],
  "有利于": [
    "you3 li4 yu2"
  ],
  "服装": [
    "fu2 zhuang1"
  ],
  "啾": [
    "jiu1"
  ],
  "效力": [
    "xiao4 li4"
  ],
  "奭": [
    "Shi4",
    "shi4"
  ],
  "防治": [
    "fang2 zhi4"
  ],
  "搭乘": [
    "da1 cheng2"
  ],
  "隧道": [
    "sui4 dao4"
  ],
  "薯条": [
    "shu3 tiao2"
  ],
  "评论员": [
    "ping2 lun4 yuan2"
  ],
  "慌张": [
    "huang1 zhang1"
  ],
  "喜怒哀乐": [
    "xi3 nu4 ai1 le4"
  ],
  "航运": [
    "hang2 yun4"
  ],
  "嶙": [
    "lin2"
  ],
  "藤": [
    "teng2",
    "teng2"
  ],
  "玉": [
    "yu4"
  ],
  "接到": [
    "jie1 dao4"
  ],
  "扩散": [
    "kuo4 san4"
  ],
  "倾销": [
    "qing1 xiao1"
  ],
  "创": [
    "chuang4",
    "chuang4",
    "chuang1",
    "chuang4"
  ],
  "吭": [
    "hang2",
    "keng1"
  ],
  "丏": [
    "mian3"
  ],
  "生命": [
    "sheng1 ming4"
  ],
  "铫": [
    "Yao2",
    "diao4",
    "yao2"
  ],
  "社团": [
    "she4 tuan2"
  ],
  "地毯": [
    "di4 tan3"
  ],
  "裁判": [
    "cai2 pan4"
  ],
  "满怀": [
    "man3 huai2"
  ],
  "沿线": [
    "yan2 xian4"
  ],
  "打盹儿": [
    "da3 dun3 r5"
  ],
  "长效": [
    "chang2 xiao4"
  ],
  "语音": [
    "yu3 yin1"
  ],
  "隐蔽": [
    "yin3 bi4"
  ],
  "终究": [
    "zhong1 jiu1"
  ],
  "雅": [
    "ya3"
  ],
  "懈": [
    "xie4"
  ],
  "神仙": [
    "shen2 xian1"
  ],
  "产": [
    "chan3"
  ],
  "苋": [
    "xian4"
  ],
  "阻挡": [
    "zu3 dang3"
  ],
  "浒": [
    "hu3"
  ],
  "愿": [
    "yuan4",
    "yuan4"
  ],
  "本能": [
    "ben3 neng2"
  ],
  "辇": [
    "nian3"
  ],
  "保持": [
    "bao3 chi2"
  ],
  "度假": [
    "du4 jia4"
  ],
  "设定": [
    "she4 ding4"
  ],
  "讲学": [
    "jiang3 xue2"
  ],
  "换言之": [
    "huan4 yan2 zhi1"
  ],
  "心中": [
    "xin1 zhong1"
  ],
  "唯": [
    "wei2",
    "wei3"
  ],
  "倡议": [
    "chang4 yi4"
  ],
  "溜达": [
    "liu1 da5"
  ],
  "簃": [
    "yi2"
  ],
  "柖": [
    null
  ],
  "这": [
    "zhe4"
  ],
  "麝": [
    "she4"
  ],
  "孙": [
    "Sun1",
    "sun1"
  ],
  "画蛇添足": [
    "hua4 she2 tian1 zu2"
  ],
  "教授": [
    "jiao4 shou4"
  ],
  "穷人": [
    "qiong2 ren2"
  ],
  "巴": [
    "Ba1",
    "ba1"
  ],
  "池子": [
    "chi2 zi5"
  ],
  "赗": [
    "feng4"
  ],
  "壮大": [
    "zhuang4 da4"
  ],
  "荇": [
    "xing4"
  ],
  "耻笑": [
    "chi3 xiao4"
  ],
  "楮": [
    "chu3"
  ],
  "刬": [
    "chan3"
  ],
  "掰": [
    "bai1"
  ],
  "眼红": [
    "yan3 hong2"
  ],
  "桢": [
    "zhen1"
  ],
  "四": [
    "si4"
  ],
  "狂热": [
    "kuang2 re4"
  ],
  "香料": [
    "xiang1 liao4"
  ],
  "原型": [
    "yuan2 xing2"
  ],
  "之下": [
    "zhi1 xia4"
  ],
  "玞": [
    "fu1"
  ],
  "失眠": [
    "shi1 mian2"
  ],
  "源源不断": [
    "yuan2 yuan2 bu4 duan4"
  ],
  "黇": [
    "tian1"
  ],
  "修正": [
    "xiu1 zheng4"
  ],
  "开支": [
    "kai1 zhi1"
  ],
  "牡丹": [
    "Mu3 dan5",
    "mu3 dan5"
  ],
  "倘": [
    "chang2",
    "tang3"
  ],
  "骋": [
    "cheng3"
  ],
  "俏": [
    "qiao4"
  ],
  "鹳": [
    "guan4"
  ],
  "真的": [
    "zhen1 de5"
  ],
  "怪物": [
    "guai4 wu5"
  ],
  "距": [
    "ju4"
  ],
  "料": [
    "liao4"
  ],
  "缺少": [
    "que1 shao3"
  ],
  "槛": [
    "jian4",
    "kan3"
  ],
  "峄": [
    "yi4"
  ],
  "记得": [
    "ji4 de5"
  ],
  "留下": [
    "liu2 xia4"
  ],
  "继": [
    "ji4"
  ],
  "成": [
    "Cheng2",
    "cheng2"
  ],
  "沐浴露": [
    "mu4 yu4 lu4"
  ],
  "赞扬": [
    "zan4 yang2"
  ],
  "人手": [
    "ren2 shou3"
  ],
  "臀": [
    "tun2",
    "tun2"
  ],
  "毓": [
    "yu4"
  ],
  "两边": [
    "liang3 bian1"
  ],
  "提升": [
    "ti2 sheng1"
  ],
  "举行": [
    "ju3 xing2"
  ],
  "厨房": [
    "chu2 fang2"
  ],
  "绲": [
    "gun3"
  ],
  "敬爱": [
    "jing4 ai4"
  ],
  "不了了之": [
    "bu4 liao3 liao3 zhi1"
  ],
  "缐": [
    null
  ],
  "甍": [
    "meng2"
  ],
  "难点": [
    "nan2 dian3"
  ],
  "院子": [
    "yuan4 zi5"
  ],
  "过头": [
    "guo4 tou2"
  ],
  "蜂": [
    "feng1",
    "feng1",
    "feng1"
  ],
  "个头儿": [
    "ge4 tou2 r5"
  ],
  "联赛": [
    "lian2 sai4"
  ],
  "愎": [
    "bi4"
  ],
  "提出": [
    "ti2 chu1"
  ],
  "吠": [
    "fei4"
  ],
  "探险": [
    "tan4 xian3"
  ],
  "漶": [
    "huan4"
  ],
  "赛车": [
    "sai4 che1"
  ],
  "引用": [
    "yin3 yong4"
  ],
  "定为": [
    null
  ],
  "烟火": [
    "yan1 huo3"
  ],
  "缂": [
    "ke4"
  ],
  "麑": [
    "ni2"
  ],
  "葡萄酒": [
    "pu2 tao5 jiu3"
  ],
  "总理": [
    "zong3 li3"
  ],
  "家禽": [
    "jia1 qin2"
  ],
  "心思": [
    "xin1 si5"
  ],
  "余": [
    "Yu2",
    "yu2",
    "yu2"
  ],
  "巨额": [
    "ju4 e2"
  ],
  "捯": [
    "dao2"
  ],
  "弸": [
    "peng2"
  ],
  "剪刀": [
    "jian3 dao1"
  ],
  "栐": [
    "yong3"
  ],
  "园地": [
    "yuan2 di4"
  ],
  "虢": [
    "Guo2"
  ],
  "锖": [
    "qiang1"
  ],
  "鲎": [
    "hou4"
  ],
  "鼓掌": [
    "gu3 zhang3"
  ],
  "臊": [
    "sao1",
    "sao4"
  ],
  "盛大": [
    "sheng4 da4"
  ],
  "乳制品": [
    "ru3 zhi4 pin3"
  ],
  "舳": [
    "zhu2"
  ],
  "儦": [
    "biao1"
  ],
  "渡过": [
    "du4 guo4"
  ],
  "模": [
    "mo2",
    "mu2"
  ],
  "痂": [
    "jia1"
  ],
  "逊": [
    "xun4"
  ],
  "好坏": [
    "hao3 huai4"
  ],
  "珐": [
    "fa4"
  ],
  "怂": [
    "song2",
    "song3"
  ],
  "事故": [
    "shi4 gu4"
  ],
  "严谨": [
    "yan2 jin3"
  ],
  "天空": [
    "tian1 kong1"
  ],
  "得": [
    "de2",
    "de5",
    "dei3"
  ],
  "就医": [
    "jiu4 yi1"
  ],
  "甑": [
    "zeng4"
  ],
  "痔": [
    "zhi4"
  ],
  "猜谜": [
    "cai1 mi2"
  ],
  "鸭子": [
    "ya1 zi5"
  ],
  "摸": [
    "mo1",
    "mo2"
  ],
  "电影院": [
    "dian4 ying3 yuan4"
  ],
  "䎖": [
    null
  ],
  "算计": [
    "suan4 ji5"
  ],
  "瘙": [
    "sao4"
  ],
  "薿": [
    "ni3"
  ],
  "热水": [
    "re4 shui3"
  ],
  "珝": [
    "xu3"
  ],
  "外汇": [
    "wai4 hui4"
  ],
  "敬重": [
    "jing4 zhong4"
  ],
  "教学楼": [
    "jiao4 xue2 lou2"
  ],
  "艺": [
    "yi4"
  ],
  "服从": [
    "fu2 cong2"
  ],
  "骕": [
    "su4"
  ],
  "提起": [
    "ti2 qi3"
  ],
  "妭": [
    "ba2"
  ],
  "恢复": [
    "hui1 fu4"
  ],
  "偰": [
    "xie4"
  ],
  "戒指": [
    "jie4 zhi5"
  ],
  "遮盖": [
    "zhe1 gai4"
  ],
  "人群": [
    "ren2 qun2"
  ],
  "敛": [
    "lian3",
    "lian4"
  ],
  "写字楼": [
    "xie3 zi4 lou2"
  ],
  "外头": [
    "wai4 tou5"
  ],
  "合并": [
    "he2 bing4",
    "he2 bing4"
  ],
  "苓": [
    "ling2"
  ],
  "赛场": [
    "sai4 chang3"
  ],
  "泓": [
    "hong2"
  ],
  "下调": [
    "xia4 diao4",
    "xia4 tiao2"
  ],
  "老板": [
    "lao3 ban3",
    "Lao3 ban3",
    "lao3 ban3"
  ],
  "拿走": [
    "na2 zou3"
  ],
  "爷爷": [
    "ye2 ye5"
  ],
  "画儿": [
    "hua4 r5"
  ],
  "管家": [
    "guan3 jia1"
  ],
  "此刻": [
    "ci3 ke4"
  ],
  "虚构": [
    "xu1 gou4"
  ],
  "家具": [
    "jia1 ju4"
  ],
  "忝": [
    "tian3"
  ],
  "口哨": [
    "kou3 shao4"
  ],
  "铜": [
    "tong2"
  ],
  "鬓": [
    "bin4"
  ],
  "挛": [
    "luan2"
  ],
  "匈": [
    "Xiong1",
    "xiong1"
  ],
  "高山": [
    "gao1 shan1"
  ],
  "墨水": [
    "mo4 shui3"
  ],
  "倡导": [
    "chang4 dao3"
  ],
  "游戏机": [
    "you2 xi4 ji1"
  ],
  "彳": [
    "chi4"
  ],
  "口试": [
    "kou3 shi4"
  ],
  "兴趣": [
    "xing4 qu4"
  ],
  "彀": [
    "gou4"
  ],
  "愭": [
    "qi2"
  ],
  "搏斗": [
    "bo2 dou4"
  ],
  "此起彼伏": [
    "ci3 qi3 bi3 fu2"
  ],
  "青少年": [
    "qing1 shao4 nian2"
  ],
  "智慧": [
    "zhi4 hui4"
  ],
  "譬如说": [
    "pi4 ru2 shuo1"
  ],
  "崿": [
    "e4"
  ],
  "台球": [
    "tai2 qiu2"
  ],
  "失控": [
    "shi1 kong4"
  ],
  "嶲": [
    "xi1"
  ],
  "颡": [
    "sang3"
  ],
  "硿": [
    null
  ],
  "寓": [
    "yu4",
    "yu4"
  ],
  "禄": [
    "lu4"
  ],
  "登山": [
    "deng1 shan1"
  ],
  "姊": [
    "zi3",
    "zi3"
  ],
  "睚": [
    "ya2"
  ],
  "赃": [
    "zang1",
    "zang1"
  ],
  "婳": [
    "hua4"
  ],
  "缆车": [
    "lan3 che1"
  ],
  "清楚": [
    "qing1 chu5"
  ],
  "无私": [
    "wu2 si1"
  ],
  "伉": [
    "kang4"
  ],
  "无线电": [
    "wu2 xian4 dian4"
  ],
  "更": [
    "geng1",
    "geng4"
  ],
  "遍地": [
    "bian4 di4"
  ],
  "漠然": [
    "mo4 ran2"
  ],
  "监护": [
    "jian1 hu4"
  ],
  "碳": [
    "tan4"
  ],
  "耵": [
    "ding1"
  ],
  "深受": [
    "shen1 shou4"
  ],
  "带来": [
    "dai4 lai2"
  ],
  "通过": [
    "tong1 guo4"
  ],
  "书法": [
    "shu1 fa3"
  ],
  "崩": [
    "beng1"
  ],
  "过失": [
    "guo4 shi1"
  ],
  "耧": [
    "lou2"
  ],
  "麾": [
    "hui1"
  ],
  "相等": [
    "xiang1 deng3"
  ],
  "跳远": [
    "tiao4 yuan3"
  ],
  "为何": [
    "wei4 he2"
  ],
  "探亲": [
    "tan4 qin1"
  ],
  "庆贺": [
    "qing4 he4"
  ],
  "职务": [
    "zhi2 wu4"
  ],
  "前往": [
    "qian2 wang3"
  ],
  "银幕": [
    "yin2 mu4"
  ],
  "真假": [
    "zhen1 jia3"
  ],
  "谝": [
    "pian3"
  ],
  "没关系": [
    "mei2 guan1 xi5"
  ],
  "繄": [
    "yi1"
  ],
  "地面": [
    "di4 mian4"
  ],
  "爸爸": [
    "ba4 ba5"
  ],
  "尽快": [
    "jin3 kuai4",
    "jin4 kuai4"
  ],
  "髓": [
    "sui3"
  ],
  "裒": [
    "pou2"
  ],
  "苣": [
    "ju4",
    "qu3"
  ],
  "山峰": [
    "shan1 feng1"
  ],
  "摩": [
    "mo2"
  ],
  "摘": [
    "zhai1"
  ],
  "庸": [
    "yong1"
  ],
  "茂盛": [
    "mao4 sheng4"
  ],
  "欲": [
    "yu4",
    "yu4"
  ],
  "庞": [
    "Pang2",
    "pang2"
  ],
  "视察": [
    "shi4 cha2"
  ],
  "轳": [
    "lu2"
  ],
  "粗暴": [
    "cu1 bao4"
  ],
  "评估": [
    "ping2 gu1"
  ],
  "关机": [
    "guan1 ji1"
  ],
  "巢": [
    "Chao2",
    "chao2"
  ],
  "自负": [
    "zi4 fu4"
  ],
  "栈": [
    "zhan4"
  ],
  "崶": [
    "feng1"
  ],
  "纵然": [
    "zong4 ran2"
  ],
  "呓": [
    "yi4"
  ],
  "前来": [
    "qian2 lai2"
  ],
  "字典": [
    "zi4 dian3"
  ],
  "喹": [
    "kui2"
  ],
  "服用": [
    "fu2 yong4"
  ],
  "澄": [
    "cheng2",
    "Cheng2",
    "cheng2",
    "deng4"
  ],
  "手表": [
    "shou3 biao3"
  ],
  "进去": [
    "jin4 qu4"
  ],
  "弥漫": [
    "mi2 man4",
    "mi2 man4"
  ],
  "码头": [
    "ma3 tou2"
  ],
  "直升机": [
    "zhi2 sheng1 ji1"
  ],
  "开玩笑": [
    "kai1 wan2 xiao4"
  ],
  "璃": [
    "li2",
    "li2",
    "li2"
  ],
  "封顶": [
    "feng1 ding3"
  ],
  "挽救": [
    "wan3 jiu4"
  ],
  "解说": [
    "jie3 shuo1"
  ],
  "脱身": [
    "tuo1 shen1"
  ],
  "审": [
    "shen3"
  ],
  "跬": [
    "kui3"
  ],
  "对话": [
    "dui4 hua4"
  ],
  "慢慢": [
    "man4 man4"
  ],
  "唉": [
    "ai1",
    "ai4"
  ],
  "嘏": [
    "gu3",
    "jia3"
  ],
  "单调": [
    "dan1 diao4"
  ],
  "撇": [
    "pie1",
    "pie3"
  ],
  "认": [
    "ren4"
  ],
  "当代": [
    "dang1 dai4"
  ],
  "曝": [
    "pu4"
  ],
  "只": [
    "zhi1",
    "zhi3",
    "zhi3",
    "zhi1",
    "zhi3",
    "zhi1"
  ],
  "万能": [
    "wan4 neng2"
  ],
  "骗人": [
    "pian4 ren2"
  ],
  "瞬": [
    "shun4"
  ],
  "稑": [
    "lu4"
  ],
  "动作": [
    "dong4 zuo4"
  ],
  "芤": [
    "kou1"
  ],
  "疲劳": [
    "pi2 lao2"
  ],
  "构造": [
    "gou4 zao4"
  ],
  "竑": [
    "hong2"
  ],
  "割": [
    "ge1"
  ],
  "捍": [
    "han4",
    "han4"
  ],
  "脱离": [
    "tuo1 li2"
  ],
  "噻": [
    "sai1"
  ],
  "东": [
    "Dong1",
    "dong1"
  ],
  "职工": [
    "zhi2 gong1"
  ],
  "一辈子": [
    "yi1 bei4 zi5"
  ],
  "瘌": [
    "la4"
  ],
  "深度": [
    "shen1 du4"
  ],
  "毯子": [
    "tan3 zi5"
  ],
  "硅": [
    "gui1"
  ],
  "依靠": [
    "yi1 kao4"
  ],
  "缶": [
    "fou3"
  ],
  "轮流": [
    "lun2 liu2"
  ],
  "党": [
    "Dang3",
    "dang3"
  ],
  "藿": [
    "huo4"
  ],
  "叮": [
    "ding1"
  ],
  "椑": [
    "bei1"
  ],
  "详": [
    "xiang2"
  ],
  "道理": [
    "dao4 li5"
  ],
  "手续": [
    "shou3 xu4"
  ],
  "擦": [
    "ca1"
  ],
  "繇": [
    "yao2",
    "you2",
    "zhou4"
  ],
  "敌人": [
    "di2 ren2"
  ],
  "季节": [
    "ji4 jie2"
  ],
  "自我": [
    "zi4 wo3"
  ],
  "阴": [
    "Yin1",
    "yin1",
    "yin1"
  ],
  "有口无心": [
    "you3 kou3 wu2 xin1"
  ],
  "皕": [
    "bi4"
  ],
  "淋": [
    "lin2",
    "lin4"
  ],
  "钵": [
    "bo1",
    "bo1"
  ],
  "踅": [
    "chi4",
    "xue2"
  ],
  "萧": [
    "Xiao1",
    "xiao1"
  ],
  "祭": [
    "Zhai4",
    "ji4"
  ],
  "嘲弄": [
    "chao2 nong4"
  ],
  "蠲": [
    "juan1"
  ],
  "七": [
    "qi1"
  ],
  "医生": [
    "yi1 sheng1"
  ],
  "绮": [
    "qi3"
  ],
  "人性": [
    "ren2 xing4"
  ],
  "瞵": [
    "lin2"
  ],
  "火腿": [
    "huo3 tui3"
  ],
  "患": [
    "huan4"
  ],
  "冒险": [
    "mao4 xian3"
  ],
  "醒来": [
    "xing3 lai2"
  ],
  "狐": [
    "hu2"
  ],
  "辟": [
    "bi4",
    "pi4",
    "pi4"
  ],
  "笑声": [
    "xiao4 sheng1"
  ],
  "差点儿": [
    "cha4 dian3 r5"
  ],
  "再度": [
    "zai4 du4"
  ],
  "莰": [
    "kan3"
  ],
  "宇航员": [
    "yu3 hang2 yuan2"
  ],
  "瑝": [
    null
  ],
  "禳": [
    "rang2"
  ],
  "座右铭": [
    "zuo4 you4 ming2"
  ],
  "点火": [
    "dian3 huo3"
  ],
  "趺": [
    "fu1"
  ],
  "龟": [
    "gui1",
    "jun1",
    "qiu1"
  ],
  "陛": [
    "bi4"
  ],
  "造": [
    "zao4"
  ],
  "裨": [
    "Pi2",
    "bi4",
    "pi2"
  ],
  "惋惜": [
    "wan3 xi1"
  ],
  "无辜": [
    "wu2 gu1"
  ],
  "数额": [
    "shu4 e2"
  ],
  "仪器": [
    "yi2 qi4"
  ],
  "没错": [
    "mei2 cuo4"
  ],
  "鬶": [
    null
  ],
  "慢性": [
    "man4 xing4"
  ],
  "猾": [
    "hua2"
  ],
  "虚伪": [
    "xu1 wei3"
  ],
  "名声": [
    "ming2 sheng1"
  ],
  "鲏": [
    "pi2"
  ],
  "处罚": [
    "chu3 fa2"
  ],
  "景区": [
    "jing3 qu1"
  ],
  "绕行": [
    "rao4 xing2"
  ],
  "趋于": [
    "qu1 yu2"
  ],
  "袁": [
    "Yuan2",
    "yuan2"
  ],
  "热": [
    "re4"
  ],
  "走路": [
    "zou3 lu4"
  ],
  "掀": [
    "xian1"
  ],
  "疃": [
    "tuan3"
  ],
  "低头": [
    "di1 tou2"
  ],
  "荔": [
    "li4",
    "li4"
  ],
  "构建": [
    "gou4 jian4"
  ],
  "豆子": [
    "dou4 zi5"
  ],
  "楫": [
    "ji2",
    "ji2"
  ],
  "铝": [
    "lu:3"
  ],
  "纲要": [
    "gang1 yao4"
  ],
  "封": [
    "Feng1",
    "feng1"
  ],
  "伭": [
    "Xian2",
    "xian2",
    "xuan2"
  ],
  "觌": [
    "di2"
  ],
  "导火索": [
    "dao3 huo3 suo3"
  ],
  "膀": [
    "bang3",
    "bang4",
    "pang1",
    "pang2",
    "bang3"
  ],
  "逻": [
    "luo2"
  ],
  "枸": [
    "gou1",
    "gou3",
    "ju3"
  ],
  "貆": [
    "huan2"
  ],
  "女朋友": [
    "nu:3 peng2 you5"
  ],
  "妥当": [
    "tuo3 dang5"
  ],
  "贲": [
    "Ben1",
    "ben1",
    "bi4"
  ],
  "遏制": [
    "e4 zhi4"
  ],
  "哪": [
    "na3",
    "na5",
    "ne2",
    "nei3"
  ],
  "上空": [
    "shang4 kong1"
  ],
  "镈": [
    "bo2"
  ],
  "实验室": [
    "shi2 yan4 shi4"
  ],
  "恧": [
    "nu:4"
  ],
  "师资": [
    "shi1 zi1"
  ],
  "兜儿": [
    "dou1 r5"
  ],
  "故": [
    "gu4"
  ],
  "个案": [
    "ge4 an4"
  ],
  "嚆": [
    "hao1"
  ],
  "糅": [
    "rou2"
  ],
  "伤脑筋": [
    "shang1 nao3 jin1"
  ],
  "志愿者": [
    "zhi4 yuan4 zhe3"
  ],
  "瞥": [
    "pie1"
  ],
  "悝": [
    "kui1",
    "li3"
  ],
  "阎王": [
    "Yan2 wang5"
  ],
  "瘥": [
    "chai4",
    "cuo2"
  ],
  "硎": [
    "xing2"
  ],
  "好在": [
    "hao3 zai4"
  ],
  "向来": [
    "xiang4 lai2"
  ],
  "监管": [
    "jian1 guan3"
  ],
  "囷": [
    "qun1"
  ],
  "蔀": [
    "bu4"
  ],
  "举措": [
    "ju3 cuo4"
  ],
  "心安理得": [
    "xin1 an1 li3 de2"
  ],
  "忘掉": [
    "wang4 diao4"
  ],
  "庼": [
    "qing3"
  ],
  "溢": [
    "yi4"
  ],
  "觭": [
    "ji1"
  ],
  "仪表": [
    "yi2 biao3"
  ],
  "编排": [
    "bian1 pai2"
  ],
  "伟": [
    "wei3"
  ],
  "甚": [
    "shen2",
    "shen4"
  ],
  "光明": [
    "guang1 ming2"
  ],
  "同年": [
    "tong2 nian2"
  ],
  "堠": [
    "hou4"
  ],
  "劼": [
    "jie2"
  ],
  "医药": [
    "yi1 yao4"
  ],
  "剧场": [
    "ju4 chang3"
  ],
  "鹊": [
    "que4"
  ],
  "倒闭": [
    "dao3 bi4"
  ],
  "尘": [
    "chen2"
  ],
  "伸张": [
    "shen1 zhang1"
  ],
  "人生": [
    "ren2 sheng1"
  ],
  "大宗": [
    "da4 zong1"
  ],
  "极力": [
    "ji2 li4"
  ],
  "不平": [
    "bu4 ping2"
  ],
  "悲": [
    "bei1"
  ],
  "关于": [
    "guan1 yu2"
  ],
  "响": [
    "xiang3"
  ],
  "鳂": [
    null
  ],
  "滞留": [
    "zhi4 liu2"
  ],
  "阔绰": [
    "kuo4 chuo4"
  ],
  "奋斗": [
    "fen4 dou4"
  ],
  "弋": [
    "yi4"
  ],
  "俊": [
    "jun4",
    "jun4",
    "zun4",
    "jun4"
  ],
  "月亮": [
    "yue4 liang5"
  ],
  "要么": [
    "yao4 me5"
  ],
  "抚恤": [
    "fu3 xu4"
  ],
  "凓": [
    "li4"
  ],
  "踵": [
    "zhong3"
  ],
  "衄": [
    "nu:4",
    "nu:4",
    "nu:4"
  ],
  "稿": [
    "gao3",
    "gao3"
  ],
  "眬": [
    "long2"
  ],
  "岬": [
    "jia3"
  ],
  "髎": [
    "liao2"
  ],
  "验证": [
    "yan4 zheng4"
  ],
  "兢": [
    "jing1"
  ],
  "太极": [
    "Tai4 ji2"
  ],
  "痦": [
    "wu4"
  ],
  "锇": [
    "e2"
  ],
  "病人": [
    "bing4 ren2"
  ],
  "稀罕": [
    "xi1 han5"
  ],
  "佞": [
    "ning4"
  ],
  "麇": [
    "jun1",
    "qun2"
  ],
  "童年": [
    "tong2 nian2"
  ],
  "稂": [
    "lang2"
  ],
  "悃": [
    "kun3"
  ],
  "岩": [
    "yan2",
    "yan2",
    "yan2",
    "yan2"
  ],
  "壮丽": [
    "zhuang4 li4"
  ],
  "晕倒": [
    "yun1 dao3"
  ],
  "地下水": [
    "di4 xia4 shui3"
  ],
  "参军": [
    "can1 jun1"
  ],
  "小学": [
    "xiao3 xue2"
  ],
  "格外": [
    "ge2 wai4"
  ],
  "画家": [
    "hua4 jia1"
  ],
  "试用期": [
    "shi4 yong4 qi1"
  ],
  "玖": [
    "jiu3"
  ],
  "序": [
    "xu4"
  ],
  "振作": [
    "zhen4 zuo4"
  ],
  "恸": [
    "tong4"
  ],
  "侵占": [
    "qin1 zhan4"
  ],
  "定居": [
    "ding4 ju1"
  ],
  "瀼": [
    "rang2"
  ],
  "模糊": [
    "mo2 hu5"
  ],
  "蜐": [
    "jie2"
  ],
  "绅士": [
    "shen1 shi4"
  ],
  "趁早": [
    "chen4 zao3"
  ],
  "疙": [
    "ge1"
  ],
  "撕": [
    "si1"
  ],
  "超市": [
    "chao1 shi4"
  ],
  "纡": [
    "Yu1",
    "yu1"
  ],
  "笱": [
    "gou3"
  ],
  "涂": [
    "tu2",
    "Tu2",
    "tu2"
  ],
  "连": [
    "Lian2",
    "lian2"
  ],
  "驭": [
    "yu4"
  ],
  "忍受": [
    "ren3 shou4"
  ],
  "忤": [
    "wu3"
  ],
  "零售": [
    "ling2 shou4"
  ],
  "应该": [
    "ying1 gai1"
  ],
  "棱": [
    "leng2",
    "leng2",
    "ling2"
  ],
  "彼此": [
    "bi3 ci3"
  ],
  "对外": [
    "dui4 wai4"
  ],
  "喧哗": [
    "xuan1 hua2"
  ],
  "府": [
    "fu3"
  ],
  "合法": [
    "he2 fa3"
  ],
  "桔": [
    "jie2",
    "ju2"
  ],
  "托": [
    "tuo1",
    "tuo1"
  ],
  "痴心": [
    "chi1 xin1"
  ],
  "浟": [
    null
  ],
  "昏": [
    "hun1",
    "hun1"
  ],
  "朝代": [
    "chao2 dai4"
  ],
  "付出": [
    "fu4 chu1"
  ],
  "开发": [
    "kai1 fa1"
  ],
  "微弱": [
    "wei1 ruo4"
  ],
  "攉": [
    "huo1"
  ],
  "壸": [
    "kun3"
  ],
  "只顾": [
    "zhi3 gu4"
  ],
  "成果": [
    "cheng2 guo3"
  ],
  "擞": [
    "sou3"
  ],
  "课题": [
    "ke4 ti2"
  ],
  "空地": [
    "kong1 di4",
    "kong4 di4"
  ],
  "爱": [
    "ai4"
  ],
  "桤": [
    "qi1"
  ],
  "热腾腾": [
    "re4 teng2 teng2"
  ],
  "齉": [
    "nang4"
  ],
  "超车": [
    "chao1 che1"
  ],
  "羽毛球": [
    "yu3 mao2 qiu2"
  ],
  "艿": [
    "nai3"
  ],
  "怍": [
    "zuo4"
  ],
  "团结": [
    "tuan2 jie2"
  ],
  "刑法": [
    "xing2 fa3"
  ],
  "裁定": [
    "cai2 ding4"
  ],
  "惝": [
    "chang3"
  ],
  "耐": [
    "nai4"
  ],
  "决议": [
    "jue2 yi4"
  ],
  "深入": [
    "shen1 ru4"
  ],
  "志愿": [
    "zhi4 yuan4"
  ],
  "集": [
    "ji2"
  ],
  "剁": [
    "duo4"
  ],
  "摸索": [
    "mo1 suo5"
  ],
  "屼": [
    "wu4"
  ],
  "由此可见": [
    "you2 ci3 ke3 jian4"
  ],
  "粕": [
    "po4"
  ],
  "顶": [
    "ding3"
  ],
  "茁": [
    "zhuo2"
  ],
  "行": [
    "hang2",
    "xing2"
  ],
  "佐料": [
    "zuo3 liao4"
  ],
  "红酒": [
    "hong2 jiu3"
  ],
  "器材": [
    "qi4 cai2"
  ],
  "粹": [
    "cui4"
  ],
  "出版": [
    "chu1 ban3"
  ],
  "赶": [
    "gan3"
  ],
  "欣欣向荣": [
    "xin1 xin1 xiang4 rong2"
  ],
  "粽": [
    "zong4",
    "zong4"
  ],
  "女生": [
    "nu:3 sheng1"
  ],
  "热爱": [
    "re4 ai4"
  ],
  "观摩": [
    "guan1 mo2"
  ],
  "看待": [
    "kan4 dai4"
  ],
  "摭": [
    "zhi2"
  ],
  "教练": [
    "jiao4 lian4"
  ],
  "素描": [
    "su4 miao2"
  ],
  "墕": [
    null
  ],
  "俗话说": [
    "su2 hua4 shuo1"
  ],
  "小于": [
    "xiao3 yu2"
  ],
  "冶": [
    "ye3"
  ],
  "筘": [
    "kou4"
  ],
  "醉": [
    "zui4"
  ],
  "帑": [
    "tang3"
  ],
  "嫣": [
    "yan1"
  ],
  "清静": [
    "qing1 jing4"
  ],
  "北方": [
    "bei3 fang1"
  ],
  "礵": [
    "shuang1"
  ],
  "随着": [
    "sui2 zhe5"
  ],
  "蛆": [
    "qu1"
  ],
  "劲": [
    "jin4",
    "jing4"
  ],
  "陶醉": [
    "tao2 zui4"
  ],
  "嬬": [
    "xu1"
  ],
  "洗衣机": [
    "xi3 yi1 ji1"
  ],
  "葑": [
    "feng1"
  ],
  "紧紧": [
    "jin3 jin3"
  ],
  "迫不及待": [
    "po4 bu4 ji2 dai4"
  ],
  "屉": [
    "ti4"
  ],
  "由来": [
    "you2 lai2"
  ],
  "渺": [
    "miao3"
  ],
  "落": [
    "la4",
    "lao4",
    "luo4"
  ],
  "各种": [
    "ge4 zhong3"
  ],
  "白领": [
    "bai2 ling3"
  ],
  "球员": [
    "qiu2 yuan2"
  ],
  "手术": [
    "shou3 shu4"
  ],
  "这样": [
    "zhe4 yang4"
  ],
  "郧": [
    "yun2"
  ],
  "旱": [
    "han4"
  ],
  "送礼": [
    "song4 li3"
  ],
  "隋": [
    "Sui2"
  ],
  "防卫": [
    "fang2 wei4"
  ],
  "幺": [
    "Yao1",
    "yao1"
  ],
  "初次": [
    "chu1 ci4"
  ],
  "奄": [
    "Yan3",
    "yan1",
    "yan3"
  ],
  "病症": [
    "bing4 zheng4"
  ],
  "驰名": [
    "chi2 ming2"
  ],
  "趑": [
    "zi1"
  ],
  "含量": [
    "han2 liang4"
  ],
  "中庸": [
    "Zhong1 yong1",
    "zhong1 yong1"
  ],
  "熨": [
    "yu4",
    "yun4"
  ],
  "农场": [
    "nong2 chang3"
  ],
  "坽": [
    "ling2"
  ],
  "通通": [
    "tong1 tong1"
  ],
  "子弟": [
    "zi3 di4"
  ],
  "耠": [
    "huo1"
  ],
  "吹牛": [
    "chui1 niu2"
  ],
  "空间": [
    "kong1 jian1"
  ],
  "甥": [
    "sheng1"
  ],
  "交集": [
    "jiao1 ji2"
  ],
  "萦": [
    "ying2"
  ],
  "典型": [
    "dian3 xing2"
  ],
  "珦": [
    "xiang4"
  ],
  "契机": [
    "qi4 ji1"
  ],
  "鲫": [
    "ji4"
  ],
  "级别": [
    "ji2 bie2"
  ],
  "寒冷": [
    "han2 leng3"
  ],
  "兔": [
    "tu4",
    "tu4"
  ],
  "蝰": [
    "kui2"
  ],
  "很难说": [
    null
  ],
  "自然而然": [
    "zi4 ran2 er2 ran2"
  ],
  "代": [
    "dai4"
  ],
  "块": [
    "kuai4"
  ],
  "利索": [
    "li4 suo5"
  ],
  "握": [
    "wo4"
  ],
  "兴致": [
    "xing4 zhi4"
  ],
  "专注": [
    "zhuan1 zhu4"
  ],
  "大片": [
    "da4 pian4"
  ],
  "截止": [
    "jie2 zhi3"
  ],
  "哀求": [
    "ai1 qiu2"
  ],
  "蛙": [
    "wa1",
    "wa1"
  ],
  "葸": [
    "xi3"
  ],
  "劳累": [
    "lao2 lei4"
  ],
  "返还": [
    "fan3 huan2"
  ],
  "皇宫": [
    "huang2 gong1"
  ],
  "蜊": [
    "li2"
  ],
  "通道": [
    "Tong1 dao4",
    "tong1 dao4"
  ],
  "喀": [
    "ka1"
  ],
  "珠宝": [
    "zhu1 bao3"
  ],
  "桂": [
    "Gui4",
    "gui4"
  ],
  "海域": [
    "hai3 yu4"
  ],
  "中年": [
    "zhong1 nian2"
  ],
  "处方": [
    "chu3 fang1"
  ],
  "柄": [
    "bing3"
  ],
  "热情": [
    "re4 qing2"
  ],
  "艰苦奋斗": [
    "jian1 ku3 fen4 dou4"
  ],
  "联系": [
    "lian2 xi4",
    "lian2 xi4"
  ],
  "渴": [
    "ke3"
  ],
  "轻型": [
    "qing1 xing2"
  ],
  "份额": [
    "fen4 e2"
  ],
  "出身": [
    "chu1 shen1"
  ],
  "根基": [
    "gen1 ji1"
  ],
  "诏": [
    "zhao4"
  ],
  "兴": [
    "Xing1",
    "xing1",
    "xing4"
  ],
  "照": [
    "zhao4",
    "zhao4"
  ],
  "把握": [
    "ba3 wo4"
  ],
  "管辖": [
    "guan3 xia2"
  ],
  "唠": [
    "lao2",
    "lao4"
  ],
  "他人": [
    "ta1 ren2"
  ],
  "窗帘": [
    "chuang1 lian2"
  ],
  "冉": [
    "ran3",
    "Ran3",
    "ran3"
  ],
  "度过": [
    "du4 guo4"
  ],
  "崡": [
    null
  ],
  "玳": [
    "dai4",
    "dai4"
  ],
  "协助": [
    "xie2 zhu4"
  ],
  "关上": [
    "guan1 shang4"
  ],
  "飞船": [
    "fei1 chuan2"
  ],
  "呢": [
    "ne5",
    "ni2"
  ],
  "即将": [
    "ji2 jiang1"
  ],
  "来历": [
    "lai2 li4"
  ],
  "追溯": [
    "zhui1 su4"
  ],
  "慎": [
    "shen4",
    "shen4"
  ],
  "地球": [
    "di4 qiu2"
  ],
  "当心": [
    "dang1 xin1"
  ],
  "兴奋": [
    "xing1 fen4"
  ],
  "取而代之": [
    "qu3 er2 dai4 zhi1"
  ],
  "骃": [
    "yin1"
  ],
  "忘": [
    "wang4"
  ],
  "口": [
    "kou3"
  ],
  "佩服": [
    "pei4 fu2"
  ],
  "费劲": [
    "fei4 jin4"
  ],
  "细胞": [
    "xi4 bao1"
  ],
  "一面": [
    "yi1 mian4"
  ],
  "捣乱": [
    "dao3 luan4"
  ],
  "佘": [
    "She2"
  ],
  "拍戏": [
    "pai1 xi4"
  ],
  "如实": [
    "ru2 shi2"
  ],
  "咂": [
    "za1"
  ],
  "孩子": [
    "hai2 zi5"
  ],
  "走": [
    "zou3"
  ],
  "时段": [
    "shi2 duan4"
  ],
  "丰盛": [
    "feng1 sheng4"
  ],
  "裤": [
    "ku4",
    "ku4"
  ],
  "龉": [
    "yu3"
  ],
  "请柬": [
    "qing3 jian3"
  ],
  "垂": [
    "chui2"
  ],
  "逝": [
    "shi4"
  ],
  "排队": [
    "pai2 dui4"
  ],
  "高贵": [
    "gao1 gui4"
  ],
  "椟": [
    "du2"
  ],
  "唾": [
    "tuo4"
  ],
  "猛然": [
    "meng3 ran2"
  ],
  "缇": [
    "ti2"
  ],
  "栏": [
    "lan2"
  ],
  "沽": [
    "Gu1",
    "gu1"
  ],
  "筲": [
    "shao1",
    "shao1"
  ],
  "独立自主": [
    "du2 li4 zi4 zhu3"
  ],
  "践": [
    "jian4"
  ],
  "袗": [
    "zhen3"
  ],
  "裳": [
    "chang2",
    "shang5"
  ],
  "畏缩": [
    "wei4 suo1"
  ],
  "叆": [
    "ai4"
  ],
  "面": [
    "mian4",
    "mian4",
    "mian4"
  ],
  "身份": [
    "shen1 fen4"
  ],
  "姥": [
    "lao3",
    "mu3"
  ],
  "中小学": [
    "zhong1 xiao3 xue2"
  ],
  "睡眠": [
    "shui4 mian2"
  ],
  "公立": [
    "gong1 li4"
  ],
  "警钟": [
    "jing3 zhong1"
  ],
  "配音": [
    "pei4 yin1"
  ],
  "侔": [
    "mou2"
  ],
  "晃荡": [
    "huang4 dang5"
  ],
  "储": [
    "Chu3",
    "chu3"
  ],
  "往年": [
    "wang3 nian2"
  ],
  "扩建": [
    "kuo4 jian4"
  ],
  "手动": [
    "shou3 dong4"
  ],
  "瓜": [
    "gua1"
  ],
  "定做": [
    "ding4 zuo4"
  ],
  "畲": [
    "She1",
    "yu2"
  ],
  "难得": [
    "nan2 de2"
  ],
  "墓": [
    "mu4"
  ],
  "孤独": [
    "gu1 du2"
  ],
  "靥": [
    "ye4"
  ],
  "出门": [
    "chu1 men2"
  ],
  "纟": [
    "si1"
  ],
  "录取": [
    "lu4 qu3"
  ],
  "钌": [
    "liao3",
    "liao4"
  ],
  "殄": [
    "tian3"
  ],
  "熏陶": [
    "xun1 tao2"
  ],
  "上流": [
    "shang4 liu2"
  ],
  "内容": [
    "nei4 rong2"
  ],
  "立方米": [
    "li4 fang1 mi3"
  ],
  "政府": [
    "zheng4 fu3"
  ],
  "算了": [
    "suan4 le5"
  ],
  "沸": [
    "fei4"
  ],
  "学年": [
    "xue2 nian2"
  ],
  "领导": [
    "ling3 dao3"
  ],
  "清理": [
    "qing1 li3"
  ],
  "锁": [
    "suo3",
    "suo3"
  ],
  "䓫": [
    null
  ],
  "大脑": [
    "da4 nao3"
  ],
  "谣言": [
    "yao2 yan2"
  ],
  "雯": [
    "wen2"
  ],
  "吆": [
    "yao1"
  ],
  "看好": [
    "kan1 hao3",
    "kan4 hao3"
  ],
  "撰写": [
    "zhuan4 xie3"
  ],
  "伙食": [
    "huo3 shi2"
  ],
  "二手车": [
    "er4 shou3 che1"
  ],
  "总的来说": [
    "zong3 de5 lai2 shuo1"
  ],
  "特制": [
    "te4 zhi4"
  ],
  "可想而知": [
    "ke3 xiang3 er2 zhi1"
  ],
  "遗留": [
    "yi2 liu2"
  ],
  "煴": [
    "yun4"
  ],
  "瞩": [
    "zhu3"
  ],
  "休眠": [
    "xiu1 mian2"
  ],
  "赓": [
    "geng1"
  ],
  "字幕": [
    "zi4 mu4"
  ],
  "有幸": [
    "you3 xing4"
  ],
  "狂欢": [
    "kuang2 huan1"
  ],
  "干燥": [
    "gan1 zao4"
  ],
  "隃": [
    "yu2"
  ],
  "蚴": [
    "you4"
  ],
  "裸": [
    "luo3",
    "luo3",
    "luo3"
  ],
  "煞": [
    "sha1",
    "sha4"
  ],
  "崄": [
    "xian3"
  ],
  "消费": [
    "xiao1 fei4"
  ],
  "绽放": [
    "zhan4 fang4"
  ],
  "忸": [
    "niu3"
  ],
  "本地": [
    "ben3 di4"
  ],
  "捧": [
    "peng3"
  ],
  "偏差": [
    "pian1 cha1"
  ],
  "裴": [
    "Pei2",
    "pei2",
    "pei2"
  ],
  "瑾": [
    "jin3"
  ],
  "乐曲": [
    "yue4 qu3"
  ],
  "亵": [
    "xie4"
  ],
  "惊讶": [
    "jing1 ya4"
  ],
  "脱口而出": [
    "tuo1 kou3 er2 chu1"
  ],
  "辩解": [
    "bian4 jie3"
  ],
  "允": [
    "yun3"
  ],
  "地域": [
    "di4 yu4"
  ],
  "残": [
    "can2"
  ],
  "涝": [
    "lao4"
  ],
  "检测": [
    "jian3 ce4"
  ],
  "星座": [
    "xing1 zuo4"
  ],
  "悠久": [
    "you1 jiu3"
  ],
  "匀": [
    "yun2"
  ],
  "噪音": [
    "zao4 yin1"
  ],
  "烘干": [
    "hong1 gan1"
  ],
  "溍": [
    "jin4"
  ],
  "藨": [
    "biao1"
  ],
  "愁": [
    "chou2"
  ],
  "本科": [
    "ben3 ke1"
  ],
  "好多": [
    "hao3 duo1"
  ],
  "破裂": [
    "po4 lie4"
  ],
  "过半": [
    "guo4 ban4"
  ],
  "坑": [
    "keng1",
    "keng1"
  ],
  "妇女": [
    "fu4 nu:3"
  ],
  "薄": [
    "Bo2",
    "bao2",
    "bo2",
    "bo4"
  ],
  "铹": [
    "lao2"
  ],
  "天文": [
    "tian1 wen2"
  ],
  "面目全非": [
    "mian4 mu4 quan2 fei1"
  ],
  "逗": [
    "dou4"
  ],
  "陋": [
    "lou4"
  ],
  "芒": [
    "mang2"
  ],
  "萌芽": [
    "meng2 ya2"
  ],
  "昃": [
    "ze4"
  ],
  "贸易": [
    "mao4 yi4"
  ],
  "优惠": [
    "you1 hui4"
  ],
  "增加": [
    "zeng1 jia1"
  ],
  "出境": [
    "chu1 jing4"
  ],
  "反过来": [
    "fan3 guo5 lai2"
  ],
  "康复": [
    "kang1 fu4"
  ],
  "宕": [
    "dang4"
  ],
  "班长": [
    "ban1 zhang3"
  ],
  "誓": [
    "shi4"
  ],
  "打算": [
    "da3 suan4"
  ],
  "遗愿": [
    "yi2 yuan4"
  ],
  "歹": [
    "dai3"
  ],
  "拙": [
    "zhuo1"
  ],
  "小提琴": [
    "xiao3 ti2 qin2"
  ],
  "蕗": [
    "lu4"
  ],
  "砂": [
    "sha1"
  ],
  "峗": [
    null
  ],
  "业余": [
    "ye4 yu2"
  ],
  "报考": [
    "bao4 kao3"
  ],
  "放心": [
    "fang4 xin1"
  ],
  "连忙": [
    "lian2 mang2"
  ],
  "丑闻": [
    "chou3 wen2"
  ],
  "追逐": [
    "zhui1 zhu2"
  ],
  "张": [
    "Zhang1",
    "zhang1"
  ],
  "检": [
    "jian3"
  ],
  "以身作则": [
    "yi3 shen1 zuo4 ze2"
  ],
  "屎": [
    "shi3"
  ],
  "礼": [
    "Li3",
    "li3"
  ],
  "哒": [
    "da1"
  ],
  "酷": [
    "ku4"
  ],
  "咺": [
    "xuan3"
  ],
  "下级": [
    "xia4 ji2"
  ],
  "阻止": [
    "zu3 zhi3"
  ],
  "过分": [
    "guo4 fen4"
  ],
  "分寸": [
    "fen1 cun5"
  ],
  "对于": [
    "dui4 yu2"
  ],
  "师傅": [
    "shi1 fu5"
  ],
  "器械": [
    "qi4 xie4"
  ],
  "溃": [
    "hui4",
    "kui4"
  ],
  "局限": [
    "ju2 xian4",
    "ju2 xian4"
  ],
  "家家户户": [
    "jia1 jia1 hu4 hu4"
  ],
  "人身": [
    "ren2 shen1"
  ],
  "交通": [
    "jiao1 tong1"
  ],
  "照片": [
    "zhao4 pian4"
  ],
  "四面八方": [
    "si4 mian4 ba1 fang1"
  ],
  "一晃": [
    "yi1 huang3"
  ],
  "阵容": [
    "zhen4 rong2"
  ],
  "见识": [
    "jian4 shi5"
  ],
  "上班": [
    "shang4 ban1"
  ],
  "轧": [
    "Ya4",
    "ga2",
    "ya4",
    "zha2"
  ],
  "厉": [
    "Li4",
    "li4"
  ],
  "高傲": [
    "gao1 ao4"
  ],
  "波及": [
    "bo1 ji2"
  ],
  "水平": [
    "shui3 ping2"
  ],
  "把手": [
    "ba3 shou3",
    "ba3 shou5"
  ],
  "围绕": [
    "wei2 rao4"
  ],
  "执": [
    "zhi2"
  ],
  "破产": [
    "po4 chan3"
  ],
  "萆": [
    "bi4"
  ],
  "珉": [
    "min2"
  ],
  "艋": [
    "meng3"
  ],
  "塄": [
    "leng2"
  ],
  "趟": [
    "tang1",
    "tang1",
    "tang4"
  ],
  "滩": [
    "tan1"
  ],
  "认证": [
    "ren4 zheng4"
  ],
  "小康": [
    "Xiao3 kang1",
    "xiao3 kang1"
  ],
  "集结": [
    "ji2 jie2"
  ],
  "媲": [
    "pi4"
  ],
  "友人": [
    "you3 ren2"
  ],
  "路途": [
    "lu4 tu2"
  ],
  "转弯": [
    "zhuan3 wan1"
  ],
  "路面": [
    "lu4 mian4"
  ],
  "不对": [
    "bu4 dui4"
  ],
  "惜": [
    "xi1"
  ],
  "塑": [
    "su4"
  ],
  "花瓣": [
    "hua1 ban4"
  ],
  "鲾": [
    "bi1"
  ],
  "徊": [
    "huai2"
  ],
  "精疲力竭": [
    "jing1 pi2 li4 jie2"
  ],
  "脾": [
    "pi2"
  ],
  "专业": [
    "zhuan1 ye4"
  ],
  "煽动": [
    "shan1 dong4"
  ],
  "许可": [
    "xu3 ke3"
  ],
  "离谱儿": [
    "li2 pu3 r5"
  ],
  "拜年": [
    "bai4 nian2"
  ],
  "尚": [
    "Shang4",
    "shang4"
  ],
  "清洁工": [
    "qing1 jie2 gong1"
  ],
  "塑料": [
    "su4 liao4"
  ],
  "承": [
    "Cheng2",
    "cheng2"
  ],
  "谜": [
    "mei4",
    "mi2"
  ],
  "才": [
    "cai2",
    "cai2"
  ],
  "景观": [
    "jing3 guan1"
  ],
  "宁可": [
    "ning4 ke3"
  ],
  "侩": [
    "kuai4"
  ],
  "综": [
    "zeng4",
    "zong1"
  ],
  "垌": [
    "dong4"
  ],
  "桶": [
    "tong3"
  ],
  "形状": [
    "xing2 zhuang4"
  ],
  "麻辣": [
    "ma2 la4"
  ],
  "逃避": [
    "tao2 bi4"
  ],
  "玱": [
    "qiang1"
  ],
  "运输": [
    "yun4 shu1"
  ],
  "卖弄": [
    "mai4 nong5"
  ],
  "烫": [
    "tang4"
  ],
  "派出": [
    "pai4 chu1"
  ],
  "琄": [
    null
  ],
  "退": [
    "tui4"
  ],
  "演示": [
    "yan3 shi4"
  ],
  "充足": [
    "chong1 zu2"
  ],
  "皙": [
    null
  ],
  "艰": [
    "jian1"
  ],
  "冷": [
    "Leng3",
    "leng3"
  ],
  "魋": [
    "tui2"
  ],
  "工作": [
    "gong1 zuo4"
  ],
  "血栓": [
    "xue4 shuan1"
  ],
  "鹏": [
    "Peng2"
  ],
  "莹": [
    "ying2"
  ],
  "各奔前程": [
    "ge4 ben4 qian2 cheng2"
  ],
  "馅": [
    "xian4"
  ],
  "炒股": [
    "chao3 gu3"
  ],
  "贮藏": [
    "zhu4 cang2"
  ],
  "懔": [
    "lin3"
  ],
  "情愿": [
    "qing2 yuan4"
  ],
  "责备": [
    "ze2 bei4"
  ],
  "树叶": [
    "shu4 ye4"
  ],
  "柁": [
    "tuo2"
  ],
  "教": [
    "Jiao4",
    "jiao1",
    "jiao4"
  ],
  "甡": [
    "shen1"
  ],
  "岚": [
    "lan2"
  ],
  "呦": [
    "you1"
  ],
  "蕹": [
    "weng4"
  ],
  "纵横交错": [
    "zong4 heng2 jiao1 cuo4"
  ],
  "畀": [
    "bi4"
  ],
  "旖": [
    "yi3"
  ],
  "相互": [
    "xiang1 hu4"
  ],
  "着想": [
    "zhuo2 xiang3"
  ],
  "撑": [
    "cheng1"
  ],
  "颠倒": [
    "dian1 dao3"
  ],
  "围巾": [
    "wei2 jin1"
  ],
  "美女": [
    "mei3 nu:3"
  ],
  "督促": [
    "du1 cu4"
  ],
  "泯": [
    "min3",
    "min3"
  ],
  "多半": [
    "duo1 ban4"
  ],
  "吣": [
    "qin4"
  ],
  "愉": [
    "yu2"
  ],
  "喜出望外": [
    "xi3 chu1 wang4 wai4"
  ],
  "憩": [
    "qi4",
    "qi4"
  ],
  "堰": [
    "yan4"
  ],
  "温习": [
    "wen1 xi2"
  ],
  "芩": [
    "qin2"
  ],
  "计算机": [
    "ji4 suan4 ji1"
  ],
  "挨着": [
    "ai1 zhe5"
  ],
  "痘": [
    "dou4"
  ],
  "啡": [
    "fei1"
  ],
  "缣": [
    "jian1"
  ],
  "生日": [
    "sheng1 ri4"
  ],
  "出发": [
    "chu1 fa1"
  ],
  "尔": [
    "er3",
    "er3"
  ],
  "住宅": [
    "zhu4 zhai2"
  ],
  "霪": [
    "yin2"
  ],
  "骨头": [
    "gu3 tou5"
  ],
  "橘子": [
    "ju2 zi5"
  ],
  "去世": [
    "qu4 shi4"
  ],
  "请教": [
    "qing3 jiao4"
  ],
  "投": [
    "tou2"
  ],
  "邯": [
    "han2"
  ],
  "不相上下": [
    "bu4 xiang1 shang4 xia4"
  ],
  "迄今为止": [
    "qi4 jin1 wei2 zhi3"
  ],
  "偈": [
    "ji4",
    "jie2"
  ],
  "迷": [
    "mi2"
  ],
  "城市": [
    "cheng2 shi4"
  ],
  "棨": [
    "qi3"
  ],
  "纮": [
    "hong2"
  ],
  "凡是": [
    "fan2 shi4"
  ],
  "靽": [
    null
  ],
  "椭": [
    "tuo3"
  ],
  "加快": [
    "jia1 kuai4"
  ],
  "副作用": [
    "fu4 zuo4 yong4"
  ],
  "啮": [
    "nie4",
    "nie4"
  ],
  "朳": [
    "ba1"
  ],
  "佃": [
    "dian4",
    "tian2"
  ],
  "煤炭": [
    "mei2 tan4"
  ],
  "鲈": [
    "lu2"
  ],
  "矢": [
    "shi3"
  ],
  "凸": [
    "tu1"
  ],
  "确立": [
    "que4 li4"
  ],
  "砷": [
    "shen1"
  ],
  "千克": [
    "qian1 ke4"
  ],
  "昈": [
    null
  ],
  "攸": [
    "you1"
  ],
  "斩草除根": [
    "zhan3 cao3 chu2 gen1"
  ],
  "姨": [
    "yi2"
  ],
  "邝": [
    "Kuang4"
  ],
  "不通": [
    "bu4 tong1"
  ],
  "代理人": [
    "dai4 li3 ren2"
  ],
  "崌": [
    "ju1"
  ],
  "刺绣": [
    "ci4 xiu4"
  ],
  "碇": [
    "ding4",
    "ding4",
    "ding4"
  ],
  "加": [
    "Jia1",
    "jia1"
  ],
  "寅": [
    "yin2"
  ],
  "电灯": [
    "dian4 deng1"
  ],
  "货运": [
    "huo4 yun4"
  ],
  "生死": [
    "sheng1 si3"
  ],
  "辒": [
    "wen1"
  ],
  "供应": [
    "gong1 ying4"
  ],
  "星期天": [
    "Xing1 qi1 tian1"
  ],
  "体育": [
    "ti3 yu4"
  ],
  "配": [
    "pei4"
  ],
  "钱包": [
    "qian2 bao1"
  ],
  "登机": [
    "deng1 ji1"
  ],
  "幸免": [
    "xing4 mian3"
  ],
  "优": [
    "you1"
  ],
  "蟆": [
    "ma2",
    "ma2"
  ],
  "破案": [
    "po4 an4"
  ],
  "传授": [
    "chuan2 shou4"
  ],
  "窊": [
    "wa1"
  ],
  "犋": [
    "ju4"
  ],
  "癔": [
    "yi4"
  ],
  "清": [
    "Qing1",
    "qing1"
  ],
  "伞": [
    "san3",
    "san3"
  ],
  "民办": [
    "min2 ban4"
  ],
  "洋": [
    "yang2"
  ],
  "不管": [
    "bu4 guan3"
  ],
  "局": [
    "ju2",
    "ju2",
    "ju2"
  ],
  "有意思": [
    "you3 yi4 si5"
  ],
  "形成": [
    "xing2 cheng2"
  ],
  "下课": [
    "xia4 ke4"
  ],
  "太阳能": [
    "tai4 yang2 neng2"
  ],
  "亚运会": [
    "Ya4 yun4 hui4"
  ],
  "骢": [
    "cong1"
  ],
  "休养": [
    "xiu1 yang3"
  ],
  "豁出去": [
    "huo1 chu5 qu5"
  ],
  "搠": [
    "shuo4"
  ],
  "面包": [
    "mian4 bao1"
  ],
  "出国": [
    "chu1 guo2"
  ],
  "炣": [
    "ke3"
  ],
  "日后": [
    "ri4 hou4"
  ],
  "蒽": [
    "en1"
  ],
  "烦躁": [
    "fan2 zao4"
  ],
  "赐教": [
    "ci4 jiao4"
  ],
  "摧毁": [
    "cui1 hui3"
  ],
  "遁": [
    "dun4",
    "dun4"
  ],
  "逖": [
    "ti4"
  ],
  "蚩": [
    "Chi1",
    "chi1"
  ],
  "郃": [
    "he2"
  ],
  "讹": [
    "e2",
    "e2"
  ],
  "尧": [
    "Yao2"
  ],
  "泫": [
    "xuan4"
  ],
  "问卷": [
    "wen4 juan4"
  ],
  "亲眼": [
    "qin1 yan3"
  ],
  "湎": [
    "mian3"
  ],
  "人工": [
    "ren2 gong1"
  ],
  "急性": [
    "ji2 xing4"
  ],
  "鋈": [
    "wu4"
  ],
  "玥": [
    "yue4"
  ],
  "排列": [
    "pai2 lie4"
  ],
  "嘘": [
    "xu1"
  ],
  "比喻": [
    "bi3 yu4"
  ],
  "步伐": [
    "bu4 fa2"
  ],
  "丰富": [
    "feng1 fu4"
  ],
  "涉嫌": [
    "she4 xian2"
  ],
  "她们": [
    "ta1 men5"
  ],
  "直觉": [
    "zhi2 jue2"
  ],
  "整治": [
    "zheng3 zhi4"
  ],
  "鼓动": [
    "gu3 dong4"
  ],
  "同期": [
    "tong2 qi1"
  ],
  "绀": [
    "gan4"
  ],
  "舍不得": [
    "she3 bu5 de5"
  ],
  "楼梯": [
    "lou2 ti1"
  ],
  "蜘": [
    "zhi1"
  ],
  "平淡": [
    "ping2 dan4"
  ],
  "账": [
    "zhang4"
  ],
  "赢": [
    "ying2"
  ],
  "原告": [
    "yuan2 gao4"
  ],
  "烧": [
    "shao1"
  ],
  "和平": [
    "He2 ping2",
    "he2 ping2"
  ],
  "长处": [
    "chang2 chu4"
  ],
  "花": [
    "Hua1",
    "hua1",
    "hua1",
    "hua1"
  ],
  "贵宾": [
    "gui4 bin1"
  ],
  "从前": [
    "cong2 qian2"
  ],
  "甜头": [
    "tian2 tou5"
  ],
  "蓓": [
    "bei4"
  ],
  "败": [
    "bai4"
  ],
  "幸亏": [
    "xing4 kui1"
  ],
  "头晕": [
    "tou2 yun1"
  ],
  "五花八门": [
    "wu3 hua1 ba1 men2"
  ],
  "厦": [
    "Xia4",
    "sha4"
  ],
  "不大": [
    "bu4 da4"
  ],
  "货币": [
    "huo4 bi4"
  ],
  "撸": [
    "lu1"
  ],
  "回信": [
    "hui2 xin4"
  ],
  "职员": [
    "zhi2 yuan2"
  ],
  "勇往直前": [
    "yong3 wang3 zhi2 qian2"
  ],
  "过度": [
    "guo4 du4"
  ],
  "段落": [
    "duan4 luo4"
  ],
  "降低": [
    "jiang4 di1"
  ],
  "场地": [
    "chang3 di4"
  ],
  "追悼会": [
    "zhui1 dao4 hui4"
  ],
  "拧": [
    "ning2",
    "ning3",
    "ning4"
  ],
  "娴": [
    "xian2",
    "xian2"
  ],
  "廴": [
    "yin3"
  ],
  "建筑师": [
    "jian4 zhu4 shi1"
  ],
  "研究生": [
    "yan2 jiu1 sheng1"
  ],
  "攻": [
    "gong1"
  ],
  "制作": [
    "zhi4 zuo4"
  ],
  "较劲": [
    "jiao4 jin4"
  ],
  "㶲": [
    null
  ],
  "铛": [
    "cheng1",
    "dang1"
  ],
  "篷": [
    "peng2"
  ],
  "表现": [
    "biao3 xian4"
  ],
  "锻": [
    "duan4"
  ],
  "跃": [
    "yue4"
  ],
  "牵涉": [
    "qian1 she4"
  ],
  "豁达": [
    "huo4 da2"
  ],
  "原地": [
    "yuan2 di4"
  ],
  "滆": [
    null
  ],
  "离职": [
    "li2 zhi2"
  ],
  "事情": [
    "shi4 qing5"
  ],
  "蒌": [
    "lou2"
  ],
  "聘用": [
    "pin4 yong4"
  ],
  "穟": [
    "sui4"
  ],
  "辉煌": [
    "hui1 huang2"
  ],
  "未免": [
    "wei4 mian3"
  ],
  "高潮": [
    "gao1 chao2"
  ],
  "絮": [
    "xu4"
  ],
  "芝麻": [
    "zhi1 ma5"
  ],
  "进步": [
    "jin4 bu4"
  ],
  "辣": [
    "la4",
    "la4"
  ],
  "不正之风": [
    "bu4 zheng4 zhi1 feng1"
  ],
  "辍": [
    "chuo4"
  ],
  "同行": [
    "tong2 hang2",
    "tong2 xing2"
  ],
  "发票": [
    "fa1 piao4"
  ],
  "阳光": [
    "yang2 guang1"
  ],
  "制止": [
    "zhi4 zhi3"
  ],
  "忉": [
    "dao1"
  ],
  "糜": [
    "Mi2",
    "mei2",
    "mi2"
  ],
  "谠": [
    "dang3"
  ],
  "沐": [
    "mu4"
  ],
  "爆满": [
    "bao4 man3"
  ],
  "放假": [
    "fang4 jia4"
  ],
  "致敬": [
    "zhi4 jing4"
  ],
  "镔": [
    "bin1"
  ],
  "正当": [
    "zheng4 dang1",
    "zheng4 dang4"
  ],
  "耔": [
    "zi3"
  ],
  "莜": [
    "you2"
  ],
  "轰动": [
    "hong1 dong4"
  ],
  "征": [
    "zheng1",
    "zheng1"
  ],
  "赛": [
    "sai4"
  ],
  "寮": [
    "Liao2",
    "liao2"
  ],
  "喜好": [
    "xi3 hao4"
  ],
  "猥": [
    "wei3"
  ],
  "话筒": [
    "hua4 tong3"
  ],
  "鬯": [
    "chang4"
  ],
  "迪": [
    "di2"
  ],
  "和解": [
    "he2 jie3"
  ],
  "璺": [
    "wen4"
  ],
  "好说": [
    "hao3 shuo1"
  ],
  "抵制": [
    "di3 zhi4"
  ],
  "荑": [
    "ti2",
    "yi2"
  ],
  "商场": [
    "shang1 chang3"
  ],
  "瞍": [
    "sou3"
  ],
  "丫": [
    "ya1"
  ],
  "薇": [
    "wei1"
  ],
  "保管": [
    "bao3 guan3"
  ],
  "鳄": [
    "e4",
    "e4"
  ],
  "顺着": [
    "shun4 zhe5"
  ],
  "容易": [
    "rong2 yi4"
  ],
  "翠绿": [
    "cui4 lu:4"
  ],
  "获胜": [
    "huo4 sheng4"
  ],
  "怔": [
    "zheng1",
    "zheng4"
  ],
  "涐": [
    "E2"
  ],
  "僵": [
    "jiang1",
    "jiang1"
  ],
  "阶": [
    "jie1",
    "jie1"
  ],
  "鞠": [
    "Ju1",
    "ju1"
  ],
  "砖": [
    "zhuan1",
    "zhuan1",
    "zhuan1"
  ],
  "垄": [
    "long3"
  ],
  "嫌疑": [
    "xian2 yi2"
  ],
  "媞": [
    "ti2"
  ],
  "馒头": [
    "man2 tou5"
  ],
  "案": [
    "an4"
  ],
  "独自": [
    "du2 zi4"
  ],
  "意料之外": [
    "yi4 liao4 zhi1 wai4"
  ],
  "面红耳赤": [
    "mian4 hong2 er3 chi4"
  ],
  "意外": [
    "yi4 wai4"
  ],
  "嬥": [
    null
  ],
  "编写": [
    "bian1 xie3"
  ],
  "划算": [
    "hua2 suan4"
  ],
  "据说": [
    "ju4 shuo1"
  ],
  "禾苗": [
    "he2 miao2"
  ],
  "诱人": [
    "you4 ren2"
  ],
  "镢": [
    "jue2"
  ],
  "儿": [
    "ren2",
    "er2",
    "r5"
  ],
  "侦察": [
    "zhen1 cha2"
  ],
  "萍水相逢": [
    "ping2 shui3 xiang1 feng2"
  ],
  "包围": [
    "bao1 wei2"
  ],
  "砉": [
    "hua1",
    "xu1"
  ],
  "足智多谋": [
    "zu2 zhi4 duo1 mou2"
  ],
  "一把手": [
    "yi1 ba3 shou3"
  ],
  "祾": [
    null
  ],
  "衍": [
    "yan3"
  ],
  "除此之外": [
    "chu2 ci3 zhi1 wai4"
  ],
  "轱": [
    "gu1"
  ],
  "碥": [
    "bian3"
  ],
  "玿": [
    "shao2"
  ],
  "打破": [
    "da3 po4"
  ],
  "告诉": [
    "gao4 su4",
    "gao4 su5"
  ],
  "宗旨": [
    "zong1 zhi3"
  ],
  "故意": [
    "gu4 yi4"
  ],
  "焕发": [
    "huan4 fa1"
  ],
  "瑧": [
    "zhen1"
  ],
  "显": [
    "xian3"
  ],
  "使得": [
    "shi3 de5"
  ],
  "琭": [
    "lu4"
  ],
  "拄": [
    "zhu3"
  ],
  "崴": [
    "wai3",
    "wei1"
  ],
  "树枝": [
    "shu4 zhi1"
  ],
  "诀别": [
    "jue2 bie2"
  ],
  "凉鞋": [
    "liang2 xie2"
  ],
  "䗖": [
    "di4"
  ],
  "扔": [
    "reng1"
  ],
  "即便": [
    "ji2 bian4"
  ],
  "仙": [
    "xian1",
    "xian1"
  ],
  "巡": [
    "xun2",
    "xun2"
  ],
  "抗衡": [
    "kang4 heng2"
  ],
  "扭头": [
    "niu3 tou2"
  ],
  "呃": [
    "e4"
  ],
  "欢乐": [
    "huan1 le4"
  ],
  "动静": [
    "dong4 jing4"
  ],
  "寺": [
    "si4"
  ],
  "堋": [
    "peng2"
  ],
  "急诊": [
    "ji2 zhen3"
  ],
  "高价": [
    "gao1 jia4"
  ],
  "地下": [
    "di4 xia4"
  ],
  "经商": [
    "jing1 shang1"
  ],
  "形象": [
    "xing2 xiang4"
  ],
  "确诊": [
    "que4 zhen3"
  ],
  "砺": [
    "li4"
  ],
  "窦": [
    "Dou4",
    "dou4"
  ],
  "超过": [
    "chao1 guo4"
  ],
  "闭": [
    "bi4"
  ],
  "乞": [
    "qi3"
  ],
  "自私": [
    "zi4 si1"
  ],
  "萤": [
    "ying2"
  ],
  "似曾相识": [
    "si4 ceng2 xiang1 shi2"
  ],
  "忽视": [
    "hu1 shi4"
  ],
  "作废": [
    "zuo4 fei4"
  ],
  "碚": [
    "bei4"
  ],
  "枯": [
    "ku1"
  ],
  "挲": [
    "suo1",
    "suo1"
  ],
  "看样子": [
    "kan4 yang4 zi5"
  ],
  "仰": [
    "Yang3",
    "yang3"
  ],
  "强占": [
    "qiang2 zhan4"
  ],
  "隔壁": [
    "ge2 bi4"
  ],
  "一生": [
    "yi1 sheng1"
  ],
  "圜": [
    "huan2",
    "yuan2"
  ],
  "铥": [
    "diu1"
  ],
  "展开": [
    "zhan3 kai1"
  ],
  "琡": [
    null
  ],
  "勘": [
    "kan1"
  ],
  "凄": [
    "qi1",
    "qi1",
    "qi1"
  ],
  "塾": [
    "shu2"
  ],
  "炎热": [
    "yan2 re4"
  ],
  "逾": [
    "yu2",
    "yu2"
  ],
  "接替": [
    "jie1 ti4"
  ],
  "不为人知": [
    "bu4 wei2 ren2 zhi1"
  ],
  "厌烦": [
    "yan4 fan2"
  ],
  "培养": [
    "pei2 yang3"
  ],
  "印": [
    "Yin4",
    "yin4"
  ],
  "鲧": [
    "Gun3",
    "Gun3"
  ],
  "这边": [
    "zhe4 bian1"
  ],
  "祆": [
    "Xian1"
  ],
  "峋": [
    "xun2"
  ],
  "钆": [
    "ga2"
  ],
  "互相": [
    "hu4 xiang1"
  ],
  "背叛": [
    "bei4 pan4"
  ],
  "对付": [
    "dui4 fu5"
  ],
  "雒": [
    "luo4"
  ],
  "作为": [
    "zuo4 wei2"
  ],
  "妈": [
    "ma1"
  ],
  "写": [
    "xie3"
  ],
  "躯": [
    "qu1"
  ],
  "缺席": [
    "que1 xi2"
  ],
  "谒": [
    "ye4"
  ],
  "槲": [
    "hu2"
  ],
  "镧": [
    "lan2"
  ],
  "夺取": [
    "duo2 qu3"
  ],
  "遗": [
    "yi2"
  ],
  "建立": [
    "jian4 li4"
  ],
  "沃": [
    "wo4"
  ],
  "随时": [
    "sui2 shi2"
  ],
  "打交道": [
    "da3 jiao1 dao4"
  ],
  "好奇心": [
    "hao4 qi2 xin1"
  ],
  "蘘": [
    "rang2"
  ],
  "猬": [
    "wei4"
  ],
  "宝宝": [
    "bao3 bao5"
  ],
  "燚": [
    "yi4"
  ],
  "久仰": [
    "jiu3 yang3"
  ],
  "晓": [
    "xiao3"
  ],
  "诉讼": [
    "su4 song4"
  ],
  "舥": [
    null
  ],
  "忺": [
    "xian1"
  ],
  "鄗": [
    "hao4"
  ],
  "前任": [
    "qian2 ren4"
  ],
  "评审": [
    "ping2 shen3"
  ],
  "封建": [
    "feng1 jian4"
  ],
  "抗拒": [
    "kang4 ju4"
  ],
  "街道": [
    "jie1 dao4"
  ],
  "难免": [
    "nan2 mian3"
  ],
  "热闹": [
    "re4 nao5"
  ],
  "耳光": [
    "er3 guang1"
  ],
  "唛": [
    "mai4"
  ],
  "谦虚": [
    "qian1 xu1"
  ],
  "芳": [
    "fang1"
  ],
  "谜团": [
    "mi2 tuan2"
  ],
  "揭示": [
    "jie1 shi4"
  ],
  "瘾": [
    "yin3"
  ],
  "啤": [
    "pi2"
  ],
  "翩": [
    "pian1"
  ],
  "妍": [
    "yan2"
  ],
  "贯彻": [
    "guan4 che4"
  ],
  "命运": [
    "ming4 yun4"
  ],
  "产物": [
    "chan3 wu4"
  ],
  "锸": [
    "cha1"
  ],
  "消费者": [
    "xiao1 fei4 zhe3"
  ],
  "潼": [
    "tong2"
  ],
  "心灵手巧": [
    "xin1 ling2 shou3 qiao3"
  ],
  "长途": [
    "chang2 tu2"
  ],
  "痼": [
    "gu4"
  ],
  "捩": [
    "lie4"
  ],
  "那样": [
    "na4 yang4"
  ],
  "椪": [
    "peng4"
  ],
  "桐": [
    "tong2"
  ],
  "取得": [
    "qu3 de2"
  ],
  "倏": [
    "shu1",
    "shu1",
    "shu1"
  ],
  "猺": [
    "yao2"
  ],
  "渟": [
    "ting2"
  ],
  "澧": [
    "Li3"
  ],
  "机会": [
    "ji1 hui4"
  ],
  "爬": [
    "pa2"
  ],
  "预告": [
    "yu4 gao4"
  ],
  "朕": [
    "zhen4"
  ],
  "寂静": [
    "ji4 jing4"
  ],
  "停下": [
    "ting2 xia4"
  ],
  "恪": [
    "Ke4",
    "ke4",
    "ke4"
  ],
  "三番五次": [
    "san1 fan1 wu3 ci4"
  ],
  "吉普": [
    "Ji2 pu3"
  ],
  "卺": [
    "jin3"
  ],
  "应付": [
    "ying4 fu5"
  ],
  "缩短": [
    "suo1 duan3"
  ],
  "忭": [
    "bian4"
  ],
  "制约": [
    "zhi4 yue1"
  ],
  "公然": [
    "gong1 ran2"
  ],
  "致命": [
    "zhi4 ming4"
  ],
  "蛮": [
    "man2"
  ],
  "凶手": [
    "xiong1 shou3"
  ],
  "贺信": [
    "he4 xin4"
  ],
  "观": [
    "Guan4",
    "guan1",
    "guan4"
  ],
  "葬": [
    "zang4",
    "zang4"
  ],
  "多种": [
    "duo1 zhong3"
  ],
  "本身": [
    "ben3 shen1"
  ],
  "责": [
    "ze2"
  ],
  "觟": [
    null
  ],
  "吸纳": [
    "xi1 na4"
  ],
  "徇": [
    "xun4",
    "xun4"
  ],
  "下令": [
    "xia4 ling4"
  ],
  "泞": [
    "ning4"
  ],
  "浏览器": [
    "liu2 lan3 qi4"
  ],
  "一帆风顺": [
    "yi1 fan1 feng1 shun4"
  ],
  "蜷": [
    "quan2"
  ],
  "追求": [
    "zhui1 qiu2"
  ],
  "分配": [
    "fen1 pei4"
  ],
  "剿": [
    "chao1",
    "jiao3",
    "chao1",
    "jiao3"
  ],
  "损害": [
    "sun3 hai4"
  ],
  "嫪": [
    "Lao4",
    "lao4"
  ],
  "塅": [
    "duan4"
  ],
  "选拔": [
    "xuan3 ba2"
  ],
  "点燃": [
    "dian3 ran2"
  ],
  "制成": [
    "zhi4 cheng2"
  ],
  "突破口": [
    "tu1 po4 kou3"
  ],
  "掌握": [
    "zhang3 wo4"
  ],
  "昒": [
    "hu1"
  ],
  "上火": [
    "shang4 huo3"
  ],
  "放映": [
    "fang4 ying4"
  ],
  "棒": [
    "bang4"
  ],
  "傻瓜": [
    "sha3 gua1"
  ],
  "振兴": [
    "Zhen4 xing1",
    "zhen4 xing1"
  ],
  "欠": [
    "qian4"
  ],
  "漯": [
    "ta4"
  ],
  "恭": [
    "gong1"
  ],
  "众多": [
    "zhong4 duo1"
  ],
  "陷阱": [
    "xian4 jing3"
  ],
  "镫": [
    "deng4"
  ],
  "高中": [
    "gao1 zhong1",
    "gao1 zhong4"
  ],
  "尖": [
    "jian1"
  ],
  "珞": [
    "luo4"
  ],
  "沉甸甸": [
    "chen2 dian4 dian4"
  ],
  "辘": [
    "lu4"
  ],
  "伺": [
    "ci4",
    "si4"
  ],
  "哭": [
    "ku1"
  ],
  "导弹": [
    "dao3 dan4"
  ],
  "粉碎": [
    "fen3 sui4"
  ],
  "苦练": [
    "ku3 lian4"
  ],
  "靠近": [
    "kao4 jin4"
  ],
  "漤": [
    "lan3"
  ],
  "聋": [
    "long2"
  ],
  "阻": [
    "zu3"
  ],
  "鸰": [
    "ling2"
  ],
  "本子": [
    "ben3 zi5"
  ],
  "货车": [
    "huo4 che1"
  ],
  "致": [
    "zhi4",
    "zhi4"
  ],
  "齿": [
    "chi3"
  ],
  "技巧": [
    "ji4 qiao3"
  ],
  "唔": [
    "wu2"
  ],
  "玡": [
    "ya2"
  ],
  "恳": [
    "ken3"
  ],
  "听话": [
    "ting1 hua4"
  ],
  "牵": [
    "qian1"
  ],
  "霭": [
    "ai3"
  ],
  "电池": [
    "dian4 chi2"
  ],
  "烬": [
    "jin4"
  ],
  "互联网": [
    "Hu4 lian2 wang3"
  ],
  "担子": [
    "dan4 zi5"
  ],
  "猜测": [
    "cai1 ce4"
  ],
  "眨眼": [
    "zha3 yan3"
  ],
  "食物": [
    "shi2 wu4"
  ],
  "运转": [
    "yun4 zhuan3"
  ],
  "人道": [
    "ren2 dao4"
  ],
  "皓": [
    "hao4",
    "hao4",
    "hao4"
  ],
  "身不由己": [
    "shen1 bu4 you2 ji3"
  ],
  "泰": [
    "Tai4",
    "tai4"
  ],
  "均": [
    "jun1"
  ],
  "鞣": [
    "rou2"
  ],
  "峘": [
    null
  ],
  "推辞": [
    "tui1 ci2"
  ],
  "麀": [
    "you1"
  ],
  "矛头": [
    "mao2 tou2"
  ],
  "合": [
    "ge3",
    "he2",
    "he2"
  ],
  "酿造": [
    "niang4 zao4"
  ],
  "牝": [
    "pin4"
  ],
  "打断": [
    "da3 duan4"
  ],
  "推广": [
    "tui1 guang3"
  ],
  "镰": [
    "lian2",
    "lian2",
    "lian2"
  ],
  "濉": [
    "sui1"
  ],
  "先后": [
    "xian1 hou4"
  ],
  "调侃": [
    "tiao2 kan3"
  ],
  "沧桑": [
    "cang1 sang1"
  ],
  "深化": [
    "shen1 hua4"
  ],
  "杀": [
    "sha1"
  ],
  "搜救": [
    "sou1 jiu4"
  ],
  "活该": [
    "huo2 gai1"
  ],
  "不行": [
    "bu4 xing2"
  ],
  "徘徊": [
    "pai2 huai2"
  ],
  "黄昏": [
    "huang2 hun1"
  ],
  "装置": [
    "zhuang1 zhi4"
  ],
  "源于": [
    "yuan2 yu2"
  ],
  "魔": [
    "mo2"
  ],
  "乓": [
    "pang1"
  ],
  "媒": [
    "mei2"
  ],
  "珩": [
    "heng2"
  ],
  "不惜": [
    "bu4 xi1"
  ],
  "痕": [
    "hen2"
  ],
  "排球": [
    "pai2 qiu2"
  ],
  "䃎": [
    "zha4"
  ],
  "凘": [
    null
  ],
  "眼下": [
    "yan3 xia4"
  ],
  "得知": [
    "de2 zhi1"
  ],
  "欹": [
    "yi1"
  ],
  "细心": [
    "xi4 xin1"
  ],
  "峁": [
    "mao3"
  ],
  "黑心": [
    "hei1 xin1"
  ],
  "矮小": [
    "ai3 xiao3"
  ],
  "生动": [
    "sheng1 dong4"
  ],
  "偶然": [
    "ou3 ran2"
  ],
  "圌": [
    "chui2"
  ],
  "规矩": [
    "gui1 ju5"
  ],
  "阱": [
    "jing3",
    "jing3"
  ],
  "匚": [
    "fang1"
  ],
  "与日俱增": [
    "yu3 ri4 ju4 zeng1"
  ],
  "鳃": [
    "sai1"
  ],
  "鲴": [
    "gu4"
  ],
  "唆": [
    "suo1"
  ],
  "总量": [
    "zong3 liang4"
  ],
  "教科书": [
    "jiao4 ke1 shu1"
  ],
  "栖": [
    "qi1"
  ],
  "记": [
    "ji4"
  ],
  "唧": [
    "ji1"
  ],
  "长久": [
    "chang2 jiu3"
  ],
  "可": [
    "ke3",
    "ke4"
  ],
  "尺寸": [
    "chi3 cun5"
  ],
  "碘": [
    "dian3"
  ],
  "用力": [
    "yong4 li4"
  ],
  "节日": [
    "jie2 ri4"
  ],
  "橡皮": [
    "xiang4 pi2"
  ],
  "打折": [
    "da3 zhe2"
  ],
  "办": [
    "ban4"
  ],
  "鲨鱼": [
    "sha1 yu2"
  ],
  "说干就干": [
    "shuo1 gan4 jiu4 gan4"
  ],
  "毕业生": [
    "bi4 ye4 sheng1"
  ],
  "匦": [
    "gui3"
  ],
  "看见": [
    "kan4 jian4"
  ],
  "脘": [
    "wan3"
  ],
  "父女": [
    "fu4 nu:3"
  ],
  "预见": [
    "yu4 jian4"
  ],
  "樟": [
    "zhang1"
  ],
  "豢": [
    "huan4"
  ],
  "擅长": [
    "shan4 chang2"
  ],
  "矬": [
    "cuo2"
  ],
  "碍事": [
    "ai4 shi4"
  ],
  "无关": [
    "wu2 guan1"
  ],
  "哞": [
    "mou1"
  ],
  "旗": [
    "qi2",
    "qi2"
  ],
  "大街小巷": [
    "da4 jie1 xiao3 xiang4"
  ],
  "提交": [
    "ti2 jiao1"
  ],
  "遽": [
    "ju4"
  ],
  "嘶": [
    "si1"
  ],
  "仳": [
    "pi3"
  ],
  "樾": [
    "yue4"
  ],
  "圉": [
    "yu3"
  ],
  "坳": [
    "ao4",
    "ao4"
  ],
  "唑": [
    "zuo4"
  ],
  "嫉": [
    "ji2"
  ],
  "帼": [
    "guo2"
  ],
  "栎": [
    "li4"
  ],
  "口味": [
    "kou3 wei4"
  ],
  "拊": [
    "fu3"
  ],
  "斝": [
    "jia3"
  ],
  "水泥": [
    "shui3 ni2"
  ],
  "幻觉": [
    "huan4 jue2"
  ],
  "颀": [
    "qi2"
  ],
  "锊": [
    "lu:e4"
  ],
  "典": [
    "dian3"
  ],
  "私房钱": [
    "si1 fang2 qian2"
  ],
  "生活费": [
    "sheng1 huo2 fei4"
  ],
  "茽": [
    "zhong4"
  ],
  "茏": [
    "long2"
  ],
  "文人": [
    "wen2 ren2"
  ],
  "暂时": [
    "zan4 shi2"
  ],
  "建树": [
    "jian4 shu4"
  ],
  "原来": [
    "yuan2 lai2"
  ],
  "唤": [
    "huan4"
  ],
  "彧": [
    "yu4"
  ],
  "照办": [
    "zhao4 ban4"
  ],
  "趔": [
    "lie4"
  ],
  "优异": [
    "you1 yi4"
  ],
  "报纸": [
    "bao4 zhi3"
  ],
  "无奈": [
    "wu2 nai4"
  ],
  "萃": [
    "cui4"
  ],
  "回避": [
    "hui2 bi4",
    "hui2 bi4"
  ],
  "讶": [
    "ya4"
  ],
  "鲦": [
    "tiao2"
  ],
  "羝": [
    "di1"
  ],
  "暴露": [
    "bao4 lu4"
  ],
  "听到": [
    "ting1 dao4"
  ],
  "齁": [
    "hou1"
  ],
  "锉": [
    "cuo4",
    "cuo4"
  ],
  "老头儿": [
    "lao3 tou2 r5"
  ],
  "黎明": [
    "li2 ming2"
  ],
  "玷": [
    "dian4"
  ],
  "来源于": [
    "lai2 yuan2 yu2"
  ],
  "线条": [
    "xian4 tiao2"
  ],
  "呼吸": [
    "hu1 xi1"
  ],
  "您": [
    "nin2"
  ],
  "挦": [
    "xian2"
  ],
  "诊": [
    "zhen3"
  ],
  "实话实说": [
    "shi2 hua4 shi2 shuo1"
  ],
  "萱": [
    "xuan1",
    "xuan1",
    "xuan1",
    "xuan1",
    "xuan1"
  ],
  "说不上": [
    "shuo1 bu5 shang4"
  ],
  "岣": [
    "gou3"
  ],
  "中旬": [
    "zhong1 xun2"
  ],
  "成群结队": [
    "cheng2 qun2 jie2 dui4"
  ],
  "莅": [
    "Li4",
    "li4",
    "li4"
  ],
  "坚决": [
    "jian1 jue2"
  ],
  "遂": [
    "sui2",
    "sui4"
  ],
  "坦率": [
    "tan3 shuai4"
  ],
  "满": [
    "Man3",
    "man3"
  ],
  "圳": [
    "zhen4"
  ],
  "察看": [
    "cha2 kan4"
  ],
  "成型": [
    "cheng2 xing2"
  ],
  "宣誓": [
    "xuan1 shi4"
  ],
  "安心": [
    "an1 xin1"
  ],
  "驾驶": [
    "jia4 shi3"
  ],
  "灰心": [
    "hui1 xin1"
  ],
  "下意识": [
    "xia4 yi4 shi2"
  ],
  "辗": [
    "zhan3"
  ],
  "品牌": [
    "pin3 pai2"
  ],
  "谨慎": [
    "jin3 shen4"
  ],
  "联网": [
    "lian2 wang3"
  ],
  "炒作": [
    "chao3 zuo4"
  ],
  "当众": [
    "dang1 zhong4"
  ],
  "鲍": [
    "Bao4",
    "bao4"
  ],
  "郏": [
    "jia2"
  ],
  "憭": [
    "liao3"
  ],
  "帷": [
    "wei2"
  ],
  "缊": [
    "yun1",
    "yun4"
  ],
  "步入": [
    "bu4 ru4"
  ],
  "准确": [
    "zhun3 que4"
  ],
  "雇员": [
    "gu4 yuan2"
  ],
  "砰": [
    "peng1"
  ],
  "从头": [
    "cong2 tou2"
  ],
  "弘扬": [
    "hong2 yang2"
  ],
  "实话": [
    "shi2 hua4"
  ],
  "梳子": [
    "shu1 zi5"
  ],
  "赴": [
    "fu4"
  ],
  "出席": [
    "chu1 xi2"
  ],
  "车主": [
    "che1 zhu3"
  ],
  "世界级": [
    "shi4 jie4 ji2"
  ],
  "晫": [
    "zhuo2"
  ],
  "泂": [
    "jiong3"
  ],
  "鹰": [
    "ying1"
  ],
  "晚年": [
    "wan3 nian2"
  ],
  "高考": [
    "gao1 kao3"
  ],
  "莪": [
    "e2"
  ],
  "工业": [
    "gong1 ye4"
  ],
  "狯": [
    "kuai4"
  ],
  "用功": [
    "yong4 gong1"
  ],
  "诲": [
    "hui4"
  ],
  "订购": [
    "ding4 gou4"
  ],
  "教养": [
    "jiao4 yang3"
  ],
  "烧毁": [
    "shao1 hui3"
  ],
  "褥": [
    "ru4"
  ],
  "点": [
    "dian3"
  ],
  "婚礼": [
    "hun1 li3"
  ],
  "水晶": [
    "shui3 jing1"
  ],
  "捌": [
    "ba1"
  ],
  "艟": [
    "chong1"
  ],
  "微型": [
    "wei1 xing2"
  ],
  "女士": [
    "nu:3 shi4"
  ],
  "作弊": [
    "zuo4 bi4"
  ],
  "一塌糊涂": [
    "yi1 ta1 hu2 tu5"
  ],
  "何处": [
    "he2 chu4"
  ],
  "戗": [
    "qiang1"
  ],
  "迈进": [
    "mai4 jin4"
  ],
  "碰到": [
    "peng4 dao4"
  ],
  "椽": [
    "chuan2"
  ],
  "写作": [
    "xie3 zuo4"
  ],
  "迷恋": [
    "mi2 lian4"
  ],
  "括弧": [
    "kuo4 hu2"
  ],
  "寥寥无几": [
    "liao2 liao2 wu2 ji3"
  ],
  "青蛙": [
    "qing1 wa1"
  ],
  "届": [
    "jie4"
  ],
  "常规": [
    "chang2 gui1"
  ],
  "大巴": [
    "da4 ba1"
  ],
  "鄫": [
    "Zeng1"
  ],
  "游览": [
    "you2 lan3"
  ],
  "盐": [
    "yan2"
  ],
  "莘": [
    "Shen1",
    "shen1",
    "xin1"
  ],
  "鸥": [
    "ou1"
  ],
  "夕": [
    "xi1"
  ],
  "创立": [
    "chuang4 li4"
  ],
  "状": [
    "zhuang4"
  ],
  "流水": [
    "liu2 shui3"
  ],
  "临床": [
    "lin2 chuang2"
  ],
  "侣": [
    "lu:3"
  ],
  "胂": [
    "shen4"
  ],
  "梴": [
    "chan1"
  ],
  "介入": [
    "jie4 ru4"
  ],
  "显眼": [
    "xian3 yan3"
  ],
  "权利": [
    "quan2 li4"
  ],
  "谂": [
    "shen3"
  ],
  "杠铃": [
    "gang4 ling2"
  ],
  "伎": [
    "ji4"
  ],
  "禧": [
    "xi3"
  ],
  "收入": [
    "shou1 ru4"
  ],
  "长相": [
    "zhang3 xiang4"
  ],
  "徛": [
    null
  ],
  "移民": [
    "yi2 min2"
  ],
  "亩": [
    "mu3",
    "mu3",
    "mu3",
    "mu3"
  ],
  "移动": [
    "yi2 dong4"
  ],
  "脸盆": [
    "lian3 pen2"
  ],
  "球场": [
    "qiu2 chang3"
  ],
  "沛": [
    "pei4"
  ],
  "下坠": [
    "xia4 zhui4"
  ],
  "迫害": [
    "po4 hai4"
  ],
  "千钧一发": [
    "qian1 jun1 yi1 fa4"
  ],
  "颂": [
    "song4"
  ],
  "建设": [
    "jian4 she4"
  ],
  "戚": [
    "qi1",
    "qi1",
    "Qi1",
    "qi1"
  ],
  "袼": [
    "ge1"
  ],
  "划时代": [
    "hua4 shi2 dai4"
  ],
  "波动": [
    "bo1 dong4"
  ],
  "韵味": [
    "yun4 wei4"
  ],
  "贴近": [
    "tie1 jin4"
  ],
  "俘获": [
    "fu2 huo4"
  ],
  "南极": [
    "nan2 ji2"
  ],
  "境内": [
    "jing4 nei4"
  ],
  "参展": [
    "can1 zhan3"
  ],
  "隹": [
    "zhui1"
  ],
  "宫廷": [
    "gong1 ting2"
  ],
  "泌": [
    "bi4",
    "mi4"
  ],
  "嗍": [
    "suo1"
  ],
  "股东": [
    "gu3 dong1"
  ],
  "混凝土": [
    "hun4 ning2 tu3"
  ],
  "金": [
    "Jin1",
    "jin1"
  ],
  "脩": [
    "xiu1"
  ],
  "馑": [
    "jin3"
  ],
  "郊外": [
    "jiao1 wai4"
  ],
  "转": [
    "zhuai3",
    "zhuan3",
    "zhuan4"
  ],
  "广播": [
    "guang3 bo1"
  ],
  "鞲": [
    "gou1"
  ],
  "杨": [
    "Yang2",
    "yang2"
  ],
  "辐": [
    "fu2"
  ],
  "丢弃": [
    "diu1 qi4"
  ],
  "溪": [
    "xi1",
    "xi1"
  ],
  "诰": [
    "gao4"
  ],
  "鳘": [
    "min3"
  ],
  "龂": [
    "yin2"
  ],
  "不堪": [
    "bu4 kan1"
  ],
  "铭": [
    "ming2"
  ],
  "争夺": [
    "zheng1 duo2"
  ],
  "测": [
    "ce4"
  ],
  "訇": [
    "Hong1",
    "hong1"
  ],
  "薅": [
    "hao1"
  ],
  "踢": [
    "ti1"
  ],
  "推选": [
    "tui1 xuan3"
  ],
  "蜾": [
    "guo3"
  ],
  "粜": [
    "tiao4"
  ],
  "海滩": [
    "hai3 tan1"
  ],
  "耨": [
    "nou4"
  ],
  "心慌": [
    "xin1 huang1"
  ],
  "交代": [
    "jiao1 dai4"
  ],
  "左边": [
    "zuo3 bian5"
  ],
  "声": [
    "sheng1"
  ],
  "行人": [
    "xing2 ren2"
  ],
  "紧凑": [
    "jin3 cou4"
  ],
  "贰": [
    "er4"
  ],
  "柷": [
    "zhu4"
  ],
  "霉": [
    "mei2",
    "mei2"
  ],
  "同学": [
    "tong2 xue2"
  ],
  "彤": [
    "Tong2",
    "tong2"
  ],
  "区域": [
    "qu1 yu4"
  ],
  "亮丽": [
    "liang4 li4"
  ],
  "嚼": [
    "jiao2",
    "jiao4"
  ],
  "脆": [
    "cui4",
    "cui4"
  ],
  "水分": [
    "shui3 fen4"
  ],
  "繁殖": [
    "fan2 zhi2"
  ],
  "塑造": [
    "su4 zao4"
  ],
  "晅": [
    "xuan1"
  ],
  "时好时坏": [
    "shi2 hao3 shi2 huai4"
  ],
  "抒": [
    "shu1"
  ],
  "传记": [
    "zhuan4 ji4"
  ],
  "有意": [
    "you3 yi4"
  ],
  "繁": [
    "fan2",
    "fan2"
  ],
  "大家": [
    "da4 jia1"
  ],
  "外卖": [
    "wai4 mai4"
  ],
  "酬": [
    "chou2",
    "chou2",
    "chou2",
    "chou2"
  ],
  "挟": [
    "jia1",
    "xie2"
  ],
  "轩": [
    "Xuan1",
    "xuan1"
  ],
  "自": [
    "zi4"
  ],
  "迺": [
    "nai3"
  ],
  "基础": [
    "ji1 chu3"
  ],
  "学者": [
    "xue2 zhe3"
  ],
  "桥梁": [
    "qiao2 liang2"
  ],
  "着迷": [
    "zhao2 mi2"
  ],
  "讲话": [
    "jiang3 hua4"
  ],
  "配合": [
    "pei4 he2"
  ],
  "淡化": [
    "dan4 hua4"
  ],
  "一带": [
    "yi1 dai4"
  ],
  "东道主": [
    "dong1 dao4 zhu3"
  ],
  "工商界": [
    "gong1 shang1 jie4"
  ],
  "春": [
    "chun1",
    "Chun1",
    "chun1"
  ],
  "减轻": [
    "jian3 qing1"
  ],
  "欲望": [
    "yu4 wang4"
  ],
  "大概": [
    "da4 gai4"
  ],
  "汹": [
    "xiong1"
  ],
  "郅": [
    "Zhi4",
    "zhi4"
  ],
  "食宿": [
    "shi2 su4"
  ],
  "嗽": [
    "sou4"
  ],
  "焘": [
    "dao4",
    "tao1"
  ],
  "阶层": [
    "jie1 ceng2"
  ],
  "私立": [
    "si1 li4"
  ],
  "海报": [
    "hai3 bao4"
  ],
  "鬟": [
    "huan2"
  ],
  "就近": [
    "jiu4 jin4"
  ],
  "诧": [
    "cha4"
  ],
  "低迷": [
    "di1 mi2"
  ],
  "排名": [
    "pai2 ming2"
  ],
  "桄": [
    "guang1",
    "guang4"
  ],
  "么": [
    "ma2",
    "ma5",
    "me5",
    "me5"
  ],
  "思考": [
    "si1 kao3"
  ],
  "八卦": [
    "ba1 gua4"
  ],
  "掾": [
    "yuan4"
  ],
  "凉爽": [
    "liang2 shuang3"
  ],
  "当务之急": [
    "dang1 wu4 zhi1 ji2"
  ],
  "某": [
    "mou3"
  ],
  "各个": [
    "ge4 ge4"
  ],
  "努力": [
    "nu3 li4"
  ],
  "现代": [
    "Xian4 dai4",
    "xian4 dai4"
  ],
  "卷": [
    "juan3",
    "juan4",
    "juan3"
  ],
  "诽": [
    "fei3"
  ],
  "垍": [
    "ji4"
  ],
  "相依为命": [
    "xiang1 yi1 wei2 ming4"
  ],
  "邛": [
    "qiong2"
  ],
  "赔": [
    "pei2"
  ],
  "被": [
    "bei4"
  ],
  "头头是道": [
    "tou2 tou2 shi4 dao4"
  ],
  "一下儿": [
    "yi1 xia4 r5"
  ],
  "蚯": [
    "qiu1"
  ],
  "鳞": [
    "lin2"
  ],
  "发愤图强": [
    "fa1 fen4 tu2 qiang2"
  ],
  "浞": [
    "zhuo2"
  ],
  "蒿": [
    "hao1"
  ],
  "蠊": [
    "lian2"
  ],
  "采纳": [
    "cai3 na4"
  ],
  "汗": [
    "han2",
    "han4"
  ],
  "风力": [
    "feng1 li4"
  ],
  "垭": [
    "ya1"
  ],
  "嘴唇": [
    "zui3 chun2"
  ],
  "全新": [
    "quan2 xin1"
  ],
  "廉政": [
    "lian2 zheng4"
  ],
  "猄": [
    null
  ],
  "恋": [
    "lian4"
  ],
  "当中": [
    "dang1 zhong1"
  ],
  "借条": [
    "jie4 tiao2"
  ],
  "奖项": [
    "jiang3 xiang4"
  ],
  "砂糖": [
    "sha1 tang2"
  ],
  "胱": [
    "guang1"
  ],
  "洢": [
    "yi1"
  ],
  "眼前": [
    "yan3 qian2"
  ],
  "炝": [
    "qiang4"
  ],
  "风格": [
    "feng1 ge2"
  ],
  "幽默": [
    "you1 mo4"
  ],
  "空想": [
    "kong1 xiang3"
  ],
  "谋求": [
    "mou2 qiu2"
  ],
  "顺理成章": [
    "shun4 li3 cheng2 zhang1"
  ],
  "出主意": [
    "chu1 zhu3 yi5"
  ],
  "维生素": [
    "wei2 sheng1 su4"
  ],
  "茋": [
    null
  ],
  "哚": [
    "duo3"
  ],
  "打扰": [
    "da3 rao3"
  ],
  "摇篮": [
    "yao2 lan2"
  ],
  "绝技": [
    "jue2 ji4"
  ],
  "自由自在": [
    "zi4 you2 zi4 zai4"
  ],
  "科学": [
    "ke1 xue2"
  ],
  "德": [
    "De2",
    "de2",
    "de2",
    "de2"
  ],
  "夫人": [
    "fu1 ren5"
  ],
  "来宾": [
    "Lai2 bin1",
    "lai2 bin1"
  ],
  "首批": [
    "shou3 pi1"
  ],
  "一动不动": [
    "yi1 dong4 bu4 dong4"
  ],
  "民": [
    "Min2",
    "min2"
  ],
  "不利": [
    "bu4 li4"
  ],
  "重创": [
    "zhong4 chuang1"
  ],
  "定": [
    "ding4"
  ],
  "目中无人": [
    "mu4 zhong1 wu2 ren2"
  ],
  "笼统": [
    "long3 tong3"
  ],
  "卫星": [
    "wei4 xing1"
  ],
  "成效": [
    "cheng2 xiao4"
  ],
  "贷款": [
    "dai4 kuan3"
  ],
  "谊": [
    "yi4"
  ],
  "啴": [
    "chan3",
    "tan1"
  ],
  "好比": [
    "hao3 bi3"
  ],
  "回头": [
    "hui2 tou2"
  ],
  "核武器": [
    "he2 wu3 qi4"
  ],
  "䓬": [
    "zhuo2"
  ],
  "身材": [
    "shen1 cai2"
  ],
  "力所能及": [
    "li4 suo3 neng2 ji2"
  ],
  "结识": [
    "jie2 shi2"
  ],
  "忮": [
    "zhi4"
  ],
  "负责": [
    "fu4 ze2"
  ],
  "冠军": [
    "guan4 jun1"
  ],
  "欢聚": [
    "huan1 ju4"
  ],
  "幼": [
    "you4"
  ],
  "折扣": [
    "zhe2 kou4"
  ],
  "供奉": [
    "gong4 feng4"
  ],
  "杻": [
    "chou3",
    "niu3"
  ],
  "捐": [
    "juan1"
  ],
  "诐": [
    "bi4"
  ],
  "车祸": [
    "che1 huo4"
  ],
  "被告": [
    "bei4 gao4"
  ],
  "风景": [
    "feng1 jing3"
  ],
  "蜡": [
    "la4"
  ],
  "爆": [
    "bao4"
  ],
  "跟前": [
    "gen1 qian2",
    "gen1 qian5"
  ],
  "诫": [
    "jie4"
  ],
  "报社": [
    "bao4 she4"
  ],
  "物证": [
    "wu4 zheng4"
  ],
  "沧": [
    "cang1"
  ],
  "精确": [
    "jing1 que4"
  ],
  "鲚": [
    "ji4"
  ],
  "智力": [
    "zhi4 li4"
  ],
  "思维": [
    "si1 wei2"
  ],
  "事物": [
    "shi4 wu4"
  ],
  "流感": [
    "liu2 gan3"
  ],
  "纬": [
    "wei3"
  ],
  "不得已": [
    "bu4 de2 yi3"
  ],
  "爽快": [
    "shuang3 kuai5"
  ],
  "供不应求": [
    "gong1 bu4 ying4 qiu2"
  ],
  "劓": [
    "yi4"
  ],
  "觐": [
    "jin4"
  ],
  "矛盾": [
    "mao2 dun4"
  ],
  "沿海": [
    "yan2 hai3"
  ],
  "逦": [
    "li3"
  ],
  "及": [
    "ji2"
  ],
  "儆": [
    "jing3"
  ],
  "聚": [
    "ju4"
  ],
  "螠": [
    "yi4"
  ],
  "游戏": [
    "you2 xi4"
  ],
  "相处": [
    "xiang1 chu3"
  ],
  "彼": [
    "bi3"
  ],
  "事后": [
    "shi4 hou4"
  ],
  "褊": [
    "bian3"
  ],
  "嘌": [
    "piao4"
  ],
  "怀旧": [
    "huai2 jiu4"
  ],
  "次日": [
    "ci4 ri4"
  ],
  "墚": [
    null
  ],
  "魄力": [
    "po4 li4"
  ],
  "流动": [
    "liu2 dong4"
  ],
  "缘故": [
    "yuan2 gu4"
  ],
  "妮": [
    "ni1"
  ],
  "看似": [
    "kan4 si4"
  ],
  "头条": [
    "Tou2 tiao2",
    "tou2 tiao2"
  ],
  "侉": [
    "kua3"
  ],
  "松": [
    "Song1",
    "song1",
    "song1"
  ],
  "墒": [
    "shang1"
  ],
  "贽": [
    "zhi4"
  ],
  "出走": [
    "chu1 zou3"
  ],
  "疲倦": [
    "pi2 juan4"
  ],
  "教学": [
    "jiao1 xue2",
    "jiao4 xue2"
  ],
  "实验": [
    "shi2 yan4"
  ],
  "公告": [
    "gong1 gao4"
  ],
  "骖": [
    "can1"
  ],
  "爸": [
    "ba4"
  ],
  "超速": [
    "chao1 su4"
  ],
  "媵": [
    "ying4"
  ],
  "钼": [
    "mu4"
  ],
  "监测": [
    "jian1 ce4"
  ],
  "兵": [
    "bing1"
  ],
  "殪": [
    "yi4"
  ],
  "正视": [
    "zheng4 shi4"
  ],
  "折腾": [
    "zhe1 teng5"
  ],
  "威力": [
    "wei1 li4"
  ],
  "湿": [
    "shi1",
    "shi1"
  ],
  "诬": [
    "wu1"
  ],
  "蘖": [
    "nie4"
  ],
  "体验": [
    "ti3 yan4"
  ],
  "呕": [
    "ou3"
  ],
  "归结": [
    "gui1 jie2"
  ],
  "搜": [
    "sou1"
  ],
  "捕捉": [
    "bu3 zhuo1"
  ],
  "今年": [
    "jin1 nian2"
  ],
  "开关": [
    "kai1 guan1"
  ],
  "抄写": [
    "chao1 xie3"
  ],
  "睄": [
    "qiao2",
    "shao4"
  ],
  "怛": [
    "da2"
  ],
  "宗教": [
    "zong1 jiao4"
  ],
  "脟": [
    null
  ],
  "哲学": [
    "zhe2 xue2"
  ],
  "镝": [
    "di1",
    "di2"
  ],
  "酅": [
    "xi1"
  ],
  "隐患": [
    "yin3 huan4"
  ],
  "悄悄": [
    "qiao1 qiao1"
  ],
  "遗传": [
    "yi2 chuan2"
  ],
  "歌声": [
    "ge1 sheng1"
  ],
  "员工": [
    "yuan2 gong1"
  ],
  "拭": [
    "shi4"
  ],
  "脯": [
    "fu3",
    "pu2"
  ],
  "删除": [
    "shan1 chu2"
  ],
  "亏损": [
    "kui1 sun3"
  ],
  "门槛": [
    "men2 kan3"
  ],
  "送给": [
    "song4 gei3"
  ],
  "首饰": [
    "shou3 shi4"
  ],
  "大多数": [
    "da4 duo1 shu4"
  ],
  "落后": [
    "luo4 hou4"
  ],
  "研讨": [
    "yan2 tao3"
  ],
  "豺": [
    "chai2"
  ],
  "打牌": [
    "da3 pai2"
  ],
  "工作日": [
    "gong1 zuo4 ri4"
  ],
  "悢": [
    "liang4"
  ],
  "虾": [
    "ha2",
    "xia1"
  ],
  "井": [
    "Jing3",
    "jing3"
  ],
  "参谋": [
    "can1 mou2"
  ],
  "歉意": [
    "qian4 yi4"
  ],
  "部署": [
    "bu4 shu3"
  ],
  "航行": [
    "hang2 xing2"
  ],
  "圲": [
    null
  ],
  "咪": [
    "mi1"
  ],
  "筥": [
    "ju3"
  ],
  "攻读": [
    "gong1 du2"
  ],
  "传来": [
    "chuan2 lai2"
  ],
  "齇": [
    "zha1"
  ],
  "店": [
    "dian4"
  ],
  "缯": [
    "Zeng1",
    "zeng1",
    "zeng4"
  ],
  "比起": [
    "bi3 qi3"
  ],
  "绻": [
    "quan3"
  ],
  "激": [
    "ji1"
  ],
  "惩处": [
    "cheng2 chu3"
  ],
  "漏洞": [
    "lou4 dong4"
  ],
  "站": [
    "zhan4"
  ],
  "回报": [
    "hui2 bao4"
  ],
  "前边": [
    "qian2 bian5"
  ],
  "啰": [
    "luo1",
    "luo2",
    "luo5"
  ],
  "码": [
    "ma3"
  ],
  "房间": [
    "fang2 jian1"
  ],
  "瘊": [
    "hou2"
  ],
  "硬件": [
    "ying4 jian4"
  ],
  "濯": [
    "zhao4",
    "zhuo2"
  ],
  "狩": [
    "shou4"
  ],
  "钰": [
    "yu4"
  ],
  "菀": [
    "wan3",
    "yu4"
  ],
  "祊": [
    "beng1"
  ],
  "迷失": [
    "mi2 shi1"
  ],
  "有害": [
    "you3 hai4"
  ],
  "箱": [
    "xiang1"
  ],
  "帅": [
    "Shuai4",
    "shuai4"
  ],
  "解": [
    "Xie4",
    "jie3",
    "jie4",
    "xie4"
  ],
  "橱": [
    "chu2"
  ],
  "淦": [
    "gan4"
  ],
  "阙": [
    "Que1",
    "que1",
    "que4"
  ],
  "棚": [
    "peng2"
  ],
  "宽容": [
    "kuan1 rong2"
  ],
  "可歌可泣": [
    "ke3 ge1 ke3 qi4"
  ],
  "小曲": [
    "xiao3 qu3"
  ],
  "孺": [
    "Ru2",
    "ru2"
  ],
  "灞": [
    "Ba4"
  ],
  "孬": [
    "nao1"
  ],
  "病": [
    "bing4"
  ],
  "拥护": [
    "yong1 hu4"
  ],
  "做法": [
    "zuo4 fa3"
  ],
  "翟": [
    "Di2",
    "Zhai2",
    "di2"
  ],
  "冷水": [
    "leng3 shui3"
  ],
  "交费": [
    "jiao1 fei4"
  ],
  "奏效": [
    "zou4 xiao4"
  ],
  "奔波": [
    "ben1 bo1"
  ],
  "粮": [
    "liang2"
  ],
  "玭": [
    "pin2"
  ],
  "眯": [
    "mi2",
    "mi1"
  ],
  "雁": [
    "yan4",
    "yan4"
  ],
  "法": [
    "fa3",
    "Fa3",
    "fa3",
    "fa3"
  ],
  "氯": [
    "lu:4"
  ],
  "菂": [
    "di4"
  ],
  "国旗": [
    "guo2 qi2"
  ],
  "签订": [
    "qian1 ding4"
  ],
  "夔": [
    "Kui2"
  ],
  "旅行": [
    "lu:3 xing2"
  ],
  "气氛": [
    "qi4 fen1"
  ],
  "钳子": [
    "qian2 zi5"
  ],
  "缚": [
    "fu4"
  ],
  "不约而同": [
    "bu4 yue1 er2 tong2"
  ],
  "花纹": [
    "hua1 wen2"
  ],
  "缟": [
    "gao3"
  ],
  "呇": [
    "qi3"
  ],
  "启": [
    "qi3",
    "qi3",
    "Qi3",
    "qi3"
  ],
  "限于": [
    "xian4 yu2"
  ],
  "防汛": [
    "fang2 xun4"
  ],
  "便道": [
    "bian4 dao4"
  ],
  "挪": [
    "nuo2"
  ],
  "莿": [
    "ci4"
  ],
  "铘": [
    "ye2"
  ],
  "神气": [
    "shen2 qi4"
  ],
  "靠拢": [
    "kao4 long3"
  ],
  "䴗": [
    "ju2"
  ],
  "庙": [
    "miao4"
  ],
  "议": [
    "yi4"
  ],
  "诇": [
    "xiong4"
  ],
  "禁不住": [
    "jin1 bu5 zhu4"
  ],
  "水源": [
    "shui3 yuan2"
  ],
  "组装": [
    "zu3 zhuang1"
  ],
  "嫔": [
    "pin2"
  ],
  "悆": [
    "yu4"
  ],
  "景": [
    "Jing3",
    "jing3"
  ],
  "后续": [
    "hou4 xu4"
  ],
  "推移": [
    "tui1 yi2"
  ],
  "恋恋不舍": [
    "lian4 lian4 bu4 she3"
  ],
  "嵬": [
    "wei2"
  ],
  "全体": [
    "quan2 ti3"
  ],
  "彬彬有礼": [
    "bin1 bin1 you3 li3"
  ],
  "膝盖": [
    "xi1 gai4"
  ],
  "尹": [
    "Yin3",
    "yin3"
  ],
  "埋没": [
    "mai2 mo4"
  ],
  "干杯": [
    "gan1 bei1"
  ],
  "鼗": [
    "tao2",
    "tao2"
  ],
  "醭": [
    "bu2"
  ],
  "鳄鱼": [
    "e4 yu2"
  ],
  "巽": [
    "xun4"
  ],
  "多媒体": [
    "duo1 mei2 ti3"
  ],
  "剧": [
    "ju4"
  ],
  "萝": [
    "luo2"
  ],
  "时候": [
    "shi2 hou5"
  ],
  "疏散": [
    "shu1 san3",
    "shu1 san4"
  ],
  "敲门": [
    "qiao1 men2"
  ],
  "田": [
    "Tian2",
    "tian2"
  ],
  "奶粉": [
    "nai3 fen3"
  ],
  "确实": [
    "que4 shi2"
  ],
  "承载": [
    "cheng2 zai4"
  ],
  "础": [
    "chu3"
  ],
  "邮票": [
    "you2 piao4"
  ],
  "不止": [
    "bu4 zhi3"
  ],
  "瀍": [
    "Chan2"
  ],
  "饥": [
    "ji1",
    "ji1"
  ],
  "茛": [
    "gen4"
  ],
  "常常": [
    "chang2 chang2"
  ],
  "散": [
    "san4",
    "san3",
    "san4"
  ],
  "推荐": [
    "tui1 jian4"
  ],
  "游人": [
    "you2 ren2"
  ],
  "融化": [
    "rong2 hua4"
  ],
  "降": [
    "jiang4",
    "xiang2"
  ],
  "耍": [
    "Shua3",
    "shua3"
  ],
  "领带": [
    "ling3 dai4"
  ],
  "道歉": [
    "dao4 qian4"
  ],
  "另外": [
    "ling4 wai4"
  ],
  "营救": [
    "ying2 jiu4"
  ],
  "上任": [
    "shang4 ren4"
  ],
  "过后": [
    "guo4 hou4"
  ],
  "雎": [
    "ju1"
  ],
  "细": [
    "xi4"
  ],
  "祖传": [
    "zu3 chuan2"
  ],
  "域": [
    "yu4"
  ],
  "办公": [
    "ban4 gong1"
  ],
  "豮": [
    "fen2"
  ],
  "从": [
    "cong2",
    "Cong2",
    "cong2"
  ],
  "造型": [
    "zao4 xing2"
  ],
  "一点点": [
    "yi1 dian3 dian3"
  ],
  "禛": [
    "zhen1"
  ],
  "平稳": [
    "ping2 wen3"
  ],
  "端": [
    "duan1",
    "duan1"
  ],
  "莒": [
    "Ju3",
    "ju3"
  ],
  "复苏": [
    "fu4 su1",
    "fu4 su1"
  ],
  "白天": [
    "bai2 tian1"
  ],
  "唤起": [
    "huan4 qi3"
  ],
  "学术": [
    "xue2 shu4"
  ],
  "气候": [
    "qi4 hou4"
  ],
  "列举": [
    "lie4 ju3"
  ],
  "主力": [
    "zhu3 li4"
  ],
  "鸹": [
    "gua1"
  ],
  "管用": [
    "guan3 yong4"
  ],
  "代表": [
    "dai4 biao3"
  ],
  "总额": [
    "zong3 e2"
  ],
  "珌": [
    "bi4"
  ],
  "任务": [
    "ren4 wu5"
  ],
  "波折": [
    "bo1 zhe2"
  ],
  "徒弟": [
    "tu2 di4"
  ],
  "颟": [
    "man1"
  ],
  "湿润": [
    "shi1 run4"
  ],
  "纽": [
    "niu3"
  ],
  "胨": [
    "dong4"
  ],
  "效果": [
    "xiao4 guo3"
  ],
  "纳入": [
    "na4 ru4"
  ],
  "禾": [
    "he2"
  ],
  "桃花": [
    "tao2 hua1"
  ],
  "纩": [
    "kuang4"
  ],
  "胼": [
    "pian2"
  ],
  "遮": [
    "zhe1"
  ],
  "大公无私": [
    "da4 gong1 wu2 si1"
  ],
  "确切": [
    "que4 qie4"
  ],
  "淄": [
    "zi1"
  ],
  "喷泉": [
    "pen1 quan2"
  ],
  "傉": [
    null
  ],
  "两": [
    "liang3"
  ],
  "护": [
    "hu4"
  ],
  "四合院": [
    "si4 he2 yuan4"
  ],
  "劝告": [
    "quan4 gao4"
  ],
  "毕业": [
    "bi4 ye4"
  ],
  "描绘": [
    "miao2 hui4"
  ],
  "讪": [
    "shan4"
  ],
  "并": [
    "bing4",
    "bing4",
    "Bing1",
    "bing4"
  ],
  "演戏": [
    "yan3 xi4"
  ],
  "炙": [
    "zhi4"
  ],
  "早期": [
    "zao3 qi1"
  ],
  "够呛": [
    "gou4 qiang4"
  ],
  "皇帝": [
    "huang2 di4"
  ],
  "赀": [
    "zi1"
  ],
  "阡": [
    "qian1"
  ],
  "甩": [
    "shuai3"
  ],
  "社": [
    "she4"
  ],
  "耳熟能详": [
    "er3 shu2 neng2 xiang2"
  ],
  "听写": [
    "ting1 xie3"
  ],
  "读书": [
    "du2 shu1"
  ],
  "浴室": [
    "yu4 shi4"
  ],
  "自在": [
    "zi4 zai5"
  ],
  "螈": [
    "yuan2"
  ],
  "阽": [
    "dian4"
  ],
  "琶": [
    "pa2"
  ],
  "蓼": [
    "liao3",
    "lu4"
  ],
  "读音": [
    "du2 yin1"
  ],
  "毂": [
    "gu1",
    "gu3"
  ],
  "祖父": [
    "zu3 fu4"
  ],
  "机动车": [
    "ji1 dong4 che1"
  ],
  "划船": [
    "hua2 chuan2"
  ],
  "分手": [
    "fen1 shou3"
  ],
  "忽然": [
    "hu1 ran2"
  ],
  "奇妙": [
    "qi2 miao4"
  ],
  "君子": [
    "jun1 zi3"
  ],
  "桁": [
    "hang2",
    "heng2"
  ],
  "萄": [
    "tao2"
  ],
  "禘": [
    "di4"
  ],
  "簉": [
    "zao4"
  ],
  "最佳": [
    "zui4 jia1"
  ],
  "排斥": [
    "pai2 chi4"
  ],
  "杯子": [
    "bei1 zi5"
  ],
  "和": [
    "he2",
    "He2",
    "he2",
    "he4",
    "hu2",
    "huo2",
    "huo4",
    "he2"
  ],
  "噀": [
    "xun4"
  ],
  "投稿": [
    "tou2 gao3"
  ],
  "俶": [
    "chu4"
  ],
  "船": [
    "chuan2",
    "chuan2"
  ],
  "其": [
    "qi2"
  ],
  "立即": [
    "li4 ji2"
  ],
  "耿直": [
    "geng3 zhi2"
  ],
  "失利": [
    "shi1 li4"
  ],
  "非得": [
    "fei1 dei3"
  ],
  "掣": [
    "che4"
  ],
  "朗诵": [
    "lang3 song4"
  ],
  "璒": [
    null
  ],
  "细微": [
    "xi4 wei1"
  ],
  "公事": [
    "gong1 shi4"
  ],
  "帮助": [
    "bang1 zhu4"
  ],
  "学": [
    "xue2"
  ],
  "爱理不理": [
    "ai4 li3 bu4 li3"
  ],
  "璬": [
    "jiao3"
  ],
  "定心丸": [
    "ding4 xin1 wan2"
  ],
  "鞠躬": [
    "ju1 gong1"
  ],
  "壮胆": [
    "zhuang4 dan3"
  ],
  "盎": [
    "ang4"
  ],
  "破碎": [
    "po4 sui4"
  ],
  "鳓": [
    "le4"
  ],
  "瞑": [
    "ming2"
  ],
  "正义": [
    "zheng4 yi4"
  ],
  "文艺": [
    "wen2 yi4"
  ],
  "芃": [
    "peng2"
  ],
  "荻": [
    "di2"
  ],
  "岌": [
    "ji2"
  ],
  "渠": [
    "Qu2",
    "ju4",
    "qu2"
  ],
  "考察": [
    "kao3 cha2"
  ],
  "龀": [
    "chen4"
  ],
  "科普": [
    "ke1 pu3"
  ],
  "莶": [
    "lian3",
    "xian1"
  ],
  "扎实": [
    "zha1 shi5",
    "zha1 shi5"
  ],
  "证人": [
    "zheng4 ren2"
  ],
  "熏": [
    "xun1",
    "xun1",
    "xun1"
  ],
  "共同体": [
    "gong4 tong2 ti3"
  ],
  "似是而非": [
    "si4 shi4 er2 fei1"
  ],
  "终结": [
    "zhong1 jie2"
  ],
  "附和": [
    "fu4 he4"
  ],
  "咧": [
    "lie1",
    "lie3",
    "lie5"
  ],
  "姜": [
    "Jiang1",
    "jiang1",
    "jiang1"
  ],
  "随": [
    "Sui2",
    "sui2"
  ],
  "烟囱": [
    "yan1 cong1"
  ],
  "网络": [
    "Wang3 luo4",
    "wang3 luo4"
  ],
  "寺庙": [
    "si4 miao4"
  ],
  "哥": [
    "ge1"
  ],
  "掞": [
    "shan4"
  ],
  "反面": [
    "fan3 mian4"
  ],
  "形式": [
    "xing2 shi4"
  ],
  "刊": [
    "kan1",
    "kan1"
  ],
  "琲": [
    "bei4"
  ],
  "轨迹": [
    "gui3 ji4"
  ],
  "早": [
    "zao3"
  ],
  "完成": [
    "wan2 cheng2"
  ],
  "农民工": [
    "nong2 min2 gong1"
  ],
  "揖": [
    "yi1"
  ],
  "蓦": [
    "mo4"
  ],
  "改版": [
    "gai3 ban3"
  ],
  "失": [
    "shi1"
  ],
  "沿岸": [
    "yan2 an4"
  ],
  "樨": [
    "xi1"
  ],
  "绫": [
    "ling2"
  ],
  "空调": [
    "kong1 tiao2"
  ],
  "枫": [
    "feng1"
  ],
  "服": [
    "fu2",
    "fu4"
  ],
  "解体": [
    "jie3 ti3"
  ],
  "溻": [
    "ta1"
  ],
  "荜": [
    "bi4"
  ],
  "招生": [
    "zhao1 sheng1"
  ],
  "雄伟": [
    "xiong2 wei3"
  ],
  "夷": [
    "yi2"
  ],
  "开发商": [
    "kai1 fa1 shang1"
  ],
  "热门": [
    "re4 men2"
  ],
  "道": [
    "dao4"
  ],
  "楹": [
    "ying2"
  ],
  "货物": [
    "huo4 wu4"
  ],
  "笤": [
    "tiao2"
  ],
  "嫂": [
    "sao3"
  ],
  "毫不": [
    "hao2 bu4"
  ],
  "百科全书": [
    "bai3 ke1 quan2 shu1"
  ],
  "流域": [
    "liu2 yu4"
  ],
  "茹": [
    "Ru2",
    "ru2"
  ],
  "呔": [
    "dai1",
    "tai3"
  ],
  "黉": [
    "hong2"
  ],
  "医院": [
    "yi1 yuan4"
  ],
  "��": [
    null
  ],
  "收缩": [
    "shou1 suo1"
  ],
  "矰": [
    "zeng1"
  ],
  "填": [
    "tian2"
  ],
  "初中": [
    "chu1 zhong1"
  ],
  "化身": [
    "hua4 shen1"
  ],
  "探测": [
    "tan4 ce4"
  ],
  "娣": [
    "di4"
  ],
  "瓠": [
    "hu4"
  ],
  "亠": [
    "tou2"
  ],
  "必须": [
    "bi4 xu1"
  ],
  "礌": [
    "lei2"
  ],
  "交付": [
    "jiao1 fu4"
  ],
  "椅": [
    "yi3"
  ],
  "锬": [
    "tan2"
  ],
  "嗯": [
    "en1",
    "en4",
    "en5"
  ],
  "勖": [
    "xu4"
  ],
  "擅自": [
    "shan4 zi4"
  ],
  "叨": [
    "dao1",
    "tao1"
  ],
  "砒": [
    "pi1"
  ],
  "清晨": [
    "qing1 chen2"
  ],
  "进行": [
    "jin4 xing2"
  ],
  "溶": [
    "rong2"
  ],
  "电梯": [
    "dian4 ti1"
  ],
  "诽谤": [
    "fei3 bang4"
  ],
  "有声有色": [
    "you3 sheng1 you3 se4"
  ],
  "霁": [
    "ji4"
  ],
  "完整": [
    "wan2 zheng3"
  ],
  "顾全大局": [
    "gu4 quan2 da4 ju2"
  ],
  "大象": [
    "da4 xiang4"
  ],
  "聍": [
    "ning2"
  ],
  "平均": [
    "ping2 jun1"
  ],
  "鼓励": [
    "gu3 li4"
  ],
  "赠送": [
    "zeng4 song4"
  ],
  "测试": [
    "ce4 shi4"
  ],
  "凸显": [
    "tu1 xian3"
  ],
  "鹘": [
    "hu2"
  ],
  "芝": [
    "zhi1"
  ],
  "醨": [
    "li2"
  ],
  "条约": [
    "tiao2 yue1"
  ],
  "荣": [
    "Rong2",
    "rong2"
  ],
  "原汁原味": [
    "yuan2 zhi1 yuan2 wei4"
  ],
  "核对": [
    "he2 dui4"
  ],
  "流量": [
    "liu2 liang4"
  ],
  "佛": [
    "Fo2",
    "fu2",
    "fu2"
  ],
  "如下": [
    "ru2 xia4"
  ],
  "侧面": [
    "ce4 mian4"
  ],
  "痱": [
    "fei4",
    "fei4"
  ],
  "独家": [
    "du2 jia1"
  ],
  "前赴后继": [
    "qian2 fu4 hou4 ji4"
  ],
  "点评": [
    "dian3 ping2"
  ],
  "进而": [
    "jin4 er2"
  ],
  "迷人": [
    "mi2 ren2"
  ],
  "债": [
    "zhai4"
  ],
  "气": [
    "qi4"
  ],
  "猝": [
    "cu4"
  ],
  "选举": [
    "xuan3 ju3"
  ],
  "鸻": [
    "heng2"
  ],
  "蝲": [
    "la4"
  ],
  "十": [
    "shi2"
  ],
  "高调": [
    "gao1 diao4"
  ],
  "窳": [
    "yu3"
  ],
  "屺": [
    "qi3"
  ],
  "呼吁": [
    "hu1 yu4"
  ],
  "鹋": [
    "miao2"
  ],
  "瞋": [
    "chen1"
  ],
  "庋": [
    "gui3"
  ],
  "炖": [
    "dun4"
  ],
  "藏品": [
    "cang2 pin3"
  ],
  "刻意": [
    "ke4 yi4"
  ],
  "伙伴": [
    "huo3 ban4"
  ],
  "塔": [
    "ta3",
    "ta3"
  ],
  "乘人之危": [
    "cheng2 ren2 zhi1 wei1"
  ],
  "眊": [
    "mao4"
  ],
  "颈": [
    "geng3",
    "jing3"
  ],
  "梏": [
    "gu4"
  ],
  "儳": [
    "chan4"
  ],
  "朝夕相处": [
    "zhao1 xi1 xiang1 chu3"
  ],
  "下雨": [
    "xia4 yu3"
  ],
  "内科": [
    "nei4 ke1"
  ],
  "湮": [
    "yan1",
    "yin1"
  ],
  "蛩": [
    "qiong2"
  ],
  "恐": [
    "kong3"
  ],
  "千方百计": [
    "qian1 fang1 bai3 ji4"
  ],
  "主题歌": [
    "zhu3 ti2 ge1"
  ],
  "光盘": [
    "guang1 pan2"
  ],
  "飞往": [
    null
  ],
  "打倒": [
    "da3 dao3"
  ],
  "似": [
    "shi4",
    "si4",
    "si4"
  ],
  "白酒": [
    "bai2 jiu3"
  ],
  "繁重": [
    "fan2 zhong4"
  ],
  "吗": [
    "ma2",
    "ma3",
    "ma5"
  ],
  "巯": [
    "qiu2"
  ],
  "岢": [
    "ke3"
  ],
  "缩小": [
    "suo1 xiao3"
  ],
  "懑": [
    "men4"
  ],
  "倔强": [
    "jue2 jiang4"
  ],
  "逸": [
    "yi4"
  ],
  "埭": [
    "dai4"
  ],
  "闹事": [
    "nao4 shi4"
  ],
  "濞": [
    "bi4"
  ],
  "亍": [
    "chu4"
  ],
  "疗": [
    "liao2"
  ],
  "冰山": [
    "bing1 shan1"
  ],
  "侃": [
    "kan3",
    "kan3"
  ],
  "面向": [
    "mian4 xiang4"
  ],
  "冯": [
    "Feng2",
    "ping2"
  ],
  "容纳": [
    "rong2 na4"
  ],
  "牁": [
    "ke1"
  ],
  "自行": [
    "zi4 xing2"
  ],
  "乱": [
    "luan4"
  ],
  "伤": [
    "shang1"
  ],
  "不得不": [
    "bu4 de2 bu4"
  ],
  "籼": [
    "xian1"
  ],
  "差别": [
    "cha1 bie2"
  ],
  "恍然大悟": [
    "huang3 ran2 da4 wu4"
  ],
  "蛞": [
    "kuo4"
  ],
  "现场": [
    "xian4 chang3"
  ],
  "编": [
    "bian1"
  ],
  "扺": [
    null
  ],
  "侪": [
    "chai2"
  ],
  "言辞": [
    "yan2 ci2"
  ],
  "峣": [
    "yao2"
  ],
  "大模大样": [
    "da4 mu2 da4 yang4"
  ],
  "欤": [
    "yu2"
  ],
  "看": [
    "kan1",
    "kan4"
  ],
  "说明书": [
    "shuo1 ming2 shu1"
  ],
  "地址": [
    "di4 zhi3"
  ],
  "熹": [
    "xi1"
  ],
  "支票": [
    "zhi1 piao4"
  ],
  "比例": [
    "bi3 li4"
  ],
  "坛": [
    "tan2",
    "tan2"
  ],
  "馈": [
    "kui4",
    "kui4"
  ],
  "上下": [
    "shang4 xia4"
  ],
  "榖": [
    "gu3"
  ],
  "显现": [
    "xian3 xian4"
  ],
  "董事": [
    "dong3 shi4"
  ],
  "前面": [
    "qian2 mian4"
  ],
  "感情": [
    "gan3 qing2"
  ],
  "还是": [
    "hai2 shi5"
  ],
  "跑步": [
    "pao3 bu4"
  ],
  "镡": [
    "Tan2",
    "xin2"
  ],
  "乌云": [
    "wu1 yun2"
  ],
  "瓴": [
    "ling2"
  ],
  "噔": [
    "deng1"
  ],
  "止": [
    "zhi3"
  ],
  "杂乱无章": [
    "za2 luan4 wu2 zhang1"
  ],
  "镜头": [
    "jing4 tou2"
  ],
  "下海": [
    "xia4 hai3"
  ],
  "在意": [
    "zai4 yi4"
  ],
  "盹": [
    "dun3"
  ],
  "棋子": [
    "qi2 zi3"
  ],
  "娇惯": [
    "jiao1 guan4"
  ],
  "彩虹": [
    "cai3 hong2"
  ],
  "帐": [
    "zhang4"
  ],
  "于是": [
    "yu2 shi4"
  ],
  "肌肉": [
    "ji1 rou4"
  ],
  "嶟": [
    "zun1"
  ],
  "溱": [
    "zhen1"
  ],
  "反应": [
    "fan3 ying4"
  ],
  "入门": [
    "ru4 men2"
  ],
  "峤": [
    "jiao4"
  ],
  "唱": [
    "chang4"
  ],
  "尝": [
    "chang2",
    "chang2",
    "chang2"
  ],
  "味道": [
    "wei4 dao5"
  ],
  "哈哈": [
    "ha1 ha1"
  ],
  "父亲": [
    "fu4 qin1"
  ],
  "社交": [
    "she4 jiao1"
  ],
  "摏": [
    "chong1"
  ],
  "渣": [
    "zha1"
  ],
  "盗版": [
    "dao4 ban3"
  ],
  "相": [
    "Xiang1",
    "xiang1",
    "xiang4"
  ],
  "上": [
    "shang3",
    "shang4"
  ],
  "插": [
    "cha1",
    "cha1"
  ],
  "悴": [
    "cui4",
    "cui4"
  ],
  "通告": [
    "tong1 gao4"
  ],
  "陈": [
    "Chen2",
    "chen2"
  ],
  "震惊": [
    "zhen4 jing1"
  ],
  "车型": [
    "che1 xing2"
  ],
  "偷看": [
    "tou1 kan4"
  ],
  "违章": [
    "wei2 zhang1"
  ],
  "秆": [
    "gan3"
  ],
  "投奔": [
    "tou2 ben4"
  ],
  "茜": [
    "qian4",
    "xi1"
  ],
  "午": [
    "wu3"
  ],
  "头脑": [
    "tou2 nao3"
  ],
  "宝": [
    "bao3",
    "bao3"
  ],
  "无缘": [
    "wu2 yuan2"
  ],
  "邹": [
    "Zou1"
  ],
  "轨": [
    "gui3"
  ],
  "一些": [
    "yi1 xie1"
  ],
  "睦": [
    "mu4"
  ],
  "老乡": [
    "lao3 xiang1"
  ],
  "肿瘤": [
    "zhong3 liu2"
  ],
  "蜴": [
    "yi4"
  ],
  "昵": [
    "ni4",
    "ni4"
  ],
  "通风": [
    "tong1 feng1"
  ],
  "暗中": [
    "an4 zhong1"
  ],
  "糵": [
    null
  ],
  "外国": [
    "wai4 guo2"
  ],
  "肩负": [
    "jian1 fu4"
  ],
  "靼": [
    "da2"
  ],
  "跋": [
    "ba2"
  ],
  "亲密": [
    "qin1 mi4"
  ],
  "桌子": [
    "zhuo1 zi5"
  ],
  "免不了": [
    "mian3 bu4 liao3"
  ],
  "重点": [
    "chong2 dian3",
    "zhong4 dian3"
  ],
  "一流": [
    "yi1 liu2"
  ],
  "零下": [
    "ling2 xia4"
  ],
  "稳定": [
    "wen3 ding4"
  ],
  "蓝图": [
    "lan2 tu2"
  ],
  "心": [
    "xin1"
  ],
  "翠": [
    "cui4"
  ],
  "清脆": [
    "qing1 cui4"
  ],
  "署": [
    "shu3"
  ],
  "虚弱": [
    "xu1 ruo4"
  ],
  "噪声": [
    "zao4 sheng1"
  ],
  "觥": [
    "gong1"
  ],
  "午饭": [
    "wu3 fan4"
  ],
  "交情": [
    "jiao1 qing5"
  ],
  "快餐": [
    "kuai4 can1"
  ],
  "扶": [
    "fu2"
  ],
  "岂": [
    "kai3",
    "qi3"
  ],
  "索取": [
    "suo3 qu3"
  ],
  "汆": [
    "cuan1"
  ],
  "书籍": [
    "shu1 ji2"
  ],
  "凵": [
    "kan3"
  ],
  "例": [
    "li4"
  ],
  "吃惊": [
    "chi1 jing1"
  ],
  "竹子": [
    "zhu2 zi5"
  ],
  "塱": [
    "lang3"
  ],
  "足以": [
    "zu2 yi3"
  ],
  "能耗": [
    "neng2 hao4"
  ],
  "衾": [
    "qin1"
  ],
  "除夕": [
    "Chu2 xi1"
  ],
  "历经": [
    "li4 jing1"
  ],
  "影片": [
    "ying3 pian4"
  ],
  "鬘": [
    "man2"
  ],
  "赞叹不已": [
    "zan4 tan4 bu4 yi3"
  ],
  "影响": [
    "ying3 xiang3"
  ],
  "采集": [
    "cai3 ji2"
  ],
  "陇": [
    "Long3"
  ],
  "纾": [
    "shu1"
  ],
  "拢": [
    "long3"
  ],
  "茨": [
    "ci2"
  ],
  "专利": [
    "zhuan1 li4"
  ],
  "稃": [
    "fu1"
  ],
  "那时候": [
    "na4 shi2 hou5"
  ],
  "耦": [
    "ou3"
  ],
  "倞": [
    "jing4",
    "liang4"
  ],
  "慰问": [
    "wei4 wen4"
  ],
  "滑梯": [
    "hua2 ti1"
  ],
  "卓越": [
    "zhuo2 yue4"
  ],
  "铋": [
    "bi4"
  ],
  "伯": [
    "ba4",
    "bai3",
    "bo2"
  ],
  "洽": [
    "qia4"
  ],
  "豌": [
    "wan1"
  ],
  "吩咐": [
    "fen1 fu5"
  ],
  "伢": [
    "ya2"
  ],
  "整体": [
    "zheng3 ti3"
  ],
  "镅": [
    "mei2"
  ],
  "旋转": [
    "xuan2 zhuan3"
  ],
  "世": [
    "Shi4",
    "shi4"
  ],
  "径": [
    "jing4"
  ],
  "进场": [
    "jin4 chang3"
  ],
  "宣布": [
    "xuan1 bu4"
  ],
  "天平": [
    "tian1 ping2"
  ],
  "回家": [
    "hui2 jia1"
  ],
  "鞳": [
    null
  ],
  "底线": [
    "di3 xian4"
  ],
  "轻蔑": [
    "qing1 mie4"
  ],
  "特征": [
    "te4 zheng1"
  ],
  "瘐": [
    "yu3"
  ],
  "情调": [
    "qing2 diao4"
  ],
  "芮": [
    "Rui4",
    "rui4"
  ],
  "珠": [
    "zhu1"
  ],
  "仞": [
    "ren4"
  ],
  "綦": [
    "qi2"
  ],
  "衒": [
    "xuan4"
  ],
  "弹性": [
    "tan2 xing4"
  ],
  "受苦": [
    "shou4 ku3"
  ],
  "赵": [
    "Zhao4",
    "zhao4"
  ],
  "英文": [
    "Ying1 wen2"
  ],
  "转播": [
    "zhuan3 bo1"
  ],
  "打磨": [
    "da3 mo2"
  ],
  "省略": [
    "sheng3 lu:e4"
  ],
  "送到": [
    null
  ],
  "内在": [
    "nei4 zai4"
  ],
  "喻": [
    "Yu4",
    "yu4"
  ],
  "族": [
    "zu2"
  ],
  "学期": [
    "xue2 qi1"
  ],
  "改正": [
    "gai3 zheng4"
  ],
  "刷子": [
    "shua1 zi5"
  ],
  "歇": [
    "xie1"
  ],
  "家电": [
    "jia1 dian4"
  ],
  "戏曲": [
    "xi4 qu3"
  ],
  "野心": [
    "ye3 xin1"
  ],
  "文科": [
    "wen2 ke1"
  ],
  "痹": [
    "bi4"
  ],
  "凰": [
    "huang2"
  ],
  "斥": [
    "chi4"
  ],
  "庙会": [
    "miao4 hui4"
  ],
  "赫": [
    "He4",
    "he4"
  ],
  "维": [
    "Wei2",
    "wei2"
  ],
  "从事": [
    "cong2 shi4"
  ],
  "勍": [
    "qing2"
  ],
  "童话": [
    "tong2 hua4"
  ],
  "蜩": [
    "tiao2"
  ],
  "喧闹": [
    "xuan1 nao4"
  ],
  "戥": [
    "deng3"
  ],
  "往往": [
    "wang3 wang3"
  ],
  "锫": [
    "pei2"
  ],
  "刺": [
    "ci1",
    "ci4"
  ],
  "戢": [
    "Ji2",
    "ji2"
  ],
  "看法": [
    "kan4 fa3"
  ],
  "客户": [
    "ke4 hu4"
  ],
  "客气": [
    "ke4 qi5"
  ],
  "悯": [
    "min3"
  ],
  "荆": [
    "jing1"
  ],
  "别人": [
    "bie2 ren5"
  ],
  "魔鬼": [
    "mo2 gui3"
  ],
  "茭": [
    "jiao1"
  ],
  "福利": [
    "fu2 li4"
  ],
  "壅": [
    "yong1"
  ],
  "大米": [
    "da4 mi3"
  ],
  "酲": [
    "cheng2"
  ],
  "深处": [
    "shen1 chu4"
  ],
  "焌": [
    "jun4",
    "qu1"
  ],
  "不光": [
    "bu4 guang1"
  ],
  "基": [
    "ji1"
  ],
  "鲿": [
    "chang2"
  ],
  "踬": [
    "zhi4"
  ],
  "诋": [
    "di3"
  ],
  "材": [
    "cai2"
  ],
  "萼": [
    "e4",
    "e4"
  ],
  "邕": [
    "Yong1",
    "yong1"
  ],
  "包括": [
    "bao1 kuo4"
  ],
  "翻译": [
    "fan1 yi4"
  ],
  "滦": [
    "Luan2"
  ],
  "一路平安": [
    "yi1 lu4 ping2 an1"
  ],
  "墐": [
    "jin3"
  ],
  "夺": [
    "duo2"
  ],
  "焕": [
    "huan4"
  ],
  "鲜活": [
    "xian1 huo2"
  ],
  "璇": [
    "xuan2",
    "xuan2"
  ],
  "媚": [
    "mei4"
  ],
  "波": [
    "Bo1",
    "bo1"
  ],
  "擿": [
    "ti1",
    "zhi4"
  ],
  "蔺": [
    "Lin4",
    "lin4"
  ],
  "疗效": [
    "liao2 xiao4"
  ],
  "不由得": [
    "bu4 you2 de5"
  ],
  "蹁": [
    "pian2"
  ],
  "林": [
    "Lin2",
    "lin2"
  ],
  "镆": [
    "mo4"
  ],
  "整顿": [
    "zheng3 dun4"
  ],
  "旺盛": [
    "wang4 sheng4"
  ],
  "一如既往": [
    "yi1 ru2 ji4 wang3"
  ],
  "立方": [
    "li4 fang1"
  ],
  "胡子": [
    "hu2 zi5"
  ],
  "赶往": [
    "gan3 wang3"
  ],
  "继承": [
    "ji4 cheng2"
  ],
  "睡": [
    "shui4"
  ],
  "延伸": [
    "yan2 shen1"
  ],
  "踌": [
    "chou2"
  ],
  "睫": [
    "jie2"
  ],
  "处分": [
    "chu3 fen4"
  ],
  "昪": [
    "bian4"
  ],
  "紧张": [
    "jin3 zhang1"
  ],
  "蔟": [
    "cu4"
  ],
  "查出": [
    "cha2 chu1"
  ],
  "猹": [
    "cha2"
  ],
  "盒饭": [
    "he2 fan4"
  ],
  "石油": [
    "shi2 you2"
  ],
  "宣言": [
    "xuan1 yan2"
  ],
  "鸳": [
    "yuan1"
  ],
  "大伙儿": [
    "da4 huo3 r5"
  ],
  "确信": [
    "que4 xin4"
  ],
  "家属": [
    "jia1 shu3"
  ],
  "蓟": [
    "Ji4",
    "ji4"
  ],
  "汹涌": [
    "xiong1 yong3"
  ],
  "见解": [
    "jian4 jie3"
  ],
  "蒐": [
    "sou1"
  ],
  "榃": [
    "tan2"
  ],
  "大棚": [
    "da4 peng2"
  ],
  "前提": [
    "qian2 ti2"
  ],
  "家政": [
    "jia1 zheng4"
  ],
  "主": [
    "zhu3"
  ],
  "起初": [
    "qi3 chu1"
  ],
  "桷": [
    "jue2"
  ],
  "蛳": [
    "si1"
  ],
  "舾": [
    "xi1"
  ],
  "昨": [
    "zuo2"
  ],
  "猩": [
    "xing1"
  ],
  "宥": [
    "you4"
  ],
  "痃": [
    null
  ],
  "格": [
    "ge2"
  ],
  "更新": [
    "geng1 xin1"
  ],
  "问世": [
    "wen4 shi4"
  ],
  "字": [
    "zi4"
  ],
  "䏡": [
    null
  ],
  "镤": [
    "pu2"
  ],
  "害臊": [
    "hai4 sao4"
  ],
  "翾": [
    "xuan1"
  ],
  "长": [
    "chang2",
    "zhang3"
  ],
  "宽敞": [
    "kuan1 chang5"
  ],
  "势不可当": [
    "shi4 bu4 ke3 dang1"
  ],
  "印象": [
    "yin4 xiang4"
  ],
  "虤": [
    null
  ],
  "器官": [
    "qi4 guan1"
  ],
  "发酵": [
    "fa1 jiao4"
  ],
  "部件": [
    "bu4 jian4"
  ],
  "老婆": [
    "lao3 po2"
  ],
  "开头": [
    "kai1 tou2"
  ],
  "引人注目": [
    "yin3 ren2 zhu4 mu4"
  ],
  "荠": [
    "ji4",
    "qi2"
  ],
  "姞": [
    "Ji2"
  ],
  "求助": [
    "qiu2 zhu4"
  ],
  "酚": [
    "fen1"
  ],
  "弧": [
    "hu2"
  ],
  "哦": [
    "e2",
    "o2",
    "o4",
    "o5"
  ],
  "孜": [
    "zi1"
  ],
  "枳": [
    "zhi3"
  ],
  "地上": [
    "di4 shang5"
  ],
  "嵛": [
    "yu2"
  ],
  "绰号": [
    "chuo4 hao4"
  ],
  "快要": [
    "kuai4 yao4"
  ],
  "腨": [
    null
  ],
  "动用": [
    "dong4 yong4"
  ],
  "孔": [
    "Kong3",
    "kong3"
  ],
  "中心": [
    "zhong1 xin1"
  ],
  "恓": [
    "xi1"
  ],
  "侯": [
    "Hou2",
    "hou2"
  ],
  "一般来说": [
    "yi1 ban1 lai2 shuo1"
  ],
  "茀": [
    "fu2"
  ],
  "紊乱": [
    "wen3 luan4"
  ],
  "人造": [
    "ren2 zao4"
  ],
  "中": [
    "Zhong1",
    "zhong1",
    "zhong4"
  ],
  "囤": [
    "dun4",
    "tun2"
  ],
  "赶紧": [
    "gan3 jin3"
  ],
  "委员会": [
    "wei3 yuan2 hui4"
  ],
  "阀": [
    "fa2"
  ],
  "椆": [
    "chou2"
  ],
  "莛": [
    "ting2"
  ],
  "夏季": [
    "xia4 ji4"
  ],
  "贵重": [
    "gui4 zhong4"
  ],
  "极度": [
    "ji2 du4"
  ],
  "资历": [
    "zi1 li4"
  ],
  "治愈": [
    "zhi4 yu4"
  ],
  "浪": [
    "lang4"
  ],
  "盯": [
    "ding1"
  ],
  "狗": [
    "gou3"
  ],
  "资讯": [
    "zi1 xun4"
  ],
  "急于": [
    "ji2 yu2"
  ],
  "家境": [
    "jia1 jing4"
  ],
  "腓": [
    "fei2"
  ],
  "观望": [
    "guan1 wang4"
  ],
  "停车场": [
    "ting2 che1 chang3"
  ],
  "公路": [
    "gong1 lu4"
  ],
  "鳜": [
    "gui4"
  ],
  "架子": [
    "jia4 zi5"
  ],
  "鸤": [
    "shi1"
  ],
  "爱心": [
    "ai4 xin1"
  ],
  "专职": [
    "zhuan1 zhi2"
  ],
  "连锁": [
    "lian2 suo3"
  ],
  "现行": [
    "xian4 xing2"
  ],
  "垏": [
    "lu:4"
  ],
  "菼": [
    "tan3"
  ],
  "眭": [
    "Sui1",
    "sui1"
  ],
  "靸": [
    "sa3"
  ],
  "耗费": [
    "hao4 fei4"
  ],
  "宗": [
    "Zong1",
    "zong1"
  ],
  "少见": [
    "shao3 jian4"
  ],
  "钤": [
    "qian2"
  ],
  "另一方面": [
    "ling4 yi1 fang1 mian4"
  ],
  "查询": [
    "cha2 xun2"
  ],
  "成品": [
    "cheng2 pin3"
  ],
  "爱惜": [
    "ai4 xi1"
  ],
  "鄙视": [
    "bi3 shi4"
  ],
  "宣传": [
    "xuan1 chuan2"
  ],
  "将来": [
    "jiang1 lai2"
  ],
  "闪电": [
    "shan3 dian4"
  ],
  "躲避": [
    "duo3 bi4"
  ],
  "纬度": [
    "wei3 du4"
  ],
  "窕": [
    "tiao3"
  ],
  "怎": [
    "zen3"
  ],
  "饷": [
    "xiang3",
    "xiang3"
  ],
  "管教": [
    "guan3 jiao4"
  ],
  "忏": [
    "chan4"
  ],
  "铀": [
    "you2"
  ],
  "䁖": [
    "lou1"
  ],
  "彷": [
    "pang2"
  ],
  "矻": [
    "ku1"
  ],
  "缓缓": [
    "huan3 huan3"
  ],
  "正宗": [
    "zheng4 zong1"
  ],
  "酌情": [
    "zhuo2 qing2"
  ],
  "獭": [
    "ta3"
  ],
  "腊月": [
    "La4 yue4"
  ],
  "畸": [
    "ji1"
  ],
  "垩": [
    "e4"
  ],
  "恐龙": [
    "kong3 long2"
  ],
  "妲": [
    "Da2"
  ],
  "山": [
    "Shan1",
    "shan1"
  ],
  "卢": [
    "Lu2",
    "lu2"
  ],
  "玃": [
    "jue2"
  ],
  "飞行员": [
    "fei1 xing2 yuan2"
  ],
  "鼬": [
    "you4"
  ],
  "占用": [
    "zhan4 yong4"
  ],
  "欂": [
    "bo2"
  ],
  "反映": [
    "fan3 ying4"
  ],
  "贴": [
    "tie1"
  ],
  "郊区": [
    "Jiao1 Qu1",
    "jiao1 qu1"
  ],
  "须": [
    "xu1",
    "xu1"
  ],
  "羹": [
    "geng1"
  ],
  "噤": [
    "jin4"
  ],
  "倒计时": [
    "dao4 ji4 shi2"
  ],
  "羽": [
    "yu3"
  ],
  "冷酷": [
    "leng3 ku4"
  ],
  "烔": [
    "tong2"
  ],
  "天赋": [
    "tian1 fu4"
  ],
  "认真": [
    "ren4 zhen1"
  ],
  "开车": [
    "kai1 che1"
  ],
  "涯": [
    "ya2"
  ],
  "架势": [
    "jia4 shi5"
  ],
  "分辨": [
    "fen1 bian4"
  ],
  "粗心大意": [
    "cu1 xin1 da4 yi4"
  ],
  "卓": [
    "Zhuo2",
    "zhuo2"
  ],
  "要领": [
    "yao4 ling3"
  ],
  "兰": [
    "Lan2",
    "lan2"
  ],
  "停止": [
    "ting2 zhi3"
  ],
  "乌": [
    "Wu1",
    "wu1"
  ],
  "栋梁": [
    "dong4 liang2"
  ],
  "诗歌": [
    "shi1 ge1"
  ],
  "抽签": [
    "chou1 qian1"
  ],
  "栩": [
    "xu3"
  ],
  "艨": [
    "meng2"
  ],
  "完": [
    "wan2"
  ],
  "不幸": [
    "bu4 xing4"
  ],
  "抛开": [
    "pao1 kai1"
  ],
  "捽": [
    "zuo2"
  ],
  "四处": [
    "si4 chu4"
  ],
  "因素": [
    "yin1 su4"
  ],
  "半天": [
    "ban4 tian1"
  ],
  "看病": [
    "kan4 bing4"
  ],
  "咆": [
    "pao2"
  ],
  "禋": [
    "yin1"
  ],
  "设施": [
    "she4 shi1"
  ],
  "小学生": [
    "xiao3 xue2 sheng1"
  ],
  "角度": [
    "jiao3 du4"
  ],
  "楼": [
    "Lou2",
    "lou2"
  ],
  "传承": [
    "chuan2 cheng2"
  ],
  "就是说": [
    "jiu4 shi4 shuo1"
  ],
  "视": [
    "shi4",
    "shi4",
    "shi4"
  ],
  "站立": [
    "zhan4 li4"
  ],
  "吮": [
    "shun3"
  ],
  "阕": [
    "que4"
  ],
  "糖尿病": [
    "tang2 niao4 bing4"
  ],
  "沉思": [
    "chen2 si1"
  ],
  "罶": [
    "liu3"
  ],
  "卦": [
    "gua4"
  ],
  "方针": [
    "fang1 zhen1"
  ],
  "郁": [
    "yu4",
    "Yu4",
    "yu4",
    "yu4",
    "Yu4",
    "yu4"
  ],
  "官僚主义": [
    "guan1 liao2 zhu3 yi4"
  ],
  "海军": [
    "hai3 jun1"
  ],
  "然而": [
    "ran2 er2"
  ],
  "把柄": [
    "ba3 bing3"
  ],
  "庆典": [
    "qing4 dian3"
  ],
  "躬": [
    "gong1",
    "gong1"
  ],
  "屈服": [
    "qu1 fu2"
  ],
  "碧绿": [
    "bi4 lu:4"
  ],
  "衰老": [
    "shuai1 lao3"
  ],
  "胁": [
    "xie2",
    "xie2"
  ],
  "工资": [
    "gong1 zi1"
  ],
  "大部分": [
    "da4 bu4 fen5"
  ],
  "新鲜": [
    "xin1 xian1"
  ],
  "玚": [
    null
  ],
  "公共": [
    "gong1 gong4"
  ],
  "减速": [
    "jian3 su4"
  ],
  "损坏": [
    "sun3 huai4"
  ],
  "外籍": [
    "wai4 ji2"
  ],
  "讱": [
    "ren4"
  ],
  "信任": [
    "xin4 ren4"
  ],
  "菘": [
    "song1"
  ],
  "景色": [
    "jing3 se4"
  ],
  "随大溜": [
    "sui2 da4 liu4"
  ],
  "子弹": [
    "zi3 dan4"
  ],
  "芋": [
    "yu4"
  ],
  "杂": [
    "za2",
    "za2"
  ],
  "玉米": [
    "yu4 mi3"
  ],
  "视为": [
    "shi4 wei2"
  ],
  "列为": [
    "lie4 wei2"
  ],
  "荒谬": [
    "huang1 miu4"
  ],
  "魅": [
    "mei4"
  ],
  "镎": [
    "na2"
  ],
  "目的地": [
    "mu4 di4 di4"
  ],
  "楸": [
    "qiu1"
  ],
  "帏": [
    "wei2"
  ],
  "凄凉": [
    "qi1 liang2",
    "qi1 liang2"
  ],
  "埴": [
    "zhi2"
  ],
  "状态": [
    "zhuang4 tai4"
  ],
  "锆": [
    "gao4"
  ],
  "位于": [
    "wei4 yu2"
  ],
  "朸": [
    null
  ],
  "接着": [
    "jie1 zhe5"
  ],
  "寓言": [
    "yu4 yan2"
  ],
  "毽": [
    "jian4"
  ],
  "座": [
    "zuo4"
  ],
  "回忆": [
    "hui2 yi4"
  ],
  "外衣": [
    "wai4 yi1"
  ],
  "撵": [
    "nian3"
  ],
  "经贸": [
    "jing1 mao4"
  ],
  "精力": [
    "jing1 li4"
  ],
  "果园": [
    "guo3 yuan2"
  ],
  "痉": [
    "jing4"
  ],
  "瑆": [
    null
  ],
  "移交": [
    "yi2 jiao1"
  ],
  "尽情": [
    "jin4 qing2"
  ],
  "尸体": [
    "shi1 ti3"
  ],
  "民族": [
    "min2 zu2"
  ],
  "事": [
    "shi4"
  ],
  "纯": [
    "chun2"
  ],
  "逅": [
    "hou4"
  ],
  "睁": [
    "zheng1"
  ],
  "倒数": [
    "dao4 shu3",
    "dao4 shu4"
  ],
  "国籍": [
    "guo2 ji2"
  ],
  "分别": [
    "fen1 bie2"
  ],
  "噜": [
    "lu1"
  ],
  "束": [
    "Shu4",
    "shu4"
  ],
  "原则": [
    "yuan2 ze2"
  ],
  "踱": [
    "duo2"
  ],
  "溟": [
    "ming2"
  ],
  "箕": [
    "ji1"
  ],
  "艰辛": [
    "jian1 xin1"
  ],
  "素食": [
    "su4 shi2"
  ],
  "郓": [
    "yun4"
  ],
  "棉花": [
    "mian2 hua5"
  ],
  "除非": [
    "chu2 fei1"
  ],
  "灼": [
    "zhuo2"
  ],
  "凭着": [
    "ping2 zhe5"
  ],
  "衠": [
    null
  ],
  "财": [
    "cai2"
  ],
  "线索": [
    "xian4 suo3"
  ],
  "糯": [
    "nuo4",
    "nuo4",
    "nuo4"
  ],
  "鲭": [
    "qing1",
    "zheng1"
  ],
  "记号": [
    "ji4 hao5"
  ],
  "盒": [
    "he2"
  ],
  "真空": [
    "zhen1 kong1"
  ],
  "流入": [
    "liu2 ru4"
  ],
  "等候": [
    "deng3 hou4"
  ],
  "逝世": [
    "shi4 shi4"
  ],
  "受害": [
    "shou4 hai4"
  ],
  "按时": [
    "an4 shi2"
  ],
  "人文": [
    "ren2 wen2"
  ],
  "勿": [
    "wu4"
  ],
  "撩": [
    "liao1",
    "liao2"
  ],
  "合伙": [
    "he2 huo3"
  ],
  "霍": [
    "Huo4",
    "huo4"
  ],
  "校": [
    "jiao4",
    "xiao4"
  ],
  "倬": [
    "zhuo1"
  ],
  "喳": [
    "cha1",
    "zha1"
  ],
  "洛": [
    "Luo4",
    "luo4"
  ],
  "馧": [
    null
  ],
  "玲": [
    "ling2"
  ],
  "卫生": [
    "wei4 sheng1"
  ],
  "命题": [
    "ming4 ti2"
  ],
  "堌": [
    "gu4"
  ],
  "灯笼": [
    "deng1 long2"
  ],
  "狂欢节": [
    "kuang2 huan1 jie2"
  ],
  "瑚": [
    "hu2"
  ],
  "植": [
    "zhi2"
  ],
  "借鉴": [
    "jie4 jian4"
  ],
  "经历": [
    "jing1 li4"
  ],
  "沼": [
    "zhao3"
  ],
  "一眼": [
    "yi1 yan3"
  ],
  "性": [
    "xing4"
  ],
  "留言": [
    "liu2 yan2"
  ],
  "下降": [
    "xia4 jiang4"
  ],
  "这么": [
    "zhe4 me5"
  ],
  "炌": [
    "kai4"
  ],
  "进": [
    "jin4"
  ],
  "哎呀": [
    "ai1 ya1"
  ],
  "母鸡": [
    "mu3 ji1"
  ],
  "訾": [
    "Zi1",
    "zi1",
    "zi3"
  ],
  "功臣": [
    "gong1 chen2"
  ],
  "过早": [
    "guo4 zao3"
  ],
  "改编": [
    "gai3 bian1"
  ],
  "据悉": [
    "ju4 xi1"
  ],
  "重叠": [
    "chong2 die2"
  ],
  "娱": [
    "yu2"
  ],
  "教师": [
    "jiao4 shi1"
  ],
  "逐年": [
    "zhu2 nian2"
  ],
  "昼": [
    "zhou4"
  ],
  "絷": [
    "zhi2"
  ],
  "急需": [
    "ji2 xu1"
  ],
  "㳚": [
    null
  ],
  "民歌": [
    "min2 ge1"
  ],
  "甘心": [
    "gan1 xin1"
  ],
  "萏": [
    "dan4"
  ],
  "诉说": [
    "su4 shuo1"
  ],
  "闰": [
    "run4"
  ],
  "膘": [
    "biao1",
    "biao1"
  ],
  "池": [
    "Chi2",
    "chi2"
  ],
  "湴": [
    "ban4",
    "pan2"
  ],
  "需要": [
    "xu1 yao4"
  ],
  "鳒": [
    "jian1"
  ],
  "胪": [
    "lu2"
  ],
  "审查": [
    "shen3 cha2"
  ],
  "坏人": [
    "huai4 ren2"
  ],
  "屿": [
    "yu3"
  ],
  "煨": [
    "wei1"
  ],
  "一锅粥": [
    "yi1 guo1 zhou1"
  ],
  "头部": [
    "tou2 bu4"
  ],
  "驱逐": [
    "qu1 zhu2"
  ],
  "姤": [
    "gou4"
  ],
  "出事": [
    "chu1 shi4"
  ],
  "唯独": [
    "wei2 du2"
  ],
  "隔离": [
    "ge2 li2"
  ],
  "毛衣": [
    "mao2 yi1"
  ],
  "联络": [
    "lian2 luo4"
  ],
  "纯朴": [
    "chun2 pu3"
  ],
  "㭕": [
    null
  ],
  "裥": [
    "jian3",
    "jian3"
  ],
  "伛": [
    "yu3"
  ],
  "废寝忘食": [
    "fei4 qin3 - wang4 shi2"
  ],
  "牟": [
    "Mou2",
    "Mu4",
    "mou2"
  ],
  "遴": [
    "Lin2",
    "lin2"
  ],
  "枥": [
    "li4"
  ],
  "中国": [
    "Zhong1 guo2"
  ],
  "鬏": [
    "jiu1"
  ],
  "提议": [
    "ti2 yi4"
  ],
  "粽子": [
    "zong4 zi5"
  ],
  "那儿": [
    "na4 r5"
  ],
  "师生": [
    "shi1 sheng1"
  ],
  "孵": [
    "fu1"
  ],
  "真诚": [
    "zhen1 cheng2"
  ],
  "㬊": [
    null
  ],
  "歌曲": [
    "ge1 qu3"
  ],
  "接受": [
    "jie1 shou4"
  ],
  "里面": [
    "li3 mian4"
  ],
  "团体": [
    "tuan2 ti3"
  ],
  "相反": [
    "xiang1 fan3"
  ],
  "仲": [
    "Zhong4",
    "zhong4"
  ],
  "析": [
    "xi1"
  ],
  "洮": [
    "tao2"
  ],
  "代理": [
    "dai4 li3"
  ],
  "噌": [
    "ceng1",
    "cheng1"
  ],
  "跛": [
    "bo3"
  ],
  "疖": [
    "jie1"
  ],
  "奴隶": [
    "nu2 li4"
  ],
  "大幅度": [
    "da4 fu2 du4"
  ],
  "鏖": [
    "ao2"
  ],
  "易": [
    "Yi4",
    "yi4"
  ],
  "格格不入": [
    "ge2 ge2 bu4 ru4"
  ],
  "硼": [
    "peng2"
  ],
  "纵": [
    "zong4"
  ],
  "菜": [
    "cai4"
  ],
  "挡": [
    "dang3",
    "dang4",
    "dang3"
  ],
  "标题": [
    "biao1 ti2"
  ],
  "散发": [
    "san4 fa1"
  ],
  "眩": [
    "xuan4"
  ],
  "鲙": [
    "kuai4"
  ],
  "粑": [
    "ba1"
  ],
  "野营": [
    "ye3 ying2"
  ],
  "会诊": [
    "hui4 zhen3"
  ],
  "樵": [
    "qiao2"
  ],
  "莽": [
    "mang3"
  ],
  "背": [
    "bei1",
    "bei1",
    "bei4"
  ],
  "错": [
    "Cuo4",
    "cuo4"
  ],
  "风云": [
    "feng1 yun2"
  ],
  "练": [
    "lian4"
  ],
  "现状": [
    "xian4 zhuang4"
  ],
  "旅途": [
    "lu:3 tu2"
  ],
  "素": [
    "su4"
  ],
  "婿": [
    "xu4",
    "xu4"
  ],
  "谥": [
    "shi4",
    "shi4",
    "yi4"
  ],
  "意愿": [
    "yi4 yuan4"
  ],
  "型": [
    "xing2"
  ],
  "缠": [
    "chan2"
  ],
  "英镑": [
    "Ying1 bang4"
  ],
  "寇": [
    "kou4",
    "kou4",
    "kou4"
  ],
  "背诵": [
    "bei4 song4"
  ],
  "藐": [
    "miao3"
  ],
  "二维码": [
    "er4 wei2 ma3"
  ],
  "出人意料": [
    "chu1 ren2 yi4 liao4"
  ],
  "没准儿": [
    "mei2 zhun3 r5"
  ],
  "外表": [
    "wai4 biao3"
  ],
  "脶": [
    "luo2"
  ],
  "捉": [
    "zhuo1"
  ],
  "屡": [
    "lu:3"
  ],
  "夜校": [
    "ye4 xiao4"
  ],
  "队": [
    "dui4"
  ],
  "准": [
    "zhun3",
    "zhun3"
  ],
  "讽刺": [
    "feng3 ci4"
  ],
  "构": [
    "gou4",
    "gou4"
  ],
  "夏天": [
    "xia4 tian1"
  ],
  "潜在": [
    "qian2 zai4"
  ],
  "研发": [
    "yan2 fa1"
  ],
  "毁灭": [
    "hui3 mie4"
  ],
  "锶": [
    "si1"
  ],
  "腿": [
    "tui3",
    "tui3"
  ],
  "实在": [
    "shi2 zai4"
  ],
  "艘": [
    "sou1"
  ],
  "诨": [
    "hun4"
  ],
  "灈": [
    "Qu2"
  ],
  "粉": [
    "fen3"
  ],
  "缺口": [
    "que1 kou3"
  ],
  "蕾": [
    "lei3"
  ],
  "周": [
    "Zhou1",
    "zhou1",
    "zhou1"
  ],
  "改动": [
    "gai3 dong4"
  ],
  "橙": [
    "cheng2"
  ],
  "淀粉": [
    "dian4 fen3"
  ],
  "藏": [
    "Zang4",
    "cang2",
    "zang4"
  ],
  "姻": [
    "yin1",
    "yin1"
  ],
  "忍心": [
    "ren3 xin1"
  ],
  "打电话": [
    "da3 dian4 hua4"
  ],
  "苦难": [
    "ku3 nan4"
  ],
  "空气": [
    "kong1 qi4"
  ],
  "行情": [
    "hang2 qing2"
  ],
  "得体": [
    "de2 ti3"
  ],
  "扶持": [
    "fu2 chi2"
  ],
  "东张西望": [
    "dong1 zhang1 xi1 wang4"
  ],
  "停车位": [
    "ting2 che1 wei4"
  ],
  "胶片": [
    "jiao1 pian4"
  ],
  "一旁": [
    "yi1 pang2"
  ],
  "璪": [
    "zao3"
  ],
  "马路": [
    "ma3 lu4"
  ],
  "劐": [
    "huo1",
    "huo4"
  ],
  "延长": [
    "Yan2 chang2",
    "yan2 chang2"
  ],
  "指向": [
    "zhi3 xiang4"
  ],
  "轮廓": [
    "lun2 kuo4"
  ],
  "男生": [
    "nan2 sheng1"
  ],
  "取经": [
    "qu3 jing1"
  ],
  "深入人心": [
    "shen1 ru4 ren2 xin1"
  ],
  "搓": [
    "cuo1"
  ],
  "厚道": [
    "hou4 dao5"
  ],
  "星": [
    "xing1"
  ],
  "佛教": [
    "Fo2 jiao4"
  ],
  "燹": [
    "xian3"
  ],
  "纪录": [
    "ji4 lu4"
  ],
  "洁": [
    "jie2"
  ],
  "公函": [
    "gong1 han2"
  ],
  "体力": [
    "ti3 li4"
  ],
  "转交": [
    "zhuan3 jiao1"
  ],
  "羸": [
    "lei2"
  ],
  "事先": [
    "shi4 xian1"
  ],
  "厕": [
    "ce4",
    "ce4",
    "si4"
  ],
  "舻": [
    "lu2"
  ],
  "拐杖": [
    "guai3 zhang4"
  ],
  "弄": [
    "long4",
    "nong4",
    "nong4",
    "long4"
  ],
  "取代": [
    "qu3 dai4"
  ],
  "过瘾": [
    "guo4 yin3"
  ],
  "唐": [
    "Tang2",
    "tang2"
  ],
  "鹕": [
    "hu2"
  ],
  "政": [
    "zheng4"
  ],
  "酣睡": [
    "han1 shui4"
  ],
  "娄": [
    "Lou2"
  ],
  "受过": [
    "shou4 guo4"
  ],
  "奏": [
    "zou4"
  ],
  "鞁": [
    "bei4"
  ],
  "其他": [
    "qi2 ta1"
  ],
  "祁": [
    "Qi2",
    "qi2"
  ],
  "妥": [
    "tuo3"
  ],
  "百货": [
    "bai3 huo4"
  ],
  "刻": [
    "ke4"
  ],
  "平安": [
    "ping2 an1"
  ],
  "脸颊": [
    "lian3 jia2"
  ],
  "层面": [
    "ceng2 mian4"
  ],
  "之": [
    "zhi1"
  ],
  "暂": [
    "zan4",
    "zan4"
  ],
  "湜": [
    "shi2"
  ],
  "为": [
    "wei2",
    "wei4",
    "wei2",
    "wei4"
  ],
  "饲料": [
    "si4 liao4"
  ],
  "抵达": [
    "di3 da2"
  ],
  "刘": [
    "Liu2",
    "liu2"
  ],
  "红茶": [
    "hong2 cha2"
  ],
  "脚": [
    "jiao3",
    "jue2"
  ],
  "汞": [
    "gong3",
    "gong3"
  ],
  "垟": [
    "yang2"
  ],
  "谆": [
    "zhun1"
  ],
  "觫": [
    "su4"
  ],
  "愚蠢": [
    "yu2 chun3"
  ],
  "雷": [
    "Lei2",
    "lei2"
  ],
  "三": [
    "San1",
    "san1"
  ],
  "巳": [
    "si4"
  ],
  "小型": [
    "xiao3 xing2"
  ],
  "股市": [
    "gu3 shi4"
  ],
  "没落": [
    "mo4 luo4"
  ],
  "腐败": [
    "fu3 bai4"
  ],
  "黎": [
    "Li2",
    "li2"
  ],
  "过往": [
    "guo4 wang3"
  ],
  "甘": [
    "Gan1",
    "gan1"
  ],
  "弩": [
    "nu3"
  ],
  "惑": [
    "huo4"
  ],
  "䓨": [
    null
  ],
  "潦草": [
    "liao2 cao3"
  ],
  "筀": [
    "gui4"
  ],
  "溶解": [
    "rong2 jie3"
  ],
  "赚": [
    "zhuan4",
    "zuan4"
  ],
  "师父": [
    "shi1 fu5"
  ],
  "湃": [
    "pai4"
  ],
  "嗪": [
    "qin2"
  ],
  "路灯": [
    "lu4 deng1"
  ],
  "活动": [
    "huo2 dong4"
  ],
  "牌子": [
    "pai2 zi5"
  ],
  "诎": [
    "qu1"
  ],
  "赌博": [
    "du3 bo2"
  ],
  "摹": [
    "mo2"
  ],
  "月票": [
    "yue4 piao4"
  ],
  "缙": [
    "jin4"
  ],
  "陔": [
    "gai1"
  ],
  "缮": [
    "shan4"
  ],
  "暝": [
    "ming2"
  ],
  "诚": [
    "cheng2"
  ],
  "漆": [
    "qi1"
  ],
  "气质": [
    "qi4 zhi4"
  ],
  "儒家": [
    "Ru2 jia1"
  ],
  "启事": [
    "qi3 shi4"
  ],
  "鹱": [
    "hu4"
  ],
  "嫦娥": [
    "Chang2 e2"
  ],
  "剕": [
    "fei4"
  ],
  "能不能": [
    null
  ],
  "增": [
    "zeng1"
  ],
  "滑": [
    "Hua2",
    "hua2"
  ],
  "绗": [
    "hang2"
  ],
  "湓": [
    "pen2"
  ],
  "关闭": [
    "guan1 bi4"
  ],
  "螟": [
    "ming2"
  ],
  "牵头": [
    "qian1 tou2"
  ],
  "堲": [
    "ji2"
  ],
  "喘息": [
    "chuan3 xi1"
  ],
  "棘": [
    "ji2"
  ],
  "氓": [
    "mang2",
    "meng2"
  ],
  "嫱": [
    "qiang2"
  ],
  "佶": [
    "ji2"
  ],
  "部": [
    "bu4"
  ],
  "日夜": [
    "ri4 ye4"
  ],
  "责任": [
    "ze2 ren4"
  ],
  "骨": [
    "gu3"
  ],
  "侗": [
    "Dong4",
    "tong2"
  ],
  "莱": [
    "lai2"
  ],
  "求": [
    "qiu2"
  ],
  "敬酒": [
    "jing4 jiu3"
  ],
  "捃": [
    "jun4"
  ],
  "红": [
    "Hong2",
    "hong2"
  ],
  "伪": [
    "wei3",
    "wei3"
  ],
  "茫": [
    "mang2"
  ],
  "刃": [
    "ren4"
  ],
  "增收": [
    "zeng1 shou1"
  ],
  "释": [
    "shi4"
  ],
  "陆": [
    "Lu4",
    "liu4",
    "lu4"
  ],
  "来": [
    "lai2"
  ],
  "扎": [
    "zha1",
    "zha2",
    "za1",
    "zha1",
    "za1",
    "zha1"
  ],
  "完了": [
    "wan2 le5"
  ],
  "仅": [
    "jin3"
  ],
  "后期": [
    "hou4 qi1"
  ],
  "瘼": [
    "mo4"
  ],
  "荸": [
    "bi2"
  ],
  "忪": [
    "song1",
    "zhong1"
  ],
  "樱": [
    "ying1"
  ],
  "恩赐": [
    "en1 ci4"
  ],
  "铟": [
    "yin1"
  ],
  "辖": [
    "xia2"
  ],
  "昣": [
    "zhen3"
  ],
  "厕所": [
    "ce4 suo3"
  ],
  "不利于": [
    null
  ],
  "拘留": [
    "ju1 liu2"
  ],
  "跐": [
    "ci3"
  ],
  "出卖": [
    "chu1 mai4"
  ],
  "核桃": [
    "he2 tao5"
  ],
  "挺": [
    "ting3"
  ],
  "破坏": [
    "po4 huai4"
  ],
  "惟": [
    "wei2"
  ],
  "唝": [
    "gong4",
    "hong3"
  ],
  "花园": [
    "hua1 yuan2"
  ],
  "有效": [
    "you3 xiao4"
  ],
  "谑": [
    "xue4"
  ],
  "铸": [
    "zhu4"
  ],
  "密": [
    "Mi4",
    "mi4"
  ],
  "诸": [
    "Zhu1",
    "zhu1"
  ],
  "可能": [
    "ke3 neng2"
  ],
  "推开": [
    "tui1 kai1"
  ],
  "红火": [
    "hong2 huo3"
  ],
  "浕": [
    "Jin4"
  ],
  "潭": [
    "Tan2",
    "tan2"
  ],
  "绍": [
    "Shao4",
    "shao4"
  ],
  "盲人": [
    "mang2 ren2"
  ],
  "缴纳": [
    "jiao3 na4"
  ],
  "冒充": [
    "mao4 chong1"
  ],
  "郤": [
    "Xi4",
    "xi4"
  ],
  "隶": [
    "li4",
    "li4"
  ],
  "耏": [
    "Er2",
    "er2"
  ],
  "出名": [
    "chu1 ming2"
  ],
  "凋": [
    "diao1"
  ],
  "齐全": [
    "qi2 quan2"
  ],
  "异议": [
    "yi4 yi4"
  ],
  "峧": [
    "jiao1"
  ],
  "前辈": [
    "qian2 bei4"
  ],
  "沙滩": [
    "sha1 tan1"
  ],
  "沓": [
    "Ta4",
    "da2",
    "ta4"
  ],
  "荛": [
    "rao2"
  ],
  "幔": [
    "man4"
  ],
  "望": [
    "wang4",
    "wang4"
  ],
  "瘩": [
    "da2"
  ],
  "女儿": [
    "nu:3 er2"
  ],
  "悲伤": [
    "bei1 shang1"
  ],
  "情报": [
    "qing2 bao4"
  ],
  "妘": [
    "Yun2"
  ],
  "裙子": [
    "qun2 zi5"
  ],
  "共计": [
    "gong4 ji4"
  ],
  "嵩": [
    "song1"
  ],
  "稠密": [
    "chou2 mi4"
  ],
  "推": [
    "tui1"
  ],
  "鳠": [
    "hu4"
  ],
  "火焰": [
    "huo3 yan4"
  ],
  "萌发": [
    "meng2 fa1"
  ],
  "意": [
    "Yi4",
    "yi4"
  ],
  "肋": [
    "lei4"
  ],
  "迂": [
    "yu1"
  ],
  "前后": [
    "qian2 hou4"
  ],
  "睥": [
    "pi4"
  ],
  "委": [
    "Wei3",
    "wei1",
    "wei3"
  ],
  "复制": [
    "fu4 zhi4"
  ],
  "摇": [
    "Yao2",
    "yao2"
  ],
  "橦": [
    null
  ],
  "醇厚": [
    "chun2 hou4"
  ],
  "混合": [
    "hun4 he2"
  ],
  "盼望": [
    "pan4 wang4"
  ],
  "铨": [
    "quan2"
  ],
  "暕": [
    "jian3"
  ],
  "蝴": [
    "hu2"
  ],
  "墅": [
    "shu4"
  ],
  "能源": [
    "neng2 yuan2"
  ],
  "赤": [
    "chi4"
  ],
  "人权": [
    "ren2 quan2"
  ],
  "风流": [
    "feng1 liu2"
  ],
  "关照": [
    "guan1 zhao4"
  ],
  "禁止": [
    "jin4 zhi3"
  ],
  "侴": [
    "Chou3"
  ],
  "大名鼎鼎": [
    "da4 ming2 ding3 ding3"
  ],
  "喜事": [
    "xi3 shi4"
  ],
  "中华": [
    "Zhong1 hua2"
  ],
  "庄": [
    "zhuang1",
    "Zhuang1",
    "zhuang1"
  ],
  "瞿": [
    "Qu2",
    "qu2"
  ],
  "发": [
    "fa1",
    "fa4"
  ],
  "蓝领": [
    "lan2 ling3"
  ],
  "佝": [
    "gou1",
    "kou4"
  ],
  "分担": [
    "fen1 dan1"
  ],
  "毳": [
    "cui4"
  ],
  "零钱": [
    "ling2 qian2"
  ],
  "装饰": [
    "zhuang1 shi4"
  ],
  "飙": [
    "biao1"
  ],
  "辚": [
    "lin2"
  ],
  "偲": [
    "cai1",
    "si1"
  ],
  "用来": [
    "yong4 lai2"
  ],
  "两栖": [
    "liang3 qi1"
  ],
  "匿": [
    "ni4"
  ],
  "替代": [
    "ti4 dai4"
  ],
  "各地": [
    "ge4 di4"
  ],
  "飓": [
    "ju4"
  ],
  "泼冷水": [
    "po1 leng3 shui3"
  ],
  "濡": [
    "ru2"
  ],
  "哈": [
    "Ha1",
    "ha1",
    "ha3"
  ],
  "诡": [
    "gui3"
  ],
  "国庆": [
    "Guo2 qing4"
  ],
  "坥": [
    null
  ],
  "亲热": [
    "qin1 re4"
  ],
  "依法": [
    "yi1 fa3"
  ],
  "安宁": [
    "An1 ning2",
    "an1 ning2"
  ],
  "摄像机": [
    "she4 xiang4 ji1"
  ],
  "垡": [
    "fa2"
  ],
  "萎缩": [
    "wei3 suo1"
  ],
  "调料": [
    "tiao2 liao4"
  ],
  "徒步": [
    "tu2 bu4"
  ],
  "龄": [
    "ling2"
  ],
  "祓": [
    "fu2"
  ],
  "锣": [
    "luo2"
  ],
  "现金": [
    "xian4 jin1"
  ],
  "改造": [
    "gai3 zao4"
  ],
  "调整": [
    "tiao2 zheng3"
  ],
  "器": [
    "qi4"
  ],
  "氖": [
    "nai3"
  ],
  "放下": [
    "fang4 xia4"
  ],
  "瓤": [
    "rang2"
  ],
  "鼱": [
    "jing1"
  ],
  "程度": [
    "cheng2 du4"
  ],
  "键": [
    "jian4"
  ],
  "隔开": [
    "ge2 kai1"
  ],
  "病情": [
    "bing4 qing2"
  ],
  "盥": [
    "guan4"
  ],
  "陬": [
    "zou1"
  ],
  "鄅": [
    "Yu3"
  ],
  "峻": [
    "jun4"
  ],
  "咝": [
    "si1"
  ],
  "腐": [
    "fu3"
  ],
  "颓废": [
    "tui2 fei4"
  ],
  "吻合": [
    "wen3 he2"
  ],
  "精华": [
    "jing1 hua2"
  ],
  "身边": [
    "shen1 bian1"
  ],
  "螗": [
    "tang2"
  ],
  "鳎": [
    "ta3"
  ],
  "新房": [
    "xin1 fang2"
  ],
  "泥潭": [
    "ni2 tan2"
  ],
  "不一会儿": [
    null
  ],
  "污染": [
    "wu1 ran3"
  ],
  "讨厌": [
    "tao3 yan4"
  ],
  "书": [
    "Shu1",
    "shu1"
  ],
  "宽度": [
    "kuan1 du4"
  ],
  "水域": [
    "shui3 yu4"
  ],
  "十足": [
    "shi2 zu2"
  ],
  "私有": [
    "si1 you3"
  ],
  "疸": [
    "da5",
    "dan3"
  ],
  "碗": [
    "wan3",
    "wan3",
    "wan3",
    "wan3"
  ],
  "翚": [
    "hui1"
  ],
  "励志": [
    "li4 zhi4"
  ],
  "彩色": [
    "cai3 se4"
  ],
  "憾": [
    "han4"
  ],
  "内地": [
    "Nei4 di4",
    "nei4 di4"
  ],
  "陴": [
    "pi2"
  ],
  "红灯": [
    "hong2 deng1"
  ],
  "流露": [
    "liu2 lu4"
  ],
  "民俗": [
    "min2 su2"
  ],
  "减压": [
    "jian3 ya1"
  ],
  "放": [
    "fang4"
  ],
  "却是": [
    "que4 shi4"
  ],
  "认得": [
    "ren4 de5"
  ],
  "鼠": [
    "shu3"
  ],
  "升值": [
    "sheng1 zhi2"
  ],
  "珅": [
    "shen1"
  ],
  "旗帜": [
    "qi2 zhi4"
  ],
  "受伤": [
    "shou4 shang1"
  ],
  "让座": [
    "rang4 zuo4"
  ],
  "促": [
    "cu4"
  ],
  "坊": [
    "Fang1",
    "fang1",
    "fang2"
  ],
  "画展": [
    "hua4 zhan3"
  ],
  "凶残": [
    "xiong1 can2",
    "xiong1 can2"
  ],
  "芫": [
    "yan2",
    "yuan2"
  ],
  "悼念": [
    "dao4 nian4"
  ],
  "拖欠": [
    "tuo1 qian4"
  ],
  "梦": [
    "meng4"
  ],
  "有用": [
    "you3 yong4"
  ],
  "螺": [
    "luo2"
  ],
  "奚": [
    "Xi1",
    "xi1"
  ],
  "死心塌地": [
    "si3 xin1 ta1 di4"
  ],
  "掬": [
    "ju1"
  ],
  "误会": [
    "wu4 hui4"
  ],
  "腯": [
    "tu2"
  ],
  "珙": [
    "gong3"
  ],
  "吉": [
    "Ji2",
    "ji2"
  ],
  "檑": [
    "lei2"
  ],
  "脸色": [
    "lian3 se4"
  ],
  "能够": [
    "neng2 gou4"
  ],
  "老字号": [
    "lao3 zi4 hao4"
  ],
  "城里": [
    null
  ],
  "超标": [
    "chao1 biao1"
  ],
  "旧": [
    "jiu4"
  ],
  "坠": [
    "zhui4"
  ],
  "煅": [
    "duan4"
  ],
  "对策": [
    "dui4 ce4"
  ],
  "辞职": [
    "ci2 zhi2"
  ],
  "呼应": [
    "hu1 ying4"
  ],
  "邺": [
    "Ye4"
  ],
  "椸": [
    "yi2"
  ],
  "伥": [
    "chang1"
  ],
  "极端": [
    "ji2 duan1"
  ],
  "面临": [
    "mian4 lin2"
  ],
  "锄": [
    "chu2"
  ],
  "钣": [
    "ban3"
  ],
  "好处": [
    "hao3 chu3",
    "hao3 chu5"
  ],
  "冠": [
    "Guan4",
    "guan1",
    "guan4"
  ],
  "清明": [
    "Qing1 ming2",
    "qing1 ming2"
  ],
  "分开": [
    "fen1 kai1"
  ],
  "罂": [
    "ying1",
    "ying1"
  ],
  "历时": [
    "li4 shi2"
  ],
  "冬季": [
    "dong1 ji4"
  ],
  "周旋": [
    "zhou1 xuan2"
  ],
  "菟": [
    "tu4"
  ],
  "打击": [
    "da3 ji1"
  ],
  "牡": [
    "mu3"
  ],
  "圣诞节": [
    "Sheng4 dan4 jie2"
  ],
  "动画片": [
    "dong4 hua4 pian4"
  ],
  "确认": [
    "que4 ren4"
  ],
  "温暖": [
    "wen1 nuan3"
  ],
  "试": [
    "shi4"
  ],
  "飑": [
    "biao1"
  ],
  "定金": [
    "ding4 jin1"
  ],
  "祈": [
    "qi2"
  ],
  "郑重": [
    "zheng4 zhong4"
  ],
  "注重": [
    "zhu4 zhong4"
  ],
  "图案": [
    "tu2 an4"
  ],
  "父": [
    "fu4"
  ],
  "通话": [
    "tong1 hua4"
  ],
  "媱": [
    null
  ],
  "妪": [
    "yu4"
  ],
  "袒": [
    "tan3",
    "tan3"
  ],
  "簰": [
    "pai2"
  ],
  "祢": [
    "ni3",
    "Mi2",
    "mi2"
  ],
  "荐": [
    "jian4"
  ],
  "投诉": [
    "tou2 su4"
  ],
  "搬": [
    "ban1"
  ],
  "圄": [
    "yu3"
  ],
  "规格": [
    "gui1 ge2"
  ],
  "锿": [
    "ai1"
  ],
  "岁数": [
    "sui4 shu5"
  ],
  "纠缠": [
    "jiu1 chan2"
  ],
  "单": [
    "Shan4",
    "dan1"
  ],
  "渡": [
    "du4"
  ],
  "下旬": [
    "xia4 xun2"
  ],
  "毫无": [
    "hao2 wu2"
  ],
  "乖巧": [
    "guai1 qiao3"
  ],
  "酣畅": [
    "han1 chang4"
  ],
  "槠": [
    "zhu1"
  ],
  "柘": [
    "zhe4"
  ],
  "䓖": [
    "qiong2"
  ],
  "挑衅": [
    "tiao3 xin4"
  ],
  "决": [
    "jue2"
  ],
  "触觉": [
    "chu4 jue2"
  ],
  "吉利": [
    "Ji2 li4",
    "ji2 li4"
  ],
  "婀": [
    "e1",
    "e1"
  ],
  "动向": [
    "dong4 xiang4"
  ],
  "缝合": [
    "feng2 he2"
  ],
  "结束": [
    "jie2 shu4"
  ],
  "忌": [
    "ji4"
  ],
  "坤": [
    "kun1",
    "kun1"
  ],
  "喘": [
    "chuan3"
  ],
  "硔": [
    null
  ],
  "织": [
    "zhi1"
  ],
  "如果说": [
    "ru2 guo3 shuo1"
  ],
  "育": [
    "yu4"
  ],
  "匜": [
    "yi2"
  ],
  "要命": [
    "yao4 ming4"
  ],
  "玩耍": [
    "wan2 shua3"
  ],
  "亲生": [
    "qin1 sheng1"
  ],
  "录音": [
    "lu4 yin1"
  ],
  "蜣": [
    "qiang1"
  ],
  "淼": [
    "miao3"
  ],
  "素质": [
    "su4 zhi4"
  ],
  "藉": [
    "Ji2",
    "ji2",
    "jie4"
  ],
  "无限": [
    "wu2 xian4"
  ],
  "消化": [
    "xiao1 hua4"
  ],
  "一长一短": [
    "yi1 chang2 - yi1 duan3"
  ],
  "叛逆": [
    "pan4 ni4"
  ],
  "识": [
    "shi2",
    "zhi4"
  ],
  "卟": [
    "bu3"
  ],
  "手掌": [
    "shou3 zhang3"
  ],
  "锡": [
    "xi1"
  ],
  "噙": [
    "qin2"
  ],
  "垫子": [
    "dian4 zi5"
  ],
  "沼泽": [
    "zhao3 ze2"
  ],
  "好友": [
    "hao3 you3"
  ],
  "龌": [
    "wo4"
  ],
  "从业": [
    "cong2 ye4"
  ],
  "墁": [
    "man4"
  ],
  "塌": [
    "ta1"
  ],
  "烦恼": [
    "fan2 nao3"
  ],
  "拨款": [
    "bo1 kuan3"
  ],
  "夂": [
    "zhi3"
  ],
  "熬": [
    "ao1",
    "ao2"
  ],
  "聊": [
    "liao2"
  ],
  "向": [
    "Xiang4",
    "xiang4",
    "xiang4",
    "xiang4"
  ],
  "参见": [
    "can1 jian4"
  ],
  "枪": [
    "Qiang1",
    "qiang1",
    "qiang1"
  ],
  "真心": [
    "zhen1 xin1"
  ],
  "叛": [
    "pan4"
  ],
  "晴天": [
    "qing2 tian1"
  ],
  "客": [
    "ke4"
  ],
  "心愿": [
    "xin1 yuan4"
  ],
  "乜": [
    "Nie4",
    "mie1"
  ],
  "嫂子": [
    "sao3 zi5"
  ],
  "质": [
    "zhi4"
  ],
  "旴": [
    "xu1"
  ],
  "钾": [
    "jia3"
  ],
  "疑问": [
    "yi2 wen4"
  ],
  "地狱": [
    "di4 yu4"
  ],
  "冷战": [
    "Leng3 zhan4",
    "leng3 zhan4",
    "leng3 zhan5"
  ],
  "汀": [
    "ting1"
  ],
  "既然": [
    "ji4 ran2"
  ],
  "瓒": [
    "zan4"
  ],
  "介于": [
    "jie4 yu2"
  ],
  "拔": [
    "ba2"
  ],
  "措手不及": [
    "cuo4 shou3 bu4 ji2"
  ],
  "金属": [
    "jin1 shu3"
  ],
  "任命": [
    "ren4 ming4"
  ],
  "召开": [
    "zhao4 kai1"
  ],
  "腙": [
    "zong1"
  ],
  "砝": [
    "fa3"
  ],
  "琏": [
    "lian3"
  ],
  "分成": [
    "fen1 cheng2"
  ],
  "棽": [
    null
  ],
  "至此": [
    "zhi4 ci3"
  ],
  "硕士": [
    "shuo4 shi4"
  ],
  "呵": [
    "a1",
    "he1"
  ],
  "霎": [
    "sha4"
  ],
  "彝": [
    "yi2"
  ],
  "楔": [
    "xie1"
  ],
  "逆": [
    "ni4"
  ],
  "槁": [
    "gao3",
    "gao3"
  ],
  "恐吓": [
    "kong3 he4"
  ],
  "紧急": [
    "jin3 ji2"
  ],
  "退却": [
    "tui4 que4"
  ],
  "床": [
    "chuang2",
    "chuang2"
  ],
  "进展": [
    "jin4 zhan3"
  ],
  "蹴": [
    "cu4",
    "cu4"
  ],
  "采购": [
    "cai3 gou4"
  ],
  "月": [
    "yue4"
  ],
  "迈": [
    "mai4"
  ],
  "培训班": [
    "pei2 xun4 ban1"
  ],
  "幸": [
    "xing4",
    "Xing4",
    "xing4"
  ],
  "熟悉": [
    "shu2 xi1"
  ],
  "跣": [
    "xian3"
  ],
  "伙": [
    "huo3",
    "huo3"
  ],
  "赦": [
    "she4"
  ],
  "刁": [
    "Diao1",
    "diao1"
  ],
  "滞后": [
    "zhi4 hou4"
  ],
  "埒": [
    "lie4"
  ],
  "榻": [
    "ta4"
  ],
  "母女": [
    "mu3 nu:3"
  ],
  "涫": [
    "guan4"
  ],
  "半夜": [
    "ban4 ye4"
  ],
  "本来": [
    "ben3 lai2"
  ],
  "黧": [
    "li2"
  ],
  "题目": [
    "ti2 mu4"
  ],
  "堡": [
    "bao3",
    "bu3",
    "pu4"
  ],
  "工商": [
    "gong1 shang1"
  ],
  "蝤": [
    "qiu2"
  ],
  "瑜": [
    "yu2"
  ],
  "劢": [
    "mai4"
  ],
  "奡": [
    "ao4"
  ],
  "掏钱": [
    "tao1 qian2"
  ],
  "钉": [
    "ding1",
    "ding4"
  ],
  "增值": [
    "zeng1 zhi2"
  ],
  "吹": [
    "chui1"
  ],
  "冀": [
    "Ji4",
    "ji4"
  ],
  "术": [
    "shu4",
    "zhu2"
  ],
  "除去": [
    "chu2 qu4"
  ],
  "魁": [
    "kui2"
  ],
  "筢": [
    "pa2"
  ],
  "决心": [
    "jue2 xin1"
  ],
  "焦距": [
    "jiao1 ju4"
  ],
  "铼": [
    "lai2"
  ],
  "形态": [
    "xing2 tai4"
  ],
  "箪": [
    "dan1"
  ],
  "髂": [
    "qia4"
  ],
  "一早": [
    "yi1 zao3"
  ],
  "绶": [
    "shou4"
  ],
  "修养": [
    "xiu1 yang3"
  ],
  "寥": [
    "liao2"
  ],
  "桂花": [
    "gui4 hua1"
  ],
  "障": [
    "zhang4"
  ],
  "铅笔": [
    "qian1 bi3"
  ],
  "叭": [
    "ba1"
  ],
  "信箱": [
    "xin4 xiang1"
  ],
  "橛": [
    "jue2",
    "jue2"
  ],
  "畖": [
    null
  ],
  "估计": [
    "gu1 ji4"
  ],
  "龇": [
    "zi1"
  ],
  "信件": [
    "xin4 jian4"
  ],
  "牵扯": [
    "qian1 che3"
  ],
  "处于": [
    "chu3 yu2"
  ],
  "要素": [
    "yao4 su4"
  ],
  "马虎": [
    "ma3 hu5"
  ],
  "拒": [
    "ju4"
  ],
  "貔": [
    "pi2"
  ],
  "格局": [
    "ge2 ju2"
  ],
  "迕": [
    "wu3"
  ],
  "儇": [
    "xuan1"
  ],
  "裾": [
    "ju1"
  ],
  "冼": [
    "Xian3"
  ],
  "咿": [
    "yi1",
    "yi1"
  ],
  "倾向": [
    "qing1 xiang4"
  ],
  "丧": [
    "sang1",
    "sang4"
  ],
  "闩": [
    "shuan1"
  ],
  "操控": [
    "cao1 kong4"
  ],
  "跑车": [
    "pao3 che1"
  ],
  "子": [
    "zi3",
    "zi5"
  ],
  "残疾人": [
    "can2 ji2 ren2"
  ],
  "酸甜苦辣": [
    "suan1 tian2 ku3 la4"
  ],
  "檎": [
    "qin2"
  ],
  "袭": [
    "xi2"
  ],
  "卡": [
    "ka3",
    "qia3"
  ],
  "菠": [
    "bo1"
  ],
  "蹦": [
    "beng4"
  ],
  "将": [
    "jiang1",
    "jiang4",
    "qiang1"
  ],
  "猁": [
    "li4"
  ],
  "瓷器": [
    "ci2 qi4"
  ],
  "框架": [
    "kuang4 jia4"
  ],
  "一技之长": [
    "yi1 ji4 zhi1 chang2"
  ],
  "宿": [
    "su4",
    "Su4",
    "su4",
    "xiu3",
    "xiu4"
  ],
  "一回事": [
    "yi1 hui2 shi4"
  ],
  "嬉": [
    "xi1"
  ],
  "鬃": [
    "zong1",
    "zong1",
    "zong1"
  ],
  "怎样": [
    "zen3 yang4"
  ],
  "匿名": [
    "ni4 ming2"
  ],
  "会场": [
    "hui4 chang3"
  ],
  "面条儿": [
    "mian4 tiao2 r5"
  ],
  "咙": [
    "long2"
  ],
  "侑": [
    "you4"
  ],
  "密不可分": [
    "mi4 bu4 ke3 fen1"
  ],
  "咚": [
    "dong1"
  ],
  "垫底": [
    "dian4 di3"
  ],
  "柊": [
    "zhong1"
  ],
  "靶": [
    "ba3"
  ],
  "亲切": [
    "qin1 qie4"
  ],
  "王": [
    "Wang2",
    "wang2",
    "wang4"
  ],
  "霆": [
    "ting2"
  ],
  "揭": [
    "Jie1",
    "jie1"
  ],
  "敷": [
    "fu1"
  ],
  "敢": [
    "gan3"
  ],
  "粞": [
    "xi1"
  ],
  "梦见": [
    "meng4 jian4"
  ],
  "采用": [
    "cai3 yong4"
  ],
  "矮": [
    "ai3"
  ],
  "锏": [
    "jian3"
  ],
  "救援": [
    "jiu4 yuan2"
  ],
  "蛛": [
    "zhu1"
  ],
  "禅": [
    "chan2",
    "shan4"
  ],
  "群体": [
    "qun2 ti3"
  ],
  "雱": [
    "pang1"
  ],
  "素材": [
    "su4 cai2"
  ],
  "轴": [
    "zhou2",
    "zhou4"
  ],
  "朋友": [
    "peng2 you5"
  ],
  "宝库": [
    "bao3 ku4"
  ],
  "秘诀": [
    "mi4 jue2"
  ],
  "藟": [
    "lei3"
  ],
  "狴": [
    "bi4"
  ],
  "刖": [
    "yue4"
  ],
  "胚胎": [
    "pei1 tai1"
  ],
  "磉": [
    "sang3"
  ],
  "削弱": [
    "xue1 ruo4"
  ],
  "制定": [
    "zhi4 ding4"
  ],
  "牖": [
    "you3"
  ],
  "数字": [
    "shu4 zi4"
  ],
  "瑙": [
    "nao3"
  ],
  "罚款": [
    "fa2 kuan3"
  ],
  "招聘": [
    "zhao1 pin4"
  ],
  "名言": [
    "ming2 yan2"
  ],
  "完善": [
    "wan2 shan4"
  ],
  "用处": [
    "yong4 chu5"
  ],
  "防盗门": [
    "fang2 dao4 men2"
  ],
  "刊登": [
    "kan1 deng1"
  ],
  "上台": [
    "shang4 tai2"
  ],
  "闺女": [
    "gui1 nu:5"
  ],
  "银行": [
    "yin2 hang2"
  ],
  "禁忌": [
    "jin4 ji4"
  ],
  "盏": [
    "zhan3"
  ],
  "现成": [
    "xian4 cheng2"
  ],
  "反击": [
    "fan3 ji1"
  ],
  "丕": [
    "pi1"
  ],
  "喉": [
    "hou2"
  ],
  "退票": [
    "tui4 piao4"
  ],
  "觖": [
    "jue2"
  ],
  "修补": [
    "xiu1 bu3"
  ],
  "配备": [
    "pei4 bei4"
  ],
  "酸": [
    "suan1"
  ],
  "秫": [
    "shu2"
  ],
  "预售": [
    "yu4 shou4"
  ],
  "出版社": [
    "chu1 ban3 she4"
  ],
  "摆脱": [
    "bai3 tuo1"
  ],
  "颔": [
    "han4"
  ],
  "鲖": [
    "tong2"
  ],
  "负": [
    "fu4"
  ],
  "拳头": [
    "quan2 tou5"
  ],
  "完美": [
    "wan2 mei3"
  ],
  "停顿": [
    "ting2 dun4"
  ],
  "酶": [
    "mei2"
  ],
  "裸露": [
    "luo3 lu4"
  ],
  "工艺": [
    "gong1 yi4"
  ],
  "日报": [
    "ri4 bao4"
  ],
  "豕": [
    "shi3"
  ],
  "歌唱": [
    "ge1 chang4"
  ],
  "铗": [
    "jia2"
  ],
  "一心": [
    "yi1 xin1"
  ],
  "地带": [
    "di4 dai4"
  ],
  "普": [
    "pu3"
  ],
  "列入": [
    "lie4 ru4"
  ],
  "容光焕发": [
    "rong2 guang1 huan4 fa1"
  ],
  "肆": [
    "si4"
  ],
  "湾": [
    "wan1"
  ],
  "辜负": [
    "gu1 fu4"
  ],
  "解救": [
    "jie3 jiu4"
  ],
  "钻空子": [
    "zuan1 kong4 zi5"
  ],
  "㑇": [
    "zhou4"
  ],
  "疠": [
    "li4"
  ],
  "思念": [
    "si1 nian4"
  ],
  "之后": [
    "zhi1 hou4"
  ],
  "擢": [
    "zhuo2"
  ],
  "过来": [
    "guo4 lai2",
    "guo4 lai5"
  ],
  "树木": [
    "shu4 mu4"
  ],
  "惩罚": [
    "cheng2 fa2"
  ],
  "匾": [
    "bian3",
    "pian2"
  ],
  "笆": [
    "ba1"
  ],
  "脊梁": [
    "ji3 liang2",
    "ji3 liang5"
  ],
  "馇": [
    "cha1",
    "zha5"
  ],
  "嫒": [
    "ai4"
  ],
  "零食": [
    "ling2 shi2"
  ],
  "玼": [
    "ci3"
  ],
  "向导": [
    "xiang4 dao3"
  ],
  "养活": [
    "yang3 huo5"
  ],
  "髑": [
    "du2"
  ],
  "沂": [
    "Yi2"
  ],
  "尨": [
    "Pang2",
    "mang2",
    "pang2"
  ],
  "满意": [
    "man3 yi4"
  ],
  "皮带": [
    "pi2 dai4"
  ],
  "糖": [
    "tang2",
    "tang2"
  ],
  "含蓄": [
    "han2 xu4"
  ],
  "圐": [
    "ku1"
  ],
  "瑕": [
    "xia2"
  ],
  "一道": [
    "yi1 dao4"
  ],
  "适应": [
    "shi4 ying4"
  ],
  "用不着": [
    "yong4 bu5 zhao2"
  ],
  "欣": [
    "xin1"
  ],
  "逊色": [
    "xun4 se4"
  ],
  "苔": [
    "tai1",
    "tai2"
  ],
  "始终": [
    "shi3 zhong1"
  ],
  "萎": [
    "wei3"
  ],
  "学院": [
    "xue2 yuan4"
  ],
  "位置": [
    "wei4 zhi5"
  ],
  "网点": [
    "wang3 dian3"
  ],
  "椐": [
    "ju1"
  ],
  "餍": [
    "yan4"
  ],
  "鸮": [
    "xiao1"
  ],
  "出入": [
    "chu1 ru4"
  ],
  "玒": [
    "hong2"
  ],
  "独唱": [
    "du2 chang4"
  ],
  "疆": [
    "jiang1"
  ],
  "琎": [
    "jin4"
  ],
  "混浊": [
    "hun4 zhuo2"
  ],
  "捂": [
    "wu3"
  ],
  "霸": [
    "ba4",
    "ba4"
  ],
  "化纤": [
    "hua4 xian1"
  ],
  "自始至终": [
    "zi4 shi3 zhi4 zhong1"
  ],
  "驱": [
    "qu1",
    "qu1",
    "qu1"
  ],
  "嵖": [
    "cha2"
  ],
  "箔": [
    "bo2"
  ],
  "着重": [
    "zhuo2 zhong4"
  ],
  "殚": [
    "dan1"
  ],
  "韶": [
    "Shao2",
    "shao2"
  ],
  "䴓": [
    "shi1"
  ],
  "名称": [
    "ming2 cheng1"
  ],
  "翔": [
    "xiang2"
  ],
  "后裔": [
    "hou4 yi4"
  ],
  "骸": [
    "hai2"
  ],
  "串门": [
    "chuan4 men2"
  ],
  "卧": [
    "wo4"
  ],
  "停放": [
    "ting2 fang4"
  ],
  "恰当": [
    "qia4 dang4"
  ],
  "慆": [
    "tao1"
  ],
  "恤": [
    "xu4",
    "xu4",
    "xu4",
    "xu4"
  ],
  "未": [
    "wei4"
  ],
  "早日": [
    "zao3 ri4"
  ],
  "气势": [
    "qi4 shi4"
  ],
  "偏偏": [
    "pian1 pian1"
  ],
  "兼任": [
    "jian1 ren4"
  ],
  "棍子": [
    "gun4 zi5"
  ],
  "粗心": [
    "cu1 xin1"
  ],
  "鳇": [
    "huang2"
  ],
  "退学": [
    "tui4 xue2"
  ],
  "䲠": [
    "chun1"
  ],
  "近日": [
    "jin4 ri4"
  ],
  "倾诉": [
    "qing1 su4"
  ],
  "饥饿": [
    "ji1 e4"
  ],
  "常温": [
    "chang2 wen1"
  ],
  "退休金": [
    "tui4 xiu1 jin1"
  ],
  "骚乱": [
    "sao1 luan4"
  ],
  "肮": [
    "ang1"
  ],
  "螅": [
    "xi1"
  ],
  "首席": [
    "shou3 xi2"
  ],
  "进一步": [
    "jin4 yi1 bu4"
  ],
  "砵": [
    "bo1"
  ],
  "组织": [
    "zu3 zhi1"
  ],
  "䢼": [
    null
  ],
  "文具": [
    "wen2 ju4"
  ],
  "醛": [
    "quan2"
  ],
  "率先": [
    "shuai4 xian1"
  ],
  "明亮": [
    "ming2 liang4"
  ],
  "现任": [
    "xian4 ren4"
  ],
  "味精": [
    "wei4 jing1"
  ],
  "其次": [
    "qi2 ci4"
  ],
  "大队": [
    "da4 dui4"
  ],
  "精彩": [
    "jing1 cai3"
  ],
  "建造": [
    "jian4 zao4"
  ],
  "铃声": [
    "ling2 sheng1"
  ],
  "絜": [
    "jie2",
    "xie2"
  ],
  "骰": [
    "tou2"
  ],
  "蔃": [
    "qiang2"
  ],
  "有名": [
    "you3 ming2"
  ],
  "窅": [
    "yao3"
  ],
  "来源": [
    "lai2 yuan2"
  ],
  "兮": [
    "xi1"
  ],
  "飕": [
    "sou1"
  ],
  "与此同时": [
    "yu3 ci3 - tong2 shi2"
  ],
  "杭": [
    "Hang2"
  ],
  "血脉": [
    "xue4 mai4"
  ],
  "聿": [
    "yu4"
  ],
  "打针": [
    "da3 zhen1"
  ],
  "呜": [
    "wu1"
  ],
  "墨": [
    "Mo4",
    "mo4"
  ],
  "祕": [
    null
  ],
  "文件": [
    "wen2 jian4"
  ],
  "髡": [
    "kun1"
  ],
  "梓": [
    "zi3"
  ],
  "动": [
    "dong4"
  ],
  "彩": [
    "cai3"
  ],
  "直线": [
    "zhi2 xian4"
  ],
  "工": [
    "gong1"
  ],
  "哔": [
    "bi4"
  ],
  "具体": [
    "ju4 ti3"
  ],
  "违法": [
    "wei2 fa3"
  ],
  "酝": [
    "yun4"
  ],
  "流通": [
    "liu2 tong1"
  ],
  "揭发": [
    "jie1 fa1"
  ],
  "师": [
    "Shi1",
    "shi1"
  ],
  "终点": [
    "zhong1 dian3"
  ],
  "咡": [
    "er4"
  ],
  "浩劫": [
    "hao4 jie2"
  ],
  "谿": [
    null
  ],
  "匕": [
    "bi3"
  ],
  "娼": [
    "chang1"
  ],
  "淑": [
    "shu1"
  ],
  "贝": [
    "Bei4",
    "bei4"
  ],
  "耐心": [
    "nai4 xin1"
  ],
  "争先恐后": [
    "zheng1 xian1 kong3 hou4"
  ],
  "颏": [
    "ke1",
    "ke2"
  ],
  "麻烦": [
    "ma2 fan5"
  ],
  "窖": [
    "jiao4"
  ],
  "繁华": [
    "fan2 hua2"
  ],
  "紧缺": [
    "jin3 que1"
  ],
  "鹴": [
    "shuang1"
  ],
  "鹟": [
    "weng1"
  ],
  "限制": [
    "xian4 zhi4"
  ],
  "跟": [
    "gen1"
  ],
  "大局": [
    "da4 ju2"
  ],
  "躲藏": [
    "duo3 cang2"
  ],
  "俅": [
    "qiu2"
  ],
  "坂": [
    "ban3",
    "ban3"
  ],
  "交叉": [
    "jiao1 cha1"
  ],
  "其余": [
    "qi2 yu2"
  ],
  "环": [
    "Huan2",
    "huan2"
  ],
  "显示": [
    "xian3 shi4"
  ],
  "玙": [
    "yu2"
  ],
  "恚": [
    "hui4"
  ],
  "珀": [
    "po4"
  ],
  "鼷": [
    "xi1"
  ],
  "真理": [
    "zhen1 li3"
  ],
  "素养": [
    "su4 yang3"
  ],
  "阼": [
    "zuo4"
  ],
  "酒精": [
    "jiu3 jing1"
  ],
  "海鲜": [
    "hai3 xian1"
  ],
  "小丑": [
    "xiao3 chou3"
  ],
  "通": [
    "tong1",
    "tong4"
  ],
  "峰": [
    "feng1",
    "feng1"
  ],
  "睐": [
    "lai4"
  ],
  "艺人": [
    "yi4 ren2"
  ],
  "起跑线": [
    "qi3 pao3 xian4"
  ],
  "迦": [
    "jia1"
  ],
  "连续剧": [
    "lian2 xu4 ju4"
  ],
  "堕": [
    "duo4"
  ],
  "酮": [
    "tong2"
  ],
  "积蓄": [
    "ji1 xu4"
  ],
  "夬": [
    "guai4"
  ],
  "峥": [
    "zheng1"
  ],
  "进度": [
    "jin4 du4"
  ],
  "崤": [
    "Xiao2"
  ],
  "姓名": [
    "xing4 ming2"
  ],
  "目瞪口呆": [
    "mu4 deng4 kou3 dai1"
  ],
  "愠": [
    "yun4"
  ],
  "筚": [
    "bi4"
  ],
  "脑子": [
    "nao3 zi5"
  ],
  "主题": [
    "zhu3 ti2"
  ],
  "落实": [
    "luo4 shi2"
  ],
  "公认": [
    "gong1 ren4"
  ],
  "后备": [
    "hou4 bei4"
  ],
  "元首": [
    "yuan2 shou3"
  ],
  "疰": [
    null
  ],
  "无理": [
    "wu2 li3"
  ],
  "谨": [
    "jin3"
  ],
  "搞鬼": [
    "gao3 gui3"
  ],
  "脏": [
    "zang4",
    "zang1"
  ],
  "活期": [
    "huo2 qi1"
  ],
  "啉": [
    "lin2"
  ],
  "莩": [
    "fu2",
    "piao3"
  ],
  "岗位": [
    "gang3 wei4"
  ],
  "宫殿": [
    "gong1 dian4"
  ],
  "书架": [
    "shu1 jia4"
  ],
  "崧": [
    "song1"
  ],
  "对": [
    "dui4"
  ],
  "矾": [
    "fan2"
  ],
  "滹": [
    "Hu1"
  ],
  "伽": [
    "jia1"
  ],
  "鹩": [
    "liao2"
  ],
  "特殊": [
    "te4 shu1"
  ],
  "陪伴": [
    "pei2 ban4"
  ],
  "之所以": [
    "zhi1 suo3 yi3"
  ],
  "套餐": [
    "tao4 can1"
  ],
  "戏剧": [
    "xi4 ju4"
  ],
  "提前": [
    "ti2 qian2"
  ],
  "高峰期": [
    "gao1 feng1 qi1"
  ],
  "鳗": [
    "man2"
  ],
  "性能": [
    "xing4 neng2"
  ],
  "铡": [
    "zha2"
  ],
  "摴": [
    "shu1"
  ],
  "褯": [
    "jie4"
  ],
  "载": [
    "zai3",
    "zai4"
  ],
  "蛱": [
    "jia2"
  ],
  "崔": [
    "Cui1",
    "cui1"
  ],
  "微妙": [
    "wei1 miao4"
  ],
  "觎": [
    "yu2"
  ],
  "车轮": [
    "che1 lun2"
  ],
  "巅峰": [
    "dian1 feng1"
  ],
  "下个月": [
    "xia4 ge4 yue4"
  ],
  "擎": [
    "qing2"
  ],
  "前景": [
    "qian2 jing3"
  ],
  "造福": [
    "zao4 fu2"
  ],
  "一一": [
    "yi1 yi1"
  ],
  "模样": [
    "mu2 yang4"
  ],
  "上来": [
    "shang4 lai2"
  ],
  "颜": [
    "Yan2",
    "yan2",
    "yan2"
  ],
  "皂": [
    "zao4"
  ],
  "竞赛": [
    "jing4 sai4"
  ],
  "洋溢": [
    "yang2 yi4"
  ],
  "保暖": [
    "bao3 nuan3"
  ],
  "舛": [
    "chuan3"
  ],
  "伯父": [
    "bo2 fu4"
  ],
  "喜爱": [
    "xi3 ai4"
  ],
  "苤": [
    "pie3"
  ],
  "裁决": [
    "cai2 jue2"
  ],
  "牙膏": [
    "ya2 gao1"
  ],
  "鞅": [
    "yang1",
    "yang4"
  ],
  "潜移默化": [
    "qian2 yi2 mo4 hua4"
  ],
  "损人利己": [
    "sun3 ren2 li4 ji3"
  ],
  "滨": [
    "bin1"
  ],
  "驮": [
    "tuo2",
    "duo4",
    "tuo2"
  ],
  "珒": [
    "jin1"
  ],
  "明天": [
    "ming2 tian1"
  ],
  "嬖": [
    "bi4"
  ],
  "玦": [
    "jue2"
  ],
  "汇聚": [
    "hui4 ju4"
  ],
  "灾害": [
    "zai1 hai4"
  ],
  "出场": [
    "chu1 chang3"
  ],
  "蜈": [
    "wu2"
  ],
  "双向": [
    "shuang1 xiang4"
  ],
  "姒": [
    "Si4",
    "si4"
  ],
  "预": [
    "yu4"
  ],
  "农": [
    "Nong2",
    "nong2",
    "nong2"
  ],
  "特质": [
    "te4 zhi4"
  ],
  "作业": [
    "zuo4 ye4"
  ],
  "洗礼": [
    "xi3 li3"
  ],
  "迁移": [
    "qian1 yi2"
  ],
  "刎": [
    "wen3"
  ],
  "扆": [
    "Yi3",
    "yi3"
  ],
  "讻": [
    null
  ],
  "富足": [
    "fu4 zu2"
  ],
  "往返": [
    "wang3 fan3"
  ],
  "鸷": [
    "zhi4"
  ],
  "洪亮": [
    "hong2 liang4"
  ],
  "可谓": [
    "ke3 wei4"
  ],
  "呕吐": [
    "ou3 tu4"
  ],
  "跆": [
    "tai2"
  ],
  "欣赏": [
    "xin1 shang3"
  ],
  "巨人": [
    "ju4 ren2"
  ],
  "犯规": [
    "fan4 gui1"
  ],
  "手机": [
    "shou3 ji1"
  ],
  "远见": [
    "yuan3 jian4"
  ],
  "究竟": [
    "jiu1 jing4"
  ],
  "拐弯": [
    "guai3 wan1"
  ],
  "钓鱼": [
    "diao4 yu2"
  ],
  "武术": [
    "wu3 shu4"
  ],
  "慌忙": [
    "huang1 mang2"
  ],
  "褛": [
    "lu:3"
  ],
  "兆": [
    "Zhao4",
    "zhao4"
  ],
  "找到": [
    "zhao3 dao4"
  ],
  "祖先": [
    "zu3 xian1"
  ],
  "生物": [
    "sheng1 wu4"
  ],
  "确保": [
    "que4 bao3"
  ],
  "楙": [
    "mao4"
  ],
  "紫": [
    "zi3"
  ],
  "僧": [
    "seng1"
  ],
  "报道": [
    "bao4 dao4"
  ],
  "室": [
    "Shi4",
    "shi4"
  ],
  "屹立": [
    "yi4 li4"
  ],
  "饻": [
    null
  ],
  "因为": [
    "yin1 wei4"
  ],
  "们": [
    "men5"
  ],
  "见证": [
    "jian4 zheng4"
  ],
  "英雄": [
    "ying1 xiong2"
  ],
  "庇": [
    "bi4"
  ],
  "重大": [
    "zhong4 da4"
  ],
  "革命": [
    "ge2 ming4"
  ],
  "鲘": [
    "hou4"
  ],
  "剽": [
    "piao1"
  ],
  "印刷": [
    "yin4 shua1"
  ],
  "只能": [
    "zhi3 neng2"
  ],
  "长达": [
    "chang2 da2"
  ],
  "腐朽": [
    "fu3 xiu3"
  ],
  "及其": [
    "ji2 qi2"
  ],
  "枞": [
    "cong1",
    "zong1"
  ],
  "进出口": [
    "jin4 chu1 kou3"
  ],
  "火灾": [
    "huo3 zai1"
  ],
  "祖": [
    "Zu3",
    "zu3"
  ],
  "小品": [
    "xiao3 pin3"
  ],
  "啖": [
    "dan4",
    "dan4",
    "dan4"
  ],
  "升高": [
    "sheng1 gao1"
  ],
  "公约": [
    "gong1 yue1"
  ],
  "气温": [
    "qi4 wen1"
  ],
  "治": [
    "zhi4"
  ],
  "垆": [
    "lu2"
  ],
  "鲱": [
    "fei1"
  ],
  "过硬": [
    "guo4 ying4"
  ],
  "扬": [
    "Yang2",
    "yang2",
    "yang2"
  ],
  "虿": [
    "chai4"
  ],
  "老": [
    "lao3"
  ],
  "敦促": [
    "dun1 cu4"
  ],
  "到头来": [
    "dao4 tou2 lai2"
  ],
  "目录": [
    "mu4 lu4"
  ],
  "中性": [
    "zhong1 xing4"
  ],
  "皋": [
    "gao1",
    "gao1",
    "gao1"
  ],
  "听众": [
    "ting1 zhong4"
  ],
  "节气": [
    "jie2 qi5"
  ],
  "狱": [
    "yu4"
  ],
  "威信": [
    "Wei1 xin4",
    "wei1 xin4"
  ],
  "诞": [
    "dan4"
  ],
  "衬衫": [
    "chen4 shan1"
  ],
  "刑": [
    "Xing2",
    "xing2"
  ],
  "不经意": [
    "bu4 jing1 yi4"
  ],
  "见钱眼开": [
    "jian4 qian2 yan3 kai1"
  ],
  "好事": [
    "hao3 shi4",
    "hao4 shi4"
  ],
  "相比之下": [
    "xiang1 bi3 zhi1 xia4"
  ],
  "寐": [
    "mei4"
  ],
  "漂亮": [
    "piao4 liang5"
  ],
  "胡闹": [
    "hu2 nao4"
  ],
  "俩": [
    "lia3",
    "liang3"
  ],
  "礁": [
    "jiao1"
  ],
  "掸": [
    "dan3"
  ],
  "辉": [
    "hui1"
  ],
  "澥": [
    "xie4"
  ],
  "惙": [
    "chuo4"
  ],
  "堆砌": [
    "dui1 qi4"
  ],
  "所长": [
    "suo3 chang2",
    "suo3 zhang3"
  ],
  "叹": [
    "tan4",
    "tan4"
  ],
  "父子": [
    "fu4 zi3"
  ],
  "沪": [
    "Hu4"
  ],
  "爵": [
    "jue2"
  ],
  "妻子": [
    "qi1 zi3",
    "qi1 zi5"
  ],
  "惬": [
    "qie4",
    "qie4"
  ],
  "跤": [
    "jiao1"
  ],
  "手工": [
    "shou3 gong1"
  ],
  "击": [
    "ji1"
  ],
  "创新": [
    "chuang4 xin1"
  ],
  "以外": [
    "yi3 wai4"
  ],
  "邵": [
    "Shao4"
  ],
  "斑点": [
    "ban1 dian3"
  ],
  "杩": [
    "ma4"
  ],
  "蹇": [
    "Jian3",
    "jian3"
  ],
  "敬意": [
    "jing4 yi4"
  ],
  "我们": [
    "wo3 men5"
  ],
  "光荣": [
    "guang1 rong2"
  ],
  "操作": [
    "cao1 zuo4"
  ],
  "地下室": [
    "di4 xia4 shi4"
  ],
  "耘": [
    "yun2"
  ],
  "绦": [
    "di2",
    "tao1",
    "tao1"
  ],
  "接班": [
    "jie1 ban1"
  ],
  "壕": [
    "hao2"
  ],
  "老远": [
    "lao3 yuan3"
  ],
  "补": [
    "bu3"
  ],
  "封面": [
    "feng1 mian4"
  ],
  "番茄": [
    "fan1 qie2"
  ],
  "垧": [
    "shang3"
  ],
  "捧场": [
    "peng3 chang3"
  ],
  "技术": [
    "ji4 shu4"
  ],
  "僦": [
    "jiu4"
  ],
  "载体": [
    "zai4 ti3"
  ],
  "毕": [
    "Bi4",
    "bi4"
  ],
  "镴": [
    "la4"
  ],
  "恰恰相反": [
    "qia4 qia4 xiang1 fan3"
  ],
  "筹集": [
    "chou2 ji2"
  ],
  "惨痛": [
    "can3 tong4"
  ],
  "狠": [
    "hen3"
  ],
  "感冒": [
    "gan3 mao4"
  ],
  "指着": [
    null
  ],
  "接待": [
    "jie1 dai4"
  ],
  "指头": [
    "zhi3 tou5"
  ],
  "玛": [
    "ma3"
  ],
  "调皮": [
    "tiao2 pi2"
  ],
  "牛仔裤": [
    "niu2 zai3 ku4"
  ],
  "懦": [
    "nuo4"
  ],
  "之一": [
    "zhi1 yi1"
  ],
  "抠": [
    "kou1"
  ],
  "诵": [
    "song4"
  ],
  "平衡": [
    "ping2 heng2"
  ],
  "陌生": [
    "mo4 sheng1"
  ],
  "药品": [
    "yao4 pin3"
  ],
  "迁": [
    "qian1"
  ],
  "羡": [
    "xian4"
  ],
  "凑合": [
    "cou4 he5"
  ],
  "殓": [
    "lian4"
  ],
  "翂": [
    null
  ],
  "漱": [
    "shu4",
    "shu4"
  ],
  "躺": [
    "tang3"
  ],
  "属于": [
    "shu3 yu2"
  ],
  "泷": [
    "Shuang1",
    "long2"
  ],
  "怆": [
    "chuang4"
  ],
  "运动": [
    "yun4 dong4"
  ],
  "篯": [
    "Jian1"
  ],
  "级": [
    "ji2"
  ],
  "偕": [
    "xie2"
  ],
  "心理": [
    "xin1 li3"
  ],
  "遏": [
    "e4"
  ],
  "神奇": [
    "shen2 qi2"
  ],
  "如醉如痴": [
    "ru2 zui4 ru2 chi1"
  ],
  "豪华": [
    "hao2 hua2"
  ],
  "筑": [
    "Zhu4",
    "zhu4",
    "zhu4"
  ],
  "机": [
    "Ji1",
    "ji1"
  ],
  "磜": [
    "qi4"
  ],
  "独身": [
    "du2 shen1"
  ],
  "修路": [
    "xiu1 lu4"
  ],
  "无忧无虑": [
    "wu2 you1 wu2 lu:4"
  ],
  "宸": [
    "chen2"
  ],
  "绢": [
    "juan4"
  ],
  "口水": [
    "kou3 shui3"
  ],
  "整数": [
    "zheng3 shu4"
  ],
  "怀抱": [
    "huai2 bao4"
  ],
  "婤": [
    "zhou1"
  ],
  "钛": [
    "tai4"
  ],
  "接近": [
    "jie1 jin4"
  ],
  "僧人": [
    "seng1 ren2"
  ],
  "凌晨": [
    "ling2 chen2"
  ],
  "拥挤": [
    "yong1 ji3"
  ],
  "高血压": [
    "gao1 xue4 ya1"
  ],
  "激动": [
    "ji1 dong4"
  ],
  "樗": [
    "chu1"
  ],
  "隐约": [
    "yin3 yue1"
  ],
  "火山": [
    "huo3 shan1"
  ],
  "幌": [
    "huang3"
  ],
  "僖": [
    "Xi1",
    "xi1"
  ],
  "长辈": [
    "zhang3 bei4"
  ],
  "作物": [
    "zuo4 wu4"
  ],
  "对照": [
    "dui4 zhao4"
  ],
  "淆": [
    "xiao2",
    "xiao2"
  ],
  "示": [
    "shi4"
  ],
  "依旧": [
    "yi1 jiu4"
  ],
  "咛": [
    "ning2"
  ],
  "裛": [
    "yi4"
  ],
  "谟": [
    "mo2",
    "mo2"
  ],
  "上级": [
    "shang4 ji2"
  ],
  "苉": [
    "pi3"
  ],
  "时尚": [
    "shi2 shang4"
  ],
  "血液": [
    "xue4 ye4"
  ],
  "过道": [
    "guo4 dao4"
  ],
  "出": [
    "chu1",
    "chu1"
  ],
  "存": [
    "cun2"
  ],
  "言": [
    "yan2"
  ],
  "家乡": [
    "jia1 xiang1"
  ],
  "莫非": [
    "mo4 fei1"
  ],
  "骚": [
    "sao1"
  ],
  "枋": [
    "fang1"
  ],
  "娘": [
    "niang2",
    "niang2"
  ],
  "密度": [
    "mi4 du4"
  ],
  "叽": [
    "ji1"
  ],
  "冁": [
    "chan3"
  ],
  "倦": [
    "juan4",
    "juan4"
  ],
  "评判": [
    "ping2 pan4"
  ],
  "忱": [
    "chen2"
  ],
  "弟": [
    "di4",
    "ti4"
  ],
  "蔚": [
    "Yu4",
    "wei4"
  ],
  "精致": [
    "jing1 zhi4"
  ],
  "佰": [
    "bai3"
  ],
  "肌": [
    "ji1"
  ],
  "夊": [
    "sui1"
  ],
  "好玩儿": [
    "hao3 wan2 r5"
  ],
  "引起": [
    "yin3 qi3"
  ],
  "对得起": [
    "dui4 de5 qi3"
  ],
  "干吗": [
    "gan4 ma2"
  ],
  "彩电": [
    "cai3 dian4"
  ],
  "惊奇": [
    "jing1 qi2"
  ],
  "策略": [
    "ce4 lu:e4"
  ],
  "去": [
    "qu4"
  ],
  "忘不了": [
    "wang4 bu4 liao3"
  ],
  "谡": [
    "su4"
  ],
  "全面": [
    "quan2 mian4"
  ],
  "流淌": [
    "liu2 tang3"
  ],
  "古董": [
    "gu3 dong3"
  ],
  "东部": [
    "dong1 bu4"
  ],
  "得失": [
    "de2 shi1"
  ],
  "剅": [
    "lou2"
  ],
  "怃": [
    "wu3"
  ],
  "黻": [
    "fu2"
  ],
  "濮": [
    "Pu2"
  ],
  "梯": [
    "ti1"
  ],
  "赟": [
    "yun1"
  ],
  "烹": [
    "peng1"
  ],
  "一毛不拔": [
    "yi1 mao2 bu4 ba2"
  ],
  "荆棘": [
    "jing1 ji2"
  ],
  "板": [
    "ban3",
    "ban3",
    "pan4"
  ],
  "聊天儿": [
    "liao2 tian1 r5"
  ],
  "测算": [
    "ce4 suan4"
  ],
  "下楼": [
    "xia4 lou2"
  ],
  "沙龙": [
    "sha1 long2"
  ],
  "大熊猫": [
    "da4 xiong2 mao1"
  ],
  "配置": [
    "pei4 zhi4"
  ],
  "早就": [
    "zao3 jiu4"
  ],
  "蕴涵": [
    "yun4 han2"
  ],
  "维持": [
    "wei2 chi2"
  ],
  "探讨": [
    "tan4 tao3"
  ],
  "黡": [
    "yan3"
  ],
  "岘": [
    "xian4"
  ],
  "竦": [
    "song3"
  ],
  "衰弱": [
    "shuai1 ruo4"
  ],
  "鸭": [
    "ya1"
  ],
  "真是的": [
    "zhen1 shi5 de5"
  ],
  "捍卫": [
    "han4 wei4"
  ],
  "狼狈": [
    "lang2 bei4"
  ],
  "益处": [
    "yi4 chu5"
  ],
  "皇后": [
    "huang2 hou4"
  ],
  "高效": [
    "gao1 xiao4"
  ],
  "鲔": [
    "wei3"
  ],
  "徕": [
    "lai2"
  ],
  "湝": [
    "jie1"
  ],
  "回到": [
    "hui2 dao4"
  ],
  "劲头": [
    "jin4 tou2"
  ],
  "发布": [
    "fa1 bu4",
    "fa1 bu4"
  ],
  "瘰": [
    "luo3"
  ],
  "尽可能": [
    "jin3 ke3 neng2",
    "jin4 ke3 neng2"
  ],
  "縻": [
    "mi2"
  ],
  "煤矿": [
    "mei2 kuang4"
  ],
  "鳈": [
    "quan2"
  ],
  "一连": [
    "yi1 lian2"
  ],
  "时节": [
    "shi2 jie2"
  ],
  "孝顺": [
    "xiao4 shun4"
  ],
  "镘": [
    "man4"
  ],
  "念念不忘": [
    "nian4 nian4 bu4 wang4"
  ],
  "湖": [
    "hu2"
  ],
  "开采": [
    "kai1 cai3"
  ],
  "泉": [
    "quan2"
  ],
  "钩": [
    "gou1",
    "gou1"
  ],
  "节": [
    "jie1",
    "jie2"
  ],
  "刺耳": [
    "ci4 er3"
  ],
  "粤": [
    "Yue4"
  ],
  "厂": [
    "han3",
    "chang3"
  ],
  "拘束": [
    "ju1 shu4"
  ],
  "磨损": [
    "mo2 sun3"
  ],
  "趱": [
    "zan3"
  ],
  "键盘": [
    "jian4 pan2"
  ],
  "抚养": [
    "fu3 yang3"
  ],
  "何况": [
    "he2 kuang4"
  ],
  "偏见": [
    "pian1 jian4"
  ],
  "讥": [
    "ji1"
  ],
  "超前": [
    "chao1 qian2"
  ],
  "饮": [
    "yin3",
    "yin3",
    "yin4"
  ],
  "汇合": [
    "hui4 he2"
  ],
  "榈": [
    "lu:2"
  ],
  "肟": [
    "wo4"
  ],
  "秩序": [
    "zhi4 xu4"
  ],
  "滤": [
    "lu:4"
  ],
  "法庭": [
    "fa3 ting2"
  ],
  "顾": [
    "Gu4",
    "gu4"
  ],
  "启示": [
    "qi3 shi4"
  ],
  "上岗": [
    "shang4 gang3"
  ],
  "泣": [
    "qi4"
  ],
  "堵塞": [
    "du3 se4"
  ],
  "观看": [
    "guan1 kan4"
  ],
  "名著": [
    "ming2 zhu4"
  ],
  "函": [
    "han2",
    "han2"
  ],
  "豉": [
    "chi3"
  ],
  "远近闻名": [
    "yuan3 jin4 wen2 ming2"
  ],
  "旐": [
    "zhao4"
  ],
  "空难": [
    "kong1 nan4"
  ],
  "卑鄙": [
    "bei1 bi3"
  ],
  "电网": [
    "dian4 wang3"
  ],
  "唯一": [
    "wei2 yi1"
  ],
  "蹽": [
    "liao1"
  ],
  "稽": [
    "Ji1",
    "ji1",
    "qi3"
  ],
  "生态": [
    "sheng1 tai4"
  ],
  "过节": [
    "guo4 jie2"
  ],
  "炎症": [
    "yan2 zheng4"
  ],
  "适当": [
    "shi4 dang4"
  ],
  "上门": [
    "shang4 men2"
  ],
  "怩": [
    "ni2"
  ],
  "弨": [
    "chao1"
  ],
  "行列": [
    "hang2 lie4"
  ],
  "瀑": [
    "bao4",
    "pu4"
  ],
  "琪": [
    "qi2"
  ],
  "征求": [
    "zheng1 qiu2"
  ],
  "聂": [
    "Nie4",
    "nie4"
  ],
  "动态": [
    "dong4 tai4"
  ],
  "土地": [
    "tu3 di4",
    "tu3 di5"
  ],
  "嘲": [
    "chao2",
    "zhao1"
  ],
  "选民": [
    "xuan3 min2"
  ],
  "网": [
    "wang3"
  ],
  "人次": [
    "ren2 ci4"
  ],
  "燃放": [
    "ran2 fang4"
  ],
  "耆": [
    "qi2"
  ],
  "铰": [
    "jiao3"
  ],
  "蓏": [
    "luo3"
  ],
  "仇人": [
    "chou2 ren2"
  ],
  "理会": [
    "li3 hui4"
  ],
  "埤": [
    "pi2"
  ],
  "美食": [
    "mei3 shi2"
  ],
  "璆": [
    "qiu2"
  ],
  "阳性": [
    "yang2 xing4"
  ],
  "钧": [
    "jun1"
  ],
  "工序": [
    "gong1 xu4"
  ],
  "诿": [
    "wei3"
  ],
  "艄": [
    "shao1"
  ],
  "标语": [
    "biao1 yu3"
  ],
  "路子": [
    "lu4 zi5"
  ],
  "慧": [
    "hui4"
  ],
  "流利": [
    "liu2 li4"
  ],
  "体": [
    "ti3"
  ],
  "沾": [
    "zhan1",
    "zhan1"
  ],
  "百合": [
    "bai3 he2"
  ],
  "捣": [
    "dao3"
  ],
  "蔓延": [
    "man4 yan2"
  ],
  "经营": [
    "jing1 ying2"
  ],
  "迓": [
    "ya4"
  ],
  "乐器": [
    "yue4 qi4"
  ],
  "驼": [
    "tuo2",
    "tuo2"
  ],
  "胆小": [
    "dan3 xiao3"
  ],
  "简化": [
    "jian3 hua4"
  ],
  "公寓": [
    "gong1 yu4"
  ],
  "鲳": [
    "chang1"
  ],
  "反馈": [
    "fan3 kui4"
  ],
  "剜": [
    "wan1"
  ],
  "蚤": [
    "zao3"
  ],
  "彻": [
    "che4"
  ],
  "情景": [
    "qing2 jing3"
  ],
  "恶性": [
    "e4 xing4"
  ],
  "杂志": [
    "za2 zhi4"
  ],
  "摔": [
    "shuai1"
  ],
  "蛉": [
    "ling2"
  ],
  "屹": [
    "yi4"
  ],
  "交换": [
    "jiao1 huan4"
  ],
  "稗": [
    "bai4",
    "bai4"
  ],
  "砭": [
    "bian1"
  ],
  "瀵": [
    "fen4"
  ],
  "忧": [
    "you1"
  ],
  "出头": [
    "chu1 tou2"
  ],
  "晌": [
    "shang3"
  ],
  "后备箱": [
    "hou4 bei4 xiang1"
  ],
  "掖": [
    "ye1",
    "ye4"
  ],
  "广泛": [
    "guang3 fan4"
  ],
  "滫": [
    "xiu3"
  ],
  "亲": [
    "qin1",
    "qing4"
  ],
  "攀升": [
    "pan1 sheng1"
  ],
  "瞀": [
    "mao4"
  ],
  "娀": [
    "song1"
  ],
  "袯": [
    "bo2"
  ],
  "山寨": [
    "shan1 zhai4"
  ],
  "祷": [
    "dao3"
  ],
  "金字塔": [
    "jin1 zi4 ta3"
  ],
  "健壮": [
    "jian4 zhuang4"
  ],
  "协作": [
    "xie2 zuo4"
  ],
  "年终": [
    "nian2 zhong1"
  ],
  "婌": [
    null
  ],
  "冷淡": [
    "leng3 dan4"
  ],
  "燎": [
    "liao2",
    "liao3"
  ],
  "剧烈": [
    "ju4 lie4"
  ],
  "娇气": [
    "jiao1 qi4"
  ],
  "昉": [
    "fang3"
  ],
  "政治": [
    "zheng4 zhi4"
  ],
  "吸": [
    "xi1"
  ],
  "蕃": [
    "bo1",
    "fan1",
    "fan2"
  ],
  "鲜": [
    "xian3",
    "xian3",
    "xian1",
    "xian3",
    "xian1",
    "xian3"
  ],
  "折叠": [
    "zhe2 die2"
  ],
  "勤奋": [
    "qin2 fen4"
  ],
  "腋": [
    "ye4"
  ],
  "柔": [
    "rou2"
  ],
  "不久": [
    "bu4 jiu3"
  ],
  "闹着玩儿": [
    "nao4 zhe5 wan2 r5"
  ],
  "骷": [
    "ku1"
  ],
  "溘": [
    "ke4"
  ],
  "失灵": [
    "shi1 ling2"
  ],
  "舂": [
    "chong1"
  ],
  "嘎": [
    "ga2",
    "ga2"
  ],
  "股": [
    "gu3"
  ],
  "同盟": [
    "tong2 meng2"
  ],
  "暵": [
    "han4"
  ],
  "重中之重": [
    "zhong4 zhong1 zhi1 zhong4"
  ],
  "当晚": [
    "dang1 wan3",
    "dang4 wan3"
  ],
  "荨": [
    "qian2"
  ],
  "㴔": [
    null
  ],
  "锾": [
    "huan2"
  ],
  "黄色": [
    "huang2 se4"
  ],
  "蓥": [
    "ying2"
  ],
  "引经据典": [
    "yin3 jing1 ju4 dian3"
  ],
  "水面": [
    "shui3 mian4"
  ],
  "伺机": [
    "si4 ji1"
  ],
  "重播": [
    "chong2 bo1"
  ],
  "确": [
    "que4",
    "que4"
  ],
  "煺": [
    "tui4"
  ],
  "娌": [
    "li3"
  ],
  "研究": [
    "yan2 jiu1"
  ],
  "殴": [
    "Ou1",
    "ou1"
  ],
  "单身": [
    "dan1 shen1"
  ],
  "精细": [
    "jing1 xi4"
  ],
  "奥秘": [
    "ao4 mi4"
  ],
  "鱼": [
    "Yu2",
    "yu2"
  ],
  "蠖": [
    "huo4"
  ],
  "疫苗": [
    "yi4 miao2"
  ],
  "快捷": [
    "kuai4 jie2"
  ],
  "嘹": [
    "liao2"
  ],
  "饱": [
    "bao3"
  ],
  "疏通": [
    "shu1 tong1"
  ],
  "为此": [
    "wei4 ci3"
  ],
  "家长": [
    "jia1 zhang3"
  ],
  "车道": [
    "che1 dao4"
  ],
  "犊": [
    "du2"
  ],
  "贴切": [
    "tie1 qie4"
  ],
  "地质": [
    "di4 zhi4"
  ],
  "资产": [
    "zi1 chan3"
  ],
  "客厅": [
    "ke4 ting1"
  ],
  "谁知道": [
    "shei2 zhi1 dao4"
  ],
  "镁": [
    "mei3"
  ],
  "热量": [
    "re4 liang4"
  ],
  "务": [
    "wu4"
  ],
  "禒": [
    null
  ],
  "鲛": [
    "jiao1"
  ],
  "热烈": [
    "re4 lie4"
  ],
  "指出": [
    "zhi3 chu1"
  ],
  "溆": [
    "xu4"
  ],
  "引领": [
    "yin3 ling3"
  ],
  "魆": [
    "xu1"
  ],
  "移植": [
    "yi2 zhi2"
  ],
  "孪生": [
    "luan2 sheng1"
  ],
  "忳": [
    null
  ],
  "诣": [
    "yi4"
  ],
  "到处": [
    "dao4 chu4"
  ],
  "法官": [
    "fa3 guan1"
  ],
  "钱财": [
    "qian2 cai2"
  ],
  "坝": [
    "ba4"
  ],
  "硭": [
    "mang2"
  ],
  "扇": [
    "shan1",
    "shan4"
  ],
  "州": [
    "zhou1"
  ],
  "吞": [
    "tun1"
  ],
  "生成": [
    "sheng1 cheng2"
  ],
  "吟": [
    "yin2",
    "yin2"
  ],
  "关头": [
    "guan1 tou2"
  ],
  "探": [
    "tan4"
  ],
  "鹈": [
    "ti2"
  ],
  "雹": [
    "bao2"
  ],
  "讲解": [
    "jiang3 jie3"
  ],
  "运营": [
    "yun4 ying2"
  ],
  "共": [
    "gong4"
  ],
  "留学生": [
    "liu2 xue2 sheng1"
  ],
  "苦": [
    "ku3"
  ],
  "白": [
    "Bai2",
    "bai2"
  ],
  "钢": [
    "gang1"
  ],
  "航天": [
    "hang2 tian1"
  ],
  "脎": [
    null
  ],
  "演绎": [
    "yan3 yi4"
  ],
  "遗弃": [
    "yi2 qi4"
  ],
  "感慨": [
    "gan3 kai3"
  ],
  "栝": [
    "gua1"
  ],
  "学会": [
    "xue2 hui4"
  ],
  "听见": [
    "ting1 jian4"
  ],
  "编造": [
    "bian1 zao4"
  ],
  "人缘儿": [
    "ren2 yuan2 r5"
  ],
  "销": [
    "xiao1"
  ],
  "耱": [
    "mo4"
  ],
  "与时俱进": [
    "yu3 shi2 ju4 jin4"
  ],
  "演出": [
    "yan3 chu1"
  ],
  "即使": [
    "ji2 shi3"
  ],
  "醒悟": [
    "xing3 wu4"
  ],
  "斩": [
    "zhan3"
  ],
  "限定": [
    "xian4 ding4"
  ],
  "蹶": [
    "jue2",
    "jue3"
  ],
  "剧本": [
    "ju4 ben3"
  ],
  "祥": [
    "xiang2"
  ],
  "朗": [
    "lang3"
  ],
  "鸪": [
    "gu1"
  ],
  "肪": [
    "fang2"
  ],
  "体谅": [
    "ti3 liang4"
  ],
  "慌": [
    "huang1"
  ],
  "堼": [
    "heng4"
  ],
  "问题": [
    "wen4 ti2"
  ],
  "洪水": [
    "hong2 shui3"
  ],
  "夜": [
    "ye4",
    "ye4"
  ],
  "到底": [
    "dao4 di3"
  ],
  "煎": [
    "jian1"
  ],
  "保养": [
    "bao3 yang3"
  ],
  "拙劣": [
    "zhuo1 lie4"
  ],
  "吊": [
    "diao4",
    "diao4"
  ],
  "银行卡": [
    "yin2 hang2 ka3"
  ],
  "附属": [
    "fu4 shu3"
  ],
  "旄": [
    "mao2",
    "mao4"
  ],
  "应有尽有": [
    "ying1 you3 jin4 you3"
  ],
  "筼": [
    "yun2"
  ],
  "薢": [
    "xiao4"
  ],
  "飞翔": [
    "fei1 xiang2"
  ],
  "捡": [
    "jian3"
  ],
  "抢救": [
    "qiang3 jiu4"
  ],
  "效率": [
    "xiao4 lu:4"
  ],
  "畜": [
    "chu4",
    "xu4"
  ],
  "谞": [
    "xu1"
  ],
  "小麦": [
    "xiao3 mai4"
  ],
  "玑": [
    "ji1"
  ],
  "绿灯": [
    "lu:4 deng1"
  ],
  "倍": [
    "bei4"
  ],
  "开口": [
    "kai1 kou3"
  ],
  "贾": [
    "Jia3",
    "gu3"
  ],
  "短": [
    "duan3"
  ],
  "提醒": [
    "ti2 xing3"
  ],
  "里头": [
    "li3 tou5"
  ],
  "真正": [
    "zhen1 zheng4"
  ],
  "蹈": [
    "dao3"
  ],
  "后勤": [
    "hou4 qin2"
  ],
  "操": [
    "cao1",
    "cao1",
    "cao4"
  ],
  "豆制品": [
    "dou4 zhi4 pin3"
  ],
  "早晨": [
    "zao3 chen2"
  ],
  "疯子": [
    "feng1 zi5"
  ],
  "阐": [
    "chan3"
  ],
  "理发": [
    "li3 fa4"
  ],
  "覃": [
    "Qin2",
    "Tan2",
    "tan2"
  ],
  "干戈": [
    "gan1 ge1"
  ],
  "哌": [
    "pai4"
  ],
  "友情": [
    "you3 qing2"
  ],
  "舍": [
    "she3",
    "She4",
    "she3",
    "she4"
  ],
  "怪异": [
    "guai4 yi4"
  ],
  "必将": [
    "bi4 jiang1"
  ],
  "打败": [
    "da3 bai4"
  ],
  "建": [
    "jian4"
  ],
  "鼢": [
    "fen2"
  ],
  "到达": [
    "dao4 da2"
  ],
  "施压": [
    "shi1 ya1"
  ],
  "念头": [
    "nian4 tou5"
  ],
  "紧密": [
    "jin3 mi4"
  ],
  "报刊": [
    "bao4 kan1"
  ],
  "悌": [
    "ti4"
  ],
  "童": [
    "Tong2",
    "tong2"
  ],
  "男女": [
    "nan2 nu:3"
  ],
  "菩萨": [
    "Pu2 sa4"
  ],
  "微不足道": [
    "wei1 bu4 zu2 dao4"
  ],
  "幕后": [
    "mu4 hou4"
  ],
  "使命": [
    "shi3 ming4"
  ],
  "恰好": [
    "qia4 hao3"
  ],
  "猯": [
    null
  ],
  "奴": [
    "nu2"
  ],
  "蛴": [
    "qi2"
  ],
  "潦": [
    "lao3",
    "liao2"
  ],
  "网民": [
    "wang3 min2"
  ],
  "村庄": [
    "cun1 zhuang1"
  ],
  "车厢": [
    "che1 xiang1"
  ],
  "点名": [
    "dian3 ming2"
  ],
  "墈": [
    "kan4"
  ],
  "逐渐": [
    "zhu2 jian4"
  ],
  "针灸": [
    "zhen1 jiu3"
  ],
  "富含": [
    "fu4 han2"
  ],
  "挤": [
    "ji3"
  ],
  "狉": [
    "pi1"
  ],
  "桕": [
    "jiu4"
  ],
  "渑": [
    "sheng2"
  ],
  "因人而异": [
    "yin1 ren2 er2 yi4"
  ],
  "沉默": [
    "chen2 mo4"
  ],
  "冷静": [
    "leng3 jing4"
  ],
  "要不然": [
    "yao4 bu4 ran2"
  ],
  "切断": [
    "qie1 duan4"
  ],
  "耀": [
    "yao4"
  ],
  "会意": [
    "hui4 yi4"
  ],
  "雪山": [
    "xue3 shan1"
  ],
  "体育馆": [
    "ti3 yu4 guan3"
  ],
  "法律": [
    "fa3 lu:4"
  ],
  "彡": [
    "shan1"
  ],
  "幻影": [
    "huan4 ying3"
  ],
  "烶": [
    "ting3"
  ],
  "丰厚": [
    "feng1 hou4"
  ],
  "峰会": [
    "feng1 hui4"
  ],
  "…极了": [
    null
  ],
  "子孙": [
    "zi3 sun1"
  ],
  "正式": [
    "zheng4 shi4"
  ],
  "挠": [
    "nao2"
  ],
  "实物": [
    "shi2 wu4"
  ],
  "雪": [
    "Xue3",
    "xue3"
  ],
  "不顾": [
    "bu4 gu4"
  ],
  "如果": [
    "ru2 guo3"
  ],
  "硌": [
    "ge4"
  ],
  "大门": [
    "Da4 men2",
    "da4 men2"
  ],
  "奇迹": [
    "qi2 ji4"
  ],
  "诸如此类": [
    "zhu1 ru2 ci3 lei4"
  ],
  "伺候": [
    "ci4 hou5"
  ],
  "赶到": [
    "gan3 dao4"
  ],
  "宝贝": [
    "bao3 bei4"
  ],
  "榜": [
    "bang3",
    "bang3"
  ],
  "群众": [
    "qun2 zhong4"
  ],
  "垿": [
    "xu4"
  ],
  "铙": [
    "nao2"
  ],
  "储备": [
    "chu3 bei4"
  ],
  "适度": [
    "shi4 du4"
  ],
  "髌": [
    "bin4"
  ],
  "推断": [
    "tui1 duan4"
  ],
  "马车": [
    "ma3 che1"
  ],
  "也就是说": [
    "ye3 jiu4 shi4 shuo1"
  ],
  "陡": [
    "dou3"
  ],
  "激活": [
    "ji1 huo2"
  ],
  "神话": [
    "shen2 hua4"
  ],
  "瘫痪": [
    "tan1 huan4"
  ],
  "滗": [
    "bi4"
  ],
  "开张": [
    "kai1 zhang1"
  ],
  "可不是": [
    "ke3 bu5 shi4"
  ],
  "对面": [
    "dui4 mian4"
  ],
  "筏": [
    "fa2",
    "fa2"
  ],
  "豆腐": [
    "dou4 fu5"
  ],
  "耳机": [
    "er3 ji1"
  ],
  "对象": [
    "dui4 xiang4"
  ],
  "湘": [
    "Xiang1"
  ],
  "防疫": [
    "fang2 yi4"
  ],
  "叹气": [
    "tan4 qi4"
  ],
  "玄": [
    "xuan2"
  ],
  "瓻": [
    "chi1"
  ],
  "拼命": [
    "pin1 ming4"
  ],
  "古朴": [
    "gu3 pu3"
  ],
  "疑点": [
    "yi2 dian3"
  ],
  "大学": [
    "Da4 xue2",
    "da4 xue2"
  ],
  "原有": [
    "yuan2 you3"
  ],
  "蕰": [
    "yun4"
  ],
  "粳": [
    "jing1",
    "jing1",
    "jing1"
  ],
  "这样一来": [
    "zhe4 yang4 yi1 lai2"
  ],
  "棵": [
    "ke1"
  ],
  "利害": [
    "li4 hai4",
    "li4 hai5"
  ],
  "致使": [
    "zhi4 shi3"
  ],
  "暖气": [
    "nuan3 qi4"
  ],
  "愉快": [
    "yu2 kuai4"
  ],
  "昆": [
    "kun1",
    "kun1",
    "kun1"
  ],
  "赊": [
    "she1"
  ],
  "诌": [
    "zhou1"
  ],
  "具备": [
    "ju4 bei4"
  ],
  "资助": [
    "zi1 zhu4"
  ],
  "文献": [
    "wen2 xian4"
  ],
  "薄弱": [
    "bo2 ruo4"
  ],
  "假期": [
    "jia4 qi1"
  ],
  "旅程": [
    "lu:3 cheng2"
  ],
  "发明": [
    "fa1 ming2"
  ],
  "木板": [
    "mu4 ban3"
  ],
  "麓": [
    "lu4"
  ],
  "高昂": [
    "gao1 ang2"
  ],
  "嘟": [
    "du1"
  ],
  "豨": [
    "xi1"
  ],
  "嘱咐": [
    "zhu3 fu5"
  ],
  "尽量": [
    "jin3 liang4",
    "jin4 liang4"
  ],
  "共性": [
    "gong4 xing4"
  ],
  "纼": [
    null
  ],
  "实惠": [
    "shi2 hui4"
  ],
  "够": [
    "gou4"
  ],
  "仪": [
    "yi2"
  ],
  "巧": [
    "qiao3"
  ],
  "翮": [
    "he2"
  ],
  "踹": [
    "chuai4"
  ],
  "氮": [
    "dan4"
  ],
  "犀": [
    "xi1"
  ],
  "参观": [
    "can1 guan1"
  ],
  "偷": [
    "tou1"
  ],
  "红色": [
    "hong2 se4"
  ],
  "反常": [
    "fan3 chang2"
  ],
  "拍卖": [
    "pai1 mai4"
  ],
  "海拔": [
    "hai3 ba2"
  ],
  "翌": [
    "yi4"
  ],
  "诈骗": [
    "zha4 pian4"
  ],
  "碲": [
    "di4"
  ],
  "谔": [
    "e4"
  ],
  "䗪": [
    "zhe4"
  ],
  "尽早": [
    "jin3 zao3"
  ],
  "抿": [
    "min3"
  ],
  "蹲": [
    "dun1"
  ],
  "出资": [
    "chu1 zi1"
  ],
  "赣": [
    "Gan4",
    "Gan4",
    "Gan4"
  ],
  "全文": [
    "quan2 wen2"
  ],
  "威慑": [
    "wei1 she4"
  ],
  "上调": [
    "shang4 tiao2"
  ],
  "靶子": [
    "ba3 zi5"
  ],
  "譬如": [
    "pi4 ru2"
  ],
  "余地": [
    "yu2 di4"
  ],
  "棒球": [
    "bang4 qiu2"
  ],
  "盍": [
    "he2",
    "he2"
  ],
  "徂": [
    "cu2"
  ],
  "车位": [
    "che1 wei4"
  ],
  "龋": [
    "qu3"
  ],
  "过时": [
    "guo4 shi2"
  ],
  "解除": [
    "jie3 chu2"
  ],
  "兽": [
    "shou4"
  ],
  "范": [
    "fan4",
    "Fan4"
  ],
  "憋": [
    "bie1"
  ],
  "第": [
    "di4"
  ],
  "萘": [
    "nai4"
  ],
  "金额": [
    "jin1 e2"
  ],
  "阇": [
    "du1",
    "she2"
  ],
  "未经": [
    "wei4 jing1"
  ],
  "全球": [
    "quan2 qiu2"
  ],
  "常识": [
    "chang2 shi2"
  ],
  "染": [
    "ran3"
  ],
  "筦": [
    "Guan3"
  ],
  "哢": [
    "long4"
  ],
  "答案": [
    "da2 an4"
  ],
  "检查": [
    "jian3 cha2"
  ],
  "胠": [
    "qu1"
  ],
  "胜利": [
    "sheng4 li4"
  ],
  "冰箱": [
    "bing1 xiang1"
  ],
  "岳父": [
    "yue4 fu4"
  ],
  "瘆": [
    "shen4"
  ],
  "梗": [
    "geng3"
  ],
  "听": [
    "yin3",
    "ting1"
  ],
  "今": [
    "jin1"
  ],
  "琇": [
    "xiu4"
  ],
  "队伍": [
    "dui4 wu3"
  ],
  "飔": [
    "si1"
  ],
  "窍": [
    "qiao4"
  ],
  "坍": [
    "tan1"
  ],
  "亲友": [
    "qin1 you3"
  ],
  "癸": [
    "gui3"
  ],
  "伯母": [
    "bo2 mu3"
  ],
  "激励": [
    "ji1 li4"
  ],
  "水果": [
    "shui3 guo3"
  ],
  "策": [
    "Ce4",
    "ce4",
    "ce4",
    "ce4"
  ],
  "婊": [
    "biao3"
  ],
  "唼": [
    "sha3"
  ],
  "辽阔": [
    "liao2 kuo4"
  ],
  "标志": [
    "biao1 zhi4"
  ],
  "坡": [
    "po1"
  ],
  "芷": [
    "zhi3"
  ],
  "书柜": [
    "shu1 gui4"
  ],
  "互": [
    "hu4"
  ],
  "分析": [
    "fen1 xi1"
  ],
  "涌现": [
    "yong3 xian4"
  ],
  "吸烟": [
    "xi1 yan1"
  ],
  "寻觅": [
    "xun2 mi4"
  ],
  "黢": [
    "qu1"
  ],
  "拟": [
    "ni3",
    "ni3"
  ],
  "当年": [
    "dang1 nian2",
    "dang4 nian2"
  ],
  "癌": [
    "ai2"
  ],
  "永": [
    "yong3"
  ],
  "朐": [
    "Qu2"
  ],
  "檀": [
    "Tan2",
    "tan2"
  ],
  "图书": [
    "tu2 shu1"
  ],
  "䢺": [
    "chu1"
  ],
  "后年": [
    "hou4 nian2"
  ],
  "鲜明": [
    "xian1 ming2"
  ],
  "锦": [
    "jin3"
  ],
  "下手": [
    "xia4 shou3"
  ],
  "家人": [
    "jia1 ren2"
  ],
  "种族": [
    "zhong3 zu2"
  ],
  "说话": [
    "shuo1 hua4"
  ],
  "增添": [
    "zeng1 tian1"
  ],
  "开发区": [
    "kai1 fa1 qu1"
  ],
  "赔钱": [
    "pei2 qian2"
  ],
  "喊": [
    "han3"
  ],
  "劫持": [
    "jie2 chi2"
  ],
  "壳": [
    "qiao4",
    "ke2",
    "qiao4"
  ],
  "梳": [
    "shu1"
  ],
  "野餐": [
    "ye3 can1"
  ],
  "围墙": [
    "wei2 qiang2"
  ],
  "里": [
    "li3",
    "li3",
    "Li3",
    "li3"
  ],
  "畤": [
    "zhi4"
  ],
  "卞": [
    "Bian4",
    "bian4"
  ],
  "征集": [
    "zheng1 ji2"
  ],
  "死亡": [
    "si3 wang2"
  ],
  "一系列": [
    "yi1 xi4 lie4"
  ],
  "暗杀": [
    "an4 sha1"
  ],
  "裈": [
    "kun1"
  ],
  "水准": [
    "shui3 zhun3"
  ],
  "模仿": [
    "mo2 fang3"
  ],
  "致力于": [
    null
  ],
  "和谐": [
    "he2 xie2"
  ],
  "国外": [
    "guo2 wai4"
  ],
  "种": [
    "zhong3",
    "zhong4"
  ],
  "廨": [
    "xie4"
  ],
  "感到": [
    "gan3 dao4"
  ],
  "邀请": [
    "yao1 qing3"
  ],
  "岳": [
    "Yue4",
    "yue4",
    "Yue4",
    "yue4"
  ],
  "忌讳": [
    "ji4 hui4"
  ],
  "监察": [
    "jian1 cha2"
  ],
  "麦": [
    "Mai4",
    "mai4"
  ],
  "舀": [
    "yao3"
  ],
  "借口": [
    "jie4 kou3"
  ],
  "熛": [
    "biao1"
  ],
  "肥料": [
    "fei2 liao4"
  ],
  "戛": [
    "jia2",
    "jia2"
  ],
  "公元": [
    "gong1 yuan2"
  ],
  "徙": [
    "xi3"
  ],
  "裘": [
    "Qiu2",
    "qiu2"
  ],
  "拓": [
    "Tuo4",
    "ta4",
    "tuo4"
  ],
  "安慰": [
    "an1 wei4"
  ],
  "知觉": [
    "zhi1 jue2"
  ],
  "琬": [
    "wan3"
  ],
  "以内": [
    "yi3 nei4"
  ],
  "更衣室": [
    "geng1 yi1 shi4"
  ],
  "爵士": [
    "jue2 shi4"
  ],
  "缺陷": [
    "que1 xian4"
  ],
  "恰巧": [
    "qia4 qiao3"
  ],
  "稼": [
    "jia4"
  ],
  "拼": [
    "pin1"
  ],
  "鹛": [
    "mei2"
  ],
  "过不去": [
    "guo4 bu5 qu4"
  ],
  "唷": [
    "yo1",
    "yo5"
  ],
  "褟": [
    "ta1"
  ],
  "笏": [
    "hu4"
  ],
  "倾家荡产": [
    "qing1 jia1 dang4 chan3"
  ],
  "指责": [
    "zhi3 ze2"
  ],
  "慷": [
    "kang1"
  ],
  "发光": [
    "fa1 guang1"
  ],
  "授权": [
    "shou4 quan2"
  ],
  "跗": [
    "fu1"
  ],
  "家": [
    "jia1",
    "jia1"
  ],
  "霈": [
    "pei4"
  ],
  "苦恼": [
    "ku3 nao3"
  ],
  "辎": [
    "zi1"
  ],
  "高": [
    "Gao1",
    "gao1"
  ],
  "不容": [
    "bu4 rong2"
  ],
  "大使馆": [
    "da4 shi3 guan3"
  ],
  "谅解": [
    "liang4 jie3"
  ],
  "别墅": [
    "bie2 shu4"
  ],
  "指望": [
    "zhi3 wang4"
  ],
  "裂": [
    "lie4"
  ],
  "冈": [
    "gang1"
  ],
  "崭": [
    "chan2",
    "zhan3",
    "chan2",
    "zhan3"
  ],
  "磕": [
    "ke1",
    "ke4"
  ],
  "问候": [
    "wen4 hou4"
  ],
  "通往": [
    "tong1 wang3"
  ],
  "等": [
    "deng3"
  ],
  "学位": [
    "xue2 wei4"
  ],
  "唣": [
    "zao4",
    "zao4"
  ],
  "干扰": [
    "gan1 rao3"
  ],
  "伸缩": [
    "shen1 suo1"
  ],
  "麋": [
    "Mi2",
    "mi2"
  ],
  "青年": [
    "qing1 nian2"
  ],
  "全程": [
    "quan2 cheng2"
  ],
  "璁": [
    "cong1"
  ],
  "饫": [
    "yu4"
  ],
  "甲": [
    "jia3"
  ],
  "无情": [
    "wu2 qing2"
  ],
  "私家车": [
    "si1 jia1 che1"
  ],
  "槐": [
    "huai2"
  ],
  "优秀": [
    "you1 xiu4"
  ],
  "团员": [
    "tuan2 yuan2"
  ],
  "差额": [
    "cha1 e2"
  ],
  "湫": [
    "jiao3"
  ],
  "杂交": [
    "za2 jiao1"
  ],
  "意味着": [
    "yi4 wei4 zhe5"
  ],
  "砌": [
    "qi4",
    "qie4"
  ],
  "分泌": [
    "fen1 mi4"
  ],
  "四周": [
    "si4 zhou1"
  ],
  "提防": [
    "di1 fang5"
  ],
  "收音机": [
    "shou1 yin1 ji1"
  ],
  "逡": [
    "qun1"
  ],
  "贬值": [
    "bian3 zhi2"
  ],
  "优势": [
    "you1 shi4"
  ],
  "还款": [
    "huan2 kuan3"
  ],
  "遭": [
    "zao1"
  ],
  "蝠": [
    "fu2"
  ],
  "坦诚": [
    "tan3 cheng2"
  ],
  "无能为力": [
    "wu2 neng2 wei2 li4"
  ],
  "炭": [
    "tan4"
  ],
  "告辞": [
    "gao4 ci2"
  ],
  "伴随": [
    "ban4 sui2"
  ],
  "惦": [
    "dian4"
  ],
  "氅": [
    "chang3"
  ],
  "剔": [
    "ti1"
  ],
  "崇高": [
    "chong2 gao1"
  ],
  "呷": [
    "xia1"
  ],
  "半岛": [
    "ban4 dao3"
  ],
  "左": [
    "Zuo3",
    "zuo3"
  ],
  "警": [
    "jing3"
  ],
  "本意": [
    "ben3 yi4"
  ],
  "鱿": [
    "you2"
  ],
  "后天": [
    "hou4 tian1"
  ],
  "继母": [
    "ji4 mu3"
  ],
  "佸": [
    "huo2"
  ],
  "加油": [
    "jia1 you2"
  ],
  "埕": [
    "cheng2"
  ],
  "履": [
    "lu:3"
  ],
  "销毁": [
    "xiao1 hui3"
  ],
  "决定": [
    "jue2 ding4"
  ],
  "迭": [
    "die2"
  ],
  "倒下": [
    "dao3 xia4"
  ],
  "夐": [
    null
  ],
  "贯穿": [
    "guan4 chuan1"
  ],
  "荙": [
    "da2"
  ],
  "果然": [
    "guo3 ran2"
  ],
  "锐": [
    "rui4"
  ],
  "㙍": [
    "duo1"
  ],
  "簇": [
    "cu4"
  ],
  "开业": [
    "kai1 ye4"
  ],
  "崁": [
    "kan3"
  ],
  "黍": [
    "shu3"
  ],
  "危害": [
    "wei1 hai4"
  ],
  "纴": [
    "ren4"
  ],
  "顺畅": [
    "shun4 chang4"
  ],
  "产生": [
    "chan3 sheng1"
  ],
  "聘": [
    "pin4"
  ],
  "两手": [
    "liang3 shou3"
  ],
  "当今": [
    "dang1 jin1"
  ],
  "匝": [
    "za1",
    "za1"
  ],
  "水龙头": [
    "shui3 long2 tou2"
  ],
  "字眼": [
    "zi4 yan3"
  ],
  "诙": [
    "hui1"
  ],
  "撒": [
    "sa1",
    "sa3"
  ],
  "名副其实": [
    "ming2 fu4 qi2 shi2"
  ],
  "烘": [
    "hong1"
  ],
  "南": [
    "Nan2",
    "nan2"
  ],
  "喟": [
    "kui4"
  ],
  "银牌": [
    "yin2 pai2"
  ],
  "前夕": [
    "qian2 xi1"
  ],
  "棺": [
    "guan1"
  ],
  "㘎": [
    "han3"
  ],
  "网页": [
    "wang3 ye4"
  ],
  "垦": [
    "ken3"
  ],
  "荁": [
    "huan2"
  ],
  "忠诚": [
    "zhong1 cheng2"
  ],
  "㬚": [
    null
  ],
  "撂": [
    "liao4"
  ],
  "篱": [
    "li2"
  ],
  "纵容": [
    "zong4 rong2"
  ],
  "关节": [
    "guan1 jie2"
  ],
  "剃": [
    "ti4",
    "ti4",
    "ti4"
  ],
  "卧室": [
    "wo4 shi4"
  ],
  "祝": [
    "Zhu4",
    "zhu4"
  ],
  "蓰": [
    "xi3"
  ],
  "地图": [
    "di4 tu2"
  ],
  "炉灶": [
    "lu2 zao4"
  ],
  "砜": [
    "feng1"
  ],
  "士气": [
    "shi4 qi4"
  ],
  "蠡": [
    "li2",
    "li3"
  ],
  "彦": [
    "yan4"
  ],
  "贤": [
    "xian2"
  ],
  "箩": [
    "luo2"
  ],
  "槚": [
    "jia3"
  ],
  "抚养费": [
    "fu3 yang3 fei4"
  ],
  "熙": [
    "xi1",
    "xi1"
  ],
  "镦": [
    "dun1"
  ],
  "料理": [
    "liao4 li3"
  ],
  "窗子": [
    "chuang1 zi5"
  ],
  "踮": [
    "dian3"
  ],
  "岸": [
    "an4",
    "an4"
  ],
  "仆": [
    "pu1",
    "pu2"
  ],
  "馊": [
    "sou1"
  ],
  "澡": [
    "zao3"
  ],
  "崎": [
    "qi2"
  ],
  "目光": [
    "mu4 guang1"
  ],
  "当下": [
    "dang1 xia4"
  ],
  "国内": [
    "guo2 nei4"
  ],
  "利益": [
    "li4 yi4"
  ],
  "芴": [
    "hu1",
    "wu4"
  ],
  "佁": [
    null
  ],
  "搐": [
    "chu4"
  ],
  "补给": [
    "bu3 ji3"
  ],
  "忍饥挨饿": [
    "ren3 ji1 ai2 e4"
  ],
  "征收": [
    "zheng1 shou1"
  ],
  "公仆": [
    "gong1 pu2"
  ],
  "心病": [
    "xin1 bing4"
  ],
  "功率": [
    "gong1 lu:4"
  ],
  "公积金": [
    "gong1 ji1 jin1"
  ],
  "草坪": [
    "cao3 ping2"
  ],
  "望见": [
    "wang4 jian4"
  ],
  "转动": [
    "zhuan3 dong4",
    "zhuan4 dong4"
  ],
  "分布": [
    "fen1 bu4"
  ],
  "斧子": [
    "fu3 zi5"
  ],
  "去向": [
    "qu4 xiang4"
  ],
  "大爷": [
    "da4 ye2",
    "da4 ye5"
  ],
  "墘": [
    "qian2"
  ],
  "下棋": [
    "xia4 qi2"
  ],
  "联邦": [
    "lian2 bang1"
  ],
  "酾": [
    "shi1"
  ],
  "描写": [
    "miao2 xie3"
  ],
  "球队": [
    "qiu2 dui4"
  ],
  "床位": [
    "chuang2 wei4"
  ],
  "逯": [
    "Lu4",
    "lu4"
  ],
  "倕": [
    null
  ],
  "堉": [
    "yu4"
  ],
  "晙": [
    "jun4"
  ],
  "纽带": [
    "niu3 dai4"
  ],
  "苑": [
    "Yuan4",
    "yuan4"
  ],
  "笸": [
    "po3"
  ],
  "工地": [
    "gong1 di4"
  ],
  "签": [
    "qian1",
    "qian1",
    "qian1"
  ],
  "檗": [
    "bo4"
  ],
  "箜": [
    "kong1"
  ],
  "认可": [
    "ren4 ke3"
  ],
  "酦": [
    "fa1",
    "po1"
  ],
  "袖珍": [
    "xiu4 zhen1"
  ],
  "隙": [
    "xi4"
  ],
  "辽": [
    "Liao2",
    "liao2"
  ],
  "少年": [
    "shao4 nian2"
  ],
  "主办": [
    "zhu3 ban4"
  ],
  "面部": [
    "mian4 bu4"
  ],
  "激光": [
    "ji1 guang1"
  ],
  "少": [
    "shao3",
    "shao4"
  ],
  "使": [
    "shi3"
  ],
  "怠工": [
    "dai4 gong1"
  ],
  "壬": [
    "ren2"
  ],
  "鼩": [
    "qu2"
  ],
  "此处": [
    "ci3 chu4"
  ],
  "瑞雪": [
    "rui4 xue3"
  ],
  "胺": [
    "an4"
  ],
  "拆": [
    "chai1"
  ],
  "档次": [
    "dang4 ci4"
  ],
  "一": [
    "yi1"
  ],
  "功效": [
    "gong1 xiao4"
  ],
  "多年": [
    "duo1 nian2"
  ],
  "世界杯": [
    "Shi4 jie4 bei1"
  ],
  "詈": [
    "li4"
  ],
  "治病": [
    "zhi4 bing4"
  ],
  "鲩": [
    "huan4"
  ],
  "偻": [
    "lou2",
    "lu:3"
  ],
  "气馁": [
    "qi4 nei3"
  ],
  "普遍": [
    "pu3 bian4"
  ],
  "夺魁": [
    "duo2 kui2"
  ],
  "敏锐": [
    "min3 rui4"
  ],
  "前": [
    "qian2"
  ],
  "氿": [
    "gui3"
  ],
  "通俗": [
    "tong1 su2"
  ],
  "叕": [
    "zhuo2"
  ],
  "怨气": [
    "yuan4 qi4"
  ],
  "高温": [
    "gao1 wen1"
  ],
  "出手": [
    "chu1 shou3"
  ],
  "起源": [
    "qi3 yuan2"
  ],
  "回忆录": [
    "hui2 yi4 lu4"
  ],
  "硬盘": [
    "ying4 pan2"
  ],
  "漫游": [
    "man4 you2"
  ],
  "意志": [
    "yi4 zhi4"
  ],
  "覆盖": [
    "fu4 gai4"
  ],
  "皤": [
    "po2"
  ],
  "动画": [
    "dong4 hua4"
  ],
  "灾难": [
    "zai1 nan4"
  ],
  "整个": [
    "zheng3 ge4"
  ],
  "潮湿": [
    "chao2 shi1"
  ],
  "阈": [
    "yu4"
  ],
  "狷": [
    "juan4",
    "juan4"
  ],
  "涸": [
    "he2"
  ],
  "开机": [
    "kai1 ji1"
  ],
  "表明": [
    "biao3 ming2"
  ],
  "相对而言": [
    "xiang1 dui4 er2 yan2"
  ],
  "娥": [
    "e2"
  ],
  "靡": [
    "mi2",
    "mi3"
  ],
  "瓢": [
    "piao2"
  ],
  "官僚": [
    "guan1 liao2"
  ],
  "种植": [
    "zhong4 zhi2"
  ],
  "交谈": [
    "jiao1 tan2"
  ],
  "宁愿": [
    "ning4 yuan4"
  ],
  "前无古人": [
    "qian2 wu2 gu3 ren2"
  ],
  "胗": [
    "zhen1"
  ],
  "剧团": [
    "ju4 tuan2"
  ],
  "傈": [
    "li4"
  ],
  "征服": [
    "zheng1 fu2"
  ],
  "耑": [
    null
  ],
  "卜": [
    "Bu3",
    "bu3",
    "bo2"
  ],
  "恶意": [
    "e4 yi4"
  ],
  "获": [
    "huo4",
    "huo4"
  ],
  "打工": [
    "da3 gong1"
  ],
  "前沿": [
    "qian2 yan2"
  ],
  "通用": [
    "tong1 yong4"
  ],
  "浅": [
    "jian1",
    "qian3"
  ],
  "疲惫": [
    "pi2 bei4"
  ],
  "铆": [
    "mao3"
  ],
  "委屈": [
    "wei3 qu5"
  ],
  "狮子": [
    "Shi1 zi3",
    "shi1 zi5"
  ],
  "个儿": [
    "ge4 r5"
  ],
  "侵": [
    "qin1"
  ],
  "煜": [
    "yu4"
  ],
  "骄傲": [
    "jiao1 ao4"
  ],
  "承包": [
    "cheng2 bao1"
  ],
  "旿": [
    null
  ],
  "胶囊": [
    "jiao1 nang2"
  ],
  "萳": [
    null
  ],
  "起来": [
    "qi3 lai5",
    "qi5 lai5"
  ],
  "哎": [
    "ai1"
  ],
  "期待": [
    "qi1 dai4"
  ],
  "会议": [
    "hui4 yi4"
  ],
  "碃": [
    "qing4"
  ],
  "劾": [
    "he2"
  ],
  "劳务": [
    "lao2 wu4"
  ],
  "非常": [
    "fei1 chang2"
  ],
  "洚": [
    "jiang4"
  ],
  "以免": [
    "yi3 mian3"
  ],
  "锤": [
    "chui2",
    "chui2"
  ],
  "唱歌": [
    "chang4 ge1"
  ],
  "元老": [
    "yuan2 lao3"
  ],
  "咳嗽": [
    "ke2 sou5"
  ],
  "强劲": [
    "qiang2 jing4"
  ],
  "迳": [
    "jing4"
  ],
  "直观": [
    "zhi2 guan1"
  ],
  "照相": [
    "zhao4 xiang4"
  ],
  "毯": [
    "tan3"
  ],
  "起点": [
    "qi3 dian3"
  ],
  "爱人": [
    "ai4 ren5"
  ],
  "严厉": [
    "yan2 li4"
  ],
  "骗子": [
    "pian4 zi5"
  ],
  "吉祥物": [
    "ji2 xiang2 wu4"
  ],
  "辿": [
    "chan1"
  ],
  "巨头": [
    "ju4 tou2"
  ],
  "坟墓": [
    "fen2 mu4"
  ],
  "一言不发": [
    "yi1 yan2 bu4 fa1"
  ],
  "夜晚": [
    "ye4 wan3"
  ],
  "铠": [
    "kai3"
  ],
  "煮": [
    "zhu3",
    "zhu3"
  ],
  "高压": [
    "gao1 ya1"
  ],
  "受益": [
    "shou4 yi4"
  ],
  "汐": [
    "xi1"
  ],
  "心事": [
    "xin1 shi4"
  ],
  "公平": [
    "gong1 ping2"
  ],
  "便于": [
    "bian4 yu2"
  ],
  "痄": [
    "zha4"
  ],
  "挑选": [
    "tiao1 xuan3"
  ],
  "外号": [
    "wai4 hao4"
  ],
  "牢": [
    "lao2"
  ],
  "蓬": [
    "Peng2",
    "peng2"
  ],
  "无能": [
    "wu2 neng2"
  ],
  "划分": [
    "hua4 fen1"
  ],
  "瀹": [
    "yue4"
  ],
  "渎": [
    "du2"
  ],
  "院长": [
    "yuan4 zhang3"
  ],
  "试点": [
    "shi4 dian3"
  ],
  "原": [
    "Yuan2",
    "yuan2"
  ],
  "打印": [
    "da3 yin4"
  ],
  "披露": [
    "pi1 lu4"
  ],
  "帐子": [
    "zhang4 zi5"
  ],
  "北边": [
    "bei3 bian1"
  ],
  "队形": [
    "dui4 xing2"
  ],
  "矗": [
    "chu4"
  ],
  "那边": [
    "na4 bian5"
  ],
  "堎": [
    "ling2"
  ],
  "区分": [
    "qu1 fen1"
  ],
  "宅": [
    "zhai2"
  ],
  "祇": [
    "qi2"
  ],
  "担心": [
    "dan1 xin1"
  ],
  "长跑": [
    "chang2 pao3"
  ],
  "辛酸": [
    "xin1 suan1"
  ],
  "瞌": [
    "ke1"
  ],
  "鄃": [
    null
  ],
  "忍不住": [
    "ren3 bu5 zhu4"
  ],
  "业": [
    "Ye4",
    "ye4"
  ],
  "天然气": [
    "tian1 ran2 qi4"
  ],
  "专长": [
    "zhuan1 chang2"
  ],
  "缭": [
    "liao2"
  ],
  "失传": [
    "shi1 chuan2"
  ],
  "乖": [
    "guai1"
  ],
  "九": [
    "jiu3"
  ],
  "火速": [
    "huo3 su4"
  ],
  "信誉": [
    "xin4 yu4"
  ],
  "美元": [
    "Mei3 yuan2"
  ],
  "突": [
    "tu1"
  ],
  "从而": [
    "cong2 er2"
  ],
  "无线": [
    "wu2 xian4"
  ],
  "渊": [
    "yuan1"
  ],
  "初": [
    "chu1"
  ],
  "发觉": [
    "fa1 jue2"
  ],
  "杉": [
    "shan1"
  ],
  "铅": [
    "qian1",
    "qian1"
  ],
  "搀": [
    "chan1"
  ],
  "旅游": [
    "lu:3 you2"
  ],
  "腽": [
    "wa4"
  ],
  "噢": [
    "o1"
  ],
  "统筹": [
    "tong3 chou2"
  ],
  "右": [
    "you4"
  ],
  "报仇": [
    "bao4 chou2"
  ],
  "垺": [
    "pou2"
  ],
  "啁": [
    "zhao1",
    "zhou1"
  ],
  "滞": [
    "zhi4"
  ],
  "欢迎": [
    "huan1 ying2"
  ],
  "胞": [
    "bao1"
  ],
  "宝石": [
    "bao3 shi2"
  ],
  "泸": [
    "lu2"
  ],
  "屏": [
    "bing1",
    "bing3",
    "ping2"
  ],
  "碟": [
    "die2"
  ],
  "直奔": [
    "zhi2 ben4"
  ],
  "远处": [
    "yuan3 chu4"
  ],
  "精益求精": [
    "jing1 yi4 qiu2 jing1"
  ],
  "脬": [
    "pao1"
  ],
  "奋力": [
    "fen4 li4"
  ],
  "惆": [
    "chou2"
  ],
  "保证": [
    "bao3 zheng4"
  ],
  "明日": [
    "ming2 ri4"
  ],
  "锜": [
    "qi2"
  ],
  "忧愁": [
    "you1 chou2"
  ],
  "阴影": [
    "yin1 ying3"
  ],
  "劬": [
    "qu2"
  ],
  "签约": [
    "qian1 yue1"
  ],
  "獴": [
    "meng3"
  ],
  "惯性": [
    "guan4 xing4"
  ],
  "玩具": [
    "wan2 ju4"
  ],
  "欺骗": [
    "qi1 pian4"
  ],
  "年画": [
    "nian2 hua4"
  ],
  "有的是": [
    "you3 de5 shi4"
  ],
  "弥补": [
    "mi2 bu3"
  ],
  "薏": [
    "yi4"
  ],
  "丶": [
    "zhu3"
  ],
  "栟": [
    "bing1"
  ],
  "梢": [
    "shao1"
  ],
  "赳": [
    "jiu1"
  ],
  "谅": [
    "liang4"
  ],
  "苌": [
    "Chang2",
    "chang2"
  ],
  "佳": [
    "Jia1",
    "jia1"
  ],
  "草地": [
    "cao3 di4"
  ],
  "货": [
    "huo4"
  ],
  "领域": [
    "ling3 yu4"
  ],
  "奖牌": [
    "jiang3 pai2"
  ],
  "的话": [
    "de5 hua4"
  ],
  "礅": [
    "dun1"
  ],
  "讦": [
    "jie2"
  ],
  "戍": [
    "shu4"
  ],
  "负面": [
    "fu4 mian4"
  ],
  "富豪": [
    "fu4 hao2"
  ],
  "荣誉": [
    "rong2 yu4"
  ],
  "电子邮件": [
    "dian4 zi3 you2 jian4"
  ],
  "诖": [
    "gua4"
  ],
  "当着": [
    "dang1 zhe5"
  ],
  "迮": [
    "ze2"
  ],
  "省事": [
    "sheng3 shi4",
    "xing3 shi4"
  ],
  "马后炮": [
    "ma3 hou4 pao4",
    "ma3 hou4 pao4"
  ],
  "傥": [
    "tang3"
  ],
  "妣": [
    "bi3"
  ],
  "罢工": [
    "ba4 gong1"
  ],
  "咸": [
    "Xian2",
    "xian2",
    "xian2"
  ],
  "冒犯": [
    "mao4 fan4"
  ],
  "㿠": [
    "huang4"
  ],
  "漕": [
    "cao2"
  ],
  "美术": [
    "mei3 shu4"
  ],
  "电话": [
    "dian4 hua4"
  ],
  "折磨": [
    "zhe2 mo2"
  ],
  "所在": [
    "suo3 zai4"
  ],
  "眉毛": [
    "mei2 mao5"
  ],
  "死": [
    "si3"
  ],
  "周围": [
    "zhou1 wei2"
  ],
  "齐": [
    "Qi2",
    "qi2"
  ],
  "气味": [
    "qi4 wei4"
  ],
  "事态": [
    "shi4 tai4"
  ],
  "钍": [
    "tu3"
  ],
  "多边": [
    "duo1 bian1"
  ],
  "砚": [
    "yan4"
  ],
  "拌": [
    "ban4"
  ],
  "层出不穷": [
    "ceng2 chu1 bu4 qiong2"
  ],
  "濩": [
    "huo4"
  ],
  "惺": [
    "xing1"
  ],
  "延": [
    "Yan2",
    "yan2"
  ],
  "拿到": [
    "na2 dao4"
  ],
  "光泽": [
    "Guang1 ze2",
    "guang1 ze2"
  ],
  "悱": [
    "fei3"
  ],
  "旨": [
    "zhi3"
  ],
  "悒": [
    "yi4"
  ],
  "妹": [
    "mei4"
  ],
  "语": [
    "yu3",
    "yu4"
  ],
  "骏马": [
    "jun4 ma3"
  ],
  "学科": [
    "xue2 ke1"
  ],
  "招收": [
    "zhao1 shou1"
  ],
  "奢侈": [
    "she1 chi3"
  ],
  "瑗": [
    "yuan4"
  ],
  "孳": [
    "zi1"
  ],
  "伈": [
    "xin3"
  ],
  "经": [
    "Jing1",
    "jing1"
  ],
  "武": [
    "Wu3",
    "wu3"
  ],
  "觇": [
    "chan1"
  ],
  "抵押": [
    "di3 ya1"
  ],
  "庄稼": [
    "zhuang1 jia5"
  ],
  "火药": [
    "huo3 yao4"
  ],
  "基准": [
    "ji1 zhun3"
  ],
  "抽奖": [
    "chou1 jiang3"
  ],
  "叙": [
    "xu4",
    "Xu4",
    "xu4"
  ],
  "票价": [
    "piao4 jia4"
  ],
  "作品": [
    "zuo4 pin3"
  ],
  "朱红": [
    "zhu1 hong2"
  ],
  "神情": [
    "shen2 qing2"
  ],
  "蒉": [
    "Kui4",
    "kui4"
  ],
  "嘉宾": [
    "jia1 bin1"
  ],
  "搭建": [
    "da1 jian4"
  ],
  "录音机": [
    "lu4 yin1 ji1"
  ],
  "美味": [
    "mei3 wei4"
  ],
  "低估": [
    "di1 gu1"
  ],
  "演": [
    "yan3"
  ],
  "场面": [
    "chang3 mian4"
  ],
  "淯": [
    "Yu4"
  ],
  "呤": [
    "ling2",
    "ling4"
  ],
  "央": [
    "yang1"
  ],
  "输液": [
    "shu1 ye4"
  ],
  "猖狂": [
    "chang1 kuang2"
  ],
  "夜里": [
    "ye4 li5"
  ],
  "孕育": [
    "yun4 yu4"
  ],
  "稚": [
    "zhi4",
    "zhi4",
    "zhi4"
  ],
  "襜": [
    "chan1"
  ],
  "釐": [
    null
  ],
  "勺": [
    "shao2"
  ],
  "酥": [
    "su1"
  ],
  "帮手": [
    "bang1 shou3"
  ],
  "当初": [
    "dang1 chu1"
  ],
  "舟": [
    "zhou1"
  ],
  "谳": [
    "yan4"
  ],
  "碉": [
    "diao1"
  ],
  "感恩": [
    "gan3 en1"
  ],
  "仑": [
    "lun2",
    "lun2",
    "lun2"
  ],
  "按": [
    "an4"
  ],
  "顾及": [
    "gu4 ji2"
  ],
  "邦": [
    "bang1"
  ],
  "截至": [
    "jie2 zhi4"
  ],
  "弢": [
    "tao1"
  ],
  "祲": [
    "jin1"
  ],
  "其后": [
    "qi2 hou4"
  ],
  "䲟": [
    "yin4"
  ],
  "恼": [
    "nao3"
  ],
  "发达": [
    "fa1 da2"
  ],
  "制服": [
    "zhi4 fu2"
  ],
  "潋": [
    "lian4"
  ],
  "翃": [
    "hong2"
  ],
  "堽": [
    "gang1"
  ],
  "新生": [
    "xin1 sheng1"
  ],
  "演唱": [
    "yan3 chang4"
  ],
  "富有": [
    "fu4 you3"
  ],
  "旰": [
    "gan4"
  ],
  "王子": [
    "wang2 zi3"
  ],
  "煤气": [
    "mei2 qi4"
  ],
  "失落": [
    "shi1 luo4"
  ],
  "彬": [
    "bin1"
  ],
  "呱": [
    "gu1"
  ],
  "炸药": [
    "zha4 yao4"
  ],
  "名牌儿": [
    "ming2 pai2 r5"
  ],
  "就诊": [
    "jiu4 zhen3"
  ],
  "修车": [
    "xiu1 che1"
  ],
  "当日": [
    "dang1 ri4",
    "dang4 ri4"
  ],
  "潩": [
    null
  ],
  "赢家": [
    "ying2 jia1"
  ],
  "大意": [
    "da4 yi4",
    "da4 yi5"
  ],
  "控": [
    "kong4"
  ],
  "山顶": [
    "shan1 ding3"
  ],
  "恰如其分": [
    "qia4 ru2 qi2 fen4"
  ],
  "阪": [
    "ban3"
  ],
  "论证": [
    "lun4 zheng4"
  ],
  "灭": [
    "mie4"
  ],
  "涢": [
    "Yun2"
  ],
  "核实": [
    "he2 shi2"
  ],
  "鸶": [
    "si1"
  ],
  "帖": [
    "tie1",
    "tie3",
    "tie4"
  ],
  "寻求": [
    "xun2 qiu2"
  ],
  "蕻": [
    "hong2",
    "hong4"
  ],
  "摞": [
    "luo4"
  ],
  "目": [
    "mu4"
  ],
  "低于": [
    "di1 yu2"
  ],
  "爱面子": [
    "ai4 mian4 zi5"
  ],
  "城区": [
    "cheng2 qu1"
  ],
  "暖烘烘": [
    "nuan3 hong1 hong1"
  ],
  "涘": [
    "si4"
  ],
  "所属": [
    "suo3 shu3"
  ],
  "暧": [
    "ai4"
  ],
  "增多": [
    "zeng1 duo1"
  ],
  "鲀": [
    "tun2"
  ],
  "诩": [
    "xu3"
  ],
  "一向": [
    "yi1 xiang4"
  ],
  "多元": [
    "duo1 yuan2"
  ],
  "艇": [
    "ting3"
  ],
  "烁": [
    "shuo4"
  ],
  "赋予": [
    "fu4 yu3"
  ],
  "开天辟地": [
    "kai1 tian1 pi4 di4"
  ],
  "大哥": [
    "da4 ge1"
  ],
  "尽": [
    "jin3",
    "jin4"
  ],
  "换取": [
    "huan4 qu3"
  ],
  "大夫": [
    "da4 fu1",
    "dai4 fu5"
  ],
  "其中": [
    "qi2 zhong1"
  ],
  "放弃": [
    "fang4 qi4"
  ],
  "界定": [
    "jie4 ding4"
  ],
  "忧虑": [
    "you1 lu:4"
  ],
  "前者": [
    "qian2 zhe3"
  ],
  "使者": [
    "shi3 zhe3"
  ],
  "霞": [
    "xia2"
  ],
  "梧": [
    "wu2"
  ],
  "直到": [
    "zhi2 dao4"
  ],
  "煸": [
    "bian1"
  ],
  "爔": [
    "xi1"
  ],
  "跑道": [
    "pao3 dao4"
  ],
  "岳母": [
    "yue4 mu3"
  ],
  "完毕": [
    "wan2 bi4"
  ],
  "卯": [
    "mao3",
    "mao3",
    "mao3"
  ],
  "鹔": [
    "su4"
  ],
  "乔装": [
    "qiao2 zhuang1"
  ],
  "乃": [
    "nai3",
    "nai3"
  ],
  "中奖": [
    "zhong4 jiang3"
  ],
  "跟不上": [
    "gen1 bu5 shang4"
  ],
  "演员": [
    "yan3 yuan2"
  ],
  "狨": [
    "rong2"
  ],
  "拖鞋": [
    "tuo1 xie2"
  ],
  "约会": [
    "yue1 hui4"
  ],
  "税": [
    "shui4"
  ],
  "衙": [
    "Ya2",
    "ya2"
  ],
  "成绩": [
    "cheng2 ji4"
  ],
  "购物": [
    "gou4 wu4"
  ],
  "差错": [
    "cha1 cuo4"
  ],
  "澹": [
    "Tan2",
    "dan4"
  ],
  "地名": [
    "di4 ming2"
  ],
  "她": [
    "ta1"
  ],
  "信封": [
    "xin4 feng1"
  ],
  "恐怖": [
    "kong3 bu4"
  ],
  "汴": [
    "Bian4"
  ],
  "求救": [
    "qiu2 jiu4"
  ],
  "护理": [
    "hu4 li3"
  ],
  "说起来": [
    null
  ],
  "疏导": [
    "shu1 dao3"
  ],
  "鸸": [
    "er2"
  ],
  "贡献": [
    "gong4 xian4"
  ],
  "质地": [
    "zhi4 di4"
  ],
  "解答": [
    "jie3 da2"
  ],
  "犷": [
    "guang3"
  ],
  "腑": [
    "fu3"
  ],
  "榉": [
    "ju3"
  ],
  "鳅": [
    "qiu1"
  ],
  "铤": [
    "ding4",
    "ting3"
  ],
  "酢": [
    "cu4",
    "zuo4"
  ],
  "顾问": [
    "gu4 wen4"
  ],
  "杷": [
    "ba4",
    "pa2"
  ],
  "由衷": [
    "you2 zhong1"
  ],
  "统治": [
    "tong3 zhi4"
  ],
  "书记": [
    "shu1 ji5"
  ],
  "嫫": [
    "mo2"
  ],
  "噍": [
    "jiao4"
  ],
  "勉强": [
    "mian3 qiang3"
  ],
  "询": [
    "xun2"
  ],
  "人": [
    "ren2"
  ],
  "佤": [
    "wa3"
  ],
  "罗": [
    "Luo2",
    "luo2"
  ],
  "爚": [
    "yue4"
  ],
  "羰": [
    "tang1"
  ],
  "难处": [
    "nan2 chu5"
  ],
  "渐渐": [
    "jian4 jian4"
  ],
  "肖": [
    "Xiao1",
    "xiao4"
  ],
  "音量": [
    "yin1 liang4"
  ],
  "电视": [
    "dian4 shi4"
  ],
  "埼": [
    "qi2"
  ],
  "燧": [
    "sui4"
  ],
  "沸腾": [
    "fei4 teng2"
  ],
  "胸膛": [
    "xiong1 tang2"
  ],
  "棼": [
    "fen2"
  ],
  "看不起": [
    "kan4 bu5 qi3"
  ],
  "荣获": [
    "rong2 huo4"
  ],
  "视频": [
    "shi4 pin2"
  ],
  "纱": [
    "sha1"
  ],
  "姑娘": [
    "gu1 niang5"
  ],
  "斤": [
    "jin1"
  ],
  "樯": [
    "qiang2",
    "qiang2"
  ],
  "弱势": [
    "ruo4 shi4"
  ],
  "铽": [
    "te4"
  ],
  "声誉": [
    "sheng1 yu4"
  ],
  "亲属": [
    "qin1 shu3"
  ],
  "木头": [
    "mu4 tou5"
  ],
  "铄": [
    "shuo4"
  ],
  "默契": [
    "mo4 qi4"
  ],
  "溲": [
    "sou1"
  ],
  "牧民": [
    "mu4 min2"
  ],
  "嗓子": [
    "sang3 zi5"
  ],
  "蚋": [
    "rui4"
  ],
  "琴": [
    "qin2",
    "qin2"
  ],
  "重建": [
    "chong2 jian4"
  ],
  "殇": [
    "shang1"
  ],
  "尴": [
    "gan1"
  ],
  "浑身": [
    "hun2 shen1"
  ],
  "措": [
    "cuo4"
  ],
  "微波炉": [
    "wei1 bo1 lu2"
  ],
  "寻找": [
    "xun2 zhao3"
  ],
  "渣子": [
    "zha1 zi5"
  ],
  "括": [
    "kuo4"
  ],
  "细菌": [
    "xi4 jun1"
  ],
  "动弹": [
    "dong4 tan5"
  ],
  "房价": [
    "fang2 jia4"
  ],
  "往来": [
    "wang3 lai2"
  ],
  "鳀": [
    "ti2"
  ],
  "湣": [
    "min3"
  ],
  "团长": [
    "tuan2 zhang3"
  ],
  "措施": [
    "cuo4 shi1"
  ],
  "嫁": [
    "jia4"
  ],
  "懿": [
    "yi4"
  ],
  "音像": [
    "yin1 xiang4"
  ],
  "劣质": [
    "lie4 zhi4"
  ],
  "假": [
    "gei1",
    "jia3",
    "jia4",
    "jia3"
  ],
  "崛起": [
    "jue2 qi3"
  ],
  "标榜": [
    "biao1 bang3"
  ],
  "翷": [
    null
  ],
  "眼": [
    "yan3"
  ],
  "畹": [
    "wan3"
  ],
  "主演": [
    "zhu3 yan3"
  ],
  "肥沃": [
    "fei2 wo4"
  ],
  "过程": [
    "guo4 cheng2"
  ],
  "没想到": [
    "mei2 xiang3 dao4"
  ],
  "昌": [
    "Chang1",
    "chang1"
  ],
  "饴": [
    "yi2"
  ],
  "虽说": [
    "sui1 shuo1"
  ],
  "企图": [
    "qi3 tu2"
  ],
  "岗": [
    "gang1",
    "gang3"
  ],
  "稔": [
    "ren3"
  ],
  "警官": [
    "jing3 guan1"
  ],
  "跻": [
    "ji1"
  ],
  "韵": [
    "yun4"
  ],
  "正能量": [
    "zheng4 neng2 liang4"
  ],
  "根": [
    "gen1"
  ],
  "不亚于": [
    "bu4 ya4 yu2"
  ],
  "菡": [
    "han4"
  ],
  "追究": [
    "zhui1 jiu1"
  ],
  "犏": [
    "pian1"
  ],
  "竫": [
    null
  ],
  "每": [
    "mei3"
  ],
  "只见": [
    "zhi3 jian4"
  ],
  "种种": [
    "zhong3 zhong3"
  ],
  "槐树": [
    "huai2 shu4"
  ],
  "工具": [
    "gong1 ju4"
  ],
  "下决心": [
    "xia4 jue2 xin1"
  ],
  "公斤": [
    "gong1 jin1"
  ],
  "酌": [
    "zhuo2"
  ],
  "亲戚": [
    "qin1 qi5"
  ],
  "纪律": [
    "ji4 lu:4"
  ],
  "耖": [
    "chao4"
  ],
  "妨碍": [
    "fang2 ai4"
  ],
  "出丑": [
    "chu1 chou3"
  ],
  "各自": [
    "ge4 zi4"
  ],
  "铺路": [
    "pu1 lu4"
  ],
  "富人": [
    "fu4 ren2"
  ],
  "敌": [
    "di2"
  ],
  "搦": [
    "nuo4"
  ],
  "邗": [
    "han2"
  ],
  "已": [
    "yi3"
  ],
  "款": [
    "kuan3"
  ],
  "跄": [
    "qiang1",
    "qiang4"
  ],
  "两侧": [
    "liang3 ce4"
  ],
  "学生": [
    "xue2 sheng5"
  ],
  "漋": [
    null
  ],
  "臬": [
    "nie4"
  ],
  "㮾": [
    null
  ],
  "邪": [
    "xie2",
    "xie2"
  ],
  "充实": [
    "chong1 shi2"
  ],
  "财富": [
    "cai2 fu4"
  ],
  "通红": [
    "tong1 hong2"
  ],
  "瑓": [
    null
  ],
  "堑": [
    "qian4"
  ],
  "频频": [
    "pin2 pin2"
  ],
  "凑巧": [
    "cou4 qiao3"
  ],
  "广场": [
    "guang3 chang3"
  ],
  "时机": [
    "shi2 ji1"
  ],
  "国家": [
    "guo2 jia1"
  ],
  "过意不去": [
    "guo4 yi4 bu4 qu4"
  ],
  "正是": [
    "zheng4 shi4"
  ],
  "兴旺": [
    "xing1 wang4"
  ],
  "出游": [
    "chu1 you2"
  ],
  "京": [
    "Jing1",
    "jing1"
  ],
  "淡季": [
    "dan4 ji4"
  ],
  "见过": [
    null
  ],
  "郦": [
    "Li4"
  ],
  "嗦": [
    "suo1"
  ],
  "仙女": [
    "xian1 nu:3"
  ],
  "仄": [
    "ze4"
  ],
  "公主": [
    "gong1 zhu3"
  ],
  "公款": [
    "gong1 kuan3"
  ],
  "鸱": [
    "chi1"
  ],
  "辫": [
    "bian4"
  ],
  "蓍": [
    "shi1"
  ],
  "严峻": [
    "yan2 jun4"
  ],
  "果汁": [
    "guo3 zhi1"
  ],
  "镀": [
    "du4"
  ],
  "复杂": [
    "fu4 za2"
  ],
  "曛": [
    "xun1"
  ],
  "含糊": [
    "han2 hu5"
  ],
  "堕落": [
    "duo4 luo4"
  ],
  "郊": [
    "Jiao1",
    "jiao1"
  ],
  "开设": [
    "kai1 she4"
  ],
  "核电站": [
    "he2 dian4 zhan4"
  ],
  "司长": [
    "si1 zhang3"
  ],
  "方面": [
    "fang1 mian4"
  ],
  "立交桥": [
    "li4 jiao1 qiao2"
  ],
  "台灯": [
    "tai2 deng1"
  ],
  "弹": [
    "dan4",
    "tan2"
  ],
  "清晰": [
    "qing1 xi1"
  ],
  "铺": [
    "pu4",
    "pu1",
    "pu4"
  ],
  "预订": [
    "yu4 ding4"
  ],
  "胸有成竹": [
    "xiong1 you3 cheng2 zhu2"
  ],
  "顺利": [
    "shun4 li4"
  ],
  "怯": [
    "qie4"
  ],
  "拗": [
    "ao4",
    "ao4",
    "niu4"
  ],
  "年": [
    "Nian2",
    "nian2",
    "nian2"
  ],
  "生命线": [
    "sheng1 ming4 xian4"
  ],
  "化解": [
    "hua4 jie3"
  ],
  "赞不绝口": [
    "zan4 bu4 jue2 kou3",
    "zan4 bu4 jue2 kou3"
  ],
  "蟑": [
    "zhang1"
  ],
  "清单": [
    "qing1 dan1"
  ],
  "滘": [
    "jiao4"
  ],
  "循环": [
    "xun2 huan2"
  ],
  "特定": [
    "te4 ding4"
  ],
  "握手": [
    "wo4 shou3"
  ],
  "骣": [
    "chan3"
  ],
  "懒得": [
    "lan3 de2"
  ],
  "驰": [
    "chi2"
  ],
  "泥土": [
    "ni2 tu3"
  ],
  "膳食": [
    "shan4 shi2"
  ],
  "海量": [
    "hai3 liang4"
  ],
  "翰": [
    "Han4",
    "han4"
  ],
  "鹜": [
    "wu4"
  ],
  "办事处": [
    "ban4 shi4 chu4"
  ],
  "藦": [
    "mo2"
  ],
  "见": [
    "jian4",
    "xian4"
  ],
  "鞭策": [
    "bian1 ce4"
  ],
  "小姐": [
    "xiao3 jie5"
  ],
  "姥爷": [
    "lao3 ye5"
  ],
  "返": [
    "fan3"
  ],
  "小偷儿": [
    "xiao3 tou1 r5"
  ],
  "长期": [
    "chang2 qi1"
  ],
  "账单": [
    "zhang4 dan1"
  ],
  "吓唬": [
    "xia4 hu5"
  ],
  "奋": [
    "fen4"
  ],
  "团圆": [
    "tuan2 yuan2"
  ],
  "主义": [
    "zhu3 yi4"
  ],
  "昳": [
    "die2"
  ],
  "完好": [
    "wan2 hao3"
  ],
  "娉": [
    "ping1"
  ],
  "俜": [
    "ping1"
  ],
  "恩怨": [
    "en1 yuan4"
  ],
  "廉价": [
    "lian2 jia4"
  ],
  "绥": [
    "sui2"
  ],
  "东南": [
    "dong1 nan2"
  ],
  "岫": [
    "xiu4"
  ],
  "澶": [
    "chan2"
  ],
  "家务": [
    "jia1 wu4"
  ],
  "戈": [
    "Ge1",
    "ge1"
  ],
  "腘": [
    "guo2"
  ],
  "规范": [
    "gui1 fan4"
  ],
  "敫": [
    "Jiao3",
    "jiao3"
  ],
  "备课": [
    "bei4 ke4"
  ],
  "摁": [
    "en4"
  ],
  "一再": [
    "yi1 zai4"
  ],
  "深": [
    "shen1",
    "shen1"
  ],
  "薳": [
    "Wei3"
  ],
  "发行": [
    "fa1 xing2"
  ],
  "药店": [
    "yao4 dian4"
  ],
  "怀": [
    "Huai2",
    "huai2"
  ],
  "大雁": [
    "da4 yan4"
  ],
  "方式": [
    "fang1 shi4"
  ],
  "浪漫": [
    "lang4 man4"
  ],
  "滔": [
    "tao1"
  ],
  "恩情": [
    "en1 qing2"
  ],
  "殂": [
    "cu2"
  ],
  "震动": [
    "zhen4 dong4"
  ],
  "谁": [
    "shei2"
  ],
  "富强": [
    "fu4 qiang2"
  ],
  "刚才": [
    "gang1 cai2",
    "gang1 cai2"
  ],
  "醋": [
    "cu4"
  ],
  "䴙": [
    "pi4",
    "pi4"
  ],
  "议题": [
    "yi4 ti2"
  ],
  "留学": [
    "liu2 xue2"
  ],
  "伤口": [
    "shang1 kou3"
  ],
  "肫": [
    "zhun1"
  ],
  "汕": [
    "shan4"
  ],
  "择": [
    "ze2"
  ],
  "僇": [
    "lu4"
  ],
  "酪": [
    "lao4"
  ],
  "是": [
    "shi4",
    "shi4"
  ],
  "等级": [
    "deng3 ji2"
  ],
  "怖": [
    "bu4"
  ],
  "诅": [
    "zu3"
  ],
  "恃": [
    "shi4"
  ],
  "俯": [
    "fu3",
    "fu3"
  ],
  "愤怒": [
    "fen4 nu4"
  ],
  "掳": [
    "lu3"
  ],
  "漾": [
    "yang4"
  ],
  "劝阻": [
    "quan4 zu3"
  ],
  "马戏": [
    "ma3 xi4"
  ],
  "回应": [
    "hui2 ying4"
  ],
  "金钱": [
    "jin1 qian2"
  ],
  "依": [
    "yi1"
  ],
  "普通话": [
    "pu3 tong1 hua4"
  ],
  "细致": [
    "xi4 zhi4"
  ],
  "新手": [
    "xin1 shou3"
  ],
  "腮": [
    "sai1",
    "sai1"
  ],
  "辙": [
    "zhe2"
  ],
  "加上": [
    "jia1 shang4"
  ],
  "一阵": [
    "yi1 zhen4"
  ],
  "糊": [
    "hu2",
    "hu2",
    "hu4",
    "hu2"
  ],
  "来临": [
    "lai2 lin2"
  ],
  "传": [
    "chuan2",
    "zhuan4"
  ],
  "陶冶": [
    "tao2 ye3"
  ],
  "做生意": [
    "zuo4 sheng1 yi4"
  ],
  "卖": [
    "mai4"
  ],
  "蛇": [
    "she2",
    "she2"
  ],
  "澍": [
    "shu4"
  ],
  "大有可为": [
    "da4 you3 ke3 wei2"
  ],
  "患病": [
    "huan4 bing4"
  ],
  "枧": [
    "jian3"
  ],
  "和睦": [
    "he2 mu4"
  ],
  "一模一样": [
    "yi1 mu2 yi1 yang4"
  ],
  "愤": [
    "fen4"
  ],
  "苗": [
    "Miao2",
    "miao2"
  ],
  "轻易": [
    "qing1 yi4"
  ],
  "深奥": [
    "shen1 ao4"
  ],
  "神": [
    "Shen2",
    "shen2"
  ],
  "万古长青": [
    "wan4 gu3 chang2 qing1"
  ],
  "予以": [
    "yu3 yi3"
  ],
  "悈": [
    null
  ],
  "汔": [
    "qi4"
  ],
  "当然": [
    "dang1 ran2"
  ],
  "宽泛": [
    "kuan1 fan4"
  ],
  "软实力": [
    "ruan3 shi2 li4"
  ],
  "鸩": [
    "zhen4"
  ],
  "缩影": [
    "suo1 ying3"
  ],
  "偿还": [
    "chang2 huan2"
  ],
  "馃": [
    "guo3"
  ],
  "苡": [
    "yi3"
  ],
  "乱七八糟": [
    "luan4 qi1 ba1 zao1"
  ],
  "尚未": [
    "shang4 wei4"
  ],
  "加重": [
    "jia1 zhong4"
  ],
  "缱": [
    "qian3"
  ],
  "比试": [
    "bi3 shi4"
  ],
  "中途": [
    "zhong1 tu2"
  ],
  "厂家": [
    "chang3 jia1"
  ],
  "你们": [
    "ni3 men5"
  ],
  "烺": [
    "lang3"
  ],
  "计算": [
    "ji4 suan4"
  ],
  "体能": [
    "ti3 neng2"
  ],
  "南北": [
    "nan2 bei3"
  ],
  "亅": [
    "jue2"
  ],
  "名": [
    "ming2"
  ],
  "趄": [
    "ju1",
    "qie4"
  ],
  "时髦": [
    "shi2 mao2"
  ],
  "你": [
    "ni3",
    "ni3"
  ],
  "疤": [
    "ba1"
  ],
  "纤": [
    "qian4",
    "xian1"
  ],
  "强化": [
    "qiang2 hua4"
  ],
  "锒": [
    "lang2"
  ],
  "量": [
    "liang2",
    "liang4"
  ],
  "挓": [
    "zha1"
  ],
  "赔偿": [
    "pei2 chang2"
  ],
  "相似": [
    "xiang1 si4"
  ],
  "高于": [
    "gao1 yu2"
  ],
  "钙": [
    "gai4"
  ],
  "实力": [
    "shi2 li4"
  ],
  "维护": [
    "wei2 hu4"
  ],
  "愿意": [
    "yuan4 yi4"
  ],
  "暑期": [
    "shu3 qi1"
  ],
  "骤然": [
    "zhou4 ran2"
  ],
  "不够": [
    "bu4 gou4"
  ],
  "皎": [
    "jiao3"
  ],
  "步行": [
    "bu4 xing2"
  ],
  "兴起": [
    "xing1 qi3"
  ],
  "秋": [
    "Qiu1",
    "qiu1",
    "qiu1",
    "qiu1"
  ],
  "基于": [
    "ji1 yu2"
  ],
  "透气": [
    "tou4 qi4"
  ],
  "艾": [
    "Ai4",
    "ai4",
    "yi4"
  ],
  "菖": [
    "chang1"
  ],
  "怵": [
    "chu4"
  ],
  "舠": [
    "dao1"
  ],
  "就任": [
    "jiu4 ren4"
  ],
  "同人": [
    "tong2 ren2"
  ],
  "突然": [
    "tu1 ran2"
  ],
  "鲌": [
    "bo2"
  ],
  "饮水": [
    "yin3 shui3"
  ],
  "饺子": [
    "jiao3 zi5"
  ],
  "杓": [
    "biao1",
    "shao2"
  ],
  "中国画": [
    "Zhong1 guo2 hua4"
  ],
  "刿": [
    "gui4"
  ],
  "膑": [
    "bin4"
  ],
  "裼": [
    "ti4",
    "xi1"
  ],
  "越来越": [
    "yue4 lai2 yue4"
  ],
  "鬲": [
    "Ge2",
    "ge2",
    "li4"
  ],
  "猫": [
    "mao1"
  ],
  "腐蚀": [
    "fu3 shi2"
  ],
  "牌": [
    "pai2"
  ],
  "沺": [
    "tian2"
  ],
  "出租": [
    "chu1 zu1"
  ],
  "生": [
    "sheng1"
  ],
  "殣": [
    "jin4"
  ],
  "纳闷儿": [
    "na4 men4 r5"
  ],
  "信仰": [
    "xin4 yang3"
  ],
  "适时": [
    "shi4 shi2"
  ],
  "蠕": [
    "ru2",
    "ru2"
  ],
  "纺织": [
    "fang3 zhi1"
  ],
  "战争": [
    "zhan4 zheng1"
  ],
  "防守": [
    "fang2 shou3"
  ],
  "自主": [
    "zi4 zhu3"
  ],
  "排练": [
    "pai2 lian4"
  ],
  "束缚": [
    "shu4 fu4"
  ],
  "币": [
    "bi4"
  ],
  "锈": [
    "xiu4",
    "xiu4"
  ],
  "鲸": [
    "jing1"
  ],
  "申请": [
    "shen1 qing3"
  ],
  "睿": [
    "rui4",
    "rui4"
  ],
  "办事": [
    "ban4 shi4"
  ],
  "墩": [
    "dun1",
    "dun1"
  ],
  "葬礼": [
    "zang4 li3"
  ],
  "劄": [
    "zha1"
  ],
  "只好": [
    "zhi3 hao3"
  ],
  "苗头": [
    "miao2 tou5"
  ],
  "不用": [
    "bu4 yong4"
  ],
  "呲": [
    "ci1",
    "zi1"
  ],
  "奓": [
    "chi3",
    "she1",
    "zha4"
  ],
  "胃口": [
    "wei4 kou3"
  ],
  "嶷": [
    "yi2"
  ],
  "呼风唤雨": [
    "hu1 feng1 huan4 yu3"
  ],
  "反抗": [
    "fan3 kang4"
  ],
  "蜂蜜": [
    "feng1 mi4"
  ],
  "棠": [
    "tang2"
  ],
  "相比": [
    "xiang1 bi3"
  ],
  "龈": [
    "ken3",
    "yin2"
  ],
  "所": [
    "suo3"
  ],
  "芏": [
    "du4"
  ],
  "畚": [
    "ben3"
  ],
  "泼": [
    "po1"
  ],
  "战": [
    "zhan4"
  ],
  "獠": [
    "liao2"
  ],
  "览": [
    "lan3"
  ],
  "瘟疫": [
    "wen1 yi4"
  ],
  "绕": [
    "rao4",
    "rao4"
  ],
  "国情": [
    "guo2 qing2"
  ],
  "仵": [
    "Wu3",
    "wu3"
  ],
  "就要": [
    "jiu4 yao4"
  ],
  "美景": [
    "mei3 jing3"
  ],
  "戳": [
    "chuo1"
  ],
  "粉丝": [
    "fen3 si1"
  ],
  "共同": [
    "gong4 tong2"
  ],
  "俗": [
    "su2"
  ],
  "弄虚作假": [
    "nong4 xu1 zuo4 jia3"
  ],
  "客房": [
    "ke4 fang2"
  ],
  "袱": [
    "fu2"
  ],
  "邓": [
    "Deng4"
  ],
  "低碳": [
    "di1 tan4"
  ],
  "菏": [
    "He2"
  ],
  "燃气": [
    "ran2 qi4"
  ],
  "北极": [
    "bei3 ji2"
  ],
  "头顶": [
    "tou2 ding3"
  ],
  "蕊": [
    "rui3",
    "rui3",
    "rui3",
    "rui3"
  ],
  "合作": [
    "he2 zuo4"
  ],
  "裂缝": [
    "lie4 feng4"
  ],
  "播放": [
    "bo1 fang4"
  ],
  "罕": [
    "han3"
  ],
  "馁": [
    "nei3"
  ],
  "崚": [
    "leng2"
  ],
  "担": [
    "dan1",
    "dan4"
  ],
  "亏本": [
    "kui1 ben3"
  ],
  "路况": [
    "lu4 kuang4"
  ],
  "而是": [
    "er2 shi4"
  ],
  "大腕儿": [
    "da4 wan4 r5"
  ],
  "辛": [
    "Xin1",
    "xin1"
  ],
  "租金": [
    "zu1 jin1"
  ],
  "修改": [
    "xiu1 gai3"
  ],
  "消毒": [
    "xiao1 du2"
  ],
  "骨气": [
    "gu3 qi4"
  ],
  "商店": [
    "shang1 dian4"
  ],
  "偏远": [
    "pian1 yuan3"
  ],
  "幢": [
    "chuang2",
    "zhuang4"
  ],
  "半数": [
    "ban4 shu4"
  ],
  "体系": [
    "ti3 xi4"
  ],
  "生育": [
    "sheng1 yu4"
  ],
  "赆": [
    "jin4"
  ],
  "冂": [
    "jiong1"
  ],
  "舰": [
    "jian4"
  ],
  "榍": [
    null
  ],
  "生怕": [
    "sheng1 pa4"
  ],
  "浭": [
    "Geng1"
  ],
  "杪": [
    "miao3"
  ],
  "影": [
    "ying3"
  ],
  "遆": [
    null
  ],
  "噘": [
    "jue1"
  ],
  "溞": [
    null
  ],
  "从容不迫": [
    "cong2 rong2 bu4 po4"
  ],
  "门": [
    "Men2",
    "men2"
  ],
  "岊": [
    "jie2"
  ],
  "辅助": [
    "fu3 zhu4"
  ],
  "预算": [
    "yu4 suan4"
  ],
  "苞": [
    "bao1"
  ],
  "伴奏": [
    "ban4 zou4"
  ],
  "门票": [
    "men2 piao4"
  ],
  "为什么": [
    "wei4 shen2 me5"
  ],
  "课堂": [
    "ke4 tang2"
  ],
  "胳": [
    "ge1",
    "ge1"
  ],
  "或": [
    "huo4"
  ],
  "理直气壮": [
    "li3 zhi2 qi4 zhuang4"
  ],
  "玫瑰": [
    "mei2 gui1"
  ],
  "蓝天": [
    "lan2 tian1"
  ],
  "鼻子": [
    "bi2 zi5"
  ],
  "拜会": [
    "bai4 hui4"
  ],
  "备受": [
    "bei4 shou4"
  ],
  "惴": [
    "zhui4"
  ],
  "水灾": [
    "shui3 zai1"
  ],
  "告示": [
    "gao4 shi5"
  ],
  "求证": [
    "qiu2 zheng4"
  ],
  "空虚": [
    "kong1 xu1"
  ],
  "岽": [
    "Dong1"
  ],
  "下山": [
    "xia4 shan1"
  ],
  "骟": [
    "shan4"
  ],
  "当成": [
    "dang4 cheng2"
  ],
  "鹃": [
    "juan1"
  ],
  "注入": [
    "zhu4 ru4"
  ],
  "神秘": [
    "shen2 mi4"
  ],
  "军事": [
    "jun1 shi4"
  ],
  "陌": [
    "mo4"
  ],
  "轮换": [
    "lun2 huan4"
  ],
  "辆": [
    "liang4"
  ],
  "亲和力": [
    "qin1 he2 li4"
  ],
  "捺": [
    "na4"
  ],
  "手头": [
    "shou3 tou2"
  ],
  "透露": [
    "tou4 lu4"
  ],
  "补助": [
    "bu3 zhu4"
  ],
  "锪": [
    "huo1"
  ],
  "嗵": [
    "tong1"
  ],
  "梃": [
    "ting3"
  ],
  "洧": [
    "wei3"
  ],
  "钕": [
    "nu:3"
  ],
  "诔": [
    "lei3"
  ],
  "感动": [
    "gan3 dong4"
  ],
  "发作": [
    "fa1 zuo4"
  ],
  "收取": [
    "shou1 qu3"
  ],
  "查": [
    "Zha1",
    "cha2",
    "zha1"
  ],
  "挚": [
    "Zhi4",
    "zhi4"
  ],
  "发火": [
    "fa1 huo3"
  ],
  "额": [
    "e2",
    "e2"
  ],
  "饲": [
    "si4",
    "si4"
  ],
  "酤": [
    "gu1"
  ],
  "浦": [
    "Pu3",
    "pu3"
  ],
  "疫": [
    "yi4"
  ],
  "毒品": [
    "du2 pin3"
  ],
  "防盗": [
    "fang2 dao4"
  ],
  "嚭": [
    "pi3"
  ],
  "赫然": [
    "he4 ran2"
  ],
  "船舶": [
    "chuan2 bo2"
  ],
  "难以想象": [
    null
  ],
  "及格": [
    "ji2 ge2"
  ],
  "涌入": [
    "yong3 ru4"
  ],
  "情": [
    "qing2"
  ],
  "油": [
    "you2"
  ],
  "颦": [
    "pin2"
  ],
  "棱角": [
    "leng2 jiao3"
  ],
  "工整": [
    "gong1 zheng3"
  ],
  "髋": [
    "kuan1"
  ],
  "数据": [
    "shu4 ju4"
  ],
  "界线": [
    "jie4 xian4"
  ],
  "野外": [
    "ye3 wai4"
  ],
  "精练": [
    "jing1 lian4"
  ],
  "姘": [
    "pin1"
  ],
  "饯": [
    "jian4"
  ],
  "疑惑": [
    "yi2 huo4"
  ],
  "转告": [
    "zhuan3 gao4"
  ],
  "廿": [
    "nian4"
  ],
  "酷似": [
    "ku4 si4"
  ],
  "碏": [
    "que4"
  ],
  "比不上": [
    "bi3 bu4 shang4"
  ],
  "热潮": [
    "re4 chao2"
  ],
  "流失": [
    "liu2 shi1"
  ],
  "碰上": [
    "peng4 shang4"
  ],
  "人们": [
    "ren2 men5"
  ],
  "餮": [
    "tie4"
  ],
  "胚": [
    "pei1",
    "pei1"
  ],
  "系": [
    "xi4",
    "xi4",
    "ji4",
    "xi4"
  ],
  "钜": [
    "ju4"
  ],
  "保守": [
    "bao3 shou3"
  ],
  "万": [
    "mo4",
    "Wan4",
    "wan4"
  ],
  "上司": [
    "shang4 si5"
  ],
  "新潮": [
    "xin1 chao2"
  ],
  "圆": [
    "yuan2"
  ],
  "愔": [
    "yin1"
  ],
  "丑陋": [
    "chou3 lou4"
  ],
  "毅力": [
    "yi4 li4"
  ],
  "櫆": [
    "kui2"
  ],
  "输入": [
    "shu1 ru4"
  ],
  "场馆": [
    "chang3 guan3"
  ],
  "推销": [
    "tui1 xiao1"
  ],
  "礼貌": [
    "li3 mao4"
  ],
  "甦": [
    null
  ],
  "墓地": [
    "mu4 di4"
  ],
  "剥": [
    "bao1",
    "bo1"
  ],
  "泗": [
    "Si4",
    "si4"
  ],
  "噫": [
    "yi1"
  ],
  "鞍": [
    "an1",
    "an1"
  ],
  "清明节": [
    "Qing1 ming2 jie2"
  ],
  "善意": [
    "shan4 yi4"
  ],
  "凭证": [
    "ping2 zheng4"
  ],
  "超越": [
    "chao1 yue4"
  ],
  "半路": [
    "ban4 lu4"
  ],
  "裣": [
    "lian3"
  ],
  "饬": [
    "chi4"
  ],
  "许可证": [
    "xu3 ke3 zheng4"
  ],
  "柜": [
    "gui4",
    "ju3",
    "gui4"
  ],
  "得手": [
    "de2 shou3"
  ],
  "鐾": [
    "bei4"
  ],
  "太太": [
    "tai4 tai5"
  ],
  "外地": [
    "wai4 di4"
  ],
  "滉": [
    "huang3"
  ],
  "鳤": [
    "guan3"
  ],
  "掉队": [
    "diao4 dui4"
  ],
  "贸": [
    "mao4"
  ],
  "酒吧": [
    "jiu3 ba1"
  ],
  "风光": [
    "feng1 guang1"
  ],
  "屁": [
    "pi4"
  ],
  "促使": [
    "cu4 shi3"
  ],
  "畔": [
    "pan4"
  ],
  "兄": [
    "xiong1"
  ],
  "要点": [
    "yao4 dian3"
  ],
  "恺": [
    "kai3"
  ],
  "四季": [
    "si4 ji4"
  ],
  "砥": [
    "di3"
  ],
  "纶": [
    "lun2"
  ],
  "回馈": [
    "hui2 kui4"
  ],
  "憎": [
    "zeng1"
  ],
  "比重": [
    "bi3 zhong4"
  ],
  "指导": [
    "zhi3 dao3"
  ],
  "咎": [
    "jiu4"
  ],
  "瘠": [
    "ji2"
  ],
  "嘣": [
    "beng1"
  ],
  "渐": [
    "jian1",
    "jian4"
  ],
  "提速": [
    "ti2 su4"
  ],
  "酯": [
    "zhi3"
  ],
  "堞": [
    "die2"
  ],
  "栴": [
    "zhan1"
  ],
  "平凡": [
    "ping2 fan2"
  ],
  "钡": [
    "bei4"
  ],
  "嗓": [
    "sang3"
  ],
  "表彰": [
    "biao3 zhang1"
  ],
  "野炊": [
    "ye3 chui1"
  ],
  "趾": [
    "zhi3"
  ],
  "小时": [
    "xiao3 shi2"
  ],
  "误差": [
    "wu4 cha1"
  ],
  "惰": [
    "duo4"
  ],
  "夸张": [
    "kua1 zhang1"
  ],
  "鳣": [
    "shan4",
    "zhan1"
  ],
  "检察": [
    "jian3 cha2"
  ],
  "蚕": [
    "can2"
  ],
  "转达": [
    "zhuan3 da2"
  ],
  "上映": [
    "shang4 ying4"
  ],
  "地道": [
    "di4 dao4",
    "di4 dao5"
  ],
  "鿏": [
    "mai4"
  ],
  "刻舟求剑": [
    "ke4 zhou1 qiu2 jian4"
  ],
  "顿": [
    "dun4"
  ],
  "思索": [
    "si1 suo3"
  ],
  "跳伞": [
    "tiao4 san3"
  ],
  "特权": [
    "te4 quan2"
  ],
  "董事长": [
    "dong3 shi4 zhang3"
  ],
  "水产品": [
    "shui3 chan3 pin3"
  ],
  "报警": [
    "bao4 jing3"
  ],
  "与其": [
    "yu3 qi2"
  ],
  "鳌": [
    "ao2",
    "ao2"
  ],
  "沩": [
    "gui1"
  ],
  "发育": [
    "fa1 yu4"
  ],
  "辊": [
    "gun3"
  ],
  "晚点": [
    "wan3 dian3"
  ],
  "圩": [
    "wei2",
    "xu1"
  ],
  "借助": [
    "jie4 zhu4"
  ],
  "饵": [
    "er3"
  ],
  "紧缩": [
    "jin3 suo1"
  ],
  "手艺": [
    "shou3 yi4"
  ],
  "糟糕": [
    "zao1 gao1"
  ],
  "不": [
    "bu4"
  ],
  "蛹": [
    "yong3"
  ],
  "凹": [
    "ao1",
    "wa1"
  ],
  "改为": [
    "gai3 wei2"
  ],
  "解析": [
    "jie3 xi1"
  ],
  "还有": [
    "hai2 you3"
  ],
  "恙": [
    "yang4"
  ],
  "姥姥": [
    "lao3 lao5"
  ],
  "公交车": [
    "gong1 jiao1 che1"
  ],
  "杙": [
    "yi4"
  ],
  "平": [
    "Ping2",
    "ping2"
  ],
  "衰减": [
    "shuai1 jian3"
  ],
  "支持": [
    "zhi1 chi2"
  ],
  "讨人喜欢": [
    "tao3 ren2 xi3 huan5"
  ],
  "逵": [
    "kui2"
  ],
  "龙舟": [
    "long2 zhou1"
  ],
  "人家": [
    "ren2 jia1",
    "ren2 jia5"
  ],
  "致富": [
    "zhi4 fu4"
  ],
  "皴": [
    "cun1"
  ],
  "成为": [
    "cheng2 wei2"
  ],
  "品位": [
    "pin3 wei4"
  ],
  "洨": [
    "Xiao2",
    "xiao2"
  ],
  "愦": [
    "kui4"
  ],
  "阒": [
    "qu4"
  ],
  "备用": [
    "bei4 yong4"
  ],
  "振奋": [
    "zhen4 fen4"
  ],
  "艎": [
    "huang2"
  ],
  "尊严": [
    "zun1 yan2"
  ],
  "乞求": [
    "qi3 qiu2"
  ],
  "体积": [
    "ti3 ji1"
  ],
  "痪": [
    "huan4"
  ],
  "译": [
    "yi4"
  ],
  "执意": [
    "zhi2 yi4"
  ],
  "印刷术": [
    "yin4 shua1 shu4"
  ],
  "伤心": [
    "shang1 xin1"
  ],
  "龢": [
    "he2"
  ],
  "机密": [
    "ji1 mi4"
  ],
  "迎来": [
    "ying2 lai2"
  ],
  "孢": [
    "bao1"
  ],
  "驸": [
    "fu4"
  ],
  "镃": [
    "zi1"
  ],
  "回落": [
    "hui2 luo4"
  ],
  "审视": [
    "shen3 shi4"
  ],
  "底蕴": [
    "di3 yun4"
  ],
  "流氓": [
    "liu2 mang2"
  ],
  "端午节": [
    "Duan1 wu3 jie2"
  ],
  "演说": [
    "yan3 shuo1"
  ],
  "甗": [
    "yan3"
  ],
  "阌": [
    "wen2"
  ],
  "命": [
    "ming4"
  ],
  "冻": [
    "dong4"
  ],
  "以": [
    "yi3",
    "yi3",
    "Yi3",
    "yi3"
  ],
  "悭": [
    "qian1"
  ],
  "湖泊": [
    "hu2 po1"
  ],
  "鳊": [
    "bian1"
  ],
  "醴": [
    "li3"
  ],
  "匣": [
    "xia2"
  ],
  "跨": [
    "kua4"
  ],
  "劝说": [
    "quan4 shuo1"
  ],
  "减弱": [
    "jian3 ruo4"
  ],
  "厾": [
    "du1"
  ],
  "咫": [
    "zhi3"
  ],
  "双边": [
    "shuang1 bian1"
  ],
  "堆": [
    "dui1"
  ],
  "枣": [
    "zao3"
  ],
  "晶莹": [
    "jing1 ying2"
  ],
  "纭": [
    "yun2"
  ],
  "谮": [
    "zen4"
  ],
  "奥运会": [
    "Ao4 yun4 hui4"
  ],
  "溠": [
    "zha4"
  ],
  "苦力": [
    "ku3 li4"
  ],
  "遇险": [
    "yu4 xian3"
  ],
  "鲇": [
    "nian2"
  ],
  "计": [
    "Ji4",
    "ji4"
  ],
  "绹": [
    "tao2"
  ],
  "消极": [
    "xiao1 ji2"
  ],
  "主管": [
    "zhu3 guan3"
  ],
  "口径": [
    "kou3 jing4"
  ],
  "检讨": [
    "jian3 tao3"
  ],
  "沘": [
    "bi3"
  ],
  "味儿": [
    "wei4 r5"
  ],
  "拓展": [
    "tuo4 zhan3"
  ],
  "辔": [
    "pei4"
  ],
  "结合": [
    "jie2 he2"
  ],
  "调节": [
    "tiao2 jie2"
  ],
  "叫": [
    "jiao4",
    "jiao4"
  ],
  "气象": [
    "qi4 xiang4"
  ],
  "辩论": [
    "bian4 lun4"
  ],
  "体面": [
    "ti3 mian4"
  ],
  "微": [
    "Wei1",
    "wei1"
  ],
  "一部分": [
    "yi1 bu4 fen5"
  ],
  "一目了然": [
    "yi1 mu4 liao3 ran2",
    "yi1 mu4 liao3 ran2"
  ],
  "在场": [
    "zai4 chang3"
  ],
  "逃": [
    "tao2"
  ],
  "消灭": [
    "xiao1 mie4"
  ],
  "部门": [
    "bu4 men2"
  ],
  "循": [
    "xun2"
  ],
  "侈": [
    "chi3"
  ],
  "昀": [
    "yun2"
  ],
  "办学": [
    "ban4 xue2"
  ],
  "冲突": [
    "chong1 tu1"
  ],
  "涄": [
    "ping1"
  ],
  "巨大": [
    "ju4 da4"
  ],
  "惦记": [
    "dian4 ji4"
  ],
  "昙": [
    "tan2"
  ],
  "导演": [
    "dao3 yan3"
  ],
  "灵感": [
    "ling2 gan3"
  ],
  "随处可见": [
    "sui2 chu4 ke3 jian4"
  ],
  "强盗": [
    "qiang2 dao4"
  ],
  "祝福": [
    "zhu4 fu2"
  ],
  "失业率": [
    "shi1 ye4 lu:4"
  ],
  "沮": [
    "ju3"
  ],
  "毅": [
    "yi4"
  ],
  "蹒": [
    "pan2"
  ],
  "咬": [
    "yao3",
    "yao3"
  ],
  "主任": [
    "zhu3 ren4"
  ],
  "滃": [
    "weng3"
  ],
  "瑰宝": [
    "gui1 bao3"
  ],
  "五": [
    "wu3"
  ],
  "绞": [
    "jiao3"
  ],
  "响应": [
    "xiang3 ying4"
  ],
  "岭": [
    "ling2",
    "ling3"
  ],
  "沇": [
    "Yan3",
    "yan3"
  ],
  "珏": [
    "jue2"
  ],
  "赢得": [
    "ying2 de2"
  ],
  "奋勇": [
    "fen4 yong3"
  ],
  "铁": [
    "Tie3",
    "tie3"
  ],
  "撤离": [
    "che4 li2"
  ],
  "加油站": [
    "jia1 you2 zhan4"
  ],
  "晴朗": [
    "qing2 lang3"
  ],
  "干": [
    "gan1",
    "Gan1",
    "gan1",
    "gan1",
    "Gan1",
    "gan1",
    "gan4"
  ],
  "进口": [
    "jin4 kou3"
  ],
  "惊": [
    "jing1"
  ],
  "鲜花": [
    "xian1 hua1"
  ],
  "尖锐": [
    "jian1 rui4"
  ],
  "销售": [
    "xiao1 shou4"
  ],
  "咇": [
    "bi4"
  ],
  "总计": [
    "zong3 ji4"
  ],
  "霖": [
    "lin2"
  ],
  "无法": [
    "wu2 fa3"
  ],
  "不肯": [
    null
  ],
  "犸": [
    "ma3"
  ],
  "硬币": [
    "ying4 bi4"
  ],
  "目的": [
    "mu4 di4"
  ],
  "控告": [
    "kong4 gao4"
  ],
  "洼": [
    "wa1",
    "wa1"
  ],
  "诱发": [
    "you4 fa1"
  ],
  "驽": [
    "nu2"
  ],
  "釆": [
    "bian4"
  ],
  "戕": [
    "qiang1"
  ],
  "峡谷": [
    "xia2 gu3"
  ],
  "徘": [
    "pai2"
  ],
  "等待": [
    "deng3 dai4"
  ],
  "着手": [
    "zhuo2 shou3"
  ],
  "宋": [
    "Song4"
  ],
  "上瘾": [
    "shang4 yin3"
  ],
  "好意": [
    "hao3 yi4"
  ],
  "秘方": [
    "mi4 fang1"
  ],
  "公益": [
    "gong1 yi4"
  ],
  "以便": [
    "yi3 bian4"
  ],
  "珖": [
    "guang1"
  ],
  "嶓": [
    "bo1"
  ],
  "癜": [
    "dian4"
  ],
  "清洁": [
    "qing1 jie2"
  ],
  "瀔": [
    "gu3"
  ],
  "疭": [
    "zong4"
  ],
  "塍": [
    "cheng2",
    "cheng2"
  ],
  "一卡通": [
    "Yi1 ka3 tong1"
  ],
  "随后": [
    "sui2 hou4"
  ],
  "画": [
    "hua4"
  ],
  "龆": [
    "tiao2"
  ],
  "巨": [
    "ju4"
  ],
  "兼": [
    "jian1"
  ],
  "茶": [
    "cha2"
  ],
  "躔": [
    "chan2"
  ],
  "狼": [
    "lang2"
  ],
  "框": [
    "kuang4"
  ],
  "莨": [
    "lang4"
  ],
  "氧气": [
    "yang3 qi4"
  ],
  "余额": [
    "yu2 e2"
  ],
  "柱子": [
    "zhu4 zi5"
  ],
  "遇难": [
    "yu4 nan4"
  ],
  "谋": [
    "mou2"
  ],
  "农历": [
    "nong2 li4"
  ],
  "华人": [
    "Hua2 ren2"
  ],
  "鼓舞": [
    "gu3 wu3"
  ],
  "旸": [
    "yang2"
  ],
  "得意": [
    "de2 yi4"
  ],
  "国宝": [
    "guo2 bao3"
  ],
  "反驳": [
    "fan3 bo2"
  ],
  "悄": [
    "qiao1",
    "qiao3"
  ],
  "恐惧": [
    "kong3 ju4"
  ],
  "下班": [
    "xia4 ban1"
  ],
  "雳": [
    "li4"
  ],
  "拾": [
    "she4",
    "shi2"
  ],
  "巫": [
    "Wu1",
    "wu1"
  ],
  "夜总会": [
    "ye4 zong3 hui4"
  ],
  "退休": [
    "tui4 xiu1"
  ],
  "讯": [
    "xun4"
  ],
  "莲": [
    "lian2"
  ],
  "廉正": [
    "lian2 zheng4"
  ],
  "琀": [
    "han2"
  ],
  "举动": [
    "ju3 dong4"
  ],
  "缤": [
    "bin1"
  ],
  "什么": [
    "shen2 me5"
  ],
  "加强": [
    "jia1 qiang2"
  ],
  "少量": [
    "shao3 liang4"
  ],
  "麹": [
    null
  ],
  "面对面": [
    "mian4 dui4 mian4"
  ],
  "进攻": [
    "jin4 gong1"
  ],
  "它们": [
    "ta1 men5"
  ],
  "烻": [
    null
  ],
  "突如其来": [
    "tu1 ru2 qi2 lai2"
  ],
  "憷": [
    "chu4"
  ],
  "浰": [
    "li4"
  ],
  "新陈代谢": [
    "xin1 chen2 dai4 xie4"
  ],
  "怦": [
    "peng1"
  ],
  "沆": [
    "hang4"
  ],
  "下载": [
    "xia4 zai3"
  ],
  "馏": [
    "liu2",
    "liu4"
  ],
  "衣服": [
    "yi1 fu5"
  ],
  "椿": [
    "chun1"
  ],
  "问": [
    "wen4"
  ],
  "形": [
    "xing2"
  ],
  "内涵": [
    "nei4 han2"
  ],
  "求婚": [
    "qiu2 hun1"
  ],
  "好客": [
    "hao4 ke4"
  ],
  "还": [
    "Huan2",
    "hai2",
    "huan2"
  ],
  "色彩": [
    "se4 cai3"
  ],
  "仝": [
    "tong2"
  ],
  "暲": [
    "zhang1"
  ],
  "楯": [
    "shun3"
  ],
  "过境": [
    "guo4 jing4"
  ],
  "大肆": [
    "da4 si4"
  ],
  "何苦": [
    "he2 ku3"
  ],
  "秀丽": [
    "xiu4 li4"
  ],
  "一事无成": [
    "yi1 shi4 wu2 cheng2"
  ],
  "眶": [
    "kuang4"
  ],
  "改进": [
    "gai3 jin4"
  ],
  "肸": [
    "xi1"
  ],
  "草": [
    "cao3",
    "cao3",
    "cao4"
  ],
  "智": [
    "zhi4"
  ],
  "着实": [
    "zhuo2 shi2"
  ],
  "杲": [
    "gao3"
  ],
  "崭新": [
    "zhan3 xin1"
  ],
  "堙": [
    "yin1",
    "yin1"
  ],
  "参考": [
    "can1 kao3"
  ],
  "陷": [
    "xian4"
  ],
  "现在": [
    "xian4 zai4"
  ],
  "瑅": [
    null
  ],
  "蘑": [
    "mo2"
  ],
  "虚假": [
    "xu1 jia3"
  ],
  "盖": [
    "Ge3",
    "gai4"
  ],
  "荞": [
    "qiao2",
    "qiao2"
  ],
  "驻": [
    "zhu4"
  ],
  "哨": [
    "shao4"
  ],
  "周年": [
    "zhou1 nian2"
  ],
  "消": [
    "xiao1"
  ],
  "挑剔": [
    "tiao1 ti5"
  ],
  "狭": [
    "xia2",
    "xia2"
  ],
  "辅": [
    "fu3"
  ],
  "汩": [
    "gu3"
  ],
  "来自": [
    "lai2 zi4"
  ],
  "水": [
    "Shui3",
    "shui3"
  ],
  "窗": [
    "chuang1",
    "chuang1",
    "chuang1",
    "chuang1",
    "chuang1"
  ],
  "焰": [
    "yan4",
    "yan4"
  ],
  "髽": [
    "zhua1"
  ],
  "庚": [
    "geng1"
  ],
  "嘡": [
    "tang1"
  ],
  "收视率": [
    "shou1 shi4 lu:4"
  ],
  "趿": [
    "ta1"
  ],
  "形形色色": [
    "xing2 xing2 se4 se4"
  ],
  "谕": [
    "yu4"
  ],
  "专家": [
    "zhuan1 jia1"
  ],
  "邾": [
    "Zhu1",
    "zhu1"
  ],
  "瘟": [
    "wen1"
  ],
  "食用": [
    "shi2 yong4"
  ],
  "无话可说": [
    "wu2 hua4 ke3 shuo1"
  ],
  "耄": [
    "mao4"
  ],
  "轵": [
    "zhi3"
  ],
  "罢": [
    "ba4",
    "ba5"
  ],
  "公开信": [
    "gong1 kai1 xin4"
  ],
  "恬": [
    "tian2"
  ],
  "苹": [
    "ping2",
    "ping2"
  ],
  "旁边": [
    "pang2 bian1"
  ],
  "漹": [
    "Yan1"
  ],
  "漈": [
    "ji4"
  ],
  "硁": [
    "keng1"
  ],
  "氛": [
    "fen1"
  ],
  "一举一动": [
    "yi1 ju3 yi1 dong4"
  ],
  "自来水": [
    "zi4 lai2 shui3"
  ],
  "升": [
    "sheng1",
    "sheng1",
    "sheng1"
  ],
  "杌": [
    "wu4"
  ],
  "婚纱": [
    "hun1 sha1"
  ],
  "净": [
    "jing4",
    "jing4"
  ],
  "分": [
    "fen1",
    "fen4"
  ],
  "疢": [
    "chen4"
  ],
  "舞": [
    "wu3"
  ],
  "各位": [
    "ge4 wei4"
  ],
  "一定": [
    "yi1 ding4"
  ],
  "惊醒": [
    "jing1 xing3"
  ],
  "糇": [
    "hou2"
  ],
  "母亲": [
    "mu3 qin1"
  ],
  "考验": [
    "kao3 yan4"
  ],
  "胥": [
    "Xu1",
    "xu1"
  ],
  "萜": [
    "tie1"
  ],
  "反正": [
    "fan3 zheng4"
  ],
  "小溪": [
    "xiao3 xi1"
  ],
  "渔": [
    "yu2"
  ],
  "饣": [
    "shi2"
  ],
  "滧": [
    "yao2"
  ],
  "壮": [
    "Zhuang4",
    "zhuang4"
  ],
  "珢": [
    null
  ],
  "吃力": [
    "chi1 li4"
  ],
  "役": [
    "yi4"
  ],
  "娑": [
    "suo1"
  ],
  "氤": [
    "yin1"
  ],
  "猕": [
    "mi2"
  ],
  "邋": [
    "la1"
  ],
  "埇": [
    "yong3"
  ],
  "含": [
    "han2"
  ],
  "镍": [
    "nie4"
  ],
  "出汗": [
    "chu1 han4"
  ],
  "羯": [
    "Jie2",
    "jie2"
  ],
  "飗": [
    "liu2"
  ],
  "士兵": [
    "shi4 bing1"
  ],
  "䂮": [
    null
  ],
  "菰": [
    "gu1"
  ],
  "鄙": [
    "bi3"
  ],
  "以上": [
    "yi3 shang4"
  ],
  "栉": [
    "zhi4"
  ],
  "鸟": [
    "diao3",
    "niao3"
  ],
  "发展": [
    "fa1 zhan3"
  ],
  "舵手": [
    "duo4 shou3"
  ],
  "城": [
    "cheng2"
  ],
  "驱动": [
    "qu1 dong4"
  ],
  "集合": [
    "ji2 he2"
  ],
  "癞": [
    "lai4"
  ],
  "远远": [
    "yuan3 yuan3"
  ],
  "栋": [
    "dong4"
  ],
  "妁": [
    "Shuo4",
    "shuo4"
  ],
  "辑": [
    "ji2"
  ],
  "脿": [
    "biao1"
  ],
  "委托": [
    "wei3 tuo1"
  ],
  "球鞋": [
    "qiu2 xie2"
  ],
  "渲": [
    "xuan4"
  ],
  "蚱": [
    "zha4"
  ],
  "仂": [
    "le4"
  ],
  "缵": [
    "zuan3"
  ],
  "肉": [
    "rou4"
  ],
  "藩": [
    "fan1"
  ],
  "碧": [
    "bi4"
  ],
  "贬": [
    "bian3"
  ],
  "滠": [
    "she4"
  ],
  "啄": [
    "zhuo2"
  ],
  "髃": [
    null
  ],
  "院士": [
    "yuan4 shi4"
  ],
  "祝愿": [
    "zhu4 yuan4"
  ],
  "生平": [
    "sheng1 ping2"
  ],
  "手帕": [
    "shou3 pa4"
  ],
  "文凭": [
    "wen2 ping2"
  ],
  "体贴": [
    "ti3 tie1"
  ],
  "鞭炮": [
    "bian1 pao4"
  ],
  "大使": [
    "da4 shi3"
  ],
  "绛": [
    "Jiang4",
    "jiang4"
  ],
  "没劲": [
    "mei2 jin4"
  ],
  "侃大山": [
    "kan3 da4 shan1"
  ],
  "钇": [
    "yi3"
  ],
  "啶": [
    "ding4"
  ],
  "自愿": [
    "zi4 yuan4"
  ],
  "哭笑不得": [
    "ku1 xiao4 bu4 de2"
  ],
  "事项": [
    "shi4 xiang4"
  ],
  "箦": [
    "ze2"
  ],
  "酉": [
    "you3"
  ],
  "工程": [
    "gong1 cheng2"
  ],
  "克": [
    "Ke4",
    "ke4",
    "Ke4",
    "ke4",
    "ke4"
  ],
  "俵": [
    "biao4"
  ],
  "琢": [
    "zhuo2",
    "zuo2"
  ],
  "氆": [
    "pu3"
  ],
  "呣": [
    "m2",
    "m4"
  ],
  "屣": [
    "xi3"
  ],
  "冽": [
    "lie4"
  ],
  "扣除": [
    "kou4 chu2"
  ],
  "碓": [
    "dui4"
  ],
  "谲": [
    "jue2"
  ],
  "人员": [
    "ren2 yuan2"
  ],
  "稷": [
    "ji4"
  ],
  "儡": [
    "lei3"
  ],
  "考量": [
    "kao3 liang2"
  ],
  "打量": [
    "da3 liang5"
  ],
  "婻": [
    null
  ],
  "努": [
    "nu3"
  ],
  "意思": [
    "yi4 si5"
  ],
  "升级": [
    "sheng1 ji2"
  ],
  "商量": [
    "shang1 liang5"
  ],
  "葵": [
    "kui2"
  ],
  "菜市场": [
    "cai4 shi4 chang3"
  ],
  "愁眉苦脸": [
    "chou2 mei2 ku3 lian3"
  ],
  "物资": [
    "wu4 zi1"
  ],
  "优质": [
    "you1 zhi4"
  ],
  "糖果": [
    "tang2 guo3"
  ],
  "琈": [
    "fu2"
  ],
  "月饼": [
    "yue4 bing3"
  ],
  "泇": [
    "Jia1"
  ],
  "佐": [
    "zuo3"
  ],
  "鼐": [
    "nai4"
  ],
  "苾": [
    "bi4"
  ],
  "夜班": [
    "ye4 ban1"
  ],
  "施": [
    "Shi1",
    "shi1"
  ],
  "纻": [
    "zhu4"
  ],
  "缓解": [
    "huan3 jie3"
  ],
  "卩": [
    "jie2"
  ],
  "觏": [
    "gou4"
  ],
  "聱": [
    "ao2"
  ],
  "兆头": [
    "zhao4 tou5"
  ],
  "獾": [
    "huan1",
    "huan1",
    "huan1"
  ],
  "俨": [
    "yan3"
  ],
  "酣": [
    "han1"
  ],
  "打动": [
    "da3 dong4"
  ],
  "燕子": [
    "yan4 zi5"
  ],
  "隔": [
    "ge2"
  ],
  "琥": [
    "hu3"
  ],
  "误": [
    "wu4"
  ],
  "门口": [
    "men2 kou3"
  ],
  "陲": [
    "chui2"
  ],
  "礼物": [
    "li3 wu4"
  ],
  "非法": [
    "fei1 fa3"
  ],
  "感人": [
    "gan3 ren2"
  ],
  "熟练": [
    "shu2 lian4"
  ],
  "归来": [
    "gui1 lai2"
  ],
  "花瓶": [
    "hua1 ping2"
  ],
  "恭维": [
    "gong1 wei5"
  ],
  "选修": [
    "xuan3 xiu1"
  ],
  "鲪": [
    "jun1"
  ],
  "客观": [
    "ke4 guan1"
  ],
  "动荡": [
    "dong4 dang4",
    "dong4 dang4"
  ],
  "诹": [
    "zou1"
  ],
  "躲": [
    "duo3"
  ],
  "谴": [
    "qian3"
  ],
  "媒体": [
    "mei2 ti3"
  ],
  "安定": [
    "An1 ding4",
    "an1 ding4"
  ],
  "消失": [
    "xiao1 shi1"
  ],
  "饲养": [
    "si4 yang3"
  ],
  "帨": [
    "shui4"
  ],
  "婵": [
    "chan2"
  ],
  "炫": [
    "xuan4"
  ],
  "韩": [
    "Han2"
  ],
  "糒": [
    "bei4"
  ],
  "厝": [
    "cuo4"
  ],
  "大街": [
    "da4 jie1"
  ],
  "廪": [
    "lin3"
  ],
  "朗读": [
    "lang3 du2"
  ],
  "茎": [
    "jing1"
  ],
  "茫然": [
    "mang2 ran2"
  ],
  "身体": [
    "shen1 ti3"
  ],
  "鹚": [
    "ci2",
    "ci2"
  ],
  "网吧": [
    "wang3 ba1"
  ],
  "冬": [
    "dong1",
    "Dong1",
    "dong1"
  ],
  "密码": [
    "mi4 ma3"
  ],
  "鹬": [
    "yu4"
  ],
  "睽": [
    "kui2"
  ],
  "尝试": [
    "chang2 shi4"
  ],
  "本事": [
    "ben3 shi4",
    "ben3 shi5"
  ],
  "选": [
    "xuan3"
  ],
  "词语": [
    "ci2 yu3"
  ],
  "烘托": [
    "hong1 tuo1"
  ],
  "目不转睛": [
    "mu4 bu4 zhuan3 jing1"
  ],
  "互信": [
    "hu4 xin4"
  ],
  "电影": [
    "dian4 ying3"
  ],
  "制品": [
    "zhi4 pin3"
  ],
  "清真寺": [
    "qing1 zhen1 si4"
  ],
  "喱": [
    "li2"
  ],
  "鱽": [
    "dao1"
  ],
  "踏": [
    "ta1",
    "ta4"
  ],
  "路线": [
    "lu4 xian4"
  ],
  "汾": [
    "fen2"
  ],
  "学堂": [
    "xue2 tang2"
  ],
  "酴": [
    "tu2"
  ],
  "闺": [
    "gui1"
  ],
  "一次性": [
    "yi1 ci4 xing4"
  ],
  "重现": [
    "chong2 xian4"
  ],
  "不见": [
    "bu4 jian4"
  ],
  "强制": [
    "qiang2 zhi4"
  ],
  "蔊": [
    "han3"
  ],
  "带领": [
    "dai4 ling3"
  ],
  "活儿": [
    "huo2 r5"
  ],
  "鲯": [
    "qi2"
  ],
  "要": [
    "yao1",
    "yao4"
  ],
  "反而": [
    "fan3 er2"
  ],
  "髢": [
    "di2"
  ],
  "快车": [
    "kuai4 che1"
  ],
  "潞": [
    "Lu4"
  ],
  "俭": [
    "jian3"
  ],
  "保留": [
    "bao3 liu2"
  ],
  "瘦": [
    "shou4"
  ],
  "律": [
    "Lu:4",
    "lu:4"
  ],
  "悬": [
    "xuan2"
  ],
  "品行": [
    "pin3 xing2"
  ],
  "出演": [
    "chu1 yan3"
  ],
  "避免": [
    "bi4 mian3"
  ],
  "醪": [
    "lao2"
  ],
  "认知": [
    "ren4 zhi1"
  ],
  "烦": [
    "fan2"
  ],
  "改良": [
    "gai3 liang2"
  ],
  "夹": [
    "jia1",
    "jia2",
    "jia4",
    "jia2"
  ],
  "竺": [
    "Zhu2",
    "du3"
  ],
  "堾": [
    null
  ],
  "傒": [
    "xi1"
  ],
  "蔑": [
    "mie4",
    "mie4"
  ],
  "诗": [
    "Shi1",
    "shi1"
  ],
  "蔓": [
    "man2",
    "man4"
  ],
  "甭": [
    "beng2",
    "beng4"
  ],
  "构成": [
    "gou4 cheng2"
  ],
  "拦": [
    "lan2"
  ],
  "癌症": [
    "ai2 zheng4"
  ],
  "浙": [
    "Zhe4",
    "Zhe4"
  ],
  "幼稚": [
    "you4 zhi4"
  ],
  "姱": [
    "kua1"
  ],
  "保存": [
    "bao3 cun2"
  ],
  "一成不变": [
    "yi1 cheng2 - bu4 bian4"
  ],
  "桀": [
    "jie2"
  ],
  "取": [
    "qu3"
  ],
  "䝙": [
    "chu1"
  ],
  "戾": [
    "li4"
  ],
  "实现": [
    "shi2 xian4"
  ],
  "虑": [
    "lu:4"
  ],
  "癖": [
    "pi3"
  ],
  "婺": [
    "wu4"
  ],
  "络": [
    "lao4",
    "luo4"
  ],
  "倧": [
    null
  ],
  "邙": [
    "Mang2"
  ],
  "括号": [
    "kuo4 hao4"
  ],
  "敢情": [
    "gan3 qing5"
  ],
  "宏大": [
    "hong2 da4"
  ],
  "商人": [
    "shang1 ren2"
  ],
  "婍": [
    null
  ],
  "光": [
    "guang1"
  ],
  "偷懒": [
    "tou1 lan3"
  ],
  "打印机": [
    "da3 yin4 ji1"
  ],
  "利": [
    "Li4",
    "li4"
  ],
  "晏": [
    "Yan4",
    "yan4"
  ],
  "能干": [
    "neng2 gan4"
  ],
  "剩": [
    "sheng4"
  ],
  "杳": [
    "yao3"
  ],
  "夏令营": [
    "xia4 ling4 ying2"
  ],
  "昭": [
    "zhao1"
  ],
  "袈": [
    "jia1"
  ],
  "揭晓": [
    "jie1 xiao3"
  ],
  "赈": [
    "zhen4"
  ],
  "噬": [
    "shi4"
  ],
  "膝": [
    "xi1",
    "xi1"
  ],
  "敦": [
    "dun1",
    "dun1"
  ],
  "斗": [
    "Dou3",
    "dou3",
    "dou4",
    "dou4",
    "dou4",
    "dou4"
  ],
  "仫": [
    "mu4"
  ],
  "大自然": [
    "da4 zi4 ran2"
  ],
  "筛选": [
    "shai1 xuan3"
  ],
  "似乎": [
    "si4 hu1"
  ],
  "旃": [
    "zhan1"
  ],
  "视线": [
    "shi4 xian4"
  ],
  "孩": [
    "hai2"
  ],
  "芗": [
    "xiang1"
  ],
  "嶅": [
    "ao2"
  ],
  "精髓": [
    "jing1 sui3"
  ],
  "害羞": [
    "hai4 xiu1"
  ],
  "纺": [
    "fang3"
  ],
  "冷门": [
    "leng3 men2"
  ],
  "威胁": [
    "wei1 xie2"
  ],
  "后头": [
    "hou4 tou5"
  ],
  "膺": [
    "ying1"
  ],
  "叩": [
    "kou4",
    "kou4"
  ],
  "歧视": [
    "qi2 shi4"
  ],
  "再三": [
    "zai4 san1"
  ],
  "阀门": [
    "fa2 men2"
  ],
  "试图": [
    "shi4 tu2"
  ],
  "试探": [
    "shi4 tan4"
  ],
  "二手": [
    "er4 shou3"
  ],
  "裂痕": [
    "lie4 hen2"
  ],
  "枹": [
    "fu1"
  ],
  "节约": [
    "jie2 yue1"
  ],
  "美容": [
    "mei3 rong2"
  ],
  "耍赖": [
    "shua3 lai4"
  ],
  "捕": [
    "bu3"
  ],
  "仔细": [
    "zi3 xi4"
  ],
  "砫": [
    "zhu3"
  ],
  "团队": [
    "tuan2 dui4"
  ],
  "试用": [
    "shi4 yong4"
  ],
  "洗涤剂": [
    "xi3 di2 ji4"
  ],
  "劁": [
    "qiao1"
  ],
  "累积": [
    "lei3 ji1"
  ],
  "霓": [
    "ni2",
    "ni2"
  ],
  "锗": [
    "zhe3"
  ],
  "圈子": [
    "quan1 zi5"
  ],
  "厌": [
    "yan4"
  ],
  "娆": [
    "rao2"
  ],
  "震撼": [
    "zhen4 han4"
  ],
  "殉": [
    "xun4"
  ],
  "歌星": [
    "ge1 xing1"
  ],
  "碹": [
    null
  ],
  "加深": [
    "jia1 shen1"
  ],
  "瓶颈": [
    "ping2 jing3"
  ],
  "钯": [
    "ba3",
    "pa2"
  ],
  "民间": [
    "min2 jian1"
  ],
  "由于": [
    "you2 yu2"
  ],
  "提供": [
    "ti2 gong1"
  ],
  "想象": [
    "xiang3 xiang4"
  ],
  "扯": [
    "che3",
    "che3"
  ],
  "坦然": [
    "tan3 ran2"
  ],
  "鲠": [
    "geng3",
    "geng3"
  ],
  "投降": [
    "tou2 xiang2"
  ],
  "拍板": [
    "pai1 ban3"
  ],
  "毹": [
    "shu1"
  ],
  "亓": [
    "Qi2",
    "qi2"
  ],
  "俦": [
    "chou2"
  ],
  "镱": [
    "yi4"
  ],
  "活力": [
    "huo2 li4"
  ],
  "飞速": [
    "fei1 su4"
  ],
  "百分点": [
    "bai3 fen1 dian3"
  ],
  "凶狠": [
    "xiong1 hen3",
    "xiong1 hen3"
  ],
  "哄": [
    "hong1",
    "hong3",
    "hong4",
    "hong4"
  ],
  "涉及": [
    "she4 ji2"
  ],
  "小气": [
    "xiao3 qi4"
  ],
  "再现": [
    "zai4 xian4"
  ],
  "古老": [
    "gu3 lao3"
  ],
  "包子": [
    "bao1 zi5"
  ],
  "骜": [
    "ao4"
  ],
  "范畴": [
    "fan4 chou2"
  ],
  "分散": [
    "fen1 san4"
  ],
  "多次": [
    "duo1 ci4"
  ],
  "适用": [
    "shi4 yong4"
  ],
  "襫": [
    "shi4"
  ],
  "学问": [
    "xue2 wen4"
  ],
  "炘": [
    "xin1"
  ],
  "改日": [
    "gai3 ri4"
  ],
  "革新": [
    "ge2 xin1"
  ],
  "浐": [
    "chan3"
  ],
  "覆": [
    "fu4"
  ],
  "保卫": [
    "bao3 wei4"
  ],
  "受到": [
    "shou4 dao4"
  ],
  "占有": [
    "zhan4 you3"
  ],
  "蓢": [
    null
  ],
  "理论": [
    "li3 lun4"
  ],
  "符号": [
    "fu2 hao4"
  ],
  "温度": [
    "wen1 du4"
  ],
  "难受": [
    "nan2 shou4"
  ],
  "犯罪": [
    "fan4 zui4"
  ],
  "巉": [
    "chan2"
  ],
  "蹯": [
    "fan2"
  ],
  "计较": [
    "ji4 jiao4"
  ],
  "走开": [
    "zou3 kai1"
  ],
  "引导": [
    "yin3 dao3"
  ],
  "昺": [
    "bing3"
  ],
  "讨论": [
    "tao3 lun4"
  ],
  "零件": [
    "ling2 jian4"
  ],
  "错过": [
    "cuo4 guo4"
  ],
  "畬": [
    null
  ],
  "巴不得": [
    "ba1 bu5 de5"
  ],
  "支撑": [
    "zhi1 cheng1"
  ],
  "伶": [
    "ling2"
  ],
  "达标": [
    "da2 biao1"
  ],
  "啬": [
    "se4"
  ],
  "脱颖而出": [
    "tuo1 ying3 er2 chu1"
  ],
  "邻国": [
    "lin2 guo2"
  ],
  "口号": [
    "kou3 hao4"
  ],
  "葩": [
    "pa1"
  ],
  "傅": [
    "Fu4",
    "fu4"
  ],
  "寒": [
    "han2"
  ],
  "噂": [
    "zun3"
  ],
  "轮胎": [
    "lun2 tai1"
  ],
  "料到": [
    "liao4 dao4"
  ],
  "鎏": [
    "liu2"
  ],
  "铑": [
    "lao3"
  ],
  "幕": [
    "mu4",
    "mu4"
  ],
  "创作": [
    "chuang4 zuo4"
  ],
  "小孩儿": [
    "xiao3 hai2 r5"
  ],
  "帝国": [
    "di4 guo2"
  ],
  "旭": [
    "xu4"
  ],
  "菔": [
    "fu2"
  ],
  "仍然": [
    "reng2 ran2"
  ],
  "蝥": [
    "mao2"
  ],
  "人均": [
    "ren2 jun1"
  ],
  "咖啡": [
    "ka1 fei1"
  ],
  "筛": [
    "shai1"
  ],
  "争论": [
    "zheng1 lun4"
  ],
  "梵": [
    "Fan4"
  ],
  "躜": [
    "zuan1"
  ],
  "荤": [
    "hun1",
    "xun1"
  ],
  "获悉": [
    "huo4 xi1"
  ],
  "企": [
    "qi3"
  ],
  "睛": [
    "jing1"
  ],
  "埸": [
    "yi4"
  ],
  "曰": [
    "yue1"
  ],
  "岐": [
    "Qi2",
    "qi2"
  ],
  "亲爱": [
    "qin1 ai4"
  ],
  "弓": [
    "Gong1",
    "gong1"
  ],
  "押": [
    "ya1"
  ],
  "晞": [
    "xi1"
  ],
  "铒": [
    "er3"
  ],
  "炼": [
    "lian4",
    "lian4"
  ],
  "其实": [
    "qi2 shi2"
  ],
  "暨": [
    "ji4"
  ],
  "百": [
    "Bai3",
    "bai3"
  ],
  "高龄": [
    "gao1 ling2"
  ],
  "半年": [
    "ban4 nian2"
  ],
  "接济": [
    "jie1 ji4"
  ],
  "囱": [
    "chuang1",
    "cong1"
  ],
  "秧歌": [
    "yang1 ge5"
  ],
  "圈套": [
    "quan1 tao4"
  ],
  "全部": [
    "quan2 bu4"
  ],
  "字母": [
    "zi4 mu3"
  ],
  "顺其自然": [
    "shun4 qi2 zi4 ran2"
  ],
  "事业": [
    "shi4 ye4"
  ],
  "涵盖": [
    "han2 gai4"
  ],
  "觉醒": [
    "jue2 xing3"
  ],
  "窃取": [
    "qie4 qu3"
  ],
  "厩": [
    "jiu4",
    "jiu4"
  ],
  "东奔西走": [
    "dong1 ben1 xi1 zou3"
  ],
  "架": [
    "jia4"
  ],
  "转悠": [
    "zhuan4 you5"
  ],
  "谯": [
    "Qiao2",
    "qiao2",
    "qiao4"
  ],
  "游玩": [
    "you2 wan2"
  ],
  "前仰后合": [
    "qian2 yang3 hou4 he2"
  ],
  "荭": [
    "hong2"
  ],
  "相应": [
    "xiang1 ying4"
  ],
  "寰": [
    "huan2"
  ],
  "橹": [
    "lu3",
    "lu3",
    "lu3",
    "lu3",
    "lu3"
  ],
  "世故": [
    "shi4 gu4",
    "shi4 gu5"
  ],
  "莫名其妙": [
    "mo4 ming2 qi2 miao4"
  ],
  "不禁": [
    "bu4 jin1"
  ],
  "无微不至": [
    "wu2 wei1 bu4 zhi4"
  ],
  "垤": [
    "die2"
  ],
  "半边天": [
    "ban4 bian1 tian1"
  ],
  "嵎": [
    "yu2"
  ],
  "售": [
    "shou4"
  ],
  "公": [
    "gong1"
  ],
  "宝藏": [
    "bao3 zang4"
  ],
  "算盘": [
    "suan4 pan2"
  ],
  "营业": [
    "ying2 ye4"
  ],
  "老实说": [
    "lao3 shi2 shuo1"
  ],
  "活跃": [
    "huo2 yue4"
  ],
  "碴": [
    "cha2"
  ],
  "金融": [
    "jin1 rong2"
  ],
  "萹": [
    "bian1",
    "bian3"
  ],
  "极限": [
    "ji2 xian4"
  ],
  "鸲": [
    "qu2"
  ],
  "走近": [
    "zou3 jin4"
  ],
  "坦克": [
    "tan3 ke4"
  ],
  "名字": [
    "ming2 zi5"
  ],
  "队员": [
    "dui4 yuan2"
  ],
  "屯": [
    "tun2",
    "zhun1"
  ],
  "工厂": [
    "gong1 chang3"
  ],
  "膙": [
    "jiang3"
  ],
  "停留": [
    "ting2 liu2"
  ],
  "嫜": [
    "zhang1"
  ],
  "审判": [
    "shen3 pan4"
  ],
  "薛": [
    "Xue1",
    "xue1"
  ],
  "拓宽": [
    "tuo4 kuan1"
  ],
  "嗜": [
    "shi4"
  ],
  "广": [
    "yan3",
    "Guang3",
    "guang3"
  ],
  "同情": [
    "tong2 qing2"
  ],
  "彐": [
    "ji4"
  ],
  "婢": [
    "bi4"
  ],
  "焗": [
    "ju2"
  ],
  "晤": [
    "wu4"
  ],
  "韨": [
    "fu2"
  ],
  "数据库": [
    "shu4 ju4 ku4"
  ],
  "柔软": [
    "rou2 ruan3"
  ],
  "犨": [
    "chou1"
  ],
  "晚会": [
    "wan3 hui4"
  ],
  "懂": [
    "dong3"
  ],
  "鄞": [
    "yin2"
  ],
  "栾": [
    "Luan2",
    "luan2"
  ],
  "概况": [
    "gai4 kuang4"
  ],
  "会长": [
    "hui4 zhang3"
  ],
  "羔": [
    "gao1"
  ],
  "事迹": [
    "shi4 ji4"
  ],
  "黑": [
    "Hei1",
    "hei1"
  ],
  "耽": [
    "dan1",
    "dan1"
  ],
  "牿": [
    "gu4"
  ],
  "万一": [
    "wan4 yi1"
  ],
  "相同": [
    "xiang1 tong2"
  ],
  "弑": [
    "shi4"
  ],
  "强调": [
    "qiang2 diao4"
  ],
  "谎言": [
    "huang3 yan2"
  ],
  "同一": [
    "tong2 yi1"
  ],
  "祧": [
    "tiao1"
  ],
  "故事": [
    "gu4 shi4",
    "gu4 shi5"
  ],
  "包含": [
    "bao1 han2"
  ],
  "钥匙": [
    "yao4 shi5"
  ],
  "荷花": [
    "he2 hua1"
  ],
  "怜": [
    "lian2"
  ],
  "浩": [
    "hao4"
  ],
  "勒": [
    "le4",
    "lei1"
  ],
  "剧情": [
    "ju4 qing2"
  ],
  "激化": [
    "ji1 hua4"
  ],
  "敏": [
    "min3"
  ],
  "毗": [
    "pi2",
    "pi2"
  ],
  "统统": [
    "tong3 tong3"
  ],
  "先前": [
    "xian1 qian2"
  ],
  "逍": [
    "xiao1"
  ],
  "皮肤": [
    "pi2 fu1"
  ],
  "基层": [
    "ji1 ceng2"
  ],
  "瞟": [
    "piao3"
  ],
  "裱": [
    "biao3"
  ],
  "驯": [
    "xun4"
  ],
  "耒": [
    "lei3"
  ],
  "一身": [
    "yi1 shen1"
  ],
  "轺": [
    "yao2"
  ],
  "盗": [
    "dao4"
  ],
  "音乐": [
    "yin1 yue4"
  ],
  "否定": [
    "fou3 ding4"
  ],
  "至关重要": [
    "zhi4 guan1 zhong4 yao4"
  ],
  "枕": [
    "zhen3"
  ],
  "鄜": [
    "fu1"
  ],
  "运动员": [
    "yun4 dong4 yuan2"
  ],
  "山路": [
    "shan1 lu4"
  ],
  "马": [
    "Ma3",
    "ma3"
  ],
  "请进": [
    "qing3 jin4"
  ],
  "强": [
    "Qiang2",
    "jiang4",
    "qiang2",
    "qiang3",
    "jiang4",
    "qiang2",
    "qiang3"
  ],
  "分割": [
    "fen1 ge1"
  ],
  "价钱": [
    "jia4 qian5"
  ],
  "王国": [
    "wang2 guo2"
  ],
  "浃": [
    "jia1"
  ],
  "囟": [
    "xin4"
  ],
  "淮": [
    "Huai2"
  ],
  "疮": [
    "chuang1"
  ],
  "下期": [
    "xia4 qi1"
  ],
  "次": [
    "ci4"
  ],
  "道具": [
    "dao4 ju4"
  ],
  "掇": [
    "duo1"
  ],
  "诈": [
    "zha4"
  ],
  "人士": [
    "ren2 shi4"
  ],
  "炯": [
    "jiong3",
    "jiong3"
  ],
  "锤子": [
    "chui2 zi5"
  ],
  "各": [
    "ge4"
  ],
  "翥": [
    "zhu4"
  ],
  "滴": [
    "di1"
  ],
  "盈利": [
    "ying2 li4"
  ],
  "鹄": [
    "gu3",
    "hu2"
  ],
  "拍": [
    "pai1"
  ],
  "忠": [
    "zhong1"
  ],
  "眇": [
    "miao3"
  ],
  "着急": [
    "zhao2 ji2"
  ],
  "砻": [
    "long2"
  ],
  "抵抗": [
    "di3 kang4"
  ],
  "椀": [
    null
  ],
  "买": [
    "mai3"
  ],
  "罚": [
    "fa2",
    "fa2"
  ],
  "互助": [
    "Hu4 zhu4",
    "hu4 zhu4"
  ],
  "嫩": [
    "nen4",
    "nen4"
  ],
  "洽谈": [
    "qia4 tan2"
  ],
  "勤": [
    "qin2",
    "qin2"
  ],
  "虞": [
    "Yu2",
    "yu2"
  ],
  "跨国": [
    "kua4 guo2"
  ],
  "淘汰": [
    "tao2 tai4"
  ],
  "醾": [
    "mi2"
  ],
  "风范": [
    "feng1 fan4"
  ],
  "迫切": [
    "po4 qie4"
  ],
  "阿拉伯语": [
    "A1 la1 bo2 yu3"
  ],
  "淀": [
    "dian4",
    "dian4"
  ],
  "馄": [
    "hun2"
  ],
  "暇": [
    "xia2"
  ],
  "氘": [
    "dao1"
  ],
  "錾": [
    "zan4"
  ],
  "版": [
    "ban3"
  ],
  "弈": [
    "yi4"
  ],
  "曾经": [
    "ceng2 jing1"
  ],
  "居民楼": [
    "ju1 min2 lou2"
  ],
  "外语": [
    "wai4 yu3"
  ],
  "喙": [
    "hui4"
  ],
  "瀱": [
    "ji4"
  ],
  "势必": [
    "shi4 bi4"
  ],
  "近视": [
    "jin4 shi4"
  ],
  "劭": [
    "Shao4",
    "shao4"
  ],
  "潺": [
    "chan2"
  ],
  "忽": [
    "Hu1",
    "hu1"
  ],
  "本分": [
    "ben3 fen4"
  ],
  "哥哥": [
    "ge1 ge5"
  ],
  "辂": [
    "lu4"
  ],
  "受骗": [
    "shou4 pian4"
  ],
  "蟠": [
    "pan2"
  ],
  "势力": [
    "shi4 li5"
  ],
  "应当": [
    "ying1 dang1"
  ],
  "忙乱": [
    "mang2 luan4"
  ],
  "呙": [
    "Guo1",
    "wai1"
  ],
  "地步": [
    "di4 bu4"
  ],
  "妆": [
    "zhuang1",
    "zhuang1"
  ],
  "榇": [
    "chen4"
  ],
  "工会": [
    "gong1 hui4"
  ],
  "中文": [
    "Zhong1 wen2"
  ],
  "跞": [
    "li4"
  ],
  "尽头": [
    "jin4 tou2"
  ],
  "评委": [
    "ping2 wei3"
  ],
  "飞跃": [
    "fei1 yue4"
  ],
  "阅读": [
    "yue4 du2"
  ],
  "中期": [
    "zhong1 qi1"
  ],
  "船长": [
    "chuan2 zhang3"
  ],
  "干涉": [
    "gan1 she4"
  ],
  "食欲": [
    "shi2 yu4"
  ],
  "踽": [
    "ju3"
  ],
  "看出": [
    "kan4 chu1"
  ],
  "舶": [
    "bo2"
  ],
  "喆": [
    "zhe2"
  ],
  "大地": [
    "da4 di4"
  ],
  "合适": [
    "he2 shi4"
  ],
  "不怎么": [
    "bu4 zen3 me5"
  ],
  "高超": [
    "gao1 chao1"
  ],
  "闭幕": [
    "bi4 mu4"
  ],
  "侂": [
    "tuo1"
  ],
  "辞": [
    "ci2",
    "ci2"
  ],
  "蹢": [
    "di2"
  ],
  "指点": [
    "zhi3 dian3"
  ],
  "荮": [
    "zhou4"
  ],
  "生病": [
    "sheng1 bing4"
  ],
  "鹆": [
    "yu4"
  ],
  "泡沫": [
    "pao4 mo4"
  ],
  "按说": [
    "an4 shuo1"
  ],
  "鳢": [
    "li3"
  ],
  "一言一行": [
    "yi1 yan2 yi1 xing2"
  ],
  "拜": [
    "bai4"
  ],
  "看起来": [
    "kan4 qi3 lai5"
  ],
  "通缉": [
    "tong1 ji1"
  ],
  "导游": [
    "dao3 you2"
  ],
  "楷": [
    "jie1",
    "kai3"
  ],
  "衬": [
    "chen4"
  ],
  "液": [
    "ye4"
  ],
  "垃": [
    "la1"
  ],
  "搞好": [
    "gao3 hao3"
  ],
  "阗": [
    "tian2"
  ],
  "喤": [
    "huang2"
  ],
  "熬夜": [
    "ao2 ye4"
  ],
  "天地": [
    "tian1 di4"
  ],
  "面貌": [
    "mian4 mao4"
  ],
  "偁": [
    "cheng1"
  ],
  "妙": [
    "miao4",
    "miao4"
  ],
  "禤": [
    "Xuan1"
  ],
  "迅": [
    "xun4"
  ],
  "施行": [
    "shi1 xing2"
  ],
  "倒是": [
    "dao4 shi5"
  ],
  "老实": [
    "lao3 shi5"
  ],
  "驵": [
    "zang3"
  ],
  "高速": [
    "gao1 su4"
  ],
  "失去": [
    "shi1 qu4"
  ],
  "惨白": [
    "can3 bai2"
  ],
  "鸢": [
    "yuan1"
  ],
  "灶": [
    "zao4",
    "zao4"
  ],
  "岁月": [
    "sui4 yue4"
  ],
  "无条件": [
    "wu2 tiao2 jian4"
  ],
  "惊慌": [
    "jing1 huang1"
  ],
  "标": [
    "biao1"
  ],
  "梼": [
    "tao2"
  ],
  "崟": [
    "yin2"
  ],
  "居住": [
    "ju1 zhu4"
  ],
  "渭": [
    "Wei4"
  ],
  "几乎": [
    "ji1 hu1"
  ],
  "创始人": [
    "chuang4 shi3 ren2"
  ],
  "昌盛": [
    "chang1 sheng4"
  ],
  "岛": [
    "dao3",
    "dao3"
  ],
  "尜": [
    "ga2"
  ],
  "伍": [
    "Wu3",
    "wu3"
  ],
  "说真的": [
    "shuo1 zhen1 de5"
  ],
  "作家": [
    "zuo4 jia1"
  ],
  "姬": [
    "Ji1",
    "ji1"
  ],
  "滋润": [
    "zi1 run4"
  ],
  "颋": [
    "ting3"
  ],
  "濋": [
    "Chu3"
  ],
  "桲": [
    "bo2",
    "po5"
  ],
  "铪": [
    "ha1"
  ],
  "微笑": [
    "wei1 xiao4"
  ],
  "战斗": [
    "zhan4 dou4"
  ],
  "督": [
    "du1"
  ],
  "剪子": [
    "jian3 zi5"
  ],
  "到位": [
    "dao4 wei4"
  ],
  "压抑": [
    "ya1 yi4"
  ],
  "追随": [
    "zhui1 sui2"
  ],
  "火热": [
    "huo3 re4"
  ],
  "晨": [
    "chen2"
  ],
  "帆": [
    "fan1",
    "fan1",
    "fan1"
  ],
  "概念": [
    "gai4 nian4"
  ],
  "镂": [
    "lou4"
  ],
  "想起": [
    "xiang3 qi3"
  ],
  "老化": [
    "lao3 hua4"
  ],
  "屋子": [
    "wu1 zi5"
  ],
  "则": [
    "ze2"
  ],
  "这儿": [
    "zhe4 r5"
  ],
  "焞": [
    "tun1"
  ],
  "时刻": [
    "shi2 ke4"
  ],
  "生词": [
    "sheng1 ci2"
  ],
  "崃": [
    "lai2"
  ],
  "表面上": [
    "biao3 mian4 shang5"
  ],
  "瑑": [
    "zhuan4"
  ],
  "姆": [
    "mu3"
  ],
  "据此": [
    "ju4 ci3"
  ],
  "萣": [
    "ding4"
  ],
  "荡漾": [
    "dang4 yang4",
    "dang4 yang4"
  ],
  "欢声笑语": [
    "huan1 sheng1 xiao4 yu3"
  ],
  "推理": [
    "tui1 li3"
  ],
  "嗑": [
    "ke4"
  ],
  "达": [
    "Da2",
    "da2"
  ],
  "艉": [
    "wei3"
  ],
  "专柜": [
    "zhuan1 gui4"
  ],
  "收买": [
    "shou1 mai3"
  ],
  "抗生素": [
    "kang4 sheng1 su4"
  ],
  "昼夜": [
    "zhou4 ye4"
  ],
  "痫": [
    "xian2"
  ],
  "拖": [
    "tuo1",
    "tuo1"
  ],
  "私营": [
    "si1 ying2"
  ],
  "敬礼": [
    "jing4 li3"
  ],
  "原创": [
    "yuan2 chuang4"
  ],
  "喜酒": [
    "xi3 jiu3"
  ],
  "羌": [
    "Qiang1",
    "qiang1",
    "qiang1"
  ],
  "捎": [
    "shao1"
  ],
  "韦": [
    "Wei2",
    "wei2"
  ],
  "朓": [
    "tiao3"
  ],
  "珂": [
    "ke1"
  ],
  "请": [
    "qing3"
  ],
  "成问题": [
    "cheng2 wen4 ti2"
  ],
  "只管": [
    "zhi3 guan3"
  ],
  "遵循": [
    "zun1 xun2"
  ],
  "狁": [
    "yun3"
  ],
  "浛": [
    null
  ],
  "抑郁症": [
    "yi4 yu4 zheng4"
  ],
  "胜负": [
    "sheng4 fu4"
  ],
  "诀": [
    "jue2"
  ],
  "依次": [
    "yi1 ci4"
  ],
  "安检": [
    "an1 jian3"
  ],
  "般": [
    "ban1",
    "bo1",
    "pan2"
  ],
  "下一代": [
    "xia4 yi1 dai4"
  ],
  "帻": [
    "ze2"
  ],
  "獗": [
    "jue2"
  ],
  "见到": [
    "jian4 dao4"
  ],
  "䐃": [
    null
  ],
  "戎": [
    "Rong2",
    "rong2"
  ],
  "琐": [
    "suo3"
  ],
  "墉": [
    "yong1"
  ],
  "个子": [
    "ge4 zi5"
  ],
  "电饭锅": [
    "dian4 fan4 guo1"
  ],
  "台风": [
    "tai2 feng1",
    "tai2 feng1"
  ],
  "舆": [
    "yu2"
  ],
  "任": [
    "Ren2",
    "ren4"
  ],
  "蔗": [
    "zhe4"
  ],
  "俣": [
    "yu3"
  ],
  "褕": [
    "yu2"
  ],
  "后退": [
    "hou4 tui4"
  ],
  "峦": [
    "luan2"
  ],
  "溁": [
    "ying2"
  ],
  "印章": [
    "yin4 zhang1"
  ],
  "泅": [
    "qiu2"
  ],
  "嘉年华": [
    "jia1 nian2 hua2"
  ],
  "相遇": [
    "xiang1 yu4"
  ],
  "附带": [
    "fu4 dai4"
  ],
  "处长": [
    "chu4 zhang3"
  ],
  "螫": [
    "shi4",
    "zhe1"
  ],
  "北京": [
    "Bei3 jing1"
  ],
  "申": [
    "Shen1",
    "shen1"
  ],
  "期": [
    "qi1",
    "qi1"
  ],
  "纰": [
    "pi1"
  ],
  "撞": [
    "zhuang4"
  ],
  "爱情": [
    "ai4 qing2"
  ],
  "屮": [
    "che4"
  ],
  "是否": [
    "shi4 fou3"
  ],
  "鸯": [
    "yang1"
  ],
  "揩": [
    "kai1"
  ],
  "李": [
    "Li3",
    "li3"
  ],
  "奔": [
    "ben1",
    "ben4",
    "ben1",
    "ben4",
    "ben1"
  ],
  "煃": [
    "kui3"
  ],
  "全局": [
    "quan2 ju2"
  ],
  "蒴": [
    "shuo4"
  ],
  "芠": [
    null
  ],
  "河": [
    "he2"
  ],
  "渼": [
    "mei3"
  ],
  "蝙": [
    "bian1"
  ],
  "庵": [
    "an1",
    "an1"
  ],
  "多心": [
    "duo1 xin1"
  ],
  "鄠": [
    "hu4"
  ],
  "扞": [
    null
  ],
  "缧": [
    "lei2"
  ],
  "知": [
    "zhi1"
  ],
  "并且": [
    "bing4 qie3"
  ],
  "口语": [
    "kou3 yu3"
  ],
  "毵": [
    "san1"
  ],
  "燔": [
    "fan2"
  ],
  "慵": [
    "yong1"
  ],
  "嗝": [
    "ge2"
  ],
  "巍": [
    "wei1"
  ],
  "编辑": [
    "bian1 ji2"
  ],
  "精心": [
    "jing1 xin1"
  ],
  "舒适": [
    "shu1 shi4"
  ],
  "涵义": [
    "han2 yi4"
  ],
  "驲": [
    "ri4"
  ],
  "传播": [
    "chuan2 bo1"
  ],
  "国土": [
    "guo2 tu3"
  ],
  "负责人": [
    "fu4 ze2 ren2"
  ],
  "太": [
    "tai4"
  ],
  "迤": [
    "yi2",
    "yi3"
  ],
  "饼": [
    "bing3"
  ],
  "依托": [
    "yi1 tuo1"
  ],
  "仓": [
    "cang1"
  ],
  "锭": [
    "ding4"
  ],
  "趼": [
    "jian3"
  ],
  "修理": [
    "xiu1 li3"
  ],
  "泾": [
    "Jing1"
  ],
  "丛": [
    "cong2"
  ],
  "坚强": [
    "jian1 qiang2"
  ],
  "功底": [
    "gong1 di3"
  ],
  "大家庭": [
    "da4 jia1 ting2"
  ],
  "蔹": [
    "lian3"
  ],
  "驴": [
    "lu:2",
    "lu:2"
  ],
  "狍": [
    "pao2"
  ],
  "荖": [
    null
  ],
  "澼": [
    "pi4"
  ],
  "价值": [
    "jia4 zhi2"
  ],
  "沙子": [
    "sha1 zi5"
  ],
  "毛笔": [
    "mao2 bi3"
  ],
  "柯": [
    "Ke1",
    "ke1"
  ],
  "容忍": [
    "rong2 ren3"
  ],
  "瓀": [
    "ruan3"
  ],
  "样": [
    "yang4"
  ],
  "管": [
    "guan3",
    "Guan3",
    "guan3"
  ],
  "王牌": [
    "wang2 pai2"
  ],
  "简要": [
    "jian3 yao4"
  ],
  "琯": [
    "guan3"
  ],
  "失望": [
    "shi1 wang4"
  ],
  "搁置": [
    "ge1 zhi4"
  ],
  "运河": [
    "yun4 he2"
  ],
  "眙": [
    "yi2"
  ],
  "取缔": [
    "qu3 di4"
  ],
  "妇": [
    "fu4",
    "fu4"
  ],
  "睬": [
    "cai3",
    "cai3"
  ],
  "忙活": [
    "mang2 huo5"
  ],
  "有待": [
    "you3 dai4"
  ],
  "细腻": [
    "xi4 ni4"
  ],
  "争议": [
    "zheng1 yi4"
  ],
  "赪": [
    "cheng1"
  ],
  "罹": [
    "li2"
  ],
  "得以": [
    "de2 yi3"
  ],
  "马上": [
    "ma3 shang4"
  ],
  "示威": [
    "shi4 wei1"
  ],
  "交头接耳": [
    "jiao1 tou2 jie1 er3"
  ],
  "颊": [
    "jia2"
  ],
  "槿": [
    "jin3"
  ],
  "书写": [
    "shu1 xie3"
  ],
  "竿": [
    "gan1"
  ],
  "噪": [
    "zao4",
    "zao4"
  ],
  "磁": [
    "ci2"
  ],
  "迄": [
    "qi4"
  ],
  "蠼": [
    "qu2"
  ],
  "涩": [
    "se4",
    "se4"
  ],
  "瑀": [
    "yu3"
  ],
  "鼻涕": [
    "bi2 ti4"
  ],
  "明显": [
    "ming2 xian3"
  ],
  "爪子": [
    "zhua3 zi5"
  ],
  "协商": [
    "xie2 shang1"
  ],
  "廆": [
    "hui4"
  ],
  "同伙": [
    "tong2 huo3"
  ],
  "模范": [
    "mo2 fan4"
  ],
  "灭绝": [
    "mie4 jue2"
  ],
  "南部": [
    "nan2 bu4"
  ],
  "檩": [
    "lin3"
  ],
  "拆除": [
    "chai1 chu2"
  ],
  "氚": [
    "chuan1"
  ],
  "竟": [
    "jing4"
  ],
  "压": [
    "ya1",
    "ya4"
  ],
  "虔诚": [
    "qian2 cheng2"
  ],
  "淏": [
    "hao4"
  ],
  "柜子": [
    "gui4 zi5"
  ],
  "稣": [
    "su1"
  ],
  "俱": [
    "ju4"
  ],
  "罪恶": [
    "zui4 e4"
  ],
  "轿车": [
    "jiao4 che1"
  ],
  "干活儿": [
    "gan4 huo2 r5"
  ],
  "正直": [
    "zheng4 zhi2"
  ],
  "淴": [
    null
  ],
  "理智": [
    "li3 zhi4"
  ],
  "洙": [
    "Zhu1",
    "zhu1"
  ],
  "跖": [
    "zhi2",
    "zhi2"
  ],
  "预示": [
    "yu4 shi4"
  ],
  "鹍": [
    "kun1"
  ],
  "顽": [
    "wan2"
  ],
  "衬托": [
    "chen4 tuo1"
  ],
  "酱油": [
    "jiang4 you2"
  ],
  "江": [
    "Jiang1",
    "jiang1"
  ],
  "咕": [
    "gu1"
  ],
  "曾": [
    "Zeng1",
    "ceng2",
    "zeng1"
  ],
  "顾客": [
    "gu4 ke4"
  ],
  "觿": [
    "xi1"
  ],
  "烈士": [
    "lie4 shi4"
  ],
  "便是": [
    "bian4 shi4"
  ],
  "筹码": [
    "chou2 ma3"
  ],
  "颤抖": [
    "chan4 dou3"
  ],
  "拶": [
    "za1",
    "zan3"
  ],
  "哉": [
    "zai1"
  ],
  "镊": [
    "nie4"
  ],
  "侧": [
    "ce4",
    "zhai1"
  ],
  "祖母": [
    "zu3 mu3"
  ],
  "生理": [
    "sheng1 li3"
  ],
  "接听": [
    "jie1 ting1"
  ],
  "囡": [
    "nan1"
  ],
  "开拓": [
    "kai1 tuo4"
  ],
  "集资": [
    "ji2 zi1"
  ],
  "吕": [
    "Lu:3",
    "lu:3"
  ],
  "鞴": [
    "bei4"
  ],
  "劳": [
    "lao2"
  ],
  "斧": [
    "fu3"
  ],
  "疥": [
    "jie4"
  ],
  "遇到": [
    "yu4 dao4"
  ],
  "为期": [
    "wei2 qi1"
  ],
  "游行": [
    "you2 xing2"
  ],
  "胶": [
    "jiao1"
  ],
  "韫": [
    "yun4"
  ],
  "注定": [
    "zhu4 ding4"
  ],
  "饱和": [
    "bao3 he2"
  ],
  "港": [
    "Gang3",
    "gang3"
  ],
  "山坡": [
    "shan1 po1"
  ],
  "欺负": [
    "qi1 fu5"
  ],
  "勾画": [
    "gou1 hua4"
  ],
  "可口": [
    "ke3 kou3"
  ],
  "噩": [
    "e4"
  ],
  "黑暗": [
    "hei1 an4"
  ],
  "漂": [
    "piao1",
    "piao3",
    "piao4"
  ],
  "肢": [
    "zhi1"
  ],
  "科幻": [
    "ke1 huan4"
  ],
  "屏幕": [
    "ping2 mu4"
  ],
  "地震": [
    "di4 zhen4"
  ],
  "身心": [
    "shen1 xin1"
  ],
  "吧": [
    "ba1",
    "ba5",
    "bia1"
  ],
  "内心": [
    "nei4 xin1"
  ],
  "嫭": [
    "hu4"
  ],
  "熠": [
    "yi4"
  ],
  "苎": [
    "zhu4"
  ],
  "大数据": [
    "da4 shu4 ju4"
  ],
  "凶猛": [
    "xiong1 meng3"
  ],
  "衡量": [
    "heng2 liang2"
  ],
  "棫": [
    "yu4"
  ],
  "封锁": [
    "feng1 suo3"
  ],
  "诼": [
    "zhuo2"
  ],
  "转身": [
    "zhuan3 shen1"
  ],
  "所有": [
    "suo3 you3"
  ],
  "生产": [
    "sheng1 chan3"
  ],
  "实践": [
    "shi2 jian4"
  ],
  "坚定": [
    "jian1 ding4"
  ],
  "俎": [
    "zu3"
  ],
  "成长": [
    "cheng2 zhang3"
  ],
  "说服": [
    "shuo1 fu2"
  ],
  "埋藏": [
    "mai2 cang2"
  ],
  "摩托": [
    "mo2 tuo1"
  ],
  "收养": [
    "shou1 yang3"
  ],
  "鄣": [
    "zhang1"
  ],
  "曙": [
    "shu3"
  ],
  "比如说": [
    "bi3 ru2 shuo1"
  ],
  "酎": [
    "zhou4"
  ],
  "璞": [
    "pu2"
  ],
  "敬": [
    "jing4"
  ],
  "婚姻": [
    "hun1 yin1"
  ],
  "蒂": [
    "di4",
    "di4"
  ],
  "羓": [
    null
  ],
  "执行": [
    "zhi2 xing2"
  ],
  "终身": [
    "zhong1 shen1"
  ],
  "电铃": [
    "dian4 ling2"
  ],
  "奖": [
    "jiang3"
  ],
  "鄄": [
    "Juan4"
  ],
  "圢": [
    "ting3"
  ],
  "挈": [
    "qie4"
  ],
  "新闻": [
    "xin1 wen2"
  ],
  "筹备": [
    "chou2 bei4"
  ],
  "消防": [
    "xiao1 fang2"
  ],
  "要强": [
    "yao4 qiang2"
  ],
  "让": [
    "rang4"
  ],
  "餐": [
    "can1"
  ],
  "滓": [
    "zi3"
  ],
  "漷": [
    "kuo4"
  ],
  "才华": [
    "cai2 hua2"
  ],
  "树梢": [
    "shu4 shao1"
  ],
  "什么样": [
    "shen2 me5 yang4"
  ],
  "下乡": [
    "xia4 xiang1"
  ],
  "口感": [
    "kou3 gan3"
  ],
  "募捐": [
    "mu4 juan1"
  ],
  "同舟共济": [
    "tong2 zhou1 gong4 ji4"
  ],
  "郊游": [
    "jiao1 you2"
  ],
  "天使": [
    "tian1 shi3"
  ],
  "奂": [
    "Huan4",
    "huan4"
  ],
  "以致": [
    "yi3 zhi4"
  ],
  "鼹": [
    "yan3"
  ],
  "危急": [
    "wei1 ji2"
  ],
  "激素": [
    "ji1 su4"
  ],
  "蘧": [
    "Qu2",
    "qu2"
  ],
  "赶不上": [
    "gan3 bu4 shang4"
  ],
  "步骤": [
    "bu4 zhou4"
  ],
  "电": [
    "dian4"
  ],
  "标签": [
    "biao1 qian1"
  ],
  "默默无闻": [
    "mo4 mo4 wu2 wen2"
  ],
  "逐步": [
    "zhu2 bu4"
  ],
  "弼": [
    "bi4"
  ],
  "单独": [
    "dan1 du2"
  ],
  "风采": [
    "feng1 cai3"
  ],
  "职": [
    "zhi2"
  ],
  "唰": [
    "shua1"
  ],
  "脒": [
    "mi3"
  ],
  "摄像": [
    "she4 xiang4"
  ],
  "温和": [
    "wen1 he2",
    "wen1 huo5"
  ],
  "结冰": [
    "jie2 bing1"
  ],
  "那些": [
    "na4 xie1"
  ],
  "告": [
    "gao4"
  ],
  "交流": [
    "jiao1 liu2"
  ],
  "勹": [
    "bao1"
  ],
  "脱落": [
    "tuo1 luo4"
  ],
  "聋人": [
    "long2 ren2"
  ],
  "职业病": [
    "zhi2 ye4 bing4"
  ],
  "作战": [
    "zuo4 zhan4"
  ],
  "携": [
    "xie2",
    "xie2",
    "xie2",
    "xie2"
  ],
  "饳": [
    "duo4"
  ],
  "倚": [
    "yi3"
  ],
  "破旧": [
    "po4 jiu4"
  ],
  "岛屿": [
    "dao3 yu3"
  ],
  "篮": [
    "lan2"
  ],
  "举一反三": [
    "ju3 yi1 fan3 san1"
  ],
  "电器": [
    "dian4 qi4"
  ],
  "垲": [
    "kai3"
  ],
  "布局": [
    "bu4 ju2"
  ],
  "停业": [
    "ting2 ye4"
  ],
  "搁浅": [
    "ge1 qian3"
  ],
  "改": [
    "gai3"
  ],
  "度": [
    "du4",
    "duo2"
  ],
  "螭": [
    "chi1"
  ],
  "冤枉": [
    "yuan1 wang5"
  ],
  "笫": [
    "zi3"
  ],
  "鞡": [
    "la5"
  ],
  "回去": [
    "hui2 qu5"
  ],
  "囊": [
    "nang2"
  ],
  "咩": [
    "mie1",
    "mie1"
  ],
  "定期": [
    "ding4 qi1"
  ],
  "讥笑": [
    "ji1 xiao4"
  ],
  "抚摸": [
    "fu3 mo1"
  ],
  "曝光": [
    "bao4 guang1"
  ],
  "榑": [
    null
  ],
  "缩水": [
    "suo1 shui3"
  ],
  "裎": [
    "cheng2",
    "cheng3"
  ],
  "挑起": [
    "tiao3 qi3"
  ],
  "心肠": [
    "xin1 chang2"
  ],
  "椎": [
    "chui2",
    "zhui1"
  ],
  "句子": [
    "ju4 zi5"
  ],
  "书房": [
    "shu1 fang2"
  ],
  "难看": [
    "nan2 kan4"
  ],
  "固": [
    "gu4"
  ],
  "学历": [
    "xue2 li4"
  ],
  "孽": [
    "nie4",
    "nie4"
  ],
  "荒诞": [
    "huang1 dan4"
  ],
  "采矿": [
    "cai3 kuang4"
  ],
  "查明": [
    "cha2 ming2"
  ],
  "然后": [
    "ran2 hou4"
  ],
  "洴": [
    "ping2"
  ],
  "宴会": [
    "yan4 hui4"
  ],
  "颁发": [
    "ban1 fa1"
  ],
  "鲐": [
    "tai2"
  ],
  "必": [
    "bi4"
  ],
  "接轨": [
    "jie1 gui3"
  ],
  "潏": [
    "jue2"
  ],
  "驺": [
    "Zou1",
    "zou1"
  ],
  "茓": [
    "xue2"
  ],
  "晔": [
    "ye4"
  ],
  "缰": [
    "jiang1",
    "jiang1"
  ],
  "威风": [
    "wei1 feng1"
  ],
  "同伴": [
    "tong2 ban4"
  ],
  "拜托": [
    "bai4 tuo1"
  ],
  "去处": [
    "qu4 chu4"
  ],
  "乃至": [
    "nai3 zhi4"
  ],
  "主页": [
    "zhu3 ye4"
  ],
  "类别": [
    "lei4 bie2"
  ],
  "下岗": [
    "xia4 gang3"
  ],
  "集邮": [
    "ji2 you2"
  ],
  "鳚": [
    "wei4"
  ],
  "浉": [
    "Shi1"
  ],
  "夯": [
    "ben4",
    "hang1"
  ],
  "彪": [
    "biao1"
  ],
  "平息": [
    "ping2 xi1"
  ],
  "私事": [
    "si1 shi4"
  ],
  "下面": [
    "xia4 mian4",
    "xia4 mian4"
  ],
  "低": [
    "di1"
  ],
  "刨": [
    "bao4",
    "pao2",
    "bao4"
  ],
  "咯": [
    "ge1",
    "lo5",
    "luo4"
  ],
  "命名": [
    "ming4 ming2"
  ],
  "大方": [
    "da4 fang1",
    "da4 fang5"
  ],
  "正好": [
    "zheng4 hao3"
  ],
  "置": [
    "zhi4"
  ],
  "歼灭": [
    "jian1 mie4"
  ],
  "全": [
    "Quan2",
    "quan2"
  ],
  "嬛": [
    "huan2",
    "qiong2",
    "xuan1"
  ],
  "蹊": [
    "qi1",
    "xi1"
  ],
  "阴性": [
    "yin1 xing4"
  ],
  "祐": [
    "you4"
  ],
  "巧妙": [
    "qiao3 miao4"
  ],
  "宽松": [
    "kuan1 song1"
  ],
  "凶恶": [
    "xiong1 e4",
    "xiong1 e4"
  ],
  "时间表": [
    "shi2 jian1 biao3"
  ],
  "出院": [
    "chu1 yuan4"
  ],
  "穿越": [
    "chuan1 yue4"
  ],
  "鳔": [
    "biao4"
  ],
  "首脑": [
    "shou3 nao3"
  ],
  "娅": [
    "ya4"
  ],
  "溧": [
    "li4"
  ],
  "叉": [
    "cha1",
    "cha2",
    "cha3"
  ],
  "周到": [
    "zhou1 dao4"
  ],
  "口腔": [
    "kou3 qiang1"
  ],
  "商贩": [
    "shang1 fan4"
  ],
  "傺": [
    "chi4"
  ],
  "鹒": [
    "geng1"
  ],
  "得分": [
    "de2 fen1"
  ],
  "煤": [
    "mei2"
  ],
  "怫": [
    "fei4",
    "fu2"
  ],
  "筷": [
    "kuai4"
  ],
  "近": [
    "jin4"
  ],
  "风餐露宿": [
    "feng1 can1 lu4 su4"
  ],
  "谢谢": [
    "xie4 xie5"
  ],
  "做到": [
    "zuo4 dao4"
  ],
  "公里": [
    "gong1 li3"
  ],
  "珸": [
    null
  ],
  "尼": [
    "ni2"
  ],
  "烙": [
    "lao4",
    "luo4"
  ],
  "苒": [
    "ran3"
  ],
  "下落": [
    "xia4 luo4"
  ],
  "鹫": [
    "jiu4"
  ],
  "骑": [
    "ji4",
    "qi2"
  ],
  "行家": [
    "hang2 jia1"
  ],
  "司": [
    "Si1",
    "si1"
  ],
  "辋": [
    "wang3"
  ],
  "触动": [
    "chu4 dong4"
  ],
  "聚会": [
    "ju4 hui4"
  ],
  "公式": [
    "gong1 shi4"
  ],
  "捐助": [
    "juan1 zhu4"
  ],
  "㧑": [
    "hui1"
  ],
  "世纪": [
    "shi4 ji4"
  ],
  "诱饵": [
    "you4 er3"
  ],
  "亶": [
    "Dan3",
    "dan3"
  ],
  "莝": [
    "cuo4"
  ],
  "举重": [
    "ju3 zhong4"
  ],
  "寒假": [
    "han2 jia4"
  ],
  "赒": [
    "zhou1"
  ],
  "浲": [
    "feng2"
  ],
  "蕴藏": [
    "yun4 cang2"
  ],
  "月份": [
    "yue4 fen4"
  ],
  "用法": [
    "yong4 fa3"
  ],
  "钖": [
    "yang2"
  ],
  "自信": [
    "zi4 xin4"
  ],
  "容许": [
    "rong2 xu3"
  ],
  "橞": [
    null
  ],
  "埯": [
    "an3"
  ],
  "进来": [
    "jin4 lai2"
  ],
  "筹措": [
    "chou2 cuo4"
  ],
  "哕": [
    "hui4",
    "yue3"
  ],
  "蓿": [
    "xu4"
  ],
  "不得了": [
    "bu4 de2 liao3"
  ],
  "辕": [
    "yuan2"
  ],
  "鲨": [
    "sha1"
  ],
  "翻天覆地": [
    "fan1 tian1 fu4 di4"
  ],
  "武力": [
    "wu3 li4"
  ],
  "眼光": [
    "yan3 guang1"
  ],
  "生涯": [
    "sheng1 ya2"
  ],
  "男士": [
    "nan2 shi4"
  ],
  "运行": [
    "yun4 xing2"
  ],
  "避难": [
    "bi4 nan4"
  ],
  "缬": [
    "xie2"
  ],
  "乐趣": [
    "le4 qu4"
  ],
  "互访": [
    "hu4 fang3"
  ],
  "再说": [
    "zai4 shuo1"
  ],
  "搜寻": [
    "sou1 xun2"
  ],
  "摄": [
    "she4"
  ],
  "罱": [
    "lan3"
  ],
  "有助于": [
    "you3 zhu4 yu2"
  ],
  "凝": [
    "ning2"
  ],
  "丢掉": [
    "diu1 diao4"
  ],
  "孤儿": [
    "gu1 er2"
  ],
  "酝酿": [
    "yun4 niang4"
  ],
  "伫": [
    "zhu4",
    "zhu4"
  ],
  "酒店": [
    "jiu3 dian4"
  ],
  "铞": [
    "diao4"
  ],
  "砄": [
    "jue2"
  ],
  "目标": [
    "mu4 biao1"
  ],
  "生活": [
    "sheng1 huo2"
  ],
  "坫": [
    "dian4"
  ],
  "太平": [
    "Tai4 ping2",
    "tai4 ping2"
  ],
  "超级": [
    "chao1 ji2"
  ],
  "机械": [
    "ji1 xie4"
  ],
  "祏": [
    "shi2"
  ],
  "用于": [
    "yong4 yu2"
  ],
  "代号": [
    "dai4 hao4"
  ],
  "偷偷": [
    "tou1 tou1"
  ],
  "极": [
    "ji2"
  ],
  "铎": [
    "Duo2",
    "duo2"
  ],
  "乔": [
    "Qiao2",
    "qiao2"
  ],
  "有事": [
    "you3 shi4"
  ],
  "季度": [
    "ji4 du4"
  ],
  "日常": [
    "ri4 chang2"
  ],
  "总": [
    "zong3"
  ],
  "幡": [
    "fan1"
  ],
  "鲬": [
    null
  ],
  "惊慌失措": [
    "jing1 huang1 shi1 cuo4"
  ],
  "盘": [
    "pan2"
  ],
  "颖": [
    "ying3"
  ],
  "最初": [
    "zui4 chu1"
  ],
  "峰回路转": [
    "feng1 hui2 lu4 zhuan3"
  ],
  "训": [
    "xun4"
  ],
  "慭": [
    "yin4"
  ],
  "霸占": [
    "ba4 zhan4"
  ],
  "缁": [
    "zi1"
  ],
  "抉": [
    "jue2"
  ],
  "次数": [
    "ci4 shu4"
  ],
  "陞": [
    null
  ],
  "埘": [
    "shi2"
  ],
  "汈": [
    "diao1"
  ],
  "发怒": [
    "fa1 nu4"
  ],
  "不许": [
    "bu4 xu3"
  ],
  "依据": [
    "yi1 ju4"
  ],
  "反对": [
    "fan3 dui4"
  ],
  "本性": [
    "ben3 xing4"
  ],
  "猱": [
    "nao2"
  ],
  "爟": [
    "guan4"
  ],
  "戡": [
    "kan1"
  ],
  "虒": [
    "si1"
  ],
  "迅速": [
    "xun4 su4"
  ],
  "改装": [
    "gai3 zhuang1"
  ],
  "朔": [
    "shuo4"
  ],
  "电线": [
    "dian4 xian4"
  ],
  "火锅": [
    "huo3 guo1"
  ],
  "桯": [
    "ting1"
  ],
  "粲": [
    "can4"
  ],
  "隐形": [
    "yin3 xing2"
  ],
  "谣": [
    "yao2"
  ],
  "极其": [
    "ji2 qi2"
  ],
  "长短": [
    "chang2 duan3"
  ],
  "礼品": [
    "li3 pin3"
  ],
  "钓": [
    "diao4"
  ],
  "奖品": [
    "jiang3 pin3"
  ],
  "不一定": [
    "bu4 yi1 ding4"
  ],
  "页": [
    "xie2",
    "ye4"
  ],
  "嗥": [
    "hao2",
    "hao2",
    "hao2"
  ],
  "众所周知": [
    "zhong4 suo3 zhou1 zhi1",
    "zhong4 suo3 zhou1 zhi1"
  ],
  "持久": [
    "chi2 jiu3"
  ],
  "压制": [
    "ya1 zhi4"
  ],
  "担保": [
    "dan1 bao3"
  ],
  "亮点": [
    "liang4 dian3"
  ],
  "首先": [
    "shou3 xian1"
  ],
  "锛": [
    "ben1"
  ],
  "风俗": [
    "feng1 su2"
  ],
  "底层": [
    "di3 ceng2"
  ],
  "雩": [
    "yu2"
  ],
  "严重": [
    "yan2 zhong4"
  ],
  "讽": [
    "feng3"
  ],
  "污秽": [
    "wu1 hui4"
  ],
  "特价": [
    "te4 jia4"
  ],
  "上市": [
    "shang4 shi4"
  ],
  "蒹": [
    "jian1"
  ],
  "铳": [
    "chong4"
  ],
  "残缺": [
    "can2 que1"
  ],
  "耳闻目睹": [
    "er3 wen2 mu4 du3"
  ],
  "容颜": [
    "rong2 yan2"
  ],
  "腐化": [
    "fu3 hua4"
  ],
  "陑": [
    "Er2"
  ],
  "獬": [
    "xie4"
  ],
  "鉴赏": [
    "jian4 shang3"
  ],
  "晚": [
    "wan3"
  ],
  "鳖": [
    "bie1",
    "bie1"
  ],
  "逑": [
    "qiu2"
  ],
  "蔷": [
    "qiang2"
  ],
  "问路": [
    "wen4 lu4"
  ],
  "峒": [
    "dong4",
    "tong2",
    "tong2"
  ],
  "虼": [
    "ge4"
  ],
  "焓": [
    "han2"
  ],
  "面粉": [
    "mian4 fen3"
  ],
  "足迹": [
    "zu2 ji4"
  ],
  "恰到好处": [
    "qia4 dao4 hao3 chu4"
  ],
  "翈": [
    null
  ],
  "碈": [
    "min2"
  ],
  "溚": [
    null
  ],
  "呼救": [
    "hu1 jiu4"
  ],
  "偏": [
    "pian1"
  ],
  "没事儿": [
    "mei2 shi4 r5"
  ],
  "公民": [
    "gong1 min2"
  ],
  "祸": [
    "huo4",
    "huo4"
  ],
  "善于": [
    "shan4 yu2"
  ],
  "获奖": [
    "huo4 jiang3"
  ],
  "坼": [
    "che4"
  ],
  "当作": [
    "dang4 zuo4"
  ],
  "窟": [
    "ku1"
  ],
  "饽": [
    "bo1"
  ],
  "阶段": [
    "jie1 duan4"
  ],
  "雄厚": [
    "xiong2 hou4"
  ],
  "陪": [
    "pei2"
  ],
  "至今": [
    "zhi4 jin1"
  ],
  "吴": [
    "Wu2"
  ],
  "嫦": [
    "chang2"
  ],
  "佩": [
    "pei4",
    "pei4"
  ],
  "碾": [
    "nian3"
  ],
  "候选人": [
    "hou4 xuan3 ren2"
  ],
  "紧迫": [
    "jin3 po4"
  ],
  "时光": [
    "shi2 guang1"
  ],
  "大吃一惊": [
    "da4 chi1 yi1 jing1"
  ],
  "当前": [
    "dang1 qian2"
  ],
  "澴": [
    "huan2"
  ],
  "下游": [
    "xia4 you2"
  ],
  "约定俗成": [
    "yue1 ding4 su2 cheng2"
  ],
  "协议": [
    "xie2 yi4"
  ],
  "一无所有": [
    "yi1 wu2 suo3 you3"
  ],
  "名气": [
    "ming2 qi5"
  ],
  "普及": [
    "pu3 ji2"
  ],
  "夜市": [
    "ye4 shi4"
  ],
  "霨": [
    "wei4"
  ],
  "面试": [
    "mian4 shi4"
  ],
  "而已": [
    "er2 yi3"
  ],
  "媲美": [
    "pi4 mei3"
  ],
  "抱": [
    "bao4"
  ],
  "茆": [
    "mao2",
    "mao3"
  ],
  "祭奠": [
    "ji4 dian4"
  ],
  "美化": [
    "mei3 hua4"
  ],
  "䃅": [
    null
  ],
  "竞技": [
    "jing4 ji4"
  ],
  "整齐": [
    "zheng3 qi2"
  ],
  "橘": [
    "ju2"
  ],
  "藓": [
    "xian3"
  ],
  "协会": [
    "xie2 hui4"
  ],
  "热衷": [
    "re4 zhong1"
  ],
  "母": [
    "mu3"
  ],
  "经验": [
    "jing1 yan4"
  ],
  "海绵": [
    "hai3 mian2"
  ],
  "抚": [
    "fu3"
  ],
  "日历": [
    "ri4 li4"
  ],
  "茯": [
    "fu2"
  ],
  "看到": [
    "kan4 dao4"
  ],
  "之间": [
    "zhi1 jian1"
  ],
  "增大": [
    "zeng1 da4"
  ],
  "没法儿": [
    "mei2 fa3 r5"
  ],
  "米": [
    "Mi3",
    "mi3"
  ],
  "怏": [
    "yang4"
  ],
  "罍": [
    "lei2"
  ],
  "傩": [
    "nuo2"
  ],
  "消沉": [
    "xiao1 chen2"
  ],
  "峱": [
    "nao2"
  ],
  "不懈": [
    "bu4 xie4"
  ],
  "分解": [
    "fen1 jie3"
  ],
  "地区": [
    "di4 qu1"
  ],
  "公益性": [
    null
  ],
  "锌": [
    "xin1"
  ],
  "锩": [
    "juan4"
  ],
  "高速公路": [
    "gao1 su4 gong1 lu4"
  ],
  "醺": [
    "xun1"
  ],
  "养老金": [
    "yang3 lao3 jin1"
  ],
  "塆": [
    "wan1"
  ],
  "被捕": [
    "bei4 bu3"
  ],
  "容": [
    "Rong2",
    "rong2"
  ],
  "亘": [
    "gen4"
  ],
  "离不开": [
    "li2 bu5 kai1"
  ],
  "山川": [
    "shan1 chuan1"
  ],
  "雪上加霜": [
    "xue3 shang4 jia1 shuang1"
  ],
  "出差": [
    "chu1 chai1"
  ],
  "志气": [
    "zhi4 qi4"
  ],
  "勋章": [
    "xun1 zhang1"
  ],
  "答": [
    "da1",
    "da2"
  ],
  "乡": [
    "xiang1"
  ],
  "丢失": [
    "diu1 shi1"
  ],
  "鼒": [
    "zi1"
  ],
  "理想": [
    "li3 xiang3"
  ],
  "渗": [
    "shen4"
  ],
  "东北": [
    "Dong1 bei3",
    "dong1 bei3"
  ],
  "嘴": [
    "zui3"
  ],
  "卡车": [
    "ka3 che1"
  ],
  "贯": [
    "guan4"
  ],
  "吵": [
    "chao3"
  ],
  "外交": [
    "wai4 jiao1"
  ],
  "骑车": [
    "qi2 che1"
  ],
  "查找": [
    "cha2 zhao3"
  ],
  "实习": [
    "shi2 xi2"
  ],
  "传媒": [
    "chuan2 mei2"
  ],
  "狳": [
    "yu2"
  ],
  "而且": [
    "er2 qie3"
  ],
  "享受": [
    "xiang3 shou4"
  ],
  "沅": [
    "Yuan2"
  ],
  "囹": [
    "ling2"
  ],
  "刍": [
    "chu2"
  ],
  "羲": [
    "Xi1"
  ],
  "急": [
    "ji2"
  ],
  "阁": [
    "ge2"
  ],
  "喈": [
    "jie1"
  ],
  "篾": [
    "mie4"
  ],
  "熟人": [
    "shu2 ren2"
  ],
  "眺": [
    "tiao4"
  ],
  "漓": [
    "li2",
    "Li2",
    "li2"
  ],
  "鹅": [
    "e2",
    "e2",
    "e2"
  ],
  "依然": [
    "yi1 ran2"
  ],
  "原本": [
    "yuan2 ben3"
  ],
  "无情无义": [
    "wu2 qing2 wu2 yi4"
  ],
  "弱点": [
    "ruo4 dian3"
  ],
  "崂": [
    "lao2"
  ],
  "不再": [
    "bu4 zai4"
  ],
  "半": [
    "ban4"
  ],
  "打听": [
    "da3 ting5"
  ],
  "蹂": [
    "rou2"
  ],
  "相连": [
    "xiang1 lian2"
  ],
  "接连": [
    "jie1 lian2"
  ],
  "补课": [
    "bu3 ke4"
  ],
  "楷模": [
    "kai3 mo2"
  ],
  "领军": [
    "ling3 jun1"
  ],
  "病房": [
    "bing4 fang2"
  ],
  "柳": [
    "Liu3",
    "liu3",
    "liu3",
    "liu3"
  ],
  "提早": [
    "ti2 zao3"
  ],
  "后边": [
    "hou4 bian5"
  ],
  "判": [
    "pan4"
  ],
  "机动": [
    "ji1 dong4"
  ],
  "天才": [
    "tian1 cai2"
  ],
  "轻": [
    "qing1"
  ],
  "厌倦": [
    "yan4 juan4"
  ],
  "丙": [
    "bing3"
  ],
  "硪": [
    "wo4"
  ],
  "头发": [
    "tou2 fa5"
  ],
  "痤": [
    "cuo2"
  ],
  "尊贵": [
    "zun1 gui4"
  ],
  "响亮": [
    "xiang3 liang4"
  ],
  "桑": [
    "Sang1",
    "sang1",
    "sang1"
  ],
  "蚧": [
    "jie4"
  ],
  "歅": [
    null
  ],
  "猪": [
    "zhu1"
  ],
  "婠": [
    null
  ],
  "化石": [
    "hua4 shi2"
  ],
  "果实": [
    "guo3 shi2"
  ],
  "鹗": [
    "e4"
  ],
  "更加": [
    "geng4 jia1"
  ],
  "代言人": [
    "dai4 yan2 ren2"
  ],
  "天然": [
    "tian1 ran2"
  ],
  "月初": [
    "yue4 chu1"
  ],
  "砮": [
    "nu3"
  ],
  "祖宗": [
    "zu3 zong1"
  ],
  "廑": [
    "jin3",
    "qin2"
  ],
  "矛": [
    "mao2"
  ],
  "领事馆": [
    "ling3 shi4 guan3"
  ],
  "正": [
    "zheng1",
    "zheng4"
  ],
  "鞔": [
    null
  ],
  "坷": [
    "ke3"
  ],
  "化险为夷": [
    "hua4 xian3 wei2 yi2"
  ],
  "逃走": [
    "tao2 zou3"
  ],
  "剪": [
    "Jian3",
    "jian3"
  ],
  "叔": [
    "shu1"
  ],
  "宫": [
    "Gong1",
    "gong1"
  ],
  "皲": [
    "jun1"
  ],
  "忑": [
    "te4"
  ],
  "洸": [
    "guang1"
  ],
  "垎": [
    "he4"
  ],
  "动员": [
    "dong4 yuan2"
  ],
  "莫过于": [
    "mo4 guo4 yu2"
  ],
  "夏": [
    "Xia4",
    "xia4"
  ],
  "增长": [
    "zeng1 zhang3"
  ],
  "弆": [
    "ju3"
  ],
  "俫": [
    null
  ],
  "自己": [
    "zi4 ji3"
  ],
  "嬿": [
    "yan4"
  ],
  "君": [
    "jun1"
  ],
  "把": [
    "ba3",
    "ba4"
  ],
  "地铁站": [
    "di4 tie3 zhan4"
  ],
  "执照": [
    "zhi2 zhao4"
  ],
  "殷勤": [
    "yin1 qin2"
  ],
  "心声": [
    "xin1 sheng1"
  ],
  "庭": [
    "ting2"
  ],
  "任意": [
    "ren4 yi4"
  ],
  "复习": [
    "fu4 xi2",
    "fu4 xi2"
  ],
  "落下": [
    "luo4 xia4"
  ],
  "狃": [
    "niu3"
  ],
  "小区": [
    "xiao3 qu1"
  ],
  "消耗": [
    "xiao1 hao4"
  ],
  "加紧": [
    "jia1 jin3"
  ],
  "陟": [
    "zhi4"
  ],
  "谜语": [
    "mi2 yu3"
  ],
  "长假": [
    "chang2 jia4"
  ],
  "踞": [
    "ju4"
  ],
  "光顾": [
    "guang1 gu4"
  ],
  "高新技术": [
    "gao1 xin1 ji4 shu4"
  ],
  "庑": [
    "wu2",
    "wu3"
  ],
  "肚子": [
    "du4 zi5"
  ],
  "线": [
    "xian4",
    "xian4"
  ],
  "略": [
    "lu:e4",
    "lu:e4"
  ],
  "专程": [
    "zhuan1 cheng2"
  ],
  "概括": [
    "gai4 kuo4"
  ],
  "瑟": [
    "se4"
  ],
  "舲": [
    "ling2"
  ],
  "名贵": [
    "ming2 gui4"
  ],
  "黑板": [
    "hei1 ban3"
  ],
  "奘": [
    "zang4",
    "zhuang3"
  ],
  "女孩儿": [
    "nu:3 hai2 r5"
  ],
  "普通": [
    "pu3 tong1"
  ],
  "电台": [
    "dian4 tai2"
  ],
  "肢体": [
    "zhi1 ti3"
  ],
  "神态": [
    "shen2 tai4"
  ],
  "聪明": [
    "cong1 ming5"
  ],
  "推行": [
    "tui1 xing2"
  ],
  "现实": [
    "xian4 shi2"
  ],
  "期间": [
    "qi1 jian1"
  ],
  "茂": [
    "mao4"
  ],
  "犹豫不决": [
    "you2 yu4 bu4 jue2"
  ],
  "不定": [
    "bu4 ding4"
  ],
  "专用": [
    "zhuan1 yong4"
  ],
  "洹": [
    "huan2"
  ],
  "跎": [
    "tuo2"
  ],
  "銎": [
    "qiong1"
  ],
  "楼道": [
    "lou2 dao4"
  ],
  "俘虏": [
    "fu2 lu3"
  ],
  "前途": [
    "qian2 tu2"
  ],
  "胎": [
    "tai1"
  ],
  "条例": [
    "tiao2 li4"
  ],
  "增强": [
    "zeng1 qiang2"
  ],
  "到期": [
    "dao4 qi1"
  ],
  "竭力": [
    "jie2 li4"
  ],
  "模型": [
    "mo2 xing2"
  ],
  "沈": [
    "Shen3",
    "chen2",
    "Shen3",
    "shen3"
  ],
  "闹": [
    "nao4",
    "nao4"
  ],
  "猖": [
    "chang1"
  ],
  "一筹莫展": [
    "yi1 chou2 mo4 zhan3"
  ],
  "鸠": [
    "jiu1"
  ],
  "竘": [
    null
  ],
  "团伙": [
    "tuan2 huo3"
  ],
  "数": [
    "shu3",
    "shu4",
    "shuo4"
  ],
  "帽子": [
    "mao4 zi5"
  ],
  "舭": [
    "bi3"
  ],
  "挖苦": [
    "wa1 ku3"
  ],
  "可靠": [
    "ke3 kao4"
  ],
  "笺": [
    "jian1",
    "jian1",
    "jian1"
  ],
  "缘": [
    "yuan2"
  ],
  "撤换": [
    "che4 huan4"
  ],
  "艽": [
    "jiao1"
  ],
  "情人": [
    "qing2 ren2"
  ],
  "㛹": [
    null
  ],
  "通报": [
    "tong1 bao4"
  ],
  "当事人": [
    "dang1 shi4 ren2"
  ],
  "厣": [
    "yan3"
  ],
  "靠": [
    "kao4"
  ],
  "傲": [
    "ao4"
  ],
  "好看": [
    "hao3 kan4"
  ],
  "特例": [
    "te4 li4"
  ],
  "兼容": [
    "jian1 rong2"
  ],
  "漩": [
    "xuan2"
  ],
  "贮": [
    "zhu4"
  ],
  "汽": [
    "qi4"
  ],
  "反差": [
    "fan3 cha1"
  ],
  "结论": [
    "jie2 lun4"
  ],
  "携带": [
    "xie2 dai4"
  ],
  "竣工": [
    "jun4 gong1"
  ],
  "荣幸": [
    "rong2 xing4"
  ],
  "外面": [
    "wai4 mian4"
  ],
  "背包": [
    "bei1 bao1"
  ],
  "铩": [
    "sha1"
  ],
  "崮": [
    "gu4"
  ],
  "片段": [
    "pian4 duan4"
  ],
  "当地": [
    "dang1 di4"
  ],
  "姿": [
    "zi1"
  ],
  "纤维": [
    "xian1 wei2"
  ],
  "诞生": [
    "dan4 sheng1"
  ],
  "暅": [
    null
  ],
  "罪魁祸首": [
    "zui4 kui2 huo4 shou3"
  ],
  "慨": [
    "kai3",
    "kai3"
  ],
  "安稳": [
    "an1 wen3"
  ],
  "战略": [
    "zhan4 lu:e4"
  ],
  "冰雪": [
    "bing1 xue3"
  ],
  "柜台": [
    "gui4 tai2",
    "gui4 tai2",
    "gui4 tai2"
  ],
  "称赞": [
    "cheng1 zan4"
  ],
  "柩": [
    "jiu4"
  ],
  "在职": [
    "zai4 zhi2"
  ],
  "耙": [
    "ba4",
    "pa2"
  ],
  "很": [
    "hen3"
  ],
  "打雷": [
    "da3 lei2"
  ],
  "手套": [
    "shou3 tao4"
  ],
  "类": [
    "lei4"
  ],
  "居然": [
    "ju1 ran2"
  ],
  "技": [
    "ji4"
  ],
  "潽": [
    "pu1"
  ],
  "士": [
    "Shi4",
    "shi4"
  ],
  "一线": [
    "yi1 xian4"
  ],
  "念书": [
    "nian4 shu1"
  ],
  "一律": [
    "yi1 lu:4"
  ],
  "发射": [
    "fa1 she4"
  ],
  "韪": [
    "wei3"
  ],
  "随机": [
    "sui2 ji1"
  ],
  "桊": [
    null
  ],
  "不翼而飞": [
    "bu4 yi4 er2 fei1"
  ],
  "计划": [
    "ji4 hua4"
  ],
  "疵": [
    "ci1"
  ],
  "纂": [
    "zuan3",
    "zuan3"
  ],
  "关": [
    "Guan1",
    "guan1"
  ],
  "吏": [
    "li4"
  ],
  "荥": [
    "xing2"
  ],
  "葴": [
    "zhen1"
  ],
  "买卖": [
    "mai3 mai4"
  ],
  "垙": [
    "guang1"
  ],
  "发电机": [
    "fa1 dian4 ji1"
  ],
  "联手": [
    "lian2 shou3"
  ],
  "闾": [
    "lu:2"
  ],
  "鲅": [
    "ba4"
  ],
  "诱惑": [
    "you4 huo4"
  ],
  "坭": [
    "ni2"
  ],
  "赁": [
    "lin4"
  ],
  "墟": [
    "xu1"
  ],
  "收敛": [
    "shou1 lian3"
  ],
  "堪": [
    "kan1"
  ],
  "墡": [
    null
  ],
  "异口同声": [
    "yi4 kou3 tong2 sheng1"
  ],
  "再也": [
    "zai4 ye3"
  ],
  "健": [
    "jian4"
  ],
  "状况": [
    "zhuang4 kuang4"
  ],
  "判断": [
    "pan4 duan4"
  ],
  "刊物": [
    "kan1 wu4"
  ],
  "耳朵": [
    "er3 duo5"
  ],
  "年迈": [
    "nian2 mai4"
  ],
  "假日": [
    "jia4 ri4"
  ],
  "气体": [
    "qi4 ti3"
  ],
  "珍": [
    "zhen1",
    "zhen1"
  ],
  "困扰": [
    "kun4 rao3"
  ],
  "翊": [
    "yi4"
  ],
  "误导": [
    "wu4 dao3"
  ],
  "前头": [
    "qian2 tou5"
  ],
  "携手": [
    "xie2 shou3"
  ],
  "最近": [
    "zui4 jin4"
  ],
  "隘": [
    "ai4"
  ],
  "巩固": [
    "gong3 gu4"
  ],
  "铊": [
    "ta1"
  ],
  "瓜子": [
    "gua1 zi3"
  ],
  "调研": [
    "diao4 yan2"
  ],
  "老太太": [
    "lao3 tai4 tai5"
  ],
  "敬而远之": [
    "jing4 er2 yuan3 zhi1"
  ],
  "觃": [
    "yan4"
  ],
  "崇尚": [
    "chong2 shang4"
  ],
  "愫": [
    "su4"
  ],
  "埚": [
    "guo1"
  ],
  "惊天动地": [
    "jing1 tian1 dong4 di4"
  ],
  "残酷": [
    "can2 ku4"
  ],
  "泽": [
    "ze2"
  ],
  "打官司": [
    "da3 guan1 si5"
  ],
  "草案": [
    "cao3 an4"
  ],
  "丫头": [
    "ya1 tou5"
  ],
  "忽略": [
    "hu1 lu:e4"
  ],
  "词": [
    "ci2",
    "ci2"
  ],
  "系统": [
    "xi4 tong3"
  ],
  "鏊": [
    "ao4"
  ],
  "崦": [
    "yan1"
  ],
  "复原": [
    "fu4 yuan2"
  ],
  "知足": [
    "zhi1 zu2"
  ],
  "少儿": [
    "shao4 er2"
  ],
  "妨害": [
    "fang2 hai4"
  ],
  "忙碌": [
    "mang2 lu4"
  ],
  "踦": [
    "ji3"
  ],
  "馓": [
    "san3"
  ],
  "毛病": [
    "mao2 bing4"
  ],
  "蚶": [
    "han1"
  ],
  "蓊": [
    "weng3"
  ],
  "同样": [
    "tong2 yang4"
  ],
  "列": [
    "lie4"
  ],
  "合格": [
    "he2 ge2"
  ],
  "收购": [
    "shou1 gou4"
  ],
  "湿度": [
    "shi1 du4"
  ],
  "一口气": [
    "yi1 kou3 qi4"
  ],
  "公证": [
    "gong1 zheng4"
  ],
  "发出": [
    "fa1 chu1"
  ],
  "闪": [
    "Shan3",
    "shan3"
  ],
  "沙漠": [
    "sha1 mo4"
  ],
  "有没有": [
    "you3 mei2 you3"
  ],
  "酽": [
    "yan4"
  ],
  "纳": [
    "Na4",
    "na4"
  ],
  "密切": [
    "mi4 qie4"
  ],
  "檐": [
    "yan2",
    "yan2"
  ],
  "迄今": [
    "qi4 jin1"
  ],
  "声称": [
    "sheng1 cheng1"
  ],
  "著名": [
    "zhu4 ming2"
  ],
  "粿": [
    "guo3"
  ],
  "涿": [
    "zhuo1"
  ],
  "脲": [
    "niao4"
  ],
  "散文": [
    "san3 wen2"
  ],
  "积": [
    "ji1"
  ],
  "阮": [
    "Ruan3",
    "ruan3"
  ],
  "龙": [
    "Long2",
    "long2"
  ],
  "庤": [
    "zhi4"
  ],
  "抻": [
    "chen1"
  ],
  "伧": [
    "cang1"
  ],
  "大事": [
    "da4 shi4"
  ],
  "于": [
    "Yu2",
    "yu2",
    "yu2"
  ],
  "舸": [
    "ge3"
  ],
  "迢": [
    "tiao2"
  ],
  "极为": [
    "ji2 wei2"
  ],
  "蝓": [
    "yu2"
  ],
  "埙": [
    "xun1"
  ],
  "保鲜": [
    "bao3 xian1"
  ],
  "解雇": [
    "jie3 gu4"
  ],
  "心想事成": [
    "xin1 xiang3 shi4 cheng2"
  ],
  "䴘": [
    "ti1",
    "ti1"
  ],
  "昡": [
    "xuan4"
  ],
  "上周": [
    "shang4 zhou1"
  ],
  "尺": [
    "che3",
    "chi3"
  ],
  "实地": [
    "shi2 di4"
  ],
  "灯光": [
    "deng1 guang1"
  ],
  "玹": [
    "xuan2"
  ],
  "传统": [
    "chuan2 tong3"
  ],
  "盱": [
    "Xu1",
    "xu1"
  ],
  "混淆": [
    "hun4 xiao2"
  ],
  "瑰": [
    "gui1",
    "gui1"
  ],
  "从来": [
    "cong2 lai2"
  ],
  "原装": [
    "yuan2 zhuang1"
  ],
  "行程": [
    "xing2 cheng2"
  ],
  "甜": [
    "tian2"
  ],
  "平面": [
    "ping2 mian4"
  ],
  "不过": [
    "bu4 guo4"
  ],
  "缕": [
    "lu:3"
  ],
  "焙": [
    "bei4"
  ],
  "热线": [
    "re4 xian4"
  ],
  "独特": [
    "du2 te4"
  ],
  "繁忙": [
    "fan2 mang2"
  ],
  "后面": [
    "hou4 mian5"
  ],
  "诗人": [
    "shi1 ren2"
  ],
  "讲述": [
    "jiang3 shu4"
  ],
  "可贵": [
    "ke3 gui4"
  ],
  "绣": [
    "xiu4",
    "xiu4"
  ],
  "日复一日": [
    "ri4 fu4 yi1 ri4"
  ],
  "嗐": [
    "hai4"
  ],
  "遗产": [
    "yi2 chan3"
  ],
  "一不小心": [
    "yi1 bu4 xiao3 xin1"
  ],
  "辈": [
    "bei4"
  ],
  "过": [
    "Guo1",
    "guo4",
    "guo5"
  ],
  "亟": [
    "ji2",
    "qi4"
  ],
  "动听": [
    "dong4 ting1"
  ],
  "吉祥": [
    "ji2 xiang2"
  ],
  "报酬": [
    "bao4 chou5"
  ],
  "后人": [
    "hou4 ren2"
  ],
  "点头": [
    "dian3 tou2"
  ],
  "核": [
    "he2",
    "he2"
  ],
  "果": [
    "guo3",
    "guo3"
  ],
  "干旱": [
    "gan1 han4"
  ],
  "禺": [
    "ou3",
    "yu2"
  ],
  "愣": [
    "leng4"
  ],
  "摩擦": [
    "mo2 ca1"
  ],
  "琦": [
    "qi2"
  ],
  "不成": [
    "bu4 cheng2"
  ],
  "柠": [
    "ning2"
  ],
  "嘈": [
    "cao2"
  ],
  "开动": [
    "kai1 dong4"
  ],
  "直径": [
    "zhi2 jing4"
  ],
  "异性": [
    "yi4 xing4"
  ],
  "淝": [
    "fei2"
  ],
  "雄": [
    "xiong2"
  ],
  "难以置信": [
    "nan2 yi3 zhi4 xin4"
  ],
  "观念": [
    "guan1 nian4"
  ],
  "乎": [
    "hu1"
  ],
  "多": [
    "duo1"
  ],
  "丞": [
    "cheng2"
  ],
  "饩": [
    "xi4"
  ],
  "鹁": [
    "bo2"
  ],
  "鲋": [
    "fu4"
  ],
  "词汇": [
    "ci2 hui4",
    "ci2 hui4"
  ],
  "扩展": [
    "kuo4 zhan3"
  ],
  "体温": [
    "ti3 wen1"
  ],
  "秧": [
    "yang1"
  ],
  "牌照": [
    "pai2 zhao4"
  ],
  "视野": [
    "shi4 ye3"
  ],
  "肯定": [
    "ken3 ding4"
  ],
  "眼看": [
    "yan3 kan4"
  ],
  "驾照": [
    "jia4 zhao4"
  ],
  "婪": [
    "lan2",
    "lan2"
  ],
  "馗": [
    "kui2"
  ],
  "行李": [
    "xing2 li5"
  ],
  "理念": [
    "li3 nian4"
  ],
  "效益": [
    "xiao4 yi4"
  ],
  "岠": [
    null
  ],
  "莞": [
    "guan1",
    "guan3",
    "wan3"
  ],
  "嗡": [
    "weng1"
  ],
  "视觉": [
    "shi4 jue2"
  ],
  "玟": [
    "min2",
    "wen2"
  ],
  "监": [
    "jian1",
    "jian4"
  ],
  "黔": [
    "Qian2"
  ],
  "钻石": [
    "zuan4 shi2"
  ],
  "仓库": [
    "cang1 ku4"
  ],
  "动物园": [
    "dong4 wu4 yuan2"
  ],
  "留神": [
    "liu2 shen2"
  ],
  "支柱": [
    "zhi1 zhu4"
  ],
  "汽水": [
    "qi4 shui3"
  ],
  "耻辱": [
    "chi3 ru3"
  ],
  "并非": [
    "bing4 fei1"
  ],
  "营养": [
    "ying2 yang3"
  ],
  "褒": [
    "bao1",
    "bao1"
  ],
  "农民": [
    "nong2 min2"
  ],
  "㸆": [
    null
  ],
  "清淡": [
    "qing1 dan4"
  ],
  "波涛": [
    "bo1 tao1"
  ],
  "缴费": [
    "jiao3 fei4"
  ],
  "动物": [
    "dong4 wu4"
  ],
  "会晤": [
    "hui4 wu4"
  ],
  "闵": [
    "Min3",
    "min3"
  ],
  "英勇": [
    "ying1 yong3"
  ],
  "露面": [
    "lou4 mian4"
  ],
  "怎么": [
    "zen3 me5",
    "zen3 me5"
  ],
  "唵": [
    "an3"
  ],
  "佼": [
    "jiao3"
  ],
  "车上": [
    null
  ],
  "硐": [
    "dong4",
    "tong2"
  ],
  "蜚": [
    "fei3"
  ],
  "罄": [
    "qing4"
  ],
  "恽": [
    "Yun4"
  ],
  "人类": [
    "ren2 lei4"
  ],
  "光滑": [
    "guang1 hua2"
  ],
  "有所": [
    "you3 suo3"
  ],
  "娵": [
    "ju1"
  ],
  "回归": [
    "hui2 gui1"
  ],
  "缴": [
    "jiao3"
  ],
  "浼": [
    "mei3"
  ],
  "耽误": [
    "dan1 wu5"
  ],
  "笊": [
    "zhao4"
  ],
  "鲣": [
    "jian1"
  ],
  "加入": [
    "jia1 ru4"
  ],
  "厂商": [
    "chang3 shang1"
  ],
  "猗": [
    "yi1"
  ],
  "症": [
    "zheng4",
    "zheng1"
  ],
  "斫": [
    "zhuo2",
    "zhuo2",
    "zhuo2"
  ],
  "分红": [
    "fen1 hong2"
  ],
  "岂有此理": [
    "qi3 you3 ci3 li3"
  ],
  "阜": [
    "fu4"
  ],
  "处境": [
    "chu3 jing4"
  ],
  "固定": [
    "gu4 ding4"
  ],
  "回复": [
    "hui2 fu4"
  ],
  "内行": [
    "nei4 hang2"
  ],
  "孚": [
    "fu2"
  ],
  "吸管": [
    "xi1 guan3"
  ],
  "首要": [
    "shou3 yao4"
  ],
  "毫升": [
    "hao2 sheng1"
  ],
  "馘": [
    "guo2"
  ],
  "为人": [
    "wei2 ren2",
    "wei4 ren2"
  ],
  "可行": [
    "ke3 xing2"
  ],
  "窈": [
    "yao3"
  ],
  "钽": [
    "tan3"
  ],
  "残忍": [
    "can2 ren3"
  ],
  "庠": [
    "xiang2"
  ],
  "住宿": [
    "zhu4 su4"
  ],
  "沙发": [
    "sha1 fa1"
  ],
  "衫": [
    "shan1"
  ],
  "茈": [
    "ci2",
    "zi3"
  ],
  "郈": [
    "Hou4"
  ],
  "大妈": [
    "da4 ma1"
  ],
  "常人": [
    "chang2 ren2"
  ],
  "伤员": [
    "shang1 yuan2"
  ],
  "螨": [
    "man3"
  ],
  "烧烤": [
    "shao1 kao3"
  ],
  "貂": [
    "diao1"
  ],
  "宣读": [
    "xuan1 du2"
  ],
  "锎": [
    "kai1"
  ],
  "希": [
    "xi1"
  ],
  "茌": [
    "Chi2"
  ],
  "唱片": [
    "chang4 pian4"
  ],
  "鹠": [
    "liu2"
  ],
  "泊": [
    "bo2",
    "po1"
  ],
  "雠": [
    "chou2"
  ],
  "另": [
    "ling4"
  ],
  "癫": [
    "dian1"
  ],
  "傲慢": [
    "ao4 man4"
  ],
  "主妇": [
    "zhu3 fu4"
  ],
  "召集": [
    "zhao4 ji2"
  ],
  "仁慈": [
    "ren2 ci2"
  ],
  "身": [
    "shen1"
  ],
  "昔": [
    "Xi1",
    "xi1"
  ],
  "葛": [
    "Ge3",
    "ge2"
  ],
  "长大": [
    "zhang3 da4"
  ],
  "澌": [
    "si1"
  ],
  "俘": [
    "fu2"
  ],
  "重心": [
    "zhong4 xin1"
  ],
  "敲": [
    "qiao1"
  ],
  "茵": [
    "yin1"
  ],
  "效应": [
    "xiao4 ying4"
  ],
  "身份证": [
    "shen1 fen4 zheng4"
  ],
  "团": [
    "tuan2",
    "tuan2"
  ],
  "幂": [
    "mi4"
  ],
  "孵化": [
    "fu1 hua4"
  ],
  "项链": [
    "xiang4 lian4"
  ],
  "邻": [
    "lin2",
    "lin2"
  ],
  "一共": [
    "yi1 gong4"
  ],
  "鸿": [
    "hong2"
  ],
  "成就": [
    "cheng2 jiu4"
  ],
  "剀": [
    "kai3"
  ],
  "宾": [
    "bin1"
  ],
  "搒": [
    "bang4",
    "peng4"
  ],
  "全国": [
    "quan2 guo2"
  ],
  "具有": [
    "ju4 you3"
  ],
  "丸": [
    "wan2"
  ],
  "溉": [
    "gai4"
  ],
  "绝对": [
    "jue2 dui4"
  ],
  "救命": [
    "jiu4 ming4"
  ],
  "自身": [
    "zi4 shen1"
  ],
  "玺": [
    "xi3"
  ],
  "双手": [
    "shuang1 shou3"
  ],
  "重组": [
    "chong2 zu3"
  ],
  "夫": [
    "fu1",
    "fu2"
  ],
  "蘸": [
    "zhan4"
  ],
  "大力": [
    "da4 li4"
  ],
  "旺季": [
    "wang4 ji4"
  ],
  "鹂": [
    "li2"
  ],
  "霅": [
    "Zha2",
    "zha2"
  ],
  "谜底": [
    "mi2 di3"
  ],
  "黑白": [
    "hei1 bai2"
  ],
  "干什么": [
    "gan4 shen2 me5"
  ],
  "猃": [
    "xian3"
  ],
  "僵化": [
    "jiang1 hua4"
  ],
  "似的": [
    "shi4 de5"
  ],
  "讠": [
    "yan2"
  ],
  "芎": [
    "xiong1"
  ],
  "帅哥": [
    "shuai4 ge1"
  ],
  "窄": [
    "zhai3"
  ],
  "重伤": [
    "zhong4 shang1"
  ],
  "觯": [
    "zhi4"
  ],
  "甸": [
    "Dian4",
    "dian4"
  ],
  "木": [
    "Mu4",
    "mu4"
  ],
  "猴": [
    "hou2"
  ],
  "马桶": [
    "ma3 tong3"
  ],
  "获取": [
    "huo4 qu3"
  ],
  "蘑菇": [
    "mo2 gu5"
  ],
  "镋": [
    "tang3",
    "tang3"
  ],
  "缤纷": [
    "bin1 fen1"
  ],
  "批准": [
    "pi1 zhun3"
  ],
  "冤": [
    "yuan1",
    "yuan1"
  ],
  "骎": [
    "qin1"
  ],
  "挖": [
    "wa1"
  ],
  "丐": [
    "gai4",
    "gai4",
    "gai4"
  ],
  "扰": [
    "rao3"
  ],
  "海啸": [
    "hai3 xiao4"
  ],
  "醚": [
    "mi2"
  ],
  "淇": [
    "qi2"
  ],
  "博览会": [
    "bo2 lan3 hui4"
  ],
  "枢纽": [
    "shu1 niu3"
  ],
  "出任": [
    "chu1 ren4"
  ],
  "补考": [
    "bu3 kao3"
  ],
  "泮": [
    "pan4"
  ],
  "艳": [
    "yan4",
    "yan4",
    "yan4",
    "yan4"
  ],
  "展出": [
    "zhan3 chu1"
  ],
  "擒": [
    "qin2"
  ],
  "启蒙": [
    "qi3 meng2",
    "qi3 meng2"
  ],
  "充分": [
    "chong1 fen4"
  ],
  "荷": [
    "He2",
    "he2",
    "he4"
  ],
  "能否": [
    "neng2 fou3"
  ],
  "模拟": [
    "mo2 ni3"
  ],
  "掩护": [
    "yan3 hu4"
  ],
  "援助": [
    "yuan2 zhu4"
  ],
  "俑": [
    "yong3"
  ],
  "变为": [
    "bian4 wei2"
  ],
  "讲究": [
    "jiang3 jiu5"
  ],
  "蟒": [
    "mang3"
  ],
  "看中": [
    "kan4 zhong4"
  ],
  "闹钟": [
    "nao4 zhong1"
  ],
  "牦": [
    "mao2"
  ],
  "由此": [
    "you2 ci3"
  ],
  "洓": [
    null
  ],
  "可恶": [
    "ke3 wu4"
  ],
  "艰难": [
    "jian1 nan2"
  ],
  "强势": [
    "qiang2 shi4"
  ],
  "振": [
    "zhen4"
  ],
  "大选": [
    "da4 xuan3"
  ],
  "魈": [
    "xiao1"
  ],
  "心爱": [
    "xin1 ai4"
  ],
  "率领": [
    "shuai4 ling3"
  ],
  "冔": [
    "xu3"
  ],
  "堵车": [
    "du3 che1"
  ],
  "商标": [
    "shang1 biao1"
  ],
  "震": [
    "zhen4"
  ],
  "相关": [
    "xiang1 guan1"
  ],
  "邮政": [
    "you2 zheng4"
  ],
  "悬挂": [
    "xuan2 gua4"
  ],
  "姐妹": [
    "jie3 mei4"
  ],
  "怨": [
    "yuan4"
  ],
  "中学": [
    "zhong1 xue2"
  ],
  "筮": [
    "shi4"
  ],
  "焚烧": [
    "fen2 shao1"
  ],
  "呗": [
    "bai4",
    "bei5"
  ],
  "患者": [
    "huan4 zhe3"
  ],
  "路程": [
    "lu4 cheng2"
  ],
  "铖": [
    "cheng2"
  ],
  "同意": [
    "tong2 yi4"
  ],
  "变动": [
    "bian4 dong4"
  ],
  "壑": [
    "he4"
  ],
  "鳡": [
    "gan3"
  ],
  "书面": [
    "shu1 mian4"
  ],
  "达到": [
    "da2 dao4"
  ],
  "帮忙": [
    "bang1 mang2"
  ],
  "翻来覆去": [
    "fan1 lai2 fu4 qu4"
  ],
  "高度": [
    "gao1 du4"
  ],
  "黏": [
    "nian2"
  ],
  "蟊": [
    "mao2"
  ],
  "自助": [
    "zi4 zhu4"
  ],
  "镯": [
    "zhuo2"
  ],
  "据": [
    "ju4",
    "ju1",
    "ju4",
    "ju4"
  ],
  "伐": [
    "fa2"
  ],
  "销量": [
    "xiao1 liang4"
  ],
  "弁": [
    "bian4"
  ],
  "肝": [
    "gan1"
  ],
  "缦": [
    "man4"
  ],
  "椠": [
    "qian4"
  ],
  "吒": [
    "zha1",
    "zha4"
  ],
  "艅": [
    "yu2"
  ],
  "赂": [
    "lu4"
  ],
  "重复": [
    "chong2 fu4",
    "chong2 fu4"
  ],
  "不同寻常": [
    "bu4 tong2 xun2 chang2"
  ],
  "早已": [
    "zao3 yi3"
  ],
  "鲤": [
    "li3"
  ],
  "点缀": [
    "dian3 zhui4"
  ],
  "时期": [
    "shi2 qi1"
  ],
  "杜": [
    "Du4",
    "du4"
  ],
  "寡妇": [
    "gua3 fu5"
  ],
  "虚幻": [
    "xu1 huan4"
  ],
  "斜": [
    "xie2"
  ],
  "惧": [
    "ju4"
  ],
  "璘": [
    "lin2"
  ],
  "几率": [
    "ji1 lu:4"
  ],
  "充当": [
    "chong1 dang1"
  ],
  "簟": [
    "dian4"
  ],
  "屐": [
    "ji1"
  ],
  "檫": [
    "cha2"
  ],
  "不以为然": [
    "bu4 yi3 wei2 ran2"
  ],
  "破": [
    "po4"
  ],
  "蹙": [
    "cu4"
  ],
  "莫": [
    "Mo4",
    "mo4"
  ],
  "困": [
    "kun4",
    "kun4"
  ],
  "百分比": [
    "bai3 fen1 bi3"
  ],
  "不好意思": [
    "bu4 hao3 yi4 si5"
  ],
  "遗憾": [
    "yi2 han4"
  ],
  "水手": [
    "shui3 shou3"
  ],
  "和尚": [
    "he2 shang5"
  ],
  "銮": [
    "luan2"
  ],
  "部队": [
    "bu4 dui4"
  ],
  "索赔": [
    "suo3 pei2"
  ],
  "互补": [
    "hu4 bu3"
  ],
  "起劲": [
    "qi3 jin4"
  ],
  "闻": [
    "Wen2",
    "wen2"
  ],
  "自理": [
    "zi4 li3"
  ],
  "表面": [
    "biao3 mian4"
  ],
  "遗址": [
    "yi2 zhi3"
  ],
  "绤": [
    "xi4"
  ],
  "貅": [
    "xiu1"
  ],
  "变质": [
    "bian4 zhi4"
  ],
  "盔": [
    "kui1"
  ],
  "餐厅": [
    "can1 ting1"
  ],
  "补充": [
    "bu3 chong1"
  ],
  "髅": [
    "lou2"
  ],
  "情感": [
    "qing2 gan3"
  ],
  "尪": [
    "wang1"
  ],
  "锳": [
    "ying1"
  ],
  "巨型": [
    "ju4 xing2"
  ],
  "忠心": [
    "zhong1 xin1"
  ],
  "议论": [
    "yi4 lun4"
  ],
  "充": [
    "chong1"
  ],
  "无不": [
    "wu2 bu4"
  ],
  "说老实话": [
    "shuo1 lao3 shi5 hua4"
  ],
  "零": [
    "ling2"
  ],
  "袜子": [
    "wa4 zi5"
  ],
  "彩霞": [
    "cai3 xia2"
  ],
  "教堂": [
    "jiao4 tang2"
  ],
  "温馨": [
    "wen1 xin1"
  ],
  "戌": [
    "qu5",
    "xu1"
  ],
  "看护": [
    "kan1 hu4"
  ],
  "凳子": [
    "deng4 zi5"
  ],
  "激发": [
    "ji1 fa1"
  ],
  "不但": [
    "bu4 dan4"
  ],
  "哮": [
    "xiao4"
  ],
  "既": [
    "ji4"
  ],
  "屈": [
    "Qu1",
    "qu1"
  ],
  "挽回": [
    "wan3 hui2"
  ],
  "锨": [
    "xian1"
  ],
  "戭": [
    "yan3"
  ],
  "烹调": [
    "peng1 tiao2"
  ],
  "洣": [
    "Mi3"
  ],
  "陆地": [
    "lu4 di4"
  ],
  "鿎": [
    null
  ],
  "廙": [
    "yi4"
  ],
  "便饭": [
    "bian4 fan4"
  ],
  "瑱": [
    "tian4",
    "zhen4"
  ],
  "窭": [
    "ju4"
  ],
  "理由": [
    "li3 you2"
  ],
  "潟": [
    "xi4"
  ],
  "奢望": [
    "she1 wang4"
  ],
  "真相": [
    "zhen1 xiang4"
  ],
  "效仿": [
    "xiao4 fang3"
  ],
  "没完没了": [
    "mei2 wan2 mei2 liao3"
  ],
  "砑": [
    "ya4"
  ],
  "峭": [
    "qiao4",
    "qiao4"
  ],
  "早餐": [
    "zao3 can1"
  ],
  "慎重": [
    "shen4 zhong4"
  ],
  "饪": [
    "ren4",
    "ren4"
  ],
  "一旦": [
    "yi1 dan4"
  ],
  "嬷": [
    "mo2"
  ],
  "迥": [
    "jiong3",
    "jiong3",
    "jiong3"
  ],
  "放水": [
    "fang4 shui3"
  ],
  "同时": [
    "tong2 shi2"
  ],
  "便": [
    "bian4",
    "pian2"
  ],
  "便利": [
    "bian4 li4"
  ],
  "有朝一日": [
    "you3 zhao1 yi1 ri4"
  ],
  "气管": [
    "qi4 guan3"
  ],
  "外资": [
    "wai4 zi1"
  ],
  "荄": [
    "gai1"
  ],
  "急转弯": [
    "ji2 zhuan3 wan1"
  ],
  "上帝": [
    "Shang4 di4"
  ],
  "答复": [
    "da2 fu4",
    "da2 fu4"
  ],
  "鹧": [
    "zhe4"
  ],
  "足够": [
    "zu2 gou4"
  ],
  "出行": [
    "chu1 xing2"
  ],
  "加热": [
    "jia1 re4"
  ],
  "腠": [
    "cou4"
  ],
  "护照": [
    "hu4 zhao4"
  ],
  "菽": [
    "shu1"
  ],
  "推进": [
    "tui1 jin4"
  ],
  "瘪": [
    "bie3",
    "bie3"
  ],
  "遇上": [
    "yu4 shang4"
  ],
  "运动会": [
    "yun4 dong4 hui4"
  ],
  "牵制": [
    "qian1 zhi4"
  ],
  "提问": [
    "ti2 wen4"
  ],
  "贩": [
    "fan4"
  ],
  "嫘": [
    "Lei2"
  ],
  "根治": [
    "gen1 zhi4"
  ],
  "虫": [
    "chong2",
    "chong2"
  ],
  "口吃": [
    "kou3 chi1"
  ],
  "礓": [
    "jiang1"
  ],
  "攻击": [
    "gong1 ji1"
  ],
  "嗤": [
    "chi1"
  ],
  "抄袭": [
    "chao1 xi2"
  ],
  "嫠": [
    "li2"
  ],
  "业绩": [
    "ye4 ji4"
  ],
  "嫕": [
    "yi4"
  ],
  "发财": [
    "fa1 cai2"
  ],
  "鲞": [
    "xiang3"
  ],
  "圻": [
    "qi2"
  ],
  "专人": [
    "zhuan1 ren2"
  ],
  "徉": [
    "yang2"
  ],
  "作者": [
    "zuo4 zhe3"
  ],
  "潖": [
    null
  ],
  "屋顶": [
    "wu1 ding3"
  ],
  "渥": [
    "wo4"
  ],
  "㭎": [
    null
  ],
  "抗": [
    "kang4"
  ],
  "怀孕": [
    "huai2 yun4"
  ],
  "澪": [
    null
  ],
  "牻": [
    "mang2"
  ],
  "獒": [
    "ao2"
  ],
  "获得": [
    "huo4 de2"
  ],
  "对准": [
    "dui4 zhun3"
  ],
  "乘客": [
    "cheng2 ke4"
  ],
  "乙": [
    "yi3",
    "zhe2"
  ],
  "共识": [
    "gong4 shi2"
  ],
  "鑫": [
    "xin1"
  ],
  "餐桌": [
    "can1 zhuo1"
  ],
  "带路": [
    "dai4 lu4"
  ],
  "胜任": [
    "sheng4 ren4"
  ],
  "集体": [
    "ji2 ti3"
  ],
  "女子": [
    "nu:3 zi3"
  ],
  "坞": [
    "wu4",
    "wu4"
  ],
  "房子": [
    "fang2 zi5"
  ],
  "拂": [
    "bi4",
    "fu2"
  ],
  "圬": [
    "wu1"
  ],
  "肩膀": [
    "jian1 bang3"
  ],
  "瑛": [
    "ying1"
  ],
  "东西": [
    "dong1 xi1",
    "dong1 xi5"
  ],
  "高峰": [
    "gao1 feng1"
  ],
  "竹竿": [
    "zhu2 gan1"
  ],
  "久": [
    "jiu3"
  ],
  "堪称": [
    "kan1 cheng1"
  ],
  "盛会": [
    "sheng4 hui4"
  ],
  "欧": [
    "Ou1",
    "ou1"
  ],
  "邳": [
    "Pi1",
    "pi1"
  ],
  "事实上": [
    "shi4 shi2 shang4"
  ],
  "资": [
    "zi1"
  ],
  "讵": [
    "ju4"
  ],
  "演播室": [
    "yan3 bo1 shi4"
  ],
  "筝": [
    "zheng1"
  ],
  "旮": [
    "ga1"
  ],
  "负担": [
    "fu4 dan1"
  ],
  "拼搏": [
    "pin1 bo2"
  ],
  "红扑扑": [
    "hong2 pu1 pu1"
  ],
  "供暖": [
    "gong1 nuan3"
  ],
  "凝聚": [
    "ning2 ju4"
  ],
  "评价": [
    "ping2 jia4"
  ],
  "焜": [
    "kun1"
  ],
  "骁": [
    "xiao1"
  ],
  "证件": [
    "zheng4 jian4"
  ],
  "徵": [
    "zhi3"
  ],
  "沄": [
    "yun2"
  ],
  "廉": [
    "lian2",
    "lian2",
    "Lian2",
    "lian2"
  ],
  "助理": [
    "zhu4 li3"
  ],
  "千变万化": [
    "qian1 bian4 wan4 hua4"
  ],
  "伤痕": [
    "shang1 hen2"
  ],
  "或是": [
    "huo4 shi4"
  ],
  "岩石": [
    "yan2 shi2"
  ],
  "铴": [
    null
  ],
  "考场": [
    "kao3 chang3"
  ],
  "全家": [
    "Quan2 jia1",
    "quan2 jia1"
  ],
  "痿": [
    "wei3"
  ],
  "心脏": [
    "xin1 zang4"
  ],
  "发热": [
    "fa1 re4"
  ],
  "讨好": [
    "tao3 hao3"
  ],
  "佺": [
    "quan2"
  ],
  "浮现": [
    "fu2 xian4"
  ],
  "坐": [
    "Zuo4",
    "zuo4"
  ],
  "热心": [
    "re4 xin1"
  ],
  "叠": [
    "die2",
    "die2",
    "die2"
  ],
  "蕨": [
    "jue2"
  ],
  "来往": [
    "lai2 wang3"
  ],
  "根源": [
    "gen1 yuan2"
  ],
  "夙": [
    "su4"
  ],
  "橐": [
    "tuo2"
  ],
  "曼": [
    "man4"
  ],
  "缉": [
    "ji1",
    "qi1"
  ],
  "雕": [
    "diao1",
    "diao1",
    "diao1"
  ],
  "蔻": [
    "kou4"
  ],
  "人格": [
    "ren2 ge2"
  ],
  "劣": [
    "lie4"
  ],
  "灸": [
    "jiu3"
  ],
  "茳": [
    "jiang1"
  ],
  "制造": [
    "zhi4 zao4"
  ],
  "狲": [
    "sun1"
  ],
  "说谎": [
    "shuo1 huang3"
  ],
  "阵营": [
    "zhen4 ying2"
  ],
  "汽油": [
    "qi4 you2"
  ],
  "工程师": [
    "gong1 cheng2 shi1"
  ],
  "弇": [
    "yan3"
  ],
  "自豪": [
    "zi4 hao2"
  ],
  "怪不得": [
    "guai4 bu5 de5"
  ],
  "国际": [
    "guo2 ji4"
  ],
  "长征": [
    "Chang2 zheng1",
    "chang2 zheng1"
  ],
  "以为": [
    "yi3 wei2"
  ],
  "开启": [
    "kai1 qi3"
  ],
  "悉": [
    "xi1"
  ],
  "满足": [
    "man3 zu2"
  ],
  "锦旗": [
    "jin3 qi2"
  ],
  "推测": [
    "tui1 ce4"
  ],
  "姚": [
    "Yao2",
    "yao2"
  ],
  "响声": [
    "xiang3 sheng1"
  ],
  "调动": [
    "diao4 dong4"
  ],
  "硗": [
    "qiao1"
  ],
  "上面": [
    "shang4 mian4"
  ],
  "熄火": [
    "xi1 huo3"
  ],
  "停电": [
    "ting2 dian4"
  ],
  "孙女": [
    "sun1 nu:3"
  ],
  "趁": [
    "chen4",
    "chen4"
  ],
  "阴天": [
    "yin1 tian1"
  ],
  "抗议": [
    "kang4 yi4"
  ],
  "稳固": [
    "wen3 gu4"
  ],
  "腧": [
    "shu4"
  ],
  "安装": [
    "an1 zhuang1"
  ],
  "共享": [
    "gong4 xiang3"
  ],
  "效": [
    "xiao4",
    "xiao4",
    "xiao4",
    "xiao4"
  ],
  "大纲": [
    "da4 gang1"
  ],
  "潜力": [
    "qian2 li4"
  ],
  "魉": [
    "liang3"
  ],
  "损伤": [
    "sun3 shang1"
  ],
  "忆": [
    "yi4"
  ],
  "蚍": [
    "pi2"
  ],
  "可怜": [
    "ke3 lian2"
  ],
  "博物馆": [
    "bo2 wu4 guan3"
  ],
  "下跌": [
    "xia4 die1"
  ],
  "西瓜": [
    "xi1 gua1"
  ],
  "爿": [
    "pan2"
  ],
  "录像": [
    "lu4 xiang4"
  ],
  "钏": [
    "Chuan4",
    "chuan4"
  ],
  "较量": [
    "jiao4 liang4"
  ],
  "豆": [
    "dou4",
    "dou4"
  ],
  "隔阂": [
    "ge2 he2"
  ],
  "适": [
    "kuo4",
    "Shi4",
    "shi4"
  ],
  "骱": [
    "xie4"
  ],
  "猩猩": [
    "xing1 xing5"
  ],
  "喇叭": [
    "la3 ba5"
  ],
  "阶级": [
    "jie1 ji2"
  ],
  "蜱": [
    "pi2"
  ],
  "韂": [
    "chan4"
  ],
  "回升": [
    "hui2 sheng1"
  ],
  "归": [
    "Gui1",
    "gui1"
  ],
  "分离": [
    "fen1 li2"
  ],
  "发扬": [
    "fa1 yang2"
  ],
  "并行": [
    "bing4 xing2"
  ],
  "谢": [
    "Xie4",
    "xie4"
  ],
  "诂": [
    "gu3"
  ],
  "鸦": [
    "ya1",
    "ya1"
  ],
  "缺乏": [
    "que1 fa2"
  ],
  "泄密": [
    "xie4 mi4"
  ],
  "强烈": [
    "qiang2 lie4"
  ],
  "高级": [
    "gao1 ji2"
  ],
  "基地": [
    "Ji1 di4",
    "ji1 di4"
  ],
  "吱": [
    "zhi1",
    "zi1"
  ],
  "不料": [
    "bu4 liao4"
  ],
  "航空": [
    "hang2 kong1"
  ],
  "兖": [
    "yan3"
  ],
  "埏": [
    "shan1",
    "yan2"
  ],
  "高跟鞋": [
    "gao1 gen1 xie2"
  ],
  "咦": [
    "yi2"
  ],
  "挹": [
    "yi4"
  ],
  "再生": [
    "zai4 sheng1"
  ],
  "进出": [
    "jin4 chu1"
  ],
  "纪实": [
    "ji4 shi2"
  ],
  "哼": [
    "heng1"
  ],
  "上述": [
    "shang4 shu4"
  ],
  "充电": [
    "chong1 dian4"
  ],
  "阑": [
    "lan2"
  ],
  "邴": [
    "Bing3",
    "bing3"
  ],
  "谀": [
    "yu2"
  ],
  "一干二净": [
    "yi1 gan1 er4 jing4"
  ],
  "鹑": [
    "chun2"
  ],
  "位子": [
    "wei4 zi5"
  ],
  "优点": [
    "you1 dian3"
  ],
  "蜥": [
    "xi1"
  ],
  "擀": [
    "gan3"
  ],
  "收获": [
    "shou1 huo4",
    "shou1 huo4"
  ],
  "不景气": [
    "bu4 jing3 qi4"
  ],
  "忘记": [
    "wang4 ji4"
  ],
  "浊": [
    "zhuo2"
  ],
  "鸵": [
    "tuo2"
  ],
  "懂事": [
    "dong3 shi4"
  ],
  "打扫": [
    "da3 sao3"
  ],
  "发电": [
    "fa1 dian4"
  ],
  "填充": [
    "tian2 chong1"
  ],
  "只得": [
    "zhi3 de2"
  ],
  "饹": [
    "ge1",
    "le5"
  ],
  "邱": [
    "Qiu1",
    "qiu1"
  ],
  "文娱": [
    "wen2 yu2"
  ],
  "棁": [
    "zhuo1"
  ],
  "䅟": [
    "shan1"
  ],
  "不要紧": [
    "bu4 yao4 jin3"
  ],
  "可信": [
    "ke3 xin4"
  ],
  "简体字": [
    "jian3 ti3 zi4"
  ],
  "总裁": [
    "zong3 cai2"
  ],
  "化验": [
    "hua4 yan4"
  ],
  "换位": [
    "huan4 wei4"
  ],
  "畸形": [
    "ji1 xing2"
  ],
  "涕": [
    "ti4"
  ],
  "官员": [
    "guan1 yuan2"
  ],
  "危险": [
    "wei1 xian3"
  ],
  "砼": [
    "tong2"
  ],
  "不时": [
    "bu4 shi2"
  ],
  "吾": [
    "Wu2",
    "wu2"
  ],
  "过关": [
    "guo4 guan1"
  ],
  "胸部": [
    "xiong1 bu4"
  ],
  "赏": [
    "shang3"
  ],
  "筷子": [
    "kuai4 zi5"
  ],
  "螳": [
    "tang2"
  ],
  "贫困": [
    "pin2 kun4"
  ],
  "局部": [
    "ju2 bu4"
  ],
  "碎": [
    "sui4"
  ],
  "继父": [
    "ji4 fu4"
  ],
  "磨合": [
    "mo2 he2"
  ],
  "外企": [
    "wai4 qi3"
  ],
  "奥": [
    "Ao4",
    "ao4"
  ],
  "埗": [
    "bu4"
  ],
  "熊": [
    "Xiong2",
    "xiong2"
  ],
  "蚜": [
    "ya2"
  ],
  "绔": [
    "ku4"
  ],
  "发表": [
    "fa1 biao3"
  ],
  "可见": [
    "ke3 jian4"
  ],
  "六": [
    "liu4"
  ],
  "觜": [
    "zi1",
    "zui3"
  ],
  "轫": [
    "ren4"
  ],
  "圃": [
    "pu3"
  ],
  "忍耐": [
    "ren3 nai4"
  ],
  "陎": [
    "Shu1"
  ],
  "秋天": [
    "qiu1 tian1"
  ],
  "经理": [
    "jing1 li3"
  ],
  "杀害": [
    "sha1 hai4"
  ],
  "侄": [
    "zhi2",
    "zhi2",
    "zhi2"
  ],
  "吉他": [
    "ji2 ta1"
  ],
  "生机": [
    "sheng1 ji1"
  ],
  "钗": [
    "chai1"
  ],
  "熙熙攘攘": [
    "xi1 xi1 rang3 rang3"
  ],
  "冰棍儿": [
    "bing1 gun4 r5"
  ],
  "浸": [
    "jin4"
  ],
  "髦": [
    "mao2"
  ],
  "舴": [
    "ze2"
  ],
  "绩": [
    "ji4"
  ],
  "蔌": [
    "Su4",
    "su4"
  ],
  "论文": [
    "lun4 wen2"
  ],
  "就职": [
    "jiu4 zhi2"
  ],
  "苗条": [
    "miao2 tiao5"
  ],
  "打": [
    "da2",
    "da3"
  ],
  "皦": [
    "Jiao3",
    "jiao3"
  ],
  "肥": [
    "fei2"
  ],
  "捞": [
    "lao1"
  ],
  "弟子": [
    "di4 zi3"
  ],
  "钎": [
    "qian1"
  ],
  "摛": [
    "chi1"
  ],
  "件": [
    "jian4"
  ],
  "磷": [
    "lin2",
    "lin2",
    "lin2"
  ],
  "夫妇": [
    "fu1 fu4"
  ],
  "候": [
    "hou4"
  ],
  "势头": [
    "shi4 tou2"
  ],
  "赶赴": [
    "gan3 fu4"
  ],
  "呀": [
    "ya5"
  ],
  "贵": [
    "gui4"
  ],
  "湟": [
    "huang2"
  ],
  "硫": [
    "liu2"
  ],
  "老公": [
    "lao3 gong1",
    "lao3 gong5"
  ],
  "博士": [
    "bo2 shi4"
  ],
  "砍": [
    "kan3"
  ],
  "推动": [
    "tui1 dong4"
  ],
  "出租车": [
    "chu1 zu1 che1"
  ],
  "傧": [
    "bin1"
  ],
  "砣": [
    "tuo2"
  ],
  "牮": [
    "jian4"
  ],
  "品尝": [
    "pin3 chang2"
  ],
  "捐献": [
    "juan1 xian4"
  ],
  "缲": [
    "qiao1",
    "sao1"
  ],
  "录": [
    "lu4",
    "Lu4",
    "lu4"
  ],
  "发脾气": [
    "fa1 pi2 qi4"
  ],
  "烂": [
    "lan4"
  ],
  "突发": [
    "tu1 fa1"
  ],
  "骡": [
    "luo2",
    "luo2"
  ],
  "插图": [
    "cha1 tu2"
  ],
  "把关": [
    "ba3 guan1"
  ],
  "嵯": [
    "cuo2"
  ],
  "叫好": [
    "jiao4 hao3"
  ],
  "爬山": [
    "pa2 shan1"
  ],
  "嗅觉": [
    "xiu4 jue2"
  ],
  "抓紧": [
    "zhua1 jin3"
  ],
  "叇": [
    "dai4"
  ],
  "知道": [
    "zhi1 dao4"
  ],
  "摇晃": [
    "yao2 huang4"
  ],
  "嗔": [
    "chen1"
  ],
  "全心全意": [
    "quan2 xin1 quan2 yi4"
  ],
  "源头": [
    "yuan2 tou2"
  ],
  "黄金": [
    "huang2 jin1"
  ],
  "刚好": [
    "gang1 hao3"
  ],
  "男人": [
    "nan2 ren2"
  ],
  "西边": [
    "xi1 bian1"
  ],
  "外婆": [
    "wai4 po2"
  ],
  "解开": [
    "jie3 kai1"
  ],
  "富翁": [
    "fu4 weng1"
  ],
  "主动": [
    "zhu3 dong4"
  ],
  "猿": [
    "yuan2",
    "yuan2",
    "yuan2"
  ],
  "举": [
    "ju3",
    "ju3"
  ],
  "颐": [
    "yi2"
  ],
  "头疼": [
    "tou2 teng2"
  ],
  "与众不同": [
    "yu3 zhong4 bu4 tong2"
  ],
  "鲷": [
    "diao1"
  ],
  "复": [
    "fu4",
    "fu4",
    "fu4"
  ],
  "温": [
    "Wen1",
    "wen1"
  ],
  "低谷": [
    "di1 gu3"
  ],
  "国徽": [
    "Guo2 hui1",
    "guo2 hui1"
  ],
  "苦心": [
    "ku3 xin1"
  ],
  "要是": [
    "yao4 shi5"
  ],
  "整洁": [
    "zheng3 jie2"
  ],
  "身子": [
    "shen1 zi5"
  ],
  "驹": [
    "ju1"
  ],
  "轸": [
    "zhen3"
  ],
  "拈": [
    "nian1"
  ],
  "折合": [
    "zhe2 he2"
  ],
  "乾": [
    "qian2",
    "Qian2",
    "qian2",
    "qian2"
  ],
  "瑂": [
    "mei2"
  ],
  "揭露": [
    "jie1 lu4"
  ],
  "感叹": [
    "gan3 tan4"
  ],
  "浠": [
    "Xi1"
  ],
  "严禁": [
    "yan2 jin4"
  ],
  "硒": [
    "xi1"
  ],
  "戏": [
    "xi4",
    "xi4"
  ],
  "浯": [
    "Wu2"
  ],
  "帡": [
    "ping2"
  ],
  "无疑": [
    "wu2 yi2"
  ],
  "棋": [
    "qi2",
    "qi2",
    "qi2"
  ],
  "氨": [
    "an1"
  ],
  "经受": [
    "jing1 shou4"
  ],
  "惘": [
    "wang3"
  ],
  "舱": [
    "cang1"
  ],
  "出土": [
    "chu1 tu3"
  ],
  "汲": [
    "Ji2",
    "ji2"
  ],
  "入学": [
    "ru4 xue2"
  ],
  "自如": [
    "zi4 ru2"
  ],
  "一心一意": [
    "yi1 xin1 yi1 yi4"
  ],
  "哳": [
    "zha1"
  ],
  "拉动": [
    "la1 dong4"
  ],
  "贵族": [
    "gui4 zu2"
  ],
  "突击": [
    "tu1 ji1"
  ],
  "掴": [
    "guai1"
  ],
  "毫米": [
    "hao2 mi3"
  ],
  "整合": [
    "zheng3 he2"
  ],
  "垕": [
    "hou4"
  ],
  "以往": [
    "yi3 wang3"
  ],
  "赙": [
    "fu4"
  ],
  "隐私": [
    "yin3 si1"
  ],
  "筇": [
    "qiong2"
  ],
  "舜": [
    "Shun4"
  ],
  "扇子": [
    "shan4 zi5"
  ],
  "此时": [
    "ci3 shi2"
  ],
  "鹀": [
    "wu2"
  ],
  "呸": [
    "pei1"
  ],
  "看管": [
    "kan1 guan3"
  ],
  "冫": [
    "bing1"
  ],
  "不难": [
    null
  ],
  "风气": [
    "feng1 qi4"
  ],
  "汁": [
    "zhi1"
  ],
  "雨水": [
    "Yu3 shui3",
    "yu3 shui3"
  ],
  "入境": [
    "ru4 jing4"
  ],
  "咍": [
    "hai1"
  ],
  "财经": [
    "cai2 jing1"
  ],
  "芸": [
    "yi4",
    "yun2",
    "yun2"
  ],
  "无聊": [
    "wu2 liao2"
  ],
  "啐": [
    "cui4"
  ],
  "操劳": [
    "cao1 lao2"
  ],
  "虹": [
    "hong2"
  ],
  "筶": [
    null
  ],
  "璀": [
    "cui3"
  ],
  "蜎": [
    "Yuan1",
    "yuan1"
  ],
  "真": [
    "zhen1"
  ],
  "乘车": [
    "cheng2 che1"
  ],
  "枚": [
    "Mei2",
    "mei2"
  ],
  "常见": [
    "chang2 jian4"
  ],
  "啷": [
    "lang1"
  ],
  "鸣": [
    "ming2"
  ],
  "绿色": [
    "lu:4 se4"
  ],
  "取决于": [
    "qu3 jue2 yu2"
  ],
  "棉": [
    "mian2"
  ],
  "易拉罐": [
    "yi4 la1 guan4"
  ],
  "通车": [
    "tong1 che1"
  ],
  "瘳": [
    "chou1"
  ],
  "造价": [
    "zao4 jia4"
  ],
  "券": [
    "quan4",
    "quan4"
  ],
  "晡": [
    "bu1"
  ],
  "指令": [
    "zhi3 ling4"
  ],
  "从不": [
    "cong2 bu4"
  ],
  "昨天": [
    "zuo2 tian1"
  ],
  "诧异": [
    "cha4 yi4"
  ],
  "原始": [
    "yuan2 shi3"
  ],
  "灼热": [
    "zhuo2 re4"
  ],
  "雉": [
    "zhi4"
  ],
  "捶": [
    "chui2",
    "chui2"
  ],
  "嗬": [
    "he1"
  ],
  "臻": [
    "zhen1"
  ],
  "克服": [
    "ke4 fu2"
  ],
  "优化": [
    "you1 hua4"
  ],
  "不在乎": [
    "bu4 zai4 hu5"
  ],
  "派": [
    "pa1",
    "pai4"
  ],
  "偏向": [
    "pian1 xiang4"
  ],
  "埂": [
    "geng3"
  ],
  "但": [
    "dan4"
  ],
  "铌": [
    "ni2"
  ],
  "斗争": [
    "dou4 zheng1"
  ],
  "观光": [
    "guan1 guang1"
  ],
  "依依不舍": [
    "yi1 yi1 bu4 she3"
  ],
  "杵": [
    "chu3"
  ],
  "袤": [
    "mao4"
  ],
  "䎃": [
    "ran3"
  ],
  "鞧": [
    "qiu1"
  ],
  "卑": [
    "bei1"
  ],
  "伪装": [
    "wei3 zhuang1"
  ],
  "简介": [
    "jian3 jie4"
  ],
  "孝": [
    "xiao4"
  ],
  "年度": [
    "nian2 du4"
  ],
  "诉": [
    "su4",
    "su4"
  ],
  "交界": [
    "jiao1 jie4"
  ],
  "起床": [
    "qi3 chuang2"
  ],
  "严肃": [
    "yan2 su4"
  ],
  "预言": [
    "yu4 yan2"
  ],
  "组长": [
    "zu3 zhang3"
  ],
  "涴": [
    "wo4"
  ],
  "滚": [
    "gun3"
  ],
  "隽": [
    "Juan4",
    "juan4",
    "jun4"
  ],
  "名誉": [
    "ming2 yu4"
  ],
  "钟头": [
    "zhong1 tou2"
  ],
  "错位": [
    "cuo4 wei4"
  ],
  "芍": [
    "Que4",
    "shao2"
  ],
  "交涉": [
    "jiao1 she4"
  ],
  "蟀": [
    "shuai4"
  ],
  "无意": [
    "wu2 yi4"
  ],
  "痛": [
    "tong4"
  ],
  "梣": [
    "chen2"
  ],
  "眵": [
    "chi1"
  ],
  "叱": [
    "chi4"
  ],
  "勾": [
    "Gou1",
    "gou1",
    "gou4"
  ],
  "迸": [
    "beng4"
  ],
  "不满": [
    "bu4 man3"
  ],
  "粼": [
    "lin2"
  ],
  "焐": [
    "wu4"
  ],
  "奇花异草": [
    "qi2 hua1 yi4 cao3"
  ],
  "伤势": [
    "shang1 shi4"
  ],
  "辞去": [
    "ci2 qu4"
  ],
  "醑": [
    "xu3"
  ],
  "啤酒": [
    "pi2 jiu3"
  ],
  "姐姐": [
    "jie3 jie5"
  ],
  "董事会": [
    "dong3 shi4 hui4"
  ],
  "捉迷藏": [
    "zhuo1 mi2 cang2"
  ],
  "圾": [
    "ji1"
  ],
  "镉": [
    "ge2"
  ],
  "如一": [
    "ru2 yi1"
  ],
  "机关": [
    "ji1 guan1"
  ],
  "一味": [
    "yi1 wei4"
  ],
  "郄": [
    "Qie4",
    "Xi4"
  ],
  "稳重": [
    "wen3 zhong4"
  ],
  "纪念馆": [
    "ji4 nian4 guan3"
  ],
  "僻静": [
    "pi4 jing4"
  ],
  "透": [
    "tou4"
  ],
  "胈": [
    null
  ],
  "表决": [
    "biao3 jue2"
  ],
  "天真": [
    "tian1 zhen1"
  ],
  "庆": [
    "qing4"
  ],
  "拉开": [
    "la1 kai1"
  ],
  "争气": [
    "zheng1 qi4"
  ],
  "再次": [
    "zai4 ci4"
  ],
  "好奇": [
    "hao4 qi2"
  ],
  "瑳": [
    "cuo1"
  ],
  "开幕式": [
    "kai1 mu4 shi4"
  ],
  "改名": [
    "gai3 ming2"
  ],
  "老是": [
    "lao3 shi5"
  ],
  "站台": [
    "zhan4 tai2"
  ],
  "铧": [
    "hua2"
  ],
  "鹇": [
    "xian2",
    "xian2"
  ],
  "相差": [
    "xiang1 cha4"
  ],
  "讨价还价": [
    "tao3 jia4 huan2 jia4"
  ],
  "出示": [
    "chu1 shi4"
  ],
  "心意": [
    "xin1 yi4"
  ],
  "闼": [
    "ta4"
  ],
  "蹋": [
    "ta4"
  ],
  "羿": [
    "Yi4"
  ],
  "斐": [
    "fei3"
  ],
  "拜见": [
    "bai4 jian4"
  ],
  "公司": [
    "gong1 si1"
  ],
  "息": [
    "xi1"
  ],
  "泖": [
    "mao3"
  ],
  "窑": [
    "yao2",
    "yao2"
  ],
  "悔": [
    "hui3"
  ],
  "提": [
    "di1",
    "ti2"
  ],
  "纪念日": [
    "ji4 nian4 ri4"
  ],
  "泄漏": [
    "xie4 lou4"
  ],
  "烷": [
    "wan2"
  ],
  "土": [
    "Tu3",
    "tu3"
  ],
  "大楼": [
    "da4 lou2"
  ],
  "之前": [
    "zhi1 qian2"
  ],
  "跂": [
    "ji1",
    "qi2",
    "qi3",
    "qi4",
    "zhi1"
  ],
  "道教": [
    "Dao4 jiao4"
  ],
  "时代": [
    "Shi2 dai4",
    "shi2 dai4"
  ],
  "手指": [
    "shou3 zhi3"
  ],
  "纪念": [
    "ji4 nian4"
  ],
  "造纸术": [
    "zao4 zhi3 shu4"
  ],
  "找": [
    "zhao3"
  ],
  "歙": [
    "she4"
  ],
  "甪": [
    "Lu4"
  ],
  "与": [
    "yu2",
    "yu3",
    "yu4"
  ],
  "隈": [
    "wei1"
  ],
  "盷": [
    null
  ],
  "向往": [
    "xiang4 wang3"
  ],
  "锝": [
    "de2"
  ],
  "鳍": [
    "qi2"
  ],
  "允许": [
    "yun3 xu3"
  ],
  "标准": [
    "biao1 zhun3"
  ],
  "恋爱": [
    "lian4 ai4"
  ],
  "榛": [
    "zhen1"
  ],
  "牢记": [
    "lao2 ji4"
  ],
  "包容": [
    "bao1 rong2"
  ],
  "当即": [
    "dang1 ji2"
  ],
  "松树": [
    "song1 shu4"
  ],
  "开阔": [
    "kai1 kuo4"
  ],
  "绖": [
    "die2"
  ],
  "赞成": [
    "zan4 cheng2"
  ],
  "筱": [
    "xiao3"
  ],
  "戒备": [
    "jie4 bei4"
  ],
  "倒卖": [
    "dao3 mai4"
  ],
  "佬": [
    "lao3"
  ],
  "公示": [
    "gong1 shi4"
  ],
  "氦": [
    "hai4"
  ],
  "起程": [
    "qi3 cheng2"
  ],
  "旱灾": [
    "han4 zai1"
  ],
  "桨": [
    "jiang3"
  ],
  "蓝色": [
    "lan2 se4"
  ],
  "沮丧": [
    "ju3 sang4"
  ],
  "骐": [
    "qi2"
  ],
  "扪": [
    "men2"
  ],
  "傀": [
    "gui1",
    "kui3"
  ],
  "庞大": [
    "pang2 da4"
  ],
  "齑": [
    "ji1"
  ],
  "为难": [
    "wei2 nan2"
  ],
  "中立": [
    "zhong1 li4"
  ],
  "污水": [
    "wu1 shui3"
  ],
  "好感": [
    "hao3 gan3"
  ],
  "垯": [
    "da5"
  ],
  "养成": [
    "yang3 cheng2"
  ],
  "抨": [
    "peng1"
  ],
  "戤": [
    "gai4"
  ],
  "侮": [
    "wu3"
  ],
  "祺": [
    "qi2"
  ],
  "争分夺秒": [
    "zheng1 fen1 duo2 miao3"
  ],
  "账户": [
    "zhang4 hu4"
  ],
  "耜": [
    "si4"
  ],
  "芙": [
    "fu2"
  ],
  "境界": [
    "jing4 jie4"
  ],
  "瞬间": [
    "shun4 jian1"
  ],
  "馐": [
    "xiu1"
  ],
  "鄱": [
    "po2"
  ],
  "小吃": [
    "xiao3 chi1"
  ],
  "聚集": [
    "ju4 ji2"
  ],
  "跳动": [
    "tiao4 dong4"
  ],
  "揉": [
    "rou2"
  ],
  "眼睛": [
    "yan3 jing5"
  ],
  "世袭": [
    "shi4 xi2"
  ],
  "语言": [
    "yu3 yan2"
  ],
  "症结": [
    "zheng1 jie2"
  ],
  "机器人": [
    "ji1 qi4 ren2"
  ],
  "伴": [
    "ban4"
  ],
  "悖": [
    "bei4",
    "bei4"
  ],
  "嗣": [
    "si4"
  ],
  "品种": [
    "pin3 zhong3"
  ],
  "中介": [
    "zhong1 jie4"
  ],
  "阊": [
    "chang1"
  ],
  "后来": [
    "hou4 lai2"
  ],
  "公职": [
    "gong1 zhi2"
  ],
  "烠": [
    null
  ],
  "先": [
    "xian1"
  ],
  "漉": [
    "lu4"
  ],
  "仿": [
    "fang3",
    "fang3",
    "fang3"
  ],
  "颍": [
    "Ying3",
    "ying3"
  ],
  "煊": [
    "xuan1"
  ],
  "听从": [
    "ting1 cong2"
  ],
  "坉": [
    null
  ],
  "勾结": [
    "gou1 jie2"
  ],
  "张扬": [
    "Zhang1 Yang2",
    "zhang1 yang2"
  ],
  "脆弱": [
    "cui4 ruo4"
  ],
  "簪": [
    "zan1",
    "zan1"
  ],
  "掠": [
    "lu:e4"
  ],
  "正如": [
    "zheng4 ru2"
  ],
  "表达": [
    "biao3 da2"
  ],
  "趁着": [
    null
  ],
  "虐待": [
    "nu:e4 dai4"
  ],
  "翦": [
    "Jian3",
    "jian3"
  ],
  "悦耳": [
    "yue4 er3"
  ],
  "闳": [
    "Hong2",
    "hong2"
  ],
  "蜇": [
    "zhe1",
    "zhe2"
  ],
  "旻": [
    "min2"
  ],
  "鹣": [
    "jian1"
  ],
  "千家万户": [
    "qian1 jia1 wan4 hu4"
  ],
  "筜": [
    "dang1"
  ],
  "上场": [
    "shang4 chang3"
  ],
  "锋": [
    "feng1"
  ],
  "馌": [
    "ye4"
  ],
  "路边": [
    "lu4 bian1"
  ],
  "呆": [
    "dai1"
  ],
  "痈": [
    "yong1"
  ],
  "关联": [
    "guan1 lian2"
  ],
  "醵": [
    "ju4"
  ],
  "暶": [
    "xuan2"
  ],
  "波浪": [
    "bo1 lang4"
  ],
  "纥": [
    "ge1",
    "he2"
  ],
  "悲欢离合": [
    "bei1 huan1 li2 he2"
  ],
  "阔": [
    "kuo4",
    "kuo4"
  ],
  "媛": [
    "yuan2",
    "yuan4"
  ],
  "磔": [
    "zhe2"
  ],
  "鼙": [
    "pi2"
  ],
  "瀑布": [
    "pu4 bu4"
  ],
  "制订": [
    "zhi4 ding4"
  ],
  "气泡": [
    "qi4 pao4"
  ],
  "项": [
    "Xiang4",
    "xiang4"
  ],
  "变异": [
    "bian4 yi4"
  ],
  "土壤": [
    "tu3 rang3"
  ],
  "沉着": [
    "chen2 zhuo2"
  ],
  "天经地义": [
    "tian1 jing1 di4 yi4"
  ],
  "倾斜": [
    "qing1 xie2"
  ],
  "清醒": [
    "qing1 xing3"
  ],
  "统": [
    "tong3"
  ],
  "汇": [
    "hui4",
    "hui4",
    "hui4"
  ],
  "宦": [
    "Huan4",
    "huan4"
  ],
  "研制": [
    "yan2 zhi4"
  ],
  "弃": [
    "qi4"
  ],
  "缓": [
    "huan3"
  ],
  "亡": [
    "wang2",
    "wang2"
  ],
  "觑": [
    "qu4"
  ],
  "漖": [
    "jiao4"
  ],
  "初步": [
    "chu1 bu4"
  ],
  "蚂": [
    "ma1",
    "ma3",
    "ma4"
  ],
  "紧接着": [
    "jin3 jie1 zhe5"
  ],
  "篆": [
    "zhuan4"
  ],
  "罅": [
    "xia4"
  ],
  "铐": [
    "kao4"
  ],
  "药片": [
    "yao4 pian4"
  ],
  "收藏": [
    "shou1 cang2"
  ],
  "专题": [
    "zhuan1 ti2"
  ],
  "踊": [
    "yong3",
    "yong3"
  ],
  "䓛": [
    null
  ],
  "保障": [
    "bao3 zhang4"
  ],
  "蝎": [
    "xie1",
    "xie1"
  ],
  "曦": [
    "xi1"
  ],
  "并列": [
    "bing4 lie4"
  ],
  "总部": [
    "zong3 bu4"
  ],
  "祖籍": [
    "zu3 ji2"
  ],
  "皭": [
    "jiao4"
  ],
  "万无一失": [
    "wan4 wu2 yi1 shi1"
  ],
  "会员": [
    "hui4 yuan2"
  ],
  "嵊": [
    "Sheng4"
  ],
  "滇": [
    "Dian1"
  ],
  "褂": [
    "gua4"
  ],
  "回味": [
    "hui2 wei4"
  ],
  "黾": [
    "meng3",
    "min3"
  ],
  "住房": [
    "zhu4 fang2"
  ],
  "碣": [
    "jie2"
  ],
  "韭": [
    "jiu3",
    "jiu3"
  ],
  "占领": [
    "zhan4 ling3"
  ],
  "暴雨": [
    "bao4 yu3"
  ],
  "萚": [
    "tuo4"
  ],
  "帧": [
    "zhen1"
  ],
  "常": [
    "Chang2",
    "chang2"
  ],
  "发动": [
    "fa1 dong4"
  ],
  "魔术": [
    "mo2 shu4"
  ],
  "绝": [
    "jue2",
    "jue2"
  ],
  "缡": [
    "li2"
  ],
  "手册": [
    "shou3 ce4"
  ],
  "虬": [
    "qiu2"
  ],
  "莠": [
    "you3"
  ],
  "汗水": [
    "han4 shui3"
  ],
  "矞": [
    "yu4"
  ],
  "踊跃": [
    "yong3 yue4"
  ],
  "媖": [
    "ying1"
  ],
  "按键": [
    "an4 jian4"
  ],
  "箓": [
    "lu4"
  ],
  "变化": [
    "bian4 hua4"
  ],
  "浡": [
    "bo2"
  ],
  "嫉妒": [
    "ji2 du4"
  ],
  "涵": [
    "han2"
  ],
  "实施": [
    "shi2 shi1"
  ],
  "血": [
    "xue4"
  ],
  "取暖": [
    "qu3 nuan3"
  ],
  "呈现": [
    "cheng2 xian4"
  ],
  "揶": [
    "ye2"
  ],
  "空": [
    "kong1",
    "kong4"
  ],
  "吻": [
    "wen3",
    "wen3"
  ],
  "缨": [
    "ying1"
  ],
  "掠夺": [
    "lu:e4 duo2"
  ],
  "猜想": [
    "cai1 xiang3"
  ],
  "信心": [
    "xin4 xin1"
  ],
  "溇": [
    "Lou2",
    "lu:3"
  ],
  "珽": [
    "ting3"
  ],
  "柃": [
    "ling2"
  ],
  "翡": [
    "fei3"
  ],
  "氛围": [
    "fen1 wei2"
  ],
  "止咳": [
    "zhi3 ke2"
  ],
  "辫子": [
    "bian4 zi5"
  ],
  "心胸": [
    "xin1 xiong1"
  ],
  "沉闷": [
    "chen2 men4"
  ],
  "䲢": [
    null
  ],
  "篡": [
    "cuan4",
    "cuan4"
  ],
  "脑袋": [
    "nao3 dai5"
  ],
  "沟通": [
    "gou1 tong1"
  ],
  "匆匆": [
    "cong1 cong1"
  ],
  "起飞": [
    "qi3 fei1"
  ],
  "沉稳": [
    "chen2 wen3"
  ],
  "赕": [
    "dan3"
  ],
  "䌹": [
    "jiong3"
  ],
  "茗": [
    "ming2"
  ],
  "切割": [
    "qie1 ge1"
  ],
  "邮件": [
    "you2 jian4"
  ],
  "搡": [
    "sang3"
  ],
  "箸": [
    "zhu4",
    "zhu4"
  ],
  "知识分子": [
    "zhi1 shi5 fen4 zi3"
  ],
  "药水": [
    "Yao4 shui3",
    "yao4 shui3"
  ],
  "锍": [
    null
  ],
  "不辞而别": [
    "bu4 ci2 er2 bie2"
  ],
  "蓝": [
    "Lan2",
    "lan2"
  ],
  "休息": [
    "xiu1 xi5"
  ],
  "不见得": [
    "bu4 jian4 de5"
  ],
  "横向": [
    "heng2 xiang4"
  ],
  "儿科": [
    "er2 ke1"
  ],
  "炸": [
    "zha2",
    "zha4"
  ],
  "锺": [
    "zhong1"
  ],
  "臭": [
    "chou4",
    "xiu4"
  ],
  "海洋": [
    "hai3 yang2"
  ],
  "嗖": [
    "sou1"
  ],
  "镲": [
    "cha3"
  ],
  "芨": [
    "ji2"
  ],
  "孟": [
    "Meng4",
    "meng4"
  ],
  "窒息": [
    "zhi4 xi1"
  ],
  "奖励": [
    "jiang3 li4"
  ],
  "曜": [
    "yao4"
  ],
  "初衷": [
    "chu1 zhong1"
  ],
  "豆浆": [
    "dou4 jiang1"
  ],
  "设计": [
    "she4 ji4"
  ],
  "苦笑": [
    "ku3 xiao4"
  ],
  "堤坝": [
    "di1 ba4"
  ],
  "羑": [
    "you3"
  ],
  "玶": [
    "ping2"
  ],
  "新兴": [
    "Xin1 xing1",
    "xin1 xing1"
  ],
  "杞": [
    "Qi3",
    "qi3"
  ],
  "坚持": [
    "jian1 chi2"
  ],
  "饮食": [
    "yin3 shi2"
  ],
  "伊": [
    "Yi1",
    "yi1"
  ],
  "矿藏": [
    "kuang4 cang2"
  ],
  "瓶子": [
    "ping2 zi5"
  ],
  "茬": [
    "cha2"
  ],
  "豳": [
    "Bin1"
  ],
  "缩": [
    "suo1"
  ],
  "僵局": [
    "jiang1 ju2"
  ],
  "推敲": [
    "tui1 qiao1"
  ],
  "句": [
    "gou1",
    "ju4"
  ],
  "潘": [
    "Pan1"
  ],
  "姐": [
    "jie3"
  ],
  "导师": [
    "dao3 shi1"
  ],
  "谦": [
    "qian1"
  ],
  "侍": [
    "shi4"
  ],
  "有序": [
    "you3 xu4"
  ],
  "聪": [
    "cong1"
  ],
  "求职": [
    "qiu2 zhi2"
  ],
  "洲": [
    "zhou1"
  ],
  "蟥": [
    "huang2"
  ],
  "肿": [
    "zhong3"
  ],
  "堨": [
    "e4"
  ],
  "渔船": [
    "yu2 chuan2"
  ],
  "甯": [
    null
  ],
  "放过": [
    "fang4 guo4"
  ],
  "贺": [
    "He4",
    "he4"
  ],
  "彗": [
    "hui4"
  ],
  "牛": [
    "Niu2",
    "niu2"
  ],
  "宁": [
    "ning4",
    "Ning2",
    "ning2",
    "ning4",
    "ning2"
  ],
  "柬": [
    "Jian3",
    "jian3"
  ],
  "咧嘴": [
    "lie3 zui3"
  ],
  "膏": [
    "gao1",
    "gao4"
  ],
  "璟": [
    "jing3"
  ],
  "孰": [
    "shu2"
  ],
  "喜": [
    "xi3"
  ],
  "外交官": [
    "wai4 jiao1 guan1"
  ],
  "接二连三": [
    "jie1 er4 lian2 san1"
  ],
  "垞": [
    "cha2"
  ],
  "气球": [
    "qi4 qiu2"
  ],
  "呛": [
    "qiang1",
    "qiang4"
  ],
  "辏": [
    "cou4"
  ],
  "有效期": [
    "you3 xiao4 qi1"
  ],
  "鲁": [
    "Lu3",
    "lu3"
  ],
  "请帖": [
    "qing3 tie3"
  ],
  "缺失": [
    "que1 shi1"
  ],
  "接送": [
    "jie1 song4"
  ],
  "发布会": [
    "fa1 bu4 hui4",
    "fa1 bu4 hui4"
  ],
  "共鸣": [
    "gong4 ming2"
  ],
  "我": [
    "wo3"
  ],
  "身影": [
    "shen1 ying3"
  ],
  "协": [
    "xie2"
  ],
  "癍": [
    "ban1"
  ],
  "腔": [
    "qiang1"
  ],
  "矧": [
    "shen3"
  ],
  "考题": [
    "kao3 ti2"
  ],
  "层次": [
    "ceng2 ci4"
  ],
  "卣": [
    "you3"
  ],
  "转移": [
    "zhuan3 yi2"
  ],
  "爱国": [
    "ai4 guo2"
  ],
  "驾车": [
    "jia4 che1"
  ],
  "牯": [
    "gu3"
  ],
  "瞢": [
    "meng2"
  ],
  "偏僻": [
    "pian1 pi4"
  ],
  "罟": [
    "gu3"
  ],
  "在于": [
    "zai4 yu2"
  ],
  "企业": [
    "qi3 ye4"
  ],
  "咱们": [
    "zan2 men5"
  ],
  "眚": [
    "sheng3"
  ],
  "蠢": [
    "chun3",
    "chun3"
  ],
  "半决赛": [
    "ban4 jue2 sai4"
  ],
  "圈": [
    "juan1",
    "juan4",
    "quan1"
  ],
  "欠条": [
    "qian4 tiao2"
  ],
  "越": [
    "Yue4",
    "yue4"
  ],
  "芊": [
    "qian1"
  ],
  "酆": [
    "Feng1"
  ],
  "窘迫": [
    "jiong3 po4"
  ],
  "蛟": [
    "jiao1"
  ],
  "圭": [
    "gui1"
  ],
  "淳": [
    "chun2",
    "chun2"
  ],
  "哿": [
    "ge3"
  ],
  "憬": [
    "jing3"
  ],
  "外币": [
    "wai4 bi4"
  ],
  "领袖": [
    "ling3 xiu4"
  ],
  "时装": [
    "shi2 zhuang1"
  ],
  "玢": [
    "bin1",
    "fen1"
  ],
  "救治": [
    "jiu4 zhi4"
  ],
  "自尊心": [
    "zi4 zun1 xin1"
  ],
  "家园": [
    "jia1 yuan2"
  ],
  "墙壁": [
    "qiang2 bi4"
  ],
  "硊": [
    null
  ],
  "出局": [
    "chu1 ju2"
  ],
  "高层": [
    "gao1 ceng2"
  ],
  "焖": [
    "men4"
  ],
  "姝": [
    "shu1"
  ],
  "螺丝": [
    "luo2 si1"
  ],
  "相信": [
    "xiang1 xin4"
  ],
  "泙": [
    "ping2"
  ],
  "蘼": [
    "mi2"
  ],
  "突出": [
    "tu1 chu1"
  ],
  "猎犬": [
    "lie4 quan3"
  ],
  "属性": [
    "shu3 xing4"
  ],
  "应酬": [
    "ying4 chou5"
  ],
  "缺": [
    "que1"
  ],
  "粟": [
    "Su4",
    "su4"
  ],
  "密集": [
    "mi4 ji2"
  ],
  "簿": [
    "bu4"
  ],
  "橑": [
    null
  ],
  "扈": [
    "Hu4",
    "hu4"
  ],
  "难忘": [
    "nan2 wang4"
  ],
  "宄": [
    "gui3"
  ],
  "瑞": [
    "rui4"
  ],
  "轶": [
    "yi4"
  ],
  "轷": [
    "Hu1"
  ],
  "绘": [
    "hui4"
  ],
  "恩": [
    "en1",
    "en1"
  ],
  "羞愧": [
    "xiu1 kui4"
  ],
  "议程": [
    "yi4 cheng2"
  ],
  "讧": [
    "hong4"
  ],
  "䴖": [
    null
  ],
  "木匠": [
    "mu4 jiang4"
  ],
  "倘若": [
    "tang3 ruo4"
  ],
  "兑换": [
    "dui4 huan4"
  ],
  "伲": [
    "ni3",
    "ni4"
  ],
  "昏迷": [
    "hun1 mi2"
  ],
  "娲": [
    "Wa1"
  ],
  "赞美": [
    "zan4 mei3"
  ],
  "浸泡": [
    "jin4 pao4"
  ],
  "简易": [
    "jian3 yi4"
  ],
  "代表团": [
    "dai4 biao3 tuan2"
  ],
  "照例": [
    "zhao4 li4"
  ],
  "抢夺": [
    "qiang3 duo2"
  ],
  "回扣": [
    "hui2 kou4"
  ],
  "分为": [
    "fen1 wei2"
  ],
  "通行证": [
    "tong1 xing2 zheng4"
  ],
  "冲": [
    "chong1",
    "chong1",
    "chong4"
  ],
  "檞": [
    null
  ],
  "浴": [
    "yu4"
  ],
  "陨": [
    "yun3"
  ],
  "粪": [
    "fen4"
  ],
  "诉苦": [
    "su4 ku3"
  ],
  "堵": [
    "du3"
  ],
  "心灵": [
    "xin1 ling2"
  ],
  "得不偿失": [
    "de2 bu4 chang2 shi1"
  ],
  "电脑": [
    "dian4 nao3"
  ],
  "讨": [
    "tao3"
  ],
  "钦佩": [
    "qin1 pei4"
  ],
  "勇于": [
    "yong3 yu2"
  ],
  "爇": [
    "re4",
    "ruo4"
  ],
  "锧": [
    "zhi4"
  ],
  "耩": [
    "jiang3"
  ],
  "熄": [
    "xi1"
  ],
  "讼": [
    "song4"
  ],
  "荒凉": [
    "huang1 liang2"
  ],
  "侹": [
    "ting3"
  ],
  "数量": [
    "shu4 liang4"
  ],
  "漪": [
    "yi1"
  ],
  "二氧化碳": [
    "er4 yang3 hua4 tan4"
  ],
  "认定": [
    "ren4 ding4"
  ],
  "适合": [
    "shi4 he2"
  ],
  "概": [
    "gai4",
    "gai4"
  ],
  "愈合": [
    "yu4 he2"
  ],
  "值钱": [
    "zhi2 qian2"
  ],
  "峪": [
    "yu4"
  ],
  "倻": [
    "ye1"
  ],
  "举世闻名": [
    "ju3 shi4 wen2 ming2"
  ],
  "无所作为": [
    "wu2 suo3 zuo4 wei2"
  ],
  "植物": [
    "zhi2 wu4"
  ],
  "露天": [
    "lu4 tian1"
  ],
  "延缓": [
    "yan2 huan3"
  ],
  "化妆": [
    "hua4 zhuang1"
  ],
  "佖": [
    null
  ],
  "走后门": [
    "zou3 hou4 men2"
  ],
  "落差": [
    "luo4 cha1"
  ],
  "繁荣": [
    "fan2 rong2"
  ],
  "匆忙": [
    "cong1 mang2"
  ],
  "茑": [
    "niao3"
  ],
  "经久不息": [
    "jing1 jiu3 - bu4 xi1"
  ],
  "扩": [
    "kuo4"
  ],
  "歃": [
    "sha4"
  ],
  "暑": [
    "shu3"
  ],
  "看台": [
    "kan4 tai2"
  ],
  "贯通": [
    "guan4 tong1"
  ],
  "快乐": [
    "kuai4 le4"
  ],
  "商讨": [
    "shang1 tao3"
  ],
  "连滚带爬": [
    "lian2 gun3 dai4 pa2"
  ],
  "藁": [
    "gao3"
  ],
  "簝": [
    "liao2"
  ],
  "汝": [
    "ru3"
  ],
  "美": [
    "Mei3",
    "mei3"
  ],
  "培训": [
    "pei2 xun4"
  ],
  "椓": [
    "zhuo2"
  ],
  "态度": [
    "tai4 du5"
  ],
  "注视": [
    "zhu4 shi4"
  ],
  "去掉": [
    "qu4 diao4"
  ],
  "收拾": [
    "shou1 shi5"
  ],
  "盛行": [
    "sheng4 xing2"
  ],
  "揽": [
    "lan3"
  ],
  "精神": [
    "jing1 shen2",
    "jing1 shen5"
  ],
  "幸好": [
    "xing4 hao3"
  ],
  "数码": [
    "shu4 ma3"
  ],
  "无数": [
    "wu2 shu4"
  ],
  "当时": [
    "dang1 shi2",
    "dang4 shi2"
  ],
  "胲": [
    "hai3"
  ],
  "丰": [
    "feng1",
    "Feng1",
    "feng1"
  ],
  "所作所为": [
    "suo3 zuo4 suo3 wei2"
  ],
  "舍得": [
    "she3 de5"
  ],
  "薸": [
    "piao1"
  ],
  "耶": [
    "ye1",
    "ye2",
    "ye5"
  ],
  "不然": [
    "bu4 ran2"
  ],
  "新式": [
    "xin1 shi4"
  ],
  "跳": [
    "tiao4"
  ],
  "肱": [
    "gong1"
  ],
  "亚军": [
    "ya4 jun1"
  ],
  "闷": [
    "men1",
    "men4"
  ],
  "重量": [
    "zhong4 liang4"
  ],
  "忡": [
    "chong1"
  ],
  "闸": [
    "zha2",
    "zha2"
  ],
  "路": [
    "Lu4",
    "lu4"
  ],
  "禀": [
    "bing3"
  ],
  "侠": [
    "xia2"
  ],
  "断断续续": [
    "duan4 duan4 xu4 xu4"
  ],
  "后悔": [
    "hou4 hui3"
  ],
  "绒": [
    "rong2"
  ],
  "因": [
    "yin1",
    "yin1"
  ],
  "特产": [
    "te4 chan3"
  ],
  "妨": [
    "fang2"
  ],
  "途中": [
    "tu2 zhong1"
  ],
  "实体": [
    "shi2 ti3"
  ],
  "军官": [
    "jun1 guan1"
  ],
  "不亦乐乎": [
    "bu4 yi4 le4 hu1"
  ],
  "轭": [
    "e4"
  ],
  "低温": [
    "di1 wen1"
  ],
  "湲": [
    "yuan2"
  ],
  "栀": [
    "zhi1"
  ],
  "队长": [
    "dui4 zhang3"
  ],
  "身躯": [
    "shen1 qu1"
  ],
  "信赖": [
    "xin4 lai4"
  ],
  "卮": [
    "zhi1",
    "zhi1"
  ],
  "嵝": [
    "lou3"
  ],
  "高尔夫球": [
    "gao1 er3 fu1 qiu2"
  ],
  "苁": [
    "cong1"
  ],
  "关系": [
    "guan1 xi5",
    "guan1 xi5"
  ],
  "溷": [
    "hun4"
  ],
  "转学": [
    "zhuan3 xue2"
  ],
  "群": [
    "qun2",
    "qun2"
  ],
  "脖子": [
    "bo2 zi5"
  ],
  "雀": [
    "qiao1",
    "que4"
  ],
  "绊": [
    "ban4"
  ],
  "蹐": [
    "ji2"
  ],
  "熵": [
    "shang1"
  ],
  "挤压": [
    "ji3 ya1"
  ],
  "翙": [
    "hui4"
  ],
  "蹀": [
    "die2"
  ],
  "脑筋": [
    "nao3 jin1"
  ],
  "车站": [
    "che1 zhan4"
  ],
  "重合": [
    "chong2 he2"
  ],
  "毡": [
    "zhan1",
    "zhan1"
  ],
  "元旦": [
    "Yuan2 dan4"
  ],
  "抬头": [
    "tai2 tou2"
  ],
  "清新": [
    "Qing1 xin1",
    "qing1 xin1"
  ],
  "寁": [
    "zan3"
  ],
  "海峡": [
    "hai3 xia2"
  ],
  "旅馆": [
    "lu:3 guan3"
  ],
  "显然": [
    "xian3 ran2"
  ],
  "健美": [
    "jian4 mei3"
  ],
  "指标": [
    "zhi3 biao1"
  ],
  "刺激": [
    "ci4 ji1"
  ],
  "骗": [
    "pian4"
  ],
  "匙": [
    "chi2",
    "shi5"
  ],
  "荧光": [
    "ying2 guang1"
  ],
  "霜": [
    "shuang1"
  ],
  "胜": [
    "sheng4"
  ],
  "后者": [
    "hou4 zhe3"
  ],
  "空白": [
    "kong4 bai2"
  ],
  "说明": [
    "shuo1 ming2"
  ],
  "疔": [
    "ding1"
  ],
  "多余": [
    "duo1 yu2"
  ],
  "顺路": [
    "shun4 lu4"
  ],
  "出风头": [
    "chu1 feng1 tou5"
  ],
  "己": [
    "ji3"
  ],
  "老大": [
    "lao3 da4"
  ],
  "炫耀": [
    "xuan4 yao4"
  ],
  "楼上": [
    "lou2 shang4"
  ],
  "蜒": [
    "yan2"
  ],
  "闿": [
    "kai3"
  ],
  "抽屉": [
    "chou1 ti5"
  ],
  "佑": [
    "you4"
  ],
  "怎么办": [
    "zen3 me5 ban4"
  ],
  "复发": [
    "fu4 fa1"
  ],
  "补救": [
    "bu3 jiu4"
  ],
  "鲂": [
    "fang2"
  ],
  "失败": [
    "shi1 bai4"
  ],
  "排行榜": [
    "pai2 hang2 bang3"
  ],
  "诓": [
    "kuang1"
  ],
  "肠": [
    "chang2",
    "chang2"
  ],
  "放学": [
    "fang4 xue2"
  ],
  "到": [
    "dao4"
  ],
  "鲢": [
    "lian2"
  ],
  "阳": [
    "yang2"
  ],
  "归根到底": [
    "gui1 gen1 dao4 di3"
  ],
  "蒟": [
    "ju3"
  ],
  "媄": [
    "mei3"
  ],
  "流程": [
    "liu2 cheng2"
  ],
  "胯": [
    "kua4"
  ],
  "无知": [
    "wu2 zhi1"
  ],
  "无形": [
    "wu2 xing2"
  ],
  "避暑": [
    "bi4 shu3"
  ],
  "悟": [
    "wu4"
  ],
  "对应": [
    "dui4 ying4"
  ],
  "攒": [
    "cuan2",
    "zan3"
  ],
  "利率": [
    "li4 lu:4"
  ],
  "等到": [
    "deng3 dao4"
  ],
  "鄌": [
    null
  ],
  "从此": [
    "cong2 ci3"
  ],
  "昴": [
    "mao3"
  ],
  "该": [
    "gai1"
  ],
  "打球": [
    "da3 qiu2"
  ],
  "绿茶": [
    "lu:4 cha2"
  ],
  "沿着": [
    "yan2 zhe5"
  ],
  "泪": [
    "lei4"
  ],
  "葶": [
    "ting2"
  ],
  "铚": [
    "zhi4"
  ],
  "雾": [
    "wu4"
  ],
  "跑龙套": [
    "pao3 long2 tao4"
  ],
  "碰": [
    "peng4",
    "peng4",
    "peng4"
  ],
  "幸福": [
    "xing4 fu2"
  ],
  "总结": [
    "zong3 jie2"
  ],
  "存在": [
    "cun2 zai4"
  ],
  "宣泄": [
    "xuan1 xie4"
  ],
  "皈": [
    "gui1"
  ],
  "将要": [
    "jiang1 yao4"
  ],
  "密封": [
    "mi4 feng1"
  ],
  "有力": [
    "you3 li4"
  ],
  "酗酒": [
    "xu4 jiu3"
  ],
  "罘": [
    "fu2"
  ],
  "沿途": [
    "yan2 tu2"
  ],
  "悲观": [
    "bei1 guan1"
  ],
  "柴": [
    "Chai2",
    "chai2"
  ],
  "咋": [
    "za3",
    "ze2",
    "zha4"
  ],
  "艮": [
    "gen3",
    "gen4"
  ],
  "受惊": [
    "shou4 jing1"
  ],
  "苇": [
    "wei3"
  ],
  "历": [
    "li4",
    "li4",
    "li4",
    "li4"
  ],
  "解脱": [
    "jie3 tuo1"
  ],
  "魃": [
    "ba2"
  ],
  "骊": [
    "li2"
  ],
  "动机": [
    "dong4 ji1"
  ],
  "仪式": [
    "yi2 shi4"
  ],
  "改变": [
    "gai3 bian4"
  ],
  "智商": [
    "zhi4 shang1"
  ],
  "枭": [
    "xiao1"
  ],
  "相辅相成": [
    "xiang1 fu3 xiang1 cheng2"
  ],
  "西班牙语": [
    "Xi1 ban1 ya2 yu3"
  ],
  "缌": [
    "si1"
  ],
  "兜": [
    "dou1",
    "dou1"
  ],
  "讴": [
    "ou1"
  ],
  "纹": [
    "wen2"
  ],
  "合情合理": [
    "he2 qing2 he2 li3"
  ],
  "外出": [
    "wai4 chu1"
  ],
  "风沙": [
    "feng1 sha1"
  ],
  "蝉": [
    "chan2"
  ],
  "树林": [
    "Shu4 lin2",
    "shu4 lin2"
  ],
  "嘁": [
    "qi1"
  ],
  "定向": [
    "ding4 xiang4"
  ],
  "窥": [
    "kui1",
    "kui1"
  ],
  "自发": [
    "zi4 fa1"
  ],
  "艚": [
    "cao2"
  ],
  "宠爱": [
    "chong3 ai4"
  ],
  "补贴": [
    "bu3 tie1"
  ],
  "叫板": [
    "jiao4 ban3"
  ],
  "加以": [
    "jia1 yi3"
  ],
  "哩": [
    "li3",
    "li5"
  ],
  "艰苦": [
    "jian1 ku3"
  ],
  "全长": [
    "quan2 chang2"
  ],
  "呵护": [
    "he1 hu4"
  ],
  "一大早": [
    "yi1 da4 zao3"
  ],
  "务必": [
    "wu4 bi4"
  ],
  "丧生": [
    "sang4 sheng1"
  ],
  "仁": [
    "ren2"
  ],
  "里边": [
    "li3 bian5"
  ],
  "声望": [
    "sheng1 wang4"
  ],
  "订": [
    "ding4"
  ],
  "偎": [
    "wei1"
  ],
  "绾": [
    "wan3"
  ],
  "若干": [
    "ruo4 gan1"
  ],
  "塥": [
    "ge2"
  ],
  "水利": [
    "shui3 li4"
  ],
  "皇室": [
    "huang2 shi4"
  ],
  "发送": [
    "fa1 song4"
  ],
  "亭": [
    "ting2"
  ],
  "路口": [
    "lu4 kou3"
  ],
  "愕": [
    "e4"
  ],
  "蚄": [
    "fang1"
  ],
  "骂": [
    "ma4",
    "ma4"
  ],
  "健全": [
    "jian4 quan2"
  ],
  "汛": [
    "xun4"
  ],
  "饭碗": [
    "fan4 wan3"
  ],
  "材料": [
    "cai2 liao4"
  ],
  "腰": [
    "yao1"
  ],
  "涨": [
    "zhang3",
    "zhang4"
  ],
  "硬": [
    "ying4"
  ],
  "帚": [
    "zhou3",
    "zhou3"
  ],
  "撙": [
    "zun3"
  ],
  "娟": [
    "juan1"
  ],
  "勰": [
    "xie2"
  ],
  "倾": [
    "qing1"
  ],
  "逴": [
    "chuo4"
  ],
  "箴": [
    "zhen1"
  ],
  "洳": [
    "ru4"
  ],
  "稿子": [
    "gao3 zi5"
  ],
  "楣": [
    "mei2"
  ],
  "街": [
    "jie1"
  ],
  "盘算": [
    "pan2 suan4"
  ],
  "旒": [
    "liu2"
  ],
  "随身": [
    "sui2 shen1"
  ],
  "邿": [
    "shi1"
  ],
  "横七竖八": [
    "heng2 qi1 shu4 ba1"
  ],
  "不妨": [
    "bu4 fang2"
  ],
  "褐": [
    "he4"
  ],
  "钦": [
    "Qin1",
    "qin1"
  ],
  "抨击": [
    "peng1 ji1"
  ],
  "郜": [
    "Gao4"
  ],
  "怨言": [
    "yuan4 yan2"
  ],
  "住处": [
    "zhu4 chu4"
  ],
  "冒昧": [
    "mao4 mei4"
  ],
  "皮": [
    "Pi2",
    "pi2"
  ],
  "冢": [
    "zhong3",
    "zhong3"
  ],
  "哽": [
    "geng3"
  ],
  "感触": [
    "gan3 chu4"
  ],
  "坚信": [
    "jian1 xin4"
  ],
  "蹭": [
    "ceng4"
  ],
  "古": [
    "Gu3",
    "gu3"
  ],
  "发炎": [
    "fa1 yan2"
  ],
  "袪": [
    "qu1"
  ],
  "实质": [
    "shi2 zhi4"
  ],
  "坏事": [
    "huai4 shi4"
  ],
  "芯片": [
    "xin1 pian4"
  ],
  "伪造": [
    "wei3 zao4"
  ],
  "发言": [
    "fa1 yan2"
  ],
  "籍": [
    "Ji2",
    "ji2"
  ],
  "茅台": [
    "Mao2 tai2",
    "mao2 tai2"
  ],
  "篓": [
    "lou3"
  ],
  "然": [
    "ran2"
  ],
  "曳": [
    "ye4"
  ],
  "孤零零": [
    "gu1 ling2 ling2"
  ],
  "提心吊胆": [
    "ti2 xin1 diao4 dan3"
  ],
  "保安": [
    "bao3 an1"
  ],
  "蚊": [
    "wen2",
    "wen2",
    "wen2"
  ],
  "个别": [
    "ge4 bie2"
  ],
  "铲": [
    "chan3",
    "chan3"
  ],
  "除": [
    "chu2"
  ],
  "信号": [
    "xin4 hao4"
  ],
  "蟏": [
    "xiao1"
  ],
  "季": [
    "Ji4",
    "ji4"
  ],
  "僚": [
    "Liao2",
    "liao2"
  ],
  "削": [
    "xiao1",
    "xue1"
  ],
  "球拍": [
    "qiu2 pai1"
  ],
  "着": [
    "zhao1",
    "zhao2",
    "zhe5",
    "zhuo2"
  ],
  "镠": [
    "liu2"
  ],
  "钢笔": [
    "gang1 bi3"
  ],
  "鍪": [
    "mou2"
  ],
  "开垦": [
    "kai1 ken3"
  ],
  "歌": [
    "ge1",
    "ge1"
  ],
  "坋": [
    "ben4"
  ],
  "骧": [
    "xiang1"
  ],
  "镓": [
    "jia1"
  ],
  "舢": [
    "shan1"
  ],
  "奔跑": [
    "ben1 pao3"
  ],
  "虺": [
    "hui1",
    "hui3"
  ],
  "淹": [
    "yan1"
  ],
  "牍": [
    "du2"
  ],
  "罪犯": [
    "zui4 fan4"
  ],
  "充满": [
    "chong1 man3"
  ],
  "教材": [
    "jiao4 cai2"
  ],
  "创意": [
    "chuang4 yi4"
  ],
  "好久": [
    "hao3 jiu3"
  ],
  "暴风雨": [
    "bao4 feng1 yu3"
  ],
  "回国": [
    "hui2 guo2"
  ],
  "寡": [
    "gua3"
  ],
  "隐瞒": [
    "yin3 man2"
  ],
  "不如": [
    "bu4 ru2"
  ],
  "骍": [
    "xing1"
  ],
  "奇": [
    "ji1",
    "qi2"
  ],
  "痛心": [
    "tong4 xin1"
  ],
  "来年": [
    "lai2 nian2"
  ],
  "显出": [
    "xian3 chu1"
  ],
  "逼真": [
    "bi1 zhen1"
  ],
  "签字": [
    "qian1 zi4"
  ],
  "蛸": [
    "shao1",
    "xiao1"
  ],
  "隺": [
    null
  ],
  "屦": [
    "ju4"
  ],
  "滁": [
    "Chu2"
  ],
  "瘿": [
    "ying3"
  ],
  "普通人": [
    "pu3 tong1 ren2"
  ],
  "浍": [
    "kuai4"
  ],
  "倌": [
    "guan1"
  ],
  "鬒": [
    "zhen3"
  ],
  "财政": [
    "cai2 zheng4"
  ],
  "莎": [
    "sha1",
    "suo1"
  ],
  "兜售": [
    "dou1 shou4"
  ],
  "火炬": [
    "huo3 ju4"
  ],
  "啃": [
    "ken3"
  ],
  "锢": [
    "gu4"
  ],
  "轻松": [
    "qing1 song1"
  ],
  "玄机": [
    "xuan2 ji1"
  ],
  "夸大": [
    "kua1 da4"
  ],
  "雌": [
    "ci2"
  ],
  "祯": [
    "zhen1"
  ],
  "氪": [
    "ke4"
  ],
  "爰": [
    "Yuan2",
    "yuan2"
  ],
  "笳": [
    "jia1"
  ],
  "午睡": [
    "wu3 shui4"
  ],
  "揣摩": [
    "chuai3 mo2"
  ],
  "做梦": [
    "zuo4 meng4"
  ],
  "比分": [
    "bi3 fen1"
  ],
  "支": [
    "Zhi1",
    "zhi1"
  ],
  "追": [
    "dui1",
    "zhui1"
  ],
  "解剖": [
    "jie3 pou1"
  ],
  "咣": [
    "guang1"
  ],
  "牢固": [
    "lao2 gu4"
  ],
  "记忆犹新": [
    "ji4 yi4 you2 xin1"
  ],
  "珋": [
    "liu3"
  ],
  "媆": [
    null
  ],
  "海盗": [
    "hai3 dao4"
  ],
  "虮": [
    "ji3"
  ],
  "无论": [
    "wu2 lun4"
  ],
  "老汉": [
    "lao3 han4"
  ],
  "之中": [
    "zhi1 zhong1"
  ],
  "大致": [
    "da4 zhi4"
  ],
  "批": [
    "pi1"
  ],
  "专制": [
    "zhuan1 zhi4"
  ],
  "肴": [
    "yao2",
    "yao2",
    "yao2"
  ],
  "钢琴": [
    "gang1 qin2"
  ],
  "路人": [
    "lu4 ren2"
  ],
  "方便": [
    "fang1 bian4"
  ],
  "柳树": [
    "liu3 shu4"
  ],
  "总监": [
    "zong3 jian1"
  ],
  "温度计": [
    "wen1 du4 ji4"
  ],
  "跏": [
    "jia1"
  ],
  "辨认": [
    "bian4 ren4"
  ],
  "西北": [
    "Xi1 bei3",
    "xi1 bei3"
  ],
  "歉": [
    "qian4"
  ],
  "砟": [
    "zha3"
  ],
  "附近": [
    "fu4 jin4"
  ],
  "变换": [
    "bian4 huan4"
  ],
  "潲": [
    "shao4"
  ],
  "嵴": [
    "ji2"
  ],
  "箨": [
    "tuo4"
  ],
  "女人": [
    "nu:3 ren2",
    "nu:3 ren5"
  ],
  "蛏": [
    "cheng1"
  ],
  "鸨": [
    "bao3"
  ],
  "好似": [
    "hao3 si4"
  ],
  "彻夜": [
    "che4 ye4"
  ],
  "一起": [
    "yi1 qi3"
  ],
  "宽阔": [
    "kuan1 kuo4"
  ],
  "感激": [
    "gan3 ji1"
  ],
  "谶": [
    "chen4"
  ],
  "注": [
    "zhu4",
    "zhu4"
  ],
  "腩": [
    "nan3"
  ],
  "返回": [
    "fan3 hui2"
  ],
  "惫": [
    "bei4"
  ],
  "秽": [
    "hui4"
  ],
  "话": [
    "hua4",
    "hua4"
  ],
  "阅历": [
    "yue4 li4"
  ],
  "匹": [
    "pi1",
    "pi3",
    "pi3"
  ],
  "耻": [
    "chi3"
  ],
  "踪": [
    "zong1",
    "zong1"
  ],
  "腻": [
    "ni4"
  ],
  "煟": [
    "wei4"
  ],
  "周边": [
    "zhou1 bian1"
  ],
  "嘭": [
    "peng1"
  ],
  "粗略": [
    "cu1 lu:e4"
  ],
  "腹": [
    "fu4"
  ],
  "悰": [
    "cong2"
  ],
  "奠": [
    "dian4"
  ],
  "鹾": [
    "cuo2"
  ],
  "难度": [
    "nan2 du4"
  ],
  "常态": [
    "chang2 tai4"
  ],
  "坐下": [
    "zuo4 xia5"
  ],
  "殴打": [
    "ou1 da3"
  ],
  "否则": [
    "fou3 ze2"
  ],
  "随时随地": [
    "sui2 shi2 sui2 di4"
  ],
  "浓": [
    "nong2"
  ],
  "建筑物": [
    "jian4 zhu4 wu4"
  ],
  "谗": [
    "chan2"
  ],
  "力不从心": [
    "li4 bu4 cong2 xin1"
  ],
  "错觉": [
    "cuo4 jue2"
  ],
  "公鸡": [
    "gong1 ji1"
  ],
  "意图": [
    "yi4 tu2"
  ],
  "源泉": [
    "yuan2 quan2"
  ],
  "默默": [
    "mo4 mo4"
  ],
  "挣钱": [
    "zheng4 qian2"
  ],
  "棬": [
    "quan1"
  ],
  "良": [
    "liang2"
  ],
  "耰": [
    "you1"
  ],
  "笨蛋": [
    "ben4 dan4"
  ],
  "平和": [
    "Ping2 he2",
    "ping2 he2"
  ],
  "馆": [
    "guan3",
    "guan3"
  ],
  "招募": [
    "zhao1 mu4"
  ],
  "肛": [
    "gang1",
    "gang1"
  ],
  "慑": [
    "she4",
    "she4"
  ],
  "瀣": [
    "xie4"
  ],
  "年夜饭": [
    "nian2 ye4 fan4"
  ],
  "恰": [
    "qia4"
  ],
  "和蔼": [
    "he2 ai3"
  ],
  "演奏": [
    "yan3 zou4"
  ],
  "供": [
    "gong1",
    "gong4"
  ],
  "陶": [
    "Tao2",
    "tao2"
  ],
  "改革": [
    "gai3 ge2"
  ],
  "螬": [
    "cao2"
  ],
  "外文": [
    "wai4 wen2"
  ],
  "鲊": [
    "zha3"
  ],
  "藏匿": [
    "cang2 ni4"
  ],
  "小看": [
    "xiao3 kan4"
  ],
  "心里": [
    "xin1 li5"
  ],
  "慷慨": [
    "kang1 kai3"
  ],
  "螵": [
    "piao1"
  ],
  "晢": [
    "zhe2"
  ],
  "誊": [
    "teng2"
  ],
  "苛": [
    "ke1"
  ],
  "灾": [
    "zai1",
    "zai1",
    "zai1"
  ],
  "耗时": [
    "hao4 shi2"
  ],
  "咥": [
    "die2",
    "xi4"
  ],
  "主权": [
    "zhu3 quan2"
  ],
  "简称": [
    "jian3 cheng1"
  ],
  "胖": [
    "pan2",
    "pang4"
  ],
  "焦急": [
    "jiao1 ji2"
  ],
  "谈": [
    "Tan2",
    "tan2"
  ],
  "没意思": [
    "mei2 yi4 si5"
  ],
  "明白": [
    "ming2 bai5"
  ],
  "鸾": [
    "luan2"
  ],
  "蟋": [
    "xi1"
  ],
  "规律": [
    "gui1 lu:4"
  ],
  "袖": [
    "xiu4"
  ],
  "勘探": [
    "kan1 tan4"
  ],
  "濒": [
    "bin1"
  ],
  "锚": [
    "mao2"
  ],
  "诚恳": [
    "cheng2 ken3"
  ],
  "黜": [
    "chu4"
  ],
  "喃": [
    "nan2"
  ],
  "频率": [
    "pin2 lu:4"
  ],
  "警告": [
    "jing3 gao4"
  ],
  "户": [
    "hu4"
  ],
  "嵫": [
    "zi1"
  ],
  "顺便": [
    "shun4 bian4"
  ],
  "锵": [
    "qiang1"
  ],
  "梾": [
    "lai2"
  ],
  "透明": [
    "tou4 ming2"
  ],
  "楚": [
    "Chu3",
    "chu3"
  ],
  "感": [
    "gan3"
  ],
  "原理": [
    "yuan2 li3"
  ],
  "缃": [
    "xiang1"
  ],
  "瞭": [
    "liao4"
  ],
  "瞎": [
    "xia1"
  ],
  "出山": [
    "chu1 shan1"
  ],
  "蛋糕": [
    "dan4 gao1"
  ],
  "帮": [
    "bang1",
    "bang1",
    "bang1"
  ],
  "来回": [
    "lai2 hui2"
  ],
  "估": [
    "gu1",
    "gu4"
  ],
  "竣": [
    "jun4"
  ],
  "导向": [
    "dao3 xiang4"
  ],
  "太空": [
    "tai4 kong1"
  ],
  "章": [
    "Zhang1",
    "zhang1"
  ],
  "啕": [
    "tao2"
  ],
  "耕": [
    "geng1",
    "geng1"
  ],
  "罢免": [
    "ba4 mian3"
  ],
  "觅": [
    "mi4",
    "mi4"
  ],
  "文学": [
    "wen2 xue2"
  ],
  "栲": [
    "kao3"
  ],
  "穄": [
    "ji4"
  ],
  "刚": [
    "gang1"
  ],
  "汇款": [
    "hui4 kuan3"
  ],
  "抽烟": [
    "chou1 yan1",
    "chou1 yan1"
  ],
  "哐": [
    "kuang1"
  ],
  "否认": [
    "fou3 ren4"
  ],
  "难": [
    "nan2",
    "nan4"
  ],
  "冲撞": [
    "chong1 zhuang4"
  ],
  "钅": [
    null
  ],
  "裙": [
    "qun2",
    "qun2",
    "qun2"
  ],
  "哆嗦": [
    "duo1 suo5"
  ],
  "晓得": [
    "xiao3 de5"
  ],
  "电源": [
    "dian4 yuan2"
  ],
  "昝": [
    "Zan3"
  ],
  "灵活": [
    "ling2 huo2"
  ],
  "目睹": [
    "mu4 du3"
  ],
  "郑": [
    "Zheng4",
    "zheng4"
  ],
  "昕": [
    "xin1"
  ],
  "境外": [
    "jing4 wai4"
  ],
  "有关": [
    "you3 guan1"
  ],
  "停泊": [
    "ting2 bo2"
  ],
  "孖": [
    "zi1"
  ],
  "顾不上": [
    "gu4 bu5 shang4"
  ],
  "喵": [
    "miao1"
  ],
  "曌": [
    "Zhao4"
  ],
  "扊": [
    "yan3"
  ],
  "建交": [
    "jian4 jiao1"
  ],
  "揸": [
    "zha1"
  ],
  "爨": [
    "Cuan4",
    "cuan4"
  ],
  "权力": [
    "quan2 li4"
  ],
  "海岸": [
    "hai3 an4"
  ],
  "蠓": [
    "meng3"
  ],
  "稍候": [
    "shao1 hou4"
  ],
  "门诊": [
    "men2 zhen3"
  ],
  "衡": [
    "heng2"
  ],
  "哪怕": [
    "na3 pa4"
  ],
  "恔": [
    "xiao4"
  ],
  "协调": [
    "xie2 tiao2"
  ],
  "车速": [
    "che1 su4"
  ],
  "宽广": [
    "kuan1 guang3"
  ],
  "残留": [
    "can2 liu2"
  ],
  "断定": [
    "duan4 ding4"
  ],
  "吸毒": [
    "xi1 du2"
  ],
  "氾": [
    null
  ],
  "娜": [
    "na4",
    "nuo2"
  ],
  "火辣辣": [
    "huo3 la4 la4"
  ],
  "筠": [
    "yun2"
  ],
  "直播": [
    "zhi2 bo1"
  ],
  "赡": [
    "shan4"
  ],
  "烟花": [
    "yan1 hua1"
  ],
  "沣": [
    "feng1"
  ],
  "憔": [
    "qiao2",
    "qiao2",
    "qiao2"
  ],
  "牤": [
    "mang1"
  ],
  "帕": [
    "pa4"
  ],
  "监督": [
    "jian1 du1"
  ],
  "炻": [
    "shi2"
  ],
  "新娘": [
    "xin1 niang2"
  ],
  "人民": [
    "ren2 min2"
  ],
  "重量级": [
    "zhong4 liang4 ji2"
  ],
  "插手": [
    "cha1 shou3"
  ],
  "运": [
    "yun4"
  ],
  "劣势": [
    "lie4 shi4"
  ],
  "骺": [
    "hou2"
  ],
  "汜": [
    "si4"
  ],
  "纫": [
    "ren4"
  ],
  "填空": [
    "tian2 kong4"
  ],
  "胫": [
    "jing4",
    "jing4"
  ],
  "口气": [
    "kou3 qi4"
  ],
  "式": [
    "shi4"
  ],
  "跟踪": [
    "gen1 zong1"
  ],
  "慝": [
    "te4"
  ],
  "鹉": [
    "wu3"
  ],
  "少有": [
    "shao3 you3"
  ],
  "珍贵": [
    "zhen1 gui4"
  ],
  "妤": [
    "yu2"
  ],
  "内衣": [
    "nei4 yi1"
  ],
  "撬": [
    "qiao4"
  ],
  "本着": [
    "ben3 zhe5"
  ],
  "部位": [
    "bu4 wei4"
  ],
  "娠": [
    "shen1"
  ],
  "漴": [
    null
  ],
  "堇": [
    "jin3"
  ],
  "麻": [
    "ma2",
    "Ma2",
    "ma2"
  ],
  "航班": [
    "hang2 ban1"
  ],
  "亢": [
    "Kang4",
    "kang4"
  ],
  "反倒": [
    "fan3 dao4"
  ],
  "掏": [
    "tao1",
    "tao1"
  ],
  "函授": [
    "han2 shou4"
  ],
  "抢劫": [
    "qiang3 jie2"
  ],
  "气魄": [
    "qi4 po4"
  ],
  "葜": [
    null
  ],
  "厉害": [
    "li4 hai5"
  ],
  "无": [
    "wu2"
  ],
  "完全": [
    "wan2 quan2"
  ],
  "去年": [
    "qu4 nian2"
  ],
  "快递": [
    "kuai4 di4"
  ],
  "岵": [
    "hu4"
  ],
  "假如": [
    "jia3 ru2"
  ],
  "蒻": [
    "ruo4"
  ],
  "鹐": [
    "qian1"
  ],
  "畛": [
    "zhen3"
  ],
  "开除": [
    "kai1 chu2"
  ],
  "主导": [
    "zhu3 dao3"
  ],
  "像样": [
    "xiang4 yang4"
  ],
  "痧": [
    "sha1"
  ],
  "眼泪": [
    "yan3 lei4"
  ],
  "潍": [
    "wei2"
  ],
  "禊": [
    "xi4"
  ],
  "篚": [
    "fei3"
  ],
  "语气": [
    "yu3 qi4"
  ],
  "篥": [
    "li4"
  ],
  "虽然": [
    "sui1 ran2"
  ],
  "家喻户晓": [
    "jia1 yu4 hu4 xiao3"
  ],
  "泄露": [
    "xie4 lu4"
  ],
  "指教": [
    "zhi3 jiao4"
  ],
  "追踪": [
    "zhui1 zong1"
  ],
  "宪": [
    "xian4"
  ],
  "潇洒": [
    "xiao1 sa3"
  ],
  "卬": [
    "Ang2",
    "ang2"
  ],
  "琤": [
    "cheng1"
  ],
  "焦": [
    "Jiao1",
    "jiao1"
  ],
  "预计": [
    "yu4 ji4"
  ],
  "堂": [
    "tang2"
  ],
  "有毒": [
    "you3 du2"
  ],
  "爱好": [
    "ai4 hao4"
  ],
  "厅": [
    "ting1"
  ],
  "小声": [
    "xiao3 sheng1"
  ],
  "请问": [
    "qing3 wen4"
  ],
  "盛": [
    "Sheng4",
    "cheng2",
    "sheng4"
  ],
  "西装": [
    "xi1 zhuang1"
  ],
  "情侣": [
    "qing2 lu:3"
  ],
  "拿出": [
    "na2 chu1"
  ],
  "胩": [
    "ka3"
  ],
  "箫": [
    "xiao1"
  ],
  "蚌": [
    "Beng4",
    "bang4"
  ],
  "抢": [
    "qiang1",
    "qiang3"
  ],
  "惕": [
    "ti4"
  ],
  "挂念": [
    "gua4 nian4"
  ],
  "上次": [
    "shang4 ci4"
  ],
  "输送": [
    "shu1 song4"
  ],
  "嗒": [
    "da1",
    "ta4"
  ],
  "幅度": [
    "fu2 du4"
  ],
  "擤": [
    "xing3"
  ],
  "优越": [
    "you1 yue4"
  ],
  "上方": [
    "shang4 fang1"
  ],
  "发烧": [
    "fa1 shao1"
  ],
  "疑": [
    "yi2"
  ],
  "拉": [
    "la1"
  ],
  "深厚": [
    "shen1 hou4"
  ],
  "饭": [
    "fan4"
  ],
  "桓": [
    "Huan2",
    "huan2"
  ],
  "答应": [
    "da1 ying5"
  ],
  "朵": [
    "duo3",
    "duo3"
  ],
  "艺术": [
    "yi4 shu4"
  ],
  "叉子": [
    "cha1 zi5"
  ],
  "热带": [
    "re4 dai4"
  ],
  "评选": [
    "ping2 xuan3"
  ],
  "擅": [
    "shan4"
  ],
  "最终": [
    "zui4 zhong1"
  ],
  "揍": [
    "zou4"
  ],
  "岿": [
    "kui1"
  ],
  "沽名钓誉": [
    "gu1 ming2 diao4 yu4"
  ],
  "罽": [
    "ji4"
  ],
  "讲课": [
    "jiang3 ke4"
  ],
  "电讯": [
    "dian4 xun4"
  ],
  "蛊": [
    "gu3"
  ],
  "杆": [
    "gan1",
    "gan3"
  ],
  "当真": [
    "dang4 zhen1"
  ],
  "亮": [
    "liang4"
  ],
  "亲自": [
    "qin1 zi4"
  ],
  "话剧": [
    "hua4 ju4"
  ],
  "开办": [
    "kai1 ban4"
  ],
  "苟": [
    "Gou3",
    "gou3"
  ],
  "脞": [
    "cuo3"
  ],
  "鹢": [
    "yi4"
  ],
  "欺": [
    "qi1"
  ],
  "春节": [
    "Chun1 jie2"
  ],
  "人间": [
    "ren2 jian1"
  ],
  "娱乐": [
    "yu2 le4"
  ],
  "女婿": [
    "nu:3 xu5"
  ],
  "负有": [
    "fu4 you3"
  ],
  "在乎": [
    "zai4 hu5"
  ],
  "麻醉": [
    "ma2 zui4"
  ],
  "剧目": [
    "ju4 mu4"
  ],
  "碶": [
    null
  ],
  "谭": [
    "Tan2",
    "tan2"
  ],
  "铏": [
    "xing2"
  ],
  "钿": [
    "dian4",
    "tian2"
  ],
  "鲻": [
    "zi1"
  ],
  "凡": [
    "fan2",
    "fan2"
  ],
  "绝招": [
    "jue2 zhao1"
  ],
  "交替": [
    "jiao1 ti4"
  ],
  "敉": [
    "mi3"
  ],
  "美丽": [
    "mei3 li4"
  ],
  "珍重": [
    "zhen1 zhong4"
  ],
  "埠": [
    "bu4"
  ],
  "痰": [
    "tan2"
  ],
  "上课": [
    "shang4 ke4"
  ],
  "浓郁": [
    "nong2 yu4"
  ],
  "称号": [
    "cheng1 hao4"
  ],
  "双方": [
    "shuang1 fang1"
  ],
  "素不相识": [
    "su4 bu4 xiang1 shi2"
  ],
  "牒": [
    "die2"
  ],
  "法制": [
    "fa3 zhi4",
    "Fa3 zhi4"
  ],
  "蚆": [
    "ba1"
  ],
  "对联": [
    "dui4 lian2"
  ],
  "招": [
    "zhao1"
  ],
  "起到": [
    "qi3 dao4"
  ],
  "名人": [
    "ming2 ren2"
  ],
  "磡": [
    "kan4"
  ],
  "鼯": [
    "wu2"
  ],
  "璲": [
    "sui4"
  ],
  "事实": [
    "shi4 shi2"
  ],
  "脂肪": [
    "zhi1 fang2"
  ],
  "馉": [
    "gu3"
  ],
  "壮实": [
    "zhuang4 shi5"
  ],
  "通畅": [
    "tong1 chang4"
  ],
  "钥": [
    "yue4"
  ],
  "界": [
    "jie4"
  ],
  "链": [
    "lian4"
  ],
  "均匀": [
    "jun1 yun2"
  ],
  "咽": [
    "yan1",
    "yan4",
    "ye4",
    "yan4"
  ],
  "涪": [
    "Fu2"
  ],
  "贻": [
    "yi2"
  ],
  "网址": [
    "wang3 zhi3"
  ],
  "时常": [
    "shi2 chang2"
  ],
  "体重": [
    "ti3 zhong4"
  ],
  "别提了": [
    "bie2 ti2 le5"
  ],
  "鞫": [
    "ju1"
  ],
  "蜕": [
    "tui4"
  ],
  "莼": [
    "chun2",
    "chun2"
  ],
  "根深蒂固": [
    "gen1 shen1 di4 gu4"
  ],
  "仙鹤": [
    "xian1 he4"
  ],
  "憨": [
    "han1"
  ],
  "祈祷": [
    "qi2 dao3"
  ],
  "随即": [
    "sui2 ji2"
  ],
  "运作": [
    "yun4 zuo4"
  ],
  "行走": [
    "xing2 zou3"
  ],
  "钹": [
    "bo2"
  ],
  "投入": [
    "tou2 ru4"
  ],
  "家里": [
    "jia1 li3"
  ],
  "杖": [
    "zhang4"
  ],
  "髫": [
    "tiao2"
  ],
  "咻": [
    "xiu1"
  ],
  "强加": [
    "qiang2 jia1"
  ],
  "顺应": [
    "shun4 ying4"
  ],
  "歆": [
    "xin1"
  ],
  "紊": [
    "wen3"
  ],
  "有着": [
    "you3 zhe5"
  ],
  "汉字": [
    "han4 zi4"
  ],
  "鬷": [
    "zong1"
  ],
  "噇": [
    "chuang2"
  ],
  "至": [
    "zhi4"
  ],
  "工夫": [
    "gong1 fu1",
    "gong1 fu5"
  ],
  "古怪": [
    "gu3 guai4"
  ],
  "严密": [
    "yan2 mi4"
  ],
  "澂": [
    null
  ],
  "珣": [
    "xun2"
  ],
  "户外": [
    "hu4 wai4"
  ],
  "读者": [
    "du2 zhe3"
  ],
  "脉络": [
    "mai4 luo4"
  ],
  "册": [
    "ce4"
  ],
  "供求": [
    "gong1 qiu2"
  ],
  "纯净水": [
    "chun2 jing4 shui3"
  ],
  "异常": [
    "yi4 chang2"
  ],
  "用途": [
    "yong4 tu2"
  ],
  "蛰": [
    "zhe2"
  ],
  "萌": [
    "meng2"
  ],
  "茧": [
    "jian3",
    "jian3"
  ],
  "雕刻": [
    "diao1 ke4"
  ],
  "暴": [
    "Bao4",
    "bao4"
  ],
  "替身": [
    "ti4 shen1"
  ],
  "晁": [
    "Chao2"
  ],
  "出道": [
    "chu1 dao4"
  ],
  "白白": [
    "bai2 bai2"
  ],
  "埆": [
    "que4"
  ],
  "幽": [
    "you1"
  ],
  "部分": [
    "bu4 fen5"
  ],
  "向前": [
    "xiang4 qian2"
  ],
  "办法": [
    "ban4 fa3"
  ],
  "代价": [
    "dai4 jia4"
  ],
  "旋涡": [
    "xuan2 wo1"
  ],
  "未知数": [
    "wei4 zhi1 shu4"
  ],
  "曲折": [
    "qu1 zhe2"
  ],
  "康": [
    "Kang1",
    "kang1"
  ],
  "思路": [
    "si1 lu4"
  ],
  "财物": [
    "cai2 wu4"
  ],
  "岍": [
    "qian1"
  ],
  "癣": [
    "xuan3"
  ],
  "为了": [
    "wei4 le5"
  ],
  "枘": [
    "rui4"
  ],
  "胎儿": [
    "tai1 er2"
  ],
  "暹": [
    "xian1"
  ],
  "铲子": [
    "chan3 zi5"
  ],
  "数目": [
    "shu4 mu4"
  ],
  "变迁": [
    "bian4 qian1"
  ],
  "不屑": [
    "bu4 xie4"
  ],
  "绨": [
    "ti2"
  ],
  "怕": [
    "Pa4",
    "pa4"
  ],
  "逼迫": [
    "bi1 po4"
  ],
  "妒忌": [
    "du4 ji4"
  ],
  "嚷": [
    "rang3"
  ],
  "出生": [
    "chu1 sheng1"
  ],
  "浇": [
    "jiao1"
  ],
  "行使": [
    "xing2 shi3"
  ],
  "帙": [
    "Zhi4",
    "zhi4",
    "zhi4"
  ],
  "阏": [
    "e4",
    "yan1"
  ],
  "双重": [
    "shuang1 chong2"
  ],
  "缪": [
    "Miao4",
    "liao3",
    "miao4",
    "miu4",
    "mou2",
    "mu4"
  ],
  "记住": [
    "ji4 zhu5"
  ],
  "省": [
    "sheng3",
    "xing3"
  ],
  "迟到": [
    "chi2 dao4"
  ],
  "袖手旁观": [
    "xiu4 shou3 pang2 guan1"
  ],
  "值班": [
    "zhi2 ban1"
  ],
  "订婚": [
    "ding4 hun1"
  ],
  "榭": [
    "xie4"
  ],
  "档": [
    "dang3",
    "dang4"
  ],
  "旭日": [
    "xu4 ri4"
  ],
  "投机": [
    "tou2 ji1"
  ],
  "公车": [
    "gong1 che1"
  ],
  "检验": [
    "jian3 yan4"
  ],
  "砗": [
    "che1"
  ],
  "楼下": [
    "lou2 xia4"
  ],
  "人体": [
    "ren2 ti3"
  ],
  "傃": [
    null
  ],
  "走投无路": [
    "zou3 tou2 wu2 lu4"
  ],
  "狻": [
    "suan1"
  ],
  "气愤": [
    "qi4 fen4"
  ],
  "膜": [
    "mo2"
  ],
  "勇敢": [
    "yong3 gan3"
  ],
  "涧": [
    "jian4",
    "jian4"
  ],
  "宛": [
    "Wan3",
    "wan3"
  ],
  "璐": [
    "lu4"
  ],
  "例如": [
    "li4 ru2"
  ],
  "防护": [
    "fang2 hu4"
  ],
  "复查": [
    "fu4 cha2"
  ],
  "无效": [
    "wu2 xiao4"
  ],
  "螽": [
    "zhong1"
  ],
  "莓": [
    "mei2"
  ],
  "筵": [
    "yan2"
  ],
  "篝": [
    "gou1"
  ],
  "秘": [
    "mi4",
    "bi4",
    "mi4"
  ],
  "项目": [
    "xiang4 mu4"
  ],
  "攻关": [
    "gong1 guan1"
  ],
  "凶": [
    "xiong1",
    "xiong1"
  ],
  "吓": [
    "he4",
    "xia4"
  ],
  "嘛": [
    "ma2",
    "ma5"
  ],
  "笔记本": [
    "bi3 ji4 ben3"
  ],
  "症状": [
    "zheng4 zhuang4"
  ],
  "笙": [
    "sheng1"
  ],
  "花费": [
    "hua1 fei4"
  ],
  "门铃": [
    "men2 ling2"
  ],
  "肽": [
    "tai4"
  ],
  "投资": [
    "tou2 zi1"
  ],
  "玤": [
    "bang4"
  ],
  "徒": [
    "Tu2",
    "tu2"
  ],
  "缸": [
    "gang1"
  ],
  "氍": [
    "qu2"
  ],
  "颤": [
    "chan4"
  ],
  "钺": [
    "yue4"
  ],
  "成家": [
    "cheng2 jia1"
  ],
  "阻碍": [
    "zu3 ai4"
  ],
  "损": [
    "sun3"
  ],
  "侦": [
    "zhen1",
    "zhen1"
  ],
  "好运": [
    "hao3 yun4"
  ],
  "敩": [
    null
  ],
  "风浪": [
    "feng1 lang4"
  ],
  "累": [
    "lei3",
    "lei4",
    "Lei2",
    "lei2"
  ],
  "艳丽": [
    "yan4 li4"
  ],
  "獍": [
    "jing4"
  ],
  "范围": [
    "fan4 wei2"
  ],
  "疼": [
    "teng2"
  ],
  "斌": [
    "bin1"
  ],
  "主体": [
    "zhu3 ti3"
  ],
  "流畅": [
    "liu2 chang4"
  ],
  "楝": [
    "lian4"
  ],
  "不足": [
    "bu4 zu2"
  ],
  "璨": [
    "can4"
  ],
  "点子": [
    "dian3 zi5"
  ],
  "登陆": [
    "deng1 lu4"
  ],
  "千军万马": [
    "qian1 jun1 wan4 ma3"
  ],
  "端正": [
    "duan1 zheng4"
  ],
  "贩卖": [
    "fan4 mai4"
  ],
  "体操": [
    "ti3 cao1"
  ],
  "挨": [
    "ai1",
    "ai2"
  ],
  "大约": [
    "da4 yue1"
  ],
  "画面": [
    "hua4 mian4"
  ],
  "芰": [
    "ji4"
  ],
  "顽强": [
    "wan2 qiang2"
  ],
  "酸奶": [
    "suan1 nai3"
  ],
  "告诫": [
    "gao4 jie4"
  ],
  "全身": [
    "quan2 shen1"
  ],
  "参与": [
    "can1 yu4"
  ],
  "闭幕式": [
    "bi4 mu4 shi4"
  ],
  "和气": [
    "he2 qi5"
  ],
  "亲朋好友": [
    "qin1 peng2 hao3 you3"
  ],
  "宴": [
    "yan4",
    "yan4"
  ],
  "洵": [
    "xun2"
  ],
  "攥": [
    "zuan4"
  ],
  "崛": [
    "jue2"
  ],
  "女": [
    "nu:3",
    "ru3"
  ],
  "村": [
    "cun1",
    "cun1"
  ],
  "得当": [
    "de2 dang4"
  ],
  "树立": [
    "shu4 li4"
  ],
  "标致": [
    "Biao1 zhi4",
    "biao1 zhi5"
  ],
  "谦逊": [
    "qian1 xun4"
  ],
  "释放": [
    "shi4 fang4"
  ],
  "中午": [
    "zhong1 wu3"
  ],
  "衽": [
    "ren4",
    "ren4"
  ],
  "唏": [
    "xi1"
  ],
  "麒": [
    "qi2"
  ],
  "山区": [
    "shan1 qu1"
  ],
  "微信": [
    "Wei1 xin4"
  ],
  "全方位": [
    "quan2 fang1 wei4"
  ],
  "包扎": [
    "bao1 za1",
    "bao1 za1"
  ],
  "荡": [
    "dang4",
    "tang4",
    "dang4"
  ],
  "或许": [
    "huo4 xu3"
  ],
  "蓂": [
    "ming2"
  ],
  "探求": [
    "tan4 qiu2"
  ],
  "刷": [
    "shua1",
    "shua4"
  ],
  "仉": [
    "Zhang3"
  ],
  "严": [
    "Yan2",
    "yan2"
  ],
  "巡逻": [
    "xun2 luo2"
  ],
  "胶水": [
    "jiao1 shui3"
  ],
  "预约": [
    "yu4 yue1"
  ],
  "下午": [
    "xia4 wu3"
  ],
  "仅次于": [
    "jin3 ci4 yu2"
  ],
  "体检": [
    "ti3 jian3"
  ],
  "例外": [
    "li4 wai4"
  ],
  "那会儿": [
    "na4 hui4 r5"
  ],
  "登": [
    "deng1"
  ],
  "片子": [
    "pian1 zi5",
    "pian4 zi5"
  ],
  "嘉": [
    "Jia1",
    "jia1"
  ],
  "芹": [
    "qin2"
  ],
  "渌": [
    "lu4"
  ],
  "铱": [
    "yi1"
  ],
  "蜞": [
    "qi2"
  ],
  "凇": [
    "song1"
  ],
  "失踪": [
    "shi1 zong1"
  ],
  "其间": [
    "qi2 jian1"
  ],
  "居民": [
    "ju1 min2"
  ],
  "甄": [
    "Zhen1",
    "zhen1"
  ],
  "搭配": [
    "da1 pei4"
  ],
  "鄢": [
    "Yan1"
  ],
  "作风": [
    "zuo4 feng1"
  ],
  "顾不得": [
    "gu4 bu5 de5"
  ],
  "小卒": [
    "xiao3 zu2"
  ],
  "物流": [
    "wu4 liu2"
  ],
  "羞": [
    "xiu1"
  ],
  "萑": [
    "huan2"
  ],
  "思前想后": [
    "si1 qian2 xiang3 hou4"
  ],
  "演变": [
    "yan3 bian4"
  ],
  "纣": [
    "Zhou4",
    "zhou4"
  ],
  "葱": [
    "cong1"
  ],
  "惩": [
    "cheng2"
  ],
  "鲥": [
    "shi2"
  ],
  "社论": [
    "she4 lun4"
  ],
  "汇报": [
    "hui4 bao4",
    "hui4 bao4"
  ],
  "礴": [
    "bo2"
  ],
  "错误": [
    "cuo4 wu4"
  ],
  "飘": [
    "piao1",
    "piao1"
  ],
  "比赛": [
    "bi3 sai4"
  ],
  "瓘": [
    "guan4"
  ],
  "笥": [
    "si4"
  ],
  "适宜": [
    "shi4 yi2"
  ],
  "理所当然": [
    "li3 suo3 dang1 ran2"
  ],
  "误解": [
    "wu4 jie3"
  ],
  "广告": [
    "guang3 gao4"
  ],
  "松弛": [
    "song1 chi2"
  ],
  "椹": [
    "shen4"
  ],
  "相伴": [
    "xiang1 ban4"
  ],
  "悠": [
    "you1"
  ],
  "出难题": [
    "chu1 nan2 ti2"
  ],
  "惠": [
    "Hui4",
    "hui4"
  ],
  "得出": [
    "de2 chu1"
  ],
  "跳舞": [
    "tiao4 wu3"
  ],
  "泃": [
    "Ju1"
  ],
  "妗": [
    "jin4"
  ],
  "动身": [
    "dong4 shen1"
  ],
  "主人": [
    "zhu3 ren2"
  ],
  "大惊小怪": [
    "da4 jing1 xiao3 guai4"
  ],
  "中医": [
    "zhong1 yi1"
  ],
  "要求": [
    "yao1 qiu2"
  ],
  "霄": [
    "xiao1"
  ],
  "柢": [
    "di3"
  ],
  "喝": [
    "he1",
    "he4"
  ],
  "望远镜": [
    "wang4 yuan3 jing4"
  ],
  "扂": [
    "dian4"
  ],
  "粝": [
    "li4"
  ],
  "从早到晚": [
    "cong2 zao3 dao4 wan3"
  ],
  "檬": [
    "meng2"
  ],
  "岨": [
    "qu1"
  ],
  "日程": [
    "ri4 cheng2"
  ],
  "弱": [
    "ruo4"
  ],
  "妹妹": [
    "mei4 mei5"
  ],
  "垓": [
    "gai1"
  ],
  "对方": [
    "dui4 fang1"
  ],
  "困惑": [
    "kun4 huo4"
  ],
  "锅": [
    "guo1"
  ],
  "蛾": [
    "e2"
  ],
  "邪恶": [
    "xie2 e4"
  ],
  "漦": [
    "li2"
  ],
  "艰险": [
    "jian1 xian3"
  ],
  "自然界": [
    "zi4 ran2 jie4"
  ],
  "簌": [
    "su4"
  ],
  "降价": [
    "jiang4 jia4"
  ],
  "毒": [
    "du2"
  ],
  "晕车": [
    "yun4 che1"
  ],
  "留念": [
    "liu2 nian4"
  ],
  "猡": [
    "luo2"
  ],
  "重返": [
    "chong2 fan3"
  ],
  "大师": [
    "da4 shi1"
  ],
  "否": [
    "fou3",
    "pi3"
  ],
  "衣食住行": [
    "yi1 shi2 zhu4 xing2"
  ],
  "怠慢": [
    "dai4 man4"
  ],
  "水管": [
    "shui3 guan3"
  ],
  "啦啦队": [
    "la1 la1 dui4"
  ],
  "深深": [
    "shen1 shen1"
  ],
  "荬": [
    "mai3"
  ],
  "邲": [
    "Bi4"
  ],
  "腒": [
    "ju1"
  ],
  "害": [
    "hai4"
  ],
  "职业": [
    "zhi2 ye4"
  ],
  "迟疑": [
    "chi2 yi2"
  ],
  "优良": [
    "you1 liang2"
  ],
  "槊": [
    "shuo4"
  ],
  "窒": [
    "zhi4"
  ],
  "话费": [
    "hua4 fei4"
  ],
  "认出": [
    "ren4 chu1"
  ],
  "渗透": [
    "shen4 tou4"
  ],
  "竟然": [
    "jing4 ran2"
  ],
  "潮流": [
    "chao2 liu2"
  ],
  "黑手": [
    "hei1 shou3"
  ],
  "弯": [
    "wan1"
  ],
  "眦": [
    "zi4",
    "zi4"
  ],
  "随便": [
    "sui2 bian4"
  ],
  "自学": [
    "zi4 xue2"
  ],
  "本期": [
    "ben3 qi1"
  ],
  "渤": [
    "Bo2"
  ],
  "怿": [
    "yi4"
  ],
  "顾虑": [
    "gu4 lu:4"
  ],
  "男": [
    "nan2"
  ],
  "立刻": [
    "li4 ke4"
  ],
  "仿佛": [
    "fang3 fu2",
    "fang3 fu2"
  ],
  "落地": [
    "luo4 di4"
  ],
  "洒": [
    "sa3"
  ],
  "奠定": [
    "dian4 ding4"
  ],
  "趣味": [
    "qu4 wei4"
  ],
  "诊所": [
    "zhen3 suo3"
  ],
  "鲝": [
    "zha3"
  ],
  "输出": [
    "shu1 chu1"
  ],
  "甜美": [
    "tian2 mei3"
  ],
  "苷": [
    "gan1"
  ],
  "嚓": [
    "ca1",
    "cha1"
  ],
  "忠贞": [
    "zhong1 zhen1"
  ],
  "坐落": [
    "zuo4 luo4"
  ],
  "旺": [
    "wang4"
  ],
  "珲": [
    "hun2"
  ],
  "转向": [
    "zhuan3 xiang4",
    "zhuan4 xiang4"
  ],
  "搅拌": [
    "jiao3 ban4"
  ],
  "一路": [
    "yi1 lu4"
  ],
  "机构": [
    "ji1 gou4"
  ],
  "颠覆": [
    "dian1 fu4"
  ],
  "用人": [
    "yong4 ren2"
  ],
  "时速": [
    "shi2 su4"
  ],
  "淙": [
    "cong2"
  ],
  "公务": [
    "gong1 wu4"
  ],
  "日期": [
    "ri4 qi1"
  ],
  "穴位": [
    "xue2 wei4"
  ],
  "奇怪": [
    "qi2 guai4"
  ],
  "正确": [
    "zheng4 que4"
  ],
  "边境": [
    "bian1 jing4"
  ],
  "相当": [
    "xiang1 dang1"
  ],
  "窜": [
    "cuan4"
  ],
  "讲": [
    "jiang3"
  ],
  "废品": [
    "fei4 pin3"
  ],
  "疗法": [
    "liao2 fa3"
  ],
  "轮船": [
    "lun2 chuan2"
  ],
  "了不起": [
    "liao3 bu5 qi3"
  ],
  "旅": [
    "lu:3"
  ],
  "娃": [
    "wa2"
  ],
  "苄": [
    "bian4"
  ],
  "穿": [
    "chuan1"
  ],
  "称为": [
    "cheng1 wei2"
  ],
  "秀": [
    "xiu4"
  ],
  "按摩": [
    "an4 mo2"
  ],
  "沫": [
    "mo4"
  ],
  "机舱": [
    "ji1 cang1"
  ],
  "之类": [
    "zhi1 lei4"
  ],
  "胆": [
    "dan3"
  ],
  "牛奶": [
    "niu2 nai3"
  ],
  "谈判": [
    "tan2 pan4"
  ],
  "交响乐": [
    "jiao1 xiang3 yue4"
  ],
  "荒": [
    "huang1"
  ],
  "保密": [
    "bao3 mi4"
  ],
  "靓": [
    "jing4",
    "liang4"
  ],
  "可耻": [
    "ke3 chi3"
  ],
  "交": [
    "jiao1"
  ],
  "别扭": [
    "bie4 niu5"
  ],
  "起": [
    "qi3"
  ],
  "充沛": [
    "chong1 pei4"
  ],
  "一刹那": [
    "yi1 cha4 na4"
  ],
  "哗变": [
    "hua2 bian4"
  ],
  "决不": [
    "jue2 bu4"
  ],
  "扩大": [
    "kuo4 da4"
  ],
  "脍": [
    "kuai4"
  ],
  "窎": [
    "diao4"
  ],
  "穗": [
    "Sui4",
    "sui4"
  ],
  "警察": [
    "jing3 cha2"
  ],
  "杠": [
    "gang1",
    "gang4",
    "gang4"
  ],
  "预备": [
    "yu4 bei4"
  ],
  "冱": [
    "hu4"
  ],
  "膊": [
    "bo2"
  ],
  "葡萄": [
    "pu2 tao5"
  ],
  "斑": [
    "ban1"
  ],
  "相声": [
    "xiang4 sheng5"
  ],
  "喔": [
    "o1",
    "o5",
    "wo1"
  ],
  "惭愧": [
    "can2 kui4"
  ],
  "菌": [
    "jun1",
    "jun4"
  ],
  "砘": [
    "dun4"
  ],
  "乒": [
    "ping1"
  ],
  "意识": [
    "yi4 shi2"
  ],
  "抬": [
    "tai2",
    "tai2"
  ],
  "历程": [
    "li4 cheng2"
  ],
  "歹徒": [
    "dai3 tu2"
  ],
  "镒": [
    "yi4"
  ],
  "缓和": [
    "huan3 he2"
  ],
  "滑雪": [
    "hua2 xue3"
  ],
  "扮": [
    "ban4"
  ],
  "峃": [
    "xue2"
  ],
  "套": [
    "tao4"
  ],
  "灾区": [
    "zai1 qu1"
  ],
  "媳": [
    "xi2"
  ],
  "点心": [
    "dian3 xin5"
  ],
  "洿": [
    "wu1"
  ],
  "赉": [
    "lai4"
  ],
  "不能不": [
    "bu4 neng2 bu4"
  ],
  "前进": [
    "qian2 jin4"
  ],
  "襻": [
    "pan4"
  ],
  "喧": [
    "xuan1",
    "xuan1"
  ],
  "一声不吭": [
    "yi1 sheng1 bu4 keng1"
  ],
  "桥": [
    "qiao2"
  ],
  "怊": [
    "chao1"
  ],
  "推卸": [
    "tui1 xie4"
  ],
  "餐饮": [
    "can1 yin3"
  ],
  "於": [
    "Yu1",
    "wu1"
  ],
  "粥": [
    "yu4",
    "zhou1"
  ],
  "畈": [
    "fan4"
  ],
  "明朗": [
    "ming2 lang3"
  ],
  "教室": [
    "jiao4 shi4"
  ],
  "别的": [
    "bie2 de5"
  ],
  "簸": [
    "bo3",
    "bo4"
  ],
  "愈来愈": [
    "yu4 lai2 yu4"
  ],
  "特性": [
    "te4 xing4"
  ],
  "孪": [
    "luan2"
  ],
  "祠": [
    "ci2"
  ],
  "煲": [
    "bao1"
  ],
  "谍": [
    "die2"
  ],
  "分钟": [
    "fen1 zhong1"
  ],
  "恶劣": [
    "e4 lie4"
  ],
  "得意扬扬": [
    "de2 yi4 yang2 yang2"
  ],
  "破解": [
    "po4 jie3"
  ],
  "戣": [
    "kui2"
  ],
  "放到": [
    null
  ],
  "直": [
    "Zhi2",
    "zhi2"
  ],
  "栏目": [
    "lan2 mu4"
  ],
  "谇": [
    "sui4"
  ],
  "遹": [
    "Yu4",
    "yu4"
  ],
  "酏": [
    "yi2"
  ],
  "纪": [
    "Ji3",
    "ji4"
  ],
  "顸": [
    "han1"
  ],
  "听取": [
    "ting1 qu3"
  ],
  "崽": [
    "zai3"
  ],
  "装扮": [
    "zhuang1 ban4"
  ],
  "㫰": [
    "lang4"
  ],
  "针对": [
    "zhen1 dui4"
  ],
  "详尽": [
    "xiang2 jin4"
  ],
  "镳": [
    "biao1"
  ],
  "操纵": [
    "cao1 zong4"
  ],
  "仿制": [
    "fang3 zhi4"
  ],
  "访问": [
    "fang3 wen4"
  ],
  "趴": [
    "pa1"
  ],
  "古典": [
    "gu3 dian3"
  ],
  "良性": [
    "liang2 xing4"
  ],
  "豇": [
    "jiang1"
  ],
  "责怪": [
    "ze2 guai4"
  ],
  "瑔": [
    "quan2"
  ],
  "因而": [
    "yin1 er2"
  ],
  "刚毅": [
    "gang1 yi4"
  ],
  "下台": [
    "xia4 tai2"
  ],
  "飙升": [
    "biao1 sheng1"
  ],
  "踏上": [
    "ta4 shang4"
  ],
  "除了": [
    "chu2 le5"
  ],
  "可悲": [
    "ke3 bei1"
  ],
  "基本功": [
    "ji1 ben3 gong1"
  ],
  "跶": [
    "da1"
  ],
  "胡说": [
    "hu2 shuo1"
  ],
  "违规": [
    "wei2 gui1"
  ],
  "暗": [
    "an4",
    "an4",
    "an4"
  ],
  "有望": [
    "you3 wang4"
  ],
  "局长": [
    "ju2 zhang3"
  ],
  "犯": [
    "fan4"
  ],
  "跳高": [
    "tiao4 gao1"
  ],
  "冥": [
    "ming2",
    "ming2"
  ],
  "价格": [
    "jia4 ge2"
  ],
  "奔赴": [
    "ben1 fu4"
  ],
  "瞄": [
    "miao2"
  ],
  "螯": [
    "ao2"
  ],
  "低下": [
    "di1 xia4"
  ],
  "完蛋": [
    "wan2 dan4"
  ],
  "哆": [
    "duo1"
  ],
  "踏实": [
    "ta1 shi5"
  ],
  "葡": [
    "Pu2",
    "pu2"
  ],
  "疱": [
    "pao4"
  ],
  "偭": [
    "mian3"
  ],
  "舣": [
    "yi3"
  ],
  "公共汽车": [
    "gong1 gong4 qi4 che1"
  ],
  "好人": [
    "hao3 ren2"
  ],
  "占": [
    "zhan4",
    "zhan1",
    "zhan4"
  ],
  "善": [
    "shan4"
  ],
  "拿": [
    "na2",
    "na2",
    "na2"
  ],
  "蛎": [
    "li4"
  ],
  "黼": [
    "fu3"
  ],
  "炕": [
    "kang4",
    "kang4"
  ],
  "搞": [
    "gao3"
  ],
  "画册": [
    "hua4 ce4"
  ],
  "鄑": [
    "zi1"
  ],
  "搞笑": [
    "gao3 xiao4"
  ],
  "嘻": [
    "xi1",
    "xi1"
  ],
  "训练": [
    "xun4 lian4"
  ],
  "贺卡": [
    "he4 ka3"
  ],
  "厚": [
    "hou4"
  ],
  "奁": [
    "lian2",
    "lian2",
    "lian2",
    "lian2"
  ],
  "默读": [
    "mo4 du2"
  ],
  "蓬勃": [
    "peng2 bo2"
  ],
  "珛": [
    "xiu4"
  ],
  "故乡": [
    "gu4 xiang1"
  ],
  "晚间": [
    "wan3 jian1"
  ],
  "窿": [
    "long2"
  ],
  "躞": [
    "xie4"
  ],
  "筐": [
    "kuang1"
  ],
  "旁观": [
    "pang2 guan1"
  ],
  "回顾": [
    "hui2 gu4"
  ],
  "楞": [
    "leng2",
    "leng4"
  ],
  "新奇": [
    "xin1 qi2"
  ],
  "沨": [
    "feng1"
  ],
  "自私自利": [
    "zi4 si1 zi4 li4"
  ],
  "唠叨": [
    "lao2 dao5"
  ],
  "夼": [
    "kuang3"
  ],
  "省钱": [
    "sheng3 qian2"
  ],
  "埋": [
    "mai2",
    "man2"
  ],
  "澎": [
    "peng2"
  ],
  "偶像": [
    "ou3 xiang4"
  ],
  "平方": [
    "ping2 fang1"
  ],
  "缑": [
    "Gou1",
    "gou1"
  ],
  "毙": [
    "bi4",
    "bi4"
  ],
  "钔": [
    "men2"
  ],
  "吼": [
    "hou3"
  ],
  "弭": [
    "mi3"
  ],
  "浮躁": [
    "fu2 zao4"
  ],
  "恨": [
    "hen4"
  ],
  "订立": [
    "ding4 li4"
  ],
  "强壮": [
    "qiang2 zhuang4"
  ],
  "惊心动魄": [
    "jing1 xin1 dong4 po4"
  ],
  "狭隘": [
    "xia2 ai4"
  ],
  "压倒": [
    "ya1 dao3"
  ],
  "徨": [
    "huang2"
  ],
  "演唱会": [
    "yan3 chang4 hui4"
  ],
  "比方": [
    "bi3 fang5"
  ],
  "雇": [
    "gu4",
    "gu4"
  ],
  "喉咙": [
    "hou2 long2"
  ],
  "打仗": [
    "da3 zhang4"
  ],
  "吲": [
    "yin3"
  ],
  "瞅": [
    "chou3",
    "chou3"
  ],
  "柑": [
    "gan1"
  ],
  "燃烧": [
    "ran2 shao1"
  ],
  "干事": [
    "gan4 shi5"
  ],
  "徐徐": [
    "xu2 xu2"
  ],
  "乞讨": [
    "qi3 tao3"
  ],
  "尾声": [
    "wei3 sheng1"
  ],
  "荪": [
    "sun1"
  ],
  "批判": [
    "pi1 pan4"
  ],
  "爆发": [
    "bao4 fa1"
  ],
  "遛": [
    "liu4"
  ],
  "车": [
    "Che1",
    "che1",
    "ju1"
  ],
  "耇": [
    "gou3"
  ],
  "精打细算": [
    "jing1 da3 xi4 suan4"
  ],
  "单一": [
    "dan1 yi1"
  ],
  "鄂": [
    "E4"
  ],
  "截": [
    "jie2"
  ],
  "日前": [
    "ri4 qian2"
  ],
  "龅": [
    "bao1"
  ],
  "茴": [
    "hui2"
  ],
  "侵害": [
    "qin1 hai4"
  ],
  "票房": [
    "piao4 fang2"
  ],
  "牙": [
    "ya2"
  ],
  "传达": [
    "chuan2 da2"
  ],
  "络绎不绝": [
    "luo4 yi4 bu4 jue2"
  ],
  "过于": [
    "guo4 yu2"
  ],
  "文字": [
    "wen2 zi4"
  ],
  "潸": [
    "shan1"
  ],
  "言行": [
    "yan2 xing2"
  ],
  "电车": [
    "dian4 che1"
  ],
  "本色": [
    "ben3 se4",
    "ben3 shai3"
  ],
  "联盟": [
    "lian2 meng2"
  ],
  "用得着": [
    "yong4 de5 zhao2"
  ],
  "不仅": [
    "bu4 jin3"
  ],
  "出色": [
    "chu1 se4"
  ],
  "喜欢": [
    "xi3 huan5"
  ],
  "如此": [
    "ru2 ci3"
  ],
  "准许": [
    "zhun3 xu3"
  ],
  "莴": [
    "wo1"
  ],
  "关爱": [
    "guan1 ai4"
  ],
  "许多": [
    "xu3 duo1"
  ],
  "富裕": [
    "Fu4 yu4",
    "fu4 yu4"
  ],
  "初等": [
    "chu1 deng3"
  ],
  "年限": [
    "nian2 xian4"
  ],
  "疍": [
    "Dan4"
  ],
  "昆虫": [
    "kun1 chong2"
  ],
  "符": [
    "Fu2",
    "fu2"
  ],
  "实际": [
    "shi2 ji4"
  ],
  "定论": [
    "ding4 lun4"
  ],
  "配送": [
    "pei4 song4"
  ],
  "照常": [
    "zhao4 chang2"
  ],
  "骶": [
    "di3"
  ],
  "时间": [
    "shi2 jian1"
  ],
  "锻炼": [
    "duan4 lian4"
  ],
  "打架": [
    "da3 jia4"
  ],
  "油画": [
    "you2 hua4"
  ],
  "压缩": [
    "ya1 suo1"
  ],
  "药材": [
    "yao4 cai2"
  ],
  "利息": [
    "li4 xi1"
  ],
  "蹿": [
    "cuan1"
  ],
  "下边": [
    "xia4 bian5"
  ],
  "促成": [
    "cu4 cheng2"
  ],
  "中级": [
    "zhong1 ji2"
  ],
  "入口": [
    "ru4 kou3"
  ],
  "境地": [
    "jing4 di4"
  ],
  "手法": [
    "shou3 fa3"
  ],
  "外星人": [
    "wai4 xing1 ren2"
  ],
  "英语": [
    "Ying1 yu3"
  ],
  "失效": [
    "shi1 xiao4"
  ],
  "走廊": [
    "zou3 lang2"
  ],
  "匪": [
    "fei3"
  ],
  "板块": [
    "ban3 kuai4"
  ],
  "钚": [
    "bu4"
  ],
  "冰": [
    "bing1",
    "bing1"
  ],
  "好像": [
    "hao3 xiang4"
  ],
  "悫": [
    "que4",
    "que4"
  ],
  "空隙": [
    "kong4 xi4"
  ],
  "聘请": [
    "pin4 qing3"
  ],
  "大姐": [
    "da4 jie3"
  ],
  "终生": [
    "zhong1 sheng1"
  ],
  "鸫": [
    "dong1"
  ],
  "燏": [
    "yu4"
  ],
  "头衔": [
    "tou2 xian2"
  ],
  "援": [
    "yuan2"
  ],
  "坚守": [
    "jian1 shou3"
  ],
  "合唱": [
    "he2 chang4"
  ],
  "诘": [
    "jie2"
  ],
  "致辞": [
    "zhi4 ci2"
  ],
  "冷笑": [
    "leng3 xiao4"
  ],
  "复合": [
    "fu4 he2",
    "fu4 he2"
  ],
  "瘵": [
    "zhai4"
  ],
  "萍": [
    "ping2"
  ],
  "精神病": [
    "jing1 shen2 bing4"
  ],
  "舵": [
    "duo4"
  ],
  "南边": [
    "nan2 bian5"
  ],
  "澜": [
    "lan2"
  ],
  "无敌": [
    "wu2 di2"
  ],
  "撖": [
    "Han4"
  ],
  "搪": [
    "tang2"
  ],
  "后盾": [
    "hou4 dun4"
  ],
  "偬": [
    "zong3"
  ],
  "嚅": [
    "ru2"
  ],
  "学习": [
    "xue2 xi2"
  ],
  "澄清": [
    "cheng2 qing1",
    "deng4 qing1"
  ],
  "狡猾": [
    "jiao3 hua2"
  ],
  "踝": [
    "huai2"
  ],
  "挂失": [
    "gua4 shi1"
  ],
  "汽车": [
    "qi4 che1"
  ],
  "祝贺": [
    "zhu4 he4"
  ],
  "沭": [
    "shu4"
  ],
  "正版": [
    "zheng4 ban3"
  ],
  "售票": [
    "shou4 piao4"
  ],
  "赶快": [
    "gan3 kuai4"
  ],
  "城乡": [
    "cheng2 xiang1"
  ],
  "娇": [
    "jiao1"
  ],
  "星期日": [
    "Xing1 qi1 ri4"
  ],
  "匠": [
    "jiang4"
  ],
  "杕": [
    "di4"
  ],
  "嬴": [
    "Ying2",
    "ying2"
  ],
  "华裔": [
    "Hua2 yi4"
  ],
  "魅力": [
    "mei4 li4"
  ],
  "长期以来": [
    "chang2 qi1 yi3 lai2"
  ],
  "描": [
    "miao2"
  ],
  "国王": [
    "guo2 wang2"
  ],
  "茄": [
    "jia1",
    "qie2"
  ],
  "夭": [
    "yao1",
    "yao1"
  ],
  "绵": [
    "mian2",
    "mian2"
  ],
  "河畔": [
    "he2 pan4"
  ],
  "天鹅": [
    "tian1 e2"
  ],
  "吡": [
    "bi3"
  ],
  "鲕": [
    "er2"
  ],
  "旆": [
    "pei4"
  ],
  "镶嵌": [
    "xiang1 qian4"
  ],
  "泱": [
    "yang1"
  ],
  "话语": [
    "hua4 yu3"
  ],
  "浜": [
    "bang1",
    "bin1"
  ],
  "嵌": [
    "kan3",
    "qian4"
  ],
  "必不可少": [
    "bi4 bu4 ke3 shao3"
  ],
  "污": [
    "wu1",
    "wu1"
  ],
  "学说": [
    "xue2 shuo1"
  ],
  "胧": [
    "long2"
  ],
  "匏": [
    "pao2"
  ],
  "暄": [
    "xuan1"
  ],
  "跳跃": [
    "tiao4 yue4"
  ],
  "尤其": [
    "you2 qi2"
  ],
  "电视剧": [
    "dian4 shi4 ju4"
  ],
  "鸬": [
    "lu2"
  ],
  "谤": [
    "bang4"
  ],
  "掂": [
    "dian1"
  ],
  "㳇": [
    "fu4"
  ],
  "从未": [
    "cong2 wei4"
  ],
  "蔼": [
    "ai3"
  ],
  "婞": [
    "xing4"
  ],
  "婕": [
    "jie2"
  ],
  "报": [
    "bao4"
  ],
  "坦": [
    "tan3"
  ],
  "亏": [
    "kui1"
  ],
  "背后": [
    "bei4 hou4"
  ],
  "保护": [
    "bao3 hu4"
  ],
  "估算": [
    "gu1 suan4"
  ],
  "天下": [
    "tian1 xia4"
  ],
  "胍": [
    "gua1"
  ],
  "发型": [
    "fa4 xing2"
  ],
  "物业": [
    "wu4 ye4"
  ],
  "冖": [
    "mi4"
  ],
  "球": [
    "qiu2"
  ],
  "急剧": [
    "ji2 ju4"
  ],
  "奖杯": [
    "jiang3 bei1"
  ],
  "干净": [
    "gan1 jing4"
  ],
  "颞": [
    "nie4"
  ],
  "餐馆": [
    "can1 guan3"
  ],
  "有点儿": [
    "you3 dian3 r5"
  ],
  "蓄": [
    "xu4"
  ],
  "提示": [
    "ti2 shi4"
  ],
  "侘": [
    "cha4"
  ],
  "官方": [
    "guan1 fang1"
  ],
  "拟定": [
    "ni3 ding4"
  ],
  "邽": [
    "Gui1"
  ],
  "卡通": [
    "ka3 tong1"
  ],
  "趸": [
    "dun3"
  ],
  "婫": [
    null
  ],
  "摄影": [
    "she4 ying3"
  ],
  "铵": [
    "an3"
  ],
  "溹": [
    null
  ],
  "梁": [
    "Liang2",
    "liang2",
    "liang2"
  ],
  "困难": [
    "kun4 nan5"
  ],
  "人力": [
    "ren2 li4"
  ],
  "船桨": [
    "chuan2 jiang3"
  ],
  "一齐": [
    "yi1 qi2"
  ],
  "补偿": [
    "bu3 chang2"
  ],
  "刮风": [
    "gua1 feng1"
  ],
  "皱": [
    "zhou4"
  ],
  "敦厚": [
    "dun1 hou4"
  ],
  "圮": [
    "pi3"
  ],
  "衰竭": [
    "shuai1 jie2"
  ],
  "游船": [
    "you2 chuan2"
  ],
  "鸦雀无声": [
    "ya1 que4 wu2 sheng1"
  ],
  "综合": [
    "zong1 he2"
  ],
  "膂": [
    "lu:3"
  ],
  "袂": [
    "mei4"
  ],
  "痴": [
    "chi1",
    "chi1"
  ],
  "邸": [
    "Di3",
    "di3"
  ],
  "诟": [
    "gou4"
  ],
  "售价": [
    "shou4 jia4"
  ],
  "益": [
    "Yi4",
    "yi4"
  ],
  "历史": [
    "li4 shi3"
  ],
  "武装": [
    "wu3 zhuang1"
  ],
  "情形": [
    "qing2 xing5"
  ],
  "掭": [
    "tian4"
  ],
  "尢": [
    "wang1"
  ],
  "赘": [
    "zhui4"
  ],
  "鸼": [
    null
  ],
  "家伙": [
    "jia1 huo5",
    "jia1 huo5"
  ],
  "老年": [
    "lao3 nian2"
  ],
  "剥夺": [
    "bo1 duo2"
  ],
  "骀": [
    "tai2"
  ],
  "别致": [
    "bie2 zhi4",
    "bie2 zhi4"
  ],
  "亲情": [
    "qin1 qing2"
  ],
  "动脉": [
    "dong4 mai4"
  ],
  "矸": [
    "gan1"
  ],
  "洎": [
    "ji4"
  ],
  "粗糙": [
    "cu1 cao1"
  ],
  "吝": [
    "lin4",
    "lin4"
  ],
  "耷": [
    "da1"
  ],
  "邮局": [
    "you2 ju2"
  ],
  "惹": [
    "re3"
  ],
  "考生": [
    "kao3 sheng1"
  ],
  "年初": [
    "nian2 chu1"
  ],
  "暴躁": [
    "bao4 zao4"
  ],
  "蓠": [
    "li2"
  ],
  "俄": [
    "E2",
    "e2"
  ],
  "蓁": [
    "zhen1"
  ],
  "斛": [
    "hu2"
  ],
  "犹": [
    "you2"
  ],
  "启发": [
    "qi3 fa1"
  ],
  "飞机": [
    "fei1 ji1"
  ],
  "主编": [
    "zhu3 bian1"
  ],
  "对待": [
    "dui4 dai4"
  ],
  "驳回": [
    "bo2 hui2"
  ],
  "不良": [
    "bu4 liang2"
  ],
  "卤": [
    "lu3",
    "lu3"
  ],
  "汊": [
    "cha4"
  ],
  "如": [
    "ru2"
  ],
  "抓住": [
    "zhua1 zhu4"
  ],
  "内部": [
    "nei4 bu4"
  ],
  "醒": [
    "xing3"
  ],
  "光辉": [
    "guang1 hui1"
  ],
  "样品": [
    "yang4 pin3"
  ],
  "粱": [
    "liang2"
  ],
  "洘": [
    null
  ],
  "天生": [
    "tian1 sheng1"
  ],
  "高科技": [
    "gao1 ke1 ji4"
  ],
  "鬼": [
    "gui3"
  ],
  "走弯路": [
    "zou3 wan1 lu4"
  ],
  "会": [
    "hui4",
    "kuai4"
  ],
  "带头": [
    "dai4 tou2"
  ],
  "鲗": [
    "zei2"
  ],
  "庳": [
    "bi4"
  ],
  "小朋友": [
    "xiao3 peng2 you3"
  ],
  "臌": [
    "gu3"
  ],
  "昫": [
    "xu4"
  ],
  "筋": [
    "jin1"
  ],
  "曲线": [
    "qu1 xian4"
  ],
  "名利": [
    "ming2 li4"
  ],
  "用品": [
    "yong4 pin3"
  ],
  "蔫": [
    "nian1"
  ],
  "曙光": [
    "shu3 guang1"
  ],
  "先天": [
    "xian1 tian1"
  ],
  "一概": [
    "yi1 gai4"
  ],
  "疗养": [
    "liao2 yang3"
  ],
  "发病": [
    "fa1 bing4"
  ],
  "演讲": [
    "yan3 jiang3"
  ],
  "审定": [
    "shen3 ding4"
  ],
  "当天": [
    "dang1 tian1",
    "dang4 tian1"
  ],
  "抵御": [
    "di3 yu4"
  ],
  "旵": [
    "chan3"
  ],
  "戋": [
    "jian1"
  ],
  "摆平": [
    "bai3 ping2"
  ],
  "跟随": [
    "gen1 sui2"
  ],
  "涓": [
    "Juan1",
    "juan1"
  ],
  "徭": [
    "yao2"
  ],
  "服务": [
    "fu2 wu4"
  ],
  "专心": [
    "zhuan1 xin1"
  ],
  "兑": [
    "Dui4",
    "dui4"
  ],
  "饶恕": [
    "rao2 shu4"
  ],
  "感染": [
    "gan3 ran3"
  ],
  "冏": [
    "jiong3"
  ],
  "逃亡": [
    "tao2 wang2"
  ],
  "丽": [
    "Li2",
    "li4"
  ],
  "退让": [
    "tui4 rang4"
  ],
  "懊": [
    "ao4"
  ],
  "警车": [
    "jing3 che1"
  ],
  "立": [
    "Li4",
    "li4"
  ],
  "转型": [
    "zhuan3 xing2"
  ],
  "退出": [
    "tui4 chu1"
  ],
  "不安": [
    "bu4 an1"
  ],
  "关怀": [
    "guan1 huai2"
  ],
  "简单": [
    "jian3 dan1"
  ],
  "的": [
    "de5",
    "di1",
    "di2",
    "di4"
  ],
  "荀": [
    "Xun2",
    "xun2"
  ],
  "附加": [
    "fu4 jia1"
  ],
  "舯": [
    null
  ],
  "猊": [
    "ni2",
    "ni2"
  ],
  "节省": [
    "jie2 sheng3"
  ],
  "机遇": [
    "ji1 yu4"
  ],
  "由": [
    "you2"
  ],
  "制度": [
    "zhi4 du4"
  ],
  "郫": [
    "pi2"
  ],
  "盅": [
    "zhong1"
  ],
  "晟": [
    "Cheng2",
    "sheng4"
  ],
  "护士": [
    "hu4 shi5"
  ],
  "听力": [
    "ting1 li4"
  ],
  "手里": [
    "shou3 li3"
  ],
  "灵机一动": [
    "ling2 ji1 yi1 dong4"
  ],
  "鸧": [
    "cang1"
  ],
  "皇": [
    "Huang2",
    "huang2"
  ],
  "难堪": [
    "nan2 kan1"
  ],
  "饿": [
    "e4"
  ],
  "不如说": [
    null
  ],
  "演艺圈": [
    "yan3 yi4 quan1"
  ],
  "赜": [
    "ze2"
  ],
  "乘": [
    "Cheng2",
    "cheng2",
    "sheng4",
    "cheng2"
  ],
  "恨不得": [
    "hen4 bu5 de5"
  ],
  "车展": [
    "che1 zhan3"
  ],
  "洌": [
    "lie4"
  ],
  "直至": [
    "zhi2 zhi4"
  ],
  "蜜": [
    "mi4"
  ],
  "戊": [
    "wu4"
  ],
  "钝": [
    "dun4"
  ],
  "扫": [
    "sao3",
    "sao4"
  ],
  "节俭": [
    "jie2 jian3"
  ],
  "巫婆": [
    "wu1 po2"
  ],
  "笛": [
    "di2"
  ],
  "名单": [
    "ming2 dan1"
  ],
  "遍": [
    "bian4",
    "bian4"
  ],
  "指挥": [
    "zhi3 hui1"
  ],
  "蕉": [
    "jiao1",
    "qiao2"
  ],
  "矫正": [
    "jiao3 zheng4"
  ],
  "发誓": [
    "fa1 shi4"
  ],
  "洪": [
    "Hong2",
    "hong2"
  ],
  "阿": [
    "A1",
    "a1",
    "e1"
  ],
  "辁": [
    "quan2"
  ],
  "畏": [
    "wei4"
  ],
  "安眠药": [
    "an1 mian2 yao4"
  ],
  "镌": [
    "juan1"
  ],
  "袢": [
    "pan4"
  ],
  "煁": [
    "chen2"
  ],
  "首": [
    "shou3"
  ],
  "园林": [
    "yuan2 lin2"
  ],
  "珺": [
    "jun4"
  ],
  "磹": [
    "dian4"
  ],
  "踣": [
    "bo2"
  ],
  "螣": [
    "teng2"
  ],
  "未来": [
    "wei4 lai2"
  ],
  "蘩": [
    "fan2"
  ],
  "殖": [
    "zhi2"
  ],
  "㸌": [
    "huo4"
  ],
  "可是": [
    "ke3 shi4"
  ],
  "圣贤": [
    "sheng4 xian2"
  ],
  "方法": [
    "fang1 fa3"
  ],
  "走进": [
    "zou3 jin4"
  ],
  "安置": [
    "an1 zhi4"
  ],
  "赭": [
    "zhe3"
  ],
  "起伏": [
    "qi3 fu2"
  ],
  "窬": [
    "yu2"
  ],
  "监视": [
    "jian1 shi4"
  ],
  "作客": [
    "zuo4 ke4"
  ],
  "海面": [
    "hai3 mian4"
  ],
  "默": [
    "mo4"
  ],
  "嶂": [
    "zhang4"
  ],
  "可观": [
    "ke3 guan1"
  ],
  "藕": [
    "ou3"
  ],
  "官司": [
    "guan1 si5"
  ],
  "公安局": [
    "gong1 an1 ju2"
  ],
  "虔": [
    "qian2"
  ],
  "淖": [
    "Nao4",
    "nao4"
  ],
  "养老": [
    "yang3 lao3"
  ],
  "斡": [
    "wo4"
  ],
  "㙘": [
    null
  ],
  "核心": [
    "he2 xin1"
  ],
  "菅": [
    "jian1"
  ],
  "选项": [
    "xuan3 xiang4"
  ],
  "址": [
    "zhi3",
    "zhi3"
  ],
  "功": [
    "gong1"
  ],
  "嚏": [
    "ti4"
  ],
  "战友": [
    "zhan4 you3"
  ],
  "有机": [
    "you3 ji1"
  ],
  "令": [
    "ling2",
    "ling3",
    "ling4"
  ],
  "忻": [
    "xin1"
  ],
  "跳水": [
    "tiao4 shui3"
  ],
  "辨别": [
    "bian4 bie2"
  ],
  "禔": [
    "ti2"
  ],
  "受贿": [
    "shou4 hui4"
  ],
  "修建": [
    "xiu1 jian4"
  ],
  "潜能": [
    "qian2 neng2"
  ],
  "图纸": [
    "tu2 zhi3"
  ],
  "煳": [
    "hu2"
  ],
  "拷": [
    "kao3"
  ],
  "坚持不懈": [
    "jian1 chi2 bu4 xie4"
  ],
  "大衣": [
    "da4 yi1"
  ],
  "明媚": [
    "ming2 mei4"
  ],
  "亮相": [
    "liang4 xiang4"
  ],
  "蜃": [
    "shen4"
  ],
  "帱": [
    "chou2",
    "dao4"
  ],
  "洗手间": [
    "xi3 shou3 jian1"
  ],
  "伣": [
    "qian4"
  ],
  "道德": [
    "dao4 de2"
  ],
  "䏲": [
    null
  ],
  "读": [
    "dou4",
    "du2"
  ],
  "缺点": [
    "que1 dian3"
  ],
  "液晶": [
    "ye4 jing1"
  ],
  "预期": [
    "yu4 qi1"
  ],
  "经费": [
    "jing1 fei4"
  ],
  "蛀": [
    "zhu4"
  ],
  "累计": [
    "lei3 ji4"
  ],
  "臆": [
    "yi4"
  ],
  "蛲": [
    "nao2"
  ],
  "说到底": [
    "shuo1 dao4 di3"
  ],
  "镑": [
    "bang4"
  ],
  "晋升": [
    "jin4 sheng1"
  ],
  "桹": [
    "lang2"
  ],
  "身高": [
    "shen1 gao1"
  ],
  "甚至于": [
    "shen4 zhi4 yu2"
  ],
  "俙": [
    null
  ],
  "孤": [
    "gu1"
  ],
  "开学": [
    "kai1 xue2"
  ],
  "糠": [
    "kang1",
    "kang1",
    "kang1"
  ],
  "处理": [
    "chu3 li3"
  ],
  "毫": [
    "hao2"
  ],
  "协议书": [
    "xie2 yi4 shu1"
  ],
  "炊": [
    "chui1"
  ],
  "遐": [
    "xia2"
  ],
  "雊": [
    "gou4"
  ],
  "枍": [
    null
  ],
  "事件": [
    "shi4 jian4"
  ],
  "入场券": [
    "ru4 chang3 quan4"
  ],
  "铮": [
    "zheng1"
  ],
  "喜庆": [
    "xi3 qing4"
  ],
  "职责": [
    "zhi2 ze2"
  ],
  "绝望": [
    "jue2 wang4"
  ],
  "逞强": [
    "cheng3 qiang2"
  ],
  "棐": [
    "fei3"
  ],
  "仃": [
    "ding1"
  ],
  "笄": [
    "ji1"
  ],
  "与否": [
    "yu3 fou3"
  ],
  "参照": [
    "can1 zhao4"
  ],
  "坰": [
    "jiong1"
  ],
  "领队": [
    "ling3 dui4"
  ],
  "影星": [
    "ying3 xing1"
  ],
  "励": [
    "Li4",
    "li4"
  ],
  "扣人心弦": [
    "kou4 ren2 xin1 xian2"
  ],
  "法语": [
    "Fa3 yu3"
  ],
  "幛": [
    "zhang4"
  ],
  "砀": [
    "dang4"
  ],
  "废": [
    "fei4",
    "fei4"
  ],
  "着火": [
    "zhao2 huo3"
  ],
  "建筑": [
    "jian4 zhu4"
  ],
  "交易": [
    "jiao1 yi4"
  ],
  "喝彩": [
    "he4 cai3"
  ],
  "虱": [
    "shi1",
    "shi1"
  ],
  "赖": [
    "Lai4",
    "lai4",
    "lai4"
  ],
  "领略": [
    "ling3 lu:e4"
  ],
  "绠": [
    "geng3"
  ],
  "栽": [
    "zai1"
  ],
  "揪": [
    "jiu1"
  ],
  "席位": [
    "xi2 wei4"
  ],
  "僬": [
    "jiao1"
  ],
  "重要": [
    "zhong4 yao4"
  ],
  "几": [
    "ji1",
    "ji1",
    "ji3"
  ],
  "岈": [
    "ya2"
  ],
  "挨家挨户": [
    "ai1 jia1 ai1 hu4"
  ],
  "水稻": [
    "shui3 dao4"
  ],
  "枇": [
    "pi2"
  ],
  "散步": [
    "san4 bu4"
  ],
  "大型": [
    "da4 xing2"
  ],
  "融洽": [
    "rong2 qia4"
  ],
  "政党": [
    "zheng4 dang3"
  ],
  "冮": [
    "Gang1"
  ],
  "辜": [
    "Gu1",
    "gu1"
  ],
  "安静": [
    "an1 jing4"
  ],
  "彻底": [
    "che4 di3"
  ],
  "沉": [
    "chen2"
  ],
  "骦": [
    "shuang1"
  ],
  "整理": [
    "zheng3 li3"
  ],
  "怄": [
    "ou4"
  ],
  "兴高采烈": [
    "xing4 gao1 cai3 lie4"
  ],
  "浚": [
    "jun4",
    "jun4"
  ],
  "呜咽": [
    "wu1 ye4"
  ],
  "保险": [
    "bao3 xian3"
  ],
  "质朴": [
    "zhi4 pu3"
  ],
  "犬": [
    "quan3"
  ],
  "迷信": [
    "mi2 xin4"
  ],
  "芬芳": [
    "fen1 fang1"
  ],
  "摄氏度": [
    "She4 shi4 du4"
  ],
  "在家": [
    "zai4 jia1"
  ],
  "调控": [
    "tiao2 kong4"
  ],
  "年轻": [
    "nian2 qing1"
  ],
  "往后": [
    "wang3 hou4"
  ],
  "得益于": [
    "de2 yi4 yu2"
  ],
  "栳": [
    "lao3"
  ],
  "瓷": [
    "ci2"
  ],
  "额外": [
    "e2 wai4"
  ],
  "以后": [
    "yi3 hou4"
  ],
  "馋": [
    "chan2"
  ],
  "抵": [
    "di3"
  ],
  "埔": [
    "bu4",
    "pu3"
  ],
  "丝": [
    "si1"
  ],
  "鳏": [
    "guan1"
  ],
  "裔": [
    "yi4"
  ],
  "实": [
    "shi2"
  ],
  "躏": [
    "lin4"
  ],
  "蕞": [
    "zui4"
  ],
  "丿": [
    "pie3"
  ],
  "高雅": [
    "gao1 ya3"
  ],
  "隍": [
    "huang2"
  ],
  "蒲": [
    "Pu2",
    "pu2"
  ],
  "宇": [
    "yu3"
  ],
  "私人": [
    "si1 ren2"
  ],
  "杯": [
    "bei1",
    "bei1",
    "bei1"
  ],
  "心疼": [
    "xin1 teng2"
  ],
  "过去": [
    "guo4 qu4",
    "guo4 qu5"
  ],
  "叻": [
    "le4"
  ],
  "湛": [
    "Zhan4",
    "zhan4"
  ],
  "酐": [
    "gan1"
  ],
  "感谢": [
    "gan3 xie4"
  ],
  "融入": [
    "rong2 ru4"
  ],
  "诀窍": [
    "jue2 qiao4"
  ],
  "大厅": [
    "da4 ting1"
  ],
  "舅": [
    "jiu4"
  ],
  "争取": [
    "zheng1 qu3"
  ],
  "肺": [
    "fei4"
  ],
  "加剧": [
    "jia1 ju4"
  ],
  "偿": [
    "chang2"
  ],
  "翛": [
    "shu4"
  ],
  "䏝": [
    "zhuan1"
  ],
  "物品": [
    "wu4 pin3"
  ],
  "疾": [
    "ji2"
  ],
  "出自": [
    "chu1 zi4"
  ],
  "珈": [
    "jia1"
  ],
  "咤": [
    "zha4"
  ],
  "手脚": [
    "shou3 jiao3"
  ],
  "燃油": [
    "ran2 you2"
  ],
  "越发": [
    "yue4 fa1"
  ],
  "公安": [
    "gong1 an1"
  ],
  "绯": [
    "fei1"
  ],
  "荫": [
    "yin4",
    "yin4"
  ],
  "戴": [
    "Dai4",
    "dai4"
  ],
  "票": [
    "piao4"
  ],
  "阉": [
    "yan1"
  ],
  "香水": [
    "xiang1 shui3"
  ],
  "陀": [
    "tuo2"
  ],
  "辀": [
    "zhou1"
  ],
  "笾": [
    "bian1"
  ],
  "连任": [
    "lian2 ren4"
  ],
  "鳙": [
    "yong1"
  ],
  "硬朗": [
    "ying4 lang3"
  ],
  "产业": [
    "chan3 ye4"
  ],
  "求学": [
    "qiu2 xue2"
  ],
  "无须": [
    "wu2 xu1"
  ],
  "叔叔": [
    "shu1 shu5"
  ],
  "光环": [
    "guang1 huan2"
  ],
  "老师": [
    "lao3 shi1"
  ],
  "旷": [
    "kuang4"
  ],
  "浬": [
    "li3"
  ],
  "嗷": [
    "ao2"
  ],
  "短暂": [
    "duan3 zan4"
  ],
  "蘅": [
    "heng2"
  ],
  "郪": [
    "qi1"
  ],
  "皮鞋": [
    "pi2 xie2"
  ],
  "山谷": [
    "shan1 gu3"
  ],
  "豹": [
    "bao4"
  ],
  "菱": [
    "ling2",
    "ling2"
  ],
  "主席": [
    "zhu3 xi2"
  ],
  "鲹": [
    "shen1"
  ],
  "镣": [
    "liao4"
  ],
  "桅": [
    "wei2"
  ],
  "难说": [
    "nan2 shuo1"
  ],
  "搬迁": [
    "ban1 qian1"
  ],
  "射": [
    "she4",
    "she4"
  ],
  "聘任": [
    "pin4 ren4"
  ],
  "棹": [
    "zhuo1",
    "zhao4"
  ],
  "妯": [
    "zhou2"
  ],
  "抱负": [
    "bao4 fu4"
  ],
  "稙": [
    "zhi2"
  ],
  "浆": [
    "jiang1",
    "jiang4"
  ],
  "凤凰": [
    "Feng4 huang2",
    "feng4 huang2"
  ],
  "剥削": [
    "bo1 xue1"
  ],
  "瞳": [
    "tong2"
  ],
  "全力": [
    "quan2 li4"
  ],
  "给": [
    "gei3",
    "ji3"
  ],
  "噎": [
    "ye1"
  ],
  "嫽": [
    "liao2"
  ],
  "圹": [
    "kuang4"
  ],
  "相当于": [
    "xiang1 dang1 yu2"
  ],
  "筹": [
    "chou2"
  ],
  "娶": [
    "qu3"
  ],
  "俊俏": [
    "jun4 qiao4"
  ],
  "包裹": [
    "bao1 guo3"
  ],
  "干预": [
    "gan1 yu4"
  ],
  "謇": [
    "jian3"
  ],
  "羁": [
    "ji1",
    "ji1"
  ],
  "莙": [
    "jun1"
  ],
  "坻": [
    "chi2",
    "di3"
  ],
  "直视": [
    "zhi2 shi4"
  ],
  "不仅仅": [
    "bu4 jin3 jin3"
  ],
  "沟": [
    "gou1"
  ],
  "速": [
    "su4"
  ],
  "卫视": [
    "wei4 shi4"
  ],
  "只不过": [
    "zhi3 bu5 guo4"
  ],
  "横": [
    "heng2",
    "heng4"
  ],
  "弦": [
    "xian2"
  ],
  "撼": [
    "han4"
  ],
  "舞蹈": [
    "wu3 dao3"
  ],
  "壮观": [
    "zhuang4 guan1"
  ],
  "春季": [
    "chun1 ji4"
  ],
  "缘分": [
    "yuan2 fen4"
  ],
  "营造": [
    "ying2 zao4"
  ],
  "舌头": [
    "she2 tou5"
  ],
  "鞑": [
    "Da2"
  ],
  "顶尖": [
    "ding3 jian1"
  ],
  "伯伯": [
    "bo2 bo5"
  ],
  "暴利": [
    "bao4 li4"
  ],
  "一头": [
    "yi1 tou2"
  ],
  "第一手": [
    "di4 yi1 shou3"
  ],
  "蕖": [
    "qu2"
  ],
  "注意": [
    "zhu4 yi4"
  ],
  "课": [
    "ke4"
  ],
  "超出": [
    "chao1 chu1"
  ],
  "变成": [
    "bian4 cheng2"
  ],
  "瞧": [
    "qiao2"
  ],
  "钨": [
    "wu1"
  ],
  "规": [
    "gui1",
    "gui1"
  ],
  "穿上": [
    "chuan1 shang5"
  ],
  "扭": [
    "niu3"
  ],
  "国": [
    "Guo2",
    "guo2"
  ],
  "枰": [
    "ping2"
  ],
  "狰": [
    "zheng1"
  ],
  "固执": [
    "gu4 zhi2"
  ],
  "电子版": [
    "dian4 zi3 ban3"
  ],
  "疐": [
    "zhi4"
  ],
  "流行": [
    "liu2 xing2"
  ],
  "腥": [
    "xing1"
  ],
  "短裤": [
    "duan3 ku4"
  ],
  "珍视": [
    "zhen1 shi4"
  ],
  "拨打": [
    "bo1 da3"
  ],
  "股份": [
    "gu3 fen4"
  ],
  "苍蝇": [
    "cang1 ying5"
  ],
  "嘚": [
    "de1"
  ],
  "髯": [
    "ran2",
    "ran2"
  ],
  "发起人": [
    "fa1 qi3 ren2"
  ],
  "用餐": [
    "yong4 can1"
  ],
  "迎接": [
    "ying2 jie1"
  ],
  "扣押": [
    "kou4 ya1"
  ],
  "隆": [
    "Long2",
    "long1",
    "long2"
  ],
  "准时": [
    "zhun3 shi2"
  ],
  "卡子": [
    "qia3 zi5"
  ],
  "例子": [
    "li4 zi5"
  ],
  "闽": [
    "Min3"
  ],
  "退役": [
    "tui4 yi4"
  ],
  "迫": [
    "po4",
    "pai3",
    "po4"
  ],
  "计时": [
    "ji4 shi2"
  ],
  "人才": [
    "ren2 cai2"
  ],
  "互动": [
    "hu4 dong4"
  ],
  "损失": [
    "sun3 shi1"
  ],
  "食堂": [
    "shi2 tang2"
  ],
  "中华民族": [
    "Zhong1 hua2 min2 zu2"
  ],
  "上去": [
    "shang4 qu4"
  ],
  "真挚": [
    "zhen1 zhi4"
  ],
  "萁": [
    "qi2"
  ],
  "外来": [
    "wai4 lai2"
  ],
  "瞠": [
    "cheng1"
  ],
  "褰": [
    "qian1"
  ],
  "馕": [
    "nang2",
    "nang3"
  ],
  "秘密": [
    "mi4 mi4"
  ],
  "巩": [
    "Gong3",
    "gong3"
  ],
  "撰": [
    "zhuan4",
    "zhuan4"
  ],
  "涨价": [
    "zhang3 jia4"
  ],
  "赠": [
    "zeng4"
  ],
  "等于": [
    "deng3 yu2"
  ],
  "廊": [
    "lang2"
  ],
  "畎": [
    "quan3"
  ],
  "蒙": [
    "meng2",
    "meng1",
    "meng2",
    "Meng2",
    "Meng3",
    "meng1",
    "meng2"
  ],
  "肷": [
    "qian3"
  ],
  "格式": [
    "ge2 shi4"
  ],
  "发放": [
    "fa1 fang4"
  ],
  "鹞": [
    "yao4"
  ],
  "孕妇": [
    "yun4 fu4"
  ],
  "胡思乱想": [
    "hu2 si1 luan4 xiang3"
  ],
  "风貌": [
    "feng1 mao4"
  ],
  "邈": [
    "miao3"
  ],
  "必修": [
    "bi4 xiu1"
  ],
  "郡": [
    "jun4"
  ],
  "氙": [
    "xian1"
  ],
  "帝国主义": [
    "di4 guo2 zhu3 yi4"
  ],
  "有限": [
    "you3 xian4"
  ],
  "认同": [
    "ren4 tong2"
  ],
  "瞧不起": [
    "qiao2 bu4 qi3"
  ],
  "介": [
    "jie4"
  ],
  "间隔": [
    "jian4 ge2"
  ],
  "桩": [
    "zhuang1"
  ],
  "吃": [
    "chi1",
    "chi1"
  ],
  "芬": [
    "fen1"
  ],
  "间": [
    "jian1",
    "jian4"
  ],
  "僻": [
    "pi4"
  ],
  "灵敏": [
    "ling2 min3"
  ],
  "踶": [
    "di4"
  ],
  "蚲": [
    "ping2"
  ],
  "出洋相": [
    "chu1 yang2 xiang4"
  ],
  "瞻": [
    "zhan1"
  ],
  "分量": [
    "fen1 liang4",
    "fen4 liang4",
    "fen4 liang5"
  ],
  "艴": [
    "fu2"
  ],
  "嫌": [
    "xian2"
  ],
  "地方": [
    "di4 fang1",
    "di4 fang5"
  ],
  "好评": [
    "hao3 ping2"
  ],
  "淬": [
    "cui4"
  ],
  "永恒": [
    "yong3 heng2"
  ],
  "歧": [
    "qi2"
  ],
  "邮编": [
    "you2 bian1"
  ],
  "初期": [
    "chu1 qi1"
  ],
  "主张": [
    "zhu3 zhang1"
  ],
  "晦": [
    "hui4"
  ],
  "锰": [
    "meng3"
  ],
  "首次": [
    "shou3 ci4"
  ],
  "大规模": [
    "da4 gui1 mo2"
  ],
  "演习": [
    "yan3 xi2"
  ],
  "热气": [
    "re4 qi4"
  ],
  "浔": [
    "xun2"
  ],
  "臑": [
    "nao4"
  ],
  "软": [
    "ruan3",
    "ruan3"
  ],
  "昧": [
    "mei4"
  ],
  "报告": [
    "bao4 gao4"
  ],
  "变更": [
    "bian4 geng1"
  ],
  "入选": [
    "ru4 xuan3"
  ],
  "食": [
    "shi2",
    "si4"
  ],
  "应急": [
    "ying4 ji2"
  ],
  "课文": [
    "ke4 wen2"
  ],
  "收费": [
    "shou1 fei4"
  ],
  "浈": [
    "Zhen1"
  ],
  "接见": [
    "jie1 jian4"
  ],
  "新型": [
    "xin1 xing2"
  ],
  "眈": [
    "dan1"
  ],
  "询问": [
    "xun2 wen4"
  ],
  "里程碑": [
    "li3 cheng2 bei1"
  ],
  "较": [
    "jiao4"
  ],
  "拘": [
    "ju1"
  ],
  "转机": [
    "zhuan3 ji1"
  ],
  "梦幻": [
    "meng4 huan4"
  ],
  "出动": [
    "chu1 dong4"
  ],
  "秩": [
    "zhi4"
  ],
  "贪婪": [
    "tan1 lan2"
  ],
  "谷": [
    "gu3",
    "Gu3",
    "gu3"
  ],
  "橄": [
    "gan3"
  ],
  "单位": [
    "dan1 wei4"
  ],
  "悸": [
    "ji4"
  ],
  "区别": [
    "qu1 bie2"
  ],
  "捅": [
    "tong3"
  ],
  "妖": [
    "yao1"
  ],
  "最后": [
    "zui4 hou4"
  ],
  "驾驭": [
    "jia4 yu4"
  ],
  "晾": [
    "liang4"
  ],
  "津津有味": [
    "jin1 jin1 you3 wei4"
  ],
  "慈善": [
    "ci2 shan4"
  ],
  "抵触": [
    "di3 chu4"
  ],
  "祋": [
    null
  ],
  "邻居": [
    "lin2 ju1"
  ],
  "拒绝": [
    "ju4 jue2"
  ],
  "瘭": [
    "biao1"
  ],
  "牲": [
    "sheng1"
  ],
  "违反": [
    "wei2 fan3"
  ],
  "回答": [
    "hui2 da2"
  ],
  "阚": [
    "Kan4",
    "kan4"
  ],
  "合成": [
    "he2 cheng2"
  ],
  "投票": [
    "tou2 piao4"
  ],
  "燋": [
    "jiao1"
  ],
  "逶": [
    "wei1"
  ],
  "长寿": [
    "Chang2 shou4",
    "chang2 shou4"
  ],
  "合资": [
    "he2 zi1"
  ],
  "恶化": [
    "e4 hua4"
  ],
  "不太": [
    null
  ],
  "联合": [
    "lian2 he2"
  ],
  "袄": [
    "ao3"
  ],
  "大同小异": [
    "da4 tong2 xiao3 yi4"
  ],
  "荃": [
    "quan2"
  ],
  "札": [
    "zha2",
    "zha2",
    "zha2"
  ],
  "要害": [
    "yao4 hai4"
  ],
  "明确": [
    "ming2 que4"
  ],
  "网上": [
    "wang3 shang4"
  ],
  "琰": [
    "yan3"
  ],
  "止血": [
    "zhi3 xue4"
  ],
  "忧郁": [
    "you1 yu4"
  ],
  "所以": [
    "suo3 yi3"
  ],
  "争执": [
    "zheng1 zhi2"
  ],
  "一番": [
    null
  ],
  "啭": [
    "zhuan4"
  ],
  "吓人": [
    "xia4 ren2"
  ],
  "无比": [
    "wu2 bi3"
  ],
  "要不": [
    "yao4 bu4"
  ],
  "浮": [
    "fu2"
  ],
  "考试": [
    "kao3 shi4"
  ],
  "露": [
    "Lu4",
    "lou4",
    "lu4"
  ],
  "芣": [
    "fu2"
  ],
  "人气": [
    "ren2 qi4"
  ],
  "虓": [
    "xiao1"
  ],
  "髹": [
    "xiu1"
  ],
  "鹯": [
    "zhan1"
  ],
  "盦": [
    "an1"
  ],
  "祗": [
    "zhi1"
  ],
  "鲡": [
    "li2"
  ],
  "入侵": [
    "ru4 qin1"
  ],
  "来访": [
    "lai2 fang3"
  ],
  "杏": [
    "xing4"
  ],
  "发源地": [
    "fa1 yuan2 di4"
  ],
  "皛": [
    null
  ],
  "时隔": [
    "shi2 ge2"
  ],
  "青春期": [
    "qing1 chun1 qi1"
  ],
  "请客": [
    "qing3 ke4"
  ],
  "冗": [
    "rong3",
    "rong3"
  ],
  "逋": [
    "bu1"
  ],
  "唬": [
    "hu3"
  ],
  "万分": [
    "wan4 fen1"
  ],
  "非凡": [
    "fei1 fan2"
  ],
  "力气": [
    "li4 qi5"
  ],
  "揣测": [
    "chuai3 ce4"
  ],
  "打扮": [
    "da3 ban5"
  ],
  "设计师": [
    "she4 ji4 shi1"
  ],
  "进修": [
    "jin4 xiu1"
  ],
  "高原": [
    "gao1 yuan2"
  ],
  "市民": [
    "shi4 min2"
  ],
  "啸": [
    "xiao4"
  ],
  "观察": [
    "guan1 cha2"
  ],
  "政权": [
    "zheng4 quan2"
  ],
  "习": [
    "Xi2",
    "xi2"
  ],
  "疽": [
    "ju1"
  ],
  "鲽": [
    "die2"
  ],
  "敖": [
    "Ao2",
    "ao2"
  ],
  "装修": [
    "zhuang1 xiu1"
  ],
  "前所未有": [
    "qian2 suo3 wei4 you3"
  ],
  "应对": [
    "ying4 dui4"
  ],
  "枉": [
    "wang3"
  ],
  "碨": [
    null
  ],
  "两口子": [
    "liang3 kou3 zi5"
  ],
  "养殖": [
    "yang3 zhi2"
  ],
  "越过": [
    "yue4 guo4"
  ],
  "提炼": [
    "ti2 lian4"
  ],
  "预报": [
    "yu4 bao4"
  ],
  "炔": [
    "Gui4",
    "que1"
  ],
  "虎": [
    "hu3"
  ],
  "嵘": [
    "rong2"
  ],
  "黟": [
    "yi1"
  ],
  "失明": [
    "shi1 ming2"
  ],
  "襟": [
    "jin1"
  ],
  "舁": [
    "yu2"
  ],
  "做": [
    "zuo4"
  ],
  "匍": [
    "pu2"
  ],
  "赝": [
    "yan4",
    "yan4"
  ],
  "强硬": [
    "qiang2 ying4"
  ],
  "大人": [
    "da4 ren5"
  ],
  "茅": [
    "Mao2",
    "mao2"
  ],
  "并购": [
    "bing4 gou4"
  ],
  "确定": [
    "que4 ding4"
  ],
  "飨": [
    "xiang3"
  ],
  "黄": [
    "Huang2",
    "huang2"
  ],
  "芯": [
    "xin1",
    "xin4"
  ],
  "开创": [
    "kai1 chuang4"
  ],
  "矩": [
    "ju3",
    "ju3"
  ],
  "失恋": [
    "shi1 lian4"
  ],
  "过敏": [
    "guo4 min3"
  ],
  "璧": [
    "bi4"
  ],
  "引诱": [
    "yin3 you4"
  ],
  "掩饰": [
    "yan3 shi4"
  ],
  "丰富多彩": [
    "feng1 fu4 duo1 cai3"
  ],
  "配件": [
    "pei4 jian4"
  ],
  "菜单": [
    "cai4 dan1"
  ],
  "踉": [
    "liang2",
    "liang4"
  ],
  "涍": [
    null
  ],
  "清除": [
    "qing1 chu2"
  ],
  "让步": [
    "rang4 bu4"
  ],
  "掼": [
    "guan4"
  ],
  "显而易见": [
    "xian3 er2 yi4 jian4"
  ],
  "济": [
    "Ji3",
    "ji3",
    "ji4"
  ],
  "一代": [
    "yi1 dai4"
  ],
  "柈": [
    "pan2"
  ],
  "垒": [
    "lei3"
  ],
  "总而言之": [
    "zong3 er2 yan2 zhi1"
  ],
  "北部": [
    "bei3 bu4"
  ],
  "朦": [
    "meng2"
  ],
  "索": [
    "Suo3",
    "suo3"
  ],
  "歼": [
    "jian1"
  ],
  "排除": [
    "pai2 chu2"
  ],
  "商贾": [
    "shang1 gu3"
  ],
  "实际上": [
    "shi2 ji4 shang4"
  ],
  "门路": [
    "men2 lu4"
  ],
  "槱": [
    "you3"
  ],
  "税务": [
    "shui4 wu4"
  ],
  "减肥": [
    "jian3 fei2"
  ],
  "婚": [
    "hun1"
  ],
  "重": [
    "chong2",
    "zhong4"
  ],
  "碌": [
    "liu4",
    "lu4",
    "liu4"
  ],
  "囫": [
    "hu2"
  ],
  "恝": [
    "jia2"
  ],
  "取消": [
    "qu3 xiao1"
  ],
  "舫": [
    "fang3"
  ],
  "厍": [
    "She4"
  ],
  "汇率": [
    "hui4 lu:4"
  ],
  "炀": [
    "yang2"
  ],
  "习俗": [
    "xi2 su2"
  ],
  "怙": [
    "hu4"
  ],
  "栽培": [
    "zai1 pei2"
  ],
  "麟": [
    "lin2",
    "lin2"
  ],
  "啵": [
    "bo1",
    "bo5"
  ],
  "切身": [
    "qie4 shen1"
  ],
  "薹": [
    "tai2"
  ],
  "驷": [
    "si4"
  ],
  "司法": [
    "si1 fa3"
  ],
  "叚": [
    null
  ],
  "黄瓜": [
    "huang2 gua1"
  ],
  "蒯": [
    "kuai3"
  ],
  "耪": [
    "pang3"
  ],
  "一年到头": [
    "yi1 nian2 dao4 tou2"
  ],
  "醯": [
    "xi1"
  ],
  "常理": [
    "chang2 li3"
  ],
  "笑话": [
    "xiao4 hua5"
  ],
  "篌": [
    "hou2"
  ],
  "进入": [
    "jin4 ru4"
  ],
  "鲑": [
    "gui1"
  ],
  "储蓄": [
    "chu3 xu4"
  ],
  "军队": [
    "jun1 dui4"
  ],
  "联": [
    "lian2"
  ],
  "摇头": [
    "yao2 tou2"
  ],
  "设": [
    "she4"
  ],
  "翕": [
    "xi1"
  ],
  "消息": [
    "xiao1 xi5"
  ],
  "带队": [
    "dai4 dui4"
  ],
  "请坐": [
    "qing3 zuo4"
  ],
  "先例": [
    "xian1 li4"
  ],
  "烤": [
    "kao3"
  ],
  "调试": [
    "tiao2 shi4"
  ],
  "掌管": [
    "zhang3 guan3"
  ],
  "单纯": [
    "dan1 chun2"
  ],
  "农产品": [
    "nong2 chan3 pin3"
  ],
  "梌": [
    null
  ],
  "知识": [
    "zhi1 shi5"
  ],
  "嵲": [
    null
  ],
  "通知": [
    "tong1 zhi1"
  ],
  "舄": [
    "xi4"
  ],
  "泻": [
    "xie4"
  ],
  "妧": [
    null
  ],
  "榆": [
    "yu2"
  ],
  "尽力": [
    "jin4 li4"
  ],
  "啪": [
    "pa1"
  ],
  "颗": [
    "ke1"
  ],
  "泥": [
    "ni2",
    "ni4"
  ],
  "玫": [
    "mei2"
  ],
  "憕": [
    null
  ],
  "㬎": [
    "xian3"
  ],
  "榜样": [
    "bang3 yang4"
  ],
  "困境": [
    "kun4 jing4"
  ],
  "齐心协力": [
    "qi2 xin1 xie2 li4"
  ],
  "绱": [
    "shang4"
  ],
  "出来": [
    "chu1 lai2",
    "chu5 lai5"
  ],
  "交警": [
    "jiao1 jing3"
  ],
  "寿命": [
    "shou4 ming4"
  ],
  "狝": [
    "xian3"
  ],
  "橡": [
    "xiang4"
  ],
  "诤": [
    "zheng4"
  ],
  "东边": [
    "dong1 bian5"
  ],
  "位": [
    "wei4"
  ],
  "蝽": [
    "chun1"
  ],
  "转化": [
    "zhuan3 hua4"
  ],
  "若": [
    "re3",
    "ruo4"
  ],
  "音": [
    "yin1"
  ],
  "蹾": [
    "dun1"
  ],
  "学士": [
    "xue2 shi4"
  ],
  "泛滥": [
    "fan4 lan4"
  ],
  "郴": [
    "chen1"
  ],
  "显得": [
    "xian3 de5"
  ],
  "鲰": [
    "Zou1",
    "zou1"
  ],
  "知己": [
    "zhi1 ji3"
  ],
  "暴风骤雨": [
    "bao4 feng1 zhou4 yu3"
  ],
  "呼唤": [
    "hu1 huan4"
  ],
  "抒情": [
    "shu1 qing2"
  ],
  "箧": [
    "qie4"
  ],
  "情节": [
    "qing2 jie2"
  ],
  "锕": [
    "a1"
  ],
  "翁": [
    "Weng1",
    "weng1"
  ],
  "亚": [
    "Ya4",
    "ya4"
  ],
  "苕": [
    "shao2",
    "tiao2"
  ],
  "看作": [
    "kan4 zuo4"
  ],
  "蹚": [
    "tang1"
  ],
  "钋": [
    "po1"
  ],
  "趵": [
    "bao4"
  ],
  "鞒": [
    "qiao2"
  ],
  "条款": [
    "tiao2 kuan3"
  ],
  "一应俱全": [
    "yi1 ying1 ju4 quan2"
  ],
  "怒": [
    "nu4"
  ],
  "剖": [
    "pou1"
  ],
  "姹": [
    "cha4"
  ],
  "剞": [
    "ji1"
  ],
  "报亭": [
    "bao4 ting2"
  ],
  "雨": [
    "yu3",
    "yu4"
  ],
  "车牌": [
    "che1 pai2"
  ],
  "叟": [
    "sou3"
  ],
  "罐头": [
    "guan4 tou5"
  ],
  "廾": [
    "gong3"
  ],
  "子女": [
    "zi3 nu:3"
  ],
  "汋": [
    "zhuo2"
  ],
  "灯": [
    "deng1"
  ],
  "襦": [
    "ru2"
  ],
  "开夜车": [
    "kai1 ye4 che1"
  ],
  "邮箱": [
    "you2 xiang1"
  ],
  "词典": [
    "ci2 dian3"
  ],
  "反": [
    "fan3"
  ],
  "层": [
    "ceng2"
  ],
  "性别": [
    "xing4 bie2"
  ],
  "透彻": [
    "tou4 che4"
  ],
  "谈起": [
    "tan2 qi3"
  ],
  "牺牲": [
    "xi1 sheng1"
  ],
  "赑": [
    "bi4"
  ],
  "盖子": [
    "gai4 zi5"
  ],
  "悛": [
    "quan1"
  ],
  "转变": [
    "zhuan3 bian4"
  ],
  "疯狂": [
    "feng1 kuang2"
  ],
  "接纳": [
    "jie1 na4"
  ],
  "呈": [
    "cheng2"
  ],
  "着力": [
    "zhuo2 li4"
  ],
  "过渡": [
    "guo4 du4"
  ],
  "光缆": [
    "guang1 lan3"
  ],
  "扒": [
    "ba1",
    "pa2"
  ],
  "午餐": [
    "wu3 can1"
  ],
  "恳求": [
    "ken3 qiu2"
  ],
  "赞助": [
    "zan4 zhu4"
  ],
  "态": [
    "tai4"
  ],
  "替": [
    "ti4"
  ],
  "立体": [
    "li4 ti3"
  ],
  "媪": [
    "ao3"
  ],
  "忞": [
    "min2",
    "min3",
    "wen3"
  ],
  "绝缘": [
    "jue2 yuan2"
  ],
  "垄断": [
    "long3 duan4"
  ],
  "烽": [
    "feng1"
  ],
  "匹配": [
    "pi3 pei4"
  ],
  "止步": [
    "zhi3 bu4"
  ],
  "酂": [
    "zan4"
  ],
  "不耻下问": [
    "bu4 chi3 xia4 wen4"
  ],
  "海": [
    "Hai3",
    "hai3"
  ],
  "笕": [
    "jian3"
  ],
  "内阁": [
    "nei4 ge2"
  ],
  "阳台": [
    "yang2 tai2",
    "yang2 tai2"
  ],
  "分享": [
    "fen1 xiang3"
  ],
  "癃": [
    "long2"
  ],
  "温柔": [
    "wen1 rou2"
  ],
  "樘": [
    "cheng3",
    "tang2"
  ],
  "原先": [
    "yuan2 xian1"
  ],
  "轾": [
    "zhi4"
  ],
  "驾": [
    "Jia4",
    "jia4"
  ],
  "倒车": [
    "dao3 che1",
    "dao4 che1"
  ],
  "准则": [
    "zhun3 ze2"
  ],
  "秕": [
    "bi3",
    "bi3"
  ],
  "况且": [
    "kuang4 qie3"
  ],
  "表演": [
    "biao3 yan3"
  ],
  "哙": [
    "Kuai4",
    "kuai4",
    "wei4"
  ],
  "年薪": [
    "nian2 xin1"
  ],
  "欢": [
    "huan1",
    "huan1",
    "huan1",
    "huan1"
  ],
  "磴": [
    "deng4"
  ],
  "贪污": [
    "tan1 wu1"
  ],
  "竞": [
    "jing4"
  ],
  "设想": [
    "she4 xiang3"
  ],
  "逭": [
    "huan4"
  ],
  "领": [
    "ling3"
  ],
  "批评": [
    "pi1 ping2"
  ],
  "大陆": [
    "Da4 lu4",
    "da4 lu4"
  ],
  "革": [
    "ge2"
  ],
  "没说的": [
    "mei2 shuo1 de5"
  ],
  "炳": [
    "bing3"
  ],
  "嘴巴": [
    "zui3 ba5"
  ],
  "氲": [
    "yun1"
  ],
  "大奖赛": [
    "da4 jiang3 sai4"
  ],
  "尥": [
    "liao4"
  ],
  "兹": [
    "zi1"
  ],
  "佣": [
    "yong4",
    "yong1"
  ],
  "嗅": [
    "xiu4"
  ],
  "方向": [
    "fang1 xiang4"
  ],
  "地": [
    "de5",
    "di4"
  ],
  "虚拟": [
    "xu1 ni3"
  ],
  "窘": [
    "jiong3"
  ],
  "勇": [
    "yong3"
  ],
  "老朋友": [
    "lao3 peng2 you5"
  ],
  "轪": [
    null
  ],
  "淜": [
    "peng2"
  ],
  "会计": [
    "kuai4 ji4"
  ],
  "鸟巢": [
    "niao3 chao2"
  ],
  "不必": [
    "bu4 bi4"
  ],
  "磅": [
    "bang4",
    "pang2"
  ],
  "谵": [
    "zhan1"
  ],
  "毪": [
    "mu2"
  ],
  "纯粹": [
    "chun2 cui4"
  ],
  "赞赏": [
    "zan4 shang3"
  ],
  "笨": [
    "ben4"
  ],
  "成分": [
    "cheng2 fen4"
  ],
  "䣘": [
    null
  ],
  "陪葬": [
    "pei2 zang4"
  ],
  "扫墓": [
    "sao3 mu4"
  ],
  "奢": [
    "she1"
  ],
  "株": [
    "zhu1"
  ],
  "旎": [
    "ni3"
  ],
  "暑假": [
    "shu3 jia4"
  ],
  "歌颂": [
    "ge1 song4"
  ],
  "梠": [
    "lu:3"
  ],
  "逼": [
    "bi1",
    "bi1"
  ],
  "牾": [
    "wu3"
  ],
  "蜮": [
    "yu4"
  ],
  "炆": [
    "wen2"
  ],
  "样子": [
    "yang4 zi5"
  ],
  "丁": [
    "Ding1",
    "ding1"
  ],
  "发起": [
    "fa1 qi3"
  ],
  "兴奋剂": [
    "xing1 fen4 ji4"
  ],
  "捭": [
    "bai3",
    "bo4"
  ],
  "高铁": [
    "gao1 tie3"
  ],
  "养": [
    "yang3"
  ],
  "恭喜": [
    "gong1 xi3"
  ],
  "总数": [
    "zong3 shu4"
  ],
  "唿": [
    "hu1"
  ],
  "骆": [
    "Luo4",
    "luo4"
  ],
  "惚": [
    "hu1"
  ],
  "鹡": [
    "ji2"
  ],
  "帘子": [
    "lian2 zi5"
  ],
  "铕": [
    "you3"
  ],
  "香": [
    "xiang1"
  ],
  "鹝": [
    "yi4"
  ],
  "栒": [
    "xun2"
  ],
  "保佑": [
    "bao3 you4"
  ],
  "卷子": [
    "juan3 zi5",
    "juan4 zi5"
  ],
  "无可奈何": [
    "wu2 ke3 nai4 he2"
  ],
  "枪毙": [
    "qiang1 bi4"
  ],
  "琨": [
    "kun1"
  ],
  "成熟": [
    "cheng2 shu2"
  ],
  "跷": [
    "qiao1",
    "qiao1"
  ],
  "貌": [
    "mao4"
  ],
  "芥": [
    "gai4",
    "jie4"
  ],
  "旁": [
    "pang2"
  ],
  "肌肤": [
    "ji1 fu1"
  ],
  "朝着": [
    "chao2 zhe5"
  ],
  "臜": [
    "za1"
  ],
  "蚀": [
    "shi2"
  ],
  "举止": [
    "ju3 zhi3"
  ],
  "吵嘴": [
    "chao3 zui3"
  ],
  "淘气": [
    "tao2 qi4"
  ],
  "资料": [
    "zi1 liao4"
  ],
  "同": [
    "tong2",
    "tong4"
  ],
  "葰": [
    null
  ],
  "暖": [
    "nuan3",
    "nuan3",
    "nuan3",
    "nuan3"
  ],
  "不客气": [
    "bu4 ke4 qi5"
  ],
  "琊": [
    "ya2"
  ],
  "辐射": [
    "fu2 she4"
  ],
  "组合": [
    "zu3 he2"
  ],
  "虻": [
    "meng2",
    "meng2"
  ],
  "暿": [
    null
  ],
  "来电": [
    "lai2 dian4"
  ],
  "送": [
    "song4"
  ],
  "现有": [
    "xian4 you3"
  ],
  "衍生": [
    "yan3 sheng1"
  ],
  "优先": [
    "you1 xian1"
  ],
  "蒜": [
    "suan4"
  ],
  "穰": [
    "Rang2",
    "rang2"
  ],
  "积淀": [
    "ji1 dian4"
  ],
  "书桌": [
    "shu1 zhuo1"
  ],
  "缜": [
    "zhen3"
  ],
  "颥": [
    "ru2"
  ],
  "注射": [
    "zhu4 she4"
  ],
  "退回": [
    "tui4 hui2"
  ],
  "增产": [
    "zeng1 chan3"
  ],
  "灌输": [
    "guan4 shu1"
  ],
  "芈": [
    "Mi3",
    "mi3"
  ],
  "动力": [
    "dong4 li4"
  ],
  "冷酷无情": [
    "leng3 ku4 wu2 qing2"
  ],
  "畦": [
    "qi2"
  ],
  "敏感": [
    "min3 gan3"
  ],
  "卡片": [
    "ka3 pian4"
  ],
  "桧": [
    "Hui4",
    "gui4"
  ],
  "运用": [
    "yun4 yong4"
  ],
  "殃": [
    "yang1"
  ],
  "硚": [
    "qiao2"
  ],
  "协同": [
    "xie2 tong2"
  ],
  "福": [
    "Fu2",
    "fu2"
  ],
  "炉": [
    "lu2",
    "lu2"
  ],
  "挝": [
    "wo1",
    "zhua1"
  ],
  "硕果": [
    "shuo4 guo3"
  ],
  "愃": [
    "xuan1"
  ],
  "涡": [
    "guo1",
    "wo1"
  ],
  "㳘": [
    "chong1"
  ],
  "不错": [
    "bu4 cuo4"
  ],
  "僔": [
    "zun3"
  ],
  "笑话儿": [
    "xiao4 hua5 r5"
  ],
  "期望": [
    "qi1 wang4"
  ],
  "诞辰": [
    "dan4 chen2"
  ],
  "贱": [
    "jian4"
  ],
  "战胜": [
    "zhan4 sheng4"
  ],
  "人口": [
    "ren2 kou3"
  ],
  "抑扬顿挫": [
    "yi4 yang2 dun4 cuo4"
  ],
  "手枪": [
    "shou3 qiang1"
  ],
  "毖": [
    "bi4"
  ],
  "明星": [
    "ming2 xing1"
  ],
  "日趋": [
    "ri4 qu1"
  ],
  "深远": [
    "shen1 yuan3"
  ],
  "强大": [
    "qiang2 da4"
  ],
  "收留": [
    "shou1 liu2"
  ],
  "斋": [
    "zhai1",
    "zhai1"
  ],
  "资本主义": [
    "zi1 ben3 zhu3 yi4"
  ],
  "榧": [
    "fei3"
  ],
  "苴": [
    "Ju1",
    "ju1"
  ],
  "糕": [
    "gao1",
    "gao1"
  ],
  "托付": [
    "tuo1 fu4"
  ],
  "对立": [
    "dui4 li4"
  ],
  "出去": [
    "chu1 qu4"
  ],
  "说法": [
    "shuo1 fa3",
    "shuo1 fa5"
  ],
  "嵋": [
    "mei2"
  ],
  "缒": [
    "zhui4"
  ],
  "不已": [
    "bu4 yi3"
  ],
  "武器": [
    "wu3 qi4"
  ],
  "模式": [
    "mo2 shi4"
  ],
  "患有": [
    "huan4 you3"
  ],
  "立功": [
    "li4 gong1"
  ],
  "骨折": [
    "gu3 zhe2"
  ],
  "棺材": [
    "guan1 cai5"
  ],
  "恁": [
    "nen4",
    "nin2"
  ],
  "肇事": [
    "zhao4 shi4"
  ],
  "稆": [
    "lu:3"
  ],
  "招呼": [
    "zhao1 hu5"
  ],
  "璈": [
    "ao2"
  ],
  "指南针": [
    "zhi3 nan2 zhen1"
  ],
  "疟": [
    "nu:e4",
    "yao4"
  ],
  "上期": [
    "shang4 qi1"
  ],
  "巧合": [
    "qiao3 he2"
  ],
  "诃": [
    "he1"
  ],
  "皖": [
    "Wan3"
  ],
  "历来": [
    "li4 lai2"
  ],
  "管理": [
    "guan3 li3"
  ],
  "简": [
    "jian3"
  ],
  "嬉笑": [
    "xi1 xiao4"
  ],
  "斯": [
    "Si1",
    "si1"
  ],
  "舒服": [
    "shu1 fu5"
  ],
  "佽": [
    "Ci4",
    "ci4"
  ],
  "信贷": [
    "xin4 dai4"
  ],
  "不怎么样": [
    "bu4 zen3 me5 yang4"
  ],
  "命令": [
    "ming4 ling4"
  ],
  "整天": [
    "zheng3 tian1"
  ],
  "翅膀": [
    "chi4 bang3"
  ],
  "钉子": [
    "ding1 zi5"
  ],
  "弥": [
    "mi2",
    "mi2"
  ],
  "笑容": [
    "xiao4 rong2"
  ],
  "淡": [
    "dan4"
  ],
  "展示": [
    "zhan3 shi4"
  ],
  "哺": [
    "bu3",
    "bu1"
  ],
  "呒": [
    "fu3",
    "m2"
  ],
  "热气球": [
    "re4 qi4 qiu2"
  ],
  "究": [
    "jiu1"
  ],
  "臂": [
    "bi4"
  ],
  "扽": [
    "den4"
  ],
  "顽固": [
    "wan2 gu4"
  ],
  "出众": [
    "chu1 zhong4"
  ],
  "频繁": [
    "pin2 fan2"
  ],
  "壁画": [
    "bi4 hua4"
  ],
  "擂": [
    "lei2",
    "lei4"
  ],
  "㺄": [
    null
  ],
  "潴": [
    "zhu1"
  ],
  "质疑": [
    "zhi4 yi2"
  ],
  "嚚": [
    "yin2"
  ],
  "遭殃": [
    "zao1 yang1"
  ],
  "暮": [
    "mu4"
  ],
  "呐": [
    "na4",
    "na5"
  ],
  "也好": [
    "ye3 hao3"
  ],
  "将近": [
    "jiang1 jin4"
  ],
  "瘸": [
    "que2"
  ],
  "肾": [
    "shen4"
  ],
  "蒡": [
    "bang4"
  ],
  "听讲": [
    "ting1 jiang3"
  ],
  "在内": [
    "zai4 nei4"
  ],
  "住户": [
    "zhu4 hu4"
  ],
  "防御": [
    "fang2 yu4"
  ],
  "荓": [
    null
  ],
  "赛跑": [
    "sai4 pao3"
  ],
  "课程": [
    "ke4 cheng2"
  ],
  "船只": [
    "chuan2 zhi1"
  ],
  "众人": [
    "zhong4 ren2"
  ],
  "写字台": [
    "xie3 zi4 tai2"
  ],
  "寿司": [
    "shou4 si1"
  ],
  "早饭": [
    "zao3 fan4"
  ],
  "印证": [
    "yin4 zheng4"
  ],
  "司令": [
    "si1 ling4"
  ],
  "桑拿": [
    "sang1 na2"
  ],
  "僮": [
    "Zhuang4",
    "tong2"
  ],
  "蓐": [
    "ru4"
  ],
  "倾听": [
    "qing1 ting1"
  ],
  "讲座": [
    "jiang3 zuo4"
  ],
  "原材料": [
    "yuan2 cai2 liao4"
  ],
  "肃": [
    "Su4",
    "su4"
  ],
  "蹄": [
    "ti2",
    "ti2"
  ],
  "虚心": [
    "xu1 xin1"
  ],
  "滚动": [
    "gun3 dong4"
  ],
  "心急如焚": [
    "xin1 ji2 ru2 fen2"
  ],
  "培": [
    "pei2"
  ],
  "奕": [
    "yi4"
  ],
  "墓碑": [
    "mu4 bei1"
  ],
  "出具": [
    "chu1 ju4"
  ],
  "特": [
    "te4"
  ],
  "蚰": [
    "you2"
  ],
  "作": [
    "zuo1",
    "zuo4"
  ],
  "处在": [
    "chu3 zai4"
  ],
  "陨石": [
    "yun3 shi2"
  ],
  "禁": [
    "jin1",
    "jin4"
  ],
  "不少": [
    "bu4 shao3"
  ],
  "排": [
    "pai2"
  ],
  "臣": [
    "Chen2",
    "chen2"
  ],
  "琛": [
    "chen1"
  ],
  "看得起": [
    "kan4 de5 qi3"
  ],
  "传闻": [
    "chuan2 wen2"
  ],
  "昄": [
    "ban3"
  ],
  "顶多": [
    "ding3 duo1"
  ],
  "收回": [
    "shou1 hui2"
  ],
  "理": [
    "li3"
  ],
  "倴": [
    null
  ],
  "羚": [
    "ling2"
  ],
  "背景": [
    "bei4 jing3"
  ],
  "费": [
    "Fei4",
    "fei4"
  ],
  "小心翼翼": [
    "xiao3 xin1 yi4 yi4"
  ],
  "仇": [
    "Qiu2",
    "chou2",
    "qiu2",
    "chou2",
    "chou2"
  ],
  "麻木": [
    "ma2 mu4"
  ],
  "起草": [
    "qi3 cao3"
  ],
  "纸": [
    "zhi3",
    "zhi3"
  ],
  "航天员": [
    "hang2 tian1 yuan2"
  ],
  "窗台": [
    "chuang1 tai2"
  ],
  "男性": [
    "nan2 xing4"
  ],
  "民意": [
    "min2 yi4"
  ],
  "一瞬间": [
    "yi1 shun4 jian1"
  ],
  "屠杀": [
    "tu2 sha1"
  ],
  "必定": [
    "bi4 ding4"
  ],
  "稞": [
    "ke1"
  ],
  "引擎": [
    "yin3 qing2"
  ],
  "鉴别": [
    "jian4 bie2"
  ],
  "爱护": [
    "ai4 hu4"
  ],
  "戬": [
    "jian3"
  ],
  "倥": [
    "kong1",
    "kong3"
  ],
  "性格": [
    "xing4 ge2"
  ],
  "周期": [
    "zhou1 qi1"
  ],
  "游": [
    "You2",
    "you2",
    "you2"
  ],
  "三维": [
    "san1 wei2"
  ],
  "单打": [
    "dan1 da3"
  ],
  "场所": [
    "chang3 suo3"
  ],
  "创建": [
    "chuang4 jian4"
  ],
  "叁": [
    "san1",
    "san1"
  ],
  "葎": [
    "lu:4"
  ],
  "浏览": [
    "liu2 lan3"
  ],
  "三明治": [
    "san1 ming2 zhi4"
  ],
  "校园": [
    "xiao4 yuan2"
  ],
  "偶": [
    "ou3"
  ],
  "芦": [
    "lu2"
  ],
  "箬": [
    "ruo4"
  ],
  "多久": [
    "duo1 jiu3"
  ],
  "黑色": [
    "hei1 se4"
  ],
  "回": [
    "hui2",
    "hui2",
    "hui2"
  ],
  "有趣": [
    "you3 qu4"
  ],
  "外形": [
    "wai4 xing2"
  ],
  "统计": [
    "tong3 ji4"
  ],
  "曈": [
    "tong2"
  ],
  "趣": [
    "qu4"
  ],
  "包装": [
    "bao1 zhuang1"
  ],
  "稌": [
    "tu2"
  ],
  "可乐": [
    "ke3 le4"
  ],
  "褚": [
    "Chu3",
    "zhu3"
  ],
  "毁坏": [
    "hui3 huai4"
  ],
  "恐怕": [
    "kong3 pa4"
  ],
  "性质": [
    "xing4 zhi4"
  ],
  "旬": [
    "xun2"
  ],
  "大于": [
    "da4 yu2"
  ],
  "单元": [
    "dan1 yuan2"
  ],
  "广大": [
    "guang3 da4"
  ],
  "仇恨": [
    "chou2 hen4"
  ],
  "走过场": [
    "zou3 guo4 chang3"
  ],
  "悠闲": [
    "you1 xian2",
    "you1 xian2"
  ],
  "零花钱": [
    "ling2 hua1 qian2"
  ],
  "风情": [
    "feng1 qing2"
  ],
  "的确": [
    "di2 que4"
  ],
  "薤": [
    "xie4"
  ],
  "滟": [
    "yan4"
  ],
  "变": [
    "bian4"
  ],
  "盉": [
    "he2"
  ],
  "战士": [
    "zhan4 shi4"
  ],
  "醇": [
    "chun2",
    "chun2"
  ],
  "柏": [
    "Bai3",
    "bai3",
    "bo2",
    "bo4",
    "bai3"
  ],
  "槜": [
    "zui4"
  ],
  "龠": [
    "yue4"
  ],
  "簇拥": [
    "cu4 yong1"
  ],
  "狄": [
    "Di2",
    "di2"
  ],
  "缅": [
    "Mian3",
    "mian3"
  ],
  "面前": [
    "mian4 qian2"
  ],
  "居高临下": [
    "ju1 gao1 lin2 xia4"
  ],
  "音乐会": [
    "yin1 yue4 hui4"
  ],
  "接触": [
    "jie1 chu4"
  ],
  "老人": [
    "lao3 ren2"
  ],
  "故障": [
    "gu4 zhang4"
  ],
  "顺差": [
    "shun4 cha1"
  ],
  "感性": [
    "gan3 xing4"
  ],
  "民用": [
    "min2 yong4"
  ],
  "虐": [
    "nu:e4"
  ],
  "篑": [
    "kui4"
  ],
  "剔除": [
    "ti1 chu2"
  ],
  "啧": [
    "ze2"
  ],
  "选用": [
    "xuan3 yong4"
  ],
  "茝": [
    "chai3"
  ],
  "灵通": [
    "ling2 tong1"
  ],
  "自卑": [
    "zi4 bei1"
  ],
  "债务": [
    "zhai4 wu4"
  ],
  "自卫": [
    "zi4 wei4"
  ],
  "图画": [
    "tu2 hua4"
  ],
  "产量": [
    "chan3 liang4"
  ],
  "垫": [
    "dian4"
  ],
  "刹车": [
    "sha1 che1"
  ],
  "创业": [
    "chuang4 ye4"
  ],
  "设立": [
    "she4 li4"
  ],
  "新": [
    "Xin1",
    "xin1"
  ],
  "往日": [
    "wang3 ri4"
  ],
  "定时": [
    "ding4 shi2"
  ],
  "鞭": [
    "bian1"
  ],
  "租赁": [
    "zu1 lin4"
  ],
  "袋": [
    "dai4"
  ],
  "诚心诚意": [
    "cheng2 xin1 cheng2 yi4"
  ],
  "建成": [
    "jian4 cheng2"
  ],
  "片面": [
    "pian4 mian4"
  ],
  "螃": [
    "pang2"
  ],
  "花样": [
    "hua1 yang4"
  ],
  "昽": [
    "long2"
  ],
  "衔接": [
    "xian2 jie1"
  ],
  "咱": [
    "zan2",
    "za2",
    "zan2",
    "zan2"
  ],
  "傕": [
    "Jue2",
    "jue2"
  ],
  "阃": [
    "kun3"
  ],
  "燠": [
    "yu4"
  ],
  "记录": [
    "ji4 lu4"
  ],
  "间接": [
    "jian4 jie1"
  ],
  "战场": [
    "zhan4 chang3"
  ],
  "搌": [
    "zhan3"
  ],
  "品": [
    "pin3"
  ],
  "平静": [
    "ping2 jing4"
  ],
  "交朋友": [
    "jiao1 peng2 you5"
  ],
  "奖学金": [
    "jiang3 xue2 jin1"
  ],
  "同感": [
    "tong2 gan3"
  ],
  "接通": [
    "jie1 tong1"
  ],
  "愍": [
    "min3"
  ],
  "领养": [
    "ling3 yang3"
  ],
  "帔": [
    "pei4"
  ],
  "联合国": [
    "Lian2 he2 guo2"
  ],
  "橡胶": [
    "xiang4 jiao1"
  ],
  "强度": [
    "qiang2 du4"
  ],
  "钮": [
    "Niu3",
    "niu3"
  ],
  "实况": [
    "shi2 kuang4"
  ],
  "接下来": [
    "jie1 xia4 lai2"
  ],
  "传染": [
    "chuan2 ran3"
  ],
  "大量": [
    "da4 liang4"
  ],
  "肖像": [
    "xiao4 xiang4"
  ],
  "转折": [
    "zhuan3 zhe2"
  ],
  "酱": [
    "jiang4"
  ],
  "全年": [
    "quan2 nian2"
  ],
  "煋": [
    "xing1"
  ],
  "见外": [
    "jian4 wai4"
  ],
  "搽": [
    "cha2"
  ],
  "敲诈": [
    "qiao1 zha4"
  ],
  "掎": [
    "ji3"
  ],
  "乍": [
    "zha4"
  ],
  "缞": [
    "cui1"
  ],
  "波澜": [
    "bo1 lan2"
  ],
  "此次": [
    "ci3 ci4"
  ],
  "时不时": [
    "shi2 bu4 shi2"
  ],
  "体质": [
    "ti3 zhi4"
  ],
  "漏": [
    "lou4"
  ],
  "公共场所": [
    "gong1 gong4 chang3 suo3"
  ],
  "得力": [
    "de2 li4"
  ],
  "对手": [
    "dui4 shou3"
  ],
  "刀": [
    "Dao1",
    "dao1"
  ],
  "哃": [
    "tong2"
  ],
  "闱": [
    "wei2"
  ],
  "正常": [
    "zheng4 chang2"
  ],
  "作对": [
    "zuo4 dui4"
  ],
  "婼": [
    "chuo4"
  ],
  "承认": [
    "cheng2 ren4"
  ],
  "弗": [
    "fu2"
  ],
  "张灯结彩": [
    "zhang1 deng1 jie2 cai3"
  ],
  "哂": [
    "shen3"
  ],
  "卷入": [
    "juan3 ru4"
  ],
  "犍": [
    "jian1"
  ],
  "谎话": [
    "huang3 hua4"
  ],
  "谏": [
    "Jian4",
    "jian4"
  ],
  "工作量": [
    "gong1 zuo4 liang4"
  ],
  "俞": [
    "Yu2",
    "shu4",
    "yu2"
  ],
  "菊花": [
    "ju2 hua1"
  ],
  "青": [
    "Qing1",
    "qing1"
  ],
  "恶": [
    "e3",
    "e4",
    "wu4"
  ],
  "过奖": [
    "guo4 jiang3"
  ],
  "逞能": [
    "cheng3 neng2"
  ],
  "交给": [
    "jiao1 gei3"
  ],
  "举办": [
    "ju3 ban4"
  ],
  "产地": [
    "chan3 di4"
  ],
  "天长地久": [
    "tian1 chang2 di4 jiu3"
  ],
  "杀毒": [
    "sha1 du2"
  ],
  "前方": [
    "qian2 fang1"
  ],
  "鄯": [
    "shan4"
  ],
  "裰": [
    "duo1"
  ],
  "皿": [
    "min3"
  ],
  "剩下": [
    "sheng4 xia4"
  ],
  "偷窥": [
    "tou1 kui1"
  ],
  "转让": [
    "zhuan3 rang4"
  ],
  "流转": [
    "liu2 zhuan3"
  ],
  "蝗": [
    "huang2"
  ],
  "漭": [
    "mang3"
  ],
  "诚挚": [
    "cheng2 zhi4"
  ],
  "昂": [
    "ang2"
  ],
  "㵐": [
    "Jue2"
  ],
  "喾": [
    "Ku4"
  ],
  "疯": [
    "feng1"
  ],
  "蔽": [
    "bi4"
  ],
  "谪": [
    "zhe2",
    "zhe2"
  ],
  "光芒": [
    "guang1 mang2"
  ],
  "珹": [
    "cheng2"
  ],
  "一般": [
    "yi1 ban1"
  ],
  "添加": [
    "tian1 jia1"
  ],
  "开展": [
    "kai1 zhan3"
  ],
  "顺手": [
    "shun4 shou3"
  ],
  "砸": [
    "za2"
  ],
  "电动车": [
    "dian4 dong4 che1"
  ],
  "咏": [
    "yong3"
  ],
  "箅": [
    "bi4"
  ],
  "科目": [
    "ke1 mu4"
  ],
  "豪": [
    "hao2"
  ],
  "型号": [
    "xing2 hao4"
  ],
  "叵": [
    "po3"
  ],
  "犯愁": [
    "fan4 chou2"
  ],
  "公园": [
    "gong1 yuan2"
  ],
  "自杀": [
    "zi4 sha1"
  ],
  "通顺": [
    "tong1 shun4"
  ],
  "录制": [
    "lu4 zhi4"
  ],
  "懂得": [
    "dong3 de5"
  ],
  "锷": [
    "e4"
  ],
  "出息": [
    "chu1 xi1",
    "chu1 xi5"
  ],
  "和平共处": [
    "he2 ping2 gong4 chu3"
  ],
  "儒学": [
    "Ru2 xue2"
  ],
  "围": [
    "Wei2",
    "wei2"
  ],
  "外科": [
    "wai4 ke1"
  ],
  "酹": [
    "lei4"
  ],
  "费用": [
    "fei4 yong4"
  ],
  "芑": [
    "qi3"
  ],
  "苏醒": [
    "su1 xing3"
  ],
  "烨": [
    "ye4",
    "ye4",
    "ye4"
  ],
  "耀眼": [
    "yao4 yan3"
  ],
  "流浪": [
    "liu2 lang4"
  ],
  "不知": [
    "bu4 zhi1"
  ],
  "搬家": [
    "ban1 jia1"
  ],
  "拣": [
    "jian3"
  ],
  "葫": [
    "hu2"
  ],
  "礞": [
    "meng2"
  ],
  "凌": [
    "Ling2",
    "ling2"
  ],
  "庖": [
    "pao2"
  ],
  "蟮": [
    "shan4"
  ],
  "害虫": [
    "hai4 chong2"
  ],
  "志": [
    "zhi4",
    "zhi4"
  ],
  "茉": [
    "mo4"
  ],
  "成语": [
    "cheng2 yu3"
  ],
  "耐人寻味": [
    "nai4 ren2 xun2 wei4"
  ],
  "广阔": [
    "guang3 kuo4"
  ],
  "贫富": [
    "pin2 fu4"
  ],
  "倡": [
    "chang4"
  ],
  "窨": [
    "xun1",
    "yin4"
  ],
  "璠": [
    "fan2"
  ],
  "畿": [
    "ji1"
  ],
  "拯救": [
    "zheng3 jiu4"
  ],
  "佻": [
    "tiao1"
  ],
  "颎": [
    "jiong3"
  ],
  "来得及": [
    "lai2 de5 ji2"
  ],
  "同等": [
    "tong2 deng3"
  ],
  "逮捕": [
    "dai4 bu3"
  ],
  "主人公": [
    "zhu3 ren2 gong1"
  ],
  "泚": [
    "ci3"
  ],
  "聚精会神": [
    "ju4 jing1 hui4 shen2"
  ],
  "更换": [
    "geng1 huan4"
  ],
  "晗": [
    "han2"
  ],
  "麻将": [
    "ma2 jiang4"
  ],
  "饱满": [
    "bao3 man3"
  ],
  "成人": [
    "cheng2 ren2"
  ],
  "鱾": [
    "ji3"
  ],
  "隳": [
    "hui1"
  ],
  "持有": [
    "chi2 you3"
  ],
  "惋": [
    "wan3"
  ],
  "芜": [
    "wu2"
  ],
  "彭": [
    "Peng2"
  ],
  "关税": [
    "guan1 shui4"
  ],
  "镵": [
    "chan2"
  ],
  "模特儿": [
    "mo2 te4 r5"
  ],
  "珍藏": [
    "zhen1 cang2"
  ],
  "嵚": [
    "qin1"
  ],
  "赶忙": [
    "gan3 mang2"
  ],
  "狎": [
    "xia2"
  ],
  "冕": [
    "mian3"
  ],
  "撒谎": [
    "sa1 huang3"
  ],
  "奶牛": [
    "nai3 niu2"
  ],
  "验": [
    "yan4",
    "yan4"
  ],
  "长城": [
    "Chang2 cheng2"
  ],
  "使用": [
    "shi3 yong4"
  ],
  "抽": [
    "chou1"
  ],
  "员": [
    "yuan2"
  ],
  "勤快": [
    "qin2 kuai4"
  ],
  "款项": [
    "kuan3 xiang4"
  ],
  "缗": [
    "min2"
  ],
  "却": [
    "que4",
    "que4"
  ],
  "硖": [
    "xia2"
  ],
  "嫚": [
    "Man4",
    "man4"
  ],
  "跟上": [
    "gen1 shang4"
  ],
  "剟": [
    "duo1"
  ],
  "髀": [
    "bi4"
  ],
  "怀疑": [
    "huai2 yi2"
  ],
  "喜洋洋": [
    "xi3 yang2 yang2"
  ],
  "槌": [
    "chui2"
  ],
  "帜": [
    "zhi4"
  ],
  "查处": [
    "cha2 chu3"
  ],
  "衷": [
    "zhong1"
  ],
  "歌咏": [
    "ge1 yong3"
  ],
  "回收": [
    "hui2 shou1"
  ],
  "岑": [
    "Cen2",
    "cen2"
  ],
  "经过": [
    "jing1 guo4"
  ],
  "胰": [
    "yi2"
  ],
  "邢": [
    "Xing2"
  ],
  "隗": [
    "Kui2",
    "Wei3",
    "wei3"
  ],
  "情结": [
    "qing2 jie2"
  ],
  "追赶": [
    "zhui1 gan3"
  ],
  "永远": [
    "yong3 yuan3"
  ],
  "空军": [
    "kong1 jun1"
  ],
  "显赫": [
    "xian3 he4"
  ],
  "巷": [
    "xiang4"
  ],
  "垢": [
    "gou4"
  ],
  "珍珠": [
    "zhen1 zhu1"
  ],
  "只有": [
    "zhi3 you3"
  ],
  "媾": [
    "gou4"
  ],
  "梦想": [
    "meng4 xiang3"
  ],
  "徐": [
    "Xu2",
    "xu2"
  ],
  "婴儿": [
    "ying1 er2"
  ],
  "好听": [
    "hao3 ting1"
  ],
  "受不了": [
    "shou4 bu4 liao3"
  ],
  "蚬": [
    "xian3"
  ],
  "挫折": [
    "cuo4 zhe2"
  ],
  "针": [
    "zhen1",
    "zhen1"
  ],
  "枯燥": [
    "ku1 zao4"
  ],
  "毕竟": [
    "bi4 jing4"
  ],
  "宏伟": [
    "hong2 wei3"
  ],
  "电报": [
    "dian4 bao4"
  ],
  "遥": [
    "yao2"
  ],
  "琼": [
    "qiong2"
  ],
  "朝三暮四": [
    "zhao1 san1 mu4 si4"
  ],
  "镜": [
    "jing4"
  ],
  "曷": [
    "he2"
  ],
  "低价": [
    "di1 jia4"
  ],
  "芟": [
    "shan1"
  ],
  "日": [
    "Ri4",
    "ri4"
  ],
  "看得出": [
    "kan4 de5 chu1"
  ],
  "无济于事": [
    "wu2 ji4 yu2 shi4"
  ],
  "球星": [
    "qiu2 xing1"
  ],
  "兀": [
    "Wu4",
    "wu4"
  ],
  "是不是": [
    "shi4 bu4 shi4"
  ],
  "招标": [
    "zhao1 biao1"
  ],
  "史": [
    "Shi3",
    "shi3"
  ],
  "柝": [
    "tuo4"
  ],
  "訚": [
    "Yin2",
    "yin2"
  ],
  "不算": [
    "bu4 suan4"
  ],
  "红包": [
    "hong2 bao1"
  ],
  "热点": [
    "re4 dian3"
  ],
  "言语": [
    "yan2 yu3",
    "yan2 yu5"
  ],
  "癗": [
    null
  ],
  "文盲": [
    "wen2 mang2"
  ],
  "怀里": [
    "huai2 li3"
  ],
  "侥": [
    "jiao3",
    "yao2"
  ],
  "景象": [
    "jing3 xiang4"
  ],
  "赞叹": [
    "zan4 tan4"
  ],
  "耕地": [
    "geng1 di4"
  ],
  "神圣": [
    "shen2 sheng4"
  ],
  "探望": [
    "tan4 wang4"
  ],
  "打猎": [
    "da3 lie4"
  ],
  "不假思索": [
    "bu4 jia3 si1 suo3"
  ],
  "芄": [
    "wan2"
  ],
  "大胆": [
    "da4 dan3"
  ],
  "坚实": [
    "jian1 shi2"
  ],
  "聃": [
    "dan1"
  ],
  "挥": [
    "hui1"
  ],
  "怎么样": [
    "zen3 me5 yang4"
  ],
  "轻而易举": [
    "qing1 er2 yi4 ju3"
  ],
  "诚意": [
    "cheng2 yi4"
  ],
  "灵巧": [
    "ling2 qiao3"
  ],
  "资金": [
    "zi1 jin1"
  ],
  "脂": [
    "zhi1"
  ],
  "梆": [
    "bang1"
  ],
  "源": [
    "yuan2"
  ],
  "非": [
    "Fei1",
    "fei1"
  ],
  "差": [
    "cha1",
    "cha4",
    "chai1",
    "ci1"
  ],
  "黪": [
    "can3"
  ],
  "昂贵": [
    "ang2 gui4"
  ],
  "臾": [
    "Yu2",
    "yu2"
  ],
  "书橱": [
    "shu1 chu2"
  ],
  "洭": [
    "kuang1"
  ],
  "有益": [
    "you3 yi4"
  ],
  "示范": [
    "shi4 fan4"
  ],
  "强迫": [
    "qiang3 po4"
  ],
  "老百姓": [
    "lao3 bai3 xing4"
  ],
  "食品": [
    "shi2 pin3"
  ],
  "酵": [
    "jiao4"
  ],
  "抛弃": [
    "pao1 qi4"
  ],
  "彘": [
    "zhi4"
  ],
  "界限": [
    "jie4 xian4"
  ],
  "灌溉": [
    "guan4 gai4"
  ],
  "谈不上": [
    "tan2 bu5 shang4"
  ],
  "违背": [
    "wei2 bei4"
  ],
  "不理": [
    "bu4 li3"
  ],
  "行动": [
    "xing2 dong4"
  ],
  "狙": [
    "ju1"
  ],
  "寝": [
    "qin3",
    "qin3"
  ],
  "个人": [
    "ge4 ren2"
  ],
  "约定": [
    "yue1 ding4"
  ],
  "糁": [
    "san3"
  ],
  "网球": [
    "wang3 qiu2"
  ],
  "七嘴八舌": [
    "qi1 zui3 ba1 she2"
  ],
  "嗌": [
    "ai4",
    "yi4"
  ],
  "照样": [
    "zhao4 yang4"
  ],
  "难得一见": [
    "nan2 de2 yi1 jian4"
  ],
  "桦": [
    "hua4"
  ],
  "拴": [
    "shuan1"
  ],
  "晒": [
    "shai4",
    "shai4"
  ],
  "高大": [
    "gao1 da4"
  ],
  "朽": [
    "xiu3"
  ],
  "宏": [
    "hong2"
  ],
  "开放": [
    "kai1 fang4"
  ],
  "如何": [
    "ru2 he2"
  ],
  "错综复杂": [
    "cuo4 zong1 fu4 za2"
  ],
  "跺": [
    "duo4",
    "duo4"
  ],
  "窃": [
    "qie4"
  ],
  "汶": [
    "Wen4"
  ],
  "伦理": [
    "lun2 li3"
  ],
  "军舰": [
    "jun1 jian4"
  ],
  "铸造": [
    "zhu4 zao4"
  ],
  "海藻": [
    "hai3 zao3"
  ],
  "一半": [
    "yi1 ban4"
  ],
  "千": [
    "qian1",
    "qian1"
  ],
  "祚": [
    "zuo4"
  ],
  "敏捷": [
    "min3 jie2"
  ],
  "水货": [
    "shui3 huo4"
  ],
  "近年来": [
    "jin4 nian2 lai2"
  ],
  "节奏": [
    "jie2 zou4"
  ],
  "怀着": [
    null
  ],
  "体育场": [
    "ti3 yu4 chang3"
  ],
  "支付": [
    "zhi1 fu4"
  ],
  "丛林": [
    "cong2 lin2"
  ],
  "沏": [
    "qi1"
  ],
  "草原": [
    "cao3 yuan2"
  ],
  "渠道": [
    "qu2 dao4"
  ],
  "平常心": [
    "ping2 chang2 xin1"
  ],
  "衃": [
    null
  ],
  "丈": [
    "zhang4"
  ],
  "勃": [
    "bo2"
  ],
  "棰": [
    "chui2",
    "chui2"
  ],
  "脾气": [
    "pi2 qi5"
  ],
  "相对": [
    "xiang1 dui4"
  ],
  "绚": [
    "xuan4"
  ],
  "基本上": [
    "ji1 ben3 shang5"
  ],
  "笑": [
    "xiao4",
    "xiao4"
  ],
  "塝": [
    "bang4"
  ],
  "砠": [
    "qu1"
  ],
  "来龙去脉": [
    "lai2 long2 qu4 mai4"
  ],
  "喜剧": [
    "xi3 ju4"
  ],
  "雕塑": [
    "diao1 su4"
  ],
  "裤子": [
    "ku4 zi5",
    "ku4 zi5"
  ],
  "远离": [
    "yuan3 li2"
  ],
  "妥善": [
    "tuo3 shan4"
  ],
  "坌": [
    "ben4"
  ],
  "菝": [
    "ba2"
  ],
  "反复": [
    "fan3 fu4",
    "fan3 fu4"
  ],
  "镇定": [
    "zhen4 ding4"
  ],
  "羕": [
    null
  ],
  "遘": [
    "gou4"
  ],
  "凭": [
    "ping2",
    "ping2"
  ],
  "控制": [
    "kong4 zhi4"
  ],
  "平价": [
    "ping2 jia4"
  ],
  "去除": [
    "qu4 chu2"
  ],
  "犹豫": [
    "you2 yu4"
  ],
  "搭档": [
    "da1 dang4"
  ],
  "今天": [
    "jin1 tian1"
  ],
  "胛": [
    "jia3"
  ],
  "递给": [
    "di4 gei3"
  ],
  "西方": [
    "Xi1 fang1"
  ],
  "帝": [
    "di4"
  ],
  "财产": [
    "cai2 chan3"
  ],
  "全都": [
    "quan2 dou1"
  ],
  "而": [
    "er2"
  ],
  "疏": [
    "shu1",
    "Shu1",
    "shu1"
  ],
  "重任": [
    "zhong4 ren4"
  ],
  "俱乐部": [
    "ju4 le4 bu4"
  ],
  "这些": [
    "zhe4 xie1"
  ],
  "吽": [
    "hong1",
    "hou3"
  ],
  "疹": [
    "zhen3"
  ],
  "笔": [
    "bi3"
  ],
  "接力": [
    "jie1 li4"
  ],
  "阆": [
    "lang2",
    "lang4"
  ],
  "塞": [
    "Sai1",
    "sai1",
    "sai4",
    "se4"
  ],
  "还原": [
    "huan2 yuan2"
  ],
  "想不到": [
    "xiang3 bu5 dao4"
  ],
  "脑": [
    "nao3"
  ],
  "赞同": [
    "zan4 tong2",
    "zan4 tong2"
  ],
  "出台": [
    "chu1 tai2"
  ],
  "了结": [
    "liao3 jie2"
  ],
  "卤味": [
    "lu3 wei4",
    "lu3 wei4"
  ],
  "孥": [
    "nu2"
  ],
  "海运": [
    "hai3 yun4"
  ],
  "蕴": [
    "yun4"
  ],
  "从来不": [
    "cong2 lai2 bu4"
  ],
  "生存": [
    "sheng1 cun2"
  ],
  "官": [
    "Guan1",
    "guan1"
  ],
  "汧": [
    "Qian1",
    "qian1"
  ],
  "上楼": [
    "shang4 lou2"
  ],
  "酗": [
    "xu4"
  ],
  "披": [
    "pi1"
  ],
  "㠇": [
    "jiu4"
  ],
  "啊": [
    "a1",
    "a2",
    "a3",
    "a4",
    "a5"
  ],
  "祖国": [
    "zu3 guo2"
  ],
  "焚": [
    "fen2"
  ],
  "示意": [
    "shi4 yi4"
  ],
  "棕": [
    "zong1",
    "zong1"
  ],
  "惮": [
    "dan4"
  ],
  "偾": [
    "fen4"
  ],
  "设法": [
    "she4 fa3"
  ],
  "媭": [
    "xu1"
  ],
  "当选": [
    "dang1 xuan3"
  ],
  "深切": [
    "shen1 qie4"
  ],
  "蓖": [
    "bi4"
  ],
  "㠓": [
    null
  ],
  "照料": [
    "zhao4 liao4"
  ],
  "肝脏": [
    "gan1 zang4"
  ],
  "一行": [
    "yi1 xing2"
  ],
  "野生": [
    "ye3 sheng1"
  ],
  "颌": [
    "he2"
  ],
  "拤": [
    "qia2"
  ],
  "懒惰": [
    "lan3 duo4"
  ],
  "烛": [
    "zhu2"
  ],
  "姓氏": [
    "xing4 shi4"
  ],
  "减少": [
    "jian3 shao3"
  ],
  "柿子": [
    "shi4 zi5"
  ],
  "文化": [
    "wen2 hua4"
  ],
  "靖": [
    "Jing4",
    "jing4"
  ],
  "脱节": [
    "tuo1 jie2"
  ],
  "饕": [
    "tao1"
  ],
  "庥": [
    "xiu1"
  ],
  "萧条": [
    "xiao1 tiao2"
  ],
  "间隙": [
    "jian4 xi4"
  ],
  "请求": [
    "qing3 qiu2"
  ],
  "蕈": [
    "xun4"
  ],
  "幅": [
    "fu2"
  ],
  "榱": [
    "cui1"
  ],
  "訄": [
    "qiu2"
  ],
  "斶": [
    null
  ],
  "蓉": [
    "Rong2",
    "rong2"
  ],
  "抡": [
    "lun1",
    "lun2"
  ],
  "平原": [
    "ping2 yuan2"
  ],
  "红薯": [
    "hong2 shu3"
  ],
  "不准": [
    "bu4 zhun3"
  ],
  "瓯": [
    "Ou1",
    "ou1"
  ],
  "崾": [
    "yao3"
  ],
  "蚊子": [
    "wen2 zi5"
  ],
  "腈": [
    "jing1"
  ],
  "遗体": [
    "yi2 ti3"
  ],
  "鲁莽": [
    "lu3 mang3"
  ],
  "煦": [
    "xu4"
  ],
  "成年": [
    "cheng2 nian2"
  ],
  "有时候": [
    "you3 shi2 hou5"
  ],
  "壹": [
    "yi1"
  ],
  "喂养": [
    "wei4 yang3"
  ],
  "睹": [
    "du3",
    "du3"
  ],
  "吸取": [
    "xi1 qu3"
  ],
  "蟛": [
    "peng2"
  ],
  "隐情": [
    "yin3 qing2"
  ],
  "东方": [
    "Dong1 fang1",
    "dong1 fang1"
  ],
  "种类": [
    "zhong3 lei4"
  ],
  "蝼": [
    "lou2"
  ],
  "呻": [
    "shen1"
  ],
  "诱": [
    "you4"
  ],
  "划": [
    "hua2",
    "hua2",
    "hua4"
  ],
  "暖和": [
    "nuan3 huo5"
  ],
  "晕": [
    "yun1",
    "yun4"
  ],
  "釜": [
    "fu3"
  ],
  "大道": [
    "da4 dao4"
  ],
  "躐": [
    "lie4"
  ],
  "搁": [
    "ge1",
    "ge2"
  ],
  "研究所": [
    "yan2 jiu1 suo3"
  ],
  "狺": [
    "yin2"
  ],
  "禸": [
    "rou2"
  ],
  "镨": [
    "pu3"
  ],
  "改邪归正": [
    "gai3 xie2 gui1 zheng4"
  ],
  "青春": [
    "qing1 chun1"
  ],
  "慥": [
    "zao4"
  ],
  "下场": [
    "xia4 chang3",
    "xia4 chang5"
  ],
  "蹼": [
    "pu3"
  ],
  "涅": [
    "nie4",
    "nie4"
  ],
  "对称": [
    "dui4 chen4"
  ],
  "磨难": [
    "mo2 nan4"
  ],
  "舞台": [
    "wu3 tai2"
  ],
  "射击": [
    "she4 ji1"
  ],
  "劂": [
    "jue2"
  ],
  "鉴定": [
    "jian4 ding4"
  ],
  "回来": [
    "hui2 lai5"
  ],
  "空儿": [
    "kong4 r5"
  ],
  "终止": [
    "zhong1 zhi3"
  ],
  "迁就": [
    "qian1 jiu4"
  ],
  "留意": [
    "liu2 yi4"
  ],
  "堧": [
    "ruan2"
  ],
  "霾": [
    "mai2"
  ],
  "上涨": [
    "shang4 zhang3"
  ],
  "乏": [
    "fa2"
  ],
  "戒": [
    "jie4"
  ],
  "糈": [
    "xu3"
  ],
  "飧": [
    "sun1",
    "sun1"
  ],
  "丝毫": [
    "si1 hao2"
  ],
  "怪": [
    "guai4",
    "guai4"
  ],
  "萩": [
    "qiu1"
  ],
  "涞": [
    "lai2"
  ],
  "莺": [
    "ying1",
    "ying1"
  ],
  "藤椅": [
    "teng2 yi3"
  ],
  "快速": [
    "kuai4 su4"
  ],
  "特别": [
    "te4 bie2"
  ],
  "男朋友": [
    "nan2 peng2 you5"
  ],
  "保质期": [
    "bao3 zhi4 qi1"
  ],
  "邮": [
    "you2"
  ],
  "恐慌": [
    "kong3 huang1"
  ],
  "盾": [
    "dun4"
  ],
  "一切": [
    "yi1 qie4"
  ],
  "殒": [
    "yun3"
  ],
  "审核": [
    "shen3 he2"
  ],
  "添": [
    "tian1"
  ],
  "向上": [
    "xiang4 shang4"
  ],
  "得天独厚": [
    "de2 tian1 du2 hou4"
  ],
  "网站": [
    "wang3 zhan4"
  ],
  "羊": [
    "Yang2",
    "yang2"
  ],
  "作文": [
    "zuo4 wen2"
  ],
  "述": [
    "shu4"
  ],
  "课本": [
    "ke4 ben3"
  ],
  "视角": [
    "shi4 jiao3"
  ],
  "芭": [
    "ba1"
  ],
  "尊敬": [
    "zun1 jing4"
  ],
  "宽": [
    "Kuan1",
    "kuan1"
  ],
  "梅": [
    "Mei2",
    "mei2",
    "mei2",
    "mei2"
  ],
  "倨": [
    "ju4"
  ],
  "廛": [
    "chan2"
  ],
  "民主": [
    "min2 zhu3"
  ],
  "国画": [
    "guo2 hua4"
  ],
  "他": [
    "ta1"
  ],
  "日语": [
    "Ri4 yu3"
  ],
  "孅": [
    "xian1"
  ],
  "猜": [
    "cai1"
  ],
  "尖端": [
    "jian1 duan1"
  ],
  "不解": [
    "bu4 jie3"
  ],
  "契": [
    "qi4"
  ],
  "鳑": [
    "pang2"
  ],
  "传人": [
    "chuan2 ren2"
  ],
  "瞽": [
    "gu3"
  ],
  "卅": [
    "sa4"
  ],
  "觚": [
    "gu1"
  ],
  "亿": [
    "yi4"
  ],
  "大都": [
    "Da4 du1",
    "da4 dou1",
    "da4 du1"
  ],
  "殿堂": [
    "dian4 tang2"
  ],
  "亨": [
    "heng1"
  ],
  "过剩": [
    "guo4 sheng4"
  ],
  "榰": [
    "zhi1"
  ],
  "罢休": [
    "ba4 xiu1"
  ],
  "颁奖": [
    "ban1 jiang3"
  ],
  "造成": [
    "zao4 cheng2"
  ],
  "经常": [
    "jing1 chang2"
  ],
  "秃": [
    "tu1"
  ],
  "汪": [
    "Wang1",
    "wang1"
  ],
  "边缘": [
    "bian1 yuan2"
  ],
  "良心": [
    "liang2 xin1"
  ],
  "索性": [
    "suo3 xing4"
  ],
  "衔": [
    "xian2",
    "xian2",
    "xian2"
  ],
  "分化": [
    "fen1 hua4"
  ],
  "火花": [
    "huo3 hua1"
  ],
  "迎": [
    "ying2"
  ],
  "值得": [
    "zhi2 de5"
  ],
  "顿时": [
    "dun4 shi2"
  ],
  "查看": [
    "cha2 kan4"
  ],
  "竹": [
    "zhu2"
  ],
  "忽悠": [
    "hu1 you5"
  ],
  "举世无双": [
    "ju3 shi4 wu2 shuang1"
  ],
  "屋": [
    "wu1"
  ],
  "继续": [
    "ji4 xu4"
  ],
  "晃": [
    "huang3",
    "huang3",
    "huang4"
  ],
  "腚": [
    "ding4"
  ],
  "送行": [
    "song4 xing2"
  ],
  "森": [
    "Sen1",
    "sen1"
  ],
  "膨胀": [
    "peng2 zhang4"
  ],
  "鞮": [
    "Di1",
    "di1"
  ],
  "坚硬": [
    "jian1 ying4"
  ],
  "斟": [
    "zhen1"
  ],
  "力量": [
    "li4 liang5"
  ],
  "慢慢来": [
    "man4 man4 lai2"
  ],
  "末": [
    "mo4"
  ],
  "蛘": [
    "yang2"
  ],
  "一句话": [
    "yi1 ju4 hua4"
  ],
  "独立": [
    "du2 li4"
  ],
  "鳐": [
    "yao2"
  ],
  "承办": [
    "cheng2 ban4"
  ],
  "縢": [
    "teng2"
  ],
  "影迷": [
    "ying3 mi2"
  ],
  "执法": [
    "zhi2 fa3"
  ],
  "崇拜": [
    "chong2 bai4"
  ],
  "答辩": [
    "da2 bian4"
  ],
  "墙": [
    "qiang2",
    "qiang2"
  ],
  "狒": [
    "fei4"
  ],
  "螱": [
    null
  ],
  "此外": [
    "ci3 wai4"
  ],
  "毛巾": [
    "mao2 jin1"
  ],
  "廉洁": [
    "lian2 jie2"
  ],
  "阴暗": [
    "yin1 an4"
  ],
  "唪": [
    "feng3"
  ],
  "立足": [
    "li4 zu2"
  ],
  "耗": [
    "hao4"
  ],
  "开辟": [
    "kai1 pi4"
  ],
  "妄": [
    "wang4"
  ],
  "叶子": [
    "ye4 zi5"
  ],
  "粮食": [
    "liang2 shi5"
  ],
  "收支": [
    "shou1 zhi1"
  ],
  "助威": [
    "zhu4 wei1"
  ],
  "茶叶": [
    "cha2 ye4"
  ],
  "迩": [
    "er3"
  ],
  "醒目": [
    "xing3 mu4"
  ],
  "绿地": [
    "lu:4 di4"
  ],
  "楠": [
    "nan2",
    "nan2",
    "nan2"
  ],
  "事务": [
    "shi4 wu4"
  ],
  "收": [
    "shou1"
  ],
  "感想": [
    "gan3 xiang3"
  ],
  "掌": [
    "zhang3"
  ],
  "朱": [
    "Zhu1",
    "zhu1",
    "zhu1"
  ],
  "䴔": [
    "jiao1"
  ],
  "汇集": [
    "hui4 ji2",
    "hui4 ji2"
  ],
  "溯": [
    "su4",
    "su4"
  ],
  "穹": [
    "qiong2"
  ],
  "论": [
    "Lun2",
    "lun4"
  ],
  "谄": [
    "chan3"
  ],
  "谌": [
    "Chen2",
    "chen2"
  ],
  "饭店": [
    "fan4 dian4"
  ],
  "担任": [
    "dan1 ren4"
  ],
  "洄": [
    "hui2"
  ],
  "簖": [
    "duan4"
  ],
  "迹": [
    "ji4",
    "ji4",
    "ji4"
  ],
  "搏": [
    "bo2"
  ],
  "兼顾": [
    "jian1 gu4"
  ],
  "档案": [
    "dang4 an4"
  ],
  "审美": [
    "shen3 mei3"
  ],
  "捐款": [
    "juan1 kuan3"
  ],
  "垾": [
    "han4"
  ],
  "饨": [
    "tun2"
  ],
  "钻研": [
    "zuan1 yan2"
  ],
  "纨": [
    "wan2"
  ],
  "玘": [
    "qi3"
  ],
  "民工": [
    "min2 gong1"
  ],
  "炬": [
    "ju4"
  ],
  "发言人": [
    "fa1 yan2 ren2"
  ],
  "幼儿园": [
    "you4 er2 yuan2"
  ],
  "淘": [
    "tao2"
  ],
  "扼": [
    "e4",
    "e4"
  ],
  "枢": [
    "shu1"
  ],
  "熻": [
    null
  ],
  "湍": [
    "tuan1"
  ],
  "犁": [
    "li2"
  ],
  "恣": [
    "zi4"
  ],
  "搴": [
    "qian1"
  ],
  "扭曲": [
    "niu3 qu1"
  ],
  "闲": [
    "xian2",
    "xian2"
  ],
  "鹿": [
    "lu4"
  ],
  "五颜六色": [
    "wu3 yan2 liu4 se4"
  ],
  "性命": [
    "xing4 ming4"
  ],
  "近代": [
    "jin4 dai4"
  ],
  "欻": [
    "chua1",
    "xu1"
  ],
  "无所事事": [
    "wu2 suo3 shi4 shi4"
  ],
  "涌": [
    "chong1",
    "yong3",
    "yong3"
  ],
  "匼": [
    null
  ],
  "能力": [
    "neng2 li4"
  ],
  "低调": [
    "di1 diao4"
  ],
  "特意": [
    "te4 yi4"
  ],
  "无所谓": [
    "wu2 suo3 wei4"
  ],
  "铃": [
    "ling2"
  ],
  "谱": [
    "pu3"
  ],
  "社会": [
    "she4 hui4"
  ],
  "坟": [
    "fen2"
  ],
  "瓞": [
    "die2"
  ],
  "舐": [
    "shi4"
  ],
  "自相矛盾": [
    "zi4 xiang1 mao2 dun4"
  ],
  "乡村": [
    "xiang1 cun1"
  ],
  "乐意": [
    "le4 yi4"
  ],
  "埌": [
    "lang4"
  ],
  "哪里": [
    "na3 li3",
    "na3 li3"
  ],
  "遗物": [
    "yi2 wu4"
  ],
  "谚": [
    "yan4"
  ],
  "姮": [
    "heng2"
  ],
  "机场": [
    "ji1 chang3"
  ],
  "迨": [
    "dai4"
  ],
  "参赛": [
    "can1 sai4"
  ],
  "蜡烛": [
    "la4 zhu2"
  ],
  "悻": [
    "xing4"
  ],
  "可怕": [
    "ke3 pa4"
  ],
  "奶茶": [
    "nai3 cha2"
  ],
  "汫": [
    "jing3"
  ],
  "凉": [
    "Liang2",
    "liang2",
    "liang4"
  ],
  "助": [
    "zhu4"
  ],
  "挂钩": [
    "gua4 gou1"
  ],
  "一经": [
    "yi1 jing1"
  ],
  "垃圾": [
    "la1 ji1"
  ],
  "妓": [
    "ji4"
  ],
  "俗语": [
    "su2 yu3"
  ],
  "笼子": [
    "long2 zi5",
    "long3 zi5"
  ],
  "镶": [
    "xiang1"
  ],
  "亡羊补牢": [
    "wang2 yang2 bu3 lao2"
  ],
  "绪": [
    "xu4"
  ],
  "心态": [
    "xin1 tai4"
  ],
  "港口": [
    "gang3 kou3"
  ],
  "谴责": [
    "qian3 ze2"
  ],
  "投射": [
    "tou2 she4"
  ],
  "强项": [
    "qiang2 xiang4"
  ],
  "发挥": [
    "fa1 hui1"
  ],
  "斠": [
    "jiao4"
  ],
  "捷": [
    "jie2",
    "Jie2",
    "jie2"
  ],
  "嗨": [
    "hai1"
  ],
  "无力": [
    "wu2 li4"
  ],
  "昱": [
    "yu4"
  ],
  "艾滋病": [
    "ai4 zi1 bing4"
  ],
  "短处": [
    "duan3 chu4"
  ],
  "活": [
    "huo2"
  ],
  "临街": [
    "lin2 jie1"
  ],
  "称呼": [
    "cheng1 hu5"
  ],
  "前天": [
    "qian2 tian1"
  ],
  "裟": [
    "sha1"
  ],
  "田径": [
    "tian2 jing4"
  ],
  "要不是": [
    "yao4 bu5 shi4"
  ],
  "瞻仰": [
    "zhan1 yang3"
  ],
  "都": [
    "Du1",
    "dou1",
    "du1"
  ],
  "号召": [
    "hao4 zhao4"
  ],
  "老伴儿": [
    "lao3 ban4 r5"
  ],
  "柔和": [
    "rou2 he2"
  ],
  "更改": [
    "geng1 gai3"
  ],
  "持之以恒": [
    "chi2 zhi1 yi3 heng2"
  ],
  "急忙": [
    "ji2 mang2"
  ],
  "一下子": [
    "yi1 xia4 zi5"
  ],
  "调": [
    "diao4",
    "tiao2"
  ],
  "潜水": [
    "qian2 shui3"
  ],
  "殿": [
    "dian4"
  ],
  "疡": [
    "yang2"
  ],
  "同类": [
    "tong2 lei4"
  ],
  "睡着": [
    "shui4 zhao2"
  ],
  "不停": [
    "bu4 ting2"
  ],
  "味": [
    "wei4"
  ],
  "桉": [
    "an1"
  ],
  "柽": [
    "cheng1"
  ],
  "瞩目": [
    "zhu3 mu4"
  ],
  "大海": [
    "da4 hai3"
  ],
  "操心": [
    "cao1 xin1"
  ],
  "作出": [
    "zuo4 chu1"
  ],
  "匸": [
    "xi4"
  ],
  "初级": [
    "chu1 ji2"
  ],
  "颇": [
    "Po1",
    "po1"
  ],
  "奉献": [
    "feng4 xian4"
  ],
  "疼痛": [
    "teng2 tong4"
  ],
  "那么": [
    "na4 me5"
  ],
  "惊险": [
    "jing1 xian3"
  ],
  "儿子": [
    "er2 zi5"
  ],
  "图表": [
    "tu2 biao3"
  ],
  "历届": [
    "li4 jie4"
  ],
  "玠": [
    "jie4"
  ],
  "佚": [
    "Yi4",
    "die2",
    "yi4"
  ],
  "持": [
    "chi2"
  ],
  "形容": [
    "xing2 rong2"
  ],
  "搛": [
    "jian1"
  ],
  "此前": [
    "ci3 qian2"
  ],
  "叫作": [
    "jiao4 zuo4"
  ],
  "丈夫": [
    "zhang4 fu5"
  ],
  "凯歌": [
    "kai3 ge1"
  ],
  "黹": [
    "zhi3"
  ],
  "委婉": [
    "wei3 wan3"
  ],
  "洈": [
    null
  ],
  "融合": [
    "rong2 he2"
  ],
  "果树": [
    "guo3 shu4"
  ],
  "校长": [
    "xiao4 zhang3"
  ],
  "无关紧要": [
    "wu2 guan1 jin3 yao4"
  ],
  "澉": [
    "gan3"
  ],
  "深思": [
    "shen1 si1"
  ],
  "引进": [
    "yin3 jin4"
  ],
  "集团": [
    "ji2 tuan2"
  ],
  "来信": [
    "lai2 xin4"
  ],
  "泳": [
    "yong3"
  ],
  "泰斗": [
    "tai4 dou3"
  ],
  "祛": [
    "qu1"
  ],
  "啻": [
    "chi4"
  ],
  "十字路口": [
    "shi2 zi4 lu4 kou3"
  ],
  "减免": [
    "jian3 mian3"
  ],
  "微博": [
    "wei1 bo2"
  ],
  "恹": [
    "yan1"
  ],
  "珫": [
    null
  ],
  "炽": [
    "chi4"
  ],
  "筒": [
    "tong3",
    "tong3"
  ],
  "评论": [
    "ping2 lun4"
  ],
  "不可避免": [
    "bu4 ke3 bi4 mian3"
  ],
  "矣": [
    "yi3"
  ],
  "都会": [
    "du1 hui4"
  ],
  "朴": [
    "Piao2",
    "po1",
    "po4",
    "pu3"
  ],
  "上网": [
    "shang4 wang3"
  ],
  "悲剧": [
    "bei1 ju4"
  ],
  "感染力": [
    "gan3 ran3 li4"
  ],
  "耤": [
    "ji2"
  ],
  "关心": [
    "guan1 xin1"
  ],
  "舒畅": [
    "shu1 chang4"
  ],
  "庄园": [
    "zhuang1 yuan2"
  ],
  "袜": [
    "wa4",
    "wa4",
    "wa4"
  ],
  "丹": [
    "dan1"
  ],
  "半场": [
    "ban4 chang3"
  ],
  "尉": [
    "Wei4",
    "wei4",
    "yu4"
  ],
  "坦白": [
    "tan3 bai2"
  ],
  "火柴": [
    "huo3 chai2"
  ],
  "殛": [
    "ji2"
  ],
  "影子": [
    "ying3 zi5"
  ],
  "锽": [
    "huang2"
  ],
  "灯泡": [
    "deng1 pao4"
  ],
  "氩": [
    "ya4"
  ],
  "提到": [
    "ti2 dao4"
  ],
  "之内": [
    "zhi1 nei4"
  ],
  "守": [
    "shou3"
  ],
  "天": [
    "tian1"
  ],
  "整整": [
    "zheng3 zheng3"
  ],
  "葳": [
    "wei1"
  ],
  "飐": [
    "zhan3"
  ],
  "犹如": [
    "you2 ru2"
  ],
  "觊": [
    "ji4"
  ],
  "补习": [
    "bu3 xi2"
  ],
  "多么": [
    "duo1 me5"
  ],
  "推算": [
    "tui1 suan4"
  ],
  "琢磨": [
    "zhuo2 mo2",
    "zuo2 mo5"
  ],
  "后": [
    "Hou4",
    "hou4",
    "hou4"
  ],
  "弊病": [
    "bi4 bing4"
  ],
  "恼羞成怒": [
    "nao3 xiu1 cheng2 nu4"
  ],
  "遥控": [
    "yao2 kong4"
  ],
  "题": [
    "Ti2",
    "ti2"
  ],
  "紧": [
    "jin3"
  ],
  "相传": [
    "xiang1 chuan2"
  ],
  "再": [
    "zai4"
  ],
  "恻": [
    "ce4"
  ],
  "崀": [
    "lang4"
  ],
  "谖": [
    "xuan1"
  ],
  "归属": [
    "gui1 shu3"
  ],
  "外援": [
    "wai4 yuan2"
  ],
  "修长": [
    "xiu1 chang2"
  ],
  "五星级": [
    "wu3 xing1 ji2"
  ],
  "抖": [
    "dou3"
  ],
  "递": [
    "di4"
  ],
  "阶梯": [
    "jie1 ti1"
  ],
  "浣": [
    "huan4",
    "huan4"
  ],
  "毐": [
    "Ai3"
  ],
  "寄托": [
    "ji4 tuo1"
  ],
  "藠": [
    "jiao4"
  ],
  "秀美": [
    "xiu4 mei3"
  ],
  "溦": [
    "wei1"
  ],
  "市场经济": [
    "shi4 chang3 jing1 ji4"
  ],
  "不由自主": [
    "bu4 you2 zi4 zhu3"
  ],
  "朝气蓬勃": [
    "zhao1 qi4 peng2 bo2"
  ],
  "掮": [
    "qian2"
  ],
  "盒子": [
    "he2 zi5"
  ],
  "阂": [
    "he2"
  ],
  "黥": [
    "Qing2",
    "qing2"
  ],
  "喁": [
    "yong2",
    "yu2"
  ],
  "凝固": [
    "ning2 gu4"
  ],
  "嗄": [
    "a2",
    "sha4"
  ],
  "融": [
    "rong2",
    "rong2"
  ],
  "灵魂": [
    "ling2 hun2"
  ],
  "假冒": [
    "jia3 mao4"
  ],
  "西餐": [
    "xi1 can1"
  ],
  "深情": [
    "shen1 qing2"
  ],
  "相机": [
    "xiang4 ji1"
  ],
  "难为情": [
    "nan2 wei2 qing2"
  ],
  "扫兴": [
    "sao3 xing4"
  ],
  "深信": [
    "shen1 xin4"
  ],
  "惯": [
    "guan4"
  ],
  "安": [
    "An1",
    "an1"
  ],
  "汨": [
    "Mi4"
  ],
  "谬": [
    "miu4"
  ],
  "吸引": [
    "xi1 yin3"
  ],
  "腕": [
    "wan4"
  ],
  "口令": [
    "kou3 ling4"
  ],
  "潜": [
    "qian2"
  ],
  "难题": [
    "nan2 ti2"
  ],
  "免得": [
    "mian3 de5"
  ],
  "靰": [
    "wu4"
  ],
  "支援": [
    "zhi1 yuan2"
  ],
  "文": [
    "Wen2",
    "wen2"
  ],
  "坒": [
    "bi4"
  ],
  "婧": [
    "jing4"
  ],
  "一手": [
    "yi1 shou3"
  ],
  "煽": [
    "shan1"
  ],
  "盆": [
    "pen2"
  ],
  "短期": [
    "duan3 qi1"
  ],
  "庄严": [
    "zhuang1 yan2"
  ],
  "切实": [
    "qie4 shi2"
  ],
  "父母": [
    "fu4 mu3"
  ],
  "茶馆儿": [
    "cha2 guan3 r5"
  ],
  "琔": [
    null
  ],
  "辩": [
    "bian4"
  ],
  "圯": [
    "yi2"
  ],
  "姿势": [
    "zi1 shi4"
  ],
  "清凉": [
    "qing1 liang2"
  ],
  "何必": [
    "he2 bi4"
  ],
  "风筝": [
    "feng1 zheng1"
  ],
  "赋": [
    "fu4"
  ],
  "边": [
    "bian1",
    "bian5"
  ],
  "出路": [
    "chu1 lu4"
  ],
  "悬念": [
    "xuan2 nian4"
  ],
  "领事": [
    "ling3 shi4"
  ],
  "诛": [
    "zhu1"
  ],
  "忠实": [
    "zhong1 shi2"
  ],
  "锹": [
    "qiao1",
    "qiao1"
  ],
  "妒": [
    "du4",
    "du4"
  ],
  "无可奉告": [
    "wu2 ke3 feng4 gao4"
  ],
  "人事": [
    "ren2 shi4"
  ],
  "不要": [
    "bu4 yao4"
  ],
  "淠": [
    "pi4"
  ],
  "少不了": [
    "shao3 bu5 liao3"
  ],
  "胴": [
    "dong4"
  ],
  "报到": [
    "bao4 dao4"
  ],
  "扛": [
    "gang1",
    "kang2",
    "gang1"
  ],
  "繁体字": [
    "fan2 ti3 zi4"
  ],
  "尼龙": [
    "ni2 long2"
  ],
  "鸡": [
    "ji1"
  ],
  "点击率": [
    "dian3 ji1 lu:4"
  ],
  "箖": [
    null
  ],
  "学分": [
    "xue2 fen1"
  ],
  "财力": [
    "cai2 li4"
  ],
  "开朗": [
    "kai1 lang3"
  ],
  "组": [
    "Zu3",
    "zu3"
  ],
  "蛑": [
    "mou2"
  ],
  "晚上": [
    "wan3 shang5"
  ],
  "刷牙": [
    "shua1 ya2"
  ],
  "芪": [
    "qi2"
  ],
  "天上": [
    "tian1 shang4"
  ],
  "乐园": [
    "le4 yuan2"
  ],
  "透过": [
    "tou4 guo4"
  ],
  "疋": [
    "shu1",
    "ya3"
  ],
  "麈": [
    "zhu3"
  ],
  "白菜": [
    "bai2 cai4"
  ],
  "嘞": [
    "lei5"
  ],
  "凫": [
    "fu2",
    "fu2"
  ],
  "光碟": [
    "guang1 die2"
  ],
  "町": [
    "ding1",
    "ting3"
  ],
  "海滨": [
    "hai3 bin1"
  ],
  "荽": [
    "sui1"
  ],
  "蒱": [
    "pu2"
  ],
  "多少": [
    "duo1 shao3",
    "duo1 shao5"
  ],
  "多功能": [
    "duo1 gong1 neng2"
  ],
  "记忆": [
    "ji4 yi4"
  ],
  "教育部": [
    "Jiao4 yu4 bu4"
  ],
  "锘": [
    "nuo4"
  ],
  "松绑": [
    "song1 bang3"
  ],
  "窸": [
    "xi1"
  ],
  "厘": [
    "li2",
    "Li2",
    "li2"
  ],
  "粒": [
    "li4"
  ],
  "多年来": [
    "duo1 nian2 lai2"
  ],
  "褶": [
    "xi2",
    "zhe3"
  ],
  "猎人": [
    "lie4 ren2"
  ],
  "专辑": [
    "zhuan1 ji2"
  ],
  "墦": [
    "fan2"
  ],
  "理财": [
    "li3 cai2"
  ],
  "便利店": [
    "bian4 li4 dian4"
  ],
  "麖": [
    "jing1"
  ],
  "传奇": [
    "chuan2 qi2"
  ],
  "轰": [
    "hong1"
  ],
  "鄚": [
    "Mo4"
  ],
  "展览": [
    "zhan3 lan3"
  ],
  "图片": [
    "tu2 pian4"
  ],
  "镩": [
    "cuan1"
  ],
  "休": [
    "Xiu1",
    "xiu1"
  ],
  "恢": [
    "hui1"
  ],
  "臼": [
    "jiu4"
  ],
  "冻结": [
    "dong4 jie2"
  ],
  "断": [
    "duan4"
  ],
  "弘": [
    "hong2"
  ],
  "一点儿": [
    "yi1 dian3 r5"
  ],
  "沾光": [
    "zhan1 guang1"
  ],
  "通信": [
    "tong1 xin4"
  ],
  "竖": [
    "shu4",
    "shu4"
  ],
  "璋": [
    "zhang1"
  ],
  "科技": [
    "ke1 ji4"
  ],
  "鞋": [
    "xie2",
    "xie2"
  ],
  "外界": [
    "wai4 jie4"
  ],
  "没辙": [
    "mei2 zhe2"
  ],
  "锁定": [
    "suo3 ding4"
  ],
  "屙": [
    "e1"
  ],
  "宰": [
    "zai3"
  ],
  "纷": [
    "fen1"
  ],
  "犇": [
    "Ben1"
  ],
  "班": [
    "Ban1",
    "ban1"
  ],
  "笔记": [
    "bi3 ji4"
  ],
  "芦花": [
    "lu2 hua1"
  ],
  "抹": [
    "ma1",
    "mo3",
    "mo4"
  ],
  "应": [
    "Ying1",
    "ying1",
    "ying4"
  ],
  "派遣": [
    "pai4 qian3"
  ],
  "糊涂": [
    "hu2 tu5"
  ],
  "貊": [
    "mo4"
  ],
  "精英": [
    "jing1 ying1"
  ],
  "绎": [
    "yi4"
  ],
  "眷": [
    "juan4",
    "juan4"
  ],
  "房地产": [
    "fang2 di4 chan3"
  ],
  "宜": [
    "Yi2",
    "yi2"
  ],
  "摆设": [
    "bai3 she4",
    "bai3 she5"
  ],
  "梿": [
    "lian2"
  ],
  "输": [
    "shu1"
  ],
  "尤为": [
    "you2 wei2"
  ],
  "遇见": [
    "yu4 jian4"
  ],
  "预防": [
    "yu4 fang2"
  ],
  "俪": [
    "li4"
  ],
  "决赛": [
    "jue2 sai4"
  ],
  "珕": [
    null
  ],
  "沉淀": [
    "chen2 dian4"
  ],
  "歌迷": [
    "ge1 mi2"
  ],
  "㧐": [
    "song3"
  ],
  "教训": [
    "jiao4 xun5"
  ],
  "阄": [
    "jiu1"
  ],
  "折射": [
    "zhe2 she4"
  ],
  "偏方": [
    "pian1 fang1"
  ],
  "婷": [
    "ting2"
  ],
  "缥": [
    "piao1",
    "piao3"
  ],
  "怅": [
    "chang4"
  ],
  "智能": [
    "zhi4 neng2"
  ],
  "旗袍": [
    "qi2 pao2"
  ],
  "栊": [
    "long2"
  ],
  "颛": [
    "Zhuan1",
    "zhuan1"
  ],
  "丢": [
    "diu1"
  ],
  "凼": [
    "dang4"
  ],
  "阅": [
    "yue4"
  ],
  "起步": [
    "qi3 bu4"
  ],
  "寿": [
    "Shou4",
    "shou4"
  ],
  "葭": [
    "jia1"
  ],
  "阵": [
    "zhen4"
  ],
  "广义": [
    "guang3 yi4"
  ],
  "亦": [
    "yi4"
  ],
  "俺": [
    "an3"
  ],
  "递交": [
    "di4 jiao1"
  ],
  "咴": [
    "hui1"
  ],
  "迟": [
    "Chi2",
    "chi2"
  ],
  "薯": [
    "shu3",
    "shu3"
  ],
  "镖": [
    "biao1"
  ],
  "中秋节": [
    "Zhong1 qiu1 jie2"
  ],
  "配套": [
    "pei4 tao4"
  ],
  "娩": [
    "mian3",
    "wan3"
  ],
  "秉承": [
    "bing3 cheng2"
  ],
  "谼": [
    null
  ],
  "梽": [
    null
  ],
  "假使": [
    "jia3 shi3"
  ],
  "形势": [
    "xing2 shi4"
  ],
  "扁": [
    "Pian1",
    "bian3",
    "pian1"
  ],
  "免疫": [
    "mian3 yi4"
  ],
  "滪": [
    "Yu4"
  ],
  "隅": [
    "yu2"
  ],
  "策划": [
    "ce4 hua4"
  ],
  "赞": [
    "zan4",
    "zan4",
    "zan4",
    "zan4"
  ],
  "镏": [
    "liu2"
  ],
  "漫画": [
    "man4 hua4"
  ],
  "米饭": [
    "mi3 fan4"
  ],
  "黠": [
    "xia2"
  ],
  "错别字": [
    "cuo4 bie2 zi4"
  ],
  "团聚": [
    "tuan2 ju4"
  ],
  "出现": [
    "chu1 xian4"
  ],
  "居": [
    "Ju1",
    "ji1",
    "ju1"
  ],
  "容量": [
    "rong2 liang4"
  ],
  "中断": [
    "zhong1 duan4"
  ],
  "口碑": [
    "kou3 bei1"
  ],
  "流血": [
    "liu2 xue4"
  ],
  "逢": [
    "feng2"
  ],
  "心脏病": [
    "xin1 zang4 bing4"
  ],
  "体制": [
    "ti3 zhi4"
  ],
  "胜出": [
    "sheng4 chu1"
  ],
  "静": [
    "jing4"
  ],
  "必需": [
    "bi4 xu1"
  ],
  "定价": [
    "ding4 jia4"
  ],
  "珵": [
    "cheng2"
  ],
  "佗": [
    "tuo2"
  ],
  "学费": [
    "xue2 fei4"
  ],
  "元": [
    "Yuan2",
    "yuan2"
  ],
  "芝士": [
    "zhi1 shi4"
  ],
  "辄": [
    "zhe2",
    "zhe2"
  ],
  "漳": [
    "Zhang1"
  ],
  "恂": [
    "xun2"
  ],
  "炮": [
    "bao1",
    "pao2",
    "pao4",
    "pao4"
  ],
  "经典": [
    "jing1 dian3"
  ],
  "双赢": [
    "shuang1 ying2"
  ],
  "蜀": [
    "Shu3"
  ],
  "限": [
    "xian4"
  ],
  "畅销": [
    "chang4 xiao1"
  ],
  "死心": [
    "si3 xin1"
  ],
  "榫": [
    "sun3"
  ],
  "赎": [
    "shu2"
  ],
  "客人": [
    "ke4 ren2"
  ],
  "孤单": [
    "gu1 dan1"
  ],
  "辛勤": [
    "xin1 qin2"
  ],
  "漫": [
    "man4"
  ],
  "敬请": [
    "jing4 qing3"
  ],
  "避": [
    "bi4"
  ],
  "休克": [
    "xiu1 ke4"
  ],
  "灿烂": [
    "can4 lan4"
  ],
  "精品": [
    "jing1 pin3"
  ],
  "蚪": [
    "dou3"
  ],
  "黛": [
    "dai4"
  ],
  "记载": [
    "ji4 zai3"
  ],
  "觋": [
    "xi2"
  ],
  "芘": [
    "pi2"
  ],
  "老家": [
    "lao3 jia1"
  ],
  "自然": [
    "zi4 ran2"
  ],
  "短缺": [
    "duan3 que1"
  ],
  "碡": [
    "zhou2"
  ],
  "自行车": [
    "zi4 xing2 che1"
  ],
  "预兆": [
    "yu4 zhao4"
  ],
  "蹉": [
    "cuo1"
  ],
  "指南": [
    "zhi3 nan2"
  ],
  "晚餐": [
    "wan3 can1"
  ],
  "菉": [
    "lu4"
  ],
  "窗户": [
    "chuang1 hu5"
  ],
  "评定": [
    "ping2 ding4"
  ],
  "友善": [
    "you3 shan4"
  ],
  "诮": [
    "qiao4"
  ],
  "迫使": [
    "po4 shi3"
  ],
  "畯": [
    "jun4"
  ],
  "醅": [
    "pei1"
  ],
  "坩": [
    "gan1"
  ],
  "首创": [
    "shou3 chuang4"
  ],
  "面对": [
    "mian4 dui4"
  ],
  "了却": [
    "liao3 que4"
  ],
  "打搅": [
    "da3 jiao3"
  ],
  "惎": [
    "ji4"
  ],
  "调解": [
    "tiao2 jie3"
  ],
  "财务": [
    "cai2 wu4"
  ],
  "俳": [
    "pai2"
  ],
  "挂号": [
    "gua4 hao4"
  ],
  "虸": [
    "zi3"
  ],
  "愐": [
    "mian3"
  ],
  "申领": [
    "shen1 ling3"
  ],
  "被动": [
    "bei4 dong4"
  ],
  "狡": [
    "jiao3"
  ],
  "救": [
    "jiu4",
    "jiu4"
  ],
  "处": [
    "chu3",
    "chu4"
  ],
  "通常": [
    "tong1 chang2"
  ],
  "诸多": [
    "zhu1 duo1"
  ],
  "合乎": [
    "he2 hu1"
  ],
  "穆": [
    "Mu4",
    "mu4"
  ],
  "周密": [
    "zhou1 mi4"
  ],
  "漫长": [
    "man4 chang2"
  ],
  "停": [
    "ting2"
  ],
  "煌": [
    "huang2"
  ],
  "峿": [
    "wu2"
  ],
  "防": [
    "fang2"
  ],
  "总共": [
    "zong3 gong4"
  ],
  "组成": [
    "zu3 cheng2"
  ],
  "合作社": [
    "he2 zuo4 she4"
  ],
  "悬殊": [
    "xuan2 shu1"
  ],
  "盛开": [
    "sheng4 kai1"
  ],
  "备": [
    "bei4",
    "bei4"
  ],
  "触摸": [
    "chu4 mo1"
  ],
  "可惜": [
    "ke3 xi1"
  ],
  "聆": [
    "ling2"
  ],
  "葙": [
    "xiang1"
  ],
  "穑": [
    "se4"
  ],
  "侵权": [
    "qin1 quan2"
  ],
  "买不起": [
    "mai3 bu5 qi3"
  ],
  "美中不足": [
    "mei3 zhong1 bu4 zu2"
  ],
  "撞击": [
    "zhuang4 ji1"
  ],
  "虍": [
    "hu1"
  ],
  "牚": [
    "cheng1"
  ],
  "徜": [
    "chang2"
  ],
  "方向盘": [
    "fang1 xiang4 pan2"
  ],
  "航海": [
    "hang2 hai3"
  ],
  "嘬": [
    "chuai4",
    "zuo1"
  ],
  "婆": [
    "po2"
  ],
  "禽": [
    "qin2"
  ],
  "申报": [
    "shen1 bao4"
  ],
  "车票": [
    "che1 piao4"
  ],
  "投身": [
    "tou2 shen1"
  ],
  "衣架": [
    "yi1 jia4"
  ],
  "遵": [
    "zun1"
  ],
  "分裂": [
    "fen1 lie4"
  ],
  "延续": [
    "yan2 xu4"
  ],
  "渍": [
    "zi4"
  ],
  "绅": [
    "shen1"
  ],
  "幸存": [
    "xing4 cun2"
  ],
  "缔": [
    "di4"
  ],
  "发现": [
    "fa1 xian4"
  ],
  "圙": [
    "lu:e4"
  ],
  "杜绝": [
    "du4 jue2"
  ],
  "质量": [
    "zhi4 liang4"
  ],
  "壤": [
    "rang3"
  ],
  "蚺": [
    "ran2"
  ],
  "高涨": [
    "gao1 zhang3"
  ],
  "概论": [
    "gai4 lun4"
  ],
  "胤": [
    "yin4"
  ],
  "伤残": [
    "shang1 can2"
  ],
  "华侨": [
    "Hua2 qiao2"
  ],
  "自以为是": [
    "zi4 yi3 wei2 shi4"
  ],
  "小": [
    "xiao3"
  ],
  "凭借": [
    "ping2 jie4"
  ],
  "靛": [
    "dian4"
  ],
  "留": [
    "liu2",
    "liu2",
    "liu2"
  ],
  "镪": [
    "qiang1",
    "qiang3"
  ],
  "胡": [
    "Hu2",
    "hu2",
    "hu2",
    "hu2"
  ],
  "属": [
    "shu3",
    "zhu3"
  ],
  "被迫": [
    "bei4 po4"
  ],
  "骈": [
    "Pian2",
    "pian2"
  ],
  "纠": [
    "jiu1",
    "jiu1"
  ],
  "新郎": [
    "xin1 lang2"
  ],
  "鼍": [
    "tuo2"
  ],
  "瞰": [
    "kan4",
    "kan4"
  ],
  "站住": [
    "zhan4 zhu4"
  ],
  "琉": [
    "liu2",
    "liu2",
    "liu2"
  ],
  "顺心": [
    "shun4 xin1"
  ],
  "甾": [
    "zai1"
  ],
  "针锋相对": [
    "zhen1 feng1 xiang1 dui4"
  ],
  "吃不上": [
    "chi1 bu5 shang4"
  ],
  "领土": [
    "ling3 tu3"
  ],
  "挟持": [
    "xie2 chi2"
  ],
  "矍": [
    "Jue2",
    "jue2"
  ],
  "异想天开": [
    "yi4 xiang3 - tian1 kai1"
  ],
  "倪": [
    "Ni2",
    "ni2"
  ],
  "氰": [
    "qing2"
  ],
  "唳": [
    "li4"
  ],
  "睢": [
    "Sui1",
    "sui1"
  ],
  "氏": [
    "shi4",
    "zhi1"
  ],
  "年龄": [
    "nian2 ling2"
  ],
  "影像": [
    "ying3 xiang4"
  ],
  "表态": [
    "biao3 tai4"
  ],
  "钞": [
    "chao1"
  ],
  "眠": [
    "mian2"
  ],
  "轻微": [
    "qing1 wei1"
  ],
  "病床": [
    "bing4 chuang2"
  ],
  "席": [
    "Xi2",
    "xi2",
    "xi2"
  ],
  "分支": [
    "fen1 zhi1"
  ],
  "蒸": [
    "zheng1"
  ],
  "部长": [
    "bu4 zhang3"
  ],
  "有些": [
    "you3 xie1"
  ],
  "大面积": [
    "da4 mian4 ji1"
  ],
  "踟": [
    "chi2"
  ],
  "不慎": [
    "bu4 shen4"
  ],
  "夥": [
    "huo3"
  ],
  "苹果": [
    "ping2 guo3"
  ],
  "襄": [
    "Xiang1",
    "xiang1"
  ],
  "骘": [
    "zhi4"
  ],
  "唢": [
    "suo3"
  ],
  "合影": [
    "he2 ying3"
  ],
  "鲲": [
    "kun1"
  ],
  "方便面": [
    "fang1 bian4 mian4"
  ],
  "潾": [
    "lin2"
  ],
  "轮": [
    "lun2"
  ],
  "楩": [
    "pian2"
  ],
  "聒": [
    "guo1"
  ],
  "大臣": [
    "da4 chen2"
  ],
  "颢": [
    "hao4"
  ],
  "洇": [
    "yan1",
    "yin1"
  ],
  "险": [
    "xian3"
  ],
  "猷": [
    "you2"
  ],
  "角": [
    "Jue2",
    "jiao3",
    "jue2"
  ],
  "黩": [
    "du2"
  ],
  "燃料": [
    "ran2 liao4"
  ],
  "笃": [
    "du3"
  ],
  "拇": [
    "mu3"
  ],
  "主意": [
    "zhu3 yi5"
  ],
  "鼠标": [
    "shu3 biao1"
  ],
  "任何": [
    "ren4 he2"
  ],
  "环保": [
    "huan2 bao3"
  ],
  "滕": [
    "Teng2"
  ],
  "欢快": [
    "huan1 kuai4"
  ],
  "缀": [
    "chuo4",
    "zhui4"
  ],
  "年前": [
    "nian2 qian2"
  ],
  "果真": [
    "guo3 zhen1"
  ],
  "人为": [
    "ren2 wei2"
  ],
  "蛋白质": [
    "dan4 bai2 zhi4"
  ],
  "打车": [
    "da3 che1"
  ],
  "屾": [
    "shen1"
  ],
  "永不": [
    "yong3 bu4"
  ],
  "觉": [
    "jiao4",
    "jue2"
  ],
  "表扬": [
    "biao3 yang2"
  ],
  "尊": [
    "zun1"
  ],
  "中型": [
    "zhong1 xing2"
  ],
  "心血": [
    "xin1 xue4"
  ],
  "溏": [
    "tang2"
  ],
  "燮": [
    "Xie4",
    "xie4",
    "xie4"
  ],
  "焦躁": [
    "jiao1 zao4"
  ],
  "组建": [
    "zu3 jian4"
  ],
  "綮": [
    "qi3"
  ],
  "临近": [
    "lin2 jin4"
  ],
  "吃饭": [
    "chi1 fan4"
  ],
  "屃": [
    "xi4",
    "xi4"
  ],
  "口头": [
    "kou3 tou2"
  ],
  "桡": [
    "rao2"
  ],
  "摽": [
    "biao1",
    "biao4"
  ],
  "川": [
    "Chuan1",
    "chuan1"
  ],
  "卫生间": [
    "wei4 sheng1 jian1"
  ],
  "一直": [
    "yi1 zhi2"
  ],
  "肭": [
    "na4"
  ],
  "如同": [
    "ru2 tong2"
  ],
  "自立": [
    "zi4 li4"
  ],
  "跱": [
    "zhi4"
  ],
  "辩护": [
    "bian4 hu4"
  ],
  "塬": [
    "yuan2"
  ],
  "蛐": [
    "qu1"
  ],
  "存放": [
    "cun2 fang4"
  ],
  "镇": [
    "zhen4"
  ],
  "举例": [
    "ju3 li4"
  ],
  "算": [
    "suan4"
  ],
  "多样": [
    "duo1 yang4"
  ],
  "掷": [
    "zhi4"
  ],
  "嘤": [
    "ying1"
  ],
  "垮": [
    "kua3"
  ],
  "风风雨雨": [
    "feng1 feng1 yu3 yu3"
  ],
  "审批": [
    "shen3 pi1"
  ],
  "接手": [
    "jie1 shou3"
  ],
  "忐": [
    "tan3"
  ],
  "阻挠": [
    "zu3 nao2"
  ],
  "郎": [
    "Lang2",
    "lang2"
  ],
  "秣": [
    "mo4"
  ],
  "光明磊落": [
    "guang1 ming2 lei3 luo4"
  ],
  "反感": [
    "fan3 gan3"
  ],
  "程": [
    "Cheng2",
    "cheng2"
  ],
  "髭": [
    "zi1"
  ],
  "郐": [
    "Kuai4"
  ],
  "汤": [
    "Tang1",
    "shang1",
    "tang1"
  ],
  "刻苦": [
    "ke4 ku3"
  ],
  "慌乱": [
    "huang1 luan4"
  ],
  "沙": [
    "Sha1",
    "sha1"
  ],
  "存折": [
    "cun2 zhe2"
  ],
  "经济": [
    "jing1 ji4"
  ],
  "迷惑不解": [
    "mi2 huo4 bu4 jie3"
  ],
  "图像": [
    "tu2 xiang4"
  ],
  "球迷": [
    "qiu2 mi2"
  ],
  "鬈": [
    "quan2"
  ],
  "秭": [
    "zi3"
  ],
  "姣": [
    "Jiao1",
    "jiao1"
  ],
  "无故": [
    "wu2 gu4"
  ],
  "威": [
    "wei1"
  ],
  "镕": [
    "rong2"
  ],
  "柒": [
    "qi1"
  ],
  "诸位": [
    "zhu1 wei4"
  ],
  "惊叹": [
    "jing1 tan4"
  ],
  "籁": [
    "lai4"
  ],
  "结尾": [
    "jie2 wei3"
  ],
  "称作": [
    "cheng1 zuo4"
  ],
  "杈": [
    "cha1",
    "cha4"
  ],
  "憙": [
    "xi3"
  ],
  "争吵": [
    "zheng1 chao3"
  ],
  "成立": [
    "cheng2 li4"
  ],
  "基金": [
    "ji1 jin1"
  ],
  "一样": [
    "yi1 yang4"
  ],
  "起码": [
    "qi3 ma3"
  ],
  "柏树": [
    "bai3 shu4"
  ],
  "香味": [
    "xiang1 wei4"
  ],
  "事宜": [
    "shi4 yi2"
  ],
  "凿": [
    "zao2"
  ],
  "谎": [
    "huang3"
  ],
  "升学": [
    "sheng1 xue2"
  ],
  "至少": [
    "zhi4 shao3"
  ],
  "海内外": [
    "hai3 nei4 wai4"
  ],
  "辶": [
    "chuo4"
  ],
  "搋": [
    "chuai1"
  ],
  "疲惫不堪": [
    "pi2 bei4 bu4 kan1"
  ],
  "睇": [
    "di4"
  ],
  "僭": [
    "jian4"
  ],
  "巧克力": [
    "qiao3 ke4 li4"
  ],
  "腴": [
    "yu2"
  ],
  "年纪": [
    "nian2 ji4"
  ],
  "管理费": [
    "guan3 li3 fei4"
  ],
  "铜牌": [
    "tong2 pai2"
  ],
  "日子": [
    "ri4 zi5"
  ],
  "保姆": [
    "bao3 mu3"
  ],
  "也许": [
    "ye3 xu3"
  ],
  "镗": [
    "tang1"
  ],
  "掩盖": [
    "yan3 gai4"
  ],
  "绸": [
    "chou2"
  ],
  "堃": [
    null
  ],
  "病毒": [
    "bing4 du2"
  ],
  "龚": [
    "Gong1"
  ],
  "甬": [
    "Yong3",
    "tong3",
    "yong3"
  ],
  "天桥": [
    "Tian1 qiao2",
    "tian1 qiao2"
  ],
  "脱": [
    "tuo1"
  ],
  "抛": [
    "pao1"
  ],
  "劳动": [
    "lao2 dong4"
  ],
  "瑖": [
    null
  ],
  "虚": [
    "xu1"
  ],
  "线路": [
    "xian4 lu4"
  ],
  "诺": [
    "nuo4"
  ],
  "迎合": [
    "ying2 he2"
  ],
  "耢": [
    "lao4"
  ],
  "结实": [
    "jie1 shi2",
    "jie1 shi5"
  ],
  "外部": [
    "wai4 bu4"
  ],
  "詟": [
    "zhe2"
  ],
  "亸": [
    "duo3"
  ],
  "声音": [
    "sheng1 yin1"
  ],
  "服饰": [
    "fu2 shi4"
  ],
  "焱": [
    "yan4"
  ],
  "埽": [
    "sao4"
  ],
  "看上去": [
    "kan4 shang5 qu5"
  ],
  "不起眼": [
    "bu4 qi3 yan3"
  ],
  "测验": [
    "ce4 yan4"
  ],
  "抢眼": [
    "qiang3 yan3"
  ],
  "祉": [
    "zhi3"
  ],
  "先进": [
    "xian1 jin4"
  ],
  "婉": [
    "wan3"
  ],
  "呋": [
    "fu1"
  ],
  "设置": [
    "she4 zhi4"
  ],
  "贝壳": [
    "bei4 ke2"
  ],
  "帆船": [
    "fan1 chuan2"
  ],
  "槭": [
    "qi4"
  ],
  "饔": [
    "yong1"
  ],
  "衢": [
    "qu2"
  ],
  "种子": [
    "zhong3 zi5"
  ],
  "妃": [
    "fei1"
  ],
  "陂": [
    "bei1",
    "po1"
  ],
  "歌舞": [
    "ge1 wu3"
  ],
  "内存": [
    "nei4 cun2"
  ],
  "蹬": [
    "deng1"
  ],
  "议员": [
    "yi4 yuan2"
  ],
  "怀念": [
    "huai2 nian4"
  ],
  "就业": [
    "jiu4 ye4"
  ],
  "电视机": [
    "dian4 shi4 ji1"
  ],
  "底": [
    "de5",
    "di3"
  ],
  "牵挂": [
    "qian1 gua4"
  ],
  "按理说": [
    "an4 li3 shuo1"
  ],
  "讷": [
    "ne4"
  ],
  "谧": [
    "mi4"
  ],
  "金子": [
    "Jin1 zi3",
    "jin1 zi5"
  ],
  "来到": [
    "lai2 dao4"
  ],
  "出面": [
    "chu1 mian4"
  ],
  "别说": [
    "bie2 shuo1"
  ],
  "仍": [
    "reng2"
  ],
  "着眼于": [
    null
  ],
  "走过": [
    "zou3 guo4"
  ],
  "奇特": [
    "qi2 te4"
  ],
  "碜": [
    "chen3"
  ],
  "城墙": [
    "cheng2 qiang2"
  ],
  "农作物": [
    "nong2 zuo4 wu4"
  ],
  "奸诈": [
    "jian1 zha4"
  ],
  "豸": [
    "zhi4"
  ],
  "住": [
    "zhu4"
  ],
  "濂": [
    "lian2"
  ],
  "用户": [
    "yong4 hu4"
  ],
  "坬": [
    null
  ],
  "鹦": [
    "ying1"
  ],
  "动感": [
    "dong4 gan3"
  ],
  "腭": [
    "e4"
  ],
  "野兽": [
    "ye3 shou4"
  ],
  "塑料袋": [
    "su4 liao4 dai4"
  ],
  "脑海": [
    "nao3 hai3"
  ],
  "阻拦": [
    "zu3 lan2"
  ],
  "开销": [
    "kai1 xiao1"
  ],
  "喷": [
    "pen1",
    "pen4"
  ],
  "狭窄": [
    "xia2 zhai3"
  ],
  "瓜分": [
    "gua1 fen1"
  ],
  "莆": [
    "pu2"
  ],
  "揳": [
    "xie1"
  ],
  "阅览室": [
    "yue4 lan3 shi4"
  ],
  "私下": [
    "si1 xia4"
  ],
  "灰色": [
    "hui1 se4"
  ],
  "隐性": [
    "yin3 xing4"
  ],
  "鹎": [
    "bei1"
  ],
  "癀": [
    "huang2"
  ],
  "友": [
    "you3"
  ],
  "尤": [
    "You2",
    "you2"
  ],
  "冲击": [
    "chong1 ji1",
    "chong1 ji1"
  ],
  "亲人": [
    "qin1 ren2"
  ],
  "胙": [
    "zuo4"
  ],
  "悦": [
    "yue4"
  ],
  "中止": [
    "zhong1 zhi3"
  ],
  "轼": [
    "shi4"
  ],
  "孤立": [
    "gu1 li4"
  ],
  "鞨": [
    "he2"
  ],
  "充电器": [
    "chong1 dian4 qi4"
  ],
  "忽高忽低": [
    "hu1 gao1 hu1 di1"
  ],
  "掉": [
    "diao4"
  ],
  "方": [
    "Fang1",
    "fang1"
  ],
  "熔": [
    "rong2"
  ],
  "商业": [
    "shang1 ye4"
  ],
  "急迫": [
    "ji2 po4"
  ],
  "判决": [
    "pan4 jue2"
  ],
  "龊": [
    "chuo4"
  ],
  "离开": [
    "li2 kai1"
  ],
  "足": [
    "ju4",
    "zu2"
  ],
  "涠": [
    "wei2"
  ],
  "流传": [
    "liu2 chuan2"
  ],
  "璮": [
    "tan3"
  ],
  "海浪": [
    "hai3 lang4"
  ],
  "挽": [
    "wan3",
    "wan3"
  ],
  "掩": [
    "yan3"
  ],
  "片": [
    "pian1",
    "pian4"
  ],
  "搜查": [
    "sou1 cha2"
  ],
  "好学": [
    "hao3 xue2",
    "hao4 xue2"
  ],
  "心里话": [
    "xin1 li5 hua4"
  ],
  "整": [
    "zheng3"
  ],
  "茱": [
    "zhu1"
  ],
  "碰撞": [
    "peng4 zhuang4"
  ],
  "恩惠": [
    "en1 hui4"
  ],
  "痴迷": [
    "chi1 mi2"
  ],
  "馔": [
    "zhuan4",
    "zhuan4"
  ],
  "预料": [
    "yu4 liao4"
  ],
  "伤害": [
    "shang1 hai4"
  ],
  "笼": [
    "long2",
    "long3"
  ],
  "傻": [
    "sha3"
  ],
  "剑": [
    "jian4",
    "jian4"
  ],
  "蚊帐": [
    "wen2 zhang4"
  ],
  "西医": [
    "xi1 yi1"
  ],
  "大包大揽": [
    "da4 bao1 da4 lan3"
  ],
  "元宵节": [
    "Yuan2 xiao1 jie2"
  ],
  "愚": [
    "yu2"
  ],
  "扰乱": [
    "rao3 luan4"
  ],
  "照顾": [
    "zhao4 gu5"
  ],
  "力争": [
    "li4 zheng1"
  ],
  "支配": [
    "zhi1 pei4"
  ],
  "耸立": [
    "song3 li4"
  ],
  "烝": [
    "zheng1"
  ],
  "规则": [
    "gui1 ze2"
  ],
  "痍": [
    "yi2"
  ],
  "可笑": [
    "ke3 xiao4"
  ],
  "实行": [
    "shi2 xing2"
  ],
  "偶尔": [
    "ou3 er3"
  ],
  "囿": [
    "you4"
  ],
  "灏": [
    "hao4"
  ],
  "国学": [
    "guo2 xue2"
  ],
  "电视台": [
    "dian4 shi4 tai2"
  ],
  "信念": [
    "xin4 nian4"
  ],
  "睾": [
    "gao1"
  ],
  "嚎": [
    "hao2"
  ],
  "涉": [
    "she4"
  ],
  "滢": [
    "ying2"
  ],
  "好家伙": [
    "hao3 jia1 huo5"
  ],
  "免除": [
    "mian3 chu2"
  ],
  "嫄": [
    "yuan2"
  ],
  "哪些": [
    "na3 xie1"
  ],
  "靴": [
    "xue1",
    "xue1"
  ],
  "此": [
    "ci3"
  ],
  "栻": [
    "shi4"
  ],
  "养老院": [
    "yang3 lao3 yuan4"
  ],
  "纷纷": [
    "fen1 fen1"
  ],
  "抓": [
    "zhua1"
  ],
  "登录": [
    "deng1 lu4"
  ],
  "得了": [
    "de2 le5",
    "de2 liao3"
  ],
  "休想": [
    "xiu1 xiang3"
  ],
  "饼干": [
    "bing3 gan1"
  ],
  "行驶": [
    "xing2 shi3"
  ],
  "亲手": [
    "qin1 shou3"
  ],
  "汤圆": [
    "tang1 yuan2"
  ],
  "前线": [
    "qian2 xian4"
  ],
  "饺": [
    "jiao3"
  ],
  "喂": [
    "wei2",
    "wei4",
    "wei4",
    "wei4"
  ],
  "多数": [
    "duo1 shu4"
  ],
  "开心": [
    "kai1 xin1"
  ],
  "象征": [
    "xiang4 zheng1"
  ],
  "不服气": [
    "bu4 fu2 qi4"
  ],
  "嚄": [
    "huo4",
    "o3"
  ],
  "磋商": [
    "cuo1 shang1"
  ],
  "类型": [
    "lei4 xing2"
  ],
  "馨": [
    "xin1"
  ],
  "叶": [
    "xie2",
    "Ye4",
    "ye4"
  ],
  "公布": [
    "gong1 bu4"
  ],
  "高明": [
    "Gao1 ming2",
    "gao1 ming2"
  ],
  "眍": [
    "kou1"
  ],
  "蒋": [
    "Jiang3"
  ],
  "欸": [
    "ai1",
    "ai3",
    "ei1",
    "ei2",
    "ei3",
    "ei4"
  ],
  "受害人": [
    "shou4 hai4 ren2"
  ],
  "篼": [
    "dou1"
  ],
  "诺言": [
    "nuo4 yan2"
  ],
  "小心": [
    "xiao3 xin1"
  ],
  "暗示": [
    "an4 shi4"
  ],
  "爆冷门": [
    "bao4 leng3 men2"
  ],
  "雇佣": [
    "gu4 yong1"
  ],
  "骙": [
    "kui2"
  ],
  "殡": [
    "bin4"
  ],
  "癿": [
    "qie2"
  ],
  "众": [
    "Zhong4",
    "zhong4",
    "zhong4"
  ],
  "风险": [
    "feng1 xian3"
  ],
  "售货员": [
    "shou4 huo4 yuan2"
  ],
  "篮球": [
    "lan2 qiu2"
  ],
  "瞒": [
    "man2"
  ],
  "机票": [
    "ji1 piao4"
  ],
  "英俊": [
    "ying1 jun4"
  ],
  "醌": [
    "kun1"
  ],
  "淅": [
    "xi1"
  ],
  "意想不到": [
    "yi4 xiang3 bu4 dao4"
  ],
  "岖": [
    "qu1"
  ],
  "踯": [
    "zhi2"
  ],
  "郛": [
    "fu2"
  ],
  "迟早": [
    "chi2 zao3"
  ],
  "中外": [
    "zhong1 wai4"
  ],
  "薪": [
    "xin1"
  ],
  "养生": [
    "yang3 sheng1"
  ],
  "榔": [
    "lang2"
  ],
  "郸": [
    "Dan1"
  ],
  "果断": [
    "guo3 duan4"
  ],
  "枝": [
    "zhi1"
  ],
  "番": [
    "Pan1",
    "fan1"
  ],
  "膈": [
    "ge2",
    "ge4"
  ],
  "一鼓作气": [
    "yi1 gu3 zuo4 qi4"
  ],
  "优美": [
    "you1 mei3"
  ],
  "政策": [
    "zheng4 ce4"
  ],
  "噗": [
    "pu1"
  ],
  "瓖": [
    "xiang1"
  ],
  "剡": [
    "shan4",
    "yan3"
  ],
  "衩": [
    "cha3",
    "cha4"
  ],
  "仅仅": [
    "jin3 jin3"
  ],
  "混": [
    "hun2",
    "hun4"
  ],
  "履行": [
    "lu:3 xing2"
  ],
  "詹": [
    "Zhan1",
    "zhan1"
  ],
  "绘声绘色": [
    "hui4 sheng1 hui4 se4"
  ],
  "行政": [
    "xing2 zheng4"
  ],
  "澽": [
    "Ju4"
  ],
  "戟": [
    "ji3"
  ],
  "垣": [
    "yuan2"
  ],
  "辌": [
    "liang2"
  ],
  "邬": [
    "Wu1"
  ],
  "乞丐": [
    "qi3 gai4"
  ],
  "导": [
    "dao3"
  ],
  "成功": [
    "Cheng2 gong1",
    "cheng2 gong1"
  ],
  "坯": [
    "pi1"
  ],
  "掺": [
    "chan1",
    "shan3"
  ],
  "上车": [
    "shang4 che1"
  ],
  "钬": [
    "huo3"
  ],
  "结": [
    "jie1",
    "jie2"
  ],
  "自费": [
    "zi4 fei4"
  ],
  "化肥": [
    "hua4 fei2"
  ],
  "烤鸭": [
    "kao3 ya1"
  ],
  "摈": [
    "bin4"
  ],
  "炸弹": [
    "zha4 dan4"
  ],
  "粗鲁": [
    "cu1 lu3"
  ],
  "切除": [
    "qie1 chu2"
  ],
  "雷同": [
    "lei2 tong2"
  ],
  "赐": [
    "ci4"
  ],
  "楂": [
    "cha2",
    "zha1"
  ],
  "内": [
    "nei4"
  ],
  "绋": [
    "fu2"
  ],
  "黝": [
    "you3"
  ],
  "民众": [
    "min2 zhong4"
  ],
  "嚣": [
    "xiao1"
  ],
  "咄": [
    "duo1"
  ],
  "㙦": [
    "xie2"
  ],
  "枷": [
    "jia1"
  ],
  "古今中外": [
    "gu3 jin1 zhong1 wai4"
  ],
  "外套": [
    "wai4 tao4"
  ],
  "签署": [
    "qian1 shu3"
  ],
  "有的放矢": [
    "you3 di4 fang4 shi3"
  ],
  "虫子": [
    "chong2 zi5"
  ],
  "性价比": [
    "xing4 jia4 bi3"
  ],
  "待会儿": [
    "dai1 hui4 r5"
  ],
  "罴": [
    "pi2"
  ],
  "翻番": [
    "fan1 fan1"
  ],
  "专门": [
    "zhuan1 men2"
  ],
  "稀少": [
    "xi1 shao3"
  ],
  "考": [
    "kao3",
    "kao3"
  ],
  "阐述": [
    "chan3 shu4"
  ],
  "尴尬": [
    "gan1 ga4"
  ],
  "先锋": [
    "xian1 feng1"
  ],
  "匆": [
    "cong1",
    "cong1",
    "cong1"
  ],
  "镜子": [
    "jing4 zi5"
  ],
  "试行": [
    "shi4 xing2"
  ],
  "半真半假": [
    "ban4 zhen1 ban4 jia3"
  ],
  "预测": [
    "yu4 ce4"
  ],
  "口子": [
    "kou3 zi5"
  ],
  "欢呼": [
    "huan1 hu1"
  ],
  "簠": [
    "fu3"
  ],
  "谈话": [
    "tan2 hua4"
  ],
  "人民币": [
    "ren2 min2 bi4"
  ],
  "嶦": [
    null
  ],
  "无足轻重": [
    "wu2 zu2 qing1 zhong4"
  ],
  "遭到": [
    "zao1 dao4"
  ],
  "打开": [
    "da3 kai1"
  ],
  "郿": [
    "mei2"
  ],
  "刺骨": [
    "ci4 gu3"
  ],
  "祟": [
    "sui4"
  ],
  "集中": [
    "ji2 zhong1"
  ],
  "痓": [
    null
  ],
  "㟃": [
    "si1"
  ],
  "良好": [
    "liang2 hao3"
  ],
  "盛气凌人": [
    "sheng4 qi4 ling2 ren2"
  ],
  "途径": [
    "tu2 jing4"
  ],
  "衿": [
    "jin1"
  ],
  "鹪": [
    "jiao1"
  ],
  "导致": [
    "dao3 zhi4"
  ],
  "情不自禁": [
    "qing2 bu4 zi4 jin1"
  ],
  "反响": [
    "fan3 xiang3"
  ],
  "成本": [
    "cheng2 ben3"
  ],
  "选择": [
    "xuan3 ze2"
  ],
  "公墓": [
    "gong1 mu4"
  ],
  "暌": [
    "kui2"
  ],
  "特有": [
    "te4 you3"
  ],
  "注册": [
    "zhu4 ce4"
  ],
  "崇": [
    "Chong2",
    "chong2"
  ],
  "延期": [
    "yan2 qi1"
  ],
  "际": [
    "ji4"
  ],
  "睡袋": [
    "shui4 dai4"
  ],
  "穆斯林": [
    "Mu4 si1 lin2"
  ],
  "刭": [
    "jing3"
  ],
  "值": [
    "zhi2"
  ],
  "昇": [
    "sheng1"
  ],
  "夜以继日": [
    "ye4 yi3 ji4 ri4"
  ],
  "钘": [
    "xing2"
  ],
  "前台": [
    "qian2 tai2"
  ],
  "崞": [
    "guo1"
  ],
  "飞行": [
    "fei1 xing2"
  ],
  "缆": [
    "lan3"
  ],
  "不用说": [
    "bu4 yong4 shuo1"
  ],
  "篦": [
    "bi4"
  ],
  "树荫": [
    "shu4 yin1"
  ],
  "锑": [
    "ti1"
  ],
  "萋": [
    "qi1"
  ],
  "血缘": [
    "xue4 yuan2"
  ],
  "斓": [
    "lan2"
  ],
  "牧": [
    "Mu4",
    "mu4"
  ],
  "灿": [
    "can4"
  ],
  "盘子": [
    "pan2 zi5"
  ],
  "宣扬": [
    "xuan1 yang2"
  ],
  "偓": [
    "wo4"
  ],
  "演技": [
    "yan3 ji4"
  ],
  "圫": [
    "ao4",
    "tuo1"
  ],
  "取款机": [
    "qu3 kuan3 ji1"
  ],
  "猛烈": [
    "meng3 lie4"
  ],
  "从今以后": [
    "cong2 jin1 yi3 hou4"
  ],
  "羖": [
    "gu3"
  ],
  "慕": [
    "mu4"
  ],
  "歌手": [
    "ge1 shou3"
  ],
  "上边": [
    "shang4 bian5"
  ],
  "衎": [
    "kan4"
  ],
  "下属": [
    "xia4 shu3"
  ],
  "儿童": [
    "er2 tong2"
  ],
  "事务所": [
    "shi4 wu4 suo3"
  ],
  "全能": [
    "quan2 neng2"
  ],
  "洺": [
    "ming2"
  ],
  "正面": [
    "zheng4 mian4"
  ],
  "侧重": [
    "ce4 zhong4"
  ],
  "愆": [
    "qian1",
    "qian1"
  ],
  "商务": [
    "shang1 wu4"
  ],
  "曹": [
    "Cao2",
    "cao2"
  ],
  "侵犯": [
    "qin1 fan4"
  ],
  "柞": [
    "zuo4"
  ],
  "绡": [
    "xiao1"
  ],
  "大气": [
    "da4 qi4"
  ],
  "襞": [
    "bi4"
  ],
  "槃": [
    "pan2"
  ],
  "哧": [
    "chi1"
  ],
  "洑": [
    "fu2"
  ],
  "侮辱": [
    "wu3 ru3"
  ],
  "考核": [
    "kao3 he2"
  ],
  "承诺": [
    "cheng2 nuo4"
  ],
  "遂心": [
    "sui4 xin1"
  ],
  "连夜": [
    "lian2 ye4"
  ],
  "总是": [
    "zong3 shi4"
  ],
  "红眼": [
    "hong2 yan3"
  ],
  "作用": [
    "zuo4 yong4"
  ],
  "指引": [
    "zhi3 yin3"
  ],
  "箭": [
    "jian4"
  ],
  "这里": [
    "zhe4 li3",
    "zhe4 li3"
  ],
  "拆迁": [
    "chai1 qian1"
  ],
  "茕": [
    "qiong2"
  ],
  "虏": [
    "lu3"
  ],
  "出口": [
    "chu1 kou3"
  ],
  "音响": [
    "yin1 xiang3"
  ],
  "殷": [
    "Yin1",
    "yan1",
    "yin1",
    "yin3"
  ],
  "条件": [
    "tiao2 jian4"
  ],
  "榄": [
    "lan3"
  ],
  "轮子": [
    "lun2 zi5"
  ],
  "有的": [
    "you3 de5"
  ],
  "喜糖": [
    "xi3 tang2"
  ],
  "沚": [
    "zhi3"
  ],
  "滏": [
    "fu3"
  ],
  "羟": [
    "qiang3"
  ],
  "猢": [
    "hu2"
  ],
  "袍": [
    "pao2"
  ],
  "谈论": [
    "tan2 lun4"
  ],
  "授": [
    "shou4"
  ],
  "湔": [
    "jian1"
  ],
  "拍照": [
    "pai1 zhao4"
  ],
  "识别": [
    "shi2 bie2"
  ],
  "核能": [
    "he2 neng2"
  ],
  "瓮": [
    "weng4",
    "Weng4",
    "weng4",
    "weng4"
  ],
  "骏": [
    "jun4"
  ],
  "鹏程万里": [
    "peng2 cheng2 wan4 li3"
  ],
  "媳妇": [
    "xi2 fu4"
  ],
  "船员": [
    "chuan2 yuan2"
  ],
  "中餐": [
    "zhong1 can1"
  ],
  "职位": [
    "zhi2 wei4"
  ],
  "钷": [
    "po3"
  ],
  "艰巨": [
    "jian1 ju4"
  ],
  "伾": [
    "pi1"
  ],
  "证书": [
    "zheng4 shu1"
  ],
  "义": [
    "Yi4",
    "yi4"
  ],
  "晐": [
    null
  ],
  "分歧": [
    "fen1 qi2"
  ],
  "澈": [
    "che4"
  ],
  "表率": [
    "biao3 shuai4"
  ],
  "疾病": [
    "ji2 bing4"
  ],
  "籴": [
    "di2"
  ],
  "骒": [
    "ke4"
  ],
  "橼": [
    "yuan2"
  ],
  "粪便": [
    "fen4 bian4"
  ],
  "疬": [
    "li4"
  ],
  "浥": [
    "yi4"
  ],
  "失误": [
    "shi1 wu4"
  ],
  "沤": [
    "ou1",
    "ou4"
  ],
  "躅": [
    "zhu2"
  ],
  "境遇": [
    "jing4 yu4"
  ],
  "对不起": [
    "dui4 bu5 qi3"
  ],
  "演练": [
    "yan3 lian4"
  ],
  "分外": [
    "fen4 wai4"
  ],
  "撤销": [
    "che4 xiao1"
  ],
  "务实": [
    "wu4 shi2"
  ],
  "跸": [
    "bi4"
  ],
  "一致": [
    "yi1 zhi4"
  ],
  "人物": [
    "ren2 wu4"
  ],
  "网友": [
    "wang3 you3"
  ],
  "颓": [
    "tui2",
    "tui2",
    "tui2"
  ],
  "碱": [
    "jian3",
    "jian3"
  ],
  "车号": [
    "che1 hao4"
  ],
  "苈": [
    "li4"
  ],
  "搜集": [
    "sou1 ji2"
  ],
  "离奇": [
    "li2 qi2"
  ],
  "儒": [
    "ru2"
  ],
  "治安": [
    "zhi4 an1"
  ],
  "腊": [
    "xi1",
    "la4",
    "la4"
  ],
  "压力": [
    "ya1 li4"
  ],
  "制裁": [
    "zhi4 cai2"
  ],
  "节水": [
    "jie2 shui3"
  ],
  "薪水": [
    "xin1 shui3"
  ],
  "竽": [
    "yu2"
  ],
  "爻": [
    "yao2"
  ],
  "杨树": [
    "yang2 shu4"
  ],
  "酺": [
    "pu2"
  ],
  "柿": [
    "shi4",
    "shi4"
  ],
  "遥远": [
    "yao2 yuan3"
  ],
  "典礼": [
    "dian3 li3"
  ],
  "著": [
    "zhu4"
  ],
  "吐": [
    "tu3",
    "tu4"
  ],
  "逃跑": [
    "tao2 pao3"
  ],
  "文雅": [
    "wen2 ya3"
  ],
  "好好": [
    "hao3 hao3"
  ],
  "大批": [
    "da4 pi1"
  ],
  "一举": [
    "yi1 ju3"
  ],
  "尿": [
    "niao4",
    "sui1"
  ],
  "交锋": [
    "jiao1 feng1"
  ],
  "箢": [
    "yuan1"
  ],
  "磋": [
    "cuo1"
  ],
  "恒": [
    "Heng2",
    "heng2",
    "heng2"
  ],
  "放置": [
    "fang4 zhi4"
  ],
  "嫌弃": [
    "xian2 qi4"
  ],
  "诠": [
    "quan2"
  ],
  "钭": [
    "Tou3",
    "tou3"
  ],
  "茺": [
    null
  ],
  "摔倒": [
    "shuai1 dao3"
  ],
  "签证": [
    "qian1 zheng4"
  ],
  "地点": [
    "di4 dian3"
  ],
  "锃": [
    "zeng4"
  ],
  "埋伏": [
    "mai2 fu2"
  ],
  "邨": [
    "Cun1"
  ],
  "触": [
    "chu4"
  ],
  "押金": [
    "ya1 jin1"
  ],
  "药物": [
    "yao4 wu4"
  ],
  "自力更生": [
    "zi4 li4 geng1 sheng1"
  ],
  "浓重": [
    "nong2 zhong4"
  ],
  "做饭": [
    "zuo4 fan4"
  ],
  "靬": [
    "jian1"
  ],
  "儋": [
    "dan1"
  ],
  "衷心": [
    "zhong1 xin1"
  ],
  "铣": [
    "xi3",
    "xian3"
  ],
  "瑭": [
    "tang2"
  ],
  "涤": [
    "di2"
  ],
  "遢": [
    "ta1"
  ],
  "后顾之忧": [
    "hou4 gu4 zhi1 you1"
  ],
  "明": [
    "Ming2",
    "ming2"
  ],
  "确凿": [
    "que4 zao2"
  ],
  "映": [
    "ying4",
    "ying4"
  ],
  "鬣": [
    "lie4"
  ],
  "绝大多数": [
    "jue2 da4 duo1 shu4"
  ],
  "但是": [
    "dan4 shi4"
  ],
  "生意": [
    "sheng1 yi4",
    "sheng1 yi5"
  ],
  "绑架": [
    "bang3 jia4"
  ],
  "观点": [
    "guan1 dian3"
  ],
  "资格": [
    "zi1 ge2"
  ],
  "绽": [
    "zhan4"
  ],
  "对弈": [
    "dui4 yi4"
  ],
  "宇宙": [
    "yu3 zhou4"
  ],
  "动不动": [
    "dong4 bu5 dong4"
  ],
  "袅": [
    "niao3",
    "niao3",
    "niao3",
    "niao3"
  ],
  "开花": [
    "kai1 hua1"
  ],
  "相片": [
    "xiang4 pian4"
  ],
  "软件": [
    "ruan3 jian4"
  ],
  "物": [
    "wu4"
  ],
  "约": [
    "yao1",
    "yue1"
  ],
  "技艺": [
    "ji4 yi4"
  ],
  "结构": [
    "jie2 gou4"
  ],
  "规模": [
    "gui1 mo2"
  ],
  "来不及": [
    "lai2 bu5 ji2"
  ],
  "师长": [
    "shi1 zhang3"
  ],
  "扑": [
    "pu1"
  ],
  "中药": [
    "zhong1 yao4"
  ],
  "螋": [
    "sou1"
  ],
  "理解": [
    "li3 jie3"
  ],
  "颁布": [
    "ban1 bu4"
  ],
  "延误": [
    "yan2 wu4"
  ],
  "精妙": [
    "jing1 miao4"
  ],
  "精简": [
    "jing1 jian3"
  ],
  "用": [
    "yong4"
  ],
  "珊": [
    "shan1"
  ],
  "元素": [
    "yuan2 su4"
  ],
  "可乘之机": [
    "ke3 cheng2 zhi1 ji1"
  ],
  "桌": [
    "zhuo1",
    "zhuo1"
  ],
  "棣": [
    "di4"
  ],
  "显著": [
    "xian3 zhu4"
  ],
  "锯": [
    "ju1",
    "ju4"
  ],
  "方案": [
    "fang1 an4"
  ],
  "寝室": [
    "qin3 shi4"
  ],
  "刳": [
    "ku1"
  ],
  "脚步": [
    "jiao3 bu4"
  ],
  "厚度": [
    "hou4 du4"
  ],
  "办不到": [
    "ban4 bu5 dao4"
  ],
  "厶": [
    "mou3",
    "si1"
  ],
  "监狱": [
    "jian1 yu4"
  ],
  "展": [
    "Zhan3",
    "zhan3"
  ],
  "瘁": [
    "cui4"
  ],
  "大": [
    "da4",
    "dai4"
  ],
  "丧失": [
    "sang4 shi1"
  ],
  "时而": [
    "shi2 er2"
  ],
  "发抖": [
    "fa1 dou3"
  ],
  "缫": [
    "sao1"
  ],
  "掀起": [
    "xian1 qi3"
  ],
  "夤": [
    "yin2"
  ],
  "求医": [
    "qiu2 yi1"
  ],
  "耐性": [
    "nai4 xing4"
  ],
  "客运": [
    "ke4 yun4"
  ],
  "以前": [
    "yi3 qian2"
  ],
  "笛子": [
    "di2 zi5"
  ],
  "杰": [
    "jie2",
    "jie2"
  ],
  "难关": [
    "nan2 guan1"
  ],
  "爽": [
    "shuang3"
  ],
  "谰": [
    "lan2"
  ],
  "涎": [
    "xian2",
    "xian2"
  ],
  "天分": [
    "tian1 fen4"
  ],
  "特长": [
    "te4 chang2"
  ],
  "解围": [
    "jie3 wei2"
  ],
  "俯首": [
    "fu3 shou3"
  ],
  "嫖": [
    "piao2"
  ],
  "物质": [
    "wu4 zhi4"
  ],
  "风雨": [
    "feng1 yu3"
  ],
  "早晚": [
    "zao3 wan3"
  ],
  "熜": [
    "cong1",
    "zong3"
  ],
  "不敢当": [
    "bu4 gan3 dang1"
  ],
  "洗衣粉": [
    "xi3 yi1 fen3"
  ],
  "下": [
    "xia4"
  ],
  "杀手": [
    "sha1 shou3"
  ],
  "蜜月": [
    "mi4 yue4"
  ],
  "磏": [
    null
  ],
  "枕头": [
    "zhen3 tou5"
  ],
  "日新月异": [
    "ri4 xin1 yue4 yi4"
  ],
  "包": [
    "Bao1",
    "bao1"
  ],
  "酰": [
    "xian1"
  ],
  "出厂": [
    "chu1 chang3"
  ],
  "砹": [
    "ai4"
  ],
  "更是": [
    "geng4 shi4"
  ],
  "蛤": [
    "ge2",
    "ha2"
  ],
  "廋": [
    "sou1"
  ],
  "燕": [
    "Yan1",
    "yan4",
    "yan4"
  ],
  "愈": [
    "yu4",
    "yu4"
  ],
  "眉": [
    "mei2"
  ],
  "培育": [
    "pei2 yu4"
  ],
  "周末": [
    "zhou1 mo4"
  ],
  "头": [
    "tou2",
    "tou5"
  ],
  "或多或少": [
    "huo4 duo1 huo4 shao3"
  ],
  "爱不释手": [
    "ai4 bu4 shi4 shou3"
  ],
  "莳": [
    "shi2",
    "shi4"
  ],
  "伸手": [
    "shen1 shou3"
  ],
  "美满": [
    "mei3 man3"
  ],
  "褪": [
    "tui4",
    "tun4"
  ],
  "汭": [
    "rui4"
  ],
  "妫": [
    "Gui1",
    "Gui1"
  ],
  "扫除": [
    "sao3 chu2"
  ],
  "加工": [
    "jia1 gong1"
  ],
  "狮": [
    "shi1"
  ],
  "土豆": [
    "tu3 dou4"
  ],
  "笮": [
    "ze2"
  ],
  "苜": [
    "mu4"
  ],
  "兄弟": [
    "xiong1 di4"
  ],
  "付费": [
    "fu4 fei4"
  ],
  "况": [
    "kuang4"
  ],
  "枨": [
    "cheng2"
  ],
  "倓": [
    "tan2"
  ],
  "朦胧": [
    "meng2 long2"
  ],
  "讫": [
    "qi4"
  ],
  "列车": [
    "lie4 che1"
  ],
  "明智": [
    "ming2 zhi4"
  ],
  "车轴": [
    "che1 zhou2"
  ],
  "攴": [
    "pu1"
  ],
  "激烈": [
    "ji1 lie4"
  ],
  "尾": [
    "wei3",
    "yi3"
  ],
  "唇": [
    "chun2",
    "chun2"
  ],
  "甓": [
    "pi4"
  ],
  "掉头": [
    "diao4 tou2"
  ],
  "守株待兔": [
    "shou3 zhu1 dai4 tu4"
  ],
  "壁": [
    "bi4"
  ],
  "称": [
    "chen4",
    "cheng1",
    "cheng4"
  ],
  "啥": [
    "sha2"
  ],
  "丝绸": [
    "si1 chou2"
  ],
  "一会儿": [
    "yi1 hui4 r5"
  ],
  "打招呼": [
    "da3 zhao1 hu5"
  ],
  "操场": [
    "cao1 chang3"
  ],
  "阍": [
    "hun1"
  ],
  "乩": [
    "ji1"
  ],
  "泡": [
    "pao1",
    "pao4"
  ],
  "逃生": [
    "tao2 sheng1"
  ],
  "袭击": [
    "xi2 ji1"
  ],
  "聩": [
    "kui4"
  ],
  "磁带": [
    "ci2 dai4"
  ],
  "街头": [
    "jie1 tou2"
  ],
  "蒈": [
    null
  ],
  "做证": [
    "zuo4 zheng4"
  ],
  "游客": [
    "you2 ke4"
  ],
  "蚣": [
    "gong1"
  ],
  "妩": [
    "wu3"
  ],
  "菲": [
    "Fei1",
    "fei1",
    "fei3"
  ],
  "诚信": [
    "cheng2 xin4"
  ],
  "派别": [
    "pai4 bie2"
  ],
  "家用": [
    "jia1 yong4"
  ],
  "一度": [
    "yi1 du4"
  ],
  "大笔": [
    "da4 bi3"
  ],
  "达成": [
    "da2 cheng2"
  ],
  "法院": [
    "fa3 yuan4"
  ],
  "攮": [
    "nang3"
  ],
  "首都": [
    "shou3 du1"
  ],
  "附件": [
    "fu4 jian4"
  ],
  "当之无愧": [
    "dang1 zhi1 wu2 kui4"
  ],
  "饮用水": [
    "yin3 yong4 shui3"
  ],
  "纛": [
    "dao4"
  ],
  "曩": [
    "nang3"
  ],
  "认识": [
    "ren4 shi5"
  ],
  "鋆": [
    "yun2"
  ],
  "蝾": [
    "rong2"
  ],
  "想": [
    "xiang3"
  ],
  "期限": [
    "qi1 xian4"
  ],
  "蹰": [
    "chu2"
  ],
  "戆": [
    "gang4",
    "zhuang4"
  ],
  "南瓜": [
    "nan2 gua1"
  ],
  "书包": [
    "shu1 bao1"
  ],
  "悼": [
    "dao4"
  ],
  "价": [
    "jia4",
    "jie5"
  ],
  "商品": [
    "shang1 pin3"
  ],
  "因此": [
    "yin1 ci3"
  ],
  "安逸": [
    "an1 yi4"
  ],
  "赧": [
    "nan3"
  ],
  "一无所知": [
    "yi1 wu2 suo3 zhi1"
  ],
  "妾": [
    "qie4"
  ],
  "改善": [
    "gai3 shan4"
  ],
  "芽": [
    "ya2"
  ],
  "醐": [
    "hu2"
  ],
  "鲜血": [
    "xian1 xue4"
  ],
  "维修": [
    "wei2 xiu1"
  ],
  "走私": [
    "zou3 si1"
  ],
  "接": [
    "jie1"
  ],
  "溺": [
    "ni4",
    "niao4"
  ],
  "毁": [
    "hui3",
    "hui3",
    "hui3"
  ],
  "溵": [
    "Yin1"
  ],
  "意向": [
    "yi4 xiang4"
  ],
  "平方米": [
    "ping2 fang1 mi3"
  ],
  "㑊": [
    "yi4"
  ],
  "韧性": [
    "ren4 xing4"
  ],
  "窣": [
    "su1"
  ],
  "蛋": [
    "Dan4",
    "dan4"
  ],
  "鹭": [
    "lu4"
  ],
  "一体": [
    "yi1 ti3"
  ],
  "福气": [
    "fu2 qi5"
  ],
  "出发点": [
    "chu1 fa1 dian3"
  ],
  "开始": [
    "kai1 shi3"
  ],
  "科研": [
    "ke1 yan2"
  ],
  "邶": [
    "bei4"
  ],
  "切": [
    "qie1",
    "qie4"
  ],
  "怼": [
    "dui3",
    "dui4"
  ],
  "下雪": [
    "xia4 xue3"
  ],
  "顽皮": [
    "wan2 pi2"
  ],
  "瀚": [
    "han4"
  ],
  "萝卜": [
    "luo2 bo5"
  ],
  "邀": [
    "yao1"
  ],
  "咉": [
    null
  ],
  "乐观": [
    "le4 guan1"
  ],
  "滍": [
    "zhi4"
  ],
  "侍候": [
    "shi4 hou4"
  ],
  "这就是说": [
    "zhe4 jiu4 shi4 shuo1"
  ],
  "磨": [
    "mo2",
    "mo4"
  ],
  "搜索": [
    "sou1 suo3"
  ],
  "过年": [
    "guo4 nian2"
  ],
  "活泼": [
    "huo2 po5"
  ],
  "高手": [
    "gao1 shou3"
  ],
  "宀": [
    "mian2"
  ],
  "退缩": [
    "tui4 suo1"
  ],
  "炟": [
    "da2"
  ],
  "渺小": [
    "miao3 xiao3"
  ],
  "腱": [
    "jian4"
  ],
  "酞": [
    "tai4"
  ],
  "大大": [
    "da4 da4"
  ],
  "矶": [
    "ji1"
  ],
  "稍后": [
    "shao1 hou4"
  ],
  "秬": [
    "ju4"
  ],
  "䜣": [
    "xin1"
  ],
  "海边": [
    "hai3 bian1"
  ],
  "意料": [
    "yi4 liao4"
  ],
  "治学": [
    "zhi4 xue2"
  ],
  "拖累": [
    "tuo1 lei3"
  ],
  "太阳": [
    "tai4 yang5"
  ],
  "苻": [
    "fu2"
  ],
  "职权": [
    "zhi2 quan2"
  ],
  "鳛": [
    "xi2"
  ],
  "胶带": [
    "jiao1 dai4"
  ],
  "字体": [
    "zi4 ti3"
  ],
  "举手": [
    "ju3 shou3"
  ],
  "上报": [
    "shang4 bao4"
  ],
  "晚饭": [
    "wan3 fan4"
  ],
  "银": [
    "yin2"
  ],
  "远方": [
    "yuan3 fang1"
  ],
  "旦": [
    "dan4"
  ],
  "当面": [
    "dang1 mian4"
  ],
  "郾": [
    "yan3"
  ],
  "发扬光大": [
    "fa1 yang2 guang1 da4"
  ],
  "家教": [
    "jia1 jiao4"
  ],
  "积累": [
    "ji1 lei3"
  ],
  "原谅": [
    "yuan2 liang4"
  ],
  "编制": [
    "bian1 zhi4",
    "bian1 zhi4"
  ],
  "受": [
    "shou4"
  ],
  "陆续": [
    "lu4 xu4"
  ],
  "翀": [
    "chong1"
  ],
  "特快": [
    "te4 kuai4"
  ],
  "趋势": [
    "qu1 shi4"
  ],
  "郯": [
    "Tan2"
  ],
  "矫": [
    "Jiao3",
    "jiao2",
    "jiao3"
  ],
  "烈": [
    "lie4"
  ],
  "骞": [
    "qian1"
  ],
  "鹮": [
    "huan2"
  ],
  "教育": [
    "jiao4 yu4"
  ],
  "播": [
    "bo1"
  ],
  "遑": [
    "huang2"
  ],
  "洗澡": [
    "xi3 zao3"
  ],
  "笈": [
    "ji2"
  ],
  "滥用": [
    "lan4 yong4"
  ],
  "胃": [
    "wei4"
  ],
  "可疑": [
    "ke3 yi2"
  ],
  "便宜": [
    "bian4 yi2",
    "pian2 yi5"
  ],
  "原因": [
    "yuan2 yin1"
  ],
  "古人": [
    "gu3 ren2"
  ],
  "婶": [
    "shen3"
  ],
  "蟪": [
    "hui4"
  ],
  "含有": [
    "han2 you3"
  ],
  "喑": [
    "yin1"
  ],
  "惯例": [
    "guan4 li4"
  ],
  "瘢": [
    "ban1"
  ],
  "需": [
    "xu1"
  ],
  "迹象": [
    "ji4 xiang4"
  ],
  "剧组": [
    "ju4 zu3"
  ],
  "水壶": [
    "shui3 hu2"
  ],
  "嚯": [
    "huo4"
  ],
  "海关": [
    "hai3 guan1"
  ],
  "珥": [
    "er3"
  ],
  "以来": [
    "yi3 lai2"
  ],
  "秘书": [
    "mi4 shu1"
  ],
  "同胞": [
    "tong2 bao1"
  ],
  "拨通": [
    "bo1 tong1"
  ],
  "踩": [
    "cai3",
    "cai3"
  ],
  "改革开放": [
    "gai3 ge2 kai1 fang4"
  ],
  "咳": [
    "hai1",
    "ke2"
  ],
  "管道": [
    "guan3 dao4"
  ],
  "下来": [
    "xia4 lai5"
  ],
  "危机": [
    "wei1 ji1"
  ],
  "构想": [
    "gou4 xiang3"
  ],
  "自称": [
    "zi4 cheng1"
  ],
  "变形": [
    "bian4 xing2"
  ],
  "冲刺": [
    "chong1 ci4"
  ],
  "宁静": [
    "ning2 jing4"
  ],
  "蕺": [
    "ji2"
  ],
  "将军": [
    "Jiang1 jun1",
    "jiang1 jun1"
  ],
  "垱": [
    "dang4"
  ],
  "高空": [
    "gao1 kong1"
  ],
  "棍": [
    "gun4"
  ],
  "自从": [
    "zi4 cong2"
  ],
  "面面俱到": [
    "mian4 mian4 ju4 dao4"
  ],
  "上个月": [
    "shang4 ge4 yue4"
  ],
  "硍": [
    "ken4"
  ],
  "下去": [
    "xia4 qu4"
  ],
  "毌": [
    "guan4"
  ],
  "义务": [
    "yi4 wu4"
  ],
  "招手": [
    "zhao1 shou3"
  ],
  "迟迟": [
    "chi2 chi2"
  ],
  "琵": [
    "pi2"
  ],
  "氡": [
    "dong1"
  ],
  "功能": [
    "gong1 neng2"
  ],
  "发掘": [
    "fa1 jue2"
  ],
  "烩": [
    "hui4"
  ],
  "菁": [
    "jing1"
  ],
  "呖": [
    "li4"
  ],
  "剧院": [
    "ju4 yuan4"
  ],
  "礼仪": [
    "li3 yi2"
  ],
  "史无前例": [
    "shi3 wu2 qian2 li4"
  ],
  "简陋": [
    "jian3 lou4"
  ],
  "引入": [
    "yin3 ru4"
  ],
  "心得": [
    "xin1 de2"
  ],
  "看重": [
    "kan4 zhong4"
  ],
  "嗟": [
    "jie1"
  ],
  "涑": [
    "su4"
  ],
  "境": [
    "jing4"
  ],
  "指手画脚": [
    "zhi3 shou3 hua4 jiao3"
  ],
  "滑冰": [
    "hua2 bing1"
  ],
  "陷入": [
    "xian4 ru4"
  ],
  "疑虑": [
    "yi2 lu:4"
  ],
  "妈妈": [
    "ma1 ma5"
  ],
  "爪": [
    "zhao3",
    "zhua3"
  ],
  "挎": [
    "kua4"
  ],
  "感受": [
    "gan3 shou4"
  ],
  "澛": [
    "lu3"
  ],
  "囵": [
    "lun2"
  ],
  "靴子": [
    "xue1 zi5"
  ],
  "倒": [
    "dao3",
    "dao4"
  ],
  "㕮": [
    null
  ],
  "㛚": [
    null
  ],
  "氟": [
    "fu2"
  ],
  "接班人": [
    "jie1 ban1 ren2"
  ],
  "归宿": [
    "gui1 su4"
  ],
  "渰": [
    "yan3"
  ],
  "椴": [
    "duan4"
  ],
  "趋": [
    "qu1"
  ],
  "商": [
    "Shang1",
    "shang1"
  ],
  "如愿以偿": [
    "ru2 yuan4 yi3 chang2"
  ],
  "咨": [
    "zi1"
  ],
  "颙": [
    "yong2"
  ],
  "分组": [
    "fen1 zu3"
  ],
  "瑢": [
    "rong2"
  ],
  "装备": [
    "zhuang1 bei4"
  ],
  "冷冻": [
    "leng3 dong4"
  ],
  "授予": [
    "shou4 yu3"
  ],
  "寨": [
    "zhai4",
    "zhai4"
  ],
  "侁": [
    "shen1"
  ],
  "布": [
    "bu4",
    "bu4"
  ],
  "回首": [
    "hui2 shou3"
  ],
  "骓": [
    "Zhui1",
    "zhui1"
  ],
  "辅导": [
    "fu3 dao3"
  ],
  "性情": [
    "xing4 qing2"
  ],
  "奖金": [
    "jiang3 jin1"
  ],
  "俾": [
    "bi3"
  ],
  "儴": [
    null
  ],
  "䗴": [
    null
  ],
  "中等": [
    "zhong1 deng3"
  ],
  "即可": [
    "ji2 ke3"
  ],
  "馝": [
    "bi4"
  ],
  "痴呆": [
    "chi1 dai1",
    "chi1 dai1"
  ],
  "两岸": [
    "liang3 an4"
  ],
  "恕": [
    "shu4"
  ],
  "顷": [
    "qing1",
    "qing3"
  ],
  "好歹": [
    "hao3 dai3"
  ],
  "淟": [
    "tian3"
  ],
  "碰见": [
    "peng4 jian4"
  ],
  "潵": [
    "Sa3",
    "sa3",
    "san4"
  ],
  "关键": [
    "guan1 jian4"
  ],
  "总经理": [
    "zong3 jing1 li3"
  ],
  "鬥": [
    null
  ],
  "领悟": [
    "ling3 wu4"
  ],
  "古代": [
    "gu3 dai4"
  ],
  "频道": [
    "pin2 dao4"
  ],
  "告知": [
    "gao4 zhi1"
  ],
  "杄": [
    null
  ],
  "薷": [
    "ru2"
  ],
  "会见": [
    "hui4 jian4"
  ],
  "战术": [
    "zhan4 shu4"
  ],
  "长度": [
    "chang2 du4"
  ],
  "报答": [
    "bao4 da2"
  ],
  "苛刻": [
    "ke1 ke4"
  ],
  "鼫": [
    "shi2"
  ],
  "禁区": [
    "jin4 qu1"
  ],
  "磻": [
    "pan2"
  ],
  "信息": [
    "xin4 xi1"
  ],
  "亲近": [
    "qin1 jin4"
  ],
  "璎": [
    "ying1"
  ],
  "带头人": [
    "dai4 tou2 ren2"
  ],
  "放松": [
    "fang4 song1"
  ],
  "受理": [
    "shou4 li3"
  ],
  "成天": [
    "cheng2 tian1"
  ],
  "领会": [
    "ling3 hui4"
  ],
  "争": [
    "zheng1"
  ],
  "珰": [
    "dang1"
  ],
  "秸": [
    "jie1",
    "jie1"
  ],
  "炉子": [
    "lu2 zi5"
  ],
  "药方": [
    "yao4 fang1"
  ],
  "疲": [
    "pi2"
  ],
  "祎": [
    "yi1"
  ],
  "痛快": [
    "tong4 kuai4"
  ],
  "涔": [
    "cen2"
  ],
  "圪": [
    "ge1"
  ],
  "赌": [
    "du3"
  ],
  "笞": [
    "chi1"
  ],
  "桎": [
    "zhi4"
  ],
  "狈": [
    "bei4"
  ],
  "厥": [
    "jue2"
  ],
  "中部": [
    "zhong1 bu4"
  ],
  "褡": [
    "da1"
  ],
  "著作": [
    "zhu4 zuo4"
  ],
  "委员": [
    "wei3 yuan2"
  ],
  "圆珠笔": [
    "yuan2 zhu1 bi3"
  ],
  "酡": [
    "tuo2"
  ],
  "丘": [
    "Qiu1",
    "qiu1",
    "qiu1"
  ],
  "领先": [
    "ling3 xian1"
  ],
  "引发": [
    "yin3 fa1"
  ],
  "石": [
    "Shi2",
    "dan4",
    "shi2"
  ],
  "断裂": [
    "duan4 lie4"
  ],
  "绳子": [
    "sheng2 zi5"
  ],
  "酒水": [
    "jiu3 shui3"
  ],
  "翎": [
    "ling2"
  ],
  "磲": [
    "qu2"
  ],
  "柚": [
    "you4"
  ],
  "地铁": [
    "di4 tie3"
  ],
  "贺电": [
    "he4 dian4"
  ],
  "飏": [
    "yang2"
  ],
  "拍摄": [
    "pai1 she4"
  ],
  "荩": [
    "jin4"
  ],
  "峙": [
    "shi4",
    "zhi4"
  ],
  "奈": [
    "nai4"
  ],
  "多云": [
    "duo1 yun2"
  ],
  "一揽子": [
    "yi1 lan3 zi5"
  ],
  "饶": [
    "Rao2",
    "rao2"
  ],
  "袆": [
    "hui1"
  ],
  "珑": [
    "long2"
  ],
  "箍": [
    "gu1"
  ],
  "颁": [
    "ban1"
  ],
  "纪念碑": [
    "ji4 nian4 bei1"
  ],
  "吹捧": [
    "chui1 peng3"
  ],
  "歌词": [
    "ge1 ci2"
  ],
  "主持": [
    "zhu3 chi2"
  ],
  "影视": [
    "ying3 shi4"
  ],
  "不免": [
    "bu4 mian3"
  ],
  "鲺": [
    null
  ],
  "库": [
    "ku4"
  ],
  "说": [
    "shui4",
    "shuo1",
    "shuo1"
  ],
  "频": [
    "pin2"
  ],
  "通讯": [
    "tong1 xun4"
  ],
  "野蛮": [
    "ye3 man2"
  ],
  "风波": [
    "feng1 bo1"
  ],
  "刮": [
    "gua1",
    "gua1"
  ],
  "鳕": [
    "xue3"
  ],
  "鲼": [
    "fen4"
  ],
  "戈壁": [
    "Ge1 bi4"
  ],
  "跌": [
    "die1"
  ],
  "尺子": [
    "chi3 zi5"
  ],
  "暂停": [
    "zan4 ting2"
  ],
  "转载": [
    "zhuan3 zai3"
  ],
  "绁": [
    "xie4",
    "xie4"
  ],
  "一贯": [
    "yi1 guan4"
  ],
  "出毛病": [
    "chu1 mao2 bing4"
  ],
  "叮嘱": [
    "ding1 zhu3"
  ],
  "蓣": [
    "yu4"
  ],
  "呶": [
    "nao2",
    "nu3"
  ],
  "羼": [
    "chan4"
  ],
  "伴侣": [
    "ban4 lu:3"
  ],
  "创伤": [
    "chuang1 shang1"
  ],
  "茼": [
    "tong2"
  ],
  "拿手": [
    "na2 shou3"
  ],
  "眄": [
    "mian3",
    "mian4"
  ],
  "客机": [
    "ke4 ji1"
  ],
  "迭起": [
    "die2 qi3"
  ],
  "窠": [
    "ke1"
  ],
  "不知不觉": [
    "bu4 zhi1 bu4 jue2"
  ],
  "照明": [
    "zhao4 ming2"
  ],
  "勉": [
    "mian3"
  ],
  "痢": [
    "li4"
  ],
  "渝": [
    "Yu2"
  ],
  "放大": [
    "fang4 da4"
  ],
  "杂技": [
    "za2 ji4"
  ],
  "全场": [
    "quan2 chang3"
  ],
  "兕": [
    "si4"
  ],
  "试题": [
    "shi4 ti2"
  ],
  "外行": [
    "wai4 hang2"
  ],
  "肩": [
    "jian1"
  ],
  "车间": [
    "che1 jian1"
  ],
  "忖": [
    "cun3"
  ],
  "宠物": [
    "chong3 wu4"
  ],
  "穜": [
    "tong2"
  ],
  "谩": [
    "man2",
    "man4"
  ],
  "抵挡": [
    "di3 dang3"
  ],
  "眉开眼笑": [
    "mei2 kai1 yan3 xiao4"
  ],
  "玻": [
    "bo1"
  ],
  "卧铺": [
    "wo4 pu4"
  ],
  "洁净": [
    "jie2 jing4"
  ],
  "有利": [
    "you3 li4"
  ],
  "薜": [
    "bi4"
  ],
  "生前": [
    "sheng1 qian2"
  ],
  "底子": [
    "di3 zi5"
  ],
  "何": [
    "He2",
    "he2"
  ],
  "预习": [
    "yu4 xi2"
  ],
  "晶": [
    "jing1"
  ],
  "焉": [
    "yan1"
  ],
  "苫": [
    "shan1"
  ],
  "气息": [
    "qi4 xi1"
  ],
  "促进": [
    "cu4 jin4"
  ],
  "认为": [
    "ren4 wei2"
  ],
  "嗫": [
    "nie4"
  ],
  "皑": [
    "ai2"
  ],
  "泄气": [
    "xie4 qi4"
  ],
  "难怪": [
    "nan2 guai4"
  ],
  "名片": [
    "ming2 pian4"
  ],
  "笼罩": [
    "long3 zhao4"
  ],
  "誉": [
    "yu4"
  ],
  "预感": [
    "yu4 gan3"
  ],
  "猎": [
    "lie4"
  ],
  "梯子": [
    "ti1 zi5"
  ],
  "自强不息": [
    "zi4 qiang2 bu4 xi1"
  ],
  "能耐": [
    "neng2 nai4"
  ],
  "嫁妆": [
    "jia4 zhuang5"
  ],
  "察": [
    "Cha2",
    "cha2",
    "cha2"
  ],
  "招待": [
    "zhao1 dai4"
  ],
  "挣扎": [
    "zheng1 zha2"
  ],
  "明年": [
    "ming2 nian2"
  ],
  "绷": [
    "beng1",
    "beng1",
    "beng3"
  ],
  "价位": [
    "jia4 wei4"
  ],
  "稍": [
    "shao1",
    "shao4"
  ],
  "主持人": [
    "zhu3 chi2 ren2"
  ],
  "别看": [
    "bie2 kan4"
  ],
  "诳": [
    "kuang2"
  ],
  "鼎": [
    "ding3"
  ],
  "串": [
    "chuan4"
  ],
  "溴": [
    "xiu4"
  ],
  "犴": [
    "an4"
  ],
  "蚁": [
    "yi3"
  ],
  "翯": [
    "he4"
  ],
  "汉语": [
    "Han4 yu3"
  ],
  "贿赂": [
    "hui4 lu4"
  ],
  "之外": [
    "zhi1 wai4"
  ],
  "妥协": [
    "tuo3 xie2"
  ],
  "结果": [
    "jie1 guo3",
    "jie2 guo3"
  ],
  "少林寺": [
    "Shao4 lin2 Si4"
  ],
  "流泪": [
    "liu2 lei4"
  ],
  "痊": [
    "quan2"
  ],
  "权威": [
    "quan2 wei1"
  ],
  "营": [
    "ying2"
  ],
  "平日": [
    "ping2 ri4"
  ],
  "铿": [
    "keng1"
  ],
  "如今": [
    "ru2 jin1"
  ],
  "国会": [
    "Guo2 hui4"
  ],
  "茚": [
    "yin4"
  ],
  "廷": [
    "ting2"
  ],
  "钳": [
    "qian2"
  ],
  "濠": [
    "hao2"
  ],
  "察觉": [
    "cha2 jue2"
  ],
  "人工智能": [
    "ren2 gong1 zhi4 neng2"
  ],
  "笪": [
    "Da2",
    "da2"
  ],
  "展现": [
    "zhan3 xian4"
  ],
  "陉": [
    "xing2"
  ],
  "找出": [
    "zhao3 chu1"
  ],
  "折": [
    "she2",
    "zhe1",
    "zhe2",
    "zhe2"
  ],
  "颚": [
    "e4"
  ],
  "专栏": [
    "zhuan1 lan2"
  ],
  "鄀": [
    "ruo4"
  ],
  "寞": [
    "mo4"
  ],
  "言论": [
    "yan2 lun4"
  ],
  "摆放": [
    "bai3 fang4"
  ],
  "褙": [
    "bei4"
  ],
  "裆": [
    "dang1"
  ],
  "送别": [
    "song4 bie2"
  ],
  "鲮": [
    "ling2"
  ],
  "先生": [
    "xian1 sheng5"
  ],
  "陈旧": [
    "chen2 jiu4"
  ],
  "劫": [
    "jie2",
    "jie2",
    "jie2",
    "jie2"
  ],
  "火暴": [
    "huo3 bao4"
  ],
  "荧": [
    "ying2"
  ],
  "悬崖": [
    "xuan2 ya2"
  ],
  "跽": [
    "ji4"
  ],
  "傍晚": [
    "bang4 wan3"
  ],
  "皞": [
    "hao4"
  ],
  "髻": [
    "ji4"
  ],
  "失业": [
    "shi1 ye4"
  ],
  "钪": [
    "kang4"
  ],
  "孓": [
    "jue2"
  ],
  "醍": [
    "ti2"
  ],
  "区": [
    "Ou1",
    "qu1"
  ],
  "对白": [
    "dui4 bai2"
  ],
  "忾": [
    "kai4"
  ],
  "殁": [
    "mo4"
  ],
  "峛": [
    null
  ],
  "俄语": [
    "E2 yu3"
  ],
  "拉锁": [
    "la1 suo3"
  ],
  "椰": [
    "ye1"
  ],
  "提名": [
    "ti2 ming2"
  ],
  "罡": [
    "gang1"
  ],
  "难过": [
    "nan2 guo4"
  ],
  "顺势": [
    "shun4 shi4"
  ],
  "哪知道": [
    "na3 zhi1 dao4"
  ],
  "不至于": [
    "bu4 zhi4 yu2"
  ],
  "珇": [
    "zu3"
  ],
  "贼": [
    "zei2"
  ],
  "摊": [
    "tan1"
  ],
  "衰": [
    "cui1",
    "shuai1"
  ],
  "膻": [
    "shan1",
    "shan1"
  ],
  "仕": [
    "shi4"
  ],
  "血压": [
    "xue4 ya1"
  ],
  "上午": [
    "shang4 wu3"
  ],
  "换成": [
    "huan4 cheng2"
  ],
  "掌声": [
    "zhang3 sheng1"
  ],
  "无偿": [
    "wu2 chang2"
  ],
  "瘕": [
    "jia3"
  ],
  "内向": [
    "nei4 xiang4"
  ],
  "伤感": [
    "shang1 gan3"
  ],
  "伏": [
    "Fu2",
    "fu2"
  ],
  "掘": [
    "jue2"
  ],
  "翻": [
    "fan1",
    "fan1"
  ],
  "磬": [
    "qing4"
  ],
  "襁": [
    "qiang3",
    "qiang3"
  ],
  "蒨": [
    "qian4"
  ],
  "主角": [
    "zhu3 jue2"
  ],
  "形影不离": [
    "xing2 ying3 bu4 li2"
  ],
  "婴": [
    "ying1"
  ],
  "豁": [
    "hua2",
    "huo1",
    "huo4"
  ],
  "争光": [
    "zheng1 guang1"
  ],
  "蛭": [
    "zhi4"
  ],
  "火车": [
    "huo3 che1"
  ],
  "县": [
    "xian4"
  ],
  "泜": [
    "zhi1"
  ],
  "陶瓷": [
    "tao2 ci2"
  ],
  "泪水": [
    "lei4 shui3"
  ],
  "昊": [
    "Hao4",
    "hao4"
  ],
  "饸": [
    "he2"
  ],
  "有": [
    "you3"
  ],
  "转换": [
    "zhuan3 huan4"
  ],
  "眼镜": [
    "yan3 jing4"
  ],
  "氇": [
    "lu3"
  ],
  "宣告": [
    "xuan1 gao4"
  ],
  "有空儿": [
    "you3 kong4 r5"
  ],
  "赍": [
    "ji1",
    "ji1",
    "ji1"
  ],
  "镬": [
    "huo4"
  ],
  "一连串": [
    "yi1 lian2 chuan4"
  ],
  "箱子": [
    "xiang1 zi5"
  ],
  "重申": [
    "chong2 shen1"
  ],
  "一方面": [
    "yi1 fang1 mian4"
  ],
  "快": [
    "kuai4"
  ],
  "有两下子": [
    "you3 liang3 xia4 zi5"
  ],
  "痕迹": [
    "hen2 ji4"
  ],
  "窗口": [
    "chuang1 kou3"
  ],
  "愧": [
    "kui4",
    "kui4"
  ],
  "颉": [
    "Xie2",
    "jie2",
    "xie2"
  ],
  "宪法": [
    "xian4 fa3"
  ],
  "躇": [
    "chu2"
  ],
  "无论如何": [
    "wu2 lun4 ru2 he2"
  ],
  "睑": [
    "jian3"
  ],
  "硙": [
    "ai2",
    "wei2",
    "wei4"
  ],
  "瘫": [
    "tan1"
  ],
  "快活": [
    "kuai4 huo5"
  ],
  "民警": [
    "min2 jing3"
  ],
  "胭": [
    "yan1",
    "yan1"
  ],
  "助手": [
    "zhu4 shou3"
  ],
  "月底": [
    "yue4 di3"
  ],
  "药": [
    "yao4",
    "yao4"
  ],
  "魍": [
    "wang3"
  ],
  "铭记": [
    "ming2 ji4"
  ],
  "完备": [
    "wan2 bei4"
  ],
  "弟弟": [
    "di4 di5"
  ],
  "樽": [
    "zun1",
    "zun1"
  ],
  "台上": [
    "tai2 shang4"
  ],
  "纪录片": [
    "ji4 lu4 pian4"
  ],
  "那": [
    "Na1",
    "Nuo2",
    "na3",
    "na4",
    "nuo2"
  ],
  "伤亡": [
    "shang1 wang2"
  ],
  "海底": [
    "hai3 di3"
  ],
  "师范": [
    "shi1 fan4"
  ],
  "疣": [
    "you2"
  ],
  "匡": [
    "Kuang1",
    "kuang1"
  ],
  "俗话": [
    "su2 hua4"
  ],
  "摆": [
    "bai3"
  ],
  "电力": [
    "dian4 li4"
  ],
  "萨": [
    "Sa4"
  ],
  "会谈": [
    "hui4 tan2"
  ],
  "份": [
    "fen4"
  ],
  "莉": [
    "li4"
  ],
  "支出": [
    "zhi1 chu1"
  ],
  "虽": [
    "sui1"
  ],
  "角逐": [
    "jue2 zhu2"
  ],
  "赅": [
    "Gai1",
    "gai1"
  ],
  "背着": [
    "bei1 zhe5",
    "bei4 zhe5"
  ],
  "内外": [
    "nei4 wai4"
  ],
  "胖子": [
    "pang4 zi5"
  ],
  "验收": [
    "yan4 shou1"
  ],
  "税收": [
    "shui4 shou1"
  ],
  "氧": [
    "yang3"
  ],
  "路上": [
    "lu4 shang5"
  ],
  "入": [
    "ru4"
  ],
  "厨师": [
    "chu2 shi1"
  ],
  "罢了": [
    "ba4 le5",
    "ba4 liao3"
  ],
  "考虑": [
    "kao3 lu:4"
  ],
  "抵消": [
    "di3 xiao1"
  ],
  "堍": [
    "tu4"
  ],
  "山冈": [
    "shan1 gang1"
  ],
  "小伙子": [
    "xiao3 huo3 zi5"
  ],
  "糨": [
    "jiang4"
  ],
  "未必": [
    "wei4 bi4"
  ],
  "收复": [
    "shou1 fu4"
  ],
  "成员": [
    "cheng2 yuan2"
  ],
  "琚": [
    "ju1"
  ],
  "表": [
    "biao3",
    "biao3"
  ],
  "稍微": [
    "shao1 wei1"
  ],
  "相约": [
    "xiang1 yue1"
  ],
  "高等": [
    "gao1 deng3"
  ],
  "黑马": [
    "hei1 ma3"
  ],
  "沦": [
    "lun2"
  ],
  "随心所欲": [
    "sui2 xin1 suo3 yu4"
  ],
  "殳": [
    "Shu1",
    "shu1"
  ],
  "三角": [
    "san1 jiao3"
  ],
  "交际": [
    "jiao1 ji4"
  ],
  "礼堂": [
    "li3 tang2"
  ],
  "夸奖": [
    "kua1 jiang3"
  ],
  "一天到晚": [
    "yi1 tian1 dao4 wan3"
  ],
  "虷": [
    "gan1"
  ],
  "终": [
    "zhong1"
  ],
  "玎": [
    "ding1"
  ],
  "氕": [
    "pie1"
  ],
  "挑战": [
    "tiao3 zhan4"
  ],
  "比": [
    "Bi3",
    "bi1",
    "bi3"
  ],
  "秋季": [
    "qiu1 ji4"
  ],
  "春天": [
    "chun1 tian1"
  ],
  "调查": [
    "diao4 cha2"
  ],
  "桠": [
    "ya1"
  ],
  "菹": [
    "zu1"
  ],
  "魂": [
    "hun2",
    "hun2"
  ],
  "镚": [
    "beng4"
  ],
  "任期": [
    "ren4 qi1"
  ],
  "笯": [
    "nu2"
  ],
  "大声": [
    "da4 sheng1"
  ],
  "空荡荡": [
    "kong1 dang4 dang4"
  ],
  "面子": [
    "mian4 zi5",
    "mian4 zi5"
  ],
  "复活": [
    "fu4 huo2"
  ],
  "有所不同": [
    "you3 suo3 bu4 tong2"
  ],
  "奎": [
    "kui2"
  ],
  "堐": [
    "ya2"
  ],
  "西南": [
    "xi1 nan2"
  ],
  "全力以赴": [
    "quan2 li4 yi3 fu4"
  ],
  "瀌": [
    "biao1"
  ],
  "饮料": [
    "yin3 liao4"
  ],
  "论述": [
    "lun4 shu4"
  ],
  "连接": [
    "lian2 jie1"
  ],
  "崩溃": [
    "beng1 kui4"
  ],
  "圆形": [
    "yuan2 xing2"
  ],
  "试卷": [
    "shi4 juan4"
  ],
  "少女": [
    "shao4 nu:3"
  ],
  "也": [
    "Ye3",
    "ye3"
  ],
  "人情": [
    "ren2 qing2"
  ],
  "灰尘": [
    "hui1 chen2"
  ],
  "领取": [
    "ling3 qu3"
  ],
  "南方": [
    "nan2 fang1"
  ],
  "竟敢": [
    "jing4 gan3"
  ],
  "大款": [
    "da4 kuan3"
  ],
  "剌": [
    "la2",
    "la4"
  ],
  "海外": [
    "hai3 wai4"
  ],
  "夫妻": [
    "fu1 qi1"
  ],
  "前不久": [
    "qian2 bu4 jiu3"
  ],
  "戒烟": [
    "jie4 yan1"
  ],
  "皮球": [
    "pi2 qiu2"
  ],
  "碛": [
    "qi4"
  ],
  "玮": [
    "wei3"
  ],
  "锥": [
    "zhui1"
  ],
  "地段": [
    "di4 duan4"
  ],
  "利润": [
    "li4 run4"
  ],
  "节假日": [
    "jie2 jia4 ri4"
  ],
  "放肆": [
    "fang4 si4"
  ],
  "酒": [
    "jiu3"
  ],
  "揣": [
    "chuai1",
    "chuai3"
  ],
  "褫": [
    "chi3"
  ],
  "车辆": [
    "che1 liang4"
  ],
  "特色": [
    "te4 se4"
  ],
  "酊": [
    "ding1",
    "ding3"
  ],
  "遣": [
    "qian3"
  ],
  "肓": [
    "huang1"
  ],
  "傣": [
    "Dai3"
  ],
  "泐": [
    "le4"
  ],
  "出血": [
    "chu1 xue4"
  ],
  "嵁": [
    null
  ],
  "佯": [
    "yang2"
  ],
  "蒄": [
    "guan1"
  ],
  "分数": [
    "fen1 shu4"
  ],
  "窝": [
    "wo1"
  ],
  "思": [
    "si1"
  ],
  "尾巴": [
    "wei3 ba5"
  ],
  "掐": [
    "qia1"
  ],
  "猞": [
    "she1"
  ],
  "绰": [
    "chao1",
    "chuo4"
  ],
  "遨": [
    "ao2"
  ],
  "缅怀": [
    "mian3 huai2"
  ],
  "理睬": [
    "li3 cai3"
  ],
  "赞许": [
    "zan4 xu3"
  ],
  "指甲": [
    "zhi3 jia5"
  ],
  "华丽": [
    "hua2 li4"
  ],
  "服务器": [
    "fu2 wu4 qi4"
  ],
  "热水器": [
    "re4 shui3 qi4"
  ],
  "浓缩": [
    "nong2 suo1"
  ],
  "过日子": [
    "guo4 ri4 zi5"
  ],
  "笔试": [
    "bi3 shi4"
  ],
  "厖": [
    "pang2"
  ],
  "静止": [
    "jing4 zhi3"
  ],
  "最好": [
    "zui4 hao3"
  ],
  "难以": [
    "nan2 yi3"
  ],
  "天线": [
    "tian1 xian4"
  ],
  "树": [
    "shu4"
  ],
  "岔": [
    "cha4"
  ],
  "园": [
    "Yuan2",
    "yuan2"
  ],
  "外公": [
    "wai4 gong1"
  ],
  "霹": [
    "pi1"
  ],
  "垸": [
    null
  ],
  "头号": [
    "tou2 hao4"
  ],
  "溅": [
    "jian4"
  ],
  "庶": [
    "shu4",
    "shu4"
  ],
  "籥": [
    "yue4"
  ],
  "氐": [
    "Di1",
    "di3"
  ],
  "陈述": [
    "chen2 shu4"
  ],
  "欺诈": [
    "qi1 zha4"
  ],
  "哝": [
    "nong2"
  ],
  "纳税": [
    "na4 shui4"
  ],
  "世界": [
    "shi4 jie4"
  ],
  "穷": [
    "qiong2"
  ],
  "沉迷": [
    "chen2 mi2"
  ],
  "收据": [
    "shou1 ju4"
  ],
  "抑": [
    "yi4"
  ],
  "玩儿": [
    "wan2 r5"
  ],
  "丢脸": [
    "diu1 lian3"
  ],
  "直接": [
    "zhi2 jie1"
  ],
  "捋": [
    "lu:3",
    "luo1"
  ],
  "牢牢": [
    "lao2 lao2"
  ],
  "迷惑": [
    "mi2 huo5"
  ],
  "促销": [
    "cu4 xiao1"
  ],
  "隐身": [
    "yin3 shen1"
  ],
  "硕": [
    "shuo4"
  ],
  "拃": [
    "zha3"
  ],
  "膦": [
    "lin4"
  ],
  "绳": [
    "sheng2"
  ],
  "及时": [
    "ji2 shi2"
  ],
  "刈": [
    "yi4"
  ],
  "弊": [
    "bi4"
  ],
  "津": [
    "Jin1",
    "jin1"
  ],
  "揠": [
    "ya4"
  ],
  "乡下": [
    "xiang1 xia5"
  ],
  "往常": [
    "wang3 chang2"
  ],
  "意见": [
    "yi4 jian4"
  ],
  "联想": [
    "Lian2 xiang3",
    "lian2 xiang3"
  ],
  "姶": [
    "e4"
  ],
  "骼": [
    "ge2"
  ],
  "佥": [
    "qian1"
  ],
  "睨": [
    "ni4"
  ],
  "羱": [
    "yuan2"
  ],
  "御": [
    "yu4",
    "yu4"
  ],
  "边界": [
    "bian1 jie4"
  ],
  "报名": [
    "bao4 ming2"
  ],
  "娃娃": [
    "wa2 wa5"
  ],
  "竭尽全力": [
    "jie2 jin4 quan2 li4"
  ],
  "保": [
    "Bao3",
    "bao3"
  ],
  "能人": [
    "neng2 ren2"
  ],
  "郝": [
    "Hao3"
  ],
  "申办": [
    "shen1 ban4"
  ],
  "心目": [
    "xin1 mu4"
  ],
  "设备": [
    "she4 bei4"
  ],
  "对抗": [
    "dui4 kang4"
  ],
  "小路": [
    "xiao3 lu4"
  ],
  "噼": [
    "pi1"
  ],
  "风暴": [
    "feng1 bao4"
  ],
  "菊": [
    "ju2"
  ],
  "顺": [
    "shun4"
  ],
  "气派": [
    "qi4 pai4"
  ],
  "楪": [
    "die2"
  ],
  "蜢": [
    "meng3"
  ],
  "勚": [
    "yi4"
  ],
  "由此看来": [
    "you2 ci3 kan4 lai2"
  ],
  "媓": [
    null
  ],
  "上升": [
    "shang4 sheng1"
  ],
  "竞争": [
    "jing4 zheng1"
  ],
  "创办": [
    "chuang4 ban4"
  ],
  "麸": [
    "fu1",
    "fu1"
  ],
  "国歌": [
    "guo2 ge1"
  ],
  "天性": [
    "tian1 xing4"
  ],
  "赡养": [
    "shan4 yang3"
  ],
  "蕤": [
    "rui2"
  ],
  "判处": [
    "pan4 chu3"
  ],
  "权衡": [
    "quan2 heng2"
  ],
  "鞯": [
    "jian1"
  ],
  "疴": [
    "ke1"
  ],
  "天主教": [
    "Tian1 zhu3 jiao4"
  ],
  "信用": [
    "xin4 yong4"
  ],
  "指示": [
    "zhi3 shi4"
  ],
  "媸": [
    "chi1"
  ],
  "蟹": [
    "xie4",
    "xie4"
  ],
  "耥": [
    null
  ],
  "丘陵": [
    "qiu1 ling2"
  ],
  "后台": [
    "hou4 tai2"
  ],
  "加盟": [
    "jia1 meng2"
  ],
  "蔡": [
    "Cai4"
  ],
  "幻": [
    "huan4"
  ],
  "篁": [
    "huang2"
  ],
  "音节": [
    "yin1 jie2"
  ],
  "一块儿": [
    "yi1 kuai4 r5"
  ],
  "不适": [
    "bu4 shi4"
  ],
  "京剧": [
    "Jing1 ju4"
  ],
  "坏": [
    "huai4"
  ],
  "购买": [
    "gou4 mai3"
  ],
  "解释": [
    "jie3 shi4"
  ],
  "稍稍": [
    "shao1 shao1"
  ],
  "见仁见智": [
    "jian4 ren2 jian4 zhi4"
  ],
  "钞票": [
    "chao1 piao4"
  ],
  "邰": [
    "Tai2"
  ],
  "驶": [
    "shi3"
  ],
  "兼职": [
    "jian1 zhi2"
  ],
  "磁盘": [
    "ci2 pan2"
  ],
  "乐队": [
    "yue4 dui4"
  ],
  "寄": [
    "ji4"
  ],
  "恍": [
    "huang3",
    "huang3"
  ],
  "收集": [
    "shou1 ji2"
  ],
  "椅子": [
    "yi3 zi5"
  ],
  "灵": [
    "ling2"
  ],
  "疁": [
    null
  ],
  "享": [
    "xiang3",
    "xiang3"
  ],
  "汉": [
    "Han4",
    "han4"
  ],
  "晷": [
    "gui3"
  ],
  "醢": [
    "hai3"
  ],
  "合约": [
    "he2 yue1"
  ],
  "兴建": [
    "xing1 jian4"
  ],
  "健身": [
    "jian4 shen1"
  ],
  "收益": [
    "shou1 yi4"
  ],
  "烊": [
    "yang2"
  ],
  "槟": [
    "bing1"
  ],
  "晰": [
    "xi1",
    "xi1"
  ],
  "显示器": [
    "xian3 shi4 qi4"
  ],
  "推迟": [
    "tui1 chi2"
  ],
  "使唤": [
    "shi3 huan5"
  ],
  "唾液": [
    "tuo4 ye4"
  ],
  "狞": [
    "ning2"
  ],
  "庾": [
    "Yu3"
  ],
  "局面": [
    "ju2 mian4"
  ],
  "同步": [
    "tong2 bu4"
  ],
  "后果": [
    "hou4 guo3"
  ],
  "晖": [
    "hui1"
  ],
  "厄": [
    "e4",
    "e4"
  ],
  "指定": [
    "zhi3 ding4"
  ],
  "扳": [
    "ban1",
    "pan1"
  ],
  "苏": [
    "su1",
    "su1",
    "Su1",
    "su1",
    "su1"
  ],
  "疏忽": [
    "shu1 hu5"
  ],
  "惨重": [
    "can3 zhong4"
  ],
  "璱": [
    "se4"
  ],
  "反弹": [
    "fan3 tan2"
  ],
  "心酸": [
    "xin1 suan1"
  ],
  "劈": [
    "pi1",
    "pi3"
  ],
  "帛": [
    "bo2"
  ],
  "降温": [
    "jiang4 wen1"
  ],
  "重新": [
    "chong2 xin1"
  ],
  "神经": [
    "shen2 jing1"
  ],
  "这会儿": [
    "zhe4 hui4 r5"
  ],
  "好": [
    "hao3",
    "hao4"
  ],
  "生气": [
    "sheng1 qi4"
  ],
  "烯": [
    "xi1"
  ],
  "僳": [
    "Su4"
  ],
  "免费": [
    "mian3 fei4"
  ],
  "铍": [
    "pi2"
  ],
  "倔": [
    "jue2",
    "jue4"
  ],
  "鹖": [
    "he2"
  ],
  "卒": [
    "zu2",
    "cu4",
    "zu2"
  ],
  "栏杆": [
    "lan2 gan1"
  ],
  "鲉": [
    "you2"
  ],
  "罩": [
    "zhao4"
  ],
  "果酱": [
    "guo3 jiang4"
  ],
  "房东": [
    "fang2 dong1"
  ],
  "情绪": [
    "qing2 xu4"
  ],
  "埋怨": [
    "man2 yuan4"
  ],
  "缓慢": [
    "huan3 man4"
  ],
  "哄堂大笑": [
    "hong1 tang2 da4 xiao4"
  ],
  "含义": [
    "han2 yi4"
  ],
  "殊": [
    "shu1"
  ],
  "肘": [
    "zhou3"
  ],
  "水涨船高": [
    "shui3 zhang3 chuan2 gao1"
  ],
  "拯": [
    "zheng3"
  ],
  "预先": [
    "yu4 xian1"
  ],
  "比如": [
    "bi3 ru2"
  ],
  "公道": [
    "gong1 dao4",
    "gong1 dao5"
  ],
  "半信半疑": [
    "ban4 xin4 ban4 yi2"
  ],
  "杼": [
    "zhu4"
  ],
  "期中": [
    "qi1 zhong1"
  ],
  "观测": [
    "guan1 ce4"
  ],
  "慊": [
    "qian4",
    "qie4"
  ],
  "推出": [
    "tui1 chu1"
  ],
  "爷": [
    "ye2"
  ],
  "蓇": [
    "gu3"
  ],
  "慰": [
    "wei4"
  ],
  "途": [
    "tu2"
  ],
  "学子": [
    "xue2 zi3"
  ],
  "旅店": [
    "lu:3 dian4"
  ],
  "常年": [
    "chang2 nian2"
  ],
  "辛苦": [
    "xin1 ku3"
  ],
  "肼": [
    "jing3"
  ],
  "懒": [
    "lan3",
    "lan3"
  ],
  "稳": [
    "wen3"
  ],
  "典范": [
    "dian3 fan4"
  ],
  "烤肉": [
    "kao3 rou4"
  ],
  "爆炸": [
    "bao4 zha4"
  ],
  "修": [
    "Xiu1",
    "xiu1",
    "xiu1"
  ],
  "萸": [
    "yu2"
  ],
  "胆子": [
    "dan3 zi5"
  ],
  "孀": [
    "shuang1"
  ],
  "力度": [
    "li4 du4"
  ],
  "琮": [
    "Cong2",
    "cong2"
  ],
  "学业": [
    "xue2 ye4"
  ],
  "穿小鞋": [
    "chuan1 xiao3 xie2"
  ],
  "启迪": [
    "qi3 di2"
  ],
  "朋": [
    "peng2"
  ],
  "见效": [
    "jian4 xiao4"
  ],
  "瑨": [
    "jin4"
  ],
  "憺": [
    "dan4"
  ],
  "藏身": [
    "cang2 shen1"
  ],
  "孝敬": [
    "xiao4 jing4"
  ],
  "生硬": [
    "sheng1 ying4"
  ],
  "宵": [
    "xiao1"
  ],
  "付": [
    "Fu4",
    "fu4"
  ],
  "遍布": [
    "bian4 bu4"
  ],
  "矿": [
    "kuang4",
    "kuang4"
  ],
  "吃苦": [
    "chi1 ku3"
  ],
  "待遇": [
    "dai4 yu4"
  ],
  "镄": [
    "fei4"
  ],
  "步": [
    "Bu4",
    "bu4"
  ],
  "脊": [
    "ji3"
  ],
  "付款": [
    "fu4 kuan3"
  ],
  "池塘": [
    "chi2 tang2"
  ],
  "吊销": [
    "diao4 xiao1"
  ],
  "会面": [
    "hui4 mian4"
  ],
  "灭亡": [
    "mie4 wang2"
  ],
  "近期": [
    "jin4 qi1"
  ],
  "熥": [
    "teng1"
  ],
  "学校": [
    "xue2 xiao4"
  ],
  "激情": [
    "ji1 qing2"
  ],
  "皇上": [
    "huang2 shang5"
  ],
  "挨打": [
    "ai2 da3"
  ],
  "溽": [
    "ru4"
  ],
  "晱": [
    null
  ],
  "㥄": [
    null
  ],
  "棘手": [
    "ji2 shou3"
  ],
  "竭": [
    "jie2"
  ],
  "冒": [
    "mao4",
    "Mao4",
    "mao4"
  ],
  "上演": [
    "shang4 yan3"
  ],
  "岸上": [
    "an4 shang4"
  ],
  "象": [
    "xiang4"
  ],
  "姈": [
    "ling2"
  ],
  "忙": [
    "mang2"
  ],
  "总统": [
    "zong3 tong3"
  ],
  "碍": [
    "ai4"
  ],
  "貘": [
    "mo4"
  ],
  "软弱": [
    "ruan3 ruo4"
  ],
  "幪": [
    "meng2"
  ],
  "实用": [
    "shi2 yong4"
  ],
  "急救": [
    "ji2 jiu4"
  ],
  "危及": [
    "wei1 ji2"
  ],
  "秤": [
    "cheng1",
    "cheng4"
  ],
  "哟": [
    "yo1",
    "yo5"
  ],
  "陕": [
    "Shan3"
  ],
  "坚韧": [
    "jian1 ren4"
  ],
  "按照": [
    "an4 zhao4"
  ],
  "运气": [
    "yun4 qi5"
  ],
  "铢": [
    "zhu1"
  ],
  "帖子": [
    "tie3 zi5"
  ],
  "轮椅": [
    "lun2 yi3"
  ],
  "翼": [
    "Yi4",
    "yi4"
  ],
  "糗": [
    "Qiu3",
    "qiu3"
  ],
  "离婚": [
    "li2 hun1"
  ],
  "庆祝": [
    "qing4 zhu4"
  ],
  "想到": [
    "xiang3 dao4"
  ],
  "云": [
    "yun2",
    "Yun2",
    "yun2"
  ],
  "插嘴": [
    "cha1 zui3"
  ],
  "通知书": [
    "tong1 zhi1 shu1"
  ],
  "敞开": [
    "chang3 kai1"
  ],
  "闫": [
    "Yan2"
  ],
  "担当": [
    "dan1 dang1"
  ],
  "眼里": [
    null
  ],
  "顺序": [
    "shun4 xu4"
  ],
  "嘱": [
    "zhu3"
  ],
  "篪": [
    "chi2"
  ],
  "传真": [
    "chuan2 zhen1"
  ],
  "跨越": [
    "kua4 yue4"
  ],
  "招待会": [
    "zhao1 dai4 hui4"
  ],
  "北": [
    "bei3"
  ],
  "罐": [
    "guan4",
    "guan4"
  ],
  "座谈": [
    "zuo4 tan2"
  ],
  "尊重": [
    "zun1 zhong4"
  ],
  "贡": [
    "Gong4",
    "gong4"
  ],
  "贿": [
    "hui4"
  ],
  "简洁": [
    "jian3 jie2"
  ],
  "祼": [
    "guan4"
  ],
  "魄": [
    "po4"
  ],
  "狭小": [
    "xia2 xiao3"
  ],
  "新款": [
    "xin1 kuan3"
  ],
  "公顷": [
    "gong1 qing3"
  ],
  "尕": [
    "ga3"
  ],
  "纵深": [
    "zong4 shen1"
  ],
  "罨": [
    "yan3"
  ],
  "仗": [
    "zhang4"
  ],
  "踒": [
    "wo1"
  ],
  "苍": [
    "Cang1",
    "cang1"
  ],
  "留心": [
    "liu2 xin1"
  ],
  "礁石": [
    "jiao1 shi2"
  ],
  "惇": [
    "dun1"
  ],
  "到来": [
    "dao4 lai2"
  ],
  "卫": [
    "Wei4",
    "wei4"
  ],
  "碰巧": [
    "peng4 qiao3"
  ],
  "闪烁": [
    "shan3 shuo4"
  ],
  "批发": [
    "pi1 fa1"
  ],
  "犄": [
    "ji1"
  ],
  "硇": [
    "nao2"
  ],
  "畅": [
    "chang4"
  ],
  "锼": [
    "sou1"
  ],
  "脔": [
    "luan2"
  ],
  "告别": [
    "gao4 bie2"
  ],
  "干部": [
    "gan4 bu4"
  ],
  "启动": [
    "qi3 dong4"
  ],
  "上头": [
    "shang4 tou2",
    "shang4 tou5"
  ],
  "千万": [
    "qian1 wan4"
  ],
  "徼": [
    "jiao3",
    "jiao4"
  ],
  "潇": [
    "xiao1"
  ],
  "㰀": [
    null
  ],
  "烃": [
    "ting1"
  ],
  "休假": [
    "xiu1 jia4"
  ],
  "冗长": [
    "rong3 chang2"
  ],
  "阖": [
    "he2"
  ],
  "溥": [
    "Pu3",
    "pu3"
  ],
  "苊": [
    "e4"
  ],
  "固然": [
    "gu4 ran2"
  ],
  "柙": [
    "xia2"
  ],
  "现": [
    "xian4"
  ],
  "资本": [
    "zi1 ben3"
  ],
  "榨": [
    "zha4",
    "zha4"
  ],
  "琫": [
    "beng3"
  ],
  "样本": [
    "yang4 ben3"
  ],
  "指数": [
    "zhi3 shu4"
  ],
  "离": [
    "chi1",
    "Li2",
    "li2"
  ],
  "通行": [
    "tong1 xing2"
  ],
  "榷": [
    "que4"
  ],
  "绌": [
    "chu4"
  ],
  "台": [
    "Tai2",
    "tai2",
    "tai2",
    "Tai2",
    "tai2",
    "tai2"
  ],
  "友好": [
    "You3 hao3",
    "you3 hao3"
  ],
  "前年": [
    "qian2 nian2"
  ],
  "顺从": [
    "shun4 cong2"
  ],
  "㽏": [
    null
  ],
  "敔": [
    "yu3"
  ],
  "一个劲儿": [
    "yi1 ge4 jin4 r5"
  ],
  "嗲": [
    "dia3"
  ],
  "尽管": [
    "jin3 guan3"
  ],
  "襚": [
    "sui4"
  ],
  "狂": [
    "kuang2"
  ],
  "脖": [
    "bo2"
  ],
  "峂": [
    "tong2"
  ],
  "手腕": [
    "shou3 wan4"
  ],
  "绂": [
    "fu2"
  ],
  "水灵灵": [
    "shui3 ling2 ling2"
  ],
  "濑": [
    "lai4"
  ],
  "配偶": [
    "pei4 ou3"
  ],
  "节能": [
    "jie2 neng2"
  ],
  "颅": [
    "lu2"
  ],
  "礼拜": [
    "li3 bai4"
  ],
  "证据": [
    "zheng4 ju4"
  ],
  "边远": [
    "bian1 yuan3"
  ],
  "脉": [
    "mai4",
    "mai4",
    "mo4",
    "mai4"
  ],
  "美观": [
    "mei3 guan1"
  ],
  "分赃": [
    "fen1 zang1"
  ],
  "顶级": [
    "ding3 ji2"
  ],
  "赤字": [
    "chi4 zi4"
  ],
  "菍": [
    null
  ],
  "牺": [
    "xi1"
  ],
  "贫": [
    "pin2"
  ],
  "残疾": [
    "can2 ji2"
  ],
  "罾": [
    "zeng1"
  ],
  "下次": [
    "xia4 ci4"
  ],
  "镮": [
    "huan2"
  ],
  "圊": [
    "qing1"
  ],
  "蝈": [
    "guo1"
  ],
  "撅": [
    "jue1"
  ],
  "借": [
    "jie4",
    "jie4"
  ],
  "大体": [
    "da4 ti3"
  ],
  "刽": [
    "gui4"
  ],
  "传递": [
    "chuan2 di4"
  ],
  "瓿": [
    "bu4"
  ],
  "廖": [
    "Liao4"
  ],
  "本质": [
    "ben3 zhi4"
  ],
  "说道": [
    "shuo1 dao4",
    "shuo1 dao5"
  ],
  "崒": [
    "cui4",
    "zu2"
  ],
  "惛": [
    "hun1"
  ],
  "沸沸扬扬": [
    "fei4 fei4 yang2 yang2"
  ],
  "风味": [
    "feng1 wei4"
  ],
  "风": [
    "feng1"
  ],
  "峨": [
    "e2",
    "e2"
  ],
  "合理": [
    "he2 li3"
  ],
  "枵": [
    "xiao1"
  ],
  "宙": [
    "zhou4"
  ],
  "炷": [
    "zhu4"
  ],
  "瞪": [
    "deng4"
  ],
  "肥皂": [
    "fei2 zao4"
  ],
  "这时候": [
    null
  ],
  "不得而知": [
    "bu4 de2 er2 zhi1"
  ],
  "根据": [
    "gen1 ju4"
  ],
  "开通": [
    "kai1 tong1",
    "kai1 tong5"
  ],
  "一时": [
    "yi1 shi2"
  ],
  "鸽子": [
    "ge1 zi5"
  ],
  "湄": [
    "mei2"
  ],
  "别具匠心": [
    "bie2 ju4 jiang4 xin1"
  ],
  "天气": [
    "tian1 qi4"
  ],
  "匮": [
    "Kui4",
    "gui4",
    "kui4"
  ],
  "蓑": [
    "suo1",
    "suo1"
  ],
  "教条": [
    "jiao4 tiao2"
  ],
  "糟": [
    "zao1"
  ],
  "动人": [
    "dong4 ren2"
  ],
  "贫穷": [
    "pin2 qiong2"
  ],
  "情况": [
    "qing2 kuang4"
  ],
  "耸": [
    "song3"
  ],
  "家庭": [
    "jia1 ting2"
  ],
  "兑现": [
    "dui4 xian4"
  ],
  "段": [
    "Duan4",
    "duan4"
  ],
  "加班": [
    "jia1 ban1"
  ],
  "空中": [
    "kong1 zhong1"
  ],
  "怠": [
    "dai4"
  ],
  "力": [
    "Li4",
    "li4"
  ],
  "提倡": [
    "ti2 chang4"
  ],
  "攘": [
    "rang3"
  ],
  "羧": [
    "suo1"
  ],
  "环绕": [
    "huan2 rao4"
  ],
  "无精打采": [
    "wu2 jing1 da3 cai3"
  ],
  "岁": [
    "sui4",
    "sui4"
  ],
  "蝶": [
    "die2",
    "die2"
  ],
  "医疗": [
    "yi1 liao2"
  ],
  "交往": [
    "jiao1 wang3"
  ],
  "蔬": [
    "shu1"
  ],
  "挫": [
    "cuo4"
  ],
  "下功夫": [
    "xia4 gong1 fu5"
  ],
  "打岔": [
    "da3 cha4"
  ],
  "现象": [
    "xian4 xiang4"
  ],
  "绉": [
    "zhou4"
  ],
  "风水": [
    "feng1 shui3"
  ],
  "汪洋": [
    "wang1 yang2"
  ],
  "羡慕": [
    "xian4 mu4"
  ],
  "希望": [
    "xi1 wang4"
  ],
  "香肠": [
    "xiang1 chang2"
  ],
  "魇": [
    "yan3"
  ],
  "账号": [
    "zhang4 hao4"
  ],
  "盟友": [
    "meng2 you3"
  ],
  "声明": [
    "sheng1 ming2"
  ],
  "芾": [
    "fei4",
    "fu2"
  ],
  "靳": [
    "Jin4",
    "jin4"
  ],
  "资深": [
    "zi1 shen1"
  ],
  "真情": [
    "zhen1 qing2"
  ],
  "便捷": [
    "bian4 jie2"
  ],
  "得罪": [
    "de2 zui4",
    "de2 zui5"
  ],
  "泠": [
    "Ling2",
    "ling2"
  ],
  "吁": [
    "xu1",
    "yu4",
    "yu4"
  ],
  "判定": [
    "pan4 ding4"
  ],
  "略微": [
    "lu:e4 wei1"
  ],
  "说不定": [
    "shuo1 bu5 ding4"
  ],
  "多劳多得": [
    "duo1 lao2 duo1 de2"
  ],
  "雏": [
    "chu2"
  ],
  "筹办": [
    "chou2 ban4"
  ],
  "冲动": [
    "chong1 dong4"
  ],
  "朴实": [
    "pu3 shi2"
  ],
  "哭泣": [
    "ku1 qi4"
  ],
  "时": [
    "shi2",
    "Shi2",
    "shi2"
  ],
  "大会": [
    "da4 hui4"
  ],
  "晚报": [
    "wan3 bao4"
  ],
  "踔": [
    "chuo1"
  ],
  "厨": [
    "chu2",
    "chu2"
  ],
  "土匪": [
    "tu3 fei3"
  ],
  "招数": [
    "zhao1 shu4"
  ],
  "红润": [
    "hong2 run4"
  ],
  "鉴": [
    "jian4",
    "jian4",
    "jian4",
    "jian4"
  ],
  "小人": [
    "xiao3 ren2"
  ],
  "练习": [
    "lian4 xi2"
  ],
  "涛": [
    "tao1"
  ],
  "䗛": [
    null
  ],
  "擘": [
    "bo4"
  ],
  "且": [
    "qie3"
  ],
  "磁卡": [
    "ci2 ka3"
  ],
  "所谓": [
    "suo3 wei4"
  ],
  "筤": [
    "lang2"
  ],
  "隰": [
    "Xi2",
    "xi2"
  ],
  "建议": [
    "jian4 yi4"
  ],
  "楒": [
    null
  ],
  "流": [
    "liu2"
  ],
  "尻": [
    "kao1"
  ],
  "牙齿": [
    "ya2 chi3"
  ],
  "怜惜": [
    "lian2 xi1"
  ],
  "痣": [
    "zhi4"
  ],
  "男子": [
    "nan2 zi3"
  ],
  "寂": [
    "ji4"
  ],
  "揆": [
    "kui2"
  ],
  "闻名": [
    "wen2 ming2"
  ],
  "主宰": [
    "zhu3 zai3"
  ],
  "当场": [
    "dang1 chang3"
  ],
  "真实": [
    "zhen1 shi2"
  ],
  "社会主义": [
    "she4 hui4 zhu3 yi4"
  ],
  "清洗": [
    "qing1 xi3"
  ],
  "坜": [
    "li4"
  ],
  "租": [
    "zu1"
  ],
  "编号": [
    "bian1 hao4"
  ],
  "介绍": [
    "jie4 shao4"
  ],
  "岜": [
    "ba1"
  ],
  "守候": [
    "shou3 hou4"
  ],
  "情怀": [
    "qing2 huai2"
  ],
  "蝌": [
    "ke1"
  ],
  "凉快": [
    "liang2 kuai5"
  ],
  "醮": [
    "jiao4"
  ],
  "别": [
    "Bie2",
    "bie2",
    "bie4"
  ],
  "拨": [
    "bo1"
  ],
  "乸": [
    "na3"
  ],
  "思想": [
    "si1 xiang3"
  ],
  "哱": [
    "bo1"
  ],
  "倮": [
    "luo3"
  ],
  "发愣": [
    "fa1 leng4"
  ],
  "鲃": [
    "ba1"
  ],
  "颠": [
    "dian1"
  ],
  "小贩": [
    "xiao3 fan4"
  ],
  "邠": [
    "Bin1",
    "bin1"
  ],
  "反省": [
    "fan3 xing3"
  ],
  "埃": [
    "Ai1",
    "ai1"
  ],
  "归还": [
    "gui1 huan2"
  ],
  "巅": [
    "dian1"
  ],
  "阿姨": [
    "a1 yi2"
  ],
  "逼近": [
    "bi1 jin4"
  ],
  "渫": [
    "Xie4",
    "xie4"
  ],
  "吃亏": [
    "chi1 kui1"
  ],
  "届时": [
    "jie4 shi2"
  ],
  "獯": [
    "xun1"
  ],
  "新颖": [
    "xin1 ying3"
  ],
  "晒太阳": [
    "shai4 tai4 yang2"
  ],
  "哗": [
    "hua1",
    "hua2",
    "hua2"
  ],
  "珪": [
    "gui1"
  ],
  "本人": [
    "ben3 ren2"
  ],
  "蔬菜": [
    "shu1 cai4"
  ],
  "博": [
    "bo2",
    "bo2"
  ],
  "太极拳": [
    "tai4 ji2 quan2"
  ],
  "瞄准": [
    "miao2 zhun3"
  ],
  "看望": [
    "kan4 wang4"
  ],
  "咔": [
    "ka3"
  ],
  "传言": [
    "chuan2 yan2"
  ],
  "游泳池": [
    "you2 yong3 chi2"
  ],
  "巴士": [
    "ba1 shi4"
  ],
  "隐藏": [
    "yin3 cang2"
  ],
  "邂": [
    "xie4"
  ],
  "辱": [
    "ru3"
  ],
  "骨干": [
    "gu3 gan4"
  ],
  "末日": [
    "Mo4 ri4",
    "mo4 ri4"
  ],
  "采访": [
    "cai3 fang3"
  ],
  "苯": [
    "ben3"
  ],
  "嘀": [
    "di1",
    "di2"
  ],
  "礼服": [
    "li3 fu2"
  ],
  "翅": [
    "chi4",
    "chi4"
  ],
  "椁": [
    "guo3"
  ],
  "谐": [
    "xie2"
  ],
  "华语": [
    "Hua2 yu3"
  ],
  "慰劳": [
    "wei4 lao2"
  ],
  "在": [
    "zai4"
  ],
  "甜蜜": [
    "tian2 mi4"
  ],
  "鹲": [
    "meng2"
  ],
  "锲": [
    "qie4"
  ],
  "主要": [
    "zhu3 yao4"
  ],
  "定义": [
    "ding4 yi4"
  ],
  "饧": [
    "xing2"
  ],
  "指": [
    "zhi3"
  ],
  "铈": [
    "shi4"
  ],
  "富": [
    "Fu4",
    "fu4"
  ],
  "连锁店": [
    "lian2 suo3 dian4"
  ],
  "殆": [
    "dai4"
  ],
  "阴谋": [
    "yin1 mou2"
  ],
  "一路上": [
    "yi1 lu4 shang4"
  ],
  "旯": [
    "la2"
  ],
  "报销": [
    "bao4 xiao1"
  ],
  "外": [
    "wai4"
  ],
  "抱歉": [
    "bao4 qian4"
  ],
  "房": [
    "Fang2",
    "fang2"
  ],
  "妞": [
    "niu1"
  ],
  "獐": [
    "zhang1",
    "zhang1"
  ],
  "开": [
    "kai1"
  ],
  "锱": [
    "zi1"
  ],
  "随意": [
    "sui2 yi4"
  ],
  "日记": [
    "ri4 ji4"
  ],
  "貉": [
    "he2",
    "mo4"
  ],
  "衲": [
    "na4"
  ],
  "坚": [
    "jian1"
  ],
  "浏": [
    "liu2"
  ],
  "左顾右盼": [
    "zuo3 gu4 you4 pan4"
  ],
  "犰": [
    "qiu2"
  ],
  "郗": [
    "Chi1"
  ],
  "专": [
    "zhuan1",
    "zhuan1"
  ],
  "嘿": [
    "hei1"
  ],
  "终于": [
    "zhong1 yu2"
  ],
  "䦃": [
    null
  ],
  "孙子": [
    "Sun1 zi3",
    "sun1 zi5"
  ],
  "扃": [
    "jiong1"
  ],
  "娈": [
    "luan2"
  ],
  "看热闹": [
    "kan4 re4 nao5"
  ],
  "茂密": [
    "mao4 mi4"
  ],
  "信": [
    "xin4"
  ],
  "擐": [
    "huan4"
  ],
  "铉": [
    "xuan4"
  ],
  "加速": [
    "jia1 su4"
  ],
  "实事求是": [
    "shi2 shi4 qiu2 shi4"
  ],
  "廒": [
    "ao2"
  ],
  "感觉": [
    "gan3 jue2"
  ],
  "枲": [
    "xi3"
  ],
  "歪曲": [
    "wai1 qu1"
  ],
  "今日": [
    "jin1 ri4"
  ],
  "交接": [
    "jiao1 jie1"
  ],
  "色": [
    "se4",
    "shai3"
  ],
  "议会": [
    "yi4 hui4"
  ],
  "以及": [
    "yi3 ji2"
  ],
  "序幕": [
    "xu4 mu4"
  ],
  "邑": [
    "yi4"
  ],
  "祀": [
    "si4",
    "si4"
  ],
  "手续费": [
    "shou3 xu4 fei4"
  ],
  "正在": [
    "zheng4 zai4"
  ],
  "镥": [
    "lu3"
  ],
  "辞退": [
    "ci2 tui4"
  ],
  "琳": [
    "lin2"
  ],
  "纽扣": [
    "niu3 kou4"
  ],
  "意义": [
    "yi4 yi4"
  ],
  "院": [
    "yuan4"
  ],
  "锞": [
    "ke4"
  ],
  "振动": [
    "zhen4 dong4"
  ],
  "是非": [
    "shi4 fei1"
  ],
  "有劲儿": [
    null
  ],
  "提高": [
    "ti2 gao1"
  ],
  "精明": [
    "jing1 ming2"
  ],
  "拱": [
    "gong3"
  ],
  "苠": [
    "min2"
  ],
  "方言": [
    "Fang1 yan2",
    "fang1 yan2"
  ],
  "鲜美": [
    "xian1 mei3"
  ],
  "芭蕾": [
    "ba1 lei3"
  ],
  "市区": [
    "shi4 qu1"
  ],
  "遵守": [
    "zun1 shou3"
  ],
  "背面": [
    "bei4 mian4"
  ],
  "阻力": [
    "zu3 li4"
  ],
  "尾气": [
    "wei3 qi4"
  ],
  "公关": [
    "gong1 guan1"
  ],
  "障碍": [
    "zhang4 ai4"
  ],
  "对峙": [
    "dui4 zhi4"
  ],
  "出口成章": [
    "chu1 kou3 cheng2 zhang1"
  ],
  "贶": [
    "kuang4"
  ],
  "占卜": [
    "zhan1 bu3"
  ],
  "蔈": [
    null
  ],
  "敝": [
    "bi4"
  ],
  "功劳": [
    "gong1 lao2"
  ],
  "仨": [
    "sa1"
  ],
  "奶奶": [
    "nai3 nai5"
  ],
  "万万": [
    "wan4 wan4"
  ],
  "馥": [
    "fu4"
  ],
  "主观": [
    "zhu3 guan1"
  ],
  "敬业": [
    "jing4 ye4"
  ],
  "泺": [
    "luo4"
  ],
  "旌": [
    "jing1"
  ],
  "取胜": [
    "qu3 sheng4"
  ],
  "原料": [
    "yuan2 liao4"
  ],
  "嗉": [
    "su4"
  ],
  "恶心": [
    "e3 xin1",
    "e3 xin1",
    "e4 xin1"
  ],
  "喏": [
    "nuo4",
    "re3"
  ],
  "极少数": [
    "ji2 shao3 shu4"
  ],
  "佳节": [
    "jia1 jie2"
  ],
  "砧": [
    "zhen1",
    "zhen1"
  ],
  "分工": [
    "fen1 gong1"
  ],
  "囚犯": [
    "qiu2 fan4"
  ],
  "降临": [
    "jiang4 lin2"
  ],
  "囔": [
    "nang1"
  ],
  "标示": [
    "biao1 shi4"
  ],
  "何时": [
    "he2 shi2"
  ],
  "堤": [
    "di1",
    "di1"
  ],
  "靿": [
    "yao4"
  ],
  "哇": [
    "wa1",
    "wa5"
  ],
  "锂": [
    "li3"
  ],
  "疚": [
    "jiu4"
  ],
  "回想": [
    "hui2 xiang3"
  ],
  "沁": [
    "qin4"
  ],
  "瘤": [
    "liu2",
    "liu2"
  ],
  "绘画": [
    "hui4 hua4"
  ],
  "解读": [
    "jie3 du2"
  ],
  "葚": [
    "shen4"
  ],
  "燃": [
    "ran2"
  ],
  "脸": [
    "lian3"
  ],
  "惊喜": [
    "jing1 xi3"
  ],
  "滨海": [
    "Bin1 hai3",
    "bin1 hai3"
  ],
  "钊": [
    "zhao1"
  ],
  "儿女": [
    "er2 nu:3"
  ],
  "乡亲": [
    "xiang1 qin1"
  ],
  "雇主": [
    "gu4 zhu3"
  ],
  "弊端": [
    "bi4 duan1"
  ],
  "耋": [
    "die2"
  ],
  "舅舅": [
    "jiu4 jiu5"
  ],
  "打包": [
    "da3 bao1"
  ],
  "世代": [
    "shi4 dai4"
  ],
  "翳": [
    "yi4",
    "yi4"
  ],
  "龁": [
    "he2"
  ],
  "微观": [
    "wei1 guan1"
  ],
  "解散": [
    "jie3 san4"
  ],
  "拳": [
    "quan2"
  ],
  "面积": [
    "mian4 ji1"
  ],
  "喜悦": [
    "xi3 yue4"
  ],
  "雨衣": [
    "yu3 yi1"
  ],
  "艹": [
    "cao3"
  ],
  "囚": [
    "qiu2"
  ],
  "见面": [
    "jian4 mian4"
  ],
  "今后": [
    "jin1 hou4"
  ],
  "识字": [
    "shi2 zi4"
  ],
  "澭": [
    null
  ],
  "沌": [
    "dun4"
  ],
  "丢人": [
    "diu1 ren2"
  ],
  "炎": [
    "yan2"
  ],
  "纠正": [
    "jiu1 zheng4"
  ],
  "口袋": [
    "kou3 dai4"
  ],
  "碑": [
    "bei1"
  ],
  "慢": [
    "man4"
  ],
  "罔": [
    "wang3",
    "wang3"
  ],
  "昤": [
    "ling1"
  ],
  "惨": [
    "can3"
  ],
  "谋害": [
    "mou2 hai4"
  ],
  "蒺": [
    "ji2"
  ],
  "楦": [
    "xuan4",
    "xuan4"
  ],
  "孑": [
    "jie2"
  ],
  "直达": [
    "zhi2 da2"
  ],
  "缝": [
    "feng2",
    "feng4"
  ],
  "土生土长": [
    "tu3 sheng1 tu3 zhang3"
  ],
  "带有": [
    "dai4 you3"
  ],
  "刚刚": [
    "gang1 gang5"
  ],
  "玩意儿": [
    "wan2 yi4 r5"
  ],
  "燥": [
    "zao4"
  ],
  "话题": [
    "hua4 ti2"
  ],
  "龃": [
    "ju3"
  ],
  "大小": [
    "da4 xiao3"
  ],
  "海水": [
    "hai3 shui3"
  ],
  "追尾": [
    "zhui1 wei3"
  ],
  "从容": [
    "cong2 rong2"
  ],
  "恩人": [
    "en1 ren2"
  ],
  "钟": [
    "Zhong1",
    "zhong1",
    "zhong1"
  ],
  "接收": [
    "jie1 shou1"
  ],
  "朴素": [
    "pu3 su4"
  ],
  "路段": [
    "lu4 duan4"
  ],
  "利用": [
    "li4 yong4"
  ],
  "双打": [
    "shuang1 da3"
  ],
  "念": [
    "nian4",
    "nian4"
  ],
  "程序": [
    "cheng2 xu4"
  ],
  "橇": [
    "qiao1"
  ],
  "逄": [
    "Pang2"
  ],
  "轨道": [
    "gui3 dao4"
  ],
  "存心": [
    "cun2 xin1"
  ],
  "摔跤": [
    "shuai1 jiao1"
  ],
  "好容易": [
    "hao3 rong2 yi4"
  ],
  "光彩": [
    "guang1 cai3"
  ],
  "物价": [
    "wu4 jia4"
  ],
  "缈": [
    "miao3"
  ],
  "任人宰割": [
    "ren4 ren2 zai3 ge1"
  ],
  "藻": [
    "zao3"
  ],
  "催促": [
    "cui1 cu4"
  ],
  "亲身": [
    "qin1 shen1"
  ],
  "蛄": [
    "gu1"
  ],
  "担忧": [
    "dan1 you1"
  ],
  "隧": [
    "sui4"
  ],
  "平台": [
    "ping2 tai2"
  ],
  "龛": [
    "kan1"
  ],
  "愿望": [
    "yuan4 wang4"
  ],
  "大体上": [
    "da4 ti3 shang4"
  ],
  "宽恕": [
    "kuan1 shu4"
  ],
  "计策": [
    "ji4 ce4"
  ],
  "圆满": [
    "yuan2 man3"
  ],
  "宣称": [
    "xuan1 cheng1"
  ],
  "逐": [
    "zhu2"
  ],
  "隐": [
    "yin3",
    "yin4"
  ],
  "班级": [
    "ban1 ji2"
  ],
  "无边": [
    "wu2 bian1"
  ],
  "表情": [
    "biao3 qing2"
  ],
  "地形": [
    "di4 xing2"
  ],
  "沉重": [
    "chen2 zhong4"
  ],
  "吩": [
    "fen1"
  ],
  "霍乱": [
    "huo4 luan4"
  ],
  "安全": [
    "an1 quan2"
  ],
  "往": [
    "wang3",
    "wang3"
  ],
  "精通": [
    "jing1 tong1"
  ],
  "难道": [
    "nan2 dao4"
  ],
  "瘛": [
    "chi4"
  ],
  "每逢": [
    "mei3 feng2"
  ],
  "随手": [
    "sui2 shou3"
  ],
  "蝮": [
    "fu4"
  ],
  "柱": [
    "zhu4"
  ],
  "眸": [
    "mou2"
  ],
  "功夫": [
    "gong1 fu5"
  ],
  "攽": [
    "ban1"
  ],
  "导航": [
    "dao3 hang2"
  ],
  "贪": [
    "tan1"
  ],
  "喋": [
    "die2",
    "die2"
  ],
  "经度": [
    "jing1 du4"
  ],
  "号": [
    "hao2",
    "hao4"
  ],
  "巇": [
    "xi1"
  ],
  "蝘": [
    "yan3"
  ],
  "棪": [
    "yan3"
  ],
  "积极": [
    "ji1 ji2"
  ],
  "执着": [
    "zhi2 zhuo2"
  ],
  "年代": [
    "nian2 dai4"
  ],
  "电动": [
    "dian4 dong4"
  ],
  "联欢": [
    "lian2 huan1"
  ],
  "桃": [
    "tao2"
  ],
  "月球": [
    "yue4 qiu2"
  ],
  "废话": [
    "fei4 hua4"
  ],
  "勐": [
    "meng3"
  ],
  "美滋滋": [
    "mei3 zi1 zi1"
  ],
  "出访": [
    "chu1 fang3"
  ],
  "扣留": [
    "kou4 liu2"
  ],
  "抔": [
    "pou2"
  ],
  "褴": [
    "lan2"
  ],
  "保修": [
    "bao3 xiu1"
  ],
  "咖": [
    "ga1",
    "ka1"
  ],
  "安抚": [
    "an1 fu3"
  ],
  "访谈": [
    "fang3 tan2"
  ],
  "无恶不作": [
    "wu2 e4 bu4 zuo4"
  ],
  "骉": [
    "biao1"
  ],
  "行为": [
    "xing2 wei2"
  ],
  "姽": [
    "gui3"
  ],
  "陆军": [
    "lu4 jun1"
  ],
  "野": [
    "ye3",
    "ye3",
    "ye3"
  ],
  "白色": [
    "bai2 se4"
  ],
  "鼻": [
    "bi2"
  ],
  "奶": [
    "nai3",
    "nai3",
    "nai3"
  ],
  "差距": [
    "cha1 ju4"
  ],
  "哗然": [
    "hua2 ran2",
    "hua2 ran2"
  ],
  "大学生": [
    "da4 xue2 sheng1"
  ],
  "颃": [
    "hang2"
  ],
  "痨": [
    "lao2"
  ],
  "簧": [
    "huang2"
  ],
  "住址": [
    "zhu4 zhi3"
  ],
  "商城": [
    "Shang1 cheng2",
    "shang1 cheng2"
  ],
  "筌": [
    "quan2"
  ],
  "市长": [
    "shi4 zhang3"
  ],
  "柰": [
    "nai4"
  ],
  "攫": [
    "jue2"
  ],
  "国防": [
    "guo2 fang2"
  ],
  "自由": [
    "zi4 you2"
  ],
  "旅客": [
    "lu:3 ke4"
  ],
  "茸": [
    "rong2"
  ],
  "减": [
    "jian3"
  ],
  "忿": [
    "fen4"
  ],
  "酃": [
    "ling2"
  ],
  "优雅": [
    "you1 ya3"
  ],
  "沉浸": [
    "chen2 jin4"
  ],
  "胸": [
    "xiong1",
    "xiong1"
  ],
  "保重": [
    "bao3 zhong4"
  ],
  "啼": [
    "ti2",
    "ti2"
  ],
  "没用": [
    "mei2 yong4"
  ],
  "职能": [
    "zhi2 neng2"
  ],
  "着落": [
    "zhuo2 luo4"
  ],
  "扩张": [
    "kuo4 zhang1"
  ],
  "误区": [
    "wu4 qu1"
  ],
  "火": [
    "Huo3",
    "huo3"
  ],
  "倭": [
    "wo1"
  ],
  "跪": [
    "gui4"
  ],
  "籀": [
    "Zhou4",
    "zhou4"
  ],
  "镐": [
    "gao3",
    "hao4"
  ],
  "健康": [
    "jian4 kang1"
  ],
  "啜": [
    "chuo4"
  ],
  "客流": [
    "ke4 liu2"
  ],
  "有人": [
    "you3 ren2"
  ],
  "粘": [
    "nian2",
    "zhan1"
  ],
  "冲洗": [
    "chong1 xi3"
  ],
  "触犯": [
    "chu4 fan4"
  ],
  "哓": [
    "xiao1"
  ],
  "侵略": [
    "qin1 lu:e4"
  ],
  "焦虑": [
    "jiao1 lu:4"
  ],
  "约束": [
    "yue1 shu4"
  ],
  "选手": [
    "xuan3 shou3"
  ],
  "夜间": [
    "ye4 jian1"
  ],
  "八": [
    "ba1"
  ],
  "无家可归": [
    "wu2 jia1 ke3 gui1"
  ],
  "综上所述": [
    "zong1 shang4 suo3 shu4"
  ],
  "场合": [
    "chang3 he2"
  ],
  "鼾": [
    "han1"
  ],
  "勠": [
    "lu4"
  ],
  "使劲": [
    "shi3 jin4"
  ],
  "歪": [
    "wai1",
    "wai3"
  ],
  "引": [
    "yin3"
  ],
  "咨询": [
    "zi1 xun2"
  ],
  "钫": [
    "fang1"
  ],
  "郢": [
    "Ying3"
  ],
  "阘": [
    "ta4"
  ],
  "䶮": [
    "yan3"
  ],
  "烀": [
    "hu1"
  ],
  "稀奇": [
    "xi1 qi2"
  ],
  "停车": [
    "ting2 che1"
  ],
  "癶": [
    "bo1"
  ],
  "牂": [
    "zang1"
  ],
  "牧场": [
    "mu4 chang3"
  ],
  "购": [
    "gou4"
  ],
  "座谈会": [
    "zuo4 tan2 hui4"
  ],
  "冷漠": [
    "leng3 mo4"
  ],
  "赶上": [
    "gan3 shang4"
  ],
  "城镇": [
    "cheng2 zhen4"
  ],
  "下车": [
    "xia4 che1"
  ],
  "官兵": [
    "guan1 bing1"
  ],
  "图": [
    "tu2"
  ],
  "呼声": [
    "hu1 sheng1"
  ],
  "占据": [
    "zhan4 ju4"
  ],
  "撮": [
    "cuo1",
    "zuo3"
  ],
  "以至于": [
    "yi3 zhi4 yu2"
  ],
  "着眼": [
    "zhuo2 yan3"
  ],
  "譬": [
    "pi4"
  ],
  "机器": [
    "ji1 qi4"
  ],
  "穿过": [
    "chuan1 guo4"
  ],
  "一家人": [
    "yi1 jia1 ren2"
  ],
  "鹨": [
    "liu4"
  ],
  "开场白": [
    "kai1 chang3 bai2"
  ],
  "僰": [
    "Bo2"
  ],
  "窀": [
    "zhun1"
  ],
  "不断": [
    "bu4 duan4"
  ],
  "水槽": [
    "shui3 cao2"
  ],
  "机智": [
    "ji1 zhi4"
  ],
  "琅": [
    "lang2",
    "Lang2",
    "lang2"
  ],
  "栅": [
    "zha4"
  ],
  "过期": [
    "guo4 qi1"
  ],
  "栌": [
    "lu2"
  ],
  "畅谈": [
    "chang4 tan2"
  ],
  "尸": [
    "shi1",
    "shi1"
  ],
  "说白了": [
    "shuo1 bai2 le5"
  ],
  "焯": [
    "chao1"
  ],
  "侏": [
    "zhu1"
  ],
  "酿": [
    "niang4"
  ],
  "伦": [
    "lun2"
  ],
  "打造": [
    "da3 zao4"
  ],
  "淞": [
    "Song1",
    "song1"
  ],
  "摄影师": [
    "she4 ying3 shi1"
  ],
  "图形": [
    "tu2 xing2"
  ],
  "熘": [
    "liu1"
  ],
  "蜿": [
    "wan1"
  ],
  "高尚": [
    "gao1 shang4"
  ],
  "衰退": [
    "shuai1 tui4"
  ],
  "开场": [
    "kai1 chang3"
  ],
  "睎": [
    "xi1"
  ],
  "湉": [
    "tian2"
  ],
  "戮": [
    "lu4",
    "lu4"
  ],
  "糙": [
    "cao1"
  ],
  "乂": [
    "yi4"
  ],
  "糍": [
    "ci2"
  ],
  "布满": [
    "bu4 man3"
  ],
  "渊源": [
    "yuan1 yuan2"
  ],
  "邘": [
    "Yu2",
    "yu2"
  ],
  "骝": [
    "liu2"
  ],
  "畅通": [
    "chang4 tong1"
  ],
  "记者": [
    "ji4 zhe3"
  ],
  "縠": [
    "hu2"
  ],
  "矜": [
    "jin1"
  ],
  "夸夸其谈": [
    "kua1 kua1 qi2 tan2"
  ],
  "讣": [
    "fu4"
  ],
  "吃喝玩乐": [
    "chi1 he1 wan2 le4"
  ],
  "医务": [
    "yi1 wu4"
  ],
  "期盼": [
    "qi1 pan4"
  ],
  "美德": [
    "Mei3 De2",
    "mei3 de2"
  ],
  "禅杖": [
    "chan2 zhang4"
  ],
  "霰": [
    "xian4"
  ],
  "机制": [
    "ji1 zhi4",
    "ji1 zhi4"
  ],
  "摇摇欲坠": [
    "yao2 yao2 yu4 zhui4"
  ],
  "挑": [
    "tiao1",
    "tiao3"
  ],
  "踺": [
    "jian4"
  ],
  "理科": [
    "li3 ke1"
  ],
  "废除": [
    "fei4 chu2"
  ],
  "伸": [
    "shen1"
  ],
  "多方面": [
    "duo1 fang1 mian4"
  ],
  "膨": [
    "peng2"
  ],
  "客车": [
    "ke4 che1"
  ],
  "泛": [
    "fan4",
    "fan4",
    "fan4"
  ],
  "揕": [
    "zhen4"
  ],
  "跚": [
    "shan1"
  ],
  "羽绒服": [
    "yu3 rong2 fu2"
  ],
  "图书馆": [
    "tu2 shu1 guan3"
  ],
  "距离": [
    "ju4 li2"
  ],
  "任职": [
    "ren4 zhi2"
  ],
  "轲": [
    "Ke1",
    "ke3"
  ],
  "号码": [
    "hao4 ma3"
  ],
  "从小": [
    "cong2 xiao3"
  ],
  "搅": [
    "jiao3"
  ],
  "鲵": [
    "ni2"
  ],
  "粢": [
    "zi1"
  ],
  "就座": [
    "jiu4 zuo4"
  ],
  "秾": [
    "nong2"
  ],
  "军人": [
    "jun1 ren2"
  ],
  "眼界": [
    "yan3 jie4"
  ],
  "粗": [
    "cu1",
    "cu1",
    "cu1"
  ],
  "黯": [
    "an4"
  ],
  "不可思议": [
    "bu4 ke3 si1 yi4"
  ],
  "首相": [
    "shou3 xiang4"
  ],
  "招牌": [
    "zhao1 pai5"
  ],
  "敕": [
    "chi4",
    "chi4",
    "chi4"
  ],
  "徽": [
    "hui1",
    "hui1"
  ],
  "水库": [
    "shui3 ku4"
  ],
  "参加": [
    "can1 jia1"
  ],
  "鼋": [
    "yuan2"
  ],
  "熟": [
    "shu2"
  ],
  "沥": [
    "li4"
  ],
  "眢": [
    "yuan1"
  ],
  "可爱": [
    "ke3 ai4"
  ],
  "定位": [
    "ding4 wei4"
  ],
  "纵观": [
    "zong4 guan1"
  ],
  "罪": [
    "zui4",
    "zui4"
  ],
  "上衣": [
    "shang4 yi1"
  ],
  "年级": [
    "nian2 ji2"
  ],
  "讹诈": [
    "e2 zha4"
  ],
  "地位": [
    "di4 wei4"
  ],
  "骄": [
    "jiao1"
  ],
  "檠": [
    "qing2"
  ],
  "上游": [
    "shang4 you2"
  ],
  "洫": [
    "xu4"
  ],
  "司空见惯": [
    "si1 kong1 jian4 guan4"
  ],
  "笋": [
    "sun3"
  ],
  "依照": [
    "yi1 zhao4"
  ],
  "调度": [
    "diao4 du4"
  ],
  "字迹": [
    "zi4 ji4"
  ],
  "水落石出": [
    "shui3 luo4 shi2 chu1"
  ],
  "摇摆": [
    "yao2 bai3"
  ],
  "只要": [
    "zhi3 yao4"
  ],
  "举报": [
    "ju3 bao4"
  ],
  "一同": [
    "yi1 tong2"
  ],
  "全世界": [
    "quan2 shi4 jie4"
  ],
  "房租": [
    "fang2 zu1"
  ],
  "舔": [
    "tian3"
  ],
  "官吏": [
    "guan1 li4"
  ],
  "过错": [
    "guo4 cuo4"
  ],
  "通宵": [
    "tong1 xiao1"
  ],
  "鹤": [
    "he4"
  ],
  "饰": [
    "shi4"
  ],
  "号称": [
    "hao4 cheng1"
  ],
  "蹅": [
    "cha3"
  ],
  "结局": [
    "jie2 ju2"
  ],
  "角落": [
    "jiao3 luo4"
  ],
  "茄子": [
    "qie2 zi5"
  ],
  "台阶": [
    "tai2 jie1"
  ],
  "酒鬼": [
    "jiu3 gui3"
  ],
  "摅": [
    "shu1"
  ],
  "危": [
    "Wei1",
    "wei1"
  ],
  "竞相": [
    "jing4 xiang1"
  ],
  "蕙": [
    "hui4"
  ],
  "蜗": [
    "wo1"
  ],
  "鞬": [
    "jian1"
  ],
  "赚钱": [
    "zhuan4 qian2"
  ],
  "狸": [
    "li2",
    "li2"
  ],
  "公开": [
    "gong1 kai1"
  ],
  "农业": [
    "nong2 ye4"
  ],
  "鼓": [
    "gu3",
    "gu3"
  ],
  "予": [
    "yu2",
    "yu3"
  ],
  "呼": [
    "hu1",
    "hu1"
  ],
  "金牌": [
    "jin1 pai2"
  ],
  "疳": [
    "gan1"
  ],
  "创造": [
    "chuang4 zao4"
  ],
  "乒乓球": [
    "ping1 pang1 qiu2"
  ],
  "人行道": [
    "ren2 xing2 dao4"
  ],
  "逞": [
    "cheng3"
  ],
  "寤": [
    "wu4"
  ],
  "平常": [
    "ping2 chang2"
  ],
  "鲜艳": [
    "xian1 yan4"
  ],
  "裉": [
    "ken4"
  ],
  "口罩": [
    "kou3 zhao4"
  ],
  "挺好": [
    "ting3 hao3"
  ],
  "平等": [
    "ping2 deng3"
  ],
  "大赛": [
    "da4 sai4"
  ],
  "自责": [
    "zi4 ze2"
  ],
  "董": [
    "Dong3",
    "dong3"
  ],
  "即": [
    "ji2"
  ],
  "自尊": [
    "zi4 zun1"
  ],
  "无穷": [
    "wu2 qiong2"
  ],
  "基本": [
    "ji1 ben3"
  ],
  "砬": [
    "la2"
  ],
  "酋": [
    "qiu2"
  ],
  "修订": [
    "xiu1 ding4"
  ],
  "厘米": [
    "li2 mi3"
  ],
  "好转": [
    "hao3 zhuan3"
  ],
  "防范": [
    "fang2 fan4"
  ],
  "变幻莫测": [
    "bian4 huan4 mo4 ce4"
  ],
  "奉": [
    "feng4"
  ],
  "戽": [
    "hu4"
  ],
  "监控": [
    "jian1 kong4"
  ],
  "蹩": [
    "bie2"
  ],
  "遵照": [
    "zun1 zhao4"
  ],
  "供给": [
    "gong1 ji3"
  ],
  "差异": [
    "cha1 yi4"
  ],
  "给予": [
    "ji3 yu3"
  ],
  "扮演": [
    "ban4 yan3"
  ],
  "农村": [
    "nong2 cun1"
  ],
  "旋律": [
    "xuan2 lu:4"
  ],
  "画龙点睛": [
    "hua4 long2 dian3 jing1"
  ],
  "泵": [
    "beng4"
  ],
  "夸耀": [
    "kua1 yao4"
  ],
  "骇": [
    "hai4"
  ],
  "锔": [
    "ju1",
    "ju2"
  ],
  "黑夜": [
    "hei1 ye4"
  ],
  "珍惜": [
    "zhen1 xi1"
  ],
  "夸": [
    "kua1",
    "kua1"
  ],
  "肯": [
    "ken3",
    "ken3"
  ],
  "秒": [
    "miao3"
  ],
  "收听": [
    "shou1 ting1"
  ],
  "看来": [
    "kan4 lai5"
  ],
  "砆": [
    "fu1"
  ],
  "暗地里": [
    "an4 di4 li5"
  ],
  "克隆": [
    "ke4 long2"
  ],
  "中学生": [
    "zhong1 xue2 sheng1"
  ],
  "景点": [
    "jing3 dian3"
  ],
  "传说": [
    "chuan2 shuo1"
  ],
  "轹": [
    "li4"
  ],
  "高档": [
    "gao1 dang4"
  ],
  "免": [
    "mian3",
    "wen4"
  ],
  "津贴": [
    "jin1 tie1"
  ],
  "播出": [
    "bo1 chu1"
  ],
  "照耀": [
    "zhao4 yao4"
  ],
  "润": [
    "run4"
  ],
  "认错": [
    "ren4 cuo4"
  ],
  "旞": [
    null
  ],
  "献血": [
    "xian4 xue4"
  ],
  "凳": [
    "deng4",
    "deng4"
  ],
  "拖延": [
    "tuo1 yan2"
  ],
  "访": [
    "fang3"
  ],
  "近来": [
    "jin4 lai2"
  ],
  "鸺": [
    "xiu1"
  ],
  "榕": [
    "Rong2",
    "rong2"
  ],
  "美好": [
    "mei3 hao3"
  ],
  "悚": [
    "song3"
  ],
  "圣": [
    "ku1",
    "sheng4",
    "sheng4"
  ],
  "苧": [
    "ning2"
  ],
  "门当户对": [
    "men2 dang1 hu4 dui4"
  ],
  "就地": [
    "jiu4 di4"
  ],
  "碰钉子": [
    "peng4 ding1 zi5"
  ],
  "拥抱": [
    "yong1 bao4"
  ],
  "缄": [
    "jian1",
    "jian1"
  ],
  "光临": [
    "guang1 lin2"
  ],
  "鳁": [
    "wen1"
  ],
  "复印": [
    "fu4 yin4"
  ],
  "均衡": [
    "jun1 heng2"
  ],
  "烦闷": [
    "fan2 men4"
  ],
  "功力": [
    "gong1 li4"
  ],
  "孛": [
    "bei4"
  ],
  "颈部": [
    "jing3 bu4"
  ],
  "最": [
    "zui4",
    "zui4",
    "zui4"
  ],
  "簕": [
    "le4"
  ],
  "瑃": [
    "chun1"
  ],
  "缢": [
    "yi4"
  ],
  "请假": [
    "qing3 jia4"
  ],
  "毫不犹豫": [
    "hao2 bu4 you2 yu4"
  ],
  "决策": [
    "jue2 ce4"
  ],
  "害怕": [
    "hai4 pa4"
  ],
  "无可厚非": [
    "wu2 ke3 hou4 fei1"
  ],
  "厂长": [
    "chang3 zhang3"
  ],
  "乳": [
    "ru3"
  ],
  "好吃": [
    "hao3 chi1",
    "hao4 chi1"
  ],
  "继而": [
    "ji4 er2"
  ],
  "名胜": [
    "ming2 sheng4"
  ],
  "俚": [
    "Li3",
    "li3"
  ],
  "个体": [
    "ge4 ti3"
  ],
  "菥": [
    "xi1"
  ],
  "镞": [
    "zu2"
  ],
  "些": [
    "xie1"
  ],
  "玓": [
    "di4"
  ],
  "献": [
    "xian4"
  ],
  "垚": [
    "yao2"
  ],
  "雍": [
    "Yong1",
    "yong1",
    "yong1"
  ],
  "瘅": [
    "dan1",
    "dan4"
  ],
  "檄": [
    "xi2"
  ],
  "拥有": [
    "yong1 you3"
  ],
  "薨": [
    "hong1"
  ],
  "棓": [
    null
  ],
  "者": [
    "zhe3"
  ],
  "研": [
    "yan2"
  ],
  "慢车": [
    "man4 che1"
  ],
  "间谍": [
    "jian4 die2"
  ],
  "挂": [
    "gua4"
  ],
  "开幕": [
    "kai1 mu4"
  ],
  "独": [
    "du2"
  ],
  "浓厚": [
    "nong2 hou4"
  ],
  "就读": [
    "jiu4 du2"
  ],
  "拮": [
    "jie2"
  ],
  "隩": [
    "yu4"
  ],
  "稹": [
    "zhen3"
  ],
  "骠": [
    "piao4"
  ],
  "趁机": [
    "chen4 ji1"
  ],
  "幄": [
    "wo4"
  ],
  "麽": [
    "mo2"
  ],
  "市": [
    "shi4"
  ],
  "垛": [
    "duo3",
    "duo4",
    "duo3"
  ],
  "玕": [
    "gan1"
  ],
  "慈": [
    "ci2"
  ],
  "陪同": [
    "pei2 tong2"
  ],
  "美人": [
    "mei3 ren2"
  ],
  "瀛": [
    "ying2"
  ],
  "此后": [
    "ci3 hou4"
  ],
  "彩票": [
    "cai3 piao4"
  ],
  "路过": [
    "lu4 guo4"
  ],
  "舒": [
    "Shu1",
    "shu1"
  ],
  "借用": [
    "jie4 yong4"
  ],
  "第一线": [
    "di4 yi1 xian4"
  ],
  "治疗": [
    "zhi4 liao2"
  ],
  "坪": [
    "ping2"
  ],
  "翘": [
    "qiao2",
    "qiao4"
  ],
  "手段": [
    "shou3 duan4"
  ],
  "昔日": [
    "xi1 ri4"
  ],
  "木材": [
    "mu4 cai2"
  ],
  "摒": [
    "bing4"
  ],
  "抽象": [
    "chou1 xiang4"
  ],
  "替换": [
    "ti4 huan4"
  ],
  "卸": [
    "xie4"
  ],
  "釉": [
    "you4"
  ],
  "宣": [
    "Xuan1",
    "xuan1"
  ],
  "游泳": [
    "you2 yong3"
  ],
  "冷落": [
    "leng3 luo4"
  ],
  "姿态": [
    "zi1 tai4"
  ],
  "说实话": [
    "shuo1 shi2 hua4"
  ],
  "品质": [
    "pin3 zhi4"
  ],
  "弶": [
    "jiang4"
  ],
  "小时候": [
    "xiao3 shi2 hou5"
  ],
  "风趣": [
    "feng1 qu4"
  ],
  "输血": [
    "shu1 xue4"
  ],
  "海湾": [
    "Hai3 wan1",
    "hai3 wan1"
  ],
  "桴": [
    "fu2"
  ],
  "燊": [
    "shen1"
  ],
  "早上": [
    "zao3 shang5"
  ],
  "早年": [
    "zao3 nian2"
  ],
  "鲟": [
    "xun2"
  ],
  "结婚": [
    "jie2 hun1"
  ],
  "哺育": [
    "bu3 yu4"
  ],
  "场景": [
    "chang3 jing3"
  ],
  "题材": [
    "ti2 cai2"
  ],
  "高低": [
    "gao1 di1"
  ],
  "救助": [
    "jiu4 zhu4"
  ],
  "进化": [
    "jin4 hua4"
  ],
  "中间": [
    "zhong1 jian1"
  ],
  "不服": [
    "bu4 fu2"
  ],
  "饭馆": [
    "fan4 guan3"
  ],
  "肄": [
    "yi4"
  ],
  "用心": [
    "yong4 xin1"
  ],
  "愈演愈烈": [
    "yu4 yan3 yu4 lie4"
  ],
  "墼": [
    "ji1"
  ],
  "同志": [
    "tong2 zhi4"
  ],
  "魑": [
    "chi1"
  ],
  "蝣": [
    "you2"
  ],
  "筻": [
    null
  ],
  "倒霉": [
    "dao3 mei2"
  ],
  "劳动力": [
    "lao2 dong4 li4"
  ],
  "远": [
    "yuan3",
    "yuan4"
  ],
  "鄹": [
    "Zou1"
  ],
  "挞": [
    "ta4"
  ],
  "幞": [
    "fu2"
  ],
  "医学": [
    "yi1 xue2"
  ],
  "绐": [
    "dai4"
  ],
  "匐": [
    "fu2"
  ],
  "螂": [
    "lang2",
    "lang2"
  ],
  "地板": [
    "di4 ban3"
  ],
  "放纵": [
    "fang4 zong4"
  ],
  "解放": [
    "jie3 fang4"
  ],
  "歌剧": [
    "ge1 ju4"
  ],
  "舆论": [
    "yu2 lun4"
  ],
  "款式": [
    "kuan3 shi4",
    "kuan3 shi5"
  ],
  "岷": [
    "min2"
  ],
  "恿": [
    "yong3",
    "yong3",
    "yong3"
  ],
  "体现": [
    "ti3 xian4"
  ],
  "澳": [
    "Ao4",
    "ao4"
  ],
  "漼": [
    "cui3"
  ],
  "裕": [
    "yu4"
  ],
  "榅": [
    "wen1"
  ],
  "报废": [
    "bao4 fei4"
  ],
  "棤": [
    "cuo4"
  ],
  "基因": [
    "ji1 yin1"
  ],
  "沿": [
    "yan2"
  ],
  "硝": [
    "xiao1"
  ],
  "耽搁": [
    "dan1 ge5"
  ],
  "短信": [
    "duan3 xin4"
  ],
  "屡次": [
    "lu:3 ci4"
  ],
  "女性": [
    "nu:3 xing4"
  ],
  "专卖店": [
    "zhuan1 mai4 dian4"
  ],
  "总之": [
    "zong3 zhi1"
  ],
  "簏": [
    "lu4"
  ],
  "遇": [
    "Yu4",
    "yu4"
  ],
  "算账": [
    "suan4 zhang4"
  ],
  "复兴": [
    "Fu4 xing1",
    "fu4 xing1"
  ],
  "参": [
    "can1",
    "can1",
    "cen1",
    "shen1",
    "can1",
    "shen1",
    "shen1"
  ],
  "移": [
    "yi2"
  ],
  "陵": [
    "ling2"
  ],
  "穸": [
    "xi1"
  ],
  "钩子": [
    "gou1 zi5"
  ],
  "辰": [
    "chen2"
  ],
  "昶": [
    "chang3"
  ],
  "宿舍": [
    "su4 she4"
  ],
  "竞选": [
    "jing4 xuan3"
  ],
  "取款": [
    "qu3 kuan3"
  ],
  "沱": [
    "tuo2"
  ],
  "破除": [
    "po4 chu2"
  ],
  "都市": [
    "du1 shi4"
  ],
  "环球": [
    "huan2 qiu2"
  ],
  "蹑": [
    "nie4"
  ],
  "共有": [
    "gong4 you3"
  ],
  "私": [
    "si1"
  ],
  "潮": [
    "chao2"
  ],
  "构思": [
    "gou4 si1"
  ],
  "槎": [
    "cha2"
  ],
  "附": [
    "fu4",
    "fu4"
  ],
  "施加": [
    "shi1 jia1"
  ],
  "友谊": [
    "you3 yi4"
  ],
  "凯": [
    "Kai3",
    "kai3"
  ],
  "厄运": [
    "e4 yun4"
  ],
  "什": [
    "shen2",
    "shi2"
  ],
  "个性": [
    "ge4 xing4"
  ],
  "岞": [
    "Zuo4"
  ],
  "辞典": [
    "ci2 dian3"
  ],
  "谙": [
    "an1"
  ],
  "包袱": [
    "bao1 fu5"
  ],
  "拽": [
    "ye4",
    "zhuai1",
    "zhuai3",
    "zhuai4"
  ],
  "用意": [
    "yong4 yi4"
  ],
  "寻": [
    "xun2"
  ],
  "没收": [
    "mo4 shou1"
  ],
  "目前": [
    "mu4 qian2"
  ],
  "彖": [
    "tuan4"
  ],
  "摇滚": [
    "yao2 gun3"
  ],
  "人品": [
    "ren2 pin3"
  ],
  "嗜好": [
    "shi4 hao4"
  ],
  "腹部": [
    "fu4 bu4"
  ],
  "顼": [
    "Xu1",
    "xu1"
  ],
  "绿": [
    "lu:4"
  ],
  "产品": [
    "chan3 pin3"
  ],
  "崆": [
    "kong1"
  ],
  "温泉": [
    "Wen1 quan2",
    "wen1 quan2"
  ],
  "嶝": [
    "deng4"
  ],
  "犒": [
    "kao4"
  ],
  "星期": [
    "xing1 qi1"
  ],
  "慬": [
    "qin2"
  ],
  "证明": [
    "zheng4 ming2"
  ],
  "学艺": [
    "xue2 yi4"
  ],
  "信用卡": [
    "xin4 yong4 ka3"
  ],
  "特点": [
    "te4 dian3"
  ],
  "钱": [
    "Qian2",
    "qian2"
  ],
  "稳妥": [
    "wen3 tuo3"
  ],
  "光线": [
    "guang1 xian4"
  ],
  "为止": [
    "wei2 zhi3"
  ],
  "茶道": [
    "cha2 dao4"
  ],
  "航": [
    "hang2"
  ],
  "缳": [
    "huan2"
  ],
  "展望": [
    "zhan3 wang4"
  ],
  "杰出": [
    "jie2 chu1"
  ],
  "吸收": [
    "xi1 shou1"
  ],
  "能量": [
    "neng2 liang4"
  ],
  "脓": [
    "nong2"
  ],
  "司机": [
    "si1 ji1"
  ],
  "艏": [
    "shou3"
  ],
  "权益": [
    "quan2 yi4"
  ],
  "响起": [
    "xiang3 qi3"
  ],
  "时事": [
    "shi2 shi4"
  ],
  "贳": [
    "shi4"
  ],
  "或者": [
    "huo4 zhe3"
  ],
  "哑": [
    "ya1",
    "ya3"
  ],
  "觉悟": [
    "jue2 wu4"
  ],
  "嘲笑": [
    "chao2 xiao4"
  ],
  "攀": [
    "pan1"
  ],
  "袷": [
    "jia2",
    "qia1"
  ],
  "爆竹": [
    "bao4 zhu2"
  ],
  "篇幅": [
    "pian1 fu5"
  ],
  "拜访": [
    "bai4 fang3"
  ],
  "抄": [
    "chao1"
  ],
  "挣": [
    "zheng1",
    "zheng4"
  ],
  "自言自语": [
    "zi4 yan2 zi4 yu3"
  ],
  "馍": [
    "mo2",
    "mo2"
  ],
  "比比皆是": [
    "bi3 bi3 jie1 shi4"
  ],
  "脚印": [
    "jiao3 yin4"
  ],
  "简直": [
    "jian3 zhi2"
  ],
  "楼房": [
    "lou2 fang2"
  ],
  "薰": [
    "xun1"
  ],
  "只是": [
    "zhi3 shi4"
  ],
  "撤": [
    "che4"
  ],
  "算是": [
    "suan4 shi4"
  ],
  "膳": [
    "shan4",
    "shan4"
  ],
  "牙刷": [
    "ya2 shua1"
  ],
  "打通": [
    "da3 tong1"
  ],
  "楗": [
    "jian4"
  ],
  "盲": [
    "mang2"
  ],
  "荼": [
    "tu2"
  ],
  "稀": [
    "xi1"
  ],
  "刁难": [
    "diao1 nan4"
  ],
  "妄想": [
    "wang4 xiang3"
  ],
  "看得见": [
    "kan4 de2 jian4"
  ],
  "欣喜": [
    "xin1 xi3"
  ],
  "美金": [
    "Mei3 jin1"
  ],
  "道路": [
    "dao4 lu4"
  ],
  "宬": [
    "cheng2"
  ],
  "异": [
    "yi4"
  ],
  "堡垒": [
    "bao3 lei3"
  ],
  "像": [
    "xiang4"
  ],
  "铷": [
    "ru2"
  ],
  "涮": [
    "shuan4"
  ],
  "岙": [
    "ao4"
  ],
  "缛": [
    "ru4"
  ],
  "㻬": [
    "tu2"
  ],
  "铯": [
    "se4"
  ],
  "外边": [
    "wai4 bian5"
  ],
  "手臂": [
    "shou3 bi4"
  ],
  "拐": [
    "guai3",
    "guai3",
    "guai3"
  ],
  "胝": [
    "zhi1"
  ],
  "喽": [
    "lou2",
    "lou5"
  ],
  "抑郁": [
    "yi4 yu4"
  ],
  "临": [
    "lin2"
  ],
  "谓": [
    "Wei4",
    "wei4"
  ],
  "丰满": [
    "Feng1 man3",
    "feng1 man3"
  ],
  "觉得": [
    "jue2 de5"
  ],
  "救济": [
    "jiu4 ji4"
  ],
  "表述": [
    "biao3 shu4"
  ],
  "牥": [
    null
  ],
  "凤": [
    "Feng4",
    "feng4"
  ],
  "胬": [
    "nu3"
  ],
  "郭": [
    "Guo1",
    "guo1"
  ],
  "增进": [
    "zeng1 jin4"
  ],
  "灌": [
    "guan4"
  ],
  "填写": [
    "tian2 xie3"
  ],
  "房屋": [
    "fang2 wu1"
  ],
  "绿化": [
    "lu:4 hua4"
  ],
  "仍旧": [
    "reng2 jiu4"
  ],
  "忌口": [
    "ji4 kou3"
  ],
  "喇": [
    "la1",
    "la3"
  ],
  "快点儿": [
    "kuai4 dian3 r5"
  ],
  "咒": [
    "zhou4",
    "zhou4"
  ],
  "翱": [
    "ao2",
    "ao2"
  ],
  "节目": [
    "jie2 mu4"
  ],
  "表白": [
    "biao3 bai2"
  ],
  "此事": [
    "ci3 shi4"
  ],
  "笠": [
    "li4"
  ],
  "甚至": [
    "shen4 zhi4"
  ],
  "臧": [
    "Zang1",
    "zang1",
    "zang4"
  ],
  "开水": [
    "kai1 shui3"
  ],
  "高额": [
    "gao1 e2"
  ],
  "帽": [
    "mao4",
    "mao4"
  ],
  "吵架": [
    "chao3 jia4"
  ],
  "咽喉": [
    "yan1 hou2"
  ],
  "温室": [
    "wen1 shi4"
  ],
  "哪儿": [
    "na3 r5"
  ],
  "皮包": [
    "pi2 bao1"
  ],
  "宠": [
    "chong3"
  ],
  "盂": [
    "yu2"
  ],
  "突破": [
    "tu1 po4"
  ],
  "废墟": [
    "fei4 xu1"
  ],
  "右边": [
    "you4 bian5"
  ],
  "桫": [
    "suo1"
  ],
  "尬": [
    "ga4"
  ],
  "绷带": [
    "beng1 dai4"
  ],
  "心情": [
    "xin1 qing2"
  ],
  "嬗": [
    "shan4"
  ],
  "久违": [
    "jiu3 wei2"
  ],
  "大大咧咧": [
    "da4 da4 lie1 lie1"
  ],
  "孤陋寡闻": [
    "gu1 lou4 gua3 wen2"
  ],
  "搔": [
    "sao1"
  ],
  "瘗": [
    "yi4"
  ],
  "械": [
    "xie4"
  ],
  "刹": [
    "cha4",
    "sha1"
  ],
  "邡": [
    "Fang1"
  ],
  "本土": [
    "ben3 tu3"
  ],
  "就": [
    "jiu4"
  ],
  "宾馆": [
    "bin1 guan3"
  ],
  "差不多": [
    "cha4 bu5 duo1"
  ],
  "正规": [
    "zheng4 gui1"
  ],
  "无非": [
    "wu2 fei1"
  ],
  "剐": [
    "gua3"
  ],
  "进程": [
    "jin4 cheng2"
  ],
  "个": [
    "ge3",
    "ge4",
    "ge4"
  ],
  "爹": [
    "die1"
  ],
  "瘘": [
    "lou4"
  ],
  "纲": [
    "gang1"
  ],
  "但愿": [
    "dan4 yuan4"
  ],
  "糌": [
    "zan1"
  ],
  "宽厚": [
    "kuan1 hou4"
  ],
  "否决": [
    "fou3 jue2"
  ],
  "催眠": [
    "cui1 mian2"
  ],
  "珧": [
    "yao2"
  ],
  "蠃": [
    "luo3"
  ],
  "一路顺风": [
    "yi1 lu4 shun4 feng1"
  ],
  "鳉": [
    null
  ],
  "前期": [
    "qian2 qi1"
  ],
  "抑制": [
    "yi4 zhi4"
  ],
  "憧": [
    "chong1"
  ],
  "详细": [
    "xiang2 xi4"
  ],
  "莲子": [
    "lian2 zi3"
  ],
  "不耐烦": [
    "bu4 nai4 fan2"
  ],
  "压迫": [
    "ya1 po4"
  ],
  "文物": [
    "wen2 wu4"
  ],
  "不便": [
    "bu4 bian4"
  ],
  "符合": [
    "fu2 he2"
  ],
  "结晶": [
    "jie2 jing1"
  ],
  "秦": [
    "Qin2"
  ],
  "侨": [
    "qiao2"
  ],
  "权": [
    "Quan2",
    "quan2"
  ],
  "简短": [
    "jian3 duan3"
  ],
  "廓": [
    "kuo4"
  ],
  "瓣": [
    "ban4"
  ],
  "变革": [
    "bian4 ge2"
  ],
  "连绵": [
    "lian2 mian2"
  ],
  "冷气": [
    "leng3 qi4"
  ],
  "塘": [
    "tang2"
  ],
  "扣": [
    "kou4",
    "kou4"
  ],
  "遗嘱": [
    "yi2 zhu3"
  ],
  "想念": [
    "xiang3 nian4"
  ],
  "体会": [
    "ti3 hui4"
  ],
  "精美": [
    "jing1 mei3"
  ],
  "庱": [
    "Cheng3"
  ],
  "荏": [
    "ren3"
  ],
  "不值": [
    "bu4 zhi2"
  ],
  "滥": [
    "lan4"
  ],
  "间断": [
    "jian4 duan4"
  ],
  "质问": [
    "zhi4 wen4"
  ],
  "发泄": [
    "fa1 xie4"
  ],
  "锓": [
    "qin2"
  ],
  "新人": [
    "xin1 ren2"
  ],
  "矼": [
    "jiang1"
  ],
  "铁路": [
    "tie3 lu4"
  ],
  "川流不息": [
    "chuan1 liu2 bu4 xi1"
  ],
  "垂头丧气": [
    "chui2 tou2 sang4 qi4"
  ],
  "惊人": [
    "jing1 ren2"
  ],
  "诒": [
    "yi2"
  ],
  "防火墙": [
    "fang2 huo3 qiang2"
  ],
  "类似": [
    "lei4 si4"
  ],
  "特大": [
    "te4 da4"
  ],
  "襕": [
    "lan2"
  ],
  "郚": [
    "wu2"
  ],
  "猰": [
    null
  ],
  "毛": [
    "Mao2",
    "mao2"
  ],
  "绯闻": [
    "fei1 wen2"
  ],
  "人质": [
    "ren2 zhi4"
  ],
  "瑷": [
    "ai4"
  ],
  "工科": [
    "gong1 ke1"
  ],
  "梨": [
    "li2"
  ],
  "召": [
    "Shao4",
    "zhao4"
  ],
  "开工": [
    "kai1 gong1"
  ],
  "痞": [
    "pi3"
  ],
  "打捞": [
    "da3 lao1"
  ],
  "瘴": [
    "zhang4"
  ],
  "垵": [
    "an3"
  ],
  "古迹": [
    "gu3 ji4"
  ],
  "文明": [
    "wen2 ming2"
  ],
  "禚": [
    "zhuo2"
  ],
  "编剧": [
    "bian1 ju4"
  ],
  "向着": [
    "xiang4 zhe5"
  ],
  "惶": [
    "huang2"
  ],
  "腌": [
    "yan1",
    "yan1"
  ],
  "续": [
    "xu4"
  ],
  "争端": [
    "zheng1 duan1"
  ],
  "靺": [
    "mo4"
  ],
  "成千上万": [
    "cheng2 qian1 shang4 wan4"
  ],
  "规划": [
    "gui1 hua4"
  ],
  "扑克": [
    "pu1 ke4"
  ],
  "辣椒": [
    "la4 jiao1"
  ],
  "转折点": [
    "zhuan3 zhe2 dian3"
  ],
  "办理": [
    "ban4 li3"
  ],
  "处置": [
    "chu3 zhi4"
  ],
  "角色": [
    "jue2 se4"
  ],
  "外观": [
    "wai4 guan1"
  ],
  "心眼儿": [
    "xin1 yan3 r5"
  ],
  "丑": [
    "Chou3",
    "chou3",
    "chou3"
  ],
  "巾": [
    "jin1"
  ],
  "峡": [
    "xia2"
  ],
  "垠": [
    "yin2"
  ],
  "募": [
    "mu4"
  ],
  "悍": [
    "han4",
    "han4"
  ],
  "运送": [
    "yun4 song4"
  ],
  "叙述": [
    "xu4 shu4"
  ],
  "假装": [
    "jia3 zhuang1"
  ],
  "懵": [
    "meng3"
  ],
  "抱怨": [
    "bao4 yuan4"
  ],
  "底下": [
    "di3 xia5"
  ],
  "本钱": [
    "ben3 qian2"
  ],
  "得到": [
    "de2 dao4"
  ],
  "飞": [
    "fei1"
  ],
  "十分": [
    "shi2 fen1"
  ],
  "焦点": [
    "jiao1 dian3"
  ],
  "绑": [
    "bang3"
  ],
  "偌": [
    "ruo4"
  ],
  "纳税人": [
    "na4 shui4 ren2"
  ],
  "骚扰": [
    "sao1 rao3"
  ],
  "勔": [
    "mian3"
  ],
  "精": [
    "jing1"
  ],
  "滔滔不绝": [
    "tao1 tao1 bu4 jue2"
  ],
  "办公室": [
    "ban4 gong1 shi4"
  ],
  "拎": [
    "lin1"
  ],
  "颧": [
    "quan2"
  ],
  "腆": [
    "tian3"
  ],
  "侠义": [
    "xia2 yi4"
  ],
  "相提并论": [
    "xiang1 ti2 bing4 lun4"
  ],
  "理事": [
    "li3 shi4"
  ],
  "墀": [
    "chi2"
  ],
  "代替": [
    "dai4 ti4"
  ],
  "眼色": [
    "yan3 se4"
  ],
  "晋": [
    "Jin4",
    "jin4"
  ],
  "蛃": [
    null
  ],
  "适量": [
    "shi4 liang4"
  ],
  "双胞胎": [
    "shuang1 bao1 tai1"
  ],
  "杧": [
    "mang2"
  ],
  "妻": [
    "qi1",
    "qi4"
  ],
  "逾期": [
    "yu2 qi1"
  ],
  "旷课": [
    "kuang4 ke4"
  ],
  "那里": [
    "na4 li5",
    "na4 li5"
  ],
  "欣慰": [
    "xin1 wei4"
  ],
  "伋": [
    "ji2"
  ],
  "哏": [
    "gen2",
    "hen3"
  ],
  "消除": [
    "xiao1 chu2"
  ],
  "俸": [
    "feng4"
  ],
  "屑": [
    "xie4"
  ],
  "寻常": [
    "xun2 chang2"
  ],
  "重视": [
    "zhong4 shi4"
  ],
  "没有": [
    "mei2 you3"
  ],
  "煓": [
    "tuan1"
  ],
  "案件": [
    "an4 jian4"
  ],
  "荦": [
    "luo4"
  ],
  "淫": [
    "yin2",
    "yin2"
  ],
  "跹": [
    "xian1"
  ],
  "凑": [
    "cou4"
  ],
  "俟": [
    "qi2",
    "si4",
    "si4"
  ],
  "滋": [
    "zi1"
  ],
  "疝": [
    "shan4"
  ],
  "剩余": [
    "sheng4 yu2"
  ],
  "传染病": [
    "chuan2 ran3 bing4"
  ],
  "卉": [
    "hui4"
  ],
  "孱": [
    "can4",
    "chan2"
  ],
  "剋": [
    "kei1"
  ],
  "祃": [
    "ma4"
  ],
  "长足": [
    "chang2 zu2"
  ],
  "滑稽": [
    "hua2 ji1"
  ],
  "睡觉": [
    "shui4 jiao4"
  ],
  "生长": [
    "sheng1 zhang3"
  ],
  "血管": [
    "xue4 guan3"
  ],
  "介意": [
    "jie4 yi4"
  ],
  "规定": [
    "gui1 ding4"
  ],
  "菪": [
    "dang4"
  ],
  "拥": [
    "yong1"
  ],
  "观赏": [
    "guan1 shang3"
  ],
  "腐烂": [
    "fu3 lan4"
  ],
  "表格": [
    "biao3 ge2"
  ],
  "夺冠": [
    "duo2 guan4"
  ],
  "公费": [
    "gong1 fei4"
  ],
  "蟫": [
    null
  ],
  "推翻": [
    "tui1 fan1"
  ],
  "及早": [
    "ji2 zao3"
  ]
}