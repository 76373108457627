import React from "react"
import { useState } from "react"
import { TextStyle } from "../App"
import { Collapse } from "../Components/Collapse"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { NextUnitContainer, UpdateUnitState, ZhLanguageConfiguration, ZhSelectNextBaseUnits } from "../Config/LanguageDefinitionConfiguration"
import { UnitDefinitionMap } from "../Config/UnitDefinitionMap"
import { ZhLanguageDefinition } from "../data/ZhLanguageDefinition"
import { db, UnitState } from "../Database/db"
import { ItemHistoryElement } from "../Database/HistoryState"
import { ControllerModeContainer, ButtonSubDisplay, ColumnLayout, RowLayout, InnerTile } from "../Styles/Styles"
import { ZhLanguageUnitTypes } from "../Types/Zh/LanguageDefinition";

export const TestFlowDebugView: React.FC<{ Back: () => any }> = props => {

    enum FlowState {
        Start,
        UnitsReceived,
        UnitsCompleted,
    }

    const [flowState, setFlowState] = useState(FlowState.Start)
    const [newUnits, setNewUnits] = useState<ItemHistoryElement[]>([])
    const [reviewUnits, setReviewUnits] = useState<ItemHistoryElement[]>([])

    const [unitsReceived, setUnitsReceived] = useState<NextUnitContainer | undefined>(undefined)
    const [newCount, setNewCount] = useState(100)
    const [reviewCount, setReviewCount] = useState(30)

    const [introducedHanziCount, setIntroducedHanziCount] = useState(0)
    const [unlockedWordsCount, setUnlockedWordsCount] = useState(0)

    const [newRadicalCount, setNewRadicalCount] = useState(20)
    const [newHanziCount, setNewHanziCount] = useState(20)
    const [newWordCount, setNewWordCount] = useState(20)

    const [checkItem, setCheck] = useState("")
    const [checkItemHistory, setItemHistory] = useState<ItemHistoryElement | undefined>(undefined)

    const GetUnits = async () => {

        const itemHistory = await db.ItemHistory.toArray()

        const params = {
            Counts: {
                New: {
                    [ZhLanguageUnitTypes.Radical]: newRadicalCount,
                    [ZhLanguageUnitTypes.Hanzi]: newHanziCount,
                    [ZhLanguageUnitTypes.Word]: newWordCount,
                },
                Review: 20
            },
            TestDefinitions: Object.values(ZhLanguageConfiguration.BaseUnitTestDefinitions).map(td => td.id)
        }


        const units = await ZhSelectNextBaseUnits(UnitDefinitionMap, params)

        setUnitsReceived(units)
        setNewUnits(units.New.flat())
        setReviewUnits(units.Review.flat())

        const hanzi = itemHistory.filter(h => h.UnitTypes.includes(ZhLanguageUnitTypes.Hanzi) && h.TestDefinitionId == 0 && h.Introduced == true)
        const words = itemHistory.filter(h => h.UnitTypes.includes(ZhLanguageUnitTypes.Word) && h.TestDefinitionId == 0 && h.Unlocked == true)

        setIntroducedHanziCount(hanzi.length)
        setUnlockedWordsCount(words.length)

        setFlowState(FlowState.UnitsReceived);
    }

    const SetUnitsComplete = async () => {

        console.log(newUnits.length)
        for (const unit of newUnits) {
            unit.Introduced = true
            unit.LastSeen = new Date()
            unit.Score = 1
            await db.ItemHistory.update(unit.Id, unit)
        }

        for (const unit of reviewUnits) {
            unit.LastSeen = new Date()
            unit.Score += 1
            await db.ItemHistory.update(unit.Id, unit)
        }


        await UpdateUnitState(ZhLanguageConfiguration);

        setFlowState(FlowState.UnitsCompleted);

    }

    const CheckItemHistory = async () => {
        var itemHistory = await db.ItemHistory.where("UnitKey").equals(checkItem).first()
        setItemHistory(itemHistory)
    }



    return <>
        <ContentTile>
            <ControllerModeContainer>

                <TextStyle>Flow State: {flowState}</TextStyle>
                <TextStyle>Hanzi: {introducedHanziCount}</TextStyle>
                <TextStyle>Words: {unlockedWordsCount}</TextStyle>
                <ButtonSubDisplay>
                    <StyledButton onClick={GetUnits}><TextStyle>Get Next Units</TextStyle></StyledButton>
                    <StyledButton disabled={flowState == FlowState.UnitsCompleted} onClick={SetUnitsComplete}><TextStyle>Complete Units</TextStyle></StyledButton>
                </ButtonSubDisplay>
                <RowLayout>
                    <div>
                        <ColumnLayout>
                            <TextStyle weight="bold">Parameters</TextStyle>
                            <TextStyle weight="bold">New</TextStyle>
                            <RowLayout>
                                <TextStyle>Radical:</TextStyle>
                                <input type="number" defaultValue={newRadicalCount} onChange={(e) => { setNewRadicalCount(parseInt(e.target.value)) }} />
                            </RowLayout>
                            <RowLayout>
                                <TextStyle>Hanzi:</TextStyle>
                                <input type="number" defaultValue={newHanziCount} onChange={(e) => { setNewHanziCount(parseInt(e.target.value)) }} />
                            </RowLayout>
                            <RowLayout>
                                <TextStyle>Word:</TextStyle>
                                <input type="number" defaultValue={newWordCount} onChange={(e) => { setNewWordCount(parseInt(e.target.value)) }} />
                            </RowLayout>
                        </ColumnLayout>
                    </div>
                    <div>
                        <ColumnLayout>
                            <TextStyle weight="bold">Check Item</TextStyle>
                            <input type='text' onChange={(e) => { setCheck(e.target.value) }} />
                            <StyledButton onClick={CheckItemHistory}>Check</StyledButton>

                        </ColumnLayout>
                    </div>
                    {checkItemHistory != undefined && <>

                        <div>
                            <ColumnLayout>
                                <TextStyle>UnitId {checkItemHistory.Id}</TextStyle>
                                <TextStyle>Unlocked: {checkItemHistory.Unlocked ? "true" : "false"}</TextStyle>
                                <TextStyle>Introduced: {checkItemHistory.Introduced ? "true" : "false"}</TextStyle>
                                <TextStyle>Types: {checkItemHistory.UnitTypes.join(", ")}</TextStyle>
                                <TextStyle>Key: {checkItemHistory.UnitKey}</TextStyle>
                                {/* {checkItemHistory.UnitTypes.includes(ZhLanguageUnitTypes.Word) && <>
                                    <TextStyle>Unlock Status: {UnitDefinitionMap[ZhLanguageUnitTypes.Word].UnlockItemOn(checkItemHistory, undefined) ? "true" : "false"}</TextStyle>
                                </>} */}
                            </ColumnLayout>
                        </div>

                        <div>
                            <ColumnLayout>
                                <TextStyle>LanguageDefinitionUnit</TextStyle>
                                <TextStyle>Keys:</TextStyle>
                                <TextStyle>Radical: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Radical"]}</TextStyle>
                                <TextStyle>Hanzi: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Hanzi"]}</TextStyle>
                                <TextStyle>Word: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Word"]}</TextStyle>
                            </ColumnLayout>
                        </div>
                    </>}
                </RowLayout>
                {(flowState == FlowState.UnitsReceived || flowState == FlowState.UnitsCompleted) && <Collapse Label="Units" Collapsed={false}>
                    <Collapse Label="New">
                        <RowLayout>
                            {newUnits.map(h => {
                                return <>
                                    <InnerTile key={h.Id} Small={true}>
                                        <ColumnLayout>
                                            <TextStyle>{h.UnitKey}</TextStyle>
                                        </ColumnLayout>
                                    </InnerTile>
                                </>
                            })}
                        </RowLayout>
                    </Collapse>
                    <Collapse Label="Review">
                        <RowLayout>

                            {reviewUnits.map(h => {
                                return <>
                                    <InnerTile key={h.Id} Small={true}>
                                        <ColumnLayout>
                                            <TextStyle>{h.UnitKey}</TextStyle>
                                        </ColumnLayout>
                                    </InnerTile>
                                </>
                            })}
                        </RowLayout>

                    </Collapse>
                </Collapse>}

                <StyledButton onClick={() => { props.Back() }}><TextStyle>Back</TextStyle></StyledButton>
            </ControllerModeContainer>
        </ContentTile >
    </>
}