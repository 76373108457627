/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TextPair } from '../models/TextPair';
import { HttpFile } from '../http/http';

export class SegmentSentenceRequest {
    'textPairs': Array<TextPair>;
    'translateMissing': boolean;
    'translateMissingUnit': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "textPairs",
            "baseName": "TextPairs",
            "type": "Array<TextPair>",
            "format": ""
        },
        {
            "name": "translateMissing",
            "baseName": "TranslateMissing",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "translateMissingUnit",
            "baseName": "TranslateMissingUnit",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SegmentSentenceRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

