import { Checkbox, InputNumber } from "antd"
import { useState } from "react"
import { RoundControllerParams } from "./RoundView"
import { ParentMenuConfig, SetMenu } from "../Loaders/LanguageDefinitionLoader"
import { SettingsState } from "../Database/SettingsState"
import { Data, SetData } from "../Types/Interfaces"
import { ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { TextStyle } from "../App"
import { RowLayout } from "../Styles/Styles"
import { ZhBaseUnitTestDefinition } from "../Types/Zh/LanguageDefinition"

export const UnitOptionsView: React.FC<Data<{ LanguageId: number, Params: RoundControllerParams }> & ParentMenuConfig & SetMenu & SetData> = props => {

    const unitParams = props.Data.Params
    const [introduceNew, setIntroduceNew] = useState(unitParams.IntroduceNew)
    const [introduceCount, setIntroduceCount] = useState(unitParams.IntroduceCount)
    const [roundSize, setRoundSize] = useState(unitParams.RoundSize)
    const [promoteOnNth, setPromoteOnNth] = useState(unitParams.PromoteOnNth)
    const [failOnNth, setFailOnNth] = useState(unitParams.FailOnNth)
    const [timeLimit, setTimeLimit] = useState(unitParams.TimeLimit)
    const [ShowUntilCorrect, setShowUntilCorrect] = useState(unitParams.ShowUntilCorrect)
    const [saveResults, setSaveResults] = useState(unitParams.SaveResults)

    const [activeTestDefinitions, setActiveTestDefinitions] = useState(unitParams.TestDefinitions)

    const [valueChanged, setValueChanged] = useState(false)

    if (valueChanged) {
        var recalculatedRoundParams = {
            IntroduceNew: introduceNew,
            IntroduceCount: introduceCount,
            RoundSize: roundSize,

            TimeLimit: timeLimit,
            PromoteOnNth: promoteOnNth,
            FailOnNth: failOnNth,

            ShowUntilCorrect: ShowUntilCorrect,
            ShuffleOnIncorrect: true,

            SaveResults: saveResults,

            TestDefinitions: activeTestDefinitions,

            OrderRound: undefined //(Item: any[]) => Item
            // need to undo this mechanism 
        }

        var Menu = [{
            Label: "Save",
            OnClick: async () => {
                await (new SettingsState()).Set(props.Data.LanguageId, recalculatedRoundParams)
                props.SetData(recalculatedRoundParams)
            }
        }, ...props.ParentMenuConfig]

        props.SetMenu(Menu)
        setValueChanged(false)

    }

    return <div style={{ flexDirection: 'column', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 24 }}>
        <div>
            Introduce new items: <Checkbox checked={introduceNew} onChange={(e) => { setIntroduceNew(e.target.checked); setValueChanged(true) }}></Checkbox>
        </div>
        <div>
            New items per round: <InputNumber value={introduceCount} onChange={(e: number | null) => { if (e != null) { setIntroduceCount(e?.valueOf()); setValueChanged(true); } }} />
        </div>
        <div>
            Review items per round: <InputNumber value={roundSize} onChange={(e: number | null) => { if (e != null) { setRoundSize(e?.valueOf()); setValueChanged(true); } }} />
        </div>
        <div>
            Promote on: <InputNumber value={promoteOnNth} onChange={(e: number | null) => { if (e != null) { setPromoteOnNth(e?.valueOf()); setValueChanged(true); } }} />
        </div>
        <div>
            Fail on <InputNumber value={failOnNth} onChange={(e: number | null) => { if (e != null) { setFailOnNth(e?.valueOf()); setValueChanged(true); } }} />
        </div>
        <div>
            Time limit: <InputNumber value={timeLimit} onChange={(e: number | null) => { if (e != null) { setTimeLimit(e?.valueOf()); setValueChanged(true); } }} />
        </div>
        <div>
            Show until correct: <Checkbox checked={ShowUntilCorrect} onChange={(e) => { setShowUntilCorrect(e.target.checked); setValueChanged(true) }} />
        </div>
        {/* <div>
                        Shuffle on incorrect: <Checkbox />
                    </div> */}
        <div>
            Save round results: <Checkbox checked={saveResults} onChange={(e) => { setSaveResults(e.target.checked); setValueChanged(true) }} />
        </div>

        <TextStyle>TestDefinitions</TextStyle>
        <div>
            {Object.entries(ZhLanguageConfiguration.BaseUnitTestDefinitions).map((d) => {
                return <RowLayout>
                    <input
                        type="checkbox"
                        checked={activeTestDefinitions.includes(d[1].id)}
                        disabled={ZhLanguageConfiguration.PrimaryTestDefinitionId == d[1].id}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setActiveTestDefinitions([...activeTestDefinitions, d[1].id].sort())
                            } else {
                                setActiveTestDefinitions([...activeTestDefinitions.filter(t => t != d[1].id)])
                            }

                            setValueChanged(true)
                        }}
                    />
                    <TextStyle>{d[1].Label}</TextStyle>
                </RowLayout>
            })}

        </div>

    </div>

}