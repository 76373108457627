/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DictionaryQueryResult } from '../models/DictionaryQueryResult';
import { HttpFile } from '../http/http';

export class SegmentedText {
    'unit': string;
    'dictionaryQuery'?: DictionaryQueryResult;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "unit",
            "baseName": "Unit",
            "type": "string",
            "format": ""
        },
        {
            "name": "dictionaryQuery",
            "baseName": "DictionaryQuery",
            "type": "DictionaryQueryResult",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SegmentedText.attributeTypeMap;
    }

    public constructor() {
    }
}

