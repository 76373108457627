import { useQuery } from "@tanstack/react-query"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { Modal } from "../Components/Modal"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { DictionaryQueryTile } from "../Displays/ProcessedTextDisplay"
import { ColumnLayout, ControllerModeContainer, InnerTile } from "../Styles/Styles"
import { TextHelperModal } from "./TextHelperView"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { Query } from "../api"

const StyledInput = styled.input`
height: 64px ;
font-size: 2rem;
padding-left: 24px;
`

export const DictionaryModal: React.FC<{ Query: string, OnClose: () => any }> = props => {
    return <>
        <Modal OnClose={props.OnClose}>
            <LoadDictionaryQuery query={props.Query} />
        </Modal>
    </>
}

export const LoadDictionaryQuery: React.FC<{ query: string }> = props => {
    const api = useContext(ApiContext)
    const topRef = React.createRef<HTMLDivElement>();

    const { isPending, error, data } = useQuery({
        queryKey: [`query#${props.query}`],
        queryFn: () => {
            const lookupQuery = new Query()
            lookupQuery.query = props.query

            return api.Lookup(lookupQuery).then(UnwrapApiCall)

        }
    })

    useEffect(() => {
        // 👇️ Scroll to the bottom every time messages change
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [data]);


    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: {}</>



    const response = data!!;

    return <>
        <ContentTile ref={topRef}>
            <ColumnLayout>
                <TextStyle size={3}>{response.query}</TextStyle>
                <ColumnLayout>
                    {response.results == undefined || response.results.length == 0 && <>
                        <TextStyle>No results</TextStyle>
                        <TargetDefnWrapper HideText={true} key={0} text={response.query} />
                    </>}
                    <TextStyle>{response.results?.length} results</TextStyle>
                </ColumnLayout>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                    {
                        response.results?.map(r => {
                            return <>
                                <InnerTile style={{ padding: 12, justifyContent: 'center' }}>
                                    <ColumnLayout>
                                        <TextStyle>{r.reading}</TextStyle>
                                        {r.definition.map(d => {
                                            return <>
                                                <TextStyle>{d.primaryLangDefn}</TextStyle>
                                                {d.targetLangDefn != undefined && <TargetDefnWrapper key={0} text={d.targetLangDefn} />}
                                            </>
                                        })}
                                    </ColumnLayout>
                                </InnerTile>
                            </>
                        })
                    }
                </div>
            </ColumnLayout>
        </ContentTile>
    </>

}

export const DictionaryView: React.FC<{ query?: string }> = props => {

    const [query, setQuery] = useState(props.query ? props.query : "")

    const [search, setSearch] = useState(false)

    const Search = async () => {
        // console.log(query)

        if (query == "" || query == undefined) {
            setSearch(false);
            return;

        } else {
            setSearch(true);
        }

    }


    return <>
        <ContentTile>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                <StyledInput style={{ width: 'calc(100% - 24px)' }} onChange={(e) => { setQuery(e.target.value) }} type="text"></StyledInput>
                <StyledButton onClick={Search}>Search</StyledButton>
            </div>
        </ContentTile>
        {search && <LoadDictionaryQuery query={query} />}

    </>
}

export const TargetDefnWrapper: React.FC<{ HideText?: boolean, text: string }> = props => {
    const [showTextHelper, setShowTextHelper] = useState(false)

    return <>
        {(props.HideText == undefined || !props.HideText) && <TextStyle>{props.text}</TextStyle>}
        <StyledButton onClick={() => setShowTextHelper(true)}>Assist</StyledButton>
        {showTextHelper && <TextHelperModal Text={props.text} OnClose={() => setShowTextHelper(false)} />}
    </>
}