import { ZhBaseUnitType } from "../Components/TestFolds/ZhBaseUnit"
import { ZhLanguageDefinition } from "../data/ZhLanguageDefinition"
import { HistoryState, ItemHistoryElement } from "../Database/HistoryState"
import { DisplayItemAboveFoldProps, DisplayItemBelowFoldProps } from "../Types/DisplayItem"
import { LanguageUnitDefinition } from "../Types/LanguageDefinition"
import { FilterFromLanguageDefinition, ZhBaseUnitTestDefinition, ZhHanziFromLanguageUnitDefinition, ZhHanziUnitItem, ZhLanguageUnitTypes, ZhRadicalFromLanguageUnitDefinition, ZhRadicalUnitItem, ZhWordiFromLanguageUnitDefinition, ZhWordUnitItem } from "../Types/Zh/LanguageDefinition";


enum ZhCompositeUnitTestDefinition {
    TargetToPrimaryMeaning,
    SpeakTest,
    WordCompletion
}

interface TestDefinition<Unit> {
    id: number

    Label: string
    Reintroduce: boolean

    AboveFold: React.FC<DisplayItemAboveFoldProps<Unit>>
    BelowFold: React.FC<DisplayItemBelowFoldProps<Unit>>

    UserIndicatesIfCorrect: boolean

}

export type TestDefinitionMapType<T> = { [testDefinition: number]: TestDefinition<T> }

export interface SubUnitDefinition<UnitItem> {
    Id: number
    Category: string

    Label: string

    GetItems: (item: UnitItem) => boolean
}

interface OrderUnitDefinition<UnitItem> {
    Id: number
    Label: string

    Order: (i1: UnitItem, i2: UnitItem) => number
}

export interface UnitDefinition<UnitItem> {
    UnitType: number
    UnitId: number
    NameLabel: string
    DisplayLabel: string

    LanguageUnitType: number
    Items: UnitItem[]
    GetItemId: (item: UnitItem) => number
    GetItemDisplay: (item: UnitItem) => string
    HistoryElementToUnit: (Id: string, Unit: LanguageUnitDefinition<number>) => UnitItem

    UnitLocked: boolean
    UnlockUnitOn: () => boolean | Promise<boolean>
    ItemsLocked: boolean
    UnlockItemOn: (item: UnitItem, UnitHistoryCache: { [key: string]: ItemHistoryElement }) => boolean | Promise<boolean>

    CanProcessText: boolean

    ParentTestDefinitionId: number

    TestDefinitions: { [n: number]: TestDefinition<any> }

    SubUnits: SubUnitDefinition<UnitItem>[]

    OrderUnit: OrderUnitDefinition<UnitItem>[]

}

export type UnitDefinitionMapType = {
    [n: number]: UnitDefinition<any>
}


export const UnitDefinitionMap: UnitDefinitionMapType = {
    [ZhLanguageUnitTypes.Radical]: {
        UnitType: ZhLanguageUnitTypes.Word,
        UnitId: 0,
        NameLabel: "Radicals",
        DisplayLabel: "部首",

        LanguageUnitType: ZhLanguageUnitTypes.Radical,
        HistoryElementToUnit: ZhRadicalFromLanguageUnitDefinition,
        Items: FilterFromLanguageDefinition(ZhLanguageDefinition, ZhLanguageUnitTypes.Radical, ZhRadicalFromLanguageUnitDefinition),
        GetItemId: (item: ZhRadicalUnitItem) => { return item.id },
        GetItemDisplay: (item: ZhRadicalUnitItem) => item.glyph,

        UnitLocked: false,
        UnlockUnitOn: () => true,
        ItemsLocked: false,
        UnlockItemOn: () => true,

        CanProcessText: false,

        ParentTestDefinitionId: ZhBaseUnitTestDefinition.GlyphToMeaning,
        ReintroduceChildTestItems: false,

        TestDefinitions: {
            // [ZhBaseUnitTestDefinition.GlyphToMeaning]: {
            //     id: ZhBaseUnitTestDefinition.GlyphToMeaning,

            //     Label: 'Radical:KangxiToMeaning',
            //     UnitType: UnitType.Radicals,
            //     Reintroduce: false,

            //     AboveFold: KangxiToMeaningAboveFold,
            //     BelowFold: KangxiToMeaningBelowFold,

            //     UserIndicatesIfCorrect: true

            // } as TestDefinition<ZhRadicalUnitItem>,
            // [ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph]: {
            //     id: ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph,

            //     Label: 'Radical:MeaningToWrittenGlyph',
            //     UnitType: UnitType.Radicals,
            //     Reintroduce: false,

            //     AboveFold: EmptyAboveFold,
            //     BelowFold: EmptyBelowFold,

            //     UserIndicatesIfCorrect: false

            // },
            // [ZhBaseUnitTestDefinition.GlyphToSpokenReading]: {
            //     id: ZhBaseUnitTestDefinition.GlyphToSpokenReading,

            //     Label: 'Radical:GlyphToSpokenReading',
            //     UnitType: UnitType.Radicals,
            //     Reintroduce: false,

            //     AboveFold: KangxiToMeaningAboveFold,
            //     BelowFold: EmptyBelowFold,

            //     UserIndicatesIfCorrect: false

            // }

        } as TestDefinitionMapType<ZhRadicalUnitItem>,

        SubUnits: [],
        OrderUnit: []
    } as UnitDefinition<ZhRadicalUnitItem>,

    [ZhLanguageUnitTypes.Hanzi]: {
        UnitType: ZhLanguageUnitTypes.Hanzi,
        UnitId: 1,
        NameLabel: "Hanzi",
        DisplayLabel: "汉字",

        LanguageUnitType: ZhLanguageUnitTypes.Hanzi,
        HistoryElementToUnit: ZhHanziFromLanguageUnitDefinition,
        Items: FilterFromLanguageDefinition(ZhLanguageDefinition, ZhLanguageUnitTypes.Hanzi, ZhHanziFromLanguageUnitDefinition),
        GetItemId: (item: ZhHanziUnitItem) => item.GeneralStandard,
        GetItemDisplay: (item: ZhHanziUnitItem) => item.Character,

        UnitLocked: true,
        UnlockUnitOn: async () => {
            return (await (new HistoryState()).IsUnitCompleted(ZhLanguageUnitTypes.Radical)) === true
        },
        ItemsLocked: false,
        UnlockItemOn: async (item, unitHistoryCache) => true,

        CanProcessText: true,

        ParentTestDefinitionId: ZhBaseUnitTestDefinition.GlyphToMeaning,

        TestDefinitions: {
            // [ZhBaseUnitTestDefinition.GlyphToMeaning]: {
            //     id: ZhBaseUnitTestDefinition.GlyphToMeaning,

            //     Label: 'Hanzi:GlyphToMeaning',
            //     UnitType: UnitType.Hanzi,
            //     Reintroduce: false,

            //     AboveFold: HanziGlyphToMeaningAboveFold,
            //     BelowFold: HanziGlyphToMeaningBelowFold,

            //     UserIndicatesIfCorrect: true
            // },
            // [ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph]: {
            //     id: ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph,

            //     Label: 'Hanzi:MeaningToWrittenGlyph',
            //     UnitType: UnitType.Hanzi,
            //     Reintroduce: false,

            //     AboveFold: HanziMeaningToWrittenGlyphAboveFold,
            //     BelowFold: EmptyBelowFold,

            //     UserIndicatesIfCorrect: false

            // },
            // [ZhBaseUnitTestDefinition.GlyphToSpokenReading]: {
            //     id: ZhBaseUnitTestDefinition.GlyphToSpokenReading,

            //     Label: 'Hanzi:GlyphToSpokenReading',
            //     UnitType: UnitType.Hanzi,
            //     Reintroduce: false,

            //     AboveFold: HanziGlyphToSpokenReadingAboveFold,
            //     BelowFold: EmptyBelowFold,

            //     UserIndicatesIfCorrect: false

            // }
        },
        SubUnits: [
            // {
            //     Id: 1,
            //     Category: "HSK",
            //     Label: "HSK1",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 1
            // },
            // {
            //     Id: 2,
            //     Category: "HSK",
            //     Label: "HSK2",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 2
            // },
            // {
            //     Id: 3,
            //     Category: "HSK",
            //     Label: "HSK3",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 3
            // },
            // {
            //     Id: 4,
            //     Category: "HSK",
            //     Label: "HSK4",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 4
            // },
            // {
            //     Id: 5,
            //     Category: "HSK",
            //     Label: "HSK5",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 5
            // },
            // {
            //     Id: 6,
            //     Category: "HSK",
            //     Label: "HSK6",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 6
            // },
            // {
            //     Id: 7,
            //     Category: "HSK",
            //     Label: "HSK7",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 7
            // },
            // {
            //     Id: 8,
            //     Category: "HSK",
            //     Label: "HSK8",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 8

            // },
            // {
            //     Id: 9,
            //     Category: "HSK",
            //     Label: "HSK9",
            //     GetItems: (item: HanziUnitItem) => item.HSK == 9
            // },
            // {
            //     Id: 10,
            //     Category: "General Standard",
            //     Label: "Common General Standard",
            //     GetItems: (item: HanziUnitItem) => item.GeneralStandard <= 6500
            // },
            // {
            //     Id: 11,
            //     Category: "General Standard",
            //     Label: "Uncommon General Standard",
            //     GetItems: (item: HanziUnitItem) => item.GeneralStandard > 6500
            // }

        ],
        OrderUnit: [
            // {
            //     Id: 0,
            //     Label: "ByGeneralStandard",
            //     Order: (i1: HanziUnitItem, i2: HanziUnitItem) => i1.GeneralStandard - i2.GeneralStandard
            // },
            // {
            //     Id: 1,
            //     Label: "ByFrequencyRank",
            //     Order: (i1: HanziUnitItem, i2: HanziUnitItem) => {
            //         if (i1.FrequencyRank != undefined && i2.FrequencyRank != undefined)
            //             return i1.FrequencyRank - i2.FrequencyRank

            //         if (i1.FrequencyRank == undefined && i2.FrequencyRank == undefined)
            //             return 0

            //         if (i1.FrequencyRank == undefined)
            //             return 1

            //         if (i2.FrequencyRank == undefined)
            //             return -1
            //     }
            // },
            // {
            //     Id: 1,
            //     Label: "ByHSK",
            //     Order: (i1: HanziUnitItem, i2: HanziUnitItem) => {
            //         if (i1.HSK != undefined && i2.HSK != undefined)
            //             return i1.HSK - i2.HSK

            //         if (i1.HSK == undefined && i2.HSK == undefined)
            //             return 0

            //         if (i1.HSK == undefined)
            //             return 1

            //         if (i2.HSK == undefined)
            //             return -1
            //     }
            // },
        ]


    } as UnitDefinition<ZhHanziUnitItem>,
    [ZhLanguageUnitTypes.Word]: {
        UnitType: ZhLanguageUnitTypes.Word,
        UnitId: 2,
        NameLabel: "CNWords",
        DisplayLabel: "词语",


        LanguageUnitType: ZhLanguageUnitTypes.Word,
        HistoryElementToUnit: ZhWordiFromLanguageUnitDefinition,
        Items: FilterFromLanguageDefinition(ZhLanguageDefinition, ZhLanguageUnitTypes.Word, ZhWordiFromLanguageUnitDefinition),
        GetItemId: (item: ZhWordUnitItem) => item.id,
        GetItemDisplay: (item: ZhWordUnitItem) => item.Unit,

        UnitLocked: true,
        UnlockUnitOn: async () => {
            return (await (new HistoryState()).IsUnitCompleted(ZhLanguageUnitTypes.Radical)) === true
        },
        ItemsLocked: true,
        UnlockItemOn: async (item: ZhWordUnitItem, unitHistoryCache: { [key: string]: ItemHistoryElement }) => {

            const all = item.Unit.split("").map(c => {
                const history = unitHistoryCache[c]

                if (history != undefined)
                    return history.Introduced == true

                return false
            }).every(r => r == true)

            return all

        },

        CanProcessText: false,

        ParentTestDefinitionId: ZhBaseUnitTestDefinition.GlyphToMeaning,

        TestDefinitions: {
            // [ZhBaseUnitTestDefinition.GlyphToMeaning]: {
            //     id: ZhBaseUnitTestDefinition.GlyphToMeaning,

            //     Label: 'CnWord:GlyphToMeaning',
            //     UnitType: UnitType.Words,
            //     Reintroduce: false,

            //     AboveFold: CnWordGlyphToMeaningAboveFold,
            //     BelowFold: CnWordGlyphToMeaningBelowFold,

            //     UserIndicatesIfCorrect: true
            // },
            // [ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph]: {
            //     id: ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph,

            //     Label: 'CnWord:MeaningToWrittenGlyph',
            //     UnitType: UnitType.Words,
            //     Reintroduce: false,

            //     AboveFold: EmptyAboveFold,
            //     BelowFold: EmptyBelowFold,

            //     UserIndicatesIfCorrect: false

            // },
            // [ZhBaseUnitTestDefinition.GlyphToSpokenReading]: {
            //     id: ZhBaseUnitTestDefinition.GlyphToSpokenReading,

            //     Label: 'CnWord:GlyphToSpokenReading',
            //     UnitType: UnitType.Words,
            //     Reintroduce: false,

            //     AboveFold: CnWordGlyphToMeaningAboveFold,
            //     BelowFold: EmptyBelowFold,

            //     UserIndicatesIfCorrect: false

            // }

        },
        SubUnits: [
        ],
        OrderUnit: []


    } as UnitDefinition<ZhWordUnitItem>,

    // [UnitType.Sentences]: {
    //     UnitType: UnitType.Sentences,
    //     UnitId: UnitType.Sentences,
    //     NameLabel: "CNSentences",
    //     DisplayLabel: "句子",


    //     Items: [],
    //     GetItemId: (item: SentencesUnitItem) => 0,
    //     GetItemDisplay: (item: SentencesUnitItem) => "",

    //     UnitLocked: true,
    //     UnlockUnitOn: async () => {
    //         return (await (new HistoryState()).IsUnitCompleted(UnitType.Radicals)) === true
    //     },
    //     ItemsLocked: true,
    //     UnlockItemOn: () => false,

    //     CanProcessText: true,

    //     ParentTestDefinitionId: SentencesTestDefinition.TargetToPrimaryMeaning,

    //     TestDefinitions: {
    //         [SentencesTestDefinition.TargetToPrimaryMeaning]: {
    //             id: SentencesTestDefinition.TargetToPrimaryMeaning,

    //             Label: 'CnSentences:TargetToPrimaryMeaning',
    //             UnitType: UnitType.Sentences,
    //             Reintroduce: false,

    //             AboveFold: (props : DisplayItemAboveFoldProps<SentencesUnitItem>) => {return <></>},
    //             BelowFold: (props : DisplayItemBelowFoldProps<SentencesUnitItem>)=>{return <></>},

    //             UserIndicatesIfCorrect: true
    //         }
    //     },
    //     SubUnits: [
    //     ],
    //     OrderUnit: []


    // } as UnitDefinition<SentencesUnitItem>,
    // [UnitType.Kana]: {
    //     UnitType: UnitType.Kana,
    //     UnitId: UnitType.Kana,
    //     NameLabel: "Kana",
    //     DisplayLabel: "かな",

    //     Items: kana as KanaUnitItem[],
    //     GetItemId: (item: KanaUnitItem) => item.id,
    //     GetItemDisplay: (item: KanaUnitItem) => item.glyph,

    //     UnitLocked: false,
    //     UnlockUnitOn: () => false,
    //     ItemsLocked: false,
    //     UnlockItemOn: () => false,

    //     CanProcessText: true,

    //     ParentTestDefinitionId: 0,

    //     TestDefinitions: {
    //         [KanaTestDefinition.GlyphToMeaning]: {
    //             id: 0,

    //             Label: 'Kana:GlyphToReading',
    //             UnitType: UnitType.Kana,
    //             Reintroduce: false,

    //             AboveFold: KanaGlyphToMeaningAboveFold,
    //             BelowFold: KanaGlyphToMeaningBelowFold,

    //             UserIndicatesIfCorrect: true
    //         }
    //     },

    //     SubUnits: [
    //         {
    //             Id: 1,
    //             Category: "Script",
    //             Label: "Hiragana",
    //             GetItems: (item: KanaUnitItem) => item.type == "hiragana"
    //         },
    //         {
    //             Id: 2,
    //             Category: "Script",
    //             Label: "Katakana",
    //             GetItems: (item: KanaUnitItem) => item.type == "katakana"
    //         },
    //     ],
    //     OrderUnit: []


    // } as UnitDefinition<KanaUnitItem>
}
