import { useState } from "react"
import { TextStyle } from "../App"
import { StyledButton } from "./ModeTile"

interface CollapseProps {
    Label: string,
    Collapsed?: boolean,
    children?: React.ReactNode
}

export const Collapse: React.FC<CollapseProps> = props => {

    const [collapsed, setCollapsed] = useState(props.Collapsed == true)

    return <>
        <div style={{width: '100%'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12}}>
                <StyledButton style={{}} onClick={() => setCollapsed(!collapsed)}>Toggle</StyledButton>
                <TextStyle>{props.Label}</TextStyle>
            </div>
            <hr />
            {!collapsed && <>
                {props.children}
            </>}
        </div>
    </>
}