/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class Message {
    'sender': MessageSenderEnum;
    'state': MessageStateEnum;
    'content'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "sender",
            "baseName": "Sender",
            "type": "MessageSenderEnum",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "State",
            "type": "MessageStateEnum",
            "format": ""
        },
        {
            "name": "content",
            "baseName": "Content",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Message.attributeTypeMap;
    }

    public constructor() {
    }
}


export enum MessageSenderEnum {
    User = 'User',
    Server = 'Server'
}
export enum MessageStateEnum {
    Sent = 'Sent',
    Loading = 'Loading'
}

