import { LanguageDefinition, LanguageUnitDefinition } from "../LanguageDefinition"

export enum ZhLanguageUnitTypes {
    Radical,
    Hanzi,
    Word
}

export enum ZhBaseUnitTestDefinition {
    GlyphToMeaning,
    MeaningReadingToGlyph,
    MeaningReadingToWrittenGlyph,
    GlyphToSpokenReading
}

export interface ZhRadicalUnitItem {
    id: number
    glyph: string
    definition: string,
    reading: string
}

export function FilterFromLanguageDefinition<UnitType, UnitItem>(
    Unit: LanguageDefinition<UnitType>,
    Type: UnitType,
    Process: (Id: string, Unit: LanguageUnitDefinition<UnitType>) => UnitItem): UnitItem[] {
    return Object.entries(Unit).map(value => {
        if (value[1].Types.includes(Type)) {
            return Process(value[0], value[1])
        }
    }).filter(o => o != undefined) as UnitItem[]
}

export function ZhRadicalFromLanguageUnitDefinition(Id: string, Unit: LanguageUnitDefinition<ZhLanguageUnitTypes>): ZhRadicalUnitItem {

    return {
        id: Unit.Keys["Radical"],
        glyph: Id,
        definition: Unit.Properties["PrimaryDefinition"],
        reading: Unit.Properties["Reading"]
    }
}

export interface ZhHanziUnitItem {
    id: number
    Character: string
    Pinyin: string | undefined
    Definition: string | undefined
    Radical: string | undefined
    RadicalIndex: string | undefined
    StrokeCount: number | undefined
    HSK: number | undefined
    GeneralStandard: number | undefined
    FrequencyRank: number | undefined
}

export function ZhHanziFromLanguageUnitDefinition(Id: string, Unit: LanguageUnitDefinition<ZhLanguageUnitTypes>): ZhHanziUnitItem {

    return {
        id: Unit.Keys["Hanzi"],
        Character: Id,

        Pinyin: Unit.Properties["Reading"],
        Definition: Unit.Properties["PrimaryDefinition"],

        Radical: Unit.Metadata["Radical"],
        RadicalIndex: Unit.Metadata["RadicalIndex"],
        StrokeCount: Unit.Metadata["StrokeCount"],
        HSK: Unit.Metadata["HSK"],
        GeneralStandard: Unit.Metadata["GeneralStandard"],
        FrequencyRank: Unit.Metadata["FrequencyRank"]
    }
}

export interface ZhWordUnitItem {
    id: number
    Unit: string
    reading: string
    meaning: string
    grade: number
}

export function ZhWordiFromLanguageUnitDefinition(Id: string, Unit: LanguageUnitDefinition<ZhLanguageUnitTypes>): ZhWordUnitItem {

    return {
        id: Unit.Keys["Word"],
        Unit: Id,
        reading: Unit.Properties["Reading"],
        meaning: Unit.Properties["PrimaryDefinition"],
        grade: Unit.Metadata["Grade"]
    }
}
