/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SegmentationOutput } from '../models/SegmentationOutput';
import { HttpFile } from '../http/http';

export class SentenceSegmentationResult {
    'data': Array<SegmentationOutput>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "data",
            "baseName": "Data",
            "type": "Array<SegmentationOutput>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SentenceSegmentationResult.attributeTypeMap;
    }

    public constructor() {
    }
}

