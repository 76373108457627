import { Button } from "antd"
import styled from "styled-components"


export const ContentTile = styled.div`

background-color: #ffffff40;
box-shadow: 3px 3px 9px #eadfd5;
display: flex;
flex-direction: column;

align-items: center;

flex-wrap: wrap;
flex-basis: 100%;
padding: 24px;

max-width: calc(100vw - 72px);;
/*min-height: calc(100vh - 230px);

@media (width < 400px) {
    min-height: calc(100vh - 230px);

}
@media (width > 400px) {
    min-height: calc(100vh - 248px);

}
*/
`

export const StyledButton = styled(Button)`
    height :64px;

    background-color: #f5efea00 ;
    outline: none;
    border: none;
    box-shadow: 3px 3px 12px #eadfd5;

    &:hover {
        background-color: #f5efea !important;

        transition: 0.5s;
    }
`

