import styled from "styled-components"

export const ModalBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #0000001a;
    z-index: 98;
    backdrop-filter: blur(3px);
`

export const ModalForeground = styled.div`
  position: fixed;
  left: 24px;
  width: calc(100% - 48px);
  background-color: #ffffffff;
  z-index: 99;
  margin-top: 0;
  box-shadow: 3px -3px 6px #eadfd5;
  bottom: 124px; 
  overflow-y: scroll;
  max-height: calc(100vh - 148px);


`

export const Modal: React.FC<{ OnClose: () => any, children: React.ReactNode }> = props => {

    return <>
        <ModalBackground onClick={props.OnClose} />
        <ModalForeground>
            {props.children}
        </ModalForeground>
    </>
}