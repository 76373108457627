import { db } from "./db"

export enum EventType {
    UnitCompletion,
    UnitUnlock,
    ItemUnlock,
    ItemPromotion,
    UnitMilestone
}

export interface EventDefn {
    Type: EventType
    UnitId: number,
    ItemId?: number,
    ItemStage?: string
    Count?: number
    Percentage?: number
}

export interface MessageRecord {
    Id?: number
    Time: Date
    Event: EventDefn,
    Cleared: boolean
}

export class MessageRecordState {
    async Get() : Promise<MessageRecord[]> {
        return await db.Messages.toArray()
    }

    async AddEvent(event: EventDefn) {
        await db.Messages.add({ Event: event, Time: new Date(), Cleared: false })
    }
}