import dayjs from "dayjs"
import { useState } from "react"
import { Card, fsrs, Rating, RecordLog } from "ts-fsrs"
import { TextStyle } from "../App"
import { DisplayItem } from "../Components/DisplayItem"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { UnitDefinitionMap } from "../Config/UnitDefinitionMap"
import { ZhLanguageDefinition } from "../data/ZhLanguageDefinition"
import { db } from "../Database/db"
import { ItemHistoryElement } from "../Database/HistoryState"
import { ControllerModeContainer, RowLayout, ColumnLayout } from "../Styles/Styles"

export const TestCardProgressionView: React.FC<{ Back: () => any }> = props => {

    const [checkItem, setCheck] = useState("")
    const [checkItemHistory, setItemHistory] = useState<ItemHistoryElement | undefined>(undefined)
    const [checkItemAllHistory, setCheckItemAllHistory] = useState<ItemHistoryElement[]>([])



    const CheckItemHistory = async () => {

        db.ItemHistory.where("UnitKey").equals(checkItem).toArray().then(hs => {
            if (hs.length != 0) {

                const parent = hs.find(h => h.TestDefinitionId == ZhLanguageConfiguration.PrimaryTestDefinitionId)
                setItemHistory(parent)
                setCheckItemAllHistory(hs)
            } else {
                setItemHistory(undefined)
            }
        })
    }

    const f = fsrs()

    const now = new Date()


    var recordLog: RecordLog | undefined = undefined

    if (checkItemHistory != undefined) {
        const dueDate = checkItemHistory.Due != undefined ? checkItemHistory.Due : now
        const elapsedDays = dayjs(now).diff(dueDate, 'day')

        const mapToCard = {
            difficulty: checkItemHistory.Difficulty,
            due: dueDate,
            lapses: checkItemHistory.Lapses,
            last_review: checkItemHistory.LastSeen,
            reps: checkItemHistory.Reps,
            elapsed_days: elapsedDays,
            scheduled_days: 0,
            stability: checkItemHistory.Stability,
            state: checkItemHistory.LearningState

        }

        recordLog = f.repeat(mapToCard, dueDate)

    }

    const Apply = (card: Card) => {
        
        checkItemHistory!!.Difficulty = card.difficulty
        checkItemHistory!!.Stability = card.stability
        checkItemHistory!!.Due = card.due
        checkItemHistory!!.Reps = card.reps
        checkItemHistory!!.Lapses = card.lapses
        checkItemHistory!!.LearningState = card.state;

        (db.ItemHistory.update(checkItemHistory!!.Id, checkItemHistory!!)).then(CheckItemHistory)

    }

    const Reset = () => {
        checkItemHistory!!.Difficulty = 0
        checkItemHistory!!.Stability = 0
        checkItemHistory!!.Due = undefined
        checkItemHistory!!.Reps = 0
        checkItemHistory!!.Lapses = 0
        checkItemHistory!!.LearningState = 0;
        (db.ItemHistory.update(checkItemHistory!!.Id, checkItemHistory!!)).then(CheckItemHistory)


    }


    return <>
        <ContentTile>
            <ControllerModeContainer>

                <RowLayout>
                    <div>
                        <ColumnLayout>
                            <TextStyle weight="bold">Check Item</TextStyle>
                            <input type='text' onChange={(e) => { setCheck(e.target.value) }} />
                            <StyledButton onClick={CheckItemHistory}>Check</StyledButton>
                            <StyledButton onClick={()=>{setItemHistory(undefined)}}>Unload</StyledButton>
                            <StyledButton onClick={Reset}>Reset</StyledButton>

                        </ColumnLayout>
                    </div>
                    {checkItemHistory != undefined && <>

                        <div>
                            <ColumnLayout>
                                <TextStyle>UnitId {checkItemHistory.Id}</TextStyle>
                                <TextStyle>Unlocked: {checkItemHistory.Unlocked ? "true" : "false"}</TextStyle>
                                <TextStyle>Introduced: {checkItemHistory.Introduced ? "true" : "false"}</TextStyle>
                                <TextStyle>Types: {checkItemHistory.UnitTypes.join(", ")}</TextStyle>
                                <TextStyle>Key: {checkItemHistory.UnitKey}</TextStyle>
                                <TextStyle>TDs: {checkItemAllHistory.map(h => `${h.TestDefinitionId},`)}</TextStyle>
                            </ColumnLayout>
                        </div>

                        <div>
                            <ColumnLayout>
                                <TextStyle>LanguageDefinitionUnit</TextStyle>
                                <TextStyle>Keys:</TextStyle>
                                <TextStyle>Radical: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Radical"]}</TextStyle>
                                <TextStyle>Hanzi: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Hanzi"]}</TextStyle>
                                <TextStyle>Word: {ZhLanguageDefinition[checkItemHistory.UnitKey].Keys["Word"]}</TextStyle>
                            </ColumnLayout>
                        </div>
                        <div>
                            <ColumnLayout>
                                <TextStyle>Difficulty: {checkItemHistory.Difficulty}</TextStyle>
                                <TextStyle>Stability: {checkItemHistory.Stability}</TextStyle>
                                <TextStyle>Reps: {checkItemHistory.Reps}</TextStyle>
                                <TextStyle>State: {checkItemHistory.LearningState}</TextStyle>
                                <TextStyle>LastSeen: {checkItemHistory.LastSeen?.toDateString()}</TextStyle>
                                <TextStyle>Due: {checkItemHistory.Due?.toDateString()}</TextStyle>
                            </ColumnLayout>
                        </div>

                    </>}
                </RowLayout>
                {checkItemHistory != undefined && recordLog != undefined && <>
                    <RowLayout>
                        {Object.entries(recordLog).map(r => {
                            return <>
                                <div>
                                    <ColumnLayout>
                                        <TextStyle weight='bold'>{r[0]}</TextStyle>
                                        <TextStyle>Difficulty: {r[1].card.difficulty}</TextStyle>
                                        <TextStyle>Stability: {r[1].card.stability}</TextStyle>
                                        <TextStyle>Due: {r[1].card.due.toDateString()}</TextStyle>
                                        <TextStyle>State: {r[1].card.state}</TextStyle>
                                        <StyledButton onClick={() => {Apply(r[1].card)} }>Apply</StyledButton>
                                    </ColumnLayout>
                                </div>

                            </>
                        })}
                    </RowLayout>
                </>}


                <StyledButton onClick={() => { props.Back() }}><TextStyle>Back</TextStyle></StyledButton>

            </ControllerModeContainer>
        </ContentTile>

    </>
}