import { useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { TestDefinitionMapType, UnitDefinitionMap, UnitDefinitionMapType } from "../Config/UnitDefinitionMap"
import { Data } from "../Types/Interfaces"
import { StyledButton } from "./ModeTile"
import { RoundItemData } from "../Views/RoundView"
import { LanguageDefinition } from "../Types/LanguageDefinition"
import { LanguageConfiguration, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { ZhBaseUnitTestDefinition } from "../Types/Zh/LanguageDefinition"
import { ZhBaseUnitType } from "./TestFolds/ZhBaseUnit"

interface DisplayItemProps<Unit> {

    LanguageConfiguration: LanguageConfiguration<any>
    Introduce: boolean,
    RoundItem: RoundItemData,

    TimeLimit: number,

    OnIntroduced: () => any
    OnAnswer: (correct: boolean, timeElapsed: number) => any

}

const DisplayItemContentSpace = styled.div`
flex-basis: 40%;
justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 12px;

`

export const DisplayItem: React.FC<Data<DisplayItemProps<any>>> = props => {
    const [revealFold, setRevealFold] = useState(false)

    const [answerSet, setAnswerSet] = useState(false)

    const onAnswer = (correct: boolean) => {
        setRevealFold(false)
        props.Data.OnAnswer(correct, timeElapsed)
        setTimeElapsed(0)
        setStartTime(new Date())
        setTimer(conditionallySetTimer)
    }

    const LanguageDefinitionUnit = props.Data.LanguageConfiguration.Definition[props.Data.RoundItem.UnitItemKey]

    const content: ZhBaseUnitType = {
        Id: props.Data.RoundItem.UnitItemKey, LanguageUnitDefinition: LanguageDefinitionUnit
    }

    const TestDefinition = props.Data.LanguageConfiguration.BaseUnitTestDefinitions[props.Data.RoundItem.TestDefinition]

    const [startTime, setStartTime] = useState(new Date())
    const [timeElapsed, setTimeElapsed] = useState(0)

    const conditionallySetTimer = () => {
        if (!revealFold && !props.Data.Introduce && props.Data.TimeLimit > 0) {
            return setTimeout(() => { console.log("time limit reached"); onResult(); }, props.Data.TimeLimit * 1000)
        } else {
            return undefined
        }
    }

    const [timer, setTimer] = useState<any>(conditionallySetTimer())

    const onResult = () => {
        clearTimeout(timer)

        var endTime = new Date()

        setTimeElapsed(endTime.getTime() - startTime.getTime())
        setRevealFold(true)
    }

    const [receivedAnswer, setReceivedAnswer] = useState<boolean | undefined>(undefined)

    const ReceiveAnswer = (answer: boolean) => {
        setRevealFold(true)
        setReceivedAnswer(answer)
        onResult()
    }



    return (
        <>
            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>

                <DisplayItemContentSpace>
                    {TestDefinition.AboveFold({ Unit: content, OnAnswer: ReceiveAnswer, ShowAnswerInterface: !props.Data.Introduce && !revealFold })}
                </DisplayItemContentSpace>
                <hr />
                <DisplayItemContentSpace>
                    {props.Data.Introduce && <>
                        {TestDefinition.BelowFold({ Unit: content })}

                    </>}
                    {!props.Data.Introduce && <>
                        {revealFold && <>

                            {TestDefinition.BelowFold({ Unit: content })}


                        </>}
                    </>}


                </DisplayItemContentSpace>
                <div style={{ marginBottom: '0', marginTop: 'auto' }}>
                    {props.Data.Introduce && <div style={{ padding: 24 }}>
                        <StyledButton onClick={props.Data.OnIntroduced}><TextStyle case='uppercase' size={2} weight='bold'>Next</TextStyle></StyledButton >
                    </div>}
                    {!revealFold && !props.Data.Introduce && TestDefinition.UserIndicatesIfCorrect && <div style={{ padding: 24 }}>
                        <StyledButton style={{ width: '100%' }} onClick={onResult}><TextStyle case='uppercase' size={2} weight='bold'>Reveal</TextStyle></StyledButton>
                    </div>}
                    {revealFold && !props.Data.Introduce && <div style={{ marginBottom: 0, marginTop: 'auto' }}>
                        <TextStyle>Time elasped: {timeElapsed}ms</TextStyle>
                        {!TestDefinition.UserIndicatesIfCorrect && <div style={{ padding: 12, gap: 12, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {receivedAnswer == true && <TextStyle>✅ Correct ✅</TextStyle>}
                            {receivedAnswer == false && <TextStyle>❌ Incorrect ❌</TextStyle>}
                        </div>}
                        {receivedAnswer == false && <StyledButton onClick={() => onAnswer(true)}><TextStyle>Override Correct</TextStyle></StyledButton>}
                        {TestDefinition.UserIndicatesIfCorrect && <div style={{ padding: 12, gap: 12, display: 'flex' }}>
                            <StyledButton onClick={() => onAnswer(false)}><TextStyle case='uppercase' size={1} weight='bold'>❌ Incorrect</TextStyle></StyledButton >
                            <StyledButton onClick={() => onAnswer(true)}><TextStyle case='uppercase' size={1} weight='bold'>✅ Correct</TextStyle></StyledButton >

                        </div>}
                        {!TestDefinition.UserIndicatesIfCorrect && <>
                            <StyledButton onClick={() => onAnswer(receivedAnswer!!)}><TextStyle case='uppercase' size={1} weight='bold'>Continue</TextStyle></StyledButton >

                        </>}
                        <div style={{ padding: 12 }}>

                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}