import { ZhLanguageDefinition } from "../data/ZhLanguageDefinition"
import { db, UnitState } from "../Database/db"
import { ItemHistoryElement } from "../Database/HistoryState"
import { LanguageDefinition, LanguageUnitDefinition } from "../Types/LanguageDefinition"
import { TestDefinitionMapType, UnitDefinitionMap, UnitDefinitionMapType } from "./UnitDefinitionMap"
import { ZhBaseUnitTestDefinition, ZhLanguageUnitTypes } from "../Types/Zh/LanguageDefinition";
import { EmptyAboveFold, EmptyBelowFold } from "../Components/TestFolds/Empty"
import { ZhBaseUnitGlyphToMeaningAboveFold, ZhBaseUnitGlyphToMeaningBelowFold, ZhBaseUnitGlyphToSpokenReadingAboveFold, ZhBaseUnitMeaningToWrittenGlyphAboveFold, ZhBaseUnitType } from "../Components/TestFolds/ZhBaseUnit"
import dayjs from "dayjs"


export interface LanguageConfiguration<LanguageUnitType> {
    Id: number
    Label: string

    Definition: LanguageDefinition<LanguageUnitType>
    Units: UnitDefinitionMapType
    UnitTypes: LanguageUnitType[]
    PrimaryTestDefinitionId: number
    BaseUnitTestDefinitions: TestDefinitionMapType<ZhBaseUnitType>

    SelectNext: (UnitDefinitions: UnitDefinitionMapType, Parameters: SelectUnitParameters) => Promise<NextUnitContainer>
}


export const ZhLanguageConfiguration: LanguageConfiguration<ZhLanguageUnitTypes> = {
    Id: 1,
    Label: '中文',

    Definition: ZhLanguageDefinition,
    Units: UnitDefinitionMap,
    UnitTypes: [ZhLanguageUnitTypes.Radical, ZhLanguageUnitTypes.Hanzi, ZhLanguageUnitTypes.Word],
    PrimaryTestDefinitionId: ZhBaseUnitTestDefinition.GlyphToMeaning,
    BaseUnitTestDefinitions: {
        [ZhBaseUnitTestDefinition.GlyphToMeaning]: {
            id: ZhBaseUnitTestDefinition.GlyphToMeaning,

            Label: 'GlyphToMeaning',
            Reintroduce: false,

            AboveFold: ZhBaseUnitGlyphToMeaningAboveFold,
            BelowFold: ZhBaseUnitGlyphToMeaningBelowFold,

            UserIndicatesIfCorrect: false

        },
        [ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph]: {
            id: ZhBaseUnitTestDefinition.MeaningReadingToWrittenGlyph,

            Label: 'MeaningToWrittenGlyph',
            Reintroduce: false,

            AboveFold: ZhBaseUnitMeaningToWrittenGlyphAboveFold,
            BelowFold: EmptyBelowFold,

            UserIndicatesIfCorrect: false

        },
        [ZhBaseUnitTestDefinition.GlyphToSpokenReading]: {
            id: ZhBaseUnitTestDefinition.GlyphToSpokenReading,

            Label: 'GlyphToSpokenReading',
            Reintroduce: false,

            AboveFold: ZhBaseUnitGlyphToSpokenReadingAboveFold,
            BelowFold: EmptyBelowFold,

            UserIndicatesIfCorrect: false

        }
    },

    SelectNext: ZhSelectNextBaseUnits
}

function SortHistoryElementByLanguageDefinitionKey(Key: string) {
    return (a: ItemHistoryElement, b: ItemHistoryElement) => {
        var unitA = ZhLanguageDefinition[a.UnitKey].Keys[Key]
        var unitB = ZhLanguageDefinition[b.UnitKey].Keys[Key]

        return unitA - unitB;

    }
}
function SortHistoryElementByLastSeen() {
    return (a: ItemHistoryElement, b: ItemHistoryElement) => {
        return a.LastSeen?.getTime()!! - b.LastSeen?.getTime()!!
    }
}

function SortHistoryElementByScore() {
    return (a: ItemHistoryElement, b: ItemHistoryElement) => {
        return a.Score - b.Score
    }
}

function SelectNew(type: number, UnitDefinitions: UnitDefinitionMapType) {
    return (element: ItemHistoryElement) => {
        return !element.Introduced && element.UnitTypes.includes(type) &&
            element.TestDefinitionId == UnitDefinitions[type].ParentTestDefinitionId
    }
}

function SelectReview(type: number, UnitDefinitions: UnitDefinitionMapType) {
    return (element: ItemHistoryElement) => {
        return element.Introduced && element.UnitTypes.includes(type) &&
            element.TestDefinitionId == UnitDefinitions[type].ParentTestDefinitionId
    }
}




export interface SelectUnitParameters {
    Counts: {
        New: { [type: number]: number }
        Review: number
    },
    TestDefinitions: number[]
}

export interface SelectedUnits {
    New: ItemHistoryElement[],
    Review: ItemHistoryElement[]
}

export interface NextUnitContainer {
    New: ItemHistoryElement[][],
    Review: ItemHistoryElement[][]
}

export async function ZhSelectNextBaseUnits(UnitDefinitions: UnitDefinitionMapType, Parameters: SelectUnitParameters): Promise<NextUnitContainer> {

    const UnitStateMap: { [UnitId: number]: UnitState } = {}

    console.log(Parameters)

    const UnitStates = await db.UnitState.toArray()
    UnitStates.map(us => {
        UnitStateMap[us.UnitId] = us
    })

    const FocusedTexts = await (await db.Texts.toArray()).filter(t => t.Focused)

    const UnlockedUnitHistory = (await db.ItemHistory.toArray()).filter(h => h.Unlocked)
    const UnitTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
    (UnlockedUnitHistory.map(h => {
        if (UnitTestCache[h.UnitKey] == undefined)
            UnitTestCache[h.UnitKey] = {}
        UnitTestCache[h.UnitKey][h.TestDefinitionId] = h
    }))


    // reap orphaned test definitions - if the parent test definition is introduced, the child test definitions will not be reintroduced
    // force set Introduced = true so when test is re-enabled, can enter review pool
    const now = new Date()
    for (const parentUnit of UnlockedUnitHistory.filter(h => h.TestDefinitionId == ZhLanguageConfiguration.PrimaryTestDefinitionId)) {

        for (const [_, h] of Object.entries(UnitTestCache[parentUnit.UnitKey])) {
            if (parentUnit.Introduced && h.Introduced == false) {
                h.Introduced = true
                h.Due = now

                console.log(`reap orphaned td ${h.UnitKey}:${h.TestDefinitionId}`)
                await db.ItemHistory.update(h.Id, h)
            }
        }
    }


    const New: ItemHistoryElement[] = []
    const Review: ItemHistoryElement[] = []

    if (FocusedTexts.length > 0) {

        const added: { [key: string]: boolean } = {}

        FocusedTexts.map(t => {
            t.ItemHistoryContainers.map(c => {
                c.SegmentUnits.map(u => {
                    u.BaseItems.map(b => {
                        if (UnitTestCache[b] != undefined) {

                            // keep track of duplicates and only add if not previously added
                            if (!added[b]) {
                                const unit = UnitTestCache[b][ZhLanguageConfiguration.PrimaryTestDefinitionId]
                                if (!unit.Introduced) {
                                    New.push(unit)
                                } else if (unit.Introduced && dayjs(unit.Due).diff(new Date(), 'day') == 0) {
                                    Review.push(unit)
                                }
                                added[b] = true

                            }
                        }
                    })
                })
            })
        })

        const newNew = New.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Hanzi]).map(h => {
            const tests: ItemHistoryElement[] = []
            Parameters.TestDefinitions.map(td => {
                tests.push(UnitTestCache[h.UnitKey][td])
            })
            return tests
        })

        const newReviwe = Review.slice(0, Parameters.Counts.Review).map(h => {
            const tests: ItemHistoryElement[] = []
            Parameters.TestDefinitions.map(td => {
                tests.push(UnitTestCache[h.UnitKey][td])
            })
            return tests
        })

        console.log(
            {
                New: newNew,
                Review: newReviwe
            })

        return {
            New: newNew,
            Review: newReviwe
        }
    }

    if (!UnitStateMap[ZhLanguageUnitTypes.Radical].Completed) {

        const NewRadicals = UnlockedUnitHistory
            .filter(SelectNew(ZhLanguageUnitTypes.Radical, UnitDefinitions))
            .sort(SortHistoryElementByLanguageDefinitionKey("Radical"))
        NewRadicals.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Radical]).map(h => New.push(h))

    } else {

        const NewHanzi = UnlockedUnitHistory
            .filter(SelectNew(ZhLanguageUnitTypes.Hanzi, UnitDefinitions))
            .sort(SortHistoryElementByLanguageDefinitionKey("Hanzi"))
        NewHanzi.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Hanzi]).map(h => New.push(h))

        const NewWords = UnlockedUnitHistory
            .filter((element: ItemHistoryElement) => {
                return !element.Introduced && element.UnitTypes.includes(ZhLanguageUnitTypes.Word) && element.UnitTypes.length == 1 &&
                    element.TestDefinitionId == UnitDefinitions[ZhLanguageUnitTypes.Word].ParentTestDefinitionId
            })
            .sort(SortHistoryElementByLanguageDefinitionKey("Word"))
        NewWords.slice(0, Parameters.Counts.New[ZhLanguageUnitTypes.Word]).map(h => New.push(h))

    }

    // add due review units
    UnlockedUnitHistory.filter(h => h.Introduced == true && dayjs(h.Due).diff(new Date(), 'day') == 0
        && h.TestDefinitionId == UnitDefinitions[ZhLanguageUnitTypes.Word].ParentTestDefinitionId)
        .slice(0, Parameters.Counts.Review)
        .map(h => Review.push(h))

    const newNew = New.map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            if (UnitTestCache[h.UnitKey][td] == undefined) {
                console.log(`ERROR ${h.UnitKey}:${td} undefined ???`)
            }
            tests.push(UnitTestCache[h.UnitKey][td])
        })
        return tests
    })

    const newReviwe = Review.map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(UnitTestCache[h.UnitKey][td])
        })
        return tests
    })

    console.log(newNew)
    console.log(newReviwe)

    return {
        New: newNew,
        Review: newReviwe
    }

}

export const UpdateUnitState = async (LanguageDefinitionConfiguration: LanguageConfiguration<any>) => {

    for (const unitType of LanguageDefinitionConfiguration.UnitTypes) {
        const UnitId = unitType
        const startTime = new Date()
        const unitState = await db.UnitState.where('UnitId').equals(UnitId).first()

        const unitDefn = UnitDefinitionMap[UnitId]
        const allUnits = await db.ItemHistory.filter(h => h.UnitTypes.includes(UnitId)).and(h => h.TestDefinitionId == unitDefn.ParentTestDefinitionId).toArray()

        const unlocked = await unitDefn.UnlockUnitOn()

        const completed = allUnits.every(h => h.Introduced == true)


        if (completed) {
            unitState!!.Completed = true
            console.log(`unit id ${UnitId} completed`)
        }

        if (unlocked && unitState?.Locked) {
            unitState!!.Locked = false
            console.log(`unit id ${UnitId} unlocked`)

            // only change unlocked state if not previously unlocked 
            await db.ItemHistory.filter(h => h.UnitTypes.includes(UnitId)).modify(async (h: ItemHistoryElement) => {
                if (!h.Unlocked)
                    h.Unlocked = !unitDefn.ItemsLocked
            })

        }

        if (unlocked && unitDefn.ItemsLocked) {

            const SimpleUnitCachee: { [key: string]: ItemHistoryElement } = {};
            // (await db.ItemHistory.where("TestDefinitionId").equals(unitDefn.ParentTestDefinitionId).toArray()).map((h: ItemHistoryElement) => unitHistoryCache[h.UnitKey] = h)

            const AllUnitHistory = (await db.ItemHistory.toArray())
            const UnitTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
            (AllUnitHistory.map(h => {
                if (UnitTestCache[h.UnitKey] == undefined)
                    UnitTestCache[h.UnitKey] = {}
                UnitTestCache[h.UnitKey][h.TestDefinitionId] = h
            }))

            AllUnitHistory.filter(h => h.TestDefinitionId == unitDefn.ParentTestDefinitionId).map((h: ItemHistoryElement) => SimpleUnitCachee[h.UnitKey] = h)


            const toUnlock = []

            for (const historyElem of allUnits) {
                const toUnitItem = unitDefn.HistoryElementToUnit(historyElem.UnitKey, ZhLanguageDefinition[historyElem.UnitKey])
                const unlockItem = await unitDefn.UnlockItemOn(toUnitItem, SimpleUnitCachee)

                if (unlockItem && (historyElem.Unlocked == false)) {

                    // ensure all tests are unlocked based on parent test state
                    const allTests = Object.entries(UnitTestCache[historyElem.UnitKey])
                    for (const [_, h] of allTests) {
                        h.Unlocked = true
                        toUnlock.push(h)
                        await db.ItemHistory.update(h.Id, h)

                    }

                }

            }

            console.log(`unlocked ${toUnlock.length} items in unit ${UnitId}`)

        }

        await db.UnitState.update(UnitId, unitState!!)

        const endTime = new Date()

        console.log(`process unit ${UnitId} ${new Date(endTime.getTime() - startTime.getTime()).getTime()} elapsed`)

    }




}