import { AppConfig } from "../../Types/AppConfig";
import { ApiException, DefaultApi, DictionaryQueryResult, HttpFile, IsomorphicFetchHttpLibrary, Messages, Query, SegmentSentenceRequest, SentenceSegmentationResult, ServerConfiguration, createConfiguration, KeyContainer, ApiKeyAuthentication } from "../../api";
import { ApiErrorType, ApiRequestStatus, ApiCall, ResponseType, RootApiContext, ApiEither } from "./RootApiContext";

export function UnwrapApiCall<T>(call: ApiEither<T>): T | undefined {
    if (call.responseType == ResponseType.Success) {
        return call.object;
    }
    return undefined;
}

async function WrapCallWithApiResult<ResultType>(call: (...params: any) => Promise<ResultType | undefined>, ...params: any): Promise<ApiCall<ResultType>> {

    try {
        const response = await call(...params)

        if (response == undefined) {
            return {
                error: ApiErrorType.HttpOther,
                status: ApiRequestStatus.Error,
                responseType: ResponseType.Error
            }
        }

        return {
            object: response!!,
            status: ApiRequestStatus.Success,
            responseType: ResponseType.Success
        }

    } catch (e: any) {
        return {
            error: ApiErrorType.HttpOther,
            status: ApiRequestStatus.Error,
            responseType: ResponseType.Error
        }
    }

}

export class DefaultApiContext implements RootApiContext {

    Settings?: AppConfig

    Api?: DefaultApi

    applySettings: (settings?: AppConfig) => void = (settings) => {
        this.Settings = settings


        const key = localStorage.getItem("key")
        const conf = {
            baseServer: new ServerConfiguration(this.Settings?.ApiServer!!, {}),
            authMethods: { "api_key" : new ApiKeyAuthentication(key ? key : "") },
            middleware: [],
            httpApi: new IsomorphicFetchHttpLibrary()
        }

        this.Api = new DefaultApi(conf)
    }

    setApiKey: (key : string) => void = (key) => {

        const conf = {
            baseServer: new ServerConfiguration(this.Settings?.ApiServer!!, {}),
            authMethods: { "api_key" : new ApiKeyAuthentication(key) },
            middleware: [],
            httpApi: new IsomorphicFetchHttpLibrary()
        }

        this.Api = new DefaultApi(conf)
    }

    CheckKey: (key: string) => ApiCall<boolean> = async (key) => {
        return await WrapCallWithApiResult(async (key: string) => {
            const keyContainer = new KeyContainer()
            keyContainer.key = key
            return await this.Api?.checkKey(keyContainer)
        }, key)
    }

    AssistantChat: (messages: Messages) => ApiCall<string> = async (messages) => {
        return await WrapCallWithApiResult(async (req: SegmentSentenceRequest) => {
            return await this.Api?.assistantChat(messages)
        }, messages)

    }

    Lookup: (request: Query) => ApiCall<DictionaryQueryResult> = async (request) => {
        return await WrapCallWithApiResult(async (req: Query) => { return await this.Api?.lookupQuery(req) }, request)
    }

    Translate: (request: Query) => ApiCall<string> = async (request) => {
        return await WrapCallWithApiResult(async (req: Query) => { return await this.Api?.assistTranslate(req) }, request)
    }

    Explain: (request: Query) => ApiCall<string> = async (request) => {
        return await WrapCallWithApiResult(async (req: Query) => { return await this.Api?.assistExplain(req) }, request)
    }


    SegmentSentences: (request: SegmentSentenceRequest) => ApiCall<SentenceSegmentationResult> = async (request) => {
        return await WrapCallWithApiResult(async (req: SegmentSentenceRequest) => { return await this.Api?.segmentSentences(req) }, request)
    }

    EvaluateImage: (image: HttpFile) => ApiCall<SentenceSegmentationResult> = async (image) => {
        return await WrapCallWithApiResult(async (image: HttpFile) => { return await this.Api?.evaluateImage(image) }, image)
    }

    EvaluatePronunciation: (id: string, blob: Blob) => ApiCall<any> = async (id, blob) => {
        return await WrapCallWithApiResult(async (id: string, blob: Blob) => {
            // @ts-expect-error
            return await this.Api?.evaluatePronunciation(id, blob)
        }, id, blob)
    }
}