import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'

import { AssistValidateRequest } from '../models/AssistValidateRequest';
import { DictionaryEntryType } from '../models/DictionaryEntryType';
import { DictionaryQueryResult } from '../models/DictionaryQueryResult';
import { EntryExtraData } from '../models/EntryExtraData';
import { KeyContainer } from '../models/KeyContainer';
import { LanguageTextPair } from '../models/LanguageTextPair';
import { LanguageUnit } from '../models/LanguageUnit';
import { LanguageUnitDefinition } from '../models/LanguageUnitDefinition';
import { LanguageUnitDefinitionExamples } from '../models/LanguageUnitDefinitionExamples';
import { LookupQuery } from '../models/LookupQuery';
import { Message } from '../models/Message';
import { Messages } from '../models/Messages';
import { Query } from '../models/Query';
import { Sample } from '../models/Sample';
import { SegmentSentenceRequest } from '../models/SegmentSentenceRequest';
import { SegmentTextRequest } from '../models/SegmentTextRequest';
import { SegmentationOutput } from '../models/SegmentationOutput';
import { SegmentationResult } from '../models/SegmentationResult';
import { SegmentedText } from '../models/SegmentedText';
import { SentenceSegmentationResult } from '../models/SentenceSegmentationResult';
import { TextPair } from '../models/TextPair';
import { TranslateResponse } from '../models/TranslateResponse';
import { TranslateResponseCost } from '../models/TranslateResponseCost';
import { TranslateResponseOutput } from '../models/TranslateResponseOutput';
import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param query 
     */
    public assistExamplesWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<TranslateResponse>> {
        const result = this.api.assistExamplesWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExamples(query: Query, _options?: Configuration): Promise<TranslateResponse> {
        const result = this.api.assistExamples(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExplainWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistExplainWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExplain(query: Query, _options?: Configuration): Promise<string> {
        const result = this.api.assistExplain(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistTranslateWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistTranslateWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistTranslate(query: Query, _options?: Configuration): Promise<string> {
        const result = this.api.assistTranslate(query, _options);
        return result.toPromise();
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidateWithHttpInfo(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Promise<HttpInfo<TranslateResponse>> {
        const result = this.api.assistValidateWithHttpInfo(assistValidateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidate(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Promise<TranslateResponse> {
        const result = this.api.assistValidate(assistValidateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param messages 
     */
    public assistantChatWithHttpInfo(messages: Messages, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistantChatWithHttpInfo(messages, _options);
        return result.toPromise();
    }

    /**
     * @param messages 
     */
    public assistantChat(messages: Messages, _options?: Configuration): Promise<string> {
        const result = this.api.assistantChat(messages, _options);
        return result.toPromise();
    }

    /**
     * @param keyContainer 
     */
    public checkKeyWithHttpInfo(keyContainer: KeyContainer, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.checkKeyWithHttpInfo(keyContainer, _options);
        return result.toPromise();
    }

    /**
     * @param keyContainer 
     */
    public checkKey(keyContainer: KeyContainer, _options?: Configuration): Promise<boolean> {
        const result = this.api.checkKey(keyContainer, _options);
        return result.toPromise();
    }

    /**
     * @param image 
     */
    public evaluateImageWithHttpInfo(image: HttpFile, _options?: Configuration): Promise<HttpInfo<SentenceSegmentationResult>> {
        const result = this.api.evaluateImageWithHttpInfo(image, _options);
        return result.toPromise();
    }

    /**
     * @param image 
     */
    public evaluateImage(image: HttpFile, _options?: Configuration): Promise<SentenceSegmentationResult> {
        const result = this.api.evaluateImage(image, _options);
        return result.toPromise();
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciationWithHttpInfo(reference: string, audio: HttpFile, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.evaluatePronunciationWithHttpInfo(reference, audio, _options);
        return result.toPromise();
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciation(reference: string, audio: HttpFile, _options?: Configuration): Promise<any> {
        const result = this.api.evaluatePronunciation(reference, audio, _options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public lookupQueryWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Promise<HttpInfo<DictionaryQueryResult>> {
        const result = this.api.lookupQueryWithHttpInfo(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public lookupQuery(lookupQuery: LookupQuery, _options?: Configuration): Promise<DictionaryQueryResult> {
        const result = this.api.lookupQuery(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentencesWithHttpInfo(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Promise<HttpInfo<SentenceSegmentationResult>> {
        const result = this.api.segmentSentencesWithHttpInfo(segmentSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentences(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Promise<SentenceSegmentationResult> {
        const result = this.api.segmentSentences(segmentSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param segmentTextRequest 
     */
    public segmentTextWithHttpInfo(segmentTextRequest: SegmentTextRequest, _options?: Configuration): Promise<HttpInfo<SegmentationResult>> {
        const result = this.api.segmentTextWithHttpInfo(segmentTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param segmentTextRequest 
     */
    public segmentText(segmentTextRequest: SegmentTextRequest, _options?: Configuration): Promise<SegmentationResult> {
        const result = this.api.segmentText(segmentTextRequest, _options);
        return result.toPromise();
    }


}



