/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AssistValidateRequest {
    'query': string;
    'sample': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "query",
            "baseName": "query",
            "type": "string",
            "format": ""
        },
        {
            "name": "sample",
            "baseName": "sample",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AssistValidateRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

