/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TranslateResponseCost } from '../models/TranslateResponseCost';
import { TranslateResponseOutput } from '../models/TranslateResponseOutput';
import { HttpFile } from '../http/http';

export class TranslateResponse {
    'output': TranslateResponseOutput;
    'cost': TranslateResponseCost;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "output",
            "baseName": "output",
            "type": "TranslateResponseOutput",
            "format": ""
        },
        {
            "name": "cost",
            "baseName": "cost",
            "type": "TranslateResponseCost",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TranslateResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

