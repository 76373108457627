import { useState } from "react"
import { AppCallbacks, TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { ItemHistoryElement } from "../Database/HistoryState"
import { ButtonDisplay, ButtonSubDisplay, ColumnLayout, ControllerModeContainer, RowLayout } from "../Styles/Styles"
import { ProcessTextView } from "./ProcessTextView"
import { TestCardProgressionView } from "./TestCardProgressionView"
import { TestDisplayTestDefinition } from "./TestDisplayTestDefinition"
import { TestFlowDebugView } from "./TestFlowDebugView"

export const GlobalDebugView: React.FC<AppCallbacks> = props => {

    enum DebugModeState {
        Menu,
        TestFlow,
        ProcessText,
        DisplayTestDefinition,
        CardProgression,
    }

    const [debugModeState, setDebugModeState] = useState<DebugModeState>(DebugModeState.Menu)

    const DeleteDatabase = async () => {
        console.log('deleting database')
        await db.delete().then(() => db.open());
        alert('database deleted')
    }

    const BackupHistory = async () => {
        var history = await db.ItemHistory.toArray()

        localStorage.setItem("historybackup", JSON.stringify(history));

        alert('backed up history')
    }

    const RestoreHistory = async () => {

        var historyBackupJson = localStorage.getItem("historybackup")

        if (historyBackupJson != null) {
            var historyBackup = JSON.parse(historyBackupJson) as ItemHistoryElement[]

            historyBackup.map(h => { db.ItemHistory.update(h.Id, h) })
            //db.History.update
        }

        alert('restored history')

    }

    return <>
        {debugModeState == DebugModeState.CardProgression && <>
            <TestCardProgressionView Back={() => setDebugModeState(DebugModeState.Menu)} />
        </>}

        {debugModeState == DebugModeState.DisplayTestDefinition && <>
            <TestDisplayTestDefinition Back={() => setDebugModeState(DebugModeState.Menu)} />
        </>}
        {debugModeState == DebugModeState.TestFlow && <>
            <TestFlowDebugView Back={() => setDebugModeState(DebugModeState.Menu)} />
        </>}

        {debugModeState == DebugModeState.Menu && <ContentTile>

            <ControllerModeContainer>
                <ButtonDisplay>
                    <ButtonSubDisplay>
                        <StyledButton onClick={() => { setDebugModeState(DebugModeState.TestFlow) }}><TextStyle>Test Unit Flow</TextStyle></StyledButton>
                        <StyledButton onClick={() => { setDebugModeState(DebugModeState.ProcessText) }}><TextStyle>Process Text</TextStyle></StyledButton>
                        <StyledButton onClick={() => { setDebugModeState(DebugModeState.DisplayTestDefinition) }}><TextStyle>Display TestDefinition</TextStyle></StyledButton>
                        <StyledButton onClick={() => { setDebugModeState(DebugModeState.CardProgression) }}><TextStyle>Card Progression</TextStyle></StyledButton>
                    </ButtonSubDisplay>
                    <ButtonSubDisplay>
                        <StyledButton onClick={BackupHistory}><TextStyle>Backup history</TextStyle></StyledButton>
                        <StyledButton onClick={RestoreHistory}><TextStyle>Restore history</TextStyle></StyledButton>

                    </ButtonSubDisplay>
                    <ButtonSubDisplay>
                        <StyledButton onClick={() => { DeleteDatabase() }}><TextStyle>Delete database</TextStyle></StyledButton>

                    </ButtonSubDisplay>
                </ButtonDisplay>
                <ButtonDisplay>
                </ButtonDisplay>
            </ControllerModeContainer>

        </ContentTile>}
    </>
}