import { useState } from "react"
import { TextStyle } from "../App"
import { Collapse } from "../Components/Collapse"
import { StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { RowLayout, InnerTile, ColumnLayout } from "../Styles/Styles"
import { ItemHistoryContainer } from "../Types/ItemHistoryContainer"
import { ProcessedText } from "../Types/ProcessedText"
import { DictionaryModal } from "../Views/DictionaryView"
import { TextHelperModal } from "../Views/TextHelperView"

export const DictionaryQueryTile: React.FC<{ Text: string }> = props => {
    const [showDictionary, setShowDictionary] = useState(false)

    return <>
        <InnerTile onClick={()=> {setShowDictionary(true)}} style={{ width: 'auto' }} Small={true}>
            {props.Text}
        </InnerTile>
        {showDictionary && <DictionaryModal OnClose={() => setShowDictionary(false)} Query={props.Text} />}

    </>
}

export const TextBreakdownDisplay: React.FC<{ ihcs: ItemHistoryContainer }> = props => {
    const [showTextHelper, setShowTextHelper] = useState(false)
    const ihcs = props.ihcs

    return <>
        <Collapse Label={ihcs.OriginalText?.target!!} Collapsed={true}>
            <ColumnLayout>
                <StyledButton onClick={() => { setShowTextHelper(true) }}>TextHelper</StyledButton>
                <TextStyle>{ihcs.OriginalText?.primary}</TextStyle>
                <RowLayout>

                    {ihcs.SegmentUnits.map((s, i) => {

                        return <DictionaryQueryTile key={i} Text={s.Segment} />

                    })}
                </RowLayout>
            </ColumnLayout>
            {showTextHelper && <TextHelperModal OnClose={() => setShowTextHelper(false)} Text={ihcs.OriginalText?.target!!} />}

        </Collapse>
    </>
}

export const ProcessedTextDisplay: React.FC<{ Text: ProcessedText }> = props => {

    const [toggleView, setToggleView] = useState(false)
    const [focusButttonDisabled, setFocusButtonDisabled ]= useState(false)
    const [deleteButtonDisabled, setDeleteButtonDisabled ]= useState(false)

    return <>
        <Collapse Label={props.Text.Label} Collapsed={true}>

            <RowLayout>
                {!props.Text.Focused && <StyledButton disabled={focusButttonDisabled}  onClick={() => db.Texts.update(props.Text.Id, { "Focused": true }).then(()=>setFocusButtonDisabled(true))} >Focus</StyledButton>}
                {props.Text.Focused && <StyledButton disabled={focusButttonDisabled} onClick={() => db.Texts.update(props.Text.Id, { "Focused": false }).then(()=>setFocusButtonDisabled(true))} >Unfocus</StyledButton>}
                <StyledButton onClick={() => { setToggleView(!toggleView) }}>View</StyledButton>
                <StyledButton disabled={deleteButtonDisabled} onClick={() => { db.Texts.delete(props.Text.Id).then(()=>setDeleteButtonDisabled(true)) }}>Delete</StyledButton>
            </RowLayout>

            {toggleView && props.Text.ItemHistoryContainers.map(ihcs => <TextBreakdownDisplay ihcs={ihcs} />)}

        </Collapse>

    </>
}