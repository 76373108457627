import dayjs from "dayjs";
import { title } from "process";
import { SegmentationOutput } from "../api";
import { SelectUnitParameters, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration";
import { db } from "../Database/db";
import { ItemHistoryElement } from "../Database/HistoryState";
import { ItemHistoryContainer, SegmentUnit } from "../Types/ItemHistoryContainer";
import { ProcessedText } from "../Types/ProcessedText";

export async function ProcessedTextFromSegmentationOutput(Label: string, segmentation: SegmentationOutput[]) {

    const unitHistory = await db.ItemHistory.toArray()

    const unitHistoryCache: { [key: string]: ItemHistoryElement } = {};
    unitHistory.filter(uh => uh.TestDefinitionId == 0).map(uh => unitHistoryCache[uh.UnitKey] = uh)

    const found = []
    const notfound = []
    const foundchars = []
    const notfoundchars = []
    const ihcs = []

    for (const sentence of segmentation) {

        const itemHistoryContainer: ItemHistoryContainer = {
            OriginalText: sentence.input,
            SegmentUnits: []
        }

        for (const unit of sentence.output) {
            const uh = unitHistoryCache[unit.unit]

            const segmentUnit: SegmentUnit = {
                Segment: unit.unit,
                FullItem: undefined,
                BaseItems: []
            }

            if (uh != undefined) {
                found.push(unit.unit)
                segmentUnit.FullItem = uh.UnitKey
            } else {
                if (unit.dictionaryQuery!!.results!!.length > 0) {

                } else {
                }
                notfound.push(unit.unit)
            }

            const charCache: { [key: string]: any } = {}

            unit.unit.split("").map(c => {
                const history = unitHistoryCache[c]

                if (history != undefined && charCache[c] == undefined) {
                    segmentUnit.BaseItems.push(history.UnitKey)
                    charCache[c] = true
                    foundchars.push(c)
                } else {
                    notfoundchars.push(c)
                }

                return false
            })

            itemHistoryContainer.SegmentUnits.push(segmentUnit)

        }
        ihcs.push(itemHistoryContainer)
    }

    const processedText: ProcessedText = {
        Label: Label,
        Date: new Date(),
        Focused: false,
        ItemHistoryContainers: ihcs,
    }

    return processedText


}

export async function ItemHistoryFromProcessedText(text: ProcessedText, Parameters: SelectUnitParameters) {
    const New: ItemHistoryElement[] = []
    const Review: ItemHistoryElement[] = []

    const added: { [key: string]: boolean } = {}

    const UnlockedUnitHistory = (await db.ItemHistory.toArray()).filter(h => h.Unlocked)

    const UnitTestCache: { [key: string]: { [test: number]: ItemHistoryElement } } = {};
    (UnlockedUnitHistory.map(h => {
        if (UnitTestCache[h.UnitKey] == undefined)
            UnitTestCache[h.UnitKey] = {}
        UnitTestCache[h.UnitKey][h.TestDefinitionId] = h
    }))

    text.ItemHistoryContainers.map(c => {
        c.SegmentUnits.map(u => {
            u.BaseItems.map(b => {
                if (UnitTestCache[b] != undefined) {

                    // keep track of duplicates and only add if not previously added
                    if (!added[b]) {
                        const unit = UnitTestCache[b][ZhLanguageConfiguration.PrimaryTestDefinitionId]
                        if (!unit.Introduced) {
                            New.push(unit)
                        } else if (unit.Introduced && dayjs(unit.Due).diff(new Date(), 'day') == 0) {
                            Review.push(unit)
                        }
                        added[b] = true

                    }
                }
            })
        })
    })


    const newNew = New.map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(UnitTestCache[h.UnitKey][td])
        })
        return tests
    })

    const newReviwe = Review.slice(0, Parameters.Counts.Review).map(h => {
        const tests: ItemHistoryElement[] = []
        Parameters.TestDefinitions.map(td => {
            tests.push(UnitTestCache[h.UnitKey][td])
        })
        return tests
    })

    return {
        New: newNew,
        Review: newReviwe
    }
}

