import { useEffect, useState } from "react"
import styled from "styled-components"
import { TextStyle } from "../App"
import { MessageRecord, MessageRecordState } from "../Database/MessageRecord"

const NotificationPane = styled.div`
  position: absolute;
  transform: translate(0, 0%);
  left: 24px;
  height: 50%;
  width: 50vw;
  background-color: #ffffffff;
  z-index: 60;
  margin-top: 0;
  box-shadow: 3px -3px 6px #eadfd5;
  bottom: 124px; 

  @media (width < 576px){ 
    width: calc(100vw - 48px);
    height: calc(100vh - 148px);
  }

`


export const MessagesLoader: React.FC = props => {

    const [loaded, setLoaded] = useState(false)
    const [messages, setMessages] = useState<MessageRecord[]>([])

    useEffect(() => {
        let active = true

        async function Load() {
            if (!loaded) {
                (new MessageRecordState()).Get().then(setMessages)
            }
        }

        Load().then(() => setLoaded(true))

        return () => {
            active = false
        }
    }, [loaded, messages])

    return <>
        <NotificationPane>
            {messages.map(m => {
                return <>
                    <TextStyle>{m.Id}</TextStyle>
                </>
            })}
        </NotificationPane>

    </>

}