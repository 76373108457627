import { RoundRecord } from "../Views/RoundView"
import { RoundHistoryState } from "../Database/RoundHistoryState"
import { db } from "../Database/db"
import {fsrs, Grade, Rating} from 'ts-fsrs';
import { AnswerState, ItemHistoryElement } from "../Database/HistoryState";
import dayjs from "dayjs";

export async function SetNewHistoryFromRoundRecord(RoundRecord: RoundRecord) {

    console.log("set history from round record")

    const history = await db.ItemHistory.toArray()
    const historyCache = {} as { [id : number] : ItemHistoryElement }
    history.forEach(h => { historyCache[h.Id!!] = h })

    const f = fsrs()
    const now = new Date()

    for (const item of RoundRecord.Items) {


        const historyItem = historyCache[item.HistoryId]

        const isNew = historyItem.Introduced == false

        historyItem.Record = item.Record
        historyItem.LastSeen = now
        historyItem.Introduced = true
        historyItem.Score += 1

        const dueDate = historyItem.Due != undefined ? historyItem.Due : now
        const elapsedDays = dayjs(now).diff(dueDate, 'day')

        const mapToCard = {
            difficulty: historyItem.Difficulty,
            due: dueDate,
            lapses: historyItem.Lapses,
            last_review: historyItem.LastSeen,
            reps: historyItem.Reps,
            elapsed_days: elapsedDays,
            scheduled_days: 0,
            stability: historyItem.Stability,
            state: historyItem.LearningState
        
        }

        const recordLog = f.repeat(mapToCard, dueDate)

        // simple score calculation
        // should improve by iterating over Round Record results and measuring time to answer

        // const easyRating = item.Record.filter(i => i.Record == AnswerState.Success).length == item.Record.length
        // easy rating not ideal for this, scales too long relative to good
        const easyRating = false
        const goodRating = item.Record.filter(i => i.Record == AnswerState.Success).length == item.Record.length
        const hardRating = item.Record.filter(i => i.Record == AnswerState.Failure).length == 1
        const againRating = item.Record.filter(i => i.Record == AnswerState.Failure).length > 1
        
        var which = recordLog[Rating.Again]
        
        if (easyRating) {
            which = recordLog[Rating.Easy]
        } else if (goodRating) {
            which = recordLog[Rating.Good]
        } else if (hardRating) {
            which = recordLog[Rating.Hard]
        } else {
            which = recordLog[Rating.Again]
        }

        // force review early if new 
        if (isNew && easyRating) {
            which = recordLog[Rating.Good]
        }

        historyItem.Difficulty = which.card.difficulty
        historyItem.Stability = which.card.stability
        historyItem.Due = which.card.due
        historyItem.Reps = which.card.reps
        historyItem.Lapses = which.card.lapses
        historyItem.LearningState = which.card.state

        await db.ItemHistory.update(item.HistoryId, historyItem)

    }

    var Id = await (new RoundHistoryState()).AppendItemHistory(RoundRecord);
   
    return Id

}